const initial_state = {
  reRendered: false,
  title: false,
};

const reducer = (state = initial_state, action) => {
  switch (action.type) {
    case "re_render_component": {
      return {
        ...state,
        reRendered: action.reRender,
      };
    }
    case "update_nav_title": {
      return {
        ...state,
        title: action.title,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
