import React, { Component } from 'react'
import Footer from './Footer';
import firebase from '../Config/Firebase'
import FooterGreen from './FooterGreen';
import axios from "axios"

export class PaymentFailed extends Component {
    constructor() {
        super()
        this.state = {
            currentUser: "",
            buttonClicked: false,
        }
        window.addEventListener("beforeunload", (ev) => {
            let uid = localStorage.getItem("userid");
            if (uid) {
                if (!this.state.buttonClicked) {
                    firebase
                        .database()
                        .ref("users/" + uid + "/newly_registered")
                        .set(false);
                    firebase
                        .database()
                        .ref("users/" + uid + "/movedToMollie")
                        .set(false);
                }
            }
        });
    }

    componentDidMount() {
        this.setState({ currentUser: this.props.currentMemberData })
    }

    render() {
        return (
            <div className="global-event-cart" style={{ height: "auto", top: window.innerWidth > 1009 && 70 }}>
                <div className="left-gec charitylgec" style={{ paddingBottom: window.innerWidth < 1009 && 40 }}>
                    <div className="container-lgec">
                        <p className="title-lpcomp">Hoi {this.state.currentUser.type === 'user' ? this.state.currentUser.first_name : this.state.currentUser.company_name},</p>
                        <p className="title-lpcomp"><b>Payment is failed sorry!</b></p>
                        <p className="title-lpcomp">
                            Leuk dat je <i>Charity Member</i> bent geworden.
                        </p>
                        {window.innerWidth > 1008 ? <p className="title-lpcomp">
                            De details van je bestelling vind je terug in het orderoverzicht<br />
                            van je profielpagina. Vanuit dit overzicht kun je jouw bestelling<br />
                            op locatie en via je mobiel valideren. Voor events geldt dat je<br />
                            jouw komst pas op de dag van het event kunt valideren.
                        </p> : <p className="title-lpcomp">
                            De details van je bestelling vind je terug in het<br />
                            orderoverzicht van je profielpagina. Vanuit dit<br />
                            overzicht kun je jouw bestelling op locatie en<br />
                            via je mobiel valideren. Voor events geldt dat<br />
                            je jouw komst pas op de dag van het event<br />
                            kunt valideren.
                        </p>}
                        <p className="title-lpcomp"><i>Bedankt voor je steun!</i></p>
                        <button className="button-new-lpcomp" onClick={() => {
                            let redirect_url = `${window.location.origin}/company/${this.state.currentUser.user_name_id}`
                            let userId = localStorage.getItem("userid");
                            this.setState({ buttonClicked: true })

                            axios.post("https://us-central1-bhodi-production-da765.cloudfunctions.net/createDonationPayment", {
                                amount: this.state.currentUser.totalPaidForDonate,
                                // amount: this.state.plan_amount,
                                customerId: this.state.currentUser.stripe_id,
                                redirectUrl: redirect_url
                            })
                                .then((responseSec) => {
                                    firebase.database().ref("users/" + userId + "/payment_url_donation").set(responseSec.data.payment._links.checkout.href)
                                    firebase.database().ref("users/" + userId + "/plan_id_donation").set("21")
                                    firebase.database().ref("users/" + userId + "/plan_amount_donation").set("4.00")
                                    firebase.database().ref("users/" + userId + "/movedToMollie").set(true)
                                    firebase.database().ref("users/" + userId + "/payment_id_donation").set(responseSec.data.payment.id).then(() => {
                                        this.setState({ aanmeldenloader: false })
                                        window.location.href = responseSec.data.payment._links.checkout.href
                                    })
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }} style={{ cursor: "pointer", textTransform: "none", marginTop: 10 }}>Pay Again</button>
                    </div>
                </div>
                {window.innerWidth > 1008 && <Footer />}
                {window.innerWidth < 1008 && <FooterGreen />}
            </div>
        )
    }
}

export default PaymentFailed