import React, { Component } from 'react'
import playicon from "../../assets/playiconlogomobile.png"
import "./index.css"
import Footer from '../../Components/Footer'
import FooterGreen from '../../Components/FooterGreen'
import { connect } from 'react-redux'
import { history } from '../../Config/Routes'
import EventsNewSection from '../../Components/EventsNewSection'
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import AddEditLocalEvent from './AddEditLocalEvent'
import EventsNewMobile from '../EventsNewMobile'
import GuestUserNavbar from '../../Components/GuestUserNavbar'
import { AiFillGift, AiOutlineQrcode } from 'react-icons/ai'
import { GiHotMeal } from 'react-icons/gi'
import PublishIcon from "@material-ui/icons/Publish";
import { GoDeviceCameraVideo, GoDeviceDesktop } from "react-icons/go";
import CloseIcon from "@material-ui/icons/Close";
import firebase from '../../Config/Firebase'
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { CiMobile3 } from "react-icons/ci"
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { ChevronLeft } from "@material-ui/icons";
import ButtonTimerProductsNew from '../../Components/ButtonTimerProductsNew'
import { HiMail } from 'react-icons/hi'
import { IoLocationSharp } from 'react-icons/io5'
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PhoneIcon from "@material-ui/icons/Phone";
import swal from "sweetalert";
import imageCompression from "browser-image-compression";
import VouchersNewSection from '../../Components/VouchersNewSection'
import VouchersNewMobile from '../VouchersNewMobile'
import ViewerFriendlist from '../../Components/ViewerFriendlist'
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import PersonIcon from "@material-ui/icons/Person";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { updateCampaignsCategory } from '../../Redux/actions/authActions'
import contactSectionImage from "../../assets/contactbannerimage.jpeg"
import { MdClose } from 'react-icons/md'
import { BiPhone } from 'react-icons/bi'
import NewProductWrapper from '../ProductsPage/NewProductsWrapper'
import thanksVideo from "../../assets/thnx.mp4"
import WebShopNewSection from '../../Components/WebShopNewSection'
import { followUnfollowCompany } from '../../Functions/friendFunctions'
import ImpressiePopup from '../../Components/CompanyPage/ImpressiePopup'
import SubscribedProductsTab from './SubscribedProductsTab'
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa'
import { isEqual } from 'lodash'
import { mergeAdminEvent } from '../../Functions/globalFunctions'
import VouchersNewGridDesktop from '../../Components/VouchersNewGridDesktop'
import { QRCodeCanvas } from 'qrcode.react'
import { Switch } from '@material-ui/core'

export class MemberHome extends Component {

    constructor() {
        super()
        this.state = {
            currentUserId: "",
            adminProduct: "",
            allFourProducts: [],
            memberFirstHeading: "",
            bhodiNewsHeading: "",
            bhodiNewsText: "",
            memberSecondHeading: "",
            dashboardMemberDesc: "",
            currentCampaignToShow: "",
            currentCampaignToShowVoucher: "",
            currentCampaignToShowWebShop: [],
            isDesktop: false,
            localEventBlock: [],
            showUploadPopup: false,
            currentUser: "",
            allImagesStock: [],
            allSectors: [],
            mainSector: "",
            mainSectorStock: "",
            bannerHeight: window.innerHeight,
            showDesktopLanding: true,
            showDesktopLandingContact: true,
            allImagesCurrentSectorStock: [],
            company_about: "",
            contactImage: "",
            contactImageMobile: "",
            showBottomIcons: false,
            memberDashboardDescription: "",
            memberLandingPageText: "",
            showImpressiePopup: false,
            impressiePhotos: [],
            currentImpressieIndex: 0,
            uploadedItems: 0,
            dropFile: [],
            loaderAddingImpressie: false,
            latestEventOrVoucher: "",
            latestEventOrVoucherSecond: "",

            currentWebShopCampaign: "",
            atIndexWebShop: 0,
            availableCompaniesProducts: [],
            atCompanyIndex: 0,
            qrPopupOpen: false,
            qrCodeLink: "",
            currentQrQuery: "",

            companySecondHeading: "",

            hideContactInfo: false
        }
        this.sectionRef = React.createRef();
        this.charityRef = React.createRef();
        this.contactRef = React.createRef();
        this.sectionRefVoucher = React.createRef()
        this.sectionRefWebShop = []
        this.sectionRefProducts = React.createRef();
        this.sectionOntdekRedirect = React.createRef();
    }


    ontdekPress = () => {
        // Find the target section by its ref and scroll to it
        if (this.sectionOntdekRedirect.current) {
            this.sectionOntdekRedirect.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    scrollToSection = () => {
        // Find the target section by its ref and scroll to it
        if (this.sectionRef.current) {
            this.sectionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    scrollToProducts = () => {
        // Find the target section by its ref and scroll to it
        if (this.sectionRefProducts.current) {
            this.sectionRefProducts.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    scrollToSectionVoucher = () => {
        // Find the target section by its ref and scroll to it
        if (this.sectionRefVoucher.current) {
            this.sectionRefVoucher.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    scrollToSectionWebShop = (index) => {
        // Find the target section by its ref and scroll to it
        if (this.sectionRefWebShop[index].current) {
            this.sectionRefWebShop[index].current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    scrollToCharity = () => {
        // Find the target section by its ref and scroll to it
        setTimeout(() => {
            if (this.charityRef.current) {
                this.charityRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        }, 500);
    };

    scrollToContact = () => {
        // Find the target section by its ref and scroll to it
        setTimeout(() => {
            if (this.contactRef.current) {
                this.contactRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        }, 500);
        // window.scrollTo(0, document.body.scrollHeight)
    };

    componentDidMount() {
        // firebase.database().ref("assigned_members/-Mlp3xzj2mn9KY8ldril").on("value", (data) => {
        //     let a = data.val()
        //     a.map((t) => {
        //         firebase.database().ref(`users/${t.user_id}/maxVideosToMake`).remove()
        //     })
        // })
        let userid = localStorage.getItem("userid")
        // if (!userid) {
        //     window.addEventListener("storage", this.handleStorageChange)
        // }

        if (window.location.href.includes("/charity")) {
            this.scrollToCharity()
        }
        if (window.location.href.includes("/contact")) {
            this.scrollToContact()
        }

        let redirectVoucher = localStorage.getItem("redirectVoucher")
        let redirectWebShop = localStorage.getItem("redirectWebShop")
        let redirectEvents = localStorage.getItem("redirectEvents")
        let redirectProducts = localStorage.getItem("redirectProducts")
        if (redirectVoucher) {
            setTimeout(() => {
                this.scrollToSectionVoucher()
            }, 1000);
            localStorage.removeItem("redirectVoucher")
        }
        if (redirectWebShop) {
            let webShopAtIndex = localStorage.getItem("webShopAtIndex")
            if (webShopAtIndex) {
                webShopAtIndex = Number(JSON.parse(webShopAtIndex)) - 1
            }
            setTimeout(() => {
                this.scrollToSectionWebShop(webShopAtIndex)
            }, 1000);
            localStorage.removeItem("redirectWebShop")
        }
        if (redirectEvents) {
            setTimeout(() => {
                this.scrollToSection()
            }, 1000)
            localStorage.removeItem("redirectEvents")
        }
        if (redirectProducts) {
            setTimeout(() => {
                window.scrollTo(0, window.innerHeight)
            }, 2000);
            localStorage.removeItem("redirectProducts")
        }

        firebase
            .database()
            .ref("bhodi_campaigns_category")
            .on("value", (data) => {
                let b = data.val();
                if (b) {
                    this.props.updateCampaignsCategory(b)
                }
            });

        let scrollToTop = localStorage.getItem("scrollToTop")
        if (scrollToTop) {
            localStorage.removeItem("scrollToTop")
            setTimeout(() => {
                window.scrollTo(0, 0)
            }, 500);
        }

        if (document.querySelector(".nav-fullwidth")) {
            document.querySelector(".nav-fullwidth").classList.add("navbarfixedpositionupdatedmember")
        }

        if (this.props.globalsectors) {
            if (this.props.globalsectors.length > 0) {
                let arr = this.props.globalsectors;
                this.setState({ allSectors: arr });
            }
        }

        if (this.props.stock_images) {
            this.setState({ allImagesStock: this.props.stock_images })
        }

        const loaderId = document.getElementById("main-page-loader-id");
        loaderId.style.display = "none";

        document.getElementById("root").style.position = "static";
        document.getElementById("root").style.overflow = "auto";

        if (document.querySelector(".navbarfullwidthMobile")) {
            document.querySelector(".navbarfullwidthMobile").style.overflowX = "hidden"
        }
        this.setState({ currentUserId: userid, bannerHeight: userid ? window.innerHeight - 130.86 : window.innerHeight })

        // if (this.props.bhodi_products && userid) {
        //     let filtered = this.props.bhodi_products.filter((g) => {
        //         return g.created_by === process.env.REACT_APP_ADMIN_ID && g.is_bookmarked
        //     })
        //     if (filtered.length > 0) {
        //         let indexOfImage = 0

        //         let allImagesNew = []
        //         if (filtered[0].images && filtered[0].images.length > 0) {
        //             let duplicated = Array.from(filtered[0].images)

        //             let allImagesUrl = []
        //             duplicated.map((g) => {
        //                 if (g) {
        //                     let filePath = g.split("/")[7].split("?")[0]
        //                     allImagesUrl.push(filePath)
        //                 }
        //             })

        //             allImagesUrl.sort().map((t) => {
        //                 let filtered = duplicated.filter((g) => {
        //                     return g.includes(t)
        //                 })

        //                 if (filtered.length > 0) {
        //                     allImagesNew.push(filtered[0])
        //                 }
        //             })
        //             if (typeof filtered[0].featuredImage !== "undefined" && filtered[0].featuredImage !== null) {
        //                 if (filtered[0].featuredImage < filtered[0].images.length) {
        //                     let filteredIndex = allImagesNew.findIndex((g) => {
        //                         return g === filtered[0].images[filtered[0].featuredImage]
        //                     })
        //                     if (filteredIndex !== -1) {
        //                         indexOfImage = filteredIndex
        //                     }
        //                 }
        //             }
        //         }

        //         filtered[0].allImagesNew = allImagesNew
        //         filtered[0].indexOfImage = indexOfImage

        //         this.setState({ adminProduct: filtered[0] })
        //     }


        //     // let filteredProducts = this.props.bhodi_products.filter((t) => {
        //     //     return !t.availableOnTime && t.images && !t.is_bookmarked && !t.isSpecialProduct && !t.availableForCompanyGuest && t.created_by === process.env.REACT_APP_ADMIN_ID
        //     // })
        //     // let sortedProducts = [...filteredProducts].sort((a, b) => {
        //     //     return b.timestamp * 100 - a.timestamp * 100;
        //     // });
        //     // if (sortedProducts.length > 0) {
        //     //     this.setState({ allFourProducts: [...sortedProducts].splice(0, 4) })
        //     // }
        // }

        // if (this.props.all_impressies) {
        //     if (this.props.all_impressies.length > 0) {
        //         let filtered = this.props.all_impressies.filter((h) => {
        //             return h[0] === this.props.member_user_id
        //         })
        //         if (filtered.length > 0) {
        //             let objected = Object.entries(filtered[0][1])
        //             let arr = []
        //             objected.map((g) => {
        //                 arr.push(g[1])
        //             })
        //             this.setState({ impressiePhotos: arr })
        //         }
        //     }
        // } else {
        //     firebase
        //         .database()
        //         .ref("impressiePhotos/" + this.props.member_user_id)
        //         .once("value", (data) => {
        //             let a = data.val();
        //             if (a) {
        //                 let objected = Object.entries(a);
        //                 let arr = [];
        //                 objected.map((g) => {
        //                     arr.push(g[1]);
        //                 });
        //                 this.setState({ impressiePhotos: arr });
        //             } else {
        //                 this.setState({ impressiePhotos: [] });
        //             }
        //         });
        // }

        if (this.props.global_campaigns) {
            let arrCompleteEvents = []
            let arrCompleteVouchers = []
            let arrCompleteWebShop = []
            let arrCompleteVouchersEvents = []
            let toDoFilter = this.props.global_campaigns
            if (this.props.atCompanyPage) {
                toDoFilter = this.props.global_campaigns.filter((g) => {
                    return g[1].created_by === this.props.member_user_id
                })
            }
            toDoFilter.map((t) => {
                let g = t[1]
                if (g.campaignType === "local_event") {
                    if (g.hideOnGlobalEvent) {
                        // if (userid) {
                        //     if (g.created_by !== userid) {
                        //         return false;
                        //     }
                        // }
                        if (g.created_by !== this.props.member_user_id) {
                            if (userid) {
                                if (g.created_by !== userid) {
                                    return false;
                                }
                            }
                        }
                    }
                    if (g.eventForGuestUser) {
                        if (userid) {
                            if (g.created_by !== userid) {
                                return false;
                            }
                        }
                    }
                    if (g.takeFromAdmin) {
                        let obj = mergeAdminEvent(g, this.props.global_campaigns)
                        g = Object.assign({}, obj)
                    }
                    let arrTimingsAvailable = [];
                    if (g.timings) {
                        g.timings.map((e) => {
                            if (e.isoStartTime && e.isoEndTime) {
                                let checkedInOfCurrentBlock = [];
                                if (g.checked_in_by) {
                                    Object.entries(g.checked_in_by).map((t) => {
                                        if (t[1].event_id === e.eventId) {
                                            checkedInOfCurrentBlock.push(t[1]);
                                        }
                                    });
                                }
                                let getCurrentDate = new Date();
                                if (getCurrentDate.getTime() < new Date(Date.parse(e.isoEndTime)).getTime()) {
                                    if (checkedInOfCurrentBlock.length < Number(e.personsAmountEvent)) {
                                        arrTimingsAvailable.push(e);
                                    }
                                }
                            }
                        });
                    }
                    if (g.created_by === userid) {
                        arrCompleteEvents.push(g);
                    } else if (arrTimingsAvailable.length > 0) {
                        if (g.active && g.created_by !== process.env.REACT_APP_ADMIN_ID) {
                            arrCompleteEvents.push(g);
                            arrCompleteVouchersEvents.push(g)
                        }
                    }
                }
                if (g.campaignType === "kortings_voucher") {
                    if (g.created_by === userid) {
                        arrCompleteVouchers.push(g);
                    } else {
                        if (g.active) {
                            arrCompleteVouchers.push(g);
                            arrCompleteVouchersEvents.push(g)
                        }
                    }
                }
                if (g.campaignType === "web_shop") {
                    if (g.created_by === userid) {
                        arrCompleteWebShop.push(g);
                    } else {
                        if (g.active) {
                            if (!userid) {
                                if (!g.hideForGuestUsers) {
                                    arrCompleteWebShop.push(g);
                                    arrCompleteVouchersEvents.push(g)
                                }
                            } else {
                                arrCompleteWebShop.push(g);
                                arrCompleteVouchersEvents.push(g)
                            }
                        }
                    }
                }
            })

            if (arrCompleteEvents.length > 0) {
                let sortedCampaigns = arrCompleteEvents.sort((a, b) => {
                    return b.timestamp * 100 - a.timestamp * 100;
                });
                if (sortedCampaigns.length > 0) {
                    this.setState({ currentCampaignToShow: sortedCampaigns[0] })
                }
            }
            if (arrCompleteVouchers.length > 0) {
                let sortedCampaigns = arrCompleteVouchers.sort((a, b) => {
                    return b.timestamp * 100 - a.timestamp * 100;
                });
                if (sortedCampaigns.length > 0) {
                    this.setState({ currentCampaignToShowVoucher: sortedCampaigns[0] })
                }
            }
            if (arrCompleteWebShop.length > 0) {
                let sortedCampaigns = arrCompleteWebShop.sort((a, b) => {
                    return b.timestamp * 100 - a.timestamp * 100;
                });
                if (sortedCampaigns.length > 0) {
                    this.setState({ currentCampaignToShowWebShop: sortedCampaigns })
                }
            }
            if (arrCompleteVouchersEvents.length > 0) {
                let sortedCampaigns = [...arrCompleteVouchersEvents].filter((campaign) => {
                    return !campaign.takeFromAdmin
                }).sort((a, b) => {
                    return b.timestamp * 100 - a.timestamp * 100;
                });
                if (sortedCampaigns.length > 0) {
                    let latestEventOrVoucher = [...sortedCampaigns][0]
                    latestEventOrVoucher.type = "campaign";
                    latestEventOrVoucher.currentImageIndex = 0;

                    if (latestEventOrVoucher.desc) {
                        latestEventOrVoucher.short_desc = latestEventOrVoucher.desc.substr(0, 32);
                    }
                    latestEventOrVoucher.images = [latestEventOrVoucher.image];
                    latestEventOrVoucher.imagesObjected = [
                        { image: latestEventOrVoucher.image, pushKey: latestEventOrVoucher.pushKey },
                    ];

                    this.setState({ latestEventOrVoucher })
                }
            }
            if (arrCompleteVouchersEvents.length > 0) {
                let sortedCampaigns = [...arrCompleteVouchersEvents].filter((campaign) => {
                    return !campaign.takeFromAdmin
                }).sort((a, b) => {
                    return b.timestamp * 100 - a.timestamp * 100;
                });
                if (sortedCampaigns.length > 1) {
                    this.setState({ latestEventOrVoucherSecond: sortedCampaigns[1] })
                }
            }
        }

        let companyPageText = ""

        if (this.props.global_values) {
            let a = this.props.global_values;
            if (a) {
                const {
                    memberFirstHeading,
                    memberSecondHeading,
                    dashboardMemberDesc,
                    memberDashboardMobileUrl,
                    memberDashboardMobileType,
                    memberDashboardDesktopUrl,
                    memberDashboardDesktopType,
                    memberDashboardDescription,
                    memberLandingPageText,
                    dailyNewAddedText,
                    bhodiNewsHeading,
                    bhodiNewsText,
                } = a;

                if (userid) {
                    companyPageText = "Ontdek Bhodi en maak gebruik van allerlei leuke pop-up events en acties"
                } else {
                    companyPageText = a.companyPageText;

                    if (companyPageText === "Meld je aan als Bhodi charity member en krijg toegang tot allerlei leuke events en actiesgetext" && window.innerWidth < 1008) {
                        companyPageText = "Meld je aan als Bhodi charity member en krijg toegang tot allerlei events en actiesgetext"
                    }
                }

                let charityImage = ""
                let charityVideo = ""

                if (a.charity_image) {
                    charityImage = a.charity_image
                    charityVideo = ""
                    // this.setState({ charityImage: a.charity_image, charityVideo: "" });
                } else {
                    charityVideo = a.charity_video
                    charityImage = ""
                    // this.setState({ charityVideo: a.charity_video, charityImage: "" });
                }

                this.setState({
                    memberFirstHeading: memberFirstHeading || "",
                    memberSecondHeading: memberSecondHeading || "",
                    dashboardMemberDesc: dashboardMemberDesc || "",
                    memberDashboardMobileUrl: memberDashboardMobileUrl || "",
                    memberDashboardMobileType: memberDashboardMobileType || "",
                    memberDashboardDesktopUrl: memberDashboardDesktopUrl || "",
                    memberDashboardDesktopType: memberDashboardDesktopType || "",
                    companyPageText: companyPageText || "",
                    memberDashboardDescription: memberDashboardDescription || "",
                    memberLandingPageText: memberLandingPageText || "",
                    dailyNewAddedText: dailyNewAddedText || "",
                    bhodiNewsHeading: bhodiNewsHeading || "",
                    bhodiNewsText: bhodiNewsText || "",
                    charityImage,
                    charityVideo,
                });
            }
        } else {
            if (userid) {
                companyPageText = "Ontdek Bhodi en maak gebruik van allerlei leuke pop-up events en acties"
            } else {
                if (window.innerWidth > 1008) {
                    companyPageText = "Meld je aan als Bhodi charity member en krijg toegang tot allerlei leuke events en actiesgetext"
                } else {
                    companyPageText = "Meld je aan als Bhodi charity member en krijg toegang tot allerlei events en actiesgetext"
                }
            }

            this.setState({
                companyPageText: companyPageText
            })
        }

        if (this.props.currentMemberData) {
            let companyData = this.props.currentMemberData

            let availableCompaniesProducts = []
            if (companyData.type !== "company" && userid && companyData.user_id === userid) {
                let allSubscriptions = []
                if (companyData.subscribed_for_products) {
                    let objected = Object.entries(companyData.subscribed_for_products)
                    if (objected.length > 0) {
                        objected.map((t) => {
                            allSubscriptions.push(t[1].productsOfCompany)
                        })
                    }
                    if (allSubscriptions.length > 0) {
                        allSubscriptions.map((t) => {
                            let filtered = this.props.user.filter((g) => {
                                return g.user_id === t
                            })
                            if (filtered.length > 0) {
                                if (filtered[0].announcement.showAnnouncementWithoutTiming) {
                                    return false;
                                }
                                availableCompaniesProducts.push(t)
                                //         if (filtered[0].announcement && filtered[0].announcement.active) {
                                //     if (new Date(Date.parse(filtered[0].announcement.end_date_iso)).getTime() < new Date().getTime()) {
                                //         availableCompaniesProducts.push(t)
                                //     }
                                // } else {
                                //     availableCompaniesProducts.push(t)
                                // }
                            }
                        })
                        availableCompaniesProducts = availableCompaniesProducts.filter(
                            (v, i, a) => a.findIndex((t) => t === v) === i
                        );
                    }
                }
            }

            let noBannerImage = false
            let noBannerImageMobile = false
            let noBannerImageContact = false
            let noBannerImageMobileContact = false
            let bannerOpacity = 0.2
            let contactImageIsVideo = false
            let contactImageIsVideoMobile = false
            let companyBannerType = "uploadfromcomp"
            let companyBannerTypeMobile = "uploadfromcomp"
            let companyAboutType = "uploadfromcomp"
            let companyAboutTypeMobile = "uploadfromcomp"

            if (!companyData.banner_image) {
                noBannerImage = true
            }
            if (!companyData.banner_image_mobile) {
                noBannerImageMobile = true
            }
            if (companyData.company_banner_opacity) {
                bannerOpacity = companyData.company_banner_opacity
            }
            if (companyData.contactImageIsVideo) {
                contactImageIsVideo = true
            }
            if (companyData.contactImageIsVideoMobile) {
                contactImageIsVideoMobile = true
            }
            if (companyData.company_banner_type) {
                companyBannerType = companyData.company_banner_type
            }
            if (companyData.company_banner_type_mobile) {
                companyBannerTypeMobile = companyData.company_banner_type_mobile
            }
            if (companyData.company_about_type) {
                companyAboutType = companyData.company_about_type
            }
            if (companyData.company_about_type_mobile) {
                companyAboutTypeMobile = companyData.company_about_type_mobile
            }
            if (!companyData.contact_image) {
                noBannerImageContact = true
            }
            if (!companyData.contact_image_mobile) {
                noBannerImageMobileContact = true
            }

            this.setState({
                noBannerImage,
                noBannerImageMobile,
                bannerOpacity,
                bannerImage: companyData.banner_image,
                bannerImageMobile: companyData.banner_image_mobile,
                bannerVideo: companyData.banner_video,
                bannerVideoMobile: companyData.banner_video_mobile,
                companyBannerSlogan: companyData.company_banner_slogan ? companyData.company_banner_slogan : companyPageText,
                companySecondHeading: companyData.company_second_heading || "",
                companyBannerType,
                companyBannerTypeMobile,
                company_about: companyData.company_about,
                contactImage: companyData.contact_image,
                contactImageMobile: companyData.contact_image_mobile,
                contactImageIsVideo,
                contactImageIsVideoMobile,
                companyBannerType,
                companyBannerTypeMobile,
                companyAboutType,
                companyAboutTypeMobile,
                currentUser: companyData,
                noBannerImageContact,
                noBannerImageMobileContact,
                availableCompaniesProducts,
                hideContactInfo: companyData.hideContactInfo || false,
            });
        }

        if (window.innerWidth > 1008) {
            this.setState({ isDesktop: true })
        } else {
            this.setState({ isDesktop: false })
        }
    }

    componentWillUnmount() {
        if (document.querySelector(".navbarfullwidthMobile")) {
            document.querySelector(".navbarfullwidthMobile").style.overflowX = "auto"
        }

        if (document.querySelector(".nav-fullwidth")) {
            document.querySelector(".nav-fullwidth").classList.add("navbarfixedpositionupdatedmember")
        }

        // window.removeEventListener("storage", this.handleStorageChange)
    }

    componentDidUpdate(prevProps) {
        // if (prevProps.all_impressies !== this.props.all_impressies) {
        //     if (this.props.all_impressies.length > 0) {
        //         this.setState({ impressiePhotos: [] })
        //         let filtered = this.props.all_impressies.filter((h) => {
        //             return h[0] === this.props.member_user_id
        //         })
        //         if (filtered.length > 0) {
        //             let objected = Object.entries(filtered[0][1])
        //             let arr = []
        //             objected.map((g) => {
        //                 arr.push(g[1])
        //             })
        //             this.setState({ impressiePhotos: arr })
        //         } else {
        //             this.setState({ impressiePhotos: [] });
        //         }
        //     } else {
        //         this.setState({ impressiePhotos: [] })
        //     }
        // }

        // if (this.props.bhodi_products !== prevProps.bhodi_products && this.state.currentUserId) {
        //     let filtered = this.props.bhodi_products.filter((g) => {
        //         return g.created_by === process.env.REACT_APP_ADMIN_ID && g.is_bookmarked
        //     })
        //     if (filtered.length > 0) {
        //         let indexOfImage = 0

        //         let allImagesNew = []
        //         if (filtered[0].images && filtered[0].images.length > 0) {
        //             let duplicated = Array.from(filtered[0].images)

        //             let allImagesUrl = []
        //             duplicated.map((g) => {
        //                 if (g) {
        //                     let filePath = g.split("/")[7].split("?")[0]
        //                     allImagesUrl.push(filePath)
        //                 }
        //             })

        //             allImagesUrl.sort().map((t) => {
        //                 let filtered = duplicated.filter((g) => {
        //                     return g.includes(t)
        //                 })

        //                 if (filtered.length > 0) {
        //                     allImagesNew.push(filtered[0])
        //                 }
        //             })
        //             if (typeof filtered[0].featuredImage !== "undefined" && filtered[0].featuredImage !== null) {
        //                 if (filtered[0].featuredImage < filtered[0].images.length) {
        //                     let filteredIndex = allImagesNew.findIndex((g) => {
        //                         return g === filtered[0].images[filtered[0].featuredImage]
        //                     })
        //                     if (filteredIndex !== -1) {
        //                         indexOfImage = filteredIndex
        //                     }
        //                 }
        //             }
        //         }

        //         filtered[0].allImagesNew = allImagesNew
        //         filtered[0].indexOfImage = indexOfImage

        //         this.setState({ adminProduct: filtered[0] })
        //     }

        //     // let filteredProducts = this.props.bhodi_products.filter((t) => {
        //     //     return !t.availableOnTime && t.images && !t.is_bookmarked && !t.isSpecialProduct && !t.availableForCompanyGuest && t.created_by === process.env.REACT_APP_ADMIN_ID
        //     // })
        //     // let sortedProducts = [...filteredProducts].sort((a, b) => {
        //     //     return b.timestamp * 100 - a.timestamp * 100;
        //     // });
        //     // if (sortedProducts.length > 0) {
        //     //     this.setState({ allFourProducts: [...sortedProducts].splice(0, 4) })
        //     // }

        // }

        if (this.props.stock_images !== prevProps.stock_images) {
            if (!isEqual(this.props.stock_images, this.state.allImagesStock)) {
                // if (JSON.stringify(this.props.stock_images) !== JSON.stringify(this.state.allImagesStock)) {
                this.setState({ allImagesStock: this.props.stock_images })
            }
        }

        if (this.props.globalsectors !== prevProps.globalsectors) {
            if (this.props.globalsectors.length > 0) {
                if (!isEqual(this.props.globalsectors, this.state.allSectors)) {
                    // if (JSON.stringify(this.props.globalsectors) !== JSON.stringify(this.state.allSectors)) {
                    let arr = this.props.globalsectors;
                    this.setState({ allSectors: arr });
                }
            }
        }

        if (this.props.global_campaigns !== prevProps.global_campaigns) {
            let arrCompleteEvents = []
            let arrCompleteVouchers = []
            let arrCompleteWebShop = []
            let arrCompleteVouchersEvents = []
            let userid = localStorage.getItem("userid")
            let toDoFilter = this.props.global_campaigns
            if (this.props.atCompanyPage) {
                toDoFilter = this.props.global_campaigns.filter((g) => {
                    return g[1].created_by === this.props.member_user_id
                })
            }
            toDoFilter.map((t) => {
                let g = t[1]
                if (g.campaignType === "local_event") {
                    if (g.hideOnGlobalEvent) {
                        if (g.created_by !== this.props.member_user_id) {
                            if (userid) {
                                if (g.created_by !== userid) {
                                    return false;
                                }
                            }
                        }
                    }
                    if (g.eventForGuestUser) {
                        if (userid) {
                            if (g.created_by !== userid) {
                                return false;
                            }
                        }
                    }
                    if (g.takeFromAdmin) {
                        let obj = mergeAdminEvent(g, this.props.global_campaigns)
                        g = Object.assign({}, obj)
                    }
                    let arrTimingsAvailable = [];
                    if (g.timings) {
                        g.timings.map((e) => {
                            if (e.isoStartTime && e.isoEndTime) {
                                let checkedInOfCurrentBlock = [];
                                if (g.checked_in_by) {
                                    Object.entries(g.checked_in_by).map((t) => {
                                        if (t[1].event_id === e.eventId) {
                                            checkedInOfCurrentBlock.push(t[1]);
                                        }
                                    });
                                }
                                let getCurrentDate = new Date();
                                if (getCurrentDate.getTime() < new Date(Date.parse(g.timings[0].isoEndTime)).getTime()) {
                                    if (checkedInOfCurrentBlock.length < Number(e.personsAmountEvent)) {
                                        arrTimingsAvailable.push(e);
                                    }
                                }
                            }
                        });
                    }
                    if (g.created_by === userid) {
                        arrCompleteEvents.push(g);
                    } else if (arrTimingsAvailable.length > 0) {
                        if (g.active && g.created_by !== process.env.REACT_APP_ADMIN_ID) {
                            arrCompleteEvents.push(g);
                            arrCompleteVouchersEvents.push(g)
                        }
                    }
                }
                if (g.campaignType === "kortings_voucher") {
                    if (g.created_by === userid) {
                        arrCompleteVouchers.push(g);
                    } else {
                        if (g.active) {
                            arrCompleteVouchers.push(g);
                            arrCompleteVouchersEvents.push(g)
                        }
                    }
                }
                if (g.campaignType === "web_shop") {
                    if (g.created_by === userid) {
                        arrCompleteWebShop.push(g);
                    } else {
                        if (!userid) {
                            if (!g.hideForGuestUsers) {
                                arrCompleteWebShop.push(g);
                                // arrCompleteVouchersEvents.push(g)
                            }
                        } else {
                            arrCompleteWebShop.push(g);
                            // arrCompleteVouchersEvents.push(g)
                        }
                    }
                }
            })

            if (arrCompleteEvents.length > 0) {
                let sortedCampaigns = arrCompleteEvents.sort((a, b) => {
                    return b.timestamp * 100 - a.timestamp * 100;
                });
                if (sortedCampaigns.length > 0) {
                    this.setState({ currentCampaignToShow: sortedCampaigns[0] })
                } else {
                    this.setState({ currentCampaignToShow: "" })
                }
            } else {
                this.setState({ currentCampaignToShow: "" })
            }

            if (arrCompleteVouchers.length > 0) {
                let sortedCampaigns = arrCompleteVouchers.sort((a, b) => {
                    return b.timestamp * 100 - a.timestamp * 100;
                });
                if (sortedCampaigns.length > 0) {
                    this.setState({ currentCampaignToShowVoucher: sortedCampaigns[0] })
                } else {
                    this.setState({ currentCampaignToShowVoucher: "" })
                }
            } else {
                this.setState({ currentCampaignToShowVoucher: "" })
            }

            if (arrCompleteWebShop.length > 0) {
                let sortedCampaigns = arrCompleteWebShop.sort((a, b) => {
                    return b.timestamp * 100 - a.timestamp * 100;
                });
                if (sortedCampaigns.length > 0) {
                    if (sortedCampaigns.length !== this.state.currentCampaignToShowWebShop.length) {
                        this.sectionRefWebShop = []
                        this.setState({ currentCampaignToShowWebShop: [] })
                        setTimeout(() => {
                            this.setState({ currentCampaignToShowWebShop: sortedCampaigns })
                        }, 400);
                    } else {
                        this.setState({ currentCampaignToShowWebShop: sortedCampaigns })
                    }
                } else {
                    this.setState({ currentCampaignToShowWebShop: [] })
                }
            } else {
                this.setState({ currentCampaignToShowWebShop: [] })
            }
            // else {
            //     this.setState({ latestEventOrVoucher: "" })
            // }

            if (this.state.latestEventOrVoucher && window.innerWidth > 1008) {
                let sortedCampaigns = this.props.global_campaigns.filter((g) => {
                    return g[1].pushKey === this.state.latestEventOrVoucher.pushKey
                })
                if (sortedCampaigns.length > 0) {
                    let latestEventOrVoucher = [...sortedCampaigns][0][1]
                    latestEventOrVoucher.type = "campaign";
                    latestEventOrVoucher.currentImageIndex = 0;

                    if (latestEventOrVoucher.desc) {
                        latestEventOrVoucher.short_desc = latestEventOrVoucher.desc.substr(0, 32);
                    }
                    latestEventOrVoucher.images = [latestEventOrVoucher.image];
                    latestEventOrVoucher.imagesObjected = [
                        { image: latestEventOrVoucher.image, pushKey: latestEventOrVoucher.pushKey },
                    ];

                    this.setState({ latestEventOrVoucher })
                }
            }
            if (this.state.latestEventOrVoucherSecond && window.innerWidth > 1008) {
                let sortedCampaigns = this.props.global_campaigns.filter((g) => {
                    return g[1].pushKey === this.state.latestEventOrVoucherSecond.pushKey
                })
                if (sortedCampaigns.length > 1) {
                    let latestEventOrVoucherSecond = [...sortedCampaigns][1][1]
                    latestEventOrVoucherSecond.type = "campaign";
                    latestEventOrVoucherSecond.currentImageIndex = 0;

                    if (latestEventOrVoucherSecond.desc) {
                        latestEventOrVoucherSecond.short_desc = latestEventOrVoucherSecond.desc.substr(0, 32);
                    }
                    latestEventOrVoucherSecond.images = [latestEventOrVoucherSecond.image];
                    latestEventOrVoucherSecond.imagesObjected = [
                        { image: latestEventOrVoucherSecond.image, pushKey: latestEventOrVoucherSecond.pushKey },
                    ];

                    this.setState({ latestEventOrVoucherSecond })
                }
            }
        }

        if (this.props.global_values !== prevProps.global_values) {
            let a = this.props.global_values;
            if (a) {
                const {
                    memberFirstHeading,
                    memberSecondHeading,
                    dashboardMemberDesc,
                    memberDashboardMobileUrl,
                    memberDashboardMobileType,
                    memberDashboardDesktopUrl,
                    memberDashboardDesktopType,
                    memberDashboardDescription,
                    memberLandingPageText,
                    dailyNewAddedText,
                    bhodiNewsHeading,
                    bhodiNewsText,
                } = a;

                let charityImage = ""
                let charityVideo = ""

                if (a.charity_image) {
                    charityImage = a.charity_image
                    charityVideo = ""
                    // this.setState({ charityImage: a.charity_image, charityVideo: "" });
                } else {
                    charityVideo = a.charity_video
                    charityImage = ""
                    // this.setState({ charityVideo: a.charity_video, charityImage: "" });
                }

                this.setState({
                    memberFirstHeading: memberFirstHeading || "",
                    memberSecondHeading: memberSecondHeading || "",
                    dashboardMemberDesc: dashboardMemberDesc || "",
                    memberDashboardMobileUrl: memberDashboardMobileUrl || "",
                    memberDashboardMobileType: memberDashboardMobileType || "",
                    memberDashboardDesktopUrl: memberDashboardDesktopUrl || "",
                    memberDashboardDesktopType: memberDashboardDesktopType || "",
                    memberLandingPageText: memberLandingPageText || "",
                    memberDashboardDescription: memberDashboardDescription || "",
                    dailyNewAddedText: dailyNewAddedText || "",
                    bhodiNewsHeading: bhodiNewsHeading || "",
                    bhodiNewsText: bhodiNewsText || "",
                    charityImage,
                    charityVideo,
                });
            }
        }

        if (this.props.user !== prevProps.user) {
            let userid = localStorage.getItem("userid")
            let currentuser = this.props.user.filter((g) => {
                return g.user_id === userid
            })
            if (currentuser.length > 0) {
                let companyData = currentuser[0]

                let availableCompaniesProducts = []
                if (companyData.type !== "company" && userid) {
                    let allSubscriptions = []
                    if (companyData.subscribed_for_products) {
                        let objected = Object.entries(companyData.subscribed_for_products)
                        if (objected.length > 0) {
                            objected.map((t) => {
                                allSubscriptions.push(t[1].productsOfCompany)
                            })
                        }
                        if (allSubscriptions.length > 0) {
                            allSubscriptions.map((t) => {
                                let filtered = this.props.user.filter((g) => {
                                    return g.user_id === t
                                })
                                if (filtered.length > 0) {
                                    if (filtered[0].announcement.showAnnouncementWithoutTiming) {
                                        return false;
                                    }
                                    availableCompaniesProducts.push(t)
                                    // if (filtered[0].announcement && filtered[0].announcement.active) {
                                    //     if (new Date(Date.parse(filtered[0].announcement.end_date_iso)).getTime() < new Date().getTime()) {
                                    //         availableCompaniesProducts.push(t)
                                    //     }
                                    // } else {
                                    //     availableCompaniesProducts.push(t)
                                    // }
                                }
                            })
                            availableCompaniesProducts = availableCompaniesProducts.filter(
                                (v, i, a) => a.findIndex((t) => t === v) === i
                            );
                        }
                    }
                }

                if (!isEqual(availableCompaniesProducts, this.state.availableCompaniesProducts)) {
                    this.setState({
                        availableCompaniesProducts
                    })
                }
            }
        }


        if (this.props.currentMemberData !== prevProps.currentMemberData) {
            let companyData = this.props.currentMemberData

            let noBannerImage = false
            let noBannerImageMobile = false
            let bannerOpacity = 0.2
            let contactImageIsVideo = false
            let contactImageIsVideoMobile = false
            let companyBannerType = "uploadfromcomp"
            let companyBannerTypeMobile = "uploadfromcomp"
            let companyAboutType = "uploadfromcomp"
            let companyAboutTypeMobile = "uploadfromcomp"
            let noBannerImageContact = false
            let noBannerImageMobileContact = false

            if (!companyData.banner_image) {
                noBannerImage = true
            }
            if (!companyData.banner_image_mobile) {
                noBannerImageMobile = true
            }

            if (companyData.company_banner_opacity) {
                bannerOpacity = companyData.company_banner_opacity
            }
            if (companyData.contactImageIsVideo) {
                contactImageIsVideo = true
            }
            if (companyData.contactImageIsVideoMobile) {
                contactImageIsVideoMobile = true
            }
            if (companyData.company_banner_type) {
                companyBannerType = companyData.company_banner_type
            }
            if (companyData.company_banner_type_mobile) {
                companyBannerTypeMobile = companyData.company_banner_type_mobile
            }
            if (companyData.company_about_type) {
                companyAboutType = companyData.company_about_type
            }
            if (companyData.company_about_type_mobile) {
                companyAboutTypeMobile = companyData.company_about_type_mobile
            }
            if (!companyData.contact_image) {
                noBannerImageContact = true
            }
            if (!companyData.contact_image_mobile) {
                noBannerImageMobileContact = true
            }

            this.setState({
                noBannerImage,
                noBannerImageMobile,
                bannerOpacity,
                bannerImage: companyData.banner_image,
                bannerImageMobile: companyData.banner_image_mobile,
                bannerVideo: companyData.banner_video,
                bannerVideoMobile: companyData.banner_video_mobile,
                companyBannerSlogan: companyData.company_banner_slogan ? companyData.company_banner_slogan : this.state.companyPageText,
                companySecondHeading: companyData.company_second_heading || "",
                companyBannerType,
                companyBannerTypeMobile,
                company_about: companyData.company_about,
                contactImage: companyData.contact_image,
                contactImageMobile: companyData.contact_image_mobile,
                contactImageIsVideo,
                contactImageIsVideoMobile,
                currentUser: companyData,
                companyAboutType,
                companyAboutTypeMobile,
                noBannerImageContact,
                noBannerImageMobileContact,
                hideContactInfo: companyData.hideContactInfo || false,
            });
        }
    }

    createMarkupDesc() {
        if (this.state.dashboardMemberDesc) {
            return { __html: this.state.dashboardMemberDesc };
        }
    }

    uploadBannerImage(e) {
        let uid = localStorage.getItem("userid");
        let a = e.target.files[0];
        if (!this.state.showDesktopLanding) {
            firebase
                .database()
                .ref("users/" + uid + "/company_banner_type_mobile")
                .set(this.state.companyBannerTypeMobile);
            if (a.type.match(/image.*/)) {
                if (a.size / 1024 / 1024 < 3) {
                    this.setState({ bannerImageBigger: false });
                    firebase
                        .storage()
                        .ref()
                        .child(`companyimagesmobile/${a.name}${new Date().getTime()}`)
                        .put(a)
                        .then((snapshot) => {
                            snapshot.ref.getDownloadURL().then((snapUrl) => {
                                firebase
                                    .database()
                                    .ref("users/" + uid + "/banner_image_mobile")
                                    .set(snapUrl);
                                firebase
                                    .database()
                                    .ref("users/" + uid + "/banner_video_mobile")
                                    .set("");
                            });
                        });
                } else {
                    this.setState({ bannerImageBigger: true });
                }
            } else {
                if (a.size / 1024 / 1024 < 15) {
                    this.setState({ bannerImageBigger: false });
                    firebase
                        .storage()
                        .ref()
                        .child(`companyimagesmobile/${a.name}${new Date().getTime()}`)
                        .put(a)
                        .then((snapshot) => {
                            snapshot.ref.getDownloadURL().then((snapUrl) => {
                                firebase
                                    .database()
                                    .ref("users/" + uid + "/banner_video_mobile")
                                    .set(snapUrl);
                                firebase
                                    .database()
                                    .ref("users/" + uid + "/banner_image_mobile")
                                    .set("");
                            });
                        });
                } else {
                    this.setState({ bannerImageBigger: true });
                }
            }
            return false;
        }
        firebase
            .database()
            .ref("users/" + uid + "/company_banner_type")
            .set(this.state.companyBannerType);
        if (a.type.match(/image.*/)) {
            if (a.size / 1024 / 1024 < 3) {
                this.setState({ bannerImageBigger: false });
                firebase
                    .storage()
                    .ref()
                    .child(`companyimages/${a.name}${new Date().getTime()}`)
                    .put(a)
                    .then((snapshot) => {
                        snapshot.ref.getDownloadURL().then((snapUrl) => {
                            firebase
                                .database()
                                .ref("users/" + uid + "/banner_image")
                                .set(snapUrl);
                            firebase
                                .database()
                                .ref("users/" + uid + "/banner_video")
                                .set("");
                        });
                    });
            } else {
                this.setState({ bannerImageBigger: true });
            }
        } else {
            if (a.size / 1024 / 1024 < 15) {
                this.setState({ bannerImageBigger: false });
                firebase
                    .storage()
                    .ref()
                    .child(`companyimages/${a.name}${new Date().getTime()}`)
                    .put(a)
                    .then((snapshot) => {
                        snapshot.ref.getDownloadURL().then((snapUrl) => {
                            firebase
                                .database()
                                .ref("users/" + uid + "/banner_video")
                                .set(snapUrl);
                            firebase
                                .database()
                                .ref("users/" + uid + "/banner_image")
                                .set("");
                        });
                    });
            } else {
                this.setState({ bannerImageBigger: true });
            }
        }
    }


    uploadContactImage(e) {
        let uid = localStorage.getItem("userid");
        let a = e.target.files[0];
        if (!this.state.showDesktopLandingContact) {
            firebase
                .database()
                .ref("users/" + uid + "/company_about_type_mobile")
                .set(this.state.companyAboutTypeMobile);
            if (a.type.match(/image.*/)) {
                if (a.size / 1024 / 1024 < 3) {
                    this.setState({ bannerImageBigger: false });
                    firebase
                        .storage()
                        .ref()
                        .child(`companyimagesmobile/${a.name}${new Date().getTime()}`)
                        .put(a)
                        .then((snapshot) => {
                            snapshot.ref.getDownloadURL().then((snapUrl) => {
                                firebase
                                    .database()
                                    .ref("users/" + uid + "/contact_image_mobile")
                                    .set(snapUrl);
                                firebase
                                    .database()
                                    .ref("users/" + uid + "/contactImageIsVideoMobile")
                                    .set(false);
                            });
                        });
                } else {
                    this.setState({ contactImageBigger: true });
                }
            } else {
                if (a.size / 1024 / 1024 < 15) {
                    this.setState({ bannerImageBigger: false });
                    firebase
                        .storage()
                        .ref()
                        .child(`companyimagesmobile/${a.name}${new Date().getTime()}`)
                        .put(a)
                        .then((snapshot) => {
                            snapshot.ref.getDownloadURL().then((snapUrl) => {
                                firebase
                                    .database()
                                    .ref("users/" + uid + "/contact_image_mobile")
                                    .set(snapUrl);
                                firebase
                                    .database()
                                    .ref("users/" + uid + "/contactImageIsVideoMobile")
                                    .set(true);
                            });
                        });
                } else {
                    this.setState({ contactImageBigger: true });
                }
            }
            return false;
        }
        firebase
            .database()
            .ref("users/" + uid + "/company_about_type")
            .set(this.state.companyAboutType);
        if (a.type.match(/image.*/)) {
            if (a.size / 1024 / 1024 < 3) {
                this.setState({ contactImageBigger: false });
                firebase
                    .storage()
                    .ref()
                    .child(`companyimages/${a.name}${new Date().getTime()}`)
                    .put(a)
                    .then((snapshot) => {
                        snapshot.ref.getDownloadURL().then((snapUrl) => {
                            firebase
                                .database()
                                .ref("users/" + uid + "/contact_image")
                                .set(snapUrl);
                            firebase
                                .database()
                                .ref("users/" + uid + "/contactImageIsVideo")
                                .set(false);
                        });
                    });
            } else {
                this.setState({ contactImageBigger: true });
            }
        } else {
            if (a.size / 1024 / 1024 < 15) {
                this.setState({ contactImageBigger: false });
                firebase
                    .storage()
                    .ref()
                    .child(`companyimages/${a.name}${new Date().getTime()}`)
                    .put(a)
                    .then((snapshot) => {
                        snapshot.ref.getDownloadURL().then((snapUrl) => {
                            firebase
                                .database()
                                .ref("users/" + uid + "/contact_image")
                                .set(snapUrl);
                            firebase
                                .database()
                                .ref("users/" + uid + "/contactImageIsVideo")
                                .set(true);
                        });
                    });
            } else {
                this.setState({ contactImageBigger: true });
            }
        }
    }

    checkForFollowText() {
        if (this.props.currentMemberData.followed_by) {
            let objected = Object.entries(this.props.currentMemberData.followed_by);
            let filtered = objected.filter((g) => {
                return g[1].user_id === this.state.currentUserId;
            });
            if (filtered.length > 0) {
                if (this.props.currentMemberData.friends) {
                    let objectedFriends = Object.entries(
                        this.props.currentMemberData.friends
                    );
                    let filteredFriends = objectedFriends.filter((g) => {
                        return g[1].user_id === this.state.currentUserId;
                    });
                    if (filteredFriends.length === 0) {
                        return <font>Ontvolgen</font>;
                    }
                } else {
                    return <font>Ontvolgen</font>;
                }
            } else {
                if (this.props.currentMemberData.friends) {
                    let objectedFriends = Object.entries(
                        this.props.currentMemberData.friends
                    );
                    let filteredFriends = objectedFriends.filter((g) => {
                        return g[1].user_id === this.state.currentUserId;
                    });
                    if (filteredFriends.length === 0) {
                        return <font>Volgen</font>;
                    }
                } else {
                    return <font>Volgen</font>;
                }
            }
        } else {
            if (this.props.currentMemberData.friends) {
                let objectedFriends = Object.entries(
                    this.props.currentMemberData.friends
                );
                let filteredFriends = objectedFriends.filter((g) => {
                    return g[1].user_id === this.state.currentUserId;
                });
                if (filteredFriends.length === 0) {
                    return <font>Volgen</font>;
                }
            } else {
                return <font>Volgen</font>;
            }
        }
    }

    followCompany() {
        followUnfollowCompany(this.props.currentMemberData, this.state.currentUserId, this.props.user)
    }

    // followCompany() {
    //     if (this.props.currentMemberData.followed_by) {
    //         let objected = Object.entries(this.props.currentMemberData.followed_by);
    //         let filtered = objected.filter((g) => {
    //             return g[1].user_id === this.state.currentUserId;
    //         });
    //         if (filtered.length > 0) {
    //             if (this.props.currentMemberData.newFollowersList) {
    //                 let objectedSec = Object.entries(
    //                     this.props.currentMemberData.newFollowersList
    //                 );
    //                 let filteredNewFollowers = objectedSec.filter((g) => {
    //                     return g[1].user_id === this.state.currentUserId;
    //                 });
    //                 if (filteredNewFollowers.length > 0) {
    //                     let newFollowers = Number(
    //                         this.props.currentMemberData.newFollowers
    //                     );
    //                     newFollowers = newFollowers - 1;
    //                     firebase
    //                         .database()
    //                         .ref(
    //                             "users/" +
    //                             this.props.currentMemberData.user_id +
    //                             "/newFollowersList/" +
    //                             filteredNewFollowers[0][1].push_key
    //                         )
    //                         .remove();
    //                     firebase
    //                         .database()
    //                         .ref(
    //                             "users/" +
    //                             this.props.currentMemberData.user_id +
    //                             "/newFollowers"
    //                         )
    //                         .set(newFollowers);
    //                 }
    //             }
    //             let filteredCurrentUser = this.props.user.filter((h) => {
    //                 return h.user_id === this.state.currentUserId;
    //             });
    //             if (filteredCurrentUser[0].following) {
    //                 let objectedSec = Object.entries(filteredCurrentUser[0].following);
    //                 let filteredSec = objectedSec.filter((g) => {
    //                     return g[1].user_id === this.props.currentMemberData.user_id;
    //                 });
    //                 if (filteredSec.length > 0) {
    //                     firebase
    //                         .database()
    //                         .ref(
    //                             "users/" +
    //                             this.state.currentUserId +
    //                             "/following/" +
    //                             filteredSec[0][1].push_key
    //                         )
    //                         .remove();
    //                 }
    //             }
    //             firebase
    //                 .database()
    //                 .ref(
    //                     "users/" +
    //                     this.props.currentMemberData.user_id +
    //                     "/followed_by/" +
    //                     filtered[0][1].push_key
    //                 )
    //                 .remove();
    //         } else {
    //             let pushFirst = firebase
    //                 .database()
    //                 .ref("users/" + this.state.currentUserId + "/following")
    //                 .push().key;
    //             let pushSecond = firebase
    //                 .database()
    //                 .ref("users/" + this.props.currentMemberData.user_id + "/followed_by")
    //                 .push().key;
    //             let pushSecondNew = firebase
    //                 .database()
    //                 .ref(
    //                     "users/" +
    //                     this.props.currentMemberData.user_id +
    //                     "/newFollowersList"
    //                 )
    //                 .push().key;
    //             let newFollowers = 0;
    //             let obj = {
    //                 user_id: this.props.currentMemberData.user_id,
    //                 push_key: pushFirst,
    //             };
    //             let objSec = {
    //                 user_id: this.state.currentUserId,
    //                 push_key: pushSecond,
    //             };
    //             let objThird = {
    //                 user_id: this.state.currentUserId,
    //                 push_key: pushSecondNew,
    //             };
    //             if (this.props.currentMemberData.newFollowers) {
    //                 newFollowers = Number(this.props.currentMemberData.newFollowers);
    //             }
    //             newFollowers = newFollowers + 1;
    //             firebase
    //                 .database()
    //                 .ref("users/" + this.state.currentUserId + "/following/" + pushFirst)
    //                 .set(obj);
    //             firebase
    //                 .database()
    //                 .ref(
    //                     "users/" +
    //                     this.props.currentMemberData.user_id +
    //                     "/followed_by/" +
    //                     pushSecond
    //                 )
    //                 .set(objSec);
    //             firebase
    //                 .database()
    //                 .ref(
    //                     "users/" + this.props.currentMemberData.user_id + "/newFollowers"
    //                 )
    //                 .set(newFollowers);
    //             firebase
    //                 .database()
    //                 .ref(
    //                     "users/" +
    //                     this.props.currentMemberData.user_id +
    //                     "/newFollowersList/" +
    //                     pushSecondNew
    //                 )
    //                 .set(objThird);
    //         }
    //     } else {
    //         let pushFirst = firebase
    //             .database()
    //             .ref("users/" + this.state.currentUserId + "/following")
    //             .push().key;
    //         let pushSecond = firebase
    //             .database()
    //             .ref("users/" + this.props.currentMemberData.user_id + "/followed_by")
    //             .push().key;
    //         let pushSecondNew = firebase
    //             .database()
    //             .ref(
    //                 "users/" + this.props.currentMemberData.user_id + "/newFollowersList"
    //             )
    //             .push().key;
    //         let newFollowers = 0;
    //         let obj = {
    //             user_id: this.props.currentMemberData.user_id,
    //             push_key: pushFirst,
    //         };
    //         let objSec = {
    //             user_id: this.state.currentUserId,
    //             push_key: pushSecond,
    //         };
    //         let objThird = {
    //             user_id: this.state.currentUserId,
    //             push_key: pushSecondNew,
    //         };
    //         if (this.props.currentMemberData.newFollowers) {
    //             newFollowers = Number(this.props.currentMemberData.newFollowers);
    //         }
    //         newFollowers = newFollowers + 1;
    //         firebase
    //             .database()
    //             .ref("users/" + this.state.currentUserId + "/following/" + pushFirst)
    //             .set(obj);
    //         firebase
    //             .database()
    //             .ref(
    //                 "users/" +
    //                 this.props.currentMemberData.user_id +
    //                 "/followed_by/" +
    //                 pushSecond
    //             )
    //             .set(objSec);
    //         firebase
    //             .database()
    //             .ref("users/" + this.props.currentMemberData.user_id + "/newFollowers")
    //             .set(newFollowers);
    //         firebase
    //             .database()
    //             .ref(
    //                 "users/" +
    //                 this.props.currentMemberData.user_id +
    //                 "/newFollowersList/" +
    //                 pushSecondNew
    //             )
    //             .set(objThird);
    //     }
    // }

    checkForCreatorName(uid) {
        let filtered = this.props.user.filter((t) => {
            return t.user_id === uid
        })
        if (filtered.length > 0) {
            return `${filtered[0].company_name}`
        }
    }

    checkForButtonProducts(e) {
        if (!e.sizesActive) {
            if (e.inCartOf || e.verzilvered_by) {
                let objectedCart = []
                let objectedVerzilvered = []

                if (e.inCartOf) {
                    objectedCart = Object.entries(e.inCartOf)
                }

                if (e.verzilvered_by) {
                    objectedVerzilvered = Object.entries(e.verzilvered_by)
                }

                if ((objectedCart.length + objectedVerzilvered.length) >= Number(e.qty)) {
                    return (<button>Uitverkocht</button>)
                }
            }
            if (Number(e.qty) === 0) {
                return <button>Uitverkocht</button>
            }
        } else {
            let stocksAvailableSizes = []
            e.variations_data.map((g) => {
                let filteredStockLength = []
                let filteredStockLengthVerzilvered = []
                if (e.inCartOf) {
                    filteredStockLength = Object.entries(e.inCartOf).filter((t) => {
                        return t[1].selectedSize === g.size
                    })
                }
                if (e.verzilvered_by) {
                    filteredStockLengthVerzilvered = Object.entries(e.verzilvered_by).filter((t) => {
                        return t[1].selectedSize === g.size
                    })
                }
                if ((filteredStockLength.length + filteredStockLengthVerzilvered.length) < Number(g.qty)) {
                    stocksAvailableSizes.push(g)
                }
            })
            if (stocksAvailableSizes.length === 0) {
                return <button>Uitverkocht</button>
            }
        }

        return (<button onClick={() => {
            history.push(`/product-detailed?key=${e.pushKey}`)
        }}>Bekijk</button>)
    }

    createRefs = (index) => {
        this.sectionRefWebShop[index] = React.createRef();
    }

    // handleStorageChange = (event) => {
    //     if (event.key === "userid") {
    //         if (event.newValue) {
    //             swal({
    //                 title: "Awesome",
    //                 text: "You have created an account, Please click OK to get a seemless experience",
    //                 icon: "success"
    //             }).then(() => {
    //                 window.location.reload()
    //             })
    //         }
    //     }
    // }

    generateQRCode() {
        // let inputString = this.state.currentUser.company_name

        // const initials = inputString
        //     .split(' ')
        //     .filter(word => word.length > 0)  // Filter out any empty words (due to extra spaces)
        //     .map(word => word[0])  // Map each word to its first letter
        //     .join('');  // Join the array of first letters into a single string

        // // Get the current time
        // const now = new Date();
        // const hours = String(now.getHours()).padStart(2, '0');  // Get hours and pad with leading zero if necessary
        // const minutes = String(now.getMinutes()).padStart(2, '0');  // Get minutes and pad with leading zero if necessary

        // // Create the time string in the format HHMM
        // const timeString = hours + minutes;

        // let number = 1001
        // if (this.state.currentUser.qr_code_number) {
        //     number = this.state.currentUser.qr_code_number
        // }

        // firebase.database().ref(`users/${this.state.currentUser.user_id}/qr_code_number`).set(number + 1)

        // // Append the number to the resulting string
        // let finalString = initials + timeString + number;

        // let query = `qr_codes/${this.state.currentUser.user_id}/${finalString}`


        // let obj = {
        //     code: finalString,
        //     is_scanned: false,
        //     created_at: new Date().toISOString(),
        //     barber_id: this.state.currentUser.user_id
        // }

        // firebase.database().ref(`${query}`).set(obj)

        // firebase.database().ref(query).on("value", (data) => {
        //     let a = data.val()
        //     if (a.is_scanned) {
        //         this.setState({ qrPopupOpen: false, qrCodeLink: "", currentQrQuery: "" })
        //         firebase.database().ref(query).off()
        //     }
        // })

        // this.setState({ qrPopupOpen: true, qrCodeLink: `${window.location.origin}/onboarding-barber-client?id=${finalString}&barber_id=${this.state.currentUser.user_name_id}`, currentQrQuery: query })

        history.push(`/company/${this.props.currentMemberData.user_name_id}/generate-qr`)
    }

    render() {
        return (
            (!this.props.atCompanyPage && this.props.member_user_id !== this.state.currentUserId) ? (<div className='member-home-new'>
                <div className='banner-chn bchn-viewer-profile'>
                    <div className='content-bchn' data-aos="zoom-in">
                        {window.innerWidth > 1008 && (<img src={this.state.currentUser.profile_image ? this.state.currentUser.profile_image : "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9"} />)}
                        <p className='second-text-cbchn'>{this.props.currentMemberData.first_name} {this.props.currentMemberData.last_name}</p>
                        <p className='third-text-cbchn'>{this.state.memberLandingPageText}</p>
                        <p className='fourth-text-cbchn'>{this.state.dailyNewAddedText}</p>
                        {/* <button onClick={() => {
                            this.followCompany()
                        }}>{this.checkForFollowText()}</button> */}
                        <div className='links-cbchn'>
                            <label onClick={() => {
                                document.querySelector("html").style.overflowY = "hidden"
                                this.setState({
                                    memberDetailedInfo: true
                                })
                            }}>Info</label>
                            {(this.state.currentUser.following) && (<label>|</label>)}
                            {(this.state.currentUser.following) && (<label onClick={() => {
                                document.querySelector("html").style.overflowY = "hidden"
                                this.setState({
                                    showRightMenuViewerFriends: true
                                })
                            }}>Vrienden</label>)}
                        </div>
                    </div>
                    <div className='overlay-bchn'>
                    </div>
                </div>


                {this.state.showRightMenuViewerFriends && (
                    <div
                        className="overlay-div-openadd"
                        onClick={() => {
                            document.querySelector("html").style.overflowY = "auto"
                            this.setState({ showRightMenuViewerFriends: false });
                        }}
                    ></div>
                )}
                {this.state.showRightMenuViewerFriends && (
                    <ViewerFriendlist
                        viewerOnUser={this.state.currentUser}
                        closePopup={() => {
                            document.querySelector("html").style.overflowY = "auto"
                            this.setState({ showRightMenuViewerFriends: false });
                        }}
                    />
                )}

                {this.state.memberDetailedInfo && (
                    <div className="loader-area mcaloader">
                        <div className="modal-create-album" style={{ width: 400 }}>
                            <div
                                className="heading-mca"
                                style={{
                                    textAlign: "center",
                                    position: "relative",
                                    padding: "13px",
                                }}
                            >
                                <font>ABOUT</font>
                                <CloseIcon
                                    onClick={() => {
                                        this.setState({ memberDetailedInfo: false });
                                    }}
                                    style={{
                                        position: "absolute",
                                        top: "50%",
                                        transform: "translateY(-50%)",
                                        right: 10,
                                        fontSize: 24,
                                    }}
                                />
                            </div>
                            <div className="body-mca groupsettingsbody">
                                <div className="form-body-mca">
                                    <div className="inner-mca-settings">
                                        <div
                                            className="mca-settings-list"
                                            style={{
                                                flexDirection: "column",
                                                backgroundColor: "transparent",
                                                height: "auto",
                                            }}
                                        >
                                            <div
                                                className="right-mcls imageinnermca"
                                                style={{
                                                    color: "white",
                                                    position: "relative",
                                                    maxWidth: 70,
                                                    margin: "0px auto",
                                                }}
                                            >
                                                {this.state.currentUser.profile_image ? (
                                                    <img
                                                        src={this.state.currentUser.profile_image}
                                                    />
                                                ) : (
                                                    <img src="https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9" />
                                                )}
                                            </div>
                                            <div
                                                className="right-mcls imageinnermca"
                                                style={{ color: "white" }}
                                            >
                                                <font>
                                                    {this.state.currentUser.first_name}{" "}
                                                    {this.state.currentUser.last_name}
                                                </font>
                                            </div>
                                            <div className="line-rcmls"></div>
                                            <div
                                                className="right-mcls"
                                                style={{ color: "white", paddingLeft: 20 }}
                                            >
                                                <EventAvailableIcon
                                                    style={{ position: "static", fontSize: 24 }}
                                                />
                                                <font>
                                                    Member sinds{" "}
                                                    {this.state.currentUser.created_on}{" "}
                                                </font>
                                            </div>
                                            <div
                                                className="right-mcls"
                                                style={{ color: "white", paddingLeft: 20 }}
                                            >
                                                <LocationOnIcon
                                                    style={{ position: "static", fontSize: 24 }}
                                                />
                                                <font>
                                                    {this.state.currentUser.city}{" "}
                                                    {this.state.currentUser.country}
                                                </font>
                                            </div>
                                            <div
                                                className="right-mcls"
                                                style={{ color: "white", paddingLeft: 20 }}
                                            >
                                                <PersonIcon
                                                    style={{ position: "static", fontSize: 24 }}
                                                />
                                                <font>
                                                    {this.state.currentUser.following ? Object.entries(this.state.currentUser.following).length : 0}{" "}
                                                    vrienden
                                                </font>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}


                {window.innerWidth > 1008 && (<Footer notOnMainPage={true} />)}
                {window.innerWidth < 1008 && (<FooterGreen notOnMainPage={true} />)}
            </div >) : <div className='member-home-new mhn-new-company-campaign'>
                {!this.state.currentUserId && this.props.currentMemberData && (
                    <GuestUserNavbar
                        onCompanyPageMain={true}
                        global_values={this.state.global_values}
                        currentUser={this.props.currentMemberData}
                        global_campaigns={this.props.global_campaigns}
                        hideRightIcons={this.props.currentMemberData.redirect_guest_to_link_via_event}
                        products_new={this.props.products_new}
                        moveToCharity={() => {
                            this.scrollToCharity()
                        }}
                        moveToContactCompany={() => {
                            this.scrollToContact()
                        }}
                    />
                )}

                {this.props.atCompanyPage && this.props.member_user_id === this.state.currentUserId && window.innerWidth > 1008 && (
                    <div className="live-event-topicons">
                        {/* {this.state.currentUser.barber_new && (<div
                            className="btn-cdd btnpopupwork"
                            style={{
                                cursor: "pointer",
                                right: window.innerWidth < 1600 ? 120 : 215,
                                marginRight: 0,
                            }}
                            onClick={() => {
                                this.generateQRCode()
                            }}
                        >
                            <AiOutlineQrcode />
                            <div
                                className="notification-bpw"
                                style={{ minWidth: 130, right: -49, left: "auto" }}
                            >
                                <p>Generate QR</p>
                            </div>
                        </div>)} */}
                        <div
                            className="btn-cdd btnpopupwork"
                            style={{
                                cursor: "pointer",
                                right: window.innerWidth < 1600 ? 120 : 215,
                                marginRight: 0,
                            }}
                            onClick={() => {
                                this.generateQRCode()
                            }}
                        >
                            <AiOutlineQrcode />
                            <div
                                className="notification-bpw"
                                style={{ minWidth: 130, right: -49, left: "auto" }}
                            >
                                <p>Generate QR</p>
                            </div>
                        </div>
                        {(<div
                            // {(!this.state.currentCampaignToShow || !this.state.currentCampaignToShowVoucher || !this.state.currentCampaignToShowWebShop) && (<div
                            className="btn-cdd btnpopupwork"
                            style={{
                                cursor: "pointer",
                                right: window.innerWidth < 1600 ? 120 : 215,
                                marginRight: 0,
                            }}
                            onClick={() => {
                                document.querySelector("html").style.overflowY = "hidden"
                                this.setState({
                                    openAddPopup: true,
                                });
                            }}
                        >
                            <AddIcon />
                            <div
                                className="notification-bpw"
                                style={{ minWidth: 130, right: -49, left: "auto" }}
                            >
                                <p>Actie toevoegen</p>
                            </div>
                        </div>)}
                        <div
                            className="btn-cdd btnpopupwork"
                            style={{
                                cursor: "pointer",
                                right: window.innerWidth < 1600 ? 120 : 215,
                                marginRight: 0,
                            }}
                            onClick={() => {
                                document.querySelector("html").style.overflowY = "hidden"

                                this.setState({
                                    showUploadPopup: true,
                                });
                            }}
                        >
                            <EditIcon />
                            <div
                                className="notification-bpw"
                                style={{ minWidth: 130, right: -49, left: "auto" }}
                            >
                                <p>Bewerk Pagina</p>
                            </div>
                        </div>
                    </div>
                )}

                {this.props.atCompanyPage ? (<div className='banner-chn' style={{ backgroundImage: this.state.isDesktop ? (!this.state.bannerImage ? "none" : `url('${this.state.bannerImage}')`) : ((this.state.bannerImageMobile || this.state.bannerImage) ? `url('${this.state.bannerImageMobile ? this.state.bannerImageMobile : this.state.bannerImage}')` : "none"), height: !this.state.isDesktop && `${this.state.bannerHeight}px` }}>
                    {this.state.isDesktop && (this.state.bannerVideo ? (
                        <video
                            src={this.state.bannerVideo}
                            playsInline
                            muted
                            autoPlay
                            loop
                            id="bannervideo"
                        ></video>
                    ) : this.state.noBannerImage && (
                        <video
                            src="https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/bg-bhodi.mp4?alt=media&token=a43a217e-8c6a-4b71-82a9-d6968fc74ee3&_gl=1*j4qhz8*_ga*MTU0MDE1MjU2OC4xNjk4ODQ5MzE3*_ga_CW55HF8NVT*MTY5OTAxNDA0OC40LjEuMTY5OTAxNDEyNi42MC4wLjA."
                            playsInline
                            muted
                            autoPlay
                            loop
                            id="bannervideo"
                        ></video>
                    ))}
                    {!this.state.isDesktop && (this.state.bannerVideoMobile ? (
                        <video
                            src={this.state.bannerVideoMobile}
                            playsInline
                            muted
                            autoPlay
                            loop
                            id="bannervideo"
                        ></video>
                    ) : (this.state.noBannerImageMobile && this.state.noBannerImage) && (
                        <video
                            src="https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/bg-bhodi.mp4?alt=media&token=a43a217e-8c6a-4b71-82a9-d6968fc74ee3&_gl=1*j4qhz8*_ga*MTU0MDE1MjU2OC4xNjk4ODQ5MzE3*_ga_CW55HF8NVT*MTY5OTAxNDA0OC40LjEuMTY5OTAxNDEyNi42MC4wLjA."
                            playsInline
                            muted
                            autoPlay
                            loop
                            id="bannervideo"
                        ></video>
                    ))}
                    {(this.props.member_user_id === this.state.currentUserId && window.innerWidth < 1008) && (<div className="icons-new-contact-section">
                        {/* {this.state.currentUser.barber_new && (<AiOutlineQrcode
                            onClick={() => {
                                this.generateQRCode()
                            }}
                            className='qr-scan-icon'
                        />)} */}
                        <AiOutlineQrcode
                            onClick={() => {
                                this.generateQRCode()
                            }}
                            className='qr-scan-icon'
                        />
                        <AddIcon
                            onClick={() => {
                                document.querySelector("html").style.overflowY = "hidden"
                                this.setState({
                                    openAddPopup: true,
                                });
                            }}
                        />

                        <EditIcon
                            onClick={() => {
                                document.querySelector("html").style.overflowY = "hidden"

                                this.setState({
                                    showUploadPopup: true,
                                });
                            }} />
                    </div>)}
                    <div className='content-bchn' data-aos="zoom-in">
                        <img src={this.state.currentUser.profile_image ? this.state.currentUser.profile_image : "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9"} />
                        <p className='second-text-cbchn'>{this.state.currentUser.company_name}</p>
                        {this.state.companySecondHeading && (<p className='second-text-cbchn'>{this.state.companySecondHeading}</p>)}
                        <p className='third-text-cbchn'>{this.state.companyBannerSlogan}</p>
                        <p className='fourth-text-cbchn'>{this.state.dailyNewAddedText}</p>
                        <div>
                            {/* {this.state.currentUserId === this.props.member_user_id && this.state.currentUser.barber_new && (<button style={{ marginRight: 8 }} onClick={() => this.generateQRCode()}>Generate QR</button>)} */}
                            {(this.state.currentCampaignToShow || this.state.currentCampaignToShowVoucher || this.state.currentCampaignToShowWebShop.length > 0) ? (<button onClick={() => {
                                // if (this.state.currentCampaignToShow) {
                                //     this.scrollToSection()
                                // } else if (this.state.currentCampaignToShowVoucher) {
                                //     this.scrollToSectionVoucher()
                                // } else {
                                //     this.scrollToSectionWebShop(0)
                                // }
                                this.ontdekPress()
                            }}>ONTDEK</button>) : this.state.currentUserId === this.props.member_user_id ? (<button onClick={() => {
                                document.querySelector("html").style.overflowY = "hidden"
                                this.setState({
                                    openAddPopup: true,
                                });
                            }}>ACTIE TOEVOEGEN</button>) : (<button>MOMENTEEL GEEN EVENTS</button>)}
                            {(this.state.currentUserId !== this.props.member_user_id && window.innerWidth < 1008 && this.state.currentUserId) && (<button style={{ marginLeft: 8 }} onClick={() => {
                                this.followCompany()
                            }}>{this.checkForFollowText()}</button>)}
                        </div>
                        {!this.state.currentUserId && (<div className='links-cbchn'>
                            <label onClick={() => {
                                this.scrollToCharity()
                            }}>Charity</label>
                            <label>|</label>
                            <label onClick={() => {
                                this.scrollToContact()
                            }}>Contact</label>
                        </div>)}
                        {this.state.currentUserId && (<div className='links-cbchn'>
                            <label onClick={() => {
                                this.setState({ showImpressiePopup: true })
                            }}>Impressie</label>
                            {(this.state.currentUser.following && this.props.member_user_id !== this.state.currentUserId) && (<label>|</label>)}
                            {(this.state.currentUser.following && this.props.member_user_id !== this.state.currentUserId) && (<label onClick={() => {
                                document.querySelector("html").style.overflowY = "hidden"
                                this.setState({
                                    showRightMenuViewerFriends: true
                                })
                            }}>Vrienden</label>)}
                            <label>|</label>
                            <label onClick={() => {
                                this.scrollToContact()
                            }}>Contact</label>
                        </div>)}
                    </div>
                    <div className='overlay-bchn' style={{ backgroundColor: `rgba(0,0,0,${this.state.bannerOpacity})` }}>
                    </div>
                </div>) : this.state.latestEventOrVoucher && (window.innerWidth > 1008 ? <VouchersNewGridDesktop
                    completeArrProducts={[this.state.latestEventOrVoucher]}
                    filteredProductsToShow={[this.state.latestEventOrVoucher]}
                    allProducts={[this.state.latestEventOrVoucher]}
                    noProducts={false}
                    onHomePages={true}
                /> : <VouchersNewMobile
                    productsWhole={[this.state.latestEventOrVoucher]}
                    lastNodeEmpty={true}
                    shouldAlwaysUpdate={true}
                />)
                    // (this.state.availableCompaniesProducts.length > 0 && this.state.currentUser.payment_status === "paid" && this.state.currentUser.subscription && this.state.currentUser.subscription.status === "active") ? (<div className="slider-main">
                    //     {this.state.availableCompaniesProducts.length > 1 && <div className='arrow-left-sm-home' onClick={() => {
                    //         if (this.state.atCompanyIndex > 0) {
                    //             this.setState({ isChangingCompany: true, atCompanyIndex: this.state.atCompanyIndex - 1 })
                    //             setTimeout(() => {
                    //                 this.setState({ isChangingCompany: false })
                    //             }, 1000);
                    //         }
                    //     }}>
                    //         <FaChevronLeft />
                    //     </div>}
                    //     {this.state.availableCompaniesProducts.length > 1 && <div className='arrow-right-sm-home' onClick={() => {
                    //         if (this.state.atCompanyIndex < this.state.availableCompaniesProducts.length - 1) {
                    //             this.setState({ isChangingCompany: true, atCompanyIndex: this.state.atCompanyIndex + 1 })
                    //             setTimeout(() => {
                    //                 this.setState({ isChangingCompany: false })
                    //             }, 1000);
                    //         }
                    //     }}>
                    //         <FaChevronRight />
                    //     </div>}
                    //     <div style={{ opacity: this.state.isChangingCompany ? 0 : 1, pointerEvents: this.state.isChangingCompany ? "none" : "all", transition: "all .3s ease-in-out" }}>
                    //         <SubscribedProductsTab toShowBannerOf={this.state.availableCompaniesProducts[this.state.atCompanyIndex]} scrollToProducts={() => this.scrollToProducts()} isDesktop={this.state.isDesktop} />
                    //     </div>
                    // </div>) : (<div className='banner-mhn bmhn-admin' style={{ backgroundImage: `url("${this.state.adminProduct.allImagesNew[this.state.adminProduct.indexOfImage]}")`, height: !this.state.isDesktop && 500 }}>
                    //     <div className='content-bmhn'>
                    //         <p>DAILY NEW ADDED</p>
                    //         <p>{this.state.adminProduct.title}</p>
                    //         <p>{this.state.adminProduct.desc && this.state.adminProduct.desc.substr(0, 140)}</p>
                    //         <p><s>€{this.state.adminProduct.price}</s> for Free</p>
                    //         {(this.state.adminProduct.variations_data && this.state.adminProduct.variations_data.length > 0) && (<p>
                    //             Beschikbaar in
                    //             {this.state.adminProduct.variations_data.map((e) => {
                    //                 return (<font>&nbsp;&nbsp;{e.size}</font>)
                    //             })}
                    //         </p>)}
                    //         <div className='button-admin-note'>
                    //             {this.state.showNoteButtonTimer && (<div className='banote-inner'>
                    //                 <p>Note</p>
                    //                 <p>Wanneer de timer is voltooid, bestel dan zo snel mogelijk als je kunt! Want op is op.</p>
                    //             </div>)}
                    //             {this.state.adminProduct.timerToStartProduct && <ButtonTimerProductsNew timerToDo={new Date(Date.parse(this.state.adminProduct.timerToStartProduct)).getTime()} btnClicked={() => {
                    //                 // this.setState({ showNoteButtonTimer: true })
                    //                 // setTimeout(() => {
                    //                 //     this.setState({ showNoteButtonTimer: false })
                    //                 // }, 2000);
                    //                 history.push(`/product-detailed?key=${this.state.adminProduct.pushKey}`)
                    //             }} />}
                    //             {this.checkForButtonProducts(this.state.adminProduct)}
                    //         </div>
                    //     </div>
                    //     <div className='overlay-bchn'>
                    //     </div>
                    //     {window.innerWidth > 1008 && (<div className="bottom-csfs-shop">
                    //         <div
                    //             className="unds-buttons"
                    //             style={{ alignItems: "center" }}
                    //         >
                    //             <div
                    //                 className="inner-undb"
                    //                 onClick={() => {
                    //                     this.scrollToProducts();
                    //                 }}
                    //             >
                    //                 <KeyboardArrowDownIcon />
                    //             </div>
                    //         </div>
                    //     </div>)}
                    // </div>)
                }
                {!this.state.currentUserId && (
                    <div
                        className={`section charityBhodisectiontarget`} ref={this.charityRef}
                        style={{ width: "100%", height: "100vh", position: "relative", overflow: "hidden" }}
                    >
                        <div
                            className={`not-loggedinposts registrationsteppage`}
                            style={{
                                backgroundImage: "none", position: "static"
                                // backgroundImage: this.state.charityImage !== "" ? `url(${this.state.charityImage})` : "none", position: "static"
                            }}
                        >
                            {/* {this.state.charityVideo && ( */}
                            <video
                                src={thanksVideo}
                                autoPlay
                                loop
                                muted
                                playsInline
                                className="videocomplete"
                                id="videoplay"
                            ></video>
                            {/* )} */}
                            <div className="registration-versionnew aboutversionregistration" style={{ top: "50%" }}>
                                <div
                                    className="about-reve about-reve-updated"
                                    style={{ width: "100%", maxWidth: window.innerWidth > 1920 ? 420 : window.innerWidth > 1008 ? 400 : 430, paddingRight: window.innerWidth < 1008 && 0, paddingLeft: window.innerWidth < 1008 && 0 }}
                                >
                                    <div
                                        className="bottom-areve"
                                        style={{ margin: "0px auto" }}
                                    >
                                        <div className="container-bareve">
                                            <p
                                                className="charitysection-heading"
                                                style={{
                                                    fontWeight: 600,
                                                    color: "black",
                                                }}
                                            >
                                                BHODI
                                            </p>
                                            <p
                                                className="charitysection-heading-inner"
                                                style={{
                                                    fontWeight: 600,
                                                    color: "black",
                                                }}
                                            >
                                                Hoe werkt het?
                                            </p>
                                            <div className="text-container-charity">
                                                <AiFillGift />
                                                <p>
                                                    Op Bhodi worden er allerlei exclusieve events
                                                    <br />
                                                    beschikbaar gesteld door diverse aanbieders.
                                                </p>
                                            </div>
                                            <div className="text-container-charity">
                                                <GiHotMeal />
                                                {window.innerWidth > 1009 ? (
                                                    <p>
                                                        Tijdens je aanmelding kun je Charity Member
                                                        <br />
                                                        worden voor 7,50 EURO. Met jouw maandelijkse
                                                        <br />
                                                        bijdrage ondersteun je kinderen en scholen
                                                        <br />
                                                        in Tanzania met maaltijden en materialen.
                                                    </p>
                                                ) : (
                                                    <p>
                                                        Tijdens je aanmelding kun je Charity Member
                                                        <br />
                                                        worden voor 7.50 EUR. Met jouw maandelijkse
                                                        <br />
                                                        bijdrage ondersteun je kinderen en scholen
                                                        <br />
                                                        in Tanzania met maaltijden en materialen.
                                                    </p>
                                                )}
                                            </div>
                                            <div className="text-container-charity">
                                                <GoDeviceCameraVideo />
                                                <p>
                                                    Voor je bijdrage ontvang je een persoonlijke
                                                    <br />
                                                    en op naam ingesproken bedankvideo vanuit
                                                    <br />
                                                    de school in Tanzania. En krijg je toegang tot
                                                    <br />
                                                    alle exclusieve acties op Bhodi.com.
                                                </p>
                                            </div>
                                            <button onClick={() => {
                                                this.setState({ storyOpen: true })
                                            }}>Bekijk de video</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div ref={this.sectionOntdekRedirect}>
                    {/* {(this.state.currentUserId && !this.props.atCompanyPage && this.props.currentMemberData) && (<div ref={this.sectionRefProducts} style={{ paddingBottom: !this.state.latestEventOrVoucherSecond && 50 }}><NewProductWrapper toShowProductOf={process.env.REACT_APP_ADMIN_ID} /></div>)} */}
                    {/* {(this.state.currentUserId && !this.props.atCompanyPage) && (<div className='second-section-mhn' ref={this.sectionRefProducts} style={{ marginBottom: this.state.latestEventOrVoucher && 0, paddingBottom: this.state.latestEventOrVoucher && 0, }}>
                    <div className='top-ssmhn'>
                        <div className='content-tssmhn'>
                            <p>Free</p>
                            <p>Check the items</p>
                        </div>
                    </div>
                    <div className='products-holder-container' style={{ marginTop: 20 }}>
                        {this.state.allFourProducts.length > 0 && this.state.allFourProducts.map((product, index) => {
                            let indexOfImage = 0

                            let allImagesNew = []
                            if (product.images && product.images.length > 0) {
                                let duplicated = Array.from(product.images)

                                let allImagesUrl = []
                                duplicated.map((g) => {
                                    if (g) {
                                        let filePath = g.split("/")[7].split("?")[0]
                                        allImagesUrl.push(filePath)
                                    }
                                })

                                allImagesUrl.sort().map((t) => {
                                    let filtered = duplicated.filter((g) => {
                                        return g.includes(t)
                                    })

                                    if (filtered.length > 0) {
                                        allImagesNew.push(filtered[0])
                                    }
                                })
                                if (typeof product.featuredImage !== "undefined" && product.featuredImage !== null) {
                                    if (product.featuredImage < product.images.length) {
                                        let filteredIndex = allImagesNew.findIndex((g) => {
                                            return g === product.images[product.featuredImage]
                                        })
                                        if (filteredIndex !== -1) {
                                            indexOfImage = filteredIndex
                                        }
                                    }
                                }
                            }
                            return (<div className='inner-phc'>
                                <div className='img-inner-phc'>
                                    <img src={allImagesNew[indexOfImage]} onClick={() => {
                                        history.push(`/product-detailed?key=${product.pushKey}`)
                                    }} />
                                </div>
                                <div className='content-inner-phc'>
                                    <div className='left-ciphc'>
                                        <p>{product.title}</p>
                                        <p><s>€{product.price}</s> for <font style={{ color: "#c17f6b" }}>Free</font></p>
                                    </div>
                                    <div className='right-ciphc'>
                                        {product.showingNote && (
                                            <div className='banote-inner'>
                                                <p>Note</p>
                                                <p>Wanneer de timer is voltooid, bestel dan zo snel mogelijk als je kunt! Want op is op.</p>
                                            </div>
                                        )}
                                        {product.timerToStartProduct && <ButtonTimerProductsNew timerToDo={new Date(Date.parse(product.timerToStartProduct)).getTime()} btnClicked={() => {
                                            this.state.allFourProducts[index].showingNote = true
                                            this.setState({ showingProductNote: true })
                                            setTimeout(() => {
                                                this.state.allFourProducts[index].showingNote = false
                                                this.setState({ showingProductNote: false })
                                            }, 2000);
                                        }} />}
                                        <button onClick={() => {
                                            history.push(`/product-detailed?key=${product.pushKey}`)
                                        }}>Explore</button>
                                    </div>
                                </div>
                            </div>)
                        })}
                    </div>
                </div>)} */}
                    {/* {(this.state.currentUserId && this.props.atCompanyPage && this.props.currentMemberData) && (<NewProductWrapper toShowProductOf={this.props.currentMemberData.user_id} />)}
                    {(!this.state.currentUserId && this.props.atCompanyPage && this.props.currentMemberData) && (<NewProductWrapper toShowProductOf={this.props.currentMemberData.user_id} onGuestUserPage={true} />)} */}
                    {/* {((this.state.currentCampaignToShow || this.state.currentCampaignToShowVoucher) && window.innerWidth < 1008 && this.props.atCompanyPage) && (<div className='second-section-mhn' ref={this.sectionRefProducts} style={{ paddingBottom: 10 }}>
                    <div className='top-ssmhn'>
                        <div className='content-tssmhn'>
                            <p>New Items</p>
                            <p>{this.checkForCreatorName(this.state.currentCampaignToShow ? this.state.currentCampaignToShow.created_by : this.state.currentCampaignToShowVoucher.created_by)} collection</p>
                        </div>
                    </div>
                </div>)} */}
                    {(this.state.currentCampaignToShow && this.props.atCompanyPage) && (
                        <div ref={this.sectionRef}>
                            {(this.state.currentCampaignToShow && window.innerWidth > 1008) && (<div className='top-events-section'>
                                <p>Event</p>
                                <p>{this.checkForCreatorName(this.state.currentCampaignToShow.created_by)}</p>
                            </div>)}
                            <EventsNewSection
                                campaignToShow={[this.state.currentCampaignToShow]}
                                currentCompany={this.state.currentUser}
                                onEditOpen={() => {
                                    document.querySelector("html").style.overflowY = "hidden"
                                    this.setState({
                                        openAddPopup: true,
                                        doEditLocalEvent: true,
                                    });
                                }}
                            />
                        </div>
                    )}
                    {(this.state.latestEventOrVoucherSecond && this.state.latestEventOrVoucherSecond.campaignType === "local_event" && !this.props.atCompanyPage) && (
                        <div ref={this.sectionRef}>
                            {(this.state.latestEventOrVoucherSecond) && (<div className='top-events-section'>
                                <p>Event</p>
                                <p>{this.checkForCreatorName(this.state.latestEventOrVoucherSecond.created_by)}</p>
                            </div>)}
                            <EventsNewSection
                                campaignToShow={[this.state.latestEventOrVoucherSecond]}
                                onEditOpen={() => {
                                    document.querySelector("html").style.overflowY = "hidden"
                                    this.setState({
                                        openAddPopup: true,
                                        doEditLocalEvent: true,
                                    });
                                }}
                            />
                        </div>
                    )}
                    {(this.state.currentCampaignToShowVoucher && this.props.atCompanyPage) && (
                        <div ref={this.sectionRefVoucher}>
                            {(this.state.currentCampaignToShowVoucher && window.innerWidth > 1008) ? (<div className='top-events-section' style={{ paddingTop: this.state.currentCampaignToShow && 0, marginTop: !this.state.currentCampaignToShow && 0 }}>
                                <p>Voucher</p>
                                <p>{this.checkForCreatorName(this.state.currentCampaignToShowVoucher.created_by)}</p>
                            </div>) : (this.state.currentCampaignToShowVoucher && !this.state.currentCampaignToShow) && (<div className='top-events-section' style={{ paddingTop: this.state.currentCampaignToShow && 0, marginTop: !this.state.currentCampaignToShow && 0 }}>
                                <p>Voucher</p>
                                <p>{this.checkForCreatorName(this.state.currentCampaignToShowVoucher.created_by)}</p>
                            </div>)}
                            <VouchersNewSection
                                campaignToShow={[this.state.currentCampaignToShowVoucher]}
                                onEditOpen={() => {
                                    document.querySelector("html").style.overflowY = "hidden"
                                    this.setState({
                                        openAddPopup: true,
                                        doEditVoucher: true,
                                    });
                                }}
                            />
                        </div>
                    )}
                    {(this.state.latestEventOrVoucherSecond && this.state.latestEventOrVoucherSecond.campaignType === "kortings_voucher" && !this.props.atCompanyPage) && (
                        <div ref={this.sectionRefVoucher}>
                            {(this.state.latestEventOrVoucherSecond) && (<div className='top-events-section'>
                                <p>Voucher</p>
                                <p>{this.checkForCreatorName(this.state.latestEventOrVoucherSecond.created_by)}</p>
                            </div>)}
                            <VouchersNewSection
                                campaignToShow={[this.state.latestEventOrVoucherSecond]}
                                onEditOpen={() => {
                                    document.querySelector("html").style.overflowY = "hidden"
                                    this.setState({
                                        openAddPopup: true,
                                        doEditVoucher: true,
                                    });
                                }}
                            />
                        </div>
                    )}
                    {(this.state.currentCampaignToShowWebShop.length > 0 && this.props.atCompanyPage) && this.state.currentCampaignToShowWebShop.map((webShopCampaign, index) => {
                        this.createRefs(index);
                        return (<div ref={this.sectionRefWebShop[index]}>
                            {(webShopCampaign && window.innerWidth > 1008) ? (<div className='top-events-section' style={{ paddingTop: (this.state.currentCampaignToShow || this.state.currentCampaignToShowVoucher) ? 0 : index !== 0 && 0, marginTop: (!this.state.currentCampaignToShow && !this.state.currentCampaignToShowVoucher) && index === 0 && 0 }}>
                                <p>Coupon Code</p>
                                <p>{this.checkForCreatorName(webShopCampaign.created_by)}</p>
                            </div>) : (webShopCampaign && !this.state.currentCampaignToShow && !this.state.currentCampaignToShowVoucher && index === 0) && (<div className='top-events-section' style={{ paddingTop: (this.state.currentCampaignToShow || this.state.currentCampaignToShowVoucher) ? 0 : index !== 0 && 0, marginTop: (!this.state.currentCampaignToShow && !this.state.currentCampaignToShowVoucher) && index === 0 && 0 }}>
                                <p>Coupon Code</p>
                                <p>{this.checkForCreatorName(webShopCampaign.created_by)}</p>
                            </div>)}
                            <WebShopNewSection
                                campaignToShow={[webShopCampaign]}
                                atIndex={index + 1}
                                onEditOpen={() => {
                                    document.querySelector("html").style.overflowY = "hidden"
                                    this.setState({
                                        openAddPopup: true,
                                        doEditWebShop: true,
                                        currentWebShopCampaign: webShopCampaign,
                                        atIndexWebShop: index,
                                    });
                                }}
                            />
                        </div>)
                    })}
                    {(this.state.latestEventOrVoucherSecond && this.state.latestEventOrVoucherSecond.campaignType === "web_shop" && !this.props.atCompanyPage) && (
                        <div>
                            {(this.state.latestEventOrVoucherSecond) && (<div className='top-events-section'>
                                <p>Coupon Code</p>
                                <p>{this.checkForCreatorName(this.state.latestEventOrVoucherSecond.created_by)}</p>
                            </div>)}
                            <WebShopNewSection
                                campaignToShow={[this.state.latestEventOrVoucherSecond]}
                                onEditOpen={() => {
                                    document.querySelector("html").style.overflowY = "hidden"
                                    this.setState({
                                        openAddPopup: true,
                                        doEditWebShop: true,
                                        currentWebShopCampaign: this.state.latestEventOrVoucherSecond
                                    });
                                }}
                            />
                        </div>
                    )}
                </div>
                {(!this.props.atCompanyPage && window.innerWidth > 1008) && (<div className='fourth-section-mhn' ref={this.contactRef} style={{ backgroundImage: this.state.isDesktop ? (this.state.memberDashboardDesktopType === "image" ? `url("${this.state.memberDashboardDesktopUrl}")` : "none") : (this.state.memberDashboardMobileType === "image" ? `url("${this.state.memberDashboardMobileUrl}")` : "none") }}>
                    {(this.state.memberDashboardDesktopType === "video" && this.state.isDesktop) && (<video
                        src={this.state.memberDashboardDesktopUrl}
                        playsInline
                        muted
                        autoPlay
                        loop
                    ></video>)}
                    {(this.state.memberDashboardMobileType === "video" && !this.state.isDesktop) && (<video
                        src={this.state.memberDashboardMobileUrl}
                        playsInline
                        muted
                        autoPlay
                        loop
                    ></video>)}
                    <div className='container-fsmhn'>
                        <p className='desc-first-cfsmhn'>{this.state.memberFirstHeading}</p>
                        <p className='desc-second-cfsmhn'>{this.state.memberSecondHeading}</p>
                        <p className='normal-desc-cfsmhn'>
                            <div dangerouslySetInnerHTML={this.createMarkupDesc()}></div>
                        </p>
                    </div>
                </div>)}
                {(!this.props.atCompanyPage && window.innerWidth < 1008) && (<div className='fourth-section-mhn fsm-updated-mobile' ref={this.contactRef} style={{ backgroundImage: "none" }}>
                    <div className='top-events-section' style={{ paddingBottom: 20 }}>
                        <p>{this.state.bhodiNewsHeading}</p>
                        <p>{this.state.bhodiNewsText}</p>
                    </div>
                    <div className='image-section-fsmhn' style={{ backgroundImage: this.state.isDesktop ? (this.state.memberDashboardDesktopType === "image" ? `url("${this.state.memberDashboardDesktopUrl}")` : "none") : (this.state.memberDashboardMobileType === "image" ? `url("${this.state.memberDashboardMobileUrl}")` : "none") }}>
                        {(this.state.memberDashboardDesktopType === "video" && this.state.isDesktop) && (<video
                            src={this.state.memberDashboardDesktopUrl}
                            playsInline
                            muted
                            autoPlay
                            loop
                        ></video>)}
                        {(this.state.memberDashboardMobileType === "video" && !this.state.isDesktop) && (<video
                            src={this.state.memberDashboardMobileUrl}
                            playsInline
                            muted
                            autoPlay
                            loop
                        ></video>)}
                    </div>
                    <div className='container-fsmhn'>
                        <p className='desc-first-cfsmhn'>{this.state.memberFirstHeading}</p>
                        <p className='desc-second-cfsmhn'>{this.state.memberSecondHeading}</p>
                        <p className='normal-desc-cfsmhn'>
                            <div dangerouslySetInnerHTML={this.createMarkupDesc()}></div>
                        </p>
                    </div>
                </div>)}
                {/* {this.props.atCompanyPage && (<div className='fourth-section-mhn' ref={this.contactRef} style={{ backgroundImage: this.state.isDesktop ? ((!this.state.contactImageIsVideo && this.state.contactImage) && `url("${this.state.contactImage}")`) : ((!this.state.contactImageIsVideoMobile && (this.state.contactImageMobile || this.state.contactImage)) && `url("${this.state.contactImageMobile ? this.state.contactImageMobile : this.state.contactImage}")`) }}>
                    {(this.state.contactImageIsVideo && this.state.contactImage && this.state.isDesktop) && (<video
                        src={this.state.contactImage}
                        playsInline
                        muted
                        autoPlay
                        loop
                    ></video>)}
                    {(this.state.contactImageIsVideoMobile && !this.state.isDesktop && this.state.contactImageMobile) && (<video
                        src={this.state.contactImageMobile}
                        playsInline
                        muted
                        autoPlay
                        loop
                    ></video>)}
                    <div className='container-fsmhn'>
                        <p className='desc-first-cfsmhn'>Over Ons</p>
                        <p className='normal-desc-cfsmhn'>
                            {this.state.company_about}
                        </p>
                    </div>
                </div>)} */}
                {(this.props.atCompanyPage && window.innerWidth > 1008) && (<div className='fourth-section-mhn company-dashboard-design' ref={this.contactRef} style={{ backgroundImage: "none", paddingLeft: 0 }}>
                    <div
                        className={`contactsectionhere`} style={{ width: "100%", height: "100%" }}
                    >
                        <div
                            className={`not-loggedinposts registrationsteppage `}
                            style={{
                                backgroundImage: !this.state.contactImageIsVideo ? (this.state.contactImage ? `url('${this.state.contactImage}')` : `url("${contactSectionImage}")`) : "none"
                            }}
                        >
                            {this.state.contactImageIsVideo && (
                                <video
                                    src={this.state.contactImage}
                                    autoPlay
                                    loop
                                    muted
                                    playsInline
                                    className="videocomplete"
                                    id="videoplay"
                                ></video>
                            )}
                            <div className="registration-versionnew aboutversionregistration" style={{ zIndex: 99 }}>
                                <div
                                    className="about-reve about-reve-updated"
                                    data-aos={"zoom-in"}
                                    data-aos-duration="1000"
                                    style={{ width: "100%", maxWidth: 400 }}
                                >
                                    {this.state.currentUser.user_id === this.state.currentUserId && (<div className="icons-new-contact-section">
                                        {this.state.contactImage && (<div className="input-publish" style={{ marginBottom: 0, paddingTop: 2 }}>
                                            <CloseIcon onClick={() => {
                                                let uid = localStorage.getItem("userid");

                                                firebase
                                                    .database()
                                                    .ref("users/" + uid + "/contact_image")
                                                    .remove();
                                                firebase
                                                    .database()
                                                    .ref("users/" + uid + "/contactImageIsVideo")
                                                    .remove();
                                            }} />
                                        </div>)}
                                        <EditIcon onClick={() => {
                                            document.querySelector("html").style.overflowY = "hidden"

                                            this.setState({
                                                showUploadPopupContact: true,
                                                companyAboutDuplicate: this.state.company_about,
                                            });
                                        }} />
                                        {/* // } */}
                                    </div>)}
                                    <div
                                        className="bottom-areve"
                                        style={{ margin: "0px auto" }}
                                    >
                                        <div className="container-bareve">
                                            <p
                                                className="charitysection-heading"
                                                style={{
                                                    fontWeight: 600,
                                                    color: "black",
                                                }}
                                            >
                                                OVER ONS
                                            </p>
                                            <p
                                                className="charitysection-heading-inner"
                                                style={{
                                                    fontWeight: 600,
                                                    marginBottom: this.state.companySecondHeading & 8,
                                                    color: "black",
                                                }}
                                            >
                                                {this.state.currentUser.company_name}
                                            </p>
                                            {this.state.companySecondHeading && (<p
                                                className="charitysection-heading-inner"
                                                style={{
                                                    fontWeight: 600,
                                                    color: "black",
                                                }}
                                            >
                                                {this.state.companySecondHeading}
                                            </p>)}
                                            <div className="text-container-charity">
                                                <p>
                                                    {this.state.company_about}
                                                </p>
                                            </div>
                                            {!this.state.hideContactInfo && (<div className="text-container-charity">
                                                <IoLocationSharp />
                                                <p>
                                                    {this.state.currentUser.street && this.state.currentUser.street} {this.state.currentUser.houseNumber && this.state.currentUser.houseNumber}
                                                    <br />
                                                    {this.state.currentUser.zipCode && this.state.currentUser.zipCode} {this.state.currentUser.city && this.state.currentUser.city}
                                                </p>
                                            </div>)}
                                            {this.state.currentUser.phoneNumber && (<div className="text-container-charity">
                                                <BiPhone />
                                                <p>
                                                    {this.state.currentUser.phoneNumber && <a style={{ color: "white", textDecoration: "none" }}
                                                        href={`tel:${this.state.currentUser.phoneNumber}`}
                                                    >{this.state.currentUser.phoneNumber}</a>}
                                                    <br />
                                                    {/* {this.state.currentUser.email && <a style={{ color: "white", textDecoration: "none" }}
                                                        href={`mailto:${this.state.currentUser.email}`}
                                                    >{this.state.currentUser.email}</a>} */}
                                                </p>
                                            </div>)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>)}

                {(this.props.atCompanyPage && window.innerWidth < 1008) && (<div className='fourth-section-mhn company-dashboard-design' ref={this.contactRef} style={{ backgroundImage: "none", paddingLeft: 0, paddingBottom: 0, height: 600 }}>
                    <div className={`contactsectionhere`} style={{ width: "100%", height: "100%" }}>
                        <div
                            className="third-sec-cdd post-detail tscddupdatedonlymobiledesign"
                            id="wrappersecabout"
                            style={{ paddingTop: 0 }}
                        >
                            <div
                                className="footer-sscdd footerupdatedonlymobiledesign"
                                style={{
                                    marginTop: 0,
                                    backgroundImage: !this.state.contactImageIsVideoMobile ?
                                        (((this.state.contactImage && !this.state.contactImageIsVideo) || this.state.contactImageMobile) ? `url('${this.state.contactImageMobile ? this.state.contactImageMobile : this.state.contactImage}')` : `url("${contactSectionImage}")`)
                                        : "none"
                                }}
                            >
                                {(this.state.contactImageIsVideoMobile && this.state.contactImageMobile) && (
                                    <video
                                        src={this.state.contactImageMobile}
                                        autoPlay
                                        loop
                                        muted
                                        playsInline
                                        className="videocomplete"
                                        id="videoplay"
                                        style={{ zIndex: 9 }}
                                    ></video>
                                )}
                                {(this.state.contactImageIsVideo && !this.state.contactImageMobile && this.state.contactImage) && (
                                    <video
                                        src={this.state.contactImage}
                                        autoPlay
                                        loop
                                        muted
                                        playsInline
                                        className="videocomplete"
                                        id="videoplay"
                                        style={{ zIndex: 9 }}
                                    ></video>
                                )}
                                <div
                                    className="overlay-volgendpage"
                                    style={{
                                        backgroundColor: `rgba(0,0,0,0.5)`,
                                    }}
                                ></div>
                                {this.state.currentUser.user_id === this.state.currentUserId && (<div className="icons-new-contact-section">
                                    {this.state.contactImageMobile && (<div className="input-publish" style={{ marginBottom: 0, paddingTop: 2 }}>
                                        <CloseIcon onClick={() => {
                                            let uid = localStorage.getItem("userid");

                                            firebase
                                                .database()
                                                .ref("users/" + uid + "/contact_image_mobile")
                                                .remove();
                                            firebase
                                                .database()
                                                .ref("users/" + uid + "/contactImageIsVideoMobile")
                                                .remove();
                                        }} />
                                    </div>)}
                                    <EditIcon onClick={() => {
                                        document.querySelector("html").style.overflowY = "hidden"

                                        this.setState({
                                            showUploadPopupContact: true,
                                            companyAboutDuplicate: this.state.company_about,
                                        });
                                    }} />
                                </div>)}
                                <div
                                    className="left-fsscdd commentright"
                                    style={{
                                        marginLeft: 0,
                                        marginBottom: 0,
                                        marginTop: 0,
                                        backgroundColor: "white",
                                    }}
                                >
                                    <div
                                        className="bottom-lfs-inner companycomments"
                                        style={{ height: "98%", width: "90%" }}
                                        data-aos={"zoom-in"}
                                        data-aos-duration="1000"
                                    >
                                        <p
                                            className="heading-blinner barlowcondensedfont"
                                            style={{ marginBottom: 5, display: "inline" }}
                                        >
                                            OVER ONS
                                        </p>
                                        <p className="heading-sec-blinner barlowcondensedfont" style={{ marginBottom: this.state.companySecondHeading && 0 }}>
                                            {this.state.currentUser.company_name}{" "}
                                        </p>
                                        {this.state.companySecondHeading && (<p className="heading-sec-blinner barlowcondensedfont">
                                            {this.state.companySecondHeading}{" "}
                                        </p>)}
                                        <p className="desc-sec-blinner" style={{ maxWidth: "86%" }}>
                                            {this.state.company_about}
                                        </p>
                                        {!this.state.hideContactInfo && (<div className="contactinfocompany-bottom">
                                            <div className="icon-cicbottom">
                                                <LocationOnIcon />
                                            </div>
                                            <div className="content-cicbottom">
                                                <p>
                                                    {this.state.currentUser.street}{" "}
                                                    {this.state.currentUser.houseNumber}{" "}
                                                    <br />{" "}
                                                    {this.state.currentUser.zipCode}{" "}
                                                    {this.state.currentUser.city}
                                                </p>
                                            </div>
                                        </div>)}
                                        {(this.state.currentUser.phoneNumber) && (
                                            <div className="contactinfocompany-bottom">
                                                <div className="icon-cicbottom">
                                                    <PhoneIcon />
                                                </div>
                                                <div className="content-cicbottom">
                                                    <p>
                                                        {this.state.currentUser.phoneNumber && (
                                                            <a href={`tel:${this.state.currentUser.phoneNumber}`}>
                                                                {this.state.currentUser.phoneNumber}
                                                            </a>
                                                        )}
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>)}
                {this.state.openAddPopup && <AddEditLocalEvent openAddPopup={this.state.openAddPopup} doEditLocalEvent={this.state.doEditLocalEvent} doEditVoucher={this.state.doEditVoucher} doEditWebShop={this.state.doEditWebShop} currentWebShopCampaign={this.state.currentWebShopCampaign} showAddressOpened={() => {
                    document.getElementById("root").style.overflow = "auto"
                    document.querySelector("html").style.overflowY = "auto"
                    this.props.showAddressOpened();
                }} closePopup={(type) => {
                    document.getElementById("root").style.overflow = "auto"
                    document.querySelector("html").style.overflowY = "auto"
                    this.setState({ openAddPopup: false, doEditLocalEvent: false, doEditVoucher: false, doEditWebShop: false })

                    if (type === "redirect-voucher") {
                        this.scrollToSectionVoucher()
                    } if (type === "redirect-webshop") {
                        this.scrollToSectionWebShop(this.state.atIndexWebShop || 0)
                        this.setState({ atIndexWebShop: 0 })
                    } else if (type !== "closewithoutredirect") {
                        this.scrollToSection()
                    }
                }} />}


                {this.state.showUploadPopup && (
                    <div className={`rightmenuaddproduct bannerpopupcompany rightmenuaddproductcompanydowhite bannerpopupcompanyupdated`}>
                        <div className="topclosebtnrmcwp">
                            <CloseIcon
                                style={{ fontSize: 18 }}
                                onClick={() => {
                                    if (this.state.addStockImageBanner) {
                                        this.setState({ addStockImageBanner: false })
                                        return false;
                                    }
                                    document.querySelector("html").style.overflowY = "auto";
                                    this.setState({ showUploadPopup: false });
                                }}
                            />
                        </div>
                        <div
                            className="company-dashboard-design"
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            {this.state.addStockImageBanner ? (<div className="rightmenuaddproduct rightmenuaddcategoryformfw rightmenuaddproductstockselection rightmenuproductstockselectionupdated updatedrightmenustock">
                                <div
                                    className="mid-rmap"
                                    style={{ position: "relative", padding: 13 }}
                                >
                                    <span>SELECTEER AFBEELDING</span>
                                    <ChevronLeft
                                        style={{
                                            cursor: "pointer",
                                            position: "absolute",
                                            top: "50%",
                                            transform: "translateY(-50%)",
                                            left: this.state.isDesktop ? 20 : 10,
                                            fontSize: 18,
                                        }}
                                        onClick={() =>
                                            this.setState({ addStockImageBanner: false })
                                        }
                                    />
                                </div>
                                <div className="bottom-rmap">
                                    <div
                                        className="mid-img-uploading-rmap"
                                        style={{ alignItems: "flex-start" }}
                                    >
                                        <div
                                            className="form-miur"
                                            style={{ marginBottom: 30, position: "relative" }}
                                        >
                                            <label>TYPE BEDRIJF</label>
                                            <div className='wrapper-select-new'>
                                                <select
                                                    value={this.state.mainSectorStock}
                                                    onChange={(e) => {
                                                        if (e.target.value !== "Selecteer") {
                                                            if (this.state.allImagesStock.length > 0) {
                                                                let filteredSecond =
                                                                    this.state.allImagesStock.filter((u) => {
                                                                        return u[0] === e.target.value;
                                                                    });
                                                                if (filteredSecond.length > 0) {
                                                                    let a = filteredSecond[0];
                                                                    let arr = [];
                                                                    if (a.length > 0) {
                                                                        let objected = Object.entries(a[1]);
                                                                        objected.map((y) => {
                                                                            arr.push(y[1]);
                                                                        });
                                                                        // this.setState({ allImagesCurrentSectorStock: arr, bannerImage: arr[0].snapUrl })
                                                                        this.setState({
                                                                            allImagesCurrentSectorStock: arr,
                                                                        });
                                                                    } else {
                                                                        this.setState({
                                                                            allImagesCurrentSectorStock: [],
                                                                        });
                                                                    }
                                                                }
                                                            }
                                                            this.setState({
                                                                mainSectorStock: e.target.value,
                                                                mainSectorStockMobile: e.target.value,
                                                            });
                                                        }
                                                    }}
                                                >
                                                    {!this.state.mainSector && (
                                                        <option disabled selected hidden value="">
                                                            Selecteer
                                                        </option>
                                                    )}
                                                    {this.state.allSectors.map((g) => {
                                                        return (
                                                            <option value={g.sector_id}>
                                                                {g.sector_name}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                                <ExpandMoreIcon
                                                    style={{
                                                        position: "absolute",
                                                        bottom: 5,
                                                        right: 5,
                                                        pointerEvents: "none",
                                                    }}
                                                    className='expand-more'
                                                />
                                            </div>
                                        </div>
                                        {this.state.allImagesCurrentSectorStock.length > 0 && (
                                            <div className="imagesstockselectionrightmenu">
                                                {this.state.allImagesCurrentSectorStock.map((y) => {
                                                    return (
                                                        <div
                                                            className="imagecontainerstockuploader"
                                                            style={{
                                                                backgroundImage: `url("${y.resizedUrl}")`,
                                                            }}
                                                            onClick={() => {
                                                                let uid = localStorage.getItem("userid");

                                                                if (!this.state.showDesktopLanding) {
                                                                    firebase
                                                                        .database()
                                                                        .ref(
                                                                            "users/" + uid + "/company_banner_type_mobile"
                                                                        )
                                                                        .set(this.state.companyBannerTypeMobile);
                                                                    firebase
                                                                        .database()
                                                                        .ref("users/" + uid + "/banner_image_mobile")
                                                                        .set(y.snapUrl);
                                                                    firebase
                                                                        .database()
                                                                        .ref("users/" + uid + "/banner_video_mobile")
                                                                        .set("");
                                                                } else {
                                                                    firebase
                                                                        .database()
                                                                        .ref(
                                                                            "users/" + uid + "/company_banner_type"
                                                                        )
                                                                        .set(this.state.companyBannerType);
                                                                    firebase
                                                                        .database()
                                                                        .ref("users/" + uid + "/banner_image")
                                                                        .set(y.snapUrl);
                                                                    firebase
                                                                        .database()
                                                                        .ref("users/" + uid + "/banner_video")
                                                                        .set("");
                                                                }

                                                                if (window.innerWidth < 1009) {
                                                                    this.setState({
                                                                        addStockImageBanner: false,
                                                                    });
                                                                }
                                                            }}
                                                        >
                                                            {(this.state.bannerImage && this.state.bannerImage === y.snapUrl) && <div className="overlay-icontstup">
                                                                <CheckCircleIcon />
                                                            </div>}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>) : (<div className="rightmenuaddproduct rightmenuupdateddesigngray updatedirghtmenunew newrightmenuglobalgood">
                                <div
                                    className="mid-rmap"
                                    style={{ position: "relative", padding: 13 }}
                                >
                                    <span>STARTPAGINA</span>
                                    <div className="top-header-infocont">
                                        <div style={{ position: "relative" }}>
                                            <div className='green-round-info' style={{ cursor: "pointer" }} onClick={() => {
                                                this.setState({ infoRightMenuOpen: !this.state.infoRightMenuOpen })
                                            }}>
                                                <font>?</font>
                                            </div>
                                            <div className="popup-gri-thifc" style={{ opacity: this.state.infoRightMenuOpen ? 1 : 0, pointerEvents: this.state.infoRightMenuOpen ? "all" : "none" }}>
                                                <CloseIcon onClick={() => this.setState({ infoRightMenuOpen: false })} />
                                                <p style={{ fontSize: 15 }}>
                                                    Personaliseer je bedrijfspagina met <br /> een
                                                    actuele omschrijving en voeg de <br /> perfecte
                                                    (eigen) foto of video toe
                                                </p>
                                            </div>
                                        </div>
                                        <CloseIcon
                                            onClick={() => {
                                                if (this.state.currentUser) {
                                                    let a = this.state.currentUser
                                                    if (a.company_banner_opacity) {
                                                        this.setState({
                                                            bannerOpacity: a.company_banner_opacity,
                                                        });
                                                    }
                                                    if (a.company_banner_slogan) {
                                                        this.setState({
                                                            companyBannerSlogan: a.company_banner_slogan,
                                                        });
                                                    }
                                                    if (a.company_second_heading) {
                                                        this.setState({
                                                            companySecondHeading: a.company_second_heading,
                                                        });
                                                    }
                                                    if (!a.company_banner_slogan) {
                                                        this.setState({ companyBannerSlogan: this.state.companyPageText });
                                                    }
                                                }

                                                document.querySelector("html").style.overflowY = "auto";
                                                this.setState({ showUploadPopup: false, bannerImageBigger: false, infoRightMenuOpen: false });
                                            }}
                                            style={{
                                                cursor: "pointer",
                                                position: "absolute",
                                                fontSize: 18,
                                                top: "50%",
                                                transform: "translateY(-50%)",
                                                right: 20,
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="bottom-rmap">
                                    <div className="mid-img-uploading-rmap">
                                        <div className="form-miur">
                                            <label>Omschrijving</label>
                                            <textarea
                                                value={this.state.companyBannerSlogan}
                                                onChange={(e) => {
                                                    this.setState({
                                                        companyBannerSlogan: e.target.value,
                                                    });
                                                }}
                                                style={{
                                                    minHeight: 250,
                                                    fontSize: 13,
                                                    letterSpacing: 1,
                                                }}
                                                maxLength={163}
                                            ></textarea>
                                        </div>
                                        <div className="form-miur">
                                            <label>Second Heading</label>
                                            <input
                                                value={this.state.companySecondHeading}
                                                onChange={(e) => {
                                                    this.setState({
                                                        companySecondHeading: e.target.value,
                                                    });
                                                }}
                                                style={{
                                                    fontSize: 13,
                                                    letterSpacing: 1,
                                                }}
                                            />
                                        </div>
                                        {/* {this.state.isDesktop && ( */}
                                        <div
                                            className="form-miur"
                                            style={{ position: "relative" }}
                                        >
                                            {/* <div className="labels-with-mobile-icon"> */}
                                            <label className="dolabelsuppercase">
                                                <font>Achtergrond</font>
                                                <div>
                                                    <div className={`round-icons-mobilepics ${this.state.showDesktopLanding && "active"}`} onClick={() => {
                                                        this.setState({ showDesktopLanding: true })
                                                    }}>
                                                        <GoDeviceDesktop />
                                                    </div>
                                                    {/* <div className="spacing-between-icons"></div> */}
                                                    <div className={`round-icons-mobilepics  ${!this.state.showDesktopLanding && "active"}`} onClick={() => {
                                                        this.setState({ showDesktopLanding: false })
                                                    }}>
                                                        <CiMobile3 />
                                                    </div>
                                                </div>
                                            </label>
                                            {/* </div> */}
                                            <div className="wrapper-select-new">
                                                <select
                                                    value={this.state.showDesktopLanding ? this.state.companyBannerType : this.state.companyBannerTypeMobile}
                                                    style={{
                                                        fontSize: 13,
                                                        letterSpacing: 1,
                                                        color: "#8c8c8c",
                                                    }}
                                                    onChange={(e) => {
                                                        if (!this.state.showDesktopLanding) {
                                                            this.setState({
                                                                companyBannerTypeMobile: e.target.value
                                                            })
                                                        }
                                                        this.setState({
                                                            companyBannerType: e.target.value,
                                                        });
                                                    }}
                                                >
                                                    <option value="uploadfromcomp">Eigen foto</option>
                                                    <option value="takefromlibrary">
                                                        Fotobibliotheek
                                                    </option>
                                                </select>
                                                <ExpandMoreIcon
                                                    style={{
                                                        cursor: "pointer",
                                                        position: "absolute",
                                                        right: 5,
                                                        bottom: 6,
                                                        fontSize: 18,
                                                        pointerEvents: "none",
                                                        pointerEvents: "none",
                                                    }}
                                                    className="expand-more"
                                                />
                                            </div>
                                        </div>
                                        {/* )} */}
                                        {/* {this.state.isDesktop && ( */}
                                        {!this.state.showDesktopLanding ? (<div
                                            className="form-miur"
                                            style={{ position: "relative" }}
                                        >
                                            <label
                                                style={{ display: "flex", alignItems: "center" }}
                                                className="dolabelsuppercase"
                                            >
                                                Eigen foto of video
                                            </label>
                                            <div className="wrapper-select-new">
                                                {this.state.companyBannerTypeMobile ===
                                                    "uploadfromcomp" ? (
                                                    <input
                                                        type="text"
                                                        value="Upload"
                                                        readOnly
                                                        style={{
                                                            fontSize: 13,
                                                            letterSpacing: 1,
                                                            backgroundColor: "white",
                                                        }}
                                                        className="dolabelsuppercase"
                                                    />
                                                ) : (
                                                    <input
                                                        type="text"
                                                        value="Kies foto"
                                                        readOnly
                                                        style={{
                                                            fontSize: 13,
                                                            letterSpacing: 1,
                                                            backgroundColor: "white",
                                                        }}
                                                        className="dolabelsuppercase"
                                                    />
                                                )}
                                                {this.state.companyBannerTypeMobile ===
                                                    "uploadfromcomp" && (
                                                        <input
                                                            type="file"
                                                            onChange={(e) => this.uploadBannerImage(e)}
                                                            accept="image/*"
                                                            style={{
                                                                position: "absolute",
                                                                width: "100%",
                                                                height: "80%",
                                                                top: 0,
                                                                left: 0,
                                                                right: 0,
                                                                bottom: 0,
                                                                opacity: 0,
                                                            }}
                                                        />
                                                    )}
                                                {this.state.companyBannerTypeMobile ===
                                                    "takefromlibrary" && (
                                                        <div
                                                            style={{
                                                                position: "absolute",
                                                                width: "100%",
                                                                height: 40,
                                                                top: 0,
                                                                cursor: "pointer",
                                                                left: 0,
                                                                right: 0,
                                                                bottom: 0,
                                                                opacity: 0,
                                                            }}
                                                            onClick={() =>
                                                                this.setState({ addStockImageBanner: true })
                                                            }
                                                        ></div>
                                                    )}
                                                <PublishIcon
                                                    className="expand-more"
                                                    style={{
                                                        cursor: "pointer",
                                                        position: "absolute",
                                                        right: 5,
                                                        bottom: 6,
                                                        fontSize: 18,
                                                        pointerEvents:
                                                            this.state.companyBannerTypeMobile ===
                                                            "uploadfromcomp" && "none",
                                                    }}
                                                    onClick={() =>
                                                        this.setState({ addStockImageBanner: true })
                                                    }
                                                />
                                            </div>
                                            {/* {this.state.bannerImageBigger && <label style={{ display: "flex", alignItems: "center", color: "red" }} className="dolabelsuppercase">Size is bigger</label>} */}
                                        </div>) : (<div
                                            className="form-miur"
                                            style={{ position: "relative" }}
                                        >
                                            <label
                                                style={{ display: "flex", alignItems: "center" }}
                                                className="dolabelsuppercase"
                                            >
                                                Eigen foto of video
                                            </label>
                                            <div className="wrapper-select-new">
                                                {this.state.companyBannerType ===
                                                    "uploadfromcomp" ? (
                                                    <input
                                                        type="text"
                                                        value="Upload"
                                                        readOnly
                                                        style={{
                                                            fontSize: 13,
                                                            letterSpacing: 1,
                                                            backgroundColor: "white",
                                                        }}
                                                        className="dolabelsuppercase"
                                                    />
                                                ) : (
                                                    <input
                                                        type="text"
                                                        value="Kies foto"
                                                        readOnly
                                                        style={{
                                                            fontSize: 13,
                                                            letterSpacing: 1,
                                                            backgroundColor: "white",
                                                        }}
                                                        className="dolabelsuppercase"
                                                    />
                                                )}
                                                {this.state.companyBannerType ===
                                                    "uploadfromcomp" && (
                                                        <input
                                                            type="file"
                                                            onChange={(e) => this.uploadBannerImage(e)}
                                                            accept="image/*"
                                                            style={{
                                                                position: "absolute",
                                                                width: "100%",
                                                                height: "80%",
                                                                top: 0,
                                                                left: 0,
                                                                right: 0,
                                                                bottom: 0,
                                                                opacity: 0,
                                                            }}
                                                        />
                                                    )}
                                                {this.state.companyBannerType ===
                                                    "takefromlibrary" && (
                                                        <div
                                                            style={{
                                                                position: "absolute",
                                                                width: "100%",
                                                                height: 40,
                                                                top: 0,
                                                                cursor: "pointer",
                                                                left: 0,
                                                                right: 0,
                                                                bottom: 0,
                                                                opacity: 0,
                                                            }}
                                                            onClick={() =>
                                                                this.setState({ addStockImageBanner: true })
                                                            }
                                                        ></div>
                                                    )}
                                                <PublishIcon
                                                    className="expand-more"
                                                    style={{
                                                        cursor: "pointer",
                                                        position: "absolute",
                                                        right: 5,
                                                        bottom: 6,
                                                        fontSize: 18,
                                                        pointerEvents:
                                                            this.state.companyBannerType ===
                                                            "uploadfromcomp" && "none",
                                                    }}
                                                    onClick={() =>
                                                        this.setState({ addStockImageBanner: true })
                                                    }
                                                />
                                            </div>
                                            {/* {this.state.bannerImageBigger && <label style={{ display: "flex", alignItems: "center", color: "red" }} className="dolabelsuppercase">Size is bigger</label>} */}
                                        </div>)}
                                        {/* )} */}
                                        {this.state.bannerImageBigger && (
                                            // {this.state.bannerImageBigger && this.state.isDesktop && (
                                            <div
                                                className="form-miur"
                                                style={{ position: "relative" }}
                                            >
                                                <label
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        color: "red",
                                                    }}
                                                    className="dolabelsuppercase labelDoRed"
                                                >
                                                    Size is bigger
                                                </label>
                                            </div>
                                        )}
                                        {!this.state.showDesktopLanding ? (<div className='form-miur'>
                                            <div className='image-wrappwr-fwcoco imagewithnewdesign'>
                                                <input />
                                                <div className="image-campaigns-new icn-newupdatedoneline">
                                                    <div
                                                        className="image-sfcplp"
                                                        style={{
                                                            backgroundImage: this.state.bannerImageMobile
                                                                ? `url("${this.state.bannerImageMobile}")`
                                                                : "none",
                                                        }}
                                                    >
                                                        {this.state.noBannerImageMobile && !this.state.bannerImageMobile && (
                                                            <video
                                                                muted
                                                                src={this.state.bannerVideoMobile ? this.state.bannerVideoMobile : "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/bg-bhodi.mp4?alt=media&token=a43a217e-8c6a-4b71-82a9-d6968fc74ee3&_gl=1*j4qhz8*_ga*MTU0MDE1MjU2OC4xNjk4ODQ5MzE3*_ga_CW55HF8NVT*MTY5OTAxNDA0OC40LjEuMTY5OTAxNDEyNi42MC4wLjA."}
                                                                autoPlay
                                                                loop
                                                                playsInline
                                                            ></video>
                                                        )}
                                                    </div>
                                                    {(this.state.bannerImageMobile || this.state.bannerVideoMobile) &&
                                                        <div
                                                            className="editimageupload"
                                                            onClick={() => {
                                                                let uid = localStorage.getItem("userid");
                                                                firebase
                                                                    .database()
                                                                    .ref("users/" + uid + "/banner_image_mobile")
                                                                    .set("");
                                                                firebase
                                                                    .database()
                                                                    .ref("users/" + uid + "/banner_video_mobile")
                                                                    .set("");
                                                            }}
                                                        >
                                                            <CloseIcon />
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>) : (<div className='form-miur'>
                                            <div className='image-wrappwr-fwcoco imagewithnewdesign'>
                                                <input />
                                                <div className="image-campaigns-new icn-newupdatedoneline">
                                                    <div
                                                        className="image-sfcplp"
                                                        style={{
                                                            backgroundImage: this.state.bannerImage
                                                                ? `url("${this.state.bannerImage}")`
                                                                : "none",
                                                        }}
                                                    >
                                                        {this.state.noBannerImage && !this.state.bannerImage && (
                                                            <video
                                                                muted
                                                                src={this.state.bannerVideo ? this.state.bannerVideo : "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/bg-bhodi.mp4?alt=media&token=a43a217e-8c6a-4b71-82a9-d6968fc74ee3&_gl=1*j4qhz8*_ga*MTU0MDE1MjU2OC4xNjk4ODQ5MzE3*_ga_CW55HF8NVT*MTY5OTAxNDA0OC40LjEuMTY5OTAxNDEyNi42MC4wLjA."}
                                                                autoPlay
                                                                loop
                                                                playsInline
                                                            ></video>
                                                        )}
                                                    </div>
                                                    {(this.state.bannerImage || this.state.bannerVideo) &&
                                                        <div
                                                            className="editimageupload"
                                                            onClick={() => {
                                                                let uid = localStorage.getItem("userid");
                                                                firebase
                                                                    .database()
                                                                    .ref("users/" + uid + "/banner_image")
                                                                    .set("");
                                                                firebase
                                                                    .database()
                                                                    .ref("users/" + uid + "/banner_video")
                                                                    .set("");
                                                            }}
                                                        >
                                                            <CloseIcon />
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>)}
                                        <div
                                            className="form-miur"
                                            style={{ position: "relative" }}
                                        >
                                            <label>Helderheid</label>
                                            <div className="wrapper-select-new">
                                                <select
                                                    value={this.state.bannerOpacity}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            bannerOpacity: Number(e.target.value),
                                                        });
                                                    }}
                                                >
                                                    <option value="0.1">0.1</option>
                                                    <option value="0.2">0.2</option>
                                                    <option value="0.3">0.3</option>
                                                    <option value="0.4">0.4</option>
                                                    <option value="0.5">0.5</option>
                                                    <option value="0.6">0.6</option>
                                                    <option value="0.7">0.7</option>
                                                    <option value="0.8">0.8</option>
                                                    <option value="0.9">0.9</option>
                                                </select>
                                                <ExpandMoreIcon
                                                    className="expand-more"
                                                    style={{
                                                        position: "absolute",
                                                        right: 5,
                                                        bottom: 5,
                                                        pointerEvents: "none",
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <button className="pink-save-button" onClick={() => {
                                            let uid = localStorage.getItem("userid");

                                            firebase
                                                .database()
                                                .ref("users/" + uid + "/company_banner_opacity")
                                                .set(Number(this.state.bannerOpacity));
                                            firebase
                                                .database()
                                                .ref("users/" + uid + "/company_banner_slogan")
                                                .set(this.state.companyBannerSlogan);
                                            firebase
                                                .database()
                                                .ref("users/" + uid + "/company_second_heading")
                                                .set(this.state.companySecondHeading);

                                            document.querySelector("html").style.overflowY = "auto";
                                            this.setState({ showUploadPopup: false });
                                        }}>OPSLAAN</button>
                                    </div>
                                </div>
                            </div>)}
                            {this.state.bannerImage ? (
                                <div
                                    className="first-sec-cdd nobgimages"
                                    style={{
                                        backgroundImage:
                                            this.state.bannerImage &&
                                            `url('${this.state.bannerImage}')`,
                                    }}
                                >
                                    <div
                                        className="fullopacitydiv"
                                        data-aos="zoom-in"
                                        data-aos-delay="20"
                                    >
                                        <h3>{this.state.currentUser.company_name}</h3>
                                        {this.state.companySecondHeading && (<h3>{this.state.companySecondHeading}</h3>)}
                                        {this.state.companyBannerSlogan !== "" ? (
                                            <p>{this.state.companyBannerSlogan} </p>
                                        ) : (
                                            <p>{this.state.companyPageText}</p>
                                        )}
                                        <p className='fourth-text-cbchn'>{this.state.dailyNewAddedText}</p>
                                    </div>
                                    <div
                                        className="opacitylayerfsc"
                                        style={{ opacity: this.state.bannerOpacity }}
                                    ></div>
                                </div>
                            ) : (
                                this.state.noBannerImage && (
                                    <div
                                        className="first-sec-cdd nobgimages videosectionfsc"
                                        style={{ overflow: "hidden" }}
                                    >
                                        {this.state.bannerVideo ? (
                                            <video
                                                src={this.state.bannerVideo}
                                                playsInline
                                                autoPlay
                                                muted
                                                loop
                                                id="bannervideo"
                                            ></video>
                                        ) : (
                                            <video
                                                src="https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/bg-bhodi.mp4?alt=media&token=a43a217e-8c6a-4b71-82a9-d6968fc74ee3&_gl=1*j4qhz8*_ga*MTU0MDE1MjU2OC4xNjk4ODQ5MzE3*_ga_CW55HF8NVT*MTY5OTAxNDA0OC40LjEuMTY5OTAxNDEyNi42MC4wLjA."
                                                autoPlay
                                                muted
                                                loop
                                                playsInline
                                            ></video>
                                        )}
                                        <div
                                            className="opacitylayerfsc"
                                            style={{ opacity: this.state.bannerOpacity }}
                                        ></div>
                                        <div
                                            className="fullopacitydiv"
                                            data-aos="zoom-in"
                                            data-aos-delay="20"
                                        >
                                            <h3>{this.state.currentUser.company_name}</h3>
                                            {this.state.companySecondHeading && (<h3>{this.state.companySecondHeading}</h3>)}
                                            {this.state.companyBannerSlogan !== "" ? (
                                                <p>{this.state.companyBannerSlogan} </p>
                                            ) : (
                                                <p>{this.state.companyPageText}</p>
                                            )}
                                            <p className='fourth-text-cbchn'>{this.state.dailyNewAddedText}</p>
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                )}



                {(this.state.addStockImageBanner && !this.state.showUploadPopup) ? (<div className='bannerpopupcompany bannerpopupcompanyupdated'>
                    <div className="rightmenuaddproduct rightmenuaddcategoryformfw rightmenuaddproductstockselection rightmenuproductstockselectionupdated updatedrightmenustock">
                        <div
                            className="mid-rmap"
                            style={{ position: "relative", padding: 13 }}
                        >
                            <span>SELECTEER AFBEELDING</span>
                            <ChevronLeft
                                style={{
                                    cursor: "pointer",
                                    position: "absolute",
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                    left: this.state.isDesktop ? 20 : 10,
                                    fontSize: 18,
                                }}
                                onClick={() =>
                                    this.setState({ addStockImageBanner: false })
                                }
                            />
                        </div>
                        <div className="bottom-rmap">
                            <div
                                className="mid-img-uploading-rmap"
                                style={{ alignItems: "flex-start" }}
                            >
                                <div
                                    className="form-miur"
                                    style={{ marginBottom: 30, position: "relative" }}
                                >
                                    <label style={{ color: "black" }}>TYPE BEDRIJF</label>
                                    <div className='wrapper-select-new'>
                                        <select
                                            value={this.state.mainSectorStock}
                                            onChange={(e) => {
                                                if (e.target.value !== "Selecteer") {
                                                    if (this.state.allImagesStock.length > 0) {
                                                        let filteredSecond =
                                                            this.state.allImagesStock.filter((u) => {
                                                                return u[0] === e.target.value;
                                                            });
                                                        if (filteredSecond.length > 0) {
                                                            let a = filteredSecond[0];
                                                            let arr = [];
                                                            if (a.length > 0) {
                                                                let objected = Object.entries(a[1]);
                                                                objected.map((y) => {
                                                                    arr.push(y[1]);
                                                                });

                                                                this.setState({
                                                                    allImagesCurrentSectorStock: arr,
                                                                });
                                                            } else {
                                                                this.setState({
                                                                    allImagesCurrentSectorStock: [],
                                                                });
                                                            }
                                                        }
                                                    }
                                                    this.setState({
                                                        mainSectorStock: e.target.value,
                                                        mainSectorStockMobile: e.target.value,
                                                    });
                                                }
                                            }}
                                        >
                                            {!this.state.mainSector && (
                                                <option disabled selected hidden value="">
                                                    Selecteer
                                                </option>
                                            )}
                                            {this.state.allSectors.map((g) => {
                                                return (
                                                    <option value={g.sector_id}>
                                                        {g.sector_name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                        <ExpandMoreIcon
                                            style={{
                                                position: "absolute",
                                                bottom: 5,
                                                right: 5,
                                                pointerEvents: "none",
                                            }}
                                            className='expand-more'
                                        />
                                    </div>
                                </div>
                                {this.state.allImagesCurrentSectorStock.length > 0 && (
                                    <div className="imagesstockselectionrightmenu">
                                        {this.state.allImagesCurrentSectorStock.map((y) => {
                                            return (
                                                <div
                                                    className="imagecontainerstockuploader"
                                                    style={{
                                                        backgroundImage: `url("${y.resizedUrl}")`,
                                                    }}
                                                    onClick={() => {
                                                        let uid = localStorage.getItem("userid");

                                                        if (!this.state.showDesktopLandingContact) {
                                                            firebase
                                                                .database()
                                                                .ref(
                                                                    "users/" + uid + "/company_about_type_mobile"
                                                                )
                                                                .set(this.state.companyAboutTypeMobile);
                                                            firebase
                                                                .database()
                                                                .ref("users/" + uid + "/contact_image_mobile")
                                                                .set(y.snapUrl);
                                                            firebase
                                                                .database()
                                                                .ref("users/" + uid + "/contactImageIsVideoMobile")
                                                                .set(false);
                                                        } else {
                                                            firebase
                                                                .database()
                                                                .ref(
                                                                    "users/" + uid + "/company_about_type"
                                                                )
                                                                .set(this.state.companyAboutType);
                                                            firebase
                                                                .database()
                                                                .ref("users/" + uid + "/contact_image")
                                                                .set(y.snapUrl);
                                                            firebase
                                                                .database()
                                                                .ref("users/" + uid + "/contactImageIsVideo")
                                                                .set(false);
                                                        }

                                                        if (window.innerWidth < 1009) {
                                                            this.setState({
                                                                addStockImageBanner: false,
                                                            });
                                                        }
                                                    }}
                                                >
                                                    {this.state.showDesktopLandingContact ? ((this.state.contactImage && this.state.contactImage === y.snapUrl) && <div className="overlay-icontstup">
                                                        <CheckCircleIcon />
                                                    </div>) : ((this.state.contactImageMobile && this.state.contactImageMobile === y.snapUrl) && <div className="overlay-icontstup">
                                                        <CheckCircleIcon />
                                                    </div>)}
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>) : this.state.showUploadPopupContact && (<div className="rightmenuaddproduct rightmenuupdateddesigngray updatedirghtmenunew newrightmenuglobalgood">
                    <div
                        className="mid-rmap"
                        style={{ position: "relative", padding: 13 }}
                    >
                        <span>ABOUTPAGINA</span>
                        <div className="top-header-infocont">
                            <div style={{ position: "relative" }}>
                                <div className='green-round-info' style={{ cursor: "pointer" }} onClick={() => {
                                    this.setState({ infoRightMenuOpen: !this.state.infoRightMenuOpen })
                                }}>
                                    <font>?</font>
                                </div>
                                <div className="popup-gri-thifc" style={{ opacity: this.state.infoRightMenuOpen ? 1 : 0, pointerEvents: this.state.infoRightMenuOpen ? "all" : "none" }}>
                                    <CloseIcon onClick={() => this.setState({ infoRightMenuOpen: false })} />
                                    <p style={{ fontSize: 15 }}>
                                        Personaliseer je bedrijfspagina met <br /> een
                                        actuele omschrijving en voeg de <br /> perfecte
                                        (eigen) foto of video toe
                                    </p>
                                </div>
                            </div>
                            <CloseIcon
                                onClick={() => {
                                    if (this.state.currentUser) {
                                        let a = this.state.currentUser
                                        if (a.company_about) {
                                            this.setState({
                                                company_about: a.company_about,
                                            });
                                        }
                                    }

                                    document.querySelector("html").style.overflowY = "auto";
                                    this.setState({ showUploadPopupContact: false, contactImageBigger: false, infoRightMenuOpen: false });
                                }}
                                style={{
                                    cursor: "pointer",
                                    position: "absolute",
                                    fontSize: 18,
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                    right: 20,
                                }}
                            />
                        </div>
                    </div>
                    <div className="bottom-rmap">
                        <div className="mid-img-uploading-rmap">
                            <div className="form-miur">
                                <label>Omschrijving</label>
                                <textarea
                                    value={this.state.company_about}
                                    onChange={(e) => {
                                        this.setState({
                                            company_about: e.target.value,
                                        });
                                    }}
                                    style={{
                                        minHeight: 250,
                                        fontSize: 13,
                                        letterSpacing: 1,
                                    }}
                                    maxLength={163}
                                ></textarea>
                            </div>
                            <div
                                className="form-miur"
                                style={{ position: "relative" }}
                            >
                                <label className="dolabelsuppercase">
                                    <font>Achtergrond</font>
                                    <div>
                                        <div className={`round-icons-mobilepics ${this.state.showDesktopLandingContact && "active"}`} onClick={() => {
                                            this.setState({ showDesktopLandingContact: true })
                                        }}>
                                            <GoDeviceDesktop />
                                        </div>
                                        {/* <div className="spacing-between-icons"></div> */}
                                        <div className={`round-icons-mobilepics  ${!this.state.showDesktopLandingContact && "active"}`} onClick={() => {
                                            this.setState({ showDesktopLandingContact: false })
                                        }}>
                                            <CiMobile3 />
                                        </div>
                                    </div>
                                </label>
                                {/* </div> */}
                                <div className="wrapper-select-new">
                                    <select
                                        value={this.state.showDesktopLandingContact ? this.state.companyAboutType : this.state.companyAboutTypeMobile}
                                        style={{
                                            fontSize: 13,
                                            letterSpacing: 1,
                                            color: "#8c8c8c",
                                        }}
                                        onChange={(e) => {
                                            if (!this.state.showDesktopLandingContact) {
                                                this.setState({
                                                    companyAboutTypeMobile: e.target.value
                                                })
                                            }
                                            this.setState({
                                                companyAboutType: e.target.value,
                                            });
                                        }}
                                    >
                                        <option value="uploadfromcomp">Eigen foto</option>
                                        <option value="takefromlibrary">
                                            Fotobibliotheek
                                        </option>
                                    </select>
                                    <ExpandMoreIcon
                                        style={{
                                            cursor: "pointer",
                                            position: "absolute",
                                            right: 5,
                                            bottom: 6,
                                            fontSize: 18,
                                            pointerEvents: "none",
                                            pointerEvents: "none",
                                        }}
                                        className="expand-more"
                                    />
                                </div>
                            </div>
                            <div
                                className="form-miur formfullwidthftcic contact-info-form-label"
                                style={{ position: "relative" }}
                            >
                                <label>
                                    Show Contact Info
                                </label>
                            </div>
                            <button className='contact-info-switch'>
                                <Switch
                                    color="primary"
                                    inputProps={{ "aria-label": "primary checkbox" }}
                                    checked={!this.state.hideContactInfo}
                                    onClick={() => {
                                        this.setState({
                                            hideContactInfo: !this.state.hideContactInfo,
                                        });
                                    }}
                                />
                                {!this.state.hideContactInfo ? (
                                    <label>Showing</label>
                                ) : (
                                    <label>Hideen</label>
                                )}
                            </button>
                            {/* )} */}
                            {/* {this.state.isDesktop && ( */}
                            {!this.state.showDesktopLandingContact ? (<div
                                className="form-miur"
                                style={{ position: "relative" }}
                            >
                                <label
                                    style={{ display: "flex", alignItems: "center" }}
                                    className="dolabelsuppercase"
                                >
                                    Eigen foto of video
                                </label>
                                <div className="wrapper-select-new">
                                    {this.state.companyAboutTypeMobile ===
                                        "uploadfromcomp" ? (
                                        <input
                                            type="text"
                                            value="Upload"
                                            readOnly
                                            style={{
                                                fontSize: 13,
                                                letterSpacing: 1,
                                                backgroundColor: "white",
                                            }}
                                            className="dolabelsuppercase"
                                        />
                                    ) : (
                                        <input
                                            type="text"
                                            value="Kies foto"
                                            readOnly
                                            style={{
                                                fontSize: 13,
                                                letterSpacing: 1,
                                                backgroundColor: "white",
                                            }}
                                            className="dolabelsuppercase"
                                        />
                                    )}
                                    {this.state.companyAboutTypeMobile ===
                                        "uploadfromcomp" && (
                                            <input
                                                type="file"
                                                onChange={(e) => this.uploadContactImage(e)}
                                                accept="image/*, video/*"
                                                style={{
                                                    position: "absolute",
                                                    width: "100%",
                                                    height: "80%",
                                                    top: 0,
                                                    left: 0,
                                                    right: 0,
                                                    bottom: 0,
                                                    opacity: 0,
                                                }}
                                            />
                                        )}
                                    {this.state.companyAboutTypeMobile ===
                                        "takefromlibrary" && (
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    width: "100%",
                                                    height: 40,
                                                    top: 0,
                                                    cursor: "pointer",
                                                    left: 0,
                                                    right: 0,
                                                    bottom: 0,
                                                    opacity: 0,
                                                }}
                                                onClick={() =>
                                                    this.setState({ addStockImageBanner: true })
                                                }
                                            ></div>
                                        )}
                                    <PublishIcon
                                        className="expand-more"
                                        style={{
                                            cursor: "pointer",
                                            position: "absolute",
                                            right: 5,
                                            bottom: 6,
                                            fontSize: 18,
                                            pointerEvents:
                                                this.state.companyAboutTypeMobile ===
                                                "uploadfromcomp" && "none",
                                        }}
                                        onClick={() =>
                                            this.setState({ addStockImageBanner: true })
                                        }
                                    />
                                </div>
                                {/* {this.state.bannerImageBigger && <label style={{ display: "flex", alignItems: "center", color: "red" }} className="dolabelsuppercase">Size is bigger</label>} */}
                            </div>) : (<div
                                className="form-miur"
                                style={{ position: "relative" }}
                            >
                                <label
                                    style={{ display: "flex", alignItems: "center" }}
                                    className="dolabelsuppercase"
                                >
                                    Eigen foto of video
                                </label>
                                <div className="wrapper-select-new">
                                    {this.state.companyAboutType ===
                                        "uploadfromcomp" ? (
                                        <input
                                            type="text"
                                            value="Upload"
                                            readOnly
                                            style={{
                                                fontSize: 13,
                                                letterSpacing: 1,
                                                backgroundColor: "white",
                                            }}
                                            className="dolabelsuppercase"
                                        />
                                    ) : (
                                        <input
                                            type="text"
                                            value="Kies foto"
                                            readOnly
                                            style={{
                                                fontSize: 13,
                                                letterSpacing: 1,
                                                backgroundColor: "white",
                                            }}
                                            className="dolabelsuppercase"
                                        />
                                    )}
                                    {this.state.companyAboutType ===
                                        "uploadfromcomp" && (
                                            <input
                                                type="file"
                                                onChange={(e) => this.uploadContactImage(e)}
                                                accept="image/*, video/*"
                                                style={{
                                                    position: "absolute",
                                                    width: "100%",
                                                    height: "80%",
                                                    top: 0,
                                                    left: 0,
                                                    right: 0,
                                                    bottom: 0,
                                                    opacity: 0,
                                                }}
                                            />
                                        )}
                                    {this.state.companyAboutType ===
                                        "takefromlibrary" && (
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    width: "100%",
                                                    height: 40,
                                                    top: 0,
                                                    cursor: "pointer",
                                                    left: 0,
                                                    right: 0,
                                                    bottom: 0,
                                                    opacity: 0,
                                                }}
                                                onClick={() =>
                                                    this.setState({ addStockImageBanner: true })
                                                }
                                            ></div>
                                        )}
                                    <PublishIcon
                                        className="expand-more"
                                        style={{
                                            cursor: "pointer",
                                            position: "absolute",
                                            right: 5,
                                            bottom: 6,
                                            fontSize: 18,
                                            pointerEvents:
                                                this.state.companyAboutType ===
                                                "uploadfromcomp" && "none",
                                        }}
                                        onClick={() =>
                                            this.setState({ addStockImageBanner: true })
                                        }
                                    />
                                </div>
                                {/* {this.state.bannerImageBigger && <label style={{ display: "flex", alignItems: "center", color: "red" }} className="dolabelsuppercase">Size is bigger</label>} */}
                            </div>)}
                            {/* )} */}
                            {this.state.contactImageBigger && (
                                // {this.state.bannerImageBigger && this.state.isDesktop && (
                                <div
                                    className="form-miur"
                                    style={{ position: "relative" }}
                                >
                                    <label
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            color: "red",
                                        }}
                                        className="dolabelsuppercase labelDoRed"
                                    >
                                        Size is bigger
                                    </label>
                                </div>
                            )}
                            {(!this.state.showDesktopLandingContact && this.state.contactImageMobile) ? (<div className="image-campaigns-new icn-edit-company" style={{ marginTop: 20 }}>
                                <div
                                    className="image-sfcplp"
                                    style={{
                                        backgroundImage: (this.state.contactImageMobile && !this.state.contactImageIsVideoMobile)
                                            ? `url("${this.state.contactImageMobile}")`
                                            : "none",
                                    }}
                                >
                                    {this.state.contactImageIsVideoMobile && this.state.contactImageMobile && (
                                        <video
                                            muted
                                            src={this.state.contactImageMobile ? this.state.contactImageMobile : "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/bg-bhodi.mp4?alt=media&token=a43a217e-8c6a-4b71-82a9-d6968fc74ee3&_gl=1*j4qhz8*_ga*MTU0MDE1MjU2OC4xNjk4ODQ5MzE3*_ga_CW55HF8NVT*MTY5OTAxNDA0OC40LjEuMTY5OTAxNDEyNi42MC4wLjA."}
                                            autoPlay
                                            loop
                                            playsInline
                                        ></video>
                                    )}
                                </div>
                                {this.state.contactImageMobile &&
                                    <div
                                        className="editimageupload"
                                        onClick={() => {
                                            let uid = localStorage.getItem("userid");
                                            firebase
                                                .database()
                                                .ref("users/" + uid + "/contact_image_mobile")
                                                .set("");
                                            firebase
                                                .database()
                                                .ref("users/" + uid + "/contactImageIsVideoMobile")
                                                .set(false);
                                        }}
                                    >
                                        <CloseIcon />
                                    </div>
                                }
                            </div>) : this.state.contactImage && (<div className="image-campaigns-new icn-edit-company" style={{ marginTop: 20 }}>
                                <div
                                    className="image-sfcplp"
                                    style={{
                                        backgroundImage: (this.state.contactImage && !this.state.contactImageIsVideo)
                                            ? `url("${this.state.contactImage}")`
                                            : "none",
                                    }}
                                >
                                    {this.state.contactImageIsVideo && this.state.contactImage && (
                                        <video
                                            muted
                                            src={this.state.contactImage ? this.state.contactImage : "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/bg-bhodi.mp4?alt=media&token=a43a217e-8c6a-4b71-82a9-d6968fc74ee3&_gl=1*j4qhz8*_ga*MTU0MDE1MjU2OC4xNjk4ODQ5MzE3*_ga_CW55HF8NVT*MTY5OTAxNDA0OC40LjEuMTY5OTAxNDEyNi42MC4wLjA."}
                                            autoPlay
                                            loop
                                            playsInline
                                        ></video>
                                    )}
                                </div>
                                {this.state.contactImage &&
                                    <div
                                        className="editimageupload"
                                        onClick={() => {
                                            let uid = localStorage.getItem("userid");
                                            firebase
                                                .database()
                                                .ref("users/" + uid + "/contact_image")
                                                .set("");
                                            firebase
                                                .database()
                                                .ref("users/" + uid + "/contactImageIsVideo")
                                                .set(false);
                                        }}
                                    >
                                        <CloseIcon />
                                    </div>
                                }
                            </div>)}
                            <button className="pink-save-button" onClick={() => {
                                let uid = localStorage.getItem("userid");

                                firebase
                                    .database()
                                    .ref("users/" + uid + "/company_about")
                                    .set(this.state.company_about);
                                firebase
                                    .database()
                                    .ref("users/" + uid + "/hideContactInfo")
                                    .set(this.state.hideContactInfo);

                                document.querySelector("html").style.overflowY = "auto";
                                this.setState({ showUploadPopupContact: false });
                            }}>OPSLAAN</button>
                        </div>
                    </div>
                </div>)}

                {this.state.showImpressiePopup && (<ImpressiePopup member_user_id={this.props.member_user_id} closeImpressie={() => {
                    this.setState({ showImpressiePopup: false })
                }} />)}

                {this.state.storyOpen && (<div className='complete-video-lpn' style={{ zIndex: 999999999 }}>
                    <div className='inner-cont-cvlpn' style={{ backgroundColor: "black" }}>
                        <MdClose className='icon-person-top-lpn' style={{ color: "white" }} onClick={() => {
                            this.setState({ storyOpen: false })
                        }} />
                        <video
                            src="https://video.bhodi.com/About-Bhodi.mp4"
                            autoPlay
                            loop
                            playsInline
                            controls
                        ></video>
                    </div>
                </div>)}

                {this.state.showRightMenuViewerFriends && (
                    <div
                        className="overlay-div-openadd"
                        onClick={() => {
                            document.querySelector("html").style.overflowY = "auto"
                            this.setState({ showRightMenuViewerFriends: false });
                        }}
                    ></div>
                )}
                {this.state.showRightMenuViewerFriends && (
                    <ViewerFriendlist
                        viewerOnUser={this.state.currentUser}
                        closePopup={() => {
                            document.querySelector("html").style.overflowY = "auto"
                            this.setState({ showRightMenuViewerFriends: false });
                        }}
                    />
                )}


                {this.state.qrPopupOpen && (<div className='qr-code-popup'>
                    <div className='qcp-inner'>
                        <MdClose className='close-qcp' onClick={() => {
                            this.setState({ qrPopupOpen: false, qrCodeLink: "", currentQrQuery: "" })
                            firebase.database().ref(this.state.currentQrQuery).off()
                        }} />
                        <h3 className='heading-qcp'>QR Code</h3>
                        <p className='desc-qcp'>Here goes some dummy text Here goes some dummy text Here goes some dummy text Here goes some dummy text Here goes some dummy text Here goes some dummy text Here goes some dummy text Here goes some dummy text</p>
                        <div className='container-code-qcp'>
                            <QRCodeCanvas value={this.state.qrCodeLink} size={250} />
                        </div>
                    </div>
                </div>)}

                {window.innerWidth > 1008 && (<Footer notOnMainPage={true} />)}
                {window.innerWidth < 1008 && (<FooterGreen notOnMainPage={true} />)}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        bhodi_products: state.productsReducers.bhodi_products,
        global_campaigns: state.campaignsReducer.global_campaigns,
        global_values: state.globalReducers.global_values,
        globalsectors: state.categoriesReducer.globalsectors,
        stock_images: state.globalReducers.stock_images,
        // all_impressies: state.globalReducers.all_impressies,
        user: state.authReducers.user,
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        updateCampaignsCategory: (campaigns_category) => dispatch(updateCampaignsCategory(campaigns_category)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MemberHome);