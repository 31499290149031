import React, { Component } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { Country, State, City } from "country-state-city";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import firebase from "../Config/Firebase";
import { connect } from "react-redux";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
  geocodeByAddress,
} from "react-google-places-autocomplete";
import FilterIconGlobalNavVoucher from "./FilterIconGlobalNavVoucher";
import { history } from "../Config/Routes";
let completeCities = City.getCitiesOfCountry("NL");

const axios = require("axios");

export class NavGlobalVoucherCity extends Component {
  constructor() {
    super();
    this.state = {
      currentUserCity: "",
      currentCategoryId: "",
      allProducts: [],
      allVouchers: [],
      allCampaigns: [],
      allEvents: [],
      allComapniesToRender: [],
      latitude: "",
      longitude: "",
      allCompaniesFiltered: [],
      allAtzUsers: [],
      allCategories: [],
      availableMainCategories: [],
      availableSubCategories: [],
      allProductsFiltered: [],
      allProductsFilteredDup: [],
      categoriesDropdown: [],
      checkedToFilter: [],
      allCitiesFiltered: [],
      citiesMain: [
        {
          countryCode: "NL",
          latitude: "52.37403000",
          longitude: "4.88969000",
          name: "Amsterdam",
          stateCode: "NH",
        },
        {
          countryCode: "NL",
          latitude: "51.92250000",
          longitude: "4.47917000",
          name: "Rotterdam",
          stateCode: "ZH",
        },
        {
          countryCode: "NL",
          latitude: "52.09083000",
          longitude: "5.12222000",
          name: "Utrecht",
          stateCode: "UT",
        },
        {
          countryCode: "NL",
          latitude: "52.06866000",
          longitude: "4.28635000",
          name: "Gemeente Den Haag",
          stateCode: "ZH",
        },
        {
          countryCode: "NL",
          latitude: "53.21917000",
          longitude: "6.56667000",
          name: "Groningen",
          stateCode: "GR",
        },
        {
          countryCode: "NL",
          latitude: "51.44083000",
          longitude: "5.47778000",
          name: "Eindhoven",
          stateCode: "NB",
        },
        {
          countryCode: "NL",
          latitude: "51.58656000",
          longitude: "4.77596000",
          name: "Breda",
          stateCode: "NB",
        },
        {
          countryCode: "NL",
          latitude: "51.69917000",
          longitude: "5.30417000",
          name: "'s-Hertogenbosch",
          stateCode: "NB",
        },
        {
          countryCode: "NL",
          latitude: "51.84250000",
          longitude: "5.85278000",
          name: "Nijmegen",
          stateCode: "GE",
        },
        {
          countryCode: "NL",
          latitude: "50.84833000",
          longitude: "5.68889000",
          name: "Maastricht",
          stateCode: "LI",
        },
        {
          countryCode: "NL",
          latitude: "52.38084000",
          longitude: "4.63683000",
          name: "Haarlem",
          stateCode: "NH",
        },
        {
          countryCode: "NL",
          latitude: "51.98000000",
          longitude: "5.91111000",
          name: "Arnhem",
          stateCode: "GE",
        },
        {
          countryCode: "NL",
          latitude: "52.21833000",
          longitude: "6.89583000",
          name: "Enschede",
          stateCode: "OV",
        },
        {
          countryCode: "NL",
          latitude: "52.15500000",
          longitude: "5.38750000",
          name: "Amersfoort",
          stateCode: "UT",
        },
        {
          countryCode: "NL",
          latitude: "52.51250000",
          longitude: "6.09444000",
          name: "Zwolle",
          stateCode: "OV",
        },
        {
          countryCode: "NL",
          latitude: "52.37025000",
          longitude: "5.21413000",
          name: "Almere Stad",
          stateCode: "FL",
        },
        {
          countryCode: "NL",
          latitude: "51.55551000",
          longitude: "5.09130000",
          name: "Tilburg",
          stateCode: "NB",
        },
        {
          countryCode: "NL",
          latitude: "52.63167000",
          longitude: "4.74861000",
          name: "Alkmaar",
          stateCode: "NH",
        },
      ],
      selectedAddress: "",
      cityText: "",
    };
  }

  componentDidMount() {
    const loaderId = document.getElementById("main-page-loader-id");
    loaderId.style.display = "none";
    let uid = localStorage.getItem("userid");

    // let currentUserData = localStorage.getItem("userdata")
    // if (currentUserData) {
    //     let parsed = JSON.parse(currentUserData)
    //     if (parsed.type === "user") {
    //         navigator.geolocation.getCurrentPosition((position) => {
    //             firebase.database().ref("users/" + uid + "/latitude").set(position.coords.latitude)
    //             firebase.database().ref("users/" + uid + "/longitude").set(position.coords.longitude)
    //             this.setState({ latitude: position.coords.latitude, longitude: position.coords.longitude })
    //         });
    //     } else {
    //         navigator.geolocation.getCurrentPosition((position) => {
    //             this.setState({ latitude: position.coords.latitude, longitude: position.coords.longitude })
    //         });
    //     }
    // }

    // firebase.database().ref("users/" + uid).on("value", (data) => {
    //     let a = data.val()
    //     if (a.latitude && a.longitude && a.type === "user") {
    //         var config = {
    //             method: 'get',
    //             url: `https://maps.googleapis.com/maps/api/geocode/json?latlng=${a.latitude},${a.longitude}&key=${process.env.REACT_APP_GEOCODE_KEY}`,
    //             headers: {}
    //         };

    //         axios(config)
    //             .then((response) => {
    //                 if (response.data.results) {
    //                     let addresses = response.data.results[0].address_components
    //                     let city = ""
    //                     for (var i = 0; i < addresses.length; i++) {
    //                         for (var b = 0; b < addresses[i].types.length; b++) {
    //                             if (addresses[i].types[b] == "locality") {
    //                                 city = addresses[i];
    //                                 break;
    //                             }
    //                         }
    //                     }
    //                     let byDefaultCity = localStorage.getItem("selectedCityByDefault")
    //                     if (byDefaultCity) {
    //                         if (this.props.noCityFiltered) {
    //                             localStorage.setItem("selectedCityByDefault", "")
    //                             this.setState({ currentUserCity: city.short_name })
    //                         } else {
    //                             this.setState({ currentUserCity: byDefaultCity })
    //                         }
    //                     } else {
    //                         this.setState({ currentUserCity: city.short_name })
    //                     }
    //                     firebase.database().ref("users/" + uid + "/city").set(city.short_name)
    //                 }
    //             })
    //             .catch((error) => {
    //                 console.log(error);
    //             });
    //     } else {
    //         let byDefaultCity = localStorage.getItem("selectedCityByDefault")
    //         if (byDefaultCity) {
    //             if (this.props.noCityFiltered) {
    //                 localStorage.setItem("selectedCityByDefault", "")
    //                 this.setState({ currentUserCity: a.city })
    //             } else {
    //                 this.setState({ currentUserCity: byDefaultCity })
    //             }
    //         } else {
    //             this.setState({ currentUserCity: a.city })
    //         }
    //     }
    // })

    let byDefaultCity = localStorage.getItem("selectedCityByDefault");
    if (byDefaultCity) {
      if (!this.props.noCityFiltered) {
        this.setState({ currentUserCity: byDefaultCity });
      }
    }

    let promiseForUser = new Promise((res, rej) => {
      // let currentUserData = localStorage.getItem("userdata")
      // if (currentUserData) {
      //     let parsed = JSON.parse(currentUserData)
      //     if (parsed.type === "user") {
      //         navigator.geolocation.getCurrentPosition((position) => {
      //             firebase.database().ref("users/" + uid + "/latitude").set(position.coords.latitude)
      //             firebase.database().ref("users/" + uid + "/longitude").set(position.coords.longitude)
      //             this.setState({ latitude: position.coords.latitude, longitude: position.coords.longitude })
      //             res()
      //         });
      //     } else {
      //         navigator.geolocation.getCurrentPosition((position) => {
      //             this.setState({ latitude: position.coords.latitude, longitude: position.coords.longitude })
      //             res()
      //         });
      //     }
      // }
      let latitude = localStorage.getItem("latitudeAfterLogin");
      let longitude = localStorage.getItem("longitudeAfterLogin");
      if (latitude) {
        this.setState({ latitude, longitude });
      }
      res();
    });

    promiseForUser.then(() => {
      firebase
        .database()
        .ref("users/" + uid)
        .on("value", (data) => {
          let a = data.val();
          // if (this.props.noCityFiltered) {
          //     if (a.city) {
          //         this.setState({ currentUserCity: a.city })
          //     }
          // }
          if (a.latitude && a.longitude && a.type === "user") {
            var config = {
              method: "get",
              url: `https://maps.googleapis.com/maps/api/geocode/json?latlng=${a.latitude},${a.longitude}&key=${process.env.REACT_APP_GEOCODE_KEY}`,
              headers: {},
            };

            axios(config)
              .then((response) => {
                if (response.data.results) {
                  let addresses = response.data.results[0].address_components;
                  let city = "";
                  for (var i = 0; i < addresses.length; i++) {
                    for (var b = 0; b < addresses[i].types.length; b++) {
                      if (addresses[i].types[b] == "locality") {
                        city = addresses[i];
                        break;
                      }
                    }
                  }
                  let byDefaultCity = localStorage.getItem(
                    "selectedCityByDefault"
                  );
                  if (byDefaultCity) {
                    if (this.props.noCityFiltered) {
                      localStorage.setItem("selectedCityByDefault", "");
                      this.setState({ currentUserCity: city.short_name });
                    } else {
                      this.setState({ currentUserCity: byDefaultCity });
                    }
                  } else {
                    this.setState({ currentUserCity: city.short_name });
                  }
                  firebase
                    .database()
                    .ref("users/" + uid + "/city")
                    .set(city.short_name);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            if (this.state.latitude && this.state.longitude) {
              var config = {
                method: "get",
                url: `https://maps.googleapis.com/maps/api/geocode/json?latlng=${this.state.latitude},${this.state.longitude}&key=${process.env.REACT_APP_GEOCODE_KEY}`,
                headers: {},
              };

              axios(config)
                .then((response) => {
                  if (response.data.results) {
                    let addresses = response.data.results[0].address_components;
                    let city = "";
                    for (var i = 0; i < addresses.length; i++) {
                      for (var b = 0; b < addresses[i].types.length; b++) {
                        if (addresses[i].types[b] == "locality") {
                          city = addresses[i];
                          break;
                        }
                      }
                    }
                    let byDefaultCity = localStorage.getItem(
                      "selectedCityByDefault"
                    );
                    if (byDefaultCity) {
                      if (this.props.noCityFiltered) {
                        localStorage.setItem("selectedCityByDefault", "");
                        this.setState({ currentUserCity: city.short_name });
                      } else {
                        this.setState({ currentUserCity: byDefaultCity });
                      }
                    } else {
                      this.setState({ currentUserCity: city.short_name });
                    }
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              let byDefaultCity = localStorage.getItem("selectedCityByDefault");
              if (byDefaultCity && !this.props.noCityFiltered) {
                this.setState({ currentUserCity: byDefaultCity });
              } else {
                this.setState({ currentUserCity: a.city });
              }
              // this.setState({ currentUserCity: a.city });
            }
          }
        });
    });
  }

  render() {
    return (
      <div>
        {this.state.bottomModal && <div className="overlay-mtb"></div>}
        <div
          className="navbartoshowcaategoryvouchers listsinanotherway"
          style={{ backgroundColor: "#FDFDFD" }}
        >
          <div className="maincategories-nscv">
            {/* <div className="lists-nfwpm">
                            <ul>
                                {this.state.allCategories.length > 0 && this.state.allCategories.map((e) => {
                                    if (this.state.availableMainCategories.includes(e.category_id)) {
                                        return <li onClick={() => {
                                            if (e.children) {
                                                this.setState({ subCategoriesCurrent: e.children })
                                                if (window.innerWidth < 1009) {
                                                    document.getElementById("root").style.position = "fixed"
                                                    document.getElementById("root").style.overflowY = "hidden"
                                                }
                                                this.setState({ currentCategoryId: e.category_id, bottomModal: true, currentCategoryName: e.title })
                                            } else {
                                                localStorage.setItem("subcategoryidProduct", e.category_id)
                                                window.location.href = `/global-vouchers/${this.props.sector}/filterbyCategory/${e.category_id}`
                                            }
                                        }}>{e.title}  {e.children && <KeyboardArrowDownIcon />}</li>
                                    }
                                })}
                            </ul>
                        </div> */}
            <div className="lists-nfwpm">
              <ul>
                {this.props.cityOnNewDesign && (<FilterIconGlobalNavVoucher
                  sector={this.props.sector}
                  showAsList={true}
                  onPopupOpen={(availableMainCategories, allCategories) => {
                    this.setState({ availableMainCategories, allCategories, showFiltersModalVouchers: true })
                  }}
                />)}
                {this.props.cityOnNewDesign ? <li
                  style={{
                    border: "none",
                    fontWeight: 600,
                    paddingLeft: 0,
                    marginLeft: 0,
                  }}
                  onClick={() => {
                    if (window.innerWidth < 1009) {
                      document.getElementById("root").style.position = "fixed";
                      document.getElementById("root").style.overflowY =
                        "hidden";
                    }
                    this.setState({
                      currentCategoryId: "city",
                      bottomModal: true,
                      currentCategoryName: this.state.currentUserCity ? this.state.currentUserCity : "LOCATIE",
                    });
                  }}
                >
                  <LocationOnIcon />
                  {/* &nbsp;
                  {this.state.currentUserCity
                    ? this.state.currentUserCity
                    : "City"}{" "}| Vouchers */}
                </li> : (<li
                  style={{
                    border: "none",
                    fontWeight: 600,
                    paddingLeft: 0,
                    marginLeft: 0,
                  }}
                  onClick={() => {
                    if (window.innerWidth < 1009) {
                      document.getElementById("root").style.position = "fixed";
                      document.getElementById("root").style.overflowY =
                        "hidden";
                    }
                    this.setState({
                      currentCategoryId: "city",
                      bottomModal: true,
                      currentCategoryName: "LOCATIE",
                    });
                  }}
                >
                  Locatie:{" "}
                  {this.state.currentUserCity
                    ? this.state.currentUserCity
                    : "City"}{" "}
                  <KeyboardArrowDownIcon />{" "}
                </li>)}
              </ul>
            </div>
          </div>
          <div
            className="modal-to-bottom"
            style={{
              right: this.state.bottomModal ? 0 : "-100%",
              width: this.state.bottomModal ? "100%" : 0,
              pointerEvents: this.state.bottomModal ? "all" : "none",
              position: "fixed",
              left: "auto",
            }}
          >
            {this.state.bottomModal && (
              <div className="heading-ito-mtb">
                <CloseIcon
                  onClick={() => {
                    if (window.innerWidth < 1009) {
                      document.getElementById("root").style.position = "static";
                      document.getElementById("root").style.overflowY = "auto";
                    }
                    this.setState({ bottomModal: false });
                  }}
                />
                <p>{this.state.currentCategoryName}</p>
              </div>
            )}
            {this.state.currentCategoryId === "city" && (
              <div
                style={{
                  width: "100%",
                  padding: "10px 20px",
                  paddingBottom: 0,
                  marginTop: 20,
                }}
              >
                {/* {this.state.currentCategoryId === "city" && <div className="search-button-city"> */}
                {/* <input type="text" placeholder="Zoek" value={this.state.cityText} onChange={(e) => {
                                this.setState({ cityText: e.target.value })
                                if (e.target.value === "") {
                                    this.setState({ allCitiesFiltered: [], noFilteredCities: false })
                                } else {
                                    let filtered = completeCities.filter((y) => {
                                        return y.name.toLowerCase().includes(e.target.value.toLowerCase())
                                    })
                                    if (filtered.length > 0) {
                                        this.setState({ allCitiesFiltered: filtered, noFilteredCities: false })
                                    } else {
                                        this.setState({ noFilteredCities: true })
                                    }
                                }
                            }} /> */}
                <GooglePlacesAutocomplete
                  apiKey="AIzaSyCI9gcvJzXk9tik68vE7YGIWlppZs7bRYI"
                  selectProps={{
                    value: this.state.selectedAddress,
                    onChange: (val) => {
                      this.setState({ selectedAddress: val });
                      geocodeByPlaceId(val.value.place_id)
                        .then((results) => {
                          let filtered = results[0].address_components.filter(
                            (e) => {
                              return (
                                e.types[0] === "locality" &&
                                e.types[1] === "political"
                              );
                            }
                          );
                          getLatLng(results[0]).then((e) => {
                            if (filtered.length > 0) {
                              localStorage.setItem(
                                "selectedCityByDefault",
                                filtered[0].long_name
                              );
                            }
                            if (this.props.onMainSector) {
                              window.location.href = `/global-vouchers/${this.props.sector}/filterByCity/getNearby=${e.lat}&${e.lng}`;
                            }
                            if (this.props.onMainCategory) {
                              window.location.href = `/global-vouchers/${this.props.sector}/filterbyCategory/${this.props.category}/filterByCity/getNearby=${e.lat}&${e.lng}`;
                            }
                          });
                        })
                        .catch((error) => console.error(error));
                    },
                  }}
                />
              </div>
            )}
            {this.state.bottomModal && (
              <ul
                className={
                  this.state.currentCategoryId === "city" &&
                  "doflewlistsworkbottom"
                }
              >
                {this.state.currentCategoryId === "city"
                  ? this.state.allCitiesFiltered.length > 0
                    ? this.state.allCitiesFiltered.map((e, i) => {
                      return (
                        <li>
                          <p
                            onClick={() => {
                              if (this.props.onMainSector) {
                                var config = {
                                  method: "get",
                                  url: `https://maps.googleapis.com/maps/api/geocode/json?address=${e.name},Netherlands&key=${process.env.REACT_APP_GEOCODE_KEY}`,
                                  headers: {},
                                };

                                axios(config)
                                  .then((response) => {
                                    if (response.data.results) {
                                      let latlong =
                                        response.data.results[0].geometry
                                          .location;
                                      localStorage.setItem(
                                        "selectedCityByDefault",
                                        e.name
                                      );
                                      window.location.href = `/global-vouchers/${this.props.sector}/filterByCity/getNearby=${latlong.lat}&${latlong.lng}`;
                                    }
                                  })
                                  .catch((error) => {
                                    console.log(error);
                                  });
                              } else if (this.props.onMainCategory) {
                                var config = {
                                  method: "get",
                                  url: `https://maps.googleapis.com/maps/api/geocode/json?address=${e.name},Netherlands&key=${process.env.REACT_APP_GEOCODE_KEY}`,
                                  headers: {},
                                };

                                axios(config)
                                  .then((response) => {
                                    if (response.data.results) {
                                      let latlong =
                                        response.data.results[0].geometry
                                          .location;
                                      localStorage.setItem(
                                        "selectedCityByDefault",
                                        e.name
                                      );
                                      window.location.href = `/global-vouchers/${this.props.sector}/filterbyCategory/${this.props.category}/filterByCity/getNearby=${latlong.lat}&${latlong.lng}`;
                                    }
                                  })
                                  .catch((error) => {
                                    console.log(error);
                                  });
                              } else if (this.props.onSubCategory) {
                                var config = {
                                  method: "get",
                                  url: `https://maps.googleapis.com/maps/api/geocode/json?address=${e.name},Netherlands&key=${process.env.REACT_APP_GEOCODE_KEY}`,
                                  headers: {},
                                };

                                axios(config)
                                  .then((response) => {
                                    if (response.data.results) {
                                      let latlong =
                                        response.data.results[0].geometry
                                          .location;
                                      localStorage.setItem(
                                        "selectedCityByDefault",
                                        e.name
                                      );
                                      window.location.href = `/global-vouchers/${this.props.sector}/filterbySubCategory/${this.props.category}/${this.props.subCategory}/filterByCity/getNearby=${latlong.lat}&${latlong.lng}`;
                                    }
                                  })
                                  .catch((error) => {
                                    console.log(error);
                                  });
                              }
                            }}
                          >
                            {e.name}
                          </p>
                        </li>
                      );
                    })
                    : this.state.currentCategoryId === "city" &&
                    this.state.citiesMain.map((e, i) => {
                      return (
                        <li>
                          <p
                            onClick={() => {
                              if (this.props.onMainSector) {
                                var config = {
                                  method: "get",
                                  url: `https://maps.googleapis.com/maps/api/geocode/json?address=${e.name},Netherlands&key=${process.env.REACT_APP_GEOCODE_KEY}`,
                                  headers: {},
                                };

                                axios(config)
                                  .then((response) => {
                                    if (response.data.results) {
                                      let latlong =
                                        response.data.results[0].geometry
                                          .location;
                                      localStorage.setItem(
                                        "selectedCityByDefault",
                                        e.name
                                      );
                                      window.location.href = `/global-vouchers/${this.props.sector}/filterByCity/getNearby=${latlong.lat}&${latlong.lng}`;
                                    }
                                  })
                                  .catch((error) => {
                                    console.log(error);
                                  });
                              } else if (this.props.onMainCategory) {
                                var config = {
                                  method: "get",
                                  url: `https://maps.googleapis.com/maps/api/geocode/json?address=${e.name},Netherlands&key=${process.env.REACT_APP_GEOCODE_KEY}`,
                                  headers: {},
                                };

                                axios(config)
                                  .then((response) => {
                                    if (response.data.results) {
                                      let latlong =
                                        response.data.results[0].geometry
                                          .location;
                                      localStorage.setItem(
                                        "selectedCityByDefault",
                                        e.name
                                      );
                                      window.location.href = `/global-vouchers/${this.props.sector}/filterbyCategory/${this.props.category}/filterByCity/getNearby=${latlong.lat}&${latlong.lng}`;
                                    }
                                  })
                                  .catch((error) => {
                                    console.log(error);
                                  });
                              } else if (this.props.onSubCategory) {
                                var config = {
                                  method: "get",
                                  url: `https://maps.googleapis.com/maps/api/geocode/json?address=${e.name},Netherlands&key=${process.env.REACT_APP_GEOCODE_KEY}`,
                                  headers: {},
                                };

                                axios(config)
                                  .then((response) => {
                                    if (response.data.results) {
                                      let latlong =
                                        response.data.results[0].geometry
                                          .location;
                                      localStorage.setItem(
                                        "selectedCityByDefault",
                                        e.name
                                      );
                                      window.location.href = `/global-vouchers/${this.props.sector}/filterbySubCategory/${this.props.category}/${this.props.subCategory}/filterByCity/getNearby=${latlong.lat}&${latlong.lng}`;
                                    }
                                  })
                                  .catch((error) => {
                                    console.log(error);
                                  });
                              }
                            }}
                          >
                            {e.name}
                          </p>
                        </li>
                      );
                    })
                  : this.state.subCategoriesCurrent.map((e) => {
                    if (
                      this.state.availableSubCategories.includes(
                        e.category_id
                      )
                    ) {
                      return (
                        <li
                          onClick={() => {
                            localStorage.setItem(
                              "subcategoryidProduct",
                              e.category_id
                            );
                            window.location.href = `/global-vouchers/${this.props.sector}/filterbySubCategory/${this.state.currentCategoryId}/${e.category_id}`;
                            // localStorage.setItem("subcategoryidProduct", e.category_id)
                            // window.location.href = `/product-list-global/${this.props.sectorName}/${e.title}`
                          }}
                        >
                          <p>{e.title}</p>
                        </li>
                      );
                    }
                  })}
              </ul>
            )}
          </div>
        </div>



        {this.state.showFiltersModal && (<div className="loaderareaduplicate mcaloader loaderAreaUpdatedNavbar ">
          <div
            className="modal-create-album modalcreatealbumchangedheader changedheadermodalcreatealbum"
            style={{ width: window.innerWidth < 1600 ? 330 : 400 }}
          >
            <div
              className="heading-mca"
              style={{
                textAlign: "center",
                position: "relative",
                padding: "13px",
              }}
            >
              <font>FILTERS</font>
              <CloseIcon
                onClick={() => {
                  this.setState({ showFiltersModal: false });
                }}
                style={{
                  position: "absolute",
                  top: "50%",
                  transform: "translateY(-50%)",
                  right: 10,
                  fontSize: 18,
                }}
              />
            </div>
            <div className="body-mca groupsettingsbody mcasettingsupdatednavbar">
              <div className="form-body-mca">
                <div className="inner-mca-settings rmclswithiconsbordered">
                  <div
                    className="mca-settings-list"
                    style={{
                      flexDirection: "column",
                      alignItems: "flex-start",
                      backgroundColor: "transparent",
                      height: "auto",
                    }}
                  >
                    <div
                      className="right-mcls"
                      style={{ color: "white", paddingLeft: 20 }}
                    >
                      <font
                        onClick={() => {
                          history.push(`/global-voucher-events/${this.props.sector}`)
                        }}
                      >
                        Alle events
                      </font>
                    </div>
                    {this.state.currentWeekEvents.length > 0 && (
                      <div
                        className="right-mcls"
                        style={{ color: "white", paddingLeft: 20 }}
                      >
                        <font
                          onClick={() => {
                            history.push(`/global-voucher-events/${this.props.sector}/filter/currentWeek`)
                          }}
                        >
                          Deze week
                        </font>
                      </div>
                    )}
                    {this.state.comingWeekEvents.length > 0 && (
                      <div
                        className="right-mcls"
                        style={{ color: "white", paddingLeft: 20 }}
                      >
                        <font
                          onClick={() => {
                            history.push(`/global-voucher-events/${this.props.sector}/filter/comingWeek`)
                          }}
                        >
                          Volgende week
                        </font>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>)}

        {this.state.showFiltersModalVouchers && (<div className="loaderareaduplicate mcaloader loaderAreaUpdatedNavbar " style={{ left: 0 }}>
          <div
            className="modal-create-album modalcreatealbumchangedheader changedheadermodalcreatealbum"
            style={{ width: window.innerWidth < 1600 ? 330 : 400 }}
          >
            <div
              className="heading-mca"
              style={{
                textAlign: "center",
                position: "relative",
                padding: "13px",
              }}
            >
              <font>FILTERS</font>
              <CloseIcon
                onClick={() => {
                  this.setState({ showFiltersModalVouchers: false });
                }}
                style={{
                  position: "absolute",
                  top: "50%",
                  transform: "translateY(-50%)",
                  right: 10,
                  fontSize: 18,
                }}
              />
            </div>
            <div className="body-mca groupsettingsbody mcasettingsupdatednavbar">
              <div className="form-body-mca">
                <div className="inner-mca-settings rmclswithiconsbordered">
                  <div
                    className="mca-settings-list"
                    style={{
                      flexDirection: "column",
                      alignItems: "flex-start",
                      backgroundColor: "transparent",
                      height: "auto",
                    }}
                  >
                    {this.state.allCategories.length > 0 &&
                      this.state.allCategories.map((e) => {
                        if (
                          this.state.availableMainCategories.includes(e.category_id)
                        ) {
                          return (
                            <div
                              className="right-mcls"
                              style={{ color: "white", paddingLeft: 20 }}
                            >
                              <font
                                onClick={() => {
                                  this.setState({ showFiltersModalVouchers: false });

                                  localStorage.setItem("subcategoryidProduct", e.category_id);
                                  history.push(`/global-vouchers/${this.props.sector}/filterbyCategory/${e.category_id}`)
                                }}
                              >
                                {e.title}
                              </font>
                            </div>
                          );
                        }
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>)}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducers.user,
  };
};

export default connect(mapStateToProps)(NavGlobalVoucherCity);
