import React, { Component } from 'react'
import 'react-sortable-tree/style.css';
import firebase from '../Config/Firebase'
import { connect } from 'react-redux'
import { updateGlobalStuff } from '../Redux/actions/authActions'
import CK from './Ck'
import AdminNavbar from './AdminNavbar';

export class EditorLandingPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      treeData: [],
      sectorModal: false,
      sectorModalCategory: false,
      sectorName: "",
      sectors: [],
      currentSector: "",
      currentSectorId: "",
      categoryName: "",
      currentSector: "",
      categoryId: "",
      all_categories: [],
      sortingTab: true,
      adjustingTab: false,
      completeMainTitles: [],
      arrOfInputs: [],
      categoryDesc: "",
      landing_page_banner_english: "",
      landing_page_banner_dutch: "",
      landing_page_events_english: "",
      landing_page_events_dutch: "",
      landing_page_new_events_dutch: "",
      landing_page_new_events_english: "",
      landing_page_profile_dutch: "",
      landing_page_profile_english: "",
      landing_page_about_dutch: "",
      landing_page_about_english: "",
      landing_page_team_bhodi_dutch: "",
      landing_page_team_bhodi_english: "",
      landing_page_banner_heading_english: "",
      landing_page_banner_heading_dutch: "",
      landing_page_events_dutch_heading: "",
      landing_page_events_english_heading: "",
      landing_page_events_dutch_headertxt: "",
      landing_page_events_english_headertxt: "",
      landing_page_events_dutch_heading_inner: "",
      landing_page_events_english_heading_inner: "",
      landing_page_new_events_dutch_heading: "",
      landing_page_new_events_english_heading: "",
      landing_page_profile_dutch_heading: "",
      landing_page_profile_english_heading: "",
      landing_page_about_dutch_heading: "",
      landing_page_about_english_heading: "",
      landing_page_team_bhodi_dutch_heading: "",
      landing_page_team_bhodi_english_heading: "",
      landing_page_connect_dutch_heading: "",
      landing_page_connect_english_heading: "",
      landing_page_connect_dutch: "",
      landing_page_connect_english: "",
      // Mobile
      landing_page_banner_english_mobile: "",
      landing_page_banner_dutch_mobile: "",
      landing_page_events_english_mobile: "",
      landing_page_events_dutch_mobile: "",
      landing_page_new_events_dutch_mobile: "",
      landing_page_new_events_english_mobile: "",
      landing_page_profile_dutch_mobile: "",
      landing_page_profile_english_mobile: "",
      landing_page_about_dutch_mobile: "",
      landing_page_about_english_mobile: "",
      landing_page_team_bhodi_dutch_mobile: "",
      landing_page_team_bhodi_english_mobile: "",
      landing_page_banner_heading_english_mobile: "",
      landing_page_banner_heading_dutch_mobile: "",
      landing_page_events_dutch_heading_mobile: "",
      landing_page_events_english_heading_mobile: "",
      landing_page_events_dutch_headertxt_mobile: "",
      landing_page_events_english_headertxt_mobile: "",
      landing_page_events_dutch_heading_inner_mobile: "",
      landing_page_events_english_heading_inner_mobile: "",
      landing_page_new_events_dutch_heading_mobile: "",
      landing_page_new_events_english_heading_mobile: "",
      landing_page_profile_dutch_heading_mobile: "",
      landing_page_profile_english_heading_mobile: "",
      landing_page_about_dutch_heading_mobile: "",
      landing_page_about_english_heading_mobile: "",
      landing_page_team_bhodi_dutch_heading_mobile: "",
      landing_page_team_bhodi_english_heading_mobile: "",
      landing_page_connect_dutch_heading_mobile: "",
      landing_page_connect_english_heading_mobile: "",
      landing_page_connect_dutch_mobile: "",
      landing_page_connect_english_mobile: "",
      showStuffNow: false,
      onMobileDesigns: false,
      landing_page_banner_top_video: "",
      landing_page_charity_image: "",
      landing_page_event_right_image: "",
      landing_page_footer_image: "",

      donate_page_banner_top_video: "",
      donate_page_banner_heading_english: "",
      donate_page_banner_heading_dutch: "",
      donate_page_banner_heading_english_mobile: "",
      donate_page_banner_heading_dutch_mobile: "",
      donate_page_banner_dutch: "",
      donate_page_banner_dutch_mobile: "",
      donate_page_banner_english: "",
      donate_page_banner_english_mobile: "",
      donate_page_new_events_dutch_heading: "",
      donate_page_new_events_english_heading: "",
      donate_page_new_events_dutch_heading_mobile: "",
      donate_page_new_events_english_heading_mobile: "",
      donate_page_new_events_dutch: "",
      donate_page_new_events_english: "",
      donate_page_new_events_dutch_mobile: "",
      donate_page_new_events_english_mobile: "",
      donate_page_charity_image: "",

      donate_page_events_dutch_heading: "",
      donate_page_events_dutch_heading_mobile: "",
      donate_page_events_english_heading: "",
      donate_page_events_english_heading_mobile: "",
      donate_page_events_dutch_heading_inner: "",
      donate_page_events_dutch_heading_inner_mobile: "",
      donate_page_events_english_heading_inner: "",
      donate_page_events_english_heading_inner_mobile: "",
      donate_page_events_dutch_headertxt: "",
      donate_page_events_dutch_headertxt_mobile: "",
      donate_page_events_english_headertxt: "",
      donate_page_events_english_headertxt_mobile: "",
      donate_page_events_dutch: "",
      donate_page_events_dutch_mobile: "",
      donate_page_events_english: "",
      donate_page_events_english_mobile: "",
      // landing_page_banner_heading_english: "<p>Charity shopping</p>",
      // landing_page_banner_heading_dutch: "<p>Charity shopping</p>",
      // landing_page_events_dutch_heading: "<p>POP-UP EVENTS</p>",
      // landing_page_events_english_heading: "<p>POP-UP EVENTS</p>",
      // landing_page_events_dutch_headertxt: "<p>Expect the unexpected</p>",
      // landing_page_events_english_headertxt: "<p>Expect the unexpected</p>",
      // landing_page_new_events_dutch_heading: "<p>Nieuwste events</p>",
      // landing_page_new_events_english_heading: "<p>Nieuwste events</p>",
      // landing_page_profile_dutch_heading: "<p>Profilepage</p>",
      // landing_page_profile_english_heading: "<p>Profilepage</p>",
      // landing_page_about_dutch_heading: "<p>About</p>",
      // landing_page_about_english_heading: "<p>About</p>",
      // landing_page_team_bhodi_dutch_heading: "<p>Team Bhodi</p>",
      // landing_page_team_bhodi_english_heading: "<p>Team Bhodi</p>",
    };
  }

  componentDidMount() {

    setTimeout(() => {
      this.setState({ showStuffNow: true })
    }, 100);

    if (this.props.global_values) {
      let a = this.props.global_values
      if (a) {
        this.setState({
          landing_page_banner_english: a.landing_page_banner_english,
          landing_page_banner_dutch: a.landing_page_banner_dutch,
          landing_page_events_english: a.landing_page_events_english,
          landing_page_events_dutch: a.landing_page_events_dutch,
          landing_page_new_events_dutch: a.landing_page_new_events_dutch,
          landing_page_new_events_english: a.landing_page_new_events_english,
          landing_page_profile_dutch: a.landing_page_profile_dutch,
          landing_page_profile_english: a.landing_page_profile_english,
          landing_page_about_dutch: a.landing_page_about_dutch,
          landing_page_about_english: a.landing_page_about_english,
          landing_page_team_bhodi_dutch: a.landing_page_team_bhodi_dutch,
          landing_page_team_bhodi_english: a.landing_page_team_bhodi_english,
          landing_page_banner_heading_english: a.landing_page_banner_heading_english,
          landing_page_banner_heading_dutch: a.landing_page_banner_heading_dutch,
          landing_page_events_dutch_heading: a.landing_page_events_dutch_heading,
          landing_page_events_english_heading: a.landing_page_events_english_heading,
          landing_page_events_dutch_heading_inner: a.landing_page_events_dutch_heading_inner,
          landing_page_events_english_heading_inner: a.landing_page_events_english_heading_inner,
          landing_page_events_dutch_headertxt: a.landing_page_events_dutch_headertxt,
          landing_page_events_english_headertxt: a.landing_page_events_english_headertxt,
          landing_page_profile_dutch_heading: a.landing_page_profile_dutch_heading,
          landing_page_profile_english_heading: a.landing_page_profile_english_heading,
          landing_page_new_events_dutch_heading: a.landing_page_new_events_dutch_heading,
          landing_page_new_events_english_heading: a.landing_page_new_events_english_heading,
          landing_page_about_dutch_heading: a.landing_page_about_dutch_heading,
          landing_page_about_english_heading: a.landing_page_about_english_heading,
          landing_page_team_bhodi_dutch_heading: a.landing_page_team_bhodi_dutch_heading,
          landing_page_team_bhodi_english_heading: a.landing_page_team_bhodi_english_heading,
          landing_page_connect_dutch_heading: a.landing_page_connect_dutch_heading,
          landing_page_connect_english_heading: a.landing_page_connect_english_heading,
          landing_page_connect_dutch: a.landing_page_connect_dutch,
          landing_page_connect_english: a.landing_page_connect_english,

          reg_login_about_dutch_heading: a.reg_login_about_dutch_heading,
          reg_login_about_english_heading: a.reg_login_about_english_heading,
          reg_login_team_bhodi_dutch_heading: a.reg_login_team_bhodi_dutch_heading,
          reg_login_team_bhodi_english_heading: a.reg_login_team_bhodi_english_heading,
          reg_login_about_dutch: a.reg_login_about_dutch,
          reg_login_about_english: a.reg_login_about_english,
          reg_login_team_bhodi_dutch: a.reg_login_team_bhodi_dutch,
          reg_login_team_bhodi_english: a.reg_login_team_bhodi_english,

          // Mobile
          landing_page_banner_english_mobile: a.landing_page_banner_english_mobile,
          landing_page_banner_dutch_mobile: a.landing_page_banner_dutch_mobile,
          landing_page_events_english_mobile: a.landing_page_events_english_mobile,
          landing_page_events_dutch_mobile: a.landing_page_events_dutch_mobile,
          landing_page_new_events_dutch_mobile: a.landing_page_new_events_dutch_mobile,
          landing_page_new_events_english_mobile: a.landing_page_new_events_english_mobile,
          landing_page_profile_dutch_mobile: a.landing_page_profile_dutch_mobile,
          landing_page_profile_english_mobile: a.landing_page_profile_english_mobile,
          landing_page_about_dutch_mobile: a.landing_page_about_dutch_mobile,
          landing_page_about_english_mobile: a.landing_page_about_english_mobile,
          landing_page_team_bhodi_dutch_mobile: a.landing_page_team_bhodi_dutch_mobile,
          landing_page_team_bhodi_english_mobile: a.landing_page_team_bhodi_english_mobile,
          landing_page_banner_heading_english_mobile: a.landing_page_banner_heading_english_mobile,
          landing_page_banner_heading_dutch_mobile: a.landing_page_banner_heading_dutch_mobile,
          landing_page_events_dutch_heading_mobile: a.landing_page_events_dutch_heading_mobile,
          landing_page_events_english_heading_mobile: a.landing_page_events_english_heading_mobile,
          landing_page_events_dutch_heading_inner_mobile: a.landing_page_events_dutch_heading_inner_mobile,
          landing_page_events_english_heading_inner_mobile: a.landing_page_events_english_heading_inner_mobile,
          landing_page_events_dutch_headertxt_mobile: a.landing_page_events_dutch_headertxt_mobile,
          landing_page_events_english_headertxt_mobile: a.landing_page_events_english_headertxt_mobile,
          landing_page_profile_dutch_heading_mobile: a.landing_page_profile_dutch_heading_mobile,
          landing_page_profile_english_heading_mobile: a.landing_page_profile_english_heading_mobile,
          landing_page_new_events_dutch_heading_mobile: a.landing_page_new_events_dutch_heading_mobile,
          landing_page_new_events_english_heading_mobile: a.landing_page_new_events_english_heading_mobile,
          landing_page_about_dutch_heading_mobile: a.landing_page_about_dutch_heading_mobile,
          landing_page_about_english_heading_mobile: a.landing_page_about_english_heading_mobile,
          landing_page_team_bhodi_dutch_heading_mobile: a.landing_page_team_bhodi_dutch_heading_mobile,
          landing_page_team_bhodi_english_heading_mobile: a.landing_page_team_bhodi_english_heading_mobile,
          landing_page_connect_dutch_heading_mobile: a.landing_page_connect_dutch_heading_mobile,
          landing_page_connect_english_heading_mobile: a.landing_page_connect_english_heading_mobile,
          landing_page_connect_dutch_mobile: a.landing_page_connect_dutch_mobile,
          landing_page_connect_english_mobile: a.landing_page_connect_english_mobile,


          // reg login
          reg_login_about_dutch_heading_mobile: a.reg_login_about_dutch_heading_mobile,
          reg_login_about_english_heading_mobile: a.reg_login_about_english_heading_mobile,
          reg_login_team_bhodi_dutch_heading_mobile: a.reg_login_team_bhodi_dutch_heading_mobile,
          reg_login_team_bhodi_english_heading_mobile: a.reg_login_team_bhodi_english_heading_mobile,
          reg_login_about_dutch_mobile: a.reg_login_about_dutch_mobile,
          reg_login_about_english_mobile: a.reg_login_about_english_mobile,
          reg_login_team_bhodi_dutch_mobile: a.reg_login_team_bhodi_dutch_mobile,
          reg_login_team_bhodi_english_mobile: a.reg_login_team_bhodi_english_mobile,

          // donate page
          donate_page_banner_heading_english: a.donate_page_banner_heading_english,
          donate_page_banner_heading_dutch: a.donate_page_banner_heading_dutch,
          donate_page_banner_heading_english_mobile: a.donate_page_banner_heading_english_mobile,
          donate_page_banner_heading_dutch_mobile: a.donate_page_banner_heading_dutch_mobile,

          donate_page_banner_dutch: a.donate_page_banner_dutch,
          donate_page_banner_dutch_mobile: a.donate_page_banner_dutch_mobile,
          donate_page_banner_english: a.donate_page_banner_english,
          donate_page_banner_english_mobile: a.donate_page_banner_english_mobile,

          donate_page_new_events_dutch_heading: a.donate_page_new_events_dutch_heading,
          donate_page_new_events_english_heading: a.donate_page_new_events_english_heading,
          donate_page_new_events_dutch_heading_mobile: a.donate_page_new_events_dutch_heading_mobile,
          donate_page_new_events_english_heading_mobile: a.donate_page_new_events_english_heading_mobile,
          donate_page_new_events_dutch: a.donate_page_new_events_dutch,
          donate_page_new_events_english: a.donate_page_new_events_english,
          donate_page_new_events_dutch_mobile: a.donate_page_new_events_dutch_mobile,
          donate_page_new_events_english_mobile: a.donate_page_new_events_english_mobile,


          donate_page_events_dutch_heading: a.donate_page_events_dutch_heading,
          donate_page_events_dutch_heading_mobile: a.donate_page_events_dutch_heading_mobile,
          donate_page_events_english_heading: a.donate_page_events_english_heading,
          donate_page_events_english_heading_mobile: a.donate_page_events_english_heading_mobile,
          donate_page_events_dutch_heading_inner: a.donate_page_events_dutch_heading_inner,
          donate_page_events_dutch_heading_inner_mobile: a.donate_page_events_dutch_heading_inner_mobile,
          donate_page_events_english_heading_inner: a.donate_page_events_english_heading_inner,
          donate_page_events_english_heading_inner_mobile: a.donate_page_events_english_heading_inner_mobile,
          donate_page_events_dutch_headertxt: a.donate_page_events_dutch_headertxt,
          donate_page_events_dutch_headertxt_mobile: a.donate_page_events_dutch_headertxt_mobile,
          donate_page_events_english_headertxt: a.donate_page_events_english_headertxt,
          donate_page_events_english_headertxt_mobile: a.donate_page_events_english_headertxt_mobile,
          donate_page_events_dutch: a.donate_page_events_dutch,
          donate_page_events_dutch_mobile: a.donate_page_events_dutch_mobile,
          donate_page_events_english: a.donate_page_events_english,
          donate_page_events_english_mobile: a.donate_page_events_english_mobile,

          // gift page
          gift_sixth_section_heading: a.gift_sixth_section_heading,
          gift_sixth_section_heading_mobile: a.gift_sixth_section_heading_mobile,
          gift_sixth_section_english_heading: a.gift_sixth_section_english_heading,
          gift_sixth_section_english_heading_mobile: a.gift_sixth_section_english_heading_mobile,

          gift_sixth_section_text: a.gift_sixth_section_text,
          gift_sixth_section_text_mobile: a.gift_sixth_section_text_mobile,
          gift_sixth_section_english_text: a.gift_sixth_section_english_text,
          gift_sixth_section_english_text_mobile: a.gift_sixth_section_english_text_mobile,

          gift_seventh_section_heading: a.gift_seventh_section_heading,
          gift_seventh_section_heading_mobile: a.gift_seventh_section_heading_mobile,
          gift_seventh_section_english_heading: a.gift_seventh_section_english_heading,
          gift_seventh_section_english_heading_mobile: a.gift_seventh_section_english_heading_mobile,

          gift_seventh_section_text: a.gift_seventh_section_text,
          gift_seventh_section_text_mobile: a.gift_seventh_section_text_mobile,
          gift_seventh_section_english_text: a.gift_seventh_section_english_text,
          gift_seventh_section_english_text_mobile: a.gift_seventh_section_english_text_mobile,

          gift_about_section_heading: a.gift_about_section_heading,
          gift_about_section_heading_mobile: a.gift_about_section_heading_mobile,
          gift_about_section_english_heading: a.gift_about_section_english_heading,
          gift_about_section_english_heading_mobile: a.gift_about_section_english_heading_mobile,

          gift_about_section_text: a.gift_about_section_text,
          gift_about_section_text_mobile: a.gift_about_section_text_mobile,
          gift_about_section_english_text: a.gift_about_section_english_text,
          gift_about_section_english_text_mobile: a.gift_about_section_english_text_mobile,

          landing_page_new_desc: a.landing_page_new_desc,
          landing_page_new_desc_mobile: a.landing_page_new_desc_mobile,
          landing_page_new_desc_english: a.landing_page_new_desc_english,
          landing_page_new_desc_english_mobile: a.landing_page_new_desc_english_mobile,
          landing_page_new_date: a.landing_page_new_date,
          landing_page_new_date_mobile: a.landing_page_new_date_mobile,
          landing_page_new_date_english: a.landing_page_new_date_english,
          landing_page_new_date_english_mobile: a.landing_page_new_date_english_mobile,
        })
        if (a.profiles_image) {
          this.setState({ profilesImage: a.profiles_image, profilesVideo: "" })
        } else {
          this.setState({ profilesVideo: a.profiles_video, profilesImage: "" })
        }
      }
    }

    firebase.database().ref("bhodi_home").on("value", (data) => {
      let a = data.val()
      if (a) {
        this.props.updateGlobalStuff(a)
        this.setState({
          landing_page_banner_english: a.landing_page_banner_english,
          landing_page_banner_dutch: a.landing_page_banner_dutch,
          landing_page_events_english: a.landing_page_events_english,
          landing_page_events_dutch: a.landing_page_events_dutch,
          landing_page_new_events_dutch: a.landing_page_new_events_dutch,
          landing_page_new_events_english: a.landing_page_new_events_english,
          landing_page_profile_dutch: a.landing_page_profile_dutch,
          landing_page_profile_english: a.landing_page_profile_english,
          landing_page_about_dutch: a.landing_page_about_dutch,
          landing_page_about_english: a.landing_page_about_english,
          landing_page_team_bhodi_dutch: a.landing_page_team_bhodi_dutch,
          landing_page_team_bhodi_english: a.landing_page_team_bhodi_english,
          landing_page_banner_heading_english: a.landing_page_banner_heading_english,
          landing_page_banner_heading_dutch: a.landing_page_banner_heading_dutch,
          landing_page_events_dutch_heading: a.landing_page_events_dutch_heading,
          landing_page_events_english_heading: a.landing_page_events_english_heading,
          landing_page_events_dutch_heading_inner: a.landing_page_events_dutch_heading_inner,
          landing_page_events_english_heading_inner: a.landing_page_events_english_heading_inner,
          landing_page_events_dutch_headertxt: a.landing_page_events_dutch_headertxt,
          landing_page_events_english_headertxt: a.landing_page_events_english_headertxt,
          landing_page_profile_dutch_heading: a.landing_page_profile_dutch_heading,
          landing_page_profile_english_heading: a.landing_page_profile_english_heading,
          landing_page_new_events_dutch_heading: a.landing_page_new_events_dutch_heading,
          landing_page_new_events_english_heading: a.landing_page_new_events_english_heading,
          landing_page_about_dutch_heading: a.landing_page_about_dutch_heading,
          landing_page_about_english_heading: a.landing_page_about_english_heading,
          landing_page_team_bhodi_dutch_heading: a.landing_page_team_bhodi_dutch_heading,
          landing_page_team_bhodi_english_heading: a.landing_page_team_bhodi_english_heading,
          landing_page_connect_dutch_heading: a.landing_page_connect_dutch_heading,
          landing_page_connect_english_heading: a.landing_page_connect_english_heading,
          landing_page_connect_dutch: a.landing_page_connect_dutch,
          landing_page_connect_english: a.landing_page_connect_english,


          reg_login_about_dutch_heading: a.reg_login_about_dutch_heading,
          reg_login_about_english_heading: a.reg_login_about_english_heading,
          reg_login_team_bhodi_dutch_heading: a.reg_login_team_bhodi_dutch_heading,
          reg_login_team_bhodi_english_heading: a.reg_login_team_bhodi_english_heading,
          // Mobile
          landing_page_banner_english_mobile: a.landing_page_banner_english_mobile,
          landing_page_banner_dutch_mobile: a.landing_page_banner_dutch_mobile,
          landing_page_events_english_mobile: a.landing_page_events_english_mobile,
          landing_page_events_dutch_mobile: a.landing_page_events_dutch_mobile,
          landing_page_new_events_dutch_mobile: a.landing_page_new_events_dutch_mobile,
          landing_page_new_events_english_mobile: a.landing_page_new_events_english_mobile,
          landing_page_profile_dutch_mobile: a.landing_page_profile_dutch_mobile,
          landing_page_profile_english_mobile: a.landing_page_profile_english_mobile,
          landing_page_about_dutch_mobile: a.landing_page_about_dutch_mobile,
          landing_page_about_english_mobile: a.landing_page_about_english_mobile,
          landing_page_team_bhodi_dutch_mobile: a.landing_page_team_bhodi_dutch_mobile,
          landing_page_team_bhodi_english_mobile: a.landing_page_team_bhodi_english_mobile,
          landing_page_banner_heading_english_mobile: a.landing_page_banner_heading_english_mobile,
          landing_page_banner_heading_dutch_mobile: a.landing_page_banner_heading_dutch_mobile,
          landing_page_events_dutch_heading_mobile: a.landing_page_events_dutch_heading_mobile,
          landing_page_events_english_heading_mobile: a.landing_page_events_english_heading_mobile,
          landing_page_events_dutch_heading_inner_mobile: a.landing_page_events_dutch_heading_inner_mobile,
          landing_page_events_english_heading_inner_mobile: a.landing_page_events_english_heading_inner_mobile,
          landing_page_events_dutch_headertxt_mobile: a.landing_page_events_dutch_headertxt_mobile,
          landing_page_events_english_headertxt_mobile: a.landing_page_events_english_headertxt_mobile,
          landing_page_profile_dutch_heading_mobile: a.landing_page_profile_dutch_heading_mobile,
          landing_page_profile_english_heading_mobile: a.landing_page_profile_english_heading_mobile,
          landing_page_new_events_dutch_heading_mobile: a.landing_page_new_events_dutch_heading_mobile,
          landing_page_new_events_english_heading_mobile: a.landing_page_new_events_english_heading_mobile,
          landing_page_about_dutch_heading_mobile: a.landing_page_about_dutch_heading_mobile,
          landing_page_about_english_heading_mobile: a.landing_page_about_english_heading_mobile,
          landing_page_team_bhodi_dutch_heading_mobile: a.landing_page_team_bhodi_dutch_heading_mobile,
          landing_page_team_bhodi_english_heading_mobile: a.landing_page_team_bhodi_english_heading_mobile,
          landing_page_connect_dutch_heading_mobile: a.landing_page_connect_dutch_heading_mobile,
          landing_page_connect_english_heading_mobile: a.landing_page_connect_english_heading_mobile,
          landing_page_connect_dutch_mobile: a.landing_page_connect_dutch_mobile,
          landing_page_connect_english_mobile: a.landing_page_connect_english_mobile,

          // reg login
          reg_login_about_dutch_heading_mobile: a.reg_login_about_dutch_heading_mobile,
          reg_login_about_english_heading_mobile: a.reg_login_about_english_heading_mobile,
          reg_login_team_bhodi_dutch_heading_mobile: a.reg_login_team_bhodi_dutch_heading_mobile,
          reg_login_team_bhodi_english_heading_mobile: a.reg_login_team_bhodi_english_heading_mobile,

          // donate page
          donate_page_banner_heading_english: a.donate_page_banner_heading_english,
          donate_page_banner_heading_dutch: a.donate_page_banner_heading_dutch,
          donate_page_banner_heading_english_mobile: a.donate_page_banner_heading_english_mobile,
          donate_page_banner_heading_dutch_mobile: a.donate_page_banner_heading_dutch_mobile,

          donate_page_banner_dutch: a.donate_page_banner_dutch,
          donate_page_banner_dutch_mobile: a.donate_page_banner_dutch_mobile,
          donate_page_banner_english: a.donate_page_banner_english,
          donate_page_banner_english_mobile: a.donate_page_banner_english_mobile,

          donate_page_new_events_dutch_heading: a.donate_page_new_events_dutch_heading,
          donate_page_new_events_english_heading: a.donate_page_new_events_english_heading,
          donate_page_new_events_dutch_heading_mobile: a.donate_page_new_events_dutch_heading_mobile,
          donate_page_new_events_english_heading_mobile: a.donate_page_new_events_english_heading_mobile,
          donate_page_new_events_dutch: a.donate_page_new_events_dutch,
          donate_page_new_events_english: a.donate_page_new_events_english,
          donate_page_new_events_dutch_mobile: a.donate_page_new_events_dutch_mobile,
          donate_page_new_events_english_mobile: a.donate_page_new_events_english_mobile,


          donate_page_events_dutch_heading: a.donate_page_events_dutch_heading,
          donate_page_events_dutch_heading_mobile: a.donate_page_events_dutch_heading_mobile,
          donate_page_events_english_heading: a.donate_page_events_english_heading,
          donate_page_events_english_heading_mobile: a.donate_page_events_english_heading_mobile,
          donate_page_events_dutch_heading_inner: a.donate_page_events_dutch_heading_inner,
          donate_page_events_dutch_heading_inner_mobile: a.donate_page_events_dutch_heading_inner_mobile,
          donate_page_events_english_heading_inner: a.donate_page_events_english_heading_inner,
          donate_page_events_english_heading_inner_mobile: a.donate_page_events_english_heading_inner_mobile,
          donate_page_events_dutch_headertxt: a.donate_page_events_dutch_headertxt,
          donate_page_events_dutch_headertxt_mobile: a.donate_page_events_dutch_headertxt_mobile,
          donate_page_events_english_headertxt: a.donate_page_events_english_headertxt,
          donate_page_events_english_headertxt_mobile: a.donate_page_events_english_headertxt_mobile,
          donate_page_events_dutch: a.donate_page_events_dutch,
          donate_page_events_dutch_mobile: a.donate_page_events_dutch_mobile,
          donate_page_events_english: a.donate_page_events_english,
          donate_page_events_english_mobile: a.donate_page_events_english_mobile,

          // gift page
          gift_sixth_section_heading: a.gift_sixth_section_heading,
          gift_sixth_section_heading_mobile: a.gift_sixth_section_heading_mobile,
          gift_sixth_section_english_heading: a.gift_sixth_section_english_heading,
          gift_sixth_section_english_heading_mobile: a.gift_sixth_section_english_heading_mobile,

          gift_sixth_section_text: a.gift_sixth_section_text,
          gift_sixth_section_text_mobile: a.gift_sixth_section_text_mobile,
          gift_sixth_section_english_text: a.gift_sixth_section_english_text,
          gift_sixth_section_english_text_mobile: a.gift_sixth_section_english_text_mobile,

          gift_seventh_section_heading: a.gift_seventh_section_heading,
          gift_seventh_section_heading_mobile: a.gift_seventh_section_heading_mobile,
          gift_seventh_section_english_heading: a.gift_seventh_section_english_heading,
          gift_seventh_section_english_heading_mobile: a.gift_seventh_section_english_heading_mobile,

          gift_seventh_section_text: a.gift_seventh_section_text,
          gift_seventh_section_text_mobile: a.gift_seventh_section_text_mobile,
          gift_seventh_section_english_text: a.gift_seventh_section_english_text,
          gift_seventh_section_english_text_mobile: a.gift_seventh_section_english_text_mobile,

          gift_about_section_heading: a.gift_about_section_heading,
          gift_about_section_heading_mobile: a.gift_about_section_heading_mobile,
          gift_about_section_english_heading: a.gift_about_section_english_heading,
          gift_about_section_english_heading_mobile: a.gift_about_section_english_heading_mobile,

          gift_about_section_text: a.gift_about_section_text,
          gift_about_section_text_mobile: a.gift_about_section_text_mobile,
          gift_about_section_english_text: a.gift_about_section_english_text,
          gift_about_section_english_text_mobile: a.gift_about_section_english_text_mobile,

          landing_page_new_desc: a.landing_page_new_desc,
          landing_page_new_desc_mobile: a.landing_page_new_desc_mobile,
          landing_page_new_desc_english: a.landing_page_new_desc_english,
          landing_page_new_desc_english_mobile: a.landing_page_new_desc_english_mobile,
          landing_page_new_date: a.landing_page_new_date,
          landing_page_new_date_mobile: a.landing_page_new_date_mobile,
          landing_page_new_date_english: a.landing_page_new_date_english,
          landing_page_new_date_english_mobile: a.landing_page_new_date_english_mobile,
        })
        if (a.profiles_image) {
          this.setState({ profilesImage: a.profiles_image, profilesVideo: "" })
        } else {
          this.setState({ profilesVideo: a.profiles_video, profilesImage: "" })
        }
      }
    })
  }

  changeAlertBannerDutch(e) {
    if (this.state.onMobileDesigns) {
      firebase.database().ref("bhodi_home/landing_page_banner_dutch_mobile").set(e).then(() => {
        this.setState({ landing_page_banner_dutch_mobile: e })
      })
    } else {
      firebase.database().ref("bhodi_home/landing_page_banner_dutch").set(e).then(() => {
        this.setState({ landing_page_banner_dutch: e })
      })
    }
  }

  changeAlertBannerEnglish(e) {
    if (this.state.onMobileDesigns) {
      firebase.database().ref("bhodi_home/landing_page_banner_english_mobile").set(e).then(() => {
        this.setState({ landing_page_banner_english_mobile: e })
      })
    } else {
      firebase.database().ref("bhodi_home/landing_page_banner_english").set(e).then(() => {
        this.setState({ landing_page_banner_english: e })
      })
    }
  }

  changeAlertBannerDutchHeading(e) {
    if (this.state.onMobileDesigns) {
      firebase.database().ref("bhodi_home/landing_page_banner_heading_dutch_mobile").set(e).then(() => {
        this.setState({ landing_page_banner_heading_dutch_mobile: e })
      })
    } else {
      firebase.database().ref("bhodi_home/landing_page_banner_heading_dutch").set(e).then(() => {
        this.setState({ landing_page_banner_heading_dutch: e })
      })
    }
  }

  changeAlertBannerEnglishHeading(e) {
    if (this.state.onMobileDesigns) {
      firebase.database().ref("bhodi_home/landing_page_banner_heading_english_mobile").set(e).then(() => {
        this.setState({ landing_page_banner_heading_english_mobile: e })
      })
    } else {
      firebase.database().ref("bhodi_home/landing_page_banner_heading_english").set(e).then(() => {
        this.setState({ landing_page_banner_heading_english: e })
      })
    }
  }

  changeAlertEventsDutch(e) {
    if (this.state.onMobileDesigns) {
      firebase.database().ref("bhodi_home/landing_page_events_dutch_mobile").set(e).then(() => {
        this.setState({ landing_page_events_dutch_mobile: e })
      })
    } else {
      firebase.database().ref("bhodi_home/landing_page_events_dutch").set(e).then(() => {
        this.setState({ landing_page_events_dutch: e })
      })
    }
  }

  changeAlertEventsEnglish(e) {
    if (this.state.onMobileDesigns) {
      firebase.database().ref("bhodi_home/landing_page_events_english_mobile").set(e).then(() => {
        this.setState({ landing_page_events_english_mobile: e })
      })
    } else {
      firebase.database().ref("bhodi_home/landing_page_events_english").set(e).then(() => {
        this.setState({ landing_page_events_english: e })
      })
    }
  }

  changeAlertEventsDutchHeading(e) {
    if (this.state.onMobileDesigns) {
      firebase.database().ref("bhodi_home/landing_page_events_dutch_heading_mobile").set(e).then(() => {
        this.setState({ landing_page_events_dutch_heading_mobile: e })
      })
    } else {
      firebase.database().ref("bhodi_home/landing_page_events_dutch_heading").set(e).then(() => {
        this.setState({ landing_page_events_dutch_heading: e })
      })
    }
  }

  changeAlertEventsEnglishHeading(e) {
    if (this.state.onMobileDesigns) {
      firebase.database().ref("bhodi_home/landing_page_events_english_heading_mobile").set(e).then(() => {
        this.setState({ landing_page_events_english_heading_mobile: e })
      })
    } else {
      firebase.database().ref("bhodi_home/landing_page_events_english_heading").set(e).then(() => {
        this.setState({ landing_page_events_english_heading: e })
      })
    }
  }

  changeAlertEventsDutchHeadingInner(e) {
    if (this.state.onMobileDesigns) {
      firebase.database().ref("bhodi_home/landing_page_events_dutch_heading_inner_mobile").set(e).then(() => {
        this.setState({ landing_page_events_dutch_heading_inner_mobile: e })
      })
    } else {
      firebase.database().ref("bhodi_home/landing_page_events_dutch_heading_inner").set(e).then(() => {
        this.setState({ landing_page_events_dutch_heading_inner: e })
      })
    }
  }

  changeAlertEventsEnglishHeadingInner(e) {
    if (this.state.onMobileDesigns) {
      firebase.database().ref("bhodi_home/landing_page_events_english_heading_inner_mobile").set(e).then(() => {
        this.setState({ landing_page_events_english_heading_inner_mobile: e })
      })
    } else {
      firebase.database().ref("bhodi_home/landing_page_events_english_heading_inner").set(e).then(() => {
        this.setState({ landing_page_events_english_heading_inner: e })
      })
    }
  }

  changeAlertEventsDutchHeaderTxt(e) {
    if (this.state.onMobileDesigns) {
      firebase.database().ref("bhodi_home/landing_page_events_dutch_headertxt_mobile").set(e).then(() => {
        this.setState({ landing_page_events_dutch_headertxt_mobile: e })
      })
    } else {
      firebase.database().ref("bhodi_home/landing_page_events_dutch_headertxt").set(e).then(() => {
        this.setState({ landing_page_events_dutch_headertxt: e })
      })
    }
  }

  changeAlertEventsEnglishHeaderTxt(e) {
    if (this.state.onMobileDesigns) {
      firebase.database().ref("bhodi_home/landing_page_events_english_headertxt_mobile").set(e).then(() => {
        this.setState({ landing_page_events_english_headertxt_mobile: e })
      })
    } else {
      firebase.database().ref("bhodi_home/landing_page_events_english_headertxt").set(e).then(() => {
        this.setState({ landing_page_events_english_headertxt: e })
      })
    }
  }

  changeAlertNewEventsDutch(e) {
    if (this.state.onMobileDesigns) {
      firebase.database().ref("bhodi_home/landing_page_new_events_dutch_mobile").set(e).then(() => {
        this.setState({ landing_page_new_events_dutch_mobile: e })
      })
    } else {
      firebase.database().ref("bhodi_home/landing_page_new_events_dutch").set(e).then(() => {
        this.setState({ landing_page_new_events_dutch: e })
      })
    }
  }

  changeAlertNewEventsEnglish(e) {
    if (this.state.onMobileDesigns) {
      firebase.database().ref("bhodi_home/landing_page_new_events_english_mobile").set(e).then(() => {
        this.setState({ landing_page_new_events_english_mobile: e })
      })
    } else {
      firebase.database().ref("bhodi_home/landing_page_new_events_english").set(e).then(() => {
        this.setState({ landing_page_new_events_english: e })
      })
    }
  }

  changeAlertNewEventsDutchHeading(e) {
    if (this.state.onMobileDesigns) {
      firebase.database().ref("bhodi_home/landing_page_new_events_dutch_heading_mobile").set(e).then(() => {
        this.setState({ landing_page_new_events_dutch_heading_mobile: e })
      })
    } else {
      firebase.database().ref("bhodi_home/landing_page_new_events_dutch_heading").set(e).then(() => {
        this.setState({ landing_page_new_events_dutch_heading: e })
      })
    }
  }

  changeAlertNewEventsEnglishHeading(e) {
    if (this.state.onMobileDesigns) {
      firebase.database().ref("bhodi_home/landing_page_new_events_english_heading_mobile").set(e).then(() => {
        this.setState({ landing_page_new_events_english_heading_mobile: e })
      })
    } else {
      firebase.database().ref("bhodi_home/landing_page_new_events_english_heading").set(e).then(() => {
        this.setState({ landing_page_new_events_english_heading: e })
      })
    }
  }


  changeAlertNewProfileDutchHeading(e) {
    if (this.state.onMobileDesigns) {
      firebase.database().ref("bhodi_home/landing_page_profile_dutch_heading_mobile").set(e).then(() => {
        this.setState({ landing_page_profile_dutch_heading_mobile: e })
      })
    } else {
      firebase.database().ref("bhodi_home/landing_page_profile_dutch_heading").set(e).then(() => {
        this.setState({ landing_page_profile_dutch_heading: e })
      })
    }
  }

  changeAlertNewProfileEnglishHeading(e) {
    if (this.state.onMobileDesigns) {
      firebase.database().ref("bhodi_home/landing_page_profile_english_heading_mobile").set(e).then(() => {
        this.setState({ landing_page_profile_english_heading_mobile: e })
      })
    } else {
      firebase.database().ref("bhodi_home/landing_page_profile_english_heading").set(e).then(() => {
        this.setState({ landing_page_profile_english_heading: e })
      })
    }
  }


  changeAlertNewProfileDutch(e) {
    if (this.state.onMobileDesigns) {
      firebase.database().ref("bhodi_home/landing_page_profile_dutch_mobile").set(e).then(() => {
        this.setState({ landing_page_profile_dutch_mobile: e })
      })
    } else {
      firebase.database().ref("bhodi_home/landing_page_profile_dutch").set(e).then(() => {
        this.setState({ landing_page_profile_dutch: e })
      })
    }
  }

  changeAlertNewProfileEnglish(e) {
    if (this.state.onMobileDesigns) {
      firebase.database().ref("bhodi_home/landing_page_profile_english_mobile").set(e).then(() => {
        this.setState({ landing_page_profile_english_mobile: e })
      })
    } else {
      firebase.database().ref("bhodi_home/landing_page_profile_english").set(e).then(() => {
        this.setState({ landing_page_profile_english: e })
      })
    }
  }


  changeAlertAboutDutchHeading(e) {
    if (this.state.onMobileDesigns) {
      firebase.database().ref("bhodi_home/landing_page_about_dutch_heading_mobile").set(e).then(() => {
        this.setState({ landing_page_about_dutch_heading_mobile: e })
      })
    } else {
      firebase.database().ref("bhodi_home/landing_page_about_dutch_heading").set(e).then(() => {
        this.setState({ landing_page_about_dutch_heading: e })
      })
    }
  }

  changeAlertAboutEnglishHeading(e) {
    if (this.state.onMobileDesigns) {
      firebase.database().ref("bhodi_home/landing_page_about_english_heading_mobile").set(e).then(() => {
        this.setState({ landing_page_about_english_heading_mobile: e })
      })
    } else {
      firebase.database().ref("bhodi_home/landing_page_about_english_heading").set(e).then(() => {
        this.setState({ landing_page_about_english_heading: e })
      })
    }
  }

  changeAlertAboutDutch(e) {
    if (this.state.onMobileDesigns) {
      firebase.database().ref("bhodi_home/landing_page_about_dutch_mobile").set(e).then(() => {
        this.setState({ landing_page_about_dutch_mobile: e })
      })
    } else {
      firebase.database().ref("bhodi_home/landing_page_about_dutch").set(e).then(() => {
        this.setState({ landing_page_about_dutch: e })
      })
    }
  }

  changeAlertAboutEnglish(e) {
    if (this.state.onMobileDesigns) {
      firebase.database().ref("bhodi_home/landing_page_about_english_mobile").set(e).then(() => {
        this.setState({ landing_page_about_english_mobile: e })
      })
    } else {
      firebase.database().ref("bhodi_home/landing_page_about_english").set(e).then(() => {
        this.setState({ landing_page_about_english: e })
      })
    }
  }


  changeAlertTeamBhodiDutchHeading(e) {
    if (this.state.onMobileDesigns) {
      firebase.database().ref("bhodi_home/landing_page_team_bhodi_dutch_heading_mobile").set(e).then(() => {
        this.setState({ landing_page_team_bhodi_dutch_heading_mobile: e })
      })
    } else {
      firebase.database().ref("bhodi_home/landing_page_team_bhodi_dutch_heading").set(e).then(() => {
        this.setState({ landing_page_team_bhodi_dutch_heading: e })
      })
    }
  }

  changeAlertTeamBhodiEnglishHeading(e) {
    if (this.state.onMobileDesigns) {
      firebase.database().ref("bhodi_home/landing_page_team_bhodi_english_heading_mobile").set(e).then(() => {
        this.setState({ landing_page_team_bhodi_english_heading_mobile: e })
      })
    } else {
      firebase.database().ref("bhodi_home/landing_page_team_bhodi_english_heading").set(e).then(() => {
        this.setState({ landing_page_team_bhodi_english_heading: e })
      })
    }
  }

  changeAlertTeamBhodiDutch(e) {
    if (this.state.onMobileDesigns) {
      firebase.database().ref("bhodi_home/landing_page_team_bhodi_dutch_mobile").set(e).then(() => {
        this.setState({ landing_page_team_bhodi_dutch_mobile: e })
      })
    } else {
      firebase.database().ref("bhodi_home/landing_page_team_bhodi_dutch").set(e).then(() => {
        this.setState({ landing_page_team_bhodi_dutch: e })
      })
    }
  }

  changeAlertTeamBhodiEnglish(e) {
    if (this.state.onMobileDesigns) {
      firebase.database().ref("bhodi_home/landing_page_team_bhodi_english_mobile").set(e).then(() => {
        this.setState({ landing_page_team_bhodi_english_mobile: e })
      })
    } else {
      firebase.database().ref("bhodi_home/landing_page_team_bhodi_english").set(e).then(() => {
        this.setState({ landing_page_team_bhodi_english: e })
      })
    }
  }

  changeAlertConnectDutchHeading(e) {
    if (this.state.onMobileDesigns) {
      firebase.database().ref("bhodi_home/landing_page_connect_dutch_heading_mobile").set(e).then(() => {
        this.setState({ landing_page_connect_dutch_heading_mobile: e })
      })
    } else {
      firebase.database().ref("bhodi_home/landing_page_connect_dutch_heading").set(e).then(() => {
        this.setState({ landing_page_connect_dutch_heading: e })
      })
    }
  }

  changeAlertConnectEnglishHeading(e) {
    if (this.state.onMobileDesigns) {
      firebase.database().ref("bhodi_home/landing_page_connect_english_heading_mobile").set(e).then(() => {
        this.setState({ landing_page_connect_english_heading_mobile: e })
      })
    } else {
      firebase.database().ref("bhodi_home/landing_page_connect_english_heading").set(e).then(() => {
        this.setState({ landing_page_connect_english_heading: e })
      })
    }
  }

  changeAlertConnectDutch(e) {
    if (this.state.onMobileDesigns) {
      firebase.database().ref("bhodi_home/landing_page_connect_dutch_mobile").set(e).then(() => {
        this.setState({ landing_page_connect_dutch_mobile: e })
      })
    } else {
      firebase.database().ref("bhodi_home/landing_page_connect_dutch").set(e).then(() => {
        this.setState({ landing_page_connect_dutch: e })
      })
    }
  }

  changeAlertConnectEnglish(e) {
    if (this.state.onMobileDesigns) {
      firebase.database().ref("bhodi_home/landing_page_connect_english_mobile").set(e).then(() => {
        this.setState({ landing_page_connect_english_mobile: e })
      })
    } else {
      firebase.database().ref("bhodi_home/landing_page_connect_english").set(e).then(() => {
        this.setState({ landing_page_connect_english: e })
      })
    }
  }


  fileUploadBannerVideo(e) {
    let files = e.target.files[0]
    let storageRef = firebase.storage().ref().child(`bhodi_home_media/${files.name}${new Date().getTime()}`)
    storageRef.put(files)
      .then((snapshot) => {
        snapshot.ref.getDownloadURL().then((snapUrl) => {
          firebase.database().ref("bhodi_home/landing_page_banner_top_video/").set(snapUrl).then(() => {
          })
        })
      })
  }


  fileUploadCharityVideo(e) {
    let files = e.target.files[0]
    let storageRef = firebase.storage().ref().child(`bhodi_home_media/${files.name}${new Date().getTime()}`)
    storageRef.put(files)
      .then((snapshot) => {
        snapshot.ref.getDownloadURL().then((snapUrl) => {
          firebase.database().ref("bhodi_home/landing_page_charity_image/").set(snapUrl).then(() => {
          })
        })
      })
  }

  fileUploadEventsImage(e) {
    let files = e.target.files[0]
    let storageRef = firebase.storage().ref().child(`bhodi_home_media/${files.name}${new Date().getTime()}`)
    storageRef.put(files)
      .then((snapshot) => {
        snapshot.ref.getDownloadURL().then((snapUrl) => {
          firebase.database().ref("bhodi_home/landing_page_event_right_image/").set(snapUrl).then(() => {
          })
        })
      })
  }

  fileUploadFooterImage(e) {
    let files = e.target.files[0]
    let storageRef = firebase.storage().ref().child(`bhodi_home_media/${files.name}${new Date().getTime()}`)
    storageRef.put(files)
      .then((snapshot) => {
        snapshot.ref.getDownloadURL().then((snapUrl) => {
          firebase.database().ref("bhodi_home/landing_page_footer_image/").set(snapUrl).then(() => {
          })
        })
      })
  }


  // registration login

  changeAlertAboutRegLoginDutchHeading(e) {
    if (this.state.onMobileDesigns) {
      firebase.database().ref("bhodi_home/reg_login_about_dutch_heading_mobile").set(e).then(() => {
        this.setState({ reg_login_about_dutch_heading_mobile: e })
      })
    } else {
      firebase.database().ref("bhodi_home/reg_login_about_dutch_heading").set(e).then(() => {
        this.setState({ reg_login_about_dutch_heading: e })
      })
    }
  }

  changeAlertAboutRegLoginEnglishHeading(e) {
    if (this.state.onMobileDesigns) {
      firebase.database().ref("bhodi_home/reg_login_about_english_heading_mobile").set(e).then(() => {
        this.setState({ reg_login_about_english_heading_mobile: e })
      })
    } else {
      firebase.database().ref("bhodi_home/reg_login_about_english_heading").set(e).then(() => {
        this.setState({ reg_login_about_english_heading: e })
      })
    }
  }

  changeAlertAboutRegLoginDutch(e) {
    if (this.state.onMobileDesigns) {
      firebase.database().ref("bhodi_home/reg_login_about_dutch_mobile").set(e).then(() => {
        this.setState({ reg_login_about_dutch_mobile: e })
      })
    } else {
      firebase.database().ref("bhodi_home/reg_login_about_dutch").set(e).then(() => {
        this.setState({ reg_login_about_dutch: e })
      })
    }
  }

  changeAlertAboutRegLoginEnglish(e) {
    if (this.state.onMobileDesigns) {
      firebase.database().ref("bhodi_home/reg_login_about_english_mobile").set(e).then(() => {
        this.setState({ reg_login_about_english_mobile: e })
      })
    } else {
      firebase.database().ref("bhodi_home/reg_login_about_english").set(e).then(() => {
        this.setState({ reg_login_about_english: e })
      })
    }
  }


  changeAlertTeamBhodiRegLoginDutchHeading(e) {
    if (this.state.onMobileDesigns) {
      firebase.database().ref("bhodi_home/reg_login_team_bhodi_dutch_heading_mobile").set(e).then(() => {
        this.setState({ reg_login_team_bhodi_dutch_heading_mobile: e })
      })
    } else {
      firebase.database().ref("bhodi_home/reg_login_team_bhodi_dutch_heading").set(e).then(() => {
        this.setState({ reg_login_team_bhodi_dutch_heading: e })
      })
    }
  }

  changeAlertTeamBhodiRegLoginEnglishHeading(e) {
    if (this.state.onMobileDesigns) {
      firebase.database().ref("bhodi_home/reg_login_team_bhodi_english_heading_mobile").set(e).then(() => {
        this.setState({ reg_login_team_bhodi_english_heading_mobile: e })
      })
    } else {
      firebase.database().ref("bhodi_home/reg_login_team_bhodi_english_heading").set(e).then(() => {
        this.setState({ reg_login_team_bhodi_english_heading: e })
      })
    }
  }

  changeAlertTeamBhodiRegLoginDutch(e) {
    if (this.state.onMobileDesigns) {
      firebase.database().ref("bhodi_home/reg_login_team_bhodi_dutch_mobile").set(e).then(() => {
        this.setState({ reg_login_team_bhodi_dutch_mobile: e })
      })
    } else {
      firebase.database().ref("bhodi_home/reg_login_team_bhodi_dutch").set(e).then(() => {
        this.setState({ reg_login_team_bhodi_dutch: e })
      })
    }
  }

  changeAlertTeamBhodiRegLoginEnglish(e) {
    if (this.state.onMobileDesigns) {
      firebase.database().ref("bhodi_home/reg_login_team_bhodi_english_mobile").set(e).then(() => {
        this.setState({ reg_login_team_bhodi_english_mobile: e })
      })
    } else {
      firebase.database().ref("bhodi_home/reg_login_team_bhodi_english").set(e).then(() => {
        this.setState({ reg_login_team_bhodi_english: e })
      })
    }
  }

  // donate page work

  fileUploadCharityImageDonate(e) {
    let files = e.target.files[0]
    let storageRef = firebase.storage().ref().child(`bhodi_home_media/${files.name}${new Date().getTime()}`)
    storageRef.put(files)
      .then((snapshot) => {
        snapshot.ref.getDownloadURL().then((snapUrl) => {
          firebase.database().ref("bhodi_home/donate_page_charity_image/").set(snapUrl).then(() => {
          })
        })
      })
  }

  fileUploadDonateVideo(e) {
    let files = e.target.files[0]
    let storageRef = firebase.storage().ref().child(`bhodi_home_media/${files.name}${new Date().getTime()}`)
    storageRef.put(files)
      .then((snapshot) => {
        snapshot.ref.getDownloadURL().then((snapUrl) => {
          firebase.database().ref("bhodi_home/donate_page_banner_top_video/").set(snapUrl).then(() => {
          })
        })
      })
  }


  changeAlertBannerDutchHeadingDonate(e) {
    if (this.state.onMobileDesigns) {
      firebase.database().ref("bhodi_home/donate_page_banner_heading_dutch_mobile").set(e).then(() => {
        this.setState({ donate_page_banner_heading_dutch_mobile: e })
      })
    } else {
      firebase.database().ref("bhodi_home/donate_page_banner_heading_dutch").set(e).then(() => {
        this.setState({ donate_page_banner_heading_dutch: e })
      })
    }
  }

  changeAlertBannerEnglishHeadingDonate(e) {
    if (this.state.onMobileDesigns) {
      firebase.database().ref("bhodi_home/donate_page_banner_heading_english_mobile").set(e).then(() => {
        this.setState({ donate_page_banner_heading_english_mobile: e })
      })
    } else {
      firebase.database().ref("bhodi_home/donate_page_banner_heading_english").set(e).then(() => {
        this.setState({ donate_page_banner_heading_english: e })
      })
    }
  }

  changeAlertBannerDutchDonate(e) {
    if (this.state.onMobileDesigns) {
      firebase.database().ref("bhodi_home/donate_page_banner_dutch_mobile").set(e).then(() => {
        this.setState({ donate_page_banner_dutch_mobile: e })
      })
    } else {
      firebase.database().ref("bhodi_home/donate_page_banner_dutch").set(e).then(() => {
        this.setState({ donate_page_banner_dutch: e })
      })
    }
  }

  changeAlertBannerEnglishDonate(e) {
    if (this.state.onMobileDesigns) {
      firebase.database().ref("bhodi_home/donate_page_banner_english_mobile").set(e).then(() => {
        this.setState({ donate_page_banner_english_mobile: e })
      })
    } else {
      firebase.database().ref("bhodi_home/donate_page_banner_english").set(e).then(() => {
        this.setState({ donate_page_banner_english: e })
      })
    }
  }

  changeAlertNewEventsDutchDonate(e) {
    if (this.state.onMobileDesigns) {
      firebase.database().ref("bhodi_home/donate_page_new_events_dutch_mobile").set(e).then(() => {
        this.setState({ donate_page_new_events_dutch_mobile: e })
      })
    } else {
      firebase.database().ref("bhodi_home/donate_page_new_events_dutch").set(e).then(() => {
        this.setState({ donate_page_new_events_dutch: e })
      })
    }
  }

  changeAlertNewEventsEnglishDonate(e) {
    if (this.state.onMobileDesigns) {
      firebase.database().ref("bhodi_home/donate_page_new_events_english_mobile").set(e).then(() => {
        this.setState({ donate_page_new_events_english_mobile: e })
      })
    } else {
      firebase.database().ref("bhodi_home/donate_page_new_events_english").set(e).then(() => {
        this.setState({ donate_page_new_events_english: e })
      })
    }
  }

  changeAlertNewEventsDutchHeadingDonate(e) {
    if (this.state.onMobileDesigns) {
      firebase.database().ref("bhodi_home/donate_page_new_events_dutch_heading_mobile").set(e).then(() => {
        this.setState({ donate_page_new_events_dutch_heading_mobile: e })
      })
    } else {
      firebase.database().ref("bhodi_home/donate_page_new_events_dutch_heading").set(e).then(() => {
        this.setState({ donate_page_new_events_dutch_heading: e })
      })
    }
  }

  changeAlertNewEventsEnglishHeadingDonate(e) {
    if (this.state.onMobileDesigns) {
      firebase.database().ref("bhodi_home/donate_page_new_events_english_heading_mobile").set(e).then(() => {
        this.setState({ donate_page_new_events_english_heading_mobile: e })
      })
    } else {
      firebase.database().ref("bhodi_home/donate_page_new_events_english_heading").set(e).then(() => {
        this.setState({ donate_page_new_events_english_heading: e })
      })
    }
  }

  changeAlertEventsDutchDonate(e) {
    if (this.state.onMobileDesigns) {
      firebase.database().ref("bhodi_home/donate_page_events_dutch_mobile").set(e).then(() => {
        this.setState({ donate_page_events_dutch_mobile: e })
      })
    } else {
      firebase.database().ref("bhodi_home/donate_page_events_dutch").set(e).then(() => {
        this.setState({ donate_page_events_dutch: e })
      })
    }
  }

  changeAlertEventsEnglishDonate(e) {
    if (this.state.onMobileDesigns) {
      firebase.database().ref("bhodi_home/donate_page_events_english_mobile").set(e).then(() => {
        this.setState({ donate_page_events_english_mobile: e })
      })
    } else {
      firebase.database().ref("bhodi_home/donate_page_events_english").set(e).then(() => {
        this.setState({ donate_page_events_english: e })
      })
    }
  }

  changeAlertEventsDutchHeadingDonate(e) {
    if (this.state.onMobileDesigns) {
      firebase.database().ref("bhodi_home/donate_page_events_dutch_heading_mobile").set(e).then(() => {
        this.setState({ donate_page_events_dutch_heading_mobile: e })
      })
    } else {
      firebase.database().ref("bhodi_home/donate_page_events_dutch_heading").set(e).then(() => {
        this.setState({ donate_page_events_dutch_heading: e })
      })
    }
  }

  changeAlertEventsEnglishHeadingDonate(e) {
    if (this.state.onMobileDesigns) {
      firebase.database().ref("bhodi_home/donate_page_events_english_heading_mobile").set(e).then(() => {
        this.setState({ donate_page_events_english_heading_mobile: e })
      })
    } else {
      firebase.database().ref("bhodi_home/donate_page_events_english_heading").set(e).then(() => {
        this.setState({ donate_page_events_english_heading: e })
      })
    }
  }

  changeAlertEventsDutchHeadingInnerDonate(e) {
    if (this.state.onMobileDesigns) {
      firebase.database().ref("bhodi_home/donate_page_events_dutch_heading_inner_mobile").set(e).then(() => {
        this.setState({ donate_page_events_dutch_heading_inner_mobile: e })
      })
    } else {
      firebase.database().ref("bhodi_home/donate_page_events_dutch_heading_inner").set(e).then(() => {
        this.setState({ donate_page_events_dutch_heading_inner: e })
      })
    }
  }

  changeAlertEventsEnglishHeadingInnerDonate(e) {
    if (this.state.onMobileDesigns) {
      firebase.database().ref("bhodi_home/donate_page_events_english_heading_inner_mobile").set(e).then(() => {
        this.setState({ donate_page_events_english_heading_inner_mobile: e })
      })
    } else {
      firebase.database().ref("bhodi_home/donate_page_events_english_heading_inner").set(e).then(() => {
        this.setState({ donate_page_events_english_heading_inner: e })
      })
    }
  }

  changeAlertEventsDutchHeaderTxtDonate(e) {
    if (this.state.onMobileDesigns) {
      firebase.database().ref("bhodi_home/donate_page_events_dutch_headertxt_mobile").set(e).then(() => {
        this.setState({ donate_page_events_dutch_headertxt_mobile: e })
      })
    } else {
      firebase.database().ref("bhodi_home/donate_page_events_dutch_headertxt").set(e).then(() => {
        this.setState({ donate_page_events_dutch_headertxt: e })
      })
    }
  }

  changeAlertEventsEnglishHeaderTxtDonate(e) {
    if (this.state.onMobileDesigns) {
      firebase.database().ref("bhodi_home/donate_page_events_english_headertxt_mobile").set(e).then(() => {
        this.setState({ donate_page_events_english_headertxt_mobile: e })
      })
    } else {
      firebase.database().ref("bhodi_home/donate_page_events_english_headertxt").set(e).then(() => {
        this.setState({ donate_page_events_english_headertxt: e })
      })
    }
  }

  fileUploadEventsImageDonate(e) {
    let files = e.target.files[0]
    let storageRef = firebase.storage().ref().child(`bhodi_home_media/${files.name}${new Date().getTime()}`)
    storageRef.put(files)
      .then((snapshot) => {
        snapshot.ref.getDownloadURL().then((snapUrl) => {
          firebase.database().ref("bhodi_home/donate_page_event_right_image/").set(snapUrl).then(() => {
          })
        })
      })
  }

  saveValueToDb(storeAt, e) {
    if (this.state.onMobileDesigns) {
      firebase.database().ref(`bhodi_home/${storeAt}`).set(e)
    } else {
      firebase.database().ref(`bhodi_home/${storeAt}`).set(e)
    }
  }

  fileUploadToDB(e, storeAt) {
    let files = e.target.files[0]
    let storageRef = firebase.storage().ref().child(`bhodi_home_media/${files.name}${new Date().getTime()}`)
    storageRef.put(files)
      .then((snapshot) => {
        snapshot.ref.getDownloadURL().then((snapUrl) => {
          firebase.database().ref(`bhodi_home/${storeAt}/`).set(snapUrl).then(() => {
          })
        })
      })
  }

  render() {
    return (
      <div className="sorting-mainadmin">
        <AdminNavbar landingPage={true} />
        <div className='buttons-container-displayeditor'>
          {this.state.onMobileDesigns ? <button className='buttonsgreendisplay' onClick={() => {
            this.setState({ onMobileDesigns: false })
          }}>Switch To Desktop</button> : <button className='buttonsgreendisplay' onClick={() => {
            this.setState({ onMobileDesigns: true })
          }}>Switch To Mobile</button>}
        </div>
        {this.state.showStuffNow && this.state.onMobileDesigns ? (<div className="container-sma" style={{ maxWidth: 1200, paddingBottom: 50 }}>
          <div className="tree-inner" style={{ marginTop: 30 }}>
            <div className='container-editors-lp' style={{ justifyContent: "center" }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>Banner Heading</label>
              </div>
            </div>
            <div className='container-editors-lp'>
              <div className='inner-celandingpage'>
                <label>Dutch</label>
                <CK data={this.state.landing_page_banner_heading_dutch_mobile} onChildChange={this.changeAlertBannerDutchHeading.bind(this)} />
              </div>
              <div className='inner-celandingpage'>
                <label>English</label>
                <CK data={this.state.landing_page_banner_heading_english_mobile} onChildChange={this.changeAlertBannerEnglishHeading.bind(this)} />
              </div>
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>Banner</label>
              </div>
            </div>
            <div className='container-editors-lp'>
              <div className='inner-celandingpage'>
                <label>Dutch</label>
                <CK data={this.state.landing_page_banner_dutch_mobile} onChildChange={this.changeAlertBannerDutch.bind(this)} />
              </div>
              <div className='inner-celandingpage'>
                <label>English</label>
                <CK data={this.state.landing_page_banner_english_mobile} onChildChange={this.changeAlertBannerEnglish.bind(this)} />
              </div>
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>Events Heading</label>
              </div>
            </div>
            <div className='container-editors-lp'>
              <div className='inner-celandingpage'>
                <label>Dutch</label>
                <CK data={this.state.landing_page_events_dutch_heading_mobile} onChildChange={this.changeAlertEventsDutchHeading.bind(this)} />
              </div>
              <div className='inner-celandingpage'>
                <label>English</label>
                <CK data={this.state.landing_page_events_english_heading_mobile} onChildChange={this.changeAlertEventsEnglishHeading.bind(this)} />
              </div>
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>Events Heading Inner</label>
              </div>
            </div>
            <div className='container-editors-lp'>
              <div className='inner-celandingpage'>
                <label>Dutch</label>
                <CK data={this.state.landing_page_events_dutch_heading_inner_mobile} onChildChange={this.changeAlertEventsDutchHeadingInner.bind(this)} />
              </div>
              <div className='inner-celandingpage'>
                <label>English</label>
                <CK data={this.state.landing_page_events_english_heading_inner_mobile} onChildChange={this.changeAlertEventsEnglishHeadingInner.bind(this)} />
              </div>
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>Events Header Text</label>
              </div>
            </div>
            <div className='container-editors-lp'>
              <div className='inner-celandingpage'>
                <label>Dutch</label>
                <CK data={this.state.landing_page_events_dutch_headertxt_mobile} onChildChange={this.changeAlertEventsDutchHeaderTxt.bind(this)} />
              </div>
              <div className='inner-celandingpage'>
                <label>English</label>
                <CK data={this.state.landing_page_events_english_headertxt_mobile} onChildChange={this.changeAlertEventsEnglishHeaderTxt.bind(this)} />
              </div>
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>Events</label>
              </div>
            </div>
            <div className='container-editors-lp'>
              <div className='inner-celandingpage'>
                <label>Dutch</label>
                <CK data={this.state.landing_page_events_dutch_mobile} onChildChange={this.changeAlertEventsDutch.bind(this)} />
              </div>
              <div className='inner-celandingpage'>
                <label>English</label>
                <CK data={this.state.landing_page_events_english_mobile} onChildChange={this.changeAlertEventsEnglish.bind(this)} />
              </div>
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>Nieuwste events heading</label>
              </div>
            </div>
            <div className='container-editors-lp'>
              <div className='inner-celandingpage'>
                <label>Dutch</label>
                <CK data={this.state.landing_page_new_events_dutch_heading_mobile} onChildChange={this.changeAlertNewEventsDutchHeading.bind(this)} />
              </div>
              <div className='inner-celandingpage'>
                <label>English</label>
                <CK data={this.state.landing_page_new_events_english_heading_mobile} onChildChange={this.changeAlertNewEventsEnglishHeading.bind(this)} />
              </div>
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>Nieuwste events</label>
              </div>
            </div>
            <div className='container-editors-lp'>
              <div className='inner-celandingpage'>
                <label>Dutch</label>
                <CK data={this.state.landing_page_new_events_dutch_mobile} onChildChange={this.changeAlertNewEventsDutch.bind(this)} />
              </div>
              <div className='inner-celandingpage'>
                <label>English</label>
                <CK data={this.state.landing_page_new_events_english_mobile} onChildChange={this.changeAlertNewEventsEnglish.bind(this)} />
              </div>
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>Profilepage heading</label>
              </div>
            </div>
            <div className='container-editors-lp'>
              <div className='inner-celandingpage'>
                <label>Dutch</label>
                <CK data={this.state.landing_page_profile_dutch_heading_mobile} onChildChange={this.changeAlertNewProfileDutchHeading.bind(this)} />
              </div>
              <div className='inner-celandingpage'>
                <label>English</label>
                <CK data={this.state.landing_page_profile_english_heading_mobile} onChildChange={this.changeAlertNewProfileEnglishHeading.bind(this)} />
              </div>
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>Profilepage</label>
              </div>
            </div>
            <div className='container-editors-lp'>
              <div className='inner-celandingpage'>
                <label>Dutch</label>
                <CK data={this.state.landing_page_profile_dutch_mobile} onChildChange={this.changeAlertNewProfileDutch.bind(this)} />
              </div>
              <div className='inner-celandingpage'>
                <label>English</label>
                <CK data={this.state.landing_page_profile_english_mobile} onChildChange={this.changeAlertNewProfileEnglish.bind(this)} />
              </div>
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>About Heading</label>
              </div>
            </div>
            <div className='container-editors-lp'>
              <div className='inner-celandingpage'>
                <label>Dutch</label>
                <CK data={this.state.landing_page_about_dutch_heading_mobile} onChildChange={this.changeAlertAboutDutchHeading.bind(this)} />
              </div>
              <div className='inner-celandingpage'>
                <label>English</label>
                <CK data={this.state.landing_page_about_english_heading_mobile} onChildChange={this.changeAlertAboutEnglishHeading.bind(this)} />
              </div>
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>About</label>
              </div>
            </div>
            <div className='container-editors-lp'>
              <div className='inner-celandingpage'>
                <label>Dutch</label>
                <CK data={this.state.landing_page_about_dutch_mobile} onChildChange={this.changeAlertAboutDutch.bind(this)} />
              </div>
              <div className='inner-celandingpage'>
                <label>English</label>
                <CK data={this.state.landing_page_about_english_mobile} onChildChange={this.changeAlertAboutEnglish.bind(this)} />
              </div>
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>Team Bhodi Heading</label>
              </div>
            </div>
            <div className='container-editors-lp'>
              <div className='inner-celandingpage'>
                <label>Dutch</label>
                <CK data={this.state.landing_page_team_bhodi_dutch_heading_mobile} onChildChange={this.changeAlertTeamBhodiDutchHeading.bind(this)} />
              </div>
              <div className='inner-celandingpage'>
                <label>English</label>
                <CK data={this.state.landing_page_team_bhodi_english_heading_mobile} onChildChange={this.changeAlertTeamBhodiEnglishHeading.bind(this)} />
              </div>
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>Team Bhodi</label>
              </div>
            </div>
            <div className='container-editors-lp'>
              <div className='inner-celandingpage'>
                <label>Dutch</label>
                <CK data={this.state.landing_page_team_bhodi_dutch_mobile} onChildChange={this.changeAlertTeamBhodiDutch.bind(this)} />
              </div>
              <div className='inner-celandingpage'>
                <label>English</label>
                <CK data={this.state.landing_page_team_bhodi_english_mobile} onChildChange={this.changeAlertTeamBhodiEnglish.bind(this)} />
              </div>
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>Connect Heading</label>
              </div>
            </div>
            <div className='container-editors-lp'>
              <div className='inner-celandingpage'>
                <label>Dutch</label>
                <CK data={this.state.landing_page_connect_dutch_heading_mobile} onChildChange={this.changeAlertConnectDutchHeading.bind(this)} />
              </div>
              <div className='inner-celandingpage'>
                <label>English</label>
                <CK data={this.state.landing_page_connect_english_heading_mobile} onChildChange={this.changeAlertConnectEnglishHeading.bind(this)} />
              </div>
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>Connect</label>
              </div>
            </div>
            <div className='container-editors-lp'>
              <div className='inner-celandingpage'>
                <label>Dutch</label>
                <CK data={this.state.landing_page_connect_dutch_mobile} onChildChange={this.changeAlertConnectDutch.bind(this)} />
              </div>
              <div className='inner-celandingpage'>
                <label>English</label>
                <CK data={this.state.landing_page_connect_english_mobile} onChildChange={this.changeAlertConnectEnglish.bind(this)} />
              </div>
            </div>
          </div>


          {/* Registration login */}
          <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
            <div className='inner-celandingpage headinglabelcelp'>
              <label>About Heading (Reg)</label>
            </div>
          </div>
          <div className='container-editors-lp'>
            <div className='inner-celandingpage'>
              <label>Dutch</label>
              <CK data={this.state.reg_login_about_dutch_heading_mobile} onChildChange={this.changeAlertAboutRegLoginDutchHeading.bind(this)} />
            </div>
            <div className='inner-celandingpage'>
              <label>English</label>
              <CK data={this.state.reg_login_about_english_heading_mobile} onChildChange={this.changeAlertAboutRegLoginEnglishHeading.bind(this)} />
            </div>
          </div>
          <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
            <div className='inner-celandingpage headinglabelcelp'>
              <label>About (Reg)</label>
            </div>
          </div>
          <div className='container-editors-lp'>
            <div className='inner-celandingpage'>
              <label>Dutch</label>
              <CK data={this.state.reg_login_about_dutch_mobile} onChildChange={this.changeAlertAboutRegLoginDutch.bind(this)} />
            </div>
            <div className='inner-celandingpage'>
              <label>English</label>
              <CK data={this.state.reg_login_about_english_mobile} onChildChange={this.changeAlertAboutRegLoginEnglish.bind(this)} />
            </div>
          </div>
          <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
            <div className='inner-celandingpage headinglabelcelp'>
              <label>Team Bhodi Heading (Reg)</label>
            </div>
          </div>
          <div className='container-editors-lp'>
            <div className='inner-celandingpage'>
              <label>Dutch</label>
              <CK data={this.state.reg_login_team_bhodi_dutch_heading_mobile} onChildChange={this.changeAlertTeamBhodiRegLoginDutchHeading.bind(this)} />
            </div>
            <div className='inner-celandingpage'>
              <label>English</label>
              <CK data={this.state.reg_login_team_bhodi_english_heading_mobile} onChildChange={this.changeAlertTeamBhodiRegLoginEnglishHeading.bind(this)} />
            </div>
          </div>
          <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
            <div className='inner-celandingpage headinglabelcelp'>
              <label>Team Bhodi (Reg)</label>
            </div>
          </div>
          <div className='container-editors-lp'>
            <div className='inner-celandingpage'>
              <label>Dutch</label>
              <CK data={this.state.reg_login_team_bhodi_dutch_mobile} onChildChange={this.changeAlertTeamBhodiRegLoginDutch.bind(this)} />
            </div>
            <div className='inner-celandingpage'>
              <label>English</label>
              <CK data={this.state.reg_login_team_bhodi_english_mobile} onChildChange={this.changeAlertTeamBhodiRegLoginEnglish.bind(this)} />
            </div>
          </div>

          {/* donate page */}
          <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
            <div className='inner-celandingpage headinglabelcelp'>
              <label>Donate Page</label>
            </div>
          </div>
          <div className='container-editors-lp' style={{ justifyContent: "center" }}>
            <div className='inner-celandingpage headinglabelcelp'>
              <label>Banner Heading</label>
            </div>
          </div>
          <div className='container-editors-lp'>
            <div className='inner-celandingpage'>
              <label>Dutch</label>
              <CK data={this.state.donate_page_banner_heading_dutch_mobile} onChildChange={this.changeAlertBannerDutchHeadingDonate.bind(this)} />
            </div>
            <div className='inner-celandingpage'>
              <label>English</label>
              <CK data={this.state.donate_page_banner_heading_english_mobile} onChildChange={this.changeAlertBannerEnglishHeadingDonate.bind(this)} />
            </div>
          </div>
          <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
            <div className='inner-celandingpage headinglabelcelp'>
              <label>Banner</label>
            </div>
          </div>
          <div className='container-editors-lp'>
            <div className='inner-celandingpage'>
              <label>Dutch</label>
              <CK data={this.state.donate_page_banner_dutch_mobile} onChildChange={this.changeAlertBannerDutchDonate.bind(this)} />
            </div>
            <div className='inner-celandingpage'>
              <label>English</label>
              <CK data={this.state.donate_page_banner_english_mobile} onChildChange={this.changeAlertBannerEnglishDonate.bind(this)} />
            </div>
          </div>
          <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
            <div className='inner-celandingpage headinglabelcelp'>
              <label>Nieuwste events heading</label>
            </div>
          </div>
          <div className='container-editors-lp'>
            <div className='inner-celandingpage'>
              <label>Dutch</label>
              <CK data={this.state.donate_page_new_events_dutch_heading_mobile} onChildChange={this.changeAlertNewEventsDutchHeadingDonate.bind(this)} />
            </div>
            <div className='inner-celandingpage'>
              <label>English</label>
              <CK data={this.state.donate_page_new_events_english_heading_mobile} onChildChange={this.changeAlertNewEventsEnglishHeadingDonate.bind(this)} />
            </div>
          </div>
          <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
            <div className='inner-celandingpage headinglabelcelp'>
              <label>Nieuwste events</label>
            </div>
          </div>
          <div className='container-editors-lp'>
            <div className='inner-celandingpage'>
              <label>Dutch</label>
              <CK data={this.state.donate_page_new_events_dutch_mobile} onChildChange={this.changeAlertNewEventsDutchDonate.bind(this)} />
            </div>
            <div className='inner-celandingpage'>
              <label>English</label>
              <CK data={this.state.donate_page_new_events_english_mobile} onChildChange={this.changeAlertNewEventsEnglishDonate.bind(this)} />
            </div>
          </div>

          <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
            <div className='inner-celandingpage headinglabelcelp'>
              <label>Events Heading</label>
            </div>
          </div>
          <div className='container-editors-lp'>
            <div className='inner-celandingpage'>
              <label>Dutch</label>
              <CK data={this.state.donate_page_events_dutch_heading_mobile} onChildChange={this.changeAlertEventsDutchHeadingDonate.bind(this)} />
            </div>
            <div className='inner-celandingpage'>
              <label>English</label>
              <CK data={this.state.donate_page_events_english_heading_mobile} onChildChange={this.changeAlertEventsEnglishHeadingDonate.bind(this)} />
            </div>
          </div>
          <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
            <div className='inner-celandingpage headinglabelcelp'>
              <label>Events Heading Inner</label>
            </div>
          </div>
          <div className='container-editors-lp'>
            <div className='inner-celandingpage'>
              <label>Dutch</label>
              <CK data={this.state.donate_page_events_dutch_heading_inner_mobile} onChildChange={this.changeAlertEventsDutchHeadingInnerDonate.bind(this)} />
            </div>
            <div className='inner-celandingpage'>
              <label>English</label>
              <CK data={this.state.donate_page_events_english_heading_inner_mobile} onChildChange={this.changeAlertEventsEnglishHeadingInnerDonate.bind(this)} />
            </div>
          </div>
          <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
            <div className='inner-celandingpage headinglabelcelp'>
              <label>Events Header Text</label>
            </div>
          </div>
          <div className='container-editors-lp'>
            <div className='inner-celandingpage'>
              <label>Dutch</label>
              <CK data={this.state.donate_page_events_dutch_headertxt_mobile} onChildChange={this.changeAlertEventsDutchHeaderTxtDonate.bind(this)} />
            </div>
            <div className='inner-celandingpage'>
              <label>English</label>
              <CK data={this.state.donate_page_events_english_headertxt_mobile} onChildChange={this.changeAlertEventsEnglishHeaderTxtDonate.bind(this)} />
            </div>
          </div>
          <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
            <div className='inner-celandingpage headinglabelcelp'>
              <label>Events</label>
            </div>
          </div>
          <div className='container-editors-lp'>
            <div className='inner-celandingpage'>
              <label>Dutch</label>
              <CK data={this.state.donate_page_events_dutch_mobile} onChildChange={this.changeAlertEventsDutchDonate.bind(this)} />
            </div>
            <div className='inner-celandingpage'>
              <label>English</label>
              <CK data={this.state.donate_page_events_english_mobile} onChildChange={this.changeAlertEventsEnglishDonate.bind(this)} />
            </div>
          </div>
          {/* bhodi.com/gift */}
          <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
            <div className='inner-celandingpage headinglabelcelp'>
              <label>bhodi.com/gift</label>
            </div>
          </div>
          <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
            <div className='inner-celandingpage headinglabelcelp'>
              <label>6th section heading</label>
            </div>
          </div>
          <div className='container-editors-lp'>
            <div className='inner-celandingpage'>
              <label>Dutch</label>
              <CK data={this.state.gift_sixth_section_heading_mobile} onChildChange={this.saveValueToDb.bind(this, "gift_sixth_section_heading_mobile")} />
            </div>
            <div className='inner-celandingpage'>
              <label>English</label>
              <CK data={this.state.gift_sixth_section_english_heading_mobile} onChildChange={this.saveValueToDb.bind(this, "gift_sixth_section_english_heading_mobile")} />
            </div>
          </div>
          <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
            <div className='inner-celandingpage headinglabelcelp'>
              <label>6th section text</label>
            </div>
          </div>
          <div className='container-editors-lp'>
            <div className='inner-celandingpage'>
              <label>Dutch</label>
              <CK data={this.state.gift_sixth_section_text_mobile} onChildChange={this.saveValueToDb.bind(this, "gift_sixth_section_text_mobile")} />
            </div>
            <div className='inner-celandingpage'>
              <label>English</label>
              <CK data={this.state.gift_sixth_section_english_text_mobile} onChildChange={this.saveValueToDb.bind(this, "gift_sixth_section_english_text_mobile")} />
            </div>
          </div>
          <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
            <div className='inner-celandingpage headinglabelcelp'>
              <label>7th section heading</label>
            </div>
          </div>
          <div className='container-editors-lp'>
            <div className='inner-celandingpage'>
              <label>Dutch</label>
              <CK data={this.state.gift_seventh_section_heading_mobile} onChildChange={this.saveValueToDb.bind(this, "gift_seventh_section_heading_mobile")} />
            </div>
            <div className='inner-celandingpage'>
              <label>English</label>
              <CK data={this.state.gift_seventh_section_english_heading_mobile} onChildChange={this.saveValueToDb.bind(this, "gift_seventh_section_english_heading_mobile")} />
            </div>
          </div>
          <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
            <div className='inner-celandingpage headinglabelcelp'>
              <label>7th section text</label>
            </div>
          </div>
          <div className='container-editors-lp'>
            <div className='inner-celandingpage'>
              <label>Dutch</label>
              <CK data={this.state.gift_seventh_section_text_mobile} onChildChange={this.saveValueToDb.bind(this, "gift_seventh_section_text_mobile")} />
            </div>
            <div className='inner-celandingpage'>
              <label>English</label>
              <CK data={this.state.gift_seventh_section_english_text_mobile} onChildChange={this.saveValueToDb.bind(this, "gift_seventh_section_english_text_mobile")} />
            </div>
          </div>
          <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
            <div className='inner-celandingpage headinglabelcelp'>
              <label>About section heading</label>
            </div>
          </div>
          <div className='container-editors-lp'>
            <div className='inner-celandingpage'>
              <label>Dutch</label>
              <CK data={this.state.gift_about_section_heading_mobile} onChildChange={this.saveValueToDb.bind(this, "gift_about_section_heading_mobile")} />
            </div>
            <div className='inner-celandingpage'>
              <label>English</label>
              <CK data={this.state.gift_about_section_english_heading_mobile} onChildChange={this.saveValueToDb.bind(this, "gift_about_section_english_heading_mobile")} />
            </div>
          </div>
          <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
            <div className='inner-celandingpage headinglabelcelp'>
              <label>About section text</label>
            </div>
          </div>
          <div className='container-editors-lp'>
            <div className='inner-celandingpage'>
              <label>Dutch</label>
              <CK data={this.state.gift_about_section_text_mobile} onChildChange={this.saveValueToDb.bind(this, "gift_about_section_text_mobile")} />
            </div>
            <div className='inner-celandingpage'>
              <label>English</label>
              <CK data={this.state.gift_about_section_english_text_mobile} onChildChange={this.saveValueToDb.bind(this, "gift_about_section_english_text_mobile")} />
            </div>
          </div>

          {/* landing-page-new */}
          <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
            <div className='inner-celandingpage headinglabelcelp'>
              <label>bhodi.com/landing-page-new</label>
            </div>
          </div>
          <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
            <div className='inner-celandingpage headinglabelcelp'>
              <label>Centered Section Desc</label>
            </div>
          </div>
          <div className='container-editors-lp'>
            <div className='inner-celandingpage'>
              <label>Dutch</label>
              <CK data={this.state.landing_page_new_desc_mobile} onChildChange={this.saveValueToDb.bind(this, "landing_page_new_desc_mobile")} />
            </div>
            <div className='inner-celandingpage'>
              <label>English</label>
              <CK data={this.state.landing_page_new_desc_english_mobile} onChildChange={this.saveValueToDb.bind(this, "landing_page_new_desc_english_mobile")} />
            </div>
          </div>
          <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
            <div className='inner-celandingpage headinglabelcelp'>
              <label>Centered Section Date</label>
            </div>
          </div>
          <div className='container-editors-lp'>
            <div className='inner-celandingpage'>
              <label>Dutch</label>
              <CK data={this.state.landing_page_new_date_mobile} onChildChange={this.saveValueToDb.bind(this, "landing_page_new_date_mobile")} />
            </div>
            <div className='inner-celandingpage'>
              <label>English</label>
              <CK data={this.state.landing_page_new_date_english_mobile} onChildChange={this.saveValueToDb.bind(this, "landing_page_new_date_english_mobile")} />
            </div>
          </div>

        </div>) : this.state.showStuffNow && (<div className="container-sma" style={{ maxWidth: 1200, paddingBottom: 50 }}>
          <div className="tree-inner" style={{ marginTop: 30 }}>
            <div className='container-editors-lp' style={{ justifyContent: "center" }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>Banner Heading</label>
              </div>
            </div>
            <div className='container-editors-lp'>
              <div className='inner-celandingpage'>
                <label>Dutch</label>
                <CK data={this.state.landing_page_banner_heading_dutch} onChildChange={this.changeAlertBannerDutchHeading.bind(this)} />
              </div>
              <div className='inner-celandingpage'>
                <label>English</label>
                <CK data={this.state.landing_page_banner_heading_english} onChildChange={this.changeAlertBannerEnglishHeading.bind(this)} />
              </div>
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>Banner</label>
              </div>
            </div>
            <div className='container-editors-lp'>
              <div className='inner-celandingpage'>
                <label>Dutch</label>
                <CK data={this.state.landing_page_banner_dutch} onChildChange={this.changeAlertBannerDutch.bind(this)} />
              </div>
              <div className='inner-celandingpage'>
                <label>English</label>
                <CK data={this.state.landing_page_banner_english} onChildChange={this.changeAlertBannerEnglish.bind(this)} />
              </div>
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>Events Heading</label>
              </div>
            </div>
            <div className='container-editors-lp'>
              <div className='inner-celandingpage'>
                <label>Dutch</label>
                <CK data={this.state.landing_page_events_dutch_heading} onChildChange={this.changeAlertEventsDutchHeading.bind(this)} />
              </div>
              <div className='inner-celandingpage'>
                <label>English</label>
                <CK data={this.state.landing_page_events_english_heading} onChildChange={this.changeAlertEventsEnglishHeading.bind(this)} />
              </div>
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>Events Heading Inner</label>
              </div>
            </div>
            <div className='container-editors-lp'>
              <div className='inner-celandingpage'>
                <label>Dutch</label>
                <CK data={this.state.landing_page_events_dutch_heading_inner} onChildChange={this.changeAlertEventsDutchHeadingInner.bind(this)} />
              </div>
              <div className='inner-celandingpage'>
                <label>English</label>
                <CK data={this.state.landing_page_events_english_heading_inner} onChildChange={this.changeAlertEventsEnglishHeadingInner.bind(this)} />
              </div>
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>Events Header Text</label>
              </div>
            </div>
            <div className='container-editors-lp'>
              <div className='inner-celandingpage'>
                <label>Dutch</label>
                <CK data={this.state.landing_page_events_dutch_headertxt} onChildChange={this.changeAlertEventsDutchHeaderTxt.bind(this)} />
              </div>
              <div className='inner-celandingpage'>
                <label>English</label>
                <CK data={this.state.landing_page_events_english_headertxt} onChildChange={this.changeAlertEventsEnglishHeaderTxt.bind(this)} />
              </div>
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>Events</label>
              </div>
            </div>
            <div className='container-editors-lp'>
              <div className='inner-celandingpage'>
                <label>Dutch</label>
                <CK data={this.state.landing_page_events_dutch} onChildChange={this.changeAlertEventsDutch.bind(this)} />
              </div>
              <div className='inner-celandingpage'>
                <label>English</label>
                <CK data={this.state.landing_page_events_english} onChildChange={this.changeAlertEventsEnglish.bind(this)} />
              </div>
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>Nieuwste events heading</label>
              </div>
            </div>
            <div className='container-editors-lp'>
              <div className='inner-celandingpage'>
                <label>Dutch</label>
                <CK data={this.state.landing_page_new_events_dutch_heading} onChildChange={this.changeAlertNewEventsDutchHeading.bind(this)} />
              </div>
              <div className='inner-celandingpage'>
                <label>English</label>
                <CK data={this.state.landing_page_new_events_english_heading} onChildChange={this.changeAlertNewEventsEnglishHeading.bind(this)} />
              </div>
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>Nieuwste events</label>
              </div>
            </div>
            <div className='container-editors-lp'>
              <div className='inner-celandingpage'>
                <label>Dutch</label>
                <CK data={this.state.landing_page_new_events_dutch} onChildChange={this.changeAlertNewEventsDutch.bind(this)} />
              </div>
              <div className='inner-celandingpage'>
                <label>English</label>
                <CK data={this.state.landing_page_new_events_english} onChildChange={this.changeAlertNewEventsEnglish.bind(this)} />
              </div>
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>Profilepage heading</label>
              </div>
            </div>
            <div className='container-editors-lp'>
              <div className='inner-celandingpage'>
                <label>Dutch</label>
                <CK data={this.state.landing_page_profile_dutch_heading} onChildChange={this.changeAlertNewProfileDutchHeading.bind(this)} />
              </div>
              <div className='inner-celandingpage'>
                <label>English</label>
                <CK data={this.state.landing_page_profile_english_heading} onChildChange={this.changeAlertNewProfileEnglishHeading.bind(this)} />
              </div>
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>Profilepage</label>
              </div>
            </div>
            <div className='container-editors-lp'>
              <div className='inner-celandingpage'>
                <label>Dutch</label>
                <CK data={this.state.landing_page_profile_dutch} onChildChange={this.changeAlertNewProfileDutch.bind(this)} />
              </div>
              <div className='inner-celandingpage'>
                <label>English</label>
                <CK data={this.state.landing_page_profile_english} onChildChange={this.changeAlertNewProfileEnglish.bind(this)} />
              </div>
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>About Heading</label>
              </div>
            </div>
            <div className='container-editors-lp'>
              <div className='inner-celandingpage'>
                <label>Dutch</label>
                <CK data={this.state.landing_page_about_dutch_heading} onChildChange={this.changeAlertAboutDutchHeading.bind(this)} />
              </div>
              <div className='inner-celandingpage'>
                <label>English</label>
                <CK data={this.state.landing_page_about_english_heading} onChildChange={this.changeAlertAboutEnglishHeading.bind(this)} />
              </div>
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>About</label>
              </div>
            </div>
            <div className='container-editors-lp'>
              <div className='inner-celandingpage'>
                <label>Dutch</label>
                <CK data={this.state.landing_page_about_dutch} onChildChange={this.changeAlertAboutDutch.bind(this)} />
              </div>
              <div className='inner-celandingpage'>
                <label>English</label>
                <CK data={this.state.landing_page_about_english} onChildChange={this.changeAlertAboutEnglish.bind(this)} />
              </div>
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>Team Bhodi Heading</label>
              </div>
            </div>
            <div className='container-editors-lp'>
              <div className='inner-celandingpage'>
                <label>Dutch</label>
                <CK data={this.state.landing_page_team_bhodi_dutch_heading} onChildChange={this.changeAlertTeamBhodiDutchHeading.bind(this)} />
              </div>
              <div className='inner-celandingpage'>
                <label>English</label>
                <CK data={this.state.landing_page_team_bhodi_english_heading} onChildChange={this.changeAlertTeamBhodiEnglishHeading.bind(this)} />
              </div>
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>Team Bhodi</label>
              </div>
            </div>
            <div className='container-editors-lp'>
              <div className='inner-celandingpage'>
                <label>Dutch</label>
                <CK data={this.state.landing_page_team_bhodi_dutch} onChildChange={this.changeAlertTeamBhodiDutch.bind(this)} />
              </div>
              <div className='inner-celandingpage'>
                <label>English</label>
                <CK data={this.state.landing_page_team_bhodi_english} onChildChange={this.changeAlertTeamBhodiEnglish.bind(this)} />
              </div>
            </div>

            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>Connect Heading</label>
              </div>
            </div>
            <div className='container-editors-lp'>
              <div className='inner-celandingpage'>
                <label>Dutch</label>
                <CK data={this.state.landing_page_connect_dutch_heading} onChildChange={this.changeAlertConnectDutchHeading.bind(this)} />
              </div>
              <div className='inner-celandingpage'>
                <label>English</label>
                <CK data={this.state.landing_page_connect_english_heading} onChildChange={this.changeAlertConnectEnglishHeading.bind(this)} />
              </div>
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>Connect</label>
              </div>
            </div>
            <div className='container-editors-lp'>
              <div className='inner-celandingpage'>
                <label>Dutch</label>
                <CK data={this.state.landing_page_connect_dutch} onChildChange={this.changeAlertConnectDutch.bind(this)} />
              </div>
              <div className='inner-celandingpage'>
                <label>English</label>
                <CK data={this.state.landing_page_connect_english} onChildChange={this.changeAlertConnectEnglish.bind(this)} />
              </div>
            </div>


            {/* images/videos bhodi */}
            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>Banner Video</label>
              </div>
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center" }}>
              <input type="file" style={{ padding: 15, border: "1px solid gray" }} accept="video/*" onChange={(e) => this.fileUploadBannerVideo(e)} />
            </div>

            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>Charity video</label>
              </div>
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center" }}>
              <input type="file" style={{ padding: 15, border: "1px solid gray" }} accept="video/*" onChange={(e) => this.fileUploadCharityVideo(e)} />
            </div>

            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>Events right image</label>
              </div>
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center" }}>
              <input type="file" style={{ padding: 15, border: "1px solid gray" }} accept="image/*" onChange={(e) => this.fileUploadEventsImage(e)} />
            </div>


            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>Footer image</label>
              </div>
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center" }}>
              <input type="file" style={{ padding: 15, border: "1px solid gray" }} accept="image/*" onChange={(e) => this.fileUploadFooterImage(e)} />
            </div>

            {/* Registration Login */}

            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>About Heading (Reg)</label>
              </div>
            </div>
            <div className='container-editors-lp'>
              <div className='inner-celandingpage'>
                <label>Dutch</label>
                <CK data={this.state.reg_login_about_dutch_heading} onChildChange={this.changeAlertAboutRegLoginDutchHeading.bind(this)} />
              </div>
              <div className='inner-celandingpage'>
                <label>English</label>
                <CK data={this.state.reg_login_about_english_heading} onChildChange={this.changeAlertAboutRegLoginEnglishHeading.bind(this)} />
              </div>
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>About (Reg)</label>
              </div>
            </div>
            <div className='container-editors-lp'>
              <div className='inner-celandingpage'>
                <label>Dutch</label>
                <CK data={this.state.reg_login_about_dutch} onChildChange={this.changeAlertAboutRegLoginDutch.bind(this)} />
              </div>
              <div className='inner-celandingpage'>
                <label>English</label>
                <CK data={this.state.reg_login_about_english} onChildChange={this.changeAlertAboutRegLoginEnglish.bind(this)} />
              </div>
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>Team Bhodi Heading (Reg)</label>
              </div>
            </div>
            <div className='container-editors-lp'>
              <div className='inner-celandingpage'>
                <label>Dutch</label>
                <CK data={this.state.reg_login_team_bhodi_dutch_heading} onChildChange={this.changeAlertTeamBhodiRegLoginDutchHeading.bind(this)} />
              </div>
              <div className='inner-celandingpage'>
                <label>English</label>
                <CK data={this.state.reg_login_team_bhodi_english_heading} onChildChange={this.changeAlertTeamBhodiRegLoginEnglishHeading.bind(this)} />
              </div>
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>Team Bhodi (Reg)</label>
              </div>
            </div>
            <div className='container-editors-lp'>
              <div className='inner-celandingpage'>
                <label>Dutch</label>
                <CK data={this.state.reg_login_team_bhodi_dutch} onChildChange={this.changeAlertTeamBhodiRegLoginDutch.bind(this)} />
              </div>
              <div className='inner-celandingpage'>
                <label>English</label>
                <CK data={this.state.reg_login_team_bhodi_english} onChildChange={this.changeAlertTeamBhodiRegLoginEnglish.bind(this)} />
              </div>
            </div>

            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>Donate Page (Reg)</label>
              </div>
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>Charity video</label>
              </div>
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center" }}>
              <input type="file" style={{ padding: 15, border: "1px solid gray" }} accept="video/*" onChange={(e) => this.fileUploadCharityImageDonate(e)} />
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>Banner Video</label>
              </div>
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center" }}>
              <input type="file" style={{ padding: 15, border: "1px solid gray" }} accept="video/*" onChange={(e) => this.fileUploadDonateVideo(e)} />
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>Banner Heading</label>
              </div>
            </div>
            <div className='container-editors-lp'>
              <div className='inner-celandingpage'>
                <label>Dutch</label>
                <CK data={this.state.donate_page_banner_heading_dutch} onChildChange={this.changeAlertBannerDutchHeadingDonate.bind(this)} />
              </div>
              <div className='inner-celandingpage'>
                <label>English</label>
                <CK data={this.state.donate_page_banner_heading_english} onChildChange={this.changeAlertBannerEnglishHeadingDonate.bind(this)} />
              </div>
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>Banner</label>
              </div>
            </div>
            <div className='container-editors-lp'>
              <div className='inner-celandingpage'>
                <label>Dutch</label>
                <CK data={this.state.donate_page_banner_dutch} onChildChange={this.changeAlertBannerDutchDonate.bind(this)} />
              </div>
              <div className='inner-celandingpage'>
                <label>English</label>
                <CK data={this.state.donate_page_banner_english} onChildChange={this.changeAlertBannerEnglishDonate.bind(this)} />
              </div>
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>Nieuwste events heading</label>
              </div>
            </div>
            <div className='container-editors-lp'>
              <div className='inner-celandingpage'>
                <label>Dutch</label>
                <CK data={this.state.donate_page_new_events_dutch_heading} onChildChange={this.changeAlertNewEventsDutchHeadingDonate.bind(this)} />
              </div>
              <div className='inner-celandingpage'>
                <label>English</label>
                <CK data={this.state.donate_page_new_events_english_heading} onChildChange={this.changeAlertNewEventsEnglishHeadingDonate.bind(this)} />
              </div>
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>Nieuwste events</label>
              </div>
            </div>
            <div className='container-editors-lp'>
              <div className='inner-celandingpage'>
                <label>Dutch</label>
                <CK data={this.state.donate_page_new_events_dutch} onChildChange={this.changeAlertNewEventsDutchDonate.bind(this)} />
              </div>
              <div className='inner-celandingpage'>
                <label>English</label>
                <CK data={this.state.donate_page_new_events_english} onChildChange={this.changeAlertNewEventsEnglishDonate.bind(this)} />
              </div>
            </div>

            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>Events Heading</label>
              </div>
            </div>
            <div className='container-editors-lp'>
              <div className='inner-celandingpage'>
                <label>Dutch</label>
                <CK data={this.state.donate_page_events_dutch_heading} onChildChange={this.changeAlertEventsDutchHeadingDonate.bind(this)} />
              </div>
              <div className='inner-celandingpage'>
                <label>English</label>
                <CK data={this.state.donate_page_events_english_heading} onChildChange={this.changeAlertEventsEnglishHeadingDonate.bind(this)} />
              </div>
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>Events Heading Inner</label>
              </div>
            </div>
            <div className='container-editors-lp'>
              <div className='inner-celandingpage'>
                <label>Dutch</label>
                <CK data={this.state.donate_page_events_dutch_heading_inner} onChildChange={this.changeAlertEventsDutchHeadingInnerDonate.bind(this)} />
              </div>
              <div className='inner-celandingpage'>
                <label>English</label>
                <CK data={this.state.donate_page_events_english_heading_inner} onChildChange={this.changeAlertEventsEnglishHeadingInnerDonate.bind(this)} />
              </div>
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>Events Header Text</label>
              </div>
            </div>
            <div className='container-editors-lp'>
              <div className='inner-celandingpage'>
                <label>Dutch</label>
                <CK data={this.state.donate_page_events_dutch_headertxt} onChildChange={this.changeAlertEventsDutchHeaderTxtDonate.bind(this)} />
              </div>
              <div className='inner-celandingpage'>
                <label>English</label>
                <CK data={this.state.donate_page_events_english_headertxt} onChildChange={this.changeAlertEventsEnglishHeaderTxtDonate.bind(this)} />
              </div>
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>Events</label>
              </div>
            </div>
            <div className='container-editors-lp'>
              <div className='inner-celandingpage'>
                <label>Dutch</label>
                <CK data={this.state.donate_page_events_dutch} onChildChange={this.changeAlertEventsDutchDonate.bind(this)} />
              </div>
              <div className='inner-celandingpage'>
                <label>English</label>
                <CK data={this.state.donate_page_events_english} onChildChange={this.changeAlertEventsEnglishDonate.bind(this)} />
              </div>
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>Events right image</label>
              </div>
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center" }}>
              <input type="file" style={{ padding: 15, border: "1px solid gray" }} accept="image/*" onChange={(e) => this.fileUploadEventsImageDonate(e)} />
            </div>
            {/* bhodi.com/gift */}
            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>bhodi.com/gift</label>
              </div>
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>6th section heading</label>
              </div>
            </div>
            <div className='container-editors-lp'>
              <div className='inner-celandingpage'>
                <label>Dutch</label>
                <CK data={this.state.gift_sixth_section_heading} onChildChange={this.saveValueToDb.bind(this, "gift_sixth_section_heading")} />
              </div>
              <div className='inner-celandingpage'>
                <label>English</label>
                <CK data={this.state.gift_sixth_section_english_heading} onChildChange={this.saveValueToDb.bind(this, "gift_sixth_section_english_heading")} />
              </div>
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>6th section text</label>
              </div>
            </div>
            <div className='container-editors-lp'>
              <div className='inner-celandingpage'>
                <label>Dutch</label>
                <CK data={this.state.gift_sixth_section_text} onChildChange={this.saveValueToDb.bind(this, "gift_sixth_section_text")} />
              </div>
              <div className='inner-celandingpage'>
                <label>English</label>
                <CK data={this.state.gift_sixth_section_english_text} onChildChange={this.saveValueToDb.bind(this, "gift_sixth_section_english_text")} />
              </div>
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>7th section heading</label>
              </div>
            </div>
            <div className='container-editors-lp'>
              <div className='inner-celandingpage'>
                <label>Dutch</label>
                <CK data={this.state.gift_seventh_section_heading} onChildChange={this.saveValueToDb.bind(this, "gift_seventh_section_heading")} />
              </div>
              <div className='inner-celandingpage'>
                <label>English</label>
                <CK data={this.state.gift_seventh_section_english_heading} onChildChange={this.saveValueToDb.bind(this, "gift_seventh_section_english_heading")} />
              </div>
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>7th section text</label>
              </div>
            </div>
            <div className='container-editors-lp'>
              <div className='inner-celandingpage'>
                <label>Dutch</label>
                <CK data={this.state.gift_seventh_section_text} onChildChange={this.saveValueToDb.bind(this, "gift_seventh_section_text")} />
              </div>
              <div className='inner-celandingpage'>
                <label>English</label>
                <CK data={this.state.gift_seventh_section_english_text} onChildChange={this.saveValueToDb.bind(this, "gift_seventh_section_english_text")} />
              </div>
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>7th section first image</label>
              </div>
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center" }}>
              <input type="file" style={{ padding: 15, border: "1px solid gray" }} accept="image/*" onChange={(e) => this.fileUploadToDB(e, "gift_seventh_section_first_image")} />
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>7th section second image</label>
              </div>
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center" }}>
              <input type="file" style={{ padding: 15, border: "1px solid gray" }} accept="image/*" onChange={(e) => this.fileUploadToDB(e, "gift_seventh_section_second_image")} />
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>About section heading</label>
              </div>
            </div>
            <div className='container-editors-lp'>
              <div className='inner-celandingpage'>
                <label>Dutch</label>
                <CK data={this.state.gift_about_section_heading} onChildChange={this.saveValueToDb.bind(this, "gift_about_section_heading")} />
              </div>
              <div className='inner-celandingpage'>
                <label>English</label>
                <CK data={this.state.gift_about_section_english_heading} onChildChange={this.saveValueToDb.bind(this, "gift_about_section_english_heading")} />
              </div>
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>About section text</label>
              </div>
            </div>
            <div className='container-editors-lp'>
              <div className='inner-celandingpage'>
                <label>Dutch</label>
                <CK data={this.state.gift_about_section_text} onChildChange={this.saveValueToDb.bind(this, "gift_about_section_text")} />
              </div>
              <div className='inner-celandingpage'>
                <label>English</label>
                <CK data={this.state.gift_about_section_english_text} onChildChange={this.saveValueToDb.bind(this, "gift_about_section_english_text")} />
              </div>
            </div>

            {/* landing-page-new */}
            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>bhodi.com/landing-page-new</label>
              </div>
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>Centered Section Desc</label>
              </div>
            </div>
            <div className='container-editors-lp'>
              <div className='inner-celandingpage'>
                <label>Dutch</label>
                <CK data={this.state.landing_page_new_desc} onChildChange={this.saveValueToDb.bind(this, "landing_page_new_desc")} />
              </div>
              <div className='inner-celandingpage'>
                <label>English</label>
                <CK data={this.state.landing_page_new_desc_english} onChildChange={this.saveValueToDb.bind(this, "landing_page_new_desc_english")} />
              </div>
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>Centered Section Date</label>
              </div>
            </div>
            <div className='container-editors-lp'>
              <div className='inner-celandingpage'>
                <label>Dutch</label>
                <CK data={this.state.landing_page_new_date} onChildChange={this.saveValueToDb.bind(this, "landing_page_new_date")} />
              </div>
              <div className='inner-celandingpage'>
                <label>English</label>
                <CK data={this.state.landing_page_new_date_english} onChildChange={this.saveValueToDb.bind(this, "landing_page_new_date_english")} />
              </div>
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>Default video</label>
              </div>
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center" }}>
              <input type="file" style={{ padding: 15, border: "1px solid gray" }} accept="video/*" onChange={(e) => this.fileUploadToDB(e, "landing_page_new_video")} />
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>Step 2 video</label>
              </div>
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center" }}>
              <input type="file" style={{ padding: 15, border: "1px solid gray" }} accept="video/*" onChange={(e) => this.fileUploadToDB(e, "landing_page_new_video_second")} />
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
              <div className='inner-celandingpage headinglabelcelp'>
                <label>Player video</label>
              </div>
            </div>
            <div className='container-editors-lp' style={{ justifyContent: "center" }}>
              <input type="file" style={{ padding: 15, border: "1px solid gray" }} accept="video/*" onChange={(e) => this.fileUploadToDB(e, "landing_page_new_video_player")} />
            </div>

          </div>
        </div>)}
      </div >
    )
  }
}


const mapStateToProps = (state) => {
  return {
    global_values: state.globalReducers.global_values,
    user: state.authReducers.user,
    admin_boolean: state.globalReducers.admin_boolean,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateGlobalStuff: (global_values) => dispatch(updateGlobalStuff(global_values)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditorLandingPage)
