import React, { Component } from "react";
import AddIcon from "@material-ui/icons/Add";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import SortableTree from "react-sortable-tree";
import "react-sortable-tree/style.css";
import CloseIcon from "@material-ui/icons/Close";
import { updateGlobalStuff } from "../Redux/actions/authActions";
import { connect } from "react-redux";
import AddCircleOutline from "@material-ui/icons/AddCircleOutline";
import firebase from "../Config/Firebase";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import AdminNavbar from "./AdminNavbar";
import CK from "./Ck";

export class DashboardSettingsMember extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dashboardMemberDesc: "",
            memberFirstHeading: "",
            memberSecondHeading: "",
            memberLandingPageText: "",
            dailyNewAddedText: "",
        };
    }

    componentDidMount() {
        const loaderId = document.getElementById("main-page-loader-id");
        loaderId.style.display = "none";


        if (this.props.global_values) {
            let a = this.props.global_values;
            if (a) {
                const {
                    memberFirstHeading,
                    memberSecondHeading,
                    dashboardMemberDesc,
                    memberLandingPageText,
                    dailyNewAddedText,
                    bhodiNewsHeading,
                    bhodiNewsText,
                } = a;

                this.setState({
                    memberFirstHeading: memberFirstHeading || "",
                    memberSecondHeading: memberSecondHeading || "",
                    dashboardMemberDesc: dashboardMemberDesc || "",
                    memberLandingPageText: memberLandingPageText || "",
                    dailyNewAddedText: dailyNewAddedText || "",
                    bhodiNewsHeading: bhodiNewsHeading || "",
                    bhodiNewsText: bhodiNewsText || "",
                });
            }
        }

        firebase
            .database()
            .ref("bhodi_home")
            .on("value", (data) => {
                let a = data.val();
                if (a) {
                    this.props.updateGlobalStuff(a);

                    const {
                        memberFirstHeading,
                        memberSecondHeading,
                        dashboardMemberDesc,
                        memberLandingPageText,
                        dailyNewAddedText,
                        bhodiNewsHeading,
                        bhodiNewsText,
                    } = a;

                    this.setState({
                        memberFirstHeading: memberFirstHeading || "",
                        memberSecondHeading: memberSecondHeading || "",
                        memberLandingPageText: memberLandingPageText || "",
                        dashboardMemberDesc: dashboardMemberDesc || "",
                        dailyNewAddedText: dailyNewAddedText || "",
                        bhodiNewsHeading: bhodiNewsHeading || "",
                        bhodiNewsText: bhodiNewsText || "",
                    });
                }
            });
    }

    saveDashboardInfo() {
        const {
            memberFirstHeading,
            memberSecondHeading,
            dashboardMemberDesc,
            memberLandingPageText,
            dailyNewAddedText,
            bhodiNewsHeading,
            bhodiNewsText
        } = this.state;
        firebase
            .database()
            .ref("bhodi_home/memberSecondHeading")
            .set(memberSecondHeading);
        firebase
            .database()
            .ref("bhodi_home/memberFirstHeading")
            .set(memberFirstHeading);
        firebase
            .database()
            .ref("bhodi_home/dailyNewAddedText")
            .set(dailyNewAddedText);
        firebase
            .database()
            .ref("bhodi_home/dashboardMemberDesc")
            .set(dashboardMemberDesc);
        firebase
            .database()
            .ref("bhodi_home/memberLandingPageText")
            .set(memberLandingPageText);
        firebase
            .database()
            .ref("bhodi_home/bhodiNewsHeading")
            .set(bhodiNewsHeading);
        firebase
            .database()
            .ref("bhodi_home/bhodiNewsText")
            .set(bhodiNewsText);
    }

    uploadMobileBg(e) {
        let files = e.target.files[0];
        let type = ""
        if (e.target.files[0].type.match(/image.*/)) {
            type = "image"
        } else {
            type = "video"
        }
        let storageRef = firebase
            .storage()
            .ref()
            .child(`bhodi_home_media/${files.name}${new Date().getTime()}`);
        storageRef.put(files).then((snapshot) => {
            snapshot.ref.getDownloadURL().then((snapUrl) => {
                firebase
                    .database()
                    .ref("bhodi_home/memberDashboardMobileUrl")
                    .set(snapUrl)
                firebase
                    .database()
                    .ref("bhodi_home/memberDashboardMobileType")
                    .set(type)
            });
        });
    }

    uploadDesktopBg(e) {
        let files = e.target.files[0];
        let type = ""
        if (e.target.files[0].type.match(/image.*/)) {
            type = "image"
        } else {
            type = "video"
        }
        let storageRef = firebase
            .storage()
            .ref()
            .child(`bhodi_home_media/${files.name}${new Date().getTime()}`);
        storageRef.put(files).then((snapshot) => {
            snapshot.ref.getDownloadURL().then((snapUrl) => {
                firebase
                    .database()
                    .ref("bhodi_home/memberDashboardDesktopUrl/")
                    .set(snapUrl)
                firebase
                    .database()
                    .ref("bhodi_home/memberDashboardDesktopType/")
                    .set(type)
            });
        });
    }


    changeAlertFifthSlideHeading(e) {
        this.setState({ dashboardMemberDesc: e });
    }

    render() {
        return (
            <div className="sorting-mainadmin">
                <AdminNavbar intervalSettings={true} />
                <div style={{ display: "flex" }}>
                    <div className="container-sma">
                        <div className="info-div-editor">
                            <div className="group-textbox" style={{ textAlign: "center" }}>
                                <label style={{ fontSize: 16, fontWeight: 600 }}>
                                    MEMBER DASHBOARD
                                </label>
                            </div>
                            <div className="group-textbox">
                                <label>DAILY NEW ADDED TEXT</label>
                                <input
                                    value={this.state.dailyNewAddedText}
                                    onChange={(e) => {
                                        this.setState({ dailyNewAddedText: e.target.value });
                                    }}
                                />
                            </div>
                            <div className="group-textbox">
                                <label>HEADING</label>
                                <input
                                    value={this.state.memberFirstHeading}
                                    onChange={(e) => {
                                        this.setState({ memberFirstHeading: e.target.value });
                                    }}
                                />
                            </div>
                            <div className="group-textbox">
                                <label>2ND HEADING</label>
                                <input
                                    value={this.state.memberSecondHeading}
                                    onChange={(e) => {
                                        this.setState({ memberSecondHeading: e.target.value });
                                    }}
                                />
                            </div>
                            <div className="group-textbox">
                                <label>MEMBER LANDINGPAGE TEXT</label>
                                <input
                                    value={this.state.memberLandingPageText}
                                    onChange={(e) => {
                                        this.setState({ memberLandingPageText: e.target.value });
                                    }}
                                />
                            </div>
                            <div className="group-textbox">
                                <label>DESCRIPTION</label>
                                {this.state.dashboardMemberDesc && (<CK
                                    data={
                                        this.state.dashboardMemberDesc
                                    }
                                    onChildChange={this.changeAlertFifthSlideHeading.bind(this)}
                                />)}
                            </div>
                            <div className="group-textbox">
                                <label>MOBILE BG IMG/VIDEO</label>
                                <input
                                    type="file"
                                    style={{ padding: 15, border: "1px solid gray" }}
                                    accept="image/*, video/*"
                                    onChange={(e) => this.uploadMobileBg(e)}
                                />
                                {/* <input type="file" onChange={(e) => this.uploadMediaLiveEvent()} /> */}
                            </div>
                            <div className="group-textbox">
                                <label>DESKTOP BG IMG/VIDEO</label>
                                <input
                                    type="file"
                                    style={{ padding: 15, border: "1px solid gray" }}
                                    accept="image/*, video/*"
                                    onChange={(e) => this.uploadDesktopBg(e)}
                                />
                                {/* <input type="file" onChange={(e) => this.uploadMediaLiveEvent()} /> */}
                            </div>
                            <div className="group-textbox">
                                <button onClick={this.saveDashboardInfo.bind(this)}>SAVE</button>
                                {/* <button onClick={this.saveCampaignInfo.bind(this)}>SAVE</button> */}
                            </div>
                        </div>
                    </div>
                    <div className="container-sma">
                        <div className="info-div-editor">
                            <div className="group-textbox" style={{ textAlign: "center" }}>
                                <label style={{ fontSize: 16, fontWeight: 600 }}>
                                    BHODI NEWS
                                </label>
                            </div>
                            <div className="group-textbox">
                                <label>HEADING</label>
                                <input
                                    value={this.state.bhodiNewsHeading}
                                    onChange={(e) => {
                                        this.setState({ bhodiNewsHeading: e.target.value });
                                    }}
                                />
                            </div>
                            <div className="group-textbox">
                                <label>TEXT</label>
                                <input
                                    value={this.state.bhodiNewsText}
                                    onChange={(e) => {
                                        this.setState({ bhodiNewsText: e.target.value });
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        global_values: state.globalReducers.global_values,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateGlobalStuff: (global_values) => dispatch(updateGlobalStuff(global_values)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardSettingsMember);
