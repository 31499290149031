import React, { Component } from "react";
import { connect } from "react-redux";
import { history } from "../Config/Routes";


export class CommentsTopUser extends Component {
  constructor() {
    super();
    this.state = {
      currentChatUser: "",
    };
  }

  componentDidMount() {
    const loaderId = document.getElementById("main-page-loader-id");
    loaderId.style.display = "none";
    let filtered = this.props.user.filter((e) => {
      return e.user_id === this.props.toShowUserId;
    });
    if (filtered.length > 0) {
      this.setState({ currentChatUser: filtered[0] });
    }
  }

  getCurrentUserChatLikes(userMain) {
    if (this.props.images) {
      let filtered = this.props.images.filter((e) => {
        return e.created_by === userMain.user_id;
      });
      let allLikes = [];
      filtered.map((y) => {
        if (y.liked_by) {
          let objected = Object.entries(y.liked_by);
          objected.map((t) => {
            allLikes.push(t[1]);
          });
        }
      });
      return allLikes.length;
    } else {
      return `0`;
    }
  }

  getCurrentUserChatCount(userMain) {
    if (this.props.images) {
      let filtered = this.props.images.filter((e) => {
        return e.created_by === userMain.user_id;
      });
      return filtered.length;
    } else {
      return `0`;
    }
  }

  redirectUser() {
    if (this.props.redirectUserOnImage) {
      localStorage.removeItem("onCurrentTab");
      localStorage.removeItem("onCurrentTabCompany");
      setTimeout(() => {
        if (this.state.currentChatUser.type === "company") {
          history.push(`/company/${this.state.currentChatUser.user_name_id}`)
        } else {
          history.push(`/member/${this.state.currentChatUser.user_name_id}`)
        }
      }, 200);
    }
  }

  render() {
    return (
      <div className="user-info-settings-albums" style={{ marginTop: 10 }}>
        {!this.state.currentChatUser.hideProfileImage &&
          this.state.currentChatUser.profile_image ? (
          <img
            style={{ border: "none" }}
            src={this.state.currentChatUser.profile_image}
            onClick={this.redirectUser.bind(this)}
          />
        ) : (
          <img
            style={{ border: "none" }}
            src="https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9"
            onClick={this.redirectUser.bind(this)}
          />
        )}
        <div
          onClick={() => {
            if (this.state.currentChatUser.type === "company") {
              window.location.href = `/company/${this.state.currentChatUser.user_name_id}`;
            } else {
              window.location.href = `/member/${this.state.currentChatUser.user_name_id}`;
            }
          }}
        >
          {this.props.showBericht ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              {this.state.currentChatUser.type === "company" ? (
                <p>{this.state.currentChatUser.company_name}</p>
              ) : (
                <p>
                  {this.state.currentChatUser.first_name}{" "}
                  {this.state.currentChatUser.last_name}
                </p>
              )}
            </div>
          ) : (
            <div style={{ display: "flex", alignItems: "center" }}>
              {this.state.currentChatUser.type === "company" ? (
                <p>{this.state.currentChatUser.company_name}</p>
              ) : (
                <p>
                  {this.state.currentChatUser.first_name}{" "}
                  {this.state.currentChatUser.last_name}
                </p>
              )}
            </div>
          )}
          {!this.props.hideBottomIcons && (<div
            style={{ display: "flex", alignItems: "center" }}
            className="chatuserinfo-main-top"
          >
            {this.props.showBericht && (
              <p>
                {this.getCurrentUserChatCount(this.state.currentChatUser)}{" "}
                bericht
              </p>
            )}
            {/* { <p>{this.state.allMessages.length} bericht</p>} */}
            <p>
              {this.state.currentChatUser.followed_by
                ? Object.entries(this.state.currentChatUser.followed_by).length
                : 0}{" "}
              volgers
            </p>
            <p>
              {this.state.currentChatUser.following
                ? Object.entries(this.state.currentChatUser.following).length
                : 0}{" "}
              volgend
            </p>
          </div>)}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducers.user,
    album: state.postsReducers.album,
    images: state.postsReducers.images,
    product: state.campaignsReducer.product,
    vouchers: state.campaignsReducer.vouchers,
  };
};

export default connect(mapStateToProps)(CommentsTopUser);
