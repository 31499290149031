import React, { Component } from 'react'
import videoMain from "../assets/timervoucher.mp4"
import Footer from './Footer'
import logoheaderWhite from "../assets/logoheaderlifestyle.png";
import TimerGlobalPageComponent from './TimerGlobalPageComponent'

export class TimerGlobalPages extends Component {
    render() {
        return (
            this.props.timerDate && this.props.timerTime ? (<div className='timer-global-page-work' style={{ height: window.innerHeight }}>
                <video src={videoMain} autoPlay muted loop playsInline data-keepplaying />
                <div className='content-tgpwo'>
                    {this.props.fromCompany ? (<h1>{this.props.fromCompany.company_name}</h1>) : (<img src={logoheaderWhite} alt="logo-image" />)}
                    <h1>RETAIL POP-UP EVENTS</h1>
                    <TimerGlobalPageComponent timeToCalculate={new Date(Number(this.props.timerDate.split("-")[0]), Number(this.props.timerDate.split("-")[1] - 1), Number(this.props.timerDate.split("-")[2]), Number(this.props.timerTime.split(":")[0]), Number(this.props.timerTime.split(":")[1]), 0).getTime()} closeScreen={() => {
                        this.props.closeScreen()
                    }} />
                    <h3>Save the date | 23-2-2023</h3>
                </div>
                <Footer />
            </div>) : (<div></div>)
        )
    }
}

export default TimerGlobalPages;
