import React, { Component } from 'react'
import { connect } from 'react-redux';
import firebase from "../../../Config/Firebase"
import {
    updateAdminOrNot,
    updateGlobalStuff,
    updateAlbumToAdd,
} from "../../../Redux/actions/authActions";
import EventsNewSection from '../../../Components/EventsNewSection';
import Footer from '../../../Components/Footer';
import FooterGreen from '../../../Components/FooterGreen';
import "./index.css"
import { mergeAdminEvent } from '../../../Functions/globalFunctions';

export class CompanyCampaign extends Component {
    constructor() {
        super()
        this.state = {
            alreadyVisitedOnce: false,
            linkAlreadyUsed: false,

            isLoading: true,
            maxTime: "",

            currentCompanyEvent: "",
            currentCompany: "",
            accessCode: "",

            accessCodeUser: "",
            accessCodeUserEmpty: false,
            showCampaignInfo: false,
        }
    }

    componentDidMount() {
        let company_id = this.props.match.params.company_id

        firebase
            .database()
            .ref("users/" + company_id)
            .once("value", (data) => {
                if (data.val()) {
                    let companyData = data.val()

                    let interval = setInterval(() => {
                        if (this.props.global_campaigns) {
                            this.getCampaignData(companyData)
                            clearInterval(interval)
                        }
                    }, 100);

                }
            });
    }

    async getCampaignData(currentUser) {
        let campaignDataRef = await firebase.database().ref(`bhodi_campaigns/${currentUser.user_id}`).orderByChild("campaignType").equalTo("local_event").once("value")
        let campaignsData = await campaignDataRef.val()

        let companyCampaigns = []
        if (campaignsData) {
            Object.entries(campaignsData).map((campaignInfo) => {
                companyCampaigns.push(campaignInfo[1])
            })
        }

        let eventToRender = companyCampaigns[0]

        if (eventToRender.takeFromAdmin && this.props.global_campaigns) {
            let obj = mergeAdminEvent(eventToRender, this.props.global_campaigns)
            eventToRender = Object.assign({}, obj)
        }

        this.setState({ currentCompanyEvent: eventToRender, currentCompany: currentUser, isLoading: false })
    }

    render() {
        return (
            this.state.isLoading ? (<div
                className="global-event-cart globaleventloaderpage"
                style={{
                    height: "auto",
                    top: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: 999999999,
                }}
            >
                <div className="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                {window.innerWidth > 1008 && <Footer notOnMainPage={true} />}
                {window.innerWidth < 1008 && <FooterGreen notOnMainPage={true} />}
            </div>) : (this.state.currentCompanyEvent) && (<div className='company-campaign-guest-new'>
                <EventsNewSection
                    campaignToShow={[this.state.currentCompanyEvent]}
                    redirectToPaymentLink={true}
                    currentCompany={this.state.currentCompany}
                />
                {window.innerWidth > 1008 && <Footer notOnMainPage={true} />}
                {window.innerWidth < 1008 && <FooterGreen notOnMainPage={true} />}
            </div>)
        )
    }
}


const mapStateToProps = (state) => {
    return {
        global_values: state.globalReducers.global_values,
        user: state.authReducers.user,
        admin_boolean: state.globalReducers.admin_boolean,
        globalsectors: state.categoriesReducer.globalsectors,
        global_campaigns: state.campaignsReducer.global_campaigns,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateAdminOrNot: (admin_boolean) => dispatch(updateAdminOrNot(admin_boolean)),
        updateGlobalStuff: (global_values) => dispatch(updateGlobalStuff(global_values)),
        updateAlbumToAdd: (albumToAdd) => dispatch(updateAlbumToAdd(albumToAdd)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompanyCampaign);