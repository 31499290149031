import React, { Component } from "react";
import firebase from "../../Config/Firebase";
import Footer from "../../Components/Footer";
import {
  updateGlobalStuff,
  updateAdminOrNot,
} from "../../Redux/actions/authActions";
import { connect } from "react-redux";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import "./index.css";
import FooterGreen from "../../Components/FooterGreen";
import { history } from "../../Config/Routes";
import { Link } from "react-router-dom";

export class GuestVisibility extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      mainImage: "",
      mainVideo: "",
      currentUserData: "",
    };
  }

  async componentDidMount() {
    const loaderId = document.getElementById("main-page-loader-id");
    loaderId.style.display = "none";
    if (this.props.global_values) {
      let a = this.props.global_values;
      if (a) {
        if (a.main_image) {
          this.setState({ mainImage: a.main_image, mainVideo: "" });
        } else {
          this.setState({ mainVideo: a.main_video, mainImage: "" });
        }
      }
    }

    if (window.location.href.includes("/member/")) {
      console.log(this.props, "/working");
      this.setState({ showMemberData: true });
      if (this.props.user) {
        if (this.props.user.length > 0) {
          let filtered = this.props.user.filter((h) => {
            return h.user_name_id === this.props.match.params.user_id;
          });
          if (filtered.length > 0) {
            this.setState({ currentUserData: filtered[0] });
          }
        }
      }
      firebase
        .database()
        .ref("users")
        .orderByChild("user_name_id")
        .equalTo(this.props.match.params.user_id)
        .on("child_added", (data) => {
          let a = data.val();
          this.setState({ currentUserData: a });
        });
    }

    firebase
      .database()
      .ref("bhodi_home")
      .on("value", (data) => {
        let a = data.val();
        if (a) {
          this.props.updateGlobalStuff(a);
          if (a.main_image) {
            this.setState({ mainImage: a.main_image, mainVideo: "" });
          } else {
            this.setState({ mainVideo: a.main_video, mainImage: "" });
          }
        }
      });
  }

  logIn() {
    const { email, password } = this.state;
    let regexEmail =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email === "" || !regexEmail.test(email)) {
      this.setState({ emailInput: true, email: "" });
    } else {
      this.setState({ emailInput: false });
    }
    if (password === "" || password.length < 6) {
      this.setState({ passwordInput: true, password: "" });
    } else {
      this.setState({ passwordInput: false });
    }
    if (
      email !== "" &&
      regexEmail.test(email) &&
      password.length > 5 &&
      password !== ""
    ) {
      this.setState({ loader: true });
      if (email === "admin@bhodi.com") {
        firebase
          .auth()
          .signInWithEmailAndPassword(email, password)
          .then((succ) => {
            firebase
              .database()
              .ref("users/" + succ.user.uid)
              .on("value", (data) => {
                localStorage.setItem("userid", succ.user.uid);
                localStorage.setItem("userdata", JSON.stringify(data.val()));
                this.props.updateAdminOrNot(true);
                localStorage.setItem("isAdmin", true);
                localStorage.removeItem("onCurrentTab");
                localStorage.removeItem("onCurrentTabCompany");
                // window.location.href = "/admin"
                window.location.reload();
              });
          })
          .catch((err) => {
            if (err.code === "auth/user-not-found") {
              this.setState({ loader: false, emailInput: true, email: "" });
            } else if (err.code === "auth/wrong-password") {
              this.setState({
                loader: false,
                passwordInput: true,
                password: "",
              });
            }
          });
      } else {
        firebase
          .auth()
          .signInWithEmailAndPassword(email, password)
          .then((succ) => {
            firebase
              .database()
              .ref("users/" + succ.user.uid)
              .on("value", (data) => {
                // if (data.val().type === "user") {
                //     if (this.state.locationPresent) {
                //         firebase.database().ref("users/" + succ.user.uid + "/latitude").set(this.state.latitude)
                //         firebase.database().ref("users/" + succ.user.uid + "/longitude").set(this.state.longitude)
                //     }
                //     firebase.database().ref("users/" + succ.user.uid + "/country").set(this.state.country)
                // }
                // localStorage.setItem("userid", succ.user.uid)
                // localStorage.setItem("userdata", JSON.stringify(data.val()))
                // localStorage.setItem("isAdmin", false)
                // localStorage.removeItem("onCurrentTab")
                // localStorage.removeItem("onCurrentTabCompany")
                // localStorage.setItem("currentAlbumOwnImage", "vrienden")
                // localStorage.setItem("currentAlbumOwnImageFriends", "vrienden")
                // if (data.val().type === "user") {
                //     window.location.href = `/member/${data.val().user_name_id}`
                // } else {
                //     window.location.href = `/company/${data.val().user_name_id}`
                // }
                let promiseOne = new Promise((res) => {
                  if (data.val().type === "user") {
                    navigator.geolocation.getCurrentPosition((position) => {
                      firebase
                        .database()
                        .ref("users/" + succ.user.uid + "/latitude")
                        .set(position.coords.latitude);
                      firebase
                        .database()
                        .ref("users/" + succ.user.uid + "/longitude")
                        .set(position.coords.longitude);
                      localStorage.setItem(
                        "latitudeAfterLogin",
                        position.coords.latitude
                      );
                      localStorage.setItem(
                        "longitudeAfterLogin",
                        position.coords.longitude
                      );
                      res();
                    });
                  } else {
                    navigator.geolocation.getCurrentPosition((position) => {
                      localStorage.setItem(
                        "latitudeAfterLogin",
                        position.coords.latitude
                      );
                      localStorage.setItem(
                        "longitudeAfterLogin",
                        position.coords.longitude
                      );
                      res();
                    });
                  }
                  setTimeout(() => {
                    res();
                  }, 4000);
                });
                promiseOne.then(() => {
                  localStorage.setItem("userid", succ.user.uid);
                  // localStorage.setItem("userdata", JSON.stringify(data.val()))
                  localStorage.setItem("isAdmin", false);
                  this.props.updateAdminOrNot(false);
                  localStorage.removeItem("onCurrentTab");
                  localStorage.removeItem("onCurrentTabCompany");
                  localStorage.setItem("currentAlbumOwnImage", "vrienden");
                  localStorage.setItem(
                    "currentAlbumOwnImageFriends",
                    "vrienden"
                  );
                  localStorage.removeItem("cookie_item");
                  setTimeout(() => {
                    window.location.reload();
                  }, 400);
                });
              });
          })
          .catch((err) => {
            if (err.code === "auth/user-not-found") {
              this.setState({ loader: false, emailInput: true, email: "" });
            } else if (err.code === "auth/wrong-password") {
              this.setState({
                loader: false,
                passwordInput: true,
                password: "",
              });
            }
          });
      }
    }
  }

  sendResetLink() {
    const { email, password } = this.state;
    let regexEmail =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email === "" || !regexEmail.test(email)) {
      this.setState({ emailInput: true, email: "" });
    } else {
      this.setState({ emailInput: false });
    }
    if (email !== "" && regexEmail.test(email)) {
      this.setState({ loader: true });
      firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(() => {
          this.setState({ loader: false, forgetPassword: false });
        })
        .catch((error) => {
          var errorMessage = error.message;
          if (
            errorMessage ===
            "There is no user record corresponding to this identifier. The user may have been deleted."
          ) {
            this.setState({ emailNotFound: true, loader: false });
          }
          // ..
        });
    }
  }

  render() {
    return (
      <div
        className="not-loggedinposts"
        style={{
          backgroundImage: this.state.mainImage
            ? `url("${this.state.mainImage})`
            : "none",
        }}
      >
        {this.state.mainVideo && (
          <video
            src={this.state.mainVideo}
            autoPlay
            loop
            muted
            playsInline
            className="videocomplete"
          ></video>
        )}
        {this.state.forgetPassword ? (
          <div className="inner-nlp">
            {this.state.currentUserData.profile_image ? (
              <img
                src={this.state.currentUserData.profile_image}
                style={{
                  width: 30,
                  height: 30,
                  borderRadius: "50%",
                  marginBottom: 10,
                }}
              />
            ) : (
              <AccountCircleIcon style={{ fontSize: 28, marginBottom: 20 }} />
            )}
            <h3
              style={{
                fontSize: 22,
                letterSpacing: 1,
                textTransform: "uppercase",
              }}
            >
              {this.state.currentUserData.first_name}{" "}
              {this.state.currentUserData.last_name}
            </h3>
            <p className="second-title-inlp">Login to see the page.</p>
            {this.state.emailInput ? (
              <input
                type="email"
                autoComplete="off"
                placeholder="Email is not valid"
                className="doalertplaceholderred"
                value={this.state.email}
                onChange={(e) => this.setState({ email: e.target.value })}
              />
            ) : (
              <input
                type="email"
                autoComplete="off"
                placeholder="Email"
                value={this.state.email}
                onChange={(e) => this.setState({ email: e.target.value })}
              />
            )}
            <button onClick={this.sendResetLink.bind(this)}>SEND</button>
            <Link to="/" className="link-style">
              <p className="bottom-title-inlp">
                <font
                  style={{ cursor: "pointer" }}
                  // Commented by me
                  // onClick={() => {
                  //   window.location.href = "/";
                  // }}
                >
                  Nieuw account &nbsp;
                </font>
                <font
                  style={{ cursor: "pointer" }}
                  onClick={() => this.setState({ forgetPassword: false })}
                >
                  Login?
                </font>
              </p>
            </Link>
          </div>
        ) : (
          <div className="inner-nlp">
            {this.state.currentUserData.profile_image ? (
              <img
                src={this.state.currentUserData.profile_image}
                style={{
                  width: 30,
                  height: 30,
                  borderRadius: "50%",
                  marginBottom: 10,
                }}
              />
            ) : (
              <AccountCircleIcon style={{ fontSize: 28, marginBottom: 20 }} />
            )}
            <h3
              style={{
                fontSize: 22,
                letterSpacing: 1,
                textTransform: "uppercase",
              }}
            >
              {this.state.currentUserData.first_name}{" "}
              {this.state.currentUserData.last_name}
            </h3>
            <p className="second-title-inlp">Login to see the page.</p>
            {this.state.emailInput ? (
              <input
                type="email"
                autoComplete="off"
                placeholder="Email is not valid"
                className="doalertplaceholderred"
                value={this.state.email}
                onChange={(e) => this.setState({ email: e.target.value })}
              />
            ) : (
              <input
                type="email"
                autoComplete="off"
                placeholder="Email"
                value={this.state.email}
                onChange={(e) => this.setState({ email: e.target.value })}
              />
            )}
            <input
              type="password"
              className={`${
                this.state.passwordInput && "doalertplaceholderred"
              }`}
              placeholder="Wachtwoord"
              value={this.state.password}
              onChange={(e) => {
                this.setState({ password: e.target.value });
              }}
            />
            <button onClick={this.logIn.bind(this)}>INLOGGEN</button>
            <p className="bottom-title-inlp">
              <font
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.location.href = "/";
                }}
              >
                Nieuw account &nbsp;
              </font>
              <font
                style={{ cursor: "pointer" }}
                onClick={() => this.setState({ forgetPassword: true })}
              >
                Wachtwoord vergeten?
              </font>
            </p>
          </div>
        )}
        {window.innerWidth > 1008 && <Footer notOnMainPage={true} />}
        {window.innerWidth < 1008 && <FooterGreen notOnMainPage={true} />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    global_values: state.globalReducers.global_values,
    admin_boolean: state.globalReducers.admin_boolean,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateGlobalStuff: (global_values) =>
      dispatch(updateGlobalStuff(global_values)),
    updateAdminOrNot: (admin_boolean) =>
      dispatch(updateAdminOrNot(admin_boolean)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GuestVisibility);
