import React, { Component } from 'react'
import PermMediaIcon from "@material-ui/icons/PermMedia";
import AddIcon from "@material-ui/icons/Add";
import AddEditProduct from './AddEditProduct';
import EditIcon from "@material-ui/icons/Edit";
import { connect } from 'react-redux';
import {
    updateGlobalStuff,
    updateGuestUserCart,
    updateStockImages,
    updateGuestUserCartTime,
    updateGuestUserCartExtendOpportunity,
    updateBhodiProducts
} from "../../Redux/actions/authActions";
import firebase from "../../Config/Firebase"
import "./styles.css"
import Footer from '../../Components/Footer';
import FooterGreen from '../../Components/FooterGreen';
import ProductGrid from './ProductGrid';
import { history } from '../../Config/Routes';
import ProductDetailed from '../ProductDetailed';
import { NavigateNextOutlined } from '@material-ui/icons';
import ProductBannerTimer from './ProductBannerTimer';
import RecurringTimerAutoAvailable from './RecurringTimerAutoAvailable';
import GuestUserNavbar from '../../Components/GuestUserNavbar';
import { checkForSubscribedExclusives } from '../../Functions/checkForSubscribedExclusives';

let dbRef = firebase.database().ref("bhodi_products")

export class ProductsPage extends Component {

    constructor() {
        super()
        this.state = {
            allProducts: [],
            categoryData: {},
            allSectors: [],
            sectors: [],
            completeCategories: [],
            onCurrentPage: 1,
            editScreenOpen: false,
            allProductsDuplicated: [],
            currentProductToEdit: "",
            currentUserHere: "",
            currentUserCharityMember: false,
            animateDetailedPage: true,
            currentProductCarousel: 0,
            maxProductsToShow: 1,
            currentMemberData: "",
            allIndexes: [],
            timersArray: [],
            productsToRender: [],
            showBannerAnnouncement: false,
            showBannerMaintenance: false,
            showShopOffline: false,
            showRecurringBanner: false,
            indexToStartProducts: 0,
            weekdays: [
                "sunday",
                "monday",
                "tuesday",
                "wednesday",
                "thursday",
                "friday",
                "saturday",
            ],
            weeklyAvailableDate: "",
            keepShowing: false,
            productsSwapped: [],
            productsAvailableForRecurr: [],
            mustAnimate: false,
            loading: true,
            currentUserId: ""
        }
    }

    componentDidMount() {
        const loaderId = document.getElementById("main-page-loader-id");
        loaderId.style.display = "none";

        if (window.innerWidth > 1008) {
            document.getElementById("root").style.position = "fixed"
            document.getElementById("root").style.overflow = "hidden"

            document.getElementById("root").style.backgroundColor = "#F6F6F6"
        } else {
            document.querySelector(".navbarfullwidthMobile").classList.add("navbartodowhitedefault")
            document.querySelector(".navbarfullwidthMobile").style.overflowX = "hidden"
        }

        let maxProductsToShow = this.state.maxProductsToShow
        if (this.props.global_values.maxProductsToShow) {
            maxProductsToShow = this.props.global_values.maxProductsToShow
            this.setState({ maxProductsToShow: this.props.global_values.maxProductsToShow })
        }

        let indexToStartProducts = 0
        let indexToStartProductsWeekly = 0
        let indexToStartProductsDaily = 0

        let allDatesWeekly = []
        let currentWeekAt = ""
        let currentDayAt = ""
        let allDatesDaily = []

        if (this.props.currentMemberData.recurring && this.props.currentMemberData.recurring.active) {
            // this.checkForRecurringProcess(this.props.currentMemberData.recurring)
            let recurring = this.props.currentMemberData.recurring
            if (recurring.recurringType === "daily") {
                let recurringStart = new Date(recurring.storedAt)
                // let todaysTime = new Date()
                let allDatesThousandsDays = []

                recurringStart.setHours(recurring.recurringBannerTime.split(":")[0])
                recurringStart.setMinutes(recurring.recurringBannerTime.split(":")[1])
                recurringStart.setSeconds("00")

                if (recurringStart.getTime() > new Date(recurring.storedAt).getTime()) {
                    recurringStart.setDate(recurringStart.getDate() - 1)
                }


                let newDateFirst = recurringStart.setDate(recurringStart.getDate())
                allDatesThousandsDays.push(newDateFirst)

                for (let i = 0; i < 1000; i++) {
                    let newDate = recurringStart.setDate(recurringStart.getDate() + 1)
                    allDatesThousandsDays.push(newDate)
                }


                let availableDates = allDatesThousandsDays.filter((e) => {
                    return new Date(e).getTime() > new Date().getTime()
                })
                console.log(availableDates[0], '/check all days dates here')

                let getIndex = allDatesThousandsDays.findIndex((g) => {
                    return g === availableDates[0]
                })
                console.log(getIndex, '/check all days dates here')

                allDatesDaily = allDatesThousandsDays
                currentDayAt = availableDates[0]
                // var differenceTime = todaysTime.getTime() - recurringStart.getTime();

                // var differenceDays = differenceTime / (1000 * 3600 * 24);

                // let dayOnAt = Math.floor(differenceDays)

                indexToStartProducts = getIndex - 1
                indexToStartProductsDaily = getIndex - 1
            } else {
                let recurringStart = new Date(recurring.storedAt)
                let allDatesThousands = []

                recurringStart.setHours(recurring.recurringBannerTime.split(":")[0])
                recurringStart.setMinutes(recurring.recurringBannerTime.split(":")[1])
                recurringStart.setSeconds("00")

                let newDateFirst = recurringStart.setDate(recurringStart.getDate())
                // if (recurringStart.getTime() > new Date().getTime()) {
                if (this.state.weekdays[recurringStart.getDay()] === recurring.recurringDay && new Date(newDateFirst).getTime() > new Date(recurring.storedAt).getTime()) {
                    allDatesThousands.push(newDateFirst)
                    // }
                }

                for (let i = 0; i < 5000; i++) {
                    let newDate = recurringStart.setDate(recurringStart.getDate() + 1)
                    if (this.state.weekdays[new Date(newDate).getDay()] === recurring.recurringDay) {
                        allDatesThousands.push(newDate)
                    }
                }

                let availableDates = allDatesThousands.filter((e) => {
                    return new Date(e).getTime() > new Date().getTime()
                })

                let getIndex = allDatesThousands.findIndex((g) => {
                    return g === availableDates[0]
                })

                allDatesWeekly = allDatesThousands
                currentWeekAt = availableDates[0]

                this.setState({ weeklyAvailableDate: availableDates[0] })

                indexToStartProducts = getIndex
                indexToStartProductsWeekly = getIndex
            }

            maxProductsToShow = Number(this.props.currentMemberData.recurring.recurringAmount)
            this.setState({ maxProductsToShow: Number(this.props.currentMemberData.recurring.recurringAmount) })
        }

        console.log(this.props, '/announcement showing')
        if (this.props.currentMemberData) {
            this.setState({ currentMemberData: this.props.currentMemberData })
            if (this.props.currentMemberData.announcement && this.props.currentMemberData.announcement.active) {
                if (new Date(Date.parse(this.props.currentMemberData.announcement.end_date_iso)).getTime() > new Date().getTime()) {
                    this.setState({ showBannerAnnouncement: true, loading: false })
                }
            }
            if (this.props.currentMemberData.show_maintanance_banner) {
                this.setState({ showBannerMaintenance: true, loading: false })
            }
            if (this.props.currentMemberData.shop_offline) {
                this.setState({ showShopOffline: true, loading: false })
            }
            firebase
                .database()
                .ref("users/" + this.props.currentMemberData.user_id)
                .on("value", (data) => {
                    let a = data.val();
                    if (a) {
                        if (this.props.global_values.maxProductsToShow && this.props.global_values.maxProductsToShow !== this.state.maxProductsToShow) {
                            maxProductsToShow = this.props.global_values.maxProductsToShow
                            this.setState({ maxProductsToShow: this.props.global_values.maxProductsToShow })
                        }

                        if (a.recurring && a.recurring.active) {
                            // this.checkForRecurringProcess(a.recurring)
                            let recurring = a.recurring
                            if (recurring.recurringType === "daily") {
                                // let recurringStart = new Date(recurring.storedAt)
                                // let todaysTime = new Date()

                                // // recurringStart.setDate(recurringStart.getDate() - 1)
                                // recurringStart.setHours(recurring.recurringBannerTime.split(":")[0])
                                // recurringStart.setMinutes(recurring.recurringBannerTime.split(":")[1])
                                // recurringStart.setSeconds("00")

                                // if (recurringStart.getTime() > new Date(recurring.storedAt).getTime()) {
                                //     recurringStart.setDate(recurringStart.getDate() - 1)
                                // }

                                // var differenceTime = todaysTime.getTime() - recurringStart.getTime();

                                // var differenceDays = differenceTime / (1000 * 3600 * 24);

                                // let dayOnAt = Math.floor(differenceDays)

                                // indexToStartProducts = dayOnAt
                                let recurringStart = new Date(recurring.storedAt)
                                // let todaysTime = new Date()
                                let allDatesThousandsDays = []

                                recurringStart.setHours(recurring.recurringBannerTime.split(":")[0])
                                recurringStart.setMinutes(recurring.recurringBannerTime.split(":")[1])
                                recurringStart.setSeconds("00")

                                if (recurringStart.getTime() > new Date(recurring.storedAt).getTime()) {
                                    recurringStart.setDate(recurringStart.getDate() - 1)
                                }


                                let newDateFirst = recurringStart.setDate(recurringStart.getDate())
                                allDatesThousandsDays.push(newDateFirst)

                                for (let i = 0; i < 1000; i++) {
                                    let newDate = recurringStart.setDate(recurringStart.getDate() + 1)
                                    allDatesThousandsDays.push(newDate)
                                }


                                let availableDates = allDatesThousandsDays.filter((e) => {
                                    return new Date(e).getTime() > new Date().getTime()
                                })
                                console.log(availableDates[0], '/check all days dates here')

                                let getIndex = allDatesThousandsDays.findIndex((g) => {
                                    return g === availableDates[0]
                                })

                                allDatesDaily = allDatesThousandsDays
                                currentDayAt = availableDates[0]
                                // var differenceTime = todaysTime.getTime() - recurringStart.getTime();

                                // var differenceDays = differenceTime / (1000 * 3600 * 24);

                                // let dayOnAt = Math.floor(differenceDays)

                                indexToStartProducts = getIndex - 1
                                indexToStartProductsDaily = getIndex - 1
                            } else {
                                let recurringStart = new Date(recurring.storedAt)
                                let allDatesThousands = []

                                recurringStart.setHours(recurring.recurringBannerTime.split(":")[0])
                                recurringStart.setMinutes(recurring.recurringBannerTime.split(":")[1])
                                recurringStart.setSeconds("00")

                                let newDateFirst = recurringStart.setDate(recurringStart.getDate())
                                // if (recurringStart.getTime() > new Date().getTime()) {
                                if (this.state.weekdays[recurringStart.getDay()] === recurring.recurringDay && new Date(newDateFirst).getTime() > new Date(recurring.storedAt).getTime()) {
                                    // if (this.state.weekdays[new Date(newDateFirst).getDay()] === recurring.recurringDay) {
                                    allDatesThousands.push(newDateFirst)
                                    // }
                                }

                                for (let i = 0; i < 5000; i++) {
                                    let newDate = recurringStart.setDate(recurringStart.getDate() + 1)
                                    if (this.state.weekdays[new Date(newDate).getDay()] === recurring.recurringDay) {
                                        allDatesThousands.push(newDate)
                                    }
                                }

                                let availableDates = allDatesThousands.filter((e) => {
                                    return new Date(e).getTime() > new Date().getTime()
                                })

                                let getIndex = allDatesThousands.findIndex((g) => {
                                    return g === availableDates[0]
                                })

                                allDatesWeekly = allDatesThousands
                                currentWeekAt = availableDates[0]

                                this.setState({ weeklyAvailableDate: availableDates[0] })

                                indexToStartProducts = getIndex
                                indexToStartProductsWeekly = getIndex
                            }

                            maxProductsToShow = Number(a.recurring.recurringAmount)
                            if (Number(a.recurring.recurringAmount) !== this.state.maxProductsToShow) {
                                this.setState({ maxProductsToShow: Number(a.recurring.recurringAmount) })
                            }
                        }

                        if (a.announcement && a.announcement.active) {
                            if (new Date(Date.parse(a.announcement.end_date_iso)).getTime() > new Date().getTime()) {
                                console.log("announcement showing")
                                this.setState({ showBannerAnnouncement: true, loading: false })
                            } else {
                                this.setState({ showBannerAnnouncement: false })
                            }
                        } else {
                            this.setState({ showBannerAnnouncement: false })
                        }


                        if (a.show_maintanance_banner) {
                            this.setState({ showBannerMaintenance: true, loading: false })
                        } else {
                            this.setState({ showBannerMaintenance: false })
                        }

                        if (a.shop_offline) {
                            this.setState({ showShopOffline: true, loading: false })
                        } else {
                            this.setState({ showShopOffline: false })
                        }

                        this.setState({ currentMemberData: a });

                        setTimeout(() => {
                            if (this.props.bhodi_products) {
                                let filtered = this.props.bhodi_products.filter((g) => {
                                    let isAvailable = true
                                    // if (g.variations_data) {
                                    //     let objected = Object.entries(g.variations_data)
                                    //     let filtered = objected.filter((t) => {
                                    //         return Number(t[1].qty) > 0
                                    //     })
                                    //     if (filtered.length === 0) {
                                    //         isAvailable = false
                                    //     }
                                    // } else {
                                    //     if (Number(g.qty) <= 0) {
                                    //         isAvailable = false
                                    //     }
                                    // }
                                    return g.created_by === this.props.currentMemberData.user_id && g.images && isAvailable
                                })

                                let isRepeating = false
                                let availableProducts = []
                                filtered.map((product, index) => {
                                    let currentProduct = Object.assign({}, product)
                                    if (product.active) {
                                        if (product.inCartOf || product.verzilvered_by) {
                                            if (currentProduct.sizesActive) {
                                                let stocksAvailableSizes = []
                                                currentProduct.variations_data.map((e) => {
                                                    let filteredStockLength = []
                                                    let filteredStockLengthVerzilvered = []
                                                    if (product.inCartOf) {
                                                        filteredStockLength = Object.entries(product.inCartOf).filter((t) => {
                                                            return t[1].selectedSize === e.size
                                                        })
                                                    }
                                                    if (product.verzilvered_by) {
                                                        filteredStockLengthVerzilvered = Object.entries(product.verzilvered_by).filter((t) => {
                                                            return t[1].selectedSize === e.size
                                                        })
                                                    }
                                                    if ((filteredStockLength.length + filteredStockLengthVerzilvered.length) < Number(e.qty)) {
                                                        stocksAvailableSizes.push(e)
                                                    }
                                                })
                                                if (stocksAvailableSizes.length === 0) {
                                                    console.log("no stock")
                                                } else {
                                                    availableProducts.push(product)
                                                }
                                            } else {
                                                let objectedCart = []
                                                let objectedVerzilvered = []

                                                if (product.inCartOf) {
                                                    objectedCart = Object.entries(product.inCartOf)
                                                }
                                                if (product.verzilvered_by) {
                                                    objectedVerzilvered = Object.entries(product.verzilvered_by)
                                                }
                                                if ((objectedCart.length + objectedVerzilvered.length) >= Number(currentProduct.qty)) {
                                                    console.log("no stock")
                                                } else {
                                                    availableProducts.push(product)
                                                }
                                            }
                                        } else {
                                            if (product.sizesActive) {
                                                let stocksAvailableSizes = []
                                                product.variations_data.map((e) => {
                                                    if (Number(e.qty) === 0) {
                                                        return false;
                                                    }
                                                    let filteredStockLength = []
                                                    let filteredStockLengthVerzilvered = []
                                                    if (product.inCartOf) {
                                                        filteredStockLength = Object.entries(product.inCartOf).filter((t) => {
                                                            return t[1].selectedSize === e.size
                                                        })
                                                    }
                                                    if (product.verzilvered_by) {
                                                        filteredStockLengthVerzilvered = Object.entries(product.verzilvered_by).filter((t) => {
                                                            return t[1].selectedSize === e.size
                                                        })
                                                    }
                                                    if ((filteredStockLength.length + filteredStockLengthVerzilvered.length) < Number(e.qty)) {
                                                        stocksAvailableSizes.push(e)
                                                    }
                                                })
                                                if (stocksAvailableSizes.length === 0) {
                                                    return false;
                                                }
                                                availableProducts.push(product)
                                            } else {
                                                if (Number(product.qty) === 0) {
                                                    return false;
                                                }
                                                availableProducts.push(product)
                                            }
                                        }
                                    }
                                })

                                if (a.recurring && a.recurring.active) {
                                    if (a.recurring.recurringType === "daily") {
                                        let maximumWeeksToShow = Math.ceil(filtered.length / Number(a.recurring.recurringAmount))

                                        let onIndex = allDatesDaily.findIndex((t) => {
                                            return t === currentDayAt
                                        })

                                        if (onIndex > maximumWeeksToShow + 1) {
                                            console.log(availableProducts, '/see this index default')
                                            let maximumWeeksToShowAvailable = Math.ceil(availableProducts.length / Number(a.recurring.recurringAmount))

                                            const separateArrays = [];
                                            let allDatesDailyNew = allDatesDaily.slice()
                                            // allDatesDailyNew.shift()

                                            for (let i = 0; i < allDatesDailyNew.length; i += maximumWeeksToShowAvailable) {
                                                separateArrays.push(allDatesDailyNew.slice(i, i + maximumWeeksToShowAvailable));
                                            }

                                            let indexGot = 0;
                                            separateArrays.map((t) => {
                                                let indexGotNew = t.findIndex((g) => {
                                                    return Number(g) === Number(currentDayAt)
                                                })
                                                if (indexGotNew !== -1) {
                                                    indexGot = indexGotNew + 1
                                                }
                                            })

                                            indexToStartProducts = indexGot

                                            isRepeating = true
                                        }
                                        // console.log("is daily")
                                    } else {
                                        let maximumWeeksToShow = Math.ceil(filtered.length / Number(a.recurring.recurringAmount))

                                        let onIndex = allDatesWeekly.findIndex((t) => {
                                            return t === currentWeekAt
                                        })

                                        console.log(onIndex, '/check here main new')
                                        if (onIndex > maximumWeeksToShow) {
                                            let maximumWeeksToShowAvailable = Math.ceil(availableProducts.length / Number(a.recurring.recurringAmount))

                                            const separateArrays = [];
                                            let allDatesWeeklyNew = allDatesWeekly.slice()
                                            allDatesWeeklyNew.shift()

                                            for (let i = 0; i < allDatesWeeklyNew.length; i += maximumWeeksToShowAvailable) {
                                                separateArrays.push(allDatesWeeklyNew.slice(i, i + maximumWeeksToShowAvailable));
                                            }

                                            let indexGot = 0;
                                            separateArrays.map((t) => {
                                                let indexGotNew = t.findIndex((g) => {
                                                    return Number(g) === Number(currentWeekAt)
                                                })
                                                if (indexGotNew !== -1) {
                                                    indexGot = indexGotNew + 1
                                                }
                                            })

                                            indexToStartProducts = indexGot
                                            // indexToStartProducts = Math.floor(indexToStartProductsWeekly / maximumWeeksToShowAvailable)

                                            isRepeating = true
                                        }
                                    }
                                }

                                console.log(indexToStartProducts, '/see this index default')
                                console.log(isRepeating, '/see if it is repeating')

                                this.processProducts(filtered, maxProductsToShow, a.recurring, indexToStartProducts, "", isRepeating)
                            }
                            //     firebase.database().ref("bhodi_products").orderByChild("created_by").equalTo(this.props.currentMemberData.user_id).once("value", (data) => {
                            //         let a = data.val()
                            //         if (a) {
                            //             let allProducts = []
                            //             let objected = Object.entries(a)
                            //             objected.map((e) => {
                            //                 if (e[1].images) {
                            //                     if (e[1].variations_data) {
                            //                         let objected = Object.entries(e[1].variations_data)
                            //                         let filtered = objected.filter((t) => {
                            //                             return Number(t[1].qty) > 0
                            //                         })
                            //                         if (filtered.length > 0) {
                            //                             allProducts.push(e[1])
                            //                         }
                            //                     } else {
                            //                         if (Number(e.qty) > 0) {
                            //                             allProducts.push(e[1])
                            //                         }
                            //                     }
                            //                 }
                            //             })
                            //             this.processProducts(allProducts, maxProductsToShow, this.props.currentMemberData.recurring, indexToStartProducts)
                            //         }
                            //     })
                        }, 2000);
                    }
                });
        }

        let userid = localStorage.getItem("userid");
        this.setState({ currentUserId: userid })

        if (this.props.user) {
            if (this.props.user.length > 0) {
                let filtered = this.props.user.filter((j) => {
                    return j.user_id === userid;
                });
                if (filtered.length > 0) {
                    let a = filtered[0];
                    if (a.subscription) {
                        if (a.subscription.status === "active") {
                            this.setState({ currentUserCharityMember: true });
                        } else {
                            if (checkForSubscribedExclusives(a)) {
                                this.setState({ currentUserCharityMember: true });
                            }
                        }
                    } else {
                        if (checkForSubscribedExclusives(a)) {
                            this.setState({ currentUserCharityMember: true });
                        }
                    }
                    this.setState({ currentUserHere: a, isGuestUser: false });
                }
            }
        }
        firebase
            .database()
            .ref("users/" + userid)
            .on("value", (data) => {
                let a = data.val();
                if (a) {
                    if (a.subscription) {
                        if (a.subscription.status === "active") {
                            this.setState({ currentUserCharityMember: true });
                        } else {
                            if (checkForSubscribedExclusives(a)) {
                                this.setState({ currentUserCharityMember: true });
                            }
                        }
                    } else {
                        if (checkForSubscribedExclusives(a)) {
                            this.setState({ currentUserCharityMember: true });
                        }
                    }
                    this.setState({ currentUserHere: a, isGuestUser: false });
                }
            });

        // if (this.props.bhodi_products) {
        //     let filtered = this.props.bhodi_products.filter((g) => {
        //         return g.created_by === this.props.currentMemberData.user_id && g.images
        //     })
        //     this.processProducts(filtered, maxProductsToShow, this.props.currentMemberData.recurring, indexToStartProducts)
        //     // let sortedProducts = [...filtered].sort((a, b) => {
        //     //     return b.timestamp * 100 - a.timestamp * 100;
        //     // });
        //     // if (filtered.length > 0) {
        //     //     let availableStockProducts = []
        //     //     let availableIndexes = []
        //     //     sortedProducts.map((product, index) => {
        //     //         let currentProduct = Object.assign({}, product)
        //     //         if (product.active) {
        //     //             if (product.inCartOf) {
        //     //                 if (currentProduct.sizesActive) {
        //     //                     let stocksAvailableSizes = []
        //     //                     currentProduct.variations_data.map((e) => {
        //     //                         let filteredStockLength = Object.entries(product.inCartOf).filter((t) => {
        //     //                             return t[1].selectedSize === e.size
        //     //                         })
        //     //                         if (filteredStockLength.length < Number(e.qty)) {
        //     //                             stocksAvailableSizes.push(e)
        //     //                         }
        //     //                     })
        //     //                     if (stocksAvailableSizes.length === 0) {
        //     //                         console.log("no stock")
        //     //                     } else {
        //     //                         availableStockProducts.push(product)
        //     //                         availableIndexes.push(index)
        //     //                     }
        //     //                 } else {
        //     //                     let objected = Object.entries(product.inCartOf)
        //     //                     if (objected.length >= Number(currentProduct.qty)) {
        //     //                         console.log("no stock")
        //     //                     } else {
        //     //                         availableStockProducts.push(product)
        //     //                         availableIndexes.push(index)
        //     //                     }
        //     //                 }
        //     //             } else {
        //     //                 availableStockProducts.push(product)
        //     //                 availableIndexes.push(index)
        //     //             }
        //     //         }
        //     //     })
        //     //     if (availableStockProducts.length > 0) {
        //     //         let currentInd = sortedProducts.map(item => item.pushKey).indexOf(availableStockProducts[0].pushKey);
        //     //         this.setState({ currentProductCarousel: currentInd, noCarouselProducts: false })
        //     //     } else {
        //     //         this.setState({ noCarouselProducts: true })
        //     //     }
        //     //     let maxIndexesToShow = availableIndexes.splice(0, maxProductsToShow)

        //     //     let productsToRender = []
        //     //     let timersArray = []
        //     //     maxIndexesToShow.map((index) => {
        //     //         let currentProduct = sortedProducts[index]
        //     //         productsToRender.push(currentProduct)
        //     //         let obj = {
        //     //             showTimer: false,
        //     //         }
        //     //         timersArray.push(obj)
        //     //     })


        //     //     this.setState({ allProducts: sortedProducts, allProductsDuplicated: sortedProducts, allIndexes: maxIndexesToShow, productsToRender, timersArray })
        //     // } else {
        //     //     this.setState({ allProducts: [], allProductsDuplicated: [] })
        //     // }
        // } else {
        //     this.setState({ allProducts: [], allProductsDuplicated: [] })
        // }

        let lastCaught = ""
        dbRef.orderByChild("created_by").equalTo(this.props.currentMemberData.user_id).on("value", (data) => {
            let a = data.val()
            if (a) {
                let allProducts = []
                let objected = Object.entries(a)
                objected.map((e) => {
                    if (e[1].images) {
                        // if (e[1].variations_data) {
                        //     let objected = Object.entries(e[1].variations_data)
                        //     let filtered = objected.filter((t) => {
                        //         return Number(t[1].qty) > 0
                        //     })
                        //     if (filtered.length > 0) {
                        //         allProducts.push(e[1])
                        //     }
                        // } else {
                        //     if (Number(e[1].qty) > 0) {
                        //         allProducts.push(e[1])
                        //     }
                        // }
                        allProducts.push(e[1])
                    }
                })

                // if (lastCaught) {
                //     var startDate = lastCaught;
                //     var endDate = new Date();
                //     var seconds = (endDate.getTime() - startDate.getTime()) / 1000;

                //     setTimeout(() => {
                //         if (lastCaught.getTime() === endDate.getTime()) {
                //             console.log(allProducts, '/see all products')
                //             let bhodiProducts = this.props.bhodi_products
                //             if (bhodiProducts && bhodiProducts.length > 0) {
                //                 let arrToSet = []
                //                 bhodiProducts.map((e) => {
                //                     let alreadyAvailable = allProducts.filter((t) => {
                //                         return t.pushKey === e.pushKey
                //                     })
                //                     if (alreadyAvailable.length > 0) {
                //                         arrToSet.push(alreadyAvailable[0])
                //                     } else {
                //                         arrToSet.push(e)
                //                     }
                //                 })

                //                 this.props.updateBhodiProducts(arrToSet)
                //             }
                //             this.processProducts(allProducts, maxProductsToShow, this.props.currentMemberData.recurring, indexToStartProducts)
                //         }
                //     }, 2000);

                //     lastCaught = endDate

                //     if (seconds < 2) {
                //         return false;
                //     }
                // } else {
                //     lastCaught = new Date()
                // }
                console.log(allProducts, '/see all products')
                let bhodiProducts = this.props.bhodi_products
                if (bhodiProducts && bhodiProducts.length > 0) {
                    let arrToSet = []
                    bhodiProducts.map((e) => {
                        let alreadyAvailable = allProducts.filter((t) => {
                            return t.pushKey === e.pushKey
                        })
                        if (alreadyAvailable.length > 0) {
                            arrToSet.push(alreadyAvailable[0])
                        } else {
                            arrToSet.push(e)
                        }
                    })

                    this.props.updateBhodiProducts(arrToSet)
                }

                let isRepeating = false
                let availableProducts = []
                allProducts.map((product, index) => {
                    let currentProduct = Object.assign({}, product)
                    if (product.active) {
                        if (product.inCartOf || product.verzilvered_by) {
                            if (currentProduct.sizesActive) {
                                let stocksAvailableSizes = []
                                currentProduct.variations_data.map((e) => {
                                    let filteredStockLength = []
                                    let filteredStockLengthVerzilvered = []
                                    if (product.inCartOf) {
                                        filteredStockLength = Object.entries(product.inCartOf).filter((t) => {
                                            return t[1].selectedSize === e.size
                                        })
                                    }
                                    if (product.verzilvered_by) {
                                        filteredStockLengthVerzilvered = Object.entries(product.verzilvered_by).filter((t) => {
                                            return t[1].selectedSize === e.size
                                        })
                                    }
                                    if ((filteredStockLength.length + filteredStockLengthVerzilvered.length) < Number(e.qty)) {
                                        stocksAvailableSizes.push(e)
                                    }
                                })
                                if (stocksAvailableSizes.length === 0) {
                                    console.log("no stock")
                                } else {
                                    availableProducts.push(product)
                                }
                            } else {
                                let objectedCart = []
                                let objectedVerzilvered = []

                                if (product.inCartOf) {
                                    objectedCart = Object.entries(product.inCartOf)
                                }
                                if (product.verzilvered_by) {
                                    objectedVerzilvered = Object.entries(product.verzilvered_by)
                                }
                                if ((objectedCart.length + objectedVerzilvered.length) >= Number(currentProduct.qty)) {
                                    console.log("no stock")
                                } else {
                                    availableProducts.push(product)
                                }
                            }
                        } else {
                            if (product.sizesActive) {
                                let stocksAvailableSizes = []
                                product.variations_data.map((e) => {
                                    if (Number(e.qty) === 0) {
                                        return false;
                                    }
                                    let filteredStockLength = []
                                    let filteredStockLengthVerzilvered = []
                                    if (product.inCartOf) {
                                        filteredStockLength = Object.entries(product.inCartOf).filter((t) => {
                                            return t[1].selectedSize === e.size
                                        })
                                    }
                                    if (product.verzilvered_by) {
                                        filteredStockLengthVerzilvered = Object.entries(product.verzilvered_by).filter((t) => {
                                            return t[1].selectedSize === e.size
                                        })
                                    }
                                    if ((filteredStockLength.length + filteredStockLengthVerzilvered.length) < Number(e.qty)) {
                                        stocksAvailableSizes.push(e)
                                    }
                                })
                                if (stocksAvailableSizes.length === 0) {
                                    return false;
                                }
                                availableProducts.push(product)
                            } else {
                                if (Number(product.qty) === 0) {
                                    return false;
                                }
                                availableProducts.push(product)
                            }
                            // availableProducts.push(product)
                        }
                    }
                })

                if (this.props.currentMemberData.recurring && this.props.currentMemberData.recurring.active) {
                    if (this.props.currentMemberData.recurring.recurringType === "daily") {
                        // console.log("is daily")
                        let maximumWeeksToShow = Math.ceil(allProducts.length / Number(this.props.currentMemberData.recurring.recurringAmount))

                        let onIndex = allDatesDaily.findIndex((t) => {
                            return t === currentDayAt
                        })
                        if (onIndex > maximumWeeksToShow + 1) {
                            let maximumWeeksToShowAvailable = Math.ceil(availableProducts.length / Number(this.props.currentMemberData.recurring.recurringAmount))

                            const separateArrays = [];
                            let allDatesDailyNew = allDatesDaily.slice()
                            // allDatesDailyNew.shift()

                            for (let i = 0; i < allDatesDailyNew.length; i += maximumWeeksToShowAvailable) {
                                separateArrays.push(allDatesDailyNew.slice(i, i + maximumWeeksToShowAvailable));
                            }

                            let indexGot = 0;
                            separateArrays.map((t) => {
                                let indexGotNew = t.findIndex((g) => {
                                    return Number(g) === Number(currentDayAt)
                                })
                                if (indexGotNew !== -1) {
                                    indexGot = indexGotNew + 1
                                }
                            })

                            indexToStartProducts = indexGot

                            isRepeating = true
                        }
                    } else {
                        let maximumWeeksToShow = Math.ceil(allProducts.length / Number(this.props.currentMemberData.recurring.recurringAmount))
                        let onIndex = allDatesWeekly.findIndex((t) => {
                            return t === currentWeekAt
                        })
                        if (onIndex > maximumWeeksToShow) {
                            let maximumWeeksToShowAvailable = Math.ceil(availableProducts.length / Number(this.props.currentMemberData.recurring.recurringAmount))
                            // console.log(indexToStartProductsWeekly, '/check here main new it')
                            // console.log(indexToStartProductsWeekly - 1, '/check here main new it past weeks')
                            // let toDoBefore = Math.ceil((indexToStartProductsWeekly - 1) / maximumWeeksToShowAvailable)
                            // console.log(toDoBefore, '/check here main new index')
                            // console.log(maximumWeeksToShowAvailable, '/check here main new it')

                            const separateArrays = [];
                            let allDatesWeeklyNew = allDatesWeekly.slice()
                            allDatesWeeklyNew.shift()

                            for (let i = 0; i < allDatesWeeklyNew.length; i += maximumWeeksToShowAvailable) {
                                separateArrays.push(allDatesWeeklyNew.slice(i, i + maximumWeeksToShowAvailable));
                            }

                            let indexGot = 0;
                            separateArrays.map((t) => {
                                let indexGotNew = t.findIndex((g) => {
                                    return Number(g) === Number(currentWeekAt)
                                })
                                if (indexGotNew !== -1) {
                                    indexGot = indexGotNew + 1
                                }
                            })

                            indexToStartProducts = indexGot

                            isRepeating = true
                        }
                    }
                }
                // console.log(allDatesWeekly, '/check here main')
                // console.log(this.props.currentMemberData.recurring, '/check here main')
                this.processProducts(allProducts, maxProductsToShow, this.props.currentMemberData.recurring, indexToStartProducts, "", isRepeating)

                // let bhodiProducts = this.props.bhodi_products
                // if (bhodiProducts.length > 0) {
                //     let arrToSet = []
                //     bhodiProducts.map((e) => {
                //         let alreadyAvailable = allProducts.filter((t) => {
                //             return t.pushKey === e.pushKey
                //         })
                //         if (alreadyAvailable.length > 0) {
                //             arrToSet.push(alreadyAvailable[0])
                //         } else {
                //             arrToSet.push(e)
                //         }
                //     })

                //     this.props.updateBhodiProducts(arrToSet)
                // }
            } else {
                this.setState({ noCarouselProducts: true })
            }
            // this.setState({ loading: false })
        })

        if (this.props.globalsectors) {
            if (this.props.globalsectors.length > 0) {
                let arr = this.props.globalsectors;
                this.setState({ allSectors: arr });
                if (arr.length > 0) {
                    if (this.state.selectedSector) {
                        this.setState({ sectors: arr });
                    } else {
                        this.setState({ sectors: arr, currentSector: arr[0] });
                    }
                    firebase
                        .database()
                        .ref("bhodi_categories_products")
                        .on("value", (data) => {
                            let a = data.val();
                            let arrSec = [];
                            if (a) {
                                let objected = Object.entries(a);
                                objected.map((g) => {
                                    if (g[0] === arr[0].sector_id) {
                                        arrSec = g[1];
                                    }
                                });
                                let categories = [];
                                arrSec.map((h) => {
                                    if (h.children) {
                                        let obj = {
                                            title: h.title,
                                            category_id: h.category_id,
                                            sub_category: h.children,
                                        };
                                        categories.push(obj);
                                    } else {
                                        let obj = {
                                            title: h.title,
                                            category_id: h.category_id,
                                        };
                                        categories.push(obj);
                                    }
                                });
                                if (categories.length > 0) {
                                    this.setState({
                                        completeCategories: objected,
                                        treeData: arrSec,
                                    });
                                }
                            } else {
                                this.setState({ completeCategories: [] });
                            }
                        });
                }
            }
        }
    }

    componentWillUnmount() {
        dbRef.orderByChild("created_by").equalTo(this.props.currentMemberData.user_id).off()
    }

    onLeave(origin, destination, direction) {
        console.log("on leave")
    }

    afterLoad(origin, destination, direction) {
        this.setState({ onCurrentPage: destination.index });
    }

    processProducts(filtered, maxProductsToShow, recurring, indexToStartProducts, avoidAvailability, isRepeating) {
        let mustProcess = true
        if (!this.props.currentMemberData.recurring) {
            if (this.state.keepShowing) {
                mustProcess = false
            }
        } else {
            if (!this.props.currentMemberData.recurring.active) {
                if (this.state.keepShowing) {
                    mustProcess = false
                }
            }
        }

        let currentIndex = indexToStartProducts - 1
        let sortedProducts = [...filtered].sort((a, b) => {
            return a.sort_time * 100 - b.sort_time * 100;
        });
        let sortedProductsDuplicated = [...filtered].sort((a, b) => {
            return b.sort_time * 100 - a.sort_time * 100;
        });

        console.log(sortedProducts, '/see sorted products')
        console.log(isRepeating, '/see if it is repeating')

        if (filtered.length > 0) {
            let availableStockProducts = []
            let availableIndexes = []
            // if (!mustProcess && this.state.productsToRender.length > 0) {
            //     this.state.productsToRender.map((product) => {
            //         let findIndex = sortedProducts.findIndex((e) => {
            //             return e.pushKey === product.pushKey
            //         })
            //         let currentProductOptionsAdd = sortedProducts.filter((e) => {
            //             return e.pushKey === product.pushKey
            //         })
            //         if (currentProductOptionsAdd.length > 0) {
            //             availableIndexes.push(findIndex)
            //             availableStockProducts.push(currentProductOptionsAdd[0])
            //         }
            //     })
            // } else
            if (recurring && recurring.active) {
                sortedProducts.map((product, index) => {
                    if (isRepeating) {
                        let currentProduct = Object.assign({}, product)
                        if (product.active) {
                            if (product.inCartOf || product.verzilvered_by) {
                                if (currentProduct.sizesActive) {
                                    let stocksAvailableSizes = []
                                    currentProduct.variations_data.map((e) => {
                                        let filteredStockLength = []
                                        let filteredStockLengthVerzilvered = []
                                        if (product.inCartOf) {
                                            filteredStockLength = Object.entries(product.inCartOf).filter((t) => {
                                                return t[1].selectedSize === e.size
                                            })
                                        }
                                        if (product.verzilvered_by) {
                                            filteredStockLengthVerzilvered = Object.entries(product.verzilvered_by).filter((t) => {
                                                return t[1].selectedSize === e.size
                                            })
                                        }
                                        if ((filteredStockLength.length + filteredStockLengthVerzilvered.length) < Number(e.qty)) {
                                            stocksAvailableSizes.push(e)
                                        }
                                    })
                                    if (stocksAvailableSizes.length === 0) {
                                        console.log("no stock")
                                    } else {
                                        availableStockProducts.push(product)
                                        availableIndexes.push(index)
                                    }
                                } else {
                                    let objectedCart = []
                                    let objectedVerzilvered = []

                                    if (product.inCartOf) {
                                        objectedCart = Object.entries(product.inCartOf)
                                    }
                                    if (product.verzilvered_by) {
                                        objectedVerzilvered = Object.entries(product.verzilvered_by)
                                    }
                                    if ((objectedCart.length + objectedVerzilvered.length) >= Number(currentProduct.qty)) {
                                        console.log("no stock")
                                    } else {
                                        availableStockProducts.push(product)
                                        availableIndexes.push(index)
                                    }
                                }
                            } else {
                                if (currentProduct.sizesActive) {
                                    let stocksAvailableSizes = []
                                    currentProduct.variations_data.map((e) => {
                                        if (Number(e.qty) === 0) {
                                            return false;
                                        }
                                        let filteredStockLength = []
                                        let filteredStockLengthVerzilvered = []
                                        if (product.inCartOf) {
                                            filteredStockLength = Object.entries(product.inCartOf).filter((t) => {
                                                return t[1].selectedSize === e.size
                                            })
                                        }
                                        if (product.verzilvered_by) {
                                            filteredStockLengthVerzilvered = Object.entries(product.verzilvered_by).filter((t) => {
                                                return t[1].selectedSize === e.size
                                            })
                                        }
                                        if ((filteredStockLength.length + filteredStockLengthVerzilvered.length) < Number(e.qty)) {
                                            stocksAvailableSizes.push(e)
                                        }
                                    })
                                    if (stocksAvailableSizes.length === 0) {
                                        return false;
                                    }
                                    availableStockProducts.push(product)
                                    availableIndexes.push(index)
                                } else {
                                    if (Number(currentProduct.qty) === 0) {
                                        return false;
                                    }
                                    availableStockProducts.push(product)
                                    availableIndexes.push(index)
                                }
                            }
                        }
                    } else {
                        availableStockProducts.push(product)
                        availableIndexes.push(index)
                    }
                })

                let productsAvailableForRecurr = []
                sortedProducts.map((product, index) => {
                    let currentProduct = Object.assign({}, product)
                    if (product.active) {
                        if (product.inCartOf || product.verzilvered_by) {
                            if (currentProduct.sizesActive) {
                                let stocksAvailableSizes = []
                                currentProduct.variations_data.map((e) => {
                                    // let filteredStockLength = Object.entries(product.inCartOf).filter((t) => {
                                    //     return t[1].selectedSize === e.size
                                    // })
                                    let filteredStockLength = []
                                    let filteredStockLengthVerzilvered = []
                                    if (product.inCartOf) {
                                        filteredStockLength = Object.entries(product.inCartOf).filter((t) => {
                                            return t[1].selectedSize === e.size
                                        })
                                    }
                                    if (product.verzilvered_by) {
                                        filteredStockLengthVerzilvered = Object.entries(product.verzilvered_by).filter((t) => {
                                            return t[1].selectedSize === e.size
                                        })
                                    }
                                    if ((filteredStockLength.length + filteredStockLengthVerzilvered.length) < Number(e.qty)) {
                                        stocksAvailableSizes.push(e)
                                    }
                                    // if (filteredStockLength.length < Number(e.qty)) {
                                    //     stocksAvailableSizes.push(e)
                                    // }
                                })
                                if (stocksAvailableSizes.length === 0) {
                                    console.log("no stock")
                                } else {
                                    productsAvailableForRecurr.push(product)
                                }
                            } else {
                                // let objected = Object.entries(product.inCartOf)
                                // if (objected.length >= Number(currentProduct.qty)) {
                                //     console.log("no stock")
                                // }
                                let objectedCart = []
                                let objectedVerzilvered = []

                                if (product.inCartOf) {
                                    objectedCart = Object.entries(product.inCartOf)
                                }
                                if (product.verzilvered_by) {
                                    objectedVerzilvered = Object.entries(product.verzilvered_by)
                                }
                                if ((objectedCart.length + objectedVerzilvered.length) >= Number(currentProduct.qty)) {
                                    console.log("no stock")
                                } else {
                                    productsAvailableForRecurr.push(product)
                                }
                            }
                        } else {
                            productsAvailableForRecurr.push(product)
                        }
                    }
                })
                console.log(productsAvailableForRecurr, '/check here main new')
                this.setState({ productsAvailableForRecurr })

            } else {
                sortedProducts.map((product, index) => {
                    let currentProduct = Object.assign({}, product)
                    if (product.variations_data) {
                        let objected = Object.entries(product.variations_data)
                        let filtered = objected.filter((t) => {
                            return Number(t[1].qty) > 0
                        })
                        if (filtered.length === 0) {
                            return false;
                        }
                    } else {
                        if (Number(product.qty) === 0) {
                            return false;
                        }
                    }
                    if (product.active) {
                        if (product.inCartOf || product.verzilvered_by) {
                            if (currentProduct.sizesActive) {
                                let stocksAvailableSizes = []
                                currentProduct.variations_data.map((e) => {
                                    let filteredStockLength = []
                                    let filteredStockLengthVerzilvered = []
                                    if (product.inCartOf) {
                                        filteredStockLength = Object.entries(product.inCartOf).filter((t) => {
                                            return t[1].selectedSize === e.size
                                        })
                                    }
                                    if (product.verzilvered_by) {
                                        filteredStockLengthVerzilvered = Object.entries(product.verzilvered_by).filter((t) => {
                                            return t[1].selectedSize === e.size
                                        })
                                    }
                                    if ((filteredStockLength.length + filteredStockLengthVerzilvered.length) < Number(e.qty)) {
                                        stocksAvailableSizes.push(e)
                                    }
                                })
                                if (stocksAvailableSizes.length === 0) {
                                    console.log("no stock")
                                } else {
                                    availableStockProducts.push(product)
                                    availableIndexes.push(index)
                                }
                            } else {
                                let objectedCart = []
                                let objectedVerzilvered = []

                                if (product.inCartOf) {
                                    objectedCart = Object.entries(product.inCartOf)
                                }
                                if (product.verzilvered_by) {
                                    objectedVerzilvered = Object.entries(product.verzilvered_by)
                                }
                                if ((objectedCart.length + objectedVerzilvered.length) >= Number(currentProduct.qty)) {
                                    console.log("no stock")
                                } else {
                                    availableStockProducts.push(product)
                                    availableIndexes.push(index)
                                }
                            }
                        } else {
                            availableStockProducts.push(product)
                            availableIndexes.push(index)
                        }
                    }
                })
            }
            console.log(availableStockProducts, '/see here main new swap stuff')
            console.log(availableIndexes, '/see here main new swap stuff')

            if (availableStockProducts.length === 3) {
                this.setState({ keepShowing: true })
            } else {
                this.setState({ keepShowing: false })
            }

            // if (availableStockProducts.length > 0) {
            //     let currentInd = sortedProducts.map(item => item.pushKey).indexOf(availableStockProducts[0].pushKey);
            //     this.setState({ currentProductCarousel: currentInd, noCarouselProducts: false })
            // } else {

            if (availableStockProducts.length === 0) {
                //     let currentInd = sortedProducts.map(item => item.pushKey).indexOf(availableStockProducts[0].pushKey);
                //     this.setState({ currentProductCarousel: currentInd, noCarouselProducts: false })
                // } else {
                this.setState({ noCarouselProducts: true })
            } else {
                this.setState({ noCarouselProducts: false })
            }
            let maxIndexesToShow = []
            let maxIndexesToShowNext = []

            if (this.props.currentMemberData.recurring && this.props.currentMemberData.recurring.active) {
                maxIndexesToShow = [...availableIndexes].splice(currentIndex * maxProductsToShow, maxProductsToShow)

                maxIndexesToShowNext = availableIndexes.splice(indexToStartProducts * maxProductsToShow, maxProductsToShow)
            } else {
                maxIndexesToShow = [...availableIndexes].splice(0, maxProductsToShow).reverse()

                maxIndexesToShowNext = availableIndexes.splice(0, maxProductsToShow).reverse()
            }

            console.log(maxIndexesToShow, "/see products render")
            console.log(maxIndexesToShow, '/see here main new swap stuff')

            let productsToRender = []
            let productsAllRecurring = []
            let timersArray = []
            let productsToSwap = []
            let productsTooked = []
            console.log(availableStockProducts, '/see available products')
            console.log(maxIndexesToShow, '/check here main new')
            if (this.props.currentMemberData.recurring && this.props.currentMemberData.recurring.active) {

                let allIndexesRecurShowing = []
                if (this.state.productsToRender.length === 3 && this.props.currentMemberData.recurring.recurringAmount == 3) {
                    // if ((this.state.productsToRender.length === 3 && this.props.currentMemberData.recurring.recurringAmount == 3) || (this.state.productsToRender.length === 2 && this.props.currentMemberData.recurring.recurringAmount == 3)) {
                    this.state.productsToRender.map((product) => {
                        let findIndex = sortedProducts.findIndex((e) => {
                            return e.pushKey === product.pushKey
                        })
                        let currentProductOptionsAdd = sortedProducts.filter((e) => {
                            return e.pushKey === product.pushKey
                        })
                        if (currentProductOptionsAdd.length > 0) {
                            productsToRender.push(currentProductOptionsAdd[0])
                            allIndexesRecurShowing.push(findIndex)
                        }
                    })
                } else {
                    // to check recurring end product to make sort time accordingly
                    maxIndexesToShow.map((index, ind) => {
                        let currentProduct = Object.assign(sortedProducts[index], {})
                        let product = Object.assign(sortedProducts[index], {})
                        if (product.active) {
                            // if (product.inCartOf) {
                            //     if (currentProduct.sizesActive) {
                            //         let stocksAvailableSizes = []
                            //         currentProduct.variations_data.map((e) => {
                            //             let filteredStockLength = Object.entries(product.inCartOf).filter((t) => {
                            //                 return t[1].selectedSize === e.size
                            //             })
                            //             if (filteredStockLength.length < Number(e.qty)) {
                            //                 stocksAvailableSizes.push(e)
                            //             }
                            //         })
                            //         if (stocksAvailableSizes.length === 0) {
                            //             console.log("no stock")
                            //         } else {
                            //             productsAllRecurring.push(currentProduct)
                            //         }
                            //     } else {
                            //         let objected = Object.entries(product.inCartOf)
                            //         if (objected.length >= Number(currentProduct.qty)) {
                            //             console.log("no stock")
                            //         } else {
                            //             productsAllRecurring.push(currentProduct)
                            //         }
                            //     }
                            // } 
                            if (product.inCartOf || product.verzilvered_by) {
                                if (currentProduct.sizesActive) {
                                    let stocksAvailableSizes = []
                                    currentProduct.variations_data.map((e) => {
                                        let filteredStockLength = []
                                        let filteredStockLengthVerzilvered = []
                                        if (product.inCartOf) {
                                            filteredStockLength = Object.entries(product.inCartOf).filter((t) => {
                                                return t[1].selectedSize === e.size
                                            })
                                        }
                                        if (product.verzilvered_by) {
                                            filteredStockLengthVerzilvered = Object.entries(product.verzilvered_by).filter((t) => {
                                                return t[1].selectedSize === e.size
                                            })
                                        }
                                        if ((filteredStockLength.length + filteredStockLengthVerzilvered.length) < Number(e.qty)) {
                                            stocksAvailableSizes.push(e)
                                        }
                                    })
                                    if (stocksAvailableSizes.length === 0) {
                                        console.log("no stock")
                                    } else {
                                        productsAllRecurring.push(currentProduct)
                                    }
                                } else {
                                    let objectedCart = []
                                    let objectedVerzilvered = []

                                    if (product.inCartOf) {
                                        objectedCart = Object.entries(product.inCartOf)
                                    }
                                    if (product.verzilvered_by) {
                                        objectedVerzilvered = Object.entries(product.verzilvered_by)
                                    }
                                    if ((objectedCart.length + objectedVerzilvered.length) >= Number(currentProduct.qty)) {
                                        console.log("no stock")
                                    } else {
                                        productsAllRecurring.push(currentProduct)
                                    }
                                }
                            } else {
                                productsAllRecurring.push(currentProduct)
                            }
                        }
                    })
                    // to check recurring end product to make sort time accordingly
                    maxIndexesToShow.map((index, ind) => {
                        let currentProduct = Object.assign(sortedProducts[index], {})
                        let product = Object.assign(sortedProducts[index], {})
                        if (productsToRender.length < this.props.global_values.maxProductsToShow) {
                            if (product.active) {
                                // if (product.inCartOf) {
                                //     if (currentProduct.sizesActive) {
                                //         let stocksAvailableSizes = []
                                //         currentProduct.variations_data.map((e) => {
                                //             let filteredStockLength = Object.entries(product.inCartOf).filter((t) => {
                                //                 return t[1].selectedSize === e.size
                                //             })
                                //             if (filteredStockLength.length < Number(e.qty)) {
                                //                 stocksAvailableSizes.push(e)
                                //             }
                                //         })
                                //         if (stocksAvailableSizes.length === 0) {
                                //             console.log("no stock")
                                //         } else {
                                //             productsToRender.push(currentProduct)
                                //             allIndexesRecurShowing.push(index)
                                //         }
                                //     } else {
                                //         let objected = Object.entries(product.inCartOf)
                                //         if (objected.length >= Number(currentProduct.qty)) {
                                //             console.log("no stock")
                                //         } else {
                                //             productsToRender.push(currentProduct)
                                //             allIndexesRecurShowing.push(index)
                                //         }
                                //     }
                                // }
                                if (product.inCartOf || product.verzilvered_by) {
                                    if (currentProduct.sizesActive) {
                                        let stocksAvailableSizes = []
                                        currentProduct.variations_data.map((e) => {
                                            let filteredStockLength = []
                                            let filteredStockLengthVerzilvered = []
                                            if (product.inCartOf) {
                                                filteredStockLength = Object.entries(product.inCartOf).filter((t) => {
                                                    return t[1].selectedSize === e.size
                                                })
                                            }
                                            if (product.verzilvered_by) {
                                                filteredStockLengthVerzilvered = Object.entries(product.verzilvered_by).filter((t) => {
                                                    return t[1].selectedSize === e.size
                                                })
                                            }
                                            if ((filteredStockLength.length + filteredStockLengthVerzilvered.length) < Number(e.qty)) {
                                                stocksAvailableSizes.push(e)
                                            }
                                        })
                                        if (stocksAvailableSizes.length === 0) {
                                            console.log("no stock")
                                        } else {
                                            productsToRender.push(currentProduct)
                                            allIndexesRecurShowing.push(index)
                                        }
                                    } else {
                                        let objectedCart = []
                                        let objectedVerzilvered = []

                                        if (product.inCartOf) {
                                            objectedCart = Object.entries(product.inCartOf)
                                        }
                                        if (product.verzilvered_by) {
                                            objectedVerzilvered = Object.entries(product.verzilvered_by)
                                        }
                                        if ((objectedCart.length + objectedVerzilvered.length) >= Number(currentProduct.qty)) {
                                            console.log("no stock")
                                        } else {
                                            productsToRender.push(currentProduct)
                                            allIndexesRecurShowing.push(index)
                                        }
                                    }
                                } else {
                                    productsToRender.push(currentProduct)
                                    allIndexesRecurShowing.push(index)
                                }
                            }
                        }
                    })
                    maxIndexesToShow = allIndexesRecurShowing.reverse()
                    productsToRender = productsToRender.reverse()
                }

                allIndexesRecurShowing.map((index, ind) => {
                    let allIndexesTest = [...this.state.allIndexes]
                    if (allIndexesTest.length > 0) {
                        let obj = {
                            showTimer: false,
                        }
                        if (allIndexesTest[ind] !== index && !productsToRender[ind].availableOnTime && !allIndexesTest.includes(index) && !avoidAvailability && !this.state.showBannerAnnouncement && !this.state.showBannerMaintenance && !this.state.showShopOffline && !this.state.showRecurringBanner) {
                            // let filtered = this.state.productsSwapped.filter((t) => {
                            //     return t.pushKey === productsToRender[ind].pushKey
                            // })
                            // if (filtered.length === 0) {
                            // productsToRender[ind].availableOnTime = new Date().getTime()
                            // obj.showTimer = true
                            let filtered = this.state.productsSwapped.filter((t) => {
                                return t.pushKey === productsToRender[ind].pushKey
                            })
                            if (filtered.length === 0) {
                                productsToRender[ind].availableOnTime = new Date().getTime()
                                obj.showTimer = true

                                productsTooked.push(productsToRender[ind])
                            }
                            // }
                        }
                        // console.log(obj, '/recurring')
                        timersArray.push(obj)
                    } else {
                        let obj = {
                            showTimer: false,
                        }
                        // console.log(obj, '/recurring')
                        timersArray.push(obj)
                    }
                })
            } else {
                maxIndexesToShow.map((index, ind) => {
                    let currentProduct = Object.assign(sortedProducts[index], {})
                    productsToRender.push(currentProduct)
                    let allIndexesTest = [...this.state.allIndexes]
                    if (allIndexesTest.length > 0) {
                        let obj = {
                            showTimer: false,
                        }
                        if (allIndexesTest[ind] !== index && !productsToRender[ind].availableOnTime && !allIndexesTest.includes(index) && !avoidAvailability && !this.state.showBannerAnnouncement && !this.state.showBannerMaintenance && !this.state.showShopOffline && !this.state.showRecurringBanner) {
                            // productsToSwap.push(sortedProducts[allIndexesTest[ind]])
                            // productsToRender[ind].availableOnTime = new Date().getTime()
                            // obj.showTimer = true
                            let filtered = this.state.productsSwapped.filter((t) => {
                                return t.pushKey === productsToRender[ind].pushKey
                            })

                            if (filtered.length === 0) {
                                productsToRender[ind].availableOnTime = new Date().getTime()
                                obj.showTimer = true

                                productsTooked.push(productsToRender[ind])
                            }
                        }
                        timersArray.push(obj)
                    } else {
                        let obj = {
                            showTimer: false,
                        }
                        timersArray.push(obj)
                    }
                })
            }

            let productsToRenderNext = []
            maxIndexesToShowNext.map((index, ind) => {
                let currentProduct = Object.assign(sortedProducts[index], {})
                productsToRenderNext.push(currentProduct)
            })

            // check for recurring
            if (this.props.currentMemberData.recurring && this.props.currentMemberData.recurring.active) {
                let checkForRecurring = []
                let checkForRecurringNext = []
                productsToRender.map((product, index) => {
                    let currentProduct = Object.assign({}, product)
                    if (product.active) {
                        if (product.inCartOf || product.verzilvered_by) {
                            if (currentProduct.sizesActive) {
                                let stocksAvailableSizes = []
                                currentProduct.variations_data.map((e) => {
                                    let filteredStockLength = []
                                    let filteredStockLengthVerzilvered = []
                                    if (product.inCartOf) {
                                        filteredStockLength = Object.entries(product.inCartOf).filter((t) => {
                                            return t[1].selectedSize === e.size
                                        })
                                    }
                                    if (product.verzilvered_by) {
                                        filteredStockLengthVerzilvered = Object.entries(product.verzilvered_by).filter((t) => {
                                            return t[1].selectedSize === e.size
                                        })
                                    }
                                    if ((filteredStockLength.length + filteredStockLengthVerzilvered.length) < Number(e.qty)) {
                                        stocksAvailableSizes.push(e)
                                    }
                                })
                                if (stocksAvailableSizes.length === 0) {
                                    console.log("no stock")
                                } else {
                                    checkForRecurring.push(product)
                                }
                            } else {
                                let objectedCart = []
                                let objectedVerzilvered = []

                                if (product.inCartOf) {
                                    objectedCart = Object.entries(product.inCartOf)
                                }
                                if (product.verzilvered_by) {
                                    objectedVerzilvered = Object.entries(product.verzilvered_by)
                                }
                                if ((objectedCart.length + objectedVerzilvered.length) >= Number(currentProduct.qty)) {
                                    console.log("no stock")
                                } else {
                                    checkForRecurring.push(product)
                                }
                            }
                        } else {
                            checkForRecurring.push(product)
                        }
                    }
                })
                productsToRenderNext.map((product, index) => {
                    let currentProduct = Object.assign({}, product)
                    if (product.active) {
                        if (product.inCartOf || product.verzilvered_by) {
                            if (currentProduct.sizesActive) {
                                let stocksAvailableSizes = []
                                currentProduct.variations_data.map((e) => {
                                    let filteredStockLength = []
                                    let filteredStockLengthVerzilvered = []
                                    if (product.inCartOf) {
                                        filteredStockLength = Object.entries(product.inCartOf).filter((t) => {
                                            return t[1].selectedSize === e.size
                                        })
                                    }
                                    if (product.verzilvered_by) {
                                        filteredStockLengthVerzilvered = Object.entries(product.verzilvered_by).filter((t) => {
                                            return t[1].selectedSize === e.size
                                        })
                                    }
                                    if ((filteredStockLength.length + filteredStockLengthVerzilvered.length) < Number(e.qty)) {
                                        stocksAvailableSizes.push(e)
                                    }
                                })
                                if (stocksAvailableSizes.length === 0) {
                                    console.log("no stock")
                                } else {
                                    checkForRecurringNext.push(product)
                                }
                            } else {
                                let objectedCart = []
                                let objectedVerzilvered = []

                                if (product.inCartOf) {
                                    objectedCart = Object.entries(product.inCartOf)
                                }
                                if (product.verzilvered_by) {
                                    objectedVerzilvered = Object.entries(product.verzilvered_by)
                                }
                                if ((objectedCart.length + objectedVerzilvered.length) >= Number(currentProduct.qty)) {
                                    console.log("no stock")
                                } else {
                                    checkForRecurringNext.push(product)
                                }
                            }
                        } else {
                            checkForRecurringNext.push(product)
                        }
                    }
                })

                console.log(checkForRecurring, '/check here main new')
                console.log(checkForRecurringNext, '/check here main new')
                console.log(isRepeating, '/is repeating')
                if (checkForRecurring.length === 0 || currentIndex === -1 && !isRepeating) {
                    if (checkForRecurringNext.length > 0) {
                        this.setState({ noCarouselProducts: false, showRecurringBanner: true })
                    } else {
                        this.setState({ noCarouselProducts: true, showRecurringBanner: false })
                    }
                } else {
                    this.setState({ showRecurringBanner: false })
                }
                //     // check for recurring
            }

            if (productsToRender.length > 0 && this.state.allIndexes.length > 0) {
                this.state.productsToRender.map((e) => {
                    let filtered = productsToRender.filter((t) => {
                        return t.pushKey === e.pushKey
                    })
                    if (filtered.length === 0) {
                        let checkIndex = sortedProducts.findIndex((t) => {
                            return t.pushKey === e.pushKey
                        })
                        if (this.state.allIndexes.includes(checkIndex)) {
                            productsToSwap.push(e)
                        }
                    }
                })
            }

            // see for animation
            let filteredAvailable = productsToRender.filter((g) => {
                return g.availableOnTime
            })
            if (this.state.productsToRender.length > 0 && productsToRender.length > 0 && filteredAvailable.length > 0 && window.innerWidth > 1008) {
                let filteredAvailableOld = this.state.productsToRender.filter((e) => {
                    return e.availableOnTime
                })
                if (filteredAvailableOld.length < filteredAvailable.length) {
                    this.setState({ mustAnimate: true })

                    setTimeout(() => {
                        this.setState({ mustAnimate: false })
                    }, 1000);
                }
            }
            // see for animation

            let productsSwapped = productsToSwap.concat(productsToRender).concat(productsTooked)
            // let productsSwapped = this.state.productsSwapped.concat(productsToSwap).concat(productsToRender)

            console.log(productsToRender, '/see products render')

            this.setState({ allProducts: sortedProducts, allProductsDuplicated: sortedProducts, productsSwapped, allIndexes: maxIndexesToShow, productsToRender, indexToStartProducts, loading: false })

            if (!avoidAvailability && !this.state.showBannerAnnouncement && !this.state.showBannerMaintenance && !this.state.showShopOffline && !this.state.showRecurringBanner) {
                setTimeout(() => {
                    let filteredTimer = timersArray.filter((g) => {
                        return g.showTimer
                    })
                    if (filteredTimer.length > 0) {
                        timersArray.map((e, i) => {
                            if (e.showTimer) {
                                firebase.database().ref(`bhodi_products/${productsToRender[i].pushKey}/availableOnTime`).set(new Date().getTime())
                            }
                        })

                        // to reset timer stuff to avoid adding of timer again
                        timersArray = []
                    }

                    if (this.props.currentMemberData.recurring && this.props.currentMemberData.recurring.active) {
                        if (productsToSwap.length > 0 && productsAllRecurring.length > 0) {
                            let currentLastSort = productsAllRecurring[productsAllRecurring.length - 1].sort_time
                            productsToSwap.map((e) => {
                                currentLastSort = currentLastSort + 20
                                firebase.database().ref(`bhodi_products/${e.pushKey}/sort_time`).set(currentLastSort)
                            })
                        }
                    } else {
                        console.log(productsToSwap, '/see products swapping')
                        if (productsToSwap.length > 0 && productsToRender.length > 0) {
                            let currentLastSort = sortedProductsDuplicated[0].sort_time
                            productsToSwap.map((e) => {
                                currentLastSort = currentLastSort + 20
                                firebase.database().ref(`bhodi_products/${e.pushKey}/sort_time`).set(currentLastSort)
                            })
                        }
                    }
                }, 1000);
            } else {
                setTimeout(() => {
                    let filteredTimer = timersArray.filter((g) => {
                        return g.showTimer
                    })
                    if (filteredTimer.length > 0) {
                        timersArray = []
                    }
                }, 1000);
            }
        } else {
            let userid = localStorage.getItem("userid");
            if (this.props.currentMemberData.user_id !== userid) {
                this.setState({ noCarouselProducts: true })
            }
            this.setState({ allProducts: [], allProductsDuplicated: [], allIndexes: [], productsToRender: [], timersArray: [], loading: false })
        }
    }

    getCreatorImage(user_id) {
        if (user_id) {
            let filtered = this.props.user.filter((g) => {
                return g.user_id === user_id
            })
            if (filtered.length > 0) {
                if (filtered[0].profile_image) {
                    return filtered[0].profile_image;
                } else {
                    return "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9";
                }
            }
        }
    }

    getCreatorName(user_id) {
        if (user_id) {
            let filtered = this.props.user.filter((g) => {
                return g.user_id === user_id
            })
            if (filtered.length > 0) {
                if (filtered[0].type === "user") {
                    return filtered[0].first_name
                } else {
                    return filtered[0].company_name
                }
            }
        }
    }

    // componentDidUpdate(prevProps) {
    //     if (prevProps.bhodi_products !== this.props.bhodi_products) {
    //         if (this.props.bhodi_products) {
    //             let filtered = this.props.bhodi_products.filter((g) => {
    //                 return g.created_by === this.props.currentMemberData.user_id && g.images
    //             })
    //             let sortedProducts = [...filtered].sort((a, b) => {
    //                 return b.timestamp * 100 - a.timestamp * 100;
    //             });
    //             if (filtered.length > 0) {
    //                 let availableStockProducts = []
    //                 let availableIndexes = []
    //                 sortedProducts.map((product, index) => {
    //                     let currentProduct = Object.assign({}, product)
    //                     if (product.active) {
    //                         if (product.inCartOf) {
    //                             if (currentProduct.sizesActive) {
    //                                 let stocksAvailableSizes = []
    //                                 currentProduct.variations_data.map((e) => {
    //                                     let filteredStockLength = Object.entries(product.inCartOf).filter((t) => {
    //                                         return t[1].selectedSize === e.size
    //                                     })
    //                                     if (filteredStockLength.length < Number(e.qty)) {
    //                                         stocksAvailableSizes.push(e)
    //                                         availableIndexes.push(index)
    //                                     }
    //                                 })
    //                                 if (stocksAvailableSizes.length === 0) {
    //                                     console.log("no stock")
    //                                 } else {
    //                                     availableStockProducts.push(product)
    //                                 }
    //                             } else {
    //                                 let objected = Object.entries(product.inCartOf)
    //                                 if (objected.length >= Number(currentProduct.qty)) {
    //                                     console.log("no stock")
    //                                 } else {
    //                                     availableStockProducts.push(product)
    //                                     availableIndexes.push(index)
    //                                 }
    //                             }
    //                         } else {
    //                             availableStockProducts.push(product)
    //                             availableIndexes.push(index)
    //                         }
    //                     }
    //                 })
    //                 if (availableStockProducts.length > 0) {
    //                     let currentInd = sortedProducts.map(item => item.pushKey).indexOf(availableStockProducts[0].pushKey);
    //                     if (currentInd !== this.state.currentProductCarousel) {
    //                         this.setState({ animateDetailedPage: false })
    //                         setTimeout(() => {
    //                             this.setState({ animateDetailedPage: true })
    //                         }, 500);
    //                     }
    //                     this.setState({ currentProductCarousel: currentInd, noCarouselProducts: false })
    //                 } else {
    //                     this.setState({ noCarouselProducts: true })
    //                 }

    //                 let maxIndexesToShow = availableIndexes.splice(0, this.state.maxProductsToShow)

    //                 let productsToRender = []
    //                 let timersArray = []
    //                 maxIndexesToShow.map((index) => {
    //                     let currentProduct = sortedProducts[index]
    //                     productsToRender.push(currentProduct)
    //                     let obj = {
    //                         showTimer: false,
    //                     }
    //                     timersArray.push(obj)
    //                 })

    //                 this.setState({ allProducts: sortedProducts, allProductsDuplicated: sortedProducts, allIndexes: maxIndexesToShow, productsToRender, timersArray })
    //                 // this.setState({ allProducts: sortedProducts, allProductsDuplicated: sortedProducts })
    //             } else {
    //                 this.setState({ allProducts: [], allProductsDuplicated: [] })
    //             }
    //         } else {
    //             this.setState({ allProducts: [], allProductsDuplicated: [] })
    //         }
    //     }

    // }

    // checkForRecurringProcess(recurring) {
    //     if (recurring.recurringType === "daily") {
    //         console.log(recurring, '/recurring')
    //         let recurringStart = new Date(recurring.storedAt)
    //         let todaysTime = new Date()
    //         // if (recurringStart.getMonth() === todaysTime.getMonth()) {
    //         //     if (recurringStart.getDate() === todaysTime.getDate()) {
    //         //         return false;
    //         //     }
    //         // }
    //         console.log(recurringStart, '/recurring')
    //         console.log(todaysTime, '/recurring')
    //         // To calculate the time difference of two dates
    //         var differenceTime = todaysTime.getTime() - recurringStart.getTime();

    //         // To calculate the no. of days between two dates
    //         var differenceDays = differenceTime / (1000 * 3600 * 24);

    //         let dayOnAt = Math.floor(differenceDays)

    //         console.log(dayOnAt, '/recurring')

    //     }
    // }

    render() {
        console.log(this.state.productsAvailableForRecurr, '/see here')
        return (
            <div>
                {!this.state.currentUserId && this.props.currentMemberData && (
                    <GuestUserNavbar
                        currentUser={this.props.currentMemberData}
                        global_campaigns={this.props.global_campaigns}
                        products_new={this.props.products_new}
                        onLoginPage={this.state.showLoginScreen}
                        onCharityPage={this.state.showCharityDesign}
                        onAanmeldenPage={this.state.showRegisterDesign}
                        moveToEvents={() => {
                            // let contactSection = document.querySelector(".lastminutesectiontarget")
                            // if (contactSection) {
                            //     let arrayClasses = Array.from(contactSection.classList)
                            this.setState({
                                hideFullPage: true,
                                aboutActive: false,
                                redirectCharityBhodiSection: false,
                                redirectLastMinute: true,
                                showLoginScreen: false,
                                showCharityMain: false,
                            });
                            setTimeout(() => {
                                this.setState({ hideFullPage: false });
                            }, 20);
                            // }
                        }}
                        moveToDefault={() => {
                            this.setState({
                                hideFullPage: true,
                                showLoginScreen: false,
                                aboutActive: false,
                                redirectCharityBhodiSection: false,
                                redirectLastMinute: false,
                                showCharityMain: false,
                            });
                            setTimeout(() => {
                                this.setState({
                                    hideFullPage: false,
                                    showLoginScreen: false,
                                });
                            }, 20);
                        }}
                        showLoginPageNav={() => {
                            document.getElementById("root").style.overflow = "auto";
                            document.getElementById("root").style.position = "static";
                            window.scrollTo(0, 0)
                            setTimeout(() => {
                                this.setState({
                                    hideFullPage: true,
                                    // showLoginScreen: true,
                                    // showCharityDesign: false,
                                    // showRegisterDesign: false,
                                    // fullpageApi: "",
                                    // showCharityMain: false,
                                });
                            }, 200);
                            setTimeout(() => {
                                this.setState({
                                    // hideFullPage: true,
                                    showLoginScreen: true,
                                    showCharityDesign: false,
                                    showRegisterDesign: false,
                                    fullpageApi: "",
                                    showCharityMain: false,
                                });
                            }, 1000);
                        }}
                        hideLoginPageNav={() => {
                            this.setState({
                                hideFullPage: false,
                                showLoginScreen: false,
                                showCharityDesign: false,
                                showRegisterDesign: false,
                                showCharityMain: false,
                            });
                        }}
                        showCharityPageNav={() => {
                            document.getElementById("root").style.overflow = "auto";
                            document.getElementById("root").style.position = "static";
                            window.scrollTo(0, 0)
                            setTimeout(() => {
                                this.setState({
                                    hideFullPage: true,
                                    // showLoginScreen: false,
                                    // showCharityDesign: true,
                                    // showRegisterDesign: false,
                                    // fullpageApi: "",
                                    // showCharityMain: false,
                                });
                            }, 200);
                            setTimeout(() => {
                                this.setState({
                                    // hideFullPage: true,
                                    showLoginScreen: false,
                                    showCharityDesign: true,
                                    showRegisterDesign: false,
                                    fullpageApi: "",
                                    showCharityMain: false,
                                });

                            }, 1000);
                        }}
                        hideCharityPageNav={() => {
                            if (window.innerWidth < 1008) {
                                document.getElementById("root").style.overflow = "hidden";
                                document.getElementById("root").style.position = "static";
                            } else {
                                document.getElementById("root").style.overflow = "hidden";
                                document.getElementById("root").style.position = "fixed";
                            }

                            this.setState({
                                hideFullPage: false,
                                showLoginScreen: false,
                                showCharityDesign: false,
                                showRegisterDesign: false,
                                showCharityMain: false,
                            });
                        }}
                        showRegisterPageNav={() => {
                            document.getElementById("root").style.overflow = "auto";
                            document.getElementById("root").style.position = "static";
                            window.scrollTo(0, 0)
                            setTimeout(() => {
                                this.setState({
                                    hideFullPage: true,
                                    // showLoginScreen: false,
                                    // showCharityDesign: false,
                                    // showRegisterDesign: true,
                                    // fullpageApi: "",
                                    // showCharityMain: false,
                                });
                            }, 200);
                            setTimeout(() => {
                                this.setState({
                                    // hideFullPage: true,
                                    showLoginScreen: false,
                                    showCharityDesign: false,
                                    showRegisterDesign: true,
                                    fullpageApi: "",
                                    showCharityMain: false,
                                });
                            }, 1000);
                        }}
                        hideRegisterPageNav={() => {
                            if (window.innerWidth < 1008) {
                                document.getElementById("root").style.overflow = "hidden";
                                document.getElementById("root").style.position = "static";
                            } else {
                                document.getElementById("root").style.overflow = "hidden";
                                document.getElementById("root").style.position = "fixed";
                            }

                            this.setState({
                                hideFullPage: false,
                                showLoginScreen: false,
                                showCharityDesign: false,
                                showRegisterDesign: false,
                                showCharityMain: false,
                            });
                        }}
                        showCharityPageMainNav={() => {
                            this.setState({
                                hideFullPage: true,
                                showLoginScreen: false,
                                showCharityMain: true,
                                showCharityDesign: false,
                                showRegisterDesign: false,
                                fullpageApi: "",
                            });
                        }}
                    />
                )}

                {this.state.currentUserHere.user_name_id === this.props.memberId && (<div className="productsTopIcons">
                    <EditIcon className='btn-cdd' onClick={() => {
                        window.location.href = `/company/${this.state.currentUserHere.user_name_id}/edit-products`
                    }} />
                    <AddIcon className='btn-cdd' onClick={() => this.setState({ addEditProductPopup: true })} />
                </div>)}
                {this.state.currentMemberData.recurring && !this.state.showRecurringBanner && this.state.productsAvailableForRecurr.length > 0 && <RecurringTimerAutoAvailable recurringData={this.state.currentMemberData.recurring} weeklyAvailableDate={this.state.weeklyAvailableDate} showRecurringBanner={() => {
                    this.setState({ showRecurringBanner: true })
                }} />}
                {this.state.loading ? <div className='no-products-container loadingContainerProducts' style={{ flexDirection: "column" }}>
                    <div className="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <p style={{ marginTop: 15 }}>LOADING PRODUCTS</p>
                </div> : this.state.noCarouselProducts ? <div className='no-products-container'>
                    <p>NO PRODUCTS, STAY TUNED NEW THINGS COMING</p>
                </div> : this.state.showShopOffline ? <ProductBannerTimer isOffline={true} announcementDone={() => {
                    this.setState({ showShopOffline: false })
                }} /> : (this.state.showBannerAnnouncement && this.state.currentMemberData.announcement) ? <ProductBannerTimer announcement={this.state.currentMemberData.announcement} announcementDone={() => {
                    this.setState({ showBannerAnnouncement: false })
                }} /> : this.state.showBannerMaintenance ? <ProductBannerTimer isMaintenance={true} announcementDone={() => {
                    this.setState({ showBannerMaintenance: false })
                }} /> : this.state.allProducts.length > 0 ?
                    (this.state.showRecurringBanner ? <ProductBannerTimer recurring={this.state.currentMemberData.recurring} weeklyAvailableDate={this.state.weeklyAvailableDate} recurringDone={() => {
                        // this.processProducts()
                        window.location.reload()
                        // let uid = this.state.currentMemberData.user_id
                        // firebase.database().ref(`users/${uid}/refreshIndex`).set(true)
                        // if (this.props.bhodi_products) {
                        //     let filtered = this.props.bhodi_products.filter((g) => {
                        //         return g.created_by === this.props.currentMemberData.user_id && g.images
                        //     })

                        //     this.processProducts(filtered, this.state.maxProductsToShow, this.state.currentMemberData.recurring, this.state.indexToStartProducts + 1, "avoidAvailability")
                        // }
                        // this.setState({ showRecurringBanner: false })
                    }} /> : this.state.productsToRender.length === 1 ? (this.state.productsToRender[0].availableOnTime ? <ProductBannerTimer currentProduct={this.state.productsToRender[0]} updateCurrentProduct={(productTimer) => {
                        this.state.productsToRender[0].availableOnTime = ""
                        this.state.productsToRender[0].productTimerMax = productTimer

                        let allProducts = [...this.props.bhodi_products]
                        let filteredIndex = allProducts.findIndex((e) => {
                            return e.pushKey === this.state.productsToRender[0].pushKey
                        })
                        if (filteredIndex !== -1) {
                            allProducts.splice(filteredIndex, 1, this.state.productsToRender[0])
                            this.props.updateBhodiProducts(allProducts)
                        }
                    }} /> : <ProductDetailed defaultProduct={this.state.productsToRender[0]} onMainpage={true} />) : <ProductGrid mustAnimate={this.state.mustAnimate} productsToShow={this.state.productsToRender} />)
                    : this.state.currentUserHere.user_name_id === this.props.memberId && <div
                        className="bg-posts-all"
                        style={{
                            width: "100%",
                            height: "100%",
                            position: "fixed",
                            zIndex: 99999,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            top: 0,
                        }}
                    >
                        <div className="inner-bpa" style={{ maxWidth: 340 }}>
                            <PermMediaIcon style={{ fill: "white" }} />
                            <p className="desc-ibpa">
                                Voeg meerdere foto's en/of korte video's toe voor een
                                uitgebreide impressie van je bedrijf.
                            </p>
                            <AddIcon
                                style={{ fill: "white" }} onClick={() => this.setState({ addEditProductPopup: true })}
                            />
                        </div>
                    </div>}
                {this.state.addEditProductPopup && (<AddEditProduct closePopup={() => this.setState({ addEditProductPopup: false, currentProductToEdit: "" })} allCategories={this.state.completeCategories} allSectors={this.state.sectors} currentProductToEdit={this.state.currentProductToEdit} />)}
                {window.innerWidth > 1008 && <Footer />}
                {window.innerWidth < 1008 && <FooterGreen notOnMainPage={this.state.addEditProductPopup} />}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducers.user,
        album: state.postsReducers.album,
        global_campaigns: state.campaignsReducer.global_campaigns,
        images: state.postsReducers.images,
        global_values: state.globalReducers.global_values,
        guest_user_cart: state.cartReducer.guest_user_cart,
        subscription_plans: state.globalReducers.subscription_plans,
        globalsectors: state.categoriesReducer.globalsectors,
        products_new: state.campaignsReducer.products_new,
        admin_boolean: state.globalReducers.admin_boolean,
        stock_images: state.globalReducers.stock_images,
        bhodi_products: state.productsReducers.bhodi_products,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateGlobalStuff: (global_values) =>
            dispatch(updateGlobalStuff(global_values)),
        updateGuestUserCart: (guest_user_cart) =>
            dispatch(updateGuestUserCart(guest_user_cart)),
        updateGuestUserCartTime: (guest_user_cart_time) =>
            dispatch(updateGuestUserCartTime(guest_user_cart_time)),
        updateGuestUserCartExtendOpportunity: (
            guest_user_cart_extend_opportunity
        ) =>
            dispatch(
                updateGuestUserCartExtendOpportunity(guest_user_cart_extend_opportunity)
            ),
        updateStockImages: (stock_images) =>
            dispatch(updateStockImages(stock_images)),
        updateBhodiProducts: (bhodi_products) => dispatch(updateBhodiProducts(bhodi_products)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductsPage);