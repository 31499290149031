import React, { Component } from "react";
import CloseIcon from "@material-ui/icons/Close";
import firebase from "../Config/Firebase";
import {
    updateUser,
    updateGuestUserCart,
    updateGuestUserCartExtendOpportunity,
    updateGuestUserCartTime,
} from "../Redux/actions/authActions";
import { connect } from "react-redux";
import CheckIcon from "@material-ui/icons/Check";
import AddIcon from "@material-ui/icons/Add";
import { isDesktop } from "react-device-detect";
import NumberFormatter from "./NumberFormatter";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { checkForSubscribedExclusives } from "../Functions/checkForSubscribedExclusives";
import { AiFillStar, AiOutlineEdit, AiOutlineStar } from "react-icons/ai";
import { bookmarkCurrentProduct, unBookmarkCurrentProduct } from "../Functions/shopFunctions";
import { history } from "../Config/Routes";
import KortingsVoucherTimer from "../Containers/MemberHome/KortingsVoucherTimer";
import ButtonTimerCampaign from "./ButtonTimerCampaign";
import { BsThreeDots } from "react-icons/bs";
import { ChevronLeft } from "@material-ui/icons";
import { VscInfo } from "react-icons/vsc";
import { RiUserFollowLine, RiUserUnfollowLine } from "react-icons/ri";
import DOMPurify from "dompurify";
import { followUnfollowCompany } from "../Functions/friendFunctions";
import descriptionLogoTop from "../assets/toplandingpagecompany.png"

export class VoucherNewSection extends Component {
    constructor() {
        super();
        this.state = {
            activateTimer: false,
            obj: {
                "category 1": {
                    sub_category: "sub category 1",
                },
                "category 2": {
                    sub_category: "sub category 2",
                },
            },
            minutes: "",
            arrCurrentProducts: [],
            completeCategories: [],
            subCategoryPresent: true,
            currentUser: "",
            orderidToUpload: 0,
            verzilverPopup: false,
            verzilverPopupGreen: false,
            isDesktop: true,
            currentProductDetailed: "",
            activeSection: 0,
            currentUserHere: "",
            selectedPersons: 2,
            campaignsSorted: [],
            currentCampaignHere: [],
            guestUserCart: [],
            showPlaceholderLocalEvent: true,
            notShowTimerGuestLocalEvent: false,
            currentUserId: "",
            currentUserCharityMember: false,
            onCurrentProductComments: "",
        };
        // window.addEventListener("beforeunload", function() { debugger; }, false)
    }

    componentDidMount() {
        const loaderId = document.getElementById("main-page-loader-id");
        loaderId.style.display = "none";


        if (this.props.global_values) {
            let a = this.props.global_values;
            if (a) {
                let kortingsVoucherInfoMobile = ""

                if (a.kortingsVoucherInfoMobile) {
                    kortingsVoucherInfoMobile = a.kortingsVoucherInfoMobile
                }

                this.setState({
                    kortingsVoucherInfoMobile,
                });
            }
        }

        firebase.database().ref("current_order_id").on("value", (data) => {
            let a = data.val()
            if (a) {
                this.setState({ orderidToUpload: Number(a) });
            }
        })

        if (this.props.guest_user_cart) {
            this.setState({ guestUserCart: this.props.guest_user_cart });
        }

        let uid = localStorage.getItem("userid");
        this.setState({ currentUserId: uid });

        if (this.props.user) {
            if (this.props.user.length > 0) {
                let filtered = this.props.user.filter((y) => {
                    return y.user_id === uid;
                });
                if (filtered.length > 0) {
                    let a = filtered[0];
                    if (a) {
                        if (a.subscription) {
                            if (a.subscription.status === "active") {
                                this.setState({ currentUserCharityMember: true });
                            } else {
                                if (checkForSubscribedExclusives(a)) {
                                    this.setState({ currentUserCharityMember: true })
                                }
                            }
                        } else {
                            if (checkForSubscribedExclusives(a)) {
                                this.setState({ currentUserCharityMember: true })
                            }
                        }
                        this.setState({
                            currentUserHere: a,
                            currentUserId: uid,
                            isGuestUser: false,
                        });
                    }
                }

                let filteredCreator = this.props.user.filter((y) => {
                    return y.user_id === this.props.campaignToShow[0].created_by;
                });
                if (filteredCreator.length > 0) {
                    let a = filteredCreator[0];
                    if (a) {
                        this.setState({ currentUser: a });
                    }
                }
            }
        }

        this.setState({
            currentCampaignHere: this.props.campaignToShow,
            campaignsSorted: [this.props.campaignToShow[0].campaignType],
        });
    }

    // uses etc

    getUseOfVoucherKortingsVoucher(e) {
        if (this.props.user) {
            let filtered = this.props.user.filter((g) => {
                return g.user_id === e.created_by;
            });
            if (filtered.length > 0) {
                if (filtered[0].useOfVoucherKortingsVoucher) {
                    return (
                        <label onClick={() => {
                            this.setState({
                                useOfVouchersPopup: true,
                                currentUseOfVoucher: filtered[0].useOfVoucherKortingsVoucher,
                            });
                        }}
                        >
                            GEBRUIK
                        </label>
                    );
                }
            }
        }
    }

    getUseOfVoucherKortingsVoucherMobile() {
        let creator = this.props.user.filter((j) => {
            return j.user_id === this.state.currentCampaignHere[0].created_by;
        });
        if (creator.length > 0) {
            if (creator[0].useOfVoucherKortingsVoucher) {
                return (
                    <>
                        <p
                            className="desc-heading-asp"
                            style={{ fontSize: 11, textTransform: "none", marginTop: 30 }}
                        >
                            GEBRUIK
                        </p>
                        {creator[0].useOfVoucherKortingsVoucher ? (<p className="desc-content-asp" onClick={() => {
                            this.setState({
                                headingOdiMain: "GEBRUIK",
                                openDetailedInner: true,
                                paraOdiMain: creator[0].useOfVoucherKortingsVoucher
                            })
                        }}>
                            {creator[0].useOfVoucherKortingsVoucher.substr(0, 130)}...
                        </p>) : (<p className="desc-content-asp">
                            {creator[0].useOfVoucherKortingsVoucher}
                        </p>)}
                    </>
                );
            }
        }
    }

    getAboutKortingsVoucherMobile() {
        if (this.state.kortingsVoucherInfoMobile) {
            return (
                <>
                    <p
                        className="desc-heading-asp"
                        style={{ fontSize: 11, textTransform: "none", marginTop: 30 }}
                    >
                        INFO
                    </p>
                    {this.state.kortingsVoucherInfoMobile.length > 130 ? (<p className="desc-content-asp" onClick={() => {
                        this.setState({
                            headingOdiMain: "INFO",
                            openDetailedInner: true,
                            paraOdiMain: this.state.kortingsVoucherInfoMobile
                        })
                    }}>
                        {this.state.kortingsVoucherInfoMobile.substr(0, 130)}...
                    </p>) : (<p className="desc-content-asp">
                        {this.state.kortingsVoucherInfoMobile}
                    </p>)}
                </>
            );
        }
    }

    getfilelink(e) {
        if (this.props.user) {
            let filtered = this.props.user.filter((g) => {
                return g.user_id === e.created_by;
            });
            if (filtered.length > 0) {
                if (filtered[0].requirement_file) {
                    return (
                        <label
                            onClick={() => {
                                window.open(
                                    filtered[0].requirement_file,
                                    "_blank" // <- This is what makes it open in a new window.
                                );
                            }}
                        >
                            VOORWAARDEN
                        </label>
                    );
                } else if (filtered[0].termsText) {
                    return (
                        <label onClick={() => {
                            this.setState({
                                termsTextPopup: true,
                                currentTermsText: filtered[0].termsText,
                            });
                        }}
                        >
                            VOORWAARDEN
                        </label>
                    );
                }
            }
        }
    }


    getfilelinkMobile(e) {
        if (this.props.user) {
            let filtered = this.props.user.filter((g) => {
                return g.user_id === this.state.currentCampaignHere[0].created_by;
            });
            if (filtered.length > 0) {
                if (filtered[0].requirement_file) {
                    return (
                        <p
                            className="desc-heading-asp"
                            style={{ fontSize: 11, textTransform: "none", marginTop: 30 }}
                            onClick={() => {
                                window.open(
                                    filtered[0].requirement_file,
                                    "_blank" // <- This is what makes it open in a new window.
                                );
                            }}
                        >
                            VOORWAARDEN
                        </p>
                    );
                } else if (filtered[0].termsText) {
                    return (
                        <>
                            <p
                                className="desc-heading-asp"
                                style={{ fontSize: 11, textTransform: "none", marginTop: 30 }}
                            >
                                VOORWAARDEN
                            </p>
                            {filtered[0].termsText.length > 130 ? (<p className="desc-content-asp" onClick={() => {
                                this.setState({
                                    headingOdiMain: "VOORWAARDEN",
                                    openDetailedInner: true,
                                    paraOdiMain: filtered[0].termsText
                                })
                            }}>
                                {filtered[0].termsText.substr(0, 130)}...
                            </p>) : (<p className="desc-content-asp">
                                {filtered[0].termsText}
                            </p>)}
                        </>
                    );
                }
            }
        }
    }

    getVerzilveredQty(e) {
        let arrItems = e;
        if (arrItems.length > 0) {
            Array.prototype.sum = function (prop) {
                var total = 0;
                for (var i = 0, _len = this.length; i < _len; i++) {
                    total += Number(this[i][1][prop].length);
                }
                return total;
            };
            return arrItems.sum("allDates");
        }
    }

    componentDidUpdate(prevProps) {

        if (this.props.global_values !== prevProps.global_values) {
            let a = this.props.global_values;
            if (a) {
                let kortingsVoucherInfoMobile = ""

                if (a.kortingsVoucherInfoMobile) {
                    kortingsVoucherInfoMobile = a.kortingsVoucherInfoMobile
                }

                this.setState({
                    kortingsVoucherInfoMobile,
                });
            }
        }
        if (this.props.guest_user_cart) {
            if (prevProps.guest_user_cart !== this.props.guest_user_cart) {
                this.setState({ guestUserCart: this.props.guest_user_cart });
            }
        }
        if (this.props.global_campaigns) {
            if (prevProps.global_campaigns !== this.props.global_campaigns) {
                let filtered = this.props.global_campaigns.filter((g) => {
                    return g[1].pushKey === this.state.currentCampaignHere[0].pushKey
                })
                if (filtered.length > 0) {
                    let campaignToUpload = Object.assign({}, filtered[0][1])
                    campaignToUpload.type = "campaign"
                    this.state.currentCampaignHere[0] = campaignToUpload
                    this.setState({ currentCampaignHere: this.state.currentCampaignHere })
                }
            }
        }
        if (this.props.user !== prevProps.user) {
            let uid = localStorage.getItem("userid");
            if (this.props.user.length > 0) {
                let filtered = this.props.user.filter((j) => {
                    return j.user_id === uid;
                });
                if (filtered.length > 0) {
                    if (this.state.currentUserHere !== filtered[0]) {
                        this.setState({ currentUserHere: filtered[0] });
                    }
                }

                if (this.state.currentCampaignHere.length > 0) {
                    let filteredMore = this.props.user.filter((g) => {
                        return g.user_id === this.props.campaignToShow[0].created_by;
                    });
                    if (filteredMore.length > 0) {
                        this.setState({ currentUser: filteredMore[0] });
                    }
                }
            }
        }
    }

    checkForBookMarkIconVouchers(g) {
        if (g.bookmarked_by) {
            let objected = Object.entries(
                g.bookmarked_by
            );
            let filtered = objected.filter((g) => {
                return g[1].bookmarked_by === this.state.currentUserId;
            });
            if (filtered.length > 0) {
                return (
                    <div
                        className="icons-lcltsmhnc"
                        onClick={() =>
                            unBookmarkCurrentProduct(g)
                        }
                    >
                        <AiFillStar />
                    </div>
                );
            } else {
                return (
                    <div
                        className="icons-lcltsmhnc"
                        onClick={() => bookmarkCurrentProduct(g)}
                    >
                        <AiOutlineStar />
                    </div>
                );
            }
        } else {
            return (
                <div
                    className="icons-lcltsmhnc"
                    onClick={() => bookmarkCurrentProduct(g)}
                >
                    <AiOutlineStar />
                </div>
            );
        }
    }

    // checkForVerzilverKortingsVoucher(e) {
    //     if (this.state.currentUserHere) {
    //         if ((this.state.currentUserHere.subscription && this.state.currentUserHere.subscription.status === "active") || checkForSubscribedExclusives(this.state.currentUserHere)) {
    //             if (e.verzilvered_by) {
    //                 let objected = Object.entries(e.verzilvered_by);
    //                 let filtered = objected.filter((h) => {
    //                     return h[1].user_id === this.state.currentUserId;
    //                 });
    //                 if (filtered.length > 0) {
    //                     return (
    //                         <button
    //                             style={{
    //                                 backgroundColor: "#C17F6B",
    //                                 display: "inline-flex",
    //                                 alignItems: "center",
    //                             }}
    //                             className="pinkcolorbutton"
    //                         >
    //                             <CheckIcon
    //                                 style={{ fontSize: 18, marginRight: 5, marginLeft: 0 }}
    //                             />{" "}
    //                             {window.innerWidth > 1008 ? `In orderoverzicht` : `Overzicht`}
    //                         </button>
    //                     );
    //                 }
    //             }
    //             if (!e.kortingsVoucherUnlimited) {
    //                 let bewaaredArray = [];
    //                 let num = 0;
    //                 if (e.verzilvered_by) {
    //                     let objectedCheckedIn = Object.entries(e.verzilvered_by);
    //                     num = this.getVerzilveredQty(objectedCheckedIn);
    //                 }
    //                 if (e.inCartOf) {
    //                     let objectedInCartOf = Object.entries(e.inCartOf);
    //                     num = num + objectedInCartOf.length;
    //                 }
    //                 if (num + bewaaredArray.length < Number(e.number)) {
    //                     if (window.innerWidth > 1008) {
    //                         if (e.verzilvered_by) {
    //                             let objected = Object.entries(e.verzilvered_by);
    //                             let filtered = objected.filter((h) => {
    //                                 return h[1].user_id === this.state.currentUserId;
    //                             });
    //                             if (filtered.length > 0) {
    //                                 return (
    //                                     <button
    //                                         style={{
    //                                             backgroundColor: "#C17F6B",
    //                                             display: "inline-flex",
    //                                             alignItems: "center",
    //                                         }}
    //                                         className="pinkcolorbutton"
    //                                     >
    //                                         <CheckIcon
    //                                             style={{ fontSize: 18, marginRight: 5, marginLeft: 0 }}
    //                                         />{" "}
    //                                         In orderoverzicht
    //                                     </button>
    //                                 );
    //                             } else {
    //                                 return (
    //                                     <button onClick={this.verzilverKortingsVoucher.bind(this, e)}>
    //                                         Valideren
    //                                     </button>
    //                                 );
    //                             }
    //                         } else {
    //                             return (
    //                                 <button onClick={this.verzilverKortingsVoucher.bind(this, e)}>
    //                                     Valideren
    //                                 </button>
    //                             );
    //                         }
    //                     } else {
    //                         if (
    //                             this.state.currentUserHere.stripe_id &&
    //                             !this.state.userNotSubscribed
    //                         ) {
    //                             if (e.verzilvered_by) {
    //                                 let objected = Object.entries(e.verzilvered_by);
    //                                 let filtered = objected.filter((h) => {
    //                                     return h[1].user_id === this.state.currentUserId;
    //                                 });
    //                                 if (filtered.length > 0) {
    //                                     return (
    //                                         <button
    //                                             style={{
    //                                                 backgroundColor: "#C17F6B",
    //                                                 display: "inline-flex",
    //                                                 alignItems: "center",
    //                                             }}
    //                                             className="pinkcolorbutton"
    //                                         >
    //                                             <CheckIcon
    //                                                 style={{
    //                                                     fontSize: 18,
    //                                                     marginRight: 5,
    //                                                     marginLeft: 0,
    //                                                 }}
    //                                             />{" "}
    //                                             In orderoverzicht
    //                                         </button>
    //                                     );
    //                                 } else {
    //                                     return (
    //                                         <button onClick={this.verzilverKortingsVoucher.bind(this, e)}>
    //                                             Valideren
    //                                         </button>
    //                                     );
    //                                 }
    //                             } else {
    //                                 return (
    //                                     <button onClick={this.verzilverKortingsVoucher.bind(this, e)}>
    //                                         Valideren
    //                                     </button>
    //                                 );
    //                             }
    //                         } else {
    //                             return (
    //                                 <button onClick={() => (window.location.href = "/charity")}>
    //                                     Register
    //                                 </button>
    //                             );
    //                         }
    //                     }
    //                 } else {
    //                     return (
    //                         <button style={{ backgroundColor: "#C17F6B" }} className="pinkcolorbutton">
    //                             Uitverkocht
    //                         </button>
    //                     );
    //                 }
    //             } else {
    //                 if (window.innerWidth > 1008) {
    //                     if (e.verzilvered_by) {
    //                         let objected = Object.entries(e.verzilvered_by);
    //                         let filtered = objected.filter((h) => {
    //                             return h[1].user_id === this.state.currentUserId;
    //                         });
    //                         if (filtered.length > 0) {
    //                             return (
    //                                 <button
    //                                     style={{
    //                                         backgroundColor: "#C17F6B",
    //                                         display: "inline-flex",
    //                                         alignItems: "center",
    //                                     }}
    //                                     className="pinkcolorbutton"
    //                                 >
    //                                     <CheckIcon
    //                                         style={{ fontSize: 18, marginRight: 5, marginLeft: 0 }}
    //                                     />{" "}
    //                                     In orderoverzicht
    //                                 </button>
    //                             );
    //                         } else {
    //                             return (
    //                                 <button onClick={this.verzilverKortingsVoucher.bind(this, e)}>
    //                                     Valideren
    //                                 </button>
    //                             );
    //                         }
    //                     } else {
    //                         return (
    //                             <button onClick={this.verzilverKortingsVoucher.bind(this, e)}>
    //                                 Valideren
    //                             </button>
    //                         );
    //                     }
    //                 } else {
    //                     if (
    //                         this.state.currentUserHere.stripe_id &&
    //                         !this.state.userNotSubscribed
    //                     ) {
    //                         if (e.verzilvered_by) {
    //                             let objected = Object.entries(e.verzilvered_by);
    //                             let filtered = objected.filter((h) => {
    //                                 return h[1].user_id === this.state.currentUserId;
    //                             });
    //                             if (filtered.length > 0) {
    //                                 return (
    //                                     <button
    //                                         style={{
    //                                             backgroundColor: "#C17F6B",
    //                                             display: "inline-flex",
    //                                             alignItems: "center",
    //                                         }}
    //                                         className="pinkcolorbutton"
    //                                     >
    //                                         <CheckIcon
    //                                             style={{ fontSize: 18, marginRight: 5, marginLeft: 0 }}
    //                                         />{" "}
    //                                         In orderoverzicht
    //                                     </button>
    //                                 );
    //                             } else {
    //                                 return (
    //                                     <button onClick={this.verzilverKortingsVoucher.bind(this, e)}>
    //                                         Valideren
    //                                     </button>
    //                                 );
    //                             }
    //                         } else {
    //                             return (
    //                                 <button onClick={this.verzilverKortingsVoucher.bind(this, e)}>
    //                                     Valideren
    //                                 </button>
    //                             );
    //                         }
    //                     } else {
    //                         return (
    //                             <button onClick={() => (window.location.href = "/charity")}>
    //                                 Register
    //                             </button>
    //                         );
    //                     }
    //                 }
    //             }
    //         } else {
    //             if (this.state.guestUserCart) {
    //                 let filtered = this.state.guestUserCart.filter((h) => {
    //                     return h.pushKey === e.pushKey;
    //                 });
    //                 if (filtered.length > 0) {
    //                     if (!e.kortingsVoucherUnlimited) {
    //                         let num = 0;
    //                         if (e.verzilvered_by) {
    //                             let objectedCheckedIn = Object.entries(e.verzilvered_by);
    //                             num = this.getVerzilveredQty(objectedCheckedIn);
    //                         }
    //                         if (e.inCartOf) {
    //                             let objectedInCartOf = Object.entries(e.inCartOf);
    //                             num = num + objectedInCartOf.length;
    //                         }
    //                         if (num < Number(e.number)) {
    //                             return (
    //                                 <button style={{ backgroundColor: "#C17F6B" }} className="pinkcolorbutton">
    //                                     Toegevoegd
    //                                 </button>
    //                             );
    //                         } else {
    //                             return (
    //                                 <button style={{ backgroundColor: "#C17F6B" }} className="pinkcolorbutton">
    //                                     Uitverkocht
    //                                 </button>
    //                             );
    //                         }
    //                     } else {
    //                         return (
    //                             <button style={{ backgroundColor: "#C17F6B" }} className="pinkcolorbutton">
    //                                 Toegevoegd
    //                             </button>
    //                         );
    //                     }
    //                 } else {
    //                     if (!e.kortingsVoucherUnlimited) {
    //                         let num = 0;
    //                         if (e.verzilvered_by) {
    //                             let objectedCheckedIn = Object.entries(e.verzilvered_by);
    //                             num = this.getVerzilveredQty(objectedCheckedIn);
    //                         }
    //                         if (e.inCartOf) {
    //                             let objectedInCartOf = Object.entries(e.inCartOf);
    //                             num = num + objectedInCartOf.length;
    //                         }
    //                         if (num < Number(e.number)) {
    //                             return (
    //                                 <button onClick={this.addToCartNonUsers.bind(this, e)}>
    //                                     Winkelmand
    //                                 </button>
    //                             );
    //                         } else {
    //                             return (
    //                                 <button style={{ backgroundColor: "#C17F6B" }} className="pinkcolorbutton">
    //                                     Uitverkocht
    //                                 </button>
    //                             );
    //                         }
    //                     } else {
    //                         return (
    //                             <button onClick={this.addToCartNonUsers.bind(this, e)}>
    //                                 Winkelmand
    //                             </button>
    //                         );
    //                     }
    //                 }
    //             } else {
    //                 if (!e.kortingsVoucherUnlimited) {
    //                     let num = 0;
    //                     if (e.verzilvered_by) {
    //                         let objectedCheckedIn = Object.entries(e.verzilvered_by);
    //                         num = this.getVerzilveredQty(objectedCheckedIn);
    //                     }
    //                     if (e.inCartOf) {
    //                         let objectedInCartOf = Object.entries(e.inCartOf);
    //                         num = num + objectedInCartOf.length;
    //                     }
    //                     if (num < Number(e.number)) {
    //                         return (
    //                             <button onClick={this.addToCartNonUsers.bind(this, e)}>
    //                                 Winkelmand
    //                             </button>
    //                         );
    //                     } else {
    //                         return (
    //                             <button style={{ backgroundColor: "#C17F6B" }} className="pinkcolorbutton">
    //                                 Uitverkocht
    //                             </button>
    //                         );
    //                     }
    //                 } else {
    //                     return (
    //                         <button onClick={this.addToCartNonUsers.bind(this, e)}>
    //                             Winkelmand
    //                         </button>
    //                     );
    //                 }
    //             }
    //         }
    //     }
    // }

    checkForVerzilverKortingsVoucher(e) {
        if (this.state.currentUserHere) {
            if ((this.state.currentUserHere.subscription && this.state.currentUserHere.subscription.status === "active") || checkForSubscribedExclusives(this.state.currentUserHere)) {
                if (e.verzilvered_by) {
                    let objected = Object.entries(e.verzilvered_by);
                    let filtered = objected.filter((h) => {
                        return h[1].user_id === this.state.currentUserId;
                    });
                    if (filtered.length > 0) {
                        return (
                            <button
                                style={{
                                    backgroundColor: "#C17F6B",
                                    display: "inline-flex",
                                    alignItems: "center",
                                }}
                                className="pinkcolorbutton"
                            >
                                <CheckIcon
                                    style={{ fontSize: 18, marginRight: 5, marginLeft: 0 }}
                                />{" "}
                                {window.innerWidth > 1008 ? `In orderoverzicht` : `Overzicht`}
                            </button>
                        );
                    }
                }
                if (!e.kortingsVoucherUnlimited) {
                    let bewaaredArray = [];
                    let num = 0;
                    if (e.verzilvered_by) {
                        let objectedCheckedIn = Object.entries(e.verzilvered_by);
                        num = this.getVerzilveredQty(objectedCheckedIn);
                    }
                    if (e.inCartOf) {
                        let objectedInCartOf = Object.entries(e.inCartOf);
                        num = num + objectedInCartOf.length;
                    }
                    if (num + bewaaredArray.length < Number(e.number)) {
                        if (window.innerWidth > 1008) {
                            if (e.verzilvered_by) {
                                let objected = Object.entries(e.verzilvered_by);
                                let filtered = objected.filter((h) => {
                                    return h[1].user_id === this.state.currentUserId;
                                });
                                if (filtered.length > 0) {
                                    return (
                                        <button
                                            className="pinkcolorbutton"
                                            style={{
                                                backgroundColor: "#C17F6B",
                                                display: "inline-flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <CheckIcon
                                                style={{ fontSize: 18, marginRight: 5, marginLeft: 0 }}
                                            />{" "}
                                            {window.innerWidth > 1008
                                                ? `In orderoverzicht`
                                                : `Overzicht`}
                                        </button>
                                    );
                                } else {
                                    return (
                                        <button onClick={this.verzilverKortingsVoucher.bind(this, e)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                            Valideren{" "}
                                        </button>
                                    );
                                }
                            } else {
                                return (
                                    <button onClick={this.verzilverKortingsVoucher.bind(this, e)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                        Valideren
                                    </button>
                                );
                            }
                        } else {
                            if (
                                this.state.currentUserHere.stripe_id &&
                                !this.state.userNotSubscribed
                            ) {
                                if (e.verzilvered_by) {
                                    let objected = Object.entries(e.verzilvered_by);
                                    let filtered = objected.filter((h) => {
                                        return h[1].user_id === this.state.currentUserId;
                                    });
                                    if (filtered.length > 0) {
                                        return (
                                            <button
                                                style={{
                                                    backgroundColor: "#C17F6B",
                                                    display: "inline-flex",
                                                    alignItems: "center",
                                                }}
                                                className="pinkcolorbutton"
                                            >
                                                <CheckIcon
                                                    style={{
                                                        fontSize: 18,
                                                        marginRight: 5,
                                                        marginLeft: 0,
                                                    }}
                                                />{" "}
                                                {window.innerWidth > 1008
                                                    ? `In orderoverzicht`
                                                    : `Overzicht`}
                                            </button>
                                        );
                                    } else {
                                        return (
                                            <button onClick={this.verzilverKortingsVoucher.bind(this, e)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                                Valideren{" "}
                                            </button>
                                        );
                                    }
                                } else {
                                    return (
                                        <button onClick={this.verzilverKortingsVoucher.bind(this, e)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                            Valideren{" "}
                                        </button>
                                    );
                                }
                            } else {
                                return (
                                    <button onClick={this.verzilverKortingsVoucher.bind(this, e)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                        Valideren
                                    </button>
                                    // <Link to="/charity" className="link-style">
                                    //   <button
                                    //   // Commented by me
                                    //   //
                                    //   >
                                    //     Register
                                    //   </button>
                                    // </Link>
                                );
                            }
                        }
                    } else {
                        return (
                            <button
                                style={{ backgroundColor: "#C17F6B" }}
                                className="pinkcolorbutton"
                            >
                                Uitverkocht
                            </button>
                        );
                    }
                } else {
                    if (window.innerWidth > 1008) {
                        if (e.verzilvered_by) {
                            let objected = Object.entries(e.verzilvered_by);
                            let filtered = objected.filter((h) => {
                                return h[1].user_id === this.state.currentUserId;
                            });
                            if (filtered.length > 0) {
                                return (
                                    <button
                                        style={{
                                            backgroundColor: "#C17F6B",
                                            display: "inline-flex",
                                            alignItems: "center",
                                        }}
                                        className="pinkcolorbutton"
                                    >
                                        <CheckIcon
                                            style={{ fontSize: 18, marginRight: 5, marginLeft: 0 }}
                                        />{" "}
                                        {window.innerWidth > 1008
                                            ? `In orderoverzicht`
                                            : `Overzicht`}
                                    </button>
                                );
                            } else {
                                return (
                                    <button onClick={this.verzilverKortingsVoucher.bind(this, e)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                        Valideren
                                    </button>
                                );
                            }
                        } else {
                            return (
                                <button onClick={this.verzilverKortingsVoucher.bind(this, e)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                    Valideren
                                </button>
                            );
                        }
                    } else {
                        if (
                            this.state.currentUserHere.stripe_id &&
                            !this.state.userNotSubscribed
                        ) {
                            if (e.verzilvered_by) {
                                let objected = Object.entries(e.verzilvered_by);
                                let filtered = objected.filter((h) => {
                                    return h[1].user_id === this.state.currentUserId;
                                });
                                if (filtered.length > 0) {
                                    return (
                                        <button
                                            style={{
                                                backgroundColor: "#C17F6B",
                                                display: "inline-flex",
                                                alignItems: "center",
                                            }}
                                            className="pinkcolorbutton"
                                        >
                                            <CheckIcon
                                                style={{ fontSize: 18, marginRight: 5, marginLeft: 0 }}
                                            />{" "}
                                            {window.innerWidth > 1008
                                                ? `In orderoverzicht`
                                                : `Overzicht`}
                                        </button>
                                    );
                                } else {
                                    return (
                                        <button onClick={this.verzilverKortingsVoucher.bind(this, e)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                            Valideren{" "}
                                        </button>
                                    );
                                }
                            } else {
                                return (
                                    <button onClick={this.verzilverKortingsVoucher.bind(this, e)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                        Valideren
                                    </button>
                                );
                            }
                        } else {
                            return (
                                <button>
                                    Register
                                </button>
                            );
                        }
                    }
                }
            } else {
                if (this.state.guestUserCart) {
                    let filtered = this.state.guestUserCart.filter((h) => {
                        return h.pushKey === e.pushKey;
                    });
                    if (filtered.length > 0) {
                        // if (!e.kortingsVoucherUnlimited) {
                        //     let num = 0
                        //     if (e.verzilvered_by) {
                        //         let objectedCheckedIn = Object.entries(e.verzilvered_by)
                        //         num = this.getVerzilveredQty(objectedCheckedIn)
                        //     }
                        //     if (num < Number(e.number)) {
                        //         return <button style={{ backgroundColor: "#C17F6B" }} className="pinkcolorbutton">Toegevoegd</button>
                        //     } else {
                        //         return <button style={{ backgroundColor: "#C17F6B" }} className="pinkcolorbutton">Uitverkocht</button>
                        //     }
                        // } else {
                        return (
                            <button
                                style={{ backgroundColor: "#C17F6B" }}
                                className="pinkcolorbutton"
                            >
                                Toegevoegd
                            </button>
                        );
                        // }
                    } else {
                        if (!e.kortingsVoucherUnlimited) {
                            let num = 0;
                            if (e.verzilvered_by) {
                                let objectedCheckedIn = Object.entries(e.verzilvered_by);
                                num = this.getVerzilveredQty(objectedCheckedIn);
                            }
                            if (e.inCartOf) {
                                let objectedInCartOf = Object.entries(e.inCartOf);
                                num = num + objectedInCartOf.length;
                            }
                            if (num < Number(e.number)) {
                                return (
                                    <button onClick={this.addToCartNonUsers.bind(this, e)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                        Boek nu
                                    </button>
                                );
                            } else {
                                return (
                                    <button
                                        style={{ backgroundColor: "#C17F6B" }}
                                        className="pinkcolorbutton"
                                    >
                                        Uitverkocht
                                    </button>
                                );
                            }
                        } else {
                            return (
                                <button onClick={this.addToCartNonUsers.bind(this, e)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                    Boek nu
                                </button>
                            );
                        }
                    }
                } else {
                    if (!e.kortingsVoucherUnlimited) {
                        let num = 0;
                        if (e.verzilvered_by) {
                            let objectedCheckedIn = Object.entries(e.verzilvered_by);
                            num = this.getVerzilveredQty(objectedCheckedIn);
                        }
                        if (e.inCartOf) {
                            let objectedInCartOf = Object.entries(e.inCartOf);
                            num = num + objectedInCartOf.length;
                        }
                        if (num < Number(e.number)) {
                            return (
                                <button onClick={this.addToCartNonUsers.bind(this, e)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                    Boek nu
                                </button>
                            );
                        } else {
                            return (
                                <button
                                    style={{ backgroundColor: "#C17F6B" }}
                                    className="pinkcolorbutton"
                                >
                                    Uitverkocht
                                </button>
                            );
                        }
                    } else {
                        return (
                            <button onClick={this.addToCartNonUsers.bind(this, e)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                Boek nu
                            </button>
                        );
                    }
                }
            }
        } else {
            if (this.state.guestUserCart) {
                let filtered = this.state.guestUserCart.filter((h) => {
                    return h.pushKey === e.pushKey;
                });
                if (filtered.length > 0) {
                    // if (!e.kortingsVoucherUnlimited) {
                    //     let num = 0
                    //     if (e.verzilvered_by) {
                    //         let objectedCheckedIn = Object.entries(e.verzilvered_by)
                    //         num = this.getVerzilveredQty(objectedCheckedIn)
                    //     }
                    //     if (num < Number(e.number)) {
                    //         return <button style={{ backgroundColor: "#C17F6B" }} className="pinkcolorbutton">Toegevoegd</button>
                    //     } else {
                    //         return <button style={{ backgroundColor: "#C17F6B" }} className="pinkcolorbutton">Uitverkocht</button>
                    //     }
                    // } else {
                    return (
                        <button
                            style={{ backgroundColor: "#C17F6B" }}
                            className="pinkcolorbutton"
                        >
                            Toegevoegd
                        </button>
                    );
                    // }
                } else {
                    if (!e.kortingsVoucherUnlimited) {
                        let num = 0;
                        if (e.verzilvered_by) {
                            let objectedCheckedIn = Object.entries(e.verzilvered_by);
                            num = this.getVerzilveredQty(objectedCheckedIn);
                        }
                        if (e.inCartOf) {
                            let objectedInCartOf = Object.entries(e.inCartOf);
                            num = num + objectedInCartOf.length;
                        }
                        if (num < Number(e.number)) {
                            return (
                                <button onClick={this.addToCartNonUsers.bind(this, e)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                    Boek nu
                                </button>
                            );
                        } else {
                            return (
                                <button
                                    style={{ backgroundColor: "#C17F6B" }}
                                    className="pinkcolorbutton"
                                >
                                    Uitverkocht
                                </button>
                            );
                        }
                    } else {
                        return (
                            <button onClick={this.addToCartNonUsers.bind(this, e)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                Boek nu
                            </button>
                        );
                    }
                }
            } else {
                if (!e.kortingsVoucherUnlimited) {
                    let num = 0;
                    if (e.verzilvered_by) {
                        let objectedCheckedIn = Object.entries(e.verzilvered_by);
                        num = this.getVerzilveredQty(objectedCheckedIn);
                    }
                    if (e.inCartOf) {
                        let objectedInCartOf = Object.entries(e.inCartOf);
                        num = num + objectedInCartOf.length;
                    }
                    if (num < Number(e.number)) {
                        return (
                            <button onClick={this.addToCartNonUsers.bind(this, e)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                Boek nu
                            </button>
                        );
                    } else {
                        return (
                            <button
                                style={{ backgroundColor: "#C17F6B" }}
                                className="pinkcolorbutton"
                            >
                                Uitverkocht
                            </button>
                        );
                    }
                } else {
                    return (
                        <button onClick={this.addToCartNonUsers.bind(this, e)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                            Boek nu
                        </button>
                    );
                }
            }
        }
    }


    verzilverKortingsVoucher(e) {
        if (this.props.user) {
            let filtered = this.props.user.filter((g) => {
                return g.user_id === e.created_by;
            });
            if (filtered.length > 0) {
                this.setState({ currentUser: filtered[0] });
            }
        }
        this.setState({
            verzilverPopup: true,
            currentActie: e,
            currentActieType: "kortings_voucher",
        });
    }


    checkForButtonsVerzilverKortingsVoucher(currentActie) {
        return (
            <div style={{ display: "flex", marginTop: 20, justifyContent: "center" }}>
                {!isDesktop ? (
                    <button
                        style={{ marginRight: 5 }}
                        onClick={this.proceedKortingsVoucher.bind(this)}
                    >
                        OK
                    </button>
                ) : (
                    <button
                        style={{ marginRight: 5 }}
                        onClick={this.proceedKortingsVoucher.bind(this)}
                    >
                        OK
                    </button>
                )}
            </div>
        );
    }

    proceedKortingsVoucher() {
        let order_id = this.state.orderidToUpload + 1;
        firebase.database().ref("current_order_id").set(order_id)
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, "0");
        let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        let yyyy = today.getFullYear();

        today = mm + "/" + dd + "/" + yyyy;
        let arrSellers = [];
        arrSellers.push(this.state.currentUser.user_id);

        if (this.state.currentUser.expireTypeVoucher && this.state.currentUser.expireDateVoucher) {
            this.state.currentActie.expireTypeVoucher = this.state.currentUser.expireTypeVoucher
            this.state.currentActie.expireDateVoucher = this.state.currentUser.expireDateVoucher
        }

        let obj = {
            created_on: today,
            order_id,
            products: [this.state.currentActie],
            buyer: this.state.currentUserId,
            sellers: arrSellers,
            timeStampCreated: new Date().getTime(),
            order_type: "campaign",
        };
        let pushuserOrderCurrentUser = firebase.database().ref("users/" + this.state.currentUserId + "/orders").push().key;

        firebase.database().ref("users/" + this.state.currentUserId + "/orders/" + pushuserOrderCurrentUser).set(obj);

        let pushuserOrder = firebase
            .database()
            .ref("users/" + this.state.currentUser.user_id + "/orders")
            .push().key;
        firebase
            .database()
            .ref(
                "users/" + this.state.currentUser.user_id + "/orders/" + pushuserOrder
            )
            .set(obj);
        let orderKey = firebase.database().ref("all_orders").push().key;
        obj.pushKey = orderKey;
        firebase
            .database()
            .ref("all_orders/" + orderKey)
            .set(obj);
        if (this.state.currentActie.verzilvered_by) {
            let objected = Object.entries(this.state.currentActie.verzilvered_by);
            let filtered = objected.filter((h) => {
                return h[1].user_id === this.state.currentUserId;
            });
            if (filtered.length > 0) {
                let newDate = new Date().getTime();
                let arr = filtered[0][1].allDates;
                arr.push(newDate);
                let pushKey = filtered[0][1].pushKey;
                let obj = {
                    user_id: this.state.currentUserId,
                    date: newDate,
                    allDates: arr,
                    pushKey,
                };
                firebase
                    .database()
                    .ref(
                        "bhodi_campaigns/" +
                        this.state.currentUser.user_id +
                        "/" +
                        this.state.currentActie.pushKey +
                        "/verzilvered_by/" +
                        filtered[0][1].pushKey
                    )
                    .set(obj);
            } else {
                let newDate = new Date().getTime();
                let arr = [];
                arr.push(newDate);
                let pushKey = firebase
                    .database()
                    .ref(
                        "bhodi_campaigns/" +
                        this.state.currentUser.user_id +
                        "/" +
                        this.state.currentActie.pushKey +
                        "/verzilvered_by"
                    )
                    .push().key;
                let obj = {
                    user_id: this.state.currentUserId,
                    date: newDate,
                    allDates: arr,
                    pushKey,
                };
                firebase
                    .database()
                    .ref(
                        "bhodi_campaigns/" +
                        this.state.currentUser.user_id +
                        "/" +
                        this.state.currentActie.pushKey +
                        "/verzilvered_by/" +
                        pushKey
                    )
                    .set(obj);
            }
        } else {
            let newDate = new Date().getTime();
            let arr = [];
            arr.push(newDate);
            let pushKey = firebase
                .database()
                .ref(
                    "bhodi_campaigns/" +
                    this.state.currentUser.user_id +
                    "/" +
                    this.state.currentActie.pushKey +
                    "/verzilvered_by"
                )
                .push().key;
            let obj = {
                user_id: this.state.currentUserId,
                date: newDate,
                allDates: arr,
                pushKey,
            };
            firebase
                .database()
                .ref(
                    "bhodi_campaigns/" +
                    this.state.currentUser.user_id +
                    "/" +
                    this.state.currentActie.pushKey +
                    "/verzilvered_by/" +
                    pushKey
                )
                .set(obj);
        }

        let notificationToShow = document.getElementById("mustShowOnAddOrder");
        if (notificationToShow) {
            notificationToShow.style.opacity = 1;
            notificationToShow.style.pointerEvents = "all";
        }

        setTimeout(() => {
            if (notificationToShow) {
                notificationToShow.style.opacity = 0;
                notificationToShow.style.pointerEvents = "none";
            }
        }, 4000);
        this.setState({ verzilverPopup: false, verzilverPopupGreen: false });
    }


    addToCartNonUsers(e) {
        let f = e;
        let imgtodrag = "";

        imgtodrag = document.getElementsByClassName("image-frontkortingsvoucher")[0];

        let arrCart = [...this.state.guestUserCart];

        let cookieItem = localStorage.getItem("cookie_item");

        var minutesToAddDB = Number(process.env.REACT_APP_CART_TIME);
        var currentDateDB = new Date();
        var futureDateDB = new Date(
            currentDateDB.getTime() + minutesToAddDB * 60000
        );

        let futureTimeStampDB = Math.floor(futureDateDB.getTime() / 1000);

        let objToMake = {
            cookieItem,
            itemPush: e.pushKey,
            timestamp: futureTimeStampDB,
        };

        if (e.campaignType === "web_shop") {
            const min = 0;
            const max = e.coupons.length;
            const intNumber = Math.floor(Math.random() * (max - min)) + min;
            e.redeemed_code = e.coupons[intNumber];
            objToMake.redeemed_code = e.coupons[intNumber];
        }

        var minutesToAdd = Number(process.env.REACT_APP_CART_TIME);
        var currentDate = new Date();
        var futureDate = new Date(currentDate.getTime() + minutesToAdd * 60000);

        let futureTimeStamp = Math.floor(futureDate.getTime() / 1000);

        arrCart.push(e);

        this.props.updateGuestUserCart(arrCart);
        this.props.updateGuestUserCartTime(futureTimeStamp);
        this.props.updateGuestUserCartExtendOpportunity(true);

        let userid = localStorage.getItem("userid");
        if (userid) {
            objToMake.cookieItem = userid;
            let pushKey = firebase
                .database()
                .ref("guest_carts/" + userid)
                .push().key;
            objToMake.pushKey = pushKey;
            let ownPushKey = firebase
                .database()
                .ref("bhodi_campaigns/" + f.created_by + "/" + f.pushKey + "/inCartOf")
                .push().key;

            objToMake.pushKeyInCartOf = ownPushKey;
            objToMake.creatorCompanyId = f.created_by;

            firebase
                .database()
                .ref("guest_carts/" + userid + "/" + pushKey)
                .set(objToMake);

            let objToOwnPush = {
                cookie: objToMake.cookieItem,
                pushKey: ownPushKey,
                cartPushKey: pushKey,
            };
            firebase
                .database()
                .ref(
                    "bhodi_campaigns/" +
                    f.created_by +
                    "/" +
                    f.pushKey +
                    "/inCartOf/" +
                    ownPushKey
                )
                .set(objToOwnPush);
        } else {
            if (cookieItem) {
                let pushKey = firebase
                    .database()
                    .ref("guest_carts/" + cookieItem)
                    .push().key;
                objToMake.pushKey = pushKey;

                let ownPushKey = firebase
                    .database()
                    .ref(
                        "bhodi_campaigns/" + f.created_by + "/" + f.pushKey + "/inCartOf"
                    )
                    .push().key;

                objToMake.pushKeyInCartOf = ownPushKey;
                objToMake.creatorCompanyId = f.created_by;

                firebase
                    .database()
                    .ref("guest_carts/" + cookieItem + "/" + pushKey)
                    .set(objToMake);
                let objToOwnPush = {
                    cookie: objToMake.cookieItem,
                    pushKey: ownPushKey,
                    cartPushKey: pushKey,
                };
                firebase
                    .database()
                    .ref(
                        "bhodi_campaigns/" +
                        f.created_by +
                        "/" +
                        f.pushKey +
                        "/inCartOf/" +
                        ownPushKey
                    )
                    .set(objToOwnPush);
            }
        }

        let disLeft = imgtodrag.getBoundingClientRect().left;
        let disTop = imgtodrag.getBoundingClientRect().top;
        let viewcart = document.getElementsByClassName("cart-icon")[0];
        let image = imgtodrag.cloneNode(true);

        let cartleft = viewcart.getBoundingClientRect().left;
        let carttop = viewcart.getBoundingClientRect().top;

        image.style =
            "z-index: 99999999999; width: 30px;opacity:0.8; position:fixed; top:" +
            disTop +
            "px;left:" +
            disLeft +
            "px;transition: left 2s, top 2s, width 2s, opacity 2s cubic-bezier(1, 1, 1, 1)";

        var rechange = document.body.appendChild(image);
        setTimeout(function () {
            image.style.left = cartleft + "px";
            image.style.top = carttop + "px";
            image.style.width = "30px";
            image.style.opacity = "0";
        }, 200);
        setTimeout(function () {
            rechange.parentNode.removeChild(rechange);
        }, 2000);

        let notificationToShow = document.getElementById("mustShowOnAddBasket");
        if (notificationToShow) {
            notificationToShow.style.opacity = 1;
            notificationToShow.style.pointerEvents = "all";
        }

        setTimeout(() => {
            if (notificationToShow) {
                notificationToShow.style.opacity = 0;
                notificationToShow.style.pointerEvents = "none";
            }
        }, 4000);
    }

    getUserName(userid) {
        if (this.props.user) {
            let filtered = this.props.user.filter((t) => {
                return t.user_id === userid
            })
            if (filtered.length > 0) {
                return `${filtered[0].company_name}`
            }
        }
    }

    getUserCity(userid) {
        if (this.props.user) {
            let filtered = this.props.user.filter((t) => {
                return t.user_id === userid
            })
            if (filtered.length > 0) {
                if (filtered[0].city) {
                    return `${filtered[0].city} | ${filtered[0].country}`
                }
                return `${filtered[0].country}`
            }
        }
    }

    getUserProfilePic(userid) {
        if (this.props.user) {
            let filtered = this.props.user.filter((t) => {
                return t.user_id === userid
            })
            if (filtered.length > 0) {
                if (filtered[0].profile_image) {
                    return `${filtered[0].profile_image}`
                }
            }
        }
        return "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9"
    }

    redirectUser(userid) {
        if (this.props.user) {
            let filtered = this.props.user.filter((t) => {
                return t.user_id === userid
            })
            if (filtered.length > 0) {
                if (filtered[0].type === "company") {
                    history.push(`/company/${filtered[0].user_name_id}`)
                } else {
                    history.push(`/member/${filtered[0].user_name_id}`)
                }
            }
        }
    }

    checkForFollowIcon(creatorId) {
        let currentUserMember = this.props.user.filter((t) => {
            return t.user_id === creatorId
        })
        if (currentUserMember[0].followed_by) {
            let objected = Object.entries(currentUserMember[0].followed_by);
            let filtered = objected.filter((g) => {
                return g[1].user_id === this.state.currentUserId;
            });
            if (filtered.length > 0) {
                return (
                    <RiUserUnfollowLine onClick={this.followCompany.bind(this, currentUserMember[0])} />
                );
            } else {
                return (
                    <RiUserFollowLine onClick={this.followCompany.bind(this, currentUserMember[0])} />
                );
            }
        } else {
            return (
                <RiUserFollowLine onClick={this.followCompany.bind(this, currentUserMember[0])} />
            );
        }
    }

    followCompany(currentUser) {
        followUnfollowCompany(currentUser, this.state.currentUserId, this.props.user)
    }

    // followCompany(currentUser) {
    //     if (currentUser.followed_by) {
    //         let objected = Object.entries(currentUser.followed_by);
    //         let filtered = objected.filter((g) => {
    //             return g[1].user_id === this.state.currentUserId;
    //         });
    //         if (filtered.length > 0) {
    //             if (currentUser.newFollowersList) {
    //                 let objectedSec = Object.entries(
    //                     currentUser.newFollowersList
    //                 );
    //                 let filteredNewFollowers = objectedSec.filter((g) => {
    //                     return g[1].user_id === this.state.currentUserId;
    //                 });
    //                 if (filteredNewFollowers.length > 0) {
    //                     let newFollowers = Number(currentUser.newFollowers);
    //                     newFollowers = newFollowers - 1;
    //                     firebase
    //                         .database()
    //                         .ref(
    //                             "users/" +
    //                             currentUser.user_id +
    //                             "/newFollowersList/" +
    //                             filteredNewFollowers[0][1].push_key
    //                         )
    //                         .remove();
    //                     firebase
    //                         .database()
    //                         .ref("users/" + currentUser.user_id + "/newFollowers")
    //                         .set(newFollowers);
    //                 }
    //             }
    //             let filteredCurrentUser = this.props.user.filter((h) => {
    //                 return h.user_id === this.state.currentUserId;
    //             });
    //             if (filteredCurrentUser[0].following) {
    //                 let objectedSec = Object.entries(filteredCurrentUser[0].following);
    //                 let filteredSec = objectedSec.filter((g) => {
    //                     return g[1].user_id === currentUser.user_id;
    //                 });
    //                 if (filteredSec.length > 0) {
    //                     firebase
    //                         .database()
    //                         .ref(
    //                             "users/" +
    //                             this.state.currentUserId +
    //                             "/following/" +
    //                             filteredSec[0][1].push_key
    //                         )
    //                         .remove();
    //                 }
    //             }
    //             firebase
    //                 .database()
    //                 .ref(
    //                     "users/" +
    //                     currentUser.user_id +
    //                     "/followed_by/" +
    //                     filtered[0][1].push_key
    //                 )
    //                 .remove();
    //         } else {
    //             let pushFirst = firebase
    //                 .database()
    //                 .ref("users/" + this.state.currentUserId + "/following")
    //                 .push().key;
    //             let pushSecond = firebase
    //                 .database()
    //                 .ref("users/" + currentUser.user_id + "/followed_by")
    //                 .push().key;
    //             let pushSecondNew = firebase
    //                 .database()
    //                 .ref("users/" + currentUser.user_id + "/newFollowersList")
    //                 .push().key;
    //             let newFollowers = 0;
    //             let obj = {
    //                 user_id: currentUser.user_id,
    //                 push_key: pushFirst,
    //             };
    //             let objSec = {
    //                 user_id: this.state.currentUserId,
    //                 push_key: pushSecond,
    //             };
    //             let objThird = {
    //                 user_id: this.state.currentUserId,
    //                 push_key: pushSecondNew,
    //             };
    //             if (currentUser.newFollowers) {
    //                 newFollowers = Number(currentUser.newFollowers);
    //             }
    //             newFollowers = newFollowers + 1;
    //             firebase
    //                 .database()
    //                 .ref("users/" + this.state.currentUserId + "/following/" + pushFirst)
    //                 .set(obj);
    //             firebase
    //                 .database()
    //                 .ref(
    //                     "users/" +
    //                     currentUser.user_id +
    //                     "/followed_by/" +
    //                     pushSecond
    //                 )
    //                 .set(objSec);
    //             firebase
    //                 .database()
    //                 .ref("users/" + currentUser.user_id + "/newFollowers")
    //                 .set(newFollowers);
    //             firebase
    //                 .database()
    //                 .ref(
    //                     "users/" +
    //                     currentUser.user_id +
    //                     "/newFollowersList/" +
    //                     pushSecondNew
    //                 )
    //                 .set(objThird);
    //         }
    //     } else {
    //         let pushFirst = firebase
    //             .database()
    //             .ref("users/" + this.state.currentUserId + "/following")
    //             .push().key;
    //         let pushSecond = firebase
    //             .database()
    //             .ref("users/" + currentUser.user_id + "/followed_by")
    //             .push().key;
    //         let pushSecondNew = firebase
    //             .database()
    //             .ref("users/" + currentUser.user_id + "/newFollowersList")
    //             .push().key;
    //         let newFollowers = 0;
    //         let obj = {
    //             user_id: currentUser.user_id,
    //             push_key: pushFirst,
    //         };
    //         let objSec = {
    //             user_id: this.state.currentUserId,
    //             push_key: pushSecond,
    //         };
    //         let objThird = {
    //             user_id: this.state.currentUserId,
    //             push_key: pushSecondNew,
    //         };
    //         if (currentUser.newFollowers) {
    //             newFollowers = Number(currentUser.newFollowers);
    //         }
    //         newFollowers = newFollowers + 1;
    //         firebase
    //             .database()
    //             .ref("users/" + this.state.currentUserId + "/following/" + pushFirst)
    //             .set(obj);
    //         firebase
    //             .database()
    //             .ref(
    //                 "users/" +
    //                 currentUser.user_id +
    //                 "/followed_by/" +
    //                 pushSecond
    //             )
    //             .set(objSec);
    //         firebase
    //             .database()
    //             .ref("users/" + currentUser.user_id + "/newFollowers")
    //             .set(newFollowers);
    //         firebase
    //             .database()
    //             .ref(
    //                 "users/" +
    //                 currentUser.user_id +
    //                 "/newFollowersList/" +
    //                 pushSecondNew
    //             )
    //             .set(objThird);
    //     }
    // }


    truncateAndSanitize(html, maxLength) {
        // Use DOMPurify to sanitize the HTML content.
        const sanitizedHTML = DOMPurify.sanitize(html);

        if (sanitizedHTML.length <= maxLength) {
            return sanitizedHTML;
        } else {
            return sanitizedHTML.substring(0, maxLength) + '...';
        }
    };

    createMarkup(e) {
        if (e) {
            if (window.innerWidth > 1008) {
                const truncatedDescription = this.truncateAndSanitize(e, window.innerWidth < 1008 ? 80 : 320);

                return { __html: truncatedDescription };
            } else {
                return { __html: e };
            }
        }
    }

    createDescPopup(e) {
        if (e) {
            return { __html: e };
        }
    }

    render() {
        return (
            <div className={`third-section-mhn ${window.innerWidth < 1008 && "tsmhn-global"}`}>

                {/* Doorlopende Verzilver */}
                <div
                    className="verzilver-popup-white-opacity"
                    style={{
                        opacity: this.state.verzilverPopup ? 1 : 0,
                        pointerEvents: this.state.verzilverPopup ? "all" : "none",
                    }}
                ></div>
                <div
                    className="verzilver-popup-white"
                    style={{
                        opacity: this.state.verzilverPopup ? 1 : 0,
                        pointerEvents: this.state.verzilverPopup ? "all" : "none",
                    }}
                >
                    <CloseIcon
                        className="close-hpw"
                        onClick={() => this.setState({ verzilverPopup: false })}
                    />
                    <LockOpenIcon className="lock-hpw" />
                    <p className="heading-hpw">VALIDEER KORTINGSVOUCHER</p>
                    <p className="desc-hpw">
                        Leuk dat je gebruik wil maken van
                        <br />
                        onze kortingsvoucher. Deze kun je
                        <br />
                        valideren vanuit je orderoverzicht.
                        <br />
                        {/* <br />
                        <b>Let op</b>: de kortingsvoucher blijft
                        <br />
                        24 uur voor jou gereserveerd
                        <br /> */}
                        <br />
                        Klik op OK om de kortingsvoucher
                        <br />
                        in je orderoverzicht te plaatsen.
                    </p>
                    {this.state.currentActie && this.checkForButtonsVerzilverKortingsVoucher(this.state.currentActie)}
                </div>
                {/* Doorlopende verzilver */}


                {this.state.campaignsSorted.length > 0 &&
                    this.state.campaignsSorted.map((g) => {
                        if (g === "kortings_voucher") {
                            return (
                                <div style={{ position: "relative" }}>
                                    {window.innerWidth < 1008 && (<div className="top-userinfo-tsco">
                                        <img src={this.getUserProfilePic(this.state.currentCampaignHere[0].created_by)} onClick={() => {
                                            localStorage.setItem("scrollToTop", true)
                                            this.redirectUser(this.state.currentCampaignHere[0].created_by)
                                        }} />
                                        <div className="cont-tus-tsco">
                                            <p onClick={() => {
                                                localStorage.setItem("scrollToTop", true)
                                                this.redirectUser(this.state.currentCampaignHere[0].created_by)
                                            }}>{this.getUserName(this.state.currentCampaignHere[0].created_by)}</p>
                                            <p>{this.getUserCity(this.state.currentCampaignHere[0].created_by)}</p>
                                        </div>
                                        <div className="icons-main-lcl">
                                            {this.state.currentUserHere.user_id === this.state.currentCampaignHere[0].created_by && <div className="icons-lcltsmhnc" style={{ display: "inline-block", marginRight: 0 }} onClick={() => {
                                                if (window.innerWidth > 1008) {
                                                    this.props.onEditOpen()
                                                } else {
                                                    localStorage.setItem("kortingsVoucherInfo", JSON.stringify(this.state.currentCampaignHere[0]))
                                                    history.push(`/editor/kortings_voucher`)
                                                }
                                            }}>
                                                <AiOutlineEdit style={{ color: "white" }} />
                                            </div>}
                                            {this.state.currentUserHere.user_id !== this.state.currentCampaignHere[0].created_by && this.state.currentUserId && this.checkForFollowIcon(this.state.currentCampaignHere[0].created_by)}
                                        </div>
                                    </div>)}
                                    <div className='tsmhn-container'>
                                        <div className='left-tsmhnc'>
                                            <div className='container-ltsmhnc'>
                                                {this.state.currentCampaignHere[0].kortingsVoucherGiveaway ? (<p>Giveaway</p>) : (<p>Voucher</p>)}
                                                <p>{this.state.currentCampaignHere[0].title}</p>
                                                {!this.state.currentCampaignHere[0].active && (<p style={{ color: "#C17F6B" }}>Actie is niet actief</p>)}
                                                <p>
                                                    <div dangerouslySetInnerHTML={this.createMarkup(this.state.currentCampaignHere[0].desc)} className="description-content-cltsm" onClick={() => {
                                                        const truncatedDescription = this.truncateAndSanitize(this.state.currentCampaignHere[0].desc, window.innerWidth < 1008 ? 80 : 180);
                                                        if (window.innerWidth < 1008 && truncatedDescription.length > 80) {
                                                            this.setState({ showDescriptionPopup: true })
                                                        } else if (truncatedDescription.length > 180) {
                                                            this.setState({ showDescriptionPopupDesktop: true })
                                                        }
                                                    }}></div>
                                                </p>
                                                {/* {(window.innerWidth < 1008 && this.state.currentCampaignHere[0].desc.length > 80) ? (<p onClick={() => {
                                                    this.setState({ showDescriptionPopup: true })
                                                }}>{this.state.currentCampaignHere[0].desc.substr(0, 80)}...</p>) : (window.innerWidth > 1008 && this.state.currentCampaignHere[0].desc.length > 180) ? (<p onClick={() => {
                                                    this.setState({ showDescriptionPopupDesktop: true })
                                                }}>{this.state.currentCampaignHere[0].desc.substr(0, 180)}...</p>) : (<p>{this.state.currentCampaignHere[0].desc}</p>)} */}

                                                {!(this.state.currentCampaignHere[0].timerDateWaiting && this.state.currentCampaignHere[0].timerTimeWaiting && new Date(Date.parse(this.state.currentCampaignHere[0].isoTimeTimerBtn)).getTime() > new Date().getTime()) && (!this.state.currentCampaignHere[0].kortingsVoucherPrijs && !this.state.currentCampaignHere[0].kortingsVoucherPercentage && this.state.currentCampaignHere[0].isoTimeTimerBtnEndTime && new Date(this.state.currentCampaignHere[0].isoTimeTimerBtnEndTime).getTime() > new Date().getTime()) && (<p>
                                                    {<KortingsVoucherTimer timerToDo={this.state.currentCampaignHere[0].isoTimeTimerBtnEndTime} salePrice={this.state.currentCampaignHere[0].kortingsVoucherSalePrice} closeScreen={() => {
                                                        this.setState({ updatePricing: true })
                                                    }} />}
                                                </p>)}
                                                {this.state.currentCampaignHere[0].kortingsVoucherPrijs && (<p style={{ marginTop: 10 }}>
                                                    <font>
                                                        €
                                                        <NumberFormatter
                                                            toFormat={this.state.currentCampaignHere[0].amount}
                                                        />{" "}
                                                        Korting
                                                    </font>
                                                </p>)}
                                                {this.state.currentCampaignHere[0].kortingsVoucherPercentage && (<p style={{ marginTop: 10 }}>
                                                    <font>
                                                        Korting{" "}
                                                        {this.state.currentCampaignHere[0].amount}
                                                        {" "}%
                                                    </font>
                                                </p>)}
                                                {(!this.state.currentCampaignHere[0].kortingsVoucherPrijs && !this.state.currentCampaignHere[0].kortingsVoucherPercentage && this.state.currentCampaignHere[0].isoTimeTimerBtnEndTime) ? (<p style={{ marginTop: 10 }}>
                                                    {(new Date(this.state.currentCampaignHere[0].isoTimeTimerBtnEndTime).getTime() > new Date().getTime()) ? (
                                                        <>
                                                            <s>
                                                                €
                                                                <NumberFormatter
                                                                    toFormat={this.state.currentCampaignHere[0].kortingsVoucherNormalPrice}
                                                                />{" "}
                                                            </s>
                                                            <div style={{ width: 15, height: 1, display: "inline-block" }}></div>
                                                            <font>
                                                                €
                                                                <NumberFormatter
                                                                    toFormat={this.state.currentCampaignHere[0].kortingsVoucherSalePrice}
                                                                />{" "}
                                                            </font>
                                                        </>
                                                    ) : <>
                                                        €
                                                        <NumberFormatter
                                                            toFormat={this.state.currentCampaignHere[0].kortingsVoucherNormalPrice}
                                                        />{" "}
                                                    </>}
                                                </p>) : this.state.currentCampaignHere[0].kortingsVoucherGiveaway ? <p>Free</p> : (!this.state.currentCampaignHere[0].kortingsVoucherPrijs && !this.state.currentCampaignHere[0].kortingsVoucherPercentage) && (<p style={{ marginTop: 10 }}>
                                                    €
                                                    <NumberFormatter
                                                        toFormat={this.state.currentCampaignHere[0].kortingsVoucherSalePrice}
                                                    />{" "}
                                                </p>)}
                                                {/* <button>BOEK NU</button> */}
                                                {this.state.currentCampaignHere[0].active && <div style={{ position: "relative" }}>
                                                    {(this.state.currentUserId || (!this.state.currentUserId && !this.state.currentCampaignHere[0].showTimerToGuests)) && (this.state.currentCampaignHere[0].timerDateWaiting && this.state.currentCampaignHere[0].timerTimeWaiting && new Date(Date.parse(this.state.currentCampaignHere[0].isoTimeTimerBtn)).getTime() > new Date().getTime()) && <ButtonTimerCampaign mobileNewSpacing={true} showLabelAbsolute={true} labelOnOwnPage={true} timerToDo={new Date(Date.parse(this.state.currentCampaignHere[0].isoTimeTimerBtn)).getTime()} atProduct={this.state.currentCampaignHere[0]} currentUserHere={this.state.currentUserHere} guestUserCart={this.state.guestUserCart} timeToCalculate={new Date(Number(this.state.currentCampaignHere[0].timerDateWaiting.split("-")[0]), Number(this.state.currentCampaignHere[0].timerDateWaiting.split("-")[1] - 1), Number(this.state.currentCampaignHere[0].timerDateWaiting.split("-")[2]), Number(this.state.currentCampaignHere[0].timerTimeWaiting.split(":")[0]), Number(this.state.currentCampaignHere[0].timerTimeWaiting.split(":")[1]), 0).getTime()}
                                                        closeScreen={() => {
                                                            this.setState({ updatePricing: true })
                                                        }}
                                                    />}
                                                    {this.checkForVerzilverKortingsVoucher(this.state.currentCampaignHere[0])}
                                                    <div className="image-frontkortingsvoucher addIconCart">
                                                        <AddIcon />
                                                    </div>
                                                </div>}
                                                {window.innerWidth > 1008 ? (<div className='labels-cltsmhnc'>
                                                    <label onClick={() => {
                                                        this.setState({ showDescriptionPopupDesktop: true })
                                                    }}>OMSCHRIJVING</label>
                                                    {this.getUseOfVoucherKortingsVoucher(
                                                        this.state.currentCampaignHere[0]
                                                    )}
                                                    {this.getfilelink(
                                                        this.state.currentCampaignHere[0]
                                                    )}
                                                    {/* Bookmark Icon */}
                                                    {this.state.currentUserId && this.checkForBookMarkIconVouchers(
                                                        this.state.currentCampaignHere[0]
                                                    )}
                                                    {this.state.currentUserHere.user_id === this.state.currentCampaignHere[0].created_by && <div className="icons-lcltsmhnc" onClick={() => {
                                                        if (window.innerWidth > 1008) {
                                                            this.props.onEditOpen()
                                                        } else {
                                                            localStorage.setItem("kortingsVoucherInfo", JSON.stringify(this.state.currentCampaignHere[0]))
                                                            history.push(`/editor/kortings_voucher`)
                                                        }
                                                    }}>
                                                        <AiOutlineEdit style={{ color: "#C17F6B" }} />
                                                    </div>}
                                                    {/* {this.state.kortingsVoucherInfoMobile && (<label onClick={() => {
                                                        this.setState({ showInfoPopup: true })
                                                    }}>
                                                        <VscInfo />
                                                    </label>)} */}
                                                </div>) : (<div className="icons-cltsmhnc-mob">
                                                    <div className="icons-lcltsmhnc">
                                                        <VscInfo onClick={() => {
                                                            this.setState({ showMoreDotsPopup: true })
                                                        }} />
                                                    </div>
                                                    {this.state.currentUserId && this.checkForBookMarkIconVouchers(
                                                        this.state.currentCampaignHere[0]
                                                    )}
                                                </div>)}
                                            </div>
                                        </div>
                                        <div className='right-tsmhnc' style={{ backgroundImage: this.state.currentCampaignHere[0].image ? `url("${(window.innerWidth < 1008 && this.state.currentCampaignHere[0].image_mobile) ? this.state.currentCampaignHere[0].image_mobile : this.state.currentCampaignHere[0].image}")` : "none", overflow: "hidden" }}>
                                            <div className="overlay-rtsmhnc"></div>
                                        </div>
                                    </div>
                                </div>
                            );
                        }
                    })}
                {this.state.useOfVouchersPopup && (
                    <div className="overlay-popup-vdd"></div>
                )}
                {this.state.voucherExceptionsPopup && (
                    <div className="overlay-popup-vdd"></div>
                )}
                {this.state.termsTextPopup && <div className="overlay-popup-vdd"></div>}
                {this.state.showDescriptionPopupDesktop && <div className="overlay-popup-vdd"></div>}
                {this.state.showInfoPopup && <div className="overlay-popup-vdd"></div>}
                <div
                    className="card-popup-vdd"
                    style={{
                        opacity: this.state.useOfVouchersPopup ? 1 : 0,
                        pointerEvents: this.state.useOfVouchersPopup ? "all" : "none",
                    }}
                >
                    <CloseIcon
                        onClick={() => {
                            this.setState({ useOfVouchersPopup: false });
                        }}
                    />
                    <p className="main-title-cpv">Gebruik van voucher</p>
                    <p className="main-desc-cpv">{this.state.currentUseOfVoucher}</p>
                </div>
                <div
                    className="card-popup-vdd"
                    style={{
                        opacity: this.state.voucherExceptionsPopup ? 1 : 0,
                        pointerEvents: this.state.voucherExceptionsPopup ? "all" : "none",
                    }}
                >
                    <CloseIcon
                        onClick={() => {
                            this.setState({ voucherExceptionsPopup: false });
                        }}
                    />
                    <p className="main-title-cpv">UITZONDERINGEN</p>
                    <p className="main-desc-cpv">{this.state.currentVoucherExceptions}</p>
                </div>
                <div
                    className="card-popup-vdd"
                    style={{
                        opacity: this.state.termsTextPopup ? 1 : 0,
                        pointerEvents: this.state.termsTextPopup ? "all" : "none",
                    }}
                >
                    <CloseIcon
                        onClick={() => {
                            this.setState({ termsTextPopup: false });
                        }}
                    />
                    <p className="main-title-cpv">VOORWAARDEN</p>
                    <p className="main-desc-cpv">{this.state.currentTermsText}</p>
                </div>
                <div
                    className="card-popup-vdd cpv-desc"
                    style={{
                        opacity: this.state.showDescriptionPopupDesktop ? 1 : 0,
                        pointerEvents: this.state.showDescriptionPopupDesktop ? "all" : "none",
                        width: "100%",
                        maxWidth: 900,
                    }}
                >
                    <CloseIcon
                        onClick={() => {
                            this.setState({ showDescriptionPopupDesktop: false });
                        }}
                    />
                    <img src={descriptionLogoTop} alt="logo-desc" style={{ widows: 90, height: 45, objectFit: "contain" }} />
                    <p className="main-title-cpv">OMSCHRIJVING</p>
                    <p className="main-desc-cpv">
                        {this.state.currentCampaignHere.length > 0 && (<div dangerouslySetInnerHTML={this.createDescPopup(this.state.currentCampaignHere[0].desc)}></div>)}
                    </p>
                </div>

                <div
                    className="card-popup-vdd"
                    style={{
                        opacity: this.state.showInfoPopup ? 1 : 0,
                        pointerEvents: this.state.showInfoPopup ? "all" : "none",
                        width: "100%",
                        maxWidth: 900,
                    }}
                >
                    <CloseIcon
                        onClick={() => {
                            this.setState({ showInfoPopup: false });
                        }}
                    />
                    <p className="main-title-cpv">INFORMATIE</p>
                    <p className="main-desc-cpv">
                        {this.state.kortingsVoucherInfoMobile}
                    </p>
                </div>

                {this.state.showDescriptionPopup && (
                    <div
                        className="overlay-asp"
                        onClick={() => {
                            this.setState({
                                showOptionsToAdd: false,
                                showDescriptionPopup: false,
                            });
                        }}
                    ></div>
                )}
                {this.state.currentCampaignHere.length > 0 && (<div
                    className="add-stuff-popup"
                    style={{
                        height: this.state.showDescriptionPopup ? "auto" : 0,
                        minHeight: this.state.showDescriptionPopup ? 350 : 0,
                        pointerEvents: this.state.showDescriptionPopup ? "all" : "none",
                        overflow: !this.state.showDescriptionPopup && "hidden",
                    }}
                >
                    <CloseIcon
                        onClick={() => {
                            this.setState({ showDescriptionPopup: false });
                        }}
                    />
                    <p
                        className="desc-heading-asp"
                        style={{ fontSize: 11, textTransform: "none" }}
                    >
                        Omschrijving
                    </p>
                    <p className="desc-content-asp">
                        <div dangerouslySetInnerHTML={this.createDescPopup(this.state.currentCampaignHere[0].desc)}></div>
                    </p>
                </div>)}

                {this.state.showMoreDotsPopup && (
                    <div
                        className="overlay-asp"
                        onClick={() => {
                            this.setState({
                                showOptionsToAdd: false,
                                showMoreDotsPopup: false,
                            });
                        }}
                    ></div>
                )}
                {this.state.currentCampaignHere.length > 0 && (<div
                    className="add-stuff-popup"
                    style={{
                        height: this.state.showMoreDotsPopup ? "auto" : 0,
                        minHeight: this.state.showMoreDotsPopup ? 350 : 0,
                        pointerEvents: this.state.showMoreDotsPopup ? "all" : "none",
                        overflow: !this.state.showMoreDotsPopup && "hidden",
                    }}
                >
                    {this.state.openDetailedInner ? (<>
                        <ChevronLeft
                            onClick={() => {
                                this.setState({
                                    openDetailedInner: false,
                                    headingOdiMain: "",
                                    paraOdiMain: "",
                                });
                            }}
                            style={{
                                right: "auto",
                                left: 10
                            }}
                        />
                        <p
                            className="desc-heading-asp"
                            style={{ fontSize: 11, textTransform: "none", marginTop: 30 }}
                        >
                            {this.state.headingOdiMain}
                        </p>
                        <p className="desc-content-asp">
                            {this.state.paraOdiMain}
                        </p>
                    </>) : (<>
                        <CloseIcon
                            onClick={() => {
                                this.setState({ showMoreDotsPopup: false });
                            }}
                        />
                        {this.getAboutKortingsVoucherMobile()}
                        {this.getUseOfVoucherKortingsVoucherMobile()}
                        {this.getfilelinkMobile()}
                    </>)}
                </div>)}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducers.user,
        product: state.campaignsReducer.product,
        vouchers: state.campaignsReducer.vouchers,
        global_values: state.globalReducers.global_values,
        guest_user_cart: state.cartReducer.guest_user_cart,
        global_campaigns: state.campaignsReducer.global_campaigns,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateUser: (user) => dispatch(updateUser(user)),
        updateGuestUserCart: (guest_user_cart) => dispatch(updateGuestUserCart(guest_user_cart)),
        updateGuestUserCartTime: (guest_user_cart_time) => dispatch(updateGuestUserCartTime(guest_user_cart_time)),
        updateGuestUserCartExtendOpportunity: (guest_user_cart_extend_opportunity) => dispatch(updateGuestUserCartExtendOpportunity(guest_user_cart_extend_opportunity)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VoucherNewSection);
