import React, { Component } from 'react'
import { connect } from 'react-redux';

export class RecurringTimerAutoAvailable extends Component {
    constructor() {
        super()
        this.state = {
            intervalTimer: "",
            availabilityTime: "",
            productTimerEndOn: "",
            availableMaxTill: "",
        }
    }

    componentDidMount() {
        this.interval = setInterval(
            () => this.setState({ timeCurrent: Date.now() }),
            1000
        );

        if (this.props.recurringData) {
            if (this.props.recurringData.recurringType === "daily") {
                let timerNextDate = new Date()
                let timerMaxDate = new Date()

                timerNextDate.setHours(this.props.recurringData.recurringBannerTime.split(":")[0] - 1)
                timerNextDate.setMinutes(this.props.recurringData.recurringBannerTime.split(":")[1])
                timerNextDate.setSeconds("00")


                timerMaxDate.setHours(this.props.recurringData.recurringBannerTime.split(":")[0])
                timerMaxDate.setMinutes(this.props.recurringData.recurringBannerTime.split(":")[1])
                timerMaxDate.setSeconds("00")

                this.setState({ availabilityTime: timerNextDate, availableMaxTill: timerMaxDate })
            } else {
                let availableDate = new Date(this.props.weeklyAvailableDate)
                availableDate.setDate(availableDate.getDate() - 1)
                this.setState({ availabilityTime: availableDate })
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.recurringData !== prevProps.recurringData || this.props.weeklyAvailableDate !== prevProps.weeklyAvailableDate) {
            if (this.props.recurringData.recurringType === "daily") {
                let currentDate = new Date()
                let timerNextDate = new Date()
                let timerMaxDate = new Date()

                timerNextDate.setHours(this.props.recurringData.recurringBannerTime.split(":")[0] - 1)
                timerNextDate.setMinutes(this.props.recurringData.recurringBannerTime.split(":")[1])
                timerNextDate.setSeconds("00")

                timerMaxDate.setHours(this.props.recurringData.recurringBannerTime.split(":")[0])
                timerMaxDate.setMinutes(this.props.recurringData.recurringBannerTime.split(":")[1])
                timerMaxDate.setSeconds("00")

                this.setState({ availabilityTime: timerNextDate, availableMaxTill: timerMaxDate })
            } else {
                let availableDate = new Date(this.props.weeklyAvailableDate)
                availableDate.setDate(availableDate.getDate() - 1)
                this.setState({ availabilityTime: availableDate })
            }
        }
    }

    getMonthName(i) {
        i = Number(i);
        if (i === 1) {
            return "januari";
        } else if (i === 2) {
            return "februari";
        } else if (i === 3) {
            return "maart";
        } else if (i === 4) {
            return "april";
        } else if (i === 5) {
            return "Mei";
        } else if (i === 6) {
            return "juni";
        } else if (i === 7) {
            return "juli";
        } else if (i === 8) {
            return "augustus";
        } else if (i === 9) {
            return "september";
        } else if (i === 10) {
            return "oktober";
        } else if (i === 11) {
            return "november";
        } else if (i === 12) {
            return "december";
        }
    }

    calculateTimeStamp() {
        if (this.state.availabilityTime) {
            var date = new Date(this.state.availabilityTime);

            let dateFuture = date.getTime();

            let dateNow = new Date().getTime();

            if (dateFuture > dateNow) {
                return (
                    <>
                    </>
                );
            } else if (this.state.availableMaxTill && dateNow > new Date(this.state.availableMaxTill).getTime()) {
                return (
                    <>
                    </>
                );
            } else {
                if (new Date(this.props.recurringData.storedAt).getDate() === new Date().getDate() && new Date(this.props.recurringData.storedAt).getMonth() === new Date().getMonth() && new Date(this.props.recurringData.storedAt).getFullYear() === new Date().getFullYear()) {
                    console.log("workg")
                } else {
                    if (this.props.showRecurringBanner) {
                        this.props.showRecurringBanner()
                    }
                }
                clearInterval(this.interval);
                return <></>;
            }
        } else {
            return <></>;
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval)
    }

    render() {
        return (
            this.calculateTimeStamp()
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducers.user,
    };
};



export default connect(
    mapStateToProps,
)(RecurringTimerAutoAvailable);