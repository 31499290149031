import React, { Component } from "react";
import { updateGlobalStuff } from "../Redux/actions/authActions";
import { connect } from "react-redux";
import firebase from "../Config/Firebase";
import AdminNavbar from "./AdminNavbar";

export class AccountApprovals extends Component {
    constructor(props) {
        super(props);

        this.state = {
            allUsers: [],
            noData: false,
        };
    }

    componentDidMount() {
        const loaderId = document.getElementById("main-page-loader-id");
        loaderId.style.display = "none";

        firebase
            .database()
            .ref("users")
            .on("value", (data) => {
                let a = data.val();
                let arr = [];
                if (a && !this.state.stopTakingData) {
                    let objected = Object.entries(a);
                    objected.map((u) => {
                        if (u[1].pending_admin_approve) {
                            arr.push(u[1]);
                        }
                    });
                    if (arr.length === 0) {
                        this.setState({ noData: true })
                    }
                    this.setState({
                        allUsers: arr,
                    });
                } else {
                    this.setState({ allUsers: [] });
                }
            });
    }

    render() {
        return (
            <div className="sorting-mainadmin">
                <AdminNavbar accountApprovals={true} />
                <div className="container-sma containeraccountcreatoradmin">
                    <div className="info-div-editor">
                        <div className="group-textbox" style={{ textAlign: "center" }}>
                            <label style={{ fontSize: 16, fontWeight: 600 }}>
                                ACCOUNT APPROVALS
                            </label>
                        </div>
                        <div className="table-account-creator">
                            <div className="container-accounts-holder">
                                {this.state.noData ? <p style={{ fontFamily: "Poppins", fontSize: 15, letterSpacing: 1 }}>No pending approvals</p> : this.state.allUsers.length > 0 && this.state.allUsers.map((e) => {
                                    return (<div className="cah-inner-main">
                                        <div className="top-cahimain">
                                            <img src={e.profile_image ? e.profile_image : "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9"} />
                                            <p>{e.company_name}</p>
                                        </div>
                                        <div className="button-cahimain">
                                            <button onClick={() => {
                                                firebase.database().ref(`users/${e.user_id}/pending_admin_approve`).remove()
                                                firebase.database().ref(`users/${e.user_id}/approved_by_admin`).set(true)
                                            }}>Approve</button>
                                        </div>
                                    </div>)
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        global_values: state.globalReducers.global_values,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateGlobalStuff: (global_values) =>
            dispatch(updateGlobalStuff(global_values)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountApprovals);
