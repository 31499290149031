import React, { Component } from 'react'
import firebase from '../../Config/Firebase'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import './index.css'

export class GlobalPageAdminSettings extends Component {

    constructor() {
        super()
        this.state = {
            charityText: "",
        }
    }


    uploadAboutGlobalImage(e) {
        let a = e.target.files[0]
        if (a.size / 1024 / 1024 < 3) {
            this.setState({ contactImageBigger: false })
            firebase.storage().ref().child(`bhodi_home_images/${a.name}${new Date().getTime()}`).put(a)
                .then((snapshot) => {
                    snapshot.ref.getDownloadURL().then((snapUrl) => {
                        firebase.database().ref("bhodi_home/about_url").set(snapUrl)
                    })
                })
        } else {
            this.setState({ contactImageBigger: true })
        }
    }


    render() {
        return (
            <div className="globalpageadminsettings">
                <div className="container-gpas">
                    <div className="inner-cgpas">
                        <p>ABOUT</p>
                        <textarea value={this.state.charityText} onChange={(e) => {
                            this.setState({ charityText: e.target.value })
                        }}></textarea>
                        <div className="icons-icgpas">
                            <input type="file" accept="image/*,video/*" onChange={(e) => this.uploadAboutGlobalImage(e)} />
                            <AddCircleOutlineIcon />
                        </div>
                    </div>
                    <div className="inner-cgpas">
                        <p>SOCIALS</p>
                        <textarea value={this.state.charityText} onChange={(e) => {
                            this.setState({ charityText: e.target.value })
                        }}></textarea>
                        <div className="icons-icgpas">
                            <input type="file" accept="image/*,video/*" onChange={(e) => this.uploadAboutGlobalImage(e)} />
                            <AddCircleOutlineIcon />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default GlobalPageAdminSettings
