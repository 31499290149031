import React, { Component } from "react";
import "react-sortable-tree/style.css";
import countries from "../DataJSON/countries.json";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CloseIcon from "@material-ui/icons/Close";
import AdminNavbar from "./AdminNavbar";

export class Translator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedCountry: {
        id: 155,
        sortname: "NL",
        name: "Netherlands",
      },
    };
  }

  componentDidMount() {
    const loaderId = document.getElementById("main-page-loader-id");
    loaderId.style.display = "none";
    console.log("working");
  }

  render() {
    return (
      <div className="sorting-mainadmin">
        <AdminNavbar translatePage={true} />
        <div className="top-languagetranslator">
          <div className="dropdown-tlt">
            <img
              src={`https://www.countryflags.io/${this.state.selectedCountry.sortname}/flat/64.png`}
            />
            <p>{this.state.selectedCountry.name}</p>
            <ExpandMoreIcon />
          </div>
        </div>
        <div className="right-overlay-modal-tlt"></div>
        <div className="right-menu-modal-tlt">
          <div className="heading-ito-mtb">
            <CloseIcon
              onClick={() => {
                this.setState({ bottomModal: false });
              }}
            />
            <p>Countries</p>
          </div>
          <div className="search-button-city">
            <input
              type="text"
              placeholder="Zoek"
              onChange={(e) => {
                if (e.target.value === "") {
                  this.setState({
                    allCitiesFiltered: [],
                    noFilteredCities: false,
                  });
                } else {
                  console.log(e.target.value);
                }
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Translator;
