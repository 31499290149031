import React, { Component } from 'react'
import DeleteIcon from "@material-ui/icons/Delete";
import Switch from "@material-ui/core/Switch";
import { BiCalendar, BiChevronRight } from "react-icons/bi"
import { MdTimer } from "react-icons/md";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { RiCheckboxMultipleBlankLine, RiDeleteBinLine } from "react-icons/ri";
import { FiChevronLeft } from "react-icons/fi";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import PublishIcon from "@material-ui/icons/Publish";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import firebase from "../../Config/Firebase"
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import {
    updateBhodiProducts,
} from "../../Redux/actions/authActions";
import { connect } from 'react-redux';
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';
import SearchIcon from "@material-ui/icons/Search";

export class AddEditProduct extends Component {

    constructor() {
        super()
        this.state = {
            all_categories: [],
            allImagesCurrentSectorStock: [],
            allImagesStock: [],
            descInfoGiveaway: "sankfsankjf sajf lsaj fljas lfj asj fjas jf asjf ahs fha sjlf lsa fjas lhf ash fsah fhsajbfkasfkjasbfjsa fhsa hf aslj fhasfsa",
            maxAmountOfText: 135,
            selectedMain: [],
            allSectors: [],
            productBlock: [],
            productImageType: "uploadfromcomp",
            productImageAlert: false,
            productDesc: "",
            productImage: [],
            dropFileProducts: [],
            productTitle: "",
            productSku: "",
            productUnlimited: false,
            allVariations: [],
            isLoading: false,
            productPrice: "",
            currentOpenedCheck: true,
            isSpecialProduct: false,
            availableForCompanyGuest: false,
            onlyAvailableViaURL: false,
            availableForGuests: false,
            sizesActive: false,
            currentGender: [],
            allAttributes: [],
            featuredImage: 0,
            allProductsToCheckSwap: [],
            timerTimeWaiting: "",
            timerDateWaiting: "",
            makeTodayTimer: "",
            allTimes: [
                { label: "01:00" },
                { label: "01:15" },
                { label: "01:30" },
                { label: "01:45" },
                { label: "02:00" },
                { label: "02:15" },
                { label: "02:30" },
                { label: "02:45" },
                { label: "03:00" },
                { label: "03:15" },
                { label: "03:30" },
                { label: "03:45" },
                { label: "04:00" },
                { label: "04:15" },
                { label: "04:30" },
                { label: "04:45" },
                { label: "05:00" },
                { label: "05:15" },
                { label: "05:30" },
                { label: "05:45" },
                { label: "06:00" },
                { label: "06:15" },
                { label: "06:30" },
                { label: "06:45" },
                { label: "07:00" },
                { label: "07:15" },
                { label: "07:30" },
                { label: "07:45" },
                { label: "08:00" },
                { label: "08:15" },
                { label: "08:30" },
                { label: "08:45" },
                { label: "09:00" },
                { label: "09:15" },
                { label: "09:30" },
                { label: "09:45" },
                { label: "10:00" },
                { label: "10:15" },
                { label: "10:30" },
                { label: "10:45" },
                { label: "11:00" },
                { label: "11:15" },
                { label: "11:30" },
                { label: "11:45" },
                { label: "12:00" },
                { label: "12:15" },
                { label: "12:30" },
                { label: "12:45" },
                { label: "13:00" },
                { label: "13:15" },
                { label: "13:30" },
                { label: "13:45" },
                { label: "14:00" },
                { label: "14:15" },
                { label: "14:30" },
                { label: "14:45" },
                { label: "15:00" },
                { label: "15:15" },
                { label: "15:30" },
                { label: "15:45" },
                { label: "16:00" },
                { label: "16:15" },
                { label: "16:30" },
                { label: "16:45" },
                { label: "17:00" },
                { label: "17:15" },
                { label: "17:30" },
                { label: "17:45" },
                { label: "18:00" },
                { label: "18:15" },
                { label: "18:30" },
                { label: "18:45" },
                { label: "19:00" },
                { label: "19:15" },
                { label: "19:30" },
                { label: "19:45" },
                { label: "20:00" },
                { label: "20:15" },
                { label: "20:30" },
                { label: "20:45" },
                { label: "21:00" },
                { label: "21:15" },
                { label: "21:30" },
                { label: "21:45" },
                { label: "22:00" },
                { label: "22:15" },
                { label: "22:30" },
                { label: "22:45" },
                { label: "23:00" },
                { label: "23:15" },
                { label: "23:30" },
                { label: "23:45" },
                { label: "24:00" },
            ],
            currentUserId: "",
            allCompaniesForProductsFilters: [],
            allCompaniesForProducts: [],
            currentCompanyIdForImport: "",
            productForStockCheck: "",
            productForPage: "",
            allPages: [],
        }
    }

    componentDidMount() {
        let newDateTimer = new Date();
        let monthTodayTimer = newDateTimer.getMonth() + 1;
        let dateTodayToMakeTimer = newDateTimer.getDate();
        let makeTodayTimer = `${newDateTimer.getFullYear()}-${monthTodayTimer}-${dateTodayToMakeTimer}`;
        let userid = localStorage.getItem("userid")

        this.setState({ makeTodayTimer, currentUserId: userid })

        if (this.props.currentProductToEdit) {
            let productForStockCheck = Object.assign({}, this.props.currentProductToEdit)

            let filteredUserCreator = this.props.user.filter((j) => {
                return j.user_id === productForStockCheck.created_by;
            });

            let product = this.props.currentProductToEdit
            let qty = Number(productForStockCheck.qty)
            let allVariations = []
            if (productForStockCheck.sizesActive) {
                if (productForStockCheck.inCartOf) {
                    let inCartOfObjected = Object.entries(productForStockCheck.inCartOf)
                    productForStockCheck.variations_data.map((e) => {
                        let alreadyInCartCurrent = inCartOfObjected.filter((t) => {
                            return t[1].selectedSize === e.size
                        })
                        if (alreadyInCartCurrent.length > 0) {
                            e.qty = e.qty - alreadyInCartCurrent.length
                        }
                    })
                }
                if (productForStockCheck.verzilvered_by) {
                    let verzilveredObjected = Object.entries(productForStockCheck.verzilvered_by)
                    productForStockCheck.variations_data.map((e) => {
                        let alreadyInCartCurrent = verzilveredObjected.filter((t) => {
                            return t[1].selectedSize === e.size
                        })
                        if (alreadyInCartCurrent.length > 0) {
                            e.qty = e.qty - alreadyInCartCurrent.length
                        }
                    })
                }
                productForStockCheck.variations_data.map((e) => {
                    allVariations.push(e)
                })
            } else {
                if (productForStockCheck && productForStockCheck.inCartOf) {
                    let inCartOf = Object.entries(productForStockCheck.inCartOf)
                    qty = qty - inCartOf.length
                }
                if (productForStockCheck && productForStockCheck.verzilvered_by) {
                    let verzilveredBy = Object.entries(productForStockCheck.verzilvered_by)
                    qty = qty - verzilveredBy.length
                }
            }
            this.setState({
                productTitle: product.title,
                productPrice: product.price,
                productSku: product.sku,
                productNumber: qty,
                currentProductPushKey: product.pushKey,
                currentOpenedCheck: product.active,
                isSpecialProduct: product.isSpecialProduct || false,
                productForPage: product.productForPage || "",
                availableForCompanyGuest: product.availableForCompanyGuest || false,
                onlyAvailableViaURL: product.onlyAvailableViaURL || false,
                availableForGuests: product.availableForGuests || false,
                productForStockCheck,
            })
            if (product.timerTimeWaiting && product.timerDateWaiting) {
                this.setState({
                    timerDateWaiting: product.timerDateWaiting,
                    timerTimeWaiting: product.timerTimeWaiting,
                })
            }
            if (product.desc) {
                this.setState({ productDesc: product.desc })
            } else {
                if (filteredUserCreator.length > 0) {
                    let a = filteredUserCreator[0];
                    if (a.productsInfo) {
                        if (a.productsInfo.globalDesc) {
                            this.setState({
                                productDesc: a.productsInfo.globalDesc,
                            })
                        }
                    }
                }
            }
            if (product.images) {
                this.setState({ productImage: product.images })
            }
            if (product.featuredImage) {
                this.setState({ featuredImage: product.featuredImage })
            }
            if (product.gender) {
                this.setState({ currentGender: product.gender })
            } else {
                if (filteredUserCreator.length > 0) {
                    let a = filteredUserCreator[0];
                    if (a.productsInfo) {
                        if (a.productsInfo.globalGender) {
                            this.setState({
                                currentGender: a.productsInfo.globalGender,
                            })
                        }
                    }
                }
            }
            if (product.variations_data) {
                this.setState({ allVariations: product.variations_data })
            }
            if (product.attributesToShow) {
                this.setState({ allAttributes: product.attributesToShow })
            }
            if (product.sizesActive) {
                this.setState({ sizesActive: true })
            }
            if (product.main_sector && product.main_category) {
                let filtered = this.props.allCategories.filter((y) => {
                    return y[0] === product.main_sector
                })
                if (filtered.length > 0) {
                    this.setState({ all_categories: filtered[0][1], selectedMain: [], mainSector: product.main_sector })
                } else {
                    this.setState({ all_categories: [], selectedMain: [], mainSector: "" })
                }

                this.setState({ selectedMain: product.main_category })
            }
        }

        if (this.props.bhodi_brands) {
            this.setState({ allPages: this.props.bhodi_brands })
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.allCategories !== this.props.allCategories && this.props.currentProductToEdit) {
            let product = this.props.currentProductToEdit
            if (product.main_sector && product.main_category) {
                let filtered = this.props.allCategories.filter((y) => {
                    return y[0] === product.main_sector
                })
                if (filtered.length > 0) {
                    this.setState({ all_categories: filtered[0][1], selectedMain: [], mainSector: product.main_sector })
                } else {
                    this.setState({ all_categories: [], selectedMain: [], mainSector: "" })
                }

                this.setState({ selectedMain: product.main_category })
            }
        }
    }

    saveProduct() {
        let currentObject = ""
        if (this.props.currentProductToEdit) {
            currentObject = Object.assign({}, this.props.currentProductToEdit)
        }
        let isoTimeTimerBtn = ""

        if (this.state.timerTimeWaiting && this.state.timerDateWaiting) {
            isoTimeTimerBtn = new Date(Number(this.state.timerDateWaiting.split("-")[0]), Number(this.state.timerDateWaiting.split("-")[1] - 1), Number(this.state.timerDateWaiting.split("-")[2]), Number(this.state.timerTimeWaiting.split(":")[0]), Number(this.state.timerTimeWaiting.split(":")[1]), 0).toISOString()
        }

        const { mainSector, featuredImage, selectedMain, sizesActive, allVariations, dropFileProducts, currentOpenedCheck, isSpecialProduct, productForPage, availableForCompanyGuest, onlyAvailableViaURL, availableForGuests, currentGender } = this.state
        // const { productTitle, productSku, productDesc, productNumber, mainSector, selectedMain, allVariations, dropFileProducts } = this.state
        let uid = this.props.userIdToSwap ? this.props.userIdToSwap : localStorage.getItem("userid");

        let productTitle =
            document.getElementById("productTitle").value;
        let productSku =
            document.getElementById("productSku").value;
        let productDesc =
            document.getElementById("productDesc").value;
        let productNumber =
            document.getElementById("productNumber");
        let productPrice = document.getElementById("productPrice").value

        if (productNumber) {
            productNumber = productNumber.value
            this.setState({
                productNumber: productNumber,
            });
        }


        this.setState({
            productDesc,
            productSku,
            productTitle,
            productPrice,
        });

        let filteredVariation = []
        if (sizesActive) {
            if (allVariations.length > 0) {
                filteredVariation = allVariations.filter((g) => {
                    return !g.sku || g.qty === "" || g.qty === "undefined" || !g.size
                })
            } else {
                filteredVariation.push("1")
            }
        } else {
            if (!productNumber) {
                filteredVariation.push("1")
            } else {
                filteredVariation = []
            }
        }

        let filteredAttributes = []
        if (this.state.allAttributes.length > 0) {
            filteredAttributes = this.state.allAttributes.filter((g) => {
                return !g.attribute || !g.value
            })
        }

        if (productTitle && productPrice && productSku && mainSector && selectedMain.length > 0 && this.state.productImage.length > 0 && filteredVariation.length === 0) {
            // if (productTitle && productPrice && productSku && productDesc && mainSector && selectedMain.length > 0 && this.state.productImage.length > 0 && filteredVariation.length === 0) {
            this.setState({ isLoading: true })

            let promiseImages = new Promise((res) => {
                let arrUploaded = []
                if (dropFileProducts.length > 0) {
                    dropFileProducts.map((e) => {
                        firebase
                            .storage()
                            .ref()
                            .child(`bhodi_products_images/${e.name}`)
                            .put(e)
                            .then((snapshot) => {
                                snapshot.ref.getDownloadURL().then((snapUrl) => {
                                    arrUploaded.push(snapUrl)
                                    if (arrUploaded.length === dropFileProducts.length) {
                                        res(arrUploaded)
                                    }
                                })
                            })
                    })
                } else {
                    res([])
                }
            })
            promiseImages.then((imagesContainer) => {

                let storageKey = ""
                if (this.state.currentProductPushKey) {
                    storageKey = this.state.currentProductPushKey
                    let imagesToConcat = this.state.productImage.filter((g) => {
                        return g.includes("https://")
                    })
                    imagesContainer = imagesToConcat.concat(imagesContainer)
                } else {
                    storageKey = firebase
                        .database()
                        .ref("bhodi_products")
                        .push().key;
                }
                let timestamp = Math.floor(Date.now() / 100);
                let obj = {
                    title: productTitle,
                    price: productPrice,
                    sku: productSku,
                    desc: productDesc,
                    main_sector: mainSector,
                    main_category: selectedMain,
                    // qty: productNumber,
                    pushKey: storageKey,
                    active: currentOpenedCheck,
                    isSpecialProduct,
                    productForPage,
                    availableForCompanyGuest,
                    onlyAvailableViaURL,
                    availableForGuests,
                    created_at: this.state.currentProductPushKey ? this.props.currentProductToEdit.created_at : new Date().getTime(),
                    timestamp: this.state.currentProductPushKey ? this.props.currentProductToEdit.timestamp : timestamp,
                    sort_time: this.state.currentProductPushKey ? this.props.currentProductToEdit.sort_time : timestamp,
                    is_bookmarked: this.state.currentProductPushKey ? (this.props.currentProductToEdit.is_bookmarked ? true : false) : false,
                    created_by: this.state.currentCompanyIdForImport ? this.state.currentCompanyIdForImport : this.state.currentProductPushKey ? this.props.currentProductToEdit.created_by : uid,
                    images: imagesContainer,
                    sizesActive,
                    featuredImage
                }
                if (isoTimeTimerBtn) {
                    obj.timerToStartProduct = isoTimeTimerBtn
                    obj.timerTimeWaiting = this.state.timerTimeWaiting
                    obj.timerDateWaiting = this.state.timerDateWaiting
                }
                if (productDesc) {
                    obj.descAdded = true
                } else {
                    obj.descAdded = false
                }
                if (currentGender.length > 0) {
                    obj.gender = currentGender
                }
                if (filteredAttributes.length === 0) {
                    obj.attributesToShow = this.state.allAttributes
                }
                if (isSpecialProduct && !this.state.currentProductPushKey) {
                    let filterMyProducts = this.props.bhodi_products.filter((g) => {
                        if (productForPage) {
                            return g.created_by === uid && g.isSpecialProduct && g.productForPage === productForPage
                        }
                        return g.created_by === uid && g.isSpecialProduct && !g.productForPage
                    })
                    let maxProductsToShow = 5
                    if (this.props.global_values) {
                        if (this.props.global_values.products_count_new) {
                            maxProductsToShow = Number(this.props.global_values.products_count_new)
                        }
                    }
                    let dateToAssignAsTimer = ""
                    let filterCurrentUser = this.props.user.filter((g) => {
                        return g.user_id === uid
                    })
                    if (filterCurrentUser.length > 0) {
                        if (filterCurrentUser[0].productsInfo && filterCurrentUser[0].productsInfo.intervalTimer) {
                            let dateCreated = new Date()
                            let splitted = filterCurrentUser[0].productsInfo.intervalTimer.split(" ")

                            if (splitted[1].includes("second")) {
                                dateCreated.setSeconds(dateCreated.getSeconds() + Number(splitted[0]))
                            } else if (splitted[1].includes("minute")) {
                                dateCreated.setMinutes(dateCreated.getMinutes() + Number(splitted[0]))
                            } else {
                                dateCreated.setHours(dateCreated.getHours() + Number(splitted[0]))
                            }

                            dateToAssignAsTimer = dateCreated
                        }
                    }

                    if (filterMyProducts.length < maxProductsToShow && dateToAssignAsTimer) {
                        obj.buttonTimingToShow = dateToAssignAsTimer.toISOString()
                    }
                }
                if (availableForCompanyGuest && !this.state.currentProductPushKey) {
                    let filterMyProducts = this.props.bhodi_products.filter((g) => {
                        return g.created_by === uid && g.availableForCompanyGuest
                    })
                    let maxProductsToShow = 5
                    if (this.props.global_values) {
                        if (this.props.global_values.products_count_new) {
                            maxProductsToShow = Number(this.props.global_values.products_count_new)
                        }
                    }
                    let dateToAssignAsTimer = ""
                    let filterCurrentUser = this.props.user.filter((g) => {
                        return g.user_id === uid
                    })
                    if (filterCurrentUser.length > 0) {
                        if (filterCurrentUser[0].productsInfo && filterCurrentUser[0].productsInfo.intervalTimer) {
                            let dateCreated = new Date()
                            let splitted = filterCurrentUser[0].productsInfo.intervalTimer.split(" ")

                            if (splitted[1].includes("second")) {
                                dateCreated.setSeconds(dateCreated.getSeconds() + Number(splitted[0]))
                            } else if (splitted[1].includes("minute")) {
                                dateCreated.setMinutes(dateCreated.getMinutes() + Number(splitted[0]))
                            } else {
                                dateCreated.setHours(dateCreated.getHours() + Number(splitted[0]))
                            }

                            dateToAssignAsTimer = dateCreated
                        }
                    }

                    if (filterMyProducts.length < maxProductsToShow && dateToAssignAsTimer) {
                        obj.buttonTimingToShow = dateToAssignAsTimer.toISOString()
                    }
                }
                if (currentObject) {
                    if (currentObject.bookmarked_by) {
                        obj.bookmarked_by = currentObject.bookmarked_by
                    }
                    if (currentObject.liked_by) {
                        obj.liked_by = currentObject.liked_by
                    }
                    if (currentObject.inCartOf) {
                        // obj.inCartOf = currentObject.inCartOf
                        let entries = Object.entries(currentObject.inCartOf)
                        entries.map((t) => {
                            let pushKeyNotification = firebase.database().ref("notifications_data").push().key
                            let obj = {
                                pushKey: pushKeyNotification,
                                notification: `${currentObject.sku} was deleted by company`,
                                created_at: new Date().getTime(),
                                notificationFor: t[1].cookie
                            }
                            firebase.database().ref("notifications_data/" + t[1].cookie + "/" + pushKeyNotification).set(obj)
                            firebase.database().ref("guest_carts/" + t[1].cookie + "/" + t[1].cartPushKey).remove()
                        })
                    }
                }
                if (sizesActive) {
                    if (this.state.currentProductPushKey) {
                        let filtered = allVariations.filter((t) => {
                            return Number(t.qty) > 0
                        })
                        let filteredAlreadyPresent = allVariations.filter((t) => {
                            return Number(t.qty) > 0
                        })
                        if (filteredAlreadyPresent.length === 0 && filtered.length === 0) {
                            console.log("don't sort")
                        } else {
                            if (filtered.length === 0 || filteredAlreadyPresent === 0 || this.props.currentProductToEdit.active !== currentOpenedCheck) {
                                let filterMyProducts = this.props.bhodi_products.filter((g) => {
                                    return g.created_by === this.props.currentProductToEdit.created_by
                                })
                                let sortedAllProducts = [...filterMyProducts].sort((a, b) => {
                                    return a.sort_time * 100 - b.sort_time * 100;
                                })

                                if (sortedAllProducts[sortedAllProducts.length - 1].pushKey !== this.props.currentProductToEdit.pushKey) {
                                    let sortTimeLast = sortedAllProducts[sortedAllProducts.length - 1].sort_time
                                    sortTimeLast = sortTimeLast + 8000

                                    obj.sort_time = sortTimeLast

                                    this.assignTimerToComingProduct(this.props.currentProductToEdit)

                                }
                            }
                        }
                    }

                    obj.variations_data = allVariations
                    obj.qty = 0
                } else {
                    if (this.state.currentProductPushKey) {
                        if (Number(productNumber) === 0 && Number(this.props.currentProductToEdit.qty) === 0) {
                            console.log("don't sort")
                        } else {
                            if (Number(productNumber) === 0 || Number(this.props.currentProductToEdit.qty) === 0 || this.props.currentProductToEdit.active !== currentOpenedCheck) {
                                let filterMyProducts = this.props.bhodi_products.filter((g) => {
                                    return g.created_by === this.props.currentProductToEdit.created_by
                                })
                                let sortedAllProducts = [...filterMyProducts].sort((a, b) => {
                                    return a.sort_time * 100 - b.sort_time * 100;
                                })

                                if (sortedAllProducts[sortedAllProducts.length - 1].pushKey !== this.props.currentProductToEdit.pushKey) {
                                    let sortTimeLast = sortedAllProducts[sortedAllProducts.length - 1].sort_time
                                    sortTimeLast = sortTimeLast + 8000

                                    obj.sort_time = sortTimeLast

                                    this.assignTimerToComingProduct(this.props.currentProductToEdit)
                                }
                            }
                        }
                    }
                    obj.qty = productNumber
                }

                // assign timer to new product if special and getting available
                let product = this.state.productForStockCheck
                if (product) {
                    let productWasNotShowing = false
                    if (product.sizesActive) {
                        let stocksAvailableSizes = []
                        product.variations_data.map((e) => {
                            if (Number(e.qty) === 0) {
                                return false;
                            }
                            let filteredStockLength = []
                            let filteredStockLengthVerzilvered = []
                            if (product.verzilvered_by) {
                                filteredStockLengthVerzilvered = Object.entries(product.verzilvered_by).filter((t) => {
                                    return t[1].selectedSize === e.size
                                })
                            }
                            if ((filteredStockLength.length + filteredStockLengthVerzilvered.length) < Number(e.qty)) {
                                stocksAvailableSizes.push(e)
                            }
                        })
                        if (stocksAvailableSizes.length === 0) {
                            productWasNotShowing = true
                        }
                    } else {
                        let filteredStockLength = []
                        let filteredStockLengthVerzilvered = []
                        if (product.verzilvered_by) {
                            filteredStockLengthVerzilvered = Object.entries(product.verzilvered_by).filter((t) => {
                                return !t[1].selectedSize
                            })
                        }
                        if (Number(product.qty) <= (filteredStockLength.length + filteredStockLengthVerzilvered.length) || Number(product.qty) === 0) {
                            productWasNotShowing = true
                        }
                    }
                    if (productWasNotShowing && (isSpecialProduct || availableForCompanyGuest) && this.state.currentProductPushKey) {
                        let dateToAssignAsTimer = ""
                        let maxProductsToShow = 5
                        if (this.props.global_values) {
                            if (this.props.global_values.products_count_new) {
                                maxProductsToShow = Number(this.props.global_values.products_count_new)
                            }
                        }
                        let filterCurrentUser = this.props.user.filter((g) => {
                            return g.user_id === product.created_by
                        })
                        if (filterCurrentUser.length > 0) {
                            if (filterCurrentUser[0].productsInfo && filterCurrentUser[0].productsInfo.intervalTimer) {
                                let dateCreated = new Date()
                                let splitted = filterCurrentUser[0].productsInfo.intervalTimer.split(" ")

                                if (splitted[1].includes("second")) {
                                    dateCreated.setSeconds(dateCreated.getSeconds() + Number(splitted[0]))
                                } else if (splitted[1].includes("minute")) {
                                    dateCreated.setMinutes(dateCreated.getMinutes() + Number(splitted[0]))
                                } else {
                                    dateCreated.setHours(dateCreated.getHours() + Number(splitted[0]))
                                }

                                dateToAssignAsTimer = dateCreated
                            }
                        }
                        if (sizesActive) {
                            if (this.state.currentProductPushKey) {
                                let filtered = allVariations.filter((t) => {
                                    return Number(t.qty) > 0
                                })
                                if (filtered.length > 0) {
                                    let filterMyProducts = this.props.bhodi_products.filter((productInner) => {
                                        if (productInner.sizesActive) {
                                            let stocksAvailableSizes = []
                                            productInner.variations_data.map((e) => {
                                                if (Number(e.qty) === 0) {
                                                    return false;
                                                }
                                                let filteredStockLength = []
                                                let filteredStockLengthVerzilvered = []
                                                if (productInner.verzilvered_by) {
                                                    filteredStockLengthVerzilvered = Object.entries(productInner.verzilvered_by).filter((t) => {
                                                        return t[1].selectedSize === e.size
                                                    })
                                                }
                                                if ((filteredStockLength.length + filteredStockLengthVerzilvered.length) < Number(e.qty)) {
                                                    stocksAvailableSizes.push(e)
                                                }
                                            })
                                            if (stocksAvailableSizes.length === 0) {
                                                return false;
                                            }
                                        } else {
                                            let filteredStockLength = []
                                            let filteredStockLengthVerzilvered = []
                                            if (productInner.verzilvered_by) {
                                                filteredStockLengthVerzilvered = Object.entries(productInner.verzilvered_by).filter((t) => {
                                                    return !t[1].selectedSize
                                                })
                                            }
                                            if (Number(productInner.qty) <= (filteredStockLength.length + filteredStockLengthVerzilvered.length) || Number(productInner.qty) === 0) {
                                                return false;
                                            }
                                        }

                                        if (availableForCompanyGuest) {
                                            return productInner.created_by === this.props.currentProductToEdit.created_by && productInner.availableForCompanyGuest
                                        }
                                        if (productForPage) {
                                            return productInner.created_by === this.props.currentProductToEdit.created_by && productInner.productForPage === productForPage
                                        }
                                        return productInner.created_by === this.props.currentProductToEdit.created_by && productInner.isSpecialProduct && !productInner.productForPage
                                    })
                                    let sortedAllProducts = [...filterMyProducts].sort((a, b) => {
                                        return a.sort_time * 100 - b.sort_time * 100;
                                    })

                                    if (sortedAllProducts[sortedAllProducts.length - 1].pushKey !== this.props.currentProductToEdit.pushKey) {
                                        let sortTimeLast = sortedAllProducts[sortedAllProducts.length - 1].sort_time
                                        sortTimeLast = sortTimeLast + 8000

                                        obj.sort_time = sortTimeLast

                                        if (sortedAllProducts.length < maxProductsToShow && dateToAssignAsTimer) {
                                            obj.buttonTimingToShow = dateToAssignAsTimer.toISOString()
                                        }
                                    }
                                }
                            }

                            obj.variations_data = allVariations
                            obj.qty = 0
                        } else {
                            if (this.state.currentProductPushKey) {
                                if (Number(productNumber) > 0) {
                                    let filterMyProducts = this.props.bhodi_products.filter((productInner) => {
                                        if (productInner.sizesActive) {
                                            let stocksAvailableSizes = []
                                            productInner.variations_data.map((e) => {
                                                if (Number(e.qty) === 0) {
                                                    return false;
                                                }
                                                let filteredStockLength = []
                                                let filteredStockLengthVerzilvered = []
                                                if (productInner.verzilvered_by) {
                                                    filteredStockLengthVerzilvered = Object.entries(productInner.verzilvered_by).filter((t) => {
                                                        return t[1].selectedSize === e.size
                                                    })
                                                }
                                                if ((filteredStockLength.length + filteredStockLengthVerzilvered.length) < Number(e.qty)) {
                                                    stocksAvailableSizes.push(e)
                                                }
                                            })
                                            if (stocksAvailableSizes.length === 0) {
                                                return false;
                                            }
                                        } else {
                                            let filteredStockLength = []
                                            let filteredStockLengthVerzilvered = []
                                            if (productInner.verzilvered_by) {
                                                filteredStockLengthVerzilvered = Object.entries(productInner.verzilvered_by).filter((t) => {
                                                    return !t[1].selectedSize
                                                })
                                            }
                                            if (Number(productInner.qty) <= (filteredStockLength.length + filteredStockLengthVerzilvered.length) || Number(productInner.qty) === 0) {
                                                return false;
                                            }
                                        }

                                        if (availableForCompanyGuest) {
                                            return productInner.created_by === this.props.currentProductToEdit.created_by && productInner.availableForCompanyGuest
                                        }
                                        if (productForPage) {
                                            return productInner.created_by === this.props.currentProductToEdit.created_by && productInner.productForPage === productForPage
                                        }
                                        return productInner.created_by === this.props.currentProductToEdit.created_by && productInner.isSpecialProduct && !productInner.productForPage
                                    })
                                    let sortedAllProducts = [...filterMyProducts].sort((a, b) => {
                                        return a.sort_time * 100 - b.sort_time * 100;
                                    })

                                    if (sortedAllProducts[sortedAllProducts.length - 1].pushKey !== this.props.currentProductToEdit.pushKey) {
                                        let sortTimeLast = sortedAllProducts[sortedAllProducts.length - 1].sort_time
                                        sortTimeLast = sortTimeLast + 8000

                                        obj.sort_time = sortTimeLast

                                        if (sortedAllProducts.length < maxProductsToShow && dateToAssignAsTimer) {
                                            obj.buttonTimingToShow = dateToAssignAsTimer.toISOString()
                                        }
                                    }
                                }
                            }
                            obj.qty = productNumber
                        }
                    }
                }
                // assign timer to new product if special and getting available

                firebase.database().ref("bhodi_products/" + storageKey).set(obj).then(() => {
                    // window.location.reload()
                    if (!this.props.userIdToSwap) {
                        if (this.state.currentProductPushKey) {
                            let allProducts = [...this.props.bhodi_products]
                            let filteredIndex = allProducts.findIndex((e) => {
                                return e.pushKey === this.state.currentProductPushKey
                            })
                            if (filteredIndex !== -1) {
                                allProducts.splice(filteredIndex, 1, obj)
                                this.props.updateBhodiProducts(allProducts)
                            }
                        } else {
                            let allProducts = [...this.props.bhodi_products]
                            allProducts.push(obj)
                            this.props.updateBhodiProducts(allProducts)
                        }
                    }
                    this.props.closePopup()
                })
            })
        } else {
            if (productTitle) {
                this.setState({ productTitleAlert: false })
            } else {
                this.setState({ productTitleAlert: true })
            }
            if (productPrice) {
                this.setState({ productPriceAlert: false })
            } else {
                this.setState({ productPriceAlert: false })
            }
            if (productSku) {
                this.setState({ productSkuAlert: false })
            } else {
                this.setState({ productSkuAlert: true })
            }
            // if (productDesc) {
            //     this.setState({ productDescAlert: false })
            // } else {
            //     this.setState({ productDescAlert: true })
            // }
            if (mainSector) {
                this.setState({ productSectorAlert: false })
            } else {
                this.setState({ productSectorAlert: true })
            }
            if (selectedMain.length > 0) {
                this.setState({ noCategorySelectedProduct: false });
            } else {
                this.setState({ noCategorySelectedProduct: true });
            }
            if (this.state.productImage.length > 0) {
                this.setState({ productImageAlert: false })
            } else {
                this.setState({ productImageAlert: true })
            }
            if (productNumber) {
                this.setState({ productNumberAlert: false })
            } else {
                this.setState({ productNumberAlert: true })
            }
            if (filteredVariation.length > 0 || allVariations.length === 0) {
                this.setState({ productSizesAlert: true })
            } else {
                this.setState({ productSizesAlert: false })
            }
            // if (allVariations.length > 0) {
            //     this.setState({ productSizesAlert: true })
            // } else {
            //     this.setState({ productSizesAlert: false })
            // }
        }

    }

    assignTimerToComingProduct(product) {

        let filterMyProducts = this.props.bhodi_products.filter((productInner) => {
            if (productInner.sizesActive) {
                let stocksAvailableSizes = []
                productInner.variations_data.map((e) => {
                    if (Number(e.qty) === 0) {
                        return false;
                    }
                    let filteredStockLength = []
                    let filteredStockLengthVerzilvered = []
                    if (productInner.inCartOf && !this.state.isSpecialProduct && !this.state.availableForCompanyGuest) {
                        filteredStockLength = Object.entries(productInner.inCartOf).filter((t) => {
                            return t[1].selectedSize === e.size
                        })
                    }
                    if (productInner.verzilvered_by) {
                        filteredStockLengthVerzilvered = Object.entries(productInner.verzilvered_by).filter((t) => {
                            return t[1].selectedSize === e.size
                        })
                    }
                    if ((filteredStockLength.length + filteredStockLengthVerzilvered.length) < Number(e.qty)) {
                        stocksAvailableSizes.push(e)
                    }
                })
                if (stocksAvailableSizes.length === 0) {
                    return false;
                }
            } else {
                let filteredStockLength = []
                let filteredStockLengthVerzilvered = []
                if (productInner.inCartOf && !this.state.isSpecialProduct && !this.state.availableForCompanyGuest) {
                    filteredStockLength = Object.entries(productInner.inCartOf).filter((t) => {
                        return !t[1].selectedSize
                    })
                }
                if (productInner.verzilvered_by) {
                    filteredStockLengthVerzilvered = Object.entries(productInner.verzilvered_by).filter((t) => {
                        return !t[1].selectedSize
                    })
                }
                if (Number(productInner.qty) <= (filteredStockLength.length + filteredStockLengthVerzilvered.length) || Number(productInner.qty) === 0) {
                    return false;
                }
            }

            if (this.state.availableForCompanyGuest) {
                return !productInner.availableOnTime && productInner.images && productInner.active && productInner.availableForCompanyGuest && productInner.created_by === product.created_by
            } else if (this.state.isSpecialProduct) {
                if (this.state.productForPage) {
                    return !productInner.availableOnTime && productInner.images && productInner.active && productInner.isSpecialProduct && productInner.productForPage === this.state.productForPage && productInner.created_by === product.created_by
                }
                return !productInner.availableOnTime && productInner.images && productInner.active && productInner.isSpecialProduct && productInner.created_by === product.created_by && !productInner.productForPage
            } else {
                return !productInner.availableOnTime && productInner.images && productInner.active && !productInner.is_bookmarked && !productInner.isSpecialProduct && !productInner.onlyAvailableViaURL && productInner.created_by === product.created_by
            }
        })

        let filterCurrentUser = this.props.user.filter((g) => {
            return g.user_id === product.created_by
        })
        if (filterCurrentUser.length > 0 && filterMyProducts.length > 0) {

            let sortedAllProducts = [...filterMyProducts].sort((a, b) => {
                return a.sort_time * 100 - b.sort_time * 100;
            })
            let currentProductIndex = sortedAllProducts.findIndex((t) => {
                return t.pushKey === product.pushKey
            })


            let maxProductsToShow = 4

            if (this.state.isSpecialProduct || this.state.availableForCompanyGuest) {
                maxProductsToShow = 5
                if (this.props.global_values) {
                    if (this.props.global_values.products_count_new) {
                        maxProductsToShow = Number(this.props.global_values.products_count_new)
                    }
                }
            } else if (filterCurrentUser[0].productsInfo) {
                if (filterCurrentUser[0].productsInfo.maxProductsToShow) {
                    maxProductsToShow = Number(filterCurrentUser[0].productsInfo.maxProductsToShow)
                }
            }


            if (filterCurrentUser[0].productsInfo.intervalTimer && currentProductIndex !== -1 && currentProductIndex < maxProductsToShow && sortedAllProducts.length > maxProductsToShow) {
                let newerProduct = sortedAllProducts[maxProductsToShow]

                let dateCreated = new Date()
                let splitted = filterCurrentUser[0].productsInfo.intervalTimer.split(" ")

                if (splitted[1].includes("second")) {
                    dateCreated.setSeconds(dateCreated.getSeconds() + Number(splitted[0]))
                } else if (splitted[1].includes("minute")) {
                    dateCreated.setMinutes(dateCreated.getMinutes() + Number(splitted[0]))
                } else {
                    dateCreated.setHours(dateCreated.getHours() + Number(splitted[0]))
                }

                firebase.database().ref(`bhodi_products/${newerProduct.pushKey}/buttonTimingToShow`).set(dateCreated.toISOString())

            } else if (filterCurrentUser[0].productsInfo && filterCurrentUser[0].productsInfo.intervalTimer && sortedAllProducts.length < maxProductsToShow) {
                let newerProduct = product

                let dateCreated = new Date()
                let splitted = filterCurrentUser[0].productsInfo.intervalTimer.split(" ")

                if (splitted[1].includes("second")) {
                    dateCreated.setSeconds(dateCreated.getSeconds() + Number(splitted[0]))
                } else if (splitted[1].includes("minute")) {
                    dateCreated.setMinutes(dateCreated.getMinutes() + Number(splitted[0]))
                } else {
                    dateCreated.setHours(dateCreated.getHours() + Number(splitted[0]))
                }

                firebase.database().ref(`bhodi_products/${newerProduct.pushKey}/buttonTimingToShow`).set(dateCreated.toISOString())
            }
        }
    }

    checkForDeleteProductImage(e, index) {
        if (
            e !==
            "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/placeholder.jpg?alt=media&token=53d59535-5c5d-4617-91c9-cc84ecf766f8"
        ) {
            return (
                <>
                    <div
                        className="editimageupload"
                        onClick={() => {
                            let dropFiles = [...this.state.dropFileProducts]
                            let productImage = [...this.state.productImage]
                            productImage.splice(index, 1)
                            dropFiles.splice(index, 1)
                            this.setState({ dropFileProducts: dropFiles, productImage })
                            this.storeThingsAtState()
                        }}
                    >
                        <DeleteIcon />
                    </div>
                    <div
                        className="editimageupload"
                        onClick={() => {
                            this.setState({ featuredImage: index })
                        }}
                        style={{ marginLeft: 5 }}
                    >
                        {this.state.featuredImage === index ? <AiFillStar style={{ fontSize: 12, color: "white" }} /> : <AiOutlineStar style={{ fontSize: 12, color: "white" }} />}
                    </div>
                </>
            );
        }
    }

    storeThingsAtState() {
        let productTitle =
            document.getElementById("productTitle").value;
        let productSku =
            document.getElementById("productSku").value;
        let productDesc =
            document.getElementById("productDesc").value;
        let productPrice =
            document.getElementById("productPrice").value;
        let productNumber =
            document.getElementById("productNumber");

        if (productNumber) {
            this.setState({
                productNumber: productNumber.value,
            });
        }

        this.setState({
            productDesc,
            productSku,
            productTitle,
            productPrice,
        });
    }

    uploadImgProduct(e) {
        this.storeThingsAtState()

        e.preventDefault();
        let dropFiles = this.state.dropFileProducts;

        if (e.target.files && e.target.files.length > 0) {
            let objected = Object.entries(e.target.files);
            objected.map((f, i) => {
                if (this.state.productImage.length < 3) {
                    const reader = new FileReader();
                    dropFiles.push(objected[i][1]);
                    reader.addEventListener("load", () => {
                        let img = new Image();
                        let result = reader.result;
                        img.onload = (e) => {
                            let arr = this.state.productImage;
                            arr.push(result);
                            this.setState({
                                productImage: arr,
                                dropFileProducts: dropFiles,
                            });
                        };
                        img.src = result;
                    });
                    reader.readAsDataURL(objected[i][1]);
                    e.target.value = null;
                    this.setState({ productImageAlertMax: false })
                } else {
                    this.setState({ productImageAlertMax: true })
                    setTimeout(() => {
                        this.setState({ productImageAlertMax: false })
                    }, 2000);
                }
            });
        }
    }

    getSelectedCategoryName() {
        if (this.state.all_categories.length > 0) {
            let filtered = this.state.all_categories.filter((y) => {
                return this.state.selectedMain.includes(y.category_id);
            });
            if (filtered.length > 0) {
                let arr = [];
                filtered.map((y, i) => {
                    arr.push(y.title);
                });
                return arr.join(", ");
            }
        }
    }


    getFormattedDate(e) {
        if (e) {
            let splitted = e.split("-");
            splitted = splitted.reverse().join("-");
            return `${splitted}`;
        }
    }

    render() {
        return (
            <div>
                {this.state.companySettingsPopupImport && (<div className='right-menu-main-settings'>
                    <div className='rightmenuinner-rmmms updatedirghtmenunew newrightmenuglobalgood newrightmenucompaniesname'>
                        <div className='header-rmmi-rmms'>
                            <span>COMPANY</span>
                            <ChevronLeft onClick={() => {
                                this.setState({ companySettingsPopupImport: false })
                            }} />
                        </div>
                        <div className="search-rm-main">
                            <div className="wrapper-select-new">
                                <input type="text" placeholder="Zoek in je vriendenlijst"
                                    onChange={(e) => {
                                        if (e.target.value !== "") {
                                            let filtered = this.state.allCompaniesForProductsDuplicate.filter((t) => {
                                                return t.company_name.toLowerCase().includes(e.target.value.toLowerCase())
                                            })
                                            if (filtered.length > 0) {
                                                this.setState({ allCompaniesForProductsFilters: filtered, noFiltersCompanies: false })
                                            } else {
                                                this.setState({ allCompaniesForProductsFilters: [], noFiltersCompanies: true })
                                            }
                                        } else {
                                            this.setState({ allCompaniesForProductsFilters: [], noFiltersCompanies: false, allCompaniesForProducts: this.state.allCompaniesForProductsDuplicate })
                                        }
                                    }}
                                />
                                <SearchIcon
                                    className="expand-more"
                                />
                            </div>
                        </div>
                        <div className='content-rmmi-rmms contentrmirmms-newcompanies'>
                            {this.state.noFiltersCompanies ? (<label>
                                No Filters Found
                            </label>) : this.state.allCompaniesForProductsFilters.length > 0 ? this.state.allCompaniesForProductsFilters.map((t) => {
                                return (<div
                                    className="form-miur"
                                    style={{ position: "relative" }}
                                >
                                    <input type="radio" name='companyselection' value={t.user_id} defaultChecked={this.state.currentCompanyIdForImport ? this.state.currentCompanyIdForImport === t.user_id : this.props.userIdToSwap === t.user_id} onClick={() => {
                                        this.setState({ currentCompanyIdForImport: t.user_id })
                                    }} />
                                    <img src={t.profile_image ? t.profile_image : "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9"} />
                                    <label>
                                        {t.company_name}
                                    </label>
                                </div>)
                            }) : this.state.allCompaniesForProducts.map((t) => {
                                return (<div
                                    className="form-miur"
                                    style={{ position: "relative" }}
                                >
                                    <input type="radio" name='companyselection' value={t.user_id} defaultChecked={this.state.currentCompanyIdForImport ? this.state.currentCompanyIdForImport === t.user_id : this.props.userIdToSwap === t.user_id} onClick={() => {
                                        this.setState({ currentCompanyIdForImport: t.user_id })
                                    }} />
                                    <img src={t.profile_image ? t.profile_image : "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9"} />
                                    <label>
                                        {t.company_name}
                                    </label>
                                </div>)
                            })}
                        </div>
                    </div>
                </div>)}
                {!this.state.companySettingsPopupImport && (<div
                    className="overlay-div-openadd"
                ></div>)}
                {!this.state.companySettingsPopupImport && (<div className="separate-page-ce new-campaigns-design campaigns-design-final new-campaigns-design-final addproductpopupnewupdatedmobile">
                    {this.state.isLoading && (
                        <div className="loader-area" style={{ backgroundImage: "none" }}>
                            <CloseIcon onClick={() => window.location.reload()} />
                            <div className="lds-ring">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <p style={{ color: "white" }}>Saving Product</p>
                        </div>
                    )}
                    <div
                        className="rightmenuaddproduct rightmenucompletewidthaddproduct rightmenugiveawaycampaign"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        {this.state.addCategory && <div className="overlay-rmadfofw"></div>}
                        {this.state.addCategory && (
                            <div className="rightmenuaddproduct rightmenuaddcategoryformfw rightmenucategorynew newcategoriesrightmenu">
                                <div
                                    className="mid-rmap"
                                    style={{ position: "relative", padding: 13 }}
                                >
                                    <span>SELECTEER CATEGORIE</span>
                                    <div className="top-header-infocont">
                                        <div style={{ position: "relative" }}>
                                            <div className='green-round-info' style={{ cursor: "pointer" }} onClick={() => {
                                                this.setState({ categoryInfoOpen: !this.state.categoryInfoOpen })
                                            }}>
                                                <font>?</font>
                                            </div>
                                            <div className="popup-gri-thifc" style={{ opacity: this.state.categoryInfoOpen ? 1 : 0, pointerEvents: this.state.categoryInfoOpen ? "all" : "none" }}>
                                                <CloseIcon onClick={() => this.setState({ categoryInfoOpen: false })} />
                                                <label style={{ fontSize: 15 }}>
                                                    Selecteer de juiste categorie(ën)
                                                    <br />
                                                    waaronder je actie wordt getoond.
                                                </label>
                                            </div>
                                        </div>
                                        <ChevronLeft
                                            style={{
                                                cursor: "pointer",
                                                position: "absolute",
                                                top: "50%",
                                                transform: "translateY(-50%)",
                                                left: 20,
                                                fontSize: 18,
                                            }}
                                            onClick={() => this.setState({ addCategory: false, categoryInfoOpen: false })}
                                        />
                                    </div>
                                </div>
                                <div className="bottom-rmap">
                                    <div className="mid-img-uploading-rmap">
                                        {this.state.all_categories.length > 0 && (
                                            <ul className="checkboxlistselectcategory">
                                                {this.state.all_categories.map((e) => {
                                                    return (
                                                        <li className="checkboxfieldstypeselection">
                                                            <input
                                                                type="checkbox"
                                                                className="input-radio checkboxessortname"
                                                                value={e.category_id}
                                                                checked={this.state.selectedMain.includes(
                                                                    e.category_id
                                                                )}
                                                                onChange={() => {
                                                                    this.storeThingsAtState()

                                                                    if (
                                                                        this.state.selectedMain.includes(
                                                                            e.category_id
                                                                        )
                                                                    ) {
                                                                        let indexed =
                                                                            this.state.selectedMain.indexOf(
                                                                                e.category_id
                                                                            );
                                                                        this.state.selectedMain.splice(indexed, 1);
                                                                        this.setState({
                                                                            selectedMain: this.state.selectedMain,
                                                                        });
                                                                    } else {
                                                                        this.state.selectedMain.push(e.category_id);
                                                                        this.setState({
                                                                            selectedMain: this.state.selectedMain,
                                                                        });
                                                                    }
                                                                }}
                                                            />
                                                            <p>{e.title}</p>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                        {this.state.addAttributes && <div className="overlay-rmadfofw"></div>}
                        {this.state.addAttributes && (
                            <div className="rightmenuaddproduct rightmenuaddcategoryformfw rightmenucategoryformupdateddesign">
                                <div
                                    className="mid-rmap"
                                    style={{ position: "relative", padding: 13 }}
                                >
                                    <span>ADD ATTRIBUTES</span>
                                    <ChevronLeft
                                        style={{
                                            cursor: "pointer",
                                            position: "absolute",
                                            top: "50%",
                                            transform: "translateY(-50%)",
                                            left: 20,
                                            fontSize: 24,
                                        }}
                                        onClick={() => this.setState({ addAttributes: false })}
                                    />
                                </div>
                                <div className="bottom-rmap">
                                    <div className="top-img-uploading-rmap">
                                        <p>
                                            {/* Selecteer de juiste categorie voor je item */}
                                            Selecteer de juiste sizes(ën)
                                            <br />
                                            waaronder je actie wordt getoond.
                                        </p>
                                    </div>
                                    <div className="mid-img-uploading-rmap">
                                        <button className='add-variation-button' onClick={() => {
                                            let obj = {
                                                attribute: "",
                                                value: "",
                                            }
                                            this.state.allAttributes.push(obj)
                                            this.setState({ allAttributes: this.state.allAttributes })
                                        }}>Add <AddIcon /></button>
                                        {this.state.allAttributes.map((e, index) => {
                                            return (<div className={`variation-container`}>
                                                <div className='variation-topheading'>
                                                    <label>Attribute {index + 1}</label>
                                                    <DeleteIcon onClick={() => {
                                                        this.state.allAttributes.splice(index, 1)
                                                        this.setState({ allAttributes: this.state.allAttributes })
                                                    }} />
                                                </div>
                                                <div className='variation-container-inputs attribute-vcinp'>
                                                    <input type="text" placeholder='Name' defaultValue={e.attribute} onChange={(g) => {
                                                        e.attribute = g.target.value
                                                    }} onBlur={() => {
                                                        this.setState({ allAttributes: this.state.allAttributes })
                                                    }} />
                                                    <input type="text" placeholder='Value' defaultValue={e.value} onChange={(g) => {
                                                        e.value = g.target.value
                                                    }} onBlur={() => {
                                                        this.setState({ allAttributes: this.state.allAttributes })
                                                    }} />
                                                </div>
                                            </div>)
                                        })}
                                    </div>
                                </div>
                            </div>
                        )}
                        {this.state.addSizes && <div className="overlay-rmadfofw"></div>}
                        {this.state.addSizes && (
                            <div className="rightmenuaddproduct rightmenuaddcategoryformfw rightmenucategoryformupdateddesign">
                                <div
                                    className="mid-rmap"
                                    style={{ position: "relative", padding: 13 }}
                                >
                                    <span>ADD VARIATIONS</span>
                                    <ChevronLeft
                                        style={{
                                            cursor: "pointer",
                                            position: "absolute",
                                            top: "50%",
                                            transform: "translateY(-50%)",
                                            left: 20,
                                            fontSize: 24,
                                        }}
                                        onClick={() => this.setState({ addSizes: false })}
                                    />
                                </div>
                                <div className="bottom-rmap">
                                    <div className="top-img-uploading-rmap">
                                        <p>
                                            {/* Selecteer de juiste categorie voor je item */}
                                            Selecteer de juiste sizes(ën)
                                            <br />
                                            waaronder je actie wordt getoond.
                                        </p>
                                    </div>
                                    <div className="mid-img-uploading-rmap">
                                        <button className='add-variation-button' onClick={() => {
                                            let obj = {
                                                size: "",
                                                qty: "",
                                                sku: "",
                                            }
                                            this.state.allVariations.push(obj)
                                            this.setState({ allVariations: this.state.allVariations })
                                        }}>Add <AddIcon /></button>
                                        {this.state.allVariations.map((e, index) => {
                                            return (<div className={`variation-container`}>
                                                <div className='variation-topheading'>
                                                    <label>Variation {index + 1}</label>
                                                    <DeleteIcon onClick={() => {
                                                        this.state.allVariations.splice(index, 1)
                                                        this.setState({ allVariations: this.state.allVariations })
                                                    }} />
                                                </div>
                                                <div className='variation-container-inputs'>
                                                    <input type="text" placeholder='SKU' id="skuInput" defaultValue={e.sku} onChange={(g) => {
                                                        e.sku = g.target.value
                                                    }} onBlur={() => {
                                                        this.setState({ allVariations: this.state.allVariations })
                                                    }} />
                                                    <input type="text" placeholder='Maat' id="sizeInput" defaultValue={e.size} onChange={(g) => {
                                                        e.size = g.target.value
                                                    }} onBlur={() => {
                                                        this.setState({ allVariations: this.state.allVariations })
                                                    }} />
                                                    <input type="text" placeholder='Voorraad' id="qtyInput" defaultValue={e.qty} onChange={(g) => {
                                                        e.qty = g.target.value
                                                    }} onBlur={() => {
                                                        this.setState({ allVariations: this.state.allVariations })
                                                    }} />
                                                    <input type="text" placeholder='Prijs (optional)' id="priceInput" defaultValue={e.price} onChange={(g) => {
                                                        e.price = g.target.value
                                                    }} onBlur={() => {
                                                        this.setState({ allVariations: this.state.allVariations })
                                                    }} />
                                                </div>
                                            </div>)
                                        })}
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="sectiongiveawaycompletepopup">
                            <div className="header-spce" style={{ justifyContent: "center" }}>
                                <p>PRODUCT</p>
                                <FiChevronLeft className="arrow-back-svg"
                                    onClick={() => {
                                        this.props.closePopup()
                                    }}
                                />
                            </div>
                            <div
                                className="section-first-cplp"
                                key={Math.random()}
                                style={{ flexDirection: "row" }}
                            >
                                <div className="content-sfcplp">
                                    {/* <div className="infocontentcampaign"> */}
                                    {/* <p className="desc-icc">
                                            {this.state.descInfoGiveaway.substr(
                                                0,
                                                this.state.maxAmountOfText
                                            )}
                                        </p> */}
                                    {/* {this.state.readMoreOpened
                                            ? this.state.descInfoGiveaway.length > 189 && (
                                                <p
                                                    className="desc-icc"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        this.setState({
                                                            maxAmountOfText: 135,
                                                            readMoreOpened: false,
                                                        });
                                                    }}
                                                >
                                                    Lees minder..
                                                </p>
                                            )
                                            : this.state.descInfoGiveaway.length > 189 && (
                                                <p
                                                    className="desc-icc"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        this.setState({
                                                            descriptionToShowInfo:
                                                                this.state.descInfoGiveaway,
                                                            showInfoPopup: true,
                                                        });
                                                    }}
                                                >
                                                    Lees meer..
                                                </p>
                                            )} */}
                                    {/* </div> */}
                                    <div className="formtocreate-icc" style={{ minHeight: "auto", paddingTop: 0 }}>
                                        <div className="form-miur formfullwidthftcic">
                                            <label>Titel</label>
                                            <input
                                                defaultValue={this.state.productTitle}
                                                id="productTitle"
                                                maxLength={65}
                                                style={{ textTransform: "uppercase" }}
                                            />
                                            {this.state.productTitleAlert && (
                                                <label style={{ color: "red" }}>Verplicht veld</label>
                                            )}
                                        </div>
                                        <div className="form-miur formfullwidthftcic">
                                            <label>Retail price</label>
                                            <input
                                                defaultValue={this.state.productPrice}
                                                id="productPrice"
                                                maxLength={65}
                                                style={{ textTransform: "uppercase" }}
                                            />
                                            {this.state.productPriceAlert && (
                                                <label style={{ color: "red" }}>Verplicht veld</label>
                                            )}
                                        </div>
                                        <div className="form-miur formfullwidthftcic">
                                            <label>Sku</label>
                                            <input
                                                defaultValue={this.state.productSku}
                                                id="productSku"
                                                maxLength={65}
                                            />
                                            {this.state.productSkuAlert && (
                                                <label style={{ color: "red" }}>Verplicht veld</label>
                                            )}
                                        </div>
                                        {this.props.userIdToSwap === process.env.REACT_APP_ADMIN_ID && !this.state.currentProductPushKey && (<div
                                            className="form-miur"
                                            style={{ position: "relative" }}
                                            onClick={() => {
                                                this.storeThingsAtState()

                                                if (this.props.user) {
                                                    let filtered = this.props.user.filter((g) => {
                                                        return g.type === "company"
                                                    })
                                                    if (filtered.length > 0) {
                                                        this.setState({ allCompaniesForProducts: filtered, allCompaniesForProductsDuplicate: filtered })
                                                    }
                                                }

                                                this.setState({ companySettingsPopupImport: true, importProductsPopup: false })
                                            }}
                                        >
                                            <label>Company</label>
                                            <div className='wrapper-select-new'>
                                                <input value="Select Company" readOnly />
                                                <BiChevronRight
                                                    style={{
                                                        position: "absolute",
                                                        bottom: 5,
                                                        right: 5,
                                                        pointerEvents: "none",
                                                    }}
                                                    className='expand-more'
                                                />
                                            </div>
                                        </div>)}
                                        <div className="form-miur formfullwidthftcic">
                                            <label>Omschrijving</label>
                                            <textarea
                                                defaultValue={this.state.productDesc}
                                                id="productDesc"
                                                maxLength={480}
                                                style={{ minHeight: 140 }}
                                            ></textarea>
                                            {/* <TextAreaComponent maxwordsDescCampaign={true} data={this.state.productDesc} reallyBlur={(e) => {
                                            this.setState({ productDesc: e })
                                        }} /> */}
                                            {/* {this.state.productDescAlert && (
                                                <label style={{ color: "red" }}>Verplicht veld</label>
                                            )} */}
                                        </div>
                                        <div className="form-miur formfullwidthftcic" style={{ position: "relative" }}>
                                            <label>Type bedrijf</label>
                                            <div className='wrapper-select-new'>
                                                <select
                                                    value={this.state.mainSector}
                                                    onChange={(e) => {
                                                        this.storeThingsAtState()

                                                        let filtered = this.props.allCategories.filter((y) => {
                                                            return y[0] === e.target.value
                                                        })
                                                        if (filtered.length > 0) {
                                                            this.setState({ all_categories: filtered[0][1], selectedMain: [], mainSector: e.target.value })
                                                        } else {
                                                            this.setState({ all_categories: [], selectedMain: [], mainSector: "" })
                                                        }
                                                    }}>
                                                    {!this.state.mainSector && (
                                                        <option disabled selected hidden>
                                                            Selecteer
                                                        </option>
                                                    )}
                                                    {this.props.allSectors.map((g) => {
                                                        return <option value={g.sector_id}>{g.sector_name}</option>
                                                    })}
                                                </select>
                                                <ExpandMoreIcon
                                                    style={{
                                                        cursor: "pointer",
                                                        position: "absolute",
                                                        right: 5,
                                                        bottom: this.state.productSectorAlert ? 37 : 10,
                                                        fontSize: 18,
                                                        pointerEvents: "none",
                                                        pointerEvents: "none",
                                                    }}
                                                    className='expand-more'
                                                />
                                            </div>
                                            {this.state.productSectorAlert && (
                                                <label style={{ color: "red" }}>Verplicht veld</label>
                                            )}
                                        </div>
                                        {this.state.all_categories.length > 0 && (<div
                                            className="form-miur formfullwidthftcic"
                                            style={{ position: "relative" }}
                                        >
                                            <label>
                                                Categorieën
                                            </label>
                                            <div className='wrapper-select-new'>
                                                {this.state.selectedMain.length > 0 ? (
                                                    <input
                                                        type="text"
                                                        value={this.getSelectedCategoryName()}
                                                        disabled
                                                        style={{
                                                            fontSize: 13,
                                                            letterSpacing: 1,
                                                            backgroundColor: "white",
                                                            paddingRight: 40,
                                                        }}
                                                    />
                                                ) : (
                                                    <input
                                                        type="text"
                                                        value=""
                                                        disabled
                                                        style={{
                                                            fontSize: 13,
                                                            letterSpacing: 1,
                                                            backgroundColor: "white",
                                                            cursor: "pointer",
                                                        }}
                                                    />
                                                )}
                                                {this.state.selectedMain.length > 0 ? (
                                                    <CheckCircleIcon
                                                        style={{
                                                            cursor: "pointer",
                                                            position: "absolute",
                                                            right: 5,
                                                            // bottom: this.state.noCategorySelectedProduct
                                                            //     ? 37
                                                            //     : 10,
                                                            bottom: 0,
                                                            fontSize: 18,
                                                        }}
                                                        onClick={() =>
                                                            this.setState({
                                                                addCategory: true,
                                                                categorySelected: true,
                                                            })
                                                        }
                                                        className='expand-more'
                                                    />
                                                ) : (
                                                    <AddCircleIcon
                                                        style={{
                                                            cursor: "pointer",
                                                            position: "absolute",
                                                            right: 5,
                                                            // bottom: this.state.noCategorySelectedProduct
                                                            //     ? 37
                                                            //     : 10,
                                                            bottom: 0,
                                                            fontSize: 18,
                                                        }}
                                                        onClick={() =>
                                                            this.setState({
                                                                addCategory: true,
                                                                categorySelected: true,
                                                            })
                                                        }
                                                        className='expand-more'
                                                    />
                                                )}
                                                <div
                                                    style={{
                                                        position: "absolute",
                                                        width: "100%",
                                                        height: 40,
                                                        top: 0,
                                                        cursor: "pointer",
                                                        left: 0,
                                                        right: 0,
                                                        bottom: 0,
                                                        opacity: 0,
                                                    }}
                                                    onClick={() => {
                                                        this.storeThingsAtState()

                                                        this.setState({
                                                            addCategory: true,
                                                            categorySelected: true,
                                                        });
                                                    }}
                                                ></div>
                                            </div>
                                            {this.state.noCategorySelectedProduct && (
                                                <label style={{ color: "red" }}>Verplicht veld</label>
                                            )}
                                        </div>)}
                                        <div className="form-miur formfullwidthftcic" style={{ position: "relative" }}>
                                            <label>Gender</label>
                                            <div className='wrapper-select-new'>
                                                <select
                                                    value={this.state.currentGender[0]}
                                                    onChange={(e) => {
                                                        this.storeThingsAtState()

                                                        let arr = []
                                                        // let arr = [...this.state.currentGender]
                                                        // if (!arr.includes(e.target.value)) {
                                                        arr.push(e.target.value)
                                                        this.setState({ currentGender: arr })
                                                        // }
                                                    }}>
                                                    {this.state.currentGender.length === 0 && (
                                                        <option disabled selected hidden>
                                                            Selecteer
                                                        </option>
                                                    )}
                                                    <option value="dames">DAMES</option>
                                                    <option value="heren">HEREN</option>
                                                    <option value="kinderen">KINDEREN</option>
                                                    <option value="unisex">UNISEX</option>
                                                </select>
                                                <ExpandMoreIcon
                                                    style={{
                                                        cursor: "pointer",
                                                        position: "absolute",
                                                        right: 5,
                                                        bottom: this.state.productSectorAlert ? 37 : 10,
                                                        fontSize: 18,
                                                        pointerEvents: "none",
                                                        pointerEvents: "none",
                                                    }}
                                                    className='expand-more'
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="form-miur"
                                            style={{ position: "relative" }}
                                        >
                                            <label className="dolabelsuppercase">Afbeelding</label>
                                            <div className='wrapper-select-new'>
                                                <input
                                                    type="text"
                                                    value={this.state.productImage.length > 0 ? "Geselecteerd" : "Upload foto"}
                                                    // value="Upload foto"
                                                    disabled
                                                    style={{
                                                        fontSize: 13,
                                                        letterSpacing: 1,
                                                        backgroundColor: "white",
                                                    }}
                                                    className="dolabelsuppercase inputplaceholderbold"
                                                />
                                                <input
                                                    type="file"
                                                    onChange={(e) => this.uploadImgProduct(e)}
                                                    accept="image/*"
                                                    multiple
                                                    style={{
                                                        position: "absolute",
                                                        width: "100%",
                                                        height: "80%",
                                                        top: 0,
                                                        left: 0,
                                                        right: 0,
                                                        bottom: 0,
                                                        opacity: 0,
                                                    }}
                                                />
                                                <PublishIcon
                                                    style={{
                                                        cursor: "pointer",
                                                        position: "absolute",
                                                        right: 5,
                                                        bottom: (this.state.productImageAlert || this.state.productImageAlertMax) ? 37 : 10,
                                                        fontSize: 18,
                                                        pointerEvents:
                                                            this.state.productImageType ===
                                                            "uploadfromcomp" && "none",
                                                    }}
                                                    className='expand-more'
                                                />
                                            </div>
                                            {this.state.productImageAlert && (
                                                <label style={{ color: "red" }}>Verplicht veld</label>
                                            )}
                                            {this.state.productImageAlertMax && (
                                                <label style={{ color: "red" }}>Max 3 images allowed</label>
                                            )}
                                        </div>
                                        {this.state.productImage.length > 0 && (<div style={{ display: "flex", flexWrap: "wrap" }} className="row-image-productsadd">
                                            {this.state.productImage.map((e, index) => {
                                                return (
                                                    <div className="image-campaigns-new" style={{ width: "auto", marginRight: index !== 2 && 10 }}>
                                                        <div
                                                            className="image-sfcplp"
                                                            style={{
                                                                backgroundImage: `url("${e}")`,
                                                            }}
                                                        >
                                                            {/* {this.state.featuredImage === index ? <AiFillStar /> : <AiOutlineStar />} */}
                                                        </div>
                                                        {this.checkForDeleteProductImage(e, index)}
                                                    </div>
                                                )
                                            })}
                                        </div>)}
                                        <div className="form-inner-for-datetime" style={{ flexDirection: "column", alignItems: "flex-start" }}>
                                            <label>Startdatum</label>
                                            <div className="inner-top-main" style={{ marginTop: 15, width: "100%" }}>
                                                <div className="right-fifd">
                                                    <div className="datinput-fifd">
                                                        <BiCalendar />
                                                        {this.state.timerDateWaiting ? (
                                                            <label>
                                                                {this.getFormattedDate(
                                                                    this.state.timerDateWaiting
                                                                )}
                                                            </label>
                                                        ) : (
                                                            <label>Datum</label>
                                                        )}
                                                        <input
                                                            type="date"
                                                            min={this.state.makeTodayTimer}
                                                            value={this.state.timerDateWaiting}
                                                            onChange={(e) => {
                                                                this.storeThingsAtState()

                                                                this.setState({
                                                                    timerDateWaiting: e.target.value,
                                                                });
                                                                let timerDate = new Date(e.target.value)
                                                                let currentDate = new Date()
                                                                if (timerDate.getMonth() === currentDate.getMonth() && timerDate.getDate() === currentDate.getDate() && timerDate.getFullYear() === currentDate.getFullYear()) {
                                                                    this.setState({ timerTimeWaiting: "" })
                                                                }
                                                            }}
                                                        />
                                                        {/* <ExpandMoreIcon /> */}
                                                    </div>
                                                    <div className="select-fifd">
                                                        <MdTimer />
                                                        <select
                                                            value={this.state.timerTimeWaiting}
                                                            onChange={(e) => {
                                                                this.storeThingsAtState()

                                                                this.setState({
                                                                    timerTimeWaiting: e.target.value,
                                                                });
                                                            }}
                                                        >
                                                            <option disabled selected hidden value="">
                                                                Tijd
                                                            </option>
                                                            {this.state.allTimes.map((y) => {
                                                                let mustRestrictHours = false;
                                                                let minHour = 0;
                                                                let minMinutes = 0;
                                                                if (this.state.timerDateWaiting) {
                                                                    let timerDate = new Date(this.state.timerDateWaiting)
                                                                    let currentDate = new Date()
                                                                    if (timerDate.getMonth() === currentDate.getMonth() && timerDate.getDate() === currentDate.getDate() && timerDate.getFullYear() === currentDate.getFullYear()) {
                                                                        minHour = currentDate.getHours()
                                                                        minMinutes = currentDate.getMinutes()
                                                                        mustRestrictHours = true
                                                                    } else {
                                                                        minHour = 0
                                                                        minMinutes = 0
                                                                        mustRestrictHours = false
                                                                    }
                                                                }
                                                                if (mustRestrictHours) {
                                                                    if (Number(y.label.split(":")[0]) >= minHour) {
                                                                        if (Number(y.label.split(":")[0]) === minHour) {
                                                                            if (Number(y.label.split(":")[1] > minMinutes)) {
                                                                                return (
                                                                                    <option value={y.label}>{y.label}</option>
                                                                                );
                                                                            }
                                                                        } else {
                                                                            return (
                                                                                <option value={y.label}>{y.label}</option>
                                                                            );
                                                                        }
                                                                    }
                                                                } else {
                                                                    return (
                                                                        <option value={y.label}>{y.label}</option>
                                                                    );
                                                                }
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="form-miur"
                                        style={{ position: "relative", marginBottom: 0 }}
                                    >
                                        <label className="dolabelsuppercase">Eigenschappen</label>
                                    </div>
                                    <div
                                        className="savebtnwork"
                                        style={{ display: "flex", alignItems: "center", marginTop: 0 }}
                                    >
                                        <button>
                                            <Switch
                                                color="primary"
                                                inputProps={{ "aria-label": "primary checkbox" }}
                                                checked={this.state.sizesActive}
                                                onClick={() => {
                                                    this.storeThingsAtState()

                                                    this.setState({
                                                        sizesActive: !this.state.sizesActive,
                                                    });
                                                }}
                                            />
                                            <label>Variabel product</label>
                                        </button>
                                        {!this.state.sizesActive && (<div
                                            className="form-miur formmiurnumbersinputaantal"
                                            style={{
                                                padding: "0px 20px",
                                                display: "flex",
                                                alignItems: "center",
                                                minHeight: 58
                                            }}
                                        >
                                            <input
                                                type="number"
                                                defaultValue={this.state.productNumber}
                                                id="productNumber"
                                            />
                                            <label
                                                style={{
                                                    color: this.state.productNumberAlert ? "red" : "black",
                                                    marginBottom: 0,
                                                    marginLeft: 0,
                                                    fontSize: 13,
                                                    fontWeight: 400,
                                                    letterSpacing: 1,
                                                }}
                                            >
                                                Voorraad
                                            </label>
                                        </div>)}
                                        {/* )} */}
                                        {this.state.sizesActive && (<button style={{ minHeight: 58 }} onClick={() => {
                                            this.storeThingsAtState()

                                            this.setState({ addSizes: true })
                                        }}>
                                            <ChevronRight style={{ fontSize: 20 }} />
                                            <label style={{ color: this.state.productSizesAlert && "red" }}>Variaties</label>
                                        </button>)}
                                        <button style={{ minHeight: 58 }} onClick={() => {
                                            this.storeThingsAtState()

                                            this.setState({ addAttributes: true })
                                        }}>
                                            <ChevronRight style={{ fontSize: 20 }} />
                                            <label>Attributes</label>
                                        </button>
                                        {/* <button>
                                            <Switch
                                                color="primary"
                                                inputProps={{ "aria-label": "primary checkbox" }}
                                                checked={this.state.productUnlimited}
                                                onClick={() => {
                                                    this.storeThingsAtState()

                                                    this.setState({
                                                        productUnlimited: !this.state.productUnlimited,
                                                    });
                                                }}
                                            />
                                            {this.state.productUnlimited ? (
                                                <label>VOORRAAD ONBEPERKT</label>
                                            ) : (
                                                <label>VOORRAAD</label>
                                            )}
                                        </button> */}
                                        <div
                                            className="form-miur formfullwidthftcic"
                                            style={{ position: "relative" }}
                                        >
                                            <label>
                                                Available Via URL
                                            </label>
                                        </div>
                                        <button>
                                            <Switch
                                                color="primary"
                                                inputProps={{ "aria-label": "primary checkbox" }}
                                                checked={this.state.onlyAvailableViaURL}
                                                onClick={() => {
                                                    this.storeThingsAtState()

                                                    this.setState({
                                                        onlyAvailableViaURL: !this.state.onlyAvailableViaURL,
                                                    });
                                                }}
                                            />
                                            {this.state.onlyAvailableViaURL ? (
                                                <label>Available</label>
                                            ) : (
                                                <label>Niet Available</label>
                                            )}
                                        </button>
                                        <div
                                            className="form-miur formfullwidthftcic"
                                            style={{ position: "relative" }}
                                        >
                                            <label>
                                                Available For Guests
                                            </label>
                                        </div>
                                        <button>
                                            <Switch
                                                color="primary"
                                                inputProps={{ "aria-label": "primary checkbox" }}
                                                checked={this.state.availableForGuests}
                                                onClick={() => {
                                                    this.storeThingsAtState()

                                                    this.setState({
                                                        availableForGuests: !this.state.availableForGuests,
                                                    });
                                                }}
                                            />
                                            {this.state.availableForGuests ? (
                                                <label>Available</label>
                                            ) : (
                                                <label>Niet Available</label>
                                            )}
                                        </button>
                                        <div
                                            className="form-miur formfullwidthftcic"
                                            style={{ position: "relative" }}
                                        >
                                            <label>
                                                Special Product
                                            </label>
                                        </div>
                                        <button>
                                            <Switch
                                                color="primary"
                                                inputProps={{ "aria-label": "primary checkbox" }}
                                                checked={this.state.isSpecialProduct}
                                                onClick={() => {
                                                    this.storeThingsAtState()

                                                    this.setState({
                                                        isSpecialProduct: !this.state.isSpecialProduct,
                                                    });
                                                }}
                                            />
                                            {this.state.isSpecialProduct ? (
                                                <label>Jaa</label>
                                            ) : (
                                                <label>Nee</label>
                                            )}
                                        </button>
                                        {this.state.isSpecialProduct && (<div className="form-miur formfullwidthftcic" style={{ position: "relative", flexDirection: "column" }}>
                                            <label>Show at URL</label>
                                            <div className='wrapper-select-new' style={{ marginTop: 12 }}>
                                                <select
                                                    value={this.state.productForPage}
                                                    onChange={(e) => {
                                                        this.storeThingsAtState()

                                                        this.setState({ productForPage: e.target.value })
                                                    }}>
                                                    {!this.state.productForPage && (
                                                        <option disabled selected hidden value="">
                                                            Selecteer
                                                        </option>
                                                    )}
                                                    {this.state.allPages.map((t) => {
                                                        return <option value={t.page_url}>{t.page_url}</option>
                                                    })}
                                                </select>
                                                <ExpandMoreIcon
                                                    style={{
                                                        cursor: "pointer",
                                                        position: "absolute",
                                                        right: 5,
                                                        bottom: this.state.productSectorAlert ? 37 : 10,
                                                        fontSize: 18,
                                                        pointerEvents: "none",
                                                        pointerEvents: "none",
                                                    }}
                                                    className='expand-more'
                                                />
                                            </div>
                                        </div>)}
                                        <div
                                            className="form-miur formfullwidthftcic"
                                            style={{ position: "relative" }}
                                        >
                                            <label>
                                                Available For Company Guest
                                            </label>
                                        </div>
                                        <button>
                                            <Switch
                                                color="primary"
                                                inputProps={{ "aria-label": "primary checkbox" }}
                                                checked={this.state.availableForCompanyGuest}
                                                onClick={() => {
                                                    this.storeThingsAtState()

                                                    this.setState({
                                                        availableForCompanyGuest: !this.state.availableForCompanyGuest,
                                                    });
                                                }}
                                            />
                                            {this.state.availableForCompanyGuest ? (
                                                <label>Jaa</label>
                                            ) : (
                                                <label>Nee</label>
                                            )}
                                        </button>
                                        <div
                                            className="form-miur formfullwidthftcic"
                                            style={{ position: "relative" }}
                                        >
                                            <label>
                                                Actie status
                                            </label>
                                        </div>
                                        <button>
                                            <Switch
                                                color="primary"
                                                inputProps={{ "aria-label": "primary checkbox" }}
                                                checked={this.state.currentOpenedCheck}
                                                onClick={() => {
                                                    this.storeThingsAtState()

                                                    this.setState({
                                                        currentOpenedCheck: !this.state.currentOpenedCheck,
                                                    });
                                                }}
                                            />
                                            {this.state.currentOpenedCheck ? (
                                                <label>Actief</label>
                                            ) : (
                                                <label>Niet Actief</label>
                                            )}
                                        </button>
                                        <button
                                            className="savebtnworksave"
                                            onClick={() => {
                                                this.storeThingsAtState()

                                                let currentObject = ""
                                                if (this.props.currentProductToEdit) {
                                                    currentObject = Object.assign({}, this.props.currentProductToEdit)
                                                }
                                                if (currentObject.inCartOf) {
                                                    this.setState({ showPopupConfirmation: true })
                                                    return false;
                                                }

                                                this.saveProduct()
                                            }}
                                            style={{ justifyContent: window.innerWidth < 1008 && "center", backgroundColor: window.innerWidth < 1008 && "#242427" }}
                                        >
                                            OPSLAAN
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>)}
                <Dialog
                    open={this.state.showPopupConfirmation}
                    onClose={() => this.setState({ showPopupConfirmation: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999 }}
                    className="modal-delete-campaigntest"
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            All carts will be cleared.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                this.setState({ showPopupConfirmation: false })
                            }}
                            color="primary"
                        >
                            NEE
                        </Button>
                        <Button
                            onClick={() => {
                                this.saveProduct()
                            }}
                            color="primary"
                        >
                            JA
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        bhodi_products: state.productsReducers.bhodi_products,
        user: state.authReducers.user,
        bhodi_brands: state.globalReducers.bhodi_brands,
        global_values: state.globalReducers.global_values,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateBhodiProducts: (bhodi_products) => dispatch(updateBhodiProducts(bhodi_products)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(AddEditProduct);