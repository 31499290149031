import React, { Component } from 'react'
import playiconlandingpagemobile from "../../assets/playiconlogomobile.png"
import profilesectionpic from "../../assets/profilesectioninformatie.jpeg"
import topinformatiemobile from "../../assets/topinformatiemobile.png"
import logoTopMobile from "../../assets/subadminlogo.png";
import "./index.css"
import { IoLocationSharp, IoMail } from 'react-icons/io5'
import { CiGlobe } from 'react-icons/ci'

export class Informatie extends Component {
    render() {
        return (
            <div className='informatie-page-main'>
                <div className='container-ipm'>
                    <div className='top-ipm'>
                        <div className='tipm-first'>
                            <div className='tipmf-inner'>
                                <div className='top-overlay-ipm'>
                                    <img src={logoTopMobile} alt="logoTopMobile" />
                                </div>
                                <p className='heading-ipm'>KAART</p>
                                <p className='desc-ipm'>
                                    Verras jullie gasten na hun diner of lunch met een <br />
                                    1+1 gratis driegangenmenu bij hun <font style={{ color: "#c17f6b" }}>volgende</font> bezoek <br />
                                    aan jullie restaurant.
                                </p>
                                <div className='spacing-ipm'></div>
                                <p className='heading-ipm'>HOE WERKT HET?</p>
                                <p className='desc-ipm'>
                                    Vanuit Bhodi ontvangen jullie 4 kaarten welke voorzien <br />
                                    zijn van een unieke QR code voor jullie restaurant. <br />
                                    De kaarten krijgen jullie per post gratis toegestuurd.
                                </p>
                            </div>
                            <div className='tipmf-img-mobile'>
                                <img src={topinformatiemobile} alt="topinformatiemobile" />
                            </div>
                        </div>
                        <div className='tipm-second'>
                            <div className='tipms-left'>
                                <p className='heading-ipm'>SCANNEN</p>
                                <p className='desc-ipm'>
                                    De gasten kunnen zich eenvoudig aanmelden voor deze <br />
                                    actie door de QR code te scannen.  Na hun aanmelding <br />
                                    krijgen de gasten toegang tot hun voucher, deze kunnen <br />
                                    ze binnen 4 maanden verzilveren.
                                </p>
                                <div className='spacing-ipm'></div>
                                <p className='desc-ipm'>
                                    De voucher is geldig van maandag t/m donderdag op <br />
                                    basis van jullie openingstijden en beschikbaarheid <br />
                                    (feestdagen zijn uitgezonderd van de actie)
                                </p>
                            </div>
                            <div className='tipms-right'>
                            </div>
                        </div>
                    </div>
                    <div className='profile-section-ipm'>
                        <div className='top-psipm'>
                            <h3>Profielpagina</h3>
                            <img src={playiconlandingpagemobile} alt="playiconlandingpagemobile" />
                            <p>
                                Op Bhodi krijgen jullie een eigen profielpagina waarop de actie eveneens <br />
                                beschikbaar word gesteld. De gasten kunnen zich eenvoudig via deze pagina <br />
                                aanmelden en gebruik maken van deze actie.
                            </p>
                            <p>
                                De pagina is voorzien van een unieke link en kan deze gedeeld worden via jullie <br />
                                socials en nieuwsbrief. Het aantal gasten die zich hiervoor kunnen aanmelden, <br />
                                mogen jullie zelf bepalen met een minimum vanaf 50 gasten.
                            </p>
                        </div>
                        <div className='bottom-psipm'>
                            <img src={profilesectionpic} alt='profilesectionpic' />
                        </div>
                    </div>
                    <div className='bijdrage-section-ipm'>
                        <h3>Bijdrage</h3>
                        <img src={playiconlandingpagemobile} alt="playiconlandingpagemobile" />
                        <p>
                            Tijdens het aanmelden voor de actie via de kaart of profielpagina, <br />
                            kunnen de gasten hun bijdrage selecteren van €6 per maand.
                        </p>
                    </div>
                    <div className='maatltijden-section-ipm'>
                        <div className='row-section-main'>
                            <div className='left-rsm'>

                            </div>
                            <div className='right-rsm'>
                                <p className='heading-ipm'>MAALTIJDEN</p>
                                <p className='desc-ipm'>
                                    Vanuit deze bijdrage ondersteunen wij kinderen en <br />
                                    scholen in Tanzania met maaltijden en materialen.
                                </p>
                                <div className='spacing-ipm'></div>
                                <p className='desc-ipm'>
                                    We werken samen met lokale bakkerijen en <br />
                                    verzorgen wij dagelijks verse broodjes.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='materialen-section-ipm'>
                        <div className='row-section-main'>
                            <div className='left-rsm'>

                            </div>
                            <div className='right-rsm'>
                                <p className='heading-ipm'>MATERIALEN</p>
                                <p className='desc-ipm'>
                                    Naast de maaltijden ondersteunen wij de scholen <br />
                                    met materialen, zoals schriften, pennen en potloden.
                                </p>
                                <div className='spacing-ipm'></div>
                                <p className='desc-ipm'>
                                    Daarnaast vergoeden wij het lesgeld voor weeskinderen. <br />
                                    Zodat ook deze kindjes naar school kunnen gaan en een <br />
                                    betere plek kunnen krijgen in de maatschappij.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='bijdrage-section-ipm bedankvideo-sipm'>
                        <h3>Bedankvideo</h3>
                        <img src={playiconlandingpagemobile} alt="playiconlandingpagemobile" />
                        <p>
                            De gasten die zich hebben aangemeld, ontvangen een persoonlijke en <br />
                            op naam ingesproken bedankvideo vanuit de school in Tanzania.
                        </p>
                    </div>
                    <div className='transparant-section-ipm'>
                        <div className='row-section-main'>
                            <div className='left-rsm'>

                            </div>
                            <div className='right-rsm'>
                                <p className='heading-ipm'>TRANSPARANT</p>
                                <p className='desc-ipm'>
                                    De maaltijduitgifte brengen wij in beeld met video. <br />
                                    Op deze manier maken we ons werk transparant <br />
                                    en kunnen de gasten daadwerkelijk zien wat er <br />
                                    met hun bijdrage is gebeurd.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='bijdrage-section-ipm tegemoetkoming-sipm'>
                        <h3>Tegemoetkoming</h3>
                        <img src={playiconlandingpagemobile} alt="playiconlandingpagemobile" />
                        <p>
                            Door de corona maatregelen is de horeca destijds hard getroffen en <br />
                            willen we jullie vanuit Bhodi graag een steuntje in de rug gegeven!
                        </p>
                        <div className='spacing-ipm'></div>
                        <p>
                            Als restaurant ontvangen jullie een tegemoetkoming in de kosten voor <br />
                            het weggeven van een gratis driegangenmenu per deelnemende gast. <br />
                            De tegemoetkoming is maximaal €30. Dit is afhankelijk <br />
                            van de duur van de maandelijkse bijdrage per deelnemende gast.
                        </p>
                        <div className='spacing-ipm'></div>
                        <p>
                            Deelname aan Bhodi is volledig gratis! <br />
                            Het lijkt ons erg leuk om er samen met jullie iets moois van te maken. <br />
                            Meld je eenvoudig aan via de per e-mail toegestuurde link.
                        </p>
                    </div>
                    <div className='bg-banner-ipm'>

                    </div>
                    <div className='bottom-footer-ipm'>
                        <div className='bf-ipm-inner'>
                            <IoLocationSharp />
                            <p>Winde 18, 7623 NE Borne</p>
                        </div>
                        <div className='bf-ipm-inner'>
                            <CiGlobe />
                            <p>bhodi.com</p>
                        </div>
                        <div className='bf-ipm-inner'>
                            <IoMail />
                            <p>info@bhodi.com</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Informatie