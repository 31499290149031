import React, { Component } from 'react'
import charityVideo from "../assets/charity-page-video.mp4"
import firebase from '../Config/Firebase'
import Switch from '@material-ui/core/Switch';
import "./index.css"
import { history } from '../Config/Routes';
import Footer from './Footer';
import FooterGreen from './FooterGreen';
import { AiOutlineHome } from 'react-icons/ai';
import { connect } from 'react-redux';
import {
    updateGlobalStuff,
} from "../Redux/actions/authActions";
import axios from "axios";

export class WizardCompany extends Component {
    constructor() {
        super()
        this.state = {
            currentStep: 1,
            doAnimate: false,
            showDutch: true,
            company_home_dutch: "",
            company_home_english: "",
            company_events_dutch: "",
            company_events_english: "",
            company_products_dutch: "",
            company_products_english: "",
            company_stories_dutch: "",
            company_stories_english: "",
            company_impressie_dutch: "",
            company_impressie_english: "",
            company_charity_dutch: "",
            company_charity_english: "",
            company_vrienden_dutch: "",
            company_vrienden_english: "",
            company_greetings_dutch: "",
            company_greetings_english: "",
            company_home_dutch_mobile: "",
            company_home_english_mobile: "",
            company_events_dutch_mobile: "",
            company_events_english_mobile: "",
            company_products_dutch_mobile: "",
            company_products_english_mobile: "",
            company_stories_dutch_mobile: "",
            company_stories_english_mobile: "",
            company_impressie_dutch_mobile: "",
            company_impressie_english_mobile: "",
            company_charity_dutch_mobile: "",
            company_charity_english_mobile: "",
            company_vrienden_dutch_mobile: "",
            company_vrienden_english_mobile: "",
            company_greetings_dutch_mobile: "",
            company_greetings_english_mobile: "",
        }
    }

    componentDidMount() {
        if (document.querySelector(".navbarfullwidthMobile")) {
            document.querySelector(".navbarfullwidthMobile").classList.add("navbarfullwidthoverflowxhidden")
        }

        if (this.props.atCurrentStep) {
            this.setState({ currentStep: this.props.atCurrentStep })
        }
        setTimeout(() => {
            this.setState({ doAnimate: true })
        }, 300);

        let res = axios.get(
            "https://us-central1-bhodi-production-da765.cloudfunctions.net/getIpLocation"
        );
        res.then((output) => {
            if (output.data.deta) {
                if (output.data.deta.country === "NL") {
                    this.setState({ showDutch: true });
                } else {
                    this.setState({ showDutch: false });
                }
            }
        });

        if (this.props.global_values) {
            let a = this.props.global_values;
            if (a) {
                this.setState({
                    // member_trending_dutch: a.member_trending_dutch,
                    // member_trending_english: a.member_trending_english,
                    // member_stories_dutch: a.member_stories_dutch,
                    // member_stories_english: a.member_stories_english,
                    // member_vrienden_dutch: a.member_vrienden_dutch,
                    // member_vrienden_english: a.member_vrienden_english,
                    // member_charity_dutch: a.member_charity_dutch,
                    // member_charity_english: a.member_charity_english,
                    // member_trending_dutch_mobile: a.member_trending_dutch_mobile,
                    // member_trending_english_mobile: a.member_trending_english_mobile,
                    // member_stories_dutch_mobile: a.member_stories_dutch_mobile,
                    // member_stories_english_mobile: a.member_stories_english_mobile,
                    // member_vrienden_dutch_mobile: a.member_vrienden_dutch_mobile,
                    // member_vrienden_english_mobile: a.member_vrienden_english_mobile,
                    // member_charity_dutch_mobile: a.member_charity_dutch_mobile,
                    // member_charity_english_mobile: a.member_charity_english_mobile,
                    company_home_dutch: a.company_home_dutch,
                    company_home_english: a.company_home_english,
                    company_events_dutch: a.company_events_dutch,
                    company_events_english: a.company_events_english,
                    company_products_dutch: a.company_products_dutch,
                    company_products_english: a.company_products_english,
                    company_stories_dutch: a.company_stories_dutch,
                    company_stories_english: a.company_stories_english,
                    company_impressie_dutch: a.company_impressie_dutch,
                    company_impressie_english: a.company_impressie_english,
                    company_charity_dutch: a.company_charity_dutch,
                    company_charity_english: a.company_charity_english,
                    company_vrienden_dutch: a.company_vrienden_dutch,
                    company_vrienden_english: a.company_vrienden_english,
                    company_greetings_dutch: a.company_greetings_dutch,
                    company_greetings_english: a.company_greetings_english,
                    company_home_dutch_mobile: a.company_home_dutch_mobile,
                    company_home_english_mobile: a.company_home_english_mobile,
                    company_events_dutch_mobile: a.company_events_dutch_mobile,
                    company_events_english_mobile: a.company_events_english_mobile,
                    company_products_dutch_mobile: a.company_products_dutch_mobile,
                    company_products_english_mobile: a.company_products_english_mobile,
                    company_stories_dutch_mobile: a.company_stories_dutch_mobile,
                    company_stories_english_mobile: a.company_stories_english_mobile,
                    company_impressie_dutch_mobile: a.company_impressie_dutch_mobile,
                    company_impressie_english_mobile: a.company_impressie_english_mobile,
                    company_charity_dutch_mobile: a.company_charity_dutch_mobile,
                    company_charity_english_mobile: a.company_charity_english_mobile,
                    company_vrienden_dutch_mobile: a.company_vrienden_dutch_mobile,
                    company_vrienden_english_mobile: a.company_vrienden_english_mobile,
                    company_greetings_dutch_mobile: a.company_greetings_dutch_mobile,
                    company_greetings_english_mobile: a.company_greetings_english_mobile,
                });
            }
        }

        firebase
            .database()
            .ref("bhodi_home")
            .on("value", (data) => {
                let a = data.val();
                if (a) {
                    this.props.updateGlobalStuff(a);
                    this.setState({
                        // member_trending_dutch: a.member_trending_dutch,
                        // member_trending_english: a.member_trending_english,
                        // member_stories_dutch: a.member_stories_dutch,
                        // member_stories_english: a.member_stories_english,
                        // member_vrienden_dutch: a.member_vrienden_dutch,
                        // member_vrienden_english: a.member_vrienden_english,
                        // member_charity_dutch: a.member_charity_dutch,
                        // member_charity_english: a.member_charity_english,
                        // member_trending_dutch_mobile: a.member_trending_dutch_mobile,
                        // member_trending_english_mobile: a.member_trending_english_mobile,
                        // member_stories_dutch_mobile: a.member_stories_dutch_mobile,
                        // member_stories_english_mobile: a.member_stories_english_mobile,
                        // member_vrienden_dutch_mobile: a.member_vrienden_dutch_mobile,
                        // member_vrienden_english_mobile: a.member_vrienden_english_mobile,
                        // member_charity_dutch_mobile: a.member_charity_dutch_mobile,
                        // member_charity_english_mobile: a.member_charity_english_mobile,
                        company_home_dutch: a.company_home_dutch,
                        company_home_english: a.company_home_english,
                        company_events_dutch: a.company_events_dutch,
                        company_events_english: a.company_events_english,
                        company_products_dutch: a.company_products_dutch,
                        company_products_english: a.company_products_english,
                        company_stories_dutch: a.company_stories_dutch,
                        company_stories_english: a.company_stories_english,
                        company_impressie_dutch: a.company_impressie_dutch,
                        company_impressie_english: a.company_impressie_english,
                        company_charity_dutch: a.company_charity_dutch,
                        company_charity_english: a.company_charity_english,
                        company_vrienden_dutch: a.company_vrienden_dutch,
                        company_vrienden_english: a.company_vrienden_english,
                        company_greetings_dutch: a.company_greetings_dutch,
                        company_greetings_english: a.company_greetings_english,
                        company_home_dutch_mobile: a.company_home_dutch_mobile,
                        company_home_english_mobile: a.company_home_english_mobile,
                        company_events_dutch_mobile: a.company_events_dutch_mobile,
                        company_events_english_mobile: a.company_events_english_mobile,
                        company_products_dutch_mobile: a.company_products_dutch_mobile,
                        company_products_english_mobile: a.company_products_english_mobile,
                        company_stories_dutch_mobile: a.company_stories_dutch_mobile,
                        company_stories_english_mobile: a.company_stories_english_mobile,
                        company_impressie_dutch_mobile: a.company_impressie_dutch_mobile,
                        company_impressie_english_mobile: a.company_impressie_english_mobile,
                        company_charity_dutch_mobile: a.company_charity_dutch_mobile,
                        company_charity_english_mobile: a.company_charity_english_mobile,
                        company_vrienden_dutch_mobile: a.company_vrienden_dutch_mobile,
                        company_vrienden_english_mobile: a.company_vrienden_english_mobile,
                        company_greetings_dutch_mobile: a.company_greetings_dutch_mobile,
                        company_greetings_english_mobile: a.company_greetings_english_mobile,
                    });
                }
            });
    }

    createMarkup(htmlToMake) {
        return { __html: htmlToMake };
    }

    componentWillUnmount() {
        if (document.querySelector(".navbarfullwidthMobile")) {
            document.querySelector(".navbarfullwidthMobile").classList.remove("navbarfullwidthoverflowxhidden")
        }
    }

    render() {
        return (
            // <div className='wizard-main'>
            <div className='wizard-main' style={{ minHeight: window.innerWidth < 1008 && `${window.innerHeight - 130}px`, height: window.innerWidth > 1008 ? window.innerHeight : "auto", backgroundColor: "#285151", paddingBottom: window.innerWidth > 1009 ? 0 : 80 }}>
                {window.innerWidth > 1009 ? (<video
                    src={charityVideo}
                    autoPlay
                    playsInline
                    muted
                    loop
                    className="absolutevideofullbg"
                ></video>) : null}
                <div
                    className="third-sec-cdd"
                    style={{ paddingTop: 0, paddingBottom: 0, backgroundColor: window.innerWidth > 1009 ? "transparent" : "#285151" }}
                >
                    <div
                        className="footer-sscdd"
                        style={{
                            marginTop: 0,
                            alignItems: "initial",
                            justifyContent: "center",
                            height: "auto",
                        }}
                    >
                        <div
                            className="left-fsscdd"
                            style={{
                                backgroundColor: "#285151",
                                maxWidth: "26%",
                                textAlign: "center",
                            }}
                        >
                            <div className="charity-lfss">
                                {this.state.currentStep === 1 ? (<div className='content-main-inner'>
                                    <AiOutlineHome style={{ color: "white", fontSize: 18, marginBottom: 10 }} />
                                    <p className="para-charity-lfss">
                                        <label className='bottom-line-pclf'>Startpagina</label>
                                        <br />
                                        <label style={{ color: "#C17F6B" }}>Hoe werkt het</label>
                                        {this.state.showDutch ? <div dangerouslySetInnerHTML={this.createMarkup(window.innerWidth > 1008 ? this.state.company_home_dutch : this.state.company_home_dutch_mobile)}></div> : <div dangerouslySetInnerHTML={this.createMarkup(window.innerWidth > 1008 ? this.state.company_home_english : this.state.company_home_english_mobile)}></div>}
                                    </p>
                                </div>) : this.state.currentStep === 2 ? (<div className='content-main-inner'>
                                    <p className="para-charity-lfss">
                                        <label className='bottom-line-pclf'>Events</label>
                                        <br />
                                        <label style={{ color: "#C17F6B" }}>Hoe werkt het</label>
                                        {this.state.showDutch ? <div dangerouslySetInnerHTML={this.createMarkup(window.innerWidth > 1008 ? this.state.company_events_dutch : this.state.company_events_dutch_mobile)}></div> : <div dangerouslySetInnerHTML={this.createMarkup(window.innerWidth > 1008 ? this.state.company_events_english : this.state.company_events_english_mobile)}></div>}
                                    </p>
                                </div>) : this.state.currentStep === 3 ? (<div className='content-main-inner'>
                                    <p className="para-charity-lfss">
                                        <label className='bottom-line-pclf'>Stories</label>
                                        <br />
                                        <label style={{ color: "#C17F6B" }}>Hoe werkt het</label>
                                        {this.state.showDutch ? <div dangerouslySetInnerHTML={this.createMarkup(window.innerWidth > 1008 ? this.state.company_stories_dutch : this.state.company_stories_dutch_mobile)}></div> : <div dangerouslySetInnerHTML={this.createMarkup(window.innerWidth > 1008 ? this.state.company_stories_english : this.state.company_stories_english_mobile)}></div>}
                                    </p>
                                </div>) : this.state.currentStep === 4 ? (<div className='content-main-inner'>
                                    <p className="para-charity-lfss">
                                        <label className='bottom-line-pclf'>Impressie</label>
                                        <br />
                                        <label style={{ color: "#C17F6B" }}>Hoe werkt het</label>
                                        {this.state.showDutch ? <div dangerouslySetInnerHTML={this.createMarkup(window.innerWidth > 1008 ? this.state.company_impressie_dutch : this.state.company_impressie_dutch_mobile)}></div> : <div dangerouslySetInnerHTML={this.createMarkup(window.innerWidth > 1008 ? this.state.company_impressie_english : this.state.company_impressie_english_mobile)}></div>}
                                    </p>
                                </div>) : this.state.currentStep === 5 ? (<div className='content-main-inner'>
                                    <p className="para-charity-lfss">
                                        <label className='bottom-line-pclf'>Charity</label>
                                        <br />
                                        <label style={{ color: "#C17F6B" }}>Hoe werkt het</label>
                                        {this.state.showDutch ? <div dangerouslySetInnerHTML={this.createMarkup(window.innerWidth > 1008 ? this.state.company_charity_dutch : this.state.company_charity_dutch_mobile)}></div> : <div dangerouslySetInnerHTML={this.createMarkup(window.innerWidth > 1008 ? this.state.company_charity_english : this.state.company_charity_english_mobile)}></div>}
                                    </p>
                                </div>) : this.state.currentStep === 8 ? (<div className='content-main-inner'>
                                    <p className="para-charity-lfss">
                                        <label className='bottom-line-pclf'>Producten</label>
                                        <br />
                                        <label style={{ color: "#C17F6B" }}>Hoe werkt het</label>
                                        {this.state.showDutch ? <div dangerouslySetInnerHTML={this.createMarkup(window.innerWidth > 1008 ? this.state.company_products_dutch : this.state.company_products_dutch_mobile)}></div> : <div dangerouslySetInnerHTML={this.createMarkup(window.innerWidth > 1008 ? this.state.company_products_english : this.state.company_products_english_mobile)}></div>}
                                    </p>
                                </div>) : this.state.currentStep === 6 && (<div className='content-main-inner'>
                                    <p className="para-charity-lfss">
                                        <label className='bottom-line-pclf'>Vrienden</label>
                                        <br />
                                        <label style={{ color: "#C17F6B" }}>Hoe werkt het</label>
                                        {this.state.showDutch ? <div dangerouslySetInnerHTML={this.createMarkup(window.innerWidth > 1008 ? this.state.company_vrienden_dutch : this.state.company_vrienden_dutch_mobile)}></div> : <div dangerouslySetInnerHTML={this.createMarkup(window.innerWidth > 1008 ? this.state.company_vrienden_english : this.state.company_vrienden_english_mobile)}></div>}
                                    </p>
                                </div>)}
                                {/* : (<div className='content-main-inner'>
                                    <p className="para-charity-lfss">
                                        <label className='bottom-line-pclf'>Greets</label>
                                        {this.state.showDutch ? <div dangerouslySetInnerHTML={this.createMarkup(this.state.company_greetings_dutch)}></div> : <div dangerouslySetInnerHTML={this.createMarkup(this.state.company_greetings_english)}></div>}
                                    </p>
                                </div>)} */}
                                {this.state.currentStep > 1 && (<button
                                    className="button-charity-lfss"
                                    style={{ marginRight: 5 }}
                                    onClick={() => {
                                        if (this.state.currentStep > 1) {
                                            if (this.state.currentStep === 2) {
                                                history.push(`/company/${this.props.currentMemberData.user_name_id}/posts`)
                                            } else if (this.state.currentStep === 8) {
                                                history.push(`/company/${this.props.currentMemberData.user_name_id}/live`)
                                            } else if (this.state.currentStep === 3) {
                                                history.push(`/company/${this.props.currentMemberData.user_name_id}`)
                                            } else if (this.state.currentStep === 4) {
                                                history.push(`/company/${this.props.currentMemberData.user_name_id}/products`)
                                            } else if (this.state.currentStep === 5) {
                                                history.push(`/company/${this.props.currentMemberData.user_name_id}/impressie`)
                                            } else if (this.state.currentStep === 6) {
                                                history.push(`/company/${this.props.currentMemberData.user_name_id}/charity`)
                                            }
                                            //  else if (this.state.currentStep === 7) {
                                            //     history.push(`/company/${this.props.currentMemberData.user_name_id}/vriendenDesktop`)
                                            // }
                                            // setCurrentStep(currentStep - 1)
                                            // this.setState({ currentStep: currentStep - 1 })
                                        }
                                    }}
                                >
                                    Vorige
                                </button>)}
                                {(this.state.currentStep < 6 || this.state.currentStep === 8) && (<button
                                    className="button-charity-lfss"
                                    onClick={() => {
                                        if (this.state.currentStep < 6 || this.state.currentStep === 8) {
                                            if (this.state.currentStep === 1) {
                                                history.push(`/company/${this.props.currentMemberData.user_name_id}/posts`)
                                            } else if (this.state.currentStep === 2) {
                                                history.push(`/company/${this.props.currentMemberData.user_name_id}/products`)
                                            } else if (this.state.currentStep === 8) {
                                                history.push(`/company/${this.props.currentMemberData.user_name_id}/impressie`)
                                            } else if (this.state.currentStep === 3) {
                                                history.push(`/company/${this.props.currentMemberData.user_name_id}/live`)
                                            } else if (this.state.currentStep === 4) {
                                                history.push(`/company/${this.props.currentMemberData.user_name_id}/charity`)
                                            } else if (this.state.currentStep === 5) {
                                                if (window.innerWidth > 1008) {
                                                    history.push(`/company/${this.props.currentMemberData.user_name_id}/vriendenDesktop`)
                                                } else {
                                                    history.push(`/company/${this.props.currentMemberData.user_name_id}/vrienden`)
                                                }
                                            }
                                            //  else if (this.state.currentStep === 6) {
                                            //     history.push(`/company/${this.props.currentMemberData.user_name_id}/greetings`)
                                            // }
                                            // this.setState({ currentStep: currentStep + 1 })
                                        }
                                    }}
                                >
                                    Volgende
                                </button>)}
                            </div>
                            <div className="add-items-miur" style={{ padding: "10px 0px" }}>
                                <Switch
                                    color="primary"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                    onClick={() => {
                                        let userid = localStorage.getItem("userid")
                                        if (userid) {
                                            firebase.database().ref(`users/${userid}/hideWizard`).set(true)
                                        }
                                    }}
                                />
                                <label>Niet meer tonen</label>
                            </div>
                        </div>
                    </div>
                </div>
                {window.innerWidth > 1008 && <Footer black />}
                {window.innerWidth < 1008 && <FooterGreen black />}
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        global_values: state.globalReducers.global_values,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateGlobalStuff: (global_values) =>
            dispatch(updateGlobalStuff(global_values)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WizardCompany);