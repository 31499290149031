import React, { Component } from 'react'
import { connect } from 'react-redux'
import TimerBannerSeparate from './TimerBannerSeparate'
import firebase from "../../Config/Firebase"
import { MdClose } from 'react-icons/md'
import { history } from '../../Config/Routes'
import { notifyMe } from '../../Functions/globalFunctions'

export class AnnouncementBannerNew extends Component {


    constructor() {
        super()
        this.state = {
            currentUserLoggedIn: "",
        }
    }

    componentDidMount() {
        let userid = localStorage.getItem("userid")
        if (this.props.user) {
            let filtered = this.props.user.filter((g) => {
                return g.user_id === userid
            })
            if (filtered.length > 0) {
                this.setState({ currentUserLoggedIn: filtered[0] })
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.user !== this.props.user) {
            let userid = localStorage.getItem("userid")
            let filtered = this.props.user.filter((g) => {
                return g.user_id === userid
            })
            if (filtered.length > 0) {
                this.setState({ currentUserLoggedIn: filtered[0] })
            }
        }
    }

    async notifyMe() {
        await notifyMe(this.state.currentUserLoggedIn, this.props.currentCompanyData.user_id)
        // let currentPathKey = firebase.database().ref(`users/${this.state.currentUserLoggedIn.user_id}/subscribed_for_products`).push().key
        // let obj = {
        //     id: currentPathKey,
        //     productsOfCompany: this.props.currentCompanyData.user_id,
        // }
        // firebase.database().ref(`users/${this.state.currentUserLoggedIn.user_id}/subscribed_for_products/${currentPathKey}`).set(obj)
    }

    checkForNotifyButton() {
        if (this.state.currentUserLoggedIn) {
            if (this.state.currentUserLoggedIn.subscribed_for_products) {
                let objected = Object.entries(this.state.currentUserLoggedIn.subscribed_for_products)
                let filtered = objected.filter((g) => {
                    return g[1].productsOfCompany === this.props.currentCompanyData.user_id
                })
                if (filtered.length > 0) {
                    return <button>AANGEMELD</button>
                }
            }
        }

        if (this.state.currentUserLoggedIn.payment_status === "paid" && this.state.currentUserLoggedIn.subscription && this.state.currentUserLoggedIn.subscription.status === "active") {
            return <button onClick={this.notifyMe.bind(this)}>NOTIFY ME</button>
        } else {
            return <button onClick={() => {
                // this.setState({ showSignupPanel: true })
                history.push(`/signup-announcement?companyId=${this.props.currentCompanyData.user_id}`)
            }}>SIGN UP</button>
        }

    }

    render() {
        return (
            <div className='banner-product-main bpm-button-new' style={{ backgroundImage: this.props.announcement.bannerType === "image" ? `url("${this.props.announcement.bannerMedia}")` : "none", height: this.props.avoidMarginTop ? "100vh" : this.props.isAtMembersPage && "100%", marginTop: this.props.avoidMarginTop && 0 }}>
                {/* <div className='login-overlay-main' style={{ opacity: this.state.showSignupPanel ? 1 : 0, pointerEvents: this.state.showSignupPanel ? "all" : "none", zIndex: 999999999 }}>
                    <div className='login-container-lpn'>
                        <MdClose className='icon-person-top-lpn' onClick={() => {
                            this.setState({ showSignupPanel: false })
                        }} />
                        <h2>SIGNUP</h2>
                        <form style={{ width: "100%" }} autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                            <div className={`container-field-cglpn ${this.state.firstNamePopup ? "containerRedAlerCglpn" : ""}`}>
                                <input type="text" placeholder='Voornaam' value={this.state.firstName} onChange={(e) => {
                                    this.setState({ firstName: e.target.value })
                                }} />
                            </div>
                            <div className={`container-field-cglpn ${this.state.lastNamePopup ? "containerRedAlerCglpn" : ""}`}>
                                <input type="text" placeholder='Achternaam' value={this.state.lastName} onChange={(e) => {
                                    this.setState({ lastName: e.target.value })
                                }} />
                            </div>
                            <div className={`container-field-cglpn ${this.state.phoneNumberPopup ? "containerRedAlerCglpn" : ""}`}>
                                <input type="text" placeholder='Telefoon' value={this.state.phoneNumber} onChange={(e) => {
                                    this.setState({ phoneNumber: e.target.value })
                                }} />
                            </div>
                            <div className={`container-field-cglpn ${this.state.loginEmailPopup ? "containerRedAlerCglpn" : ""}`}>
                                <input type="email" autoComplete='new-password' placeholder='E-mail' value={this.state.loginEmail} onChange={(e) => {
                                    this.setState({ loginEmail: e.target.value })
                                }} />
                            </div>
                            <div className={`container-field-cglpn ${this.state.loginPasswordPopup ? "containerRedAlerCglpn" : ""}`}>
                                <input type="password" autoComplete='new-password' placeholder='Wachtwoord' value={this.state.loginPassword} onChange={(e) => {
                                    this.setState({ loginPassword: e.target.value })
                                }} />
                            </div>
                            <div className={`container-field-cglpn formfielddropdown-address`}>
                                <input
                                    type="text"
                                    placeholder="Plaats, straat of postcode"
                                    value={this.state.address}
                                    onChange={(e) => {
                                        this.setState({ address: e.target.value })

                                        this.handlSearchAutoFill(e.target.value)
                                    }}
                                    onFocus={() => {
                                        this.setState({ showingDropdown: true })
                                    }}
                                />
                                {this.state.showingDropdown && (<div className="dropdown-box-new">
                                    {this.state.addressMatches.length > 0 && this.state.addressMatches.map((item) => {
                                        return <li className="list-dbn" onClick={() => {
                                            this.fillFieldsWithContext(item.context)

                                            this.setState({ address: item.value, showingDropdown: false })
                                        }}>
                                            {item.label.split('').map((char, i) => (
                                                <span style={{ fontWeight: i >= item.highlights[0] && i <= item.highlights[1] ? 'bold' : 'normal' }} key={i}>
                                                    {char}
                                                </span>
                                            ))}
                                            {item.description && (<span className="desc-dbn">{item.description}</span>)}
                                        </li>
                                    })}
                                </div>)}
                            </div>
                        </form>
                        <div className='container-button-cglpn'>
                            {this.state.aanmeldenloader ? (
                                <div className="lds-ellipsis">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            ) : (<button onClick={() => this.logIn()} style={{ borderRadius: 6 }}>Aanmelden</button>)}
                        </div>
                    </div>
                </div> */}

                {this.props.announcement.bannerType !== "image" && <video
                    src={this.props.announcement.bannerMedia}
                    autoPlay
                    muted
                    loop
                    data-keepplaying
                    playsInline
                ></video>}
                <div className='overlay-fsblpb'></div>
                <div className='ace-fsblpb'>
                    <h1 style={{ marginBottom: 10, textTransform: "uppercase" }}>{this.props.announcement.title}</h1>
                    {this.props.isAtMembersPage ? (new Date(Date.parse(this.props.announcement.end_date_iso)).getTime() > new Date().getTime() ? <TimerBannerSeparate availabilityTime={new Date(Date.parse(this.props.announcement.end_date_iso)).getTime()} closeScreen={() => {
                        this.setState({ updatingWork: true })
                    }} /> : <p>De producten zijn nu live beschikbaar, wees er snel bij want op=op.</p>) : !this.props.announcement.showAnnouncementWithoutTiming && (<TimerBannerSeparate availabilityTime={new Date(Date.parse(this.props.announcement.end_date_iso)).getTime()} closeScreen={() => {
                        if (this.props.announcementDone) {
                            this.props.announcementDone()
                        }
                    }} />)}
                    <p>{this.props.announcement.desc}</p>
                    {this.props.showNotifyButton && this.checkForNotifyButton()}
                    {/* {this.props.showNotifyButton && ((this.state.currentUserLoggedIn.payment_status === "paid" && this.state.currentUserLoggedIn.subscription && this.state.currentUserLoggedIn.subscription.status === "active") ? this.checkForNotifyButton() : <button onClick={() => {
                        history.push(`/signup-announcement?companyId=${this.props.currentCompanyData.user_id}`)
                    }}>SIGN UP</button>)} */}
                    {this.props.isAtMembersPage && (<button onClick={() => {
                        localStorage.setItem("redirectProducts", true)
                        history.push(`/company/${this.props.currentCompanyData.user_name_id}`)
                    }}>ONTDEK</button>)}
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.authReducers.user,
    };
};

export default connect(
    mapStateToProps,
)(AnnouncementBannerNew);