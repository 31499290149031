import { ArrowBack, ChevronLeft, Close, ExpandMore } from '@material-ui/icons';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import firebase from '../../Config/Firebase';

export class SaloonWebShopCode extends Component {
    constructor() {
        super()
        this.state = {
            webShopCode: "",
            allSaloonsData: [],
            isLoading: false,
            selectedSaloon: "",
            webShopCodeDefault: "",
        }
    }

    componentDidMount() {
        let userid = localStorage.getItem("userid")

        if (this.props.user) {
            let allSaloons = this.props.user.filter((t) => {
                return t.barber_new && t.syncAdminCode
            })

            if (allSaloons.length > 0) {
                this.setState({ allSaloonsData: allSaloons })
            }
        }

        if (this.props.global_values) {
            const { webshopAdminCode } = this.props.global_values
            this.setState({ webShopCode: webshopAdminCode || "", webShopCodeDefault: webshopAdminCode || "" })
        }
    }

    saveWebshopCode() {
        if (this.state.selectedSaloon) {
            firebase.database().ref(`users/${this.state.selectedSaloon}/webshopCodeSaloon`).set(this.state.webShopCode)

            this.state.allSaloonsData.forEach((usr) => {
                if (usr.user_id === this.state.selectedSaloon) {
                    usr.webshopCodeSaloon = this.state.webShopCode
                }
            })

            this.setState({ allSaloonsData: this.state.allSaloonsData })
        } else {
            firebase.database().ref("bhodi_home/webshopAdminCode").set(this.state.webShopCode)

            this.state.allSaloonsData.forEach((usr) => {
                firebase.database().ref(`users/${usr.user_id}/webshopCodeSaloon`).set(this.state.webShopCode)

                usr.webshopCodeSaloon = this.state.webShopCode
            })

            this.setState({ allSaloonsData: this.state.allSaloonsData })
        }
    }

    render() {
        return (
            <>
                <div className="modal-create-album modalcreatealbumchangedheader changedheadermodalcreatealbum">
                    {!this.props.isDesktop && (
                        <ArrowBack
                            onClick={() => {
                                this.setState({
                                    userInfoRight: false,
                                    menuItemsModals: true,
                                });
                            }}
                            style={{
                                position: "absolute",
                                top: 15,
                                left: 10,
                                fontSize: 18,
                            }}
                        />
                    )}
                    <div
                        className="heading-mca"
                        style={{
                            textAlign: "center",
                            position: "relative",
                            padding: "13px",
                        }}
                    >
                        <font>WEBSHOP CODE</font>
                        <div>
                            <ChevronLeft
                                onClick={() => {
                                    this.props.onChevronLeft()
                                }}
                                style={{
                                    fontSize: 18,
                                }}
                            />
                            <div className="spacing-between-icons"></div>
                            <Close
                                onClick={() => {
                                    this.props.onCloseComplete()
                                }}
                                style={{
                                    fontSize: 18,
                                }}
                            />
                        </div>
                    </div>
                    <div className='rightmenuinner-rmmms updatedirghtmenunew newrightmenuglobalgood' style={{ height: "auto", padding: "0px 20px" }}>
                        <div className="filter-administratie">
                            {this.state.allSaloonsData.length > 0 && (<div
                                className="form-miur"
                                style={{ position: "relative" }}
                            >
                                <label>Saloons</label>
                                <div className='wrapper-select-new' style={{ marginBottom: 15 }}>
                                    <select value={this.state.selectedSaloon} onChange={(e) => {
                                        this.setState({ selectedSaloon: e.target.value })

                                        let user = this.state.allSaloonsData.find((t) => {
                                            return t.user_id === e.target.value
                                        })
                                        if (user && user.webshopCodeSaloon) {
                                            this.setState({ webShopCode: user.webshopCodeSaloon })
                                        } else {
                                            this.setState({ webShopCode: this.state.webShopCodeDefault })
                                        }
                                    }}>
                                        <option value="">Alle Saloons</option>
                                        {this.state.allSaloonsData.map((t) => {
                                            return (<option value={t.user_id}>{t.company_name}</option>)
                                        })}
                                    </select>
                                    <ExpandMore
                                        style={{
                                            position: "absolute",
                                            bottom: 5,
                                            right: 5,
                                            pointerEvents: "none",
                                        }}
                                        className='expand-more'
                                    />
                                </div>
                            </div>)}
                            <div
                                className="form-miur"
                                style={{ position: "relative" }}
                            >
                                <label>Webshop Code</label>
                                <div className='wrapper-select-new' style={{ marginBottom: 15 }}>
                                    <input type="text" value={this.state.webShopCode} onChange={(e) => {
                                        this.setState({ webShopCode: e.target.value })
                                    }} />
                                </div>
                            </div>
                            <div className='content-rmmi-rmms'>
                                <div className='save-button-main' style={{ marginTop: 40 }} onClick={() => {
                                    this.saveWebshopCode()
                                }}>
                                    <button>OPSLAAN</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducers.user,
        global_values: state.globalReducers.global_values,
    };
};

export default connect(mapStateToProps)(SaloonWebShopCode)