import localforage from 'localforage';

let bookmarked_things = ""
let global_campaigns = ""
let vouchersectors = ""

localforage.getItem("persist:root").then((value) => {
    if (value) {
        let converted = JSON.parse(value)
        if (converted.campaignsReducer) {
            let parsed = JSON.parse(converted.campaignsReducer)
            if (parsed.bookmarked_things) {
                bookmarked_things = parsed.bookmarked_things
            }
            if (parsed.global_campaigns) {
                global_campaigns = parsed.global_campaigns
            }
            if (parsed.vouchersectors) {
                vouchersectors = parsed.vouchersectors
            }
        }
    }
})


const initial_state = {
    // product: null,
    // vouchers: null,
    // globalvouchers: null,
    // globalshopproducts: null,
    // shopproducts: null,
    // allVouchers: null,
    vouchersectors: vouchersectors ? vouchersectors : null,
    bookmarked_things: bookmarked_things ? bookmarked_things : null,
    global_campaigns: global_campaigns ? global_campaigns : null,
}

const reducer = (state = initial_state, action) => {
    switch (action.type) {
        // case "get_all_products": {
        //     return { ...state, product: action.product }
        // }
        // case "get_all_vouchers": {
        //     return { ...state, vouchers: action.vouchers }
        // }
        // case "get_all_global_vouchers": {
        //     return { ...state, globalvouchers: action.globalvouchers }
        // }
        // case "get_all_global_products": {
        //     return { ...state, globalshopproducts: action.globalshopproducts }
        // }
        // case "all_shop_products": {
        //     return { ...state, shopproducts: action.shopproducts }
        // }
        // case "all_vouchers": {
        //     return { ...state, allVouchers: action.allVouchers }
        // }
        case "all_vouchers_sc": {
            return { ...state, vouchersectors: action.vouchersectors }
        }
        case "update_bookmarked_things": {
            return { ...state, bookmarked_things: action.bookmarked_things }
        }
        case "update_all_campaigns": {
            return { ...state, global_campaigns: action.global_campaigns }
        }
        // case "update_bewaared_campaigns": {
        //     return { ...state, bewaared_campaigns: action.bewaared_campaigns }
        // }
        // case "update_all_products_new": {
        //     return { ...state, products_new: action.products_new }
        // }
        default: {
            return state;
        }
    }
}

export default reducer