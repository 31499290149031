import React, { Component } from "react";
import Routes from "./Config/Routes";
// import { persistor } from './Redux/store'
import { store, persistor } from "./Redux/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import firebase from "./Config/Firebase"

// const store = configureStore()

class App extends Component {
  componentDidMount() {
    const loaderId = document.getElementById("main-page-loader-id");
    loaderId.style.display = "none";


    this.pingInterval = setInterval(this.pingFirebase, 1 * 60 * 1000); // 5 minutes in milliseconds
    // Handle visibility change
    document.addEventListener("visibilitychange", this.handleVisibilityChange);
  }

  componentWillUnmount() {
    // Clear the interval and remove the visibility change event listener
    clearInterval(this.pingInterval);
    document.removeEventListener("visibilitychange", this.handleVisibilityChange);
  }

  pingFirebase = () => {
    // Perform a simple read operation to keep the connection alive
    // firebase.database().ref("guest_user_cookie").once("value", snapshot => {
    //   // This will trigger a connection to Firebase
    // });
    firebase.database().ref("testing_bg_work").set(Date.now())
  };

  handleVisibilityChange = () => {
    if (document.hidden) {
      clearInterval(this.pingInterval);
    } else {
      this.pingInterval = setInterval(this.pingFirebase, 1 * 60 * 1000); // Restart interval
    }
  }



  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Routes />
        </PersistGate>
      </Provider>
    );
  }
}


export default App;
