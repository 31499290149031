import React, { Component } from "react";
import firebase from "../../Config/Firebase";
import Footer from "../Footer";
import {
    updateProducts,
    updateGlobalVouchers,
    updateAllSectors,
    updateGuestUserCart,
    updateGuestUserCartTime,
    updateGuestUserCartExtendOpportunity,
} from "../../Redux/actions/authActions";
import { connect } from "react-redux";
import ReactFullpage from "@fullpage/react-fullpage";
import CloseIcon from "@material-ui/icons/Close";
import EventsNewMobile from "../../Containers/EventsNewMobile";
import NavGlobalVoucherEventsCity from "../NavGlobalVoucherEventsCity";
import { checkForSubscribedExclusives } from "../../Functions/checkForSubscribedExclusives";
import NavbarMainFullWidthCompany from "../NavSecMainFullWidthCompany";
import FriendListRightMenu from "../FriendListRightMenu";
import { history } from "../../Config/Routes";
import NavbarMainFullWidth from "../NavbarMainFullWidth";
import NavbarWrapperVisitorMobile from "../NavbarWrapperVisitorMobile";
import VouchersNewGridDesktop from "../VouchersNewGridDesktop";
import { mergeAdminEvent } from "../../Functions/globalFunctions";

const initialState = {
    beschikbaar: true,
    popuprightToShow: false,
    titleProduct: "",
    normalPriceProduct: "",
    charityPriceProduct: "",
    descProduct: "",
    currentImageIndex: 0,
    arrImages: [],
    addCategory: false,
    gebruikers: false,
    addTimer: false,
    activateTimer: false,
    obj: {
        "category 1": {
            sub_category: "sub category 1",
        },
        "category 2": {
            sub_category: "sub category 2",
        },
    },
    isMain: "category 1",
    selectedMain: "category 1",
    selectedSub: "sub category 1",
    isSub: "sub category 1",
    daysIndex: 0,
    hoursIndex: -1,
    minutesIndex: -1,
    defaultQty: 1,
    allProducts: [],
    dropFile: [],
    filesName: [],
    productsIndex: 0,
    reachedProductsIndex: 0,
    hour: "",
    minutes: "",
    productActivated: true,
    firstTime: true,
    beforeUnloaded: false,
    currentUserProduct: true,
    newerProducts: false,
    seenProductsCheck: false,
    newProducts: [],
    seenProducts: [],
    currentProductsShowing: [],
    showAllProducts: false,
    arrCurrentProducts: [],
    cartData: [],
    selectCategoryPopup: false,
    uploadImagePopup: false,
    productDetailOpen: false,
    dayBigger: false,
    showingBookMarkProducts: false,
    allMonths: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    hours: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23,
    ],
    productStatus: ["exclusive", "normal"],
    productStatusIndex: 0,
    sectors: [],
    currentSector: "",
    currentSectorId: "",
    categoryName: "",
    currentSector: "",
    all_categories: [],
    sub_categories: [],
    completeCategories: [],
    subCategoryPresent: true,
    currentUserHere: "",
    fullpageApi: "",
    scrollStart: true,
    scrollStop: false,
    eventDate: "",
    startHour: "",
    endHour: "",
    startMinute: "",
    endMinute: "",
    showTimerFieldsEmptyWork: false,
    counterMinute: 1,
    counterSecond: 59,
    currentActie: "",
    dateToday: "",
    endDateToday: "",
    muchDaysEvent: false,
    payOnline: false,
    isDesktop: false,
    currentProductDetailed: "",
    allProductsFiltered: [],
    noFilteredProducts: false,
    searchText: "",
    availableMainCategories: [],
    availableSectors: [],
    availableSubCategories: [],
    categoriesDropdown: [],
    subCategoriesCheck: [],
    subCategoriesDropdown: [],
    checkedToFilter: [],
    allProductsDup: [],
    allProductsFilteredDup: [],
    selectSectorsPopup: false,
    allProductsForSectors: [],
    allSectors: [],
    categoriesToFilter: [],
    latitude: "",
    longitude: "",
    toShowSections: [],
    firstSectionToShow: [],
    onCurrentPage: 0,
    allCampaigns: [],
    currentVoucherWork: "",
    activeSection: 0,
    hideFullpage: false,
    showOptionsToAdd: false,
    showDescriptionPopup: false,
    codegebruikpopup: false,
    codebewaarpopup: false,
    selectedDish: 0,
    alreadyBewared: [],
    mustShowAnimation: false,
    useOfVoucherOpen: false,
    exceptionsVoucherOpen: false,
    allVouchers: [],
    completeArrProducts: [],
    filteredProductsToShow: [],
    currentWeekEvents: [],
    comingWeekEvents: [],
    urlParams: null,
    currentUserId: '',
    currentUserCharityMember: false,
    currentUser: "",
    orderidToUpload: 0,
    guestusercart: [],

    selectedTimeIndex: 0,
    selectedTiming: false,
    reserved_event_id: 1,
};

let ordersRef = firebase.database().ref("current_order_id")

export class EventsDesktopGrid extends Component {
    constructor() {
        super();
        this.state = {
            ...initialState,
        };
    }

    getVoucherEventInfo() {
        this.setState({ ...initialState });

        if (this.props.guest_user_cart) {
            this.setState({ guestUserCart: this.props.guest_user_cart });
        }

        if (window.innerWidth < 1009) {
            this.setState({ isDesktop: false });
        } else {
            this.setState({ isDesktop: true });
        }

        window.addEventListener("resize", () => {
            if (window.innerWidth < 1009) {
                this.setState({ isDesktop: false });
            } else {
                this.setState({ isDesktop: true });
            }
        });


        ordersRef.on("value", (data) => {
            let a = data.val()

            if (a) {
                this.setState({ orderidToUpload: Number(a) });
            }
        })

        if (this.props.globalsectors) {
            if (this.props.globalsectors.length > 0) {
                this.setState({ allSectors: this.props.globalsectors });
            }
        }

        let localCurrent = localStorage.getItem("onCurrentSection");
        if (localCurrent) {
            setTimeout(() => {
                localStorage.removeItem("onCurrentSection");
                if (this.state.fullpageApi) {
                    this.state.fullpageApi.moveTo(Number(localCurrent), 0)
                }
            }, 1000);
        }

        let uid = localStorage.getItem("userid");
        if (uid) {
            if (this.props.user) {
                if (this.props.user.length > 0) {
                    let filtered = this.props.user.filter((y) => {
                        return y.user_id === uid;
                    });
                    if (filtered.length > 0) {
                        if (filtered[0].subscription) {
                            if (filtered[0].subscription.status === "active") {
                                this.setState({ currentUserCharityMember: true });
                            } else {
                                if (checkForSubscribedExclusives(filtered[0])) {
                                    this.setState({ currentUserCharityMember: true })
                                }
                            }
                        } else {
                            if (checkForSubscribedExclusives(filtered[0])) {
                                this.setState({ currentUserCharityMember: true })
                            }
                        }
                        this.setState({ currentUserHere: filtered[0], currentUserId: uid });
                    }
                }
            }
        }

        this.props.completeArrProducts.map((e) => {
            e.selectedTimeIndex = 0
            e.reserved_event_id = 1
            e.selectedTiming = false
            e.dropdownOpenTiming = false
        })

        this.props.allProducts.map((e) => {
            e.selectedTimeIndex = 0
            e.reserved_event_id = 1
            e.selectedTiming = false
            e.dropdownOpenTiming = false
        })

        this.props.filteredProductsToShow.map((e) => {
            e.selectedTimeIndex = 0
            e.reserved_event_id = 1
            e.selectedTiming = false
            e.dropdownOpenTiming = false
        })

        this.setState({
            allProducts: this.props.allProducts,
            completeArrProducts: this.props.completeArrProducts,
            filteredProductsToShow: this.props.filteredProductsToShow,
            toShowSections: this.props.toShowSections,
            allProductsDup: this.props.allProductsDup,
            allProductsForSectors: this.props.allProductsForSectors,
            noProducts: false,
        });
    }

    componentDidMount() {
        this.setState({ urlParams: this.props });
        this.getVoucherEventInfo();

        const loaderId = document.getElementById("main-page-loader-id");
        loaderId.style.display = "none";
    }

    componentWillUnmount() {
        ordersRef.off()
    }

    onLeave(origin, destination, direction) {
        if (Number(origin.index) > 0) {
            this.setState({ countsArray: [] });
        }
    }

    afterLoad(origin, destination, direction) {
        this.setState({ countsArray: [], onCurrentPage: destination.index });
    }

    getTouches(evt) {
        return (
            evt.touches || // browser API
            evt.originalEvent.touches
        ); // jQuery
    }

    componentDidUpdate(prevProps) {
        let uid = localStorage.getItem("userid");

        if (this.props.user !== prevProps.user) {
            if (this.props.user.length > 0) {
                let filtered = this.props.user.filter((y) => {
                    return y.user_id === uid;
                });
                if (filtered.length > 0) {
                    this.setState({ currentUserHere: filtered[0] });
                }
                // navigator.geolocation.getCurrentPosition((position) => {
                //     this.setState({ latitude: position.coords.latitude, longitude: position.coords.longitude })
                // });
                let latitude = localStorage.getItem("latitudeAfterLogin");
                let longitude = localStorage.getItem("longitudeAfterLogin");
                if (latitude) {
                    this.setState({ latitude, longitude });
                }
            }
        }

        if (this.props.guest_user_cart !== prevProps.guest_user_cart) {
            this.setState({ guestUserCart: this.props.guest_user_cart });
        }

        if (this.props.global_campaigns !== prevProps.global_campaigns) {
            let allArrs = this.props.global_campaigns;
            let allKeysCurrent = [];
            let allKeysComingCampaigns = [];
            this.state.filteredProductsToShow.map((u) => {
                allKeysCurrent.push(u.pushKey);
            });

            this.state.filteredProductsToShow.map((t, i) => {
                let checkProductDeleted = allArrs.filter((y) => {
                    return t.pushKey === y[1].pushKey
                })
                if (checkProductDeleted.length === 0) {
                    this.state.filteredProductsToShow[i].showPopupNotLeft = true
                }
            });
            allArrs.map((y) => {
                y[1].type = "campaign";
                y[1].currentImageIndex = 0;
                allKeysComingCampaigns.push(y[1].pushKey);
                if (y[1].desc) {
                    // y[1].desc = y[1].desc.substr(0, 200)
                    y[1].short_desc = y[1].desc.substr(0, 32);
                }
                y[1].images = [y[1].image];
                y[1].imagesObjected = [{ image: y[1].image, pushKey: y[1].pushKey }];

                if (y[1].campaignType === "local_event") {
                    if (y[1].takeFromAdmin) {
                        let obj = mergeAdminEvent(y[1], this.props.global_campaigns)
                        y[1] = Object.assign({}, obj)
                    }
                    this.state.filteredProductsToShow.map((t, i) => {
                        if (t.pushKey === y[1].pushKey) {
                            if (!y[1].active) {
                                y[1].showPopupNotLeft = true;
                            } else {
                                y[1].showPopupNotLeft = false;
                            }

                            let obj = {
                                ...y[1]
                            }

                            if (t.selectedTimeIndex) {
                                obj.selectedTimeIndex = t.selectedTimeIndex
                            } else {
                                obj.selectedTimeIndex = 0
                            }
                            if (t.dropdownOpenTiming) {
                                obj.dropdownOpenTiming = t.dropdownOpenTiming
                            }
                            if (t.selectedTiming) {
                                obj.selectedTiming = t.selectedTiming
                            }
                            if (t.reserved_event_id) {
                                obj.reserved_event_id = t.reserved_event_id
                            }

                            this.state.filteredProductsToShow[i] = obj;
                        }
                    });
                } else
                    if (y[1].campaignType === "web_shop") {
                        this.state.filteredProductsToShow.map((t, i) => {
                            if (t.pushKey === y[1].pushKey) {
                                if (!y[1].active) {
                                    y[1].showPopupNotLeft = true;
                                } else {
                                    y[1].showPopupNotLeft = false;
                                }

                                let obj = {
                                    ...y[1]
                                }

                                this.state.filteredProductsToShow[i] = obj;
                            }
                        });
                    }
            });

            this.setState({
                allProducts: this.state.allProducts,
            });
        }
    }

    getSellerName(e) {
        let filtered = this.props.user.filter((g) => {
            return g.user_id === e.created_by;
        });
        if (filtered.length > 0) {
            return `${filtered[0].company_name} | ${filtered[0].city}`;
            // return filtered[0].company_name;
        }
    }

    getSellerImage(e) {
        let filtered = this.props.user.filter((g) => {
            return g.user_id === e.created_by;
        });
        if (filtered.length > 0) {
            if (filtered[0].profile_image) {
                return filtered[0].profile_image;
            } else {
                return "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9";
            }
        }
    }


    getSellerRedirect(e) {
        let filtered = this.props.user.filter((g) => {
            return g.user_id === e.created_by;
        });
        if (filtered.length > 0) {
            history.push(`/company/${filtered[0].user_name_id}`)
        }
    }

    getSellerCity(e) {
        let filtered = this.props.user.filter((g) => {
            return g.user_id === e.created_by;
        });
        if (filtered.length > 0) {
            return filtered[0].city;
        }
    }

    getDayName(num) {
        if (num === 0) {
            return `Zondag`;
        } else if (num === 1) {
            return `Maandag`;
        } else if (num === 2) {
            return `Dinsdag`;
        } else if (num === 3) {
            return `Woensdag`;
        } else if (num === 4) {
            return `Donderdag`;
        } else if (num === 5) {
            return `Vrijdag`;
        } else if (num === 6) {
            return `Zaterdag`;
        }
    }

    getMonthName(i) {
        i = Number(i);
        if (i === 1) {
            return "Januari";
        } else if (i === 2) {
            return "Februari";
        } else if (i === 3) {
            return "Maart";
        } else if (i === 4) {
            return "April";
        } else if (i === 5) {
            return "Mei";
        } else if (i === 6) {
            return "Juni";
        } else if (i === 7) {
            return "Juli";
        } else if (i === 8) {
            return "Augustus";
        } else if (i === 9) {
            return "September";
        } else if (i === 10) {
            return "Oktober";
        } else if (i === 11) {
            return "November";
        } else if (i === 12) {
            return "December";
        }
    }


    getSectorName() {
        if (this.state.allSectors.length > 0) {
            let filtered = this.state.allSectors.filter((t) => {
                return t.sector_id === this.props.currentSector;
            });
            return filtered[0].sector_name;
        }
    }

    createDescPopup(e) {
        if (e) {
            return { __html: e };
        }
    }


    getStartTimeOfReservedEvent(event) {
        if (event) {
            if (event.campaignType === "local_event") {
                let filtered = event.timings
                if (filtered.length > 0) {
                    return filtered[this.state.selectedTimeIndex].startTimeEvent;
                }
            }
        }
    }

    getEndTimeOfReservedEvent(event) {
        if (event) {
            if (event.campaignType === "local_event") {
                let filtered = event.timings
                if (filtered.length > 0) {
                    return filtered[this.state.selectedTimeIndex].endTimeEvent;
                }
            }
        }
    }


    render() {
        return (
            <div
                className={`vouchersglobalpagemaintodoWhite ${this.state.firstSectionToShow.length === 1 && "dofullwidthvouchersectionmain"}`}
            >
                {/* friends list */}
                {this.state.showRightMenuFriends && (
                    <div
                        className="overlay-div-openadd"
                        onClick={() => {
                            this.setState({ showRightMenuFriends: false });
                        }}
                    ></div>
                )}
                {this.state.showRightMenuFriends && (
                    <FriendListRightMenu
                        closePopup={(type) => {
                            if (type === "fromfriendscompletely") {
                                this.setState({ closeCompleteRightMenu: true });
                            }
                            this.setState({ showRightMenuFriends: false });
                        }}
                    />
                )}

                {this.state.isDesktop && !this.props.hideHeader && (
                    this.state.currentUserHere.type === "company" ? (<NavbarMainFullWidthCompany
                        menuItemsShowing={true}
                        member_id={this.state.currentUserHere.user_name_id}
                        showPosts={() => {
                            localStorage.setItem("onCurrentTabCompany", "showLists");
                            history.push(
                                `/company/${this.state.currentUserHere.user_name_id}`
                            );
                            // Commented by me
                            // window.location.href = `/company/${this.state.currentMemberData.user_name_id}`;
                        }}
                        showFriends={() => {
                            this.setState({ showRightMenuFriends: true });
                        }}
                        showProductsOfFriends={() => {
                            localStorage.setItem(
                                "onCurrentTabCompany",
                                "showproductsfriends"
                            );
                            // Commented by me
                            history.push(
                                `/company/${this.state.currentUserHere.user_name_id}/volgend`
                            );
                        }}
                        showCharity={() => {
                            localStorage.setItem(
                                "onCurrentTabCompany",
                                "showCharity"
                            );
                            // Commented by me
                            history.push(
                                `/company/${this.state.currentUserHere.user_name_id}/charity`
                            );
                            // window.location.href = `/company/${this.state.currentMemberData.user_name_id}/charity`;
                        }}
                        hideMenuWork={false}

                        onGlobalPageEvents={true}
                        sector={this.props.currentSector}
                        isDezeWeek={this.props.isDezeWeek}
                        isVolgendeWeek={this.props.isVolgendeWeek}
                        noCityFiltered={this.props.noCityFiltered ? true : false}
                        searchFiltered={(e) => {
                            if (e !== "") {
                                let filtered = this.state.completeArrProducts.filter(
                                    (y) => {
                                        let filteredUser = this.props.user.filter((j) => {
                                            return j.user_id === y.created_by;
                                        });
                                        if (filteredUser.length > 0) {
                                            return (
                                                y.title
                                                    .toLowerCase()
                                                    .includes(e.toLowerCase()) || y.desc.toLowerCase().includes(e.toLowerCase()) ||
                                                filteredUser[0].company_name
                                                    .toLowerCase()
                                                    .includes(e.toLowerCase())
                                            );
                                        }
                                    }
                                );

                                let filteredHere = [...filtered];

                                if (filtered.length > 0) {
                                    this.setState({
                                        allProducts: [],
                                    });

                                    setTimeout(() => {
                                        this.setState({
                                            allProducts: filtered,
                                            filteredProductsToShow: filteredHere,
                                        });
                                    }, 10);
                                } else {
                                    this.setState({
                                        allProducts: [],
                                        filteredProductsToShow: [],
                                    });
                                }
                            } else {
                                let filtered = [...this.state.completeArrProducts];
                                let filteredHere = [...filtered];

                                this.setState({
                                    allProducts: [],
                                });

                                setTimeout(() => {
                                    this.setState({
                                        allProducts: filtered,
                                        filteredProductsToShow: filteredHere,
                                    });
                                }, 10);
                            }
                        }}
                    />) : (<NavbarMainFullWidth
                        member_id={this.state.currentUserHere.user_name_id}
                        linkToVolgend={`/member/${this.state.currentUserHere.user_name_id}/volgend`}
                        linkToCharity={`/member/${this.state.currentUserHere.user_name_id}/charity`}
                        showProducts={() => {
                            localStorage.setItem("onCurrentTab", "products");
                        }}
                        showFriends={() => {
                            this.setState({ showRightMenuFriends: true });
                        }}
                        showCharity={() => {
                            localStorage.setItem("onCurrentTab", "charitypage");
                        }}


                        onGlobalPageEvents={true}
                        sector={this.props.currentSector}
                        isDezeWeek={this.props.isDezeWeek}
                        isVolgendeWeek={this.props.isVolgendeWeek}
                        noCityFiltered={this.props.noCityFiltered ? true : false}
                        searchFiltered={(e) => {
                            if (e !== "") {
                                let filtered = this.state.completeArrProducts.filter(
                                    (y) => {
                                        let filteredUser = this.props.user.filter((j) => {
                                            return j.user_id === y.created_by;
                                        });
                                        if (filteredUser.length > 0) {
                                            return (
                                                y.title
                                                    .toLowerCase()
                                                    .includes(e.toLowerCase()) || y.desc.toLowerCase().includes(e.toLowerCase()) ||
                                                filteredUser[0].company_name
                                                    .toLowerCase()
                                                    .includes(e.toLowerCase())
                                            );
                                        }
                                    }
                                );

                                let filteredHere = [...filtered];

                                if (filtered.length > 0) {
                                    this.setState({
                                        allProducts: [],
                                    });

                                    setTimeout(() => {
                                        this.setState({
                                            allProducts: filtered,
                                            filteredProductsToShow: filteredHere,
                                        });
                                    }, 10);
                                } else {
                                    this.setState({
                                        allProducts: [],
                                        filteredProductsToShow: [],
                                    });
                                }
                            } else {
                                let filtered = [...this.state.completeArrProducts];
                                let filteredHere = [...filtered];

                                this.setState({
                                    allProducts: [],
                                });

                                setTimeout(() => {
                                    this.setState({
                                        allProducts: filtered,
                                        filteredProductsToShow: filteredHere,
                                    });
                                }, 10);
                            }
                        }}
                    />)
                )}

                {this.state.isDesktop ? (
                    <>
                        <VouchersNewGridDesktop
                            completeArrProducts={this.state.completeArrProducts}
                            filteredProductsToShow={this.state.filteredProductsToShow}
                            allProducts={this.state.allProducts}
                            noProducts={this.state.noProducts}
                        />
                        <Footer getData={this.state.deletingItem} notOnMainPage={true} />
                    </>
                ) : (
                    <div
                        className="mobileshopglobal mobilevouchersglobal new-vouchers-page nvp-total-fixed"
                        style={{ backgroundColor: "white" }}
                    >
                        <NavbarWrapperVisitorMobile
                            showSearchOnGlobal={true}
                            searchFiltered={(e) => {
                                if (e !== "") {
                                    let filtered = [...this.state.filteredProductsToShow].filter(
                                        (y) => {
                                            let filteredUser = this.props.user.filter((j) => {
                                                return j.user_id === y.created_by;
                                            });
                                            if (filteredUser.length > 0) {
                                                return (
                                                    y.title.toLowerCase().includes(e.toLowerCase()) || y.desc.toLowerCase().includes(e.toLowerCase()) ||
                                                    filteredUser[0].company_name
                                                        .toLowerCase()
                                                        .includes(e.toLowerCase())
                                                );
                                            }
                                        }
                                    );

                                    this.setState({
                                        completeArrProducts: [],
                                        noProductsHere: false,
                                    });
                                    setTimeout(() => {
                                        if (filtered.length > 0) {
                                            this.setState({
                                                completeArrProducts: filtered,
                                                noProductsHere: false,
                                            });
                                        } else {
                                            this.setState({ completeArrProducts: [], noProductsHere: true });
                                        }
                                    }, 100);
                                } else {
                                    this.setState({
                                        completeArrProducts: [],
                                    });

                                    setTimeout(() => {
                                        this.setState({
                                            completeArrProducts: this.state.filteredProductsToShow,
                                        });
                                    }, 100);
                                }
                            }} />
                        {/* <div className="space-globalpages space-globalpagesevents"></div> */}
                        {this.state.completeArrProducts.length > 0 &&
                            !this.state.noProductsHere && !this.props.hideHeader && (
                                <div className="top-event-page-new">
                                    <label className="heading-tepn">{this.getSectorName()}</label>
                                    <NavGlobalVoucherEventsCity
                                        sector={this.props.currentSector}
                                        onMainSector={(!this.props.isDezeWeek && !this.props.isVolgendeWeek) ? true : false}
                                        onCurrentWeek={this.props.isDezeWeek ? true : false}
                                        onComingWeek={this.props.isVolgendeWeek ? true : false}
                                        noCityFiltered={this.props.noCityFiltered ? true : false}
                                        noShowCity={true}
                                        cityOnNewDesign={true}
                                    />
                                </div>
                            )}
                        {(this.state.completeArrProducts.length > 0 && !this.state.noProductsHere && !this.props.hideHeader && this.props.onBookmarkedPage) && (
                            <div className="top-event-page-new">
                                <label className="heading-tepn">Bookmarked Events</label>
                            </div>
                        )}
                        {this.state.completeArrProducts.length > 0 &&
                            !this.state.noProductsHere && (
                                <EventsNewMobile
                                    productsWhole={this.state.completeArrProducts}
                                    lastNodeEmpty={true}
                                    onEventsPage={true}
                                />
                            )}
                        {this.state.noProductsHere && (
                            <ReactFullpage
                                // navigation
                                onLeave={this.onLeave.bind(this)}
                                afterLoad={this.afterLoad.bind(this)}
                                // responsiveWidth="1008"
                                normalScrollElements=".posts-detailed-popup, .charity-confirmation-complete, .loaderareaduplicate, .bg-overlaymini, .mnicart-flyout-inner, .rightmenucompletewidthaddproduct, .rightmenucommentsaddproduct"
                                render={({ state, fullpageApi }) => {
                                    return (
                                        <div>
                                            <div className="section" id="sectionnotheight">
                                                <div
                                                    className="inner-shop-container normalimgscampaigns containerEmptyPage"
                                                    style={{
                                                        backgroundImage: `none`,
                                                        backgroundColor: "#285151",
                                                    }}
                                                >
                                                    <div className="container-contemptpage">
                                                        <h3>Geen resultaten</h3>
                                                    </div>
                                                    {/* <div className='overlay-volgendpage' style={{ backgroundColor: `rgba(0,0,0,${this.state.globalOverlay})` }}></div> */}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }}
                            />
                        )}
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducers.user,
        album: state.postsReducers.album,
        images: state.postsReducers.images,
        product: state.campaignsReducer.product,
        globalvouchers: state.campaignsReducer.globalvouchers,
        globalsectors: state.categoriesReducer.globalsectors,
        global_campaigns: state.campaignsReducer.global_campaigns,
        global_values: state.globalReducers.global_values,
        guest_user_cart: state.cartReducer.guest_user_cart,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateProducts: (product) => dispatch(updateProducts(product)),
        updateGlobalVouchers: (vouchers) =>
            dispatch(updateGlobalVouchers(vouchers)),
        updateAllSectors: (globalsectors) =>
            dispatch(updateAllSectors(globalsectors)),
        updateGuestUserCart: (guest_user_cart) =>
            dispatch(updateGuestUserCart(guest_user_cart)),
        updateGuestUserCartTime: (guest_user_cart_time) =>
            dispatch(updateGuestUserCartTime(guest_user_cart_time)),
        updateGuestUserCartExtendOpportunity: (
            guest_user_cart_extend_opportunity
        ) =>
            dispatch(
                updateGuestUserCartExtendOpportunity(guest_user_cart_extend_opportunity)
            ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventsDesktopGrid);
