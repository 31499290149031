import React, { Component } from "react";

export class ButtonTimerProductsNew extends Component {

    constructor() {
        super()
        this.state = {
            intervalStartedOnEdit: false,
        }
    }

    componentDidMount() {
        const loaderId = document.getElementById("main-page-loader-id");
        if (loaderId) {
            loaderId.style.display = "none";
        }
        this.interval = setInterval(
            () => this.setState({ timeCurrent: Date.now() }),
            1000
        );
    }

    componentWillUnmount() {
        clearInterval(this.interval)
    }

    componentDidUpdate(prevProps) {
        if (this.props.timerToDo !== prevProps.timerToDo && !this.state.intervalStartedOnEdit) {

            var date = new Date(this.props.timerToDo);
            // var date = new Date(this.props.timerToDo > dateMain.getTime() ? this.props.timerToDo : dateMain.getTime());

            let dateFuture = date.getTime();

            let dateNow = new Date().getTime();

            if (dateFuture > dateNow) {
                clearInterval(this.interval);
                this.setState({ intervalStartedOnEdit: true })

                setTimeout(() => {
                    this.interval = setInterval(
                        () => this.setState({ timeCurrent: Date.now() }),
                        1000
                    );
                }, 3000);
            }
        }
    }

    getMonthName(i) {
        i = Number(i);
        if (i === 1) {
            return "jan";
        } else if (i === 2) {
            return "feb";
        } else if (i === 3) {
            return "mrt";
        } else if (i === 4) {
            return "apr";
        } else if (i === 5) {
            return "mei";
        } else if (i === 6) {
            return "jun";
        } else if (i === 7) {
            return "jul";
        } else if (i === 8) {
            return "aug";
        } else if (i === 9) {
            return "sep";
        } else if (i === 10) {
            return "okt";
        } else if (i === 11) {
            return "nov";
        } else if (i === 12) {
            return "dec";
        }
    }

    calculateTimeStamp() {
        // let dateMain = new Date("2023-02-23T19:00:00")

        var date = new Date(this.props.timerToDo);
        // var date = new Date(this.props.timerToDo > dateMain.getTime() ? this.props.timeToCalculate : dateMain.getTime());

        let dateFuture = date.getTime();

        let dateNow = new Date().getTime();

        let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

        let days = Math.floor(diffInMilliSeconds / 86400);
        let daysNumber = Math.floor(diffInMilliSeconds / 86400);
        diffInMilliSeconds -= days * 86400;

        let hours = Math.floor(diffInMilliSeconds / 3600) % 24;
        diffInMilliSeconds -= hours * 3600;

        let minutes = Math.floor(diffInMilliSeconds / 60) % 60;
        diffInMilliSeconds -= minutes * 60;

        let seconds = diffInMilliSeconds % 60;

        let difference = "";
        if (days > 0) {
            difference += days === 1 ? `${days}:` : `${days}:`;
        }

        if (days < 10) {
            days = `0${days}`;
        } else {
            days = `${days}`;
        }

        if (hours < 10) {
            hours = `0${hours}`;
        } else {
            hours = `${hours}`;
        }

        if (minutes < 10) {
            minutes = `0${minutes}`;
        } else {
            minutes = `${minutes}`;
        }

        if (seconds < 9) {
            seconds = `0${Math.ceil(seconds)}`;
        } else {
            seconds = `${Math.ceil(seconds)}`;
        }

        if (dateFuture > dateNow) {
            if (Number(days) === 0 && Number(minutes) < 5) {
                return (
                    <button className="mainbtntimer" onClick={() => {
                        if (this.props.btnClicked) {
                            this.props.btnClicked()
                        }
                    }}>
                        {/* {Number(hours) > 0 && <span>{hours} u </span>} */}
                        {Number(minutes) > 0 && <span>{minutes} min&nbsp;</span>}
                        <span>{seconds} sec</span>
                    </button>
                );
            } else {
                let hoursMain = date.getHours()
                let minutesMain = date.getMinutes()
                if (hoursMain < 10) {
                    hoursMain = `0${hoursMain}`;
                } else {
                    hoursMain = `${hoursMain}`;
                }

                if (minutesMain < 10) {
                    minutesMain = `0${minutesMain}`;
                } else {
                    minutesMain = `${minutesMain}`;
                }
                return (
                    <button style={{ padding: "8px 20px" }} className="mainbtntimer" onClick={() => {
                        if (this.props.btnClicked) {
                            this.props.btnClicked()
                        }
                    }}>
                        <span>{date.getDate()} {this.getMonthName(date.getMonth() + 1)} om {hoursMain}:{minutesMain} uur</span>
                    </button>
                );
            }
        } else {
            if (this.props.closeScreen) {
                this.props.closeScreen()
            }
            clearInterval(this.interval);
            return <></>;
        }
    }

    render() {
        return this.calculateTimeStamp();
    }
}

export default ButtonTimerProductsNew;
