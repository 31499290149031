import React, { Component } from 'react'
import { connect } from 'react-redux';
import { ChevronLeft } from '@material-ui/icons';
import SearchIcon from "@material-ui/icons/Search";
import Switch from "@material-ui/core/Switch";
import firebase from '../Config/Firebase';

export class GrantCompanyAdministratie extends Component {

    constructor() {
        super()
        this.state = {
            allCompanies: [],
            allCompaniesFilters: [],
            allCompaniesDuplicate: []
        }
    }

    componentDidMount() {
        if (this.props.user) {
            let filtered = this.props.user.filter((g) => {
                return g.type === "company"
            })
            if (filtered.length > 0) {
                this.setState({ allCompanies: filtered, allCompaniesDuplicate: filtered })
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.user !== this.props.user) {
            if (this.state.allCompaniesFilters.length > 0) {
                this.state.allCompaniesFilters.map((t, i) => {
                    let filtered = this.props.user.filter((g) => {
                        return g.user_id === t.user_id
                    })
                    if (filtered.length > 0) {
                        this.state.allCompaniesFilters[i] = filtered[0]
                    }
                })
                this.setState({ allCompaniesFilters: this.state.allCompaniesFilters })
            } else if (!this.state.noFiltersCompanies) {
                this.state.allCompanies.map((t, i) => {
                    let filtered = this.props.user.filter((g) => {
                        return g.user_id === t.user_id
                    })
                    if (filtered.length > 0) {
                        this.state.allCompanies[i] = filtered[0]
                    }
                })
                this.setState({ allCompanies: this.state.allCompanies })
            }
        }
    }

    render() {
        return (
            <div className='right-menu-main-settings'>
                <div className='rightmenuinner-rmmms updatedirghtmenunew newrightmenuglobalgood newrightmenucompaniesname'>
                    <div className='header-rmmi-rmms'>
                        <span>COMPANY</span>
                        <ChevronLeft onClick={() => {
                            this.props.closePopup()
                        }} />
                    </div>
                    <div className="search-rm-main">
                        <div className="wrapper-select-new">
                            <input type="text" placeholder="Zoek in je vriendenlijst"
                                onChange={(e) => {
                                    if (e.target.value !== "") {
                                        let filtered = this.state.allCompaniesDuplicate.filter((t) => {
                                            return t.company_name.toLowerCase().includes(e.target.value.toLowerCase())
                                        })
                                        if (filtered.length > 0) {
                                            this.setState({ allCompaniesFilters: filtered, noFiltersCompanies: false })
                                        } else {
                                            this.setState({ allCompaniesFilters: [], noFiltersCompanies: true })
                                        }
                                    } else {
                                        this.setState({ allCompaniesFilters: [], noFiltersCompanies: false, allCompanies: this.state.allCompaniesDuplicate })
                                    }
                                }}
                            />
                            <SearchIcon
                                className="expand-more"
                            />
                        </div>
                    </div>
                    <div className='content-rmmi-rmms contentrmirmms-grantadmin crnc-maxheight'>
                        {this.state.noFiltersCompanies ? (<label>
                            No Filters Found
                        </label>) : this.state.allCompaniesFilters.length > 0 ? this.state.allCompaniesFilters.map((t) => {
                            return (<div
                                className="form-miur"
                                style={{ position: "relative" }}
                            >
                                {/* <input type="radio" name='companyselection' value={t.user_id} defaultChecked={t.isAllowedAdministration} /> */}
                                <div className='content-fm-gadm'>
                                    <img src={t.profile_image ? t.profile_image : "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9"} />
                                    <label>
                                        {t.company_name}
                                    </label>
                                </div>
                                <div className='switch-companyfilter'>
                                    <Switch
                                        color="primary"
                                        inputProps={{ "aria-label": "primary checkbox" }}
                                        checked={t.isAllowedAdministration}
                                        onClick={() => {
                                            t.isAllowedAdministration = !t.isAllowedAdministration

                                            firebase.database().ref(`users/${t.user_id}/isAllowedAdministration`).set(t.isAllowedAdministration)

                                            this.setState({
                                                allCompaniesFilters: this.state.allCompaniesFilters,
                                            });
                                        }}
                                    />
                                </div>
                            </div>)
                        }) : this.state.allCompanies.map((t) => {
                            return (<div
                                className="form-miur"
                                style={{ position: "relative" }}
                            >
                                {/* <input type="radio" name='companyselection' value={t.user_id} defaultChecked={t.isAllowedAdministration} /> */}
                                <div className='content-fm-gadm'>
                                    <img src={t.profile_image ? t.profile_image : "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9"} />
                                    <label>
                                        {t.company_name}
                                    </label>
                                </div>
                                <div className='switch-companyfilter'>
                                    <Switch
                                        color="primary"
                                        inputProps={{ "aria-label": "primary checkbox" }}
                                        checked={t.isAllowedAdministration}
                                        onClick={() => {
                                            t.isAllowedAdministration = !t.isAllowedAdministration

                                            firebase.database().ref(`users/${t.user_id}/isAllowedAdministration`).set(t.isAllowedAdministration)

                                            this.setState({
                                                allCompanies: this.state.allCompanies,
                                            });
                                        }}
                                    />
                                </div>
                            </div>)
                        })}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducers.user,
    };
};

export default connect(
    mapStateToProps,
)(GrantCompanyAdministratie);