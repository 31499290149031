import React, { Component } from 'react'
import FriendListRightMenu from './FriendListRightMenu';

export class VriendenPageDesktop extends Component {
    render() {
        return (
            <div>
                <div
                    className="overlay-div-openadd"
                    onClick={() => {
                        this.setState({ showRightMenuFriends: false });
                    }}
                ></div>
                <FriendListRightMenu
                    closePopup={() => {
                        if (this.props.currentMemberData.type === "company") {
                            window.location.href = `/company/${this.props.currentMemberData.user_name_id}`
                        } else {
                            window.location.href = `/member/${this.props.currentMemberData.user_name_id}`
                        }
                    }}
                />
            </div>
        )
    }
}

export default VriendenPageDesktop