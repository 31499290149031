import React, { Component } from "react";
import "react-sortable-tree/style.css";
import CloseIcon from "@material-ui/icons/Close";
import { updateCategoriesVouchers, updateGlobalStuff } from "../Redux/actions/authActions";
import { connect } from "react-redux";
import firebase from "../Config/Firebase";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PublishIcon from "@material-ui/icons/Publish";
import AdminNavbar from "./AdminNavbar";
import * as XLSX from "xlsx";
import swal from "sweetalert";
import axios from "axios";
import { PiNoteLight } from "react-icons/pi"
import { BiAlarmExclamation } from "react-icons/bi";
import { FaPhoneAlt } from "react-icons/fa";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { BsChevronRight } from "react-icons/bs";
import { ChevronLeft } from "@material-ui/icons";
import { VscEdit } from "react-icons/vsc";
import Footer from "./Footer";

var convert = require("xml-js")

const bcrypt = require("bcryptjs");
const saltRounds = 10;

let categoriesVouchersRef = firebase.database().ref("bhodi_categories_vouchers")
let campaignsCategoryRef = firebase.database().ref("bhodi_campaigns_category")

export class AccountImportRestaurants extends Component {
    constructor(props) {
        super(props);

        this.state = {
            treeData: [],
            sectorModal: false,
            sectorModalCategory: false,
            sectorName: "",
            sectors: [],
            currentSector: "",
            currentSectorId: "",
            categoryName: "",
            currentSector: "",
            categoryId: "",
            all_categories: [],
            sortingTab: true,
            adjustingTab: false,
            completeMainTitles: [],
            arrOfInputs: [],
            kortingsInfo: "",
            charityInfo: "",
            lastMinuteInfo: "",
            giveawayInfo: "",
            webShopInfo: "",
            liveEventInfo: "",
            localEventInfo: "",
            globalOverlay: "",
            orderSuccessOverlay: "",
            kortingsInfoMobile: "",
            charityInfoMobile: "",
            giveawayInfoMobile: "",
            webShopInfoMobile: "",
            localEventInfoMobile: "",
            showGlobalTimer: false,
            currentDateGlobal: "",
            currentTimeGlobal: "",
            intervalTimer: "",
            intervalTitle: "",
            intervalDesc: "",
            dropdownKeys: [],
            completeCSVArray: [],
            allListings: [],
            allListingsDuplicated: [],
            csvFileStreet: "",
            csvFileHouse: "",
            csvFilePostCode: "",
            csvFileClientId: "",
            csvFileWoonplaats: "",
            csvFileTelefoon: "",
            csvFileVendorID: "",
            csvFileContactPerson: "",
            csvFileEmail: "",
            csvFileFormType: "voucher",
            paymentLinkType: "bhodi",
            csvFileWebsite: "",
            csvFileBedrijsfnaam: "",
            currentUserSpecific: "",
            allListingsUploaded: false,
            selectedMain: [],
            all_categories: [],
            selectedSector: "",
            isSubAdmin: false,
            currentStatus: "fresh",
            switchedToSubAdmin: false,

            callBackofCompany: "",
            callBackDate: "",
            callBackDateHours: "",
            callBackDateMinutes: "",
            callBackofCompanyIndex: 0,

            currentIndustryType: "",
            loadingData: true,

            completeCategories: [],
            mainSector: "",
            all_categories: [],
            selectedMain: []
        };
    }

    componentDidMount() {
        const loaderId = document.getElementById("main-page-loader-id");
        loaderId.style.display = "none";

        let userid = localStorage.getItem("userid")
        if (process.env.REACT_APP_SUB_ADMIN_ID.includes(userid)) {
            this.setState({ isSubAdmin: true })
        } else {
            this.setState({ showSwitchTab: true })
        }

        this.fetchAllData("restaurant")

        firebase
            .database()
            .ref("users")
            .on("value", (data) => {
                let a = data.val();
                let arr = [];
                if (a && !this.state.stopTakingData) {
                    let objected = Object.entries(a);
                    objected.map((u) => {
                        arr.push(u[1]);
                    });
                    let usersWithNumbers = [];
                    arr.map((t) => {
                        if (t.user_id_numbers) {
                            usersWithNumbers.push(t);
                        }
                    });
                    let sorted = usersWithNumbers.sort((a, b) => {
                        if (a.user_id_numbers && b.user_id_numbers) {
                            return a.user_id_numbers - b.user_id_numbers;
                        }
                    });
                    this.setState({
                        allUsers: arr,
                        currentUserSpecific: Number(sorted[sorted.length - 1].user_id_numbers) + 1,
                    });
                } else {
                    this.setState({ allUsers: [] });
                }
            });



        console.log(this.props, '/see here')

        if (this.props.globalsectors) {
            if (this.props.globalsectors.length > 0) {
                let arr = this.props.globalsectors;
                this.setState({ allSectors: arr });


                if (this.props.campaigns_category) {
                    this.setState({ categoryData: this.props.campaigns_category })
                } else {
                    campaignsCategoryRef.on("value", (data) => {
                        let b = data.val();
                        if (b) {
                            this.setState({ categoryData: b });
                        }
                    });
                }
                if (arr.length > 0) {
                    this.setState({ sectors: arr });

                    if (this.props.categories_vouchers && this.props.campaigns_category) {
                        let a = this.props.categories_vouchers;
                        if (a) {
                            let objected = Object.entries(a);

                            this.setState({
                                completeCategories: objected,
                            });
                        } else {
                            this.setState({ completeCategories: [] });
                        }
                    } else {
                        categoriesVouchersRef.on("value", (data) => {
                            let a = data.val();

                            this.props.updateCategoriesVouchers(a)
                            let arrSec = [];
                            if (a) {
                                let objected = Object.entries(a);
                                this.setState({
                                    completeCategories: objected,
                                });
                            } else {
                                this.setState({ completeCategories: [] });
                            }
                        });
                    }
                }
            }
        }
    }

    componentWillUnmount() {
        categoriesVouchersRef.off()
        campaignsCategoryRef.off()
    }

    fetchAllData(type) {
        if (type === this.state.currentIndustryType) {
            return false;
        }
        if (this.state.currentIndustryType) {
            if (this.state.currentIndustryType === "restaurant") {
                firebase.database().ref("accounts_by_admin").off()
            } else {
                firebase.database().ref(`accounts_by_admin${type}`).off()
            }
        }

        this.setState({ currentIndustryType: type })

        firebase.database().ref(type === "restaurant" ? "accounts_by_admin" : `accounts_by_admin_${type}`).on("value", (data) => {
            let a = data.val()
            if (a) {
                // a.map((t) => {
                //     let filtered = this.props.user.filter((g) => {
                //         return t.email === g.email
                //     })
                //     if (filtered.length > 0) {
                //         console.log(t.client_id, '/client id')
                //         console.log(filtered[0], '/client id of user')
                //         firebase.database().ref(`users/${filtered[0].user_id}/client_id_creation`).set(t.client_id)
                //     }
                // })
                if (this.state.isSubAdmin) {
                    if (type === "restaurant") {
                        a = a.filter((t) => t.restaurant_is_new_form)
                    }
                }
                if (this.state.isSubAdmin) {
                    if (type === "barber") {
                        a = a.filter((t) => t.barber_is_new_form)
                    }
                }
                if (this.state.currentStatus) {
                    if (this.state.currentStatus === "fresh") {
                        let filtered = a.filter((t) => {
                            if (t.account_created) {
                                return false
                            }
                            let isCallbackDate = false
                            if (t.isoTimeCallback) {
                                let date = new Date(t.isoTimeCallback)
                                let today = new Date()

                                date.setHours(0)
                                date.setMinutes(0)
                                date.setSeconds(0)

                                today.setHours(23)
                                today.setMinutes(59)
                                today.setSeconds(59)


                                if (date.getTime() < today.getTime()) {
                                    isCallbackDate = true
                                }
                            }
                            if (t.isoTimeCallback) {
                                let date = new Date(t.isoTimeCallback)
                                let today = new Date()
                                let diff = date - today
                                t.score = Math.abs(diff)
                            } else {
                                t.score = Number.MAX_VALUE
                            }
                            if (isCallbackDate) {
                                return true
                            }
                            return !t.status
                        })
                        if (filtered.length > 0) {
                            this.setState({ allListings: filtered, noFilters: false, currentStatus: "fresh" })
                        } else {
                            this.setState({ noFilters: true })
                        }
                    } else {
                        this.filteredByStatus(this.state.currentStatus, a)
                    }

                    this.setState({ allListingsDuplicated: a, loadingData: false })
                } else {
                    this.setState({ allListings: a, allListingsDuplicated: a, loadingData: false })
                }
            } else {
                this.setState({ allListings: [], allListingsDuplicated: [], loadingData: false })
            }
        })
    }

    createAccounts(filtered) {
        if (filtered.length > 0) {
            let accountsToCreate = []
            let accountsCreatedAll = []

            let today = new Date();
            let date = today.getDate() + "-" + (today.getMonth() + 1) + "-" + today.getFullYear();
            filtered.map((t) => {
                let jointName = t.company_name;

                let filteredForJoin = this.state.allUsers.filter((g) => {
                    return g.user_name_id === jointName.toLowerCase();
                });

                if (filteredForJoin.length > 0) {
                    let randomStr = Math.ceil(Math.random() * (20 - 0) + 0);
                    jointName = t.company_name + randomStr;

                    let filteredForJoinSecond = this.state.allUsers.filter((g) => {
                        return g.user_name_id === jointName.toLowerCase();
                    });
                    if (filteredForJoinSecond.length > 0) {
                        let randomStr = Math.ceil(Math.random() * (20000 - 0) + 0);
                        jointName = t.company_name + randomStr;

                        let filteredForJoinThird = this.state.allUsers.filter((g) => {
                            return g.user_name_id === jointName.toLowerCase();
                        });
                        if (filteredForJoinThird.length > 0) {
                            let randomStr = Math.ceil(Math.random() * (20000 - 0) + 0);
                            jointName = t.company_name + randomStr;

                            let filteredForJoinFourth = this.state.allUsers.filter(
                                (g) => {
                                    return g.user_name_id === jointName.toLowerCase();
                                }
                            );
                            if (filteredForJoinFourth.length > 0) {
                                let randomStr = Math.ceil(Math.random() * (20000 - 0) + 0);
                                jointName = t.company_name + randomStr;
                            }
                        }
                    }
                }

                if (jointName.includes(" ")) {
                    jointName = jointName.replace(/ /g, "_");
                }

                let obj = {
                    first_name: t.first_name,
                    email: t.email,
                    last_name: t.last_name,
                    password: t.password,
                    country: "Netherlands",
                    passwordChanged: false,
                    user_id: "userId",
                    street: t.street,
                    city: t.city,
                    zipCode: t.zipCode,
                    houseNumber: t.houseNumber,
                    company_website: t.company_website,
                    kvkCompany: t.kvkCompany,
                    phoneNumber: t.phoneNumber,
                    type: "company",
                    user_name_id: jointName.toLowerCase(),
                    created_on: date,
                    company_name: t.company_name,
                    lunch_show_contact: true,
                    dinner_show_contact: true,
                    dinner_title_contact: "Dinner",
                    lunch_title_contact: "Dinner",
                    newly_registered: true,
                    user_id_numbers: this.state.currentUserSpecific,
                    profileActive: false,
                };
                if (this.state.currentIndustryType === "barber") {
                    obj.barber_new = true
                }
                if (t.vendorId) {
                    obj.vendor_id_barber = t.vendorId
                }
                if (t.isVendorSaloon) {
                    obj.saloon_vendor = true
                }
                let objSecond = {
                    email: t.email,
                    password: t.password,
                    industry: this.state.currentIndustryType,
                };

                accountsToCreate.push(obj)
                accountsCreatedAll.push(objSecond)
            })

            let accountsToVanish = []

            let accountsCreated = 0
            let promiseFirst = new Promise((res, rej) => {
                accountsToCreate.map((accountInfo) => {
                    bcrypt.genSalt(saltRounds, (err, salt) => {
                        bcrypt.hash(accountInfo.password, salt, (err, hash) => {
                            var data = JSON.stringify({
                                email: accountInfo.email,
                                password: accountInfo.password,
                            })

                            var config = {
                                method: "post",
                                url: "https://us-central1-bhodi-production-da765.cloudfunctions.net/createAccount",
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                data: data,
                            }

                            axios(config)
                                .then((response) => {
                                    if (response.data.statusCode == 201) {
                                        let userId = response.data.user_id;
                                        accountInfo.user_id = userId
                                        accountInfo.password = hash

                                        firebase.database().ref("users/" + userId).set(accountInfo)

                                        accountsCreated = accountsCreated + 1
                                        if (accountsCreated === accountsToCreate.length) {
                                            res()
                                        }
                                    }
                                })
                                .catch((err) => {
                                    console.log(err)
                                    accountsToVanish.push(accountInfo.email)

                                    accountsCreated = accountsCreated + 1
                                    if (accountsCreated === accountsToCreate.length) {
                                        res()
                                    }
                                });
                        })
                    })
                })
            })

            promiseFirst.then(() => {
                accountsCreatedAll = accountsCreatedAll.filter((account) => !accountsToVanish.includes(account.email))

                accountsCreatedAll.map((t) => {
                    let pushKey = firebase.database().ref("accounts_created_uniquelink").push().key
                    firebase.database().ref(`accounts_created_uniquelink/${pushKey}`).set(t)
                })

                swal({
                    title: "Cool",
                    text: "Accounts created successfully",
                    icon: "success",
                }).then(() => {
                    this.setState({
                        // allListings: arrToPush,
                        allListingsUploaded: true,
                        popupToClarify: false,
                        csvFileStreet: "",
                        csvFileHouse: "",
                        csvFilePostCode: "",
                        csvFileClientId: "",
                        csvFileWoonplaats: "",
                        csvFileTelefoon: "",
                        csvFileVendorID: "",
                        csvFileContactPerson: "",
                        mainSector: "",
                        selectedMain: [],
                        csvFileFormType: "voucher",
                        paymentLinkType: "bhodi",
                        csvFileEmail: "",
                        csvFileWebsite: "",
                        csvFileBedrijsfnaam: "",
                    })
                })
            })
        }
    }


    sendEmail() {
        if (this.state.allListings.length > 0) {
            let filtered = this.state.allListings.filter((t) => {
                return t.selected
            })
            if (filtered.length > 0) {
                filtered.map((t) => {
                    var dataForAPI = JSON.stringify({
                        sendToEmail: t.email,
                        subject: "Account Created at Bhodi!",
                        innerHTMLpart: `<!DOCTYPE html><html><head><title>Page Title</title></head><body><h2>WELCOME!</h2><p>We have registered your account at Bhodi with credentials below.</p><p>Email: ${t.email}</p><p>Password: ${t.password}</p></body></html>`,
                    });

                    var config = {
                        method: "post",
                        url: "https://us-central1-bhodi-production-da765.cloudfunctions.net/sendEmailMailjet",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        data: dataForAPI,
                    };

                    axios(config)
                        .then((response) => {
                            if (response.data.statusCode === 201) {
                                swal({
                                    title: "Cool",
                                    text: "Emails sent successfully",
                                    icon: "success",
                                })
                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                })
            }
        }
    }

    fileUploadAccounts(e) {
        e.preventDefault();
        if (e.target.files && e.target.files.length > 0) {
            if (e.target.files[0].type.includes("/xml")) {
                this.setState({ csvSelectedName: e.target.files[0].name, selectedXml: true })
                const reader = new FileReader();
                reader.onload = (e) => {
                    let readXml = e.target.result

                    let result = convert.xml2json(readXml, { compact: true, spaces: 4 })

                    let currentArray = JSON.parse(result)

                    if (currentArray.items && currentArray.items.item && currentArray.items.item.length > 0) {
                        let allKeys = [];
                        currentArray.items.item.map((e) => {
                            let keysOfObject = Object.keys(e);
                            keysOfObject.map((t) => {
                                allKeys.push(t);
                            });
                        });
                        let filteredOfKeys = allKeys.filter(
                            (v, i, a) => a.findIndex((t) => t === v) === i
                        );
                        this.setState({
                            dropdownKeys: filteredOfKeys,
                            completeCSVArray: currentArray.items.item,
                            popupToClarify: true,
                            csvFileStreet: "",
                            csvFileHouse: "",
                            csvFilePostCode: "",
                            csvFileClientId: "",
                            csvFileWoonplaats: "",
                            csvFileTelefoon: "",
                            csvFileVendorID: "",
                            csvFileContactPerson: "",
                            mainSector: "",
                            selectedMain: [],
                            csvFileFormType: "voucher",
                            paymentLinkType: "bhodi",
                            csvFileEmail: "",
                            csvFileWebsite: "",
                            csvFileBedrijsfnaam: "",
                        });
                    }

                };
                reader.readAsText(e.target.files[0]);
                e.target.value = "";
            } else {
                this.setState({ csvSelectedName: e.target.files[0].name, selectedXml: false })
                const reader = new FileReader();
                reader.onload = (e) => {
                    const data = e.target.result;
                    const workbook = XLSX.read(data, { type: "array" });
                    const sheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[sheetName];
                    const json = XLSX.utils.sheet_to_json(worksheet);
                    let allKeys = [];

                    json.map((e) => {
                        let keysOfObject = Object.keys(e);
                        keysOfObject.map((t) => {
                            allKeys.push(t);
                        });
                    });
                    let filteredOfKeys = allKeys.filter(
                        (v, i, a) => a.findIndex((t) => t === v) === i
                    );
                    this.setState({
                        dropdownKeys: filteredOfKeys,
                        completeCSVArray: json,
                        popupToClarify: true,
                        csvFileStreet: "",
                        csvFileHouse: "",
                        csvFilePostCode: "",
                        csvFileClientId: "",
                        csvFileWoonplaats: "",
                        csvFileTelefoon: "",
                        csvFileVendorID: "",
                        csvFileContactPerson: "",
                        mainSector: "",
                        selectedMain: [],
                        csvFileFormType: "voucher",
                        paymentLinkType: "bhodi",
                        csvFileEmail: "",
                        csvFileWebsite: "",
                        csvFileBedrijsfnaam: "",
                    });
                };
                reader.readAsArrayBuffer(e.target.files[0]);
                e.target.value = "";
            }
        }
    }

    randomStr(e) {
        let len = e
        let arr = "abcdefghijklmnopqrstuvwxyz1928102489"
        var ans = '';
        for (var i = len; i > 0; i--) {
            ans +=
                arr[Math.floor(Math.random() * arr.length)];
        }
        return ans;
    }

    uploadCsvFile() {

        const {
            csvFileStreet,
            csvFileEmail,
            csvFileHouse,
            csvFilePostCode,
            csvFileClientId,
            csvFileWoonplaats,
            csvFileTelefoon,
            csvFileContactPerson,
            csvFileFormType,
            paymentLinkType,
            selectedMain,
            selectedSector,
            csvFileBedrijsfnaam,
            csvFileWebsite,
            csvFileVendorID,
        } = this.state;
        if (csvFileBedrijsfnaam) {
            this.setState({ notSelectedBedrijsfnaamCSV: false });
        } else {
            this.setState({ notSelectedBedrijsfnaamCSV: true });
        }
        if (csvFileStreet) {
            this.setState({ notSelectedStreetCSV: false });
        } else {
            this.setState({ notSelectedStreetCSV: true });
        }
        if (csvFileEmail) {
            this.setState({ notSelectedEmailCSV: false });
        } else {
            this.setState({ notSelectedEmailCSV: true });
        }
        if (csvFileHouse) {
            this.setState({ notSelectedHouseCSV: false });
        } else {
            this.setState({ notSelectedHouseCSV: true });
        }
        if (csvFilePostCode) {
            this.setState({ notSelectedPostCodeCSV: false });
        } else {
            this.setState({ notSelectedPostCodeCSV: true });
        }
        if (csvFileClientId) {
            this.setState({ notSelectedClientIdCsv: false });
        } else {
            this.setState({ notSelectedClientIdCsv: true });
        }
        if (csvFileWoonplaats) {
            this.setState({ notSelectedWoonplaatsCSV: false });
        } else {
            this.setState({ notSelectedWoonplaatsCSV: true });
        }
        if (selectedSector) {
            this.setState({ notSelectedSectorCSV: false });
        } else {
            this.setState({ notSelectedSectorCSV: true });
        }
        if (selectedMain.length > 0) {
            this.setState({ notSelectedCategoryCsv: false });
        } else {
            this.setState({ notSelectedCategoryCsv: true });
        }
        let checkForSectorAndCategory = true
        if (this.state.currentIndustryType !== "barber" && this.state.currentIndustryType !== "barber_vendors") {
            if (selectedMain.length > 0 && selectedSector) {
                checkForSectorAndCategory = true
            } else {
                checkForSectorAndCategory = false
            }
        }
        if (
            csvFileStreet &&
            csvFileEmail &&
            csvFileHouse &&
            csvFilePostCode &&
            csvFileWoonplaats &&
            csvFileBedrijsfnaam &&
            checkForSectorAndCategory
        ) {
            if (this.state.completeCSVArray.length > 0) {
                let arrToPush = [...this.state.allListingsDuplicated];
                let accountsToCreate = [];
                this.state.completeCSVArray.map((e) => {
                    let filtered = this.state.allListingsDuplicated.filter((t) => {
                        return t.email === e[csvFileEmail]
                    })
                    if (filtered.length === 0) {
                        let obj = {
                            first_name: "",
                            last_name: "",
                            street: e[csvFileStreet] ? e[csvFileStreet] : "",
                            email: e[csvFileEmail] ? e[csvFileEmail] : "",
                            houseNumber: e[csvFileHouse] ? e[csvFileHouse] : "",
                            zipCode: e[csvFilePostCode] ? e[csvFilePostCode] : "",
                            client_id: e[csvFileClientId] ? e[csvFileClientId] : "",
                            city: e[csvFileWoonplaats] ? e[csvFileWoonplaats] : "",
                            phoneNumber: e[csvFileTelefoon] ? e[csvFileTelefoon] : "",
                            contactPerson: e[csvFileContactPerson] ? e[csvFileContactPerson] : "",
                            company_name: e[csvFileBedrijsfnaam] ? e[csvFileBedrijsfnaam] : "",
                            kvkCompany: "",
                            company_website: e[csvFileWebsite] ? e[csvFileWebsite] : "",
                            selected: false,
                            type: csvFileFormType,
                            paymentLinkType,
                            selectedMain,
                            selectedSector,
                            password: this.randomStr(8),
                        };
                        if (this.state.currentIndustryType === "restaurant") {
                            // adding for new form changes
                            obj.restaurant_is_new_form = true
                        }
                        if (this.state.currentIndustryType === "barber") {
                            // adding for new form changes
                            obj.barber_is_new_form = true
                            if (csvFileVendorID) {
                                obj.vendorId = e[csvFileVendorID] ? e[csvFileVendorID] : ""
                            }
                            delete obj.selectedMain
                            delete obj.selectedSector
                            delete obj.type
                        }
                        if (this.state.currentIndustryType === "barber_vendors") {
                            // adding for new form changes
                            delete obj.selectedMain
                            delete obj.selectedSector
                            delete obj.type
                            obj.isVendorSaloon = true
                        }
                        arrToPush.push(obj);
                        accountsToCreate.push(obj);
                    }
                });

                firebase.database().ref(this.state.currentIndustryType === "restaurant" ? "accounts_by_admin" : `accounts_by_admin_${this.state.currentIndustryType}`).set(arrToPush)
                this.createAccounts(accountsToCreate)
            }
        }
    }

    exportToXLSX(data, fileName) {
        // Create a new workbook and add a worksheet
        const workbook = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(data);

        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(workbook, ws, 'Sheet 1');

        // Convert the workbook to a binary XLSX file
        const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });

        // Create a Blob with the XLSX data
        const blob = new Blob([this.s2ab(wbout)], { type: 'application/octet-stream' });

        // Create a download link and trigger the download
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${fileName}.xlsx`;
        a.click();
        window.URL.revokeObjectURL(url);
    }

    // Helper function to convert string to array buffer
    s2ab(s) {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) {
            view[i] = s.charCodeAt(i) & 0xFF;
        }
        return buf;
    }


    exportCsv() {
        let data = [
            ["Client ID", "Company Name", "Contact Person", "Email", "Straatnaam", "House Number", "Postcode", "Plaats", "Unique URL"]
        ]
        this.state.allListingsDuplicated.map((e) => {
            if (this.state.currentIndustryType === "restaurant") {
                if (!e.restaurant_is_new_form) {
                    return false;
                }
            }
            if (this.state.currentIndustryType === "barber") {
                if (!e.barber_is_new_form) {
                    return false;
                }
            }

            let userFinded = this.props.user.find((t) => {
                return t.email === e.email
            })

            if (!userFinded) {
                return false
            }

            let unique_url = `${window.location.origin}/loginCompanyDirectly?email=${e.email}&key=${e.password}&industry=${this.state.currentIndustryType}`
            if (e.restaurant_is_new_form) {
                unique_url = `${window.location.origin}/loginCompanyDirectly?email=${e.email}&key=${e.password}&industry=restaurant_new`
            }
            if (e.barber_is_new_form) {
                unique_url = `${window.location.origin}/loginCompanyDirectly?email=${e.email}&key=${e.password}&industry=barber_new`
            }
            let arr = [e.client_id, e.company_name, e.email, e.street, e.houseNumber, e.zipCode, e.city, unique_url]
            if (e.contactPerson) {
                arr.splice(2, 0, e.contactPerson)
            } else {
                arr.splice(2, 0, "")
            }
            data.push(arr)
        })

        this.exportToXLSX(data, 'exported_data');

        // const csvContent = data.map(row => row.join(',')).join('\n');

        // const blob = new Blob([csvContent], { type: 'text/csv' });

        // const a = document.createElement('a');
        // a.href = URL.createObjectURL(blob);
        // a.download = 'data.csv';
        // a.textContent = 'Download CSV';

        // a.click();
    }

    filteredByStatus(type, listToFollow) {
        let allLists = this.state.allListingsDuplicated
        if (listToFollow && listToFollow.length > 0) {
            allLists = listToFollow
        }
        if (type === "subscribed") {
            let filtered = allLists.filter((t) => {
                return t.account_created
            })

            if (filtered.length > 0) {
                this.setState({ allListings: filtered, noFilters: false, currentStatus: type })
            } else {
                this.setState({ noFilters: true, currentStatus: type })
            }
        } else {
            let filtered = allLists.filter((t) => {
                return t.status === type
            })

            if (filtered.length > 0) {
                this.setState({ allListings: filtered, noFilters: false, currentStatus: type })
            } else {
                this.setState({ noFilters: true, currentStatus: type })
            }
        }
    }

    updateCurrentUser(user, type, index, date) {
        let findIndex = this.state.allListingsDuplicated.findIndex((t) => {
            return t.email === user.email && t.company_name === user.company_name
        })

        let imgtodrag = document.getElementsByClassName("nrt-subadmin")[index];

        let disLeft = imgtodrag.getBoundingClientRect().left;
        let disTop = imgtodrag.getBoundingClientRect().top;
        let viewcart = document.getElementsByClassName(`${type}-button`)[0];


        if (findIndex !== -1) {
            if (this.state.allListingsDuplicated[findIndex].status === type) {
                delete this.state.allListingsDuplicated[findIndex].status
                delete this.state.allListingsDuplicated[findIndex].isoTimeCallback

                firebase.database().ref(this.state.currentIndustryType === "restaurant" ? "accounts_by_admin" : `accounts_by_admin_${this.state.currentIndustryType}`).set(this.state.allListingsDuplicated)

                viewcart = document.getElementsByClassName(`fresh-button`)[0]

            } else {
                this.state.allListingsDuplicated[findIndex].status = type
                if (date) {
                    this.state.allListingsDuplicated[findIndex].isoTimeCallback = date
                }

                firebase.database().ref(this.state.currentIndustryType === "restaurant" ? "accounts_by_admin" : `accounts_by_admin_${this.state.currentIndustryType}`).set(this.state.allListingsDuplicated)
            }
        }


        let image = imgtodrag.cloneNode(true);

        let cartleft = viewcart.getBoundingClientRect().left;
        let carttop = viewcart.getBoundingClientRect().top;

        image.style =
            "z-index: 99999999999; width: 100%;opacity:0.8; position:fixed; top:" +
            disTop +
            "px;left:" +
            disLeft +
            "px;transition: left 2s, top 2s, width 2s, opacity 2s cubic-bezier(1, 1, 1, 1)";

        var rechange = document.body.appendChild(image);
        setTimeout(function () {
            image.style.left = cartleft + "px";
            image.style.top = carttop + "px";
            image.style.width = "30px";
            image.style.opacity = "0";
        }, 200);
        setTimeout(function () {
            rechange.parentNode.removeChild(rechange);
        }, 2000);
    }

    askForNote(user) {
        swal({
            title: "Notitie",
            content: {
                element: "textarea",
                attributes: {
                    value: user.note ? user.note : "",
                }
            },
            className: "bigger-modal-note",
            // showCancelButton: true
            button: "Sluit",
        }).then((value) => {
            if (value) {
                let findIndex = this.state.allListingsDuplicated.findIndex((t) => {
                    return t.email === user.email && t.company_name === user.company_name
                })

                if (findIndex !== -1) {
                    this.state.allListingsDuplicated[findIndex].note = value
                    firebase.database().ref(this.state.currentIndustryType === "restaurant" ? "accounts_by_admin" : `accounts_by_admin_${this.state.currentIndustryType}`).set(this.state.allListingsDuplicated)
                }
            }
        })
    }

    saveCurrentCallback() {
        let dateMain = new Date(this.state.callBackDate)
        dateMain.setHours(Number(this.state.callBackDateHours))
        dateMain.setMinutes(Number(this.state.callBackDateMinutes))

        let date = dateMain.toISOString()
        // let date = new Date(this.state.callBackDate).toISOString()

        this.updateCurrentUser(this.state.callBackofCompany, "callback", this.state.callBackofCompanyIndex, date)

        this.setState({ callBackSettingPopup: false, callBackofCompany: "", callBackofCompanyIndex: "", callBackDate: "", callBackDateHours: "", callBackDateMinutes: "" })
    }


    updateCallback() {
        let dateMain = new Date(this.state.callBackDate)
        dateMain.setHours(Number(this.state.callBackDateHours))
        dateMain.setMinutes(Number(this.state.callBackDateMinutes))

        let date = dateMain.toISOString()
        let user = this.state.callBackofCompany
        let type = "callback"

        let findIndex = this.state.allListingsDuplicated.findIndex((t) => {
            return t.email === user.email && t.company_name === user.company_name
        })


        if (findIndex !== -1) {
            this.state.allListingsDuplicated[findIndex].status = type
            if (date) {
                this.state.allListingsDuplicated[findIndex].isoTimeCallback = date
            }
            firebase.database().ref(this.state.currentIndustryType === "restaurant" ? "accounts_by_admin" : `accounts_by_admin_${this.state.currentIndustryType}`).set(this.state.allListingsDuplicated)
        }

        this.setState({ callBackSettingPopup: false, callBackofCompany: "", callBackofCompanyIndex: "", callBackDate: "", isUpdatingCallback: false, callBackDateHours: "", callBackDateMinutes: "" })
    }

    getMonthName(i) {
        i = Number(i);
        if (i === 1) {
            return "jan";
        } else if (i === 2) {
            return "feb";
        } else if (i === 3) {
            return "mrt";
        } else if (i === 4) {
            return "apr";
        } else if (i === 5) {
            return "mei";
        } else if (i === 6) {
            return "jun";
        } else if (i === 7) {
            return "jul";
        } else if (i === 8) {
            return "aug";
        } else if (i === 9) {
            return "sep";
        } else if (i === 10) {
            return "okt";
        } else if (i === 11) {
            return "nov";
        } else if (i === 12) {
            return "dec";
        }
    }

    getCurrentDateOfCallback(isoDate) {
        let currentDate = new Date(Date.parse(isoDate))
        return `${currentDate.getMonth() + 1} / ${currentDate.getDate()} / ${currentDate.getFullYear()}`
    }

    getCurrentTimeOfCallback(isoDate) {
        let currentDate = new Date(Date.parse(isoDate))

        let hours = currentDate.getHours()
        let minutes = currentDate.getMinutes()


        if (hours < 10) {
            hours = `0${hours}`;
        } else {
            hours = `${hours}`;
        }

        if (minutes < 10) {
            minutes = `0${minutes}`;
        } else {
            minutes = `${minutes}`;
        }

        return `${hours}:${minutes} hrs`
    }

    getStatusCount(type) {
        if (type === "subscribed") {
            let filtered = this.state.allListingsDuplicated.filter((t) => {
                return t.account_created
            })
            return `(${filtered.length})`
        }
        let filtered = this.state.allListingsDuplicated.filter((t) => {
            return t.status === type
        })
        return `(${filtered.length})`
    }

    getStatusCountFresh() {
        let filtered = this.state.allListingsDuplicated.filter((t) => {
            if (t.account_created) {
                return false
            }
            let isCallbackDate = false
            if (t.isoTimeCallback) {
                let date = new Date(t.isoTimeCallback)
                let today = new Date()

                date.setHours(0)
                date.setMinutes(0)
                date.setSeconds(0)

                today.setHours(23)
                today.setMinutes(59)
                today.setSeconds(59)


                if (date.getTime() < today.getTime()) {
                    isCallbackDate = true
                }
            }
            if (t.isoTimeCallback) {
                let date = new Date(t.isoTimeCallback)
                let today = new Date()
                let diff = date - today
                t.score = Math.abs(diff)
            } else {
                t.score = Number.MAX_VALUE
            }
            if (isCallbackDate) {
                return true
            }
            return !t.status
        })
        return `(${filtered.length})`
    }

    sendEmailTouser(userInfo) {
        let unique_url = `${window.location.origin}/loginCompanyDirectly?email=${userInfo.email}&key=${userInfo.password}&industry=${this.state.currentIndustryType}`

        var dataForAPI = JSON.stringify({
            sendToEmail: userInfo.email,
            subject: "Account Created at Bhodi!",
            innerHTMLpart: `<!DOCTYPE html><html><head><title>Page Title</title></head><body><h2>WELCOME!</h2><p>Here goes some demo text in good way</p><p>For more info please: <a href=${unique_url}>Click here</a></p></body></html>`,
        });

        var config = {
            method: "post",
            url: "https://us-central1-bhodi-production-da765.cloudfunctions.net/sendEmailMailjet",
            headers: {
                "Content-Type": "application/json",
            },
            data: dataForAPI,
        };

        axios(config)
            .then((response) => {
                if (response.data.statusCode === 201) {
                    swal({
                        title: "Cool",
                        text: "Email sent successfully",
                        icon: "success",
                    }).then(() => {
                        let findIndex = this.state.allListingsDuplicated.findIndex((t) => {
                            return t.email === userInfo.email
                        })
                        if (findIndex !== -1) {

                            let emailSentCount = 0
                            if (userInfo.emailSentCount) {
                                emailSentCount = userInfo.emailSentCount
                            }
                            emailSentCount = emailSentCount + 1

                            firebase.database().ref(this.state.currentIndustryType === "restaurant" ? `accounts_by_admin/${findIndex}/emailSentCount` : `accounts_by_admin_${this.state.currentIndustryType}/${findIndex}/emailSentCount`).set(emailSentCount)
                            firebase.database().ref(this.state.currentIndustryType === "restaurant" ? `accounts_by_admin/${findIndex}/emailLastSent` : `accounts_by_admin_${this.state.currentIndustryType}/${findIndex}/emailLastSent`).set(new Date().toISOString())
                        }
                    })
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    checkForEditIcon(data) {
        let filtered = []
        let filtereAccountCreated = []
        if (this.props.user) {
            filtered = this.props.user.filter((t) => {
                return t.came_via_id === data.client_id
            })
            filtereAccountCreated = this.props.user.filter((t) => {
                return t.client_id_creation === data.client_id
            })
        }
        if (filtered.length > 0) {
            return false;
        }
        if (filtereAccountCreated.length > 0) {
            return false;
        }
        return <VscEdit onClick={() => {
            this.setState({ editCompanyModal: true, companyToEdit: data, csvFileClientId: data.client_id })
        }} />
    }


    editAccount() {
        const { csvFileClientId } = this.state

        let findIndex = this.state.allListingsDuplicated.findIndex((t) => {
            return t.email === this.state.companyToEdit.email && t.client_id === this.state.companyToEdit.client_id
        })

        if (findIndex !== -1) {
            firebase.database().ref(this.state.currentIndustryType === "restaurant" ? `accounts_by_admin/${findIndex}/client_id` : `accounts_by_admin_${this.state.currentIndustryType}/${findIndex}/client_id`).set(csvFileClientId)
        }

        this.setState({ editCompanyModal: false, csvFileClientId: "", companyToEdit: "" })
    }

    render() {
        return (
            <div className="sorting-mainadmin">
                <AdminNavbar accountImport={true} />
                <div className="container-sma containeraccountcreatoradmin">
                    <div className="info-div-editor">
                        {!this.state.isSubAdmin && (<div className="group-textbox" style={{ textAlign: "center" }}>
                            <label style={{ fontSize: 16, fontWeight: 600 }}>
                                ACCOUNT IMPORT
                            </label>
                        </div>)}
                        {this.state.isSubAdmin ? (<div className="buttons-subadmin-imprest">
                            <div className="sector-tcsma" style={{ marginRight: 20 }}>
                                <select
                                    onChange={(e) => {
                                        let valueIndustry = e.target.value
                                        if (valueIndustry === this.state.currentIndustryType) {
                                            return false;
                                        }
                                        this.setState({
                                            currentStatus: "fresh",
                                            loadingData: true,
                                        });

                                        setTimeout(() => {
                                            this.fetchAllData(valueIndustry)
                                        }, 500);
                                    }}
                                    value={this.state.currentIndustryType}
                                >
                                    <option value="">Selecteer</option>
                                    <option value="restaurant">Restaurants</option>
                                    <option value="barber">Barber</option>
                                    <option value="barber_vendors">Barber Vendors</option>
                                    <option value="wellness">Wellness</option>
                                    <option value="retailers">Retailers</option>
                                    <option value="shops">Shops</option>
                                </select>
                                <ArrowDropDownIcon className="dropdown-select-tcsma" />
                            </div>
                            {this.state.showSwitchTab && (<button
                                onClick={() => {
                                    this.setState({ isSubAdmin: false, allListings: this.state.allListingsDuplicated, currentStatus: "fresh", noFilters: false })
                                }}
                            >Switch to Admin</button>)}
                            <button className={!this.state.currentStatus && "active"} onClick={() => {
                                this.setState({ allListings: this.state.allListingsDuplicated, noFilters: false, currentStatus: "" })
                            }}>All ({this.state.allListingsDuplicated.length})</button>
                            <button className={`fresh-button ${this.state.currentStatus === "fresh" && "active"}`} onClick={() => {
                                let filtered = this.state.allListingsDuplicated.filter((t) => {
                                    if (t.account_created) {
                                        return false
                                    }
                                    let isCallbackDate = false
                                    if (t.isoTimeCallback) {
                                        let date = new Date(t.isoTimeCallback)
                                        let today = new Date()

                                        date.setHours(0)
                                        date.setMinutes(0)
                                        date.setSeconds(0)

                                        today.setHours(23)
                                        today.setMinutes(59)
                                        today.setSeconds(59)


                                        if (date.getTime() < today.getTime()) {
                                            isCallbackDate = true
                                        }
                                    }
                                    if (t.isoTimeCallback) {
                                        let date = new Date(t.isoTimeCallback)
                                        let today = new Date()
                                        let diff = date - today
                                        t.score = Math.abs(diff)
                                    } else {
                                        t.score = Number.MAX_VALUE
                                    }
                                    if (isCallbackDate) {
                                        return true
                                    }
                                    return !t.status
                                })
                                if (filtered.length > 0) {
                                    this.setState({ allListings: filtered, noFilters: false, currentStatus: "fresh" })
                                } else {
                                    this.setState({ noFilters: true })
                                }
                            }}>Fresh {this.getStatusCountFresh()}</button>
                            <button className={`not_responded-button ${this.state.currentStatus === "not_responded" && "active"}`} onClick={() => {
                                this.filteredByStatus("not_responded")
                            }}>Not Responded {this.getStatusCount("not_responded")}</button>
                            <button className={`inactive-button ${this.state.currentStatus === "inactive" && "active"}`} onClick={() => {
                                this.filteredByStatus("inactive")
                            }}>Inactive {this.getStatusCount("inactive")}</button>
                            <button className={`delete-button ${this.state.currentStatus === "delete" && "active"}`} onClick={() => {
                                this.filteredByStatus("delete")
                            }}>Delete {this.getStatusCount("delete")}</button>
                            <button className={`not_interested-button ${this.state.currentStatus === "not_interested" && "active"}`} onClick={() => {
                                this.filteredByStatus("not_interested")
                            }}>Not Intrested {this.getStatusCount("not_interested")}</button>
                            <button className={`callback-button ${this.state.currentStatus === "callback" && "active"}`} onClick={() => {
                                this.filteredByStatus("callback")
                            }}>Callback {this.getStatusCount("callback")}</button>
                            <button className={`confirmation-button ${this.state.currentStatus === "confirmation" && "active"}`} onClick={() => {
                                this.filteredByStatus("confirmation")
                            }}>Confirmation {this.getStatusCount("confirmation")}</button>
                            <button className={`subscribed-button ${this.state.currentStatus === "subscribed" && "active"}`} onClick={() => {
                                this.filteredByStatus("subscribed")
                            }}>Subscribed {this.getStatusCount("subscribed")}</button>
                        </div>) : (<div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                            <div className="group-textbox" style={{ maxWidth: 500 }}>
                                <label>CSV/XML File</label>
                                <input
                                    type="file"
                                    accept=".csv"
                                    onChange={this.fileUploadAccounts.bind(this)}
                                />
                            </div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <div className="sector-tcsma" style={{ marginRight: 20 }}>
                                    <select
                                        onChange={(e) => {
                                            let valueIndustry = e.target.value
                                            if (valueIndustry === this.state.currentIndustryType) {
                                                return false;
                                            }
                                            this.setState({
                                                currentStatus: "fresh",
                                                loadingData: true,
                                            });

                                            setTimeout(() => {
                                                this.fetchAllData(valueIndustry)
                                            }, 500);
                                        }}
                                        value={this.state.currentIndustryType}
                                    >
                                        <option value="">Selecteer</option>
                                        <option value="restaurant">Restaurants</option>
                                        <option value="barber">Barber</option>
                                        <option value="barber_vendors">Barber Vendors</option>
                                        <option value="wellness">Wellness</option>
                                        <option value="retailers">Retailers</option>
                                        <option value="shops">Shops</option>
                                    </select>
                                    <ArrowDropDownIcon className="dropdown-select-tcsma" />
                                </div>
                                {this.state.showSwitchTab && (<button
                                    style={{ backgroundColor: "black", color: "white", padding: "8px 12px", border: "none", outline: "none", fontSize: 13, fontFamily: "Poppins", letterSpacing: 0.6, borderRadius: 4, marginRight: 8 }}
                                    onClick={() => {
                                        this.setState({ isSubAdmin: true })
                                    }}
                                >Switch to Subadmin</button>)}
                                {this.state.allListings.length > 0 && (<button
                                    style={{ backgroundColor: "black", color: "white", padding: "8px 12px", border: "none", outline: "none", fontSize: 13, fontFamily: "Poppins", letterSpacing: 0.6, borderRadius: 4 }}
                                    onClick={() => {
                                        this.exportCsv()
                                    }}
                                >Export</button>)}
                            </div>
                        </div>)}
                        {this.state.isSubAdmin ? (<div className="table-account-creator">
                            <div className="firstrow-tac frt-subadmin">
                                <div>
                                    <p>Number</p>
                                </div>
                                <div>
                                    <p>Bedrijfsnaam</p>
                                </div>
                                {this.state.currentStatus !== "subscribed" && (<div>
                                    <p style={{ textAlign: "center", paddingRight: "20%" }}>Response</p>
                                </div>)}
                                {this.state.currentStatus !== "subscribed" && (<div>
                                    <p>Action</p>
                                </div>)}
                                {this.state.currentStatus !== "subscribed" && (<div>
                                    <p>Note</p>
                                </div>)}
                            </div>
                            {this.state.loadingData ? <div className="loader-admin-import">
                                <div className="lds-ring">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </div> : this.state.noFilters ? (<p style={{ marginTop: 30, fontSize: 16, fontFamily: "Poppins", fontWeight: "bold" }}>No Filters</p>) : this.state.allListings.length > 0 && this.state.allListings.sort((a, b) => {
                                return a.score - b.score
                            }).map((e, ind) => {
                                return (<div className="normalrows-tac nrt-subadmin" key={Math.random()}>
                                    <div>
                                        <div className="phone-number-wrapper">
                                            <div className="pnw-main">
                                                <a href={`tel:${e.phoneNumber}`}>
                                                    <FaPhoneAlt />
                                                </a>
                                            </div>
                                            <p>
                                                <a href={`tel:${e.phoneNumber}`}>{e.phoneNumber}</a>
                                                {e.contactPerson && (<font style={{ marginLeft: 10 }}>({e.contactPerson})</font>)}
                                            </p>
                                        </div>
                                        {e.isoTimeCallback && (<div className="callback-enabled">
                                            <div className="top-callenab">
                                                <BiAlarmExclamation />
                                                <label>CALLBACK</label>
                                            </div>
                                            <p className="date-callenab">{this.getCurrentDateOfCallback(e.isoTimeCallback)}</p>
                                            <p className="time-callenab">{this.getCurrentTimeOfCallback(e.isoTimeCallback)}</p>
                                            <div className="actions-callenab">
                                                <button onClick={() => {
                                                    this.updateCurrentUser(e, "callback", ind)
                                                }}>Remove</button>
                                                <button onClick={() => {
                                                    const date = new Date(Date.parse(e.isoTimeCallback));

                                                    let hours = date.getHours()
                                                    let minutes = date.getMinutes()

                                                    if (hours < 10) {
                                                        hours = `0${hours}`
                                                    }
                                                    if (minutes < 10) {
                                                        minutes = `0${minutes}`
                                                    }

                                                    let formattedDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`

                                                    this.setState({ callBackSettingPopup: true, callBackofCompany: e, callBackofCompanyIndex: ind, callBackDate: formattedDate, callBackDateHours: hours, callBackDateMinutes: minutes, isUpdatingCallback: true })
                                                }}>Update</button>
                                            </div>
                                        </div>)}
                                    </div>
                                    <div>
                                        <p>{e.company_name}</p>
                                        {e.emailSentCount && (<div style={{ width: "100%" }}>
                                            <p className="date-callenab">Email Sent: {e.emailSentCount}</p>
                                            <p className="time-callenab">{this.getCurrentDateOfCallback(e.emailLastSent)}</p>
                                        </div>)}
                                    </div>
                                    {this.state.currentStatus !== "subscribed" && (<div>
                                        <button className={e.status === "not_responded" && "active"} onClick={() => {
                                            this.updateCurrentUser(e, "not_responded", ind)
                                        }}>NOT RESPONDED</button>
                                        <button className={e.status === "inactive" && "active"} onClick={() => {
                                            this.updateCurrentUser(e, "inactive", ind)
                                        }}>INACTIVE</button>
                                        <button className={e.status === "not_interested" && "active"} onClick={() => {
                                            this.updateCurrentUser(e, "not_interested", ind)
                                        }}>NOT INTERESTED</button>
                                        <button className={e.status === "delete" && "active"} onClick={() => {
                                            this.updateCurrentUser(e, "delete", ind)
                                        }}>DELETE</button>
                                    </div>)}
                                    {this.state.currentStatus !== "subscribed" && (<div>
                                        <button className={e.status === "callback" && "active"} onClick={() => {
                                            if (e.status === "callback") {
                                                this.updateCurrentUser(e, "callback", ind)
                                                return false;
                                            }
                                            this.setState({ callBackSettingPopup: true, callBackofCompany: e, callBackofCompanyIndex: ind })
                                        }}>CALL BACK</button>
                                        <button className={e.status === "confirmation" && "active"} onClick={() => {
                                            this.updateCurrentUser(e, "confirmation", ind)
                                        }}>CONFIRMATION</button>
                                        <button className={e.status === "confirmation" && "active"} onClick={() => {
                                            this.sendEmailTouser(e)
                                        }}>Send Email</button>
                                    </div>)}
                                    {this.state.currentStatus !== "subscribed" && (<div>
                                        <button onClick={() => {
                                            this.askForNote(e)
                                        }}>MAKE NOTE</button>
                                        {e.note && (<div className="note-icon">
                                            <PiNoteLight onClick={() => {
                                                this.askForNote(e)
                                            }} />
                                        </div>)}
                                    </div>)}
                                </div>)
                            })}
                        </div>) : (<div className="table-account-creator">
                            <div className="firstrow-tac frt-admin frt-adminimport">
                                <div>
                                    <p>Straatnaam</p>
                                </div>
                                <div>
                                    <p>Email</p>
                                </div>
                                <div>
                                    <p>Bedrijfsnaam</p>
                                </div>
                                <div>
                                    <p>Client ID</p>
                                </div>
                                <div>
                                    <p>Postcode</p>
                                </div>
                                <div>
                                    <p>Plaats</p>
                                </div>
                                <div>
                                    <p>Number</p>
                                </div>
                                <div>
                                    <p>Website</p>
                                </div>
                            </div>
                            {this.state.loadingData ? <div className="loader-admin-import">
                                <div className="lds-ring">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </div> : this.state.allListingsDuplicated.length > 0 && this.state.allListingsDuplicated.map((e) => {
                                return (<div className="normalrows-tac nrt-admin nrt-adminimport" key={Math.random()}>
                                    <div>
                                        <p>{e.street}</p>
                                    </div>
                                    <div>
                                        <p>{e.email}</p>
                                    </div>
                                    <div>
                                        <p>{e.company_name}</p>
                                    </div>
                                    <div>
                                        <p>{e.client_id}</p>
                                        {this.checkForEditIcon(e)}
                                    </div>
                                    <div>
                                        <p>{e.zipCode}</p>
                                    </div>
                                    <div>
                                        <p>{e.city}</p>
                                    </div>
                                    <div>
                                        <p>{e.phoneNumber}</p>
                                    </div>
                                    <div>
                                        <p>{e.company_website}</p>
                                    </div>
                                </div>)
                            })}
                        </div>)}
                    </div>
                </div>
                {this.state.popupToClarify && (
                    <div className="fields-clarify-csv">
                        <div className="opacity-fccsv"></div>
                        <div className="box-fccsv" style={{ position: "relative", maxHeight: 900, overflowY: "auto" }}>
                            <CloseIcon
                                style={{ position: "absolute", top: 10, right: 10 }}
                                onClick={() => {
                                    this.setState({ popupToClarify: false });
                                }}
                            />
                            <div className="inner-box-fccsv">
                                <label
                                    style={{
                                        color: this.state.notSelectedBedrijsfnaamCSV
                                            ? "red"
                                            : "black",
                                    }}
                                >
                                    Selecteer Bedrijsfnaam
                                </label>
                                <div className="selectinnerbfccsv">
                                    <select
                                        value={this.state.csvFileBedrijsfnaam}
                                        onChange={(e) => {
                                            this.setState({
                                                csvFileBedrijsfnaam: e.target.value,
                                            });
                                        }}
                                    >
                                        <option value="" disabled selected hidden>
                                            Selecteer
                                        </option>
                                        {this.state.dropdownKeys.map((t) => {
                                            return <option value={t}>{t}</option>;
                                        })}
                                    </select>
                                    <ExpandMoreIcon />
                                </div>
                            </div>
                            <div className="inner-box-fccsv">
                                <label
                                    style={{
                                        color: this.state.notSelectedWebsiteCSV
                                            ? "red"
                                            : "black",
                                    }}
                                >
                                    Selecteer Website
                                </label>
                                <div className="selectinnerbfccsv">
                                    <select
                                        value={this.state.csvFileWebsite}
                                        onChange={(e) => {
                                            this.setState({
                                                csvFileWebsite: e.target.value,
                                            });
                                        }}
                                    >
                                        <option value="" disabled selected hidden>
                                            Selecteer
                                        </option>
                                        {this.state.dropdownKeys.map((t) => {
                                            return <option value={t}>{t}</option>;
                                        })}
                                    </select>
                                    <ExpandMoreIcon />
                                </div>
                            </div>
                            <div className="inner-box-fccsv">
                                <label
                                    style={{
                                        color: this.state.notSelectedStreetCSV
                                            ? "red"
                                            : "black",
                                    }}
                                >
                                    Selecteer Street
                                </label>
                                <div className="selectinnerbfccsv">
                                    <select
                                        value={this.state.csvFileStreet}
                                        onChange={(e) => {
                                            this.setState({
                                                csvFileStreet: e.target.value,
                                            });
                                        }}
                                    >
                                        <option value="" disabled selected hidden>
                                            Selecteer
                                        </option>
                                        {this.state.dropdownKeys.map((t) => {
                                            return <option value={t}>{t}</option>;
                                        })}
                                    </select>
                                    <ExpandMoreIcon />
                                </div>
                            </div>
                            <div className="inner-box-fccsv">
                                <label
                                    style={{
                                        color: this.state.notSelectedEmailCSV ? "red" : "black",
                                    }}
                                >
                                    Selecteer Email
                                </label>
                                <div className="selectinnerbfccsv">
                                    <select
                                        value={this.state.csvFileEmail}
                                        onChange={(e) => {
                                            this.setState({
                                                csvFileEmail: e.target.value,
                                            });
                                        }}
                                    >
                                        <option value="" disabled selected hidden>
                                            Selecteer
                                        </option>
                                        {this.state.dropdownKeys.map((t) => {
                                            return <option value={t}>{t}</option>;
                                        })}
                                    </select>
                                    <ExpandMoreIcon />
                                </div>
                            </div>
                            <div className="inner-box-fccsv">
                                <label
                                    style={{
                                        color: this.state.notSelectedHouseCSV
                                            ? "red"
                                            : "black",
                                    }}
                                >
                                    Selecteer Huisnummer
                                </label>
                                <div className="selectinnerbfccsv">
                                    <select
                                        value={this.state.csvFileHouse}
                                        onChange={(e) => {
                                            this.setState({
                                                csvFileHouse: e.target.value,
                                            });
                                        }}
                                    >
                                        <option value="" disabled selected hidden>
                                            Selecteer
                                        </option>
                                        {this.state.dropdownKeys.map((t) => {
                                            return <option value={t}>{t}</option>;
                                        })}
                                    </select>
                                    <ExpandMoreIcon />
                                </div>
                            </div>
                            <div className="inner-box-fccsv">
                                <label
                                    style={{
                                        color: this.state.notSelectedPostCodeCSV
                                            ? "red"
                                            : "black",
                                    }}
                                >
                                    Selecteer Postcode
                                </label>
                                <div className="selectinnerbfccsv">
                                    <select
                                        value={this.state.csvFilePostCode}
                                        onChange={(e) => {
                                            this.setState({
                                                csvFilePostCode: e.target.value,
                                            });
                                        }}
                                    >
                                        <option value="" disabled selected hidden>
                                            Selecteer
                                        </option>
                                        {this.state.dropdownKeys.map((t) => {
                                            return <option value={t}>{t}</option>;
                                        })}
                                    </select>
                                    <ExpandMoreIcon />
                                </div>
                            </div>
                            <div className="inner-box-fccsv">
                                <label
                                    style={{
                                        color: this.state.notSelectedClientIdCsv
                                            ? "red"
                                            : "black",
                                    }}
                                >
                                    Selecteer Client Id
                                </label>
                                <div className="selectinnerbfccsv">
                                    <select
                                        value={this.state.csvFileClientId}
                                        onChange={(e) => {
                                            this.setState({
                                                csvFileClientId: e.target.value,
                                            });
                                        }}
                                    >
                                        <option value="" disabled selected hidden>
                                            Selecteer
                                        </option>
                                        {this.state.dropdownKeys.map((t) => {
                                            return <option value={t}>{t}</option>;
                                        })}
                                    </select>
                                    <ExpandMoreIcon />
                                </div>
                            </div>
                            <div className="inner-box-fccsv">
                                <label
                                    style={{
                                        color: this.state.notSelectedWoonplaatsCSV
                                            ? "red"
                                            : "black",
                                    }}
                                >
                                    Selecteer Woonplaats
                                </label>
                                <div className="selectinnerbfccsv">
                                    <select
                                        value={this.state.csvFileWoonplaats}
                                        onChange={(e) => {
                                            this.setState({
                                                csvFileWoonplaats: e.target.value,
                                            });
                                        }}
                                    >
                                        <option value="" disabled selected hidden>
                                            Selecteer
                                        </option>
                                        {this.state.dropdownKeys.map((t) => {
                                            return <option value={t}>{t}</option>;
                                        })}
                                    </select>
                                    <ExpandMoreIcon />
                                </div>
                            </div>
                            <div className="inner-box-fccsv">
                                <label
                                    style={{
                                        color: this.state.notSelectedTelefoonCSV
                                            ? "red"
                                            : "black",
                                    }}
                                >
                                    Selecteer Telefoon
                                </label>
                                <div className="selectinnerbfccsv">
                                    <select
                                        value={this.state.csvFileTelefoon}
                                        onChange={(e) => {
                                            this.setState({
                                                csvFileTelefoon: e.target.value,
                                            });
                                        }}
                                    >
                                        <option value="" disabled selected hidden>
                                            Selecteer
                                        </option>
                                        {this.state.dropdownKeys.map((t) => {
                                            return <option value={t}>{t}</option>;
                                        })}
                                    </select>
                                    <ExpandMoreIcon />
                                </div>
                            </div>
                            <div className="inner-box-fccsv">
                                <label>
                                    Selecteer Contact Person
                                </label>
                                <div className="selectinnerbfccsv">
                                    <select
                                        value={this.state.csvFileContactPerson}
                                        onChange={(e) => {
                                            this.setState({
                                                csvFileContactPerson: e.target.value,
                                            });
                                        }}
                                    >
                                        <option value="" disabled selected hidden>
                                            Selecteer
                                        </option>
                                        {this.state.dropdownKeys.map((t) => {
                                            return <option value={t}>{t}</option>;
                                        })}
                                    </select>
                                    <ExpandMoreIcon />
                                </div>
                            </div>
                            <div className="inner-box-fccsv">
                                <label>
                                    Selecteer Payment Link Type
                                </label>
                                <div className="selectinnerbfccsv">
                                    <select
                                        value={this.state.paymentLinkType}
                                        onChange={(e) => {
                                            this.setState({
                                                paymentLinkType: e.target.value,
                                            });
                                        }}
                                    >
                                        <option value="bhodi">Bhodi</option>
                                        <option value="stripe">Stripe</option>
                                    </select>
                                    <ExpandMoreIcon />
                                </div>
                            </div>

                            {this.state.currentIndustryType === "barber" && (<div className="inner-box-fccsv">
                                <label>
                                    Selecteer Vendor ID
                                </label>
                                <div className="selectinnerbfccsv">
                                    <select
                                        value={this.state.csvFileVendorID}
                                        onChange={(e) => {
                                            this.setState({
                                                csvFileVendorID: e.target.value,
                                            });
                                        }}
                                    >
                                        <option value="" disabled selected hidden>
                                            Selecteer
                                        </option>
                                        {this.state.dropdownKeys.map((t) => {
                                            return <option value={t}>{t}</option>;
                                        })}
                                    </select>
                                    <ExpandMoreIcon />
                                </div>
                            </div>)}
                            {(this.state.currentIndustryType !== "barber" && this.state.currentIndustryType !== "barber_vendors") && (<>
                                <div className="inner-box-fccsv">
                                    <label>
                                        Selecteer Form Type
                                    </label>
                                    <div className="selectinnerbfccsv">
                                        <select
                                            value={this.state.csvFileFormType}
                                            onChange={(e) => {
                                                this.setState({
                                                    csvFileFormType: e.target.value,
                                                });
                                            }}
                                        >
                                            <option value="voucher">Voucher</option>
                                            <option value="voucher-percentage">Voucher Percentage</option>
                                            <option value="retailer-start-event">Retailer Start Event</option>
                                            <option value="event">Event</option>
                                        </select>
                                        <ExpandMoreIcon />
                                    </div>
                                </div>
                                <div className="inner-box-fccsv">
                                    <label
                                        style={{
                                            color: this.state.notSelectedSectorCSV ? "red" : "black",
                                        }}
                                    >
                                        Selecteer Sector
                                    </label>
                                    <div className="selectinnerbfccsv">
                                        <select
                                            style={{ fontSize: 13, letterSpacing: 1 }}
                                            value={this.state.mainSector}
                                            onChange={(e) => {
                                                if (e.target.value !== "Selecteer") {
                                                    let cloned = Object.entries(this.state.categoryData);
                                                    let filtered = cloned.filter((g) => {
                                                        return g[0] === e.target.value;
                                                    });
                                                    if (filtered.length > 0) {
                                                        this.setState({
                                                            mainSector: e.target.value,
                                                        });

                                                        if (e.target.value !== "Selecteer") {
                                                            let arrSec = [];
                                                            this.state.completeCategories.map((g) => {
                                                                if (g[0] === e.target.value) {
                                                                    arrSec = g[1];
                                                                }
                                                            });

                                                            let categories = [];
                                                            arrSec.map((h) => {
                                                                if (h.children) {
                                                                    let obj = {
                                                                        title: h.title,
                                                                        category_id: h.category_id,
                                                                        sub_category: h.children,
                                                                    };
                                                                    categories.push(obj);
                                                                } else {
                                                                    let obj = {
                                                                        title: h.title,
                                                                        category_id: h.category_id,
                                                                    };
                                                                    categories.push(obj);
                                                                }
                                                            });


                                                            this.setState({
                                                                all_categories: categories,
                                                                sub_categories: [],
                                                                selectedMain: [],
                                                                subCategoryPresent: false,
                                                            });
                                                            this.setState({ selectedSector: e.target.value });
                                                        } else {
                                                            this.setState({
                                                                all_categories: [],
                                                                subCategoryPresent: false,
                                                                selectedMain: [],
                                                                sub_categories: [],
                                                                selectedSector: "",
                                                            });
                                                        }
                                                    }
                                                }
                                            }}
                                        >
                                            <option disabled selected hidden value="">
                                                Selecteer
                                            </option>
                                            {this.state.allSectors.map((g) => {
                                                return (
                                                    <option value={g.sector_id}>{g.sector_name}</option>
                                                );
                                            })}
                                        </select>
                                        <ExpandMoreIcon />
                                    </div>
                                </div>
                                {this.state.all_categories.length > 0 && (<div className="inner-box-fccsv ibfc-container"
                                    onClick={() => {
                                        this.setState({ addCategory: true })
                                    }}>
                                    <label
                                        style={{
                                            color: this.state.notSelectedCategoryCsv ? "red" : "black",
                                        }}
                                    >
                                        Selecteer Category
                                    </label>
                                    <BsChevronRight />
                                </div>)}
                            </>)}
                            <div className="inner-box-fccsv" style={{ marginBottom: 0 }}>
                                <button
                                    className="button-ibfccsv"
                                    onClick={this.uploadCsvFile.bind(this)}
                                >
                                    <PublishIcon style={{ fontSize: 20 }} />
                                    <font>Upload</font>
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                {
                    this.state.callBackSettingPopup && (<div className="callback-popup-main">
                        <div className="capoma-inner">
                            <CloseIcon className="close-capoin" onClick={() => {
                                this.setState({ callBackSettingPopup: false, callBackofCompany: "", callBackofCompanyIndex: "", callBackDateTime: "" })
                            }} />
                            <h3>CALL BACK</h3>
                            <div className="tag-capoma">
                                <div className="itcapoma-inner">
                                    <p>Company: {this.state.callBackofCompany.company_name}</p>
                                </div>
                                <div className="itcapoma-inner">
                                    <p>Name: {this.state.callBackofCompany.company_name}</p>
                                </div>
                            </div>
                            <div className="dateset-capoma">
                                <p>SET DATE & TIME</p>
                                <input type="date" value={this.state.callBackDate} onChange={(e) => {
                                    this.setState({ callBackDate: e.target.value })
                                }} />
                                <label>Hours</label>
                                <select value={this.state.callBackDateHours} onChange={(e) => {
                                    this.setState({ callBackDateHours: e.target.value })
                                }}>
                                    <option value="">Selecteer</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                    <option value="13">13</option>
                                    <option value="14">14</option>
                                    <option value="15">15</option>
                                    <option value="16">16</option>
                                    <option value="17">17</option>
                                    <option value="18">18</option>
                                </select>
                                <label>Minutes</label>
                                <select value={this.state.callBackDateMinutes} onChange={(e) => {
                                    this.setState({ callBackDateMinutes: e.target.value })
                                }}>
                                    <option value="">Selecteer</option>
                                    <option value="00">00</option>
                                    <option value="15">15</option>
                                    <option value="30">30</option>
                                    <option value="45">45</option>
                                </select>
                            </div>
                            <div className="buttons-capoma">
                                <button onClick={() => {
                                    if (this.state.isUpdatingCallback) {
                                        this.updateCallback()
                                    } else {
                                        this.saveCurrentCallback()
                                    }
                                }}>Save Callback</button>
                            </div>
                        </div>
                    </div>)
                }


                {this.state.addCategory && (<div className={`${"separate-page-ce new-campaigns-design campaigns-design-final new-campaigns-design-final"}`} >
                    <div
                        className={`rightmenuaddproduct rightmenucompletewidthaddproduct`}
                        style={{
                            display: "flex",
                            alignItems: window.innerWidth > 1008 && "center",
                            justifyContent: window.innerWidth > 1008 && "center",
                            flexDirection: window.innerWidth < 1008 && "column",
                        }}
                    >
                        <div className="overlay-rmadfofw"></div>
                        <div className="rightmenuaddproduct rightmenuaddcategoryformfw rightmenucategorynew newcategoriesrightmenu">
                            <div
                                className="mid-rmap"
                                style={{ position: "relative", padding: 13 }}
                            >
                                <span>SELECTEER CATEGORIE</span>
                                <div className="top-header-infocont">
                                    <div style={{ position: "relative" }}>
                                        <div className='green-round-info' style={{ cursor: "pointer" }} onClick={() => {
                                            this.setState({ categoryInfoOpen: !this.state.categoryInfoOpen })
                                        }}>
                                            <font>?</font>
                                        </div>
                                        <div className="popup-gri-thifc" style={{ opacity: this.state.categoryInfoOpen ? 1 : 0, pointerEvents: this.state.categoryInfoOpen ? "all" : "none" }}>
                                            <CloseIcon onClick={() => this.setState({ categoryInfoOpen: false })} />
                                            <label style={{ fontSize: 15 }}>
                                                Selecteer de juiste categorie(ën)
                                                <br />
                                                waaronder je actie wordt getoond.
                                            </label>
                                        </div>
                                    </div>
                                    <ChevronLeft
                                        style={{
                                            cursor: "pointer",
                                            position: "absolute",
                                            top: "50%",
                                            transform: "translateY(-50%)",
                                            left: 20,
                                            fontSize: 18,
                                        }}
                                        onClick={() => this.setState({ addCategory: false, categoryInfoOpen: false })}
                                    />
                                </div>
                            </div>
                            <div className="bottom-rmap">
                                <div className="mid-img-uploading-rmap">
                                    {this.state.all_categories.length > 0 && (
                                        <ul className="checkboxlistselectcategory">
                                            {this.state.all_categories.map((e) => {
                                                return (
                                                    <li className="checkboxfieldstypeselection">
                                                        <input
                                                            type="checkbox"
                                                            className="input-radio checkboxessortname"
                                                            value={e.category_id}
                                                            checked={this.state.selectedMain.includes(
                                                                e.category_id
                                                            )}
                                                            onChange={() => {
                                                                if (
                                                                    this.state.selectedMain.includes(
                                                                        e.category_id
                                                                    )
                                                                ) {
                                                                    let indexed =
                                                                        this.state.selectedMain.indexOf(
                                                                            e.category_id
                                                                        );
                                                                    this.state.selectedMain.splice(indexed, 1);
                                                                    this.setState({
                                                                        selectedMain: this.state.selectedMain,
                                                                    });
                                                                } else {
                                                                    this.state.selectedMain.push(e.category_id);
                                                                    this.setState({
                                                                        selectedMain: this.state.selectedMain,
                                                                    });
                                                                }
                                                            }}
                                                        />
                                                        <p>{e.title}</p>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>)}


                <div
                    className="modal-sector-sorting mss-manual-import"
                    style={{ right: this.state.editCompanyModal ? 0 : "-100%" }}
                >
                    <CloseIcon
                        className="close-mss"
                        onClick={() => {
                            document.getElementById("root").style.position = "static";
                            document.getElementById("root").style.overflow = "auto";
                            this.setState({ editCompanyModal: false, csvFileClientId: "", companyToEdit: "" });
                        }}
                    />
                    <p>Edit Company</p>
                    <div className="form-mss">
                        <label style={{
                            color: this.state.notSelectedClientIdCsv ? "red" : "white",
                        }}>Client Id</label>
                        <input
                            type="text"
                            value={this.state.csvFileClientId}
                            onChange={(e) => this.setState({ csvFileClientId: e.target.value })}
                        />
                    </div>
                    <div className="form-mss">
                        <button onClick={this.editAccount.bind(this)}>EDIT</button>
                    </div>
                </div>
                <div
                    className="modal-sector-overlay"
                    style={{
                        opacity: this.state.editCompanyModal ? 0.7 : 0,
                        pointerEvents: this.state.editCompanyModal ? "all" : "none",
                    }}
                    onClick={() => {
                        document.getElementById("root").style.position = "static";
                        document.getElementById("root").style.overflow = "auto";
                        this.setState({ editCompanyModal: false, csvFileClientId: "", companyToEdit: "" });
                    }}
                ></div>

                <Footer notOnMainPage={true} />
            </div >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        global_values: state.globalReducers.global_values,
        globalsectors: state.categoriesReducer.globalsectors,
        campaigns_category: state.categoriesReducer.campaigns_category,
        categories_vouchers: state.categoriesReducer.categories_vouchers,
        user: state.authReducers.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateGlobalStuff: (global_values) => dispatch(updateGlobalStuff(global_values)),
        updateCategoriesVouchers: (categories_vouchers) => dispatch(updateCategoriesVouchers(categories_vouchers)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountImportRestaurants);
