import React, { Component } from "react";
import "./index.css";

export class CompanyDashboard extends Component {
  render() {
    return (
      <div>

      </div>
    )
  }
}

export default CompanyDashboard;
