import React, { Component } from "react";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import { connect } from "react-redux";
import firebase from "../../Config/Firebase";
import AdminNavbar from "../AdminNavbar";
import Switch from "@material-ui/core/Switch";
import axios from "axios";
import * as XLSX from "xlsx";
import "./index.css"
import Footer from "../Footer";

export class PaymentLinks extends Component {
    constructor(props) {
        super(props);

        this.state = {
            allPaymentLinks: [],
            allWebshopPaymentLinks: [],
            companyID: "",
            copiedTextDone: false,
            noOfSeats: 1,
            subscription_data: [],
            selectedPlanAmount: "",
            selectedPlanId: "",
            couponCode: "",
            webshopUrl: "",
            webshopTime: "",
            selectedPlanStripeId: "",
            typeOfLink: "stripe",
            onWebshopPaymentLinks: false,
        };
    }

    componentDidMount() {
        const loaderId = document.getElementById("main-page-loader-id");
        loaderId.style.display = "none";


        let arr = [];
        if (this.props.subscription_plans) {
            if (this.props.subscription_plans.length > 0) {
                arr = this.props.subscription_plans;
                this.setState({
                    subscription_data: arr,
                });
            }
        }

        console.log(arr, '/see ')

        firebase
            .database()
            .ref("payment_links_stripe")
            .on("value", (data) => {
                let a = data.val();
                let arr = []
                let arrWebshop = []
                if (a) {
                    let objected = Object.entries(a)
                    objected.map((data) => {
                        if (!data[1].coupon_code) {
                            arr.push(data[1])
                        }
                    })
                    objected.map((data) => {
                        if (data[1].coupon_code) {
                            arrWebshop.push(data[1])
                        }
                    })
                    this.setState({ allPaymentLinks: arr, allWebshopPaymentLinks: arrWebshop })
                }
            });
    }

    createPaymentLink() {
        const { companyID, noOfSeats, selectedPlanAmount, selectedPlanId, selectedPlanStripeId, couponCode, webshopUrl, webshopTime } = this.state;


        if (this.state.typeOfLink === "bhodi") {
            let pushKey = firebase.database().ref("payment_links_stripe").push().key
            let obj = {
                pushKey,
                company_id: companyID,
                payment_id: "",
                active: true,
                payment_url: `${window.location.origin}/pay-via-link?key=${pushKey}`,
                noOfSeats: noOfSeats || 1,
                plan_amount: selectedPlanAmount,
            }
            if (couponCode) {
                obj.coupon_code = couponCode
                obj.webshop_url = webshopUrl
                obj.webshop_timing = webshopTime
            }

            firebase.database().ref(`payment_links_stripe/${pushKey}`).set(obj)

            this.setState({ paymentLinkModal: false, companyID: "" })
            document.getElementById("root").style.position = "static";
            document.getElementById("root").style.overflow = "auto";
            return false
        }

        let apiDataRaw = {
            company_id: companyID,
            stripe_plan_key: selectedPlanStripeId,
            plan_id: selectedPlanId,
            plan_amount: selectedPlanAmount,
        }
        if (couponCode) {
            apiDataRaw.coupon_code = couponCode
            apiDataRaw.webshop_url = webshopUrl
            apiDataRaw.webshop_timing = webshopTime
        }

        var dataForAPI = JSON.stringify(apiDataRaw);

        var config = {
            method: "post",
            url: "https://us-central1-bhodi-production-da765.cloudfunctions.net/createPaymentLink",
            headers: {
                "Content-Type": "application/json",
            },
            data: dataForAPI,
        };

        axios(config)
            .then((response) => {
                let apiData = response.data.data
                let pushKey = firebase.database().ref("payment_links_stripe").push().key
                let obj = {
                    pushKey,
                    company_id: companyID,
                    payment_id: apiData.id,
                    active: true,
                    payment_url: apiData.url,
                    noOfSeats: noOfSeats || 1,
                    plan_amount: selectedPlanAmount,
                }
                if (couponCode) {
                    obj.coupon_code = couponCode
                    obj.webshop_url = webshopUrl
                    obj.webshop_timing = webshopTime
                }

                firebase.database().ref(`payment_links_stripe/${pushKey}`).set(obj)

                this.setState({ paymentLinkModal: false, companyID: "" })
                document.getElementById("root").style.position = "static";
                document.getElementById("root").style.overflow = "auto";
            })
            .catch(function (error) {
                console.log(error);
            });


    }

    updatePaymentLink(plink, active, pushKey) {
        let dataToSend = {
            active,
        }

        if (!plink) {
            firebase.database().ref(`payment_links_stripe/${pushKey}/active`).set(active)
            return false;
        }

        var dataForAPI = JSON.stringify({
            plink_id: plink,
            dataToUpdate: dataToSend,
        });

        var config = {
            method: "post",
            url: "https://us-central1-bhodi-production-da765.cloudfunctions.net/updatePaymentLink",
            headers: {
                "Content-Type": "application/json",
            },
            data: dataForAPI,
        };

        axios(config)
            .then((response) => {
                if (response.data.statusCode === 200) {
                    firebase.database().ref(`payment_links_stripe/${pushKey}/active`).set(active)
                } else {
                    alert("Error in updating active status")
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    copyToClipboard(e) {
        var textField = document.createElement("textarea");
        textField.innerText = e;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand("copy");
        textField.remove();
        this.setState({ copiedTextDone: true });

        setTimeout(() => {
            this.setState({ copiedTextDone: false })
        }, 3000);
    }


    exportToXLSX(data, fileName) {
        // Create a new workbook and add a worksheet
        const workbook = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(data);

        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(workbook, ws, 'Sheet 1');

        // Convert the workbook to a binary XLSX file
        const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });

        // Create a Blob with the XLSX data
        const blob = new Blob([this.s2ab(wbout)], { type: 'application/octet-stream' });

        // Create a download link and trigger the download
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${fileName}.xlsx`;
        a.click();
        window.URL.revokeObjectURL(url);
    }

    // Helper function to convert string to array buffer
    s2ab(s) {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) {
            view[i] = s.charCodeAt(i) & 0xFF;
        }
        return buf;
    }


    exportCsv() {
        let data = [
            ["Company ID", "Company Name", "Address", "Brochure Type", "Seats", "Plan Amount", "Payment URL", "Payment Link ID"]
        ]
        this.state.allPaymentLinks.map((e) => {
            let noOfSeats = e.noOfSeats ? Number(e.noOfSeats) : 1
            let plan_amount = e.plan_amount ? e.plan_amount : "4.00"

            let arr = [e.company_id, e.company_name ? e.company_name : "", e.address ? e.address : "", e.brochure_type ? e.brochure_type : "", noOfSeats, plan_amount, e.payment_url, e.payment_id]

            for (let i = 0; i < noOfSeats; i++) {
                data.push(arr)
            }
        })
        this.exportToXLSX(data, 'payments_link_data');

        // const csvContent = data.map(row => row.join(',')).join('\n');

        // const blob = new Blob([csvContent], { type: 'text/csv' });

        // const a = document.createElement('a');
        // a.href = URL.createObjectURL(blob);
        // a.download = 'data.csv';
        // a.textContent = 'Download CSV';

        // a.click();
    }


    render() {
        return (
            <div className="sorting-mainadmin">
                <AdminNavbar atPaymentLinks={true} />
                <div className="container-sma" style={{ maxWidth: 920 }}>
                    <div className="top-container-sma">
                        <div className="sector-tcsma">
                        </div>
                        <div className="category-tcsma">
                            <p
                                onClick={() => {
                                    document.getElementById("root").style.position = "fixed";
                                    document.getElementById("root").style.overflow = "hidden";
                                    this.setState({ paymentLinkModal: true });
                                }}
                            >
                                <AddIcon /> LINK{" "}
                            </p>
                            {this.state.allPaymentLinks.length > 0 && (<p onClick={this.exportCsv.bind(this)}>
                                EXPORT{" "}
                            </p>)}
                        </div>
                    </div>
                    <div className="tabs-container-sma">
                        <div className="category-tcsma">
                            <p className={!this.state.onWebshopPaymentLinks && "active"} onClick={() => {
                                this.setState({ onWebshopPaymentLinks: false })
                            }}>
                                PAYMENT LINKS
                            </p>
                            <p className={this.state.onWebshopPaymentLinks && "active"} onClick={() => {
                                this.setState({ onWebshopPaymentLinks: true })
                            }}>
                                WEBSHOP PAYMENT LINKS
                            </p>
                        </div>
                    </div>
                    <div className="plm-wrapper">
                        {(this.state.onWebshopPaymentLinks && this.state.allWebshopPaymentLinks.length === 0) ? (<p style={{ fontFamily: "Poppins", fontSize: 13, letterSpacing: 0.4, margin: 0 }}>No Payment Links Created</p>) : (<table>
                            <tbody>
                                <tr>
                                    <td>Company ID</td>
                                    <td>Plan Amount</td>
                                    <td>No. of Seats</td>
                                    <td>Payment Link</td>
                                    <td>Action</td>
                                </tr>
                                {this.state.onWebshopPaymentLinks ? (
                                    <>
                                        {this.state.allWebshopPaymentLinks.length > 0 && this.state.allWebshopPaymentLinks.map((data) => {
                                            return (<tr>
                                                <td>
                                                    <span>{data.company_id}</span>
                                                </td>
                                                <td>
                                                    <span>{data.plan_amount ? data.plan_amount : "4.00"}</span>
                                                </td>
                                                <td>
                                                    <input defaultValue={data.noOfSeats || ""} onBlur={(e) => {
                                                        firebase.database().ref(`payment_links_stripe/${data.pushKey}/noOfSeats`).set(e.target.value)
                                                    }} />
                                                </td>
                                                <td>
                                                    <button onClick={this.copyToClipboard.bind(this, data.payment_url)}>Copy</button>
                                                </td>
                                                <td>
                                                    <Switch
                                                        color="primary"
                                                        inputProps={{ "aria-label": "primary checkbox" }}
                                                        checked={data.active}
                                                        onChange={() => {
                                                            data.active = !data.active

                                                            this.updatePaymentLink(data.payment_id, data.active, data.pushKey)
                                                        }}
                                                    />
                                                </td>
                                            </tr>)
                                        })}
                                    </>
                                ) : this.state.allPaymentLinks.length > 0 && this.state.allPaymentLinks.map((data) => {
                                    return (<tr>
                                        <td>
                                            <span>{data.company_id}</span>
                                        </td>
                                        <td>
                                            <span>{data.plan_amount ? data.plan_amount : "4.00"}</span>
                                        </td>
                                        <td>
                                            <input defaultValue={data.noOfSeats || ""} onBlur={(e) => {
                                                firebase.database().ref(`payment_links_stripe/${data.pushKey}/noOfSeats`).set(e.target.value)
                                            }} />
                                        </td>
                                        <td>
                                            <button onClick={this.copyToClipboard.bind(this, data.payment_url)}>Copy</button>
                                        </td>
                                        <td>
                                            <Switch
                                                color="primary"
                                                inputProps={{ "aria-label": "primary checkbox" }}
                                                checked={data.active}
                                                onChange={() => {
                                                    data.active = !data.active

                                                    this.updatePaymentLink(data.payment_id, data.active, data.pushKey)
                                                }}
                                            />
                                        </td>
                                    </tr>)
                                })}
                            </tbody>
                        </table>)}
                    </div>
                </div>
                <div
                    className="modal-sector-sorting"
                    style={{ right: this.state.paymentLinkModal ? 0 : "-100%" }}
                >
                    <CloseIcon
                        className="close-mss"
                        onClick={() => {
                            document.getElementById("root").style.position = "static";
                            document.getElementById("root").style.overflow = "auto";
                            this.setState({ paymentLinkModal: false });
                        }}
                    />
                    <p>Create Payment Link</p>
                    <div className="form-mss">
                        <label>Company ID</label>
                        <input
                            type="text"
                            value={this.state.companyID}
                            onChange={(e) => this.setState({ companyID: e.target.value })}
                        />
                    </div>
                    <div className="form-mss">
                        <label>Tables Amount</label>
                        <input
                            type="text"
                            value={this.state.noOfSeats}
                            onChange={(e) => this.setState({ noOfSeats: e.target.value })}
                        />
                    </div>
                    <div className="form-mss">
                        <label>Link Type</label>
                        <select value={this.state.typeOfLink} onChange={(e) => {
                            this.setState({
                                typeOfLink: e.target.value,
                            })
                        }}>
                            <option value="stripe">Stripe</option>
                            <option value="bhodi">Bhodi</option>
                        </select>
                    </div>
                    <div className="form-mss">
                        <label>Plan Amount</label>
                        <select value={this.state.selectedPlanStripeId} onChange={(e) => {
                            let val = e.target.value
                            let filtered = this.state.subscription_data.filter((t) => {
                                return t.stripe_plan_key == val
                            })
                            if (filtered.length > 0) {
                                this.setState({
                                    selectedPlanStripeId: filtered[0].stripe_plan_key,
                                    selectedPlanId: filtered[0].plan_id,
                                    selectedPlanAmount: filtered[0].price,
                                })
                            }
                        }}>
                            <option value="">Select Plan</option>
                            {this.state.subscription_data.length > 0 && [...this.state.subscription_data].sort((a, b) => a.sort_level - b.sort_level).map((e) => {
                                return <option value={e.stripe_plan_key}>{e.price}</option>
                            })}
                        </select>
                    </div>
                    {this.state.onWebshopPaymentLinks && (<div className="form-mss">
                        <label>Webshop URL</label>
                        <input
                            type="text"
                            value={this.state.webshopUrl}
                            onChange={(e) => this.setState({ webshopUrl: e.target.value })}
                        />
                    </div>)}
                    {this.state.onWebshopPaymentLinks && (<div className="form-mss">
                        <label>Webshop Time</label>
                        <input
                            type="text"
                            value={this.state.webshopTime}
                            onChange={(e) => this.setState({ webshopTime: e.target.value })}
                        />
                    </div>)}
                    {this.state.onWebshopPaymentLinks && (<div className="form-mss">
                        <label>Coupon Code</label>
                        <input
                            type="text"
                            value={this.state.couponCode}
                            onChange={(e) => this.setState({ couponCode: e.target.value })}
                        />
                    </div>)}

                    <div className="form-mss">
                        <button onClick={this.createPaymentLink.bind(this)}>CREATE</button>
                    </div>
                </div>
                <div
                    className="modal-sector-overlay"
                    style={{
                        opacity: this.state.paymentLinkModal ? 0.7 : 0,
                        pointerEvents: this.state.paymentLinkModal ? "all" : "none",
                    }}
                    onClick={() => {
                        document.getElementById("root").style.position = "static";
                        document.getElementById("root").style.overflow = "auto";
                        this.setState({ paymentLinkModal: false });
                    }}
                ></div>

                <button className="top-absolute-copied" style={{ opacity: this.state.copiedTextDone ? 1 : 0, pointerEvents: this.state.copiedTextDone ? "all" : "none" }}>Gekopieerd</button>

                <Footer notOnMainPage={true} />

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducers.user,
        subscription_plans: state.globalReducers.subscription_plans,
    };
};

export default connect(mapStateToProps)(PaymentLinks);
