import React, { Component } from 'react'
import "./index.css"
import firebase from "../../Config/Firebase"
import { connect } from 'react-redux';
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import GooglePlacesAutocomplete, {
    geocodeByPlaceId,
} from "react-google-places-autocomplete";
import Footer from '../../Components/Footer';
import FooterGreen from '../../Components/FooterGreen';
import { history } from '../../Config/Routes';
import { updateAdminOrNot } from '../../Redux/actions/authActions';

const bcrypt = require("bcryptjs");
const saltRounds = 10;

export class OnboardUsers extends Component {
    constructor() {
        super()
        this.state = {
            aanmeldenInfo: false,
            secondStep: false,
            aanmeldenloader: false,

            email: "",
            password: "",
            city: "",
            country: "155",
            bankName: "",
            allUsers: [],
            firstName: "",
            lastName: "",
            currentUserSpecific: "",

            isUserForm: true,

            showMainLoader: false,

            thirdStep: false,

            loaderSendingEmail: false,
        }
    }

    componentDidMount() {
        firebase
            .database()
            .ref("users")
            .once("value", (data) => {
                let a = data.val();
                let arr = [];
                if (a && !this.state.stopTakingData) {
                    let objected = Object.entries(a);
                    objected.map((u) => {
                        arr.push(u[1]);
                    });
                    let usersWithNumbers = [];
                    arr.map((t) => {
                        if (t.user_id_numbers) {
                            usersWithNumbers.push(t);
                        }
                    });
                    let sorted = usersWithNumbers.sort((a, b) => {
                        if (a.user_id_numbers && b.user_id_numbers) {
                            return a.user_id_numbers - b.user_id_numbers;
                        }
                    });
                    this.setState({
                        allUsers: arr,
                        currentUserSpecific: Number(sorted[sorted.length - 1].user_id_numbers) + 1,
                    });
                } else {
                    this.setState({ allUsers: [] });
                }
            });

        let urlMain = new URLSearchParams(window.location.search)

        let emailUrl = urlMain.get("email")
        let keyUrl = urlMain.get("key")

        if (!emailUrl || !keyUrl) {
            history.push("/")
        }
    }

    checkForValuePresent(param) {
        if (param !== "") {
            return true;
        } else {
            return false;
        }
    }

    updateStateOnCondition(param, paramToSet) {
        if (param !== "") {
            this.setState({
                [paramToSet]: false,
            })
        } else {
            this.setState({
                [paramToSet]: true,
            })
        }
    }


    processAccount() {
        const { city, password, agreed } = this.state

        if (agreed) {
            this.setState({ agreedPopup: false });
        } else {
            this.setState({ agreedPopup: true });
        }
        if (city) {
            this.setState({ cityPopup: false });
        } else {
            this.setState({ cityPopup: true });
        }
        if (password === "" || password.length < 6) {
            if (password.length < 6) {
                this.setState({ password: "" });
            }
            this.setState({ passInvalid: true });
        } else {
            this.setState({ passInvalid: false });
        }

        if (
            city && password !== "" && password.length > 5 && agreed
        ) {
            let urlMain = new URLSearchParams(window.location.search)
            this.setState({ aanmeldenloader: true })

            let emailUrl = urlMain.get("email")
            let keyUrl = urlMain.get("key")

            if (urlMain.get("email") && urlMain.get("key")) {
                let promiseLogin = new Promise((res) => {
                    firebase.auth().signInWithEmailAndPassword(emailUrl, keyUrl)
                        .then((succ) => {
                            firebase.database().ref(`users/${succ.user.uid}`).once("value", (data) => {
                                let a = data.val()
                                res(a)
                            })
                        })
                        .catch(() => {
                            alert("Some error occured, please check link")
                            this.setState({ aanmeldenloader: true })
                        })
                })
                promiseLogin.then((userData) => {
                    let myPlaintextPassword = password;

                    let firstName = userData.first_name
                    let lastName = userData.last_name

                    let jointName = firstName + lastName;

                    let filteredForJoin = this.state.allUsers.filter((g) => {
                        return g.user_name_id === jointName.toLowerCase();
                    });

                    if (filteredForJoin.length > 0) {
                        let randomStr = Math.ceil(Math.random() * (20 - 0) + 0);
                        jointName = firstName + lastName + randomStr;
                    }

                    if (jointName.includes(" ")) {
                        jointName = jointName.replace(/ /g, "_");
                    }

                    bcrypt.genSalt(saltRounds, (err, salt) => {
                        bcrypt.hash(myPlaintextPassword, salt, (err, hash) => {
                            firebase.database().ref(`users/${userData.user_id}/user_name_id`).set(jointName.toLowerCase())
                            firebase.database().ref(`users/${userData.user_id}/user_id_numbers`).set(this.state.currentUserSpecific)
                            firebase.database().ref(`users/${userData.user_id}/city`).set(city)
                            firebase.database().ref(`users/${userData.user_id}/password`).set(hash)

                            var user = firebase.auth().currentUser;
                            user
                                .updatePassword(myPlaintextPassword)
                                .then((e) => {
                                    firebase
                                        .auth()
                                        .signInWithEmailAndPassword(emailUrl, myPlaintextPassword)
                                        .then((succ) => {
                                            localStorage.setItem("userid", succ.user.uid);
                                            // localStorage.setItem("userdata", JSON.stringify(a))
                                            localStorage.setItem("isAdmin", false);
                                            this.props.updateAdminOrNot(false);
                                            localStorage.removeItem("onCurrentTab");
                                            localStorage.removeItem("onCurrentTabCompany");
                                            localStorage.setItem("currentAlbumOwnImage", "vrienden");

                                            setTimeout(() => {
                                                window.location.href = `${window.location.origin}/member/${jointName.toLowerCase()}`
                                            }, 500);
                                        })
                                })
                        })
                    })
                })
            }
        }
    }

    render() {
        return (
            this.state.showMainLoader ? (
                <div
                    className="global-event-cart globaleventloaderpage"
                    style={{
                        height: "auto",
                        top: 0,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        zIndex: 999999999,
                    }}
                >
                    <div className="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            ) : <div className={`global-event-cart globaleventcartfixedstickyfooter companysubscribepagelink onboarduserspage`}>
                {this.state.loadingAddress && (<div className="loader-fullpage-checkout">
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                </div>)}
                <div className="left-gec">
                    <div className="container-lgec">
                        <div className="top-step-one-clgec">
                            <label>Welkom op Bhodi!</label>
                            <label>Account aanmaken</label>
                            <label>
                                Hartelijk dank voor je aanmelding, kies je wachtwoord en{window.innerWidth > 420 && (<br />)}
                                maak eenvoudig je account aan en ontdek het platform.
                            </label>
                        </div>
                        <div className="forms-clgec">
                            <div className={`form-field-clgec ${this.state.passInvalid ? "redalertffcl" : ""}`}>
                                <input
                                    placeholder='Kies wachtwoord'
                                    type='password'
                                    value={this.state.password}
                                    onChange={(e) => {
                                        this.setState({ password: e.target.value })
                                    }}
                                />
                            </div>
                            <div className={`form-field-clgec  citypickercontainer`}>
                                <GooglePlacesAutocomplete
                                    apiKey="AIzaSyBing0s1IsYV0GaPy2Fu513--sq5EPGLFg"
                                    autocompletionRequest={{ types: ["locality"] }}
                                    selectProps={{
                                        value: this.state.checkCity,
                                        placeholder: "Woonplaats",
                                        onChange: (val) => {
                                            this.setState({ checkCity: val });
                                            geocodeByPlaceId(val.value.place_id)
                                                .then((results) => {
                                                    let filtered = results[0].address_components.filter(
                                                        (e) => {
                                                            return (
                                                                e.types[0] === "locality" &&
                                                                e.types[1] === "political"
                                                            );
                                                        }
                                                    );
                                                    this.setState({ city: filtered[0].long_name })
                                                })
                                                .catch((error) => console.error(error));
                                        },
                                    }}
                                />
                            </div>
                            {this.state.cityPopup && (
                                <div className="alert-field">
                                    <span style={{ letterSpacing: 1 }}>
                                        Please select city
                                    </span>
                                </div>
                            )}
                            <div
                                className="fx-registration-inner"
                                style={{ alignItems: "center" }}
                            >
                                <div className="selectoptionfull" style={{ marginTop: 15 }}>
                                    <div
                                        className="left-frvn"
                                        style={{
                                            width: "100%",
                                            paddingLeft: 10,
                                            backgroundColor: "transparent",
                                        }}
                                    >
                                        <div className="checking-box">
                                            <input
                                                type="checkbox"
                                                checked={this.state.agreed}
                                                onChange={() =>
                                                    this.setState({ agreed: !this.state.agreed })
                                                }
                                            />
                                            <label
                                                style={{
                                                    color: this.state.agreedPopup ? "red" : "black",
                                                    fontSize: 13,
                                                }}
                                            >
                                                <font
                                                    style={{
                                                        textDecorationLine: "underline",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                        window.open(
                                                            "https://media.bhodi.com/gebruikersvoorwaarden.pdf",
                                                            "_blank" // <- This is what makes it open in a new window.
                                                        );
                                                    }}
                                                >
                                                    Akkoord met voorwaarden
                                                </font>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bottom-buttons-clgec">
                                {this.state.aanmeldenloaderNew && (
                                    <div className="lds-ellipsis">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                )}
                                {this.state.aanmeldenloader ? (
                                    window.innerWidth < 1008 && (
                                        // <button style={{ width: "100%" }}>
                                        //     <div className="lds-ellipsis">
                                        //         <div></div>
                                        //         <div></div>
                                        //         <div></div>
                                        //         <div></div>
                                        //     </div>
                                        // </button>
                                        <></>
                                    )
                                ) : (
                                    <button
                                        onClick={() => {
                                            this.processAccount()
                                        }}
                                    >
                                        {"Volgende"}
                                        {window.innerWidth < 1008 && <ChevronRightIcon />}
                                    </button>
                                )}
                                {this.state.aanmeldenloader && (
                                    <div className="lds-ellipsis">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {window.innerWidth > 1008 && (<div className="right-gec">

                </div>)}


                {window.innerWidth > 1008 && <Footer notOnMainPage={true} />}
                {window.innerWidth < 1008 && <FooterGreen notOnMainPage={true} />}
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.authReducers.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateAdminOrNot: (admin_boolean) => dispatch(updateAdminOrNot(admin_boolean)),
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OnboardUsers);