import React, { Component } from 'react'
import LandingPage from '../LandingPage'
import NewProductWrapper from '../ProductsPage/NewProductsWrapper'
import Footer from '../../Components/Footer'
import CharityLandingPage from '../CharityLandingsPage'
import CartNonUser from '../../Components/CartNonUser'
import FooterBasket from '../../Components/FooterBasket'
import FooterGreen from '../../Components/FooterGreen'

export class SpecialProducts extends Component {

    constructor() {
        super()
        this.state = {
            currentUserId: ""
        }
        this.charityPageRef = React.createRef()
        this.childRef = React.createRef()
    }

    componentDidMount() {
        let userid = localStorage.getItem("userid")
        this.setState({ currentUserId: userid })
    }

    render() {
        return (
            <div className='specialproductslandingpage'>
                {this.state.currentUserId ? (<CartNonUser isSpecialProduct={true} notNeedOrders={true} />) : (<FooterBasket isSpecialProduct={true} />)}
                <LandingPage onSpecialProducts={true} moveToCharitySection={() => {
                    this.charityPageRef.current.scrollIntoView({ behavior: 'smooth' });
                }} moveToAnotherSection={() => {
                    if (this.childRef.current) {
                        this.childRef.current.scrollIntoView({ behavior: 'smooth' });
                    }
                }} />
                <div style={{ paddingBottom: 50 }}>
                    <NewProductWrapper toShowProductOf={process.env.REACT_APP_ADMIN_ID} isSpecialProductsPage={true} />
                </div>
                <div ref={this.charityPageRef}>
                    <CharityLandingPage dontshowMenu={true} onLandingPage={true} isSpecialProductsPage={true} refProp={this.childRef} />
                </div>
                {window.innerWidth > 1008 ? (<Footer notOnMainPage={true} />) : (<FooterGreen notOnMainPage={true} />)}
            </div>
        )
    }
}

export default SpecialProducts