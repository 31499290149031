import localforage from 'localforage';

let subscription_plans = ""
let global_values = ""
let all_impressies = ""
let signup_fields_info = ""
let admin_boolean = ""
let bhodi_sounds = ""
let charity_videos = ""
let stock_images = ""
let bhodi_abouts = ""
let bhodi_faqs = ""
let bhodi_faqs_guests = ""
let bhodi_brands = ""

localforage.getItem("persist:root").then((value) => {
    if (value) {
        let converted = JSON.parse(value)
        if (converted.globalReducers) {
            let parsed = JSON.parse(converted.globalReducers)
            if (parsed.subscription_plans) {
                subscription_plans = parsed.subscription_plans
            }
            if (parsed.global_values) {
                global_values = parsed.global_values
            }
            if (parsed.all_impressies) {
                all_impressies = parsed.all_impressies
            }
            if (parsed.signup_fields_info) {
                signup_fields_info = parsed.signup_fields_info
            }
            if (parsed.admin_boolean) {
                admin_boolean = parsed.admin_boolean
            }
            if (parsed.bhodi_sounds) {
                bhodi_sounds = parsed.bhodi_sounds
            }
            if (parsed.charity_videos) {
                charity_videos = parsed.charity_videos
            }
            if (parsed.stock_images) {
                stock_images = parsed.stock_images
            }
            if (parsed.bhodi_abouts) {
                bhodi_abouts = parsed.bhodi_abouts
            }
            if (parsed.bhodi_faqs) {
                bhodi_faqs = parsed.bhodi_faqs
            }
            if (parsed.bhodi_faqs_guests) {
                bhodi_faqs_guests = parsed.bhodi_faqs_guests
            }
            if (parsed.bhodi_brands) {
                bhodi_brands = parsed.bhodi_brands
            }
        }
    }
})

const initial_state = {
    subscription_plans: subscription_plans ? subscription_plans : null,
    global_values: global_values ? global_values : null,
    all_impressies: all_impressies ? all_impressies : null,
    signup_fields_info: signup_fields_info ? signup_fields_info : null,
    admin_boolean: admin_boolean ? admin_boolean : null,
    bhodi_sounds: bhodi_sounds ? bhodi_sounds : null,
    charity_videos: charity_videos ? charity_videos : null,
    stock_images: stock_images ? stock_images : null,
    bhodi_abouts: bhodi_abouts ? bhodi_abouts : null,
    bhodi_faqs: bhodi_faqs ? bhodi_faqs : null,
    bhodi_faqs_guests: bhodi_faqs_guests ? bhodi_faqs_guests : null,
    bhodi_brands: bhodi_brands ? bhodi_brands : null,
}

const reducer = (state = initial_state, action) => {
    switch (action.type) {
        case "update_subscription_plans": {
            return { ...state, subscription_plans: action.subscription_plans }
        }
        case "update_global_stuff": {
            return { ...state, global_values: action.global_values }
        }
        case "update_impressies": {
            return { ...state, all_impressies: action.all_impressies }
        }
        case "update_signup_fields_info": {
            return { ...state, signup_fields_info: action.signup_fields_info }
        }
        case "update_admin_or_not": {
            return { ...state, admin_boolean: action.admin_boolean }
        }
        case "update_charity_videos": {
            return { ...state, charity_videos: action.charity_videos }
        }
        case "update_bhodi_sounds": {
            return { ...state, bhodi_sounds: action.bhodi_sounds }
        }
        case "update_stock_images": {
            return { ...state, stock_images: action.stock_images }
        }
        case "update_bhodi_abouts": {
            return { ...state, bhodi_abouts: action.bhodi_abouts }
        }
        case "update_bhodi_faqs": {
            return { ...state, bhodi_faqs: action.bhodi_faqs }
        }
        case "update_bhodi_faqs_guests": {
            return { ...state, bhodi_faqs_guests: action.bhodi_faqs_guests }
        }
        case "update_bhodi_brands": {
            return { ...state, bhodi_brands: action.bhodi_brands }
        }
        default: {
            return state;
        }
    }
}

export default reducer