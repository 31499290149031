import React, { Component } from "react";
import "./index.css";

export class MemberOwnProducts extends Component {
  render() {
    return <div>
    </div>
  }
}

export default MemberOwnProducts;
