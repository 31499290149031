import React, { Component } from "react";
import firebase from "../Config/Firebase";
import { connect } from "react-redux";
import {
  updateUser,
  updateMessage,
  updateAlbums,
} from "../Redux/actions/authActions";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";

export class ViewerFriendList extends Component {
  constructor() {
    super();
    this.state = {
      arr: [],
      friendRequests: [],
      noFriendRequests: false,
      showFriends: true,
      showGroups: false,
      arrOfRequests: [],
      arrOfRejects: [],
      arrOfBlocked: [],
      rejectionModal: false,
      noFriends: false,
      inviteHeading: true,
      createGroupModal: false,
      friendsArray: [],
      groupNameInput: "",
      groupDescriptionInput: "",
      arrUidsOfFriends: [],
      src: "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9",
      arrGroups: [],
      groupUsers: [],
      arrForCheckingIcons: [],
      currentUsersGroup: [],
      groupsSettingsModal: false,
      currentUsersAlbums: [],
      arrForCheckingIconsAlbums: [],
      arrAlbums: [],
      sendingMessage: "",
      allMessages: [],
      allUrls: [],
      allProductsCompleted: [],
      alreadyScrolledOnce: false,
      showGroupsWork: false,
      showPermissions: false,
      arrOfFollow: [],
      arrOfFriendsRightMenu: [],
      arrOfSentRequests: [],
      currentUser: "",
      arrOfFollowed: [],
      arrOfFollowing: [],
      ownUser: [],
      beherenOnMobile: false,
      currentChatUser: "",
      arrForSearch: [],
      allMessagesGlobal: [],
    };
  }

  componentDidMount() {
    const loaderId = document.getElementById("main-page-loader-id");
    loaderId.style.display = "none";
    let userid = localStorage.getItem("userid");
    this.setState({ currentUserid: userid });

    let showVerzondenOnDefault = localStorage.getItem("showVerzondenOnDefault");
    if (showVerzondenOnDefault) {
      localStorage.removeItem("showVerzondenOnDefault");
      if (window.innerWidth > 1009) {
        this.setState({ rejectionModal: true });
        this.getSentRequests();
      } else {
        this.setState({ beherenTab: true, beherenOnMobile: true });
        this.getSentRequests();
      }
    }

    if (this.props.user) {
      if (this.props.user.length > 0) {
        let arrUsers = this.props.user;
        let currentUser = "";
        let filtered = this.props.user.filter((t) => {
          return t.user_id === this.props.viewerOnUser.user_id;
        });
        if (filtered.length > 0) {
          currentUser = filtered[0];
          this.setState({ currentUser });
          let a = currentUser;
          let friendsAll = "";
          let friendsAllOne = [];
          if (a.following) {
            friendsAll = Object.entries(a.following);
            friendsAllOne = [];
            friendsAll.map((e) => {
              let filteredFriends = arrUsers.filter((f) => {
                return f.user_id === e[1].user_id;
              });
              friendsAllOne.push(filteredFriends[0]);
            });
            friendsAllOne.map((e) => {
              if (this.props.message) {
                let ownMessages = this.props.message.filter((messages) => {
                  return messages[0] === e.user_id;
                });
                if (ownMessages.length > 0) {
                  let objected = Object.entries(ownMessages[0][1]);
                  objected.map((f) => {
                    let checkTimeStamp = new Date(
                      f[1].date.split("-")[2],
                      Number(f[1].date.split("-")[1]) - 1,
                      f[1].date.split("-")[0]
                    );
                    let timestampMax = f[1].time;
                    checkTimeStamp.setHours(timestampMax.split(":")[0]);
                    checkTimeStamp.setMinutes(timestampMax.split(":")[1]);
                    checkTimeStamp.setSeconds(timestampMax.split(":")[2]);
                    f[1].timeStamp = checkTimeStamp.getTime();
                  });
                  e.timeStamp = objected.reverse()[0][1].timeStamp;
                } else {
                  e.timeStamp = 0;
                }
              } else {
                e.timeStamp = 0;
              }
            });
            let sorted = friendsAllOne.sort((x, y) => {
              return y.timeStamp - x.timeStamp;
            });
            this.setState({ arr: sorted });
          } else {
            this.setState({ arr: [], noFriends: true });
          }
        }
      }
    }

    firebase
      .database()
      .ref("users")
      .on("value", (data) => {
        let a = data.val();
        let arrUsers = [];
        let currentUser = "";
        if (a) {
          let entries = Object.entries(a);
          entries.map((f) => {
            arrUsers.push(f[1]);
            if (f[1].user_id === this.props.viewerOnUser.user_id) {
              currentUser = f[1];
            }
          });
          if (currentUser) {
            this.setState({ currentUser });
            let a = currentUser;
            let friendsAll = "";
            let friendsAllOne = [];
            if (a.following) {
              friendsAll = Object.entries(a.following);
              friendsAllOne = [];
              friendsAll.map((e) => {
                let filteredFriends = arrUsers.filter((f) => {
                  return f.user_id === e[1].user_id;
                });
                friendsAllOne.push(filteredFriends[0]);
              });

              friendsAllOne.map((e) => {
                if (this.props.message) {
                  let ownMessages = this.props.message.filter((messages) => {
                    return messages[0] === e.user_id;
                  });
                  if (ownMessages.length > 0) {
                    let objected = Object.entries(ownMessages[0][1]);
                    objected.map((f) => {
                      let checkTimeStamp = new Date(
                        f[1].date.split("-")[2],
                        Number(f[1].date.split("-")[1]) - 1,
                        f[1].date.split("-")[0]
                      );
                      let timestampMax = f[1].time.concat(":00");
                      checkTimeStamp.setHours(timestampMax.split(":")[0]);
                      checkTimeStamp.setMinutes(timestampMax.split(":")[1]);
                      checkTimeStamp.setSeconds(timestampMax.split(":")[2]);
                      f[1].timeStamp = checkTimeStamp.getTime();
                    });
                    e.timeStamp = objected.reverse()[0][1].timeStamp;
                  } else {
                    e.timeStamp = 0;
                  }
                } else {
                  e.timeStamp = 0;
                }
              });
              let sorted = friendsAllOne.sort((x, y) => {
                return y.timeStamp - x.timeStamp;
              });
              // this.setState({ arr: sorted })
              this.setState({
                arr: sorted,
                currentUser,
              });
            } else {
              this.setState({
                arr: [],
                noFriends: true,
              });
            }
          }
          this.props.updateUser(arrUsers);
        }
      });
  }

  componentDidUpdate() {
    var objDiv = document.getElementById("scrollerdivmsg");
    if (objDiv && !this.state.alreadyScrolledOnce) {
      objDiv.scrollTo(0, objDiv.scrollHeight);
      // objDiv.scrollTop = objDiv.scrollHeight;
      this.setState({ alreadyScrolledOnce: true });
    }
  }

  render() {
    return (
      <div
        className="rightmenuaddproduct rightmenucommentsaddproduct rightmenuproductfriendlists friendlistSeparatePageMain rightmenuonlychangeheader changeheaderonlyrightmenu"
        // className="rightmenuaddproduct rightmenucommentsaddproduct rightmenuproductfriendlists"
        style={{ overflow: "hidden" }}
      >
        <div
          className="mid-rmap"
          style={{
            position: "relative",
            padding: 13,
            // alignItems: "flex-start",
            // flexDirection: window.innerWidth > 1008 && "row",
            // justifyContent: window.innerWidth > 1008 && "space-between",
          }}
        >
          {/* {window.innerWidth > 1008 && (
            <div className="name-top-at">
              <img
                src={
                  this.state.currentUser.profile_image
                    ? this.state.currentUser.profile_image
                    : "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9"
                }
                style={{ width: 30, height: 30, borderRadius: "50%" }}
              />
              <p>
                {this.state.currentUser.type === "company"
                  ? this.state.currentUser.company_name
                  : this.state.currentUser.first_name +
                  " " +
                  this.state.currentUser.last_name}
              </p>
            </div>
          )} */}
          <span>Vrienden</span>
          {/* <span>&nbsp;</span> */}
          <div>
            <CloseIcon
              style={{
                cursor: "pointer",
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                right: 20,
                fontSize: 18,
              }}
              onClick={() => {
                this.props.closePopup();
              }}
            />
          </div>
        </div>
        <div
          className="mid-img-uploading-rmap commentsmidimgurmap"
          style={{ alignItems: "flex-start" }}
        >
          <div style={{ width: "100%", marginTop: 15 }}>
            <div className="search-rm-main">
              <input type="text" placeholder={this.state.currentUser.type === "company" ? `Vrienden van ${this.state.currentUser.company_name}` : `Vrienden van ${this.state.currentUser.first_name} ${this.state.currentUser.last_name}`} />
              <SearchIcon />
            </div>
            {this.state.arr.length > 0 &&
              [...this.state.arr]
                .sort((x, y) => {
                  return y.timestamp - x.timestamp;
                })
                .map((e, i) => {
                  return (
                    <>
                      <div className="comment-block-iled">
                        <div
                          className="credentials-cbiled"
                          onClick={() => {
                            if (e.type === "company") {
                              window.location.href = `/company/${e.user_name_id}`;
                            } else {
                              window.location.href = `/member/${e.user_name_id}`;
                            }
                          }}
                        >
                          {e.profile_image ? (
                            <img
                              src={e.profile_image}
                              alt="userimage"
                              onClick={() => {
                                if (e.type === "company") {
                                  window.location.href = `/company/${e.user_name_id}`;
                                } else {
                                  window.location.href = `/member/${e.user_name_id}`;
                                }
                              }}
                            />
                          ) : (
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9"
                              alt="userimage"
                              onClick={() => {
                                if (e.type === "company") {
                                  window.location.href = `/company/${e.user_name_id}`;
                                } else {
                                  window.location.href = `/member/${e.user_name_id}`;
                                }
                              }}
                            />
                          )}
                          {/* <div className='overflow-redirect' onClick={() => {
                                            let userId = localStorage.getItem("userid")
                                            firebase.database().ref("allMessages/" + userId + "/" + e.user_id).once("value", (data) => {
                                                let allMessages = []
                                                let a = data.val()
                                                if (a) {
                                                    a = Object.entries(a)
                                                    a.map((e, i) => {
                                                        allMessages.push(a[i][1])
                                                    })
                                                }
                                                this.setState({ allMessages, currentChatUser: e })
                                                allMessages.map((g) => {
                                                    firebase.database().ref("allMessages/" + userId + "/" + e.user_id + "/" + g.key + "/seen").set(true)
                                                })
                                            })
                                            this.setState({ chatPopup: true, selectedUser: e })
                                        }}></div> */}
                          {e.type === "user" ? (
                            <label>
                              {e.first_name} {e.last_name}
                            </label>
                          ) : (
                            <label>{e.company_name}</label>
                          )}
                          {/* <p className="recentmsgdate" >{this.getRecentMessageDate(e)}</p>
                                        {this.getMessageCount(e)} */}
                        </div>
                      </div>
                    </>
                  );
                })}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducers.user,
    message: state.authReducers.message,
    album: state.postsReducers.album,
    images: state.postsReducers.images,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: (user) => dispatch(updateUser(user)),
    updateMessage: (message) => dispatch(updateMessage(message)),
    updateAlbums: (album) => dispatch(updateAlbums(album)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewerFriendList);
