import localforage from 'localforage';

let guest_user_cart = ""
let guest_user_cart_time = ""
let guest_user_cart_extend_opportunity = ""
let guest_db_cart = ""
let global_cart = ""

localforage.getItem("persist:root").then((value) => {
    if (value) {
        let converted = JSON.parse(value)
        if (converted.cartReducer) {
            let parsed = JSON.parse(converted.cartReducer)
            if (parsed.guest_user_cart) {
                guest_user_cart = parsed.guest_user_cart
            }
            if (parsed.guest_user_cart_time) {
                guest_user_cart_time = parsed.guest_user_cart_time
            }
            if (parsed.guest_user_cart_extend_opportunity) {
                guest_user_cart_extend_opportunity = parsed.guest_user_cart_extend_opportunity
            }
            if (parsed.guest_db_cart) {
                guest_db_cart = parsed.guest_db_cart
            }
            if (parsed.global_cart) {
                global_cart = parsed.global_cart
            }
        }
    }
})


const initial_state = {
    guest_user_cart: guest_user_cart ? guest_user_cart : null,
    guest_user_cart_time: guest_user_cart_time ? guest_user_cart_time : null,
    guest_user_cart_extend_opportunity: guest_user_cart_extend_opportunity ? guest_user_cart_extend_opportunity : null,
    guest_db_cart: guest_db_cart ? guest_db_cart : null,
    global_cart: global_cart ? global_cart : null,
}

const reducer = (state = initial_state, action) => {
    switch (action.type) {
        case "update_guest_user_cart": {
            return { ...state, guest_user_cart: action.guest_user_cart }
        }
        case "update_guest_user_cart_time": {
            return { ...state, guest_user_cart_time: action.guest_user_cart_time }
        }
        case "update_guest_user_cart_extend_opportunity": {
            return { ...state, guest_user_cart_extend_opportunity: action.guest_user_cart_extend_opportunity }
        }
        case "update_guest_db_cart": {
            return { ...state, guest_db_cart: action.guest_db_cart }
        }
        case "update_my_cart": {
            return { ...state, global_cart: action.global_cart }
        }
        default: {
            return state;
        }
    }
}

export default reducer