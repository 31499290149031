import localforage from 'localforage';

let album = ""
let images = ""

localforage.getItem("persist:root").then((value) => {
    if (value) {
        let converted = JSON.parse(value)
        if (converted.postsReducers) {
            let parsed = JSON.parse(converted.postsReducers)
            if (parsed.album) {
                album = parsed.album
            }
            if (parsed.images) {
                images = parsed.images
            }
        }
    }
})

const initial_state = {
    album: album ? album : null,
    images: images ? images : null,
}

const reducer = (state = initial_state, action) => {
    switch (action.type) {
        case "get_all_albums": {
            return { ...state, album: action.album }
        }
        case "get_all_images": {
            return { ...state, images: action.images }
        }
        default: {
            return state;
        }
    }
}

export default reducer