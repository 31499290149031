import React from "react";
import "./index.css";

export class ImagesSection extends React.PureComponent {
  render() {
    return (<div>
    </div>)
  }
}


export default ImagesSection;
