import React, { Component } from 'react'
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { connect } from 'react-redux';
import { history } from '../../Config/Routes';
import AnnouncementBannerNew from '../ProductsPage/AnnouncementBannerNew';

export class SubscribedProductsTab extends Component {
    constructor() {
        super()
        this.state = {
            currentCompany: "",
        }
    }

    componentDidMount() {
        if (this.props.user && this.props.toShowBannerOf) {
            let filtered = this.props.user.filter((t) => {
                return t.user_id === this.props.toShowBannerOf
            })
            if (filtered.length > 0) {
                console.log(filtered[0], '/see here')
                this.setState({ currentCompany: filtered[0] })
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.user !== prevProps.user || this.props.toShowBannerOf !== prevProps.toShowBannerOf) {
            let filtered = this.props.user.filter((t) => {
                return t.user_id === this.props.toShowBannerOf
            })
            if (filtered.length > 0) {
                this.setState({ currentCompany: filtered[0] })
            }
        }
    }


    render() {
        return (
            <div className='banner-mhn bmhn-admin' style={{ backgroundImage: "none", height: !this.props.isDesktop && 500, padding: 0 }}>
                {this.state.currentCompany && (<AnnouncementBannerNew announcement={this.state.currentCompany.announcement} currentCompanyData={this.state.currentCompany} showNotifyButton={false} isAtMembersPage={true} />)}
                {/* <div className='content-bmhn'>
                    <p>SUBSCRIPTIONS</p>
                    <p>{this.state.currentCompany.company_name}</p>
                    <p>{this.state.adminProduct.title}</p>
                    <p>{this.state.adminProduct.desc && this.state.adminProduct.desc.substr(0, 140)}</p>
                    <p><s>€{this.state.adminProduct.price}</s> for Free</p>
                    <div className='button-admin-note'>
                        <button onClick={() => {
                            history.push(`/company/${this.state.currentCompany.user_name_id}`)
                        }}>Bekijk</button>
                    </div>
                </div>
                <div className='overlay-bchn'>
                </div> */}
                {window.innerWidth > 1008 && (<div className="bottom-csfs-shop">
                    <div
                        className="unds-buttons"
                        style={{ alignItems: "center" }}
                    >
                        <div
                            className="inner-undb"
                            onClick={() => {
                                this.props.scrollToProducts();
                            }}
                        >
                            <KeyboardArrowDownIcon />
                        </div>
                    </div>
                </div>)}
            </div>
        )
    }
}



const mapStateToProps = (state) => {
    return {
        user: state.authReducers.user,
    };
};

export default connect(
    mapStateToProps,
)(SubscribedProductsTab);