import React, { Component } from "react";
// import './index.css'
import { connect } from "react-redux";
import {
  updateGuestUserCart,
  updateGuestUserCartExtendOpportunity,
  updateGuestUserCartTime,
} from "../Redux/actions/authActions";
import "./index.css";
import firebase from "../Config/Firebase";
import { mergeAdminEvent } from "../Functions/globalFunctions";

export class FooterBasketTimer extends Component {
  constructor() {
    super();
    this.state = {
      black: false,
      popupright: false,
      popupitems: false,
      popupmarket: false,
      popupservices: false,
      popupcompanies: false,
      lists: [1, 2, 3, 4, 5],
      cartItems: [],
      arrItemsOfCart: [],
      currentUserHere: "",
      allSellers: [],
      currentUserId: "",
      allSectors: [],
      availableMainSector: [],
      availableMainSectorVouchers: [],
      allSectors: [],
      availableMainSectorShop: [],
      allSectorsVouchers: [],
      filteredAllSectors: [],
      filteredAllSectorsFilter: [],
      allEvents: [],
      availableMainSectorEvents: [],
      filteredAllSectorsFilterEvents: [],
      allVouchers: [],
      endDate: "",
      allCampaigns: [],
      popupExtend: false,
      extendOpportunity: false,
      alreadyBewared: [],
      alreadyBewaredCampaigns: [],
      currentUserExclusives: [],
      friendsExclusives: [],
      availableAlbumCategories: [],
      categoriesToShow: [],
      filteredAllCategoriesAlbums: [],
      noFilterAlbumCategories: false,
      allCampaignsToFilter: [],
      allBewaaredThings: [],
      availableImageCategories: [],
      availableMainSectorVoucherEvents: [],
      guestUserCart: [],
      stopCartQty: false,
      completeCartArray: [],
    };
  }

  componentDidMount() {
    const loaderId = document.getElementById("main-page-loader-id");
    loaderId.style.display = "none";
    let userid = localStorage.getItem("userid");
    this.setState({ currentUserId: userid });

    let cookieItem = localStorage.getItem("cookie_item");
    if (userid && !cookieItem) {
      cookieItem = userid;
    }
    if (cookieItem) {
      firebase
        .database()
        .ref("guest_carts/" + cookieItem)
        .on("value", (data) => {
          let a = data.val();
          if (a) {
            let arr = [];
            let arrKeys = [];
            let arrComplete = [];
            let arrCompleteBhodiProducts = [];
            let objected = Object.entries(a);
            objected.map((y) => {
              arr.push(y[1]);
              arrKeys.push(y[1].itemPush);
            });

            this.setState({ completeCartArray: arr });

            if (this.props.global_campaigns) {
              if (this.props.global_campaigns.length > 0) {
                // this.interval = setInterval(() => this.setState({ timeCurrent: Date.now() }), 1000);
                let arrSecCheck = [];

                this.props.global_campaigns.map((i) => {
                  arrSecCheck.push(i[1]);
                });
                arrSecCheck.map((g) => {
                  if (g.active) {
                    g.type = "campaign";
                    g.currentImageIndex = 0;
                    if (g.desc) {
                      g.desc = g.desc.substr(0, 200);
                      g.short_desc = g.desc.substr(0, 120);
                    }
                    if (g.campaignType !== "charity") {
                      g.images = [g.image];
                      g.imagesObjected = [
                        { image: g.image, pushKey: g.pushKey },
                      ];
                    }
                    arrComplete.push(g);
                  }
                });

                this.setState({ allCampaigns: arrComplete });
              }
            }
            if (this.props.bhodi_products) {
              if (this.props.bhodi_products.length > 0) {
                this.props.bhodi_products.map((g) => {
                  if (g.active) {
                    g.type = "bhodi_product";
                    g.currentImageIndex = 0;
                    if (g.desc) {
                      g.desc = g.desc.substr(0, 200);
                      g.short_desc = g.desc.substr(0, 120);
                    }
                    arrCompleteBhodiProducts.push(g);
                  }
                });

                this.setState({ allBhodiProducts: arrCompleteBhodiProducts });
              }
            }

            let everyCartItems = [];
            arrComplete.map((y) => {
              objected.map((t) => {
                if (t[1].item_type === "bhodi_product") {
                  return false;
                }
                if (t[1].type === "product") {
                  everyCartItems.push(y);
                }
                if (y.pushKey === t[1].itemPush) {
                  if (y.campaignType === "local_event") {
                    if (y.takeFromAdmin) {
                      let obj = mergeAdminEvent(y, this.props.global_campaigns)
                      y = Object.assign({}, obj)
                    }
                    // y.reserved_event_id = t[1].event_id
                    let obj = {
                      ...y,
                      reserved_event_id: t[1].event_id
                    }
                    everyCartItems.push(obj);
                  } else {
                    everyCartItems.push(y);
                  }
                }
              });
            });
            arrCompleteBhodiProducts.map((y) => {
              objected.map((t) => {
                if (t[1].item_type === "bhodi_product") {
                  if (t[1].itemPush === y.pushKey) {
                    // t[1].mainProduct = y;
                    let currentObj = Object.assign({}, y)
                    currentObj.item_type = "bhodi_product"
                    if (t[1].selectedSize) {
                      currentObj.selectedSizeOfOrder = t[1].selectedSize
                    }
                    everyCartItems.push(currentObj);
                  }
                }
              });
            });

            let toDoFilterCompanies = [];
            let toDoFilter = [];
            everyCartItems.map((b) => {
              if (b.isNotActive) {
                b.hideFromListComplete = true;
              } else {
                b.hideFromListComplete = false;
                toDoFilterCompanies.push(b);
              }
              if (b.item_type === "bhodi_product") {
                toDoFilter.push(b);
              } else if (b.type === "product") {
                toDoFilter.push(b);
              } else {
                toDoFilter.push(b);
              }
            });
            let filteredSecMa = toDoFilterCompanies.filter(
              (v, i, a) =>
                a.findIndex((t) => t.created_by === v.created_by) === i
            );
            let arrMainSellers = [];
            filteredSecMa.map((y) => {
              arrMainSellers.push(y.created_by);
            });
            this.props.updateGuestUserCart(everyCartItems);
            this.setState({
              guestUserCart: everyCartItems,
              allSellers: arrMainSellers,
            });
            this.interval = setInterval(
              () => this.setState({ timeCurrent: Date.now() }),
              1000
            );

            if (this.props.guest_user_cart_time) {
              let currentDate = Math.ceil(new Date().getTime() / 1000);
              if (
                Number(currentDate) < Number(this.props.guest_user_cart_time)
              ) {
                this.setState({
                  endDate: this.props.guest_user_cart_time,
                  extendOpportunity: this.props.guest_user_cart_extend_opportunity,
                });
              }
              //  else {
              //   console.log("working this to clear")
              //   this.props.updateGuestUserCartTime("");
              //   firebase
              //     .database()
              //     .ref("guest_carts/" + cookieItem)
              //     .remove();
              //   this.props.updateGuestUserCartExtendOpportunity("");
              //   this.props.updateGuestUserCart([]);
              //   clearInterval(this.interval);
              // }
            }
          } else {
            this.setState({ guestUserCart: [], allSellers: [] });
            this.props.updateGuestUserCart([]);
            this.props.updateGuestUserCartTime("");
            firebase
              .database()
              .ref("guest_carts/" + cookieItem)
              .remove();
            this.props.updateGuestUserCartExtendOpportunity("");
            clearInterval(this.interval);
          }
        });
    } else {
      firebase
        .database()
        .ref("guest_carts")
        .on("value", (data) => {
          let cookieItem = localStorage.getItem("cookie_item");
          if (userid && !cookieItem) {
            cookieItem = userid;
          }
          let as = data.val();
          if (as) {
            let objectedMain = Object.entries(data.val());
            let filtered = objectedMain.filter((u) => {
              return u[0] === cookieItem;
            });
            if (filtered.length > 0) {
              let a = filtered[0][1];
              let arr = [];
              let arrKeys = [];
              let arrComplete = [];
              let arrCompleteBhodiProducts = [];
              let objected = Object.entries(a);
              objected.map((y) => {
                arr.push(y[1]);
                arrKeys.push(y[1].itemPush);
              });

              this.setState({ completeCartArray: arr });

              if (this.props.global_campaigns) {
                if (this.props.global_campaigns.length > 0) {
                  // this.interval = setInterval(() => this.setState({ timeCurrent: Date.now() }), 1000);
                  let arrSecCheck = [];

                  this.props.global_campaigns.map((i) => {
                    arrSecCheck.push(i[1]);
                  });
                  arrSecCheck.map((g) => {
                    if (g.active) {
                      g.type = "campaign";
                      g.currentImageIndex = 0;

                      if (g.desc) {
                        g.desc = g.desc.substr(0, 200);
                        g.short_desc = g.desc.substr(0, 120);
                      }
                      if (g.campaignType !== "charity") {
                        g.images = [g.image];
                        g.imagesObjected = [
                          { image: g.image, pushKey: g.pushKey },
                        ];
                      } else {
                        if (g.dishes) {
                          g.images = [g.imageToRender];
                          g.imagesObjected = [
                            { image: g.imageToRender, pushKey: g.pushKey },
                          ];
                          g.title = g.main_title;
                          g.desc = g.dishesObjected[0].description;
                          g.charity_price = g.main_price;
                          g.normal_price = g.main_price;
                          g.amount = g.main_price;
                        }
                      }
                      arrComplete.push(g);
                    }
                  });

                  this.setState({ allCampaigns: arrComplete });
                }
              }
              if (this.props.bhodi_products) {
                if (this.props.bhodi_products.length > 0) {
                  this.props.bhodi_products.map((g) => {
                    if (g.active) {
                      g.type = "bhodi_product";
                      g.currentImageIndex = 0;
                      if (g.desc) {
                        g.desc = g.desc.substr(0, 200);
                        g.short_desc = g.desc.substr(0, 120);
                      }
                      arrCompleteBhodiProducts.push(g);
                    }
                  });

                  this.setState({ allBhodiProducts: arrCompleteBhodiProducts });
                }
              }

              let everyCartItems = [];
              arrComplete.map((y) => {
                objected.map((t) => {
                  if (t[1].item_type === "bhodi_product") {
                    return false;
                  }
                  if (y.pushKey === t[1].itemPush) {
                    // if (y.active) {
                    //   everyCartItems.push(y);
                    // }
                    if (y.active) {
                      if (y.campaignType === "local_event") {
                        if (y.takeFromAdmin) {
                          let obj = mergeAdminEvent(y, this.props.global_campaigns)
                          y = Object.assign({}, obj)
                        }
                        let obj = {
                          ...y,
                          reserved_event_id: t[1].event_id
                        }
                        everyCartItems.push(obj);
                      } else {
                        everyCartItems.push(y);
                      }
                    }
                  }
                });
              });
              arrCompleteBhodiProducts.map((y) => {
                objected.map((t) => {
                  if (t[1].item_type === "bhodi_product") {
                    if (t[1].itemPush === y.pushKey) {
                      // t[1].mainProduct = y;
                      let currentObj = Object.assign({}, y)
                      currentObj.item_type = "bhodi_product"
                      if (t[1].selectedSize) {
                        currentObj.selectedSizeOfOrder = t[1].selectedSize
                      }
                      everyCartItems.push(currentObj);
                    }
                  }
                });
              });

              let toDoFilterCompanies = [];
              let toDoFilter = [];
              everyCartItems.map((b) => {
                // if (b.isNotActive) {
                //   b.hideFromListComplete = true;
                // } else {
                //   b.hideFromListComplete = false;
                //   toDoFilterCompanies.push(b);
                // }
                // toDoFilter.push(b);
                if (b.isNotActive) {
                  b.hideFromListComplete = true;
                } else {
                  b.hideFromListComplete = false;
                  toDoFilterCompanies.push(b);
                }
                if (b.item_type === "bhodi_product") {
                  toDoFilter.push(b);
                } else if (b.type === "product") {
                  toDoFilter.push(b);
                } else {
                  toDoFilter.push(b);
                }
              });
              let filteredSecMa = toDoFilterCompanies.filter(
                (v, i, a) =>
                  a.findIndex((t) => t.created_by === v.created_by) === i
              );
              let arrMainSellers = [];
              filteredSecMa.map((y) => {
                arrMainSellers.push(y.created_by);
              });
              this.setState({
                guestUserCart: everyCartItems,
                allSellers: arrMainSellers,
              });
              this.interval = setInterval(
                () => this.setState({ timeCurrent: Date.now() }),
                1000
              );

              if (this.props.guest_user_cart_time) {
                let currentDate = Math.ceil(Date.now() / 1000);
                if (
                  Number(currentDate) < Number(this.props.guest_user_cart_time)
                ) {
                  this.props.updateGuestUserCart(everyCartItems);
                  this.setState({
                    endDate: this.props.guest_user_cart_time,
                    extendOpportunity: this.props.guest_user_cart_extend_opportunity,
                  });
                }
                // else {
                //   console.log("working this to clear")
                //   this.setState({
                //     guestUserCart: [],
                //     allSellers: [],
                //     endDate: "",
                //   });
                //   this.props.updateGuestUserCart([]);
                //   firebase
                //     .database()
                //     .ref("guest_carts/" + cookieItem)
                //     .remove();
                //   this.props.updateGuestUserCartTime("");
                //   this.props.updateGuestUserCartExtendOpportunity("");
                //   clearInterval(this.interval);
                // }
              }
            } else {
              this.setState({ guestUserCart: [], allSellers: [], endDate: "" });
              this.props.updateGuestUserCart([]);
              firebase
                .database()
                .ref("guest_carts/" + cookieItem)
                .remove();
              this.props.updateGuestUserCartExtendOpportunity("");
              this.props.updateGuestUserCartTime("");
              clearInterval(this.interval);
            }
          } else {
            this.setState({ guestUserCart: [], allSellers: [], endDate: "" });
            this.props.updateGuestUserCart([]);
            firebase
              .database()
              .ref("guest_carts/" + cookieItem)
              .remove();
            this.props.updateGuestUserCartTime("");
            this.props.updateGuestUserCartExtendOpportunity("");
            clearInterval(this.interval);
          }
        });
    }

    // let makeCart = this.props.guest_user_cart
    // if (makeCart) {
    //     if (makeCart.length > 0) {
    //         let toDoFilterCompanies = []
    //         let toDoFilter = []
    //         makeCart.map((b) => {
    //             if (b.isNotActive) {
    //                 b.hideFromListComplete = true
    //             } else {
    //                 b.hideFromListComplete = false
    //                 toDoFilterCompanies.push(b)
    //             }
    //             toDoFilter.push(b)
    //         })
    //         let filteredSecMa = toDoFilterCompanies.filter((v, i, a) => a.findIndex(t => (t.created_by === v.created_by)) === i)
    //         let arrMainSellers = []
    //         filteredSecMa.map((y) => {
    //             arrMainSellers.push(y.created_by)
    //         })
    //         this.setState({ guestUserCart: makeCart, allSellers: arrMainSellers })
    //         this.interval = setInterval(() => this.setState({ timeCurrent: Date.now() }), 1000);

    //         if (this.props.guest_user_cart_time) {
    //             let currentDate = Math.ceil(Date.now() / 1000)
    //             if (Number(currentDate) < Number(this.props.guest_user_cart_time)) {
    //                 this.setState({ endDate: this.props.guest_user_cart_time, extendOpportunity: this.props.guest_user_cart_extend_opportunity })
    //             } else {
    //                 let cartDup = [...makeCart]
    //                 console.log(cartDup, '/cart dup')
    //                 this.props.updateGuestUserCartTime("")
    //                 this.props.updateGuestUserCart([])
    //                 this.props.updateGuestUserCartExtendOpportunity("")
    //                 clearInterval(this.interval)
    //             }
    //         }
    //     }
    // }
  }

  getCartQtyEvents() {
    let maxDate = this.state.endDate;
    let currentDate = Math.ceil(Date.now() / 1000);
    if (this.state.endDate) {
      if (Number(currentDate) < Number(maxDate)) {
        return this.state.guestUserCart.length;
      } else {
        this.setState({ endDate: "", stopCartQty: true });
        this.props.updateGuestUserCart([]);
        let cookieItem = localStorage.getItem("cookie_item");
        let userid = localStorage.getItem("userid");
        if (userid && !cookieItem) {
          cookieItem = userid;
        }
        firebase
          .database()
          .ref("guest_carts/" + cookieItem)
          .remove();
        this.props.updateGuestUserCartTime("");
        this.props.updateGuestUserCartExtendOpportunity("");
        clearInterval(this.interval);
        return this.state.guestUserCart.length;
      }
    } else {
      return this.state.guestUserCart.length;
    }
  }

  getSellerName(e) {
    if (e) {
      let filteredUser = this.props.user.filter((g) => {
        return g.user_id === e;
      });
      if (filteredUser.length > 0) {
        if (filteredUser[0].type === "company") {
          if (filteredUser[0].company_name) {
            return filteredUser[0].company_name;
          } else {
            return filteredUser[0].first_name + " " + filteredUser[0].last_name;
          }
        } else {
          return filteredUser[0].first_name + " " + filteredUser[0].last_name;
        }
      }
    }
  }

  getTheTimer() {
    let dateToEnd = new Date(this.state.endDate * 1000);
    const date1 = new Date();
    const date2 = new Date(dateToEnd);
    // const diffTime = Math.abs(date2 - date1);
    // const diffMinutes = Math.ceil(diffTime / (1000 * 60));
    // const diffSeconds = Math.ceil(diffTime / (1000 * 60 * 60));

    var delta = Math.abs(date2 - date1) / 1000;

    var hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    var minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    var seconds = delta % 60;

    if (minutes < 10) {
      minutes = `0${minutes}`;
    }

    if (Math.ceil(seconds) < 10) {
      seconds = `0${Math.round(seconds)}`;
    } else {
      seconds = Math.round(seconds);
    }

    return `${minutes}:${seconds}`;
  }

  checkForTimerHeading() {
    let filtered = this.state.guestUserCart.filter((b) => {
      return !b.isNotActive && !b.hideFromListComplete;
    });

    if (filtered.length > 0) {
      if (this.state.endDate) {
        return (
          <span style={{ fontWeight: 600, letterSpacing: 1.5, marginBottom: window.innerWidth > 1009 && 20 }}>
            Je reservering verloopt over:
          </span>
        );
      }
    }
  }

  checkForTimerButton() {
    let filtered = this.state.guestUserCart.filter((b) => {
      return !b.isNotActive && !b.hideFromListComplete;
    });
    if (filtered.length > 0) {
      if (this.state.endDate) {
        return <button className="timer-cart">{this.getTheTimer()}</button>;
      }
    }
  }

  getCharityObjectedQty(e) {
    let arrItems = e;
    if (arrItems.length > 0) {
      Array.prototype.sum = function (prop) {
        var total = 0;
        for (var i = 0, _len = this.length; i < _len; i++) {
          total += Number(this[i][1][prop]);
        }
        return total;
      };
      return arrItems.sum("used_time");
    }
  }

  getVerzilveredQty(e) {
    let arrItems = e;
    if (arrItems.length > 0) {
      Array.prototype.sum = function (prop) {
        var total = 0;
        for (var i = 0, _len = this.length; i < _len; i++) {
          total += Number(this[i][1][prop].length);
        }
        return total;
      };
      return arrItems.sum("allDates");
    }
  }

  checkForPopupExtend() {
    let filtered = this.state.guestUserCart.filter((b) => {
      return !b.isNotActive && !b.hideFromListComplete;
    });

    if (filtered.length > 0) {
      if (this.state.endDate) {
        const date1 = new Date();
        const date2 = new Date(this.state.endDate * 1000);

        var delta = Math.abs(date2 - date1) / 1000;

        var hours = Math.floor(delta / 3600) % 24;
        delta -= hours * 3600;

        var minutes = Math.floor(delta / 60) % 60;
        delta -= minutes * 60;

        if (minutes < 2) {
          if (this.state.extendOpportunity) {
            this.props.openExtendPopup();
            this.setState({ extendOpportunity: false });
            // return <>
            //     <div className="bg-overlaymini" style={{ backgroundColor: "rgba(0, 0, 0, .9)" }} onClick={() => {
            //         this.props.updateGuestUserCartExtendOpportunity(false)
            //         this.setState({ extendOpportunity: false })
            //     }}>
            //     </div>
            //     <div className="card-to-extend" style={{ position: "fixed" }}>
            //         <CloseIcon onClick={() => {
            //             this.setState({ extendOpportunity: false })
            //             this.props.updateGuestUserCartExtendOpportunity(false)
            //         }} />
            //         <p className="main-title-cte">BASKET</p>
            //         <p className="greetings-cte">Hi</p>
            //         <p className="greetings-cte">je winkelmandje verloopt bijna.</p>
            //         <p className="timer-cte">{this.getTheTimer()}</p>
            //         <p className="bottom-content-cte">Je kan nog 1x verlengen, zodat</p>
            //         <p className="bottom-content-cte">we ander members ook de mogelijkheid</p>
            //         <p className="bottom-content-cte">geven om gebruik te maken van deze actie</p>
            //         <button onClick={() => {
            //             var minutesToAdd = Number(process.env.REACT_APP_CART_TIME);
            //             var currentDate = new Date();
            //             var futureDate = new Date(currentDate.getTime() + minutesToAdd * 60000);

            //             let futureTimeStamp = Math.floor(futureDate.getTime() / 1000)

            //             this.setState({ extendOpportunity: false })
            //             this.props.updateGuestUserCartTime(futureTimeStamp)
            //             this.props.updateGuestUserCartExtendOpportunity(false)
            //         }}>VERLENG</button>
            //     </div>
            // </>
          }
        }
      }
    }
  }

  getCartQtyGuest() {
    let filtered = this.state.guestUserCart.filter((t) => {
      return !t.hideFromListComplete;
    });
    return filtered.length;
  }

  // componentWillUnmount() {
  //   clearInterval(this.interval)
  // }


  showTimeAtProduct(product) {
    if (product.is_bookmarked || product.onlyAvailableViaURL || product.isSpecialProduct || product.availableForCompanyGuest) {
      return;
    }
    let filterMyProducts = this.props.bhodi_products.filter((productInner) => {
      if (productInner.sizesActive) {
        let stocksAvailableSizes = []
        productInner.variations_data.map((e) => {
          if (Number(e.qty) === 0) {
            return false;
          }
          let filteredStockLength = []
          let filteredStockLengthVerzilvered = []
          if (productInner.inCartOf) {
            filteredStockLength = Object.entries(productInner.inCartOf).filter((t) => {
              return t[1].selectedSize === e.size
            })
          }
          if (productInner.verzilvered_by) {
            filteredStockLengthVerzilvered = Object.entries(productInner.verzilvered_by).filter((t) => {
              return t[1].selectedSize === e.size
            })
          }
          if ((filteredStockLength.length + filteredStockLengthVerzilvered.length) < Number(e.qty)) {
            stocksAvailableSizes.push(e)
          }
        })
        if (stocksAvailableSizes.length === 0) {
          return false;
        }
      } else {
        let filteredStockLength = []
        let filteredStockLengthVerzilvered = []
        if (productInner.inCartOf) {
          filteredStockLength = Object.entries(productInner.inCartOf).filter((t) => {
            return !t[1].selectedSize
          })
        }
        if (productInner.verzilvered_by) {
          filteredStockLengthVerzilvered = Object.entries(productInner.verzilvered_by).filter((t) => {
            return !t[1].selectedSize
          })
        }
        if (Number(productInner.qty) <= (filteredStockLength.length + filteredStockLengthVerzilvered.length) || Number(productInner.qty) === 0) {
          return false;
        }
        // if (Number(product.qty) === 0) {
        //     return false;
        // }
      }

      if (product.isSpecialProduct) {
        return !productInner.availableOnTime && productInner.images && productInner.active && productInner.created_by === product.created_by && productInner.isSpecialProduct
      } else {
        return !productInner.availableOnTime && productInner.images && productInner.active && productInner.created_by === product.created_by && !productInner.isSpecialProduct
      }
    })
    // let filterMyProducts = this.props.bhodi_products.filter((productInner) => {
    //     return productInner.created_by === product.created_by
    // })
    let filterCurrentUser = this.props.user.filter((g) => {
      return g.user_id === product.created_by
    })
    if (filterCurrentUser.length > 0 && filterMyProducts.length > 0) {

      let sortedAllProducts = [...filterMyProducts].sort((a, b) => {
        return a.sort_time * 100 - b.sort_time * 100;
      })

      let maxProductsToShow = 4

      if (product.isSpecialProduct) {
        maxProductsToShow = 5
        if (this.props.global_values) {
          if (this.props.global_values.products_count_new) {
            maxProductsToShow = Number(this.props.global_values.products_count_new)
          }
        }
      } else if (filterCurrentUser[0].productsInfo) {
        if (filterCurrentUser[0].productsInfo.maxProductsToShow) {
          maxProductsToShow = Number(filterCurrentUser[0].productsInfo.maxProductsToShow)
        }
      }


      if (filterCurrentUser[0].productsInfo && filterCurrentUser[0].productsInfo.intervalTimer && sortedAllProducts.length < maxProductsToShow) {
        let newerProduct = product

        let dateCreated = new Date()
        let splitted = filterCurrentUser[0].productsInfo.intervalTimer.split(" ")

        if (splitted[1].includes("second")) {
          dateCreated.setSeconds(dateCreated.getSeconds() + Number(splitted[0]))
        } else if (splitted[1].includes("minute")) {
          dateCreated.setMinutes(dateCreated.getMinutes() + Number(splitted[0]))
        } else {
          dateCreated.setHours(dateCreated.getHours() + Number(splitted[0]))
        }

        firebase.database().ref(`bhodi_products/${newerProduct.pushKey}/buttonTimingToShow`).set(dateCreated.toISOString())
      }
    }
  }


  componentDidUpdate(prevProps) {
    if (this.props.guest_user_cart) {
      if (prevProps.guest_user_cart !== this.props.guest_user_cart) {
        if (this.props.guest_user_cart.length > 0) {
          let filteredSecMa = this.props.guest_user_cart.filter(
            (v, i, a) => a.findIndex((t) => t.created_by === v.created_by) === i
          );
          let arrMainSellers = [];
          filteredSecMa.map((y) => {
            arrMainSellers.push(y.created_by);
          });
          // if (this.props.guest_user_cart.length > 0) {
          //     this.interval = setInterval(() => this.setState({ timeCurrent: Date.now() }), 1000);
          // } else {
          //     clearInterval(this.interval)
          // }
          this.setState({ allSellers: arrMainSellers });
          this.setState({
            guestUserCart: this.props.guest_user_cart,
            stopCartQty: false,
          });
        } else {
          if (!this.props.guest_user_cart_time) {
            if (prevProps.guest_user_cart) {
              if (prevProps.guest_user_cart.length > 0) {
                let userid = localStorage.getItem("userid");

                let cookieItem = localStorage.getItem("cookie_item");
                if (userid && !cookieItem) {
                  cookieItem = userid;
                }

                prevProps.guest_user_cart.map((f) => {
                  if (f.item_type === "bhodi_product") {
                    if (this.props.bhodi_products) {
                      let myCurrent = this.props.bhodi_products.filter(
                        (h) => {
                          return h.pushKey === f.pushKey;
                        }
                      );
                      if (myCurrent[0].inCartOf) {
                        let objected = Object.entries(myCurrent[0].inCartOf);
                        if (objected.length > 0) {
                          let filteredCart = objected.filter((y) => {
                            if (f.selectedSizeOfOrder) {
                              return (y[1].cookie === cookieItem && y[1].selectedSize === f.selectedSizeOfOrder);
                            } else {
                              return y[1].cookie === cookieItem;
                            }
                          });
                          if (filteredCart.length > 0) {
                            firebase
                              .database()
                              .ref(
                                "bhodi_products/" +
                                f.pushKey +
                                "/inCartOf/" +
                                filteredCart[0][1].pushKey
                              )
                              .remove();

                            this.showTimeAtProduct(myCurrent[0])
                          }
                        }
                      }
                    }
                  } else if (this.props.global_campaigns) {
                    let myCurrent = this.props.global_campaigns.filter((h) => {
                      return h[1].pushKey === f.pushKey;
                    });
                    if (myCurrent[0][1].inCartOf) {
                      let objected = Object.entries(myCurrent[0][1].inCartOf);
                      if (objected.length > 0) {
                        let filteredCart = objected.filter((y) => {
                          return y[1].cookie === cookieItem;
                        });
                        if (filteredCart.length > 0) {
                          filteredCart.map((cartInner) => {
                            firebase
                              .database()
                              .ref(
                                "bhodi_campaigns/" +
                                f.created_by +
                                "/" +
                                f.pushKey +
                                "/inCartOf/" +
                                cartInner[1].pushKey
                              )
                              .remove();
                          })
                        }
                      }
                    }
                  }
                });
              }
            }
            this.setState({ guestUserCart: [] });
            clearInterval(this.interval);
          }
        }
      }
    }
    if (this.props.guest_user_cart_time) {
      if (this.props.guest_user_cart_time !== prevProps.guest_user_cart_time) {
        this.setState({ endDate: this.props.guest_user_cart_time });
      }
    }
    if (
      this.props.guest_user_cart_extend_opportunity !==
      prevProps.guest_user_cart_extend_opportunity
    ) {

      this.setState({
        extendOpportunity: this.props.guest_user_cart_extend_opportunity,
      });
    }
    if (this.props.global_campaigns !== prevProps.global_campaigns) {
      if (this.props.global_campaigns !== prevProps.global_campaigns) {
        setTimeout(() => {
          let allArrs = this.props.global_campaigns;
          let availableKeysYet = [];
          if (this.state.guestUserCart.length > 0) {
            allArrs.map((y) => {
              if (y[1].takeFromAdmin) {
                let obj = mergeAdminEvent(y[1], this.props.global_campaigns)
                y[1] = Object.assign({}, obj)
              }
              availableKeysYet.push(y[1].pushKey);
              y[1].type = "campaign";
              y[1].currentImageIndex = 0;

              if (y[1].desc) {
                y[1].desc = y[1].desc.substr(0, 200);
                y[1].short_desc = y[1].desc.substr(0, 120);
              }
              y[1].images = [y[1].image];
              y[1].imagesObjected = [
                { image: y[1].image, pushKey: y[1].pushKey },
              ];

              if (y[1].campaignType === "local_event") {
                if (y[1].takeFromAdmin) {
                  let obj = mergeAdminEvent(y[1], this.props.global_campaigns)
                  y[1] = Object.assign({}, obj)
                }
                this.state.guestUserCart.map((e, i) => {
                  if (e.item_type === "bhodi_product") {
                    return false;
                  }
                  if (e.pushKey === y[1].pushKey) {
                    if (!y[1].active) {
                      y[1].isNotActive = true;
                    } else {
                      y[1].isNotActive = false;
                    }
                    if (this.state.guestUserCart[i].hideFromListComplete) {
                      y[1].hideFromListComplete = true;
                    } else {
                      y[1].hideFromListComplete = false;
                    }
                    let obj = {
                      reserved_event_id: e.reserved_event_id,
                      ...y[1]
                    }
                    this.state.guestUserCart[i] = obj;
                  }
                });
              }
              if (y[1].campaignType === "kortings_voucher" || y[1].campaignType === "web_shop") {
                this.state.guestUserCart.map((t, i) => {
                  if (t.item_type === "bhodi_product") {
                    return false;
                  }
                  if (t.pushKey === y[1].pushKey) {
                    if (!y[1].active) {
                      y[1].isNotActive = true;
                    } else {
                      y[1].isNotActive = false;
                    }
                    if (this.state.guestUserCart[i].hideFromListComplete) {
                      y[1].hideFromListComplete = true;
                    } else {
                      y[1].hideFromListComplete = false;
                    }
                    this.state.guestUserCart[i] = y[1];
                  }
                });
              }
            });
            this.state.guestUserCart.map((t) => {
              if (t.item_type === "bhodi_product") {
                return false;
              }
              if (!availableKeysYet.includes(t.pushKey)) {
                t.isNotActive = true;
              }
            });
            this.props.updateGuestUserCart(this.state.guestUserCart);
          }
        }, 200);
      }
    }
  }

  getDayName(num) {
    if (num === 0) {
      return `Zondag`;
    } else if (num === 1) {
      return `Maandag`;
    } else if (num === 2) {
      return `Dinsdag`;
    } else if (num === 3) {
      return `Woensdag`;
    } else if (num === 4) {
      return `Donderdag`;
    } else if (num === 5) {
      return `Vrijdag`;
    } else if (num === 6) {
      return `Zaterdag`;
    }
  }

  getMonthName(i) {
    i = Number(i);
    if (i === 1) {
      return "Januari";
    } else if (i === 2) {
      return "Februari";
    } else if (i === 3) {
      return "Maart";
    } else if (i === 4) {
      return "April";
    } else if (i === 5) {
      return "Mei";
    } else if (i === 6) {
      return "Juni";
    } else if (i === 7) {
      return "Juli";
    } else if (i === 8) {
      return "Augustus";
    } else if (i === 9) {
      return "September";
    } else if (i === 10) {
      return "Oktober";
    } else if (i === 11) {
      return "November";
    } else if (i === 12) {
      return "December";
    }
  }

  showDateToUserTiming(e, f) {
    if (e === f) {
      let splitted = e.split("-");
      let newDate = new Date(e).getDay();
      return `${this.getDayName(newDate)} ${splitted[2]} ${this.getMonthName(
        splitted[1]
      )}`;
    } else {
      let splitted = e.split("-");
      let splittedSecond = f.split("-");
      let newDate = new Date(e).getDay();
      let newDateAnother = new Date(f).getDay();
      if (splitted[1] === splittedSecond[1]) {
        return `${this.getDayName(newDate)} ${splitted[2]} - ${splittedSecond[2]
          } ${this.getMonthName(splittedSecond[1])}`;
      } else {
        // return `${this.getDayName(newDate)} ${splitted[2]} ${this.getMonthName(splitted[1])} - ${this.getDayName(newDateAnother)} ${splittedSecond[2]} ${this.getMonthName(splittedSecond[1])}`
        return `${splitted[2]} ${this.getMonthName(splitted[1])} t/m ${splittedSecond[2]
          } ${this.getMonthName(splittedSecond[1])}`;
      }
    }
  }

  // componentWillUnmount() {
  //   clearInterval(this.interval)
  // }

  render() {
    return (
      !this.props.notOnMainPage && (
        <div>
          <div className="centered">
            {(this.state.guestUserCart.length > 0 && this.state.guestUserCart[0].item_type === "bhodi_product") ? (<h3>WINKELMAND</h3>) : (<h3>AANMELDEN</h3>)}
            {this.state.stopCartQty ? (
              <span style={{ marginBottom: 5 }}>0 producten</span>
            ) : (
              <span style={{ marginBottom: 5 }}>
                {this.getCartQtyEvents()}  {this.state.guestUserCart.length > 1 ? "Items" : "Item"}
              </span>
            )}
            {this.checkForTimerHeading()}
            {this.checkForTimerButton()}
            {/* <span>{this.state.guestUserCart.length} events</span> */}
          </div>
          {this.checkForPopupExtend()}
        </div>
      )
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducers.user,
    product: state.campaignsReducer.product,
    shopproducts: state.campaignsReducer.shopproducts,
    allVouchers: state.campaignsReducer.allVouchers,
    globalsectors: state.categoriesReducer.globalsectors,
    global_campaigns: state.campaignsReducer.global_campaigns,
    guest_user_cart: state.cartReducer.guest_user_cart,
    guest_user_cart_time: state.cartReducer.guest_user_cart_time,
    guest_user_cart_extend_opportunity: state.cartReducer.guest_user_cart_extend_opportunity,
    guest_db_cart: state.cartReducer.guest_db_cart,
    bhodi_products: state.productsReducers.bhodi_products,
    global_values: state.globalReducers.global_values,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateGuestUserCart: (guest_user_cart) =>
      dispatch(updateGuestUserCart(guest_user_cart)),
    updateGuestUserCartTime: (guest_user_cart_time) =>
      dispatch(updateGuestUserCartTime(guest_user_cart_time)),
    updateGuestUserCartExtendOpportunity: (
      guest_user_cart_extend_opportunity
    ) =>
      dispatch(
        updateGuestUserCartExtendOpportunity(guest_user_cart_extend_opportunity)
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FooterBasketTimer);
