import React, { Component } from "react";
import firebase from "../../Config/Firebase";
import { connect } from "react-redux";
import { updateGlobalStuff } from "../../Redux/actions/authActions";
import CK from "../Ck";
import styles from "./index.module.css"
import swal from "sweetalert";

export class PopupSpecial extends Component {
    constructor(props) {
        super(props);

        this.state = {

            showStuffNow: false,

            main_text_dutch: "",
            main_text_english: "",
            main_text_dutch_mobile: "",
            main_text_english_mobile: "",
            title_text_dutch: "",
            title_text_english: "",
            page_url: "",


            bannerImageFile: [],
            logoImageFile: [],
            logoImageFileMobile: [],
            playAnnouncementLogoImage: [],
            playAnnouncementLogoImageMobile: [],
            playAnnouncementImage: [],
            playAnnouncementImageMobile: [],
        };
    }

    componentDidMount() {
        const loaderId = document.getElementById("main-page-loader-id");
        loaderId.style.display = "none";

        setTimeout(() => {
            this.setState({ showStuffNow: true });
        }, 100);

        if (this.props.pageData) {
            let a = this.props.pageData;
            if (a) {
                this.setState({
                    main_text_dutch: a.main_text_dutch || "",
                    main_text_english: a.main_text_english || "",
                    main_text_dutch_mobile: a.main_text_dutch_mobile || "",
                    main_text_english_mobile: a.main_text_english_mobile || "",
                    title_text_dutch: a.title_text_dutch || "",
                    title_text_english: a.title_text_english || "",
                    page_url: a.page_url || "",
                });
            }
        }
    }

    saveValueToState(storeAt, e) {
        this.setState({ [storeAt]: e });
        // firebase.database().ref(`bhodi_home/${storeAt}`).set(e)
    }

    fileUploadToDB(e, storeAt) {
        // let files = e.target.files[0]
        // let storageRef = firebase.storage().ref().child(`bhodi_home_media/${files.name}${new Date().getTime()}`)
        // storageRef.put(files)
        //     .then((snapshot) => {
        //         snapshot.ref.getDownloadURL().then((snapUrl) => {
        //             firebase.database().ref(`bhodi_home/${storeAt}/`).set(snapUrl).then(() => {
        //             })
        //         })
        //     })
        this.setState({ [storeAt]: [e.target.files[0]] });
    }

    checkForAvailableFile(file, alertValue) {
        if (file.length === 0) {
            this.setState({ [alertValue]: true })
        } else {
            this.setState({ [alertValue]: false })
        }
    }
    checkForText(stateName, alertValue) {
        if (stateName) {
            this.setState({ [alertValue]: false })
        } else {
            this.setState({ [alertValue]: true })
        }
    }

    uploaderFunction(fileArray) {
        let files = fileArray[0]
        let storageRef = firebase.storage().ref().child(`bhodi_brands_media/${files.name}${new Date().getTime()}`)
        return storageRef.put(files)
            .then((snapshot) => {
                return snapshot.ref.getDownloadURL().then((snapUrl) => {
                    return snapUrl
                })
            })
    }

    async processObjectAndData() {
        const { bannerImageFile, logoImageFile, logoImageFileMobile, playAnnouncementImage, playAnnouncementImageMobile, playAnnouncementLogoImage, playAnnouncementLogoImageMobile, main_text_dutch, main_text_english, main_text_english_mobile, main_text_dutch_mobile, title_text_dutch, title_text_english, page_url } = this.state
        // let fileUpload = await this.uploaderFunction(bannerImageFile)
        // console.log(fileUpload)

        this.checkForText(main_text_dutch, "main_text_dutch_not_present")
        this.checkForText(main_text_dutch_mobile, "main_text_dutch_mobile_not_present")
        this.checkForText(main_text_english, "main_text_english_not_present")
        this.checkForText(main_text_english_mobile, "main_text_english_mobile_not_present")
        this.checkForText(title_text_dutch, "title_text_dutch_not_present")
        this.checkForText(title_text_english, "title_text_english_not_present")
        this.checkForText(page_url, "page_url_not_present")

        if (this.props.type === "add-page") {
            this.checkForAvailableFile(bannerImageFile, "bannerImageFileNotPresent")
            this.checkForAvailableFile(logoImageFile, "logoImageFileNotPresent")
            this.checkForAvailableFile(logoImageFileMobile, "logoImageFileMobileNotPresent")
            this.checkForAvailableFile(playAnnouncementImage, "playAnnouncementImageNotPresent")
            this.checkForAvailableFile(playAnnouncementImageMobile, "playAnnouncementImageMobileNotPresent")
            this.checkForAvailableFile(playAnnouncementLogoImage, "playAnnouncementLogoImageNotPresent")
            this.checkForAvailableFile(playAnnouncementLogoImageMobile, "playAnnouncementLogoImageMobileNotPresent")


            if (bannerImageFile.length === 0 || logoImageFile.length === 0 || logoImageFileMobile.length === 0 || playAnnouncementImage.length === 0 || playAnnouncementImageMobile.length === 0 || playAnnouncementLogoImage.length === 0 || playAnnouncementLogoImageMobile.length === 0) {
                return false
            }
        }

        if (!main_text_dutch || !main_text_dutch_mobile || !main_text_english || !main_text_english_mobile || !title_text_dutch || !title_text_english || !page_url) {
            return false
        }

        let obj = {
            main_text_dutch,
            main_text_english,
            main_text_dutch_mobile,
            main_text_english_mobile,
            title_text_dutch,
            title_text_english,
            page_url,
        }

        let promiseUploadingBanner = new Promise((res, rej) => {
            if (bannerImageFile.length > 0) {
                let result = this.uploaderFunction(bannerImageFile)
                res(result)
            } else {
                res("N/A")
            }
        })
        let promiseUploadingLogo = new Promise((res, rej) => {
            if (logoImageFile.length > 0) {
                let result = this.uploaderFunction(logoImageFile)
                res(result)
            } else {
                res("N/A")
            }
        })
        let promiseUploadingLogoMobile = new Promise((res, rej) => {
            if (logoImageFileMobile.length > 0) {
                let result = this.uploaderFunction(logoImageFileMobile)
                res(result)
            } else {
                res("N/A")
            }
        })
        let promiseUploadingAnnouncement = new Promise((res, rej) => {
            if (playAnnouncementImage.length > 0) {
                let result = this.uploaderFunction(playAnnouncementImage)
                res(result)
            } else {
                res("N/A")
            }
        })
        let promiseUploadingAnnouncementMobile = new Promise((res, rej) => {
            if (playAnnouncementImageMobile.length > 0) {
                let result = this.uploaderFunction(playAnnouncementImageMobile)
                res(result)
            } else {
                res("N/A")
            }
        })
        let promiseUploadingAnnouncementLogo = new Promise((res, rej) => {
            if (playAnnouncementLogoImage.length > 0) {
                let result = this.uploaderFunction(playAnnouncementLogoImage)
                res(result)
            } else {
                res("N/A")
            }
        })
        let promiseUploadingAnnouncementLogoMobile = new Promise((res, rej) => {
            if (playAnnouncementLogoImageMobile.length > 0) {
                let result = this.uploaderFunction(playAnnouncementLogoImageMobile)
                res(result)
            } else {
                res("N/A")
            }
        })
        Promise.all([promiseUploadingBanner, promiseUploadingLogo, promiseUploadingLogoMobile, promiseUploadingAnnouncement, promiseUploadingAnnouncementMobile, promiseUploadingAnnouncementLogo, promiseUploadingAnnouncementLogoMobile]).then((results) => {
            let bannerImage = results[0]
            let logoImage = results[1]
            let logoImageMobile = results[2]
            let announcementImage = results[3]
            let announcementImageMobile = results[4]
            let announcementImageLogo = results[5]
            let announcementImageLogoMobile = results[6]

            let banner_image = ""
            let top_logo = ""
            let top_logo_mobile = ""
            let play_icon = ""
            let play_icon_mobile = ""
            let play_picture = ""
            let play_picture_mobile = ""

            if (bannerImage !== "N/A") {
                banner_image = bannerImage
            }
            if (logoImage !== "N/A") {
                top_logo = logoImage
            }
            if (logoImageMobile !== "N/A") {
                top_logo_mobile = logoImageMobile
            }
            if (announcementImageLogo !== "N/A") {
                play_icon = announcementImageLogo
            }
            if (announcementImageLogoMobile !== "N/A") {
                play_icon_mobile = announcementImageLogoMobile
            }
            if (announcementImage !== "N/A") {
                play_picture = announcementImage
            }
            if (announcementImageMobile !== "N/A") {
                play_picture_mobile = announcementImageMobile
            }

            if (banner_image) {
                obj.banner_image = banner_image
            }
            if (top_logo) {
                obj.top_logo = top_logo
            }
            if (top_logo_mobile) {
                obj.top_logo_mobile = top_logo_mobile
            }
            if (play_icon) {
                obj.play_icon = play_icon
            }
            if (play_icon_mobile) {
                obj.play_icon_mobile = play_icon_mobile
            }
            if (play_picture) {
                obj.play_picture = play_picture
            }
            if (play_picture_mobile) {
                obj.play_picture_mobile = play_picture_mobile
            }

            let pushKey = ""
            if (this.props.pageKey) {
                pushKey = this.props.pageKey
            } else {
                pushKey = firebase.database().ref("bhodi_brands").push().key
            }
            obj.pushKey = pushKey

            let objToSend = ""

            if (this.props.pageData) {
                objToSend = {
                    ...this.props.pageData,
                    ...obj,
                }
            } else {
                objToSend = obj
            }

            firebase.database().ref("bhodi_brands/" + pushKey).set(objToSend).then(() => {
                swal({
                    title: "Cool",
                    text: this.props.type === "edit-page" ? "Page edited successfully" : "Page added successfully",
                    icon: "success",
                }).then(() => {
                    this.props.onClose()
                })
            })
        })
    }


    render() {
        return (
            <div className={styles.popupEditorMainPage}>
                <div className="buttons-container-displayeditor" style={{ maxWidth: "none", margin: 0, display: "flex", justifyContent: "flex-end" }}>
                    <button
                        className="buttonsgreendisplay"
                        onClick={() => {
                            this.props.onClose()
                        }}
                    >
                        Close
                    </button>
                    <button
                        className="buttonsgreendisplay"
                        onClick={() => {
                            this.processObjectAndData()
                        }}
                        style={{ marginLeft: 10 }}
                    >
                        Save
                    </button>
                </div>
                {this.state.showStuffNow && (<div className={styles.mainContainerParent}>
                    <div className={styles.innerBoxes}>
                        <label style={{ color: this.state.main_text_dutch_not_present && "red" }}>Main Text Dutch</label>
                        <CK data={this.state.main_text_dutch} onChildChange={this.saveValueToState.bind(this, "main_text_dutch")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label style={{ color: this.state.main_text_dutch_mobile_not_present && "red" }}>Main Text Dutch Mobile</label>
                        <CK data={this.state.main_text_dutch_mobile} onChildChange={this.saveValueToState.bind(this, "main_text_dutch_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label style={{ color: this.state.main_text_english_not_present && "red" }}>Main Text English</label>
                        <CK data={this.state.main_text_english} onChildChange={this.saveValueToState.bind(this, "main_text_english")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label style={{ color: this.state.main_text_english_mobile_not_present && "red" }}>Main Text English Mobile</label>
                        <CK data={this.state.main_text_english_mobile} onChildChange={this.saveValueToState.bind(this, "main_text_english_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label style={{ color: this.state.title_text_dutch_not_present && "red" }}>Title Dutch</label>
                        <CK data={this.state.title_text_dutch} onChildChange={this.saveValueToState.bind(this, "title_text_dutch")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label style={{ color: this.state.title_text_english_not_present && "red" }}>Title English</label>
                        <CK data={this.state.title_text_english} onChildChange={this.saveValueToState.bind(this, "title_text_english")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label style={{ color: this.state.page_url_not_present && "red" }}>Page URL</label>
                        <input type="text" defaultValue={this.state.page_url} onBlur={(e) => {
                            this.saveValueToState("page_url", e.target.value)
                        }} />
                        {/* <CK data={this.state.page_url} onChildChange={this.saveValueToState.bind(this, "page_url")} /> */}
                    </div>
                    <div className={styles.innerBoxes} style={{ backgroundColor: "transparent" }}>
                    </div>
                    <div className={styles.innerBoxes}>
                        <label style={{ color: this.state.bannerImageFileNotPresent && "red" }}>Banner Image</label>
                        <input
                            type="file"
                            style={{ padding: 15, border: "1px solid gray" }}
                            accept="image/*"
                            onChange={(e) => this.fileUploadToDB(e, "bannerImageFile")}
                        />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label style={{ color: this.state.logoImageFileNotPresent && "red" }}>Logo Image</label>
                        <input
                            type="file"
                            style={{ padding: 15, border: "1px solid gray" }}
                            accept="image/*"
                            onChange={(e) => this.fileUploadToDB(e, "logoImageFile")}
                        />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label style={{ color: this.state.logoImageFileMobileNotPresent && "red" }}>Logo Image Mobile</label>
                        <input
                            type="file"
                            style={{ padding: 15, border: "1px solid gray" }}
                            accept="image/*"
                            onChange={(e) => this.fileUploadToDB(e, "logoImageFileMobile")}
                        />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label style={{ color: this.state.playAnnouncementLogoImageNotPresent && "red" }}>Play Announcement Logo Image</label>
                        <input
                            type="file"
                            style={{ padding: 15, border: "1px solid gray" }}
                            accept="image/*"
                            onChange={(e) => this.fileUploadToDB(e, "playAnnouncementLogoImage")}
                        />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label style={{ color: this.state.playAnnouncementLogoImageMobileNotPresent && "red" }}>Play Announcement Logo Image Mobile</label>
                        <input
                            type="file"
                            style={{ padding: 15, border: "1px solid gray" }}
                            accept="image/*"
                            onChange={(e) => this.fileUploadToDB(e, "playAnnouncementLogoImageMobile")}
                        />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label style={{ color: this.state.playAnnouncementImageNotPresent && "red" }}>Play Announcement Image</label>
                        <input
                            type="file"
                            style={{ padding: 15, border: "1px solid gray" }}
                            accept="image/*"
                            onChange={(e) => this.fileUploadToDB(e, "playAnnouncementImage")}
                        />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label style={{ color: this.state.playAnnouncementImageMobileNotPresent && "red" }}>Play Announcement Image Mobile</label>
                        <input
                            type="file"
                            style={{ padding: 15, border: "1px solid gray" }}
                            accept="image/*"
                            onChange={(e) => this.fileUploadToDB(e, "playAnnouncementImageMobile")}
                        />
                    </div>
                </div>)}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        global_values: state.globalReducers.global_values,
        user: state.authReducers.user,
        admin_boolean: state.globalReducers.admin_boolean,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateGlobalStuff: (global_values) =>
            dispatch(updateGlobalStuff(global_values)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PopupSpecial);
