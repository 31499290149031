import { combineReducers } from "redux";
import authReducers from "./reducers/authReducers";
import campaignsReducer from "./reducers/campaignsReducer";
import cartReducer from "./reducers/cartReducer";
import categoriesReducer from "./reducers/categoriesReducer";
import exclusiveReducers from "./reducers/exclusiveReducers";
import globalReducers from "./reducers/globalReducers";
import imagesReducer from "./reducers/imagesReducer";
import postsReducers from "./reducers/postsReducers";
import renderReducer from "./renderReducer";
import productsReducers from "./reducers/productsReducers";

export default combineReducers({
  authReducers,
  campaignsReducer,
  cartReducer,
  categoriesReducer,
  exclusiveReducers,
  globalReducers,
  imagesReducer,
  postsReducers,
  renderReducer,
  productsReducers,
});

