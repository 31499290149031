import localforage from 'localforage';

let maincategoriesvoucher = ""
let subcategoriesvoucher = ""
let globalsectors = ""
let campaigns_category = ""
let categories_vouchers = ""
let album_categories = ""
let categories_albums = ""
let categories_albums_available = ""
let available_posts = ""

localforage.getItem("persist:root").then((value) => {
    if (value) {
        let converted = JSON.parse(value)
        if (converted.categoriesReducer) {
            let parsed = JSON.parse(converted.categoriesReducer)
            if (parsed.maincategoriesvoucher) {
                maincategoriesvoucher = parsed.maincategoriesvoucher
            }
            if (parsed.subcategoriesvoucher) {
                subcategoriesvoucher = parsed.subcategoriesvoucher
            }
            if (parsed.globalsectors) {
                globalsectors = parsed.globalsectors
            }
            if (parsed.campaigns_category) {
                campaigns_category = parsed.campaigns_category
            }
            if (parsed.categories_vouchers) {
                categories_vouchers = parsed.categories_vouchers
            }
            if (parsed.album_categories) {
                album_categories = parsed.album_categories
            }
            if (parsed.categories_albums) {
                categories_albums = parsed.categories_albums
            }
            if (parsed.categories_albums_available) {
                categories_albums_available = parsed.categories_albums_available
            }
            if (parsed.available_posts) {
                available_posts = parsed.available_posts
            }
        }
    }
})

const initial_state = {
    maincategoriesvoucher: maincategoriesvoucher ? maincategoriesvoucher : null,
    subcategoriesvoucher: subcategoriesvoucher ? subcategoriesvoucher : null,
    // shopsectors: null,
    // maincategoriesshop: null,
    // subcategoriesshop: null,
    globalsectors: globalsectors ? globalsectors : null,
    campaigns_category: campaigns_category ? campaigns_category : null,
    album_categories: album_categories ? album_categories : null,
    categories_albums: categories_albums ? categories_albums : null,
    categories_albums_available: categories_albums_available ? categories_albums_available : null,
    available_posts: available_posts ? available_posts : null,
}

const reducer = (state = initial_state, action) => {
    switch (action.type) {
        case "update_main_category": {
            return { ...state, maincategoriesvoucher: action.maincategoriesvoucher }
        }
        case "update_sub_category": {
            return { ...state, subcategoriesvoucher: action.subcategoriesvoucher }
        }
        // case "all_shop_sc": {
        //     return { ...state, shopsectors: action.shopsectors }
        // }
        // case "update_main_category_shop": {
        //     return { ...state, maincategoriesshop: action.maincategoriesshop }
        // }
        // case "update_sub_category_shop": {
        //     return { ...state, subcategoriesshop: action.subcategoriesshop }
        // }
        case "update_all_sectors": {
            return { ...state, globalsectors: action.globalsectors }
        }
        case "update_campaigns_category": {
            return { ...state, campaigns_category: action.campaigns_category }
        }
        case "update_categories_vouchers": {
            return { ...state, categories_vouchers: action.categories_vouchers }
        }
        case "update_album_categories": {
            return { ...state, album_categories: action.album_categories }
        }
        case "update_category_albums": {
            return { ...state, categories_albums: action.categories_albums }
        }
        case "update_category_albums_available": {
            return { ...state, categories_albums_available: action.categories_albums_available }
        }
        case "update_available_posts": {
            return { ...state, available_posts: action.available_posts }
        }
        default: {
            return state;
        }
    }
}

export default reducer