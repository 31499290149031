import React, { Component } from "react";
import { MdTimer } from "react-icons/md";

export class ButtonTimerProducts extends Component {
    componentDidMount() {
        const loaderId = document.getElementById("main-page-loader-id");
        if (loaderId) {
            loaderId.style.display = "none";
        }
        this.interval = setInterval(
            () => this.setState({ timeCurrent: Date.now() }),
            1000
        );
    }


    getMonthName(i) {
        i = Number(i);
        if (i === 1) {
            return "januari";
        } else if (i === 2) {
            return "februari";
        } else if (i === 3) {
            return "maart";
        } else if (i === 4) {
            return "april";
        } else if (i === 5) {
            return "Mei";
        } else if (i === 6) {
            return "juni";
        } else if (i === 7) {
            return "juli";
        } else if (i === 8) {
            return "augustus";
        } else if (i === 9) {
            return "september";
        } else if (i === 10) {
            return "oktober";
        } else if (i === 11) {
            return "november";
        } else if (i === 12) {
            return "december";
        }
    }

    calculateTimeStamp() {
        var date = new Date(this.props.timeToCalculate);

        let dateFuture = date.getTime();

        let dateNow = new Date().getTime();

        let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

        let days = Math.floor(diffInMilliSeconds / 86400);
        let daysNumber = Math.floor(diffInMilliSeconds / 86400);
        diffInMilliSeconds -= days * 86400;

        let hours = Math.floor(diffInMilliSeconds / 3600) % 24;
        diffInMilliSeconds -= hours * 3600;

        let minutes = Math.floor(diffInMilliSeconds / 60) % 60;
        diffInMilliSeconds -= minutes * 60;

        let seconds = diffInMilliSeconds % 60;

        let difference = "";
        if (days > 0) {
            difference += days === 1 ? `${days}:` : `${days}:`;
        }

        if (days < 10) {
            days = `0${days}`;
        } else {
            days = `${days}`;
        }

        if (hours < 10) {
            hours = `0${hours}`;
        } else {
            hours = `${hours}`;
        }

        if (minutes < 10) {
            minutes = `0${minutes}`;
        } else {
            minutes = `${minutes}`;
        }

        if (seconds < 9) {
            seconds = `0${Math.ceil(seconds)}`;
        } else {
            seconds = `${Math.ceil(seconds)}`;
        }

        if (dateFuture > dateNow) {
            return (
                this.props.onMobileCampaign ? <div className="icon-riimage">
                    {Number(hours) > 0 ? <span>{hours} u </span> : Number(minutes) > 0 ? <span>&nbsp;{minutes} m</span> : <span>&nbsp;{seconds} s</span>}
                </div> : <button className={`mainbtntimer`}>
                    <MdTimer />
                    {Number(hours) > 0 && <span>{hours} u </span>}
                    {Number(minutes) > 0 && <span>&nbsp;{minutes} min</span>}
                    <span>&nbsp;{seconds} sec</span>
                    {/* {Number(hours) > 0 ? <span>{hours} u </span> : Number(minutes) > 0 ? <span>{minutes} min </span> : <span>{seconds} sec</span>} */}
                </button>
            );
        } else {
            if (this.props.closeScreen) {
                this.props.closeScreen()
            }
            clearInterval(this.interval);
            return <></>;
        }
    }

    render() {
        return this.calculateTimeStamp();
    }
}

export default ButtonTimerProducts;
