import React, { Component } from 'react'
import "./index.css"
import { connect } from 'react-redux';
import firebase from "../../Config/Firebase"
import {
    updateAdminOrNot,
    updateGlobalStuff,
    updateAlbumToAdd,
} from "../../Redux/actions/authActions";
import Footer from '../../Components/Footer';
import FooterGreen from '../../Components/FooterGreen';
import logo from "../../assets/logoheaderlifestyle.png"
import playiconImage from "../../assets/playiconlogomobile.png"
import axios from 'axios';
import TimerOnboardingBarberClient from '../../Components/TimerOnboardingBarberClient';
import { followUnfollowCompany } from '../../Functions/friendFunctions';

const bcrypt = require("bcryptjs");
const saltRounds = 10;

export class BarberClientOnboarding extends Component {
    constructor() {
        super()
        this.state = {
            secondStep: false,
            aanmeldenloader: false,

            fullName: "",
            fullNameEmpty: false,
            email: "",
            emailEmpty: false,

            showMainLoader: false,
            thirdStep: false,
            urlRedirect: "",

            allUsers: [],
            currentUserSpecific: "",
            customerId: "",
            barber_id: "",

            maxTime: "",
            isAlreadyJoined: false,
            maxTimePast: false,
            windowInnerHeight: "",
            barberData: "",
        }
    }

    componentDidMount() {

        let windowInnerHeight = window.innerHeight
        this.setState({ windowInnerHeight })

        let urlMain = new URLSearchParams(window.location.search)

        if (urlMain.get("id") && urlMain.get("barber_id")) {
            this.setState({ showMainLoader: true })

            let barber_name_id = urlMain.get("barber_id")
            let customerId = urlMain.get("id")

            firebase
                .database()
                .ref("users")
                .orderByChild("user_name_id")
                .equalTo(barber_name_id)
                .once("child_added", (data) => {
                    if (data.val()) {
                        let barberData = data.val()
                        this.setState({ barberData })

                        firebase.database().ref("users/" + barberData.user_id).on("value", (data) => {
                            let barberDataOn = data.val()
                            if (barberDataOn) {
                                this.setState({ barberData: barberDataOn })
                            }
                        })

                        if (this.state.showMainLoader) {
                            firebase.database().ref(`qr_codes/${barberData.user_id}/${customerId}`).once("value", (dataInner) => {
                                let qrData = dataInner.val()
                                if (qrData) {
                                    let maxTime = new Date(Date.parse(qrData.created_at))
                                    maxTime.setHours(maxTime.getHours() + 24)
                                    localStorage.setItem("max_time_onboarding_barber_client", JSON.stringify(maxTime.getTime()))

                                    if (maxTime.getTime() > new Date().getTime() && !qrData.is_joined) {
                                        // firebase.database().ref(`qr_codes/${barberData.user_id}/${customerId}/is_scanned`).set(true)
                                        this.setState({ showMainLoader: false, barber_id: barberData.user_id, customerId, maxTime })
                                    } else if (qrData.is_joined) {
                                        this.setState({ showMainLoader: false, isAlreadyJoined: true })
                                    } else {
                                        this.setState({ showMainLoader: false, maxTimePast: true })
                                    }
                                }
                            })
                        }
                    }
                });
        }


        firebase
            .database()
            .ref("users")
            .once("value", (data) => {
                let a = data.val();
                let arr = [];
                if (a && !this.state.stopTakingData) {
                    let objected = Object.entries(a);
                    objected.map((u) => {
                        arr.push(u[1]);
                    });
                    let usersWithNumbers = [];
                    arr.map((t) => {
                        if (t.user_id_numbers) {
                            usersWithNumbers.push(t);
                        }
                    });
                    let sorted = usersWithNumbers.sort((a, b) => {
                        if (a.user_id_numbers && b.user_id_numbers) {
                            return a.user_id_numbers - b.user_id_numbers;
                        }
                    });
                    this.setState({
                        allUsers: arr,
                        currentUserSpecific: Number(sorted[sorted.length - 1].user_id_numbers) + 1,
                    });
                } else {
                    this.setState({ allUsers: [] });
                }
            });

    }

    checkForValuePresent(param) {
        if (param !== "") {
            return true;
        } else {
            return false;
        }
    }

    updateStateOnCondition(param, paramToSet) {
        if (param !== "") {
            this.setState({
                [paramToSet]: false,
            })
        } else {
            this.setState({
                [paramToSet]: true,
            })
        }
    }

    randomStr(e) {
        let len = e
        let arr = "abcdefghijklmnopqrstuvwxyz1928102489"
        var ans = '';
        for (var i = len; i > 0; i--) {
            ans +=
                arr[Math.floor(Math.random() * arr.length)];
        }
        return ans;
    }

    moveFurther() {
        const { fullName, email } = this.state

        this.updateStateOnCondition(fullName, "fullNameEmpty")
        this.updateStateOnCondition(email, "emailEmpty")

        if (
            this.checkForValuePresent(fullName) &&
            this.checkForValuePresent(email)
        ) {
            this.setState({ aanmeldenloader: true })
            let password = this.randomStr(8)

            let userFound = this.state.allUsers.find((usr) => {
                return usr.email === email
            })
            if (userFound) {
                let today = new Date();
                let todayOneMonth = new Date();

                let oneMonthlater = todayOneMonth.setMonth(todayOneMonth.getMonth() + 1)

                firebase.database().ref("users/" + userFound.user_id + "/subscription_started").set(today.toISOString())
                firebase.database().ref("users/" + userFound.user_id + "/subscription_validity").set(new Date(oneMonthlater).toISOString())

                var dataForAPI = JSON.stringify({
                    sendToEmail: email,
                    subject: "Account Created at Bhodi!",
                    innerHTMLpart: `<!DOCTYPE html><html><head><title>Page Title</title></head><body><h2>WELCOME!</h2><p>Your account is already registered at Bhodi, thanks for doing charity, you are charity member for next 1 month onwards now.</p></body></html>`,
                });

                var config = {
                    method: "post",
                    url: "https://us-central1-bhodi-production-da765.cloudfunctions.net/sendEmailMailjet",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    data: dataForAPI,
                };

                axios(config)
                    .then((response) => {
                        if (response.data.statusCode === 201) {
                            if (userFound.type === "company") {
                                this.setState({ aanmeldenloader: false, secondStep: true, urlRedirect: `${window.location.origin}/company/${userFound.user_name_id}` })
                            } else {
                                this.setState({ aanmeldenloader: false, secondStep: true, urlRedirect: `${window.location.origin}/member/${userFound.user_name_id}` })
                            }
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });

                return;
            }

            const toCalculateFrom = fullName.trim().split(' ');
            const firstName = toCalculateFrom[0];
            const lastName = toCalculateFrom.slice(1).join(' ') || ""

            let jointName = fullName;

            let filteredForJoin = this.state.allUsers.filter((g) => {
                return g.user_name_id === jointName.toLowerCase();
            });

            if (filteredForJoin.length > 0) {
                let randomStr = Math.ceil(Math.random() * (20000 - 0) + 0);
                jointName = toCalculateFrom + randomStr;

                let filteredForJoinSecond = this.state.allUsers.filter((g) => {
                    return g.user_name_id === jointName.toLowerCase();
                });
                if (filteredForJoinSecond.length > 0) {
                    let randomStr = Math.ceil(Math.random() * (20000 - 0) + 0);
                    jointName = toCalculateFrom + randomStr;

                    let filteredForJoinThird = this.state.allUsers.filter((g) => {
                        return g.user_name_id === jointName.toLowerCase();
                    });
                    if (filteredForJoinThird.length > 0) {
                        let randomStr = Math.ceil(Math.random() * (20000 - 0) + 0);
                        jointName = toCalculateFrom + randomStr;

                        let filteredForJoinFourth = this.state.allUsers.filter(
                            (g) => {
                                return g.user_name_id === jointName.toLowerCase();
                            }
                        );
                        if (filteredForJoinFourth.length > 0) {
                            let randomStr = Math.ceil(Math.random() * (20000 - 0) + 0);
                            jointName = toCalculateFrom + randomStr;
                        }
                    }
                }
            }

            if (jointName.includes(" ")) {
                jointName = jointName.replace(/ /g, "_");
            }

            let today = new Date();
            let todayOneMonth = new Date();
            let date = today.getDate() + "-" + (today.getMonth() + 1) + "-" + today.getFullYear();

            let oneMonthlater = todayOneMonth.setMonth(todayOneMonth.getMonth() + 1)

            let obj = {
                first_name: firstName,
                last_name: lastName,
                email: email,
                password: "",
                // pass: myPlaintextPassword,
                country: "Netherlands",
                passwordChanged: false,
                user_id: "",
                type: "user",
                user_name_id: jointName.toLowerCase(),
                created_on: date,
                newly_registered: false,
                user_id_numbers: this.state.currentUserSpecific,
                accountType: "global",
                street: "",
                city: "",
                zipCode: "",
                phoneNumber: "",
                houseNumber: "",
                profileActive: true,
                hideWizard: true,
                joined_via_barber: this.state.barber_id,
                barber_client_id: this.state.customerId,
                subscription_validity: new Date(oneMonthlater).toISOString(),
                subscription_started: today.toISOString(),
            }

            bcrypt.genSalt(saltRounds, (err, salt) => {
                bcrypt.hash(password, salt, (err, hash) => {
                    firebase.auth().createUserWithEmailAndPassword(email, password)
                        .then((e) => {
                            let userId = e.user.uid;

                            obj.user_id = userId
                            obj.password = hash

                            firebase.database().ref(`qr_codes/${obj.joined_via_barber}/${obj.barber_client_id}/joined_user_id`).set(userId)
                            firebase.database().ref(`qr_codes/${obj.joined_via_barber}/${obj.barber_client_id}/is_joined`).set(true)
                            firebase.database().ref("users/" + userId).set(obj).then(() => {
                                localStorage.setItem("userid", userId);
                                localStorage.removeItem("cookie_item");
                                localStorage.setItem("isAdmin", false);
                                this.props.updateAdminOrNot(false);

                                followUnfollowCompany(this.state.barberData, userId, this.props.user)
                                localStorage.removeItem("max_time_onboarding_barber_client")

                                var dataForAPI = {
                                    sendToEmail: email,
                                    onboardingUrl: `${window.location.origin}/onboard-users?email=${email}&key=${password}`,
                                    name: fullName,
                                    // sendToEmail: "fahimmemon826@gmail.com",
                                    // subject: "Account Created at Bhodi!",
                                    // innerHTMLpart: `<!DOCTYPE html><html><head><title>Page Title</title></head><body><h2>WELCOME!</h2><p>We have registered your account at Bhodi with credentials below.</p><p>Email: ${email}</p><p>Password: ${password}</p></body></html>`,
                                };
                                if (this.state.barberData) {
                                    dataForAPI.webShopCode = this.state.barberData.webshopCodeSaloon
                                }

                                var config = {
                                    method: "post",
                                    url: "https://us-central1-bhodi-production-da765.cloudfunctions.net/onboardBarberClient",
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                    data: dataForAPI,
                                };

                                axios(config)
                                    .then((response) => {
                                        if (response.data.statusCode === 201) {
                                            this.setState({ aanmeldenloader: false, secondStep: true, urlRedirect: `${window.location.origin}/member/${obj.user_name_id}` })
                                        }
                                    })
                                    .catch(function (error) {
                                        console.log(error);
                                    });
                            })

                        })
                })
            })
            // this.setState({ secondStep: true })
        }
    }

    getFormattedDate(e) {
        if (e) {
            let splitted = e.split("-");
            splitted = splitted.reverse().join("-");
            return `${splitted}`;
        }
    }


    render() {
        return (
            this.state.showMainLoader ? (
                <div
                    className="global-event-cart globaleventloaderpage"
                    style={{
                        height: "auto",
                        top: 0,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        zIndex: 999999999,
                    }}
                >
                    <div className="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    {window.innerWidth > 1008 && <Footer notOnMainPage={true} />}
                    {window.innerWidth < 1008 && <FooterGreen notOnMainPage={true} />}
                </div>
            ) : (<div className="global-event-cart globaleventcartfixedstickyfooter companysubscribepagelink companysubscribepagelinkbarber clientonboardingbarber" style={{ minHeight: window.innerWidth < 1008 && `${window.innerHeight}px`, height: window.innerWidth < 1008 && `${this.state.windowInnerHeight}px` }}>
                <div className="left-gec">
                    {this.state.maxTimePast ? (<div className='form-box-cocosum third-step-fbcoco'>
                        <p className='first-smt-fbcoco'>Sorry</p>
                        <div className='second-headingwrap-fbcoco sc-fourthstep-main sc-fifthstep-main' style={{ flexDirection: "column", alignItems: "flex-start", marginBottom: 0 }}>
                            <p>Use time of link is expired.</p>
                        </div>
                    </div>) : this.state.isAlreadyJoined ? (<div className='form-box-cocosum third-step-fbcoco'>
                        <p className='first-smt-fbcoco'>Sorry</p>
                        <div className='second-headingwrap-fbcoco sc-fourthstep-main sc-fifthstep-main' style={{ flexDirection: "column", alignItems: "flex-start", marginBottom: 0 }}>
                            <p>This link is already used.</p>
                        </div>
                    </div>) : this.state.secondStep ? (<div className='form-box-cocosum third-step-fbcoco'>
                        <p className='first-smt-fbcoco'>Gelukt</p>
                        <div className='second-headingwrap-fbcoco sc-fourthstep-main sc-fifthstep-main' style={{ flexDirection: "column", alignItems: "flex-start" }}>
                            <p>Yess! Jullie account is aangemaakt.</p>

                            <p className='third-smt-fbcoco'>
                                Indien gewenst kunnen jullie de pagina nog verder personaliseren
                                met een foto of video van jullie zaak.
                            </p>
                        </div>
                        <div className='bottom-cocosum' style={{ justifyContent: "center" }}>
                            <button style={{ marginLeft: 0 }} onClick={() => {
                                window.location.href = this.state.urlRedirect
                            }}>BEKIJK PAGINA</button>
                        </div>
                    </div>) : (<div className="container-lgec">
                        {window.innerWidth > 1008 ? (<div className="top-step-one-clgec">
                            <label>Aanmelden</label>
                            <label>Account gegevens</label>
                            <label>
                                Leuk dat jullie willen meedoen met Bhodi!<br />
                                Controleer het e-mailadres en kies een wachtwoord.
                            </label>
                        </div>) : (<div className='top-cobarber'>
                            <img src={logo} alt='logo' />
                            <img src={playiconImage} alt='play-icon' className='play-icon' />
                            <p>
                                Per e-mail ontvang je een<br />
                                persoonlijke bedankvideo<br />
                                vanuit de school in Tanzania.
                                <br />
                                <br />
                                Daarnaast ontvang je exclusieve<br />
                                acties voor onze Keune webshop.
                            </p>
                        </div>)}
                        <div className="forms-clgec">
                            <div className={`form-field-clgec ${this.state.fullNameEmpty ? "redalertffcl" : ""}`}>
                                <input
                                    placeholder='Voornaam'
                                    value={this.state.fullName}
                                    type='text'
                                    onChange={(e) => {
                                        this.setState({ fullName: e.target.value })
                                    }}
                                />
                            </div>
                            <div className={`form-field-clgec ${this.state.emailEmpty ? "redalertffcl" : ""}`}>
                                <input
                                    placeholder='Email'
                                    value={this.state.email}
                                    type='email'
                                    onChange={(e) => {
                                        this.setState({ email: e.target.value })
                                    }}
                                />
                            </div>
                            <div className="bottom-buttons-clgec">
                                {this.state.aanmeldenloader ? (
                                    window.innerWidth < 1008 && (
                                        <></>
                                    )
                                ) : (
                                    <button
                                        onClick={() => {
                                            this.moveFurther()
                                        }}
                                    >
                                        Afronden
                                    </button>
                                )}
                                {this.state.aanmeldenloader && (
                                    <div className="lds-ellipsis">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='timer-info-qr'>
                            <p className='heading-tiqr'>PAGINA VERLOOPT OVER</p>
                            {/* <div className='timer-times-qr'>
                                <div className='inner-ttq'>
                                    <p>23</p>
                                    <p>Hours</p>
                                </div>
                                <div className='inner-ttq'>
                                    <p>23</p>
                                    <p>Minuts</p>
                                </div>
                                <div className='inner-ttq'>
                                    <p>59</p>
                                    <p>Seconds</p>
                                </div>
                            </div> */}
                            {this.state.maxTime && (<TimerOnboardingBarberClient timeToCalculate={this.state.maxTime.getTime()} changeScreen={() => {
                                this.setState({ maxTimePast: true })
                            }} />)}
                        </div>
                    </div>)}
                </div>
                {window.innerWidth > 1008 && (<div className="right-gec">

                </div>)}

                {window.innerWidth > 1008 && <Footer notOnMainPage={true} />}
                {window.innerWidth < 1008 && <FooterGreen notOnMainPage={true} />}
            </div>)
        )
    }
}


const mapStateToProps = (state) => {
    return {
        global_values: state.globalReducers.global_values,
        user: state.authReducers.user,
        admin_boolean: state.globalReducers.admin_boolean,
        globalsectors: state.categoriesReducer.globalsectors,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateAdminOrNot: (admin_boolean) => dispatch(updateAdminOrNot(admin_boolean)),
        updateGlobalStuff: (global_values) => dispatch(updateGlobalStuff(global_values)),
        updateAlbumToAdd: (albumToAdd) => dispatch(updateAlbumToAdd(albumToAdd)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BarberClientOnboarding);