import React, { Component } from "react";
import "react-sortable-tree/style.css";
import CloseIcon from "@material-ui/icons/Close";
import { updateGlobalStuff } from "../Redux/actions/authActions";
import { connect } from "react-redux";
import firebase from "../Config/Firebase";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PublishIcon from "@material-ui/icons/Publish";
import AdminNavbar from "./AdminNavbar";
import * as XLSX from "xlsx";
import swal from "sweetalert";
import axios from "axios";

var convert = require("xml-js")

const bcrypt = require("bcryptjs");
const saltRounds = 10;

export class AccountCreator extends Component {
    constructor(props) {
        super(props);

        this.state = {
            treeData: [],
            sectorModal: false,
            sectorModalCategory: false,
            sectorName: "",
            sectors: [],
            currentSector: "",
            currentSectorId: "",
            categoryName: "",
            currentSector: "",
            categoryId: "",
            all_categories: [],
            sortingTab: true,
            adjustingTab: false,
            completeMainTitles: [],
            arrOfInputs: [],
            kortingsInfo: "",
            charityInfo: "",
            lastMinuteInfo: "",
            giveawayInfo: "",
            webShopInfo: "",
            liveEventInfo: "",
            localEventInfo: "",
            globalOverlay: "",
            orderSuccessOverlay: "",
            kortingsInfoMobile: "",
            charityInfoMobile: "",
            giveawayInfoMobile: "",
            webShopInfoMobile: "",
            localEventInfoMobile: "",
            showGlobalTimer: false,
            currentDateGlobal: "",
            currentTimeGlobal: "",
            intervalTimer: "",
            intervalTitle: "",
            intervalDesc: "",
            dropdownKeys: [],
            completeCSVArray: [],
            allListings: [],
            csvFileVoornaam: "",
            csvFileAchternaam: "",
            csvFileStreet: "",
            csvFileHouse: "",
            csvFilePostCode: "",
            csvFileWoonplaats: "",
            csvFileCountry: "",
            csvFileTelefoon: "",
            csvFileEmail: "",
            csvFileKvk: "",
            csvFileWebsite: "",
            csvFileBedrijsfnaam: "",
            currentUserSpecific: "",
            allListingsUploaded: false,
            selectedMain: [],
            all_categories: [],
            allSectors: [],
            selectedSector: "",
            categoryData: [],
        };
    }

    componentDidMount() {
        const loaderId = document.getElementById("main-page-loader-id");
        loaderId.style.display = "none";

        firebase.database().ref("accounts_to_create").on("value", (data) => {
            let a = data.val()
            if (a) {
                this.setState({ allListings: a })
            }
        })

        firebase
            .database()
            .ref("bhodi_campaigns_category")
            .on("value", (data) => {
                let b = data.val();
                if (b) {
                    this.setState({ categoryData: b });
                }
            });

        firebase
            .database()
            .ref("bhodi_categories_vouchers")
            .on("value", (data) => {
                let a = data.val();
                if (a) {
                    let objected = Object.entries(a);
                    this.setState({
                        completeCategories: objected,
                    });
                } else {
                    this.setState({ completeCategories: [] });
                }
            });

        firebase
            .database()
            .ref("all_sectors")
            .on("value", (data) => {
                let a = data.val();
                let arr = [];
                if (a) {
                    let objected = Object.entries(a);
                    objected.map((h) => {
                        arr.push(h[1]);
                    });
                    this.setState({ allSectors: arr });
                } else {
                    this.setState({ allSectors: [] });
                }
            });

        firebase
            .database()
            .ref("users")
            .on("value", (data) => {
                let a = data.val();
                let arr = [];
                if (a && !this.state.stopTakingData) {
                    let objected = Object.entries(a);
                    objected.map((u) => {
                        arr.push(u[1]);
                    });
                    let usersWithNumbers = [];
                    arr.map((t) => {
                        if (t.user_id_numbers) {
                            usersWithNumbers.push(t);
                        }
                    });
                    let sorted = usersWithNumbers.sort((a, b) => {
                        if (a.user_id_numbers && b.user_id_numbers) {
                            return a.user_id_numbers - b.user_id_numbers;
                        }
                    });
                    this.setState({
                        allUsers: arr,
                        currentUserSpecific: Number(sorted[sorted.length - 1].user_id_numbers) + 1,
                    });
                } else {
                    this.setState({ allUsers: [] });
                }
            });
    }

    createAccounts() {
        console.log("working")
        if (this.state.allListings.length > 0) {
            let filtered = this.state.allListings.filter((t) => {
                return t.selected
            })
            if (filtered.length > 0) {
                let accountsToCreate = []
                let today = new Date();
                let date = today.getDate() + "-" + (today.getMonth() + 1) + "-" + today.getFullYear();
                filtered.map((t) => {
                    let jointName = t.company_name;

                    let filteredForJoin = this.state.allUsers.filter((g) => {
                        return g.user_name_id === jointName.toLowerCase();
                    });

                    if (filteredForJoin.length > 0) {
                        let randomStr = Math.ceil(Math.random() * (20 - 0) + 0);
                        jointName = t.company_name + randomStr;

                        let filteredForJoinSecond = this.state.allUsers.filter((g) => {
                            return g.user_name_id === jointName.toLowerCase();
                        });
                        if (filteredForJoinSecond.length > 0) {
                            let randomStr = Math.ceil(Math.random() * (20000 - 0) + 0);
                            jointName = t.company_name + randomStr;

                            let filteredForJoinThird = this.state.allUsers.filter((g) => {
                                return g.user_name_id === jointName.toLowerCase();
                            });
                            if (filteredForJoinThird.length > 0) {
                                let randomStr = Math.ceil(Math.random() * (20000 - 0) + 0);
                                jointName = t.company_name + randomStr;

                                let filteredForJoinFourth = this.state.allUsers.filter(
                                    (g) => {
                                        return g.user_name_id === jointName.toLowerCase();
                                    }
                                );
                                if (filteredForJoinFourth.length > 0) {
                                    let randomStr = Math.ceil(Math.random() * (20000 - 0) + 0);
                                    jointName = t.company_name + randomStr;
                                }
                            }
                        }
                    }

                    if (jointName.includes(" ")) {
                        jointName = jointName.replace(/ /g, "_");
                    }

                    let obj = {
                        first_name: t.first_name,
                        email: t.email,
                        last_name: t.last_name,
                        password: t.password,
                        country: t.country,
                        passwordChanged: false,
                        user_id: "userId",
                        street: t.street,
                        city: t.city,
                        zipCode: t.zipCode,
                        houseNumber: t.houseNumber,
                        company_website: t.company_website,
                        kvkCompany: t.kvkCompany,
                        phoneNumber: t.phoneNumber,
                        type: "company",
                        user_name_id: jointName.toLowerCase(),
                        created_on: date,
                        company_name: t.company_name,
                        lunch_show_contact: true,
                        dinner_show_contact: true,
                        dinner_title_contact: "Dinner",
                        lunch_title_contact: "Dinner",
                        newly_registered: true,
                        user_id_numbers: this.state.currentUserSpecific,
                        profileActive: false,
                    };

                    accountsToCreate.push(obj)
                })
                console.log(accountsToCreate)
                // put account created
                accountsToCreate.map((accountInfo) => {
                    let findIndex = this.state.allListings.findIndex((e) => {
                        return e.email === accountInfo.email
                    })
                    if (findIndex !== -1) {
                        this.state.allListings[findIndex].accountCreated = true
                    }
                })
                // put account created

                let accountsCreated = 0
                let promiseFirst = new Promise((res, rej) => {
                    accountsToCreate.map((accountInfo) => {
                        bcrypt.genSalt(saltRounds, (err, salt) => {
                            bcrypt.hash(accountInfo.password, salt, (err, hash) => {
                                // firebase.auth().createUserWithEmailAndPassword(accountInfo.email, accountInfo.password)
                                //     .then((e) => {
                                //         console.log(e, '/created')

                                //         let userId = e.user.uid;
                                //         accountInfo.user_id = userId
                                //         accountInfo.password = hash

                                //         console.log("users/" + userId)

                                //         firebase.database().ref("users/" + userId).set(accountInfo)

                                //         accountsCreated = accountsCreated + 1
                                //         if (accountsCreated === accountsToCreate.length) {
                                //             res()
                                //         }
                                //     })
                                var data = JSON.stringify({
                                    email: accountInfo.email,
                                    password: accountInfo.password,
                                })

                                var config = {
                                    method: "post",
                                    url: "https://us-central1-bhodi-production-da765.cloudfunctions.net/createAccount",
                                    headers: {
                                        'Content-Type': 'application/json'
                                    },
                                    data: data,
                                }

                                axios(config)
                                    .then((response) => {
                                        if (response.data.statusCode == 201) {
                                            let userId = response.data.user_id;
                                            accountInfo.user_id = userId
                                            accountInfo.password = hash

                                            firebase.database().ref("users/" + userId).set(accountInfo)


                                            let storageKey = firebase
                                                .database()
                                                .ref("bhodi_campaigns/" + userId)
                                                .push().key;
                                            let timestamp = Math.floor(Date.now() / 100);

                                            let objCampaign = {
                                                title: "Dummy",
                                                desc: "Dummy",
                                                localImageType: "uploadfromcomp",
                                                campaignType: "local_campaign",
                                                pushKey: storageKey,
                                                image: "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/placeholder.jpg?alt=media&token=53d59535-5c5d-4617-91c9-cc84ecf766f8",
                                                sortNumber: 0,
                                                active: false,
                                                created_by: userId,
                                                timestamp,
                                                number: 0,
                                                localUnlimited: false,
                                                created_at: new Date().getTime(),
                                            };
                                            if (this.state.selectedMain.length > 0) {
                                                objCampaign.main_sector = this.state.selectedSector;
                                                objCampaign.main_category = this.state.selectedMain;
                                                objCampaign.sub_category = "";
                                            }
                                            firebase
                                                .database()
                                                .ref("bhodi_campaigns/" + userId + "/" + storageKey)
                                                .set(objCampaign)
                                                .then(() => {
                                                    accountsCreated = accountsCreated + 1
                                                    if (accountsCreated === accountsToCreate.length) {
                                                        res()
                                                    }
                                                });
                                        }
                                    })
                                    .catch((err) => {
                                        console.log(err)
                                    });
                            })
                        })
                    })
                })

                promiseFirst.then(() => {
                    let arr = []
                    this.state.allListings.slice(0, this.state.allListings.length).map((t) => {
                        t.selected = false
                        arr.push(t)
                    })
                    firebase.database().ref("accounts_to_create").set(arr)
                    swal({
                        title: "Cool",
                        text: "Accounts created successfully",
                        icon: "success",
                    })
                })
            }
        }
    }

    uploadAccounts() {
        const { allListings } = this.state
        let arr = []
        allListings.slice(0, allListings.length).map((t) => {
            t.selected = false
            arr.push(t)
        })
        console.log(arr)
        if (arr.length > 0) {
            firebase.database().ref("accounts_to_create").set(arr)
        }
    }

    sendEmail() {
        if (this.state.allListings.length > 0) {
            let filtered = this.state.allListings.filter((t) => {
                return t.selected
            })
            if (filtered.length > 0) {
                filtered.map((t) => {
                    var dataForAPI = JSON.stringify({
                        sendToEmail: t.email,
                        subject: "Account Created at Bhodi!",
                        innerHTMLpart: `<!DOCTYPE html><html><head><title>Page Title</title></head><body><h2>WELCOME!</h2><p>We have registered your account at Bhodi with credentials below.</p><p>Email: ${t.email}</p><p>Password: ${t.password}</p></body></html>`,
                    });

                    var config = {
                        method: "post",
                        url: "https://us-central1-bhodi-production-da765.cloudfunctions.net/sendEmailMailjet",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        data: dataForAPI,
                    };

                    axios(config)
                        .then((response) => {
                            if (response.data.statusCode === 201) {
                                swal({
                                    title: "Cool",
                                    text: "Emails sent successfully",
                                    icon: "success",
                                })
                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                })
            }
        }
    }

    fileUploadAccounts(e) {
        e.preventDefault();
        if (e.target.files && e.target.files.length > 0) {
            if (e.target.files[0].type.includes("/xml")) {
                this.setState({ csvSelectedName: e.target.files[0].name, selectedXml: true })
                const reader = new FileReader();
                reader.onload = (e) => {
                    let readXml = e.target.result

                    let result = convert.xml2json(readXml, { compact: true, spaces: 4 })

                    let currentArray = JSON.parse(result)

                    if (currentArray.items && currentArray.items.item && currentArray.items.item.length > 0) {
                        let allKeys = [];
                        currentArray.items.item.map((e) => {
                            let keysOfObject = Object.keys(e);
                            keysOfObject.map((t) => {
                                allKeys.push(t);
                            });
                        });
                        let filteredOfKeys = allKeys.filter(
                            (v, i, a) => a.findIndex((t) => t === v) === i
                        );
                        this.setState({
                            dropdownKeys: filteredOfKeys,
                            completeCSVArray: currentArray.items.item,
                            popupToClarify: true,
                            csvFileVoornaam: "",
                            csvFileAchternaam: "",
                            csvFileStreet: "",
                            csvFileHouse: "",
                            csvFilePostCode: "",
                            csvFileWoonplaats: "",
                            csvFileCountry: "",
                            csvFileTelefoon: "",
                            csvFileEmail: "",
                            csvFileKvk: "",
                            csvFileWebsite: "",
                            csvFileBedrijsfnaam: "",
                        });
                    }

                };
                reader.readAsText(e.target.files[0]);
                e.target.value = "";
            } else {
                this.setState({ csvSelectedName: e.target.files[0].name, selectedXml: false })
                const reader = new FileReader();
                reader.onload = (e) => {
                    const data = e.target.result;
                    const workbook = XLSX.read(data, { type: "array" });
                    const sheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[sheetName];
                    const json = XLSX.utils.sheet_to_json(worksheet);
                    let allKeys = [];

                    json.map((e) => {
                        let keysOfObject = Object.keys(e);
                        keysOfObject.map((t) => {
                            allKeys.push(t);
                        });
                    });
                    let filteredOfKeys = allKeys.filter(
                        (v, i, a) => a.findIndex((t) => t === v) === i
                    );
                    this.setState({
                        dropdownKeys: filteredOfKeys,
                        completeCSVArray: json,
                        popupToClarify: true,
                        csvFileVoornaam: "",
                        csvFileAchternaam: "",
                        csvFileStreet: "",
                        csvFileHouse: "",
                        csvFilePostCode: "",
                        csvFileWoonplaats: "",
                        csvFileCountry: "",
                        csvFileTelefoon: "",
                        csvFileEmail: "",
                        csvFileKvk: "",
                        csvFileWebsite: "",
                        csvFileBedrijsfnaam: "",
                    });
                };
                reader.readAsArrayBuffer(e.target.files[0]);
                e.target.value = "";
            }
        }
    }

    randomStr(e) {
        let len = e
        let arr = "abcdefghijklmnopqrstuvwxyz1928102489"
        var ans = '';
        for (var i = len; i > 0; i--) {
            ans +=
                arr[Math.floor(Math.random() * arr.length)];
        }
        return ans;
    }

    uploadCsvFile() {

        const {
            csvFileVoornaam,
            csvFileAchternaam,
            csvFileStreet,
            csvFileEmail,
            csvFileHouse,
            csvFilePostCode,
            csvFileWoonplaats,
            csvFileCountry,
            csvFileTelefoon,
            csvFileBedrijsfnaam,
            csvFileKvk,
            csvFileWebsite,
        } = this.state;
        if (csvFileVoornaam) {
            this.setState({ notSelectedVoornaamCSV: false });
        } else {
            this.setState({ notSelectedVoornaamCSV: true });
        }
        if (csvFileAchternaam) {
            this.setState({ notSelectedAchternaamCSV: false });
        } else {
            this.setState({ notSelectedAchternaamCSV: true });
        }
        if (csvFileBedrijsfnaam) {
            this.setState({ notSelectedBedrijsfnaamCSV: false });
        } else {
            this.setState({ notSelectedBedrijsfnaamCSV: true });
        }
        if (csvFileKvk) {
            this.setState({ notSelectedKvkCSV: false });
        } else {
            this.setState({ notSelectedKvkCSV: true });
        }
        if (csvFileWebsite) {
            this.setState({ notSelectedWebsiteCSV: false });
        } else {
            this.setState({ notSelectedWebsiteCSV: true });
        }
        if (csvFileStreet) {
            this.setState({ notSelectedStreetCSV: false });
        } else {
            this.setState({ notSelectedStreetCSV: true });
        }
        if (csvFileEmail) {
            this.setState({ notSelectedEmailCSV: false });
        } else {
            this.setState({ notSelectedEmailCSV: true });
        }
        if (csvFileHouse) {
            this.setState({ notSelectedHouseCSV: false });
        } else {
            this.setState({ notSelectedHouseCSV: true });
        }
        if (csvFilePostCode) {
            this.setState({ notSelectedPostCodeCSV: false });
        } else {
            this.setState({ notSelectedPostCodeCSV: true });
        }
        if (csvFileWoonplaats) {
            this.setState({ notSelectedWoonplaatsCSV: false });
        } else {
            this.setState({ notSelectedWoonplaatsCSV: true });
        }
        if (csvFileCountry) {
            this.setState({ notSelectedCountryCSV: false });
        } else {
            this.setState({ notSelectedCountryCSV: true });
        }
        if (csvFileTelefoon) {
            this.setState({ notSelectedTelefoonCSV: false });
        } else {
            this.setState({ notSelectedTelefoonCSV: true });
        }
        if (
            csvFileVoornaam &&
            csvFileAchternaam &&
            csvFileStreet &&
            csvFileEmail &&
            csvFileHouse &&
            csvFilePostCode &&
            csvFileWoonplaats &&
            csvFileCountry &&
            csvFileKvk &&
            csvFileWebsite &&
            csvFileBedrijsfnaam &&
            csvFileTelefoon
        ) {
            if (this.state.completeCSVArray.length > 0) {
                let arrToPush = [];
                this.state.completeCSVArray.map((e) => {
                    let obj = {
                        first_name: e[csvFileVoornaam],
                        last_name: e[csvFileAchternaam],
                        street: e[csvFileStreet],
                        email: e[csvFileEmail],
                        houseNumber: e[csvFileHouse],
                        zipCode: e[csvFilePostCode],
                        city: e[csvFileWoonplaats],
                        country: e[csvFileCountry],
                        phoneNumber: e[csvFileTelefoon],
                        company_name: e[csvFileBedrijsfnaam],
                        kvkCompany: e[csvFileKvk],
                        company_website: e[csvFileWebsite],
                        selected: false,
                        password: this.randomStr(8),
                    };
                    arrToPush.push(obj);
                });
                this.setState({
                    allListings: arrToPush,
                    allListingsUploaded: true,
                    popupToClarify: false,
                    csvFileVoornaam: "",
                    csvFileAchternaam: "",
                    csvFileStreet: "",
                    csvFileHouse: "",
                    csvFilePostCode: "",
                    csvFileWoonplaats: "",
                    csvFileCountry: "",
                    csvFileTelefoon: "",
                    csvFileEmail: "",
                    csvFileKvk: "",
                    csvFileWebsite: "",
                    csvFileBedrijsfnaam: "",
                })
            }
        }
    }

    checkForSendEmail() {
        if (this.state.allListings.length > 0) {
            let filtered = this.state.allListings.filter((t) => {
                return t.selected
            })
            if (filtered.length > 0) {
                let filteredInner = filtered.filter((t) => {
                    return t.accountCreated && !t.emailSent
                })
                if (filteredInner.length > 0) {
                    return <button onClick={this.sendEmail.bind(this)} style={{ marginLeft: 5 }}>SEND EMAIL</button>
                }
            }
        }
    }

    checkForCreateAccount() {
        if (this.state.allListings.length > 0) {
            let filtered = this.state.allListings.filter((t) => {
                return t.selected
            })
            if (filtered.length > 0) {
                let filteredInner = filtered.filter((t) => {
                    return !t.accountCreated
                })
                if (filteredInner.length > 0) {
                    return <button onClick={this.createAccounts.bind(this)} style={{ marginLeft: 5 }}>CREATE</button>
                }
            }
        }
    }

    render() {
        return (
            <div className="sorting-mainadmin">
                <AdminNavbar accountCreator={true} />
                <div className="container-sma containeraccountcreatoradmin">
                    <div className="info-div-editor">
                        <div className="group-textbox" style={{ textAlign: "center" }}>
                            <label style={{ fontSize: 16, fontWeight: 600 }}>
                                ACCOUNT IMPORT
                            </label>
                        </div>
                        <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <div className="group-textbox" style={{ maxWidth: 500 }}>
                                <label>CSV/XML File</label>
                                <input
                                    type="file"
                                    accept=".csv"
                                    onChange={this.fileUploadAccounts.bind(this)}
                                />
                            </div>
                            <div className="account-create-selects">
                                <div className='wrapper-acs-select'>
                                    <select
                                        style={{ fontSize: 13, letterSpacing: 1 }}
                                        value={this.state.selectedSector}
                                        onChange={(e) => {
                                            if (e.target.value !== "Selecteer") {
                                                let cloned = Object.entries(
                                                    this.state.categoryData
                                                );
                                                let filtered = cloned.filter((g) => {
                                                    return g[0] === e.target.value;
                                                });
                                                if (filtered.length > 0) {
                                                    if (e.target.value !== "Selecteer") {
                                                        let arrSec = [];
                                                        this.state.completeCategories.map((g) => {
                                                            if (g[0] === e.target.value) {
                                                                arrSec = g[1];
                                                            }
                                                        });
                                                        let categories = [];
                                                        arrSec.map((h) => {
                                                            if (h.children) {
                                                                let obj = {
                                                                    title: h.title,
                                                                    category_id: h.category_id,
                                                                    sub_category: h.children,
                                                                };
                                                                categories.push(obj);
                                                            } else {
                                                                let obj = {
                                                                    title: h.title,
                                                                    category_id: h.category_id,
                                                                };
                                                                categories.push(obj);
                                                            }
                                                        });
                                                        this.setState({
                                                            selectedSector: e.target.value,
                                                            all_categories: categories,
                                                            selectedMain: []
                                                        });
                                                    } else {
                                                        this.setState({
                                                            all_categories: [],
                                                            selectedMain: [],
                                                            selectedSector: "",
                                                        });
                                                    }
                                                }
                                            }
                                        }}
                                    >
                                        <option disabled selected hidden value="">
                                            Selecteer Sector
                                        </option>
                                        {this.state.allSectors.map((g) => {
                                            return (
                                                <option value={g.sector_id}>{g.sector_name}</option>
                                            );
                                        })}
                                    </select>
                                    <ExpandMoreIcon
                                        style={{
                                            position: "absolute",
                                            bottom: 5,
                                            right: 5,
                                            pointerEvents: "none",
                                        }}
                                        className='expand-more'
                                    />
                                </div>
                                {this.state.all_categories.length > 0 && <div className='wrapper-acs-select' style={{ marginLeft: 10 }}>
                                    <select
                                        style={{ fontSize: 13, letterSpacing: 1 }}
                                        value={this.state.selectedMain.length > 0 ? this.state.selectedMain[0] : ""}
                                        onChange={(e) => {
                                            this.setState({
                                                selectedMain: [e.target.value],
                                            });
                                        }}>
                                        <option disabled selected hidden value="">
                                            Selecteer Category
                                        </option>
                                        {this.state.all_categories.map((e) => {
                                            return (
                                                <option value={e.category_id}>
                                                    {/* <input
                                                type="checkbox"
                                                className="input-radio checkboxessortname"
                                                value={e.category_id}
                                                checked={this.state.selectedMain.includes(
                                                    e.category_id
                                                )}
                                                onChange={() => {
                                                    if (
                                                        this.state.selectedMain.includes(
                                                            e.category_id
                                                        )
                                                    ) {
                                                        let indexed =
                                                            this.state.selectedMain.indexOf(
                                                                e.category_id
                                                            );
                                                        this.state.selectedMain.splice(indexed, 1);
                                                        this.setState({
                                                            selectedMain: this.state.selectedMain,
                                                        });
                                                    } else {
                                                        this.state.selectedMain.push(e.category_id);
                                                        this.setState({
                                                            selectedMain: this.state.selectedMain,
                                                        });
                                                    }
                                                }}
                                            /> */}
                                                    {e.title}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    <ExpandMoreIcon
                                        style={{
                                            position: "absolute",
                                            bottom: 5,
                                            right: 5,
                                            pointerEvents: "none",
                                        }}
                                        className='expand-more'
                                    />
                                </div>}
                            </div>
                        </div>
                        <div className="table-account-creator">
                            <div className="firstrow-tac">
                                <div>
                                    <p>Voornaam</p>
                                </div>
                                <div>
                                    <p>Achternaam</p>
                                </div>
                                <div>
                                    <p>Straatnaam</p>
                                </div>
                                <div>
                                    <p>Email</p>
                                </div>
                                <div>
                                    <p>Bedrijfsnaam</p>
                                </div>
                                <div>
                                    <p>Huisnummer</p>
                                </div>
                                <div>
                                    <p>Postcode</p>
                                </div>
                                <div>
                                    <p>Plaats</p>
                                </div>
                                <div>
                                    <p>Country</p>
                                </div>
                                <div>
                                    <p>Number</p>
                                </div>
                                <div>
                                    <p>KVK</p>
                                </div>
                                <div>
                                    <p>Website</p>
                                </div>
                                <div>
                                    <p>Actions</p>
                                </div>
                            </div>
                            {this.state.allListings.length > 0 && this.state.allListings.map((e) => {
                                return (<div className="normalrows-tac" key={Math.random()}>
                                    <div>
                                        <p>{e.first_name}</p>
                                    </div>
                                    <div>
                                        <p>{e.last_name}</p>
                                    </div>
                                    <div>
                                        <p>{e.street}</p>
                                    </div>
                                    <div>
                                        <p>{e.email}</p>
                                    </div>
                                    <div>
                                        <p>{e.company_name}</p>
                                    </div>
                                    <div>
                                        <p>{e.houseNumber}</p>
                                    </div>
                                    <div>
                                        <p>{e.zipCode}</p>
                                    </div>
                                    <div>
                                        <p>{e.city}</p>
                                    </div>
                                    <div>
                                        <p>{e.country}</p>
                                    </div>
                                    <div>
                                        <p>{e.phoneNumber}</p>
                                    </div>
                                    <div>
                                        <p>{e.kvkCompany}</p>
                                    </div>
                                    <div>
                                        <p>{e.company_website}</p>
                                    </div>
                                    <div>
                                        <div className={`checkbox-nrtac ${e.selected && "active"}`} onClick={() => {
                                            e.selected = !e.selected
                                            this.setState({ allListings: this.state.allListings })
                                        }}>

                                        </div>
                                    </div>
                                </div>)
                            })}
                        </div>
                        <div className="group-textbox" style={{ textAlign: "right", marginTop: 40, flexDirection: "row", justifyContent: "flex-end" }}>
                            {this.checkForCreateAccount()}
                            {this.checkForSendEmail()}
                            {this.state.allListingsUploaded && (<button style={{ marginLeft: 5 }} onClick={this.uploadAccounts.bind(this)}>UPLOAD</button>)}
                        </div>
                    </div>
                </div>
                {this.state.popupToClarify && (
                    <div className="fields-clarify-csv">
                        <div className="opacity-fccsv"></div>
                        <div className="box-fccsv" style={{ position: "relative", maxHeight: 900, overflowY: "auto" }}>
                            <CloseIcon
                                style={{ position: "absolute", top: 10, right: 10 }}
                                onClick={() => {
                                    this.setState({ popupToClarify: false });
                                }}
                            />
                            <div className="inner-box-fccsv">
                                <label
                                    style={{
                                        color: this.state.notSelectedVoornaamCSV ? "red" : "black",
                                    }}
                                >
                                    Selecteer Voornaam
                                </label>
                                <div className="selectinnerbfccsv">
                                    <select
                                        value={this.state.csvFileVoornaam}
                                        onChange={(e) => {
                                            this.setState({
                                                csvFileVoornaam: e.target.value,
                                            });
                                        }}
                                    >
                                        <option value="" disabled selected hidden>
                                            Selecteer
                                        </option>
                                        {this.state.dropdownKeys.map((t) => {
                                            return <option value={t}>{t}</option>;
                                        })}
                                    </select>
                                    <ExpandMoreIcon />
                                </div>
                            </div>
                            <div className="inner-box-fccsv">
                                <label
                                    style={{
                                        color: this.state.notSelectedAchternaamCSV
                                            ? "red"
                                            : "black",
                                    }}
                                >
                                    Selecteer Achternaam
                                </label>
                                <div className="selectinnerbfccsv">
                                    <select
                                        value={this.state.csvFileAchternaam}
                                        onChange={(e) => {
                                            this.setState({
                                                csvFileAchternaam: e.target.value,
                                            });
                                        }}
                                    >
                                        <option value="" disabled selected hidden>
                                            Selecteer
                                        </option>
                                        {this.state.dropdownKeys.map((t) => {
                                            return <option value={t}>{t}</option>;
                                        })}
                                    </select>
                                    <ExpandMoreIcon />
                                </div>
                            </div>
                            <div className="inner-box-fccsv">
                                <label
                                    style={{
                                        color: this.state.notSelectedBedrijsfnaamCSV
                                            ? "red"
                                            : "black",
                                    }}
                                >
                                    Selecteer Bedrijsfnaam
                                </label>
                                <div className="selectinnerbfccsv">
                                    <select
                                        value={this.state.csvFileBedrijsfnaam}
                                        onChange={(e) => {
                                            this.setState({
                                                csvFileBedrijsfnaam: e.target.value,
                                            });
                                        }}
                                    >
                                        <option value="" disabled selected hidden>
                                            Selecteer
                                        </option>
                                        {this.state.dropdownKeys.map((t) => {
                                            return <option value={t}>{t}</option>;
                                        })}
                                    </select>
                                    <ExpandMoreIcon />
                                </div>
                            </div>
                            <div className="inner-box-fccsv">
                                <label
                                    style={{
                                        color: this.state.notSelectedKvkCSV
                                            ? "red"
                                            : "black",
                                    }}
                                >
                                    Selecteer KVK
                                </label>
                                <div className="selectinnerbfccsv">
                                    <select
                                        value={this.state.csvFileKvk}
                                        onChange={(e) => {
                                            this.setState({
                                                csvFileKvk: e.target.value,
                                            });
                                        }}
                                    >
                                        <option value="" disabled selected hidden>
                                            Selecteer
                                        </option>
                                        {this.state.dropdownKeys.map((t) => {
                                            return <option value={t}>{t}</option>;
                                        })}
                                    </select>
                                    <ExpandMoreIcon />
                                </div>
                            </div>
                            <div className="inner-box-fccsv">
                                <label
                                    style={{
                                        color: this.state.notSelectedWebsiteCSV
                                            ? "red"
                                            : "black",
                                    }}
                                >
                                    Selecteer Website
                                </label>
                                <div className="selectinnerbfccsv">
                                    <select
                                        value={this.state.csvFileWebsite}
                                        onChange={(e) => {
                                            this.setState({
                                                csvFileWebsite: e.target.value,
                                            });
                                        }}
                                    >
                                        <option value="" disabled selected hidden>
                                            Selecteer
                                        </option>
                                        {this.state.dropdownKeys.map((t) => {
                                            return <option value={t}>{t}</option>;
                                        })}
                                    </select>
                                    <ExpandMoreIcon />
                                </div>
                            </div>
                            <div className="inner-box-fccsv">
                                <label
                                    style={{
                                        color: this.state.notSelectedStreetCSV
                                            ? "red"
                                            : "black",
                                    }}
                                >
                                    Selecteer Street
                                </label>
                                <div className="selectinnerbfccsv">
                                    <select
                                        value={this.state.csvFileStreet}
                                        onChange={(e) => {
                                            this.setState({
                                                csvFileStreet: e.target.value,
                                            });
                                        }}
                                    >
                                        <option value="" disabled selected hidden>
                                            Selecteer
                                        </option>
                                        {this.state.dropdownKeys.map((t) => {
                                            return <option value={t}>{t}</option>;
                                        })}
                                    </select>
                                    <ExpandMoreIcon />
                                </div>
                            </div>
                            <div className="inner-box-fccsv">
                                <label
                                    style={{
                                        color: this.state.notSelectedEmailCSV ? "red" : "black",
                                    }}
                                >
                                    Selecteer Email
                                </label>
                                <div className="selectinnerbfccsv">
                                    <select
                                        value={this.state.csvFileEmail}
                                        onChange={(e) => {
                                            this.setState({
                                                csvFileEmail: e.target.value,
                                            });
                                        }}
                                    >
                                        <option value="" disabled selected hidden>
                                            Selecteer
                                        </option>
                                        {this.state.dropdownKeys.map((t) => {
                                            return <option value={t}>{t}</option>;
                                        })}
                                    </select>
                                    <ExpandMoreIcon />
                                </div>
                            </div>
                            <div className="inner-box-fccsv">
                                <label
                                    style={{
                                        color: this.state.notSelectedHouseCSV
                                            ? "red"
                                            : "black",
                                    }}
                                >
                                    Selecteer Huisnummer
                                </label>
                                <div className="selectinnerbfccsv">
                                    <select
                                        value={this.state.csvFileHouse}
                                        onChange={(e) => {
                                            this.setState({
                                                csvFileHouse: e.target.value,
                                            });
                                        }}
                                    >
                                        <option value="" disabled selected hidden>
                                            Selecteer
                                        </option>
                                        {this.state.dropdownKeys.map((t) => {
                                            return <option value={t}>{t}</option>;
                                        })}
                                    </select>
                                    <ExpandMoreIcon />
                                </div>
                            </div>
                            <div className="inner-box-fccsv">
                                <label
                                    style={{
                                        color: this.state.notSelectedPostCodeCSV
                                            ? "red"
                                            : "black",
                                    }}
                                >
                                    Selecteer Postcode
                                </label>
                                <div className="selectinnerbfccsv">
                                    <select
                                        value={this.state.csvFilePostCode}
                                        onChange={(e) => {
                                            this.setState({
                                                csvFilePostCode: e.target.value,
                                            });
                                        }}
                                    >
                                        <option value="" disabled selected hidden>
                                            Selecteer
                                        </option>
                                        {this.state.dropdownKeys.map((t) => {
                                            return <option value={t}>{t}</option>;
                                        })}
                                    </select>
                                    <ExpandMoreIcon />
                                </div>
                            </div>
                            <div className="inner-box-fccsv">
                                <label
                                    style={{
                                        color: this.state.notSelectedWoonplaatsCSV
                                            ? "red"
                                            : "black",
                                    }}
                                >
                                    Selecteer Woonplaats
                                </label>
                                <div className="selectinnerbfccsv">
                                    <select
                                        value={this.state.csvFileWoonplaats}
                                        onChange={(e) => {
                                            this.setState({
                                                csvFileWoonplaats: e.target.value,
                                            });
                                        }}
                                    >
                                        <option value="" disabled selected hidden>
                                            Selecteer
                                        </option>
                                        {this.state.dropdownKeys.map((t) => {
                                            return <option value={t}>{t}</option>;
                                        })}
                                    </select>
                                    <ExpandMoreIcon />
                                </div>
                            </div>
                            <div className="inner-box-fccsv">
                                <label
                                    style={{
                                        color: this.state.notSelectedCountryCSV
                                            ? "red"
                                            : "black",
                                    }}
                                >
                                    Selecteer Country
                                </label>
                                <div className="selectinnerbfccsv">
                                    <select
                                        value={this.state.csvFileCountry}
                                        onChange={(e) => {
                                            this.setState({
                                                csvFileCountry: e.target.value,
                                            });
                                        }}
                                    >
                                        <option value="" disabled selected hidden>
                                            Selecteer
                                        </option>
                                        {this.state.dropdownKeys.map((t) => {
                                            return <option value={t}>{t}</option>;
                                        })}
                                    </select>
                                    <ExpandMoreIcon />
                                </div>
                            </div>
                            <div className="inner-box-fccsv">
                                <label
                                    style={{
                                        color: this.state.notSelectedTelefoonCSV
                                            ? "red"
                                            : "black",
                                    }}
                                >
                                    Selecteer Telefoon
                                </label>
                                <div className="selectinnerbfccsv">
                                    <select
                                        value={this.state.csvFileTelefoon}
                                        onChange={(e) => {
                                            this.setState({
                                                csvFileTelefoon: e.target.value,
                                            });
                                        }}
                                    >
                                        <option value="" disabled selected hidden>
                                            Selecteer
                                        </option>
                                        {this.state.dropdownKeys.map((t) => {
                                            return <option value={t}>{t}</option>;
                                        })}
                                    </select>
                                    <ExpandMoreIcon />
                                </div>
                            </div>
                            <div className="inner-box-fccsv" style={{ marginBottom: 0 }}>
                                <button
                                    className="button-ibfccsv"
                                    onClick={this.uploadCsvFile.bind(this)}
                                >
                                    <PublishIcon style={{ fontSize: 20 }} />
                                    <font>Upload</font>
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        global_values: state.globalReducers.global_values,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateGlobalStuff: (global_values) =>
            dispatch(updateGlobalStuff(global_values)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountCreator);
