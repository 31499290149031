import React, { Component } from "react";
import firebase from "../../Config/Firebase";
import { connect } from "react-redux";
import { updateGlobalStuff } from "../../Redux/actions/authActions";
import CK from "../Ck";
import styles from "./index.module.css"
import Switch from "@material-ui/core/Switch";

export class EditorCommercial extends Component {
    constructor(props) {
        super(props);

        this.state = {
            commercial_page_charity_title: "",
            commercial_page_charity_title_mobile: "",
            commercial_page_charity_title_english: "",
            commercial_page_charity_title_english_mobile: "",
            commercial_page_charity_first: "",
            commercial_page_charity_first_mobile: "",
            commercial_page_charity_first_english: "",
            commercial_page_charity_first_english_mobile: "",
            commercial_page_charity_second: "",
            commercial_page_charity_second_mobile: "",
            commercial_page_charity_second_english: "",
            commercial_page_charity_second_english_mobile: "",
            commercial_page_charity_third: "",
            commercial_page_charity_third_mobile: "",
            commercial_page_charity_third_english: "",
            commercial_page_charity_third_english_mobile: "",

            charity_page_new_events_dutch: "",
            charity_page_new_events_dutch_mobile: "",
            charity_page_new_events_english: "",
            charity_page_new_events_english_mobile: "",
            charity_page_new_events_dutch_heading: "",
            charity_page_new_events_dutch_heading_mobile: "",
            charity_page_new_events_english_heading: "",
            charity_page_new_events_english_heading_mobile: "",


            charity_page_events_dutch_headertxt: "",
            charity_page_events_dutch_headertxt_mobile: "",
            charity_page_events_english_headertxt: "",
            charity_page_events_english_headertxt_mobile: "",
            charity_page_events_dutch_heading: "",
            charity_page_events_dutch_heading_mobile: "",
            charity_page_events_english_heading: "",
            charity_page_events_english_heading_mobile: "",

            charity_page_events_dutch_heading_inner: "",
            charity_page_events_dutch_heading_inner_mobile: "",
            charity_page_events_english_heading_inner: "",
            charity_page_events_english_heading_inner_mobile: "",
            charity_page_events_dutch: "",
            charity_page_events_dutch_mobile: "",
            charity_page_events_english: "",
            charity_page_events_english_mobile: "",

            charity_page_connect_dutch_heading: "",
            charity_page_connect_dutch_heading_mobile: "",
            charity_page_connect_english_heading: "",
            charity_page_connect_english_heading_mobile: "",
            charity_page_connect_dutch: "",
            charity_page_connect_dutch_mobile: "",
            charity_page_connect_english: "",
            charity_page_connect_english_mobile: "",

            charity_page_profile_dutch_heading: "",
            charity_page_profile_dutch_heading_mobile: "",
            charity_page_profile_english_heading: "",
            charity_page_profile_english_heading_mobile: "",
            charity_page_profile_dutch: "",
            charity_page_profile_dutch_mobile: "",
            charity_page_profile_english: "",
            charity_page_profile_english_mobile: "",


            charity_page_about_dutch_heading: "",
            charity_page_about_dutch_heading_mobile: "",
            charity_page_about_english_heading: "",
            charity_page_about_english_heading_mobile: "",
            charity_page_about_dutch: "",
            charity_page_about_dutch_mobile: "",
            charity_page_about_english: "",
            charity_page_about_english_mobile: "",

            charity_page_team_bhodi_dutch_heading: "",
            charity_page_team_bhodi_dutch_heading_mobile: "",
            charity_page_team_bhodi_english_heading: "",
            charity_page_team_bhodi_english_heading_mobile: "",
            charity_page_team_bhodi_dutch: "",
            charity_page_team_bhodi_dutch_mobile: "",
            charity_page_team_bhodi_english: "",
            charity_page_team_bhodi_english_mobile: "",

            checkout_step_one_text_first: "",
            checkout_step_one_text_first_mobile: "",
            checkout_step_one_text_first_english: "",
            checkout_step_one_text_first_english_mobile: "",
            checkout_step_one_second_text: "",
            checkout_step_one_second_text_mobile: "",
            checkout_step_one_second_text_english: "",
            checkout_step_one_second_text_english_mobile: "",
            checkout_step_one_text_first_signed_in: "",
            checkout_step_one_text_first_signed_in_mobile: "",
            checkout_step_one_text_first_signed_in_english: "",
            checkout_step_one_text_first_signed_in_english_mobile: "",
            checkout_step_one_second_text_signed_in: "",
            checkout_step_one_second_text_signed_in_mobile: "",
            checkout_step_one_second_text_signed_in_english: "",
            checkout_step_one_second_text_signed_in_english_mobile: "",
            checkout_step_one_text: "",
            checkout_step_one_text_mobile: "",
            checkout_step_one_text_english: "",
            checkout_step_one_text_english_mobile: "",
            checkout_step_one_text_signed_in: "",
            checkout_step_one_text_signed_in_mobile: "",
            checkout_step_one_text_signed_in_english: "",
            checkout_step_one_text_signed_in_english_mobile: "",

            // Products announcement sign up step one

            checkout_step_one_new_text_first: "",
            checkout_step_one_new_text_first_mobile: "",
            checkout_step_one_new_text_first_english: "",
            checkout_step_one_new_text_first_english_mobile: "",
            checkout_step_one_new_second_text: "",
            checkout_step_one_new_second_text_mobile: "",
            checkout_step_one_new_second_text_english: "",
            checkout_step_one_new_second_text_english_mobile: "",
            checkout_step_one_new_text_first_signed_in: "",
            checkout_step_one_new_text_first_signed_in_mobile: "",
            checkout_step_one_new_text_first_signed_in_english: "",
            checkout_step_one_new_text_first_signed_in_english_mobile: "",
            checkout_step_one_new_second_text_signed_in: "",
            checkout_step_one_new_second_text_signed_in_mobile: "",
            checkout_step_one_new_second_text_signed_in_english: "",
            checkout_step_one_new_second_text_signed_in_english_mobile: "",
            checkout_step_one_new_text: "",
            checkout_step_one_new_text_mobile: "",
            checkout_step_one_new_text_english: "",
            checkout_step_one_new_text_english_mobile: "",
            checkout_step_one_new_text_signed_in: "",
            checkout_step_one_new_text_signed_in_mobile: "",
            checkout_step_one_new_text_signed_in_english: "",
            checkout_step_one_new_text_signed_in_english_mobile: "",

            checkout_step_two_text_first: "",
            checkout_step_two_text_first_mobile: "",
            checkout_step_two_text_first_english: "",
            checkout_step_two_text_first_english_mobile: "",
            checkout_step_two_second_text: "",
            checkout_step_two_second_text_mobile: "",
            checkout_step_two_second_text_english: "",
            checkout_step_two_second_text_english_mobile: "",
            checkout_step_two_top_text: "",
            checkout_step_two_top_text_mobile: "",
            checkout_step_two_top_text_english: "",
            checkout_step_two_top_text_english_mobile: "",
            checkout_step_two_top_products_text: "",
            checkout_step_two_top_products_text_mobile: "",
            checkout_step_two_top_products_text_english: "",
            checkout_step_two_top_products_text_english_mobile: "",
            checkout_step_two_bottom_text: "",
            checkout_step_two_bottom_text_mobile: "",
            checkout_step_two_bottom_text_english: "",
            checkout_step_two_bottom_text_english_mobile: "",
            showFourEuro: false,

            // Products announcement sign up step two

            checkout_step_two_new_text_first: "",
            checkout_step_two_new_text_first_mobile: "",
            checkout_step_two_new_text_first_english: "",
            checkout_step_two_new_text_first_english_mobile: "",
            checkout_step_two_new_second_text: "",
            checkout_step_two_new_second_text_mobile: "",
            checkout_step_two_new_second_text_english: "",
            checkout_step_two_new_second_text_english_mobile: "",
            checkout_step_two_new_top_text: "",
            checkout_step_two_new_top_text_mobile: "",
            checkout_step_two_new_top_text_english: "",
            checkout_step_two_new_top_text_english_mobile: "",
            checkout_step_two_new_top_products_text: "",
            checkout_step_two_new_top_products_text_mobile: "",
            checkout_step_two_new_top_products_text_english: "",
            checkout_step_two_new_top_products_text_english_mobile: "",
            checkout_step_two_new_bottom_text: "",
            checkout_step_two_new_bottom_text_mobile: "",
            checkout_step_two_new_bottom_text_english: "",
            checkout_step_two_new_bottom_text_english_mobile: "",
            showFourEuroNew: false,

            checkout_confirmation_text: "",
            checkout_confirmation_text_mobile: "",
            checkout_confirmation_text_english: "",
            checkout_confirmation_text_english_mobile: "",
            checkout_confirmation_text_guest: "",
            checkout_confirmation_text_guest_mobile: "",
            checkout_confirmation_text_guest_english: "",
            checkout_confirmation_text_guest_english_mobile: "",
            checkout_confirmation_products_text: "",
            checkout_confirmation_products_text_mobile: "",
            checkout_confirmation_products_text_english: "",
            checkout_confirmation_products_text_english_mobile: "",

            showStuffNow: false,

            landing_page_new_dutch_description: "",
            landing_page_new_dutch_description_mobile: "",
            landing_page_new_english_description: "",
            landing_page_new_english_description_mobile: "",
            landing_page_new_dutch_point_one: "",
            landing_page_new_dutch_point_one_mobile: "",
            landing_page_new_english_point_one: "",
            landing_page_new_english_point_one_mobile: "",
            landing_page_new_dutch_point_two: "",
            landing_page_new_dutch_point_two_mobile: "",
            landing_page_new_english_point_two: "",
            landing_page_new_english_point_two_mobile: "",
            landing_page_new_dutch_point_three: "",
            landing_page_new_dutch_point_three_mobile: "",
            landing_page_new_english_point_three: "",
            landing_page_new_english_point_three_mobile: "",
        };
    }

    componentDidMount() {
        const loaderId = document.getElementById("main-page-loader-id");
        loaderId.style.display = "none";

        setTimeout(() => {
            this.setState({ showStuffNow: true });
        }, 100);

        if (this.props.global_values) {
            let a = this.props.global_values;
            if (a) {
                this.setState({
                    commercial_page_charity_title: a.commercial_page_charity_title || "",
                    commercial_page_charity_title_english: a.commercial_page_charity_title_english || "",
                    commercial_page_charity_title_mobile: a.commercial_page_charity_title_mobile || "",
                    commercial_page_charity_title_english_mobile: a.commercial_page_charity_title_english_mobile || "",
                    commercial_page_charity_first: a.commercial_page_charity_first || "",
                    commercial_page_charity_first_english: a.commercial_page_charity_first_english || "",
                    commercial_page_charity_first_mobile: a.commercial_page_charity_first_mobile || "",
                    commercial_page_charity_first_english_mobile: a.commercial_page_charity_first_english_mobile || "",
                    commercial_page_charity_second: a.commercial_page_charity_second || "",
                    commercial_page_charity_second_english: a.commercial_page_charity_second_english || "",
                    commercial_page_charity_second_mobile: a.commercial_page_charity_second_mobile || "",
                    commercial_page_charity_second_english_mobile: a.commercial_page_charity_second_english_mobile || "",
                    commercial_page_charity_third: a.commercial_page_charity_third || "",
                    commercial_page_charity_third_english: a.commercial_page_charity_third_english || "",
                    commercial_page_charity_third_mobile: a.commercial_page_charity_third_mobile || "",
                    commercial_page_charity_third_english_mobile: a.commercial_page_charity_third_english_mobile || "",

                    charity_page_new_events_dutch: a.charity_page_new_events_dutch || "",
                    charity_page_new_events_dutch_mobile: a.charity_page_new_events_dutch_mobile || "",
                    charity_page_new_events_english: a.charity_page_new_events_english || "",
                    charity_page_new_events_english_mobile: a.charity_page_new_events_english_mobile || "",
                    charity_page_new_events_dutch_heading: a.charity_page_new_events_dutch_heading || "",
                    charity_page_new_events_dutch_heading_mobile: a.charity_page_new_events_dutch_heading_mobile || "",
                    charity_page_new_events_english_heading: a.charity_page_new_events_english_heading || "",
                    charity_page_new_events_english_heading_mobile: a.charity_page_new_events_english_heading_mobile || "",


                    charity_page_events_dutch_headertxt: a.charity_page_events_dutch_headertxt || "",
                    charity_page_events_dutch_headertxt_mobile: a.charity_page_events_dutch_headertxt_mobile || "",
                    charity_page_events_english_headertxt: a.charity_page_events_english_headertxt || "",
                    charity_page_events_english_headertxt_mobile: a.charity_page_events_english_headertxt_mobile || "",
                    charity_page_events_dutch_heading: a.charity_page_events_dutch_heading || "",
                    charity_page_events_dutch_heading_mobile: a.charity_page_events_dutch_heading_mobile || "",
                    charity_page_events_english_heading: a.charity_page_events_english_heading || "",
                    charity_page_events_english_heading_mobile: a.charity_page_events_english_heading_mobile || "",

                    charity_page_events_dutch_heading_inner: a.charity_page_events_dutch_heading_inner || "",
                    charity_page_events_dutch_heading_inner_mobile: a.charity_page_events_dutch_heading_inner_mobile || "",
                    charity_page_events_english_heading_inner: a.charity_page_events_english_heading_inner || "",
                    charity_page_events_english_heading_inner_mobile: a.charity_page_events_english_heading_inner_mobile || "",
                    charity_page_events_dutch: a.charity_page_events_dutch || "",
                    charity_page_events_dutch_mobile: a.charity_page_events_dutch_mobile || "",
                    charity_page_events_english: a.charity_page_events_english || "",
                    charity_page_events_english_mobile: a.charity_page_events_english_mobile || "",

                    charity_page_connect_dutch_heading: a.charity_page_connect_dutch_heading || "",
                    charity_page_connect_dutch_heading_mobile: a.charity_page_connect_dutch_heading_mobile || "",
                    charity_page_connect_english_heading: a.charity_page_connect_english_heading || "",
                    charity_page_connect_english_heading_mobile: a.charity_page_connect_english_heading_mobile || "",
                    charity_page_connect_dutch: a.charity_page_connect_dutch || "",
                    charity_page_connect_dutch_mobile: a.charity_page_connect_dutch_mobile || "",
                    charity_page_connect_english: a.charity_page_connect_english || "",
                    charity_page_connect_english_mobile: a.charity_page_connect_english_mobile || "",

                    charity_page_profile_dutch_heading: a.charity_page_profile_dutch_heading || "",
                    charity_page_profile_dutch_heading_mobile: a.charity_page_profile_dutch_heading_mobile || "",
                    charity_page_profile_english_heading: a.charity_page_profile_english_heading || "",
                    charity_page_profile_english_heading_mobile: a.charity_page_profile_english_heading_mobile || "",
                    charity_page_profile_dutch: a.charity_page_profile_dutch || "",
                    charity_page_profile_dutch_mobile: a.charity_page_profile_dutch_mobile || "",
                    charity_page_profile_english: a.charity_page_profile_english || "",
                    charity_page_profile_english_mobile: a.charity_page_profile_english_mobile || "",

                    charity_page_about_dutch_heading: a.charity_page_about_dutch_heading || "",
                    charity_page_about_dutch_heading_mobile: a.charity_page_about_dutch_heading_mobile || "",
                    charity_page_about_english_heading: a.charity_page_about_english_heading || "",
                    charity_page_about_english_heading_mobile: a.charity_page_about_english_heading_mobile || "",
                    charity_page_about_dutch: a.charity_page_about_dutch || "",
                    charity_page_about_dutch_mobile: a.charity_page_about_dutch_mobile || "",
                    charity_page_about_english: a.charity_page_about_english || "",
                    charity_page_about_english_mobile: a.charity_page_about_english_mobile || "",

                    charity_page_team_bhodi_dutch_heading: a.charity_page_team_bhodi_dutch_heading || "",
                    charity_page_team_bhodi_dutch_heading_mobile: a.charity_page_team_bhodi_dutch_heading_mobile || "",
                    charity_page_team_bhodi_english_heading: a.charity_page_team_bhodi_english_heading || "",
                    charity_page_team_bhodi_english_heading_mobile: a.charity_page_team_bhodi_english_heading_mobile || "",
                    charity_page_team_bhodi_dutch: a.charity_page_team_bhodi_dutch || "",
                    charity_page_team_bhodi_dutch_mobile: a.charity_page_team_bhodi_dutch_mobile || "",
                    charity_page_team_bhodi_english: a.charity_page_team_bhodi_english || "",
                    charity_page_team_bhodi_english_mobile: a.charity_page_team_bhodi_english_mobile || "",

                    checkout_step_one_text_first: a.checkout_step_one_text_first || "",
                    checkout_step_one_text_first_mobile: a.checkout_step_one_text_first_mobile || "",
                    checkout_step_one_text_first_english: a.checkout_step_one_text_first_english || "",
                    checkout_step_one_text_first_english_mobile: a.checkout_step_one_text_first_english_mobile || "",
                    checkout_step_one_second_text: a.checkout_step_one_second_text || "",
                    checkout_step_one_second_text_mobile: a.checkout_step_one_second_text_mobile || "",
                    checkout_step_one_second_text_english: a.checkout_step_one_second_text_english || "",
                    checkout_step_one_second_text_english_mobile: a.checkout_step_one_second_text_english_mobile || "",
                    checkout_step_one_text_first_signed_in: a.checkout_step_one_text_first_signed_in || "",
                    checkout_step_one_text_first_signed_in_mobile: a.checkout_step_one_text_first_signed_in_mobile || "",
                    checkout_step_one_text_first_signed_in_english: a.checkout_step_one_text_first_signed_in_english || "",
                    checkout_step_one_text_first_signed_in_english_mobile: a.checkout_step_one_text_first_signed_in_english_mobile || "",
                    checkout_step_one_second_text_signed_in: a.checkout_step_one_second_text_signed_in || "",
                    checkout_step_one_second_text_signed_in_mobile: a.checkout_step_one_second_text_signed_in_mobile || "",
                    checkout_step_one_second_text_signed_in_english: a.checkout_step_one_second_text_signed_in_english || "",
                    checkout_step_one_second_text_signed_in_english_mobile: a.checkout_step_one_second_text_signed_in_english_mobile || "",
                    checkout_step_one_text: a.checkout_step_one_text || "",
                    checkout_step_one_text_mobile: a.checkout_step_one_text_mobile || "",
                    checkout_step_one_text_english: a.checkout_step_one_text_english || "",
                    checkout_step_one_text_english_mobile: a.checkout_step_one_text_english_mobile || "",
                    checkout_step_one_text_signed_in: a.checkout_step_one_text_signed_in || "",
                    checkout_step_one_text_signed_in_mobile: a.checkout_step_one_text_signed_in_mobile || "",
                    checkout_step_one_text_signed_in_english: a.checkout_step_one_text_signed_in_english || "",
                    checkout_step_one_text_signed_in_english_mobile: a.checkout_step_one_text_signed_in_english_mobile || "",

                    // Products announcement sign up step one
                    checkout_step_one_new_text_first: a.checkout_step_one_new_text_first || "",
                    checkout_step_one_new_text_first_mobile: a.checkout_step_one_new_text_first_mobile || "",
                    checkout_step_one_new_text_first_english: a.checkout_step_one_new_text_first_english || "",
                    checkout_step_one_new_text_first_english_mobile: a.checkout_step_one_new_text_first_english_mobile || "",
                    checkout_step_one_new_second_text: a.checkout_step_one_new_second_text || "",
                    checkout_step_one_new_second_text_mobile: a.checkout_step_one_new_second_text_mobile || "",
                    checkout_step_one_new_second_text_english: a.checkout_step_one_new_second_text_english || "",
                    checkout_step_one_new_second_text_english_mobile: a.checkout_step_one_new_second_text_english_mobile || "",
                    checkout_step_one_new_text_first_signed_in: a.checkout_step_one_new_text_first_signed_in || "",
                    checkout_step_one_new_text_first_signed_in_mobile: a.checkout_step_one_new_text_first_signed_in_mobile || "",
                    checkout_step_one_new_text_first_signed_in_english: a.checkout_step_one_new_text_first_signed_in_english || "",
                    checkout_step_one_new_text_first_signed_in_english_mobile: a.checkout_step_one_new_text_first_signed_in_english_mobile || "",
                    checkout_step_one_new_second_text_signed_in: a.checkout_step_one_new_second_text_signed_in || "",
                    checkout_step_one_new_second_text_signed_in_mobile: a.checkout_step_one_new_second_text_signed_in_mobile || "",
                    checkout_step_one_new_second_text_signed_in_english: a.checkout_step_one_new_second_text_signed_in_english || "",
                    checkout_step_one_new_second_text_signed_in_english_mobile: a.checkout_step_one_new_second_text_signed_in_english_mobile || "",
                    checkout_step_one_new_text: a.checkout_step_one_new_text || "",
                    checkout_step_one_new_text_mobile: a.checkout_step_one_new_text_mobile || "",
                    checkout_step_one_new_text_english: a.checkout_step_one_new_text_english || "",
                    checkout_step_one_new_text_english_mobile: a.checkout_step_one_new_text_english_mobile || "",
                    checkout_step_one_new_text_signed_in: a.checkout_step_one_new_text_signed_in || "",
                    checkout_step_one_new_text_signed_in_mobile: a.checkout_step_one_new_text_signed_in_mobile || "",
                    checkout_step_one_new_text_signed_in_english: a.checkout_step_one_new_text_signed_in_english || "",
                    checkout_step_one_new_text_signed_in_english_mobile: a.checkout_step_one_new_text_signed_in_english_mobile || "",


                    checkout_step_two_text_first: a.checkout_step_two_text_first || "",
                    checkout_step_two_text_first_mobile: a.checkout_step_two_text_first_mobile || "",
                    checkout_step_two_text_first_english: a.checkout_step_two_text_first_english || "",
                    checkout_step_two_text_first_english_mobile: a.checkout_step_two_text_first_english_mobile || "",
                    checkout_step_two_second_text: a.checkout_step_two_second_text || "",
                    checkout_step_two_second_text_mobile: a.checkout_step_two_second_text_mobile || "",
                    checkout_step_two_second_text_english: a.checkout_step_two_second_text_english || "",
                    checkout_step_two_second_text_english_mobile: a.checkout_step_two_second_text_english_mobile || "",
                    checkout_step_two_top_text: a.checkout_step_two_top_text || "",
                    checkout_step_two_top_text_mobile: a.checkout_step_two_top_text_mobile || "",
                    checkout_step_two_top_text_english: a.checkout_step_two_top_text_english || "",
                    checkout_step_two_top_text_english_mobile: a.checkout_step_two_top_text_english_mobile || "",
                    checkout_step_two_top_products_text: a.checkout_step_two_top_products_text || "",
                    checkout_step_two_top_products_text_mobile: a.checkout_step_two_top_products_text_mobile || "",
                    checkout_step_two_top_products_text_english: a.checkout_step_two_top_products_text_english || "",
                    checkout_step_two_top_products_text_english_mobile: a.checkout_step_two_top_products_text_english_mobile || "",
                    checkout_step_two_bottom_text: a.checkout_step_two_bottom_text || "",
                    checkout_step_two_bottom_text_mobile: a.checkout_step_two_bottom_text_mobile || "",
                    checkout_step_two_bottom_text_english: a.checkout_step_two_bottom_text_english || "",
                    checkout_step_two_bottom_text_english_mobile: a.checkout_step_two_bottom_text_english_mobile || "",
                    showFourEuro: a.showFourEuro || false,

                    // Products announcement sign up step two
                    checkout_step_two_new_text_first: a.checkout_step_two_new_text_first || "",
                    checkout_step_two_new_text_first_mobile: a.checkout_step_two_new_text_first_mobile || "",
                    checkout_step_two_new_text_first_english: a.checkout_step_two_new_text_first_english || "",
                    checkout_step_two_new_text_first_english_mobile: a.checkout_step_two_new_text_first_english_mobile || "",
                    checkout_step_two_new_second_text: a.checkout_step_two_new_second_text || "",
                    checkout_step_two_new_second_text_mobile: a.checkout_step_two_new_second_text_mobile || "",
                    checkout_step_two_new_second_text_english: a.checkout_step_two_new_second_text_english || "",
                    checkout_step_two_new_second_text_english_mobile: a.checkout_step_two_new_second_text_english_mobile || "",
                    checkout_step_two_new_top_text: a.checkout_step_two_new_top_text || "",
                    checkout_step_two_new_top_text_mobile: a.checkout_step_two_new_top_text_mobile || "",
                    checkout_step_two_new_top_text_english: a.checkout_step_two_new_top_text_english || "",
                    checkout_step_two_new_top_text_english_mobile: a.checkout_step_two_new_top_text_english_mobile || "",
                    checkout_step_two_new_top_products_text: a.checkout_step_two_new_top_products_text || "",
                    checkout_step_two_new_top_products_text_mobile: a.checkout_step_two_new_top_products_text_mobile || "",
                    checkout_step_two_new_top_products_text_english: a.checkout_step_two_new_top_products_text_english || "",
                    checkout_step_two_new_top_products_text_english_mobile: a.checkout_step_two_new_top_products_text_english_mobile || "",
                    checkout_step_two_new_bottom_text: a.checkout_step_two_new_bottom_text || "",
                    checkout_step_two_new_bottom_text_mobile: a.checkout_step_two_new_bottom_text_mobile || "",
                    checkout_step_two_new_bottom_text_english: a.checkout_step_two_new_bottom_text_english || "",
                    checkout_step_two_new_bottom_text_english_mobile: a.checkout_step_two_new_bottom_text_english_mobile || "",
                    showFourEuroNew: a.showFourEuroNew || false,

                    checkout_confirmation_text: a.checkout_confirmation_text || "",
                    checkout_confirmation_text_mobile: a.checkout_confirmation_text_mobile || "",
                    checkout_confirmation_text_english: a.checkout_confirmation_text_english || "",
                    checkout_confirmation_text_english_mobile: a.checkout_confirmation_text_english_mobile || "",
                    checkout_confirmation_text_guest: a.checkout_confirmation_text_guest || "",
                    checkout_confirmation_text_guest_mobile: a.checkout_confirmation_text_guest_mobile || "",
                    checkout_confirmation_text_guest_english: a.checkout_confirmation_text_guest_english || "",
                    checkout_confirmation_text_guest_english_mobile: a.checkout_confirmation_text_guest_english_mobile || "",
                    checkout_confirmation_products_text: a.checkout_confirmation_products_text || "",
                    checkout_confirmation_products_text_mobile: a.checkout_confirmation_products_text_mobile || "",
                    checkout_confirmation_products_text_english: a.checkout_confirmation_products_text_english || "",
                    checkout_confirmation_products_text_english_mobile: a.checkout_confirmation_products_text_english_mobile || "",


                    landing_page_new_dutch_description: a.landing_page_new_dutch_description || "",
                    landing_page_new_dutch_description_mobile: a.landing_page_new_dutch_description_mobile || "",
                    landing_page_new_english_description: a.landing_page_new_english_description || "",
                    landing_page_new_english_description_mobile: a.landing_page_new_english_description_mobile || "",
                    landing_page_new_dutch_point_one: a.landing_page_new_dutch_point_one || "",
                    landing_page_new_dutch_point_one_mobile: a.landing_page_new_dutch_point_one_mobile || "",
                    landing_page_new_english_point_one: a.landing_page_new_english_point_one || "",
                    landing_page_new_english_point_one_mobile: a.landing_page_new_english_point_one_mobile || "",
                    landing_page_new_dutch_point_two: a.landing_page_new_dutch_point_two || "",
                    landing_page_new_dutch_point_two_mobile: a.landing_page_new_dutch_point_two_mobile || "",
                    landing_page_new_english_point_two: a.landing_page_new_english_point_two || "",
                    landing_page_new_english_point_two_mobile: a.landing_page_new_english_point_two_mobile || "",
                    landing_page_new_dutch_point_three: a.landing_page_new_dutch_point_three || "",
                    landing_page_new_dutch_point_three_mobile: a.landing_page_new_dutch_point_three_mobile || "",
                    landing_page_new_english_point_three: a.landing_page_new_english_point_three || "",
                    landing_page_new_english_point_three_mobile: a.landing_page_new_english_point_three_mobile || "",
                });
            }
        }

        firebase
            .database()
            .ref("bhodi_home")
            .on("value", (data) => {
                let a = data.val();
                if (a) {
                    this.props.updateGlobalStuff(a);
                    this.setState({
                        commercial_page_charity_title: a.commercial_page_charity_title || "",
                        commercial_page_charity_title_english: a.commercial_page_charity_title_english || "",
                        commercial_page_charity_title_mobile: a.commercial_page_charity_title_mobile || "",
                        commercial_page_charity_title_english_mobile: a.commercial_page_charity_title_english_mobile || "",
                        commercial_page_charity_first: a.commercial_page_charity_first || "",
                        commercial_page_charity_first_english: a.commercial_page_charity_first_english || "",
                        commercial_page_charity_first_mobile: a.commercial_page_charity_first_mobile || "",
                        commercial_page_charity_first_english_mobile: a.commercial_page_charity_first_english_mobile || "",
                        commercial_page_charity_second: a.commercial_page_charity_second || "",
                        commercial_page_charity_second_english: a.commercial_page_charity_second_english || "",
                        commercial_page_charity_second_mobile: a.commercial_page_charity_second_mobile || "",
                        commercial_page_charity_second_english_mobile: a.commercial_page_charity_second_english_mobile || "",
                        commercial_page_charity_third: a.commercial_page_charity_third || "",
                        commercial_page_charity_third_english: a.commercial_page_charity_third_english || "",
                        commercial_page_charity_third_mobile: a.commercial_page_charity_third_mobile || "",
                        commercial_page_charity_third_english_mobile: a.commercial_page_charity_third_english_mobile || "",

                        charity_page_new_events_dutch: a.charity_page_new_events_dutch || "",
                        charity_page_new_events_dutch_mobile: a.charity_page_new_events_dutch_mobile || "",
                        charity_page_new_events_english: a.charity_page_new_events_english || "",
                        charity_page_new_events_english_mobile: a.charity_page_new_events_english_mobile || "",
                        charity_page_new_events_dutch_heading: a.charity_page_new_events_dutch_heading || "",
                        charity_page_new_events_dutch_heading_mobile: a.charity_page_new_events_dutch_heading_mobile || "",
                        charity_page_new_events_english_heading: a.charity_page_new_events_english_heading || "",
                        charity_page_new_events_english_heading_mobile: a.charity_page_new_events_english_heading_mobile || "",

                        charity_page_events_dutch_headertxt: a.charity_page_events_dutch_headertxt || "",
                        charity_page_events_dutch_headertxt_mobile: a.charity_page_events_dutch_headertxt_mobile || "",
                        charity_page_events_english_headertxt: a.charity_page_events_english_headertxt || "",
                        charity_page_events_english_headertxt_mobile: a.charity_page_events_english_headertxt_mobile || "",
                        charity_page_events_dutch_heading: a.charity_page_events_dutch_heading || "",
                        charity_page_events_dutch_heading_mobile: a.charity_page_events_dutch_heading_mobile || "",
                        charity_page_events_english_heading: a.charity_page_events_english_heading || "",
                        charity_page_events_english_heading_mobile: a.charity_page_events_english_heading_mobile || "",

                        charity_page_events_dutch_heading_inner: a.charity_page_events_dutch_heading_inner || "",
                        charity_page_events_dutch_heading_inner_mobile: a.charity_page_events_dutch_heading_inner_mobile || "",
                        charity_page_events_english_heading_inner: a.charity_page_events_english_heading_inner || "",
                        charity_page_events_english_heading_inner_mobile: a.charity_page_events_english_heading_inner_mobile || "",
                        charity_page_events_dutch: a.charity_page_events_dutch || "",
                        charity_page_events_dutch_mobile: a.charity_page_events_dutch_mobile || "",
                        charity_page_events_english: a.charity_page_events_english || "",
                        charity_page_events_english_mobile: a.charity_page_events_english_mobile || "",

                        charity_page_connect_dutch_heading: a.charity_page_connect_dutch_heading || "",
                        charity_page_connect_dutch_heading_mobile: a.charity_page_connect_dutch_heading_mobile || "",
                        charity_page_connect_english_heading: a.charity_page_connect_english_heading || "",
                        charity_page_connect_english_heading_mobile: a.charity_page_connect_english_heading_mobile || "",
                        charity_page_connect_dutch: a.charity_page_connect_dutch || "",
                        charity_page_connect_dutch_mobile: a.charity_page_connect_dutch_mobile || "",
                        charity_page_connect_english: a.charity_page_connect_english || "",
                        charity_page_connect_english_mobile: a.charity_page_connect_english_mobile || "",

                        charity_page_profile_dutch_heading: a.charity_page_profile_dutch_heading || "",
                        charity_page_profile_dutch_heading_mobile: a.charity_page_profile_dutch_heading_mobile || "",
                        charity_page_profile_english_heading: a.charity_page_profile_english_heading || "",
                        charity_page_profile_english_heading_mobile: a.charity_page_profile_english_heading_mobile || "",
                        charity_page_profile_dutch: a.charity_page_profile_dutch || "",
                        charity_page_profile_dutch_mobile: a.charity_page_profile_dutch_mobile || "",
                        charity_page_profile_english: a.charity_page_profile_english || "",
                        charity_page_profile_english_mobile: a.charity_page_profile_english_mobile || "",

                        charity_page_about_dutch_heading: a.charity_page_about_dutch_heading || "",
                        charity_page_about_dutch_heading_mobile: a.charity_page_about_dutch_heading_mobile || "",
                        charity_page_about_english_heading: a.charity_page_about_english_heading || "",
                        charity_page_about_english_heading_mobile: a.charity_page_about_english_heading_mobile || "",
                        charity_page_about_dutch: a.charity_page_about_dutch || "",
                        charity_page_about_dutch_mobile: a.charity_page_about_dutch_mobile || "",
                        charity_page_about_english: a.charity_page_about_english || "",
                        charity_page_about_english_mobile: a.charity_page_about_english_mobile || "",

                        charity_page_team_bhodi_dutch_heading: a.charity_page_team_bhodi_dutch_heading || "",
                        charity_page_team_bhodi_dutch_heading_mobile: a.charity_page_team_bhodi_dutch_heading_mobile || "",
                        charity_page_team_bhodi_english_heading: a.charity_page_team_bhodi_english_heading || "",
                        charity_page_team_bhodi_english_heading_mobile: a.charity_page_team_bhodi_english_heading_mobile || "",
                        charity_page_team_bhodi_dutch: a.charity_page_team_bhodi_dutch || "",
                        charity_page_team_bhodi_dutch_mobile: a.charity_page_team_bhodi_dutch_mobile || "",
                        charity_page_team_bhodi_english: a.charity_page_team_bhodi_english || "",
                        charity_page_team_bhodi_english_mobile: a.charity_page_team_bhodi_english_mobile || "",

                        checkout_step_one_text_first: a.checkout_step_one_text_first || "",
                        checkout_step_one_text_first_mobile: a.checkout_step_one_text_first_mobile || "",
                        checkout_step_one_text_first_english: a.checkout_step_one_text_first_english || "",
                        checkout_step_one_text_first_english_mobile: a.checkout_step_one_text_first_english_mobile || "",
                        checkout_step_one_second_text: a.checkout_step_one_second_text || "",
                        checkout_step_one_second_text_mobile: a.checkout_step_one_second_text_mobile || "",
                        checkout_step_one_second_text_english: a.checkout_step_one_second_text_english || "",
                        checkout_step_one_second_text_english_mobile: a.checkout_step_one_second_text_english_mobile || "",
                        checkout_step_one_text_first_signed_in: a.checkout_step_one_text_first_signed_in || "",
                        checkout_step_one_text_first_signed_in_mobile: a.checkout_step_one_text_first_signed_in_mobile || "",
                        checkout_step_one_text_first_signed_in_english: a.checkout_step_one_text_first_signed_in_english || "",
                        checkout_step_one_text_first_signed_in_english_mobile: a.checkout_step_one_text_first_signed_in_english_mobile || "",
                        checkout_step_one_second_text_signed_in: a.checkout_step_one_second_text_signed_in || "",
                        checkout_step_one_second_text_signed_in_mobile: a.checkout_step_one_second_text_signed_in_mobile || "",
                        checkout_step_one_second_text_signed_in_english: a.checkout_step_one_second_text_signed_in_english || "",
                        checkout_step_one_second_text_signed_in_english_mobile: a.checkout_step_one_second_text_signed_in_english_mobile || "",
                        checkout_step_one_text: a.checkout_step_one_text || "",
                        checkout_step_one_text_mobile: a.checkout_step_one_text_mobile || "",
                        checkout_step_one_text_english: a.checkout_step_one_text_english || "",
                        checkout_step_one_text_english_mobile: a.checkout_step_one_text_english_mobile || "",
                        checkout_step_one_text_signed_in: a.checkout_step_one_text_signed_in || "",
                        checkout_step_one_text_signed_in_mobile: a.checkout_step_one_text_signed_in_mobile || "",
                        checkout_step_one_text_signed_in_english: a.checkout_step_one_text_signed_in_english || "",
                        checkout_step_one_text_signed_in_english_mobile: a.checkout_step_one_text_signed_in_english_mobile || "",

                        // Products announcement sign up step one
                        checkout_step_one_new_text_first: a.checkout_step_one_new_text_first || "",
                        checkout_step_one_new_text_first_mobile: a.checkout_step_one_new_text_first_mobile || "",
                        checkout_step_one_new_text_first_english: a.checkout_step_one_new_text_first_english || "",
                        checkout_step_one_new_text_first_english_mobile: a.checkout_step_one_new_text_first_english_mobile || "",
                        checkout_step_one_new_second_text: a.checkout_step_one_new_second_text || "",
                        checkout_step_one_new_second_text_mobile: a.checkout_step_one_new_second_text_mobile || "",
                        checkout_step_one_new_second_text_english: a.checkout_step_one_new_second_text_english || "",
                        checkout_step_one_new_second_text_english_mobile: a.checkout_step_one_new_second_text_english_mobile || "",
                        checkout_step_one_new_text_first_signed_in: a.checkout_step_one_new_text_first_signed_in || "",
                        checkout_step_one_new_text_first_signed_in_mobile: a.checkout_step_one_new_text_first_signed_in_mobile || "",
                        checkout_step_one_new_text_first_signed_in_english: a.checkout_step_one_new_text_first_signed_in_english || "",
                        checkout_step_one_new_text_first_signed_in_english_mobile: a.checkout_step_one_new_text_first_signed_in_english_mobile || "",
                        checkout_step_one_new_second_text_signed_in: a.checkout_step_one_new_second_text_signed_in || "",
                        checkout_step_one_new_second_text_signed_in_mobile: a.checkout_step_one_new_second_text_signed_in_mobile || "",
                        checkout_step_one_new_second_text_signed_in_english: a.checkout_step_one_new_second_text_signed_in_english || "",
                        checkout_step_one_new_second_text_signed_in_english_mobile: a.checkout_step_one_new_second_text_signed_in_english_mobile || "",
                        checkout_step_one_new_text: a.checkout_step_one_new_text || "",
                        checkout_step_one_new_text_mobile: a.checkout_step_one_new_text_mobile || "",
                        checkout_step_one_new_text_english: a.checkout_step_one_new_text_english || "",
                        checkout_step_one_new_text_english_mobile: a.checkout_step_one_new_text_english_mobile || "",
                        checkout_step_one_new_text_signed_in: a.checkout_step_one_new_text_signed_in || "",
                        checkout_step_one_new_text_signed_in_mobile: a.checkout_step_one_new_text_signed_in_mobile || "",
                        checkout_step_one_new_text_signed_in_english: a.checkout_step_one_new_text_signed_in_english || "",
                        checkout_step_one_new_text_signed_in_english_mobile: a.checkout_step_one_new_text_signed_in_english_mobile || "",

                        checkout_step_two_text_first: a.checkout_step_two_text_first || "",
                        checkout_step_two_text_first_mobile: a.checkout_step_two_text_first_mobile || "",
                        checkout_step_two_text_first_english: a.checkout_step_two_text_first_english || "",
                        checkout_step_two_text_first_english_mobile: a.checkout_step_two_text_first_english_mobile || "",
                        checkout_step_two_second_text: a.checkout_step_two_second_text || "",
                        checkout_step_two_second_text_mobile: a.checkout_step_two_second_text_mobile || "",
                        checkout_step_two_second_text_english: a.checkout_step_two_second_text_english || "",
                        checkout_step_two_second_text_english_mobile: a.checkout_step_two_second_text_english_mobile || "",
                        checkout_step_two_top_text: a.checkout_step_two_top_text || "",
                        checkout_step_two_top_text_mobile: a.checkout_step_two_top_text_mobile || "",
                        checkout_step_two_top_text_english: a.checkout_step_two_top_text_english || "",
                        checkout_step_two_top_text_english_mobile: a.checkout_step_two_top_text_english_mobile || "",
                        checkout_step_two_top_products_text: a.checkout_step_two_top_products_text || "",
                        checkout_step_two_top_products_text_mobile: a.checkout_step_two_top_products_text_mobile || "",
                        checkout_step_two_top_products_text_english: a.checkout_step_two_top_products_text_english || "",
                        checkout_step_two_top_products_text_english_mobile: a.checkout_step_two_top_products_text_english_mobile || "",
                        checkout_step_two_bottom_text: a.checkout_step_two_bottom_text || "",
                        checkout_step_two_bottom_text_mobile: a.checkout_step_two_bottom_text_mobile || "",
                        checkout_step_two_bottom_text_english: a.checkout_step_two_bottom_text_english || "",
                        checkout_step_two_bottom_text_english_mobile: a.checkout_step_two_bottom_text_english_mobile || "",
                        showFourEuro: a.showFourEuro || false,


                        // Products announcement sign up step two
                        checkout_step_two_new_text_first: a.checkout_step_two_new_text_first || "",
                        checkout_step_two_new_text_first_mobile: a.checkout_step_two_new_text_first_mobile || "",
                        checkout_step_two_new_text_first_english: a.checkout_step_two_new_text_first_english || "",
                        checkout_step_two_new_text_first_english_mobile: a.checkout_step_two_new_text_first_english_mobile || "",
                        checkout_step_two_new_second_text: a.checkout_step_two_new_second_text || "",
                        checkout_step_two_new_second_text_mobile: a.checkout_step_two_new_second_text_mobile || "",
                        checkout_step_two_new_second_text_english: a.checkout_step_two_new_second_text_english || "",
                        checkout_step_two_new_second_text_english_mobile: a.checkout_step_two_new_second_text_english_mobile || "",
                        checkout_step_two_new_top_text: a.checkout_step_two_new_top_text || "",
                        checkout_step_two_new_top_text_mobile: a.checkout_step_two_new_top_text_mobile || "",
                        checkout_step_two_new_top_text_english: a.checkout_step_two_new_top_text_english || "",
                        checkout_step_two_new_top_text_english_mobile: a.checkout_step_two_new_top_text_english_mobile || "",
                        checkout_step_two_new_top_products_text: a.checkout_step_two_new_top_products_text || "",
                        checkout_step_two_new_top_products_text_mobile: a.checkout_step_two_new_top_products_text_mobile || "",
                        checkout_step_two_new_top_products_text_english: a.checkout_step_two_new_top_products_text_english || "",
                        checkout_step_two_new_top_products_text_english_mobile: a.checkout_step_two_new_top_products_text_english_mobile || "",
                        checkout_step_two_new_bottom_text: a.checkout_step_two_new_bottom_text || "",
                        checkout_step_two_new_bottom_text_mobile: a.checkout_step_two_new_bottom_text_mobile || "",
                        checkout_step_two_new_bottom_text_english: a.checkout_step_two_new_bottom_text_english || "",
                        checkout_step_two_new_bottom_text_english_mobile: a.checkout_step_two_new_bottom_text_english_mobile || "",
                        showFourEuroNew: a.showFourEuroNew || false,

                        checkout_confirmation_text: a.checkout_confirmation_text || "",
                        checkout_confirmation_text_mobile: a.checkout_confirmation_text_mobile || "",
                        checkout_confirmation_text_english: a.checkout_confirmation_text_english || "",
                        checkout_confirmation_text_english_mobile: a.checkout_confirmation_text_english_mobile || "",
                        checkout_confirmation_text_guest: a.checkout_confirmation_text_guest || "",
                        checkout_confirmation_text_guest_mobile: a.checkout_confirmation_text_guest_mobile || "",
                        checkout_confirmation_text_guest_english: a.checkout_confirmation_text_guest_english || "",
                        checkout_confirmation_text_guest_english_mobile: a.checkout_confirmation_text_guest_english_mobile || "",
                        checkout_confirmation_products_text: a.checkout_confirmation_products_text || "",
                        checkout_confirmation_products_text_mobile: a.checkout_confirmation_products_text_mobile || "",
                        checkout_confirmation_products_text_english: a.checkout_confirmation_products_text_english || "",
                        checkout_confirmation_products_text_english_mobile: a.checkout_confirmation_products_text_english_mobile || "",

                        landing_page_new_dutch_description: a.landing_page_new_dutch_description || "",
                        landing_page_new_dutch_description_mobile: a.landing_page_new_dutch_description_mobile || "",
                        landing_page_new_english_description: a.landing_page_new_english_description || "",
                        landing_page_new_english_description_mobile: a.landing_page_new_english_description_mobile || "",
                        landing_page_new_dutch_point_one: a.landing_page_new_dutch_point_one || "",
                        landing_page_new_dutch_point_one_mobile: a.landing_page_new_dutch_point_one_mobile || "",
                        landing_page_new_english_point_one: a.landing_page_new_english_point_one || "",
                        landing_page_new_english_point_one_mobile: a.landing_page_new_english_point_one_mobile || "",
                        landing_page_new_dutch_point_two: a.landing_page_new_dutch_point_two || "",
                        landing_page_new_dutch_point_two_mobile: a.landing_page_new_english_point_two || "",
                        landing_page_new_english_point_two: a.landing_page_new_english_point_two || "",
                        landing_page_new_english_point_two_mobile: a.landing_page_new_english_point_two_mobile || "",
                        landing_page_new_dutch_point_three: a.landing_page_new_dutch_point_three || "",
                        landing_page_new_dutch_point_three_mobile: a.landing_page_new_dutch_point_three_mobile || "",
                        landing_page_new_english_point_three: a.landing_page_new_english_point_three || "",
                        landing_page_new_english_point_three_mobile: a.landing_page_new_english_point_three_mobile || "",
                    });
                }
            });
    }

    saveValueToDb(storeAt, e) {
        firebase.database().ref(`bhodi_home/${storeAt}`).set(e)
    }

    fileUploadToDB(e, storeAt) {
        let files = e.target.files[0]
        let storageRef = firebase.storage().ref().child(`bhodi_home_media/${files.name}${new Date().getTime()}`)
        storageRef.put(files)
            .then((snapshot) => {
                snapshot.ref.getDownloadURL().then((snapUrl) => {
                    firebase.database().ref(`bhodi_home/${storeAt}/`).set(snapUrl).then(() => {
                    })
                })
            })
    }

    fileUploadToDBLandingpage(e, storeAt) {
        let files = e.target.files[0]
        let storageRef = firebase.storage().ref().child(`bhodi_home_media/${files.name}${new Date().getTime()}`)
        storageRef.put(files)
            .then((snapshot) => {
                snapshot.ref.getDownloadURL().then((snapUrl) => {
                    firebase.database().ref(`bhodi_home/${storeAt}/`).set(snapUrl)
                    if (storeAt === "landing_page_new_bg_media") {
                        firebase.database().ref(`bhodi_home/landing_page_media_type`).set(files.type)
                    } else {
                        firebase.database().ref(`bhodi_home/landing_page_media_type_mobile`).set(files.type)
                    }
                })
            })
    }


    render() {
        return (
            <div className={styles.popupEditorMainPage}>
                <div className="buttons-container-displayeditor" style={{ maxWidth: "none", margin: 0, display: "flex", justifyContent: "flex-end" }}>
                    <button
                        className="buttonsgreendisplay"
                        onClick={() => {
                            this.props.onClose()
                        }}
                    >
                        Close
                    </button>
                </div>
                {this.state.showStuffNow && this.props.type === "landing_page_first_section" && (<div className={styles.mainContainerParent}>
                    <div className={styles.innerBoxes}>
                        <label>Description Dutch</label>
                        <CK data={this.state.landing_page_new_dutch_description} onChildChange={this.saveValueToDb.bind(this, "landing_page_new_dutch_description")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Description Dutch Mobile</label>
                        <CK data={this.state.landing_page_new_dutch_description_mobile} onChildChange={this.saveValueToDb.bind(this, "landing_page_new_dutch_description_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Description English</label>
                        <CK data={this.state.landing_page_new_english_description} onChildChange={this.saveValueToDb.bind(this, "landing_page_new_english_description")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Description English Mobile</label>
                        <CK data={this.state.landing_page_new_english_description_mobile} onChildChange={this.saveValueToDb.bind(this, "landing_page_new_english_description_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Point One Dutch</label>
                        <CK data={this.state.landing_page_new_dutch_point_one} onChildChange={this.saveValueToDb.bind(this, "landing_page_new_dutch_point_one")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Point One Dutch Mobile</label>
                        <CK data={this.state.landing_page_new_dutch_point_one_mobile} onChildChange={this.saveValueToDb.bind(this, "landing_page_new_dutch_point_one_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Point One English</label>
                        <CK data={this.state.landing_page_new_english_point_one} onChildChange={this.saveValueToDb.bind(this, "landing_page_new_english_point_one")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Point One English Mobile</label>
                        <CK data={this.state.landing_page_new_english_point_one_mobile} onChildChange={this.saveValueToDb.bind(this, "landing_page_new_english_point_one_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Point Two Dutch</label>
                        <CK data={this.state.landing_page_new_dutch_point_two} onChildChange={this.saveValueToDb.bind(this, "landing_page_new_dutch_point_two")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Point Two Dutch Mobile</label>
                        <CK data={this.state.landing_page_new_dutch_point_two_mobile} onChildChange={this.saveValueToDb.bind(this, "landing_page_new_dutch_point_two_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Point Two English</label>
                        <CK data={this.state.landing_page_new_english_point_two} onChildChange={this.saveValueToDb.bind(this, "landing_page_new_english_point_two")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Point Two English Mobile</label>
                        <CK data={this.state.landing_page_new_english_point_two_mobile} onChildChange={this.saveValueToDb.bind(this, "landing_page_new_english_point_two_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Point Three Dutch</label>
                        <CK data={this.state.landing_page_new_dutch_point_three} onChildChange={this.saveValueToDb.bind(this, "landing_page_new_dutch_point_three")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Point Three Dutch Mobile</label>
                        <CK data={this.state.landing_page_new_dutch_point_three_mobile} onChildChange={this.saveValueToDb.bind(this, "landing_page_new_dutch_point_three_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Point Three English</label>
                        <CK data={this.state.landing_page_new_english_point_three} onChildChange={this.saveValueToDb.bind(this, "landing_page_new_english_point_three")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Point Three English Mobile</label>
                        <CK data={this.state.landing_page_new_english_point_three_mobile} onChildChange={this.saveValueToDb.bind(this, "landing_page_new_english_point_three_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Main logo icon</label>
                        <input
                            type="file"
                            style={{ padding: 15, border: "1px solid gray" }}
                            accept="image/*"
                            onChange={(e) => this.fileUploadToDB(e, "landing_page_new_logo_main")}
                        />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Main logo icon mobile</label>
                        <input
                            type="file"
                            style={{ padding: 15, border: "1px solid gray" }}
                            accept="image/*"
                            onChange={(e) => this.fileUploadToDB(e, "landing_page_new_logo_main_mobile")}
                        />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Small logo icon</label>
                        <input
                            type="file"
                            style={{ padding: 15, border: "1px solid gray" }}
                            accept="image/*"
                            onChange={(e) => this.fileUploadToDB(e, "landing_page_new_logo_small")}
                        />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Small logo icon mobile</label>
                        <input
                            type="file"
                            style={{ padding: 15, border: "1px solid gray" }}
                            accept="image/*"
                            onChange={(e) => this.fileUploadToDB(e, "landing_page_new_logo_small_mobile")}
                        />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Play icon</label>
                        <input
                            type="file"
                            style={{ padding: 15, border: "1px solid gray" }}
                            accept="image/*"
                            onChange={(e) => this.fileUploadToDB(e, "landing_page_new_logo_play")}
                        />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Play icon mobile</label>
                        <input
                            type="file"
                            style={{ padding: 15, border: "1px solid gray" }}
                            accept="image/*"
                            onChange={(e) => this.fileUploadToDB(e, "landing_page_new_logo_play_mobile")}
                        />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Background Image/Video</label>
                        <input
                            type="file"
                            style={{ padding: 15, border: "1px solid gray" }}
                            accept="image/*, video/*"
                            onChange={(e) => this.fileUploadToDBLandingpage(e, "landing_page_new_bg_media")}
                        />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Background Image/Video Mobile</label>
                        <input
                            type="file"
                            style={{ padding: 15, border: "1px solid gray" }}
                            accept="image/*, video/*"
                            onChange={(e) => this.fileUploadToDBLandingpage(e, "landing_page_new_bg_media_mobile")}
                        />
                    </div>
                </div>)}
                {this.state.showStuffNow && this.props.type === "charity_commercial_first_section" && (<div className={styles.mainContainerParent}>
                    <div className={styles.innerBoxes}>
                        <label>Heading Input Dutch</label>
                        <CK data={this.state.charity_page_new_events_dutch_heading} onChildChange={this.saveValueToDb.bind(this, "charity_page_new_events_dutch_heading")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Heading Input Dutch Mobile</label>
                        <CK data={this.state.charity_page_new_events_dutch_heading_mobile} onChildChange={this.saveValueToDb.bind(this, "charity_page_new_events_dutch_heading_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Heading Input English</label>
                        <CK data={this.state.charity_page_new_events_english_heading} onChildChange={this.saveValueToDb.bind(this, "charity_page_new_events_english_heading")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Heading Input English Mobile</label>
                        <CK data={this.state.charity_page_new_events_english_heading_mobile} onChildChange={this.saveValueToDb.bind(this, "charity_page_new_events_english_heading_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Desc Input Dutch</label>
                        <CK data={this.state.charity_page_new_events_dutch} onChildChange={this.saveValueToDb.bind(this, "charity_page_new_events_dutch")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Desc Input Dutch Mobile</label>
                        <CK data={this.state.charity_page_new_events_dutch_mobile} onChildChange={this.saveValueToDb.bind(this, "charity_page_new_events_dutch_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Desc Input English</label>
                        <CK data={this.state.charity_page_new_events_english} onChildChange={this.saveValueToDb.bind(this, "charity_page_new_events_english")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Desc Input English Mobile</label>
                        <CK data={this.state.charity_page_new_events_english_mobile} onChildChange={this.saveValueToDb.bind(this, "charity_page_new_events_english_mobile")} />
                    </div>
                </div>)}
                {this.state.showStuffNow && this.props.type === "charity_commercial_second_section" && (<div className={styles.mainContainerParent}>
                    <div className={styles.innerBoxes}>
                        <label>Title Input Dutch</label>
                        <CK data={this.state.commercial_page_charity_title} onChildChange={this.saveValueToDb.bind(this, "commercial_page_charity_title")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Title Input Dutch Mobile</label>
                        <CK data={this.state.commercial_page_charity_title_mobile} onChildChange={this.saveValueToDb.bind(this, "commercial_page_charity_title_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Title Input English</label>
                        <CK data={this.state.commercial_page_charity_title_english} onChildChange={this.saveValueToDb.bind(this, "commercial_page_charity_title_english")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Title Input English Mobile</label>
                        <CK data={this.state.commercial_page_charity_title_english_mobile} onChildChange={this.saveValueToDb.bind(this, "commercial_page_charity_title_english_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>First Input Dutch</label>
                        <CK data={this.state.commercial_page_charity_first} onChildChange={this.saveValueToDb.bind(this, "commercial_page_charity_first")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>First Input Dutch Mobile</label>
                        <CK data={this.state.commercial_page_charity_first_mobile} onChildChange={this.saveValueToDb.bind(this, "commercial_page_charity_first_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>First Input English</label>
                        <CK data={this.state.commercial_page_charity_first_english} onChildChange={this.saveValueToDb.bind(this, "commercial_page_charity_first_english")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>First Input English Mobile</label>
                        <CK data={this.state.commercial_page_charity_first_english_mobile} onChildChange={this.saveValueToDb.bind(this, "commercial_page_charity_first_english_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Second Input Dutch</label>
                        <CK data={this.state.commercial_page_charity_second} onChildChange={this.saveValueToDb.bind(this, "commercial_page_charity_second")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Second Input Dutch Mobile</label>
                        <CK data={this.state.commercial_page_charity_second_mobile} onChildChange={this.saveValueToDb.bind(this, "commercial_page_charity_second_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Second Input English</label>
                        <CK data={this.state.commercial_page_charity_second_english} onChildChange={this.saveValueToDb.bind(this, "commercial_page_charity_second_english")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Second Input English Mobile</label>
                        <CK data={this.state.commercial_page_charity_second_english_mobile} onChildChange={this.saveValueToDb.bind(this, "commercial_page_charity_second_english_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Third Input Dutch</label>
                        <CK data={this.state.commercial_page_charity_third} onChildChange={this.saveValueToDb.bind(this, "commercial_page_charity_third")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Third Input Dutch Mobile</label>
                        <CK data={this.state.commercial_page_charity_third_mobile} onChildChange={this.saveValueToDb.bind(this, "commercial_page_charity_third_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Third Input English</label>
                        <CK data={this.state.commercial_page_charity_third_english} onChildChange={this.saveValueToDb.bind(this, "commercial_page_charity_third_english")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Third Input English Mobile</label>
                        <CK data={this.state.commercial_page_charity_third_english_mobile} onChildChange={this.saveValueToDb.bind(this, "commercial_page_charity_third_english_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Video Section</label>
                        <input
                            type="file"
                            style={{ padding: 15, border: "1px solid gray" }}
                            accept="video/*"
                            onChange={(e) => this.fileUploadToDB(e, "charity_page_charity_image")}
                        />
                    </div>
                </div>)}
                {this.state.showStuffNow && this.props.type === "charity_commercial_third_section" && (<div className={styles.mainContainerParent}>
                    <div className={styles.innerBoxes}>
                        <label>Heading Input Dutch</label>
                        <CK data={this.state.charity_page_events_dutch_heading} onChildChange={this.saveValueToDb.bind(this, "charity_page_events_dutch_heading")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Heading Input Dutch Mobile</label>
                        <CK data={this.state.charity_page_events_dutch_heading_mobile} onChildChange={this.saveValueToDb.bind(this, "charity_page_events_dutch_heading_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Heading Input English</label>
                        <CK data={this.state.charity_page_events_english_heading} onChildChange={this.saveValueToDb.bind(this, "charity_page_events_english_heading")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Heading Input English Mobile</label>
                        <CK data={this.state.charity_page_events_english_heading_mobile} onChildChange={this.saveValueToDb.bind(this, "charity_page_events_english_heading_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Desc Input Dutch</label>
                        <CK data={this.state.charity_page_events_dutch_headertxt} onChildChange={this.saveValueToDb.bind(this, "charity_page_events_dutch_headertxt")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Desc Input Dutch Mobile</label>
                        <CK data={this.state.charity_page_events_dutch_headertxt_mobile} onChildChange={this.saveValueToDb.bind(this, "charity_page_events_dutch_headertxt_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Desc Input English</label>
                        <CK data={this.state.charity_page_events_english_headertxt} onChildChange={this.saveValueToDb.bind(this, "charity_page_events_english_headertxt")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Desc Input English Mobile</label>
                        <CK data={this.state.charity_page_events_english_headertxt_mobile} onChildChange={this.saveValueToDb.bind(this, "charity_page_events_english_headertxt_mobile")} />
                    </div>
                </div>)}
                {this.state.showStuffNow && this.props.type === "charity_commercial_fourth_section" && (<div className={styles.mainContainerParent}>
                    <div className={styles.innerBoxes}>
                        <label>Heading Input Dutch</label>
                        <CK data={this.state.charity_page_events_dutch_heading_inner} onChildChange={this.saveValueToDb.bind(this, "charity_page_events_dutch_heading_inner")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Heading Input Dutch Mobile</label>
                        <CK data={this.state.charity_page_events_dutch_heading_inner_mobile} onChildChange={this.saveValueToDb.bind(this, "charity_page_events_dutch_heading_inner_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Heading Input English</label>
                        <CK data={this.state.charity_page_events_english_heading_inner} onChildChange={this.saveValueToDb.bind(this, "charity_page_events_english_heading_inner")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Heading Input English Mobile</label>
                        <CK data={this.state.charity_page_events_english_heading_inner_mobile} onChildChange={this.saveValueToDb.bind(this, "charity_page_events_english_heading_inner_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Desc Input Dutch</label>
                        <CK data={this.state.charity_page_events_dutch} onChildChange={this.saveValueToDb.bind(this, "charity_page_events_dutch")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Desc Input Dutch Mobile</label>
                        <CK data={this.state.charity_page_events_dutch_mobile} onChildChange={this.saveValueToDb.bind(this, "charity_page_events_dutch_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Desc Input English</label>
                        <CK data={this.state.charity_page_events_english} onChildChange={this.saveValueToDb.bind(this, "charity_page_events_english")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Desc Input English Mobile</label>
                        <CK data={this.state.charity_page_events_english_mobile} onChildChange={this.saveValueToDb.bind(this, "charity_page_events_english_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Video Section</label>
                        <input
                            type="file"
                            style={{ padding: 15, border: "1px solid gray" }}
                            accept="video/*"
                            onChange={(e) => this.fileUploadToDB(e, "charity_page_event_right_image")}
                        />
                    </div>
                </div>)}
                {this.state.showStuffNow && this.props.type === "charity_commercial_fifth_section" && (<div className={styles.mainContainerParent}>
                    <div className={styles.innerBoxes}>
                        <label>Heading Input Dutch</label>
                        <CK data={this.state.charity_page_connect_dutch_heading} onChildChange={this.saveValueToDb.bind(this, "charity_page_connect_dutch_heading")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Heading Input Dutch Mobile</label>
                        <CK data={this.state.charity_page_connect_dutch_heading_mobile} onChildChange={this.saveValueToDb.bind(this, "charity_page_connect_dutch_heading_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Heading Input English</label>
                        <CK data={this.state.charity_page_connect_english_heading} onChildChange={this.saveValueToDb.bind(this, "charity_page_connect_english_heading")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Heading Input English Mobile</label>
                        <CK data={this.state.charity_page_connect_english_heading_mobile} onChildChange={this.saveValueToDb.bind(this, "charity_page_connect_english_heading_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Desc Input Dutch</label>
                        <CK data={this.state.charity_page_connect_dutch} onChildChange={this.saveValueToDb.bind(this, "charity_page_connect_dutch")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Desc Input Dutch Mobile</label>
                        <CK data={this.state.charity_page_connect_dutch_mobile} onChildChange={this.saveValueToDb.bind(this, "charity_page_connect_dutch_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Desc Input English</label>
                        <CK data={this.state.charity_page_connect_english} onChildChange={this.saveValueToDb.bind(this, "charity_page_connect_english")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Desc Input English Mobile</label>
                        <CK data={this.state.charity_page_connect_english_mobile} onChildChange={this.saveValueToDb.bind(this, "charity_page_connect_english_mobile")} />
                    </div>
                </div>)}
                {this.state.showStuffNow && this.props.type === "charity_commercial_sixth_section" && (<div className={styles.mainContainerParent}>
                    <div className={styles.innerBoxes}>
                        <label>Heading Input Dutch</label>
                        <CK data={this.state.charity_page_profile_dutch_heading} onChildChange={this.saveValueToDb.bind(this, "charity_page_profile_dutch_heading")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Heading Input Dutch Mobile</label>
                        <CK data={this.state.charity_page_profile_dutch_heading_mobile} onChildChange={this.saveValueToDb.bind(this, "charity_page_profile_dutch_heading_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Heading Input English</label>
                        <CK data={this.state.charity_page_profile_english_heading} onChildChange={this.saveValueToDb.bind(this, "charity_page_profile_english_heading")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Heading Input English Mobile</label>
                        <CK data={this.state.charity_page_profile_english_heading_mobile} onChildChange={this.saveValueToDb.bind(this, "charity_page_profile_english_heading_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Desc Input Dutch</label>
                        <CK data={this.state.charity_page_profile_dutch} onChildChange={this.saveValueToDb.bind(this, "charity_page_profile_dutch")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Desc Input Dutch Mobile</label>
                        <CK data={this.state.charity_page_profile_dutch_mobile} onChildChange={this.saveValueToDb.bind(this, "charity_page_profile_dutch_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Desc Input English</label>
                        <CK data={this.state.charity_page_profile_english} onChildChange={this.saveValueToDb.bind(this, "charity_page_profile_english")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Desc Input English Mobile</label>
                        <CK data={this.state.charity_page_profile_english_mobile} onChildChange={this.saveValueToDb.bind(this, "charity_page_profile_english_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Video Section</label>
                        <input
                            type="file"
                            style={{ padding: 15, border: "1px solid gray" }}
                            accept="video/*"
                            onChange={(e) => this.fileUploadToDB(e, "charity_page_profile_video")}
                        />
                    </div>
                </div>)}
                {this.state.showStuffNow && this.props.type === "charity_commercial_seventh_section" && (<div className={styles.mainContainerParent}>
                    <div className={styles.innerBoxes}>
                        <label>Heading Input Dutch</label>
                        <CK data={this.state.charity_page_about_dutch_heading} onChildChange={this.saveValueToDb.bind(this, "charity_page_about_dutch_heading")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Heading Input Dutch Mobile</label>
                        <CK data={this.state.charity_page_about_dutch_heading_mobile} onChildChange={this.saveValueToDb.bind(this, "charity_page_about_dutch_heading_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Heading Input English</label>
                        <CK data={this.state.charity_page_about_english_heading} onChildChange={this.saveValueToDb.bind(this, "charity_page_about_english_heading")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Heading Input English Mobile</label>
                        <CK data={this.state.charity_page_about_english_heading_mobile} onChildChange={this.saveValueToDb.bind(this, "charity_page_about_english_heading_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Desc Input Dutch</label>
                        <CK data={this.state.charity_page_about_dutch} onChildChange={this.saveValueToDb.bind(this, "charity_page_about_dutch")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Desc Input Dutch Mobile</label>
                        <CK data={this.state.charity_page_about_dutch_mobile} onChildChange={this.saveValueToDb.bind(this, "charity_page_about_dutch_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Desc Input English</label>
                        <CK data={this.state.charity_page_about_english} onChildChange={this.saveValueToDb.bind(this, "charity_page_about_english")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Desc Input English Mobile</label>
                        <CK data={this.state.charity_page_about_english_mobile} onChildChange={this.saveValueToDb.bind(this, "charity_page_about_english_mobile")} />
                    </div>
                </div>)}
                {this.state.showStuffNow && this.props.type === "charity_commercial_eighth_section" && (<div className={styles.mainContainerParent}>
                    <div className={styles.innerBoxes}>
                        <label>Heading Input Dutch</label>
                        <CK data={this.state.charity_page_team_bhodi_dutch_heading} onChildChange={this.saveValueToDb.bind(this, "charity_page_team_bhodi_dutch_heading")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Heading Input Dutch Mobile</label>
                        <CK data={this.state.charity_page_team_bhodi_dutch_heading_mobile} onChildChange={this.saveValueToDb.bind(this, "charity_page_team_bhodi_dutch_heading_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Heading Input English</label>
                        <CK data={this.state.charity_page_team_bhodi_english_heading} onChildChange={this.saveValueToDb.bind(this, "charity_page_team_bhodi_english_heading")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Heading Input English Mobile</label>
                        <CK data={this.state.charity_page_team_bhodi_english_heading_mobile} onChildChange={this.saveValueToDb.bind(this, "charity_page_team_bhodi_english_heading_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Desc Input Dutch</label>
                        <CK data={this.state.charity_page_team_bhodi_dutch} onChildChange={this.saveValueToDb.bind(this, "charity_page_team_bhodi_dutch")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Desc Input Dutch Mobile</label>
                        <CK data={this.state.charity_page_team_bhodi_dutch_mobile} onChildChange={this.saveValueToDb.bind(this, "charity_page_team_bhodi_dutch_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Desc Input English</label>
                        <CK data={this.state.charity_page_team_bhodi_english} onChildChange={this.saveValueToDb.bind(this, "charity_page_team_bhodi_english")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Desc Input English Mobile</label>
                        <CK data={this.state.charity_page_team_bhodi_english_mobile} onChildChange={this.saveValueToDb.bind(this, "charity_page_team_bhodi_english_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Image Section</label>
                        <input
                            type="file"
                            style={{ padding: 15, border: "1px solid gray" }}
                            accept="image/*"
                            onChange={(e) => this.fileUploadToDB(e, "landing_page_footer_image")}
                        />
                    </div>
                </div>)}
                {this.state.showStuffNow && this.props.type === "checkout_step_one" && (<div className={styles.mainContainerParent}>
                    <div className={styles.innerBoxes}>
                        <label>Text Input First Dutch</label>
                        <CK data={this.state.checkout_step_one_text_first} onChildChange={this.saveValueToDb.bind(this, "checkout_step_one_text_first")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input First Dutch Mobile</label>
                        <CK data={this.state.checkout_step_one_text_first_mobile} onChildChange={this.saveValueToDb.bind(this, "checkout_step_one_text_first_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input First English</label>
                        <CK data={this.state.checkout_step_one_text_first_english} onChildChange={this.saveValueToDb.bind(this, "checkout_step_one_text_first_english")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input First English Mobile</label>
                        <CK data={this.state.checkout_step_one_text_first_english_mobile} onChildChange={this.saveValueToDb.bind(this, "checkout_step_one_text_first_english_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input First Dutch (Signed In)</label>
                        <CK data={this.state.checkout_step_one_text_first_signed_in} onChildChange={this.saveValueToDb.bind(this, "checkout_step_one_text_first_signed_in")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input First Dutch Mobile (Signed In)</label>
                        <CK data={this.state.checkout_step_one_text_first_signed_in_mobile} onChildChange={this.saveValueToDb.bind(this, "checkout_step_one_text_first_signed_in_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input First English (Signed In)</label>
                        <CK data={this.state.checkout_step_one_text_first_signed_in_english} onChildChange={this.saveValueToDb.bind(this, "checkout_step_one_text_first_signed_in_english")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input First English Mobile (Signed In)</label>
                        <CK data={this.state.checkout_step_one_text_first_signed_in_english_mobile} onChildChange={this.saveValueToDb.bind(this, "checkout_step_one_text_first_signed_in_english_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input Second Dutch</label>
                        <CK data={this.state.checkout_step_one_second_text} onChildChange={this.saveValueToDb.bind(this, "checkout_step_one_second_text")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input Second Dutch Mobile</label>
                        <CK data={this.state.checkout_step_one_second_text_mobile} onChildChange={this.saveValueToDb.bind(this, "checkout_step_one_second_text_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input Second English</label>
                        <CK data={this.state.checkout_step_one_second_text_english} onChildChange={this.saveValueToDb.bind(this, "checkout_step_one_second_text_english")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input Second English Mobile</label>
                        <CK data={this.state.checkout_step_one_second_text_english_mobile} onChildChange={this.saveValueToDb.bind(this, "checkout_step_one_second_text_english_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input Second Dutch (Signed In)</label>
                        <CK data={this.state.checkout_step_one_second_text_signed_in} onChildChange={this.saveValueToDb.bind(this, "checkout_step_one_second_text_signed_in")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input Second Dutch Mobile (Signed In)</label>
                        <CK data={this.state.checkout_step_one_second_text_signed_in_mobile} onChildChange={this.saveValueToDb.bind(this, "checkout_step_one_second_text_signed_in_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input Second English (Signed In)</label>
                        <CK data={this.state.checkout_step_one_second_text_signed_in_english} onChildChange={this.saveValueToDb.bind(this, "checkout_step_one_second_text_signed_in_english")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input Second English Mobile (Signed In)</label>
                        <CK data={this.state.checkout_step_one_second_text_signed_in_english_mobile} onChildChange={this.saveValueToDb.bind(this, "checkout_step_one_second_text_signed_in_english_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input Dutch</label>
                        <CK data={this.state.checkout_step_one_text} onChildChange={this.saveValueToDb.bind(this, "checkout_step_one_text")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input Dutch Mobile</label>
                        <CK data={this.state.checkout_step_one_text_mobile} onChildChange={this.saveValueToDb.bind(this, "checkout_step_one_text_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input English</label>
                        <CK data={this.state.checkout_step_one_text_english} onChildChange={this.saveValueToDb.bind(this, "checkout_step_one_text_english")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input English Mobile</label>
                        <CK data={this.state.checkout_step_one_text_english_mobile} onChildChange={this.saveValueToDb.bind(this, "checkout_step_one_text_english_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input Dutch (Signed In)</label>
                        <CK data={this.state.checkout_step_one_text_signed_in} onChildChange={this.saveValueToDb.bind(this, "checkout_step_one_text_signed_in")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input Dutch Mobile (Signed In)</label>
                        <CK data={this.state.checkout_step_one_text_signed_in_mobile} onChildChange={this.saveValueToDb.bind(this, "checkout_step_one_text_signed_in_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input English (Signed In)</label>
                        <CK data={this.state.checkout_step_one_text_signed_in_english} onChildChange={this.saveValueToDb.bind(this, "checkout_step_one_text_signed_in_english")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input English Mobile (Signed In)</label>
                        <CK data={this.state.checkout_step_one_text_signed_in_english_mobile} onChildChange={this.saveValueToDb.bind(this, "checkout_step_one_text_signed_in_english_mobile")} />
                    </div>
                </div>)}
                {this.state.showStuffNow && this.props.type === "checkout_step_one_new" && (<div className={styles.mainContainerParent}>
                    <div className={styles.innerBoxes}>
                        <label>Text Input First Dutch</label>
                        <CK data={this.state.checkout_step_one_new_text_first} onChildChange={this.saveValueToDb.bind(this, "checkout_step_one_new_text_first")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input First Dutch Mobile</label>
                        <CK data={this.state.checkout_step_one_new_text_first_mobile} onChildChange={this.saveValueToDb.bind(this, "checkout_step_one_new_text_first_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input First English</label>
                        <CK data={this.state.checkout_step_one_new_text_first_english} onChildChange={this.saveValueToDb.bind(this, "checkout_step_one_new_text_first_english")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input First English Mobile</label>
                        <CK data={this.state.checkout_step_one_new_text_first_english_mobile} onChildChange={this.saveValueToDb.bind(this, "checkout_step_one_new_text_first_english_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input First Dutch (Signed In)</label>
                        <CK data={this.state.checkout_step_one_new_text_first_signed_in} onChildChange={this.saveValueToDb.bind(this, "checkout_step_one_new_text_first_signed_in")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input First Dutch Mobile (Signed In)</label>
                        <CK data={this.state.checkout_step_one_new_text_first_signed_in_mobile} onChildChange={this.saveValueToDb.bind(this, "checkout_step_one_new_text_first_signed_in_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input First English (Signed In)</label>
                        <CK data={this.state.checkout_step_one_new_text_first_signed_in_english} onChildChange={this.saveValueToDb.bind(this, "checkout_step_one_new_text_first_signed_in_english")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input First English Mobile (Signed In)</label>
                        <CK data={this.state.checkout_step_one_new_text_first_signed_in_english_mobile} onChildChange={this.saveValueToDb.bind(this, "checkout_step_one_new_text_first_signed_in_english_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input Second Dutch</label>
                        <CK data={this.state.checkout_step_one_new_second_text} onChildChange={this.saveValueToDb.bind(this, "checkout_step_one_new_second_text")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input Second Dutch Mobile</label>
                        <CK data={this.state.checkout_step_one_new_second_text_mobile} onChildChange={this.saveValueToDb.bind(this, "checkout_step_one_new_second_text_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input Second English</label>
                        <CK data={this.state.checkout_step_one_new_second_text_english} onChildChange={this.saveValueToDb.bind(this, "checkout_step_one_new_second_text_english")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input Second English Mobile</label>
                        <CK data={this.state.checkout_step_one_new_second_text_english_mobile} onChildChange={this.saveValueToDb.bind(this, "checkout_step_one_new_second_text_english_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input Second Dutch (Signed In)</label>
                        <CK data={this.state.checkout_step_one_new_second_text_signed_in} onChildChange={this.saveValueToDb.bind(this, "checkout_step_one_new_second_text_signed_in")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input Second Dutch Mobile (Signed In)</label>
                        <CK data={this.state.checkout_step_one_new_second_text_signed_in_mobile} onChildChange={this.saveValueToDb.bind(this, "checkout_step_one_new_second_text_signed_in_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input Second English (Signed In)</label>
                        <CK data={this.state.checkout_step_one_new_second_text_signed_in_english} onChildChange={this.saveValueToDb.bind(this, "checkout_step_one_new_second_text_signed_in_english")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input Second English Mobile (Signed In)</label>
                        <CK data={this.state.checkout_step_one_new_second_text_signed_in_english_mobile} onChildChange={this.saveValueToDb.bind(this, "checkout_step_one_new_second_text_signed_in_english_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input Dutch</label>
                        <CK data={this.state.checkout_step_one_new_text} onChildChange={this.saveValueToDb.bind(this, "checkout_step_one_new_text")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input Dutch Mobile</label>
                        <CK data={this.state.checkout_step_one_new_text_mobile} onChildChange={this.saveValueToDb.bind(this, "checkout_step_one_new_text_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input English</label>
                        <CK data={this.state.checkout_step_one_new_text_english} onChildChange={this.saveValueToDb.bind(this, "checkout_step_one_new_text_english")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input English Mobile</label>
                        <CK data={this.state.checkout_step_one_new_text_english_mobile} onChildChange={this.saveValueToDb.bind(this, "checkout_step_one_new_text_english_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input Dutch (Signed In)</label>
                        <CK data={this.state.checkout_step_one_new_text_signed_in} onChildChange={this.saveValueToDb.bind(this, "checkout_step_one_new_text_signed_in")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input Dutch Mobile (Signed In)</label>
                        <CK data={this.state.checkout_step_one_new_text_signed_in_mobile} onChildChange={this.saveValueToDb.bind(this, "checkout_step_one_new_text_signed_in_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input English (Signed In)</label>
                        <CK data={this.state.checkout_step_one_new_text_signed_in_english} onChildChange={this.saveValueToDb.bind(this, "checkout_step_one_new_text_signed_in_english")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input English Mobile (Signed In)</label>
                        <CK data={this.state.checkout_step_one_new_text_signed_in_english_mobile} onChildChange={this.saveValueToDb.bind(this, "checkout_step_one_new_text_signed_in_english_mobile")} />
                    </div>
                </div>)}
                {this.state.showStuffNow && this.props.type === "checkout_step_two" && (<div className={styles.mainContainerParent}>
                    <div className={styles.innerBoxes}>
                        <label>Text Input First Dutch</label>
                        <CK data={this.state.checkout_step_two_text_first} onChildChange={this.saveValueToDb.bind(this, "checkout_step_two_text_first")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input First Dutch Mobile</label>
                        <CK data={this.state.checkout_step_two_text_first_mobile} onChildChange={this.saveValueToDb.bind(this, "checkout_step_two_text_first_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input First English</label>
                        <CK data={this.state.checkout_step_two_text_first_english} onChildChange={this.saveValueToDb.bind(this, "checkout_step_two_text_first_english")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input First English Mobile</label>
                        <CK data={this.state.checkout_step_two_text_first_english_mobile} onChildChange={this.saveValueToDb.bind(this, "checkout_step_two_text_first_english_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input Second Dutch</label>
                        <CK data={this.state.checkout_step_two_second_text} onChildChange={this.saveValueToDb.bind(this, "checkout_step_two_second_text")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input Second Dutch Mobile</label>
                        <CK data={this.state.checkout_step_two_second_text_mobile} onChildChange={this.saveValueToDb.bind(this, "checkout_step_two_second_text_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input Second English</label>
                        <CK data={this.state.checkout_step_two_second_text_english} onChildChange={this.saveValueToDb.bind(this, "checkout_step_two_second_text_english")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input Second English Mobile</label>
                        <CK data={this.state.checkout_step_two_second_text_english_mobile} onChildChange={this.saveValueToDb.bind(this, "checkout_step_two_second_text_english_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Top Text Input Dutch</label>
                        <CK data={this.state.checkout_step_two_top_text} onChildChange={this.saveValueToDb.bind(this, "checkout_step_two_top_text")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Top Text Input Dutch Mobile</label>
                        <CK data={this.state.checkout_step_two_top_text_mobile} onChildChange={this.saveValueToDb.bind(this, "checkout_step_two_top_text_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Top Text Input English</label>
                        <CK data={this.state.checkout_step_two_top_text_english} onChildChange={this.saveValueToDb.bind(this, "checkout_step_two_top_text_english")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Top Text Input English Mobile</label>
                        <CK data={this.state.checkout_step_two_top_text_english_mobile} onChildChange={this.saveValueToDb.bind(this, "checkout_step_two_top_text_english_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Top Text Input Dutch (Products Guest)</label>
                        <CK data={this.state.checkout_step_two_top_products_text} onChildChange={this.saveValueToDb.bind(this, "checkout_step_two_top_products_text")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Top Text Input Dutch Mobile (Products Guest)</label>
                        <CK data={this.state.checkout_step_two_top_products_text_mobile} onChildChange={this.saveValueToDb.bind(this, "checkout_step_two_top_products_text_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Top Text Input English (Products Guest)</label>
                        <CK data={this.state.checkout_step_two_top_products_text_english} onChildChange={this.saveValueToDb.bind(this, "checkout_step_two_top_products_text_english")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Top Text Input English Mobile (Products Guest)</label>
                        <CK data={this.state.checkout_step_two_top_products_text_english_mobile} onChildChange={this.saveValueToDb.bind(this, "checkout_step_two_top_products_text_english_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Bottom Text Input Dutch</label>
                        <CK data={this.state.checkout_step_two_bottom_text} onChildChange={this.saveValueToDb.bind(this, "checkout_step_two_bottom_text")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Bottom Text Input Dutch Mobile</label>
                        <CK data={this.state.checkout_step_two_bottom_text_mobile} onChildChange={this.saveValueToDb.bind(this, "checkout_step_two_bottom_text_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Bottom Text Input English</label>
                        <CK data={this.state.checkout_step_two_bottom_text_english} onChildChange={this.saveValueToDb.bind(this, "checkout_step_two_bottom_text_english")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Bottom Text Input English Mobile</label>
                        <CK data={this.state.checkout_step_two_bottom_text_english_mobile} onChildChange={this.saveValueToDb.bind(this, "checkout_step_two_bottom_text_english_mobile")} />
                    </div>
                    <div className={styles.innerBoxes} style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                        <label style={{ margin: 0 }}>Show 4 Euro</label>
                        <Switch
                            color="primary"
                            inputProps={{ "aria-label": "primary checkbox" }}
                            checked={this.state.showFourEuro}
                            onClick={() => {
                                firebase.database().ref(`bhodi_home/showFourEuro`).set(!this.state.showFourEuro)
                            }}
                        />
                    </div>
                </div>)}
                {this.state.showStuffNow && this.props.type === "checkout_step_two_new" && (<div className={styles.mainContainerParent}>
                    <div className={styles.innerBoxes}>
                        <label>Text Input First Dutch</label>
                        <CK data={this.state.checkout_step_two_new_text_first} onChildChange={this.saveValueToDb.bind(this, "checkout_step_two_new_text_first")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input First Dutch Mobile</label>
                        <CK data={this.state.checkout_step_two_new_text_first_mobile} onChildChange={this.saveValueToDb.bind(this, "checkout_step_two_new_text_first_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input First English</label>
                        <CK data={this.state.checkout_step_two_new_text_first_english} onChildChange={this.saveValueToDb.bind(this, "checkout_step_two_new_text_first_english")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input First English Mobile</label>
                        <CK data={this.state.checkout_step_two_new_text_first_english_mobile} onChildChange={this.saveValueToDb.bind(this, "checkout_step_two_new_text_first_english_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input Second Dutch</label>
                        <CK data={this.state.checkout_step_two_new_second_text} onChildChange={this.saveValueToDb.bind(this, "checkout_step_two_new_second_text")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input Second Dutch Mobile</label>
                        <CK data={this.state.checkout_step_two_new_second_text_mobile} onChildChange={this.saveValueToDb.bind(this, "checkout_step_two_new_second_text_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input Second English</label>
                        <CK data={this.state.checkout_step_two_new_second_text_english} onChildChange={this.saveValueToDb.bind(this, "checkout_step_two_new_second_text_english")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input Second English Mobile</label>
                        <CK data={this.state.checkout_step_two_new_second_text_english_mobile} onChildChange={this.saveValueToDb.bind(this, "checkout_step_two_new_second_text_english_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Top Text Input Dutch</label>
                        <CK data={this.state.checkout_step_two_new_top_text} onChildChange={this.saveValueToDb.bind(this, "checkout_step_two_new_top_text")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Top Text Input Dutch Mobile</label>
                        <CK data={this.state.checkout_step_two_new_top_text_mobile} onChildChange={this.saveValueToDb.bind(this, "checkout_step_two_new_top_text_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Top Text Input English</label>
                        <CK data={this.state.checkout_step_two_new_top_text_english} onChildChange={this.saveValueToDb.bind(this, "checkout_step_two_new_top_text_english")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Top Text Input English Mobile</label>
                        <CK data={this.state.checkout_step_two_new_top_text_english_mobile} onChildChange={this.saveValueToDb.bind(this, "checkout_step_two_new_top_text_english_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Top Text Input Dutch (Products Guest)</label>
                        <CK data={this.state.checkout_step_two_new_top_products_text} onChildChange={this.saveValueToDb.bind(this, "checkout_step_two_new_top_products_text")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Top Text Input Dutch Mobile (Products Guest)</label>
                        <CK data={this.state.checkout_step_two_new_top_products_text_mobile} onChildChange={this.saveValueToDb.bind(this, "checkout_step_two_new_top_products_text_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Top Text Input English (Products Guest)</label>
                        <CK data={this.state.checkout_step_two_new_top_products_text_english} onChildChange={this.saveValueToDb.bind(this, "checkout_step_two_new_top_products_text_english")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Top Text Input English Mobile (Products Guest)</label>
                        <CK data={this.state.checkout_step_two_new_top_products_text_english_mobile} onChildChange={this.saveValueToDb.bind(this, "checkout_step_two_new_top_products_text_english_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Bottom Text Input Dutch</label>
                        <CK data={this.state.checkout_step_two_new_bottom_text} onChildChange={this.saveValueToDb.bind(this, "checkout_step_two_new_bottom_text")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Bottom Text Input Dutch Mobile</label>
                        <CK data={this.state.checkout_step_two_new_bottom_text_mobile} onChildChange={this.saveValueToDb.bind(this, "checkout_step_two_new_bottom_text_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Bottom Text Input English</label>
                        <CK data={this.state.checkout_step_two_new_bottom_text_english} onChildChange={this.saveValueToDb.bind(this, "checkout_step_two_new_bottom_text_english")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Bottom Text Input English Mobile</label>
                        <CK data={this.state.checkout_step_two_new_bottom_text_english_mobile} onChildChange={this.saveValueToDb.bind(this, "checkout_step_two_new_bottom_text_english_mobile")} />
                    </div>
                    <div className={styles.innerBoxes} style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                        <label style={{ margin: 0 }}>Show 4 Euro</label>
                        <Switch
                            color="primary"
                            inputProps={{ "aria-label": "primary checkbox" }}
                            checked={this.state.showFourEuroNew}
                            onClick={() => {
                                firebase.database().ref(`bhodi_home/showFourEuroNew`).set(!this.state.showFourEuroNew)
                            }}
                        />
                    </div>
                </div>)}
                {this.state.showStuffNow && this.props.type === "checkout_step_confirmation" && (<div className={styles.mainContainerParent}>
                    <div className={styles.innerBoxes}>
                        <label>Text Input Dutch</label>
                        <CK data={this.state.checkout_confirmation_text} onChildChange={this.saveValueToDb.bind(this, "checkout_confirmation_text")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input Dutch Mobile</label>
                        <CK data={this.state.checkout_confirmation_text_mobile} onChildChange={this.saveValueToDb.bind(this, "checkout_confirmation_text_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input English</label>
                        <CK data={this.state.checkout_confirmation_text_english} onChildChange={this.saveValueToDb.bind(this, "checkout_confirmation_text_english")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input English Mobile</label>
                        <CK data={this.state.checkout_confirmation_text_english_mobile} onChildChange={this.saveValueToDb.bind(this, "checkout_confirmation_text_english_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input Dutch Guests</label>
                        <CK data={this.state.checkout_confirmation_text_guest} onChildChange={this.saveValueToDb.bind(this, "checkout_confirmation_text_guest")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input Dutch Mobile Guests</label>
                        <CK data={this.state.checkout_confirmation_text_guest_mobile} onChildChange={this.saveValueToDb.bind(this, "checkout_confirmation_text_guest_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input English Guests</label>
                        <CK data={this.state.checkout_confirmation_text_guest_english} onChildChange={this.saveValueToDb.bind(this, "checkout_confirmation_text_guest_english")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input English Mobile Guests</label>
                        <CK data={this.state.checkout_confirmation_text_guest_english_mobile} onChildChange={this.saveValueToDb.bind(this, "checkout_confirmation_text_guest_english_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input Dutch Products</label>
                        <CK data={this.state.checkout_confirmation_products_text} onChildChange={this.saveValueToDb.bind(this, "checkout_confirmation_products_text")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input Dutch Mobile Products</label>
                        <CK data={this.state.checkout_confirmation_products_text_mobile} onChildChange={this.saveValueToDb.bind(this, "checkout_confirmation_products_text_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input English Products</label>
                        <CK data={this.state.checkout_confirmation_products_text_english} onChildChange={this.saveValueToDb.bind(this, "checkout_confirmation_products_text_english")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Text Input English Mobile Products</label>
                        <CK data={this.state.checkout_confirmation_products_text_english_mobile} onChildChange={this.saveValueToDb.bind(this, "checkout_confirmation_products_text_english_mobile")} />
                    </div>
                    <div className={styles.innerBoxes}>
                        <label>Image Section</label>
                        <input
                            type="file"
                            style={{ padding: 15, border: "1px solid gray" }}
                            accept="image/*"
                            onChange={(e) => this.fileUploadToDB(e, "charity_confirmation_bg_image")}
                        />
                    </div>
                </div>)}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        global_values: state.globalReducers.global_values,
        user: state.authReducers.user,
        admin_boolean: state.globalReducers.admin_boolean,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateGlobalStuff: (global_values) =>
            dispatch(updateGlobalStuff(global_values)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditorCommercial);
