import React, { Component } from 'react'
import { history } from '../Config/Routes';
import { connect } from 'react-redux';
import FriendListRightMenu from './FriendListRightMenu';
import NavbarMainFullWidthCompanyMobile from './NavbarMainFullWidthCompanyMobile';
import RoundIconsNavbarCompany from './RoundIconsNavbarCompany';
import NavbarFullWidthMobile from './NavbarFullWidthMobile';
import RoundIconsNavbarMobile from './RoundIconsNavbarMobile';
import headerBhodiIcon from "../assets/bhodi-header-mobile.png";
import CartNonUserMobile from './CartNonUserMobile';
import FooterBasket from './FooterBasket';

export class NavbarWrapperVisitorMobile extends Component {

    constructor() {
        super()
        this.state = {
            currentUserHere: "",
        }
    }

    getVoucherEventInfo() {
        let uid = localStorage.getItem("userid");
        if (uid) {
            if (this.props.user) {
                if (this.props.user.length > 0) {
                    let filtered = this.props.user.filter((y) => {
                        return y.user_id === uid;
                    });
                    if (filtered.length > 0) {
                        this.setState({ currentUserHere: filtered[0], currentUserId: uid });
                    }
                }
            }
        }
    }

    componentDidMount() {
        this.getVoucherEventInfo();
    }

    render() {
        return (
            this.state.currentUserHere ? <div>
                {this.state.currentUserHere.type === "company" ? (<>
                    <NavbarMainFullWidthCompanyMobile
                        doLessHeight={this.state.doLessHeight}
                        member_id={this.state.currentUserHere.user_name_id}
                        showSearchOnGlobal={this.props.showSearchOnGlobal}
                        searchFiltered={(e) => {
                            if (this.props.searchFiltered) {
                                this.props.searchFiltered(e)
                            }
                        }}
                        onProductDetailed={this.props.onProductDetailed}
                    />
                    {!this.props.onProductDetailed && (<RoundIconsNavbarCompany
                        member_id={this.state.currentUserHere.user_name_id}
                        showFriends={() => {
                            this.setState({ showRightMenuFriendsAnother: true });
                            // localStorage.setItem("onCurrentTabCompany", "showFriends")
                            // window.location.href = `/company/${this.state.currentMemberData.user_name_id}/friends`
                        }}
                        showProductsOfFriends={() => {
                            localStorage.setItem(
                                "onCurrentTabCompany",
                                "showproductsfriends"
                            );
                            // Commented by me
                            history.push(
                                `/company/${this.state.currentUserHere.user_name_id}/volgend`
                            );
                        }}
                        showCharity={() => {
                            localStorage.setItem(
                                "onCurrentTabCompany",
                                "showCharity"
                            );
                            // Commented by me
                            history.push(
                                `/company/${this.state.currentUserHere.user_name_id}/charity`
                            );
                            // window.location.href = `/company/${this.state.currentMemberData.user_name_id}/charity`;
                        }}
                        showFriendsNewUpdated={() => {
                            localStorage.setItem(
                                "onCurrentTabCompany",
                                "showVrienden"
                            );
                            history.push(
                                `/company/${this.state.currentUserHere.user_name_id}/vrienden`
                            );
                        }}
                        hideNav={this.state.hideNav}
                        hideMenuWork={false}
                    />)}
                </>) : (<>
                    <NavbarFullWidthMobile
                        member_id={this.state.currentUserHere.user_name_id}
                        linkToVolgend={`/member/${this.state.currentUserHere.user_name_id}/volgend`}
                        linkToCharity={`/member/${this.state.currentUserHere.user_name_id}/charity`}
                        showProducts={() => {
                            localStorage.setItem("onCurrentTab", "products");
                        }}
                        showFriends={() => {
                            this.setState({ showRightMenuFriends: true });
                        }}
                        showCharity={() => {
                            localStorage.setItem("onCurrentTab", "charitypage");
                        }}
                        showFollowIcon={this.props.currentMainMember ? this.props.currentMainMember.type === "company" : this.state.currentUserHere.type === "company"}
                        currentMainMember={this.props.currentMainMember ? this.props.currentMainMember : this.state.currentUserHere}
                        showSearchOnGlobal={this.props.showSearchOnGlobal}
                        searchFiltered={(e) => {
                            if (this.props.searchFiltered) {
                                this.props.searchFiltered(e)
                            }
                        }}
                        onProductDetailed={this.props.onProductDetailed}
                    />
                    {!this.props.onProductDetailed && (<RoundIconsNavbarMobile
                        linkToVolgend={`/member/${this.state.currentUserHere.user_name_id}/volgend`}
                        linkToCharity={`/member/${this.state.currentUserHere.user_name_id}/charity`}
                        linkToTrending={`/member/${this.state.currentUserHere.user_name_id}`}
                        member_id={this.state.currentUserHere.user_name_id}
                        showProducts={() => {
                            localStorage.setItem("onCurrentTab", "products");
                        }}
                        showFriends={() => {
                            this.setState({ showRightMenuFriends: true });
                        }}
                        showFriendsNewUpdated={() => {
                            localStorage.setItem(
                                "onCurrentTab",
                                "showVrienden"
                            );
                            history.push(
                                `/member/${this.state.currentUserHere.user_name_id}/vrienden`
                            );
                        }}
                        showCharity={() => {
                            localStorage.setItem("onCurrentTab", "charitypage");
                        }}
                    />)}
                </>)}



                {/* friends list */}
                {this.state.showRightMenuFriends && (
                    <div
                        className="overlay-div-openadd"
                        onClick={() => {
                            this.setState({ showRightMenuFriends: false });
                        }}
                    ></div>
                )}
                {this.state.showRightMenuFriends && (
                    <FriendListRightMenu
                        closePopup={(type) => {
                            if (type === "fromfriendscompletely") {
                                this.setState({ closeCompleteRightMenu: true });
                            }
                            this.setState({ showRightMenuFriends: false });
                        }}
                    />
                )}

            </div> : this.props.onProductDetailed ? (<div>
                <div
                    className="navbarfullwidthMobile navbarfullwidthmobilefullpagesupdated navbartodowhitedefault navbartodowhitedefaultnewdesign"
                    id="navbartohidemainly"
                    style={{
                        overflow:
                            window.location.href.includes("charityConfirmation") &&
                            "hidden", display: this.state.openAddPostsPopup && "none"
                    }}
                >
                    <div className="top-nfwpm">
                        <div className="name-wrapper-nav" style={{ position: "relative", display: "flex", alignItems: "center" }}>
                            <p
                                style={{ textTransform: "none", flexShrink: 0 }}
                            >
                                <img src={headerBhodiIcon} style={{ width: 100, height: 30, borderRadius: 0, objectFit: "cover", objectPosition: "center" }} />
                            </p>
                        </div>
                        <div className="icons-wrapper-nav">
                            {!window.location.href.includes("/charityConfirmation") && !window.location.href.includes("/exclusive-subscribing") && (
                                <FooterBasket onlyShowCount={true} onGuestSpecial={true} />
                            )}
                        </div>
                    </div>
                </div>
            </div>) : <div></div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducers.user,
    };
};

export default connect(
    mapStateToProps
)(NavbarWrapperVisitorMobile);
