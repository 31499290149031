import React, { Component } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "./index.css";
import firebase from "../Config/Firebase";
import CloseIcon from "@material-ui/icons/Close";
import { connect } from "react-redux";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { updateUser } from "../Redux/actions/authActions";
import SearchIcon from "@material-ui/icons/Search";
import TimerIcon from "@material-ui/icons/Timer";
import { BsTelephone } from "react-icons/bs";
import { isDesktop } from "react-device-detect";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import lockOrders from "../assets/lock_orders.png"
import lockOpenOrders from "../assets/lock_open_orders.png"
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CommentsTopUser from "./CommentsTopUser";
import { FiMail } from "react-icons/fi";
import TimerOrders from "./TimerOrders";
import { mergeAdminEvent } from "../Functions/globalFunctions";
import { MdArrowForwardIos } from "react-icons/md";
import { checkForSubscribedExclusives } from "../Functions/checkForSubscribedExclusives";

export class RightMenuOrders extends Component {
  constructor() {
    super();
    this.state = {
      modal: false,
      wholeData: "",
      arrPopup: [],
      isAdmin: false,
      heading: "",
      headingInput: "",
      arrPopup: [2, 21],
      incoming: false,
      outgoing: true,
      campaigns: false,
      campaignsOutgoing: false,
      arrOrders: [],
      arrPopup: [],
      outgoingProducts: [],
      ordersPresent: true,
      currentUserHere: "",
      vouchersOutgoing: false,
      vouchersIncoming: false,
      currentVoucherWork: "",
      showVoucherNow: false,
      currentUser: "",
      showFooter: true,
      currentUserOfPopups: "",
      redeem_code: "",
      ordersFiltered: [],
      searchText: "",
      ordersFilteredDuplicate: [],
      counterMinute: 1,
      counterSecond: 59,
      verzilverPopupMobileGiveaway: false,
      verzilverPopupMobileKortingsVoucher: false,
      verzilverPopupMobileAnother: false,
      isOnLastMinuteOpened: false,
      noFiltersHere: false,
      verzilverPopupEventNoValidate: false,
      currentActie: "",
      currentOrderSelected: "",
      campaignCheck: [],
      currentOrderValidate: "",
      currentFiltered: "Alle Orders",
      onIndexImage: 0,
      currentCampaignWebShop: "",
      shuffledText: "",
      currentUserCharityMember: false,
      currentUser: ""
    };
  }

  componentDidMount() {
    if (document.querySelector(".footergreen")) {
      document.querySelector(".footergreen").style.display = "none"
    }

    const loaderId = document.getElementById("main-page-loader-id");
    loaderId.style.display = "none";
    // Products
    let uid = localStorage.getItem("userid");

    if (this.props.user) {
      let userFound = this.props.user.find((t) => {
        return t.user_id === uid
      })
      if (userFound) {
        if ((userFound.subscription && userFound.subscription.status === "active") || checkForSubscribedExclusives(userFound)) {
          this.setState({
            currentUserCharityMember: true
          })
        } else {
          this.setState({
            currentUserCharityMember: false
          })
        }
        this.setState({
          currentUserHere: userFound
        })
      }
    }


    this.setState({
      incoming: false,
      outgoing: true,
      campaigns: false,
      campaignsOutgoing: false,
      vouchersOutgoing: false,
      vouchersIncoming: false,
    });
    let fetched = new Promise((resolve, reject) => {
      firebase
        .database()
        .ref("all_orders").orderByChild("buyer").equalTo(uid)
        .on("value", (data) => {
          let arrCompleteOrders = [];
          let a = data.val();
          let allCampaigns = [];
          if (this.props.global_campaigns) {
            if (this.props.global_campaigns.length > 0) {
              this.props.global_campaigns.map((e) => {
                allCampaigns.push(e[0]);
              });
            }
          }
          if (a) {
            let objected = Object.entries(a);
            let arrOrders = [];
            objected.map((g) => {
              arrCompleteOrders.push(g[1]);
              if (g[1].buyer === uid) {
                if (g[1].products) {
                  if (g[1].products.length > 0) {
                    if (!allCampaigns.includes(g[1].products[0].pushKey)) {
                      g[1].voucherDeleted = true;
                    }
                  }
                }
                arrOrders.push(g[1]);
              }
            });
            let arrPopup = [];
            arrOrders.map(() => {
              let obj = {
                popup: false,
              };
              arrPopup.push(obj);
            });
            arrOrders.reverse();
            resolve(arrOrders);
            // check for beschikbaar
            this.setState({
              arrOrders,
              arrPopup,
              outgoingProducts: arrOrders,
              arrCompleteOrders,
            });
          } else {
            resolve([]);
            this.setState({
              arrOrders: [],
              arrPopup: [],
              arrCompleteOrders: [],
            });
          }
        });
    });
    fetched.then((e) => {
      if (e.length > 0) {
        this.setState({ ordersPresent: true });
      } else {
        this.setState({ ordersPresent: false });
      }
    });
  }

  componentWillUnmount() {
    if (document.querySelector(".footergreen")) {
      document.querySelector(".footergreen").style.display = "flex"
    }
  }


  getBuyerName(f) {
    let arrItems = this.state.arrOrders[f];
    let filtered = this.props.user.filter((g) => {
      return g.user_id === arrItems.buyer;
    });
    if (filtered.length > 0) {
      if (filtered[0].type === "user") {
        return `${filtered[0].first_name} ${filtered[0].last_name}`;
      } else {
        return `${filtered[0].company_name}`;
      }
    }
  }

  getSellerName(e) {
    let filtered = this.props.user.filter((g) => {
      return g.user_id === e;
    });
    if (filtered.length > 0) {
      if (filtered[0].type === "user") {
        return `${filtered[0].first_name} ${filtered[0].last_name}`;
      } else {
        return `${filtered[0].company_name}`;
      }
    }
  }

  getSellerContact(e) {
    let filtered = this.props.user.filter((g) => {
      return g.user_id === e;
    });
    return `${filtered[0].email}`;
  }

  getSellerNameCampaign(e) {
    let filtered = this.props.user.filter((g) => {
      return g.user_id === e.created_by;
    });
    if (filtered.length > 0) {
      if (filtered[0].type === "user") {
        return `${filtered[0].first_name} ${filtered[0].last_name}`;
      } else {
        return `${filtered[0].company_name}, ${filtered[0].houseNumber} ${filtered[0].street}, ${filtered[0].zipCode} ${filtered[0].city}`;
      }
    }
  }

  getSellerNameCampaignOnly(e) {
    let filtered = this.props.user.filter((g) => {
      if (e.mainParentCreator) {
        return g.user_id === e.mainParentCreator;
      } else {
        return g.user_id === e.created_by;
      }
    });
    if (filtered.length > 0) {
      if (filtered[0].type === "user") {
        return `${filtered[0].first_name} ${filtered[0].last_name}`;
      } else {
        return `${filtered[0].company_name}`;
      }
    }
  }

  getBuyerNameOutgoing(f) {
    let filtered = this.props.user.filter((g) => {
      return g.user_id === f;
    });
    if (filtered.length > 0) {
      if (filtered[0].type === "user") {
        return `${filtered[0].first_name} ${filtered[0].last_name}`;
      } else {
        return `${filtered[0].company_name}`;
      }
    }
  }

  getAddressOutgoing(f) {
    let filtered = this.props.user.filter((g) => {
      return g.user_id === f;
    });
    if (filtered.length > 0) {
      return `${filtered[0].street} ${filtered[0].houseNumber}`;
    }
  }

  getAddressOutgoingSecond(f) {
    let filtered = this.props.user.filter((g) => {
      return g.user_id === f;
    });
    if (filtered.length > 0) {
      return `${filtered[0].zipCode} ${filtered[0].city}`;
    }
  }

  getAddressOutgoingEmail(f) {
    let filtered = this.props.user.filter((g) => {
      return g.user_id === f;
    });
    if (filtered.length > 0) {
      return `${filtered[0].email}`;
    }
  }

  getAddressOutgoingPhone(f) {
    let filtered = this.props.user.filter((g) => {
      return g.user_id === f;
    });
    if (filtered.length > 0) {
      return `${filtered[0].phoneNumber}`;
    }
  }

  checkForContactIcons(f) {
    let filtered = this.props.user.filter((g) => {
      return g.user_id === f;
    });
    if (filtered.length > 0 && !filtered[0].hide_contact_info_on_order) {
      return <>
        {filtered[0].phoneNumber && (<p className="addres-otb telephone-otb" style={{ display: "flex", alignItems: "center" }}>
          <a href={`tel:${filtered[0].phoneNumber}`}>
            <BsTelephone style={{ marginRight: 8 }} />
            {filtered[0].phoneNumber}
          </a>
        </p>)}
        {filtered[0].email && (<p className="addres-otb" style={{ display: "flex", alignItems: "center" }}>
          <a href={`mailto:${filtered[0].email}`}>
            <FiMail style={{ marginRight: 8 }} />
            {/* E:{" "} */}
            {filtered[0].email}
          </a>
        </p>)}
      </>
    }
  }

  getMonthName(i) {
    i = Number(i);
    if (i === 1) {
      return "Januari";
    } else if (i === 2) {
      return "Februari";
    } else if (i === 3) {
      return "Maart";
    } else if (i === 4) {
      return "April";
    } else if (i === 5) {
      return "Mei";
    } else if (i === 6) {
      return "Juni";
    } else if (i === 7) {
      return "Juli";
    } else if (i === 8) {
      return "Augustus";
    } else if (i === 9) {
      return "September";
    } else if (i === 10) {
      return "Oktober";
    } else if (i === 11) {
      return "November";
    } else if (i === 12) {
      return "December";
    }
  }

  getPerfectDate(e) {
    if (e) {
      let splitted = e.split("/");
      let joint = `${splitted[1]} ${this.getMonthName(splitted[0])} ${splitted[2]
        }`;
      return joint;
    }
  }

  checkForButtonPopup(order) {
    let uid = localStorage.getItem("userid");

    if (order.order_type === "campaign") {
      if (!order.voucherDeleted) {
        if (order.products[0].campaignType === "local_event") {
          if (order.products[0].customExpireTypeThreeMonth) {
            let dateOfOrderRedeemMax = new Date(order.timeStampCreated);
            dateOfOrderRedeemMax.setMonth(dateOfOrderRedeemMax.getMonth() + 3)

            if (order.isRedeemed) {
              return (
                <button
                  className="button-valideercode"
                  style={{
                    backgroundColor: "#C17F6B",
                    color: "white",
                    fontSize: 13,
                    letterSpacing: 1,
                    borderRadius: 4,
                    border: "none",
                    padding: "8px 12px",
                  }}
                >
                  GEBRUIKT
                </button>
              );
            } else {
              if (dateOfOrderRedeemMax.getTime() > new Date().getTime()) {
                return (
                  <button
                    className="button-valideercode"
                    style={{
                      backgroundColor: "#285151",
                      color: "white",
                      fontSize: 13,
                      letterSpacing: 1,
                      borderRadius: 4,
                      border: "none",
                      padding: "8px 12px",
                    }}
                    onClick={() => {
                      let currentUser = this.props.user.filter((h) => {
                        return h.user_id === order.products[0].created_by;
                      });
                      if (this.state.showFooter) {
                        if (this.state.currentUserCharityMember) {
                          let checkCampaign = this.props.global_campaigns.filter((e) => {
                            return e[1].pushKey === order.products[0].pushKey;
                          });

                          this.setState({
                            verzilverPopupMobileEvents: true,
                            currentOrderSelected: "",
                            isOnLastMinuteOpened: true,
                            currentUserOfPopups: currentUser[0],
                            currentActie: order.products[0],
                            currentOrderValidate: order,
                            campaignCheck: checkCampaign,
                          });
                        } else {
                          this.setState({ nonCharityMemberAlert: true });
                        }
                      }
                    }}
                  >
                    VALIDEREN <ChevronRightIcon />
                  </button>
                );
              } else {
                return (
                  <button
                    className="button-valideercode"
                    style={{
                      backgroundColor: "#C17F6B",
                      color: "white",
                      fontSize: 13,
                      letterSpacing: 1,
                      borderRadius: 4,
                      border: "none",
                      padding: "8px 12px",
                    }}
                  >
                    NIET GEVALIDEERD
                  </button>
                );
              }
            }

          } else if (order.products[0].timings) {
            if (order.products[0].timings.length > 0) {
              let filtered = order.products[0].timings.filter((j) => {
                return j.eventId === order.products[0].reserved_event_id;
              });
              let checkCampaign = this.props.global_campaigns.filter((e) => {
                return e[1].pushKey === order.products[0].pushKey;
              });
              let f = checkCampaign[0][1];
              if (filtered.length > 0) {
                let dateMax = new Date(filtered[0].endDateEvent);
                if (filtered[0].isoStartTime && filtered[0].isoEndTime) {
                  dateMax = new Date(Date.parse(filtered[0].isoEndTime))
                } else {
                  let endTimeGood = filtered[0].endTimeEvent.concat(":00");
                  dateMax.setHours(endTimeGood.split(":")[0]);
                  dateMax.setMinutes(endTimeGood.split(":")[1]);
                }
                let dateMaxStart = new Date(filtered[0].startDateEvent);
                if (filtered[0].isoStartTime && filtered[0].isoEndTime) {
                  dateMaxStart = new Date(Date.parse(filtered[0].isoStartTime))
                } else {
                  let endTimeGoodStart = filtered[0].startTimeEvent.concat(":00");
                  dateMaxStart.setHours(endTimeGoodStart.split(":")[0]);
                  dateMaxStart.setMinutes(endTimeGoodStart.split(":")[1]);
                }
                let currentDate = new Date();
                if (order.isRedeemed) {
                  return (
                    <button
                      className="button-valideercode"
                      style={{
                        backgroundColor: "#C17F6B",
                        color: "white",
                        fontSize: 13,
                        letterSpacing: 1,
                        borderRadius: 4,
                        border: "none",
                        padding: "8px 12px",
                      }}
                    >
                      GEBRUIKT
                    </button>
                  );
                } else {
                  if (currentDate.getTime() < dateMax.getTime()) {
                    if (currentDate.getTime() > dateMaxStart.getTime()) {
                      return (
                        <button
                          className="button-valideercode"
                          style={{
                            backgroundColor: "#285151",
                            color: "white",
                            fontSize: 13,
                            letterSpacing: 1,
                            borderRadius: 4,
                            border: "none",
                            padding: "8px 12px",
                          }}
                          onClick={() => {
                            let currentUser = this.props.user.filter((h) => {
                              return h.user_id === order.products[0].created_by;
                            });
                            if (this.state.showFooter) {
                              if (this.state.currentUserCharityMember) {
                                this.setState({
                                  verzilverPopupMobileEvents: true,
                                  currentOrderSelected: "",
                                  isOnLastMinuteOpened: true,
                                  currentUserOfPopups: currentUser[0],
                                  currentActie: order.products[0],
                                  currentOrderValidate: order,
                                  campaignCheck: checkCampaign,
                                });
                              } else {
                                this.setState({ nonCharityMemberAlert: true });
                              }
                            }
                          }}
                        >
                          VALIDEREN <ChevronRightIcon />
                        </button>
                      );
                    } else {
                      return (
                        <button
                          className="button-valideercode"
                          style={{
                            backgroundColor: "#285151",
                            color: "white",
                            fontSize: 13,
                            letterSpacing: 1,
                            borderRadius: 4,
                            border: "none",
                            padding: "8px 12px",
                          }}
                          onClick={() => {
                            let currentUser = this.props.user.filter((h) => {
                              return h.user_id === order.products[0].created_by;
                            });
                            if (this.state.showFooter) {
                              if (this.state.currentUserCharityMember) {
                                this.setState({
                                  verzilverPopupEventNoValidate: true,
                                  isOnLastMinuteOpened: true,
                                  currentUserOfPopups: currentUser[0],
                                  currentActie: order.products[0],
                                });
                              } else {
                                this.setState({ nonCharityMemberAlert: true });
                              }
                            }
                          }}
                        >
                          VALIDEREN <ChevronRightIcon />
                        </button>
                      );
                    }
                  } else {
                    return (
                      <button
                        className="button-valideercode"
                        style={{
                          backgroundColor: "#C17F6B",
                          color: "white",
                          fontSize: 13,
                          letterSpacing: 1,
                          borderRadius: 4,
                          border: "none",
                          padding: "8px 12px",
                        }}
                      >
                        NIET GEVALIDEERD
                      </button>
                    );
                  }
                }
              }
            }
          }
        } else {
          if (order.timeStampCreated) {
            let dateOfOrderRedeemMax = new Date(order.timeStampCreated);
            if (order.products[0].expireTypeVoucher && order.products[0].expireDateVoucher) {
              let operation = order.products[0].expireTypeVoucher
              let unitsToAdd = Number(order.products[0].expireDateVoucher)
              if (operation === 'daily') {
                dateOfOrderRedeemMax.setDate(dateOfOrderRedeemMax.getDate() + unitsToAdd);
              } else if (operation === 'weekly') {
                dateOfOrderRedeemMax.setDate(dateOfOrderRedeemMax.getDate() + (unitsToAdd * 7));
              } else if (operation === 'monthly') {
                dateOfOrderRedeemMax.setMonth(dateOfOrderRedeemMax.getMonth() + unitsToAdd);
              }
            } else {
              // dateOfOrderRedeemMax.setTime(
              //   dateOfOrderRedeemMax.getTime() + 3 * 60000
              // );
              dateOfOrderRedeemMax.setDate(dateOfOrderRedeemMax.getDate() + 7);
            }
            if (order.isRedeemed) {
              return (
                <>
                  <button
                    className="button-valideercode"
                    style={{
                      backgroundColor: "#C17F6B",
                      color: "white",
                      fontSize: 13,
                      letterSpacing: 1,
                      borderRadius: 4,
                      border: "none",
                      padding: "8px 12px",
                    }}
                  >
                    GEBRUIKT
                  </button>
                  {order.products[0].campaignType === "web_shop" && order.products[0].voucherWebshopUrl && (<a className="link-withbtnurlwebshop" href={order.products[0].voucherWebshopUrl} target="_blank">Bezoek webshop</a>)}
                </>
              );
            } else {
              if (!order.stockReturned) {
                if (order.products[0].campaignType === "web_shop" && order.products[0].isoEndTime && new Date(Date.parse(order.products[0].isoEndTime)).getTime() < new Date().getTime()) {
                  return false;
                }
                return (
                  <>
                    <button
                      className="button-valideercode"
                      style={{
                        backgroundColor: "#285151",
                        color: "white",
                        fontSize: 13,
                        letterSpacing: 1,
                        borderRadius: 4,
                        border: "none",
                        padding: "8px 12px",
                      }}
                      onClick={() => {
                        let currentUser = this.props.user.filter((h) => {
                          return h.user_id === order.products[0].created_by;
                        });
                        if (currentUser.length > 0) {
                          if (this.state.showFooter) {
                            let e = currentUser[0];
                            let checkCampaign = this.props.global_campaigns.filter((e) => {
                              return e[1].pushKey === order.products[0].pushKey;
                            });
                            if (order.products[0].campaignType === "kortings_voucher") {
                              if (this.state.currentUserCharityMember) {
                                if (!isDesktop) {
                                  this.setState({
                                    verzilverPopupMobileKortingsVoucher: true,
                                    currentOrderSelected: "",
                                    currentUserOfPopups: e,
                                    currentActie: order.products[0],
                                    currentOrderValidate: order,
                                    campaignCheck: checkCampaign,
                                  });
                                } else {
                                  this.setState({ validOnMobilePopup: true });
                                }
                              } else {
                                this.setState({ nonCharityMemberAlert: true });
                              }
                            } else if (order.products[0].campaignType === "web_shop") {
                              this.setState({
                                verzilverPopupWebShopNew: true,
                                currentOrderSelected: "",
                                currentUserOfPopups: e,
                                redeem_code: order.products[0].redeemed_code,
                                currentActie: order.products[0],
                                currentOrderValidate: order,
                                campaignCheck: checkCampaign,
                              });
                            }
                          }
                        }
                      }}
                    >
                      VALIDEREN <ChevronRightIcon />
                    </button>
                    {order.products[0].campaignType === "web_shop" && order.products[0].voucherWebshopUrl && (<a className="link-withbtnurlwebshop" href={order.products[0].voucherWebshopUrl} target="_blank">Bezoek webshop</a>)}
                  </>
                );
              } else {
                return (
                  <>
                    <button
                      className="button-valideercode"
                      style={{
                        backgroundColor: "#C17F6B",
                        color: "white",
                        fontSize: 13,
                        letterSpacing: 1,
                        borderRadius: 4,
                        border: "none",
                        padding: "8px 12px",
                      }}
                    >
                      NIET GEVALIDEERD
                    </button>
                    {order.products[0].campaignType === "web_shop" && order.products[0].voucherWebshopUrl && (<a className="link-withbtnurlwebshop" href={order.products[0].voucherWebshopUrl} target="_blank">Bezoek webshop</a>)}
                  </>
                );
              }
            }
          }
        }
        // }
      }
    }
  }

  getDayName(num) {
    if (num === 0) {
      return `Zondag`;
    } else if (num === 1) {
      return `Maandag`;
    } else if (num === 2) {
      return `Dinsdag`;
    } else if (num === 3) {
      return `Woensdag`;
    } else if (num === 4) {
      return `Donderdag`;
    } else if (num === 5) {
      return `Vrijdag`;
    } else if (num === 6) {
      return `Zaterdag`;
    }
  }

  getMonthName(i) {
    i = Number(i);
    if (i === 1) {
      return "Januari";
    } else if (i === 2) {
      return "Februari";
    } else if (i === 3) {
      return "Maart";
    } else if (i === 4) {
      return "April";
    } else if (i === 5) {
      return "Mei";
    } else if (i === 6) {
      return "Juni";
    } else if (i === 7) {
      return "Juli";
    } else if (i === 8) {
      return "Augustus";
    } else if (i === 9) {
      return "September";
    } else if (i === 10) {
      return "Oktober";
    } else if (i === 11) {
      return "November";
    } else if (i === 12) {
      return "December";
    }
  }

  showDateToUserTiming(product) {
    let filtered = product.timings.filter((u) => {
      return u.eventId === product.reserved_event_id;
    });

    if (filtered.length > 0) {
      let e = filtered[0].startDateEvent;
      let f = filtered[0].endDateEvent;
      if (new Date(e).getFullYear() !== new Date(f).getFullYear()) {
        let date = new Date(
          filtered[0].isoStartTime ? filtered[0].isoStartTime : filtered[0].startDateEvent
        );
        let endDate = new Date(
          filtered[0].isoEndTime ? filtered[0].isoEndTime : filtered[0].endDateEvent
        );
        return `${date.getDate()} ${this.getMonthName(
          date.getMonth() + 1
        )} ${date.getFullYear()} t/m ${endDate.getDate()} ${this.getMonthName(
          endDate.getMonth() + 1
        )} ${endDate.getFullYear()}`
        // return `${this.getDayName(newDate)} ${splitted[2]} ${this.getMonthName(
        //   splitted[1]
        // )}`;
      } else if (e === f) {
        let splitted = e.split("-");
        let newDate = new Date(e).getDay();
        return `${this.getDayName(newDate)} ${splitted[2]} ${this.getMonthName(
          splitted[1]
        )}`;
      } else {
        let splitted = e.split("-");
        let splittedSecond = f.split("-");
        if (splitted[1] === splittedSecond[1]) {
          return `${splitted[2]} - ${splittedSecond[2]} ${this.getMonthName(
            splittedSecond[1]
          )}`;
        } else {
          // console.log("working this")
          // return `${this.getDayName(newDate)} ${splitted[2]} ${this.getMonthName(splitted[1])} - ${this.getDayName(newDateAnother)} ${splittedSecond[2]} ${this.getMonthName(splittedSecond[1])}`
          return `${splitted[2]} ${this.getMonthName(splitted[1])} t/m ${splittedSecond[2]
            } ${this.getMonthName(splittedSecond[1])}`;
        }
      }
    }
  }

  showTimeToUserTiming(product) {
    let filtered = product.timings.filter((u) => {
      return u.eventId === product.reserved_event_id;
    });

    if (filtered.length > 0 && filtered[0].startTimeEvent && filtered[0].endTimeEvent) {
      return `Tijd: ${filtered[0].startTimeEvent}u - ${filtered[0].endTimeEvent}u`;
    }
  }

  showDateToUserTimingPopup(product) {
    let filtered = product.timings.filter((u) => {
      return u.eventId === product.reserved_event_id;
    });

    if (filtered.length > 0) {
      let e = filtered[0].startDateEvent;
      let f = filtered[0].endDateEvent;
      if (e === f) {
        let splitted = e.split("-");
        let newDate = new Date(e).getDay();
        return `${this.getDayName(newDate)} ${splitted[2]} ${this.getMonthName(
          splitted[1]
        )}`;
      } else {
        let splitted = e.split("-");
        let splittedSecond = f.split("-");
        if (splitted[1] === splittedSecond[1]) {
          return `${splitted[2]} - ${splittedSecond[2]} ${this.getMonthName(
            splittedSecond[1]
          )}`;
        } else {
          // console.log("working this")
          // return `${this.getDayName(newDate)} ${splitted[2]} ${this.getMonthName(splitted[1])} - ${this.getDayName(newDateAnother)} ${splittedSecond[2]} ${this.getMonthName(splittedSecond[1])}`
          return `${splitted[2]} ${this.getMonthName(splitted[1])} t/m ${splittedSecond[2]
            } ${this.getMonthName(splittedSecond[1])}`;
        }
      }
    }
  }


  showTimeToUserTimingPopup(product) {
    let filtered = product.timings.filter((u) => {
      return u.eventId === product.reserved_event_id;
    });

    if (filtered.length > 0) {
      return `${filtered[0].startTimeEvent} tot ${filtered[0].endTimeEvent}u`
    }
  }


  copyToClipboard(e) {
    this.setState({ copiedTextDone: true });
    var textField = document.createElement("textarea");
    textField.innerText = e;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    // setTimeout(() => {
    //     this.setState({ copiedTextDone: false })
    // }, 3000);
  }

  getUserGlobalDesc(e) {
    if (this.props.user) {
      let filtered = this.props.user.filter((t) => {
        return t.user_id === e.created_by
      })
      if (filtered.length > 0) {
        if (filtered[0].productsInfo) {
          if (filtered[0].productsInfo.globalDesc) {
            return <font>{filtered[0].productsInfo.globalDesc.substr(0, 40)}...</font>
          }
        }
      }
    }
  }

  getSKUOfCurrentOrder(order) {
    if (order.selectedSize && order.products[0].variations_data) {
      let filtered = order.products[0].variations_data.filter((t) => {
        return t.size === order.selectedSize
      })
      if (filtered.length > 0) {
        return filtered[0].sku
      }
    } else {
      return order.products[0].sku
    }
  }

  checkForHeader() {
    return <div
      className="mid-rmap"
      style={{
        position: "relative",
        padding: 13,
        alignItems: "center",
      }}
    >
      <span>ORDERS</span>
      <div>
        {this.props.onSettingsPage && (
          <ChevronLeftIcon
            style={{
              cursor: "pointer",
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
              left: 10,
              fontSize: 18,
            }}
            onClick={() => {
              this.props.closePopup();
            }}
          />
        )}
        <div className="city-rm-main">
          <div className="select-options-filter-order">
            <div className="inner-sofo">
              <div
                className="top-box-isofo"
                onClick={() =>
                  this.setState({
                    dropdownOpen: !this.state.dropdownOpen,
                  })
                }
              >
                {this.state.currentFiltered ? (
                  <p>{this.state.currentFiltered}</p>
                ) : (
                  <p>Filter</p>
                )}
                <ExpandMoreIcon />
              </div>
              <div
                className="box-opened-dropdown"
                style={{
                  opacity: this.state.dropdownOpen ? 1 : 0,
                  pointerEvents: this.state.dropdownOpen ? "all" : "none",
                }}
              >
                <ul>
                  <li
                    onClick={() => {
                      this.setState({
                        currentFiltered: "Alle Orders",
                        dropdownOpen: false,
                        onProducts: false,
                        ordersFiltered: this.state.arrOrders,
                        ordersFilteredDuplicate: this.state.arrOrders,
                        noFiltersHere: false,
                      });
                    }}
                  >
                    All Orders
                  </li>
                  <li
                    onClick={() => {
                      this.setState({
                        currentFiltered: "Beschikbaar",
                        dropdownOpen: false,
                        onProducts: false,
                      });
                      let filteredArray = [];
                      this.state.arrOrders.map((order) => {
                        if (order.order_type === "campaign") {
                          if (order.voucherDeleted) {
                            return false
                          }
                          if (
                            order.products[0].campaignType ===
                            "local_event"
                          ) {
                            if (order.products[0].timings) {
                              if (order.products[0].timings.length > 0) {
                                let filtered =
                                  order.products[0].timings.filter(
                                    (j) => {
                                      return (
                                        j.eventId ===
                                        order.products[0]
                                          .reserved_event_id
                                      );
                                    }
                                  );
                                if (order.timeStampCreated) {
                                  if (filtered.length > 0) {
                                    let dateMax = new Date(
                                      filtered[0].endDateEvent
                                    );
                                    if (filtered[0].isoStartTime && filtered[0].isoEndTime) {
                                      dateMax = new Date(Date.parse(filtered[0].isoEndTime))
                                    } else {
                                      let endTimeGood =
                                        filtered[0].endTimeEvent.concat(
                                          ":00"
                                        );
                                      dateMax.setHours(
                                        endTimeGood.split(":")[0]
                                      );
                                      dateMax.setMinutes(
                                        endTimeGood.split(":")[1]
                                      );
                                    }
                                    let dateMaxStart = new Date(
                                      filtered[0].startDateEvent
                                    );
                                    if (filtered[0].isoStartTime && filtered[0].isoEndTime) {
                                      dateMaxStart = new Date(Date.parse(filtered[0].isoStartTime))
                                    } else {
                                      let endTimeGoodStart =
                                        filtered[0].startTimeEvent.concat(
                                          ":00"
                                        );
                                      dateMaxStart.setHours(
                                        endTimeGoodStart.split(":")[0]
                                      );
                                      dateMaxStart.setMinutes(
                                        endTimeGoodStart.split(":")[1]
                                      );
                                    }
                                    let currentDate = new Date();
                                    if (!order.isRedeemed) {
                                      if (
                                        currentDate.getTime() <
                                        dateMax.getTime()
                                      ) {
                                        // if (currentDate.getTime() > dateMaxStart.getTime() && currentDate.getTime() < dateMax.getTime()) {
                                        // if (currentDate.getTime() < dateMax.getTime()) {
                                        filteredArray.push(order);
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          } else {
                            if (order.timeStampCreated) {
                              let dateOfOrderRedeemMax = new Date(
                                order.timeStampCreated
                              );
                              if (order.products[0].expireTypeVoucher && order.products[0].expireDateVoucher) {
                                let operation = order.products[0].expireTypeVoucher
                                let unitsToAdd = Number(order.products[0].expireDateVoucher)
                                if (operation === 'daily') {
                                  dateOfOrderRedeemMax.setDate(dateOfOrderRedeemMax.getDate() + unitsToAdd);
                                } else if (operation === 'weekly') {
                                  dateOfOrderRedeemMax.setDate(dateOfOrderRedeemMax.getDate() + (unitsToAdd * 7));
                                } else if (operation === 'monthly') {
                                  dateOfOrderRedeemMax.setMonth(dateOfOrderRedeemMax.getMonth() + unitsToAdd);
                                }
                              } else {
                                // dateOfOrderRedeemMax.setTime(
                                //   dateOfOrderRedeemMax.getTime() + 3 * 60000
                                // );
                                dateOfOrderRedeemMax.setDate(dateOfOrderRedeemMax.getDate() + 7);
                              }
                              if (!order.isRedeemed) {
                                if (
                                  dateOfOrderRedeemMax.getTime() >
                                  new Date().getTime()
                                ) {
                                  if (!order.stockReturned) {
                                    filteredArray.push(order);
                                  }
                                }
                              }
                            }
                          }
                        }
                      });
                      if (filteredArray.length > 0) {
                        this.setState({
                          noFiltersHere: false,
                          ordersFiltered: filteredArray,
                          ordersFilteredDuplicate: filteredArray,
                        });
                      } else {
                        this.setState({
                          noFiltersHere: true,
                          ordersFiltered: filteredArray,
                          ordersFilteredDuplicate: filteredArray,
                        });
                      }
                    }}
                  >
                    Beschikbaar
                  </li>
                  <li
                    onClick={() => {
                      this.setState({
                        currentFiltered: "Gebruikt",
                        dropdownOpen: false,
                        onProducts: false,
                      });
                      let filteredArray = [];
                      this.state.arrOrders.map((order) => {
                        if (order.order_type === "campaign") {
                          if (
                            order.products[0].campaignType ===
                            "live_event_own"
                          ) {
                            filteredArray.push(order);
                          }
                          if (
                            order.products[0].campaignType ===
                            "local_event"
                          ) {
                            if (order.products[0].timings) {
                              if (order.products[0].timings.length > 0) {
                                let filtered =
                                  order.products[0].timings.filter(
                                    (j) => {
                                      return (
                                        j.eventId ===
                                        order.products[0]
                                          .reserved_event_id
                                      );
                                    }
                                  );
                                if (order.timeStampCreated) {
                                  if (filtered.length > 0) {
                                    if (order.isRedeemed) {
                                      filteredArray.push(order);
                                    }
                                  }
                                }
                              }
                            }
                          } else {
                            if (order.timeStampCreated) {
                              let dateOfOrderRedeemMax = new Date(
                                order.timeStampCreated
                              );
                              if (order.products[0].expireTypeVoucher && order.products[0].expireDateVoucher) {
                                let operation = order.products[0].expireTypeVoucher
                                let unitsToAdd = Number(order.products[0].expireDateVoucher)
                                if (operation === 'daily') {
                                  dateOfOrderRedeemMax.setDate(dateOfOrderRedeemMax.getDate() + unitsToAdd);
                                } else if (operation === 'weekly') {
                                  dateOfOrderRedeemMax.setDate(dateOfOrderRedeemMax.getDate() + (unitsToAdd * 7));
                                } else if (operation === 'monthly') {
                                  dateOfOrderRedeemMax.setMonth(dateOfOrderRedeemMax.getMonth() + unitsToAdd);
                                }
                              } else {
                                // dateOfOrderRedeemMax.setTime(
                                //   dateOfOrderRedeemMax.getTime() + 3 * 60000
                                // );
                                dateOfOrderRedeemMax.setDate(dateOfOrderRedeemMax.getDate() + 7);
                              }
                              if (order.isRedeemed) {
                                filteredArray.push(order);
                              }
                            }
                          }
                        } else if (
                          order.order_type === "multi_products"
                        ) {
                          filteredArray.push(order);
                        }
                      });
                      if (filteredArray.length > 0) {
                        this.setState({
                          noFiltersHere: false,
                          ordersFiltered: filteredArray,
                          ordersFilteredDuplicate: filteredArray,
                        });
                      } else {
                        this.setState({
                          noFiltersHere: true,
                          ordersFiltered: filteredArray,
                          ordersFilteredDuplicate: filteredArray,
                        });
                      }
                    }}
                  >
                    Gebruikt
                  </li>
                  <li
                    onClick={() => {
                      this.setState({
                        currentFiltered: "Verlopen",
                        dropdownOpen: false,
                        onProducts: false,
                      });
                      let filteredArray = [];
                      this.state.arrOrders.map((order) => {
                        if (order.order_type === "campaign") {
                          if (order.stockReturned) {
                            filteredArray.push(order);
                          } else if (order.voucherDeleted && !order.isRedeemed) {
                            filteredArray.push(order);
                          } else {
                            if (
                              order.products[0].campaignType ===
                              "local_event"
                            ) {
                              if (order.products[0].timings) {
                                if (
                                  order.products[0].timings.length > 0
                                ) {
                                  let filtered =
                                    order.products[0].timings.filter((j) => {
                                      return j.eventId === order.products[0].reserved_event_id;
                                    });
                                  if (filtered.length > 0) {
                                    let dateMax = new Date(
                                      filtered[0].endDateEvent
                                    );
                                    if (filtered[0].isoStartTime && filtered[0].isoEndTime) {
                                      dateMax = new Date(Date.parse(filtered[0].isoEndTime))
                                    } else {
                                      let endTimeGood = filtered[0].endTimeEvent.concat(":00");
                                      dateMax.setHours(endTimeGood.split(":")[0]);
                                      dateMax.setMinutes(endTimeGood.split(":")[1]);
                                    }
                                    let currentDate = new Date();
                                    if (order.timeStampCreated) {
                                      if (!order.isRedeemed) {
                                        if (
                                          currentDate.getTime() >
                                          dateMax.getTime()
                                        ) {
                                          filteredArray.push(order);
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      });
                      if (filteredArray.length > 0) {
                        this.setState({
                          noFiltersHere: false,
                          ordersFiltered: filteredArray,
                          ordersFilteredDuplicate: filteredArray,
                        });
                      } else {
                        this.setState({
                          noFiltersHere: true,
                          ordersFiltered: filteredArray,
                          ordersFilteredDuplicate: filteredArray,
                        });
                      }
                    }}
                  >
                    Verlopen
                  </li>
                  <li
                    onClick={() => {
                      this.setState({
                        currentFiltered: "Producten",
                        dropdownOpen: false,
                        onProducts: true,
                      });

                      let filteredArray = [];
                      this.state.arrOrders.map((order) => {
                        if (order.order_type === "bhodi_products") {
                          filteredArray.push(order);
                        }
                      });
                      if (filteredArray.length > 0) {
                        this.setState({
                          noFiltersHere: false,
                          ordersFiltered: filteredArray,
                          ordersFilteredDuplicate: filteredArray,
                        });
                      } else {
                        this.setState({
                          noFiltersHere: true,
                          ordersFiltered: filteredArray,
                          ordersFilteredDuplicate: filteredArray,
                        });
                      }
                    }}
                  >
                    Producten
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <CloseIcon
          style={{
            cursor: "pointer",
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            right: 20,
            fontSize: 18,
          }}
          className="close-do-small-orders"
          onClick={() => {
            if (this.props.onSettingsPage) {
              this.props.closePopupCompletely();
            } else {
              this.props.closePopup();
            }
          }}
        />
      </div>
    </div>
  }

  createDescMarkup(e) {
    if (e) {
      return { __html: e };
    }
  }

  createWordFromInitials(inputString, number) {
    // Split the input string into words, extract the first letter of each word, and join them
    const initials = inputString
      .split(' ')
      .filter(word => word.length > 0)  // Filter out any empty words (due to extra spaces)
      .map(word => word[0])  // Map each word to its first letter
      .join('');  // Join the array of first letters into a single string

    // Get the current time
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');  // Get hours and pad with leading zero if necessary
    const minutes = String(now.getMinutes()).padStart(2, '0');  // Get minutes and pad with leading zero if necessary

    // Create the time string in the format HHMM
    const timeString = hours + minutes;

    // Append the number to the resulting string
    return initials + timeString + number;
  }

  render() {
    return (
      <div>
        {this.state.currentOrderSelected ? (
          <div
            className="rightmenuaddproduct rightmenucommentsaddproduct rightmenuproductfriendlists rightmenucompanyvouchersmain rightmenuorderselectedopened orders-detailed-new"
            style={{ overflow: "hidden", paddingBottom: window.innerWidth < 1008 && 40 }}
          >
            <div
              className="mid-rmap"
              style={{
                position: "relative",
                padding: 13,
                alignItems: "flex-start",
              }}
            >
              <ChevronLeftIcon
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  top: "50%",
                  transform: "translateY(-50%)",
                  left: 10,
                  fontSize: 18,
                }}
                onClick={() => {
                  this.setState({ currentOrderSelected: "" });
                }}
              />
              <span>&nbsp;</span>
              <CloseIcon
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  top: "50%",
                  transform: "translateY(-50%)",
                  right: 20,
                  fontSize: 18,
                }}
                onClick={() => {
                  if (this.props.onSettingsPage) {
                    this.props.closePopupCompletely();
                  } else {
                    this.props.closePopup();
                  }
                }}
              />
            </div>
            {this.state.currentOrderSelected.order_type === "bhodi_products" ? (
              <div
                className="mid-img-uploading-rmap commentsmidimgurmap"
                style={{ alignItems: "flex-start" }}
              >
                <div
                  style={{
                    width: "100%",
                    marginTop: 15,
                    paddingBottom: window.innerWidth < 1008 && 120,
                  }}
                >
                  <div className="top-order-date-rmo">
                    <p>
                      Order: {this.state.currentOrderSelected.order_id}
                    </p>
                  </div>
                  <div className="container-detailed-orderpage">
                    <div className="bottom-order-details-rmo">
                      <img
                        src={this.state.currentOrderSelected.products[0].images[this.state.onIndexImage]}
                        alt="order"
                      />
                      <div className="order-info-ocrm">
                        <p className="title-oiocrm">
                          {this.state.currentOrderSelected.products[0].title && (
                            <font>
                              {this.state.currentOrderSelected.products[0].title.charAt(0).toUpperCase() + this.state.currentOrderSelected.products[0].title.slice(1)}
                            </font>
                          )}
                        </p>
                        <div
                          className="order-totals-block"
                          style={{
                            alignItems: "flex-start",
                            minHeight: "auto",
                          }}
                        >
                          <div
                            className="order-total-price-row summary-row"
                            style={{
                              borderBottom: 0,
                              maxWidth: 960,
                              fontFamily: "Roboto",
                              marginBottom: 0,
                              paddingBottom: 0,
                            }}
                          >
                            <p
                              className="normalprice-cpgm"
                              style={{ marginBottom: 0 }}
                            >
                              <s>
                                € {this.state.currentOrderSelected.products[0].price}
                              </s>{" "}
                              FREE
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bottom-order-desc-rmo">
                      <div
                        className="order-totals-block"
                        style={{
                          alignItems: "flex-start",
                          minHeight: "auto",
                        }}
                      >
                        <div
                          className="order-total-price-row summary-row"
                          style={{
                            borderBottom: 0,
                            maxWidth: 960,
                            fontFamily: "Roboto",
                            flexDirection: "column"
                          }}
                        >
                          <p className="desc-lds">
                            {this.state.currentOrderSelected.products[0].desc ? this.state.currentOrderSelected.products[0].desc : this.getUserGlobalDesc(this.state.currentOrderSelected.products[0])}
                          </p>
                          <p className="desc-lds">
                            Artikelnummer: {this.getSKUOfCurrentOrder(this.state.currentOrderSelected)}
                          </p>
                          {this.state.currentOrderSelected.selectedSize && (<p className="desc-lds">
                            Maat: {this.state.currentOrderSelected.selectedSize}
                          </p>)}
                          {this.state.currentOrderSelected.shipmentCost && (<p className="desc-lds">
                            Verzending: {this.state.currentOrderSelected.shipmentCost === "Free" ? "Free" : `€ ${this.state.currentOrderSelected.shipmentCost}`}
                          </p>)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="button-main-orderdetailednew">
                    {this.checkForButtonPopup(
                      this.state.currentOrderSelected
                    )}
                  </div>
                  <div className="bottom-order-companyname-rmo">
                    <CommentsTopUser
                      toShowUserId={this.state.currentOrderSelected.sellers[0]}
                      // showBericht={true}
                      redirectUserOnImage={true}
                      hideBottomIcons={true}
                    />
                    <p className="addres-otb">
                      <font style={{ fontWeight: 500 }}>Aanbieder:</font> <br />
                      {this.getBuyerNameOutgoing(
                        this.state.currentOrderSelected.sellers[0]
                      )}
                    </p>
                    <p className="addres-otb">
                      {this.getAddressOutgoing(
                        this.state.currentOrderSelected.sellers[0]
                      )}
                    </p>
                    <p className="addres-otb">
                      {this.getAddressOutgoingSecond(
                        this.state.currentOrderSelected.sellers[0]
                      )}
                    </p>
                    <p className="addres-otb telephone-otb" style={{ display: "flex", alignItems: "center" }}>
                      {/* T:{" "} */}
                      <a href={`tel:${this.getAddressOutgoingPhone(this.state.currentOrderSelected.sellers[0])}`}>
                        <BsTelephone style={{ marginRight: 8 }} />
                        {this.getAddressOutgoingPhone(
                          this.state.currentOrderSelected.sellers[0]
                        )}
                      </a>
                    </p>
                    <p className="addres-otb" style={{ display: "flex", alignItems: "center" }}>
                      <a href={`mailto:${this.getAddressOutgoingEmail(this.state.currentOrderSelected.sellers[0])}`}>
                        <FiMail style={{ marginRight: 8 }} />
                        {/* E:{" "} */}
                        {this.getAddressOutgoingEmail(
                          this.state.currentOrderSelected.sellers[0]
                        )}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="mid-img-uploading-rmap commentsmidimgurmap"
                style={{ alignItems: "flex-start" }}
              >
                <div
                  style={{
                    width: "100%",
                    marginTop: 15,
                    paddingBottom: window.innerWidth < 1008 && 120,
                  }}
                >
                  <div className="top-order-date-rmo">
                    <p>
                      Order: {this.state.currentOrderSelected.order_id}
                    </p>
                  </div>
                  <div className="container-detailed-orderpage">
                    <div className="bottom-order-details-rmo">
                      {this.state.currentOrderSelected.products[0]
                        .campaignType === "live_event" ? (
                        <img src="https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9" />
                      ) : this.state.currentOrderSelected.products[0].campaignType === "local_event" && this.state.currentOrderSelected.products[0].localEventPlaceHolderIsImage ? (
                        <img src={this.state.currentOrderSelected.products[0].image} />
                      ) : this.state.currentOrderSelected.products[0].campaignType === "local_event" && !this.state.currentOrderSelected.products[0].localEventPlaceHolderIsImage ? (
                        <video src={this.state.currentOrderSelected.products[0].image} muted loop autoPlay playsInline className="video-orders-event" style={{ width: 60, height: 50, marginRight: 10 }}></video>
                      ) : (
                        <img
                          src={this.state.currentOrderSelected.products[0].image}
                          alt="order"
                        />
                      )}
                      <div className="order-info-ocrm">
                        {this.state.currentOrderSelected.products[0].campaignType === "live_event" ? (
                          <p className="title-oiocrm">
                            <font>
                              {this.state.currentOrderSelected.products[0].descriptionLiveEvent
                                .charAt(0)
                                .toUpperCase() +
                                this.state.currentOrderSelected.products[0].descriptionLiveEvent
                                  // .substr(0, 30)
                                  .slice(1)}
                            </font>
                          </p>
                        ) : (
                          <p className="title-oiocrm">
                            {this.state.currentOrderSelected.products[0]
                              .campaignType === "charity"
                              ? this.state.currentOrderSelected.products[0]
                                .main_title && (
                                <font>
                                  {this.state.currentOrderSelected.products[0].main_title
                                    .charAt(0)
                                    .toUpperCase() +
                                    this.state.currentOrderSelected.products[0].main_title
                                      // .substr(0, 30)
                                      .slice(1)}
                                </font>
                              )
                              : this.state.currentOrderSelected.products[0]
                                .title && (
                                <font>
                                  {this.state.currentOrderSelected.products[0].title
                                    .charAt(0)
                                    .toUpperCase() +
                                    this.state.currentOrderSelected.products[0].title
                                      // .substr(0, 30)
                                      .slice(1)}
                                </font>
                              )}
                          </p>
                        )}
                        {this.state.currentOrderSelected.products[0].campaignType === "local_event" ? (
                          this.state.currentOrderSelected.products[0].amount ? <div
                            className="order-totals-block"
                            style={{ alignItems: "flex-start", minHeight: "auto" }}
                          >
                            <div
                              className="order-total-price-row summary-row"
                              style={{
                                borderBottom: 0,
                                maxWidth: 960,
                                fontFamily: "Roboto",
                                marginBottom: 0,
                                paddingBottom: 0,
                              }}
                            >
                              <p
                                className="normalprice-cpgm"
                                style={{ marginBottom: 0 }}
                              >
                                EVENT: Korting{" "}
                                {this.state.currentOrderSelected.products[0]
                                  .localEventType === "percentage" ? (
                                  <font>
                                    {
                                      this.state.currentOrderSelected.products[0]
                                        .amount
                                    }
                                    %
                                  </font>
                                ) : (
                                  <font>
                                    €
                                    {
                                      this.state.currentOrderSelected.products[0]
                                        .amount
                                    }
                                  </font>
                                )}
                              </p>
                            </div>
                          </div> : <></>
                        ) : this.state.currentOrderSelected.products[0].campaignType === "web_shop" ? (
                          <div
                            className="order-totals-block"
                            style={{ alignItems: "flex-start", minHeight: "auto" }}
                          >
                            <div
                              className="order-total-price-row summary-row"
                              style={{
                                borderBottom: 0,
                                maxWidth: 960,
                                fontFamily: "Roboto",
                                marginBottom: 0,
                                paddingBottom: 0,
                              }}
                            >
                              <p
                                className="normalprice-cpgm"
                                style={{ marginBottom: 0 }}
                              >
                                Webshop: Korting{" "}
                                {this.state.currentOrderSelected.products[0].voucherIsPercentage ? (
                                  <font>
                                    {this.state.currentOrderSelected.products[0].amount} %
                                  </font>
                                ) : (
                                  <font>
                                    € {this.state.currentOrderSelected.products[0].amount}
                                  </font>
                                )}
                              </p>
                            </div>
                          </div>
                        ) : (
                          this.state.currentOrderSelected.products[0]
                            .campaignType === "kortings_voucher" && (
                            <div
                              className="order-totals-block"
                              style={{
                                alignItems: "flex-start",
                                minHeight: "auto",
                              }}
                            >
                              <div
                                className="order-total-price-row summary-row"
                                style={{
                                  borderBottom: 0,
                                  maxWidth: 960,
                                  fontFamily: "Roboto",
                                  marginBottom: 0,
                                  paddingBottom: 0,
                                }}
                              >
                                <p
                                  className="normalprice-cpgm"
                                  style={{ marginBottom: 0 }}
                                >
                                  Kortingsvoucher{" "}
                                  {this.state.currentOrderSelected.products[0].kortingsVoucherGiveaway ? (
                                    <font>
                                      Free
                                    </font>
                                  ) : this.state.currentOrderSelected.products[0].kortingsVoucherPrijs ? (
                                    <font>
                                      €{" "}{this.state.currentOrderSelected.products[0].amount}
                                    </font>
                                  ) : this.state.currentOrderSelected.products[0].kortingsVoucherPercentage ? (
                                    <font>
                                      {this.state.currentOrderSelected.products[0].amount} %
                                    </font>
                                  ) : !this.state.currentOrderSelected.products[0].isoTimeTimerBtnEndTime ? (
                                    <font>
                                      €{" "}{this.state.currentOrderSelected.products[0].kortingsVoucherSalePrice}
                                    </font>
                                  ) : (
                                    <>
                                      {(new Date(this.state.currentOrderSelected.products[0].isoTimeTimerBtnEndTime).getTime() > new Date().getTime()) ? (<font>
                                        €{" "}
                                        {this.state.currentOrderSelected.products[0].kortingsVoucherSalePrice}
                                      </font>) : <font>
                                        €{" "}
                                        {this.state.currentOrderSelected.products[0].kortingsVoucherNormalPrice}
                                      </font>}
                                    </>
                                  )}
                                </p>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                    <div className="bottom-order-desc-rmo">
                      {this.state.currentOrderSelected.products[0].campaignType === "local_event" ? (
                        <div
                          className="order-totals-block"
                          style={{ alignItems: "flex-start", minHeight: "auto" }}
                        >
                          <div
                            className="order-total-price-row summary-row"
                            style={{
                              borderBottom: 0,
                              maxWidth: 960,
                              fontFamily: "Roboto",
                            }}
                          >
                            <p className="desc-lds">
                              <div dangerouslySetInnerHTML={this.createDescMarkup(this.state.currentOrderSelected.products[0].desc)}></div>
                              {/* {this.state.currentOrderSelected.products[0].desc} */}
                            </p>
                          </div>
                          <div
                            className="order-total-price-row summary-row"
                            style={{
                              borderBottom: 0,
                              maxWidth: 960,
                              fontFamily: "Roboto",
                              marginBottom: 0,
                              paddingBottom: 0,
                              flexDirection: "column",
                              textTransform: "none",
                            }}
                          >
                            <p
                              className="desc-hading-lds"
                              style={{
                                color: "rgb(33, 37, 41)",
                                fontSize: 13,
                                fontWeight: 500,
                                letterSpacing: 1,
                                marginTop: 0,
                                marginBottom: 0,
                              }}
                            >
                              Datum:{" "}
                              {this.showDateToUserTiming(
                                this.state.currentOrderSelected.products[0]
                              )}
                              <br />
                              {this.showTimeToUserTiming(
                                this.state.currentOrderSelected.products[0]
                              )}
                            </p>
                          </div>
                        </div>
                      ) : (
                        (this.state.currentOrderSelected.products[0].campaignType === "kortings_voucher" || this.state.currentOrderSelected.products[0].campaignType === "web_shop") && (
                          <div
                            className="order-totals-block"
                            style={{
                              alignItems: "flex-start",
                              minHeight: "auto",
                            }}
                          >
                            <div
                              className="order-total-price-row summary-row"
                              style={{
                                borderBottom: 0,
                                maxWidth: 960,
                                fontFamily: "Roboto",
                              }}
                            >
                              <p className="desc-lds">
                                <div dangerouslySetInnerHTML={this.createDescMarkup(this.state.currentOrderSelected.products[0].desc)}></div>
                                {/* {this.state.currentOrderSelected.products[0].desc} */}
                              </p>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                  <div className="button-main-orderdetailednew">
                    {this.checkForButtonPopup(
                      this.state.currentOrderSelected
                    )}
                  </div>
                  <div className="bottom-order-companyname-rmo">
                    <CommentsTopUser
                      toShowUserId={this.state.currentOrderSelected.sellers[0]}
                      // showBericht={true}
                      redirectUserOnImage={true}
                      hideBottomIcons={true}
                    />
                    <p className="addres-otb">
                      <font style={{ fontWeight: 500 }}>Aanbieder:</font> <br />
                      {this.getBuyerNameOutgoing(
                        this.state.currentOrderSelected.sellers[0]
                      )}
                    </p>
                    <p className="addres-otb">
                      {this.getAddressOutgoing(
                        this.state.currentOrderSelected.sellers[0]
                      )}
                    </p>
                    <p className="addres-otb">
                      {this.getAddressOutgoingSecond(
                        this.state.currentOrderSelected.sellers[0]
                      )}
                    </p>
                    {this.checkForContactIcons(this.state.currentOrderSelected.sellers[0])}
                    {this.state.currentOrderSelected.redeemed_text && (<p className="addres-otb" style={{ display: "flex", alignItems: "center" }}>
                      Gebruikte code: {this.state.currentOrderSelected.redeemed_text}
                    </p>)}
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : this.state.noFiltersHere ? (
          <div
            className="rightmenuaddproduct rightmenucommentsaddproduct rightmenuproductfriendlists rightmenucompanyvouchersmain rightmenuorderspagemain"
            style={{ overflow: "hidden", paddingBottom: window.innerWidth < 1008 && 40 }}
          >
            {this.checkForHeader()}
            <div
              className="mid-img-uploading-rmap commentsmidimgurmap"
              style={{ alignItems: "flex-start" }}
            >
              <div style={{ width: "100%", marginTop: 15 }}>
                <div className="top-order-main">
                  <div className="orders-container-rightmenu">
                    {this.state.onProducts ? (<p>Je hebt nog geen producten besteld.</p>) : (<p>Je hebt momenteel geen actieve vouchers of events.</p>)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : this.state.ordersFiltered.length > 0 ? (
          <div
            className="rightmenuaddproduct rightmenucommentsaddproduct rightmenuproductfriendlists rightmenucompanyvouchersmain rightmenuorderspagemain"
            style={{ overflow: "hidden", paddingBottom: window.innerWidth < 1008 && 40 }}
          >
            {this.checkForHeader()}
            <div
              className="mid-img-uploading-rmap commentsmidimgurmap"
              style={{ alignItems: "flex-start" }}
            >
              <div style={{ width: "100%", marginTop: 15 }}>
                <div className="search-rm-main" style={{ width: window.innerWidth < 1008 && "100%" }}>
                  <input
                    type="text"
                    value={this.state.searchText}
                    onChange={(e) => {
                      this.setState({ searchText: e.target.value });
                      if (e.target.value !== "") {
                        if (this.state.currentFiltered) {
                          let arrToShow = [];
                          this.state.arrOrders.map((u) => {
                            let buyerCred = this.props.user.filter((j) => {
                              return j.user_id === u.sellers[0];
                            });
                            if (buyerCred.length > 0) {
                              if (
                                buyerCred[0].company_name
                                  .toLowerCase()
                                  .includes(e.target.value.toLowerCase())
                              ) {
                                arrToShow.push(u);
                              } else if (u.products[0].title.toLowerCase().includes(e.target.value.toLowerCase())) {
                                arrToShow.push(u);
                              }
                            }
                          });
                          if (arrToShow.length > 0) {
                            if (arrToShow.length > 0) {
                              this.setState({
                                ordersFiltered: arrToShow,
                                noFiltersHere: false,
                              });
                            } else {
                              this.setState({ noFiltersHere: true });
                            }
                          } else {
                            if (this.state.ordersFilteredDuplicate.length > 0) {
                              this.setState({
                                ordersFiltered:
                                  this.state.ordersFilteredDuplicate,
                                noFiltersHere: false,
                              });
                            } else {
                              this.setState({ noFiltersHere: true });
                            }
                          }
                        }
                      } else {
                        if (this.state.currentFiltered) {
                          this.setState({
                            ordersFiltered: this.state.ordersFilteredDuplicate,
                            noFiltersHere: false,
                          });
                        } else {
                          this.setState({
                            ordersFiltered: [],
                            noFiltersHere: false,
                          });
                        }
                      }
                    }}
                  />
                  <SearchIcon />
                </div>
                {this.state.ordersFiltered.length > 0 &&
                  this.state.ordersFiltered.map((y, i) => {
                    if (y.order_type === "bhodi_products") {
                      let indexOfImage = 0
                      if (y.products[0].images && y.products[0].images.length > 0) {
                        if (y.products[0].featuredImage && y.products[0].featuredImage < y.products[0].images.length) {
                          indexOfImage = y.products[0].featuredImage
                        }

                        let findIndex = y.products[0].images.findIndex((t) => {
                          return t.includes("_1")
                        })
                        if (findIndex !== -1) {
                          indexOfImage = findIndex
                        }
                      }
                      return (
                        <div className="orders-container-rightmenu">
                          <div className="top-order-main">
                            <p>
                              {this.getPerfectDate(y.created_on)} | Order{" "}
                              {y.order_id}
                            </p>
                          </div>
                          <div
                            className="bottom-order-main"
                            onClick={() => {
                              this.setState({ currentOrderSelected: y, onIndexImage: indexOfImage });
                            }}
                          >
                            <img
                              src={y.products[0].images[indexOfImage]}
                              alt="order"
                            />
                            <div className="order-info-ocrm">
                              <p className="title-oiocrm">
                                <font>
                                  {y.products[0].title
                                    .charAt(0)
                                    .toUpperCase() +
                                    y.products[0].title
                                      .substr(0, 30)
                                      .slice(1)}
                                </font>
                              </p>
                              <p className="name-oiocrm">
                                {this.getSellerNameCampaignOnly(
                                  y.products[0],
                                  y.sellers[0]
                                )}
                              </p>
                            </div>
                            <div className="more-show-bom">
                              <MdArrowForwardIos className="icon-smaller-msb" />
                            </div>
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div className="orders-container-rightmenu">
                          <div className="top-order-main">
                            <p>
                              {this.getPerfectDate(y.created_on)} | Order{" "}
                              {y.order_id} {(this.state.currentFiltered === "Beschikbaar" && y.products[0].campaignType !== "local_event" && !(y.products[0].campaignType === "web_shop" && y.products[0].isoEndTime)) ? <TimerOrders timeToCalculate={y.timeStampCreated} productToCheck={y} /> : (this.state.currentFiltered === "Beschikbaar" && y.products[0].campaignType === "web_shop" && y.products[0].isoEndTime) ? <TimerOrders isoEndTime={new Date(Date.parse(y.products[0].isoEndTime)).getTime()} productToCheck={y} /> : ""}
                              {/* {y.order_id} {(this.state.currentFiltered === "Beschikbaar" && y.products[0].campaignType !== "local_event" && !(y.products[0].campaignType === "web_shop" && y.products[0].isoEndTime)) ? <TimerOrders timeToCalculate={y.timeStampCreated} productToCheck={y} /> : ""} */}
                              {/* {y.order_id} */}
                            </p>
                          </div>
                          <div
                            className="bottom-order-main"
                            onClick={() => {
                              this.setState({ currentOrderSelected: y });
                            }}
                          >
                            {y.products[0].campaignType === "live_event" ? (
                              <img src="https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9" />
                            ) : y.products[0].campaignType === "local_event" && y.products[0].localEventPlaceHolderIsImage ? (
                              <img src={y.products[0].image} />
                            ) : y.products[0].campaignType === "local_event" && !y.products[0].localEventPlaceHolderIsImage ? (
                              <video src={y.products[0].image} muted loop autoPlay playsInline className="video-orders-event"></video>
                            ) : y.products[0].campaignType === "charity" ? (
                              <img
                                src={y.products[0].dishesObjected[0].image}
                                alt="order"
                              />
                            ) : (
                              <img src={y.products[0].image} alt="order" />
                            )}
                            <div className="order-info-ocrm">
                              {y.products[0].campaignType === "live_event" ? (
                                <p className="title-oiocrm">
                                  <font>
                                    {y.products[0].descriptionLiveEvent
                                      .charAt(0)
                                      .toUpperCase() +
                                      y.products[0].descriptionLiveEvent
                                        .substr(0, 30)
                                        .slice(1)}
                                  </font>
                                </p>
                              ) : (
                                <p className="title-oiocrm">
                                  {y.products[0].campaignType === "charity" ? (
                                    <font>
                                      {y.products[0].main_title
                                        .charAt(0)
                                        .toUpperCase() +
                                        y.products[0].main_title
                                          .substr(0, 30)
                                          .slice(1)}
                                    </font>
                                  ) : (
                                    <font>
                                      {y.products[0].title
                                        .charAt(0)
                                        .toUpperCase() +
                                        y.products[0].title
                                          .substr(0, 30)
                                          .slice(1)}
                                    </font>
                                  )}
                                </p>
                              )}
                              <p className="name-oiocrm">
                                {this.getSellerNameCampaignOnly(
                                  y.products[0],
                                  y.sellers[0]
                                )}
                              </p>
                            </div>
                            <div className="more-show-bom">
                              <MdArrowForwardIos className="icon-smaller-msb" />
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
              </div>
            </div>
          </div>
        ) : (
          <div
            className="rightmenuaddproduct rightmenucommentsaddproduct rightmenuproductfriendlists rightmenucompanyvouchersmain rightmenuorderspagemain"
            style={{ overflow: "hidden", paddingBottom: window.innerWidth < 1008 && 40 }}
          >
            {this.checkForHeader()}
            <div
              className="mid-img-uploading-rmap commentsmidimgurmap"
              style={{ alignItems: "flex-start" }}
            >
              <div style={{ width: "100%", marginTop: 15 }}>
                <div className="search-rm-main" style={{ width: window.innerWidth < 1008 && "100%" }}>
                  <input
                    type="text"
                    value={this.state.searchText}
                    onChange={(e) => {
                      this.setState({ searchText: e.target.value });
                      if (e.target.value !== "") {
                        let arrToShow = [];
                        this.state.arrOrders.map((u) => {
                          let buyerCred = this.props.user.filter((j) => {
                            return j.user_id === u.sellers[0];
                          });
                          if (buyerCred.length > 0) {
                            if (
                              buyerCred[0].company_name
                                .toLowerCase()
                                .includes(e.target.value.toLowerCase())
                            ) {
                              arrToShow.push(u);
                            }
                          }
                        });
                        if (arrToShow.length > 0) {
                          this.setState({
                            ordersFiltered: arrToShow,
                            noFiltersHere: false,
                          });
                        } else {
                          this.setState({ noFiltersHere: true });
                        }
                      } else {
                        if (this.state.currentFiltered) {
                          this.setState({
                            ordersFiltered: this.state.ordersFilteredDuplicate,
                            noFiltersHere: false,
                          });
                        } else {
                          this.setState({
                            ordersFiltered: [],
                            noFiltersHere: false,
                          });
                        }
                      }
                    }}
                  />
                  <SearchIcon />
                </div>
                {this.state.arrOrders.length > 0 &&
                  this.state.arrOrders.map((y, i) => {
                    if (y.order_type === "bhodi_products") {
                      let indexOfImage = 0
                      if (y.products[0].images && y.products[0].images.length > 0) {
                        if (y.products[0].featuredImage && y.products[0].featuredImage < y.products[0].images.length) {
                          indexOfImage = y.products[0].featuredImage
                        }

                        let findIndex = y.products[0].images.findIndex((t) => {
                          return t.includes("_1")
                        })
                        if (findIndex !== -1) {
                          indexOfImage = findIndex
                        }
                      }
                      return (
                        <div className="orders-container-rightmenu">
                          <div className="top-order-main">
                            <p>
                              {this.getPerfectDate(y.created_on)} | Order{" "}
                              {y.order_id}
                            </p>
                          </div>
                          <div
                            className="bottom-order-main"
                            onClick={() => {
                              this.setState({ currentOrderSelected: y, onIndexImage: indexOfImage });
                              // this.setState({ currentOrderSelected: y });
                            }}
                          >
                            <img
                              src={y.products[0].images[indexOfImage]}
                              alt="order"
                            />
                            <div className="order-info-ocrm">
                              <p className="title-oiocrm">
                                <font>
                                  {y.products[0].title
                                    .charAt(0)
                                    .toUpperCase() +
                                    y.products[0].title
                                      .substr(0, 30)
                                      .slice(1)}
                                </font>
                              </p>
                              <p className="name-oiocrm">
                                {this.getSellerNameCampaignOnly(
                                  y.products[0],
                                  y.sellers[0]
                                )}
                              </p>
                            </div>
                            <div className="more-show-bom">
                              <MdArrowForwardIos className="icon-smaller-msb" />
                            </div>
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div className="orders-container-rightmenu">
                          <div className="top-order-main">
                            <p>
                              {this.getPerfectDate(y.created_on)} | Order{" "}
                              {/* {y.order_id} */}
                              {y.order_id} {(this.state.currentFiltered === "Beschikbaar" && y.products[0].campaignType !== "local_event" && !(y.products[0].campaignType === "web_shop" && y.products[0].isoEndTime)) ? <TimerOrders timeToCalculate={y.timeStampCreated} productToCheck={y} /> : (this.state.currentFiltered === "Beschikbaar" && y.products[0].campaignType === "web_shop" && y.products[0].isoEndTime) ? <TimerOrders isoEndTime={new Date(Date.parse(y.products[0].isoEndTime))} productToCheck={y} /> : ""}
                              {/* {y.order_id} {(this.state.currentFiltered === "Beschikbaar" && y.products[0].campaignType !== "local_event") ? <TimerOrders timeToCalculate={y.timeStampCreated} productToCheck={y} /> : ""} */}
                            </p>
                          </div>
                          <div
                            className="bottom-order-main"
                            onClick={() => {
                              this.setState({ currentOrderSelected: y });
                            }}
                          >
                            {y.products[0].campaignType === "live_event" ? (
                              <img src="https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9" />
                            ) : y.products[0].campaignType === "local_event" && y.products[0].localEventPlaceHolderIsImage ? (
                              <img src={y.products[0].image} />
                            ) : y.products[0].campaignType === "local_event" && !y.products[0].localEventPlaceHolderIsImage ? (
                              <video src={y.products[0].image} muted loop autoPlay playsInline className="video-orders-event"></video>
                            ) : y.products[0].campaignType === "charity" ? (
                              <img
                                src={y.products[0].dishesObjected[0].image}
                                alt="order"
                              />
                            ) : (
                              <img src={y.products[0].image} alt="order" />
                            )}
                            <div className="order-info-ocrm">
                              {y.products[0].campaignType === "live_event" ? (
                                <p className="title-oiocrm">
                                  <font>
                                    {y.products[0].descriptionLiveEvent
                                      .charAt(0)
                                      .toUpperCase() +
                                      y.products[0].descriptionLiveEvent
                                        .substr(0, 30)
                                        .slice(1)}
                                  </font>
                                </p>
                              ) : (
                                <p className="title-oiocrm">
                                  {y.products[0].campaignType === "charity"
                                    ? y.products[0].main_title && (
                                      <font>
                                        {y.products[0].main_title
                                          .charAt(0)
                                          .toUpperCase() +
                                          y.products[0].main_title
                                            .substr(0, 30)
                                            .slice(1)}
                                      </font>
                                    )
                                    : y.products[0].title && (
                                      <font>
                                        {y.products[0].title
                                          .charAt(0)
                                          .toUpperCase() +
                                          y.products[0].title
                                            .substr(0, 30)
                                            .slice(1)}
                                      </font>
                                    )}
                                </p>
                              )}
                              <p className="name-oiocrm">
                                {this.getSellerNameCampaignOnly(
                                  y.products[0],
                                  y.sellers[0]
                                )}
                              </p>
                            </div>
                            <div className="more-show-bom">
                              <MdArrowForwardIos className="icon-smaller-msb" />
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
              </div>
            </div>
          </div>
        )}

        {/* Valid dialogs */}
        <Dialog
          open={this.state.validOnMobilePopup}
          onClose={() => this.setState({ validOnMobilePopup: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ zIndex: 99999999999999999 }}
          className="edit-dialog-cdd smallertitleedcdd"
        >
          <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Het is alleen mogelijk om te
              <br />
              valideren via mobiele telefoon.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setState({ validOnMobilePopup: false })}
              color="primary"
              className="close-modal-btn-edcd"
            >
              <CloseIcon />
            </Button>
          </DialogActions>
        </Dialog>

        {/* Mobile popups */}


        <Dialog
          open={this.state.nonCharityMemberAlert}
          onClose={() => this.setState({ nonCharityMemberAlert: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ zIndex: 99999999999999999 }}
          className="edit-dialog-cdd smallertitleedcdd"
        >
          <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You must be a charity member to use this voucher.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setState({ nonCharityMemberAlert: false })}
              color="primary"
              className="close-modal-btn-edcd"
            >
              <CloseIcon />
            </Button>
          </DialogActions>
        </Dialog>



        <div
          className="verzilver-popup-white-opacity"
          style={{
            opacity: this.state.verzilverPopupWebShopNew ? 1 : 0,
            pointerEvents: this.state.verzilverPopupWebShopNew
              ? "all"
              : "none",
          }}
        ></div>
        <div
          className="verzilver-popup-white"
          style={{
            opacity: this.state.verzilverPopupWebShopNew ? 1 : 0,
            pointerEvents: this.state.verzilverPopupWebShopNew
              ? "all"
              : "none",
          }}
        >
          <CloseIcon
            className="close-hpw"
            onClick={() =>
              this.setState({
                verzilverPopupWebShopNew: false,
                currentUserOfPopups: "",
              })
            }
          />
          <img src={lockOrders} className="lock-pic-hpw" />
          {/* <LockOpenIcon className="lock-hpw" /> */}
          <p className="heading-hpw">
            <b>LET OP</b>
          </p>
          <p className="desc-hpw">
            {/* Na het valideren van de webshop heb je
            <br />
            2 minuten de tijd om de pop-up te laten
            <br />
            zien voordat deze weer verdwijnt. */}
            Klik op de onderstaande knop om<br />
            de kortingscode te generen.
          </p>
          <button
            onClick={() => {
              this.interval = setInterval(() => {
                if (this.state.counterSecond > 1) {
                  this.setState({ counterSecond: --this.state.counterSecond });
                } else {
                  if (this.state.counterMinute === 1) {
                    this.setState({ counterMinute: 0, counterSecond: 59 });
                  } else {
                    clearInterval(this.interval);
                    this.setState({
                      counterMinute: 1,
                      counterSecond: 59,
                      verzilverPopupMobileAnother: false,
                      isOnLastMinuteOpened: false,
                    });
                  }
                }
              }, 1000);
              // valid really
              let order = this.state.currentOrderValidate
              let checkCampaign = this.state.campaignCheck
              let uid = localStorage.getItem("userid");
              let f = checkCampaign[0][1];

              firebase
                .database()
                .ref(
                  "all_orders/" + order.pushKey + "/isRedeemed"
                )
                .set(true);
              firebase
                .database()
                .ref(
                  "all_orders/" +
                  order.pushKey +
                  "/orderAlreadyCounted"
                )
                .set(true);
              firebase
                .database()
                .ref(
                  "all_orders/" + order.pushKey + "/redeemed_at"
                )
                .set(new Date().getTime());

              if (checkCampaign.length > 0) {
                if (checkCampaign[0][1].verzilvered_by && checkCampaign[0][1].voucherMultipleUseage) {
                  let objected = Object.entries(
                    checkCampaign[0][1].verzilvered_by
                  );
                  let filteredVerzilvered = objected.filter(
                    (aa) => {
                      return aa[1].user_id === uid;
                    }
                  );
                  if (filteredVerzilvered.length > 0) {
                    firebase
                      .database()
                      .ref(
                        `bhodi_campaigns/${order.products[0].created_by}/${order.products[0].pushKey}/verzilvered_by/${filteredVerzilvered[0][0]}`
                      )
                      .remove();

                    if (!f.voucherUnlimited) {
                      firebase
                        .database()
                        .ref(
                          "bhodi_campaigns/" +
                          f.created_by +
                          "/" +
                          f.pushKey +
                          "/number"
                        )
                        .set(Number(f.number) - 1);
                    }
                  }
                }
              }
              this.setState({
                verzilverPopupWebShopNew: false,
                verzilverPopupWebShop: true,
                isOnKortingsVoucher: false,
                isOnGiveawayVoucher: true,
                currentOrderValidate: "",
                campaignCheck: [],
                currentCampaignWebShop: order.products[0]
              });
            }}
          >
            VALIDEREN
          </button>
        </div>


        <div
          className="verzilver-popup-white-opacity"
          style={{
            opacity: this.state.verzilverPopupWebShop ? 1 : 0,
            pointerEvents: this.state.verzilverPopupWebShop ? "all" : "none",
          }}
        ></div>
        <div
          className="verzilver-popup-white"
          style={{
            opacity: this.state.verzilverPopupWebShop ? 1 : 0,
            pointerEvents: this.state.verzilverPopupWebShop ? "all" : "none",
          }}
        >
          <CloseIcon
            className="close-hpw"
            onClick={() => {
              this.setState({ confirmationCopiedCode: true })
            }}
          />
          <img src={lockOpenOrders} className="lock-pic-hpw" />
          <p className="heading-hpw">Yes, gelukt!</p>
          <p className="desc-hpw">
            {/* Kopieer de onderstaande kortingscode
            <br />
            en vul deze in op onze webshop. Bij het
            <br />
            afrekenen wordt deze verwerkt. */}
            Kopieer de onderstaande kortingscode en vul deze tijdens het afrekenen in op de webshop van de aanbieder.
            <br />
            <br />
            Kopieer code:
          </p>
          <p>
            <b>{this.state.redeem_code}</b>
          </p>
          {this.state.redeem_code && this.state.copiedTextDone ? (
            <button style={{ backgroundColor: "#C17F6B" }}>Gekopieerd</button>
          ) : (
            this.state.redeem_code && (
              <button
                onClick={this.copyToClipboard.bind(
                  this,
                  this.state.redeem_code
                )}
              >
                Kopiëren
              </button>
            )
          )}
          <br />
          {this.state.currentCampaignWebShop && this.state.currentCampaignWebShop.voucherWebshopUrl && (<>
            <br />
            <a className="link-withbtnurlwebshop" href={this.state.currentCampaignWebShop.voucherWebshopUrl} target="_blank" style={{ marginLeft: 0 }}>Bezoek webshop</a>
          </>)}
        </div>

        <div
          className="verzilver-popup-white-opacity"
          style={{
            opacity: this.state.verzilverPopupMobileKortingsVoucher ? 1 : 0,
            pointerEvents: this.state.verzilverPopupMobileKortingsVoucher
              ? "all"
              : "none",
          }}
        ></div>
        <div
          className="verzilver-popup-white"
          style={{
            opacity: this.state.verzilverPopupMobileKortingsVoucher ? 1 : 0,
            pointerEvents: this.state.verzilverPopupMobileKortingsVoucher
              ? "all"
              : "none",
          }}
        >
          <CloseIcon
            className="close-hpw"
            onClick={() =>
              this.setState({
                verzilverPopupMobileKortingsVoucher: false,
                currentUserOfPopups: "",
              })
            }
          />
          <img src={lockOrders} className="lock-pic-hpw" />
          {/* <LockOpenIcon className="lock-hpw" /> */}
          <p className="heading-hpw">
            <b>LET OP</b>
          </p>
          <p className="desc-hpw">
            Na het valideren van de kortingsvoucher
            <br />
            heb je 2 minuten de tijd om de pop-up te
            <br />
            laten zien voordat deze weer verdwijnt.
          </p>
          <button
            onClick={() => {
              this.interval = setInterval(() => {
                if (this.state.counterSecond > 1) {
                  this.setState({ counterSecond: --this.state.counterSecond });
                } else {
                  if (this.state.counterMinute === 1) {
                    this.setState({ counterMinute: 0, counterSecond: 59 });
                  } else {
                    clearInterval(this.interval);
                    this.setState({
                      counterMinute: 1,
                      counterSecond: 59,
                      verzilverPopupMobileAnother: false,
                      isOnLastMinuteOpened: false,
                    });
                  }
                }
              }, 1000);
              // valid really
              let order = this.state.currentOrderValidate
              let checkCampaign = this.state.campaignCheck
              let uid = localStorage.getItem("userid");
              let f = checkCampaign[0][1];
              firebase
                .database()
                .ref(
                  "all_orders/" + order.pushKey + "/isRedeemed"
                )
                .set(true);
              firebase
                .database()
                .ref(
                  "all_orders/" +
                  order.pushKey +
                  "/orderAlreadyCounted"
                )
                .set(true);
              firebase
                .database()
                .ref(
                  "all_orders/" + order.pushKey + "/redeemed_at"
                )
                .set(new Date().getTime());

              if (checkCampaign.length > 0) {
                if (checkCampaign[0][1].verzilvered_by) {
                  let objected = Object.entries(
                    checkCampaign[0][1].verzilvered_by
                  );
                  let filteredVerzilvered = objected.filter(
                    (aa) => {
                      return aa[1].user_id === uid;
                    }
                  );
                  if (filteredVerzilvered.length > 0) {
                    firebase
                      .database()
                      .ref(
                        `bhodi_campaigns/${order.products[0].created_by}/${order.products[0].pushKey}/verzilvered_by/${filteredVerzilvered[0][0]}`
                      )
                      .remove();

                    if (!f.kortingsVoucherUnlimited) {
                      firebase
                        .database()
                        .ref(
                          "bhodi_campaigns/" +
                          f.created_by +
                          "/" +
                          f.pushKey +
                          "/number"
                        )
                        .set(Number(f.number) - 1);
                    }
                  }
                }
              }
              // valid really
              this.setState({
                verzilverPopupMobileKortingsVoucher: false,
                verzilverPopupMobileAnother: true,
                isOnKortingsVoucher: true,
                isOnGiveawayVoucher: false,
                currentOrderValidate: "",
                campaignCheck: [],
              });
            }}
          >
            VALIDEREN
          </button>
        </div>

        <div
          className="verzilver-popup-white-opacity"
          style={{
            opacity: this.state.verzilverPopupMobileEvents ? 1 : 0,
            pointerEvents: this.state.verzilverPopupMobileEvents
              ? "all"
              : "none",
          }}
        ></div>
        <div
          className="verzilver-popup-white"
          style={{
            opacity: this.state.verzilverPopupMobileEvents ? 1 : 0,
            pointerEvents: this.state.verzilverPopupMobileEvents
              ? "all"
              : "none",
          }}
        >
          <CloseIcon
            className="close-hpw"
            onClick={() =>
              this.setState({
                verzilverPopupMobileEvents: false,
                currentUserOfPopups: "",
              })
            }
          />
          <img src={lockOrders} className="lock-pic-hpw" />
          {/* <LockOpenIcon className="lock-hpw" /> */}
          <p className="heading-hpw">
            <b>LET OP</b>
          </p>
          <p className="desc-hpw">
            na het valideren van het event heb je
            <br />
            2 minuten de tijd om de pop-up te
            <br />
            laten zien aan de medewerker van
            <br />
            het bedrijf.
            <br />
            <br />
            Ga je reserveren bij een restaurant?
            <br />
            Noteer dan de code en geef deze
            <br />
            door tijdens het reserveren via
            <br />
            de site of email van het restaurant.
          </p>
          <button
            onClick={() => {
              this.interval = setInterval(() => {
                if (this.state.counterSecond > 1) {
                  this.setState({ counterSecond: --this.state.counterSecond });
                } else {
                  if (this.state.counterMinute === 1) {
                    this.setState({ counterMinute: 0, counterSecond: 59 });
                  } else {
                    clearInterval(this.interval);
                    this.setState({
                      counterMinute: 1,
                      counterSecond: 59,
                      verzilverPopupMobileAnother: false,
                      isOnLastMinuteOpened: false,
                    });
                  }
                }
              }, 1000);
              // check valid real
              let order = this.state.currentOrderValidate
              let checkCampaign = this.state.campaignCheck
              let uid = localStorage.getItem("userid");
              let f = checkCampaign[0][1];
              if (f.takeFromAdmin) {
                let obj = mergeAdminEvent(f, this.props.global_campaigns)
                f = Object.assign({}, obj)
              }
              let shuffledText = this.createWordFromInitials(this.state.currentUserOfPopups.company_name, order.order_id)
              firebase
                .database()
                .ref(
                  "all_orders/" +
                  order.pushKey +
                  "/isRedeemed"
                )
                .set(true);
              firebase
                .database()
                .ref(
                  "all_orders/" +
                  order.pushKey +
                  "/redeemed_text"
                )
                .set(shuffledText);
              firebase
                .database()
                .ref(
                  "all_orders/" +
                  order.pushKey +
                  "/orderAlreadyCounted"
                )
                .set(true);
              firebase
                .database()
                .ref(
                  "all_orders/" +
                  order.pushKey +
                  "/redeemed_at"
                )
                .set(new Date().getTime());

              if (order.via_payment_link) {
                if (checkCampaign.length > 0) {
                  if (checkCampaign[0][1].added_via_payment_link) {
                    let objected = Object.entries(
                      checkCampaign[0][1].added_via_payment_link
                    );
                    let filteredVerzilvered = objected.filter(
                      (aa) => {
                        return aa[1].user_id === uid;
                      }
                    );
                    if (filteredVerzilvered.length > 0) {
                      firebase
                        .database()
                        .ref(
                          `bhodi_campaigns/${order.products[0].created_by}/${order.products[0].pushKey}/added_via_payment_link/${filteredVerzilvered[0][0]}`
                        )
                        .remove();
                    }
                  }
                }
              } else {
                if (checkCampaign.length > 0) {
                  if (checkCampaign[0][1].checked_in_by) {
                    let objected = Object.entries(
                      checkCampaign[0][1].checked_in_by
                    );
                    let filteredVerzilvered = objected.filter(
                      (aa) => {
                        return aa[1].user_id === uid;
                      }
                    );
                    if (filteredVerzilvered.length > 0) {
                      firebase
                        .database()
                        .ref(
                          `bhodi_campaigns/${order.products[0].created_by}/${order.products[0].pushKey}/checked_in_by/${filteredVerzilvered[0][0]}`
                        )
                        .remove();

                      if (f.takeFromAdmin) {
                        firebase.database().ref(`bhodi_campaigns/${f.created_by}/${f.pushKey}/stockAmount`).set(Number(f.stockAmount) - 1)
                      } else {
                        let timings = f.timings;
                        f.timings.map((e, i) => {
                          if (
                            e.eventId ===
                            order.products[0].reserved_event_id
                          ) {
                            let personsAmountAdded =
                              Number(e.personsAmountEvent) - 1;
                            if (personsAmountAdded < 10) {
                              timings[
                                i
                              ].personsAmountEvent = `0${personsAmountAdded}`;
                            } else {
                              timings[
                                i
                              ].personsAmountEvent = `${personsAmountAdded}`;
                            }
                          }
                        });
                        firebase
                          .database()
                          .ref(
                            "bhodi_campaigns/" +
                            f.created_by +
                            "/" +
                            f.pushKey +
                            "/timings"
                          )
                          .set(timings);
                      }
                    }
                  }
                }
              }
              // check valid real
              this.setState({
                verzilverPopupMobileEvents: false,
                verzilverPopupMobileAnother: true,
                currentOrderValidate: "",
                campaignCheck: [],
                shuffledText,
              });
            }}
          >
            VALIDEREN
          </button>
        </div>

        <div
          className="verzilver-popup-white-opacity"
          style={{
            opacity: this.state.verzilverPopupEventNoValidate ? 1 : 0,
            pointerEvents: this.state.verzilverPopupEventNoValidate
              ? "all"
              : "none",
          }}
        ></div>
        <div
          className="verzilver-popup-white"
          style={{
            opacity: this.state.verzilverPopupEventNoValidate ? 1 : 0,
            pointerEvents: this.state.verzilverPopupEventNoValidate
              ? "all"
              : "none",
          }}
        >
          <CloseIcon
            className="close-hpw"
            onClick={() =>
              this.setState({
                verzilverPopupEventNoValidate: false,
                currentUserOfPopups: "",
              })
            }
          />
          <img src={lockOrders} className="lock-pic-hpw" />
          {/* <LockOpenIcon className="lock-hpw" /> */}
          <p className="heading-hpw">LET OP</p>
          <p className="desc-hpw">
            Valideren kan pas op de
            <br />
            aangegeven aanvangstijd
            {/* <br />
            van het event. */}
            {/* Valideren kan alleen op
            <br />
            de startdag van het Event */}
            {(this.state.currentActie && this.state.verzilverPopupEventNoValidate && this.state.currentActie.campaignType === "local_event") ? (<br />) : null}
            {(this.state.currentActie && this.state.verzilverPopupEventNoValidate && this.state.currentActie.campaignType === "local_event") ? (<br />) : null}
            {/* {(this.state.currentActie && this.state.verzilverPopupEventNoValidate && this.state.currentActie.campaignType === "local_event") ? `Datum:` : null}
            {(this.state.currentActie && this.state.verzilverPopupEventNoValidate && this.state.currentActie.campaignType === "local_event") ? (<br />) : null} */}
            {(this.state.currentActie && this.state.verzilverPopupEventNoValidate && this.state.currentActie.campaignType === "local_event") ? `Datum: ${this.showDateToUserTimingPopup(this.state.currentActie)}` : null}
            {(this.state.currentActie && this.state.verzilverPopupEventNoValidate && this.state.currentActie.campaignType === "local_event") ? (<br />) : null}
            {(this.state.currentActie && this.state.verzilverPopupEventNoValidate && this.state.currentActie.campaignType === "local_event") ? `Tijd: ${this.showTimeToUserTimingPopup(this.state.currentActie)}` : null}
          </p>
        </div>

        <div
          className="verzilver-popup-white-opacity"
          style={{
            opacity: this.state.verzilverPopupMobileAnother ? 1 : 0,
            pointerEvents: this.state.verzilverPopupMobileAnother
              ? "all"
              : "none",
          }}
        ></div>
        <div
          className="verzilver-popup-white"
          style={{
            opacity: this.state.verzilverPopupMobileAnother ? 1 : 0,
            pointerEvents: this.state.verzilverPopupMobileAnother
              ? "all"
              : "none",
          }}
        >
          <CloseIcon
            className="close-hpw"
            onClick={() => {
              clearInterval(this.interval);
              this.setState({
                counterMinute: 1,
                counterSecond: 59,
                verzilverPopupMobileAnother: false,
                currentUserOfPopups: "",
                isOnLastMinuteOpened: false,
                isOnKortingsVoucher: false,
                isOnLocalVoucher: false,
                isOnGiveawayVoucher: false,
                shuffledText: "",
              });
            }}
          />
          <img src={lockOpenOrders} className="lock-pic-hpw" />
          {/* <LockOpenIcon className="lock-hpw" /> */}
          <p className="heading-hpw">Yes, gelukt!</p>
          {this.state.isOnLastMinuteOpened && (
            <p className="desc-hpw">Welkom op het event van:</p>
          )}
          {this.state.isOnKortingsVoucher && (
            <p className="desc-hpw">Je gebruikt nu de kortingsvoucher</p>
          )}
          {this.state.isOnGiveawayVoucher && (
            <p className="desc-hpw">Je gebruikt nu de giveaway</p>
          )}
          {this.state.isOnLocalVoucher && (
            <p className="desc-hpw">Je gebruikt nu de local</p>
          )}
          {!this.state.isOnLastMinuteOpened && (
            <p className="desc-hpw">{this.state.currentActie.title}</p>
          )}
          <p className="desc-hpw">
            {!this.state.isOnLastMinuteOpened && "van"}{" "}
            {this.state.currentUserOfPopups.company_name}
          </p>
          {this.state.shuffledText && (<p className="desc-hpw">
            {this.state.shuffledText}
          </p>)}
          <button>
            <TimerIcon /> {this.state.counterMinute}:{this.state.counterSecond}
          </button>
        </div>


        <Dialog
          open={this.state.confirmationCopiedCode}
          onClose={() => this.setState({ confirmationCopiedCode: false, copiedTextDone: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ zIndex: 99999999999999999 }}
          className="edit-dialog-cdd smallertitleedcdd"
        >
          <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {/* Are you sure that you copied the code? */}
              Heb je de code goed gekopieerd?<br />
              Deze is hierna niet meer zichtbaar.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.setState({ verzilverPopupWebShop: false, copiedTextDone: false });
                setTimeout(() => {
                  this.setState({ currentUserOfPopups: "", redeem_code: "" });
                }, 100);
                this.setState({ confirmationCopiedCode: false })
              }}
              color="primary"
            >
              Ja
            </Button>
            <Button
              onClick={() => this.setState({ confirmationCopiedCode: false })}
              color="primary"
            >
              Nee
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducers.user,
    global_campaigns: state.campaignsReducer.global_campaigns,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: (user) => dispatch(updateUser(user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RightMenuOrders);
