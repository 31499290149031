import localforage from 'localforage';

let currentMoments = ""
let currentMomentsFriends = ""
let albumToAdd = ""
let morealbumsToAdd = ""
let albumToAddFriends = ""
let morealbumsToAddFriends = ""
let selected_dragged_files = ""
let selected_dragged_files_name = ""

localforage.getItem("persist:root").then((value) => {
    if (value) {
        let converted = JSON.parse(value)
        if (converted.imagesReducer) {
            let parsed = JSON.parse(converted.imagesReducer)
            if (parsed.currentMoments) {
                currentMoments = parsed.currentMoments
            }
            if (parsed.currentMomentsFriends) {
                currentMomentsFriends = parsed.currentMomentsFriends
            }
            if (parsed.albumToAdd) {
                albumToAdd = parsed.albumToAdd
            }
            if (parsed.morealbumsToAdd) {
                morealbumsToAdd = parsed.morealbumsToAdd
            }
            if (parsed.albumToAddFriends) {
                albumToAddFriends = parsed.albumToAddFriends
            }
            if (parsed.morealbumsToAddFriends) {
                morealbumsToAddFriends = parsed.morealbumsToAddFriends
            }
            if (parsed.selected_dragged_files) {
                selected_dragged_files = parsed.selected_dragged_files
            }
            if (parsed.selected_dragged_files_name) {
                selected_dragged_files_name = parsed.selected_dragged_files_name
            }
        }
    }
})


const initial_state = {
    currentMoments: currentMoments ? currentMoments : null,
    currentMomentsFriends: currentMomentsFriends ? currentMomentsFriends : null,
    albumToAdd: albumToAdd ? albumToAdd : null,
    morealbumsToAdd: morealbumsToAdd ? morealbumsToAdd : null,
    albumToAddFriends: albumToAddFriends ? albumToAddFriends : null,
    morealbumsToAddFriends: morealbumsToAddFriends ? morealbumsToAddFriends : null,
    selected_dragged_files: selected_dragged_files ? selected_dragged_files : null,
    selected_dragged_files_name: selected_dragged_files_name ? selected_dragged_files_name : null,
}

const reducer = (state = initial_state, action) => {
    switch (action.type) {
        case "update_current_moments": {
            return { ...state, currentMoments: action.currentMoments }
        }
        case "update_current_moments_friends": {
            return { ...state, currentMomentsFriends: action.currentMomentsFriends }
        }
        case "update_album_to_add": {
            return { ...state, albumToAdd: action.albumToAdd }
        }
        case "update_more_albums_added": {
            return { ...state, morealbumsToAdd: action.morealbumsToAdd }
        }
        case "update_album_to_add_friends": {
            return { ...state, albumToAddFriends: action.albumToAddFriends }
        }
        case "update_more_albums_added_friends": {
            return { ...state, morealbumsToAddFriends: action.morealbumsToAddFriends }
        }
        case "update_dragged_files": {
            return { ...state, selected_dragged_files: action.selected_dragged_files }
        }
        case "update_dragged_files_name": {
            return { ...state, selected_dragged_files_name: action.selected_dragged_files_name }
        }
        default: {
            return state;
        }
    }
}

export default reducer