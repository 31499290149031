import React, { Component } from "react";
import AddIcon from "@material-ui/icons/Add";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import SortableTree from "react-sortable-tree";
import "react-sortable-tree/style.css";
import CloseIcon from "@material-ui/icons/Close";
import { updateGlobalStuff } from "../Redux/actions/authActions";
import { connect } from "react-redux";
import AddCircleOutline from "@material-ui/icons/AddCircleOutline";
import firebase from "../Config/Firebase";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import AdminNavbar from "./AdminNavbar";

export class IntervalSettings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            treeData: [],
            sectorModal: false,
            sectorModalCategory: false,
            sectorName: "",
            sectors: [],
            currentSector: "",
            currentSectorId: "",
            categoryName: "",
            currentSector: "",
            categoryId: "",
            all_categories: [],
            sortingTab: true,
            adjustingTab: false,
            completeMainTitles: [],
            arrOfInputs: [],
            kortingsInfo: "",
            charityInfo: "",
            lastMinuteInfo: "",
            giveawayInfo: "",
            webShopInfo: "",
            liveEventInfo: "",
            localEventInfo: "",
            globalOverlay: "",
            orderSuccessOverlay: "",
            kortingsInfoMobile: "",
            charityInfoMobile: "",
            giveawayInfoMobile: "",
            webShopInfoMobile: "",
            localEventInfoMobile: "",
            showGlobalTimer: false,
            currentDateGlobal: "",
            currentTimeGlobal: "",
            intervalTimer: "",
            intervalTitle: "",
            intervalDesc: "",
        };
    }

    componentDidMount() {
        const loaderId = document.getElementById("main-page-loader-id");
        loaderId.style.display = "none";


        if (this.props.global_values) {
            let a = this.props.global_values;
            if (a) {
                if (a.intervalTimer) {
                    this.setState({ intervalTimer: a.intervalTimer });
                }
                if (a.intervalTitle) {
                    this.setState({ intervalTitle: a.intervalTitle });
                }
                if (a.intervalDesc) {
                    this.setState({ intervalDesc: a.intervalDesc });
                }
            }
        }

        firebase
            .database()
            .ref("bhodi_home")
            .on("value", (data) => {
                let a = data.val();
                if (a) {
                    this.props.updateGlobalStuff(a);

                    if (a.intervalTimer) {
                        this.setState({ intervalTimer: a.intervalTimer });
                    }
                    if (a.intervalTitle) {
                        this.setState({ intervalTitle: a.intervalTitle });
                    }
                    if (a.intervalDesc) {
                        this.setState({ intervalDesc: a.intervalDesc });
                    }
                }
            });
    }

    saveIntervalInfo() {
        const {
            intervalTitle,
            intervalDesc,
            intervalTimer,
        } = this.state;
        firebase
            .database()
            .ref("bhodi_home/intervalTitle")
            .set(intervalTitle);
        firebase
            .database()
            .ref("bhodi_home/intervalDesc")
            .set(intervalDesc);
        firebase
            .database()
            .ref("bhodi_home/intervalTimer")
            .set(intervalTimer);
    }

    uploadIntervalSmalldemo(e) {
        let files = e.target.files[0];
        let type = ""
        if (e.target.files[0].type.match(/image.*/)) {
            type = "image"
        } else {
            type = "video"
        }
        let storageRef = firebase
            .storage()
            .ref()
            .child(`bhodi_home_media/${files.name}${new Date().getTime()}`);
        storageRef.put(files).then((snapshot) => {
            snapshot.ref.getDownloadURL().then((snapUrl) => {
                firebase
                    .database()
                    .ref("bhodi_home/intervalBgSmall/")
                    .set(snapUrl)
                firebase
                    .database()
                    .ref("bhodi_home/intervalBgSmallType/")
                    .set(type)
            });
        });
    }

    uploadIntervalBigdemo(e) {
        let files = e.target.files[0];
        let type = ""
        if (e.target.files[0].type.match(/image.*/)) {
            type = "image"
        } else {
            type = "video"
        }
        let storageRef = firebase
            .storage()
            .ref()
            .child(`bhodi_home_media/${files.name}${new Date().getTime()}`);
        storageRef.put(files).then((snapshot) => {
            snapshot.ref.getDownloadURL().then((snapUrl) => {
                firebase
                    .database()
                    .ref("bhodi_home/intervalBgLarge/")
                    .set(snapUrl)
                firebase
                    .database()
                    .ref("bhodi_home/intervalBgLargeType/")
                    .set(type)
            });
        });
    }


    render() {
        return (
            <div className="sorting-mainadmin">
                <AdminNavbar intervalSettings={true} />
                <div className="container-sma">
                    <div className="info-div-editor">
                        <div className="group-textbox" style={{ textAlign: "center" }}>
                            <label style={{ fontSize: 16, fontWeight: 600 }}>
                                CAMPAIGNS INFO
                            </label>
                        </div>
                        <div className="group-textbox">
                            <label>TITLE</label>
                            <input
                                value={this.state.intervalTitle}
                                onChange={(e) => {
                                    this.setState({ intervalTitle: e.target.value });
                                }}
                            />
                        </div>
                        <div className="group-textbox">
                            <label>DESCRIPTION</label>
                            <textarea
                                value={this.state.intervalDesc}
                                onChange={(e) => {
                                    this.setState({ intervalDesc: e.target.value });
                                }}
                            ></textarea>
                        </div>
                        <div className="group-textbox">
                            <label>TIMER</label>
                            <select
                                style={{
                                    padding: 6,
                                    borderRadius: 4,
                                    fontSize: 13,
                                    letterSpacing: 1,
                                    backgroundColor: "#f7f7f7",
                                }}
                                value={this.state.intervalTimer}
                                onChange={(e) => {
                                    this.setState({ intervalTimer: e.target.value })
                                }}
                            >
                                {!this.state.intervalTimer && (<option value="" disabled hidden>Selecteer</option>)}
                                {[10, 20, 30, 40, 50].map((e) => {
                                    return (<option value={`${e} seconds`}>{e} seconds</option>)
                                })}
                                <option value="1 minute">1 minute</option>
                                {[2, 3, 4, 5, 6, 7, 8, 9, 10].map((e) => {
                                    return (<option value={`${e} minutes`}>{e} minutes</option>)
                                })}
                                <option value="1 hour">1 hour</option>
                                {[2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24].map((e) => {
                                    return (<option value={`${e} hours`}>{e} hours</option>)
                                })}
                            </select>
                        </div>
                        <div className="group-textbox">
                            <label>SMALL DEMO BG</label>
                            <input
                                type="file"
                                style={{ padding: 15, border: "1px solid gray" }}
                                accept="image/*"
                                onChange={(e) => this.uploadIntervalSmalldemo(e)}
                            />
                            {/* <input type="file" onChange={(e) => this.uploadMediaLiveEvent()} /> */}
                        </div>
                        <div className="group-textbox">
                            <label>BIG DEMO BG</label>
                            <input
                                type="file"
                                style={{ padding: 15, border: "1px solid gray" }}
                                accept="image/*"
                                onChange={(e) => this.uploadIntervalBigdemo(e)}
                            />
                            {/* <input type="file" onChange={(e) => this.uploadMediaLiveEvent()} /> */}
                        </div>
                        <div className="group-textbox">
                            <button onClick={this.saveIntervalInfo.bind(this)}>SAVE</button>
                            {/* <button onClick={this.saveCampaignInfo.bind(this)}>SAVE</button> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        global_values: state.globalReducers.global_values,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateGlobalStuff: (global_values) =>
            dispatch(updateGlobalStuff(global_values)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(IntervalSettings);
