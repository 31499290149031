import React, { Component } from "react";
import "react-sortable-tree/style.css";
import firebase from "../Config/Firebase";
import { connect } from "react-redux";
import { updateGlobalStuff } from "../Redux/actions/authActions";
import CK from "./Ck";
import AdminNavbar from "./AdminNavbar";

export class EditorCommercial extends Component {
  constructor(props) {
    super(props);

    this.state = {
      treeData: [],
      sectorModal: false,
      sectorModalCategory: false,
      sectorName: "",
      sectors: [],
      currentSector: "",
      currentSectorId: "",
      categoryName: "",
      currentSector: "",
      categoryId: "",
      all_categories: [],
      sortingTab: true,
      adjustingTab: false,
      completeMainTitles: [],
      arrOfInputs: [],
      categoryDesc: "",
      showStuffNow: false,
      commercial_page_banner_dutch: "",
      commercial_page_banner_english: "",
      commercial_page_van_start_dutch: "",
      commercial_page_van_start_english: "",
      commercial_page_events_dutch: "",
      commercial_page_events_english: "",
      commercial_page_fan_base_dutch: "",
      commercial_page_fan_base_english: "",
      commercial_page_profile_dutch: "",
      commercial_page_profile_english: "",
      commercial_page_about_dutch: "",
      commercial_page_about_english: "",
      commercial_page_team_bhodi_dutch: "",
      commercial_page_team_bhodi_english: "",
      commercial_page_aanmelding_dutch: "",
      commercial_page_aanmelding_english: "",
      commercial_page_charity_member_dutch: "",
      commercial_page_charity_member_english: "",
      // Heading
      commercial_page_banner_dutch_heading: "",
      commercial_page_banner_english_heading: "",
      commercial_page_van_start_dutch_heading: "",
      commercial_page_van_start_english_heading: "",
      commercial_page_events_dutch_heading_inner: "",
      commercial_page_events_english_heading_inner: "",
      commercial_page_fan_base_dutch_heading: "",
      commercial_page_fan_base_english_heading: "",
      commercial_page_profile_dutch_heading: "",
      commercial_page_profile_english_heading: "",
      commercial_page_about_dutch_heading: "",
      commercial_page_about_english_heading: "",
      commercial_page_team_bhodi_dutch_heading: "",
      commercial_page_team_bhodi_english_heading: "",
      commercial_page_aanmelding_dutch_heading: "",
      commercial_page_aanmelding_english_heading: "",
      commercial_page_charity_member_dutch_heading: "",
      commercial_page_charity_member_english_heading: "",
      commercial_page_events_dutch_heading: "",
      commercial_page_events_english_heading: "",
      commercial_page_events_dutch_headertxt: "",
      commercial_page_events_english_headertxt: "",
      // Mobile

      commercial_page_banner_dutch_mobile: "",
      commercial_page_banner_english_mobile: "",
      commercial_page_van_start_dutch_mobile: "",
      commercial_page_van_start_english_mobile: "",
      commercial_page_events_dutch_mobile: "",
      commercial_page_events_english_mobile: "",
      commercial_page_fan_base_dutch_mobile: "",
      commercial_page_fan_base_english_mobile: "",
      commercial_page_profile_dutch_mobile: "",
      commercial_page_profile_english_mobile: "",
      commercial_page_about_dutch_mobile: "",
      commercial_page_about_english_mobile: "",
      commercial_page_team_bhodi_dutch_mobile: "",
      commercial_page_team_bhodi_english_mobile: "",
      commercial_page_aanmelding_dutch_mobile: "",
      commercial_page_aanmelding_english_mobile: "",
      commercial_page_charity_member_dutch_mobile: "",
      commercial_page_charity_member_english_mobile: "",
      // Heading
      commercial_page_banner_dutch_heading_mobile: "",
      commercial_page_banner_english_heading_mobile: "",
      commercial_page_van_start_dutch_heading_mobile: "",
      commercial_page_van_start_english_heading_mobile: "",
      commercial_page_events_dutch_heading_inner_mobile: "",
      commercial_page_events_english_heading_inner_mobile: "",
      commercial_page_fan_base_dutch_heading_mobile: "",
      commercial_page_fan_base_english_heading_mobile: "",
      commercial_page_profile_dutch_heading_mobile: "",
      commercial_page_profile_english_heading_mobile: "",
      commercial_page_about_dutch_heading_mobile: "",
      commercial_page_about_english_heading_mobile: "",
      commercial_page_team_bhodi_dutch_heading_mobile: "",
      commercial_page_team_bhodi_english_heading_mobile: "",
      commercial_page_aanmelding_dutch_heading_mobile: "",
      commercial_page_aanmelding_english_heading_mobile: "",
      commercial_page_charity_member_dutch_heading_mobile: "",
      commercial_page_charity_member_english_heading_mobile: "",
      commercial_page_events_dutch_heading_mobile: "",
      commercial_page_events_english_heading_mobile: "",
      commercial_page_events_dutch_headertxt_mobile: "",
      commercial_page_events_english_headertxt_mobile: "",
      onMobileDesigns: false,
      // commercial_page_first_slide_dutch_heading: a.commercial_page_first_slide_dutch_heading,
      // commercial_page_first_slide_dutch_heading_mobile: a.commercial_page_first_slide_dutch_heading,
      // commercial_page_first_slide_dutch_paragraph_mobile: "<p>De weg naar de perfecte vertaling laat onze werkwijze zien. Deze heeft gezorgd voor onze goede prijs-kwaliteitverhouding, uitstekende beoordelingen en vele tevreden klanten. Elke vertaalopdracht doorloopt een aantal stappen:</p>",
      // commercial_page_first_slide_dutch_paragraph: "<p>De weg naar de perfecte vertaling laat onze werkwijze zien. Deze heeft gezorgd voor onze goede prijs-kwaliteitverhouding, uitstekende beoordelingen en vele tevreden klanten. Elke vertaalopdracht doorloopt een aantal stappen:</p>",
      // commercial_page_second_slide_dutch_heading: a.commercial_page_first_slide_dutch_heading,
      // commercial_page_second_slide_dutch_heading_mobile: a.commercial_page_first_slide_dutch_heading,
      // commercial_page_second_slide_dutch_paragraph_mobile: "<p>De weg naar de perfecte vertaling laat onze werkwijze zien. Deze heeft gezorgd voor onze goede prijs-kwaliteitverhouding, uitstekende beoordelingen en vele tevreden klanten. Elke vertaalopdracht doorloopt een aantal stappen:</p>",
      // commercial_page_second_slide_dutch_paragraph: "<p>De weg naar de perfecte vertaling laat onze werkwijze zien. Deze heeft gezorgd voor onze goede prijs-kwaliteitverhouding, uitstekende beoordelingen en vele tevreden klanten. Elke vertaalopdracht doorloopt een aantal stappen:</p>",
      // commercial_page_third_slide_dutch_heading: a.commercial_page_first_slide_dutch_heading,
      // commercial_page_third_slide_dutch_heading_mobile: a.commercial_page_first_slide_dutch_heading,
      // commercial_page_third_slide_dutch_paragraph_mobile: "<p>De weg naar de perfecte vertaling laat onze werkwijze zien. Deze heeft gezorgd voor onze goede prijs-kwaliteitverhouding, uitstekende beoordelingen en vele tevreden klanten. Elke vertaalopdracht doorloopt een aantal stappen:</p>",
      // commercial_page_third_slide_dutch_paragraph: "<p>De weg naar de perfecte vertaling laat onze werkwijze zien. Deze heeft gezorgd voor onze goede prijs-kwaliteitverhouding, uitstekende beoordelingen en vele tevreden klanten. Elke vertaalopdracht doorloopt een aantal stappen:</p>",
      // commercial_page_fourth_slide_dutch_heading: a.commercial_page_first_slide_dutch_heading,
      // commercial_page_fourth_slide_dutch_heading_mobile: a.commercial_page_first_slide_dutch_heading,
      // commercial_page_fourth_slide_dutch_paragraph_mobile: "<p>De weg naar de perfecte vertaling laat onze werkwijze zien. Deze heeft gezorgd voor onze goede prijs-kwaliteitverhouding, uitstekende beoordelingen en vele tevreden klanten. Elke vertaalopdracht doorloopt een aantal stappen:</p>",
      // commercial_page_fourth_slide_dutch_paragraph: "<p>De weg naar de perfecte vertaling laat onze werkwijze zien. Deze heeft gezorgd voor onze goede prijs-kwaliteitverhouding, uitstekende beoordelingen en vele tevreden klanten. Elke vertaalopdracht doorloopt een aantal stappen:</p>",
      // commercial_page_fifth_slide_dutch_heading: a.commercial_page_first_slide_dutch_heading,
      // commercial_page_fifth_slide_dutch_heading_mobile: a.commercial_page_first_slide_dutch_heading,
      // commercial_page_fifth_slide_dutch_paragraph_mobile: "<p>De weg naar de perfecte vertaling laat onze werkwijze zien. Deze heeft gezorgd voor onze goede prijs-kwaliteitverhouding, uitstekende beoordelingen en vele tevreden klanten. Elke vertaalopdracht doorloopt een aantal stappen:</p>",
      // commercial_page_fifth_slide_dutch_paragraph: "<p>De weg naar de perfecte vertaling laat onze werkwijze zien. Deze heeft gezorgd voor onze goede prijs-kwaliteitverhouding, uitstekende beoordelingen en vele tevreden klanten. Elke vertaalopdracht doorloopt een aantal stappen:</p>",
      // // English
      // commercial_page_first_slide_english_heading: a.commercial_page_first_slide_dutch_heading,
      // commercial_page_first_slide_english_heading_mobile: a.commercial_page_first_slide_dutch_heading,
      // commercial_page_first_slide_english_paragraph_mobile: "<p>De weg naar de perfecte vertaling laat onze werkwijze zien. Deze heeft gezorgd voor onze goede prijs-kwaliteitverhouding, uitstekende beoordelingen en vele tevreden klanten. Elke vertaalopdracht doorloopt een aantal stappen:</p>",
      // commercial_page_first_slide_english_paragraph: "<p>De weg naar de perfecte vertaling laat onze werkwijze zien. Deze heeft gezorgd voor onze goede prijs-kwaliteitverhouding, uitstekende beoordelingen en vele tevreden klanten. Elke vertaalopdracht doorloopt een aantal stappen:</p>",
      // commercial_page_second_slide_english_heading: a.commercial_page_first_slide_dutch_heading,
      // commercial_page_second_slide_english_heading_mobile: a.commercial_page_first_slide_dutch_heading,
      // commercial_page_second_slide_english_paragraph_mobile: "<p>De weg naar de perfecte vertaling laat onze werkwijze zien. Deze heeft gezorgd voor onze goede prijs-kwaliteitverhouding, uitstekende beoordelingen en vele tevreden klanten. Elke vertaalopdracht doorloopt een aantal stappen:</p>",
      // commercial_page_second_slide_english_paragraph: "<p>De weg naar de perfecte vertaling laat onze werkwijze zien. Deze heeft gezorgd voor onze goede prijs-kwaliteitverhouding, uitstekende beoordelingen en vele tevreden klanten. Elke vertaalopdracht doorloopt een aantal stappen:</p>",
      // commercial_page_third_slide_english_heading: a.commercial_page_first_slide_dutch_heading,
      // commercial_page_third_slide_english_heading_mobile: a.commercial_page_first_slide_dutch_heading,
      // commercial_page_third_slide_english_paragraph_mobile: "<p>De weg naar de perfecte vertaling laat onze werkwijze zien. Deze heeft gezorgd voor onze goede prijs-kwaliteitverhouding, uitstekende beoordelingen en vele tevreden klanten. Elke vertaalopdracht doorloopt een aantal stappen:</p>",
      // commercial_page_third_slide_english_paragraph: "<p>De weg naar de perfecte vertaling laat onze werkwijze zien. Deze heeft gezorgd voor onze goede prijs-kwaliteitverhouding, uitstekende beoordelingen en vele tevreden klanten. Elke vertaalopdracht doorloopt een aantal stappen:</p>",
      // commercial_page_fourth_slide_english_heading: a.commercial_page_first_slide_dutch_heading,
      // commercial_page_fourth_slide_english_heading_mobile: a.commercial_page_first_slide_dutch_heading,
      // commercial_page_fourth_slide_english_paragraph_mobile: "<p>De weg naar de perfecte vertaling laat onze werkwijze zien. Deze heeft gezorgd voor onze goede prijs-kwaliteitverhouding, uitstekende beoordelingen en vele tevreden klanten. Elke vertaalopdracht doorloopt een aantal stappen:</p>",
      // commercial_page_fourth_slide_english_paragraph: "<p>De weg naar de perfecte vertaling laat onze werkwijze zien. Deze heeft gezorgd voor onze goede prijs-kwaliteitverhouding, uitstekende beoordelingen en vele tevreden klanten. Elke vertaalopdracht doorloopt een aantal stappen:</p>",
      // commercial_page_fifth_slide_english_heading: a.commercial_page_first_slide_dutch_heading,
      // commercial_page_fifth_slide_english_heading_mobile: a.commercial_page_first_slide_dutch_heading,
      // commercial_page_fifth_slide_english_paragraph_mobile: "<p>De weg naar de perfecte vertaling laat onze werkwijze zien. Deze heeft gezorgd voor onze goede prijs-kwaliteitverhouding, uitstekende beoordelingen en vele tevreden klanten. Elke vertaalopdracht doorloopt een aantal stappen:</p>",
      // commercial_page_fifth_slide_english_paragraph: "<p>De weg naar de perfecte vertaling laat onze werkwijze zien. Deze heeft gezorgd voor onze goede prijs-kwaliteitverhouding, uitstekende beoordelingen en vele tevreden klanten. Elke vertaalopdracht doorloopt een aantal stappen:</p>",
      // commercial_page_banner_dutch_heading_mobile_mobile: "<p>Charity shopping</p>",
      // commercial_page_banner_english_heading_mobile: "<p>Charity shopping</p>",
      // commercial_page_van_start_dutch_heading_mobile: "<p>Van start!</p>",
      // commercial_page_van_start_english_heading_mobile: "<p>Van start!</p>",
      // commercial_page_events_dutch_heading_inner_mobile: "<p>Events</p>",
      // commercial_page_events_english_heading_inner_mobile: "<p>Events</p>",
      // commercial_page_fan_base_dutch_heading_mobile: "<p>FANBASE</p>",
      // commercial_page_fan_base_english_heading_mobile: "<p>FANBASE</p>",
      // commercial_page_profile_dutch_heading_mobile: "<p>Profielpagina</p>",
      // commercial_page_profile_english_heading_mobile: "<p>Profielpagina</p>",
      // commercial_page_about_dutch_heading_mobile: "<p>About</p>",
      // commercial_page_about_english_heading_mobile: "<p>About</p>",
      // commercial_page_team_bhodi_dutch_heading_mobile: "<p>Team Bhodi</p>",
      // commercial_page_team_bhodi_english_heading_mobile: "<p>Team Bhodi</p>",
      // commercial_page_aanmelding_dutch_heading_mobile: "<p>AANMELDING</p>",
      // commercial_page_aanmelding_english_heading_mobile: "<p>AANMELDING</p>",
      // commercial_page_charity_member_dutch_heading_mobile: "<p>CHARITY MEMBER</p>",
      // commercial_page_charity_member_english_heading_mobile: "<p>CHARITY MEMBER</p>",
      // commercial_page_events_dutch_heading_mobile: "<p>POP-UP SALE EVENTS</p>",
      // commercial_page_events_english_heading_mobile: "<p>POP-UP SALE EVENTS</p>",
      // commercial_page_events_dutch_headertxt_mobile: "<p>Expect the unexpected</p>",
      // commercial_page_events_english_headertxt_mobile: "<p>Expect the unexpected</p>",
      // commercial_page_banner_dutch_mobile: " Als bedrijf kun je Bhodi ondersteunen door het beschikbaar stellen van mooie<br />aanbiedingen voor je klanten, relaties en de bestaande charity members van Bhodi.",
      // commercial_page_banner_english_mobile: " Als bedrijf kun je Bhodi ondersteunen door het beschikbaar stellen van mooie<br />aanbiedingen voor je klanten, relaties en de bestaande charity members van Bhodi.",
      // commercial_page_van_start_dutch_mobile: "In het voorjaar van 2022 gaan  we live met Bhodi.<br />Ontdek de mogelijkheden van het platform",
      // commercial_page_van_start_english_mobile: "In het voorjaar van 2022 gaan  we live met Bhodi.<br />Ontdek de mogelijkheden van het platform",
      // commercial_page_events_dutch_mobile: "Bhodi is een social commerce platform waarbij je<br />als winkelier of dienstverlener exclusieve pop-up events<br />kunt organiseren voor je bestaande en nieuwe klanten.<br />Een pop-up event is een aanbieding dat op een onverwachts<br />beschikbaar kan worden gesteld op je Bhodi profielpagina.<br /><br /><br />Je klanten kun je hiervoor uitnodigen via social media en nieuwsbrieven. Een charity pop-up event heeft als kracht dat het exceptioneel is en de datum en tijdstip verrassend zijn. De inschrijving voor het event, de beperkte beschikbaarheid en dat het een aanbieding is voor het goede doel, zorgt voor een exclusiviteit.",
      // commercial_page_events_english_mobile: "Bhodi is een social commerce platform waarbij je<br />als winkelier of dienstverlener exclusieve pop-up events<br />kunt organiseren voor je bestaande en nieuwe klanten.<br />Een pop-up event is een aanbieding dat op een onverwachts<br />beschikbaar kan worden gesteld op je Bhodi profielpagina.<br /><br /><br />Je klanten kun je hiervoor uitnodigen via social media en nieuwsbrieven. Een charity pop-up event heeft als kracht dat het exceptioneel is en de datum en tijdstip verrassend zijn. De inschrijving voor het event, de beperkte beschikbaarheid en dat het een aanbieding is voor het goede doel, zorgt voor een exclusiviteit.",
      // commercial_page_fan_base_dutch_mobile: "Blijf met je Bhodi profielpagina op<br />een leuke manier in contact met je klanten",
      // commercial_page_fan_base_english_mobile: "Blijf met je Bhodi profielpagina op<br />een leuke manier in contact met je klanten",
      // commercial_page_profile_dutch_mobile: "Op Bhodi krijg je als bedrijf een eigen profielpagina<br />waarop je eenvoudig de events kan toevoegen.<br /><br />Daarnaast bieden we de mogelijkheid dat je de<br />profielpagina kan voorzien van content en media<br />en je volgers up to date kunt houden van nieuwe<br />events, aanbiedingen en posts over je bedrijf.",
      // commercial_page_profile_english_mobile: "Op Bhodi krijg je als bedrijf een eigen profielpagina<br />waarop je eenvoudig de events kan toevoegen.<br /><br />Daarnaast bieden we de mogelijkheid dat je de<br />profielpagina kan voorzien van content en media<br />en je volgers up to date kunt houden van nieuwe<br />events, aanbiedingen en posts over je bedrijf.",
      // commercial_page_about_dutch_mobile: "Er zijn veel online platforms waarvan ondernemers<br />gebruiken maken voor het verkopen van hun producten of diensten.<br />Door de kortingen en de afdracht van een commissie en kosten<br />aan het platform, komen de marges erg onder druk te staan.<br /><br />Vanuit Bhodi vinden wij dit geen gezonde marktwerking en<br />daarom functioneert Bhodi volledig zonder bemiddelingsbijdrage.",
      // commercial_page_about_english_mobile: "Er zijn veel online platforms waarvan ondernemers<br />gebruiken maken voor het verkopen van hun producten of diensten.<br />Door de kortingen en de afdracht van een commissie en kosten<br />aan het platform, komen de marges erg onder druk te staan.<br /><br />Vanuit Bhodi vinden wij dit geen gezonde marktwerking en<br />daarom functioneert Bhodi volledig zonder bemiddelingsbijdrage.",
      // commercial_page_team_bhodi_dutch_mobile: " Als bedrijf kun je Bhodi ondersteunen door het beschikbaar<br />stellen van exclusieve pop-up events op je profielpagina.<br /><br />De profielpagina kun je onder de aandacht brengen bij je klanten<br />en kunnen ze zich eenvoudig registreren als charity member en<br />gebruik maken van de exclusieve kortingen.<br /><br />Op deze manier kunnen we voor Bhodi nieuwe charity members<br />werven en kinderen en scholen in Tanzania ondersteunen",
      // commercial_page_team_bhodi_english_mobile: " Als bedrijf kun je Bhodi ondersteunen door het beschikbaar<br />stellen van exclusieve pop-up events op je profielpagina.<br /><br />De profielpagina kun je onder de aandacht brengen bij je klanten<br />en kunnen ze zich eenvoudig registreren als charity member en<br />gebruik maken van de exclusieve kortingen.<br /><br />Op deze manier kunnen we voor Bhodi nieuwe charity members<br />werven en kinderen en scholen in Tanzania ondersteunen",
      // commercial_page_aanmelding_dutch_mobile: "Op jullie Bhodi profielpagina kan de klant zich<br />eenvoudig aanmelden voor het pop-up event.<br />Na zijn aanmelding kan de klant gebruik maken<br />van de betreffende aanbieding. Bijvoorbeeld een<br />kortingscode voor op jullie webshop. Of een<br />kortingsvoucher die gebruikt kan worden op locatie.<br /><br />De profielpagina wordt voorzien van een unieke<br />link. Zodat de klanten direct op je pagina<br />uit kunnen komen vanuit jullie socials.",
      // commercial_page_aanmelding_english_mobile: "Op jullie Bhodi profielpagina kan de klant zich<br />eenvoudig aanmelden voor het pop-up event.<br />Na zijn aanmelding kan de klant gebruik maken<br />van de betreffende aanbieding. Bijvoorbeeld een<br />kortingscode voor op jullie webshop. Of een<br />kortingsvoucher die gebruikt kan worden op locatie.<br /><br />De profielpagina wordt voorzien van een unieke<br />link. Zodat de klanten direct op je pagina<br />uit kunnen komen vanuit jullie socials.",
      // commercial_page_charity_member_dutch_mobile: "Wanneer het een nieuwe member is op Bhodi,<br />dan kunnen ze zich registreren als charity member<br />en hun bijdrage kiezen vanaf €4 per maand.<br /><br />Met deze bijdrage ondersteunen we kinderen en<br />scholen in Tanzania met maaltijden en materialen.<br />Als charity member krijgen ze toegang tot<br />alle exclusieve aanbiedingen op Bhodi.",
      // commercial_page_charity_member_english_mobile: "Wanneer het een nieuwe member is op Bhodi,<br />dan kunnen ze zich registreren als charity member<br />en hun bijdrage kiezen vanaf €4 per maand.<br /><br />Met deze bijdrage ondersteunen we kinderen en<br />scholen in Tanzania met maaltijden en materialen.<br />Als charity member krijgen ze toegang tot<br />alle exclusieve aanbiedingen op Bhodi.",
      // commercial_page_slider_dutch_heading_mobile: "<p>POP-UP EVENT</p>",
      // commercial_page_slider_dutch_heading: "<p>POP-UP EVENT</p>",
      // commercial_page_slider_english_heading_mobile: "<p>POP-UP EVENT</p>",
      // commercial_page_slider_english_heading: "<p>POP-UP EVENT</p>",
      // commercial_page_slider_english_para: "<p>Bekijk de verschillende pop-up events</p>",
      // commercial_page_slider_english_para_mobile: "<p>Bekijk de verschillende pop-up events</p>",
      // commercial_page_slider_dutch_para_mobile: "<p>Bekijk de verschillende pop-up events</p>",
      // commercial_page_slider_dutch_para: "<p>Bekijk de verschillende pop-up events</p>",
    };
  }

  componentDidMount() {
    const loaderId = document.getElementById("main-page-loader-id");
    loaderId.style.display = "none";

    setTimeout(() => {
      this.setState({ showStuffNow: true });
    }, 100);

    if (this.props.global_values) {
      let a = this.props.global_values;
      if (a) {
        this.setState({
          commercial_page_banner_dutch: a.commercial_page_banner_dutch,
          commercial_page_banner_english: a.commercial_page_banner_english,
          commercial_page_van_start_dutch: a.commercial_page_van_start_dutch,
          commercial_page_van_start_english:
            a.commercial_page_van_start_english,
          commercial_page_events_dutch: a.commercial_page_events_dutch,
          commercial_page_events_english: a.commercial_page_events_english,
          commercial_page_fan_base_dutch: a.commercial_page_fan_base_dutch,
          commercial_page_fan_base_english: a.commercial_page_fan_base_english,
          commercial_page_profile_dutch: a.commercial_page_profile_dutch,
          commercial_page_profile_english: a.commercial_page_profile_english,
          commercial_page_about_dutch: a.commercial_page_about_dutch,
          commercial_page_about_english: a.commercial_page_about_english,
          commercial_page_team_bhodi_dutch: a.commercial_page_team_bhodi_dutch,
          commercial_page_team_bhodi_english:
            a.commercial_page_team_bhodi_english,
          commercial_page_aanmelding_dutch: a.commercial_page_aanmelding_dutch,
          commercial_page_aanmelding_english:
            a.commercial_page_aanmelding_english,
          commercial_page_charity_member_dutch:
            a.commercial_page_charity_member_dutch,
          commercial_page_charity_member_english:
            a.commercial_page_charity_member_english,
          commercial_page_banner_dutch_heading:
            a.commercial_page_banner_dutch_heading,
          commercial_page_banner_english_heading:
            a.commercial_page_banner_english_heading,
          commercial_page_van_start_dutch_heading:
            a.commercial_page_van_start_dutch_heading,
          commercial_page_van_start_english_heading:
            a.commercial_page_van_start_english_heading,
          commercial_page_events_dutch_heading_inner:
            a.commercial_page_events_dutch_heading_inner,
          commercial_page_events_english_heading_inner:
            a.commercial_page_events_english_heading_inner,
          commercial_page_fan_base_dutch_heading:
            a.commercial_page_fan_base_dutch_heading,
          commercial_page_fan_base_english_heading:
            a.commercial_page_fan_base_english_heading,
          commercial_page_profile_dutch_heading:
            a.commercial_page_profile_dutch_heading,
          commercial_page_profile_english_heading:
            a.commercial_page_profile_english_heading,
          commercial_page_about_dutch_heading:
            a.commercial_page_about_dutch_heading,
          commercial_page_about_english_heading:
            a.commercial_page_about_english_heading,
          commercial_page_team_bhodi_dutch_heading:
            a.commercial_page_team_bhodi_dutch_heading,
          commercial_page_team_bhodi_english_heading:
            a.commercial_page_team_bhodi_english_heading,
          commercial_page_aanmelding_dutch_heading:
            a.commercial_page_aanmelding_dutch_heading,
          commercial_page_aanmelding_english_heading:
            a.commercial_page_aanmelding_english_heading,
          commercial_page_charity_member_dutch_heading:
            a.commercial_page_charity_member_dutch_heading,
          commercial_page_charity_member_english_heading:
            a.commercial_page_charity_member_english_heading,
          commercial_page_events_dutch_heading:
            a.commercial_page_events_dutch_heading,
          commercial_page_events_english_heading:
            a.commercial_page_events_english_heading,
          commercial_page_events_dutch_headertxt:
            a.commercial_page_events_dutch_headertxt,
          commercial_page_events_english_headertxt:
            a.commercial_page_events_english_headertxt,
          // Mobile
          commercial_page_banner_dutch_mobile:
            a.commercial_page_banner_dutch_mobile,
          commercial_page_banner_english_mobile:
            a.commercial_page_banner_english_mobile,
          commercial_page_van_start_dutch_mobile:
            a.commercial_page_van_start_dutch_mobile,
          commercial_page_van_start_english_mobile:
            a.commercial_page_van_start_english_mobile,
          commercial_page_events_dutch_mobile:
            a.commercial_page_events_dutch_mobile,
          commercial_page_events_english_mobile:
            a.commercial_page_events_english_mobile,
          commercial_page_fan_base_dutch_mobile:
            a.commercial_page_fan_base_dutch_mobile,
          commercial_page_fan_base_english_mobile:
            a.commercial_page_fan_base_english_mobile,
          commercial_page_profile_dutch_mobile:
            a.commercial_page_profile_dutch_mobile,
          commercial_page_profile_english_mobile:
            a.commercial_page_profile_english_mobile,
          commercial_page_about_dutch_mobile:
            a.commercial_page_about_dutch_mobile,
          commercial_page_about_english_mobile:
            a.commercial_page_about_english_mobile,
          commercial_page_team_bhodi_dutch_mobile:
            a.commercial_page_team_bhodi_dutch_mobile,
          commercial_page_team_bhodi_english_mobile:
            a.commercial_page_team_bhodi_english_mobile,
          commercial_page_aanmelding_dutch_mobile:
            a.commercial_page_aanmelding_dutch_mobile,
          commercial_page_aanmelding_english_mobile:
            a.commercial_page_aanmelding_english_mobile,
          commercial_page_charity_member_dutch_mobile:
            a.commercial_page_charity_member_dutch_mobile,
          commercial_page_charity_member_english_mobile:
            a.commercial_page_charity_member_english_mobile,
          commercial_page_banner_dutch_heading_mobile:
            a.commercial_page_banner_dutch_heading_mobile,
          commercial_page_banner_english_heading_mobile:
            a.commercial_page_banner_english_heading_mobile,
          commercial_page_van_start_dutch_heading_mobile:
            a.commercial_page_van_start_dutch_heading_mobile,
          commercial_page_van_start_english_heading_mobile:
            a.commercial_page_van_start_english_heading_mobile,
          commercial_page_events_dutch_heading_inner_mobile:
            a.commercial_page_events_dutch_heading_inner_mobile,
          commercial_page_events_english_heading_inner_mobile:
            a.commercial_page_events_english_heading_inner_mobile,
          commercial_page_fan_base_dutch_heading_mobile:
            a.commercial_page_fan_base_dutch_heading_mobile,
          commercial_page_fan_base_english_heading_mobile:
            a.commercial_page_fan_base_english_heading_mobile,
          commercial_page_profile_dutch_heading_mobile:
            a.commercial_page_profile_dutch_heading_mobile,
          commercial_page_profile_english_heading_mobile:
            a.commercial_page_profile_english_heading_mobile,
          commercial_page_about_dutch_heading_mobile:
            a.commercial_page_about_dutch_heading_mobile,
          commercial_page_about_english_heading_mobile:
            a.commercial_page_about_english_heading_mobile,
          commercial_page_team_bhodi_dutch_heading_mobile:
            a.commercial_page_team_bhodi_dutch_heading_mobile,
          commercial_page_team_bhodi_english_heading_mobile:
            a.commercial_page_team_bhodi_english_heading_mobile,
          commercial_page_aanmelding_dutch_heading_mobile:
            a.commercial_page_aanmelding_dutch_heading_mobile,
          commercial_page_aanmelding_english_heading_mobile:
            a.commercial_page_aanmelding_english_heading_mobile,
          commercial_page_charity_member_dutch_heading_mobile:
            a.commercial_page_charity_member_dutch_heading_mobile,
          commercial_page_charity_member_english_heading_mobile:
            a.commercial_page_charity_member_english_heading_mobile,
          commercial_page_events_dutch_heading_mobile:
            a.commercial_page_events_dutch_heading_mobile,
          commercial_page_events_english_heading_mobile:
            a.commercial_page_events_english_heading_mobile,
          commercial_page_events_dutch_headertxt_mobile:
            a.commercial_page_events_dutch_headertxt_mobile,
          commercial_page_events_english_headertxt_mobile:
            a.commercial_page_events_english_headertxt_mobile,
          // Slides
          commercial_page_first_slide_dutch_heading:
            a.commercial_page_first_slide_dutch_heading,
          commercial_page_first_slide_dutch_heading_mobile:
            a.commercial_page_first_slide_dutch_heading_mobile,
          commercial_page_first_slide_dutch_paragraph_mobile:
            a.commercial_page_first_slide_dutch_paragraph_mobile,
          commercial_page_first_slide_dutch_paragraph:
            a.commercial_page_first_slide_dutch_paragraph,
          commercial_page_second_slide_dutch_heading:
            a.commercial_page_second_slide_dutch_heading,
          commercial_page_second_slide_dutch_heading_mobile:
            a.commercial_page_second_slide_dutch_heading_mobile,
          commercial_page_second_slide_dutch_paragraph_mobile:
            a.commercial_page_second_slide_dutch_paragraph_mobile,
          commercial_page_second_slide_dutch_paragraph:
            a.commercial_page_second_slide_dutch_paragraph,
          commercial_page_third_slide_dutch_heading:
            a.commercial_page_third_slide_dutch_heading,
          commercial_page_third_slide_dutch_heading_mobile:
            a.commercial_page_third_slide_dutch_heading_mobile,
          commercial_page_third_slide_dutch_paragraph_mobile:
            a.commercial_page_third_slide_dutch_paragraph_mobile,
          commercial_page_third_slide_dutch_paragraph:
            a.commercial_page_third_slide_dutch_paragraph,
          commercial_page_fourth_slide_dutch_heading:
            a.commercial_page_fourth_slide_dutch_heading,
          commercial_page_fourth_slide_dutch_heading_mobile:
            a.commercial_page_fourth_slide_dutch_heading_mobile,
          commercial_page_fourth_slide_dutch_paragraph_mobile:
            a.commercial_page_fourth_slide_dutch_paragraph_mobile,
          commercial_page_fourth_slide_dutch_paragraph:
            a.commercial_page_fourth_slide_dutch_paragraph,
          commercial_page_fifth_slide_dutch_heading:
            a.commercial_page_fifth_slide_dutch_heading,
          commercial_page_fifth_slide_dutch_heading_mobile:
            a.commercial_page_fifth_slide_dutch_heading_mobile,
          commercial_page_fifth_slide_dutch_paragraph_mobile:
            a.commercial_page_fifth_slide_dutch_paragraph_mobile,
          commercial_page_fifth_slide_dutch_paragraph:
            a.commercial_page_fifth_slide_dutch_paragraph,
          // English
          commercial_page_first_slide_english_heading:
            a.commercial_page_first_slide_english_heading,
          commercial_page_first_slide_english_heading_mobile:
            a.commercial_page_first_slide_english_heading_mobile,
          commercial_page_first_slide_english_paragraph_mobile:
            a.commercial_page_first_slide_english_paragraph_mobile,
          commercial_page_first_slide_english_paragraph:
            a.commercial_page_first_slide_english_paragraph,
          commercial_page_second_slide_english_heading:
            a.commercial_page_second_slide_english_heading,
          commercial_page_second_slide_english_heading_mobile:
            a.commercial_page_second_slide_english_heading_mobile,
          commercial_page_second_slide_english_paragraph_mobile:
            a.commercial_page_second_slide_english_paragraph_mobile,
          commercial_page_second_slide_english_paragraph:
            a.commercial_page_second_slide_english_paragraph,
          commercial_page_third_slide_english_heading:
            a.commercial_page_third_slide_english_heading,
          commercial_page_third_slide_english_heading_mobile:
            a.commercial_page_third_slide_english_heading_mobile,
          commercial_page_third_slide_english_paragraph_mobile:
            a.commercial_page_third_slide_english_paragraph_mobile,
          commercial_page_third_slide_english_paragraph:
            a.commercial_page_third_slide_english_paragraph,
          commercial_page_fourth_slide_english_heading:
            a.commercial_page_fourth_slide_english_heading,
          commercial_page_fourth_slide_english_heading_mobile:
            a.commercial_page_fourth_slide_english_heading_mobile,
          commercial_page_fourth_slide_english_paragraph_mobile:
            a.commercial_page_fourth_slide_english_paragraph_mobile,
          commercial_page_fourth_slide_english_paragraph:
            a.commercial_page_fourth_slide_english_paragraph,
          commercial_page_fifth_slide_english_heading:
            a.commercial_page_fifth_slide_english_heading,
          commercial_page_fifth_slide_english_heading_mobile:
            a.commercial_page_fifth_slide_english_heading_mobile,
          commercial_page_fifth_slide_english_paragraph_mobile:
            a.commercial_page_fifth_slide_english_paragraph_mobile,
          commercial_page_fifth_slide_english_paragraph:
            a.commercial_page_fifth_slide_english_paragraph,

          commercial_page_slider_dutch_heading_mobile:
            a.commercial_page_slider_dutch_heading_mobile,
          commercial_page_slider_dutch_heading:
            a.commercial_page_slider_dutch_heading,
          commercial_page_slider_english_heading_mobile:
            a.commercial_page_slider_english_heading_mobile,
          commercial_page_slider_english_heading:
            a.commercial_page_slider_english_heading,
          commercial_page_slider_english_para:
            a.commercial_page_slider_english_para,
          commercial_page_slider_english_para_mobile:
            a.commercial_page_slider_english_para_mobile,
          commercial_page_slider_dutch_para_mobile:
            a.commercial_page_slider_dutch_para_mobile,
          commercial_page_slider_dutch_para:
            a.commercial_page_slider_dutch_para,
        });
      }
    }

    firebase
      .database()
      .ref("bhodi_home")
      .on("value", (data) => {
        let a = data.val();
        if (a) {
          this.props.updateGlobalStuff(a);
          this.setState({
            commercial_page_banner_dutch: a.commercial_page_banner_dutch,
            commercial_page_banner_english: a.commercial_page_banner_english,
            commercial_page_van_start_dutch: a.commercial_page_van_start_dutch,
            commercial_page_van_start_english:
              a.commercial_page_van_start_english,
            commercial_page_events_dutch: a.commercial_page_events_dutch,
            commercial_page_events_english: a.commercial_page_events_english,
            commercial_page_fan_base_dutch: a.commercial_page_fan_base_dutch,
            commercial_page_fan_base_english:
              a.commercial_page_fan_base_english,
            commercial_page_profile_dutch: a.commercial_page_profile_dutch,
            commercial_page_profile_english: a.commercial_page_profile_english,
            commercial_page_about_dutch: a.commercial_page_about_dutch,
            commercial_page_about_english: a.commercial_page_about_english,
            commercial_page_team_bhodi_dutch:
              a.commercial_page_team_bhodi_dutch,
            commercial_page_team_bhodi_english:
              a.commercial_page_team_bhodi_english,
            commercial_page_aanmelding_dutch:
              a.commercial_page_aanmelding_dutch,
            commercial_page_aanmelding_english:
              a.commercial_page_aanmelding_english,
            commercial_page_charity_member_dutch:
              a.commercial_page_charity_member_dutch,
            commercial_page_charity_member_english:
              a.commercial_page_charity_member_english,
            commercial_page_banner_dutch_heading:
              a.commercial_page_banner_dutch_heading,
            commercial_page_banner_english_heading:
              a.commercial_page_banner_english_heading,
            commercial_page_van_start_dutch_heading:
              a.commercial_page_van_start_dutch_heading,
            commercial_page_van_start_english_heading:
              a.commercial_page_van_start_english_heading,
            commercial_page_events_dutch_heading_inner:
              a.commercial_page_events_dutch_heading_inner,
            commercial_page_events_english_heading_inner:
              a.commercial_page_events_english_heading_inner,
            commercial_page_fan_base_dutch_heading:
              a.commercial_page_fan_base_dutch_heading,
            commercial_page_fan_base_english_heading:
              a.commercial_page_fan_base_english_heading,
            commercial_page_profile_dutch_heading:
              a.commercial_page_profile_dutch_heading,
            commercial_page_profile_english_heading:
              a.commercial_page_profile_english_heading,
            commercial_page_about_dutch_heading:
              a.commercial_page_about_dutch_heading,
            commercial_page_about_english_heading:
              a.commercial_page_about_english_heading,
            commercial_page_team_bhodi_dutch_heading:
              a.commercial_page_team_bhodi_dutch_heading,
            commercial_page_team_bhodi_english_heading:
              a.commercial_page_team_bhodi_english_heading,
            commercial_page_aanmelding_dutch_heading:
              a.commercial_page_aanmelding_dutch_heading,
            commercial_page_aanmelding_english_heading:
              a.commercial_page_aanmelding_english_heading,
            commercial_page_charity_member_dutch_heading:
              a.commercial_page_charity_member_dutch_heading,
            commercial_page_charity_member_english_heading:
              a.commercial_page_charity_member_english_heading,
            commercial_page_events_dutch_heading:
              a.commercial_page_events_dutch_heading,
            commercial_page_events_english_heading:
              a.commercial_page_events_english_heading,
            commercial_page_events_dutch_headertxt:
              a.commercial_page_events_dutch_headertxt,
            commercial_page_events_english_headertxt:
              a.commercial_page_events_english_headertxt,
            // Mobile
            commercial_page_banner_dutch_mobile:
              a.commercial_page_banner_dutch_mobile,
            commercial_page_banner_english_mobile:
              a.commercial_page_banner_english_mobile,
            commercial_page_van_start_dutch_mobile:
              a.commercial_page_van_start_dutch_mobile,
            commercial_page_van_start_english_mobile:
              a.commercial_page_van_start_english_mobile,
            commercial_page_events_dutch_mobile:
              a.commercial_page_events_dutch_mobile,
            commercial_page_events_english_mobile:
              a.commercial_page_events_english_mobile,
            commercial_page_fan_base_dutch_mobile:
              a.commercial_page_fan_base_dutch_mobile,
            commercial_page_fan_base_english_mobile:
              a.commercial_page_fan_base_english_mobile,
            commercial_page_profile_dutch_mobile:
              a.commercial_page_profile_dutch_mobile,
            commercial_page_profile_english_mobile:
              a.commercial_page_profile_english_mobile,
            commercial_page_about_dutch_mobile:
              a.commercial_page_about_dutch_mobile,
            commercial_page_about_english_mobile:
              a.commercial_page_about_english_mobile,
            commercial_page_team_bhodi_dutch_mobile:
              a.commercial_page_team_bhodi_dutch_mobile,
            commercial_page_team_bhodi_english_mobile:
              a.commercial_page_team_bhodi_english_mobile,
            commercial_page_aanmelding_dutch_mobile:
              a.commercial_page_aanmelding_dutch_mobile,
            commercial_page_aanmelding_english_mobile:
              a.commercial_page_aanmelding_english_mobile,
            commercial_page_charity_member_dutch_mobile:
              a.commercial_page_charity_member_dutch_mobile,
            commercial_page_charity_member_english_mobile:
              a.commercial_page_charity_member_english_mobile,
            commercial_page_banner_dutch_heading_mobile:
              a.commercial_page_banner_dutch_heading_mobile,
            commercial_page_banner_english_heading_mobile:
              a.commercial_page_banner_english_heading_mobile,
            commercial_page_van_start_dutch_heading_mobile:
              a.commercial_page_van_start_dutch_heading_mobile,
            commercial_page_van_start_english_heading_mobile:
              a.commercial_page_van_start_english_heading_mobile,
            commercial_page_events_dutch_heading_inner_mobile:
              a.commercial_page_events_dutch_heading_inner_mobile,
            commercial_page_events_english_heading_inner_mobile:
              a.commercial_page_events_english_heading_inner_mobile,
            commercial_page_fan_base_dutch_heading_mobile:
              a.commercial_page_fan_base_dutch_heading_mobile,
            commercial_page_fan_base_english_heading_mobile:
              a.commercial_page_fan_base_english_heading_mobile,
            commercial_page_profile_dutch_heading_mobile:
              a.commercial_page_profile_dutch_heading_mobile,
            commercial_page_profile_english_heading_mobile:
              a.commercial_page_profile_english_heading_mobile,
            commercial_page_about_dutch_heading_mobile:
              a.commercial_page_about_dutch_heading_mobile,
            commercial_page_about_english_heading_mobile:
              a.commercial_page_about_english_heading_mobile,
            commercial_page_team_bhodi_dutch_heading_mobile:
              a.commercial_page_team_bhodi_dutch_heading_mobile,
            commercial_page_team_bhodi_english_heading_mobile:
              a.commercial_page_team_bhodi_english_heading_mobile,
            commercial_page_aanmelding_dutch_heading_mobile:
              a.commercial_page_aanmelding_dutch_heading_mobile,
            commercial_page_aanmelding_english_heading_mobile:
              a.commercial_page_aanmelding_english_heading_mobile,
            commercial_page_charity_member_dutch_heading_mobile:
              a.commercial_page_charity_member_dutch_heading_mobile,
            commercial_page_charity_member_english_heading_mobile:
              a.commercial_page_charity_member_english_heading_mobile,
            commercial_page_events_dutch_heading_mobile:
              a.commercial_page_events_dutch_heading_mobile,
            commercial_page_events_english_heading_mobile:
              a.commercial_page_events_english_heading_mobile,
            commercial_page_events_dutch_headertxt_mobile:
              a.commercial_page_events_dutch_headertxt_mobile,
            commercial_page_events_english_headertxt_mobile:
              a.commercial_page_events_english_headertxt_mobile,
            // Slides
            commercial_page_first_slide_dutch_heading:
              a.commercial_page_first_slide_dutch_heading,
            commercial_page_first_slide_dutch_heading_mobile:
              a.commercial_page_first_slide_dutch_heading_mobile,
            commercial_page_first_slide_dutch_paragraph_mobile:
              a.commercial_page_first_slide_dutch_paragraph_mobile,
            commercial_page_first_slide_dutch_paragraph:
              a.commercial_page_first_slide_dutch_paragraph,
            commercial_page_second_slide_dutch_heading:
              a.commercial_page_second_slide_dutch_heading,
            commercial_page_second_slide_dutch_heading_mobile:
              a.commercial_page_second_slide_dutch_heading_mobile,
            commercial_page_second_slide_dutch_paragraph_mobile:
              a.commercial_page_second_slide_dutch_paragraph_mobile,
            commercial_page_second_slide_dutch_paragraph:
              a.commercial_page_second_slide_dutch_paragraph,
            commercial_page_third_slide_dutch_heading:
              a.commercial_page_third_slide_dutch_heading,
            commercial_page_third_slide_dutch_heading_mobile:
              a.commercial_page_third_slide_dutch_heading_mobile,
            commercial_page_third_slide_dutch_paragraph_mobile:
              a.commercial_page_third_slide_dutch_paragraph_mobile,
            commercial_page_third_slide_dutch_paragraph:
              a.commercial_page_third_slide_dutch_paragraph,
            commercial_page_fourth_slide_dutch_heading:
              a.commercial_page_fourth_slide_dutch_heading,
            commercial_page_fourth_slide_dutch_heading_mobile:
              a.commercial_page_fourth_slide_dutch_heading_mobile,
            commercial_page_fourth_slide_dutch_paragraph_mobile:
              a.commercial_page_fourth_slide_dutch_paragraph_mobile,
            commercial_page_fourth_slide_dutch_paragraph:
              a.commercial_page_fourth_slide_dutch_paragraph,
            commercial_page_fifth_slide_dutch_heading:
              a.commercial_page_fifth_slide_dutch_heading,
            commercial_page_fifth_slide_dutch_heading_mobile:
              a.commercial_page_fifth_slide_dutch_heading_mobile,
            commercial_page_fifth_slide_dutch_paragraph_mobile:
              a.commercial_page_fifth_slide_dutch_paragraph_mobile,
            commercial_page_fifth_slide_dutch_paragraph:
              a.commercial_page_fifth_slide_dutch_paragraph,
            // English
            commercial_page_first_slide_english_heading:
              a.commercial_page_first_slide_english_heading,
            commercial_page_first_slide_english_heading_mobile:
              a.commercial_page_first_slide_english_heading_mobile,
            commercial_page_first_slide_english_paragraph_mobile:
              a.commercial_page_first_slide_english_paragraph_mobile,
            commercial_page_first_slide_english_paragraph:
              a.commercial_page_first_slide_english_paragraph,
            commercial_page_second_slide_english_heading:
              a.commercial_page_second_slide_english_heading,
            commercial_page_second_slide_english_heading_mobile:
              a.commercial_page_second_slide_english_heading_mobile,
            commercial_page_second_slide_english_paragraph_mobile:
              a.commercial_page_second_slide_english_paragraph_mobile,
            commercial_page_second_slide_english_paragraph:
              a.commercial_page_second_slide_english_paragraph,
            commercial_page_third_slide_english_heading:
              a.commercial_page_third_slide_english_heading,
            commercial_page_third_slide_english_heading_mobile:
              a.commercial_page_third_slide_english_heading_mobile,
            commercial_page_third_slide_english_paragraph_mobile:
              a.commercial_page_third_slide_english_paragraph_mobile,
            commercial_page_third_slide_english_paragraph:
              a.commercial_page_third_slide_english_paragraph,
            commercial_page_fourth_slide_english_heading:
              a.commercial_page_fourth_slide_english_heading,
            commercial_page_fourth_slide_english_heading_mobile:
              a.commercial_page_fourth_slide_english_heading_mobile,
            commercial_page_fourth_slide_english_paragraph_mobile:
              a.commercial_page_fourth_slide_english_paragraph_mobile,
            commercial_page_fourth_slide_english_paragraph:
              a.commercial_page_fourth_slide_english_paragraph,
            commercial_page_fifth_slide_english_heading:
              a.commercial_page_fifth_slide_english_heading,
            commercial_page_fifth_slide_english_heading_mobile:
              a.commercial_page_fifth_slide_english_heading_mobile,
            commercial_page_fifth_slide_english_paragraph_mobile:
              a.commercial_page_fifth_slide_english_paragraph_mobile,
            commercial_page_fifth_slide_english_paragraph:
              a.commercial_page_fifth_slide_english_paragraph,

            commercial_page_slider_dutch_heading_mobile:
              a.commercial_page_slider_dutch_heading_mobile,
            commercial_page_slider_dutch_heading:
              a.commercial_page_slider_dutch_heading,
            commercial_page_slider_english_heading_mobile:
              a.commercial_page_slider_english_heading_mobile,
            commercial_page_slider_english_heading:
              a.commercial_page_slider_english_heading,
            commercial_page_slider_english_para:
              a.commercial_page_slider_english_para,
            commercial_page_slider_english_para_mobile:
              a.commercial_page_slider_english_para_mobile,
            commercial_page_slider_dutch_para_mobile:
              a.commercial_page_slider_dutch_para_mobile,
            commercial_page_slider_dutch_para:
              a.commercial_page_slider_dutch_para,
          });
        }
      });
  }

  changeAlertBannerDutch(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_banner_dutch_mobile")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_banner_dutch_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_banner_dutch")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_banner_dutch: e });
        });
    }
  }

  changeAlertBannerEnglish(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_banner_english_mobile")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_banner_english_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_banner_english")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_banner_english: e });
        });
    }
  }

  changeAlertEventsDutch(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_events_dutch_mobile")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_events_dutch_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_events_dutch")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_events_dutch: e });
        });
    }
  }

  changeAlertEventsEnglish(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_events_english_mobile")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_events_english_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_events_english")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_events_english: e });
        });
    }
  }

  changeAlertVanStartDutch(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_van_start_dutch_mobile")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_van_start_dutch_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_van_start_dutch")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_van_start_dutch: e });
        });
    }
  }

  changeAlertVanStartEnglish(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_van_start_english_mobile")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_van_start_english_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_van_start_english")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_van_start_english: e });
        });
    }
  }

  changeAlertNewProfileDutch(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_profile_dutch_mobile")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_profile_dutch_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_profile_dutch")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_profile_dutch: e });
        });
    }
  }

  changeAlertNewProfileEnglish(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_profile_english_mobile")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_profile_english_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_profile_english")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_profile_english: e });
        });
    }
  }

  changeAlertFanBaseDutch(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_fan_base_dutch_mobile")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_fan_base_dutch_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_fan_base_dutch")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_fan_base_dutch: e });
        });
    }
  }

  changeAlertFanBaseEnglish(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_fan_base_english_mobile")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_fan_base_english_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_fan_base_english")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_fan_base_english: e });
        });
    }
  }

  changeAlertAboutDutch(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_about_dutch_mobile")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_about_dutch_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_about_dutch")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_about_dutch: e });
        });
    }
  }

  changeAlertAboutEnglish(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_about_english_mobile")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_about_english_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_about_english")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_about_english: e });
        });
    }
  }

  changeAlertTeamBhodiDutch(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_team_bhodi_dutch_mobile")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_team_bhodi_dutch_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_team_bhodi_dutch")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_team_bhodi_dutch: e });
        });
    }
  }

  changeAlertTeamBhodiEnglish(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_team_bhodi_english_mobile")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_team_bhodi_english_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_team_bhodi_english")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_team_bhodi_english: e });
        });
    }
  }

  changeAlertAanmeldingDutch(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_aanmelding_dutch_mobile")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_aanmelding_dutch_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_aanmelding_dutch")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_aanmelding_dutch: e });
        });
    }
  }

  changeAlertAanmeldingEnglish(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_aanmelding_english_mobile")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_aanmelding_english_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_aanmelding_english")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_aanmelding_english: e });
        });
    }
  }

  changeAlertCharityMemberDutch(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_charity_member_dutch_mobile")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_charity_member_dutch_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_charity_member_dutch")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_charity_member_dutch: e });
        });
    }
  }

  changeAlertCharityMemberEnglish(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_charity_member_english_mobile")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_charity_member_english_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_charity_member_english")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_charity_member_english: e });
        });
    }
  }

  //

  changeAlertBannerDutchHeading(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_banner_dutch_heading_mobile")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_banner_dutch_heading_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_banner_dutch_heading")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_banner_dutch_heading: e });
        });
    }
  }

  changeAlertBannerEnglishHeading(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_banner_english_heading_mobile")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_banner_english_heading_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_banner_english_heading")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_banner_english_heading: e });
        });
    }
  }

  changeAlertVanStartDutchHeading(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_van_start_dutch_heading_mobile")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_van_start_dutch_heading_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_van_start_dutch_heading")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_van_start_dutch_heading: e });
        });
    }
  }

  changeAlertVanStartEnglishHeading(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_van_start_english_heading_mobile")
        .set(e)
        .then(() => {
          this.setState({
            commercial_page_van_start_english_heading_mobile: e,
          });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_van_start_english_heading")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_van_start_english_heading: e });
        });
    }
  }

  changeAlertEventsDutchHeadingInner(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_events_dutch_heading_inner_mobile")
        .set(e)
        .then(() => {
          this.setState({
            commercial_page_events_dutch_heading_inner_mobile: e,
          });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_events_dutch_heading_inner")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_events_dutch_heading_inner: e });
        });
    }
  }

  changeAlertEventsEnglishHeadingInner(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_events_english_heading_inner_mobile")
        .set(e)
        .then(() => {
          this.setState({
            commercial_page_events_english_heading_inner_mobile: e,
          });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_events_english_heading_inner")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_events_english_heading_inner: e });
        });
    }
  }

  changeAlertFanBaseDutchHeading(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_fan_base_dutch_heading_mobile")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_fan_base_dutch_heading_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_fan_base_dutch_heading")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_fan_base_dutch_heading: e });
        });
    }
  }

  changeAlertFanBaseEnglishHeading(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_fan_base_english_heading_mobile")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_fan_base_english_heading_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_fan_base_english_heading")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_fan_base_english_heading: e });
        });
    }
  }

  changeAlertNewProfileDutchHeading(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_profile_dutch_heading_mobile")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_profile_dutch_heading_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_profile_dutch_heading")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_profile_dutch_heading: e });
        });
    }
  }

  changeAlertNewProfileEnglishHeading(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_profile_english_heading_mobile")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_profile_english_heading_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_profile_english_heading")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_profile_english_heading: e });
        });
    }
  }

  changeAlertAboutDutchHeading(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_about_dutch_heading_mobile")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_about_dutch_heading_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_about_dutch_heading")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_about_dutch_heading: e });
        });
    }
  }

  changeAlertAboutEnglishHeading(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_about_english_heading_mobile")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_about_english_heading_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_about_english_heading")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_about_english_heading: e });
        });
    }
  }

  changeAlertTeamBhodiDutchHeading(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_team_bhodi_dutch_heading_mobile")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_team_bhodi_dutch_heading_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_team_bhodi_dutch_heading")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_team_bhodi_dutch_heading: e });
        });
    }
  }

  changeAlertTeamBhodiEnglishHeading(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_team_bhodi_english_heading_mobile")
        .set(e)
        .then(() => {
          this.setState({
            commercial_page_team_bhodi_english_heading_mobile: e,
          });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_team_bhodi_english_heading")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_team_bhodi_english_heading: e });
        });
    }
  }

  changeAlertAanmeldingDutchHeading(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_aanmelding_dutch_heading_mobile")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_aanmelding_dutch_heading_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_aanmelding_dutch_heading")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_aanmelding_dutch_heading: e });
        });
    }
  }

  changeAlertAanmeldingEnglishHeading(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_aanmelding_english_heading_mobile")
        .set(e)
        .then(() => {
          this.setState({
            commercial_page_aanmelding_english_heading_mobile: e,
          });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_aanmelding_english_heading")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_aanmelding_english_heading: e });
        });
    }
  }

  changeAlertCharityMemberDutchHeading(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_charity_member_dutch_heading_mobile")
        .set(e)
        .then(() => {
          this.setState({
            commercial_page_charity_member_dutch_heading_mobile: e,
          });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_charity_member_dutch_heading")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_charity_member_dutch_heading: e });
        });
    }
  }

  changeAlertCharityMemberEnglishHeading(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_charity_member_english_heading_mobile")
        .set(e)
        .then(() => {
          this.setState({
            commercial_page_charity_member_english_heading_mobile: e,
          });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_charity_member_english_heading")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_charity_member_english_heading: e });
        });
    }
  }

  changeAlertEventsDutchHeading(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_events_dutch_heading_mobile")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_events_dutch_heading_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_events_dutch_heading")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_events_dutch_heading: e });
        });
    }
  }

  changeAlertEventsEnglishHeading(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_events_english_heading_mobile")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_events_english_heading_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_events_english_heading")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_events_english_heading: e });
        });
    }
  }

  changeAlertEventsDutchHeaderText(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_events_dutch_headertxt_mobile")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_events_dutch_headertxt_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_events_dutch_headertxt")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_events_dutch_headertxt: e });
        });
    }
  }

  changeAlertEventsEnglishHeaderText(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_events_english_headertxt_mobile")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_events_english_headertxt_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_events_english_headertxt")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_events_english_headertxt: e });
        });
    }
  }

  // sliders

  changeAlertSlidesDutchHeading(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_slider_dutch_heading_mobile")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_slider_dutch_heading_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_slider_dutch_heading")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_slider_dutch_heading: e });
        });
    }
  }

  changeAlertSliderEnglishHeading(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_slider_english_heading_mobile")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_slider_english_heading_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_slider_english_heading")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_slider_english_heading: e });
        });
    }
  }

  changeAlertSlidesDutchPara(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_slider_dutch_para_mobile")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_slider_dutch_para_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_slider_dutch_para")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_slider_dutch_para: e });
        });
    }
  }

  changeAlertSliderEnglishPara(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_slider_english_para_mobile")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_slider_english_para_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_slider_english_para")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_slider_english_para: e });
        });
    }
  }

  changeAlertFirstSlideHeadingDutch(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_first_slide_dutch_heading_mobile")
        .set(e)
        .then(() => {
          this.setState({
            commercial_page_first_slide_dutch_heading_mobile: e,
          });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_first_slide_dutch_heading")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_first_slide_dutch_heading: e });
        });
    }
  }

  changeAlertFirstSlideParagraphDutch(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_first_slide_dutch_paragraph_mobile")
        .set(e)
        .then(() => {
          this.setState({
            commercial_page_first_slide_dutch_paragraph_mobile: e,
          });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_first_slide_dutch_paragraph")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_first_slide_dutch_paragraph: e });
        });
    }
  }

  changeAlertSecondSlideHeadingDutch(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_second_slide_dutch_heading_mobile")
        .set(e)
        .then(() => {
          this.setState({
            commercial_page_second_slide_dutch_heading_mobile: e,
          });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_second_slide_dutch_heading")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_second_slide_dutch_heading: e });
        });
    }
  }

  changeAlertSecondSlideParagraphDutch(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_second_slide_dutch_paragraph_mobile")
        .set(e)
        .then(() => {
          this.setState({
            commercial_page_second_slide_dutch_paragraph_mobile: e,
          });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_second_slide_dutch_paragraph")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_second_slide_dutch_paragraph: e });
        });
    }
  }

  changeAlertThirdSlideHeadingDutch(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_third_slide_dutch_heading_mobile")
        .set(e)
        .then(() => {
          this.setState({
            commercial_page_third_slide_dutch_heading_mobile: e,
          });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_third_slide_dutch_heading")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_third_slide_dutch_heading: e });
        });
    }
  }

  changeAlertThirdSlideParagraphDutch(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_third_slide_dutch_paragraph_mobile")
        .set(e)
        .then(() => {
          this.setState({
            commercial_page_third_slide_dutch_paragraph_mobile: e,
          });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_third_slide_dutch_paragraph")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_third_slide_dutch_paragraph: e });
        });
    }
  }

  changeAlertFourthSlideHeadingDutch(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_fourth_slide_dutch_heading_mobile")
        .set(e)
        .then(() => {
          this.setState({
            commercial_page_fourth_slide_dutch_heading_mobile: e,
          });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_fourth_slide_dutch_heading")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_fourth_slide_dutch_heading: e });
        });
    }
  }

  changeAlertFourthSlideParagraphDutch(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_fourth_slide_dutch_paragraph_mobile")
        .set(e)
        .then(() => {
          this.setState({
            commercial_page_fourth_slide_dutch_paragraph_mobile: e,
          });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_fourth_slide_dutch_paragraph")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_fourth_slide_dutch_paragraph: e });
        });
    }
  }

  changeAlertFifthSlideHeadingDutch(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_fifth_slide_dutch_heading_mobile")
        .set(e)
        .then(() => {
          this.setState({
            commercial_page_fifth_slide_dutch_heading_mobile: e,
          });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_fifth_slide_dutch_heading")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_fifth_slide_dutch_heading: e });
        });
    }
  }

  changeAlertFifthSlideParagraphDutch(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_fifth_slide_dutch_paragraph_mobile")
        .set(e)
        .then(() => {
          this.setState({
            commercial_page_fifth_slide_dutch_paragraph_mobile: e,
          });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_fifth_slide_dutch_paragraph")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_fifth_slide_dutch_paragraph: e });
        });
    }
  }

  changeAlertFirstSlideHeading(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_first_slide_english_heading_mobile")
        .set(e)
        .then(() => {
          this.setState({
            commercial_page_first_slide_english_heading_mobile: e,
          });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_first_slide_english_heading")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_first_slide_english_heading: e });
        });
    }
  }

  changeAlertFirstSlideParagraph(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_first_slide_english_paragraph_mobile")
        .set(e)
        .then(() => {
          this.setState({
            commercial_page_first_slide_english_paragraph_mobile: e,
          });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_first_slide_english_paragraph")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_first_slide_english_paragraph: e });
        });
    }
  }

  changeAlertSecondSlideHeading(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_second_slide_english_heading_mobile")
        .set(e)
        .then(() => {
          this.setState({
            commercial_page_second_slide_english_heading_mobile: e,
          });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_second_slide_english_heading")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_second_slide_english_heading: e });
        });
    }
  }

  changeAlertSecondSlideParagraph(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_second_slide_english_paragraph_mobile")
        .set(e)
        .then(() => {
          this.setState({
            commercial_page_second_slide_english_paragraph_mobile: e,
          });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_second_slide_english_paragraph")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_second_slide_english_paragraph: e });
        });
    }
  }

  changeAlertThirdSlideHeading(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_third_slide_english_heading_mobile")
        .set(e)
        .then(() => {
          this.setState({
            commercial_page_third_slide_english_heading_mobile: e,
          });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_third_slide_english_heading")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_third_slide_english_heading: e });
        });
    }
  }

  changeAlertThirdSlideParagraph(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_third_slide_english_paragraph_mobile")
        .set(e)
        .then(() => {
          this.setState({
            commercial_page_third_slide_english_paragraph_mobile: e,
          });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_third_slide_english_paragraph")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_third_slide_english_paragraph: e });
        });
    }
  }

  changeAlertFourthSlideHeading(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_fourth_slide_english_heading_mobile")
        .set(e)
        .then(() => {
          this.setState({
            commercial_page_fourth_slide_english_heading_mobile: e,
          });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_fourth_slide_english_heading")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_fourth_slide_english_heading: e });
        });
    }
  }

  changeAlertFourthSlideParagraph(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_fourth_slide_english_paragraph_mobile")
        .set(e)
        .then(() => {
          this.setState({
            commercial_page_fourth_slide_english_paragraph_mobile: e,
          });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_fourth_slide_english_paragraph")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_fourth_slide_english_paragraph: e });
        });
    }
  }

  changeAlertFifthSlideHeading(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_fifth_slide_english_heading_mobile")
        .set(e)
        .then(() => {
          this.setState({
            commercial_page_fifth_slide_english_heading_mobile: e,
          });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_fifth_slide_english_heading")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_fifth_slide_english_heading: e });
        });
    }
  }

  changeAlertFifthSlideParagraph(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_fifth_slide_english_paragraph_mobile")
        .set(e)
        .then(() => {
          this.setState({
            commercial_page_fifth_slide_english_paragraph_mobile: e,
          });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/commercial_page_fifth_slide_english_paragraph")
        .set(e)
        .then(() => {
          this.setState({ commercial_page_fifth_slide_english_paragraph: e });
        });
    }
  }

  fileUploadFirstSlide(e) {
    let files = e.target.files[0];
    let storageRef = firebase
      .storage()
      .ref()
      .child(`bhodi_home_slider/${files.name}${new Date().getTime()}`);
    storageRef.put(files).then((snapshot) => {
      snapshot.ref.getDownloadURL().then((snapUrl) => {
        firebase
          .database()
          .ref("bhodi_home/commercial_page_first_slide_image/")
          .set(snapUrl)
          .then(() => {});
      });
    });
  }

  fileUploadSecondSlide(e) {
    let files = e.target.files[0];
    let storageRef = firebase
      .storage()
      .ref()
      .child(`bhodi_home_slider/${files.name}${new Date().getTime()}`);
    storageRef.put(files).then((snapshot) => {
      snapshot.ref.getDownloadURL().then((snapUrl) => {
        firebase
          .database()
          .ref("bhodi_home/commercial_page_second_slide_image/")
          .set(snapUrl)
          .then(() => {});
      });
    });
  }

  fileUploadThirdSlide(e) {
    let files = e.target.files[0];
    let storageRef = firebase
      .storage()
      .ref()
      .child(`bhodi_home_slider/${files.name}${new Date().getTime()}`);
    storageRef.put(files).then((snapshot) => {
      snapshot.ref.getDownloadURL().then((snapUrl) => {
        firebase
          .database()
          .ref("bhodi_home/commercial_page_third_slide_image/")
          .set(snapUrl)
          .then(() => {});
      });
    });
  }

  fileUploadFourthSlide(e) {
    let files = e.target.files[0];
    let storageRef = firebase
      .storage()
      .ref()
      .child(`bhodi_home_slider/${files.name}${new Date().getTime()}`);
    storageRef.put(files).then((snapshot) => {
      snapshot.ref.getDownloadURL().then((snapUrl) => {
        firebase
          .database()
          .ref("bhodi_home/commercial_page_fourth_slide_image/")
          .set(snapUrl)
          .then(() => {});
      });
    });
  }

  fileUploadFifthSlide(e) {
    let files = e.target.files[0];
    let storageRef = firebase
      .storage()
      .ref()
      .child(`bhodi_home_slider/${files.name}${new Date().getTime()}`);
    storageRef.put(files).then((snapshot) => {
      snapshot.ref.getDownloadURL().then((snapUrl) => {
        firebase
          .database()
          .ref("bhodi_home/commercial_page_fifth_slide_image/")
          .set(snapUrl)
          .then(() => {});
      });
    });
  }

  fileUploadBannerVideo(e) {
    let files = e.target.files[0];
    let storageRef = firebase
      .storage()
      .ref()
      .child(`bhodi_home_media/${files.name}${new Date().getTime()}`);
    storageRef.put(files).then((snapshot) => {
      snapshot.ref.getDownloadURL().then((snapUrl) => {
        firebase
          .database()
          .ref("bhodi_home/commercial_banner_video/")
          .set(snapUrl)
          .then(() => {});
      });
    });
  }

  fileUploadEventsImage(e) {
    let files = e.target.files[0];
    let storageRef = firebase
      .storage()
      .ref()
      .child(`bhodi_home_media/${files.name}${new Date().getTime()}`);
    storageRef.put(files).then((snapshot) => {
      snapshot.ref.getDownloadURL().then((snapUrl) => {
        firebase
          .database()
          .ref("bhodi_home/commercial_events_image/")
          .set(snapUrl)
          .then(() => {});
      });
    });
  }

  fileUploadProfileVideo(e) {
    let files = e.target.files[0];
    let storageRef = firebase
      .storage()
      .ref()
      .child(`bhodi_home_media/${files.name}${new Date().getTime()}`);
    storageRef.put(files).then((snapshot) => {
      snapshot.ref.getDownloadURL().then((snapUrl) => {
        firebase
          .database()
          .ref("bhodi_home/commercial_profile_video/")
          .set(snapUrl)
          .then(() => {});
      });
    });
  }

  fileUploadAanmeldenImage(e) {
    let files = e.target.files[0];
    let storageRef = firebase
      .storage()
      .ref()
      .child(`bhodi_home_media/${files.name}${new Date().getTime()}`);
    storageRef.put(files).then((snapshot) => {
      snapshot.ref.getDownloadURL().then((snapUrl) => {
        firebase
          .database()
          .ref("bhodi_home/commercial_aanmelden_image/")
          .set(snapUrl)
          .then(() => {});
      });
    });
  }

  fileUploadCharityMemberImage(e) {
    let files = e.target.files[0];
    let storageRef = firebase
      .storage()
      .ref()
      .child(`bhodi_home_media/${files.name}${new Date().getTime()}`);
    storageRef.put(files).then((snapshot) => {
      snapshot.ref.getDownloadURL().then((snapUrl) => {
        firebase
          .database()
          .ref("bhodi_home/commercial_charity_member_image/")
          .set(snapUrl)
          .then(() => {});
      });
    });
  }

  fileUploadCharityVideo(e) {
    let files = e.target.files[0];
    let storageRef = firebase
      .storage()
      .ref()
      .child(`bhodi_home_media/${files.name}${new Date().getTime()}`);
    storageRef.put(files).then((snapshot) => {
      snapshot.ref.getDownloadURL().then((snapUrl) => {
        firebase
          .database()
          .ref("bhodi_home/commercial_page_charity_video/")
          .set(snapUrl)
          .then(() => {});
      });
    });
  }

  fileUploadFooterImage(e) {
    let files = e.target.files[0];
    let storageRef = firebase
      .storage()
      .ref()
      .child(`bhodi_home_media/${files.name}${new Date().getTime()}`);
    storageRef.put(files).then((snapshot) => {
      snapshot.ref.getDownloadURL().then((snapUrl) => {
        firebase
          .database()
          .ref("bhodi_home/landing_page_footer_image/")
          .set(snapUrl)
          .then(() => {});
      });
    });
  }

  render() {
    return (
      <div className="sorting-mainadmin">
        <AdminNavbar commercialPage={true} />
        <div className="buttons-container-displayeditor">
          {this.state.onMobileDesigns ? (
            <button
              className="buttonsgreendisplay"
              onClick={() => {
                this.setState({ onMobileDesigns: false });
              }}
            >
              Switch To Desktop
            </button>
          ) : (
            <button
              className="buttonsgreendisplay"
              onClick={() => {
                this.setState({ onMobileDesigns: true });
              }}
            >
              Switch To Mobile
            </button>
          )}
        </div>
        {this.state.showStuffNow && this.state.onMobileDesigns ? (
          <div
            className="container-sma"
            style={{ maxWidth: 1200, paddingBottom: 50 }}
          >
            <div className="tree-inner" style={{ marginTop: 30 }}>
              <div
                className="container-editors-lp"
                style={{ justifyContent: "center" }}
              >
                <div className="inner-celandingpage headinglabelcelp">
                  <label>Banner Heading</label>
                </div>
              </div>
              <div className="container-editors-lp">
                <div className="inner-celandingpage">
                  <label>Dutch</label>
                  <CK
                    data={
                      this.state.commercial_page_banner_dutch_heading_mobile
                    }
                    onChildChange={this.changeAlertBannerDutchHeading.bind(
                      this
                    )}
                  />
                </div>
                <div className="inner-celandingpage">
                  <label>English</label>
                  <CK
                    data={
                      this.state.commercial_page_banner_english_heading_mobile
                    }
                    onChildChange={this.changeAlertBannerEnglishHeading.bind(
                      this
                    )}
                  />
                </div>
              </div>
              <div
                className="container-editors-lp"
                style={{ justifyContent: "center", marginTop: 30 }}
              >
                <div className="inner-celandingpage headinglabelcelp">
                  <label>Banner</label>
                </div>
              </div>
              <div className="container-editors-lp">
                <div className="inner-celandingpage">
                  <label>Dutch</label>
                  <CK
                    data={this.state.commercial_page_banner_dutch_mobile}
                    onChildChange={this.changeAlertBannerDutch.bind(this)}
                  />
                </div>
                <div className="inner-celandingpage">
                  <label>English</label>
                  <CK
                    data={this.state.commercial_page_banner_english_mobile}
                    onChildChange={this.changeAlertBannerEnglish.bind(this)}
                  />
                </div>
              </div>
              <div
                className="container-editors-lp"
                style={{ justifyContent: "center", marginTop: 30 }}
              >
                <div className="inner-celandingpage headinglabelcelp">
                  <label>Van Start Heading</label>
                </div>
              </div>
              <div className="container-editors-lp">
                <div className="inner-celandingpage">
                  <label>Dutch</label>
                  <CK
                    data={
                      this.state.commercial_page_van_start_dutch_heading_mobile
                    }
                    onChildChange={this.changeAlertVanStartDutchHeading.bind(
                      this
                    )}
                  />
                </div>
                <div className="inner-celandingpage">
                  <label>English</label>
                  <CK
                    data={
                      this.state
                        .commercial_page_van_start_english_heading_mobile
                    }
                    onChildChange={this.changeAlertVanStartEnglishHeading.bind(
                      this
                    )}
                  />
                </div>
              </div>
              <div
                className="container-editors-lp"
                style={{ justifyContent: "center", marginTop: 30 }}
              >
                <div className="inner-celandingpage headinglabelcelp">
                  <label>Van Start</label>
                </div>
              </div>
              <div className="container-editors-lp">
                <div className="inner-celandingpage">
                  <label>Dutch</label>
                  <CK
                    data={this.state.commercial_page_van_start_dutch_mobile}
                    onChildChange={this.changeAlertVanStartDutch.bind(this)}
                  />
                </div>
                <div className="inner-celandingpage">
                  <label>English</label>
                  <CK
                    data={this.state.commercial_page_van_start_english_mobile}
                    onChildChange={this.changeAlertVanStartEnglish.bind(this)}
                  />
                </div>
              </div>
              <div
                className="container-editors-lp"
                style={{ justifyContent: "center", marginTop: 30 }}
              >
                <div className="inner-celandingpage headinglabelcelp">
                  <label>Events Heading</label>
                </div>
              </div>
              <div className="container-editors-lp">
                <div className="inner-celandingpage">
                  <label>Dutch</label>
                  <CK
                    data={
                      this.state
                        .commercial_page_events_dutch_heading_inner_mobile
                    }
                    onChildChange={this.changeAlertEventsDutchHeadingInner.bind(
                      this
                    )}
                  />
                </div>
                <div className="inner-celandingpage">
                  <label>English</label>
                  <CK
                    data={
                      this.state
                        .commercial_page_events_english_heading_inner_mobile
                    }
                    onChildChange={this.changeAlertEventsEnglishHeadingInner.bind(
                      this
                    )}
                  />
                </div>
              </div>
              <div
                className="container-editors-lp"
                style={{ justifyContent: "center", marginTop: 30 }}
              >
                <div className="inner-celandingpage headinglabelcelp">
                  <label>Events</label>
                </div>
              </div>
              <div className="container-editors-lp">
                <div className="inner-celandingpage">
                  <label>Dutch</label>
                  <CK
                    data={this.state.commercial_page_events_dutch_mobile}
                    onChildChange={this.changeAlertEventsDutch.bind(this)}
                  />
                </div>
                <div className="inner-celandingpage">
                  <label>English</label>
                  <CK
                    data={this.state.commercial_page_events_english_mobile}
                    onChildChange={this.changeAlertEventsEnglish.bind(this)}
                  />
                </div>
              </div>
              <div
                className="container-editors-lp"
                style={{ justifyContent: "center", marginTop: 30 }}
              >
                <div className="inner-celandingpage headinglabelcelp">
                  <label>Fan Base Heading</label>
                </div>
              </div>
              <div className="container-editors-lp">
                <div className="inner-celandingpage">
                  <label>Dutch</label>
                  <CK
                    data={
                      this.state.commercial_page_fan_base_dutch_heading_mobile
                    }
                    onChildChange={this.changeAlertFanBaseDutchHeading.bind(
                      this
                    )}
                  />
                </div>
                <div className="inner-celandingpage">
                  <label>English</label>
                  <CK
                    data={
                      this.state.commercial_page_fan_base_english_heading_mobile
                    }
                    onChildChange={this.changeAlertFanBaseEnglishHeading.bind(
                      this
                    )}
                  />
                </div>
              </div>
              <div
                className="container-editors-lp"
                style={{ justifyContent: "center", marginTop: 30 }}
              >
                <div className="inner-celandingpage headinglabelcelp">
                  <label>Fan Base</label>
                </div>
              </div>
              <div className="container-editors-lp">
                <div className="inner-celandingpage">
                  <label>Dutch</label>
                  <CK
                    data={this.state.commercial_page_fan_base_dutch_mobile}
                    onChildChange={this.changeAlertFanBaseDutch.bind(this)}
                  />
                </div>
                <div className="inner-celandingpage">
                  <label>English</label>
                  <CK
                    data={this.state.commercial_page_fan_base_english_mobile}
                    onChildChange={this.changeAlertFanBaseEnglish.bind(this)}
                  />
                </div>
              </div>
              <div
                className="container-editors-lp"
                style={{ justifyContent: "center", marginTop: 30 }}
              >
                <div className="inner-celandingpage headinglabelcelp">
                  <label>Profilepage Heading</label>
                </div>
              </div>
              <div className="container-editors-lp">
                <div className="inner-celandingpage">
                  <label>Dutch</label>
                  <CK
                    data={
                      this.state.commercial_page_profile_dutch_heading_mobile
                    }
                    onChildChange={this.changeAlertNewProfileDutchHeading.bind(
                      this
                    )}
                  />
                </div>
                <div className="inner-celandingpage">
                  <label>English</label>
                  <CK
                    data={
                      this.state.commercial_page_profile_english_heading_mobile
                    }
                    onChildChange={this.changeAlertNewProfileEnglishHeading.bind(
                      this
                    )}
                  />
                </div>
              </div>
              <div
                className="container-editors-lp"
                style={{ justifyContent: "center", marginTop: 30 }}
              >
                <div className="inner-celandingpage headinglabelcelp">
                  <label>Profilepage</label>
                </div>
              </div>
              <div className="container-editors-lp">
                <div className="inner-celandingpage">
                  <label>Dutch</label>
                  <CK
                    data={this.state.commercial_page_profile_dutch_mobile}
                    onChildChange={this.changeAlertNewProfileDutch.bind(this)}
                  />
                </div>
                <div className="inner-celandingpage">
                  <label>English</label>
                  <CK
                    data={this.state.commercial_page_profile_english_mobile}
                    onChildChange={this.changeAlertNewProfileEnglish.bind(this)}
                  />
                </div>
              </div>
              <div
                className="container-editors-lp"
                style={{ justifyContent: "center", marginTop: 30 }}
              >
                <div className="inner-celandingpage headinglabelcelp">
                  <label>Popup events Heading</label>
                </div>
              </div>
              <div className="container-editors-lp">
                <div className="inner-celandingpage">
                  <label>Dutch</label>
                  <CK
                    data={
                      this.state.commercial_page_events_dutch_heading_mobile
                    }
                    onChildChange={this.changeAlertEventsDutchHeading.bind(
                      this
                    )}
                  />
                </div>
                <div className="inner-celandingpage">
                  <label>English</label>
                  <CK
                    data={
                      this.state.commercial_page_events_english_heading_mobile
                    }
                    onChildChange={this.changeAlertEventsEnglishHeading.bind(
                      this
                    )}
                  />
                </div>
              </div>
              <div
                className="container-editors-lp"
                style={{ justifyContent: "center", marginTop: 30 }}
              >
                <div className="inner-celandingpage headinglabelcelp">
                  <label>Popup events text</label>
                </div>
              </div>
              <div className="container-editors-lp">
                <div className="inner-celandingpage">
                  <label>Dutch</label>
                  <CK
                    data={
                      this.state.commercial_page_events_dutch_headertxt_mobile
                    }
                    onChildChange={this.changeAlertEventsDutchHeaderText.bind(
                      this
                    )}
                  />
                </div>
                <div className="inner-celandingpage">
                  <label>English</label>
                  <CK
                    data={
                      this.state.commercial_page_events_english_headertxt_mobile
                    }
                    onChildChange={this.changeAlertEventsEnglishHeaderText.bind(
                      this
                    )}
                  />
                </div>
              </div>
              <div
                className="container-editors-lp"
                style={{ justifyContent: "center", marginTop: 30 }}
              >
                <div className="inner-celandingpage headinglabelcelp">
                  <label>Aanmelding Heading</label>
                </div>
              </div>
              <div className="container-editors-lp">
                <div className="inner-celandingpage">
                  <label>Dutch</label>
                  <CK
                    data={
                      this.state.commercial_page_aanmelding_dutch_heading_mobile
                    }
                    onChildChange={this.changeAlertAanmeldingDutchHeading.bind(
                      this
                    )}
                  />
                </div>
                <div className="inner-celandingpage">
                  <label>English</label>
                  <CK
                    data={
                      this.state
                        .commercial_page_aanmelding_english_heading_mobile
                    }
                    onChildChange={this.changeAlertAanmeldingEnglishHeading.bind(
                      this
                    )}
                  />
                </div>
              </div>
              <div
                className="container-editors-lp"
                style={{ justifyContent: "center", marginTop: 30 }}
              >
                <div className="inner-celandingpage headinglabelcelp">
                  <label>Aanmelding</label>
                </div>
              </div>
              <div className="container-editors-lp">
                <div className="inner-celandingpage">
                  <label>Dutch</label>
                  <CK
                    data={this.state.commercial_page_aanmelding_dutch_mobile}
                    onChildChange={this.changeAlertAanmeldingDutch.bind(this)}
                  />
                </div>
                <div className="inner-celandingpage">
                  <label>English</label>
                  <CK
                    data={this.state.commercial_page_aanmelding_english_mobile}
                    onChildChange={this.changeAlertAanmeldingEnglish.bind(this)}
                  />
                </div>
              </div>
              <div
                className="container-editors-lp"
                style={{ justifyContent: "center", marginTop: 30 }}
              >
                <div className="inner-celandingpage headinglabelcelp">
                  <label>Charity Member Heading</label>
                </div>
              </div>
              <div className="container-editors-lp">
                <div className="inner-celandingpage">
                  <label>Dutch</label>
                  <CK
                    data={
                      this.state
                        .commercial_page_charity_member_dutch_heading_mobile
                    }
                    onChildChange={this.changeAlertCharityMemberDutchHeading.bind(
                      this
                    )}
                  />
                </div>
                <div className="inner-celandingpage">
                  <label>English</label>
                  <CK
                    data={
                      this.state
                        .commercial_page_charity_member_english_heading_mobile
                    }
                    onChildChange={this.changeAlertCharityMemberEnglishHeading.bind(
                      this
                    )}
                  />
                </div>
              </div>
              <div
                className="container-editors-lp"
                style={{ justifyContent: "center", marginTop: 30 }}
              >
                <div className="inner-celandingpage headinglabelcelp">
                  <label>Charity Member</label>
                </div>
              </div>
              <div className="container-editors-lp">
                <div className="inner-celandingpage">
                  <label>Dutch</label>
                  <CK
                    data={
                      this.state.commercial_page_charity_member_dutch_mobile
                    }
                    onChildChange={this.changeAlertCharityMemberDutch.bind(
                      this
                    )}
                  />
                </div>
                <div className="inner-celandingpage">
                  <label>English</label>
                  <CK
                    data={
                      this.state.commercial_page_charity_member_english_mobile
                    }
                    onChildChange={this.changeAlertCharityMemberEnglish.bind(
                      this
                    )}
                  />
                </div>
              </div>
              <div
                className="container-editors-lp"
                style={{ justifyContent: "center", marginTop: 30 }}
              >
                <div className="inner-celandingpage headinglabelcelp">
                  <label>About Heading</label>
                </div>
              </div>
              <div className="container-editors-lp">
                <div className="inner-celandingpage">
                  <label>Dutch</label>
                  <CK
                    data={this.state.commercial_page_about_dutch_heading_mobile}
                    onChildChange={this.changeAlertAboutDutchHeading.bind(this)}
                  />
                </div>
                <div className="inner-celandingpage">
                  <label>English</label>
                  <CK
                    data={
                      this.state.commercial_page_about_english_heading_mobile
                    }
                    onChildChange={this.changeAlertAboutEnglishHeading.bind(
                      this
                    )}
                  />
                </div>
              </div>
              <div
                className="container-editors-lp"
                style={{ justifyContent: "center", marginTop: 30 }}
              >
                <div className="inner-celandingpage headinglabelcelp">
                  <label>About</label>
                </div>
              </div>
              <div className="container-editors-lp">
                <div className="inner-celandingpage">
                  <label>Dutch</label>
                  <CK
                    data={this.state.commercial_page_about_dutch_mobile}
                    onChildChange={this.changeAlertAboutDutch.bind(this)}
                  />
                </div>
                <div className="inner-celandingpage">
                  <label>English</label>
                  <CK
                    data={this.state.commercial_page_about_english_mobile}
                    onChildChange={this.changeAlertAboutEnglish.bind(this)}
                  />
                </div>
              </div>
              <div
                className="container-editors-lp"
                style={{ justifyContent: "center", marginTop: 30 }}
              >
                <div className="inner-celandingpage headinglabelcelp">
                  <label>Team Bhodi Heading</label>
                </div>
              </div>
              <div className="container-editors-lp">
                <div className="inner-celandingpage">
                  <label>Dutch</label>
                  <CK
                    data={
                      this.state.commercial_page_team_bhodi_dutch_heading_mobile
                    }
                    onChildChange={this.changeAlertTeamBhodiDutchHeading.bind(
                      this
                    )}
                  />
                </div>
                <div className="inner-celandingpage">
                  <label>English</label>
                  <CK
                    data={
                      this.state
                        .commercial_page_team_bhodi_english_heading_mobile
                    }
                    onChildChange={this.changeAlertTeamBhodiEnglishHeading.bind(
                      this
                    )}
                  />
                </div>
              </div>
              <div
                className="container-editors-lp"
                style={{ justifyContent: "center", marginTop: 30 }}
              >
                <div className="inner-celandingpage headinglabelcelp">
                  <label>Team Bhodi</label>
                </div>
              </div>
              <div className="container-editors-lp">
                <div className="inner-celandingpage">
                  <label>Dutch</label>
                  <CK
                    data={this.state.commercial_page_team_bhodi_dutch_mobile}
                    onChildChange={this.changeAlertTeamBhodiDutch.bind(this)}
                  />
                </div>
                <div className="inner-celandingpage">
                  <label>English</label>
                  <CK
                    data={this.state.commercial_page_team_bhodi_english_mobile}
                    onChildChange={this.changeAlertTeamBhodiEnglish.bind(this)}
                  />
                </div>
              </div>

              <div
                className="container-editors-lp"
                style={{ justifyContent: "center", marginTop: 30 }}
              >
                <div className="inner-celandingpage headinglabelcelp">
                  <label>Slider Heading</label>
                </div>
              </div>
              <div className="container-editors-lp">
                <div className="inner-celandingpage">
                  <label>Dutch</label>
                  <CK
                    data={
                      this.state.commercial_page_slider_dutch_heading_mobile
                    }
                    onChildChange={this.changeAlertSlidesDutchHeading.bind(
                      this
                    )}
                  />
                </div>
                <div className="inner-celandingpage">
                  <label>English</label>
                  <CK
                    data={
                      this.state.commercial_page_slider_english_heading_mobile
                    }
                    onChildChange={this.changeAlertSliderEnglishHeading.bind(
                      this
                    )}
                  />
                </div>
              </div>
              <div
                className="container-editors-lp"
                style={{ justifyContent: "center", marginTop: 30 }}
              >
                <div className="inner-celandingpage headinglabelcelp">
                  <label>Slider text</label>
                </div>
              </div>
              <div className="container-editors-lp">
                <div className="inner-celandingpage">
                  <label>Dutch</label>
                  <CK
                    data={this.state.commercial_page_slider_dutch_para_mobile}
                    onChildChange={this.changeAlertSlidesDutchPara.bind(this)}
                  />
                </div>
                <div className="inner-celandingpage">
                  <label>English</label>
                  <CK
                    data={this.state.commercial_page_slider_english_para_mobile}
                    onChildChange={this.changeAlertSliderEnglishPara.bind(this)}
                  />
                </div>
              </div>

              <div
                className="container-editors-lp"
                style={{ justifyContent: "center", marginTop: 30 }}
              >
                <div className="inner-celandingpage headinglabelcelp">
                  <label>First Slide Heading</label>
                </div>
              </div>
              <div className="container-editors-lp">
                <div className="inner-celandingpage">
                  <label>Dutch</label>
                  <CK
                    data={
                      this.state
                        .commercial_page_first_slide_dutch_heading_mobile
                    }
                    onChildChange={this.changeAlertFirstSlideHeadingDutch.bind(
                      this
                    )}
                  />
                </div>
                <div className="inner-celandingpage">
                  <label>English</label>
                  <CK
                    data={
                      this.state
                        .commercial_page_first_slide_english_heading_mobile
                    }
                    onChildChange={this.changeAlertFirstSlideHeading.bind(this)}
                  />
                </div>
              </div>
              <div
                className="container-editors-lp"
                style={{ justifyContent: "center", marginTop: 30 }}
              >
                <div className="inner-celandingpage headinglabelcelp">
                  <label>First Slide para</label>
                </div>
              </div>
              <div className="container-editors-lp">
                <div className="inner-celandingpage">
                  <label>Dutch</label>
                  <CK
                    data={
                      this.state
                        .commercial_page_first_slide_dutch_paragraph_mobile
                    }
                    onChildChange={this.changeAlertFirstSlideParagraphDutch.bind(
                      this
                    )}
                  />
                </div>
                <div className="inner-celandingpage">
                  <label>English</label>
                  <CK
                    data={
                      this.state
                        .commercial_page_first_slide_english_paragraph_mobile
                    }
                    onChildChange={this.changeAlertFirstSlideParagraph.bind(
                      this
                    )}
                  />
                </div>
              </div>

              <div
                className="container-editors-lp"
                style={{ justifyContent: "center", marginTop: 30 }}
              >
                <div className="inner-celandingpage headinglabelcelp">
                  <label>Second slide Heading</label>
                </div>
              </div>
              <div className="container-editors-lp">
                <div className="inner-celandingpage">
                  <label>Dutch</label>
                  <CK
                    data={
                      this.state
                        .commercial_page_second_slide_dutch_heading_mobile
                    }
                    onChildChange={this.changeAlertSecondSlideHeadingDutch.bind(
                      this
                    )}
                  />
                </div>
                <div className="inner-celandingpage">
                  <label>English</label>
                  <CK
                    data={
                      this.state
                        .commercial_page_second_slide_english_heading_mobile
                    }
                    onChildChange={this.changeAlertSecondSlideHeading.bind(
                      this
                    )}
                  />
                </div>
              </div>
              <div
                className="container-editors-lp"
                style={{ justifyContent: "center", marginTop: 30 }}
              >
                <div className="inner-celandingpage headinglabelcelp">
                  <label>Second slide para</label>
                </div>
              </div>
              <div className="container-editors-lp">
                <div className="inner-celandingpage">
                  <label>Dutch</label>
                  <CK
                    data={
                      this.state
                        .commercial_page_second_slide_dutch_paragraph_mobile
                    }
                    onChildChange={this.changeAlertSecondSlideParagraphDutch.bind(
                      this
                    )}
                  />
                </div>
                <div className="inner-celandingpage">
                  <label>English</label>
                  <CK
                    data={
                      this.state
                        .commercial_page_second_slide_english_paragraph_mobile
                    }
                    onChildChange={this.changeAlertSecondSlideParagraph.bind(
                      this
                    )}
                  />
                </div>
              </div>

              <div
                className="container-editors-lp"
                style={{ justifyContent: "center", marginTop: 30 }}
              >
                <div className="inner-celandingpage headinglabelcelp">
                  <label>Third Slide Heading</label>
                </div>
              </div>
              <div className="container-editors-lp">
                <div className="inner-celandingpage">
                  <label>Dutch</label>
                  <CK
                    data={
                      this.state
                        .commercial_page_third_slide_dutch_heading_mobile
                    }
                    onChildChange={this.changeAlertThirdSlideHeadingDutch.bind(
                      this
                    )}
                  />
                </div>
                <div className="inner-celandingpage">
                  <label>English</label>
                  <CK
                    data={
                      this.state
                        .commercial_page_third_slide_english_heading_mobile
                    }
                    onChildChange={this.changeAlertThirdSlideHeading.bind(this)}
                  />
                </div>
              </div>
              <div
                className="container-editors-lp"
                style={{ justifyContent: "center", marginTop: 30 }}
              >
                <div className="inner-celandingpage headinglabelcelp">
                  <label>Third Slide Para</label>
                </div>
              </div>
              <div className="container-editors-lp">
                <div className="inner-celandingpage">
                  <label>Dutch</label>
                  <CK
                    data={
                      this.state
                        .commercial_page_third_slide_dutch_paragraph_mobile
                    }
                    onChildChange={this.changeAlertThirdSlideParagraphDutch.bind(
                      this
                    )}
                  />
                </div>
                <div className="inner-celandingpage">
                  <label>English</label>
                  <CK
                    data={
                      this.state
                        .commercial_page_third_slide_english_paragraph_mobile
                    }
                    onChildChange={this.changeAlertThirdSlideParagraph.bind(
                      this
                    )}
                  />
                </div>
              </div>

              <div
                className="container-editors-lp"
                style={{ justifyContent: "center", marginTop: 30 }}
              >
                <div className="inner-celandingpage headinglabelcelp">
                  <label>Fourth Slide Heading</label>
                </div>
              </div>
              <div className="container-editors-lp">
                <div className="inner-celandingpage">
                  <label>Dutch</label>
                  <CK
                    data={
                      this.state
                        .commercial_page_fourth_slide_dutch_heading_mobile
                    }
                    onChildChange={this.changeAlertFourthSlideHeadingDutch.bind(
                      this
                    )}
                  />
                </div>
                <div className="inner-celandingpage">
                  <label>English</label>
                  <CK
                    data={
                      this.state
                        .commercial_page_fourth_slide_english_heading_mobile
                    }
                    onChildChange={this.changeAlertFourthSlideHeading.bind(
                      this
                    )}
                  />
                </div>
              </div>
              <div
                className="container-editors-lp"
                style={{ justifyContent: "center", marginTop: 30 }}
              >
                <div className="inner-celandingpage headinglabelcelp">
                  <label>Fourth Slide Para</label>
                </div>
              </div>
              <div className="container-editors-lp">
                <div className="inner-celandingpage">
                  <label>Dutch</label>
                  <CK
                    data={
                      this.state
                        .commercial_page_fourth_slide_dutch_paragraph_mobile
                    }
                    onChildChange={this.changeAlertFourthSlideParagraphDutch.bind(
                      this
                    )}
                  />
                </div>
                <div className="inner-celandingpage">
                  <label>English</label>
                  <CK
                    data={
                      this.state
                        .commercial_page_fourth_slide_english_paragraph_mobile
                    }
                    onChildChange={this.changeAlertFourthSlideParagraph.bind(
                      this
                    )}
                  />
                </div>
              </div>

              <div
                className="container-editors-lp"
                style={{ justifyContent: "center", marginTop: 30 }}
              >
                <div className="inner-celandingpage headinglabelcelp">
                  <label>Fifth Slide Heading</label>
                </div>
              </div>
              <div className="container-editors-lp">
                <div className="inner-celandingpage">
                  <label>Dutch</label>
                  <CK
                    data={
                      this.state
                        .commercial_page_fifth_slide_dutch_heading_mobile
                    }
                    onChildChange={this.changeAlertFifthSlideHeadingDutch.bind(
                      this
                    )}
                  />
                </div>
                <div className="inner-celandingpage">
                  <label>English</label>
                  <CK
                    data={
                      this.state
                        .commercial_page_fifth_slide_english_heading_mobile
                    }
                    onChildChange={this.changeAlertFifthSlideHeading.bind(this)}
                  />
                </div>
              </div>
              <div
                className="container-editors-lp"
                style={{ justifyContent: "center", marginTop: 30 }}
              >
                <div className="inner-celandingpage headinglabelcelp">
                  <label>Fifth Slide Para</label>
                </div>
              </div>
              <div className="container-editors-lp">
                <div className="inner-celandingpage">
                  <label>Dutch</label>
                  <CK
                    data={
                      this.state
                        .commercial_page_fifth_slide_dutch_paragraph_mobile
                    }
                    onChildChange={this.changeAlertFifthSlideParagraphDutch.bind(
                      this
                    )}
                  />
                </div>
                <div className="inner-celandingpage">
                  <label>English</label>
                  <CK
                    data={
                      this.state
                        .commercial_page_fifth_slide_english_paragraph_mobile
                    }
                    onChildChange={this.changeAlertFifthSlideParagraph.bind(
                      this
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          this.state.showStuffNow && (
            <div
              className="container-sma"
              style={{ maxWidth: 1200, paddingBottom: 50 }}
            >
              <div className="tree-inner" style={{ marginTop: 30 }}>
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center" }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>Banner Heading</label>
                  </div>
                </div>
                <div className="container-editors-lp">
                  <div className="inner-celandingpage">
                    <label>Dutch</label>
                    <CK
                      data={this.state.commercial_page_banner_dutch_heading}
                      onChildChange={this.changeAlertBannerDutchHeading.bind(
                        this
                      )}
                    />
                  </div>
                  <div className="inner-celandingpage">
                    <label>English</label>
                    <CK
                      data={this.state.commercial_page_banner_english_heading}
                      onChildChange={this.changeAlertBannerEnglishHeading.bind(
                        this
                      )}
                    />
                  </div>
                </div>
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center", marginTop: 30 }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>Banner</label>
                  </div>
                </div>
                <div className="container-editors-lp">
                  <div className="inner-celandingpage">
                    <label>Dutch</label>
                    <CK
                      data={this.state.commercial_page_banner_dutch}
                      onChildChange={this.changeAlertBannerDutch.bind(this)}
                    />
                  </div>
                  <div className="inner-celandingpage">
                    <label>English</label>
                    <CK
                      data={this.state.commercial_page_banner_english}
                      onChildChange={this.changeAlertBannerEnglish.bind(this)}
                    />
                  </div>
                </div>
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center", marginTop: 30 }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>Van Start Heading</label>
                  </div>
                </div>
                <div className="container-editors-lp">
                  <div className="inner-celandingpage">
                    <label>Dutch</label>
                    <CK
                      data={this.state.commercial_page_van_start_dutch_heading}
                      onChildChange={this.changeAlertVanStartDutchHeading.bind(
                        this
                      )}
                    />
                  </div>
                  <div className="inner-celandingpage">
                    <label>English</label>
                    <CK
                      data={
                        this.state.commercial_page_van_start_english_heading
                      }
                      onChildChange={this.changeAlertVanStartEnglishHeading.bind(
                        this
                      )}
                    />
                  </div>
                </div>
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center", marginTop: 30 }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>Van Start</label>
                  </div>
                </div>
                <div className="container-editors-lp">
                  <div className="inner-celandingpage">
                    <label>Dutch</label>
                    <CK
                      data={this.state.commercial_page_van_start_dutch}
                      onChildChange={this.changeAlertVanStartDutch.bind(this)}
                    />
                  </div>
                  <div className="inner-celandingpage">
                    <label>English</label>
                    <CK
                      data={this.state.commercial_page_van_start_english}
                      onChildChange={this.changeAlertVanStartEnglish.bind(this)}
                    />
                  </div>
                </div>
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center", marginTop: 30 }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>Events Heading</label>
                  </div>
                </div>
                <div className="container-editors-lp">
                  <div className="inner-celandingpage">
                    <label>Dutch</label>
                    <CK
                      data={
                        this.state.commercial_page_events_dutch_heading_inner
                      }
                      onChildChange={this.changeAlertEventsDutchHeadingInner.bind(
                        this
                      )}
                    />
                  </div>
                  <div className="inner-celandingpage">
                    <label>English</label>
                    <CK
                      data={
                        this.state.commercial_page_events_english_heading_inner
                      }
                      onChildChange={this.changeAlertEventsEnglishHeadingInner.bind(
                        this
                      )}
                    />
                  </div>
                </div>
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center", marginTop: 30 }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>Events</label>
                  </div>
                </div>
                <div className="container-editors-lp">
                  <div className="inner-celandingpage">
                    <label>Dutch</label>
                    <CK
                      data={this.state.commercial_page_events_dutch}
                      onChildChange={this.changeAlertEventsDutch.bind(this)}
                    />
                  </div>
                  <div className="inner-celandingpage">
                    <label>English</label>
                    <CK
                      data={this.state.commercial_page_events_english}
                      onChildChange={this.changeAlertEventsEnglish.bind(this)}
                    />
                  </div>
                </div>
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center", marginTop: 30 }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>Fan Base Heading</label>
                  </div>
                </div>
                <div className="container-editors-lp">
                  <div className="inner-celandingpage">
                    <label>Dutch</label>
                    <CK
                      data={this.state.commercial_page_fan_base_dutch_heading}
                      onChildChange={this.changeAlertFanBaseDutchHeading.bind(
                        this
                      )}
                    />
                  </div>
                  <div className="inner-celandingpage">
                    <label>English</label>
                    <CK
                      data={this.state.commercial_page_fan_base_english_heading}
                      onChildChange={this.changeAlertFanBaseEnglishHeading.bind(
                        this
                      )}
                    />
                  </div>
                </div>
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center", marginTop: 30 }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>Fan Base</label>
                  </div>
                </div>
                <div className="container-editors-lp">
                  <div className="inner-celandingpage">
                    <label>Dutch</label>
                    <CK
                      data={this.state.commercial_page_fan_base_dutch}
                      onChildChange={this.changeAlertFanBaseDutch.bind(this)}
                    />
                  </div>
                  <div className="inner-celandingpage">
                    <label>English</label>
                    <CK
                      data={this.state.commercial_page_fan_base_english}
                      onChildChange={this.changeAlertFanBaseEnglish.bind(this)}
                    />
                  </div>
                </div>
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center", marginTop: 30 }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>Profilepage Heading</label>
                  </div>
                </div>
                <div className="container-editors-lp">
                  <div className="inner-celandingpage">
                    <label>Dutch</label>
                    <CK
                      data={this.state.commercial_page_profile_dutch_heading}
                      onChildChange={this.changeAlertNewProfileDutchHeading.bind(
                        this
                      )}
                    />
                  </div>
                  <div className="inner-celandingpage">
                    <label>English</label>
                    <CK
                      data={this.state.commercial_page_profile_english_heading}
                      onChildChange={this.changeAlertNewProfileEnglishHeading.bind(
                        this
                      )}
                    />
                  </div>
                </div>
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center", marginTop: 30 }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>Profilepage</label>
                  </div>
                </div>
                <div className="container-editors-lp">
                  <div className="inner-celandingpage">
                    <label>Dutch</label>
                    <CK
                      data={this.state.commercial_page_profile_dutch}
                      onChildChange={this.changeAlertNewProfileDutch.bind(this)}
                    />
                  </div>
                  <div className="inner-celandingpage">
                    <label>English</label>
                    <CK
                      data={this.state.commercial_page_profile_english}
                      onChildChange={this.changeAlertNewProfileEnglish.bind(
                        this
                      )}
                    />
                  </div>
                </div>
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center", marginTop: 30 }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>Popup events Heading</label>
                  </div>
                </div>
                <div className="container-editors-lp">
                  <div className="inner-celandingpage">
                    <label>Dutch</label>
                    <CK
                      data={this.state.commercial_page_events_dutch_heading}
                      onChildChange={this.changeAlertEventsDutchHeading.bind(
                        this
                      )}
                    />
                  </div>
                  <div className="inner-celandingpage">
                    <label>English</label>
                    <CK
                      data={this.state.commercial_page_events_english_heading}
                      onChildChange={this.changeAlertEventsEnglishHeading.bind(
                        this
                      )}
                    />
                  </div>
                </div>
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center", marginTop: 30 }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>Popup events text</label>
                  </div>
                </div>
                <div className="container-editors-lp">
                  <div className="inner-celandingpage">
                    <label>Dutch</label>
                    <CK
                      data={this.state.commercial_page_events_dutch_headertxt}
                      onChildChange={this.changeAlertEventsDutchHeaderText.bind(
                        this
                      )}
                    />
                  </div>
                  <div className="inner-celandingpage">
                    <label>English</label>
                    <CK
                      data={this.state.commercial_page_events_english_headertxt}
                      onChildChange={this.changeAlertEventsEnglishHeaderText.bind(
                        this
                      )}
                    />
                  </div>
                </div>
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center", marginTop: 30 }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>Aanmelding Heading</label>
                  </div>
                </div>
                <div className="container-editors-lp">
                  <div className="inner-celandingpage">
                    <label>Dutch</label>
                    <CK
                      data={this.state.commercial_page_aanmelding_dutch_heading}
                      onChildChange={this.changeAlertAanmeldingDutchHeading.bind(
                        this
                      )}
                    />
                  </div>
                  <div className="inner-celandingpage">
                    <label>English</label>
                    <CK
                      data={
                        this.state.commercial_page_aanmelding_english_heading
                      }
                      onChildChange={this.changeAlertAanmeldingEnglishHeading.bind(
                        this
                      )}
                    />
                  </div>
                </div>
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center", marginTop: 30 }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>Aanmelding</label>
                  </div>
                </div>
                <div className="container-editors-lp">
                  <div className="inner-celandingpage">
                    <label>Dutch</label>
                    <CK
                      data={this.state.commercial_page_aanmelding_dutch}
                      onChildChange={this.changeAlertAanmeldingDutch.bind(this)}
                    />
                  </div>
                  <div className="inner-celandingpage">
                    <label>English</label>
                    <CK
                      data={this.state.commercial_page_aanmelding_english}
                      onChildChange={this.changeAlertAanmeldingEnglish.bind(
                        this
                      )}
                    />
                  </div>
                </div>
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center", marginTop: 30 }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>Charity Member Heading</label>
                  </div>
                </div>
                <div className="container-editors-lp">
                  <div className="inner-celandingpage">
                    <label>Dutch</label>
                    <CK
                      data={
                        this.state.commercial_page_charity_member_dutch_heading
                      }
                      onChildChange={this.changeAlertCharityMemberDutchHeading.bind(
                        this
                      )}
                    />
                  </div>
                  <div className="inner-celandingpage">
                    <label>English</label>
                    <CK
                      data={
                        this.state
                          .commercial_page_charity_member_english_heading
                      }
                      onChildChange={this.changeAlertCharityMemberEnglishHeading.bind(
                        this
                      )}
                    />
                  </div>
                </div>
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center", marginTop: 30 }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>Charity Member</label>
                  </div>
                </div>
                <div className="container-editors-lp">
                  <div className="inner-celandingpage">
                    <label>Dutch</label>
                    <CK
                      data={this.state.commercial_page_charity_member_dutch}
                      onChildChange={this.changeAlertCharityMemberDutch.bind(
                        this
                      )}
                    />
                  </div>
                  <div className="inner-celandingpage">
                    <label>English</label>
                    <CK
                      data={this.state.commercial_page_charity_member_english}
                      onChildChange={this.changeAlertCharityMemberEnglish.bind(
                        this
                      )}
                    />
                  </div>
                </div>
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center", marginTop: 30 }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>About Heading</label>
                  </div>
                </div>
                <div className="container-editors-lp">
                  <div className="inner-celandingpage">
                    <label>Dutch</label>
                    <CK
                      data={this.state.commercial_page_about_dutch_heading}
                      onChildChange={this.changeAlertAboutDutchHeading.bind(
                        this
                      )}
                    />
                  </div>
                  <div className="inner-celandingpage">
                    <label>English</label>
                    <CK
                      data={this.state.commercial_page_about_english_heading}
                      onChildChange={this.changeAlertAboutEnglishHeading.bind(
                        this
                      )}
                    />
                  </div>
                </div>
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center", marginTop: 30 }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>About</label>
                  </div>
                </div>
                <div className="container-editors-lp">
                  <div className="inner-celandingpage">
                    <label>Dutch</label>
                    <CK
                      data={this.state.commercial_page_about_dutch}
                      onChildChange={this.changeAlertAboutDutch.bind(this)}
                    />
                  </div>
                  <div className="inner-celandingpage">
                    <label>English</label>
                    <CK
                      data={this.state.commercial_page_about_english}
                      onChildChange={this.changeAlertAboutEnglish.bind(this)}
                    />
                  </div>
                </div>
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center", marginTop: 30 }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>Team Bhodi Heading</label>
                  </div>
                </div>
                <div className="container-editors-lp">
                  <div className="inner-celandingpage">
                    <label>Dutch</label>
                    <CK
                      data={this.state.commercial_page_team_bhodi_dutch_heading}
                      onChildChange={this.changeAlertTeamBhodiDutchHeading.bind(
                        this
                      )}
                    />
                  </div>
                  <div className="inner-celandingpage">
                    <label>English</label>
                    <CK
                      data={
                        this.state.commercial_page_team_bhodi_english_heading
                      }
                      onChildChange={this.changeAlertTeamBhodiEnglishHeading.bind(
                        this
                      )}
                    />
                  </div>
                </div>
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center", marginTop: 30 }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>Team Bhodi</label>
                  </div>
                </div>
                <div className="container-editors-lp">
                  <div className="inner-celandingpage">
                    <label>Dutch</label>
                    <CK
                      data={this.state.commercial_page_team_bhodi_dutch}
                      onChildChange={this.changeAlertTeamBhodiDutch.bind(this)}
                    />
                  </div>
                  <div className="inner-celandingpage">
                    <label>English</label>
                    <CK
                      data={this.state.commercial_page_team_bhodi_english}
                      onChildChange={this.changeAlertTeamBhodiEnglish.bind(
                        this
                      )}
                    />
                  </div>
                </div>

                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center", marginTop: 30 }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>Slider Heading</label>
                  </div>
                </div>
                <div className="container-editors-lp">
                  <div className="inner-celandingpage">
                    <label>Dutch</label>
                    <CK
                      data={this.state.commercial_page_slider_dutch_heading}
                      onChildChange={this.changeAlertSlidesDutchHeading.bind(
                        this
                      )}
                    />
                  </div>
                  <div className="inner-celandingpage">
                    <label>English</label>
                    <CK
                      data={this.state.commercial_page_slider_english_heading}
                      onChildChange={this.changeAlertSliderEnglishHeading.bind(
                        this
                      )}
                    />
                  </div>
                </div>
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center", marginTop: 30 }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>Slider text</label>
                  </div>
                </div>
                <div className="container-editors-lp">
                  <div className="inner-celandingpage">
                    <label>Dutch</label>
                    <CK
                      data={this.state.commercial_page_slider_dutch_para}
                      onChildChange={this.changeAlertSlidesDutchPara.bind(this)}
                    />
                  </div>
                  <div className="inner-celandingpage">
                    <label>English</label>
                    <CK
                      data={this.state.commercial_page_slider_english_para}
                      onChildChange={this.changeAlertSliderEnglishPara.bind(
                        this
                      )}
                    />
                  </div>
                </div>

                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center", marginTop: 30 }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>First Slide Image</label>
                  </div>
                </div>
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center" }}
                >
                  <input
                    type="file"
                    style={{ padding: 15, border: "1px solid gray" }}
                    accept="image/*"
                    onChange={(e) => this.fileUploadFirstSlide(e)}
                  />
                </div>
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center", marginTop: 30 }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>First Slide Heading</label>
                  </div>
                </div>
                <div className="container-editors-lp">
                  <div className="inner-celandingpage">
                    <label>Dutch</label>
                    <CK
                      data={
                        this.state.commercial_page_first_slide_dutch_heading
                      }
                      onChildChange={this.changeAlertFirstSlideHeadingDutch.bind(
                        this
                      )}
                    />
                  </div>
                  <div className="inner-celandingpage">
                    <label>English</label>
                    <CK
                      data={
                        this.state.commercial_page_first_slide_english_heading
                      }
                      onChildChange={this.changeAlertFirstSlideHeading.bind(
                        this
                      )}
                    />
                  </div>
                </div>
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center", marginTop: 30 }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>First Slide para</label>
                  </div>
                </div>
                <div className="container-editors-lp">
                  <div className="inner-celandingpage">
                    <label>Dutch</label>
                    <CK
                      data={
                        this.state.commercial_page_first_slide_dutch_paragraph
                      }
                      onChildChange={this.changeAlertFirstSlideParagraphDutch.bind(
                        this
                      )}
                    />
                  </div>
                  <div className="inner-celandingpage">
                    <label>English</label>
                    <CK
                      data={
                        this.state.commercial_page_first_slide_english_paragraph
                      }
                      onChildChange={this.changeAlertFirstSlideParagraph.bind(
                        this
                      )}
                    />
                  </div>
                </div>

                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center", marginTop: 30 }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>Second Slide Image</label>
                  </div>
                </div>
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center" }}
                >
                  <input
                    type="file"
                    style={{ padding: 15, border: "1px solid gray" }}
                    accept="image/*"
                    onChange={(e) => this.fileUploadSecondSlide(e)}
                  />
                </div>
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center", marginTop: 30 }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>Second slide Heading</label>
                  </div>
                </div>
                <div className="container-editors-lp">
                  <div className="inner-celandingpage">
                    <label>Dutch</label>
                    <CK
                      data={
                        this.state.commercial_page_second_slide_dutch_heading
                      }
                      onChildChange={this.changeAlertSecondSlideHeadingDutch.bind(
                        this
                      )}
                    />
                  </div>
                  <div className="inner-celandingpage">
                    <label>English</label>
                    <CK
                      data={
                        this.state.commercial_page_second_slide_english_heading
                      }
                      onChildChange={this.changeAlertSecondSlideHeading.bind(
                        this
                      )}
                    />
                  </div>
                </div>
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center", marginTop: 30 }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>Second slide para</label>
                  </div>
                </div>
                <div className="container-editors-lp">
                  <div className="inner-celandingpage">
                    <label>Dutch</label>
                    <CK
                      data={
                        this.state.commercial_page_second_slide_dutch_paragraph
                      }
                      onChildChange={this.changeAlertSecondSlideParagraphDutch.bind(
                        this
                      )}
                    />
                  </div>
                  <div className="inner-celandingpage">
                    <label>English</label>
                    <CK
                      data={
                        this.state
                          .commercial_page_second_slide_english_paragraph
                      }
                      onChildChange={this.changeAlertSecondSlideParagraph.bind(
                        this
                      )}
                    />
                  </div>
                </div>

                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center", marginTop: 30 }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>Third Slide Image</label>
                  </div>
                </div>
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center" }}
                >
                  <input
                    type="file"
                    style={{ padding: 15, border: "1px solid gray" }}
                    accept="image/*"
                    onChange={(e) => this.fileUploadThirdSlide(e)}
                  />
                </div>
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center", marginTop: 30 }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>Third Slide Heading</label>
                  </div>
                </div>
                <div className="container-editors-lp">
                  <div className="inner-celandingpage">
                    <label>Dutch</label>
                    <CK
                      data={
                        this.state.commercial_page_third_slide_dutch_heading
                      }
                      onChildChange={this.changeAlertThirdSlideHeadingDutch.bind(
                        this
                      )}
                    />
                  </div>
                  <div className="inner-celandingpage">
                    <label>English</label>
                    <CK
                      data={
                        this.state.commercial_page_third_slide_english_heading
                      }
                      onChildChange={this.changeAlertThirdSlideHeading.bind(
                        this
                      )}
                    />
                  </div>
                </div>
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center", marginTop: 30 }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>Third Slide Para</label>
                  </div>
                </div>
                <div className="container-editors-lp">
                  <div className="inner-celandingpage">
                    <label>Dutch</label>
                    <CK
                      data={
                        this.state.commercial_page_third_slide_dutch_paragraph
                      }
                      onChildChange={this.changeAlertThirdSlideParagraphDutch.bind(
                        this
                      )}
                    />
                  </div>
                  <div className="inner-celandingpage">
                    <label>English</label>
                    <CK
                      data={
                        this.state.commercial_page_third_slide_english_paragraph
                      }
                      onChildChange={this.changeAlertThirdSlideParagraph.bind(
                        this
                      )}
                    />
                  </div>
                </div>

                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center", marginTop: 30 }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>Fourth Slide Image</label>
                  </div>
                </div>
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center" }}
                >
                  <input
                    type="file"
                    style={{ padding: 15, border: "1px solid gray" }}
                    accept="image/*"
                    onChange={(e) => this.fileUploadFourthSlide(e)}
                  />
                </div>
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center", marginTop: 30 }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>Fourth Slide Heading</label>
                  </div>
                </div>
                <div className="container-editors-lp">
                  <div className="inner-celandingpage">
                    <label>Dutch</label>
                    <CK
                      data={
                        this.state.commercial_page_fourth_slide_dutch_heading
                      }
                      onChildChange={this.changeAlertFourthSlideHeadingDutch.bind(
                        this
                      )}
                    />
                  </div>
                  <div className="inner-celandingpage">
                    <label>English</label>
                    <CK
                      data={
                        this.state.commercial_page_fourth_slide_english_heading
                      }
                      onChildChange={this.changeAlertFourthSlideHeading.bind(
                        this
                      )}
                    />
                  </div>
                </div>
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center", marginTop: 30 }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>Fourth Slide Para</label>
                  </div>
                </div>
                <div className="container-editors-lp">
                  <div className="inner-celandingpage">
                    <label>Dutch</label>
                    <CK
                      data={
                        this.state.commercial_page_fourth_slide_dutch_paragraph
                      }
                      onChildChange={this.changeAlertFourthSlideParagraphDutch.bind(
                        this
                      )}
                    />
                  </div>
                  <div className="inner-celandingpage">
                    <label>English</label>
                    <CK
                      data={
                        this.state
                          .commercial_page_fourth_slide_english_paragraph
                      }
                      onChildChange={this.changeAlertFourthSlideParagraph.bind(
                        this
                      )}
                    />
                  </div>
                </div>

                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center", marginTop: 30 }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>Fifth Slide Image</label>
                  </div>
                </div>
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center" }}
                >
                  <input
                    type="file"
                    style={{ padding: 15, border: "1px solid gray" }}
                    accept="image/*"
                    onChange={(e) => this.fileUploadFifthSlide(e)}
                  />
                </div>
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center", marginTop: 30 }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>Fifth Slide Heading</label>
                  </div>
                </div>
                <div className="container-editors-lp">
                  <div className="inner-celandingpage">
                    <label>Dutch</label>
                    <CK
                      data={
                        this.state.commercial_page_fifth_slide_dutch_heading
                      }
                      onChildChange={this.changeAlertFifthSlideHeadingDutch.bind(
                        this
                      )}
                    />
                  </div>
                  <div className="inner-celandingpage">
                    <label>English</label>
                    <CK
                      data={
                        this.state.commercial_page_fifth_slide_english_heading
                      }
                      onChildChange={this.changeAlertFifthSlideHeading.bind(
                        this
                      )}
                    />
                  </div>
                </div>
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center", marginTop: 30 }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>Fifth Slide Para</label>
                  </div>
                </div>
                <div className="container-editors-lp">
                  <div className="inner-celandingpage">
                    <label>Dutch</label>
                    <CK
                      data={
                        this.state.commercial_page_fifth_slide_dutch_paragraph
                      }
                      onChildChange={this.changeAlertFifthSlideParagraphDutch.bind(
                        this
                      )}
                    />
                  </div>
                  <div className="inner-celandingpage">
                    <label>English</label>
                    <CK
                      data={
                        this.state.commercial_page_fifth_slide_english_paragraph
                      }
                      onChildChange={this.changeAlertFifthSlideParagraph.bind(
                        this
                      )}
                    />
                  </div>
                </div>

                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center", marginTop: 30 }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>Banner video</label>
                  </div>
                </div>
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center" }}
                >
                  <input
                    type="file"
                    style={{ padding: 15, border: "1px solid gray" }}
                    accept="video/*"
                    onChange={(e) => this.fileUploadBannerVideo(e)}
                  />
                </div>

                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center", marginTop: 30 }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>Event image</label>
                  </div>
                </div>
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center" }}
                >
                  <input
                    type="file"
                    style={{ padding: 15, border: "1px solid gray" }}
                    accept="image/*"
                    onChange={(e) => this.fileUploadEventsImage(e)}
                  />
                </div>

                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center", marginTop: 30 }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>Profile video</label>
                  </div>
                </div>
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center" }}
                >
                  <input
                    type="file"
                    style={{ padding: 15, border: "1px solid gray" }}
                    accept="video/*"
                    onChange={(e) => this.fileUploadProfileVideo(e)}
                  />
                </div>

                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center", marginTop: 30 }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>Aanmelden Image</label>
                  </div>
                </div>
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center" }}
                >
                  <input
                    type="file"
                    style={{ padding: 15, border: "1px solid gray" }}
                    accept="image/*"
                    onChange={(e) => this.fileUploadAanmeldenImage(e)}
                  />
                </div>

                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center", marginTop: 30 }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>Charity Image</label>
                  </div>
                </div>
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center" }}
                >
                  <input
                    type="file"
                    style={{ padding: 15, border: "1px solid gray" }}
                    accept="image/*"
                    onChange={(e) => this.fileUploadCharityMemberImage(e)}
                  />
                </div>

                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center", marginTop: 30 }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>Charity video</label>
                  </div>
                </div>
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center" }}
                >
                  <input
                    type="file"
                    style={{ padding: 15, border: "1px solid gray" }}
                    accept="video/*"
                    onChange={(e) => this.fileUploadCharityVideo(e)}
                  />
                </div>

                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center", marginTop: 30 }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>Footer image</label>
                  </div>
                </div>
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center" }}
                >
                  <input
                    type="file"
                    style={{ padding: 15, border: "1px solid gray" }}
                    accept="image/*"
                    onChange={(e) => this.fileUploadFooterImage(e)}
                  />
                </div>
              </div>
            </div>
          )
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    global_values: state.globalReducers.global_values,
    user: state.authReducers.user,
    admin_boolean: state.globalReducers.admin_boolean,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateGlobalStuff: (global_values) =>
      dispatch(updateGlobalStuff(global_values)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditorCommercial);
