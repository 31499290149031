import React, { Component } from 'react'
import "./index.css"
import { MdClose } from 'react-icons/md'

export class SubscribeForCompanyAnnouncement extends Component {
    render() {
        return (
            <div className='subscribe-announcement'>
                <div className='samain-inner'>
                    <MdClose onClick={() => {
                        this.props.onClose()
                    }} />
                    {!this.props.isSubscribed ? (<h3>Oops!</h3>) : (<h3>Welcome!</h3>)}
                    {this.props.isSubscribed
                        ? (<p>Awesome Your subscription is completed, that is very much awesome, thank you so much for your support for Bhodi and you have subscribed for that company now.</p>)
                        : (<p>Sorry your payment was incompleted so it isn't subscribed.</p>)}
                </div>
            </div>
        )
    }
}

export default SubscribeForCompanyAnnouncement