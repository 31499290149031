import firebase from '../Config/Firebase'

const sendRequest = (e) => {
    let currentUser = { ...e };
    let userid = localStorage.getItem("userid");
    let push = firebase
        .database()
        .ref("users/" + userid + "/sentrequests")
        .push().key;
    let pushHide = firebase
        .database()
        .ref("users/" + userid + "/usersToHide")
        .push().key;
    let pushSec = firebase
        .database()
        .ref("users/" + currentUser.user_id + "/comingrequests")
        .push().key;
    let pushHideSec = firebase
        .database()
        .ref("users/" + currentUser.user_id + "/usersToHide")
        .push().key;
    let obj = {
        push,
        user_id: e.user_id,
    };
    let objSec = {
        push,
        user_id: userid,
    };
    let rejectlist = currentUser.rejectlist;
    if (rejectlist) {
        let objected = Object.entries(currentUser.rejectlist);
        let filtered = objected.filter((t) => {
            return t[1].user_id === userid;
        });
        if (filtered.length > 0) {
            firebase
                .database()
                .ref("users/" + userid + "/sentrequests/" + push)
                .set(obj);
            firebase
                .database()
                .ref("users/" + userid + "/usersToHide/" + pushHide)
                .set(obj);
        } else {
            firebase
                .database()
                .ref("users/" + userid + "/sentrequests/" + push)
                .set(obj);
            firebase
                .database()
                .ref("users/" + currentUser.user_id + "/comingrequests/" + pushSec)
                .set(objSec);
            firebase
                .database()
                .ref("users/" + currentUser.user_id + "/usersToHide/" + pushHideSec)
                .set(objSec);
            firebase
                .database()
                .ref("users/" + userid + "/usersToHide/" + pushHide)
                .set(obj);
        }
    } else {
        firebase
            .database()
            .ref("users/" + userid + "/sentrequests/" + push)
            .set(obj);
        firebase
            .database()
            .ref("users/" + currentUser.user_id + "/comingrequests/" + pushSec)
            .set(objSec);
        firebase
            .database()
            .ref("users/" + currentUser.user_id + "/usersToHide/" + pushHideSec)
            .set(objSec);
        firebase
            .database()
            .ref("users/" + userid + "/usersToHide/" + pushHide)
            .set(obj);
    }
}


const deleteFriend = (currentUserProps, currentUserHereProps, albumProps) => {
    let e = currentUserProps;
    let eHere = currentUserHereProps;
    let currentUser = { ...e };
    let currentUserHere = { ...eHere };
    let userid = localStorage.getItem("userid");

    if (currentUser.friends) {
        let objected = Object.entries(currentUser.friends);
        let filtered = objected.filter((g) => {
            return g[1].user_id === userid;
        });
        if (filtered.length > 0) {
            let objectedSent = Object.entries(currentUserHere.friends);
            let filteredSent = objectedSent.filter((g) => {
                return g[1].user_id === currentUser.user_id;
            });
            if (filteredSent.length > 0) {
                let filteredHideComing = [];
                let filteredHideSent = [];
                if (currentUser.usersToHide) {
                    let objectedHideComing = Object.entries(currentUser.usersToHide);
                    filteredHideComing = objectedHideComing.filter((g) => {
                        return g[1].user_id === userid;
                    });
                }
                if (currentUserHere.usersToHide) {
                    let objectedHideSent = Object.entries(currentUserHere.usersToHide);
                    filteredHideSent = objectedHideSent.filter((g) => {
                        return g[1].user_id === currentUser.user_id;
                    });
                }
                firebase
                    .database()
                    .ref("users/" + currentUser.user_id + "/friends/" + filtered[0][0])
                    .remove();
                if (filteredHideComing.length > 0) {
                    firebase
                        .database()
                        .ref(
                            "users/" +
                            currentUser.user_id +
                            "/usersToHide/" +
                            filteredHideComing[0][0]
                        )
                        .remove();
                }
                firebase
                    .database()
                    .ref(
                        "allMessages/" +
                        currentUser.user_id +
                        "/" +
                        currentUserHere.user_id
                    )
                    .remove();
                firebase
                    .database()
                    .ref(
                        "allMessages/" +
                        currentUserHere.user_id +
                        "/" +
                        currentUser.user_id
                    )
                    .remove();
                firebase
                    .database()
                    .ref(
                        "users/" +
                        currentUserHere.user_id +
                        "/friends/" +
                        filteredSent[0][0]
                    )
                    .remove();
                if (filteredHideSent.length > 0) {
                    firebase
                        .database()
                        .ref(
                            "users/" +
                            currentUserHere.user_id +
                            "/usersToHide/" +
                            filteredHideSent[0][0]
                        )
                        .remove();
                }
            }
        }
    }
}

const undoFromSent = (currentUserProps, currentUserHere, e) => {
    let filteredSent = [];
    let filteredHideSent = [];
    if (currentUserHere.sentrequests) {
        let objected = Object.entries(currentUserHere.sentrequests);
        let filtered = objected.filter((g) => {
            return g[1].user_id === currentUserProps.user_id;
        });
        if (filtered.length > 0) {
            let objectedHideComing = Object.entries(
                currentUserHere.usersToHide
            );
            let filteredHideComing = objectedHideComing.filter((g) => {
                return g[1].user_id === currentUserProps.user_id;
            });
            if (currentUserProps.comingrequests) {
                let objectedSent = Object.entries(currentUserProps.comingrequests);
                filteredSent = objectedSent.filter((g) => {
                    return g[1].user_id === currentUserHere.user_id;
                });
                let objectedHideSent = Object.entries(currentUserProps.usersToHide);
                filteredHideSent = objectedHideSent.filter((g) => {
                    return g[1].user_id === currentUserHere.user_id;
                });
            }
            firebase
                .database()
                .ref(
                    "users/" +
                    currentUserHere.user_id +
                    "/sentrequests/" +
                    filtered[0][0]
                )
                .remove();
            firebase
                .database()
                .ref(
                    "users/" +
                    currentUserHere.user_id +
                    "/usersToHide/" +
                    filteredHideComing[0][0]
                )
                .remove();
            if (filteredSent.length > 0) {
                firebase
                    .database()
                    .ref("users/" + currentUserProps.user_id + "/comingrequests/" + filteredSent[0][0])
                    .remove();
                firebase
                    .database()
                    .ref("users/" + currentUserProps.user_id + "/usersToHide/" + filteredHideSent[0][0])
                    .remove();
            }
        }
    }
}


const rejectInvite = (currentUserProps, currentUserHere) => {
    let userid = localStorage.getItem("userid");

    let sentRequests = "";
    if (currentUserProps.sentrequests) {
        sentRequests = Object.entries(currentUserProps.sentrequests);
    }
    if (sentRequests && sentRequests.length > 0) {
        let filteredPushSend = sentRequests.filter((f) => {
            return f[1].user_id === userid;
        });
        let pushKeySend = "";
        if (filteredPushSend.length > 0) {
            pushKeySend = filteredPushSend[0][0];
        }
        let b = currentUserHere;
        if (b.comingrequests) {
            let comingRequests = Object.entries(b.comingrequests);
            let filteredPushComing = comingRequests.filter((g) => {
                return g[1].user_id === currentUserProps.user_id;
            });
            let pushKeyComing = "";
            if (filteredPushComing.length > 0) {
                pushKeyComing = filteredPushComing[0][0];
            }
            if (pushKeyComing) {
                let pushKey = firebase
                    .database()
                    .ref("users/" + currentUserProps.user_id + "/inRejectList")
                    .push().key;
                let pushKeySec = firebase
                    .database()
                    .ref("users/" + userid + "/rejectlist")
                    .push().key;
                let obj = {
                    pushKey,
                    user_id: userid,
                };
                let objSec = {
                    pushKey: pushKeySec,
                    user_id: currentUserProps.user_id,
                };
                // firebase.database().ref("users/" + e + "/sentrequests/" + pushKeySend).remove()
                firebase
                    .database()
                    .ref(
                        "users/" +
                        userid +
                        "/comingrequests/" +
                        pushKeyComing
                    )
                    .remove();
                firebase
                    .database()
                    .ref("users/" + currentUserProps.user_id + "/inRejectList/" + pushKey)
                    .set(obj);
                firebase
                    .database()
                    .ref(
                        "users/" + userid + "/rejectlist/" + pushKeySec
                    )
                    .set(objSec);
            }
        }
    }
}

function followUnfollowCompany(companyToFollow, currentUserId, allUsers) {
    if (companyToFollow.followed_by) {
        let objected = Object.entries(companyToFollow.followed_by);
        let filtered = objected.filter((g) => {
            return g[1].user_id === currentUserId;
        });
        if (filtered.length > 0) {
            if (companyToFollow.newFollowersList) {
                let objectedSec = Object.entries(
                    companyToFollow.newFollowersList
                );
                let filteredNewFollowers = objectedSec.filter((g) => {
                    return g[1].user_id === currentUserId;
                });
                if (filteredNewFollowers.length > 0) {
                    let newFollowers = Number(
                        companyToFollow.newFollowers
                    );
                    newFollowers = newFollowers - 1;
                    firebase
                        .database()
                        .ref(
                            "users/" +
                            companyToFollow.user_id +
                            "/newFollowersList/" +
                            filteredNewFollowers[0][1].push_key
                        )
                        .remove();
                    firebase
                        .database()
                        .ref(
                            "users/" +
                            companyToFollow.user_id +
                            "/newFollowers"
                        )
                        .set(newFollowers);
                }
            }
            let filteredCurrentUser = allUsers.filter((h) => {
                return h.user_id === currentUserId;
            });
            if (filteredCurrentUser[0].following) {
                let objectedSec = Object.entries(filteredCurrentUser[0].following);
                let filteredSec = objectedSec.filter((g) => {
                    return g[1].user_id === companyToFollow.user_id;
                });
                if (filteredSec.length > 0) {
                    firebase
                        .database()
                        .ref(
                            "users/" +
                            currentUserId +
                            "/following/" +
                            filteredSec[0][1].push_key
                        )
                        .remove();
                }
            }
            firebase
                .database()
                .ref(
                    "users/" +
                    companyToFollow.user_id +
                    "/followed_by/" +
                    filtered[0][1].push_key
                )
                .remove();
        } else {
            let pushFirst = firebase
                .database()
                .ref("users/" + currentUserId + "/following")
                .push().key;
            let pushSecond = firebase
                .database()
                .ref("users/" + companyToFollow.user_id + "/followed_by")
                .push().key;
            let pushSecondNew = firebase
                .database()
                .ref(
                    "users/" +
                    companyToFollow.user_id +
                    "/newFollowersList"
                )
                .push().key;
            let newFollowers = 0;
            let obj = {
                user_id: companyToFollow.user_id,
                push_key: pushFirst,
            };
            let objSec = {
                user_id: currentUserId,
                push_key: pushSecond,
            };
            let objThird = {
                user_id: currentUserId,
                push_key: pushSecondNew,
            };
            if (companyToFollow.newFollowers) {
                newFollowers = Number(companyToFollow.newFollowers);
            }
            newFollowers = newFollowers + 1;
            firebase
                .database()
                .ref("users/" + currentUserId + "/following/" + pushFirst)
                .set(obj);
            firebase
                .database()
                .ref(
                    "users/" +
                    companyToFollow.user_id +
                    "/followed_by/" +
                    pushSecond
                )
                .set(objSec);
            firebase
                .database()
                .ref(
                    "users/" + companyToFollow.user_id + "/newFollowers"
                )
                .set(newFollowers);
            firebase
                .database()
                .ref(
                    "users/" +
                    companyToFollow.user_id +
                    "/newFollowersList/" +
                    pushSecondNew
                )
                .set(objThird);
        }
    } else {
        let pushFirst = firebase
            .database()
            .ref("users/" + currentUserId + "/following")
            .push().key;
        let pushSecond = firebase
            .database()
            .ref("users/" + companyToFollow.user_id + "/followed_by")
            .push().key;
        let pushSecondNew = firebase
            .database()
            .ref(
                "users/" + companyToFollow.user_id + "/newFollowersList"
            )
            .push().key;
        let newFollowers = 0;
        let obj = {
            user_id: companyToFollow.user_id,
            push_key: pushFirst,
        };
        let objSec = {
            user_id: currentUserId,
            push_key: pushSecond,
        };
        let objThird = {
            user_id: currentUserId,
            push_key: pushSecondNew,
        };
        if (companyToFollow.newFollowers) {
            newFollowers = Number(companyToFollow.newFollowers);
        }
        newFollowers = newFollowers + 1;
        firebase
            .database()
            .ref("users/" + currentUserId + "/following/" + pushFirst)
            .set(obj);
        firebase
            .database()
            .ref(
                "users/" +
                companyToFollow.user_id +
                "/followed_by/" +
                pushSecond
            )
            .set(objSec);
        firebase
            .database()
            .ref("users/" + companyToFollow.user_id + "/newFollowers")
            .set(newFollowers);
        firebase
            .database()
            .ref(
                "users/" +
                companyToFollow.user_id +
                "/newFollowersList/" +
                pushSecondNew
            )
            .set(objThird);
    }
}

export { sendRequest, deleteFriend, undoFromSent, rejectInvite, followUnfollowCompany }