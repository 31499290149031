import React, { Component } from 'react'
import { MdClose } from 'react-icons/md';
import { history } from '../../Config/Routes';
import CharityLandingPage from '../CharityLandingsPage';
import ChevronLeft from '@material-ui/icons/ChevronLeft';

export class NewStoryPopup extends Component {

    constructor() {
        super()
        this.state = {
            animationOn: false,
            loadPage: false,
        }
    }


    componentDidMount() {
        setTimeout(() => {
            this.setState({ animationOn: true })
        }, 200);
    }

    render() {
        return (
            <div className={`container-new-story-bhodi ${this.state.animationOn && "active"}`}>
                <div className="inner-cnsb">
                    <div className='close-icon-top' style={{ left: this.props.moveOnePageBack && 20, right: this.props.moveOnePageBack && "auto" }} onClick={() => {
                        // let urlMain = new URLSearchParams(window.location.search)

                        if (this.props.moveOnePageBack) {
                            if (window.location.href.includes("#charity")) {
                                history.go(-2)
                            } else {
                                history.goBack()
                            }
                        } else {
                            this.props.closePopup()
                        }
                    }}>
                        {this.props.moveOnePageBack ? (<ChevronLeft />) : (<MdClose />)}
                    </div>
                    <CharityLandingPage fromPopup={true} dontshowMenu={true} />
                </div>
            </div>
        )
    }
}

export default NewStoryPopup