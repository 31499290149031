import localforage from "localforage";

let user = "";
let message = "";

localforage.getItem("persist:root").then((value) => {
  if (value) {
    let converted = JSON.parse(value);
    if (converted.authReducers) {
      let parsed = JSON.parse(converted.authReducers);
      if (parsed.user) {
        user = parsed.user;
      }
      if (parsed.message) {
        message = parsed.message;
      }
    }
  }
});

const initial_state = {
  user: user ? user : null,
  // album: null,
  // images: null,
  message: message ? message : null,
  // maincategoriesvoucher: null,
  // subcategoriesvoucher: null,
  // shopsectors: null,
  // maincategoriesshop: null,
  // subcategoriesshop: null,
  // globalsectors: null,
  // album_categories: null,
  // categories_albums: null,
  // categories_albums_available: null,
  // guest_user_cart: null,
  // guest_user_cart_time: null,
  // guest_user_cart_extend_opportunity: null,
  // guest_db_cart: null,
  // global_cart: null,
  // product: null,
  // vouchers: null,
  // globalvouchers: null,
  // globalshopproducts: null,
  // shopproducts: null,
  // allVouchers: null,
  // vouchersectors: null,
  // bookmarked_things: null,
  // bewaared_campaigns: null,
  // currentMoments: null,
  // currentMomentsFriends: null,
  // albumToAdd: null,
  // morealbumsToAdd: null,
  // albumToAddFriends: null,
  // morealbumsToAddFriends: null,
  // selected_dragged_files: null,
  // selected_dragged_files_name: null,
  // subscription_plans: null,
  // global_values: null,
  // all_impressies: null,
  // signup_fields_info: null,
  // admin_boolean: null,
  // bhodi_sounds: null,
  // charity_videos: null,
  // stock_images: null,
  // current_exclusives: null,
  // friends_exclusives: null,
  // selected_exclusive: null,
  // all_exclusives: null,
  // selected_exclusive_category: null,
  // selected_exclusive_category_friends: null,
  // selected_exclusive_friends: null,
};

const reducer = (state = initial_state, action) => {
  switch (action.type) {
    case "get_all_users": {
      return { ...state, user: action.user };
    }
    // case "get_all_albums": {
    //     return { ...state, album: action.album }
    // }
    // case "get_all_images": {
    //     return { ...state, images: action.images }
    // }
    case "get_all_messages": {
      return { ...state, message: action.message };
    }
    // case "get_all_products": {
    //     return { ...state, product: action.product }
    // }
    // case "get_all_vouchers": {
    //     return { ...state, vouchers: action.vouchers }
    // }
    // case "get_all_global_vouchers": {
    //     return { ...state, globalvouchers: action.globalvouchers }
    // }
    // case "get_all_global_products": {
    //     return { ...state, globalshopproducts: action.globalshopproducts }
    // }
    // case "all_shop_products": {
    //     return { ...state, shopproducts: action.shopproducts }
    // }
    // case "all_vouchers": {
    //     return { ...state, allVouchers: action.allVouchers }
    // }
    // case "all_vouchers_sc": {
    //     return { ...state, vouchersectors: action.vouchersectors }
    // }
    // case "update_main_category": {
    //     return { ...state, maincategoriesvoucher: action.maincategoriesvoucher }
    // }
    // case "update_sub_category": {
    //     return { ...state, subcategoriesvoucher: action.subcategoriesvoucher }
    // }
    // case "all_shop_sc": {
    //     return { ...state, shopsectors: action.shopsectors }
    // }
    // case "update_main_category_shop": {
    //     return { ...state, maincategoriesshop: action.maincategoriesshop }
    // }
    // case "update_sub_category_shop": {
    //     return { ...state, subcategoriesshop: action.subcategoriesshop }
    // }
    // case "update_bookmarked_things": {
    //     return { ...state, bookmarked_things: action.bookmarked_things }
    // }
    // case "update_current_moments": {
    //     return { ...state, currentMoments: action.currentMoments }
    // }
    // case "update_current_moments_friends": {
    //     return { ...state, currentMomentsFriends: action.currentMomentsFriends }
    // }
    // case "update_all_sectors": {
    //     return { ...state, globalsectors: action.globalsectors }
    // }
    // case "update_subscription_plans": {
    //     return { ...state, subscription_plans: action.subscription_plans }
    // }
    // case "update_global_stuff": {
    //     return { ...state, global_values: action.global_values }
    // }
    // case "update_all_campaigns": {
    //     return { ...state, global_campaigns: action.global_campaigns }
    // }
    // case "update_impressies": {
    //     return { ...state, all_impressies: action.all_impressies }
    // }
    // case "update_album_to_add": {
    //     console.log(action.albumToAdd)
    //     return { ...state, albumToAdd: action.albumToAdd }
    // }
    // case "update_more_albums_added": {
    //     return { ...state, morealbumsToAdd: action.morealbumsToAdd }
    // }
    // case "update_album_to_add_friends": {
    //     return { ...state, albumToAddFriends: action.albumToAddFriends }
    // }
    // case "update_more_albums_added_friends": {
    //     return { ...state, morealbumsToAddFriends: action.morealbumsToAddFriends }
    // }
    // case "update_bewaared_campaigns": {
    //     return { ...state, bewaared_campaigns: action.bewaared_campaigns }
    // }
    // case "update_album_categories": {
    //     return { ...state, album_categories: action.album_categories }
    // }
    // case "update_current_exclusives": {
    //     return { ...state, current_exclusives: action.current_exclusives }
    // }
    // case "update_friends_exclusives": {
    //     return { ...state, friends_exclusives: action.friends_exclusives }
    // }
    // case "update_selected_exclusive": {
    //     return { ...state, selected_exclusive: action.selected_exclusive }
    // }
    // case "update_all_exclusives": {
    //     return { ...state, all_exclusives: action.all_exclusives }
    // }
    // case "update_selected_exclusive_category": {
    //     return { ...state, selected_exclusive_category: action.selected_exclusive_category }
    // }
    // case "update_selected_exclusive_friends": {
    //     return { ...state, selected_exclusive_friends: action.selected_exclusive_friends }
    // }
    // case "update_selected_exclusive_category_friends": {
    //     return { ...state, selected_exclusive_category_friends: action.selected_exclusive_category_friends }
    // }
    // case "update_dragged_files": {
    //     return { ...state, selected_dragged_files: action.selected_dragged_files }
    // }
    // case "update_dragged_files_name": {
    //     return { ...state, selected_dragged_files_name: action.selected_dragged_files_name }
    // }
    // case "update_category_albums": {
    //     return { ...state, categories_albums: action.categories_albums }
    // }
    // case "update_category_albums_available": {
    //     return { ...state, categories_albums_available: action.categories_albums_available }
    // }
    // case "update_guest_user_cart": {
    //     return { ...state, guest_user_cart: action.guest_user_cart }
    // }
    // case "update_guest_user_cart_time": {
    //     return { ...state, guest_user_cart_time: action.guest_user_cart_time }
    // }
    // case "update_guest_user_cart_extend_opportunity": {
    //     return { ...state, guest_user_cart_extend_opportunity: action.guest_user_cart_extend_opportunity }
    // }
    // case "update_signup_fields_info": {
    //     return { ...state, signup_fields_info: action.signup_fields_info }
    // }
    // case "update_guest_db_cart": {
    //     return { ...state, guest_db_cart: action.guest_db_cart }
    // }
    // case "update_admin_or_not": {
    //     return { ...state, admin_boolean: action.admin_boolean }
    // }
    // case "update_charity_videos": {
    //     return { ...state, charity_videos: action.charity_videos }
    // }
    // case "update_bhodi_sounds": {
    //     return { ...state, bhodi_sounds: action.bhodi_sounds }
    // }
    // case "update_all_products_new": {
    //     return { ...state, products_new: action.products_new }
    // }
    // case "update_my_cart": {
    //     return { ...state, global_cart: action.global_cart }
    // }
    // case "update_stock_images": {
    //     return { ...state, stock_images: action.stock_images }
    // }
    default: {
      return state;
    }
  }
};

export default reducer;
