let invalidChars = [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
    "/",
    ".",
    "'",
    ";",
    "[",
    "]",
    "=",
    "`",
    "",
];

let pricesId = {
    '3.95': 'price_1OZEbBGbWn2wWpJRKnnOnk7p',
    '4.50': 'price_1OZEbBGbWn2wWpJRKnnOnk7p',
    '4.95': 'price_1OZEbBGbWn2wWpJRKnnOnk7p',
    '5.00': 'price_1OZEbBGbWn2wWpJRKnnOnk7p',
    '5.50': 'price_1OZEbBGbWn2wWpJRKnnOnk7p',
    '6.00': 'price_1OZEbBGbWn2wWpJRKnnOnk7p',
    '6.50': 'price_1OZEbBGbWn2wWpJRKnnOnk7p',
    '7.50': 'price_1OZEbBGbWn2wWpJRKnnOnk7p',
    '8.50': 'price_1OZEbBGbWn2wWpJRKnnOnk7p',
    '12.50': 'price_1OZEbBGbWn2wWpJRKnnOnk7p'
}

export {
    invalidChars,
    pricesId,
}