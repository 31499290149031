import React, { Component } from "react";
import AddIcon from "@material-ui/icons/Add";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import SortableTree from "react-sortable-tree";
import "react-sortable-tree/style.css";
import CloseIcon from "@material-ui/icons/Close";
import AddCircleOutline from "@material-ui/icons/AddCircleOutline";
import firebase from "../Config/Firebase";
import DeleteIcon from "@material-ui/icons/Delete";
import AdminNavbar from "./AdminNavbar";

export class SoundSorting extends Component {
  constructor(props) {
    super(props);

    this.state = {
      treeData: [],
      sectorModal: false,
      sectorModalCategory: false,
      sectorName: "",
      sectors: [],
      currentSector: "",
      currentSectorId: "",
      categoryName: "",
      currentSector: "",
      categoryId: "",
      sortingTab: true,
      adjustingTab: false,
      arrOfInputs: [],
      soundName: "",
      soundFile: [],
      allSounds: [],
      isCreating: false,
    };
  }

  componentDidMount() {
    const loaderId = document.getElementById("main-page-loader-id");
    loaderId.style.display = "none";
    firebase
      .database()
      .ref("all_sounds")
      .on("value", (data) => {
        let a = data.val();
        let arrSec = [];
        if (a) {
          let objected = Object.entries(a);
          objected.map((g) => {
            arrSec.push(g[1]);
          });
          let arrOfInputs = [];
          if (this.state.arrOfInputs.length > 0) {
            if (this.state.allSounds.length !== arrSec.length) {
              arrSec.map((g) => {
                let obj = {
                  inputOpened: false,
                };
                arrOfInputs.push(obj);
              });
            } else {
              arrOfInputs = this.state.arrOfInputs;
            }
          } else {
            arrSec.map((g) => {
              let obj = {
                inputOpened: false,
              };
              arrOfInputs.push(obj);
            });
          }
          this.setState({ allSounds: arrSec, arrOfInputs });
        } else {
          this.setState({ allSounds: [] });
        }
      });
  }

  createSound() {
    const { soundName } = this.state;
    if (this.state.soundFile.length > 0) {
      this.setState({ isCreating: true });
      let e = this.state.soundFile[0];
      firebase
        .storage()
        .ref()
        .child(`soundsProducten/${e.name}${new Date().getTime()}`)
        .put(e)
        .then((snapshot) => {
          snapshot.ref.getDownloadURL().then((snapUrl) => {
            let randomNum = Math.floor(Math.random() * 90 + 10);
            let id = soundName + "" + randomNum;
            let pushKey = firebase.database().ref("all_sounds").push().key;
            let obj = {
              title: soundName,
              sound_id: id,
              pushKey,
              soundURL: snapUrl,
            };
            firebase
              .database()
              .ref("all_sounds/" + pushKey)
              .set(obj)
              .then(() => {
                document.getElementById("root").style.position = "static";
                document.getElementById("root").style.overflow = "auto";
                this.setState({
                  soundName: "",
                  sectorModal: false,
                  isCreating: false,
                });
              });
          });
        });
    }
  }

  uploadSound(event) {
    event.preventDefault();
    let dropFiles = [];
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      dropFiles.push(file);
      this.setState({ soundFile: dropFiles });
    }
  }

  render() {
    return (
      <div className="sorting-mainadmin">
        <AdminNavbar soundsPage={true} />
        <div className="container-sma">
          <div className="top-container-sma">
            <div className="sector-tcsma"></div>
            <div className="category-tcsma">
              <p
                onClick={() => {
                  document.getElementById("root").style.position = "fixed";
                  document.getElementById("root").style.overflow = "hidden";
                  this.setState({ sectorModal: true });
                }}
              >
                <AddIcon /> SOUND{" "}
              </p>
            </div>
          </div>
          <div className="tree-inner" style={{ marginTop: 30 }}>
            <ul className="adjustng-ti">
              {this.state.allSounds.map((h, i) => {
                return (
                  <li
                    onClick={() => {
                      this.state.arrOfInputs[i].inputOpened = true;
                      this.setState({ arrOfInputs: this.state.arrOfInputs });
                    }}
                  >
                    {!this.state.arrOfInputs[i].inputOpened ? (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <label style={{ flexGrow: 1, marginBottom: 0 }}>
                          {h.title}
                        </label>
                        <DeleteIcon
                          onClick={() => {
                            firebase
                              .database()
                              .ref("all_sounds/" + h.pushKey)
                              .remove();
                          }}
                        />
                      </div>
                    ) : (
                      <input
                        type="text"
                        value={h.title}
                        autoFocus
                        onBlur={() => {
                          this.state.arrOfInputs[i].inputOpened = false;
                          this.setState({
                            arrOfInputs: this.state.arrOfInputs,
                          });
                        }}
                        onChange={(e) => {
                          this.state.allSounds[i].title = e.target.value;
                          firebase
                            .database()
                            .ref("all_sounds/" + h.pushKey)
                            .set(this.state.allSounds[i]);
                        }}
                      />
                    )}{" "}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div
          className="modal-sector-sorting"
          style={{ right: this.state.sectorModal ? 0 : "-100%" }}
        >
          <CloseIcon
            className="close-mss"
            onClick={() => {
              document.getElementById("root").style.position = "static";
              document.getElementById("root").style.overflow = "auto";
              this.setState({ sectorModal: false });
            }}
          />
          <p>Create Sound</p>
          <div className="form-mss">
            <label>Sound name</label>
            <input
              type="text"
              value={this.state.soundName}
              onChange={(e) => this.setState({ soundName: e.target.value })}
            />
          </div>
          <div className="form-mss">
            <label>Sound file</label>
            <input
              type="file"
              accept="audio/*"
              onChange={(e) => this.uploadSound(e)}
              style={{ backgroundColor: "white" }}
            />
          </div>
          <div className="form-mss">
            {this.state.isCreating ? (
              <button>CREATING</button>
            ) : (
              <button onClick={this.createSound.bind(this)}>CREATE</button>
            )}
          </div>
        </div>
        <div
          className="modal-sector-overlay"
          style={{
            opacity: this.state.sectorModal ? 0.7 : 0,
            pointerEvents: this.state.sectorModal ? "all" : "none",
          }}
          onClick={() => {
            document.getElementById("root").style.position = "static";
            document.getElementById("root").style.overflow = "auto";
            this.setState({ sectorModal: false });
          }}
        ></div>
      </div>
    );
  }
}

export default SoundSorting;
