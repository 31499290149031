import React, { Component } from "react";
import "./index.css";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import CloseIcon from "@material-ui/icons/Close";
import { RiTimerLine, RiShoppingBasketLine } from "react-icons/ri";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import countries from "../../DataJSON/countries.json";
import { connect } from "react-redux";
import Footer from "../../Components/Footer";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import firebase from "../../Config/Firebase";
import {
  updateSubscriptionPlans,
  updateGuestUserCart,
  updateAdminOrNot,
  updateGuestUserCartTime,
  updateSignUpFieldsFilled,
} from "../../Redux/actions/authActions";
import Switch from "@material-ui/core/Switch";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import FooterGreen from "../../Components/FooterGreen";
import { history } from "../../Config/Routes";
import TimerGec from "../../Components/TimerGec";
import FooterBasketTimer from "../../Components/FooterBasketTimer";
import swal from "sweetalert";
import { BsCheck } from "react-icons/bs";
import { BiCheck } from "react-icons/bi";
import { invalidChars } from "../../Config/Constants";
import { mergeAdminEvent } from "../../Functions/globalFunctions";

const axios = require("axios");

const CancelToken = axios.CancelToken
let cancelPreviousRequest;

export class GuestEventCart extends Component {
  constructor() {
    super();
    this.state = {
      country: "155",
      isUserForm: true,
      showCharityStuff: false,
      subscription_data: [],
      plan_id: "",
      plan_amount: "",
      // registration fields
      firstName: "",
      firstNamePopup: false,
      lastName: "",
      lastNamePopup: false,
      email: "",
      emailPopup: false,
      password: "",
      passwordPopup: false,
      country: "155",
      companyRegistration: false,
      companyName: "",
      kvkCompany: "",
      kvkCompanyPopup: false,
      street: "",
      streetPopup: false,
      houseNumber: "",
      houseNumberPopup: false,
      city: "",
      cityPopup: false,
      zipCode: "",
      zipCodePopup: false,
      phoneNumber: "",
      phoneNumberPopup: false,
      company_website: "",
      company_websitePopup: false,
      passwordInputType: "password",
      guestUserCart: [],
      aanmeldenloader: false,
      allUsers: [],
      agreed: false,
      agreedPopup: false,
      currentUserSpecific: "",
      isUserCreated: false,
      currentDataOfUser: "",
      popupopenforright: true,
      guestCartTime: "",
      shipmentFeeAvailable: false,
      isAddressLoaded: false,
      loadingAddress: false,
      avoidReadOnly: false,
      isUserInfoFilled: false,

      sessionIdForAutocompleteAPI: "",

      addressMatches: [],
      showingDropdown: false,

      isNotGuest: false,
    };
  }

  generateUniqueId(length) {
    const characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    let uniqueId = '';

    while (uniqueId.length < length) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      uniqueId += characters.charAt(randomIndex);
    }

    return uniqueId;
  }

  componentDidMount() {

    const loaderId = document.getElementById("main-page-loader-id");
    loaderId.style.display = "none";

    this.setState({ sessionIdForAutocompleteAPI: this.generateUniqueId(10) })

    // window.addEventListener('pageshow', (event) => {
    //     if (event.persisted) {
    //         window.location.reload()
    //     }
    // })

    // if (!window.location.href.includes("stepTwo")) {
    //     var minutesToAdd = Number(process.env.REACT_APP_CART_TIME);
    //     var currentDate = new Date();
    //     var futureDate = new Date(currentDate.getTime() + minutesToAdd * 60000);

    //     let futureTimeStamp = Math.floor(futureDate.getTime() / 1000)

    //     this.props.updateGuestUserCartTime(futureTimeStamp)
    // }
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 500);

    let userid = localStorage.getItem("userid");
    let useridGuest = localStorage.getItem("userid-guest");
    let useridNonMem = localStorage.getItem("userid-nonmem");

    if (window.innerWidth > 1008) {
      this.setState({ popupopenforright: true });
    } else {
      this.setState({ popupopenforright: false });
    }

    if (this.props.guest_user_cart_time) {
      this.setState({ guestCartTime: this.props.guest_user_cart_time })
    }

    if (this.props.user) {
      if (this.props.user.length > 0) {
        this.setState({ allUsers: this.props.user });
      }
    }
    if (this.props.guest_user_cart) {
      if (this.props.guest_user_cart.length > 0) {
        if (this.props.guest_user_cart[0].item_type === "bhodi_product") {
          this.setState({ productsCheckingOut: true })
        } else {
          this.setState({ productsCheckingOut: false })
        }
        console.log(this.props.guest_user_cart, '/see here')
        this.setState({ guestUserCart: this.props.guest_user_cart });
      } else {
        this.setState({ guestUserCart: [] });
      }
    }

    firebase
      .database()
      .ref("users")
      .once("value", (data) => {
        let a = data.val();
        let arr = [];
        if (a) {
          let objected = Object.entries(a);
          objected.map((g) => {
            if (
              g[0] !== undefined &&
              g[0] !== "undefined" &&
              g[0] !== null &&
              g[0] !== "null"
            ) {
              arr.push(g[1]);
            }
          });
          let usersWithNumbers = []
          arr.map((t) => {
            if (t.user_id_numbers) {
              usersWithNumbers.push(t)
            }
          })
          let sorted = usersWithNumbers.sort((a, b) => {
            if (a.user_id_numbers && b.user_id_numbers) {
              return a.user_id_numbers - b.user_id_numbers;
            }
          });
          this.setState({
            allUsers: arr,
            currentUserSpecific: Number(sorted[sorted.length - 1].user_id_numbers) + 1,
          });
        }
      });

    let arr = [];
    if (this.props.subscription_plans) {
      if (this.props.subscription_plans.length > 0) {
        arr = this.props.subscription_plans;
        this.setState({
          subscription_data: arr,
          plan_id: arr[0].plan_id,
          plan_amount: arr[0].price,
        });
      }
    }


    let filtered = [];
    if (this.props.user) {
      filtered = this.props.user.filter((u) => {
        if (useridNonMem) {
          return u.user_id === useridNonMem;
        } else if (useridGuest) {
          return u.user_id === useridGuest;
        } else {
          return u.user_id === userid;
        }
      });
    }
    if (filtered.length > 0) {
      if (filtered[0].first_name) {
        this.setState({ firstName: filtered[0].first_name });
      }
      if (filtered[0].email) {
        this.setState({ email: filtered[0].email });
      }
      if (filtered[0].last_name) {
        this.setState({ lastName: filtered[0].last_name });
      }
      if (filtered[0].password) {
        this.setState({ password: filtered[0].password });
      }
      if (filtered[0].country) {
        let filteredSecond = countries.filter((y) => {
          return y.name === filtered[0].country;
        });
        if (filteredSecond.length > 0) {
          this.setState({ country: filteredSecond[0].id });
        }
      }
      if (filtered[0].street) {
        this.setState({ street: filtered[0].street });
      }
      if (filtered[0].city) {
        this.setState({ city: filtered[0].city });
      }
      if (filtered[0].zipCode) {
        this.setState({ zipCode: filtered[0].zipCode });
      }
      if (filtered[0].houseNumber) {
        this.setState({ houseNumber: filtered[0].houseNumber });
      }
      if (filtered[0].company_website) {
        this.setState({ company_website: filtered[0].company_website });
      }
      if (filtered[0].kvkCompany) {
        this.setState({ kvkCompany: filtered[0].kvkCompany });
      }
      if (filtered[0].phoneNumber) {
        this.setState({ phoneNumber: filtered[0].phoneNumber });
      }
      if (filtered[0].company_name) {
        this.setState({
          companyName: filtered[0].company_name,
          isUserForm: false,
        });
      }
      if (filtered[0]) {
        if (filtered[0].type === "company") {
          this.setState({
            redirectUrl: `${window.location.origin}/company/${filtered[0].user_name_id}/charityConfirmation`,
          });
        } else {
          this.setState({
            redirectUrl: `${window.location.origin}/member/${filtered[0].user_name_id}/charityConfirmation`,
          });
        }
      }
      if (useridGuest) {
        this.setState({ isUserCreated: true, currentDataOfUser: filtered[0], isAddressLoaded: false, isNotGuest: false });
      } else {
        this.setState({ isUserCreated: true, currentDataOfUser: filtered[0], isAddressLoaded: true, isNotGuest: true });
      }
    } else {
      this.setState({ isUserInfoFilled: false, currentDataOfUser: "" });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.guest_user_cart_time !== this.props.guest_user_cart_time) {
      if (this.props.guest_user_cart_time) {
        this.setState({ guestCartTime: this.props.guest_user_cart_time })
      } else {
        swal(
          "LET OP",
          "Cart is expired",
          "error"
        ).then(() => {
          history.goBack();
        });
        this.setState({ guestCartTime: "", guestUserCart: [] })
      }
    }

    if (this.props.global_campaigns !== prevProps.global_campaigns) {
      if (this.props.global_campaigns !== prevProps.global_campaigns) {
        setTimeout(() => {
          let allArrs = this.props.global_campaigns;
          let allArrsProducts = this.props.products_new;
          let availableKeysYet = [];
          if (this.state.guestUserCart.length > 0) {
            allArrs.map((y) => {
              if (y[1].takeFromAdmin) {
                let obj = mergeAdminEvent(y[1], this.props.global_campaigns)
                y[1] = Object.assign({}, obj)
              }
              availableKeysYet.push(y[1].pushKey);
              y[1].type = "campaign";
              y[1].currentImageIndex = 0;
              if (y[1].desc) {
                y[1].desc = y[1].desc.substr(0, 200);
                y[1].short_desc = y[1].desc.substr(0, 120);
              }
              y[1].images = [y[1].image];
              y[1].imagesObjected = [
                { image: y[1].image, pushKey: y[1].pushKey },
              ];
              if (y[1].campaignType === "local_event") {
                if (y[1].takeFromAdmin) {
                  let obj = mergeAdminEvent(y[1], this.props.global_campaigns)
                  y[1] = Object.assign({}, obj)
                }
                this.state.guestUserCart.map((t, i) => {
                  if (t.pushKey === y[1].pushKey) {
                    if (!y[1].active) {
                      y[1].isNotActive = true;
                    } else {
                      y[1].isNotActive = false;
                    }
                    if (this.state.guestUserCart[i].hideFromListComplete) {
                      y[1].hideFromListComplete = true;
                    } else {
                      y[1].hideFromListComplete = false;
                    }
                    let obj = {
                      reserved_event_id: t.reserved_event_id,
                      ...y[1]
                    }
                    this.state.guestUserCart[i] = obj;
                    // this.state.guestUserCart[i] = y[1];
                  }
                });
              }
              if (y[1].campaignType === "kortings_voucher") {
                this.state.guestUserCart.map((t, i) => {
                  if (t.pushKey === y[1].pushKey) {
                    if (!y[1].active) {
                      y[1].isNotActive = true;
                    } else {
                      y[1].isNotActive = false;
                    }
                    if (this.state.guestUserCart[i].hideFromListComplete) {
                      y[1].hideFromListComplete = true;
                    } else {
                      y[1].hideFromListComplete = false;
                    }
                    this.state.guestUserCart[i] = y[1];
                  }
                });
              }
            });
            // allArrsProducts.map((y) => {
            //     availableKeysYet.push(y.pushKey)
            //     this.state.guestUserCart.map((t, i) => {
            //         if (t.pushKey === y.pushKey) {
            //             if (!y.active) {
            //                 y.isNotActive = true
            //             } else {
            //                 y.isNotActive = false
            //             }
            //             if (this.state.guestUserCart[i].hideFromListComplete) {
            //                 y.hideFromListComplete = true
            //             } else {
            //                 y.hideFromListComplete = false
            //             }
            //             this.state.guestUserCart[i] = y[1]
            //         }
            //     })
            // })
            this.state.guestUserCart.map((t) => {
              if (t.item_type === "bhodi_product") {
                return false;
              }
              if (!availableKeysYet.includes(t.pushKey)) {
                t.isNotActive = true;
              }
            });
            console.log(this.state.guestUserCart, "/working??");
            this.props.updateGuestUserCart(this.state.guestUserCart);
          }
        }, 200);
      }
    }
    if (prevProps.user !== this.props.user) {
      if (this.state.email) {
        let filtered = this.props.user.filter((u) => {
          return u.email === this.state.email;
        });
        if (filtered.length > 0) {
          this.setState({ currentDataOfUser: filtered[0] });
        } else {
          this.setState({ currentDataOfUser: "" });
        }
      }
    }
  }

  getDayName(num) {
    if (num === 0) {
      return `Zondag`;
    } else if (num === 1) {
      return `Maandag`;
    } else if (num === 2) {
      return `Dinsdag`;
    } else if (num === 3) {
      return `Woensdag`;
    } else if (num === 4) {
      return `Donderdag`;
    } else if (num === 5) {
      return `Vrijdag`;
    } else if (num === 6) {
      return `Zaterdag`;
    }
  }

  getMonthName(i) {
    i = Number(i);
    if (i === 1) {
      return "Januari";
    } else if (i === 2) {
      return "Februari";
    } else if (i === 3) {
      return "Maart";
    } else if (i === 4) {
      return "April";
    } else if (i === 5) {
      return "Mei";
    } else if (i === 6) {
      return "Juni";
    } else if (i === 7) {
      return "Juli";
    } else if (i === 8) {
      return "Augustus";
    } else if (i === 9) {
      return "September";
    } else if (i === 10) {
      return "Oktober";
    } else if (i === 11) {
      return "November";
    } else if (i === 12) {
      return "December";
    }
  }

  showDateToUserTiming(e, f) {
    if (e === f) {
      let splitted = e.split("-");
      let newDate = new Date(e).getDay();
      return `${this.getDayName(newDate)} ${splitted[2]} ${this.getMonthName(
        splitted[1]
      )}`;
    } else {
      let splitted = e.split("-");
      let splittedSecond = f.split("-");
      let newDate = new Date(e).getDay();
      let newDateAnother = new Date(f).getDay();
      if (splitted[1] === splittedSecond[1]) {
        return `${this.getDayName(newDate)} ${splitted[2]} - ${splittedSecond[2]
          } ${this.getMonthName(splittedSecond[1])}`;
      } else {
        // return `${this.getDayName(newDate)} ${splitted[2]} ${this.getMonthName(splitted[1])} - ${this.getDayName(newDateAnother)} ${splittedSecond[2]} ${this.getMonthName(splittedSecond[1])}`
        return `${splitted[2]} ${this.getMonthName(splitted[1])} t/m ${splittedSecond[2]
          } ${this.getMonthName(splittedSecond[1])}`;
      }
    }
  }

  randomStr(e) {
    let len = e;
    let arr = "abcdefghijklmnopqrstuvwxyz1928102489";
    var ans = "";
    for (var i = len; i > 0; i--) {
      ans += arr[Math.floor(Math.random() * arr.length)];
    }
    return ans;
  }

  getVerzilveredQty(e) {
    let arrItems = e;
    if (arrItems.length > 0) {
      Array.prototype.sum = function (prop) {
        var total = 0;
        for (var i = 0, _len = this.length; i < _len; i++) {
          total += Number(this[i][1][prop].length);
        }
        return total;
      };
      return arrItems.sum("allDates");
    }
  }

  getSellerName(e) {
    if (e) {
      let filteredUser = this.props.user.filter((g) => {
        if (e.mainParentCreator) {
          return g.user_id === e.mainParentCreator;
        } else {
          return g.user_id === e.created_by;
        }
      });
      if (filteredUser.length > 0) {
        if (filteredUser[0].type === "company") {
          if (filteredUser[0].company_name) {
            return filteredUser[0].company_name;
          } else {
            return filteredUser[0].first_name + " " + filteredUser[0].last_name;
          }
        } else {
          return filteredUser[0].first_name + " " + filteredUser[0].last_name;
        }
      }
    }
  }

  volgendeStap() {
    // if (this.state.productsCheckingOut && !this.state.shipmentFeeAvailable && this.state.currentDataOfUser) {
    //   if (this.state.currentDataOfUser.type === "company") {
    //     history.push(`/company/${this.state.currentDataOfUser.user_name_id.toLowerCase()}/charityConfirmation?checkout-type=product&free-of-cost=true`);
    //   } else {
    //     history.push(`/member/${this.state.currentDataOfUser.user_name_id.toLowerCase()}/charityConfirmation?checkout-type=product&free-of-cost=true`);
    //   }
    //   return false;
    // }
    const {
      firstName,
      lastName,
      email,
      country,
      companyName,
      kvkCompany,
      street,
      houseNumber,
      zipCode,
      city,
      phoneNumber,
      company_website,
    } = this.state;

    if (!this.state.isUserForm) {
      if (firstName === "") {
        this.setState({ firstNamePopup: true });
      } else {
        this.setState({ firstNamePopup: false });
      }
      if (lastName === "") {
        this.setState({ lastNamePopup: true });
      } else {
        this.setState({ lastNamePopup: false });
      }
      if (companyName === "") {
        this.setState({ companyNamePopup: true });
      } else {
        this.setState({ companyNamePopup: false });
      }
      if (kvkCompany === "") {
        this.setState({ kvkCompanyPopup: true });
      } else {
        this.setState({ kvkCompanyPopup: false });
      }
      if (street === "") {
        this.setState({ streetPopup: true });
      } else {
        this.setState({ streetPopup: false });
      }
      if (houseNumber === "") {
        this.setState({ houseNumberPopup: true });
      } else {
        this.setState({ houseNumberPopup: false });
      }
      if (zipCode === "") {
        this.setState({ zipCodePopup: true });
      } else {
        this.setState({ zipCodePopup: false });
      }
      if (city === "") {
        this.setState({ cityPopup: true });
      } else {
        this.setState({ cityPopup: false });
      }
      if (phoneNumber === "") {
        this.setState({ phoneNumberPopup: true });
      } else {
        this.setState({ phoneNumberPopup: false });
      }
      if (company_website === "") {
        this.setState({ company_websitePopup: true });
      } else {
        this.setState({ company_websitePopup: false });
      }
      if (
        firstName !== "" &&
        lastName !== "" &&
        email !== "" &&
        companyName !== "" &&
        kvkCompany !== "" &&
        street !== "" &&
        houseNumber !== "" &&
        zipCode !== "" &&
        city !== "" &&
        phoneNumber !== "" &&
        company_website !== ""
      ) {
        let uid = this.state.currentDataOfUser.user_id;

        let filtered = countries.filter((e) => {
          return e.id === Number(country);
        });

        let jointName = companyName;

        let filteredForJoin = this.state.allUsers.filter((g) => {
          return g.user_name_id === jointName.toLowerCase();
        });

        if (filteredForJoin.length > 0) {
          let randomStr = Math.ceil(Math.random() * (20000 - 0) + 0);
          jointName = companyName + randomStr;

          let filteredForJoinSecond = this.state.allUsers.filter((g) => {
            return g.user_name_id === jointName.toLowerCase();
          });
          if (filteredForJoinSecond.length > 0) {
            let randomStr = Math.ceil(Math.random() * (20000 - 0) + 0);
            jointName = companyName + randomStr;

            let filteredForJoinThird = this.state.allUsers.filter((g) => {
              return g.user_name_id === jointName.toLowerCase();
            });
            if (filteredForJoinThird.length > 0) {
              let randomStr = Math.ceil(Math.random() * (20000 - 0) + 0);
              jointName = companyName + randomStr;

              let filteredForJoinFourth = this.state.allUsers.filter(
                (g) => {
                  return g.user_name_id === jointName.toLowerCase();
                }
              );
              if (filteredForJoinFourth.length > 0) {
                let randomStr = Math.ceil(Math.random() * (20000 - 0) + 0);
                jointName = companyName + randomStr;
              }
            }
          }
        }

        if (jointName.includes(" ")) {
          jointName = jointName.replace(/ /g, "_");
        }

        firebase
          .database()
          .ref("users/" + uid + "/first_name")
          .set(firstName);
        firebase
          .database()
          .ref("users/" + uid + "/last_name")
          .set(lastName);
        firebase
          .database()
          .ref("users/" + uid + "/company_name")
          .set(companyName);
        firebase
          .database()
          .ref("users/" + uid + "/country")
          .set(filtered[0].name);
        firebase
          .database()
          .ref("users/" + uid + "/street")
          .set(street);
        firebase
          .database()
          .ref("users/" + uid + "/city")
          .set(city);
        firebase
          .database()
          .ref("users/" + uid + "/type")
          .set("company");
        firebase
          .database()
          .ref("users/" + uid + "/user_name_id")
          .set(jointName.toLowerCase());
        firebase
          .database()
          .ref("users/" + uid + "/zipCode")
          .set(zipCode);
        firebase
          .database()
          .ref("users/" + uid + "/houseNumber")
          .set(houseNumber);
        firebase
          .database()
          .ref("users/" + uid + "/company_website")
          .set(company_website);
        firebase
          .database()
          .ref("users/" + uid + "/kvkCompany")
          .set(kvkCompany);
        firebase
          .database()
          .ref("users/" + uid + "/phoneNumber")
          .set(phoneNumber);
      }
      setTimeout(() => {
        history.push("/guest-event-cart/stepThree");
      }, 200);
    } else {
      if (firstName === "") {
        this.setState({ firstNamePopup: true });
      } else {
        this.setState({ firstNamePopup: false });
      }
      if (lastName === "") {
        this.setState({ lastNamePopup: true });
      } else {
        this.setState({ lastNamePopup: false });
      }
      if (street === "") {
        this.setState({ streetPopup: true });
      } else {
        this.setState({ streetPopup: false });
      }
      if (houseNumber === "") {
        this.setState({ houseNumberPopup: true });
      } else {
        this.setState({ houseNumberPopup: false });
      }
      if (zipCode === "") {
        this.setState({ zipCodePopup: true });
      } else {
        this.setState({ zipCodePopup: false });
      }
      if (city === "") {
        this.setState({ cityPopup: true });
      } else {
        this.setState({ cityPopup: false });
      }
      if (phoneNumber === "") {
        this.setState({ phoneNumberPopup: true });
      } else {
        this.setState({ phoneNumberPopup: false });
      }
      if (
        firstName !== "" &&
        lastName !== "" &&
        street !== "" &&
        city !== "" &&
        zipCode !== "" &&
        phoneNumber !== "" &&
        houseNumber !== ""
      ) {
        let uid = this.state.currentDataOfUser.user_id;

        let filtered = countries.filter((e) => {
          return e.id === Number(country);
        });

        this.setState({ aanmeldenloader: true });

        firebase
          .database()
          .ref("users/" + uid + "/first_name")
          .set(firstName);
        firebase
          .database()
          .ref("users/" + uid + "/country")
          .set(filtered[0].name);

        if (lastName) {
          firebase
            .database()
            .ref("users/" + uid + "/last_name")
            .set(lastName);
        }
        if (street) {
          firebase
            .database()
            .ref("users/" + uid + "/street")
            .set(street);
        }
        if (city) {
          firebase
            .database()
            .ref("users/" + uid + "/city")
            .set(city);
        }
        if (zipCode) {
          firebase
            .database()
            .ref("users/" + uid + "/zipCode")
            .set(zipCode);
        }
        if (phoneNumber) {
          firebase
            .database()
            .ref("users/" + uid + "/phoneNumber")
            .set(phoneNumber);
        }
        if (houseNumber) {
          firebase
            .database()
            .ref("users/" + uid + "/houseNumber")
            .set(houseNumber);
        }
        setTimeout(() => {
          this.setState({ aanmeldenloader: false });
          history.push("/guest-event-cart/stepThree");
        }, 300);
      }
    }
  }

  getDiscountedValue(e, discount) {
    if (discount !== "Giveaway") {
      let splitted = discount.split("%");
      var numVal1 = Number(e);
      var numVal2 = Number(splitted[0]) / 100;
      var totalValue = numVal1 - numVal1 * numVal2;
      return totalValue;
    }
  }

  checkForMoreAddress(value, type) {
    let houseNumber = this.state.houseNumber
    let zipCode = this.state.zipCode
    if (type === "zipCode") {
      zipCode = value
    }
    if (type === "houseNum") {
      houseNumber = value
    }
    if (houseNumber && zipCode) {
      // this.setState({ loadingAddress: true })
      let houseSpacesRemoved = houseNumber.replace(/\s/g, '%20');
      let zipCodeSpacesRemoved = zipCode.replace(/\s/g, '%20');

      if (cancelPreviousRequest) {
        cancelPreviousRequest("Search canceled due to a new request")
      }


      const source = CancelToken.source()

      cancelPreviousRequest = source.cancel

      axios.post("https://us-central1-bhodi-production-da765.cloudfunctions.net/getCompleteAddress", {
        houseNumber: houseSpacesRemoved,
        zipCode: zipCodeSpacesRemoved,
      }, {
        headers: {
          "Content-Type": "application/json"
        },
        cancelToken: source.token,
      })
        .then((response) => {
          console.log(response, '/see here')
          if (response.status === 200 && response.data.statusCode === 200) {
            const { street, city } = response.data.data
            this.setState({ loadingAddress: false, street, city, isAddressLoaded: true, avoidReadOnly: false })
          } else {
            this.setState({ loadingAddress: false, street: "", avoidReadOnly: true, city: "", isAddressLoaded: true })
          }
        })
        .catch((error) => {
          if (error.message === "Search canceled due to a new request") {
            return false;
          }
          this.setState({ loadingAddress: false, street: "", avoidReadOnly: true, city: "", isAddressLoaded: true })
        });

      // const username = 'OVTu5YNUMjflM2uo5LArcRAZJ7DXCU0mJCRiX2uJNwb';
      // const password = 'gKe8DaFh1hV1xRsE3UdXYABuy2g1X13ySjpiss8LEMWO4EMN5x';

      // // Create a base64 encoded string of the username and password
      // const base64Credentials = btoa(`${username}:${password}`);
      // console.log(base64Credentials, '/see here')

      // axios.get(`https://api.postcode.eu/nl/v1/addresses/postcode/${zipCodeSpacesRemoved}${houseSpacesRemoved}`, {
      //   headers: {
      //     'Authorization': `Basic ${base64Credentials}`,
      //     "Content-Type": "application/json"
      //   }
      // })
      //   .then((response) => {
      //     console.log(response, '/see here')
      //   })
      //   .catch((error) => {
      //     this.setState({ loadingAddress: false, street: "", avoidReadOnly: true, city: "", isAddressLoaded: true })
      //   });

    }
  }

  // checkForMoreAddress(address) {
  //   axios.post("https://us-central1-bhodi-production-da765.cloudfunctions.net/getAddressSuggestions", {
  //     searchTerm: address,
  //     sessionId: this.state.sessionIdForAutocompleteAPI,
  //   }, {
  //     headers: {
  //       "Content-Type": "application/json"
  //     }
  //   })
  //     .then((response) => {
  //       console.log(response, '/see here')
  //       // if (response.status === 200 && response.data.statusCode === 200) {
  //       //   const { street, city } = response.data.data
  //       //   this.setState({ loadingAddress: false, street, city, isAddressLoaded: true, avoidReadOnly: false })
  //       // } else {
  //       //   this.setState({ loadingAddress: false, street: "", avoidReadOnly: true, city: "", isAddressLoaded: true })
  //       // }
  //     })
  //     .catch((error) => {
  //       this.setState({ loadingAddress: false, street: "", avoidReadOnly: true, city: "", isAddressLoaded: true })
  //     });
  // }

  handlSearch(value, type) {
    this.checkForMoreAddress(value, type)
  }

  fillFieldsWithContext(context) {
    if (context) {
      // this.setState({ loadingAddress: true })
      axios.post("https://us-central1-bhodi-production-da765.cloudfunctions.net/getAddressByContext", {
        context: context,
        sessionId: this.state.sessionIdForAutocompleteAPI,
      }, {
        headers: {
          "Content-Type": "application/json"
        },
      })
        .then((response) => {
          console.log(response, '/see here')
          if (response.status === 200 && response.data.statusCode === 200) {
            const { street, locality, buildingNumber, postcode } = response.data.data.address
            this.setState({ street, city: locality, zipCode: postcode, houseNumber: buildingNumber, isAddressLoaded: true, avoidReadOnly: false })
          } else {
            this.setState({ loadingAddress: false, avoidReadOnly: true, street: "", city: "", zipCode: "", houseNumber: "", isAddressLoaded: true })
          }
        })
        .catch((error) => {
          if (error.message === "Search canceled due to a new request") {
            return false;
          }
          this.setState({ loadingAddress: false, street: "", avoidReadOnly: true, city: "", zipCode: "", houseNumber: "", isAddressLoaded: true })
        });
    }
  }

  checkForAutoFillSuggestions(address) {
    if (address) {
      // this.setState({ loadingAddress: true })
      let addressSpacesRemoved = address.replace(/\s/g, '%20');

      if (cancelPreviousRequest) {
        cancelPreviousRequest("Search canceled due to a new request")
      }

      const source = CancelToken.source()

      cancelPreviousRequest = source.cancel

      axios.post("https://us-central1-bhodi-production-da765.cloudfunctions.net/getAddressSuggestions", {
        searchTerm: addressSpacesRemoved,
        sessionId: this.state.sessionIdForAutocompleteAPI,
      }, {
        headers: {
          "Content-Type": "application/json"
        },
        cancelToken: source.token,
      })
        .then((response) => {
          console.log(response, '/see here')
          if (response.status === 200 && response.data.statusCode === 200) {
            const { matches } = response.data.data
            this.setState({ loadingAddress: false, addressMatches: matches })
          } else {
            this.setState({ loadingAddress: false, addressMatches: [], avoidReadOnly: true, street: "", city: "", isAddressLoaded: true })
          }
        })
        .catch((error) => {
          if (error.message === "Search canceled due to a new request") {
            return false;
          }
          this.setState({ loadingAddress: false, addressMatches: [], street: "", avoidReadOnly: true, city: "", isAddressLoaded: true })
        });
    }
  }

  handlSearchAutoFill(address) {
    this.checkForAutoFillSuggestions(address)
  }
  // handlSearch(value) {
  //   this.debounceSearch(value)
  // }

  render() {
    return (
      <div className="global-event-cart globaleventcartfixedstickyfooter">
        {this.state.loadingAddress && (<div className="loader-fullpage-checkout">
          <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </div>)}
        <div className="left-gec">
          {window.innerWidth > 1008 && (<div className="button-back-lgec">
            <ChevronLeftIcon />
          </div>)}
          <div className="container-lgec">
            {/* {window.innerWidth > 1008 && <h2>BHODI</h2>}
            {this.state.productsCheckingOut ? (<div className="flex-box-checkout-steps">
              <div className="fbcs-inner">
                <div className="green-fbcsinner">
                  <BsCheck />
                </div>
                <label>Gegevens</label>
              </div>
              <div className="fbcs-inner">
                <div className="green-fbcsinner">
                </div>
                <label>Bezorging</label>
              </div>
              <div className="fbcs-inner">
                <div className="green-fbcsinner">
                </div>
                <label>Afronden</label>
              </div>
            </div>) : (
              <div className="flex-box-checkout-steps">
                <div className="fbcs-inner">
                  <div className="green-fbcsinner">
                    <BsCheck />
                  </div>
                  <label>Aanmelden</label>
                </div>
                <div className="fbcs-inner">
                  <div className="green-fbcsinner">
                    <BsCheck />
                  </div>
                  <label>Gegevens</label>
                </div>
                <div className="fbcs-inner">
                  <div className="green-fbcsinner">
                  </div>
                  <label>Charity</label>
                </div>
              </div>
            )}
            {this.state.productsCheckingOut && (<div className="selecteer-tooltipdes" style={{ position: "relative", maxWidth: 420, paddingBottom: 20, borderBottom: "1px solid #eeecec" }}>
              <p
                className="text-clgec-charity texboldclged"
                style={{
                  fontSize: 13,
                  fontWeight: 500,
                  color: "rgb(33, 37, 41)",
                }}
              >
                Afleveradres
              </p>
            </div>)} */}
            <div className="top-step-one-clgec">
              <label>Aanmelden</label>
              <label>Account gegevens</label>
              <label>Indien gewenst kun je het menu later nog wijzigen with the dezctiptrion and a bit to show here</label>
            </div>
            <div className="forms-clgec">
              {!this.state.isUserCreated && (
                <div
                  className={`form-field-clgec ${this.state.emailPopup ? "redalertffcl" : ""
                    }`}
                >
                  <label>Contact</label>
                  <input
                    type="email"
                    placeholder="Email adres"
                    autoComplete="off"
                    value={this.state.email}
                    onChange={(e) => this.setState({ email: e.target.value })}
                  />
                  {this.state.emailTookedPopup && (
                    <div className="alert-field">
                      <span style={{ letterSpacing: 1 }}>
                        Email already taken
                      </span>
                    </div>
                  )}
                </div>
              )}
              {!this.state.isUserCreated && (
                <div
                  className={`form-field-clgec ${this.state.passwordPopup ? "redalertffcl" : ""
                    }`}
                >
                  <input
                    type={this.state.passwordInputType}
                    autoComplete="off"
                    placeholder="Kies wachtwoord"
                    value={this.state.password}
                    onChange={(e) => {
                      this.setState({ password: e.target.value });
                    }}
                  />
                </div>
              )}
              {!this.state.isNotGuest && (
                <div className="form-field-clgec switchformclgec">
                  {this.state.isUserForm ? (
                    <label>Consument</label>
                  ) : (
                    <label>Zakelijk</label>
                  )}
                  <Switch
                    color="primary"
                    inputProps={{ "aria-label": "primary checkbox" }}
                    checked={this.state.isUserForm}
                    onClick={() => {
                      this.setState({
                        isUserForm: !this.state.isUserForm,
                      });
                    }}
                  />
                </div>
              )}
              {!this.state.isUserForm && (
                <div className="form-field-row">
                  <div
                    className={`form-field-clgec ${this.state.companyNamePopup ? "redalertffcl" : ""
                      }`}
                  >
                    <input
                      type="text"
                      placeholder="Bedrijfsnaam"
                      value={this.state.companyName}
                      onChange={(e) =>
                        this.setState({ companyName: e.target.value })
                      }
                    />
                  </div>
                  <div
                    className={`form-field-clgec ${this.state.kvkCompanyPopup ? "redalertffcl" : ""
                      }`}
                  >
                    <input
                      type="text"
                      placeholder="KVK nummer"
                      value={this.state.kvkCompany}
                      onChange={(e) =>
                        this.setState({ kvkCompany: e.target.value })
                      }
                    />
                  </div>
                </div>
              )}
              <div className="form-field-row">
                <div
                  className={`form-field-clgec ${this.state.firstNamePopup ? "redalertffcl" : ""
                    }`}
                >
                  <input
                    placeholder="Voornaam"
                    value={this.state.firstName}
                    onChange={(e) =>
                      this.setState({ firstName: e.target.value })
                    }
                  />
                </div>
                <div
                  className={`form-field-clgec ${this.state.lastNamePopup ? "redalertffcl" : ""
                    }`}
                >
                  <input
                    placeholder="Achternaam"
                    value={this.state.lastName}
                    onChange={(e) =>
                      this.setState({ lastName: e.target.value })
                    }
                  />
                </div>
              </div>
              <div
                className={`form-field-clgec formfielddropdown-address`}
              >
                <input
                  type="text"
                  placeholder="Plaats, straat of postcode"
                  value={this.state.address}
                  onChange={(e) => {
                    this.setState({ address: e.target.value })

                    this.handlSearchAutoFill(e.target.value)
                  }}
                  onFocus={() => {
                    this.setState({ showingDropdown: true })
                  }}
                />
                {this.state.showingDropdown && (<div className="dropdown-box-new">
                  {this.state.addressMatches.length > 0 && this.state.addressMatches.map((item) => {
                    return <li className="list-dbn" onClick={() => {
                      this.fillFieldsWithContext(item.context)

                      this.setState({ address: item.value, showingDropdown: false })
                    }}>
                      {item.label.split('').map((char, i) => (
                        // Apply bold style if the current character index is within the highlights range
                        <span style={{ fontWeight: i >= item.highlights[0] && i <= item.highlights[1] ? 'bold' : 'normal' }} key={i}>
                          {char}
                        </span>
                      ))}
                      {item.description && (<span className="desc-dbn">{item.description}</span>)}
                    </li>
                  })}
                </div>)}
              </div>
              {this.state.avoidReadOnly && (<div
                className={`form-field-clgec`}
              >
                <label style={{ marginBottom: 0 }}>Address not founded</label>
              </div>)}
              {this.state.isAddressLoaded && (<div className="form-field-row">
                <div
                  className={`form-field-clgec ffclgec-check ${this.state.streetPopup ? "redalertffcl" : ""
                    }`}
                >
                  <input
                    type="text"
                    placeholder="Postcode"
                    value={this.state.zipCode}
                    onChange={(e) => this.setState({ zipCode: e.target.value })}
                    readOnly={!this.state.avoidReadOnly}
                  />
                  {!this.state.avoidReadOnly && (<BiCheck />)}
                </div>
                <div
                  className={`form-field-clgec ffclgec-check ${this.state.cityPopup ? "redalertffcl" : ""
                    }`}
                >
                  <input
                    type="text"
                    placeholder="Huisnummer"
                    value={this.state.houseNumber}
                    onChange={(e) => this.setState({ houseNumber: e.target.value })}
                    readOnly={!this.state.avoidReadOnly}
                  />
                  {!this.state.avoidReadOnly && (<BiCheck />)}
                </div>
              </div>)}
              {this.state.isAddressLoaded && (<div className="form-field-row">
                <div
                  className={`form-field-clgec ffclgec-check ${this.state.streetPopup ? "redalertffcl" : ""
                    }`}
                >
                  <input
                    type="text"
                    placeholder="Straatnaam"
                    value={this.state.street}
                    onChange={(e) => this.setState({ street: e.target.value })}
                    readOnly={!this.state.avoidReadOnly}
                  />
                  {!this.state.avoidReadOnly && (<BiCheck />)}
                </div>
                <div
                  className={`form-field-clgec ffclgec-check ${this.state.cityPopup ? "redalertffcl" : ""
                    }`}
                >
                  <input
                    type="text"
                    placeholder="Plaats"
                    value={this.state.city}
                    onChange={(e) => this.setState({ city: e.target.value })}
                    readOnly={!this.state.avoidReadOnly}
                  />
                  {!this.state.avoidReadOnly && (<BiCheck />)}
                </div>
              </div>)}
              {this.state.isUserCreated && (<div className="inner-ipclgec">
                <p>Contact</p>
                <p style={{ flexGrow: 1, paddingLeft: 15 }}>
                  {this.state.email}
                </p>
              </div>)}
              {this.state.isAddressLoaded && (<div className="form-field-clgec formselectclgec">
                <select
                  value={this.state.country}
                  onChange={(e) => {
                    this.setState({ country: e.target.value });
                  }}
                >
                  {countries.map((e) => {
                    return <option value={e.id}>{e.name}</option>;
                  })}
                </select>
                <KeyboardArrowDownIcon />
              </div>)}
              <div
                className={`form-field-clgec ${this.state.phoneNumberPopup ? "redalertffcl" : ""
                  }`}
              >
                <input
                  type="text"
                  placeholder="Telefoon"
                  value={this.state.phoneNumber}
                  onKeyDown={(e) => {
                    if (invalidChars.includes(e.key.toLowerCase())) {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) => {
                    this.setState({ phoneNumber: e.target.value });
                  }}
                />
              </div>
              {!this.state.isUserForm && (
                <div
                  className={`form-field-clgec ${this.state.company_websitePopup ? "redalertffcl" : ""
                    }`}
                >
                  <input
                    placeholder="Company Website"
                    value={this.state.company_website}
                    onChange={(e) =>
                      this.setState({ company_website: e.target.value })
                    }
                  />
                </div>
              )}


              {window.innerWidth < 1008 && (<div className="steps-container-main">
                <div className="scm-inner-steps">

                </div>
                <div className="scm-inner-steps active">

                </div>
                <div className="scm-inner-steps">

                </div>
                <label>2/3</label>
              </div>)}

              <div className="bottom-buttons-clgec">
                {window.innerWidth < 1008 && !this.state.aanmeldenloader && (
                  <button onClick={() => history.goBack()}>
                    <ChevronLeftIcon />
                  </button>
                )}
                {this.state.aanmeldenloader ? (
                  window.innerWidth < 1008 && (
                    <button style={{ width: "100%" }}>
                      <div className="lds-ellipsis">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </button>
                  )
                ) : (
                  <button onClick={this.volgendeStap.bind(this)}>
                    Volgende stap
                    {/* {this.state.productsCheckingOut ? (this.state.shipmentFeeAvailable ? `Afrekenen` : `Plaats bestelling`) : (`Volgende stap`)} */}
                    {window.innerWidth < 1008 && <ChevronRightIcon />}
                  </button>
                )}
                {this.state.aanmeldenloader && window.innerWidth > 1008 && (
                  <div className="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                )}
                {window.innerWidth > 1008 && (<div className="steps-container-main">
                  <div className="scm-inner-steps">

                  </div>
                  <div className="scm-inner-steps active">

                  </div>
                  <div className="scm-inner-steps">

                  </div>
                  <label>2/3</label>
                </div>)}
              </div>

            </div>
          </div>
        </div>
        {this.state.popupopenforright && (
          <div className="right-gec">
            {this.state.guestCartTime && (<TimerGec />)}
            {this.state.currentDataOfUser && <CloseIcon style={{ position: "absolute", top: 10, right: 10, color: "white" }} onClick={() => {
              if (this.state.currentDataOfUser.type === "company") {
                history.push(`/company/${this.state.currentDataOfUser.user_name_id}`)
              } else {
                history.push(`/member/${this.state.currentDataOfUser.user_name_id}`)
              }
            }} />}
            {this.state.guestUserCart.length > 0 && this.state.guestCartTime &&
              this.state.guestUserCart.map((u, i) => {
                if (u.campaignType === "local_event" && !u.hideFromListComplete) {
                  return (
                    <div className="event-info-rgec">
                      {u.imagesObjected.length > 0 && (
                        <div
                          className="image-eirgec"
                          style={{
                            backgroundImage: `url("${(window.innerWidth < 1008 && u.image_mobile) ? u.image_mobile : u.imagesObjected[0].image}")`,
                          }}
                        >
                          <div className="count-ieirgec">
                            <label>1</label>
                          </div>
                        </div>
                      )}
                      {u.isNotActive ? (
                        <div className="content-eirgec">
                          <p className="title-vcvmg">
                            Sorry, you missed the event :(
                          </p>
                        </div>
                      ) : (
                        <div className="content-eirgec">
                          <p className="title-vcvmg">{u.title}</p>
                          {u.amount ? (<p
                            className="normal-price-vcvmg normalparaeventsglobal"
                            style={{ marginTop: 0, textTransform: "uppercase" }}
                          >
                            <LocationOnIcon /> {this.getSellerName(u)} |
                            KORTING:{" "}
                            {u.localEventType === "percentage"
                              ? `${u.amount}%`
                              : `€${u.amount}`}
                          </p>) : (<p
                            className="normal-price-vcvmg normalparaeventsglobal"
                            style={{ marginTop: 0, textTransform: "uppercase" }}
                          >
                            <LocationOnIcon /> {this.getSellerName(u)}
                          </p>)}
                          {u.timings.length > 0 && (
                            <p
                              className="normal-price-vcvmg normalparaeventsglobal"
                              style={{ marginTop: 0 }}
                            >
                              <RiTimerLine />{" "}
                              {this.showDateToUserTiming(
                                u.timings[u.reserved_event_id ? Number(u.reserved_event_id) - 1 : 0].startDateEvent,
                                u.timings[u.reserved_event_id ? Number(u.reserved_event_id) - 1 : 0].endDateEvent
                              )}{" "}
                              {(u.timings[u.reserved_event_id ? Number(u.reserved_event_id) - 1 : 0].startTimeEvent && u.timings[u.reserved_event_id ? Number(u.reserved_event_id) - 1 : 0].endTimeEvent) && `${u.timings[u.reserved_event_id ? Number(u.reserved_event_id) - 1 : 0].startTimeEvent}-
                              ${u.timings[u.reserved_event_id ? Number(u.reserved_event_id) - 1 : 0].endTimeEvent} uur`}
                            </p>
                          )}
                        </div>
                      )}
                    </div>
                  );
                } else if (u.campaignType === "kortings_voucher" && !u.hideFromListComplete) {
                  return (
                    <>
                      {(u.timerDateWaiting && u.timerTimeWaiting && new Date(Date.parse(u.isoTimeTimerBtn)).getTime() > new Date().getTime()) && (<p className="voucher-start-time-label">Voucher will be moved to your Favorieten</p>)}
                      <div className="event-info-rgec">
                        {u.imagesObjected.length > 0 && (
                          <div
                            className="image-eirgec"
                            style={{
                              backgroundImage: `url("${(window.innerWidth < 1008 && u.image_mobile) ? u.image_mobile : u.imagesObjected[0].image}")`,
                            }}
                          >
                            <div className="count-ieirgec">
                              <label>1</label>
                            </div>
                          </div>
                        )}
                        {u.isNotActive ? (
                          <div className="content-eirgec">
                            <p className="title-vcvmg">
                              Sorry, you missed the event :(
                            </p>
                          </div>
                        ) : (
                          <div className="content-eirgec">
                            <p className="title-vcvmg">{u.title}</p>
                            <p
                              className="normal-price-vcvmg normalparaeventsglobal"
                              style={{ marginTop: 0, textTransform: "uppercase" }}
                            >
                              <LocationOnIcon /> {this.getSellerName(u)} |
                              KORTING:{" "}
                              {u.kortingsVoucherGiveaway ? `Free` : u.kortingsVoucherPrijs ? `€${u.amount}` : u.kortingsVoucherPercentage ? `${u.amount}%` : !u.isoTimeTimerBtnEndTime ? `€${u.kortingsVoucherSalePrice}` : (!u.kortingsVoucherPrijs && (new Date(u.isoTimeTimerBtnEndTime).getTime() > new Date().getTime())) ? `€${u.kortingsVoucherSalePrice}` : `€${u.kortingsVoucherNormalPrice}`}
                            </p>
                          </div>
                        )}
                      </div>
                    </>
                  );
                }
                if (u.item_type === "bhodi_product") {
                  let indexOfImage = 0
                  if (u.images && u.images.length > 0) {
                    if (u.featuredImage && u.featuredImage < u.images.length) {
                      indexOfImage = u.featuredImage
                    }

                    let findIndex = u.images.findIndex((t) => {
                      return t.includes("_1")
                    })
                    if (findIndex !== -1) {
                      indexOfImage = findIndex
                    }
                  }
                  return (
                    <div className="event-info-rgec">
                      {u.images && u.images.length > 0 && (
                        <div
                          className="image-eirgec"
                          style={{
                            backgroundImage: `url("${u.images[indexOfImage]}")`,
                          }}
                        >
                          <div className="count-ieirgec">
                            <label>1</label>
                          </div>
                        </div>
                      )}
                      <div className="content-eirgec">
                        <p className="title-vcvmg">{u.title}</p>
                        <p
                          className="normal-price-vcvmg normalparaeventsglobal"
                          style={{
                            marginTop: 0,
                            textTransform: "uppercase",
                          }}
                        >
                          <LocationOnIcon /> {this.getSellerName(u)} |
                          <s>€ {u.price}</s>&nbsp;FREE ITEM
                        </p>
                      </div>
                    </div>
                  );
                }
              })}
          </div>
        )}
        {window.innerWidth < 1009 && (
          <div className="showtoopen-gec">
            {/* <h2>BHODI</h2> */}
            <div className="popup-opener-stogec">
              <RiShoppingBasketLine className="basket-postog" />
              <label
                onClick={() =>
                  this.setState({
                    popupopenforright: !this.state.popupopenforright,
                  })
                }
              >
                Bekijk je items{" "}
                {this.state.popupopenforright ? (
                  <FiChevronUp />
                ) : (
                  <FiChevronDown />
                )}{" "}
              </label>
            </div>
          </div>
        )}
        {/* <FooterBasket notOnMainPage={true} /> */}
        <FooterBasketTimer notOnMainPage={true} />
        {window.innerWidth > 1008 && <Footer notOnMainPage={true} />}
        {window.innerWidth < 1008 && <FooterGreen notOnMainPage={true} />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    subscription_plans: state.globalReducers.subscription_plans,
    user: state.authReducers.user,
    guest_user_cart: state.cartReducer.guest_user_cart,
    user: state.authReducers.user,
    global_campaigns: state.campaignsReducer.global_campaigns,
    signup_fields_info: state.globalReducers.signup_fields_info,
    admin_boolean: state.globalReducers.admin_boolean,
    // products_new: state.campaignsReducer.products_new,
    guest_user_cart_time: state.cartReducer.guest_user_cart_time,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateSubscriptionPlans: (subscription_plans) =>
      dispatch(updateSubscriptionPlans(subscription_plans)),
    updateGuestUserCart: (guest_user_cart) =>
      dispatch(updateGuestUserCart(guest_user_cart)),
    updateGuestUserCartTime: (guest_user_cart_time) =>
      dispatch(updateGuestUserCartTime(guest_user_cart_time)),
    updateSignUpFieldsFilled: (signup_fields_info) =>
      dispatch(updateSignUpFieldsFilled(signup_fields_info)),
    updateAdminOrNot: (admin_boolean) =>
      dispatch(updateAdminOrNot(admin_boolean)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GuestEventCart);
