import React, { Component } from "react";
import "./index.css";
import Footer from "./Footer";
import {
    updateGlobalVouchers,
    updateAllSectors,
} from "../Redux/actions/authActions";
import { connect } from "react-redux";
import VouchersNewGridDesktop from "./VouchersNewGridDesktop";

const initialState = {
    beschikbaar: true,
    popuprightToShow: false,
    titleProduct: "",
    normalPriceProduct: "",
    charityPriceProduct: "",
    descProduct: "",
    currentImageIndex: 0,
    arrImages: [],
    addCategory: false,
    gebruikers: false,
    addTimer: false,
    activateTimer: false,
    obj: {
        "category 1": {
            sub_category: "sub category 1",
        },
        "category 2": {
            sub_category: "sub category 2",
        },
    },
    isMain: "category 1",
    selectedMain: "category 1",
    selectedSub: "sub category 1",
    isSub: "sub category 1",
    daysIndex: 0,
    hoursIndex: -1,
    minutesIndex: -1,
    defaultQty: 1,
    allProducts: [],
    dropFile: [],
    filesName: [],
    productsIndex: 0,
    reachedProductsIndex: 0,
    hour: "",
    minutes: "",
    productActivated: true,
    firstTime: true,
    beforeUnloaded: false,
    currentUserProduct: true,
    newerProducts: false,
    seenProductsCheck: false,
    newProducts: [],
    seenProducts: [],
    currentProductsShowing: [],
    showAllProducts: false,
    arrCurrentProducts: [],
    cartData: [],
    selectCategoryPopup: false,
    uploadImagePopup: false,
    productDetailOpen: false,
    dayBigger: false,
    showingBookMarkProducts: false,
    allMonths: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    hours: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23,
    ],
    productStatus: ["exclusive", "normal"],
    productStatusIndex: 0,
    sectors: [],
    currentSector: "",
    currentSectorId: "",
    categoryName: "",
    currentSector: "",
    all_categories: [],
    sub_categories: [],
    completeCategories: [],
    subCategoryPresent: true,
    currentUserHere: "",
    fullpageApi: "",
    scrollStart: true,
    scrollStop: false,
    eventDate: "",
    startHour: "",
    endHour: "",
    startMinute: "",
    endMinute: "",
    showTimerFieldsEmptyWork: false,
    counterMinute: 1,
    counterSecond: 59,
    currentActie: "",
    dateToday: "",
    endDateToday: "",
    muchDaysEvent: false,
    payOnline: false,
    isDesktop: false,
    currentProductDetailed: "",
    allProductsFiltered: [],
    noFilteredProducts: false,
    searchText: "",
    availableMainCategories: [],
    availableSectors: [],
    availableSubCategories: [],
    categoriesDropdown: [],
    subCategoriesCheck: [],
    subCategoriesDropdown: [],
    checkedToFilter: [],
    allProductsFilteredDup: [],
    selectSectorsPopup: false,
    categoriesToFilter: [],
    latitude: "",
    longitude: "",
    toShowSections: [],
    firstSectionToShow: [],
    onCurrentPage: 0,
    allCampaigns: [],
    currentVoucherWork: "",
    activeSection: 0,
    hideFullpage: false,
    showOptionsToAdd: false,
    showDescriptionPopup: false,
    codegebruikpopup: false,
    codebewaarpopup: false,
    selectedDish: 0,
    alreadyBewared: [],
    mustShowAnimation: false,
    useOfVoucherOpen: false,
    exceptionsVoucherOpen: false,
    allVouchers: [],
    completeArrProducts: [],
    filteredProductsToShow: [],
    showSearchLine: false,
    urlParams: null,
};

export class VouchersGrid extends Component {
    constructor() {
        super();
        this.state = {
            ...initialState,
        };
    }

    getVoucherInfo() {
        this.setState({ ...initialState });

        window.addEventListener("resize", () => {
            if (window.innerWidth < 1009) {
                this.setState({ isDesktop: false });
            } else {
                this.setState({ isDesktop: true });
            }
        });
        if (window.innerWidth < 1009) {
            this.setState({ isDesktop: false });
        } else {
            this.setState({ isDesktop: true });
        }

        if (this.props.global_values) {
            let a = this.props.global_values;
            if (a) {
                if (a.globalOverlay) {
                    this.setState({ globalOverlay: a.globalOverlay });
                }
            }
        }

        if (window.innerWidth < 1009) {
            this.setState({ isDesktop: false });
        } else {
            document.getElementById("root").style.position = "fixed";
            document.getElementById("root").style.overflow = "hidden";
            this.setState({ isDesktop: true });
        }

        let uid = localStorage.getItem("userid");
        this.setState({ currentUserId: uid });
        if (!uid) {
            this.setState({ isGuest: true });
        } else {
            this.setState({ isGuest: false });
        }


        let localCurrent = localStorage.getItem("onCurrentSection");

        if (this.props.all_products_grid) {
            this.setState({ allCampaigns: this.props.all_products_grid });
        }
        if (localCurrent) {
            setTimeout(() => {
                localStorage.removeItem("onCurrentSection");
                if (this.state.fullpageApi) {
                    this.state.fullpageApi.moveTo(Number(localCurrent), 0)
                }
                // this.setState({ activeSection: Number(localCurrent) });
            }, 1000);
        }

        let promise = new Promise((resolve, reject) => {
            let latitude = localStorage.getItem("latitudeAfterLogin");
            let longitude = localStorage.getItem("longitudeAfterLogin");
            if (latitude) {
                this.setState({ latitude, longitude });
            }
            if (uid) {
                if (this.props.user) {
                    if (this.props.user.length > 0) {
                        let filtered = this.props.user.filter((y) => {
                            return y.user_id === uid;
                        });
                        if (filtered.length > 0) {
                            this.setState({ currentUserHere: filtered[0] });
                            setTimeout(() => {
                                resolve();
                            }, 200);
                        }
                    }
                }
            }
        });


        promise.then(() => {
            let arrCurrentProducts = this.state.allCampaigns
            if (arrCurrentProducts.length > 0) {

                let filtered = [...arrCurrentProducts]

                let allFiltered = [...arrCurrentProducts]

                if (allFiltered.length > 0) {
                    this.setState({
                        allProducts: filtered,
                        completeArrProducts: allFiltered,
                        filteredProductsToShow: allFiltered,
                        noProducts: false,
                    });
                }
            }
        });
    }

    componentDidMount() {
        this.getVoucherInfo();
    }

    getCharityObjectedQty(e) {
        let arrItems = e;
        if (arrItems.length > 0) {
            Array.prototype.sum = function (prop) {
                var total = 0;
                for (var i = 0, _len = this.length; i < _len; i++) {
                    total += Number(this[i][1][prop]);
                }
                return total;
            };
            return arrItems.sum("used_time");
        }
    }

    getVerzilveredQty(e) {
        let arrItems = e;
        if (arrItems.length > 0) {
            Array.prototype.sum = function (prop) {
                var total = 0;
                for (var i = 0, _len = this.length; i < _len; i++) {
                    total += Number(this[i][1][prop].length);
                }
                return total;
            };
            return arrItems.sum("allDates");
        }
    }

    onLeave(origin, destination, direction) {
        if (Number(origin.index) > 0) {
            this.setState({ countsArray: [] });
        }
    }

    afterLoad(origin, destination, direction) {
        this.setState({ countsArray: [], onCurrentPage: destination.index });
    }

    getTouches(evt) {
        return (
            evt.touches || // browser API
            evt.originalEvent.touches
        ); // jQuery
    }

    componentDidUpdate(prevProps) {
        let uid = localStorage.getItem("userid");

        // if (this.props.global_campaigns !== prevProps.global_campaigns) {
        //     let allArrs = this.props.global_campaigns;
        //     let allKeysCurrent = [];
        //     let allKeysComingCampaigns = [];
        //     this.state.filteredProductsToShow.map((u) => {
        //         allKeysCurrent.push(u.pushKey);
        //     });
        //     allArrs.map((y) => {
        //         y[1].type = "campaign";
        //         y[1].currentImageIndex = 0;
        //         allKeysComingCampaigns.push(y[1].pushKey);
        //         if (y[1].desc) {
        //             // y[1].desc = y[1].desc.substr(0, 200)
        //             y[1].short_desc = y[1].desc.substr(0, 32);
        //         }
        //         y[1].images = [y[1].image];
        //         y[1].imagesObjected = [{ image: y[1].image, pushKey: y[1].pushKey }];
        //         if (y[1].campaignType === "kortings_voucher") {
        //             this.state.allProducts.map((t, i) => {
        //                 if (t.pushKey === y[1].pushKey) {
        //                     if (!y[1].active) {
        //                         y[1].showPopupNotLeft = true;
        //                     } else {
        //                         if (!y[1].kortingsVoucherUnlimited) {
        //                             let bewaaredArray = [];
        //                             let num = 0;
        //                             if (y[1].verzilvered_by) {
        //                                 let objectedVerzilvered = Object.entries(
        //                                     y[1].verzilvered_by
        //                                 );
        //                                 num = this.getVerzilveredQty(objectedVerzilvered);
        //                             }
        //                             if (num + bewaaredArray.length >= Number(y[1].number)) {
        //                                 y[1].showPopupNotLeft = true;
        //                             } else {
        //                                 y[1].showPopupNotLeft = false;
        //                             }
        //                         } else {
        //                             y[1].showPopupNotLeft = false;
        //                         }
        //                     }
        //                     this.state.allProducts[i] = y[1];
        //                 }
        //             });
        //             this.state.filteredProductsToShow.map((t, i) => {
        //                 if (t.pushKey === y[1].pushKey) {
        //                     if (!y[1].active) {
        //                         y[1].showPopupNotLeft = true;
        //                     } else {
        //                         if (!y[1].kortingsVoucherUnlimited) {
        //                             let bewaaredArray = [];
        //                             let num = 0;
        //                             if (y[1].verzilvered_by) {
        //                                 let objectedVerzilvered = Object.entries(
        //                                     y[1].verzilvered_by
        //                                 );
        //                                 num = this.getVerzilveredQty(objectedVerzilvered);
        //                             }
        //                             if (num + bewaaredArray.length >= Number(y[1].number)) {
        //                                 y[1].showPopupNotLeft = true;
        //                             } else {
        //                                 y[1].showPopupNotLeft = false;
        //                             }
        //                         } else {
        //                             y[1].showPopupNotLeft = false;
        //                         }
        //                     }
        //                     this.state.filteredProductsToShow[i] = y[1];
        //                 }
        //             });
        //         }
        //         if (y[1].campaignType === "local_event") {
        //             this.state.allProducts.map((t, i) => {
        //                 if (t.pushKey === y[1].pushKey) {
        //                     if (!y[1].active) {
        //                         y[1].showPopupNotLeft = true;
        //                     } else {
        //                         y[1].showPopupNotLeft = false;
        //                     }
        //                     this.state.allProducts[i] = y[1];
        //                 }
        //             });
        //             this.state.filteredProductsToShow.map((t, i) => {
        //                 if (t.pushKey === y[1].pushKey) {
        //                     if (!y[1].active) {
        //                         y[1].showPopupNotLeft = true;
        //                     } else {
        //                         y[1].showPopupNotLeft = false;
        //                     }
        //                     this.state.filteredProductsToShow[i] = y[1];
        //                 }
        //             });
        //         }
        //     });
        //     this.setState({
        //         allProducts: this.state.allProducts,
        //     });
        // }
        if (this.props.user !== prevProps.user) {
            if (this.props.user.length > 0) {
                let filtered = this.props.user.filter((y) => {
                    return y.user_id === uid;
                });
                if (filtered.length > 0) {
                    this.setState({ currentUserHere: filtered[0] });
                }
            }
        }
    }

    getSellerName(e) {
        let filtered = this.props.user.filter((g) => {
            return g.user_id === e.created_by;
        });
        if (filtered.length > 0) {
            return filtered[0].company_name;
        }
    }

    getSellerImage(e) {
        let filtered = this.props.user.filter((g) => {
            return g.user_id === e.created_by;
        });
        if (filtered.length > 0) {
            if (filtered[0].profile_image) {
                return filtered[0].profile_image;
            } else {
                return "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9";
            }
        }
    }

    getSellerCity(e) {
        let filtered = this.props.user.filter((g) => {
            return g.user_id === e.created_by;
        });
        if (filtered.length > 0) {
            return filtered[0].city;
        }
    }

    getDayName(num) {
        if (num === 0) {
            return `Zondag`;
        } else if (num === 1) {
            return `Maandag`;
        } else if (num === 2) {
            return `Dinsdag`;
        } else if (num === 3) {
            return `Woensdag`;
        } else if (num === 4) {
            return `Donderdag`;
        } else if (num === 5) {
            return `Vrijdag`;
        } else if (num === 6) {
            return `Zaterdag`;
        }
    }

    getMonthName(i) {
        i = Number(i);
        if (i === 1) {
            return "Januari";
        } else if (i === 2) {
            return "Februari";
        } else if (i === 3) {
            return "Maart";
        } else if (i === 4) {
            return "April";
        } else if (i === 5) {
            return "Mei";
        } else if (i === 6) {
            return "Juni";
        } else if (i === 7) {
            return "Juli";
        } else if (i === 8) {
            return "Augustus";
        } else if (i === 9) {
            return "September";
        } else if (i === 10) {
            return "Oktober";
        } else if (i === 11) {
            return "November";
        } else if (i === 12) {
            return "December";
        }
    }

    showDateToUserTiming(e, f) {
        if (e === f) {
            let splitted = e.split("-");
            let newDate = new Date(e).getDay();
            return `${this.getDayName(newDate)} ${splitted[2]} ${this.getMonthName(
                splitted[1]
            )}`;
        } else {
            let splitted = e.split("-");
            let splittedSecond = f.split("-");
            let newDate = new Date(e).getDay();
            let newDateAnother = new Date(f).getDay();
            if (splitted[1] === splittedSecond[1]) {
                return `${splitted[2]} - ${splittedSecond[2]} ${this.getMonthName(
                    splittedSecond[1]
                )}`;
                // return `${this.getDayName(newDate)} ${splitted[2]} - ${splittedSecond[2]} ${this.getMonthName(splittedSecond[1])}`
            } else {
                // return `${this.getDayName(newDate)} ${splitted[2]} ${this.getMonthName(splitted[1])} - ${this.getDayName(newDateAnother)} ${splittedSecond[2]} ${this.getMonthName(splittedSecond[1])}`
                return `${splitted[2]} ${this.getMonthName(splitted[1])} t/m ${splittedSecond[2]
                    } ${this.getMonthName(splittedSecond[1])}`;
                // return `${this.getDayName(newDate)} ${splitted[2]} ${this.getMonthName(splitted[1])} t/m ${splittedSecond[2]} ${this.getMonthName(splittedSecond[1])}`
            }
        }
    }

    render() {
        return (
            <div>
                <VouchersNewGridDesktop
                    completeArrProducts={this.state.completeArrProducts}
                    filteredProductsToShow={this.state.filteredProductsToShow}
                    allProducts={this.state.allProducts}
                    noProducts={this.state.noProducts}
                />
                {!this.state.popuprightToShow && !this.state.productDetailOpen && (
                    <Footer getData={this.state.deletingItem} notOnMainPage={true} />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducers.user,
        album: state.postsReducers.album,
        images: state.postsReducers.images,
        product: state.campaignsReducer.product,
        globalvouchers: state.campaignsReducer.globalvouchers,
        globalsectors: state.categoriesReducer.globalsectors,
        global_campaigns: state.campaignsReducer.global_campaigns,
        global_values: state.globalReducers.global_values,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateGlobalVouchers: (vouchers) =>
            dispatch(updateGlobalVouchers(vouchers)),
        updateAllSectors: (globalsectors) =>
            dispatch(updateAllSectors(globalsectors)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VouchersGrid);
