import React, { Component } from 'react'
import "./index.css"
import Footer from '../../Components/Footer'
import FooterGreen from '../../Components/FooterGreen'
import { connect } from 'react-redux'
import EventsNewSection from '../../Components/EventsNewSection'
import AddIcon from "@material-ui/icons/Add";
import AddEditLocalEvent from '../../Containers/MemberHome/AddEditLocalEvent'
import firebase from '../../Config/Firebase'
import { updateCampaignsCategory } from '../../Redux/actions/authActions'
import AdminNavbar from '../AdminNavbar'
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import CloseIcon from "@material-ui/icons/Close";
import { ChevronLeft } from "@material-ui/icons";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import PublishIcon from "@material-ui/icons/Publish";
import DeleteIcon from "@material-ui/icons/Delete";

export class AdminLocalEvent extends Component {

    constructor() {
        super()
        this.state = {
            currentUserId: "",
            currentCampaignToShow: "",
            isDesktop: false,
            allSectors: [],
            mainSector: "",
            bannerHeight: window.innerHeight,
            allImagesCurrentSectorStock: [],
            company_about: "",
            contactImage: "",
            contactImageMobile: "",
            showBottomIcons: false,
            latestEventOrVoucher: "",

            currentWebShopCampaign: "",
            atIndexWebShop: 0,

            gebruikEventDown: false,
            useOfVoucherLocalEvent: "",

            requirement_file: "",
            termsText: "",
            file_name: "",

            savtBtnText: "SAVE",
        }
        this.sectionRef = React.createRef();
        this.sectionRefVoucher = React.createRef()
        this.sectionRefWebShop = []
        this.sectionOntdekRedirect = React.createRef();
    }


    scrollToSection = () => {
        // Find the target section by its ref and scroll to it
        if (this.sectionRef.current) {
            this.sectionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    scrollToSectionVoucher = () => {
        // Find the target section by its ref and scroll to it
        if (this.sectionRefVoucher.current) {
            this.sectionRefVoucher.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    scrollToSectionWebShop = (index) => {
        // Find the target section by its ref and scroll to it
        if (this.sectionRefWebShop[index].current) {
            this.sectionRefWebShop[index].current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    componentDidMount() {
        // firebase.database().ref("assigned_members/-Mlp3xzj2mn9KY8ldril").on("value", (data) => {
        //     let a = data.val()
        //     a.map((t) => {
        //         firebase.database().ref(`users/${t.user_id}/maxVideosToMake`).remove()
        //     })
        // })
        let userid = localStorage.getItem("userid")
        // if (!userid) {
        //     window.addEventListener("storage", this.handleStorageChange)
        // }

        let redirectEvents = localStorage.getItem("redirectEvents")

        if (redirectEvents) {
            setTimeout(() => {
                this.scrollToSection()
            }, 1000)
            localStorage.removeItem("redirectEvents")
        }

        firebase
            .database()
            .ref("bhodi_campaigns_category")
            .on("value", (data) => {
                let b = data.val();
                if (b) {
                    this.props.updateCampaignsCategory(b)
                }
            });

        let scrollToTop = localStorage.getItem("scrollToTop")
        if (scrollToTop) {
            localStorage.removeItem("scrollToTop")
            setTimeout(() => {
                window.scrollTo(0, 0)
            }, 500);
        }

        if (document.querySelector(".nav-fullwidth")) {
            document.querySelector(".nav-fullwidth").classList.add("navbarfixedpositionupdatedmember")
        }

        const loaderId = document.getElementById("main-page-loader-id");
        loaderId.style.display = "none";

        document.getElementById("root").style.position = "static";
        document.getElementById("root").style.overflow = "auto";

        if (document.querySelector(".navbarfullwidthMobile")) {
            document.querySelector(".navbarfullwidthMobile").style.overflowX = "hidden"
        }
        this.setState({ currentUserId: userid, bannerHeight: userid ? window.innerHeight - 130.86 : window.innerHeight })

        if (this.props.global_campaigns && this.props.global_campaigns.length > 0) {
            let arrCompleteEvents = []

            let toDoFilter = this.props.global_campaigns.filter((g) => {
                return g[1].created_by === process.env.REACT_APP_ADMIN_ID
            })
            toDoFilter.map((t) => {
                let g = t[1]
                if (g.campaignType === "local_event") {
                    let arrTimingsAvailable = [];
                    if (g.timings) {
                        g.timings.map((e) => {
                            if (e.isoStartTime && e.isoEndTime) {
                                let checkedInOfCurrentBlock = [];
                                if (g.checked_in_by) {
                                    Object.entries(g.checked_in_by).map((t) => {
                                        if (t[1].event_id === e.eventId) {
                                            checkedInOfCurrentBlock.push(t[1]);
                                        }
                                    });
                                }
                                let getCurrentDate = new Date();
                                if (getCurrentDate.getTime() < new Date(Date.parse(e.isoEndTime)).getTime()) {
                                    if (checkedInOfCurrentBlock.length < Number(e.personsAmountEvent)) {
                                        arrTimingsAvailable.push(e);
                                    }
                                }
                            }
                        });
                    }
                    if (g.created_by === userid) {
                        arrCompleteEvents.push(g);
                    } else if (arrTimingsAvailable.length > 0) {
                        if (g.active) {
                            arrCompleteEvents.push(g);
                        }
                    }
                }
            })

            if (arrCompleteEvents.length > 0) {
                let sortedCampaigns = arrCompleteEvents.sort((a, b) => {
                    return b.timestamp * 100 - a.timestamp * 100;
                });
                if (sortedCampaigns.length > 0) {
                    this.setState({ currentCampaignToShow: sortedCampaigns[0] })
                }
            }
        }

        if (this.props.global_values) {
            this.setState({
                useOfVoucherLocalEvent: this.props.global_values.voucherUseLocalEvent || "",
                requirement_file: this.props.global_values.requirement_file || "",
                termsText: this.props.global_values.termsText || "",
                file_name: this.props.file_name || "",
            })
        }

        if (window.innerWidth > 1008) {
            this.setState({ isDesktop: true })
        } else {
            this.setState({ isDesktop: false })
        }
    }

    componentWillUnmount() {
        if (document.querySelector(".navbarfullwidthMobile")) {
            document.querySelector(".navbarfullwidthMobile").style.overflowX = "auto"
        }

        if (document.querySelector(".nav-fullwidth")) {
            document.querySelector(".nav-fullwidth").classList.add("navbarfixedpositionupdatedmember")
        }

        // window.removeEventListener("storage", this.handleStorageChange)
    }

    componentDidUpdate(prevProps) {

        if (this.props.global_campaigns !== prevProps.global_campaigns && this.props.global_campaigns.length > 0) {
            let arrCompleteEvents = []
            let arrCompleteVouchers = []
            let arrCompleteWebShop = []
            // let arrCompleteVouchersEvents = []
            let userid = localStorage.getItem("userid")
            let toDoFilter = this.props.global_campaigns.filter((g) => {
                return g[1].created_by === process.env.REACT_APP_ADMIN_ID
            })
            toDoFilter.map((t) => {
                let g = t[1]
                if (g.campaignType === "local_event") {
                    let arrTimingsAvailable = [];
                    if (g.timings) {
                        g.timings.map((e) => {
                            if (e.isoStartTime && e.isoEndTime) {
                                let checkedInOfCurrentBlock = [];
                                if (g.checked_in_by) {
                                    Object.entries(g.checked_in_by).map((t) => {
                                        if (t[1].event_id === e.eventId) {
                                            checkedInOfCurrentBlock.push(t[1]);
                                        }
                                    });
                                }
                                let getCurrentDate = new Date();
                                if (getCurrentDate.getTime() < new Date(Date.parse(g.timings[0].isoEndTime)).getTime()) {
                                    if (checkedInOfCurrentBlock.length < Number(e.personsAmountEvent)) {
                                        arrTimingsAvailable.push(e);
                                    }
                                }
                            }
                        });
                    }
                    if (g.created_by === userid) {
                        arrCompleteEvents.push(g);
                    } else if (arrTimingsAvailable.length > 0) {
                        if (g.active) {
                            arrCompleteEvents.push(g);
                            // arrCompleteVouchersEvents.push(g)
                        }
                    }
                }
            })

            if (arrCompleteEvents.length > 0) {
                let sortedCampaigns = arrCompleteEvents.sort((a, b) => {
                    return b.timestamp * 100 - a.timestamp * 100;
                });
                if (sortedCampaigns.length > 0) {
                    this.setState({ currentCampaignToShow: sortedCampaigns[0] })
                } else {
                    this.setState({ currentCampaignToShow: "" })
                }
            } else {
                this.setState({ currentCampaignToShow: "" })
            }
        }

    }


    saveVoucherSettings() {
        this.setState({ savtBtnText: "SAVING.." });
        const {
            useOfVoucherLocalEvent,
            termsText,
        } = this.state;

        if (useOfVoucherLocalEvent) {
            firebase
                .database()
                .ref("bhodi_home/voucherUseLocalEvent")
                .set(useOfVoucherLocalEvent);
        } else {
            firebase
                .database()
                .ref("bhodi_home/voucherUseLocalEvent")
                .set("");
        }
        if (termsText) {
            firebase
                .database()
                .ref("bhodi_home/termsText")
                .set(termsText);
        } else {
            firebase
                .database()
                .ref("bhodi_home/termsText")
                .remove();
        }
        setTimeout(() => {
            this.setState({ savtBtnText: "SAVE", settingsPopupEvent: false });
        }, 3000);
    }

    fileUploadpdf(e) {
        let files = e.target.files[0];
        let storageRef = firebase
            .storage()
            .ref()
            .child(`requirementfiles/${files.name}${new Date().getTime()}`);
        storageRef.put(files).then((snapshot) => {
            snapshot.ref.getDownloadURL().then((snapUrl) => {
                firebase
                    .database()
                    .ref("bhodi_home/requirement_file")
                    .set(snapUrl)
                    .then(() => {
                        firebase
                            .database()
                            .ref("bhodi_home/file_name")
                            .set(files.name)
                            .then(() => {
                                this.setState({ termsText: "" });
                            });
                    });
            });
        });
    }

    deletePdf() {
        firebase
            .database()
            .ref("bhodi_home/requirement_file")
            .remove()
            .then(() => {
                firebase
                    .database()
                    .ref("bhodi_home/file_name")
                    .remove();
            });
    }


    render() {
        return (
            <div className='member-home-new admin-local-event-page'>
                <AdminNavbar atLocalEvent={true} />
                {!this.state.currentCampaignToShow && (<div className="live-event-topicons">
                    {(<div
                        className="btn-cdd btnpopupwork"
                        style={{
                            cursor: "pointer",
                            right: window.innerWidth < 1600 ? 120 : 215,
                            marginRight: 0,
                        }}
                        onClick={() => {
                            document.querySelector("html").style.overflowY = "hidden"
                            this.setState({
                                openAddPopup: true,
                            });
                        }}
                    >
                        <AddIcon />
                        <div
                            className="notification-bpw"
                            style={{ minWidth: 130, right: -49, left: "auto" }}
                        >
                            <p>Actie toevoegen</p>
                        </div>
                    </div>)}
                </div>)}

                <div className="live-event-topicons">
                    {(<div
                        className="btn-cdd btnpopupwork"
                        style={{
                            cursor: "pointer",
                            right: window.innerWidth < 1600 ? 120 : 215,
                            marginRight: 0,
                            padding: "8px 12px",
                        }}
                        onClick={() => {
                            this.setState({
                                settingsPopupEvent: true,
                            });
                        }}
                    >
                        <p style={{ margin: 0, fontFamily: "Poppins", color: "white" }}>Settings</p>
                    </div>)}
                </div>

                <div ref={this.sectionOntdekRedirect}>
                    {(this.state.currentCampaignToShow) && (
                        <div ref={this.sectionRef}>
                            {(this.state.currentCampaignToShow) && (<div className='top-events-section' style={{ paddingTop: 40 }}>
                                <p>Event</p>
                                <p>Bhodi</p>
                            </div>)}
                            <EventsNewSection
                                campaignToShow={[this.state.currentCampaignToShow]}
                                onEditOpen={() => {
                                    document.querySelector("html").style.overflowY = "hidden"
                                    this.setState({
                                        openAddPopup: true,
                                        doEditLocalEvent: true,
                                    });
                                }}
                            />
                        </div>
                    )}
                </div>

                {this.state.openAddPopup && <AddEditLocalEvent openAddPopup={this.state.openAddPopup} doEditLocalEvent={this.state.doEditLocalEvent} doEditVoucher={this.state.doEditVoucher} doEditWebShop={this.state.doEditWebShop} currentWebShopCampaign={this.state.currentWebShopCampaign} isAdmin={true} showAddressOpened={() => {
                    document.getElementById("root").style.overflow = "auto"
                    document.querySelector("html").style.overflowY = "auto"
                    this.props.showAddressOpened();
                }} closePopup={(type) => {
                    document.getElementById("root").style.overflow = "auto"
                    document.querySelector("html").style.overflowY = "auto"
                    this.setState({ openAddPopup: false, doEditLocalEvent: false, doEditVoucher: false, doEditWebShop: false })

                    if (type === "redirect-voucher") {
                        this.scrollToSectionVoucher()
                    } if (type === "redirect-webshop") {
                        this.scrollToSectionWebShop(this.state.atIndexWebShop || 0)
                        this.setState({ atIndexWebShop: 0 })
                    } else if (type !== "closewithoutredirect") {
                        this.scrollToSection()
                    }
                }} />}

                {window.innerWidth > 1008 && (<Footer notOnMainPage={true} />)}
                {window.innerWidth < 1008 && (<FooterGreen notOnMainPage={true} />)}

                {this.state.settingsPopupEvent && (<div className={`loaderareaduplicate mcaloader loaderAreaUpdatedNavbar loaderAreaUpdateMustGray`}>
                    <div className="modal-create-album modalcreatealbumchangedheader changedheadermodalcreatealbum changedheadermodallabelnomargin" style={{ width: 400 }}>
                        <div
                            className="heading-mca"
                            style={{
                                textAlign: "center",
                                position: "relative",
                                padding: "13px",
                            }}
                        >
                            <font>VOUCHER INSTELLINGEN</font>
                            <div>
                                <ChevronLeft
                                    onClick={() => {
                                        this.setState({
                                            settingsPopupEvent: false,
                                        });
                                    }}
                                    style={{
                                        position: "absolute",
                                        top: "50%",
                                        transform: "translateY(-50%)",
                                        left: 10,
                                        fontSize: 18,
                                    }}
                                />
                                <div className="spacing-between-icons"></div>
                                <CloseIcon
                                    onClick={() => {
                                        this.setState({
                                            settingsPopupEvent: false,
                                        });
                                    }}
                                    style={{
                                        position: "absolute",
                                        top: "50%",
                                        transform: "translateY(-50%)",
                                        right: 10,
                                        fontSize: 18,
                                    }}
                                />
                            </div>
                        </div>
                        <div className="body-mca groupsettingsbody mcasettingsupdatednavbar">
                            <div className="form-body-mca formbodyvouchersettings">
                                <div className="inner-mca-settings">
                                    <div
                                        className="mca-settings-list"
                                        style={{
                                            flexDirection: "column",
                                            backgroundColor: "transparent",
                                            width: "90%",
                                            margin: "10px auto",
                                        }}
                                    >
                                        <div
                                            className="wrapperforfullwidth vouchersettingswrapperform"
                                            style={{
                                                alignItems: "flex-start",
                                                position: "relative",
                                            }}
                                        >
                                            <div
                                                className="right-mcls"
                                                style={{ color: "white", margin: 0 }}
                                            ></div>
                                            <div
                                                className="right-mcls"
                                                style={{ color: "white", margin: 0 }}
                                            ></div>
                                            <div
                                                className="right-mcls"
                                                style={{ color: "white", margin: 0 }}
                                            >
                                                <div className="form-profile">
                                                    <div
                                                        style={{
                                                            width: "100%",
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <label style={{ marginBottom: 0 }}>
                                                            Gebruik events
                                                        </label>
                                                        {this.state.gebruikEventDown ? (
                                                            <KeyboardArrowUpIcon
                                                                style={{
                                                                    color: "black",
                                                                    cursor: "pointer",
                                                                    margin: 0,
                                                                }}
                                                                onClick={() =>
                                                                    this.setState({
                                                                        gebruikEventDown: false,
                                                                    })
                                                                }
                                                            />
                                                        ) : (
                                                            <KeyboardArrowDownIcon
                                                                style={{
                                                                    color: "black",
                                                                    cursor: "pointer",
                                                                    margin: 0,
                                                                }}
                                                                onClick={() =>
                                                                    this.setState({
                                                                        gebruikEventDown: true,
                                                                    })
                                                                }
                                                            />
                                                        )}
                                                    </div>
                                                    {this.state.gebruikEventDown && (
                                                        <textarea
                                                            style={{ marginTop: 20 }}
                                                            value={this.state.useOfVoucherLocalEvent}
                                                            onChange={(e) =>
                                                                this.setState({
                                                                    useOfVoucherLocalEvent:
                                                                        e.target.value,
                                                                })
                                                            }
                                                        ></textarea>
                                                    )}
                                                </div>
                                            </div>
                                            <div
                                                className="right-mcls"
                                                style={{ color: "white", margin: 0 }}
                                            >
                                                <div
                                                    className="form-profile"
                                                    style={{ position: "relative" }}
                                                >
                                                    <div
                                                        style={{
                                                            width: "100%",
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                        }}
                                                    >
                                                        <label>Voorwaarden</label>
                                                        {!this.state.requirement_file && (
                                                            <label style={{ position: "relative", width: "auto" }}>
                                                                <PublishIcon style={{ margin: 0 }} />
                                                                <input
                                                                    type="file"
                                                                    style={{
                                                                        position: "absolute",
                                                                        width: "100%",
                                                                        height: "100%",
                                                                        top: 0,
                                                                        bottom: 0,
                                                                        left: 0,
                                                                        right: 0,
                                                                        opacity: 0,
                                                                    }}
                                                                    accept=".pdf,.doc"
                                                                    onChange={(e) =>
                                                                        this.fileUploadpdf(e)
                                                                    }
                                                                />
                                                            </label>
                                                        )}
                                                    </div>
                                                    {this.state.requirement_file && (
                                                        <DeleteIcon
                                                            style={{
                                                                margin: 0,
                                                                color: "black",
                                                                position: "absolute",
                                                                top: 2,
                                                                right: 4,
                                                                fontSize: 20,
                                                            }}
                                                            onClick={this.deletePdf.bind(this)}
                                                        />
                                                    )}
                                                    {!this.state.requirement_file ? (
                                                        <textarea
                                                            value={this.state.termsText}
                                                            onChange={(e) =>
                                                                this.setState({
                                                                    termsText: e.target.value,
                                                                })
                                                            }
                                                            style={{ marginTop: 20 }}
                                                        ></textarea>
                                                    ) : (
                                                        <input
                                                            value={
                                                                this.state.file_name
                                                            }
                                                            style={{ marginTop: 20 }}
                                                            disabled
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                            <div className="btn-save-rmcls btnsaveupdateddesktop" style={{ position: "absolute", paddingTop: 25, marginTop: 30, paddingBottom: 20 }}>
                                                {this.state.savtBtnText === "SAVE" ? <button onClick={() => {
                                                    if (this.state.savtBtnText === "SAVE") {
                                                        this.saveVoucherSettings()
                                                    }
                                                }}>OPSLAAN</button> : <button><i class="fa fa-spinner fa-spin"></i> OPSLAAN</button>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>)}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        global_campaigns: state.campaignsReducer.global_campaigns,
        global_values: state.globalReducers.global_values,
        user: state.authReducers.user,
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        updateCampaignsCategory: (campaigns_category) => dispatch(updateCampaignsCategory(campaigns_category)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AdminLocalEvent);