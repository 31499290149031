import React, { Component } from 'react'
import 'react-sortable-tree/style.css';
import firebase from '../Config/Firebase'
import { connect } from 'react-redux'
import { updateGlobalStuff, updateBhodiBrands } from '../Redux/actions/authActions'
import AddIcon from "@material-ui/icons/Add";
import AdminNavbar from './AdminNavbar';
import PopupSpecial from './PopupEditor/PopupSpecial';
import { AiOutlineEdit } from 'react-icons/ai';
import Switch from "@material-ui/core/Switch";

export class EditorSpecialPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            treeData: [],
            sectorModal: false,
            sectorModalCategory: false,
            sectorName: "",
            special_products_text_dutch: "",
            special_products_text_dutch_mobile: "",
            special_products_text_english: "",
            special_products_text_english_mobile: "",
            showStuffNow: false,
            onMobileDesigns: false,

            allPages: [],
            pageKey: "",
            pageData: "",
            pageUrlToShow: "",
        };
    }

    componentDidMount() {

        setTimeout(() => {
            this.setState({ showStuffNow: true })
        }, 100);

        if (this.props.bhodi_brands) {
            this.setState({ allPages: this.props.bhodi_brands })
        }

        firebase.database().ref("bhodi_brands").on("value", (data) => {
            let a = data.val()
            let arrayPages = []
            if (a) {
                let objected = Object.entries(a)
                objected.map((t) => {
                    arrayPages.push(t[1])
                })
                this.setState({ allPages: arrayPages })
            }
        })

        if (this.props.global_values) {
            let a = this.props.global_values
            if (a) {
                this.setState({
                    pageUrlToShow: a.page_url_to_show || "",
                })
            }
        }

        firebase.database().ref("bhodi_home").on("value", (data) => {
            let a = data.val()
            if (a) {
                this.props.updateGlobalStuff(a)
                this.setState({
                    pageUrlToShow: a.page_url_to_show || "",
                })
            }
        })
    }

    saveValueToDb(storeAt, e) {
        if (this.state.onMobileDesigns) {
            firebase.database().ref(`bhodi_home/${storeAt}`).set(e)
        } else {
            firebase.database().ref(`bhodi_home/${storeAt}`).set(e)
        }
    }

    fileUploadToDB(e, storeAt) {
        let files = e.target.files[0]
        let storageRef = firebase.storage().ref().child(`bhodi_home_media/${files.name}${new Date().getTime()}`)
        storageRef.put(files)
            .then((snapshot) => {
                snapshot.ref.getDownloadURL().then((snapUrl) => {
                    firebase.database().ref(`bhodi_home/${storeAt}/`).set(snapUrl).then(() => {
                    })
                })
            })
    }

    render() {
        return (
            <div className="sorting-mainadmin">
                <AdminNavbar specialProductsPage={true} />
                {/* <div className='buttons-container-displayeditor'>
                    <button className='buttonsgreendisplay' onClick={() => {
                        this.setState({ addEditPagePopup: true, popupEditorType: "add-page" })
                    }}>Add Page</button>
                </div> */}
                {this.state.addEditPagePopup && (<PopupSpecial type={this.state.popupEditorType} pageKey={this.state.pageKey} pageData={this.state.pageData} onClose={() => {
                    this.setState({ addEditPagePopup: false, popupEditorType: "" })
                }} />)}
                <div className="container-sma" style={{ maxWidth: 920 }}>
                    <div className="top-container-sma">
                        <div className="sector-tcsma">
                        </div>
                        <div className="category-tcsma">
                            <p
                                onClick={() => {
                                    this.setState({ addEditPagePopup: true, popupEditorType: "add-page" })
                                }}
                            >
                                <AddIcon /> PAGE{" "}
                            </p>
                        </div>
                    </div>
                    <div className="tabs-container-sma">
                        <div className="category-tcsma">
                            <p className={"active"}>
                                PAGES
                            </p>
                        </div>
                    </div>
                    {this.state.allPages.length > 0 && (<div className="plm-wrapper">
                        <table>
                            <tbody>
                                <tr>
                                    <td>Page URL</td>
                                    <td>Action</td>
                                </tr>
                                {this.state.allPages.map((data) => {
                                    return (<tr>
                                        <td>
                                            <span>{data.page_url}</span>
                                        </td>
                                        <td>
                                            <AiOutlineEdit style={{ marginRight: 10 }} onClick={() => {
                                                this.setState({ addEditPagePopup: true, popupEditorType: "edit-page", pageKey: data.pushKey, pageData: data })
                                            }} />
                                            <Switch
                                                color="primary"
                                                inputProps={{ "aria-label": "primary checkbox" }}
                                                checked={this.state.pageUrlToShow === data.page_url}
                                                onClick={(e) => {
                                                    if (this.state.pageUrlToShow === data.page_url) {
                                                        firebase.database().ref(`bhodi_home/page_url_to_show`).remove()
                                                    } else {
                                                        firebase.database().ref(`bhodi_home/page_url_to_show`).set(data.page_url)
                                                    }
                                                }}
                                            />
                                        </td>
                                    </tr>)
                                })}
                            </tbody>
                        </table>
                    </div>)}
                </div>
                {/* <div className='buttons-container-displayeditor'>
                    {this.state.onMobileDesigns ? <button className='buttonsgreendisplay' onClick={() => {
                        this.setState({ onMobileDesigns: false })
                    }}>Switch To Desktop</button> : <button className='buttonsgreendisplay' onClick={() => {
                        this.setState({ onMobileDesigns: true })
                    }}>Switch To Mobile</button>}
                </div>
                {this.state.showStuffNow && this.state.onMobileDesigns ? (<div className="container-sma" style={{ maxWidth: 1200, paddingBottom: 50 }}>
                    <div className="tree-inner" style={{ marginTop: 30 }}>
                        <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
                            <div className='inner-celandingpage headinglabelcelp'>
                                <label>Main Text</label>
                            </div>
                        </div>
                        <div className='container-editors-lp'>
                            <div className='inner-celandingpage'>
                                <label>Dutch</label>
                                <CK data={this.state.special_products_text_dutch_mobile} onChildChange={this.saveValueToDb.bind(this, "special_products_text_dutch_mobile")} />
                            </div>
                            <div className='inner-celandingpage'>
                                <label>English</label>
                                <CK data={this.state.special_products_text_english_mobile} onChildChange={this.saveValueToDb.bind(this, "special_products_text_english_mobile")} />
                            </div>
                        </div>
                    </div>
                </div>) : this.state.showStuffNow && (<div className="container-sma" style={{ maxWidth: 1200, paddingBottom: 50 }}>
                    <div className="tree-inner" style={{ marginTop: 30 }}>
                        <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
                            <div className='inner-celandingpage headinglabelcelp'>
                                <label>Main Text</label>
                            </div>
                        </div>
                        <div className='container-editors-lp'>
                            <div className='inner-celandingpage'>
                                <label>Dutch</label>
                                <CK data={this.state.special_products_text_dutch} onChildChange={this.saveValueToDb.bind(this, "special_products_text_dutch")} />
                            </div>
                            <div className='inner-celandingpage'>
                                <label>English</label>
                                <CK data={this.state.special_products_text_english} onChildChange={this.saveValueToDb.bind(this, "special_products_text_english")} />
                            </div>
                        </div>

                        <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
                            <div className='inner-celandingpage headinglabelcelp'>
                                <label>Logo Img</label>
                            </div>
                        </div>
                        <div className='container-editors-lp' style={{ justifyContent: "center" }}>
                            <input type="file" style={{ padding: 15, border: "1px solid gray" }} accept="image/*" onChange={(e) => this.fileUploadToDB(e, "speical_products_page_top_logo")} />
                        </div>
                        <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
                            <div className='inner-celandingpage headinglabelcelp'>
                                <label>Logo Img Mobile</label>
                            </div>
                        </div>
                        <div className='container-editors-lp' style={{ justifyContent: "center" }}>
                            <input type="file" style={{ padding: 15, border: "1px solid gray" }} accept="image/*" onChange={(e) => this.fileUploadToDB(e, "speical_products_page_top_logo_mobile")} />
                        </div>

                        <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
                            <div className='inner-celandingpage headinglabelcelp'>
                                <label>Play Announcement Logo</label>
                            </div>
                        </div>
                        <div className='container-editors-lp' style={{ justifyContent: "center" }}>
                            <input type="file" style={{ padding: 15, border: "1px solid gray" }} accept="image/*" onChange={(e) => this.fileUploadToDB(e, "speical_products_page_play_icon")} />
                        </div>
                        <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
                            <div className='inner-celandingpage headinglabelcelp'>
                                <label>Play Announcement Logo Mobile</label>
                            </div>
                        </div>
                        <div className='container-editors-lp' style={{ justifyContent: "center" }}>
                            <input type="file" style={{ padding: 15, border: "1px solid gray" }} accept="image/*" onChange={(e) => this.fileUploadToDB(e, "speical_products_page_play_icon_mobile")} />
                        </div>

                        <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
                            <div className='inner-celandingpage headinglabelcelp'>
                                <label>Play Announcement Pic</label>
                            </div>
                        </div>
                        <div className='container-editors-lp' style={{ justifyContent: "center" }}>
                            <input type="file" style={{ padding: 15, border: "1px solid gray" }} accept="image/*" onChange={(e) => this.fileUploadToDB(e, "speical_products_page_play_picture")} />
                        </div>
                        <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
                            <div className='inner-celandingpage headinglabelcelp'>
                                <label>Play Announcement Pic Mobile</label>
                            </div>
                        </div>
                        <div className='container-editors-lp' style={{ justifyContent: "center" }}>
                            <input type="file" style={{ padding: 15, border: "1px solid gray" }} accept="image/*" onChange={(e) => this.fileUploadToDB(e, "speical_products_page_play_picture_mobile")} />
                        </div>


                        <div className='container-editors-lp' style={{ justifyContent: "center", marginTop: 30 }}>
                            <div className='inner-celandingpage headinglabelcelp'>
                                <label>Banner Image</label>
                            </div>
                        </div>
                        <div className='container-editors-lp' style={{ justifyContent: "center" }}>
                            <input type="file" style={{ padding: 15, border: "1px solid gray" }} accept="image/*, video/*" onChange={(e) => this.fileUploadToDB(e, "speical_products_page_banner_image")} />
                        </div>

                    </div>
                </div>)} */}
            </div >
        )
    }
}


const mapStateToProps = (state) => {
    return {
        global_values: state.globalReducers.global_values,
        bhodi_brands: state.globalReducers.bhodi_brands,
        user: state.authReducers.user,
        admin_boolean: state.globalReducers.admin_boolean,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateGlobalStuff: (global_values) => dispatch(updateGlobalStuff(global_values)),
        updateBhodiBrands: (bhodi_brands) => dispatch(updateBhodiBrands(bhodi_brands)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditorSpecialPage)
