import React, { Component } from "react";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import HomeIcon from "@material-ui/icons/Home";
import firebase from "../Config/Firebase";
import PersonIcon from "@material-ui/icons/Person";
import FooterBasket from "./FooterBasket";
import { connect } from "react-redux";
import UpdatedLoginDesign from "./UpdatedLoginDesign";
import {
  updateAllCampaigns,
} from "../Redux/actions/authActions";
import topcompanylandingpage from "../assets/toplandingpagecompany.png"
import headerBhodiIcon from "../assets/bhodi-header-mobile.png";
import { MdClose } from 'react-icons/md';
import NewStoryPopup from "../Containers/MemberHome/NewStoryPopup";
import { history } from "../Config/Routes";
import { mergeAdminEvent } from "../Functions/globalFunctions";

class GuestUserNavbar extends Component {
  constructor() {
    super();
    this.state = {
      isDesktop: true,
      listsOpacity: 1,
      listsPointers: "all",
      currentCampaigns: [],
      currentEvents: [],
      arrLiveEvent: [],
      allImages: [],
      // availableImages: [],
      availableAlbums: [],
      showLoginNewUpdated: false,
      currentUserProducts: [],
      impressiePhotos: [],
    };
  }

  componentDidMount() {
    const loaderId = document.getElementById("main-page-loader-id");
    loaderId.style.display = "none";
    // ReactDOM.render(element, document.getElementById('root'))

    if (this.props.all_impressies) {
      if (this.props.all_impressies.length > 0) {
        let filtered = this.props.all_impressies.filter((h) => {
          return h[0] === this.props.currentUser.user_id
        })
        if (filtered.length > 0) {
          let objected = Object.entries(filtered[0][1])
          let arr = []
          objected.map((g) => {
            arr.push(g[1])
          })
          this.setState({ impressiePhotos: arr })
        }
      }
    } else {
      firebase
        .database()
        .ref("impressiePhotos/" + this.props.currentUser.user_id)
        .once("value", (data) => {
          let a = data.val();
          if (a) {
            let objected = Object.entries(a);
            let arr = [];
            objected.map((g) => {
              arr.push(g[1]);
            });
            this.setState({ impressiePhotos: arr });
          } else {
            this.setState({ impressiePhotos: [] });
          }
        });
    }

    if (window.innerWidth > 1008) {
      this.setState({ isDesktop: true });
    } else {
      this.setState({ isDesktop: false });
    }

    window.addEventListener("resize", () => {
      if (window.innerWidth > 1008) {
        this.setState({ isDesktop: true });
      } else {
        this.setState({ isDesktop: false });
      }
    });

    if (this.props.global_campaigns) {
      if (this.props.global_campaigns.length > 0) {
        let arr = [];
        let arrEvent = [];
        let arrLiveEvent = [];
        let filteredCampaigns = this.props.global_campaigns.filter((g) => {
          return g[1].created_by === this.props.currentUser.user_id;
        });
        if (filteredCampaigns.length > 0) {
          filteredCampaigns.map((g) => {
            g[1].currentImageIndex = 0;

            if (g[1].campaignType === "local_event") {
              if (g[1].takeFromAdmin) {
                let obj = mergeAdminEvent(g[1], this.props.global_campaigns)
                g[1] = Object.assign({}, obj)
              }
              if (g[1].active) {
                // arrLiveEvent.push(g[1])


                let arrTimingsAvailable = [];
                if (g[1].timings) {
                  g[1].timings.map((e) => {
                    if (e.isoStartTime && e.isoEndTime) {
                      let checkedInOfCurrentBlock = [];
                      if (g[1].checked_in_by) {
                        Object.entries(g[1].checked_in_by).map((t) => {
                          if (t[1].reserved_event_id === e.eventId) {
                            checkedInOfCurrentBlock.push(t[1]);
                          }
                        });
                      }

                      let getCurrentDate = new Date();
                      if (getCurrentDate.getTime() < new Date(Date.parse(e.isoStartTime)).getTime()) {
                        if (
                          checkedInOfCurrentBlock.length <
                          Number(e.personsAmountEvent)
                        ) {
                          arrTimingsAvailable.push(e);
                        }
                      }
                    }
                  });
                }
                if (arrTimingsAvailable.length > 0) {
                  arrLiveEvent.push(g[1]);
                }
              }
            }
            if (g[1].active) {
              if (g[1].desc) {
                g[1].desc = g[1].desc.substr(0, 520);
                g[1].short_desc = g[1].desc.substr(0, 120);
              }
              if (g[1].campaignType !== "charity") {
                g[1].images = [g[1].image];
                g[1].imagesObjected = [
                  { image: g[1].image, pushKey: g[1].pushKey },
                ];
              }
            }
          });
          this.setState({
            currentCampaigns: arr,
            currentEvents: arrEvent,
            arrLiveEvent,
          });
        }
      }
    } else {
      firebase
        .database()
        .ref("bhodi_campaigns")
        .on("value", (data) => {
          let a = data.val();
          let arr = [];
          if (a && !this.state.stopTakingData) {
            let objected = Object.entries(a);
            let arrSecCheck = [];
            objected.map((i) => {
              arrSecCheck.push(i[1]);
            });
            arrSecCheck.map((y) => {
              let objected = Object.entries(y);
              objected.map((i) => {
                if (!i[1].created_by) {
                  return false;
                }
                arr.push(i);
              });
            });

            this.props.updateAllCampaigns(arr);
          } else {
            this.props.updateAllCampaigns([]);
          }
        });

    }
  }

  getVerzilveredQty(e) {
    let arrItems = e;
    if (arrItems.length > 0) {
      Array.prototype.sum = function (prop) {
        var total = 0;
        for (var i = 0, _len = this.length; i < _len; i++) {
          total += Number(this[i][1][prop].length);
        }
        return total;
      };
      return arrItems.sum("allDates");
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.products_new !== this.props.products_new) {
      let filteredMainProduct = this.props.products_new.filter((e) => {
        return e.active && e.created_by === this.props.currentUser.user_id;
      });
      if (filteredMainProduct.length > 0) {
        this.setState({ allProducts: filteredMainProduct });
      }
    }
    if (prevProps.all_impressies !== this.props.all_impressies) {
      if (this.props.all_impressies.length > 0) {
        this.setState({ impressiePhotos: [] })
        let filtered = this.props.all_impressies.filter((h) => {
          return h[0] === this.props.currentUser.user_id
        })
        if (filtered.length > 0) {
          let objected = Object.entries(filtered[0][1])
          let arr = []
          objected.map((g) => {
            arr.push(g[1])
          })
          this.setState({ impressiePhotos: arr })
        } else {
          this.setState({ impressiePhotos: [] });
        }
      } else {
        this.setState({ impressiePhotos: [] })
      }
    }
  }

  render() {
    return this.state.isDesktop ? (
      <div
        className="nav-fullwidth"
        style={{ backgroundColor: this.props.mustBeGray && "#F8F8F8" }}
      >
        <div className="container-nfw containernfw-guestnavbar">
          <div
            className="left-nfw"
            style={{
              opacity: this.state.listsOpacity,
              pointerEvents: this.state.listsPointers,
            }}
          >
            <ul>
              <li
                onClick={() =>
                  (window.location.href = `/company/${this.props.currentUser.user_name_id}`)
                }
              >
                <HomeIcon />
              </li>
              <li
                className="nav-item"
                onClick={() =>
                  this.setState({ storyOpen: true })
                }
              >
                Story
              </li>
              <li
                className="nav-item"
                onClick={() =>
                  this.setState({ aboutOpen: true })
                }
              >
                About
              </li>
              <li
                className="nav-item"
                onClick={() => {
                  window.open(
                    "https://media.bhodi.com/gebruikersvoorwaarden.pdf",
                    "_blank" // <- This is what makes it open in a new window.
                  );
                }}
              >
                Voorwaarden
              </li>
            </ul>
          </div>
          <div
            className="center-nfw"
            style={{ flexDirection: "column" }}
            onClick={() => {
              window.location.href = `/company/${this.props.currentUser.user_name_id}`;
            }}
          >
            <img src={topcompanylandingpage} style={{ width: 90, height: 45, objectFit: "contain" }} />
            {/* {this.props.currentUser && (
              <img
                src={
                  this.props.currentUser.profile_image
                    ? this.props.currentUser.profile_image
                    : "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9"
                }
                style={{
                  width: this.props.menuItemsShowing ? 25 : 30,
                  height: this.props.menuItemsShowing ? 25 : 30,
                  objectFit: "cover",
                  objectPosition: "center",
                  marginRight: 8,
                  borderRadius: "50%",
                }}
              />
            )}
            <label
              style={{
                fontSize: 14,
                letterSpacing: 1,
                marginTop: 5,
                marginBottom: 0,
                textTransform: this.props.menuItemsShowing && "uppercase",
              }}
              className={`${!this.props.menuItemsShowing && "doliststylefont"}`}
            >
              {this.props.currentUser.company_name}
            </label> */}
          </div>

          {!this.props.hideRightIcons && (<div className="right-cnfwgn">
            {!this.props.onStoriesPage && (<div
              className="btn-cdd"
              style={{
                cursor: "pointer",
                padding: "5px 15px",
                letterSpacing: 1,
                fontSize: 13,
                backgroundColor: "transparent",
                right: 20,
              }}
              onClick={() => {
                this.setState({ showLoginNewUpdated: true })
              }}
            >
              <PersonIcon style={{ marginRight: 5, fontSize: 26 }} />
            </div>)}
            <FooterBasket />
          </div>)}
        </div>
        {this.state.showLoginNewUpdated && <UpdatedLoginDesign closePopup={() => {
          this.setState({ showLoginNewUpdated: false })
        }} />}

        {this.state.aboutOpen && <NewStoryPopup closePopup={() => {
          this.setState({ aboutOpen: false })
        }} />}
        {/* {(<div className='about-main-popup' style={{ opacity: this.state.aboutOpen ? 1 : 0, pointerEvents: this.state.aboutOpen ? "all" : "none", zIndex: 99999999999 }}>
          <div className='abp-right-menu' style={{ right: this.state.aboutOpen ? 0 : "-100%" }}>
            <CloseIcon onClick={() => this.setState({ aboutOpen: false })} />
            <div className='aboutcont-bgimage'>
            </div>
            <div className='aboutinner-cont-pop'>
              <h2>About</h2>
              <p className='main-crm-black'>Bhodi</p>
              <p>
                Winde 18<br />
                7623 NE Borne<br />
                info@bhodi.com
                <br />
                <br />
                KVK:   70329346<br />
                Bank:  NL56RABO 0328 7127 44<br />
                Btw:   Bhodi is als social<br />
                enterprise vrijgesteld van btw
              </p>
              <p className='main-crm-black'>Bhodi commerce</p>
              <p>
                Winde 18<br />
                7623 NE Borne<br />
                info@bhodi.com
                <br />
                <br />
                KVK:   53027965<br />
                Btw:   NL850714436B01<br />
                Bank: NL39 RABO 0125 1418 66
              </p>
            </div>
          </div>
        </div>)} */}
        {this.state.storyOpen && (<div className='complete-video-lpn' style={{ zIndex: 999999999999 }}>
          <div className='inner-cont-cvlpn' style={{ backgroundColor: "black" }}>
            <MdClose className='icon-person-top-lpn' style={{ color: "white" }} onClick={() => {
              this.setState({ storyOpen: false })
            }} />
            <video
              src="https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/Bhodi-video.mp4?alt=media&token=d42c20a1-1944-4b79-b7bb-4ea317a7a69e&_gl=1*13idzsb*_ga*MTI0NjQwMTkyLjE2ODcxNDYzNTQ.*_ga_CW55HF8NVT*MTY5ODIyOTU1MC40NjYuMS4xNjk4MjMxNDQ1LjU1LjAuMA."
              autoPlay
              loop
              playsInline
              controls
            ></video>
          </div>
        </div>)}

      </div>
    ) : (
      <div
        className={`navbarfullwidthMobile positionabsolutenav navbarmobileforcompanyguest`}
      >
        {this.props.currentUser && (
          <div
            className="top-nfwpm"
            onClick={() => {
              // window.scrollTo(0, 0)
              window.location.href = `/company/${this.props.currentUser.user_name_id}`;
            }}
          >
            <img src={headerBhodiIcon} style={{ width: 100, height: 30, borderRadius: 0, objectFit: "cover", objectPosition: "center" }} />
            {/* <img
              src={
                this.props.currentUser.profile_image
                  ? this.props.currentUser.profile_image
                  : "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9"
              }
            />
            <p>{this.props.currentUser.company_name}</p> */}
          </div>
        )}
        {this.props.onLoginPage ? (
          <div
            className="btn-cdd"
            style={{
              cursor: "pointer",
              padding: "5px 15px",
              letterSpacing: 1,
              fontSize: 13,
              backgroundColor: "transparent",
              right: 90,
            }}
            onClick={() => {
              console.log("working")
            }}
          >
            <CloseIcon
              className="fontsizealwaysbiggermenu"
              style={{ marginRight: 5 }}
            />
          </div>
        ) : (!this.props.onStoriesPage && !this.props.hideRightIcons) && (
          <div
            className="btn-cdd"
            style={{
              cursor: "pointer",
              padding: "5px 15px",
              letterSpacing: 1,
              fontSize: 13,
              backgroundColor: "transparent",
              right: 90,
            }}
            onClick={() => {
              this.setState({ showLoginNewUpdated: true })
            }}
          >
            <PersonIcon
              className="fontsizealwaysbiggermenu"
              style={{ marginRight: 5, fontSize: 26 }}
            />
          </div>
        )}
        {!this.props.hideRightIcons && (<FooterBasket onlyShowCount={true} />)}
        <div
          className="btn-cdd"
          style={{
            cursor: "pointer",
            padding: "5px 15px",
            letterSpacing: 1,
            fontSize: 13,
            backgroundColor: "transparent",
            right: 0,
          }}
          onClick={() => {
            this.setState({
              popupright: true,
              popupVouchers: false,
              popupShop: false,
              popupcompanies: false,
              popupExclusives: false,
            });
            document.getElementById("root").style.position = "fixed";
          }}
        >
          <MenuIcon
            className="fontsizealwaysbiggermenu"
            onClick={() => this.setState({ menuOpen: true })}
          />
        </div>

        <div
          className={`mymenu menuchangeintopopupmobile ${this.state.menuOpen && "menumobileopen"
            }`}
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav mr-auto slideractive" id="menu">
            {!this.state.isDesktop && (
              <CloseIcon
                className="closeiconhomenav"
                onClick={() => {
                  document.getElementById("root").style.position = "static";
                  this.setState({ menuOpen: false });
                }}
              />
            )}
            <li
              className="nav-item"
              onClick={() =>
                this.setState({ storyOpen: true })
              }
            >
              <a className="nav-link">Story</a>
            </li>
            <li
              className="nav-item"
              onClick={() => {
                history.push(`/bhodi-about`)
                // history.push(`/bhodi-about?fromCompany=${this.props.currentUser.user_name_id}`)
                // this.setState({ aboutOpen: true })
              }
              }
            >
              <a className="nav-link">About</a>
            </li>
            <li
              className="nav-item"
              onClick={() => {
                window.open(
                  "https://media.bhodi.com/gebruikersvoorwaarden.pdf",
                  "_blank" // <- This is what makes it open in a new window.
                );
              }}
            >
              <a className="nav-link">Voorwaarden</a>
            </li>
          </ul>
        </div>
        {this.state.showLoginNewUpdated && <UpdatedLoginDesign closePopup={() => {
          this.setState({ showLoginNewUpdated: false })
        }} />}


        {this.state.aboutOpen && <NewStoryPopup closePopup={() => {
          this.setState({ aboutOpen: false })
        }} />}

        {/* {(<div className='about-main-popup' style={{ opacity: this.state.aboutOpen ? 1 : 0, pointerEvents: this.state.aboutOpen ? "all" : "none", zIndex: 99999999999 }}>
          <div className='abp-right-menu' style={{ right: this.state.aboutOpen ? 0 : "-100%" }}>
            <CloseIcon onClick={() => this.setState({ aboutOpen: false })} />
            <div className='aboutcont-bgimage'>
            </div>
            <div className='aboutinner-cont-pop'>
              <h2>About</h2>
              <p className='main-crm-black'>Bhodi</p>
              <p>
                Winde 18<br />
                7623 NE Borne<br />
                info@bhodi.com
                <br />
                <br />
                KVK:   70329346<br />
                Bank:  NL56RABO 0328 7127 44<br />
                Btw:   Bhodi is als social<br />
                enterprise vrijgesteld van btw
              </p>
              <p className='main-crm-black'>Bhodi commerce</p>
              <p>
                Winde 18<br />
                7623 NE Borne<br />
                info@bhodi.com
                <br />
                <br />
                KVK:   53027965<br />
                Btw:   NL850714436B01<br />
                Bank: NL39 RABO 0125 1418 66
              </p>
            </div>
          </div>
        </div>)} */}
        {this.state.storyOpen && (<div className='complete-video-lpn' style={{ zIndex: 999999999999 }}>
          <div className='inner-cont-cvlpn' style={{ backgroundColor: "black" }}>
            <MdClose className='icon-person-top-lpn' style={{ color: "white" }} onClick={() => {
              this.setState({ storyOpen: false })
            }} />
            <video
              src="https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/Bhodi-video.mp4?alt=media&token=d42c20a1-1944-4b79-b7bb-4ea317a7a69e&_gl=1*13idzsb*_ga*MTI0NjQwMTkyLjE2ODcxNDYzNTQ.*_ga_CW55HF8NVT*MTY5ODIyOTU1MC40NjYuMS4xNjk4MjMxNDQ1LjU1LjAuMA."
              autoPlay
              loop
              playsInline
              controls
            ></video>
          </div>
        </div>)}
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    bhodi_products: state.productsReducers.bhodi_products,
    all_impressies: state.globalReducers.all_impressies,
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    updateAllCampaigns: (global_campaigns) =>
      dispatch(updateAllCampaigns(global_campaigns)),
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(GuestUserNavbar);