import React, { Component } from "react";
import firebase from "../Config/Firebase";
import FriendList from "../Containers/FriendsList";
// import MemberFriendList from "../Containers/MemberFriends";
// import MemberProducts from "../Containers/MemberProducts";
import NavbarMainFullWidthCompany from "./NavSecMainFullWidthCompany";
import CompanyLandingsPage from "../Containers/Company";
import Impressie from "../Containers/Impressie";
import ImagesSection from "../Containers/ImagesSection";
import {
  updateCurrentMoments,
  updateAlbumToAdd,
  updateMoreAlbumsAdded,
  updateSelectedExclusiveFriends,
  updateSelectedExclusive,
  updateCurrentMomentsFriendsPage,
  updateAlbumToAddFriends,
  updateMoreAlbumsAddedFriends,
  updateSignUpFieldsFilled,
  updateGuestUserCart,
  updateGuestUserCartTime,
  updateGuestUserCartExtendOpportunity,
  updateUser,
  rerenderComponent,
} from "../Redux/actions/authActions";
// import { updateUser } from '../Redux/actions/authActions'
import { connect } from "react-redux";
import Charity from "../Containers/Charity";
import CreateProductMemberCompany from "../Containers/CreateProductMemberCompany";
import "./index.css";
import NavbarMainFullWidthCompanyMobile from "./NavbarMainFullWidthCompanyMobile";
import NewVisit from "../Containers/New Visit";
import ShopInnerMember from "../Containers/Shop Work/Shop Inner Member";
import LiveEvent from "../Containers/LiveEvent";
import FriendListRightMenu from "./FriendListRightMenu";
import ViewerFriendlist from "./ViewerFriendlist";
// import ProductsPage from "../Containers/ProductsPage";
import CharityConfirmationFullPage from "./CharityConfirmationFullPage";

import { history } from "../Config/Routes";
import WizardCompany from "./WizardCompany";
import VriendenPageDesktop from "./VriendenPageDesktop";
import ProductsPage from "../Containers/ProductsPage";
import EditProductScreen from "../Containers/ProductsPage/EditProductScreen";
import LandingPage from "../Containers/LandingPage";
import ExclusiveSubscribeProcess from "../Containers/ExclusiveSubscribeProcess";
import RoundIconsNavbarCompany from "./RoundIconsNavbarCompany";
import { checkForSubscribedExclusives } from "../Functions/checkForSubscribedExclusives";
import MemberHome from "../Containers/MemberHome";
import CompanyTrending from "../Containers/CompanyTrending";
import NavbarWrapperVisitor from "./NavbarWrapperVisitor";
import NavbarWrapperVisitorMobile from "./NavbarWrapperVisitorMobile";
import GenerateQRPage from "../Containers/GenerateQRPage";
import GenerateQRCodeCompany from "../Containers/CompanyPaymentLinkCampaign/GenerateQRCodeCompany";
import CompanyCampaignGuest from "../Containers/CompanyPaymentLinkCampaign/CompanyCampaignGuest";

const initialState = {};

export class WholeCompanyStuff extends Component {
  constructor() {
    super();
    this.state = {
      showComponentRender: true,
      showLists: true,
      showItems: false,
      showFriends: false,
      currentMemberData: "",
      popuprightToShow: false,
      doShowMenu: false,
      popuprightToShowVoucher: false,
      menuItemsShowing: true,
      doLessHeight: true,
      fillUpAddress: false,
      showProductsShops: false,
      openDelEdit: false,
      userid: "",
      moveToContactSection: false,
      hideNav: false,
      doShowMenuRightAbout: false,
      allAlbumsCurrentShared: [],
      openActieToevoegenPopup: false,
      openBewerkPagina: false,
      showTrendingPosts: false,
      showPublicPosts: false,
      subscriptionActivated: false,
      closeCompleteRightMenu: false,
      urlParams: null,
      updated: false,
      openClientListRight: false,
      currentMemberDataOnce: "",
      alreadySubscribedForExclusives: false,
      showExcLoader: false,
    };
    // window.addEventListener("beforeunload", (ev) => {
    //     window.location.reload()
    //     return false
    // })
    // window.addEventListener("beforeunload", (ev) => {
    //     let uid = localStorage.getItem("userid")
    //     firebase.database().ref("users/" + uid + "/newly_registered").set(false)
    // });
  }

  getCompanyInfo() {
    // this.setState({ ...initialState });

    let member_id = this.props.match.params.company_id;
    let currentUserMember = "";
    let userid = localStorage.getItem("userid");
    this.setState({ userid });

    window.addEventListener("resize", () => {
      if (window.innerWidth < 1009) {
        this.setState({ isDesktop: false, showingFullPage: false });
      } else {
        setTimeout(() => {
          this.setState({ showingFullPage: true });
        }, 1000);
        this.setState({ isDesktop: true });
      }
    });
    if (window.innerWidth < 1009) {
      this.setState({ isDesktop: false });
    } else {
      this.setState({ isDesktop: true });
    }

    if (this.props.user) {
      if (this.props.user.length > 0) {
        let filtered = this.props.user.filter((y) => {
          return y.user_name_id === member_id;
        });
        let filteredMyOwn = this.props.user.filter((y) => {
          return y.user_id === userid;
        });
        if (filteredMyOwn.length > 0) {
          let currentUserMyOwn = filteredMyOwn[0];
          if (currentUserMyOwn.show_exc_loader) {
            this.setState({ showExcLoader: true })

            setTimeout(() => {
              let urlMain = new URL(window.location.href)
              let subscribingToUser = urlMain.searchParams.get("subscribingTo");
              if (filtered[0].subscribersForExclusives) {
                let objected = Object.entries(filtered[0].subscribersForExclusives)
                if (objected.length > 0) {
                  let filtered = objected.map((t) => {
                    return t[1].subscription && t[1].pushKey === subscribingToUser
                  })
                  if (filtered.length > 0) {
                    firebase.database().ref("users/" + userid + "/show_exc_loader").remove()
                  }
                }
              }
            }, 1000);
          }
          if (currentUserMyOwn.subscription) {
            if (currentUserMyOwn.subscription.status === "active") {
              this.setState({ subscriptionActivated: true });
            } else {
              if (checkForSubscribedExclusives(currentUserMyOwn)) {
                this.setState({ subscriptionActivated: true })
              }
            }
          } else {
            if (checkForSubscribedExclusives(currentUserMyOwn)) {
              this.setState({ subscriptionActivated: true })
            }
          }
        }
        if (filtered.length > 0) {
          if (!filtered[0].profileActive && filtered[0].user_id !== userid) {
            window.location.href = "/404";
          }
          let currentUser = filtered[0];
          if (currentUser.blocked) {
            let blocked = Object.entries(currentUser.blocked);
            let filtered = blocked.filter((y) => {
              return y[1].user_id === userid;
            });
            if (filtered.length > 0) {
              window.location.href = "/404";
            }
          }
          if (currentUser.subscribersForExclusives) {
            let objected = Object.entries(currentUser.subscribersForExclusives)
            let filtered = objected.filter((y) => {
              return y[1].userId === userid && y[1].payment_status === "paid" && y[1].subscription && y[1].subscription.status === "active"
            })
            if (filtered.length > 0) {
              this.setState({ alreadySubscribedForExclusives: true })
            } else {
              this.setState({ alreadySubscribedForExclusives: false })
            }
          } else {
            this.setState({ alreadySubscribedForExclusives: false })
          }
          this.setState({ currentMemberData: currentUser, userid, currentMemberDataOnce: currentUser });
          if (currentUser.user_id === userid) {
            this.setState({ showComponentRender: true });
          } else {
            this.setState({ showComponentRender: false });
          }
        } else {
          window.location.href = "/404";
        }
      }
    }

    // routing work
    let route = window.location.pathname;
    if (route.includes("vriendenDesktop")) {
      this.setState({
        showVriendenWizard: true,
        showLists: false,
        showPosts: false,
      });
    } else if (route.includes("/generate-qr")) {
      localStorage.setItem("onCurrentTabCompany", "showgenerateqr");
      this.setState({
        showcharityConfirmation: false,
        showLists: false,
        showProductsNew: false,
        doLessHeight: false,
        showItems: false,
        showFriends: false,
        showGenerateQR: true,
        showCampaignGuestPage: false,
        showTrendingCompany: false,
        showImpression: false,
        showVoucher: false,
        showPosts: false,
        showProductsBookmarked: false,
        showCharity: false,
        showProductsShops: false,
        showProductsFriends: false,
        showLive: false,
        showGreetings: false,
        showExclusiveSubscribe: false,
      });
    } else if (route.includes("/campaign-guest")) {
      localStorage.setItem("onCurrentTabCompany", "campaignguest");
      this.setState({
        showcharityConfirmation: false,
        showLists: false,
        showProductsNew: false,
        doLessHeight: false,
        showItems: false,
        showFriends: false,
        showGenerateQR: false,
        showCampaignGuestPage: true,
        showTrendingCompany: false,
        showImpression: false,
        showVoucher: false,
        showPosts: false,
        showProductsBookmarked: false,
        showCharity: false,
        showProductsShops: false,
        showProductsFriends: false,
        showLive: false,
        showGreetings: false,
        showExclusiveSubscribe: false,
      });
    } else if (route.includes("/trending")) {
      localStorage.setItem("onCurrentTabCompany", "shownewtrending");
      this.setState({
        showcharityConfirmation: false,
        showLists: false,
        showProductsNew: false,
        doLessHeight: false,
        showItems: false,
        showFriends: false,
        showTrendingCompany: true,
        showGenerateQR: false,
        showCampaignGuestPage: false,
        showImpression: false,
        showVoucher: false,
        showPosts: false,
        showProductsBookmarked: false,
        showCharity: false,
        showProductsShops: false,
        showProductsFriends: false,
        showLive: false,
        showGreetings: false,
        showExclusiveSubscribe: false,
      });
    } else if (route.includes("/friends")) {
      localStorage.setItem("onCurrentTabCompany", "showFriends");
      this.setState({
        showcharityConfirmation: false,
        showLists: false,
        showProductsNew: false,
        doLessHeight: false,
        showItems: false,
        showFriends: true,
        showImpression: false,
        showGenerateQR: false,
        showCampaignGuestPage: false,
        showVoucher: false,
        showPosts: false,
        showProductsBookmarked: false,
        showCharity: false,
        showProductsShops: false,
        showProductsFriends: false,
        showLive: false,
        showGreetings: false,
        showExclusiveSubscribe: false,
      });
    } else if (route.includes("/charityConfirmation")) {
      localStorage.setItem("onCurrentTabCompany", "charityConfirmation");
      this.setState({
        showcharityConfirmation: true,
        showOrderSuccess: false,
        showLists: false,
        showGenerateQR: false,
        showCampaignGuestPage: false,
        showFriends: false,
        showProductsOwn: false,
        showProductsBookmarked: false,
        showGreetings: false,
        showCharity: false,
        shopProductsShop: false,
        showContent: false,
        showExclusiveSubscribe: false,
      });
    } else if (route.includes("/charity/guest")) {
      localStorage.setItem("onCurrentTabCompany", "showLists");
      this.setState({
        showcharityConfirmation: false,
        showLists: true,
        showProductsNew: false,
        showGenerateQR: false,
        showCampaignGuestPage: false,
        doLessHeight: true,
        showItems: false,
        showFriends: false,
        showImpression: false,
        showGreetings: false,
        showVoucher: false,
        showPosts: false,
        showProductsBookmarked: false,
        showCharity: false,
        showProductsShops: false,
        showProductsFriends: false,
        showExclusiveSubscribe: false,
      });
    } else if (route.includes("/charity")) {
      localStorage.setItem("onCurrentTabCompany", "showCharity");
      this.setState({
        showcharityConfirmation: false,
        showLists: false,
        showGenerateQR: false,
        showCampaignGuestPage: false,
        showProductsNew: false,
        doLessHeight: false,
        showCharity: true,
        showItems: false,
        showFriends: false,
        showImpression: false,
        showVoucher: false,
        showGreetings: false,
        showPosts: false,
        showProductsBookmarked: false,
        showProductsShops: false,
        showProductsFriends: false,
        showLive: false,
        showExclusiveSubscribe: false,
      });
    } else if (route.includes("/vrienden")) {
      localStorage.setItem("onCurrentTabCompany", "showLive");
      this.setState({
        showFriendsNewUpdated: true,
        showcharityConfirmation: false,
        showLive: false,
        showGenerateQR: false,
        showCampaignGuestPage: false,
        showProductsNew: false,
        showLists: false,
        showGreetings: false,
        doLessHeight: false,
        showItems: false,
        showFriends: false,
        showImpression: false,
        showVoucher: false,
        showPosts: false,
        showProductsBookmarked: false,
        showCharity: false,
        showProductsShops: false,
        showExclusiveSubscribe: false,
        showProductsFriends: false,
      });
    }
    else if (route.includes("/contact/guest")) {
      localStorage.setItem("onCurrentTabCompany", "showLists");
      this.setState({
        showcharityConfirmation: false,
        showLists: true,
        showProductsNew: false,
        showExclusiveSubscribe: false,
        doLessHeight: true,
        showGenerateQR: false,
        showCampaignGuestPage: false,
        showGreetings: false,
        showItems: false,
        showFriends: false,
        showImpression: false,
        showVoucher: false,
        showPosts: false,
        showProductsBookmarked: false,
        showCharity: false,
        showProductsShops: false,
        showProductsFriends: false,
      });
    } else if (route.includes("/contact")) {
      localStorage.setItem("onCurrentTabCompany", "showLists");
      this.setState({
        showcharityConfirmation: false,
        showLists: true,
        showProductsNew: false,
        doLessHeight: true,
        showItems: false,
        showFriends: false,
        showCampaignGuestPage: false,
        showGenerateQR: false,
        showImpression: false,
        showExclusiveSubscribe: false,
        showGreetings: false,
        showVoucher: false,
        showPosts: false,
        showProductsBookmarked: false,
        showCharity: false,
        showProductsShops: false,
        showProductsFriends: false,
        showLive: false,
      });
    } else if (route.includes("/volgend")) {
      localStorage.setItem("onCurrentTabCompany", "showproductsfriends");
      this.setState({
        showcharityConfirmation: false,
        showProductsFriends: true,
        showProductsNew: false,
        showGreetings: false,
        showLists: false,
        showGenerateQR: false,
        showCampaignGuestPage: false,
        showExclusiveSubscribe: false,
        doLessHeight: false,
        showItems: false,
        showFriends: false,
        showImpression: false,
        showVoucher: false,
        showPosts: false,
        showProductsBookmarked: false,
        showCharity: false,
        showProductsShops: false,
        showLive: false,
      });
    } else {
      localStorage.setItem("onCurrentTabCompany", "showLists");
      this.setState({
        showcharityConfirmation: false,
        showLists: true,
        showProductsNew: false,
        doLessHeight: true,
        showGreetings: false,
        showExclusiveSubscribe: false,
        showItems: false,
        showFriends: false,
        showImpression: false,
        showVoucher: false,
        showGenerateQR: false,
        showCampaignGuestPage: false,
        showPosts: false,
        showProductsBookmarked: false,
        showCharity: false,
        showProductsShops: false,
        showProductsFriends: false,
        showLive: false,
      });
    }

    setTimeout(() => {
      this.setState({ renderConfirmation: true })
    }, 1000);


    if (this.props.user) {
      firebase
        .database()
        .ref("users").orderByChild("user_name_id").equalTo(member_id)
        .once("value", (data) => {
          let ab = data.val();
          let currentUser = "";
          if (ab) {
            let objected = Object.entries(ab)
            currentUser = objected[0][1];
            let a = currentUser;
            if (a.user_name_id === member_id) {
              if (!a.profileActive && a.user_id !== userid) {
                window.location.href = "/404";
              }
              currentUser = a;
            }
            if (currentUser) {
              if (currentUser.subscribersForExclusives) {
                let objected = Object.entries(currentUser.subscribersForExclusives)
                let filtered = objected.filter((y) => {
                  return y[1].userId === userid && y[1].payment_status === "paid" && y[1].subscription && y[1].subscription.status === "active"
                })
                if (filtered.length > 0) {
                  this.setState({ alreadySubscribedForExclusives: true })
                } else {
                  this.setState({ alreadySubscribedForExclusives: false })
                }
              } else {
                this.setState({ alreadySubscribedForExclusives: false })
              }

              this.setState({ currentMemberData: currentUser, currentMemberDataOnce: currentUser });
            }
            this.routeStuffChecking()
          }
        });
    } else {
      firebase
        .database()
        .ref("users")
        .once("value", (data) => {
          let a = data.val();
          let currentUser = "";
          let arr = [];
          if (a) {
            let entries = Object.entries(a);
            entries.map((f) => {
              if (f[1].user_name_id === member_id) {
                if (!f[1].profileActive && f[1].user_id !== userid) {
                  window.location.href = "/404";
                }
                currentUser = f[1];
              }
            });
            entries.map((f) => {
              if (f && f.length > 0 && f[1]) {
                f[1].pass = "";
                f[1].password = "";
                arr.push(f[1]);
              }
            });
            this.props.updateUser(arr);
            if (currentUser) {

              if (currentUser.subscribersForExclusives) {
                let objected = Object.entries(currentUser.subscribersForExclusives)
                let filtered = objected.filter((y) => {
                  return y[1].userId === userid && y[1].payment_status === "paid" && y[1].subscription && y[1].subscription.status === "active"
                })
                if (filtered.length > 0) {
                  this.setState({ alreadySubscribedForExclusives: true })
                } else {
                  this.setState({ alreadySubscribedForExclusives: false })
                }
              } else {
                this.setState({ alreadySubscribedForExclusives: false })
              }

              this.setState({ currentMemberData: currentUser, currentMemberDataOnce: currentUser });
            }
            this.routeStuffChecking()
          }
        });
    }
  }

  componentDidMount() {
    this.setState({ urlParams: this.props.match.params, updated: true });

    this.getCompanyInfo();
    const loaderId = document.getElementById("main-page-loader-id");
    loaderId.style.display = "none";
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params !== this.state.urlParams) {
      this.setState({ updated: false });
      this.getCompanyInfo();
      this.setState({ urlParams: this.props.match.params });

      setTimeout(() => {
        this.setState({ updated: true });
      }, 250);
    }

    if (this.props.reRendered) {
      this.getCompanyInfo();
      this.setState({ updated: false });
      this.props.rerenderComponent(false);
      setTimeout(() => {
        this.setState({ updated: true });
      }, 250);
    }

    let member_id = this.props.match.params.company_id;
    let userid = localStorage.getItem("userid");

    if (prevProps.user !== this.props.user) {
      let filtered = this.props.user.filter((y) => {
        return y.user_name_id === member_id;
      });
      let filteredMyOwn = this.props.user.filter((y) => {
        return y.user_id === userid;
      });
      if (filteredMyOwn.length > 0) {
        let currentUserMyOwn = filteredMyOwn[0];
        if (currentUserMyOwn.show_exc_loader) {
          this.setState({ showExcLoader: true })

          setTimeout(() => {
            let urlMain = new URL(window.location.href)
            let subscribingToUser = urlMain.searchParams.get("subscribingTo");
            if (filtered[0].subscribersForExclusives) {
              let objected = Object.entries(filtered[0].subscribersForExclusives)
              if (objected.length > 0) {
                let filtered = objected.map((t) => {
                  return t[1].subscription && t[1].pushKey === subscribingToUser
                })
                if (filtered.length > 0) {
                  firebase.database().ref("users/" + userid + "/show_exc_loader").remove()
                }
              }
            }
          }, 1000);
        } else {
          this.setState({ showExcLoader: false })
        }
        if (currentUserMyOwn.subscription) {
          if (currentUserMyOwn.subscription.status === "active") {
            this.setState({ subscriptionActivated: true });
          } else {
            if (checkForSubscribedExclusives(currentUserMyOwn)) {
              this.setState({ subscriptionActivated: true })
            }
          }
        } else {
          if (checkForSubscribedExclusives(currentUserMyOwn)) {
            this.setState({ subscriptionActivated: true })
          }
        }
      }
      if (filtered.length > 0) {
        if (!filtered[0].profileActive && filtered[0].user_id !== userid) {
          window.location.href = "/404";
        }
        let currentUser = filtered[0];

        if (currentUser.subscribersForExclusives) {
          let objected = Object.entries(currentUser.subscribersForExclusives)
          let filtered = objected.filter((y) => {
            return y[1].userId === userid && y[1].payment_status === "paid" && y[1].subscription && y[1].subscription.status === "active"
          })
          if (filtered.length > 0) {
            this.setState({ alreadySubscribedForExclusives: true })
          } else {
            this.setState({ alreadySubscribedForExclusives: false })
          }
        } else {
          this.setState({ alreadySubscribedForExclusives: false })
        }

        this.setState({ currentMemberData: currentUser });
      } else {
        if (this.state.currentMemberData) {
          if (this.state.currentMemberData.user_id === userid) {
            return false;
          }
        }
        window.location.href = "/404";
      }
    }
  }

  routeStuffChecking() {
    let route = window.location.pathname;
    if (route.includes("vriendenDesktop")) {
      this.setState({
        showVriendenWizard: true,
        showLists: false,
        showPosts: false,
      });
    } else if (route.includes("/generate-qr")) {
      localStorage.setItem("onCurrentTabCompany", "showgenerateqr");
      this.setState({
        showcharityConfirmation: false,
        showLists: false,
        showProductsNew: false,
        doLessHeight: false,
        showItems: false,
        showFriends: false,
        showGenerateQR: true,
        showCampaignGuestPage: false,
        showTrendingCompany: false,
        showImpression: false,
        showVoucher: false,
        showPosts: false,
        showProductsBookmarked: false,
        showCharity: false,
        showProductsShops: false,
        showProductsFriends: false,
        showLive: false,
        showGreetings: false,
        showExclusiveSubscribe: false,
      });
    } else if (route.includes("/campaign-guest")) {
      localStorage.setItem("onCurrentTabCompany", "campaignguest");
      this.setState({
        showcharityConfirmation: false,
        showLists: false,
        showProductsNew: false,
        doLessHeight: false,
        showItems: false,
        showFriends: false,
        showGenerateQR: false,
        showCampaignGuestPage: true,
        showTrendingCompany: false,
        showImpression: false,
        showVoucher: false,
        showPosts: false,
        showProductsBookmarked: false,
        showCharity: false,
        showProductsShops: false,
        showProductsFriends: false,
        showLive: false,
        showGreetings: false,
        showExclusiveSubscribe: false,
      });
    } else if (route.includes("/friends")) {
      localStorage.setItem("onCurrentTabCompany", "showFriends");
      this.setState({
        showLists: false,
        showProductsNew: false,
        doLessHeight: false,
        showItems: false,
        showGreetings: false,
        showGenerateQR: false,
        showCampaignGuestPage: false,
        showFriends: true,
        showImpression: false,
        showVoucher: false,
        showPosts: false,
        showProductsBookmarked: false,
        showExclusiveSubscribe: false,
        showCharity: false,
        showProductsShops: false,
        showProductsFriends: false,
      });
    } else if (route.includes("/charityConfirmation")) {
      localStorage.setItem("onCurrentTabCompany", "charityConfirmation");
      this.setState({
        showcharityConfirmation: true,
        showOrderSuccess: false,
        showLists: false,
        showGreetings: false,
        showFriends: false,
        showGenerateQR: false,
        showCampaignGuestPage: false,
        showProductsOwn: false,
        showProductsBookmarked: false,
        showCharity: false,
        shopProductsShop: false,
        showContent: false,
        showExclusiveSubscribe: false,
      });
    } else if (route.includes("/charity/guest")) {
      localStorage.setItem("onCurrentTabCompany", "showLists");
      this.setState({
        showLists: true,
        showProductsNew: false,
        doLessHeight: true,
        showGenerateQR: false,
        showCampaignGuestPage: false,
        showItems: false,
        showGreetings: false,
        showFriends: false,
        showImpression: false,
        showVoucher: false,
        showPosts: false,
        showExclusiveSubscribe: false,
        showProductsBookmarked: false,
        showCharity: false,
        showProductsShops: false,
        showProductsFriends: false,
      });
    } else if (route.includes("/charity")) {
      localStorage.setItem("onCurrentTabCompany", "showCharity");
      this.setState({
        showLists: false,
        showProductsNew: false,
        doLessHeight: false,
        showCharity: true,
        showItems: false,
        showGreetings: false,
        showFriends: false,
        showExclusiveSubscribe: false,
        showImpression: false,
        showVoucher: false,
        showGenerateQR: false,
        showCampaignGuestPage: false,
        showPosts: false,
        showProductsBookmarked: false,
        showProductsShops: false,
        showProductsFriends: false,
      });
    } else if (route.includes("/vrienden")) {
      localStorage.setItem("onCurrentTabCompany", "showVrienden");
      this.setState({
        showFriendsNewUpdated: true,
        showLive: false,
        showProductsNew: false,
        showGenerateQR: false,
        showCampaignGuestPage: false,
        showLists: false,
        doLessHeight: false,
        showExclusiveSubscribe: false,
        showItems: false,
        showFriends: false,
        showGreetings: false,
        showImpression: false,
        showVoucher: false,
        showPosts: false,
        showProductsBookmarked: false,
        showCharity: false,
        showProductsShops: false,
        showProductsFriends: false,
      });
    }
    else if (route.includes("/contact/guest")) {
      localStorage.setItem("onCurrentTabCompany", "showLists");
      this.setState({
        showLists: true,
        showProductsNew: false,
        doLessHeight: true,
        showItems: false,
        showFriends: false,
        showImpression: false,
        showCampaignGuestPage: false,
        showGenerateQR: false,
        showExclusiveSubscribe: false,
        showGreetings: false,
        showVoucher: false,
        showPosts: false,
        showProductsBookmarked: false,
        showCharity: false,
        showProductsShops: false,
        showProductsFriends: false,
      });
    } else if (route.includes("/contact")) {
      localStorage.setItem("onCurrentTabCompany", "showLists");
      this.setState({
        showLists: true,
        showProductsNew: false,
        doLessHeight: true,
        showGenerateQR: false,
        showCampaignGuestPage: false,
        showGreetings: false,
        showItems: false,
        showFriends: false,
        showExclusiveSubscribe: false,
        showImpression: false,
        showVoucher: false,
        showPosts: false,
        showProductsBookmarked: false,
        showCharity: false,
        showProductsShops: false,
        showProductsFriends: false,
      });
    } else if (route.includes("/volgend")) {
      localStorage.setItem("onCurrentTabCompany", "showproductsfriends");
      this.setState({
        showProductsFriends: true,
        showProductsNew: false,
        showLists: false,
        doLessHeight: false,
        showItems: false,
        showFriends: false,
        showImpression: false,
        showExclusiveSubscribe: false,
        showGenerateQR: false,
        showCampaignGuestPage: false,
        showVoucher: false,
        showGreetings: false,
        showPosts: false,
        showProductsBookmarked: false,
        showCharity: false,
        showProductsShops: false,
      });
    } else if (route.includes("/trending")) {
      localStorage.setItem("onCurrentTabCompany", "shownewtrending");
      this.setState({
        showProductsFriends: false,
        showTrendingCompany: true,
        showProductsNew: false,
        showLists: false,
        doLessHeight: false,
        showItems: false,
        showFriends: false,
        showImpression: false,
        showExclusiveSubscribe: false,
        showGenerateQR: false,
        showCampaignGuestPage: false,
        showVoucher: false,
        showGreetings: false,
        showPosts: false,
        showProductsBookmarked: false,
        showCharity: false,
        showProductsShops: false,
      });
    } else {
      localStorage.setItem("onCurrentTabCompany", "showLists");
      this.setState({
        showLists: true,
        showProductsNew: false,
        doLessHeight: true,
        showItems: false,
        showFriends: false,
        showGreetings: false,
        showImpression: false,
        showVoucher: false,
        showGenerateQR: false,
        showCampaignGuestPage: false,
        showPosts: false,
        showProductsBookmarked: false,
        showCharity: false,
        showExclusiveSubscribe: false,
        showProductsShops: false,
        showProductsFriends: false,
      });
    }
  }

  render() {
    return this.state.currentMemberData && this.state.updated ? (
      <div
        // ${this.state.subscriptionActivated && "subscriptionActivatedOnWholePage"}
        className={` ${((this.state.showImpression) && this.state.currentMemberData.hideWizard) ? "roundiconsdoabsolutemobile" : ""} ${this.state.showProductsNew && this.state.userid && window.innerWidth > 1009 && "productsPageNavbarToDoGray"} `}
        id="mainWrapperAccount"
      >
        {this.state.showComponentRender ? (
          <div>
            {this.state.isDesktop
              ? this.state.userid && (
                <NavbarMainFullWidthCompany
                  subscriptionActivated={this.state.subscriptionActivated}
                  linkToTrending={`/company/${this.state.currentMemberData.user_name_id}/posts`}
                  openAddPopupPosts={() => {
                    document.getElementById("root").style.position = "fixed"
                    document.getElementById("root").style.overflowY = "hidden"
                    this.setState({ addPosts: true })
                  }}
                  mustBeGray={this.state.showProductsShops}
                  moveToContactSection={() => {
                    history.push(
                      `/company/${this.state.currentMemberData.user_name_id}/contact`
                    );
                    // Commented by me
                    // window.location.href = `/company/${this.state.currentMemberData.user_name_id}/contact`;
                  }}
                  showingWizard={(!this.state.currentMemberData.hideWizard && !this.state.currentMemberDataOnce.create_subscription && this.state.currentMemberData.user_id === this.state.userid) && !this.state.showGreetings}
                  menuItemsShowing={this.state.menuItemsShowing}
                  doLessHeight={this.state.doLessHeight}
                  member_id={this.props.match.params.company_id}
                  showProducts={() => {
                    localStorage.setItem("onCurrentTabCompany", "showItems");
                    this.setState({
                      showItems: false,
                      moveToContactSection: false,
                    });
                    setTimeout(() => {
                      this.setState({
                        showItems: true,
                        doLessHeight: false,
                        showLists: false,
                        showFriends: false,
                        showImpression: false,
                        showVoucher: false,
                        showPosts: false,
                        showProductsBookmarked: false,
                        showCharity: false,
                        showProductsShops: false,
                        showProductsFriends: false,
                        showContent: false,
                      });
                    }, 10);
                  }}
                  showPosts={() => {
                    localStorage.setItem("onCurrentTabCompany", "showLists");
                    history.push(
                      `/company/${this.state.currentMemberData.user_name_id}`
                    );
                    // Commented by me
                    // window.location.href = `/company/${this.state.currentMemberData.user_name_id}`;
                  }}
                  showFriends={() => {
                    if ((!this.state.currentMemberData.hideWizard && !this.state.currentMemberDataOnce.create_subscription && this.state.currentMemberData.user_id === this.state.userid)) {
                      // this.setState({ isFriendActiveWizard: true })
                      history.push(
                        `/company/${this.state.currentMemberData.user_name_id}/vriendenDesktop`
                      );
                    } else {
                      this.setState({ showRightMenuFriends: true });
                    }
                    // localStorage.setItem("onCurrentTabCompany", "showFriends")
                    // window.location.href = `/company/${this.state.currentMemberData.user_name_id}/friends`
                  }}
                  showLive={() => {
                    // this.setState({ showLive: true, showLists: false, doLessHeight: false, showItems: false, showFriends: false, showImpression: false, showVoucher: false, showPosts: false, showProductsBookmarked: false, showCharity: false, showProductsShops: false, showProductsFriends: false })
                    localStorage.setItem("onCurrentTabCompany", "showLive");
                    // Commented my me
                    history.push(
                      `/company/${this.state.currentMemberData.user_name_id}/live`
                    );
                    // window.location.href = `/company/${this.state.currentMemberData.user_name_id}/live`;
                  }}
                  showProductsNew={() => {
                    localStorage.setItem(
                      "onCurrentTabCompany",
                      "showProductsNew"
                    );
                    // Commented my me

                    history.push(
                      `/company/${this.state.currentMemberData.user_name_id}/products`
                    );

                    // window.location.href = `/company/${this.state.currentMemberData.user_name_id}/products`;
                  }}
                  showNewTrending={() => {
                    localStorage.setItem(
                      "onCurrentTabCompany",
                      "shownewtrending"
                    );
                    // Commented by me
                    history.push(
                      `/company/${this.state.currentMemberData.user_name_id}/trending`
                    );
                    // window.location.href = `/company/${this.state.currentMemberData.user_name_id}/volgend`;
                  }}
                  showMainPosts={() => {
                    localStorage.setItem("onCurrentTabCompany", "showPosts");
                    history.push(
                      `/company/${this.state.currentMemberData.user_name_id}/posts`
                    );
                    // window.location.href = `/company/${this.state.currentMemberData.user_name_id}/posts`;
                  }}
                  showProductsOfFriends={() => {
                    localStorage.setItem(
                      "onCurrentTabCompany",
                      "showproductsfriends"
                    );
                    // Commented by me
                    history.push(
                      `/company/${this.state.currentMemberData.user_name_id}/volgend`
                    );
                    // window.location.href = `/company/${this.state.currentMemberData.user_name_id}/volgend`;
                  }}
                  showProductsBookmarked={() => {
                    localStorage.setItem(
                      "onCurrentTabCompany",
                      "showProductsBookmarked"
                    );
                    this.setState({
                      showProductsBookmarked: false,
                      moveToContactSection: false,
                    });
                    setTimeout(() => {
                      this.setState({
                        showLists: false,
                        doLessHeight: false,
                        showItems: false,
                        showFriends: false,
                        showImpression: false,
                        showVoucher: false,
                        showPosts: false,
                        showProductsBookmarked: true,
                        showCharity: false,
                        showProductsShops: false,
                        showProductsFriends: false,
                        showContent: false,
                      });
                    }, 10);
                  }}
                  showCharity={() => {
                    localStorage.setItem(
                      "onCurrentTabCompany",
                      "showCharity"
                    );
                    // Commented by me
                    history.push(
                      `/company/${this.state.currentMemberData.user_name_id}/charity`
                    );
                    // window.location.href = `/company/${this.state.currentMemberData.user_name_id}/charity`;
                  }}
                  showProductsShop={() => {
                    localStorage.setItem(
                      "onCurrentTabCompany",
                      "showProductsShops"
                    );
                    this.setState({
                      showProductsShops: false,
                      showProductsFriends: false,
                      showContent: false,
                    });
                  }}
                  hideNav={this.state.hideNav}
                  openProductsWork={() => {
                    this.setState({
                      popuprightToShow: !this.state.popuprightToShow,
                      hideNav: true,
                    });
                  }}
                  openVouchersWork={() => {
                    this.setState({
                      popuprightToShowVoucher:
                        !this.state.popuprightToShowVoucher,
                      hideNav: true,
                    });
                  }}
                  hideMenuWork={false}
                  fillUpAddress={this.state.fillUpAddress}
                  removeFillUpAddress={() => {
                    this.setState({ fillUpAddress: false });
                  }}
                  doShowMenuRightAbout={this.state.doShowMenuRightAbout}
                  closeAboutBhodiRightMenu={() => {
                    this.setState({ doShowMenuRightAbout: false });
                  }}
                  showTrendingPosts={() => {
                    this.setState({ showTrendingPosts: true });
                  }}
                  showGlobalPosts={() => {
                    this.setState({ showPublicPosts: true });
                  }}
                  showStoriesPopup={() => {
                    this.setState({ showStoriesRightMenu: true });
                  }}
                  showStoriesPopupRight={() => {
                    if (
                      this.state.currentMemberData.user_id ===
                      this.state.userid
                    ) {
                      this.setState({ showStoriesRightMenuAnothers: true });
                    } else {
                      setTimeout(() => {
                        localStorage.setItem(
                          "onCurrentTabCompany",
                          "showPosts"
                        );
                        history.push(`/company/${this.state.currentMemberData.user_name_id}/posts`)
                        // window.location.href = `/company/${this.state.currentMemberData.user_name_id}/posts`;
                        // window.location.href = `/member/${this.state.userDataViewer.user_name_id}`;
                      }, 200);
                      // this.setState({ showStoriesRightMenuViewers: true });
                    }
                  }}
                  openClientListRight={this.state.openClientListRight}
                  closeopenClientListRight={() => {
                    this.setState({ openClientListRight: false });
                  }}
                />
              )
              : this.state.userid && (!this.state.currentMemberData.hideWizard || !this.state.showFriendsNewUpdated) && (
                <NavbarMainFullWidthCompanyMobile
                  mustBeGray={this.state.showProductsShops}
                  moveToContactSection={() => {
                    // Commented by me
                    history.push(
                      `/company/${this.state.currentMemberData.user_name_id}#contacttoscrollfromtop`
                    );
                    // window.location.href = `/company/${this.state.currentMemberData.user_name_id}#contacttoscrollfromtop`;
                  }}
                  openAddPopupPosts={() => {
                    document.getElementById("root").style.position = "fixed"
                    document.getElementById("root").style.overflowY = "hidden"
                    this.setState({ addPosts: true })
                  }}
                  showingWizard={(!this.state.currentMemberData.hideWizard && !this.state.currentMemberDataOnce.create_subscription && this.state.currentMemberData.user_id === this.state.userid) && !this.state.showGreetings}
                  menuItemsShowing={this.state.menuItemsShowing}
                  doLessHeight={this.state.doLessHeight}
                  member_id={this.props.match.params.company_id}
                  showProducts={() => {
                    localStorage.setItem("onCurrentTabCompany", "showItems");
                    this.setState({
                      showItems: false,
                      moveToContactSection: false,
                    });
                    setTimeout(() => {
                      this.setState({
                        showItems: true,
                        doLessHeight: false,
                        showLists: false,
                        showFriends: false,
                        showImpression: false,
                        showVoucher: false,
                        showPosts: false,
                        showProductsBookmarked: false,
                        showCharity: false,
                        showProductsShops: false,
                        showProductsFriends: false,
                        showContent: false,
                      });
                    }, 10);
                  }}
                  showPosts={() => {
                    localStorage.setItem("onCurrentTabCompany", "showLists");
                    // Commented by me
                    history.push(
                      `/company/${this.state.currentMemberData.user_name_id}`
                    );
                    // window.location.href = `/company/${this.state.currentMemberData.user_name_id}`;
                  }}
                  showFriends={() => {
                    this.setState({ showRightMenuFriends: true });
                    // localStorage.setItem("onCurrentTabCompany", "showFriends")
                    // window.location.href = `/company/${this.state.currentMemberData.user_name_id}/friends`
                  }}
                  showLive={() => {
                    localStorage.setItem("onCurrentTabCompany", "showLive");
                    // Commented by me
                    history.push(
                      `/company/${this.state.currentMemberData.user_name_id}/live`
                    );
                    // window.location.href = `/company/${this.state.currentMemberData.user_name_id}/live`;
                  }}
                  showProductsNew={() => {
                    localStorage.setItem(
                      "onCurrentTabCompany",
                      "showProductsNew"
                    );
                    history.push(
                      `/company/${this.state.currentMemberData.user_name_id}/products`
                    );
                    // window.location.href = `/company/${this.state.currentMemberData.user_name_id}/products`;
                    // window.location.href = `/company/${this.state.currentMemberData.user_name_id}/products`;
                  }}
                  showMainPosts={() => {
                    localStorage.setItem("onCurrentTabCompany", "showPosts");
                    // Commented by me
                    history.push(
                      `/company/${this.state.currentMemberData.user_name_id}/posts`
                    );
                    // window.location.href = `/company/${this.state.currentMemberData.user_name_id}/posts`;
                  }}
                  showProductsOfFriends={() => {
                    localStorage.setItem(
                      "onCurrentTabCompany",
                      "showproductsfriends"
                    );
                    // Commented by me
                    history.push(
                      `/company/${this.state.currentMemberData.user_name_id}/volgend`
                    );
                    // window.location.href = `/company/${this.state.currentMemberData.user_name_id}/volgend`;
                  }}
                  showNewTrending={() => {
                    localStorage.setItem(
                      "onCurrentTabCompany",
                      "shownewtrending"
                    );
                    // Commented by me
                    history.push(
                      `/company/${this.state.currentMemberData.user_name_id}/trending`
                    );
                    // window.location.href = `/company/${this.state.currentMemberData.user_name_id}/volgend`;
                  }}
                  showProductsBookmarked={() => {
                    localStorage.setItem(
                      "onCurrentTabCompany",
                      "showProductsBookmarked"
                    );
                    this.setState({
                      showProductsBookmarked: false,
                      moveToContactSection: false,
                    });
                    setTimeout(() => {
                      this.setState({
                        showLists: false,
                        doLessHeight: false,
                        showItems: false,
                        showFriends: false,
                        showImpression: false,
                        showVoucher: false,
                        showPosts: false,
                        showProductsBookmarked: true,
                        showCharity: false,
                        showProductsShops: false,
                        showProductsFriends: false,
                        showContent: false,
                      });
                    }, 10);
                  }}
                  showCharity={() => {
                    localStorage.setItem(
                      "onCurrentTabCompany",
                      "showCharity"
                    );
                    // Commented by me
                    history.push(
                      `/company/${this.state.currentMemberData.user_name_id}/charity`
                    );
                    // window.location.href = `/company/${this.state.currentMemberData.user_name_id}/charity`;
                  }}
                  showProductsShop={() => {
                    localStorage.setItem(
                      "onCurrentTabCompany",
                      "showProductsShops"
                    );
                    this.setState({
                      showProductsShops: false,
                      showProductsFriends: false,
                      showContent: false,
                    });
                  }}
                  showFriendsNewUpdated={() => {
                    localStorage.setItem(
                      "onCurrentTabCompany",
                      "showVrienden"
                    );
                    history.push(
                      `/company/${this.state.currentMemberData.user_name_id}/vrienden`
                    );
                    // window.location.href = `/company/${this.state.currentMemberData.user_name_id}/vrienden`;
                  }}
                  hideNav={this.state.hideNav}
                  openProductsWork={() => {
                    this.setState({
                      popuprightToShow: !this.state.popuprightToShow,
                      hideNav: true,
                    });
                  }}
                  openVouchersWork={() => {
                    this.setState({
                      popuprightToShowVoucher:
                        !this.state.popuprightToShowVoucher,
                      hideNav: true,
                    });
                  }}
                  hideMenuWork={false}
                  fillUpAddress={this.state.fillUpAddress}
                  removeFillUpAddress={() => {
                    this.setState({ fillUpAddress: false });
                  }}
                  doShowMenuRightAbout={this.state.doShowMenuRightAbout}
                  closeAboutBhodiRightMenu={() => {
                    this.setState({ doShowMenuRightAbout: false });
                  }}
                  showTrendingPosts={() => {
                    this.setState({ showTrendingPosts: true });
                  }}
                  showGlobalPosts={() => {
                    this.setState({ showPublicPosts: true });
                  }}
                  showStoriesPopup={() => {
                    this.setState({ showStoriesRightMenu: true });
                  }}
                  showStoriesPopupRight={() => {
                    if (
                      this.state.currentMemberData.user_id ===
                      this.state.userid
                    ) {
                      this.setState({ showStoriesRightMenuAnothers: true });
                    } else {
                      setTimeout(() => {
                        localStorage.setItem(
                          "onCurrentTabCompany",
                          "showPosts"
                        );
                        window.location.href = `/company/${this.state.currentMemberData.user_name_id}/posts`;
                        // window.location.href = `/member/${this.state.userDataViewer.user_name_id}`;
                      }, 200);
                      // this.setState({ showStoriesRightMenuViewers: true });
                    }
                    // this.setState({ showStoriesRightMenuAnothers: true })
                  }}
                  doActiveToevogen={() => {
                    this.setState({ openActieToevoegenPopup: true });
                    setTimeout(() => {
                      this.setState({ openActieToevoegenPopup: false });
                    }, 200);
                  }}
                  doBewerkPagina={() => {
                    this.setState({ openBewerkPagina: true });
                    setTimeout(() => {
                      this.setState({ openBewerkPagina: false });
                    }, 200);
                  }}
                  showGegevensPopup={this.state.showGegevensPopup}
                  closeShowGegevensPopup={() => {
                    this.setState({ showGegevensPopup: false });
                  }}
                  closeCompleteRightMenu={this.state.closeCompleteRightMenu}
                  revertBackCloseCompleteRightMenu={() => {
                    this.setState({ closeCompleteRightMenu: false });
                  }}
                />
              )}
            {this.state.userid && !this.state.isDesktop && (!this.state.currentMemberData.hideWizard || !this.state.showFriendsNewUpdated) && (
              <RoundIconsNavbarCompany
                mustBeGray={this.state.showProductsShops}
                moveToContactSection={() => {
                  // Commented by me
                  history.push(
                    `/company/${this.state.currentMemberData.user_name_id}#contacttoscrollfromtop`
                  );
                  // window.location.href = `/company/${this.state.currentMemberData.user_name_id}#contacttoscrollfromtop`;
                }}
                openAddPopupPosts={() => {
                  document.getElementById("root").style.position = "fixed"
                  document.getElementById("root").style.overflowY = "hidden"
                  this.setState({ addPosts: true })
                }}
                showingWizard={(!this.state.currentMemberData.hideWizard && !this.state.currentMemberDataOnce.create_subscription && this.state.currentMemberData.user_id === this.state.userid) && !this.state.showGreetings}
                menuItemsShowing={this.state.menuItemsShowing}
                doLessHeight={this.state.doLessHeight}
                member_id={this.props.match.params.company_id}
                showProducts={() => {
                  localStorage.setItem("onCurrentTabCompany", "showItems");
                  this.setState({
                    showItems: false,
                    moveToContactSection: false,
                  });
                  setTimeout(() => {
                    this.setState({
                      showItems: true,
                      doLessHeight: false,
                      showLists: false,
                      showFriends: false,
                      showImpression: false,
                      showVoucher: false,
                      showPosts: false,
                      showProductsBookmarked: false,
                      showCharity: false,
                      showProductsShops: false,
                      showProductsFriends: false,
                      showContent: false,
                    });
                  }, 10);
                }}
                showPosts={() => {
                  localStorage.setItem("onCurrentTabCompany", "showLists");
                  // Commented by me
                  history.push(
                    `/company/${this.state.currentMemberData.user_name_id}`
                  );
                  // window.location.href = `/company/${this.state.currentMemberData.user_name_id}`;
                }}
                showFriends={() => {
                  this.setState({ showRightMenuFriends: true });
                  // localStorage.setItem("onCurrentTabCompany", "showFriends")
                  // window.location.href = `/company/${this.state.currentMemberData.user_name_id}/friends`
                }}
                showLive={() => {
                  localStorage.setItem("onCurrentTabCompany", "showLive");
                  // Commented by me
                  history.push(
                    `/company/${this.state.currentMemberData.user_name_id}/live`
                  );
                  // window.location.href = `/company/${this.state.currentMemberData.user_name_id}/live`;
                }}
                showProductsNew={() => {
                  localStorage.setItem(
                    "onCurrentTabCompany",
                    "showProductsNew"
                  );
                  history.push(
                    `/company/${this.state.currentMemberData.user_name_id}/products`
                  );
                  // window.location.href = `/company/${this.state.currentMemberData.user_name_id}/products`;
                  // window.location.href = `/company/${this.state.currentMemberData.user_name_id}/products`;
                }}
                showMainPosts={() => {
                  localStorage.setItem("onCurrentTabCompany", "showPosts");
                  // Commented by me
                  history.push(
                    `/company/${this.state.currentMemberData.user_name_id}/posts`
                  );
                  // window.location.href = `/company/${this.state.currentMemberData.user_name_id}/posts`;
                }}
                showProductsOfFriends={() => {
                  localStorage.setItem(
                    "onCurrentTabCompany",
                    "showproductsfriends"
                  );
                  // Commented by me
                  history.push(
                    `/company/${this.state.currentMemberData.user_name_id}/volgend`
                  );
                  // window.location.href = `/company/${this.state.currentMemberData.user_name_id}/volgend`;
                }}
                showNewTrending={() => {
                  localStorage.setItem(
                    "onCurrentTabCompany",
                    "shownewtrending"
                  );
                  // Commented by me
                  history.push(
                    `/company/${this.state.currentMemberData.user_name_id}/trending`
                  );
                  // window.location.href = `/company/${this.state.currentMemberData.user_name_id}/volgend`;
                }}
                showProductsBookmarked={() => {
                  localStorage.setItem(
                    "onCurrentTabCompany",
                    "showProductsBookmarked"
                  );
                  this.setState({
                    showProductsBookmarked: false,
                    moveToContactSection: false,
                  });
                  setTimeout(() => {
                    this.setState({
                      showLists: false,
                      doLessHeight: false,
                      showItems: false,
                      showFriends: false,
                      showImpression: false,
                      showVoucher: false,
                      showPosts: false,
                      showProductsBookmarked: true,
                      showCharity: false,
                      showProductsShops: false,
                      showProductsFriends: false,
                      showContent: false,
                    });
                  }, 10);
                }}
                showCharity={() => {
                  localStorage.setItem(
                    "onCurrentTabCompany",
                    "showCharity"
                  );
                  // Commented by me
                  history.push(
                    `/company/${this.state.currentMemberData.user_name_id}/charity`
                  );
                  // window.location.href = `/company/${this.state.currentMemberData.user_name_id}/charity`;
                }}
                showProductsShop={() => {
                  localStorage.setItem(
                    "onCurrentTabCompany",
                    "showProductsShops"
                  );
                  this.setState({
                    showProductsShops: false,
                    showProductsFriends: false,
                    showContent: false,
                  });
                }}
                showFriendsNewUpdated={() => {
                  localStorage.setItem(
                    "onCurrentTabCompany",
                    "showVrienden"
                  );
                  history.push(
                    `/company/${this.state.currentMemberData.user_name_id}/vrienden`
                  );
                }}
                hideNav={this.state.hideNav}
                openProductsWork={() => {
                  this.setState({
                    popuprightToShow: !this.state.popuprightToShow,
                    hideNav: true,
                  });
                }}
                openVouchersWork={() => {
                  this.setState({
                    popuprightToShowVoucher:
                      !this.state.popuprightToShowVoucher,
                    hideNav: true,
                  });
                }}
                hideMenuWork={false}
                fillUpAddress={this.state.fillUpAddress}
                removeFillUpAddress={() => {
                  this.setState({ fillUpAddress: false });
                }}
                doShowMenuRightAbout={this.state.doShowMenuRightAbout}
                closeAboutBhodiRightMenu={() => {
                  this.setState({ doShowMenuRightAbout: false });
                }}
                showTrendingPosts={() => {
                  this.setState({ showTrendingPosts: true });
                }}
                showGlobalPosts={() => {
                  this.setState({ showPublicPosts: true });
                }}
                showStoriesPopup={() => {
                  this.setState({ showStoriesRightMenu: true });
                }}
                showStoriesPopupRight={() => {
                  if (
                    this.state.currentMemberData.user_id ===
                    this.state.userid
                  ) {
                    this.setState({ showStoriesRightMenuAnothers: true });
                  } else {
                    setTimeout(() => {
                      localStorage.setItem(
                        "onCurrentTabCompany",
                        "showPosts"
                      );
                      window.location.href = `/company/${this.state.currentMemberData.user_name_id}/posts`;
                      // window.location.href = `/member/${this.state.userDataViewer.user_name_id}`;
                    }, 200);
                    // this.setState({ showStoriesRightMenuViewers: true });
                  }
                  // this.setState({ showStoriesRightMenuAnothers: true })
                }}
                doActiveToevogen={() => {
                  this.setState({ openActieToevoegenPopup: true });
                  setTimeout(() => {
                    this.setState({ openActieToevoegenPopup: false });
                  }, 200);
                }}
                doBewerkPagina={() => {
                  this.setState({ openBewerkPagina: true });
                  setTimeout(() => {
                    this.setState({ openBewerkPagina: false });
                  }, 200);
                }}
                showGegevensPopup={this.state.showGegevensPopup}
                closeShowGegevensPopup={() => {
                  this.setState({ showGegevensPopup: false });
                }}
                closeCompleteRightMenu={this.state.closeCompleteRightMenu}
                revertBackCloseCompleteRightMenu={() => {
                  this.setState({ closeCompleteRightMenu: false });
                }}
              />
            )}
            {(!this.state.currentMemberData.hideWizard && !this.state.currentMemberData.newly_registered && !this.state.currentMemberDataOnce.create_subscription && this.state.currentMemberData.user_id === this.state.userid) && !this.state.showGreetings && !this.state.showcharityConfirmation && !this.state.showExclusiveSubscribe && (<WizardCompany currentMemberData={this.state.currentMemberData} atCurrentStep={this.state.showLive ? 2 : this.state.showPosts ? 3 : this.state.showImpression ? 4 : this.state.showCharity ? 5 : this.state.showGreetings ? 7 : this.state.showProductsNew ? 8 : (this.state.showVriendenWizard || this.state.showFriendsNewUpdated) && 6} />)}
            {this.state.showLists &&
              this.state.currentMemberData.newly_registered ? (
              <NewVisit
                currentMemberData={this.state.currentMemberData}
                openClientListRight={() => {
                  this.setState({ openClientListRight: true });
                }}
              />
            ) : (
              this.state.showLists && !(!this.state.currentMemberData.hideWizard && !this.state.currentMemberDataOnce.create_subscription && this.state.currentMemberData.user_id === this.state.userid) && (
                <MemberHome
                  member_user_id={this.state.currentMemberData.user_id}
                  memberId={this.props.match.params.company_id}
                  atCompanyPage={true}
                  currentMemberData={this.state.currentMemberData}
                  showAddressOpened={() => {
                    this.setState({ fillUpAddress: true });
                  }}
                />
              )
            )}
            {this.state.showProductsFriends && (
              <CreateProductMemberCompany
                onShowItems={this.state.nowNoShowItemsAny}
                showHideNav={() => {
                  this.setState({ hideNav: !this.state.hideNav });
                }}
                currentMemberData={this.state.currentMemberData}
              />
            )}
            {this.state.showTrendingCompany && (
              <CompanyTrending
                currentMemberData={this.state.currentMemberData}
              />
            )}
            {this.state.showGenerateQR && (
              this.state.currentMemberData.barber_new ? <GenerateQRPage
                currentMemberData={this.state.currentMemberData}
              /> : <GenerateQRCodeCompany
                currentMemberData={this.state.currentMemberData}
              />
            )}
            {this.state.showCampaignGuestPage && (
              <CompanyCampaignGuest
                currentMemberData={this.state.currentMemberData}
                company_id={this.state.currentMemberData.user_id}
              />
            )}
            {this.state.showVriendenWizard && !(!this.state.currentMemberData.hideWizard && !this.state.currentMemberDataOnce.create_subscription && this.state.currentMemberData.user_id === this.state.userid) && (
              <VriendenPageDesktop currentMemberData={this.state.currentMemberData} />
            )}
            {this.state.showFriends && <FriendList />}
            {this.state.showFriendsNewUpdated && !((!this.state.currentMemberData.hideWizard && !this.state.currentMemberDataOnce.create_subscription && this.state.currentMemberData.user_id === this.state.userid) && !this.state.showGreetings) && <FriendListRightMenu closePopup={(type) => {
              // if (type === "fromfriendscompletely") {
              //   this.setState({ closeCompleteRightMenu: true });
              // }
              // this.setState({ showRightMenuFriends: false });
              history.goBack()
            }} />}
            {this.state.showCharity && !(!this.state.currentMemberData.hideWizard && !this.state.currentMemberDataOnce.create_subscription && this.state.currentMemberData.user_id === this.state.userid) && (
              <Charity
                subscriptionActivated={false}
              />
            )}
            {this.state.showcharityConfirmation && this.state.renderConfirmation && (
              <CharityConfirmationFullPage />
            )}
            {/* {this.state.showProductsNew && !(!this.state.currentMemberData.hideWizard && !this.state.currentMemberDataOnce.create_subscription && this.state.currentMemberData.user_id === this.state.userid) && (<ProductsPage
              memberId={this.props.match.params.company_id}
              currentMemberData={this.state.currentMemberData}
            />)}
            {this.state.showExclusiveSubscribe && (<ExclusiveSubscribeProcess
              memberId={this.props.match.params.company_id}
              currentMemberData={this.state.currentMemberData}
            />)} */}
            {this.state.showProductEdit && (
              <EditProductScreen
                memberId={this.props.match.params.company_id}
                currentMemberData={this.state.currentMemberData}
              />
            )}
          </div>
        ) : (
          <div>
            {this.state.isDesktop
              ? this.state.userid && (
                <NavbarWrapperVisitor
                  subscriptionActivated={false}
                  mustBeGray={this.state.showProductsShops}
                  currentMainMember={this.state.currentMemberData}
                  moveToContactSection={() => {
                    // Commented by me
                    history.push(
                      `/company/${this.state.currentMemberData.user_name_id}/contact`
                    );
                    // window.location.href = `/company/${this.state.currentMemberData.user_name_id}/contact`;
                  }}
                  menuItemsShowing={this.state.menuItemsShowing}
                  doLessHeight={this.state.doLessHeight}
                  member_id={this.props.match.params.company_id}
                  showProducts={() => {
                    localStorage.setItem("onCurrentTabCompany", "showItems");
                    this.setState({
                      showItems: false,
                      moveToContactSection: false,
                    });
                    setTimeout(() => {
                      this.setState({
                        showItems: true,
                        doLessHeight: false,
                        showLists: false,
                        showFriends: false,
                        showImpression: false,
                        showVoucher: false,
                        showPosts: false,
                        showProductsBookmarked: false,
                        showCharity: false,
                        showProductsShops: false,
                        showProductsFriends: false,
                        showContent: false,
                      });
                    }, 10);
                  }}
                  showPosts={() => {
                    localStorage.setItem("onCurrentTabCompany", "showLists");
                    // Commented by me
                    history.push(
                      `/company/${this.state.currentMemberData.user_name_id}`
                    );
                    // window.location.href = `/company/${this.state.currentMemberData.user_name_id}`;
                  }}
                  showFriends={() => {
                    this.setState({ showRightMenuFriendsAnother: true });
                    // localStorage.setItem("onCurrentTabCompany", "showFriends")
                    // window.location.href = `/company/${this.state.currentMemberData.user_name_id}/friends`
                  }}
                  showLive={() => {
                    // this.setState({ showLive: true, showLists: false, doLessHeight: false, showItems: false, showFriends: false, showImpression: false, showVoucher: false, showPosts: false, showProductsBookmarked: false, showCharity: false, showProductsShops: false, showProductsFriends: false })
                    localStorage.setItem("onCurrentTabCompany", "showLive");
                    // Component by me
                    history.push(
                      `/company/${this.state.currentMemberData.user_name_id}/live`
                    );
                    // window.location.href = `/company/${this.state.currentMemberData.user_name_id}/live`;
                  }}
                  showProductsNew={() => {
                    localStorage.setItem(
                      "onCurrentTabCompany",
                      "showProductsNew"
                    );
                    // Commented by me
                    history.push(
                      `/company/${this.state.currentMemberData.user_name_id}/products`
                    );
                    // window.location.href = `/company/${this.state.currentMemberData.user_name_id}/products`;
                    // window.location.href = `/company/${this.state.currentMemberData.user_name_id}/products`;
                  }}
                  showMainPosts={() => {
                    localStorage.setItem("onCurrentTabCompany", "showPosts");
                    // Commented by me
                    history.push(
                      `/company/${this.state.currentMemberData.user_name_id}/posts`
                    );
                    // window.location.href = `/company/${this.state.currentMemberData.user_name_id}/posts`;
                  }}
                  showCharity={() => {
                    localStorage.setItem(
                      "onCurrentTabCompany",
                      "showCharity"
                    );
                    // Commented by me
                    history.push(
                      `/company/${this.state.currentMemberData.user_name_id}/charity`
                    );
                    // window.location.href = `/company/${this.state.currentMemberData.user_name_id}/charity`;
                  }}
                  showProductsShop={() => {
                    localStorage.setItem(
                      "onCurrentTabCompany",
                      "showProductsShops"
                    );
                    this.setState({
                      showProductsShops: false,
                      showProductsFriends: false,
                      showContent: false,
                    });
                  }}
                  showProductsOfFriends={() => {
                    localStorage.setItem(
                      "onCurrentTabCompany",
                      "showproductsfriends"
                    );
                    this.setState({
                      showProductsFriends: false,
                      moveToContactSection: false,
                    });
                    setTimeout(() => {
                      this.setState({
                        showProductsFriends: true,
                        showLists: false,
                        doLessHeight: false,
                        showItems: false,
                        showFriends: false,
                        showImpression: false,
                        showVoucher: false,
                        showPosts: false,
                        showProductsBookmarked: false,
                        showCharity: false,
                        showProductsShops: false,
                        showContent: false,
                      });
                    }, 10);
                  }}
                  showProductsBookmarked={() => {
                    localStorage.setItem(
                      "onCurrentTabCompany",
                      "showProductsBookmarked"
                    );
                    this.setState({
                      showProductsBookmarked: false,
                      moveToContactSection: false,
                    });
                    setTimeout(() => {
                      this.setState({
                        showLists: false,
                        doLessHeight: false,
                        showItems: false,
                        showFriends: false,
                        showImpression: false,
                        showVoucher: false,
                        showPosts: false,
                        showProductsBookmarked: true,
                        showCharity: false,
                        showProductsShops: false,
                        showProductsFriends: false,
                        showContent: false,
                      });
                    }, 10);
                  }}
                  hideNav={this.state.hideNav}
                  openProductsWork={() => {
                    this.setState({
                      popuprightToShow: !this.state.popuprightToShow,
                      hideNav: true,
                    });
                  }}
                  openVouchersWork={() => {
                    this.setState({
                      popuprightToShowVoucher:
                        !this.state.popuprightToShowVoucher,
                      hideNav: true,
                    });
                  }}
                  hideMenuWork={false}
                  fillUpAddress={this.state.fillUpAddress}
                  removeFillUpAddress={() => {
                    this.setState({ fillUpAddress: false });
                  }}
                  showTrendingPosts={() => {
                    this.setState({ showTrendingPosts: true });
                  }}
                  showGlobalPosts={() => {
                    this.setState({ showPublicPosts: true });
                  }}
                  showStoriesPopup={() => {
                    this.setState({ showStoriesRightMenu: true });
                  }}
                  showStoriesPopupRight={() => {
                    if (
                      this.state.currentMemberData.user_id ===
                      this.state.userid
                    ) {
                      this.setState({ showStoriesRightMenuAnothers: true });
                    } else {
                      setTimeout(() => {
                        localStorage.setItem(
                          "onCurrentTabCompany",
                          "showPosts"
                        );
                        window.location.href = `/company/${this.state.currentMemberData.user_name_id}/posts`;
                        // window.location.href = `/member/${this.state.userDataViewer.user_name_id}`;
                      }, 200);
                      // this.setState({ showStoriesRightMenuViewers: true });
                    }
                    // this.setState({ showStoriesRightMenuAnothers: true })
                  }}
                />
              )
              : this.state.userid && (
                <NavbarWrapperVisitorMobile
                  mobileForViewer={true}
                  mustBeGray={this.state.showProductsShops}
                  currentMainMember={this.state.currentMemberData}
                  moveToContactSection={() => {
                    // Commented by me
                    history.push(
                      `/company/${this.state.currentMemberData.user_name_id}#contacttoscrollfromtop`
                    );
                    // window.location.href = `/company/${this.state.currentMemberData.user_name_id}#contacttoscrollfromtop`;
                  }}
                  menuItemsShowing={this.state.menuItemsShowing}
                  doLessHeight={this.state.doLessHeight}
                  member_id={this.props.match.params.company_id}
                  openAddPopupPosts={() => {
                    document.getElementById("root").style.position = "fixed"
                    document.getElementById("root").style.overflowY = "hidden"
                    this.setState({ addPosts: true })
                  }}
                  showProducts={() => {
                    localStorage.setItem("onCurrentTabCompany", "showItems");
                    this.setState({
                      showItems: false,
                      moveToContactSection: false,
                    });
                    setTimeout(() => {
                      this.setState({
                        showItems: true,
                        doLessHeight: false,
                        showLists: false,
                        showFriends: false,
                        showImpression: false,
                        showVoucher: false,
                        showPosts: false,
                        showProductsBookmarked: false,
                        showCharity: false,
                        showProductsShops: false,
                        showProductsFriends: false,
                        showContent: false,
                      });
                    }, 10);
                  }}
                  showPosts={() => {
                    localStorage.setItem("onCurrentTabCompany", "showLists");
                    // Commented by me
                    history.push(
                      `/company/${this.state.currentMemberData.user_name_id}`
                    );
                    // window.location.href = `/company/${this.state.currentMemberData.user_name_id}`;
                  }}
                  showFriends={() => {
                    this.setState({ showRightMenuFriendsAnother: true });
                    // localStorage.setItem("onCurrentTabCompany", "showFriends")
                    // window.location.href = `/company/${this.state.currentMemberData.user_name_id}/friends`
                  }}
                  showMainPosts={() => {
                    localStorage.setItem("onCurrentTabCompany", "showPosts");
                    // Commented by me
                    history.push(
                      `/company/${this.state.currentMemberData.user_name_id}/posts`
                    );
                    // window.location.href = `/company/${this.state.currentMemberData.user_name_id}/posts`;
                  }}
                  showProductsOfFriends={() => {
                    localStorage.setItem(
                      "onCurrentTabCompany",
                      "showproductsfriends"
                    );
                    this.setState({
                      showProductsFriends: false,
                      moveToContactSection: false,
                    });
                    setTimeout(() => {
                      this.setState({
                        showProductsFriends: true,
                        showLists: false,
                        doLessHeight: false,
                        showItems: false,
                        showFriends: false,
                        showImpression: false,
                        showVoucher: false,
                        showPosts: false,
                        showProductsBookmarked: false,
                        showCharity: false,
                        showProductsShops: false,
                        showContent: false,
                      });
                    }, 10);
                  }}
                  showLive={() => {
                    // this.setState({ showLive: true, showLists: false, doLessHeight: false, showItems: false, showFriends: false, showImpression: false, showVoucher: false, showPosts: false, showProductsBookmarked: false, showCharity: false, showProductsShops: false, showProductsFriends: false })
                    localStorage.setItem("onCurrentTabCompany", "showLive");
                    // Commented by me
                    history.push(
                      `/company/${this.state.currentMemberData.user_name_id}/live`
                    );
                    // window.location.href = `/company/${this.state.currentMemberData.user_name_id}/live`;
                  }}
                  showProductsNew={() => {
                    localStorage.setItem(
                      "onCurrentTabCompany",
                      "showProductsNew"
                    );
                    // Commented by me
                    history.push(
                      `/company/${this.state.currentMemberData.user_name_id}/products`
                    );

                    // window.location.href = `/company/${this.state.currentMemberData.user_name_id}/products`;

                    // window.location.href = `/company/${this.state.currentMemberData.user_name_id}/products`;
                  }}
                  showProductsBookmarked={() => {
                    localStorage.setItem(
                      "onCurrentTabCompany",
                      "showProductsBookmarked"
                    );
                    this.setState({
                      showProductsBookmarked: false,
                      moveToContactSection: false,
                    });
                    setTimeout(() => {
                      this.setState({
                        showLists: false,
                        doLessHeight: false,
                        showItems: false,
                        showFriends: false,
                        showImpression: false,
                        showVoucher: false,
                        showPosts: false,
                        showProductsBookmarked: true,
                        showCharity: false,
                        showProductsShops: false,
                        showProductsFriends: false,
                        showContent: false,
                      });
                    }, 10);
                  }}
                  showCharity={() => {
                    localStorage.setItem(
                      "onCurrentTabCompany",
                      "showCharity"
                    );
                    // Commented by me
                    history.push(
                      `/company/${this.state.currentMemberData.user_name_id}/charity`
                    );
                    // window.location.href = `/company/${this.state.currentMemberData.user_name_id}/charity`;
                  }}
                  showProductsShop={() => {
                    localStorage.setItem(
                      "onCurrentTabCompany",
                      "showProductsShops"
                    );
                    this.setState({
                      showProductsShops: false,
                      showProductsFriends: false,
                      showContent: false,
                    });
                  }}
                  hideNav={this.state.hideNav}
                  openProductsWork={() => {
                    this.setState({
                      popuprightToShow: !this.state.popuprightToShow,
                      hideNav: true,
                    });
                  }}
                  openVouchersWork={() => {
                    this.setState({
                      popuprightToShowVoucher:
                        !this.state.popuprightToShowVoucher,
                      hideNav: true,
                    });
                  }}
                  hideMenuWork={false}
                  fillUpAddress={this.state.fillUpAddress}
                  removeFillUpAddress={() => {
                    this.setState({ fillUpAddress: false });
                  }}
                  showTrendingPosts={() => {
                    this.setState({ showTrendingPosts: true });
                  }}
                  showGlobalPosts={() => {
                    this.setState({ showPublicPosts: true });
                  }}
                  showStoriesPopup={() => {
                    this.setState({ showStoriesRightMenu: true });
                  }}
                  showStoriesPopupRight={() => {
                    if (
                      this.state.currentMemberData.user_id ===
                      this.state.userid
                    ) {
                      this.setState({ showStoriesRightMenuAnothers: true });
                    } else {
                      setTimeout(() => {
                        localStorage.setItem(
                          "onCurrentTabCompany",
                          "showPosts"
                        );
                        window.location.href = `/company/${this.state.currentMemberData.user_name_id}/posts`;
                        // window.location.href = `/member/${this.state.userDataViewer.user_name_id}`;
                      }, 200);
                      // this.setState({ showStoriesRightMenuViewers: true });
                    }
                    // this.setState({ showStoriesRightMenuAnothers: true })
                  }}
                  showGegevensPopup={this.state.showGegevensPopup}
                  closeShowGegevensPopup={() => {
                    this.setState({ showGegevensPopup: false });
                  }}
                />
              )}

            {this.state.showLists && (
              <MemberHome
                subscriptionActivated={false}
                atCompanyPage={true}
                member_user_id={this.state.currentMemberData.user_id}
                memberId={this.props.match.params.company_id}
                currentMemberData={this.state.currentMemberData}
                showAddressOpened={() => {
                  this.setState({ fillUpAddress: true });
                }}
              />
            )}
            {this.state.showCampaignGuestPage && (
              <CompanyCampaignGuest
                currentMemberData={this.state.currentMemberData}
                company_id={this.state.currentMemberData.user_id}
              />
            )}
          </div>
        )}
        {this.state.showRightMenuFriends && (
          <div
            className="overlay-div-openadd"
            onClick={() => {
              this.setState({ showRightMenuFriends: false });
            }}
          ></div>
        )}
        {this.state.showRightMenuFriends && (
          <FriendListRightMenu
            closePopup={(type) => {
              if (type === "fromfriendscompletely") {
                this.setState({ closeCompleteRightMenu: true });
              }
              this.setState({ showRightMenuFriends: false });
            }}
          />
        )}

        {this.state.showRightMenuFriendsAnother && (
          <div
            className="overlay-div-openadd"
            onClick={() => {
              this.setState({ showRightMenuFriends: false });
            }}
          ></div>
        )}
        {this.state.showRightMenuFriendsAnother && (
          <ViewerFriendlist
            viewerOnUser={this.state.currentMemberData}
            closePopup={() => {
              this.setState({ showRightMenuFriendsAnother: false });
            }}
          />
        )}
      </div>
    ) : (
      <div className="loaderspaceloading">
        <div className="lds-heart">
          <div></div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    reRendered: state.renderReducer.reRendered,
    user: state.authReducers.user,
    global_campaigns: state.campaignsReducer.global_campaigns,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    rerenderComponent: (reRendered) => dispatch(rerenderComponent(reRendered)),
    updateUser: (user) => dispatch(updateUser(user)),
    updateCurrentMoments: (currentMoments) =>
      dispatch(updateCurrentMoments(currentMoments)),
    updateAlbumToAdd: (albumToAdd) => dispatch(updateAlbumToAdd(albumToAdd)),
    updateMoreAlbumsAdded: (morealbumsToAdd) =>
      dispatch(updateMoreAlbumsAdded(morealbumsToAdd)),
    updateSelectedExclusive: (selected_exclusive) =>
      dispatch(updateSelectedExclusive(selected_exclusive)),
    updateGuestUserCart: (guest_user_cart) =>
      dispatch(updateGuestUserCart(guest_user_cart)),
    updateGuestUserCartTime: (guest_user_cart_time) =>
      dispatch(updateGuestUserCartTime(guest_user_cart_time)),
    updateGuestUserCartExtendOpportunity: (
      guest_user_cart_extend_opportunity
    ) =>
      dispatch(
        updateGuestUserCartExtendOpportunity(guest_user_cart_extend_opportunity)
      ),
    updateSignUpFieldsFilled: (signup_fields_info) =>
      dispatch(updateSignUpFieldsFilled(signup_fields_info)),
    updateAlbumToAddFriends: (albumToAddFriends) =>
      dispatch(updateAlbumToAddFriends(albumToAddFriends)),
    updateMoreAlbumsAddedFriends: (morealbumsToAddFriends) =>
      dispatch(updateMoreAlbumsAddedFriends(morealbumsToAddFriends)),
    updateSelectedExclusiveFriends: (selected_exclusive_friends) =>
      dispatch(updateSelectedExclusiveFriends(selected_exclusive_friends)),
    updateCurrentMomentsFriendsPage: (currentMomentsFriends) =>
      dispatch(updateCurrentMomentsFriendsPage(currentMomentsFriends)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WholeCompanyStuff);
