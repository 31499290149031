import React, { Component } from "react";

export class BackTimer extends Component {
  componentDidMount() {
    const loaderId = document.getElementById("main-page-loader-id");
    loaderId.style.display = "none";
    this.interval = setInterval(
      () => this.setState({ timeCurrent: Date.now() }),
      1000
    );
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }
  
  calculateTimeStamp() {
    var date = new Date(this.props.timeToCalculate);

    let dateFuture = date.getTime();

    let dateNow = new Date().getTime();

    let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

    let days = Math.floor(diffInMilliSeconds / 86400);
    diffInMilliSeconds -= days * 86400;

    let hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    diffInMilliSeconds -= hours * 3600;

    let minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;

    let seconds = diffInMilliSeconds % 60;

    let difference = "";
    if (days > 0) {
      difference += days === 1 ? `${days}:` : `${days}:`;
    }

    if (hours < 10) {
      hours = `0${hours}`;
    } else {
      hours = `${hours}`;
    }

    if (minutes < 10) {
      minutes = `0${minutes}`;
    } else {
      minutes = `${minutes}`;
    }

    if (seconds < 9) {
      seconds = `0${Math.ceil(seconds)}`;
    } else {
      seconds = `${Math.ceil(seconds)}`;
    }

    difference += hours === 0 || hours === 1 ? `${hours}:` : `${hours}:`;

    difference += minutes === 0 || hours === 1 ? `${minutes}:` : `${minutes}:`;

    difference +=
      seconds === 0 || minutes === 1
        ? `${Math.ceil(seconds) !== 60 ? seconds : "00"}`
        : `${Math.ceil(seconds) !== 60 ? seconds : "00"}`;

    return difference;
  }

  render() {
    return <p>{this.calculateTimeStamp()}</p>;
  }
}

export default BackTimer;
