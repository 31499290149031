import React, { Component } from "react";
import ReactFullpage from "@fullpage/react-fullpage";
import firebase from "../../Config/Firebase";
import {
    updateProducts,
    updateUser,
    updateBookmarkStuff,
} from "../../Redux/actions/authActions";
import { connect } from "react-redux";
import VouchersNewMobile from "../VouchersNewMobile";
import NavbarWrapperVisitorMobile from "../../Components/NavbarWrapperVisitorMobile";
import VouchersGrid from "../../Components/VouchersGrid";
import { history } from "../../Config/Routes";
import NavbarMainFullWidth from "../../Components/NavbarMainFullWidth";
import NavbarMainFullWidthCompany from "../../Components/NavSecMainFullWidthCompany";
import FriendListRightMenu from "../../Components/FriendListRightMenu";
import { mergeAdminEvent } from "../../Functions/globalFunctions";

export class BookmarkedThings extends Component {
    constructor() {
        super();
        this.state = {
            toShowSections: [],
            allProducts: [],
            currentProductDetailed: "",
            showDetailedWork: false,
            currentProductDetailedIndex: "",
            currentSectionShowing: 0,
            arrToHideOrShow: [],
            countsArray: [],
            currentUser: "",
            orderidToUpload: 0,
            verzilverPopup: false,
            verzilverPopupGreen: false,
            counterMinute: 1,
            counterSecond: 59,
            currentActie: "",
            currentVoucherWork: "",
            isDesktop: true,
            activeSection: 0,
            showVoucherOfType: "voucher",
            completeBookmarked: [],
            isVoucherPresent: false,
            isEventPresent: false,
            firstSectionToShow: [],
            allSectors: [],
            currentVoucherWork: "",
            showOptionsToAdd: false,
            showDescriptionPopup: false,
            codegebruikpopup: false,
            codebewaarpopup: false,
            selectedDish: 0,
            alreadyBewared: [],
            mustShowAnimation: false,
            useOfVoucherOpen: false,
            exceptionsVoucherOpen: false,
            completeArrProducts: [],
            filteredProductsToShow: [],
            currentUserHere: "",
        };
    }

    componentDidMount() {
        const loaderId = document.getElementById("main-page-loader-id");
        loaderId.style.display = "none";
        if (this.props.global_values) {
            let a = this.props.global_values;
            if (a) {
                if (a.globalOverlay) {
                    this.setState({ globalOverlay: a.globalOverlay });
                }
            }
        }

        document.getElementById("root").style.overflow = "hidden";

        if (window.innerWidth < 1009) {
            this.setState({ isDesktop: false });
        } else {
            this.setState({ isDesktop: true });
        }

        let uid = localStorage.getItem("userid");
        this.setState({ currentUserId: uid });

        let localCurrent = localStorage.getItem("onCurrentSection");
        if (localCurrent) {
            localStorage.removeItem("onCurrentSection");
            this.setState({ activeSection: Number(localCurrent) });
        }

        let currentUser = "";
        if (this.props.user) {
            if (this.props.user.length > 0) {
                let filtered = this.props.user.filter((y) => {
                    return y.user_id === uid;
                });
                if (filtered.length > 0) {
                    let a = filtered[0];
                    currentUser = a;
                    if (a) {
                        this.setState({ currentUserHere: a, notSubscribed: true });
                    }
                }
            }
        }
        if (uid) {
            firebase
                .database()
                .ref("users/" + uid)
                .on("value", (data) => {
                    let a = data.val();
                    if (a) {
                        currentUser = a;
                        this.setState({ currentUserHere: currentUser });
                        // this.props.updateUser(arrUsers)
                    }
                });
        }


        if (this.props.global_campaigns) {
            if (this.props.global_campaigns.length > 0) {
                let objected = this.props.global_campaigns;
                let arr = [];
                let allCampaignsCompletedToRender = [];
                objected.map((e) => {
                    e[1].type = "campaign";
                    e[1].currentImageIndex = 0;
                    allCampaignsCompletedToRender.push(e[1]);
                    if (e[1].desc) {
                        e[1].short_desc = e[1].desc.substr(0, 120);
                    }
                    e[1].images = [e[1].image];
                    e[1].imagesObjected = [
                        { image: e[1].image, pushKey: e[1].pushKey },
                    ];
                    if (e[1].active) {
                        if (e[1].campaignType === "kortings_voucher") {
                            let objectedVerzilveredBy = [];
                            let num = 0;
                            if (e[1].verzilvered_by) {
                                objectedVerzilveredBy = Object.entries(
                                    e[1].verzilvered_by
                                );
                                num = this.getVerzilveredQty(objectedVerzilveredBy);
                            }
                            let bewaaredArray = [];
                            if (!e[1].kortingsVoucherUnlimited) {
                                if (num + bewaaredArray.length < Number(e[1].number)) {
                                    arr.push(e[1]);
                                }
                            } else {
                                arr.push(e[1]);
                            }
                        }
                        if (e[1].campaignType === "web_shop") {
                            let objectedVerzilveredBy = [];
                            let num = 0;
                            if (e[1].verzilvered_by) {
                                objectedVerzilveredBy = Object.entries(
                                    e[1].verzilvered_by
                                );
                                num = this.getVerzilveredQty(objectedVerzilveredBy);
                            }
                            let bewaaredArray = [];
                            if (!e[1].voucherUnlimited) {
                                if (num + bewaaredArray.length < Number(e[1].number)) {
                                    arr.push(e[1]);
                                }
                            } else {
                                arr.push(e[1]);
                            }
                        }
                        if (e[1].campaignType === "local_event") {
                            if (e[1].takeFromAdmin) {
                                let obj = mergeAdminEvent(e[1], this.props.global_campaigns)
                                e[1] = Object.assign({}, obj)
                            }
                            let arrTimingsAvailable = [];
                            if (e[1].timings) {
                                e[1].timings.map((t) => {
                                    if (t.isoStartTime && t.isoEndTime) {
                                        let checkedInOfCurrentBlock = [];
                                        if (e[1].checked_in_by) {
                                            Object.entries(e[1].checked_in_by).map((g) => {
                                                if (g[1].event_id === t.eventId) {
                                                    checkedInOfCurrentBlock.push(g[1]);
                                                }
                                            });
                                        }
                                        let getCurrentDate = new Date();
                                        if (getCurrentDate.getTime() < new Date(Date.parse(t.isoEndTime)).getTime()) {
                                            if (checkedInOfCurrentBlock.length < Number(t.personsAmountEvent)) {
                                                arrTimingsAvailable.push(t);
                                            }
                                        }
                                    }
                                });
                            }
                            if (arrTimingsAvailable.length > 0) {
                                if (e[1].active) {
                                    arr.push(e[1]);
                                }
                            }
                        }
                    }
                });
                let arrToMake = [];
                arr.map((t, i) => {
                    if (t.campaignType === "kortings_voucher") {
                        arrToMake.push(t);
                    }
                    if (t.campaignType === "web_shop") {
                        arrToMake.push(t);
                    }
                    if (t.campaignType === "local_event") {
                        arrToMake.push(t);
                    }
                });

                let bookmarkedVoucherEvents = []
                arrToMake.map((t) => {
                    if (t.bookmarked_by) {
                        let filtered = Object.entries(t.bookmarked_by).filter((g) => {
                            return g[1].bookmarked_by === uid
                        })
                        if (filtered.length > 0) {
                            bookmarkedVoucherEvents.push(t)
                        }
                    }
                })

                let bookmarkedVoucherEventsAll = [...bookmarkedVoucherEvents]

                if (bookmarkedVoucherEvents.length > 0) {
                    this.setState({
                        allProducts: bookmarkedVoucherEvents,
                        completeArrProducts: bookmarkedVoucherEventsAll,
                        filteredProductsToShow: bookmarkedVoucherEventsAll,
                        countsArray: [],
                    });
                } else {
                    history.goBack()
                }

            }
        }
    }

    onLeave(origin, destination, direction) {
        if (Number(origin.index) > 0) {
            // this.state.arrToHideOrShow[origin.index - 1].show = false
            this.setState({ countsArray: [] });
        }
    }

    afterLoad(origin, destination, direction) {
        this.setState({ countsArray: [], onCurrentPage: destination.index });
    }

    getSectorName() {
        if (this.state.allSectors.length > 0) {
            let filtered = this.state.allSectors.filter((t) => {
                return t.sector_id === this.props.match.params.sectorid;
            });
            return filtered[0].sector_name;
        }
    }

    getTouches(evt) {
        return (
            evt.touches || // browser API
            evt.originalEvent.touches
        ); // jQuery
    }

    getCharityObjectedQty(e) {
        let arrItems = e;
        if (arrItems.length > 0) {
            Array.prototype.sum = function (prop) {
                var total = 0;
                for (var i = 0, _len = this.length; i < _len; i++) {
                    total += Number(this[i][1][prop]);
                }
                return total;
            };
            return arrItems.sum("used_time");
        }
    }

    getVerzilveredQty(e) {
        let arrItems = e;
        if (arrItems.length > 0) {
            Array.prototype.sum = function (prop) {
                var total = 0;
                for (var i = 0, _len = this.length; i < _len; i++) {
                    total += Number(this[i][1][prop].length);
                }
                return total;
            };
            return arrItems.sum("allDates");
        }
    }

    componentDidUpdate(prevProps) {
        let uid = localStorage.getItem("userid");
        // if (this.props.global_campaigns !== prevProps.global_campaigns) {
        //     let allArrs = this.props.global_campaigns;
        //     let allKeysCurrent = [];
        //     let allKeysComingCampaigns = [];
        //     this.state.filteredProductsToShow.map((u) => {
        //         allKeysCurrent.push(u.pushKey);
        //     });
        //     allArrs.map((y) => {
        //         y[1].type = "campaign";
        //         y[1].currentImageIndex = 0;
        //         allKeysComingCampaigns.push(y[1].pushKey);
        //         if (y[1].desc) {
        //             // y[1].desc = y[1].desc.substr(0, 200)
        //             y[1].short_desc = y[1].desc.substr(0, 120);
        //         }
        //         if (y[1].campaignType !== "charity") {
        //             y[1].images = [y[1].image];
        //             y[1].imagesObjected = [{ image: y[1].image, pushKey: y[1].pushKey }];
        //         }
        //         if (y[1].campaignType === "kortings_voucher") {
        //             this.state.allProducts.map((t, i) => {
        //                 if (t.pushKey === y[1].pushKey) {
        //                     if (!y[1].active) {
        //                         y[1].showPopupNotLeft = true;
        //                     } else {
        //                         if (!y[1].kortingsVoucherUnlimited) {
        //                             let bewaaredArray = [];
        //                             let num = 0;
        //                             if (y[1].verzilvered_by) {
        //                                 let objectedVerzilvered = Object.entries(
        //                                     y[1].verzilvered_by
        //                                 );
        //                                 num = this.getVerzilveredQty(objectedVerzilvered);
        //                             }
        //                             if (num + bewaaredArray.length >= Number(y[1].number)) {
        //                                 y[1].showPopupNotLeft = true;
        //                             } else {
        //                                 y[1].showPopupNotLeft = false;
        //                             }
        //                         } else {
        //                             y[1].showPopupNotLeft = false;
        //                         }
        //                     }
        //                     this.state.allProducts[i] = y[1];
        //                 }
        //             });
        //             this.state.filteredProductsToShow.map((t, i) => {
        //                 if (t.pushKey === y[1].pushKey) {
        //                     if (!y[1].active) {
        //                         y[1].showPopupNotLeft = true;
        //                     } else {
        //                         if (!y[1].kortingsVoucherUnlimited) {
        //                             let bewaaredArray = [];
        //                             let num = 0;
        //                             if (y[1].verzilvered_by) {
        //                                 let objectedVerzilvered = Object.entries(
        //                                     y[1].verzilvered_by
        //                                 );
        //                                 num = this.getVerzilveredQty(objectedVerzilvered);
        //                             }
        //                             if (num + bewaaredArray.length >= Number(y[1].number)) {
        //                                 y[1].showPopupNotLeft = true;
        //                             } else {
        //                                 y[1].showPopupNotLeft = false;
        //                             }
        //                         } else {
        //                             y[1].showPopupNotLeft = false;
        //                         }
        //                     }
        //                     this.state.filteredProductsToShow[i] = y[1];
        //                 }
        //             });
        //         }
        //         if (y[1].campaignType === "local_event") {
        //             this.state.allProducts.map((t, i) => {
        //                 if (t.pushKey === y[1].pushKey) {
        //                     if (!y[1].active) {
        //                         y[1].showPopupNotLeft = true;
        //                     } else {
        //                         y[1].showPopupNotLeft = false;
        //                     }
        //                     this.state.allProducts[i] = y[1];
        //                 }
        //             });
        //             this.state.filteredProductsToShow.map((t, i) => {
        //                 if (t.pushKey === y[1].pushKey) {
        //                     if (!y[1].active) {
        //                         y[1].showPopupNotLeft = true;
        //                     } else {
        //                         y[1].showPopupNotLeft = false;
        //                     }
        //                     this.state.filteredProductsToShow[i] = y[1];
        //                 }
        //             });
        //         }
        //     });
        //     this.setState({
        //         allProducts: this.state.allProducts,
        //     });
        // }
        if (this.props.user !== prevProps.user) {
            if (this.props.user.length > 0) {
                let filtered = this.props.user.filter((y) => {
                    return y.user_id === uid;
                });
                if (filtered.length > 0) {
                    this.setState({ currentUserHere: filtered[0] });
                }
                // navigator.geolocation.getCurrentPosition((position) => {
                //     this.setState({ latitude: position.coords.latitude, longitude: position.coords.longitude })
                // });
                let latitude = localStorage.getItem("latitudeAfterLogin");
                let longitude = localStorage.getItem("longitudeAfterLogin");
                if (latitude) {
                    this.setState({ latitude, longitude });
                }
            }
        }
    }

    getSellerName(e) {
        let filtered = this.props.user.filter((g) => {
            return g.user_id === e.created_by;
        });
        if (filtered.length > 0) {
            return filtered[0].company_name;
        }
    }

    getSellerImage(e) {
        let filtered = this.props.user.filter((g) => {
            return g.user_id === e.created_by;
        });
        if (filtered.length > 0) {
            if (filtered[0].profile_image) {
                return filtered[0].profile_image;
            } else {
                return "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9";
            }
        }
    }

    getSellerCity(e) {
        let filtered = this.props.user.filter((g) => {
            return g.user_id === e.created_by;
        });
        if (filtered.length > 0) {
            return filtered[0].city;
        }
    }

    render() {
        return (
            <div>

                {this.state.isDesktop && (<>
                    {this.state.currentUserHere.type === "company" ? (<NavbarMainFullWidthCompany
                        menuItemsShowing={true}
                        member_id={this.state.currentUserHere.user_name_id}
                        showPosts={() => {
                            localStorage.setItem("onCurrentTabCompany", "showLists");
                            history.push(
                                `/company/${this.state.currentUserHere.user_name_id}`
                            );
                        }}
                        showFriends={() => {
                            this.setState({ showRightMenuFriends: true });
                        }}
                        showProductsOfFriends={() => {
                            localStorage.setItem(
                                "onCurrentTabCompany",
                                "showproductsfriends"
                            );
                            // Commented by me
                            history.push(
                                `/company/${this.state.currentUserHere.user_name_id}/volgend`
                            );
                        }}
                        showCharity={() => {
                            localStorage.setItem(
                                "onCurrentTabCompany",
                                "showCharity"
                            );
                            // Commented by me
                            history.push(
                                `/company/${this.state.currentUserHere.user_name_id}/charity`
                            );
                            // window.location.href = `/company/${this.state.currentMemberData.user_name_id}/charity`;
                        }}
                        hideMenuWork={false}

                        onBookmarkPage={true}
                        searchFiltered={(e) => {
                            if (e !== "") {
                                let filtered = this.state.completeArrProducts.filter(
                                    (y) => {
                                        let filteredUser = this.props.user.filter((j) => {
                                            return j.user_id === y.created_by;
                                        });
                                        if (filteredUser.length > 0) {
                                            return (
                                                y.title
                                                    .toLowerCase()
                                                    .includes(e.toLowerCase()) ||
                                                filteredUser[0].company_name
                                                    .toLowerCase()
                                                    .includes(e.toLowerCase())
                                            );
                                        }
                                    }
                                );

                                let filteredHere = [...filtered];

                                if (filtered.length > 0) {
                                    this.setState({
                                        allProducts: [],
                                    });

                                    setTimeout(() => {
                                        this.setState({
                                            allProducts: filtered,
                                            filteredProductsToShow: filteredHere,
                                        });
                                    }, 10);
                                } else {
                                    this.setState({
                                        allProducts: [],
                                        filteredProductsToShow: [],
                                    });
                                }
                            } else {
                                let filtered = [...this.state.completeArrProducts];
                                let filteredHere = [...filtered];

                                this.setState({
                                    allProducts: [],
                                });

                                setTimeout(() => {
                                    this.setState({
                                        allProducts: filtered,
                                        filteredProductsToShow: filteredHere,
                                    });
                                }, 10);
                            }
                        }}
                    />) : (<NavbarMainFullWidth
                        member_id={this.state.currentUserHere.user_name_id}
                        linkToVolgend={`/member/${this.state.currentUserHere.user_name_id}/volgend`}
                        linkToCharity={`/member/${this.state.currentUserHere.user_name_id}/charity`}
                        showProducts={() => {
                            localStorage.setItem("onCurrentTab", "products");
                        }}
                        showFriends={() => {
                            this.setState({ showRightMenuFriends: true });
                        }}
                        showCharity={() => {
                            localStorage.setItem("onCurrentTab", "charitypage");
                        }}


                        onBookmarkPage={true}
                        searchFiltered={(e) => {
                            if (e !== "") {
                                let filtered = this.state.completeArrProducts.filter(
                                    (y) => {
                                        let filteredUser = this.props.user.filter((j) => {
                                            return j.user_id === y.created_by;
                                        });
                                        if (filteredUser.length > 0) {
                                            return (
                                                y.title
                                                    .toLowerCase()
                                                    .includes(e.toLowerCase()) ||
                                                filteredUser[0].company_name
                                                    .toLowerCase()
                                                    .includes(e.toLowerCase())
                                            );
                                        }
                                    }
                                );

                                let filteredHere = [...filtered];

                                if (filtered.length > 0) {
                                    this.setState({
                                        allProducts: [],
                                    });

                                    setTimeout(() => {
                                        this.setState({
                                            allProducts: filtered,
                                            filteredProductsToShow: filteredHere,
                                        });
                                    }, 10);
                                } else {
                                    this.setState({
                                        allProducts: [],
                                        filteredProductsToShow: [],
                                    });
                                }
                            } else {
                                let filtered = [...this.state.completeArrProducts];
                                let filteredHere = [...filtered];

                                this.setState({
                                    allProducts: [],
                                });

                                setTimeout(() => {
                                    this.setState({
                                        allProducts: filtered,
                                        filteredProductsToShow: filteredHere,
                                    });
                                }, 10);
                            }
                        }}
                    />)}
                </>)}
                {/* friends list */}
                {this.state.showRightMenuFriends && (
                    <div
                        className="overlay-div-openadd"
                        onClick={() => {
                            this.setState({ showRightMenuFriends: false });
                        }}
                    ></div>
                )}
                {this.state.showRightMenuFriends && (
                    <FriendListRightMenu
                        closePopup={(type) => {
                            if (type === "fromfriendscompletely") {
                                this.setState({ closeCompleteRightMenu: true });
                            }
                            this.setState({ showRightMenuFriends: false });
                        }}
                    />
                )}
                {window.innerWidth > 1009 ? (
                    this.state.allProducts.length > 0 && (<VouchersGrid all_products_grid={this.state.allProducts} />)
                ) : (
                    <div
                        className="mobileshopglobal mobilevouchersglobal nvp-total-fixed"
                        style={{ backgroundColor: "white" }}
                    >
                        <NavbarWrapperVisitorMobile showSearchOnGlobal={true}
                            searchFiltered={(e) => {
                                if (e !== "") {
                                    let filtered = [...this.state.filteredProductsToShow].filter(
                                        (y) => {
                                            let filteredUser = this.props.user.filter((j) => {
                                                return j.user_id === y.created_by;
                                            });
                                            if (filteredUser.length > 0) {
                                                return (
                                                    y.title.toLowerCase().includes(e.toLowerCase()) ||
                                                    filteredUser[0].company_name
                                                        .toLowerCase()
                                                        .includes(e.toLowerCase())
                                                );
                                            }
                                        }
                                    );

                                    this.setState({ completeArrProducts: [] });
                                    setTimeout(() => {
                                        if (filtered.length > 0) {
                                            this.setState({ completeArrProducts: filtered });
                                        } else {
                                            this.setState({ completeArrProducts: [] });
                                        }
                                    }, 100);
                                } else {
                                    this.setState({ completeArrProducts: [] });

                                    setTimeout(() => {
                                        this.setState({
                                            completeArrProducts: this.state.filteredProductsToShow,
                                        });
                                    }, 100);
                                }
                            }} />
                        <div className="top-event-page-new">
                            <label className="heading-tepn">Bookmarked Vouchers</label>
                        </div>
                        {this.state.completeArrProducts.length > 0 && !this.state.noProductsHere && (
                            <VouchersNewMobile
                                productsWhole={this.state.completeArrProducts}
                                lastNodeEmpty={true}
                            />
                        )}
                        {this.state.noProductsHere && (
                            <ReactFullpage
                                onLeave={this.onLeave.bind(this)}
                                afterLoad={this.afterLoad.bind(this)}
                                normalScrollElements=".posts-detailed-popup, .charity-confirmation-complete, .loaderareaduplicate, .bg-overlaymini, .mnicart-flyout-inner, .rightmenucompletewidthaddproduct, .rightmenucommentsaddproduct"
                                render={({ state, fullpageApi }) => {
                                    return (
                                        <div>
                                            <div className="section" id="sectionnotheight">
                                                <div
                                                    className="inner-shop-container normalimgscampaigns containerEmptyPage"
                                                    style={{
                                                        backgroundImage: `none`,
                                                        backgroundColor: "#285151",
                                                    }}
                                                >
                                                    <div className="container-contemptpage">
                                                        <h3>Geen resultaten</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }}
                            />
                        )}
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducers.user,
        product: state.campaignsReducer.product,
        bookmarked_things: state.campaignsReducer.bookmarked_things,
        global_campaigns: state.campaignsReducer.global_campaigns,
        global_values: state.globalReducers.global_values,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateUser: (user) => dispatch(updateUser(user)),
        updateProducts: (product) => dispatch(updateProducts(product)),
        updateBookmarkStuff: (bookmarked_things) =>
            dispatch(updateBookmarkStuff(bookmarked_things)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookmarkedThings);
