import React, { Component } from "react";
// import './index.css'
import LocationOnIcon from "@material-ui/icons/LocationOn";
import CloseIcon from "@material-ui/icons/Close";
import firebase from "../Config/Firebase";
import { connect } from "react-redux";
import "./index.css";
import RightMenuCompanyEvents from "./RightMenuCompanyEvents";
import { Link } from "react-router-dom";
import { history } from "../Config/Routes";
import HomeIcon from "@material-ui/icons/Home";
import RightMenuCompanyVouchers from "./RightMenuCompanyVouchers";
import { mergeAdminEvent } from "../Functions/globalFunctions";
import { checkForSubscribedExclusives } from "../Functions/checkForSubscribedExclusives";
// import { AiOutlineHome } from "react-icons/ai";

export class NavbarMenuMobileFooterStuff extends Component {
    constructor() {
        super();
        this.state = {
            black: false,
            popupright: false,
            popupitems: false,
            popupmarket: false,
            popupservices: false,
            popupcompanies: false,
            currentUserHere: "",
            allSellers: [],
            currentUserId: "",
            allSectors: [],
            availableMainSector: [],
            availableMainSectorVouchers: [],
            allSectors: [],
            availableMainSectorShop: [],
            allSectorsVouchers: [],
            filteredAllSectors: [],
            filteredAllSectorsFilter: [],
            allEvents: [],
            endDate: "",
            allCampaigns: [],
            extendOpportunity: false,
            alreadyBewared: [],
            filteredAllCategoriesAlbums: [],
            noFilterAlbumCategories: false,
            allCampaignsToFilter: [],
            availableMainSectorVoucherEvents: [],
            allCampaignsToFilterEvents: [],
            currentUserOfPopups: "",
            allProductsToFilter: [],
            path: null,
            popupVouchers: false,
            showEventsOnEmpty: false,
            allProductsAvailableToFilter: [],
            allAvailableGlobalPosts: [],
            allCampaignsToFilterVouchers: [],
        };
    }

    componentDidMount() {
        // window.addEventListener("popstate", () => {
        //   window.location.reload();
        // });
        this.setState({ path: null });
        const loaderId = document.getElementById("main-page-loader-id");
        loaderId.style.display = "none";

        let userid = localStorage.getItem("userid");
        this.setState({ currentUserId: userid });

        if (this.props.global_values) {
            let a = this.props.global_values;
            if (a) {
                if (a.showGlobalTimer) {
                    this.setState({ showEventsOnEmpty: true })
                } else {
                    this.setState({ showEventsOnEmpty: false })
                }
            }
        }

        if (this.props.global_campaigns) {
            let arrSecCheck = [];
            this.props.global_campaigns.map((i) => {
                arrSecCheck.push(i[1]);
            });

            let arrCompleteVouchers = [];
            let arrCompleteEvents = [];
            arrSecCheck.map((g) => {
                if (this.props.user) {
                    let filtered = this.props.user.filter((t) => {
                        return t.user_id === g.created_by
                    })
                    if (!filtered[0].profileActive) {
                        return false;
                    }
                }
                if (g.campaignType === "local_event") {
                    if (g.takeFromAdmin) {
                        if (this.props.user && userid) {
                            let filtered = this.props.user.filter((t) => {
                                return t.user_id === userid
                            })
                            if (filtered.length > 0) {
                                let a = filtered[0]
                                if (a.subscription) {
                                    if (a.subscription.status === "active") {
                                        return false;
                                    } else {
                                        if (checkForSubscribedExclusives(a)) {
                                            return false;
                                        }
                                    }
                                } else {
                                    if (checkForSubscribedExclusives(a)) {
                                        return false;
                                    }
                                }
                            }
                        }

                        let obj = mergeAdminEvent(g, this.props.global_campaigns)
                        g = Object.assign({}, obj)

                    }
                    let arrTimingsAvailable = [];
                    if (g.timings) {
                        g.timings.map((e) => {
                            if (e.isoStartTime && e.isoEndTime) {
                                let checkedInOfCurrentBlock = [];
                                if (g.checked_in_by) {
                                    Object.entries(g.checked_in_by).map((t) => {
                                        if (t[1].event_id === e.eventId) {
                                            checkedInOfCurrentBlock.push(t[1]);
                                        }
                                    });
                                }
                                let getCurrentDate = new Date();
                                if (getCurrentDate.getTime() < new Date(Date.parse(e.isoEndTime)).getTime()) {
                                    if (checkedInOfCurrentBlock.length < Number(e.personsAmountEvent)) {
                                        arrTimingsAvailable.push(e);
                                    }
                                }
                            }
                        });
                    }
                    if (arrTimingsAvailable.length > 0) {
                        if (g.active && !g.hideOnGlobalEvent && !g.eventForGuestUser) {
                            arrCompleteEvents.push(g);
                        }
                    }
                } else if (g.campaignType === "kortings_voucher") {
                    if (g.hideOnGlobal) {
                        return false
                    }
                    let objectedVerzilveredBy = [];
                    let num = 0;
                    if (g.verzilvered_by) {
                        objectedVerzilveredBy = Object.entries(g.verzilvered_by);
                        num = this.getVerzilveredQty(objectedVerzilveredBy);
                    }
                    if (g.inCartOf) {
                        num = num + Object.entries(g.inCartOf).length
                    }
                    let bewaaredArray = [];
                    if (!g.kortingsVoucherUnlimited) {
                        if (num + bewaaredArray.length < Number(g.number)) {
                            arrCompleteVouchers.push(g);
                        }
                    } else {
                        arrCompleteVouchers.push(g);
                    }
                } else if (g.campaignType === "web_shop" && !g.isoStartTime && !g.isoEndTime) {
                    if (g.hideOnGlobal) {
                        return false
                    }
                    let objectedVerzilveredBy = [];
                    let num = 0;
                    if (g.verzilvered_by) {
                        objectedVerzilveredBy = Object.entries(g.verzilvered_by);
                        num = this.getVerzilveredQty(objectedVerzilveredBy);
                    }
                    if (g.inCartOf) {
                        num = num + Object.entries(g.inCartOf).length
                    }
                    let bewaaredArray = [];
                    if (!g.voucherUnlimited) {
                        if (num + bewaaredArray.length < Number(g.number)) {
                            arrCompleteVouchers.push(g);
                        }
                    } else {
                        arrCompleteVouchers.push(g);
                    }
                } else if (g.campaignType === "web_shop" && g.isoStartTime && g.isoEndTime) {
                    if (g.hideOnGlobal) {
                        return false
                    }
                    let objectedVerzilveredBy = [];
                    let num = 0;
                    if (g.verzilvered_by) {
                        objectedVerzilveredBy = Object.entries(g.verzilvered_by);
                        num = this.getVerzilveredQty(objectedVerzilveredBy);
                    }
                    if (g.inCartOf) {
                        num = num + Object.entries(g.inCartOf).length
                    }
                    let bewaaredArray = [];
                    if (!g.voucherUnlimited) {
                        if (num + bewaaredArray.length < Number(g.number)) {
                            arrCompleteEvents.push(g);
                        }
                    } else {
                        arrCompleteEvents.push(g);
                    }
                }
            });

            let filteredMainSectorVouchers = arrCompleteVouchers.filter(
                (v, i, a) => a.findIndex((t) => t.main_sector === v.main_sector) === i
            );
            let filteredMainSectorEvents = arrCompleteEvents.filter(
                (v, i, a) => a.findIndex((t) => t.main_sector === v.main_sector) === i
            );
            let availableMainSectorVouchers = [];
            let availableMainSectorVouchersAll = [];
            let availableMainSectorVoucherEvents = [];
            let availableMainSectorVoucherEventsAll = [];

            filteredMainSectorVouchers.map((g) => {
                availableMainSectorVouchers.push(g.main_sector);
            });
            filteredMainSectorEvents.map((g) => {
                if (g.campaignType === "local_event" || g.campaignType === "web_shop") {
                    if (g.active) {
                        availableMainSectorVoucherEvents.push(g.main_sector);
                    }
                }
            });

            arrCompleteEvents.map((g) => {
                if (g.campaignType === "local_event" || g.campaignType === "web_shop") {
                    if (g.active) {
                        availableMainSectorVoucherEventsAll.push(g.main_sector);
                    }
                }
            });
            arrCompleteVouchers.map((g) => {
                if (g.campaignType === "kortings_voucher" || g.campaignType === "web_shop") {
                    if (g.active) {
                        availableMainSectorVouchersAll.push(g.main_sector);
                    }
                }
            });

            availableMainSectorVoucherEvents = availableMainSectorVoucherEventsAll.filter(
                (v, i, a) => a.findIndex((t) => t === v) === i
            );
            availableMainSectorVouchers = availableMainSectorVouchersAll.filter(
                (v, i, a) => a.findIndex((t) => t === v) === i
            );


            this.setState({
                availableMainSectorVoucherEvents,
                availableMainSectorVouchers,
                allCampaignsToFilterEvents: arrCompleteEvents,
                allCampaignsToFilterVouchers: arrCompleteVouchers,
            });

        }

        if (this.props.user) {
            if (this.props.user.length > 0) {
                let filtered = this.props.user.filter((t) => {
                    return t.user_id === userid;
                });
                if (filtered.length > 0) {
                    let currentUser = filtered[0];
                    if (currentUser) {
                        let followingLength = 0;
                        if (currentUser.following) {
                            followingLength = Object.entries(currentUser.following).length;
                        }
                        if (currentUser) {
                            currentUser.followingLength = followingLength;

                            this.setState({ currentUserHere: currentUser });
                        }
                    }
                }
            }
        }

        if (this.props.globalsectors) {
            this.setState({ allSectors: this.props.globalsectors });
        }

        firebase
            .database()
            .ref("users/" + userid)
            .on("value", (data) => {
                let a = data.val();
                let arrUsers = [];
                let currentUser = a;
                if (a) {
                    let followingLength = 0;
                    if (currentUser.following) {
                        followingLength = Object.entries(currentUser.following).length;
                    }
                    if (currentUser) {
                        currentUser.followingLength = followingLength;

                        this.setState({ currentUserHere: currentUser });
                    }
                }
            });
    }

    componentDidUpdate(prevProps) {
        // if (this.props.global_campaigns !== prevProps.global_campaigns) {
        //     let arrSecCheck = [];
        //     this.props.global_campaigns.map((i) => {
        //         arrSecCheck.push(i[1]);
        //     });
        //     let arrCompleteEvents = [];
        //     arrSecCheck.map((g) => {
        //         if (this.props.user) {
        //             let filtered = this.props.user.filter((t) => {
        //                 return t.user_id === g.created_by
        //             })
        //             if (!filtered[0].profileActive) {
        //                 return false;
        //             }
        //         }
        //         if (g.campaignType === "live_event") {
        //             if (g.takeFromVendor) {
        //                 return false;
        //             }
        //         }
        //         if (
        //             g.campaignType === "local_event"
        //         ) {
        //             let arrTimingsAvailable = [];
        //             if (g.timings) {
        //                 g.timings.map((e) => {
        //                     if (g.timings[0].isoStartTime && g.timings[0].isoEndTime) {
        //                         let checkedInOfCurrentBlock = [];
        //                         if (g.checked_in_by) {
        //                             Object.entries(g.checked_in_by).map((t) => {
        //                                 checkedInOfCurrentBlock.push(t[1]);
        //                             });
        //                         }
        //                         let getCurrentDate = new Date();
        //                         if (getCurrentDate.getTime() < new Date(Date.parse(g.timings[0].isoEndTime)).getTime()) {
        //                             if (checkedInOfCurrentBlock.length < Number(e.personsAmountEvent)) {
        //                                 arrTimingsAvailable.push(e);
        //                             }
        //                         }
        //                     }
        //                 });
        //             }
        //             if (arrTimingsAvailable.length > 0) {
        //                 if (g.active) {
        //                     arrCompleteEvents.push(g);
        //                 }
        //             }
        //         }
        //     });

        //     let filteredMainSectorEvents = arrCompleteEvents.filter(
        //         (v, i, a) => a.findIndex((t) => t.main_sector === v.main_sector) === i
        //     );
        //     let availableMainSectorVoucherEvents = [];
        //     let availableMainSectorVoucherEventsAll = [];


        //     filteredMainSectorEvents.map((g) => {
        //         if (
        //             g.campaignType === "local_event"
        //         ) {
        //             if (g.active) {
        //                 availableMainSectorVoucherEvents.push(g.main_sector);
        //             }
        //         }
        //     });
        //     arrCompleteEvents.map((g) => {
        //         if (
        //             g.campaignType === "local_event"
        //         ) {
        //             if (g.active) {
        //                 availableMainSectorVoucherEventsAll.push(g.main_sector);
        //             }
        //         }
        //     });
        //     availableMainSectorVoucherEvents = availableMainSectorVoucherEventsAll.filter(
        //         (v, i, a) => a.findIndex((t) => t === v) === i
        //     );


        //     this.setState({
        //         availableMainSectorVoucherEvents,
        //         allCampaignsToFilterEvents: arrCompleteEvents,
        //     });

        // }
        if (this.props.global_campaigns !== prevProps.global_campaigns) {
            let arrSecCheck = [];
            this.props.global_campaigns.map((i) => {
                arrSecCheck.push(i[1]);
            });

            let arrCompleteVouchers = [];
            let arrCompleteEvents = [];
            arrSecCheck.map((g) => {
                if (this.props.user) {
                    let filtered = this.props.user.filter((t) => {
                        return t.user_id === g.created_by
                    })
                    if (!filtered[0].profileActive) {
                        return false;
                    }
                }
                if (g.campaignType === "local_event") {
                    if (g.takeFromAdmin) {
                        let userid = localStorage.getItem("userid")
                        if (this.props.user && userid) {
                            let filtered = this.props.user.filter((t) => {
                                return t.user_id === userid
                            })
                            if (filtered.length > 0) {
                                let a = filtered[0]
                                if (a.subscription) {
                                    if (a.subscription.status === "active") {
                                        return false;
                                    } else {
                                        if (checkForSubscribedExclusives(a)) {
                                            return false;
                                        }
                                    }
                                } else {
                                    if (checkForSubscribedExclusives(a)) {
                                        return false;
                                    }
                                }
                            }
                        }

                        let obj = mergeAdminEvent(g, this.props.global_campaigns)
                        g = Object.assign({}, obj)
                    }
                    let arrTimingsAvailable = [];
                    if (g.timings) {
                        g.timings.map((e) => {
                            if (e.isoStartTime && e.isoEndTime) {
                                let checkedInOfCurrentBlock = [];
                                if (g.checked_in_by) {
                                    Object.entries(g.checked_in_by).map((t) => {
                                        if (t[1].event_id === e.eventId) {
                                            checkedInOfCurrentBlock.push(t[1]);
                                        }
                                    });
                                }
                                let getCurrentDate = new Date();
                                if (getCurrentDate.getTime() < new Date(Date.parse(e.isoEndTime)).getTime()) {
                                    if (checkedInOfCurrentBlock.length < Number(e.personsAmountEvent)) {
                                        arrTimingsAvailable.push(e);
                                    }
                                }
                            }
                        });
                    }
                    if (arrTimingsAvailable.length > 0) {
                        if (g.active && !g.hideOnGlobalEvent && !g.eventForGuestUser) {
                            arrCompleteEvents.push(g);
                        }
                    }
                } else if (g.campaignType === "kortings_voucher") {
                    if (g.hideOnGlobal) {
                        return false
                    }
                    let objectedVerzilveredBy = [];
                    let num = 0;
                    if (g.verzilvered_by) {
                        objectedVerzilveredBy = Object.entries(g.verzilvered_by);
                        num = this.getVerzilveredQty(objectedVerzilveredBy);
                    }
                    let bewaaredArray = [];
                    if (g.inCartOf) {
                        num = num + Object.entries(g.inCartOf).length
                    }
                    if (!g.kortingsVoucherUnlimited) {
                        if (num + bewaaredArray.length < Number(g.number)) {
                            arrCompleteVouchers.push(g);
                        }
                    } else {
                        arrCompleteVouchers.push(g);
                    }
                } else if (g.campaignType === "web_shop" && !g.isoStartTime && !g.isoEndTime) {
                    if (g.hideOnGlobal) {
                        return false
                    }
                    let objectedVerzilveredBy = [];
                    let num = 0;
                    if (g.verzilvered_by) {
                        objectedVerzilveredBy = Object.entries(g.verzilvered_by);
                        num = this.getVerzilveredQty(objectedVerzilveredBy);
                    }
                    let bewaaredArray = [];
                    if (g.inCartOf) {
                        num = num + Object.entries(g.inCartOf).length
                    }
                    if (!g.voucherUnlimited) {
                        if (num + bewaaredArray.length < Number(g.number)) {
                            arrCompleteVouchers.push(g);
                        }
                    } else {
                        arrCompleteVouchers.push(g);
                    }
                } else if (g.campaignType === "web_shop" && g.isoStartTime && g.isoEndTime) {
                    if (g.hideOnGlobal) {
                        return false
                    }
                    let objectedVerzilveredBy = [];
                    let num = 0;
                    if (g.verzilvered_by) {
                        objectedVerzilveredBy = Object.entries(g.verzilvered_by);
                        num = this.getVerzilveredQty(objectedVerzilveredBy);
                    }
                    let bewaaredArray = [];
                    if (g.inCartOf) {
                        num = num + Object.entries(g.inCartOf).length
                    }
                    if (!g.voucherUnlimited) {
                        if (num + bewaaredArray.length < Number(g.number)) {
                            arrCompleteEvents.push(g);
                        }
                    } else {
                        arrCompleteEvents.push(g);
                    }
                }
            });

            let filteredMainSectorVouchers = arrCompleteVouchers.filter(
                (v, i, a) => a.findIndex((t) => t.main_sector === v.main_sector) === i
            );
            let filteredMainSectorEvents = arrCompleteEvents.filter(
                (v, i, a) => a.findIndex((t) => t.main_sector === v.main_sector) === i
            );
            let availableMainSectorVouchers = [];
            let availableMainSectorVouchersAll = [];
            let availableMainSectorVoucherEvents = [];
            let availableMainSectorVoucherEventsAll = [];

            filteredMainSectorVouchers.map((g) => {
                availableMainSectorVouchers.push(g.main_sector);
            });
            filteredMainSectorEvents.map((g) => {
                if (g.campaignType === "local_event" || g.campaignType === "web_shop") {
                    if (g.active) {
                        availableMainSectorVoucherEvents.push(g.main_sector);
                    }
                }
            });

            arrCompleteEvents.map((g) => {
                if (g.campaignType === "local_event" || g.campaignType === "web_shop") {
                    if (g.active) {
                        availableMainSectorVoucherEventsAll.push(g.main_sector);
                    }
                }
            });
            arrCompleteVouchers.map((g) => {
                if (g.campaignType === "kortings_voucher" || g.campaignType === "web_shop") {
                    if (g.active) {
                        availableMainSectorVouchersAll.push(g.main_sector);
                    }
                }
            });

            availableMainSectorVoucherEvents = availableMainSectorVoucherEventsAll.filter(
                (v, i, a) => a.findIndex((t) => t === v) === i
            );
            availableMainSectorVouchers = availableMainSectorVouchersAll.filter(
                (v, i, a) => a.findIndex((t) => t === v) === i
            );


            this.setState({
                availableMainSectorVoucherEvents,
                availableMainSectorVouchers,
                allCampaignsToFilterEvents: arrCompleteEvents,
                allCampaignsToFilterVouchers: arrCompleteVouchers,
            });

        }
    }

    getVerzilveredQty(e) {
        let arrItems = e;
        if (arrItems.length > 0) {
            Array.prototype.sum = function (prop) {
                var total = 0;
                for (var i = 0, _len = this.length; i < _len; i++) {
                    total += Number(this[i][1][prop].length);
                }
                return total;
            };
            return arrItems.sum("allDates");
        }
    }

    redirectSectorEvents(e) {
        if (this.state.availableMainSectorVoucherEvents.length > 0) {
            this.setState({ rightMenuEventCompanies: true });
        }
    }

    redirectSectorVouchers(e) {
        if (this.state.availableMainSectorVouchers.length > 0) {
            this.setState({ rightMenuVoucherCompanies: true });
        }
    }

    checkForBookmarkMenu() {
        if (this.state.allCampaignsToFilterEvents.length > 0 || this.state.allCampaignsToFilterVouchers.length > 0) {
            let getAvailableEvents = []
            let getAvailableVouchers = []
            if (this.state.allCampaignsToFilterEvents.length > 0) {
                this.state.allCampaignsToFilterEvents.map((t) => {
                    if (t.bookmarked_by) {
                        let filtered = Object.entries(t.bookmarked_by).filter((g) => {
                            return g[1].bookmarked_by === this.state.currentUserId
                        })
                        if (filtered.length > 0) {
                            getAvailableEvents.push(t)
                        }
                    }
                })
            }
            if (this.state.allCampaignsToFilterVouchers.length > 0) {
                this.state.allCampaignsToFilterVouchers.map((t) => {
                    if (t.bookmarked_by) {
                        let filtered = Object.entries(t.bookmarked_by).filter((g) => {
                            return g[1].bookmarked_by === this.state.currentUserId
                        })
                        if (filtered.length > 0) {
                            getAvailableVouchers.push(t)
                        }
                    }
                })
            }
            if (getAvailableEvents.length > 0 || getAvailableVouchers.length > 0) {
                return (
                    <Link
                        className="link-style"
                        to={`/bookmarked-things`}
                    >
                        <li
                            className={window.location.href.includes("/bookmarked-things") && "active"}
                        >
                            {(this.props.fromRoundIcon && window.location.href.includes("/bookmarked-things")) && (<div className="round-active-newmembers"></div>)}
                            {this.props.fromRoundIcon ? <label>Favorieten</label> : "Favorieten"}
                        </li>
                    </Link>
                );
            }
        }
    }

    openingPopupHidingThings() {
        const buttonsCenter = document.querySelectorAll(".center-nfw");

        buttonsCenter.forEach((box) => {
            box.style.zIndex = "99999999";
        });

        const buttonsLiveEvent = document.querySelectorAll(".live-event-topicons");

        buttonsLiveEvent.forEach((box) => {
            box.style.display = "none";
        });


        if (window.innerWidth < 1008) {
            const navbarMain = document.querySelectorAll(".navbarfullwidthMobile");

            navbarMain.forEach((box) => {
                box.style.display = "none";
            });
        }

        const buttonsCharity =
            document.querySelectorAll(".top-btncharity");

        buttonsCharity.forEach((box) => {
            box.style.visibility = "hidden";
        });
    }

    closingPopupBackingThings() {
        const buttonsCenter = document.querySelectorAll(".center-nfw");

        buttonsCenter.forEach((box) => {
            box.style.zIndex = "9999999999";
        });

        const buttonsLiveEvent = document.querySelectorAll(".live-event-topicons");

        buttonsLiveEvent.forEach((box) => {
            box.style.display = "flex";
        });

        if (window.innerWidth < 1008) {
            const navbarMain = document.querySelectorAll(".navbarfullwidthMobile");

            navbarMain.forEach((box) => {
                box.style.display = "block";
            });
        }

        const buttonsCharity =
            document.querySelectorAll(".top-btncharity");

        buttonsCharity.forEach((box) => {
            box.style.visibility = "visible";
        });
    }

    render() {
        // console.log("ReRendering occuring here", this.props.reRendered);
        // console.log("Redux State", this.props.reRendered);
        return (
            <>
                {this.state.currentUserId && (
                    <>
                        {this.state.currentUserHere.type === "company" ? (
                            <Link
                                to={`/company/${this.state.currentUserHere.user_name_id}`}
                                onClick={() => {
                                    localStorage.removeItem("onCurrentTab");
                                    localStorage.removeItem("onCurrentTabCompany");
                                    localStorage.removeItem("onStoriesActive");
                                }}
                            >
                                <li>
                                    <HomeIcon />
                                </li>
                            </Link>
                        ) : (
                            <Link
                                className="link-style"
                                to={`/member/${this.state.currentUserHere.user_name_id}`}
                                onClick={() => {
                                    localStorage.removeItem("onCurrentTab");
                                    localStorage.removeItem("onCurrentTabCompany");
                                    localStorage.removeItem("onStoriesActive");
                                }}
                            >
                                <li>
                                    <HomeIcon />
                                </li>
                            </Link>
                        )}
                        {this.props.showTrending && (<li className={window.location.href.includes("/trending") && "active"}
                            onClick={() => {
                                history.push(`/company/${this.props.currentUserMember.user_name_id}/trending`)
                            }}
                        >
                            {(this.props.fromRoundIcon && window.location.href.includes("/trending")) && (<div className="round-active-newmembers"></div>)}
                            {this.props.fromRoundIcon ? <label>Trending</label> : "Trending"}
                        </li>)}
                        {this.state.availableMainSectorVoucherEvents.length > 0 && (
                            <li
                                className={window.location.href.includes("/global-voucher-events/") && "active"}
                                onClick={() => {
                                    this.openingPopupHidingThings()
                                    this.setState({
                                        popupVouchers: false,
                                        popupShop: true,
                                        popupcompanies: false,
                                        popupright: false,
                                        popupExclusives: false,
                                    });
                                }}
                            >
                                {(this.props.fromRoundIcon && window.location.href.includes("/global-voucher-events/")) && (<div className="round-active-newmembers"></div>)}
                                {this.props.fromRoundIcon ? <label>Events</label> : "Events"}
                            </li>
                        )}
                        {this.state.availableMainSectorVouchers.length > 0 && (
                            <li
                                className={window.location.href.includes("/global-vouchers/") && "active"}
                                onClick={() => {
                                    this.openingPopupHidingThings()
                                    this.setState({
                                        popupVouchers: true,
                                        popupShop: false,
                                        popupcompanies: false,
                                        popupright: false,
                                        popupExclusives: false,
                                    });
                                }}
                            >
                                {(this.props.fromRoundIcon && window.location.href.includes("/global-vouchers/")) && (<div className="round-active-newmembers"></div>)}
                                {this.props.fromRoundIcon ? <label>Vouchers</label> : "Vouchers"}
                            </li>
                        )}
                        {this.state.availableMainSectorVoucherEvents.length === 0 && this.state.showEventsOnEmpty && (
                            <li onClick={() => {
                                history.push("/timerMainPage")
                            }}>
                                {this.props.fromRoundIcon ? <label>Events</label> : "Events"}
                                {/* Events */}
                            </li>
                        )}
                        {this.checkForBookmarkMenu()}
                    </>
                )}


                {/* Popup Shop */}
                {this.state.popupShop && (
                    <div
                        className="bg-overlaymini"
                        onClick={() => {
                            this.closingPopupBackingThings()
                            this.setState({
                                popupShop: false,
                                rightMenuEventCompanies: false,
                            });
                            document.getElementById("root").style.position = "static";
                        }}
                    ></div>
                )}
                <div
                    className={`mnicart-flyout-inner mnicartflyout-updated updated-mnicartflyout ${this.state.popupShop ? "open" : null
                        }`}
                    style={{ maxWidth: 400 }}
                >
                    <div
                        className="checkout-flyout-inner-content"
                        style={{ paddingBottom: 10 }}
                    >
                        <div
                            className="flyout-section"
                            style={{ backgroundColor: "#FBFBFB" }}
                        >
                            <div className="header-companysection">
                                {/* <img src={gift} /> */}
                                <p>ONTDEK ALLE EVENTS</p>
                                <CloseIcon
                                    onClick={() => {
                                        this.closingPopupBackingThings()
                                        this.setState({ popupShop: false });
                                        document.getElementById("root").style.position = "static";
                                    }}
                                />
                            </div>
                            <div className="container-companysectionright">
                                <div className="lists-companysectionright lcr-nmmfs-new">
                                    <ul>
                                        <li
                                            onClick={() => this.redirectSectorEvents()}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="captionvoucherstuff">
                                                <LocationOnIcon />
                                            </div>
                                            <label style={{ cursor: "pointer" }}>
                                                Bekijk alle aanbieders
                                            </label>
                                        </li>
                                        <p className="categoryheadlcsr">CATEGORIEEN</p>
                                        {this.state.allSectors.map((e) => {
                                            if (
                                                this.state.availableMainSectorVoucherEvents.includes(
                                                    e.sector_id
                                                )
                                            ) {
                                                return (
                                                    <Link
                                                        className="link-style"
                                                        to={`/global-voucher-events/${e.sector_id}`}
                                                        onClick={() => {
                                                            localStorage.removeItem("onCurrentSection");
                                                            this.setState({
                                                                path: `/global-voucher-events/${e.sector_id}`,
                                                                popupShop: false,
                                                            });
                                                            document.getElementById("root").style.position = "static";
                                                        }}
                                                        key={Math.random()}
                                                    >
                                                        <li>
                                                            {e.image ? (
                                                                <img src={e.image} />
                                                            ) : (
                                                                <img src="https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9" />
                                                            )}
                                                            <label>{e.sector_name}</label>
                                                        </li>
                                                    </Link>
                                                );
                                            }
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* Popup Shop */}
                {this.state.popupVouchers && (
                    <div
                        className="bg-overlaymini"
                        onClick={() => {
                            this.closingPopupBackingThings()
                            this.setState({
                                popupVouchers: false,
                                rightMenuVoucherCompanies: false,
                            });
                            document.getElementById("root").style.position = "static";
                        }}
                    ></div>
                )}
                <div
                    className={`mnicart-flyout-inner mnicartflyout-updated updated-mnicartflyout ${this.state.popupVouchers ? "open" : null
                        }`}
                    style={{ maxWidth: 400 }}
                >
                    <div
                        className="checkout-flyout-inner-content"
                        style={{ paddingBottom: 10 }}
                    >
                        <div
                            className="flyout-section"
                            style={{ backgroundColor: "#FBFBFB" }}
                        >
                            <div className="header-companysection">
                                {/* <img src={gift} /> */}
                                <p>ONTDEK ALLE VOUCHERS</p>
                                <CloseIcon
                                    onClick={() => {
                                        this.closingPopupBackingThings()
                                        this.setState({ popupVouchers: false });
                                        document.getElementById("root").style.position = "static";
                                    }}
                                />
                            </div>
                            <div className="container-companysectionright">
                                <div className="lists-companysectionright lcr-nmmfs-new">
                                    <ul>
                                        <li
                                            onClick={() => this.redirectSectorVouchers()}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="captionvoucherstuff">
                                                <LocationOnIcon />
                                            </div>
                                            <label style={{ cursor: "pointer" }}>
                                                Bekijk alle aanbieders
                                            </label>
                                        </li>
                                        <p className="categoryheadlcsr">CATEGORIEEN</p>
                                        {this.state.allSectors.map((e) => {
                                            if (
                                                this.state.availableMainSectorVouchers.includes(
                                                    e.sector_id
                                                )
                                            ) {
                                                return (
                                                    <Link
                                                        className="link-style"
                                                        to={`/global-vouchers/${e.sector_id}`}
                                                        onClick={() => {
                                                            localStorage.removeItem("onCurrentSection");
                                                            this.setState({
                                                                path: `/global-vouchers/${e.sector_id}`,
                                                                popupVouchers: false,
                                                            });
                                                            document.getElementById("root").style.position = "static";
                                                        }}
                                                        key={Math.random()}
                                                    >
                                                        <li>
                                                            {e.image ? (
                                                                <img src={e.image} />
                                                            ) : (
                                                                <img src="https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9" />
                                                            )}
                                                            <label>{e.sector_name}</label>
                                                        </li>
                                                    </Link>
                                                );
                                            }
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.rightMenuEventCompanies && (
                    <RightMenuCompanyEvents
                        closePopup={() => {
                            this.setState({ rightMenuEventCompanies: false });
                        }}
                    />
                )}

                {this.state.rightMenuVoucherCompanies && (
                    <RightMenuCompanyVouchers
                        closePopup={() => {
                            this.setState({ rightMenuVoucherCompanies: false });
                        }}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        reRendered: state.renderReducer.reRendered,
        user: state.authReducers.user,
        product: state.campaignsReducer.product,
        images: state.postsReducers.images,
        shopproducts: state.campaignsReducer.shopproducts,
        allVouchers: state.campaignsReducer.allVouchers,
        globalsectors: state.categoriesReducer.globalsectors,
        global_values: state.globalReducers.global_values,
        global_campaigns: state.campaignsReducer.global_campaigns,
        current_exclusives: state.exclusiveReducers.current_exclusives,
        friends_exclusives: state.exclusiveReducers.friends_exclusives,
        selected_exclusive: state.exclusiveReducers.selected_exclusive,
        categories_albums: state.categoriesReducer.categories_albums,
        categories_albums_available:
            state.categoriesReducer.categories_albums_available,
        available_posts: state.categoriesReducer.available_posts,
        products_new: state.campaignsReducer.products_new,
        bhodi_products: state.productsReducers.bhodi_products,
    };
};

export default connect(mapStateToProps)(NavbarMenuMobileFooterStuff);
