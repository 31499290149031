import React, { Component } from "react";
import AddIcon from "@material-ui/icons/Add";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import SortableTree from "react-sortable-tree";
import "react-sortable-tree/style.css";
import CloseIcon from "@material-ui/icons/Close";
import firebase from "../Config/Firebase";
import AdminNavbar from "./AdminNavbar";

export class VoucherTypeSorting extends Component {
  constructor(props) {
    super(props);

    this.state = {
      treeData: [],
      sectorModal: false,
      sectorModalCategory: false,
      sectorName: "",
      sectors: [],
      currentSector: "",
      currentSectorId: "",
      categoryName: "",
      currentSector: "",
      categoryId: "",
      all_categories: [],
      sortingTab: true,
      adjustingTab: false,
      completeMainTitles: [],
      arrOfInputs: [],
      categoryDesc: "",
    };
  }

  componentDidMount() {
    const loaderId = document.getElementById("main-page-loader-id");
    loaderId.style.display = "none";
    firebase
      .database()
      .ref("all_sectors")
      .on("value", (data) => {
        let a = data.val();
        let arr = [];
        if (a) {
          let objected = Object.entries(a);
          objected.map((g) => {
            arr.push(g[1]);
          });
          if (arr.length > 0) {
            if (this.state.currentSectorId) {
              this.setState({ sectors: arr });
            } else {
              this.setState({
                sectors: arr,
                currentSector: arr[0],
                currentSectorId: arr[0].sector_id,
              });
            }
            firebase
              .database()
              .ref("bhodi_voucher_type_category")
              .on("value", (data) => {
                let a = data.val();
                let arrSec = [];
                if (a) {
                  let objected = Object.entries(a);
                  objected.map((g) => {
                    if (g[0] === this.state.currentSectorId) {
                      arrSec = g[1];
                    }
                  });
                  let completeMainTitles = [];
                  let arrOfInputs = [];
                  if (this.state.arrOfInputs.length > 0) {
                    if (this.state.treeData.length !== arrSec.length) {
                      arrSec.map((g) => {
                        let obj = {
                          inputOpened: false,
                        };
                        arrOfInputs.push(obj);
                      });
                    } else {
                      arrOfInputs = this.state.arrOfInputs;
                    }
                  } else {
                    arrSec.map((g) => {
                      let obj = {
                        inputOpened: false,
                      };
                      arrOfInputs.push(obj);
                    });
                  }
                  this.setState({
                    all_categories: objected,
                    treeData: arrSec,
                    completeMainTitles,
                    arrOfInputs,
                  });
                } else {
                  this.setState({ all_categories: [] });
                }
              });
          }
        } else {
          this.setState({ sectors: [] });
        }
      });
  }

  createSector() {
    const { sectorName } = this.state;
    let timestamp = +new Date();
    let id = sectorName + "" + timestamp;
    let pushKey = firebase.database().ref("all_sectors").push().key;
    let obj = {
      sector_name: sectorName,
      sector_id: id,
      pushKey,
    };
    firebase
      .database()
      .ref("all_sectors/" + pushKey)
      .set(obj)
      .then(() => {
        document.getElementById("root").style.position = "static";
        document.getElementById("root").style.overflow = "auto";
        this.setState({ sectorName: "", sectorModal: false });
      });
  }

  createSectorCategory() {
    const { categoryName, categoryId, currentSectorId, categoryDesc } =
      this.state;
    let timestamp = +new Date();
    let id = categoryName + "" + timestamp;
    let pushKey = firebase
      .database()
      .ref("bhodi_voucher_type_category")
      .push().key;
    let obj = {
      title: categoryName,
      category_id: id,
      sector_id: currentSectorId,
      expanded: true,
      category_main_id: categoryId,
      category_des: categoryDesc,
    };
    this.state.treeData.push(obj);
    firebase
      .database()
      .ref("bhodi_voucher_type_category/" + currentSectorId)
      .set(this.state.treeData)
      .then(() => {
        document.getElementById("root").style.position = "static";
        document.getElementById("root").style.overflow = "auto";
        this.setState({
          categoryName: "",
          sectorModalCategory: false,
          categoryId: "",
          categoryDesc: "",
        });
      });
  }

  saveCategoryData() {
    const { currentSectorId } = this.state;
    firebase
      .database()
      .ref("bhodi_voucher_type_category/" + currentSectorId)
      .set(this.state.treeData);
  }

  render() {
    return (
      <div className="sorting-mainadmin">
        <AdminNavbar VoucherTypeSorting={true} />
        <div className="container-sma">
          <div className="top-container-sma">
            <div className="sector-tcsma">
              <select
                onChange={(e) => {
                  this.setState({
                    currentSector: JSON.parse(e.target.value),
                    currentSectorId: JSON.parse(e.target.value).sector_id,
                  });
                  let filtered = [];
                  this.state.all_categories.map((j) => {
                    if (j[0] === JSON.parse(e.target.value).sector_id) {
                      filtered = j[1];
                    }
                  });
                  this.setState({ treeData: filtered });
                }}
              >
                {this.state.sectors.length > 0 &&
                  this.state.sectors.map((g) => {
                    return (
                      <option
                        value={JSON.stringify(g)}
                        defaultChecked={
                          this.state.currentSectorId === g.sector_id
                        }
                      >
                        {g.sector_name}
                      </option>
                    );
                  })}
              </select>
              <ArrowDropDownIcon className="dropdown-select-tcsma" />
            </div>
            <div className="category-tcsma">
              <p
                onClick={() => {
                  document.getElementById("root").style.position = "fixed";
                  document.getElementById("root").style.overflow = "hidden";
                  this.setState({ sectorModal: true });
                }}
              >
                <AddIcon /> SECTOR{" "}
              </p>
              <p
                onClick={() => {
                  document.getElementById("root").style.position = "fixed";
                  document.getElementById("root").style.overflow = "hidden";
                  this.setState({ sectorModalCategory: true });
                }}
              >
                <AddIcon /> CATEGORIE{" "}
              </p>
              <p onClick={this.saveCategoryData.bind(this)}>SAVE</p>
            </div>
          </div>
          <div className="tabs-container-sma">
            <div className="category-tcsma">
              <p
                className={this.state.sortingTab && "active"}
                onClick={() =>
                  this.setState({ sortingTab: true, adjustingTab: false })
                }
              >
                SORTING
              </p>
              <p
                className={this.state.adjustingTab && "active"}
                onClick={() =>
                  this.setState({ adjustingTab: true, sortingTab: false })
                }
              >
                ADJUSTING
              </p>
            </div>
          </div>
          {this.state.sortingTab && (
            <div className="tree-inner" style={{ height: 1200, marginTop: 30 }}>
              <SortableTree
                treeData={this.state.treeData}
                onChange={(treeData) => {
                  this.setState({ treeData });
                }}
                maxDepth={1}
              />
            </div>
          )}
          {this.state.adjustingTab && (
            <div className="tree-inner" style={{ marginTop: 30 }}>
              <ul className="adjustng-ti">
                {this.state.treeData.map((h, i) => {
                  return (
                    <li
                      onClick={() => {
                        this.state.arrOfInputs[i].inputOpened = true;
                        this.setState({ arrOfInputs: this.state.arrOfInputs });
                      }}
                    >
                      {!this.state.arrOfInputs[i].inputOpened ? (
                        <font>{h.title}</font>
                      ) : (
                        <input
                          type="text"
                          value={h.title}
                          autoFocus
                          onBlur={() => {
                            this.state.arrOfInputs[i].inputOpened = false;
                            this.setState({
                              arrOfInputs: this.state.arrOfInputs,
                            });
                          }}
                          onChange={(e) => {
                            this.state.treeData[i].title = e.target.value;
                            const { currentSectorId } = this.state;
                            firebase
                              .database()
                              .ref(
                                "bhodi_voucher_type_category/" + currentSectorId
                              )
                              .set(this.state.treeData);
                          }}
                        />
                      )}{" "}
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
        <div
          className="modal-sector-sorting"
          style={{ right: this.state.sectorModal ? 0 : "-100%" }}
        >
          <CloseIcon
            className="close-mss"
            onClick={() => {
              document.getElementById("root").style.position = "static";
              document.getElementById("root").style.overflow = "auto";
              this.setState({ sectorModal: false });
            }}
          />
          <p>Create Sector</p>
          <div className="form-mss">
            <label>Sector name</label>
            <input
              type="text"
              value={this.state.sectorName}
              onChange={(e) => this.setState({ sectorName: e.target.value })}
            />
          </div>
          <div className="form-mss">
            <button onClick={this.createSector.bind(this)}>CREATE</button>
          </div>
        </div>
        <div
          className="modal-sector-overlay"
          style={{
            opacity: this.state.sectorModal ? 0.7 : 0,
            pointerEvents: this.state.sectorModal ? "all" : "none",
          }}
          onClick={() => {
            document.getElementById("root").style.position = "static";
            document.getElementById("root").style.overflow = "auto";
            this.setState({ sectorModal: false });
          }}
        ></div>
        <div
          className="modal-sector-sorting"
          style={{ right: this.state.sectorModalCategory ? 0 : "-100%" }}
        >
          <CloseIcon
            className="close-mss"
            onClick={() => {
              document.getElementById("root").style.position = "static";
              document.getElementById("root").style.overflow = "auto";
              this.setState({ sectorModalCategory: false });
            }}
          />
          <p>Create Category</p>
          <div className="form-mss">
            <label>Category name</label>
            <input
              type="text"
              value={this.state.categoryName}
              onChange={(e) => this.setState({ categoryName: e.target.value })}
            />
          </div>
          <div className="form-mss">
            <label>Category id</label>
            <input
              type="text"
              value={this.state.categoryId}
              onChange={(e) => this.setState({ categoryId: e.target.value })}
            />
          </div>
          <div className="form-mss">
            <label>Category Description</label>
            <input
              type="text"
              value={this.state.categoryDesc}
              onChange={(e) => this.setState({ categoryDesc: e.target.value })}
            />
          </div>
          <div className="form-mss">
            <button onClick={this.createSectorCategory.bind(this)}>
              CREATE
            </button>
          </div>
        </div>
        <div
          className="modal-sector-overlay"
          style={{
            opacity: this.state.sectorModalCategory ? 0.7 : 0,
            pointerEvents: this.state.sectorModalCategory ? "all" : "none",
          }}
          onClick={() => {
            document.getElementById("root").style.position = "static";
            document.getElementById("root").style.overflow = "auto";
            this.setState({ sectorModalCategory: false });
          }}
        ></div>
      </div>
    );
  }
}

export default VoucherTypeSorting;
