import firebase from "../Config/Firebase"

function notifyMe(currentUserLoggedIn, productsOfCompany) {
    return new Promise(async (res) => {
        let currentPathKey = firebase.database().ref(`users/${currentUserLoggedIn.user_id}/subscribed_for_products`).push().key
        let obj = {
            id: currentPathKey,
            productsOfCompany,
        }
        await firebase.database().ref(`users/${currentUserLoggedIn.user_id}/subscribed_for_products/${currentPathKey}`).set(obj)
        res()
    })
}

function mergeAdminEvent(toMergeIn, globalCampaigns) {
    const shallowCopy = globalCampaigns.map(obj => JSON.parse(JSON.stringify(obj)));
    const deepCopy = shallowCopy.map(obj => ({ ...obj }));

    let filteredAdminProduct = deepCopy.filter((myCamp) => {
        return myCamp[1].created_by === process.env.REACT_APP_ADMIN_ID && myCamp[1].campaignType === "local_event"
    })
    if (filteredAdminProduct.length > 0) {
        let adminProduct = [...filteredAdminProduct][0][1]
        delete adminProduct.checked_in_by
        delete adminProduct.inCartOf

        let obj = {
            ...adminProduct,
            ...toMergeIn,
        }
        if (obj.timings && obj.timings.length > 0) {
            obj.timings[0].personsAmountEvent = toMergeIn.stockAmount
        }

        return obj
    } else {
        return toMergeIn
    }
}

export { notifyMe, mergeAdminEvent }