import React, { Component } from "react";
import "./index.css";
import firebase from "../../../Config/Firebase";
import {
  updateUser,
  updateAlbums,
  updateImages,
  updateProducts,
  updateGlobalVouchers,
  updateAllSectors,
} from "../../../Redux/actions/authActions";
import { connect } from "react-redux";
import { Country, City } from "country-state-city";
import TimerGlobalPages from "../../../Components/TimerGlobalPages";
import EventsDesktopGrid from "../../../Components/GlobalEvents/EventsDesktopGrid";
import { checkForSubscribedExclusives } from "../../../Functions/checkForSubscribedExclusives";
import { mergeAdminEvent } from "../../../Functions/globalFunctions";

const initialState = {
  beschikbaar: true,
  popuprightToShow: false,
  titleProduct: "",
  normalPriceProduct: "",
  charityPriceProduct: "",
  descProduct: "",
  currentImageIndex: 0,
  arrImages: [],
  addCategory: false,
  gebruikers: false,
  addTimer: false,
  activateTimer: false,
  obj: {
    "category 1": {
      sub_category: "sub category 1",
    },
    "category 2": {
      sub_category: "sub category 2",
    },
  },
  isMain: "category 1",
  selectedMain: "category 1",
  selectedSub: "sub category 1",
  isSub: "sub category 1",
  daysIndex: 0,
  hoursIndex: -1,
  minutesIndex: -1,
  defaultQty: 1,
  allProducts: [],
  dropFile: [],
  filesName: [],
  productsIndex: 0,
  reachedProductsIndex: 0,
  hour: "",
  minutes: "",
  productActivated: true,
  firstTime: true,
  beforeUnloaded: false,
  currentUserProduct: true,
  newerProducts: false,
  seenProductsCheck: false,
  newProducts: [],
  seenProducts: [],
  currentProductsShowing: [],
  showAllProducts: false,
  arrCurrentProducts: [],
  cartData: [],
  selectCategoryPopup: false,
  uploadImagePopup: false,
  productDetailOpen: false,
  dayBigger: false,
  showingBookMarkProducts: false,
  allMonths: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  hours: [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23,
  ],
  productStatus: ["exclusive", "normal"],
  productStatusIndex: 0,
  sectors: [],
  currentSector: "",
  currentSectorId: "",
  categoryName: "",
  currentSector: "",
  all_categories: [],
  sub_categories: [],
  completeCategories: [],
  subCategoryPresent: true,
  currentUserHere: "",
  fullpageApi: "",
  scrollStart: true,
  scrollStop: false,
  eventDate: "",
  startHour: "",
  endHour: "",
  startMinute: "",
  endMinute: "",
  showTimerFieldsEmptyWork: false,
  counterMinute: 1,
  counterSecond: 59,
  currentActie: "",
  dateToday: "",
  endDateToday: "",
  muchDaysEvent: false,
  payOnline: false,
  isDesktop: false,
  currentProductDetailed: "",
  allProductsFiltered: [],
  noFilteredProducts: false,
  searchText: "",
  availableMainCategories: [],
  availableSectors: [],
  availableSubCategories: [],
  categoriesDropdown: [],
  subCategoriesCheck: [],
  subCategoriesDropdown: [],
  checkedToFilter: [],
  allProductsDup: [],
  allProductsFilteredDup: [],
  selectSectorsPopup: false,
  allProductsForSectors: [],
  allSectors: [],
  categoriesToFilter: [],
  latitude: "",
  longitude: "",
  toShowSections: [],
  firstSectionToShow: [],
  onCurrentPage: 0,
  allCampaigns: [],
  currentVoucherWork: "",
  activeSection: 0,
  hideFullpage: false,
  showOptionsToAdd: false,
  showDescriptionPopup: false,
  codegebruikpopup: false,
  codebewaarpopup: false,
  selectedDish: 0,
  alreadyBewared: [],
  mustShowAnimation: false,
  useOfVoucherOpen: false,
  exceptionsVoucherOpen: false,
  allVouchers: [],
  completeArrProducts: [],
  filteredProductsToShow: [],
  currentWeekEvents: [],
  comingWeekEvents: [],
  urlParams: null,
  currentUserId: '',
  currentUserCharityMember: false,
  currentUser: "",
  orderidToUpload: 0,
};

export class VoucherEventsMain extends Component {
  constructor() {
    super();
    this.state = {
      ...initialState,
    };
  }

  getVoucherEventInfo() {
    this.setState({ ...initialState });

    if (window.innerWidth < 1009) {
      this.setState({ isDesktop: false });
    } else {
      document.getElementById("root").style.position = "fixed";
      document.getElementById("root").style.overflow = "hidden";
      this.setState({ isDesktop: true });
    }

    window.addEventListener("resize", () => {
      if (window.innerWidth < 1009) {
        this.setState({ isDesktop: false });
      } else {
        this.setState({ isDesktop: true });
      }
    });

    if (this.props.global_values) {
      let a = this.props.global_values;
      if (a) {
        if (a.globalOverlay) {
          this.setState({ globalOverlay: a.globalOverlay });
        }
        if (a.showGlobalTimer) {
          this.setState({ showOverlay: true, timerDate: a.globalTimerDate, timerTime: a.globalTimerTime })
        } else {
          this.setState({ showOverlay: false })
        }
      }
    }

    let uid = localStorage.getItem("userid");
    this.setState({ currentUserId: uid });
    if (!uid) {
      this.setState({ isGuest: true });
    } else {
      this.setState({ isGuest: false });
    }

    // setTimeout(() => {
    //     let elems = document.getElementById("#fullpage")
    //     console.log(elems)
    //     elems[0].classList.remove("active")
    //     elems[1].className += " active"
    // }, 10);

    if (this.props.globalsectors) {
      if (this.props.globalsectors.length > 0) {
        this.setState({ allSectors: this.props.globalsectors });
      }
    }

    // firebase
    //   .database()
    //   .ref("all_carts/" + uid)
    //   .on("value", (data) => {
    //     let a = data.val();
    //     if (a) {
    //       let objected = Object.entries(a);
    //       this.setState({ cartData: objected });
    //     } else {
    //       this.setState({ cartData: [] });
    //     }
    //   });

    if (this.props.global_campaigns) {
      if (this.props.global_campaigns.length > 0) {
        let objected = this.props.global_campaigns;
        let arr = [];
        let allCampaignsCompletedToRender = [];
        let arrCurrent = [];
        let arrComing = [];
        objected.map((e) => {
          e[1].type = "campaign";
          e[1].currentImageIndex = 0;
          let arrImages = [];
          allCampaignsCompletedToRender.push(e[1]);
          if (e[1].desc) {
            // e[1].desc = e[1].desc.substr(0, 200)
            e[1].short_desc = e[1].desc.substr(0, 32);
          }
          if (e[1].campaignType !== "charity") {
            if (e[1].campaignType === "live_event") {
              e[1].title = e[1].descriptionLiveEvent;
              e[1].images = [e[1].placeHolderURL];
              e[1].imagesObjected = [
                { image: e[1].placeHolderURL, pushKey: e[1].pushKey },
              ];
            } else {
              e[1].images = [e[1].image];
              e[1].imagesObjected = [
                { image: e[1].image, pushKey: e[1].pushKey },
              ];
            }
          }
          // if (e[1].created_by === uid) {
          //     arr.push(e[1])
          // } else {
          if (e[1].active) {
            if (e[1].campaignType === "local_event") {
              if (e[1].takeFromVendor) {
                return false;
              }
              if (e[1].hideOnGlobalEvent) {
                return false;
              }
              if (e[1].eventForGuestUser) {
                return false;
              }
              if (e[1].takeFromAdmin) {
                if (this.props.user && uid) {
                  let filtered = this.props.user.filter((t) => {
                    return t.user_id === uid
                  })
                  if (filtered.length > 0) {
                    let a = filtered[0]
                    if (a.subscription) {
                      if (a.subscription.status === "active") {
                        return false;
                      } else {
                        if (checkForSubscribedExclusives(a)) {
                          return false;
                        }
                      }
                    } else {
                      if (checkForSubscribedExclusives(a)) {
                        return false;
                      }
                    }
                  }
                }

                let obj = mergeAdminEvent(e[1], this.props.global_campaigns)
                e[1] = Object.assign({}, obj)
              }
              let arrTimingsAvailable = [];
              if (e[1].timings) {
                e[1].timings.map((y) => {
                  let checkedInOfCurrentBlock = [];
                  if (e[1].checked_in_by) {
                    Object.entries(e[1].checked_in_by).map((t) => {
                      if (t[1].event_id === y.eventId) {
                        checkedInOfCurrentBlock.push(t[1]);
                      }
                    });
                  }
                  // let getEventDate = new Date(`${y.endDateEvent} ${y.endTimeEvent}`)
                  let getEventDate = new Date(y.startDateEvent);
                  let endTimeGood = y.startTimeEvent.concat(":00");
                  getEventDate.setHours(endTimeGood.split(":")[0]);
                  getEventDate.setMinutes(endTimeGood.split(":")[1]);
                  let getCurrentDate = new Date();
                  if (getCurrentDate.getTime() < new Date(Date.parse(e[1].timings[0].isoEndTime)).getTime()) {
                    // if (getCurrentDate.getTime() < getEventDate.getTime()) {
                    if (
                      checkedInOfCurrentBlock.length <
                      Number(y.personsAmountEvent)
                    ) {
                      arrTimingsAvailable.push(y);
                    }
                  }
                });
              }
              if (arrTimingsAvailable.length > 0) {
                arr.push(e[1]);
              }
            }
            if (e[1].campaignType === "web_shop" && e[1].isoEndTime) {
              if (e[1].hideOnGlobal) {
                return false;
              }
              let getCurrentDate = new Date();
              if (getCurrentDate.getTime() > new Date(Date.parse(e[1].isoEndTime)).getTime()) {
                return false;
              }
              let objectedVerzilveredBy = [];
              let num = 0;
              if (e[1].verzilvered_by) {
                objectedVerzilveredBy = Object.entries(e[1].verzilvered_by);
                num = this.getVerzilveredQty(objectedVerzilveredBy);
              }
              let bewaaredArray = [];
              if (e[1].inCartOf) {
                num = num + Object.entries(e[1].inCartOf).length
              }
              if (!e[1].voucherUnlimited) {
                if (num + bewaaredArray.length < Number(e[1].number)) {
                  arr.push(e[1]);
                }
              } else {
                arr.push(e[1]);
              }
            }
          }
          // }
        });
        let arrToMake = [];
        arr.map((t, i) => {
          if (
            t.campaignType === "local_event" || t.campaignType === "web_shop"
          ) {
            arrToMake.push(t);
          }
        });

        this.setState({
          allCampaigns: arrToMake,
        });
      }
    }

    // firebase.database().ref("all_vouchers").once("value", (data) => {
    //     let a = data.val()
    //     if (a) {
    //         if (!this.state.deletingItem) {
    //             if (this.state.beforeUnloaded) {
    //                 console.log("before unloaded")
    //             } else {
    //                 let arr = []
    //                 let allProductsCompleted = []
    //                 let allProductsCompletedToRender = []
    //                 let arrCurrentProducts = []
    //                 let objected = Object.entries(a)
    //                 objected.map((e) => {
    //                     let arrImages = []
    //                     let arrSec = []
    //                     e[1].type = "voucher"
    //                     if (e[1].images) {
    //                         let objectedImg = Object.entries(e[1].images)
    //                         objectedImg.map((f) => {
    //                             arrImages.push(f[1].image)
    //                             arrSec.push(f[1])
    //                         })
    //                         e[1].images = arrImages
    //                         e[1].imagesObjected = arrSec
    //                         e[1].currentImageIndex = 0
    //                         if (e[1].activated && e[1].qty !== 0) {
    //                             allProductsCompletedToRender.push(e[1])
    //                             if (e[1].desc) {
    //                                 e[1].short_desc = e[1].desc.substr(0, 150)
    //                                 // e[1].desc = e[1].desc.substr(0, 200)
    //                             }
    //                             this.props.user.map((f) => {
    //                                 if (f.user_id === e[1].created_by) {
    //                                     if (f.friends) {
    //                                         let objectedFriends = Object.entries(f.friends)
    //                                         objectedFriends.map((g) => {
    //                                             if (g[1].user_id === uid && e[1].qty !== 0) {
    //                                                 arr.push(e[1])
    //                                             }
    //                                         })
    //                                     }
    //                                     if (this.state.currentProductDetailed.pushKey === e[1].pushKey) {
    //                                         this.setState({ currentProductDetailed: e })
    //                                     }
    //                                     arrCurrentProducts.push(e[1])
    //                                 }
    //                             })
    //                         }
    //                     }
    //                 })
    //                 this.setState({ allVouchers: arrCurrentProducts })
    //             }
    //         }
    //     } else {
    //         this.setState({ allVouchers: [], allProductsDup: [], newProducts: [], noProducts: true, showingFeaturedProducts: true, firstTime: false, newerProducts: false, featuredProducts: true, currentUserProduct: true, arrCurrentProducts: [], })
    //     }
    // })
    let promise = new Promise((resolve, reject) => {
      // navigator.geolocation.getCurrentPosition((position) => {
      //     this.setState({ latitude: position.coords.latitude, longitude: position.coords.longitude })
      // });
      let latitude = localStorage.getItem("latitudeAfterLogin");
      let longitude = localStorage.getItem("longitudeAfterLogin");
      if (latitude) {
        this.setState({ latitude, longitude });
      }
      if (uid) {
        if (this.props.user) {
          if (this.props.user.length > 0) {
            let filtered = this.props.user.filter((y) => {
              return y.user_id === uid;
            });
            if (filtered.length > 0) {
              if (filtered[0].subscription) {
                if (filtered[0].subscription.status === "active") {
                  this.setState({ currentUserCharityMember: true });
                } else {
                  if (checkForSubscribedExclusives(filtered[0])) {
                    this.setState({ currentUserCharityMember: true });
                  }
                }
              } else {
                if (checkForSubscribedExclusives(filtered[0])) {
                  this.setState({ currentUserCharityMember: true });
                }
              }
              this.setState({ currentUserHere: filtered[0] });
              if (latitude && longitude) {
                setTimeout(() => {
                  resolve();
                }, 200);
              } else {
                if (filtered[0].city && filtered[0].country) {
                  let filteredCountry = Country.getAllCountries().filter(
                    (t) => {
                      if (
                        filtered[0].country === "Netherlands" ||
                        filtered[0].country === "Netherland"
                      ) {
                        return t.name === "Netherlands The";
                      } else {
                        return t.name === filtered[0].country;
                      }
                    }
                  );
                  if (filteredCountry.length > 0) {
                    let allCities = City.getCitiesOfCountry(
                      filteredCountry[0].isoCode
                    );
                    let filteredCityForLatLng = allCities.filter((t) => {
                      return t.name === filtered[0].city;
                    });
                    if (filteredCityForLatLng.length > 0) {
                      this.setState({
                        latitude: filteredCityForLatLng[0].latitude,
                        longitude: filteredCityForLatLng[0].longitude,
                      });
                    }
                  }
                  setTimeout(() => {
                    resolve();
                  }, 800);
                } else {
                  setTimeout(() => {
                    resolve();
                  }, 800);
                }
              }
            }
            // navigator.geolocation.getCurrentPosition((position) => {
            //     this.setState({ latitude: position.coords.latitude, longitude: position.coords.longitude })
            // });
            // let latitude = localStorage.getItem("latitudeAfterLogin")
            // let longitude = localStorage.getItem("longitudeAfterLogin")
            // if (latitude) {
            //     this.setState({ latitude, longitude })
            // }
          }
        }
      } else {
        setTimeout(() => {
          resolve();
        }, 200);
      }
    });

    promise.then(() => {
      let arrCurrentProducts = this.state.allVouchers.concat(
        this.state.allCampaigns
      );
      console.log(arrCurrentProducts, '/check here')
      let arrDistanced = [];
      if (arrCurrentProducts.length > 0) {
        arrCurrentProducts.map((y) => {
          let current = this.props.user.filter((u) => {
            return u.user_id === y.created_by;
          });
          let filteredHere = this.props.user.filter((y) => {
            return y.user_id === uid;
          });
          if (current[0].blocked) {
            let filtered = Object.entries(current[0].blocked).filter((t) => {
              return t[1].user_id === uid
            })
            if (filtered.length > 0) {
              return false;
            }
          }
          if (filteredHere[0].blocked) {
            let filtered = Object.entries(filteredHere[0].blocked).filter((t) => {
              return t[1].user_id === current[0].user_id
            })
            if (filtered.length > 0) {
              return false;
            }
          }
          if (current[0].latitude && current[0].longitude) {
            let lat1 = this.state.latitude;
            let lat2 = current[0].latitude;
            let lon1 = this.state.longitude;
            let lon2 = current[0].longitude;

            var rad = function (x) {
              return (x * Math.PI) / 180;
            };

            var R = 6378137; // Earth’s mean radius in meter
            var dLat = rad(lat2 - lat1);
            var dLong = rad(lon2 - lon1);
            var a =
              Math.sin(dLat / 2) * Math.sin(dLat / 2) +
              Math.cos(rad(lat1)) *
              Math.cos(rad(lat2)) *
              Math.sin(dLong / 2) *
              Math.sin(dLong / 2);
            var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            var d = R * c;
            y.distanceToSort = d;
          } else {
            if (current[0].country) {
              let filteredCountry = Country.getAllCountries().filter((t) => {
                if (
                  current[0].country === "Netherlands" ||
                  current[0].country === "Netherland"
                ) {
                  return t.name === "Netherlands The";
                } else {
                  return t.name.toLowerCase() === current[0].country.toLowerCase();
                }
              });
              if (filteredCountry.length > 0) {
                let allCities = City.getCitiesOfCountry(
                  filteredCountry[0].isoCode
                );
                if (current[0].city) {
                  let filteredCityForLatLng = allCities.filter((t) => {
                    return t.name.toLowerCase() === current[0].city.toLowerCase()
                  });
                  if (filteredCityForLatLng.length > 0) {
                    let lat1 = this.state.latitude;
                    let lat2 = Number(filteredCityForLatLng[0].latitude);
                    let lon1 = this.state.longitude;
                    let lon2 = Number(filteredCityForLatLng[0].longitude);

                    var rad = function (x) {
                      return (x * Math.PI) / 180;
                    };

                    var R = 6378137; // Earth’s mean radius in meter
                    var dLat = rad(lat2 - lat1);
                    var dLong = rad(lon2 - lon1);
                    var a =
                      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                      Math.cos(rad(lat1)) *
                      Math.cos(rad(lat2)) *
                      Math.sin(dLong / 2) *
                      Math.sin(dLong / 2);
                    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
                    var d = R * c;

                    y.distanceToSort = d;
                  } else {
                    y.distanceToSort = 9999999999;
                  }
                } else {
                  y.distanceToSort = 9999999999;
                }
              } else {
                y.distanceToSort = 9999999999;
              }
            } else {
              y.distanceToSort = 9999999999;
            }
          }
          if (current[0].profileActive) {
            arrDistanced.push(y);
          }
        });
        // arrDistanced = arrDistanced.sort((a, b) => {
        //     return b.distanceToSort - a.distanceToSort
        // })
        console.log(arrDistanced, '/check here')
        let filtered = arrDistanced.filter((y) => {
          return y.main_sector === this.props.match.params.sectorid;
        });

        filtered = filtered.sort((a, b) => {
          return a.distanceToSort - b.distanceToSort;
        });

        let allFiltered = arrDistanced.filter((y) => {
          return y.main_sector === this.props.match.params.sectorid;
        });

        allFiltered = allFiltered.sort((a, b) => {
          return a.distanceToSort - b.distanceToSort;
        });

        if (allFiltered.length > 0) {
          this.setState({
            allProducts: filtered,
            completeArrProducts: allFiltered,
            filteredProductsToShow: allFiltered,
            allProductsDup: arrCurrentProducts,
            allProductsForSectors: arrCurrentProducts,
            noProducts: false,
          });
        } else {
          if (this.props.user) {
            let toRedirect = this.props.user.filter((h) => {
              return h.user_id === uid;
            });
            if (toRedirect.length > 0) {
              if (toRedirect[0].type === "user") {
                window.location.href = `/member/${toRedirect[0].user_name_id}`;
              } else {
                window.location.href = `/company/${toRedirect[0].user_name_id}`;
              }
            } else {
              window.location.href = "/";
            }
          } else {
            window.location.href = "/";
          }
        }
      } else {
        if (this.props.user) {
          let toRedirect = this.props.user.filter((h) => {
            return h.user_id === uid;
          });
          if (toRedirect.length > 0) {
            if (toRedirect[0].type === "user") {
              window.location.href = `/member/${toRedirect[0].user_name_id}`;
            } else {
              window.location.href = `/company/${toRedirect[0].user_name_id}`;
            }
          } else {
            window.location.href = "/";
          }
        } else {
          window.location.href = "/";
        }
        this.setState({
          firstSectionToShow: [],
          noProducts: true,
          allProducts: [],
          completeArrProducts: [],
          filteredProductsToShow: [],
          toShowSections: [],
          allProductsForSectors: [],
          allProductsDup: [],
          urlParams: null,
        });
      }
    });
  }

  componentDidMount() {
    this.setState({ urlParams: this.props.match.params });
    this.getVoucherEventInfo();

    const loaderId = document.getElementById("main-page-loader-id");
    loaderId.style.display = "none";

    // }
    // firebase.database().ref("bewaar_vouchers/" + uid).on("value", (data) => {
    //     let a = data.val()
    //     if (a) {
    //         let objected = Object.entries(a)
    //         let arr = []
    //         objected.map((y) => {
    //             let productTimeStamp = new Date(Number(y[1].added_on))

    //             var futureDate = new Date();

    //             let diffInMilliSeconds = Math.abs(futureDate - productTimeStamp) / 1000;

    //             const days = Math.floor(diffInMilliSeconds / 86400);
    //             diffInMilliSeconds -= days * 86400;

    //             const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    //             diffInMilliSeconds -= hours * 3600;

    //             const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    //             diffInMilliSeconds -= minutes * 60;

    //             let difference = '';

    //             difference += (hours === 0 || hours === 1) ? `${hours}:` : `${hours}:`;

    //             difference += (minutes === 0 || hours === 1) ? `${minutes}` : `${minutes}`;

    //             if (days === 0) {
    //                 arr.push(y[1])
    //             }
    //         })
    //         if (arr.length > 0) {
    //             this.interval = setInterval(() => this.setState({ timeCurrent: Date.now() }), 1000);
    //         }
    //         this.setState({ alreadyBewared: arr })
    //     } else {
    //         this.setState({ alreadyBewared: [] })
    //     }
    // })
  }

  getVerzilveredQty(e) {
    let arrItems = e;
    if (arrItems.length > 0) {
      Array.prototype.sum = function (prop) {
        var total = 0;
        for (var i = 0, _len = this.length; i < _len; i++) {
          total += Number(this[i][1][prop].length);
        }
        return total;
      };
      return arrItems.sum("allDates");
    }
  }

  onLeave(origin, destination, direction) {
    if (Number(origin.index) > 0) {
      // this.state.arrToHideOrShow[origin.index - 1].show = false
      this.setState({ countsArray: [] });
    }
  }

  afterLoad(origin, destination, direction) {
    this.setState({ countsArray: [], onCurrentPage: destination.index });
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params !== this.state.urlParams) {
      this.getVoucherEventInfo();
      this.setState({ urlParams: this.props.match.params });
    }

    if (prevProps.global_values !== this.props.global_values) {
      let a = this.props.global_values;
      if (a) {
        if (a.showGlobalTimer) {
          let dateTimer = new Date(Number(a.globalTimerDate.split("-")[0]), Number(a.globalTimerDate.split("-")[1]) - 1, Number(a.globalTimerDate.split("-")[2]), Number(a.globalTimerTime.split(":")[0]), Number(a.globalTimerTime.split(":")[1]), 0).getTime()
          let dateYet = new Date().getTime()
          if (dateYet < dateTimer) {
            this.setState({ showOverlay: true, timerDate: a.globalTimerDate, timerTime: a.globalTimerTime })
          }
        } else {
          this.setState({ showOverlay: false })
        }
      }
    }

    let uid = localStorage.getItem("userid");
    if (this.props.global_campaigns !== prevProps.global_campaigns) {
      let allArrs = this.props.global_campaigns;
      let allKeysCurrent = [];
      let allKeysComingCampaigns = [];
      this.state.filteredProductsToShow.map((u) => {
        allKeysCurrent.push(u.pushKey);
      });
      allArrs.map((y) => {
        y[1].type = "campaign";
        y[1].currentImageIndex = 0;
        allKeysComingCampaigns.push(y[1].pushKey);
        if (y[1].desc) {
          // y[1].desc = y[1].desc.substr(0, 200)
          y[1].short_desc = y[1].desc.substr(0, 32);
        }
        if (y[1].campaignType !== "charity") {
          y[1].images = [y[1].image];
          y[1].imagesObjected = [{ image: y[1].image, pushKey: y[1].pushKey }];
        }
        if (y[1].campaignType === "local_event") {
          if (y[1].takeFromAdmin) {
            let obj = mergeAdminEvent(y[1], this.props.global_campaigns)
            y[1] = Object.assign({}, obj)
          }
          this.state.filteredProductsToShow.map((t, i) => {
            if (t.pushKey === y[1].pushKey) {
              if (!y[1].active) {
                y[1].showPopupNotLeft = true;
              } else {
                y[1].showPopupNotLeft = false;
              }
              this.state.filteredProductsToShow[i] = y[1];
            }
          });
        }
        if (y[1].campaignType === "web_shop") {
          this.state.filteredProductsToShow.map((t, i) => {
            if (t.pushKey === y[1].pushKey) {
              if (!y[1].active) {
                y[1].showPopupNotLeft = true;
              } else {
                y[1].showPopupNotLeft = false;
              }
              this.state.filteredProductsToShow[i] = y[1];
            }
          });
        }
      });
      this.setState({
        allProducts: this.state.allProducts,
        firstSectionToShow: this.state.firstSectionToShow,
      });
    }
    if (this.props.user !== prevProps.user) {
      if (this.props.user.length > 0) {
        let filtered = this.props.user.filter((y) => {
          return y.user_id === uid;
        });
        if (filtered.length > 0) {
          this.setState({ currentUserHere: filtered[0] });
        }
        // navigator.geolocation.getCurrentPosition((position) => {
        //     this.setState({ latitude: position.coords.latitude, longitude: position.coords.longitude })
        // });
        let latitude = localStorage.getItem("latitudeAfterLogin");
        let longitude = localStorage.getItem("longitudeAfterLogin");
        if (latitude) {
          this.setState({ latitude, longitude });
        }
      }
    }
  }

  getDayName(num) {
    if (num === 0) {
      return `Zondag`;
    } else if (num === 1) {
      return `Maandag`;
    } else if (num === 2) {
      return `Dinsdag`;
    } else if (num === 3) {
      return `Woensdag`;
    } else if (num === 4) {
      return `Donderdag`;
    } else if (num === 5) {
      return `Vrijdag`;
    } else if (num === 6) {
      return `Zaterdag`;
    }
  }

  getMonthName(i) {
    i = Number(i);
    if (i === 1) {
      return "Januari";
    } else if (i === 2) {
      return "Februari";
    } else if (i === 3) {
      return "Maart";
    } else if (i === 4) {
      return "April";
    } else if (i === 5) {
      return "Mei";
    } else if (i === 6) {
      return "Juni";
    } else if (i === 7) {
      return "Juli";
    } else if (i === 8) {
      return "Augustus";
    } else if (i === 9) {
      return "September";
    } else if (i === 10) {
      return "Oktober";
    } else if (i === 11) {
      return "November";
    } else if (i === 12) {
      return "December";
    }
  }

  render() {
    return (
      this.state.showOverlay ? <TimerGlobalPages timerDate={this.state.timerDate} timerTime={this.state.timerTime} closeScreen={() => {
        this.setState({ showOverlay: false })
      }} /> : this.state.filteredProductsToShow.length > 0 ? <EventsDesktopGrid
        firstSectionToShow={this.state.firstSectionToShow}
        allProducts={this.state.allProducts}
        completeArrProducts={this.state.completeArrProducts}
        filteredProductsToShow={this.state.filteredProductsToShow}
        toShowSections={this.state.toShowSections}
        allProductsDup={this.state.allProductsDup}
        allProductsForSectors={this.state.allProductsForSectors}
        currentSector={this.props.match.params.sectorid}
        noCityFiltered={true}
      /> : <div></div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducers.user,
    album: state.postsReducers.album,
    images: state.postsReducers.images,
    product: state.campaignsReducer.product,
    globalvouchers: state.campaignsReducer.globalvouchers,
    globalsectors: state.categoriesReducer.globalsectors,
    global_campaigns: state.campaignsReducer.global_campaigns,
    global_values: state.globalReducers.global_values,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: (user) => dispatch(updateUser(user)),
    updateAlbums: (album) => dispatch(updateAlbums(album)),
    updateImages: (images) => dispatch(updateImages(images)),
    updateProducts: (product) => dispatch(updateProducts(product)),
    updateGlobalVouchers: (vouchers) =>
      dispatch(updateGlobalVouchers(vouchers)),
    updateAllSectors: (globalsectors) =>
      dispatch(updateAllSectors(globalsectors)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VoucherEventsMain);
