import React, { Component } from "react";
import CloseIcon from "@material-ui/icons/Close";
import firebase from "../Config/Firebase";
import {
    updateUser,
    updateGuestUserCart,
    updateGuestUserCartExtendOpportunity,
    updateGuestUserCartTime,
} from "../Redux/actions/authActions";
import { connect } from "react-redux";
import CheckIcon from "@material-ui/icons/Check";
import AddIcon from "@material-ui/icons/Add";
import { isDesktop } from "react-device-detect";
import NumberFormatter from "./NumberFormatter";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { checkForSubscribedExclusives } from "../Functions/checkForSubscribedExclusives";
import { AiFillStar, AiOutlineEdit, AiOutlineStar } from "react-icons/ai";
import { bookmarkCurrentProduct, unBookmarkCurrentProduct } from "../Functions/shopFunctions";
import { history } from "../Config/Routes";
import KortingsVoucherTimer from "../Containers/MemberHome/KortingsVoucherTimer";
import ButtonTimerCampaign from "./ButtonTimerCampaign";
import { BsThreeDots } from "react-icons/bs";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { ChevronLeft } from "@material-ui/icons";
import { VscInfo } from "react-icons/vsc";
import { RiArrowDownSLine, RiUserFollowLine, RiUserUnfollowLine } from "react-icons/ri";
import DOMPurify from "dompurify";
import { followUnfollowCompany } from "../Functions/friendFunctions";
import { mergeAdminEvent } from "../Functions/globalFunctions";

export class VoucherEventsNewGlobal extends Component {
    constructor() {
        super();
        this.state = {
            activateTimer: false,
            obj: {
                "category 1": {
                    sub_category: "sub category 1",
                },
                "category 2": {
                    sub_category: "sub category 2",
                },
            },
            minutes: "",
            arrCurrentProducts: [],
            completeCategories: [],
            subCategoryPresent: true,
            currentUser: "",
            orderidToUpload: 0,
            verzilverPopup: false,
            verzilverPopupGreen: false,
            isDesktop: true,
            currentProductDetailed: "",
            activeSection: 0,
            currentUserHere: "",
            selectedPersons: 2,
            guestUserCart: [],
            showPlaceholderLocalEvent: true,
            notShowTimerGuestLocalEvent: false,
            currentUserId: "",
            currentUserCharityMember: false,
            onCurrentProductComments: "",
            allCampaigns: [],
            currentCampaignInfoOpen: "",
            defaultLoad: 10,
        };
        // window.addEventListener("beforeunload", function() { debugger; }, false)
    }

    componentDidMount() {
        const loaderId = document.getElementById("main-page-loader-id");
        loaderId.style.display = "none";


        if (this.props.global_values) {
            let a = this.props.global_values;
            if (a) {
                let kortingsVoucherInfoMobile = ""
                let localEventInfoMobile = ""

                if (a.localEventInfoMobile) {
                    localEventInfoMobile = a.localEventInfoMobile
                }
                if (a.kortingsVoucherInfoMobile) {
                    kortingsVoucherInfoMobile = a.kortingsVoucherInfoMobile
                }

                this.setState({
                    kortingsVoucherInfoMobile,
                    localEventInfoMobile,
                });
            }
        }

        window.onscroll = () => {
            if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 1900) && this.state.allCampaigns.length > this.state.defaultLoad && !this.state.showLoader) {
                this.setState({ showLoader: true })
                setTimeout(() => {
                    this.setState({ defaultLoad: this.state.defaultLoad + 10, showLoader: false })
                }, 3000);
            }
        }

        firebase.database().ref("current_order_id").on("value", (data) => {
            let a = data.val()
            if (a) {
                this.setState({ orderidToUpload: Number(a) });
            }
        })

        if (this.props.guest_user_cart) {
            this.setState({ guestUserCart: this.props.guest_user_cart });
        }

        let uid = localStorage.getItem("userid");
        this.setState({ currentUserId: uid });

        if (this.props.user) {
            if (this.props.user.length > 0) {
                let filtered = this.props.user.filter((y) => {
                    return y.user_id === uid;
                });
                if (filtered.length > 0) {
                    let a = filtered[0];
                    if (a) {
                        if (a.subscription) {
                            if (a.subscription.status === "active") {
                                this.setState({ currentUserCharityMember: true });
                            } else {
                                if (checkForSubscribedExclusives(a)) {
                                    this.setState({ currentUserCharityMember: true })
                                }
                            }
                        } else {
                            if (checkForSubscribedExclusives(a)) {
                                this.setState({ currentUserCharityMember: true })
                            }
                        }
                        this.setState({
                            currentUserHere: a,
                            currentUserId: uid,
                            isGuestUser: false,
                        });
                    }
                }
            }
        }

        let allCampaigns = []

        this.props.allCampaigns.map((e) => {
            let obj = {
                ...e,
            }
            if (obj.campaignType === "local_event") {
                obj.selectedTimeIndex = 0
                obj.reserved_event_id = 1
                obj.selectedTiming = false
                obj.dropdownOpenTiming = false
            }
            allCampaigns.push(obj)
        })

        this.setState({
            allCampaigns,
        });
    }

    // uses etc

    getUseOfVoucherKortingsVoucher(e) {
        if (this.props.user) {
            let filtered = this.props.user.filter((g) => {
                return g.user_id === e.created_by;
            });
            if (filtered.length > 0) {
                if (filtered[0].useOfVoucherKortingsVoucher) {
                    return (
                        <label onClick={() => {
                            this.setState({
                                useOfVouchersPopup: true,
                                currentUseOfVoucher: filtered[0].useOfVoucherKortingsVoucher,
                            });
                        }}
                        >
                            GEBRUIK
                        </label>
                    );
                }
            }
        }
    }

    getUseOfVoucherKortingsVoucherMobile(e) {
        let creator = this.props.user.filter((j) => {
            return j.user_id === e.created_by;
        });
        if (creator.length > 0) {
            if (creator[0].useOfVoucherKortingsVoucher) {
                return (
                    <>
                        <p
                            className="desc-heading-asp"
                            style={{ fontSize: 11, textTransform: "none", marginTop: 30 }}
                        >
                            GEBRUIK
                        </p>
                        {creator[0].useOfVoucherKortingsVoucher ? (<p className="desc-content-asp" onClick={() => {
                            this.setState({
                                headingOdiMain: "GEBRUIK",
                                openDetailedInner: true,
                                paraOdiMain: creator[0].useOfVoucherKortingsVoucher
                            })
                        }}>
                            {creator[0].useOfVoucherKortingsVoucher.substr(0, 130)}...
                        </p>) : (<p className="desc-content-asp">
                            {creator[0].useOfVoucherKortingsVoucher}
                        </p>)}
                    </>
                );
            }
        }
    }

    getUseOfVoucherWebShopMobile(e) {
        let creator = this.props.user.filter((j) => {
            return j.user_id === e.created_by;
        });
        if (creator.length > 0) {
            if (creator[0].useOfVoucherWebShop) {
                return (
                    <>
                        <p
                            className="desc-heading-asp"
                            style={{ fontSize: 11, textTransform: "none", marginTop: 30 }}
                        >
                            GEBRUIK
                        </p>
                        {creator[0].useOfVoucherWebShop ? (<p className="desc-content-asp" onClick={() => {
                            this.setState({
                                headingOdiMain: "GEBRUIK",
                                openDetailedInner: true,
                                paraOdiMain: creator[0].useOfVoucherWebShop
                            })
                        }}>
                            {creator[0].useOfVoucherWebShop.substr(0, 130)}...
                        </p>) : (<p className="desc-content-asp">
                            {creator[0].useOfVoucherWebShop}
                        </p>)}
                    </>
                );
            }
        }
    }

    getAboutKortingsVoucherMobile() {
        if (this.state.kortingsVoucherInfoMobile) {
            return (
                <>
                    <p
                        className="desc-heading-asp"
                        style={{ fontSize: 11, textTransform: "none", marginTop: 30 }}
                    >
                        INFO
                    </p>
                    {this.state.kortingsVoucherInfoMobile.length > 130 ? (<p className="desc-content-asp" onClick={() => {
                        this.setState({
                            headingOdiMain: "INFO",
                            openDetailedInner: true,
                            paraOdiMain: this.state.kortingsVoucherInfoMobile
                        })
                    }}>
                        {this.state.kortingsVoucherInfoMobile.substr(0, 130)}...
                    </p>) : (<p className="desc-content-asp">
                        {this.state.kortingsVoucherInfoMobile}
                    </p>)}
                </>
            );
        }
    }

    getAboutWebShopMobile() {
        if (this.state.webShopInfoMobile) {
            return (
                <>
                    <p
                        className="desc-heading-asp"
                        style={{ fontSize: 11, textTransform: "none", marginTop: 30 }}
                    >
                        INFO
                    </p>
                    {this.state.webShopInfoMobile.length > 130 ? (<p className="desc-content-asp" onClick={() => {
                        this.setState({
                            headingOdiMain: "INFO",
                            openDetailedInner: true,
                            paraOdiMain: this.state.webShopInfoMobile
                        })
                    }}>
                        {this.state.webShopInfoMobile.substr(0, 130)}...
                    </p>) : (<p className="desc-content-asp">
                        {this.state.webShopInfoMobile}
                    </p>)}
                </>
            );
        }
    }

    getfilelinkMobile(e) {
        if (this.props.user) {
            let filtered = this.props.user.filter((g) => {
                return g.user_id === e.created_by;
            });
            if (filtered.length > 0) {
                if (filtered[0].requirement_file) {
                    return (
                        <p
                            className="desc-heading-asp"
                            style={{ fontSize: 11, textTransform: "none", marginTop: 30 }}
                            onClick={() => {
                                window.open(
                                    filtered[0].requirement_file,
                                    "_blank" // <- This is what makes it open in a new window.
                                );
                            }}
                        >
                            VOORWAARDEN
                        </p>
                    );
                } else if (filtered[0].termsText) {
                    return (
                        <>
                            <p
                                className="desc-heading-asp"
                                style={{ fontSize: 11, textTransform: "none", marginTop: 30 }}
                            >
                                VOORWAARDEN
                            </p>
                            {filtered[0].termsText.length > 130 ? (<p className="desc-content-asp" onClick={() => {
                                this.setState({
                                    headingOdiMain: "VOORWAARDEN",
                                    openDetailedInner: true,
                                    paraOdiMain: filtered[0].termsText
                                })
                            }}>
                                {filtered[0].termsText.substr(0, 130)}...
                            </p>) : (<p className="desc-content-asp">
                                {filtered[0].termsText}
                            </p>)}
                        </>
                    );
                }
            }
        }
    }

    getVerzilveredQty(e) {
        let arrItems = e;
        if (arrItems.length > 0) {
            Array.prototype.sum = function (prop) {
                var total = 0;
                for (var i = 0, _len = this.length; i < _len; i++) {
                    total += Number(this[i][1][prop].length);
                }
                return total;
            };
            return arrItems.sum("allDates");
        }
    }

    shouldComponentUpdate(nextProps) {
        if (this.props.shouldAlwaysUpdate) {
            return true;
        } else if (nextProps.allCampaigns !== this.props.allCampaigns && this.state.allCampaigns.length > 0) {
            return false;
        } else {
            return true;
        }
    }

    componentDidUpdate(prevProps) {

        if (this.props.guest_user_cart) {
            if (prevProps.guest_user_cart !== this.props.guest_user_cart) {
                this.setState({ guestUserCart: this.props.guest_user_cart });
            }
        }

        if (this.props.user !== prevProps.user) {
            let uid = localStorage.getItem("userid");
            if (this.props.user.length > 0) {
                let filtered = this.props.user.filter((j) => {
                    return j.user_id === uid;
                });
                if (filtered.length > 0) {
                    if (this.state.currentUserHere !== filtered[0]) {
                        this.setState({ currentUserHere: filtered[0] });
                    }
                }
            }
        }

        if (this.props.global_campaigns !== prevProps.global_campaigns) {
            if (this.state.allCampaigns.length > 0) {
                this.state.allCampaigns.map((t, i) => {
                    let filtered = this.props.global_campaigns.filter((g) => {
                        return g[1].pushKey === t.pushKey;
                    });
                    if (filtered.length > 0) {
                        if (t.campaignType === "local_event") {
                            if (filtered[0][1].takeFromAdmin) {
                                let obj = mergeAdminEvent(filtered[0][1], this.props.global_campaigns)
                                filtered[0][1] = Object.assign({}, obj)
                            }
                            let obj = {
                                ...filtered[0][1]
                            }

                            if (t.selectedTimeIndex) {
                                obj.selectedTimeIndex = t.selectedTimeIndex
                            } else {
                                obj.selectedTimeIndex = 0
                            }
                            if (t.dropdownOpenTiming) {
                                obj.dropdownOpenTiming = t.dropdownOpenTiming
                            }
                            if (t.selectedTiming) {
                                obj.selectedTiming = t.selectedTiming
                            }
                            if (t.reserved_event_id) {
                                obj.reserved_event_id = t.reserved_event_id
                            }
                            this.state.allCampaigns[i] = obj;

                        } else {
                            this.state.allCampaigns[i] = filtered[0][1];
                        }
                    }
                });
                this.setState({ allCampaigns: this.state.allCampaigns });
            }
        }
    }

    checkForVerzilverKortingsVoucher(e, index) {
        if (this.state.currentUserHere) {
            if ((this.state.currentUserHere.subscription && this.state.currentUserHere.subscription.status === "active") || checkForSubscribedExclusives(this.state.currentUserHere)) {
                if (e.verzilvered_by) {
                    let objected = Object.entries(e.verzilvered_by);
                    let filtered = objected.filter((h) => {
                        return h[1].user_id === this.state.currentUserId;
                    });
                    if (filtered.length > 0) {
                        return (
                            <button
                                style={{
                                    backgroundColor: "#C17F6B",
                                    display: "inline-flex",
                                    alignItems: "center",
                                }}
                                className="pinkcolorbutton"
                            >
                                <CheckIcon
                                    style={{ fontSize: 18, marginRight: 5, marginLeft: 0 }}
                                />{" "}
                                {window.innerWidth > 1008 ? `In orderoverzicht` : `Overzicht`}
                            </button>
                        );
                    }
                }
                if (!e.kortingsVoucherUnlimited) {
                    let bewaaredArray = [];
                    let num = 0;
                    if (e.verzilvered_by) {
                        let objectedCheckedIn = Object.entries(e.verzilvered_by);
                        num = this.getVerzilveredQty(objectedCheckedIn);
                    }
                    if (e.inCartOf) {
                        let objectedInCartOf = Object.entries(e.inCartOf);
                        num = num + objectedInCartOf.length;
                    }
                    if (num + bewaaredArray.length < Number(e.number)) {
                        if (window.innerWidth > 1008) {
                            if (e.verzilvered_by) {
                                let objected = Object.entries(e.verzilvered_by);
                                let filtered = objected.filter((h) => {
                                    return h[1].user_id === this.state.currentUserId;
                                });
                                if (filtered.length > 0) {
                                    return (
                                        <button
                                            className="pinkcolorbutton"
                                            style={{
                                                backgroundColor: "#C17F6B",
                                                display: "inline-flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <CheckIcon
                                                style={{ fontSize: 18, marginRight: 5, marginLeft: 0 }}
                                            />{" "}
                                            {window.innerWidth > 1008
                                                ? `In orderoverzicht`
                                                : `Overzicht`}
                                        </button>
                                    );
                                } else {
                                    return (
                                        <button onClick={this.verzilverKortingsVoucher.bind(this, e)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                            Valideren{" "}
                                        </button>
                                    );
                                }
                            } else {
                                return (
                                    <button onClick={this.verzilverKortingsVoucher.bind(this, e)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                        Valideren
                                    </button>
                                );
                            }
                        } else {
                            if (
                                this.state.currentUserHere.stripe_id &&
                                !this.state.userNotSubscribed
                            ) {
                                if (e.verzilvered_by) {
                                    let objected = Object.entries(e.verzilvered_by);
                                    let filtered = objected.filter((h) => {
                                        return h[1].user_id === this.state.currentUserId;
                                    });
                                    if (filtered.length > 0) {
                                        return (
                                            <button
                                                style={{
                                                    backgroundColor: "#C17F6B",
                                                    display: "inline-flex",
                                                    alignItems: "center",
                                                }}
                                                className="pinkcolorbutton"
                                            >
                                                <CheckIcon
                                                    style={{
                                                        fontSize: 18,
                                                        marginRight: 5,
                                                        marginLeft: 0,
                                                    }}
                                                />{" "}
                                                {window.innerWidth > 1008
                                                    ? `In orderoverzicht`
                                                    : `Overzicht`}
                                            </button>
                                        );
                                    } else {
                                        return (
                                            <button onClick={this.verzilverKortingsVoucher.bind(this, e)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                                Valideren{" "}
                                            </button>
                                        );
                                    }
                                } else {
                                    return (
                                        <button onClick={this.verzilverKortingsVoucher.bind(this, e)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                            Valideren{" "}
                                        </button>
                                    );
                                }
                            } else {
                                return (
                                    <button onClick={this.verzilverKortingsVoucher.bind(this, e)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                        Valideren
                                    </button>
                                    // <Link to="/charity" className="link-style">
                                    //   <button
                                    //   // Commented by me
                                    //   //
                                    //   >
                                    //     Register
                                    //   </button>
                                    // </Link>
                                );
                            }
                        }
                    } else {
                        return (
                            <button
                                style={{ backgroundColor: "#C17F6B" }}
                                className="pinkcolorbutton"
                            >
                                Uitverkocht
                            </button>
                        );
                    }
                } else {
                    if (window.innerWidth > 1008) {
                        if (e.verzilvered_by) {
                            let objected = Object.entries(e.verzilvered_by);
                            let filtered = objected.filter((h) => {
                                return h[1].user_id === this.state.currentUserId;
                            });
                            if (filtered.length > 0) {
                                return (
                                    <button
                                        style={{
                                            backgroundColor: "#C17F6B",
                                            display: "inline-flex",
                                            alignItems: "center",
                                        }}
                                        className="pinkcolorbutton"
                                    >
                                        <CheckIcon
                                            style={{ fontSize: 18, marginRight: 5, marginLeft: 0 }}
                                        />{" "}
                                        {window.innerWidth > 1008
                                            ? `In orderoverzicht`
                                            : `Overzicht`}
                                    </button>
                                );
                            } else {
                                return (
                                    <button onClick={this.verzilverKortingsVoucher.bind(this, e)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                        Valideren
                                    </button>
                                );
                            }
                        } else {
                            return (
                                <button onClick={this.verzilverKortingsVoucher.bind(this, e)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                    Valideren
                                </button>
                            );
                        }
                    } else {
                        if (
                            this.state.currentUserHere.stripe_id &&
                            !this.state.userNotSubscribed
                        ) {
                            if (e.verzilvered_by) {
                                let objected = Object.entries(e.verzilvered_by);
                                let filtered = objected.filter((h) => {
                                    return h[1].user_id === this.state.currentUserId;
                                });
                                if (filtered.length > 0) {
                                    return (
                                        <button
                                            style={{
                                                backgroundColor: "#C17F6B",
                                                display: "inline-flex",
                                                alignItems: "center",
                                            }}
                                            className="pinkcolorbutton"
                                        >
                                            <CheckIcon
                                                style={{ fontSize: 18, marginRight: 5, marginLeft: 0 }}
                                            />{" "}
                                            {window.innerWidth > 1008
                                                ? `In orderoverzicht`
                                                : `Overzicht`}
                                        </button>
                                    );
                                } else {
                                    return (
                                        <button onClick={this.verzilverKortingsVoucher.bind(this, e)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                            Valideren{" "}
                                        </button>
                                    );
                                }
                            } else {
                                return (
                                    <button onClick={this.verzilverKortingsVoucher.bind(this, e)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                        Valideren
                                    </button>
                                );
                            }
                        } else {
                            return (
                                <button>
                                    Register
                                </button>
                            );
                        }
                    }
                }
            } else {
                if (this.state.guestUserCart) {
                    let filtered = this.state.guestUserCart.filter((h) => {
                        return h.pushKey === e.pushKey;
                    });
                    if (filtered.length > 0) {
                        // if (!e.kortingsVoucherUnlimited) {
                        //     let num = 0
                        //     if (e.verzilvered_by) {
                        //         let objectedCheckedIn = Object.entries(e.verzilvered_by)
                        //         num = this.getVerzilveredQty(objectedCheckedIn)
                        //     }
                        //     if (num < Number(e.number)) {
                        //         return <button style={{ backgroundColor: "#C17F6B" }} className="pinkcolorbutton">Toegevoegd</button>
                        //     } else {
                        //         return <button style={{ backgroundColor: "#C17F6B" }} className="pinkcolorbutton">Uitverkocht</button>
                        //     }
                        // } else {
                        return (
                            <button
                                style={{ backgroundColor: "#C17F6B" }}
                                className="pinkcolorbutton"
                            >
                                Toegevoegd
                            </button>
                        );
                        // }
                    } else {
                        if (!e.kortingsVoucherUnlimited) {
                            let num = 0;
                            if (e.verzilvered_by) {
                                let objectedCheckedIn = Object.entries(e.verzilvered_by);
                                num = this.getVerzilveredQty(objectedCheckedIn);
                            }
                            if (e.inCartOf) {
                                let objectedInCartOf = Object.entries(e.inCartOf);
                                num = num + objectedInCartOf.length;
                            }
                            if (num < Number(e.number)) {
                                return (
                                    <button onClick={this.addToCartNonUsers.bind(this, e, index)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                        Boek nu
                                    </button>
                                );
                            } else {
                                return (
                                    <button
                                        style={{ backgroundColor: "#C17F6B" }}
                                        className="pinkcolorbutton"
                                    >
                                        Uitverkocht
                                    </button>
                                );
                            }
                        } else {
                            return (
                                <button onClick={this.addToCartNonUsers.bind(this, e, index)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                    Boek nu
                                </button>
                            );
                        }
                    }
                } else {
                    if (!e.kortingsVoucherUnlimited) {
                        let num = 0;
                        if (e.verzilvered_by) {
                            let objectedCheckedIn = Object.entries(e.verzilvered_by);
                            num = this.getVerzilveredQty(objectedCheckedIn);
                        }
                        if (e.inCartOf) {
                            let objectedInCartOf = Object.entries(e.inCartOf);
                            num = num + objectedInCartOf.length;
                        }
                        if (num < Number(e.number)) {
                            return (
                                <button onClick={this.addToCartNonUsers.bind(this, e, index)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                    Boek nu
                                </button>
                            );
                        } else {
                            return (
                                <button
                                    style={{ backgroundColor: "#C17F6B" }}
                                    className="pinkcolorbutton"
                                >
                                    Uitverkocht
                                </button>
                            );
                        }
                    } else {
                        return (
                            <button onClick={this.addToCartNonUsers.bind(this, e, index)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                Boek nu
                            </button>
                        );
                    }
                }
            }
        } else {
            if (this.state.guestUserCart) {
                let filtered = this.state.guestUserCart.filter((h) => {
                    return h.pushKey === e.pushKey;
                });
                if (filtered.length > 0) {
                    // if (!e.kortingsVoucherUnlimited) {
                    //     let num = 0
                    //     if (e.verzilvered_by) {
                    //         let objectedCheckedIn = Object.entries(e.verzilvered_by)
                    //         num = this.getVerzilveredQty(objectedCheckedIn)
                    //     }
                    //     if (num < Number(e.number)) {
                    //         return <button style={{ backgroundColor: "#C17F6B" }} className="pinkcolorbutton">Toegevoegd</button>
                    //     } else {
                    //         return <button style={{ backgroundColor: "#C17F6B" }} className="pinkcolorbutton">Uitverkocht</button>
                    //     }
                    // } else {
                    return (
                        <button
                            style={{ backgroundColor: "#C17F6B" }}
                            className="pinkcolorbutton"
                        >
                            Toegevoegd
                        </button>
                    );
                    // }
                } else {
                    if (!e.kortingsVoucherUnlimited) {
                        let num = 0;
                        if (e.verzilvered_by) {
                            let objectedCheckedIn = Object.entries(e.verzilvered_by);
                            num = this.getVerzilveredQty(objectedCheckedIn);
                        }
                        if (e.inCartOf) {
                            let objectedInCartOf = Object.entries(e.inCartOf);
                            num = num + objectedInCartOf.length;
                        }
                        if (num < Number(e.number)) {
                            return (
                                <button onClick={this.addToCartNonUsers.bind(this, e, index)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                    Boek nu
                                </button>
                            );
                        } else {
                            return (
                                <button
                                    style={{ backgroundColor: "#C17F6B" }}
                                    className="pinkcolorbutton"
                                >
                                    Uitverkocht
                                </button>
                            );
                        }
                    } else {
                        return (
                            <button onClick={this.addToCartNonUsers.bind(this, e, index)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                Boek nu
                            </button>
                        );
                    }
                }
            } else {
                if (!e.kortingsVoucherUnlimited) {
                    let num = 0;
                    if (e.verzilvered_by) {
                        let objectedCheckedIn = Object.entries(e.verzilvered_by);
                        num = this.getVerzilveredQty(objectedCheckedIn);
                    }
                    if (e.inCartOf) {
                        let objectedInCartOf = Object.entries(e.inCartOf);
                        num = num + objectedInCartOf.length;
                    }
                    if (num < Number(e.number)) {
                        return (
                            <button onClick={this.addToCartNonUsers.bind(this, e, index)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                Boek nu
                            </button>
                        );
                    } else {
                        return (
                            <button
                                style={{ backgroundColor: "#C17F6B" }}
                                className="pinkcolorbutton"
                            >
                                Uitverkocht
                            </button>
                        );
                    }
                } else {
                    return (
                        <button onClick={this.addToCartNonUsers.bind(this, e, index)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                            Boek nu
                        </button>
                    );
                }
            }
        }
    }


    verzilverKortingsVoucher(e) {
        if (this.props.user) {
            let filtered = this.props.user.filter((g) => {
                return g.user_id === e.created_by;
            });
            if (filtered.length > 0) {
                this.setState({ currentUser: filtered[0] });
            }
        }
        this.setState({
            verzilverPopup: true,
            currentActie: e,
            currentActieType: "kortings_voucher",
        });
    }


    checkForButtonsVerzilverKortingsVoucher(currentActie) {
        return (
            <div style={{ display: "flex", marginTop: 20, justifyContent: "center" }}>
                {!isDesktop ? (
                    <button
                        style={{ marginRight: 5 }}
                        onClick={this.proceedKortingsVoucher.bind(this)}
                    >
                        OK
                    </button>
                ) : (
                    <button
                        style={{ marginRight: 5 }}
                        onClick={this.proceedKortingsVoucher.bind(this)}
                    >
                        OK
                    </button>
                )}
            </div>
        );
    }

    proceedKortingsVoucher() {
        let order_id = this.state.orderidToUpload + 1;
        firebase.database().ref("current_order_id").set(order_id)
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, "0");
        let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        let yyyy = today.getFullYear();

        today = mm + "/" + dd + "/" + yyyy;
        let arrSellers = [];
        arrSellers.push(this.state.currentUser.user_id);
        if (document.querySelector(".navbarfullwidthMobile")) {
            document.querySelector(".navbarfullwidthMobile").style.overflowX = "visible"
        }

        if (this.state.currentUser.expireTypeVoucher && this.state.currentUser.expireDateVoucher) {
            this.state.currentActie.expireTypeVoucher = this.state.currentUser.expireTypeVoucher
            this.state.currentActie.expireDateVoucher = this.state.currentUser.expireDateVoucher
        }

        let obj = {
            created_on: today,
            order_id,
            products: [this.state.currentActie],
            buyer: this.state.currentUserId,
            sellers: arrSellers,
            timeStampCreated: new Date().getTime(),
            order_type: "campaign",
        };
        // let pushuserOrderCurrentUser = firebase
        //     .database()
        //     .ref("users/" + this.state.currentUserId + "/orders")
        //     .push().key;
        // firebase
        //     .database()
        //     .ref(
        //         "users/" +
        //         this.state.currentUserId +
        //         "/orders/" +
        //         pushuserOrderCurrentUser
        //     )
        //     .set(obj);
        // let pushuserOrder = firebase
        //     .database()
        //     .ref("users/" + this.state.currentUser.user_id + "/orders")
        //     .push().key;
        // firebase
        //     .database()
        //     .ref(
        //         "users/" + this.state.currentUser.user_id + "/orders/" + pushuserOrder
        //     )
        //     .set(obj);
        let orderKey = firebase.database().ref("all_orders").push().key;
        obj.pushKey = orderKey;
        firebase
            .database()
            .ref("all_orders/" + orderKey)
            .set(obj);
        if (this.state.currentActie.verzilvered_by) {
            let objected = Object.entries(this.state.currentActie.verzilvered_by);
            let filtered = objected.filter((h) => {
                return h[1].user_id === this.state.currentUserId;
            });
            if (filtered.length > 0) {
                let newDate = new Date().getTime();
                let arr = filtered[0][1].allDates;
                arr.push(newDate);
                let pushKey = filtered[0][1].pushKey;
                let obj = {
                    user_id: this.state.currentUserId,
                    date: newDate,
                    allDates: arr,
                    pushKey,
                };
                firebase
                    .database()
                    .ref(
                        "bhodi_campaigns/" +
                        this.state.currentUser.user_id +
                        "/" +
                        this.state.currentActie.pushKey +
                        "/verzilvered_by/" +
                        filtered[0][1].pushKey
                    )
                    .set(obj);
            } else {
                let newDate = new Date().getTime();
                let arr = [];
                arr.push(newDate);
                let pushKey = firebase
                    .database()
                    .ref(
                        "bhodi_campaigns/" +
                        this.state.currentUser.user_id +
                        "/" +
                        this.state.currentActie.pushKey +
                        "/verzilvered_by"
                    )
                    .push().key;
                let obj = {
                    user_id: this.state.currentUserId,
                    date: newDate,
                    allDates: arr,
                    pushKey,
                };
                firebase
                    .database()
                    .ref(
                        "bhodi_campaigns/" +
                        this.state.currentUser.user_id +
                        "/" +
                        this.state.currentActie.pushKey +
                        "/verzilvered_by/" +
                        pushKey
                    )
                    .set(obj);
            }
        } else {
            let newDate = new Date().getTime();
            let arr = [];
            arr.push(newDate);
            let pushKey = firebase
                .database()
                .ref(
                    "bhodi_campaigns/" +
                    this.state.currentUser.user_id +
                    "/" +
                    this.state.currentActie.pushKey +
                    "/verzilvered_by"
                )
                .push().key;
            let obj = {
                user_id: this.state.currentUserId,
                date: newDate,
                allDates: arr,
                pushKey,
            };
            firebase
                .database()
                .ref(
                    "bhodi_campaigns/" +
                    this.state.currentUser.user_id +
                    "/" +
                    this.state.currentActie.pushKey +
                    "/verzilvered_by/" +
                    pushKey
                )
                .set(obj);
        }

        let notificationToShow = document.getElementById("mustShowOnAddOrder");
        if (notificationToShow) {
            notificationToShow.style.opacity = 1;
            notificationToShow.style.pointerEvents = "all";
        }

        setTimeout(() => {
            if (notificationToShow) {
                notificationToShow.style.opacity = 0;
                notificationToShow.style.pointerEvents = "none";
            }
            if (document.querySelector(".navbarfullwidthMobile")) {
                document.querySelector(".navbarfullwidthMobile").style.overflowX = "hidden"
            }
        }, 4000);
        this.setState({ verzilverPopup: false, verzilverPopupGreen: false });
    }


    addToCartNonUsers(e, index) {
        let f = e;
        let imgtodrag = "";

        imgtodrag = document.getElementsByClassName("addIconCart")[index];

        let arrCart = [...this.state.guestUserCart];

        let cookieItem = localStorage.getItem("cookie_item");

        var minutesToAddDB = Number(process.env.REACT_APP_CART_TIME);
        var currentDateDB = new Date();
        var futureDateDB = new Date(
            currentDateDB.getTime() + minutesToAddDB * 60000
        );

        let futureTimeStampDB = Math.floor(futureDateDB.getTime() / 1000);

        let objToMake = {
            cookieItem,
            itemPush: e.pushKey,
            timestamp: futureTimeStampDB,
        };

        if (e.campaignType === "web_shop") {
            const min = 0;
            const max = e.coupons.length;
            const intNumber = Math.floor(Math.random() * (max - min)) + min;
            e.redeemed_code = e.coupons[intNumber];
            objToMake.redeemed_code = e.coupons[intNumber];
        }

        var minutesToAdd = Number(process.env.REACT_APP_CART_TIME);
        var currentDate = new Date();
        var futureDate = new Date(currentDate.getTime() + minutesToAdd * 60000);

        let futureTimeStamp = Math.floor(futureDate.getTime() / 1000);

        arrCart.push(e);

        this.props.updateGuestUserCart(arrCart);
        this.props.updateGuestUserCartTime(futureTimeStamp);
        this.props.updateGuestUserCartExtendOpportunity(true);

        let userid = localStorage.getItem("userid");
        if (userid) {
            objToMake.cookieItem = userid;
            let pushKey = firebase
                .database()
                .ref("guest_carts/" + userid)
                .push().key;
            objToMake.pushKey = pushKey;
            let ownPushKey = firebase
                .database()
                .ref("bhodi_campaigns/" + f.created_by + "/" + f.pushKey + "/inCartOf")
                .push().key;

            objToMake.pushKeyInCartOf = ownPushKey;
            objToMake.creatorCompanyId = f.created_by;

            firebase
                .database()
                .ref("guest_carts/" + userid + "/" + pushKey)
                .set(objToMake);

            let objToOwnPush = {
                cookie: objToMake.cookieItem,
                pushKey: ownPushKey,
                cartPushKey: pushKey,
            };
            firebase
                .database()
                .ref(
                    "bhodi_campaigns/" +
                    f.created_by +
                    "/" +
                    f.pushKey +
                    "/inCartOf/" +
                    ownPushKey
                )
                .set(objToOwnPush);
        } else {
            if (cookieItem) {
                let pushKey = firebase
                    .database()
                    .ref("guest_carts/" + cookieItem)
                    .push().key;
                objToMake.pushKey = pushKey;

                let ownPushKey = firebase
                    .database()
                    .ref(
                        "bhodi_campaigns/" + f.created_by + "/" + f.pushKey + "/inCartOf"
                    )
                    .push().key;

                objToMake.pushKeyInCartOf = ownPushKey;
                objToMake.creatorCompanyId = f.created_by;

                firebase
                    .database()
                    .ref("guest_carts/" + cookieItem + "/" + pushKey)
                    .set(objToMake);
                let objToOwnPush = {
                    cookie: objToMake.cookieItem,
                    pushKey: ownPushKey,
                    cartPushKey: pushKey,
                };
                firebase
                    .database()
                    .ref(
                        "bhodi_campaigns/" +
                        f.created_by +
                        "/" +
                        f.pushKey +
                        "/inCartOf/" +
                        ownPushKey
                    )
                    .set(objToOwnPush);
            }
        }

        let disLeft = imgtodrag.getBoundingClientRect().left;
        let disTop = imgtodrag.getBoundingClientRect().top;
        let viewcart = document.getElementsByClassName("cart-icon")[0];
        let image = imgtodrag.cloneNode(true);

        let cartleft = viewcart.getBoundingClientRect().left;
        let carttop = viewcart.getBoundingClientRect().top;

        image.style =
            "z-index: 99999999999; width: 30px;opacity:0.8; position:fixed; top:" +
            disTop +
            "px;left:" +
            disLeft +
            "px;transition: left 2s, top 2s, width 2s, opacity 2s cubic-bezier(1, 1, 1, 1)";

        var rechange = document.body.appendChild(image);
        setTimeout(function () {
            image.style.left = cartleft + "px";
            image.style.top = carttop + "px";
            image.style.width = "30px";
            image.style.opacity = "0";
        }, 200);
        setTimeout(function () {
            rechange.parentNode.removeChild(rechange);
        }, 2000);

        let notificationToShow = document.getElementById("mustShowOnAddBasket");
        if (notificationToShow) {
            notificationToShow.style.opacity = 1;
            notificationToShow.style.pointerEvents = "all";
        }

        setTimeout(() => {
            if (notificationToShow) {
                notificationToShow.style.opacity = 0;
                notificationToShow.style.pointerEvents = "none";
            }
        }, 4000);
    }

    getUserName(userid) {
        if (this.props.user) {
            let filtered = this.props.user.filter((t) => {
                return t.user_id === userid
            })
            if (filtered.length > 0) {
                return `${filtered[0].company_name}`
            }
        }
    }

    getUserCity(userid) {
        if (this.props.user) {
            let filtered = this.props.user.filter((t) => {
                return t.user_id === userid
            })
            if (filtered.length > 0) {
                if (filtered[0].city) {
                    return `${filtered[0].city} | ${filtered[0].country}`
                }
                return `${filtered[0].country}`
            }
        }
    }

    getUserProfilePic(userid) {
        if (this.props.user) {
            let filtered = this.props.user.filter((t) => {
                return t.user_id === userid
            })
            if (filtered.length > 0) {
                if (filtered[0].profile_image) {
                    return `${filtered[0].profile_image}`
                }
            }
        }
        return "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9"
    }


    redirectUser(userid) {
        if (this.props.user) {
            let filtered = this.props.user.filter((t) => {
                return t.user_id === userid
            })
            if (filtered.length > 0) {
                if (filtered[0].type === "company") {
                    history.push(`/company/${filtered[0].user_name_id}`)
                } else {
                    history.push(`/member/${filtered[0].user_name_id}`)
                }
            }
        }
    }

    // event functions

    getMonthName(i) {
        i = Number(i);
        if (window.innerWidth < 1008) {
            if (i === 1) {
                return "jan";
            } else if (i === 2) {
                return "feb";
            } else if (i === 3) {
                return "mrt";
            } else if (i === 4) {
                return "apr";
            } else if (i === 5) {
                return "mei";
            } else if (i === 6) {
                return "jun";
            } else if (i === 7) {
                return "jul";
            } else if (i === 8) {
                return "aug";
            } else if (i === 9) {
                return "sep";
            } else if (i === 10) {
                return "okt";
            } else if (i === 11) {
                return "nov";
            } else if (i === 12) {
                return "dec";
            }
        }
        if (i === 1) {
            return "Januari";
        } else if (i === 2) {
            return "Februari";
        } else if (i === 3) {
            return "Maart";
        } else if (i === 4) {
            return "April";
        } else if (i === 5) {
            return "Mei";
        } else if (i === 6) {
            return "Juni";
        } else if (i === 7) {
            return "Juli";
        } else if (i === 8) {
            return "Augustus";
        } else if (i === 9) {
            return "September";
        } else if (i === 10) {
            return "Oktober";
        } else if (i === 11) {
            return "November";
        } else if (i === 12) {
            return "December";
        }
    }

    getFormattedStartDateLocalEvent(event) {
        if (event.timings && event.timings.length > 0) {
            let currentTimings = event.timings[0]
            let date = new Date(
                currentTimings.isoStartTime ? currentTimings.isoStartTime : currentTimings.startDateEvent
            );
            let endDate = new Date(
                currentTimings.isoEndTime ? currentTimings.isoEndTime : currentTimings.endDateEvent
            );
            if (date.getMonth() === endDate.getMonth()) {
                if (date.getDate() === endDate.getDate()) {
                    return `${date.getDate()} ${this.getMonthName(
                        date.getMonth() + 1
                    )} 
                ${(currentTimings.startTimeEvent && currentTimings.endTimeEvent && !event.takeFromAdmin) && `| ${currentTimings.startTimeEvent}-${currentTimings.endTimeEvent} uur`} 
                ${!event.takeFromAdmin ? "|" : ""} ${date.getFullYear()}`;
                } else {
                    return `${date.getDate()} t/m ${endDate.getDate()} ${this.getMonthName(
                        date.getMonth() + 1
                    )} 
                ${(currentTimings.startTimeEvent && currentTimings.endTimeEvent && !event.takeFromAdmin) && `| ${currentTimings.startTimeEvent}-${currentTimings.endTimeEvent} uur`} 
                ${!event.takeFromAdmin ? "|" : ""} ${date.getFullYear()}`;
                }
            } else {
                return `${date.getDate()} ${this.getMonthName(
                    date.getMonth() + 1
                )} t/m ${endDate.getDate()} ${this.getMonthName(
                    endDate.getMonth() + 1
                )} 
             ${(currentTimings.startTimeEvent && currentTimings.endTimeEvent && !event.takeFromAdmin) && `| ${currentTimings.startTimeEvent}-${currentTimings.endTimeEvent} uur`} 
             ${!event.takeFromAdmin ? "|" : ""} ${date.getFullYear()}`;
            }
        } else {
            return ""
        }
    }

    getFormattedDateWebShop(e) {
        let currentTimings = e
        let date = new Date(
            currentTimings.isoStartTime ? currentTimings.isoStartTime : currentTimings.startDateEvent
        );
        let endDate = new Date(
            currentTimings.isoEndTime ? currentTimings.isoEndTime : currentTimings.endDateEvent
        );
        if (date.getMonth() === endDate.getMonth()) {
            if (date.getDate() === endDate.getDate()) {
                return `${date.getDate()} ${this.getMonthName(
                    date.getMonth() + 1
                )} 
                ${(currentTimings.voucherStartTime && currentTimings.voucherEndTime) && `| ${currentTimings.voucherStartTime}-${currentTimings.voucherEndTime} uur`} 
                | ${date.getFullYear()}`;
            } else {
                return `${date.getDate()} t/m ${endDate.getDate()} ${this.getMonthName(
                    date.getMonth() + 1
                )} 
                ${(currentTimings.voucherStartTime && currentTimings.voucherEndTime) && `| ${currentTimings.voucherStartTime}-${currentTimings.voucherEndTime} uur`} 
                | ${date.getFullYear()}`;
            }
        } else {
            return `${date.getDate()} ${this.getMonthName(
                date.getMonth() + 1
            )} t/m ${endDate.getDate()} ${this.getMonthName(
                endDate.getMonth() + 1
            )} 
             ${(currentTimings.voucherStartTime && currentTimings.voucherEndTime) && `| ${currentTimings.voucherStartTime}-${currentTimings.voucherEndTime} uur`} 
            | ${date.getFullYear()}`;
        }
    }


    getLocalEventSubscribeButton(event, index) {
        let currentLocalEvent = event;
        let localEventIfSharedThen = "";
        if (!currentLocalEvent.localEventIfSharedThen) {
            localEventIfSharedThen = currentLocalEvent;
        } else {
            localEventIfSharedThen = currentLocalEvent.localEventIfSharedThen;
        }
        let alreadyInCart = false;

        if (localEventIfSharedThen.inCartOf) {
            let objected = Object.entries(localEventIfSharedThen.inCartOf);
            objected = objected.filter((t) => {
                return t[1].event_id === currentLocalEvent.timings[currentLocalEvent.selectedTimeIndex].eventId
            })
            let cookieItem = localStorage.getItem("cookie_item");
            let filtered = objected.filter((h) => {
                if (this.state.currentUserId) {
                    return h[1].cookie === this.state.currentUserId && h[1].event_id === event.reserved_event_id;
                } else {
                    return h[1].cookie === cookieItem && h[1].event_id === event.reserved_event_id;
                }
            });
            if (filtered.length > 0) {
                alreadyInCart = true;
            }
        }
        if (alreadyInCart) {
            return (
                <button
                    style={{ backgroundColor: "#C17F6B", color: "white" }}
                >
                    Toegevoegd
                </button>
            );
        } else {
            let qtyToMinus = 0;
            if (localEventIfSharedThen.inCartOf) {
                let objected = Object.entries(localEventIfSharedThen.inCartOf);
                objected = objected.filter((t) => {
                    return t[1].event_id === currentLocalEvent.timings[currentLocalEvent.selectedTimeIndex].eventId
                })
                qtyToMinus = qtyToMinus + objected.length;
            }
            if (localEventIfSharedThen.checked_in_by) {
                let objected = Object.entries(localEventIfSharedThen.checked_in_by);
                objected = objected.filter((t) => {
                    return t[1].event_id === currentLocalEvent.timings[currentLocalEvent.selectedTimeIndex].eventId
                })
                qtyToMinus = qtyToMinus + objected.length;
            }
            if (currentLocalEvent.timings && currentLocalEvent.timings.length > 0) {
                if (
                    Number(currentLocalEvent.timings[currentLocalEvent.selectedTimeIndex].personsAmountEvent) -
                    qtyToMinus <=
                    0
                ) {
                    return (
                        <button style={{ backgroundColor: "#C17F6B", color: "white" }}>
                            Uitverkocht
                        </button>
                    );
                }
            }
            return (
                <>
                    <button
                        onClick={this.addToCartLocalEvent.bind(this, currentLocalEvent, index)}
                    >
                        {currentLocalEvent.created_by === this.state.currentUserId ? "Boek nu" : `Boek nu`}
                    </button>
                    <div className="image-front addIconCart">
                        <AddIcon />
                    </div>
                </>
            );
        }
    }


    addToCartLocalEvent(event, index) {
        let currentLocalEvent = event;
        let localEventIfSharedThen = "";
        if (!currentLocalEvent.localEventIfSharedThen) {
            localEventIfSharedThen = currentLocalEvent;
        } else {
            localEventIfSharedThen = currentLocalEvent.localEventIfSharedThen;
        }

        let e = localEventIfSharedThen;

        if (document.querySelector(".navbarfullwidthMobile")) {
            document.querySelector(".navbarfullwidthMobile").style.overflowX = "visible"
        }

        let imgtodrag = document.getElementsByClassName("addIconCart")[index];
        let disLeft = imgtodrag.getBoundingClientRect().left;
        let disTop = imgtodrag.getBoundingClientRect().top;
        let viewcart = document.getElementsByClassName("cart-icon")[0];
        let image = imgtodrag.cloneNode(true);

        let cartleft = viewcart.getBoundingClientRect().left;
        let carttop = viewcart.getBoundingClientRect().top;

        image.style =
            "z-index: 99999999999; width: 30px;opacity:0.8; position:fixed; top:" +
            disTop +
            "px;left:" +
            disLeft +
            "px;transition: left 2s, top 2s, width 2s, opacity 2s cubic-bezier(1, 1, 1, 1)";

        var rechange = document.body.appendChild(image);
        setTimeout(function () {
            image.style.left = cartleft + "px";
            image.style.top = carttop + "px";
            image.style.width = "30px";
            image.style.opacity = "0";
        }, 200);
        setTimeout(function () {
            rechange.parentNode.removeChild(rechange);
        }, 2000);

        let arrCart = [...this.state.guestUserCart];
        let cookieItem = localStorage.getItem("userid");

        var minutesToAddDB = Number(process.env.REACT_APP_CART_TIME);
        var currentDateDB = new Date();
        var futureDateDB = new Date(
            currentDateDB.getTime() + minutesToAddDB * 60000
        );

        let futureTimeStampDB = Math.floor(futureDateDB.getTime() / 1000);

        let objToMake = {
            cookieItem,
            itemPush: e.pushKey,
            timestamp: futureTimeStampDB,
            event_id: event.reserved_event_id,
        };

        let pushKeyInCartOf = firebase
            .database()
            .ref(
                `bhodi_campaigns/${event.created_by}/${localEventIfSharedThen.pushKey}/inCartOf`
            )
            .push().key;
        let objInCartOf = {
            cookie: cookieItem,
            pushKey: pushKeyInCartOf,
            event_id: event.reserved_event_id,
        };

        objToMake.pushKeyInCartOf = pushKeyInCartOf;
        objToMake.creatorCompanyId = event.created_by;

        firebase
            .database()
            .ref(
                `bhodi_campaigns/${event.created_by}/${localEventIfSharedThen.pushKey}/inCartOf/${pushKeyInCartOf}`
            )
            .set(objInCartOf);

        var minutesToAdd = Number(process.env.REACT_APP_CART_TIME);
        var currentDate = new Date();
        var futureDate = new Date(currentDate.getTime() + minutesToAdd * 60000);

        let futureTimeStamp = Math.floor(futureDate.getTime() / 1000);

        arrCart.push(e);

        this.props.updateGuestUserCart(arrCart);
        this.props.updateGuestUserCartTime(futureTimeStamp);
        this.props.updateGuestUserCartExtendOpportunity(true);

        let userid = localStorage.getItem("userid");
        if (userid) {
            objToMake.cookieItem = userid;
            let pushKey = firebase
                .database()
                .ref("guest_carts/" + userid)
                .push().key;
            objToMake.pushKey = pushKey;
            firebase
                .database()
                .ref("guest_carts/" + userid + "/" + pushKey)
                .set(objToMake);
        }



        let notificationToShow = document.getElementById("mustShowOnAddBasket");
        if (notificationToShow) {
            notificationToShow.style.opacity = 1;
            notificationToShow.style.pointerEvents = "all";
        }

        setTimeout(() => {
            if (notificationToShow) {
                notificationToShow.style.opacity = 0;
                notificationToShow.style.pointerEvents = "none";
            }
            if (document.querySelector(".navbarfullwidthMobile")) {
                document.querySelector(".navbarfullwidthMobile").style.overflowX = "hidden"
            }
        }, 4000);
    }

    // addToCartLocalEvent(e, index) {

    //     let imgtodrag = document.getElementsByClassName("addIconCart")[index];
    //     let disLeft = imgtodrag.getBoundingClientRect().left;
    //     let disTop = imgtodrag.getBoundingClientRect().top;
    //     let viewcart = document.getElementsByClassName("cart-icon")[0];
    //     let image = imgtodrag.cloneNode(true);

    //     let cartleft = viewcart.getBoundingClientRect().left;
    //     let carttop = viewcart.getBoundingClientRect().top;

    //     image.style =
    //         "z-index: 99999999999; width: 30px;opacity:0.8; position:fixed; top:" +
    //         disTop +
    //         "px;left:" +
    //         disLeft +
    //         "px;transition: left 2s, top 2s, width 2s, opacity 2s cubic-bezier(1, 1, 1, 1)";

    //     var rechange = document.body.appendChild(image);
    //     setTimeout(function () {
    //         image.style.left = cartleft + "px";
    //         image.style.top = carttop + "px";
    //         image.style.width = "30px";
    //         image.style.opacity = "0";
    //     }, 200);
    //     setTimeout(function () {
    //         rechange.parentNode.removeChild(rechange);
    //     }, 2000);

    //     let arrCart = [...this.state.guestUserCart];
    //     let cookieItem = localStorage.getItem("userid");

    //     var minutesToAddDB = Number(process.env.REACT_APP_CART_TIME);
    //     var currentDateDB = new Date();
    //     var futureDateDB = new Date(
    //         currentDateDB.getTime() + minutesToAddDB * 60000
    //     );

    //     let futureTimeStampDB = Math.floor(futureDateDB.getTime() / 1000);

    //     let objToMake = {
    //         cookieItem,
    //         itemPush: e.pushKey,
    //         timestamp: futureTimeStampDB,
    //     };

    //     let pushKeyInCartOf = firebase
    //         .database()
    //         .ref(
    //             `bhodi_campaigns/${e.created_by}/${e.pushKey}/inCartOf`
    //         )
    //         .push().key;
    //     let objInCartOf = {
    //         cookie: cookieItem,
    //         pushKey: pushKeyInCartOf,
    //     };

    //     objToMake.pushKeyInCartOf = pushKeyInCartOf;

    //     firebase
    //         .database()
    //         .ref(
    //             `bhodi_campaigns/${e.created_by}/${e.pushKey}/inCartOf/${pushKeyInCartOf}`
    //         )
    //         .set(objInCartOf);

    //     var minutesToAdd = Number(process.env.REACT_APP_CART_TIME);
    //     var currentDate = new Date();
    //     var futureDate = new Date(currentDate.getTime() + minutesToAdd * 60000);

    //     let futureTimeStamp = Math.floor(futureDate.getTime() / 1000);

    //     arrCart.push(e);

    //     this.props.updateGuestUserCart(arrCart);
    //     this.props.updateGuestUserCartTime(futureTimeStamp);
    //     this.props.updateGuestUserCartExtendOpportunity(true);

    //     let userid = localStorage.getItem("userid");
    //     if (userid) {
    //         objToMake.cookieItem = userid;
    //         let pushKey = firebase
    //             .database()
    //             .ref("guest_carts/" + userid)
    //             .push().key;
    //         objToMake.pushKey = pushKey;
    //         firebase
    //             .database()
    //             .ref("guest_carts/" + userid + "/" + pushKey)
    //             .set(objToMake);
    //     }

    //     let notificationToShow = document.getElementById("mustShowOnAddBasket");
    //     if (notificationToShow) {
    //         notificationToShow.style.opacity = 1;
    //         notificationToShow.style.pointerEvents = "all";
    //     }

    //     setTimeout(() => {
    //         if (notificationToShow) {
    //             notificationToShow.style.opacity = 0;
    //             notificationToShow.style.pointerEvents = "none";
    //         }
    //     }, 4000);
    // }

    getWinkelmandbuttonUsersLocalBlock(event) {
        let currentLocalEvent = event;
        let localEventIfSharedThen = "";

        if (!currentLocalEvent.localEventIfSharedThen) {
            localEventIfSharedThen = currentLocalEvent;
        } else {
            localEventIfSharedThen = currentLocalEvent.localEventIfSharedThen;
        }

        let e = localEventIfSharedThen;
        if (this.state.currentUserHere) {
            if (
                ((this.state.currentUserHere.subscription && this.state.currentUserHere.subscription.status === "active") || checkForSubscribedExclusives(this.state.currentUserHere))
            ) {
                // if (window.innerWidth > 1009) {
                let added_via_payment_link = []
                if (e.added_via_payment_link) {
                    added_via_payment_link = Object.entries(e.added_via_payment_link)
                }
                if (e.checked_in_by) {
                    let objected = Object.entries(e.checked_in_by);
                    if (added_via_payment_link.length > 0) {
                        objected.concat(added_via_payment_link)
                    }
                    let filtered = objected.filter((h) => {
                        return h[1].user_id === this.state.currentUserId && h[1].event_id === event.reserved_event_id;
                    });
                    if (filtered.length > 0) {
                        return (
                            <button
                                style={{ backgroundColor: "#C17F6B" }}
                                className="pink-color-btndcw"
                            >
                                <a>{window.innerWidth > 1008 ? `In Overzicht` : `Overzicht`}</a>
                            </button>
                        );
                    } else {
                        let qtyToMinus = 0;
                        if (localEventIfSharedThen.inCartOf) {
                            let objected = Object.entries(localEventIfSharedThen.inCartOf);
                            objected = objected.filter((t) => {
                                return t[1].event_id === currentLocalEvent.timings[currentLocalEvent.selectedTimeIndex].eventId
                            })
                            qtyToMinus = qtyToMinus + objected.length;
                        }
                        if (localEventIfSharedThen.checked_in_by) {
                            let objected = Object.entries(
                                localEventIfSharedThen.checked_in_by
                            );
                            objected = objected.filter((t) => {
                                return t[1].event_id === currentLocalEvent.timings[currentLocalEvent.selectedTimeIndex].eventId
                            })
                            qtyToMinus = qtyToMinus + objected.length;
                        }
                        if (
                            currentLocalEvent.timings &&
                            currentLocalEvent.timings.length > 0
                        ) {
                            if (
                                Number(currentLocalEvent.timings[currentLocalEvent.selectedTimeIndex].personsAmountEvent) -
                                qtyToMinus <=
                                0
                            ) {
                                return (
                                    <button
                                        style={{ backgroundColor: "#C17F6B", color: "white" }}
                                    >
                                        Uitverkocht
                                    </button>
                                );
                            }
                        }
                        return (
                            <button
                                onClick={this.verzilverLocalBlock.bind(this, currentLocalEvent)} className={window.innerWidth < 1008 && "pink-color-btndcw"}
                            >
                                <a>
                                    Boek nu {window.innerWidth < 1008 && <ChevronRightIcon />}
                                </a>
                                <div className="image-local-event addIconCart">
                                    <AddIcon />
                                </div>
                            </button>
                        );
                    }
                } else {
                    let qtyToMinus = 0;
                    if (localEventIfSharedThen.inCartOf) {
                        let objected = Object.entries(localEventIfSharedThen.inCartOf);
                        objected = objected.filter((t) => {
                            return t[1].event_id === currentLocalEvent.timings[currentLocalEvent.selectedTimeIndex].eventId
                        })
                        qtyToMinus = qtyToMinus + objected.length;
                    }
                    if (localEventIfSharedThen.checked_in_by) {
                        let objected = Object.entries(
                            localEventIfSharedThen.checked_in_by
                        );
                        objected = objected.filter((t) => {
                            return t[1].event_id === currentLocalEvent.timings[currentLocalEvent.selectedTimeIndex].eventId
                        })
                        qtyToMinus = qtyToMinus + objected.length;
                    }
                    if (
                        currentLocalEvent.timings &&
                        currentLocalEvent.timings.length > 0
                    ) {
                        if (
                            Number(currentLocalEvent.timings[currentLocalEvent.selectedTimeIndex].personsAmountEvent) -
                            qtyToMinus <=
                            0
                        ) {
                            return (
                                <button
                                    style={{ backgroundColor: "#C17F6B", color: "white" }}
                                >
                                    Uitverkocht
                                </button>
                            );
                        }
                    }
                    return (
                        <button
                            onClick={this.verzilverLocalBlock.bind(this, currentLocalEvent)} className={window.innerWidth < 1008 && "pink-color-btndcw"}
                        >
                            <a>
                                Boek nu {window.innerWidth < 1008 && <ChevronRightIcon />}
                            </a>
                            <div className="image-local-event addIconCart">
                                <AddIcon />
                            </div>
                        </button>
                    );
                }
                // } else {
                //     if (
                //         this.state.currentUserHere.stripe_id &&
                //         !this.state.userNotSubscribed
                //     ) {
                //         if (e.checked_in_by) {
                //             let objected = Object.entries(e.checked_in_by);
                //             let filtered = objected.filter((h) => {
                //                 return h[1].user_id === this.state.currentUserId && h[1].event_id === event.reserved_event_id;
                //             });
                //             if (filtered.length > 0) {
                //                 return (
                //                     <button
                //                         style={{ backgroundColor: "#C17F6B" }}
                //                         className="pink-color-btndcw"
                //                     >
                //                         <a>{window.innerWidth > 1008 ? `In orderoverzicht` : `Overzicht`}</a>
                //                         {/* <a>In orderoverzicht</a> */}
                //                     </button>
                //                 );
                //             } else {
                //                 return (
                //                     <button
                //                         onClick={this.verzilverLocalBlock.bind(
                //                             this,
                //                             currentLocalEvent
                //                         )} className={window.innerWidth < 1008 && "pink-color-btndcw"}
                //                     >
                //                         <a>
                //                             Boek nu {window.innerWidth < 1008 && <ChevronRightIcon />}
                //                         </a>
                //                         <div className="image-local-event addIconCart">
                //                             <AddIcon />
                //                         </div>
                //                     </button>
                //                 );
                //             }
                //         } else {
                //             return (
                //                 <button
                //                     onClick={this.verzilverLocalBlock.bind(this, currentLocalEvent)} className={window.innerWidth < 1008 && "pink-color-btndcw"}
                //                 >
                //                     <a>
                //                         Boek nu {window.innerWidth < 1008 && <ChevronRightIcon />}
                //                     </a>
                //                     <div className="image-local-event addIconCart">
                //                         <AddIcon />
                //                     </div>
                //                 </button>
                //             );
                //         }
                //     } else {
                //         return (
                //             <button onClick={() => (window.location.href = "/charity")}>
                //                 Register
                //             </button>
                //         );
                //     }
                // }
            }
        }
    }

    // getWinkelmandbuttonUsersLocalBlock(event) {
    //     let currentLocalEvent = event;
    //     let localEventIfSharedThen = "";

    //     if (!currentLocalEvent.localEventIfSharedThen) {
    //         localEventIfSharedThen = currentLocalEvent;
    //     } else {
    //         localEventIfSharedThen = currentLocalEvent.localEventIfSharedThen;
    //     }

    //     let alreadyUsedMain = false;

    //     let e = localEventIfSharedThen;
    //     if (this.state.currentUserHere) {
    //         if ((this.state.currentUserHere.subscription && this.state.currentUserHere.subscription.status === "active") || checkForSubscribedExclusives(this.state.currentUserHere)) {
    //             // if (
    //             //   this.state.currentUserHere.subscription &&
    //             //   this.state.currentUserHere.subscription.status === "active"
    //             // ) {
    //             if (alreadyUsedMain) {
    //                 return (
    //                     <button
    //                         style={{ backgroundColor: "#C17F6B", display: "inline-flex", alignItems: "center", color: "white" }}
    //                     // className="pink-color-btndcw pinkcolorbutton"
    //                     >
    //                         <CheckIcon
    //                             style={{ fontSize: 18, marginRight: 15, marginLeft: 0 }}
    //                         />
    //                         <font>{window.innerWidth > 1008 ? `In orderoverzicht` : `Overzicht`}</font>
    //                     </button>
    //                 );
    //             }
    //             if (window.innerWidth > 1009) {
    //                 if (e.checked_in_by) {
    //                     let objected = Object.entries(e.checked_in_by);
    //                     let filtered = objected.filter((h) => {
    //                         return h[1].user_id === this.state.currentUserId;
    //                     });
    //                     if (filtered.length > 0) {
    //                         return (
    //                             <button
    //                                 style={{ backgroundColor: "#C17F6B", display: "inline-flex", alignItems: "center", color: "white" }}
    //                             // style={{ backgroundColor: "#C17F6B", display: "flex", alignItems: "center" }}
    //                             //         className="pink-color-btndcw pinkcolorbutton"
    //                             >
    //                                 <CheckIcon
    //                                     style={{ fontSize: 18, marginRight: 15, marginLeft: 0 }}
    //                                 />
    //                                 <font>{window.innerWidth > 1008 ? `In orderoverzicht` : `Overzicht`}</font>
    //                             </button>
    //                         );
    //                     } else {
    //                         let qtyToMinus = 0;
    //                         if (localEventIfSharedThen.inCartOf) {
    //                             let objected = Object.entries(localEventIfSharedThen.inCartOf);
    //                             qtyToMinus = qtyToMinus + objected.length;
    //                         }
    //                         if (localEventIfSharedThen.checked_in_by) {
    //                             let objected = Object.entries(
    //                                 localEventIfSharedThen.checked_in_by
    //                             );
    //                             qtyToMinus = qtyToMinus + objected.length;
    //                         }
    //                         if (
    //                             currentLocalEvent.timings &&
    //                             currentLocalEvent.timings.length > 0
    //                         ) {
    //                             if (
    //                                 Number(currentLocalEvent.timings[0].personsAmountEvent) -
    //                                 qtyToMinus <=
    //                                 0
    //                             ) {
    //                                 return (
    //                                     <button
    //                                         style={{ backgroundColor: "#C17F6B", color: "white" }}
    //                                     >
    //                                         Uitverkocht
    //                                     </button>
    //                                 );
    //                             }
    //                         }
    //                         return (
    //                             <button
    //                                 onClick={this.verzilverLocalBlock.bind(this, currentLocalEvent)}
    //                             >
    //                                 <font>
    //                                     Boek nu
    //                                 </font>
    //                                 <div className="image-local-event addIconCart">
    //                                     <AddIcon />
    //                                 </div>
    //                             </button>
    //                         );
    //                     }
    //                 } else {
    //                     let qtyToMinus = 0;
    //                     if (localEventIfSharedThen.inCartOf) {
    //                         let objected = Object.entries(localEventIfSharedThen.inCartOf);
    //                         qtyToMinus = qtyToMinus + objected.length;
    //                     }
    //                     if (localEventIfSharedThen.checked_in_by) {
    //                         let objected = Object.entries(
    //                             localEventIfSharedThen.checked_in_by
    //                         );
    //                         qtyToMinus = qtyToMinus + objected.length;
    //                     }
    //                     if (
    //                         currentLocalEvent.timings &&
    //                         currentLocalEvent.timings.length > 0
    //                     ) {
    //                         if (
    //                             Number(currentLocalEvent.timings[0].personsAmountEvent) -
    //                             qtyToMinus <=
    //                             0
    //                         ) {
    //                             return (
    //                                 <button
    //                                     style={{ backgroundColor: "#C17F6B", color: "white" }}
    //                                 >
    //                                     Uitverkocht
    //                                 </button>
    //                             );
    //                         }
    //                     }
    //                     return (
    //                         <button
    //                             onClick={this.verzilverLocalBlock.bind(this, currentLocalEvent)}
    //                         >
    //                             <a>
    //                                 Boek nu {window.innerWidth < 1008 && <ChevronRightIcon />}
    //                             </a>
    //                             <div className="image-local-event addIconCart">
    //                                 <AddIcon />
    //                             </div>
    //                         </button>
    //                     );
    //                 }
    //             } else {
    //                 if (
    //                     this.state.currentUserHere.stripe_id &&
    //                     !this.state.userNotSubscribed
    //                 ) {
    //                     if (e.checked_in_by) {
    //                         let objected = Object.entries(e.checked_in_by);
    //                         let filtered = objected.filter((h) => {
    //                             return h[1].user_id === this.state.currentUserId;
    //                         });
    //                         if (filtered.length > 0) {
    //                             return (
    //                                 <button
    //                                     style={{ backgroundColor: "#C17F6B", display: "inline-flex", alignItems: "center", color: "white" }}
    //                                 >
    //                                     <CheckIcon
    //                                         style={{ fontSize: 18, marginRight: 15, marginLeft: 0 }}
    //                                     />
    //                                     <font>{window.innerWidth > 1008 ? `In orderoverzicht` : `Overzicht`}</font>
    //                                     {/* <a>In orderoverzicht</a> */}
    //                                 </button>
    //                             );
    //                         } else {
    //                             return (
    //                                 <button
    //                                     onClick={this.verzilverLocalBlock.bind(
    //                                         this,
    //                                         currentLocalEvent
    //                                     )}
    //                                 >
    //                                     <font>
    //                                         Boek nu
    //                                     </font>
    //                                     <div className="image-local-event addIconCart">
    //                                         <AddIcon />
    //                                     </div>
    //                                 </button>
    //                             );
    //                         }
    //                     } else {
    //                         return (
    //                             <button
    //                                 onClick={this.verzilverLocalBlock.bind(this, currentLocalEvent)}
    //                             >
    //                                 <font>
    //                                     Boek nu
    //                                 </font>
    //                                 <div className="image-local-event addIconCart">
    //                                     <AddIcon />
    //                                 </div>
    //                             </button>
    //                         );
    //                     }
    //                 } else {
    //                     return (
    //                         <button onClick={() => (window.location.href = "/charity")}>
    //                             Register
    //                         </button>
    //                     );
    //                 }
    //             }
    //         }
    //     }
    // }

    verzilverLocalBlock(event) {
        if (this.props.user) {
            let filtered = this.props.user.filter((g) => {
                return g.user_id === event.created_by;
            });
            if (filtered.length > 0) {
                this.setState({ currentUser: filtered[0] });
            }
        }
        this.setState({
            verzilverPopupLocalEvent: true,
            currentActieLocalEvent: event,
            currentActieType: "local_event",
        });
        // this.setState({
        //     verzilverPopupLocalEvent: true,
        // });
    }

    proceedLocalBlock(event) {
        let currentLocalEvent = event;
        let localEventIfSharedThen = "";
        if (!currentLocalEvent.localEventIfSharedThen) {
            localEventIfSharedThen = currentLocalEvent;
        } else {
            localEventIfSharedThen = currentLocalEvent.localEventIfSharedThen;
        }

        if (document.querySelector(".navbarfullwidthMobile")) {
            document.querySelector(".navbarfullwidthMobile").style.overflowX = "visible"
        }

        let order_id = this.state.orderidToUpload + 1;
        firebase.database().ref("current_order_id").set(order_id)
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, "0");
        let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        let yyyy = today.getFullYear();

        today = mm + "/" + dd + "/" + yyyy;
        let arrSellers = [];
        arrSellers.push(this.state.currentUser.user_id);


        currentLocalEvent.reserved_event_id = event.reserved_event_id;

        let obj = {
            created_on: today,
            order_id,
            products: [currentLocalEvent],
            buyer: this.state.currentUserId,
            sellers: arrSellers,
            timeStampCreated: new Date().getTime(),
            order_type: "campaign",
        };
        // let pushuserOrderCurrentUser = firebase
        //     .database()
        //     .ref("users/" + this.state.currentUserId + "/orders")
        //     .push().key;
        // firebase
        //     .database()
        //     .ref(
        //         "users/" +
        //         this.state.currentUserId +
        //         "/orders/" +
        //         pushuserOrderCurrentUser
        //     )
        //     .set(obj);
        // let pushuserOrder = firebase
        //     .database()
        //     .ref("users/" + this.state.currentUser.user_id + "/orders")
        //     .push().key;
        // firebase
        //     .database()
        //     .ref(
        //         "users/" + this.state.currentUser.user_id + "/orders/" + pushuserOrder
        //     )
        //     .set(obj);
        let orderKey = firebase.database().ref("all_orders").push().key;
        obj.pushKey = orderKey;

        if (obj.products[0].campaignType === "local_event" && !obj.products[0].manual_code_import) {
            let toSendEmailkey = firebase.database().ref("events_for_emails").push().key;
            obj.toSendEmailkey = toSendEmailkey;


            let objToSendEmailValidate = {
                orderKey,
                pushKey: toSendEmailkey,
                created_at: new Date().toISOString(),
                event_key: obj.products[0].pushKey,
                buyer_id: obj.buyer,
            }

            if (obj.products[0].timings) {
                if (
                    obj.products[0].timings.length > 0
                ) {
                    let filtered = obj.products[0].timings.filter((j) => {
                        return j.eventId === obj.products[0].reserved_event_id;
                    });
                    if (filtered.length > 0) {
                        let dateMax = new Date(filtered[0].startDateEvent);
                        if (filtered[0].isoStartTime && filtered[0].isoEndTime) {
                            dateMax = new Date(Date.parse(filtered[0].isoStartTime))
                        } else {
                            let endTimeGood = filtered[0].startTimeEvent.concat(":00");
                            dateMax.setHours(endTimeGood.split(":")[0]);
                            dateMax.setMinutes(endTimeGood.split(":")[1]);
                        }
                        objToSendEmailValidate.send_email_at = dateMax.toISOString()

                        firebase.database().ref("events_for_emails/" + toSendEmailkey).set(objToSendEmailValidate)
                    }
                }
            }
        }

        firebase
            .database()
            .ref("all_orders/" + orderKey)
            .set(obj);


        let newDate = new Date().getTime();
        let arr = [];
        arr.push(newDate);
        let pushKey = firebase
            .database()
            .ref(
                "bhodi_campaigns/" +
                this.state.currentUser.user_id +
                "/" +
                localEventIfSharedThen.pushKey +
                "/checked_in_by"
            )
            .push().key;

        let objCheckedIn = {
            user_id: this.state.currentUserId,
            date: newDate,
            allDates: arr,
            pushKey,
            event_id: event.reserved_event_id,
            order_key: orderKey,
        };
        firebase
            .database()
            .ref(
                "bhodi_campaigns/" +
                this.state.currentUser.user_id +
                "/" +
                localEventIfSharedThen.pushKey +
                "/checked_in_by/" +
                pushKey
            )
            .set(objCheckedIn);



        let notificationToShow = document.getElementById("mustShowOnAddOrder");
        if (notificationToShow) {
            notificationToShow.style.opacity = 1;
            notificationToShow.style.pointerEvents = "all";
        }

        setTimeout(() => {
            if (notificationToShow) {
                notificationToShow.style.opacity = 0;
                notificationToShow.style.pointerEvents = "none";
            }
            if (document.querySelector(".navbarfullwidthMobile")) {
                document.querySelector(".navbarfullwidthMobile").style.overflowX = "hidden"
            }
        }, 4000);
        // if (!isDesktop) {
        //     this.setState({ verzilverPopup: false, verzilverPopupGreen: true })
        // } else {
        //     this.setState({ verzilverPopup: false, verzilverPopupGreen: false })
        // }
        if (document.querySelector(".nav-clgd")) {
            document.querySelectorAll(".nav-clgd").forEach((el) => {
                el.style.opacity = 1
            })
            document.querySelectorAll(".nav-clgd").forEach((el) => {
                el.style.pointerEvents = "all"
            })
        }
        this.setState({ verzilverPopup: false, verzilverPopupGreen: false, verzilverPopupLocalEvent: false, verzilverPopupGreenLocalEvent: true });
    }

    getStartTimeOfReservedEvent(event) {
        if (event) {
            if (event.campaignType === "local_event") {
                let filtered = event.timings
                if (filtered.length > 0) {
                    return filtered[event.selectedTimeIndex].startTimeEvent;
                }
            }
        }
    }

    getEndTimeOfReservedEvent(event) {
        if (event) {
            if (event.campaignType === "local_event") {
                let filtered = event.timings
                if (filtered.length > 0) {
                    return filtered[event.selectedTimeIndex].endTimeEvent;
                }
            }
        }
    }


    checkForBookMarkIcon(g) {
        if (g.bookmarked_by) {
            let objected = Object.entries(
                g.bookmarked_by
            );
            let filtered = objected.filter((g) => {
                return g[1].bookmarked_by === this.state.currentUserId;
            });
            if (filtered.length > 0) {
                return (
                    <div
                        className="icons-lcltsmhnc"
                        onClick={() =>
                            unBookmarkCurrentProduct(g)
                        }
                    >
                        <AiFillStar />
                    </div>
                );
            } else {
                return (
                    <div
                        className="icons-lcltsmhnc"
                        onClick={() => bookmarkCurrentProduct(g)}
                    >
                        <AiOutlineStar />
                    </div>
                );
            }
        } else {
            return (
                <div
                    className="icons-lcltsmhnc"
                    onClick={() => bookmarkCurrentProduct(g)}
                >
                    <AiOutlineStar />
                </div>
            );
        }
    }



    checkForWinkelmandButtonLocalEvent(event, index) {
        let currentLocalEvent = event;
        let localEventIfSharedThen = "";
        if (!currentLocalEvent.localEventIfSharedThen) {
            localEventIfSharedThen = currentLocalEvent;
        } else {
            localEventIfSharedThen = currentLocalEvent.localEventIfSharedThen;
        }
        if (this.state.guestUserCart) {
            let filtered = this.state.guestUserCart.filter((h) => {
                return h.pushKey === localEventIfSharedThen.pushKey && (h.event_id === this.state.reserved_event_id || h.reserved_event_id === this.state.reserved_event_id);
            });
            if (filtered.length > 0) {
                return (
                    <button
                        style={{ backgroundColor: "#C17F6B" }}
                        className="pink-color-btndcw pinkcolorbutton"
                    >
                        <a>Toegevoegd</a>
                    </button>
                );
            } else {
                let qtyToMinus = 0;
                if (localEventIfSharedThen.inCartOf) {
                    let objected = Object.entries(localEventIfSharedThen.inCartOf);
                    objected = objected.filter((t) => {
                        return t[1].event_id === currentLocalEvent.timings[currentLocalEvent.selectedTimeIndex].eventId
                    })
                    qtyToMinus = qtyToMinus + objected.length;
                }
                if (localEventIfSharedThen.checked_in_by) {
                    let objected = Object.entries(localEventIfSharedThen.checked_in_by);
                    objected = objected.filter((t) => {
                        return t[1].event_id === currentLocalEvent.timings[currentLocalEvent.selectedTimeIndex].eventId
                    })
                    qtyToMinus = qtyToMinus + objected.length;
                }
                if (currentLocalEvent.timings && currentLocalEvent.timings.length > 0) {
                    if (
                        Number(currentLocalEvent.timings[0].personsAmountEvent) -
                        qtyToMinus <=
                        0
                    ) {
                        return (
                            <button style={{ backgroundColor: "#C17F6B", color: "white" }} className="pinkcolorbutton">
                                Uitverkocht
                            </button>
                        );
                    }
                }
                return (
                    <button
                        style={{ position: "relative" }}
                        onClick={this.addToCartLocalEventGuestUser.bind(
                            this,
                            localEventIfSharedThen, index
                        )} className={window.innerWidth < 1008 && "pink-color-btndcw"}
                    >
                        <a>Boek nu</a>
                        {/* <a>WINKELMAND</a> */}
                        <div className="image-live-event addIconCart">
                            <AddIcon />
                        </div>
                    </button>
                );
            }
        } else {
            return (
                <button
                    style={{ position: "relative" }}
                    onClick={this.addToCartLocalEventGuestUser.bind(
                        this,
                        localEventIfSharedThen, index
                    )} className={window.innerWidth < 1008 && "pink-color-btndcw"}
                >
                    <a>Boek nu</a>
                    {/* <a>WINKELMAND</a> */}
                    <div className="image-live-event addIconCart">
                        <AddIcon />
                    </div>
                </button>
            );
        }
    }


    addToCartLocalEventGuestUser(e, index) {
        // let f = e
        // let imgtodrag = document.getElementsByClassName('image-live-event')[0];
        let imgtodrag = document.getElementsByClassName("addIconCart")[index];
        let disLeft = imgtodrag.getBoundingClientRect().left;
        let disTop = imgtodrag.getBoundingClientRect().top;
        let viewcart = document.getElementsByClassName("cart-icon")[0];
        let image = imgtodrag.cloneNode(true);

        let cartleft = viewcart.getBoundingClientRect().left;
        let carttop = viewcart.getBoundingClientRect().top;

        if (document.querySelector(".navbarfullwidthMobile")) {
            document.querySelector(".navbarfullwidthMobile").style.overflowX = "visible"
        }

        image.style =
            "z-index: 99999999999; width: 30px;opacity:0.8; position:fixed; top:" +
            disTop +
            "px;left:" +
            disLeft +
            "px;transition: left 2s, top 2s, width 2s, opacity 2s cubic-bezier(1, 1, 1, 1)";

        var rechange = document.body.appendChild(image);
        setTimeout(function () {
            image.style.left = cartleft + "px";
            image.style.top = carttop + "px";
            image.style.width = "30px";
            image.style.opacity = "0";
        }, 200);
        setTimeout(function () {
            rechange.parentNode.removeChild(rechange);
        }, 2000);

        let arrCart = [...this.state.guestUserCart];
        let cookieItem = localStorage.getItem("cookie_item");

        var minutesToAddDB = Number(process.env.REACT_APP_CART_TIME);
        var currentDateDB = new Date();
        var futureDateDB = new Date(
            currentDateDB.getTime() + minutesToAddDB * 60000
        );

        let futureTimeStampDB = Math.floor(futureDateDB.getTime() / 1000);

        let objToMake = {
            cookieItem,
            itemPush: e.pushKey,
            timestamp: futureTimeStampDB,
            event_id: this.state.reserved_event_id,
        };

        let pushKeyInCartOf = firebase
            .database()
            .ref(
                `bhodi_campaigns/${this.state.currentUser.user_id}/${e.pushKey}/inCartOf`
            )
            .push().key;
        let objInCartOf = {
            cookie: cookieItem,
            event_id: this.state.reserved_event_id,
            pushKey: pushKeyInCartOf,
        };

        objToMake.pushKeyInCartOf = pushKeyInCartOf;
        objToMake.creatorCompanyId = this.state.currentUser.user_id;

        firebase
            .database()
            .ref(
                `bhodi_campaigns/${this.state.currentUser.user_id}/${e.pushKey}/inCartOf/${pushKeyInCartOf}`
            )
            .set(objInCartOf);

        var minutesToAdd = Number(process.env.REACT_APP_CART_TIME);
        var currentDate = new Date();
        var futureDate = new Date(currentDate.getTime() + minutesToAdd * 60000);

        let futureTimeStamp = Math.floor(futureDate.getTime() / 1000);

        arrCart.push(e);

        this.props.updateGuestUserCart(arrCart);
        this.props.updateGuestUserCartTime(futureTimeStamp);
        this.props.updateGuestUserCartExtendOpportunity(true);

        let userid = localStorage.getItem("userid");
        if (userid) {
            objToMake.cookieItem = userid;
            let pushKey = firebase
                .database()
                .ref("guest_carts/" + userid)
                .push().key;
            objToMake.pushKey = pushKey;
            firebase
                .database()
                .ref("guest_carts/" + userid + "/" + pushKey)
                .set(objToMake);
        } else {
            if (cookieItem) {
                let pushKey = firebase
                    .database()
                    .ref("guest_carts/" + cookieItem)
                    .push().key;
                objToMake.pushKey = pushKey;
                firebase
                    .database()
                    .ref("guest_carts/" + cookieItem + "/" + pushKey)
                    .set(objToMake);
            }
        }

        let notificationToShow = document.getElementById("mustShowOnAddBasket");
        if (notificationToShow) {
            notificationToShow.style.opacity = 1;
            notificationToShow.style.pointerEvents = "all";
        }

        setTimeout(() => {
            if (notificationToShow) {
                notificationToShow.style.opacity = 0;
                notificationToShow.style.pointerEvents = "none";
            }
            if (document.querySelector(".navbarfullwidthMobile")) {
                document.querySelector(".navbarfullwidthMobile").style.overflowX = "hidden"
            }
        }, 4000);
    }

    getAboutLocalEventMobile() {
        if (this.state.localEventInfoMobile) {
            return (
                <>
                    <p
                        className="desc-heading-asp"
                        style={{ fontSize: 11, textTransform: "none", marginTop: 30 }}
                    >
                        INFO
                    </p>
                    {this.state.localEventInfoMobile.length > 130 ? (<p className="desc-content-asp" onClick={() => {
                        this.setState({
                            headingOdiMain: "INFO",
                            paraOdiMain: this.state.localEventInfoMobile,
                            openDetailedInner: true,
                        })
                    }}>
                        {this.state.localEventInfoMobile.substr(0, 30)}...
                    </p>) : (<p className="desc-content-asp">
                        {this.state.localEventInfoMobile}
                    </p>)}
                </>
            );
        }
    }

    getUseOfVoucherlocalEventMobile(e) {
        let creator = this.props.user.filter((j) => {
            return j.user_id === e.created_by;
        });
        if (creator.length > 0) {
            if (creator[0].useOfVoucherLocalEvent) {
                return (
                    <>
                        <p
                            className="desc-heading-asp"
                            style={{ fontSize: 11, textTransform: "none", marginTop: 30 }}
                        >
                            GEBRUIK
                        </p>
                        {creator[0].useOfVoucherLocalEvent.length > 130 ? (<p className="desc-content-asp" onClick={() => {
                            this.setState({
                                headingOdiMain: "GEBRUIK",
                                paraOdiMain: creator[0].useOfVoucherLocalEvent,
                                openDetailedInner: true,
                            })
                        }}>
                            {creator[0].useOfVoucherLocalEvent.substr(0, 130)}
                        </p>) : (<p className="desc-content-asp">
                            {creator[0].useOfVoucherLocalEvent}
                        </p>)}
                    </>
                );
            }
        }
    }


    checkForFollowIcon(creatorId) {
        let currentUserMember = this.props.user.filter((t) => {
            return t.user_id === creatorId
        })
        if (currentUserMember[0].followed_by) {
            let objected = Object.entries(currentUserMember[0].followed_by);
            let filtered = objected.filter((g) => {
                return g[1].user_id === this.state.currentUserId;
            });
            if (filtered.length > 0) {
                return (
                    <RiUserUnfollowLine onClick={this.followCompany.bind(this, currentUserMember[0])} />
                );
            } else {
                return (
                    <RiUserFollowLine onClick={this.followCompany.bind(this, currentUserMember[0])} />
                );
            }
        } else {
            return (
                <RiUserFollowLine onClick={this.followCompany.bind(this, currentUserMember[0])} />
            );
        }
    }

    followCompany(currentUser) {
        followUnfollowCompany(currentUser, this.state.currentUserId, this.props.user)
    }

    // followCompany(currentUser) {
    //     if (currentUser.followed_by) {
    //         let objected = Object.entries(currentUser.followed_by);
    //         let filtered = objected.filter((g) => {
    //             return g[1].user_id === this.state.currentUserId;
    //         });
    //         if (filtered.length > 0) {
    //             if (currentUser.newFollowersList) {
    //                 let objectedSec = Object.entries(
    //                     currentUser.newFollowersList
    //                 );
    //                 let filteredNewFollowers = objectedSec.filter((g) => {
    //                     return g[1].user_id === this.state.currentUserId;
    //                 });
    //                 if (filteredNewFollowers.length > 0) {
    //                     let newFollowers = Number(currentUser.newFollowers);
    //                     newFollowers = newFollowers - 1;
    //                     firebase
    //                         .database()
    //                         .ref(
    //                             "users/" +
    //                             currentUser.user_id +
    //                             "/newFollowersList/" +
    //                             filteredNewFollowers[0][1].push_key
    //                         )
    //                         .remove();
    //                     firebase
    //                         .database()
    //                         .ref("users/" + currentUser.user_id + "/newFollowers")
    //                         .set(newFollowers);
    //                 }
    //             }
    //             let filteredCurrentUser = this.props.user.filter((h) => {
    //                 return h.user_id === this.state.currentUserId;
    //             });
    //             if (filteredCurrentUser[0].following) {
    //                 let objectedSec = Object.entries(filteredCurrentUser[0].following);
    //                 let filteredSec = objectedSec.filter((g) => {
    //                     return g[1].user_id === currentUser.user_id;
    //                 });
    //                 if (filteredSec.length > 0) {
    //                     firebase
    //                         .database()
    //                         .ref(
    //                             "users/" +
    //                             this.state.currentUserId +
    //                             "/following/" +
    //                             filteredSec[0][1].push_key
    //                         )
    //                         .remove();
    //                 }
    //             }
    //             firebase
    //                 .database()
    //                 .ref(
    //                     "users/" +
    //                     currentUser.user_id +
    //                     "/followed_by/" +
    //                     filtered[0][1].push_key
    //                 )
    //                 .remove();
    //         } else {
    //             let pushFirst = firebase
    //                 .database()
    //                 .ref("users/" + this.state.currentUserId + "/following")
    //                 .push().key;
    //             let pushSecond = firebase
    //                 .database()
    //                 .ref("users/" + currentUser.user_id + "/followed_by")
    //                 .push().key;
    //             let pushSecondNew = firebase
    //                 .database()
    //                 .ref("users/" + currentUser.user_id + "/newFollowersList")
    //                 .push().key;
    //             let newFollowers = 0;
    //             let obj = {
    //                 user_id: currentUser.user_id,
    //                 push_key: pushFirst,
    //             };
    //             let objSec = {
    //                 user_id: this.state.currentUserId,
    //                 push_key: pushSecond,
    //             };
    //             let objThird = {
    //                 user_id: this.state.currentUserId,
    //                 push_key: pushSecondNew,
    //             };
    //             if (currentUser.newFollowers) {
    //                 newFollowers = Number(currentUser.newFollowers);
    //             }
    //             newFollowers = newFollowers + 1;
    //             firebase
    //                 .database()
    //                 .ref("users/" + this.state.currentUserId + "/following/" + pushFirst)
    //                 .set(obj);
    //             firebase
    //                 .database()
    //                 .ref(
    //                     "users/" +
    //                     currentUser.user_id +
    //                     "/followed_by/" +
    //                     pushSecond
    //                 )
    //                 .set(objSec);
    //             firebase
    //                 .database()
    //                 .ref("users/" + currentUser.user_id + "/newFollowers")
    //                 .set(newFollowers);
    //             firebase
    //                 .database()
    //                 .ref(
    //                     "users/" +
    //                     currentUser.user_id +
    //                     "/newFollowersList/" +
    //                     pushSecondNew
    //                 )
    //                 .set(objThird);
    //         }
    //     } else {
    //         let pushFirst = firebase
    //             .database()
    //             .ref("users/" + this.state.currentUserId + "/following")
    //             .push().key;
    //         let pushSecond = firebase
    //             .database()
    //             .ref("users/" + currentUser.user_id + "/followed_by")
    //             .push().key;
    //         let pushSecondNew = firebase
    //             .database()
    //             .ref("users/" + currentUser.user_id + "/newFollowersList")
    //             .push().key;
    //         let newFollowers = 0;
    //         let obj = {
    //             user_id: currentUser.user_id,
    //             push_key: pushFirst,
    //         };
    //         let objSec = {
    //             user_id: this.state.currentUserId,
    //             push_key: pushSecond,
    //         };
    //         let objThird = {
    //             user_id: this.state.currentUserId,
    //             push_key: pushSecondNew,
    //         };
    //         if (currentUser.newFollowers) {
    //             newFollowers = Number(currentUser.newFollowers);
    //         }
    //         newFollowers = newFollowers + 1;
    //         firebase
    //             .database()
    //             .ref("users/" + this.state.currentUserId + "/following/" + pushFirst)
    //             .set(obj);
    //         firebase
    //             .database()
    //             .ref(
    //                 "users/" +
    //                 currentUser.user_id +
    //                 "/followed_by/" +
    //                 pushSecond
    //             )
    //             .set(objSec);
    //         firebase
    //             .database()
    //             .ref("users/" + currentUser.user_id + "/newFollowers")
    //             .set(newFollowers);
    //         firebase
    //             .database()
    //             .ref(
    //                 "users/" +
    //                 currentUser.user_id +
    //                 "/newFollowersList/" +
    //                 pushSecondNew
    //             )
    //             .set(objThird);
    //     }
    // }

    // createMarkup(e) {
    //     if (e) {
    //         return { __html: e };
    //     }
    // }

    truncateAndSanitize(html, maxLength) {
        // Use DOMPurify to sanitize the HTML content.
        const sanitizedHTML = DOMPurify.sanitize(html);

        if (sanitizedHTML.length <= maxLength) {
            return sanitizedHTML;
        } else {
            return sanitizedHTML.substring(0, maxLength) + '...';
        }
    };

    createMarkup(e) {
        if (e) {
            if (window.innerWidth < 1008) {
                const truncatedDescription = this.truncateAndSanitize(e, 80);

                return { __html: truncatedDescription };
            } else {
                return { __html: e };
            }
        }
    }

    createDescPopup(e) {
        if (e) {
            return { __html: e };
        }
    }


    // webshop 


    checkForVerzilverButton(e, index) {
        if (this.state.currentUserHere) {
            if ((this.state.currentUserHere.subscription && this.state.currentUserHere.subscription.status === "active") || checkForSubscribedExclusives(this.state.currentUserHere)) {
                if (e.verzilvered_by) {
                    let objected = Object.entries(e.verzilvered_by);
                    let filtered = objected.filter((h) => {
                        return h[1].user_id === this.state.currentUserId;
                    });
                    if (filtered.length > 0) {
                        return (
                            <button
                                style={{
                                    backgroundColor: "#C17F6B",
                                    display: "inline-flex",
                                    alignItems: "center",
                                }}
                                className="pinkcolorbutton"
                            >
                                <CheckIcon
                                    style={{ fontSize: 18, marginRight: 5, marginLeft: 0 }}
                                />{" "}
                                {window.innerWidth > 1008 ? `In orderoverzicht` : `Overzicht`}
                            </button>
                        );
                    }
                }
                if (!e.voucherUnlimited) {
                    let bewaaredArray = [];
                    let num = 0;
                    if (e.verzilvered_by) {
                        let objectedCheckedIn = Object.entries(e.verzilvered_by);
                        num = this.getVerzilveredQty(objectedCheckedIn);
                    }
                    if (e.inCartOf) {
                        let objectedInCartOf = Object.entries(e.inCartOf);
                        num = num + objectedInCartOf.length;
                    }
                    if (num + bewaaredArray.length < Number(e.number)) {
                        if (window.innerWidth > 1008) {
                            if (e.verzilvered_by) {
                                let objected = Object.entries(e.verzilvered_by);
                                let filtered = objected.filter((h) => {
                                    return h[1].user_id === this.state.currentUserId;
                                });
                                if (filtered.length > 0) {
                                    return (
                                        <button
                                            className="pinkcolorbutton"
                                            style={{
                                                backgroundColor: "#C17F6B",
                                                display: "inline-flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <CheckIcon
                                                style={{ fontSize: 18, marginRight: 5, marginLeft: 0 }}
                                            />{" "}
                                            {window.innerWidth > 1008 ? `In orderoverzicht` : `Overzicht`}
                                        </button>
                                    );
                                }
                            }

                            return (
                                <button onClick={this.verzilverVoucher.bind(this, e)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                    Valideren
                                </button>
                            );
                        } else {
                            if (
                                this.state.currentUserHere.stripe_id &&
                                !this.state.userNotSubscribed
                            ) {
                                if (e.verzilvered_by) {
                                    let objected = Object.entries(e.verzilvered_by);
                                    let filtered = objected.filter((h) => {
                                        return h[1].user_id === this.state.currentUserId;
                                    });
                                    if (filtered.length > 0) {
                                        return (
                                            <button
                                                style={{
                                                    backgroundColor: "#C17F6B",
                                                    display: "inline-flex",
                                                    alignItems: "center",
                                                }}
                                                className="pinkcolorbutton"
                                            >
                                                <CheckIcon
                                                    style={{
                                                        fontSize: 18,
                                                        marginRight: 5,
                                                        marginLeft: 0,
                                                    }}
                                                />{" "}
                                                {window.innerWidth > 1008
                                                    ? `In orderoverzicht`
                                                    : `Overzicht`}
                                            </button>
                                        );
                                    }
                                }
                                return (
                                    <button onClick={this.verzilverVoucher.bind(this, e)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                        Valideren{" "}
                                    </button>
                                );
                            } else {
                                return (
                                    <button onClick={this.verzilverVoucher.bind(this, e)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                        Valideren
                                    </button>
                                );
                            }
                        }
                    } else {
                        return (
                            <button
                                style={{ backgroundColor: "#C17F6B" }}
                                className="pinkcolorbutton"
                            >
                                Uitverkocht
                            </button>
                        );
                    }
                } else {
                    if (window.innerWidth > 1008) {
                        if (e.verzilvered_by) {
                            let objected = Object.entries(e.verzilvered_by);
                            let filtered = objected.filter((h) => {
                                return h[1].user_id === this.state.currentUserId;
                            });
                            if (filtered.length > 0) {
                                return (
                                    <button
                                        style={{
                                            backgroundColor: "#C17F6B",
                                            display: "inline-flex",
                                            alignItems: "center",
                                        }}
                                        className="pinkcolorbutton"
                                    >
                                        <CheckIcon
                                            style={{ fontSize: 18, marginRight: 5, marginLeft: 0 }}
                                        />{" "}
                                        {window.innerWidth > 1008
                                            ? `In orderoverzicht`
                                            : `Overzicht`}
                                    </button>
                                );
                            }
                        }
                        return (
                            <button onClick={this.verzilverVoucher.bind(this, e)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                Valideren
                            </button>
                        );
                    } else {
                        if (
                            this.state.currentUserHere.stripe_id &&
                            !this.state.userNotSubscribed
                        ) {
                            if (e.verzilvered_by) {
                                let objected = Object.entries(e.verzilvered_by);
                                let filtered = objected.filter((h) => {
                                    return h[1].user_id === this.state.currentUserId;
                                });
                                if (filtered.length > 0) {
                                    return (
                                        <button
                                            style={{
                                                backgroundColor: "#C17F6B",
                                                display: "inline-flex",
                                                alignItems: "center",
                                            }}
                                            className="pinkcolorbutton"
                                        >
                                            <CheckIcon
                                                style={{ fontSize: 18, marginRight: 5, marginLeft: 0 }}
                                            />{" "}
                                            {window.innerWidth > 1008
                                                ? `In orderoverzicht`
                                                : `Overzicht`}
                                        </button>
                                    );
                                }
                            }
                            return (
                                <button onClick={this.verzilverVoucher.bind(this, e)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                    Valideren
                                </button>
                            );
                        } else {
                            return (
                                <button>
                                    Register
                                </button>
                            );
                        }
                    }
                }
            } else {
                if (this.state.guestUserCart) {
                    let filtered = this.state.guestUserCart.filter((h) => {
                        return h.pushKey === e.pushKey;
                    });
                    if (filtered.length > 0) {
                        return (
                            <button
                                style={{ backgroundColor: "#C17F6B" }}
                                className="pinkcolorbutton"
                            >
                                Toegevoegd
                            </button>
                        );
                    } else {
                        if (!e.voucherUnlimited) {
                            let num = 0;
                            if (e.verzilvered_by) {
                                let objectedCheckedIn = Object.entries(e.verzilvered_by);
                                num = this.getVerzilveredQty(objectedCheckedIn);
                            }
                            if (e.inCartOf) {
                                let objectedInCartOf = Object.entries(e.inCartOf);
                                num = num + objectedInCartOf.length;
                            }
                            if (num < Number(e.number)) {
                                return (
                                    <button onClick={this.addToCartNonUsers.bind(this, e, index)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                        Boek nu
                                    </button>
                                );
                            } else {
                                return (
                                    <button
                                        style={{ backgroundColor: "#C17F6B" }}
                                        className="pinkcolorbutton"
                                    >
                                        Uitverkocht
                                    </button>
                                );
                            }
                        } else {
                            return (
                                <button onClick={this.addToCartNonUsers.bind(this, e, index)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                    Boek nu
                                </button>
                            );
                        }
                    }
                } else {
                    if (!e.voucherUnlimited) {
                        let num = 0;
                        if (e.verzilvered_by) {
                            let objectedCheckedIn = Object.entries(e.verzilvered_by);
                            num = this.getVerzilveredQty(objectedCheckedIn);
                        }
                        if (e.inCartOf) {
                            let objectedInCartOf = Object.entries(e.inCartOf);
                            num = num + objectedInCartOf.length;
                        }
                        if (num < Number(e.number)) {
                            return (
                                <button onClick={this.addToCartNonUsers.bind(this, e, index)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                    Boek nu
                                </button>
                            );
                        } else {
                            return (
                                <button
                                    style={{ backgroundColor: "#C17F6B" }}
                                    className="pinkcolorbutton"
                                >
                                    Uitverkocht
                                </button>
                            );
                        }
                    } else {
                        return (
                            <button onClick={this.addToCartNonUsers.bind(this, e, index)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                Boek nu
                            </button>
                        );
                    }
                }
            }
        } else {
            if (this.state.guestUserCart) {
                let filtered = this.state.guestUserCart.filter((h) => {
                    return h.pushKey === e.pushKey;
                });
                if (filtered.length > 0) {
                    return (
                        <button
                            style={{ backgroundColor: "#C17F6B" }}
                            className="pinkcolorbutton"
                        >
                            Toegevoegd
                        </button>
                    );
                } else {
                    if (!e.voucherUnlimited) {
                        let num = 0;
                        if (e.verzilvered_by) {
                            let objectedCheckedIn = Object.entries(e.verzilvered_by);
                            num = this.getVerzilveredQty(objectedCheckedIn);
                        }
                        if (e.inCartOf) {
                            let objectedInCartOf = Object.entries(e.inCartOf);
                            num = num + objectedInCartOf.length;
                        }
                        if (num < Number(e.number)) {
                            return (
                                <button onClick={this.addToCartNonUsers.bind(this, e, index)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                    Boek nu
                                </button>
                            );
                        } else {
                            return (
                                <button
                                    style={{ backgroundColor: "#C17F6B" }}
                                    className="pinkcolorbutton"
                                >
                                    Uitverkocht
                                </button>
                            );
                        }
                    } else {
                        return (
                            <button onClick={this.addToCartNonUsers.bind(this, e, index)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                Boek nu
                            </button>
                        );
                    }
                }
            } else {
                if (!e.voucherUnlimited) {
                    let num = 0;
                    if (e.verzilvered_by) {
                        let objectedCheckedIn = Object.entries(e.verzilvered_by);
                        num = this.getVerzilveredQty(objectedCheckedIn);
                    }
                    if (e.inCartOf) {
                        let objectedInCartOf = Object.entries(e.inCartOf);
                        num = num + objectedInCartOf.length;
                    }
                    if (num < Number(e.number)) {
                        return (
                            <button onClick={this.addToCartNonUsers.bind(this, e, index)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                Boek nu
                            </button>
                        );
                    } else {
                        return (
                            <button
                                style={{ backgroundColor: "#C17F6B" }}
                                className="pinkcolorbutton"
                            >
                                Uitverkocht
                            </button>
                        );
                    }
                } else {
                    return (
                        <button onClick={this.addToCartNonUsers.bind(this, e, index)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                            Boek nu
                        </button>
                    );
                }
            }
        }
    }

    verzilverVoucher(e) {
        if (this.props.user) {
            let filtered = this.props.user.filter((g) => {
                return g.user_id === e.created_by;
            });
            if (filtered.length > 0) {
                this.setState({ currentUser: filtered[0] });
            }
        }
        this.setState({
            verzilverPopupWebShop: true,
            currentActie: e,
            currentActieType: "web_shop",
        });
    }

    checkForButtonsverzilverVoucher(currentActie) {
        return (
            <div style={{ display: "flex", marginTop: 20, justifyContent: "center" }}>
                {!isDesktop ? (
                    <button
                        style={{ marginRight: 5 }}
                        onClick={this.proceedVoucher.bind(this, currentActie)}
                    >
                        OK
                    </button>
                ) : (
                    <button
                        style={{ marginRight: 5 }}
                        onClick={this.proceedVoucher.bind(this, currentActie)}
                    >
                        OK
                    </button>
                )}
            </div>
        );
    }

    proceedVoucher() {
        let order_id = this.state.orderidToUpload + 1;
        firebase.database().ref("current_order_id").set(order_id)
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, "0");
        let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        let yyyy = today.getFullYear();

        today = mm + "/" + dd + "/" + yyyy;
        let arrSellers = [];
        arrSellers.push(this.state.currentUser.user_id);

        if (this.state.currentUser.expireTypeVoucher && this.state.currentUser.expireDateVoucher) {
            this.state.currentActie.expireTypeVoucher = this.state.currentUser.expireTypeVoucher
            this.state.currentActie.expireDateVoucher = this.state.currentUser.expireDateVoucher
        }

        const min = 0;
        const max = this.state.currentActie.coupons.length;
        const intNumber = Math.floor(Math.random() * (max - min)) + min;

        this.state.currentActie.redeemed_code = this.state.currentActie.coupons[intNumber];
        if (document.querySelector(".navbarfullwidthMobile")) {
            document.querySelector(".navbarfullwidthMobile").style.overflowX = "visible"
        }

        let obj = {
            created_on: today,
            order_id,
            products: [this.state.currentActie],
            buyer: this.state.currentUserId,
            sellers: arrSellers,
            timeStampCreated: new Date().getTime(),
            order_type: "campaign",
        };
        // let pushuserOrderCurrentUser = firebase.database().ref("users/" + this.state.currentUserId + "/orders").push().key;

        // firebase.database().ref("users/" + this.state.currentUserId + "/orders/" + pushuserOrderCurrentUser).set(obj);

        // let pushuserOrder = firebase
        //     .database()
        //     .ref("users/" + this.state.currentUser.user_id + "/orders")
        //     .push().key;
        // firebase
        //     .database()
        //     .ref(
        //         "users/" + this.state.currentUser.user_id + "/orders/" + pushuserOrder
        //     )
        //     .set(obj);
        let orderKey = firebase.database().ref("all_orders").push().key;
        obj.pushKey = orderKey;
        firebase
            .database()
            .ref("all_orders/" + orderKey)
            .set(obj);
        if (this.state.currentActie.verzilvered_by) {
            let objected = Object.entries(this.state.currentActie.verzilvered_by);
            let filtered = objected.filter((h) => {
                return h[1].user_id === this.state.currentUserId;
            });
            if (filtered.length > 0) {
                let newDate = new Date().getTime();
                let arr = filtered[0][1].allDates;
                arr.push(newDate);
                let pushKey = filtered[0][1].pushKey;
                let obj = {
                    user_id: this.state.currentUserId,
                    date: newDate,
                    allDates: arr,
                    pushKey,
                };
                firebase
                    .database()
                    .ref(
                        "bhodi_campaigns/" +
                        this.state.currentUser.user_id +
                        "/" +
                        this.state.currentActie.pushKey +
                        "/verzilvered_by/" +
                        filtered[0][1].pushKey
                    )
                    .set(obj);
            } else {
                let newDate = new Date().getTime();
                let arr = [];
                arr.push(newDate);
                let pushKey = firebase
                    .database()
                    .ref(
                        "bhodi_campaigns/" +
                        this.state.currentUser.user_id +
                        "/" +
                        this.state.currentActie.pushKey +
                        "/verzilvered_by"
                    )
                    .push().key;
                let obj = {
                    user_id: this.state.currentUserId,
                    date: newDate,
                    allDates: arr,
                    pushKey,
                };
                firebase
                    .database()
                    .ref(
                        "bhodi_campaigns/" +
                        this.state.currentUser.user_id +
                        "/" +
                        this.state.currentActie.pushKey +
                        "/verzilvered_by/" +
                        pushKey
                    )
                    .set(obj);
            }
        } else {
            let newDate = new Date().getTime();
            let arr = [];
            arr.push(newDate);
            let pushKey = firebase
                .database()
                .ref(
                    "bhodi_campaigns/" +
                    this.state.currentUser.user_id +
                    "/" +
                    this.state.currentActie.pushKey +
                    "/verzilvered_by"
                )
                .push().key;
            let obj = {
                user_id: this.state.currentUserId,
                date: newDate,
                allDates: arr,
                pushKey,
            };
            firebase
                .database()
                .ref(
                    "bhodi_campaigns/" +
                    this.state.currentUser.user_id +
                    "/" +
                    this.state.currentActie.pushKey +
                    "/verzilvered_by/" +
                    pushKey
                )
                .set(obj);
        }

        let notificationToShow = document.getElementById("mustShowOnAddOrder");
        if (notificationToShow) {
            notificationToShow.style.opacity = 1;
            notificationToShow.style.pointerEvents = "all";
        }

        setTimeout(() => {
            if (notificationToShow) {
                notificationToShow.style.opacity = 0;
                notificationToShow.style.pointerEvents = "none";
            }
            if (document.querySelector(".navbarfullwidthMobile")) {
                document.querySelector(".navbarfullwidthMobile").style.overflowX = "hidden"
            }
        }, 4000);
        this.setState({ verzilverPopupWebShop: false, verzilverPopupGreenWebShop: true, currentActie: "", currentActieType: "" });
    }

    render() {
        return (
            <div className="third-section-mhn tsmhn-global">

                {/* Doorlopende Verzilver */}
                <div
                    className="verzilver-popup-white-opacity"
                    style={{
                        opacity: this.state.verzilverPopup ? 1 : 0,
                        pointerEvents: this.state.verzilverPopup ? "all" : "none",
                    }}
                ></div>
                <div
                    className="verzilver-popup-white"
                    style={{
                        opacity: this.state.verzilverPopup ? 1 : 0,
                        pointerEvents: this.state.verzilverPopup ? "all" : "none",
                    }}
                >
                    <CloseIcon
                        className="close-hpw"
                        onClick={() => this.setState({ verzilverPopup: false })}
                    />
                    <LockOpenIcon className="lock-hpw" />
                    <p className="heading-hpw">VALIDEER KORTINGSVOUCHER</p>
                    <p className="desc-hpw">
                        Leuk dat je gebruik wil maken van
                        <br />
                        onze kortingsvoucher. Deze kun je
                        <br />
                        valideren vanuit je orderoverzicht.
                        <br />
                        {/* <br />
                        <b>Let op</b>: de kortingsvoucher blijft
                        <br />
                        24 uur voor jou gereserveerd
                        <br /> */}
                        <br />
                        Klik op OK om de kortingsvoucher
                        <br />
                        in je orderoverzicht te plaatsen.
                    </p>
                    {this.state.currentActie && this.checkForButtonsVerzilverKortingsVoucher(this.state.currentActie)}
                </div>
                {/* Doorlopende verzilver */}

                {/* local event verzilver */}
                <div
                    className="verzilver-popup-white-opacity"
                    style={{
                        opacity: this.state.verzilverPopupLocalEvent ? 1 : 0,
                        pointerEvents: this.state.verzilverPopupLocalEvent ? "all" : "none",
                    }}
                ></div>
                <div
                    className="verzilver-popup-white"
                    style={{
                        opacity: this.state.verzilverPopupLocalEvent ? 1 : 0,
                        pointerEvents: this.state.verzilverPopupLocalEvent ? "all" : "none",
                    }}
                >
                    <CloseIcon
                        className="close-hpw"
                        onClick={() => this.setState({ verzilverPopupLocalEvent: false })}
                    />
                    <p
                        className="heading-hpw"
                        style={{
                            textTransform: "uppercase",
                            marginBottom: 6,
                            fontSize: 16,
                            letterSpacing: 2,
                        }}
                    >
                        Aanmelding
                    </p>
                    <div
                        style={{
                            width: 40,
                            height: 3,
                            backgroundColor: "#C17F6B",
                            margin: "0px auto",
                            marginBottom: 25,
                        }}
                    ></div>
                    <p className="desc-hpw">
                        Leuk, je staat op het punt om je
                        <br />
                        aan te melden voor ons event!
                        <br />
                        <br />
                        Klik op de onderstaande knop
                        <br />
                        om dit te bevestigen.
                    </p>
                    <div style={{ display: "flex", marginTop: 20, justifyContent: "center" }}>
                        <button
                            style={{ marginRight: 5 }}
                            onClick={() => {
                                this.proceedLocalBlock(this.state.currentActieLocalEvent)
                            }}
                        >
                            OK
                        </button>
                    </div>
                </div>
                <div
                    className="verzilver-popup-white-opacity"
                    style={{
                        opacity: this.state.verzilverPopupGreenLocalEvent ? 1 : 0,
                        pointerEvents: this.state.verzilverPopupGreenLocalEvent
                            ? "all"
                            : "none",
                    }}
                ></div>
                <div
                    className="verzilver-popup-white"
                    style={{
                        opacity: this.state.verzilverPopupGreenLocalEvent ? 1 : 0,
                        pointerEvents: this.state.verzilverPopupGreenLocalEvent
                            ? "all"
                            : "none",
                    }}
                >
                    <CloseIcon
                        className="close-hpw"
                        onClick={() =>
                            this.setState({ verzilverPopupGreenLocalEvent: false })
                        }
                    />
                    <p
                        className="heading-hpw"
                        style={{
                            textTransform: "uppercase",
                            marginBottom: 6,
                            fontSize: 16,
                            letterSpacing: 2,
                        }}
                    >
                        {this.state.currentUser.company_name}
                    </p>
                    <p className="heading-hpw" style={{ textTransform: "uppercase" }}>
                        Bevestiging
                    </p>
                    <div
                        style={{
                            width: 40,
                            height: 3,
                            backgroundColor: "#C17F6B",
                            margin: "0px auto",
                            marginBottom: 25,
                        }}
                    ></div>
                    <p className="desc-hpw">
                        Je aanmelding is ontvangen, <br />
                        deze vind je terug in je <br />
                        <u
                            style={{ cursor: "pointer" }}
                            onClick={() => (window.location.href = "/order-list")}
                        >
                            orderoverzicht
                        </u>
                        .
                        <br />
                        <br />
                        {/* We zien je graag op{" "}
                        <br />
                        {this.getStartTimeOfReservedEvent(this.state.currentActieLocalEvent)} tot{" "}
                        {this.getEndTimeOfReservedEvent(this.state.currentActieLocalEvent)}
                        <br /> */}
                        Wij verheugen ons op je komst! <br />
                    </p>
                    {/* {this.state.currentUser.phoneNumber && <p className="heading-hpw" style={{ marginBottom: 6 }}>Tel: {this.state.currentUser.phoneNumber}</p>}
                    {this.state.currentUser.company_website && <p className="heading-hpw">{this.state.currentUser.company_website}</p>} */}
                    <button
                        style={{ backgroundColor: "#C17F6B" }}
                        onClick={() => {
                            this.setState({
                                verzilverPopupLocalEvent: false,
                                verzilverPopupGreenLocalEvent: false,
                                currentActieLocalEvent: "",
                            });
                        }}
                    >
                        SLUIT
                    </button>
                </div>

                {/* local event verzilver */}


                {/* Webshop Verzilver */}
                <div
                    className="verzilver-popup-white-opacity"
                    style={{
                        opacity: this.state.verzilverPopupWebShop ? 1 : 0,
                        pointerEvents: this.state.verzilverPopupWebShop ? "all" : "none",
                    }}
                ></div>
                <div
                    className="verzilver-popup-white"
                    style={{
                        opacity: this.state.verzilverPopupWebShop ? 1 : 0,
                        pointerEvents: this.state.verzilverPopupWebShop ? "all" : "none",
                    }}
                >
                    <CloseIcon
                        className="close-hpw"
                        onClick={() => this.setState({ verzilverPopupWebShop: false })}
                    />
                    <LockOpenIcon className="lock-hpw" />
                    <p className="heading-hpw">VALIDEER COUPONCODE</p>
                    <p className="desc-hpw">
                        Leuk dat je gebruik wil maken van
                        <br />
                        onze kortingsvoucher. Deze kun je
                        <br />
                        valideren vanuit je orderoverzicht.
                        <br />
                        {/* <br />
                        <b>Let op</b>: de kortingsvoucher blijft
                        <br />
                        24 uur voor jou gereserveerd
                        <br /> */}
                        <br />
                        Klik op OK om de kortingsvoucher
                        <br />
                        in je orderoverzicht te plaatsen.
                    </p>
                    {this.state.currentActie && this.checkForButtonsverzilverVoucher(this.state.currentActie)}
                </div>
                {/* Webshop verzilver */}



                {this.state.allCampaigns.length > 0 &&
                    this.state.allCampaigns.slice(0, this.state.defaultLoad).map((g, ind) => {
                        if (g.campaignType === "kortings_voucher") {
                            return (
                                <div style={{ position: "relative" }}>
                                    {window.innerWidth < 1008 && (<div className="top-userinfo-tsco">
                                        <img src={this.getUserProfilePic(g.created_by)} onClick={() => {
                                            localStorage.setItem("scrollToTop", true)
                                            this.redirectUser(g.created_by)
                                        }} />
                                        <div className="cont-tus-tsco">
                                            <p onClick={() => {
                                                localStorage.setItem("scrollToTop", true)
                                                this.redirectUser(g.created_by)
                                            }}>{this.getUserName(g.created_by)}</p>
                                            <p>{this.getUserCity(g.created_by)}</p>
                                        </div>
                                        <div className="icons-main-lcl">
                                            {this.state.currentUserHere.user_id === g.created_by && <div className="icons-lcltsmhnc" style={{ display: "inline-block", marginRight: 0 }} onClick={() => {
                                                if (window.innerWidth > 1008) {
                                                    this.props.onEditOpen()
                                                } else {
                                                    localStorage.setItem("kortingsVoucherInfo", JSON.stringify(g))
                                                    history.push(`/editor/kortings_voucher`)
                                                }
                                            }}>
                                                <AiOutlineEdit style={{ color: "white" }} />
                                            </div>}
                                            {this.state.currentUserHere.user_id !== g.created_by && this.state.currentUserId && this.checkForFollowIcon(g.created_by)}
                                        </div>
                                    </div>)}
                                    <div className='tsmhn-container' style={{ marginTop: ind === 0 && 0 }}>
                                        <div className='left-tsmhnc'>
                                            <div className='container-ltsmhnc'>
                                                {g.kortingsVoucherGiveaway ? (<p>Giveaway</p>) : (<p>Voucher</p>)}
                                                {/* <p>Voucher</p> */}
                                                <p>{g.title}</p>
                                                {!g.active && (<p style={{ color: "#C17F6B" }}>Actie is niet actief</p>)}
                                                <p>
                                                    <div dangerouslySetInnerHTML={this.createMarkup(g.desc)} className="description-content-cltsm" onClick={() => {
                                                        const truncatedDescription = this.truncateAndSanitize(g.desc, 80);
                                                        if (window.innerWidth < 1008 && truncatedDescription.length > 80) {
                                                            this.setState({ showDescriptionPopup: true, currentCampaignInfoOpen: g })
                                                        }
                                                    }}></div>
                                                </p>
                                                {/* {(window.innerWidth < 1008 && g.desc.length > 80) ? (<p onClick={() => {
                                                    this.setState({ showDescriptionPopup: true, currentCampaignInfoOpen: g })
                                                }}>{g.desc.substr(0, 80)}...</p>) : (<p>{g.desc}</p>)} */}
                                                {!(g.timerDateWaiting && g.timerTimeWaiting && new Date(Date.parse(g.isoTimeTimerBtn)).getTime() > new Date().getTime()) && (!g.kortingsVoucherPrijs && !g.kortingsVoucherPercentage && g.isoTimeTimerBtnEndTime && new Date(g.isoTimeTimerBtnEndTime).getTime() > new Date().getTime()) && (<p>
                                                    {<KortingsVoucherTimer timerToDo={g.isoTimeTimerBtnEndTime} salePrice={g.kortingsVoucherSalePrice} closeScreen={() => {
                                                        this.setState({ updatePricing: true })
                                                    }} />}
                                                </p>)}
                                                {g.kortingsVoucherPrijs && (<p style={{ marginTop: 10 }}>
                                                    <font>
                                                        €
                                                        <NumberFormatter
                                                            toFormat={g.amount}
                                                        />{" "}
                                                        Korting
                                                    </font>
                                                </p>)}
                                                {g.kortingsVoucherPercentage && (<p style={{ marginTop: 10 }}>
                                                    <font>
                                                        Korting{" "}
                                                        {g.amount}
                                                        {" "}%
                                                    </font>
                                                </p>)}
                                                {(!g.kortingsVoucherPrijs && !g.kortingsVoucherPercentage && g.isoTimeTimerBtnEndTime) ? (<p style={{ marginTop: 10 }}>
                                                    {(new Date(g.isoTimeTimerBtnEndTime).getTime() > new Date().getTime()) ? (
                                                        <>
                                                            <s>
                                                                €
                                                                <NumberFormatter
                                                                    toFormat={g.kortingsVoucherNormalPrice}
                                                                />{" "}
                                                            </s>
                                                            <div style={{ width: 15, height: 1, display: "inline-block" }}></div>
                                                            <font>
                                                                €
                                                                <NumberFormatter
                                                                    toFormat={g.kortingsVoucherSalePrice}
                                                                />{" "}
                                                            </font>
                                                        </>
                                                    ) : <>
                                                        €
                                                        <NumberFormatter
                                                            toFormat={g.kortingsVoucherNormalPrice}
                                                        />{" "}
                                                    </>}
                                                </p>) : g.kortingsVoucherGiveaway ? (<p>Free</p>) : (!g.kortingsVoucherPrijs && !g.kortingsVoucherPercentage) && (<p style={{ marginTop: 10 }}>
                                                    €
                                                    <NumberFormatter
                                                        toFormat={g.kortingsVoucherSalePrice}
                                                    />{" "}
                                                </p>)}
                                                {/* <button>BOEK NU</button> */}
                                                {g.active && <div style={{ position: "relative" }}>
                                                    {(g.timerDateWaiting && g.timerTimeWaiting && new Date(Date.parse(g.isoTimeTimerBtn)).getTime() > new Date().getTime()) && <ButtonTimerCampaign mobileNewSpacing={true} showLabelAbsolute={true} labelOnOwnPage={true} timerToDo={new Date(Date.parse(g.isoTimeTimerBtn)).getTime()} atProduct={g} currentUserHere={this.state.currentUserHere} guestUserCart={this.state.guestUserCart} timeToCalculate={new Date(Number(g.timerDateWaiting.split("-")[0]), Number(g.timerDateWaiting.split("-")[1] - 1), Number(g.timerDateWaiting.split("-")[2]), Number(g.timerTimeWaiting.split(":")[0]), Number(g.timerTimeWaiting.split(":")[1]), 0).getTime()}
                                                        closeScreen={() => {
                                                            this.setState({ updatePricing: true })
                                                        }}
                                                    />
                                                    }
                                                    {this.checkForVerzilverKortingsVoucher(g, ind)}
                                                    <div className="image-front addIconCart">
                                                        <AddIcon />
                                                    </div>
                                                </div>}
                                                <div className="icons-cltsmhnc-mob">
                                                    <div className="icons-lcltsmhnc">
                                                        <VscInfo onClick={() => {
                                                            this.setState({ showMoreDotsPopup: true, currentCampaignInfoOpen: g })
                                                        }} />
                                                    </div>
                                                    {this.state.currentUserId && this.checkForBookMarkIcon(
                                                        g
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='right-tsmhnc' style={{ backgroundImage: g.image ? `url("${(window.innerWidth < 1008 && g.image_mobile) ? g.image_mobile : g.image}")` : "none", overflow: "hidden" }}>
                                            <div className="overlay-rtsmhnc"></div>
                                        </div>
                                    </div>
                                </div>
                            );
                        } else if (g.campaignType === "web_shop") {
                            return (
                                <div style={{ position: "relative" }}>
                                    {window.innerWidth < 1008 && (<div className="top-userinfo-tsco">
                                        <img src={this.getUserProfilePic(g.created_by)} onClick={() => {
                                            localStorage.setItem("scrollToTop", true)
                                            this.redirectUser(g.created_by)
                                        }} />
                                        <div className="cont-tus-tsco">
                                            <p onClick={() => {
                                                localStorage.setItem("scrollToTop", true)
                                                this.redirectUser(g.created_by)
                                            }}>{this.getUserName(g.created_by)}</p>
                                            <p>{this.getUserCity(g.created_by)}</p>
                                        </div>
                                        <div className="icons-main-lcl">
                                            {this.state.currentUserHere.user_id === g.created_by && <div className="icons-lcltsmhnc" style={{ display: "inline-block", marginRight: 0 }} onClick={() => {
                                                if (window.innerWidth > 1008) {
                                                    this.props.onEditOpen()
                                                } else {
                                                    localStorage.setItem("webShopInfo", JSON.stringify(g))
                                                    history.push(`/editor/web_shop`)
                                                }
                                            }}>
                                                <AiOutlineEdit style={{ color: "white" }} />
                                            </div>}
                                            {this.state.currentUserHere.user_id !== g.created_by && this.state.currentUserId && this.checkForFollowIcon(g.created_by)}
                                        </div>
                                    </div>)}
                                    <div className='tsmhn-container' style={{ marginTop: ind === 0 && 0 }}>
                                        <div className='left-tsmhnc'>
                                            <div className='container-ltsmhnc'>
                                                <p>Webshop Coupon</p>
                                                <p>{g.title}</p>
                                                {!g.active && (<p style={{ color: "#C17F6B" }}>Actie is niet actief</p>)}
                                                <p>
                                                    <div dangerouslySetInnerHTML={this.createMarkup(g.desc)} className="description-content-cltsm" onClick={() => {
                                                        const truncatedDescription = this.truncateAndSanitize(g.desc, 80);
                                                        if (window.innerWidth < 1008 && truncatedDescription.length > 80) {
                                                            this.setState({ showDescriptionPopup: true, currentCampaignInfoOpen: g })
                                                        }
                                                    }}></div>
                                                </p>
                                                {g.isoStartTime && g.isoEndTime && (<p style={{ marginTop: 10 }}>{this.getFormattedDateWebShop(g)}</p>)}
                                                {!g.voucherIsPercentage && (<p style={{ marginTop: 10 }}>
                                                    <font>
                                                        €
                                                        <NumberFormatter
                                                            toFormat={g.amount}
                                                        />{" "}
                                                        Korting
                                                    </font>
                                                </p>)}
                                                {g.voucherIsPercentage && (<p style={{ marginTop: 10 }}>
                                                    <font>
                                                        Korting{" "}
                                                        {g.amount}
                                                        {" "}%
                                                    </font>
                                                </p>)}

                                                {g.active && <div style={{ position: "relative" }}>
                                                    {((g.isoTimeTimerBtn && !g.isoStartTime && new Date(Date.parse(g.isoTimeTimerBtn)).getTime() > new Date().getTime()) || (g.isoTimeTimerBtn && g.isoStartTime && new Date(Date.parse(g.isoTimeTimerBtn)).getTime() > new Date(Date.parse(g.isoStartTime)) && new Date(Date.parse(g.isoTimeTimerBtn)).getTime() > new Date().getTime())) ?
                                                        (
                                                            <ButtonTimerCampaign mobileNewSpacing={true} showLabelAbsolute={true} labelOnOwnPage={true} timerToDo={new Date(Date.parse(g.isoTimeTimerBtn)).getTime()} atProduct={g} currentUserHere={this.state.currentUserHere} guestUserCart={this.state.guestUserCart} timeToCalculate={new Date(Number(g.timerDateWaiting.split("-")[0]), Number(g.timerDateWaiting.split("-")[1] - 1), Number(g.timerDateWaiting.split("-")[2]), Number(g.timerTimeWaiting.split(":")[0]), Number(g.timerTimeWaiting.split(":")[1]), 0).getTime()}
                                                                closeScreen={() => {
                                                                    this.setState({ updatePricing: true })
                                                                }}
                                                            />
                                                        )
                                                        : (g.isoStartTime && new Date(Date.parse(g.isoStartTime)).getTime() > new Date().getTime()) && <ButtonTimerCampaign mobileNewSpacing={true} showLabelAbsolute={true} labelOnOwnPage={true} timerToDo={new Date(Date.parse(g.isoStartTime)).getTime()} atProduct={g} currentUserHere={this.state.currentUserHere} guestUserCart={this.state.guestUserCart} timeToCalculate={new Date(Date.parse(g.isoStartTime)).getTime()}
                                                            closeScreen={() => {
                                                                this.setState({ updatePricing: true })
                                                            }}
                                                        />}
                                                    {this.checkForVerzilverButton(g, ind)}
                                                    <div className="image-front addIconCart">
                                                        <AddIcon />
                                                    </div>
                                                </div>}
                                                <div className="icons-cltsmhnc-mob">
                                                    <div className="icons-lcltsmhnc">
                                                        <VscInfo onClick={() => {
                                                            this.setState({ showMoreDotsPopup: true, currentCampaignInfoOpen: g })
                                                        }} />
                                                    </div>
                                                    {this.state.currentUserId && this.checkForBookMarkIcon(
                                                        g
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='right-tsmhnc' style={{ backgroundImage: g.image ? `url("${(window.innerWidth < 1008 && g.image_mobile) ? g.image_mobile : g.image}")` : "none", overflow: "hidden" }}>
                                            <div className="overlay-rtsmhnc"></div>
                                        </div>
                                    </div>
                                </div>
                            );
                        } else if (g.campaignType === "local_event") {
                            if (!g.reserved_event_id) {
                                g.selectedTimeIndex = 0
                                g.reserved_event_id = 1
                                g.selectedTiming = false
                                g.dropdownOpenTiming = false
                            }
                            return (
                                <div style={{ position: "relative" }}>
                                    {window.innerWidth < 1008 && (<div className="top-userinfo-tsco">
                                        <img src={this.getUserProfilePic(g.created_by)} onClick={() => {
                                            localStorage.setItem("scrollToTop", true)
                                            this.redirectUser(g.created_by)
                                        }} />
                                        <div className="cont-tus-tsco">
                                            <p onClick={() => {
                                                localStorage.setItem("scrollToTop", true)
                                                this.redirectUser(g.created_by)
                                            }}>{this.getUserName(g.created_by)}</p>
                                            <p>{this.getUserCity(g.created_by)}</p>
                                        </div>
                                        <div className="icons-main-lcl">
                                            {this.state.currentUserHere.user_id === g.created_by && <div className="icons-lcltsmhnc" style={{ display: "inline-block", marginRight: 0 }} onClick={() => {
                                                if (window.innerWidth > 1008) {
                                                    this.props.onEditOpen()
                                                } else {
                                                    localStorage.setItem("localEventInfo", JSON.stringify(g))
                                                    history.push(`/editor/local_event`)
                                                }
                                            }}>
                                                <AiOutlineEdit />
                                            </div>}
                                            {this.state.currentUserHere.user_id !== g.created_by && this.state.currentUserId && this.checkForFollowIcon(g.created_by)}
                                        </div>
                                    </div>)}
                                    <div className='tsmhn-container' style={{ marginTop: ind === 0 && 0 }}>
                                        <div className='left-tsmhnc'>
                                            <div className='container-ltsmhnc'>
                                                <p>
                                                    Event
                                                    {(g.amount && g.localEventType === "percentage" && !g.takeFromAdmin) && (
                                                        <font>
                                                            &nbsp;{g.amount}% Off
                                                        </font>
                                                    )}
                                                </p>
                                                <p>{g.title}</p>
                                                {!g.active && (<p style={{ color: "#C17F6B" }}>Actie is niet actief</p>)}
                                                {/* <p>{g.desc}</p> */}
                                                <p>
                                                    <div dangerouslySetInnerHTML={this.createMarkup(g.desc)} className="description-content-cltsm" onClick={() => {
                                                        const truncatedDescription = this.truncateAndSanitize(g.desc, 80);
                                                        if (window.innerWidth < 1008 && truncatedDescription.length > 80) {
                                                            this.setState({ showDescriptionPopup: true, currentCampaignInfoOpen: g })
                                                        }
                                                    }}></div>
                                                </p>
                                                {/* {(window.innerWidth < 1008 && g.desc.length > 80) ? (<p onClick={() => {
                                                    this.setState({ showDescriptionPopup: true, currentCampaignInfoOpen: g })
                                                }}>{g.desc.substr(0, 80)}...</p>) : (<p>{g.desc}</p>)} */}
                                                <p>{this.getFormattedStartDateLocalEvent(g)}</p>
                                                {(g.amount && g.localEventType !== "percentage" && !g.takeFromAdmin) && (<p>
                                                    <font>
                                                        €{" "}
                                                        <NumberFormatter
                                                            toFormat={g.amount}
                                                        />{" "}
                                                        Korting
                                                    </font>
                                                </p>)}
                                                {this.state.currentUserId && (g.isoTimeTimerBtn && new Date(Date.parse(g.isoTimeTimerBtn)).getTime() > new Date().getTime()) && <ButtonTimerCampaign mobileNewSpacing={true} showLabelAbsolute={true} labelOnOwnPage={true} timerToDo={new Date(Date.parse(g.isoTimeTimerBtn)).getTime()} atProduct={g} currentUserHere={this.state.currentUserHere} guestUserCart={this.state.guestUserCart} closeScreen={() => {
                                                    this.setState({ updatePricing: true })
                                                }} />}
                                                {/* <button>BOEK NU</button> */}
                                                {g.active && g.timings && <div id="innerbtnboeknulocalevent">
                                                    {g.timings.length > 1 && (<button className="timing-btnContainer">
                                                        <div className="main-tibtncont" onClick={() => {
                                                            g.dropdownOpenTiming = !g.dropdownOpenTiming
                                                            this.setState({ allCampaigns: this.state.allCampaigns })
                                                        }}>
                                                            {g.selectedTiming ? (<font>{g.timings[g.selectedTimeIndex].startTimeEvent}-{g.timings[g.selectedTimeIndex].endTimeEvent} uur</font>) : (<font>Selecteer Tijd</font>)}
                                                            <RiArrowDownSLine />
                                                        </div>
                                                        <div className="dropdownContainer-tibtn" style={{ opacity: g.dropdownOpenTiming ? 1 : 0, pointerEvents: g.dropdownOpenTiming ? "all" : "none" }}>
                                                            <ul>
                                                                {g.timings.map((t, index) => {
                                                                    let isUitverkocht = false
                                                                    return (<li onClick={() => {
                                                                        if (!isUitverkocht) {
                                                                            g.selectedTimeIndex = index
                                                                            g.selectedTiming = true
                                                                            g.dropdownOpenTiming = false
                                                                            g.reserved_event_id = t.eventId

                                                                            this.setState({ allCampaigns: this.state.allCampaigns })
                                                                            // this.setState({ selectedTiming: true, dropdownOpenTiming: false, selectedTimeIndex: index, reserved_event_id: t.eventId })
                                                                        }
                                                                    }}>{t.startTimeEvent}-{t.endTimeEvent} uur {isUitverkocht ? ` (Uitverkocht)` : ""}</li>)
                                                                })}
                                                            </ul>
                                                        </div>
                                                    </button>)}

                                                    {(g.timings.length === 1 || (g.timings.length > 1 && g.selectedTiming)) && (<>
                                                        {this.state.currentUserHere &&
                                                            !this.state.currentUserCharityMember &&
                                                            this.state.showPlaceholderLocalEvent &&
                                                            this.getLocalEventSubscribeButton(g, ind)}
                                                        {!this.state.currentUserId &&
                                                            !this.state.notShowTimerGuestLocalEvent
                                                            ? this.state.showPlaceholderLocalEvent &&
                                                            this.checkForWinkelmandButtonLocalEvent(g, ind)
                                                            : !this.state.currentUserId
                                                                ? this.state.showPlaceholderLocalEvent && (
                                                                    <button
                                                                        style={{ backgroundColor: "#C17F6B" }} className="pinkcolorbutton"
                                                                    >
                                                                        <a>UITVERKOCHT</a>
                                                                    </button>
                                                                )
                                                                : this.state.showPlaceholderLocalEvent &&
                                                                this.getWinkelmandbuttonUsersLocalBlock(g, ind)}
                                                    </>)}
                                                </div>}
                                                <div className="icons-cltsmhnc-mob">
                                                    <div className="icons-lcltsmhnc">
                                                        <VscInfo onClick={() => {
                                                            this.setState({ showMoreDotsPopup: true, currentCampaignInfoOpen: g })
                                                        }} />
                                                    </div>
                                                    {this.state.currentUserId && this.checkForBookMarkIcon(g)}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='right-tsmhnc' style={{ backgroundImage: g.localEventPlaceHolderIsImage ? `url("${g.image}")` : "none", overflow: "hidden" }}>
                                            {!g.localEventPlaceHolderIsImage && (
                                                <video
                                                    src={g.image}
                                                    autoPlay
                                                    muted
                                                    loop
                                                    playsInline
                                                    className="localeventvideo"
                                                ></video>
                                            )}
                                            <div className="overlay-rtsmhnc"></div>
                                        </div>
                                    </div>
                                </div>
                            );
                        }
                    })}

                {this.state.showLoader && <div className="loadMoreProductsNew">
                    <div className="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <label>Loading More</label>
                </div>}
                {this.state.useOfVouchersPopup && (
                    <div className="overlay-popup-vdd"></div>
                )}
                {this.state.voucherExceptionsPopup && (
                    <div className="overlay-popup-vdd"></div>
                )}
                {this.state.termsTextPopup && <div className="overlay-popup-vdd"></div>}
                <div
                    className="card-popup-vdd"
                    style={{
                        opacity: this.state.useOfVouchersPopup ? 1 : 0,
                        pointerEvents: this.state.useOfVouchersPopup ? "all" : "none",
                    }}
                >
                    <CloseIcon
                        onClick={() => {
                            this.setState({ useOfVouchersPopup: false });
                        }}
                    />
                    <p className="main-title-cpv">Gebruik van voucher</p>
                    <p className="main-desc-cpv">{this.state.currentUseOfVoucher}</p>
                </div>
                <div
                    className="card-popup-vdd"
                    style={{
                        opacity: this.state.voucherExceptionsPopup ? 1 : 0,
                        pointerEvents: this.state.voucherExceptionsPopup ? "all" : "none",
                    }}
                >
                    <CloseIcon
                        onClick={() => {
                            this.setState({ voucherExceptionsPopup: false });
                        }}
                    />
                    <p className="main-title-cpv">UITZONDERINGEN</p>
                    <p className="main-desc-cpv">{this.state.currentVoucherExceptions}</p>
                </div>
                <div
                    className="card-popup-vdd"
                    style={{
                        opacity: this.state.termsTextPopup ? 1 : 0,
                        pointerEvents: this.state.termsTextPopup ? "all" : "none",
                    }}
                >
                    <CloseIcon
                        onClick={() => {
                            this.setState({ termsTextPopup: false });
                        }}
                    />
                    <p className="main-title-cpv">VOORWAARDEN</p>
                    <p className="main-desc-cpv">{this.state.currentTermsText}</p>
                </div>

                {this.state.showDescriptionPopup && (
                    <div
                        className="overlay-asp"
                        onClick={() => {
                            this.setState({
                                showOptionsToAdd: false,
                                showDescriptionPopup: false,
                                currentCampaignInfoOpen: "",
                            });
                        }}
                    ></div>
                )}
                <div
                    className="add-stuff-popup"
                    style={{
                        height: this.state.showDescriptionPopup ? "auto" : 0,
                        minHeight: this.state.showDescriptionPopup ? 350 : 0,
                        pointerEvents: this.state.showDescriptionPopup ? "all" : "none",
                        overflow: !this.state.showDescriptionPopup && "hidden",
                    }}
                >
                    <CloseIcon
                        onClick={() => {
                            this.setState({ showDescriptionPopup: false, currentCampaignInfoOpen: "" });
                        }}
                    />
                    <p
                        className="desc-heading-asp"
                        style={{ fontSize: 11, textTransform: "none" }}
                    >
                        Omschrijving
                    </p>
                    <p className="desc-content-asp">
                        {this.state.currentCampaignInfoOpen && (<div dangerouslySetInnerHTML={this.createDescPopup(this.state.currentCampaignInfoOpen.desc)}></div>)}
                        {/* {this.state.currentCampaignInfoOpen.desc} */}
                    </p>
                </div>

                {this.state.showMoreDotsPopup && (
                    <div
                        className="overlay-asp"
                        onClick={() => {
                            this.setState({
                                showOptionsToAdd: false,
                                showMoreDotsPopup: false,
                                currentCampaignInfoOpen: "",
                            });
                        }}
                    ></div>
                )}
                <div
                    className="add-stuff-popup"
                    style={{
                        height: this.state.showMoreDotsPopup ? "auto" : 0,
                        minHeight: this.state.showMoreDotsPopup ? 350 : 0,
                        pointerEvents: this.state.showMoreDotsPopup ? "all" : "none",
                        overflow: !this.state.showMoreDotsPopup && "hidden",
                        paddingTop: this.state.openDetailedInner && 50,
                        paddingBottom: this.state.openDetailedInner && 50,
                    }}
                >
                    {this.state.openDetailedInner ? (<>
                        <ChevronLeft
                            onClick={() => {
                                this.setState({
                                    openDetailedInner: false,
                                    headingOdiMain: "",
                                    paraOdiMain: "",
                                });
                            }}
                            style={{
                                right: "auto",
                                left: 10
                            }}
                        />
                        <p
                            className="desc-heading-asp"
                            style={{ fontSize: 11, textTransform: "none", marginTop: 0 }}
                        >
                            {this.state.headingOdiMain}
                        </p>
                        <p className="desc-content-asp">
                            {this.state.paraOdiMain}
                        </p>
                    </>) : (<>
                        <CloseIcon
                            onClick={() => {
                                this.setState({
                                    showOptionsToAdd: false,
                                    showMoreDotsPopup: false,
                                    currentCampaignInfoOpen: "",
                                });
                            }}
                        />
                        {this.state.currentCampaignInfoOpen && (this.state.currentCampaignInfoOpen.campaignType === "local_event" ? this.getAboutLocalEventMobile() : this.state.currentCampaignInfoOpen.campaignType === "web_shop" ? this.getAboutWebShopMobile() : this.getAboutKortingsVoucherMobile())}
                        {this.state.currentCampaignInfoOpen && (this.state.currentCampaignInfoOpen.campaignType === "local_event" ? this.getUseOfVoucherlocalEventMobile(this.state.currentCampaignInfoOpen) : this.state.currentCampaignInfoOpen.campaignType === "web_shop" ? this.getUseOfVoucherWebShopMobile(this.state.currentCampaignInfoOpen) : this.getUseOfVoucherKortingsVoucherMobile(this.state.currentCampaignInfoOpen))}
                        {this.state.currentCampaignInfoOpen && this.getfilelinkMobile(this.state.currentCampaignInfoOpen)}
                    </>)}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducers.user,
        product: state.campaignsReducer.product,
        vouchers: state.campaignsReducer.vouchers,
        global_values: state.globalReducers.global_values,
        guest_user_cart: state.cartReducer.guest_user_cart,
        global_campaigns: state.campaignsReducer.global_campaigns,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateUser: (user) => dispatch(updateUser(user)),
        updateGuestUserCart: (guest_user_cart) => dispatch(updateGuestUserCart(guest_user_cart)),
        updateGuestUserCartTime: (guest_user_cart_time) => dispatch(updateGuestUserCartTime(guest_user_cart_time)),
        updateGuestUserCartExtendOpportunity: (guest_user_cart_extend_opportunity) => dispatch(updateGuestUserCartExtendOpportunity(guest_user_cart_extend_opportunity)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VoucherEventsNewGlobal);
