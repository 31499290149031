import React, { Component } from "react";
import {
  updateSignUpFieldsFilled,
  updateGuestUserCart,
  updateGuestUserCartTime,
  updateGuestUserCartExtendOpportunity,
} from "../Redux/actions/authActions";
import { connect } from "react-redux";
import firebase from "../Config/Firebase";
import swal from "sweetalert";
import Footer from "./Footer";
import FooterGreen from "./FooterGreen";
import NewVisit from "../Containers/New Visit";
import { bookmarkCurrentProduct } from "../Functions/shopFunctions";
import { mergeAdminEvent } from "../Functions/globalFunctions";

const axios = require("axios");

export class CharityConfirmationFullPage extends Component {
  constructor() {
    super();
    this.state = {
      currentUser: "",
      guestUserCart: [],
      showLoader: false,
      showLoaderMain: true,
      popupopenforright: true,
      cookieItemCheck: "",
      showNewlyRegistered: false,
      showProductsText: false,

      showDutch: true,
      checkout_confirmation_text: "",
      checkout_confirmation_text_mobile: "",
      checkout_confirmation_text_english: "",
      checkout_confirmation_text_english_mobile: "",
      checkout_confirmation_text_guest: "",
      checkout_confirmation_text_guest_mobile: "",
      checkout_confirmation_text_guest_english: "",
      checkout_confirmation_text_guest_english_mobile: "",
      checkout_confirmation_products_text: "",
      checkout_confirmation_products_text_mobile: "",
      checkout_confirmation_products_text_english: "",
      checkout_confirmation_products_text_english_mobile: "",
      charity_confirmation_bg_image: "",
    };
  }

  componentDidMount() {
    const loaderId = document.getElementById("main-page-loader-id");
    loaderId.style.display = "none";
    let uid = localStorage.getItem("userid");
    let userid = localStorage.getItem("userid");
    let cookieItemCheck = localStorage.getItem("cookie_item");
    let userAlreadyMember = localStorage.getItem("user-already-member");

    if (userAlreadyMember) {
      localStorage.removeItem("user-already-member");
    } else {
      this.setState({ cookieItemCheck });
    }
    localStorage.removeItem("movedToCheckout")

    if (this.props.user) {
      if (this.props.user.length > 0) {
        let filtered = this.props.user.filter((h) => {
          return h.user_id === uid;
        });
        if (filtered.length > 0) {
          if (!filtered[0].payment_status) {
            this.setState({ showLoader: true });
          }
          this.setState({ currentUser: filtered[0] });
        }
      }
    }

    // if (window.innerWidth > 1008) {
    //     this.setState({ popupopenforright: true })
    // } else {
    //     this.setState({ popupopenforright: false })
    // }

    let makeCart = this.props.guestCart;
    if (makeCart) {
      if (makeCart.length > 0) {
        let toDoFilterCompanies = [];
        let toDoFilter = [];
        makeCart.map((b) => {
          if (b.showPopupNotLeft) {
            b.hideFromListComplete = true;
          } else {
            b.hideFromListComplete = false;
            toDoFilterCompanies.push(b);
          }
          toDoFilter.push(b);
        });
        this.setState({ guestUserCart: makeCart });
      }
    }

    let urlMain = new URLSearchParams(window.location.search)

    if (urlMain.get("show-product-text")) {
      this.setState({ showProductsText: true })
    }

    firebase
      .database()
      .ref("users/" + userid)
      .once("value", (data) => {
        let a = data.val();
        if (a) {
          // if (a.user_name_id === this.props.member_id) {
          //     this.props.updateSignUpFieldsFilled("")
          // }
          if (urlMain.get("token")) {
            var config = {
              method: "get",
              url: "https://us-central1-bhodi-production-da765.cloudfunctions.net/getAuthDetails",
              headers: {},
            };

            axios(config)
              .then((response) => {
                if (response.status === 200) {
                  let accessToken = response.data.access_token;

                  var configAnother = {
                    method: "post",
                    url: `https://us-central1-bhodi-production-da765.cloudfunctions.net/capturePaymentforOrder`,
                    headers: {
                      authToken: accessToken,
                      "Content-Type": "application/json",
                    },
                    data: {
                      orderId: urlMain.get("token"),
                    }
                  };

                  axios(configAnother)
                    .then((responseNew) => {
                      let cookieItem = localStorage.getItem("cookie_item");
                      let userid = localStorage.getItem("userid");
                      if (userid && !cookieItem) {
                        cookieItem = userid;
                      }

                      this.processOrderPaypal(responseNew.data, cookieItem, a)
                    })
                    .catch(function (error) {
                      console.log(error);
                    });
                }
              })
              .catch(function (error) {
                console.log(error);
              });

            return false;
          }
          if (urlMain.get("checkout-type") && urlMain.get("checkout-type") === "product" && urlMain.get("free-of-cost") && urlMain.get("free-of-cost") === "true") {
            let cookieItem = localStorage.getItem("cookie_item");
            let userid = localStorage.getItem("userid");
            if (userid && !cookieItem) {
              cookieItem = userid;
            }

            console.log("/working check dp")
            this.processOrder("direct-products", cookieItem, a)
            return false;
          }
          if (urlMain.get("guest-login") === "true") {
            let cookieItem = localStorage.getItem("cookie_item");
            let userid = localStorage.getItem("userid");
            if (userid && !cookieItem) {
              cookieItem = userid;
            }

            this.processOrder("subscription", cookieItem, a)
            return false;
          }

          if (a.create_subscription) {
            this.setState({ create_subscription: a.create_subscription });

            if (a.payment_status === "paid" && a.subscription && a.subscription.status === "active") {
              // if ((a.payment_status === "paid" && !a.subscription) || (a.payment_status === "paid" && a.subscription && a.subscription.status === "canceled")) {
              let cookieItem = localStorage.getItem("cookie_item");
              let userid = localStorage.getItem("userid");
              if (userid && !cookieItem) {
                cookieItem = userid;
              }

              firebase.database().ref("users/" + userid).on("value", (data) => {
                let a = data.val()
                if (a.order_loaded) {
                  firebase.database().ref("users/" + userid + "/create_subscription").set(false)
                  this.setState({
                    create_subscription: false,
                    showLoader: false,
                    showLoaderMain: false,
                    showCharityConfirmation: true,
                  });
                  this.props.updateSignUpFieldsFilled(
                    ""
                  );
                  this.props.updateGuestUserCartExtendOpportunity(
                    ""
                  );
                  this.props.updateGuestUserCartTime(
                    ""
                  );
                  this.props.updateGuestUserCart(
                    []
                  );

                  localStorage.removeItem(
                    "cookie_item"
                  );
                  firebase.database().ref("users/" + userid + "/order_loaded").remove()
                }
              })

              // this.processOrder("subscription", cookieItem, a)
            } else {
              let paymenStatus = a.payment_status;
              if (paymenStatus === "open") {
                firebase
                  .database()
                  .ref("users/" + userid + "/create_subscription")
                  .set(false)
                  .then(() => {
                    this.setState({ create_subscription: false });
                  });
              } else if (
                paymenStatus === "failed" ||
                paymenStatus === "expired"
              ) {
                firebase
                  .database()
                  .ref("users/" + userid + "/create_subscription")
                  .set(false)
                // window.location.href = "/registration/step2";
              } else if (paymenStatus === "canceled") {
                swal(
                  "Bedankt voor je steun",
                  "Subscription canceled!",
                  "error"
                ).then(() => {
                  firebase
                    .database()
                    .ref("users/" + userid + "/create_subscription")
                    .set(false)
                    .then(() => {
                      this.setState({ create_subscription: false });
                    });
                });
              } else {
                this.setState({
                  create_subscription: false,
                  showLoader: false,
                  showLoaderMain: false,
                  showCharityConfirmation: true,
                  paymentCancelled: true
                });

                firebase
                  .database()
                  .ref("users/" + userid + "/create_subscription")
                  .set(false)
              }
            }
          } else {
            if (a.payment_status === "paid" && a.subscription && a.subscription.status === "active" && !a.subscription.id) {
              let cookieItem = localStorage.getItem("cookie_item");
              let userid = localStorage.getItem("userid");
              if (userid && !cookieItem) {
                cookieItem = userid;
              }

              let objectPayment = localStorage.getItem("doingPaymentOf")
              if (objectPayment) {
                let parsedObj = JSON.parse(objectPayment)
                // let objectedToSubscribe = {
                //   uid: userid,
                //   customerId: parsedObj.customerId,
                //   amount: parsedObj.amount,
                //   routeAmount: parsedObj.routeAmount,
                //   orgId: parsedObj.orgId,
                //   redirectUrl: "https://www.bhodi.com"
                // }
                axios
                  .post(
                    "https://us-central1-bhodi-production-da765.cloudfunctions.net/createSplitSubscription",
                    {
                      uid: userid,
                      customerId: parsedObj.customerId,
                      amount: parsedObj.amount,
                      routeAmount: parsedObj.routeAmount,
                      orgId: parsedObj.orgId,
                      redirectUrl: "https://www.bhodi.com"
                    }
                  )
                  .then(() => {
                    localStorage.removeItem("doingPaymentOf")
                    this.processOrder("direct", cookieItem, a)
                  })
                  .catch((error) => {
                    console.log(error);
                  });

                // this.processOrder("direct", cookieItem, a)
              }

            } else {
              localStorage.removeItem("cookie_item")

              firebase
                .database()
                .ref("users/" + userid + "/create_subscription")
                .set(false)

              if (a.type === "company") {
                window.location.href = `/company/${a.user_name_id}`
              } else {
                window.location.href = `/member/${a.user_name_id}`
              }
            }
          }
        }
      });

    if (this.props.global_values) {
      let a = this.props.global_values;
      if (a) {
        this.setState({
          checkout_confirmation_text: a.checkout_confirmation_text || "",
          checkout_confirmation_text_mobile: a.checkout_confirmation_text_mobile || "",
          checkout_confirmation_text_english: a.checkout_confirmation_text_english || "",
          checkout_confirmation_text_english_mobile: a.checkout_confirmation_text_english_mobile || "",
          checkout_confirmation_text_guest: a.checkout_confirmation_text_guest || "",
          checkout_confirmation_text_guest_mobile: a.checkout_confirmation_text_guest_mobile || "",
          checkout_confirmation_text_guest_english: a.checkout_confirmation_text_guest_english || "",
          checkout_confirmation_text_guest_english_mobile: a.checkout_confirmation_text_guest_english_mobile || "",
          checkout_confirmation_products_text: a.checkout_confirmation_products_text || "",
          checkout_confirmation_products_text_mobile: a.checkout_confirmation_products_text_mobile || "",
          checkout_confirmation_products_text_english: a.checkout_confirmation_products_text_english || "",
          checkout_confirmation_products_text_english_mobile: a.checkout_confirmation_products_text_english_mobile || "",
          charity_confirmation_bg_image: a.charity_confirmation_bg_image || "",
        });
      }
    }


    firebase
      .database()
      .ref("bhodi_home")
      .on("value", (data) => {
        let a = data.val();
        if (a) {
          this.setState({
            checkout_confirmation_text: a.checkout_confirmation_text || "",
            checkout_confirmation_text_mobile: a.checkout_confirmation_text_mobile || "",
            checkout_confirmation_text_english: a.checkout_confirmation_text_english || "",
            checkout_confirmation_text_english_mobile: a.checkout_confirmation_text_english_mobile || "",
            checkout_confirmation_text_guest: a.checkout_confirmation_text_guest || "",
            checkout_confirmation_text_guest_mobile: a.checkout_confirmation_text_guest_mobile || "",
            checkout_confirmation_text_guest_english: a.checkout_confirmation_text_guest_english || "",
            checkout_confirmation_text_guest_english_mobile: a.checkout_confirmation_text_guest_english_mobile || "",
            checkout_confirmation_products_text: a.checkout_confirmation_products_text || "",
            checkout_confirmation_products_text_mobile: a.checkout_confirmation_products_text_mobile || "",
            checkout_confirmation_products_text_english: a.checkout_confirmation_products_text_english || "",
            checkout_confirmation_products_text_english_mobile: a.checkout_confirmation_products_text_english_mobile || "",
            charity_confirmation_bg_image: a.charity_confirmation_bg_image || "",
          });
        }
      });

  }

  getSellerName(e) {
    if (e) {
      let filteredUser = this.props.user.filter((g) => {
        if (e.mainParentCreator) {
          return g.user_id === e.mainParentCreator;
        } else {
          return g.user_id === e.created_by;
        }
      });
      if (filteredUser.length > 0) {
        if (filteredUser[0].type === "company") {
          if (filteredUser[0].company_name) {
            return filteredUser[0].company_name;
          } else {
            return filteredUser[0].first_name + " " + filteredUser[0].last_name;
          }
        } else {
          return filteredUser[0].first_name + " " + filteredUser[0].last_name;
        }
      }
    }
  }

  processOrder(response, cookieItem, a) {
    let uid = localStorage.getItem("userid");
    let userid = localStorage.getItem("userid");
    let cookieItemCheck = localStorage.getItem("cookie_item");

    if (response === "direct") {
      if (cookieItemCheck) {
        localStorage.removeItem("cookie_item");
        // this.setState({ showNewlyRegistered: true, showLoader: false });
      }
      // else {
      //   window.location.href = `/guest-event-cart/stepTwo`;
      // }
    } else {
      this.setState({ showLoader: false });
    }

    let promiseSubscription = new Promise(
      (resolveFirst, reject) => {
        if (cookieItem) {
          firebase
            .database()
            .ref("guest_carts/" + cookieItem)
            .once("value", (data) => {
              let a = data.val();
              if (a) {
                let arr = [];
                let arrKeys = [];
                let arrComplete = [];
                let arrCompleteProducts = [];
                let arrCompleteBhodiProducts = []
                let objected = Object.entries(a);
                objected.map((y) => {
                  arr.push(y[1]);
                  arrKeys.push(y[1].itemPush);
                });

                if (this.props.global_campaigns) {
                  if (this.props.global_campaigns.length > 0) {
                    this.interval = setInterval(
                      () =>
                        this.setState({
                          timeCurrent: Date.now(),
                        }),
                      1000
                    );
                    let arrSecCheck = [];

                    this.props.global_campaigns.map((i) => {
                      arrSecCheck.push(i[1]);
                    });
                    arrSecCheck.map((g) => {
                      if (g.active) {
                        g.type = "campaign";
                        g.currentImageIndex = 0;
                        if (g.desc) {
                          g.desc = g.desc.substr(0, 200);
                          g.short_desc = g.desc.substr(0, 120);
                        }
                        if (g.campaignType !== "charity") {
                          if (g.image) {
                            g.images = [g.image];
                            g.imagesObjected = [
                              {
                                image: g.image,
                                pushKey: g.pushKey,
                              },
                            ];
                          }
                        } else {
                          if (g.dishes) {
                            g.images = [g.imageToRender];
                            g.imagesObjected = [
                              {
                                image: g.imageToRender,
                                pushKey: g.pushKey,
                              },
                            ];
                            g.title = g.main_title;
                            g.desc =
                              g.dishesObjected[0].description;
                            g.charity_price = g.main_price;
                            g.normal_price = g.main_price;
                            g.amount = g.main_price;
                          }
                        }
                        arrComplete.push(g);
                      }
                    });

                    this.setState({
                      allCampaigns: arrComplete,
                    });
                  }
                }
                if (this.props.products_new) {
                  if (this.props.products_new.length > 0) {
                    this.props.products_new.map((g) => {
                      if (g.active) {
                        g.type = "product";
                        g.currentImageIndex = 0;
                        if (g.desc) {
                          g.desc = g.desc.substr(0, 200);
                          g.short_desc = g.desc.substr(0, 120);
                        }
                        arrCompleteProducts.push(g);
                      }
                    });

                    this.setState({
                      allProducts: arrCompleteProducts,
                    });
                  }
                }

                if (this.props.bhodi_products) {
                  if (this.props.bhodi_products.length > 0) {
                    this.props.bhodi_products.map((g) => {
                      if (g.active) {
                        g.type = "bhodi_product";
                        g.currentImageIndex = 0;
                        if (g.desc) {
                          g.desc = g.desc.substr(0, 200);
                          g.short_desc = g.desc.substr(0, 120);
                        }
                        arrCompleteBhodiProducts.push(g);
                      }
                    });

                    this.setState({ allBhodiProducts: arrCompleteBhodiProducts });
                  }
                }

                let everyCartItems = [];
                arrComplete.map((y) => {
                  objected.map((t) => {
                    if (t[1].type === "product") {
                      return false;
                    }
                    if (t[1].item_type === "bhodi_product") {
                      return false;
                    }
                    if (y.pushKey === t[1].itemPush) {
                      if (y.active) {
                        if (
                          y.campaignType === "local_event"
                        ) {
                          let obj = {
                            ...y,
                            reserved_event_id: t[1].event_id
                          }
                          everyCartItems.push(obj);
                          // everyCartItems.push(y);
                        } else if (y.campaignType === "web_shop") {
                          let obj = {
                            ...y,
                            redeemed_code: t[1].redeemed_code
                          }
                          everyCartItems.push(obj);
                          // everyCartItems.push(y);
                        } else {
                          everyCartItems.push(y);
                        }
                        // everyCartItems.push(y)
                      }
                    }
                  });
                });
                arrCompleteProducts.map((y) => {
                  objected.map((t) => {
                    if (t[1].type === "product") {
                      if (t[1].itemPush === y.pushKey) {
                        t[1].mainProduct = y;
                        everyCartItems.push(t[1]);
                      }
                    }
                  });
                });
                arrCompleteBhodiProducts.map((y) => {
                  objected.map((t) => {
                    if (t[1].item_type === "bhodi_product") {
                      if (t[1].itemPush === y.pushKey) {
                        // t[1].mainProduct = y;
                        let currentObj = Object.assign({}, y)
                        currentObj.item_type = "bhodi_product"
                        if (t[1].selectedSize) {
                          currentObj.selectedSizeOfOrder = t[1].selectedSize
                        }
                        everyCartItems.push(currentObj);
                      }
                    }
                  });
                });

                this.setState({
                  guestUserCart: everyCartItems,
                });
                resolveFirst();
              } else {
                this.setState({ guestUserCart: [] });
                resolveFirst();
              }
            });
        } else {
          this.setState({ guestUserCart: [] });
          resolveFirst();
        }
      }
    );
    promiseSubscription.then((y) => {
      let cartEvents = this.state.guestUserCart;
      if (response === "direct-products" && cartEvents && cartEvents.length > 0) {

        setTimeout(() => {
          this.setState({ showLoader: false });
        }, 1000);

        this.addOrdersToList(cartEvents, cookieItem)
        return false;
      }
      if (response === "direct" && cartEvents && cartEvents.length > 0) {
        this.setState({ showLoader: false });

        this.addOrdersToList(cartEvents, cookieItem)
        return false;
      }
      if (response === "direct-products" || response === "direct") {
        return false;
      }
      if (cartEvents.length > 0) {
        firebase.database().ref("users/" + userid + "/create_subscription").set(false)
        this.addOrdersToList(cartEvents, cookieItem)
      } else {
        let a = this.state.currentUser
        if (a.type === "company") {
          window.location.href = `/company/${a.user_name_id}`
        } else {
          window.location.href = `/member/${a.user_name_id}`
        }
      }
      // if (cartEvents) {
      //   if (cartEvents.length > 0) {
      //     firebase
      //       .database()
      //       .ref("users/" + userid + "/create_subscription")
      //       .set(false)
      //   } else {
      //     firebase
      //       .database()
      //       .ref("users/" + userid + "/create_subscription")
      //       .set(false)
      //       .then(() => {
      //         firebase
      //           .database()
      //           .ref("users/" + userid + "/subscription")
      //           .set(response.data.subscription)
      //           .then(() => {
      //             firebase
      //               .database()
      //               .ref("users/" + userid + "/subscription_id")
      //               .set(response.data.subscription.id)
      //               .then(() => {
      //                 this.setState({
      //                   create_subscription: false,
      //                 });
      //               });
      //           });
      //       });
      //     // })
      //   }
      // } else {
      //   // firebase.database().ref("users/" + userid + "/show_subscriber_page").set(true)
      //   // swal("Bedankt voor je steun", "Je bent nu charity member!", "success").then(() => {
      //   firebase
      //     .database()
      //     .ref("users/" + userid + "/create_subscription")
      //     .set(false)
      //     .then(() => {
      //       firebase
      //         .database()
      //         .ref("users/" + userid + "/subscription")
      //         .set(response.data.subscription)
      //         .then(() => {
      //           firebase
      //             .database()
      //             .ref("users/" + userid + "/subscription_id")
      //             .set(response.data.subscription.id)
      //             .then(() => {
      //               this.setState({
      //                 create_subscription: false,
      //               });
      //             });
      //         });
      //       // })
      //     });
      // }
    });
  }


  showDateToUserTimingMail(product) {
    let filtered = product.timings.filter((u) => {
      return u.eventId === product.reserved_event_id;
    });

    if (filtered.length > 0) {
      let e = filtered[0].startDateEvent;
      let f = filtered[0].endDateEvent;
      if (e === f) {
        let splitted = e.split("-");
        let newDate = new Date(e).getDay();
        return `${this.getDayName(newDate)} ${splitted[2]} ${this.getMonthName(
          splitted[1]
        )}`;
      } else {
        let splitted = e.split("-");
        let splittedSecond = f.split("-");
        if (splitted[1] === splittedSecond[1]) {
          return `${splitted[2]} - ${splittedSecond[2]} ${this.getMonthName(
            splittedSecond[1]
          )}`;
        } else {
          return `${splitted[2]} ${this.getMonthName(splitted[1])} t/m ${splittedSecond[2]
            } ${this.getMonthName(splittedSecond[1])}`;
        }
      }
    }
  }

  showTimeToUserTimingMail(product) {
    let filtered = product.timings.filter((u) => {
      return u.eventId === product.reserved_event_id;
    });

    if (filtered.length > 0) {
      return `${filtered[0].startTimeEvent}u - ${filtered[0].endTimeEvent}u`;
    }
  }


  showDateToUserTimingMailWebshop(product) {
    let e = product.voucherStartDate;
    let f = product.voucherEndDate;
    if (e === f) {
      let splitted = e.split("-");
      let newDate = new Date(e).getDay();
      return `${this.getDayName(newDate)} ${splitted[2]} ${this.getMonthName(
        splitted[1]
      )}`;
    } else {
      let splitted = e.split("-");
      let splittedSecond = f.split("-");
      if (splitted[1] === splittedSecond[1]) {
        return `${splitted[2]} - ${splittedSecond[2]} ${this.getMonthName(
          splittedSecond[1]
        )}`;
      } else {
        return `${splitted[2]} ${this.getMonthName(splitted[1])} t/m ${splittedSecond[2]
          } ${this.getMonthName(splittedSecond[1])}`;
      }
    }
  }

  showTimeToUserTimingMailWebshop(product) {
    return `${product.voucherStartTime}u - ${product.voucherEndTime}u`;
  }


  processOrderPaypal(response, cookieItem, a) {
    let uid = localStorage.getItem("userid");
    let userid = localStorage.getItem("userid");
    let cookieItemCheck = localStorage.getItem("cookie_item");

    let promiseSubscription = new Promise(
      (resolveFirst, reject) => {
        if (cookieItem) {
          firebase
            .database()
            .ref("guest_carts/" + cookieItem)
            .once("value", (data) => {
              let a = data.val();
              if (a) {
                let arr = [];
                let arrKeys = [];
                let arrComplete = [];
                let arrCompleteProducts = [];
                let arrCompleteBhodiProducts = []
                let objected = Object.entries(a);
                objected.map((y) => {
                  arr.push(y[1]);
                  arrKeys.push(y[1].itemPush);
                });

                if (this.props.global_campaigns) {
                  if (this.props.global_campaigns.length > 0) {
                    this.interval = setInterval(
                      () =>
                        this.setState({
                          timeCurrent: Date.now(),
                        }),
                      1000
                    );
                    let arrSecCheck = [];

                    this.props.global_campaigns.map((i) => {
                      arrSecCheck.push(i[1]);
                    });
                    arrSecCheck.map((g) => {
                      if (g.active) {
                        g.type = "campaign";
                        g.currentImageIndex = 0;
                        if (g.desc) {
                          g.desc = g.desc.substr(0, 200);
                          g.short_desc = g.desc.substr(0, 120);
                        }
                        if (g.campaignType !== "charity") {
                          if (g.image) {
                            g.images = [g.image];
                            g.imagesObjected = [
                              {
                                image: g.image,
                                pushKey: g.pushKey,
                              },
                            ];
                          }
                        }
                        arrComplete.push(g);
                      }
                    });

                    this.setState({
                      allCampaigns: arrComplete,
                    });
                  }
                }
                if (this.props.products_new) {
                  if (this.props.products_new.length > 0) {
                    this.props.products_new.map((g) => {
                      if (g.active) {
                        g.type = "product";
                        g.currentImageIndex = 0;
                        if (g.desc) {
                          g.desc = g.desc.substr(0, 200);
                          g.short_desc = g.desc.substr(0, 120);
                        }
                        arrCompleteProducts.push(g);
                      }
                    });

                    this.setState({
                      allProducts: arrCompleteProducts,
                    });
                  }
                }

                if (this.props.bhodi_products) {
                  if (this.props.bhodi_products.length > 0) {
                    this.props.bhodi_products.map((g) => {
                      if (g.active) {
                        g.type = "bhodi_product";
                        g.currentImageIndex = 0;
                        if (g.desc) {
                          g.desc = g.desc.substr(0, 200);
                          g.short_desc = g.desc.substr(0, 120);
                        }
                        arrCompleteBhodiProducts.push(g);
                      }
                    });

                    this.setState({ allBhodiProducts: arrCompleteBhodiProducts });
                  }
                }

                let everyCartItems = [];
                arrComplete.map((y) => {
                  objected.map((t) => {
                    if (t[1].type === "product") {
                      return false;
                    }
                    if (t[1].item_type === "bhodi_product") {
                      return false;
                    }
                    if (y.pushKey === t[1].itemPush) {
                      if (y.active) {
                        if (
                          y.campaignType === "local_event"
                        ) {
                          let obj = {
                            ...y,
                            reserved_event_id: t[1].event_id
                          }
                          everyCartItems.push(obj);
                          // everyCartItems.push(y);
                        } else {
                          everyCartItems.push(y);
                        }
                        // everyCartItems.push(y)
                      }
                    }
                  });
                });
                arrCompleteProducts.map((y) => {
                  objected.map((t) => {
                    if (t[1].type === "product") {
                      if (t[1].itemPush === y.pushKey) {
                        t[1].mainProduct = y;
                        everyCartItems.push(t[1]);
                      }
                    }
                  });
                });
                arrCompleteBhodiProducts.map((y) => {
                  objected.map((t) => {
                    if (t[1].item_type === "bhodi_product") {
                      if (t[1].itemPush === y.pushKey) {
                        // t[1].mainProduct = y;
                        let currentObj = Object.assign({}, y)
                        currentObj.item_type = "bhodi_product"
                        if (t[1].selectedSize) {
                          currentObj.selectedSizeOfOrder = t[1].selectedSize
                        }
                        everyCartItems.push(currentObj);
                      }
                    }
                  });
                });

                this.setState({
                  guestUserCart: everyCartItems,
                });
                resolveFirst();
              } else {
                this.setState({ guestUserCart: [] });
                resolveFirst();
              }
            });
        } else {
          this.setState({ guestUserCart: [] });
          resolveFirst();
        }
      }
    );
    promiseSubscription.then((y) => {
      let cartEvents = this.state.guestUserCart;

      if (response.status === "COMPLETED") {

        cartEvents.map((t, i) => {
          if (response.purchase_units && response.purchase_units.length > 0) {
            let filtered = response.purchase_units.filter((g) => {
              return g.reference_id === `REFID-${t.created_by}`
            })
            if (filtered.length > 0) {
              cartEvents[i].paypalOrderCaptureId = filtered[0].payments.captures[0].id
            }
          }
        })

        setTimeout(() => {
          this.setState({ showLoader: false });
        }, 1000);

        this.addOrdersToList(cartEvents, cookieItem)
        return false;

      } else {
        swal({
          title: "Sorry",
          text: "We can't process your order now",
          icon: "error"
        }).then(() => {
          if (this.state.currentUser.type === "user") {
            window.location.href = `/member/${this.state.currentUser.user_name_id}`;
          } else {
            window.location.href = `/company/${this.state.currentUser.user_name_id}`;
          }
        })
      }
    });
  }


  getBuyerNameOutgoing(f) {
    let filtered = this.props.user.filter((g) => {
      return g.user_id === f;
    });
    if (filtered.length > 0) {
      if (filtered[0].type === "user") {
        return `${filtered[0].first_name} ${filtered[0].last_name}`;
      } else {
        return `${filtered[0].company_name}`;
      }
    }
  }

  getAddressOutgoing(f) {
    let filtered = this.props.user.filter((g) => {
      return g.user_id === f;
    });
    if (filtered.length > 0) {
      return `${filtered[0].street} ${filtered[0].houseNumber}`;
    }
  }

  getAddressOutgoingSecond(f) {
    let filtered = this.props.user.filter((g) => {
      return g.user_id === f;
    });
    if (filtered.length > 0) {
      return `${filtered[0].zipCode} ${filtered[0].city}`;
    }
  }


  addOrdersToList(cartEvents, cookieItem) {
    let userid = localStorage.getItem("userid");

    let checkOrderId = new Promise(
      (rese, reje) => {
        // firebase
        //   .database()
        //   .ref("all_orders")
        //   .on("value", (data) => {
        //     let a = data.val();
        //     if (a) {
        //       let objected =
        //         Object.entries(a);
        //       rese();
        //       this.setState({
        //         orderidToUpload:
        //           objected.length,
        //       });
        //     }
        //   });
        firebase.database().ref("current_order_id").on("value", (data) => {
          let a = data.val()
          if (a) {
            rese()
            this.setState({ orderidToUpload: Number(a) });
          }
        })
      }
    );
    checkOrderId.then(() => {
      let completeStuff = 0;
      let allItems = []
      cartEvents.map((j) => {
        if (j.type === "product") {
          return false;
        }
        console.log(j, "/dekh");
        if (j.item_type === "bhodi_product") {
          // if (!j.giveawayUnlimited) {
          //     firebase.database().ref("bhodi_campaigns/" + j.created_by + "/" + j.pushKey + "/number").set(Number(j.number) + 1)
          // }

          let selectedSize = ""

          if (this.props.bhodi_products) {
            let myCurrent = this.props.bhodi_products.filter((h) => {
              return (h.pushKey === j.pushKey);
            });
            if (myCurrent[0].inCartOf) {
              let objected = Object.entries(myCurrent[0].inCartOf);
              if (objected.length > 0) {
                let filteredCart = objected.filter((y) => {
                  if (j.selectedSizeOfOrder) {
                    return (y[1].cookie === cookieItem && j.selectedSizeOfOrder === y[1].selectedSize);
                  } else {
                    return (y[1].cookie === cookieItem);
                  }
                });
                if (filteredCart.length > 0) {
                  firebase.database().ref("bhodi_products/" + j.pushKey + "/inCartOf/" + filteredCart[0][1].pushKey).remove();
                }
              }
            }
          }

          let filteredCurrentUserCart = this.props.user.filter((e) => {
            return (e.user_id === j.created_by);
          });

          let currentUserCreator = filteredCurrentUserCart[0];
          let order_id = this.state.orderidToUpload + 1;
          firebase.database().ref("current_order_id").set(order_id)
          let today = new Date();
          let dd = String(today.getDate()).padStart(2, "0");
          let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
          let yyyy = today.getFullYear();

          today = mm + "/" + dd + "/" + yyyy;
          let arrSellers = [];
          arrSellers.push(currentUserCreator.user_id);

          if (j.isSpecialProduct || j.availableForCompanyGuest) {
            this.checkForNextProductTimer(j)
          }

          let obj = {
            created_on: today,
            order_id,
            products: [j],
            buyer: userid,
            sellers: arrSellers,
            order_type: "bhodi_products",
            timeStampCreated: new Date().getTime(),
            orderAlreadyCounted: true,
          };
          if (j.selectedSizeOfOrder) {
            obj.selectedSize = j.selectedSizeOfOrder
          }
          if (filteredCurrentUserCart.length > 0) {
            if (this.props.global_values.shippingCostProducts && this.props.global_values.shippingCostProducts !== "free") {
              obj.shipmentCost = this.props.global_values.shippingCostProducts
            } else {
              obj.shipmentCost = "Free"
            }
          }
          // let pushuserOrderCurrentUser = firebase.database().ref("users/" + userid + "/orders").push().key;
          // firebase.database().ref("users/" + userid + "/orders/" + pushuserOrderCurrentUser).set(obj);
          // let pushuserOrder = firebase.database().ref("users/" + currentUserCreator.user_id + "/orders").push().key;
          // firebase.database().ref("users/" + currentUserCreator.user_id + "/orders/" + pushuserOrder).set(obj);
          let orderKey = firebase.database().ref("all_orders").push().key;
          obj.pushKey = orderKey;
          firebase.database().ref("all_orders/" + orderKey).set(obj);
          let newDate = new Date().getTime();
          let arr = [];
          arr.push(newDate);
          let pushKey = firebase.database().ref("bhodi_products/" + j.pushKey + "/verzilvered_by").push().key;
          let objVerzilver = {
            user_id: userid,
            date: newDate,
            allDates: arr,
            pushKey,
          };
          if (j.selectedSizeOfOrder) {
            objVerzilver.selectedSize = j.selectedSizeOfOrder
          }
          firebase.database().ref("bhodi_products/" + j.pushKey + "/verzilvered_by/" + pushKey).set(objVerzilver);
          // }
          completeStuff = completeStuff + 1;

          allItems.push(obj)


          if (userid) {
            let filtered = this.props.user.filter((userData) => {
              return userid === userData.user_id
            })
            console.log(filtered, '/see here')
            if (filtered.length > 0) {
              let dataToSend = JSON.stringify({
                parcel: {
                  name: filtered[0].first_name + " " + filtered[0].last_name,
                  email: filtered[0].email,
                  telephone: filtered[0].phoneNumber,
                  address: filtered[0].street,
                  house_number: filtered[0].houseNumber,
                  city: filtered[0].city,
                  country: "NL",
                  postal_code: filtered[0].zipCode,
                  order_number: order_id,
                  is_return: false,
                  request_label: false,
                  apply_shipping_rules: false,
                  request_label_async: false,
                }
              });

              let config = {
                method: "post",
                url: "https://us-central1-bhodi-production-da765.cloudfunctions.net/createSendCloudParcel",
                headers: {
                  "Content-Type": "application/json"
                },
                data: dataToSend
              }

              axios(config)
                .then((response) => {
                  console.log(response)
                })
                .catch((error) => {
                  console.log(error)
                })
            }
          }
        }
        if (j.campaignType === "local_event") {
          let filteredCurrentUserCart = this.props.user.filter((e) => {
            return (e.user_id === j.created_by);
          });

          if (this.props.global_campaigns) {
            let myCurrent = this.props.global_campaigns.filter((h) => {
              return (h[1].pushKey === j.pushKey);
            });
            if (j.takeFromAdmin) {
              let obj = mergeAdminEvent(j, this.props.global_campaigns)
              j = Object.assign({}, obj)
            }
            if (myCurrent[0][1].inCartOf) {
              let objected = Object.entries(myCurrent[0][1].inCartOf);
              if (objected.length > 0) {
                let filteredCart = objected.filter((y) => {
                  return y[1].cookie === cookieItem;
                });
                if (filteredCart.length > 0) {
                  filteredCart.map((cartInner) => {
                    firebase
                      .database()
                      .ref(
                        "bhodi_campaigns/" +
                        j.created_by +
                        "/" +
                        j.pushKey +
                        "/inCartOf/" +
                        cartInner[1].pushKey
                      )
                      .remove();
                  })
                }
              }
            }
          }

          let order_id = this.state.orderidToUpload + 1;
          firebase.database().ref("current_order_id").set(order_id)
          let today = new Date();
          let dd = String(today.getDate()).padStart(2, "0");
          let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
          let yyyy = today.getFullYear();

          today = mm + "/" + dd + "/" + yyyy;
          let arrSellers = [];

          arrSellers.push(filteredCurrentUserCart[0].user_id);

          // j.reserved_event_id = j.timings[0].eventId;

          let obj = {
            created_on: today,
            order_id,
            products: [j],
            buyer: userid,
            sellers: arrSellers,
            order_type: "campaign",
            timeStampCreated:
              new Date().getTime(),
          };
          // let pushuserOrderCurrentUser =
          //   firebase
          //     .database()
          //     .ref(
          //       "users/" +
          //       userid +
          //       "/orders"
          //     )
          //     .push().key;
          // firebase
          //   .database()
          //   .ref(
          //     "users/" +
          //     userid +
          //     "/orders/" +
          //     pushuserOrderCurrentUser
          //   )
          //   .set(obj);
          // let pushuserOrder = firebase
          //   .database()
          //   .ref(
          //     "users/" +
          //     filteredCurrentUserCart[0]
          //       .user_id +
          //     "/orders"
          //   )
          //   .push().key;
          // firebase
          //   .database()
          //   .ref(
          //     "users/" +
          //     filteredCurrentUserCart[0]
          //       .user_id +
          //     "/orders/" +
          //     pushuserOrder
          //   )
          //   .set(obj);
          let orderKey = firebase.database().ref("all_orders").push().key;
          obj.pushKey = orderKey;
          firebase.database().ref("all_orders/" + orderKey).set(obj);


          let newDate = new Date().getTime();
          let arr = [];
          arr.push(newDate);
          let pushKey = firebase
            .database()
            .ref("bhodi_campaigns/" + filteredCurrentUserCart[0].user_id + "/" + j.pushKey + "/checked_in_by").push().key;
          let objCheckedIn = {
            user_id: userid,
            date: newDate,
            allDates: arr,
            pushKey,
            event_id: obj.products[0].reserved_event_id,
            order_key: orderKey,
          };
          firebase
            .database()
            .ref(
              "bhodi_campaigns/" +
              filteredCurrentUserCart[0]
                .user_id +
              "/" +
              j.pushKey +
              "/checked_in_by/" +
              pushKey
            )
            .set(objCheckedIn);

          completeStuff = completeStuff + 1;
          allItems.push(obj)

        } else if (j.campaignType === "kortings_voucher") {
          let myCurrent = ""
          if (this.props.global_campaigns) {
            myCurrent =
              this.props.global_campaigns.filter((h) => {
                return (h[1].pushKey === j.pushKey);
              });
            if (myCurrent[0][1].inCartOf) {
              let objected = Object.entries(myCurrent[0][1].inCartOf);
              if (objected.length > 0) {
                let filteredCart = objected.filter((y) => {
                  return (y[1].cookie === cookieItem);
                });
                if (filteredCart.length > 0) {
                  firebase
                    .database()
                    .ref(
                      "bhodi_campaigns/" +
                      j.created_by +
                      "/" +
                      j.pushKey +
                      "/inCartOf/" +
                      filteredCart[0][1].pushKey
                    )
                    .remove();
                }
              }
            }
          }

          if (myCurrent[0][1].timerDateWaiting && myCurrent[0][1].timerTimeWaiting && new Date(Date.parse(myCurrent[0][1].isoTimeTimerBtn)).getTime() > new Date().getTime()) {
            bookmarkCurrentProduct(myCurrent[0][1])
          } else {
            let filteredCurrentUserCart =
              this.props.user.filter(
                (e) => {
                  return (
                    e.user_id ===
                    j.created_by
                  );
                }
              );
            let currentUserCreator = filteredCurrentUserCart[0];
            let order_id = this.state.orderidToUpload + 1;
            firebase.database().ref("current_order_id").set(order_id)
            let today = new Date();
            let dd = String(
              today.getDate()
            ).padStart(2, "0");
            let mm = String(
              today.getMonth() + 1
            ).padStart(2, "0"); //January is 0!
            let yyyy =
              today.getFullYear();

            today =
              mm + "/" + dd + "/" + yyyy;
            let arrSellers = [];
            arrSellers.push(
              currentUserCreator.user_id
            );
            let obj = {
              created_on: today,
              order_id,
              products: [j],
              buyer: userid,
              sellers: arrSellers,
              order_type: "campaign",
              timeStampCreated: new Date().getTime(),
            };
            // let pushuserOrderCurrentUser =
            //   firebase
            //     .database()
            //     .ref(
            //       "users/" +
            //       userid +
            //       "/orders"
            //     )
            //     .push().key;
            // firebase
            //   .database()
            //   .ref(
            //     "users/" +
            //     userid +
            //     "/orders/" +
            //     pushuserOrderCurrentUser
            //   )
            //   .set(obj);
            // let pushuserOrder = firebase
            //   .database()
            //   .ref(
            //     "users/" +
            //     currentUserCreator.user_id +
            //     "/orders"
            //   )
            //   .push().key;
            // firebase
            //   .database()
            //   .ref(
            //     "users/" +
            //     currentUserCreator.user_id +
            //     "/orders/" +
            //     pushuserOrder
            //   )
            //   .set(obj);
            let orderKey = firebase.database().ref("all_orders").push().key;
            obj.pushKey = orderKey;
            firebase.database().ref("all_orders/" + orderKey).set(obj);

            let newDate =
              new Date().getTime();
            let arr = [];
            arr.push(newDate);
            let pushKey = firebase
              .database()
              .ref(
                "bhodi_campaigns/" +
                currentUserCreator.user_id +
                "/" +
                j.pushKey +
                "/verzilvered_by"
              )
              .push().key;
            let objSecond = {
              user_id: userid,
              date: newDate,
              allDates: arr,
              pushKey,
            };
            firebase
              .database()
              .ref("bhodi_campaigns/" + currentUserCreator.user_id + "/" + j.pushKey + "/verzilvered_by/" + pushKey)
              .set(objSecond);

            allItems.push(obj)
          }

          completeStuff = completeStuff + 1;
        } else if (j.campaignType === "web_shop") {
          let myCurrent = ""
          if (this.props.global_campaigns) {
            myCurrent =
              this.props.global_campaigns.filter((h) => {
                return (h[1].pushKey === j.pushKey);
              });
            if (myCurrent[0][1].inCartOf) {
              let objected = Object.entries(myCurrent[0][1].inCartOf);
              if (objected.length > 0) {
                let filteredCart = objected.filter((y) => {
                  return (y[1].cookie === cookieItem);
                });
                if (filteredCart.length > 0) {
                  firebase
                    .database()
                    .ref(
                      "bhodi_campaigns/" +
                      j.created_by +
                      "/" +
                      j.pushKey +
                      "/inCartOf/" +
                      filteredCart[0][1].pushKey
                    )
                    .remove();
                }
              }
            }
          }

          let filteredCurrentUserCart = this.props.user.filter(
            (e) => {
              return (
                e.user_id ===
                j.created_by
              );
            }
          );
          let currentUserCreator = filteredCurrentUserCart[0];
          let order_id = this.state.orderidToUpload + 1;
          firebase.database().ref("current_order_id").set(order_id)
          let today = new Date();
          let dd = String(
            today.getDate()
          ).padStart(2, "0");
          let mm = String(
            today.getMonth() + 1
          ).padStart(2, "0"); //January is 0!
          let yyyy =
            today.getFullYear();

          today =
            mm + "/" + dd + "/" + yyyy;
          let arrSellers = [];
          arrSellers.push(
            currentUserCreator.user_id
          );
          let obj = {
            created_on: today,
            order_id,
            products: [j],
            buyer: userid,
            sellers: arrSellers,
            order_type: "campaign",
            timeStampCreated: new Date().getTime(),
          };

          let orderKey = firebase.database().ref("all_orders").push().key;
          obj.pushKey = orderKey;
          firebase.database().ref("all_orders/" + orderKey).set(obj);

          let newDate =
            new Date().getTime();
          let arr = [];
          arr.push(newDate);
          let pushKey = firebase
            .database()
            .ref(
              "bhodi_campaigns/" +
              currentUserCreator.user_id +
              "/" +
              j.pushKey +
              "/verzilvered_by"
            )
            .push().key;
          let objSecond = {
            user_id: userid,
            date: newDate,
            allDates: arr,
            pushKey,
          };
          firebase
            .database()
            .ref("bhodi_campaigns/" + currentUserCreator.user_id + "/" + j.pushKey + "/verzilvered_by/" + pushKey)
            .set(objSecond);

          allItems.push(obj)

          completeStuff = completeStuff + 1;
        }
        if (
          cartEvents.length ===
          completeStuff
        ) {
          // console.log(allItems, '/see here')

          let itemsHTML = ""
          let sellersHTML = ""

          allItems.map((item, index) => {
            if (item.products[0].campaignType === "kortings_voucher") {
              let product = item.products[0]
              let pricing = ""
              if (product.kortingsVoucherGiveaway) {
                pricing = "Free"
              } else if (product.kortingsVoucherPrijs) {
                pricing = `€ ${product.amount}`
              } else if (product.kortingsVoucherPercentage) {
                pricing = `${product.amount} %`
              } else if (!product.isoTimeTimerBtnEndTime) {
                pricing = `€ ${product.kortingsVoucherSalePrice}`
              } else {
                if (new Date(product.isoTimeTimerBtnEndTime).getTime() > new Date().getTime()) {
                  pricing = `€ ${product.kortingsVoucherSalePrice}`
                } else {
                  pricing = `€ ${product.kortingsVoucherNormalPrice}`
                }
              }

              itemsHTML += `<div><h4>${index + 1}. ${product.title}</h4><div>${product.desc}</div><p>Kortingsvoucher ${pricing}</p></div>`
            } else if (item.products[0].campaignType === "local_event") {
              let product = item.products[0]
              let pricing = ""
              if (product.localEventType === "percentage") {
                pricing = `${product.amount} %`
              } else {
                pricing = `€ ${product.amount}`
              }

              itemsHTML += `<div><h4>${index + 1}. ${product.title}</h4><div>${product.desc}</div><p>EVENT: Korting ${pricing}</p>Datum: ${this.showDateToUserTimingMail(product)}<p></p><p>Tijd: ${this.showTimeToUserTimingMail(product)}</p></div>`
            } else if (item.products[0].campaignType === "web_shop") {
              let product = item.products[0]
              let pricing = ""
              if (product.voucherIsPercentage) {
                pricing = `${product.amount} %`
              } else {
                pricing = `€ ${product.amount}`
              }

              if (product.isoStartTime && product.isoEndTime) {
                itemsHTML += `<div><h4>${index + 1}. ${product.title}</h4><div>${product.desc}</div><p>Webshop: Korting ${pricing}</p>Datum: ${this.showDateToUserTimingMailWebshop(product)}<p></p><p>Tijd: ${this.showTimeToUserTimingMailWebshop(product)}</p></div>`
              } else {
                itemsHTML += `<div><h4>${index + 1}. ${product.title}</h4><div>${product.desc}</div><p>Webshop: Korting ${pricing}</p></div>`
              }
            } else if (item.products[0].item_type === "bhodi_product") {
              let product = item.products[0]
              itemsHTML += `<div><h4>${index + 1}. ${product.title}</h4><div>${product.desc}</div></div>`
            }
          })

          allItems.filter((v, i, a) => a.findIndex((t) => t.products[0].created_by === v.products[0].created_by) === i).map((item) => {
            let htmlForCompanies = "<div>"

            htmlForCompanies += `<p>Name: ${this.getBuyerNameOutgoing(item.products[0].created_by)}</p>`
            htmlForCompanies += `<p>Address:</p>`
            htmlForCompanies += `<p>${this.getAddressOutgoing(item.products[0].created_by)}</p>`
            htmlForCompanies += `<p>${this.getAddressOutgoingSecond(item.products[0].created_by)}</p>`

            htmlForCompanies += "</div><br />"

            sellersHTML += htmlForCompanies
          })

          var dataForAPI = JSON.stringify({
            sendToEmail: "fahimrazaofficial2022@gmail.com",
            subject: "Order Confirmation at Bhodi!",
            innerHTMLpart: `<!DOCTYPE html><html><head><title>Page Title</title></head><body><h2>Congratulations On Your Order!</h2><p>Your order is processed at Bhodi.</p><h3>Items:</h3>${itemsHTML}<br /><h3>Aanbieders</h3>${sellersHTML}</body></html>`,
          });

          var config = {
            method: "post",
            url: "https://us-central1-bhodi-production-da765.cloudfunctions.net/sendEmailMailjet",
            headers: {
              "Content-Type": "application/json",
            },
            data: dataForAPI,
          };

          axios(config)
            .then((response) => {
              if (response.data.statusCode === 201) {
                console.log(response)
              }
            })
            .catch(function (error) {
              console.log(error);
            });

          this.setState({
            create_subscription: false,
            showLoader: false,
            showLoaderMain: false,
            showCharityConfirmation: true,
          });
          this.props.updateSignUpFieldsFilled(
            ""
          );
          this.props.updateGuestUserCartExtendOpportunity(
            ""
          );
          this.props.updateGuestUserCartTime(
            ""
          );
          this.props.updateGuestUserCart(
            []
          );

          // let cookieItemInner =
          //   localStorage.getItem(
          //     "cookie_item"
          //   );
          // let useridInner =
          //   localStorage.getItem(
          //     "userid"
          //   );
          // if (useridInner) {
          //   cookieItemInner = useridInner;
          // }
          localStorage.removeItem(
            "cookie_item"
          );
          firebase
            .database()
            .ref(
              "guest_carts/" +
              cookieItem
            )
            .remove();
        }
      });
    });
  }

  getDayName(num) {
    if (num === 0) {
      return `Zondag`;
    } else if (num === 1) {
      return `Maandag`;
    } else if (num === 2) {
      return `Dinsdag`;
    } else if (num === 3) {
      return `Woensdag`;
    } else if (num === 4) {
      return `Donderdag`;
    } else if (num === 5) {
      return `Vrijdag`;
    } else if (num === 6) {
      return `Zaterdag`;
    }
  }

  getMonthName(i) {
    i = Number(i);
    if (i === 1) {
      return "Januari";
    } else if (i === 2) {
      return "Februari";
    } else if (i === 3) {
      return "Maart";
    } else if (i === 4) {
      return "April";
    } else if (i === 5) {
      return "Mei";
    } else if (i === 6) {
      return "Juni";
    } else if (i === 7) {
      return "Juli";
    } else if (i === 8) {
      return "Augustus";
    } else if (i === 9) {
      return "September";
    } else if (i === 10) {
      return "Oktober";
    } else if (i === 11) {
      return "November";
    } else if (i === 12) {
      return "December";
    }
  }

  showDateToUserTiming(e, f) {
    if (e === f) {
      let splitted = e.split("-");
      let newDate = new Date(e).getDay();
      return `${this.getDayName(newDate)} ${splitted[2]} ${this.getMonthName(
        splitted[1]
      )}`;
    } else {
      let splitted = e.split("-");
      let splittedSecond = f.split("-");
      let newDate = new Date(e).getDay();
      let newDateAnother = new Date(f).getDay();
      if (splitted[1] === splittedSecond[1]) {
        return `${this.getDayName(newDate)} ${splitted[2]} - ${splittedSecond[2]
          } ${this.getMonthName(splittedSecond[1])}`;
      } else {
        // return `${this.getDayName(newDate)} ${splitted[2]} ${this.getMonthName(splitted[1])} - ${this.getDayName(newDateAnother)} ${splittedSecond[2]} ${this.getMonthName(splittedSecond[1])}`
        return `${splitted[2]} ${this.getMonthName(splitted[1])} t/m ${splittedSecond[2]
          } ${this.getMonthName(splittedSecond[1])}`;
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  checkForNextProductTimer(product) {
    let filterMyProducts = this.props.bhodi_products.filter((g) => {
      if (product.availableForCompanyGuest) {
        return g.created_by === product.created_by && g.availableForCompanyGuest
      }
      if (product.productForPage) {
        return g.created_by === product.created_by && g.availableForCompanyGuest && g.productForPage === product.productForPage
      }
      return g.created_by === product.created_by && g.isSpecialProduct && !g.productForPage
    })
    let sortedAllProducts = [...filterMyProducts].sort((a, b) => {
      return a.sort_time * 100 - b.sort_time * 100;
    })

    let maxProductsToShow = 5

    if (this.props.global_values) {
      if (this.props.global_values.products_count_new) {
        maxProductsToShow = Number(this.props.global_values.products_count_new)
      }
    }
    if (sortedAllProducts.length > maxProductsToShow) {
      this.assignTimerToComingProduct(product)
    }
  }

  assignTimerToComingProduct(product) {

    let filterMyProducts = this.props.bhodi_products.filter((productInner) => {
      if (productInner.sizesActive) {
        let stocksAvailableSizes = []
        productInner.variations_data.map((e) => {
          if (Number(e.qty) === 0) {
            return false;
          }
          let filteredStockLength = []
          let filteredStockLengthVerzilvered = []
          if (productInner.verzilvered_by) {
            filteredStockLengthVerzilvered = Object.entries(productInner.verzilvered_by).filter((t) => {
              return t[1].selectedSize === e.size
            })
          }
          if ((filteredStockLength.length + filteredStockLengthVerzilvered.length) < Number(e.qty)) {
            stocksAvailableSizes.push(e)
          }
        })
        if (stocksAvailableSizes.length === 0) {
          return false;
        }
      } else {
        let filteredStockLength = []
        let filteredStockLengthVerzilvered = []
        if (productInner.verzilvered_by) {
          filteredStockLengthVerzilvered = Object.entries(productInner.verzilvered_by).filter((t) => {
            return !t[1].selectedSize
          })
        }
        if (Number(productInner.qty) <= (filteredStockLength.length + filteredStockLengthVerzilvered.length) || Number(productInner.qty) === 0) {
          return false;
        }
      }

      if (product.availableForCompanyGuest) {
        return !productInner.availableOnTime && productInner.images && productInner.active && productInner.availableForCompanyGuest && productInner.created_by === product.created_by
      } else if (product.productForPage) {
        return !productInner.availableOnTime && productInner.images && productInner.active && productInner.isSpecialProduct && productInner.created_by === product.created_by && productInner.productForPage === product.productForPage
      } else {
        return !productInner.availableOnTime && productInner.images && productInner.active && productInner.isSpecialProduct && productInner.created_by === product.created_by && !productInner.productForPage
      }
    })

    let filterCurrentUser = this.props.user.filter((g) => {
      return g.user_id === product.created_by
    })
    if (filterCurrentUser.length > 0 && filterMyProducts.length > 0) {

      let sortedAllProducts = [...filterMyProducts].sort((a, b) => {
        return a.sort_time * 100 - b.sort_time * 100;
      })

      let maxProductsToShow = 5

      if (this.props.global_values) {
        if (this.props.global_values.products_count_new) {
          maxProductsToShow = Number(this.props.global_values.products_count_new)
        }
      }

      if (sortedAllProducts.length > maxProductsToShow) {
        let newerProduct = sortedAllProducts[maxProductsToShow]

        let dateCreated = new Date()

        if (filterCurrentUser[0].productsInfo && filterCurrentUser[0].productsInfo.intervalTimer) {
          let splitted = filterCurrentUser[0].productsInfo.intervalTimer.split(" ")

          if (splitted[1].includes("second")) {
            dateCreated.setSeconds(dateCreated.getSeconds() + Number(splitted[0]))
          } else if (splitted[1].includes("minute")) {
            dateCreated.setMinutes(dateCreated.getMinutes() + Number(splitted[0]))
          } else {
            dateCreated.setHours(dateCreated.getHours() + Number(splitted[0]))
          }

          firebase.database().ref(`bhodi_products/${newerProduct.pushKey}/buttonTimingToShow`).set(dateCreated.toISOString())
        }
      }
    }
  }


  createMarkup(e) {
    if (e) {
      return { __html: e };
    }
  }

  render() {
    return this.state.showLoaderMain ? (
      <div
        className="global-event-cart globaleventloaderpage"
        style={{
          height: "auto",
          top: window.innerWidth > 1009 && 70,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 999999999,
        }}
      >
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        {window.innerWidth > 1008 && <Footer notOnMainPage={true} />}
        {window.innerWidth < 1008 && <FooterGreen notOnMainPage={true} />}
      </div>
    ) : this.state.showLoader ? (
      <div
        className="global-event-cart globaleventloaderpage"
        style={{
          height: "auto",
          top: window.innerWidth > 1009 && 70,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 999999999,
        }}
      >
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        {window.innerWidth > 1008 && <Footer notOnMainPage={true} />}
        {window.innerWidth < 1008 && <FooterGreen notOnMainPage={true} />}
      </div>
    ) : this.state.showNewlyRegistered ? (
      <NewVisit
        fromConfirmationPage={true}
        currentUserPropin={this.state.currentUser}
      />
    ) : (
      <div
        className="global-event-cart globaleventpage-newstuff"
        style={{ height: "auto", top: window.innerWidth > 1009 && 70, zIndex: 999 }}
      >
        <div
          className="left-gec charitylgec"
          style={{ paddingBottom: window.innerWidth < 1009 && 40 }}
        >
          {this.state.paymentCancelled ? (
            <div className="container-lgec">
              {/* <p className="heading-lpcomp">Welkom bij Bhodi</p> */}
              <p className="title-lpcomp">
                Hoi{" "}
                {this.state.currentUser.type === "user"
                  ? this.state.currentUser.first_name
                  : this.state.currentUser.company_name},{" "}
              </p>
              <p className="title-lpcomp">
                <b>Yesss... de payment is cancelled!</b>
              </p>
              <button
                className="button-new-lpcomp btn-newlpc"
                onClick={() => {
                  if (this.state.currentUser.type === "user") {
                    window.location.href = `/member/${this.state.currentUser.user_name_id}`;
                  } else {
                    window.location.href = `/company/${this.state.currentUser.user_name_id}`;
                  }
                }}
                style={{
                  cursor: "pointer",
                  textTransform: "none",
                  marginTop: 10,
                }}
              >
                Ontdek
              </button>
            </div>
          ) : this.state.showProductsText ? (
            <div className="container-lgec">
              {/* <p className="heading-lpcomp">Welkom bij Bhodi</p> */}
              <p className="title-lpcomp">
                Hoi{" "}
                {this.state.currentUser.type === "user"
                  ? this.state.currentUser.first_name
                  : this.state.currentUser.company_name},{" "}
              </p>
              <p className="title-lpcomp" dangerouslySetInnerHTML={this.createMarkup(this.state.showDutch ? (window.innerWidth > 1008 ? this.state.checkout_confirmation_products_text : this.state.checkout_confirmation_products_text_mobile) : (window.innerWidth > 1008 ? this.state.checkout_confirmation_products_text_english : this.state.checkout_confirmation_products_text_english_mobile))}>
              </p>
              {/* <p className="title-lpcomp">
                <b>Yesss... de bestelling is gelukt!</b>
              </p>
              <p className="title-lpcomp">
                De details van je bestelling vind je terug in het orderoverzicht&nbsp;
                {window.innerWidth > 1008 && (<br />)}
                van je profielpagina onder het tabblad producten.&nbsp;
              </p>
              <p className="title-lpcomp">
                Mocht je nog vragen hebben over je bestelling,&nbsp;
                {window.innerWidth > 1008 && (<br />)}
                neem dan contact op met de aanbieder.&nbsp;
              </p>
              <p className="title-lpcomp">
                <i>Team Bhodi</i>
              </p> */}
              <button
                className="button-new-lpcomp btn-newlpc"
                onClick={() => {
                  if (this.state.currentUser.type === "user") {
                    window.location.href = `/member/${this.state.currentUser.user_name_id}`;
                  } else {
                    window.location.href = `/company/${this.state.currentUser.user_name_id}`;
                  }
                }}
                style={{
                  cursor: "pointer",
                  textTransform: "none",
                  marginTop: 10,
                }}
              >
                Ontdek
              </button>
            </div>
          ) : this.state.cookieItemCheck ? (
            <div className="container-lgec">
              {/* <p className="heading-lpcomp">Welkom bij Bhodi</p> */}
              <p className="title-lpcomp">
                Hoi{" "}
                {this.state.currentUser.type === "user"
                  ? this.state.currentUser.first_name
                  : this.state.currentUser.company_name},{" "}
                welkom bij Bhodi.
              </p>

              <p className="title-lpcomp" dangerouslySetInnerHTML={this.createMarkup(this.state.showDutch ? (window.innerWidth > 1008 ? this.state.checkout_confirmation_text_guest : this.state.checkout_confirmation_text_guest_mobile) : (window.innerWidth > 1008 ? this.state.checkout_confirmation_text_guest_english : this.state.checkout_confirmation_text_guest_english_mobile))}>
              </p>
              {/* <p className="title-lpcomp">
                <b>Je aanmelding is gelukt!</b>
              </p>
              <p className="title-lpcomp">
                De details van je bestelling vind je terug in het orderoverzicht&nbsp;
                {window.innerWidth > 1008 && (<br />)}
                van je profielpagina. Vanuit dit overzicht kun je jouw bestelling&nbsp;
                {window.innerWidth > 1008 && (<br />)}
                valideren bij de aanbieder. Voor events geldt dat je jouw komst&nbsp;
                {window.innerWidth > 1008 && (<br />)}
                pas op de dag van het event kunt valideren.
              </p>
              <p className="title-lpcomp" style={{ marginBottom: 5 }}>
                <b>Charity Member van Bhodi</b>
              </p>
              <p className="title-lpcomp">
                Leuk dat je Charity Member geworden bent! Vanaf nu kun je direct&nbsp;
                {window.innerWidth > 1008 && (<br />)}
                gebruikmaken van de speciale acties en exclusieve events op Bhodi.&nbsp;
                {window.innerWidth > 1008 && (<br />)}
                Ons team in Tanzania gaat aan de slag met jouw persoonlijke&nbsp;
                {window.innerWidth > 1008 && (<br />)}
                bedankvideo en je kunt deze video bekijken op je Charity pagina.
              </p>
              <p className="title-lpcomp">
                <i>Bedankt voor je steun!</i>
              </p> */}
              <button
                className="button-new-lpcomp btn-newlpc"
                onClick={() => {
                  if (this.state.currentUser.type === "user") {
                    window.location.href = `/member/${this.state.currentUser.user_name_id}`;
                  } else {
                    window.location.href = `/company/${this.state.currentUser.user_name_id}`;
                  }
                }}
                style={{
                  cursor: "pointer",
                  textTransform: "none",
                  marginTop: 10,
                }}
              >
                Ontdek
              </button>
            </div>
          ) : (
            <div className="container-lgec">
              {/* <p className="heading-lpcomp">WELKOM BIJ BHODI</p> */}
              <p className="title-lpcomp">
                Hoi{" "}
                {this.state.currentUser.type === "user"
                  ? this.state.currentUser.first_name
                  : this.state.currentUser.company_name}
                ,
              </p>
              <p className="title-lpcomp" dangerouslySetInnerHTML={this.createMarkup(this.state.showDutch ? (window.innerWidth > 1008 ? this.state.checkout_confirmation_text : this.state.checkout_confirmation_text_mobile) : (window.innerWidth > 1008 ? this.state.checkout_confirmation_text_english : this.state.checkout_confirmation_text_english_mobile))}>
              </p>
              {/* <p className="title-lpcomp">
                <b>Je aanmelding is gelukt!</b>
              </p>
              <p className="title-lpcomp">
                De details van je bestelling vind je terug in het orderoverzicht&nbsp;
                {window.innerWidth > 1008 && (<br />)}
                van je profielpagina. Vanuit dit overzicht kun je jouw bestelling&nbsp;
                {window.innerWidth > 1008 && (<br />)}
                valideren bij de aanbieder. Voor events geldt dat je jouw komst&nbsp;
                {window.innerWidth > 1008 && (<br />)}
                pas op de dag van het event kunt valideren.
              </p>
              <p className="title-lpcomp">
                <i>Bedankt voor je steun!</i>
              </p> */}
              <button
                className="button-new-lpcomp"
                onClick={() => {
                  if (this.state.currentUser.type === "user") {
                    window.location.href = `/member/${this.state.currentUser.user_name_id}`;
                  } else {
                    window.location.href = `/company/${this.state.currentUser.user_name_id}`;
                  }
                }}
                style={{
                  cursor: "pointer",
                  textTransform: "none",
                  marginTop: 10,
                }}
              >
                Verder
              </button>
            </div>
          )}
        </div>
        {this.state.popupopenforright && (
          <div
            className="right-gec rgec-backgroundimage minHeightImageMobileConfirmation"
            style={{
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              maxWidth: 1180,
              backgroundImage: this.state.charity_confirmation_bg_image && `url("${this.state.charity_confirmation_bg_image}")`
            }}
          >
          </div>
        )}
        {window.innerWidth > 1008 && <Footer notOnMainPage={true} />}
        {window.innerWidth < 1008 && <FooterGreen notOnMainPage={true} />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducers.user,
    album: state.postsReducers.album,
    images: state.postsReducers.images,
    global_values: state.globalReducers.global_values,
    product: state.campaignsReducer.product,
    global_campaigns: state.campaignsReducer.global_campaigns,
    all_impressies: state.globalReducers.all_impressies,
    guest_user_cart: state.cartReducer.guest_user_cart,
    bhodi_products: state.productsReducers.bhodi_products,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateGuestUserCart: (guest_user_cart) =>
      dispatch(updateGuestUserCart(guest_user_cart)),
    updateGuestUserCartTime: (guest_user_cart_time) =>
      dispatch(updateGuestUserCartTime(guest_user_cart_time)),
    updateGuestUserCartExtendOpportunity: (
      guest_user_cart_extend_opportunity
    ) =>
      dispatch(
        updateGuestUserCartExtendOpportunity(guest_user_cart_extend_opportunity)
      ),
    updateSignUpFieldsFilled: (signup_fields_info) =>
      dispatch(updateSignUpFieldsFilled(signup_fields_info)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CharityConfirmationFullPage);
