import React, { Component } from "react";
import firebase from "../Config/Firebase";
import { Link } from "react-router-dom";
import {
    updateTitle,
    updateBhodiProducts,
} from "../Redux/actions/authActions";
import { connect } from "react-redux";
import { BiBell } from "react-icons/bi";
import NavbarMenuMobileFooterStuff from "./NavbarMenuMobileFooterStuff";
import { mergeAdminEvent } from "../Functions/globalFunctions";

export class RoundIconsNavbarMobile extends Component {
    constructor() {
        super();
        this.state = {
            active: false,
            search: false,
            searchText: "",
            rightModalSettings: false,
            arrForSearch: [],
            checkedPrivate: false,
            checkedHideDonation: false,
            checkedHideFriendsInfo: false,
            checkedHideProfileImage: false,
            checkedHideProfileInfo: false,
            showItemsDiv: false,
            showItems: false,
            showLists: true,
            showFriends: false,
            showFriendstab: false,
            showProductsBookmarked: false,
            showBookmarkTab: false,
            showProductsOthertab: false,
            userNameInput: false,
            userDescInput: false,
            lengthOfOtherProducts: [],
            lengthOfOtherVouchers: [],
            lengthOfOtherCampaigns: [],
            userInfoRight: true,
            typeOfOrder: "incoming",
            showCartOrder: true,
            savtBtnText: "SAVE",
            isDesktop: true,
            isScrolled: false,
            menuItemsModals: true,
            hideMenuContent: false,
            lengthOfCurrentContents: [],
            lengthOfOtherContents: [],
            currentUserMember: "",
            bhodiFaqs: [],
            arrDropdowns: [],
            bhodiAbouts: [],
            arrDropdownsAbouts: [],
            currentUserOfPopups: "",
            redeem_code: "",
            guestUserCart: [],
            deleteBtnText: "SAVE",
            showDelete: false,
            password: "",
            showPassword: false,
            confirmPassword: "",
            showconfirmPassword: false,
            currentPassword: "",
            showcurrentPassword: false,
            notSeenMessages: [],
            charityCount: 0,
            showOrderIcon: true,
            toShowAlbum: "",
            onOpgelsagenMain: false,
            onGelikedMain: false,
            toShowAlbumImg: "",
            updated: true,
            commentsReplies: [],
            commentsHere: [],
            maxLimitAlleReacties: 15,
            trendingActive: true,
        };
    }

    updateTitle() {
        if (this.props.message) {
            let allMessages = [];
            this.props.message.map((g) => {
                let objected = Object.entries(g[1]);
                objected.map((y) => {
                    allMessages.push(y[1]);
                });
            });
            let filtered = allMessages.filter((g) => {
                return !g.seen && g.type === "reciever";
            });
            this.setState({ notSeenMessages: filtered });
        } else {
            this.setState({ notSeenMessages: [] });
        }

        let userid = localStorage.getItem("userid");
        let uid = localStorage.getItem("userid");

        firebase.database().ref("charity_videos/" + userid)
            .on("value", (data) => {
                let a = data.val();
                if (a) {
                    let objected = Object.entries(a);
                    let filtered = objected.filter((e) => {
                        return !e[1].seen
                    })
                    this.setState({ charityCount: filtered.length });
                }
            });

        this.setState({ currentUserId: userid });

        let route = window.location.pathname;
        if (route.includes("/friends")) {
            this.setState({
                showLists: false,
            });
        } else if (route.includes("/vrienden")) {
            this.setState({
                showLists: false,
                onFriendsPage: true,
                onCharityPage: false,
                onVolgendPage: false,
            });
        } else if (route.includes("/charity")) {
            this.setState({
                showLists: false,
                onCharityPage: true,
                onVolgendPage: false,
                onFriendsPage: false,
            });
        } else if (route.includes("/volgend")) {
            this.setState({
                showLists: false,
                onCharityPage: false,
                onVolgendPage: true,
                onFriendsPage: false,
            });
        } else {
            this.setState({
                showLists: true,
                showItems: false,
                showFriends: false,
                onFriendsPage: false,
                showProductsBookmarked: false,
                showCharity: false,
                shopProductsShop: false,
            });
        }

        if (this.props.user) {
            if (this.props.user.length > 0) {
                let filter = this.props.user.filter((u) => {
                    return u.user_name_id === this.props.member_id;
                });
                let currentData = filter[0];
                if (filter.length > 0) {
                    if (currentData) {
                        let a = currentData;
                        if (a.friends) {
                            let objected = Object.entries(a.friends);
                            a.objectedFriends = objected;
                        } else {
                            a.objectedFriends = [];
                        }
                        if (!a.profile_image) {
                            a.profile_image =
                                "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9";
                        }
                        this.setState({
                            currentUserMember: a,
                            create_subscription: a.create_subscription,
                            userid: userid,
                            url: a.user_name_id,
                            firstName: a.first_name,
                            lastName: a.last_name,
                            city: a.city,
                            zipCode: a.zipCode,
                            street: a.street,
                            houseNumber: a.houseNumber,
                            country: a.country,
                            phoneNumber: a.phoneNumber,
                            email: a.email,
                            profileImageUrl: a.profile_image,
                            description: a.user_description,
                        });
                    }
                }
            }
        }

        let bhodi_campaigns = this.props.global_campaigns;
        if (bhodi_campaigns) {
            if (bhodi_campaigns.length > 0) {
                let arr = [];
                let allCampaignsCompleted = [];
                let allCampaignsCompletedToRender = [];
                let arrCurrentCampaigns = [];
                let objected = bhodi_campaigns;
                objected.map((e) => {
                    e[1].type = "campaign";
                    if (e[1].active) {
                        allCampaignsCompletedToRender.push(e[1]);
                        if (e[1].desc) {
                            e[1].desc = e[1].desc.substr(0, 200);
                        }
                        allCampaignsCompleted.push(e[1]);
                    }
                });
                allCampaignsCompleted.map((e) => {
                    e.type = "campaign";
                    if (e.created_by !== uid) {
                        let filtered = this.props.user.filter((f) => {
                            return f.user_id === e.created_by;
                        });
                        if (filtered.length > 0) {
                            let f = filtered[0];
                            if (f.followed_by || f.friends) {
                                let objectedFriends = [];
                                if (f.followed_by) {
                                    objectedFriends = Object.entries(f.followed_by);
                                }
                                objectedFriends.map((g) => {
                                    if (g[1].user_id === uid) {
                                        if (e.active) {
                                            if (e.desc) {
                                                e.desc = e.desc.substr(0, 520);
                                                e.short_desc = e.desc.substr(0, 120);
                                            }
                                            e.images = [e.image];
                                            e.imagesObjected = [
                                                { image: e.image, pushKey: e.pushKey },
                                            ];

                                            if (e.campaignType === "local_event") {
                                                if (e.takeFromAdmin) {
                                                    let obj = mergeAdminEvent(e, this.props.global_campaigns)
                                                    e = Object.assign({}, obj)
                                                }
                                                let arrTimingsAvailable = [];
                                                if (e.timings) {
                                                    e.timings.map((y) => {
                                                        if (y.isoStartTime && y.isoEndTime) {
                                                            let checkedInOfCurrentBlock = [];
                                                            if (e.checked_in_by) {
                                                                Object.entries(e.checked_in_by).map((t) => {
                                                                    if (t[1].event_id === y.eventId) {
                                                                        checkedInOfCurrentBlock.push(t[1]);
                                                                    }
                                                                });
                                                            }

                                                            let getCurrentDate = new Date();
                                                            if (
                                                                getCurrentDate.getTime() <
                                                                new Date(Date.parse(y.isoEndTime)).getTime()
                                                            ) {
                                                                if (
                                                                    checkedInOfCurrentBlock.length <
                                                                    Number(y.personsAmountEvent)
                                                                ) {
                                                                    arrTimingsAvailable.push(y);
                                                                }
                                                            }
                                                        }
                                                    });
                                                }
                                                if (arrTimingsAvailable.length > 0) {
                                                    arr.push(e);
                                                }
                                            }
                                            if (e.campaignType === "web_shop") {
                                                let objectedVerzilveredBy = [];
                                                let num = 0;
                                                if (e.verzilvered_by) {
                                                    objectedVerzilveredBy = Object.entries(
                                                        e.verzilvered_by
                                                    );
                                                    num = this.getVerzilveredQty(
                                                        objectedVerzilveredBy
                                                    );
                                                }
                                                let bewaaredArray = [];
                                                if (!e.voucherUnlimited) {
                                                    if (
                                                        num + bewaaredArray.length <
                                                        Number(e.number)
                                                    ) {
                                                        arr.push(e);
                                                    }
                                                } else {
                                                    arr.push(e);
                                                }
                                            }
                                            if (e.campaignType === "kortings_voucher") {
                                                let objectedVerzilveredBy = [];
                                                let num = 0;
                                                if (e.verzilvered_by) {
                                                    objectedVerzilveredBy = Object.entries(
                                                        e.verzilvered_by
                                                    );
                                                    num = this.getVerzilveredQty(objectedVerzilveredBy);
                                                }
                                                let bewaaredArray = [];
                                                if (!e.kortingsVoucherUnlimited) {
                                                    if (num + bewaaredArray.length < Number(e.number)) {
                                                        arr.push(e);
                                                    }
                                                } else {
                                                    arr.push(e);
                                                }
                                            }
                                        }
                                    }
                                });
                            }
                        }
                    }
                    if (e.created_by === uid) {
                        arrCurrentCampaigns.push(e);
                    }
                });
                this.setState({ lengthOfOtherCampaigns: arr });
            }
        }

        firebase
            .database()
            .ref("users/" + userid)
            .on("value", (data) => {
                let a = data.val();
                if (a) {
                    if (a.friends) {
                        let objected = Object.entries(a.friends);
                        a.objectedFriends = objected;
                    } else {
                        a.objectedFriends = [];
                    }
                    if (!a.profile_image) {
                        a.profile_image =
                            "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9";
                    }

                    // localStorage.setItem("userdata", JSON.stringify(data.val()))
                    this.setState({
                        currentUserMember: a,
                        create_subscription: a.create_subscription,
                        userid: userid,
                        url: a.user_name_id,
                        firstName: a.first_name,
                        lastName: a.last_name,
                        city: a.city,
                        zipCode: a.zipCode,
                        street: a.street,
                        houseNumber: a.houseNumber,
                        country: a.country,
                        phoneNumber: a.phoneNumber,
                        email: a.email,
                        profileImageUrl: a.profile_image,
                        description: a.user_description,
                    });
                }
            });

    }

    componentDidMount() {
        this.updateTitle();
        const loaderId = document.getElementById("main-page-loader-id");
        loaderId.style.display = "none";

        let onStoryActive = localStorage.getItem("onStoriesActive");
        if (onStoryActive) {
            if (!window.location.href.includes("/charity") && !window.location.href.includes("/volgend")) {
                this.setState({
                    trendingActive: false,
                });
            }
        }

    }

    getCharityObjectedQty(e) {
        let arrItems = e;
        if (arrItems.length > 0) {
            Array.prototype.sum = function (prop) {
                var total = 0;
                for (var i = 0, _len = this.length; i < _len; i++) {
                    total += Number(this[i][1][prop]);
                }
                return total;
            };
            return arrItems.sum("used_time");
        }
    }

    getVerzilveredQty(e) {
        let arrItems = e;
        if (arrItems.length > 0) {
            Array.prototype.sum = function (prop) {
                var total = 0;
                for (var i = 0, _len = this.length; i < _len; i++) {
                    total += Number(this[i][1][prop].length);
                }
                return total;
            };
            return arrItems.sum("allDates");
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.title) {
            this.setState({ updated: false });
            this.updateTitle();
            this.props.updateTitle(false);
            setTimeout(() => {
                this.setState({ updated: true });
            }, 250);
        }


        if (prevProps.message !== this.props.message) {
            if (this.props.message) {
                let allMessages = [];
                this.props.message.map((g) => {
                    let objected = Object.entries(g[1]);
                    objected.map((y) => {
                        allMessages.push(y[1]);
                    });
                });
                let filtered = allMessages.filter((g) => {
                    return !g.seen;
                });
                this.setState({ notSeenMessages: filtered });
            } else {
                this.setState({ notSeenMessages: [] });
            }
        }

        if (this.props.global_campaigns !== prevProps.global_campaigns) {
            if (!window.location.href.includes("volgend")) {
                let uid = localStorage.getItem("userid");
                let bhodi_campaigns = this.props.global_campaigns;
                if (bhodi_campaigns) {
                    if (bhodi_campaigns.length > 0) {
                        let arr = [];
                        let allCampaignsCompleted = [];
                        let allCampaignsCompletedToRender = [];
                        let arrCurrentCampaigns = [];
                        let objected = bhodi_campaigns;
                        objected.map((e) => {
                            e[1].type = "campaign";
                            if (e[1].campaignType === "charity") {
                                let objectedCheckedIn = [];
                                let inCartOf = 0;
                                if (e[1].checked_in_by) {
                                    objectedCheckedIn = Object.entries(e[1].checked_in_by);
                                }
                                if (e[1].inCartOf) {
                                    inCartOf = e[1].inCartOf;
                                }
                                if (objectedCheckedIn.length + inCartOf < e[1].charity_qty) {
                                    this.setState({ charityWorkReached: false });
                                } else {
                                    this.setState({ charityWorkReached: true });
                                }
                                if (!e[1].algemene_title) {
                                    let arrDishes = [];
                                    if (e[1].dishes) {
                                        let objected = Object.entries(e[1].dishes);
                                        objected.map((h) => {
                                            arrDishes.push(h[1]);
                                        });
                                        e[1].dishesObjected = arrDishes;
                                        e[1].dishesObjected.sort((a, b) => {
                                            return a.sortNumber - b.sortNumber;
                                        });
                                        e[1].imageToRender = e[1].dishesObjected[0].image;
                                        let filteredArr = e[1].dishesObjected.filter(
                                            (v, i, a) => a.findIndex((t) => t.image === v.image) === i
                                        );
                                        if (filteredArr.length === 1) {
                                            this.setState({ selectedDish: null });
                                        }
                                    } else {
                                        e[1].dishesObjected = [];
                                        e[1].imageToRender = e[1].image;
                                    }
                                }
                            }
                            if (e[1].active) {
                                allCampaignsCompletedToRender.push(e[1]);
                                if (e[1].desc) {
                                    e[1].desc = e[1].desc.substr(0, 200);
                                }
                                allCampaignsCompleted.push(e[1]);
                            }
                        });
                        allCampaignsCompleted.map((e) => {
                            e.type = "campaign";
                            if (e.created_by !== uid) {
                                let filtered = this.props.user.filter((f) => {
                                    return f.user_id === e.created_by;
                                });
                                if (filtered.length > 0) {
                                    let f = filtered[0];
                                    if (f.followed_by || f.friends) {
                                        let objectedFriends = [];
                                        if (f.followed_by) {
                                            objectedFriends = Object.entries(f.followed_by);
                                        }
                                        objectedFriends.map((g) => {
                                            if (g[1].user_id === uid) {
                                                if (e.active) {
                                                    if (e.desc) {
                                                        e.desc = e.desc.substr(0, 520);
                                                        e.short_desc = e.desc.substr(0, 120);
                                                    }
                                                    e.images = [e.image];
                                                    e.imagesObjected = [
                                                        { image: e.image, pushKey: e.pushKey },
                                                    ];

                                                    if (e.campaignType === "local_event") {
                                                        if (e.eventForGuestUser) {
                                                            return false;
                                                        }
                                                        if (e.takeFromAdmin) {
                                                            let obj = mergeAdminEvent(e, this.props.global_campaigns)
                                                            e = Object.assign({}, obj)
                                                        }
                                                        let arrTimingsAvailable = [];
                                                        if (e.timings) {
                                                            e.timings.map((y) => {
                                                                if (y.isoStartTime && y.isoEndTime) {
                                                                    let checkedInOfCurrentBlock = [];
                                                                    if (e.checked_in_by) {
                                                                        Object.entries(e.checked_in_by).map((t) => {
                                                                            if (t[1].event_id === y.eventId) {
                                                                                checkedInOfCurrentBlock.push(t[1]);
                                                                            }
                                                                        });
                                                                    }
                                                                    let getCurrentDate = new Date();
                                                                    if (
                                                                        getCurrentDate.getTime() <
                                                                        new Date(Date.parse(y.isoEndTime)).getTime()
                                                                    ) {
                                                                        if (
                                                                            checkedInOfCurrentBlock.length <
                                                                            Number(y.personsAmountEvent)
                                                                        ) {
                                                                            arrTimingsAvailable.push(y);
                                                                        }
                                                                    }
                                                                }
                                                            });
                                                        }
                                                        if (arrTimingsAvailable.length > 0) {
                                                            arr.push(e);
                                                        }
                                                    }
                                                    if (e.campaignType === "web_shop") {
                                                        let objectedVerzilveredBy = [];
                                                        let num = 0;
                                                        if (e.verzilvered_by) {
                                                            objectedVerzilveredBy = Object.entries(
                                                                e.verzilvered_by
                                                            );
                                                            num = this.getVerzilveredQty(
                                                                objectedVerzilveredBy
                                                            );
                                                        }
                                                        let bewaaredArray = [];
                                                        if (!e.voucherUnlimited) {
                                                            if (
                                                                num + bewaaredArray.length <
                                                                Number(e.number)
                                                            ) {
                                                                arr.push(e);
                                                            }
                                                        } else {
                                                            arr.push(e);
                                                        }
                                                    }
                                                    if (e.campaignType === "kortings_voucher") {
                                                        let objectedVerzilveredBy = [];
                                                        let num = 0;
                                                        if (e.verzilvered_by) {
                                                            objectedVerzilveredBy = Object.entries(
                                                                e.verzilvered_by
                                                            );
                                                            num = this.getVerzilveredQty(objectedVerzilveredBy);
                                                        }
                                                        let bewaaredArray = [];
                                                        if (!e.kortingsVoucherUnlimited) {
                                                            if (num + bewaaredArray.length < Number(e.number)) {
                                                                arr.push(e);
                                                            }
                                                        } else {
                                                            arr.push(e);
                                                        }
                                                    }
                                                }
                                            }
                                        });
                                    }
                                }
                            }
                            if (e.created_by === uid) {
                                arrCurrentCampaigns.push(e);
                            }
                        });
                        this.setState({ lengthOfOtherCampaigns: arr });
                    }
                }
            }
        }
    }

    getCountComing(e) {
        let objected = [];
        let newFollowers = 0;
        if (this.state.currentUserMember.newFollowersList) {
            let objectedInner = Object.entries(
                this.state.currentUserMember.newFollowersList
            );
            newFollowers = objectedInner.length;
        }

        let allCounted = objected.length + newFollowers;

        if (allCounted > 0) {
            return <div className="vrienden-round-mobile">
                <BiBell />
            </div>
        }
    }

    render() {
        return (
            this.state.updated && (
                <div>
                    <div className="new-round-icons-member">
                        <ul>
                            {/* Mobile Volgend */}
                            {this.state.currentUserMember.user_id === this.state.currentUserId && <NavbarMenuMobileFooterStuff fromRoundIcon={true} />}
                            {this.state.lengthOfOtherCampaigns.length > 0 &&
                                this.state.currentUserMember.user_id ===
                                this.state.currentUserId && (
                                    <li className={this.state.onVolgendPage && "active"}>
                                        <Link
                                            className="link-style"
                                            to={this.props.linkToVolgend}
                                            onClick={() => {
                                                this.props.showProducts();
                                                this.setState({ rightModalSettings: false });
                                            }}
                                        >
                                            {/* <div className="captionvoucherstuff charityimageonvoucher">
                                                <AiOutlineTag />
                                            </div> */}
                                            {this.state.onVolgendPage && (<div className="round-active-newmembers"></div>)}
                                            <label>Volgend</label>
                                        </Link>
                                    </li>
                                )}
                            <li style={{ cursor: "pointer" }} className={this.state.onCharityPage && "active"}>
                                <Link
                                    className="link-style"
                                    to={this.props.linkToCharity}
                                    onClick={() => {
                                        this.props.showCharity();
                                        this.setState({ rightModalSettings: false });
                                    }}
                                >
                                    {this.state.charityCount > 0 && (<div className="imginnermcaadd">
                                        <font>{this.state.charityCount}</font>
                                    </div>)}
                                    {this.state.onCharityPage && (<div className="round-active-newmembers"></div>)}
                                    <label style={{ cursor: "pointer" }}>Charity</label>
                                </Link>
                            </li>
                            {(
                                <li
                                    className={this.state.onFriendsPage && "active"}
                                    onClick={() => {
                                        this.props.showFriendsNewUpdated();
                                    }}
                                >
                                    {this.getCountComing()}
                                    {this.state.onFriendsPage && (<div className="round-active-newmembers"></div>)}
                                    <label className="vrienden-mobile">Vrienden</label>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            )
        );
    }
}

const mapStateToProps = (state) => {
    return {
        title: state.renderReducer.title,
        user: state.authReducers.user,
        album: state.postsReducers.album,
        images: state.postsReducers.images,
        product: state.campaignsReducer.product,
        global_campaigns: state.campaignsReducer.global_campaigns,
        message: state.authReducers.message,
        bhodi_products: state.productsReducers.bhodi_products,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateTitle: (title) => dispatch(updateTitle(title)),
        updateBhodiProducts: (bhodi_products) => dispatch(updateBhodiProducts(bhodi_products)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RoundIconsNavbarMobile);
