import React, { Component } from "react";

export class TimerOnboardingBarberClient extends Component {
  componentDidMount() {
    this.interval = setInterval(
      () => this.setState({ timeCurrent: Date.now() }),
      1000
    );
  }

  calculateTimeStamp() {
    var date = new Date(this.props.timeToCalculate);

    let dateFuture = date.getTime();

    let dateNow = new Date().getTime();

    let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

    // let days = Math.floor(diffInMilliSeconds / 86400);
    // diffInMilliSeconds -= days * 86400;

    let hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    diffInMilliSeconds -= hours * 3600;

    let minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;

    let seconds = diffInMilliSeconds % 60;


    if (hours < 10) {
      hours = `0${hours}`;
    } else {
      hours = `${hours}`;
    }

    if (minutes < 10) {
      minutes = `0${minutes}`;
    } else {
      minutes = `${minutes}`;
    }

    if (seconds < 9) {
      seconds = `0${Math.ceil(seconds)}`;
    } else {
      seconds = `${Math.ceil(seconds)}`;
    }

    if (dateFuture > dateNow) {
      return (
        <div className='timer-times-qr'>
          <div className='inner-ttq'>
            <p>{hours}</p>
            <p>Hours</p>
          </div>
          <div className='inner-ttq'>
            <p>{minutes}</p>
            <p>Minuts</p>
          </div>
          <div className='inner-ttq'>
            <p>{seconds}</p>
            <p>Seconds</p>
          </div>
        </div>
      );
    } else {
      clearInterval(this.interval);
      this.props.changeScreen()
      return <></>;
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  render() {
    return this.calculateTimeStamp();
  }
}

export default TimerOnboardingBarberClient;
