import React, { Component } from "react";
// import './index.css'
import CloseIcon from "@material-ui/icons/Close";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { connect } from "react-redux";
import { HiOutlineShoppingBag } from "react-icons/hi";
import { RiAccountBoxLine } from "react-icons/ri";
import {
  voucherSectorCategories,
  updateGuestUserCart,
  updateGuestUserCartExtendOpportunity,
  updateGuestUserCartTime,
} from "../Redux/actions/authActions";
import "./index.css";
import firebase from "../Config/Firebase";
import FooterBasketTimer from "./FooterBasketTimer";
import NumberFormatter from "./NumberFormatter";
import { history } from "../Config/Routes";
import { MdLocalShipping } from "react-icons/md";
import { mergeAdminEvent } from "../Functions/globalFunctions";

export class FooterBasket extends Component {
  constructor() {
    super();
    this.state = {
      black: false,
      popupright: false,
      popupitems: false,
      popupmarket: false,
      popupservices: false,
      popupcompanies: false,
      lists: [1, 2, 3, 4, 5],
      cartItems: [],
      arrItemsOfCart: [],
      currentUserHere: "",
      allSellers: [],
      currentUserId: "",
      allSectors: [],
      availableMainSector: [],
      availableMainSectorVouchers: [],
      allSectors: [],
      availableMainSectorShop: [],
      allSectorsVouchers: [],
      filteredAllSectors: [],
      filteredAllSectorsFilter: [],
      allEvents: [],
      availableMainSectorEvents: [],
      filteredAllSectorsFilterEvents: [],
      allVouchers: [],
      endDate: "",
      allCampaigns: [],
      popupExtend: false,
      extendOpportunity: false,
      alreadyBewared: [],
      alreadyBewaredCampaigns: [],
      currentUserExclusives: [],
      friendsExclusives: [],
      availableAlbumCategories: [],
      categoriesToShow: [],
      filteredAllCategoriesAlbums: [],
      noFilterAlbumCategories: false,
      allCampaignsToFilter: [],
      allBewaaredThings: [],
      availableImageCategories: [],
      availableMainSectorVoucherEvents: [],
      guestUserCart: [],
      stopCartQty: false,
      completeCartArray: [],
      pointOutToTest: false,
      popupright: false,
      allProducts: [],
      showCartPageRoute: false,
    };
  }

  componentDidMount() {
    const loaderId = document.getElementById("main-page-loader-id");
    loaderId.style.display = "none";
    if (window.location.href.includes("cart-page-guest")) {
      this.setState({ showCartPageRoute: true, popupright: true });
    }

    let userid = localStorage.getItem("userid");
    this.setState({ currentUserId: userid });

    let cookieItem = localStorage.getItem("cookie_item");
    if (userid && !cookieItem) {
      cookieItem = userid;
    }

    firebase
      .database()
      .ref("bhodi_categories_vouchers")
      .on("value", (data) => {
        let a = data.val();
        this.props.voucherSectorCategories(a);
        this.setState({ allCategories: a });
      });

    if (cookieItem) {
      // firebase
      //   .database()
      //   .ref("guest_carts/" + cookieItem)
      //   .on("value", (data) => {
      //     let a = data.val();
      //     if (a) {
      //       let arr = [];
      //       let arrKeys = [];
      //       let arrComplete = [];
      //       let objected = Object.entries(a);
      //       let arrCompleteProducts = [];
      //       let arrCompleteBhodiProducts = [];

      //       objected.map((y) => {
      //         arr.push(y[1]);
      //         arrKeys.push(y[1].itemPush);
      //       });

      //       this.setState({ completeCartArray: arr });

      //       if (this.props.global_campaigns) {
      //         if (this.props.global_campaigns.length > 0) {
      //           // if (window.innerWidth > 1009) {
      //           //   this.interval = setInterval(
      //           //     () => this.setState({ timeCurrent: Date.now() }),
      //           //     1000
      //           //   );
      //           // }
      //           let arrSecCheck = [];

      //           this.props.global_campaigns.map((i) => {
      //             arrSecCheck.push(i[1]);
      //           });
      //           arrSecCheck.map((g) => {
      //             if (g.active) {
      //               g.type = "campaign";
      //               g.currentImageIndex = 0;
      //               if (g.desc) {
      //                 g.desc = g.desc.substr(0, 200);
      //                 g.short_desc = g.desc.substr(0, 120);
      //               }
      //               g.images = [g.image];
      //               g.imagesObjected = [
      //                 { image: g.image, pushKey: g.pushKey },
      //               ];
      //               arrComplete.push(g);
      //             }
      //           });

      //           this.setState({ allCampaigns: arrComplete });
      //         }
      //       }
      //       if (this.props.products_new) {
      //         if (this.props.products_new.length > 0) {
      //           this.props.products_new.map((g) => {
      //             if (g.active) {
      //               g.type = "product";
      //               g.currentImageIndex = 0;
      //               if (g.desc) {
      //                 g.desc = g.desc.substr(0, 200);
      //                 g.short_desc = g.desc.substr(0, 120);
      //               }
      //               arrCompleteProducts.push(g);
      //             }
      //           });

      //           this.setState({ allProducts: arrCompleteProducts });
      //         }
      //       }

      //       if (this.props.bhodi_products) {
      //         if (this.props.bhodi_products.length > 0) {
      //           this.props.bhodi_products.map((g) => {
      //             if (g.active) {
      //               g.type = "bhodi_product";
      //               g.currentImageIndex = 0;
      //               if (g.desc) {
      //                 g.desc = g.desc.substr(0, 200);
      //                 g.short_desc = g.desc.substr(0, 120);
      //               }
      //               arrCompleteBhodiProducts.push(g);
      //             }
      //           });

      //           this.setState({ allBhodiProducts: arrCompleteBhodiProducts });
      //         }
      //       }

      //       let everyCartItems = [];
      //       arrComplete.map((y) => {
      //         objected.map((t) => {
      //           if (t[1].item_type === "bhodi_product") {
      //             return false;
      //           }
      //           if (t[1].type === "product") {
      //             return false;
      //           }
      //           if (y.pushKey === t[1].itemPush) {
      //             if (y.active) {
      //               if (y.campaignType === "local_event") {
      //                 console.log(t[1].event_id, '/see here')
      //                 let obj = {
      //                   ...y,
      //                   reserved_event_id: t[1].event_id
      //                 }
      //                 everyCartItems.push(obj);
      //                 // everyCartItems.push(y);
      //               } else {
      //                 everyCartItems.push(y);
      //               }
      //             }
      //           }
      //         });
      //       });
      //       arrCompleteProducts.map((y) => {
      //         objected.map((t) => {
      //           if (t[1].type === "product") {
      //             if (t[1].itemPush === y.pushKey) {
      //               t[1].mainProduct = y;
      //               everyCartItems.push(t[1]);
      //             }
      //           }
      //         });
      //       });

      //       arrCompleteBhodiProducts.map((y) => {
      //         objected.map((t) => {
      //           if (t[1].item_type === "bhodi_product") {
      //             if (t[1].itemPush === y.pushKey) {
      //               // t[1].mainProduct = y;
      //               let currentObj = Object.assign({}, y)
      //               currentObj.item_type = "bhodi_product"
      //               if (t[1].selectedSize) {
      //                 currentObj.selectedSizeOfOrder = t[1].selectedSize
      //               }
      //               everyCartItems.push(currentObj);
      //             }
      //           }
      //         });
      //       });

      //       let toDoFilterCompanies = [];
      //       let toDoFilter = [];
      //       everyCartItems.map((b) => {
      //         if (b.isNotActive) {
      //           b.hideFromListComplete = true;
      //         } else {
      //           b.hideFromListComplete = false;
      //           toDoFilterCompanies.push(b);
      //         }
      //         // toDoFilter.push(b)
      //         if (b.item_type === "bhodi_product") {
      //           toDoFilter.push(b);
      //         } else if (b.type === "product") {
      //           toDoFilter.push(b);
      //         } else if (b.campaignType === "live_event") {
      //           let getStartDateEvent = new Date(b.startDate);
      //           let startTimeEventGood = b.startTime.concat(":00");
      //           getStartDateEvent.setHours(startTimeEventGood.split(":")[0]);
      //           getStartDateEvent.setMinutes(startTimeEventGood.split(":")[1]);
      //           if (getStartDateEvent.getTime() > new Date().getTime()) {
      //             toDoFilter.push(b);
      //           }
      //         } else {
      //           toDoFilter.push(b);
      //         }
      //       });
      //       let filteredSecMa = toDoFilterCompanies.filter(
      //         (v, i, a) =>
      //           a.findIndex((t) => t.created_by === v.created_by) === i
      //       );
      //       let arrMainSellers = [];
      //       filteredSecMa.map((y) => {
      //         if (y.mainParentCreator) {
      //           arrMainSellers.push(y.mainParentCreator);
      //         } else {
      //           arrMainSellers.push(y.created_by);
      //         }
      //       });

      //       this.props.updateGuestUserCart(everyCartItems);
      //       let arrMainSellersFiltered = arrMainSellers.filter(
      //         (v, i, a) => a.findIndex((t) => t === v) === i
      //       );
      //       this.setState({
      //         guestUserCart: everyCartItems,
      //         allSellers: arrMainSellersFiltered,
      //       });
      //       // if (window.innerWidth > 1009) {
      //       //   this.interval = setInterval(
      //       //     () => this.setState({ timeCurrent: Date.now() }),
      //       //     1000
      //       //   );
      //       // }

      //       if (this.props.guest_user_cart_time) {
      //         let currentDate = Math.ceil(Date.now() / 1000);
      //         if (
      //           Number(currentDate) < Number(this.props.guest_user_cart_time)
      //         ) {
      //           this.setState({
      //             endDate: this.props.guest_user_cart_time,
      //             extendOpportunity:
      //               this.props.guest_user_cart_extend_opportunity,
      //           });
      //         }
      //         // else {
      //         //   console.log("/working this to remove see")
      //         //   this.props.updateGuestUserCartTime("");
      //         //   firebase
      //         //     .database()
      //         //     .ref("guest_carts/" + cookieItem)
      //         //     .remove();
      //         //   this.props.updateGuestUserCartExtendOpportunity("");
      //         //   this.props.updateGuestUserCart([]);
      //         //   clearInterval(this.interval);
      //         // }
      //       }
      //     } else {
      //       console.log("working this to clear")
      //       this.setState({
      //         guestUserCart: [],
      //         allSellers: [],
      //         endDate: "",
      //         pointOutToTest: false,
      //         extendOpportunity: false,
      //       });
      //       this.props.updateGuestUserCart([]);
      //       this.props.updateGuestUserCartTime("");
      //       firebase
      //         .database()
      //         .ref("guest_carts/" + cookieItem)
      //         .remove();
      //       this.props.updateGuestUserCartExtendOpportunity("");
      //       clearInterval(this.interval);
      //     }
      //   });
      this.processCartData(cookieItem)
    } else {
      this.intervalCookie = setInterval(() => {
        let cookieItemCheck = localStorage.getItem("cookie_item");
        if (userid && !cookieItemCheck) {
          cookieItemCheck = userid;
        }

        if (cookieItemCheck) {
          clearInterval(this.intervalCookie)
          this.processCartData(cookieItemCheck)
        }
      }, 100)
    }
    // else {
    //   firebase
    //     .database()
    //     .ref("guest_carts")
    //     .on("value", (data) => {
    //       let cookieItem = localStorage.getItem("cookie_item");
    //       if (userid && !cookieItem) {
    //         cookieItem = userid;
    //       }
    //       let as = data.val();
    //       if (as) {
    //         let objectedMain = Object.entries(data.val());
    //         let filtered = objectedMain.filter((u) => {
    //           return u[0] === cookieItem;
    //         });
    //         if (filtered.length > 0) {
    //           let a = filtered[0][1];
    //           let arr = [];
    //           let arrKeys = [];
    //           let arrComplete = [];
    //           let arrCompleteBhodiProducts = [];

    //           let objected = Object.entries(a);
    //           objected.map((y) => {
    //             arr.push(y[1]);
    //             arrKeys.push(y[1].itemPush);
    //           });

    //           this.setState({ completeCartArray: arr });

    //           if (this.props.global_campaigns) {
    //             if (this.props.global_campaigns.length > 0) {
    //               // if (window.innerWidth > 1009) {
    //               //   this.interval = setInterval(
    //               //     () => this.setState({ timeCurrent: Date.now() }),
    //               //     1000
    //               //   );
    //               // }
    //               let arrSecCheck = [];

    //               this.props.global_campaigns.map((i) => {
    //                 arrSecCheck.push(i[1]);
    //               });
    //               arrSecCheck.map((g) => {
    //                 if (g.active) {
    //                   g.type = "campaign";
    //                   g.currentImageIndex = 0;

    //                   if (g.desc) {
    //                     g.desc = g.desc.substr(0, 200);
    //                     g.short_desc = g.desc.substr(0, 120);
    //                   }
    //                   if (g.campaignType !== "charity") {
    //                     g.images = [g.image];
    //                     g.imagesObjected = [
    //                       { image: g.image, pushKey: g.pushKey },
    //                     ];
    //                   } else {
    //                     if (g.dishes) {
    //                       g.images = [g.imageToRender];
    //                       g.imagesObjected = [
    //                         { image: g.imageToRender, pushKey: g.pushKey },
    //                       ];
    //                       g.title = g.main_title;
    //                       g.desc = g.dishesObjected[0].description;
    //                       g.charity_price = g.main_price;
    //                       g.normal_price = g.main_price;
    //                       g.amount = g.main_price;
    //                     }
    //                   }
    //                   arrComplete.push(g);
    //                 }
    //               });

    //               this.setState({ allCampaigns: arrComplete });
    //             }
    //           }
    //           if (this.props.bhodi_products) {
    //             if (this.props.bhodi_products.length > 0) {
    //               this.props.bhodi_products.map((g) => {
    //                 if (g.active) {
    //                   g.type = "bhodi_product";
    //                   g.currentImageIndex = 0;
    //                   if (g.desc) {
    //                     g.desc = g.desc.substr(0, 200);
    //                     g.short_desc = g.desc.substr(0, 120);
    //                   }
    //                   arrCompleteBhodiProducts.push(g);
    //                 }
    //               });

    //               this.setState({ allBhodiProducts: arrCompleteBhodiProducts });
    //             }
    //           }

    //           let everyCartItems = [];
    //           arrComplete.map((y) => {
    //             objected.map((t) => {
    //               if (t[1].item_type === "bhodi_product") {
    //                 return false;
    //               }
    //               if (t[1].type === "product") {
    //                 everyCartItems.push(y);
    //               }
    //               if (y.pushKey === t[1].itemPush) {
    //                 if (y.campaignType === "local_event") {
    //                   console.log(t[1].event_id, '/see here')
    //                   let obj = {
    //                     ...y,
    //                     reserved_event_id: t[1].event_id
    //                   }
    //                   everyCartItems.push(obj);
    //                   // everyCartItems.push(y);
    //                 } else {
    //                   everyCartItems.push(y);
    //                 }
    //               }
    //             });
    //           });
    //           arrCompleteBhodiProducts.map((y) => {
    //             objected.map((t) => {
    //               if (t[1].item_type === "bhodi_product") {
    //                 if (t[1].itemPush === y.pushKey) {
    //                   // t[1].mainProduct = y;
    //                   let currentObj = Object.assign({}, y)
    //                   currentObj.item_type = "bhodi_product"
    //                   if (t[1].selectedSize) {
    //                     currentObj.selectedSizeOfOrder = t[1].selectedSize
    //                   }
    //                   everyCartItems.push(currentObj);
    //                 }
    //               }
    //             });
    //           });


    //           let toDoFilterCompanies = [];
    //           let toDoFilter = [];
    //           everyCartItems.map((b) => {
    //             if (b.isNotActive) {
    //               b.hideFromListComplete = true;
    //             } else {
    //               b.hideFromListComplete = false;
    //               toDoFilterCompanies.push(b);
    //             }
    //             if (b.item_type === "bhodi_product") {
    //               toDoFilter.push(b);
    //             } else if (b.type === "product") {
    //               toDoFilter.push(b);
    //             } else if (b.campaignType === "live_event") {
    //               let getStartDateEvent = new Date(b.startDate);
    //               let startTimeEventGood = b.startTime.concat(":00");
    //               getStartDateEvent.setHours(startTimeEventGood.split(":")[0]);
    //               getStartDateEvent.setMinutes(
    //                 startTimeEventGood.split(":")[1]
    //               );
    //               if (getStartDateEvent.getTime() > new Date().getTime()) {
    //                 toDoFilter.push(b);
    //               }
    //             } else {
    //               toDoFilter.push(b);
    //             }
    //           });
    //           let filteredSecMa = toDoFilterCompanies.filter(
    //             (v, i, a) =>
    //               a.findIndex((t) => t.created_by === v.created_by) === i
    //           );
    //           let arrMainSellers = [];
    //           filteredSecMa.map((y) => {
    //             if (y.mainParentCreator) {
    //               arrMainSellers.push(y.mainParentCreator);
    //             } else {
    //               arrMainSellers.push(y.created_by);
    //             }
    //           });
    //           let arrMainSellersFiltered = arrMainSellers.filter(
    //             (v, i, a) => a.findIndex((t) => t === v) === i
    //           );
    //           console.log(everyCartItems, '/see here')
    //           this.setState({
    //             guestUserCart: everyCartItems,
    //             allSellers: arrMainSellersFiltered,
    //           });
    //           // this.interval = setInterval(() => this.setState({ timeCurrent: Date.now() }), 1000);

    //           if (this.props.guest_user_cart_time) {
    //             let currentDate = Math.ceil(Date.now() / 1000);
    //             if (
    //               Number(currentDate) < Number(this.props.guest_user_cart_time)
    //             ) {
    //               this.props.updateGuestUserCart(everyCartItems);
    //               this.setState({
    //                 endDate: this.props.guest_user_cart_time,
    //                 extendOpportunity:
    //                   this.props.guest_user_cart_extend_opportunity,
    //               });
    //             }
    //             //  else {
    //             //   console.log("cart empty")

    //             //   this.setState({
    //             //     guestUserCart: [],
    //             //     allSellers: [],
    //             //     endDate: "",
    //             //   });
    //             //   this.props.updateGuestUserCart([]);
    //             //   firebase
    //             //     .database()
    //             //     .ref("guest_carts/" + cookieItem)
    //             //     .remove();
    //             //   this.props.updateGuestUserCartTime("");
    //             //   this.props.updateGuestUserCartExtendOpportunity("");
    //             //   clearInterval(this.interval);
    //             // }
    //           }
    //         } else {
    //           console.log("cart empty")
    //           console.log("working this to clear")

    //           this.setState({
    //             guestUserCart: [],
    //             allSellers: [],
    //             endDate: "",
    //             pointOutToTest: false,
    //             extendOpportunity: false,
    //           });
    //           this.props.updateGuestUserCart([]);
    //           firebase
    //             .database()
    //             .ref("guest_carts/" + cookieItem)
    //             .remove();
    //           this.props.updateGuestUserCartExtendOpportunity("");
    //           this.props.updateGuestUserCartTime("");
    //           clearInterval(this.interval);
    //         }
    //       } else {
    //         console.log("cart empty")
    //         console.log("working this to clear")

    //         this.setState({
    //           guestUserCart: [],
    //           allSellers: [],
    //           endDate: "",
    //           pointOutToTest: false,
    //           extendOpportunity: false,
    //         });
    //         this.props.updateGuestUserCart([]);
    //         firebase
    //           .database()
    //           .ref("guest_carts/" + cookieItem)
    //           .remove();
    //         this.props.updateGuestUserCartTime("");
    //         this.props.updateGuestUserCartExtendOpportunity("");
    //         clearInterval(this.interval);
    //       }
    //     });
    // }

    // let makeCart = this.props.guest_user_cart
    // if (makeCart) {
    //     if (makeCart.length > 0) {
    //         let toDoFilterCompanies = []
    //         let toDoFilter = []
    //         makeCart.map((b) => {
    //             if (b.isNotActive) {
    //                 b.hideFromListComplete = true
    //             } else {
    //                 b.hideFromListComplete = false
    //                 toDoFilterCompanies.push(b)
    //             }
    //             toDoFilter.push(b)
    //         })
    //         let filteredSecMa = toDoFilterCompanies.filter((v, i, a) => a.findIndex(t => (t.created_by === v.created_by)) === i)
    //         let arrMainSellers = []
    //         filteredSecMa.map((y) => {
    //             arrMainSellers.push(y.created_by)
    //         })
    //         this.setState({ guestUserCart: makeCart, allSellers: arrMainSellers })
    //         this.interval = setInterval(() => this.setState({ timeCurrent: Date.now() }), 1000);

    //         if (this.props.guest_user_cart_time) {
    //             let currentDate = Math.ceil(Date.now() / 1000)
    //             if (Number(currentDate) < Number(this.props.guest_user_cart_time)) {
    //                 this.setState({ endDate: this.props.guest_user_cart_time, extendOpportunity: this.props.guest_user_cart_extend_opportunity })
    //             } else {
    //                 let cartDup = [...makeCart]
    //                 console.log(cartDup, '/cart dup')
    //                 this.props.updateGuestUserCartTime("")
    //                 this.props.updateGuestUserCart([])
    //                 this.props.updateGuestUserCartExtendOpportunity("")
    //                 clearInterval(this.interval)
    //             }
    //         }
    //     }
    // }
  }

  processCartData(cookieItem) {
    firebase
      .database()
      .ref("guest_carts/" + cookieItem)
      .on("value", (data) => {
        let a = data.val();
        if (a) {
          let arr = [];
          let arrKeys = [];
          let arrComplete = [];
          let objected = Object.entries(a);
          let arrCompleteProducts = [];
          let arrCompleteBhodiProducts = [];

          objected.map((y) => {
            arr.push(y[1]);
            arrKeys.push(y[1].itemPush);
          });

          this.setState({ completeCartArray: arr });

          if (this.props.global_campaigns) {
            if (this.props.global_campaigns.length > 0) {
              let arrSecCheck = [];

              this.props.global_campaigns.map((i) => {
                arrSecCheck.push(i[1]);
              });
              arrSecCheck.map((g) => {
                if (g.active) {
                  g.type = "campaign";
                  g.currentImageIndex = 0;
                  if (g.desc) {
                    g.desc = g.desc.substr(0, 200);
                    g.short_desc = g.desc.substr(0, 120);
                  }
                  g.images = [g.image];
                  g.imagesObjected = [
                    { image: g.image, pushKey: g.pushKey },
                  ];
                  arrComplete.push(g);
                }
              });

              this.setState({ allCampaigns: arrComplete });
            }
          }
          if (this.props.products_new) {
            if (this.props.products_new.length > 0) {
              this.props.products_new.map((g) => {
                if (g.active) {
                  g.type = "product";
                  g.currentImageIndex = 0;
                  if (g.desc) {
                    g.desc = g.desc.substr(0, 200);
                    g.short_desc = g.desc.substr(0, 120);
                  }
                  arrCompleteProducts.push(g);
                }
              });

              this.setState({ allProducts: arrCompleteProducts });
            }
          }

          if (this.props.bhodi_products) {
            if (this.props.bhodi_products.length > 0) {
              this.props.bhodi_products.map((g) => {
                if (g.active) {
                  g.type = "bhodi_product";
                  g.currentImageIndex = 0;
                  if (g.desc) {
                    g.desc = g.desc.substr(0, 200);
                    g.short_desc = g.desc.substr(0, 120);
                  }
                  arrCompleteBhodiProducts.push(g);
                }
              });

              this.setState({ allBhodiProducts: arrCompleteBhodiProducts });
            }
          }

          let everyCartItems = [];
          arrComplete.map((y) => {
            objected.map((t) => {
              if (t[1].item_type === "bhodi_product") {
                return false;
              }
              if (t[1].type === "product") {
                return false;
              }
              if (y.pushKey === t[1].itemPush) {
                if (y.active) {
                  if (y.campaignType === "local_event") {
                    console.log(t[1].event_id, '/see here')
                    let obj = {
                      ...y,
                      reserved_event_id: t[1].event_id
                    }
                    everyCartItems.push(obj);
                    // everyCartItems.push(y);
                  } else {
                    everyCartItems.push(y);
                  }
                }
              }
            });
          });
          arrCompleteProducts.map((y) => {
            objected.map((t) => {
              if (t[1].type === "product") {
                if (t[1].itemPush === y.pushKey) {
                  t[1].mainProduct = y;
                  everyCartItems.push(t[1]);
                }
              }
            });
          });

          arrCompleteBhodiProducts.map((y) => {
            objected.map((t) => {
              if (t[1].item_type === "bhodi_product") {
                if (t[1].itemPush === y.pushKey) {
                  // t[1].mainProduct = y;
                  let currentObj = Object.assign({}, y)
                  currentObj.item_type = "bhodi_product"
                  if (t[1].selectedSize) {
                    currentObj.selectedSizeOfOrder = t[1].selectedSize
                  }
                  everyCartItems.push(currentObj);
                }
              }
            });
          });

          let toDoFilterCompanies = [];
          let toDoFilter = [];
          everyCartItems.map((b) => {
            if (b.isNotActive) {
              b.hideFromListComplete = true;
            } else {
              b.hideFromListComplete = false;
              toDoFilterCompanies.push(b);
            }
            // toDoFilter.push(b)
            if (b.item_type === "bhodi_product") {
              toDoFilter.push(b);
            } else if (b.type === "product") {
              toDoFilter.push(b);
            } else if (b.campaignType === "live_event") {
              let getStartDateEvent = new Date(b.startDate);
              let startTimeEventGood = b.startTime.concat(":00");
              getStartDateEvent.setHours(startTimeEventGood.split(":")[0]);
              getStartDateEvent.setMinutes(startTimeEventGood.split(":")[1]);
              if (getStartDateEvent.getTime() > new Date().getTime()) {
                toDoFilter.push(b);
              }
            } else {
              toDoFilter.push(b);
            }
          });
          let filteredSecMa = toDoFilterCompanies.filter(
            (v, i, a) =>
              a.findIndex((t) => t.created_by === v.created_by) === i
          );
          let arrMainSellers = [];
          filteredSecMa.map((y) => {
            if (y.mainParentCreator) {
              arrMainSellers.push(y.mainParentCreator);
            } else {
              arrMainSellers.push(y.created_by);
            }
          });

          let sortedArray = [...arr].sort((x, y) => { return y.timestamp - x.timestamp })
          this.props.updateGuestUserCart(everyCartItems);
          this.props.updateGuestUserCartTime(sortedArray[0].timestamp);

          let arrMainSellersFiltered = arrMainSellers.filter(
            (v, i, a) => a.findIndex((t) => t === v) === i
          );
          this.setState({
            guestUserCart: everyCartItems,
            allSellers: arrMainSellersFiltered,
          });

          if (this.props.guest_user_cart_time) {
            let currentDate = Math.ceil(Date.now() / 1000);
            if (
              Number(currentDate) < Number(this.props.guest_user_cart_time)
            ) {
              this.setState({
                endDate: this.props.guest_user_cart_time,
                extendOpportunity:
                  this.props.guest_user_cart_extend_opportunity,
              });
            }
          }
        } else {
          this.setState({
            guestUserCart: [],
            allSellers: [],
            endDate: "",
            pointOutToTest: false,
            extendOpportunity: false,
          });
          this.props.updateGuestUserCart([]);
          this.props.updateGuestUserCartTime("");
          firebase
            .database()
            .ref("guest_carts/" + cookieItem)
            .remove();
          this.props.updateGuestUserCartExtendOpportunity("");
          clearInterval(this.interval);
        }
      });
  }

  getCartQtyEvents() {
    let maxDate = this.state.endDate;
    let currentDate = Math.ceil(Date.now() / 1000);
    if (this.state.endDate) {
      if (Number(currentDate) < Number(maxDate)) {
        return this.state.guestUserCart.length;
      } else {
        console.log("working this to clear")

        this.setState({
          endDate: "",
          stopCartQty: true,
          pointOutToTest: false,
        });
        this.props.updateGuestUserCart([]);
        let cookieItem = localStorage.getItem("cookie_item");
        let userid = localStorage.getItem("userid");
        if (userid && !cookieItem) {
          cookieItem = userid;
        }
        console.log("cart empty")
        firebase
          .database()
          .ref("guest_carts/" + cookieItem)
          .remove();
        this.props.updateGuestUserCartTime("");
        this.props.updateGuestUserCartExtendOpportunity("");
        clearInterval(this.interval);
        return this.state.guestUserCart.length;
      }
    } else {
      return this.state.guestUserCart.length;
    }
  }

  getSellerName(e) {
    if (e) {
      let filteredUser = this.props.user.filter((g) => {
        return g.user_id === e;
      });
      if (filteredUser.length > 0) {
        if (filteredUser[0].type === "company") {
          if (filteredUser[0].company_name) {
            return filteredUser[0].company_name;
          } else {
            return filteredUser[0].first_name + " " + filteredUser[0].last_name;
          }
        } else {
          return filteredUser[0].first_name + " " + filteredUser[0].last_name;
        }
      }
    }
  }

  getTheTimer() {
    let dateToEnd = new Date(this.state.endDate * 1000);
    const date1 = new Date();
    const date2 = new Date(dateToEnd);
    // const diffTime = Math.abs(date2 - date1);
    // const diffMinutes = Math.ceil(diffTime / (1000 * 60));
    // const diffSeconds = Math.ceil(diffTime / (1000 * 60 * 60));

    var delta = Math.abs(date2 - date1) / 1000;

    var hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    var minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    var seconds = delta % 60;

    if (minutes < 10) {
      minutes = `0${minutes}`;
    }

    if (Math.ceil(seconds) < 10) {
      seconds = `0${Math.round(seconds)}`;
    } else {
      seconds = Math.round(seconds);
    }

    return `${minutes}:${seconds}`;
  }

  checkForTimerHeading() {
    let filtered = this.state.guestUserCart.filter((b) => {
      return !b.isNotActive && !b.hideFromListComplete;
    });

    if (filtered.length > 0) {
      if (this.state.endDate) {
        return (
          <span style={{ fontWeight: 600, letterSpacing: 1.5 }}>
            Je reservering verloopt over:
          </span>
        );
      }
    }
  }

  checkForTimerButton() {
    let filtered = this.state.guestUserCart.filter((b) => {
      return !b.isNotActive && !b.hideFromListComplete;
    });
    if (filtered.length > 0) {
      if (this.state.endDate) {
        return <button className="timer-cart">{this.getTheTimer()}</button>;
      }
    }
  }

  getCharityObjectedQty(e) {
    let arrItems = e;
    if (arrItems.length > 0) {
      Array.prototype.sum = function (prop) {
        var total = 0;
        for (var i = 0, _len = this.length; i < _len; i++) {
          total += Number(this[i][1][prop]);
        }
        return total;
      };
      return arrItems.sum("used_time");
    }
  }

  getVerzilveredQty(e) {
    let arrItems = e;
    if (arrItems.length > 0) {
      Array.prototype.sum = function (prop) {
        var total = 0;
        for (var i = 0, _len = this.length; i < _len; i++) {
          total += Number(this[i][1][prop].length);
        }
        return total;
      };
      return arrItems.sum("allDates");
    }
  }

  checkForPopupExtend() {
    let filtered = this.state.guestUserCart.filter((b) => {
      return !b.isNotActive && !b.hideFromListComplete;
    });

    if (filtered.length > 0) {
      if (this.state.endDate) {
        const date1 = new Date();
        const date2 = new Date(this.state.endDate * 1000);

        var delta = Math.abs(date2 - date1) / 1000;

        var hours = Math.floor(delta / 3600) % 24;
        delta -= hours * 3600;

        var minutes = Math.floor(delta / 60) % 60;
        delta -= minutes * 60;

        if (minutes < 2) {
          if (this.state.extendOpportunity) {
            return (
              <>
                <div
                  className="bg-overlaymini"
                  style={{ backgroundColor: "rgba(0, 0, 0, .9)" }}
                  onClick={() => {
                    this.props.updateGuestUserCartExtendOpportunity(false);
                    this.setState({ extendOpportunity: false });
                  }}
                ></div>
                <div className="card-to-extend" style={{ position: "fixed" }}>
                  <CloseIcon
                    onClick={() => {
                      this.setState({ extendOpportunity: false });
                      this.props.updateGuestUserCartExtendOpportunity(false);
                    }}
                  />
                  <p className="main-title-cte">WINKELMAND</p>
                  {/* <p className="greetings-cte">Hi</p> */}
                  <p className="greetings-cte">
                    Je winkelmandje verloopt bijna.
                  </p>
                  <p className="timer-cte">{this.getTheTimer()}</p>
                  <p className="bottom-content-cte">Om ook andere members</p>
                  <p className="bottom-content-cte">
                    toegang te geven tot deze actie
                  </p>
                  <p className="bottom-content-cte">
                    kan je winkelmand niet nogmaals
                  </p>
                  <p className="bottom-content-cte">worden verlengd.</p>
                  {/* <p className="bottom-content-cte">Je kan nog 1x verlengen, zodat</p>
                                <p className="bottom-content-cte">we ander members ook de mogelijkheid</p>
                                <p className="bottom-content-cte">geven om gebruik te maken van deze actie</p> */}
                  <button
                    onClick={() => {
                      var minutesToAdd = Number(process.env.REACT_APP_CART_TIME);
                      var currentDate = new Date();
                      var futureDate = new Date(
                        currentDate.getTime() + minutesToAdd * 60000
                      );

                      let futureTimeStamp = Math.floor(
                        futureDate.getTime() / 1000
                      );

                      this.setState({ extendOpportunity: false });
                      this.props.updateGuestUserCartTime(futureTimeStamp);
                      this.props.updateGuestUserCartExtendOpportunity(false);



                      if (this.state.completeCartArray.length > 0) {
                        this.state.completeCartArray.map((t) => {
                          if (t.cookieItem && t.pushKey) {
                            firebase
                              .database()
                              .ref(
                                `guest_carts/${t.cookieItem}/${t.pushKey}/timestamp`
                              )
                              .set(futureTimeStamp);
                          }
                        });
                        // let onKey = this.state.completeCartArray[this.state.completeCartArray.length - 1]
                      }
                    }}
                  >
                    VERLENGEN
                  </button>
                </div>
              </>
            );
          }
        }
      }
    }
  }

  getCartQtyGuest() {
    let filtered = this.state.guestUserCart.filter((t) => {
      return !t.hideFromListComplete;
    });
    return filtered.length;
  }

  componentDidUpdate(prevProps) {
    if (this.props.guest_user_cart) {
      if (prevProps.guest_user_cart !== this.props.guest_user_cart) {
        if (this.props.guest_user_cart.length > 0 && this.props.global_campaigns) {
          let filteredSecMa = this.props.guest_user_cart.filter(
            (v, i, a) => a.findIndex((t) => t.created_by === v.created_by) === i
          );
          let arrMainSellers = [];
          filteredSecMa.map((y) => {
            if (y.mainParentCreator) {
              arrMainSellers.push(y.mainParentCreator);
            } else {
              arrMainSellers.push(y.created_by);
            }
          });
          let arrMainSellersFiltered = arrMainSellers.filter(
            (v, i, a) => a.findIndex((t) => t === v) === i
          );
          this.setState({ allSellers: arrMainSellersFiltered });

          this.setState({
            guestUserCart: this.props.guest_user_cart,
            stopCartQty: false,
          });
        }
      }
    }
    if (this.props.guest_user_cart_time) {
      if (this.props.guest_user_cart_time !== prevProps.guest_user_cart_time) {
        this.setState({ endDate: this.props.guest_user_cart_time });
      }
    }
    if (
      this.props.guest_user_cart_extend_opportunity !==
      prevProps.guest_user_cart_extend_opportunity
    ) {
      this.setState({
        extendOpportunity: this.props.guest_user_cart_extend_opportunity,
      });
    }
    if (this.props.global_campaigns !== prevProps.global_campaigns) {
      if (this.props.global_campaigns !== prevProps.global_campaigns) {
        setTimeout(() => {
          let allArrs = this.props.global_campaigns;
          let availableKeysYet = [];
          if (this.state.guestUserCart.length > 0) {
            allArrs.map((y) => {
              if (y[1].takeFromAdmin) {
                let obj = mergeAdminEvent(y[1], this.props.global_campaigns)
                y[1] = Object.assign({}, obj)
              }
              availableKeysYet.push(y[1].pushKey);
              y[1].type = "campaign";
              y[1].currentImageIndex = 0;
              if (y[1].desc) {
                y[1].desc = y[1].desc.substr(0, 200);
                y[1].short_desc = y[1].desc.substr(0, 120);
              }
              y[1].images = [y[1].image];
              y[1].imagesObjected = [
                { image: y[1].image, pushKey: y[1].pushKey },
              ];
              if (y[1].campaignType === "local_event") {
                if (y[1].takeFromAdmin) {
                  let obj = mergeAdminEvent(y[1], this.props.global_campaigns)
                  y[1] = Object.assign({}, obj)
                }
                this.state.guestUserCart.map((e, i) => {
                  if (e.type === "product") {
                    return false;
                  }
                  if (e.item_type === "bhodi_product") {
                    return false;
                  }
                  if (e.pushKey === y[1].pushKey) {
                    if (!y[1].active) {
                      y[1].isNotActive = true;
                    } else {
                      y[1].isNotActive = false;
                    }
                    if (this.state.guestUserCart[i].hideFromListComplete) {
                      y[1].hideFromListComplete = true;
                    } else {
                      y[1].hideFromListComplete = false;
                    }
                    console.log(e.reserved_event_id, '/see here')
                    let obj = {
                      reserved_event_id: e.reserved_event_id,
                      ...y[1]
                    }
                    this.state.guestUserCart[i] = obj;

                    // this.state.guestUserCart[i] = y[1];
                  }
                });
              }
              if (y[1].campaignType === "kortings_voucher" || y[1].campaignType === "web_shop") {
                this.state.guestUserCart.map((t, i) => {
                  if (t.type === "product") {
                    return false;
                  }
                  if (t.item_type === "bhodi_product") {
                    return false;
                  }
                  if (t.pushKey === y[1].pushKey) {
                    if (!y[1].active) {
                      y[1].isNotActive = true;
                    } else {
                      y[1].isNotActive = false;
                    }
                    if (this.state.guestUserCart[i].hideFromListComplete) {
                      y[1].hideFromListComplete = true;
                    } else {
                      y[1].hideFromListComplete = false;
                    }
                    this.state.guestUserCart[i] = y[1];
                  }
                });
              }
            });
            this.state.guestUserCart.map((t) => {
              if (t.type === "product") {
                return false;
              }
              if (t.item_type === "bhodi_product") {
                return false;
              }
              if (!availableKeysYet.includes(t.pushKey)) {
                t.isNotActive = true;
              }
            });
            this.props.updateGuestUserCart(this.state.guestUserCart);
          }
        }, 200);
      }
    }
  }

  getDayName(num) {
    if (num === 0) {
      return `Zondag`;
    } else if (num === 1) {
      return `Maandag`;
    } else if (num === 2) {
      return `Dinsdag`;
    } else if (num === 3) {
      return `Woensdag`;
    } else if (num === 4) {
      return `Donderdag`;
    } else if (num === 5) {
      return `Vrijdag`;
    } else if (num === 6) {
      return `Zaterdag`;
    }
  }

  getMonthName(i) {
    i = Number(i);
    if (i === 1) {
      return "Januari";
    } else if (i === 2) {
      return "Februari";
    } else if (i === 3) {
      return "Maart";
    } else if (i === 4) {
      return "April";
    } else if (i === 5) {
      return "Mei";
    } else if (i === 6) {
      return "Juni";
    } else if (i === 7) {
      return "Juli";
    } else if (i === 8) {
      return "Augustus";
    } else if (i === 9) {
      return "September";
    } else if (i === 10) {
      return "Oktober";
    } else if (i === 11) {
      return "November";
    } else if (i === 12) {
      return "December";
    }
  }

  showDateToUserTiming(e, f) {
    if (e === f) {
      let splitted = e.split("-");
      let newDate = new Date(e).getDay();
      return `${this.getDayName(newDate)} ${splitted[2]} ${this.getMonthName(
        splitted[1]
      )}`;
    } else {
      let splitted = e.split("-");
      let splittedSecond = f.split("-");
      let newDate = new Date(e).getDay();
      let newDateAnother = new Date(f).getDay();
      if (splitted[1] === splittedSecond[1]) {
        return `${this.getDayName(newDate)} ${splitted[2]} - ${splittedSecond[2]
          } ${this.getMonthName(splittedSecond[1])}`;
      } else {
        // return `${this.getDayName(newDate)} ${splitted[2]} ${this.getMonthName(splitted[1])} - ${this.getDayName(newDateAnother)} ${splittedSecond[2]} ${this.getMonthName(splittedSecond[1])}`
        return `${splitted[2]} ${this.getMonthName(splitted[1])} t/m ${splittedSecond[2]
          } ${this.getMonthName(splittedSecond[1])}`;
      }
    }
  }

  getMonthName(i) {
    i = Number(i);
    if (i === 1) {
      return "Januari";
    } else if (i === 2) {
      return "Februari";
    } else if (i === 3) {
      return "Maart";
    } else if (i === 4) {
      return "April";
    } else if (i === 5) {
      return "Mei";
    } else if (i === 6) {
      return "Juni";
    } else if (i === 7) {
      return "Juli";
    } else if (i === 8) {
      return "Augustus";
    } else if (i === 9) {
      return "September";
    } else if (i === 10) {
      return "Oktober";
    } else if (i === 11) {
      return "November";
    } else if (i === 12) {
      return "December";
    }
  }

  getFormattedStartDate(product) {
    let date = new Date(product.startDate);
    return `${date.getDate()} ${this.getMonthName(date.getMonth() + 1)} | ${product.startTime
      } uur | ${date.getFullYear()}`;
  }

  getDiscountedValue(e, discount) {
    if (discount !== "Giveaway") {
      let splitted = discount.split("%");
      var numVal1 = Number(e);
      var numVal2 = Number(splitted[0]) / 100;
      var totalValue = numVal1 - numVal1 * numVal2;
      return totalValue;
    }
  }

  checkForShippingCost() {
    let filtered = this.state.guestUserCart.filter((g) => {
      return g.item_type === "bhodi_product"
    })
    let filteredMain =
      filtered.filter(
        (v, i, a) =>
          a.findIndex(
            (t) => t.created_by === v.created_by
          ) === i
      );
    if (filtered.length > 0 && this.props.user) {
      let allCreatorsShipmentCost = []
      filteredMain.map((e) => {
        let filteredInner = this.props.user.filter((t) => {
          return e.created_by === t.user_id
        })
        if (filteredInner.length > 0) {
          if (this.props.global_values.shippingCostProducts && this.props.global_values.shippingCostProducts !== "free") {
            let obj = {
              shipmentCost: this.props.global_values.shippingCostProducts
            }
            allCreatorsShipmentCost.push(obj)
          }
        }
      })
      let allTotal = 0
      allCreatorsShipmentCost.map((e) => {
        allTotal += Number(e.shipmentCost)
      })
      return (<div className="shipping-cost-container">
        <div>
          <MdLocalShipping style={{ color: "white", fontSize: 18 }} />
          {/* <img src={truckpic} style={{ width: 25, height: 20 }} /> */}
          <p>SHIPPING COST</p>
        </div>
        <label>total: €{<NumberFormatter toFormat={allTotal.toFixed(2)} />}</label>
      </div>)
    }
  }


  showTimeAtProduct(product) {
    if (product.is_bookmarked || product.onlyAvailableViaURL || product.isSpecialProduct || product.availableForCompanyGuest) {
      return;
    }
    let filterMyProducts = this.props.bhodi_products.filter((productInner) => {
      if (productInner.sizesActive) {
        let stocksAvailableSizes = []
        productInner.variations_data.map((e) => {
          if (Number(e.qty) === 0) {
            return false;
          }
          let filteredStockLength = []
          let filteredStockLengthVerzilvered = []
          if (productInner.inCartOf) {
            filteredStockLength = Object.entries(productInner.inCartOf).filter((t) => {
              return t[1].selectedSize === e.size
            })
          }
          if (productInner.verzilvered_by) {
            filteredStockLengthVerzilvered = Object.entries(productInner.verzilvered_by).filter((t) => {
              return t[1].selectedSize === e.size
            })
          }
          if ((filteredStockLength.length + filteredStockLengthVerzilvered.length) < Number(e.qty)) {
            stocksAvailableSizes.push(e)
          }
        })
        if (stocksAvailableSizes.length === 0) {
          return false;
        }
      } else {
        let filteredStockLength = []
        let filteredStockLengthVerzilvered = []
        if (productInner.inCartOf) {
          filteredStockLength = Object.entries(productInner.inCartOf).filter((t) => {
            return !t[1].selectedSize
          })
        }
        if (productInner.verzilvered_by) {
          filteredStockLengthVerzilvered = Object.entries(productInner.verzilvered_by).filter((t) => {
            return !t[1].selectedSize
          })
        }
        if (Number(productInner.qty) <= (filteredStockLength.length + filteredStockLengthVerzilvered.length) || Number(productInner.qty) === 0) {
          return false;
        }
        // if (Number(product.qty) === 0) {
        //     return false;
        // }
      }

      if (product.isSpecialProduct) {
        return !productInner.availableOnTime && productInner.images && productInner.active && productInner.isSpecialProduct && productInner.created_by === product.created_by
      } else {
        return !productInner.availableOnTime && productInner.images && productInner.active && productInner.created_by === product.created_by && !productInner.isSpecialProduct
      }
    })
    // let filterMyProducts = this.props.bhodi_products.filter((productInner) => {
    //     return productInner.created_by === product.created_by
    // })
    let filterCurrentUser = this.props.user.filter((g) => {
      return g.user_id === product.created_by
    })
    if (filterCurrentUser.length > 0 && filterMyProducts.length > 0) {

      let sortedAllProducts = [...filterMyProducts].sort((a, b) => {
        return a.sort_time * 100 - b.sort_time * 100;
      })

      let maxProductsToShow = 4

      if (product.isSpecialProduct) {
        maxProductsToShow = 5
        if (this.props.global_values) {
          if (this.props.global_values.products_count_new) {
            maxProductsToShow = Number(this.props.global_values.products_count_new)
          }
        }
      } else if (filterCurrentUser[0].productsInfo) {
        if (filterCurrentUser[0].productsInfo.maxProductsToShow) {
          maxProductsToShow = Number(filterCurrentUser[0].productsInfo.maxProductsToShow)
        }
      }


      if (filterCurrentUser[0].productsInfo && filterCurrentUser[0].productsInfo.intervalTimer && sortedAllProducts.length < maxProductsToShow) {
        let newerProduct = product

        let dateCreated = new Date()
        let splitted = filterCurrentUser[0].productsInfo.intervalTimer.split(" ")

        if (splitted[1].includes("second")) {
          dateCreated.setSeconds(dateCreated.getSeconds() + Number(splitted[0]))
        } else if (splitted[1].includes("minute")) {
          dateCreated.setMinutes(dateCreated.getMinutes() + Number(splitted[0]))
        } else {
          dateCreated.setHours(dateCreated.getHours() + Number(splitted[0]))
        }

        firebase.database().ref(`bhodi_products/${newerProduct.pushKey}/buttonTimingToShow`).set(dateCreated.toISOString())
      }
    }
  }


  render() {
    return (
      !this.props.notOnMainPage && (
        <div>
          {/* <li id="addtocarticon" className="cart-icon" onClick={() => {
                    this.setState({ popupright: true, popupVouchers: false, popupShop: false, popupcompanies: false, popupExclusives: false, });
                    document.getElementById("root").style.position = "fixed"
                }}>
                    <a href="javascript:void(0)" className="socialIcon">
                        <span className="footer_count">{this.getCartQtyGuest()}</span>
                        <span className="d-block d-sm-none ftext">Basket</span>
                        <span className="tooltiptext">Basket</span>
                        <span className="tooltiptext tooltipinfo" id="mustShowOnAddBasket">Toegevoegd</span>
                    </a>
                </li> */}
          <div
            className="btn-cdd socialIcon cart-icon"
            style={{
              cursor: "pointer",
              padding: (this.props.isSpecialProduct && window.innerWidth < 1008) ? "5px 3px" : "5px 15px",
              letterSpacing: 1,
              fontSize: 13,
              backgroundColor: "transparent",
              top: this.props.onGuestSpecial ? "auto" : this.props.isSpecialProduct && (window.innerWidth < 1008 ? 13 : 22),
              right: this.props.onGuestSpecial ? "auto" : this.props.isSpecialProduct ? (window.innerWidth < 1008 ? 91 : 150) : (window.innerWidth > 1009 ? -20 : 50),
              position: this.props.onGuestSpecial && "relative"
            }}
            onClick={() => {
              if (this.props.onlyShowCount) {
                history.push("/cart-page-guest");
                // window.location.href = "/cart-page-guest";
              } else {
                this.setState({
                  popupright: true,
                  popupVouchers: false,
                  popupShop: false,
                  popupcompanies: false,
                  popupExclusives: false,
                });
                document.getElementById("root").style.position = "fixed";
              }
            }}
          >
            {(this.props.isSpecialProduct && window.innerWidth < 1008) ? <span className="footer_count" style={{
              fontSize: 9,
              top: -5,
              right: -10,
              width: 14,
              height: 14,
              lineHeight: 0,
            }}>{this.getCartQtyGuest()}</span> : (<span className="footer_count">{this.getCartQtyGuest()}</span>)}
            <HiOutlineShoppingBag style={{ fontSize: 24, color: this.props.isSpecialProduct && "white" }} />
          </div>
          {/* Popup Basket */}
          {this.state.popupright && (
            <div
              className="bg-overlaymini"
              style={{ position: this.props.overlayfixed && "fixed" }}
              onClick={() => {
                this.setState({
                  popupright: false,
                  popupmarket: false,
                  popupservices: false,
                });
                document.getElementById("root").style.position = "static";
              }}
            ></div>
          )}
          <div
            className={`mnicart-flyout-inner mnicartflyoutbasketevent ${this.state.popupright ? "open" : null
              }`}
          >
            <div className="checkout-flyout-inner-content">
              <div className="flyout-section">
                <div className="minicart-flyout-wrapper">
                  {!this.state.showCartPageRoute && (
                    <CloseIcon
                      style={{
                        position: "absolute",
                        cursor: "pointer",
                        top: 15,
                        fontSize: 20,
                        right: 35,
                        zIndex: 9999,
                      }}
                      onClick={() => {
                        this.setState({ popupright: false });
                        document.getElementById("root").style.position =
                          "static";
                      }}
                    />
                  )}
                  {this.state.showCartPageRoute && (
                    <CloseIcon
                      style={{
                        position: "absolute",
                        cursor: "pointer",
                        top: 15,
                        fontSize: 20,
                        right: 35,
                        zIndex: 9999,
                      }}
                      onClick={() => {
                        history.goBack()
                      }}
                    />
                  )}
                  <div className="minicart-flyout-header">
                    {/* {window.innerWidth > 1009 ? (
                      <div className="centered">
                        <h3>WINKELMAND</h3>
                        {this.state.stopCartQty ? (
                          <span style={{ marginBottom: 5 }}>0 producten</span>
                        ) : (
                          <span style={{ marginBottom: 5 }}>
                            {this.getCartQtyEvents()} {this.state.guestUserCart.length > 1 ? "Items" : "Item"}
                          </span>
                        )}
                        {this.checkForTimerHeading()}
                        {this.checkForTimerButton()}
                      </div>
                    ) : ( */}
                    <FooterBasketTimer
                      openExtendPopup={() => {
                        this.interval = setInterval(
                          () => this.setState({ timeCurrent: Date.now() }),
                          1000
                        );
                        this.setState({ pointOutToTest: true });
                      }}
                    />
                    {/* )} */}
                  </div>
                  <div
                    className="flyout-body"
                    style={{ paddingBottom: this.state.showCartPageRoute && 0 }}
                  >
                    {this.state.allSellers.length > 0 &&
                      this.state.allSellers.map((e) => {
                        return (
                          <>
                            <p
                              className="seller-name-cp"
                              style={{ marginLeft: 0 }}
                            >
                              AANBIEDER: {this.getSellerName(e)}
                            </p>
                            {this.state.guestUserCart.length > 0 &&
                              this.state.guestUserCart.map((g, i) => {
                                console.log(g, '/see here')
                                if (!g.hideFromListComplete) {
                                  if (
                                    g.created_by === e ||
                                    g.mainParentCreator === e
                                  ) {
                                    if (g.item_type === "bhodi_product") {
                                      let indexOfImage = 0
                                      if (g.images && g.images.length > 0) {
                                        if (g.featuredImage && g.featuredImage < g.images.length) {
                                          indexOfImage = g.featuredImage
                                        }

                                        let findIndex = g.images.findIndex((t) => {
                                          return t.includes("_1")
                                        })
                                        if (findIndex !== -1) {
                                          indexOfImage = findIndex
                                        }
                                      }
                                      return (
                                        <div
                                          className="minicart-product"
                                          key={Math.random()}
                                        >
                                          {g.images && g.images.length > 0 && (
                                            <div
                                              className="mini-cartimage"
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              <img
                                                src={g.images[indexOfImage]}
                                                style={{ maxWidth: "none" }}
                                              />
                                            </div>
                                          )}
                                          {g.isNotActive ? (
                                            <div
                                              className="mini-cartinfo"
                                              style={{
                                                alignItems: "flex-start",
                                                textAlign: "left",
                                              }}
                                            >
                                              <div className="mini-cart-small-title"></div>
                                              <div
                                                className="mini-cart-name"
                                                style={{ cursor: "pointer" }}
                                              >
                                                <a
                                                  style={{
                                                    textTransform: "uppercase",
                                                    fontSize: 13,
                                                    fontWeight: 600,
                                                  }}
                                                >
                                                  PRODUCT NIET MEER BESCHIKBAAR
                                                </a>
                                              </div>
                                            </div>
                                          ) : (
                                            <div
                                              className="mini-cartinfo"
                                              style={{
                                                alignItems: "flex-start",
                                                textAlign: "left",
                                              }}
                                            >
                                              <div className="mini-cart-small-title">
                                                {/* <font>{this.getSellerName(g)}</font> */}
                                              </div>
                                              <div
                                                className="mini-cart-name"
                                                style={{ cursor: "pointer" }}
                                              >
                                                <a
                                                  style={{
                                                    textTransform: "uppercase",
                                                    fontSize: 13,
                                                    fontWeight: 600,
                                                  }}
                                                >
                                                  {g.title &&
                                                    g.title.substr(0, 40)} {g.selectedSizeOfOrder && `(${g.selectedSizeOfOrder})`}
                                                </a>
                                              </div>
                                              <div
                                                className="mini-cart-details-bottom"
                                                style={{ textAlign: "left" }}
                                              >
                                                <div className="mini-cart-pricing">
                                                  <font>Free Item</font>
                                                </div>
                                                <div className="mini-cart-price-wrapper">
                                                  <CloseIcon
                                                    style={{
                                                      fontSize: 18,
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                      let completeCartArray =
                                                        this.state
                                                          .completeCartArray;
                                                      let filtered =
                                                        completeCartArray.filter(
                                                          (y) => {
                                                            if (g.selectedSizeOfOrder) {
                                                              return (
                                                                y.itemPush ===
                                                                g.pushKey && y.selectedSize === g.selectedSizeOfOrder
                                                              );
                                                            } else {
                                                              return (
                                                                y.itemPush ===
                                                                g.pushKey
                                                              );
                                                            }
                                                          }
                                                        );
                                                      let cookieItem =
                                                        localStorage.getItem(
                                                          "cookie_item"
                                                        );
                                                      // if (this.props.bhodi_products) {
                                                      //   let myCurrent = this.props.bhodi_products.filter((h) => {
                                                      //     return (h.pushKey === g.pushKey);
                                                      //   });
                                                      //   if (myCurrent.length > 0) {
                                                      //     if (
                                                      //       myCurrent[0].inCartOf
                                                      //     ) {
                                                      //       let objected =
                                                      //         Object.entries(
                                                      //           myCurrent[0].inCartOf
                                                      //         );
                                                      //       if (
                                                      //         objected.length > 0
                                                      //       ) {
                                                      //         let filteredCart =
                                                      //           objected.filter(
                                                      //             (y) => {
                                                      //               if (g.selectedSizeOfOrder) {
                                                      //                 return (
                                                      //                   y[1]
                                                      //                     .cookie ===
                                                      //                   cookieItem && y[1].selectedSize === g.selectedSizeOfOrder
                                                      //                 );
                                                      //               }
                                                      //               return (
                                                      //                 y[1]
                                                      //                   .cookie ===
                                                      //                 cookieItem
                                                      //               );
                                                      //             }
                                                      //           );
                                                      //         if (
                                                      //           filteredCart.length >
                                                      //           0
                                                      //         ) {
                                                      //           firebase
                                                      //             .database()
                                                      //             .ref(
                                                      //               "bhodi_products/" +
                                                      //               g.pushKey +
                                                      //               "/inCartOf/" +
                                                      //               filteredCart[0][1]
                                                      //                 .pushKey
                                                      //             )
                                                      //             .remove();
                                                      //         }
                                                      //       }
                                                      //     }
                                                      //   }
                                                      // }
                                                      if (filtered.length > 0) {
                                                        firebase
                                                          .database()
                                                          .ref(
                                                            "guest_carts/" +
                                                            cookieItem +
                                                            "/" +
                                                            filtered[0]
                                                              .pushKey
                                                          )
                                                          .remove();

                                                        firebase
                                                          .database()
                                                          .ref(
                                                            "bhodi_products/" +
                                                            g.pushKey +
                                                            "/inCartOf/" +
                                                            filtered[0]
                                                              .pushKeyInCartOf
                                                          )
                                                          .remove();

                                                        this.showTimeAtProduct(g)
                                                      }
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      );
                                    }
                                    return (
                                      <div
                                        className="minicart-product"
                                        key={Math.random()}
                                      >
                                        {(g.campaignType === "live_event" || (g.campaignType === "local_event" && !g.localEventPlaceHolderIsImage)) ? (
                                          <div
                                            className="mini-cartimage"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <div className="iconreplacedlive"></div>
                                          </div>
                                        ) : (
                                          g.imagesObjected &&
                                          g.imagesObjected.length > 0 && (
                                            <div
                                              className="mini-cartimage"
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              <img
                                                src={(window.innerWidth < 1008 && g.image_mobile) ? g.image_mobile : g.imagesObjected[0].image}
                                              />
                                            </div>
                                          )
                                        )}
                                        {g.isNotActive ? (
                                          <div className="mini-cartinfo">
                                            <div className="mini-cart-small-title"></div>
                                            {g.campaignType ===
                                              "last_minute" ? (
                                              <div
                                                className="mini-cart-name"
                                                style={{ cursor: "pointer" }}
                                              >
                                                <a
                                                  style={{
                                                    textTransform: "uppercase",
                                                    fontSize: 13,
                                                    fontWeight: 600,
                                                  }}
                                                >
                                                  Sorry, you missed this event
                                                </a>
                                              </div>
                                            ) : (
                                              <div
                                                className="mini-cart-name"
                                                style={{ cursor: "pointer" }}
                                              >
                                                <a
                                                  style={{
                                                    textTransform: "uppercase",
                                                    fontSize: 13,
                                                    fontWeight: 600,
                                                  }}
                                                >
                                                  CAMPAGNE NIET MEER BESCHIKBAAR
                                                </a>
                                              </div>
                                            )}
                                          </div>
                                        ) : (
                                          <div className="mini-cartinfo">
                                            <div className="mini-cart-small-title">
                                              {/* <font>{this.getSellerName(g)}</font> */}
                                            </div>
                                            {g.campaignType === "live_event" ? (
                                              <div
                                                className="mini-cart-name"
                                                style={{ cursor: "pointer" }}
                                              >
                                                <a
                                                  style={{
                                                    textTransform: "uppercase",
                                                    fontSize: 13,
                                                    fontWeight: 600,
                                                  }}
                                                >
                                                  LIVE EVENT
                                                </a>
                                                {/* <a style={{ textTransform: "uppercase", fontSize: 13, fontWeight: 600 }}>{g.descriptionLiveEvent.substr(0, 40)}</a> */}
                                              </div>
                                            ) : g.campaignType ===
                                              "local_event" ? (
                                              <div
                                                className="mini-cart-name"
                                                style={{ cursor: "pointer" }}
                                              >
                                                <a
                                                  style={{
                                                    textTransform: "uppercase",
                                                    fontSize: 13,
                                                    fontWeight: 600,
                                                  }}
                                                >
                                                  {g.title &&
                                                    g.title.substr(0, 40)}
                                                </a>
                                                {/* <a style={{ textTransform: "uppercase", fontSize: 13, fontWeight: 600}}>LOCAL EVENT</a> */}
                                                {/* <a style={{ textTransform: "uppercase", fontSize: 13, fontWeight: 600 }}>{g.descriptionLiveEvent.substr(0, 40)}</a> */}
                                              </div>
                                            ) : (
                                              <div
                                                className="mini-cart-name"
                                                style={{ cursor: "pointer" }}
                                              >
                                                <a
                                                  style={{
                                                    textTransform: "uppercase",
                                                    fontSize: 13,
                                                    fontWeight: 600,
                                                  }}
                                                >
                                                  {g.title}
                                                </a>
                                              </div>
                                            )}
                                            {g.campaignType ===
                                              "local_event" ? (
                                              <div
                                                className="mini-cart-details-bottom"
                                                style={{ textAlign: "left" }}
                                              >
                                                {(!g.takeFromAdmin && g.created_by !== process.env.REACT_APP_ADMIN_ID && g.amount) && (<div className="mini-cart-pricing">
                                                  {g.localEventType ===
                                                    "percentage" ? (
                                                    <font>
                                                      Korting {g.amount}%
                                                    </font>
                                                  ) : (
                                                    <font>
                                                      Korting €{g.amount}
                                                    </font>
                                                  )}
                                                </div>)}
                                                {g.timings &&
                                                  g.timings.length > 0 && (
                                                    <>
                                                      <div className="mini-cart-pricing">
                                                        <font>
                                                          {this.showDateToUserTiming(
                                                            g.timings[g.reserved_event_id ? Number(g.reserved_event_id) - 1 : 0]
                                                              .startDateEvent,
                                                            g.timings[g.reserved_event_id ? Number(g.reserved_event_id) - 1 : 0]
                                                              .endDateEvent
                                                          )}
                                                        </font>
                                                      </div>
                                                      {(!g.takeFromAdmin && g.created_by !== process.env.REACT_APP_ADMIN_ID && g.amount) && (<div className="mini-cart-pricing">
                                                        <font>
                                                          {
                                                            g.timings[g.reserved_event_id ? Number(g.reserved_event_id) - 1 : 0]
                                                              .startTimeEvent
                                                          }
                                                          -
                                                          {
                                                            g.timings[g.reserved_event_id ? Number(g.reserved_event_id) - 1 : 0]
                                                              .endTimeEvent
                                                          }{" "}
                                                          uur
                                                        </font>
                                                      </div>)}
                                                    </>
                                                  )}
                                                <div className="mini-cart-price-wrapper">
                                                  <CloseIcon
                                                    style={{
                                                      fontSize: 18,
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                      // if (this.state.guestUserCart.length > 0) {
                                                      //     let timings = g.timings
                                                      //     if (timings && timings.length > 0) {
                                                      //         g.timings.map((e, i) => {
                                                      //             if (i === 0) {
                                                      //                 let personsAmountAdded = Number(e.personsAmountEvent) + 1
                                                      //                 if (personsAmountAdded < 10) {
                                                      //                     timings[i].personsAmountEvent = `0${personsAmountAdded}`
                                                      //                 } else {
                                                      //                     timings[i].personsAmountEvent = `${personsAmountAdded}`
                                                      //                 }
                                                      //             }
                                                      //         })
                                                      //         firebase.database().ref("bhodi_campaigns/" + g.created_by + "/" + g.pushKey + "/timings").set(timings)
                                                      //     }
                                                      // }

                                                      let completeCartArray = this.state.completeCartArray;
                                                      let filtered = completeCartArray.filter((y) => {
                                                        return y.itemPush === g.pushKey
                                                      });
                                                      let cookieItem = localStorage.getItem("cookie_item");

                                                      if (filtered.length > 0) {
                                                        firebase.database().ref("bhodi_campaigns/" + filtered[0].creatorCompanyId + "/" + filtered[0].itemPush + "/inCartOf/" + filtered[0].pushKeyInCartOf).remove();
                                                        firebase.database().ref("guest_carts/" + cookieItem + "/" + filtered[0].pushKey).remove();
                                                      }
                                                      // if (
                                                      //   this.props
                                                      //     .global_campaigns
                                                      // ) {
                                                      //   let myCurrent =
                                                      //     this.props.global_campaigns.filter(
                                                      //       (h) => {
                                                      //         return (
                                                      //           h[1]
                                                      //             .pushKey ===
                                                      //           g.pushKey
                                                      //         );
                                                      //       }
                                                      //     );
                                                      //   if (
                                                      //     myCurrent[0][1]
                                                      //       .inCartOf
                                                      //   ) {
                                                      //     let objected =
                                                      //       Object.entries(
                                                      //         myCurrent[0][1]
                                                      //           .inCartOf
                                                      //       );
                                                      //     if (
                                                      //       objected.length > 0
                                                      //     ) {
                                                      //       let filteredCart =
                                                      //         objected.filter(
                                                      //           (y) => {
                                                      //             return (
                                                      //               y[1]
                                                      //                 .cookie ===
                                                      //               cookieItem
                                                      //             );
                                                      //           }
                                                      //         );
                                                      //       if (
                                                      //         filteredCart.length >
                                                      //         0
                                                      //       ) {

                                                      //         firebase
                                                      //           .database()
                                                      //           .ref(
                                                      //             "bhodi_campaigns/" +
                                                      //             g.created_by +
                                                      //             "/" +
                                                      //             g.pushKey +
                                                      //             "/inCartOf/" +
                                                      //             filteredCart[0][1]
                                                      //               .pushKey
                                                      //           )
                                                      //           .remove();
                                                      //       }
                                                      //     }
                                                      //   }
                                                      // }
                                                      // if (filtered.length > 0) {
                                                      //   firebase
                                                      //     .database()
                                                      //     .ref(
                                                      //       "guest_carts/" +
                                                      //       cookieItem +
                                                      //       "/" +
                                                      //       filtered[0]
                                                      //         .pushKey
                                                      //     )
                                                      //     .remove();
                                                      // }

                                                      // toDoChanges.splice(i, 1)
                                                      // if (toDoChanges.length === 0) {
                                                      //     this.setState({ guestUserCart: [], allSellers: [] })
                                                      // }
                                                      // this.props.updateGuestUserCart(toDoChanges)
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                            ) : g.campaignType === "web_shop" ? (
                                              <div
                                                className="mini-cart-details-bottom"
                                                style={{ textAlign: "left" }}
                                              >
                                                <div className="mini-cart-pricing">
                                                  {!g.voucherIsPercentage && (
                                                    <font>
                                                      Korting €{g.amount}
                                                    </font>
                                                  )}
                                                  {g.voucherIsPercentage && (
                                                    <font>
                                                      Korting {g.amount}%
                                                    </font>
                                                  )}
                                                </div>
                                                <div className="mini-cart-price-wrapper">
                                                  <CloseIcon
                                                    style={{
                                                      fontSize: 18,
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                      let completeCartArray = this.state.completeCartArray;
                                                      let filtered = completeCartArray.filter((y) => {
                                                        return y.itemPush === g.pushKey
                                                      });
                                                      let cookieItem = localStorage.getItem("cookie_item");

                                                      if (filtered.length > 0) {
                                                        firebase.database().ref("bhodi_campaigns/" + filtered[0].creatorCompanyId + "/" + filtered[0].itemPush + "/inCartOf/" + filtered[0].pushKeyInCartOf).remove();
                                                        firebase.database().ref("guest_carts/" + cookieItem + "/" + filtered[0].pushKey).remove();
                                                      }
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                            ) : g.campaignType ===
                                            "kortings_voucher" && (
                                              <div
                                                className="mini-cart-details-bottom"
                                                style={{ textAlign: "left" }}
                                              >
                                                <div className="mini-cart-pricing">
                                                  {g.kortingsVoucherPrijs && (
                                                    <font>
                                                      Korting €{g.amount}
                                                    </font>
                                                  )}
                                                  {g.kortingsVoucherPercentage && (
                                                    <font>
                                                      Korting {g.amount}%
                                                    </font>
                                                  )}
                                                  {g.kortingsVoucherGiveaway ? (
                                                    <font>Free</font>
                                                  ) : (!g.kortingsVoucherPrijs && !g.kortingsVoucherPercentage && !g.isoTimeTimerBtnEndTime) && (
                                                    <font>
                                                      Korting €{g.kortingsVoucherSalePrice}
                                                    </font>
                                                  )}
                                                  {(!g.kortingsVoucherPrijs && !g.kortingsVoucherPercentage && g.isoTimeTimerBtnEndTime && (new Date(g.isoTimeTimerBtnEndTime).getTime() > new Date().getTime())) && (
                                                    <font>
                                                      Korting €{g.kortingsVoucherSalePrice}
                                                    </font>
                                                  )}
                                                  {(!g.kortingsVoucherPrijs && !g.kortingsVoucherPercentage && g.isoTimeTimerBtnEndTime && (new Date(g.isoTimeTimerBtnEndTime).getTime() < new Date().getTime())) && (
                                                    <font>
                                                      Korting €{g.kortingsVoucherNormalPrice}
                                                    </font>
                                                  )}
                                                </div>
                                                <div className="mini-cart-price-wrapper">
                                                  <CloseIcon
                                                    style={{
                                                      fontSize: 18,
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                      let completeCartArray = this.state.completeCartArray;
                                                      let filtered = completeCartArray.filter((y) => {
                                                        return y.itemPush === g.pushKey
                                                      });
                                                      let cookieItem = localStorage.getItem("cookie_item");

                                                      if (filtered.length > 0) {
                                                        firebase.database().ref("bhodi_campaigns/" + filtered[0].creatorCompanyId + "/" + filtered[0].itemPush + "/inCartOf/" + filtered[0].pushKeyInCartOf).remove();
                                                        firebase.database().ref("guest_carts/" + cookieItem + "/" + filtered[0].pushKey).remove();
                                                      }
                                                    }}
                                                  // onClick={() => {

                                                  //   let completeCartArray =
                                                  //     this.state
                                                  //       .completeCartArray;
                                                  //   let filtered =
                                                  //     completeCartArray.filter(
                                                  //       (y) => {
                                                  //         return (
                                                  //           y.itemPush ===
                                                  //           g.pushKey
                                                  //         );
                                                  //       }
                                                  //     );
                                                  //   let cookieItem =
                                                  //     localStorage.getItem(
                                                  //       "cookie_item"
                                                  //     );
                                                  //   if (
                                                  //     this.props
                                                  //       .global_campaigns
                                                  //   ) {
                                                  //     let myCurrent =
                                                  //       this.props.global_campaigns.filter(
                                                  //         (h) => {
                                                  //           return (
                                                  //             h[1].pushKey ===
                                                  //             g.pushKey
                                                  //           );
                                                  //         }
                                                  //       );
                                                  //     if (
                                                  //       myCurrent[0][1]
                                                  //         .inCartOf
                                                  //     ) {
                                                  //       let objected =
                                                  //         Object.entries(
                                                  //           myCurrent[0][1]
                                                  //             .inCartOf
                                                  //         );
                                                  //       if (
                                                  //         objected.length > 0
                                                  //       ) {
                                                  //         let filteredCart =
                                                  //           objected.filter(
                                                  //             (y) => {
                                                  //               return (
                                                  //                 y[1]
                                                  //                   .cookie ===
                                                  //                 cookieItem
                                                  //               );
                                                  //             }
                                                  //           );
                                                  //         if (
                                                  //           filteredCart.length >
                                                  //           0
                                                  //         ) {
                                                  //           firebase
                                                  //             .database()
                                                  //             .ref(
                                                  //               "bhodi_campaigns/" +
                                                  //               g.created_by +
                                                  //               "/" +
                                                  //               g.pushKey +
                                                  //               "/inCartOf/" +
                                                  //               filteredCart[0][1]
                                                  //                 .pushKey
                                                  //             )
                                                  //             .remove();
                                                  //         }
                                                  //       }
                                                  //     }
                                                  //   }
                                                  //   if (filtered.length > 0) {
                                                  //     firebase
                                                  //       .database()
                                                  //       .ref(
                                                  //         "guest_carts/" +
                                                  //         cookieItem +
                                                  //         "/" +
                                                  //         filtered[0]
                                                  //           .pushKey
                                                  //       )
                                                  //       .remove();
                                                  //   }

                                                  // }}
                                                  />
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    );
                                  }
                                }
                              })}
                          </>
                        );
                      })}
                  </div>
                </div>
              </div>
              {this.state.popupright && this.state.guestUserCart.length > 0 && (
                <div className="minicart-flyout-checkout">
                  {this.checkForShippingCost()}
                  <div className="buttons-total">
                    {window.innerWidth > 1009 ? (
                      <button
                        className="btn-continue"
                        onClick={() => {
                          this.setState({ popupright: false });
                          document.getElementById("root").style.position =
                            "static";
                        }}
                      >
                        VERDER WINKELEN
                      </button>
                    ) : (
                      <button
                        style={{ pointerEvents: "none", opacity: 0 }}
                        className="btn-continue"
                        onClick={() => {
                          this.setState({ popupright: false });
                          document.getElementById("root").style.position =
                            "static";
                        }}
                      >
                        VERDER WINKELEN
                      </button>
                    )}
                    {window.innerWidth > 1009 ? (
                      <button
                        className="btn-cart"
                        onClick={() => {
                          var minutesToAdd = Number(process.env.REACT_APP_CART_TIME);
                          var currentDate = new Date();
                          var futureDate = new Date(
                            currentDate.getTime() + minutesToAdd * 60000
                          );

                          let futureTimeStamp = Math.floor(
                            futureDate.getTime() / 1000
                          );

                          this.props.updateGuestUserCartTime(futureTimeStamp);

                          if (this.state.completeCartArray.length > 0) {
                            this.state.completeCartArray.map((t) => {
                              if (t.cookieItem && t.pushKey) {
                                firebase
                                  .database()
                                  .ref(
                                    `guest_carts/${t.cookieItem}/${t.pushKey}/timestamp`
                                  )
                                  .set(futureTimeStamp);
                              }
                            });
                            // let onKey = this.state.completeCartArray[this.state.completeCartArray.length - 1]
                            localStorage.setItem("completeCartArray", JSON.stringify(this.state.completeCartArray))
                          }

                          setTimeout(() => {
                            let useridGuest = localStorage.getItem("userid-guest");
                            let useridNonMem = localStorage.getItem("userid-nonmem");
                            if (useridGuest || useridNonMem) {
                              history.push("/guest-event-cart/stepTwo");
                            } else {
                              history.push("/guest-event-cart");
                            }
                            // history.push("/guest-event-cart");
                            // window.location.href = "/guest-event-cart";
                          }, 200);
                        }}
                      >
                        VOLGENDE STAP
                      </button>
                    ) : (
                      <button
                        className="btn-cart"
                        style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
                      >
                        <div>
                          <ChevronLeftIcon onClick={() => history.goBack()} />
                        </div>
                        <div
                          onClick={() => {
                            var minutesToAdd = Number(process.env.REACT_APP_CART_TIME);
                            var currentDate = new Date();
                            var futureDate = new Date(
                              currentDate.getTime() + minutesToAdd * 60000
                            );

                            let futureTimeStamp = Math.floor(
                              futureDate.getTime() / 1000
                            );

                            this.props.updateGuestUserCartTime(futureTimeStamp);

                            if (this.state.completeCartArray.length > 0) {
                              this.state.completeCartArray.map((t) => {
                                if (t.cookieItem && t.pushKey) {
                                  firebase
                                    .database()
                                    .ref(
                                      `guest_carts/${t.cookieItem}/${t.pushKey}/timestamp`
                                    )
                                    .set(futureTimeStamp);
                                }
                              });
                              // let onKey = this.state.completeCartArray[this.state.completeCartArray.length - 1]
                              localStorage.setItem("completeCartArray", JSON.stringify(this.state.completeCartArray))
                            }

                            setTimeout(() => {
                              let useridGuest = localStorage.getItem("userid-guest");
                              let useridNonMem = localStorage.getItem("userid-nonmem");
                              if (useridGuest || useridNonMem) {
                                history.push("/guest-event-cart/stepTwo");
                              } else {
                                history.push("/guest-event-cart");
                              }
                              // history.push("/guest-event-cart");
                              // window.location.href = "/guest-event-cart";
                            }, 200);
                          }}>
                          VOLGENDE STAP <ChevronRightIcon />
                        </div>
                      </button>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          {window.innerWidth > 1009 && this.checkForPopupExtend()}
          {window.innerWidth < 1009 && this.state.pointOutToTest && (
            <>
              <div
                className="bg-overlaymini"
                style={{ backgroundColor: "rgba(0, 0, 0, .9)" }}
                onClick={() => {
                  this.props.updateGuestUserCartExtendOpportunity(false);
                  clearInterval(this.interval);
                  this.setState({ pointOutToTest: false });
                }}
              ></div>
              <div className="card-to-extend" style={{ position: "fixed" }}>
                <CloseIcon
                  onClick={() => {
                    this.setState({ pointOutToTest: false });
                    this.props.updateGuestUserCartExtendOpportunity(false);
                    clearInterval(this.interval);
                  }}
                />
                <p className="main-title-cte">WINKELMAND</p>
                {/* <p className="greetings-cte">Hi</p> */}
                <p className="greetings-cte">Je winkelmandje verloopt bijna.</p>
                <p className="timer-cte">{this.getTheTimer()}</p>
                <p className="bottom-content-cte">Om ook andere members</p>
                <p className="bottom-content-cte">
                  toegang te geven tot deze actie
                </p>
                <p className="bottom-content-cte">
                  kan je winkelmand niet nogmaals
                </p>
                <p className="bottom-content-cte">worden verlengd.</p>
                <button
                  onClick={() => {
                    var minutesToAdd = Number(process.env.REACT_APP_CART_TIME);
                    var currentDate = new Date();
                    var futureDate = new Date(
                      currentDate.getTime() + minutesToAdd * 60000
                    );

                    let futureTimeStamp = Math.floor(
                      futureDate.getTime() / 1000
                    );

                    clearInterval(this.interval);
                    this.setState({ pointOutToTest: false });
                    this.props.updateGuestUserCartTime(futureTimeStamp);
                    this.props.updateGuestUserCartExtendOpportunity(false);


                    if (this.state.completeCartArray.length > 0) {
                      this.state.completeCartArray.map((t) => {
                        if (t.cookieItem && t.pushKey) {
                          firebase
                            .database()
                            .ref(
                              `guest_carts/${t.cookieItem}/${t.pushKey}/timestamp`
                            )
                            .set(futureTimeStamp);
                        }
                      });
                      // let onKey = this.state.completeCartArray[this.state.completeCartArray.length - 1]
                    }
                  }}
                >
                  VERLENGEN
                </button>
              </div>
            </>
          )}
        </div>
      )
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducers.user,
    product: state.campaignsReducer.product,
    shopproducts: state.campaignsReducer.shopproducts,
    allVouchers: state.campaignsReducer.allVouchers,
    globalsectors: state.categoriesReducer.globalsectors,
    global_campaigns: state.campaignsReducer.global_campaigns,
    current_exclusives: state.exclusiveReducers.current_exclusives,
    friends_exclusives: state.exclusiveReducers.friends_exclusives,
    selected_exclusive: state.exclusiveReducers.selected_exclusive,
    global_values: state.globalReducers.global_values,
    categories_albums: state.categoriesReducer.categories_albums,
    categories_albums_available:
      state.categoriesReducer.categories_albums_available,
    guest_user_cart: state.cartReducer.guest_user_cart,
    guest_user_cart_time: state.cartReducer.guest_user_cart_time,
    guest_user_cart_extend_opportunity:
      state.cartReducer.guest_user_cart_extend_opportunity,
    guest_db_cart: state.cartReducer.guest_db_cart,
    bhodi_products: state.productsReducers.bhodi_products,
    products_new: state.campaignsReducer.products_new,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateGuestUserCart: (guest_user_cart) =>
      dispatch(updateGuestUserCart(guest_user_cart)),
    updateGuestUserCartTime: (guest_user_cart_time) =>
      dispatch(updateGuestUserCartTime(guest_user_cart_time)),
    updateGuestUserCartExtendOpportunity: (
      guest_user_cart_extend_opportunity
    ) =>
      dispatch(
        updateGuestUserCartExtendOpportunity(guest_user_cart_extend_opportunity)
      ),
    voucherSectorCategories: (vouchersectors) =>
      dispatch(voucherSectorCategories(vouchersectors)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FooterBasket);
