import React, { Component } from 'react'
import firebase from "../../Config/Firebase"
import "./index.css"
import axios from "axios";
import { connect } from 'react-redux';
import {
    updateAdminOrNot,
    updateGlobalStuff,
    updateAlbumToAdd,
    updateCategoriesVouchers,
} from "../../Redux/actions/authActions";
import Footer from '../../Components/Footer';
import FooterGreen from '../../Components/FooterGreen';
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { history } from '../../Config/Routes';
import swal from 'sweetalert';
import logoSubAdmin from "../../assets/subadminlogo.png";


export class PayViaLink extends Component {
    constructor() {
        super()
        this.state = {
            secondStep: false,
            aanmeldenloader: false,
            email: "",
            firstName: "",
            lastName: "",
            allUsers: [],
            showMainLoader: true,
            fromDirectUrl: false,
            thirdStep: false,
            companyUrlRedirect: "",
            defaultEmailParam: "",
            defaultKeyParam: "",
            fourthStep: false,
            company_id: "",

            allListings: [],
            indexOfCurrentCompany: -1,
            currentIndustry: "restaurant",


            allImagesCurrentSectorStock: [],
            allSectors: [],
            mainSectorStock: "",


            // new restaurant stuff
            amountOfTables: "",
            subscription_data: [],
        }
    }

    componentDidMount() {
        let searchParams = new URLSearchParams(window.location.search)
        if (searchParams.get("key")) {
            let pushKey = searchParams.get("key")
            firebase.database().ref(`payment_links_stripe/${pushKey}`).on("value", (data) => {
                let a = data.val()
                if (a) {
                    if (a.active) {
                        this.setState({ company_id: a.company_id })
                    } else {
                        this.setState({ isNotActive: true })
                    }
                } else {
                    this.setState({ isNotActive: true })
                }
                this.setState({ showMainLoader: false })
            })
        }

        let arr = [];
        if (this.props.subscription_plans) {
            if (this.props.subscription_plans.length > 0) {
                arr = this.props.subscription_plans;
                this.setState({
                    subscription_data: arr,
                });
            }
        }
    }

    componentDidUpdate(prevProps) {

        let arr = [];
        if (this.props.subscription_plans !== prevProps.subscription_plans) {
            if (this.props.subscription_plans.length > 0) {
                arr = this.props.subscription_plans;
                this.setState({
                    subscription_data: arr,
                });
            }
        }
    }

    checkForValuePresent(param) {
        if (param !== "") {
            return true;
        } else {
            return false;
        }
    }

    updateStateOnCondition(param, paramToSet) {
        if (param !== "") {
            this.setState({
                [paramToSet]: false,
            })
        } else {
            this.setState({
                [paramToSet]: true,
            })
        }
    }

    copyToClipboard() {
        this.setState({ copiedTextDone: true });
        var textField = document.createElement("textarea");
        textField.innerText = this.state.companyUrlRedirect;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand("copy");
        textField.remove();
        setTimeout(() => {
            this.setState({ copiedTextDone: false })
        }, 3000);
    }


    getFormattedDate(e) {
        if (e) {
            let splitted = e.split("-");
            splitted = splitted.reverse().join("-");
            return `${splitted}`;
        }
    }

    moveForPermissionAsking() {
        const { firstName, lastName, email } = this.state

        this.updateStateOnCondition(firstName, 'firstNameInvalid')
        this.updateStateOnCondition(lastName, 'lastNameInvalid')
        this.updateStateOnCondition(email, 'emailInvalid')

        if (this.checkForValuePresent(firstName) && this.checkForValuePresent(lastName) && this.checkForValuePresent(email)) {
            console.log("ask permission")
        }
    }


    render() {
        return (
            this.state.showMainLoader ? (
                <div
                    className="global-event-cart globaleventloaderpage"
                    style={{
                        height: "auto",
                        top: 0,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        zIndex: 999999999,
                    }}
                >
                    <div className="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    {window.innerWidth > 1008 && <Footer notOnMainPage={true} />}
                    {window.innerWidth < 1008 && <FooterGreen notOnMainPage={true} />}
                </div>
            ) : this.state.isNotActive ? (
                <div
                    className="global-event-cart globaleventloaderpage"
                    style={{
                        height: "auto",
                        top: 0,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        zIndex: 999999999,
                    }}
                >
                    <div className="link-not-found-box">
                        <p>Sorry, Link not found</p>
                    </div>
                    {window.innerWidth > 1008 && <Footer notOnMainPage={true} />}
                    {window.innerWidth < 1008 && <FooterGreen notOnMainPage={true} />}
                </div>
            ) : (<div className="global-event-cart globaleventcartfixedstickyfooter companysubscribepagelink companysubscribepagelinkbarber">
                {this.state.loadingAddress && (<div className="loader-fullpage-checkout">
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                </div>)}
                <div className="left-gec">
                    {/* {window.innerWidth > 1008 && (this.state.secondStep || this.state.thirdStep || this.state.fourthStep) && (<div className="button-back-lgec" onClick={() => {
                        history.goBack()
                    }}>
                        <ChevronLeftIcon />
                    </div>)} */}
                    {(<div className="container-lgec">
                        <div className="top-step-one-clgec">
                            {(<label>Hurrayy</label>)}
                            {(<label>Payment Link</label>)}
                            {(<label>
                                Leuk dat jullie willen meedoen met Bhodi!<br />
                                Controleer het e-mailadres en kies een wachtwoord.
                            </label>)}
                        </div>
                        <div className="forms-clgec">
                            <div className={`form-field-clgec ${this.state.firstNameInvalid ? "redalertffcl" : ""}`}>
                                <input
                                    placeholder='First Name'
                                    value={this.state.firstName}
                                    onChange={(e) => {
                                        this.setState({ firstName: e.target.value })
                                    }}
                                />
                            </div>
                            <div className={`form-field-clgec ${this.state.lastNameInvalid ? "redalertffcl" : ""}`}>
                                <input
                                    placeholder='Last Name'
                                    value={this.state.lastName}
                                    onChange={(e) => {
                                        this.setState({ lastName: e.target.value })
                                    }}
                                />
                            </div>
                            <div className={`form-field-clgec ${this.state.emailInvalid ? "redalertffcl" : ""}`}>
                                <input
                                    placeholder='E-mail'
                                    value={this.state.email}
                                    onChange={(e) => {
                                        this.setState({ email: e.target.value })
                                    }}
                                />
                            </div>
                            <div className="bottom-buttons-clgec">
                                {this.state.aanmeldenloader ? (
                                    window.innerWidth < 1008 && (
                                        <></>
                                    )
                                ) : (
                                    <button
                                        onClick={() => {
                                            this.moveForPermissionAsking()
                                        }}
                                    >
                                        Afronden
                                        {window.innerWidth < 1008 && <ChevronRightIcon />}
                                    </button>
                                )}
                                {this.state.aanmeldenloader && (
                                    <div className="lds-ellipsis">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>)}
                </div>
                {window.innerWidth > 1008 && (<div className="right-gec">

                </div>)}
                {window.innerWidth < 1009 && (
                    <div className="showtoopen-gec stg-updated-checkout">
                        <div className="popup-opener-stogec pos-inner-img">
                            {(this.state.secondStep || this.state.thirdStep || this.state.fourthStep) && (<div className="button-back-lgec" onClick={() => {
                                if (this.state.fourthStep) {
                                    this.setState({ fourthStep: false, thirdStep: true })
                                } else if (this.state.thirdStep) {
                                    this.setState({ thirdStep: false, secondStep: false })
                                } else {
                                    history.goBack()
                                }
                            }}>
                                <ChevronLeftIcon />
                            </div>)}
                            <img src={logoSubAdmin} alt='logo' width={50} />
                        </div>
                    </div>
                )}

                {window.innerWidth > 1008 && <Footer notOnMainPage={true} />}
                {window.innerWidth < 1008 && <FooterGreen notOnMainPage={true} />}
            </div>)
        )
    }
}


const mapStateToProps = (state) => {
    return {
        global_values: state.globalReducers.global_values,
        user: state.authReducers.user,
        admin_boolean: state.globalReducers.admin_boolean,
        subscription_plans: state.globalReducers.subscription_plans,
        campaigns_category: state.categoriesReducer.campaigns_category,
        categories_vouchers: state.categoriesReducer.categories_vouchers,
        stock_images: state.globalReducers.stock_images,
        globalsectors: state.categoriesReducer.globalsectors,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateAdminOrNot: (admin_boolean) => dispatch(updateAdminOrNot(admin_boolean)),
        updateGlobalStuff: (global_values) => dispatch(updateGlobalStuff(global_values)),
        updateAlbumToAdd: (albumToAdd) => dispatch(updateAlbumToAdd(albumToAdd)),
        updateCategoriesVouchers: (categories_vouchers) => dispatch(updateCategoriesVouchers(categories_vouchers)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PayViaLink);