import React, { Component } from 'react'
import { connect } from 'react-redux';
import {
    updateGlobalStuff,
    updateBhodiProducts,
} from "../../Redux/actions/authActions";
import CloseIcon from "@material-ui/icons/Close";
import firebase from "../../Config/Firebase"
import Footer from '../../Components/Footer';
import FooterGreen from '../../Components/FooterGreen';
import ExclusiveProductsEditor from '../../Components/ExclusiveProductsEditor';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { BiDownload } from 'react-icons/bi';
import * as XLSX from "xlsx";
import { MdClose } from 'react-icons/md';
import GuestProductEditor from '../../Components/GuestProductEditor';
import SearchIcon from "@material-ui/icons/Search";
import swal from "sweetalert";
var convert = require("xml-js")

export class ManualEventCode extends Component {

    constructor() {
        super()
        this.state = {
            editScreenOpen: true,
            allOrderUsers: [],
            allOrderUsersDuplicated: [],
            allOrderUsersFiltered: [],
            allEventCompanies: [],
            currentUserId: "",
            dropdownKeysCode: [],
            allCompaniesForEvents: [],
            allCompaniesForEventsFiltered: [],
            allCompaniesForEventsDuplicated: [],
            ordersOfCompany: "",
            completeCSVArrayCodes: [],
            ordersSelectedForEmail: [],
        }
    }

    async componentDidMount() {
        let userid = localStorage.getItem("userid");
        this.setState({ currentUserId: userid })
    }

    getCreatorImage(user_id) {
        if (user_id) {
            let filtered = this.props.user.filter((g) => {
                return g.user_id === user_id
            })
            if (filtered.length > 0) {
                if (filtered[0].profile_image) {
                    return filtered[0].profile_image;
                } else {
                    return "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9";
                }
            }
        }
    }

    getCreatorName(user_id) {
        if (user_id) {
            let filtered = this.props.user.filter((g) => {
                return g.user_id === user_id
            })
            if (filtered.length > 0) {
                if (filtered[0].type === "user") {
                    return filtered[0].first_name
                } else {
                    return filtered[0].company_name
                }
            }
        }
    }

    checkForDefaultSelectedStock(filteredOfKeys) {
        let checkCodePresent = filteredOfKeys.findIndex((e) => {
            return e.toLowerCase() === "code"
        })
        if (checkCodePresent !== -1) {
            this.setState({ codeImportField: filteredOfKeys[checkCodePresent] })
        } else {
            this.setState({ codeImportField: "" })
        }
    }

    fileUploadCodeImport(e) {
        e.preventDefault();
        if (e.target.files && e.target.files.length > 0) {
            if (e.target.files[0].type.includes("/xml")) {
                this.setState({ csvSelectedName: e.target.files[0].name, selectedXml: true })
                const reader = new FileReader();
                reader.onload = (e) => {
                    let readXml = e.target.result

                    let result = convert.xml2json(readXml, { compact: true, spaces: 4 })

                    let currentArray = JSON.parse(result)

                    if (currentArray.items && currentArray.items.item && currentArray.items.item.length > 0) {
                        let allKeys = [];
                        currentArray.items.item.map((e) => {
                            let keysOfObject = Object.keys(e);
                            keysOfObject.map((t) => {
                                allKeys.push(t);
                            });
                        });
                        let filteredOfKeys = allKeys.filter(
                            (v, i, a) => a.findIndex((t) => t === v) === i
                        );
                        this.checkForDefaultSelectedStock(filteredOfKeys)
                        this.setState({
                            dropdownKeysCode: filteredOfKeys,
                            completeCSVArrayCodes: currentArray.items.item,
                        });
                    }

                };
                reader.readAsText(e.target.files[0]);
                e.target.value = "";
            } else {
                this.setState({ csvSelectedName: e.target.files[0].name, selectedXml: false })
                const reader = new FileReader();
                reader.onload = (e) => {
                    const data = e.target.result;
                    const workbook = XLSX.read(data, { type: "array" });
                    const sheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[sheetName];
                    const json = XLSX.utils.sheet_to_json(worksheet);
                    let allKeys = [];

                    json.map((e) => {
                        let keysOfObject = Object.keys(e);
                        keysOfObject.map((t) => {
                            allKeys.push(t);
                        });
                    });
                    let filteredOfKeys = allKeys.filter(
                        (v, i, a) => a.findIndex((t) => t === v) === i
                    );
                    this.checkForDefaultSelectedStock(filteredOfKeys)
                    this.setState({
                        dropdownKeysCode: filteredOfKeys,
                        completeCSVArrayCodes: json,
                    });
                };
                reader.readAsArrayBuffer(e.target.files[0]);
                e.target.value = "";
            }
        }
    }

    saveAndAssignCodes() {
        const { codeImportField } = this.state

        let allCodes = []

        this.state.completeCSVArrayCodes.map((e) => {
            let code = ""

            if (this.state.selectedXml) {
                if (codeImportField) {
                    code = e[codeImportField]._text
                }
            } else {
                if (codeImportField) {
                    code = e[codeImportField]
                }
            }

            let obj = {
                code,
            }

            allCodes.push(obj)
        })

        if (this.state.allOrderUsersDuplicated.length <= allCodes.length) {
            let allOrdersToPush = this.state.allOrderUsersDuplicated.map((order, index) => {
                order.assigned_code = allCodes[index].code
                return order
            })

            allOrdersToPush.forEach((order) => {
                firebase.database().ref(`bhodi_campaigns/${order.creatorId}/${order.event_key}/checked_in_by/${order.pushKey}/assigned_code`).set(order.assigned_code)
            })

            swal({
                title: "Awesome",
                text: "Codes assigned successfully ",
                icon: "success",
            }).then(() => {
                this.loadOrders(allOrdersToPush[0].creatorId)
                this.setState({ codeImportPopup: false })
                document.querySelector("html").style.overflowY = "auto"
            })
        }

    }

    getFormattedDate(e) {
        if (e) {
            let splitted = e.split("-");
            splitted = splitted.reverse().join("-");
            return `${splitted}`;
        }
    }

    getAllStock(product) {
        let qty = 0;
        if (product.variations_data && product.variations_data.length > 0) {
            product.variations_data.map((t) => {
                qty += Number(t.qty)
            })
        } else {
            qty = product.qty
        }
        return qty
    }

    getBuyerName(uid) {
        let filtered = this.props.user.filter((g) => {
            return g.user_id === uid
        })
        if (filtered.length > 0) {
            if (filtered[0].type === "company") {
                return `${filtered[0].company_name}`
            } else {
                return `${filtered[0].first_name} ${filtered[0].last_name}`
            }
        }
    }

    getBuyerAddress(uid) {
        let filtered = this.props.user.filter((g) => {
            return g.user_id === uid
        })
        if (filtered.length > 0) {
            const { street, houseNumber, zipCode, city } = filtered[0]
            return `${street} ${houseNumber}, ${zipCode} ${city}`
        }
    }

    duplicateCurrentProduct(product) {
        let objToMake = Object.assign({}, product)
        delete objToMake.images

        let timestamp = Math.floor(Date.now() / 100);
        let storageKey = firebase.database().ref("bhodi_products").push().key;

        objToMake.created_at = new Date().getTime()
        objToMake.timestamp = timestamp
        objToMake.sort_time = timestamp
        objToMake.active = false
        objToMake.pushKey = storageKey

        firebase.database().ref("bhodi_products/" + storageKey).set(objToMake)

        let allProducts = [...this.props.bhodi_products]
        allProducts.push(objToMake)
        // this.props.updateBhodiProducts(allProducts)
    }

    loadOrders(company_id) {
        let currentCompanyEvent = this.props.global_campaigns.filter((camp) => {
            return camp[1].created_by === company_id && camp[1].campaignType === "local_event"
        })
        if (currentCompanyEvent.length > 0) {
            let event = currentCompanyEvent[0][1]
            if (event.checked_in_by) {
                let allOrders = Object.entries(event.checked_in_by).filter((order) => {
                    if (order[1].assigned_for_email) {
                        return false;
                    }
                    order[1].event_key = event.pushKey
                    order[1].creatorId = event.created_by
                    return order[1].order_key
                })
                let allOrdersOfEvents = allOrders.map((t) => t[1])

                this.setState({
                    allOrderUsers: allOrdersOfEvents,
                    allOrderUsersDuplicated: allOrdersOfEvents,
                    companySettingsPopup: false,
                    ordersOfCompany: company_id,
                    allCompaniesForEventsFiltered: [],
                })
            }
        }
    }

    sendEmails() {
        const { allOrderUsersDuplicated, ordersSelectedForEmail } = this.state

        if (ordersSelectedForEmail.length === 0) {
            return false;
        }

        let allOrdersToSendEmails = allOrderUsersDuplicated.filter((order) => {
            return ordersSelectedForEmail.includes(order.user_id)
        })

        allOrdersToSendEmails.forEach((order) => {
            if (!order.assigned_code) {
                return false;
            }
            let toSendEmailkey = firebase.database().ref("events_for_emails").push().key;

            let objToSendEmailValidate = {
                orderKey: order.order_key,
                pushKey: toSendEmailkey,
                created_at: new Date().toISOString(),
                event_key: order.event_key,
                buyer_id: order.user_id,
                send_email_at: new Date().toISOString()
            }

            firebase.database().ref(`bhodi_campaigns/${order.creatorId}/${order.event_key}/checked_in_by/${order.pushKey}/assigned_for_email`).set(true)
            firebase.database().ref(`all_orders/${order.order_key}/toSendEmailkey`).set(toSendEmailkey)
            firebase.database().ref(`all_orders/${order.order_key}/assigned_code`).set(order.assigned_code)
            firebase.database().ref("events_for_emails/" + toSendEmailkey).set(objToSendEmailValidate)
        })

        swal({
            title: "Awesome",
            text: "Emails will be sent in a while",
            icon: "success",
        }).then(() => {
            this.loadOrders(allOrdersToSendEmails[0].creatorId)
            this.setState({ ordersSelectedForEmail: [] })
        })
    }

    render() {
        return (
            this.state.guestEditorMain ? (
                <GuestProductEditor closePopup={() => {
                    this.setState({ guestEditorMain: false })
                }} />
            ) : this.state.guestEditor ? (
                <ExclusiveProductsEditor closePopup={() => {
                    this.setState({ guestEditor: false })
                }} />
            ) : <div>
                <button className="top-absolute-copied" style={{ opacity: this.state.copiedTextDone ? 1 : 0, pointerEvents: this.state.copiedTextDone ? "all" : "none" }}>Gekopieerd</button>
                <div className={`popup-main-neworder ${this.state.newOrderComing && "active"}`}>
                    {this.state.orderedProduct && this.state.productOrderedBy && (<div className='inner-panew'>
                        <MdClose onClick={() => {
                            this.setState({ newOrderComing: false })
                        }} />
                        <h3>CONGRATULATIONS</h3>
                        <p>{this.getBuyerName(this.state.productOrderedBy)} Just ordered Product {this.state.orderedProduct.title}</p>
                        <p>Delivery Address: {this.getBuyerAddress(this.state.productOrderedBy)}</p>
                    </div>)}
                </div>
                {this.state.companySettingsPopup && (<div className='right-menu-main-settings'>
                    <div className='rightmenuinner-rmmms updatedirghtmenunew newrightmenuglobalgood newrightmenucompaniesname'>
                        <div className='header-rmmi-rmms'>
                            <span>COMPANY</span>
                            <CloseIcon onClick={() => {
                                this.setState({ companySettingsPopup: false })
                                document.querySelector("html").style.overflowY = "auto"
                            }} />
                        </div>
                        <div className="search-rm-main">
                            <div className="wrapper-select-new">
                                <input type="text" placeholder="Zoek in je vriendenlijst"
                                    onChange={(e) => {
                                        if (e.target.value !== "") {
                                            let filtered = this.state.allCompaniesForEventsDuplicated.filter((t) => {
                                                return t.company_name.toLowerCase().includes(e.target.value.toLowerCase())
                                            })
                                            if (filtered.length > 0) {
                                                this.setState({ allCompaniesForEventsFiltered: filtered, noFiltersCompanies: false })
                                            } else {
                                                this.setState({ allCompaniesForEventsFiltered: [], noFiltersCompanies: true })
                                            }
                                        } else {
                                            this.setState({ allCompaniesForEventsFiltered: [], noFiltersCompanies: false, allCompaniesForEvents: this.state.allCompaniesForEventsDuplicated })
                                        }
                                    }}
                                />
                                <SearchIcon
                                    className="expand-more"
                                />
                            </div>
                        </div>
                        <div className='content-rmmi-rmms contentrmirmms-newcompanies'>
                            {this.state.noFiltersCompanies ? (<label>
                                No Filters Found
                            </label>) : this.state.allCompaniesForEventsFiltered.length > 0 ? this.state.allCompaniesForEventsFiltered.map((t) => {
                                return (<div
                                    className="form-miur"
                                    style={{ position: "relative" }}
                                >
                                    <input type="radio" name='companyselection' value={t.user_id} defaultChecked={this.state.ordersOfCompany === t.user_id} />
                                    <img src={t.profile_image ? t.profile_image : "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9"} />
                                    <label>
                                        {t.company_name}
                                    </label>
                                </div>)
                            }) : this.state.allCompaniesForEvents.map((t) => {
                                return (<div
                                    className="form-miur"
                                    style={{ position: "relative" }}
                                >
                                    <input type="radio" name='companyselection' value={t.user_id} defaultChecked={this.state.ordersOfCompany === t.user_id} />
                                    <img src={t.profile_image ? t.profile_image : "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9"} />
                                    <label>
                                        {t.company_name}
                                    </label>
                                </div>)
                            })}
                            <div className='save-button-main' style={{ marginTop: 40 }} onClick={() => {
                                let allElements = document.getElementsByName("companyselection")
                                if (allElements) {
                                    // Initialize a variable to store the selected value
                                    let selectedValue = "";

                                    // Loop through the radio buttons to find the selected one
                                    for (const radioButton of allElements) {
                                        if (radioButton.checked) {
                                            // Set the selected value to the value of the checked radio button
                                            selectedValue = radioButton.value;
                                            break; // Exit the loop once a checked radio button is found
                                        }
                                    }

                                    // Display the selected value (you can modify this part as needed)
                                    if (selectedValue !== "") {
                                        document.querySelector("html").style.overflowY = "auto"
                                        this.loadOrders(selectedValue)
                                    }
                                }
                            }}>
                                <button>OPSLAAN</button>
                            </div>
                        </div>
                    </div>
                </div>)}
                {this.state.codeImportPopup && (<div className='right-menu-main-settings'>
                    <div className='rightmenuinner-rmmms updatedirghtmenunew newrightmenuglobalgood'>
                        <div className='header-rmmi-rmms'>
                            <span>CODES IMPORT</span>
                            <CloseIcon onClick={() => {
                                document.querySelector("html").style.overflowY = "auto"
                                this.setState({ codeImportPopup: false })
                            }} />
                        </div>
                        <div className='content-rmmi-rmms'>
                            <div
                                className="form-miur"
                                style={{ position: "relative" }}
                            >
                                <div className='wrapper-select-new'>
                                    {/* <div className='wrapper-select-new wrapper-select-green'> */}
                                    <input value={this.state.csvSelectedNameStock ? this.state.csvSelectedNameStock : "Select file"} />
                                    <input type="file"
                                        style={{ position: "absolute", top: 0, right: 0, left: 0, bottom: 0, width: "100%", height: "100%", zIndex: 9999, cursor: "pointer", opacity: 0 }}
                                        accept=".csv, .xml, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        onChange={this.fileUploadCodeImport.bind(this)}
                                    />
                                    <BiDownload
                                        style={{
                                            position: "absolute",
                                            bottom: 5,
                                            right: 5,
                                            pointerEvents: "none",
                                        }}
                                        className='expand-more'
                                    />
                                </div>
                            </div>
                            <div
                                className="form-miur"
                                style={{ position: "relative" }}
                            >
                            </div>
                            {this.state.dropdownKeysCode.length > 0 ? <>
                                <div
                                    className="form-miur"
                                    style={{ position: "relative" }}
                                >
                                    <label>Code</label>
                                    <div className='wrapper-select-new'>
                                        <select value={this.state.codeImportField} onChange={(e) => {
                                            this.setState({ codeImportField: e.target.value })
                                        }}>
                                            <option value="" disabled selected hidden>Selecteer</option>
                                            {this.state.dropdownKeysCode.map((t) => {
                                                return <option value={t}>{t}</option>;
                                            })}
                                        </select>
                                        <ExpandMoreIcon
                                            style={{
                                                position: "absolute",
                                                bottom: 5,
                                                right: 5,
                                                pointerEvents: "none",
                                            }}
                                            className='expand-more'
                                        />
                                    </div>
                                    {this.state.skuAlertStock && (
                                        <label style={{ color: "red", marginTop: 5 }}>Verplicht veld</label>
                                    )}
                                </div>
                                <div className='save-button-main' style={{ marginTop: 40 }}>
                                    {this.state.uploadingStocks ? <button>SAVING</button> : (<button onClick={this.saveAndAssignCodes.bind(this)}>OPSLAAN</button>)}
                                </div>
                            </> : this.state.processingXmlStock ? <div className="loadMoreProducts">
                                <div className="lds-ring">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                                <label>Processing</label>
                            </div> : this.state.errorInXmlStock && <div className="loadMoreProducts">
                                <label>Sorry, not able to process XML</label>
                            </div>}
                        </div>
                    </div>
                </div>)}
                {this.state.editScreenOpen && (<div className="edit-screen-products" style={{ width: window.innerWidth < 1008 && "100%", paddingTop: 40 }}>
                    <div className='top-edit-screen-products'>
                        <CloseIcon onClick={() => this.setState({ editScreenOpen: false })} />
                        <label>ADJUSTMENTS</label>
                    </div>
                    <div className='buttons-top-main' style={{ justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>
                        <div style={{ display: "flex" }}>
                            <button style={{ padding: 0, backgroundColor: "transparent", color: "black", textAlign: window.innerWidth < 1008 && "left" }}>Total: {this.state.allOrderUsers.length}</button>
                            <div className='input-btm-products'>
                                <input type='text' placeholder='Search Orders' onChange={(e) => {
                                    if (e.target.value) {
                                        let filtered = [...this.state.allOrderUsers].filter((order) => {
                                            return this.getBuyerName(order.user_id).toLowerCase().includes(e.target.value.toLowerCase())
                                        })
                                        if (filtered.length > 0) {
                                            this.setState({
                                                allOrderUsersFiltered: filtered,
                                                noFilteredProducts: false,
                                                ordersSelectedForEmail: [],
                                            })
                                        } else {
                                            this.setState({
                                                noFilteredProducts: true,
                                                ordersSelectedForEmail: [],
                                            })
                                        }
                                    } else {
                                        this.setState({
                                            noFilteredProducts: false,
                                            allOrderUsersFiltered: [],
                                            ordersSelectedForEmail: [],
                                        })
                                    }
                                }} />
                            </div>
                        </div>
                        <div style={{ display: "flex", overflowX: window.innerWidth < 1008 && "auto" }} className='scroller-button-mobile'>
                            {window.innerWidth > 1008 && (<button onClick={() => this.setState({ deletingAllProduct: true })}>DELETE ALL PRODUCTS</button>)}
                            <button onClick={() => {
                                if (window.innerWidth > 1008) {
                                    document.querySelector("html").style.overflowY = "hidden"
                                    this.setState({ codeImportPopup: true })
                                } else {
                                    this.setState({ showOnlyDesktop: true })
                                }
                            }}>IMPORT CODES</button>
                            <button onClick={() => {
                                document.querySelector("html").style.overflowY = "hidden"
                                if (this.props.global_campaigns) {
                                    let filtered = this.props.global_campaigns.filter((g) => {
                                        return g[1].campaignType === "local_event" && g[1].manual_code_import
                                    })
                                    if (filtered.length > 0) {
                                        let allCompanyIds = filtered.map((camp) => camp[1].created_by)
                                        let filteredUids = allCompanyIds.filter(
                                            (v, i, a) => a.findIndex((t) => t === v) === i
                                        );
                                        if (this.props.user && filteredUids.length > 0) {
                                            let allCompanies = []
                                            filteredUids.map((uid) => {
                                                let findUser = this.props.user.find((user) => {
                                                    return user.user_id === uid
                                                })
                                                if (findUser) {
                                                    allCompanies.push(findUser)
                                                }
                                            })
                                            this.setState({ allCompaniesForEvents: allCompanies, allCompaniesForEventsDuplicated: allCompanies })
                                        }
                                    }
                                }
                                this.setState({ companySettingsPopup: true })
                            }}>COMPANY</button>
                            {this.state.ordersSelectedForEmail.length > 0 && <button onClick={() => {
                                this.sendEmails()
                            }}>Send Email</button>}
                        </div>
                    </div>
                    <div className='content-edit-screen-products' style={{ overflow: window.innerWidth < 1008 && "hidden" }}>
                        <div className='table-esproducts'>
                            <div className='table-heading-esproducts'>
                                <p>Image</p>
                                <p>Name</p>
                                <p>Assigned Code</p>
                                {this.state.allOrderUsersDuplicated.length > 0 && (<div className='checkbox-tces'>
                                    <div className='inner-ctces'>
                                        <input type="checkbox" name="email-sending" checked={this.state.ordersSelectedForEmail.length > 0 ? (this.state.allOrderUsersFiltered.length > 0 ? this.state.ordersSelectedForEmail.length === this.state.allOrderUsersFiltered.length : this.state.ordersSelectedForEmail.length === this.state.allOrderUsersDuplicated.length) : false} onChange={() => {
                                            // let allSelectionsDuplicate = [...this.state.ordersSelectedForEmail]
                                            // if (allSelectionsDuplicate.includes(order.user_id)) {
                                            //     allSelectionsDuplicate = allSelectionsDuplicate.filter((selection) => selection !== order.user_id)
                                            // } else {
                                            //     allSelectionsDuplicate.push(order.user_id)
                                            // }
                                            if (this.state.allOrderUsersFiltered.length > 0) {
                                                if (this.state.allOrderUsersFiltered.length === this.state.ordersSelectedForEmail.length && this.state.ordersSelectedForEmail.length > 0) {
                                                    this.setState({ ordersSelectedForEmail: [] })
                                                } else {
                                                    this.setState({ ordersSelectedForEmail: this.state.allOrderUsersFiltered.map((order) => order.user_id) })
                                                }
                                            } else {
                                                if (this.state.allOrderUsersDuplicated.length === this.state.ordersSelectedForEmail.length && this.state.ordersSelectedForEmail.length > 0) {
                                                    this.setState({ ordersSelectedForEmail: [] })
                                                } else {
                                                    this.setState({ ordersSelectedForEmail: this.state.allOrderUsersDuplicated.map((order) => order.user_id) })
                                                }
                                            }

                                        }} />
                                        <label>&nbsp;</label>
                                    </div>
                                </div>)}
                            </div>
                            <div className='table-content-esproducts-main'>
                                {this.state.noFilteredProducts ? <p>No available orders</p> : this.state.allOrderUsersFiltered.length > 0 ? [...this.state.allOrderUsersFiltered].map((order, index) => {
                                    return (<div className='table-content-esproducts'>
                                        <p>
                                            <div className='bg-img' style={{ backgroundImage: `url("${this.getCreatorImage(order.user_id)}")` }}>

                                            </div>
                                        </p>
                                        <p style={{ textTransform: "uppercase" }}>{this.getBuyerName(order.user_id)}</p>
                                        <p>{order.assigned_code || "Not Assigned"}</p>
                                        <div className='checkbox-tces'>
                                            <div className='inner-ctces'>
                                                <input type="checkbox" name="email-sending" checked={this.state.ordersSelectedForEmail.includes(order.user_id)} value={order.user_id} onChange={() => {
                                                    let allSelectionsDuplicate = [...this.state.ordersSelectedForEmail]
                                                    if (allSelectionsDuplicate.includes(order.user_id)) {
                                                        allSelectionsDuplicate = allSelectionsDuplicate.filter((selection) => selection !== order.user_id)
                                                    } else {
                                                        allSelectionsDuplicate.push(order.user_id)
                                                    }
                                                    this.setState({ ordersSelectedForEmail: allSelectionsDuplicate })
                                                }} />
                                                <label>&nbsp;</label>
                                            </div>
                                        </div>
                                    </div>)
                                }) : [...this.state.allOrderUsersDuplicated].map((order, index) => {
                                    return (<div className='table-content-esproducts'>
                                        <p>
                                            <div className='bg-img' style={{ backgroundImage: `url("${this.getCreatorImage(order.user_id)}")` }}>

                                            </div>
                                        </p>
                                        <p style={{ textTransform: "uppercase" }}>{this.getBuyerName(order.user_id)}</p>
                                        <p>{order.assigned_code || "Not Assigned"}</p>
                                        <div className='checkbox-tces'>
                                            <div className='inner-ctces'>
                                                <input type="checkbox" name="email-sending" checked={this.state.ordersSelectedForEmail.includes(order.user_id)} value={order.user_id} onChange={() => {
                                                    let allSelectionsDuplicate = [...this.state.ordersSelectedForEmail]
                                                    if (allSelectionsDuplicate.includes(order.user_id)) {
                                                        allSelectionsDuplicate = allSelectionsDuplicate.filter((selection) => selection !== order.user_id)
                                                    } else {
                                                        allSelectionsDuplicate.push(order.user_id)
                                                    }
                                                    this.setState({ ordersSelectedForEmail: allSelectionsDuplicate })
                                                }} />
                                                <label>&nbsp;</label>
                                            </div>
                                        </div>
                                    </div>)
                                })}
                            </div>
                        </div>
                    </div>
                </div>)}
                {window.innerWidth > 1008 && <Footer notOnMainPage={true} />}
                {window.innerWidth < 1008 && <FooterGreen notOnMainPage={true} />}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducers.user,
        global_campaigns: state.campaignsReducer.global_campaigns,
        images: state.postsReducers.images,
        global_values: state.globalReducers.global_values,
        admin_boolean: state.globalReducers.admin_boolean,
        bhodi_products: state.productsReducers.bhodi_products,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateGlobalStuff: (global_values) =>
            dispatch(updateGlobalStuff(global_values)),
        updateBhodiProducts: (bhodi_products) => dispatch(updateBhodiProducts(bhodi_products)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ManualEventCode);