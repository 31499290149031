import React, { Component } from 'react'
import { connect } from "react-redux";
import {
    updateBhodiFaqsGuests,
} from "../../Redux/actions/authActions";
import firebase from "../../Config/Firebase";

export class GuestFaqComponent extends Component {
    constructor() {
        super()
        this.state = {
            bhodiFaqsGuests: [],
            arrDropdownsGuests: [],
        }
    }

    componentDidMount() {
        if (this.props.bhodi_faqs_guests) {
            let a = this.props.bhodi_faqs_guests;
            if (a) {
                let arrSec = a;
                let arrDropdowns = [];
                arrSec.map(() => {
                    let obj = {
                        opened: false,
                    };
                    arrDropdowns.push(obj);
                });
                this.setState({ bhodiFaqsGuests: arrSec, arrDropdownsGuests: arrDropdowns });
            } else {
                this.setState({ bhodiFaqsGuests: [], arrDropdownsGuests: [] });
            }
        }

        firebase
            .database()
            .ref("bhodi_faqs_guests")
            .on("value", (data) => {
                let a = data.val();
                let arrSec = [];
                if (a) {
                    arrSec = a;
                    let arrDropdowns = [];
                    arrSec.map(() => {
                        let obj = {
                            opened: false,
                        };
                        arrDropdowns.push(obj);
                    });
                    this.setState({ bhodiFaqsGuests: arrSec, arrDropdownsGuests: arrDropdowns });
                } else {
                    this.setState({ bhodiFaqsGuests: [], arrDropdownsGuests: [] });
                }
                this.props.updateBhodiFaqsGuests(arrSec)
            });
    }

    render() {
        return (
            window.innerWidth > 1008 ? (
                <>
                    {this.state.bhodiFaqsGuests.length > 0 && (<>
                        <p style={{ cursor: "pointer" }} onClick={() => {
                            if (this.props.openPopup) {
                                this.props.openPopup(this.state.bhodiFaqsGuests, this.state.arrDropdownsGuests)
                            }
                        }}>vragen</p>
                        <p>|</p>
                    </>)}
                </>
            ) : (
                <>
                    {this.state.bhodiFaqsGuests.length > 0 && (<li className="nav-item" onClick={() => {
                        if (this.props.openPopup) {
                            this.props.openPopup(this.state.bhodiFaqsGuests, this.state.arrDropdownsGuests)
                        }
                    }}>
                        <a className="nav-link">Vragen</a>
                    </li>)}
                </>
            )
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducers.user,
        bhodi_faqs_guests: state.globalReducers.bhodi_faqs_guests,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateBhodiFaqsGuests: (bhodi_faqs_guests) => dispatch(updateBhodiFaqsGuests(bhodi_faqs_guests)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GuestFaqComponent);