import React, { Component } from "react";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "./index.css";
import firebase from "../../Config/Firebase";
import CloseIcon from "@material-ui/icons/Close";
import { connect } from "react-redux";
import { updateUser } from "../../Redux/actions/authActions";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import MailIcon from "@material-ui/icons/Mail";
import SearchIcon from "@material-ui/icons/Search";
import TimerIcon from "@material-ui/icons/Timer";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { BsFillBagCheckFill } from "react-icons/bs";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import Footer from "../../Components/Footer";
import { isDesktop } from "react-device-detect";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { MdFormatListNumbered } from "react-icons/md";
import Clipboard from "react-clipboard.js";
import BackTimer from "../../Components/BackTimer";
import Box from "@material-ui/core/Box";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { checkForSubscribedExclusives } from "../../Functions/checkForSubscribedExclusives";

export class MyOrders extends Component {
  constructor() {
    super();
    this.state = {
      modal: false,
      wholeData: "",
      arrPopup: [],
      isAdmin: false,
      heading: "",
      headingInput: "",
      arrPopup: [2, 21],
      incoming: false,
      outgoing: true,
      campaigns: false,
      campaignsOutgoing: false,
      arrOrders: [],
      arrPopup: [],
      outgoingProducts: [],
      ordersPresent: true,
      currentUserHere: "",
      vouchersOutgoing: false,
      vouchersIncoming: false,
      currentVoucherWork: "",
      showVoucherNow: false,
      currentUser: "",
      showFooter: false,
      currentUserOfPopups: "",
      redeem_code: "",
      ordersFiltered: [],
      currentFiltered: "",
      searchText: "",
      ordersFilteredDuplicate: [],
      counterMinute: 1,
      counterSecond: 59,
      verzilverPopupMobileGiveaway: false,
      verzilverPopupMobileKortingsVoucher: false,
      verzilverPopupMobileAnother: false,
      isOnLastMinuteOpened: false,
      noFiltersHere: false,
      verzilverPopupEventNoValidate: false,
      currentActie: "",
    };
  }

  componentDidMount() {
    const loaderId = document.getElementById("main-page-loader-id");
    loaderId.style.display = "none";

    if (
      window.location.pathname === "/order-list" ||
      window.location.pathname.includes("/my_orders")
    ) {
      this.setState({ showFooter: true });
    }

    // Products
    let uid = localStorage.getItem("userid");
    // firebase
    //   .database()
    //   .ref("users")
    //   .on("value", (data) => {
    //     let a = data.val();
    //     let arrUsers = [];
    //     if (a) {
    //       let entries = Object.entries(a);
    //       entries.map((f) => {
    //         arrUsers.push(f[1]);
    //         if (f[1].user_id === uid) {
    //           this.setState({ currentUserHere: f[1] });
    //         }
    //       });
    //       // this.props.updateUser(arrUsers)
    //     }
    //   });

    if (this.props.user) {
      let userFound = this.props.user.find((t) => {
        return t.user_id === uid
      })
      if (userFound) {
        if ((userFound.subscription && userFound.subscription.status === "active") || checkForSubscribedExclusives(userFound)) {
          this.setState({
            currentUserCharityMember: true
          })
        } else {
          this.setState({
            currentUserCharityMember: false
          })
        }
        this.setState({
          currentUserHere: userFound
        })
      }
    }

    this.setState({
      incoming: false,
      outgoing: true,
      campaigns: false,
      campaignsOutgoing: false,
      vouchersOutgoing: false,
      vouchersIncoming: false,
    });
    let fetched = new Promise((resolve, reject) => {
      firebase
        .database()
        .ref("all_orders")
        .on("value", (data) => {
          // firebase.database().ref("all_orders").orderByChild("buyer").equalTo(uid).on("value", (data) => {
          let arrCompleteOrders = [];
          let a = data.val();
          console.log(a, "/working");
          let allCampaigns = [];
          if (this.props.global_campaigns) {
            if (this.props.global_campaigns.length > 0) {
              this.props.global_campaigns.map((e) => {
                allCampaigns.push(e[0]);
              });
            }
          }
          console.log(allCampaigns, "/these keys");
          if (a) {
            let objected = Object.entries(a);
            let arrOrders = [];
            objected.map((g) => {
              arrCompleteOrders.push(g[1]);
              if (g[1].buyer === uid) {
                if (g[1].products) {
                  if (g[1].products.length > 0) {
                    if (!allCampaigns.includes(g[1].products[0].pushKey)) {
                      g[1].voucherDeleted = true;
                    }
                  }
                }
                arrOrders.push(g[1]);
              }
            });
            let arrPopup = [];
            arrOrders.map(() => {
              let obj = {
                popup: false,
              };
              arrPopup.push(obj);
            });
            arrOrders.reverse();
            resolve(arrOrders);
            console.log(arrOrders, "/orders array");
            this.setState({
              arrOrders,
              arrPopup,
              outgoingProducts: arrOrders,
              arrCompleteOrders,
              ordersFiltered: [],
              noFiltersHere: false,
              ordersFilteredDuplicate: [],
              currentFiltered: "",
            });
          } else {
            resolve([]);
            this.setState({
              arrOrders: [],
              arrPopup: [],
              arrCompleteOrders: [],
            });
          }
        });
    });
    fetched.then((e) => {
      if (e.length > 0) {
        this.setState({ ordersPresent: true });
      } else {
        this.setState({ ordersPresent: false });
      }
    });
  }

  getCompletePriceRegards(i) {
    let arrItems = this.state.arrOrders[i].products;
    let arrItemsCompleted = this.state.arrOrders[i].productCompleted;
    if (arrItems.length > 0) {
      Array.prototype.sum = function (prop) {
        var total = 0;
        for (var i = 0, _len = this.length; i < _len; i++) {
          total += Number(this[i][prop]) * arrItemsCompleted[i].cartQty;
        }
        return total;
      };
      return arrItems.sum("charity_price");
    }
  }

  getSumOfProductsLength(i) {
    let arrItems = this.state.arrOrders[i].productCompleted;
    if (arrItems.length > 0) {
      Array.prototype.sum = function (prop) {
        var total = 0;
        for (var i = 0, _len = this.length; i < _len; i++) {
          total += Number(this[i][prop]);
        }
        return total;
      };
      return arrItems.sum("cartQty");
    }
    // return 1
  }

  getBuyerName(f) {
    let arrItems = this.state.arrOrders[f];
    let filtered = this.props.user.filter((g) => {
      return g.user_id === arrItems.buyer;
    });
    if (filtered.length > 0) {
      if (filtered[0].type === "user") {
        return `${filtered[0].first_name} ${filtered[0].last_name}`;
      } else {
        return `${filtered[0].company_name}`;
      }
    }
  }

  getBuyerAddess(f) {
    let arrItems = this.state.arrOrders[f];
    let filtered = this.props.user.filter((g) => {
      return g.user_id === arrItems.buyer;
    });
    if (filtered.length > 0) {
      return `${filtered[0].street} ${filtered[0].houseNumber}`;
    }
  }

  getBuyerAddessSecond(f) {
    let arrItems = this.state.arrOrders[f];
    let filtered = this.props.user.filter((g) => {
      return g.user_id === arrItems.buyer;
    });
    if (filtered.length > 0) {
      return `${filtered[0].zipCode} ${filtered[0].city}`;
    }
  }

  getBuyerAddessThird(f) {
    let arrItems = this.state.arrOrders[f];
    let filtered = this.props.user.filter((g) => {
      return g.user_id === arrItems.buyer;
    });
    if (filtered.length > 0) {
      return `${filtered[0].email}`;
    }
  }

  getBuyerAddessFourth(f) {
    let arrItems = this.state.arrOrders[f];
    let filtered = this.props.user.filter((g) => {
      return g.user_id === arrItems.buyer;
    });
    if (filtered.length > 0) {
      return `${filtered[0].phoneNumber}`;
    }
  }

  getSellerName(e) {
    let filtered = this.props.user.filter((g) => {
      return g.user_id === e;
    });
    if (filtered.length > 0) {
      if (filtered[0].type === "user") {
        return `${filtered[0].first_name} ${filtered[0].last_name}`;
      } else {
        return `${filtered[0].company_name}`;
      }
    }
  }

  getSellerContact(e) {
    let filtered = this.props.user.filter((g) => {
      return g.user_id === e;
    });
    return `${filtered[0].email}`;
  }

  getSellerNameCampaign(e) {
    let filtered = this.props.user.filter((g) => {
      return g.user_id === e.created_by;
    });
    if (filtered.length > 0) {
      if (filtered[0].type === "user") {
        return `${filtered[0].first_name} ${filtered[0].last_name}`;
      } else {
        return `${filtered[0].company_name}, ${filtered[0].houseNumber} ${filtered[0].street}, ${filtered[0].zipCode} ${filtered[0].city}`;
      }
    }
  }

  getSellerNameCampaignOnly(e) {
    let filtered = this.props.user.filter((g) => {
      return g.user_id === e.created_by;
    });
    if (filtered.length > 0) {
      if (filtered[0].type === "user") {
        return `${filtered[0].first_name} ${filtered[0].last_name}`;
      } else {
        return `${filtered[0].company_name}`;
      }
    }
  }

  getSellerContactCampaign(e) {
    let filtered = this.props.user.filter((g) => {
      return g.user_id === e.created_by;
    });
    if (filtered.length > 0) {
      return `${filtered[0].email}`;
    }
  }

  getBuyerNameOutgoing(f) {
    let filtered = this.props.user.filter((g) => {
      return g.user_id === f;
    });
    if (filtered.length > 0) {
      if (filtered[0].type === "user") {
        return `${filtered[0].first_name} ${filtered[0].last_name}`;
      } else {
        return `${filtered[0].company_name}`;
      }
    }
  }

  getAddressOutgoing(f) {
    let filtered = this.props.user.filter((g) => {
      return g.user_id === f;
    });
    if (filtered.length > 0) {
      return `${filtered[0].street} ${filtered[0].houseNumber}`;
    }
  }

  getAddressOutgoingSecond(f) {
    let filtered = this.props.user.filter((g) => {
      return g.user_id === f;
    });
    if (filtered.length > 0) {
      return `${filtered[0].zipCode} ${filtered[0].city}`;
    }
  }

  getAddressOutgoingEmail(f) {
    let filtered = this.props.user.filter((g) => {
      return g.user_id === f;
    });
    if (filtered.length > 0) {
      return `${filtered[0].email}`;
    }
  }

  getAddressOutgoingPhone(f) {
    let filtered = this.props.user.filter((g) => {
      return g.user_id === f;
    });
    if (filtered.length > 0) {
      return `${filtered[0].phoneNumber}`;
    }
  }

  // checkForExpireText(e) {
  //     if (e.saving_it) {
  //         let splitted = e.created_on.split("/")
  //         let newDate = new Date(Number(splitted[2]), Number(splitted[0]), Number(splitted[1]))
  //         newDate.setDate(newDate.getDate() + e.products[0].expireAfter)
  //         if (new Date().getMonth() + 1 === newDate.getMonth()) {
  //             if (newDate.getDate() < new Date().getDate()) {
  //                 return <div className="bottom-row">
  //                     <div className="order-detail-status">
  //                         <span>Geldigheidsdatum is verlopen</span>
  //                     </div>
  //                 </div>
  //             } else {
  //                 return <div className="bottom-row">
  //                     <div className="order-detail-status">
  //                         <span>Voucher geldig t/m {newDate.getDate()}-{newDate.getMonth()}-{newDate.getFullYear()}</span>
  //                     </div>
  //                 </div>
  //             }
  //         } else {
  //             return <div className="bottom-row">
  //                 <div className="order-detail-status">
  //                     <span>Voucher geldig t/m {newDate.getDate()}-{newDate.getMonth()}-{newDate.getFullYear()}</span>
  //                 </div>
  //             </div>
  //         }
  //     }
  // }

  getMonthName(i) {
    i = Number(i);
    if (i === 1) {
      return "Januari";
    } else if (i === 2) {
      return "Februari";
    } else if (i === 3) {
      return "Maart";
    } else if (i === 4) {
      return "April";
    } else if (i === 5) {
      return "Mei";
    } else if (i === 6) {
      return "Juni";
    } else if (i === 7) {
      return "Juli";
    } else if (i === 8) {
      return "Augustus";
    } else if (i === 9) {
      return "September";
    } else if (i === 10) {
      return "Oktober";
    } else if (i === 11) {
      return "November";
    } else if (i === 12) {
      return "December";
    }
  }

  getPerfectDate(e) {
    if (e) {
      let splitted = e.split("/");
      let joint = `${splitted[1]} ${this.getMonthName(splitted[0])} ${splitted[2]
        }`;
      return joint;
    }
  }

  // checkForButtonPopup(order) {
  //   let uid = localStorage.getItem("userid");

  //   if (order.order_type === "campaign") {
  //     if (!order.voucherDeleted) {
  //       // if (order.products[0].campaignType !== "charity") {
  //       // if (order.products[0].campaignType !== "charity") {
  //       if (order.products[0].campaignType === "local_event") {
  //         if (order.products[0].timings) {
  //           if (order.products[0].timings.length > 0) {
  //             let filtered = order.products[0].timings.filter((j) => {
  //               return j.eventId === order.products[0].reserved_event_id;
  //             });
  //             if (filtered.length > 0) {
  //               let dateMax = new Date(filtered[0].endDateEvent);
  //               let endTimeGood = filtered[0].endTimeEvent.concat(":00");
  //               dateMax.setHours(endTimeGood.split(":")[0]);
  //               dateMax.setMinutes(endTimeGood.split(":")[1]);
  //               let dateMaxStart = new Date(filtered[0].startDateEvent);
  //               let endTimeGoodStart = filtered[0].startTimeEvent.concat(":00");
  //               dateMaxStart.setHours(endTimeGoodStart.split(":")[0]);
  //               dateMaxStart.setMinutes(endTimeGoodStart.split(":")[1]);
  //               let currentDate = new Date();
  //               if (order.isRedeemed) {
  //                 return (
  //                   <button
  //                     className="button-valideercode"
  //                     style={{
  //                       backgroundColor: "#C17F6B",
  //                       position: "absolute",
  //                       right: 25,
  //                       top: 77,
  //                       color: "white",
  //                       fontSize: 13,
  //                       letterSpacing: 1,
  //                       borderRadius: 4,
  //                       border: "none",
  //                       padding: "8px 12px",
  //                     }}
  //                   >
  //                     GEBRUIKT
  //                   </button>
  //                 );
  //               } else {
  //                 if (currentDate.getTime() < dateMax.getTime()) {
  //                   // if (currentDate.getTime() > dateMaxStart.getTime() && currentDate.getTime() < dateMax.getTime()) {
  //                   if (currentDate.getTime() > dateMaxStart.getTime()) {
  //                     return (
  //                       <button
  //                         className="button-valideercode"
  //                         style={{
  //                           backgroundColor: "#285151",
  //                           position: "absolute",
  //                           right: 25,
  //                           top: 77,
  //                           color: "white",
  //                           fontSize: 13,
  //                           letterSpacing: 1,
  //                           borderRadius: 4,
  //                           border: "none",
  //                           padding: "8px 12px",
  //                         }}
  //                         onClick={() => {
  //                           let currentUser = this.props.user.filter((h) => {
  //                             return h.user_id === order.products[0].created_by;
  //                           });
  //                           let checkCampaign =
  //                             this.props.global_campaigns.filter((e) => {
  //                               return (
  //                                 e[1].pushKey === order.products[0].pushKey
  //                               );
  //                             });
  //                           let f = checkCampaign[0][1];
  //                           if (this.state.showFooter) {
  //                             if (!isDesktop) {
  //                               this.setState({
  //                                 verzilverPopupMobileEvents: true,
  //                                 isOnLastMinuteOpened: true,
  //                                 currentUserOfPopups: currentUser[0],
  //                                 currentActie: order.products[0],
  //                               });
  //                               firebase
  //                                 .database()
  //                                 .ref(
  //                                   "all_orders/" +
  //                                   order.pushKey +
  //                                   "/isRedeemed"
  //                                 )
  //                                 .set(true);
  //                               firebase
  //                                 .database()
  //                                 .ref(
  //                                   "all_orders/" +
  //                                   order.pushKey +
  //                                   "/orderAlreadyCounted"
  //                                 )
  //                                 .set(true);
  //                               firebase
  //                                 .database()
  //                                 .ref(
  //                                   "all_orders/" +
  //                                   order.pushKey +
  //                                   "/redeemed_at"
  //                                 )
  //                                 .set(new Date().getTime());

  //                               if (checkCampaign.length > 0) {
  //                                 if (order.products[0].child_menu_id) {
  //                                   let checkCampaignInner =
  //                                     this.props.global_campaigns.filter(
  //                                       (e) => {
  //                                         return (
  //                                           e[1].pushKey ===
  //                                           order.products[0].child_menu_id
  //                                         );
  //                                       }
  //                                     );
  //                                   if (checkCampaignInner.length > 0) {
  //                                     if (
  //                                       checkCampaignInner[0][1].checked_in_by
  //                                     ) {
  //                                       let objectedInner = Object.entries(
  //                                         checkCampaignInner[0][1].checked_in_by
  //                                       );
  //                                       let filteredVerzilveredInner =
  //                                         objectedInner.filter((aa) => {
  //                                           return aa[1].user_id === uid;
  //                                         });
  //                                       firebase
  //                                         .database()
  //                                         .ref(
  //                                           `bhodi_campaigns/${checkCampaignInner[0][1].created_by}/${checkCampaignInner[0][1].pushKey}/checked_in_by/${filteredVerzilveredInner[0][0]}`
  //                                         )
  //                                         .remove();
  //                                     }
  //                                     if (
  //                                       checkCampaignInner[0][1]
  //                                         .extraStockPersons
  //                                     ) {
  //                                       firebase
  //                                         .database()
  //                                         .ref(
  //                                           `bhodi_campaigns/${checkCampaignInner[0][1].created_by}/${checkCampaignInner[0][1].pushKey}/extraStockPersons`
  //                                         )
  //                                         .set(
  //                                           Number(
  //                                             checkCampaignInner[0][1]
  //                                               .extraStockPersons
  //                                           ) - 1
  //                                         );
  //                                     }
  //                                   }
  //                                 }

  //                                 if (checkCampaign[0][1].checked_in_by) {
  //                                   let objected = Object.entries(
  //                                     checkCampaign[0][1].checked_in_by
  //                                   );
  //                                   let filteredVerzilvered = objected.filter(
  //                                     (aa) => {
  //                                       return aa[1].user_id === uid;
  //                                     }
  //                                   );
  //                                   if (filteredVerzilvered.length > 0) {
  //                                     firebase
  //                                       .database()
  //                                       .ref(
  //                                         `bhodi_campaigns/${order.products[0].created_by}/${order.products[0].pushKey}/checked_in_by/${filteredVerzilvered[0][0]}`
  //                                       )
  //                                       .remove();

  //                                     let timings = f.timings;
  //                                     f.timings.map((e, i) => {
  //                                       if (
  //                                         e.eventId ===
  //                                         order.products[0].reserved_event_id
  //                                       ) {
  //                                         let personsAmountAdded =
  //                                           Number(e.personsAmountEvent) - 1;
  //                                         if (personsAmountAdded < 10) {
  //                                           timings[
  //                                             i
  //                                           ].personsAmountEvent = `0${personsAmountAdded}`;
  //                                         } else {
  //                                           timings[
  //                                             i
  //                                           ].personsAmountEvent = `${personsAmountAdded}`;
  //                                         }
  //                                       }
  //                                     });
  //                                     firebase
  //                                       .database()
  //                                       .ref(
  //                                         "bhodi_campaigns/" +
  //                                         f.created_by +
  //                                         "/" +
  //                                         f.pushKey +
  //                                         "/timings"
  //                                       )
  //                                       .set(timings);
  //                                   }
  //                                 }
  //                               }
  //                             } else {
  //                               this.setState({ validOnMobilePopup: true });
  //                             }
  //                           } else {
  //                             if (window.innerWidth > 1009) {
  //                               this.props.showVerzilverLastMinutePopup(
  //                                 currentUser[0]
  //                               );
  //                               if (!isDesktop) {
  //                                 firebase
  //                                   .database()
  //                                   .ref(
  //                                     "all_orders/" +
  //                                     order.pushKey +
  //                                     "/isRedeemed"
  //                                   )
  //                                   .set(true);
  //                                 firebase
  //                                   .database()
  //                                   .ref(
  //                                     "all_orders/" +
  //                                     order.pushKey +
  //                                     "/orderAlreadyCounted"
  //                                   )
  //                                   .set(true);
  //                                 firebase
  //                                   .database()
  //                                   .ref(
  //                                     "all_orders/" +
  //                                     order.pushKey +
  //                                     "/redeemed_at"
  //                                   )
  //                                   .set(new Date().getTime());

  //                                 if (checkCampaign.length > 0) {
  //                                   if (checkCampaign[0][1].checked_in_by) {
  //                                     let objected = Object.entries(
  //                                       checkCampaign[0][1].checked_in_by
  //                                     );
  //                                     let filteredVerzilvered = objected.filter(
  //                                       (aa) => {
  //                                         return aa[1].user_id === uid;
  //                                       }
  //                                     );
  //                                     if (filteredVerzilvered.length > 0) {
  //                                       firebase
  //                                         .database()
  //                                         .ref(
  //                                           `bhodi_campaigns/${order.products[0].created_by}/${order.products[0].pushKey}/checked_in_by/${filteredVerzilvered[0][0]}`
  //                                         )
  //                                         .remove();
  //                                     }
  //                                   }
  //                                 }
  //                               }
  //                             } else {
  //                               this.setState({
  //                                 verzilverPopupMobileEvents: true,
  //                                 isOnLastMinuteOpened: true,
  //                                 currentUserOfPopups: currentUser[0],
  //                                 currentActie: order.products[0],
  //                               });
  //                               firebase
  //                                 .database()
  //                                 .ref(
  //                                   "all_orders/" +
  //                                   order.pushKey +
  //                                   "/isRedeemed"
  //                                 )
  //                                 .set(true);
  //                               firebase
  //                                 .database()
  //                                 .ref(
  //                                   "all_orders/" +
  //                                   order.pushKey +
  //                                   "/orderAlreadyCounted"
  //                                 )
  //                                 .set(true);
  //                               firebase
  //                                 .database()
  //                                 .ref(
  //                                   "all_orders/" +
  //                                   order.pushKey +
  //                                   "/redeemed_at"
  //                                 )
  //                                 .set(new Date().getTime());

  //                               if (checkCampaign.length > 0) {
  //                                 if (checkCampaign[0][1].checked_in_by) {
  //                                   let objected = Object.entries(
  //                                     checkCampaign[0][1].checked_in_by
  //                                   );
  //                                   let filteredVerzilvered = objected.filter(
  //                                     (aa) => {
  //                                       return aa[1].user_id === uid;
  //                                     }
  //                                   );
  //                                   if (filteredVerzilvered.length > 0) {
  //                                     firebase
  //                                       .database()
  //                                       .ref(
  //                                         `bhodi_campaigns/${order.products[0].created_by}/${order.products[0].pushKey}/checked_in_by/${filteredVerzilvered[0][0]}`
  //                                       )
  //                                       .remove();
  //                                   }
  //                                 }
  //                               }
  //                             }
  //                           }
  //                         }}
  //                       >
  //                         VALIDEREN
  //                       </button>
  //                     );
  //                   } else {
  //                     return (
  //                       <button
  //                         className="button-valideercode"
  //                         style={{
  //                           backgroundColor: "#285151",
  //                           position: "absolute",
  //                           right: 25,
  //                           top: 77,
  //                           color: "white",
  //                           fontSize: 13,
  //                           letterSpacing: 1,
  //                           borderRadius: 4,
  //                           border: "none",
  //                           padding: "8px 12px",
  //                         }}
  //                         onClick={() => {
  //                           let currentUser = this.props.user.filter((h) => {
  //                             return h.user_id === order.products[0].created_by;
  //                           });
  //                           if (this.state.showFooter) {
  //                             if (!isDesktop) {
  //                               this.setState({
  //                                 verzilverPopupEventNoValidate: true,
  //                                 isOnLastMinuteOpened: true,
  //                                 currentUserOfPopups: currentUser[0],
  //                                 currentActie: order.products[0],
  //                               });
  //                             } else {
  //                               this.setState({ validOnMobilePopup: true });
  //                             }
  //                           } else {
  //                             if (window.innerWidth > 1009) {
  //                               this.props.showVerzilverLastMinutePopup(
  //                                 currentUser[0]
  //                               );
  //                             } else {
  //                               this.setState({
  //                                 verzilverPopupEventNoValidate: true,
  //                                 isOnLastMinuteOpened: true,
  //                                 currentUserOfPopups: currentUser[0],
  //                                 currentActie: order.products[0],
  //                               });
  //                             }
  //                           }
  //                         }}
  //                       >
  //                         VALIDEREN
  //                       </button>
  //                     );
  //                   }
  //                 } else {
  //                   return (
  //                     <button
  //                       className="button-valideercode"
  //                       style={{
  //                         backgroundColor: "#C17F6B",
  //                         position: "absolute",
  //                         right: 25,
  //                         top: 77,
  //                         color: "white",
  //                         fontSize: 13,
  //                         letterSpacing: 1,
  //                         borderRadius: 4,
  //                         border: "none",
  //                         padding: "8px 12px",
  //                       }}
  //                     >
  //                       NIET GEVALIDEERD
  //                     </button>
  //                   );
  //                 }
  //               }
  //             }
  //           }
  //         }
  //       }
  //       // }
  //     }
  //   }
  // }
  checkForButtonPopup(order) {
    let uid = localStorage.getItem("userid");

    if (order.order_type === "campaign") {
      if (!order.voucherDeleted) {
        if (order.products[0].campaignType === "local_event") {
          if (order.products[0].customExpireTypeThreeMonth) {
            let dateOfOrderRedeemMax = new Date(order.timeStampCreated);
            dateOfOrderRedeemMax.setMonth(dateOfOrderRedeemMax.getMonth() + 3)

            if (order.isRedeemed) {
              return (
                <button
                  className="button-valideercode"
                  style={{
                    backgroundColor: "#C17F6B",
                    color: "white",
                    fontSize: 13,
                    letterSpacing: 1,
                    borderRadius: 4,
                    border: "none",
                    padding: "8px 12px",
                  }}
                >
                  GEBRUIKT
                </button>
              );
            } else {
              if (dateOfOrderRedeemMax.getTime() > new Date().getTime()) {
                return (
                  <button
                    className="button-valideercode"
                    style={{
                      backgroundColor: "#285151",
                      color: "white",
                      fontSize: 13,
                      letterSpacing: 1,
                      borderRadius: 4,
                      border: "none",
                      padding: "8px 12px",
                    }}
                    onClick={() => {
                      let currentUser = this.props.user.filter((h) => {
                        return h.user_id === order.products[0].created_by;
                      });
                      if (this.state.showFooter) {
                        if (this.state.currentUserCharityMember) {
                          let checkCampaign = this.props.global_campaigns.filter((e) => {
                            return e[1].pushKey === order.products[0].pushKey;
                          });

                          this.setState({
                            verzilverPopupMobileEvents: true,
                            currentOrderSelected: "",
                            isOnLastMinuteOpened: true,
                            currentUserOfPopups: currentUser[0],
                            currentActie: order.products[0],
                            currentOrderValidate: order,
                            campaignCheck: checkCampaign,
                          });
                        } else {
                          this.setState({ nonCharityMemberAlert: true });
                        }
                      }
                    }}
                  >
                    VALIDEREN
                  </button>
                );
              } else {
                return (
                  <button
                    className="button-valideercode"
                    style={{
                      backgroundColor: "#C17F6B",
                      color: "white",
                      fontSize: 13,
                      letterSpacing: 1,
                      borderRadius: 4,
                      border: "none",
                      padding: "8px 12px",
                    }}
                  >
                    NIET GEVALIDEERD
                  </button>
                );
              }
            }

          } else if (order.products[0].timings) {
            if (order.products[0].timings.length > 0) {
              let filtered = order.products[0].timings.filter((j) => {
                return j.eventId === order.products[0].reserved_event_id;
              });
              let checkCampaign = this.props.global_campaigns.filter((e) => {
                return e[1].pushKey === order.products[0].pushKey;
              });
              let f = checkCampaign[0][1];
              if (filtered.length > 0) {
                let dateMax = new Date(filtered[0].endDateEvent);
                if (filtered[0].isoStartTime && filtered[0].isoEndTime) {
                  dateMax = new Date(Date.parse(filtered[0].isoEndTime))
                } else {
                  let endTimeGood = filtered[0].endTimeEvent.concat(":00");
                  dateMax.setHours(endTimeGood.split(":")[0]);
                  dateMax.setMinutes(endTimeGood.split(":")[1]);
                }
                let dateMaxStart = new Date(filtered[0].startDateEvent);
                if (filtered[0].isoStartTime && filtered[0].isoEndTime) {
                  dateMaxStart = new Date(Date.parse(filtered[0].isoStartTime))
                } else {
                  let endTimeGoodStart = filtered[0].startTimeEvent.concat(":00");
                  dateMaxStart.setHours(endTimeGoodStart.split(":")[0]);
                  dateMaxStart.setMinutes(endTimeGoodStart.split(":")[1]);
                }
                let currentDate = new Date();
                if (order.isRedeemed) {
                  return (
                    <button
                      className="button-valideercode"
                      style={{
                        backgroundColor: "#C17F6B",
                        color: "white",
                        fontSize: 13,
                        letterSpacing: 1,
                        borderRadius: 4,
                        border: "none",
                        padding: "8px 12px",
                      }}
                    >
                      GEBRUIKT
                    </button>
                  );
                } else {
                  if (currentDate.getTime() < dateMax.getTime()) {
                    if (currentDate.getTime() > dateMaxStart.getTime()) {
                      return (
                        <button
                          className="button-valideercode"
                          style={{
                            backgroundColor: "#285151",
                            color: "white",
                            fontSize: 13,
                            letterSpacing: 1,
                            borderRadius: 4,
                            border: "none",
                            padding: "8px 12px",
                          }}
                          onClick={() => {
                            let currentUser = this.props.user.filter((h) => {
                              return h.user_id === order.products[0].created_by;
                            });
                            if (this.state.showFooter) {
                              if (this.state.currentUserCharityMember) {
                                this.setState({
                                  verzilverPopupMobileEvents: true,
                                  currentOrderSelected: "",
                                  isOnLastMinuteOpened: true,
                                  currentUserOfPopups: currentUser[0],
                                  currentActie: order.products[0],
                                  currentOrderValidate: order,
                                  campaignCheck: checkCampaign,
                                });
                              } else {
                                this.setState({ nonCharityMemberAlert: true });
                              }
                            }
                          }}
                        >
                          VALIDEREN
                        </button>
                      );
                    } else {
                      return (
                        <button
                          className="button-valideercode"
                          style={{
                            backgroundColor: "#285151",
                            color: "white",
                            fontSize: 13,
                            letterSpacing: 1,
                            borderRadius: 4,
                            border: "none",
                            padding: "8px 12px",
                          }}
                          onClick={() => {
                            let currentUser = this.props.user.filter((h) => {
                              return h.user_id === order.products[0].created_by;
                            });
                            if (this.state.showFooter) {
                              if (this.state.currentUserCharityMember) {
                                this.setState({
                                  verzilverPopupEventNoValidate: true,
                                  isOnLastMinuteOpened: true,
                                  currentUserOfPopups: currentUser[0],
                                  currentActie: order.products[0],
                                });
                              } else {
                                this.setState({ nonCharityMemberAlert: true });
                              }
                            }
                          }}
                        >
                          VALIDEREN
                        </button>
                      );
                    }
                  } else {
                    return (
                      <button
                        className="button-valideercode"
                        style={{
                          backgroundColor: "#C17F6B",
                          color: "white",
                          fontSize: 13,
                          letterSpacing: 1,
                          borderRadius: 4,
                          border: "none",
                          padding: "8px 12px",
                        }}
                      >
                        NIET GEVALIDEERD
                      </button>
                    );
                  }
                }
              }
            }
          }
        } else {
          if (order.timeStampCreated) {
            let dateOfOrderRedeemMax = new Date(order.timeStampCreated);
            if (order.products[0].expireTypeVoucher && order.products[0].expireDateVoucher) {
              let operation = order.products[0].expireTypeVoucher
              let unitsToAdd = Number(order.products[0].expireDateVoucher)
              if (operation === 'daily') {
                dateOfOrderRedeemMax.setDate(dateOfOrderRedeemMax.getDate() + unitsToAdd);
              } else if (operation === 'weekly') {
                dateOfOrderRedeemMax.setDate(dateOfOrderRedeemMax.getDate() + (unitsToAdd * 7));
              } else if (operation === 'monthly') {
                dateOfOrderRedeemMax.setMonth(dateOfOrderRedeemMax.getMonth() + unitsToAdd);
              }
            } else {
              // dateOfOrderRedeemMax.setTime(
              //   dateOfOrderRedeemMax.getTime() + 3 * 60000
              // );
              dateOfOrderRedeemMax.setDate(dateOfOrderRedeemMax.getDate() + 7);
            }
            if (order.isRedeemed) {
              return (
                <>
                  <button
                    className="button-valideercode"
                    style={{
                      backgroundColor: "#C17F6B",
                      color: "white",
                      fontSize: 13,
                      letterSpacing: 1,
                      borderRadius: 4,
                      border: "none",
                      padding: "8px 12px",
                    }}
                  >
                    GEBRUIKT
                  </button>
                  {order.products[0].campaignType === "web_shop" && order.products[0].voucherWebshopUrl && (<a className="link-withbtnurlwebshop" href={order.products[0].voucherWebshopUrl} target="_blank">Bezoek webshop</a>)}
                </>
              );
            } else {
              if (!order.stockReturned) {
                if (order.products[0].campaignType === "web_shop" && order.products[0].isoEndTime && new Date(Date.parse(order.products[0].isoEndTime)).getTime() < new Date().getTime()) {
                  return false;
                }
                return (
                  <>
                    <button
                      className="button-valideercode"
                      style={{
                        backgroundColor: "#285151",
                        color: "white",
                        fontSize: 13,
                        letterSpacing: 1,
                        borderRadius: 4,
                        border: "none",
                        padding: "8px 12px",
                      }}
                      onClick={() => {
                        let currentUser = this.props.user.filter((h) => {
                          return h.user_id === order.products[0].created_by;
                        });
                        if (currentUser.length > 0) {
                          if (this.state.showFooter) {
                            let e = currentUser[0];
                            let checkCampaign = this.props.global_campaigns.filter((e) => {
                              return e[1].pushKey === order.products[0].pushKey;
                            });
                            if (order.products[0].campaignType === "kortings_voucher") {
                              if (this.state.currentUserCharityMember) {
                                if (!isDesktop) {
                                  this.setState({
                                    verzilverPopupMobileKortingsVoucher: true,
                                    currentOrderSelected: "",
                                    currentUserOfPopups: e,
                                    currentActie: order.products[0],
                                    currentOrderValidate: order,
                                    campaignCheck: checkCampaign,
                                  });
                                } else {
                                  this.setState({ validOnMobilePopup: true });
                                }
                              } else {
                                this.setState({ nonCharityMemberAlert: true });
                              }
                            } else if (order.products[0].campaignType === "web_shop") {
                              this.setState({
                                verzilverPopupWebShopNew: true,
                                currentOrderSelected: "",
                                currentUserOfPopups: e,
                                redeem_code: order.products[0].redeemed_code,
                                currentActie: order.products[0],
                                currentOrderValidate: order,
                                campaignCheck: checkCampaign,
                              });
                            }
                          }
                        }
                      }}
                    >
                      VALIDEREN
                    </button>
                    {order.products[0].campaignType === "web_shop" && order.products[0].voucherWebshopUrl && (<a className="link-withbtnurlwebshop" href={order.products[0].voucherWebshopUrl} target="_blank">Bezoek webshop</a>)}
                  </>
                );
              } else {
                return (
                  <>
                    <button
                      className="button-valideercode"
                      style={{
                        backgroundColor: "#C17F6B",
                        color: "white",
                        fontSize: 13,
                        letterSpacing: 1,
                        borderRadius: 4,
                        border: "none",
                        padding: "8px 12px",
                      }}
                    >
                      NIET GEVALIDEERD
                    </button>
                    {order.products[0].campaignType === "web_shop" && order.products[0].voucherWebshopUrl && (<a className="link-withbtnurlwebshop" href={order.products[0].voucherWebshopUrl} target="_blank">Bezoek webshop</a>)}
                  </>
                );
              }
            }
          }
        }
        // }
      }
    }
  }

  checkForGebruikTextRight(order) {
    if (order.order_type === "campaign") {
      if (order.products[0].campaignType !== "live_event") {
        if (order.products[0].campaignType === "live_event_own") {
          return (
            <div
              className="order-arrow orderarrowtexting"
              style={{ width: 200 }}
            >
              <div
                className="dot-inner-order-image"
                style={{ backgroundColor: "#285151" }}
              ></div>
              <p>Gebruikt</p>
            </div>
          );
          return false;
        }
        if (
          order.products[0].campaignType === "last_minute" ||
          order.products[0].campaignType === "local_event"
        ) {
          if (order.products[0].timings) {
            if (order.products[0].timings.length > 0) {
              let filtered = order.products[0].timings.filter((j) => {
                return j.eventId === order.products[0].reserved_event_id;
              });
              if (filtered.length > 0) {
                // let dateMax = new Date(filtered[0].endDateEvent)
                // let endTimeGood = filtered[0].endTimeEvent.concat(":00")
                // dateMax.setHours(endTimeGood.split(":")[0])
                // dateMax.setMinutes(endTimeGood.split(":")[1])
                let dateMax = new Date(filtered[0].endDateEvent);
                let endTimeGood = filtered[0].endTimeEvent.concat(":00");
                dateMax.setHours(endTimeGood.split(":")[0]);
                dateMax.setMinutes(endTimeGood.split(":")[1]);
                let dateMaxStart = new Date(filtered[0].startDateEvent);
                let endTimeGoodStart = filtered[0].startTimeEvent.concat(":00");
                dateMaxStart.setHours(endTimeGoodStart.split(":")[0]);
                dateMaxStart.setMinutes(endTimeGoodStart.split(":")[1]);
                let currentDate = new Date();
                if (order.isRedeemed) {
                  let dateHere = new Date(order.redeemed_at);
                  return (
                    <div
                      className="order-arrow orderarrowtexting"
                      style={{ width: 200 }}
                    >
                      <div
                        className="dot-inner-order-image"
                        style={{ backgroundColor: "#285151" }}
                      ></div>
                      <p>
                        Gebruikt <br /> {dateHere.getDate()}-
                        {dateHere.getMonth() + 1}-{dateHere.getFullYear()}
                      </p>
                    </div>
                  );
                } else if (order.voucherDeleted) {
                  return (
                    <div
                      className="order-arrow orderarrowtexting"
                      style={{ width: 200 }}
                    >
                      <div
                        className="dot-inner-order-image"
                        style={{ backgroundColor: "#B90B0C" }}
                      ></div>
                      <p>Voucher verlopen</p>
                    </div>
                  );
                } else {
                  if (currentDate.getTime() < dateMax.getTime()) {
                    // if (currentDate.getTime() > dateMaxStart.getTime() && currentDate.getTime() < dateMax.getTime()) {
                    // if (currentDate.getTime() < dateMax.getTime()) {
                    return (
                      <div
                        className="order-arrow orderarrowtexting"
                        style={{ width: 200 }}
                      >
                        <div
                          className="dot-inner-order-image"
                          style={{ backgroundColor: "#C17F6B" }}
                        ></div>
                        <p>
                          Beschikbaar <br /> {dateMax.getDate()}-
                          {dateMax.getMonth() + 1}-{dateMax.getFullYear()}
                        </p>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        className="order-arrow orderarrowtexting"
                        style={{ width: 200 }}
                      >
                        <div
                          className="dot-inner-order-image"
                          style={{ backgroundColor: "#B90B0C" }}
                        ></div>
                        <p>Voucher verlopen</p>
                      </div>
                    );
                  }
                }
              }
            }
          }
        } else {
          if (order.isRedeemed) {
            let dateHere = new Date(order.redeemed_at);
            return (
              <div
                className="order-arrow orderarrowtexting"
                style={{ width: 200 }}
              >
                <div
                  className="dot-inner-order-image"
                  style={{ backgroundColor: "#285151" }}
                ></div>
                <p>
                  Gebruikt <br /> {dateHere.getDate()}-{dateHere.getMonth() + 1}
                  -{dateHere.getFullYear()}
                </p>
              </div>
            );
          } else if (order.voucherDeleted) {
            return (
              <div
                className="order-arrow orderarrowtexting"
                style={{ width: 200 }}
              >
                <div
                  className="dot-inner-order-image"
                  style={{ backgroundColor: "#B90B0C" }}
                ></div>
                <p>Voucher verlopen</p>
              </div>
            );
          } else {
            if (order.timeStampCreated) {
              let dateOfOrderRedeemMax = new Date(order.timeStampCreated);
              dateOfOrderRedeemMax.setTime(
                dateOfOrderRedeemMax.getTime() + 7 * 60000
              );
              if (dateOfOrderRedeemMax.getTime() > new Date().getTime()) {
                return (
                  <div
                    className="order-arrow orderarrowtexting"
                    style={{ width: 200 }}
                  >
                    <div
                      className="dot-inner-order-image"
                      style={{ backgroundColor: "#C17F6B" }}
                    ></div>
                    {/* <p>Beschikbaar <br /> {dateOfOrderRedeemMax.getDate()}-{dateOfOrderRedeemMax.getMonth() + 1}-{dateOfOrderRedeemMax.getFullYear()}</p> */}
                    <p>
                      Beschikbaar <br />{" "}
                      <BackTimer timeToCalculate={dateOfOrderRedeemMax} />{" "}
                    </p>
                  </div>
                );
              } else {
                return (
                  <div
                    className="order-arrow orderarrowtexting"
                    style={{ width: 200 }}
                  >
                    <div
                      className="dot-inner-order-image"
                      style={{ backgroundColor: "#B90B0C" }}
                    ></div>
                    <p>Voucher verlopen</p>
                  </div>
                );
              }
            }
          }
        }
      } else {
        return (
          <div
            className="order-arrow orderarrowtexting"
            style={{ width: 200, opacity: 0, pointerEvents: "none" }}
          >
            <div
              className="dot-inner-order-image"
              style={{ backgroundColor: "#B90B0C" }}
            ></div>
            <p>Voucher verlopen</p>
          </div>
        );
      }
    }
  }

  checkForOrderStatus(order) {
    if (order.order_type === "campaign") {
      if (order.products[0].campaignType !== "live_event") {
        // if (order.products[0].campaignType !== "charity") {
        if (order.products[0].campaignType === "live_event_own") {
          return (
            <div
              className="order-detail-number status-odn"
              style={{ color: "#285151" }}
            >
              <span>Status: Gebruikt</span>
            </div>
          );
          return false;
        }
        if (
          order.products[0].campaignType === "last_minute" ||
          order.products[0].campaignType === "local_event"
        ) {
          if (order.products[0].timings) {
            if (order.products[0].timings.length > 0) {
              let filtered = order.products[0].timings.filter((j) => {
                return j.eventId === order.products[0].reserved_event_id;
              });
              if (filtered.length > 0) {
                // let dateMax = new Date(filtered[0].endDateEvent)
                // let endTimeGood = filtered[0].endTimeEvent.concat(":00")
                // dateMax.setHours(endTimeGood.split(":")[0])
                // dateMax.setMinutes(endTimeGood.split(":")[1])
                let dateMax = new Date(filtered[0].endDateEvent);
                let endTimeGood = filtered[0].endTimeEvent.concat(":00");
                dateMax.setHours(endTimeGood.split(":")[0]);
                dateMax.setMinutes(endTimeGood.split(":")[1]);
                let dateMaxStart = new Date(filtered[0].startDateEvent);
                let endTimeGoodStart = filtered[0].startTimeEvent.concat(":00");
                dateMaxStart.setHours(endTimeGoodStart.split(":")[0]);
                dateMaxStart.setMinutes(endTimeGoodStart.split(":")[1]);
                let currentDate = new Date();
                if (order.isRedeemed) {
                  return (
                    <div
                      className="order-detail-number status-odn"
                      style={{ color: "#285151" }}
                    >
                      <span>Status: Gebruikt</span>
                    </div>
                  );
                } else if (order.voucherDeleted) {
                  return (
                    <div
                      className="order-detail-number status-odn"
                      style={{ color: "#B90B0C" }}
                    >
                      <span>Status: Voucher verlopen</span>
                    </div>
                  );
                } else {
                  if (currentDate.getTime() < dateMax.getTime()) {
                    return (
                      <div
                        className="order-detail-number status-odn"
                        style={{ color: "#C17F6B" }}
                      >
                        <span>Status: Beschikbaar</span>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        className="order-detail-number status-odn"
                        style={{ color: "#B90B0C" }}
                      >
                        <span>Status: Voucher verlopen</span>
                      </div>
                    );
                  }
                }
              }
            }
          }
        } else {
          if (order.isRedeemed) {
            return (
              <div
                className="order-detail-number status-odn"
                style={{ color: "#285151" }}
              >
                <span>Status: Gebruikt</span>
              </div>
            );
          } else if (order.voucherDeleted) {
            return (
              <div
                className="order-detail-number status-odn"
                style={{ color: "#B90B0C" }}
              >
                <span>Status: Voucher verlopen</span>
              </div>
            );
          } else {
            if (order.timeStampCreated) {
              let dateOfOrderRedeemMax = new Date(order.timeStampCreated);
              dateOfOrderRedeemMax.setTime(
                dateOfOrderRedeemMax.getTime() + 7 * 60000
              );
              if (dateOfOrderRedeemMax.getTime() > new Date().getTime()) {
                return (
                  <div
                    className="order-detail-number status-odn"
                    style={{ color: "#C17F6B" }}
                  >
                    <span style={{ display: "flex" }}>
                      Beschikbaar:&nbsp;
                      <BackTimer timeToCalculate={dateOfOrderRedeemMax} />
                    </span>
                  </div>
                );
              } else {
                return (
                  <div
                    className="order-detail-number status-odn"
                    style={{ color: "#B90B0C" }}
                  >
                    <span>Status: Voucher verlopen</span>
                  </div>
                );
              }
            }
          }
        }
      }
    }
  }

  getDayName(num) {
    if (num === 0) {
      return `Zondag`;
    } else if (num === 1) {
      return `Maandag`;
    } else if (num === 2) {
      return `Dinsdag`;
    } else if (num === 3) {
      return `Woensdag`;
    } else if (num === 4) {
      return `Donderdag`;
    } else if (num === 5) {
      return `Vrijdag`;
    } else if (num === 6) {
      return `Zaterdag`;
    }
  }

  getMonthName(i) {
    i = Number(i);
    if (i === 1) {
      return "Januari";
    } else if (i === 2) {
      return "Februari";
    } else if (i === 3) {
      return "Maart";
    } else if (i === 4) {
      return "April";
    } else if (i === 5) {
      return "Mei";
    } else if (i === 6) {
      return "Juni";
    } else if (i === 7) {
      return "Juli";
    } else if (i === 8) {
      return "Augustus";
    } else if (i === 9) {
      return "September";
    } else if (i === 10) {
      return "Oktober";
    } else if (i === 11) {
      return "November";
    } else if (i === 12) {
      return "December";
    }
  }

  showDateToUserTiming(product) {
    let filtered = product.timings.filter((u) => {
      return u.eventId === product.reserved_event_id;
    });

    if (filtered.length > 0) {
      let e = filtered[0].startDateEvent;
      let f = filtered[0].endDateEvent;
      if (e === f) {
        let splitted = e.split("-");
        let newDate = new Date(e).getDay();
        return `${this.getDayName(newDate)} ${splitted[2]} ${this.getMonthName(
          splitted[1]
        )}`;
      } else {
        let splitted = e.split("-");
        let splittedSecond = f.split("-");
        let newDate = new Date(e).getDay();
        let newDateAnother = new Date(f).getDay();
        if (splitted[1] === splittedSecond[1]) {
          return `${this.getDayName(newDate)} ${splitted[2]} - ${splittedSecond[2]
            } ${this.getMonthName(splittedSecond[1])}`;
        } else {
          // return `${this.getDayName(newDate)} ${splitted[2]} ${this.getMonthName(splitted[1])} - ${this.getDayName(newDateAnother)} ${splittedSecond[2]} ${this.getMonthName(splittedSecond[1])}`
          return `${splitted[2]} ${this.getMonthName(splitted[1])} t/m ${splittedSecond[2]
            } ${this.getMonthName(splittedSecond[1])}`;
        }
      }
    }
  }

  copyToClipboard(e) {
    this.setState({ copiedTextDone: true });
    var textField = document.createElement("textarea");
    textField.innerText = e;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    // setTimeout(() => {
    //     this.setState({ copiedTextDone: false })
    // }, 3000);
  }

  render() {
    return (
      <div
        className="amdiv"
        style={{
          width: "100%",
          height: "100%",
          overflowY: "auto",
          backgroundColor: "#f6f4f2",
          minHeight: "100vh",
        }}
      >
        <div
          className="verzilver-popup-green"
          style={{
            opacity: this.state.showVoucherNow ? 1 : 0,
            pointerEvents: this.state.showVoucherNow ? "all" : "none",
          }}
        >
          <CloseIcon
            className="close-hpw"
            style={{ color: "white" }}
            onClick={() => {
              this.setState({ showVoucherNow: false });
            }}
          />
          <CheckCircleOutlineIcon
            className="lock-hpw"
            style={{ color: "white" }}
          />
          {/* <p className="main-heading-vpg" style={{ color: "white" }}>Company</p> */}
          <div className="desc-hpw-green">
            <p style={{ color: "white" }}>
              {this.state.currentVoucherWork.title}
            </p>
            <p style={{ color: "white" }}>
              {this.state.currentVoucherWork.desc}
            </p>
          </div>
        </div>
        <div
          className="verzilver-popup-green-opacity"
          style={{
            opacity: this.state.showVoucherNow ? 0.7 : 0,
            pointerEvents: this.state.showVoucherNow ? "all" : "none",
          }}
        ></div>
        {window.innerWidth < 1009 && this.state.showFooter && (
          <div
            className="center-fwnvvm centermakeheaderaanbieders"
            style={{ color: "white" }}
          >
            <BsFillBagCheckFill style={{ fontSize: 20, marginRight: 10 }} />
            <label
              onClick={() => {
                window.location.reload();
              }}
            >
              Orderoverzicht
            </label>
          </div>
        )}
        <div className="account-main">
          <div className="account-wrapper">
            {this.state.showFooter ? (
              <div
                className="section-title sectiontitleflexwrapper"
                style={{
                  textTransform: "none",
                  paddingBottom: 20,
                  position: "relative",
                }}
              >
                {window.innerWidth > 1009 ? (
                  <h2
                    style={{
                      fontSize: 15,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span>
                      <BsFillBagCheckFill
                        style={{ marginRight: 20, left: 2, fontSize: 20 }}
                      />
                    </span>
                    <span>Orderoverzicht</span>
                  </h2>
                ) : (
                  <div></div>
                )}
                <div className="select-options-filter-order">
                  <div className="inner-sofo">
                    <div
                      className="top-box-isofo"
                      onClick={() =>
                        this.setState({
                          dropdownOpen: !this.state.dropdownOpen,
                        })
                      }
                    >
                      {this.state.currentFiltered ? (
                        <p>{this.state.currentFiltered}</p>
                      ) : (
                        <p>Filter</p>
                      )}
                      <ExpandMoreIcon />
                    </div>
                    <div
                      className="box-opened-dropdown"
                      style={{
                        opacity: this.state.dropdownOpen ? 1 : 0,
                        pointerEvents: this.state.dropdownOpen ? "all" : "none",
                      }}
                    >
                      <ul>
                        <li
                          onClick={() => {
                            this.setState({
                              currentFiltered: "Beschikbaar",
                              dropdownOpen: false,
                            });
                            let filteredArray = [];
                            this.state.arrOrders.map((order) => {
                              if (order.order_type === "campaign") {
                                if (
                                  order.products[0].campaignType ===
                                  "last_minute" ||
                                  order.products[0].campaignType ===
                                  "local_event"
                                ) {
                                  if (order.products[0].timings) {
                                    if (order.products[0].timings.length > 0) {
                                      let filtered =
                                        order.products[0].timings.filter(
                                          (j) => {
                                            return (
                                              j.eventId ===
                                              order.products[0]
                                                .reserved_event_id
                                            );
                                          }
                                        );
                                      if (order.timeStampCreated) {
                                        if (filtered.length > 0) {
                                          // let dateMax = new Date(filtered[0].endDateEvent)
                                          // let endTimeGood = filtered[0].endTimeEvent.concat(":00")
                                          // dateMax.setHours(endTimeGood.split(":")[0])
                                          // dateMax.setMinutes(endTimeGood.split(":")[1])
                                          // let dateMaxStart = new Date(filtered[0].startDateEvent)
                                          // let endTimeGoodStart = filtered[0].startTimeEvent.concat(":00")
                                          // dateMaxStart.setHours(endTimeGoodStart.split(":")[0])
                                          // dateMaxStart.setMinutes(endTimeGoodStart.split(":")[1])
                                          let dateMax = new Date(
                                            filtered[0].endDateEvent
                                          );
                                          let endTimeGood =
                                            filtered[0].endTimeEvent.concat(
                                              ":00"
                                            );
                                          dateMax.setHours(
                                            endTimeGood.split(":")[0]
                                          );
                                          dateMax.setMinutes(
                                            endTimeGood.split(":")[1]
                                          );
                                          let dateMaxStart = new Date(
                                            filtered[0].startDateEvent
                                          );
                                          let endTimeGoodStart =
                                            filtered[0].startTimeEvent.concat(
                                              ":00"
                                            );
                                          dateMaxStart.setHours(
                                            endTimeGoodStart.split(":")[0]
                                          );
                                          dateMaxStart.setMinutes(
                                            endTimeGoodStart.split(":")[1]
                                          );
                                          let currentDate = new Date();
                                          if (!order.isRedeemed) {
                                            if (
                                              currentDate.getTime() <
                                              dateMax.getTime()
                                            ) {
                                              // if (currentDate.getTime() > dateMaxStart.getTime() && currentDate.getTime() < dateMax.getTime()) {
                                              // if (currentDate.getTime() < dateMax.getTime()) {
                                              filteredArray.push(order);
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                } else {
                                  if (order.timeStampCreated) {
                                    let dateOfOrderRedeemMax = new Date(
                                      order.timeStampCreated
                                    );
                                    dateOfOrderRedeemMax.setTime(
                                      dateOfOrderRedeemMax.getTime() + 7 * 60000
                                    );
                                    if (!order.isRedeemed) {
                                      if (
                                        dateOfOrderRedeemMax.getTime() >
                                        new Date().getTime()
                                      ) {
                                        filteredArray.push(order);
                                      }
                                    }
                                  }
                                }
                              }
                            });
                            if (filteredArray.length > 0) {
                              this.setState({
                                noFiltersHere: false,
                                ordersFiltered: filteredArray,
                                ordersFilteredDuplicate: filteredArray,
                              });
                            } else {
                              this.setState({
                                noFiltersHere: true,
                                ordersFiltered: filteredArray,
                                ordersFilteredDuplicate: filteredArray,
                              });
                            }
                          }}
                        >
                          Beschikbaar
                        </li>
                        <li
                          onClick={() => {
                            this.setState({
                              currentFiltered: "Gebruikt",
                              dropdownOpen: false,
                            });
                            let filteredArray = [];
                            this.state.arrOrders.map((order) => {
                              if (order.order_type === "campaign") {
                                if (
                                  order.products[0].campaignType ===
                                  "live_event_own"
                                ) {
                                  filteredArray.push(order);
                                }
                                if (
                                  order.products[0].campaignType ===
                                  "last_minute" ||
                                  order.products[0].campaignType ===
                                  "local_event"
                                ) {
                                  if (order.products[0].timings) {
                                    if (order.products[0].timings.length > 0) {
                                      let filtered =
                                        order.products[0].timings.filter(
                                          (j) => {
                                            return (
                                              j.eventId ===
                                              order.products[0]
                                                .reserved_event_id
                                            );
                                          }
                                        );
                                      if (order.timeStampCreated) {
                                        if (filtered.length > 0) {
                                          if (order.isRedeemed) {
                                            filteredArray.push(order);
                                          }
                                        }
                                      }
                                    }
                                  }
                                } else {
                                  if (order.timeStampCreated) {
                                    let dateOfOrderRedeemMax = new Date(
                                      order.timeStampCreated
                                    );
                                    dateOfOrderRedeemMax.setTime(
                                      dateOfOrderRedeemMax.getTime() + 7 * 60000
                                    );
                                    if (order.isRedeemed) {
                                      filteredArray.push(order);
                                    }
                                  }
                                }
                              }
                            });
                            if (filteredArray.length > 0) {
                              this.setState({
                                noFiltersHere: false,
                                ordersFiltered: filteredArray,
                                ordersFilteredDuplicate: filteredArray,
                              });
                            } else {
                              this.setState({
                                noFiltersHere: true,
                                ordersFiltered: filteredArray,
                                ordersFilteredDuplicate: filteredArray,
                              });
                            }
                          }}
                        >
                          Gebruikt
                        </li>
                        <li
                          onClick={() => {
                            this.setState({
                              currentFiltered: "Verlopen",
                              dropdownOpen: false,
                            });
                            let filteredArray = [];
                            this.state.arrOrders.map((order) => {
                              if (order.order_type === "campaign") {
                                if (order.voucherDeleted && !order.isRedeemed) {
                                  if (
                                    order.products[0].campaignType !==
                                    "live_event_own"
                                  ) {
                                    filteredArray.push(order);
                                  }
                                } else {
                                  if (
                                    order.products[0].campaignType ===
                                    "last_minute" ||
                                    order.products[0].campaignType ===
                                    "local_event"
                                  ) {
                                    if (order.products[0].timings) {
                                      if (
                                        order.products[0].timings.length > 0
                                      ) {
                                        let filtered =
                                          order.products[0].timings.filter(
                                            (j) => {
                                              return (
                                                j.eventId ===
                                                order.products[0]
                                                  .reserved_event_id
                                              );
                                            }
                                          );
                                        if (filtered.length > 0) {
                                          let dateMax = new Date(
                                            filtered[0].endDateEvent
                                          );
                                          let endTimeGood =
                                            filtered[0].endTimeEvent.concat(
                                              ":00"
                                            );
                                          dateMax.setHours(
                                            endTimeGood.split(":")[0]
                                          );
                                          dateMax.setMinutes(
                                            endTimeGood.split(":")[1]
                                          );
                                          let currentDate = new Date();
                                          if (order.timeStampCreated) {
                                            if (!order.isRedeemed) {
                                              if (
                                                currentDate.getTime() >
                                                dateMax.getTime()
                                              ) {
                                                filteredArray.push(order);
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  } else {
                                    if (
                                      order.products[0].campaignType !==
                                      "live_event_own"
                                    ) {
                                      if (order.timeStampCreated) {
                                        let dateOfOrderRedeemMax = new Date(
                                          order.timeStampCreated
                                        );
                                        dateOfOrderRedeemMax.setTime(
                                          dateOfOrderRedeemMax.getTime() +
                                          7 * 60000
                                        );
                                        if (!order.isRedeemed) {
                                          if (
                                            dateOfOrderRedeemMax.getTime() <
                                            new Date().getTime()
                                          ) {
                                            filteredArray.push(order);
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            });
                            if (filteredArray.length > 0) {
                              this.setState({
                                noFiltersHere: false,
                                ordersFiltered: filteredArray,
                                ordersFilteredDuplicate: filteredArray,
                              });
                            } else {
                              this.setState({
                                noFiltersHere: true,
                                ordersFiltered: filteredArray,
                                ordersFilteredDuplicate: filteredArray,
                              });
                            }
                          }}
                        >
                          Verlopen
                        </li>
                      </ul>
                    </div>
                  </div>
                  {this.state.noFiltersHere ? (
                    <CloseIcon
                      className="close-isofo"
                      onClick={() => {
                        this.setState({
                          ordersFiltered: [],
                          noFiltersHere: false,
                          ordersFilteredDuplicate: [],
                          currentFiltered: "",
                          searchText: "",
                        });
                      }}
                    />
                  ) : (
                    this.state.ordersFiltered.length > 0 && (
                      <CloseIcon
                        className="close-isofo"
                        onClick={() => {
                          this.setState({
                            ordersFiltered: [],
                            noFiltersHere: false,
                            ordersFilteredDuplicate: [],
                            currentFiltered: "",
                            searchText: "",
                          });
                        }}
                      />
                    )
                  )}
                </div>
              </div>
            ) : (
              <h1
                className="account-title"
                style={{ display: "flex", alignItems: "center" }}
              >
                <span className="icon-user">
                  <AccountCircleOutlinedIcon style={{ fontSize: 34 }} />
                </span>
                <font style={{ textTransform: "uppercase" }}>
                  MIJN ORDEROVERZICHT
                </font>
              </h1>
            )}
            {/* <div className="section-title" style={{ textTransform: "none", paddingBottom: 20, position: "relative" }}>
                            <h2 style={{ fontSize: 15, display: "flex", alignItems: "center" }}>
                                <span>
                                    <BsFillBagCheckFill style={{ marginRight: 20, left: 2, fontSize: 20 }} />
                                </span>
                                <span>
                                    Orderoverzicht 
                                </span>
                            </h2>
                        </div> */}
            <div className="account-right-content" style={{ width: "100%" }}>
              <div className="content-wrapper-rc">
                {!this.state.showFooter && (
                  <div
                    className="section-title sectiontitleflexwrapper"
                    style={{ textTransform: "none" }}
                  >
                    <h2 style={{ fontSize: 15 }}>
                      <span>
                        <BsFillBagCheckFill
                          style={{
                            position: "absolute",
                            transform: "translateY(-50%)",
                            top: "50%",
                            left: 2,
                            fontSize: 20,
                          }}
                        />
                      </span>
                      <span>Orderoverzicht</span>
                    </h2>
                    <div className="select-options-filter-order">
                      <div className="inner-sofo">
                        <div
                          className="top-box-isofo"
                          onClick={() =>
                            this.setState({
                              dropdownOpen: !this.state.dropdownOpen,
                            })
                          }
                        >
                          {this.state.currentFiltered ? (
                            <p>{this.state.currentFiltered}</p>
                          ) : (
                            <p>Filter</p>
                          )}
                          <ExpandMoreIcon />
                        </div>
                        <div
                          className="box-opened-dropdown"
                          style={{
                            opacity: this.state.dropdownOpen ? 1 : 0,
                            pointerEvents: this.state.dropdownOpen
                              ? "all"
                              : "none",
                          }}
                        >
                          <ul>
                            <li
                              onClick={() => {
                                this.setState({
                                  currentFiltered: "Beschikbaar",
                                  dropdownOpen: false,
                                });
                                let filteredArray = [];
                                this.state.arrOrders.map((order) => {
                                  if (order.order_type === "campaign") {
                                    if (
                                      order.products[0].campaignType ===
                                      "last_minute" ||
                                      order.products[0].campaignType ===
                                      "local_event"
                                    ) {
                                      if (order.products[0].timings) {
                                        if (
                                          order.products[0].timings.length > 0
                                        ) {
                                          let filtered =
                                            order.products[0].timings.filter(
                                              (j) => {
                                                return (
                                                  j.eventId ===
                                                  order.products[0]
                                                    .reserved_event_id
                                                );
                                              }
                                            );
                                          if (order.timeStampCreated) {
                                            if (filtered.length > 0) {
                                              // let dateMax = new Date(filtered[0].endDateEvent)
                                              // let endTimeGood = filtered[0].endTimeEvent.concat(":00")
                                              // dateMax.setHours(endTimeGood.split(":")[0])
                                              // dateMax.setMinutes(endTimeGood.split(":")[1])
                                              // let dateMaxStart = new Date(filtered[0].startDateEvent)
                                              // let endTimeGoodStart = filtered[0].startTimeEvent.concat(":00")
                                              // dateMaxStart.setHours(endTimeGoodStart.split(":")[0])
                                              // dateMaxStart.setMinutes(endTimeGoodStart.split(":")[1])
                                              let dateMax = new Date(
                                                filtered[0].endDateEvent
                                              );
                                              let endTimeGood =
                                                filtered[0].endTimeEvent.concat(
                                                  ":00"
                                                );
                                              dateMax.setHours(
                                                endTimeGood.split(":")[0]
                                              );
                                              dateMax.setMinutes(
                                                endTimeGood.split(":")[1]
                                              );
                                              let dateMaxStart = new Date(
                                                filtered[0].startDateEvent
                                              );
                                              let endTimeGoodStart =
                                                filtered[0].startTimeEvent.concat(
                                                  ":00"
                                                );
                                              dateMaxStart.setHours(
                                                endTimeGoodStart.split(":")[0]
                                              );
                                              dateMaxStart.setMinutes(
                                                endTimeGoodStart.split(":")[1]
                                              );
                                              let currentDate = new Date();
                                              if (!order.isRedeemed) {
                                                if (
                                                  currentDate.getTime() <
                                                  dateMax.getTime()
                                                ) {
                                                  // if (currentDate.getTime() > dateMaxStart.getTime() && currentDate.getTime() < dateMax.getTime()) {
                                                  // if (currentDate.getTime() < dateMax.getTime()) {
                                                  filteredArray.push(order);
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    } else {
                                      if (order.timeStampCreated) {
                                        let dateOfOrderRedeemMax = new Date(
                                          order.timeStampCreated
                                        );
                                        dateOfOrderRedeemMax.setTime(
                                          dateOfOrderRedeemMax.getTime() +
                                          7 * 60000
                                        );
                                        if (!order.isRedeemed) {
                                          if (
                                            dateOfOrderRedeemMax.getTime() >
                                            new Date().getTime()
                                          ) {
                                            filteredArray.push(order);
                                          }
                                        }
                                      }
                                    }
                                  }
                                });
                                if (filteredArray.length > 0) {
                                  this.setState({
                                    noFiltersHere: false,
                                    ordersFiltered: filteredArray,
                                    ordersFilteredDuplicate: filteredArray,
                                  });
                                } else {
                                  this.setState({
                                    noFiltersHere: true,
                                    ordersFiltered: filteredArray,
                                    ordersFilteredDuplicate: filteredArray,
                                  });
                                }
                              }}
                            >
                              Beschikbaar
                            </li>
                            <li
                              onClick={() => {
                                this.setState({
                                  currentFiltered: "Gebruikt",
                                  dropdownOpen: false,
                                });
                                let filteredArray = [];
                                this.state.arrOrders.map((order) => {
                                  if (order.order_type === "campaign") {
                                    if (
                                      order.products[0].campaignType ===
                                      "live_event_own"
                                    ) {
                                      filteredArray.push(order);
                                    }
                                    if (
                                      order.products[0].campaignType ===
                                      "last_minute" ||
                                      order.products[0].campaignType ===
                                      "local_event"
                                    ) {
                                      if (order.products[0].timings) {
                                        if (
                                          order.products[0].timings.length > 0
                                        ) {
                                          let filtered =
                                            order.products[0].timings.filter(
                                              (j) => {
                                                return (
                                                  j.eventId ===
                                                  order.products[0]
                                                    .reserved_event_id
                                                );
                                              }
                                            );
                                          if (order.timeStampCreated) {
                                            if (filtered.length > 0) {
                                              if (order.isRedeemed) {
                                                filteredArray.push(order);
                                              }
                                            }
                                          }
                                        }
                                      }
                                    } else {
                                      if (order.timeStampCreated) {
                                        let dateOfOrderRedeemMax = new Date(
                                          order.timeStampCreated
                                        );
                                        dateOfOrderRedeemMax.setTime(
                                          dateOfOrderRedeemMax.getTime() +
                                          7 * 60000
                                        );
                                        if (order.isRedeemed) {
                                          filteredArray.push(order);
                                        }
                                      }
                                    }
                                  }
                                });
                                if (filteredArray.length > 0) {
                                  this.setState({
                                    noFiltersHere: false,
                                    ordersFiltered: filteredArray,
                                    ordersFilteredDuplicate: filteredArray,
                                  });
                                } else {
                                  this.setState({
                                    noFiltersHere: true,
                                    ordersFiltered: filteredArray,
                                    ordersFilteredDuplicate: filteredArray,
                                  });
                                }
                              }}
                            >
                              Gebruikt
                            </li>
                            <li
                              onClick={() => {
                                this.setState({
                                  currentFiltered: "Verlopen",
                                  dropdownOpen: false,
                                });
                                let filteredArray = [];
                                this.state.arrOrders.map((order) => {
                                  if (order.order_type === "campaign") {
                                    if (
                                      order.voucherDeleted &&
                                      !order.isRedeemed
                                    ) {
                                      if (
                                        order.products[0].campaignType !==
                                        "live_event_own"
                                      ) {
                                        filteredArray.push(order);
                                      }
                                    } else {
                                      if (
                                        order.products[0].campaignType ===
                                        "last_minute" ||
                                        order.products[0].campaignType ===
                                        "local_event"
                                      ) {
                                        if (order.products[0].timings) {
                                          if (
                                            order.products[0].timings.length > 0
                                          ) {
                                            let filtered =
                                              order.products[0].timings.filter(
                                                (j) => {
                                                  return (
                                                    j.eventId ===
                                                    order.products[0]
                                                      .reserved_event_id
                                                  );
                                                }
                                              );
                                            if (filtered.length > 0) {
                                              let dateMax = new Date(
                                                filtered[0].endDateEvent
                                              );
                                              let endTimeGood =
                                                filtered[0].endTimeEvent.concat(
                                                  ":00"
                                                );
                                              dateMax.setHours(
                                                endTimeGood.split(":")[0]
                                              );
                                              dateMax.setMinutes(
                                                endTimeGood.split(":")[1]
                                              );
                                              let currentDate = new Date();
                                              if (order.timeStampCreated) {
                                                if (!order.isRedeemed) {
                                                  if (
                                                    currentDate.getTime() >
                                                    dateMax.getTime()
                                                  ) {
                                                    filteredArray.push(order);
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      } else {
                                        if (
                                          order.products[0].campaignType !==
                                          "live_event_own"
                                        ) {
                                          if (order.timeStampCreated) {
                                            let dateOfOrderRedeemMax = new Date(
                                              order.timeStampCreated
                                            );
                                            dateOfOrderRedeemMax.setTime(
                                              dateOfOrderRedeemMax.getTime() +
                                              7 * 60000
                                            );
                                            if (!order.isRedeemed) {
                                              if (
                                                dateOfOrderRedeemMax.getTime() <
                                                new Date().getTime()
                                              ) {
                                                filteredArray.push(order);
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                });
                                if (filteredArray.length > 0) {
                                  this.setState({
                                    noFiltersHere: false,
                                    ordersFiltered: filteredArray,
                                    ordersFilteredDuplicate: filteredArray,
                                  });
                                } else {
                                  this.setState({
                                    noFiltersHere: true,
                                    ordersFiltered: filteredArray,
                                    ordersFilteredDuplicate: filteredArray,
                                  });
                                }
                              }}
                            >
                              Verlopen
                            </li>
                          </ul>
                        </div>
                        {/* <select value={this.state.currentFiltered} onChange={(e) => {
                                                this.setState({ currentFiltered: e.target.value })
                                                if (e.target.value === "valid") {
                                                    let filteredArray = []
                                                    this.state.arrOrders.map((order) => {
                                                        if (order.order_type === "campaign") {
                                                            if (order.products[0].campaignType === "last_minute" || order.products[0].campaignType === "local_event") {
                                                                if (order.products[0].timings) {
                                                                    if (order.products[0].timings.length > 0) {
                                                                        let filtered = order.products[0].timings.filter((j) => {
                                                                            return j.eventId === order.products[0].reserved_event_id
                                                                        })
                                                                        if (order.timeStampCreated) {
                                                                            if (filtered.length > 0) {
                                                                                // let dateMax = new Date(filtered[0].endDateEvent)
                                                                                // let endTimeGood = filtered[0].endTimeEvent.concat(":00")
                                                                                // dateMax.setHours(endTimeGood.split(":")[0])
                                                                                // dateMax.setMinutes(endTimeGood.split(":")[1])
                                                                                let dateMax = new Date(filtered[0].endDateEvent)
                                                                                let endTimeGood = filtered[0].endTimeEvent.concat(":00")
                                                                                dateMax.setHours(endTimeGood.split(":")[0])
                                                                                dateMax.setMinutes(endTimeGood.split(":")[1])
                                                                                let dateMaxStart = new Date(filtered[0].startDateEvent)
                                                                                let endTimeGoodStart = filtered[0].startTimeEvent.concat(":00")
                                                                                dateMaxStart.setHours(endTimeGoodStart.split(":")[0])
                                                                                dateMaxStart.setMinutes(endTimeGoodStart.split(":")[1])
                                                                                let currentDate = new Date()
                                                                                if (!order.isRedeemed) {
                                                                                    // if (currentDate.getTime() > dateMaxStart.getTime() && currentDate.getTime() < dateMax.getTime()) {
                                                                                    if (currentDate.getTime() < dateMax.getTime()) {
                                                                                        filteredArray.push(order)
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            } else {
                                                                if (order.timeStampCreated) {
                                                                    let dateOfOrderRedeemMax = new Date(order.timeStampCreated)
                                                                    dateOfOrderRedeemMax.setTime(dateOfOrderRedeemMax.getTime() + 7 * 60000)
                                                                    if (!order.isRedeemed) {
                                                                        if (dateOfOrderRedeemMax.getTime() > new Date().getTime()) {
                                                                            filteredArray.push(order)
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    })
                                                    if (filteredArray.length > 0) {
                                                        this.setState({ noFiltersHere: false, ordersFiltered: filteredArray, ordersFilteredDuplicate: filteredArray })
                                                    } else {
                                                        this.setState({ noFiltersHere: true, ordersFiltered: filteredArray, ordersFilteredDuplicate: filteredArray })
                                                    }
                                                } else if (e.target.value === "used") {
                                                    let filteredArray = []
                                                    this.state.arrOrders.map((order) => {
                                                        if (order.order_type === "campaign") {
                                                            if (order.products[0].campaignType === "last_minute" || order.products[0].campaignType === "local_event") {
                                                                if (order.products[0].timings) {
                                                                    if (order.products[0].timings.length > 0) {
                                                                        let filtered = order.products[0].timings.filter((j) => {
                                                                            return j.eventId === order.products[0].reserved_event_id
                                                                        })
                                                                        if (order.timeStampCreated) {
                                                                            if (filtered.length > 0) {
                                                                                if (order.isRedeemed) {
                                                                                    filteredArray.push(order)
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            } else {
                                                                if (order.timeStampCreated) {
                                                                    let dateOfOrderRedeemMax = new Date(order.timeStampCreated)
                                                                    dateOfOrderRedeemMax.setTime(dateOfOrderRedeemMax.getTime() + 7 * 60000)
                                                                    if (order.isRedeemed) {
                                                                        filteredArray.push(order)
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    })
                                                    if (filteredArray.length > 0) {
                                                        this.setState({ noFiltersHere: false, ordersFiltered: filteredArray, ordersFilteredDuplicate: filteredArray })
                                                    } else {
                                                        this.setState({ noFiltersHere: true, ordersFiltered: filteredArray, ordersFilteredDuplicate: filteredArray })
                                                    }
                                                } else if (e.target.value === "gone") {
                                                    let filteredArray = []
                                                    this.state.arrOrders.map((order) => {
                                                        if (order.order_type === "campaign") {
                                                            if (order.voucherDeleted) {
                                                                filteredArray.push(order)
                                                            } else {
                                                                if (order.products[0].campaignType !== "charity" && order.products[0].campaignType !== "live_event") {
                                                                    // if (order.products[0].campaignType !== "charity") {
                                                                    if (order.products[0].campaignType === "last_minute" || order.products[0].campaignType === "local_event") {
                                                                        if (order.products[0].timings) {
                                                                            if (order.products[0].timings.length > 0) {
                                                                                let filtered = order.products[0].timings.filter((j) => {
                                                                                    return j.eventId === order.products[0].reserved_event_id
                                                                                })
                                                                                if (filtered.length > 0) {
                                                                                    let dateMax = new Date(filtered[0].endDateEvent)
                                                                                    let endTimeGood = filtered[0].endTimeEvent.concat(":00")
                                                                                    dateMax.setHours(endTimeGood.split(":")[0])
                                                                                    dateMax.setMinutes(endTimeGood.split(":")[1])
                                                                                    let currentDate = new Date()
                                                                                    if (order.timeStampCreated) {
                                                                                        if (!order.isRedeemed) {
                                                                                            if (currentDate.getTime() > dateMax.getTime()) {
                                                                                                filteredArray.push(order)
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    } else {
                                                                        if (order.timeStampCreated) {
                                                                            let dateOfOrderRedeemMax = new Date(order.timeStampCreated)
                                                                            dateOfOrderRedeemMax.setTime(dateOfOrderRedeemMax.getTime() + 7 * 60000)
                                                                            if (!order.isRedeemed) {
                                                                                if (dateOfOrderRedeemMax.getTime() < new Date().getTime()) {
                                                                                    filteredArray.push(order)
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    })
                                                    if (filteredArray.length > 0) {
                                                        this.setState({ noFiltersHere: false, ordersFiltered: filteredArray, ordersFilteredDuplicate: filteredArray })
                                                    } else {
                                                        this.setState({ noFiltersHere: true, ordersFiltered: filteredArray, ordersFilteredDuplicate: filteredArray })
                                                    }
                                                }
                                            }}>
                                                <option disabled selected hidden value="">Filter</option>
                                                <option value="valid">Beschikbaar</option>
                                                <option value="used">Gebruikt</option>
                                                <option value="gone">Verlopen</option>
                                            </select>
                                            <ExpandMoreIcon /> */}
                      </div>
                      {this.state.noFiltersHere ? (
                        <CloseIcon
                          className="close-isofo"
                          onClick={() => {
                            this.setState({
                              ordersFiltered: [],
                              noFiltersHere: false,
                              ordersFilteredDuplicate: [],
                              currentFiltered: "",
                              searchText: "",
                            });
                          }}
                        />
                      ) : (
                        this.state.ordersFiltered.length > 0 && (
                          <CloseIcon
                            className="close-isofo"
                            onClick={() => {
                              this.setState({
                                ordersFiltered: [],
                                noFiltersHere: false,
                                ordersFilteredDuplicate: [],
                                currentFiltered: "",
                                searchText: "",
                              });
                            }}
                          />
                        )
                      )}
                    </div>
                  </div>
                )}
                <div className="search-bar-orders">
                  <input
                    type="text"
                    value={this.state.searchText}
                    onChange={(e) => {
                      this.setState({ searchText: e.target.value });
                      if (e.target.value !== "") {
                        if (this.state.currentFiltered) {
                          let arrToShow = [];
                          this.state.ordersFiltered.map((u) => {
                            let buyerCred = this.props.user.filter((j) => {
                              return j.user_id === u.sellers[0];
                            });
                            if (buyerCred.length > 0) {
                              if (
                                buyerCred[0].company_name
                                  .toLowerCase()
                                  .includes(e.target.value.toLowerCase())
                              ) {
                                arrToShow.push(u);
                              }
                            }
                          });
                          if (arrToShow.length > 0) {
                            if (arrToShow.length > 0) {
                              this.setState({
                                ordersFiltered: arrToShow,
                                noFiltersHere: false,
                              });
                            } else {
                              this.setState({ noFiltersHere: true });
                            }
                          } else {
                            if (this.state.ordersFilteredDuplicate.length > 0) {
                              this.setState({
                                ordersFiltered:
                                  this.state.ordersFilteredDuplicate,
                                noFiltersHere: false,
                              });
                            } else {
                              this.setState({ noFiltersHere: true });
                            }
                          }
                        } else {
                          let arrToShow = [];
                          this.state.arrOrders.map((u) => {
                            let buyerCred = this.props.user.filter((j) => {
                              return j.user_id === u.sellers[0];
                            });
                            if (buyerCred.length > 0) {
                              if (
                                buyerCred[0].company_name
                                  .toLowerCase()
                                  .includes(e.target.value.toLowerCase())
                              ) {
                                arrToShow.push(u);
                              }
                            }
                          });
                          if (arrToShow.length > 0) {
                            this.setState({
                              ordersFiltered: arrToShow,
                              noFiltersHere: false,
                            });
                          } else {
                            this.setState({ noFiltersHere: true });
                          }
                        }
                      } else {
                        if (this.state.currentFiltered) {
                          this.setState({
                            ordersFiltered: this.state.ordersFilteredDuplicate,
                            noFiltersHere: false,
                          });
                        } else {
                          this.setState({
                            ordersFiltered: [],
                            noFiltersHere: false,
                          });
                        }
                      }
                    }}
                  />
                  <SearchIcon />
                </div>
                <div
                  className="orders-container"
                  style={{ padding: "20px 0px" }}
                >
                  {this.state.noFiltersHere ? (
                    <div className="no-orders">
                      <p>Je hebt nog geen orders om te bekijken</p>
                    </div>
                  ) : this.state.ordersFiltered.length > 0 ? (
                    this.state.ordersFiltered.map((y, i) => {
                      if (y.order_type === "campaign") {
                        if (window.innerWidth > 1009) {
                          return (
                            <div
                              className="all-orders-list"
                              key={Math.random()}
                            >
                              <div
                                className="order-block"
                                style={{ width: "100%", marginLeft: 0 }}
                              >
                                <div
                                  className="order-block-inner"
                                  onClick={() => {
                                    let element = document.getElementById(
                                      `orderdropdowndetails${i}`
                                    );
                                    if (element.style.display === "none") {
                                      element.style.display = "block";
                                    } else {
                                      element.style.display = "none";
                                    }
                                  }}
                                >
                                  <div className="order-image dottoShowOnImageIdentity">
                                    {y.products[0].campaignType ===
                                      "live_event" ? (
                                      <img src="https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9" />
                                    ) : y.products[0].campaignType ===
                                      "charity" ? (
                                      <img
                                        src={
                                          y.products[0].dishesObjected[0].image
                                        }
                                        alt="order"
                                      />
                                    ) : (
                                      <img
                                        src={y.products[0].image}
                                        alt="order"
                                      />
                                    )}
                                    {/* {y.products[0].campaignType === "charity" ? <img src={y.products[0].dishesObjected[0].image} alt="order" /> : <img src={y.products[0].image} alt="order" />} */}
                                  </div>
                                  <div className="order-details">
                                    {y.products[0].campaignType ===
                                      "live_event_own" ? (
                                      <div className="top-row">
                                        {window.innerWidth > 1009 ? (
                                          <div className="order-detail-date">
                                            <span>
                                              {this.getPerfectDate(
                                                y.created_on
                                              )}{" "}
                                              |{" "}
                                              {this.getSellerNameCampaignOnly({
                                                created_by: y.sellers[0],
                                              })}
                                            </span>
                                          </div>
                                        ) : (
                                          <div className="order-detail-date">
                                            <span>
                                              {this.getSellerNameCampaignOnly({
                                                created_by: y.sellers[0],
                                              })}
                                            </span>
                                          </div>
                                        )}
                                        {window.innerWidth > 1009 ? (
                                          <div className="order-detail-number">
                                            <span>
                                              Ordernummer: {y.order_id} |{" "}
                                              <font>
                                                {y.products[0].description}
                                              </font>
                                            </span>
                                          </div>
                                        ) : (
                                          <div className="order-detail-number">
                                            <span>
                                              <font>
                                                {y.products[0].description}
                                              </font>
                                            </span>
                                          </div>
                                        )}
                                      </div>
                                    ) : (
                                      <div className="top-row">
                                        {window.innerWidth > 1009 ? (
                                          <div className="order-detail-date">
                                            <span>
                                              {this.getPerfectDate(
                                                y.created_on
                                              )}{" "}
                                              |{" "}
                                              {this.getSellerNameCampaignOnly(
                                                y.products[0]
                                              )}
                                            </span>
                                          </div>
                                        ) : (
                                          <div className="order-detail-date">
                                            <span>
                                              {this.getSellerNameCampaignOnly(
                                                y.products[0]
                                              )}
                                            </span>
                                          </div>
                                        )}
                                        {window.innerWidth > 1009 ? (
                                          <div className="order-detail-number">
                                            <span>
                                              Ordernummer: {y.order_id} |{" "}
                                              {y.products[0].campaignType ===
                                                "charity" ? (
                                                <font>
                                                  {y.products[0].main_title}
                                                </font>
                                              ) : (
                                                <font>
                                                  {y.products[0].title}
                                                </font>
                                              )}
                                            </span>
                                          </div>
                                        ) : (
                                          <div className="order-detail-number">
                                            <span>
                                              {y.products[0].campaignType ===
                                                "charity" ? (
                                                <font>
                                                  {y.products[0].main_title}
                                                </font>
                                              ) : (
                                                <font>
                                                  {y.products[0].title}
                                                </font>
                                              )}
                                            </span>
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                  {this.checkForGebruikTextRight(y)}
                                  <div className="order-arrow">
                                    <span>
                                      <ExpandMoreIcon
                                        style={{ fontSize: 30 }}
                                      />
                                    </span>
                                  </div>
                                </div>
                                <div
                                  className="order-dropdown-details"
                                  id={`orderdropdowndetails${i}`}
                                  style={{
                                    display: "none",
                                    position: "relative",
                                  }}
                                >
                                  {window.innerWidth > 1009 &&
                                    this.checkForButtonPopup(y)}
                                  {y.products[0].campaignType ===
                                    "local_event" ? (
                                    <div
                                      className="order-totals-block"
                                      style={{
                                        alignItems: "flex-start",
                                        minHeight: "auto",
                                      }}
                                    >
                                      <div
                                        className="order-total-price-row summary-row"
                                        style={{
                                          borderBottom: 0,
                                          maxWidth: 960,
                                          fontFamily: "Roboto",
                                          marginBottom: 0,
                                          paddingBottom: 0,
                                        }}
                                      >
                                        <p
                                          className="title-lds-shop"
                                          style={{ marginBottom: 0 }}
                                        >
                                          {y.products[0].title}
                                        </p>
                                      </div>
                                      {y.products[0].amount && (<div
                                        className="order-total-price-row summary-row"
                                        style={{
                                          borderBottom: 0,
                                          maxWidth: 960,
                                          fontFamily: "Roboto",
                                          marginBottom: 0,
                                          paddingBottom: 0,
                                        }}
                                      >
                                        <p
                                          className="normalprice-cpgm"
                                          style={{ marginBottom: 0 }}
                                        >
                                          EVENT: Korting{" "}
                                          {y.products[0].localEventType ===
                                            "percentage" ? (
                                            <font>{y.products[0].amount}%</font>
                                          ) : (
                                            <font>€{y.products[0].amount}</font>
                                          )}
                                        </p>
                                      </div>)}
                                      <div
                                        className="order-total-price-row summary-row"
                                        style={{
                                          borderBottom: 0,
                                          maxWidth: 960,
                                          fontFamily: "Roboto",
                                          marginBottom: 0,
                                          paddingBottom: 0,
                                          textTransform: "none",
                                        }}
                                      >
                                        <p
                                          className="desc-hading-lds"
                                          style={{
                                            color: "rgb(33, 37, 41)",
                                            fontSize: 13,
                                            fontWeight: 500,
                                            letterSpacing: 1,
                                          }}
                                        >
                                          Omschrijving
                                        </p>
                                      </div>
                                      <div
                                        className="order-total-price-row summary-row"
                                        style={{
                                          borderBottom: 0,
                                          maxWidth: 960,
                                          fontFamily: "Roboto",
                                        }}
                                      >
                                        <p className="desc-lds">
                                          {y.products[0].desc}
                                        </p>
                                      </div>
                                      <div
                                        className="order-total-price-row summary-row"
                                        style={{
                                          borderBottom: 0,
                                          maxWidth: 960,
                                          fontFamily: "Roboto",
                                          marginBottom: 0,
                                          paddingBottom: 0,
                                          textTransform: "none",
                                        }}
                                      >
                                        <p
                                          className="desc-hading-lds"
                                          style={{
                                            color: "rgb(33, 37, 41)",
                                            fontSize: 13,
                                            fontWeight: 500,
                                            letterSpacing: 1,
                                            marginTop: 0,
                                          }}
                                        >
                                          Datum
                                        </p>
                                      </div>
                                      <div
                                        className="order-total-price-row summary-row"
                                        style={{
                                          borderBottom: 0,
                                          maxWidth: 960,
                                          fontFamily: "Roboto",
                                        }}
                                      >
                                        <p className="desc-lds">
                                          {this.showDateToUserTiming(
                                            y.products[0]
                                          )}
                                        </p>
                                      </div>
                                      {window.innerWidth < 1009 &&
                                        this.checkForButtonPopup(y)}
                                    </div>
                                  ) : (
                                    y.products[0].campaignType ===
                                    "doorlopende" && (
                                      <div
                                        className="order-totals-block"
                                        style={{
                                          alignItems: "flex-start",
                                          minHeight: "auto",
                                        }}
                                      >
                                        <div
                                          className="order-total-price-row summary-row"
                                          style={{
                                            borderBottom: 0,
                                            maxWidth: 960,
                                            fontFamily: "Roboto",
                                            marginBottom: 0,
                                            paddingBottom: 0,
                                          }}
                                        >
                                          <p
                                            className="title-lds-shop"
                                            style={{ marginBottom: 0 }}
                                          >
                                            {y.products[0].title}
                                          </p>
                                        </div>
                                        <div
                                          className="order-total-price-row summary-row"
                                          style={{
                                            borderBottom: 0,
                                            maxWidth: 960,
                                            fontFamily: "Roboto",
                                            marginBottom: 0,
                                            paddingBottom: 0,
                                          }}
                                        >
                                          <p
                                            className="normalprice-cpgm"
                                            style={{ marginBottom: 0 }}
                                          >
                                            Kortingsvoucher{" "}
                                            {y.products[0].webShopType ===
                                              "percentage" ? (
                                              <font>
                                                {y.products[0].amount}%
                                              </font>
                                            ) : (
                                              <font>
                                                €{y.products[0].amount}
                                              </font>
                                            )}
                                          </p>
                                        </div>
                                        <div
                                          className="order-total-price-row summary-row"
                                          style={{
                                            borderBottom: 0,
                                            maxWidth: 960,
                                            fontFamily: "Roboto",
                                            marginBottom: 0,
                                            paddingBottom: 0,
                                            textTransform: "none",
                                          }}
                                        >
                                          <p
                                            className="desc-hading-lds"
                                            style={{
                                              color: "rgb(33, 37, 41)",
                                              fontSize: 13,
                                              fontWeight: 500,
                                              letterSpacing: 1,
                                            }}
                                          >
                                            Omschrijving
                                          </p>
                                        </div>
                                        <div
                                          className="order-total-price-row summary-row"
                                          style={{
                                            borderBottom: 0,
                                            maxWidth: 960,
                                            fontFamily: "Roboto",
                                          }}
                                        >
                                          <p className="desc-lds">
                                            {y.products[0].desc}
                                          </p>
                                        </div>
                                        {window.innerWidth < 1009 &&
                                          this.checkForButtonPopup(y)}
                                      </div>
                                    )
                                  )}
                                  {y.products[0].campaignType ===
                                    "live_event_own" &&
                                    y.products.map((e) => {
                                      return (
                                        <div
                                          className="order-totals-block"
                                          style={{
                                            alignItems: "flex-start",
                                            minHeight: "auto",
                                          }}
                                        >
                                          <div
                                            className="order-total-price-row summary-row"
                                            style={{
                                              borderBottom: 0,
                                              maxWidth: 960,
                                              fontFamily: "Roboto",
                                              marginBottom: 0,
                                              paddingBottom: 0,
                                            }}
                                          >
                                            <p
                                              className="title-lds-shop"
                                              style={{ marginBottom: 0 }}
                                            >
                                              {e.description}{" "}
                                              {e.innerProducts.length > 1 &&
                                                `MAAT: ${e.innerProducts[
                                                  e.onInnerProductAt
                                                ].maat
                                                }`}
                                            </p>
                                          </div>
                                          <div
                                            className="order-total-price-row summary-row"
                                            style={{
                                              borderBottom: 0,
                                              maxWidth: 960,
                                              fontFamily: "Roboto",
                                              marginBottom: 0,
                                              paddingBottom: 0,
                                            }}
                                          >
                                            <p
                                              className="normalprice-cpgm"
                                              style={{ marginBottom: 0 }}
                                            >
                                              Prijs €
                                              {
                                                e.innerProducts[
                                                  e.onInnerProductAt
                                                ].prijs
                                              }
                                            </p>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  <div
                                    className="order-totals-block"
                                    style={{
                                      position: "relative",
                                      width: "100%",
                                      margin: "0px",
                                      padding: 10,
                                      borderTop: "1px solid #F6F4F2",
                                      marginTop: 10,
                                      alignItems: "flex-start",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <div
                                      className="order-total-price-row summary-row"
                                      style={{
                                        borderBottom: "none",
                                        flexDirection: "column",
                                        marginBottom: 0,
                                        paddingTop: 21,
                                      }}
                                    >
                                      <div
                                        className="item"
                                        style={{ marginBottom: 10 }}
                                      >
                                        <font>KLANTGEGEVENS</font>
                                      </div>
                                      <p className="addres-otb">
                                        {this.getBuyerNameOutgoing(y.buyer)}
                                      </p>
                                      <p className="addres-otb">
                                        {this.getAddressOutgoing(y.buyer)}
                                      </p>
                                      <p className="addres-otb">
                                        {this.getAddressOutgoingSecond(y.buyer)}
                                      </p>
                                      <p className="addres-otb telephone-otb">
                                        T:{" "}
                                        {this.getAddressOutgoingPhone(y.buyer)}
                                      </p>
                                      <p className="addres-otb">
                                        E:{" "}
                                        {this.getAddressOutgoingEmail(y.buyer)}
                                      </p>
                                    </div>
                                    <div
                                      className="order-total-price-row summary-row"
                                      style={{
                                        borderBottom: "none",
                                        flexDirection: "column",
                                        marginBottom: 0,
                                        paddingTop: 21,
                                      }}
                                    >
                                      <div
                                        className="item"
                                        style={{ marginBottom: 10 }}
                                      >
                                        <font>Aanbieder</font>
                                      </div>
                                      <p className="addres-otb">
                                        {this.getBuyerNameOutgoing(
                                          y.sellers[0]
                                        )}
                                      </p>
                                      <p className="addres-otb">
                                        {this.getAddressOutgoing(y.sellers[0])}
                                      </p>
                                      <p className="addres-otb">
                                        {this.getAddressOutgoingSecond(
                                          y.sellers[0]
                                        )}
                                      </p>
                                      <p className="addres-otb telephone-otb">
                                        T:{" "}
                                        {this.getAddressOutgoingPhone(
                                          y.sellers[0]
                                        )}
                                      </p>
                                      <p className="addres-otb">
                                        E:{" "}
                                        {this.getAddressOutgoingEmail(
                                          y.sellers[0]
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        } else {
                          return (
                            <div
                              className="all-orders-list orderlistmobiledesign"
                              key={Math.random()}
                            >
                              {y.products[0].campaignType ===
                                "live_event_own" ? (
                                <h3 className="barlowcondensedfont olmd-heading">
                                  {this.getPerfectDate(y.created_on)} |{" "}
                                  {this.getSellerNameCampaignOnly({
                                    created_by: y.sellers[0],
                                  })}
                                </h3>
                              ) : (
                                <h3 className="barlowcondensedfont olmd-heading">
                                  {this.getPerfectDate(y.created_on)} |{" "}
                                  {this.getSellerNameCampaignOnly(
                                    y.products[0]
                                  )}
                                </h3>
                              )}
                              <div
                                className="order-block"
                                style={{ width: "100%", marginLeft: 0 }}
                              >
                                <div
                                  className="order-block-inner"
                                  onClick={() => {
                                    let element = document.getElementById(
                                      `orderdropdowndetails${i}`
                                    );
                                    if (element.style.display === "none") {
                                      element.style.display = "block";
                                    } else {
                                      element.style.display = "none";
                                    }
                                  }}
                                >
                                  <div className="order-image dottoShowOnImageIdentity">
                                    {y.products[0].campaignType ===
                                      "live_event" ? (
                                      <img src="https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9" />
                                    ) : y.products[0].campaignType ===
                                      "charity" ? (
                                      <img
                                        src={
                                          y.products[0].dishesObjected[0].image
                                        }
                                        alt="order"
                                      />
                                    ) : (
                                      <img
                                        src={y.products[0].image}
                                        alt="order"
                                      />
                                    )}
                                    {/* {y.products[0].campaignType === "charity" ? <img src={y.products[0].dishesObjected[0].image} alt="order" /> : <img src={y.products[0].image} alt="order" />} */}
                                  </div>
                                  <div className="order-details">
                                    <div className="top-row">
                                      <div className="order-detail-number title-odn">
                                        <span>
                                          {y.products[0].campaignType ===
                                            "charity" ? (
                                            <font>
                                              {y.products[0].main_title.length >
                                                25
                                                ? `${y.products[0].main_title.substr(
                                                  0,
                                                  25
                                                )}...`
                                                : y.products[0].main_title}
                                            </font>
                                          ) : (
                                            <font>
                                              {y.products[0].title.length > 25
                                                ? `${y.products[0].title.substr(
                                                  0,
                                                  25
                                                )}...`
                                                : y.products[0].title}
                                            </font>
                                          )}
                                        </span>
                                      </div>
                                      <div className="order-detail-number ordernumber-odn">
                                        <span>Ordernummer: {y.order_id}</span>
                                      </div>
                                      {this.checkForOrderStatus(y)}
                                    </div>
                                  </div>
                                  <div className="order-arrow">
                                    <span>
                                      <ExpandMoreIcon
                                        style={{ fontSize: 30 }}
                                      />
                                    </span>
                                  </div>
                                </div>
                                <div
                                  className="order-dropdown-details"
                                  id={`orderdropdowndetails${i}`}
                                  style={{
                                    display: "none",
                                    position: "relative",
                                  }}
                                >
                                  {window.innerWidth > 1009 &&
                                    this.checkForButtonPopup(y)}
                                  {y.products[0].campaignType === "charity" ? (
                                    <div
                                      className="order-totals-block"
                                      style={{
                                        alignItems: "flex-start",
                                        minHeight: "auto",
                                      }}
                                    >
                                      <div
                                        className="order-total-price-row summary-row"
                                        style={{
                                          borderBottom: 0,
                                          maxWidth: 960,
                                          fontFamily: "Roboto",
                                          marginBottom: 0,
                                          paddingBottom: 0,
                                        }}
                                      >
                                        <p
                                          className="title-lds-shop"
                                          style={{ marginBottom: 0 }}
                                        >
                                          {y.products[0].main_title}
                                        </p>
                                      </div>
                                      <div
                                        className="order-total-price-row summary-row"
                                        style={{
                                          borderBottom: 0,
                                          maxWidth: 960,
                                          fontFamily: "Roboto",
                                          marginBottom: 0,
                                          paddingBottom: 0,
                                        }}
                                      >
                                        <p
                                          className="normalprice-cpgm"
                                          style={{ marginBottom: 0 }}
                                        >
                                          CHARITY MENU: AANTAL {y.peopleComing}{" "}
                                          €
                                          {y.products[0].main_price *
                                            Number(y.peopleComing)}
                                        </p>
                                      </div>
                                      {window.innerWidth < 1009 &&
                                        this.checkForButtonPopup(y)}
                                    </div>
                                  ) : y.products[0].campaignType ===
                                    "last_minute" ? (
                                    <div
                                      className="order-totals-block"
                                      style={{
                                        alignItems: "flex-start",
                                        minHeight: "auto",
                                      }}
                                    >
                                      <div
                                        className="order-total-price-row summary-row"
                                        style={{
                                          borderBottom: 0,
                                          maxWidth: 960,
                                          fontFamily: "Roboto",
                                          marginBottom: 0,
                                          paddingBottom: 0,
                                        }}
                                      >
                                        <p
                                          className="title-lds-shop"
                                          style={{ marginBottom: 0 }}
                                        >
                                          {y.products[0].title}
                                        </p>
                                      </div>
                                      <div
                                        className="order-total-price-row summary-row"
                                        style={{
                                          borderBottom: 0,
                                          maxWidth: 960,
                                          fontFamily: "Roboto",
                                          marginBottom: 0,
                                          paddingBottom: 0,
                                        }}
                                      >
                                        <p
                                          className="normalprice-cpgm"
                                          style={{ marginBottom: 0 }}
                                        >
                                          EVENT: Korting{" "}
                                          {y.products[0].lastMinuteType ===
                                            "percentage" ? (
                                            <font>{y.products[0].amount}%</font>
                                          ) : (
                                            <font>€{y.products[0].amount}</font>
                                          )}
                                        </p>
                                      </div>
                                      <div
                                        className="order-total-price-row summary-row"
                                        style={{
                                          borderBottom: 0,
                                          maxWidth: 960,
                                          fontFamily: "Roboto",
                                          marginBottom: 0,
                                          paddingBottom: 0,
                                          textTransform: "none",
                                        }}
                                      >
                                        <p
                                          className="desc-hading-lds"
                                          style={{
                                            color: "rgb(33, 37, 41)",
                                            fontSize: 13,
                                            fontWeight: 500,
                                            letterSpacing: 1,
                                          }}
                                        >
                                          Omschrijving
                                        </p>
                                      </div>
                                      <div
                                        className="order-total-price-row summary-row"
                                        style={{
                                          borderBottom: 0,
                                          maxWidth: 960,
                                          fontFamily: "Roboto",
                                        }}
                                      >
                                        <p className="desc-lds">
                                          {y.products[0].desc}
                                        </p>
                                      </div>
                                      <div
                                        className="order-total-price-row summary-row"
                                        style={{
                                          borderBottom: 0,
                                          maxWidth: 960,
                                          fontFamily: "Roboto",
                                          marginBottom: 0,
                                          paddingBottom: 0,
                                          textTransform: "none",
                                        }}
                                      >
                                        <p
                                          className="desc-hading-lds"
                                          style={{
                                            color: "rgb(33, 37, 41)",
                                            fontSize: 13,
                                            fontWeight: 500,
                                            letterSpacing: 1,
                                            marginTop: 0,
                                          }}
                                        >
                                          Datum
                                        </p>
                                      </div>
                                      <div
                                        className="order-total-price-row summary-row"
                                        style={{
                                          borderBottom: 0,
                                          maxWidth: 960,
                                          fontFamily: "Roboto",
                                        }}
                                      >
                                        <p className="desc-lds">
                                          {this.showDateToUserTiming(
                                            y.products[0]
                                          )}
                                        </p>
                                      </div>
                                      {window.innerWidth < 1009 &&
                                        this.checkForButtonPopup(y)}
                                    </div>
                                  ) : y.products[0].campaignType ===
                                    "local_event" ? (
                                    <div
                                      className="order-totals-block"
                                      style={{
                                        alignItems: "flex-start",
                                        minHeight: "auto",
                                      }}
                                    >
                                      <div
                                        className="order-total-price-row summary-row"
                                        style={{
                                          borderBottom: 0,
                                          maxWidth: 960,
                                          fontFamily: "Roboto",
                                          marginBottom: 0,
                                          paddingBottom: 0,
                                        }}
                                      >
                                        <p
                                          className="title-lds-shop"
                                          style={{ marginBottom: 0 }}
                                        >
                                          {y.products[0].title}
                                        </p>
                                      </div>
                                      {y.products[0].amount && (<div
                                        className="order-total-price-row summary-row"
                                        style={{
                                          borderBottom: 0,
                                          maxWidth: 960,
                                          fontFamily: "Roboto",
                                          marginBottom: 0,
                                          paddingBottom: 0,
                                        }}
                                      >
                                        <p
                                          className="normalprice-cpgm"
                                          style={{ marginBottom: 0 }}
                                        >
                                          EVENT: Korting{" "}
                                          {y.products[0].localEventType ===
                                            "percentage" ? (
                                            <font>{y.products[0].amount}%</font>
                                          ) : (
                                            <font>€{y.products[0].amount}</font>
                                          )}
                                        </p>
                                      </div>)}
                                      <div
                                        className="order-total-price-row summary-row"
                                        style={{
                                          borderBottom: 0,
                                          maxWidth: 960,
                                          fontFamily: "Roboto",
                                          marginBottom: 0,
                                          paddingBottom: 0,
                                          textTransform: "none",
                                        }}
                                      >
                                        <p
                                          className="desc-hading-lds"
                                          style={{
                                            color: "rgb(33, 37, 41)",
                                            fontSize: 13,
                                            fontWeight: 500,
                                            letterSpacing: 1,
                                          }}
                                        >
                                          Omschrijving
                                        </p>
                                      </div>
                                      <div
                                        className="order-total-price-row summary-row"
                                        style={{
                                          borderBottom: 0,
                                          maxWidth: 960,
                                          fontFamily: "Roboto",
                                        }}
                                      >
                                        <p className="desc-lds">
                                          {y.products[0].desc}
                                        </p>
                                      </div>
                                      <div
                                        className="order-total-price-row summary-row"
                                        style={{
                                          borderBottom: 0,
                                          maxWidth: 960,
                                          fontFamily: "Roboto",
                                          marginBottom: 0,
                                          paddingBottom: 0,
                                          textTransform: "none",
                                        }}
                                      >
                                        <p
                                          className="desc-hading-lds"
                                          style={{
                                            color: "rgb(33, 37, 41)",
                                            fontSize: 13,
                                            fontWeight: 500,
                                            letterSpacing: 1,
                                            marginTop: 0,
                                          }}
                                        >
                                          Datum
                                        </p>
                                      </div>
                                      <div
                                        className="order-total-price-row summary-row"
                                        style={{
                                          borderBottom: 0,
                                          maxWidth: 960,
                                          fontFamily: "Roboto",
                                        }}
                                      >
                                        <p className="desc-lds">
                                          {this.showDateToUserTiming(
                                            y.products[0]
                                          )}
                                        </p>
                                      </div>
                                      {window.innerWidth < 1009 &&
                                        this.checkForButtonPopup(y)}
                                    </div>
                                  ) : (
                                    y.products[0].campaignType ===
                                    "doorlopende" && (
                                      <div
                                        className="order-totals-block"
                                        style={{
                                          alignItems: "flex-start",
                                          minHeight: "auto",
                                        }}
                                      >
                                        <div
                                          className="order-total-price-row summary-row"
                                          style={{
                                            borderBottom: 0,
                                            maxWidth: 960,
                                            fontFamily: "Roboto",
                                            marginBottom: 0,
                                            paddingBottom: 0,
                                          }}
                                        >
                                          <p
                                            className="title-lds-shop"
                                            style={{ marginBottom: 0 }}
                                          >
                                            {y.products[0].title}
                                          </p>
                                        </div>
                                        <div
                                          className="order-total-price-row summary-row"
                                          style={{
                                            borderBottom: 0,
                                            maxWidth: 960,
                                            fontFamily: "Roboto",
                                            marginBottom: 0,
                                            paddingBottom: 0,
                                          }}
                                        >
                                          <p
                                            className="normalprice-cpgm"
                                            style={{ marginBottom: 0 }}
                                          >
                                            Kortingsvoucher{" "}
                                            {y.products[0].webShopType ===
                                              "percentage" ? (
                                              <font>
                                                {y.products[0].amount}%
                                              </font>
                                            ) : (
                                              <font>
                                                €{y.products[0].amount}
                                              </font>
                                            )}
                                          </p>
                                        </div>
                                        <div
                                          className="order-total-price-row summary-row"
                                          style={{
                                            borderBottom: 0,
                                            maxWidth: 960,
                                            fontFamily: "Roboto",
                                            marginBottom: 0,
                                            paddingBottom: 0,
                                            textTransform: "none",
                                          }}
                                        >
                                          <p
                                            className="desc-hading-lds"
                                            style={{
                                              color: "rgb(33, 37, 41)",
                                              fontSize: 13,
                                              fontWeight: 500,
                                              letterSpacing: 1,
                                            }}
                                          >
                                            Omschrijving
                                          </p>
                                        </div>
                                        <div
                                          className="order-total-price-row summary-row"
                                          style={{
                                            borderBottom: 0,
                                            maxWidth: 960,
                                            fontFamily: "Roboto",
                                          }}
                                        >
                                          <p className="desc-lds">
                                            {y.products[0].desc}
                                          </p>
                                        </div>
                                        {window.innerWidth < 1009 &&
                                          this.checkForButtonPopup(y)}
                                      </div>
                                    )
                                  )}
                                  {y.products[0].campaignType ===
                                    "live_event_own" &&
                                    y.products.map((e) => {
                                      return (
                                        <div
                                          className="order-totals-block"
                                          style={{
                                            alignItems: "flex-start",
                                            minHeight: "auto",
                                          }}
                                        >
                                          <div
                                            className="order-total-price-row summary-row"
                                            style={{
                                              borderBottom: 0,
                                              maxWidth: 960,
                                              fontFamily: "Roboto",
                                              marginBottom: 0,
                                              paddingBottom: 0,
                                            }}
                                          >
                                            <p
                                              className="title-lds-shop"
                                              style={{ marginBottom: 0 }}
                                            >
                                              {e.description}{" "}
                                              {e.innerProducts.length > 1 &&
                                                `MAAT: ${e.innerProducts[
                                                  e.onInnerProductAt
                                                ].maat
                                                }`}
                                            </p>
                                          </div>
                                          <div
                                            className="order-total-price-row summary-row"
                                            style={{
                                              borderBottom: 0,
                                              maxWidth: 960,
                                              fontFamily: "Roboto",
                                              marginBottom: 0,
                                              paddingBottom: 0,
                                            }}
                                          >
                                            <p
                                              className="normalprice-cpgm"
                                              style={{ marginBottom: 0 }}
                                            >
                                              Prijs €
                                              {
                                                e.innerProducts[
                                                  e.onInnerProductAt
                                                ].prijs
                                              }
                                            </p>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  <div
                                    className="order-totals-block"
                                    style={{
                                      position: "relative",
                                      width: "100%",
                                      margin: "0px",
                                      padding: 10,
                                      borderTop: "1px solid #F6F4F2",
                                      marginTop: 10,
                                      alignItems: "flex-start",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <div
                                      className="order-total-price-row summary-row"
                                      style={{
                                        borderBottom: "none",
                                        flexDirection: "column",
                                        marginBottom: 0,
                                        paddingTop: 21,
                                      }}
                                    >
                                      <div
                                        className="item"
                                        style={{ marginBottom: 10 }}
                                      >
                                        <font>KLANTGEGEVENS</font>
                                      </div>
                                      <p className="addres-otb">
                                        {this.getBuyerNameOutgoing(y.buyer)}
                                      </p>
                                      <p className="addres-otb">
                                        {this.getAddressOutgoing(y.buyer)}
                                      </p>
                                      <p className="addres-otb">
                                        {this.getAddressOutgoingSecond(y.buyer)}
                                      </p>
                                      <p className="addres-otb telephone-otb">
                                        T:{" "}
                                        {this.getAddressOutgoingPhone(y.buyer)}
                                      </p>
                                      <p className="addres-otb">
                                        E:{" "}
                                        {this.getAddressOutgoingEmail(y.buyer)}
                                      </p>
                                    </div>
                                    <div
                                      className="order-total-price-row summary-row"
                                      style={{
                                        borderBottom: "none",
                                        flexDirection: "column",
                                        marginBottom: 0,
                                        paddingTop: 21,
                                      }}
                                    >
                                      <div
                                        className="item"
                                        style={{ marginBottom: 10 }}
                                      >
                                        <font>Aanbieder</font>
                                      </div>
                                      <p className="addres-otb">
                                        {this.getBuyerNameOutgoing(
                                          y.sellers[0]
                                        )}
                                      </p>
                                      <p className="addres-otb">
                                        {this.getAddressOutgoing(y.sellers[0])}
                                      </p>
                                      <p className="addres-otb">
                                        {this.getAddressOutgoingSecond(
                                          y.sellers[0]
                                        )}
                                      </p>
                                      <p className="addres-otb telephone-otb">
                                        T:{" "}
                                        {this.getAddressOutgoingPhone(
                                          y.sellers[0]
                                        )}
                                      </p>
                                      <p className="addres-otb">
                                        E:{" "}
                                        {this.getAddressOutgoingEmail(
                                          y.sellers[0]
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      }
                    })
                  ) : this.state.arrOrders.length > 0 ? (
                    this.state.arrOrders.map((y, i) => {
                      if (y.order_type === "campaign") {
                        if (window.innerWidth > 1009) {
                          return (
                            <div
                              className="all-orders-list"
                              key={Math.random()}
                            >
                              <div
                                className="order-block"
                                style={{ width: "100%", marginLeft: 0 }}
                              >
                                <div
                                  className="order-block-inner"
                                  onClick={() => {
                                    let element = document.getElementById(
                                      `orderdropdowndetails${i}`
                                    );
                                    if (element.style.display === "none") {
                                      element.style.display = "block";
                                    } else {
                                      element.style.display = "none";
                                    }
                                  }}
                                >
                                  <div className="order-image dottoShowOnImageIdentity">
                                    {y.products[0].campaignType ===
                                      "live_event" ? (
                                      <img src="https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9" />
                                    ) : y.products[0].campaignType ===
                                      "charity" ? (
                                      <img
                                        src={
                                          y.products[0].dishesObjected[0].image
                                        }
                                        alt="order"
                                      />
                                    ) : (
                                      <img
                                        src={y.products[0].image}
                                        alt="order"
                                      />
                                    )}
                                    {/* {y.products[0].campaignType === "charity" ? <img src={y.products[0].dishesObjected[0].image} alt="order" /> : <img src={y.products[0].image} alt="order" />} */}
                                  </div>
                                  <div className="order-details">
                                    {y.products[0].campaignType ===
                                      "live_event_own" ? (
                                      <div className="top-row">
                                        {window.innerWidth > 1009 ? (
                                          <div className="order-detail-date">
                                            <span>
                                              {this.getPerfectDate(
                                                y.created_on
                                              )}{" "}
                                              |{" "}
                                              {this.getSellerNameCampaignOnly({
                                                created_by: y.sellers[0],
                                              })}
                                            </span>
                                          </div>
                                        ) : (
                                          <div className="order-detail-date">
                                            <span>
                                              {this.getSellerNameCampaignOnly({
                                                created_by: y.sellers[0],
                                              })}
                                            </span>
                                          </div>
                                        )}
                                        {window.innerWidth > 1009 ? (
                                          <div className="order-detail-number">
                                            <span>
                                              Ordernummer: {y.order_id} |{" "}
                                              <font>
                                                {y.products[0].description}
                                              </font>
                                            </span>
                                          </div>
                                        ) : (
                                          <div className="order-detail-number">
                                            <span>
                                              <font>
                                                {y.products[0].description}
                                              </font>
                                            </span>
                                          </div>
                                        )}
                                      </div>
                                    ) : (
                                      <div className="top-row">
                                        {window.innerWidth > 1009 ? (
                                          <div className="order-detail-date">
                                            <span>
                                              {this.getPerfectDate(
                                                y.created_on
                                              )}{" "}
                                              |{" "}
                                              {this.getSellerNameCampaignOnly(
                                                y.products[0]
                                              )}
                                            </span>
                                          </div>
                                        ) : (
                                          <div className="order-detail-date">
                                            <span>
                                              {this.getSellerNameCampaignOnly(
                                                y.products[0]
                                              )}
                                            </span>
                                          </div>
                                        )}
                                        {window.innerWidth > 1009 ? (
                                          <div className="order-detail-number">
                                            <span>
                                              Ordernummer: {y.order_id} |{" "}
                                              {y.products[0].campaignType ===
                                                "charity" ? (
                                                <font>
                                                  {y.products[0].main_title}
                                                </font>
                                              ) : (
                                                <font>
                                                  {y.products[0].title}
                                                </font>
                                              )}
                                            </span>
                                          </div>
                                        ) : (
                                          <div className="order-detail-number">
                                            <span>
                                              {y.products[0].campaignType ===
                                                "charity" ? (
                                                <font>
                                                  {y.products[0].main_title}
                                                </font>
                                              ) : (
                                                <font>
                                                  {y.products[0].title}
                                                </font>
                                              )}
                                            </span>
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                  {this.checkForGebruikTextRight(y)}
                                  <div className="order-arrow">
                                    <span>
                                      <ExpandMoreIcon
                                        style={{ fontSize: 30 }}
                                      />
                                    </span>
                                  </div>
                                </div>
                                <div
                                  className="order-dropdown-details"
                                  id={`orderdropdowndetails${i}`}
                                  style={{
                                    display: "none",
                                    position: "relative",
                                  }}
                                >
                                  {window.innerWidth > 1009 &&
                                    this.checkForButtonPopup(y)}
                                  {y.products[0].campaignType === "charity" ? (
                                    <div
                                      className="order-totals-block"
                                      style={{
                                        alignItems: "flex-start",
                                        minHeight: "auto",
                                      }}
                                    >
                                      <div
                                        className="order-total-price-row summary-row"
                                        style={{
                                          borderBottom: 0,
                                          maxWidth: 960,
                                          fontFamily: "Roboto",
                                          marginBottom: 0,
                                          paddingBottom: 0,
                                        }}
                                      >
                                        <p
                                          className="title-lds-shop"
                                          style={{ marginBottom: 0 }}
                                        >
                                          {y.products[0].main_title}
                                        </p>
                                      </div>
                                      <div
                                        className="order-total-price-row summary-row"
                                        style={{
                                          borderBottom: 0,
                                          maxWidth: 960,
                                          fontFamily: "Roboto",
                                          marginBottom: 0,
                                          paddingBottom: 0,
                                        }}
                                      >
                                        <p
                                          className="normalprice-cpgm"
                                          style={{ marginBottom: 0 }}
                                        >
                                          CHARITY MENU: AANTAL {y.peopleComing}{" "}
                                          €
                                          {y.products[0].main_price *
                                            Number(y.peopleComing)}
                                        </p>
                                      </div>
                                      {window.innerWidth < 1009 &&
                                        this.checkForButtonPopup(y)}
                                    </div>
                                  ) : y.products[0].campaignType ===
                                    "last_minute" ? (
                                    <div
                                      className="order-totals-block"
                                      style={{
                                        alignItems: "flex-start",
                                        minHeight: "auto",
                                      }}
                                    >
                                      <div
                                        className="order-total-price-row summary-row"
                                        style={{
                                          borderBottom: 0,
                                          maxWidth: 960,
                                          fontFamily: "Roboto",
                                          marginBottom: 0,
                                          paddingBottom: 0,
                                        }}
                                      >
                                        <p
                                          className="title-lds-shop"
                                          style={{ marginBottom: 0 }}
                                        >
                                          {y.products[0].title}
                                        </p>
                                      </div>
                                      <div
                                        className="order-total-price-row summary-row"
                                        style={{
                                          borderBottom: 0,
                                          maxWidth: 960,
                                          fontFamily: "Roboto",
                                          marginBottom: 0,
                                          paddingBottom: 0,
                                        }}
                                      >
                                        <p
                                          className="normalprice-cpgm"
                                          style={{ marginBottom: 0 }}
                                        >
                                          EVENT: Korting{" "}
                                          {y.products[0].lastMinuteType ===
                                            "percentage" ? (
                                            <font>{y.products[0].amount}%</font>
                                          ) : (
                                            <font>€{y.products[0].amount}</font>
                                          )}
                                        </p>
                                      </div>
                                      <div
                                        className="order-total-price-row summary-row"
                                        style={{
                                          borderBottom: 0,
                                          maxWidth: 960,
                                          fontFamily: "Roboto",
                                          marginBottom: 0,
                                          paddingBottom: 0,
                                          textTransform: "none",
                                        }}
                                      >
                                        <p
                                          className="desc-hading-lds"
                                          style={{
                                            color: "rgb(33, 37, 41)",
                                            fontSize: 13,
                                            fontWeight: 500,
                                            letterSpacing: 1,
                                          }}
                                        >
                                          Omschrijving
                                        </p>
                                      </div>
                                      <div
                                        className="order-total-price-row summary-row"
                                        style={{
                                          borderBottom: 0,
                                          maxWidth: 960,
                                          fontFamily: "Roboto",
                                        }}
                                      >
                                        <p className="desc-lds">
                                          {y.products[0].desc}
                                        </p>
                                      </div>
                                      <div
                                        className="order-total-price-row summary-row"
                                        style={{
                                          borderBottom: 0,
                                          maxWidth: 960,
                                          fontFamily: "Roboto",
                                          marginBottom: 0,
                                          paddingBottom: 0,
                                          textTransform: "none",
                                        }}
                                      >
                                        <p
                                          className="desc-hading-lds"
                                          style={{
                                            color: "rgb(33, 37, 41)",
                                            fontSize: 13,
                                            fontWeight: 500,
                                            letterSpacing: 1,
                                            marginTop: 0,
                                          }}
                                        >
                                          Datum
                                        </p>
                                      </div>
                                      <div
                                        className="order-total-price-row summary-row"
                                        style={{
                                          borderBottom: 0,
                                          maxWidth: 960,
                                          fontFamily: "Roboto",
                                        }}
                                      >
                                        <p className="desc-lds">
                                          {this.showDateToUserTiming(
                                            y.products[0]
                                          )}
                                        </p>
                                      </div>
                                      {window.innerWidth < 1009 &&
                                        this.checkForButtonPopup(y)}
                                    </div>
                                  ) : y.products[0].campaignType ===
                                    "local_event" ? (
                                    <div
                                      className="order-totals-block"
                                      style={{
                                        alignItems: "flex-start",
                                        minHeight: "auto",
                                      }}
                                    >
                                      <div
                                        className="order-total-price-row summary-row"
                                        style={{
                                          borderBottom: 0,
                                          maxWidth: 960,
                                          fontFamily: "Roboto",
                                          marginBottom: 0,
                                          paddingBottom: 0,
                                        }}
                                      >
                                        <p
                                          className="title-lds-shop"
                                          style={{ marginBottom: 0 }}
                                        >
                                          {y.products[0].title}
                                        </p>
                                      </div>
                                      {y.products[0].amount && (<div
                                        className="order-total-price-row summary-row"
                                        style={{
                                          borderBottom: 0,
                                          maxWidth: 960,
                                          fontFamily: "Roboto",
                                          marginBottom: 0,
                                          paddingBottom: 0,
                                        }}
                                      >
                                        <p
                                          className="normalprice-cpgm"
                                          style={{ marginBottom: 0 }}
                                        >
                                          EVENT: Korting{" "}
                                          {y.products[0].localEventType ===
                                            "percentage" ? (
                                            <font>{y.products[0].amount}%</font>
                                          ) : (
                                            <font>€{y.products[0].amount}</font>
                                          )}
                                        </p>
                                      </div>)}
                                      <div
                                        className="order-total-price-row summary-row"
                                        style={{
                                          borderBottom: 0,
                                          maxWidth: 960,
                                          fontFamily: "Roboto",
                                          marginBottom: 0,
                                          paddingBottom: 0,
                                          textTransform: "none",
                                        }}
                                      >
                                        <p
                                          className="desc-hading-lds"
                                          style={{
                                            color: "rgb(33, 37, 41)",
                                            fontSize: 13,
                                            fontWeight: 500,
                                            letterSpacing: 1,
                                          }}
                                        >
                                          Omschrijving
                                        </p>
                                      </div>
                                      <div
                                        className="order-total-price-row summary-row"
                                        style={{
                                          borderBottom: 0,
                                          maxWidth: 960,
                                          fontFamily: "Roboto",
                                        }}
                                      >
                                        <p className="desc-lds">
                                          {y.products[0].desc}
                                        </p>
                                      </div>
                                      <div
                                        className="order-total-price-row summary-row"
                                        style={{
                                          borderBottom: 0,
                                          maxWidth: 960,
                                          fontFamily: "Roboto",
                                          marginBottom: 0,
                                          paddingBottom: 0,
                                          textTransform: "none",
                                        }}
                                      >
                                        <p
                                          className="desc-hading-lds"
                                          style={{
                                            color: "rgb(33, 37, 41)",
                                            fontSize: 13,
                                            fontWeight: 500,
                                            letterSpacing: 1,
                                            marginTop: 0,
                                          }}
                                        >
                                          Datum
                                        </p>
                                      </div>
                                      <div
                                        className="order-total-price-row summary-row"
                                        style={{
                                          borderBottom: 0,
                                          maxWidth: 960,
                                          fontFamily: "Roboto",
                                        }}
                                      >
                                        <p className="desc-lds">
                                          {this.showDateToUserTiming(
                                            y.products[0]
                                          )}
                                        </p>
                                      </div>
                                      {window.innerWidth < 1009 &&
                                        this.checkForButtonPopup(y)}
                                    </div>
                                  ) : (
                                    y.products[0].campaignType ===
                                    "doorlopende" && (
                                      <div
                                        className="order-totals-block"
                                        style={{
                                          alignItems: "flex-start",
                                          minHeight: "auto",
                                        }}
                                      >
                                        <div
                                          className="order-total-price-row summary-row"
                                          style={{
                                            borderBottom: 0,
                                            maxWidth: 960,
                                            fontFamily: "Roboto",
                                            marginBottom: 0,
                                            paddingBottom: 0,
                                          }}
                                        >
                                          <p
                                            className="title-lds-shop"
                                            style={{ marginBottom: 0 }}
                                          >
                                            {y.products[0].title}
                                          </p>
                                        </div>
                                        <div
                                          className="order-total-price-row summary-row"
                                          style={{
                                            borderBottom: 0,
                                            maxWidth: 960,
                                            fontFamily: "Roboto",
                                            marginBottom: 0,
                                            paddingBottom: 0,
                                          }}
                                        >
                                          <p
                                            className="normalprice-cpgm"
                                            style={{ marginBottom: 0 }}
                                          >
                                            Kortingsvoucher{" "}
                                            {y.products[0].webShopType ===
                                              "percentage" ? (
                                              <font>
                                                {y.products[0].amount}%
                                              </font>
                                            ) : (
                                              <font>
                                                €{y.products[0].amount}
                                              </font>
                                            )}
                                          </p>
                                        </div>
                                        <div
                                          className="order-total-price-row summary-row"
                                          style={{
                                            borderBottom: 0,
                                            maxWidth: 960,
                                            fontFamily: "Roboto",
                                            marginBottom: 0,
                                            paddingBottom: 0,
                                            textTransform: "none",
                                          }}
                                        >
                                          <p
                                            className="desc-hading-lds"
                                            style={{
                                              color: "rgb(33, 37, 41)",
                                              fontSize: 13,
                                              fontWeight: 500,
                                              letterSpacing: 1,
                                            }}
                                          >
                                            Omschrijving
                                          </p>
                                        </div>
                                        <div
                                          className="order-total-price-row summary-row"
                                          style={{
                                            borderBottom: 0,
                                            maxWidth: 960,
                                            fontFamily: "Roboto",
                                          }}
                                        >
                                          <p className="desc-lds">
                                            {y.products[0].desc}
                                          </p>
                                        </div>
                                        {window.innerWidth < 1009 &&
                                          this.checkForButtonPopup(y)}
                                      </div>
                                    )
                                  )}
                                  {y.products[0].campaignType ===
                                    "live_event_own" &&
                                    y.products.map((e) => {
                                      return (
                                        <div
                                          className="order-totals-block"
                                          style={{
                                            alignItems: "flex-start",
                                            minHeight: "auto",
                                          }}
                                        >
                                          <div
                                            className="order-total-price-row summary-row"
                                            style={{
                                              borderBottom: 0,
                                              maxWidth: 960,
                                              fontFamily: "Roboto",
                                              marginBottom: 0,
                                              paddingBottom: 0,
                                            }}
                                          >
                                            <p
                                              className="title-lds-shop"
                                              style={{ marginBottom: 0 }}
                                            >
                                              {e.description}{" "}
                                              {e.innerProducts.length > 1 &&
                                                `MAAT: ${e.innerProducts[
                                                  e.onInnerProductAt
                                                ].maat
                                                }`}
                                            </p>
                                          </div>
                                          <div
                                            className="order-total-price-row summary-row"
                                            style={{
                                              borderBottom: 0,
                                              maxWidth: 960,
                                              fontFamily: "Roboto",
                                              marginBottom: 0,
                                              paddingBottom: 0,
                                            }}
                                          >
                                            <p
                                              className="normalprice-cpgm"
                                              style={{ marginBottom: 0 }}
                                            >
                                              Prijs €
                                              {
                                                e.innerProducts[
                                                  e.onInnerProductAt
                                                ].prijs
                                              }
                                            </p>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  <div
                                    className="order-totals-block"
                                    style={{
                                      position: "relative",
                                      width: "100%",
                                      margin: "0px",
                                      padding: 10,
                                      borderTop: "1px solid #F6F4F2",
                                      marginTop: 10,
                                      alignItems: "flex-start",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <div
                                      className="order-total-price-row summary-row"
                                      style={{
                                        borderBottom: "none",
                                        flexDirection: "column",
                                        marginBottom: 0,
                                        paddingTop: 21,
                                      }}
                                    >
                                      <div
                                        className="item"
                                        style={{ marginBottom: 10 }}
                                      >
                                        <font>KLANTGEGEVENS</font>
                                      </div>
                                      <p className="addres-otb">
                                        {this.getBuyerNameOutgoing(y.buyer)}
                                      </p>
                                      <p className="addres-otb">
                                        {this.getAddressOutgoing(y.buyer)}
                                      </p>
                                      <p className="addres-otb">
                                        {this.getAddressOutgoingSecond(y.buyer)}
                                      </p>
                                      <p className="addres-otb telephone-otb">
                                        T:{" "}
                                        {this.getAddressOutgoingPhone(y.buyer)}
                                      </p>
                                      <p className="addres-otb">
                                        E:{" "}
                                        {this.getAddressOutgoingEmail(y.buyer)}
                                      </p>
                                    </div>
                                    <div
                                      className="order-total-price-row summary-row"
                                      style={{
                                        borderBottom: "none",
                                        flexDirection: "column",
                                        marginBottom: 0,
                                        paddingTop: 21,
                                      }}
                                    >
                                      <div
                                        className="item"
                                        style={{ marginBottom: 10 }}
                                      >
                                        <font>Aanbieder</font>
                                      </div>
                                      <p className="addres-otb">
                                        {this.getBuyerNameOutgoing(
                                          y.sellers[0]
                                        )}
                                      </p>
                                      <p className="addres-otb">
                                        {this.getAddressOutgoing(y.sellers[0])}
                                      </p>
                                      <p className="addres-otb">
                                        {this.getAddressOutgoingSecond(
                                          y.sellers[0]
                                        )}
                                      </p>
                                      <p className="addres-otb telephone-otb">
                                        T:{" "}
                                        {this.getAddressOutgoingPhone(
                                          y.sellers[0]
                                        )}
                                      </p>
                                      <p className="addres-otb">
                                        E:{" "}
                                        {this.getAddressOutgoingEmail(
                                          y.sellers[0]
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        } else {
                          return (
                            <div
                              className="all-orders-list orderlistmobiledesign"
                              key={Math.random()}
                            >
                              <h3 className="barlowcondensedfont olmd-heading">
                                {this.getPerfectDate(y.created_on)} |{" "}
                                {this.getSellerNameCampaignOnly(
                                  y.products[0]
                                )}
                              </h3>
                              <div
                                className="order-block"
                                style={{ width: "100%", marginLeft: 0 }}
                              >
                                <div
                                  className="order-block-inner"
                                  onClick={() => {
                                    let element = document.getElementById(
                                      `orderdropdowndetails${i}`
                                    );
                                    if (element.style.display === "none") {
                                      element.style.display = "block";
                                    } else {
                                      element.style.display = "none";
                                    }
                                  }}
                                >
                                  <div className="order-image dottoShowOnImageIdentity">
                                    {y.products[0].campaignType ===
                                      "live_event" ? (
                                      <img src="https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9" />
                                    ) : y.products[0].campaignType ===
                                      "charity" ? (
                                      <img
                                        src={
                                          y.products[0].dishesObjected[0].image
                                        }
                                        alt="order"
                                      />
                                    ) : (
                                      <img
                                        src={y.products[0].image}
                                        alt="order"
                                      />
                                    )}
                                    {/* {y.products[0].campaignType === "charity" ? <img src={y.products[0].dishesObjected[0].image} alt="order" /> : <img src={y.products[0].image} alt="order" />} */}
                                  </div>
                                  <div className="order-details">
                                    <div className="top-row">
                                      <div className="order-detail-number title-odn">
                                        <span>
                                          {y.products[0].campaignType ===
                                            "charity" ? (
                                            <font>
                                              {y.products[0].main_title.length >
                                                25
                                                ? `${y.products[0].main_title.substr(
                                                  0,
                                                  25
                                                )}...`
                                                : y.products[0].main_title}
                                            </font>
                                          ) : (
                                            <font>
                                              {y.products[0].title.length > 25
                                                ? `${y.products[0].title.substr(
                                                  0,
                                                  25
                                                )}...`
                                                : y.products[0].title}
                                            </font>
                                          )}
                                        </span>
                                      </div>
                                      <div className="order-detail-number ordernumber-odn">
                                        <span>Ordernummer: {y.order_id}</span>
                                      </div>
                                      {this.checkForOrderStatus(y)}
                                    </div>
                                  </div>
                                  <div className="order-arrow">
                                    <span>
                                      <ExpandMoreIcon
                                        style={{ fontSize: 30 }}
                                      />
                                    </span>
                                  </div>
                                </div>
                                <div
                                  className="order-dropdown-details"
                                  id={`orderdropdowndetails${i}`}
                                  style={{
                                    display: "none",
                                    position: "relative",
                                  }}
                                >
                                  {window.innerWidth > 1009 &&
                                    this.checkForButtonPopup(y)}
                                  {y.products[0].campaignType ===
                                    "local_event" ? (
                                    <div
                                      className="order-totals-block"
                                      style={{
                                        alignItems: "flex-start",
                                        minHeight: "auto",
                                      }}
                                    >
                                      <div
                                        className="order-total-price-row summary-row"
                                        style={{
                                          borderBottom: 0,
                                          maxWidth: 960,
                                          fontFamily: "Roboto",
                                          marginBottom: 0,
                                          paddingBottom: 0,
                                        }}
                                      >
                                        <p
                                          className="title-lds-shop"
                                          style={{ marginBottom: 0 }}
                                        >
                                          {y.products[0].title}
                                        </p>
                                      </div>
                                      <div
                                        className="order-total-price-row summary-row"
                                        style={{
                                          borderBottom: 0,
                                          maxWidth: 960,
                                          fontFamily: "Roboto",
                                          marginBottom: 0,
                                          paddingBottom: 0,
                                        }}
                                      >
                                        <p
                                          className="normalprice-cpgm"
                                          style={{ marginBottom: 0 }}
                                        >
                                          EVENT: Korting{" "}
                                          {y.products[0].localEventType ===
                                            "percentage" ? (
                                            <font>{y.products[0].amount}%</font>
                                          ) : (
                                            <font>€{y.products[0].amount}</font>
                                          )}
                                        </p>
                                      </div>
                                      <div
                                        className="order-total-price-row summary-row"
                                        style={{
                                          borderBottom: 0,
                                          maxWidth: 960,
                                          fontFamily: "Roboto",
                                          marginBottom: 0,
                                          paddingBottom: 0,
                                          textTransform: "none",
                                        }}
                                      >
                                        <p
                                          className="desc-hading-lds"
                                          style={{
                                            color: "rgb(33, 37, 41)",
                                            fontSize: 13,
                                            fontWeight: 500,
                                            letterSpacing: 1,
                                          }}
                                        >
                                          Omschrijving
                                        </p>
                                      </div>
                                      <div
                                        className="order-total-price-row summary-row"
                                        style={{
                                          borderBottom: 0,
                                          maxWidth: 960,
                                          fontFamily: "Roboto",
                                        }}
                                      >
                                        <p className="desc-lds">
                                          {y.products[0].desc}
                                        </p>
                                      </div>
                                      <div
                                        className="order-total-price-row summary-row"
                                        style={{
                                          borderBottom: 0,
                                          maxWidth: 960,
                                          fontFamily: "Roboto",
                                          marginBottom: 0,
                                          paddingBottom: 0,
                                          textTransform: "none",
                                        }}
                                      >
                                        <p
                                          className="desc-hading-lds"
                                          style={{
                                            color: "rgb(33, 37, 41)",
                                            fontSize: 13,
                                            fontWeight: 500,
                                            letterSpacing: 1,
                                            marginTop: 0,
                                          }}
                                        >
                                          Datum
                                        </p>
                                      </div>
                                      <div
                                        className="order-total-price-row summary-row"
                                        style={{
                                          borderBottom: 0,
                                          maxWidth: 960,
                                          fontFamily: "Roboto",
                                        }}
                                      >
                                        <p className="desc-lds">
                                          {this.showDateToUserTiming(
                                            y.products[0]
                                          )}
                                        </p>
                                      </div>
                                      {window.innerWidth < 1009 &&
                                        this.checkForButtonPopup(y)}
                                    </div>
                                  ) : (
                                    y.products[0].campaignType ===
                                    "doorlopende" && (
                                      <div
                                        className="order-totals-block"
                                        style={{
                                          alignItems: "flex-start",
                                          minHeight: "auto",
                                        }}
                                      >
                                        <div
                                          className="order-total-price-row summary-row"
                                          style={{
                                            borderBottom: 0,
                                            maxWidth: 960,
                                            fontFamily: "Roboto",
                                            marginBottom: 0,
                                            paddingBottom: 0,
                                          }}
                                        >
                                          <p
                                            className="title-lds-shop"
                                            style={{ marginBottom: 0 }}
                                          >
                                            {y.products[0].title}
                                          </p>
                                        </div>
                                        <div
                                          className="order-total-price-row summary-row"
                                          style={{
                                            borderBottom: 0,
                                            maxWidth: 960,
                                            fontFamily: "Roboto",
                                            marginBottom: 0,
                                            paddingBottom: 0,
                                          }}
                                        >
                                          <p
                                            className="normalprice-cpgm"
                                            style={{ marginBottom: 0 }}
                                          >
                                            Kortingsvoucher{" "}
                                            {y.products[0].webShopType ===
                                              "percentage" ? (
                                              <font>
                                                {y.products[0].amount}%
                                              </font>
                                            ) : (
                                              <font>
                                                €{y.products[0].amount}
                                              </font>
                                            )}
                                          </p>
                                        </div>
                                        <div
                                          className="order-total-price-row summary-row"
                                          style={{
                                            borderBottom: 0,
                                            maxWidth: 960,
                                            fontFamily: "Roboto",
                                            marginBottom: 0,
                                            paddingBottom: 0,
                                            textTransform: "none",
                                          }}
                                        >
                                          <p
                                            className="desc-hading-lds"
                                            style={{
                                              color: "rgb(33, 37, 41)",
                                              fontSize: 13,
                                              fontWeight: 500,
                                              letterSpacing: 1,
                                            }}
                                          >
                                            Omschrijving
                                          </p>
                                        </div>
                                        <div
                                          className="order-total-price-row summary-row"
                                          style={{
                                            borderBottom: 0,
                                            maxWidth: 960,
                                            fontFamily: "Roboto",
                                          }}
                                        >
                                          <p className="desc-lds">
                                            {y.products[0].desc}
                                          </p>
                                        </div>
                                        {window.innerWidth < 1009 &&
                                          this.checkForButtonPopup(y)}
                                      </div>
                                    )
                                  )}
                                  <div
                                    className="order-totals-block"
                                    style={{
                                      position: "relative",
                                      width: "100%",
                                      margin: "0px",
                                      padding: 10,
                                      borderTop: "1px solid #F6F4F2",
                                      marginTop: 10,
                                      alignItems: "flex-start",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <div
                                      className="order-total-price-row summary-row"
                                      style={{
                                        borderBottom: "none",
                                        flexDirection: "column",
                                        marginBottom: 0,
                                        paddingTop: 21,
                                      }}
                                    >
                                      <div
                                        className="item"
                                        style={{ marginBottom: 10 }}
                                      >
                                        <font>KLANTGEGEVENS</font>
                                      </div>
                                      <p className="addres-otb">
                                        {this.getBuyerNameOutgoing(y.buyer)}
                                      </p>
                                      <p className="addres-otb">
                                        {this.getAddressOutgoing(y.buyer)}
                                      </p>
                                      <p className="addres-otb">
                                        {this.getAddressOutgoingSecond(y.buyer)}
                                      </p>
                                      <p className="addres-otb telephone-otb">
                                        T:{" "}
                                        {this.getAddressOutgoingPhone(y.buyer)}
                                      </p>
                                      <p className="addres-otb">
                                        E:{" "}
                                        {this.getAddressOutgoingEmail(y.buyer)}
                                      </p>
                                    </div>
                                    <div
                                      className="order-total-price-row summary-row"
                                      style={{
                                        borderBottom: "none",
                                        flexDirection: "column",
                                        marginBottom: 0,
                                        paddingTop: 21,
                                      }}
                                    >
                                      <div
                                        className="item"
                                        style={{ marginBottom: 10 }}
                                      >
                                        <font>Aanbieder</font>
                                      </div>
                                      <p className="addres-otb">
                                        {this.getBuyerNameOutgoing(
                                          y.sellers[0]
                                        )}
                                      </p>
                                      <p className="addres-otb">
                                        {this.getAddressOutgoing(y.sellers[0])}
                                      </p>
                                      <p className="addres-otb">
                                        {this.getAddressOutgoingSecond(
                                          y.sellers[0]
                                        )}
                                      </p>
                                      <p className="addres-otb telephone-otb">
                                        T:{" "}
                                        {this.getAddressOutgoingPhone(
                                          y.sellers[0]
                                        )}
                                      </p>
                                      <p className="addres-otb">
                                        E:{" "}
                                        {this.getAddressOutgoingEmail(
                                          y.sellers[0]
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      }
                    })
                  ) : (
                    !this.state.ordersPresent && (
                      <div className="no-orders">
                        <p>Je hebt nog geen orders om te bekijken</p>
                        {/* {this.state.outgoing ? <p>There are no outgoing orders for this account.</p> : this.state.incoming ? <p>There are no incoming orders for this account.</p> : this.state.campaigns ? <p>There are no incoming campaigns orders for this account.</p> : this.state.vouchersOutgoing ? <p>There are no outgoing exclusive orders for this account.</p> : this.state.vouchersIncoming ? <p>There are no incoming exclusive orders for this account.</p> : this.state.campaignsOutgoing && <p>There are no outgoing campaigns orders for this account.</p>} */}
                        {/* {this.state.outgoing && <div className="btn-container" onClick={() => window.location.href = "/product-list-global"}>
                                            <button>SHOP</button>
                                        </div>} */}
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Order Page popups */}
        <div
          className="verzilver-popup-white-opacity"
          style={{
            opacity: this.state.verzilverPopup ? 1 : 0,
            pointerEvents: this.state.verzilverPopup ? "all" : "none",
          }}
        ></div>
        <div
          className="verzilver-popup-white"
          style={{
            opacity: this.state.verzilverPopup ? 1 : 0,
            pointerEvents: this.state.verzilverPopup ? "all" : "none",
          }}
        >
          <CloseIcon
            className="close-hpw"
            onClick={() =>
              this.setState({ verzilverPopup: false, currentUserOfPopups: "" })
            }
          />
          <LockOpenIcon className="lock-hpw" />
          <p className="heading-hpw">VALIDEER ACTIE</p>
          <p className="desc-hpw">
            Om de actie te kunnen verzilveren, dien je gebruik te maken van een
            mobiele telefoon.
            <br />
            <br />
            Klik op Bewaren om de voucher op een later tijdstip te gebruiken.
          </p>
        </div>

        <div
          className="verzilver-popup-white-opacity"
          style={{
            opacity: this.state.verzilverPopupLastMinute ? 1 : 0,
            pointerEvents: this.state.verzilverPopupLastMinute ? "all" : "none",
          }}
        ></div>
        <div
          className="verzilver-popup-white"
          style={{
            opacity: this.state.verzilverPopupLastMinute ? 1 : 0,
            pointerEvents: this.state.verzilverPopupLastMinute ? "all" : "none",
          }}
        >
          <CloseIcon
            className="close-hpw"
            onClick={() =>
              this.setState({
                verzilverPopupLastMinute: false,
                currentUserOfPopups: "",
              })
            }
          />
          <p
            className="heading-hpw"
            style={{
              textTransform: "uppercase",
              marginBottom: 6,
              fontSize: 16,
              letterSpacing: 2,
            }}
          >
            {this.state.currentUserOfPopups.company_name}
          </p>
          <p className="heading-hpw" style={{ textTransform: "uppercase" }}>
            BEVESTIGING
          </p>
          <div
            style={{
              width: 40,
              height: 3,
              backgroundColor: "#C17F6B",
              margin: "0px auto",
              marginBottom: 25,
            }}
          ></div>
          <p className="desc-hpw">
            Hartelijk dank voor uw <br />
            boeking. Wij verheugen ons <br />
            op uw komst. <br />
            <br />
            Vergeet niet te reserveren bij ons <br />
            onder vermelding van Bhodi.
          </p>
          {this.state.currentUserOfPopups.phoneNumber && (
            <p className="heading-hpw" style={{ marginBottom: 6 }}>
              Tel: {this.state.currentUserOfPopups.phoneNumber}
            </p>
          )}
          {this.state.currentUserOfPopups.company_website && (
            <p className="heading-hpw">
              {this.state.currentUserOfPopups.company_website}
            </p>
          )}
          <button
            style={{ backgroundColor: "#C17F6B" }}
            onClick={() => {
              this.setState({
                verzilverPopupLastMinute: false,
                currentUserOfPopups: "",
              });
            }}
          >
            SLUIT VENSTER
          </button>
        </div>
        <div
          className="verzilver-popup-white-opacity"
          style={{
            opacity: this.state.verzilverPopupGreenLastMinute ? 1 : 0,
            pointerEvents: this.state.verzilverPopupGreenLastMinute
              ? "all"
              : "none",
          }}
        ></div>

        <div
          className="verzilver-popup-white-opacity"
          style={{
            opacity: this.state.verzilverPopupWebShop ? 1 : 0,
            pointerEvents: this.state.verzilverPopupWebShop ? "all" : "none",
          }}
        ></div>
        <div
          className="verzilver-popup-white"
          style={{
            opacity: this.state.verzilverPopupWebShop ? 1 : 0,
            pointerEvents: this.state.verzilverPopupWebShop ? "all" : "none",
          }}
        >
          <CloseIcon
            className="close-hpw"
            onClick={() => {
              this.setState({ verzilverPopupWebShop: false });
              setTimeout(() => {
                this.setState({ currentUserOfPopups: "", redeem_code: "" });
              }, 100);
              // this.setState({ verzilverPopupWebShop: false, currentUserOfPopups: "", redeem_code: "" })
            }}
          />
          <LockOpenIcon className="lock-hpw" />
          <p className="heading-hpw">Yes, gelukt!</p>
          <p className="desc-hpw">
            Kopieer de onderstaande kortingscode
            <br />
            en vul deze in op onze webshop. Bij het
            <br />
            afrekenen wordt deze verwerkt.
            <br />
            <br />
            Kopieer code:
          </p>
          {/* {this.state.redeem_code && <button onClick={this.copyToClipboard.bind(this, this.state.redeem_code)}>
                        {this.state.redeem_code}
                    </button>} */}
          <p>
            <b>{this.state.redeem_code}</b>
          </p>
          {this.state.redeem_code && this.state.copiedTextDone ? (
            <button
              style={{ backgroundColor: "#C17F6B" }}
              onClick={this.copyToClipboard.bind(this, this.state.redeem_code)}
            >
              Gekopieerd
            </button>
          ) : (
            this.state.redeem_code && (
              <button
                onClick={this.copyToClipboard.bind(
                  this,
                  this.state.redeem_code
                )}
              >
                Kopiëren
              </button>
            )
          )}
          {/* {this.state.redeem_code && <button onClick={this.copyToClipboard.bind(this, this.state.redeem_code)}>Kopiëren</button>}
                    {this.state.copiedTextDone && <div className='copiedstatusshower'>
                        <p style={{ opacity: this.state.copiedTextDone ? 1 : 0, pointerEvents: this.state.copiedTextDone ? "all" : "none" }}>Copied</p>
                    </div>} */}
        </div>

        <div
          className="verzilver-popup-white-opacity"
          style={{
            opacity: this.state.verzilverPopupGiveaway ? 1 : 0,
            pointerEvents: this.state.verzilverPopupGiveaway ? "all" : "none",
          }}
        ></div>
        <div
          className="verzilver-popup-white"
          style={{
            opacity: this.state.verzilverPopupGiveaway ? 1 : 0,
            pointerEvents: this.state.verzilverPopupGiveaway ? "all" : "none",
          }}
        >
          <CloseIcon
            className="close-hpw"
            onClick={() =>
              this.setState({
                verzilverPopupGiveaway: false,
                currentUserOfPopups: "",
              })
            }
          />
          <LockOpenIcon className="lock-hpw" />
          <p className="heading-hpw">VALIDEER ACTIE</p>
          <p className="desc-hpw">
            Om de actie te kunnen verzilveren, dien je gebruik te maken van een
            mobiele telefoon.
            <br />
            <br />
            Klik op Bewaren om de voucher op een later tijdstip te gebruiken.
          </p>
        </div>

        <div
          className="verzilver-popup-white-opacity"
          style={{
            opacity: this.state.verzilverPopupCharity ? 1 : 0,
            pointerEvents: this.state.verzilverPopupCharity ? "all" : "none",
          }}
        ></div>
        <div
          className="verzilver-popup-white"
          style={{
            opacity: this.state.verzilverPopupCharity ? 1 : 0,
            pointerEvents: this.state.verzilverPopupCharity ? "all" : "none",
          }}
        >
          <CloseIcon
            className="close-hpw"
            onClick={() =>
              this.setState({
                verzilverPopupCharity: false,
                currentUserOfPopups: "",
              })
            }
          />
          {/* <LockOpenIcon className="lock-hpw" /> */}
          <p
            className="heading-hpw"
            style={{
              textTransform: "uppercase",
              marginBottom: 6,
              fontSize: 16,
              letterSpacing: 2,
            }}
          >
            {this.state.currentUserOfPopups.company_name}
          </p>
          <p className="heading-hpw" style={{ textTransform: "uppercase" }}>
            BEVESTIGING
          </p>
          <div
            style={{
              width: 40,
              height: 3,
              backgroundColor: "#C17F6B",
              margin: "0px auto",
              marginBottom: 25,
            }}
          ></div>
          <p className="desc-hpw">
            Hartelijk dank voor uw <br />
            boeking. Wij verheugen ons <br />
            op uw komst. <br />
            <br />
            Vergeet niet te reserveren bij ons <br />
            onder vermelding van Bhodi.
          </p>
          {this.state.currentUserOfPopups.phoneNumber && (
            <p className="heading-hpw" style={{ marginBottom: 6 }}>
              Tel: {this.state.currentUserOfPopups.phoneNumber}
            </p>
          )}
          {this.state.currentUserOfPopups.company_website && (
            <p className="heading-hpw">
              {this.state.currentUserOfPopups.company_website}
            </p>
          )}
          <button
            style={{ backgroundColor: "#C17F6B" }}
            onClick={() => {
              this.setState({
                verzilverPopupCharity: false,
                currentUserOfPopups: "",
              });
            }}
          >
            SLUIT VENSTER
          </button>
        </div>
        {/* Order Page popups */}

        {/* Valid dialogs */}
        <Dialog
          open={this.state.validOnMobilePopup}
          onClose={() => this.setState({ validOnMobilePopup: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ zIndex: 99999999999999999 }}
          className="edit-dialog-cdd smallertitleedcdd"
        >
          <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Het is alleen mogelijk om te
              <br />
              valideren via mobiele telefoon.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setState({ validOnMobilePopup: false })}
              color="primary"
              className="close-modal-btn-edcd"
            >
              <CloseIcon />
            </Button>
          </DialogActions>
        </Dialog>


        <Dialog
          open={this.state.nonCharityMemberAlert}
          onClose={() => this.setState({ nonCharityMemberAlert: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ zIndex: 99999999999999999 }}
          className="edit-dialog-cdd smallertitleedcdd"
        >
          <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You must be a charity member to use this voucher.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setState({ nonCharityMemberAlert: false })}
              color="primary"
              className="close-modal-btn-edcd"
            >
              <CloseIcon />
            </Button>
          </DialogActions>
        </Dialog>

        {/* Mobile popups */}

        <div
          className="verzilver-popup-white-opacity"
          style={{
            opacity: this.state.verzilverPopupMobileGiveaway ? 1 : 0,
            pointerEvents: this.state.verzilverPopupMobileGiveaway
              ? "all"
              : "none",
          }}
        ></div>
        <div
          className="verzilver-popup-white"
          style={{
            opacity: this.state.verzilverPopupMobileGiveaway ? 1 : 0,
            pointerEvents: this.state.verzilverPopupMobileGiveaway
              ? "all"
              : "none",
          }}
        >
          <CloseIcon
            className="close-hpw"
            onClick={() =>
              this.setState({
                verzilverPopupMobileGiveaway: false,
                currentUserOfPopups: "",
              })
            }
          />
          <LockOpenIcon className="lock-hpw" />
          <p className="heading-hpw">LET OP</p>
          <p className="desc-hpw">
            na het valideren van de giveaway op locatie heb
            <br />
            je 2 minuten de tijd om de pop-up te laten zien
            <br />
            voordat deze uit je beeldscherm verdwijnt.
          </p>
          <button
            onClick={() => {
              this.interval = setInterval(() => {
                if (this.state.counterSecond > 1) {
                  this.setState({ counterSecond: --this.state.counterSecond });
                } else {
                  if (this.state.counterMinute === 1) {
                    this.setState({ counterMinute: 0, counterSecond: 59 });
                  } else {
                    clearInterval(this.interval);
                    this.setState({
                      counterMinute: 1,
                      counterSecond: 59,
                      verzilverPopupMobileAnother: false,
                    });
                  }
                }
              }, 1000);
              this.setState({
                verzilverPopupMobileGiveaway: false,
                verzilverPopupMobileAnother: true,
                isOnGiveawayVoucher: true,
                isOnKortingsVoucher: false,
              });
            }}
          >
            VALIDEREN
          </button>
        </div>

        <div
          className="verzilver-popup-white-opacity"
          style={{
            opacity: this.state.verzilverPopupMobileKortingsVoucher ? 1 : 0,
            pointerEvents: this.state.verzilverPopupMobileKortingsVoucher
              ? "all"
              : "none",
          }}
        ></div>
        <div
          className="verzilver-popup-white"
          style={{
            opacity: this.state.verzilverPopupMobileKortingsVoucher ? 1 : 0,
            pointerEvents: this.state.verzilverPopupMobileKortingsVoucher
              ? "all"
              : "none",
          }}
        >
          <CloseIcon
            className="close-hpw"
            onClick={() =>
              this.setState({
                verzilverPopupMobileKortingsVoucher: false,
                currentUserOfPopups: "",
              })
            }
          />
          <LockOpenIcon className="lock-hpw" />
          <p className="heading-hpw">
            <b>LET OP</b>
          </p>
          <p className="desc-hpw">
            Na het valideren van de kortingsvoucher
            <br />
            heb je 2 minuten de tijd om de pop-up te
            <br />
            laten zien voordat deze weer verdwijnt.
          </p>
          <button
            onClick={() => {
              this.interval = setInterval(() => {
                if (this.state.counterSecond > 1) {
                  this.setState({ counterSecond: --this.state.counterSecond });
                } else {
                  if (this.state.counterMinute === 1) {
                    this.setState({ counterMinute: 0, counterSecond: 59 });
                  } else {
                    clearInterval(this.interval);
                    this.setState({
                      counterMinute: 1,
                      counterSecond: 59,
                      verzilverPopupMobileAnother: false,
                      isOnLastMinuteOpened: false,
                    });
                  }
                }
              }, 1000);
              this.setState({
                verzilverPopupMobileKortingsVoucher: false,
                verzilverPopupMobileAnother: true,
                isOnKortingsVoucher: true,
                isOnGiveawayVoucher: false,
              });
            }}
          >
            VALIDEREN
          </button>
        </div>

        <div
          className="verzilver-popup-white-opacity"
          style={{
            opacity: this.state.verzilverPopupMobileGiveawayNew ? 1 : 0,
            pointerEvents: this.state.verzilverPopupMobileGiveawayNew
              ? "all"
              : "none",
          }}
        ></div>
        <div
          className="verzilver-popup-white"
          style={{
            opacity: this.state.verzilverPopupMobileGiveawayNew ? 1 : 0,
            pointerEvents: this.state.verzilverPopupMobileGiveawayNew
              ? "all"
              : "none",
          }}
        >
          <CloseIcon
            className="close-hpw"
            onClick={() =>
              this.setState({
                verzilverPopupMobileGiveawayNew: false,
                currentUserOfPopups: "",
              })
            }
          />
          <LockOpenIcon className="lock-hpw" />
          <p className="heading-hpw">
            <b>LET OP</b>
          </p>
          <p className="desc-hpw">
            Na het valideren van de giveaway heb je
            <br />
            2 minuten de tijd om de pop-up te laten
            <br />
            zien voordat deze weer verdwijnt.
          </p>
          <button
            onClick={() => {
              this.interval = setInterval(() => {
                if (this.state.counterSecond > 1) {
                  this.setState({ counterSecond: --this.state.counterSecond });
                } else {
                  if (this.state.counterMinute === 1) {
                    this.setState({ counterMinute: 0, counterSecond: 59 });
                  } else {
                    clearInterval(this.interval);
                    this.setState({
                      counterMinute: 1,
                      counterSecond: 59,
                      verzilverPopupMobileAnother: false,
                      isOnLastMinuteOpened: false,
                    });
                  }
                }
              }, 1000);
              this.setState({
                verzilverPopupMobileGiveawayNew: false,
                verzilverPopupMobileAnother: true,
                isOnKortingsVoucher: false,
                isOnGiveawayVoucher: true,
              });
            }}
          >
            VALIDEREN
          </button>
        </div>

        <div
          className="verzilver-popup-white-opacity"
          style={{
            opacity: this.state.verzilverPopupMobileEvents ? 1 : 0,
            pointerEvents: this.state.verzilverPopupMobileEvents
              ? "all"
              : "none",
          }}
        ></div>
        <div
          className="verzilver-popup-white"
          style={{
            opacity: this.state.verzilverPopupMobileEvents ? 1 : 0,
            pointerEvents: this.state.verzilverPopupMobileEvents
              ? "all"
              : "none",
          }}
        >
          <CloseIcon
            className="close-hpw"
            onClick={() =>
              this.setState({
                verzilverPopupMobileEvents: false,
                currentUserOfPopups: "",
              })
            }
          />
          <LockOpenIcon className="lock-hpw" />
          <p className="heading-hpw">
            <b>LET OP</b>
          </p>
          <p className="desc-hpw">
            na het valideren van het event heb je
            <br />
            2 minuten de tijd om de pop-up te
            <br />
            laten zien aan de medewerker van
            <br />
            het bedrijf.
            <br />
            <br />
            Ga je reserveren bij een restaurant?
            <br />
            Noteer dan de code en geef deze
            <br />
            door tijdens het reserveren via
            <br />
            de site of email van het restaurant.
          </p>
          <button
            onClick={() => {
              this.interval = setInterval(() => {
                if (this.state.counterSecond > 1) {
                  this.setState({ counterSecond: --this.state.counterSecond });
                } else {
                  if (this.state.counterMinute === 1) {
                    this.setState({ counterMinute: 0, counterSecond: 59 });
                  } else {
                    clearInterval(this.interval);
                    this.setState({
                      counterMinute: 1,
                      counterSecond: 59,
                      verzilverPopupMobileAnother: false,
                      isOnLastMinuteOpened: false,
                    });
                  }
                }
              }, 1000);
              this.setState({
                verzilverPopupMobileEvents: false,
                verzilverPopupMobileAnother: true,
              });
            }}
          >
            VALIDEREN
          </button>
        </div>

        <div
          className="verzilver-popup-white-opacity"
          style={{
            opacity: this.state.verzilverPopupEventNoValidate ? 1 : 0,
            pointerEvents: this.state.verzilverPopupEventNoValidate
              ? "all"
              : "none",
          }}
        ></div>
        <div
          className="verzilver-popup-white"
          style={{
            opacity: this.state.verzilverPopupEventNoValidate ? 1 : 0,
            pointerEvents: this.state.verzilverPopupEventNoValidate
              ? "all"
              : "none",
          }}
        >
          <CloseIcon
            className="close-hpw"
            onClick={() =>
              this.setState({
                verzilverPopupEventNoValidate: false,
                currentUserOfPopups: "",
              })
            }
          />
          <LockOpenIcon className="lock-hpw" />
          <p className="heading-hpw">LET OP</p>
          <p className="desc-hpw">
            Valideren kan alleen op
            <br />
            de startdag van het Event
          </p>
        </div>

        <div
          className="verzilver-popup-white-opacity"
          style={{
            opacity: this.state.verzilverPopupMobileAnother ? 1 : 0,
            pointerEvents: this.state.verzilverPopupMobileAnother
              ? "all"
              : "none",
          }}
        ></div>
        <div
          className="verzilver-popup-white"
          style={{
            opacity: this.state.verzilverPopupMobileAnother ? 1 : 0,
            pointerEvents: this.state.verzilverPopupMobileAnother
              ? "all"
              : "none",
          }}
        >
          <CloseIcon
            className="close-hpw"
            onClick={() => {
              clearInterval(this.interval);
              this.setState({
                counterMinute: 1,
                counterSecond: 59,
                verzilverPopupMobileAnother: false,
                currentUserOfPopups: "",
                isOnLastMinuteOpened: false,
                isOnKortingsVoucher: false,
                isOnGiveawayVoucher: false,
              });
            }}
          />
          <LockOpenIcon className="lock-hpw" />
          <p className="heading-hpw">Yes, gelukt!</p>
          {this.state.isOnLastMinuteOpened && (
            <p className="desc-hpw">Welkom op het event van:</p>
          )}
          {this.state.isOnKortingsVoucher && (
            <p className="desc-hpw">Je gebruikt nu de kortingsvoucher</p>
          )}
          {this.state.isOnGiveawayVoucher && (
            <p className="desc-hpw">Je gebruikt nu de giveaway</p>
          )}
          {!this.state.isOnLastMinuteOpened && (
            <p className="desc-hpw">{this.state.currentActie.title}</p>
          )}
          <p className="desc-hpw">
            {!this.state.isOnLastMinuteOpened && "van"}{" "}
            {this.state.currentUserOfPopups.company_name}
          </p>
          <button>
            <TimerIcon /> {this.state.counterMinute}:{this.state.counterSecond}
          </button>
        </div>

        {this.state.showFooter && <Footer />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducers.user,
    global_campaigns: state.campaignsReducer.global_campaigns,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: (user) => dispatch(updateUser(user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyOrders);
