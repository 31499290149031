import React, { Component } from 'react'
import logowhite from "../assets/logowhite.png";
import logoSubAdmin from "../assets/subadminlogo.png";
import firebase from '../Config/Firebase';
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import RightMenuIncomingOrders from './RightMenuIncomingOrders';
import GrantCompanyAdministratie from './GrantCompanyAdministratie';
import PopupEditor from './PopupEditor';
import AdminBarberAdministration from './AdminBarberAdministration';
import SaloonWebShopCode from './AdminComponents/SaloonWebshopCode';
import AdminVideoApprovals from './AdminVideoApprovals';

export class AdminNavbar extends Component {
    constructor() {
        super()
        this.state = {
            globalPageDropdown: false,
            secondDropDown: false,
            thirdDropDown: false,
            isSubAdmin: false,
            newPageDropdown: false
        }
    }

    componentDidMount() {
        let userid = localStorage.getItem("userid")
        if (process.env.REACT_APP_SUB_ADMIN_ID.includes(userid)) {
            this.setState({ isSubAdmin: true })
        }
    }

    logout() {
        firebase
            .auth()
            .signOut()
            .then(() => {
                // localStorage.setItem("userid", "")
                // window.location.href = "/login"
                localStorage.removeItem("userdata");
                // localStorage.removeItem("isAdmin")
                localStorage.removeItem("onCurrentTab");
                localStorage.removeItem("onCurrentTabCompany");
                localStorage.removeItem("userid");
                localStorage.removeItem("cookie_item");
                setTimeout(() => {
                    window.location.href = "/";
                }, 50);
            });
    }


    render() {
        return (
            <>
                {this.state.isSubAdmin ? (<div className="top-main-sma subadmin-tmsma">
                    <img src={logoSubAdmin} className='logo-subadmin' />
                    <div className='container-tmsma'>
                    </div>
                    <ExitToAppIcon onClick={this.logout.bind(this)} style={{ color: "white" }} />
                </div>) : (<div className="top-main-sma">
                    <img src={logowhite} />
                    {!this.state.isSubAdmin ? (<div className='container-tmsma'>
                        <p className={this.props.dashboardPage && "active"} onClick={() => (window.location.href = "/admin")}>
                            DASHBOARD
                        </p>
                        <p className={this.props.productsPage && "active"} onClick={() => (window.location.href = "/admin-products")}>
                            PRODUCTS
                        </p>
                        <p className={this.props.sectorsPage && "active"} onClick={() => (window.location.href = "/sectors-sorting")}>
                            SECTORS
                        </p>
                        <div className='container-dropdown'>
                            <p className={(this.props.codeImportPage || this.props.clientsListPage || this.props.voucherSorting || this.props.specialProductsPage || this.props.memberDashboard || this.props.atPaymentLinks || this.props.editProductSizes || this.props.accountImport || this.props.accountApprovals || this.props.intervalSettings || this.props.sortingPage || this.props.campaignsSorting || this.props.VoucherTypeSorting || this.props.onWizard || this.props.accountCreator || this.props.atLocalEvent) && "active"} onClick={() => { this.setState({ secondDropDown: !this.state.secondDropDown, newPageDropdown: false, globalPageDropdown: false, thirdDropDown: false }) }}>
                                ITEMS
                            </p>
                            {this.state.secondDropDown && (<div className='container-dropdown-inner'>
                                <p
                                    onClick={() => (window.location.href = "/category-sorting")}
                                >
                                    PRODUCTS
                                </p>
                                <p onClick={() => (window.location.href = "/edit-product-sizes")}>
                                    PRODUCT SIZES
                                </p>
                                <p onClick={() => (window.location.href = "/voucher-sorting")}>
                                    EXCLUSIVE
                                </p>
                                <p onClick={() => (window.location.href = "/campaigns-sorting")}>
                                    CAMPAIGNS
                                </p>
                                <p onClick={() => (window.location.href = "/voucher-type-sorting")}>
                                    VOUCHER TYPE
                                </p>
                                <p onClick={() => (window.location.href = "/wizard-settings")}>
                                    WIZARD
                                </p>
                                <p onClick={() => (window.location.href = "/interval-settings")}>
                                    INTERVAL TIMER
                                </p>
                                <p onClick={() => (window.location.href = "/account-creator")}>
                                    ACCOUNT CREATOR
                                </p>
                                <p onClick={() => (window.location.href = "/account-import")}>
                                    ACCOUNT IMPORT
                                </p>
                                <p onClick={() => (window.location.href = "/account-manual-import")}>
                                    MANUAL IMPORT
                                </p>
                                <p onClick={() => (window.location.href = "/account-approvals")}>
                                    APPROVAL BAR
                                </p>
                                <p onClick={() => (window.location.href = "/member-dashboard-settings")}>
                                    MEMBER DASH
                                </p>
                                <p onClick={() => {
                                    this.setState({ grantAdministration: true })
                                }}>
                                    COMPANIES APPROVAL
                                </p>
                                <p onClick={() => {
                                    this.setState({ grantVideosApproval: true })
                                }}>
                                    VIDEOS APPROVAL
                                </p>
                                <p onClick={() => {
                                    window.location.href = "/create-payment-links"
                                }}>
                                    PAYMENT LINKS
                                </p>
                                <p onClick={() => {
                                    window.location.href = "/special-products-cms"
                                }}>
                                    SPECIAL PRODUCTS
                                </p>
                                <p onClick={() => {
                                    window.location.href = "/admin-local-event"
                                }}>
                                    LOCAL EVENT
                                </p>
                                <p onClick={() => {
                                    this.setState({ barberInfoRight: true, secondDropDown: false })
                                }}>
                                    BARBER INFO
                                </p>
                                <p onClick={() => {
                                    this.setState({ saloonWebshopRight: true, secondDropDown: false })
                                }}>
                                    WEBSHOP SALOON
                                </p>
                                <p onClick={() => (window.location.href = "/admin-code-import")}>
                                    CODE IMPORT
                                </p>
                                <p onClick={() => (window.location.href = "/clients-list")}>
                                    CLIENTS LIST
                                </p>
                            </div>)}
                        </div>
                        <p className={this.props.stockImages && "active"} onClick={() => (window.location.href = "/stock-image-upload")}>
                            STOCK IMAGES
                        </p>
                        <p className={this.props.videoMakerPage && "active"} onClick={() => (window.location.href = "/video-maker-settings")}>
                            VIDEO MAKER
                        </p>
                        <div className='container-dropdown'>
                            <p className={(this.props.faqsPage || this.props.faqsPageGuests || this.props.usersPage || this.props.aboutsUsersPage) && "active"} onClick={() => { this.setState({ thirdDropDown: !this.state.thirdDropDown, secondDropDown: false, newPageDropdown: false, globalPageDropdown: false }) }}>
                                FAQS
                            </p>
                            {this.state.thirdDropDown && (<div className='container-dropdown-inner'>
                                <p onClick={() => (window.location.href = "/edit-faqs")}>FAQs</p>
                                <p onClick={() => (window.location.href = "/edit-users")}>USERS</p>
                                <p onClick={() => (window.location.href = "/edit-abouts-users")}>
                                    ABOUTS USER
                                </p>
                                <p onClick={() => (window.location.href = "/edit-faqs-guest")}>FAQs Guest</p>
                            </div>)}
                        </div>
                        <p className={this.props.aboutsPage && "active"} onClick={() => (window.location.href = "/edit-abouts")}>ABOUTS</p>
                        <div className='container-dropdown'>
                            <p className={(this.props.landingPage || this.props.commercialPage || this.props.charityPage) && "active"} onClick={() => { this.setState({ globalPageDropdown: !this.state.globalPageDropdown, secondDropDown: false, newPageDropdown: false, thirdDropDown: false }) }}>
                                GLOBAL PAGES
                            </p>
                            {this.state.globalPageDropdown && (<div className='container-dropdown-inner'>
                                <p onClick={() => (window.location.href = "/edit-landingsPage")}>
                                    LANDING PAGE
                                </p>
                                <p onClick={() => (window.location.href = "/edit-commercial")}>
                                    COMMERCIAL
                                </p>
                                <p onClick={() => (window.location.href = "/edit-charity")}>
                                    CHARITY
                                </p>
                            </div>)}
                        </div>
                        <p className={this.props.soundsPage && "active"} onClick={() => (window.location.href = "/edit-sounds")}>SOUNDS</p>
                        <p className={this.props.plansPage && "active"} onClick={() => (window.location.href = "/subscription-plans")}>PLANS</p>
                        <p className={this.props.textChanges && "active"} onClick={() => (window.location.href = "/text-changes")}>TEXT</p>
                        <p className={this.props.invoiceGenerator && "active"} onClick={() => (window.location.href = "/invoice-generator")}>INVOICE GENERATOR</p>
                        <p onClick={() => {
                            this.setState({ allOrdersRight: true })
                        }}>ORDERS</p>
                        <div className='container-dropdown'>
                            <p onClick={() => { this.setState({ newPageDropdown: !this.state.newPageDropdown, secondDropDown: false, globalPageDropdown: false, thirdDropDown: false }) }}>
                                NEW PAGE
                            </p>
                            {this.state.newPageDropdown && (<div className='container-dropdown-inner'>
                                <p
                                    onClick={() => {
                                        this.setState({ popupEditor: true, popupEditorType: "landing_page_first_section" })
                                    }}
                                >
                                    SECTION 0
                                </p>
                                <p
                                    onClick={() => {
                                        this.setState({ popupEditor: true, popupEditorType: "charity_commercial_first_section" })
                                    }}
                                >
                                    SECTION 1
                                </p>
                                <p
                                    onClick={() => {
                                        this.setState({ popupEditor: true, popupEditorType: "charity_commercial_second_section" })
                                    }}
                                >
                                    SECTION 2
                                </p>
                                <p
                                    onClick={() => {
                                        this.setState({ popupEditor: true, popupEditorType: "charity_commercial_third_section" })
                                    }}
                                >
                                    SECTION 3
                                </p>
                                <p
                                    onClick={() => {
                                        this.setState({ popupEditor: true, popupEditorType: "charity_commercial_fourth_section" })
                                    }}
                                >
                                    SECTION 4
                                </p>
                                <p
                                    onClick={() => {
                                        this.setState({ popupEditor: true, popupEditorType: "charity_commercial_fifth_section" })
                                    }}
                                >
                                    SECTION 5
                                </p>
                                <p
                                    onClick={() => {
                                        this.setState({ popupEditor: true, popupEditorType: "charity_commercial_sixth_section" })
                                    }}
                                >
                                    SECTION 6
                                </p>
                                <p
                                    onClick={() => {
                                        this.setState({ popupEditor: true, popupEditorType: "charity_commercial_seventh_section" })
                                    }}
                                >
                                    SECTION 7
                                </p>
                                <p
                                    onClick={() => {
                                        this.setState({ popupEditor: true, popupEditorType: "charity_commercial_eighth_section" })
                                    }}
                                >
                                    SECTION 8
                                </p>
                            </div>)}
                        </div>
                        <div className='container-dropdown'>
                            <p onClick={() => { this.setState({ checkoutFlowDropdown: !this.state.checkoutFlowDropdown, secondDropDown: false, globalPageDropdown: false, thirdDropDown: false }) }}>
                                CHECKOUT FLOW
                            </p>
                            {this.state.checkoutFlowDropdown && (<div className='container-dropdown-inner'>
                                <p
                                    onClick={() => {
                                        this.setState({ popupEditor: true, popupEditorType: "checkout_step_one" })
                                    }}
                                >
                                    STEP 1
                                </p>
                                <p
                                    onClick={() => {
                                        this.setState({ popupEditor: true, popupEditorType: "checkout_step_two" })
                                    }}
                                >
                                    STEP 2
                                </p>
                                <p
                                    onClick={() => {
                                        this.setState({ popupEditor: true, popupEditorType: "checkout_step_one_new" })
                                    }}
                                >
                                    STEP 1 NEW
                                </p>
                                <p
                                    onClick={() => {
                                        this.setState({ popupEditor: true, popupEditorType: "checkout_step_two_new" })
                                    }}
                                >
                                    STEP 2 NEW
                                </p>
                                <p
                                    onClick={() => {
                                        this.setState({ popupEditor: true, popupEditorType: "checkout_step_confirmation" })
                                    }}
                                >
                                    CONFIRMATION
                                </p>
                            </div>)}
                        </div>
                    </div>) : (<div className='container-tmsma'>
                    </div>)}
                    <ExitToAppIcon onClick={this.logout.bind(this)} style={{ color: "white" }} />
                </div>)}
                {this.state.allOrdersRight && (
                    <div className="modal-create-album">
                        <RightMenuIncomingOrders
                            closePopup={() => {
                                this.setState({ allOrdersRight: false });
                            }}
                            closePopupComplete={() => {
                                let element = document.getElementById("footermain");
                                if (element) {
                                    element.style.display = "flex";
                                }
                                this.setState({
                                    rightModalSettings: false,
                                    privacyRight: false,
                                    channelPageRight: false,
                                    userInfoRight: false,
                                    shopSettings: false,
                                    voucherSettings: false,
                                    emailPassword: false,
                                    myOrdersRight: false,
                                    allOrdersRight: false,
                                    showBhodiFaqs: false,
                                    showBhodiAbouts: false,
                                });
                            }}
                        />
                    </div>
                )}
                {this.state.barberInfoRight && (
                    <div
                        className={`loaderareaduplicate mcaloader loaderAreaUpdatedNavbar loaderAreaUpdateMustGray`}
                    >
                        <AdminBarberAdministration
                            isDesktop={true}
                            onChevronLeft={() => {
                                this.setState({ barberInfoRight: false });
                            }}
                            onCloseComplete={() => {
                                this.setState({
                                    barberInfoRight: false,
                                });
                            }}
                        />
                    </div>
                )}
                {this.state.saloonWebshopRight && (
                    <div
                        className={`loaderareaduplicate mcaloader loaderAreaUpdatedNavbar loaderAreaUpdateMustGray`}
                    >
                        <SaloonWebShopCode
                            isDesktop={true}
                            onChevronLeft={() => {
                                this.setState({ saloonWebshopRight: false });
                            }}
                            onCloseComplete={() => {
                                this.setState({
                                    saloonWebshopRight: false,
                                });
                            }}
                        />
                    </div>
                )}
                {this.state.grantAdministration && (<GrantCompanyAdministratie closePopup={() => {
                    this.setState({ grantAdministration: false })
                }} />)}
                {this.state.grantVideosApproval && (<AdminVideoApprovals closePopup={() => {
                    this.setState({ grantVideosApproval: false })
                }} />)}
                {this.state.popupEditor && (<PopupEditor type={this.state.popupEditorType} onClose={() => {
                    this.setState({ popupEditor: false, popupEditorType: "" })
                }} />)}
            </>
        )
    }
}

export default AdminNavbar