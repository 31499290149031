import React, { Component } from "react";
import { checkForSubscribedExclusives } from "../../Functions/checkForSubscribedExclusives";
import NumberFormatter from "../../Components/NumberFormatter";

export class KortingsVoucherTimer extends Component {

    constructor() {
        super()
        this.state = {
            intervalStartedOnEdit: false,
        }
    }

    componentDidMount() {
        const loaderId = document.getElementById("main-page-loader-id");
        if (loaderId) {
            loaderId.style.display = "none";
        }
        this.interval = setInterval(
            () => this.setState({ timeCurrent: Date.now() }),
            1000
        );
    }

    componentWillUnmount() {
        clearInterval(this.interval)
    }

    componentDidUpdate(prevProps) {
        if (this.props.timerToDo !== prevProps.timerToDo && !this.state.intervalStartedOnEdit) {

            var date = new Date(this.props.timerToDo);
            // var date = new Date(this.props.timerToDo > dateMain.getTime() ? this.props.timerToDo : dateMain.getTime());

            let dateFuture = date.getTime();

            let dateNow = new Date().getTime();

            if (dateFuture > dateNow) {
                clearInterval(this.interval);
                this.setState({ intervalStartedOnEdit: true })

                setTimeout(() => {
                    this.interval = setInterval(
                        () => this.setState({ timeCurrent: Date.now() }),
                        1000
                    );
                }, 3000);
            }
        }
    }

    getMonthName(i) {
        i = Number(i);
        if (i === 1) {
            return "januari";
        } else if (i === 2) {
            return "februari";
        } else if (i === 3) {
            return "maart";
        } else if (i === 4) {
            return "april";
        } else if (i === 5) {
            return "Mei";
        } else if (i === 6) {
            return "juni";
        } else if (i === 7) {
            return "juli";
        } else if (i === 8) {
            return "augustus";
        } else if (i === 9) {
            return "september";
        } else if (i === 10) {
            return "oktober";
        } else if (i === 11) {
            return "november";
        } else if (i === 12) {
            return "december";
        }
    }

    calculateTimeStamp() {
        // let dateMain = new Date("2023-02-23T19:00:00")

        var date = new Date(this.props.timerToDo);
        // var date = new Date(this.props.timerToDo > dateMain.getTime() ? this.props.timeToCalculate : dateMain.getTime());

        let dateFuture = date.getTime();

        let dateNow = new Date().getTime();

        let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

        let days = Math.floor(diffInMilliSeconds / 86400);
        let daysNumber = Math.floor(diffInMilliSeconds / 86400);
        diffInMilliSeconds -= days * 86400;

        let hours = Math.floor(diffInMilliSeconds / 3600) % 24;
        diffInMilliSeconds -= hours * 3600;

        let minutes = Math.floor(diffInMilliSeconds / 60) % 60;
        diffInMilliSeconds -= minutes * 60;

        let seconds = diffInMilliSeconds % 60;

        let difference = "";
        if (days > 0) {
            difference += days === 1 ? `${days}:` : `${days}:`;
        }

        if (days < 10) {
            days = `0${days}`;
        } else {
            days = `${days}`;
        }

        if (hours < 10) {
            hours = `0${hours}`;
        } else {
            hours = `${hours}`;
        }

        if (minutes < 10) {
            minutes = `0${minutes}`;
        } else {
            minutes = `${minutes}`;
        }

        if (seconds < 9) {
            seconds = `0${Math.ceil(seconds)}`;
        } else {
            seconds = `${Math.ceil(seconds)}`;
        }

        if (dateFuture > dateNow) {
            if (this.props.currentUserHere) {
                let e = this.props.atProduct
                let userid = localStorage.getItem("userid");
                if ((this.props.currentUserHere.subscription && this.props.currentUserHere.subscription.status === "active") || checkForSubscribedExclusives(this.props.currentUserHere)) {
                    if (e.verzilvered_by) {
                        let objected = Object.entries(e.verzilvered_by);
                        let filtered = objected.filter((h) => {
                            return h[1].user_id === userid;
                        });
                        if (filtered.length > 0) {
                            return false;
                        }
                    }
                } else {
                    let e = this.props.atProduct
                    if (this.props.guestUserCart && this.props.guestUserCart.length > 0) {
                        let filtered = this.props.guestUserCart.filter((h) => {
                            return h.pushKey === e.pushKey;
                        });
                        if (filtered.length > 0) {
                            return false;
                        }
                    }
                }
            } else {
                let e = this.props.atProduct
                if (this.props.guestUserCart && this.props.guestUserCart.length > 0) {
                    let filtered = this.props.guestUserCart.filter((h) => {
                        return h.pushKey === e.pushKey;
                    });
                    if (filtered.length > 0) {
                        return false;
                    }
                }
            }

            if (Number(days) === 0 && Number(minutes) < 5) {
                return (
                    <>
                        {/* €{" "}
                        <NumberFormatter
                            toFormat={this.props.salePrice}
                        />{" "} till  */}
                        Aanbieding is geldig t/m:&nbsp;
                        {/* {Number(hours) > 0 && <span>{hours} u </span>} */}
                        {Number(minutes) > 0 && <span>{minutes} min </span>}
                        <span>{seconds} sec</span>
                    </>
                );
            } else {
                let hoursMain = date.getHours()
                let minutesMain = date.getMinutes()
                if (hoursMain < 10) {
                    hoursMain = `0${hoursMain}`;
                } else {
                    hoursMain = `${hoursMain}`;
                }

                if (minutesMain < 10) {
                    minutesMain = `0${minutesMain}`;
                } else {
                    minutesMain = `${minutesMain}`;
                }
                return (
                    <>
                        {/* €{" "}
                        <NumberFormatter
                            toFormat={this.props.salePrice}
                        />{" "} till  */}
                        Aanbieding is geldig t/m:&nbsp;
                        <span>{date.getDate()} {this.getMonthName(date.getMonth() + 1)} tot {hoursMain}:{minutesMain} uur</span>
                    </>
                );
            }
        } else {
            if (this.props.closeScreen) {
                this.props.closeScreen()
            }
            clearInterval(this.interval);
            return <></>;
        }
    }

    render() {
        return this.calculateTimeStamp();
    }
}

export default KortingsVoucherTimer;
