import React, { Component } from "react";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import SortableTree from "react-sortable-tree";
import "react-sortable-tree/style.css";
import CloseIcon from "@material-ui/icons/Close";
import PublishIcon from "@material-ui/icons/Publish";
import firebase from "../Config/Firebase";
import CK from "./Ck";
import AdminNavbar from "./AdminNavbar";

export class EditAbouts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      treeData: [],
      sectorModal: false,
      sectorModalCategory: false,
      sectorName: "",
      sectors: [],
      currentSector: "",
      currentSectorId: "",
      categoryName: "",
      currentSector: "",
      all_categories: [],
      sortingTab: true,
      adjustingTab: false,
      completeMainTitles: [],
      arrOfInputs: [],
      faqTitle: "",
      faqDesc: "",
      faqCreating: false,
      faqFile: [],
    };
  }

  componentDidMount() {
    const loaderId = document.getElementById("main-page-loader-id");
    loaderId.style.display = "none";

    firebase
      .database()
      .ref("bhodi_abouts")
      .on("value", (data) => {
        let a = data.val();
        let arrSec = [];
        if (a) {
          let arrOfInputs = [];
          arrSec = a;
          if (this.state.arrOfInputs.length > 0) {
            if (this.state.faqCreating) {
              arrSec.map((g) => {
                let obj = {
                  inputOpened: false,
                  inputDescOpened: false,
                  inputDescMobileOpened: false,
                };
                arrOfInputs.push(obj);
              });
            } else {
              arrOfInputs = this.state.arrOfInputs;
            }
          } else {
            arrSec.map((g) => {
              let obj = {
                inputOpened: false,
                inputDescOpened: false,
                inputDescMobileOpened: false,
              };
              arrOfInputs.push(obj);
            });
          }
          this.setState({ treeData: arrSec, arrOfInputs, faqCreating: false });
        } else {
          this.setState({
            treeData: [],
            completeMainTitles: [],
            arrOfInputs: [],
          });
        }
      });
  }

  createFaq() {
    const { faqDesc, faqTitle, faqFile } = this.state;
    let obj = {
      title: faqTitle,
      desc: faqDesc,
      descMobile: faqDesc,
      expanded: true,
    };
    this.setState({ sortingTab: true, adjustingTab: false, faqCreating: true });

    if (faqFile.length > 0) {
      this.setState({ loaderShow: true });
      faqFile.map((e) => {
        firebase
          .storage()
          .ref()
          .child(`aboutimages/${e.name}${new Date().getTime()}`)
          .put(e)
          .then((snapshot) => {
            snapshot.ref.getDownloadURL().then((snapUrl) => {
              obj.image = snapUrl;
              setTimeout(() => {
                this.state.treeData.push(obj);
                firebase
                  .database()
                  .ref("bhodi_abouts")
                  .set(this.state.treeData)
                  .then(() => {
                    document.getElementById("root").style.position = "static";
                    document.getElementById("root").style.overflow = "auto";
                    this.setState({
                      faqTitle: "",
                      faqDesc: "",
                      faqFile: [],
                      sectorModalCategory: false,
                    });
                  });
              }, 20);
            });
          });
      });
    } else {
      this.setState({ loaderShow: true });
      setTimeout(() => {
        this.state.treeData.push(obj);
        firebase
          .database()
          .ref("bhodi_abouts")
          .set(this.state.treeData)
          .then(() => {
            document.getElementById("root").style.position = "static";
            document.getElementById("root").style.overflow = "auto";
            this.setState({
              faqTitle: "",
              faqDesc: "",
              faqFile: [],
              sectorModalCategory: false,
            });
          });
      }, 20);
    }
  }

  saveCategoryData() {
    firebase.database().ref("bhodi_abouts").set(this.state.treeData);
  }

  changeAlertAbout(e, i) {
    this.state.arrOfInputs[i].inputDescOpened = false;
    this.setState({ arrOfInputs: this.state.arrOfInputs });
    this.state.treeData[i].desc = e;
    firebase.database().ref("bhodi_abouts").set(this.state.treeData);
  }

  changeAlertAboutMobileDesc(e, i) {
    this.state.arrOfInputs[i].inputDescMobileOpened = false;
    this.setState({ arrOfInputs: this.state.arrOfInputs });
    this.state.treeData[i].descMobile = e;
    firebase.database().ref("bhodi_abouts").set(this.state.treeData);
  }

  createMarkup(e) {
    if (e) {
      return { __html: e };
    }
  }

  render() {
    return (
      <div className="sorting-mainadmin">
        <AdminNavbar aboutsPage={true} />
        <div className="container-sma" style={{ maxWidth: 1020 }}>
          <div className="top-container-sma">
            <div className="sector-tcsma"></div>
            <div className="category-tcsma">
              <p
                onClick={() => {
                  document.getElementById("root").style.position = "fixed";
                  document.getElementById("root").style.overflow = "hidden";
                  this.setState({ sectorModalCategory: true });
                }}
              >
                <AddIcon /> ABOUTS{" "}
              </p>
              <p onClick={this.saveCategoryData.bind(this)}>SAVE</p>
            </div>
          </div>
          <div className="tabs-container-sma">
            <div className="category-tcsma">
              <p
                className={this.state.sortingTab && "active"}
                onClick={() =>
                  this.setState({ sortingTab: true, adjustingTab: false })
                }
              >
                SORTING
              </p>
              <p
                className={this.state.adjustingTab && "active"}
                onClick={() =>
                  this.setState({ adjustingTab: true, sortingTab: false })
                }
              >
                ADJUSTING
              </p>
            </div>
          </div>
          {this.state.sortingTab && (
            <div className="tree-inner" style={{ height: 1200, marginTop: 30 }}>
              <SortableTree
                treeData={this.state.treeData}
                onChange={(treeData) => {
                  this.setState({ treeData });
                }}
                maxDepth={1}
              />
            </div>
          )}
          {this.state.adjustingTab && (
            <div
              className="tree-inner"
              style={{ marginTop: 30, paddingBottom: 70 }}
            >
              <ul className="adjustng-ti faqadminadjustment">
                {this.state.treeData.map((h, i) => {
                  return (
                    <li style={{ position: "relative" }}>
                      <DeleteIcon
                        style={{
                          position: "absolute",
                          cursor: "pointer",
                          right: 5,
                          top: 5,
                          fontSize: 18,
                        }}
                        onClick={() => {
                          this.state.treeData.splice(i, 1);
                          firebase
                            .database()
                            .ref("bhodi_abouts")
                            .set(this.state.treeData);
                        }}
                      />
                      <div style={{ display: "flex" }}>
                        <div
                          className="faqs-inner-admin"
                          style={{ minWidth: 400 }}
                        >
                          {!this.state.arrOfInputs[i].inputOpened ? (
                            h.title ? (
                              <p
                                onClick={() => {
                                  this.state.arrOfInputs[i].inputOpened = true;
                                  this.setState({
                                    arrOfInputs: this.state.arrOfInputs,
                                  });
                                }}
                              >
                                {h.title}
                              </p>
                            ) : (
                              <p
                                onClick={() => {
                                  this.state.arrOfInputs[i].inputOpened = true;
                                  this.setState({
                                    arrOfInputs: this.state.arrOfInputs,
                                  });
                                }}
                              >
                                Dummy title
                              </p>
                            )
                          ) : (
                            <input
                              type="text"
                              value={h.title}
                              autoFocus
                              onBlur={() => {
                                this.state.arrOfInputs[i].inputOpened = false;
                                this.setState({
                                  arrOfInputs: this.state.arrOfInputs,
                                });
                              }}
                              onChange={(e) => {
                                this.state.treeData[i].title = e.target.value;
                                firebase
                                  .database()
                                  .ref("bhodi_abouts")
                                  .set(this.state.treeData);
                              }}
                            />
                          )}
                          {!this.state.arrOfInputs[i].inputDescOpened ? (
                            h.desc ? (
                              <div
                                dangerouslySetInnerHTML={this.createMarkup(
                                  h.desc
                                )}
                                onClick={() => {
                                  this.state.arrOfInputs[
                                    i
                                  ].inputDescOpened = true;
                                  this.setState({
                                    arrOfInputs: this.state.arrOfInputs,
                                  });
                                }}
                              ></div>
                            ) : (
                              <p
                                onClick={() => {
                                  this.state.arrOfInputs[
                                    i
                                  ].inputDescOpened = true;
                                  this.setState({
                                    arrOfInputs: this.state.arrOfInputs,
                                  });
                                }}
                              >
                                Dummy desc
                              </p>
                            )
                          ) : (
                            <CK
                              data={h.desc}
                              onChildChange={(f) => this.changeAlertAbout(f, i)}
                            />
                          )}
                        </div>
                        <div
                          className="faqs-inner-admin"
                          style={{ marginLeft: 30, minWidth: 400 }}
                        >
                          {!this.state.arrOfInputs[i].inputDescMobileOpened ? (
                            h.descMobile ? (
                              <div
                                dangerouslySetInnerHTML={this.createMarkup(
                                  h.descMobile
                                )}
                                onClick={() => {
                                  this.state.arrOfInputs[
                                    i
                                  ].inputDescMobileOpened = true;
                                  this.setState({
                                    arrOfInputs: this.state.arrOfInputs,
                                  });
                                }}
                              ></div>
                            ) : (
                              <p
                                onClick={() => {
                                  this.state.arrOfInputs[
                                    i
                                  ].inputDescMobileOpened = true;
                                  this.setState({
                                    arrOfInputs: this.state.arrOfInputs,
                                  });
                                }}
                              >
                                Dummy desc
                              </p>
                            )
                          ) : (
                            <CK
                              data={h.descMobile}
                              onChildChange={(f) =>
                                this.changeAlertAboutMobileDesc(f, i)
                              }
                            />
                          )}
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
        <div
          className="modal-sector-sorting"
          style={{ right: this.state.sectorModalCategory ? 0 : "-100%" }}
        >
          <CloseIcon
            className="close-mss"
            onClick={() => {
              document.getElementById("root").style.position = "static";
              document.getElementById("root").style.overflow = "auto";
              this.setState({ sectorModalCategory: false });
            }}
          />
          <p>Create FAQ</p>
          <div className="form-mss">
            <label>Title</label>
            <input
              type="text"
              value={this.state.faqTitle}
              onChange={(e) => this.setState({ faqTitle: e.target.value })}
            />
          </div>
          <div className="form-mss">
            <label>Description</label>
            <CK
              data={this.state.faqDesc}
              onChildChange={(f) => {
                this.setState({ faqDesc: f });
              }}
            />
            {/* // <textarea style={{ minHeight: 200, border: "none", outline: "none", padding: "5px 8px" }} value={this.state.faqDesc} onChange={(e) => this.setState({ faqDesc: e.target.value })}></textarea> */}
            {/* <input type="text" value={this.state.faqDesc} onChange={(e) => this.setState({ faqDesc: e.target.value })} /> */}
          </div>
          <div className="form-mss">
            <label>Image</label>
            <div className="imageuploaderaboutfaq">
              <input
                type="file"
                onChange={(e) => {
                  e.preventDefault();
                  let dropFiles = [];
                  if (e.target.files && e.target.files.length > 0) {
                    let objected = Object.entries(e.target.files);
                    objected.map((f, i) => {
                      const reader = new FileReader();
                      dropFiles.push(objected[i][1]);
                      this.setState({ faqFile: dropFiles });
                      e.target.value = null;
                    });
                  }
                }}
              />
              <PublishIcon />
            </div>
          </div>
          <div className="form-mss">
            <button onClick={this.createFaq.bind(this)}>CREATE</button>
          </div>
        </div>
        <div
          className="modal-sector-overlay"
          style={{
            opacity: this.state.sectorModalCategory ? 0.7 : 0,
            pointerEvents: this.state.sectorModalCategory ? "all" : "none",
          }}
          onClick={() => {
            document.getElementById("root").style.position = "static";
            document.getElementById("root").style.overflow = "auto";
            this.setState({ sectorModalCategory: false });
          }}
        ></div>
      </div>
    );
  }
}

export default EditAbouts;
