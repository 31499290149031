import React, { Component } from "react";
import firebase from "../Config/Firebase";
import FriendList from "../Containers/FriendsList";
// import MemberFriendList from "../Containers/MemberFriends";
// import MemberProducts from "../Containers/MemberProducts";
import MemberOwnProducts from "../Containers/MemberOwnProducts";
import NavbarMainFullWidth from "./NavbarMainFullWidth";
import ImagesSection from "../Containers/ImagesSection";
import Charity from "../Containers/Charity";
import "./index.css";
import NavbarFullWidthMobile from "./NavbarFullWidthMobile";
import {
  updateUser,
  updateAlbumToAddFriends,
  updateCurrentMomentsFriendsPage,
  updateMoreAlbumsAddedFriends,
  updateTitle,
  rerenderComponent,
} from "../Redux/actions/authActions";
import { connect } from "react-redux";
import NewVisit from "../Containers/New Visit";
import ShopInnerMember from "../Containers/Shop Work/Shop Inner Member";
import CreateProductMemberCompany from "../Containers/CreateProductMemberCompany";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Footer from "./Footer";
import FriendListRightMenu from "./FriendListRightMenu";
import ViewerFriendlist from "./ViewerFriendlist";
import CharityConfirmationFullPage from "./CharityConfirmationFullPage";
import FooterGreen from "./FooterGreen";
import { history } from "../Config/Routes";
import WizardMember from "./WizardMember";
import VriendenPageDesktop from "./VriendenPageDesktop";
import { deleteFriend, sendRequest } from "../Functions/friendFunctions";
import ExclusiveSubscribeProcess from "../Containers/ExclusiveSubscribeProcess";
import RoundIconsNavbarMobile from "./RoundIconsNavbarMobile";
import RoundIconsNavbarViewerMobile from "./RoundIconsNavbarViewerMobile";
import { checkForSubscribedExclusives } from "../Functions/checkForSubscribedExclusives";
import MemberHome from "../Containers/MemberHome";
import NavbarWrapperVisitor from "./NavbarWrapperVisitor";
import NavbarWrapperVisitorMobile from "./NavbarWrapperVisitorMobile";
import SubscribeForCompanyAnnouncement from "./SubscribeForCompanyAnnouncement";
import { notifyMe } from "../Functions/globalFunctions";

export class WholeMemberStuff extends Component {
  constructor() {
    super();
    this.state = {
      showComponentRender: true,
      showLists: true,
      showItems: false,
      showFriends: false,
      showProductsShop: false,
      hideNav: false,
      activate: false,
      drag: false,
      isRemoving: false,
      filesName: [],
      showProductsBookmarked: false,
      itShouldUpdatedActivate: true,
      popuprightToShow: false,
      pageSettingsModal: true,
      doShowMenu: false,
      currentMemberData: "",
      isDesktop: true,
      nowNoShowItemsAny: true,
      accountPrivate: false,
      currentUserHere: "",
      currentUserId: "",
      doShowMenuRightAbout: false,
      allAlbumsCurrentShared: [],
      showcharityConfirmation: false,
      subscriptionActivated: false,
      showGegevensPopup: false,
      mounted: true,
      urlParams: null,
      updated: true,
      onStories: false,
    };
    // window.addEventListener("beforeunload", (ev) => {
    //     let uid = localStorage.getItem("userid")
    //     firebase.database().ref("users/" + uid + "/newly_registered").set(false)
    // });
  }

  getUserValues() {
    let member_id = this.props.match.params.user_id;
    let userid = localStorage.getItem("userid");

    if (this.props.user) {
      if (this.props.user.length > 0) {
        let filtered = this.props.user.filter((y) => {
          return y.user_name_id === member_id;
        });
        let filteredMyOwn = this.props.user.filter((y) => {
          return y.user_id === userid;
        });
        if (filteredMyOwn.length > 0) {
          let currentUserMyOwn = filteredMyOwn[0];
          if (currentUserMyOwn.subscription) {
            if (currentUserMyOwn.subscription.status === "active") {
              this.setState({ subscriptionActivated: true });
            } else {
              if (checkForSubscribedExclusives(currentUserMyOwn)) {
                this.setState({ subscriptionActivated: true })
              }
            }
          } else {
            if (checkForSubscribedExclusives(currentUserMyOwn)) {
              this.setState({ subscriptionActivated: true })
            }
          }
          if (currentUserMyOwn.blocked) {
            let blocked = Object.entries(currentUserMyOwn.blocked);
            let filteredInner = blocked.filter((y) => {
              return y[1].user_id == filtered[0].user_id;
            });
            if (filteredInner.length > 0) {
              window.location.href = "/404";
            }
          }
        }
        if (filtered.length > 0) {
          let currentUser = filtered[0];
          if (!filtered[0].profileActive && filtered[0].user_id !== userid) {
            window.location.href = "/404"
          }
          if (currentUser.blocked) {
            let blocked = Object.entries(currentUser.blocked);
            let filtered = blocked.filter((y) => {
              return y[1].user_id === userid;
            });
            if (filtered.length > 0) {
              window.location.href = "/404";
            }
          }
          if (currentUser.accountType !== "global") {
            this.setState({ accountPrivate: true });
          } else {
            this.setState({ accountPrivate: false });
          }
          if (currentUser.friends) {
            let blocked = Object.entries(currentUser.friends);
            let filtered = blocked.filter((y) => {
              return y[1].user_id === userid;
            });
            if (filtered.length > 0) {
              this.setState({ accountPrivate: false });
            }
          }
          this.setState({
            currentMemberData: currentUser,
            userid,
            currentUserId: userid,
          });
          if (currentUser.user_id === userid) {
            this.setState({ showComponentRender: true });
          } else {
            this.setState({ showComponentRender: false });
          }
        } else {
          window.location.href = "/404";
        }
        let filteredHere = this.props.user.filter((y) => {
          return y.user_id === userid;
        });
        if (filteredHere.length > 0) {
          this.setState({ currentUserHere: filteredHere[0] });
        }
      }
    }
  }

  componentDidMount() {
    this.setState({ mounted: true, urlParams: this.props.match.params });
    const loaderId = document.getElementById("main-page-loader-id");
    loaderId.style.display = "none";

    let member_id = this.props.match.params.user_id;

    let currentUserMember = "";
    let userid = localStorage.getItem("userid");

    if (window.innerWidth > 1009) {
      this.setState({ isDesktop: true });
    } else {
      this.setState({ isDesktop: false });
    }

    let onStories = localStorage.getItem("onStoriesActive")
    if (onStories) {
      this.setState({ onStories: true })
    } else {
      this.setState({ onStories: false })
    }

    window.addEventListener("resize", () => {
      if (window.innerWidth < 1009) {
        this.setState({ isDesktop: false });
      } else {
        this.setState({ isDesktop: true });
      }
    });

    this.getUserValues();

    // let promiseFirst = new Promise((resolve) => {
    //     firebase.database().ref("users").orderByChild("user_name_id").equalTo(member_id).on("child_added", (data) => {
    //         if (data.val()) {
    //             currentUserMember = data.val()
    //             resolve(currentUserMember)
    //             if (data.val().friends) {
    //                 let objected = Object.entries(data.val().friends)
    //                 currentUserMember.objectedFriends = objected
    //             } else {
    //                 currentUserMember.objectedFriends = []
    //             }
    //             this.setState({ currentMemberData: currentUserMember })
    //         }
    //     })
    // })

    // routing work
    let route = window.location.pathname;

    if (route.includes("vriendenDesktop")) {
      this.setState({
        showVriendenWizard: true,
      });
    } else if (route.includes("/friends")) {
      localStorage.setItem("onCurrentTab", "friend");
      this.setState({
        showcharityConfirmation: false,
        showLists: false,
        showItems: false,
        showFriends: true,
        showProductsOwn: false,
        showProductsBookmarked: false,
        showCharity: false,
        shopProductsShop: false,
        showContent: false,
        showExclusiveSubscribe: false,
      });
    } else if (route.includes("/exclusive-subscribing")) {
      localStorage.setItem("onCurrentTab", "showExcSubscribe");
      this.setState({
        showcharityConfirmation: false,
        showOrderSuccess: false,
        showLists: false,
        showFriends: false,
        showProductsOwn: false,
        showProductsBookmarked: false,
        showCharity: false,
        shopProductsShop: false,
        showContent: false,
        showExclusiveSubscribe: true,
      });
    } else if (route.includes("/charityConfirmation")) {
      localStorage.setItem("onCurrentTab", "charityConfirmation");
      this.setState({
        showcharityConfirmation: true,
        showOrderSuccess: false,
        showLists: false,
        showFriends: false,
        showProductsOwn: false,
        showProductsBookmarked: false,
        showCharity: false,
        shopProductsShop: false,
        showContent: false,
        showExclusiveSubscribe: false,
      });
    } else if (route.includes("/charity")) {
      localStorage.setItem("onCurrentTab", "charitypage");
      this.setState({
        showcharityConfirmation: false,
        showLists: false,
        showItems: false,
        showFriends: false,
        showProductsOwn: false,
        showProductsBookmarked: false,
        showCharity: true,
        shopProductsShop: false,
        showContent: false,
        showExclusiveSubscribe: false,
      });
    } else if (route.includes("/volgend")) {
      localStorage.setItem("onCurrentTab", "products");
      this.setState({
        showcharityConfirmation: false,
        showItems: true,
        showLists: false,
        showFriends: false,
        showProductsOwn: false,
        showProductsBookmarked: false,
        showCharity: false,
        shopProductsShop: false,
        showContent: false,
        showExclusiveSubscribe: false,
      });
    } else if (route.includes("/vrienden")) {
      localStorage.setItem("onCurrentTab", "showVrienden");
      this.setState({
        showFriendsNewUpdated: true,
        showcharityConfirmation: false,
        showItems: false,
        showLists: false,
        showFriends: false,
        showProductsOwn: false,
        showProductsBookmarked: false,
        showCharity: false,
        shopProductsShop: false,
        showContent: false,
        showExclusiveSubscribe: false,
      });
    } else if (route.includes("/order-success")) {
      localStorage.setItem("onCurrentTab", "showordersuccess");
      this.setState({
        showcharityConfirmation: false,
        showOrderSuccess: true,
        showLists: false,
        showFriends: false,
        showProductsOwn: false,
        showProductsBookmarked: false,
        showCharity: false,
        shopProductsShop: false,
        showContent: false,
        showExclusiveSubscribe: false,
      });
    } else {
      localStorage.setItem("onCurrentTab", "images");
      this.setState({
        showcharityConfirmation: false,
        showLists: true,
        showItems: false,
        showFriends: false,
        showProductsOwn: false,
        showProductsBookmarked: false,
        showCharity: false,
        shopProductsShop: false,
        showContent: false,
        showExclusiveSubscribe: false,
      });
    }

    // alert("working")

    firebase
      .database()
      .ref("users").orderByChild("user_name_id").equalTo(member_id)
      .once("value", async (data) => {
        let ab = data.val();
        let currentUser = "";
        if (ab) {
          let objected = Object.entries(ab)
          currentUser = objected[0][1];
          let a = currentUser;
          if (!a.profileActive && a.user_id !== userid) {
            window.location.href = "/404"
          }
          if (a.blocked) {
            let blocked = Object.entries(a.blocked);
            let filtered = blocked.filter((y) => {
              return y[1].user_id === userid;
            });
            if (filtered.length > 0) {
              window.location.href = "/404";
            }
          }
          if (currentUser.accountType !== "global") {
            this.setState({ accountPrivate: true });
          } else {
            this.setState({ accountPrivate: false });
          }
          if (currentUser.friends) {
            let blocked = Object.entries(currentUser.friends);
            let filtered = blocked.filter((y) => {
              return y[1].user_id === userid;
            });
            if (filtered.length > 0) {
              this.setState({ accountPrivate: false });
            }
          }
          if (a.user_id === userid) {
            this.setState({ currentUserHere: a });

            let urlMain = new URLSearchParams(window.location.search)
            if (urlMain.get("subscribingForCompany") && a.subscribing_for_company) {
              this.setState({ showSubscribePopup: true })

              firebase.database().ref(`users/${userid}/subscribing_for_company`).remove()
              if (a.payment_status === "paid" && a.subscription && a.subscription.status === "active") {
                await notifyMe(a, urlMain.get("subscribingForCompany"))
              }
            }
          }
          if (currentUser) {
            this.setState({ currentMemberData: currentUser });
          }
        }
      });
  }

  checkForFriendsText() {
    let friendsCurrentUser = this.state.currentMemberData.friends;
    let comingCurrentUser = this.state.currentMemberData.comingrequests;
    let sentCurrentUser = this.state.currentMemberData.sentrequests;
    let rejectCurrentUser = this.state.currentMemberData.rejectlist;
    let inRejectCurrentUser = this.state.currentMemberData.inRejectList;
    let blocked = this.state.currentMemberData.blocked;
    let completeArray = [];
    let completeArrayToCheck = [];
    if (friendsCurrentUser) {
      let objected = Object.entries(friendsCurrentUser);
      objected.map((y) => {
        y[1].type = "friend";
        completeArray.push(y);
      });
    }
    if (comingCurrentUser) {
      let objected = Object.entries(comingCurrentUser);
      objected.map((y) => {
        y[1].type = "comingrequests";
        completeArray.push(y);
      });
    }
    if (sentCurrentUser) {
      let objected = Object.entries(sentCurrentUser);
      objected.map((y) => {
        y[1].type = "sentrequests";
        completeArray.push(y);
      });
    }
    if (rejectCurrentUser) {
      let objected = Object.entries(rejectCurrentUser);
      objected.map((y) => {
        completeArrayToCheck.push(y[1].user_id);
      });
    }
    if (inRejectCurrentUser) {
      let objected = Object.entries(inRejectCurrentUser);
      objected.map((y) => {
        completeArrayToCheck.push(y[1].user_id);
      });
    }
    if (blocked) {
      let objected = Object.entries(blocked);
      objected.map((y) => {
        completeArrayToCheck.push(y[1].user_id);
      });
    }
    let filtered = completeArray.filter((g) => {
      return g[1].user_id === this.state.currentUserId;
    });
    let checkFromRight = [];
    if (this.state.currentMemberData.followed_by) {
      let objected = Object.entries(this.state.currentMemberData.followed_by);
      let filteredAnother = objected.filter((g) => {
        return g[1].user_id === this.state.currentUserId;
      });
      checkFromRight = filteredAnother;
    }
    if (!completeArrayToCheck.includes(this.state.currentUserId)) {
      if (filtered.length > 0) {
        if (filtered[0][1].type === "friend") {
          return (
            <button
              style={{ marginRight: 10 }}
              onClick={() => deleteFriend(this.state.currentMemberData, this.state.currentUserHere, this.props.album)}
            >
              <a>Ontvrienden</a>
            </button>
          );
        } else if (filtered[0][1].type === "comingrequests") {
          return (
            <button style={{ marginRight: 10 }}>
              <a>Intrekken</a>
            </button>
          );
        } else if (filtered[0][1].type === "sentrequests") {
          return (
            <button style={{ marginRight: 10 }}>
              <a>Vriendschapsverzoek ontvangen</a>
            </button>
          );
        }
      } else {
        return (
          <button
            style={{ marginRight: 10 }}
            onClick={() => sendRequest(this.state.currentMemberData)}
          >
            <a>Uitnodiging sturen</a>
          </button>
        );
      }
    } else {
      let sentCurrentUser = this.state.currentUserHere.sentrequests;
      let completeArray = [];
      if (sentCurrentUser) {
        let objected = Object.entries(sentCurrentUser);
        objected.map((y) => {
          y[1].type = "sentrequests";
          completeArray.push(y);
        });
      }
      let filtered = completeArray.filter((g) => {
        return g[1].user_id === this.state.currentMemberData.user_id;
      });
      if (filtered.length > 0) {
        if (filtered[0][1].type === "sentrequests") {
          return (
            <button style={{ marginRight: 10 }}>
              <a>Intrekken</a>
            </button>
          );
        }
      } else {
        return (
          <button
            style={{ marginRight: 10 }}
            onClick={() => sendRequest(this.state.currentMemberData)}
          >
            <a>Uitnodiging sturen</a>
          </button>
        );
      }
    }
  }

  componentDidUpdate(prevProps) {
    let member_id = this.props.match.params.user_id;
    let userid = localStorage.getItem("userid");

    const { currentMemberData } = this.state;

    if (currentMemberData === "") {
      this.getUserValues();
    }

    if (this.props.match.params !== this.state.urlParams) {
      this.getUserValues();
      this.setState({ urlParams: this.props.match.params });
    }

    if (this.props.reRendered) {
      this.getUserValues();
      this.setState({ updated: false });
      this.props.rerenderComponent(false);
      setTimeout(() => {
        this.setState({ updated: true });
      }, 250);
    }


    if (this.props.title) {
      this.setState({ updated: false });
      this.getUserValues();
      this.props.updateTitle(false);
      setTimeout(() => {
        this.setState({ updated: true });
      }, 250);
    }

    if (prevProps.user !== this.props.user) {
      if (this.props.user) {
        let filtered = this.props.user.filter((y) => {
          return y.user_name_id === member_id;
        });
        let filteredMyOwn = this.props.user.filter((y) => {
          return y.user_id === userid;
        });
        if (filteredMyOwn.length > 0) {
          let currentUserMyOwn = filteredMyOwn[0];
          if (currentUserMyOwn.subscription) {
            if (currentUserMyOwn.subscription.status === "active") {
              this.setState({ subscriptionActivated: true });
            } else {
              if (checkForSubscribedExclusives(currentUserMyOwn)) {
                this.setState({ subscriptionActivated: true })
              }
            }
          } else {
            if (checkForSubscribedExclusives(currentUserMyOwn)) {
              this.setState({ subscriptionActivated: true })
            }
          }
          if (currentUserMyOwn.blocked) {
            let blocked = Object.entries(currentUserMyOwn.blocked);
            let filteredInner = blocked.filter((y) => {
              return y[1].user_id == filtered[0].user_id;
            });
            if (filteredInner.length > 0) {
              window.location.href = "/404";
            }
          }
        }
        if (filtered.length > 0) {
          if (!filtered[0].profileActive && filtered[0].user_id !== userid) {
            window.location.href = "/404"
          }
          let currentUser = filtered[0];
          if (currentUser.blocked) {
            let blocked = Object.entries(currentUser.blocked);
            let filtered = blocked.filter((y) => {
              return y[1].user_id === userid;
            });
            if (filtered.length > 0) {
              window.location.href = "/404";
            }
          }
          this.setState({ currentMemberData: currentUser });
        } else {
          if (this.state.currentMemberData) {
            if (this.state.currentMemberData.user_id === userid) {
              return false;
            }
          }
          window.location.href = "/404";
        }
      }
    }
    if (this.state.showTrendingPosts && this.state.onStories) {
      this.setState({ onStories: false })
    }
    if (window.innerWidth < 1008) {
      if (this.props.albumToAdd === "vrienden" && this.state.onStories) {
        this.setState({ onStories: false })
      }
    }
  }

  render() {
    console.count("re-render main")
    return this.state.currentMemberData && this.state.updated ? (
      <div
      // className={`${this.state.subscriptionActivated && "subscriptionActivatedOnWholePage"
      //   } `}
      >
        {this.state.showSubscribePopup && (<SubscribeForCompanyAnnouncement isSubscribed={this.state.currentUserHere.payment_status === "paid" && this.state.currentUserHere.subscription && this.state.currentUserHere.subscription.status === "active"} onClose={() => {
          this.setState({ showSubscribePopup: false })
        }} />)}
        {this.state.showComponentRender ? (
          <div
            id="mainWrapperAccount"
          >
            {this.state.isDesktop ? (
              <NavbarMainFullWidth
                mounted={this.state.mounted}
                member_id={this.props.match.params.user_id}
                linkToVolgend={`/member/${this.state.currentMemberData.user_name_id}/volgend`}
                linkToTrending={`/member/${this.state.currentMemberData.user_name_id}`}
                linkToCharity={`/member/${this.state.currentMemberData.user_name_id}/charity`}
                showingWizard={(!this.state.currentMemberData.hideWizard && !this.state.currentUserHere.create_subscription && this.state.currentMemberData.user_id === this.state.currentUserId)}
                showProducts={() => {
                  localStorage.setItem("onCurrentTab", "products");
                  // window.location.href = `/member/${this.state.currentMemberData.user_name_id}/volgend`;
                }}
                openAddPopupPosts={() => {
                  document.getElementById("root").style.position = "fixed"
                  document.getElementById("root").style.overflowY = "hidden"
                  this.setState({ addPosts: true })
                }}
                showProductsShop={() => {
                  localStorage.setItem("onCurrentTab", "shopProducts");
                }}
                showPosts={() => {
                  localStorage.setItem("onCurrentTab", "images");
                  // window.location.href = `/member/${this.state.currentMemberData.user_name_id}`;
                }}
                showFriends={() => {
                  if ((!this.state.currentMemberData.hideWizard && !this.state.currentUserHere.create_subscription && this.state.currentMemberData.user_id === this.state.currentUserId)) {
                    // this.setState({ isFriendActiveWizard: true })
                    history.push(
                      `/member/${this.state.currentMemberData.user_name_id}/vriendenDesktop`
                    );
                  } else {
                    this.setState({ showRightMenuFriends: true });
                  }
                  // this.setState({ showRightMenuFriends: true });
                  // localStorage.setItem("onCurrentTab", "friend")
                  // window.location.href = `/member/${this.state.currentMemberData.user_name_id}/friends`
                }}
                showProductsOwn={() => {
                  localStorage.setItem("onCurrentTab", "ownproducts");
                }}
                showProductsBookmarked={() => {
                  localStorage.setItem("onCurrentTab", "bookmarkedproducts");
                }}
                showCharity={() => {
                  localStorage.setItem("onCurrentTab", "charitypage");
                  // window.location.href = `/member/${this.state.currentMemberData.user_name_id}/charity`;
                }}
                showContent={() => {
                  localStorage.setItem("onCurrentTab", "showContent");
                }}
                isShowingList={this.state.showLists}
                changeActivate={() => {
                  this.setState({
                    activate: true,
                    itShouldUpdatedActivate: true,
                  });
                }}
                onPopup={() => {
                  this.setState({
                    drag: true,
                    isRemoving: true,
                    filesName: [],
                    activate: false,
                  });
                }}
                hideNav={this.state.hideNav}
                openProductsWork={() => {
                  this.setState({
                    popuprightToShow: !this.state.popuprightToShow,
                    hideNav: true,
                  });
                }}
                doShowMenuRightAbout={this.state.doShowMenuRightAbout}
                closeAboutBhodiRightMenu={() => {
                  this.setState({ doShowMenuRightAbout: false });
                }}
                showTrendingPosts={() => {
                  this.setState({ showTrendingPosts: true });
                }}
                showGlobalPosts={() => {
                  this.setState({ showPublicPosts: true });
                }}
                showStoriesPopup={() => {
                  this.setState({ showStoriesRightMenu: true });
                }}
                showStoriesPopupRight={() => {
                  if (
                    this.state.currentMemberData.user_id === this.state.userid
                  ) {
                    this.setState({ showStoriesRightMenuAnothers: true });
                  } else {
                    window.location.href = `/member/${this.state.currentMemberData.user_name_id}`;
                    // this.setState({ showStoriesRightMenuViewers: true });
                  }
                  // this.setState({ showStoriesRightMenuAnothers: true })
                }}
              />
            ) : (!this.state.currentMemberData.hideWizard || !this.state.showFriendsNewUpdated) && (
              <NavbarFullWidthMobile
                linkToVolgend={`/member/${this.state.currentMemberData.user_name_id}/volgend`}
                linkToCharity={`/member/${this.state.currentMemberData.user_name_id}/charity`}
                linkToTrending={`/member/${this.state.currentMemberData.user_name_id}`}
                showingWizard={(!this.state.currentMemberData.hideWizard && !this.state.currentUserHere.create_subscription && this.state.currentMemberData.user_id === this.state.currentUserId)}
                member_id={this.props.match.params.user_id}
                mounted={this.state.mounted}
                showPosts={() => {
                  localStorage.setItem("onCurrentTab", "images");
                  // window.location.href = `/member/${this.state.currentMemberData.user_name_id}`;
                }}
                showProducts={() => {
                  localStorage.setItem("onCurrentTab", "products");
                  // window.location.href = `/member/${this.state.currentMemberData.user_name_id}/volgend`;
                }}
                openAddPopupPosts={() => {
                  document.getElementById("root").style.position = "fixed"
                  document.getElementById("root").style.overflowY = "hidden"
                  this.setState({ addPosts: true })
                }}
                showFriends={() => {
                  this.setState({ showRightMenuFriends: true });
                  // localStorage.setItem("onCurrentTab", "friend")
                  // window.location.href = `/member/${this.state.currentMemberData.user_name_id}/friends`
                }}
                showFriendsNewUpdated={() => {
                  localStorage.setItem(
                    "onCurrentTab",
                    "showVrienden"
                  );
                  history.push(
                    `/member/${this.state.currentMemberData.user_name_id}/vrienden`
                  );
                  // window.location.href = `/company/${this.state.currentMemberData.user_name_id}/vrienden`;
                }}
                showProductsOwn={() => {
                  this.setState({ showProductsOwn: false });
                  setTimeout(() => {
                    this.setState({
                      showLists: false,
                      showItems: false,
                      showFriends: false,
                      showProductsOwn: true,
                      showProductsBookmarked: false,
                      showCharity: false,
                      showContent: false,
                    });
                  }, 10);
                }}
                showProductsBookmarked={() => {
                  this.setState({ showProductsBookmarked: false });
                  setTimeout(() => {
                    this.setState({
                      showLists: false,
                      showItems: false,
                      showFriends: false,
                      showProductsOwn: false,
                      showProductsBookmarked: true,
                      showCharity: false,
                      showContent: false,
                    });
                  }, 10);
                }}
                showCharity={() => {
                  localStorage.setItem("onCurrentTab", "charitypage");
                  // window.location.href = `/member/${this.state.currentMemberData.user_name_id}/charity`;
                }}
                showContent={() => {
                  this.setState({ showCharity: false });
                  setTimeout(() => {
                    this.setState({
                      showLists: false,
                      showItems: false,
                      showFriends: false,
                      showProductsOwn: false,
                      showProductsBookmarked: false,
                      showCharity: false,
                      showContent: true,
                    });
                  }, 10);
                }}
                isShowingList={this.state.showLists}
                changeActivate={() => {
                  this.setState({
                    activate: true,
                    itShouldUpdatedActivate: true,
                  });
                }}
                onPopup={() => {
                  this.setState({
                    drag: true,
                    isRemoving: true,
                    filesName: [],
                    activate: false,
                  });
                }}
                hideNav={this.state.hideNav}
                openProductsWork={() => {
                  this.setState({
                    popuprightToShow: !this.state.popuprightToShow,
                    hideNav: true,
                  });
                }}
                doShowMenuRightAbout={this.state.doShowMenuRightAbout}
                closeAboutBhodiRightMenu={() => {
                  this.setState({ doShowMenuRightAbout: false });
                }}
                showTrendingPosts={() => {
                  this.setState({ showTrendingPosts: true });
                }}
                showGlobalPosts={() => {
                  this.setState({ showPublicPosts: true });
                }}
                showStoriesPopup={() => {
                  this.setState({ showStoriesRightMenu: true });
                }}
                showStoriesPopupRight={() => {
                  if (
                    this.state.currentMemberData.user_id === this.state.userid
                  ) {
                    this.setState({ showStoriesRightMenuAnothers: true });
                  } else {
                    setTimeout(() => {
                      window.location.href = `/member/${this.state.currentMemberData.user_name_id}`;
                    }, 200);
                    // this.setState({ showStoriesRightMenuViewers: true });
                  }
                  // this.setState({ showStoriesRightMenuAnothers: true })
                }}
                showGegevensPopup={this.state.showGegevensPopup}
                closeShowGegevensPopup={() => {
                  this.setState({ showGegevensPopup: false });
                }}
                closeCompleteRightMenu={this.state.closeCompleteRightMenu}
                revertBackCloseCompleteRightMenu={() => {
                  this.setState({ closeCompleteRightMenu: false });
                }}
              />
            )}
            {(!this.state.currentMemberData.hideWizard || !this.state.showFriendsNewUpdated) && !this.state.isDesktop && (<RoundIconsNavbarMobile
              linkToVolgend={`/member/${this.state.currentMemberData.user_name_id}/volgend`}
              linkToCharity={`/member/${this.state.currentMemberData.user_name_id}/charity`}
              linkToTrending={`/member/${this.state.currentMemberData.user_name_id}`}
              showingWizard={(!this.state.currentMemberData.hideWizard && !this.state.currentUserHere.create_subscription && this.state.currentMemberData.user_id === this.state.currentUserId)}
              member_id={this.props.match.params.user_id}
              mounted={this.state.mounted}
              showPosts={() => {
                localStorage.setItem("onCurrentTab", "images");
                // window.location.href = `/member/${this.state.currentMemberData.user_name_id}`;
              }}
              showProducts={() => {
                localStorage.setItem("onCurrentTab", "products");
                // window.location.href = `/member/${this.state.currentMemberData.user_name_id}/volgend`;
              }}
              openAddPopupPosts={() => {
                document.getElementById("root").style.position = "fixed"
                document.getElementById("root").style.overflowY = "hidden"
                this.setState({ addPosts: true })
              }}
              showFriends={() => {
                this.setState({ showRightMenuFriends: true });
                // localStorage.setItem("onCurrentTab", "friend")
                // window.location.href = `/member/${this.state.currentMemberData.user_name_id}/friends`
              }}
              showFriendsNewUpdated={() => {
                localStorage.setItem(
                  "onCurrentTab",
                  "showVrienden"
                );
                history.push(
                  `/member/${this.state.currentMemberData.user_name_id}/vrienden`
                );
                // window.location.href = `/company/${this.state.currentMemberData.user_name_id}/vrienden`;
              }}
              showProductsOwn={() => {
                this.setState({ showProductsOwn: false });
                setTimeout(() => {
                  this.setState({
                    showLists: false,
                    showItems: false,
                    showFriends: false,
                    showProductsOwn: true,
                    showProductsBookmarked: false,
                    showCharity: false,
                    showContent: false,
                  });
                }, 10);
              }}
              showProductsBookmarked={() => {
                this.setState({ showProductsBookmarked: false });
                setTimeout(() => {
                  this.setState({
                    showLists: false,
                    showItems: false,
                    showFriends: false,
                    showProductsOwn: false,
                    showProductsBookmarked: true,
                    showCharity: false,
                    showContent: false,
                  });
                }, 10);
              }}
              showCharity={() => {
                localStorage.setItem("onCurrentTab", "charitypage");
                // window.location.href = `/member/${this.state.currentMemberData.user_name_id}/charity`;
              }}
              showContent={() => {
                this.setState({ showCharity: false });
                setTimeout(() => {
                  this.setState({
                    showLists: false,
                    showItems: false,
                    showFriends: false,
                    showProductsOwn: false,
                    showProductsBookmarked: false,
                    showCharity: false,
                    showContent: true,
                  });
                }, 10);
              }}
              isShowingList={this.state.showLists}
              changeActivate={() => {
                this.setState({
                  activate: true,
                  itShouldUpdatedActivate: true,
                });
              }}
              onPopup={() => {
                this.setState({
                  drag: true,
                  isRemoving: true,
                  filesName: [],
                  activate: false,
                });
              }}
              hideNav={this.state.hideNav}
              openProductsWork={() => {
                this.setState({
                  popuprightToShow: !this.state.popuprightToShow,
                  hideNav: true,
                });
              }}
              doShowMenuRightAbout={this.state.doShowMenuRightAbout}
              closeAboutBhodiRightMenu={() => {
                this.setState({ doShowMenuRightAbout: false });
              }}
              showTrendingPosts={() => {
                this.setState({ showTrendingPosts: true });
              }}
              showGlobalPosts={() => {
                this.setState({ showPublicPosts: true });
              }}
              showStoriesPopup={() => {
                this.setState({ showStoriesRightMenu: true });
              }}
              showStoriesPopupRight={() => {
                if (
                  this.state.currentMemberData.user_id === this.state.userid
                ) {
                  this.setState({ showStoriesRightMenuAnothers: true });
                } else {
                  setTimeout(() => {
                    window.location.href = `/member/${this.state.currentMemberData.user_name_id}`;
                  }, 200);
                  // this.setState({ showStoriesRightMenuViewers: true });
                }
                // this.setState({ showStoriesRightMenuAnothers: true })
              }}
              showGegevensPopup={this.state.showGegevensPopup}
              closeShowGegevensPopup={() => {
                this.setState({ showGegevensPopup: false });
              }}
              closeCompleteRightMenu={this.state.closeCompleteRightMenu}
              revertBackCloseCompleteRightMenu={() => {
                this.setState({ closeCompleteRightMenu: false });
              }}
            />)}
            {/* {this.state.showLists ? this.state.currentMemberData.newly_registered ? <NewVisit /> : this.state.currentMemberData.show_subscriber_page ? <SubscriberWelcome /> : <ImagesSection subscriptionActivated={this.state.subscriptionActivated} memberId={this.props.match.params.user_id} hideNav={() => this.setState({ hideNav: !this.state.hideNav })} */}
            {/* {this.state.showLists ? ( */}
            {(this.state.showLists && this.state.currentMemberData.newly_registered) ? (
              <NewVisit />
            ) : (!this.state.currentMemberData.hideWizard && !this.state.currentUserHere.create_subscription && this.state.currentMemberData.user_id === this.state.currentUserId) && !this.state.showcharityConfirmation && !this.state.showExclusiveSubscribe ? (<WizardMember currentMemberData={this.state.currentMemberData} atCurrentStep={this.state.showCharity ? 4 : (this.state.showVriendenWizard || this.state.showFriendsNewUpdated) ? 3 : this.state.onStories ? 2 : 2} />) : this.state.showVriendenWizard ? (<VriendenPageDesktop currentMemberData={this.state.currentMemberData} />) : this.state.showLists ? (
              (
                <MemberHome
                  memberId={this.props.match.params.user_id}
                  member_user_id={this.state.currentMemberData.user_id}
                  currentMemberData={this.state.currentMemberData}
                />
              )
            ) : this.state.showItems ? (
              <CreateProductMemberCompany
                onShowItems={this.state.nowNoShowItemsAny}
                showHideNav={() => {
                  this.setState({ hideNav: !this.state.hideNav });
                }}
                currentMemberData={this.state.currentMemberData}
              />
            ) : this.state.showFriends ? (
              <FriendList />
            ) : this.state.showFriendsNewUpdated ? (
              <FriendListRightMenu closePopup={(type) => {
                // if (type === "fromfriendscompletely") {
                //   this.setState({ closeCompleteRightMenu: true });
                // }
                // this.setState({ showRightMenuFriends: false });
                history.goBack()
              }} />
            ) : this.state.showcharityConfirmation ? (
              <CharityConfirmationFullPage />
            ) : this.state.showExclusiveSubscribe ? (
              <ExclusiveSubscribeProcess />
            ) : (
              this.state.showCharity && (
                <Charity
                  subscriptionActivated={false}
                />
              )
            )}
          </div>
        ) : this.state.accountPrivate ? (
          <div className="bg-accounttypeprivate">
            <div
              className="first-sec-cdd nobgimages"
              style={{ height: "100%", maxWidth: "100%" }}
            >
              <div
                className={`fullopacitydiv ${this.state.animationClassAdd && "zoominoutanimation"
                  }`}
              >
                {this.state.currentMemberData.profile_image ? (
                  <img
                    src={this.state.currentMemberData.profile_image}
                    style={{
                      width: 30,
                      height: 30,
                      borderRadius: "50%",
                      marginBottom: 10,
                    }}
                  />
                ) : (
                  <AccountCircleIcon
                    style={{ fontSize: 28, marginBottom: 20 }}
                  />
                )}
                <h3>
                  {this.state.currentMemberData.first_name}{" "}
                  {this.state.currentMemberData.last_name}
                </h3>
                <p>Dit account is privé</p>
                <p>
                  VERSTUUR EEN UITNODIGING <br />
                  OM HET PROFIEL TE BEKIJKEN
                </p>
                {this.state.currentUserHere && this.checkForFriendsText()}
              </div>
              <div
                className="opacitylayerfsc"
                style={{ opacity: this.state.bannerOpacity }}
              ></div>
            </div>
            {window.innerWidth > 1008 && <Footer />}
            {window.innerWidth < 1008 && <FooterGreen />}
          </div>
        ) : (
          <div
            id="mainWrapperAccountViewer"
          >
            {this.state.isDesktop ? (
              <NavbarWrapperVisitor
                subscriptionActivated={false}
                member_id={this.props.match.params.user_id}
                currentMainMember={this.state.currentMemberData}
                showProducts={() => {
                  this.setState({ showItems: false });
                  setTimeout(() => {
                    this.setState({
                      showItems: true,
                      showLists: false,
                      showFriends: false,
                    });
                  }, 10);
                }}
                showPosts={() => {
                  localStorage.setItem("onCurrentTabVolgendPage", "images");
                  setTimeout(() => {
                    history.push(
                      `/member/${this.state.currentMemberData.user_name_id}`
                    );
                    // Commented by me

                    // window.location.href = `/member/${this.state.currentMemberData.user_name_id}`;
                  }, 1000);
                }}
                showFriends={() => {
                  // if ((!this.state.currentMemberData.hideWizard && !this.state.currentUserHere.create_subscription && this.state.currentMemberData.user_id === this.state.currentUserId)) {
                  //   console.log("do nothing")
                  // } else {
                  this.setState({ showRightMenuFriendsAnother: true });
                  // }
                  // localStorage.setItem("onCurrentTabVolgendPage", "friend")
                  // window.location.href = `/member/${this.state.currentMemberData.user_name_id}/friends`
                }}
                hideNav={this.state.hideNav}
                showTrendingPosts={() => {
                  this.setState({ showTrendingPosts: true });
                }}
                showGlobalPosts={() => {
                  this.setState({ showPublicPosts: true });
                }}
                showStoriesPopup={() => {
                  this.setState({ showStoriesRightMenu: true });
                }}
                showStoriesPopupRight={() => {
                  if (
                    this.state.currentMemberData.user_id === this.state.userid
                  ) {
                    this.setState({ showStoriesRightMenuAnothers: true });
                  } else {
                    setTimeout(() => {
                      window.location.href = `/member/${this.state.currentMemberData.user_name_id}`;
                    }, 200);
                    // this.setState({ showStoriesRightMenuViewers: true });
                  }
                  // this.setState({ showStoriesRightMenuAnothers: true })
                }}
              />
            ) : (
              <NavbarWrapperVisitorMobile
                member_id={this.props.match.params.user_id}
                currentMainMember={this.state.currentMemberData}
                showProducts={() => {
                  this.setState({ showItems: false });
                  setTimeout(() => {
                    this.setState({
                      showItems: true,
                      showLists: false,
                      showFriends: false,
                    });
                  }, 10);
                }}
                showPosts={() => {
                  localStorage.setItem("onCurrentTabVolgendPage", "images");
                  setTimeout(() => {
                    history.push(
                      `/member/${this.state.currentMemberData.user_name_id}`
                    );
                  }, 200);
                }}
                showFriends={() => {
                  // if ((!this.state.currentMemberData.hideWizard && !this.state.currentUserHere.create_subscription && this.state.currentMemberData.user_id === this.state.currentUserId)) {
                  //   console.log("do nothing")
                  // } else {
                  this.setState({ showRightMenuFriendsAnother: true });
                  // }
                  // this.setState({ showRightMenuFriendsAnother: true });
                  // this.setState({ showFriends: false })
                  // setTimeout(() => {
                  //     this.setState({ showLists: false, showItems: false, showFriends: true })
                  // }, 10);
                }}
                showTrendingPosts={() => {
                  this.setState({ showTrendingPosts: true });
                }}
                showGlobalPosts={() => {
                  this.setState({ showPublicPosts: true });
                }}
                showStoriesPopup={() => {
                  this.setState({ showStoriesRightMenu: true });
                }}
                showStoriesPopupRight={() => {
                  // this.setState({ showStoriesRightMenuAnothers: true })
                  if (
                    this.state.currentMemberData.user_id === this.state.userid
                  ) {
                    this.setState({ showStoriesRightMenuAnothers: true });
                  } else {
                    setTimeout(() => {
                      window.location.href = `/member/${this.state.currentMemberData.user_name_id}`;
                    }, 200);
                    // this.setState({ showStoriesRightMenuViewers: true });
                  }
                }}
                hideNav={this.state.hideNav}
                showGegevensPopup={this.state.showGegevensPopup}
                closeShowGegevensPopup={() => {
                  this.setState({ showGegevensPopup: false });
                }}
              />
            )}
            {/* {!this.state.isDesktop && (
              <RoundIconsNavbarViewerMobile
                member_id={this.props.match.params.user_id}
                showProducts={() => {
                  this.setState({ showItems: false });
                  setTimeout(() => {
                    this.setState({
                      showItems: true,
                      showLists: false,
                      showFriends: false,
                    });
                  }, 10);
                }}
                showPosts={() => {
                  localStorage.setItem("onCurrentTabVolgendPage", "images");
                  setTimeout(() => {
                    // Commented by me
                    history.push(
                      `/member/${this.state.currentMemberData.user_name_id}`
                    );
                    // window.location.href = `/member/${this.state.currentMemberData.user_name_id}` ;
                  }, 200);
                  // this.setState({ showLists: false })
                  // setTimeout(() => {
                  //     this.setState({ showLists: true, showItems: false, showFriends: false })
                  // }, 10);
                }}
                showFriends={() => {
                  // if ((!this.state.currentMemberData.hideWizard && !this.state.currentUserHere.create_subscription && this.state.currentMemberData.user_id === this.state.currentUserId)) {
                  //   console.log("do nothing")
                  // } else {
                  this.setState({ showRightMenuFriendsAnother: true });
                  // }
                  // this.setState({ showRightMenuFriendsAnother: true });
                  // this.setState({ showFriends: false })
                  // setTimeout(() => {
                  //     this.setState({ showLists: false, showItems: false, showFriends: true })
                  // }, 10);
                }}
                showTrendingPosts={() => {
                  this.setState({ showTrendingPosts: true });
                }}
                showGlobalPosts={() => {
                  this.setState({ showPublicPosts: true });
                }}
                showStoriesPopup={() => {
                  this.setState({ showStoriesRightMenu: true });
                }}
                showStoriesPopupRight={() => {
                  // this.setState({ showStoriesRightMenuAnothers: true })
                  if (
                    this.state.currentMemberData.user_id === this.state.userid
                  ) {
                    this.setState({ showStoriesRightMenuAnothers: true });
                  } else {
                    history.push(`/member/${this.state.currentMemberData.user_name_id}`)
                    // window.location.href = `/member/${this.state.currentMemberData.user_name_id}`;
                    // this.setState({ showStoriesRightMenuViewers: true });
                  }
                }}
                hideNav={this.state.hideNav}
                showGegevensPopup={this.state.showGegevensPopup}
                closeShowGegevensPopup={() => {
                  this.setState({ showGegevensPopup: false });
                }}
              />
            )} */}
            {this.state.showLists && (
              <MemberHome
                member_id={this.props.match.params.user_id}
                member_user_id={this.state.currentMemberData.user_id}
                currentMemberData={this.state.currentMemberData}
              />
            )}
          </div>
        )}
        {this.state.showRightMenuFriends && (
          <div
            className="overlay-div-openadd"
            onClick={() => {
              this.setState({ showRightMenuFriends: false });
            }}
          ></div>
        )}
        {this.state.showRightMenuFriends && (
          <FriendListRightMenu
            closePopup={(type) => {
              if (type === "fromfriendscompletely") {
                this.setState({ closeCompleteRightMenu: true });
              }
              this.setState({ showRightMenuFriends: false });
            }}
          />
        )}

        {this.state.showRightMenuFriendsAnother && (
          <div
            className="overlay-div-openadd"
            onClick={() => {
              this.setState({ showRightMenuFriends: false });
            }}
          ></div>
        )}
        {this.state.showRightMenuFriendsAnother && (
          <ViewerFriendlist
            viewerOnUser={this.state.currentMemberData}
            closePopup={() => {
              this.setState({ showRightMenuFriendsAnother: false });
            }}
          />
        )}
      </div>
    ) : (
      <div className="loaderspaceloading">
        <div className="lds-heart">
          <div></div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    reRendered: state.renderReducer.reRendered,
    title: state.renderReducer.title,
    user: state.authReducers.user,
    album: state.postsReducers.album,
    albumToAdd: state.imagesReducer.albumToAdd,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    rerenderComponent: (reRendered) => dispatch(rerenderComponent(reRendered)),
    updateTitle: (title) => dispatch(updateTitle(title)),
    updateUser: (user) => dispatch(updateUser(user)),
    updateAlbumToAddFriends: (albumToAddFriends) =>
      dispatch(updateAlbumToAddFriends(albumToAddFriends)),
    updateMoreAlbumsAddedFriends: (morealbumsToAddFriends) =>
      dispatch(updateMoreAlbumsAddedFriends(morealbumsToAddFriends)),
    updateCurrentMomentsFriendsPage: (currentMomentsFriends) =>
      dispatch(updateCurrentMomentsFriendsPage(currentMomentsFriends)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WholeMemberStuff);
