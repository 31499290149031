import React, { Component } from "react";
import { Route, Router } from "react-router-dom";
import "../Components/globalmess.css"
import Impressie from "../Containers/Impressie";
import Registration from "../Containers/Registration";
import QuickAuth from "../Components/QuickAuth";
import CompanyDashboard from "../Containers/CompanyDashboard";
import GlobalPosts from "../Containers/Global Posts";
import WholeMemberStuff from "../Components/WholeMemberStuff";
import WholeCompanyStuff from "../Components/WholeCompanyStuff";
import Sorting from "../Components/Sorting";
import VoucherSorting from "../Components/VoucherSorting";
import CampaignsSorting from "../Components/CampaignsSorting";
import VoucherTypeSorting from "../Components/VoucherTypeSorting";
import NewVisit from "../Containers/New Visit";
import VouchersMain from "../Containers/VouchersGlobal/VouchersMain";
import VoucherDetailedMainDesktop from "../Containers/VoucherDetailedDesktop";
// import VoucherDetailedMainDesktop from "../Containers/VoucherDetailedDesktop";
import GlobalPageAdminSettings from "../Containers/GlobalPageAdminSettings";
import Translator from "../Components/Translator";
import StockImagesUpload from "../Components/StockImages";
import ErrorPage from "../Containers/NoPageFound";
import SectorSorting from "../Components/SectorSorting";
import VideoMakerSettings from "../Components/VideoMakerSettings";
import EditFaq from "../Components/EditFaq";
import VoucherEventsMain from "../Containers/VoucherEventsGlobal/VoucherEventsMain";
import VoucherEventsMainCity from "../Containers/VoucherEventsGlobal/VoucherEventsMainCity";
import VoucherEventsCurrentWeek from "../Containers/VoucherEventsGlobal/VoucherEventsCurrentWeek";
import GuestEventCart from "../Containers/GuestEventCart";
import VoucherEventsComingWeek from "../Containers/VoucherEventsGlobal/VoucherEventsComingWeek";
import VoucherEventsComingWeekCity from "../Containers/VoucherEventsGlobal/VoucherEventsComingWeekCity";
import VoucherEventsCurrentWeekCity from "../Containers/VoucherEventsGlobal/VoucherEventsCurrentWeekCity";
import GuestVisibility from "../Containers/GuestVisibility";
import MyOrders from "../Containers/My Orders";
import AllUsersToEdit from "../Components/AllUsers";
import CampaignsEditor from "../Components/CampaignsEditor";
import EditAbouts from "../Components/EditAbouts";
import SettingsSeparate from "../Components/SettingsSeparate";
import SettingsSeparateUsers from "../Components/SettingsSeparateUser";
import EditAboutsUser from "../Components/EditAboutUsers";
import { connect } from "react-redux";
import firebase from "./Firebase";
import LandingsPageUpdated from "../Containers/LandingspageUpdated";
import LoginUpdated from "../Containers/LoginUpdated";
import Commercial from "../Containers/Commercial";
import EditorCommercial from "../Components/EditorCommercial";
import EditorLandingPage from "../Components/EditorLandingPage";
import EditorCharityPage from "../Components/EditCharity";
import CharityLandingPage from "../Containers/CharityLandingsPage";
import SoundSorting from "../Components/SoundSorting";
import TextChanges from "../Components/TextChanges";
import GuestEventCartStepTwo from "../Containers/GuestStepTwo";
import CartNonUserMobile from "../Components/CartNonUserMobile";
import FooterBasket from "../Components/FooterBasket";
import AdminDashboard from "../Components/AdminDashboard";
import GlobalEventTime from "../Components/GlobalEventTime";
import LandingPage from "../Containers/LandingPage";
import LoginAdmin from "../Containers/LoginAdmin";
import WizardSettings from "../Components/WizardSettings";
import ProductDetailed from "../Containers/ProductDetailed";
import IntervalSettings from "../Components/IntervalSettings";
import axios from "axios";
import swal from "sweetalert";
import AccountCreator from "../Components/AccountCreator";
import SubscriptionPlansEdit from "../Components/SubscriptionPlansEdit";
import AccountApprovals from "../Components/AccountApprovals";
import AddEditProductSizes from "../Components/AddEditProductSizes";
import InvoiceGenerator from "../Components/InvoiceGenerator";
import "../Components/newcssfile.css"
import AdminProductsWrapper from "../Containers/ProductsPage/AdminProductsWrapper";
import DashboardSettingsMember from "../Components/DashboardSettingsMember";
import VouchersMainCity from "../Containers/VouchersGlobal/VouchersMainCity";
import VouchersGlobalMain from "../Containers/VouchersGlobal/VouchersGlobalMain";
import VouchersGlobalMainCity from "../Containers/VouchersGlobal/VouchersGlobalMainCity";
import CompanySubscribe from "../Containers/CompanySubscribe";
import BookmarkedThings from "../Containers/BookmarkedThings";
import AccountImportRestaurants from "../Components/AccountImportRestaurants";
import VolgendMobileUpdated from "../Containers/VolgendMobileUpdated";
import GuestStepOne from "../Containers/GuestStepOne";
import CompanySubscribeViaLink from "../Containers/CompanySubscribeViaLink";
import BhodiAbout from "../Components/BhodiAbout";
import PaymentLinks from "../Components/PaymentLinks";
import OnboardUsers from "../Containers/Onboard-Users";
import ManualImports from "../Components/ManualImports";
import OnboardCompany from "../Containers/OnboardCompany";
import SpecialProducts from "../Containers/SpecialProducts";
import EditorSpecialPage from "../Components/EditorSpecialPage";
import BrandPage from "../Containers/BrandPage";
import TiktokPage from "../Containers/TikTokPage";
import SignUpAnnouncement from "../Containers/SignUpAnnouncement";
import SignUpAnnouncementStepTwo from "../Containers/SignUpAnnouncementStepTwo";
import AdminLocalEvent from "../Components/AdminLocalEvent";
import PayViaLink from "../Containers/PayViaLink";
import Informatie from "../Containers/Informatie";
import BarberClientOnboarding from "../Containers/BarberClientOnboarding";
import BarberStaticPage from "../Containers/BarberStaticPage";
import ResortsPage from "../Containers/ResortsPage";
import AdminCodeWrapper from "../Components/AdminComponents/AdminCodeWrapper";
import EditFaqGuests from "../Components/EditFaqGuest";
import CompanyCampaign from "../Containers/CompanyPaymentLinkCampaign/CompanyCampaign";
import CompanyStaticPage from "../Containers/CompanyPaymentLinkCampaign/CompanyStaticPage";
import ClientsAccountOnboard from "../Containers/CompanyPaymentLinkCampaign/ClientsAccountOnboard";
import AdminClientsList from "../Containers/CompanyPaymentLinkCampaign/AdminClientsList";
import LoginCompany from "../Containers/LoginCompany";

const CreateBrowserHistory = require("history").createBrowserHistory;
export const history = CreateBrowserHistory();


class Routes extends Component {
  constructor() {
    super();
    this.state = {
      showRoute: true,
      isAdmin: false,
      isSubAdmin: false,
    };
  }

  async componentDidMount() {
    const loaderId = document.getElementById("main-page-loader-id");
    loaderId.style.display = "none";
    let userid = localStorage.getItem("userid");

    if (window.innerWidth < 1008) {
      window.addEventListener("pageshow", (event) => {
        if (event.persisted) {
          window.location.reload();
        }
      });
    }

    let isAdmin = this.props.admin_boolean;
    // let isAdmin = localStorage.getItem("isAdmin")
    if (isAdmin) {
      this.setState({ isAdmin: true });
    } else {
      this.setState({ isAdmin: false });
    }
    if (userid) {
      setTimeout(() => {
        if (firebase.auth()) {
          if (firebase.auth().currentUser) {
            if (firebase.auth().currentUser.uid !== userid) {
              localStorage.setItem("userid", firebase.auth().currentUser.uid);
              window.location.reload();
            }
            if (process.env.REACT_APP_SUB_ADMIN_ID.includes(firebase.auth().currentUser.uid)) {
              this.setState({ isSubAdmin: true })
            }
          }
        }
      }, 1800);
      this.setState({ showRoute: true });
      if (window.location.href.includes("/login") || window.location.pathname === "/") {
        // history.goBack();
        if (this.props.user) {
          let filteredUser = this.props.user.filter((g) => {
            return g.user_id === userid
          })
          if (filteredUser.length > 0) {
            if (window.location.pathname === "/") {
              if (window.location.search) {
                let urlMain = new URLSearchParams(window.location.search)
                if (urlMain.get("code")) {
                  history.push(`/company/${filteredUser[0].user_name_id}${window.location.search}`)
                  return false;
                }
              }
            }
            if (filteredUser[0].email == "mailto@bhodi.com") {
              history.push(`/admin`)
            } else if (filteredUser[0].type === "company") {
              history.push(`/company/${filteredUser[0].user_name_id}`)
            } else {
              history.push(`/member/${filteredUser[0].user_name_id}`)
            }
          }
        }
      }
    } else {
      if (!window.location.href.includes("/login") && !window.location.href.includes("/stepTwo") && !window.location.pathname !== "/") {
        window.addEventListener("storage", this.handleStorageChange)
      }
      this.setState({ showRoute: false });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("storage", this.handleStorageChange)
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.user !== nextProps.user) {
      return false;
    } else {
      return true;
    }
  }


  handleStorageChange = (event) => {
    if (event.key === "userid") {
      if (event.newValue) {
        swal({
          title: "Awesome",
          text: "You have created an account, Please click OK to get a seemless experience",
          icon: "success"
        }).then(() => {
          window.location.reload()
        })
      }
    }
  }

  render() {
    return this.state.showRoute ? (
      <Router history={history}>
        <Route exact path="/" component={LandingPage} />
        <Route exact path="/resorts" component={ResortsPage} />
        <Route path="/404" component={ErrorPage} />
        <Route path="/login" component={LoginUpdated} />
        <Route path="/admin-login" component={LoginAdmin} />
        <Route path="/company-login" component={LoginCompany} />
        <Route path="/quickAuth/:userid" component={QuickAuth} />
        {/* First  */}
        <Route exact path="/member/:user_id" component={WholeMemberStuff} />
        <Route
          exact
          path="/member/:user_id/friends"
          component={WholeMemberStuff}
        />
        <Route
          exact
          path="/member/:user_id/vrienden"
          component={WholeMemberStuff}
        />
        <Route
          exact
          path="/member/:user_id/volgend"
          component={WholeMemberStuff}
        />
        <Route
          exact
          path="/member/:user_id/charity"
          component={WholeMemberStuff}
        />
        <Route
          exact
          path="/member/:user_id/charityConfirmation"
          component={WholeMemberStuff}
        />
        <Route
          exact
          path="/member/:user_id/vriendenDesktop"
          component={WholeMemberStuff}
        />
        <Route
          exact
          path="/member/:user_id/exclusive-subscribing"
          component={WholeMemberStuff}
        />
        <Route exact path="/timerMainPage" component={GlobalEventTime} />
        {/* First Route */}
        <Route
          exact
          path="/client-onboard/:industry"
          component={ClientsAccountOnboard}
        />
        <Route
          exact
          path="/company/:company_id"
          component={WholeCompanyStuff}
        />
        <Route
          exact
          path="/campaign/:company_id"
          component={CompanyCampaign}
        />
        <Route
          exact
          path="/company/:company_id/campaign-guest"
          component={WholeCompanyStuff}
        />
        <Route
          exact
          path="/company/:company_id/friends"
          component={WholeCompanyStuff}
        />
        <Route
          exact
          path="/company/:company_id/volgend"
          component={WholeCompanyStuff}
        />
        <Route
          exact
          path="/company/:company_id/trending"
          component={WholeCompanyStuff}
        />
        <Route
          exact
          path="/company/:company_id/charity"
          component={WholeCompanyStuff}
        />
        <Route
          exact
          path="/company/:company_id/vrienden"
          component={WholeCompanyStuff}
        />
        <Route
          exact
          path="/company/:company_id/exclusive-subscribing"
          component={WholeCompanyStuff}
        />
        <Route
          exact
          path="/company/:company_id/contact"
          component={WholeCompanyStuff}
        />
        <Route
          exact
          path="/company/:company_id/contact/guest"
          component={WholeCompanyStuff}
        />
        <Route
          exact
          path="/company/:company_id/charity/guest"
          component={WholeCompanyStuff}
        />
        <Route
          exact
          path="/company/:company_id/order-success"
          component={WholeCompanyStuff}
        />
        <Route
          exact
          path="/company/:company_id/charityConfirmation"
          component={WholeCompanyStuff}
        />
        <Route
          exact
          path="/company/:company_id/vriendenDesktop"
          component={WholeCompanyStuff}
        />
        <Route
          exact
          path="/company/:company_id/generate-qr"
          component={WholeCompanyStuff}
        />
        <Route
          exact
          path="/bhodi-about"
          component={BhodiAbout}
        />


        {(this.state.isAdmin && !this.state.isSubAdmin) && (
          <Route path="/sectors-sorting" component={SectorSorting} />
        )}
        {(this.state.isAdmin && !this.state.isSubAdmin) && (
          <Route path="/admin" component={AdminDashboard} />
        )}
        {(this.state.isAdmin && !this.state.isSubAdmin) && (
          <Route path="/admin-products" component={AdminProductsWrapper} />
        )}
        {(this.state.isAdmin && !this.state.isSubAdmin) && (
          <Route path="/admin-code-import" component={AdminCodeWrapper} />
        )}
        {(this.state.isAdmin && !this.state.isSubAdmin) && (
          <Route path="/member-dashboard-settings" component={DashboardSettingsMember} />
        )}
        {(this.state.isAdmin && !this.state.isSubAdmin) && (
          <Route path="/category-sorting" component={VoucherSorting} />
        )}
        {(this.state.isAdmin && !this.state.isSubAdmin) && (
          <Route path="/edit-product-sizes" component={AddEditProductSizes} />
        )}
        {(this.state.isAdmin && !this.state.isSubAdmin) && (
          <Route path="/voucher-sorting" component={Sorting} />
        )}
        {(this.state.isAdmin && !this.state.isSubAdmin) && (
          <Route path="/campaigns-sorting" component={CampaignsSorting} />
        )}
        {(this.state.isAdmin && !this.state.isSubAdmin) && (
          <Route path="/voucher-type-sorting" component={VoucherTypeSorting} />
        )}
        {(this.state.isAdmin && !this.state.isSubAdmin) && (
          <Route path="/global-settings" component={GlobalPageAdminSettings} />
        )}
        {(this.state.isAdmin && !this.state.isSubAdmin) && (
          <Route path="/language-translate" component={Translator} />
        )}
        {(this.state.isAdmin && !this.state.isSubAdmin) && (
          <Route path="/subscription-plans" component={SubscriptionPlansEdit} />
        )}
        {(this.state.isAdmin && !this.state.isSubAdmin) && (
          <Route path="/stock-image-upload" component={StockImagesUpload} />
        )}
        {(this.state.isAdmin && !this.state.isSubAdmin) && (
          <Route path="/video-maker-settings" component={VideoMakerSettings} />
        )}
        {this.state.isAdmin && <Route path="/edit-faqs" component={EditFaq} />}
        {this.state.isAdmin && <Route path="/edit-faqs-guest" component={EditFaqGuests} />}
        {(this.state.isAdmin && !this.state.isSubAdmin) && (
          <Route path="/edit-users" component={AllUsersToEdit} />
        )}
        {(this.state.isAdmin && !this.state.isSubAdmin) && (
          <Route path="/edit-abouts" component={EditAbouts} />
        )}
        {(this.state.isAdmin && !this.state.isSubAdmin) && (
          <Route path="/edit-abouts-users" component={EditAboutsUser} />
        )}
        {(this.state.isAdmin && !this.state.isSubAdmin) && (
          <Route path="/edit-landingsPage" component={EditorLandingPage} />
        )}
        {(this.state.isAdmin && !this.state.isSubAdmin) && (
          <Route path="/edit-commercial" component={EditorCommercial} />
        )}
        {(this.state.isAdmin && !this.state.isSubAdmin) && (
          <Route path="/edit-charity" component={EditorCharityPage} />
        )}
        {(this.state.isAdmin && !this.state.isSubAdmin) && (
          <Route path="/edit-sounds" component={SoundSorting} />
        )}
        {(this.state.isAdmin && !this.state.isSubAdmin) && (
          <Route path="/text-changes" component={TextChanges} />
        )}
        {(this.state.isAdmin && !this.state.isSubAdmin) && (
          <Route path="/wizard-settings" component={WizardSettings} />
        )}
        {(this.state.isAdmin && !this.state.isSubAdmin) && (
          <Route path="/interval-settings" component={IntervalSettings} />
        )}
        {(this.state.isAdmin && !this.state.isSubAdmin) && (
          <Route path="/account-creator" component={AccountCreator} />
        )}
        {this.state.isAdmin && (
          <Route path="/account-import" component={AccountImportRestaurants} />
        )}
        {this.state.isAdmin && (
          <Route path="/clients-list" component={AdminClientsList} />
        )}
        {this.state.isAdmin && (
          <Route path="/account-manual-import" component={ManualImports} />
        )}
        {(this.state.isAdmin && !this.state.isSubAdmin) && (
          <Route path="/account-approvals" component={AccountApprovals} />
        )}
        {(this.state.isAdmin && !this.state.isSubAdmin) && (
          <Route path="/invoice-generator" component={InvoiceGenerator} />
        )}
        {(this.state.isAdmin && !this.state.isSubAdmin) && (
          <Route path="/create-payment-links" component={PaymentLinks} />
        )}
        {(this.state.isAdmin && !this.state.isSubAdmin) && (
          <Route path="/admin-local-event" component={AdminLocalEvent} />
        )}
        <Route exact path="/order-list" component={MyOrders} />
        {/* {this.state.isAdmin && <Route path="/language-translate" component={Translator} />} */}
        <Route
          exact
          path="/product-detailed"
          component={ProductDetailed}
        />

        <Route exact path="/new-visit" component={NewVisit} />
        {/* <Route
          exact
          path="/global-vouchers/:sectorid"
          component={VouchersMain}
        /> */}

        <Route
          exact
          path="/global-vouchers/:sectorid"
          component={VouchersMain}
        />
        <Route
          exact
          path="/global-vouchers/:sectorid/filterByCity/:coords"
          component={VouchersMainCity}
        />
        <Route
          exact
          path="/global-vouchers/:sectorid/filterbyCategory/:maincategory"
          component={VouchersGlobalMain}
        />
        <Route
          exact
          path="/global-vouchers/:sectorid/filterbyCategory/:maincategory/filterByCity/:coords"
          component={VouchersGlobalMainCity}
        />
        <Route exact path="/bookmarked-things" component={BookmarkedThings} />
        <Route
          exact
          path="/global-voucher-events/:sectorid"
          component={VoucherEventsMain}
        />
        <Route
          exact
          path="/global-voucher-events/:sectorid/filterByCity/:coords"
          component={VoucherEventsMainCity}
        />
        <Route
          exact
          path="/global-voucher-events/:sectorid/filter/currentWeek"
          component={VoucherEventsCurrentWeek}
        />
        <Route
          exact
          path="/global-voucher-events/:sectorid/filter/currentWeek/filterByCity/:coords"
          component={VoucherEventsCurrentWeekCity}
        />
        <Route
          exact
          path="/global-voucher-events/:sectorid/filter/comingWeek"
          component={VoucherEventsComingWeek}
        />
        <Route
          exact
          path="/global-voucher-events/:sectorid/filter/comingWeek/filterByCity/:coords"
          component={VoucherEventsComingWeekCity}
        />
        <Route exact path="/guest-event-cart" component={GuestStepOne} />
        <Route exact path="/signup-announcement" component={SignUpAnnouncement} />
        <Route exact path="/guest-event-cart/stepTwo" component={GuestEventCartStepTwo} />
        <Route exact path="/signup-announcement/stepTwo" component={SignUpAnnouncementStepTwo} />
        {/* <Route
          exact
          path="/guest-event-cart/stepThree"
          component={GuestEventCartStepTwo}
        /> */}
        {/* <Route exact path="/guest-event-cart" component={GuestEventCart} />
        <Route
          exact
          path="/guest-event-cart/stepTwo"
          component={GuestEventCartStepTwo}
        /> */}
        <Route exact path="/editor/:campaignType" component={CampaignsEditor} />
        <Route
          exact
          path="/settings-company/:settings_id"
          component={SettingsSeparate}
        />
        <Route
          exact
          path="/settings-separate-users/:settings_id"
          component={SettingsSeparateUsers}
        />
        <Route exact path="/charity-page" component={CharityLandingPage} />
        <Route exact path="/commercial" component={Commercial} />
        <Route exact path="/cart-page-member" component={CartNonUserMobile} />
        <Route exact path="/cart-page-guest" component={FooterBasket} />
        <Route
          exact
          path="/landing-page-new"
          component={LandingPage}
        />
        <Route
          exact
          path="/company-subscribe"
          component={CompanySubscribe}
        />
        <Route
          exact
          path="/pay-via-link"
          component={PayViaLink}
        />
        <Route
          exact
          path="/loginCompanyDirectly"
          component={CompanySubscribeViaLink}
        />
        <Route
          exact
          path="/onboard-users"
          component={OnboardUsers}
        />
        <Route
          exact
          path="/onboard-company"
          component={OnboardCompany}
        />
        <Route
          exact
          path="/special-products"
          component={SpecialProducts}
        />
        <Route
          exact
          path="/TikTok"
          component={TiktokPage}
        />
        <Route
          exact
          path="/special-products-cms"
          component={EditorSpecialPage}
        />
        <Route
          exact
          path="/brand/:brand_url"
          component={BrandPage}
        />
        <Route
          exact
          path="/informatie"
          component={Informatie}
        />
        <Route
          exact
          path="/onboarding-barber-client"
          component={BarberClientOnboarding}
        />
        <Route
          exact
          path="/barber-static-page"
          component={BarberStaticPage}
        />
        <Route
          exact
          path="/company-static-page"
          component={CompanyStaticPage}
        />
      </Router>
    ) : (
      <Router history={history}>
        <Route exact path="/" component={LandingPage} />
        <Route exact path="/resorts" component={ResortsPage} />
        <Route exact path="/charity-page" component={CharityLandingPage} />
        <Route exact path="/commercial" component={Commercial} />
        {/* {this.state.showRoute && <Route path="/posts" component={AllImages} />} */}
        {/* <Route path="/detailedPage" component={GuestVisibility} /> */}
        <Route path="/404" component={GuestVisibility} />

        <Route path="/charity" component={GuestVisibility} />
        <Route path="/cart-orders/:typeoforder" component={GuestVisibility} />
        <Route exact path="/search/:searchText" component={GuestVisibility} />
        <Route path="/product-overview" component={GuestVisibility} />
        <Route path="/voucher-overview" component={GuestVisibility} />
        <Route path="/add-product" component={GuestVisibility} />
        <Route path="/coupon-overview" component={GuestVisibility} />
        <Route path="/faq" component={GuestVisibility} />
        <Route path="/aanbieders-new" component={GuestVisibility} />
        <Route
          path="/product-detail/:pushProduct"
          component={GuestVisibility}
        />
        <Route path="/voucher-list" component={GuestVisibility} />
        <Route path="/voucher-list-global" component={GuestVisibility} />
        <Route path="/groepen" component={GuestVisibility} />
        <Route path="/albums" component={GuestVisibility} />
        <Route path="/order-confirmation" component={GuestVisibility} />
        <Route path="/invite" component={GuestVisibility} />
        <Route path="/login" component={LoginUpdated} />
        <Route path="/admin-login" component={LoginAdmin} />
        <Route path="/company-login" component={LoginCompany} />
        <Route path="/quickAuth/:userid" component={GuestVisibility} />
        {/* <Route path="/createproductmember" component={CreateProductMember} /> */}
        <Route path="/thankyoupage/:order_id" component={GuestVisibility} />
        {/* Second  */}
        <Route exact path="/member/:user_id" component={GuestVisibility} />
        <Route
          exact
          path="/member/:user_id/friends"
          component={GuestVisibility}
        />
        <Route
          exact
          path="/member/:user_id/volgend"
          component={GuestVisibility}
        />
        <Route
          exact
          path="/member/:user_id/vrienden"
          component={GuestVisibility}
        />
        <Route
          exact
          path="/member/:user_id/charity"
          component={GuestVisibility}
        />
        <Route
          exact
          path="/member/:member_id/products"
          component={GuestVisibility}
        />
        <Route
          exact
          path="/member/:user_id/vriendenDesktop"
          component={GuestVisibility}
        />
        <Route
          exact
          path="/member/:member_id/charityConfirmation"
          component={GuestVisibility}
        />
        <Route exact path="/timerMainPage" component={GuestVisibility} />
        {/* Second Route */}
        <Route
          exact
          path="/client-onboard/:industry"
          component={ClientsAccountOnboard}
        />
        <Route
          exact
          path="/company/:company_id"
          component={WholeCompanyStuff}
        />
        <Route
          exact
          path="/campaign/:company_id"
          component={CompanyCampaign}
        />
        <Route
          exact
          path="/company/:company_id/campaign-guest"
          component={WholeCompanyStuff}
        />
        <Route
          exact
          path="/company/:company_id/friends"
          component={GuestVisibility}
        />
        <Route
          exact
          path="/company/:company_id/volgend"
          component={GuestVisibility}
        />
        <Route
          exact
          path="/company/:company_id/trending"
          component={GuestVisibility}
        />
        <Route
          exact
          path="/company/:company_id/charity"
          component={GuestVisibility}
        />
        <Route
          exact
          path="/company/:company_id/vriendenDesktop"
          component={WholeCompanyStuff}
        />
        <Route
          exact
          path="/company/:company_id/contact"
          component={GuestVisibility}
        />
        <Route
          exact
          path="/company/:company_id/vrienden"
          component={WholeCompanyStuff}
        />
        <Route
          exact
          path="/company/:company_id/contact/guest"
          component={WholeCompanyStuff}
        />
        <Route
          exact
          path="/company/:company_id/charity/guest"
          component={WholeCompanyStuff}
        />
        <Route
          exact
          path="/company/:company_id/charityConfirmation"
          component={GuestVisibility}
        />
        <Route
          exact
          path="/bhodi-about"
          component={BhodiAbout}
        />
        {/* {this.state.isAdmin && <Route path="/language-translate" component={Translator} />} */}
        {/* <Route
          exact
          path="/searchProduct/:searchid"
          component={GuestVisibility}
        /> */}
        <Route exact path="/new-visit" component={GuestVisibility} />

        <Route
          exact
          path="/product-detailed"
          component={ProductDetailed}
        />
        <Route
          exact
          path="/global-voucher-list/:sectorid/filterByCity/:coords"
          component={GuestVisibility}
        />
        <Route exact path="/main-shop/:sectorid" component={GuestVisibility} />
        <Route exact path="/gelocation" component={GuestVisibility} />
        <Route
          exact
          path="/global-shop/:sectorid"
          component={GuestVisibility}
        />
        <Route
          exact
          path="/global-shop/:sectorid/filterByCity/:coords"
          component={GuestVisibility}
        />
        <Route
          exact
          path="/global-shop/:sectorid/filterbyCategory/:maincategory"
          component={GuestVisibility}
        />
        <Route
          exact
          path="/global-shop/:sectorid/filterbyCategory/:maincategory/filterByCity/:coords"
          component={GuestVisibility}
        />
        <Route
          exact
          path="/global-shop/:sectorid/filterbySubCategory/:maincategory/:subcategory"
          component={GuestVisibility}
        />
        <Route
          exact
          path="/global-shop/:sectorid/filterbySubCategory/:maincategory/:subcategory/filterByCity/:coords"
          component={GuestVisibility}
        />
        <Route
          exact
          path="/global-vouchers/:sectorid"
          component={GuestVisibility}
        />
        <Route
          exact
          path="/global-voucher-detailed/:voucherKey"
          component={GuestVisibility}
        />
        <Route
          exact
          path="/global-products/:sectorid"
          component={GuestVisibility}
        />
        <Route
          exact
          path="/global-products/:sectorid/filterByCity/:coords"
          component={GuestVisibility}
        />
        <Route
          exact
          path="/global-products/:sectorid/filterbyCategory/:maincategory"
          component={GuestVisibility}
        />
        <Route
          exact
          path="/global-products/:sectorid/filterbyCategory/:maincategory/filterByCity/:coords"
          component={GuestVisibility}
        />
        <Route
          exact
          path="/global-voucher-events/:sectorid"
          component={GuestVisibility}
        />
        <Route
          exact
          path="/global-voucher-events/:sectorid/filterByCity/:coords"
          component={GuestVisibility}
        />
        <Route
          exact
          path="/global-voucher-events/:sectorid/filter/currentWeek"
          component={GuestVisibility}
        />
        <Route
          exact
          path="/global-voucher-events/:sectorid/filter/currentWeek/filterByCity/:coords"
          component={GuestVisibility}
        />
        <Route
          exact
          path="/global-voucher-events/:sectorid/filter/comingWeek"
          component={GuestVisibility}
        />
        <Route
          exact
          path="/global-voucher-events/:sectorid/filter/comingWeek/filterByCity/:coords"
          component={GuestVisibility}
        />
        <Route exact path="/guest-event-cart" component={GuestStepOne} />
        <Route exact path="/signup-announcement" component={SignUpAnnouncement} />
        <Route exact path="/guest-event-cart/stepTwo" component={GuestEventCartStepTwo} />
        <Route exact path="/signup-announcement/stepTwo" component={SignUpAnnouncementStepTwo} />
        {/* <Route
          exact
          path="/guest-event-cart/stepThree"
          component={GuestEventCartStepTwo}
        /> */}
        <Route exact path="/order-list" component={GuestVisibility} />
        <Route exact path="/editor/:campaignType" component={GuestVisibility} />

        <Route exact path="/cart-page-guest" component={FooterBasket} />

        <Route
          exact
          path="/landing-page-new"
          component={LandingPage}
        />
        <Route
          exact
          path="/company-subscribe"
          component={CompanySubscribe}
        />
        <Route
          exact
          path="/pay-via-link"
          component={PayViaLink}
        />
        <Route
          exact
          path="/loginCompanyDirectly"
          component={CompanySubscribeViaLink}
        />
        <Route
          exact
          path="/onboard-users"
          component={OnboardUsers}
        />
        <Route
          exact
          path="/onboard-company"
          component={OnboardCompany}
        />
        <Route
          exact
          path="/special-products"
          component={SpecialProducts}
        />
        <Route
          exact
          path="/TikTok"
          component={TiktokPage}
        />
        <Route
          exact
          path="/special-products-cms"
          component={EditorSpecialPage}
        />
        <Route
          exact
          path="/brand/:brand_url"
          component={BrandPage}
        />
        <Route
          exact
          path="/informatie"
          component={Informatie}
        />
        <Route
          exact
          path="/onboarding-barber-client"
          component={BarberClientOnboarding}
        />
        <Route
          exact
          path="/barber-static-page"
          component={BarberStaticPage}
        />
        <Route
          exact
          path="/company-static-page"
          component={CompanyStaticPage}
        />
      </Router>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    admin_boolean: state.globalReducers.admin_boolean,
    user: state.authReducers.user,
  };
};

export default connect(mapStateToProps)(Routes);
