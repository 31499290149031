import React, { Component } from 'react'
import { connect } from 'react-redux';
import firebase from "../../Config/Firebase"
import {
    updateAdminOrNot,
    updateGlobalStuff,
    updateAlbumToAdd,
} from "../../Redux/actions/authActions";
import Footer from '../../Components/Footer';
import FooterGreen from '../../Components/FooterGreen';

export class BarberStaticPage extends Component {
    constructor() {
        super()
        this.state = {
            secondStep: false,
            aanmeldenloader: false,

            showMainLoader: false,

            customerId: "",
            barber_id: "",

            barberData: "",

            linkNotAvailable: false,
            alreadyVisitedOnce: false,
        }
    }

    componentDidMount() {
        let windowInnerHeight = window.innerHeight
        this.setState({ windowInnerHeight })

        let urlMain = new URLSearchParams(window.location.search)

        let staticVisited = localStorage.getItem("static-visited")

        let max_time_onboarding_barber_client = localStorage.getItem("max_time_onboarding_barber_client")

        let timerContinued = true

        if (max_time_onboarding_barber_client) {
            if (JSON.parse(max_time_onboarding_barber_client) < new Date().getTime()) {
                timerContinued = false
            }
        }

        if (staticVisited && timerContinued) {
            this.setState({ alreadyVisitedOnce: true })
        } else if (urlMain.get("barber_id") && urlMain.get("visit_time")) {
            this.setState({ showMainLoader: true })

            let barber_name_id = urlMain.get("barber_id")
            let visit_time = urlMain.get("visit_time")

            firebase
                .database()
                .ref("users")
                .orderByChild("user_name_id")
                .equalTo(barber_name_id)
                .once("child_added", (data) => {
                    if (data.val()) {
                        let barberData = data.val()

                        if (barberData.static_generate_time) {
                            let date = new Date(Date.parse(barberData.static_generate_time))
                            date.setMinutes(date.getMinutes() + 1)

                            let dateVisit = new Date(Date.parse(visit_time))
                            dateVisit.setMinutes(date.getMinutes() + 1)

                            if (new Date().getTime() < date.getTime() && new Date().getTime() < dateVisit.getTime()) {
                                this.generateQRCode(barberData)
                                return false;
                            }
                        }

                        this.setState({ linkNotAvailable: true })
                    }
                });
        } else {
            this.setState({ linkNotAvailable: true })
        }
    }


    generateQRCode(currentUser) {
        let inputString = currentUser.company_name

        const initials = inputString
            .split(' ')
            .filter(word => word.length > 0)  // Filter out any empty words (due to extra spaces)
            .map(word => word[0])  // Map each word to its first letter
            .join('');  // Join the array of first letters into a single string

        // Get the current time
        const now = new Date();
        const hours = String(now.getHours()).padStart(2, '0');  // Get hours and pad with leading zero if necessary
        const minutes = String(now.getMinutes()).padStart(2, '0');  // Get minutes and pad with leading zero if necessary

        // Create the time string in the format HHMM
        const timeString = hours + minutes;

        let number = 1001
        if (currentUser.qr_code_number) {
            number = currentUser.qr_code_number
        }

        firebase.database().ref(`users/${currentUser.user_id}/qr_code_number`).set(number + 1)

        // Append the number to the resulting string
        let finalString = initials + timeString + number;

        let query = `qr_codes/${currentUser.user_id}/${finalString}`

        let obj = {
            code: finalString,
            is_scanned: false,
            created_at: new Date().toISOString(),
            barber_id: currentUser.user_id,
        }

        firebase.database().ref(`${query}`).set(obj)

        localStorage.setItem("static-visited", true)
        window.location.href = `/onboarding-barber-client?id=${finalString}&barber_id=${currentUser.user_name_id}`

        // this.setState({ qrPopupOpen: true, qrCodeLink: , currentQrQuery: query })
    }

    render() {
        return (
            (this.state.linkNotAvailable || this.state.alreadyVisitedOnce) ? (<div className="global-event-cart globaleventcartfixedstickyfooter companysubscribepagelink companysubscribepagelinkbarber clientonboardingbarber" style={{ minHeight: window.innerWidth < 1008 && `${window.innerHeight}px`, height: window.innerWidth < 1008 && `${this.state.windowInnerHeight}px` }}>
                <div className="left-gec">
                    <div className='form-box-cocosum third-step-fbcoco'>
                        <p className='first-smt-fbcoco'>Sorry</p>
                        {this.state.alreadyVisitedOnce ? (<div className='second-headingwrap-fbcoco sc-fourthstep-main sc-fifthstep-main' style={{ flexDirection: "column", alignItems: "flex-start", marginBottom: 0 }}>
                            <p>You've already used your chance.</p>
                        </div>) : (<div className='second-headingwrap-fbcoco sc-fourthstep-main sc-fifthstep-main' style={{ flexDirection: "column", alignItems: "flex-start", marginBottom: 0 }}>
                            <p>Use time of link is expired.</p>
                        </div>)}
                    </div>
                </div>
                {window.innerWidth > 1008 && (<div className="right-gec">

                </div>)}

                {window.innerWidth > 1008 && <Footer notOnMainPage={true} />}
                {window.innerWidth < 1008 && <FooterGreen notOnMainPage={true} />}
            </div>) : <div
                className="global-event-cart globaleventloaderpage"
                style={{
                    height: "auto",
                    top: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: 999999999,
                }}
            >
                <div className="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                {window.innerWidth > 1008 && <Footer notOnMainPage={true} />}
                {window.innerWidth < 1008 && <FooterGreen notOnMainPage={true} />}
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        global_values: state.globalReducers.global_values,
        user: state.authReducers.user,
        admin_boolean: state.globalReducers.admin_boolean,
        globalsectors: state.categoriesReducer.globalsectors,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateAdminOrNot: (admin_boolean) => dispatch(updateAdminOrNot(admin_boolean)),
        updateGlobalStuff: (global_values) => dispatch(updateGlobalStuff(global_values)),
        updateAlbumToAdd: (albumToAdd) => dispatch(updateAlbumToAdd(albumToAdd)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BarberStaticPage);