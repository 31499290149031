import React, { Component } from "react";
import FavoriteIcon from "@material-ui/icons/Favorite";
import firebase from "../Config/Firebase";
import { connect } from "react-redux";
import { updateSubscriptionPlans } from "../Redux/actions/authActions";
import CloseIcon from "@material-ui/icons/Close";

export class CharityPopup extends Component {
  state = {
    subscription_data: [],
  };

  componentDidMount() {
    const loaderId = document.getElementById("main-page-loader-id");
    loaderId.style.display = "none";
    let arr = [];
    firebase
      .database()
      .ref("subscription_plans")
      .on("child_added", (data) => {
        if (data.val().price !== "0.00") {
          arr.push(data.val());
          this.props.updateSubscriptionPlans(arr);
          this.setState({
            subscription_data: arr,
            plan_id: arr[0].plan_id,
            plan_amount: arr[0].price,
          });
        }
      });
  }

  render() {
    return (
      <div className="charitypopupscreen">
        <div className="charity-popup-overlay"></div>
        <div className="charity-popup-content">
          <CloseIcon
            style={{ position: "absolute", top: 10, right: 10, color: "gray" }}
            onClick={() => {
              this.props.closePopupCharity();
            }}
          />
          <FavoriteIcon />
          <p className="title-cpcontent">CHARITY SALE</p>
          <p className="desc-cpcontent">
            De aangesloten winkeliers maken allerlei leuke acties beschikbaar
            voor de charity members van Bhodi.
          </p>
          <p className="desc-cpcontent">
            Als charity member ondersteun je kinderen en scholen in Tanzania met
            maaltijden en materialen.
          </p>
          <p className="desc-cpcontent">
            Je maandelijkse bijdrage kan je op ieder gewenst moment stopzetten.
          </p>
          <div
            className="radios-charity-lfss"
            style={{ justifyContent: "center" }}
          >
            {this.state.subscription_data.length > 0 &&
              [...this.state.subscription_data].sort((a, b) => a.sort_level - b.sort_level).map((e) => {
                if (!e.active) {
                  return false;
                }
                return (
                  <div className="inner-rclfss">
                    <input
                      type="radio"
                      id="radiocharity"
                      name="charity"
                      value={e.plan_id}
                      checked={e.plan_id === this.state.current_plan_id}
                      onChange={() =>
                        this.setState({
                          plan_id: e.plan_id,
                          plan_amount: e.price,
                          current_plan_id: e.plan_id,
                          current_plan_amount: e.price,
                        })
                      }
                    />
                    <span>€ {Number(e.price)}</span>
                  </div>
                );
              })}
          </div>
          <button>VOLGENDE STEP</button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    subscription_plans: state.globalReducers.subscription_plans,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateSubscriptionPlans: (subscription_plans) =>
      dispatch(updateSubscriptionPlans(subscription_plans)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CharityPopup);
