import localforage from 'localforage';

let bhodi_products = ""
let product_categories = ""

localforage.getItem("persist:root").then((value) => {
    if (value) {
        let converted = JSON.parse(value)
        if (converted.productsReducers) {
            let parsed = JSON.parse(converted.productsReducers)
            if (parsed.bhodi_products) {
                bhodi_products = parsed.bhodi_products
            }
            if (parsed.product_categories) {
                product_categories = parsed.product_categories
            }
        }
    }
})

const initial_state = {
    bhodi_products: bhodi_products ? bhodi_products : null,
    product_categories: product_categories ? product_categories : null,
}

const reducer = (state = initial_state, action) => {
    switch (action.type) {
        case "update_bhodi_products": {
            return { ...state, bhodi_products: action.bhodi_products }
        }
        case "update_product_sector_categories": {
            return { ...state, product_categories: action.product_categories }
        }
        default: {
            return state;
        }
    }
}

export default reducer