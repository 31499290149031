import React, { Component } from "react";
import firebase from "../Config/Firebase";
import { connect } from "react-redux";
import NavbarMenuMobileFooterStuff from "./NavbarMenuMobileFooterStuff";

export class RoundIconsNavbarViewerMobile extends Component {
    constructor() {
        super();
        this.state = {
            active: false,
            search: false,
            searchText: "",
            rightModalSettings: false,
            arrForSearch: [],
            checkedPrivate: false,
            checkedHideDonation: false,
            checkedHideFriendsInfo: false,
            checkedHideProfileImage: false,
            checkedHideProfileInfo: false,
            showPostsActive: true,
            showItemsDiv: false,
            showItems: false,
            showLists: true,
            showFriends: false,
            showProductsOwn: false,
            showFriendstab: false,
            showProductsBookmarked: false,
            showBookmarkTab: false,
            showProductsOthertab: false,
            userNameInput: false,
            userDescInput: false,
            memberProducts: [],
            currentMemberData: "",
            currentUser: "",
            currentUserMember: "",
            currentUserHere: "",
            allAlbumsCurrentShared: [],
            toShowAlbum: "",
            toShowAlbumImg: "",
            onStoriesPageActive: true,
        };
    }

    componentDidMount() {
        const loaderId = document.getElementById("main-page-loader-id");
        loaderId.style.display = "none";
        let member_id = this.props.member_id;

        let currentUserMember = "";
        let userid = localStorage.getItem("userid");

        let filter = this.props.user.filter((u) => {
            return u.user_id === userid;
        });
        if (filter.length > 0) {
            let currentData = filter[0];
            this.setState({
                currentUserHere: currentData,
                currentUserId: userid,
                isGuestUser: false,
            });
        }

        if (this.props.user) {
            if (this.props.user.length > 0) {
                let filter = this.props.user.filter((u) => {
                    return u.user_name_id === this.props.member_id;
                });
                let currentData = filter[0];
                if (filter.length > 0) {
                    if (currentData) {
                        let a = currentData;
                        let currentUserMember = a;
                        currentUserMember.objectedFriends = [];
                        if (a.following) {
                            this.setState({ showFriendstab: true });
                        } else {
                            this.setState({ showFriendstab: false });
                        }
                        this.setState({
                            currentMemberData: currentUserMember,
                            currentUser: currentUserMember,
                            currentUserMember,
                            currentUserId: userid,
                        });
                    }
                }
            }
        }

        // let promiseFirst = new Promise((resolve) => {
        firebase
            .database()
            .ref("users")
            .orderByChild("user_name_id")
            .equalTo(member_id)
            .once("child_added", (data) => {
                if (data.val()) {
                    currentUserMember = data.val();
                    currentUserMember.objectedFriends = [];
                    if (data.val().following) {
                        this.setState({ showFriendstab: true });
                    } else {
                        this.setState({ showFriendstab: false });
                    }
                    this.setState({
                        currentMemberData: currentUserMember,
                        currentUser: currentUserMember,
                        currentUserMember,
                        currentUserId: userid,
                    });
                }
            });

    }

    componentDidUpdate(prevProps) {
        let userid = localStorage.getItem("userid");
        if (prevProps.user !== this.props.user) {
            if (this.props.user.length > 0) {
                let filter = this.props.user.filter((u) => {
                    return u.user_name_id === this.props.member_id;
                });
                let currentData = filter[0];
                if (filter.length > 0) {
                    if (currentData) {
                        let a = currentData;
                        let currentUserMember = a;
                        this.setState({
                            currentMemberData: currentUserMember,
                            currentUser: currentUserMember,
                            currentUserMember,
                            currentUserId: userid,
                        });
                    }
                }
            }
        }
    }

    render() {
        return (
            !this.props.hideNav && (
                <div className="new-round-icons-member">
                    <ul style={{ width: "100%" }}>
                        {/* <li
                            id="storiespopupnavbar"
                            className={
                                this.state.onStoriesPageActive && "active"
                            }
                        // onClick={() => {
                        //     if (this.props.showStoriesPopupRight) {
                        //         this.props.showStoriesPopupRight();
                        //         this.setState({ storiesPopupStuffRight: true });
                        //     }
                        // }}
                        >
                            {this.state.onStoriesPageActive && (<div className="round-active-newmembers"></div>)}
                            <label>Stories</label>
                        </li> */}
                        <NavbarMenuMobileFooterStuff fromRoundIcon={true} />
                        {!this.state.currentMemberData.hideFriendsInfo &&
                            this.state.showFriendstab && (
                                <li
                                    onClick={() => {
                                        this.props.showFriends();
                                    }}
                                >
                                    <label>Vrienden</label>
                                </li>
                            )}
                        {/* {this.state.allAlbumsCurrentShared.length > 0 && ( */}

                        {/* )} */}
                    </ul>
                </div>
            )
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducers.user,
        album: state.postsReducers.album,
        albumToAddFriends: state.imagesReducer.albumToAddFriends,
    };
};

export default connect(mapStateToProps)(RoundIconsNavbarViewerMobile);
