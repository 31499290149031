import { ArrowBack, ChevronLeft, Close, ExpandMore } from '@material-ui/icons';
import React, { Component } from 'react'
import firebase from "../Config/Firebase";
import NumberFormatter from './NumberFormatter';
import { connect } from 'react-redux';

export class AdminBarberAdministration extends Component {
    constructor() {
        super()
        this.state = {
            selectedDateAdministratie: "",
            allAdministrationData: [],
            allAdministrationDataFilter: [],
            allAdministrationYears: [],
            allAdministrationCompanies: [],
            isLoading: true,
            selectedCompanyAdministratie: "",
        }
    }

    componentDidMount() {
        let userid = localStorage.getItem("userid")
        firebase.database().ref("qr_codes").once("value", (data) => {
            let a = data.val()
            let arrData = []
            let allYears = []
            let allCompanies = []

            if (a) {
                let objected = Object.entries(a)

                objected.map((entryMain) => {
                    let objectedInner = Object.entries(entryMain[1])
                    objectedInner.map((entry) => {
                        if (entry[1].is_joined && entry[1].joined_user_id) {
                            let newObj = Object.assign(entry[1], {})
                            newObj.amount = "1.00"
                            newObj.added_at = new Date(Date.parse(newObj.created_at)).getTime()

                            allYears.push(new Date(Number(newObj.added_at)).getFullYear())
                            allCompanies.push(newObj.barber_id)

                            arrData.push(newObj)
                        }
                    })
                })
            }

            let filteredAllYears = allYears.filter(
                (v, i, a) => a.findIndex((t) => t === v) === i
            );
            let filteredAllCompanies = allCompanies.filter(
                (v, i, a) => a.findIndex((t) => t === v) === i
            );
            this.setState({ allAdministrationData: arrData, allAdministrationDataFilter: arrData, allAdministrationYears: filteredAllYears, allAdministrationCompanies: filteredAllCompanies, isLoading: false })
        })
    }

    getSignedUpMembers(data) {
        let filtered = data.filter((m) => {
            return m.is_joined
        })

        return filtered.length
    }

    getUserNameList(uid) {
        if (this.props.user) {
            let user = this.props.user.find((g) => {
                return g.user_id === uid
            })
            if (user) {
                return `${user.first_name}`
            }
        }
        return ""
    }

    getImageUrl(uid) {
        if (this.props.user) {
            let filtered = this.props.user.find((t) => {
                return t.user_id === uid
            })
            if (filtered && filtered.profile_image) {
                return `${filtered.profile_image}`
            }
        }
        return "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9"
    }


    getAdministrationData() {
        if (this.state.allAdministrationData.length > 0) {
            let allTotal = 0
            this.state.allAdministrationData.map((e) => {
                allTotal += Number(e.amount)
            })
            return <>
                <p>Users Amount: {this.state.allAdministrationData.length}</p>
                <p>Total Donation: €{<NumberFormatter toFormat={allTotal.toFixed(2)} />}</p>
            </>
        } else {
            return <p>No Matching records found</p>
        }
    }

    getUserName(userid) {
        let company = this.props.user.find((g) => {
            return g.user_id === userid
        })
        if (company) {
            return company.company_name
        } else {
            return ""
        }
    }

    render() {
        return (
            <>
                <div className="modal-create-album modalcreatealbumchangedheader changedheadermodalcreatealbum">
                    {!this.props.isDesktop && (
                        <ArrowBack
                            onClick={() => {
                                this.setState({
                                    userInfoRight: false,
                                    menuItemsModals: true,
                                });
                            }}
                            style={{
                                position: "absolute",
                                top: 15,
                                left: 10,
                                fontSize: 18,
                            }}
                        />
                    )}
                    <div
                        className="heading-mca"
                        style={{
                            textAlign: "center",
                            position: "relative",
                            padding: "13px",
                        }}
                    >
                        <font>BARBAR ADMINISTRATIE</font>
                        <div>
                            <ChevronLeft
                                onClick={() => {
                                    this.props.onChevronLeft()
                                }}
                                style={{
                                    fontSize: 18,
                                }}
                            />
                            <div className="spacing-between-icons"></div>
                            <Close
                                onClick={() => {
                                    this.props.onCloseComplete()
                                }}
                                style={{
                                    fontSize: 18,
                                }}
                            />
                        </div>
                    </div>
                    <div className='rightmenuinner-rmmms updatedirghtmenunew newrightmenuglobalgood' style={{ height: "auto", padding: "0px 20px" }}>
                        {this.state.isLoading ? (<div className='loadingAdministrationData'>
                            <div className="lds-ring">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <p style={{ marginTop: 15 }}>LOADING</p>
                        </div>) : (<div className="filter-administratie">
                            <div
                                className="form-miur"
                                style={{ position: "relative" }}
                            >
                                <label>Filter op datum</label>
                                <div className='wrapper-select-new' style={{ marginBottom: 15 }}>
                                    <select value={this.state.selectedDateAdministratie} onChange={(e) => {
                                        this.setState({ selectedDateAdministratie: e.target.value })

                                        if (e.target.value === "") {
                                            if (this.state.selectedYearAdministratie) {
                                                let filtered = this.state.allAdministrationDataFilter.filter((t) => {
                                                    if (t.added_at) {
                                                        return new Date(Number(t.added_at)).getFullYear() === Number(this.state.selectedYearAdministratie)
                                                    }
                                                })
                                                if (this.state.selectedCompanyAdministratie) {
                                                    filtered = filtered.filter((t) => {
                                                        return t.barber_id === this.state.selectedCompanyAdministratie
                                                    })
                                                }
                                                if (filtered.length > 0) {
                                                    this.setState({ allAdministrationData: filtered })
                                                } else {
                                                    this.setState({ allAdministrationData: [] })
                                                }
                                                return false;
                                            }
                                            let dataToAdd = this.state.allAdministrationDataFilter
                                            if (this.state.selectedCompanyAdministratie) {
                                                dataToAdd = dataToAdd.filter((t) => {
                                                    return t.barber_id === this.state.selectedCompanyAdministratie
                                                })
                                            }
                                            this.setState({ allAdministrationData: dataToAdd })
                                            return false;
                                        }

                                        let filtered = this.state.allAdministrationDataFilter.filter((t) => {
                                            if (t.added_at) {
                                                if (this.state.selectedYearAdministratie) {
                                                    return new Date(Number(t.added_at)).getMonth() === Number(e.target.value) && new Date(Number(t.added_at)).getFullYear() === Number(this.state.selectedYearAdministratie)
                                                } else {
                                                    return new Date(Number(t.added_at)).getMonth() === Number(e.target.value)
                                                }
                                            }
                                        })
                                        if (this.state.selectedCompanyAdministratie) {
                                            filtered = filtered.filter((t) => {
                                                return t.barber_id === this.state.selectedCompanyAdministratie
                                            })
                                        }
                                        if (filtered.length > 0) {
                                            this.setState({ allAdministrationData: filtered })
                                        } else {
                                            this.setState({ allAdministrationData: [] })
                                        }
                                    }}>
                                        <option value="">Alle manden</option>
                                        <option value="0">January</option>
                                        <option value="1">February</option>
                                        <option value="2">March</option>
                                        <option value="3">April</option>
                                        <option value="4">May</option>
                                        <option value="5">June</option>
                                        <option value="6">July</option>
                                        <option value="7">August</option>
                                        <option value="8">September</option>
                                        <option value="9">October</option>
                                        <option value="10">November</option>
                                        <option value="11">December</option>
                                    </select>
                                    <ExpandMore
                                        style={{
                                            position: "absolute",
                                            bottom: 5,
                                            right: 5,
                                            pointerEvents: "none",
                                        }}
                                        className='expand-more'
                                    />
                                </div>
                                {this.state.allAdministrationYears.length > 1 && (<div className='wrapper-select-new' style={{ marginBottom: 15 }}>
                                    <select value={this.state.selectedYearAdministratie} onChange={(e) => {
                                        this.setState({ selectedYearAdministratie: e.target.value })

                                        if (e.target.value === "") {
                                            if (this.state.selectedDateAdministratie) {
                                                let filtered = this.state.allAdministrationDataFilter.filter((t) => {
                                                    if (t.added_at) {
                                                        return new Date(Number(t.added_at)).getMonth() === Number(this.state.selectedDateAdministratie)
                                                    }
                                                })
                                                if (this.state.selectedCompanyAdministratie) {
                                                    filtered = filtered.filter((t) => {
                                                        return t.barber_id === this.state.selectedCompanyAdministratie
                                                    })
                                                }
                                                if (filtered.length > 0) {
                                                    this.setState({ allAdministrationData: filtered })
                                                } else {
                                                    this.setState({ allAdministrationData: [] })
                                                }
                                                return false;
                                            }
                                            let dataToAdd = this.state.allAdministrationDataFilter
                                            if (this.state.selectedCompanyAdministratie) {
                                                dataToAdd = dataToAdd.filter((t) => {
                                                    return t.barber_id === this.state.selectedCompanyAdministratie
                                                })
                                            }
                                            this.setState({ allAdministrationData: dataToAdd })
                                            return false;
                                        }

                                        let filtered = this.state.allAdministrationDataFilter.filter((t) => {
                                            if (t.added_at) {
                                                if (this.state.selectedDateAdministratie) {
                                                    return new Date(Number(t.added_at)).getFullYear() === Number(e.target.value) && new Date(Number(t.added_at)).getMonth() === Number(this.state.selectedDateAdministratie)
                                                } else {
                                                    return new Date(Number(t.added_at)).getFullYear() === Number(e.target.value)
                                                }
                                            }
                                        })
                                        if (this.state.selectedCompanyAdministratie) {
                                            filtered = filtered.filter((t) => {
                                                return t.barber_id === this.state.selectedCompanyAdministratie
                                            })
                                        }
                                        if (filtered.length > 0) {
                                            this.setState({ allAdministrationData: filtered })
                                        } else {
                                            this.setState({ allAdministrationData: [] })
                                        }
                                    }}>
                                        <option value="">Alle year</option>
                                        {this.state.allAdministrationYears.map((t) => {
                                            return (<option value={t}>{t}</option>)
                                        })}
                                    </select>
                                    <ExpandMore
                                        style={{
                                            position: "absolute",
                                            bottom: 5,
                                            right: 5,
                                            pointerEvents: "none",
                                        }}
                                        className='expand-more'
                                    />
                                </div>)}
                                {this.state.allAdministrationCompanies.length > 0 && (<div className='wrapper-select-new' style={{ marginBottom: 15 }}>
                                    <select value={this.state.selectedCompanyAdministratie} onChange={(e) => {
                                        this.setState({ selectedCompanyAdministratie: e.target.value })

                                        if (e.target.value === "") {
                                            this.setState({ allAdministrationData: this.state.allAdministrationDataFilter, selectedDateAdministratie: "", selectedYearAdministratie: "" })
                                            return false;
                                        }

                                        let filtered = this.state.allAdministrationDataFilter.filter((t) => {
                                            if (t.barber_id) {
                                                return t.barber_id === e.target.value
                                            }
                                        })
                                        if (filtered.length > 0) {
                                            this.setState({ allAdministrationData: filtered, selectedDateAdministratie: "", selectedYearAdministratie: "" })
                                        } else {
                                            this.setState({ allAdministrationData: [], selectedDateAdministratie: "", selectedYearAdministratie: "" })
                                        }
                                    }}>
                                        <option value="">Alle Companies</option>
                                        {this.state.allAdministrationCompanies.map((t) => {
                                            return (<option value={t}>{this.getUserName(t)}</option>)
                                        })}
                                    </select>
                                    <ExpandMore
                                        style={{
                                            position: "absolute",
                                            bottom: 5,
                                            right: 5,
                                            pointerEvents: "none",
                                        }}
                                        className='expand-more'
                                    />
                                </div>)}
                                <div className="administration-data-wsn">
                                    {this.getAdministrationData()}
                                    {this.state.allAdministrationData.length > 0 && (<div className='container-hadwsn'>
                                        <h3 className='heading-adwsn'>Users</h3>
                                    </div>)}
                                    {this.state.allAdministrationData.map((e) => {
                                        return (<div className='qr-list-main'>
                                            <img src={this.getImageUrl(e.joined_user_id)} alt="profile-user" />
                                            <p>{this.getUserNameList(e.joined_user_id)} ({this.getUserName(e.barber_id)}) </p>
                                        </div>)
                                    })}
                                </div>
                            </div>
                        </div>)}
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducers.user,
    };
};
export default connect(mapStateToProps)(AdminBarberAdministration)