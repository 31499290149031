const rerenderComponent = (reRender) => {
  return {
    type: "re_render_component",
    reRender,
  };
};

const updateTitle = (title) => {
  return {
    type: "update_nav_title",
    title,
  };
};

const updateUser = (user) => {
  return {
    type: "get_all_users",
    user,
  };
};

const updateAlbums = (album) => {
  return {
    type: "get_all_albums",
    album,
  };
};

const updateImages = (images) => {
  return {
    type: "get_all_images",
    images,
  };
};

const updateMessage = (message) => {
  return {
    type: "get_all_messages",
    message,
  };
};

const updateProducts = (product) => {
  return {
    type: "get_all_products",
    product,
  };
};

const updateVouchers = (vouchers) => {
  return {
    type: "get_all_vouchers",
    vouchers,
  };
};

const updateGlobalVouchers = (globalvouchers) => {
  return {
    type: "get_all_global_vouchers",
    globalvouchers,
  };
};

const updateGlobalShop = (globalshopproducts) => {
  return {
    type: "get_all_global_products",
    globalshopproducts,
  };
};

const updateallShopProducts = (shopproducts) => {
  return {
    type: "all_shop_products",
    shopproducts,
  };
};

const updateallVouchers = (allVouchers) => {
  return {
    type: "all_vouchers",
    allVouchers,
  };
};

const voucherSectorCategories = (vouchersectors) => {
  return {
    type: "all_vouchers_sc",
    vouchersectors,
  };
};

const availablemaincategoriesvouchers = (maincategoriesvoucher) => {
  return {
    type: "update_main_category",
    maincategoriesvoucher,
  };
};

const availablesubcategoriesvouchers = (subcategoriesvoucher) => {
  return {
    type: "update_sub_category",
    subcategoriesvoucher,
  };
};

const shopSectorCategories = (shopsectors) => {
  return {
    type: "all_shop_sc",
    shopsectors,
  };
};

const availablemaincategoriesshop = (maincategoriesshop) => {
  return {
    type: "update_main_category_shop",
    maincategoriesshop,
  };
};

const availablesubcategoriesshop = (subcategoriesshop) => {
  return {
    type: "update_sub_category_shop",
    subcategoriesshop,
  };
};

const updateBookmarkStuff = (bookmarked_things) => {
  return {
    type: "update_bookmarked_things",
    bookmarked_things,
  };
};

const updateCurrentMoments = (currentMoments) => {
  return {
    type: "update_current_moments",
    currentMoments,
  };
};

const updateCurrentMomentsFriendsPage = (currentMomentsFriends) => {
  return {
    type: "update_current_moments_friends",
    currentMomentsFriends,
  };
};

const updateAllSectors = (globalsectors) => {
  return {
    type: "update_all_sectors",
    globalsectors,
  };
};

const updateCampaignsCategory = (campaigns_category) => {
  return {
    type: "update_campaigns_category",
    campaigns_category,
  };
};

const updateCategoriesVouchers = (categories_vouchers) => {
  return {
    type: "update_categories_vouchers",
    categories_vouchers,
  };
};

const updateSubscriptionPlans = (subscription_plans) => {
  return {
    type: "update_subscription_plans",
    subscription_plans,
  };
};

const updateGlobalStuff = (global_values) => {
  return {
    type: "update_global_stuff",
    global_values,
  };
};

const updateAllCampaigns = (global_campaigns) => {
  return {
    type: "update_all_campaigns",
    global_campaigns,
  };
};

const updateImpressies = (all_impressies) => {
  return {
    type: "update_impressies",
    all_impressies,
  };
};

const updateAlbumToAdd = (albumToAdd) => {
  console.log(albumToAdd, "/this to update");
  return {
    type: "update_album_to_add",
    albumToAdd,
  };
};

const updateMoreAlbumsAdded = (morealbumsToAdd) => {
  return {
    type: "update_more_albums_added",
    morealbumsToAdd,
  };
};

const updateAlbumToAddFriends = (albumToAddFriends) => {
  return {
    type: "update_album_to_add_friends",
    albumToAddFriends,
  };
};

const updateMoreAlbumsAddedFriends = (morealbumsToAddFriends) => {
  return {
    type: "update_more_albums_added_friends",
    morealbumsToAddFriends,
  };
};

const updateBewaaredCampaigns = (bewaared_campaigns) => {
  return {
    type: "update_bewaared_campaigns",
    bewaared_campaigns,
  };
};

const updateAlbumCategories = (album_categories) => {
  return {
    type: "update_album_categories",
    album_categories,
  };
};

const updateCurrentExclusives = (current_exclusives) => {
  return {
    type: "update_current_exclusives",
    current_exclusives,
  };
};

const updateAllExclusives = (all_exclusives) => {
  return {
    type: "update_all_exclusives",
    all_exclusives,
  };
};

const updatefriendsExclusives = (friends_exclusives) => {
  return {
    type: "update_friends_exclusives",
    friends_exclusives,
  };
};

const updateSelectedExclusive = (selected_exclusive) => {
  return {
    type: "update_selected_exclusive",
    selected_exclusive,
  };
};

const updateSelectedExclusiveCategory = (selected_exclusive_category) => {
  return {
    type: "update_selected_exclusive_category",
    selected_exclusive_category,
  };
};

const updateSelectedExclusiveFriends = (selected_exclusive_friends) => {
  return {
    type: "update_selected_exclusive_friends",
    selected_exclusive_friends,
  };
};

const updateSelectedExclusiveCategoryFriends = (
  selected_exclusive_category_friends
) => {
  return {
    type: "update_selected_exclusive_category_friends",
    selected_exclusive_category_friends,
  };
};

const updateDraggedFiles = (selected_dragged_files) => {
  return {
    type: "update_dragged_files",
    selected_dragged_files,
  };
};

const updateDraggedFilesName = (selected_dragged_files_name) => {
  return {
    type: "update_dragged_files_name",
    selected_dragged_files_name,
  };
};

const updateCategoryAlbums = (categories_albums) => {
  return {
    type: "update_category_albums",
    categories_albums,
  };
};

const updateAvailableCategoriesAlbums = (categories_albums_available) => {
  return {
    type: "update_category_albums_available",
    categories_albums_available,
  };
};

const updateAvailablePosts = (available_posts) => {
  return {
    type: "update_available_posts",
    available_posts,
  };
};

const updateGuestUserCart = (guest_user_cart) => {
  return {
    type: "update_guest_user_cart",
    guest_user_cart,
  };
};

const updateGuestUserCartTime = (guest_user_cart_time) => {
  return {
    type: "update_guest_user_cart_time",
    guest_user_cart_time,
  };
};

const updateGuestUserCartExtendOpportunity = (
  guest_user_cart_extend_opportunity
) => {
  return {
    type: "update_guest_user_cart_extend_opportunity",
    guest_user_cart_extend_opportunity,
  };
};

const updateSignUpFieldsFilled = (signup_fields_info) => {
  return {
    type: "update_signup_fields_info",
    signup_fields_info,
  };
};

const updateguestDBCart = (guest_db_cart) => {
  return {
    type: "update_guest_db_cart",
    guest_db_cart,
  };
};

const updateAdminOrNot = (admin_boolean) => {
  return {
    type: "update_admin_or_not",
    admin_boolean,
  };
};

const updateAllCharityVideos = (charity_videos) => {
  return {
    type: "update_charity_videos",
    charity_videos,
  };
};

const updateBhodiSounds = (bhodi_sounds) => {
  return {
    type: "update_bhodi_sounds",
    bhodi_sounds,
  };
};

const updateAllProductsNew = (products_new) => {
  return {
    type: "update_all_products_new",
    products_new,
  };
};

const updateMyCart = (global_cart) => {
  return {
    type: "update_my_cart",
    global_cart,
  };
};

const updateStockImages = (stock_images) => {
  return {
    type: "update_stock_images",
    stock_images,
  };
};

const updateBhodiProducts = (bhodi_products) => {
  return {
    type: "update_bhodi_products",
    bhodi_products,
  };
};

const productSectorCategories = (product_categories) => {
  return {
    type: "update_product_sector_categories",
    product_categories,
  };
};


const updateBhodiAbouts = (bhodi_abouts) => {
  return {
    type: "update_bhodi_abouts",
    bhodi_abouts,
  };
};

const updateBhodiFaqs = (bhodi_faqs) => {
  return {
    type: "update_bhodi_faqs",
    bhodi_faqs,
  };
};

const updateBhodiFaqsGuests = (bhodi_faqs_guests) => {
  return {
    type: "update_bhodi_faqs_guests",
    bhodi_faqs_guests,
  };
};

const updateBhodiBrands = (bhodi_brands) => {
  return {
    type: "update_bhodi_brands",
    bhodi_brands,
  };
};

export {
  updateUser,
  updateAlbums,
  updateImages,
  updateMessage,
  updateProducts,
  updateVouchers,
  updateGlobalVouchers,
  updateGlobalShop,
  updateallShopProducts,
  updateallVouchers,
  voucherSectorCategories,
  availablemaincategoriesvouchers,
  availablesubcategoriesvouchers,
  shopSectorCategories,
  availablemaincategoriesshop,
  availablesubcategoriesshop,
  updateBookmarkStuff,
  updateCurrentMoments,
  updateCurrentMomentsFriendsPage,
  updateAllSectors,
  updateCampaignsCategory,
  updateCategoriesVouchers,
  updateSubscriptionPlans,
  updateGlobalStuff,
  updateAllCampaigns,
  updateImpressies,
  updateAlbumToAdd,
  updateMoreAlbumsAdded,
  updateBewaaredCampaigns,
  updateAlbumCategories,
  updateCurrentExclusives,
  updatefriendsExclusives,
  updateSelectedExclusive,
  updateSelectedExclusiveCategory,
  updateDraggedFiles,
  updateDraggedFilesName,
  updateCategoryAlbums,
  updateAvailableCategoriesAlbums,
  updateAvailablePosts,
  updateMoreAlbumsAddedFriends,
  updateAlbumToAddFriends,
  updateSelectedExclusiveCategoryFriends,
  updateSelectedExclusiveFriends,
  updateAllExclusives,
  updateGuestUserCart,
  updateGuestUserCartTime,
  updateGuestUserCartExtendOpportunity,
  updateSignUpFieldsFilled,
  updateguestDBCart,
  updateAdminOrNot,
  updateAllCharityVideos,
  updateBhodiSounds,
  updateAllProductsNew,
  updateMyCart,
  updateStockImages,
  rerenderComponent,
  updateBhodiProducts,
  updateTitle,
  productSectorCategories,
  updateBhodiAbouts,
  updateBhodiFaqs,
  updateBhodiFaqsGuests,
  updateBhodiBrands,
};
