import React, { Component } from "react";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import SortableTree from "react-sortable-tree";
import "react-sortable-tree/style.css";
import CloseIcon from "@material-ui/icons/Close";
import PublishIcon from "@material-ui/icons/Publish";
import firebase from "../Config/Firebase";
import CK from "./Ck";
import AdminNavbar from "./AdminNavbar";

export class SubscriptionPlansEdit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            allSubscriptionPlans: []
        };
    }

    componentDidMount() {
        const loaderId = document.getElementById("main-page-loader-id");
        loaderId.style.display = "none";

        firebase
            .database()
            .ref("subscription_plans")
            .on("value", (data) => {
                let a = data.val()
                let arr = []
                if (a) {
                    let objected = Object.entries(a)
                    objected.map((t) => {
                        arr.push(t[1])
                    })
                }
                this.setState({ allSubscriptionPlans: arr })
            });
    }

    saveSubscriptionPlans() {
        this.state.allSubscriptionPlans.map((t) => {
            firebase.database().ref(`subscription_plans/${t.pushKey}`).set(t)
        })
    }

    render() {
        return (
            <div className="sorting-mainadmin">
                <AdminNavbar plansPage={true} />
                <div className="container-sma" style={{ maxWidth: 1020 }}>
                    <div className="top-container-sma">
                        <div className="sector-tcsma"></div>
                        <div className="category-tcsma">
                            <p onClick={this.saveSubscriptionPlans.bind(this)}>SAVE</p>
                        </div>
                    </div>
                    <div
                        className="tree-inner"
                        style={{ marginTop: 30, paddingBottom: 70 }}
                    >
                        {this.state.allSubscriptionPlans.length > 0 && this.state.allSubscriptionPlans.map((e) => {
                            return (<div className="container-adjust-splan">
                                <p>{e.description}</p>
                                <input type="number" placeholder="Split Amount" value={e.split_amount} onChange={(g) => {
                                    e.split_amount = g.target.value
                                    this.setState({ allSubscriptionPlans: this.state.allSubscriptionPlans })
                                }} />
                            </div>)
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

export default SubscriptionPlansEdit;
