import React, { Component } from "react";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "./index.css";
import firebase from "../Config/Firebase";
import CloseIcon from "@material-ui/icons/Close";
import { connect } from "react-redux";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { updateUser } from "../Redux/actions/authActions";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import MailIcon from "@material-ui/icons/Mail";
import SearchIcon from "@material-ui/icons/Search";
import TimerIcon from "@material-ui/icons/Timer";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { BsCheck, BsFillBagCheckFill } from "react-icons/bs";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import Footer from "../Components/Footer";
import { isDesktop } from "react-device-detect";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { MdArrowForwardIos, MdFormatListNumbered } from "react-icons/md";
import Clipboard from "react-clipboard.js";
import BackTimer from "../Components/BackTimer";
import Box from "@material-ui/core/Box";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CommentsTopUser from "./CommentsTopUser";

export class RightMenuOrders extends Component {
  constructor() {
    super();
    this.state = {
      modal: false,
      wholeData: "",
      arrPopup: [],
      isAdmin: false,
      heading: "",
      headingInput: "",
      arrPopup: [2, 21],
      incoming: false,
      outgoing: true,
      campaigns: false,
      campaignsOutgoing: false,
      arrOrders: [],
      arrPopup: [],
      outgoingProducts: [],
      ordersPresent: true,
      currentUserHere: "",
      vouchersOutgoing: false,
      vouchersIncoming: false,
      currentVoucherWork: "",
      showVoucherNow: false,
      currentUser: "",
      showFooter: true,
      currentUserOfPopups: "",
      redeem_code: "",
      ordersFiltered: [],
      searchText: "",
      ordersFilteredDuplicate: [],
      counterMinute: 1,
      counterSecond: 59,
      verzilverPopupMobileGiveaway: false,
      verzilverPopupMobileKortingsVoucher: false,
      verzilverPopupMobileAnother: false,
      isOnLastMinuteOpened: false,
      noFiltersHere: false,
      verzilverPopupEventNoValidate: false,
      currentActie: "",
      currentOrderSelected: "",
      currentFiltered: "",
    };
  }

  componentDidMount() {
    const loaderId = document.getElementById("main-page-loader-id");
    loaderId.style.display = "none";
    // Products
    let uid = localStorage.getItem("userid");
    firebase
      .database()
      .ref("users")
      .on("value", (data) => {
        let a = data.val();
        let arrUsers = [];
        if (a) {
          let entries = Object.entries(a);
          entries.map((f) => {
            if (f[1].user_id === uid) {
              this.setState({ currentUserHere: f[1] });
            }
          });
          entries.map((f) => {
            if (f && f.length > 0 && f[1]) {
              f[1].pass = "";
              f[1].password = "";
              arrUsers.push(f[1]);
            }
          });
          this.props.updateUser(arrUsers);
        }
      });
    this.setState({
      incoming: false,
      outgoing: true,
      campaigns: false,
      campaignsOutgoing: false,
      vouchersOutgoing: false,
      vouchersIncoming: false,
    });
    let fetched = new Promise((resolve, reject) => {
      firebase
        .database()
        .ref("all_orders")
        .on("value", (data) => {
          let arrCompleteOrders = [];
          let a = data.val();
          if (a) {
            let objected = Object.entries(a);
            let arrOrders = [];
            objected.map((g) => {
              arrCompleteOrders.push(g[1]);
              if (g[1].sellers.includes(uid)) {
                if (g[1].order_type === "product") {
                  let currentProducts = g[1].products;
                  let filteredSec = [];
                  currentProducts.map((h, i) => {
                    if (h.created_by === uid) {
                      filteredSec.push(h);
                    } else {
                      g[1].productCompleted.splice(i, 1);
                      g[1].products.splice(i, 1);
                    }
                  });
                  g[1].productsToRender = filteredSec;
                  arrOrders.push(g[1]);
                } else {
                  if (g[1].order_type === "campaign") {
                    arrOrders.push(g[1]);
                  } else if (g[1].order_type === "multi_products") {
                    let currentProducts = g[1].products;
                    let filteredSec = [];
                    currentProducts.map((h, i) => {
                      if (h.seller === uid) {
                        filteredSec.push(h);
                      }
                    });
                    g[1].productsToRender = filteredSec;
                    arrOrders.push(g[1]);
                  } else if (g[1].order_type === "bhodi_products") {
                    arrOrders.push(g[1]);
                  }
                }
              }
            });
            let arrPopup = [];
            arrOrders.map(() => {
              let obj = {
                popup: false,
              };
              arrPopup.push(obj);
            });
            arrOrders.reverse();

            if (this.state.currentOrderSelected) {
              let currentOrder = arrOrders.find((g) => {
                return g.pushKey === this.state.currentOrderSelected.pushKey
              })
              if (currentOrder) {
                this.setState({ currentOrderSelected: currentOrder })
              }
            }

            resolve(arrOrders);
            this.setState({
              arrOrders,
              arrPopup,
              outgoingProducts: arrOrders,
              arrCompleteOrders,
              ordersFiltered: [],
              noFiltersHere: false,
              ordersFilteredDuplicate: [],
              currentFiltered: "",
            });
          } else {
            resolve([]);
            this.setState({
              arrOrders: [],
              arrPopup: [],
              arrCompleteOrders: [],
            });
          }
        });
    });
    fetched.then((e) => {
      if (e.length > 0) {
        this.setState({ ordersPresent: true });
      } else {
        this.setState({ ordersPresent: false });
      }
    });
  }

  getCompletePriceRegards(i) {
    let arrItems = this.state.arrOrders[i].products;
    let arrItemsCompleted = this.state.arrOrders[i].productCompleted;
    if (arrItems.length > 0) {
      Array.prototype.sum = function (prop) {
        var total = 0;
        for (var i = 0, _len = this.length; i < _len; i++) {
          total += Number(this[i][prop]) * arrItemsCompleted[i].cartQty;
        }
        return total;
      };
      return arrItems.sum("charity_price");
    }
  }

  getSumOfProductsLength(i) {
    let arrItems = this.state.arrOrders[i].productCompleted;
    if (arrItems.length > 0) {
      Array.prototype.sum = function (prop) {
        var total = 0;
        for (var i = 0, _len = this.length; i < _len; i++) {
          total += Number(this[i][prop]);
        }
        return total;
      };
      return arrItems.sum("cartQty");
    }
    // return 1
  }

  getBuyerName(f) {
    let arrItems = this.state.arrOrders[f];
    let filtered = this.props.user.filter((g) => {
      return g.user_id === arrItems.buyer;
    });
    if (filtered.length > 0) {
      if (filtered[0].type === "user") {
        return `${filtered[0].first_name} ${filtered[0].last_name}`;
      } else {
        return `${filtered[0].company_name}`;
      }
    }
  }

  getBuyerAddess(f) {
    let arrItems = this.state.arrOrders[f];
    let filtered = this.props.user.filter((g) => {
      return g.user_id === arrItems.buyer;
    });
    if (filtered.length > 0) {
      return `${filtered[0].street || ""} ${filtered[0].houseNumber || ""}`;
    }
  }

  getBuyerAddessSecond(f) {
    let arrItems = this.state.arrOrders[f];
    let filtered = this.props.user.filter((g) => {
      return g.user_id === arrItems.buyer;
    });
    if (filtered.length > 0) {
      return `${filtered[0].zipCode || ""} ${filtered[0].city || ""}`;
    }
  }

  getBuyerAddessThird(f) {
    let arrItems = this.state.arrOrders[f];
    let filtered = this.props.user.filter((g) => {
      return g.user_id === arrItems.buyer;
    });
    if (filtered.length > 0) {
      return `${filtered[0].email}`;
    }
  }

  getBuyerAddessFourth(f) {
    let arrItems = this.state.arrOrders[f];
    let filtered = this.props.user.filter((g) => {
      return g.user_id === arrItems.buyer;
    });
    if (filtered.length > 0) {
      return `${filtered[0].phoneNumber}`;
    }
  }

  getSellerName(e) {
    let filtered = this.props.user.filter((g) => {
      return g.user_id === e;
    });
    if (filtered.length > 0) {
      if (filtered[0].type === "user") {
        return `${filtered[0].first_name} ${filtered[0].last_name}`;
      } else {
        return `${filtered[0].company_name}`;
      }
    }
  }

  getSellerContact(e) {
    let filtered = this.props.user.filter((g) => {
      return g.user_id === e;
    });
    return `${filtered[0].email}`;
  }

  getSellerNameCampaign(e) {
    let filtered = this.props.user.filter((g) => {
      return g.user_id === e.created_by;
    });
    if (filtered.length > 0) {
      if (filtered[0].type === "user") {
        return `${filtered[0].first_name} ${filtered[0].last_name}`;
      } else {
        return `${filtered[0].company_name}, ${filtered[0].houseNumber} ${filtered[0].street}, ${filtered[0].zipCode} ${filtered[0].city}`;
      }
    }
  }

  getSellerNameCampaignOnly(e, f) {
    let filtered = this.props.user.filter((g) => {
      if (e.created_by) {
        return g.user_id === e.created_by;
      } else {
        return g.user_id === f;
      }
    });
    if (filtered.length > 0) {
      if (filtered[0].type === "user") {
        return `${filtered[0].first_name} ${filtered[0].last_name}`;
      } else {
        return `${filtered[0].company_name}`;
      }
    }
  }

  getSellerContactCampaign(e) {
    let filtered = this.props.user.filter((g) => {
      return g.user_id === e.created_by;
    });
    if (filtered.length > 0) {
      return `${filtered[0].email}`;
    }
  }

  getBuyerNameOutgoing(f) {
    let filtered = this.props.user.filter((g) => {
      return g.user_id === f;
    });
    if (filtered.length > 0) {
      if (filtered[0].type === "user") {
        return `${filtered[0].first_name} ${filtered[0].last_name}`;
      } else {
        return `${filtered[0].company_name}`;
      }
    }
  }

  getAddressOutgoing(f) {
    let filtered = this.props.user.filter((g) => {
      return g.user_id === f;
    });
    if (filtered.length > 0) {
      return `${filtered[0].street || ""} ${filtered[0].houseNumber || ""}`;
    }
  }

  getAddressOutgoingSecond(f) {
    let filtered = this.props.user.filter((g) => {
      return g.user_id === f;
    });
    if (filtered.length > 0) {
      return `${filtered[0].zipCode || ""} ${filtered[0].city || ""}`;
    }
  }

  getAddressOutgoingEmail(f) {
    let filtered = this.props.user.filter((g) => {
      return g.user_id === f;
    });
    if (filtered.length > 0) {
      return `${filtered[0].email}`;
    }
  }

  getAddressOutgoingPhone(f) {
    let filtered = this.props.user.filter((g) => {
      return g.user_id === f;
    });
    if (filtered.length > 0) {
      return `${filtered[0].phoneNumber}`;
    }
  }

  // checkForExpireText(e) {
  //     if (e.saving_it) {
  //         let splitted = e.created_on.split("/")
  //         let newDate = new Date(Number(splitted[2]), Number(splitted[0]), Number(splitted[1]))
  //         newDate.setDate(newDate.getDate() + e.products[0].expireAfter)
  //         if (new Date().getMonth() + 1 === newDate.getMonth()) {
  //             if (newDate.getDate() < new Date().getDate()) {
  //                 return <div className="bottom-row">
  //                     <div className="order-detail-status">
  //                         <span>Geldigheidsdatum is verlopen</span>
  //                     </div>
  //                 </div>
  //             } else {
  //                 return <div className="bottom-row">
  //                     <div className="order-detail-status">
  //                         <span>Voucher geldig t/m {newDate.getDate()}-{newDate.getMonth()}-{newDate.getFullYear()}</span>
  //                     </div>
  //                 </div>
  //             }
  //         } else {
  //             return <div className="bottom-row">
  //                 <div className="order-detail-status">
  //                     <span>Voucher geldig t/m {newDate.getDate()}-{newDate.getMonth()}-{newDate.getFullYear()}</span>
  //                 </div>
  //             </div>
  //         }
  //     }
  // }

  getMonthName(i) {
    i = Number(i);
    if (i === 1) {
      return "Januari";
    } else if (i === 2) {
      return "Februari";
    } else if (i === 3) {
      return "Maart";
    } else if (i === 4) {
      return "April";
    } else if (i === 5) {
      return "Mei";
    } else if (i === 6) {
      return "Juni";
    } else if (i === 7) {
      return "Juli";
    } else if (i === 8) {
      return "Augustus";
    } else if (i === 9) {
      return "September";
    } else if (i === 10) {
      return "Oktober";
    } else if (i === 11) {
      return "November";
    } else if (i === 12) {
      return "December";
    }
  }

  getPerfectDate(e) {
    if (e) {
      let splitted = e.split("/");
      let joint = `${splitted[1]} ${this.getMonthName(splitted[0])} ${splitted[2]
        }`;
      return joint;
    }
  }

  getDayName(num) {
    if (num === 0) {
      return `Zondag`;
    } else if (num === 1) {
      return `Maandag`;
    } else if (num === 2) {
      return `Dinsdag`;
    } else if (num === 3) {
      return `Woensdag`;
    } else if (num === 4) {
      return `Donderdag`;
    } else if (num === 5) {
      return `Vrijdag`;
    } else if (num === 6) {
      return `Zaterdag`;
    }
  }

  getMonthName(i) {
    i = Number(i);
    if (i === 1) {
      return "Januari";
    } else if (i === 2) {
      return "Februari";
    } else if (i === 3) {
      return "Maart";
    } else if (i === 4) {
      return "April";
    } else if (i === 5) {
      return "Mei";
    } else if (i === 6) {
      return "Juni";
    } else if (i === 7) {
      return "Juli";
    } else if (i === 8) {
      return "Augustus";
    } else if (i === 9) {
      return "September";
    } else if (i === 10) {
      return "Oktober";
    } else if (i === 11) {
      return "November";
    } else if (i === 12) {
      return "December";
    }
  }

  showDateToUserTiming(product) {
    let filtered = product.timings.filter((u) => {
      return u.eventId === product.reserved_event_id;
    });

    if (filtered.length > 0) {
      let e = filtered[0].startDateEvent;
      let f = filtered[0].endDateEvent;
      if (e === f) {
        let splitted = e.split("-");
        let newDate = new Date(e).getDay();
        return `${this.getDayName(newDate)} ${splitted[2]} ${this.getMonthName(
          splitted[1]
        )}`;
      } else {
        let splitted = e.split("-");
        let splittedSecond = f.split("-");
        let newDate = new Date(e).getDay();
        let newDateAnother = new Date(f).getDay();
        if (splitted[1] === splittedSecond[1]) {
          return `${splitted[2]} - ${splittedSecond[2]} ${this.getMonthName(
            splittedSecond[1]
          )}`;
        } else {
          // return `${this.getDayName(newDate)} ${splitted[2]} ${this.getMonthName(splitted[1])} - ${this.getDayName(newDateAnother)} ${splittedSecond[2]} ${this.getMonthName(splittedSecond[1])}`
          return `${splitted[2]} ${this.getMonthName(splitted[1])} t/m ${splittedSecond[2]
            } ${this.getMonthName(splittedSecond[1])}`;
        }
      }
    }
  }

  copyToClipboard(e) {
    this.setState({ copiedTextDone: true });
    var textField = document.createElement("textarea");
    textField.innerText = e;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    // setTimeout(() => {
    //     this.setState({ copiedTextDone: false })
    // }, 3000);
  }

  getUserGlobalDesc(e) {
    if (this.props.user) {
      let filtered = this.props.user.filter((t) => {
        return t.user_id === e.created_by
      })
      if (filtered.length > 0) {
        if (filtered[0].productsInfo) {
          if (filtered[0].productsInfo.globalDesc) {
            return <font>{filtered[0].productsInfo.globalDesc.substr(0, 40)}...</font>
          }
        }
      }
    }
  }

  getSKUOfCurrentOrder(order) {
    if (order.selectedSize && order.products[0].variations_data) {
      let filtered = order.products[0].variations_data.filter((t) => {
        return t.size === order.selectedSize
      })
      if (filtered.length > 0) {
        return filtered[0].sku
      }
    } else {
      return order.products[0].sku
    }
  }

  createDescMarkup(e) {
    if (e) {
      return { __html: e };
    }
  }

  checkForRedemedButton(order) {
    if (order.is_used) {
      return <div className="button-main-orderdetailednew">
        <button
          className="button-valideercode"
          style={{
            backgroundColor: "#C17F6B",
            color: "white",
            fontSize: 13,
            letterSpacing: 1,
            borderRadius: 4,
            border: "none",
            padding: "8px 12px",
          }}
        >
          Gebruikt
        </button>
      </div>
    }
    return <div className="button-main-orderdetailednew">
      <button
        className="button-valideercode"
        style={{
          backgroundColor: "#285151",
          color: "white",
          fontSize: 13,
          letterSpacing: 1,
          borderRadius: 4,
          border: "none",
          padding: "8px 12px",
        }}
        onClick={() => {
          firebase.database().ref("all_orders/" + order.pushKey + "/is_used").set(true)
        }}
      >
        Valideer code <ChevronRightIcon />
      </button>
    </div>
  }

  render() {
    return (
      <div>
        {this.state.currentOrderSelected ? (
          <div
            className="rightmenuaddproduct rightmenucommentsaddproduct rightmenuproductfriendlists rightmenucompanyvouchersmain rightmenuorderselectedopened orders-detailed-new"
            style={{ overflow: "hidden" }}
          >
            <div
              className="mid-rmap"
              style={{
                position: "relative",
                padding: 13,
                alignItems: "flex-start",
              }}
            >
              <ChevronLeftIcon
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  top: "50%",
                  transform: "translateY(-50%)",
                  left: 10,
                  fontSize: 18,
                }}
                onClick={() => {
                  this.setState({ currentOrderSelected: "" });
                }}
              />
              <span>&nbsp;</span>
              <CloseIcon
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  top: "50%",
                  transform: "translateY(-50%)",
                  right: 20,
                  fontSize: 18,
                }}
                onClick={() => {
                  this.props.closePopupComplete();
                }}
              />
            </div>
            {this.state.currentOrderSelected.order_type === "bhodi_products" ? (
              <div
                className="mid-img-uploading-rmap commentsmidimgurmap"
                style={{ alignItems: "flex-start" }}
              >
                <div
                  style={{
                    width: "100%",
                    marginTop: 15,
                    paddingBottom: window.innerWidth < 1008 && 120,
                  }}
                >
                  <div className="top-order-date-rmo">
                    <p>
                      Order: {this.state.currentOrderSelected.order_id}
                    </p>
                  </div>
                  <div className="container-detailed-orderpage">
                    <div className="bottom-order-details-rmo">
                      <img
                        src={this.state.currentOrderSelected.products[0].images[this.state.onIndexImage]}
                        alt="order"
                      />
                      <div className="order-info-ocrm">
                        <p className="title-oiocrm">
                          {this.state.currentOrderSelected.products[0].title && (
                            <font>
                              {this.state.currentOrderSelected.products[0].title.charAt(0).toUpperCase() + this.state.currentOrderSelected.products[0].title.slice(1)}
                            </font>
                          )}
                        </p>
                        <div
                          className="order-totals-block"
                          style={{
                            alignItems: "flex-start",
                            minHeight: "auto",
                          }}
                        >
                          <div
                            className="order-total-price-row summary-row"
                            style={{
                              borderBottom: 0,
                              maxWidth: 960,
                              fontFamily: "Roboto",
                              marginBottom: 0,
                              paddingBottom: 0,
                            }}
                          >
                            <p
                              className="normalprice-cpgm"
                              style={{ marginBottom: 0 }}
                            >
                              <s>
                                € {this.state.currentOrderSelected.products[0].price}
                              </s>{" "}
                              FREE
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bottom-order-desc-rmo">
                      <div
                        className="order-totals-block"
                        style={{
                          alignItems: "flex-start",
                          minHeight: "auto",
                        }}
                      >
                        <div
                          className="order-total-price-row summary-row"
                          style={{
                            borderBottom: 0,
                            maxWidth: 960,
                            fontFamily: "Roboto",
                            flexDirection: "column"
                          }}
                        >
                          <p className="desc-lds">
                            {this.state.currentOrderSelected.products[0].desc ? this.state.currentOrderSelected.products[0].desc : this.getUserGlobalDesc(this.state.currentOrderSelected.products[0])}
                            {this.state.currentOrderSelected.products[0].paypalOrderCaptureId && <br />}
                            {this.state.currentOrderSelected.products[0].paypalOrderCaptureId && <br />}
                            {this.state.currentOrderSelected.products[0].paypalOrderCaptureId ? `Paypal Payment ID: ${this.state.currentOrderSelected.products[0].paypalOrderCaptureId}` : ""}
                          </p>
                          <p className="desc-lds">
                            Artikelnummer: {this.getSKUOfCurrentOrder(this.state.currentOrderSelected)}
                          </p>
                          {this.state.currentOrderSelected.selectedSize && (<p className="desc-lds">
                            Maat: {this.state.currentOrderSelected.selectedSize}
                          </p>)}
                          {this.state.currentOrderSelected.shipmentCost && (<p className="desc-lds">
                            Verzending: {this.state.currentOrderSelected.shipmentCost === "Free" ? "Free" : `€ ${this.state.currentOrderSelected.shipmentCost}`}
                          </p>)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bottom-order-companyname-rmo">
                    <CommentsTopUser
                      toShowUserId={this.state.currentOrderSelected.buyer}
                      hideBottomIcons={true}
                    // showBericht={true}
                    />
                    <p className="addres-otb" style={{ marginTop: 15 }}>
                      <font style={{ fontWeight: 500 }}>Afleveradres:</font> <br />
                      {this.getBuyerNameOutgoing(
                        this.state.currentOrderSelected.buyer
                      )}
                    </p>
                    <p className="addres-otb">
                      {this.getAddressOutgoing(
                        this.state.currentOrderSelected.buyer
                      )}
                    </p>
                    <p className="addres-otb">
                      {this.getAddressOutgoingSecond(
                        this.state.currentOrderSelected.buyer
                      )}
                    </p>
                    <p className="addres-otb telephone-otb">
                      <a href={`tel:${this.getAddressOutgoingPhone(this.state.currentOrderSelected.buyer)}`}>
                        T:{" "}
                        {this.getAddressOutgoingPhone(
                          this.state.currentOrderSelected.buyer
                        )}
                      </a>
                    </p>
                    <p className="addres-otb">
                      <a href={`mailto:${this.getAddressOutgoingEmail(this.state.currentOrderSelected.buyer)}`}>
                        E:{" "}
                        {this.getAddressOutgoingEmail(
                          this.state.currentOrderSelected.buyer
                        )}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            ) : this.state.currentOrderSelected.order_type === "multi_products" ? (
              <div
                className="mid-img-uploading-rmap commentsmidimgurmap multiproductsmidrmap"
                style={{ alignItems: "flex-start" }}
              >
                <div style={{ width: "100%", marginTop: 15 }}>
                  <div
                    className="top-order-date-rmo"
                    style={{ marginBottom: 10 }}
                  >
                    <p>
                      {this.getPerfectDate(
                        this.state.currentOrderSelected.created_on
                      )}{" "}
                      | Order {this.state.currentOrderSelected.order_id}
                    </p>
                  </div>
                  {this.state.currentOrderSelected.productsToRender.length >
                    0 &&
                    this.state.currentOrderSelected.productsToRender.map(
                      (prod) => {
                        return (
                          <>
                            <div className="bottom-order-details-rmo">
                              <img src={prod.product.image} alt="order" />
                              <div className="order-info-ocrm">
                                <p className="title-oiocrm">
                                  <font>
                                    {prod.product.description
                                      .charAt(0)
                                      .toUpperCase() +
                                      prod.product.description
                                        // .substr(0, 30)
                                        .slice(1)}
                                  </font>
                                </p>
                              </div>
                            </div>
                            <div
                              className="bottom-order-desc-rmo"
                              style={{ paddingBottom: 10 }}
                            >
                              <div
                                className="order-totals-block"
                                style={{
                                  alignItems: "flex-start",
                                  minHeight: "auto",
                                }}
                              >
                                <div
                                  className="order-total-price-row summary-row"
                                  style={{
                                    borderBottom: 0,
                                    maxWidth: 960,
                                    fontFamily: "Roboto",
                                    marginBottom: 0,
                                    paddingBottom: 0,
                                  }}
                                >
                                  <p
                                    className="normalprice-cpgm"
                                    style={{ marginBottom: 0 }}
                                  >
                                    KORTING: €
                                    {
                                      prod.product.innerProducts[
                                        prod.product.onInnerProductAt
                                      ].prijs
                                    }{" "}
                                  </p>
                                </div>
                                <div
                                  className="order-total-price-row summary-row"
                                  style={{
                                    borderBottom: 0,
                                    maxWidth: 960,
                                    fontFamily: "Roboto",
                                  }}
                                >
                                  <p className="desc-lds">
                                    {prod.product.description}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      }
                    )}
                  <div
                    className="bottom-order-companyname-rmo"
                    style={{ marginBottom: 30, borderBottom: "none" }}
                  >
                    <CommentsTopUser
                      toShowUserId={this.state.currentOrderSelected.buyer}
                      // showBericht={true}
                      hideBottomIcons={true}
                    />
                    <p className="addres-otb">
                      {this.getBuyerNameOutgoing(
                        this.state.currentOrderSelected.buyer
                      )}
                    </p>
                    <p className="addres-otb">
                      {this.getAddressOutgoing(
                        this.state.currentOrderSelected.buyer
                      )}
                    </p>
                    <p className="addres-otb">
                      {this.getAddressOutgoingSecond(
                        this.state.currentOrderSelected.buyer
                      )}
                    </p>
                    <p className="addres-otb telephone-otb">
                      <a href={`tel:${this.getAddressOutgoingPhone(this.state.currentOrderSelected.buyer)}`}>
                        T:{" "}
                        {this.getAddressOutgoingPhone(
                          this.state.currentOrderSelected.buyer
                        )}
                      </a>
                    </p>
                    <p className="addres-otb">
                      <a href={`mailto:${this.getAddressOutgoingEmail(this.state.currentOrderSelected.buyer)}`}>
                        E:{" "}
                        {this.getAddressOutgoingEmail(
                          this.state.currentOrderSelected.buyer
                        )}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="mid-img-uploading-rmap commentsmidimgurmap"
                style={{ alignItems: "flex-start" }}
              >
                <div style={{ width: "100%", marginTop: 15 }}>
                  <div className="top-order-date-rmo">
                    <p>
                      {this.getPerfectDate(
                        this.state.currentOrderSelected.created_on
                      )}{" "}
                      | Order {this.state.currentOrderSelected.order_id}
                    </p>
                  </div>
                  <div className="bottom-order-details-rmo">
                    {this.state.currentOrderSelected.products[0]
                      .campaignType === "live_event" ? (
                      <img src="https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9" />
                    ) : this.state.currentOrderSelected.products[0].campaignType === "local_event" && this.state.currentOrderSelected.products[0].localEventPlaceHolderIsImage ? (
                      <img src={this.state.currentOrderSelected.products[0].image} />
                    ) : this.state.currentOrderSelected.products[0].campaignType === "local_event" && !this.state.currentOrderSelected.products[0].localEventPlaceHolderIsImage ? (
                      <video src={this.state.currentOrderSelected.products[0].image} muted loop autoPlay playsInline className="video-orders-event" style={{ width: 60, height: 50, marginRight: 10 }}></video>
                    ) : this.state.currentOrderSelected.products[0]
                      .campaignType === "charity" ? (
                      <img
                        src={
                          this.state.currentOrderSelected.products[0]
                            .dishesObjected[0].image
                        }
                        alt="order"
                      />
                    ) : (
                      <img
                        src={this.state.currentOrderSelected.products[0].image}
                        alt="order"
                      />
                    )}
                    <div className="order-info-ocrm">
                      {this.state.currentOrderSelected.products[0]
                        .campaignType === "live_event_own" ? (
                        <p className="title-oiocrm">
                          <font>
                            {this.state.currentOrderSelected.products[0].description
                              .charAt(0)
                              .toUpperCase() +
                              this.state.currentOrderSelected.products[0].description
                                // .substr(0, 30)
                                .slice(1)}
                          </font>
                        </p>
                      ) : this.state.currentOrderSelected.products[0]
                        .campaignType === "live_event" ? (
                        <p className="title-oiocrm">
                          <font>
                            {this.state.currentOrderSelected.products[0].descriptionLiveEvent
                              .charAt(0)
                              .toUpperCase() +
                              this.state.currentOrderSelected.products[0].descriptionLiveEvent
                                // .substr(0, 30)
                                .slice(1)}
                          </font>
                        </p>
                      ) : (
                        <p className="title-oiocrm">
                          {this.state.currentOrderSelected.products[0]
                            .campaignType === "charity"
                            ? this.state.currentOrderSelected.products[0]
                              .main_title && (
                              <font>
                                {this.state.currentOrderSelected.products[0].main_title
                                  .charAt(0)
                                  .toUpperCase() +
                                  this.state.currentOrderSelected.products[0].main_title
                                    // .substr(0, 30)
                                    .slice(1)}
                              </font>
                            )
                            : this.state.currentOrderSelected.products[0]
                              .title && (
                              <font>
                                {this.state.currentOrderSelected.products[0].title
                                  .charAt(0)
                                  .toUpperCase() +
                                  this.state.currentOrderSelected.products[0].title
                                    // .substr(0, 30)
                                    .slice(1)}
                              </font>
                            )}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="bottom-order-desc-rmo">
                    {this.state.currentOrderSelected.products[0]
                      .campaignType === "local_event" ? (
                      <div
                        className="order-totals-block"
                        style={{ alignItems: "flex-start", minHeight: "auto" }}
                      >
                        {this.state.currentOrderSelected.products[0].amount ? (<div
                          className="order-total-price-row summary-row"
                          style={{
                            borderBottom: 0,
                            maxWidth: 960,
                            fontFamily: "Roboto",
                            marginBottom: 0,
                            paddingBottom: 0,
                          }}
                        >
                          <p
                            className="normalprice-cpgm"
                            style={{ marginBottom: 0 }}
                          >
                            EVENT: Korting{" "}
                            {this.state.currentOrderSelected.products[0]
                              .localEventType === "percentage" ? (
                              <font>
                                {
                                  this.state.currentOrderSelected.products[0]
                                    .amount
                                }
                                %
                              </font>
                            ) : (
                              <font>
                                €
                                {
                                  this.state.currentOrderSelected.products[0]
                                    .amount
                                }
                              </font>
                            )}
                          </p>
                        </div>) : (<></>)}
                        <div
                          className="order-total-price-row summary-row"
                          style={{
                            borderBottom: 0,
                            maxWidth: 960,
                            fontFamily: "Roboto",
                          }}
                        >
                          <p className="desc-lds">
                            {/* {this.state.currentOrderSelected.products[0].desc} */}
                            <div dangerouslySetInnerHTML={this.createDescMarkup(this.state.currentOrderSelected.products[0].desc)}></div>
                          </p>
                        </div>
                        <div
                          className="order-total-price-row summary-row"
                          style={{
                            borderBottom: 0,
                            maxWidth: 960,
                            fontFamily: "Roboto",
                            marginBottom: 0,
                            paddingBottom: 0,
                            textTransform: "none",
                          }}
                        >
                          <p
                            className="desc-hading-lds"
                            style={{
                              color: "rgb(33, 37, 41)",
                              fontSize: 13,
                              fontWeight: 500,
                              letterSpacing: 1,
                              marginTop: 0,
                            }}
                          >
                            Datum:{" "}
                            {this.showDateToUserTiming(
                              this.state.currentOrderSelected.products[0]
                            )}
                          </p>
                        </div>
                        {/* <div className="order-total-price-row summary-row" style={{ borderBottom: 0, maxWidth: 960, fontFamily: "Roboto" }}>
                                        <p className="desc-lds">{this.showDateToUserTiming(this.state.currentOrderSelected.products[0])}</p>
                                    </div> */}
                      </div>
                    ) : this.state.currentOrderSelected.products[0].campaignType === "web_shop" ? (
                      <div
                        className="order-totals-block"
                        style={{ alignItems: "flex-start", minHeight: "auto" }}
                      >
                        <div
                          className="order-total-price-row summary-row"
                          style={{
                            borderBottom: 0,
                            maxWidth: 960,
                            fontFamily: "Roboto",
                            marginBottom: 0,
                            paddingBottom: 0,
                          }}
                        >
                          <p
                            className="normalprice-cpgm"
                            style={{ marginBottom: 0 }}
                          >
                            Webshop: Korting{" "}
                            {this.state.currentOrderSelected.products[0].voucherIsPercentage ? (
                              <font>
                                {this.state.currentOrderSelected.products[0].amount} %
                              </font>
                            ) : (
                              <font>
                                € {this.state.currentOrderSelected.products[0].amount}
                              </font>
                            )}
                          </p>
                        </div>
                        <div
                          className="order-total-price-row summary-row"
                          style={{
                            borderBottom: 0,
                            maxWidth: 960,
                            fontFamily: "Roboto",
                          }}
                        >
                          <p className="desc-lds">
                            <div dangerouslySetInnerHTML={this.createDescMarkup(this.state.currentOrderSelected.products[0].desc)}></div>
                            {/* {this.state.currentOrderSelected.products[0].desc} */}
                          </p>
                        </div>
                      </div>
                    ) : (
                      this.state.currentOrderSelected.products[0].campaignType === "kortings_voucher" && (
                        <div
                          className="order-totals-block"
                          style={{
                            alignItems: "flex-start",
                            minHeight: "auto",
                          }}
                        >
                          <div
                            className="order-total-price-row summary-row"
                            style={{
                              borderBottom: 0,
                              maxWidth: 960,
                              fontFamily: "Roboto",
                              marginBottom: 0,
                              paddingBottom: 0,
                            }}
                          >
                            <p
                              className="normalprice-cpgm"
                              style={{ marginBottom: 0 }}
                            >
                              Kortingsvoucher{" "}
                              {this.state.currentOrderSelected.products[0].kortingsVoucherGiveaway ? (
                                <font>
                                  Free
                                </font>
                              ) : this.state.currentOrderSelected.products[0].kortingsVoucherPrijs ? (
                                <font>
                                  {this.state.currentOrderSelected.products[0].amount}
                                  %
                                </font>
                              ) : this.state.currentOrderSelected.products[0].kortingsVoucherPercentage ? (
                                <font>
                                  €{" "}{this.state.currentOrderSelected.products[0].amount}
                                </font>
                              ) : !this.state.currentOrderSelected.products[0].isoTimeTimerBtnEndTime ? (
                                <font>
                                  €{" "}{this.state.currentOrderSelected.products[0].kortingsVoucherSalePrice}
                                </font>
                              ) : (
                                <>
                                  {(new Date(this.state.currentOrderSelected.products[0].isoTimeTimerBtnEndTime).getTime() > new Date().getTime()) ? (<font>
                                    €{" "}
                                    {this.state.currentOrderSelected.products[0].kortingsVoucherSalePrice}
                                  </font>) : <font>
                                    €{" "}
                                    {this.state.currentOrderSelected.products[0].kortingsVoucherNormalPrice}
                                  </font>}
                                </>
                              )}
                            </p>
                          </div>
                          <div
                            className="order-total-price-row summary-row"
                            style={{
                              borderBottom: 0,
                              maxWidth: 960,
                              fontFamily: "Roboto",
                            }}
                          >
                            <p className="desc-lds">
                              <div dangerouslySetInnerHTML={this.createDescMarkup(this.state.currentOrderSelected.products[0].desc)}></div>
                              {/* {this.state.currentOrderSelected.products[0].desc} */}
                            </p>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                  {(this.state.currentOrderSelected.is_used || (this.state.currentOrderSelected.redeemed_text && this.state.currentOrderSelected.redeemed_text.toLowerCase() === this.state.searchText.toLowerCase())) && (this.checkForRedemedButton(this.state.currentOrderSelected))}
                  <div className="bottom-order-companyname-rmo">
                    <CommentsTopUser
                      toShowUserId={this.state.currentOrderSelected.buyer}
                      // showBericht={true}
                      hideBottomIcons={true}
                    />
                    <p className="addres-otb" style={{ marginTop: 15 }}>
                      {this.getBuyerNameOutgoing(
                        this.state.currentOrderSelected.buyer
                      )}
                    </p>
                    <p className="addres-otb">
                      {this.getAddressOutgoing(
                        this.state.currentOrderSelected.buyer
                      )}
                    </p>
                    <p className="addres-otb">
                      {this.getAddressOutgoingSecond(
                        this.state.currentOrderSelected.buyer
                      )}
                    </p>
                    <p className="addres-otb telephone-otb">
                      <a href={`tel:${this.getAddressOutgoingPhone(this.state.currentOrderSelected.buyer)}`}>
                        T:{" "}
                        {this.getAddressOutgoingPhone(
                          this.state.currentOrderSelected.buyer
                        )}
                      </a>
                    </p>
                    <p className="addres-otb">
                      <a href={`mailto:${this.getAddressOutgoingEmail(this.state.currentOrderSelected.buyer)}`}>
                        E:{" "}
                        {this.getAddressOutgoingEmail(
                          this.state.currentOrderSelected.buyer
                        )}
                      </a>
                    </p>
                    {(this.state.currentOrderSelected.is_used || (this.state.currentOrderSelected.redeemed_text && this.state.currentOrderSelected.redeemed_text.toLowerCase() === this.state.searchText.toLowerCase())) && (<p className="addres-otb" style={{ display: "flex", alignItems: "center" }}>
                      Gebruikte code: {this.state.currentOrderSelected.redeemed_text}
                    </p>)}
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : this.state.noFiltersHere ? (
          <div
            className="rightmenuaddproduct rightmenucommentsaddproduct rightmenuproductfriendlists rightmenucompanyvouchersmain rightmenuorderspagemain"
            style={{ overflow: "hidden" }}
          >
            <div
              className="mid-rmap"
              style={{
                position: "relative",
                padding: 13,
                alignItems: "center",
                // alignItems: "flex-start",
              }}
            >
              <span>KLANTBESTELLINGEN</span>
              {/* <span>&nbsp;</span> */}
              <div>
                <ChevronLeftIcon
                  style={{
                    cursor: "pointer",
                    position: "absolute",
                    top: "50%",
                    transform: "translateY(-50%)",
                    left: 10,
                    fontSize: 18,
                  }}
                  onClick={() => {
                    this.setState({ noFiltersHere: false, ordersFiltered: [] });
                  }}
                />
                <div className="spacing-between-icons"></div>

                <div className="city-rm-main" style={{ right: 85 }}>
                  <div className="select-options-filter-order">
                    <div className="inner-sofo">
                      <div
                        className="top-box-isofo"
                        onClick={() =>
                          this.setState({
                            dropdownOpen: !this.state.dropdownOpen,
                          })
                        }
                      >
                        {this.state.currentFiltered ? (
                          <p>Status: {this.state.currentFiltered}</p>
                        ) : (
                          <p>Filter</p>
                        )}
                        <ExpandMoreIcon />
                      </div>
                      <div
                        className="box-opened-dropdown"
                        style={{
                          width: 130,
                          opacity: this.state.dropdownOpen ? 1 : 0,
                          pointerEvents: this.state.dropdownOpen ? "all" : "none",
                        }}
                      >
                        <ul>
                          <li
                            onClick={() => {
                              this.setState({
                                currentFiltered: "Event",
                                dropdownOpen: false,
                              });
                              let filteredArray = [];
                              this.state.arrOrders.map((order) => {
                                if (order.order_type === "campaign") {
                                  if (
                                    order.products[0].campaignType ===
                                    "last_minute" ||
                                    order.products[0].campaignType ===
                                    "local_event"
                                  ) {
                                    filteredArray.push(order);
                                  }
                                }
                              });
                              if (filteredArray.length > 0) {
                                this.setState({
                                  noFiltersHere: false,
                                  ordersFiltered: filteredArray,
                                  ordersFilteredDuplicate: filteredArray,
                                });
                              } else {
                                this.setState({
                                  noFiltersHere: true,
                                  ordersFiltered: filteredArray,
                                  ordersFilteredDuplicate: filteredArray,
                                });
                              }
                            }}
                          >
                            Event
                          </li>
                          <li
                            onClick={() => {
                              this.setState({
                                currentFiltered: "Kortingsvoucher",
                                dropdownOpen: false,
                              });
                              let filteredArray = [];
                              this.state.arrOrders.map((order) => {
                                if (order.order_type === "campaign") {
                                  if (order.products[0].campaignType === "kortings_voucher" || order.products[0].campaignType === "web_shop") {
                                    filteredArray.push(order);
                                  }
                                }
                              });
                              if (filteredArray.length > 0) {
                                this.setState({
                                  noFiltersHere: false,
                                  ordersFiltered: filteredArray,
                                  ordersFilteredDuplicate: filteredArray,
                                });
                              } else {
                                this.setState({
                                  noFiltersHere: true,
                                  ordersFiltered: filteredArray,
                                  ordersFilteredDuplicate: filteredArray,
                                });
                              }
                            }}
                          >
                            Kortingsvoucher
                          </li>
                          {/* <li
                            onClick={() => {
                              this.setState({
                                currentFiltered: "Producten",
                                dropdownOpen: false,
                              });
                              let filteredArray = [];
                              this.state.arrOrders.map((order) => {
                                if (order.order_type === "bhodi_products") {
                                  filteredArray.push(order);
                                }
                              });
                              if (filteredArray.length > 0) {
                                this.setState({
                                  noFiltersHere: false,
                                  ordersFiltered: filteredArray,
                                  ordersFilteredDuplicate: filteredArray,
                                });
                              } else {
                                this.setState({
                                  noFiltersHere: true,
                                  ordersFiltered: filteredArray,
                                  ordersFilteredDuplicate: filteredArray,
                                });
                              }
                            }}
                          >
                            Producten
                          </li> */}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <CloseIcon
                  style={{
                    cursor: "pointer",
                    position: "absolute",
                    top: "50%",
                    transform: "translateY(-50%)",
                    right: 20,
                    fontSize: 18,
                  }}
                  onClick={() => {
                    this.props.closePopupComplete();
                  }}
                />
              </div>
            </div>
            <div
              className="mid-img-uploading-rmap commentsmidimgurmap"
              style={{ alignItems: "flex-start" }}
            >
              <div style={{ width: "100%", marginTop: 15 }}>
                <div className="search-rm-main">
                  <input
                    type="text"
                    value={this.state.searchText}
                    onChange={(e) => {
                      this.setState({ searchText: e.target.value });
                      if (e.target.value !== "") {
                        if (this.state.currentFiltered) {
                          let arrToShow = [];
                          this.state.ordersFiltered.map((u) => {
                            let buyerCred = this.props.user.filter((j) => {
                              return j.user_id === u.buyer;
                            });
                            if (u.redeemed_text && u.redeemed_text.toLowerCase().includes(e.target.value.toLowerCase())) {
                              arrToShow.push(u)
                            } else if (buyerCred.length > 0) {
                              if (buyerCred[0].type === "company") {
                                if (
                                  buyerCred[0].company_name
                                    .toLowerCase()
                                    .includes(e.target.value.toLowerCase())
                                ) {
                                  arrToShow.push(u);
                                }
                              } else {
                                if (
                                  buyerCred[0].first_name
                                    .toLowerCase()
                                    .includes(e.target.value.toLowerCase())
                                ) {
                                  arrToShow.push(u);
                                }
                              }
                            }
                          });
                          if (arrToShow.length > 0) {
                            if (arrToShow.length > 0) {
                              this.setState({
                                ordersFiltered: arrToShow,
                                noFiltersHere: false,
                              });
                            } else {
                              this.setState({ noFiltersHere: true });
                            }
                          } else {
                            if (this.state.ordersFilteredDuplicate.length > 0) {
                              this.setState({
                                ordersFiltered:
                                  this.state.ordersFilteredDuplicate,
                                noFiltersHere: false,
                              });
                            } else {
                              this.setState({ noFiltersHere: true });
                            }
                          }
                        } else {
                          let arrToShow = [];
                          this.state.arrOrders.map((u) => {
                            let buyerCred = this.props.user.filter((j) => {
                              return j.user_id === u.buyer;
                            });
                            if (u.redeemed_text && u.redeemed_text.toLowerCase().includes(e.target.value.toLowerCase())) {
                              arrToShow.push(u)
                            } else if (buyerCred.length > 0) {
                              if (buyerCred[0].type === "company") {
                                if (buyerCred[0].company_name.toLowerCase().includes(e.target.value.toLowerCase())) {
                                  arrToShow.push(u);
                                }
                              } else {
                                if (buyerCred[0].first_name.toLowerCase().includes(e.target.value.toLowerCase())) {
                                  arrToShow.push(u);
                                }
                              }
                            }
                          });
                          if (arrToShow.length > 0) {
                            this.setState({
                              ordersFiltered: arrToShow,
                              noFiltersHere: false,
                            });
                          } else {
                            this.setState({ noFiltersHere: true });
                          }
                        }
                      } else {
                        if (this.state.currentFiltered) {
                          this.setState({
                            ordersFiltered: this.state.ordersFilteredDuplicate,
                            noFiltersHere: false,
                          });
                        } else {
                          this.setState({
                            ordersFiltered: [],
                            noFiltersHere: false,
                          });
                        }
                      }
                    }}
                  />
                  <SearchIcon />
                </div>
                <div className="top-order-main">
                  <div className="orders-container-rightmenu">
                    <p>Geen resultaten</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : this.state.ordersFiltered.length > 0 ? (
          <div
            className="rightmenuaddproduct rightmenucommentsaddproduct rightmenuproductfriendlists rightmenucompanyvouchersmain rightmenuorderspagemain"
            style={{ overflow: "hidden" }}
          >
            <div
              className="mid-rmap"
              style={{
                position: "relative",
                padding: 13,
                alignItems: "center",
              }}
            >
              <span>KLANTBESTELLINGEN</span>
              {/* <span>&nbsp;</span> */}
              <div>
                <ChevronLeftIcon
                  style={{
                    cursor: "pointer",
                    position: "absolute",
                    top: "50%",
                    transform: "translateY(-50%)",
                    left: 10,
                    fontSize: 18,
                  }}
                  onClick={() => {
                    this.props.closePopup();
                  }}
                />
                <div className="spacing-between-icons"></div>

                <div className="city-rm-main" style={{ right: 85 }}>
                  <div className="select-options-filter-order">
                    <div className="inner-sofo">
                      <div
                        className="top-box-isofo"
                        onClick={() =>
                          this.setState({
                            dropdownOpen: !this.state.dropdownOpen,
                          })
                        }
                      >
                        {this.state.currentFiltered ? (
                          <p>Status: {this.state.currentFiltered}</p>
                        ) : (
                          <p>Filter</p>
                        )}
                        <ExpandMoreIcon />
                      </div>
                      <div
                        className="box-opened-dropdown"
                        style={{
                          width: 130,
                          opacity: this.state.dropdownOpen ? 1 : 0,
                          pointerEvents: this.state.dropdownOpen ? "all" : "none",
                        }}
                      >
                        <ul>
                          <li
                            onClick={() => {
                              this.setState({
                                currentFiltered: "Event",
                                dropdownOpen: false,
                              });
                              let filteredArray = [];
                              this.state.arrOrders.map((order) => {
                                if (order.order_type === "campaign") {
                                  if (
                                    order.products[0].campaignType ===
                                    "last_minute" ||
                                    order.products[0].campaignType ===
                                    "local_event"
                                  ) {
                                    filteredArray.push(order);
                                  }
                                }
                              });
                              if (filteredArray.length > 0) {
                                this.setState({
                                  noFiltersHere: false,
                                  ordersFiltered: filteredArray,
                                  ordersFilteredDuplicate: filteredArray,
                                });
                              } else {
                                this.setState({
                                  noFiltersHere: true,
                                  ordersFiltered: filteredArray,
                                  ordersFilteredDuplicate: filteredArray,
                                });
                              }
                            }}
                          >
                            Event
                          </li>
                          <li
                            onClick={() => {
                              this.setState({
                                currentFiltered: "Kortingsvoucher",
                                dropdownOpen: false,
                              });
                              let filteredArray = [];
                              this.state.arrOrders.map((order) => {
                                if (order.order_type === "campaign") {
                                  if (order.products[0].campaignType === "kortings_voucher" || order.products[0].campaignType === "web_shop") {
                                    filteredArray.push(order);
                                  }
                                }
                              });
                              if (filteredArray.length > 0) {
                                this.setState({
                                  noFiltersHere: false,
                                  ordersFiltered: filteredArray,
                                  ordersFilteredDuplicate: filteredArray,
                                });
                              } else {
                                this.setState({
                                  noFiltersHere: true,
                                  ordersFiltered: filteredArray,
                                  ordersFilteredDuplicate: filteredArray,
                                });
                              }
                            }}
                          >
                            Kortingsvoucher
                          </li>
                          {/* <li
                            onClick={() => {
                              this.setState({
                                currentFiltered: "Producten",
                                dropdownOpen: false,
                              });
                              let filteredArray = [];
                              this.state.arrOrders.map((order) => {
                                if (order.order_type === "bhodi_products") {
                                  filteredArray.push(order);
                                }
                              });
                              if (filteredArray.length > 0) {
                                this.setState({
                                  noFiltersHere: false,
                                  ordersFiltered: filteredArray,
                                  ordersFilteredDuplicate: filteredArray,
                                });
                              } else {
                                this.setState({
                                  noFiltersHere: true,
                                  ordersFiltered: filteredArray,
                                  ordersFilteredDuplicate: filteredArray,
                                });
                              }
                            }}
                          >
                            Producten
                          </li> */}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <CloseIcon
                  style={{
                    cursor: "pointer",
                    position: "absolute",
                    top: "50%",
                    transform: "translateY(-50%)",
                    right: 20,
                    fontSize: 18,
                  }}
                  onClick={() => {
                    this.props.closePopupComplete();
                  }}
                />
              </div>
            </div>
            <div
              className="mid-img-uploading-rmap commentsmidimgurmap"
              style={{ alignItems: "flex-start" }}
            >
              <div style={{ width: "100%", marginTop: 15 }}>
                <div className="search-rm-main">
                  <input
                    type="text"
                    value={this.state.searchText}
                    onChange={(e) => {
                      this.setState({ searchText: e.target.value });
                      if (e.target.value !== "") {
                        if (this.state.currentFiltered) {
                          let arrToShow = [];
                          this.state.ordersFiltered.map((u) => {
                            let buyerCred = this.props.user.filter((j) => {
                              return j.user_id === u.buyer;
                            });
                            if (u.redeemed_text && u.redeemed_text.toLowerCase().includes(e.target.value.toLowerCase())) {
                              arrToShow.push(u)
                            } else if (buyerCred.length > 0) {
                              if (buyerCred[0].type === "company") {
                                if (
                                  buyerCred[0].company_name
                                    .toLowerCase()
                                    .includes(e.target.value.toLowerCase())
                                ) {
                                  arrToShow.push(u);
                                }
                              } else {
                                if (
                                  buyerCred[0].first_name
                                    .toLowerCase()
                                    .includes(e.target.value.toLowerCase())
                                ) {
                                  arrToShow.push(u);
                                }
                              }
                            }
                          });
                          if (arrToShow.length > 0) {
                            if (arrToShow.length > 0) {
                              this.setState({
                                ordersFiltered: arrToShow,
                                noFiltersHere: false,
                              });
                            } else {
                              this.setState({ noFiltersHere: true });
                            }
                          } else {
                            if (this.state.ordersFilteredDuplicate.length > 0) {
                              this.setState({
                                ordersFiltered:
                                  this.state.ordersFilteredDuplicate,
                                noFiltersHere: false,
                              });
                            } else {
                              this.setState({ noFiltersHere: true });
                            }
                          }
                        } else {
                          let arrToShow = [];
                          this.state.arrOrders.map((u) => {
                            let buyerCred = this.props.user.filter((j) => {
                              return j.user_id === u.buyer;
                            });
                            if (u.redeemed_text && u.redeemed_text.toLowerCase().includes(e.target.value.toLowerCase())) {
                              arrToShow.push(u)
                            } else if (buyerCred.length > 0) {
                              if (buyerCred[0].type === "company") {
                                if (buyerCred[0].company_name.toLowerCase().includes(e.target.value.toLowerCase())) {
                                  arrToShow.push(u);
                                }
                              } else {
                                if (buyerCred[0].first_name.toLowerCase().includes(e.target.value.toLowerCase())) {
                                  arrToShow.push(u);
                                }
                              }
                            }
                          });
                          if (arrToShow.length > 0) {
                            this.setState({
                              ordersFiltered: arrToShow,
                              noFiltersHere: false,
                            });
                          } else {
                            this.setState({ noFiltersHere: true });
                          }
                        }
                      } else {
                        if (this.state.currentFiltered) {
                          this.setState({
                            ordersFiltered: this.state.ordersFilteredDuplicate,
                            noFiltersHere: false,
                          });
                        } else {
                          this.setState({
                            ordersFiltered: [],
                            noFiltersHere: false,
                          });
                        }
                      }
                    }}
                  />
                  <SearchIcon />
                </div>
                {this.state.ordersFiltered.length > 0 &&
                  this.state.ordersFiltered.map((y, i) => {
                    if (y.order_type === "bhodi_products") {
                      let indexOfImage = 0
                      if (y.products[0].images && y.products[0].images.length > 0) {
                        if (y.products[0].featuredImage && y.products[0].featuredImage < y.products[0].images.length) {
                          indexOfImage = y.products[0].featuredImage
                        }

                        let findIndex = y.products[0].images.findIndex((t) => {
                          return t.includes("_1")
                        })
                        if (findIndex !== -1) {
                          indexOfImage = findIndex
                        }
                      }
                      return (
                        <div className="orders-container-rightmenu">
                          <div className="top-order-main">
                            <p>
                              {this.getPerfectDate(y.created_on)} | Order{" "}
                              {y.order_id}
                            </p>
                          </div>
                          <div
                            className="bottom-order-main"
                            onClick={() => {
                              this.setState({ currentOrderSelected: y, onIndexImage: indexOfImage });
                            }}
                          >
                            <img
                              src={y.products[0].images[indexOfImage]}
                              alt="order"
                            />
                            <div className="order-info-ocrm">
                              <p className="title-oiocrm">
                                <font>
                                  {y.products[0].title
                                    .charAt(0)
                                    .toUpperCase() +
                                    y.products[0].title
                                      .substr(0, 30)
                                      .slice(1)}
                                </font>
                              </p>
                              <p className="name-oiocrm">
                                {this.getSellerNameCampaignOnly({
                                  created_by: y.buyer,
                                })}
                              </p>
                            </div>
                            <div className="more-show-bom">
                              <MdArrowForwardIos className="icon-smaller-msb" />
                            </div>
                          </div>
                        </div>
                      );
                    } else if (y.order_type === "multi_products") {
                      return (
                        <div className="orders-container-rightmenu">
                          <div className="top-order-main">
                            <p>
                              {this.getPerfectDate(y.created_on)} | Order{" "}
                              {y.order_id}
                            </p>
                          </div>
                          <div
                            className="bottom-order-main"
                            onClick={() => {
                              this.setState({ currentOrderSelected: y });
                            }}
                          >
                            <img
                              src={y.productsToRender[0].product.image}
                              alt="order"
                            />
                            <div className="order-info-ocrm">
                              <p className="title-oiocrm">
                                <font>
                                  {y.productsToRender[0].product.description
                                    .charAt(0)
                                    .toUpperCase() +
                                    y.productsToRender[0].product.description
                                      .substr(0, 30)
                                      .slice(1)}
                                </font>
                              </p>
                              <p className="name-oiocrm">
                                {this.getSellerNameCampaignOnly({
                                  created_by: y.buyer,
                                })}
                              </p>
                            </div>
                            <div className="more-show-bom">
                              <MdArrowForwardIos className="icon-smaller-msb" />
                            </div>
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div className="orders-container-rightmenu">
                          <div className="top-order-main">
                            <p>
                              {this.getPerfectDate(y.created_on)} | Order{" "}
                              {y.order_id}
                            </p>
                          </div>
                          <div
                            className="bottom-order-main"
                            onClick={() => {
                              this.setState({ currentOrderSelected: y });
                            }}
                          >
                            {y.products[0].campaignType === "live_event" ? (
                              <img src="https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9" />
                            ) : y.products[0].campaignType === "local_event" && y.products[0].localEventPlaceHolderIsImage ? (
                              <img src={y.products[0].image} />
                            ) : y.products[0].campaignType === "local_event" && !y.products[0].localEventPlaceHolderIsImage ? (
                              <video src={y.products[0].image} muted loop autoPlay playsInline className="video-orders-event"></video>
                            ) : y.products[0].campaignType === "charity" ? (
                              <img
                                src={y.products[0].dishesObjected[0].image}
                                alt="order"
                              />
                            ) : (
                              <img src={y.products[0].image} alt="order" />
                            )}
                            <div className="order-info-ocrm">
                              {y.products[0].campaignType ===
                                "live_event_own" ? (
                                <p className="title-oiocrm">
                                  <font>
                                    {y.products[0].description
                                      .charAt(0)
                                      .toUpperCase() +
                                      y.products[0].description
                                        .substr(0, 30)
                                        .slice(1)}
                                  </font>
                                </p>
                              ) : y.products[0].campaignType ===
                                "live_event" ? (
                                <p className="title-oiocrm">
                                  <font>
                                    {y.products[0].descriptionLiveEvent
                                      .charAt(0)
                                      .toUpperCase() +
                                      y.products[0].descriptionLiveEvent
                                        .substr(0, 30)
                                        .slice(1)}
                                  </font>
                                </p>
                              ) : (
                                <p className="title-oiocrm">
                                  {y.products[0].campaignType === "charity" ? (
                                    <font>
                                      {y.products[0].main_title
                                        .charAt(0)
                                        .toUpperCase() +
                                        y.products[0].main_title
                                          .substr(0, 30)
                                          .slice(1)}
                                    </font>
                                  ) : (
                                    <font>
                                      {y.products[0].title
                                        .charAt(0)
                                        .toUpperCase() +
                                        y.products[0].title
                                          .substr(0, 30)
                                          .slice(1)}
                                    </font>
                                  )}
                                </p>
                              )}
                              <p className="name-oiocrm">
                                {this.getSellerNameCampaignOnly({
                                  created_by: y.buyer,
                                })}
                              </p>
                            </div>
                            {(y.is_used || (y.redeemed_text && y.redeemed_text.toLowerCase() === this.state.searchText.toLowerCase())) && (<div className="more-show-bom" style={{ right: 32 }} onClick={() => {
                              this.setState({ redeemTextToShow: y.redeemed_text })
                            }}>
                              <BsCheck />
                            </div>)}
                            <div className="more-show-bom">
                              <MdArrowForwardIos className="icon-smaller-msb" />
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
              </div>
            </div>
          </div>
        ) : (
          <div
            className="rightmenuaddproduct rightmenucommentsaddproduct rightmenuproductfriendlists rightmenucompanyvouchersmain rightmenuorderspagemain"
            style={{ overflow: "hidden" }}
          >
            <div
              className="mid-rmap"
              style={{
                position: "relative",
                padding: 13,
                alignItems: "center",
                // alignItems: "flex-start",
              }}
            >
              <span>KLANTBESTELLINGEN</span>
              {/* <span>&nbsp;</span> */}
              <div>
                <ChevronLeftIcon
                  style={{
                    cursor: "pointer",
                    position: "absolute",
                    top: "50%",
                    transform: "translateY(-50%)",
                    left: 10,
                    fontSize: 18,
                  }}
                  onClick={() => {
                    this.props.closePopup();
                  }}
                />
                <div className="spacing-between-icons"></div>

                <div className="city-rm-main" style={{ right: 85 }}>
                  <div className="select-options-filter-order">
                    <div className="inner-sofo">
                      <div
                        className="top-box-isofo"
                        onClick={() =>
                          this.setState({
                            dropdownOpen: !this.state.dropdownOpen,
                          })
                        }
                      >
                        {this.state.currentFiltered ? (
                          <p>Status: {this.state.currentFiltered}</p>
                        ) : (
                          <p>Alle</p>
                        )}
                        <ExpandMoreIcon />
                      </div>
                      <div
                        className="box-opened-dropdown"
                        style={{
                          width: 130,
                          opacity: this.state.dropdownOpen ? 1 : 0,
                          pointerEvents: this.state.dropdownOpen ? "all" : "none",
                        }}
                      >
                        <ul>
                          <li
                            onClick={() => {
                              this.setState({
                                currentFiltered: "Event",
                                dropdownOpen: false,
                              });
                              let filteredArray = [];
                              this.state.arrOrders.map((order) => {
                                if (order.order_type === "campaign") {
                                  if (
                                    order.products[0].campaignType ===
                                    "last_minute" ||
                                    order.products[0].campaignType ===
                                    "local_event"
                                  ) {
                                    filteredArray.push(order);
                                  }
                                }
                              });
                              if (filteredArray.length > 0) {
                                this.setState({
                                  noFiltersHere: false,
                                  ordersFiltered: filteredArray,
                                  ordersFilteredDuplicate: filteredArray,
                                });
                              } else {
                                this.setState({
                                  noFiltersHere: true,
                                  ordersFiltered: filteredArray,
                                  ordersFilteredDuplicate: filteredArray,
                                });
                              }
                            }}
                          >
                            Event
                          </li>
                          <li
                            onClick={() => {
                              this.setState({
                                currentFiltered: "Kortingsvoucher",
                                dropdownOpen: false,
                              });
                              let filteredArray = [];
                              this.state.arrOrders.map((order) => {
                                if (order.order_type === "campaign") {
                                  if (order.products[0].campaignType === "kortings_voucher" || order.products[0].campaignType === "web_shop") {
                                    filteredArray.push(order);
                                  }
                                }
                              });
                              if (filteredArray.length > 0) {
                                this.setState({
                                  noFiltersHere: false,
                                  ordersFiltered: filteredArray,
                                  ordersFilteredDuplicate: filteredArray,
                                });
                              } else {
                                this.setState({
                                  noFiltersHere: true,
                                  ordersFiltered: filteredArray,
                                  ordersFilteredDuplicate: filteredArray,
                                });
                              }
                            }}
                          >
                            Kortingsvoucher
                          </li>
                          {/* <li
                            onClick={() => {
                              this.setState({
                                currentFiltered: "Producten",
                                dropdownOpen: false,
                              });
                              let filteredArray = [];
                              this.state.arrOrders.map((order) => {
                                if (order.order_type === "bhodi_products") {
                                  filteredArray.push(order);
                                }
                              });
                              if (filteredArray.length > 0) {
                                this.setState({
                                  noFiltersHere: false,
                                  ordersFiltered: filteredArray,
                                  ordersFilteredDuplicate: filteredArray,
                                });
                              } else {
                                this.setState({
                                  noFiltersHere: true,
                                  ordersFiltered: filteredArray,
                                  ordersFilteredDuplicate: filteredArray,
                                });
                              }
                            }}
                          >
                            Producten
                          </li> */}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <CloseIcon
                  style={{
                    cursor: "pointer",
                    position: "absolute",
                    top: "50%",
                    transform: "translateY(-50%)",
                    right: 20,
                    fontSize: 18,
                  }}
                  onClick={() => {
                    this.props.closePopupComplete();
                  }}
                />
              </div>
            </div>
            <div
              className="mid-img-uploading-rmap commentsmidimgurmap"
              style={{ alignItems: "flex-start" }}
            >
              <div style={{ width: "100%", marginTop: 15 }}>
                <div className="search-rm-main">
                  <input
                    type="text"
                    value={this.state.searchText}
                    onChange={(e) => {
                      this.setState({ searchText: e.target.value });
                      if (e.target.value !== "") {
                        let arrToShow = [];
                        this.state.arrOrders.map((u) => {
                          let buyerCred = this.props.user.filter((j) => {
                            return j.user_id === u.buyer;
                          });
                          if (u.redeemed_text && u.redeemed_text.toLowerCase().includes(e.target.value.toLowerCase())) {
                            arrToShow.push(u)
                          } else if (buyerCred.length > 0) {
                            if (buyerCred[0].type === "company") {
                              if (buyerCred[0].company_name.toLowerCase().includes(e.target.value.toLowerCase())) {
                                arrToShow.push(u);
                              }
                            } else {
                              if (buyerCred[0].first_name.toLowerCase().includes(e.target.value.toLowerCase())) {
                                arrToShow.push(u);
                              }
                            }
                          }
                        });
                        if (arrToShow.length > 0) {
                          this.setState({
                            ordersFiltered: arrToShow,
                            noFiltersHere: false,
                          });
                        } else {
                          this.setState({ noFiltersHere: true });
                        }
                      } else {
                        if (this.state.currentFiltered) {
                          this.setState({
                            ordersFiltered: this.state.ordersFilteredDuplicate,
                            noFiltersHere: false,
                          });
                        } else {
                          this.setState({
                            ordersFiltered: [],
                            noFiltersHere: false,
                          });
                        }
                      }
                    }}
                  />
                  <SearchIcon />
                </div>
                {this.state.arrOrders.length > 0 &&
                  this.state.arrOrders.map((y, i) => {
                    if (y.order_type === "bhodi_products") {
                      let indexOfImage = 0
                      if (y.products[0].images && y.products[0].images.length > 0) {
                        if (y.products[0].featuredImage && y.products[0].featuredImage < y.products[0].images.length) {
                          indexOfImage = y.products[0].featuredImage
                        }

                        let findIndex = y.products[0].images.findIndex((t) => {
                          return t.includes("_1")
                        })
                        if (findIndex !== -1) {
                          indexOfImage = findIndex
                        }
                      }
                      return (
                        <div className="orders-container-rightmenu">
                          <div className="top-order-main">
                            <p>
                              {this.getPerfectDate(y.created_on)} | Order{" "}
                              {y.order_id}
                            </p>
                          </div>
                          <div
                            className="bottom-order-main"
                            onClick={() => {
                              this.setState({ currentOrderSelected: y, onIndexImage: indexOfImage });
                            }}
                          >
                            <img
                              src={y.products[0].images[indexOfImage]}
                              alt="order"
                            />
                            <div className="order-info-ocrm">
                              <p className="title-oiocrm">
                                <font>
                                  {y.products[0].title
                                    .charAt(0)
                                    .toUpperCase() +
                                    y.products[0].title
                                      .substr(0, 30)
                                      .slice(1)}
                                </font>
                              </p>
                              <p className="name-oiocrm">
                                {this.getSellerNameCampaignOnly({
                                  created_by: y.buyer,
                                })}
                              </p>
                            </div>
                            <div className="more-show-bom">
                              <MdArrowForwardIos className="icon-smaller-msb" />
                            </div>
                          </div>
                        </div>
                      );
                    } else if (y.order_type === "multi_products") {
                      return (
                        <div className="orders-container-rightmenu">
                          <div className="top-order-main">
                            <p>
                              {this.getPerfectDate(y.created_on)} | Order{" "}
                              {y.order_id}
                            </p>
                          </div>
                          <div
                            className="bottom-order-main"
                            onClick={() => {
                              this.setState({ currentOrderSelected: y });
                            }}
                          >
                            <img
                              src={y.productsToRender[0].product.image}
                              alt="order"
                            />
                            <div className="order-info-ocrm">
                              <p className="title-oiocrm">
                                <font>
                                  {y.productsToRender[0].product.description
                                    .charAt(0)
                                    .toUpperCase() +
                                    y.productsToRender[0].product.description
                                      .substr(0, 30)
                                      .slice(1)}
                                </font>
                              </p>
                              <p className="name-oiocrm">
                                {this.getSellerNameCampaignOnly({
                                  created_by: y.buyer,
                                })}
                              </p>
                            </div>
                            <div className="more-show-bom">
                              <MdArrowForwardIos className="icon-smaller-msb" />
                            </div>
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div className="orders-container-rightmenu">
                          <div className="top-order-main">
                            <p>
                              {this.getPerfectDate(y.created_on)} | Order{" "}
                              {y.order_id}
                            </p>
                          </div>
                          <div
                            className="bottom-order-main"
                            onClick={() => {
                              this.setState({ currentOrderSelected: y });
                            }}
                          >
                            {y.products[0].campaignType === "live_event" ? (
                              <img src="https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9" />
                            ) : y.products[0].campaignType === "local_event" && y.products[0].localEventPlaceHolderIsImage ? (
                              <img src={y.products[0].image} />
                            ) : y.products[0].campaignType === "local_event" && !y.products[0].localEventPlaceHolderIsImage ? (
                              <video src={y.products[0].image} muted loop autoPlay playsInline className="video-orders-event"></video>
                            ) : y.products[0].campaignType === "charity" ? (
                              <img
                                src={y.products[0].dishesObjected[0].image}
                                alt="order"
                              />
                            ) : (
                              <img src={y.products[0].image} alt="order" />
                            )}
                            <div className="order-info-ocrm">
                              {y.products[0].campaignType ===
                                "live_event_own" ? (
                                <p className="title-oiocrm">
                                  <font>
                                    {y.products[0].description
                                      .charAt(0)
                                      .toUpperCase() +
                                      y.products[0].description
                                        .substr(0, 30)
                                        .slice(1)}
                                  </font>
                                </p>
                              ) : y.products[0].campaignType ===
                                "live_event" ? (
                                <p className="title-oiocrm">
                                  <font>
                                    {y.products[0].descriptionLiveEvent
                                      .charAt(0)
                                      .toUpperCase() +
                                      y.products[0].descriptionLiveEvent
                                        .substr(0, 30)
                                        .slice(1)}
                                  </font>
                                </p>
                              ) : (
                                <p className="title-oiocrm">
                                  {y.products[0].campaignType === "charity"
                                    ? y.products[0].main_title && (
                                      <font>
                                        {y.products[0].main_title
                                          .charAt(0)
                                          .toUpperCase() +
                                          y.products[0].main_title
                                            .substr(0, 30)
                                            .slice(1)}
                                      </font>
                                    )
                                    : y.products[0].title && (
                                      <font>
                                        {y.products[0].title
                                          .charAt(0)
                                          .toUpperCase() +
                                          y.products[0].title
                                            .substr(0, 30)
                                            .slice(1)}
                                      </font>
                                    )}
                                </p>
                              )}
                              <p className="name-oiocrm">
                                {this.getSellerNameCampaignOnly({
                                  created_by: y.buyer,
                                })}
                              </p>
                            </div>
                            {(y.is_used || (y.redeemed_text && y.redeemed_text.toLowerCase() === this.state.searchText.toLowerCase())) && (<div className="more-show-bom" style={{ right: 32 }} onClick={() => {
                              this.setState({ redeemTextToShow: y.redeemed_text })
                            }}>
                              <BsCheck />
                            </div>)}
                            <div className="more-show-bom">
                              <MdArrowForwardIos className="icon-smaller-msb" />
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
              </div>
            </div>
          </div>
        )}

        <div
          className="verzilver-popup-white-opacity"
          style={{
            opacity: this.state.redeemTextToShow ? 1 : 0,
            pointerEvents: this.state.redeemTextToShow ? "all" : "none",
          }}
        ></div>
        <div
          className="verzilver-popup-white"
          style={{
            opacity: this.state.redeemTextToShow ? 1 : 0,
            pointerEvents: this.state.redeemTextToShow ? "all" : "none",
          }}
        >
          <CloseIcon
            className="close-hpw"
            onClick={() =>
              this.setState({ redeemTextToShow: "", currentOrderSelected: "" })
            }
          />
          <p className="desc-hpw" style={{ marginBottom: 0 }}>
            Code: {this.state.redeemTextToShow}
          </p>
        </div>


        {/* Order Page popups */}
        <div
          className="verzilver-popup-white-opacity"
          style={{
            opacity: this.state.verzilverPopup ? 1 : 0,
            pointerEvents: this.state.verzilverPopup ? "all" : "none",
          }}
        ></div>
        <div
          className="verzilver-popup-white"
          style={{
            opacity: this.state.verzilverPopup ? 1 : 0,
            pointerEvents: this.state.verzilverPopup ? "all" : "none",
          }}
        >
          <CloseIcon
            className="close-hpw"
            onClick={() =>
              this.setState({ verzilverPopup: false, currentUserOfPopups: "" })
            }
          />
          <LockOpenIcon className="lock-hpw" />
          <p className="heading-hpw">VALIDEER ACTIE</p>
          <p className="desc-hpw">
            Om de actie te kunnen verzilveren, dien je gebruik te maken van een
            mobiele telefoon.
            <br />
            <br />
            Klik op Bewaren om de voucher op een later tijdstip te gebruiken.
          </p>
        </div>

        <div
          className="verzilver-popup-white-opacity"
          style={{
            opacity: this.state.verzilverPopupLastMinute ? 1 : 0,
            pointerEvents: this.state.verzilverPopupLastMinute ? "all" : "none",
          }}
        ></div>
        <div
          className="verzilver-popup-white"
          style={{
            opacity: this.state.verzilverPopupLastMinute ? 1 : 0,
            pointerEvents: this.state.verzilverPopupLastMinute ? "all" : "none",
          }}
        >
          <CloseIcon
            className="close-hpw"
            onClick={() =>
              this.setState({
                verzilverPopupLastMinute: false,
                currentUserOfPopups: "",
              })
            }
          />
          <p
            className="heading-hpw"
            style={{
              textTransform: "uppercase",
              marginBottom: 6,
              fontSize: 16,
              letterSpacing: 2,
            }}
          >
            {this.state.currentUserOfPopups.company_name}
          </p>
          <p className="heading-hpw" style={{ textTransform: "uppercase" }}>
            BEVESTIGING
          </p>
          <div
            style={{
              width: 40,
              height: 3,
              backgroundColor: "#C17F6B",
              margin: "0px auto",
              marginBottom: 25,
            }}
          ></div>
          <p className="desc-hpw">
            Hartelijk dank voor uw <br />
            boeking. Wij verheugen ons <br />
            op uw komst. <br />
            <br />
            Vergeet niet te reserveren bij ons <br />
            onder vermelding van Bhodi.
          </p>
          {this.state.currentUserOfPopups.phoneNumber && (
            <p className="heading-hpw" style={{ marginBottom: 6 }}>
              Tel: {this.state.currentUserOfPopups.phoneNumber}
            </p>
          )}
          {this.state.currentUserOfPopups.company_website && (
            <p className="heading-hpw">
              {this.state.currentUserOfPopups.company_website}
            </p>
          )}
          <button
            style={{ backgroundColor: "#C17F6B" }}
            onClick={() => {
              this.setState({
                verzilverPopupLastMinute: false,
                currentUserOfPopups: "",
              });
            }}
          >
            SLUIT VENSTER
          </button>
        </div>
        <div
          className="verzilver-popup-white-opacity"
          style={{
            opacity: this.state.verzilverPopupGreenLastMinute ? 1 : 0,
            pointerEvents: this.state.verzilverPopupGreenLastMinute
              ? "all"
              : "none",
          }}
        ></div>

        <div
          className="verzilver-popup-white-opacity"
          style={{
            opacity: this.state.verzilverPopupWebShop ? 1 : 0,
            pointerEvents: this.state.verzilverPopupWebShop ? "all" : "none",
          }}
        ></div>
        <div
          className="verzilver-popup-white"
          style={{
            opacity: this.state.verzilverPopupWebShop ? 1 : 0,
            pointerEvents: this.state.verzilverPopupWebShop ? "all" : "none",
          }}
        >
          <CloseIcon
            className="close-hpw"
            onClick={() =>
              this.setState({
                verzilverPopupWebShop: false,
                currentUserOfPopups: "",
                redeem_code: "",
              })
            }
          />
          <LockOpenIcon className="lock-hpw" />
          <p className="heading-hpw">Yes, gelukt!</p>
          <p className="desc-hpw">
            Kopieer de onderstaande kortingscode
            <br />
            en vul deze in op onze webshop. Bij het
            <br />
            afrekenen wordt deze verwerkt.
            <br />
            <br />
            Kopieer code:
          </p>
          {/* {this.state.redeem_code && <button onClick={this.copyToClipboard.bind(this, this.state.redeem_code)}>
                        {this.state.redeem_code}
                    </button>} */}
          <p>
            <b>{this.state.redeem_code}</b>
          </p>
          {this.state.redeem_code && this.state.copiedTextDone ? (
            <button
              style={{ backgroundColor: "#C17F6B" }}
              onClick={this.copyToClipboard.bind(this, this.state.redeem_code)}
            >
              Gekopieerd
            </button>
          ) : (
            this.state.redeem_code && (
              <button
                onClick={this.copyToClipboard.bind(
                  this,
                  this.state.redeem_code
                )}
              >
                Kopiëren
              </button>
            )
          )}
          {/* {this.state.redeem_code && <button onClick={this.copyToClipboard.bind(this, this.state.redeem_code)}>Kopiëren</button>}
                    {this.state.copiedTextDone && <div className='copiedstatusshower'>
                        <p style={{ opacity: this.state.copiedTextDone ? 1 : 0, pointerEvents: this.state.copiedTextDone ? "all" : "none" }}>Copied</p>
                    </div>} */}
        </div>

        <div
          className="verzilver-popup-white-opacity"
          style={{
            opacity: this.state.verzilverPopupGiveaway ? 1 : 0,
            pointerEvents: this.state.verzilverPopupGiveaway ? "all" : "none",
          }}
        ></div>
        <div
          className="verzilver-popup-white"
          style={{
            opacity: this.state.verzilverPopupGiveaway ? 1 : 0,
            pointerEvents: this.state.verzilverPopupGiveaway ? "all" : "none",
          }}
        >
          <CloseIcon
            className="close-hpw"
            onClick={() =>
              this.setState({
                verzilverPopupGiveaway: false,
                currentUserOfPopups: "",
              })
            }
          />
          <LockOpenIcon className="lock-hpw" />
          <p className="heading-hpw">VALIDEER ACTIE</p>
          <p className="desc-hpw">
            Om de actie te kunnen verzilveren, dien je gebruik te maken van een
            mobiele telefoon.
            <br />
            <br />
            Klik op Bewaren om de voucher op een later tijdstip te gebruiken.
          </p>
        </div>

        <div
          className="verzilver-popup-white-opacity"
          style={{
            opacity: this.state.verzilverPopupCharity ? 1 : 0,
            pointerEvents: this.state.verzilverPopupCharity ? "all" : "none",
          }}
        ></div>
        <div
          className="verzilver-popup-white"
          style={{
            opacity: this.state.verzilverPopupCharity ? 1 : 0,
            pointerEvents: this.state.verzilverPopupCharity ? "all" : "none",
          }}
        >
          <CloseIcon
            className="close-hpw"
            onClick={() =>
              this.setState({
                verzilverPopupCharity: false,
                currentUserOfPopups: "",
              })
            }
          />
          {/* <LockOpenIcon className="lock-hpw" /> */}
          <p
            className="heading-hpw"
            style={{
              textTransform: "uppercase",
              marginBottom: 6,
              fontSize: 16,
              letterSpacing: 2,
            }}
          >
            {this.state.currentUserOfPopups.company_name}
          </p>
          <p className="heading-hpw" style={{ textTransform: "uppercase" }}>
            BEVESTIGING
          </p>
          <div
            style={{
              width: 40,
              height: 3,
              backgroundColor: "#C17F6B",
              margin: "0px auto",
              marginBottom: 25,
            }}
          ></div>
          <p className="desc-hpw">
            Hartelijk dank voor uw <br />
            boeking. Wij verheugen ons <br />
            op uw komst. <br />
            <br />
            Vergeet niet te reserveren bij ons <br />
            onder vermelding van Bhodi.
          </p>
          {this.state.currentUserOfPopups.phoneNumber && (
            <p className="heading-hpw" style={{ marginBottom: 6 }}>
              Tel: {this.state.currentUserOfPopups.phoneNumber}
            </p>
          )}
          {this.state.currentUserOfPopups.company_website && (
            <p className="heading-hpw">
              {this.state.currentUserOfPopups.company_website}
            </p>
          )}
          <button
            style={{ backgroundColor: "#C17F6B" }}
            onClick={() => {
              this.setState({
                verzilverPopupCharity: false,
                currentUserOfPopups: "",
              });
            }}
          >
            SLUIT VENSTER
          </button>
        </div>

        {/* Order Page popups */}

        {/* Valid dialogs */}
        <Dialog
          open={this.state.validOnMobilePopup}
          onClose={() => this.setState({ validOnMobilePopup: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ zIndex: 99999999999999999 }}
          className="edit-dialog-cdd smallertitleedcdd"
        >
          <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Het is alleen mogelijk om te
              <br />
              valideren via mobiele telefoon.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setState({ validOnMobilePopup: false })}
              color="primary"
              className="close-modal-btn-edcd"
            >
              <CloseIcon />
            </Button>
          </DialogActions>
        </Dialog>

        {/* Mobile popups */}

        <div
          className="verzilver-popup-white-opacity"
          style={{
            opacity: this.state.verzilverPopupMobileGiveaway ? 1 : 0,
            pointerEvents: this.state.verzilverPopupMobileGiveaway
              ? "all"
              : "none",
          }}
        ></div>
        <div
          className="verzilver-popup-white"
          style={{
            opacity: this.state.verzilverPopupMobileGiveaway ? 1 : 0,
            pointerEvents: this.state.verzilverPopupMobileGiveaway
              ? "all"
              : "none",
          }}
        >
          <CloseIcon
            className="close-hpw"
            onClick={() =>
              this.setState({
                verzilverPopupMobileGiveaway: false,
                currentUserOfPopups: "",
              })
            }
          />
          <LockOpenIcon className="lock-hpw" />
          <p className="heading-hpw">LET OP</p>
          <p className="desc-hpw">
            na het valideren van de giveaway op locatie heb
            <br />
            je 2 minuten de tijd om de pop-up te laten zien
            <br />
            voordat deze uit je beeldscherm verdwijnt.
          </p>
          <button
            onClick={() => {
              this.interval = setInterval(() => {
                if (this.state.counterSecond > 1) {
                  this.setState({ counterSecond: --this.state.counterSecond });
                } else {
                  if (this.state.counterMinute === 1) {
                    this.setState({ counterMinute: 0, counterSecond: 59 });
                  } else {
                    clearInterval(this.interval);
                    this.setState({
                      counterMinute: 1,
                      counterSecond: 59,
                      verzilverPopupMobileAnother: false,
                    });
                  }
                }
              }, 1000);
              this.setState({
                verzilverPopupMobileGiveaway: false,
                verzilverPopupMobileAnother: true,
                isOnGiveawayVoucher: true,
                isOnKortingsVoucher: false,
              });
            }}
          >
            VALIDEREN
          </button>
        </div>

        <div
          className="verzilver-popup-white-opacity"
          style={{
            opacity: this.state.verzilverPopupMobileKortingsVoucher ? 1 : 0,
            pointerEvents: this.state.verzilverPopupMobileKortingsVoucher
              ? "all"
              : "none",
          }}
        ></div>
        <div
          className="verzilver-popup-white"
          style={{
            opacity: this.state.verzilverPopupMobileKortingsVoucher ? 1 : 0,
            pointerEvents: this.state.verzilverPopupMobileKortingsVoucher
              ? "all"
              : "none",
          }}
        >
          <CloseIcon
            className="close-hpw"
            onClick={() =>
              this.setState({
                verzilverPopupMobileKortingsVoucher: false,
                currentUserOfPopups: "",
              })
            }
          />
          <LockOpenIcon className="lock-hpw" />
          <p className="heading-hpw">
            <b>LET OP</b>
          </p>
          <p className="desc-hpw">
            Na het valideren van de kortingsvoucher
            <br />
            heb je 2 minuten de tijd om de pop-up te
            <br />
            laten zien voordat deze weer verdwijnt.
          </p>
          <button
            onClick={() => {
              this.interval = setInterval(() => {
                if (this.state.counterSecond > 1) {
                  this.setState({ counterSecond: --this.state.counterSecond });
                } else {
                  if (this.state.counterMinute === 1) {
                    this.setState({ counterMinute: 0, counterSecond: 59 });
                  } else {
                    clearInterval(this.interval);
                    this.setState({
                      counterMinute: 1,
                      counterSecond: 59,
                      verzilverPopupMobileAnother: false,
                      isOnLastMinuteOpened: false,
                    });
                  }
                }
              }, 1000);
              this.setState({
                verzilverPopupMobileKortingsVoucher: false,
                verzilverPopupMobileAnother: true,
                isOnKortingsVoucher: true,
                isOnGiveawayVoucher: false,
              });
            }}
          >
            VALIDEREN
          </button>
        </div>

        <div
          className="verzilver-popup-white-opacity"
          style={{
            opacity: this.state.verzilverPopupMobileGiveawayNew ? 1 : 0,
            pointerEvents: this.state.verzilverPopupMobileGiveawayNew
              ? "all"
              : "none",
          }}
        ></div>
        <div
          className="verzilver-popup-white"
          style={{
            opacity: this.state.verzilverPopupMobileGiveawayNew ? 1 : 0,
            pointerEvents: this.state.verzilverPopupMobileGiveawayNew
              ? "all"
              : "none",
          }}
        >
          <CloseIcon
            className="close-hpw"
            onClick={() =>
              this.setState({
                verzilverPopupMobileGiveawayNew: false,
                currentUserOfPopups: "",
              })
            }
          />
          <LockOpenIcon className="lock-hpw" />
          <p className="heading-hpw">
            <b>LET OP</b>
          </p>
          <p className="desc-hpw">
            Na het valideren van de giveaway heb je
            <br />
            2 minuten de tijd om de pop-up te laten
            <br />
            zien voordat deze weer verdwijnt.
          </p>
          <button
            onClick={() => {
              this.interval = setInterval(() => {
                if (this.state.counterSecond > 1) {
                  this.setState({ counterSecond: --this.state.counterSecond });
                } else {
                  if (this.state.counterMinute === 1) {
                    this.setState({ counterMinute: 0, counterSecond: 59 });
                  } else {
                    clearInterval(this.interval);
                    this.setState({
                      counterMinute: 1,
                      counterSecond: 59,
                      verzilverPopupMobileAnother: false,
                      isOnLastMinuteOpened: false,
                    });
                  }
                }
              }, 1000);
              this.setState({
                verzilverPopupMobileGiveawayNew: false,
                verzilverPopupMobileAnother: true,
                isOnKortingsVoucher: false,
                isOnGiveawayVoucher: true,
              });
            }}
          >
            VALIDEREN
          </button>
        </div>

        <div
          className="verzilver-popup-white-opacity"
          style={{
            opacity: this.state.verzilverPopupMobileEvents ? 1 : 0,
            pointerEvents: this.state.verzilverPopupMobileEvents
              ? "all"
              : "none",
          }}
        ></div>
        <div
          className="verzilver-popup-white"
          style={{
            opacity: this.state.verzilverPopupMobileEvents ? 1 : 0,
            pointerEvents: this.state.verzilverPopupMobileEvents
              ? "all"
              : "none",
          }}
        >
          <CloseIcon
            className="close-hpw"
            onClick={() =>
              this.setState({
                verzilverPopupMobileEvents: false,
                currentUserOfPopups: "",
              })
            }
          />
          <LockOpenIcon className="lock-hpw" />
          <p className="heading-hpw">
            <b>LET OP</b>
          </p>
          <p className="desc-hpw">
            na het valideren van het event heb je
            <br />
            2 minuten de tijd om de pop-up te
            <br />
            laten zien aan de medewerker van
            <br />
            het bedrijf.
            <br />
            <br />
            Ga je reserveren bij een restaurant?
            <br />
            Noteer dan de code en geef deze
            <br />
            door tijdens het reserveren via
            <br />
            de site of email van het restaurant.
          </p>
          <button
            onClick={() => {
              this.interval = setInterval(() => {
                if (this.state.counterSecond > 1) {
                  this.setState({ counterSecond: --this.state.counterSecond });
                } else {
                  if (this.state.counterMinute === 1) {
                    this.setState({ counterMinute: 0, counterSecond: 59 });
                  } else {
                    clearInterval(this.interval);
                    this.setState({
                      counterMinute: 1,
                      counterSecond: 59,
                      verzilverPopupMobileAnother: false,
                      isOnLastMinuteOpened: false,
                    });
                  }
                }
              }, 1000);
              this.setState({
                verzilverPopupMobileEvents: false,
                verzilverPopupMobileAnother: true,
              });
            }}
          >
            VALIDEREN
          </button>
        </div>

        <div
          className="verzilver-popup-white-opacity"
          style={{
            opacity: this.state.verzilverPopupEventNoValidate ? 1 : 0,
            pointerEvents: this.state.verzilverPopupEventNoValidate
              ? "all"
              : "none",
          }}
        ></div>
        <div
          className="verzilver-popup-white"
          style={{
            opacity: this.state.verzilverPopupEventNoValidate ? 1 : 0,
            pointerEvents: this.state.verzilverPopupEventNoValidate
              ? "all"
              : "none",
          }}
        >
          <CloseIcon
            className="close-hpw"
            onClick={() =>
              this.setState({
                verzilverPopupEventNoValidate: false,
                currentUserOfPopups: "",
              })
            }
          />
          <LockOpenIcon className="lock-hpw" />
          <p className="heading-hpw">LET OP</p>
          <p className="desc-hpw">
            Valideren kan alleen op
            <br />
            de startdag van het Event
          </p>
        </div>

        <div
          className="verzilver-popup-white-opacity"
          style={{
            opacity: this.state.verzilverPopupMobileAnother ? 1 : 0,
            pointerEvents: this.state.verzilverPopupMobileAnother
              ? "all"
              : "none",
          }}
        ></div>
        <div
          className="verzilver-popup-white"
          style={{
            opacity: this.state.verzilverPopupMobileAnother ? 1 : 0,
            pointerEvents: this.state.verzilverPopupMobileAnother
              ? "all"
              : "none",
          }}
        >
          <CloseIcon
            className="close-hpw"
            onClick={() => {
              clearInterval(this.interval);
              this.setState({
                counterMinute: 1,
                counterSecond: 59,
                verzilverPopupMobileAnother: false,
                currentUserOfPopups: "",
                isOnLastMinuteOpened: false,
                isOnKortingsVoucher: false,
                isOnGiveawayVoucher: false,
              });
            }}
          />
          <LockOpenIcon className="lock-hpw" />
          <p className="heading-hpw">Yes, gelukt!</p>
          {this.state.isOnLastMinuteOpened && (
            <p className="desc-hpw">Welkom op het event van:</p>
          )}
          {this.state.isOnKortingsVoucher && (
            <p className="desc-hpw">Je gebruikt nu de kortingsvoucher</p>
          )}
          {this.state.isOnGiveawayVoucher && (
            <p className="desc-hpw">Je gebruikt nu de giveaway</p>
          )}
          {!this.state.isOnLastMinuteOpened && (
            <p className="desc-hpw">{this.state.currentActie.title}</p>
          )}
          <p className="desc-hpw">
            {!this.state.isOnLastMinuteOpened && "van"}{" "}
            {this.state.currentUserOfPopups.company_name}
          </p>
          <button>
            <TimerIcon /> {this.state.counterMinute}:{this.state.counterSecond}
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducers.user,
    global_campaigns: state.campaignsReducer.global_campaigns,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: (user) => dispatch(updateUser(user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RightMenuOrders);
