import React, { Component } from 'react'
import { connect } from 'react-redux';
import firebase from "../../../Config/Firebase"
import {
    updateAdminOrNot,
    updateGlobalStuff,
    updateAlbumToAdd,
} from "../../../Redux/actions/authActions";
import EventsNewSection from '../../../Components/EventsNewSection';
import Footer from '../../../Components/Footer';
import FooterGreen from '../../../Components/FooterGreen';
import "./index.css"
import GuestCompanyNavbar from '../../../Components/GuestCompanyNavbar';

export class CompanyCampaignGuest extends Component {
    constructor() {
        super()
        this.state = {
            alreadyVisitedOnce: false,
            linkAlreadyUsed: false,

            isLoading: true,
            maxTime: "",

            currentCompanyEvent: "",
            currentCompany: "",
            accessCode: "",

            accessCodeUser: "",
            accessCodeUserEmpty: false,
            showCampaignInfo: false,
        }
    }

    componentDidMount() {
        let company_id = this.props.company_id
        console.log(company_id, '/see here')

        firebase
            .database()
            .ref("users/" + company_id)
            .once("value", (data) => {
                if (data.val()) {
                    let companyData = data.val()

                    this.getCampaignData(companyData)
                }
            });
    }

    async getCampaignData(currentUser) {
        let campaignDataRef = await firebase.database().ref(`bhodi_campaigns/${currentUser.user_id}`).orderByChild("campaignType").equalTo("local_event").once("value")
        let campaignsData = await campaignDataRef.val()

        let companyCampaigns = []
        if (campaignsData) {
            Object.entries(campaignsData).map((campaignInfo) => {
                companyCampaigns.push(campaignInfo[1])
            })
        }

        this.setState({ currentCompanyEvent: companyCampaigns[0], currentCompany: currentUser, isLoading: false })
    }

    render() {
        return (
            this.state.isLoading ? (<div
                className="global-event-cart globaleventloaderpage"
                style={{
                    height: "auto",
                    top: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: 999999999,
                }}
            >
                <div className="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                {window.innerWidth > 1008 && <Footer notOnMainPage={true} />}
                {window.innerWidth < 1008 && <FooterGreen notOnMainPage={true} />}
            </div>) : (this.state.currentCompanyEvent) && (<div className='company-campaign-guest'>
                <GuestCompanyNavbar
                    currentCompany={this.state.currentCompany}
                />
                {(this.state.currentCompanyEvent) && (<div className='top-events-section'>
                    <p>Event</p>
                    <p>{this.state.currentCompany.company_name}</p>
                </div>)}
                <EventsNewSection
                    campaignToShow={[this.state.currentCompanyEvent]}
                    showQrCode={true}
                    currentCompany={this.state.currentCompany}
                    accessCode={this.state.accessCode}
                    onEditOpen={() => {
                        document.querySelector("html").style.overflowY = "hidden"
                        this.setState({
                            openAddPopup: true,
                            doEditLocalEvent: true,
                        });
                    }}
                />
                {window.innerWidth > 1008 && <Footer notOnMainPage={true} />}
                {window.innerWidth < 1008 && <FooterGreen notOnMainPage={true} />}
            </div>)
        )
    }
}


const mapStateToProps = (state) => {
    return {
        global_values: state.globalReducers.global_values,
        user: state.authReducers.user,
        admin_boolean: state.globalReducers.admin_boolean,
        globalsectors: state.categoriesReducer.globalsectors,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateAdminOrNot: (admin_boolean) => dispatch(updateAdminOrNot(admin_boolean)),
        updateGlobalStuff: (global_values) => dispatch(updateGlobalStuff(global_values)),
        updateAlbumToAdd: (albumToAdd) => dispatch(updateAlbumToAdd(albumToAdd)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompanyCampaignGuest);