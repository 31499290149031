import React, { Component } from "react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import "react-sortable-tree/style.css";
import firebase from "../Config/Firebase";
import PublishIcon from "@material-ui/icons/Publish";
import CloseIcon from "@material-ui/icons/Close";
import swal from "sweetalert";
import imageCompression from "browser-image-compression";
import DeleteIcon from "@material-ui/icons/Delete";
import AdminNavbar from "./AdminNavbar";

export class StockImagesUpload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      treeData: [],
      sectorModal: false,
      sectorModalCategory: false,
      sectorName: "",
      sectors: [],
      currentSector: "",
      currentSectorId: "",
      categoryName: "",
      currentSector: "",
      categoryId: "",
      all_categories: [],
      sortingTab: true,
      adjustingTab: false,
      completeMainTitles: [],
      arrOfInputs: [],
      uploadedItems: 0,
      filesName: [],
      dropFile: [],
      allImagesCurrent: [],
    };
  }

  componentDidMount() {
    const loaderId = document.getElementById("main-page-loader-id");
    loaderId.style.display = "none";
    let promiseFirst = new Promise((res, rej) => {
      firebase
        .database()
        .ref("all_sectors")
        .on("value", (data) => {
          let a = data.val();
          let arr = [];
          if (a) {
            let objected = Object.entries(a);
            objected.map((g) => {
              arr.push(g[1]);
            });
            if (arr.length > 0) {
              if (this.state.currentSectorId) {
                this.setState({ sectors: arr });
              } else {
                this.setState({
                  sectors: arr,
                  currentSector: arr[0],
                  currentSectorId: arr[0].sector_id,
                });
              }
            }
          } else {
            this.setState({ sectors: [] });
          }
          res();
        });
    });
    promiseFirst.then(() => {
      firebase
        .database()
        .ref("stock_images/" + this.state.currentSectorId)
        .on("value", (data) => {
          let a = data.val();
          let arr = [];
          if (a) {
            let objected = Object.entries(a);
            objected.map((y) => {
              arr.push(y[1]);
            });
            this.setState({ allImagesCurrent: arr });
          } else {
            this.setState({ allImagesCurrent: [] });
          }
        });
    });
  }

  // Random name creator for pushing data in firebase storage
  makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  async fileUploadFirst(e) {
    let file = e.target;
    let filesName = this.state.filesName;
    let dropFile = this.state.dropFile;
    for (let i = 0; i < file.files.length; i++) {
      if (
        file.files[i].type.match(/image.*/) ||
        file.files[i].type.match(/video.*/)
      ) {
        let obj = {
          fileName: file.files[i].name,
          i,
        };
        dropFile.push(file.files[i]);
        filesName.push(obj);
        this.setState({
          filesName,
          dropFile,
          isRemoving: true,
          doingDrag: false,
          drag: true,
        });
      }
    }
    this.setState({ dropFile, filesName, loader: true });
    let fileNewDrop = dropFile;
    this.setState({
      activate: false,
    });
    for (let i = 0; i < fileNewDrop.length; i++) {
      if (fileNewDrop[i].type.match(/image.*/)) {
        this.setState({
          nothing: false,
          textDrag: "uploading",
          loader: true,
          fileUploading: false,
          drag: false,
          textDrag: "Upload",
          isRemoving: false,
        });
        let options = {
          maxSizeMB: 5,
        };
        imageCompression(fileNewDrop[i], options)
          .then((compressedFile) => {
            firebase
              .storage()
              .ref()
              .child(
                `stockimages/${compressedFile.name}${new Date().getTime()}`
              )
              .put(compressedFile)
              .then((snapshot) => {
                snapshot.ref.getDownloadURL().then((snapUrl) => {
                  let optionss = {
                    maxSizeMB: 1,
                    maxWidthOrHeight: 800,
                  };
                  imageCompression(fileNewDrop[i], optionss).then(
                    (compressedFiler) => {
                      firebase
                        .storage()
                        .ref()
                        .child(`stockimages/${this.makeid(8)}`)
                        .put(compressedFiler)
                        .then((snapshot) => {
                          snapshot.ref.getDownloadURL().then((resizedUrl) => {
                            let push = firebase
                              .database()
                              .ref("stock_images/" + this.state.currentSectorId)
                              .push().key;
                            let today = new Date();
                            let date =
                              today.getFullYear() +
                              "-" +
                              (today.getMonth() + 1) +
                              "-" +
                              today.getDate();
                            let time =
                              today.getHours() +
                              ":" +
                              today.getMinutes() +
                              ":" +
                              today.getSeconds();
                            let timestamp = Math.floor(Date.now() / 100);
                            let obj = {
                              push,
                              snapUrl,
                              type: "image",
                              resizedUrl,
                              date,
                              time,
                              timestamp,
                            };
                            firebase
                              .database()
                              .ref(
                                "stock_images/" +
                                this.state.currentSectorId +
                                "/" +
                                push
                              )
                              .set(obj)
                              .then(() => {
                                this.setState({
                                  uploadedItems: this.state.uploadedItems + 1,
                                });
                                if (
                                  this.state.uploadedItems ===
                                  this.state.filesName.length
                                ) {
                                  swal({
                                    title: "Good job!",
                                    text: "Om je toegevoegde afbeeldingen terug te zien, kun je eenvoudig filteren op je geselecteerde albums",
                                    icon: "success",
                                  }).then(() => {
                                    this.setState({
                                      checkedAlbumsForPost:
                                        this.state.checkedAlbumsForPost,
                                      loader: false,
                                      fileUploading: false,
                                      drag: false,
                                      dropFile: [],
                                      filesName: [],
                                      textDrag: "Upload",
                                      uploadedItems: 0,
                                      isRemoving: false,
                                      selectedAlbums: [],
                                      showForAlbumEmpty: false,
                                    });
                                  });
                                }
                              })
                              .catch((err) => {
                                console.log(err);
                              });
                          });
                        });
                    }
                  );
                });
              });
          })
          .catch(function (error) {
            console.log(error.message);
          });
      }
    }
  }

  render() {
    return (
      <div className="sorting-mainadmin">
        {this.state.loader && (
          <div className="loader-area">
            <CloseIcon onClick={() => window.location.reload()} />
            <div className="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <p style={{ color: "white" }}>
              Uploading Media {this.state.uploadedItems} /{" "}
              {this.state.filesName.length}
            </p>
          </div>
        )}
        <AdminNavbar stockImages={true} />
        <div className="container-sma containerstockimagesupload">
          <div className="top-container-sma">
            <div className="sector-tcsma">
              <select
                onChange={(e) => {
                  firebase
                    .database()
                    .ref("stock_images/" + JSON.parse(e.target.value).sector_id)
                    .on("value", (data) => {
                      let a = data.val();
                      let arr = [];
                      if (a) {
                        let objected = Object.entries(a);
                        objected.map((y) => {
                          arr.push(y[1]);
                        });
                        this.setState({ allImagesCurrent: arr });
                      } else {
                        this.setState({ allImagesCurrent: [] });
                      }
                    });
                  this.setState({
                    currentSector: JSON.parse(e.target.value),
                    currentSectorId: JSON.parse(e.target.value).sector_id,
                  });
                }}
              >
                {this.state.sectors.length > 0 &&
                  this.state.sectors.map((g) => {
                    return (
                      <option
                        value={JSON.stringify(g)}
                        defaultChecked={
                          this.state.currentSectorId === g.sector_id
                        }
                      >
                        {g.sector_name}
                      </option>
                    );
                  })}
              </select>
              <ArrowDropDownIcon className="dropdown-select-tcsma" />
            </div>
            <div className="category-tcsma categorystockimageuploader">
              <input
                type="file"
                className="inputfilemob"
                accept="image/*"
                multiple={true}
                onChange={(e) => this.fileUploadFirst(e)}
              />
              <PublishIcon />
            </div>
          </div>
          {this.state.allImagesCurrent.length > 0 && (
            <div className="all-images-stock-uploader">
              {this.state.allImagesCurrent.map((y) => {
                return (
                  <div
                    className="imagecontainerstockuploader"
                    style={{ backgroundImage: `url("${y.resizedUrl}")` }}
                  >
                    {/* <img src={y.resizedUrl} /> */}
                    <div className="delete-icsu">
                      <DeleteIcon
                        onClick={() => {
                          firebase
                            .database()
                            .ref("bhodi_campaigns")
                            .once("value", (data) => {
                              let a = data.val();
                              let arr = [];
                              let replacedImages = 0;
                              if (a) {
                                let objected = Object.entries(a);
                                let arrSecCheck = [];
                                objected.map((i) => {
                                  arrSecCheck.push(i[1]);
                                });
                                arrSecCheck.map((y) => {
                                  let objected = Object.entries(y);
                                  objected.map((i) => {
                                    arr.push(i);
                                  });
                                });
                                let arrComplete = [];
                                arr.map((g) => {
                                  arrComplete.push(g[1]);
                                });
                                let filtered = arrComplete.filter((u) => {
                                  return u.image === y.snapUrl;
                                });
                                if (filtered.length > 0) {
                                  filtered.map(async (t) => {
                                    await firebase
                                      .database()
                                      .ref(
                                        "bhodi_campaigns/" +
                                        t.created_by +
                                        "/" +
                                        t.pushKey +
                                        "/image"
                                      )
                                      .set(
                                        "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/placeholder.jpg?alt=media&token=53d59535-5c5d-4617-91c9-cc84ecf766f8"
                                      )
                                      .then(() => {
                                        replacedImages = replacedImages + 1;
                                      });
                                    if (filtered.length === replacedImages) {
                                      firebase
                                        .database()
                                        .ref(
                                          "stock_images/" +
                                          this.state.currentSectorId +
                                          "/" +
                                          y.push
                                        )
                                        .remove();
                                    }
                                  });
                                } else {
                                  firebase
                                    .database()
                                    .ref(
                                      "stock_images/" +
                                      this.state.currentSectorId +
                                      "/" +
                                      y.push
                                    )
                                    .remove();
                                }
                              } else {
                                firebase
                                  .database()
                                  .ref(
                                    "stock_images/" +
                                    this.state.currentSectorId +
                                    "/" +
                                    y.push
                                  )
                                  .remove();
                              }
                            });
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default StockImagesUpload;
