import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./rootReducers";
import thunk from "redux-thunk";
// import thunk from 'redux-thunk'
import { persistStore, persistReducer } from "redux-persist";
import { createInjectStore } from "redux-reducers-injector";
import storage from "redux-persist/lib/storage";
import localforage from "localforage";
import loggerMiddleware from "./middleware/logger";
import monitorReducerEnhancer from "./enhancers/monitorReducer";
import reduxReset from "redux-reset";

// import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2'

const persistConfig = {
  key: "root",
  storage: localforage,
  // timeout: 0,
  // debounce: 500,
  // stateReconciler: autoMergeLevel2,
  // blacklist: ["get_all_products", "get_all_vouchers", "get_all_global_vouchers", "get_all_global_products", "all_shop_products", "all_vouchers", "all_vouchers_sc", "all_shop_sc", "update_main_category_shop", "update_sub_category_shop", "update_bewaared_campaigns", "update_current_exclusives", "update_friends_exclusives", "update_selected_exclusive", "update_all_exclusives", "update_selected_exclusive_category", "update_selected_exclusive_friends", "update_selected_exclusive_category_friends", "update_all_products_new"]
};

// const middlewareEnhancer = applyMiddleware(loggerMiddleware, thunkMiddleware)
// const composedEnhancers = compose(middlewareEnhancer, monitorReducerEnhancer)

// const enhanceCreateStore = compose(applyMiddleware(thunk))(createStore)

const persistedReducer = persistReducer(persistConfig, rootReducer);
// const store = enhanceCreateStore(persistedReducer)
// export const store = createInjectStore(persistedReducer, applyMiddleware(thunk))

export const store = createStore(
  persistedReducer,
  {},
  compose(applyMiddleware(thunk), reduxReset())
);

export const persistor = persistStore(store);

// console.log(persistor, '/persistor')

export default () => {
  return {
    store,
    persistor,
  };
};

// export {
//     store,
//     persistor
// }
