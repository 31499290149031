import React, { Component } from "react";
import firebase from "../Config/Firebase";
import CloseIcon from "@material-ui/icons/Close";
import Switch from "@material-ui/core/Switch";
import AddIcon from "@material-ui/icons/Add";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import { connect } from "react-redux";
import {
  updateSignUpFieldsFilled,
  updateGuestUserCart,
  updateGuestUserCartTime,
  updateGuestUserCartExtendOpportunity,
  updateBhodiFaqs,
  updateBhodiAbouts,
} from "../Redux/actions/authActions";
import DeleteIcon from "@material-ui/icons/Delete";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import RemoveIcon from "@material-ui/icons/Remove";
import PublishIcon from "@material-ui/icons/Publish";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import FooterGreen from "./FooterGreen";
import RightMenuOrders from "./RightMenuOrders";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { BiChevronRight } from "react-icons/bi";
import { BsCheck2 } from "react-icons/bs";
import swal from "sweetalert";
import MySubcribedExclusives from "./MySubcribedExclusives";
import RightMenuIncomingOrders from "./RightMenuIncomingOrders";
import { checkForSubscribedExclusives } from "../Functions/checkForSubscribedExclusives";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
const axios = require("axios");

const bcrypt = require("bcryptjs");
const saltRounds = 10;

export class SettingsSeparate extends Component {
  constructor() {
    super();
    this.state = {
      active: false,
      search: false,
      searchText: "",
      rightModalSettings: false,
      arrForSearch: [],
      checkedPrivate: false,
      checkedHideDonation: false,
      checkedHideFriendsInfo: false,
      checkedHideProfileImage: false,
      checkedHideProfileInfo: false,
      showPostsActive: true,
      showItemsDiv: false,
      showItems: false,
      showLists: true,
      showFriends: false,
      showProductsOwn: false,
      showFriendstab: false,
      showProductsBookmarked: false,
      showBookmarkTab: false,
      showProductsOthertab: false,
      memberProducts: [],
      paymentEmail: "",
      nationality_country_code: "",
      phone_number_details_country_code: "",
      phone_number_national_number: "",
      business_address_line1: "",
      business_address_state: "",
      business_address_city: "",
      business_address_country_code: "",
      business_address_postal_code: "",
      bussiness_legal_name: "",
      business_description: "",
      website_urls: "",
      phone_type: "",
      given_name: "",
      surname: "",
      preferred_language_code: "",
      primary_currency_code: "",
      currentUserMember: "",
      userInfoRight: true,
      typeOfOrder: "incoming",
      showCartOrder: true,
      impressiePhotosWork: [],
      savtBtnText: "SAVE",
      listsOpacity: 1,
      listsPointers: "all",
      isDesktop: true,
      isMenuOpen: false,
      isScrolled: false,
      menuItemsModals: true,
      deliverTextOne: "",
      deliverTextTwo: "",
      productsBackColor: "#F5F5F5",
      lengthOfOtherProducts: [],
      lengthOfOtherVouchers: [],
      lengthOfOtherCampaigns: [],
      voucherSettings: false,
      useOfVoucher: "",
      useOfVoucherCharity: "",
      useOfVoucherGiveaway: "",
      useOfVoucherDoorlopende: "",
      useOfVoucherLocalEvent: "",
      useOfVoucherKortingsVoucher: "",
      useOfVoucherWebShop: "",
      voucherExceptions: "",
      termsText: "",
      hideMenuShop: false,
      hideMenuVouchers: false,
      arrCurrentProducts: [],
      lengthOfCurrentVouhers: [],
      currentUser: "",
      hideMenuContent: false,
      lengthOfCurrentContents: [],
      lengthOfOtherContents: [],
      showContentTab: false,
      kvkCompany: "",
      bhodiFaqs: [],
      arrDropdowns: [],
      bhodiAbouts: [],
      arrDropdownsAbouts: [],
      currentUserId: "",
      orderidToUpload: 0,
      currentUserOfPopups: "",
      redeem_code: "",
      guestUserCart: [],
      currentUserHere: "",
      deleteBtnText: "SAVE",
      showDelete: false,
      password: "",
      showPassword: false,
      confirmPassword: "",
      showconfirmPassword: false,
      currentPassword: "",
      showcurrentPassword: false,
      profileActive: true,
      passwordForDel: "",
      expireTypeVoucher: "",
      expireDateVoucher: "",
    };
  }

  componentWillReceiveProps(e) {
    if (e.fillUpAddress) {
      let element = document.getElementById("footermain");
      if (element) {
        element.style.display = "none";
      }
      this.setState({ rightModalSettings: true });
      if (window.innerWidth < 1009) {
        this.setState({ userInfoRight: false, menuItemsModals: true });
      }
    }
  }

  componentDidMount() {
    const loaderId = document.getElementById("main-page-loader-id");
    loaderId.style.display = "none";
    let userid = localStorage.getItem("userid");
    this.setState({ currentUserId: userid });

    this.setState({ rightModalSettings: true });
    if (window.innerWidth < 1009) {
      document.getElementById("root").style.position = "fixed";
      document.getElementById("root").style.overflow = "hidden";
      this.setState({ menuItemsModals: true });
    }

    let settings_id = "";

    if (this.props.fromMainPage) {
      settings_id = this.props.type;
    } else {
      settings_id = this.props.match.params.settings_id;
    }

    if (settings_id === "payment_settings") {
      this.setState({
        paymentSettingsRight: true,
        userInfoRight: false,
        menuItemsModals: false,
      });
    } else if (settings_id === "mijn_gegevens") {
      this.setState({
        userInfoRight: true,
        privacyRight: false,
        emailPassword: false,
        showMijnAbonnementen: false,
        shopSettings: false,
        myOrdersRight: false,
        allOrdersRight: false,
        menuItemsModals: false,
        shopSettings: false,
        shopSettings: false,
        voucherSettings: false,
        showSubscribedContent: false,
        showBhodiFaqs: false,
        showBhodiAbouts: false,
      });
    } else if (settings_id === "privacy") {
      this.setState({
        privacyRight: true,
        userInfoRight: false,
        emailPassword: false,
        shopSettings: false,
        myOrdersRight: false,
        allOrdersRight: false,
        showMijnAbonnementen: false,
        menuItemsModals: false,
        shopSettings: false,
        shopSettings: false,
        voucherSettings: false,
        showSubscribedContent: false,
        showBhodiFaqs: false,
        showBhodiAbouts: false,
      });
    } else if (settings_id === "inloggegevens") {
      this.setState({
        emailPassword: true,
        privacyRight: false,
        userInfoRight: false,
        shopSettings: false,
        myOrdersRight: false,
        allOrdersRight: false,
        menuItemsModals: false,
        showMijnAbonnementen: false,
        shopSettings: false,
        shopSettings: false,
        voucherSettings: false,
        showSubscribedContent: false,
        showBhodiFaqs: false,
        showBhodiAbouts: false,
      });
    } else if (settings_id === "my_orders") {
      this.setState({
        myOrdersRight: true,
        shopSettings: false,
        privacyRight: false,
        emailPassword: false,
        userInfoRight: false,
        allOrdersRight: false,
        menuItemsModals: false,
        shopSettings: false,
        shopSettings: false,
        showMijnAbonnementen: false,
        voucherSettings: false,
        showSubscribedContent: false,
        showBhodiFaqs: false,
        showBhodiAbouts: false,
      });
    } else if (settings_id === "ongoing_orders") {
      this.setState({
        myOrdersRight: false,
        shopSettings: false,
        privacyRight: false,
        emailPassword: false,
        userInfoRight: false,
        allOrdersRight: true,
        menuItemsModals: false,
        shopSettings: false,
        shopSettings: false,
        voucherSettings: false,
        showSubscribedContent: false,
        showBhodiFaqs: false,
        showBhodiAbouts: false,
        showMijnAbonnementen: false,
      });
    } else if (settings_id === "voucher_settings") {
      this.setState({
        myOrdersRight: false,
        privacyRight: false,
        emailPassword: false,
        userInfoRight: false,
        allOrdersRight: false,
        menuItemsModals: false,
        shopSettings: false,
        voucherSettings: true,
        shopSettings: false,
        showSubscribedContent: false,
        showBhodiFaqs: false,
        showBhodiAbouts: false,
        showMijnAbonnementen: false,
      });
    } else if (settings_id === "shop_settings") {
      this.setState({
        myOrdersRight: false,
        privacyRight: false,
        emailPassword: false,
        userInfoRight: false,
        allOrdersRight: false,
        menuItemsModals: false,
        shopSettings: false,
        voucherSettings: false,
        shopSettings: true,
        showSubscribedContent: false,
        showBhodiFaqs: false,
        showBhodiAbouts: false,
        showMijnAbonnementen: false,
      });
    } else if (settings_id === "faqs") {
      this.setState({
        myOrdersRight: false,
        privacyRight: false,
        emailPassword: false,
        userInfoRight: false,
        allOrdersRight: false,
        menuItemsModals: false,
        shopSettings: false,
        voucherSettings: false,
        shopSettings: false,
        showBhodiFaqs: true,
        showSubscribedContent: false,
        showBhodiAbouts: false,
        showMijnAbonnementen: false,
      });
    } else if (settings_id === "abouts") {
      this.setState({
        myOrdersRight: false,
        privacyRight: false,
        emailPassword: false,
        userInfoRight: false,
        allOrdersRight: false,
        menuItemsModals: false,
        shopSettings: false,
        voucherSettings: false,
        shopSettings: false,
        showBhodiAbouts: true,
        showBhodiFaqs: false,
        showSubscribedContent: false,
        showMijnAbonnementen: false,
      });
    } else if (settings_id === "mijn_abonnementen") {
      this.setState({
        myOrdersRight: false,
        privacyRight: false,
        emailPassword: false,
        userInfoRight: false,
        allOrdersRight: false,
        menuItemsModals: false,
        shopSettings: false,
        voucherSettings: false,
        shopSettings: false,
        showMijnAbonnementen: true,
        showBhodiAbouts: false,
        showBhodiFaqs: false,
        showSubscribedContent: false,
      });
    }

    if (window.innerWidth > 1009) {
      this.setState({
        listsOpacity: 1,
        listsPointers: "all",
        isDesktop: true,
      });
    } else {
      this.setState({
        listsOpacity: 1,
        listsPointers: "all",
        isDesktop: false,
      });
      window.onscroll = () => {
        if (window.scrollY === 0) {
          this.setState({ isScrolled: false });
        } else {
          this.setState({ isScrolled: true });
        }
      };
    }

    console.log(this.props, "/check for props");

    if (this.props.user) {
      if (this.props.user.length > 0) {
        let filter = this.props.user.filter((u) => {
          return u.user_id === userid;
        });
        let filterCurrentHere = this.props.user.filter((u) => {
          return u.user_id === userid;
        });
        this.setState({ currentUserHere: filterCurrentHere[0] });
        let filteredImpressie = [];
        if (this.props.all_impressies) {
          if (this.props.all_impressies.length > 0)
            filteredImpressie = this.props.all_impressies.filter((u) => {
              return u[0] === filter[0].user_id;
            });
        }
        this.setState({ impressiePhotosWork: filteredImpressie });
        let currentData = filter[0];
        if (filter.length > 0) {
          if (currentData) {
            let a = currentData;
            this.setState({
              currentUserMember: a,
              currentUser: a,
              create_subscription: a.create_subscription,
              userid: userid,
              url: a.user_name_id,
              firstName: a.first_name,
              lastName: a.last_name,
              city: a.city,
              zipCode: a.zipCode,
              street: a.street,
              houseNumber: a.houseNumber,
              country: a.country,
              phoneNumber: a.phoneNumber,
              bankName: a.bankName,
              bankAccountNumber: a.bankAccountNumber,
              email: a.email,
              companyName: a.company_name,
              kvkCompany: a.kvkCompany,
              company_website: a.company_website,
              companyEmail: a.email,
              profileImageUrl: a.profile_image,
              description: a.user_description,
              currentUserId: userid,
              paymentEmail: a.paymentEmail,
              nationality_country_code: a.nationality_country_code,
              phone_number_details_country_code:
                a.phone_number_details_country_code,
              phone_number_national_number: a.phone_number_national_number,
              business_address_line1: a.business_address_line1,
              business_address_state: a.business_address_state,
              business_address_city: a.business_address_city,
              business_address_country_code: a.business_address_country_code,
              business_address_postal_code: a.business_address_postal_code,
              bussiness_legal_name: a.bussiness_legal_name,
              business_description: a.business_description,
              website_urls: a.website_urls,
              phone_type: a.phone_type,
              given_name: a.given_name,
              surname: a.surname,
              preferred_language_code: a.preferred_language_code,
              primary_currency_code: a.primary_currency_code,
              deliverTextOne: a.delivery_text_one,
              deliverTextTwo: a.delivery_text_two,
              productsBackColor: a.product_background_color,
            });
            if (typeof a.hideFriendsInfo === "boolean") {
              this.setState({
                checkedHideDonation: a.hideDonation,
                checkedHideFriendsInfo: a.hideFriendsInfo,
                checkedHideProfileImage: a.hideProfileImage,
                checkedHideProfileInfo: a.hideProfileInfo,
                checkedHideGlobalProduct: a.hideGlobalProducts,
              });
            }
            if (a.profileActive) {
              this.setState({ profileActive: true })
            } else {
              this.setState({ profileActive: false })
            }
            if (a.hideFriendsInfo) {
              this.setState({ hideFriendsInfo: a.hideFriendsInfo });
            } else {
              this.setState({ hideFriendsInfo: false });
            }
            if (!a.profile_image) {
              a.profile_image =
                "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9";
            }
            if (a.accountType === "global") {
              this.setState({ checkedPrivate: false });
            } else {
              this.setState({ checkedPrivate: true });
            }
            if (a.useOfVoucherLocalEvent) {
              this.setState({
                useOfVoucherLocalEvent: a.useOfVoucherLocalEvent,
              });
            }
            if (a.useOfVoucherKortingsVoucher) {
              this.setState({
                useOfVoucherKortingsVoucher: a.useOfVoucherKortingsVoucher,
              });
            }
            if (a.voucherExceptions) {
              this.setState({ voucherExceptions: a.voucherExceptions });
            }
            if (a.termsText) {
              this.setState({ termsText: a.termsText });
            }
            if (a.expireTypeVoucher) {
              this.setState({ expireTypeVoucher: a.expireTypeVoucher });
            }
            if (a.expireDateVoucher) {
              this.setState({ expireDateVoucher: a.expireDateVoucher });
            }
            if (a.subscription) {
              if (a.subscription.status === "active") {
                this.setState({ showDelete: false });
              } else {
                this.setState({ showDelete: true });
              }
            } else {
              if (checkForSubscribedExclusives(a)) {
                this.setState({ showDelete: false })
              } else {
                this.setState({ showDelete: true });
              }
            }
            if (a.user_id === userid) {
              let email = a.email;
              let password = a.pass;
              console.log(email, "/working");
              console.log(password, "/working");
              var user = firebase.auth().currentUser;
              console.log(user, "/current user");
              // var credential = firebase.auth.EmailAuthProvider.credential(
              //     email,
              //     password
              // );;
              // user.reauthenticateWithCredential(credential)
            }
          }
        }
      }
    }

    firebase
      .database()
      .ref("users")
      .on("value", (data) => {
        let a = data.val();
        let objected = Object.entries(a);
        objected.map((y) => {
          if (y[1].user_id === userid) {
            let a = y[1];
            this.setState({
              currentUserMember: a,
              currentUser: a,
              create_subscription: a.create_subscription,
              userid: userid,
              url: a.user_name_id,
              firstName: a.first_name,
              lastName: a.last_name,
              city: a.city,
              zipCode: a.zipCode,
              street: a.street,
              houseNumber: a.houseNumber,
              country: a.country,
              phoneNumber: a.phoneNumber,
              bankName: a.bankName,
              bankAccountNumber: a.bankAccountNumber,
              email: a.email,
              companyName: a.company_name,
              kvkCompany: a.kvkCompany,
              company_website: a.company_website,
              companyEmail: a.email,
              profileImageUrl: a.profile_image,
              description: a.user_description,
              currentUserId: userid,
              paymentEmail: a.paymentEmail,
              nationality_country_code: a.nationality_country_code,
              phone_number_details_country_code:
                a.phone_number_details_country_code,
              phone_number_national_number: a.phone_number_national_number,
              business_address_line1: a.business_address_line1,
              business_address_state: a.business_address_state,
              business_address_city: a.business_address_city,
              business_address_country_code: a.business_address_country_code,
              business_address_postal_code: a.business_address_postal_code,
              bussiness_legal_name: a.bussiness_legal_name,
              business_description: a.business_description,
              website_urls: a.website_urls,
              phone_type: a.phone_type,
              given_name: a.given_name,
              surname: a.surname,
              preferred_language_code: a.preferred_language_code,
              primary_currency_code: a.primary_currency_code,
              deliverTextOne: a.delivery_text_one,
              deliverTextTwo: a.delivery_text_two,
              productsBackColor: a.product_background_color,
            });
            if (a.profileActive) {
              this.setState({ profileActive: true })
            } else {
              this.setState({ profileActive: false })
            }
            if (typeof a.hideFriendsInfo === "boolean") {
              this.setState({
                checkedHideDonation: a.hideDonation,
                checkedHideFriendsInfo: a.hideFriendsInfo,
                checkedHideProfileImage: a.hideProfileImage,
                checkedHideProfileInfo: a.hideProfileInfo,
                checkedHideGlobalProduct: a.hideGlobalProducts,
              });
            }
            if (a.hideFriendsInfo) {
              this.setState({ hideFriendsInfo: a.hideFriendsInfo });
            } else {
              this.setState({ hideFriendsInfo: false });
            }
            if (!a.profile_image) {
              a.profile_image =
                "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9";
            }
            if (a.accountType === "global") {
              this.setState({ checkedPrivate: false });
            } else {
              this.setState({ checkedPrivate: true });
            }
            if (a.useOfVoucherLocalEvent) {
              this.setState({
                useOfVoucherLocalEvent: a.useOfVoucherLocalEvent,
              });
            }
            if (a.useOfVoucherKortingsVoucher) {
              this.setState({
                useOfVoucherKortingsVoucher: a.useOfVoucherKortingsVoucher,
              });
            }
            if (a.voucherExceptions) {
              this.setState({ voucherExceptions: a.voucherExceptions });
            }
            if (a.termsText) {
              this.setState({ termsText: a.termsText });
            }
            if (a.expireTypeVoucher) {
              this.setState({ expireTypeVoucher: a.expireTypeVoucher });
            }
            if (a.expireDateVoucher) {
              this.setState({ expireDateVoucher: a.expireDateVoucher });
            }
            // if (a.user_id === userid) {
            //     let email = a.email
            //     let password = a.pass
            //     var user = firebase.auth().currentUser;
            //     var credential = firebase.auth.EmailAuthProvider.credential(
            //         email,
            //         password
            //     );;
            //     user.reauthenticateWithCredential(credential)
            // }
            if (a.subscription) {
              if (a.subscription.status === "active") {
                this.setState({ showDelete: false });
              } else {
                this.setState({ showDelete: true });
              }
            } else {
              if (checkForSubscribedExclusives(a)) {
                this.setState({ showDelete: false })
              } else {
                this.setState({ showDelete: true });
              }
            }
          }
          if (y[1].user_id === userid) {
            let a = y[1];
            this.setState({ currentUserHere: a });
          }
        });
      });

    if (this.props.bhodi_faqs) {
      let a = this.props.bhodi_faqs;
      if (a) {
        let arrSec = a;
        let arrDropdowns = [];
        arrSec.map(() => {
          let obj = {
            opened: false,
          };
          arrDropdowns.push(obj);
        });
        this.setState({ bhodiFaqs: arrSec, arrDropdowns });
      } else {
        this.setState({ bhodiFaqs: [], arrDropdowns: [] });
      }
    }
    if (this.props.bhodi_abouts) {
      let a = this.props.bhodi_abouts;
      if (a) {
        let arrSec = a;
        let arrDropdownsAbouts = [];
        arrSec.map(() => {
          let obj = {
            opened: false,
          };
          arrDropdownsAbouts.push(obj);
        });
        this.setState({ bhodiAbouts: arrSec, arrDropdownsAbouts });
      } else {
        this.setState({ bhodiAbouts: [], arrDropdownsAbouts: [] });
      }
    }

    firebase
      .database()
      .ref("bhodi_faqs")
      .on("value", (data) => {
        let a = data.val();
        let arrSec = [];
        if (a) {
          arrSec = a;
          let arrDropdowns = [];
          arrSec.map(() => {
            let obj = {
              opened: false,
            };
            arrDropdowns.push(obj);
          });
          this.setState({ bhodiFaqs: arrSec, arrDropdowns });
        } else {
          this.setState({ bhodiFaqs: [], arrDropdowns: [] });
        }
        this.props.updateBhodiFaqs(arrSec)
      });

    firebase
      .database()
      .ref("bhodi_abouts")
      .on("value", (data) => {
        let a = data.val();
        let arrSec = [];
        if (a) {
          arrSec = a;
          let arrDropdownsAbouts = [];
          arrSec.map(() => {
            let obj = {
              opened: false,
            };
            arrDropdownsAbouts.push(obj);
          });
          this.setState({ bhodiAbouts: arrSec, arrDropdownsAbouts });
        } else {
          this.setState({ bhodiAbouts: [], arrDropdownsAbouts: [] });
        }
        this.props.updateBhodiAbouts(arrSec)
      });
  }

  handleForm(e) {
    e.preventDefault();
    return false;
  }

  saveUrl() {
    const { url, description } = this.state;
    let userid = localStorage.getItem("userid");
    if (description) {
      firebase
        .database()
        .ref("users/" + userid + "/user_description")
        .set(description);
    }
    if (url) {
      firebase
        .database()
        .ref("users/" + userid + "/user_name_id")
        .set(url)
        .then(() => {
          firebase
            .auth()
            .signOut()
            .then(() => {
              localStorage.setItem("userid", "");
              window.location.href = "/login";
            });
        });
    } else {
      console.log("fill some thing");
    }
    let element = document.getElementById("footermain");
    if (element) {
      element.style.display = "block";
    }
    this.setState({ rightModalSettings: false, profileRight: false });
  }

  saveInfo(type) {
    this.setState({ savtBtnText: "SAVING.." });
    const {
      firstName,
      lastName,
      street,
      city,
      zipCode,
      houseNumber,
      country,
      phoneNumber,
      bankName,
      bankAccountNumber,
      companyEmail,
      companyName,
      kvkCompany,
      profileActive,
    } = this.state;
    const { first_name, last_name } = this.state.currentUserMember;
    let userid = localStorage.getItem("userid");
    if (firstName) {
      firebase
        .database()
        .ref("users/" + userid + "/first_name")
        .set(firstName);
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/first_name")
        .set("");
    }
    if (lastName) {
      firebase
        .database()
        .ref("users/" + userid + "/last_name")
        .set(lastName);
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/last_name")
        .set("");
    }
    if (street) {
      firebase
        .database()
        .ref("users/" + userid + "/street")
        .set(street);
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/street")
        .set("");
    }
    if (city) {
      firebase
        .database()
        .ref("users/" + userid + "/city")
        .set(city);
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/city")
        .set("");
    }
    if (zipCode) {
      firebase
        .database()
        .ref("users/" + userid + "/zipCode")
        .set(zipCode);
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/zipCode")
        .set("");
    }
    if (houseNumber) {
      firebase
        .database()
        .ref("users/" + userid + "/houseNumber")
        .set(houseNumber);
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/houseNumber")
        .set("");
    }
    if (street && houseNumber && zipCode && city) {
      var config = {
        method: "get",
        url: `https://maps.googleapis.com/maps/api/geocode/json?address=${street}+${houseNumber},${zipCode},${city}&key=${process.env.REACT_APP_GEOCODE_KEY}`,
        headers: {},
      };

      axios(config)
        .then((response) => {
          let coords = response.data.results[0].geometry.location;
          firebase
            .database()
            .ref("users/" + userid + "/latitude")
            .set(coords.lat);
          firebase
            .database()
            .ref("users/" + userid + "/longitude")
            .set(coords.lng);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/latitude")
        .remove();
      firebase
        .database()
        .ref("users/" + userid + "/longitude")
        .remove();
    }
    if (country) {
      firebase
        .database()
        .ref("users/" + userid + "/country")
        .set(country);
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/country")
        .set("");
    }
    if (phoneNumber) {
      firebase
        .database()
        .ref("users/" + userid + "/phoneNumber")
        .set(phoneNumber);
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/phoneNumber")
        .set("");
    }
    if (bankName) {
      firebase
        .database()
        .ref("users/" + userid + "/bankName")
        .set(bankName);
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/bankName")
        .set("");
    }
    if (bankAccountNumber) {
      firebase
        .database()
        .ref("users/" + userid + "/bankAccountNumber")
        .set(bankAccountNumber);
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/bankAccountNumber")
        .set("");
    }
    if (profileActive) {
      firebase
        .database()
        .ref("users/" + userid + "/profileActive")
        .set(true);
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/profileActive")
        .set(false);
    }
    if (companyName) {
      firebase
        .database()
        .ref("users/" + userid + "/company_name")
        .set(companyName);
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/company_name")
        .set("");
    }
    if (kvkCompany) {
      firebase
        .database()
        .ref("users/" + userid + "/kvkCompany")
        .set(kvkCompany);
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/kvkCompany")
        .set("");
    }
    // if (companyEmail) {
    //     firebase.database().ref("users/" + userid + "/company_email").set(companyEmail).then(() => {
    //         this.setState({ savtBtnText: "SAVE" })
    //     })
    // } else {
    //     firebase.database().ref("users/" + userid + "/company_email").set("").then(() => {
    //         this.setState({ savtBtnText: "SAVE" })
    //     })
    // }
    if (
      (first_name !== firstName || last_name !== lastName) && this.state.currentUserMember.stripe_id
    ) {
      axios
        .post(
          "https://us-central1-bhodi-production-da765.cloudfunctions.net/updateStripeCustomer",
          {
            name: firstName + "" + lastName,
            email: this.state.currentUserMember.email,
            customerId: this.state.currentUserMember.stripe_id,
          }
        )
        .then((response) => {
          if (type === "goback") {
            if (this.state.closeOnlyCurrent) {
              this.props.closeOnlyCurrent();
            }
            if (this.state.closeIncludingRightMenu) {
              this.props.closeIncludingRightMenu();
            }
          } else {

            if (this.props.fromMainPage) {
              this.props.closeIncludingRightMenu();
            } else {
              window.history.back();
            }
            // this.setState({ savtBtnText: "SAVE" });
          }
        });
    } else {
      setTimeout(() => {
        if (type === "goback") {
          // localStorage.setItem("mustShowOpenRightMenu", true)
          // setTimeout(() => {
          //     window.history.back()
          // }, 100);
          if (this.state.closeOnlyCurrent) {
            this.props.closeOnlyCurrent();
          }
          if (this.state.closeIncludingRightMenu) {
            this.props.closeIncludingRightMenu();
          }
        } else {
          // this.setState({ savtBtnText: "SAVE" });
          if (this.props.fromMainPage) {
            this.props.closeIncludingRightMenu();
          } else {
            window.history.back();
          }
        }
      }, 3000);
    }

    // this.setState({ rightModalSettings: false, userInfoRight: false })
  }

  savePrivacy(type) {
    // let element = document.getElementById("footermain")
    // if (element) {
    //     element.style.display = "block"
    // }
    this.setState({ savtBtnText: "SAVING.." });
    // if (this.state.checkedPrivate) {
    //     firebase.database().ref("users/" + this.state.userid + "/accountType").set("private")
    // } else {
    //     firebase.database().ref("users/" + this.state.userid + "/accountType").set("global")
    // }
    // if (this.state.checkedHideDonation) {
    //     firebase.database().ref("users/" + this.state.userid + "/hideDonation").set(true)
    // } else {
    //     firebase.database().ref("users/" + this.state.userid + "/hideDonation").set(false)
    // }
    // if (this.state.checkedHideProfileInfo) {
    //     firebase.database().ref("users/" + this.state.userid + "/hideProfileInfo").set(true)
    // } else {
    //     firebase.database().ref("users/" + this.state.userid + "/hideProfileInfo").set(false)
    // }
    // if (this.state.checkedHideProfileImage) {
    //     firebase.database().ref("users/" + this.state.userid + "/hideProfileImage").set(true)
    // } else {
    //     firebase.database().ref("users/" + this.state.userid + "/hideProfileImage").set(false)
    // }
    if (this.state.checkedHideFriendsInfo) {
      firebase
        .database()
        .ref("users/" + this.state.userid + "/hideFriendsInfo")
        .set(true)
        .then(() => {
          setTimeout(() => {
            if (type === "goback") {
              localStorage.setItem("mustShowOpenRightMenu", true);
              setTimeout(() => {
                window.history.back();
              }, 100);
            } else {
              // this.setState({ savtBtnText: "SAVE" });

              if (this.props.fromMainPage) {
                this.props.closeIncludingRightMenu();
              } else {
                window.history.back();
              }
            }
          }, 3000);
        });
    } else {
      firebase
        .database()
        .ref("users/" + this.state.userid + "/hideFriendsInfo")
        .set(false)
        .then(() => {
          setTimeout(() => {
            if (type === "goback") {
              // localStorage.setItem("mustShowOpenRightMenu", true)
              // setTimeout(() => {
              //     window.history.back()
              // }, 100);
              if (this.state.closeOnlyCurrent) {
                this.props.closeOnlyCurrent();
              }
              if (this.state.closeIncludingRightMenu) {
                this.props.closeIncludingRightMenu();
              }
            } else {

              if (this.props.fromMainPage) {
                this.props.closeIncludingRightMenu();
              } else {
                window.history.back();
              }
              // this.setState({ savtBtnText: "SAVE" });
            }
          }, 3000);
        });
    }
    // if (this.state.checkedHideGlobalProduct) {
    //     firebase.database().ref("users/" + this.state.userid + "/hideGlobalProducts").set(true).then(() => {
    //         this.setState({ savtBtnText: "SAVE" })
    //     })
    // } else {
    //     firebase.database().ref("users/" + this.state.userid + "/hideGlobalProducts").set(false).then(() => {
    //         this.setState({ savtBtnText: "SAVE" })
    //     })
    // }
    // this.setState({ rightModalSettings: false, privacyRight: false })
  }


  logout() {
    firebase
      .auth()
      .signOut()
      .then(() => {
        localStorage.setItem("userid", "");
        window.location.href = "/";
      });
  }

  saveEmailPassword(type) {
    const { email, password, confirmPassword, url, currentPassword } =
      this.state;

    let filteredEmail = [];
    let filteredURL = [];

    if (email !== this.state.currentUserMember.email) {
      filteredEmail = this.props.user.filter((h) => {
        return h.email === email;
      });
    }

    if (url !== this.state.currentUserMember.user_name_id) {
      filteredURL = this.props.user.filter((h) => {
        return h.user_name_id === url;
      });
    }

    if (filteredEmail.length === 0 && filteredURL.length === 0) {
      this.setState({ savtBtnText: "SAVING.." });

      let userid = localStorage.getItem("userid");
      if (url && url !== this.state.currentUserMember.user_name_id) {
        firebase
          .database()
          .ref("users/" + userid + "/user_name_id")
          .set(url)
          .then(() => {
            firebase
              .auth()
              .signOut()
              .then(() => {
                localStorage.setItem("userid", "");
                window.location.href = "/login";
              });
          });
      } else {
        console.log("fill some thing");
      }
      let changePassword = confirmPassword;
      if (email !== "") {
        // let user = firebase.auth().currentUser;
        // user.updateEmail(email).then(() => {
        //   firebase
        //     .database()
        //     .ref("users/" + this.state.userid + "/email")
        //     .set(email)
        //     .then(() => { });
        // });
        var data = JSON.stringify({
          userId: userid,
          email: email,
        });

        var config = {
          method: "post",
          url: "https://us-central1-bhodi-production-da765.cloudfunctions.net/updateEmail",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios(config)
          .then((response) => {
            if (response.data.statusCode === 201 && this.state.currentUserMember.stripe_id) {
              axios
                .post(
                  "https://us-central1-bhodi-production-da765.cloudfunctions.net/updateStripeCustomer",
                  {
                    name:
                      this.state.currentUserMember.first_name +
                      "" +
                      this.state.currentUserMember.last_name,
                    email: email,
                    customerId: this.state.currentUserMember.stripe_id,
                  }
                )
                .then((responseInner) => {
                  console.log("mollie Updated");
                });
            }
            if (response.data.statusCode === 201) {
              firebase
                .database()
                .ref("users/" + userid + "/email")
                .set(email);
            }
          })
          .catch((response) => {
            var errorMessage = response.error.message;
            if (
              errorMessage ===
              "There is no user record corresponding to this identifier. The user may have been deleted."
            ) {
              this.setState({ emailNotFound: true, aanmeldenloader: false });
            }
          });
      }
      if (password !== changePassword) {
        if (password !== "" && changePassword !== "") {
          if (currentPassword === "") {
            this.setState({ savtBtnText: "SAVE", fillCurrentPassword: true });
          } else {
            this.setState({ fillCurrentPassword: false });
          }
        }
        this.setState({ changedPassword: true });
      } else {
        if (password !== "" && changePassword !== "") {
          if (currentPassword !== "") {
            this.setState({
              changedPassword: false,
              fillCurrentPassword: false,
            });
          } else {
            this.setState({
              savtBtnText: "SAVE",
              changedPassword: false,
              fillCurrentPassword: true,
            });
          }
        }
      }
      if (
        password !== "" &&
        changePassword !== "" &&
        currentPassword !== "" &&
        password === changePassword
      ) {
        var user = firebase.auth().currentUser;
        var credential = firebase.auth.EmailAuthProvider.credential(
          email,
          currentPassword
        );
        user
          .reauthenticateWithCredential(credential)
          .then(() => {
            this.setState({ fillCurrentPassword: false });
            var newPassword = password;
            user
              .updatePassword(newPassword)
              .then((e) => {
                bcrypt.genSalt(saltRounds, (err, salt) => {
                  bcrypt.hash(newPassword, salt, (err, hash) => {
                    firebase
                      .auth()
                      .signInWithEmailAndPassword(email, newPassword)
                      .then((succ) => {
                        // firebase
                        //   .database()
                        //   .ref("users/" + this.state.userid + "/pass")
                        //   .set(newPassword)
                        //   .then(() => {
                        firebase
                          .database()
                          .ref("users/" + this.state.userid + "/password")
                          .set(hash)
                          .then(() => {
                            this.setState({
                              password: "",
                              confirmPassword: "",
                            });
                            window.location.reload();
                          });
                        // });
                      });
                  });
                });
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((err) => {
            if (err.code === "auth/wrong-password") {
              this.setState({ savtBtnText: "SAVE", fillCurrentPassword: true });
            }
          });
      } else {
        setTimeout(() => {
          if (type === "goback") {
            // localStorage.setItem("mustShowOpenRightMenu", true)
            // setTimeout(() => {
            //     window.history.back()
            // }, 100);
            if (this.state.closeOnlyCurrent) {
              this.props.closeOnlyCurrent();
            }
            if (this.state.closeIncludingRightMenu) {
              this.props.closeIncludingRightMenu();
            }
          } else {

            if (this.props.fromMainPage) {
              this.props.closeIncludingRightMenu();
            } else {
              window.history.back();
            }
            // this.setState({ savtBtnText: "SAVE" });
          }
        }, 3000);
      }

    } else {
      if (filteredEmail.length > 0) {
        this.setState({ emailAlreadyTooked: true });
      } else {
        this.setState({ emailAlreadyTooked: false });
      }
      if (filteredURL.length > 0) {
        this.setState({ urlNotAvailable: true });
      } else {
        this.setState({ urlNotAvailable: false });
      }
    }
  }

  fileUpload(e) {
    let files = e.target.files[0];
    let storageRef = firebase
      .storage()
      .ref()
      .child(`userimages/${files.name}${new Date().getTime()}`);
    storageRef.put(files).then((snapshot) => {
      snapshot.ref.getDownloadURL().then((snapUrl) => {
        firebase
          .database()
          .ref("users/" + this.state.userid + "/profile_image")
          .set(snapUrl)
          .then(() => { });
      });
    });
  }

  saveVoucherSettings(type) {
    this.setState({ savtBtnText: "SAVING.." });
    const {
      termsText,
      useOfVoucherKortingsVoucher,
      useOfVoucherLocalEvent,
      expireDateVoucher,
      expireTypeVoucher,
    } = this.state;
    let userid = localStorage.getItem("userid");
    if (useOfVoucherLocalEvent) {
      firebase
        .database()
        .ref("users/" + userid + "/useOfVoucherLocalEvent")
        .set(useOfVoucherLocalEvent);
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/useOfVoucherLocalEvent")
        .set("");
    }
    if (useOfVoucherKortingsVoucher) {
      firebase
        .database()
        .ref("users/" + userid + "/useOfVoucherKortingsVoucher")
        .set(useOfVoucherKortingsVoucher);
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/useOfVoucherKortingsVoucher")
        .set("");
    }
    if (termsText) {
      firebase
        .database()
        .ref("users/" + userid + "/termsText")
        .set(termsText);
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/termsText")
        .remove();
    }
    if (expireDateVoucher) {
      firebase
        .database()
        .ref("users/" + userid + "/expireDateVoucher")
        .set(expireDateVoucher);
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/expireDateVoucher")
        .remove();
    }
    if (expireTypeVoucher) {
      firebase
        .database()
        .ref("users/" + userid + "/expireTypeVoucher")
        .set(expireTypeVoucher);
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/expireTypeVoucher")
        .remove();
    }
    // if (voucherExceptions) {
    //     firebase.database().ref("users/" + userid + "/voucherExceptions").set(voucherExceptions)
    // } else {
    //     firebase.database().ref("users/" + userid + "/voucherExceptions").set("")
    // }
    setTimeout(() => {
      if (type === "goback") {
        // localStorage.setItem("mustShowOpenRightMenu", true)
        // setTimeout(() => {
        //     window.history.back()
        // }, 100);
        if (this.state.closeOnlyCurrent) {
          this.props.closeOnlyCurrent();
        }
        if (this.state.closeIncludingRightMenu) {
          this.props.closeIncludingRightMenu();
        }
      } else {

        if (this.props.fromMainPage) {
          this.props.closeIncludingRightMenu();
        } else {
          window.history.back();
        }
        // this.setState({ savtBtnText: "SAVE" });
      }
    }, 3000);
  }

  createMarkup(e) {
    if (e) {
      return { __html: e };
    }
  }

  async deleteCurrentUser() {
    if (this.state.showDelete) {
      if (this.state.passwordForDel) {
        this.setState({ passwordRequiredDel: false })

        let email = this.state.currentUserMember.email
        let password = this.state.passwordForDel
        var user = firebase.auth().currentUser;
        var credential = firebase.auth.EmailAuthProvider.credential(
          email,
          password
        );;
        user.reauthenticateWithCredential(credential).then(() => {
          this.setState({ passwordWrongDel: false })
          this.deleteUserMain()
        }).catch(() => {
          this.setState({ passwordWrongDel: true })
          return false;
        })
      } else {
        this.setState({ passwordRequiredDel: true, passwordWrongDel: false })
        return false;
      }
    } else {
      this.setState({
        // cancelSubscriptionPopup: false,
        showDelete: true,
      });
      axios
        .post(
          "https://us-central1-bhodi-production-da765.cloudfunctions.net/cancelStripeCustomerSubscription",
          {
            subscriptionId:
              this.state.currentUserMember.subscription_id,
            customerId: this.state.currentUserMember.stripe_id,
          }
        )
        .then(() => {
          axios
            .post(
              "https://us-central1-bhodi-production-da765.cloudfunctions.net/deleteStripeCustomer",
              {
                customerId: this.state.currentUserMember.stripe_id,
              }
            )
            .then(() => {
              this.deleteCurrentUser();
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
      // this.setState({ cancelSubscriptionPopup: true });
    }
  }

  async deleteUserMain() {
    this.setState({ deleteBtnText: "SAVING.." });

    let allUsers = this.props.user;
    allUsers.map((u) => {
      if (u.following) {
        let objected = Object.entries(u.following);
        let filtered = objected.filter((b) => {
          return b[1].user_id === this.state.currentUserId;
        });
        if (filtered.length > 0) {
          firebase
            .database()
            .ref(
              "users/" + u.user_id + "/following/" + filtered[0][1].pushKey
            )
            .remove();
        }
      }
      if (u.followed_by) {
        let objected = Object.entries(u.followed_by);
        let filtered = objected.filter((b) => {
          return b[1].user_id === this.state.currentUserId;
        });
        if (filtered.length > 0) {
          firebase
            .database()
            .ref(
              "users/" + u.user_id + "/followed_by/" + filtered[0][1].pushKey
            )
            .remove();
        }
      }
      if (u.newFollowersList) {
        let objected = Object.entries(u.newFollowersList);
        let filtered = objected.filter((b) => {
          return b[1].user_id === this.state.currentUserId;
        });
        if (filtered.length > 0) {
          let newFollowers = u.newFollowers - 1;
          firebase
            .database()
            .ref(
              "users/" +
              u.user_id +
              "/newFollowersList/" +
              filtered[0][1].pushKey
            )
            .remove();
          firebase
            .database()
            .ref("users/" + u.user_id + "/newFollowers")
            .set(newFollowers);
        }
      }
    });
    await firebase
      .database()
      .ref("bhodi_campaigns/" + this.state.currentUserId)
      .remove();
    await firebase
      .database()
      .ref("impressiePhotos/" + this.state.currentUserId)
      .remove();
    await firebase
      .database()
      .ref("allMessages/" + this.state.currentUserId)
      .remove();
    await firebase
      .database()
      .ref("guest_carts/" + this.state.currentUserId)
      .remove();
    await firebase.auth().currentUser.delete();

    firebase
      .database()
      .ref("users/" + this.state.currentUserId)
      .remove()
      .then(() => {
        localStorage.removeItem("userdata");
        // localStorage.removeItem("isAdmin")
        localStorage.removeItem("onCurrentTab");
        localStorage.removeItem("onCurrentTabCompany");
        localStorage.removeItem("userid");
        this.props.updateSignUpFieldsFilled("");
        this.props.updateGuestUserCartExtendOpportunity("");
        this.props.updateGuestUserCartTime("");
        this.props.updateGuestUserCart([]);
        setTimeout(() => {
          window.location.href = "/";
        }, 50);
        // window.location.href = "/login";
      });
  }

  deletePdf() {
    firebase
      .database()
      .ref("users/" + this.state.userid + "/requirement_file")
      .remove()
      .then(() => {
        firebase
          .database()
          .ref("users/" + this.state.userid + "/file_name")
          .remove();
      });
  }

  connectToMollie() {
    var config = {
      method: "get",
      url: "https://us-central1-bhodi-production-da765.cloudfunctions.net/connectToMollie",
    }

    axios(config)
      .then((response) => {
        if (response.data.statusCode === 201) {
          window.location.href = response.data.url
        } else {
          swal({
            title: "Oops.",
            text: "Error occured, please try again in a while",
            icon: "error",
          })
        }
      })
      .catch((error) => {
        swal({
          title: "Oops.",
          text: "Error occured, please try again in a while",
          icon: "error",
        })
      });
  }

  disconnectMollie() {
    firebase.database().ref(`users/${this.state.currentUserHere.user_id}/mollie_credentials`).remove()
  }

  disconnectPaypal() {
    firebase.database().ref(`users/${this.state.currentUserHere.user_id}/paypalInfo`).remove()
  }

  connectToPaypal() {
    let returnUrl = "";
    if (this.state.currentUserHere.type === "company") {
      returnUrl = `${window.location.origin}/company/${this.state.currentUserHere.user_name_id}`;
    }

    var data = JSON.stringify({
      customer_data: {
        customer_type: "MERCHANT",
        person_details: {
          email_address: this.state.currentUserHere.email,
        },
        partner_specific_identifiers: [
          {
            type: "TRACKING_ID",
            value: "ebaef1b4-5550-429d-9925-ed99d1666efc2",
          },
        ],
      },
      requested_capabilities: [
        {
          capability: "API_INTEGRATION",
          api_integration_preference: {
            partner_id: "E3M5Y9GGKLQQJ",
            rest_api_integration: {
              integration_method: "PAYPAL",
              integration_type: "THIRD_PARTY",
            },
            rest_third_party_details: {
              partner_client_id:
                "AS_WXrkwOcMdxirbjpW230Zw_HKMN_kFhxR7XtmrB2FO4LFziukkSGX2Vtn1kBaHtZGKzKt4RWwp3r6j",
              feature_list: [
                "PAYMENT",
                "REFUND",
                "PARTNER_FEE",
                "DELAY_FUNDS_DISBURSEMENT",
              ],
            },
          },
        },
      ],
      web_experience_preference: {
        return_url: returnUrl,
        partner_logo_url:
          "https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/logowhite.png?alt=media&token=12590aef-0263-47e7-8462-15bf3a7f5e71",
      },
      collected_consents: [
        {
          type: "SHARE_DATA_CONSENT",
          granted: true,
        },
      ],
      products: ["EXPRESS_CHECKOUT"],
    });

    var config = {
      method: "get",
      url: "https://us-central1-bhodi-production-da765.cloudfunctions.net/getAuthDetails",
      headers: {},
    };

    axios(config)
      .then((response) => {
        if (response.status === 200) {
          let accessToken = response.data.access_token;

          var configAnother = {
            method: "post",
            url: "https://us-central1-bhodi-production-da765.cloudfunctions.net/partnerReferrals",
            headers: {
              authToken: accessToken,
              "Content-Type": "application/json",
            },
            data: data,
          };

          axios(configAnother)
            .then((responseNew) => {
              if (responseNew.data.links.length > 0) {
                let filtered = responseNew.data.links.filter((e) => {
                  return e.rel === "action_url";
                });
                if (filtered.length > 0) {
                  window.location.href = filtered[0].href;
                }
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  render() {
    return (
      <div className="separate-settings">
        {/* <div className="center-fwnvvm centermakeheaderaanbieders" style={{ color: "white" }}>
                    <ChevronLeftIcon style={{ fontSize: 20, marginRight: 10 }} onClick={() => {
                        window.history.back()
                    }} />
                </div> */}
        {this.state.rightModalSettings && (
          <div
            className={`loaderareaduplicate mcaloader ${this.state.userInfoRight ? "userinfomodalfull" : ""
              } ${this.state.shopSettings ? "shopsettingsmodalfull" : ""} ${this.state.privacyRight ? "privacymodalfull" : ""
              } ${this.state.emailPassword ? "emailpwdmodalfull" : ""}`}
          >
            {this.state.userInfoRight && (
              <div className="modal-create-album modalFullWidth orderModalFullWidth modalcreatealbumchangedheader changedheadermodalcreatealbum">
                <div
                  className="center-fwnvvm centermakeheaderaanbieders"
                  style={{ color: "white", justifyContent: "space-between" }}
                >
                  <label>MIJN GEGEVENS</label>
                  <div>
                    <ChevronLeftIcon
                      style={{ fontSize: 20, marginRight: 10 }}
                      onClick={() => {
                        const {
                          first_name,
                          last_name,
                          zipCode,
                          street,
                          company_name,
                          kvkCompany,
                          company_website,
                          houseNumber,
                          country,
                          city,
                          phoneNumber,
                          bankAccountNumber,
                          bankName,
                          profileActive,
                        } = this.state.currentUserMember;
                        if (
                          first_name !== this.state.firstName ||
                          last_name !== this.state.lastName ||
                          zipCode !== this.state.zipCode ||
                          street !== this.state.street ||
                          company_name !== this.state.companyName ||
                          kvkCompany !== this.state.kvkCompany ||
                          houseNumber !== this.state.houseNumber ||
                          company_website !== this.state.company_website ||
                          country !== this.state.country ||
                          city !== this.state.city ||
                          phoneNumber !== this.state.phoneNumber ||
                          bankName !== this.state.bankName ||
                          bankAccountNumber !== this.state.bankAccountNumber ||
                          profileActive !== this.state.profileActive
                        ) {
                          this.setState({
                            beforeCloseMenuConfirm: true,
                            closeOnlyCurrent: true,
                            closeIncludingRightMenu: false,
                          });
                          return false;
                        }
                        if (this.props.fromMainPage) {
                          this.props.closeOnlyCurrent();
                        } else {
                          localStorage.setItem("mustShowOpenRightMenu", true);
                          setTimeout(() => {
                            window.history.back();
                          }, 100);
                        }
                      }}
                    />
                    <div className="spacing-between-icons"></div>
                    <CloseIcon
                      style={{ fontSize: 20, marginRight: 10 }}
                      onClick={() => {
                        const {
                          first_name,
                          last_name,
                          zipCode,
                          street,
                          company_name,
                          kvkCompany,
                          company_website,
                          houseNumber,
                          country,
                          city,
                          phoneNumber,
                          bankName,
                          bankAccountNumber,
                          profileActive,
                        } = this.state.currentUserMember;
                        if (
                          first_name !== this.state.firstName ||
                          last_name !== this.state.lastName ||
                          zipCode !== this.state.zipCode ||
                          street !== this.state.street ||
                          company_name !== this.state.companyName ||
                          kvkCompany !== this.state.kvkCompany ||
                          houseNumber !== this.state.houseNumber ||
                          company_website !== this.state.company_website ||
                          country !== this.state.country ||
                          city !== this.state.city ||
                          phoneNumber !== this.state.phoneNumber ||
                          bankName !== this.state.bankName ||
                          bankAccountNumber !== this.state.bankAccountNumber ||
                          profileActive !== this.state.profileActive
                        ) {
                          this.setState({
                            beforeCloseMenuConfirm: true,
                            closeOnlyCurrent: false,
                            closeIncludingRightMenu: true,
                          });
                          return false;
                        }
                        if (this.props.fromMainPage) {
                          this.props.closeIncludingRightMenu();
                        } else {
                          window.history.back();
                        }
                      }}
                    />
                  </div>
                </div>
                {/* {!this.state.isDesktop && <ChevronLeftIcon onClick={() => {
                            window.history.back()
                        }} style={{ position: "static", top: 15, left: 10, fontSize: 18 }} />} */}
                <div className="body-mca groupsettingsbody">
                  <div className="form-body-mca">
                    <div className="inner-mca-settings">
                      <div
                        className="mca-settings-list"
                        style={{
                          flexDirection: "column",
                          backgroundColor: "transparent",
                          width: "90%",
                          margin: "10px auto",
                        }}
                      >
                        {/* <div className="top-mcls-one">
                                            <p>
                                                <AccountCircleOutlinedIcon />
                                                MIJN GEGEVENS
                                            </p>
                                        </div> */}
                        <div
                          className="wrapperforfullwidth"
                          style={{ position: "relative" }}
                        >
                          <div
                            className="right-mcls"
                            style={{ color: "white", margin: 0 }}
                          >
                            <div className="form-profile">
                              <label>Voornaam</label>
                              <input
                                type="text"
                                value={this.state.firstName}
                                onChange={(e) =>
                                  this.setState({ firstName: e.target.value })
                                }
                              />
                            </div>
                          </div>
                          <div
                            className="right-mcls"
                            style={{ color: "white", margin: 0 }}
                          >
                            <div className="form-profile">
                              <label>Achternaam</label>
                              <input
                                type="text"
                                value={this.state.lastName}
                                onChange={(e) =>
                                  this.setState({ lastName: e.target.value })
                                }
                              />
                            </div>
                          </div>
                          <div
                            className="right-mcls"
                            style={{ color: "white", margin: 0 }}
                          >
                            <div className="form-profile">
                              <label>Bedrijfsnaam</label>
                              <input
                                type="text"
                                value={this.state.companyName}
                                onChange={(e) => {
                                  this.state.currentUserMember.company_name =
                                    e.target.value;
                                  this.setState({
                                    companyName: e.target.value,
                                    currentUserMember:
                                      this.state.currentUserMember,
                                  });
                                }}
                              />
                            </div>
                            <div className="form-profile">
                              <label>KVK</label>
                              <input
                                type="text"
                                value={this.state.kvkCompany}
                                onChange={(e) => {
                                  this.state.currentUserMember.kvkCompany =
                                    e.target.value;
                                  this.setState({
                                    kvkCompany: e.target.value,
                                    currentUserMember:
                                      this.state.currentUserMember,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          <div
                            className="right-mcls"
                            style={{ color: "white", margin: 0 }}
                          >
                            {/* <div className="form-profile">
                                                    <label>Zakelijke e-mail</label>
                                                    <input type="text" value={this.state.companyEmail} onChange={(e) => this.setState({ companyEmail: e.target.value })} />
                                                </div> */}
                            <div className="form-profile">
                              <label>Website</label>
                              <input
                                type="text"
                                value={this.state.company_website}
                                onChange={(e) => {
                                  this.state.currentUserMember.company_website =
                                    e.target.value;
                                  this.setState({
                                    company_website: e.target.value,
                                    currentUserMember:
                                      this.state.currentUserMember,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          <div
                            className="right-mcls"
                            style={{ color: "white", margin: 0 }}
                          >
                            <div className="form-profile">
                              <label>Straatnaam</label>
                              <input
                                type="text"
                                value={this.state.street}
                                onChange={(e) =>
                                  this.setState({ street: e.target.value })
                                }
                              />
                            </div>
                          </div>
                          <div
                            className="right-mcls"
                            style={{ color: "white", margin: 0 }}
                          >
                            <div className="form-profile">
                              <label>Huisnummer</label>
                              <input
                                type="text"
                                value={this.state.houseNumber}
                                onChange={(e) =>
                                  this.setState({ houseNumber: e.target.value })
                                }
                              />
                            </div>
                          </div>
                          <div
                            className="right-mcls"
                            style={{ color: "white", margin: 0 }}
                          >
                            <div className="form-profile">
                              <label>Postcode</label>
                              <input
                                type="text"
                                value={this.state.zipCode}
                                onChange={(e) =>
                                  this.setState({ zipCode: e.target.value })
                                }
                              />
                            </div>
                          </div>
                          <div
                            className="right-mcls"
                            style={{ color: "white", margin: 0 }}
                          >
                            <div className="form-profile">
                              <label>Plaatsnaam</label>
                              <input
                                type="text"
                                value={this.state.city}
                                onChange={(e) =>
                                  this.setState({ city: e.target.value })
                                }
                              />
                            </div>
                          </div>
                          <div
                            className="right-mcls"
                            style={{ color: "white", margin: 0 }}
                          >
                            <div className="form-profile">
                              <label>Land</label>
                              <input
                                type="text"
                                value={this.state.country}
                                onChange={(e) =>
                                  this.setState({ country: e.target.value })
                                }
                              />
                            </div>
                          </div>
                          <div
                            className="right-mcls"
                            style={{ color: "white", margin: 0 }}
                          >
                            <div className="form-profile">
                              <label>Telefoonnummer</label>
                              <input
                                type="text"
                                value={this.state.phoneNumber}
                                onChange={(e) =>
                                  this.setState({ phoneNumber: e.target.value })
                                }
                              />
                            </div>
                          </div>
                          <div
                            className="right-mcls"
                            style={{ color: "white", margin: 0 }}
                          >
                            <div className="form-profile">
                              <label>Naam rekeninghouder</label>
                              <input
                                type="text"
                                value={this.state.bankName}
                                onChange={(e) =>
                                  this.setState({
                                    bankName: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div
                            className="right-mcls"
                            style={{ color: "white", margin: 0 }}
                          >
                            <div className="form-profile">
                              <label>Bankrekeningnummer</label>
                              <input
                                type="text"
                                value={this.state.bankAccountNumber}
                                onChange={(e) =>
                                  this.setState({
                                    bankAccountNumber: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div
                            className="right-mcls"
                            style={{ color: "#1C1C1C", flexDirection: "row" }}
                          >
                            <Switch
                              color="primary"
                              inputProps={{
                                "aria-label": "primary checkbox",
                              }}
                              checked={this.state.profileActive}
                              onClick={() => {
                                this.setState({
                                  profileActive:
                                    !this.state.profileActive,
                                });
                              }}
                            />
                            {this.state.profileActive ? (
                              <font>Profiel actief</font>
                            ) : (
                              <font>Profiel inactief</font>
                            )}
                          </div>
                        </div>
                        <div
                          className="btn-save-rmcls"
                          style={{ position: "absolute", bottom: -50 }}
                        >
                          {this.state.savtBtnText === "SAVE" ? (
                            <button
                              style={{ backgroundColor: "#285151" }}
                              onClick={() => {
                                if (this.state.savtBtnText === "SAVE") {
                                  this.saveInfo();
                                }
                              }}
                            >
                              OPSLAAN
                            </button>
                          ) : (
                            <button>
                              <i class="fa fa-spinner fa-spin"></i> OPSLAAN
                            </button>
                          )}
                        </div>
                        <div className="delete-account-content" style={{ width: "90%", margin: "20px auto", marginTop: 0 }}>
                          <p style={{ color: "black", fontWeight: "bold" }}>Het verwijderen van je account betekent:</p>
                          <p style={{ color: "black" }}>Dat je alle gegevens en content in dit account kwijtraakt. Hierna kun je niet meer inloggen met dit account.</p>
                          <div className="red-button-verwijder">
                            {this.state.deleteBtnText !== "SAVE" ? (
                              <button
                                style={{ backgroundColor: "red", minWidth: 170 }}
                              >
                                <i class="fa fa-spinner fa-spin"></i> DELETING
                              </button>
                            ) : (
                              <button
                                style={{ backgroundColor: "red", minWidth: 170 }}
                                // onClick={this.deleteCurrentUser.bind(this)}
                                onClick={() =>
                                  this.setState({ verwijderPopupDel: true })
                                }
                              >
                                Verwijder account
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {this.state.showMijnAbonnementen && (
              <div className="modal-create-album modalFullWidth orderModalFullWidth modalcreatealbumchangedheader changedheadermodalcreatealbum">
                <div
                  className="center-fwnvvm centermakeheaderaanbieders"
                  style={{ color: "white", justifyContent: "space-between" }}
                >
                  <label>MIJN EXCLUSIVES</label>
                  <div>
                    <ChevronLeftIcon
                      style={{ fontSize: 20, marginRight: 10 }}
                      onClick={() => {
                        if (this.props.fromMainPage) {
                          this.props.closeOnlyCurrent();
                        } else {
                          localStorage.setItem("mustShowOpenRightMenu", true);
                          setTimeout(() => {
                            window.history.back();
                          }, 100);
                        }
                      }}
                    />
                    <div className="spacing-between-icons"></div>
                    <CloseIcon
                      style={{ fontSize: 20, marginRight: 10 }}
                      onClick={() => {
                        if (this.props.fromMainPage) {
                          this.props.closeIncludingRightMenu();
                        } else {
                          window.history.back();
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="body-mca groupsettingsbody">
                  <div className="form-body-mca">
                    <div className="inner-mca-settings">
                      <div
                        className="mca-settings-list"
                        style={{
                          flexDirection: "column",
                          backgroundColor: "transparent",
                          width: "90%",
                          margin: "10px auto",
                        }}
                      >
                        <div
                          className="wrapperforfullwidth"
                          style={{ position: "relative" }}
                        >
                          <MySubcribedExclusives />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {this.state.paymentSettingsRight && (
              <div className="modal-create-album modalFullWidth orderModalFullWidth modalcreatealbumchangedheader changedheadermodalcreatealbum">
                <div
                  className="center-fwnvvm centermakeheaderaanbieders"
                  style={{ color: "white", justifyContent: "space-between" }}
                >
                  <label>PAYMENT SETTINGS</label>
                  <div>
                    <ChevronLeftIcon
                      style={{ fontSize: 20, marginRight: 10 }}
                      onClick={() => {
                        if (this.props.fromMainPage) {
                          this.props.closeOnlyCurrent();
                        } else {
                          localStorage.setItem("mustShowOpenRightMenu", true);
                          setTimeout(() => {
                            window.history.back();
                          }, 100);
                        }
                      }}
                    />
                    <div className="spacing-between-icons"></div>
                    <CloseIcon
                      style={{ fontSize: 20, marginRight: 10 }}
                      onClick={() => {
                        if (this.props.fromMainPage) {
                          this.props.closeIncludingRightMenu();
                        } else {
                          window.history.back();
                        }
                      }}
                    />
                  </div>
                </div>

                <div className='rightmenuinner-rmmms updatedirghtmenunew newrightmenuglobalgood' style={{ height: "auto" }}>
                  {this.state.currentUserMember.approved_by_admin ? (<div className='content-rmmi-rmms'>
                    <div
                      className="form-miur"
                      style={{ position: "relative" }}
                    >
                      <label>Mollie subscription <div className='green-round-info'>?</div></label>
                      {this.state.currentUserMember.mollie_credentials ? (<div className='wrapper-select-new wrapper-select-green'>
                        <input value="Verbonden" style={{ pointerEvents: "none" }} />
                        <BsCheck2
                          style={{
                            position: "absolute",
                            bottom: 5,
                            right: 5,
                            pointerEvents: "none",
                          }}
                          className='more-right-icon fontsizemsallsvg'
                        />
                        <CloseIcon
                          style={{
                            position: "absolute",
                            bottom: 5,
                            right: 5,
                            cursor: "pointer",
                          }}
                          className='expand-more fontsizemsallsvg'
                          onClick={this.disconnectMollie.bind(this)}
                        />
                      </div>) : (<div className='wrapper-select-new wrapper-select-green' onClick={this.connectToMollie.bind(this)}>
                        <input value="Connect your account" readOnly style={{ cursor: "pointer" }} />
                        <BiChevronRight
                          style={{
                            position: "absolute",
                            bottom: 5,
                            right: 5,
                            pointerEvents: "none",
                          }}
                          className='expand-more'
                        />
                      </div>)}
                    </div>
                    <div
                      className="form-miur"
                      style={{ position: "relative" }}
                    >
                      <label>Paypal <div className='green-round-info'>?</div></label>
                      <div className='wrapper-select-new wrapper-select-green'>
                        {this.state.currentUserMember.paypalInfo ? (<div className='wrapper-select-new wrapper-select-green'>
                          <input value="Verbonden" style={{ pointerEvents: "none" }} />
                          <BsCheck2
                            style={{
                              position: "absolute",
                              bottom: 5,
                              right: 5,
                              pointerEvents: "none",
                            }}
                            className='more-right-icon fontsizemsallsvg'
                          />
                          <CloseIcon
                            style={{
                              position: "absolute",
                              bottom: 5,
                              right: 5,
                              cursor: "pointer",
                            }}
                            className='expand-more fontsizemsallsvg'
                            onClick={this.disconnectPaypal.bind(this)}
                          />
                        </div>) : (<div className='wrapper-select-new wrapper-select-green' onClick={this.connectToPaypal.bind(this)}>
                          <input value="Connect your account" readOnly style={{ cursor: "pointer" }} />
                          <BiChevronRight
                            style={{
                              position: "absolute",
                              bottom: 5,
                              right: 5,
                              pointerEvents: "none",
                            }}
                            className='expand-more'
                          />
                        </div>)}
                      </div>
                    </div>
                    {/* <div className='save-button-main' style={{ marginTop: 40 }}>
                      <button>OPSLAAN</button>
                    </div> */}
                  </div>) : this.state.currentUserMember.pending_admin_approve ? (<div className='content-rmmi-rmms'>
                    <div className='save-button-main' style={{ marginTop: 40 }}>
                      <button>AANVRAAG IN BEHANDELING</button>
                    </div>
                    <div className='save-button-main' style={{ marginTop: 20 }} onClick={() => {
                      firebase.database().ref("users/" + this.state.currentUserMember.user_id + "/pending_admin_approve").remove()
                    }}>
                      <button>CANCEL</button>
                    </div>
                  </div>) : (<div className='content-rmmi-rmms'>
                    <div className='save-button-main' style={{ marginTop: 40 }} onClick={() => {
                      firebase.database().ref("users/" + this.state.currentUserMember.user_id + "/pending_admin_approve").set(true)
                    }}>
                      <button>ACTIVEER BETALINGEN</button>
                    </div>
                  </div>)}
                </div>
              </div>
            )}
            {this.state.shopSettings && (
              <div
                className="modal-create-album modalFullWidth orderModalFullWidth"
                style={{ width: 400 }}
              >
                <div
                  className="center-fwnvvm centermakeheaderaanbieders"
                  style={{ color: "white" }}
                >
                  <ChevronLeftIcon
                    style={{ fontSize: 20, marginRight: 10 }}
                    onClick={() => {
                      window.history.back();
                    }}
                  />
                </div>
                {/* {!this.state.isDesktop && <ChevronLeftIcon onClick={() => {
                            window.history.back()
                        }} style={{ position: "static", top: 15, left: 10, fontSize: 18 }} />} */}
                <div className="body-mca groupsettingsbody">
                  <div className="form-body-mca">
                    <div className="inner-mca-settings">
                      <div
                        className="mca-settings-list"
                        style={{
                          flexDirection: "column",
                          backgroundColor: "transparent",
                          width: "90%",
                          margin: "10px auto",
                        }}
                      >
                        <div className="top-mcls-one">
                          <p>
                            <AccountCircleOutlinedIcon />
                            SHOP SETTINGS
                          </p>
                        </div>
                        <div
                          className="wrapperforfullwidth"
                          style={{ position: "relative" }}
                        >
                          <div
                            className="right-mcls"
                            style={{ color: "white", margin: 0 }}
                          >
                            <div className="form-profile">
                              <label
                                style={{
                                  color: this.state.paymentEmailRequired
                                    ? "red"
                                    : "black",
                                }}
                              >
                                Email
                              </label>
                              <input
                                type="text"
                                value={this.state.paymentEmail}
                                onChange={(e) =>
                                  this.setState({
                                    paymentEmail: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div
                            className="right-mcls"
                            style={{ color: "white", margin: 0 }}
                          >
                            <div className="form-profile">
                              <label
                                style={{
                                  color: this.state.given_nameRequired
                                    ? "red"
                                    : "black",
                                }}
                              >
                                Given Name
                              </label>
                              <input
                                type="text"
                                value={this.state.given_name}
                                onChange={(e) =>
                                  this.setState({ given_name: e.target.value })
                                }
                              />
                            </div>
                          </div>
                          <div
                            className="right-mcls"
                            style={{ color: "white", margin: 0 }}
                          >
                            <div className="form-profile">
                              <label
                                style={{
                                  color: this.state.surnameRequired
                                    ? "red"
                                    : "black",
                                }}
                              >
                                Surname
                              </label>
                              <input
                                type="text"
                                value={this.state.surname}
                                onChange={(e) =>
                                  this.setState({ surname: e.target.value })
                                }
                              />
                            </div>
                          </div>
                          <div
                            className="right-mcls"
                            style={{ color: "white", margin: 0 }}
                          >
                            <div className="form-profile">
                              <label
                                style={{
                                  color: this.state.date_of_birthRequired
                                    ? "red"
                                    : "black",
                                }}
                              >
                                Date of birth
                              </label>
                              <input
                                type="date"
                                value={this.state.date_of_birth}
                                onChange={(e) =>
                                  this.setState({
                                    date_of_birth: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div
                            className="right-mcls"
                            style={{ color: "white", margin: 0 }}
                          >
                            <div className="form-profile">
                              <label
                                style={{
                                  color: this.state
                                    .nationality_country_codeRequired
                                    ? "red"
                                    : "black",
                                }}
                              >
                                Nationality Country Code
                              </label>
                              <input
                                type="text"
                                value={this.state.nationality_country_code}
                                onChange={(e) =>
                                  this.setState({
                                    nationality_country_code: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div
                            className="right-mcls"
                            style={{ color: "white", margin: 0 }}
                          >
                            <div className="form-profile">
                              <label
                                style={{
                                  color: this.state
                                    .phone_number_country_codeRequired
                                    ? "red"
                                    : "black",
                                }}
                              >
                                Phone Number Country Code
                              </label>
                              <input
                                type="text"
                                value={this.state.phone_number_country_code}
                                onChange={(e) =>
                                  this.setState({
                                    phone_number_country_code: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div
                            className="right-mcls"
                            style={{ color: "white", margin: 0 }}
                          >
                            <div className="form-profile">
                              <label
                                style={{
                                  color: this.state
                                    .phone_number_national_numberRequired
                                    ? "red"
                                    : "black",
                                }}
                              >
                                Phone Number National Number
                              </label>
                              <input
                                type="text"
                                value={this.state.phone_number_national_number}
                                onChange={(e) =>
                                  this.setState({
                                    phone_number_national_number:
                                      e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div
                            className="right-mcls"
                            style={{ color: "white", margin: 0 }}
                          >
                            <div className="form-profile">
                              <label
                                style={{
                                  color: this.state
                                    .business_address_line1Required
                                    ? "red"
                                    : "black",
                                }}
                              >
                                Business Address
                              </label>
                              <input
                                type="text"
                                value={this.state.business_address_line1}
                                onChange={(e) =>
                                  this.setState({
                                    business_address_line1: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div
                            className="right-mcls"
                            style={{ color: "white", margin: 0 }}
                          >
                            <div className="form-profile">
                              <label
                                style={{
                                  color: this.state
                                    .business_address_stateRequired
                                    ? "red"
                                    : "black",
                                }}
                              >
                                Business Address State
                              </label>
                              <input
                                type="text"
                                value={this.state.business_address_state}
                                onChange={(e) =>
                                  this.setState({
                                    business_address_state: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div
                            className="right-mcls"
                            style={{ color: "white", margin: 0 }}
                          >
                            <div className="form-profile">
                              <label
                                style={{
                                  color: this.state
                                    .business_address_cityRequired
                                    ? "red"
                                    : "black",
                                }}
                              >
                                Business Address City
                              </label>
                              <input
                                type="text"
                                value={this.state.business_address_city}
                                onChange={(e) =>
                                  this.setState({
                                    business_address_city: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div
                            className="right-mcls"
                            style={{ color: "white", margin: 0 }}
                          >
                            <div className="form-profile">
                              <label
                                style={{
                                  color: this.state
                                    .business_address_country_codeRequired
                                    ? "red"
                                    : "black",
                                }}
                              >
                                Business Address Country Code
                              </label>
                              <input
                                type="text"
                                value={this.state.business_address_country_code}
                                onChange={(e) =>
                                  this.setState({
                                    business_address_country_code:
                                      e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div
                            className="right-mcls"
                            style={{ color: "white", margin: 0 }}
                          >
                            <div className="form-profile">
                              <label
                                style={{
                                  color: this.state
                                    .business_address_postal_codeRequired
                                    ? "red"
                                    : "black",
                                }}
                              >
                                Business Address Postal Code
                              </label>
                              <input
                                type="text"
                                value={this.state.business_address_postal_code}
                                onChange={(e) =>
                                  this.setState({
                                    business_address_postal_code:
                                      e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div
                            className="right-mcls"
                            style={{ color: "white", margin: 0 }}
                          >
                            <div className="form-profile">
                              <label
                                style={{
                                  color: this.state.bussiness_legal_nameRequired
                                    ? "red"
                                    : "black",
                                }}
                              >
                                Business Legal Name
                              </label>
                              <input
                                type="text"
                                value={this.state.bussiness_legal_name}
                                onChange={(e) =>
                                  this.setState({
                                    bussiness_legal_name: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div
                            className="right-mcls"
                            style={{ color: "white", margin: 0 }}
                          >
                            <div className="form-profile">
                              <label
                                style={{
                                  color: this.state.business_descriptionRequired
                                    ? "red"
                                    : "black",
                                }}
                              >
                                Business Description
                              </label>
                              <input
                                type="text"
                                value={this.state.business_description}
                                onChange={(e) =>
                                  this.setState({
                                    business_description: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div
                            className="right-mcls"
                            style={{ color: "white", margin: 0 }}
                          >
                            <div className="form-profile">
                              <label
                                style={{
                                  color: this.state.website_urlsRequired
                                    ? "red"
                                    : "black",
                                }}
                              >
                                Website URLs
                              </label>
                              <input
                                type="text"
                                value={this.state.website_urls}
                                onChange={(e) =>
                                  this.setState({
                                    website_urls: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div
                            className="right-mcls"
                            style={{ color: "white", margin: 0 }}
                          >
                            <div className="form-profile">
                              <label
                                style={{
                                  color: this.state.business_establishedRequired
                                    ? "red"
                                    : "black",
                                }}
                              >
                                Business Established
                              </label>
                              <input
                                type="date"
                                value={this.state.business_established}
                                onChange={(e) =>
                                  this.setState({
                                    business_established: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div
                            className="btn-save-rmcls"
                            style={{ position: "absolute", bottom: -50 }}
                          >
                            <button
                              style={{ marginLeft: 10 }}
                              onClick={() => {
                                window.history.back();
                              }}
                            >
                              SLUITEN
                            </button>
                            {this.state.savtBtnText === "SAVE" ? (
                              <button>OPSLAAN</button>
                            ) : (
                              <button>
                                <i class="fa fa-spinner fa-spin"></i> OPSLAAN
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {this.state.privacyRight && (
              <div
                className="modal-create-album modalFullWidth orderModalFullWidth  modalcreatealbumchangedheader changedheadermodalcreatealbum"
                style={{ width: 400 }}
              >
                <div
                  className="center-fwnvvm centermakeheaderaanbieders"
                  style={{ color: "white", justifyContent: "space-between" }}
                >
                  <label>PRIVACY INSTELLINGEN</label>
                  <div>
                    <ChevronLeftIcon
                      style={{ fontSize: 20, marginRight: 10 }}
                      onClick={() => {
                        const { hideFriendsInfo } = this.state.currentUserMember;
                        let hideFriendsMain = false;
                        if (hideFriendsInfo) {
                          hideFriendsMain = hideFriendsInfo
                        }
                        if (
                          hideFriendsMain !== this.state.checkedHideFriendsInfo
                        ) {
                          this.setState({
                            beforeCloseMenuConfirmPrivacy: true,
                            closeOnlyCurrent: true,
                            closeIncludingRightMenu: false,
                          });
                          return false;
                        }
                        // localStorage.setItem("mustShowOpenRightMenu", true)
                        // setTimeout(() => {
                        //     window.history.back()
                        // }, 100);
                        if (this.props.fromMainPage) {
                          this.props.closeOnlyCurrent();
                        } else {
                          localStorage.setItem("mustShowOpenRightMenu", true);
                          setTimeout(() => {
                            window.history.back();
                          }, 100);
                        }
                      }}
                    />
                    <div className="spacing-between-icons"></div>
                    <CloseIcon
                      style={{ fontSize: 20, marginRight: 10 }}
                      onClick={() => {
                        const { hideFriendsInfo } = this.state.currentUserMember;
                        let hideFriendsMain = false;
                        if (hideFriendsInfo) {
                          hideFriendsMain = hideFriendsInfo
                        }
                        if (
                          hideFriendsMain !== this.state.checkedHideFriendsInfo
                        ) {
                          this.setState({
                            beforeCloseMenuConfirmPrivacy: true,
                            closeOnlyCurrent: false,
                            closeIncludingRightMenu: true,
                          });
                          return false;
                        }

                        if (this.props.fromMainPage) {
                          this.props.closeIncludingRightMenu();
                        } else {
                          window.history.back();
                        }
                      }}
                    />
                  </div>
                </div>
                {/* {!this.state.isDesktop && <ChevronLeftIcon onClick={() => {
                            window.history.back()
                        }} style={{ position: "static", top: 15, left: 10, fontSize: 18 }} />} */}
                <div className="body-mca groupsettingsbody">
                  <div className="form-body-mca">
                    <div className="inner-mca-settings">
                      <div
                        className="mca-settings-list"
                        style={{
                          flexDirection: "column",
                          backgroundColor: "transparent",
                          width: "90%",
                          margin: "10px auto",
                        }}
                      >
                        {/* <div className="top-mcls-one">
                                            <p>
                                                <AccountCircleOutlinedIcon />
                                                PRIVACY INSTELLINGEN
                                            </p>
                                        </div> */}
                        <div
                          className="wrapperforfullwidth"
                          style={{ position: "relative" }}
                        >
                          <div
                            className="right-mcls"
                            style={{ color: "#1C1C1C", flexDirection: "row" }}
                          >
                            <Switch
                              color="primary"
                              inputProps={{ "aria-label": "primary checkbox" }}
                              checked={this.state.checkedHideFriendsInfo}
                              onClick={() => {
                                this.setState({
                                  checkedHideFriendsInfo:
                                    !this.state.checkedHideFriendsInfo,
                                });
                              }}
                            />
                            {this.state.checkedHideFriendsInfo ? (
                              <font>Je vriendenpagina is nu verborgen</font>
                            ) : (
                              <font>Verberg vriendenpagina</font>
                            )}
                          </div>
                        </div>
                        <div
                          className="btn-save-rmcls"
                          style={{ position: "absolute", bottom: -50 }}
                        >
                          {/* <button style={{ marginLeft: 10 }} onClick={() => {
                                                window.history.back()
                                            }}>SLUITEN</button> */}
                          {this.state.savtBtnText === "SAVE" ? (
                            <button
                              style={{ backgroundColor: "#285151" }}
                              onClick={() => {
                                if (this.state.savtBtnText === "SAVE") {
                                  this.savePrivacy();
                                }
                              }}
                            >
                              OPSLAAN
                            </button>
                          ) : (
                            <button>
                              <i class="fa fa-spinner fa-spin"></i> OPSLAAN
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {this.state.emailPassword && (
              <div
                className="modal-create-album modalFullWidth orderModalFullWidth modalcreatealbumchangedheader changedheadermodalcreatealbum"
                style={{ width: 400 }}
              >
                <div
                  className="center-fwnvvm centermakeheaderaanbieders"
                  style={{ color: "white", justifyContent: "space-between" }}
                >
                  <label>INLOGGEGEVENS</label>
                  <div>
                    <ChevronLeftIcon
                      style={{ fontSize: 20, marginRight: 10 }}
                      onClick={() => {
                        const { email, user_name_id } =
                          this.state.currentUserMember;
                        if (
                          email !== this.state.email ||
                          user_name_id !== this.state.url ||
                          this.state.currentPassword ||
                          this.state.password ||
                          this.state.confirmPassword
                        ) {
                          this.setState({
                            beforeCloseMenuConfirmInloggevens: true,
                            closeOnlyCurrent: true,
                            closeIncludingRightMenu: false,
                          });
                          return false;
                        }
                        if (this.props.fromMainPage) {
                          this.props.closeOnlyCurrent();
                        } else {
                          localStorage.setItem("mustShowOpenRightMenu", true);
                          setTimeout(() => {
                            window.history.back();
                          }, 100);
                        }
                        // localStorage.setItem("mustShowOpenRightMenu", true)
                        // setTimeout(() => {
                        //     window.history.back()
                        // }, 100);
                      }}
                    />
                    <div className="spacing-between-icons"></div>
                    <CloseIcon
                      style={{ fontSize: 20, marginRight: 10 }}
                      onClick={() => {
                        const { email, user_name_id } =
                          this.state.currentUserMember;
                        if (
                          email !== this.state.email ||
                          user_name_id !== this.state.url ||
                          this.state.currentPassword ||
                          this.state.password ||
                          this.state.confirmPassword
                        ) {
                          this.setState({
                            beforeCloseMenuConfirmInloggevens: true,
                            closeOnlyCurrent: false,
                            closeIncludingRightMenu: true,
                          });
                          return false;
                        }
                        if (this.props.fromMainPage) {
                          this.props.closeIncludingRightMenu();
                        } else {
                          localStorage.setItem("mustShowOpenRightMenu", true);
                          setTimeout(() => {
                            window.history.back();
                          }, 100);
                        }
                      }}
                    />
                  </div>
                </div>
                {/* {!this.state.isDesktop && <ChevronLeftIcon onClick={() => {
                            window.history.back()
                        }} style={{ position: "static", top: 15, left: 10, fontSize: 18 }} />} */}
                <div className="body-mca groupsettingsbody">
                  <div className="form-body-mca">
                    <div className="inner-mca-settings">
                      <div
                        className="mca-settings-list"
                        style={{
                          flexDirection: "column",
                          backgroundColor: "transparent",
                          width: "90%",
                          margin: "10px auto",
                        }}
                      >
                        {/* <div className="top-mcls-one">
                                            <p>
                                                <AccountCircleOutlinedIcon />
                                                INLOGGEGEVENS
                                            </p>
                                        </div> */}
                        <div className="wrapperforfullwidth">
                          <div
                            className="right-mcls"
                            style={{ color: "white", margin: 0 }}
                          >
                            <div className="form-profile">
                              <label>Gebruikersnaam is je email adres</label>
                              <input
                                type="text"
                                value={this.state.email}
                                onChange={(e) =>
                                  this.setState({ email: e.target.value })
                                }
                              />
                              {this.state.emailAlreadyTooked && (
                                <p style={{ color: "red" }}>
                                  Email is already tooked!
                                </p>
                              )}
                            </div>
                          </div>
                          <div
                            className="right-mcls"
                            style={{ color: "white" }}
                          >
                            <div className="form-profile">
                              <label>
                                Link naar je profielpagina:
                                www.bhodi.com/company/{this.state.url}
                              </label>
                              <input
                                type="text"
                                placeholder="Page url"
                                value={this.state.url}
                                onChange={(e) => {
                                  let converted = e.target.value
                                    .split(" ")
                                    .join("");
                                  this.setState({
                                    url: converted.toLowerCase(),
                                  });
                                }}
                              />
                              {this.state.urlNotAvailable && (
                                <p style={{ color: "red" }}>
                                  Sorry! this URL is taken
                                </p>
                              )}
                            </div>
                          </div>
                          <div
                            className="right-mcls"
                            style={{ color: "white", margin: 0 }}
                          >
                            <div className="form-profile">
                              <label className="tmo-dup-heading">
                                Wachtwoord wijzigen
                              </label>
                            </div>
                          </div>
                          <div
                            className="right-mcls"
                            style={{ color: "white", margin: 0 }}
                          >
                            <div className="form-profile">
                              <label>Huidig wachtwoord</label>
                              <div className="toonpasswordcontainer">
                                <input
                                  type={
                                    !this.state.showcurrentPassword
                                      ? "password"
                                      : "text"
                                  }
                                  value={this.state.currentPassword}
                                  onChange={(e) =>
                                    this.setState({
                                      currentPassword: e.target.value,
                                    })
                                  }
                                />
                                <label
                                  onClick={() => {
                                    this.setState({
                                      showcurrentPassword:
                                        !this.state.showcurrentPassword,
                                    });
                                  }}
                                >
                                  toon
                                </label>
                              </div>
                              {this.state.fillCurrentPassword && (
                                <p style={{ color: "red" }}>
                                  Enter your right correct password!
                                </p>
                              )}
                            </div>
                          </div>
                          <div
                            className="right-mcls"
                            style={{ color: "white", margin: 0 }}
                          >
                            <div className="form-profile">
                              <label>Nieuw wachtwoord</label>
                              <div className="toonpasswordcontainer">
                                <input
                                  type={
                                    !this.state.showPassword
                                      ? "password"
                                      : "text"
                                  }
                                  value={this.state.password}
                                  onChange={(e) =>
                                    this.setState({ password: e.target.value })
                                  }
                                />
                                <label
                                  onClick={() => {
                                    this.setState({
                                      showPassword: !this.state.showPassword,
                                    });
                                  }}
                                >
                                  toon
                                </label>
                              </div>
                            </div>
                          </div>
                          <div
                            className="right-mcls"
                            style={{ color: "white", margin: 0 }}
                          >
                            <div className="form-profile">
                              <label>Herhaal wachtwoord</label>
                              <div className="toonpasswordcontainer">
                                <input
                                  type={
                                    !this.state.showconfirmPassword
                                      ? "password"
                                      : "text"
                                  }
                                  value={this.state.confirmPassword}
                                  onChange={(e) =>
                                    this.setState({
                                      confirmPassword: e.target.value,
                                    })
                                  }
                                />
                                <label
                                  onClick={() => {
                                    this.setState({
                                      showconfirmPassword:
                                        !this.state.showconfirmPassword,
                                    });
                                  }}
                                >
                                  toon
                                </label>
                              </div>
                              {this.state.changedPassword && (
                                <p style={{ color: "red" }}>
                                  Password and Confirm Password should be same
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div
                          className="btn-save-rmcls"
                          style={{
                            position: "absolute",
                            paddingTop: 25,
                            marginTop: 30,
                            paddingBottom: 20,
                          }}
                        >
                          {/* <button style={{ marginRight: 10, backgroundColor: "#285151" }} onClick={() => {
                                                this.setState({ currentPassword: "", password: "", confirmPassword: "", changePassword: "", fillCurrentPassword: "" })
                                            }}>ANNULEREN</button> */}
                          {/* <button style={{ marginLeft: 10 }} onClick={() => {
                                                window.history.back()
                                            }}>SLUITEN</button> */}
                          {this.state.savtBtnText === "SAVE" ? (
                            <button
                              style={{ backgroundColor: "#285151" }}
                              onClick={() => {
                                if (this.state.savtBtnText === "SAVE") {
                                  this.saveEmailPassword();
                                }
                              }}
                            >
                              OPSLAAN
                            </button>
                          ) : (
                            <button>
                              <i class="fa fa-spinner fa-spin"></i> OPSLAAN
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {this.state.myOrdersRight && (
              <div className="modal-create-album modalFullWidth orderModalFullWidth">
                <div
                  className="center-fwnvvm centermakeheaderaanbieders"
                  style={{ color: "white" }}
                >
                  <ChevronLeftIcon
                    style={{ fontSize: 20, marginRight: 10 }}
                    onClick={() => {
                      window.history.back();
                    }}
                  />
                </div>
                <RightMenuOrders
                  showFooter={true}
                  onSettingsPage={true}
                  closePopup={() => {
                    // document.getElementById("root").style.position = "static"
                    // this.setState({ ordersRightMenu: false })
                    // window.history.back()
                    this.props.closeOnlyCurrent();
                  }}
                  closePopupCompletely={() => {
                    this.props.closeIncludingRightMenu();
                  }}
                  showVerzilverPopup={(e) => {
                    this.setState({
                      validOnMobilePopup: true,
                      currentUserOfPopups: e,
                    });
                  }}
                  showVerzilverLastMinutePopup={(e) => {
                    this.setState({
                      validOnMobilePopup: true,
                      currentUserOfPopups: e,
                    });
                  }}
                  showVerzilverCharityPopup={(e) => {
                    this.setState({
                      validOnMobilePopup: true,
                      currentUserOfPopups: e,
                    });
                  }}
                  showVerzilverGiveawayPopup={(e) => {
                    this.setState({
                      validOnMobilePopup: true,
                      currentUserOfPopups: e,
                    });
                  }}
                  showVerzilverWebShopPopup={(e, redeem_code) => {
                    this.setState({
                      verzilverPopupWebShop: true,
                      currentUserOfPopups: e,
                      redeem_code,
                    });
                  }}
                />
              </div>
            )}
            {this.state.allOrdersRight && (
              <div className="modal-create-album modalFullWidth orderModalFullWidth klantbestellingenfullwidth">
                <div
                  className="center-fwnvvm centermakeheaderaanbieders"
                  style={{ color: "white", justifyContent: "space-between" }}
                >
                  <ChevronLeftIcon
                    style={{ fontSize: 20, marginRight: 10 }}
                    onClick={() => {
                      this.props.closeOnlyCurrent();
                      // window.history.back()
                    }}
                  />
                  <label>KLANTBESTELLINGEN</label>
                  <CloseIcon
                    style={{ fontSize: 20, marginRight: 10 }}
                    onClick={() => {
                      if (this.props.fromMainPage) {
                        this.props.closeIncludingRightMenu();
                      } else {
                        localStorage.setItem("mustShowOpenRightMenu", true);
                        setTimeout(() => {
                          window.history.back();
                        }, 100);
                      }
                    }}
                  />
                </div>
                {/* {!this.state.isDesktop && <ChevronLeftIcon onClick={() => {
                            window.history.back()
                        }} style={{ position: "static", top: 15, left: 10, fontSize: 18 }} />} */}
                {/* <OrdersCartUpdated fromSettingsPage={true} /> */}
                <RightMenuIncomingOrders
                  closePopup={() => {
                    if (this.props.fromMainPage) {
                      this.props.closeOnlyCurrent();
                    } else {
                      localStorage.setItem("mustShowOpenRightMenu", true);
                      setTimeout(() => {
                        window.history.back();
                      }, 100);
                    }
                  }}
                  closePopupComplete={() => {
                    if (this.props.fromMainPage) {
                      this.props.closeIncludingRightMenu();
                    } else {
                      window.history.back();
                    }
                  }} />
              </div>
            )}
            {this.state.showBhodiFaqs && (
              <div
                className="modal-create-album modalFullWidth orderModalFullWidth modalcreatealbumchangedheader changedheadermodalcreatealbum changedbackgroundmodalfaqabout"
                style={{ width: 400, paddingBottom: 60 }}
              >
                <div
                  className="center-fwnvvm centermakeheaderaanbieders"
                  style={{ color: "white", justifyContent: "space-between" }}
                >
                  <label>VEELGESTELDE VRAGEN</label>
                  <div>
                    <ChevronLeftIcon
                      style={{ fontSize: 20, marginRight: 10 }}
                      onClick={() => {
                        // localStorage.setItem("mustShowOpenRightMenu", true)
                        // setTimeout(() => {
                        //     window.history.back()
                        // }, 100);
                        if (this.props.fromMainPage) {
                          this.props.closeOnlyCurrent();
                        } else {
                          localStorage.setItem("mustShowOpenRightMenu", true);
                          setTimeout(() => {
                            window.history.back();
                          }, 100);
                        }
                      }}
                    />
                    <div className="spacing-between-icons"></div>
                    <CloseIcon
                      style={{ fontSize: 20, marginRight: 10 }}
                      onClick={() => {
                        if (this.props.fromMainPage) {
                          this.props.closeIncludingRightMenu();
                        } else {
                          window.history.back();
                        }
                      }}
                    />
                  </div>
                </div>
                {/* {!this.state.isDesktop && <ChevronLeftIcon onClick={() => {
                            window.history.back()
                        }} style={{ position: "static", top: 15, left: 10, fontSize: 18 }} />} */}
                <div className="body-mca groupsettingsbody">
                  <div className="form-body-mca">
                    <div className="inner-mca-settings">
                      <div
                        className="mca-settings-list"
                        style={{
                          flexDirection: "column",
                          backgroundColor: "transparent",
                          width: "90%",
                          margin: "10px auto",
                        }}
                      >
                        {/* <div className="top-mcls-one">
                                            <p>
                                                <AccountCircleOutlinedIcon />
                                                VEELGESTELDE VRAGEN
                                            </p>
                                        </div> */}
                        <div
                          className="wrapperforfullwidth wrapperbhodifaqs"
                          style={{ position: "relative" }}
                        >
                          {this.state.bhodiFaqs.length > 0 &&
                            this.state.bhodiFaqs.map((y, i) => {
                              if (y.title) {
                                return (
                                  <div className="faq-inner-main">
                                    <div
                                      className="top-faq-rmcls"
                                      onClick={() => {
                                        if (y.descMobile) {
                                          this.state.arrDropdowns[i].opened =
                                            !this.state.arrDropdowns[i].opened;
                                          this.setState({
                                            arrDropdowns:
                                              this.state.arrDropdowns,
                                          });
                                        }
                                      }}
                                    >
                                      <p>{y.title}</p>
                                      {this.state.arrDropdowns[i].opened
                                        ? y.descMobile && <RemoveIcon />
                                        : y.descMobile && <AddIcon />}
                                    </div>
                                    {this.state.arrDropdowns[i].opened && (
                                      <div className="bottom-faq-rmcls">
                                        {/* <p>{y.desc}</p> */}
                                        <div
                                          dangerouslySetInnerHTML={this.createMarkup(
                                            y.descMobile
                                          )}
                                        ></div>
                                      </div>
                                    )}
                                  </div>
                                );
                              }
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {this.state.showBhodiAbouts && (
              <div
                className="modal-create-album modalFullWidth orderModalFullWidth modalcreatealbumchangedheader changedheadermodalcreatealbum changedbackgroundmodalfaqabout"
                style={{ width: 400, paddingBottom: 60 }}
              >
                <div
                  className="center-fwnvvm centermakeheaderaanbieders"
                  style={{ color: "white", justifyContent: "space-between" }}
                >
                  <label>ABOUT</label>
                  <div>
                    <ChevronLeftIcon
                      style={{ fontSize: 20, marginRight: 10 }}
                      onClick={() => {
                        if (this.props.fromMainPage) {
                          this.props.closeOnlyCurrent();
                        } else {
                          localStorage.setItem("mustShowOpenRightMenu", true);
                          setTimeout(() => {
                            window.history.back();
                          }, 100);
                        }
                      }}
                    />
                    <div className="spacing-between-icons"></div>
                    <CloseIcon
                      style={{ fontSize: 20, marginRight: 10 }}
                      onClick={() => {
                        if (this.props.fromMainPage) {
                          this.props.closeIncludingRightMenu();
                        } else {
                          window.history.back();
                        }
                      }}
                    />
                  </div>
                </div>
                {/* {!this.state.isDesktop && <ChevronLeftIcon onClick={() => {
                            window.history.back()
                        }} style={{ position: "static", top: 15, left: 10, fontSize: 18 }} />} */}
                <div className="body-mca groupsettingsbody">
                  <div className="form-body-mca">
                    <div className="inner-mca-settings">
                      <div
                        className="mca-settings-list"
                        style={{
                          flexDirection: "column",
                          backgroundColor: "transparent",
                          width: "90%",
                          margin: "10px auto",
                        }}
                      >
                        {/* <div className="top-mcls-one">
                                            <p>
                                                <AccountCircleOutlinedIcon />
                                                VEELGESTELDE VRAGEN
                                            </p>
                                        </div> */}
                        <div
                          className="wrapperforfullwidth wrapperbhodifaqs"
                          style={{ position: "relative" }}
                        >
                          {this.state.bhodiAbouts.length > 0 &&
                            this.state.bhodiAbouts.map((y, i) => {
                              if (y.title) {
                                return (
                                  <div className="faq-inner-main">
                                    <div
                                      className="top-faq-rmcls"
                                      onClick={() => {
                                        if (y.desc) {
                                          this.state.arrDropdownsAbouts[
                                            i
                                          ].opened =
                                            !this.state.arrDropdownsAbouts[i]
                                              .opened;
                                          this.setState({
                                            arrDropdownsAbouts:
                                              this.state.arrDropdownsAbouts,
                                          });
                                        }
                                      }}
                                    >
                                      <p>{y.title}</p>
                                      {this.state.arrDropdownsAbouts[i].opened
                                        ? y.descMobile && <RemoveIcon />
                                        : y.descMobile && <AddIcon />}
                                    </div>
                                    {this.state.arrDropdownsAbouts[i]
                                      .opened && (
                                        <div className="bottom-faq-rmcls">
                                          {/* <p>{y.desc}</p> */}
                                          <div
                                            dangerouslySetInnerHTML={this.createMarkup(
                                              y.descMobile
                                            )}
                                          ></div>
                                          {y.image && <img src={y.image} />}
                                        </div>
                                      )}
                                  </div>
                                );
                              }
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {this.state.voucherSettings && (
              <div
                className="modal-create-album modalFullWidth orderModalFullWidth modalcreatealbumchangedheader changedheadermodalcreatealbum changedheadermodallabelnomargin"
                style={{ width: 400 }}
              >
                <div
                  className="center-fwnvvm centermakeheaderaanbieders"
                  style={{ color: "white", justifyContent: "space-between" }}
                >
                  <label>VOUCHER INSTELLINGEN</label>
                  <div>
                    <ChevronLeftIcon
                      style={{ fontSize: 20, marginRight: 10 }}
                      onClick={() => {
                        let {
                          useOfVoucherLocalEvent,
                          useOfVoucherKortingsVoucher
                        } = this.state.currentUserMember;
                        if (
                          useOfVoucherLocalEvent === undefined ||
                          useOfVoucherLocalEvent === null
                        ) {
                          useOfVoucherLocalEvent = "";
                        }
                        if (
                          useOfVoucherKortingsVoucher === undefined ||
                          useOfVoucherKortingsVoucher === null
                        ) {
                          useOfVoucherKortingsVoucher = "";
                        }
                        if (useOfVoucherLocalEvent !== this.state.useOfVoucherLocalEvent || useOfVoucherKortingsVoucher !== this.state.useOfVoucherKortingsVoucher) {
                          this.setState({
                            beforeCloseMenuConfirmVoucher: true,
                            closeOnlyCurrent: true,
                            closeIncludingRightMenu: false,
                          });
                          return false;
                        }
                        if (this.props.fromMainPage) {
                          this.props.closeOnlyCurrent();
                        } else {
                          localStorage.setItem("mustShowOpenRightMenu", true);
                          setTimeout(() => {
                            window.history.back();
                          }, 100);
                        }
                      }}
                    />
                    <div className="spacing-between-icons"></div>
                    <CloseIcon
                      style={{ fontSize: 20, marginRight: 10 }}
                      onClick={() => {
                        let {
                          useOfVoucherLocalEvent,
                          useOfVoucherKortingsVoucher,
                        } = this.state.currentUserMember;
                        if (
                          useOfVoucherLocalEvent === undefined ||
                          useOfVoucherLocalEvent === null
                        ) {
                          useOfVoucherLocalEvent = "";
                        }
                        if (
                          useOfVoucherKortingsVoucher === undefined ||
                          useOfVoucherKortingsVoucher === null
                        ) {
                          useOfVoucherKortingsVoucher = "";
                        }
                        if (useOfVoucherLocalEvent !== this.state.useOfVoucherLocalEvent || useOfVoucherKortingsVoucher !== this.state.useOfVoucherKortingsVoucher) {
                          this.setState({
                            beforeCloseMenuConfirmVoucher: true,
                            closeOnlyCurrent: false,
                            closeIncludingRightMenu: true,
                          });
                          return false;
                        }
                        if (this.props.fromMainPage) {
                          this.props.closeIncludingRightMenu();
                        } else {
                          localStorage.setItem("mustShowOpenRightMenu", true);
                          setTimeout(() => {
                            window.history.back();
                          }, 100);
                        }
                      }}
                    />
                  </div>
                </div>
                {/* {!this.state.isDesktop && <ChevronLeftIcon onClick={() => {
                            window.history.back()
                        }} style={{ position: "static", top: 15, left: 10, fontSize: 18 }} />} */}
                <div className="body-mca groupsettingsbody">
                  <div className="form-body-mca formbodyvouchersettings">
                    <div className="inner-mca-settings">
                      <div
                        className="mca-settings-list"
                        style={{
                          flexDirection: "column",
                          backgroundColor: "transparent",
                          width: "90%",
                          margin: "10px auto",
                        }}
                      >
                        {/* <div className="top-mcls-one" style={{ flexDirection: "row", alignItems: "center" }}>
                                            <p>
                                                <AccountCircleOutlinedIcon />
                                                VOUCHER INSTELLINGEN
                                            </p>
                                        </div> */}
                        <div
                          className="wrapperforfullwidth vouchersettingswrapperform"
                          style={{
                            alignItems: "flex-start",
                            position: "relative",
                          }}
                        >
                          <div
                            className="right-mcls"
                            style={{ color: "white", margin: 0 }}
                          >
                            <div
                              className="form-profile"
                              style={{ position: "relative" }}
                            >
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <label>Voorwaarden</label>
                                {/* {this.state.currentUserMember.requirement_file && <label style={{ marginLeft: 22 }}>{this.state.currentUserMember.file_name}</label>} */}
                                {!this.state.currentUserMember
                                  .requirement_file && (
                                    <label style={{ position: "relative", width: "auto" }}>
                                      <PublishIcon style={{ margin: 0 }} />
                                      <input
                                        type="file"
                                        style={{
                                          position: "absolute",
                                          width: "100%",
                                          height: "100%",
                                          top: 0,
                                          bottom: 0,
                                          left: 0,
                                          right: 0,
                                          opacity: 0,
                                        }}
                                        accept=".pdf,.doc"
                                        onChange={(e) => this.fileUploadpdf(e)}
                                      />
                                    </label>
                                  )}
                              </div>
                              {this.state.currentUserMember
                                .requirement_file && (
                                  <DeleteIcon
                                    style={{
                                      margin: 0,
                                      color: "black",
                                      position: "absolute",
                                      top: 2,
                                      right: 4,
                                      fontSize: 20,
                                    }}
                                    onClick={this.deletePdf.bind(this)}
                                  />
                                )}
                              {!this.state.currentUserMember
                                .requirement_file ? (
                                <textarea
                                  value={this.state.termsText}
                                  onChange={(e) =>
                                    this.setState({ termsText: e.target.value })
                                  }
                                  style={{ marginTop: 20 }}
                                ></textarea>
                              ) : (
                                <input
                                  value={this.state.currentUserMember.file_name}
                                  style={{ marginTop: 20 }}
                                  disabled
                                />
                              )}
                            </div>
                          </div>
                          <div
                            className="right-mcls"
                            style={{ color: "white", margin: 0 }}
                          >
                            <div
                              className="form-profile"
                              style={{ position: "relative" }}
                            >
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <label>Geldigheidsperiode van de voucher</label>
                              </div>
                            </div>
                          </div>
                          <div className="right-mcls" style={{ marginTop: 0 }}>
                            <div
                              className="form-profile"
                              style={{ position: "relative" }}
                            >
                              <div className='wrapper-select-new'>
                                <select
                                  value={this.state.expireTypeVoucher}
                                  onChange={(e) => {
                                    this.setState({ expireTypeVoucher: e.target.value, expireDateVoucher: "" })
                                  }}
                                >
                                  {!this.state.expireTypeVoucher && (<option value=""></option>)}
                                  <option value="daily">Aantal dagen geldig</option>
                                  <option value="weekly">Aantal weken geldig</option>
                                  <option value="monthly">Aantal maanden geldig</option>
                                </select>
                                <ExpandMoreIcon
                                  style={{
                                    cursor: "pointer",
                                    position: "absolute",
                                    right: 5,
                                    bottom: 10,
                                    fontSize: 18,
                                    pointerEvents: "none",
                                    pointerEvents: "none",
                                  }}
                                  className='expand-more'
                                />
                              </div>
                            </div>
                            <div
                              className="form-profile kortingnumberdiv"
                              style={{ position: "relative" }}
                            >
                              <div className='wrapper-select-new'>
                                <select
                                  value={this.state.expireDateVoucher}
                                  onChange={(e) => {
                                    this.setState({ expireDateVoucher: e.target.value })
                                  }}
                                >
                                  {!this.state.expireDateVoucher && (<option value=""></option>)}
                                  {this.state.expireTypeVoucher === "weekly" && [1, 2, 3, 4].map((week) => {
                                    return <option value={week}>{week} {week > 1 ? `weeks` : `week`}</option>
                                  })}
                                  {this.state.expireTypeVoucher === "daily" && [1, 2, 3, 4, 5, 6, 7].map((day) => {
                                    return <option value={day}>{day} {day > 1 ? `days` : `day`}</option>
                                  })}
                                  {this.state.expireTypeVoucher === "monthly" && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month) => {
                                    return <option value={month}>{month} {month > 1 ? `months` : `month`}</option>
                                  })}
                                </select>
                                <ExpandMoreIcon
                                  style={{
                                    cursor: "pointer",
                                    position: "absolute",
                                    right: 5,
                                    bottom: 10,
                                    fontSize: 18,
                                    pointerEvents: "none",
                                    pointerEvents: "none",
                                  }}
                                  className='expand-more'
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            className="right-mcls"
                            style={{ color: "white", margin: 0 }}
                          ></div>
                          <div
                            className="right-mcls"
                            style={{
                              color: "white",
                              margin: 0,
                              padding: "20px 0px",
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                height: 2,
                                backgroundColor: "#d3d3d3",
                              }}
                            ></div>
                          </div>
                          <div
                            className="right-mcls"
                            style={{ color: "white", margin: 0 }}
                          ></div>
                          <div
                            className="right-mcls"
                            style={{ color: "white", margin: 0 }}
                          >
                            <div className="form-profile">
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <label style={{ marginBottom: 0 }}>
                                  Gebruik events
                                </label>
                                {this.state.gebruikLastMinuteDown ? (
                                  <KeyboardArrowUpIcon
                                    style={{
                                      color: "black",
                                      cursor: "pointer",
                                      margin: 0,
                                    }}
                                    onClick={() =>
                                      this.setState({
                                        gebruikLastMinuteDown: false,
                                      })
                                    }
                                  />
                                ) : (
                                  <KeyboardArrowDownIcon
                                    style={{
                                      color: "black",
                                      cursor: "pointer",
                                      margin: 0,
                                    }}
                                    onClick={() =>
                                      this.setState({
                                        gebruikCharityDown: false,
                                        gebruikGiveAwayDown: false,
                                        gebruikLastMinuteDown: true,
                                        gebruikWebShopDown: false,
                                        gebruikVanDown: false,
                                        gebruikKortingsDown: false,
                                        voorwaardenDown: false,
                                      })
                                    }
                                  />
                                )}
                              </div>
                              {this.state.gebruikLastMinuteDown && (
                                <textarea
                                  style={{ marginTop: 20 }}
                                  value={this.state.useOfVoucherLocalEvent}
                                  onChange={(e) =>
                                    this.setState({
                                      useOfVoucherLocalEvent: e.target.value,
                                    })
                                  }
                                ></textarea>
                              )}
                            </div>
                          </div>
                          <div
                            className="right-mcls"
                            style={{ color: "white", margin: 0 }}
                          >
                            <div className="form-profile">
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <label style={{ marginBottom: 0 }}>
                                  Gebruik KortingsVoucher
                                </label>
                                {this.state.gebruikKortingsDown ? (
                                  <KeyboardArrowUpIcon
                                    style={{
                                      color: "black",
                                      cursor: "pointer",
                                      margin: 0,
                                    }}
                                    onClick={() =>
                                      this.setState({
                                        gebruikKortingsDown: false,
                                      })
                                    }
                                  />
                                ) : (
                                  <KeyboardArrowDownIcon
                                    style={{
                                      color: "black",
                                      cursor: "pointer",
                                      margin: 0,
                                    }}
                                    onClick={() =>
                                      this.setState({
                                        gebruikLastMinuteDown: false,
                                        gebruikKortingsDown: true,
                                      })
                                    }
                                  />
                                )}
                              </div>
                              {this.state.gebruikKortingsDown && (
                                <textarea
                                  style={{ marginTop: 20 }}
                                  value={this.state.useOfVoucherKortingsVoucher}
                                  onChange={(e) =>
                                    this.setState({
                                      useOfVoucherKortingsVoucher: e.target.value,
                                    })
                                  }
                                ></textarea>
                              )}
                            </div>
                          </div>
                        </div>
                        <div
                          className="btn-save-rmcls"
                          style={{ position: "absolute", bottom: -50 }}
                        >
                          {this.state.savtBtnText === "SAVE" ? (
                            <button
                              style={{ backgroundColor: "#285151" }}
                              onClick={() => {
                                if (this.state.savtBtnText === "SAVE") {
                                  this.saveVoucherSettings();
                                }
                              }}
                            >
                              OPSLAAN
                            </button>
                          ) : (
                            <button>
                              <i class="fa fa-spinner fa-spin"></i> OPSLAAN
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {this.state.isDesktop ? (
              <div className="modal-create-album" style={{ width: 400 }}>
              </div>
            ) : (
              this.state.menuItemsModals && (
                <div className="modal-create-album" style={{ width: 400 }}>
                  <div
                    className="heading-mca"
                    style={{
                      textAlign: "center",
                      position: "relative",
                      padding: "13px",
                    }}
                  >
                    <font>PROFIELINSTELLINGEN</font>
                    <CloseIcon
                      onClick={() => {
                        let element = document.getElementById("footermain");
                        if (element) {
                          element.style.display = "flex";
                        }
                        if (window.innerWidth < 1009) {
                          document.getElementById("root").style.position =
                            "static";
                          document.getElementById("root").style.overflow =
                            "hidden";
                        }
                        if (this.props.closeAboutBhodiRightMenu) {
                          this.props.closeAboutBhodiRightMenu();
                        }
                        this.setState({ rightModalSettings: false });
                      }}
                      style={{
                        position: "absolute",
                        top: "50%",
                        transform: "translateY(-50%)",
                        right: 10,
                        fontSize: 24,
                      }}
                    />
                  </div>
                  <div className="body-mca groupsettingsbody">
                    <div className="form-body-mca">
                      <div className="inner-mca-settings">
                        <div
                          className="mca-settings-list"
                          style={{
                            flexDirection: "column",
                            backgroundColor: "transparent",
                            height: "auto",
                          }}
                        >
                          <div
                            className="right-mcls imageinnermca"
                            style={{
                              color: "white",
                              position: "relative",
                              maxWidth: 70,
                              margin: "0px auto",
                            }}
                          >
                            {this.state.currentUserMember.profile_image ? (
                              <img
                                src={this.state.currentUserMember.profile_image}
                              />
                            ) : (
                              <img src="https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9" />
                            )}
                            <div className="imginnermcaadd">
                              <AddIcon />
                            </div>
                            <input
                              type="file"
                              accept="image/*"
                              onChange={(e) => this.fileUpload(e)}
                            />
                          </div>
                          <div
                            className="right-mcls imageinnermca"
                            style={{ color: "white" }}
                          >
                            <font>{this.state.companyName}</font>
                          </div>
                          <div className="line-rcmls"></div>
                          <div
                            className="right-mcls"
                            style={{ color: "white", paddingLeft: 20 }}
                          >
                            <font
                              onClick={() => {
                                if (this.props.closeAboutBhodiRightMenu) {
                                  this.props.closeAboutBhodiRightMenu();
                                }
                                this.setState({
                                  userInfoRight: true,
                                  privacyRight: false,
                                  emailPassword: false,
                                  shopSettings: false,
                                  myOrdersRight: false,
                                  allOrdersRight: false,
                                  menuItemsModals: false,
                                  shopSettings: false,
                                  voucherSettings: false,
                                  showSubscribedContent: false,
                                  showBhodiFaqs: false,
                                  showBhodiAbouts: false,
                                });
                              }}
                            >
                              MIJN GEGEVENS
                            </font>
                          </div>
                          <div
                            className="right-mcls"
                            style={{ color: "white", paddingLeft: 20 }}
                          >
                            <font
                              onClick={() => {
                                if (this.props.closeAboutBhodiRightMenu) {
                                  this.props.closeAboutBhodiRightMenu();
                                }
                                this.setState({
                                  privacyRight: true,
                                  userInfoRight: false,
                                  emailPassword: false,
                                  shopSettings: false,
                                  myOrdersRight: false,
                                  allOrdersRight: false,
                                  menuItemsModals: false,
                                  shopSettings: false,
                                  voucherSettings: false,
                                  showSubscribedContent: false,
                                  showBhodiFaqs: false,
                                  showBhodiAbouts: false,
                                });
                              }}
                            >
                              PRIVACY INSTELLINGEN
                            </font>
                          </div>
                          <div
                            className="right-mcls"
                            style={{ color: "white", paddingLeft: 20 }}
                          >
                            <font
                              onClick={() => {
                                if (this.props.closeAboutBhodiRightMenu) {
                                  this.props.closeAboutBhodiRightMenu();
                                }
                                this.setState({
                                  emailPassword: true,
                                  privacyRight: false,
                                  userInfoRight: false,
                                  shopSettings: false,
                                  myOrdersRight: false,
                                  allOrdersRight: false,
                                  menuItemsModals: false,
                                  shopSettings: false,
                                  voucherSettings: false,
                                  showSubscribedContent: false,
                                  showBhodiFaqs: false,
                                  showBhodiAbouts: false,
                                });
                              }}
                            >
                              INLOGGEGEVENS
                            </font>
                          </div>
                          <div
                            className="right-mcls"
                            style={{ color: "white", paddingLeft: 20 }}
                          >
                            <font
                              onClick={() => {
                                if (this.props.closeAboutBhodiRightMenu) {
                                  this.props.closeAboutBhodiRightMenu();
                                }
                                this.setState({
                                  myOrdersRight: true,
                                  shopSettings: false,
                                  privacyRight: false,
                                  emailPassword: false,
                                  userInfoRight: false,
                                  allOrdersRight: false,
                                  menuItemsModals: false,
                                  shopSettings: false,
                                  voucherSettings: false,
                                  showSubscribedContent: false,
                                  showBhodiFaqs: false,
                                  showBhodiAbouts: false,
                                });
                              }}
                            >
                              MIJN ORDEROVERZICHT
                            </font>
                          </div>
                          <div
                            style={{
                              width: "90%",
                              height: 1,
                              margin: "20px auto",
                              backgroundColor: "#1d3d3e",
                            }}
                          ></div>
                          <div
                            className="right-mcls"
                            style={{ color: "white", paddingLeft: 20 }}
                          >
                            <font
                              onClick={() => {
                                if (this.props.closeAboutBhodiRightMenu) {
                                  this.props.closeAboutBhodiRightMenu();
                                }
                                this.setState({
                                  myOrdersRight: false,
                                  shopSettings: false,
                                  privacyRight: false,
                                  emailPassword: false,
                                  userInfoRight: false,
                                  allOrdersRight: true,
                                  menuItemsModals: false,
                                  shopSettings: false,
                                  voucherSettings: false,
                                  showSubscribedContent: false,
                                  showBhodiFaqs: false,
                                  showBhodiAbouts: false,
                                });
                              }}
                            >
                              ONTVANGEN ORDERS
                            </font>
                          </div>
                          {/* {this.state.currentUserMember.subscribed_content && <div className={`right-mcls ${this.state.allOrdersRight && "active"}`} style={{ color: "white", paddingLeft: 20 }}>
                                            <font onClick={() => this.setState({ myOrdersRight: false, shopSettings: false, privacyRight: false, emailPassword: false, userInfoRight: false, allOrdersRight: false, showSubscribedContent: true })}>MIJN ABONNEMENTEN</font>
                                        </div>}
                                        <div className="right-mcls" style={{ color: "white", paddingLeft: 20 }}>
                                            <font onClick={() => this.setState({ shopSettings: true, privacyRight: false, emailPassword: false, userInfoRight: false, myOrdersRight: false, allOrdersRight: false, shopSettings: false, voucherSettings: false, showSubscribedContent: false })}>PAYMENT SETTINGS</font>
                                        </div> */}
                          <div
                            className="right-mcls"
                            style={{ color: "white", paddingLeft: 20 }}
                          >
                            <font
                              onClick={() => {
                                if (this.props.closeAboutBhodiRightMenu) {
                                  this.props.closeAboutBhodiRightMenu();
                                }
                                this.setState({
                                  myOrdersRight: false,
                                  privacyRight: false,
                                  emailPassword: false,
                                  userInfoRight: false,
                                  allOrdersRight: false,
                                  menuItemsModals: false,
                                  shopSettings: false,
                                  voucherSettings: true,
                                  showSubscribedContent: false,
                                  showBhodiFaqs: false,
                                  showBhodiAbouts: false,
                                });
                              }}
                            >
                              VOUCHER INSTELLINGEN
                            </font>
                          </div>
                          {this.state.bhodiFaqs.length > 0 && (
                            <div
                              className="right-mcls"
                              style={{ color: "white", paddingLeft: 20 }}
                            >
                              <font
                                onClick={() => {
                                  if (this.props.closeAboutBhodiRightMenu) {
                                    this.props.closeAboutBhodiRightMenu();
                                  }
                                  this.setState({
                                    myOrdersRight: false,
                                    privacyRight: false,
                                    emailPassword: false,
                                    userInfoRight: false,
                                    allOrdersRight: false,
                                    menuItemsModals: false,
                                    shopSettings: false,
                                    voucherSettings: false,
                                    showBhodiFaqs: true,
                                    showSubscribedContent: false,
                                    showBhodiAbouts: false,
                                  });
                                }}
                              >
                                FAQ
                              </font>
                            </div>
                          )}
                          {this.state.bhodiAbouts.length > 0 && (
                            <div
                              className="right-mcls"
                              style={{ color: "white", paddingLeft: 20 }}
                            >
                              <font
                                onClick={() => {
                                  // if (this.props.closeAboutBhodiRightMenu) {
                                  //     this.props.closeAboutBhodiRightMenu()
                                  // }
                                  this.setState({
                                    myOrdersRight: false,
                                    privacyRight: false,
                                    emailPassword: false,
                                    userInfoRight: false,
                                    allOrdersRight: false,
                                    menuItemsModals: false,
                                    shopSettings: false,
                                    voucherSettings: false,
                                    showBhodiAbouts: true,
                                    showBhodiFaqs: false,
                                    showSubscribedContent: false,
                                  });
                                }}
                              >
                                ABOUT
                              </font>
                            </div>
                          )}
                          <div
                            className="right-mcls logout-mcls"
                            style={{ color: "white" }}
                          >
                            <ExitToAppIcon onClick={this.logout.bind(this)} />
                            <font onClick={this.logout.bind(this)}>
                              UITLOGGEN
                            </font>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        )}

        <Dialog
          open={this.state.cancelSubscriptionPopup}
          onClose={() => this.setState({ cancelSubscriptionPopup: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ zIndex: 99999999999999999 }}
          className="edit-dialog-cdd"
        >
          <DialogTitle id="alert-dialog-title">OPGELET</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Je bent nog Charity Member.
              <br />
              <br />
              Om je account te verwijderen zul je eerst<br />
              je maandelijkse bijdrage moeten stopzetten.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <button
              onClick={() => {
                this.setState({
                  cancelSubscriptionPopup: false,
                  showDelete: true,
                });
                axios
                  .post(
                    "https://us-central1-bhodi-production-da765.cloudfunctions.net/cancelStripeCustomerSubscription",
                    {
                      subscriptionId:
                        this.state.currentUserMember.subscription_id,
                      customerId: this.state.currentUserMember.stripe_id,
                    }
                  )
                  .then(() => {
                    axios
                      .post(
                        "https://us-central1-bhodi-production-da765.cloudfunctions.net/deleteStripeCustomer",
                        {
                          customerId: this.state.currentUserMember.stripe_id,
                        }
                      )
                      .then(() => {
                        this.deleteCurrentUser();
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }}
              className="stopplan-button"
            >
              BIJDRAGE STOPPEN
            </button>
            <Button
              onClick={() => this.setState({ cancelSubscriptionPopup: false })}
              color="primary"
              className="close-modal-btn-edcd"
            >
              <CloseIcon />
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.beforeCloseMenuConfirm}
          onClose={() => this.setState({ beforeCloseMenuConfirm: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ zIndex: 99999999999999999 }}
          className="edit-dialog-cdd smallertitleedcdd"
        >
          <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Weet je zeker dat je verder <br /> wilt zonder op te slaan?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setState({ beforeCloseMenuConfirm: false })}
              color="primary"
              className="close-modal-btn-edcd"
            >
              <CloseIcon />
            </Button>
            <Button
              onClick={() => {
                this.setState({
                  beforeCloseMenuConfirm: false,
                  // userInfoRight: false,
                });
                this.saveInfo("goback");
              }}
              color="primary"
            >
              OPSLAAN
            </Button>
            <Button
              onClick={() => {
                this.setState({
                  beforeCloseMenuConfirm: false,
                });
                // if (this.state.closeOnlyCurrent) {
                //   this.props.closeOnlyCurrent();
                // }
                // if (this.state.closeIncludingRightMenu) {
                //   this.props.closeIncludingRightMenu();
                // }
              }}
              color="primary"
            >
              NIET OPSLAAN
            </Button>
            <Button
              onClick={() => {
                // window.history.back()
                if (this.state.closeOnlyCurrent) {
                  this.props.closeOnlyCurrent();
                }
                if (this.state.closeIncludingRightMenu) {
                  this.props.closeIncludingRightMenu();
                }
              }}
              color="primary"
            >
              ANNULEREN
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.beforeCloseMenuConfirmVoucher}
          onClose={() =>
            this.setState({ beforeCloseMenuConfirmVoucher: false })
          }
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ zIndex: 99999999999999999 }}
          className="edit-dialog-cdd smallertitleedcdd"
        >
          <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Weet je zeker dat je verder <br /> wilt zonder op te slaan?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() =>
                this.setState({ beforeCloseMenuConfirmVoucher: false })
              }
              color="primary"
              className="close-modal-btn-edcd"
            >
              <CloseIcon />
            </Button>
            <Button
              onClick={() => {
                this.setState({
                  beforeCloseMenuConfirmVoucher: false,
                  // voucherSettings: false,
                });
                this.saveVoucherSettings("goback");
              }}
              color="primary"
            >
              OPSLAAN
            </Button>
            <Button
              onClick={() => {
                this.setState({
                  beforeCloseMenuConfirmVoucher: false,
                });
                // if (this.state.closeOnlyCurrent) {
                //   this.props.closeOnlyCurrent();
                // }
                // if (this.state.closeIncludingRightMenu) {
                //   this.props.closeIncludingRightMenu();
                // }
              }}
              color="primary"
            >
              NIET OPSLAAN
            </Button>
            <Button
              onClick={() => {
                // window.history.back()
                if (this.state.closeOnlyCurrent) {
                  this.props.closeOnlyCurrent();
                }
                if (this.state.closeIncludingRightMenu) {
                  this.props.closeIncludingRightMenu();
                }
              }}
              color="primary"
            >
              ANNULEREN
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.beforeCloseMenuConfirmPrivacy}
          onClose={() =>
            this.setState({ beforeCloseMenuConfirmPrivacy: false })
          }
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ zIndex: 99999999999999999 }}
          className="edit-dialog-cdd smallertitleedcdd"
        >
          <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Weet je zeker dat je verder <br /> wilt zonder op te slaan?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() =>
                this.setState({ beforeCloseMenuConfirmPrivacy: false })
              }
              color="primary"
              className="close-modal-btn-edcd"
            >
              <CloseIcon />
            </Button>
            <Button
              onClick={() => {
                this.setState({
                  beforeCloseMenuConfirmPrivacy: false,
                  // privacyRight: false,
                });
                this.savePrivacy("goback");
              }}
              color="primary"
            >
              OPSLAAN
            </Button>
            <Button
              onClick={() => {
                this.setState({
                  beforeCloseMenuConfirmPrivacy: false,
                });
                // if (this.state.closeOnlyCurrent) {
                //   this.props.closeOnlyCurrent();
                // }
                // if (this.state.closeIncludingRightMenu) {
                //   this.props.closeIncludingRightMenu();
                // }
              }}
              color="primary"
            >
              NIET OPSLAAN
            </Button>
            <Button
              onClick={() => {
                // window.history.back()
                if (this.state.closeOnlyCurrent) {
                  this.props.closeOnlyCurrent();
                }
                if (this.state.closeIncludingRightMenu) {
                  this.props.closeIncludingRightMenu();
                }
              }}
              color="primary"
            >
              ANNULEREN
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.beforeCloseMenuConfirmInloggevens}
          onClose={() =>
            this.setState({ beforeCloseMenuConfirmInloggevens: false })
          }
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ zIndex: 99999999999999999 }}
          className="edit-dialog-cdd smallertitleedcdd"
        >
          <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Weet je zeker dat je verder <br /> wilt zonder op te slaan?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() =>
                this.setState({ beforeCloseMenuConfirmInloggevens: false })
              }
              color="primary"
              className="close-modal-btn-edcd"
            >
              <CloseIcon />
            </Button>
            <Button
              onClick={() => {
                this.setState({
                  beforeCloseMenuConfirmInloggevens: false,
                  // emailPassword: false,
                });
                this.saveEmailPassword("goback");
              }}
              color="primary"
            >
              OPSLAAN
            </Button>
            <Button
              onClick={() => {
                this.setState({
                  beforeCloseMenuConfirmInloggevens: false,
                });
                // if (this.state.closeOnlyCurrent) {
                //   this.props.closeOnlyCurrent();
                // }
                // if (this.state.closeIncludingRightMenu) {
                //   this.props.closeIncludingRightMenu();
                // }
              }}
              color="primary"
            >
              NIET OPSLAAN
            </Button>
            <Button
              onClick={() => {
                // window.history.back()
                if (this.state.closeOnlyCurrent) {
                  this.props.closeOnlyCurrent();
                }
                if (this.state.closeIncludingRightMenu) {
                  this.props.closeIncludingRightMenu();
                }
              }}
              color="primary"
            >
              ANNULEREN
            </Button>
          </DialogActions>
        </Dialog>
        {this.state.verwijderPopupDel && (
          <div className="overlay-delete-account">
            <div className="inner-odacc">
              <div className="right-iodacc">
                <CloseIcon
                  onClick={() => this.setState({ verwijderPopupDel: false })}
                />
                <h4>ACCOUNT VERWIJDEREN</h4>
                {/* <p>
                  Je staat op het punt om je Bhodi account te<br />
                  verwijderen. Je gegevens worden verwijdert en<br />
                  hierna kun je niet meer op je account inloggen.
                  <br />
                  <br />
                  We zullen je missen, maar wellicht zien we<br />
                  je in de toekomst weer terug op Bhodi!
                </p> */}
                {this.state.showDelete ? (<p>
                  Je staat op het punt om je account<br />
                  te verwijderen. Hierna kun je niet<br />
                  meer inloggen met dit account.
                  <br />
                  <br />
                  Voer je wachtwoord opnieuw in<br />
                  om je account te verwijderen.
                  {/* We want to get know your account password in order to delete your account. */}
                </p>) : (<p>
                  Je staat op het punt om je account<br />
                  te verwijderen. Hierna kun je niet<br />
                  meer inloggen met dit account. Je<br />
                  maandelijkse bijdrage wordt ook<br />
                  automatisch stopgezet.
                  <br />
                  <br />
                  Voer je wachtwoord opnieuw in<br />
                  om je account te verwijderen.
                </p>)}
                <div className="input-riodacc">
                  <label>Jouw  wachtwoord</label>
                  <input type="password" value={this.state.passwordForDel} onChange={(e) => {
                    this.setState({ passwordForDel: e.target.value })
                  }} />
                  {this.state.passwordRequiredDel && (
                    <label style={{ color: "red" }}>Please enter password to delete</label>
                  )}
                  {this.state.passwordWrongDel && (
                    <label style={{ color: "red" }}>Please enter correct password</label>
                  )}
                </div>
                {this.state.deleteBtnText !== "SAVE" ? (
                  <button>DELETING</button>
                ) : (
                  <button onClick={this.deleteCurrentUser.bind(this)}>
                    VERWIJDEREN
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
        <FooterGreen notOnMainPage={true} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducers.user,
    album: state.postsReducers.album,
    images: state.postsReducers.images,
    product: state.campaignsReducer.product,
    global_campaigns: state.campaignsReducer.global_campaigns,
    all_impressies: state.globalReducers.all_impressies,
    bhodi_abouts: state.globalReducers.bhodi_abouts,
    bhodi_faqs: state.globalReducers.bhodi_faqs,
    guest_user_cart: state.cartReducer.guest_user_cart,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateGuestUserCart: (guest_user_cart) =>
      dispatch(updateGuestUserCart(guest_user_cart)),
    updateGuestUserCartTime: (guest_user_cart_time) =>
      dispatch(updateGuestUserCartTime(guest_user_cart_time)),
    updateBhodiAbouts: (bhodi_abouts) => dispatch(updateBhodiAbouts(bhodi_abouts)),
    updateBhodiFaqs: (bhodi_faqs) => dispatch(updateBhodiFaqs(bhodi_faqs)),
    updateGuestUserCartExtendOpportunity: (
      guest_user_cart_extend_opportunity
    ) =>
      dispatch(
        updateGuestUserCartExtendOpportunity(guest_user_cart_extend_opportunity)
      ),
    updateSignUpFieldsFilled: (signup_fields_info) =>
      dispatch(updateSignUpFieldsFilled(signup_fields_info)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsSeparate);
