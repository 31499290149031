import React, { Component } from "react";
import firebase from "../Config/Firebase";
import { connect } from "react-redux";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { City } from "country-state-city";
import { history } from "../Config/Routes";
let completeCities = City.getCitiesOfCountry("NL");
const axios = require("axios");

export class RightMenuCompanyVouchers extends Component {
    constructor() {
        super();
        this.state = {
            arr: [],
            arrFiltered: [],
            citiesMain: [
                {
                    countryCode: "NL",
                    latitude: "52.37403000",
                    longitude: "4.88969000",
                    name: "Amsterdam",
                    stateCode: "NH",
                },
                {
                    countryCode: "NL",
                    latitude: "51.92250000",
                    longitude: "4.47917000",
                    name: "Rotterdam",
                    stateCode: "ZH",
                },
                {
                    countryCode: "NL",
                    latitude: "52.09083000",
                    longitude: "5.12222000",
                    name: "Utrecht",
                    stateCode: "UT",
                },
                {
                    countryCode: "NL",
                    latitude: "52.06866000",
                    longitude: "4.28635000",
                    name: "Gemeente Den Haag",
                    stateCode: "ZH",
                },
                {
                    countryCode: "NL",
                    latitude: "53.21917000",
                    longitude: "6.56667000",
                    name: "Groningen",
                    stateCode: "GR",
                },
                {
                    countryCode: "NL",
                    latitude: "51.44083000",
                    longitude: "5.47778000",
                    name: "Eindhoven",
                    stateCode: "NB",
                },
                {
                    countryCode: "NL",
                    latitude: "51.58656000",
                    longitude: "4.77596000",
                    name: "Breda",
                    stateCode: "NB",
                },
                {
                    countryCode: "NL",
                    latitude: "51.69917000",
                    longitude: "5.30417000",
                    name: "'s-Hertogenbosch",
                    stateCode: "NB",
                },
                {
                    countryCode: "NL",
                    latitude: "51.84250000",
                    longitude: "5.85278000",
                    name: "Nijmegen",
                    stateCode: "GE",
                },
                {
                    countryCode: "NL",
                    latitude: "50.84833000",
                    longitude: "5.68889000",
                    name: "Maastricht",
                    stateCode: "LI",
                },
                {
                    countryCode: "NL",
                    latitude: "52.38084000",
                    longitude: "4.63683000",
                    name: "Haarlem",
                    stateCode: "NH",
                },
                {
                    countryCode: "NL",
                    latitude: "51.98000000",
                    longitude: "5.91111000",
                    name: "Arnhem",
                    stateCode: "GE",
                },
                {
                    countryCode: "NL",
                    latitude: "52.21833000",
                    longitude: "6.89583000",
                    name: "Enschede",
                    stateCode: "OV",
                },
                {
                    countryCode: "NL",
                    latitude: "52.15500000",
                    longitude: "5.38750000",
                    name: "Amersfoort",
                    stateCode: "UT",
                },
                {
                    countryCode: "NL",
                    latitude: "52.51250000",
                    longitude: "6.09444000",
                    name: "Zwolle",
                    stateCode: "OV",
                },
                {
                    countryCode: "NL",
                    latitude: "52.37025000",
                    longitude: "5.21413000",
                    name: "Almere Stad",
                    stateCode: "FL",
                },
                {
                    countryCode: "NL",
                    latitude: "51.55551000",
                    longitude: "5.09130000",
                    name: "Tilburg",
                    stateCode: "NB",
                },
                {
                    countryCode: "NL",
                    latitude: "52.63167000",
                    longitude: "4.74861000",
                    name: "Alkmaar",
                    stateCode: "NH",
                },
            ],
            currentUserCity: "",
            allCitiesFiltered: [],
        };
    }

    componentDidMount() {
        const loaderId = document.getElementById("main-page-loader-id");
        loaderId.style.display = "none";
        let uid = localStorage.getItem("userid");

        let latitude = localStorage.getItem("latitudeAfterLogin");
        let longitude = localStorage.getItem("longitudeAfterLogin");

        let promiseForUser = new Promise((res, rej) => {
            if (latitude) {
                this.setState({ latitude, longitude });

                res();
            } else {
                let filtered = this.props.user.filter((y) => {
                    return y.user_id === uid;
                });
                if (filtered.length > 0) {
                    if (filtered[0].city) {
                        var config = {
                            method: "get",
                            url: `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(filtered[0].city)}&key=${process.env.REACT_APP_GEOCODE_KEY}`,
                            headers: {},
                        };

                        axios(config)
                            .then((response) => {
                                if (response.data.results) {
                                    const { lat, lng } = response.data.results[0].geometry.location

                                    latitude = lat
                                    longitude = lng
                                    this.setState({ latitude: lat, longitude: lng })

                                    res();
                                }
                            })
                            .catch(() => {
                                res();
                            });
                    }
                }
            }
        });

        promiseForUser.then(() => {
            let filtered = this.props.user.filter((y) => {
                return y.user_id === uid;
            });
            if (filtered.length > 0) {
                let a = filtered[0];
                if (a.latitude && a.longitude && a.type === "user") {
                    var config = {
                        method: "get",
                        url: `https://maps.googleapis.com/maps/api/geocode/json?latlng=${a.latitude},${a.longitude}&key=${process.env.REACT_APP_GEOCODE_KEY}`,
                        headers: {},
                    };

                    axios(config)
                        .then((response) => {
                            if (response.data.results) {
                                let addresses = response.data.results[0].address_components;
                                let city = "";
                                for (var i = 0; i < addresses.length; i++) {
                                    for (var b = 0; b < addresses[i].types.length; b++) {
                                        if (addresses[i].types[b] == "locality") {
                                            city = addresses[i];
                                            break;
                                        }
                                    }
                                }
                                let byDefaultCity = localStorage.getItem(
                                    "selectedCityByDefault"
                                );
                                if (byDefaultCity) {
                                    if (this.props.noCityFiltered) {
                                        localStorage.setItem("selectedCityByDefault", "");
                                        this.setState({ currentUserCity: city.short_name });
                                    } else {
                                        this.setState({ currentUserCity: byDefaultCity });
                                    }
                                } else {
                                    this.setState({ currentUserCity: city.short_name });
                                }
                                firebase
                                    .database()
                                    .ref("users/" + uid + "/city")
                                    .set(city.short_name);
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                } else if (a.latitude && a.longitude) {
                    var config = {
                        method: "get",
                        url: `https://maps.googleapis.com/maps/api/geocode/json?latlng=${this.state.latitude},${this.state.longitude}&key=${process.env.REACT_APP_GEOCODE_KEY}`,
                        headers: {},
                    };

                    axios(config)
                        .then((response) => {
                            if (response.data.results) {
                                let addresses = response.data.results[0].address_components;
                                let city = "";
                                for (var i = 0; i < addresses.length; i++) {
                                    for (var b = 0; b < addresses[i].types.length; b++) {
                                        if (addresses[i].types[b] == "locality") {
                                            city = addresses[i];
                                            break;
                                        }
                                    }
                                }
                                let byDefaultCity = localStorage.getItem(
                                    "selectedCityByDefault"
                                );
                                if (byDefaultCity) {
                                    if (this.props.noCityFiltered) {
                                        localStorage.setItem("selectedCityByDefault", "");
                                        this.setState({ currentUserCity: city.short_name });
                                    } else {
                                        this.setState({ currentUserCity: byDefaultCity });
                                    }
                                } else {
                                    this.setState({ currentUserCity: city.short_name });
                                }
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
            }

            let arr = [];
            this.props.global_campaigns.map((e) => {

                if (e[1].active) {
                    if (e[1].desc) {
                        e[1].desc = e[1].desc.substr(0, 200);
                    }
                    if (
                        e[1].campaignType !== "last_minute" &&
                        e[1].campaignType !== "live_event" &&
                        e[1].campaignType !== "local_event"
                    ) {
                        arr.push(e[1]);
                    }
                }
            });
            let filteredToShow = arr.filter(
                (v, i, a) => a.findIndex((t) => t.created_by === v.created_by) === i
            );
            // this.setState({ allCampaigns: filtered, allCampaignsToCheckSectors: arr })
            let allUsers = [];
            let checkForDistance = new Promise((res, rej) => {
                filteredToShow.map((y) => {
                    let current = this.props.user.filter((u) => {
                        return u.user_id === y.created_by;
                    });
                    if (current.length === 0) {
                        return false;
                    }
                    if (current[0].company_about) {
                        let string = current[0].company_about;
                        let toLowerCase = string.replaceAll(
                            /\S*/g,
                            (word) => `${word.slice(0, 1)}${word.slice(1).toLowerCase()}`
                        );
                        let finalPhrase = `${toLowerCase.slice(0, 1).toUpperCase()}${toLowerCase.slice(1)}`;
                        current[0].aboutModified = finalPhrase;
                    }
                    if (current[0].latitude && current[0].longitude) {
                        let lat1 = latitude;
                        let lat2 = current[0].latitude;
                        let lon1 = longitude;
                        let lon2 = current[0].longitude;

                        var rad = function (x) {
                            return (x * Math.PI) / 180;
                        };

                        var R = 6378137; // Earth’s mean radius in meter
                        var dLat = rad(lat2 - lat1);
                        var dLong = rad(lon2 - lon1);
                        var a =
                            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                            Math.cos(rad(lat1)) *
                            Math.cos(rad(lat2)) *
                            Math.sin(dLong / 2) *
                            Math.sin(dLong / 2);
                        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
                        var d = R * c;
                        current[0].distanceToSort = d;

                        if (current[0].type === "company") {
                            allUsers.push(current[0]);

                            if (allUsers.length === filteredToShow.length) {
                                res()
                            }
                        }
                    } else {
                        if (current[0].city) {
                            var config = {
                                method: "get",
                                url: `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(current[0].city)}&key=${process.env.REACT_APP_GEOCODE_KEY}`,
                                headers: {},
                            };

                            axios(config)
                                .then((response) => {
                                    if (response.data.results) {
                                        const { lat, lng } = response.data.results[0].geometry.location

                                        // firebase.database().ref(`users/${current[0].user_id}/latitude`).set(lat)
                                        // firebase.database().ref(`users/${current[0].user_id}/longitude`).set(lng)

                                        let lat1 = lat;
                                        let lat2 = current[0].latitude;
                                        let lon1 = lng;
                                        let lon2 = current[0].longitude;

                                        var rad = function (x) {
                                            return (x * Math.PI) / 180;
                                        };

                                        var R = 6378137; // Earth’s mean radius in meter
                                        var dLat = rad(lat2 - lat1);
                                        var dLong = rad(lon2 - lon1);
                                        var a =
                                            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                                            Math.cos(rad(lat1)) *
                                            Math.cos(rad(lat2)) *
                                            Math.sin(dLong / 2) *
                                            Math.sin(dLong / 2);
                                        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
                                        var d = R * c;
                                        current[0].distanceToSort = d;

                                        if (current[0].type === "company") {
                                            allUsers.push(current[0]);

                                            if (allUsers.length === filteredToShow.length) {
                                                res()
                                            }
                                        }
                                    } else {
                                        current[0].distanceToSort = 99999999;
                                        if (current[0].type === "company") {
                                            allUsers.push(current[0]);

                                            if (allUsers.length === filteredToShow.length) {
                                                res()
                                            }
                                        }
                                    }
                                })
                                .catch(() => {
                                    current[0].distanceToSort = 99999999;
                                    if (current[0].type === "company") {
                                        allUsers.push(current[0]);

                                        if (allUsers.length === filteredToShow.length) {
                                            res()
                                        }
                                    }
                                });
                        } else {
                            current[0].distanceToSort = 99999999;
                            if (current[0].type === "company") {
                                allUsers.push(current[0]);

                                if (allUsers.length === filteredToShow.length) {
                                    res()
                                }
                            }
                        }
                    }
                });
            })
            checkForDistance.then(() => {
                let allUsersComplete = [...allUsers].sort((a, b) => {
                    return a.distanceToSort - b.distanceToSort;
                });
                allUsers.sort((a, b) => {
                    return a.distanceToSort - b.distanceToSort;
                });
                this.setState({ arr: allUsers, allUsersComplete });
            })
        });

        // filteredToShow.map((y) => {
        //   let current = this.props.user.filter((u) => {
        //     return u.user_id === y.created_by;
        //   });
        //   if (current.length === 0) {
        //     return false;
        //   }
        //   if (current[0].latitude && current[0].longitude) {
        //     let lat1 = latitude;
        //     let lat2 = current[0].latitude;
        //     let lon1 = longitude;
        //     let lon2 = current[0].longitude;

        //     var rad = function (x) {
        //       return (x * Math.PI) / 180;
        //     };

        //     var R = 6378137; // Earth’s mean radius in meter
        //     var dLat = rad(lat2 - lat1);
        //     var dLong = rad(lon2 - lon1);
        //     var a =
        //       Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        //       Math.cos(rad(lat1)) *
        //       Math.cos(rad(lat2)) *
        //       Math.sin(dLong / 2) *
        //       Math.sin(dLong / 2);
        //     var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        //     var d = R * c;
        //     current[0].distanceToSort = d;
        //   } else {
        //     if (current[0].city) {
        //       var config = {
        //         method: "get",
        //         url: `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(current[0].city)}&key=${process.env.REACT_APP_GEOCODE_KEY}`,
        //         headers: {},
        //       };

        //       axios(config)
        //         .then((response) => {
        //           if (response.data.results) {
        //             const { lat, lng } = response.data.results[0].geometry.location

        //             firebase.database().ref(`users/${current[0].user_id}/latitude`).set(lat)
        //             firebase.database().ref(`users/${current[0].user_id}/longitude`).set(lng)
        //           } else {
        //             current[0].distanceToSort = 99999999;
        //           }
        //         })
        //         .catch((error) => {
        //           console.log(error);
        //         });
        //     } else {
        //       current[0].distanceToSort = 99999999;
        //     }
        //   }
        //   if (current[0].company_about) {
        //     let string = current[0].company_about;
        //     let toLowerCase = string.replaceAll(
        //       /\S*/g,
        //       (word) => `${word.slice(0, 1)}${word.slice(1).toLowerCase()}`
        //     );
        //     let finalPhrase = `${toLowerCase
        //       .slice(0, 1)
        //       .toUpperCase()}${toLowerCase.slice(1)}`;
        //     current[0].aboutModified = finalPhrase;
        //   }
        //   if (current[0].type === "company") {
        //     allUsers.push(current[0]);
        //   }
        // });
    }

    render() {
        return this.state.citySelectionModal ? (
            <div
                className="rightmenuaddproduct rightmenucommentsaddproduct rightmenuproductfriendlists rightmenucompanyvouchersmain newalleaanbiedersmain rightmenuaanbiederschangemenu changedmenurightmenuaanbieders"
                style={{ overflowX: "hidden" }}
            >
                <div
                    className="mid-rmap"
                    style={{
                        position: "relative",
                        padding: 13,
                        alignItems: "flex-start",
                    }}
                >
                    <ChevronLeftIcon
                        style={{
                            cursor: "pointer",
                            position: "absolute",
                            top: "50%",
                            transform: "translateY(-50%)",
                            left: 10,
                            fontSize: 18,
                        }}
                        onClick={() => {
                            this.setState({ citySelectionModal: false });
                        }}
                    />
                    <CloseIcon
                        style={{
                            cursor: "pointer",
                            position: "absolute",
                            top: "50%",
                            transform: "translateY(-50%)",
                            right: 20,
                            fontSize: 18,
                        }}
                        onClick={() => {
                            this.props.closePopup();
                        }}
                    />
                </div>
                <div
                    className="mid-img-uploading-rmap commentsmidimgurmap"
                    style={{ alignItems: "flex-start", height: "auto", width: window.innerWidth < 1008 && "100%" }}
                >
                    <div
                        style={{
                            width: "100%",
                            marginTop: 15,
                            paddingBottom: window.innerWidth < 1008 && 40,
                        }}
                    >
                        <div className="search-button-city">
                            <input
                                type="text"
                                placeholder="Zoek"
                                onChange={(e) => {
                                    if (e.target.value === "") {
                                        this.setState({
                                            allCitiesFiltered: [],
                                            noFilteredCities: false,
                                        });
                                    } else {
                                        let filtered = completeCities.filter((y) => {
                                            return y.name
                                                .toLowerCase()
                                                .includes(e.target.value.toLowerCase());
                                        });
                                        if (filtered.length > 0) {
                                            this.setState({
                                                allCitiesFiltered: filtered,
                                                noFilteredCities: false,
                                            });
                                        } else {
                                            this.setState({ noFilteredCities: true });
                                        }
                                    }
                                }}
                            />
                        </div>
                        <ul className="doflewlistsworkbottom">
                            {this.state.allCitiesFiltered.length > 0
                                ? this.state.allCitiesFiltered.map((e, i) => {
                                    return (
                                        <li>
                                            <p
                                                onClick={() => {
                                                    var config = {
                                                        method: "get",
                                                        url: `https://maps.googleapis.com/maps/api/geocode/json?address=${e.name},Netherlands&key=${process.env.REACT_APP_GEOCODE_KEY}`,
                                                        headers: {},
                                                    };

                                                    axios(config)
                                                        .then((response) => {
                                                            if (response.data.results) {
                                                                let latlong =
                                                                    response.data.results[0].geometry.location;
                                                                let latitude = latlong.lat;
                                                                let longitude = latlong.lng;

                                                                if (this.state.allUsersComplete.length > 0) {
                                                                    let allUsers = [];
                                                                    this.state.allUsersComplete.map((y) => {
                                                                        if (y.latitude && y.longitude) {
                                                                            let lat1 = latitude;
                                                                            let lat2 = y.latitude;
                                                                            let lon1 = longitude;
                                                                            let lon2 = y.longitude;

                                                                            var rad = function (x) {
                                                                                return (x * Math.PI) / 180;
                                                                            };

                                                                            var R = 6378137; // Earth’s mean radius in meter
                                                                            var dLat = rad(lat2 - lat1);
                                                                            var dLong = rad(lon2 - lon1);
                                                                            var a =
                                                                                Math.sin(dLat / 2) *
                                                                                Math.sin(dLat / 2) +
                                                                                Math.cos(rad(lat1)) *
                                                                                Math.cos(rad(lat2)) *
                                                                                Math.sin(dLong / 2) *
                                                                                Math.sin(dLong / 2);
                                                                            var c =
                                                                                2 *
                                                                                Math.atan2(
                                                                                    Math.sqrt(a),
                                                                                    Math.sqrt(1 - a)
                                                                                );
                                                                            var d = R * c;
                                                                            y.distanceToSort = d;
                                                                        } else {
                                                                            y.distanceToSort = 99999999;
                                                                        }
                                                                        allUsers.push(y);
                                                                    });
                                                                    allUsers.sort((a, b) => {
                                                                        return (
                                                                            a.distanceToSort - b.distanceToSort
                                                                        );
                                                                    });
                                                                    let usersSameCity = allUsers.filter((users) => users.city.toLowerCase() === e.name.toLowerCase())
                                                                    let usersSameCityNot = allUsers.filter((users) => users.city.toLowerCase() !== e.name.toLowerCase())
                                                                    this.setState({
                                                                        arrFiltered: usersSameCity.concat(usersSameCityNot),
                                                                        citySelectionModal: false,
                                                                        currentUserCity: e.name,
                                                                    });
                                                                }
                                                            }
                                                        })
                                                        .catch((error) => {
                                                            console.log(error);
                                                        });
                                                }}
                                            >
                                                {e.name}
                                            </p>
                                        </li>
                                    );
                                })
                                : this.state.citiesMain.map((e, i) => {
                                    return (
                                        <li>
                                            <p
                                                onClick={() => {
                                                    var config = {
                                                        method: "get",
                                                        url: `https://maps.googleapis.com/maps/api/geocode/json?address=${e.name},Netherlands&key=${process.env.REACT_APP_GEOCODE_KEY}`,
                                                        headers: {},
                                                    };

                                                    axios(config)
                                                        .then((response) => {
                                                            if (response.data.results) {
                                                                let latlong =
                                                                    response.data.results[0].geometry.location;
                                                                let latitude = latlong.lat;
                                                                let longitude = latlong.lng;

                                                                if (this.state.allUsersComplete.length > 0) {
                                                                    let allUsers = [];
                                                                    this.state.allUsersComplete.map((y) => {
                                                                        if (y.latitude && y.longitude) {
                                                                            let lat1 = latitude;
                                                                            let lat2 = y.latitude;
                                                                            let lon1 = longitude;
                                                                            let lon2 = y.longitude;

                                                                            var rad = function (x) {
                                                                                return (x * Math.PI) / 180;
                                                                            };

                                                                            var R = 6378137; // Earth’s mean radius in meter
                                                                            var dLat = rad(lat2 - lat1);
                                                                            var dLong = rad(lon2 - lon1);
                                                                            var a =
                                                                                Math.sin(dLat / 2) *
                                                                                Math.sin(dLat / 2) +
                                                                                Math.cos(rad(lat1)) *
                                                                                Math.cos(rad(lat2)) *
                                                                                Math.sin(dLong / 2) *
                                                                                Math.sin(dLong / 2);
                                                                            var c =
                                                                                2 *
                                                                                Math.atan2(
                                                                                    Math.sqrt(a),
                                                                                    Math.sqrt(1 - a)
                                                                                );
                                                                            var d = R * c;
                                                                            y.distanceToSort = d;
                                                                        } else {
                                                                            y.distanceToSort = 99999999;
                                                                        }
                                                                        allUsers.push(y);
                                                                    });
                                                                    allUsers.sort((a, b) => {
                                                                        return (
                                                                            a.distanceToSort - b.distanceToSort
                                                                        );
                                                                    });
                                                                    let usersSameCity = allUsers.filter((users) => users.city.toLowerCase() === e.name.toLowerCase())
                                                                    let usersSameCityNot = allUsers.filter((users) => users.city.toLowerCase() !== e.name.toLowerCase())
                                                                    this.setState({
                                                                        arrFiltered: usersSameCity.concat(usersSameCityNot),
                                                                        citySelectionModal: false,
                                                                        currentUserCity: e.name,
                                                                    });
                                                                }
                                                            }
                                                        })
                                                        .catch((error) => {
                                                            console.log(error);
                                                        });
                                                }}
                                            >
                                                {e.name}
                                            </p>
                                        </li>
                                    );
                                })}
                        </ul>
                    </div>
                </div>
            </div>
        ) : (
            <div
                className="rightmenuaddproduct rightmenucommentsaddproduct rightmenuproductfriendlists rightmenucompanyvouchersmain newalleaanbiedersmain rightmenuaanbiederschangemenu changedmenurightmenuaanbieders"
                style={{ overflowX: "hidden" }}
            >
                <div
                    className="mid-rmap"
                    style={{
                        position: "relative",
                        padding: 13,
                        alignItems: "flex-start",
                    }}
                >
                    <ChevronLeftIcon
                        style={{
                            cursor: "pointer",
                            position: "absolute",
                            top: "50%",
                            transform: "translateY(-50%)",
                            left: 10,
                            fontSize: 18,
                        }}
                        onClick={() => {
                            this.props.closePopup();
                        }}
                    />
                    <div>
                        <div className="city-rm-main">
                            <LocationOnIcon
                                onClick={() => this.setState({ citySelectionModal: true })}
                            />
                            <p onClick={() => this.setState({ citySelectionModal: true })}>
                                {this.state.currentUserCity ? this.state.currentUserCity : "City"}
                            </p>
                        </div>
                        <CloseIcon
                            style={{
                                cursor: "pointer",
                                position: "absolute",
                                top: "50%",
                                transform: "translateY(-50%)",
                                right: 20,
                                fontSize: 18,
                            }}
                            onClick={() => {
                                this.props.closePopup();
                            }}
                        />
                    </div>
                </div>
                <div
                    className="mid-img-uploading-rmap commentsmidimgurmap"
                    style={{ alignItems: "flex-start", height: "auto", width: window.innerWidth < 1008 && "100%" }}
                >
                    <div
                        style={{
                            width: "100%",
                            marginTop: 15,
                            paddingBottom: window.innerWidth < 1008 && 40,
                        }}
                    >
                        <div className="search-rm-main">
                            <input
                                type="text"
                                placeholder="Zoek naar je favoriete aanbieder"
                                onChange={(e) => {
                                    if (e.target.value !== "") {
                                        let filtered = [...this.state.allUsersComplete].filter(
                                            (y) => {
                                                if (y.company_name) {
                                                    return y.company_name
                                                        .toLowerCase()
                                                        .includes(e.target.value.toLowerCase());
                                                } else {
                                                    return y.first_name
                                                        .toLowerCase()
                                                        .includes(e.target.value.toLowerCase());
                                                }
                                            }
                                        );

                                        if (filtered.length > 0) {
                                            this.setState({ arrFiltered: filtered });
                                        } else {
                                            this.setState({ allComapniesToRender: [] });
                                        }
                                    } else {
                                        this.setState({ arrFiltered: [] });
                                    }
                                }}
                            />
                            <SearchIcon />
                        </div>
                        {this.state.arrFiltered.length > 0
                            ? this.state.arrFiltered.map((e, i) => {
                                return (
                                    <>
                                        <div className="comment-block-iled">
                                            <div
                                                className="credentials-cbiled"
                                                style={{ flexDirection: "column" }}
                                            >
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        display: "flex",
                                                        marginBottom: 10,
                                                    }}
                                                >
                                                    {e.profile_image ? (
                                                        <img
                                                            src={e.profile_image}
                                                            alt="userimage"
                                                            onClick={() => {
                                                                if (e.type === "company") {
                                                                    history.push(`/company/${e.user_name_id}`);
                                                                    // Commented by me
                                                                    // window.location.href = `/company/${e.user_name_id}`;
                                                                } else {
                                                                    // Commented by me

                                                                    history.push(`/member/${e.user_name_id}`);
                                                                    // window.location.href = `/member/${e.user_name_id}`;
                                                                }
                                                            }}
                                                        />
                                                    ) : (
                                                        <img
                                                            src="https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9"
                                                            alt="userimage"
                                                            onClick={() => {
                                                                if (e.type === "company") {
                                                                    // Commented by me
                                                                    history.push(`/company/${e.user_name_id}`);
                                                                    // window.location.href = `/company/${e.user_name_id}`;
                                                                } else {
                                                                    history.push(`/member/${e.user_name_id}`);
                                                                    // window.location.href = `/member/${e.user_name_id}`;
                                                                }
                                                            }}
                                                        />
                                                    )}
                                                    <div
                                                        className="overflow-redirect"
                                                        onClick={() => {
                                                            if (e.type === "company") {
                                                                // Commented by me
                                                                history.push(`/company/${e.user_name_id}`);
                                                                // window.location.href = `/company/${e.user_name_id}`;
                                                            } else {
                                                                // Commented by me
                                                                history.push(`/member/${e.user_name_id}`);
                                                                // window.location.href = `/member/${e.user_name_id}`;
                                                            }
                                                        }}
                                                    ></div>
                                                    <div className="detailedinfocompanyvoucherparent">
                                                        {e.type === "user" ? (
                                                            <label>
                                                                {e.first_name} {e.last_name}
                                                            </label>
                                                        ) : (
                                                            <label>{e.company_name}</label>
                                                        )}
                                                        <div className="detailedinfocompanyvoucher">
                                                            <p>
                                                                {e.followed_by
                                                                    ? Object.entries(e.followed_by).length
                                                                    : 0}{" "}
                                                                volgers
                                                            </p>
                                                            {/* <p>{e.following ? Object.entries(e.following).length : 0} volgend</p> */}
                                                            <p>{e.city}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                {e.aboutModified && (
                                                    <p className="company-about-dicv">
                                                        {e.aboutModified.substr(0, 38)}...
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                );
                            })
                            : this.state.arr.length > 0 &&
                            this.state.arr.map((e, i) => {
                                return (
                                    <>
                                        <div className="comment-block-iled">
                                            <div
                                                className="credentials-cbiled"
                                                style={{ flexDirection: "column" }}
                                            >
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        display: "flex",
                                                        marginBottom: 10,
                                                    }}
                                                >
                                                    {e.profile_image ? (
                                                        <img
                                                            src={e.profile_image}
                                                            alt="userimage"
                                                            onClick={() => {
                                                                if (e.type === "company") {
                                                                    // Commented by me
                                                                    history.push(`/company/${e.user_name_id}`);
                                                                    // window.location.href = `/company/${e.user_name_id}`;
                                                                } else {
                                                                    // Commented by me
                                                                    history.push(`/member/${e.user_name_id}`);
                                                                    // window.location.href = `/member/${e.user_name_id}`;
                                                                }
                                                            }}
                                                        />
                                                    ) : (
                                                        <img
                                                            src="https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9"
                                                            alt="userimage"
                                                            onClick={() => {
                                                                if (e.type === "company") {
                                                                    // Commented by me
                                                                    history.push(`/company/${e.user_name_id}`);
                                                                    // window.location.href = `/company/${e.user_name_id}`;
                                                                } else {
                                                                    // Commented by me
                                                                    history.push(`/member/${e.user_name_id}`);
                                                                    // window.location.href = `/member/${e.user_name_id}`;
                                                                }
                                                            }}
                                                        />
                                                    )}
                                                    <div
                                                        className="overflow-redirect"
                                                        onClick={() => {
                                                            if (e.type === "company") {
                                                                // Commented by me
                                                                history.push(`/company/${e.user_name_id}`);
                                                                // window.location.href = `/company/${e.user_name_id}`;
                                                            } else {
                                                                // Commented by me
                                                                history.push(`/member/${e.user_name_id}`);
                                                                // window.location.href = `/member/${e.user_name_id}`;
                                                            }
                                                        }}
                                                    ></div>
                                                    <div className="detailedinfocompanyvoucherparent">
                                                        {e.type === "user" ? (
                                                            <label>
                                                                {e.first_name} {e.last_name}
                                                            </label>
                                                        ) : (
                                                            <label>{e.company_name}</label>
                                                        )}
                                                        <div className="detailedinfocompanyvoucher">
                                                            <p>
                                                                {e.followed_by
                                                                    ? Object.entries(e.followed_by).length
                                                                    : 0}{" "}
                                                                volgers
                                                            </p>
                                                            {/* <p>{e.following ? Object.entries(e.following).length : 0} volgend</p> */}
                                                            <p>{e.city}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                {e.aboutModified && (
                                                    <p className="company-about-dicv">
                                                        {e.aboutModified.substr(0, 38)}...
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                );
                            })}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducers.user,
        message: state.authReducers.message,
        album: state.postsReducers.album,
        images: state.postsReducers.images,
        global_campaigns: state.campaignsReducer.global_campaigns,
    };
};

export default connect(mapStateToProps)(RightMenuCompanyVouchers);
