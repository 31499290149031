import React, { Component } from "react";
// import './index.css'
import CloseIcon from "@material-ui/icons/Close";
import FooterGreen from "./FooterGreen";
import { connect } from "react-redux";
import { HiOutlineShoppingBag } from "react-icons/hi";
import { RiAccountBoxLine } from "react-icons/ri";
import {
    updateUser,
    updateProducts,
    updateGuestUserCart,
    updateGuestUserCartExtendOpportunity,
    updateGuestUserCartTime,
    updateAlbums,
} from "../Redux/actions/authActions";
import "./index.css";
import firebase from "../Config/Firebase";
import { mergeAdminEvent } from "../Functions/globalFunctions";

export class FooterBasketTimer extends Component {
    constructor() {
        super();
        this.state = {
            black: false,
            popupright: false,
            popupitems: false,
            popupmarket: false,
            popupservices: false,
            popupcompanies: false,
            lists: [1, 2, 3, 4, 5],
            cartItems: [],
            arrItemsOfCart: [],
            currentUserHere: "",
            allSellers: [],
            currentUserId: "",
            allSectors: [],
            availableMainSector: [],
            availableMainSectorVouchers: [],
            allSectors: [],
            availableMainSectorShop: [],
            allSectorsVouchers: [],
            filteredAllSectors: [],
            filteredAllSectorsFilter: [],
            allEvents: [],
            availableMainSectorEvents: [],
            filteredAllSectorsFilterEvents: [],
            allVouchers: [],
            endDate: "",
            allCampaigns: [],
            popupExtend: false,
            extendOpportunity: false,
            alreadyBewared: [],
            alreadyBewaredCampaigns: [],
            currentUserExclusives: [],
            friendsExclusives: [],
            availableAlbumCategories: [],
            categoriesToShow: [],
            filteredAllCategoriesAlbums: [],
            noFilterAlbumCategories: false,
            allCampaignsToFilter: [],
            allBewaaredThings: [],
            availableImageCategories: [],
            availableMainSectorVoucherEvents: [],
            guestUserCart: [],
            stopCartQty: false,
            completeCartArray: [],
        };
    }

    componentDidMount() {
        const loaderId = document.getElementById("main-page-loader-id");
        loaderId.style.display = "none";
        let userid = localStorage.getItem("userid");
        this.setState({ currentUserId: userid });

        let cookieItem = localStorage.getItem("cookie_item");
        if (userid && !cookieItem) {
            cookieItem = userid;
        }
        if (cookieItem) {
            console.log(cookieItem, "/working?");
            firebase
                .database()
                .ref("guest_carts/" + cookieItem)
                .on("value", (data) => {
                    let a = data.val();
                    if (a) {
                        let arr = [];
                        let arrKeys = [];
                        let arrComplete = [];
                        let arrCompleteBhodiProducts = [];
                        let objected = Object.entries(a);
                        objected.map((y) => {
                            arr.push(y[1]);
                            arrKeys.push(y[1].itemPush);
                        });

                        this.setState({ completeCartArray: arr });

                        if (this.props.global_campaigns) {
                            if (this.props.global_campaigns.length > 0) {
                                // this.interval = setInterval(() => this.setState({ timeCurrent: Date.now() }), 1000);
                                let arrSecCheck = [];

                                this.props.global_campaigns.map((i) => {
                                    arrSecCheck.push(i[1]);
                                });
                                arrSecCheck.map((g) => {
                                    if (g.active) {
                                        g.type = "campaign";
                                        g.currentImageIndex = 0;
                                        if (g.campaignType === "charity") {
                                            let objectedCheckedIn = [];
                                            let inCartOf = 0;
                                            if (g.checked_in_by) {
                                                objectedCheckedIn = Object.entries(g.checked_in_by);
                                            }
                                            if (g.inCartOf) {
                                                inCartOf = g.inCartOf;
                                            }
                                            if (objectedCheckedIn.length + inCartOf < g.charity_qty) {
                                                this.setState({ charityWorkReached: false });
                                            } else {
                                                this.setState({ charityWorkReached: true });
                                            }
                                            if (!g.algemene_title) {
                                                let arrDishes = [];
                                                if (g.dishes) {
                                                    let objected = Object.entries(g.dishes);
                                                    objected.map((h) => {
                                                        if (h[1].description.length > 40) {
                                                            h[1].short_desc = `${h[1].description.substr(
                                                                0,
                                                                40
                                                            )}...`;
                                                        } else {
                                                            h[1].short_desc = h[1].description;
                                                        }
                                                        arrDishes.push(h[1]);
                                                    });
                                                    g.dishesObjected = arrDishes;
                                                    g.selectedDish = 0;
                                                    g.dishesObjected.sort((a, b) => {
                                                        return a.sortNumber - b.sortNumber;
                                                    });
                                                    g.imageToRender = g.dishesObjected[0].image;
                                                    let filteredArr = g.dishesObjected.filter(
                                                        (v, i, a) =>
                                                            a.findIndex((t) => t.image === v.image) === i
                                                    );
                                                    if (filteredArr.length === 1) {
                                                        this.setState({ selectedDish: null });
                                                    }
                                                } else {
                                                    g.dishesObjected = [];
                                                    g.imageToRender = g.image;
                                                }
                                            }
                                        }
                                        if (g.desc) {
                                            g.desc = g.desc.substr(0, 200);
                                            g.short_desc = g.desc.substr(0, 120);
                                        }
                                        if (g.campaignType !== "charity") {
                                            g.images = [g.image];
                                            g.imagesObjected = [
                                                { image: g.image, pushKey: g.pushKey },
                                            ];
                                        } else {
                                            if (g.dishes) {
                                                g.images = [g.imageToRender];
                                                g.imagesObjected = [
                                                    { image: g.imageToRender, pushKey: g.pushKey },
                                                ];
                                                g.title = g.main_title;
                                                g.desc = g.dishesObjected[0].description;
                                                g.charity_price = g.main_price;
                                                g.normal_price = g.main_price;
                                                g.amount = g.main_price;
                                            }
                                        }
                                        arrComplete.push(g);
                                    }
                                });

                                this.setState({ allCampaigns: arrComplete });
                            }
                        }
                        if (this.props.bhodi_products) {
                            if (this.props.bhodi_products.length > 0) {
                                this.props.bhodi_products.map((g) => {
                                    if (g.active) {
                                        g.type = "bhodi_product";
                                        g.currentImageIndex = 0;
                                        if (g.desc) {
                                            g.desc = g.desc.substr(0, 200);
                                            g.short_desc = g.desc.substr(0, 120);
                                        }
                                        arrCompleteBhodiProducts.push(g);
                                    }
                                });

                                this.setState({ allBhodiProducts: arrCompleteBhodiProducts });
                            }
                        }

                        let everyCartItems = [];
                        arrComplete.map((y) => {
                            objected.map((t) => {
                                if (t[1].item_type === "bhodi_product") {
                                    return false;
                                }
                                if (t[1].type === "product") {
                                    everyCartItems.push(y);
                                }
                                if (y.pushKey === t[1].itemPush) {
                                    if (y.campaignType === "local_event") {
                                        if (y.takeFromAdmin) {
                                            let obj = mergeAdminEvent(y, this.props.global_campaigns)
                                            y = Object.assign({}, obj)
                                        }
                                        everyCartItems.push(y);
                                    } else {
                                        everyCartItems.push(y);
                                    }
                                }
                            });
                        });
                        arrCompleteBhodiProducts.map((y) => {
                            objected.map((t) => {
                                if (t[1].item_type === "bhodi_product") {
                                    if (t[1].itemPush === y.pushKey) {
                                        // t[1].mainProduct = y;
                                        let currentObj = Object.assign({}, y)
                                        currentObj.item_type = "bhodi_product"
                                        if (t[1].selectedSize) {
                                            currentObj.selectedSizeOfOrder = t[1].selectedSize
                                        }
                                        everyCartItems.push(currentObj);
                                    }
                                }
                            });
                        });

                        let toDoFilterCompanies = [];
                        let toDoFilter = [];
                        everyCartItems.map((b) => {
                            if (b.isNotActive) {
                                b.hideFromListComplete = true;
                            } else {
                                b.hideFromListComplete = false;
                                toDoFilterCompanies.push(b);
                            }
                            if (b.item_type === "bhodi_product") {
                                toDoFilter.push(b);
                            } else if (b.type === "product") {
                                toDoFilter.push(b);
                            } else if (b.campaignType === "live_event") {
                                let getStartDateEvent = new Date(b.startDate);
                                let startTimeEventGood = b.startTime.concat(":00");
                                getStartDateEvent.setHours(startTimeEventGood.split(":")[0]);
                                getStartDateEvent.setMinutes(startTimeEventGood.split(":")[1]);
                                if (getStartDateEvent.getTime() > new Date().getTime()) {
                                    toDoFilter.push(b);
                                }
                            } else {
                                toDoFilter.push(b);
                            }
                        });
                        let filteredSecMa = toDoFilterCompanies.filter(
                            (v, i, a) =>
                                a.findIndex((t) => t.created_by === v.created_by) === i
                        );
                        let arrMainSellers = [];
                        filteredSecMa.map((y) => {
                            arrMainSellers.push(y.created_by);
                        });
                        this.props.updateGuestUserCart(everyCartItems);
                        this.setState({
                            guestUserCart: everyCartItems,
                            allSellers: arrMainSellers,
                        });
                        this.interval = setInterval(
                            () => this.setState({ timeCurrent: Date.now() }),
                            1000
                        );

                        if (this.props.guest_user_cart_time) {
                            let currentDate = Math.ceil(Date.now() / 1000);
                            if (
                                Number(currentDate) < Number(this.props.guest_user_cart_time)
                            ) {
                                this.setState({
                                    endDate: this.props.guest_user_cart_time,
                                    extendOpportunity:
                                        this.props.guest_user_cart_extend_opportunity,
                                });
                            }
                        }
                    } else {
                        clearInterval(this.interval);
                    }
                });
        } else {
            firebase
                .database()
                .ref("guest_carts")
                .on("value", (data) => {
                    let cookieItem = localStorage.getItem("cookie_item");
                    if (userid && !cookieItem) {
                        cookieItem = userid;
                    }
                    let as = data.val();
                    if (as) {
                        let objectedMain = Object.entries(data.val());
                        let filtered = objectedMain.filter((u) => {
                            return u[0] === cookieItem;
                        });
                        if (filtered.length > 0) {
                            let a = filtered[0][1];
                            let arr = [];
                            let arrKeys = [];
                            let arrComplete = [];
                            let arrCompleteBhodiProducts = [];
                            let objected = Object.entries(a);
                            objected.map((y) => {
                                arr.push(y[1]);
                                arrKeys.push(y[1].itemPush);
                            });

                            this.setState({ completeCartArray: arr });

                            if (this.props.global_campaigns) {
                                if (this.props.global_campaigns.length > 0) {
                                    // this.interval = setInterval(() => this.setState({ timeCurrent: Date.now() }), 1000);
                                    let arrSecCheck = [];

                                    this.props.global_campaigns.map((i) => {
                                        arrSecCheck.push(i[1]);
                                    });
                                    arrSecCheck.map((g) => {
                                        if (g.active) {
                                            g.type = "campaign";
                                            g.currentImageIndex = 0;
                                            if (g.campaignType === "charity") {
                                                let objectedCheckedIn = [];
                                                let inCartOf = 0;
                                                if (g.checked_in_by) {
                                                    objectedCheckedIn = Object.entries(g.checked_in_by);
                                                }
                                                if (g.inCartOf) {
                                                    inCartOf = g.inCartOf;
                                                }
                                                if (
                                                    objectedCheckedIn.length + inCartOf <
                                                    g.charity_qty
                                                ) {
                                                    this.setState({ charityWorkReached: false });
                                                } else {
                                                    this.setState({ charityWorkReached: true });
                                                }
                                                if (!g.algemene_title) {
                                                    let arrDishes = [];
                                                    if (g.dishes) {
                                                        let objected = Object.entries(g.dishes);
                                                        objected.map((h) => {
                                                            if (h[1].description.length > 40) {
                                                                h[1].short_desc = `${h[1].description.substr(
                                                                    0,
                                                                    40
                                                                )}...`;
                                                            } else {
                                                                h[1].short_desc = h[1].description;
                                                            }
                                                            arrDishes.push(h[1]);
                                                        });
                                                        g.dishesObjected = arrDishes;
                                                        g.selectedDish = 0;
                                                        g.dishesObjected.sort((a, b) => {
                                                            return a.sortNumber - b.sortNumber;
                                                        });
                                                        g.imageToRender = g.dishesObjected[0].image;
                                                        let filteredArr = g.dishesObjected.filter(
                                                            (v, i, a) =>
                                                                a.findIndex((t) => t.image === v.image) === i
                                                        );
                                                        if (filteredArr.length === 1) {
                                                            this.setState({ selectedDish: null });
                                                        }
                                                    } else {
                                                        g.dishesObjected = [];
                                                        g.imageToRender = g.image;
                                                    }
                                                }
                                            }
                                            if (g.desc) {
                                                g.desc = g.desc.substr(0, 200);
                                                g.short_desc = g.desc.substr(0, 120);
                                            }
                                            if (g.campaignType !== "charity") {
                                                g.images = [g.image];
                                                g.imagesObjected = [
                                                    { image: g.image, pushKey: g.pushKey },
                                                ];
                                            } else {
                                                if (g.dishes) {
                                                    g.images = [g.imageToRender];
                                                    g.imagesObjected = [
                                                        { image: g.imageToRender, pushKey: g.pushKey },
                                                    ];
                                                    g.title = g.main_title;
                                                    g.desc = g.dishesObjected[0].description;
                                                    g.charity_price = g.main_price;
                                                    g.normal_price = g.main_price;
                                                    g.amount = g.main_price;
                                                }
                                            }
                                            arrComplete.push(g);
                                        }
                                    });

                                    this.setState({ allCampaigns: arrComplete });
                                }
                            }
                            if (this.props.bhodi_products) {
                                if (this.props.bhodi_products.length > 0) {
                                    this.props.bhodi_products.map((g) => {
                                        if (g.active) {
                                            g.type = "bhodi_product";
                                            g.currentImageIndex = 0;
                                            if (g.desc) {
                                                g.desc = g.desc.substr(0, 200);
                                                g.short_desc = g.desc.substr(0, 120);
                                            }
                                            arrCompleteBhodiProducts.push(g);
                                        }
                                    });

                                    this.setState({ allBhodiProducts: arrCompleteBhodiProducts });
                                }
                            }

                            let everyCartItems = [];
                            arrComplete.map((y) => {
                                objected.map((t) => {
                                    if (t[1].item_type === "bhodi_product") {
                                        return false;
                                    }
                                    if (y.pushKey === t[1].itemPush) {
                                        if (y.active) {
                                            everyCartItems.push(y);
                                        }
                                    }
                                });
                            });
                            arrCompleteBhodiProducts.map((y) => {
                                objected.map((t) => {
                                    if (t[1].item_type === "bhodi_product") {
                                        if (t[1].itemPush === y.pushKey) {
                                            // t[1].mainProduct = y;
                                            let currentObj = Object.assign({}, y)
                                            currentObj.item_type = "bhodi_product"
                                            if (t[1].selectedSize) {
                                                currentObj.selectedSizeOfOrder = t[1].selectedSize
                                            }
                                            everyCartItems.push(currentObj);
                                        }
                                    }
                                });
                            });

                            let toDoFilterCompanies = [];
                            let toDoFilter = [];
                            everyCartItems.map((b) => {
                                // if (b.isNotActive) {
                                //     b.hideFromListComplete = true;
                                // } else {
                                //     b.hideFromListComplete = false;
                                //     toDoFilterCompanies.push(b);
                                // }
                                // toDoFilter.push(b);
                                if (b.isNotActive) {
                                    b.hideFromListComplete = true;
                                } else {
                                    b.hideFromListComplete = false;
                                    toDoFilterCompanies.push(b);
                                }
                                if (b.item_type === "bhodi_product") {
                                    toDoFilter.push(b);
                                } else if (b.type === "product") {
                                    toDoFilter.push(b);
                                } else if (b.campaignType === "live_event") {
                                    let getStartDateEvent = new Date(b.startDate);
                                    let startTimeEventGood = b.startTime.concat(":00");
                                    getStartDateEvent.setHours(startTimeEventGood.split(":")[0]);
                                    getStartDateEvent.setMinutes(
                                        startTimeEventGood.split(":")[1]
                                    );
                                    if (getStartDateEvent.getTime() > new Date().getTime()) {
                                        toDoFilter.push(b);
                                    }
                                } else {
                                    toDoFilter.push(b);
                                }
                            });
                            let filteredSecMa = toDoFilterCompanies.filter(
                                (v, i, a) =>
                                    a.findIndex((t) => t.created_by === v.created_by) === i
                            );
                            let arrMainSellers = [];
                            filteredSecMa.map((y) => {
                                arrMainSellers.push(y.created_by);
                            });
                            this.setState({
                                guestUserCart: everyCartItems,
                                allSellers: arrMainSellers,
                            });
                            this.interval = setInterval(
                                () => this.setState({ timeCurrent: Date.now() }),
                                1000
                            );

                            if (this.props.guest_user_cart_time) {
                                let currentDate = Math.ceil(Date.now() / 1000);
                                if (
                                    Number(currentDate) < Number(this.props.guest_user_cart_time)
                                ) {
                                    this.props.updateGuestUserCart(everyCartItems);
                                    this.setState({
                                        endDate: this.props.guest_user_cart_time,
                                        extendOpportunity:
                                            this.props.guest_user_cart_extend_opportunity,
                                    });
                                }
                            }
                        } else {
                            clearInterval(this.interval);
                        }
                    } else {
                        clearInterval(this.interval);
                    }
                });
        }
    }

    getTheTimer() {
        let maxDate = this.state.endDate;
        let currentDate = Math.ceil(Date.now() / 1000);

        if (this.state.endDate) {
            if (Number(currentDate) < Number(maxDate)) {
                let dateToEnd = new Date(this.state.endDate * 1000);
                const date1 = new Date();
                const date2 = new Date(dateToEnd);
                // const diffTime = Math.abs(date2 - date1);
                // const diffMinutes = Math.ceil(diffTime / (1000 * 60));
                // const diffSeconds = Math.ceil(diffTime / (1000 * 60 * 60));

                var delta = Math.abs(date2 - date1) / 1000;

                var hours = Math.floor(delta / 3600) % 24;
                delta -= hours * 3600;

                var minutes = Math.floor(delta / 60) % 60;
                delta -= minutes * 60;

                var seconds = delta % 60;

                if (minutes < 10) {
                    minutes = `0${minutes}`;
                }

                if (Math.ceil(seconds) < 10) {
                    seconds = `0${Math.round(seconds)}`;
                } else {
                    seconds = Math.round(seconds);
                }

                return `Je reservering verloopt over: ${minutes}:${seconds}`;
            } else {
                this.setState({ endDate: "", stopCartQty: true });
                this.props.updateGuestUserCart([]);
                let cookieItem = localStorage.getItem("cookie_item");
                let userid = localStorage.getItem("userid");
                if (userid && !cookieItem) {
                    cookieItem = userid;
                }
                firebase
                    .database()
                    .ref("guest_carts/" + cookieItem)
                    .remove();
                this.props.updateGuestUserCartTime("");
                this.props.updateGuestUserCartExtendOpportunity("");
                clearInterval(this.interval);
                return "";
            }
        }
    }

    checkForTimerButton() {
        let filtered = this.state.guestUserCart.filter((b) => {
            return !b.isNotActive && !b.hideFromListComplete;
        });
        if (filtered.length > 0) {
            if (this.state.endDate) {
                return <p style={{ fontFamily: "Roboto", width: "90%", maxWidth: 640, margin: "0px auto", marginBottom: 20, color: "white", fontSize: 13, letterSpacing: 1 }}>{this.getTheTimer()}</p>;
            }
        }
    }

    getCharityObjectedQty(e) {
        let arrItems = e;
        if (arrItems.length > 0) {
            Array.prototype.sum = function (prop) {
                var total = 0;
                for (var i = 0, _len = this.length; i < _len; i++) {
                    total += Number(this[i][1][prop]);
                }
                return total;
            };
            return arrItems.sum("used_time");
        }
    }

    getVerzilveredQty(e) {
        let arrItems = e;
        if (arrItems.length > 0) {
            Array.prototype.sum = function (prop) {
                var total = 0;
                for (var i = 0, _len = this.length; i < _len; i++) {
                    total += Number(this[i][1][prop].length);
                }
                return total;
            };
            return arrItems.sum("allDates");
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.guest_user_cart_time) {
            if (this.props.guest_user_cart_time !== prevProps.guest_user_cart_time) {
                this.setState({ endDate: this.props.guest_user_cart_time });
            }
        }
        if (this.props.global_campaigns !== prevProps.global_campaigns) {
            if (this.props.global_campaigns !== prevProps.global_campaigns) {
                setTimeout(() => {
                    let allArrs = this.props.global_campaigns;
                    let availableKeysYet = [];
                    if (this.state.guestUserCart.length > 0) {
                        allArrs.map((y) => {
                            if (y[1].takeFromAdmin) {
                                let obj = mergeAdminEvent(y[1], this.props.global_campaigns)
                                y[1] = Object.assign({}, obj)
                            }
                            availableKeysYet.push(y[1].pushKey);
                            y[1].type = "campaign";
                            y[1].currentImageIndex = 0;
                            if (y[1].desc) {
                                y[1].desc = y[1].desc.substr(0, 200);
                                y[1].short_desc = y[1].desc.substr(0, 120);
                            }
                            if (y[1].campaignType !== "charity") {
                                y[1].images = [y[1].image];
                                y[1].imagesObjected = [
                                    { image: y[1].image, pushKey: y[1].pushKey },
                                ];
                            } else {
                                if (y[1].dishes) {
                                    y[1].images = [y[1].imageToRender];
                                    y[1].imagesObjected = [
                                        { image: y[1].imageToRender, pushKey: y[1].pushKey },
                                    ];
                                    y[1].title = y[1].main_title;
                                    y[1].desc = y[1].dishesObjected[0].description;
                                    y[1].charity_price = y[1].main_price;
                                    y[1].normal_price = y[1].main_price;
                                    y[1].amount = y[1].main_price;
                                }
                            }
                            if (y[1].campaignType === "local_event") {
                                if (y[1].takeFromAdmin) {
                                    let obj = mergeAdminEvent(y[1], this.props.global_campaigns)
                                    y[1] = Object.assign({}, obj)
                                }
                                this.state.guestUserCart.map((t, i) => {
                                    if (t.pushKey === y[1].pushKey) {
                                        if (!y[1].active) {
                                            y[1].isNotActive = true;
                                        } else {
                                            y[1].isNotActive = false;
                                        }
                                        if (this.state.guestUserCart[i].hideFromListComplete) {
                                            y[1].hideFromListComplete = true;
                                        } else {
                                            y[1].hideFromListComplete = false;
                                        }
                                        y[1].redeemed_code =
                                            this.state.guestUserCart[i].redeemed_code;
                                        this.state.guestUserCart[i] = y[1];
                                    }
                                });
                            }
                        });
                        this.state.guestUserCart.map((t) => {
                            if (t.item_type === "bhodi_product") {
                                return false;
                            }
                            if (!availableKeysYet.includes(t.pushKey)) {
                                t.isNotActive = true;
                            }
                        });
                        console.log(this.state.guestUserCart, "/working??");
                        this.props.updateGuestUserCart(this.state.guestUserCart);
                    }
                }, 200);
            }
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval)
    }

    render() {
        return (
            !this.props.notOnMainPage && (
                <div>
                    <div className="centered">
                        {this.checkForTimerButton()}
                    </div>
                </div>
            )
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducers.user,
        product: state.campaignsReducer.product,
        global_campaigns: state.campaignsReducer.global_campaigns,
        guest_user_cart: state.cartReducer.guest_user_cart,
        guest_user_cart_time: state.cartReducer.guest_user_cart_time,
        guest_user_cart_extend_opportunity: state.cartReducer.guest_user_cart_extend_opportunity,
        bhodi_products: state.productsReducers.bhodi_products,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateUser: (user) => dispatch(updateUser(user)),
        updateAlbums: (album) => dispatch(updateAlbums(album)),
        updateProducts: (product) => dispatch(updateProducts(product)),
        updateGuestUserCart: (guest_user_cart) =>
            dispatch(updateGuestUserCart(guest_user_cart)),
        updateGuestUserCartTime: (guest_user_cart_time) =>
            dispatch(updateGuestUserCartTime(guest_user_cart_time)),
        updateGuestUserCartExtendOpportunity: (
            guest_user_cart_extend_opportunity
        ) =>
            dispatch(
                updateGuestUserCartExtendOpportunity(guest_user_cart_extend_opportunity)
            ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FooterBasketTimer);
