

import React, { Component } from "react";
import "./index.css";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import CloseIcon from "@material-ui/icons/Close";
import { RiTimerLine, RiShoppingBasketLine } from "react-icons/ri";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import countries from "../../DataJSON/countries.json";
import { connect } from "react-redux";
import Footer from "../../Components/Footer";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import firebase from "../../Config/Firebase";
import {
    updateSubscriptionPlans,
    updateGuestUserCart,
    updateAdminOrNot,
    updateGuestUserCartTime,
    updateSignUpFieldsFilled,
} from "../../Redux/actions/authActions";
import FooterBasket from "../../Components/FooterBasket";
import FooterGreen from "../../Components/FooterGreen";
import { history } from "../../Config/Routes";
import TimerGec from "../../Components/TimerGec";
import FooterBasketTimer from "../../Components/FooterBasketTimer";
import swal from "sweetalert";
import { BsCheck } from "react-icons/bs";
import { checkForSubscribedExclusives } from "../../Functions/checkForSubscribedExclusives";
import { mergeAdminEvent } from "../../Functions/globalFunctions";
const axios = require("axios");

const bcrypt = require("bcryptjs");
const saltRounds = 10;

export class GuestEventCartStepTwo extends Component {
    constructor() {
        super();
        this.state = {
            country: "155",
            isUserForm: true,
            showCharityStuff: true,
            subscription_data: [],
            plan_id: "",
            plan_amount: "",
            split_amount: "",
            // registration fields
            firstName: "",
            firstNamePopup: false,
            lastName: "",
            lastNamePopup: false,
            email: "",
            emailPopup: false,
            password: "",
            passwordPopup: false,
            country: "155",
            companyRegistration: false,
            companyName: "",
            kvkCompany: "",
            kvkCompanyPopup: false,
            street: "",
            streetPopup: false,
            houseNumber: "",
            houseNumberPopup: false,
            city: "",
            cityPopup: false,
            zipCode: "",
            zipCodePopup: false,
            phoneNumber: "",
            phoneNumberPopup: false,
            company_website: "",
            company_websitePopup: false,
            passwordInputType: "password",
            guestUserCart: [],
            aanmeldenloader: false,
            allUsers: [],
            agreed: false,
            agreedPopup: false,
            currentUserSpecific: "",
            isUserCreated: false,
            currentDataOfUser: "",
            popupopenforright: true,
            mustSelectPlanId: false,
            organizationId: "",
            organizationUserId: "",
            productsCheckingOut: false,
            guestCartTime: "",
            deliveryNote: "",
            shipmentFeeAvailable: false,
            paymentMethodSelected: "",
            showPaymentMethods: false,
            showProductsTextCheckout: false,

            checkout_step_two_text_first: "",
            checkout_step_two_text_first_mobile: "",
            checkout_step_two_text_first_english: "",
            checkout_step_two_text_first_english_mobile: "",
            checkout_step_two_second_text: "",
            checkout_step_two_second_text_mobile: "",
            checkout_step_two_second_text_english: "",
            checkout_step_two_second_text_english_mobile: "",
            checkout_step_two_top_text: "",
            checkout_step_two_top_text_mobile: "",
            checkout_step_two_top_text_english: "",
            checkout_step_two_top_text_english_mobile: "",
            checkout_step_two_top_products_text: "",
            checkout_step_two_top_products_text_mobile: "",
            checkout_step_two_top_products_text_english: "",
            checkout_step_two_top_products_text_english_mobile: "",
            checkout_step_two_bottom_text: "",
            checkout_step_two_bottom_text_mobile: "",
            checkout_step_two_bottom_text_english: "",
            checkout_step_two_bottom_text_english_mobile: "",
            showFourEuro: false,

            showDutch: true,
            mustSelectRetailerFirst: false,
            selectingRetailerScreen: false,

            availableJoinedCampaigns: [],
            availableJoinedCampaignsDuplicated: [],

            companySearchText: "",
        };
        // window.addEventListener("beforeunload", (ev) => {
        //     if (!window.location.href.includes("stepTwo")) {
        //         var minutesToAdd = Number(process.env.REACT_APP_CART_TIME);
        //         var currentDate = new Date();
        //         var futureDate = new Date(currentDate.getTime() + minutesToAdd * 60000);

        //         let futureTimeStamp = Math.floor(futureDate.getTime() / 1000)

        //         this.props.updateGuestUserCartTime(futureTimeStamp)
        //     }
        // });
    }

    importScript() {

        let filtered = this.state.guestUserCart.filter((g) => {
            return g.item_type === "bhodi_product"
        })

        let filteredMain = filtered.filter((v, i, a) => a.findIndex((t) => t.created_by === v.created_by) === i);
        let purchaseUnits = []
        if (filtered.length > 0 && this.props.user) {
            let allCreatorsShipmentCost = []
            filteredMain.map((e) => {
                let filteredInner = this.props.user.filter((t) => {
                    return e.created_by === t.user_id
                })
                if (filteredInner.length > 0) {
                    if (this.props.global_values.shippingCostProducts && this.props.global_values.shippingCostProducts !== "free") {
                        let obj = {
                            shipmentCost: this.props.global_values.shippingCostProducts,
                            bhodi_id: filteredInner[0].user_id,
                        }
                        allCreatorsShipmentCost.push(obj)
                    }
                }
            })

            if (allCreatorsShipmentCost.length > 0) {
                allCreatorsShipmentCost.map((t, i) => {
                    let obj = {
                        "reference_id": `REFID-${t.bhodi_id}`,
                        "amount": {
                            "currency_code": "EUR",
                            "value": Number(t.shipmentCost).toFixed(2)
                        },
                        "payment_instruction": {
                            "disbursement_mode": "INSTANT"
                        }
                    }
                    purchaseUnits.push(obj)
                })
            }
        }

        if (purchaseUnits.length > 0) {
            let paypalSdkScript = document.createElement("script")
            paypalSdkScript.src = `https://www.paypal.com/sdk/js?client-id=AS_WXrkwOcMdxirbjpW230Zw_HKMN_kFhxR7XtmrB2FO4LFziukkSGX2Vtn1kBaHtZGKzKt4RWwp3r6j&components=buttons,payment-fields,marks,funding-eligibility&enable-funding=ideal&currency=EUR`
            // paypalSdkScript["data-merchant-id"] = "T2READKGCYSW2"
            paypalSdkScript.async = true

            let paypalSdkLoaded = new Promise((res) => {
                paypalSdkScript.onload = res
            })

            paypalSdkScript.onload = () => {
                let additionalScript = document.createElement("script")
                additionalScript.src = "../additionalScript.js"
                additionalScript.async = true

                window.purchase_units_main = purchaseUnits
                window.currentDataOfUser = this.state.currentDataOfUser

                // paypal.Marks({
                //   fundingSource: paypal.FUNDING.IDEAL
                // }).render('#ideallabel')
                // paypal.Marks({
                //   fundingSource: paypal.FUNDING.PAYPAL
                // }).render('#paypallabel')
                additionalScript.onload = () => {

                }
                document.head.appendChild(additionalScript)
            }

            document.head.appendChild(paypalSdkScript)
        }
    }

    componentDidMount() {
        const loaderId = document.getElementById("main-page-loader-id");
        loaderId.style.display = "none";

        setTimeout(() => {
            window.scrollTo(0, 0)
        }, 500);

        let userid = localStorage.getItem("userid");
        let useridGuest = localStorage.getItem("userid-guest");
        let useridNonMem = localStorage.getItem("userid-nonmem");
        let cookieItem = localStorage.getItem("cookie_item");

        let urlMain = new URLSearchParams(window.location.search)
        if (this.props.guest_user_cart && this.props.guest_user_cart.length > 0) {
            if (this.props.guest_user_cart[0].creatorCompanyId === process.env.REACT_APP_ADMIN_ID || this.props.guest_user_cart[0].created_by === process.env.REACT_APP_ADMIN_ID) {
                this.setState({ mustSelectRetailerFirst: true, selectingRetailerScreen: true })
            } else if (urlMain.get("select-retailer")) {
                this.setState({ mustSelectRetailerFirst: true, selectingRetailerScreen: true })
            }
        }


        if (this.props.global_campaigns) {
            let adminCampaign = this.props.global_campaigns.filter((g) => {
                return g[1].created_by === process.env.REACT_APP_ADMIN_ID && g[1].campaignType === "local_event"
            })
            if (adminCampaign.length > 0) {
                let campaignsJoined = this.props.global_campaigns.filter((t) => {
                    return t[1].takeFromAdmin
                })
                let availableJoinedCampaigns = []
                if (campaignsJoined.length > 0) {
                    campaignsJoined.map((campInner) => {
                        let g = campInner[1]
                        let obj = mergeAdminEvent(g, this.props.global_campaigns)
                        g = Object.assign({}, obj)
                        let arrTimingsAvailable = [];
                        if (g.timings) {
                            g.timings.map((e) => {
                                if (e.isoStartTime && e.isoEndTime) {
                                    let checkedInOfCurrentBlock = [];
                                    if (g.checked_in_by) {
                                        Object.entries(g.checked_in_by).map((t) => {
                                            if (t[1].event_id === e.eventId) {
                                                checkedInOfCurrentBlock.push(t[1]);
                                            }
                                        });
                                    }
                                    let getCurrentDate = new Date();
                                    if (getCurrentDate.getTime() < new Date(Date.parse(e.isoEndTime)).getTime()) {
                                        if (checkedInOfCurrentBlock.length < Number(e.personsAmountEvent)) {
                                            arrTimingsAvailable.push(e);
                                        }
                                    }
                                }
                            });
                        }
                        if (arrTimingsAvailable.length > 0 && g.active) {
                            availableJoinedCampaigns.push(g)
                        }
                    })
                    this.setState({
                        availableJoinedCampaigns,
                        availableJoinedCampaignsDuplicated: availableJoinedCampaigns,
                    })
                }
            }
        }

        let movedToCheckout = localStorage.getItem("movedToCheckout")
        if (movedToCheckout && cookieItem) {
            let filteredUser = this.props.user.filter((u) => {
                return u.user_id === userid;
            });

            firebase.database().ref("guest_carts/" + cookieItem).once("value", (data) => {
                let a = data.val()
                let arr = []
                if (a) {
                    let objected = Object.entries(a)
                    objected.map((t) => {
                        arr.push(t[1])
                    })
                    arr.map((g) => {
                        if (g.item_type === "bhodi_product") {
                            firebase.database().ref(`bhodi_products/${g.itemPush}/inCartOf/${g.pushKeyInCartOf}/cookie`).set(userid)
                            g.cookieItem = userid
                            firebase.database().ref(`guest_carts/${userid}/${g.pushKey}`).set(g)
                            firebase.database().ref(`users/${userid}/cookie_item`).remove()
                        } else if (g.creatorCompanyId) {
                            firebase.database().ref(`bhodi_campaigns/${g.creatorCompanyId}/${g.itemPush}/inCartOf/${g.pushKeyInCartOf}/cookie`).set(userid)
                            g.cookieItem = userid
                            firebase.database().ref(`guest_carts/${userid}/${g.pushKey}`).set(g)
                            firebase.database().ref(`users/${userid}/cookie_item`).remove()
                        }
                    })
                }

                firebase.database().ref(`guest_carts/${cookieItem}`).remove()
                localStorage.removeItem("cookie_item")
                localStorage.removeItem("movedToCheckout")
            })
            this.setState({ loadSwalStyle: true })
            swal({
                title: `Hi ${filteredUser[0].type === "company" ? filteredUser[0].company_name : filteredUser[0].first_name}`,
                text: "Je betaling is helaas mislukt, maar je account is wel aangemaakt! Je item blijft nog eventjes voor je bewaard in je winkelmand, zodat je de bestelling alsnog kunt afronden.",
                className: "swal-darker-overlay",
                icon: "warning"
            }).then(() => {
                if (filteredUser[0].type === "company") {
                    window.location.href = `/company/${filteredUser[0].user_name_id.toLowerCase()}`
                } else {
                    window.location.href = `/member/${filteredUser[0].user_name_id.toLowerCase()}`
                }
            })
        }

        // window.addEventListener('pageshow', (event) => {
        //     if (event.persisted) {
        //         window.location.reload()
        //     }
        // })

        // if (!window.location.href.includes("stepTwo")) {
        //     var minutesToAdd = Number(process.env.REACT_APP_CART_TIME);
        //     var currentDate = new Date();
        //     var futureDate = new Date(currentDate.getTime() + minutesToAdd * 60000);

        //     let futureTimeStamp = Math.floor(futureDate.getTime() / 1000)

        //     this.props.updateGuestUserCartTime(futureTimeStamp)
        // }

        if (this.props.guest_user_cart_time) {
            this.setState({ guestCartTime: this.props.guest_user_cart_time })
        }

        if (window.innerWidth > 1008) {
            this.setState({ popupopenforright: true });
        } else {
            this.setState({ popupopenforright: false });
        }

        if (this.props.user) {
            if (this.props.user.length > 0) {
                this.setState({ allUsers: this.props.user });
            }
        }
        if (this.props.guest_user_cart) {
            if (this.props.guest_user_cart.length > 0) {
                let seeFirstProduct = this.props.guest_user_cart[0]
                if (this.props.user && cookieItem) {
                    if (this.props.user.length > 0) {
                        // let filteredOfCarts = this.props.guest_user_cart.filter((v, i, a) => a.findIndex((t) => t.created_by === v.created_by) === i);
                        // if (filteredOfCarts.length > 1) {
                        //     return false;
                        // }
                        let filtered = this.props.user.filter((t) => {
                            if (seeFirstProduct.localEventIfSharedThen && !seeFirstProduct.chargeFromMainVendor) {
                                return t.user_id === seeFirstProduct.localEventIfSharedThen.created_by
                            }
                            return t.user_id === seeFirstProduct.created_by
                        })
                        if (filtered.length > 0) {
                            if (filtered[0].isAllowedAdministration) {
                                // if (filtered[0].bankAccountNumber && filtered[0].bankName && filtered[0].isAllowedAdministration) {
                                this.setState({ organizationUserId: filtered[0].user_id })
                            }
                        }
                    }
                }

                let filtered = this.props.guest_user_cart.filter((g) => {
                    return g.item_type === "bhodi_product"
                })
                let filteredMain = filtered.filter((v, i, a) => a.findIndex((t) => t.created_by === v.created_by) === i);
                if (filtered.length > 0 && this.props.user && userid) {
                    let allCreatorsShipmentCost = []
                    filteredMain.map((e) => {
                        let filteredInner = this.props.user.filter((t) => {
                            return e.created_by === t.user_id
                        })
                        if (filteredInner.length > 0) {
                            if (this.props.global_values.shippingCostProducts && this.props.global_values.shippingCostProducts !== "free") {
                                let obj = {
                                    shipmentCost: this.props.global_values.shippingCostProducts,
                                    bhodi_id: filteredInner[0].user_id,
                                }
                                allCreatorsShipmentCost.push(obj)
                            }
                        }
                    })
                    if (allCreatorsShipmentCost.length > 0) {
                        this.setState({ shipmentFeeAvailable: true })
                    } else {
                        document.querySelector(".paypal-pay-btn").style.display = "inline-flex"
                        this.setState({ shipmentFeeAvailable: false })
                    }
                } else {
                    document.querySelector(".paypal-pay-btn").style.display = "inline-flex"
                }

                if (this.props.guest_user_cart && this.props.guest_user_cart.length > 0 && this.props.guest_user_cart[0].item_type === "bhodi_product" && userid) {
                    let filtered = this.props.user.filter((t) => {
                        return t.user_id === seeFirstProduct.created_by
                    })
                    let filteredUser = this.props.user.filter((g) => {
                        return g.user_id === userid
                    })
                    if (filteredUser[0].subscription && filteredUser[0].subscription.status === "active") {
                        if (filtered.length > 0) {
                            if (filtered[0].productsInfo) {
                                if (filtered[0].productsInfo.deliveryNote) {
                                    this.setState({
                                        deliveryNote: filtered[0].productsInfo.deliveryNote,
                                    })
                                }
                            }
                        }
                        this.setState({ productsCheckingOut: true })
                    } else {
                        this.setState({ productsCheckingOut: false, showProductsTextCheckout: true })
                        document.querySelector(".paypal-pay-btn").style.display = "inline-flex"
                    }
                } else {
                    this.setState({ productsCheckingOut: false, showProductsTextCheckout: (this.props.guest_user_cart && this.props.guest_user_cart.length > 0 && this.props.guest_user_cart[0].item_type === "bhodi_product") ? true : false })
                }
                this.setState({ guestUserCart: this.props.guest_user_cart });
            } else {
                this.setState({ guestUserCart: [] });
            }
        }

        firebase
            .database()
            .ref("users")
            .once("value", (data) => {
                let a = data.val();
                let arr = [];
                if (a) {
                    let objected = Object.entries(a);
                    objected.map((g) => {
                        if (
                            g[0] !== undefined &&
                            g[0] !== "undefined" &&
                            g[0] !== null &&
                            g[0] !== "null"
                        ) {
                            arr.push(g[1]);
                        }
                    });
                    let usersWithNumbers = []
                    arr.map((t) => {
                        if (t.user_id_numbers) {
                            usersWithNumbers.push(t)
                        }
                    })
                    let sorted = usersWithNumbers.sort((a, b) => {
                        if (a.user_id_numbers && b.user_id_numbers) {
                            return a.user_id_numbers - b.user_id_numbers;
                        }
                    });
                    this.setState({
                        allUsers: arr,
                        currentUserSpecific:
                            Number(sorted[sorted.length - 1].user_id_numbers) + 1,
                    });
                }
            });

        let arr = [];
        if (this.props.subscription_plans) {
            if (this.props.subscription_plans.length > 0) {
                arr = this.props.subscription_plans;
                this.setState({
                    subscription_data: arr,
                });
            }
        }

        let filtered = [];
        if (this.props.user) {
            filtered = this.props.user.filter((u) => {
                if (useridNonMem) {
                    return u.user_id === useridNonMem;
                } else if (useridGuest) {
                    return u.user_id === useridGuest;
                } else {
                    return u.user_id === userid;
                }
            });
        }
        if (filtered.length > 0) {
            if (filtered[0].first_name) {
                this.setState({ firstName: filtered[0].first_name });
            }
            if (filtered[0].email) {
                this.setState({ email: filtered[0].email });
            }
            if (filtered[0].last_name) {
                this.setState({ lastName: filtered[0].last_name });
            }
            if (filtered[0].password) {
                this.setState({ password: filtered[0].password });
            }
            if (filtered[0].country) {
                let filteredSecond = countries.filter((y) => {
                    return y.name === filtered[0].country;
                });
                if (filteredSecond.length > 0) {
                    this.setState({ country: filteredSecond[0].id });
                }
            }
            if (filtered[0].street) {
                this.setState({ street: filtered[0].street });
            }
            if (filtered[0].city) {
                this.setState({ city: filtered[0].city });
            }
            if (filtered[0].zipCode) {
                this.setState({ zipCode: filtered[0].zipCode });
            }
            if (filtered[0].houseNumber) {
                this.setState({ houseNumber: filtered[0].houseNumber });
            }
            if (filtered[0].company_website) {
                this.setState({ company_website: filtered[0].company_website });
            }
            if (filtered[0].kvkCompany) {
                this.setState({ kvkCompany: filtered[0].kvkCompany });
            }
            if (filtered[0].phoneNumber) {
                this.setState({ phoneNumber: filtered[0].phoneNumber });
            }
            if (filtered[0].company_name) {
                this.setState({
                    companyName: filtered[0].company_name,
                    isUserForm: false,
                });
            }
            if (filtered[0]) {
                if (filtered[0].type === "company") {
                    this.setState({
                        redirectUrl: `${window.location.origin}/company/${filtered[0].user_name_id}/charityConfirmation`,
                    });
                } else {
                    this.setState({
                        redirectUrl: `${window.location.origin}/member/${filtered[0].user_name_id}/charityConfirmation`,
                    });
                }
            }
            this.setState({ isUserCreated: true, currentDataOfUser: filtered[0] });
        } else {
            this.setState({ isUserCreated: false, currentDataOfUser: "" });
        }

        setTimeout(() => {
            this.importScript()
        }, 1000);


        if (this.props.global_values) {
            let a = this.props.global_values;
            if (a) {
                this.setState({
                    checkout_step_two_text_first: a.checkout_step_two_text_first || "",
                    checkout_step_two_text_first_mobile: a.checkout_step_two_text_first_mobile || "",
                    checkout_step_two_text_first_english: a.checkout_step_two_text_first_english || "",
                    checkout_step_two_text_first_english_mobile: a.checkout_step_two_text_first_english_mobile || "",
                    checkout_step_two_second_text: a.checkout_step_two_second_text || "",
                    checkout_step_two_second_text_mobile: a.checkout_step_two_second_text_mobile || "",
                    checkout_step_two_second_text_english: a.checkout_step_two_second_text_english || "",
                    checkout_step_two_second_text_english_mobile: a.checkout_step_two_second_text_english_mobile || "",
                    checkout_step_two_top_text: a.checkout_step_two_top_text || "",
                    checkout_step_two_top_text_mobile: a.checkout_step_two_top_text_mobile || "",
                    checkout_step_two_top_text_english: a.checkout_step_two_top_text_english || "",
                    checkout_step_two_top_text_english_mobile: a.checkout_step_two_top_text_english_mobile || "",
                    checkout_step_two_top_products_text: a.checkout_step_two_top_products_text || "",
                    checkout_step_two_top_products_text_mobile: a.checkout_step_two_top_products_text_mobile || "",
                    checkout_step_two_top_products_text_english: a.checkout_step_two_top_products_text_english || "",
                    checkout_step_two_top_products_text_english_mobile: a.checkout_step_two_top_products_text_english_mobile || "",
                    checkout_step_two_bottom_text: a.checkout_step_two_bottom_text || "",
                    checkout_step_two_bottom_text_mobile: a.checkout_step_two_bottom_text_mobile || "",
                    checkout_step_two_bottom_text_english: a.checkout_step_two_bottom_text_english || "",
                    checkout_step_two_bottom_text_english_mobile: a.checkout_step_two_bottom_text_english_mobile || "",
                    showFourEuro: a.showFourEuro || false,
                });
            }
        }


        firebase
            .database()
            .ref("bhodi_home")
            .on("value", (data) => {
                let a = data.val();
                if (a) {
                    this.setState({
                        checkout_step_two_text_first: a.checkout_step_two_text_first || "",
                        checkout_step_two_text_first_mobile: a.checkout_step_two_text_first_mobile || "",
                        checkout_step_two_text_first_english: a.checkout_step_two_text_first_english || "",
                        checkout_step_two_text_first_english_mobile: a.checkout_step_two_text_first_english_mobile || "",
                        checkout_step_two_second_text: a.checkout_step_two_second_text || "",
                        checkout_step_two_second_text_mobile: a.checkout_step_two_second_text_mobile || "",
                        checkout_step_two_second_text_english: a.checkout_step_two_second_text_english || "",
                        checkout_step_two_second_text_english_mobile: a.checkout_step_two_second_text_english_mobile || "",
                        checkout_step_two_top_text: a.checkout_step_two_top_text || "",
                        checkout_step_two_top_text_mobile: a.checkout_step_two_top_text_mobile || "",
                        checkout_step_two_top_text_english: a.checkout_step_two_top_text_english || "",
                        checkout_step_two_top_text_english_mobile: a.checkout_step_two_top_text_english_mobile || "",
                        checkout_step_two_top_products_text: a.checkout_step_two_top_products_text || "",
                        checkout_step_two_top_products_text_mobile: a.checkout_step_two_top_products_text_mobile || "",
                        checkout_step_two_top_products_text_english: a.checkout_step_two_top_products_text_english || "",
                        checkout_step_two_top_products_text_english_mobile: a.checkout_step_two_top_products_text_english_mobile || "",
                        checkout_step_two_bottom_text: a.checkout_step_two_bottom_text || "",
                        checkout_step_two_bottom_text_mobile: a.checkout_step_two_bottom_text_mobile || "",
                        checkout_step_two_bottom_text_english: a.checkout_step_two_bottom_text_english || "",
                        checkout_step_two_bottom_text_english_mobile: a.checkout_step_two_bottom_text_english_mobile || "",
                        showFourEuro: a.showFourEuro || false,
                    });
                }
            });
    }

    componentWillUnmount() {
        const script = document.querySelector('script[src="https://www.paypal.com/sdk/js?client-id=AS_WXrkwOcMdxirbjpW230Zw_HKMN_kFhxR7XtmrB2FO4LFziukkSGX2Vtn1kBaHtZGKzKt4RWwp3r6j&components=buttons,payment-fields,marks,funding-eligibility&enable-funding=ideal&currency=EUR"]')
        if (script) {
            document.head.removeChild(script)
        }

        const additionalScript = document.querySelector('script[src="../additionalScript.js"]')
        if (additionalScript) {
            document.head.removeChild(additionalScript)
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.guest_user_cart_time !== this.props.guest_user_cart_time) {
            if (this.props.guest_user_cart_time) {
                this.setState({ guestCartTime: this.props.guest_user_cart_time })
            } else {
                let movedToCheckout = localStorage.getItem("movedToCheckout")
                if (!movedToCheckout) {
                    swal(
                        "LET OP",
                        "Cart is expired",
                        "error"
                    ).then(() => {
                        // history.go(-2);
                        let moveBackTwo = localStorage.getItem("moveback-two")
                        if (moveBackTwo) {
                            localStorage.removeItem("moveback-two")
                            history.go(-2)
                        } else {
                            history.goBack()
                        }
                    });
                    this.setState({ guestCartTime: "", guestUserCart: [] })
                }
            }
        }
        // if (prevProps.guest_user_cart !== this.props.guest_user_cart) {
        //   if (this.props.guest_user_cart.length === 0) {
        //     this.setState({ guestUserCart: [] });
        //   }
        // }
        if (this.props.global_campaigns !== prevProps.global_campaigns) {
            if (this.props.global_campaigns !== prevProps.global_campaigns) {
                setTimeout(() => {
                    let allArrs = this.props.global_campaigns;
                    let allArrsProducts = this.props.products_new;
                    let availableKeysYet = [];
                    if (this.state.guestUserCart.length > 0) {
                        allArrs.map((y) => {
                            if (y[1].takeFromAdmin) {
                                let obj = mergeAdminEvent(y[1], this.props.global_campaigns)
                                y[1] = Object.assign({}, obj)
                            }
                            availableKeysYet.push(y[1].pushKey);
                            y[1].type = "campaign";
                            y[1].currentImageIndex = 0;


                            if (y[1].desc) {
                                y[1].desc = y[1].desc.substr(0, 200);
                                y[1].short_desc = y[1].desc.substr(0, 120);
                            }
                            y[1].images = [y[1].image];
                            y[1].imagesObjected = [
                                { image: y[1].image, pushKey: y[1].pushKey },
                            ];
                            if (y[1].campaignType === "local_event") {
                                if (y[1].takeFromAdmin) {
                                    let obj = mergeAdminEvent(y[1], this.props.global_campaigns)
                                    y[1] = Object.assign({}, obj)
                                }
                                this.state.guestUserCart.map((t, i) => {
                                    if (t.pushKey === y[1].pushKey) {
                                        if (!y[1].active) {
                                            y[1].isNotActive = true;
                                        } else {
                                            y[1].isNotActive = false;
                                        }
                                        if (this.state.guestUserCart[i].hideFromListComplete) {
                                            y[1].hideFromListComplete = true;
                                        } else {
                                            y[1].hideFromListComplete = false;
                                        }
                                        let obj = {
                                            reserved_event_id: t.reserved_event_id,
                                            ...y[1]
                                        }
                                        this.state.guestUserCart[i] = obj;
                                        // this.state.guestUserCart[i] = y[1];
                                    }
                                });
                            }
                            if (y[1].campaignType === "kortings_voucher" || y[1].campaignType === "web_shop") {
                                this.state.guestUserCart.map((t, i) => {
                                    if (t.pushKey === y[1].pushKey) {
                                        if (!y[1].active) {
                                            y[1].isNotActive = true;
                                        } else {
                                            y[1].isNotActive = false;
                                        }
                                        if (this.state.guestUserCart[i].hideFromListComplete) {
                                            y[1].hideFromListComplete = true;
                                        } else {
                                            y[1].hideFromListComplete = false;
                                        }
                                        this.state.guestUserCart[i] = y[1];
                                    }
                                });
                            }
                        });
                        // allArrsProducts.map((y) => {
                        //     availableKeysYet.push(y.pushKey)
                        //     this.state.guestUserCart.map((t, i) => {
                        //         if (t.pushKey === y.pushKey) {
                        //             if (!y.active) {
                        //                 y.isNotActive = true
                        //             } else {
                        //                 y.isNotActive = false
                        //             }
                        //             if (this.state.guestUserCart[i].hideFromListComplete) {
                        //                 y.hideFromListComplete = true
                        //             } else {
                        //                 y.hideFromListComplete = false
                        //             }
                        //             this.state.guestUserCart[i] = y[1]
                        //         }
                        //     })
                        // })
                        this.state.guestUserCart.map((t) => {
                            if (t.item_type === "bhodi_product") {
                                return false;
                            }
                            if (!availableKeysYet.includes(t.pushKey)) {
                                t.isNotActive = true;
                            }
                        });
                        this.props.updateGuestUserCart(this.state.guestUserCart);
                    }
                }, 200);
            }
        }
        if (prevProps.user !== this.props.user && !this.props.signup_fields_info) {
            if (this.state.email) {
                let filtered = this.props.user.filter((u) => {
                    return u.email === this.state.email;
                });
                if (filtered.length > 0) {
                    this.setState({ currentDataOfUser: filtered[0] });
                } else {
                    this.setState({ currentDataOfUser: "" });
                }
            }
        }
    }

    getDayName(num) {
        if (num === 0) {
            return `Zondag`;
        } else if (num === 1) {
            return `Maandag`;
        } else if (num === 2) {
            return `Dinsdag`;
        } else if (num === 3) {
            return `Woensdag`;
        } else if (num === 4) {
            return `Donderdag`;
        } else if (num === 5) {
            return `Vrijdag`;
        } else if (num === 6) {
            return `Zaterdag`;
        }
    }

    getMonthName(i) {
        i = Number(i);
        if (i === 1) {
            return "Januari";
        } else if (i === 2) {
            return "Februari";
        } else if (i === 3) {
            return "Maart";
        } else if (i === 4) {
            return "April";
        } else if (i === 5) {
            return "Mei";
        } else if (i === 6) {
            return "Juni";
        } else if (i === 7) {
            return "Juli";
        } else if (i === 8) {
            return "Augustus";
        } else if (i === 9) {
            return "September";
        } else if (i === 10) {
            return "Oktober";
        } else if (i === 11) {
            return "November";
        } else if (i === 12) {
            return "December";
        }
    }

    showDateToUserTiming(e, f) {
        if (e === f) {
            let splitted = e.split("-");
            let newDate = new Date(e).getDay();
            return `${this.getDayName(newDate)} ${splitted[2]} ${this.getMonthName(
                splitted[1]
            )}`;
        } else {
            let splitted = e.split("-");
            let splittedSecond = f.split("-");
            let newDate = new Date(e).getDay();
            let newDateAnother = new Date(f).getDay();
            if (splitted[1] === splittedSecond[1]) {
                return `${this.getDayName(newDate)} ${splitted[2]} - ${splittedSecond[2]
                    } ${this.getMonthName(splittedSecond[1])}`;
            } else {
                // return `${this.getDayName(newDate)} ${splitted[2]} ${this.getMonthName(splitted[1])} - ${this.getDayName(newDateAnother)} ${splittedSecond[2]} ${this.getMonthName(splittedSecond[1])}`
                return `${splitted[2]} ${this.getMonthName(splitted[1])} t/m ${splittedSecond[2]
                    } ${this.getMonthName(splittedSecond[1])}`;
            }
        }
    }

    randomStr(e) {
        let len = e;
        let arr = "abcdefghijklmnopqrstuvwxyz1928102489";
        var ans = "";
        for (var i = len; i > 0; i--) {
            ans += arr[Math.floor(Math.random() * arr.length)];
        }
        return ans;
    }

    getCharityObjectedQty(e) {
        let arrItems = e;
        if (arrItems.length > 0) {
            Array.prototype.sum = function (prop) {
                var total = 0;
                for (var i = 0, _len = this.length; i < _len; i++) {
                    total += Number(this[i][1][prop]);
                }
                return total;
            };
            return arrItems.sum("used_time");
        }
    }

    getVerzilveredQty(e) {
        let arrItems = e;
        if (arrItems.length > 0) {
            Array.prototype.sum = function (prop) {
                var total = 0;
                for (var i = 0, _len = this.length; i < _len; i++) {
                    total += Number(this[i][1][prop].length);
                }
                return total;
            };
            return arrItems.sum("allDates");
        }
    }

    saveSubscription() {

        let userid = localStorage.getItem("userid")
        if (this.state.guestUserCart[0].item_type === "bhodi_product" && userid && this.state.currentDataOfUser.subscription && this.state.currentDataOfUser.subscription.status === "active") {
            this.checkForPaypalOrder()
            return false;
        }

        if (!this.state.plan_id) {
            this.setState({ mustSelectPlanId: true })

            setTimeout(() => {
                this.setState({ mustSelectPlanId: false })
            }, 2000);
            return;
        }

        this.setState({ aanmeldenloader: true, mustSelectPlanId: false });
        let uid = localStorage.getItem("userid");
        let uidGuest = localStorage.getItem("userid-guest");
        let uidNonMem = localStorage.getItem("userid-nonmem");

        let plan_amount = this.state.plan_amount;
        let plan_id = this.state.plan_id;

        let redirect_url = "";
        if (this.state.currentDataOfUser.type === "company") {
            redirect_url = `${window.location.origin
                }/company/${this.state.currentDataOfUser.user_name_id.toLowerCase()}/charityConfirmation`;
        } else {
            redirect_url = `${window.location.origin
                }/member/${this.state.currentDataOfUser.user_name_id.toLowerCase()}/charityConfirmation`;
        }

        let promiseCustomer = new Promise((res, rej) => {
            if (this.state.currentDataOfUser.stripe_id) {
                res(this.state.currentDataOfUser.stripe_id)
            } else {
                axios
                    .post(
                        "https://us-central1-bhodi-production-da765.cloudfunctions.net/createStripeCustomer",
                        {
                            fullName: this.state.companyName ? this.state.companyName : this.state.firstName + "" + this.state.lastName,
                            email: this.state.email,
                        }
                    )
                    .then((response) => {
                        if (response.data.statusCode === 200) {
                            res(response.data.data.id)
                        }
                    })
            }
        })
        promiseCustomer.then((stripeId) => {

            let promiseConvertCart = new Promise((res) => {
                if (this.state.mustSelectRetailerFirst) {
                    let checkForCart = this.props.global_campaigns.filter((t) => {
                        return t[1].created_by === process.env.REACT_APP_ADMIN_ID && t[1].campaignType === "local_event"
                    })
                    let addInCartOf = this.props.global_campaigns.filter((t) => {
                        return t[1].created_by === this.state.companyIsSelected
                    })
                    if (checkForCart.length > 0) {
                        let cookieItem = localStorage.getItem("cookie_item");
                        if (checkForCart[0][1].inCartOf) {
                            let objectedInCartOf = Object.entries(checkForCart[0][1].inCartOf)
                            let filtered = objectedInCartOf.filter((g) => {
                                return g[1].cookie === cookieItem
                            })

                            let objToMake = {
                                cookieItem,
                                itemPush: addInCartOf[0][1].pushKey,
                                timestamp: this.props.guest_user_cart_time,
                                event_id: 1,
                            };

                            let pushKey = firebase.database().ref("guest_carts/" + cookieItem).push().key;
                            objToMake.pushKey = pushKey;

                            let pushKeyInCartOf = firebase.database().ref(`bhodi_campaigns/${addInCartOf[0][1].created_by}/${addInCartOf[0][1].pushKey}/inCartOf`).push().key;

                            let objInCartOf = {
                                cookie: cookieItem,
                                pushKey: pushKeyInCartOf,
                                event_id: 1,
                            };

                            objToMake.pushKeyInCartOf = pushKeyInCartOf;
                            objToMake.creatorCompanyId = addInCartOf[0][1].created_by;

                            firebase.database().ref(`bhodi_campaigns/${addInCartOf[0][1].created_by}/${addInCartOf[0][1].pushKey}/inCartOf/${pushKeyInCartOf}`).set(objInCartOf);

                            firebase.database().ref(`bhodi_campaigns/${checkForCart[0][1].created_by}/${checkForCart[0][1].pushKey}/inCartOf/${filtered[0][1].pushKey}`).remove()

                            let mainObj = {
                                [objToMake.pushKey]: objToMake
                            }

                            firebase.database().ref("guest_carts/" + cookieItem).set(mainObj)

                            if (this.props.user) {
                                if (this.props.user.length > 0) {
                                    let filtered = this.props.user.filter((t) => {
                                        return t.user_id === addInCartOf[0][1].created_by
                                    })
                                    if (filtered.length > 0) {
                                        if (filtered[0].isAllowedAdministration) {
                                            const updates = {
                                                [`users/${uidNonMem ? uidNonMem : uidGuest ? uidGuest : uid}/company_referred`]: filtered[0].user_id,
                                            };

                                            firebase.database().ref().update(updates)
                                        }
                                    }
                                }
                            }

                            res()
                        }
                    }
                } else {
                    res()
                }
            })

            promiseConvertCart.then(() => {
                let paymentObject = {
                    priceId: this.state.stripe_plan_key,
                    customerId: stripeId,
                    successUrl: redirect_url,
                    cancelUrl: `${window.location.origin}/guest-event-cart/stepTwo`,
                }
                if (this.props.guest_user_cart && this.props.guest_user_cart.length > 0 && this.props.guest_user_cart[0].item_type === "bhodi_product") {
                    paymentObject.extraLineItems = {
                        price: this.props.global_values.shipmentPriceIdStripe,
                        quantity: 1,
                    }
                }
                if (this.state.organizationUserId) {
                    paymentObject.routeAmount = this.state.split_amount
                    paymentObject.orgId = this.state.organizationUserId
                    paymentObject.uid = uid
                    paymentObject.description = `${this.state.firstName + "" + this.state.lastName}'s recurring payment`
                    let pushKeyOfData = firebase.database().ref(`payouts_info/${this.state.organizationUserId}/${stripeId}`).push().key
                    paymentObject.pushKeyOfData = pushKeyOfData
                }

                axios
                    .post(
                        "https://us-central1-bhodi-production-da765.cloudfunctions.net/createStripeCheckout",
                        paymentObject,
                    )
                    .then((responseSec) => {
                        if (uidGuest) {
                            localStorage.setItem("userid", uidGuest)
                            localStorage.removeItem("userid-guest")
                        }
                        if (uidNonMem) {
                            localStorage.setItem("userid", uidNonMem)
                            localStorage.removeItem("userid-nonmem")
                        }
                        const updates = {
                            [`users/${uidNonMem ? uidNonMem : uidGuest ? uidGuest : uid}/create_subscription`]: true,
                            [`users/${uidNonMem ? uidNonMem : uidGuest ? uidGuest : uid}/load_order`]: true,
                            [`users/${uidNonMem ? uidNonMem : uidGuest ? uidGuest : uid}/plan_id`]: plan_id,
                            [`users/${uidNonMem ? uidNonMem : uidGuest ? uidGuest : uid}/plan_amount`]: plan_amount,
                            [`users/${uidNonMem ? uidNonMem : uidGuest ? uidGuest : uid}/payment_id`]: responseSec.data.data.id,
                        };

                        firebase.database().ref().update(updates)
                            .then(() => {
                                this.setState({ aanmeldenloader: false });
                                document.querySelector(".paypal-pay-btn").style.display = "inline-flex"
                                localStorage.setItem("movedToCheckout", true)

                                window.location.href = responseSec.data.data.url
                            });
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            })

        })

        // })
    }

    startNewSubscription() {
        if (this.state.guestUserCart[0].item_type === "bhodi_product" && this.state.currentDataOfUser.subscription && this.state.currentDataOfUser.subscription.status === "active") {
            this.checkForPaypalOrder()
            return false;
        }
        if (!this.state.plan_id) {
            this.setState({ mustSelectPlanId: true })

            setTimeout(() => {
                this.setState({ mustSelectPlanId: false })
            }, 2000);
            return;
        }

        alert("working here")
        return false;

        this.setState({ mustSelectPlanId: false })
        let plan_amount = this.state.plan_amount;
        let plan_id = this.state.plan_id;
        let userid = localStorage.getItem("userid");

        let uid = localStorage.getItem("userid");

        var d = new Date();
        // d.setDate(d.getDate() + 30)

        firebase
            .database()
            .ref("users/" + userid + "/plan_id")
            .set(plan_id);
        firebase
            .database()
            .ref("users/" + userid + "/create_subscription")
            .set(true);
        firebase
            .database()
            .ref("users/" + userid + "/plan_amount")
            .set(plan_amount);
        firebase
            .database()
            .ref("users/" + userid + "/is_canceled")
            .remove();
        firebase
            .database()
            .ref("users/" + userid + "/is_paused")
            .remove()
            .then(() => {
                setTimeout(() => {
                    if (this.state.currentDataOfUser.type === "company") {
                        history.push(`/company/${this.state.currentDataOfUser.user_name_id.toLowerCase()}/charityConfirmation`);
                        // return (
                        //     <Redirect
                        //       to={`/company/${this.state.currentDataOfUser.user_name_id.toLowerCase()}/charityConfirmation`}
                        //     />
                        //   );
                        // Commented By Me
                        // window.location.href = `/company/${this.state.currentDataOfUser.user_name_id.toLowerCase()}/charityConfirmation`;
                    } else {
                        // Commented By Me
                        history.push(`/member/${this.state.currentDataOfUser.user_name_id.toLowerCase()}/charityConfirmation`);
                        // return (
                        //   <Redirect
                        //     to={`/member/${this.state.currentDataOfUser.user_name_id.toLowerCase()}/charityConfirmation`}
                        //   />
                        // );

                        // window.location.href = `/member/${this.state.currentDataOfUser.user_name_id.toLowerCase()}/charityConfirmation`;
                    }
                }, 1000);
            });

        // this.setState({ startSubLoader: true })

        // let month = (d.getMonth() + 1)
        // let day = d.getDate()
        // let year = d.getFullYear()

        // if (month < 10) {
        //     month = '0' + month;
        // }
        // if (day < 10) {
        //     day = '0' + day;
        // }

        // let date = [year, month, day].join('-')
        // let reverseDate = [day, month, year].join('-')
        // let description = `Subscription no ${this.state.currentDataOfUser.user_id_numbers} ${this.state.currentDataOfUser.first_name} ${this.state.currentDataOfUser.last_name}`

        // if (plan_id) {
        //     axios.post("https://us-central1-bhodi-production-da765.cloudfunctions.net/createSubscription", {
        //         customerId: this.state.currentDataOfUser.stripe_id,
        //         amount: plan_amount,
        //         startDate: date,
        //         description,
        //     })
        //         .then((response) => {
        //             if (!response.data.error) {
        //                 firebase.database().ref("users/" + userid + "/subscription").set(response.data.subscription)
        //                 firebase.database().ref("users/" + userid + "/subscription_id").set(response.data.subscription.id).then(() => {
        //                     this.setState({ stopLoader: false, pauseLoader: false, startSubLoader: false, updateLoader: false })
        //                 })
        //             }
        //         })
        //         .catch((error) => {
        //             console.log(error);
        //         });
        // }
    }

    checkForPaypalOrder() {
        this.setState({ aanmeldenloader: true, mustSelectPlanId: false });

        let filtered = this.state.guestUserCart.filter((g) => {
            return g.item_type === "bhodi_product"
        })
        let return_url = "";
        let cancel_url = "";
        if (this.state.currentDataOfUser.type === "company") {
            return_url = `${window.location.origin}/company/${this.state.currentDataOfUser.user_name_id.toLowerCase()}/charityConfirmation?show-product-text=true`;
            cancel_url = `${window.location.origin}/company/${this.state.currentDataOfUser.user_name_id.toLowerCase()}/charityConfirmation?show-product-text=true`;
        } else {
            return_url = `${window.location.origin}/member/${this.state.currentDataOfUser.user_name_id.toLowerCase()}/charityConfirmation?show-product-text=true`;
            cancel_url = `${window.location.origin}/member/${this.state.currentDataOfUser.user_name_id.toLowerCase()}/charityConfirmation?show-product-text=true`;
        }
        let filteredMain = filtered.filter((v, i, a) => a.findIndex((t) => t.created_by === v.created_by) === i);
        if (filtered.length > 0 && this.props.user) {
            let allCreatorsShipmentCost = []
            filteredMain.map((e) => {
                let filteredInner = this.props.user.filter((t) => {
                    return e.created_by === t.user_id
                })
                if (filteredInner.length > 0) {
                    if (this.props.global_values.shippingCostProducts && this.props.global_values.shippingCostProducts !== "free") {
                        let obj = {
                            shipmentCost: this.props.global_values.shippingCostProducts,
                            bhodi_id: filteredInner[0].user_id,
                        }
                        allCreatorsShipmentCost.push(obj)
                    }
                }
            })

            if (allCreatorsShipmentCost.length > 0) {
                let purchaseUnits = []
                allCreatorsShipmentCost.map((t, i) => {
                    let obj = {
                        "reference_id": `REFID-${t.bhodi_id}`,
                        "amount": {
                            "currency_code": "EUR",
                            "value": Number(t.shipmentCost).toFixed(2)
                        },
                        "payment_instruction": {
                            "disbursement_mode": "INSTANT"
                        }
                    }
                    purchaseUnits.push(obj)
                })

                let data = JSON.stringify({
                    "intent": "CAPTURE",
                    "application_context": {
                        "user_action": "PAY_NOW",
                        "return_url": return_url,
                        "cancel_url": cancel_url
                    },
                    "purchase_units": purchaseUnits,
                    // "payment_source": {
                    //   "ideal": {
                    //     "country_code": "NL",
                    //     "name": "John Doe"
                    //   }
                    // }
                });

                var config = {
                    method: "get",
                    url: "https://us-central1-bhodi-production-da765.cloudfunctions.net/getAuthDetails",
                    headers: {},
                };

                axios(config)
                    .then((response) => {
                        if (response.status === 200) {
                            let accessToken = response.data.access_token;

                            var configAnother = {
                                method: "post",
                                url: `https://us-central1-bhodi-production-da765.cloudfunctions.net/createPaypalOrder`,
                                headers: {
                                    authToken: accessToken,
                                    "Content-Type": "application/json",
                                },
                                data: data
                            };

                            axios(configAnother)
                                .then((responseNew) => {
                                    this.setState({ aanmeldenloader: false });
                                    if (responseNew.data.statusCode === 200 && responseNew.data.links && responseNew.data.links.length > 0) {
                                        let checkForLink = responseNew.data.links.filter((t) => {
                                            return t.rel === "approve"
                                        })
                                        if (checkForLink.length > 0) {
                                            window.location.href = checkForLink[0].href
                                        }
                                    }
                                    console.log(responseNew)
                                })
                                .catch(function (error) {
                                    console.log(error);
                                });
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            } else {
                if (this.state.currentDataOfUser.type === "company") {
                    history.push(`/company/${this.state.currentDataOfUser.user_name_id.toLowerCase()}/charityConfirmation?checkout-type=product&free-of-cost=true&show-product-text=true`);
                } else {
                    history.push(`/member/${this.state.currentDataOfUser.user_name_id.toLowerCase()}/charityConfirmation?checkout-type=product&free-of-cost=true&show-product-text=true`);
                }
            }
        }
    }

    getSellerName(e) {
        if (e) {
            let filteredUser = this.props.user.filter((g) => {
                if (e.mainParentCreator) {
                    return g.user_id === e.mainParentCreator;
                } else {
                    return g.user_id === e.created_by;
                }
            });
            if (filteredUser.length > 0) {
                if (filteredUser[0].type === "company") {
                    if (filteredUser[0].company_name) {
                        return filteredUser[0].company_name;
                    } else {
                        return filteredUser[0].first_name + " " + filteredUser[0].last_name;
                    }
                } else {
                    return filteredUser[0].first_name + " " + filteredUser[0].last_name;
                }
            }
        }
    }

    getDiscountedValue(e, discount) {
        if (discount !== "Giveaway") {
            let splitted = discount.split("%");
            var numVal1 = Number(e);
            var numVal2 = Number(splitted[0]) / 100;
            var totalValue = numVal1 - numVal1 * numVal2;
            return totalValue;
        }
    }

    createMarkup(e) {
        if (e) {
            return { __html: e };
        }
    }


    getCompanyName(uid) {
        let filtered = this.props.user.filter((g) => {
            return g.user_id === uid;
        });
        if (filtered.length > 0) {
            return filtered[0].company_name;
        }
    }

    getCompanyCity(uid) {
        let filtered = this.props.user.filter((g) => {
            return g.user_id === uid;
        });
        if (filtered.length > 0) {
            return filtered[0].city;
        }
    }

    getCompanyImage(uid) {
        let filtered = this.props.user.filter((g) => {
            return g.user_id === uid;
        });
        if (filtered.length > 0) {
            if (filtered[0].profile_image) {
                return filtered[0].profile_image;
            } else {
                return "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9";
            }
        }
    }

    render() {
        return (
            <div className="global-event-cart globaleventcartfixedstickyfooter">
                {this.state.loadSwalStyle && <link rel="stylesheet" type="text/css" href="/stepTwoSwalStyle.css"></link>}
                <div className="left-gec charitylgec">
                    {window.innerWidth > 1008 && (<div className="button-back-lgec" onClick={() => {
                        if (this.state.mustSelectRetailerFirst && !this.state.selectingRetailerScreen) {
                            this.setState({ selectingRetailerScreen: true })
                            return false;
                        }
                        let moveBackTwo = localStorage.getItem("moveback-two")
                        if (moveBackTwo) {
                            localStorage.removeItem("moveback-two")
                            history.go(-2)
                        } else {
                            history.goBack()
                        }
                    }}>
                        <ChevronLeftIcon />
                    </div>)}
                    {this.state.selectingRetailerScreen ? (<div className="container-lgec">
                        <div className="search-container-lgec">
                            <input type="text" placeholder="Zoek op naam" value={this.state.companySearchText} onChange={(e) => {
                                let value = e.target.value
                                if (value.trim() === "") {
                                    this.setState({ companySearchText: value, availableJoinedCampaigns: this.state.availableJoinedCampaignsDuplicated })
                                } else {
                                    let filtered = [...this.state.availableJoinedCampaignsDuplicated].filter((g) => {
                                        let companyName = this.getCompanyName(g.created_by)
                                        return companyName.toLowerCase().includes(value.toLowerCase())
                                    })
                                    this.setState({ companySearchText: value, availableJoinedCampaigns: filtered })
                                }
                            }} />
                            <ChevronRightIcon />
                        </div>
                        <div className="lists-retaurants-lgec">
                            <p className="para-main-lrlg">Selecteer een restaurant en geniet van de actie</p>
                            <div className="container-lrlgec">
                                {this.state.availableJoinedCampaigns.length > 0 && this.state.availableJoinedCampaigns.map((campinfo) => {
                                    return (<div className="inner-clrlgec">
                                        <img src={this.getCompanyImage(campinfo.created_by)} alt="user-pic" />
                                        <div className="content-iclrlgec">
                                            <p>{this.getCompanyName(campinfo.created_by)}</p>
                                            <p>{this.getCompanyCity(campinfo.created_by)}</p>
                                        </div>
                                        <button style={{ backgroundColor: this.state.companyIsSelected === campinfo.created_by && "black", color: this.state.companyIsSelected === campinfo.created_by && "white" }} onClick={() => {
                                            this.setState({ companyIsSelected: campinfo.created_by })
                                        }}>Selecteer</button>
                                    </div>)
                                })}
                            </div>
                            {(document.querySelector(".container-lrlgec") && document.querySelector(".container-lrlgec").scrollHeight > 520) && (<div className="see-more-lrlg" onClick={() => {
                                document.querySelector(".container-lrlgec").scrollTo(0, document.querySelector(".container-lrlgec").scrollTop + 100)
                            }}>
                                <p>toon meer</p>
                                <FiChevronDown />
                            </div>)}
                        </div>
                        <div className="bottom-buttons-clgec">
                            {this.state.aanmeldenloader ? (
                                <></>
                            ) : (<>
                                <button onClick={() => {
                                    if (this.state.companyIsSelected) {
                                        this.setState({ selectingRetailerScreen: false })
                                    } else {
                                        swal(
                                            "LET OP",
                                            "Please select a company",
                                            "warning"
                                        )
                                    }
                                }}>
                                    Volgende
                                    {/* Volgende stap{" "} */}
                                    {window.innerWidth < 1008 && <ChevronRightIcon />}
                                </button>
                                <div id="ideal-pay-btn" style={{ display: "none" }}>

                                </div>
                            </>)}
                            {this.state.aanmeldenloader && (
                                <div className="lds-ellipsis">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            )}
                            <div className="steps-container-main">
                                <div className="scm-inner-steps">

                                </div>
                                <div className="scm-inner-steps active">

                                </div>
                                <div className="scm-inner-steps">

                                </div>
                                <label>2/3</label>
                            </div>
                        </div>
                    </div>) : (<div className="container-lgec">
                        {/* {window.innerWidth > 1008 && <h2>BHODI</h2>}
                        {this.state.productsCheckingOut ? (<div className="flex-box-checkout-steps">
                            <div className="fbcs-inner">
                                <div className="green-fbcsinner">
                                    <BsCheck />
                                </div>
                                <label>Gegevens</label>
                            </div>
                            <div className="fbcs-inner">
                                <div className="green-fbcsinner">
                                    <BsCheck />
                                </div>
                                <label>Bezorging</label>
                            </div>
                            <div className="fbcs-inner">
                                <div className="green-fbcsinner">
                                </div>
                                <label>Afronden</label>
                            </div>
                        </div>) : (
                            <div className="flex-box-checkout-steps">
                                <div className="fbcs-inner">
                                    <div className="green-fbcsinner">
                                        <BsCheck />
                                    </div>
                                    <label>Aanmelden</label>
                                </div>
                                <div className="fbcs-inner">
                                    <div className="green-fbcsinner">
                                        <BsCheck />
                                    </div>
                                    <label>Gegevens</label>
                                </div>
                                <div className="fbcs-inner">
                                    <div className="green-fbcsinner">
                                        <BsCheck />
                                    </div>
                                    <label>Charity</label>
                                </div>
                            </div>
                            // <h4>{`Winkelmand > Gegevens > Charity`}</h4>
                        )}
                        <div className="info-popups-clgec">
                            {this.state.productsCheckingOut && (<div className="selecteer-tooltipdes" style={{ position: "relative", maxWidth: 420, paddingBottom: 20, marginBottom: 20, borderBottom: "1px solid #eeecec" }}>
                                <p
                                    className="text-clgec-charity texboldclged"
                                    style={{
                                        fontSize: 13,
                                        fontWeight: 500,
                                        color: "rgb(33, 37, 41)",
                                    }}
                                >
                                    Afleveradres
                                </p>
                            </div>)}
                            <div className="inner-ipclgec">
                                <p>Gegevens</p>
                                <p style={{ flexGrow: 1, paddingLeft: 15 }}>
                                    {this.state.street} {this.state.houseNumber},{" "}
                                    {this.state.zipCode}, {this.state.city}
                                </p>
                                <p
                                    style={{ color: "#C17F6B", cursor: "pointer" }}
                                    onClick={() => {
                                        history.push("/guest-event-cart");
                                    }}
                                >
                                    Wijzig
                                </p>
                            </div>
                        </div> */}
                        <div className="top-step-one-clgec">
                            <label dangerouslySetInnerHTML={this.createMarkup(this.state.showDutch ? (window.innerWidth > 1008 ? this.state.checkout_step_two_text_first : this.state.checkout_step_two_text_first_mobile) : (window.innerWidth > 1008 ? this.state.checkout_step_two_text_first_english : this.state.checkout_step_two_text_first_english_mobile))}>
                                {/* Aanmelden */}
                            </label>
                            <label dangerouslySetInnerHTML={this.createMarkup(this.state.showDutch ? (window.innerWidth > 1008 ? this.state.checkout_step_two_second_text : this.state.checkout_step_two_second_text_mobile) : (window.innerWidth > 1008 ? this.state.checkout_step_two_second_text_english : this.state.checkout_step_two_second_text_english_mobile))}>
                                {/* Account gegevens */}
                            </label>
                            {this.state.showProductsTextCheckout ? (<label style={{ maxWidth: 470 }} dangerouslySetInnerHTML={this.createMarkup(this.state.showDutch ? (window.innerWidth > 1008 ? this.state.checkout_step_two_top_products_text : this.state.checkout_step_two_top_products_text_mobile) : (window.innerWidth > 1008 ? this.state.checkout_step_two_top_products_text_english : this.state.checkout_step_two_top_products_text_english_mobile))}>
                                {/* Word charity member en krijg toegang tot<br />
                                exclusieve acties en gratis fashion & lifestyle items.
                                <br />
                                <br />
                                Als Charity Member ondersteun je kinderen en scholen in Tanzania<br />
                                met maaltijden en materialen. Van deze uitgifte ontvang je een<br />
                                persoonlijke en op naam ingesproken bedankvideo! */}
                            </label>) : (<label style={{ maxWidth: 470 }} dangerouslySetInnerHTML={this.createMarkup(this.state.showDutch ? (window.innerWidth > 1008 ? this.state.checkout_step_two_top_text : this.state.checkout_step_two_top_text_mobile) : (window.innerWidth > 1008 ? this.state.checkout_step_two_top_text_english : this.state.checkout_step_two_top_text_english_mobile))}>
                                {/* Als Charity Member ondersteun je kinderen en scholen in Tanzania
                                met maaltijden en materialen. Van deze uitgifte ontvang je een
                                persoonlijke en op naam ingesproken bedankvideo en krijg je
                                toegang tot alle exclusieve acties en events. */}
                            </label>)}
                        </div>
                        {!this.state.productsCheckingOut && (<div className="selecteer-tooltipdes" style={{ position: "relative" }}>
                            <p
                                className="text-clgec-charity texboldclged"
                                style={{
                                    fontSize: 13,
                                    fontWeight: 500,
                                    color: "rgb(33, 37, 41)",
                                }}
                            >
                                Selecteer je bijdrage
                            </p>
                            <span
                                className="tooltiptext tooltipinfo"
                                style={{ display: this.state.mustSelectPlanId ? "inline-flex" : "none" }}
                            >
                                Selecteer
                            </span>
                        </div>)}
                        {!this.state.productsCheckingOut && (<div className="radios-charity-lfss" style={{ marginTop: 20 }}>
                            {this.state.subscription_data.length > 0 &&
                                [...this.state.subscription_data].sort((a, b) => a.sort_level - b.sort_level).map((e) => {
                                    if (!this.state.showFourEuro && this.state.showProductsTextCheckout && e.price === "4.00") {
                                        return false;
                                    }
                                    if (!e.active) {
                                        return false;
                                    }
                                    return (
                                        <div className="inner-rclfss">
                                            <input
                                                type="radio"
                                                id="radiocharity"
                                                name="charity"
                                                value={e.plan_id}
                                                checked={e.plan_id === this.state.plan_id}
                                                onChange={() =>
                                                    this.setState({
                                                        plan_id: e.plan_id,
                                                        stripe_plan_key: e.stripe_plan_key,
                                                        plan_amount: e.price,
                                                        split_amount: e.split_amount,
                                                    })
                                                }
                                            />
                                            <span>€ {Number(e.price)}</span>
                                        </div>
                                    );
                                })}
                        </div>)}
                        {this.state.productsCheckingOut ? (<>
                            <p className="text-clgec-charity" style={{ maxWidth: 550 }}>
                                {this.state.deliveryNote}
                            </p>
                        </>) : (<>
                            {window.innerWidth < 1008 ? (<p className="text-clgec-charity" style={{ maxWidth: 550 }} dangerouslySetInnerHTML={this.createMarkup(this.state.showDutch ? (this.state.checkout_step_two_bottom_text_mobile) : (this.state.checkout_step_two_bottom_text_english_mobile))}>
                                {/* Jouw maandelijkse bijdrage aan Bhodi kun je heel eenvoudig&nbsp;
                                <br />
                                en op ieder gewenst moment zelf aanpassen of direct stopzetten. */}
                            </p>) : (<p className="text-clgec-charity" style={{ maxWidth: 350 }} dangerouslySetInnerHTML={this.createMarkup(this.state.showDutch ? (this.state.checkout_step_two_bottom_text) : (this.state.checkout_step_two_bottom_text_english))}>
                                {/* Jouw maandelijkse bijdrage aan Bhodi kun je heel eenvoudig
                                en op ieder gewenst moment zelf aanpassen of direct stopzetten. */}
                            </p>)}
                            {/* {window.innerWidth > 1008 ? (<p className="text-clgec-charity" style={{ maxWidth: 550 }}>
                                Alle aangesloten winkeliers en aanbieders stellen hun exclusieve&nbsp;
                                <br />
                                pop-up events en acties speciaal beschikbaar voor Charity Members.
                            </p>) : (<p className="text-clgec-charity" style={{ maxWidth: 350 }}>
                                De exclusieve pop-up events en acties zijn alleen beschikbaar voor Charity Members.
                            </p>)}
                            {window.innerWidth > 1008 ? (<p className="text-clgec-charity" style={{ maxWidth: window.innerWidth > 1008 ? 550 : "90%" }}>
                                Als Charity Member ondersteun je kinderen en scholen in Tanzania&nbsp;
                                <br />
                                met maaltijden en materialen. Van deze uitgifte ontvang je een&nbsp;
                                <br />
                                persoonlijke en op naam ingesproken bedankvideo.
                            </p>) : (<p className="text-clgec-charity" style={{ maxWidth: 335 }}>
                                Als Charity Member ondersteun je kinderen en scholen in Tanzania met maaltijden en materialen. Van deze uitgifte ontvang je een persoonlijke bedankvideo van ons.
                            </p>)}
                            {window.innerWidth > 1008 ? (<p className="text-clgec-charity" style={{ maxWidth: 550 }}>
                                Jouw maandelijkse bijdrage aan Bhodi kun je heel eenvoudig&nbsp;
                                <br />
                                en op ieder gewenst moment zelf aanpassen of stopzetten.
                            </p>) : <p className="text-clgec-charity" style={{ maxWidth: 550 }}>
                                Jouw maandelijkse bijdrage kun je op ieder gewenst moment aanpassen of stopzetten.
                            </p>} */}
                        </>)}
                        {this.state.productsCheckingOut && this.state.shipmentFeeAvailable && (<div className="payment-methods-checkout">
                            <p className="text-clgec-charity" style={{ maxWidth: 550 }}>
                                Payment Method
                            </p>
                            <div className="radio-icons-payment">
                                <div className="inner-rip-checkout">
                                    <input type="radio" name="payment-type" value="ideal" onClick={() => {
                                        this.setState({ paymentMethodSelected: "ideal" })
                                    }} />
                                    <label id="ideallabel">IDEAL</label>
                                </div>
                                <div className="inner-rip-checkout">
                                    <input type="radio" name="payment-type" value="paypal" onClick={() => {
                                        this.setState({ paymentMethodSelected: "paypal" })
                                    }} />
                                    <label id="paypallabel">PAYPAL</label>
                                </div>
                            </div>
                            <div id="ideal-fields-payment">

                            </div>
                        </div>)}
                        <div className="bottom-buttons-clgec">
                            {this.state.aanmeldenloader ? (
                                <></>
                            ) : (<>
                                <button onClick={this.saveSubscription.bind(this)} className="paypal-pay-btn" style={{ display: this.state.mustSelectRetailerFirst ? "inline-flex" : "none" }}>
                                    {this.state.productsCheckingOut ? (this.state.shipmentFeeAvailable ? `Afrekenen` : `Plaats bestelling`) : (`Afronden`)}
                                    {/* Volgende stap{" "} */}
                                    {window.innerWidth < 1008 && <ChevronRightIcon />}
                                </button>
                                <div id="ideal-pay-btn" style={{ display: "none" }}>

                                </div>
                            </>)}
                            {this.state.aanmeldenloader && (
                                <div className="lds-ellipsis">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            )}
                            <div className="steps-container-main">
                                <div className="scm-inner-steps">

                                </div>
                                {this.state.mustSelectRetailerFirst && (<div className="scm-inner-steps">

                                </div>)}
                                <div className="scm-inner-steps active">

                                </div>
                                {this.state.mustSelectRetailerFirst ? (<label>3/3</label>) : (<label>2/2</label>)}
                            </div>
                        </div>
                    </div>)}
                </div>
                {this.state.popupopenforright && (
                    <div className="right-gec">
                        {this.state.guestCartTime && (<TimerGec />)}
                        {this.state.currentDataOfUser && !localStorage.getItem("cookie_item") && window.innerWidth > 1008 && <CloseIcon style={{ position: "absolute", top: 10, right: 10, color: "white" }} onClick={() => {
                            if (this.state.currentDataOfUser.type === "company") {
                                history.push(`/company/${this.state.currentDataOfUser.user_name_id}`)
                            } else {
                                history.push(`/member/${this.state.currentDataOfUser.user_name_id}`)
                            }
                        }} />}
                        {this.state.guestUserCart.length > 0 && this.state.guestCartTime &&
                            this.state.guestUserCart.map((u, i) => {
                                if (u.campaignType === "local_event" && !u.hideFromListComplete) {
                                    return (
                                        <div className="event-info-rgec">
                                            {u.imagesObjected.length > 0 && (
                                                <div
                                                    className="image-eirgec"
                                                    style={{
                                                        backgroundImage: `url("${(window.innerWidth < 1008 && u.image_mobile) ? u.image_mobile : u.imagesObjected[0].image}")`,
                                                    }}
                                                >
                                                    <div className="count-ieirgec">
                                                        <label>1</label>
                                                    </div>
                                                </div>
                                            )}
                                            {u.isNotActive ? (
                                                <div className="content-eirgec">
                                                    <p className="title-vcvmg">
                                                        Sorry, you missed the event :(
                                                    </p>
                                                </div>
                                            ) : (
                                                <div className="content-eirgec">
                                                    <p className="title-vcvmg">{u.title}</p>
                                                    {(u.amount && u.created_by !== process.env.REACT_APP_ADMIN_ID) ? (<p
                                                        className="normal-price-vcvmg normalparaeventsglobal"
                                                        style={{ marginTop: 0, textTransform: "uppercase" }}
                                                    >
                                                        <LocationOnIcon /> {this.getSellerName(u)} |
                                                        KORTING:{" "}
                                                        {u.localEventType === "percentage"
                                                            ? `${u.amount}%`
                                                            : `€${u.amount}`}
                                                    </p>) : (<p
                                                        className="normal-price-vcvmg normalparaeventsglobal"
                                                        style={{ marginTop: 0, textTransform: "uppercase" }}
                                                    >
                                                        <LocationOnIcon /> {this.getSellerName(u)}
                                                    </p>)}
                                                    {u.timings.length > 0 && (
                                                        <p
                                                            className="normal-price-vcvmg normalparaeventsglobal"
                                                            style={{ marginTop: 0 }}
                                                        >
                                                            <RiTimerLine />{" "}
                                                            {this.showDateToUserTiming(
                                                                u.timings[u.reserved_event_id ? Number(u.reserved_event_id) - 1 : 0].startDateEvent,
                                                                u.timings[u.reserved_event_id ? Number(u.reserved_event_id) - 1 : 0].endDateEvent
                                                            )}{" "}
                                                            {(!u.takeFromAdmin && u.created_by !== process.env.REACT_APP_ADMIN_ID && u.timings[u.reserved_event_id ? Number(u.reserved_event_id) - 1 : 0].startTimeEvent && u.timings[u.reserved_event_id ? Number(u.reserved_event_id) - 1 : 0].endTimeEvent) && `${u.timings[u.reserved_event_id ? Number(u.reserved_event_id) - 1 : 0].startTimeEvent} - ${u.timings[u.reserved_event_id ? Number(u.reserved_event_id) - 1 : 0].endTimeEvent} uur`}
                                                        </p>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    );
                                } else if (u.campaignType === "kortings_voucher" && !u.hideFromListComplete) {
                                    return (
                                        <div className="event-info-rgec">
                                            {u.imagesObjected.length > 0 && (
                                                <div
                                                    className="image-eirgec"
                                                    style={{
                                                        backgroundImage: `url("${(window.innerWidth < 1008 && u.image_mobile) ? u.image_mobile : u.imagesObjected[0].image}")`,
                                                    }}
                                                >
                                                    <div className="count-ieirgec">
                                                        <label>1</label>
                                                    </div>
                                                </div>
                                            )}
                                            {u.isNotActive ? (
                                                <div className="content-eirgec">
                                                    <p className="title-vcvmg">
                                                        Sorry, you missed the event :(
                                                    </p>
                                                </div>
                                            ) : (
                                                <div className="content-eirgec">
                                                    <p className="title-vcvmg">{u.title}</p>
                                                    <p
                                                        className="normal-price-vcvmg normalparaeventsglobal"
                                                        style={{ marginTop: 0, textTransform: "uppercase" }}
                                                    >
                                                        <LocationOnIcon /> {this.getSellerName(u)} |
                                                        KORTING:{" "}
                                                        {u.kortingsVoucherGiveaway ? `Free` : u.kortingsVoucherPrijs ? `€${u.amount}` : u.kortingsVoucherPercentage ? `${u.amount}%` : !u.isoTimeTimerBtnEndTime ? `€${u.kortingsVoucherSalePrice}` : (!u.kortingsVoucherPrijs && (new Date(u.isoTimeTimerBtnEndTime).getTime() > new Date().getTime())) ? `€${u.kortingsVoucherSalePrice}` : `€${u.kortingsVoucherNormalPrice}`}
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    );
                                } else if (u.campaignType === "web_shop" && !u.hideFromListComplete) {
                                    return (
                                        <div className="event-info-rgec">
                                            {u.imagesObjected.length > 0 && (
                                                <div
                                                    className="image-eirgec"
                                                    style={{
                                                        backgroundImage: `url("${(window.innerWidth < 1008 && u.image_mobile) ? u.image_mobile : u.imagesObjected[0].image}")`,
                                                    }}
                                                >
                                                    <div className="count-ieirgec">
                                                        <label>1</label>
                                                    </div>
                                                </div>
                                            )}
                                            {u.isNotActive ? (
                                                <div className="content-eirgec">
                                                    <p className="title-vcvmg">
                                                        Sorry, you missed the campaign :(
                                                    </p>
                                                </div>
                                            ) : (
                                                <div className="content-eirgec">
                                                    <p className="title-vcvmg">{u.title}</p>
                                                    <p
                                                        className="normal-price-vcvmg normalparaeventsglobal"
                                                        style={{ marginTop: 0, textTransform: "uppercase" }}
                                                    >
                                                        <LocationOnIcon /> {this.getSellerName(u)} |
                                                        KORTING:{" "}
                                                        {!u.voucherIsPercentage ? `€${u.amount}` : `${u.amount}%`}
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    );
                                }
                                if (u.item_type === "bhodi_product") {
                                    let indexOfImage = 0
                                    if (u.images && u.images.length > 0) {
                                        if (u.featuredImage && u.featuredImage < u.images.length) {
                                            indexOfImage = u.featuredImage
                                        }

                                        let findIndex = u.images.findIndex((t) => {
                                            return t.includes("_1")
                                        })
                                        if (findIndex !== -1) {
                                            indexOfImage = findIndex
                                        }
                                    }
                                    return (
                                        <div className="event-info-rgec">
                                            {u.images && u.images.length > 0 && (
                                                <div
                                                    className="image-eirgec"
                                                    style={{
                                                        backgroundImage: `url("${u.images[indexOfImage]}")`,
                                                    }}
                                                >
                                                    <div className="count-ieirgec">
                                                        <label>1</label>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="content-eirgec">
                                                <p className="title-vcvmg">{u.title}</p>
                                                <p
                                                    className="normal-price-vcvmg normalparaeventsglobal"
                                                    style={{
                                                        marginTop: 0,
                                                        textTransform: "uppercase",
                                                    }}
                                                >
                                                    <LocationOnIcon /> {this.getSellerName(u)}
                                                    <s>€ {u.price}</s>&nbsp;FREE ITEM
                                                </p>
                                            </div>
                                        </div>
                                    );
                                }
                            })}
                    </div>
                )}
                {window.innerWidth < 1009 && (
                    <div className="showtoopen-gec stg-updated-checkout">
                        {/* <h2>BHODI</h2> */}
                        <div className="popup-opener-stogec">
                            <div className="button-back-lgec" onClick={() => {
                                if (this.state.mustSelectRetailerFirst && !this.state.selectingRetailerScreen) {
                                    this.setState({ selectingRetailerScreen: true })
                                    return false;
                                }
                                let moveBackTwo = localStorage.getItem("moveback-two")
                                if (moveBackTwo) {
                                    localStorage.removeItem("moveback-two")
                                    history.go(-2)
                                } else {
                                    history.goBack()
                                }
                            }}>
                                <ChevronLeftIcon />
                            </div>
                            <div className="postogec-inner">
                                <RiShoppingBasketLine className="basket-postog" />
                                <label
                                    onClick={() =>
                                        this.setState({
                                            popupopenforright: !this.state.popupopenforright,
                                        })
                                    }
                                >
                                    Bekijk je items{" "}
                                    {this.state.popupopenforright ? (
                                        <FiChevronUp />
                                    ) : (
                                        <FiChevronDown />
                                    )}{" "}
                                </label>
                            </div>
                        </div>
                    </div>
                )}
                {/* <FooterBasket notOnMainPage={true} />
                <Footer notOnMainPage={true} /> */}
                <FooterBasket notOnMainPage={true} />
                <FooterBasketTimer notOnMainPage={true} />
                {/* {this.state.showFooterBasket ? (<FooterBasket notOnMainPage={true} />) : (<CartNonUser notOnMainPage={true} />)} */}
                {window.innerWidth > 1008 && <Footer notOnMainPage={true} />}
                {window.innerWidth < 1008 && <FooterGreen notOnMainPage={true} />}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        subscription_plans: state.globalReducers.subscription_plans,
        user: state.authReducers.user,
        guest_user_cart: state.cartReducer.guest_user_cart,
        user: state.authReducers.user,
        global_campaigns: state.campaignsReducer.global_campaigns,
        signup_fields_info: state.globalReducers.signup_fields_info,
        admin_boolean: state.globalReducers.admin_boolean,
        // products_new: state.campaignsReducer.products_new,
        global_values: state.globalReducers.global_values,
        guest_user_cart_time: state.cartReducer.guest_user_cart_time,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateSubscriptionPlans: (subscription_plans) =>
            dispatch(updateSubscriptionPlans(subscription_plans)),
        updateGuestUserCart: (guest_user_cart) =>
            dispatch(updateGuestUserCart(guest_user_cart)),
        updateGuestUserCartTime: (guest_user_cart_time) =>
            dispatch(updateGuestUserCartTime(guest_user_cart_time)),
        updateSignUpFieldsFilled: (signup_fields_info) =>
            dispatch(updateSignUpFieldsFilled(signup_fields_info)),
        updateAdminOrNot: (admin_boolean) =>
            dispatch(updateAdminOrNot(admin_boolean)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GuestEventCartStepTwo);


