import React, { Component } from 'react'
import FooterGreen from '../../Components/FooterGreen'
import "./index.css"
import VoucherEventsNewGlobal from '../../Components/VoucherEventsNewGlobal'

export class VouchersNewMobile extends Component {

    constructor() {
        super()
        this.state = {
            latestProducts: [],
            otherProducts: [],
            allProducts: []
        }
    }

    componentDidMount() {
        let scrollOnGlobal = localStorage.getItem("scrollOnGlobal")
        if (scrollOnGlobal) {
            window.scrollTo(0, Number(scrollOnGlobal))
            localStorage.removeItem("scrollOnGlobal")
        }

        document.getElementById("root").style.position = "static";
        document.getElementById("root").style.overflow = "auto";
    }

    render() {
        return (
            <div style={{ marginBottom: 70 }} id="divtoscrollfromtop">
                <VoucherEventsNewGlobal
                    allCampaigns={this.props.productsWhole}
                    onEditOpen={() => {
                        document.querySelector("html").style.overflowY = "hidden"
                        this.setState({
                            openAddPopup: true,
                            doEditVoucher: true,
                        });
                    }}
                    shouldAlwaysUpdate={this.props.shouldAlwaysUpdate}
                />
                <FooterGreen notOnMainPage={true} />
            </div>
        )
    }
}

export default VouchersNewMobile