import React, { Component } from "react";
// import './index.css'
import logowhite from "../assets/logowhite.png";
import firebase from "../Config/Firebase";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  updateUser,
  updateAllCharityVideos,
  updateImages,
  updateAllSectors,
  updateAllCampaigns,
  updateImpressies,
  updateSubscriptionPlans,
  updateMessage,
  updateTitle,
  updateGlobalStuff,
  voucherSectorCategories,
  updateBhodiProducts,
  productSectorCategories,
  updateStockImages,
  updateBhodiBrands,
} from "../Redux/actions/authActions";
import homeSvg from "../assets/svgs/HomeBlack.svg"
import "./index.css";
import { mergeAdminEvent } from "../Functions/globalFunctions";

export class FooterGreen extends Component {
  constructor() {
    super();
    this.state = {
      black: false,
      popupright: false,
      popupitems: false,
      popupmarket: false,
      popupservices: false,
      popupShop: false,
      cartItems: [],
      arrItemsOfCart: [],
      currentUserHere: "",
      allSellers: [],
      currentUserId: "",
      allSectors: [],
      availableMainSector: [],
      allSectors: [],
      allVouchers: [],
      endDate: "",
      allCampaigns: [],
      categoriesToShow: [],
      allCampaignsToFilter: [],
      availableMainSectorVoucherEvents: [],
      guestUserCart: [],
      allCampaignsToFilterEvents: [],
      onProfilePage: false,
      availableMainSectorProductsNew: [],
      allProductsToFilter: [],
      path: null,
      availableMainSectorProducts: [],
      allProductsAvailableToFilter: [],
    };
  }

  componentDidMount() {
    // window.addEventListener("popstate", () => {
    //   window.location.reload();
    // });
    this.setState({ path: null });
    const loaderId = document.getElementById("main-page-loader-id");
    loaderId.style.display = "none";

    let userid = localStorage.getItem("userid");
    this.setState({ currentUserId: userid });

    if (this.props.global_values) {
      let a = this.props.global_values;
      if (a) {
        if (a.showGlobalTimer) {
          this.setState({ showEventsOnEmpty: true })
        } else {
          this.setState({ showEventsOnEmpty: false })
        }
      }
    }

    firebase.database().ref("bhodi_home").on("value", (data) => {
      let a = data.val()
      if (a) {
        if (a.showGlobalTimer) {
          let dateTimer = new Date(Number(a.globalTimerDate.split("-")[0]), Number(a.globalTimerDate.split("-")[1]) - 1, Number(a.globalTimerDate.split("-")[2]), Number(a.globalTimerTime.split(":")[0]), Number(a.globalTimerTime.split(":")[1]), 0).getTime()
          let dateYet = new Date().getTime()
          if (dateYet < dateTimer) {
            this.setState({ showEventsOnEmpty: true })
          }
        } else {
          this.setState({ showEventsOnEmpty: false })
        }
        this.props.updateGlobalStuff(a)
      }
    })


    firebase
      .database()
      .ref("stock_images/")
      .on("value", (data) => {
        let a = data.val();
        if (a) {
          let objected = Object.entries(a);
          this.props.updateStockImages(objected);
          this.setState({ allImagesStock: objected });
        } else {
          this.setState({ allImagesStock: [] });
        }
      });

    firebase
      .database()
      .ref("subscription_plans")
      .once("value", (data) => {
        let a = data.val();
        if (a) {
          let arr = [];
          let objected = Object.entries(a);
          objected.map((y) => {
            arr.push(y[1]);
          });
          this.props.updateSubscriptionPlans(arr);
        }
      });

    firebase
      .database()
      .ref("bhodi_brands")
      .once("value", (data) => {
        let a = data.val();
        if (a) {
          let arr = [];
          let objected = Object.entries(a);
          objected.map((y) => {
            arr.push(y[1]);
          });
          this.props.updateBhodiBrands(arr);
        }
      });

    firebase
      .database()
      .ref("charity_videos")
      .once("value", (data) => {
        let a = data.val();
        if (a) {
          this.props.updateAllCharityVideos(a);
        }
      });

    if (userid) {
      firebase
        .database()
        .ref("allMessages/" + userid)
        .on("value", (data) => {
          let a = data.val();
          let arr = [];
          if (a) {
            let objected = Object.entries(a);
            arr = objected;
            this.props.updateMessage(arr);
          } else {
            this.props.updateMessage(arr);
          }
        });
    } else {
      let cookieItem = localStorage.getItem("cookie_item");
      if (!cookieItem) {
        let uniqueKey = firebase.database().ref("guest_carts").push().key
        localStorage.setItem("cookie_item", uniqueKey);
      }
    }


    firebase
      .database()
      .ref("bhodi_categories_vouchers")
      .on("value", (data) => {
        let a = data.val();
        this.props.voucherSectorCategories(a);
        this.setState({ allCategories: a });
      });

    firebase
      .database()
      .ref("bhodi_categories_products")
      .on("value", (data) => {
        let a = data.val();
        this.props.productSectorCategories(a);
        this.setState({ allCategories: a });
      });

    if (this.props.global_campaigns) {
      if (this.props.global_campaigns.length > 0) {
        // this.interval = setInterval(
        //   () => this.setState({ timeCurrent: Date.now() }),
        //   1000
        // );
        let arrSecCheck = [];
        this.props.global_campaigns.map((i) => {
          arrSecCheck.push(i[1]);
        });
        let arrCompleteEvents = [];
        let arrComplete = [];
        arrSecCheck.map((g) => {
          if (this.props.user) {
            let filtered = this.props.user.filter((t) => {
              return t.user_id === g.created_by
            })
            if (userid) {
              let filteredHere = this.props.user.filter((y) => {
                return y.user_id === userid;
              });
              if (filtered[0].blocked) {
                let filteredInner = Object.entries(filtered[0].blocked).filter((t) => {
                  return t[1].user_id === userid
                })
                if (filteredInner.length > 0) {
                  return false;
                }
              }
              if (filteredHere.length > 0 && filteredHere[0].blocked) {
                let filteredInner = Object.entries(filteredHere[0].blocked).filter((t) => {
                  return t[1].user_id === filtered[0].user_id
                })
                if (filteredInner.length > 0) {
                  return false;
                }
              }
            }
            if (filtered.length > 0 && !filtered[0].profileActive) {
              return false;
            }
          }
          if (g.campaignType === "local_event") {
            if (g.takeFromVendor) {
              return false;
            }
            if (g.takeFromAdmin) {
              let obj = mergeAdminEvent(g, this.props.global_campaigns)
              g = Object.assign({}, obj)
            }
            if (g.localEventAccessible) {
              if (!g.localEventShowOnGlobal) {
                return false;
              }
              let allCampaigns = this.props.global_campaigns.filter((e) => {
                let timestampCreated = Math.floor(Number(g.created_at) / 1000);
                let stringified = timestampCreated.toString().substr(3, 10);
                return (
                  e[1].vendor_id === g.created_by &&
                  e[1].campaignType === "local_event" &&
                  e[1].joinedCode === stringified &&
                  e[1].active
                );
              });
              if (allCampaigns.length > 0) {
                allCampaigns.map((e) => {
                  let qtyToMinus = 0;
                  if (e[1].inCartOf) {
                    let objected = Object.entries(e[1].inCartOf);
                    qtyToMinus = qtyToMinus + objected.length;
                  }
                  if (e[1].checked_in_by) {
                    let objected = Object.entries(e[1].checked_in_by);
                    qtyToMinus = qtyToMinus + objected.length;
                  }
                  if (e[1].extraStockPersons) {
                    let stockAvailable =
                      Number(e[1].extraStockPersons) - qtyToMinus;
                    e[1].maxStockAvailable = stockAvailable;
                  }
                });
                let filteredMain = allCampaigns.filter((y) => {
                  return y[1].maxStockAvailable > 0;
                });
                if (filteredMain.length === 0) {
                  return false;
                }
              } else {
                return false;
              }
            }
          }
          if (g.campaignType === "live_event") {
            if (g.takeFromVendor) {
              return false;
            }
          }
          if (
            g.campaignType === "local_event"
          ) {
            if (g.takeFromAdmin) {
              let obj = mergeAdminEvent(g, this.props.global_campaigns)
              g = Object.assign({}, obj)
            }
            let arrTimingsAvailable = [];
            if (g.timings) {
              g.timings.map((e) => {
                if (e.isoStartTime && e.isoEndTime) {
                  let checkedInOfCurrentBlock = [];
                  if (g.checked_in_by) {
                    Object.entries(g.checked_in_by).map((t) => {
                      if (t[1].event_id === e.eventId) {
                        checkedInOfCurrentBlock.push(t[1]);
                      }
                    });
                  }
                  // let getEventDate = new Date(`${e.endDateEvent} ${e.endTimeEvent}`)
                  // let getEventDate = new Date(e.startDateEvent);
                  // let endTimeGood = e.startTimeEvent.concat(":00");
                  // getEventDate.setHours(endTimeGood.split(":")[0]);
                  // getEventDate.setMinutes(endTimeGood.split(":")[1]);
                  let getCurrentDate = new Date();
                  if (getCurrentDate.getTime() < new Date(Date.parse(e.isoEndTime)).getTime()) {
                    if (
                      checkedInOfCurrentBlock.length <
                      Number(e.personsAmountEvent)
                    ) {
                      arrTimingsAvailable.push(e);
                    }
                  }
                }
              });
            }
            if (arrTimingsAvailable.length > 0) {
              if (g.active) {
                arrCompleteEvents.push(g);
              }
            }
          }
        });

        let filteredMainSectorEvents = arrCompleteEvents.filter(
          (v, i, a) => a.findIndex((t) => t.main_sector === v.main_sector) === i
        );
        let availableMainSectorVoucherEvents = [];
        let availableMainSectorVoucherEventsAll = [];
        filteredMainSectorEvents.map((g) => {
          if (
            g.campaignType === "last_minute" ||
            g.campaignType === "local_event"
          ) {
            if (g.active) {
              availableMainSectorVoucherEvents.push(g.main_sector);
            }
          }
        });
        arrCompleteEvents.map((g) => {
          if (
            g.campaignType === "local_event"
          ) {
            if (g.active) {
              availableMainSectorVoucherEventsAll.push(g.main_sector);
            }
          }
        });
        availableMainSectorVoucherEvents =
          availableMainSectorVoucherEventsAll.filter(
            (v, i, a) => a.findIndex((t) => t === v) === i
          );
        this.setState({
          availableMainSectorVoucherEvents,
          allCampaigns: arrComplete,
        });

        this.setState({ allCampaigns: arrComplete });
      }
    }

    if (this.props.user) {
      if (this.props.user.length > 0) {
        let filtered = this.props.user.filter((t) => {
          return t.user_id === userid;
        });
        if (filtered.length > 0) {
          let currentUser = filtered[0];
          if (currentUser) {
            let followingLength = 0;
            if (currentUser.following) {
              followingLength = Object.entries(currentUser.following).length;
            }
            if (currentUser) {
              currentUser.followingLength = followingLength;

              this.setState({ currentUserHere: currentUser });
            }
          }
        }
      }
    }

    if (this.props.globalsectors) {
      this.setState({ allSectors: this.props.globalsectors });
    }

    firebase
      .database()
      .ref("impressiePhotos")
      .on("value", (data) => {
        let a = data.val();
        let arr = [];
        if (a && !this.state.stopTakingData) {
          let objected = Object.entries(a);
          arr = objected;
          this.props.updateImpressies(arr);
        } else {
          this.props.updateImpressies([]);
        }
      });
    firebase
      .database()
      .ref("all_sectors")
      .on("value", (data) => {
        let a = data.val();
        let arr = [];
        if (a) {
          let objected = Object.entries(a);
          objected.map((h) => {
            arr.push(h[1]);
          });
          this.props.updateAllSectors(arr);
          this.setState({ allSectors: arr });
        } else {
          this.setState({ allSectors: [] });
        }
      });
    firebase
      .database()
      .ref("users")
      .on("value", (data) => {
        let a = data.val();
        let arrUsers = [];
        let currentUser = "";
        if (a) {
          let entries = Object.entries(a);
          entries.map((f) => {
            // arrUsers.push(f[1])
            // f[1].pass = ""
            // f[1].password = ""
            if (f[1].user_id === userid) {
              currentUser = f[1];
            }
          });
          let followingLength = 0;
          if (currentUser.following) {
            followingLength = Object.entries(currentUser.following).length;
          }
          if (currentUser) {
            currentUser.followingLength = followingLength;
            // localStorage.setItem("userdata", JSON.stringify(currentUser))
            this.setState({ currentUserHere: currentUser });
          }
          entries.map((f) => {
            if (f && f.length > 0 && f[1]) {
              f[1].pass = "";
              f[1].password = "";
              arrUsers.push(f[1]);
            }
          });
          if (currentUser && currentUser.order_loaded && !window.location.href.includes("/charityConfirmation")) {
            firebase.database().ref("users/" + currentUser.user_id + "/create_subscription").set(false)
            firebase.database().ref("users/" + currentUser.user_id + "/order_loaded").remove()

            this.props.updateSignUpFieldsFilled(
              ""
            );
            this.props.updateGuestUserCartExtendOpportunity(
              ""
            );
            this.props.updateGuestUserCartTime(
              ""
            );
            this.props.updateGuestUserCart(
              []
            );

            localStorage.removeItem(
              "cookie_item"
            );
          }
          this.props.updateUser(arrUsers);
        }
      });

    firebase
      .database()
      .ref("bhodi_products")
      .once("value", (data) => {
        let a = data.val();
        if (a) {
          let arrayMain = []
          let objected = Object.entries(a);
          objected.map((h) => {
            arrayMain.push(h[1])
          });

          if (arrayMain.length > 0) {
            this.props.updateBhodiProducts(arrayMain);
          } else {
            this.props.updateBhodiProducts([]);
          }
        } else {
          this.props.updateBhodiProducts([]);
        }
      });
    // bhodi products

    firebase
      .database()
      .ref("bhodi_campaigns")
      .on("value", (data) => {
        let a = data.val();
        let arr = [];
        if (a) {
          let objected = Object.entries(a);
          let arrSecCheck = [];
          objected.map((i) => {
            arrSecCheck.push(i[1]);
          });
          arrSecCheck.map((y) => {
            let objected = Object.entries(y);
            objected.map((i) => {
              arr.push(i);
            });
          });
          let arrComplete = [];
          let arrCompleteEvents = [];
          arr.map((g) => {
            if (!g[1].created_by) {
              return false;
            }
            if (this.props.user) {
              let filtered = this.props.user.filter((t) => {
                return t.user_id === g[1].created_by
              })
              if (userid) {
                let filteredHere = this.props.user.filter((y) => {
                  return y.user_id === userid;
                });
                if (filtered.length > 0) {
                  if (filtered[0].blocked) {
                    let filteredInner = Object.entries(filtered[0].blocked).filter((t) => {
                      return t[1].user_id === userid
                    })
                    if (filteredInner.length > 0) {
                      return false;
                    }
                  }
                }
                if (filteredHere.length > 0 && filteredHere[0].blocked) {
                  let filteredInner = Object.entries(filteredHere[0].blocked).filter((t) => {
                    return t[1].user_id === filtered[0].user_id
                  })
                  if (filteredInner.length > 0) {
                    return false;
                  }
                }
              }
              if (filtered.length > 0 && !filtered[0].profileActive) {
                return false;
              }
            }
            if (
              g[1].campaignType === "last_minute" ||
              g[1].campaignType === "local_event"
            ) {
              let arrTimingsAvailable = [];
              if (g[1].timings) {
                g[1].timings.map((e) => {
                  if (e.isoStartTime && e.isoEndTime) {
                    let checkedInOfCurrentBlock = [];
                    if (g[1].checked_in_by) {
                      Object.entries(g[1].checked_in_by).map((t) => {
                        if (t[1].event_id === e.eventId) {
                          checkedInOfCurrentBlock.push(t[1]);
                        }
                      });
                    }

                    let getCurrentDate = new Date();
                    if (getCurrentDate.getTime() < new Date(Date.parse(e.isoStartTime)).getTime()) {
                      if (
                        checkedInOfCurrentBlock.length <
                        Number(e.personsAmountEvent)
                      ) {
                        arrTimingsAvailable.push(e);
                      }
                    }
                  }
                });
              }
              if (arrTimingsAvailable.length > 0) {
                arrCompleteEvents.push(g[1]);
              }
            }
            if (g[1].campaignType === "live_event") {
              if (!g[1].liveEventisEnded && !g[1].eventStoppedForEdit) {
                arrCompleteEvents.push(g[1]);
              }
            }
          });
          let filteredMainSectorEvents = arrCompleteEvents.filter(
            (v, i, a) =>
              a.findIndex((t) => t.main_sector === v.main_sector) === i
          );
          let availableMainSectorVoucherEvents = [];
          let availableMainSectorVoucherEventsAll = [];

          filteredMainSectorEvents.map((g) => {
            if (
              g.campaignType === "local_event"
            ) {
              if (g.active) {
                availableMainSectorVoucherEvents.push(g.main_sector);
              }
            }
          });
          arrCompleteEvents.map((g) => {
            if (
              g.campaignType === "local_event"
            ) {
              if (g.active) {
                availableMainSectorVoucherEventsAll.push(g.main_sector);
              }
            }
            // if (g.campaignType === "live_event") {
            //     if (this.props.user) {
            //         let filtered = this.props.user.filter((t) => {
            //             return t.user_id === g.created_by
            //         })
            //         if (filtered.length > 0) {
            //             if (filtered[0].paypalInfo && !g.liveEventisEnded && !g.eventStoppedForEdit) {
            //                 availableMainSectorVoucherEventsAll.push(g.main_sector)
            //             }
            //         }
            //     }
            // }
          });
          availableMainSectorVoucherEvents =
            availableMainSectorVoucherEventsAll.filter(
              (v, i, a) => a.findIndex((t) => t === v) === i
            );
          this.setState({
            availableMainSectorVoucherEvents,
            allCampaigns: arrComplete,
          });

          this.setState({
            allCampaignsToFilter: arrComplete,
            allCampaignsToFilterEvents: arrCompleteEvents,
          });

          this.props.updateAllCampaigns(arr);
        } else {
          this.setState({
            allCampaignsToFilter: [],
            allCampaignsToFilterEvents: [],
          });
          this.props.updateAllCampaigns([]);
        }
      });
  }

  componentDidUpdate() {
    // if (this.state.path !== null) {
    //   window.location.reload();
    // }
  }

  getVerzilveredQty(e) {
    let arrItems = e;
    if (arrItems.length > 0) {
      Array.prototype.sum = function (prop) {
        var total = 0;
        for (var i = 0, _len = this.length; i < _len; i++) {
          total += Number(this[i][1][prop].length);
        }
        return total;
      };
      return arrItems.sum("allDates");
    }
  }


  render() {
    return (
      !this.props.notOnMainPage && (
        <div>
          <div
            className="footer-main footergreen"
            id="footermain"
            style={{
              left: 0,
              position: this.props.sticky && "static",
              backgroundColor: "#295150",
              opacity: !this.state.currentUserId && 0,
              pointerEvents: !this.state.currentUserId && "none",
            }}
          >
            <div className="notificationfooter" id="gebruiknotification">
              <p>BEWAARDE VOUCHER IS OPGESLAGEN</p>
            </div>
            <div className="notificationfooter" id="addbookmarkednotification">
              <p>VOUCHER IS OPGESLAGEN ONDER FAVORIETEN</p>
            </div>
            <div className="row ">
              {!this.props.hideLogo ? (
                this.props.white ? (
                  <div className="col-md-2 text-left">
                    <img
                      src={logowhite}
                      className="img-responsive footerlogo hidden-xs"
                    />
                  </div>
                ) : (
                  <div className="col-md-2 text-left"></div>
                )
              ) : !this.props.hideLogo ? (
                <div className="col-md-2 text-left">
                  <img
                    src={logowhite}
                    className="img-responsive footerlogo hidden-xs"
                  />
                </div>
              ) : (
                <div className="col-md-2 text-left"></div>
              )}
              <div className="col-md-4 offset-md-2 text-center ">
                {this.state.currentUserId && (
                  <ul className="social-network social-circle">
                    {this.state.currentUserHere.type === "company" ? (
                      <Link
                        className="link-style"
                        to={`/company/${this.state.currentUserHere.user_name_id}`}
                        onClick={() => {
                          localStorage.removeItem("onCurrentTab");
                          localStorage.removeItem("onCurrentTabCompany");
                          localStorage.removeItem("onStoriesActive");
                          this.props.updateTitle(true);
                        }}
                      >
                        <li
                          className={`${this.state.onProfilePage && "active"}`}
                        // setTimeout(() => {
                        //   window.location.href = `/company/${this.state.currentUserHere.user_name_id}`;
                        // }, 1000);
                        >
                          <a href="javascript:void(0)" className="socialIcon">
                            <img src={homeSvg} alt="homeIcon" />
                            {/* <RiHome4Line /> */}
                            {/* <span className="d-block d-sm-none ftext">Profiel</span>
                                        <span className="tooltiptext">Profielpagina</span> */}
                          </a>
                        </li>
                      </Link>
                    ) : (
                      <Link
                        className="link-style"
                        to={`/member/${this.state.currentUserHere.user_name_id}`}
                        onClick={() => {
                          localStorage.removeItem("onCurrentTab");
                          localStorage.removeItem("onCurrentTabCompany");
                          localStorage.removeItem("onStoriesActive");
                          this.props.updateTitle(true);
                        }}
                      >
                        <li
                        >
                          <a href="javascript:void(0)" className="socialIcon">
                            <img src={homeSvg} alt="homeIcon" />
                          </a>
                        </li>
                      </Link>
                    )}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      )
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducers.user,
    product: state.campaignsReducer.product,
    images: state.postsReducers.images,
    allVouchers: state.campaignsReducer.allVouchers,
    globalsectors: state.categoriesReducer.globalsectors,
    global_campaigns: state.campaignsReducer.global_campaigns,
    products_new: state.campaignsReducer.products_new,
    global_values: state.globalReducers.global_values,
    bhodi_products: state.productsReducers.bhodi_products,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateTitle: (user) => dispatch(updateTitle(user)),
    updateUser: (user) => dispatch(updateUser(user)),
    updateAllSectors: (globalsectors) =>
      dispatch(updateAllSectors(globalsectors)),
    updateAllCampaigns: (global_campaigns) =>
      dispatch(updateAllCampaigns(global_campaigns)),
    updateImpressies: (all_impressies) =>
      dispatch(updateImpressies(all_impressies)),
    updateImages: (images) => dispatch(updateImages(images)),
    updateSubscriptionPlans: (subscription_plans) =>
      dispatch(updateSubscriptionPlans(subscription_plans)),
    updateAllCharityVideos: (charity_videos) =>
      dispatch(updateAllCharityVideos(charity_videos)),
    updateMessage: (message) => dispatch(updateMessage(message)),
    updateGlobalStuff: (global_values) => dispatch(updateGlobalStuff(global_values)),
    updateBhodiBrands: (bhodi_brands) => dispatch(updateBhodiBrands(bhodi_brands)),
    voucherSectorCategories: (vouchersectors) =>
      dispatch(voucherSectorCategories(vouchersectors)),
    updateBhodiProducts: (bhodi_products) => dispatch(updateBhodiProducts(bhodi_products)),
    productSectorCategories: (product_categories) => dispatch(productSectorCategories(product_categories)),
    updateStockImages: (stock_images) =>
      dispatch(updateStockImages(stock_images)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FooterGreen);
