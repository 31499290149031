import React, { Component } from "react";

export class TimerOrders extends Component {
    componentDidMount() {
        const loaderId = document.getElementById("main-page-loader-id");
        loaderId.style.display = "none";
        this.interval = setInterval(
            () => this.setState({ timeCurrent: Date.now() }),
            1000
        );
    }

    calculateTimeStamp() {
        var date = new Date(this.props.timeToCalculate);
        if (this.props.isoEndTime) {
            date = new Date(this.props.isoEndTime)
        } else if (this.props.productToCheck) {
            if (this.props.productToCheck.products[0].expireTypeVoucher && this.props.productToCheck.products[0].expireDateVoucher) {
                let operation = this.props.productToCheck.products[0].expireTypeVoucher
                let unitsToAdd = Number(this.props.productToCheck.products[0].expireDateVoucher)
                if (operation === 'daily') {
                    date.setDate(date.getDate() + unitsToAdd);
                } else if (operation === 'weekly') {
                    date.setDate(date.getDate() + (unitsToAdd * 7));
                } else if (operation === 'monthly') {
                    date.setMonth(date.getMonth() + unitsToAdd);
                }
            } else {
                // date.setTime(
                //     date.getTime() + 7 * 60000
                // );
                date.setDate(date.getDate() + 7);
            }
        } else if (!this.props.alreadyDateSetted) {
            // date.setTime(
            //     date.getTime() + 7 * 60000
            // );
            date.setDate(date.getDate() + 7);
        }

        let dateFuture = date.getTime();

        let dateNow = new Date().getTime();

        let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

        let days = Math.floor(diffInMilliSeconds / 86400);
        diffInMilliSeconds -= days * 86400;

        let hours = Math.floor(diffInMilliSeconds / 3600) % 24;
        diffInMilliSeconds -= hours * 3600;

        let minutes = Math.floor(diffInMilliSeconds / 60) % 60;
        diffInMilliSeconds -= minutes * 60;

        let seconds = diffInMilliSeconds % 60;

        if (days < 10) {
            days = `0${days}`;
        } else {
            days = `${days}`;
        }

        if (hours < 10) {
            hours = `0${hours}`;
        } else {
            hours = `${hours}`;
        }

        if (minutes < 10) {
            minutes = `0${minutes}`;
        } else {
            minutes = `${minutes}`;
        }

        if (seconds < 10) {
            seconds = `0${Math.ceil(seconds)}`;
        } else {
            seconds = `${Math.ceil(seconds)}`;
        }

        if (dateFuture > dateNow) {
            if (Number(days) > 0) {
                return `| Geldig ${days} d ${hours} hrs`
            } else if (Number(hours) > 0) {
                return `| Geldig ${hours}:${minutes}:${seconds}`
            } else {
                return `| Geldig ${minutes}:${seconds}`
            }
        } else {
            clearInterval(this.interval);
            return <></>;
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval)
    }

    render() {
        return this.calculateTimeStamp();
    }
}

export default TimerOrders;
