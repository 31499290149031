import React, { Component } from "react";
import "react-sortable-tree/style.css";
import firebase from "../Config/Firebase";
import { connect } from "react-redux";
import { updateGlobalStuff } from "../Redux/actions/authActions";
import CK from "./Ck";
import AdminNavbar from "./AdminNavbar";

export class EditorCharityPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      treeData: [],
      sectorModal: false,
      sectorModalCategory: false,
      sectorName: "",
      sectors: [],
      currentSector: "",
      currentSectorId: "",
      categoryName: "",
      currentSector: "",
      categoryId: "",
      all_categories: [],
      sortingTab: true,
      adjustingTab: false,
      completeMainTitles: [],
      arrOfInputs: [],
      categoryDesc: "",
      charity_page_banner_english: "",
      charity_page_banner_dutch: "",
      charity_page_events_english: "",
      charity_page_events_dutch: "",
      charity_page_new_events_dutch: "",
      charity_page_new_events_english: "",
      charity_page_profile_dutch: "",
      charity_page_profile_english: "",
      charity_page_about_dutch: "",
      charity_page_about_english: "",
      charity_page_team_bhodi_dutch: "",
      charity_page_team_bhodi_english: "",
      charity_page_banner_heading_english: "",
      charity_page_banner_heading_dutch: "",
      charity_page_events_dutch_heading: "",
      charity_page_events_english_heading: "",
      charity_page_events_dutch_headertxt: "",
      charity_page_events_english_headertxt: "",
      charity_page_events_dutch_heading_inner: "",
      charity_page_events_english_heading_inner: "",
      charity_page_new_events_dutch_heading: "",
      charity_page_new_events_english_heading: "",
      charity_page_profile_dutch_heading: "",
      charity_page_profile_english_heading: "",
      charity_page_about_dutch_heading: "",
      charity_page_about_english_heading: "",
      charity_page_team_bhodi_dutch_heading: "",
      charity_page_team_bhodi_english_heading: "",
      charity_page_connect_dutch_heading: "",
      charity_page_connect_english_heading: "",
      charity_page_connect_dutch: "",
      charity_page_connect_english: "",
      // Mobile
      charity_page_banner_english_mobile: "",
      charity_page_banner_dutch_mobile: "",
      charity_page_events_english_mobile: "",
      charity_page_events_dutch_mobile: "",
      charity_page_new_events_dutch_mobile: "",
      charity_page_new_events_english_mobile: "",
      charity_page_profile_dutch_mobile: "",
      charity_page_profile_english_mobile: "",
      charity_page_about_dutch_mobile: "",
      charity_page_about_english_mobile: "",
      charity_page_team_bhodi_dutch_mobile: "",
      charity_page_team_bhodi_english_mobile: "",
      charity_page_banner_heading_english_mobile: "",
      charity_page_banner_heading_dutch_mobile: "",
      charity_page_events_dutch_heading_mobile: "",
      charity_page_events_english_heading_mobile: "",
      charity_page_events_dutch_headertxt_mobile: "",
      charity_page_events_english_headertxt_mobile: "",
      charity_page_events_dutch_heading_inner_mobile: "",
      charity_page_events_english_heading_inner_mobile: "",
      charity_page_new_events_dutch_heading_mobile: "",
      charity_page_new_events_english_heading_mobile: "",
      charity_page_profile_dutch_heading_mobile: "",
      charity_page_profile_english_heading_mobile: "",
      charity_page_about_dutch_heading_mobile: "",
      charity_page_about_english_heading_mobile: "",
      charity_page_team_bhodi_dutch_heading_mobile: "",
      charity_page_team_bhodi_english_heading_mobile: "",
      charity_page_connect_dutch_heading_mobile: "",
      charity_page_connect_english_heading_mobile: "",
      charity_page_connect_dutch_mobile: "",
      charity_page_connect_english_mobile: "",
      showStuffNow: false,
      onMobileDesigns: false,
      charity_page_banner_top_video: "",
      charity_page_charity_image: "",
      charity_page_event_right_image: "",
      charity_page_footer_image: "",
      // charity_page_banner_heading_english: "<p>Charity shopping</p>",
      // charity_page_banner_heading_dutch: "<p>Charity shopping</p>",
      // charity_page_events_dutch_heading: "<p>POP-UP EVENTS</p>",
      // charity_page_events_english_heading: "<p>POP-UP EVENTS</p>",
      // charity_page_events_dutch_headertxt: "<p>Expect the unexpected</p>",
      // charity_page_events_english_headertxt: "<p>Expect the unexpected</p>",
      // charity_page_new_events_dutch_heading: "<p>Nieuwste events</p>",
      // charity_page_new_events_english_heading: "<p>Nieuwste events</p>",
      // charity_page_profile_dutch_heading: "<p>Profilepage</p>",
      // charity_page_profile_english_heading: "<p>Profilepage</p>",
      // charity_page_about_dutch_heading: "<p>About</p>",
      // charity_page_about_english_heading: "<p>About</p>",
      // charity_page_team_bhodi_dutch_heading: "<p>Team Bhodi</p>",
      // charity_page_team_bhodi_english_heading: "<p>Team Bhodi</p>",
    };
  }

  componentDidMount() {
    const loaderId = document.getElementById("main-page-loader-id");
    loaderId.style.display = "none";

    setTimeout(() => {
      this.setState({ showStuffNow: true });
    }, 100);

    if (this.props.global_values) {
      let a = this.props.global_values;
      if (a) {
        this.setState({
          charity_page_banner_english: a.charity_page_banner_english,
          charity_page_banner_dutch: a.charity_page_banner_dutch,
          charity_page_events_english: a.charity_page_events_english,
          charity_page_events_dutch: a.charity_page_events_dutch,
          charity_page_new_events_dutch: a.charity_page_new_events_dutch,
          charity_page_new_events_english: a.charity_page_new_events_english,
          charity_page_profile_dutch: a.charity_page_profile_dutch,
          charity_page_profile_english: a.charity_page_profile_english,
          charity_page_about_dutch: a.charity_page_about_dutch,
          charity_page_about_english: a.charity_page_about_english,
          charity_page_team_bhodi_dutch: a.charity_page_team_bhodi_dutch,
          charity_page_team_bhodi_english: a.charity_page_team_bhodi_english,
          charity_page_banner_heading_english:
            a.charity_page_banner_heading_english,
          charity_page_banner_heading_dutch:
            a.charity_page_banner_heading_dutch,
          charity_page_events_dutch_heading:
            a.charity_page_events_dutch_heading,
          charity_page_events_english_heading:
            a.charity_page_events_english_heading,
          charity_page_events_dutch_heading_inner:
            a.charity_page_events_dutch_heading_inner,
          charity_page_events_english_heading_inner:
            a.charity_page_events_english_heading_inner,
          charity_page_events_dutch_headertxt:
            a.charity_page_events_dutch_headertxt,
          charity_page_events_english_headertxt:
            a.charity_page_events_english_headertxt,
          charity_page_profile_dutch_heading:
            a.charity_page_profile_dutch_heading,
          charity_page_profile_english_heading:
            a.charity_page_profile_english_heading,
          charity_page_new_events_dutch_heading:
            a.charity_page_new_events_dutch_heading,
          charity_page_new_events_english_heading:
            a.charity_page_new_events_english_heading,
          charity_page_about_dutch_heading: a.charity_page_about_dutch_heading,
          charity_page_about_english_heading:
            a.charity_page_about_english_heading,
          charity_page_team_bhodi_dutch_heading:
            a.charity_page_team_bhodi_dutch_heading,
          charity_page_team_bhodi_english_heading:
            a.charity_page_team_bhodi_english_heading,
          charity_page_connect_dutch_heading:
            a.charity_page_connect_dutch_heading,
          charity_page_connect_english_heading:
            a.charity_page_connect_english_heading,
          charity_page_connect_dutch: a.charity_page_connect_dutch,
          charity_page_connect_english: a.charity_page_connect_english,
          // Mobile
          charity_page_banner_english_mobile:
            a.charity_page_banner_english_mobile,
          charity_page_banner_dutch_mobile: a.charity_page_banner_dutch_mobile,
          charity_page_events_english_mobile:
            a.charity_page_events_english_mobile,
          charity_page_events_dutch_mobile: a.charity_page_events_dutch_mobile,
          charity_page_new_events_dutch_mobile:
            a.charity_page_new_events_dutch_mobile,
          charity_page_new_events_english_mobile:
            a.charity_page_new_events_english_mobile,
          charity_page_profile_dutch_mobile:
            a.charity_page_profile_dutch_mobile,
          charity_page_profile_english_mobile:
            a.charity_page_profile_english_mobile,
          charity_page_about_dutch_mobile: a.charity_page_about_dutch_mobile,
          charity_page_about_english_mobile:
            a.charity_page_about_english_mobile,
          charity_page_team_bhodi_dutch_mobile:
            a.charity_page_team_bhodi_dutch_mobile,
          charity_page_team_bhodi_english_mobile:
            a.charity_page_team_bhodi_english_mobile,
          charity_page_banner_heading_english_mobile:
            a.charity_page_banner_heading_english_mobile,
          charity_page_banner_heading_dutch_mobile:
            a.charity_page_banner_heading_dutch_mobile,
          charity_page_events_dutch_heading_mobile:
            a.charity_page_events_dutch_heading_mobile,
          charity_page_events_english_heading_mobile:
            a.charity_page_events_english_heading_mobile,
          charity_page_events_dutch_heading_inner_mobile:
            a.charity_page_events_dutch_heading_inner_mobile,
          charity_page_events_english_heading_inner_mobile:
            a.charity_page_events_english_heading_inner_mobile,
          charity_page_events_dutch_headertxt_mobile:
            a.charity_page_events_dutch_headertxt_mobile,
          charity_page_events_english_headertxt_mobile:
            a.charity_page_events_english_headertxt_mobile,
          charity_page_profile_dutch_heading_mobile:
            a.charity_page_profile_dutch_heading_mobile,
          charity_page_profile_english_heading_mobile:
            a.charity_page_profile_english_heading_mobile,
          charity_page_new_events_dutch_heading_mobile:
            a.charity_page_new_events_dutch_heading_mobile,
          charity_page_new_events_english_heading_mobile:
            a.charity_page_new_events_english_heading_mobile,
          charity_page_about_dutch_heading_mobile:
            a.charity_page_about_dutch_heading_mobile,
          charity_page_about_english_heading_mobile:
            a.charity_page_about_english_heading_mobile,
          charity_page_team_bhodi_dutch_heading_mobile:
            a.charity_page_team_bhodi_dutch_heading_mobile,
          charity_page_team_bhodi_english_heading_mobile:
            a.charity_page_team_bhodi_english_heading_mobile,
          charity_page_connect_dutch_heading_mobile:
            a.charity_page_connect_dutch_heading_mobile,
          charity_page_connect_english_heading_mobile:
            a.charity_page_connect_english_heading_mobile,
          charity_page_connect_dutch_mobile:
            a.charity_page_connect_dutch_mobile,
          charity_page_connect_english_mobile:
            a.charity_page_connect_english_mobile,
        });
        if (a.profiles_image) {
          this.setState({ profilesImage: a.profiles_image, profilesVideo: "" });
        } else {
          this.setState({ profilesVideo: a.profiles_video, profilesImage: "" });
        }
      }
    }

    firebase
      .database()
      .ref("bhodi_home")
      .on("value", (data) => {
        let a = data.val();
        if (a) {
          this.props.updateGlobalStuff(a);
          this.setState({
            charity_page_banner_english: a.charity_page_banner_english,
            charity_page_banner_dutch: a.charity_page_banner_dutch,
            charity_page_events_english: a.charity_page_events_english,
            charity_page_events_dutch: a.charity_page_events_dutch,
            charity_page_new_events_dutch: a.charity_page_new_events_dutch,
            charity_page_new_events_english: a.charity_page_new_events_english,
            charity_page_profile_dutch: a.charity_page_profile_dutch,
            charity_page_profile_english: a.charity_page_profile_english,
            charity_page_about_dutch: a.charity_page_about_dutch,
            charity_page_about_english: a.charity_page_about_english,
            charity_page_team_bhodi_dutch: a.charity_page_team_bhodi_dutch,
            charity_page_team_bhodi_english: a.charity_page_team_bhodi_english,
            charity_page_banner_heading_english:
              a.charity_page_banner_heading_english,
            charity_page_banner_heading_dutch:
              a.charity_page_banner_heading_dutch,
            charity_page_events_dutch_heading:
              a.charity_page_events_dutch_heading,
            charity_page_events_english_heading:
              a.charity_page_events_english_heading,
            charity_page_events_dutch_heading_inner:
              a.charity_page_events_dutch_heading_inner,
            charity_page_events_english_heading_inner:
              a.charity_page_events_english_heading_inner,
            charity_page_events_dutch_headertxt:
              a.charity_page_events_dutch_headertxt,
            charity_page_events_english_headertxt:
              a.charity_page_events_english_headertxt,
            charity_page_profile_dutch_heading:
              a.charity_page_profile_dutch_heading,
            charity_page_profile_english_heading:
              a.charity_page_profile_english_heading,
            charity_page_new_events_dutch_heading:
              a.charity_page_new_events_dutch_heading,
            charity_page_new_events_english_heading:
              a.charity_page_new_events_english_heading,
            charity_page_about_dutch_heading:
              a.charity_page_about_dutch_heading,
            charity_page_about_english_heading:
              a.charity_page_about_english_heading,
            charity_page_team_bhodi_dutch_heading:
              a.charity_page_team_bhodi_dutch_heading,
            charity_page_team_bhodi_english_heading:
              a.charity_page_team_bhodi_english_heading,
            charity_page_connect_dutch_heading:
              a.charity_page_connect_dutch_heading,
            charity_page_connect_english_heading:
              a.charity_page_connect_english_heading,
            charity_page_connect_dutch: a.charity_page_connect_dutch,
            charity_page_connect_english: a.charity_page_connect_english,
            // Mobile
            charity_page_banner_english_mobile:
              a.charity_page_banner_english_mobile,
            charity_page_banner_dutch_mobile:
              a.charity_page_banner_dutch_mobile,
            charity_page_events_english_mobile:
              a.charity_page_events_english_mobile,
            charity_page_events_dutch_mobile:
              a.charity_page_events_dutch_mobile,
            charity_page_new_events_dutch_mobile:
              a.charity_page_new_events_dutch_mobile,
            charity_page_new_events_english_mobile:
              a.charity_page_new_events_english_mobile,
            charity_page_profile_dutch_mobile:
              a.charity_page_profile_dutch_mobile,
            charity_page_profile_english_mobile:
              a.charity_page_profile_english_mobile,
            charity_page_about_dutch_mobile: a.charity_page_about_dutch_mobile,
            charity_page_about_english_mobile:
              a.charity_page_about_english_mobile,
            charity_page_team_bhodi_dutch_mobile:
              a.charity_page_team_bhodi_dutch_mobile,
            charity_page_team_bhodi_english_mobile:
              a.charity_page_team_bhodi_english_mobile,
            charity_page_banner_heading_english_mobile:
              a.charity_page_banner_heading_english_mobile,
            charity_page_banner_heading_dutch_mobile:
              a.charity_page_banner_heading_dutch_mobile,
            charity_page_events_dutch_heading_mobile:
              a.charity_page_events_dutch_heading_mobile,
            charity_page_events_english_heading_mobile:
              a.charity_page_events_english_heading_mobile,
            charity_page_events_dutch_heading_inner_mobile:
              a.charity_page_events_dutch_heading_inner_mobile,
            charity_page_events_english_heading_inner_mobile:
              a.charity_page_events_english_heading_inner_mobile,
            charity_page_events_dutch_headertxt_mobile:
              a.charity_page_events_dutch_headertxt_mobile,
            charity_page_events_english_headertxt_mobile:
              a.charity_page_events_english_headertxt_mobile,
            charity_page_profile_dutch_heading_mobile:
              a.charity_page_profile_dutch_heading_mobile,
            charity_page_profile_english_heading_mobile:
              a.charity_page_profile_english_heading_mobile,
            charity_page_new_events_dutch_heading_mobile:
              a.charity_page_new_events_dutch_heading_mobile,
            charity_page_new_events_english_heading_mobile:
              a.charity_page_new_events_english_heading_mobile,
            charity_page_about_dutch_heading_mobile:
              a.charity_page_about_dutch_heading_mobile,
            charity_page_about_english_heading_mobile:
              a.charity_page_about_english_heading_mobile,
            charity_page_team_bhodi_dutch_heading_mobile:
              a.charity_page_team_bhodi_dutch_heading_mobile,
            charity_page_team_bhodi_english_heading_mobile:
              a.charity_page_team_bhodi_english_heading_mobile,
            charity_page_connect_dutch_heading_mobile:
              a.charity_page_connect_dutch_heading_mobile,
            charity_page_connect_english_heading_mobile:
              a.charity_page_connect_english_heading_mobile,
            charity_page_connect_dutch_mobile:
              a.charity_page_connect_dutch_mobile,
            charity_page_connect_english_mobile:
              a.charity_page_connect_english_mobile,
          });
          if (a.profiles_image) {
            this.setState({
              profilesImage: a.profiles_image,
              profilesVideo: "",
            });
          } else {
            this.setState({
              profilesVideo: a.profiles_video,
              profilesImage: "",
            });
          }
        }
      });
  }

  changeAlertBannerDutch(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/charity_page_banner_dutch_mobile")
        .set(e)
        .then(() => {
          this.setState({ charity_page_banner_dutch_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/charity_page_banner_dutch")
        .set(e)
        .then(() => {
          this.setState({ charity_page_banner_dutch: e });
        });
    }
  }

  changeAlertBannerEnglish(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/charity_page_banner_english_mobile")
        .set(e)
        .then(() => {
          this.setState({ charity_page_banner_english_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/charity_page_banner_english")
        .set(e)
        .then(() => {
          this.setState({ charity_page_banner_english: e });
        });
    }
  }

  changeAlertBannerDutchHeading(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/charity_page_banner_heading_dutch_mobile")
        .set(e)
        .then(() => {
          this.setState({ charity_page_banner_heading_dutch_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/charity_page_banner_heading_dutch")
        .set(e)
        .then(() => {
          this.setState({ charity_page_banner_heading_dutch: e });
        });
    }
  }

  changeAlertBannerEnglishHeading(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/charity_page_banner_heading_english_mobile")
        .set(e)
        .then(() => {
          this.setState({ charity_page_banner_heading_english_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/charity_page_banner_heading_english")
        .set(e)
        .then(() => {
          this.setState({ charity_page_banner_heading_english: e });
        });
    }
  }

  changeAlertEventsDutch(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/charity_page_events_dutch_mobile")
        .set(e)
        .then(() => {
          this.setState({ charity_page_events_dutch_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/charity_page_events_dutch")
        .set(e)
        .then(() => {
          this.setState({ charity_page_events_dutch: e });
        });
    }
  }

  changeAlertEventsEnglish(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/charity_page_events_english_mobile")
        .set(e)
        .then(() => {
          this.setState({ charity_page_events_english_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/charity_page_events_english")
        .set(e)
        .then(() => {
          this.setState({ charity_page_events_english: e });
        });
    }
  }

  changeAlertEventsDutchHeading(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/charity_page_events_dutch_heading_mobile")
        .set(e)
        .then(() => {
          this.setState({ charity_page_events_dutch_heading_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/charity_page_events_dutch_heading")
        .set(e)
        .then(() => {
          this.setState({ charity_page_events_dutch_heading: e });
        });
    }
  }

  changeAlertEventsEnglishHeading(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/charity_page_events_english_heading_mobile")
        .set(e)
        .then(() => {
          this.setState({ charity_page_events_english_heading_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/charity_page_events_english_heading")
        .set(e)
        .then(() => {
          this.setState({ charity_page_events_english_heading: e });
        });
    }
  }

  changeAlertEventsDutchHeadingInner(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/charity_page_events_dutch_heading_inner_mobile")
        .set(e)
        .then(() => {
          this.setState({ charity_page_events_dutch_heading_inner_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/charity_page_events_dutch_heading_inner")
        .set(e)
        .then(() => {
          this.setState({ charity_page_events_dutch_heading_inner: e });
        });
    }
  }

  changeAlertEventsEnglishHeadingInner(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/charity_page_events_english_heading_inner_mobile")
        .set(e)
        .then(() => {
          this.setState({
            charity_page_events_english_heading_inner_mobile: e,
          });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/charity_page_events_english_heading_inner")
        .set(e)
        .then(() => {
          this.setState({ charity_page_events_english_heading_inner: e });
        });
    }
  }

  changeAlertEventsDutchHeaderTxt(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/charity_page_events_dutch_headertxt_mobile")
        .set(e)
        .then(() => {
          this.setState({ charity_page_events_dutch_headertxt_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/charity_page_events_dutch_headertxt")
        .set(e)
        .then(() => {
          this.setState({ charity_page_events_dutch_headertxt: e });
        });
    }
  }

  changeAlertEventsEnglishHeaderTxt(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/charity_page_events_english_headertxt_mobile")
        .set(e)
        .then(() => {
          this.setState({ charity_page_events_english_headertxt_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/charity_page_events_english_headertxt")
        .set(e)
        .then(() => {
          this.setState({ charity_page_events_english_headertxt: e });
        });
    }
  }

  changeAlertNewEventsDutch(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/charity_page_new_events_dutch_mobile")
        .set(e)
        .then(() => {
          this.setState({ charity_page_new_events_dutch_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/charity_page_new_events_dutch")
        .set(e)
        .then(() => {
          this.setState({ charity_page_new_events_dutch: e });
        });
    }
  }

  changeAlertNewEventsEnglish(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/charity_page_new_events_english_mobile")
        .set(e)
        .then(() => {
          this.setState({ charity_page_new_events_english_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/charity_page_new_events_english")
        .set(e)
        .then(() => {
          this.setState({ charity_page_new_events_english: e });
        });
    }
  }

  changeAlertNewEventsDutchHeading(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/charity_page_new_events_dutch_heading_mobile")
        .set(e)
        .then(() => {
          this.setState({ charity_page_new_events_dutch_heading_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/charity_page_new_events_dutch_heading")
        .set(e)
        .then(() => {
          this.setState({ charity_page_new_events_dutch_heading: e });
        });
    }
  }

  changeAlertNewEventsEnglishHeading(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/charity_page_new_events_english_heading_mobile")
        .set(e)
        .then(() => {
          this.setState({ charity_page_new_events_english_heading_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/charity_page_new_events_english_heading")
        .set(e)
        .then(() => {
          this.setState({ charity_page_new_events_english_heading: e });
        });
    }
  }

  changeAlertNewProfileDutchHeading(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/charity_page_profile_dutch_heading_mobile")
        .set(e)
        .then(() => {
          this.setState({ charity_page_profile_dutch_heading_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/charity_page_profile_dutch_heading")
        .set(e)
        .then(() => {
          this.setState({ charity_page_profile_dutch_heading: e });
        });
    }
  }

  changeAlertNewProfileEnglishHeading(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/charity_page_profile_english_heading_mobile")
        .set(e)
        .then(() => {
          this.setState({ charity_page_profile_english_heading_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/charity_page_profile_english_heading")
        .set(e)
        .then(() => {
          this.setState({ charity_page_profile_english_heading: e });
        });
    }
  }

  changeAlertNewProfileDutch(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/charity_page_profile_dutch_mobile")
        .set(e)
        .then(() => {
          this.setState({ charity_page_profile_dutch_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/charity_page_profile_dutch")
        .set(e)
        .then(() => {
          this.setState({ charity_page_profile_dutch: e });
        });
    }
  }

  changeAlertNewProfileEnglish(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/charity_page_profile_english_mobile")
        .set(e)
        .then(() => {
          this.setState({ charity_page_profile_english_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/charity_page_profile_english")
        .set(e)
        .then(() => {
          this.setState({ charity_page_profile_english: e });
        });
    }
  }

  changeAlertAboutDutchHeading(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/charity_page_about_dutch_heading_mobile")
        .set(e)
        .then(() => {
          this.setState({ charity_page_about_dutch_heading_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/charity_page_about_dutch_heading")
        .set(e)
        .then(() => {
          this.setState({ charity_page_about_dutch_heading: e });
        });
    }
  }

  changeAlertAboutEnglishHeading(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/charity_page_about_english_heading_mobile")
        .set(e)
        .then(() => {
          this.setState({ charity_page_about_english_heading_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/charity_page_about_english_heading")
        .set(e)
        .then(() => {
          this.setState({ charity_page_about_english_heading: e });
        });
    }
  }

  changeAlertAboutDutch(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/charity_page_about_dutch_mobile")
        .set(e)
        .then(() => {
          this.setState({ charity_page_about_dutch_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/charity_page_about_dutch")
        .set(e)
        .then(() => {
          this.setState({ charity_page_about_dutch: e });
        });
    }
  }

  changeAlertAboutEnglish(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/charity_page_about_english_mobile")
        .set(e)
        .then(() => {
          this.setState({ charity_page_about_english_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/charity_page_about_english")
        .set(e)
        .then(() => {
          this.setState({ charity_page_about_english: e });
        });
    }
  }

  changeAlertTeamBhodiDutchHeading(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/charity_page_team_bhodi_dutch_heading_mobile")
        .set(e)
        .then(() => {
          this.setState({ charity_page_team_bhodi_dutch_heading_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/charity_page_team_bhodi_dutch_heading")
        .set(e)
        .then(() => {
          this.setState({ charity_page_team_bhodi_dutch_heading: e });
        });
    }
  }

  changeAlertTeamBhodiEnglishHeading(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/charity_page_team_bhodi_english_heading_mobile")
        .set(e)
        .then(() => {
          this.setState({ charity_page_team_bhodi_english_heading_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/charity_page_team_bhodi_english_heading")
        .set(e)
        .then(() => {
          this.setState({ charity_page_team_bhodi_english_heading: e });
        });
    }
  }

  changeAlertTeamBhodiDutch(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/charity_page_team_bhodi_dutch_mobile")
        .set(e)
        .then(() => {
          this.setState({ charity_page_team_bhodi_dutch_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/charity_page_team_bhodi_dutch")
        .set(e)
        .then(() => {
          this.setState({ charity_page_team_bhodi_dutch: e });
        });
    }
  }

  changeAlertTeamBhodiEnglish(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/charity_page_team_bhodi_english_mobile")
        .set(e)
        .then(() => {
          this.setState({ charity_page_team_bhodi_english_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/charity_page_team_bhodi_english")
        .set(e)
        .then(() => {
          this.setState({ charity_page_team_bhodi_english: e });
        });
    }
  }

  changeAlertConnectDutchHeading(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/charity_page_connect_dutch_heading_mobile")
        .set(e)
        .then(() => {
          this.setState({ charity_page_connect_dutch_heading_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/charity_page_connect_dutch_heading")
        .set(e)
        .then(() => {
          this.setState({ charity_page_connect_dutch_heading: e });
        });
    }
  }

  changeAlertConnectEnglishHeading(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/charity_page_connect_english_heading_mobile")
        .set(e)
        .then(() => {
          this.setState({ charity_page_connect_english_heading_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/charity_page_connect_english_heading")
        .set(e)
        .then(() => {
          this.setState({ charity_page_connect_english_heading: e });
        });
    }
  }

  changeAlertConnectDutch(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/charity_page_connect_dutch_mobile")
        .set(e)
        .then(() => {
          this.setState({ charity_page_connect_dutch_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/charity_page_connect_dutch")
        .set(e)
        .then(() => {
          this.setState({ charity_page_connect_dutch: e });
        });
    }
  }

  changeAlertConnectEnglish(e) {
    if (this.state.onMobileDesigns) {
      firebase
        .database()
        .ref("bhodi_home/charity_page_connect_english_mobile")
        .set(e)
        .then(() => {
          this.setState({ charity_page_connect_english_mobile: e });
        });
    } else {
      firebase
        .database()
        .ref("bhodi_home/charity_page_connect_english")
        .set(e)
        .then(() => {
          this.setState({ charity_page_connect_english: e });
        });
    }
  }

  fileUploadBannerVideo(e) {
    let files = e.target.files[0];
    let storageRef = firebase
      .storage()
      .ref()
      .child(`bhodi_home_media/${files.name}${new Date().getTime()}`);
    storageRef.put(files).then((snapshot) => {
      snapshot.ref.getDownloadURL().then((snapUrl) => {
        firebase
          .database()
          .ref("bhodi_home/charity_page_banner_top_video/")
          .set(snapUrl)
          .then(() => {});
      });
    });
  }

  fileUploadCharityVideo(e) {
    let files = e.target.files[0];
    let storageRef = firebase
      .storage()
      .ref()
      .child(`bhodi_home_media/${files.name}${new Date().getTime()}`);
    storageRef.put(files).then((snapshot) => {
      snapshot.ref.getDownloadURL().then((snapUrl) => {
        firebase
          .database()
          .ref("bhodi_home/charity_page_charity_image/")
          .set(snapUrl)
          .then(() => {});
      });
    });
  }

  fileUploadEventsImage(e) {
    let files = e.target.files[0];
    let storageRef = firebase
      .storage()
      .ref()
      .child(`bhodi_home_media/${files.name}${new Date().getTime()}`);
    storageRef.put(files).then((snapshot) => {
      snapshot.ref.getDownloadURL().then((snapUrl) => {
        firebase
          .database()
          .ref("bhodi_home/charity_page_event_right_image/")
          .set(snapUrl)
          .then(() => {});
      });
    });
  }

  fileUploadFooterImage(e) {
    let files = e.target.files[0];
    let storageRef = firebase
      .storage()
      .ref()
      .child(`bhodi_home_media/${files.name}${new Date().getTime()}`);
    storageRef.put(files).then((snapshot) => {
      snapshot.ref.getDownloadURL().then((snapUrl) => {
        firebase
          .database()
          .ref("bhodi_home/charity_page_footer_image/")
          .set(snapUrl)
          .then(() => {});
      });
    });
  }

  render() {
    return (
      <div className="sorting-mainadmin">
        <AdminNavbar charityPage={true} />
        <div className="buttons-container-displayeditor">
          {this.state.onMobileDesigns ? (
            <button
              className="buttonsgreendisplay"
              onClick={() => {
                this.setState({ onMobileDesigns: false });
              }}
            >
              Switch To Desktop
            </button>
          ) : (
            <button
              className="buttonsgreendisplay"
              onClick={() => {
                this.setState({ onMobileDesigns: true });
              }}
            >
              Switch To Mobile
            </button>
          )}
        </div>
        {this.state.showStuffNow && this.state.onMobileDesigns ? (
          <div
            className="container-sma"
            style={{ maxWidth: 1200, paddingBottom: 50 }}
          >
            <div className="tree-inner" style={{ marginTop: 30 }}>
              <div
                className="container-editors-lp"
                style={{ justifyContent: "center" }}
              >
                <div className="inner-celandingpage headinglabelcelp">
                  <label>Banner Heading</label>
                </div>
              </div>
              <div className="container-editors-lp">
                <div className="inner-celandingpage">
                  <label>Dutch</label>
                  <CK
                    data={this.state.charity_page_banner_heading_dutch_mobile}
                    onChildChange={this.changeAlertBannerDutchHeading.bind(
                      this
                    )}
                  />
                </div>
                <div className="inner-celandingpage">
                  <label>English</label>
                  <CK
                    data={this.state.charity_page_banner_heading_english_mobile}
                    onChildChange={this.changeAlertBannerEnglishHeading.bind(
                      this
                    )}
                  />
                </div>
              </div>
              <div
                className="container-editors-lp"
                style={{ justifyContent: "center", marginTop: 30 }}
              >
                <div className="inner-celandingpage headinglabelcelp">
                  <label>Banner</label>
                </div>
              </div>
              <div className="container-editors-lp">
                <div className="inner-celandingpage">
                  <label>Dutch</label>
                  <CK
                    data={this.state.charity_page_banner_dutch_mobile}
                    onChildChange={this.changeAlertBannerDutch.bind(this)}
                  />
                </div>
                <div className="inner-celandingpage">
                  <label>English</label>
                  <CK
                    data={this.state.charity_page_banner_english_mobile}
                    onChildChange={this.changeAlertBannerEnglish.bind(this)}
                  />
                </div>
              </div>
              <div
                className="container-editors-lp"
                style={{ justifyContent: "center", marginTop: 30 }}
              >
                <div className="inner-celandingpage headinglabelcelp">
                  <label>Events Heading</label>
                </div>
              </div>
              <div className="container-editors-lp">
                <div className="inner-celandingpage">
                  <label>Dutch</label>
                  <CK
                    data={this.state.charity_page_events_dutch_heading_mobile}
                    onChildChange={this.changeAlertEventsDutchHeading.bind(
                      this
                    )}
                  />
                </div>
                <div className="inner-celandingpage">
                  <label>English</label>
                  <CK
                    data={this.state.charity_page_events_english_heading_mobile}
                    onChildChange={this.changeAlertEventsEnglishHeading.bind(
                      this
                    )}
                  />
                </div>
              </div>
              <div
                className="container-editors-lp"
                style={{ justifyContent: "center", marginTop: 30 }}
              >
                <div className="inner-celandingpage headinglabelcelp">
                  <label>Events Heading Inner</label>
                </div>
              </div>
              <div className="container-editors-lp">
                <div className="inner-celandingpage">
                  <label>Dutch</label>
                  <CK
                    data={
                      this.state.charity_page_events_dutch_heading_inner_mobile
                    }
                    onChildChange={this.changeAlertEventsDutchHeadingInner.bind(
                      this
                    )}
                  />
                </div>
                <div className="inner-celandingpage">
                  <label>English</label>
                  <CK
                    data={
                      this.state
                        .charity_page_events_english_heading_inner_mobile
                    }
                    onChildChange={this.changeAlertEventsEnglishHeadingInner.bind(
                      this
                    )}
                  />
                </div>
              </div>
              <div
                className="container-editors-lp"
                style={{ justifyContent: "center", marginTop: 30 }}
              >
                <div className="inner-celandingpage headinglabelcelp">
                  <label>Events Header Text</label>
                </div>
              </div>
              <div className="container-editors-lp">
                <div className="inner-celandingpage">
                  <label>Dutch</label>
                  <CK
                    data={this.state.charity_page_events_dutch_headertxt_mobile}
                    onChildChange={this.changeAlertEventsDutchHeaderTxt.bind(
                      this
                    )}
                  />
                </div>
                <div className="inner-celandingpage">
                  <label>English</label>
                  <CK
                    data={
                      this.state.charity_page_events_english_headertxt_mobile
                    }
                    onChildChange={this.changeAlertEventsEnglishHeaderTxt.bind(
                      this
                    )}
                  />
                </div>
              </div>
              <div
                className="container-editors-lp"
                style={{ justifyContent: "center", marginTop: 30 }}
              >
                <div className="inner-celandingpage headinglabelcelp">
                  <label>Events</label>
                </div>
              </div>
              <div className="container-editors-lp">
                <div className="inner-celandingpage">
                  <label>Dutch</label>
                  <CK
                    data={this.state.charity_page_events_dutch_mobile}
                    onChildChange={this.changeAlertEventsDutch.bind(this)}
                  />
                </div>
                <div className="inner-celandingpage">
                  <label>English</label>
                  <CK
                    data={this.state.charity_page_events_english_mobile}
                    onChildChange={this.changeAlertEventsEnglish.bind(this)}
                  />
                </div>
              </div>
              <div
                className="container-editors-lp"
                style={{ justifyContent: "center", marginTop: 30 }}
              >
                <div className="inner-celandingpage headinglabelcelp">
                  <label>Nieuwste events heading</label>
                </div>
              </div>
              <div className="container-editors-lp">
                <div className="inner-celandingpage">
                  <label>Dutch</label>
                  <CK
                    data={
                      this.state.charity_page_new_events_dutch_heading_mobile
                    }
                    onChildChange={this.changeAlertNewEventsDutchHeading.bind(
                      this
                    )}
                  />
                </div>
                <div className="inner-celandingpage">
                  <label>English</label>
                  <CK
                    data={
                      this.state.charity_page_new_events_english_heading_mobile
                    }
                    onChildChange={this.changeAlertNewEventsEnglishHeading.bind(
                      this
                    )}
                  />
                </div>
              </div>
              <div
                className="container-editors-lp"
                style={{ justifyContent: "center", marginTop: 30 }}
              >
                <div className="inner-celandingpage headinglabelcelp">
                  <label>Nieuwste events</label>
                </div>
              </div>
              <div className="container-editors-lp">
                <div className="inner-celandingpage">
                  <label>Dutch</label>
                  <CK
                    data={this.state.charity_page_new_events_dutch_mobile}
                    onChildChange={this.changeAlertNewEventsDutch.bind(this)}
                  />
                </div>
                <div className="inner-celandingpage">
                  <label>English</label>
                  <CK
                    data={this.state.charity_page_new_events_english_mobile}
                    onChildChange={this.changeAlertNewEventsEnglish.bind(this)}
                  />
                </div>
              </div>
              <div
                className="container-editors-lp"
                style={{ justifyContent: "center", marginTop: 30 }}
              >
                <div className="inner-celandingpage headinglabelcelp">
                  <label>Profilepage heading</label>
                </div>
              </div>
              <div className="container-editors-lp">
                <div className="inner-celandingpage">
                  <label>Dutch</label>
                  <CK
                    data={this.state.charity_page_profile_dutch_heading_mobile}
                    onChildChange={this.changeAlertNewProfileDutchHeading.bind(
                      this
                    )}
                  />
                </div>
                <div className="inner-celandingpage">
                  <label>English</label>
                  <CK
                    data={
                      this.state.charity_page_profile_english_heading_mobile
                    }
                    onChildChange={this.changeAlertNewProfileEnglishHeading.bind(
                      this
                    )}
                  />
                </div>
              </div>
              <div
                className="container-editors-lp"
                style={{ justifyContent: "center", marginTop: 30 }}
              >
                <div className="inner-celandingpage headinglabelcelp">
                  <label>Profilepage</label>
                </div>
              </div>
              <div className="container-editors-lp">
                <div className="inner-celandingpage">
                  <label>Dutch</label>
                  <CK
                    data={this.state.charity_page_profile_dutch_mobile}
                    onChildChange={this.changeAlertNewProfileDutch.bind(this)}
                  />
                </div>
                <div className="inner-celandingpage">
                  <label>English</label>
                  <CK
                    data={this.state.charity_page_profile_english_mobile}
                    onChildChange={this.changeAlertNewProfileEnglish.bind(this)}
                  />
                </div>
              </div>
              <div
                className="container-editors-lp"
                style={{ justifyContent: "center", marginTop: 30 }}
              >
                <div className="inner-celandingpage headinglabelcelp">
                  <label>About Heading</label>
                </div>
              </div>
              <div className="container-editors-lp">
                <div className="inner-celandingpage">
                  <label>Dutch</label>
                  <CK
                    data={this.state.charity_page_about_dutch_heading_mobile}
                    onChildChange={this.changeAlertAboutDutchHeading.bind(this)}
                  />
                </div>
                <div className="inner-celandingpage">
                  <label>English</label>
                  <CK
                    data={this.state.charity_page_about_english_heading_mobile}
                    onChildChange={this.changeAlertAboutEnglishHeading.bind(
                      this
                    )}
                  />
                </div>
              </div>
              <div
                className="container-editors-lp"
                style={{ justifyContent: "center", marginTop: 30 }}
              >
                <div className="inner-celandingpage headinglabelcelp">
                  <label>About</label>
                </div>
              </div>
              <div className="container-editors-lp">
                <div className="inner-celandingpage">
                  <label>Dutch</label>
                  <CK
                    data={this.state.charity_page_about_dutch_mobile}
                    onChildChange={this.changeAlertAboutDutch.bind(this)}
                  />
                </div>
                <div className="inner-celandingpage">
                  <label>English</label>
                  <CK
                    data={this.state.charity_page_about_english_mobile}
                    onChildChange={this.changeAlertAboutEnglish.bind(this)}
                  />
                </div>
              </div>
              <div
                className="container-editors-lp"
                style={{ justifyContent: "center", marginTop: 30 }}
              >
                <div className="inner-celandingpage headinglabelcelp">
                  <label>Team Bhodi Heading</label>
                </div>
              </div>
              <div className="container-editors-lp">
                <div className="inner-celandingpage">
                  <label>Dutch</label>
                  <CK
                    data={
                      this.state.charity_page_team_bhodi_dutch_heading_mobile
                    }
                    onChildChange={this.changeAlertTeamBhodiDutchHeading.bind(
                      this
                    )}
                  />
                </div>
                <div className="inner-celandingpage">
                  <label>English</label>
                  <CK
                    data={
                      this.state.charity_page_team_bhodi_english_heading_mobile
                    }
                    onChildChange={this.changeAlertTeamBhodiEnglishHeading.bind(
                      this
                    )}
                  />
                </div>
              </div>
              <div
                className="container-editors-lp"
                style={{ justifyContent: "center", marginTop: 30 }}
              >
                <div className="inner-celandingpage headinglabelcelp">
                  <label>Team Bhodi</label>
                </div>
              </div>
              <div className="container-editors-lp">
                <div className="inner-celandingpage">
                  <label>Dutch</label>
                  <CK
                    data={this.state.charity_page_team_bhodi_dutch_mobile}
                    onChildChange={this.changeAlertTeamBhodiDutch.bind(this)}
                  />
                </div>
                <div className="inner-celandingpage">
                  <label>English</label>
                  <CK
                    data={this.state.charity_page_team_bhodi_english_mobile}
                    onChildChange={this.changeAlertTeamBhodiEnglish.bind(this)}
                  />
                </div>
              </div>

              <div
                className="container-editors-lp"
                style={{ justifyContent: "center", marginTop: 30 }}
              >
                <div className="inner-celandingpage headinglabelcelp">
                  <label>Connect Heading</label>
                </div>
              </div>
              <div className="container-editors-lp">
                <div className="inner-celandingpage">
                  <label>Dutch</label>
                  <CK
                    data={this.state.charity_page_connect_dutch_heading_mobile}
                    onChildChange={this.changeAlertConnectDutchHeading.bind(
                      this
                    )}
                  />
                </div>
                <div className="inner-celandingpage">
                  <label>English</label>
                  <CK
                    data={
                      this.state.charity_page_connect_english_heading_mobile
                    }
                    onChildChange={this.changeAlertConnectEnglishHeading.bind(
                      this
                    )}
                  />
                </div>
              </div>
              <div
                className="container-editors-lp"
                style={{ justifyContent: "center", marginTop: 30 }}
              >
                <div className="inner-celandingpage headinglabelcelp">
                  <label>Connect</label>
                </div>
              </div>
              <div className="container-editors-lp">
                <div className="inner-celandingpage">
                  <label>Dutch</label>
                  <CK
                    data={this.state.charity_page_connect_dutch_mobile}
                    onChildChange={this.changeAlertConnectDutch.bind(this)}
                  />
                </div>
                <div className="inner-celandingpage">
                  <label>English</label>
                  <CK
                    data={this.state.charity_page_connect_english_mobile}
                    onChildChange={this.changeAlertConnectEnglish.bind(this)}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          this.state.showStuffNow && (
            <div
              className="container-sma"
              style={{ maxWidth: 1200, paddingBottom: 50 }}
            >
              <div className="tree-inner" style={{ marginTop: 30 }}>
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center" }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>Banner Heading</label>
                  </div>
                </div>
                <div className="container-editors-lp">
                  <div className="inner-celandingpage">
                    <label>Dutch</label>
                    <CK
                      data={this.state.charity_page_banner_heading_dutch}
                      onChildChange={this.changeAlertBannerDutchHeading.bind(
                        this
                      )}
                    />
                  </div>
                  <div className="inner-celandingpage">
                    <label>English</label>
                    <CK
                      data={this.state.charity_page_banner_heading_english}
                      onChildChange={this.changeAlertBannerEnglishHeading.bind(
                        this
                      )}
                    />
                  </div>
                </div>
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center", marginTop: 30 }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>Banner</label>
                  </div>
                </div>
                <div className="container-editors-lp">
                  <div className="inner-celandingpage">
                    <label>Dutch</label>
                    <CK
                      data={this.state.charity_page_banner_dutch}
                      onChildChange={this.changeAlertBannerDutch.bind(this)}
                    />
                  </div>
                  <div className="inner-celandingpage">
                    <label>English</label>
                    <CK
                      data={this.state.charity_page_banner_english}
                      onChildChange={this.changeAlertBannerEnglish.bind(this)}
                    />
                  </div>
                </div>
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center", marginTop: 30 }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>Events Heading</label>
                  </div>
                </div>
                <div className="container-editors-lp">
                  <div className="inner-celandingpage">
                    <label>Dutch</label>
                    <CK
                      data={this.state.charity_page_events_dutch_heading}
                      onChildChange={this.changeAlertEventsDutchHeading.bind(
                        this
                      )}
                    />
                  </div>
                  <div className="inner-celandingpage">
                    <label>English</label>
                    <CK
                      data={this.state.charity_page_events_english_heading}
                      onChildChange={this.changeAlertEventsEnglishHeading.bind(
                        this
                      )}
                    />
                  </div>
                </div>
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center", marginTop: 30 }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>Events Heading Inner</label>
                  </div>
                </div>
                <div className="container-editors-lp">
                  <div className="inner-celandingpage">
                    <label>Dutch</label>
                    <CK
                      data={this.state.charity_page_events_dutch_heading_inner}
                      onChildChange={this.changeAlertEventsDutchHeadingInner.bind(
                        this
                      )}
                    />
                  </div>
                  <div className="inner-celandingpage">
                    <label>English</label>
                    <CK
                      data={
                        this.state.charity_page_events_english_heading_inner
                      }
                      onChildChange={this.changeAlertEventsEnglishHeadingInner.bind(
                        this
                      )}
                    />
                  </div>
                </div>
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center", marginTop: 30 }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>Events Header Text</label>
                  </div>
                </div>
                <div className="container-editors-lp">
                  <div className="inner-celandingpage">
                    <label>Dutch</label>
                    <CK
                      data={this.state.charity_page_events_dutch_headertxt}
                      onChildChange={this.changeAlertEventsDutchHeaderTxt.bind(
                        this
                      )}
                    />
                  </div>
                  <div className="inner-celandingpage">
                    <label>English</label>
                    <CK
                      data={this.state.charity_page_events_english_headertxt}
                      onChildChange={this.changeAlertEventsEnglishHeaderTxt.bind(
                        this
                      )}
                    />
                  </div>
                </div>
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center", marginTop: 30 }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>Events</label>
                  </div>
                </div>
                <div className="container-editors-lp">
                  <div className="inner-celandingpage">
                    <label>Dutch</label>
                    <CK
                      data={this.state.charity_page_events_dutch}
                      onChildChange={this.changeAlertEventsDutch.bind(this)}
                    />
                  </div>
                  <div className="inner-celandingpage">
                    <label>English</label>
                    <CK
                      data={this.state.charity_page_events_english}
                      onChildChange={this.changeAlertEventsEnglish.bind(this)}
                    />
                  </div>
                </div>
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center", marginTop: 30 }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>Nieuwste events heading</label>
                  </div>
                </div>
                <div className="container-editors-lp">
                  <div className="inner-celandingpage">
                    <label>Dutch</label>
                    <CK
                      data={this.state.charity_page_new_events_dutch_heading}
                      onChildChange={this.changeAlertNewEventsDutchHeading.bind(
                        this
                      )}
                    />
                  </div>
                  <div className="inner-celandingpage">
                    <label>English</label>
                    <CK
                      data={this.state.charity_page_new_events_english_heading}
                      onChildChange={this.changeAlertNewEventsEnglishHeading.bind(
                        this
                      )}
                    />
                  </div>
                </div>
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center", marginTop: 30 }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>Nieuwste events</label>
                  </div>
                </div>
                <div className="container-editors-lp">
                  <div className="inner-celandingpage">
                    <label>Dutch</label>
                    <CK
                      data={this.state.charity_page_new_events_dutch}
                      onChildChange={this.changeAlertNewEventsDutch.bind(this)}
                    />
                  </div>
                  <div className="inner-celandingpage">
                    <label>English</label>
                    <CK
                      data={this.state.charity_page_new_events_english}
                      onChildChange={this.changeAlertNewEventsEnglish.bind(
                        this
                      )}
                    />
                  </div>
                </div>
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center", marginTop: 30 }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>Profilepage heading</label>
                  </div>
                </div>
                <div className="container-editors-lp">
                  <div className="inner-celandingpage">
                    <label>Dutch</label>
                    <CK
                      data={this.state.charity_page_profile_dutch_heading}
                      onChildChange={this.changeAlertNewProfileDutchHeading.bind(
                        this
                      )}
                    />
                  </div>
                  <div className="inner-celandingpage">
                    <label>English</label>
                    <CK
                      data={this.state.charity_page_profile_english_heading}
                      onChildChange={this.changeAlertNewProfileEnglishHeading.bind(
                        this
                      )}
                    />
                  </div>
                </div>
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center", marginTop: 30 }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>Profilepage</label>
                  </div>
                </div>
                <div className="container-editors-lp">
                  <div className="inner-celandingpage">
                    <label>Dutch</label>
                    <CK
                      data={this.state.charity_page_profile_dutch}
                      onChildChange={this.changeAlertNewProfileDutch.bind(this)}
                    />
                  </div>
                  <div className="inner-celandingpage">
                    <label>English</label>
                    <CK
                      data={this.state.charity_page_profile_english}
                      onChildChange={this.changeAlertNewProfileEnglish.bind(
                        this
                      )}
                    />
                  </div>
                </div>
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center", marginTop: 30 }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>About Heading</label>
                  </div>
                </div>
                <div className="container-editors-lp">
                  <div className="inner-celandingpage">
                    <label>Dutch</label>
                    <CK
                      data={this.state.charity_page_about_dutch_heading}
                      onChildChange={this.changeAlertAboutDutchHeading.bind(
                        this
                      )}
                    />
                  </div>
                  <div className="inner-celandingpage">
                    <label>English</label>
                    <CK
                      data={this.state.charity_page_about_english_heading}
                      onChildChange={this.changeAlertAboutEnglishHeading.bind(
                        this
                      )}
                    />
                  </div>
                </div>
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center", marginTop: 30 }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>About</label>
                  </div>
                </div>
                <div className="container-editors-lp">
                  <div className="inner-celandingpage">
                    <label>Dutch</label>
                    <CK
                      data={this.state.charity_page_about_dutch}
                      onChildChange={this.changeAlertAboutDutch.bind(this)}
                    />
                  </div>
                  <div className="inner-celandingpage">
                    <label>English</label>
                    <CK
                      data={this.state.charity_page_about_english}
                      onChildChange={this.changeAlertAboutEnglish.bind(this)}
                    />
                  </div>
                </div>
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center", marginTop: 30 }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>Team Bhodi Heading</label>
                  </div>
                </div>
                <div className="container-editors-lp">
                  <div className="inner-celandingpage">
                    <label>Dutch</label>
                    <CK
                      data={this.state.charity_page_team_bhodi_dutch_heading}
                      onChildChange={this.changeAlertTeamBhodiDutchHeading.bind(
                        this
                      )}
                    />
                  </div>
                  <div className="inner-celandingpage">
                    <label>English</label>
                    <CK
                      data={this.state.charity_page_team_bhodi_english_heading}
                      onChildChange={this.changeAlertTeamBhodiEnglishHeading.bind(
                        this
                      )}
                    />
                  </div>
                </div>
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center", marginTop: 30 }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>Team Bhodi</label>
                  </div>
                </div>
                <div className="container-editors-lp">
                  <div className="inner-celandingpage">
                    <label>Dutch</label>
                    <CK
                      data={this.state.charity_page_team_bhodi_dutch}
                      onChildChange={this.changeAlertTeamBhodiDutch.bind(this)}
                    />
                  </div>
                  <div className="inner-celandingpage">
                    <label>English</label>
                    <CK
                      data={this.state.charity_page_team_bhodi_english}
                      onChildChange={this.changeAlertTeamBhodiEnglish.bind(
                        this
                      )}
                    />
                  </div>
                </div>

                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center", marginTop: 30 }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>Connect Heading</label>
                  </div>
                </div>
                <div className="container-editors-lp">
                  <div className="inner-celandingpage">
                    <label>Dutch</label>
                    <CK
                      data={this.state.charity_page_connect_dutch_heading}
                      onChildChange={this.changeAlertConnectDutchHeading.bind(
                        this
                      )}
                    />
                  </div>
                  <div className="inner-celandingpage">
                    <label>English</label>
                    <CK
                      data={this.state.charity_page_connect_english_heading}
                      onChildChange={this.changeAlertConnectEnglishHeading.bind(
                        this
                      )}
                    />
                  </div>
                </div>
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center", marginTop: 30 }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>Connect</label>
                  </div>
                </div>
                <div className="container-editors-lp">
                  <div className="inner-celandingpage">
                    <label>Dutch</label>
                    <CK
                      data={this.state.charity_page_connect_dutch}
                      onChildChange={this.changeAlertConnectDutch.bind(this)}
                    />
                  </div>
                  <div className="inner-celandingpage">
                    <label>English</label>
                    <CK
                      data={this.state.charity_page_connect_english}
                      onChildChange={this.changeAlertConnectEnglish.bind(this)}
                    />
                  </div>
                </div>

                {/* images/videos bhodi */}
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center", marginTop: 30 }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>Banner Video</label>
                  </div>
                </div>
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center" }}
                >
                  <input
                    type="file"
                    style={{ padding: 15, border: "1px solid gray" }}
                    accept="video/*"
                    onChange={(e) => this.fileUploadBannerVideo(e)}
                  />
                </div>

                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center", marginTop: 30 }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>Charity video</label>
                  </div>
                </div>
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center" }}
                >
                  <input
                    type="file"
                    style={{ padding: 15, border: "1px solid gray" }}
                    accept="video/*"
                    onChange={(e) => this.fileUploadCharityVideo(e)}
                  />
                </div>

                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center", marginTop: 30 }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>Events right image</label>
                  </div>
                </div>
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center" }}
                >
                  <input
                    type="file"
                    style={{ padding: 15, border: "1px solid gray" }}
                    accept="image/*"
                    onChange={(e) => this.fileUploadEventsImage(e)}
                  />
                </div>

                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center", marginTop: 30 }}
                >
                  <div className="inner-celandingpage headinglabelcelp">
                    <label>Footer image</label>
                  </div>
                </div>
                <div
                  className="container-editors-lp"
                  style={{ justifyContent: "center" }}
                >
                  <input
                    type="file"
                    style={{ padding: 15, border: "1px solid gray" }}
                    accept="image/*"
                    onChange={(e) => this.fileUploadFooterImage(e)}
                  />
                </div>
              </div>
            </div>
          )
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    global_values: state.globalReducers.global_values,
    user: state.authReducers.user,
    admin_boolean: state.globalReducers.admin_boolean,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateGlobalStuff: (global_values) =>
      dispatch(updateGlobalStuff(global_values)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditorCharityPage);
