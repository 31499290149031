import React, { Component } from "react";
import AddIcon from "@material-ui/icons/Add";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import SortableTree from "react-sortable-tree";
import "react-sortable-tree/style.css";
import CloseIcon from "@material-ui/icons/Close";
import { updateGlobalStuff } from "../Redux/actions/authActions";
import { connect } from "react-redux";
import AddCircleOutline from "@material-ui/icons/AddCircleOutline";
import firebase from "../Config/Firebase";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import AdminNavbar from "./AdminNavbar";

export class CampaignsSorting extends Component {
  constructor(props) {
    super(props);

    this.state = {
      treeData: [],
      sectorModal: false,
      sectorModalCategory: false,
      sectorName: "",
      sectors: [],
      currentSector: "",
      currentSectorId: "",
      categoryName: "",
      currentSector: "",
      categoryId: "",
      all_categories: [],
      sortingTab: true,
      adjustingTab: false,
      completeMainTitles: [],
      arrOfInputs: [],
      kortingsInfo: "",
      charityInfo: "",
      lastMinuteInfo: "",
      giveawayInfo: "",
      webShopInfo: "",
      liveEventInfo: "",
      localEventInfo: "",
      globalOverlay: "",
      orderSuccessOverlay: "",
      kortingsInfoMobile: "",
      charityInfoMobile: "",
      giveawayInfoMobile: "",
      webShopInfo: "",
      webShopInfoMobile: "",
      localEventInfoMobile: "",
      showGlobalTimer: false,
      currentDateGlobal: "",
      currentTimeGlobal: "",
      allTimes: [
        { label: "01:00" },
        { label: "01:15" },
        { label: "01:30" },
        { label: "01:45" },
        { label: "02:00" },
        { label: "02:15" },
        { label: "02:30" },
        { label: "02:45" },
        { label: "03:00" },
        { label: "03:15" },
        { label: "03:30" },
        { label: "03:45" },
        { label: "04:00" },
        { label: "04:15" },
        { label: "04:30" },
        { label: "04:45" },
        { label: "05:00" },
        { label: "05:15" },
        { label: "05:30" },
        { label: "05:45" },
        { label: "06:00" },
        { label: "06:15" },
        { label: "06:30" },
        { label: "06:45" },
        { label: "07:00" },
        { label: "07:15" },
        { label: "07:30" },
        { label: "07:45" },
        { label: "08:00" },
        { label: "08:15" },
        { label: "08:30" },
        { label: "08:45" },
        { label: "09:00" },
        { label: "09:15" },
        { label: "09:30" },
        { label: "09:45" },
        { label: "10:00" },
        { label: "10:15" },
        { label: "10:30" },
        { label: "10:45" },
        { label: "11:00" },
        { label: "11:15" },
        { label: "11:30" },
        { label: "11:45" },
        { label: "12:00" },
        { label: "12:15" },
        { label: "12:30" },
        { label: "12:45" },
        { label: "13:00" },
        { label: "13:15" },
        { label: "13:30" },
        { label: "13:45" },
        { label: "14:00" },
        { label: "14:15" },
        { label: "14:30" },
        { label: "14:45" },
        { label: "15:00" },
        { label: "15:15" },
        { label: "15:30" },
        { label: "15:45" },
        { label: "16:00" },
        { label: "16:15" },
        { label: "16:30" },
        { label: "16:45" },
        { label: "17:00" },
        { label: "17:15" },
        { label: "17:30" },
        { label: "17:45" },
        { label: "18:00" },
        { label: "18:15" },
        { label: "18:30" },
        { label: "18:45" },
        { label: "19:00" },
        { label: "19:15" },
        { label: "19:30" },
        { label: "19:45" },
        { label: "20:00" },
        { label: "20:15" },
        { label: "20:30" },
        { label: "20:45" },
        { label: "21:00" },
        { label: "21:15" },
        { label: "21:30" },
        { label: "21:45" },
        { label: "22:00" },
        { label: "22:15" },
        { label: "22:30" },
        { label: "22:45" },
        { label: "23:00" },
        { label: "23:15" },
        { label: "23:30" },
        { label: "23:45" },
        { label: "24:00" },
      ],
      localCampaignInfo: "",
      localEventInfo: "",
      webShopInfo: "",
      kortingsVoucherInfo: "",
      localCampaignInfoMobile: "",
      kortingsVoucherInfoMobile: "",
    };
  }

  componentDidMount() {
    const loaderId = document.getElementById("main-page-loader-id");
    loaderId.style.display = "none";

    let newDateLocalEvent = new Date();
    let monthTodayLocalEvent = newDateLocalEvent.getMonth() + 1;
    let dateTodayToMakeLocalEvent = newDateLocalEvent.getDate();
    let makeTodayLocalEvent = `${newDateLocalEvent.getFullYear()}-${monthTodayLocalEvent}-${dateTodayToMakeLocalEvent}`;
    this.setState({ makeTodayLocalEvent })

    firebase
      .database()
      .ref("campaigns_info")
      .on("value", (data) => {
        let a = data.val();
        if (a) {
          if (a.kortingsVoucherInfo) {
            this.setState({ kortingsVoucherInfo: a.kortingsVoucherInfo });
          }
          if (a.webShopInfo) {
            this.setState({ webShopInfo: a.webShopInfo });
          }
          if (a.localEventInfo) {
            this.setState({ localEventInfo: a.localEventInfo });
          }
        }
      });

    if (this.props.global_values) {
      let a = this.props.global_values;
      if (a) {
        if (a.globalOverlay) {
          this.setState({
            globalOverlay: a.globalOverlay,
            orderSuccessOverlay: a.orderSuccessOverlay,
          });
        }

        if (a.kortingsVoucherInfoMobile) {
          this.setState({ kortingsVoucherInfoMobile: a.kortingsVoucherInfoMobile });
        }
        if (a.webShopInfoMobile) {
          this.setState({ webShopInfoMobile: a.webShopInfoMobile });
        }
        if (a.localEventInfoMobile) {
          this.setState({ localEventInfoMobile: a.localEventInfoMobile });
        }
        if (a.showGlobalTimer) {
          this.setState({ showGlobalTimer: a.showGlobalTimer });
        } else {
          this.setState({ showGlobalTimer: false })
        }
        if (a.globalTimerDate) {
          this.setState({ currentDateGlobal: a.globalTimerDate })
        }
        if (a.globalTimerTime) {
          this.setState({ currentTimeGlobal: a.globalTimerTime })
        }
      }
    }

    firebase
      .database()
      .ref("bhodi_home")
      .on("value", (data) => {
        let a = data.val();
        if (a) {
          this.props.updateGlobalStuff(a);
          if (a.globalOverlay) {
            this.setState({
              globalOverlay: a.globalOverlay,
              orderSuccessOverlay: a.orderSuccessOverlay,
            });
          }

          if (a.kortingsInfoMobile) {
            this.setState({ kortingsInfoMobile: a.kortingsInfoMobile });
          }
          if (a.kortingsVoucherInfoMobile) {
            this.setState({ kortingsVoucherInfoMobile: a.kortingsVoucherInfoMobile });
          }
          if (a.charityInfoMobile) {
            this.setState({ charityInfoMobile: a.charityInfoMobile });
          }
          if (a.giveawayInfoMobile) {
            this.setState({ giveawayInfoMobile: a.giveawayInfoMobile });
          }
          if (a.webShopInfo) {
            this.setState({ webShopInfo: a.webShopInfo });
          }
          if (a.webShopInfoMobile) {
            this.setState({ webShopInfoMobile: a.webShopInfoMobile });
          }
          if (a.localEventInfoMobile) {
            this.setState({ localEventInfoMobile: a.localEventInfoMobile });
          }
          if (a.localCampaignInfoMobile) {
            this.setState({ localCampaignInfoMobile: a.localCampaignInfoMobile });
          }
          if (a.showGlobalTimer) {
            this.setState({ showGlobalTimer: a.showGlobalTimer });
          } else {
            this.setState({ showGlobalTimer: false })
          }
          if (a.globalTimerDate) {
            this.setState({ currentDateGlobal: a.globalTimerDate })
          }
          if (a.globalTimerTime) {
            this.setState({ currentTimeGlobal: a.globalTimerTime })
          }
        }
      });

    firebase
      .database()
      .ref("all_sectors")
      .on("value", (data) => {
        let a = data.val();
        let arr = [];
        if (a) {
          let objected = Object.entries(a);
          objected.map((g) => {
            arr.push(g[1]);
          });
          if (arr.length > 0) {
            if (this.state.currentSectorId) {
              this.setState({ sectors: arr });
            } else {
              this.setState({
                sectors: arr,
                currentSector: arr[0],
                currentSectorId: arr[0].sector_id,
              });
            }
            firebase
              .database()
              .ref("bhodi_campaigns_category")
              .on("value", (data) => {
                let a = data.val();
                let arrSec = [];
                if (a) {
                  let objected = Object.entries(a);
                  console.log(objected, "/objected came");
                  objected.map((g) => {
                    if (g[0] === this.state.currentSectorId) {
                      arrSec = g[1];
                    }
                  });
                  let completeMainTitles = [];
                  let arrOfInputs = [];
                  if (this.state.arrOfInputs.length > 0) {
                    if (this.state.treeData.length !== arrSec.length) {
                      arrSec.map((g) => {
                        let obj = {
                          inputOpened: false,
                        };
                        arrOfInputs.push(obj);
                      });
                    } else {
                      arrOfInputs = this.state.arrOfInputs;
                    }
                  } else {
                    arrSec.map((g) => {
                      let obj = {
                        inputOpened: false,
                      };
                      arrOfInputs.push(obj);
                    });
                  }
                  console.log(arrSec, "/arrsec to show");
                  this.setState({
                    all_categories: objected,
                    treeData: arrSec,
                    completeMainTitles,
                    arrOfInputs,
                  });
                } else {
                  this.setState({ all_categories: [] });
                }
              });
          }
        } else {
          this.setState({ sectors: [] });
        }
      });
  }

  createSector() {
    const { sectorName } = this.state;
    let timestamp = +new Date();
    let id = sectorName + "" + timestamp;
    let pushKey = firebase.database().ref("all_sectors").push().key;
    let obj = {
      sector_name: sectorName,
      sector_id: id,
      pushKey,
    };
    firebase
      .database()
      .ref("all_sectors/" + pushKey)
      .set(obj)
      .then(() => {
        document.getElementById("root").style.position = "static";
        document.getElementById("root").style.overflow = "auto";
        this.setState({ sectorName: "", sectorModal: false });
      });
  }

  createSectorCategory() {
    const { categoryName, categoryId, currentSectorId } = this.state;
    let timestamp = +new Date();
    let id = categoryName + "" + timestamp;
    let pushKey = firebase
      .database()
      .ref("bhodi_campaigns_category")
      .push().key;
    let obj = {
      title: categoryName,
      category_id: id,
      sector_id: currentSectorId,
      expanded: true,
      category_main_id: categoryId,
    };
    this.state.treeData.push(obj);
    firebase
      .database()
      .ref("bhodi_campaigns_category/" + currentSectorId)
      .set(this.state.treeData)
      .then(() => {
        document.getElementById("root").style.position = "static";
        document.getElementById("root").style.overflow = "auto";
        this.setState({
          categoryName: "",
          sectorModalCategory: false,
          categoryId: "",
        });
      });
  }

  saveCategoryData() {
    const { currentSectorId } = this.state;
    firebase
      .database()
      .ref("bhodi_campaigns_category/" + currentSectorId)
      .set(this.state.treeData);
  }

  checkForIcon(type) {
    let filtered = this.state.treeData.filter((e) => {
      return e.category_main_id === type;
    });
    if (filtered.length > 0) {
      return <CheckCircleIcon />;
    } else {
      return <AddCircleOutline />;
    }
  }

  createAutoCampaign(type) {
    let filtered = this.state.treeData.filter((e) => {
      return e.category_main_id === type;
    });
    const { currentSectorId } = this.state;
    if (filtered.length === 0) {
      let categoryName = "";
      if (type === "charity") {
        categoryName = "Charity";
      } else if (type === "giveaway") {
        categoryName = "Giveaway";
      } else if (type === "doorlopende") {
        categoryName = "Kortingsvoucher";
      } else if (type === "last_minute") {
        categoryName = "Lastminute voucher";
      } else if (type === "web_shop") {
        categoryName = "Webshop code";
      } else if (type === "live_event") {
        categoryName = "Live Event";
      } else if (type === "local_event") {
        categoryName = "Local Event";
      } else if (type === "local_campaign") {
        categoryName = "Local";
      } else if (type === "new_kortingsvoucher") {
        categoryName = "Kortingsvoucher";
      } else if (type === "new_webshop") {
        categoryName = "Coupon codes";
      }
      let timestamp = +new Date();
      let id = categoryName + "" + timestamp;
      let pushKey = firebase
        .database()
        .ref("bhodi_campaigns_category")
        .push().key;
      let obj = {
        title: categoryName,
        category_id: id,
        sector_id: currentSectorId,
        expanded: true,
        category_main_id: type,
      };
      this.state.treeData.push(obj);
      firebase
        .database()
        .ref("bhodi_campaigns_category/" + currentSectorId)
        .set(this.state.treeData)
        .then(() => {
          document.getElementById("root").style.position = "static";
          document.getElementById("root").style.overflow = "auto";
          this.setState({
            categoryName: "",
            sectorModalCategory: false,
            categoryId: "",
          });
        });
    } else {
      let data = [...this.state.treeData];
      data.map((y, i) => {
        if (y.category_main_id === type) {
          data.splice(i, 1);
        }
      });
      firebase
        .database()
        .ref("bhodi_campaigns_category/" + currentSectorId)
        .set(data)
        .then(() => {
          document.getElementById("root").style.position = "static";
          document.getElementById("root").style.overflow = "auto";
          this.setState({
            categoryName: "",
            sectorModalCategory: false,
            categoryId: "",
          });
        });
    }
  }

  saveCampaignInfo() {
    const {
      localEventInfo,
      localEventInfoMobile,
      webShopInfo,
      webShopInfoMobile,
      kortingsVoucherInfo,
      kortingsVoucherInfoMobile,
    } = this.state;
    firebase.database().ref("campaigns_info/webShopInfo").set(webShopInfo);
    firebase
      .database()
      .ref("campaigns_info/localEventInfo")
      .set(localEventInfo);
    firebase
      .database()
      .ref("campaigns_info/kortingsVoucherInfo")
      .set(kortingsVoucherInfo);

    firebase
      .database()
      .ref("bhodi_home/kortingsVoucherInfoMobile")
      .set(kortingsVoucherInfoMobile);
    firebase
      .database()
      .ref("bhodi_home/localEventInfoMobile")
      .set(localEventInfoMobile);
    firebase
      .database()
      .ref("bhodi_home/webShopInfoMobile")
      .set(webShopInfoMobile);
  }

  uploadBannerImageBackground(e) {
    let files = e.target.files[0];
    let storageRef = firebase
      .storage()
      .ref()
      .child(`bhodi_home_media/${files.name}${new Date().getTime()}`);
    storageRef.put(files).then((snapshot) => {
      snapshot.ref.getDownloadURL().then((snapUrl) => {
        firebase
          .database()
          .ref("bhodi_home/orderSuccessBackground/")
          .set(snapUrl)
          .then(() => { });
      });
    });
  }

  render() {
    return (
      <div className="sorting-mainadmin">
        <AdminNavbar campaignsSorting={true} />
        <div className="container-sma">
          <div className="top-container-sma">
            <div className="sector-tcsma">
              <select
                onChange={(e) => {
                  this.setState({
                    currentSector: JSON.parse(e.target.value),
                    currentSectorId: JSON.parse(e.target.value).sector_id,
                  });
                  let filtered = [];
                  this.state.all_categories.map((j) => {
                    if (j[0] === JSON.parse(e.target.value).sector_id) {
                      filtered = j[1];
                    }
                  });
                  this.setState({ treeData: filtered });
                }}
              >
                {this.state.sectors.length > 0 &&
                  this.state.sectors.map((g) => {
                    return (
                      <option
                        value={JSON.stringify(g)}
                        defaultChecked={
                          this.state.currentSectorId === g.sector_id
                        }
                      >
                        {g.sector_name}
                      </option>
                    );
                  })}
              </select>
              <ArrowDropDownIcon className="dropdown-select-tcsma" />
            </div>
            <div className="category-tcsma">
              <p
                onClick={() => {
                  document.getElementById("root").style.position = "fixed";
                  document.getElementById("root").style.overflow = "hidden";
                  this.setState({ sectorModal: true });
                }}
              >
                <AddIcon /> SECTOR{" "}
              </p>
              <p
                onClick={() => {
                  document.getElementById("root").style.position = "fixed";
                  document.getElementById("root").style.overflow = "hidden";
                  this.setState({ sectorModalCategory: true });
                }}
              >
                <AddIcon /> CATEGORIE{" "}
              </p>
              <p onClick={this.saveCategoryData.bind(this)}>SAVE</p>
            </div>
          </div>
          <div className="tabs-container-sma">
            <div className="category-tcsma">
              <p
                className={this.state.sortingTab && "active"}
                onClick={() =>
                  this.setState({ sortingTab: true, adjustingTab: false })
                }
              >
                SORTING
              </p>
              <p
                className={this.state.adjustingTab && "active"}
                onClick={() =>
                  this.setState({ adjustingTab: true, sortingTab: false })
                }
              >
                ADJUSTING
              </p>
            </div>
          </div>
          {this.state.sortingTab && (
            <div className="tree-inner" style={{ height: 1200, marginTop: 30 }}>
              <SortableTree
                treeData={this.state.treeData}
                onChange={(treeData) => {
                  this.setState({ treeData });
                }}
                maxDepth={1}
              />
            </div>
          )}
          {this.state.adjustingTab && (
            <div className="tree-inner" style={{ marginTop: 30 }}>
              <ul className="adjustng-ti">
                {this.state.treeData.map((h, i) => {
                  return (
                    <li
                      onClick={() => {
                        this.state.arrOfInputs[i].inputOpened = true;
                        this.setState({ arrOfInputs: this.state.arrOfInputs });
                      }}
                    >
                      {!this.state.arrOfInputs[i].inputOpened ? (
                        <font>{h.title}</font>
                      ) : (
                        <input
                          type="text"
                          value={h.title}
                          autoFocus
                          onBlur={() => {
                            this.state.arrOfInputs[i].inputOpened = false;
                            this.setState({
                              arrOfInputs: this.state.arrOfInputs,
                            });
                          }}
                          onChange={(e) => {
                            this.state.treeData[i].title = e.target.value;
                            const { currentSectorId } = this.state;
                            firebase
                              .database()
                              .ref(
                                "bhodi_campaigns_category/" + currentSectorId
                              )
                              .set(this.state.treeData);
                          }}
                        />
                      )}{" "}
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          {this.state.adjustingTab && (
            <div className="info-div-editor">
              <div className="group-textbox" style={{ textAlign: "center" }}>
                <label style={{ fontSize: 16, fontWeight: 600 }}>
                  CAMPAIGNS INFO
                </label>
              </div>
              <div className="group-textbox" style={{ flexDirection: "row", alignItems: "center" }}>
                <input type="checkbox" style={{ width: 30, height: 30, marginRight: 10 }} checked={this.state.showGlobalTimer} onChange={(e) => {
                  this.setState({ showGlobalTimer: !this.state.showGlobalTimer })
                  if (e.target.checked) {
                    firebase.database().ref("bhodi_home/showGlobalTimer").set(true)
                  } else {
                    firebase.database().ref("bhodi_home/showGlobalTimer").set(false)
                  }
                }} />
                <label style={{ marginBottom: 0 }}>SHOW TIMER GLOBAL PAGE</label>
              </div>
              <div className="group-textbox">
                <label>TIMER GLOBAL PAGE DATE</label>
                <input type="date" min={this.state.makeTodayLocalEvent} value={this.state.currentDateGlobal} onChange={(e) => {
                  this.setState({ currentDateGlobal: e.target.value })
                  firebase.database().ref("bhodi_home/globalTimerDate").set(e.target.value)
                }} />
              </div>
              <div className="group-textbox">
                <label>TIMER GLOBAL PAGE TIME</label>
                <select value={this.state.currentTimeGlobal} onChange={(e) => {
                  this.setState({ currentTimeGlobal: e.target.value })
                  firebase.database().ref("bhodi_home/globalTimerTime").set(e.target.value)
                }}>
                  {this.state.allTimes.map((e) => {
                    return (<option value={e.label}>{e.label}</option>)
                  })}
                </select>
                {/* <input type="date" min={this.state.makeTodayLocalEvent} value={this.state.currentTimeGlobal} onChange={(e) => {
                  this.setState({ currentDateGlobal: e.target.value })
                  firebase.database().ref("bhodi_home/globalTimerDate").set(e.target.value)
                }} /> */}
              </div>
              <div className="group-textbox">
                <label>LOCAL EVENT CAMPAIGN (Add/Edit Campaign)</label>
                <textarea
                  value={this.state.localEventInfo}
                  onChange={(e) => {
                    this.setState({ localEventInfo: e.target.value });
                  }}
                ></textarea>
              </div>
              <div className="group-textbox">
                <label>DOORLOPEND (Add/Edit Campaign)</label>
                <textarea
                  value={this.state.kortingsVoucherInfo}
                  onChange={(e) => {
                    this.setState({ kortingsVoucherInfo: e.target.value });
                  }}
                ></textarea>
              </div>
              <div className="group-textbox">
                <label>COUPON CODE (Add/Edit Campaign)</label>
                <textarea
                  value={this.state.webShopInfo}
                  onChange={(e) => {
                    this.setState({ webShopInfo: e.target.value });
                  }}
                ></textarea>
              </div>
              <div className="group-textbox">
                <label>GLOBAL OVERLAY</label>
                <select
                  value={this.state.globalOverlay}
                  style={{
                    padding: 6,
                    borderRadius: 4,
                    fontSize: 13,
                    letterSpacing: 1,
                    backgroundColor: "#f7f7f7",
                  }}
                  onChange={(e) => {
                    this.setState({ globalOverlay: e.target.value });
                    firebase
                      .database()
                      .ref("bhodi_home/globalOverlay")
                      .set(e.target.value);
                  }}
                >
                  <option value="0.1">0.1</option>
                  <option value="0.2">0.2</option>
                  <option value="0.3">0.3</option>
                  <option value="0.4">0.4</option>
                  <option value="0.5">0.5</option>
                  <option value="0.6">0.6</option>
                  <option value="0.7">0.7</option>
                  <option value="0.8">0.8</option>
                  <option value="0.9">0.9</option>
                </select>
              </div>
              <div className="group-textbox">
                <label>ORDER SUCCESS BG</label>
                <input
                  type="file"
                  style={{ padding: 15, border: "1px solid gray" }}
                  accept="image/*"
                  onChange={(e) => this.uploadBannerImageBackground(e)}
                />
                {/* <input type="file" onChange={(e) => this.uploadMediaLiveEvent()} /> */}
              </div>
              <div className="group-textbox">
                <label>ORDER SUCCESS OVERLAY</label>
                <select
                  value={this.state.orderSuccessOverlay}
                  style={{
                    padding: 6,
                    borderRadius: 4,
                    fontSize: 13,
                    letterSpacing: 1,
                    backgroundColor: "#f7f7f7",
                  }}
                  onChange={(e) => {
                    this.setState({ orderSuccessOverlay: e.target.value });
                    firebase
                      .database()
                      .ref("bhodi_home/orderSuccessOverlay")
                      .set(e.target.value);
                  }}
                >
                  <option value="0.1">0.1</option>
                  <option value="0.2">0.2</option>
                  <option value="0.3">0.3</option>
                  <option value="0.4">0.4</option>
                  <option value="0.5">0.5</option>
                  <option value="0.6">0.6</option>
                  <option value="0.7">0.7</option>
                  <option value="0.8">0.8</option>
                  <option value="0.9">0.9</option>
                </select>
              </div>
              <div className="group-textbox">
                <label>LOCAL EVENT CAMPAIGN MOBILE</label>
                <textarea
                  value={this.state.localEventInfoMobile}
                  onChange={(e) => {
                    this.setState({ localEventInfoMobile: e.target.value });
                  }}
                ></textarea>
              </div>
              <div className="group-textbox">
                <label>KORTINGSVOUCHER MOBILE</label>
                <textarea
                  value={this.state.kortingsVoucherInfoMobile}
                  onChange={(e) => {
                    this.setState({ kortingsVoucherInfoMobile: e.target.value });
                  }}
                ></textarea>
              </div>
              <div className="group-textbox">
                <label>WEBSHOP MOBILE</label>
                <textarea
                  value={this.state.webShopInfoMobile}
                  onChange={(e) => {
                    this.setState({ webShopInfoMobile: e.target.value });
                  }}
                ></textarea>
              </div>
              <div className="group-textbox">
                <button onClick={this.saveCampaignInfo.bind(this)}>SAVE</button>
              </div>
            </div>
          )}
        </div>
        <div
          className="modal-sector-sorting"
          style={{ right: this.state.sectorModal ? 0 : "-100%" }}
        >
          <CloseIcon
            className="close-mss"
            onClick={() => {
              document.getElementById("root").style.position = "static";
              document.getElementById("root").style.overflow = "auto";
              this.setState({ sectorModal: false });
            }}
          />
          <p>Create Sector</p>
          <div className="form-mss">
            <label>Sector name</label>
            <input
              type="text"
              value={this.state.sectorName}
              onChange={(e) => this.setState({ sectorName: e.target.value })}
            />
          </div>
          <div className="form-mss">
            <button onClick={this.createSector.bind(this)}>CREATE</button>
          </div>
        </div>
        <div
          className="modal-sector-overlay"
          style={{
            opacity: this.state.sectorModal ? 0.7 : 0,
            pointerEvents: this.state.sectorModal ? "all" : "none",
          }}
          onClick={() => {
            document.getElementById("root").style.position = "static";
            document.getElementById("root").style.overflow = "auto";
            this.setState({ sectorModal: false });
          }}
        ></div>
        <div
          className="modal-sector-sorting"
          style={{ right: this.state.sectorModalCategory ? 0 : "-100%" }}
        >
          <CloseIcon
            className="close-mss"
            onClick={() => {
              document.getElementById("root").style.position = "static";
              document.getElementById("root").style.overflow = "auto";
              this.setState({ sectorModalCategory: false });
            }}
          />
          <p>Create Category</p>
          <div className="form-mss">
            <label>Category name</label>
            <input
              type="text"
              value={this.state.categoryName}
              onChange={(e) => this.setState({ categoryName: e.target.value })}
            />
          </div>
          <div className="form-mss">
            <label>Category id</label>
            <input
              type="text"
              value={this.state.categoryId}
              onChange={(e) => this.setState({ categoryId: e.target.value })}
            />
          </div>
          <div className="form-mss" style={{ marginBottom: 40 }}>
            <button onClick={this.createSectorCategory.bind(this)}>
              CREATE
            </button>
          </div>
          <div className="addexistingcategory">
            <div
              style={{ display: "flex", cursor: "pointer" }}
              onClick={this.createAutoCampaign.bind(this, "local_event")}
            >
              {this.checkForIcon("local_event")}
              <p>Local Event</p>
            </div>
          </div>
          <div className="addexistingcategory">
            <div
              style={{ display: "flex", cursor: "pointer" }}
              onClick={this.createAutoCampaign.bind(this, "new_kortingsvoucher")}
            >
              {this.checkForIcon("new_kortingsvoucher")}
              <p>Kortingsvoucher</p>
            </div>
          </div>
          <div className="addexistingcategory">
            <div
              style={{ display: "flex", cursor: "pointer" }}
              onClick={this.createAutoCampaign.bind(this, "new_webshop")}
            >
              {this.checkForIcon("new_webshop")}
              <p>Webshop</p>
            </div>
          </div>
        </div>
        <div
          className="modal-sector-overlay"
          style={{
            opacity: this.state.sectorModalCategory ? 0.7 : 0,
            pointerEvents: this.state.sectorModalCategory ? "all" : "none",
          }}
          onClick={() => {
            document.getElementById("root").style.position = "static";
            document.getElementById("root").style.overflow = "auto";
            this.setState({ sectorModalCategory: false });
          }}
        ></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    global_values: state.globalReducers.global_values,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateGlobalStuff: (global_values) =>
      dispatch(updateGlobalStuff(global_values)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignsSorting);
