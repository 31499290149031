import React, { Component } from 'react'
import NavbarMainFullWidth from './NavbarMainFullWidth';
import NavbarMainFullWidthCompany from './NavSecMainFullWidthCompany';
import { history } from '../Config/Routes';
import { connect } from 'react-redux';
import FriendListRightMenu from './FriendListRightMenu';

export class NavbarWrapperVisitor extends Component {

    constructor() {
        super()
        this.state = {
            currentUserHere: "",
        }
    }

    getVoucherEventInfo() {
        let uid = localStorage.getItem("userid");
        if (uid) {
            if (this.props.user) {
                if (this.props.user.length > 0) {
                    let filtered = this.props.user.filter((y) => {
                        return y.user_id === uid;
                    });
                    if (filtered.length > 0) {
                        this.setState({ currentUserHere: filtered[0], currentUserId: uid });
                    }
                }
            }
        }
    }

    componentDidMount() {
        this.getVoucherEventInfo();
    }

    render() {
        return (
            this.state.currentUserHere ? <div>
                {this.state.currentUserHere.type === "company" ? (<NavbarMainFullWidthCompany
                    menuItemsShowing={true}
                    member_id={this.state.currentUserHere.user_name_id}
                    showPosts={() => {
                        localStorage.setItem("onCurrentTabCompany", "showLists");
                        history.push(
                            `/company/${this.state.currentUserHere.user_name_id}`
                        );
                        // Commented by me
                        // window.location.href = `/company/${this.state.currentMemberData.user_name_id}`;
                    }}
                    showFriends={() => {
                        this.setState({ showRightMenuFriends: true });
                    }}
                    showProductsOfFriends={() => {
                        localStorage.setItem(
                            "onCurrentTabCompany",
                            "showproductsfriends"
                        );
                        // Commented by me
                        history.push(
                            `/company/${this.state.currentUserHere.user_name_id}/volgend`
                        );
                    }}
                    showCharity={() => {
                        localStorage.setItem(
                            "onCurrentTabCompany",
                            "showCharity"
                        );
                        // Commented by me
                        history.push(
                            `/company/${this.state.currentUserHere.user_name_id}/charity`
                        );
                        // window.location.href = `/company/${this.state.currentMemberData.user_name_id}/charity`;
                    }}
                    hideMenuWork={false}
                    showFollowIcon={this.props.currentMainMember.type === "company"}
                    currentMainMember={this.props.currentMainMember}
                />) : (<NavbarMainFullWidth
                    member_id={this.state.currentUserHere.user_name_id}
                    linkToVolgend={`/member/${this.state.currentUserHere.user_name_id}/volgend`}
                    linkToCharity={`/member/${this.state.currentUserHere.user_name_id}/charity`}
                    showProducts={() => {
                        localStorage.setItem("onCurrentTab", "products");
                    }}
                    showFriends={() => {
                        this.setState({ showRightMenuFriends: true });
                    }}
                    showCharity={() => {
                        localStorage.setItem("onCurrentTab", "charitypage");
                    }}
                    showFollowIcon={this.props.currentMainMember.type === "company"}
                    currentMainMember={this.props.currentMainMember}

                />)}



                {/* friends list */}
                {this.state.showRightMenuFriends && (
                    <div
                        className="overlay-div-openadd"
                        onClick={() => {
                            this.setState({ showRightMenuFriends: false });
                        }}
                    ></div>
                )}
                {this.state.showRightMenuFriends && (
                    <FriendListRightMenu
                        closePopup={(type) => {
                            if (type === "fromfriendscompletely") {
                                this.setState({ closeCompleteRightMenu: true });
                            }
                            this.setState({ showRightMenuFriends: false });
                        }}
                    />
                )}

            </div> : <div></div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducers.user,
    };
};

export default connect(
    mapStateToProps
)(NavbarWrapperVisitor);
