import React, { Component } from "react";
import "./index.css";

import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles

AOS.init();


export class CompanyLandingsPage extends Component {
  render() {
    return (
      <div>

      </div>
    )
  }
}

export default CompanyLandingsPage;
