import React, { Component } from 'react'
import "./index.css"
import {
    updateAdminOrNot,
    updateGlobalStuff,
} from "../Redux/actions/authActions";
import firebase from "../Config/Firebase";
import { connect } from 'react-redux';
import CloseIcon from "@material-ui/icons/Close";
import swal from "sweetalert";
import PublishIcon from "@material-ui/icons/Publish";
import "./index-new.css"

export class ExclusiveProductsEditor extends Component {

    constructor() {
        super()
        this.state = {
            showForm: false,
            companyGuestStoryTitle: "BHODI",
            companyGuestStoryDate: "23-3-2023",
            companyGuestStoryDesc: "pop up events",
            companyGuestStoryInfo: "play the announcement",
            companyGuestStoryButtonText: "Aanmelden",
            companyGuestStoryVideo: "",
            companyGuestStoryVideoPopup: "",
            specialTextOneStoryGuest: "FASHION",
            specialTextTwoStoryGuest: "WATCHES",
            specialTextThreeStoryGuest: "LIFESTYLE",
            specialTextFourthStoryGuest: "FOOD",
        }
    }

    componentDidMount() {

        // if (window.innerWidth < 1008) {
        document.getElementById("root").style.overflow = "hidden";
        // }

        let uid = localStorage.getItem("userid");
        if (this.props.user) {
            let filtered = this.props.user.filter((g) => {
                return g.user_id === uid
            })
            if (filtered.length > 0) {
                let a = filtered[0]
                if (a.company_products_button_text) {
                    this.setState({
                        companyGuestStoryButtonText: a.company_products_button_text,
                    })
                }
                if (a.company_products_video) {
                    this.setState({
                        companyGuestStoryVideo: a.company_products_video,
                    })
                }
                if (a.company_products_video_popup) {
                    this.setState({
                        companyGuestStoryVideoPopup: a.company_products_video_popup,
                    })
                }
                if (a.company_products_title) {
                    this.setState({
                        companyGuestStoryTitle: a.company_products_title,
                    })
                }
                if (a.company_products_desc) {
                    this.setState({
                        companyGuestStoryDesc: a.company_products_desc,
                    })
                }
                if (a.company_products_info) {
                    this.setState({
                        companyGuestStoryInfo: a.company_products_info,
                    })
                }
                if (a.company_products_date) {
                    this.setState({
                        companyGuestStoryDate: a.company_products_date,
                    })
                }
                if (a.specialTextOneProducts) {
                    this.setState({
                        specialTextOneStoryGuest: a.specialTextOneProducts,
                    })
                }
                if (a.specialTextTwoProducts) {
                    this.setState({
                        specialTextOneStoryGuest: a.specialTextTwoProducts,
                    })
                }
                if (a.specialTextThreeProducts) {
                    this.setState({
                        specialTextOneStoryGuest: a.specialTextThreeProducts,
                    })
                }
                if (a.specialTextFourthProducts) {
                    this.setState({
                        specialTextOneStoryGuest: a.specialTextFourthProducts,
                    })
                }
            }
        }
        firebase.database().ref(`users/${uid}`).on("value", (data) => {
            let a = data.val()
            if (a) {
                if (a.company_products_button_text) {
                    this.setState({
                        companyGuestStoryButtonText: a.company_products_button_text,
                    })
                }
                if (a.company_products_video) {
                    this.setState({
                        companyGuestStoryVideo: a.company_products_video,
                    })
                } else {
                    this.setState({
                        companyGuestStoryVideo: "",
                    })
                }
                if (a.company_products_video_popup) {
                    this.setState({
                        companyGuestStoryVideoPopup: a.company_products_video_popup,
                    })
                } else {
                    this.setState({
                        companyGuestStoryVideoPopup: "",
                    })
                }
                if (a.company_products_title) {
                    this.setState({
                        companyGuestStoryTitle: a.company_products_title,
                    })
                }
                if (a.company_products_desc) {
                    this.setState({
                        companyGuestStoryDesc: a.company_products_desc,
                    })
                }
                if (a.company_products_info) {
                    this.setState({
                        companyGuestStoryInfo: a.company_products_info,
                    })
                }
                if (a.company_products_date) {
                    this.setState({
                        companyGuestStoryDate: a.company_products_date,
                    })
                }
                if (a.specialTextOneProducts) {
                    this.setState({
                        specialTextOneStoryGuest: a.specialTextOneProducts,
                    })
                }
                if (a.specialTextTwoProducts) {
                    this.setState({
                        specialTextOneStoryGuest: a.specialTextTwoProducts,
                    })
                }
                if (a.specialTextThreeProducts) {
                    this.setState({
                        specialTextOneStoryGuest: a.specialTextThreeProducts,
                    })
                }
                if (a.specialTextFourthProducts) {
                    this.setState({
                        specialTextOneStoryGuest: a.specialTextFourthProducts,
                    })
                }
            }
        })

        if (this.props.global_values) {
            let a = this.props.global_values;
            if (a) {
                this.setState({
                    landing_page_banner_top_video: a.landing_page_banner_top_video,

                    landing_page_new_desc: a.landing_page_new_desc,
                    landing_page_new_desc_mobile: a.landing_page_new_desc_mobile,
                    landing_page_new_desc_english: a.landing_page_new_desc_english,
                    landing_page_new_desc_english_mobile: a.landing_page_new_desc_english_mobile,
                    landing_page_new_date: a.landing_page_new_date,
                    landing_page_new_date_mobile: a.landing_page_new_date_mobile,
                    landing_page_new_date_english: a.landing_page_new_date_english,
                    landing_page_new_date_english_mobile: a.landing_page_new_date_english_mobile,

                    landing_page_new_video: a.landing_page_new_video,
                    landing_page_new_video_second: a.landing_page_new_video_second,
                    landing_page_new_video_player: a.landing_page_new_video_player,
                });
            }
        }

        firebase
            .database()
            .ref("bhodi_home")
            .on("value", (data) => {
                let a = data.val();
                if (a) {
                    this.props.updateGlobalStuff(a);
                    this.setState({
                        landing_page_banner_top_video: a.landing_page_banner_top_video,

                        landing_page_new_desc: a.landing_page_new_desc,
                        landing_page_new_desc_mobile: a.landing_page_new_desc_mobile,
                        landing_page_new_desc_english: a.landing_page_new_desc_english,
                        landing_page_new_desc_english_mobile: a.landing_page_new_desc_english_mobile,
                        landing_page_new_date: a.landing_page_new_date,
                        landing_page_new_date_mobile: a.landing_page_new_date_mobile,
                        landing_page_new_date_english: a.landing_page_new_date_english,
                        landing_page_new_date_english_mobile: a.landing_page_new_date_english_mobile,

                        landing_page_new_video: a.landing_page_new_video,
                        landing_page_new_video_second: a.landing_page_new_video_second,
                        landing_page_new_video_player: a.landing_page_new_video_player,
                    });
                }
            });

        let arr = []
        firebase
            .database()
            .ref("subscription_plans")
            .on("child_added", (data) => {
                if (data.val().price !== "0.00") {
                    arr.push(data.val());
                    this.setState({
                        subscription_data: arr,
                        plan_id: arr[0].plan_id,
                        plan_amount: arr[0].price,
                    });
                }
            });
    }

    createMarkupCenteredDesc() {
        if (window.innerWidth > 768) {
            if (this.state.landing_page_new_desc) {
                return { __html: this.state.landing_page_new_desc };
            }
        } else {
            if (this.state.landing_page_new_desc_mobile) {
                return { __html: this.state.landing_page_new_desc_mobile };
            }
        }
    }

    createMarkupCenteredDescEnglish() {
        if (window.innerWidth > 768) {
            if (this.state.landing_page_new_desc_english) {
                return { __html: this.state.landing_page_new_desc_english };
            }
        } else {
            if (this.state.landing_page_new_desc_english_mobile) {
                return { __html: this.state.landing_page_new_desc_english_mobile };
            }
        }
    }

    createMarkupCenteredDate() {
        if (window.innerWidth > 768) {
            if (this.state.landing_page_new_date) {
                return { __html: this.state.landing_page_new_date };
            }
        } else {
            if (this.state.landing_page_new_date_mobile) {
                return { __html: this.state.landing_page_new_date_mobile };
            }
        }
    }

    createMarkupCenteredDateEnglish() {
        if (window.innerWidth > 768) {
            if (this.state.landing_page_new_date_english) {
                return { __html: this.state.landing_page_new_date_english };
            }
        } else {
            if (this.state.landing_page_new_date_english_mobile) {
                return { __html: this.state.landing_page_new_date_english_mobile };
            }
        }
    }

    uploadBannerImage(e) {
        let uid = localStorage.getItem("userid");
        let a = e.target.files[0];
        if (a.size / 1024 / 1024 < 15) {
            this.setState({ bannerImageBigger: false });
            firebase
                .storage()
                .ref()
                .child(`companyvideos/${a.name}${new Date().getTime()}`)
                .put(a)
                .then((snapshot) => {
                    snapshot.ref.getDownloadURL().then((snapUrl) => {
                        firebase
                            .database()
                            .ref("users/" + uid + "/company_guest_story_video")
                            .set(snapUrl);
                        swal({
                            title: "Good job!",
                            text: "Media uploaded successfully!",
                            icon: "success",
                        })
                    });
                });
        } else {
            this.setState({ bannerImageBigger: true });
        }
    }

    uploadBannerImagePopup(e) {
        let uid = localStorage.getItem("userid");
        let a = e.target.files[0];
        if (a.size / 1024 / 1024 < 15) {
            this.setState({ bannerImageBigger: false });
            firebase
                .storage()
                .ref()
                .child(`companyvideos/${a.name}${new Date().getTime()}`)
                .put(a)
                .then((snapshot) => {
                    snapshot.ref.getDownloadURL().then((snapUrl) => {
                        firebase
                            .database()
                            .ref("users/" + uid + "/company_guest_story_video_popup")
                            .set(snapUrl);
                        swal({
                            title: "Good job!",
                            text: "Media uploaded successfully!",
                            icon: "success",
                        })
                    });
                });
        } else {
            this.setState({ bannerImageBigger: true });
        }
    }


    render() {
        let uid = localStorage.getItem("userid");
        return (
            <div className="guest-story-editor">
                <div className='landing-page-new'>
                    <video
                        src={this.state.companyGuestStoryVideo ? this.state.companyGuestStoryVideo : this.state.landing_page_new_video}
                        autoPlay
                        muted
                        loop
                        playsInline
                        className='video-fullpage-lpn'
                        onLoadedData={() => this.setState({ showText: true })}
                    ></video>
                    {(<div className='centered-play-lpn' data-aos="zoom-in" style={{ opacity: !this.state.showForm ? 1 : 0 }}>
                        <div className='logo-cglpn'>
                            <h1>{this.state.companyGuestStoryTitle}</h1>
                            <p>{this.state.companyGuestStoryDesc}</p>
                        </div>
                        {/* <AiFillPlayCircle onClick={() => this.setState({ showVideoPanel: true })} /> */}
                        <h3>
                            {this.state.companyGuestStoryInfo}
                        </h3>
                        <h5>
                            {this.state.companyGuestStoryDate}
                        </h5>
                        <div className='container-bottom-lpn'>
                            <p>{this.state.specialTextOneStoryGuest}</p>
                            <p>|</p>
                            <p>{this.state.specialTextTwoStoryGuest}</p>
                            <p>|</p>
                            <p>{this.state.specialTextThreeStoryGuest}</p>
                            <p>|</p>
                            <p>{this.state.specialTextFourthStoryGuest}</p>
                        </div>
                        <button>{this.state.companyGuestStoryButtonText}</button>
                    </div>)}
                </div>
                <div className="rightmenuaddproduct rightmenuupdateddesigngray">
                    <div
                        className="mid-rmap"
                        style={{ position: "relative", padding: 13 }}
                    >
                        <span>EDITPAGE</span>
                        <CloseIcon
                            onClick={() => {
                                this.props.closePopup()
                            }}
                            style={{
                                cursor: "pointer",
                                position: "absolute",
                                fontSize: 18,
                                top: "50%",
                                transform: "translateY(-50%)",
                                right: 20,
                            }}
                        />
                    </div>
                    <div className="bottom-rmap">
                        <div
                            className="top-img-uploading-rmap"
                            style={{ width: "85%" }}
                        >
                            <p style={{ fontSize: 15 }}>
                                Personaliseer je bedrijfspagina met <br /> een
                                actuele omschrijving en voeg de <br /> perfecte
                                (eigen) foto of video toe
                            </p>
                        </div>
                        <div className="mid-img-uploading-rmap">
                            <div className="form-miur">
                                <label>TITEL</label>
                                <input
                                    type="text"
                                    value={this.state.companyGuestStoryTitle}
                                    onChange={(e) => {
                                        this.setState({
                                            companyGuestStoryTitle: e.target.value,
                                        });
                                    }}
                                    style={{
                                        fontSize: 13,
                                        letterSpacing: 1,
                                        textTransform: "uppercase",
                                    }}
                                />
                            </div>
                            <div className="form-miur">
                                <label>DESC</label>
                                <input
                                    type="text"
                                    value={this.state.companyGuestStoryDesc}
                                    onChange={(e) => {
                                        this.setState({
                                            companyGuestStoryDesc: e.target.value,
                                        });
                                    }}
                                    style={{
                                        fontSize: 13,
                                        letterSpacing: 1,
                                        textTransform: "uppercase",
                                    }}
                                />
                            </div>
                            <div className="form-miur">
                                <label>INFO</label>
                                <input
                                    type="text"
                                    value={this.state.companyGuestStoryInfo}
                                    onChange={(e) => {
                                        this.setState({
                                            companyGuestStoryInfo: e.target.value,
                                        });
                                    }}
                                    style={{
                                        fontSize: 13,
                                        letterSpacing: 1,
                                    }}
                                />
                            </div>
                            <div className="form-miur">
                                <label>DATE</label>
                                <input
                                    type="text"
                                    value={this.state.companyGuestStoryDate}
                                    onChange={(e) => {
                                        this.setState({
                                            companyGuestStoryDate: e.target.value,
                                        });
                                    }}
                                    style={{
                                        fontSize: 13,
                                        letterSpacing: 1,
                                    }}
                                />
                            </div>
                            <div className="form-miur">
                                <label>BUTTON TEXT</label>
                                <input
                                    type="text"
                                    value={this.state.companyGuestStoryButtonText}
                                    onChange={(e) => {
                                        this.setState({
                                            companyGuestStoryButtonText: e.target.value,
                                        });
                                    }}
                                    style={{
                                        fontSize: 13,
                                        letterSpacing: 1,
                                    }}
                                />
                            </div>
                            <div className="form-miur">
                                <label>FIRST TEXT</label>
                                <input
                                    type="text"
                                    value={this.state.specialTextOneStoryGuest}
                                    onChange={(e) => {
                                        this.setState({
                                            specialTextOneStoryGuest: e.target.value,
                                        });
                                    }}
                                    style={{
                                        fontSize: 13,
                                        letterSpacing: 1,
                                    }}
                                />
                            </div>
                            <div className="form-miur">
                                <label>SECOND TEXT</label>
                                <input
                                    type="text"
                                    value={this.state.specialTextTwoStoryGuest}
                                    onChange={(e) => {
                                        this.setState({
                                            specialTextTwoStoryGuest: e.target.value,
                                        });
                                    }}
                                    style={{
                                        fontSize: 13,
                                        letterSpacing: 1,
                                    }}
                                />
                            </div>
                            <div className="form-miur">
                                <label>THIRD TEXT</label>
                                <input
                                    type="text"
                                    value={this.state.specialTextThreeStoryGuest}
                                    onChange={(e) => {
                                        this.setState({
                                            specialTextThreeStoryGuest: e.target.value,
                                        });
                                    }}
                                    style={{
                                        fontSize: 13,
                                        letterSpacing: 1,
                                    }}
                                />
                            </div>
                            <div className="form-miur">
                                <label>FOURTH TEXT</label>
                                <input
                                    type="text"
                                    value={this.state.specialTextFourthStoryGuest}
                                    onChange={(e) => {
                                        this.setState({
                                            specialTextFourthStoryGuest: e.target.value,
                                        });
                                    }}
                                    style={{
                                        fontSize: 13,
                                        letterSpacing: 1,
                                    }}
                                />
                            </div>
                            <div
                                className="form-miur form-miur-buttontype"
                                style={{ position: "relative", overflow: "hidden" }}
                            >
                                <label
                                    style={{ display: "flex", alignItems: "center" }}
                                    className="dolabelsuppercase"
                                >
                                    EIGEN VIDEO
                                </label>
                                <input
                                    type="file"
                                    onChange={(e) => this.uploadBannerImage(e)}
                                    accept="video/*"
                                    style={{
                                        position: "absolute",
                                        width: "100%",
                                        height: "80%",
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        opacity: 0,
                                    }}
                                />
                                <PublishIcon
                                    style={{
                                        cursor: "pointer",
                                        position: "absolute",
                                        right: 5,
                                        bottom: 6,
                                        fontSize: 18,
                                        color: "white",
                                        pointerEvents: "none",
                                    }}
                                    onClick={() =>
                                        this.setState({ addStockImageBanner: true })
                                    }
                                />
                            </div>
                            {this.state.companyGuestStoryVideo && (<div className="image-campaigns-new" style={{ marginTop: 20 }}>
                                <div
                                    className="image-sfcplp"
                                    style={{
                                        backgroundImage: "none", overflow: "hidden"
                                    }}
                                >
                                    <video
                                        muted
                                        src={this.state.companyGuestStoryVideo}
                                        autoPlay
                                        loop
                                        playsInline
                                    ></video>
                                </div>
                                <div
                                    className="editimageupload"
                                    onClick={() => {
                                        let uid = localStorage.getItem("userid");
                                        firebase
                                            .database()
                                            .ref("users/" + uid + "/company_guest_story_video")
                                            .remove();
                                    }}
                                >
                                    <CloseIcon />
                                </div>
                            </div>)}
                            <div
                                className="form-miur form-miur-buttontype"
                                style={{ position: "relative", overflow: "hidden" }}
                            >
                                <label
                                    style={{ display: "flex", alignItems: "center" }}
                                    className="dolabelsuppercase"
                                >
                                    EIGEN VIDEO POPUP
                                </label>
                                <input
                                    type="file"
                                    onChange={(e) => this.uploadBannerImagePopup(e)}
                                    accept="video/*"
                                    style={{
                                        position: "absolute",
                                        width: "100%",
                                        height: "80%",
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        opacity: 0,
                                    }}
                                />
                                <PublishIcon
                                    style={{
                                        cursor: "pointer",
                                        position: "absolute",
                                        right: 5,
                                        bottom: 6,
                                        fontSize: 18,
                                        pointerEvents: "none",
                                        color: "white"
                                    }}
                                    onClick={() =>
                                        this.setState({ addStockImageBanner: true })
                                    }
                                />
                            </div>
                            {this.state.bannerImageBigger && (
                                <div
                                    className="form-miur"
                                    style={{ position: "relative" }}
                                >
                                    <label
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            color: "red",
                                        }}
                                        className="dolabelsuppercase"
                                    >
                                        Size is bigger
                                    </label>
                                </div>
                            )}
                            {this.state.companyGuestStoryVideoPopup && (<div className="image-campaigns-new" style={{ marginTop: 20 }}>
                                <div
                                    className="image-sfcplp"
                                    style={{
                                        backgroundImage: "none", overflow: "hidden"
                                    }}
                                >
                                    <video
                                        muted
                                        src={this.state.companyGuestStoryVideoPopup}
                                        autoPlay
                                        loop
                                        playsInline
                                    ></video>
                                </div>
                                <div
                                    className="editimageupload"
                                    onClick={() => {
                                        let uid = localStorage.getItem("userid");
                                        firebase
                                            .database()
                                            .ref("users/" + uid + "/company_guest_story_video_popup")
                                            .remove();
                                    }}
                                >
                                    <CloseIcon />
                                </div>
                            </div>)}
                            <button className="pink-save-button" onClick={() => {
                                firebase
                                    .database()
                                    .ref("users/" + uid + "/company_products_desc")
                                    .set(this.state.companyGuestStoryDesc);
                                firebase
                                    .database()
                                    .ref("users/" + uid + "/company_products_button_text")
                                    .set(this.state.companyGuestStoryButtonText);
                                firebase
                                    .database()
                                    .ref("users/" + uid + "/specialTextFourthProduct")
                                    .set(this.state.specialTextFourthStoryGuest);
                                firebase
                                    .database()
                                    .ref("users/" + uid + "/specialTextThreeProduct")
                                    .set(this.state.specialTextThreeStoryGuest);
                                firebase
                                    .database()
                                    .ref("users/" + uid + "/specialTextTwoProduct")
                                    .set(this.state.specialTextTwoStoryGuest);
                                firebase
                                    .database()
                                    .ref("users/" + uid + "/specialTextOneProduct")
                                    .set(this.state.specialTextOneStoryGuest);
                                firebase
                                    .database()
                                    .ref("users/" + uid + "/company_products_date")
                                    .set(this.state.companyGuestStoryDate);
                                firebase
                                    .database()
                                    .ref("users/" + uid + "/company_products_info")
                                    .set(this.state.companyGuestStoryInfo);
                                firebase
                                    .database()
                                    .ref("users/" + uid + "/company_products_title")
                                    .set(this.state.companyGuestStoryTitle);

                                swal({
                                    title: "Good job!",
                                    text: "Saved successfully!",
                                    icon: "success",
                                })
                            }}>OPSLAAN</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        global_values: state.globalReducers.global_values,
        user: state.authReducers.user,
        admin_boolean: state.globalReducers.admin_boolean,
        subscription_plans: state.globalReducers.subscription_plans,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateAdminOrNot: (admin_boolean) =>
            dispatch(updateAdminOrNot(admin_boolean)),
        updateGlobalStuff: (global_values) =>
            dispatch(updateGlobalStuff(global_values)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ExclusiveProductsEditor);