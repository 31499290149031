import React, { Component } from "react";
import {
    voucherSectorCategories,
    availablesubcategoriesvouchers,
    availablemaincategoriesvouchers,
} from "../Redux/actions/authActions";
import firebase from "../Config/Firebase";
import { connect } from "react-redux";
import filterIcon from "../assets/filtericonglobalevent.png";
import { BsFilter } from "react-icons/bs";

const axios = require("axios");

let initialState = {
    currentUserCity: "",
    currentCategoryId: "",
    allVouchers: [],
    allCampaigns: [],
    allComapniesToRender: [],
    latitude: "",
    longitude: "",
    allCategories: [],
    availableMainCategories: [],
    availableSubCategories: [],
};

export class FilterIconGlobalNavVoucher extends Component {
    constructor() {
        super();
        this.state = {
            ...initialState,
        }
    }


    getCampaignsData() {
        let uid = localStorage.getItem("userid");

        let promiseForUser = new Promise((res, rej) => {
            let latitude = localStorage.getItem("latitudeAfterLogin");
            let longitude = localStorage.getItem("longitudeAfterLogin");
            if (latitude) {
                this.setState({ latitude, longitude });
            }
            res();
        });

        promiseForUser.then(() => {
            firebase
                .database()
                .ref("users/" + uid)
                .on("value", (data) => {
                    let a = data.val();
                    if (a.latitude && a.longitude && a.type === "user") {
                        var config = {
                            method: "get",
                            url: `https://maps.googleapis.com/maps/api/geocode/json?latlng=${a.latitude},${a.longitude}&key=${process.env.REACT_APP_GEOCODE_KEY}`,
                            headers: {},
                        };

                        axios(config)
                            .then((response) => {
                                if (response.data.results) {
                                    let addresses = response.data.results[0].address_components;
                                    let city = "";
                                    for (var i = 0; i < addresses.length; i++) {
                                        for (var b = 0; b < addresses[i].types.length; b++) {
                                            if (addresses[i].types[b] == "locality") {
                                                city = addresses[i];
                                                break;
                                            }
                                        }
                                    }
                                    firebase
                                        .database()
                                        .ref("users/" + uid + "/city")
                                        .set(city.short_name);
                                }
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    }
                });
        });

        if (this.props.vouchersectors) {
            let objected = Object.entries(this.props.vouchersectors);
            let filtered = objected.filter((u) => {
                return u[0] === this.props.sector;
            });
            if (filtered.length > 0) {
                this.setState({ allCategories: filtered[0][1] });
            }
        }

        let sectorId = this.props.sector;
        firebase
            .database()
            .ref("bhodi_categories_vouchers/" + sectorId)
            .once("value", (data) => {
                let a = data.val();
                this.setState({ allCategories: a });
            });

        let promiseThree = new Promise((res, rej) => {
            res();
        });
        promiseThree.then(() => {
            let promiseFour = new Promise((res, rej) => {
                if (this.props.global_campaigns) {
                    if (this.props.global_campaigns.length > 0) {
                        let arr = [];
                        let allCampaignsCompletedToRender = [];
                        let mainly = this.props.global_campaigns;

                        mainly.map((e) => {
                            e[1].type = "campaign";
                            if (e[1].active) {
                                allCampaignsCompletedToRender.push(e[1]);
                                if (e[1].desc) {
                                    e[1].desc = e[1].desc.substr(0, 200);
                                }
                                if (e[1].campaignType === "kortings_voucher") {
                                    let objectedVerzilveredBy = [];
                                    let num = 0;
                                    if (e[1].verzilvered_by) {
                                        objectedVerzilveredBy = Object.entries(
                                            e[1].verzilvered_by
                                        );
                                        num = this.getVerzilveredQty(objectedVerzilveredBy);
                                    }
                                    let bewaaredArray = [];
                                    if (!e[1].kortingsVoucherUnlimited) {
                                        if (num + bewaaredArray.length < Number(e[1].number)) {
                                            arr.push(e[1]);
                                        }
                                    } else {
                                        arr.push(e[1]);
                                    }
                                }
                                // arr.push(e[1])
                            }
                        });
                        let filtered = arr.filter((y) => {
                            return y.main_sector === sectorId;
                        });
                        this.setState({ allCampaigns: filtered });
                        res();
                    }
                }
            });
            promiseFour.then(() => {
                let combinedArr = [...this.state.allCampaigns];
                let filteredToShow = combinedArr.filter(
                    (v, i, a) => a.findIndex((t) => t.created_by === v.created_by) === i
                );
                let filteredToShowSub = combinedArr.filter(
                    (v, i, a) =>
                        a.findIndex((t) => t.sub_category === v.sub_category) === i
                );
                let allUsers = [];

                let availableSubCategories = [];

                let filteredToShowMain = [];
                combinedArr.map((y) => {
                    if (y.main_category) {
                        if (y.main_category.length > 0) {
                            y.main_category.map((e) => {
                                filteredToShowMain.push(e);
                            });
                        }
                    }
                });
                let availableMainCategories = filteredToShowMain.filter(
                    (v, i, a) => a.findIndex((t) => t === v) === i
                );
                filteredToShowSub.map((e) => {
                    if (e.sub_category) {
                        availableSubCategories.push(e.sub_category);
                    }
                });
                filteredToShow.map((y) => {
                    let current = this.props.user.filter((u) => {
                        return u.user_id === y.created_by;
                    });
                    if (current[0].latitude && current[0].longitude) {
                        let lat1 = this.state.latitude;
                        let lat2 = current[0].latitude;
                        let lon1 = this.state.longitude;
                        let lon2 = current[0].longitude;

                        var rad = function (x) {
                            return (x * Math.PI) / 180;
                        };

                        var R = 6378137; // Earth’s mean radius in meter
                        var dLat = rad(lat2 - lat1);
                        var dLong = rad(lon2 - lon1);
                        var a =
                            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                            Math.cos(rad(lat1)) *
                            Math.cos(rad(lat2)) *
                            Math.sin(dLong / 2) *
                            Math.sin(dLong / 2);
                        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
                        var d = R * c;
                        current[0].distanceToSort = d;
                    } else {
                        current[0].distanceToSort = 99999999;
                    }
                    if (current[0].type === "company") {
                        allUsers.push(current[0]);
                    }
                });
                allUsers.sort((a, b) => {
                    return a.distanceToSort - b.distanceToSort;
                });
                this.props.availablemaincategoriesvouchers(availableMainCategories);
                this.props.availablesubcategoriesvouchers(availableSubCategories);
                this.setState({
                    allComapniesToRender: allUsers,
                    availableMainCategories,
                    availableSubCategories,
                });
            });
        });
    }

    componentDidMount() {
        const loaderId = document.getElementById("main-page-loader-id");
        loaderId.style.display = "none";

        this.getCampaignsData()
    }

    componentWillUnmount() {
        let uid = localStorage.getItem("userid");

        firebase.database().ref("users/" + uid).off()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.sector !== this.props.sector) {
            this.getCampaignsData()
        }
    }

    getVerzilveredQty(e) {
        let arrItems = e;
        if (arrItems.length > 0) {
            Array.prototype.sum = function (prop) {
                var total = 0;
                for (var i = 0, _len = this.length; i < _len; i++) {
                    total += Number(this[i][1][prop].length);
                }
                return total;
            };
            return arrItems.sum("allDates");
        }
    }

    render() {
        return (
            this.props.showAsList ? <li
                style={{
                    border: "none",
                    fontWeight: 600,
                    paddingLeft: 0,
                    marginLeft: 0,
                }}
                onClick={() => {
                    this.props.onPopupOpen(this.state.availableMainCategories, this.state.allCategories)
                }}
            >
                <BsFilter style={{ fontSize: 20 }} />
            </li> : <a
                href="javascript:void(0)"
                className="btn btn-black orderbasketicon btnpopupwork"
                onClick={() => {
                    this.props.onPopupOpen(this.state.availableMainCategories, this.state.allCategories)
                }}
            >
                <img
                    src={filterIcon}
                    style={{ maxWidth: 20, marginTop: 3 }}
                />
            </a>
            // <div className="navglobalvoucheractive">
            //     {this.state.bottomModal && <div className="overlay-mtb"></div>}
            //     <div
            //         className="navbartoshowcaategoryvouchers listsinanotherway"
            //         style={{ backgroundColor: "#FDFDFD" }}
            //     >
            //         <div className="maincategories-nscv">
            //             <div className="lists-nfwpm">
            //                 <ul>
            //                     {this.state.allCategories.length > 0 &&
            //                         this.state.allCategories.map((e) => {
            //                             if (
            //                                 this.state.availableMainCategories.includes(e.category_id)
            //                             ) {
            //                                 return (
            //                                     <li
            //                                         className={
            //                                             this.props.onMainCategory &&
            //                                             this.props.category === e.category_id &&
            //                                             "active"
            //                                         }
            //                                         onClick={() => {
            //                                             if (e.children) {
            //                                                 this.setState({
            //                                                     subCategoriesCurrent: e.children,
            //                                                 });
            //                                                 if (window.innerWidth < 1009) {
            //                                                     document.getElementById("root").style.position =
            //                                                         "fixed";
            //                                                     document.getElementById(
            //                                                         "root"
            //                                                     ).style.overflowY = "hidden";
            //                                                 }
            //                                                 this.setState({
            //                                                     currentCategoryId: e.category_id,
            //                                                     bottomModal: true,
            //                                                     currentCategoryName: e.title,
            //                                                 });
            //                                             } else {
            //                                                 localStorage.setItem(
            //                                                     "subcategoryidProduct",
            //                                                     e.category_id
            //                                                 );
            //                                                 history.push(`/global-vouchers/${this.props.sector}/filterbyCategory/${e.category_id}`)
            //                                                 // window.location.href = `/global-vouchers/${this.props.sector}/filterbyCategory/${e.category_id}`;
            //                                             }
            //                                         }}
            //                                     >
            //                                         {e.title} {e.children && <KeyboardArrowDownIcon />}
            //                                     </li>
            //                                 );
            //                             }
            //                         })}
            //                 </ul>
            //             </div>
            //         </div>
            //     </div>
            // </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducers.user,
        vouchersectors: state.campaignsReducer.vouchersectors,
        maincategoriesvoucher: state.categoriesReducer.globalsectors,
        subcategoriesvoucher: state.categoriesReducer.subcategoriesvoucher,
        global_campaigns: state.campaignsReducer.global_campaigns,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        voucherSectorCategories: (vouchersectors) =>
            dispatch(voucherSectorCategories(vouchersectors)),
        availablemaincategoriesvouchers: (maincategoriesvoucher) =>
            dispatch(availablemaincategoriesvouchers(maincategoriesvoucher)),
        availablesubcategoriesvouchers: (subcategoriesvoucher) =>
            dispatch(availablesubcategoriesvouchers(subcategoriesvoucher)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterIconGlobalNavVoucher);
