import React, { Component } from "react";
import firebase from "../Config/Firebase";
import { connect } from "react-redux";
import {
    updateTitle,
} from "../Redux/actions/authActions";
import { BiBell } from "react-icons/bi";
import NavbarMenuMobileFooterStuff from "./NavbarMenuMobileFooterStuff";
import { mergeAdminEvent } from "../Functions/globalFunctions";

export class RoundIconsNavbarCompany extends Component {
    constructor() {
        super();
        this.state = {
            active: false,
            search: false,
            rightModalSettings: false,
            arrForSearch: [],
            showItems: false,
            showLists: true,
            showFriends: false,
            showProductsOwn: false,
            showFriendstab: false,
            showProductsBookmarked: false,
            showBookmarkTab: false,
            showProductsOthertab: false,
            currentUserMember: "",
            userInfoRight: true,
            typeOfOrder: "incoming",
            showCartOrder: true,
            impressiePhotosWork: [],
            lengthOfOtherCampaigns: [],
            currentUser: "",
            bhodiFaqs: [],
            arrDropdowns: [],
            bhodiAbouts: [],
            arrDropdownsAbouts: [],
            currentUserId: "",
            orderidToUpload: 0,
            currentUserOfPopups: "",
            redeem_code: "",
            guestUserCart: [],
            currentUserHere: "",
            deleteBtnText: "SAVE",
            showDelete: false,
            password: "",
            showPassword: false,
            confirmPassword: "",
            showconfirmPassword: false,
            currentPassword: "",
            showcurrentPassword: false,
            showLists: true,
            liveEventCompany: [],
            notSeenMessages: [],
            charityCount: 0,
            updated: true,
            hideFriendsInfo: false,
        };
    }

    updateTitle() {
        // check for connect paypal
        let route = window.location.pathname;
        if (route.includes("/friends")) {
            this.setState({
                onLandingPageActive: false,
                onStoriesPageActive: false,
                onContactPageActive: false,
                onImpressiePageActive: false,
                onCharityPageActive: false,
                onVolgendPageActive: false,
                onVriendenPageActive: true,
                onProductsPageActive: false,
                onEventsPageActive: false,
            });
        } else if (route.includes("/trending")) {
            this.setState({
                onLandingPageActive: false,
                onStoriesPageActive: false,
                onContactPageActive: false,
                onImpressiePageActive: false,
                onCharityPageActive: false,
                onVolgendPageActive: false,
                onVriendenPageActive: false,
                onTrendingPageActive: true,
                onProductsPageActive: false,
                onEventsPageActive: false,
            });
        } else if (route.includes("/charity")) {
            this.setState({
                onLandingPageActive: false,
                onStoriesPageActive: false,
                onContactPageActive: false,
                onImpressiePageActive: false,
                onCharityPageActive: true,
                onVolgendPageActive: false,
                onEventsPageActive: false,
                onVriendenPageActive: false,
                onProductsPageActive: false,
            });
        } else if (route.includes("/contact")) {
            this.setState({
                onLandingPageActive: false,
                onStoriesPageActive: false,
                onContactPageActive: true,
                onImpressiePageActive: false,
                onCharityPageActive: false,
                onEventsPageActive: false,
                onVolgendPageActive: false,
                onVriendenPageActive: false,
                onProductsPageActive: false,
            });
        } else if (route.includes("/volgend")) {
            this.setState({
                onLandingPageActive: false,
                onStoriesPageActive: false,
                onImpressiePageActive: false,
                onContactPageActive: false,
                onCharityPageActive: false,
                onVolgendPageActive: true,
                onVriendenPageActive: false,
                onEventsPageActive: false,
                onProductsPageActive: false,
            });
        } else if (route.includes("/vrienden")) {
            this.setState({
                onLandingPageActive: false,
                onStoriesPageActive: false,
                onImpressiePageActive: false,
                onVolgendPageActive: false,
                onCharityPageActive: false,
                onContactPageActive: false,
                onVriendenPageActive: true,
                onProductsPageActive: false,
                onEventsPageActive: false,
            });
        } else {
            this.setState({
                onLandingPageActive: true,
                onStoriesPageActive: false,
                onImpressiePageActive: false,
                onCharityPageActive: false,
                onContactPageActive: false,
                onVolgendPageActive: false,
                onVriendenPageActive: false,
                onEventsPageActive: false,
                onProductsPageActive: false,
            });
        }

        let userid = localStorage.getItem("userid");

        firebase
            .database()
            .ref("charity_videos/" + userid)
            .on("value", (data) => {
                let a = data.val();
                if (a) {
                    let objected = Object.entries(a);
                    let filtered = objected.filter((e) => {
                        return !e[1].seen
                    })
                    this.setState({ charityCount: filtered.length });
                }
            });

        if (this.props.message) {
            let allMessages = [];
            this.props.message.map((g) => {
                let objected = Object.entries(g[1]);
                objected.map((y) => {
                    allMessages.push(y[1]);
                });
            });
            let filtered = allMessages.filter((g) => {
                return !g.seen && g.type === "reciever";
            });
            this.setState({ notSeenMessages: filtered });
        } else {
            this.setState({ notSeenMessages: [] });
        }

        this.setState({ currentUserId: userid });

        if (this.props.user) {
            if (this.props.user.length > 0) {
                let filter = this.props.user.filter((u) => {
                    return u.user_name_id === this.props.member_id;
                });

                // let filteredImpressie = [];
                // if (this.props.all_impressies) {
                //     if (this.props.all_impressies.length > 0)
                //         filteredImpressie = this.props.all_impressies.filter((u) => {
                //             return u[0] === filter[0].user_id;
                //         });
                // }
                // this.setState({ impressiePhotosWork: filteredImpressie });

                let filterCurrentHere = this.props.user.filter((u) => {
                    return u.user_id === userid;
                });
                this.setState({ currentUserHere: filterCurrentHere[0] });

                let currentData = filter[0];
                if (filter.length > 0) {
                    if (currentData) {
                        let a = currentData;
                        this.setState({
                            currentUserMember: a,
                            currentUser: a,
                            create_subscription: a.create_subscription,
                            userid: userid,
                            url: a.user_name_id,
                        });
                        if (a.friends) {
                            this.setState({ showFriendstab: true });
                        } else {
                            this.setState({ showFriendstab: false });
                        }
                        if (a.hideFriendsInfo) {
                            this.setState({ hideFriendsInfo: a.hideFriendsInfo });
                        } else {
                            this.setState({ hideFriendsInfo: false });
                        }
                    }
                }
            }
        }

        if (this.props.member_id) {
            firebase
                .database()
                .ref("users")
                .on("value", (data) => {
                    let a = data.val();
                    let objected = Object.entries(a);
                    objected.map((y) => {
                        if (y[1].user_name_id === this.props.member_id) {
                            let a = y[1];
                            this.setState({
                                currentUserMember: a,
                                create_subscription: a.create_subscription,
                                userid: userid,
                                url: a.user_name_id,
                            });
                            if (a.friends) {
                                this.setState({ showFriendstab: true });
                            } else {
                                this.setState({ showFriendstab: false });
                            }
                            if (a.hideFriendsInfo) {
                                this.setState({ hideFriendsInfo: a.hideFriendsInfo });
                            } else {
                                this.setState({ hideFriendsInfo: false });
                            }
                        }
                    });
                });

            let uid = localStorage.getItem("userid");

            let bhodi_campaigns = this.props.global_campaigns;
            if (bhodi_campaigns) {
                if (bhodi_campaigns.length > 0) {
                    let arr = [];
                    let allCampaignsCompleted = [];
                    let allCampaignsCompletedToRender = [];
                    let arrCurrentCampaigns = [];
                    let objected = bhodi_campaigns;
                    objected.map((e) => {
                        e[1].type = "campaign";
                        if (e[1].active) {
                            allCampaignsCompletedToRender.push(e[1]);
                            if (e[1].desc) {
                                e[1].desc = e[1].desc.substr(0, 200);
                            }
                            allCampaignsCompleted.push(e[1]);
                        }
                    });
                    allCampaignsCompleted.map((e) => {
                        e.type = "campaign";
                        if (e.created_by !== uid) {
                            let filtered = this.props.user.filter((f) => {
                                return f.user_id === e.created_by;
                            });
                            if (filtered.length > 0) {
                                let f = filtered[0];
                                if (f.followed_by || f.friends) {
                                    let objectedFriends = [];
                                    if (f.followed_by) {
                                        objectedFriends = Object.entries(f.followed_by);
                                    }
                                    objectedFriends.map((g) => {
                                        if (g[1].user_id === uid) {
                                            if (e.active) {
                                                if (e.desc) {
                                                    e.desc = e.desc.substr(0, 520);
                                                    e.short_desc = e.desc.substr(0, 120);
                                                }
                                                if (e.campaignType !== "charity") {
                                                    e.images = [e.image];
                                                    e.imagesObjected = [
                                                        { image: e.image, pushKey: e.pushKey },
                                                    ];
                                                }
                                                if (e.campaignType === "local_event") {
                                                    if (e.eventForGuestUser) {
                                                        return false;
                                                    }
                                                    if (e.takeFromAdmin) {
                                                        let obj = mergeAdminEvent(e, this.props.global_campaigns)
                                                        e = Object.assign({}, obj)
                                                    }
                                                    let arrTimingsAvailable = [];
                                                    if (e.timings) {
                                                        e.timings.map((y) => {
                                                            if (y.isoStartTime && y.isoEndTime) {
                                                                let checkedInOfCurrentBlock = [];
                                                                if (e.checked_in_by) {
                                                                    Object.entries(e.checked_in_by).map((t) => {
                                                                        if (t[1].event_id === y.eventId) {
                                                                            checkedInOfCurrentBlock.push(t[1]);
                                                                        }
                                                                    });
                                                                }
                                                                let getCurrentDate = new Date();
                                                                if (
                                                                    getCurrentDate.getTime() <
                                                                    new Date(Date.parse(y.isoEndTime)).getTime()
                                                                ) {
                                                                    if (
                                                                        checkedInOfCurrentBlock.length <
                                                                        Number(y.personsAmountEvent)
                                                                    ) {
                                                                        arrTimingsAvailable.push(y);
                                                                    }
                                                                }
                                                            }
                                                        });
                                                    }
                                                    if (arrTimingsAvailable.length > 0) {
                                                        arr.push(e);
                                                    }
                                                }
                                                if (e.campaignType === "web_shop") {
                                                    let objectedVerzilveredBy = [];
                                                    let num = 0;
                                                    if (e.verzilvered_by) {
                                                        objectedVerzilveredBy = Object.entries(
                                                            e.verzilvered_by
                                                        );
                                                        num = this.getVerzilveredQty(
                                                            objectedVerzilveredBy
                                                        );
                                                    }
                                                    let bewaaredArray = [];
                                                    if (!e.voucherUnlimited) {
                                                        if (
                                                            num + bewaaredArray.length <
                                                            Number(e.number)
                                                        ) {
                                                            arr.push(e);
                                                        }
                                                    } else {
                                                        arr.push(e);
                                                    }
                                                }
                                                if (e.campaignType === "kortings_voucher") {
                                                    let objectedVerzilveredBy = [];
                                                    let num = 0;
                                                    if (e.verzilvered_by) {
                                                        objectedVerzilveredBy = Object.entries(
                                                            e.verzilvered_by
                                                        );
                                                        num = this.getVerzilveredQty(objectedVerzilveredBy);
                                                    }
                                                    let bewaaredArray = [];
                                                    if (!e.kortingsVoucherUnlimited) {
                                                        if (num + bewaaredArray.length < Number(e.number)) {
                                                            arr.push(e);
                                                        }
                                                    } else {
                                                        arr.push(e);
                                                    }
                                                }
                                            }
                                        }
                                    });
                                }
                            }
                        }
                        if (e.created_by === uid) {
                            arrCurrentCampaigns.push(e);
                        }
                    });
                    this.setState({ lengthOfOtherCampaigns: arr });
                }
            }
        }
    }

    componentDidMount() {
        this.updateTitle();
        const loaderId = document.getElementById("main-page-loader-id");
        loaderId.style.display = "none";
    }

    getVerzilveredQty(e) {
        let arrItems = e;
        if (arrItems.length > 0) {
            Array.prototype.sum = function (prop) {
                var total = 0;
                for (var i = 0, _len = this.length; i < _len; i++) {
                    total += Number(this[i][1][prop].length);
                }
                return total;
            };
            return arrItems.sum("allDates");
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.title) {
            this.setState({ updated: false });
            this.updateTitle();
            this.props.updateTitle(false);

            setTimeout(() => {
                this.setState({ updated: true });
            }, 250);
        }

        if (prevProps.message !== this.props.message) {
            if (this.props.message) {
                let allMessages = [];
                this.props.message.map((g) => {
                    let objected = Object.entries(g[1]);
                    objected.map((y) => {
                        allMessages.push(y[1]);
                    });
                });
                let filtered = allMessages.filter((g) => {
                    return !g.seen;
                });
                this.setState({ notSeenMessages: filtered });
            } else {
                this.setState({ notSeenMessages: [] });
            }
        }
        if (this.props.global_campaigns !== prevProps.global_campaigns) {
            if (!window.location.href.includes("volgend")) {
                let uid = localStorage.getItem("userid");
                let bhodi_campaigns = this.props.global_campaigns;
                if (bhodi_campaigns) {
                    if (bhodi_campaigns.length > 0) {
                        let arr = [];
                        let allCampaignsCompleted = [];
                        let allCampaignsCompletedToRender = [];
                        let arrCurrentCampaigns = [];
                        let objected = bhodi_campaigns;
                        objected.map((e) => {
                            e[1].type = "campaign";
                            if (e[1].active) {
                                allCampaignsCompletedToRender.push(e[1]);
                                if (e[1].desc) {
                                    e[1].desc = e[1].desc.substr(0, 200);
                                }
                                allCampaignsCompleted.push(e[1]);
                            }
                        });
                        allCampaignsCompleted.map((e) => {
                            e.type = "campaign";
                            if (e.created_by !== uid) {
                                let filtered = this.props.user.filter((f) => {
                                    return f.user_id === e.created_by;
                                });
                                if (filtered.length > 0) {
                                    let f = filtered[0];
                                    if (f.followed_by || f.friends) {
                                        let objectedFriends = [];
                                        if (f.followed_by) {
                                            objectedFriends = Object.entries(f.followed_by);
                                        }
                                        objectedFriends.map((g) => {
                                            if (g[1].user_id === uid) {
                                                if (e.active) {
                                                    if (e.desc) {
                                                        e.desc = e.desc.substr(0, 520);
                                                        e.short_desc = e.desc.substr(0, 120);
                                                    }
                                                    e.images = [e.image];
                                                    e.imagesObjected = [
                                                        { image: e.image, pushKey: e.pushKey },
                                                    ];
                                                    if (e.campaignType === "local_event") {
                                                        if (e.eventForGuestUser) {
                                                            return false;
                                                        }
                                                        if (e.takeFromAdmin) {
                                                            let obj = mergeAdminEvent(e, this.props.global_campaigns)
                                                            e = Object.assign({}, obj)
                                                        }
                                                        let arrTimingsAvailable = [];
                                                        if (e.timings) {
                                                            e.timings.map((y) => {
                                                                if (y.isoStartTime && y.isoEndTime) {
                                                                    let checkedInOfCurrentBlock = [];
                                                                    if (e.checked_in_by) {
                                                                        Object.entries(e.checked_in_by).map((t) => {
                                                                            if (t[1].event_id === y.eventId) {
                                                                                checkedInOfCurrentBlock.push(t[1]);
                                                                            }
                                                                        });
                                                                    }
                                                                    let getCurrentDate = new Date();
                                                                    if (
                                                                        getCurrentDate.getTime() <
                                                                        new Date(Date.parse(y.isoEndTime)).getTime()
                                                                    ) {
                                                                        if (
                                                                            checkedInOfCurrentBlock.length <
                                                                            Number(y.personsAmountEvent)
                                                                        ) {
                                                                            arrTimingsAvailable.push(y);
                                                                        }
                                                                    }
                                                                }
                                                            });
                                                        }
                                                        if (arrTimingsAvailable.length > 0) {
                                                            arr.push(e);
                                                        }
                                                    }
                                                    if (e.campaignType === "web_shop") {
                                                        let objectedVerzilveredBy = [];
                                                        let num = 0;
                                                        if (e.verzilvered_by) {
                                                            objectedVerzilveredBy = Object.entries(
                                                                e.verzilvered_by
                                                            );
                                                            num = this.getVerzilveredQty(
                                                                objectedVerzilveredBy
                                                            );
                                                        }
                                                        let bewaaredArray = [];
                                                        if (!e.voucherUnlimited) {
                                                            if (
                                                                num + bewaaredArray.length <
                                                                Number(e.number)
                                                            ) {
                                                                arr.push(e);
                                                            }
                                                        } else {
                                                            arr.push(e);
                                                        }
                                                    }
                                                    if (e.campaignType === "kortings_voucher") {
                                                        let objectedVerzilveredBy = [];
                                                        let num = 0;
                                                        if (e.verzilvered_by) {
                                                            objectedVerzilveredBy = Object.entries(
                                                                e.verzilvered_by
                                                            );
                                                            num = this.getVerzilveredQty(objectedVerzilveredBy);
                                                        }
                                                        let bewaaredArray = [];
                                                        if (!e.kortingsVoucherUnlimited) {
                                                            if (num + bewaaredArray.length < Number(e.number)) {
                                                                arr.push(e);
                                                            }
                                                        } else {
                                                            arr.push(e);
                                                        }
                                                    }
                                                }
                                            }
                                        });
                                    }
                                }
                            }
                            if (e.created_by === uid) {
                                arrCurrentCampaigns.push(e);
                            }
                        });
                        this.setState({ lengthOfOtherCampaigns: arr });
                    }
                }
            }
        }
    }

    getCountComing(e) {
        let objected = [];

        let newFollowers = 0;
        if (this.state.currentUserMember.newFollowersList) {
            let objectedInner = Object.entries(
                this.state.currentUserMember.newFollowersList
            );
            newFollowers = objectedInner.length;
        }

        let allCounted = objected.length + newFollowers;

        if (allCounted > 0) {
            return <div className="vrienden-round-mobile">
                <BiBell />
            </div>
        }
    }

    render() {
        return (
            !this.props.hideNav &&
            this.state.updated && (
                <div>

                    <div className="new-round-icons-member">
                        <ul style={{ width: "100%" }}>
                            <NavbarMenuMobileFooterStuff fromRoundIcon={true} showTrending={this.state.currentUserMember.user_id === this.state.currentUserId} currentUserMember={this.state.currentUserMember} />
                            {this.state.lengthOfOtherCampaigns.length > 0 && this.state.currentUserMember.user_id === this.state.currentUserId && (
                                <li
                                    className={
                                        this.state.onVolgendPageActive && "active"
                                    }
                                    onClick={() => {
                                        // this.setState({ showProductsFriends: true, showLists: false, doLessHeight: false, showItems: false, showFriends: false, showImpression: false, showVoucher: false, showPosts: false, showProductsBookmarked: false, showCharity: false, showItemsShops: false, showContent: false })
                                        this.props.showProductsOfFriends();
                                    }}
                                >
                                    {this.state.onVolgendPageActive && (<div className="round-active-newmembers"></div>)}
                                    <label>Volgend</label>
                                </li>
                            )}
                            {this.state.currentUserMember.user_id ===
                                this.state.currentUserId && (
                                    <li
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            this.props.showCharity();
                                        }}
                                        className={this.state.onCharityPageActive && "active"}
                                    >
                                        {this.state.charityCount > 0 && (<div className="imginnermcaadd">
                                            <font>{this.state.charityCount}</font>
                                        </div>)}
                                        {this.state.onCharityPageActive && (<div className="round-active-newmembers"></div>)}
                                        <label style={{ cursor: "pointer" }}>Charity</label>
                                    </li>
                                )}
                            {this.state.currentUserMember &&
                                this.state.currentUserMember.user_id ===
                                this.state.currentUserId ? (
                                <li
                                    className={this.state.onVriendenPageActive && "active"}
                                    onClick={() => {
                                        this.props.showFriendsNewUpdated();
                                        // this.props.showFriends();
                                    }}
                                >
                                    {this.getCountComing()}
                                    {this.state.onVriendenPageActive && (<div className="round-active-newmembers"></div>)}
                                    <label>Vrienden </label>
                                </li>
                            ) : (
                                this.state.currentUserMember &&
                                this.state.showFriendstab &&
                                !this.state.hideFriendsInfo && (
                                    <li
                                        className={this.state.onVriendenPageActive && "active"}
                                        onClick={() => {
                                            this.props.showFriends();
                                        }}
                                    >
                                        {this.state.onVriendenPageActive && (<div className="round-active-newmembers"></div>)}
                                        <label>Vrienden</label>
                                    </li>
                                )
                            )}
                        </ul>
                    </div>
                </div>
            )
        );
    }
}

const mapStateToProps = (state) => {
    return {
        title: state.renderReducer.title,
        user: state.authReducers.user,
        album: state.postsReducers.album,
        images: state.postsReducers.images,
        product: state.campaignsReducer.product,
        global_campaigns: state.campaignsReducer.global_campaigns,
        all_impressies: state.globalReducers.all_impressies,
        guest_user_cart: state.cartReducer.guest_user_cart,
        message: state.authReducers.message,
        albumToAdd: state.imagesReducer.albumToAdd,
        albumToAddFriends: state.imagesReducer.albumToAddFriends,
        bhodi_products: state.productsReducers.bhodi_products,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateTitle: (title) => dispatch(updateTitle(title)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RoundIconsNavbarCompany);
