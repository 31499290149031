import React, { Component } from 'react'
import { connect } from 'react-redux';

export class ProductTimer extends Component {
    constructor() {
        super()
        this.state = {
            intervalTimer: "",
            availabilityTime: "",
            productTimerEndOn: "",
        }
    }

    componentDidMount() {

        this.interval = setInterval(
            () => this.setState({ timeCurrent: Date.now() }),
            1000
        );
    }

    getMonthName(i) {
        i = Number(i);
        if (i === 1) {
            return "januari";
        } else if (i === 2) {
            return "februari";
        } else if (i === 3) {
            return "maart";
        } else if (i === 4) {
            return "april";
        } else if (i === 5) {
            return "Mei";
        } else if (i === 6) {
            return "juni";
        } else if (i === 7) {
            return "juli";
        } else if (i === 8) {
            return "augustus";
        } else if (i === 9) {
            return "september";
        } else if (i === 10) {
            return "oktober";
        } else if (i === 11) {
            return "november";
        } else if (i === 12) {
            return "december";
        }
    }

    calculateTimeStamp() {
        if (this.props.availabilityTime) {
            var date = new Date(this.props.availabilityTime);

            let dateFuture = date.getTime();

            let dateNow = new Date().getTime();

            let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

            let days = Math.floor(diffInMilliSeconds / 86400);
            let daysNumber = Math.floor(diffInMilliSeconds / 86400);
            diffInMilliSeconds -= days * 86400;

            let hours = Math.floor(diffInMilliSeconds / 3600) % 24;
            diffInMilliSeconds -= hours * 3600;

            let minutes = Math.floor(diffInMilliSeconds / 60) % 60;
            diffInMilliSeconds -= minutes * 60;

            let seconds = diffInMilliSeconds % 60;

            let difference = "";
            if (days > 0) {
                difference += days === 1 ? `${days}:` : `${days}:`;
            }

            if (days < 10) {
                days = `0${days}`;
            } else {
                days = `${days}`;
            }

            if (hours < 10) {
                hours = `0${hours}`;
            } else {
                hours = `${hours}`;
            }

            if (minutes < 10) {
                minutes = `0${minutes}`;
            } else {
                minutes = `${minutes}`;
            }

            if (seconds < 9) {
                seconds = `0${Math.ceil(seconds)}`;
            } else {
                seconds = `${Math.ceil(seconds)}`;
            }

            if (dateFuture > dateNow) {
                return (
                    <>
                        <p>{new Date(this.props.availabilityTime).getDate()} {this.getMonthName(new Date(this.props.availabilityTime).getMonth() + 1)} | {new Date(this.props.availabilityTime).getHours()}:{new Date(this.props.availabilityTime).getMinutes() > 0 ? new Date(this.props.availabilityTime).getMinutes() : "00"} | {new Date(this.props.availabilityTime).getFullYear()}</p>
                        <div className="timerforliveeventmain">
                            {Number(days) > 0 && (<p>
                                <span>{days}</span>
                                <span>days</span>
                            </p>)}
                            {Number(hours) > 0 && (<p>
                                <span>{hours}</span>
                                <span>hours</span>
                            </p>)}
                            {Number(minutes) > 0 && (<p>
                                <span>{minutes}</span>
                                <span>min</span>
                            </p>)}
                            {Number(seconds) > 0 && (<p>
                                <span>{seconds}</span>
                                <span>sec</span>
                            </p>)}
                        </div>
                    </>
                );
            } else {
                if (this.props.closeScreen) {
                    this.props.closeScreen()
                }
                clearInterval(this.interval);
                return <></>;
            }
        } else {
            return <></>;
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval)
    }

    render() {
        return (
            this.calculateTimeStamp()
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducers.user,
    };
};



export default connect(
    mapStateToProps,
)(ProductTimer);