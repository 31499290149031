import React, { Component } from "react";

export class TimerGlobalPageComponent extends Component {
    componentDidMount() {
        const loaderId = document.getElementById("main-page-loader-id");
        if (loaderId) {
            loaderId.style.display = "none";
        }
        this.interval = setInterval(
            () => this.setState({ timeCurrent: Date.now() }),
            1000
        );
    }

    calculateTimeStamp() {
        var date = new Date(this.props.timeToCalculate);

        let dateFuture = date.getTime();

        let dateNow = new Date().getTime();

        let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

        let days = Math.floor(diffInMilliSeconds / 86400);
        diffInMilliSeconds -= days * 86400;

        let hours = Math.floor(diffInMilliSeconds / 3600) % 24;
        diffInMilliSeconds -= hours * 3600;

        let minutes = Math.floor(diffInMilliSeconds / 60) % 60;
        diffInMilliSeconds -= minutes * 60;

        let seconds = diffInMilliSeconds % 60;

        let difference = "";
        if (days > 0) {
            difference += days === 1 ? `${days}:` : `${days}:`;
        }

        if (days < 10) {
            days = `0${days}`;
        } else {
            days = `${days}`;
        }

        if (hours < 10) {
            hours = `0${hours}`;
        } else {
            hours = `${hours}`;
        }

        if (minutes < 10) {
            minutes = `0${minutes}`;
        } else {
            minutes = `${minutes}`;
        }

        if (seconds < 9) {
            seconds = `0${Math.ceil(seconds)}`;
        } else {
            seconds = `${Math.ceil(seconds)}`;
        }

        if (dateFuture > dateNow) {
            return (
                <div className="timerforliveeventmain">
                    <p>
                        <span>{days}</span>
                        <span>days</span>
                    </p>
                    {/* <p>
                        <span>:</span>
                    </p> */}
                    <p>
                        <span>{hours}</span>
                        <span>hrs</span>
                    </p>
                    {/* <p>
                        <span>:</span>
                    </p> */}
                    <p>
                        <span>{minutes}</span>
                        <span>min</span>
                    </p>
                    {/* <p>
                        <span>:</span>
                    </p> */}
                    <p>
                        <span>{seconds}</span>
                        <span>sec</span>
                    </p>
                </div>
            );
        } else {
            if (this.props.closeScreen) {
                this.props.closeScreen()
            }
            clearInterval(this.interval);
            return <></>;
        }
    }
    
    componentWillUnmount() {
        clearInterval(this.interval)
    }

    render() {
        return this.calculateTimeStamp();
    }
}

export default TimerGlobalPageComponent;
