import React, { Component } from 'react'
import LandingPage from '../LandingPage'
import "./index.css"
import { RiDoubleQuotesR } from 'react-icons/ri'
import { updateGlobalStuff } from '../../Redux/actions/authActions'
import topImage from "../../assets/bewellness-top-image.png"
import { connect } from 'react-redux'
import firebase from "../../Config/Firebase";
import CloseIcon from "@material-ui/icons/Close"
import { MdOutlineStar } from 'react-icons/md'

let allResorts = [
    {
        image: "https://media.bhodi.com/thermen-holiday.jpg",
        title: "Thermen Holiday",
        textFirst: "Een oasa van rust midden in de Randstad.",
        textSecond: "Schiedam",
        linkButton: "https://thermenholiday.nl",
        rating: 3,
    },
    {
        image: "https://media.bhodi.com/thermen-barendrecht.jpg",
        title: "Thermen Barendrecht",
        textFirst: "Zeer stijlvol en prachtig wellnessresort.",
        textSecond: "Barendrecht",
        linkButton: "https://thermenbarendrecht.nl",
        rating: 5,
    },
    {
        image: "https://media.bhodi.com/elysium.jpg",
        title: "Elysium",
        textFirst: "Geniet van 29 sauna's en 29 baden!",
        textSecond: "Bleiswijk",
        linkButton: "https://elysium.nl",
        rating: 4,
    },
    {
        image: "https://media.bhodi.com/veluwsebron.jpg",
        title: "Veluwse Bron",
        textFirst: "Prachtige locatie en uitzicht over een meer.",
        textSecond: "Emst",
        linkButton: "https://veluwsebron.nl",
        rating: 4,
    },
    {
        image: "https://media.bhodi.com/zwaluwhoeve.jpg",
        title: "Zwaluwhoeve",
        textFirst: "LANDELIJK GENIETEN",
        textSecond: "Hierden",
        linkButton: "https://zwaluwhoeve.nl",
        rating: 3,
    },
    {
        image: "https://media.bhodi.com/spapuur.jpg",
        title: "SpaPuur",
        textFirst: "Breed scala aan faciliteiten en kuurtuin.",
        textSecond: "Tilburg",
        linkButton: "https://spapuur.nl",
        rating: 3,
    },
    {
        image: "https://media.bhodi.com/spasense.jpg",
        title: "SpaSense",
        textFirst: "Geniet van de mooiste Kelosauna van Nederland.",
        textSecond: "Geldrop",
        linkButton: "https://spasense.nl",
        rating: 5,
    },
    {
        image: "https://media.bhodi.com/spaweesp.jpg",
        title: "SpaWeesp",
        textFirst: "Mediterrane sferen direct aan de Vecht.",
        textSecond: "Weesp",
        linkButton: "https://spaweesp.nl",
        rating: 3,
    },
    {
        image: "https://media.bhodi.com/spawell.jpg",
        title: "SpaWell",
        textFirst: "Moderne sauna en wellnessfaciliteiten.",
        textSecond: "Peize",
        linkButton: "https://spawell.nl",
        rating: 3,
    },
]

export class ResortsPage extends Component {

    constructor() {
        super()
        this.state = {
            charity_page_team_bhodi_dutch: "",
            charity_page_team_bhodi_english: "",
            charity_page_team_bhodi_dutch_mobile: "",
            charity_page_team_bhodi_english_mobile: "",
            charity_page_team_bhodi_dutch_heading: "",
            charity_page_team_bhodi_english_heading: "",
            charity_page_team_bhodi_dutch_heading_mobile: "",
            charity_page_team_bhodi_english_heading_mobile: "",
            landing_page_footer_image: "",
            hulpNodigPopup: false,
        }
    }


    componentDidMount() {
        const loaderId = document.getElementById("main-page-loader-id");
        loaderId.style.display = "none";

        if (this.props.global_values) {
            let a = this.props.global_values;
            if (a) {
                this.setState({
                    charity_page_team_bhodi_dutch: a.charity_page_team_bhodi_dutch,
                    charity_page_team_bhodi_english: a.charity_page_team_bhodi_english,
                    charity_page_team_bhodi_dutch_heading: a.charity_page_team_bhodi_dutch_heading,
                    charity_page_team_bhodi_english_heading: a.charity_page_team_bhodi_english_heading,
                    // Mobile
                    charity_page_team_bhodi_dutch_mobile: a.charity_page_team_bhodi_dutch_mobile,
                    charity_page_team_bhodi_english_mobile: a.charity_page_team_bhodi_english_mobile,
                    charity_page_team_bhodi_english_heading_mobile: a.charity_page_team_bhodi_english_heading_mobile,
                    charity_page_connect_dutch_heading_mobile: a.charity_page_connect_dutch_heading_mobile,
                    landing_page_footer_image: a.landing_page_footer_image,

                });
            }
        }

        firebase
            .database()
            .ref("bhodi_home")
            .on("value", (data) => {
                let a = data.val();
                if (a) {
                    this.props.updateGlobalStuff(a);
                    this.setState({
                        charity_page_team_bhodi_dutch: a.charity_page_team_bhodi_dutch,
                        charity_page_team_bhodi_english: a.charity_page_team_bhodi_english,
                        charity_page_team_bhodi_dutch_heading: a.charity_page_team_bhodi_dutch_heading,
                        charity_page_team_bhodi_english_heading: a.charity_page_team_bhodi_english_heading,
                        // Mobile
                        charity_page_team_bhodi_dutch_mobile: a.charity_page_team_bhodi_dutch_mobile,
                        charity_page_team_bhodi_english_mobile: a.charity_page_team_bhodi_english_mobile,
                        charity_page_team_bhodi_english_heading_mobile: a.charity_page_team_bhodi_english_heading_mobile,
                        charity_page_connect_dutch_heading_mobile: a.charity_page_connect_dutch_heading_mobile,
                        landing_page_footer_image: a.landing_page_footer_image,
                    })
                }
            });
    }

    createMarkupTeamBhodiDutch() {
        if (window.innerWidth > 768) {
            if (this.state.charity_page_team_bhodi_dutch) {
                return { __html: this.state.charity_page_team_bhodi_dutch };
            }
        } else {
            if (this.state.charity_page_team_bhodi_dutch_mobile) {
                return { __html: this.state.charity_page_team_bhodi_dutch_mobile };
            }
        }
    }

    createMarkupTeamBhodiEnglish() {
        if (window.innerWidth > 768) {
            if (this.state.charity_page_team_bhodi_english) {
                return { __html: this.state.charity_page_team_bhodi_english };
            }
        } else {
            if (this.state.charity_page_team_bhodi_english_mobile) {
                return { __html: this.state.charity_page_team_bhodi_english_mobile };
            }
        }
    }

    createMarkupTeamBhodiDutchHeading() {
        if (window.innerWidth > 768) {
            if (this.state.charity_page_team_bhodi_dutch_heading) {
                return { __html: this.state.charity_page_team_bhodi_dutch_heading };
            }
        } else {
            if (this.state.charity_page_team_bhodi_dutch_heading_mobile) {
                return {
                    __html: this.state.charity_page_team_bhodi_dutch_heading_mobile,
                };
            }
        }
    }

    createMarkupTeamBhodiEnglishHeading() {
        if (window.innerWidth > 768) {
            if (this.state.charity_page_team_bhodi_english_heading) {
                return { __html: this.state.charity_page_team_bhodi_english_heading };
            }
        } else {
            if (this.state.charity_page_team_bhodi_english_heading_mobile) {
                return {
                    __html: this.state.charity_page_team_bhodi_english_heading_mobile,
                };
            }
        }
    }

    render() {
        return (
            <div className='resorts-page-main'>
                <LandingPage onResortsPage={true} onPopupOpen={() => {
                    this.setState({ hulpNodigPopup: true })
                }} />
                <div className='resorts-top-section'>
                    <div className='content-rts-top'>
                        {/* <img src={topLogo} />
                        <h2>Wellnessresorts</h2> */}
                        <img src={topImage} />
                        <p>
                            Elk resort kent haar eigen bijzondere kenmerken en kwaliteiten. De BeWellness collectie biedt een dagje ultieme ontspanning, vol rust en ruimte. Ruimte om te ontspannen en ruimte om je hoofd leeg te maken. Even tijd voor jezelf.
                        </p>
                    </div>
                </div>
                <div className='resorts-block'>
                    <div className='inner-resorts-block'>
                        <div className='resorts-container'>
                            {allResorts.length > 0 && allResorts.map((resort) => {
                                return ((<div className='rc-inner-container'>
                                    <div className='content-ric'>
                                        <div className='content-ric-inner' onClick={() => {
                                            window.open(resort.linkButton, "_blank")
                                        }}>
                                            <div className='stars-counts-main'>
                                                {Array(resort.rating).fill(1).map(() => {
                                                    return (<MdOutlineStar size={17} color='white' />)
                                                })}
                                            </div>
                                            <h3>{resort.title}</h3>
                                            <p>{resort.textFirst}</p>
                                            <button>Bekijk</button>
                                        </div>
                                        <div className='img-cric'>
                                            <img src={resort.image} alt={resort.title} />
                                        </div>
                                    </div>
                                </div>))
                            })}
                        </div>
                    </div>
                </div>
                <div>
                    <div className='sslpb-wrapper'>
                        <div
                            className="sixth-section-lpb"
                            style={{
                                backgroundImage: `url("${this.state.landing_page_footer_image}")`,
                            }}
                        >
                            <div className="container-sslpb">
                                <h2>
                                    {this.state.showDutch ? (
                                        <div
                                            dangerouslySetInnerHTML={this.createMarkupTeamBhodiDutchHeading()}
                                        ></div>
                                    ) : (
                                        <div
                                            dangerouslySetInnerHTML={this.createMarkupTeamBhodiEnglishHeading()}
                                        ></div>
                                    )}
                                </h2>
                                <div className="circle-csslpb">
                                    <RiDoubleQuotesR />
                                </div>
                                <p className="para-desc-sslpb">
                                    Wij wensen jullie een hele fijne dag toe{window.innerWidth < 1008 ? (" ") : (<br />)}
                                    bij één van de bovengenoemde resorts!
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="footer-section-lpb">
                        <div className="container-fslpb">
                            <div className="block-cfslpb">
                                <h3 data-aos="fade-in">Charity</h3>
                                <p data-aos="fade-in">Maaltijden voor kinderen</p>
                            </div>
                            <div className="block-cfslpb">
                                <h3 data-aos="fade-in">Events</h3>
                                <p data-aos="fade-in">Leuke pop-up sale events</p>
                            </div>
                            {window.innerWidth < 1008 ? (<div className="block-cfslpb">
                                <h3>Voorwaarden</h3>
                                <p
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        window.open(
                                            "https://media.bhodi.com/gebruikersvoorwaarden.pdf",
                                            "_blank" // <- This is what makes it open in a new window.
                                        );
                                    }}
                                >
                                    Gebruikersvoorwaarden
                                </p>
                            </div>) : (<div className="block-cfslpb">
                                <h3 data-aos="fade-in">Voorwaarden</h3>
                                <p
                                    style={{ cursor: "pointer" }}
                                    data-aos="fade-in"
                                    onClick={() => {
                                        window.open(
                                            "https://media.bhodi.com/gebruikersvoorwaarden.pdf",
                                            "_blank" // <- This is what makes it open in a new window.
                                        );
                                    }}
                                >
                                    Gebruikersvoorwaarden
                                </p>
                            </div>)}
                        </div>
                    </div>
                </div>
                <div className='about-main-popup' style={{ opacity: this.state.hulpNodigPopup ? 1 : 0, pointerEvents: this.state.hulpNodigPopup ? "all" : "none", zIndex: 99999999999 }}>
                    <div className='abp-right-menu' style={{ right: this.state.hulpNodigPopup ? 0 : "-100%" }}>
                        <div className='white-bg-abp'>
                            <CloseIcon onClick={() => this.setState({ hulpNodigPopup: false })} />
                        </div>
                        <div className='aboutcont-bgimage abgimg-hulpnodig'>
                        </div>
                        <div className='aboutinner-cont-pop'>
                            <h2>Hoe werkt het?</h2>
                            <p className='main-crm-black'></p>
                            <p>
                                Het ontspannen kan écht bijna beginnen. Je hebt de reserveringscode in jouw mail ontvangen en kun je deze gaan verzilveren bij een onderstaand wellnessresort waar het e-ticket geldig is.
                                <br />
                                <br />
                                Ga naar de website van het wellnessresort en klik rechts bovenaan op 'e-ticket verzilveren'. Vul jouw e-ticketcode(s) in, vul je gegevens aan en maak jouw reservering compleet.
                                <br />
                                <br />
                                Wij wensen jullie een hele fijne dag toe!
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        global_values: state.globalReducers.global_values,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateGlobalStuff: (global_values) =>
            dispatch(updateGlobalStuff(global_values)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResortsPage);