import React, { Component } from 'react'
import firebase from '../../Config/Firebase'
import LandingPage from '../LandingPage'
import NewProductWrapper from '../ProductsPage/NewProductsWrapper'
import Footer from '../../Components/Footer'
import CharityLandingPage from '../CharityLandingsPage'
import CartNonUser from '../../Components/CartNonUser'
import FooterBasket from '../../Components/FooterBasket'
import FooterGreen from '../../Components/FooterGreen'
import { connect } from 'react-redux'

export class BrandPage extends Component {

    constructor() {
        super()
        this.state = {
            currentUserId: "",
            currentPage: ""
        }
        this.charityPageRef = React.createRef()
    }

    componentDidMount() {
        let userid = localStorage.getItem("userid")
        this.setState({ currentUserId: userid })


        if (this.props.bhodi_brands && this.props.bhodi_brands.length > 0) {
            let filtered = this.props.bhodi_brands.filter((t) => {
                return t.page_url === this.props.match.params.brand_url
            })
            if (filtered.length > 0) {
                this.setState({ currentPage: filtered[0] })
            }
        }

        firebase.database().ref("bhodi_brands").orderByChild("page_url").equalTo(this.props.match.params.brand_url).on("value", (data) => {
            let a = data.val()
            if (a) {
                let objected = Object.entries(a)
                this.setState({ currentPage: objected[0][1] })
            }
        })
    }

    render() {
        return (
            <div className='specialproductslandingpage'>
                {this.state.currentUserId ? (<CartNonUser isSpecialProduct={true} notNeedOrders={true} />) : (<FooterBasket isSpecialProduct={true} />)}
                {this.state.currentPage ? (<LandingPage onSpecialProducts={true} onBrandsPage={true} currentPageData={this.state.currentPage}
                    moveToCharitySection={() => {
                        this.charityPageRef.current.scrollIntoView({ behavior: 'smooth' });
                    }} />) : (<div style={{ height: "100vh", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#d3d3d3" }}>
                        <div className="lds-ring">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>)}
                <div style={{ paddingBottom: 50 }}>
                    <NewProductWrapper toShowProductOf={process.env.REACT_APP_ADMIN_ID} isSpecialProductsPage={true} productForPage={this.props.match.params.brand_url} />
                </div>
                <div ref={this.charityPageRef}>
                    <CharityLandingPage dontshowMenu={true} onLandingPage={true} isSpecialProductsPage={true} />
                </div>
                {window.innerWidth > 1008 ? (<Footer notOnMainPage={true} />) : (<FooterGreen notOnMainPage={true} />)}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        bhodi_brands: state.globalReducers.bhodi_brands,
    }
}


export default connect(mapStateToProps)(BrandPage)