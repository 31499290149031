import React, { Component } from "react";
import SearchIcon from "@material-ui/icons/Search";
import firebase from "../Config/Firebase";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PersonIcon from "@material-ui/icons/Person";
import { Link } from "react-router-dom";
import {
  updateSignUpFieldsFilled,
  updateGuestUserCart,
  updateGuestUserCartTime,
  updateGuestUserCartExtendOpportunity,
  updateTitle,
  updateBhodiFaqs,
  updateBhodiAbouts,
} from "../Redux/actions/authActions";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import EditIcon from "@material-ui/icons/Edit";
import { connect } from "react-redux";
// import CartOrder from "../Containers/OrdersCart";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import CartNonUserMobile from "./CartNonUserMobile";
import RightMenuOrders from "./RightMenuOrders";
import { IoScanCircleOutline } from "react-icons/io5";
import { BiMenu } from "react-icons/bi";
import { MdOutlinePrivacyTip } from "react-icons/md";
import SettingsSeparateUser from "./SettingsSeparateUser";
import { RiGitRepositoryPrivateLine, RiUserSettingsLine } from "react-icons/ri"
import { TbFolderStar } from "react-icons/tb"
import { history } from "../Config/Routes";
import { FaQ } from "react-icons/fa6";
import { TiInfo } from "react-icons/ti";
import headerBhodiIcon from "../assets/bhodi-header-mobile.png";
import searchpicpng from "../assets/searchiconglobalevent.png";
import { AiOutlineSearch } from "react-icons/ai";
import { BsChevronLeft } from "react-icons/bs";
import { mergeAdminEvent } from "../Functions/globalFunctions";

export class NavbarFullWidthMobile extends Component {
  constructor() {
    super();
    this.state = {
      active: false,
      search: false,
      searchText: "",
      rightModalSettings: false,
      arrForSearch: [],
      checkedPrivate: false,
      checkedHideDonation: false,
      checkedHideFriendsInfo: false,
      checkedHideProfileImage: false,
      checkedHideProfileInfo: false,
      showPostsActive: true,
      showItemsDiv: false,
      showItems: false,
      showLists: true,
      showFriends: false,
      showProductsOwn: false,
      showFriendstab: false,
      showProductsBookmarked: false,
      showBookmarkTab: false,
      showProductsOthertab: false,
      userNameInput: false,
      userDescInput: false,
      lengthOfOtherProducts: [],
      lengthOfOtherVouchers: [],
      lengthOfOtherCampaigns: [],
      userInfoRight: true,
      typeOfOrder: "incoming",
      showCartOrder: true,
      savtBtnText: "SAVE",
      isDesktop: true,
      isScrolled: false,
      menuItemsModals: true,
      hideMenuContent: false,
      lengthOfCurrentContents: [],
      lengthOfOtherContents: [],
      showContentTab: false,
      currentUserMember: "",
      bhodiFaqs: [],
      arrDropdowns: [],
      bhodiAbouts: [],
      arrDropdownsAbouts: [],
      currentUserOfPopups: "",
      redeem_code: "",
      guestUserCart: [],
      deleteBtnText: "SAVE",
      showDelete: false,
      password: "",
      showPassword: false,
      confirmPassword: "",
      showconfirmPassword: false,
      currentPassword: "",
      showcurrentPassword: false,
      notSeenMessages: [],
      showOrderIcon: true,
      toShowAlbum: "",
      onOpgelsagenMain: false,
      onGelikedMain: false,
      toShowAlbumImg: "",
      updated: true,
      commentsReplies: [],
      commentsHere: [],
      maxLimitAlleReacties: 15,
      myownImages: [],
    };
  }

  updateTitle() {
    let rightMenuOpenDefault = localStorage.getItem("mustShowOpenRightMenu");
    if (rightMenuOpenDefault) {
      let element = document.getElementById("footermain");
      if (element) {
        element.style.display = "none";
      }
      this.setState({ rightModalSettings: true });

      document.querySelector("html").style.overflowY = "hidden"
      if (window.innerWidth < 1009) {
        this.setState({ userInfoRight: false, menuItemsModals: true });
      }
      localStorage.removeItem("mustShowOpenRightMenu");
    }

    let userid = localStorage.getItem("userid");
    let uid = localStorage.getItem("userid");
    this.setState({ currentUserId: userid });

    let route = window.location.pathname;
    if (route.includes("/friends")) {
      localStorage.setItem("onCurrentTab", "friend");
      this.setState({
        showLists: false,
        showItems: false,
        showFriends: true,
        showProductsOwn: false,
        showProductsBookmarked: false,
        showCharity: false,
        shopProductsShop: false,
        showContent: false,
      });
    } else if (route.includes("/charity")) {
      localStorage.setItem("onCurrentTab", "charitypage");
      this.setState({
        showLists: false,
        showItems: false,
        showFriends: false,
        showProductsOwn: false,
        showProductsBookmarked: false,
        showCharity: true,
        shopProductsShop: false,
        showContent: false,
      });
    } else if (route.includes("/volgend")) {
      localStorage.setItem("onCurrentTab", "products");
      this.setState({
        showItems: true,
        showLists: false,
        showFriends: false,
        showProductsOwn: false,
        showProductsBookmarked: false,
        showCharity: false,
        shopProductsShop: false,
        showContent: false,
      });
    } else {
      localStorage.setItem("onCurrentTab", "images");
      this.setState({
        showLists: true,
        showItems: false,
        showFriends: false,
        showProductsOwn: false,
        showProductsBookmarked: false,
        showCharity: false,
        shopProductsShop: false,
        showContent: false,
      });
    }

    if (this.props.bhodi_faqs) {
      let a = this.props.bhodi_faqs;
      if (a) {
        let arrSec = a;
        let arrDropdowns = [];
        arrSec.map(() => {
          let obj = {
            opened: false,
          };
          arrDropdowns.push(obj);
        });
        this.setState({ bhodiFaqs: arrSec, arrDropdowns });
      } else {
        this.setState({ bhodiFaqs: [], arrDropdowns: [] });
      }
    }
    if (this.props.bhodi_abouts) {
      let a = this.props.bhodi_abouts;
      if (a) {
        let arrSec = a;
        let arrDropdownsAbouts = [];
        arrSec.map(() => {
          let obj = {
            opened: false,
          };
          arrDropdownsAbouts.push(obj);
        });
        this.setState({ bhodiAbouts: arrSec, arrDropdownsAbouts });
      } else {
        this.setState({ bhodiAbouts: [], arrDropdownsAbouts: [] });
      }
    }

    firebase
      .database()
      .ref("bhodi_faqs")
      .on("value", (data) => {
        let a = data.val();
        let arrSec = [];
        if (a) {
          arrSec = a;
          let arrDropdowns = [];
          arrSec.map(() => {
            let obj = {
              opened: false,
            };
            arrDropdowns.push(obj);
          });
          this.setState({ bhodiFaqs: arrSec, arrDropdowns });
        } else {
          this.setState({ bhodiFaqs: [], arrDropdowns: [] });
        }
        this.props.updateBhodiFaqs(arrSec)
      });

    firebase
      .database()
      .ref("bhodi_abouts_user")
      .on("value", (data) => {
        let a = data.val();
        let arrSec = [];
        if (a) {
          arrSec = a;
          let arrDropdownsAbouts = [];
          arrSec.map(() => {
            let obj = {
              opened: false,
            };
            arrDropdownsAbouts.push(obj);
          });
          this.setState({ bhodiAbouts: arrSec, arrDropdownsAbouts });
        } else {
          this.setState({ bhodiAbouts: [], arrDropdownsAbouts: [] });
        }
        this.props.updateBhodiAbouts(arrSec)
      });

    console.log(this.props.user, "/props/user");
    if (this.props.user) {
      if (this.props.user.length > 0) {
        let filter = this.props.user.filter((u) => {
          return u.user_name_id === this.props.member_id;
        });
        let currentData = filter[0];
        console.log(currentData, "/currentdata");
        if (filter.length > 0) {
          if (currentData) {
            let a = currentData;
            a.objectedFriends = [];
            if (!a.profile_image) {
              a.profile_image =
                "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9";
            }
            this.setState({
              currentUserMember: a,
              create_subscription: a.create_subscription,
              userid: userid,
              url: a.user_name_id,
              firstName: a.first_name,
              lastName: a.last_name,
              city: a.city,
              zipCode: a.zipCode,
              street: a.street,
              houseNumber: a.houseNumber,
              country: a.country,
              phoneNumber: a.phoneNumber,
              email: a.email,
              profileImageUrl: a.profile_image,
              description: a.user_description,
            });
          }
        }
      }
    }

    if (this.props.images) {
      let allImages = this.props.images;
      let filtered = [];
      allImages.map((f) => {
        if (f.created_by === userid && !f.inAlbums) {
          filtered.push(f);
        }
      });
      this.setState({ myownImages: filtered })
    } else {
      this.setState({ myownImages: [] })
    }

    firebase
      .database()
      .ref("users/" + userid)
      .on("value", (data) => {
        let a = data.val();
        if (a) {
          if (a.friends) {
            let objected = Object.entries(a.friends);
            a.objectedFriends = objected;
          } else {
            a.objectedFriends = [];
          }
          if (!a.profile_image) {
            a.profile_image =
              "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9";
          }

          // localStorage.setItem("userdata", JSON.stringify(data.val()))
          this.setState({
            currentUserMember: a,
            create_subscription: a.create_subscription,
            userid: userid,
            url: a.user_name_id,
            firstName: a.first_name,
            lastName: a.last_name,
            city: a.city,
            zipCode: a.zipCode,
            street: a.street,
            houseNumber: a.houseNumber,
            country: a.country,
            phoneNumber: a.phoneNumber,
            email: a.email,
            profileImageUrl: a.profile_image,
            description: a.user_description,
          });
          if (a.accountType === "global") {
            this.setState({ checkedPrivate: false });
          } else {
            this.setState({ checkedPrivate: true });
          }

          let sessionStored = localStorage.getItem("onCurrentTab");
          if (sessionStored) {
            if (sessionStored === "friend") {
              this.setState({
                showLists: false,
                showItems: false,
                showFriends: true,
                showProductsOwn: false,
                showProductsBookmarked: false,
                showCharity: false,
                shopProductsShop: false,
                showContent: false,
              });
            } else if (sessionStored === "charitypage") {
              this.setState({
                showLists: false,
                showItems: false,
                showFriends: false,
                showProductsOwn: false,
                showProductsBookmarked: false,
                showCharity: true,
                shopProductsShop: false,
                showContent: false,
              });
            } else if (sessionStored === "images") {
              this.setState({
                showLists: true,
                showItems: false,
                showFriends: false,
                showProductsOwn: false,
                showProductsBookmarked: false,
                showCharity: false,
                shopProductsShop: false,
                showContent: false,
              });
            }
          }
          let email = a.email;
          let password = a.pass;
          var user = firebase.auth().currentUser;
          if (user && email && password) {
            var credential = firebase.auth.EmailAuthProvider.credential(
              email,
              password
            );
            user.reauthenticateWithCredential(credential);
          }
        }
      });

    if (!window.location.href.includes("/charityConfirmation") && !window.location.href.includes("/exclusive-subscribing")) {
      firebase
        .database()
        .ref("users/" + userid)
        .once("value", (data) => {
          let a = data.val();
          if (a) {
            if (a.user_name_id === this.props.member_id) {
              this.props.updateSignUpFieldsFilled("");
            }
            if (a.create_subscription) {
              this.setState({ create_subscription: a.create_subscription });
            }
            setTimeout(() => {
              firebase
                .database()
                .ref("users/" + userid + "/create_subscription")
                .set(false)
            }, 1000);
          }
        });
    }
  }

  componentDidMount() {
    this.updateTitle();
    const loaderId = document.getElementById("main-page-loader-id");
    loaderId.style.display = "none";
    // if (!window.location.href.includes("charityConfirmation")) {
    //     if (window.location.href.includes("charity")) {
    //         this.setState({ showOrderIcon: false })
    //     }
    // }
  }

  getCharityObjectedQty(e) {
    let arrItems = e;
    if (arrItems.length > 0) {
      Array.prototype.sum = function (prop) {
        var total = 0;
        for (var i = 0, _len = this.length; i < _len; i++) {
          total += Number(this[i][1][prop]);
        }
        return total;
      };
      return arrItems.sum("used_time");
    }
  }

  getVerzilveredQty(e) {
    let arrItems = e;
    if (arrItems.length > 0) {
      Array.prototype.sum = function (prop) {
        var total = 0;
        for (var i = 0, _len = this.length; i < _len; i++) {
          total += Number(this[i][1][prop].length);
        }
        return total;
      };
      return arrItems.sum("allDates");
    }
  }

  logout() {
    firebase
      .auth()
      .signOut()
      .then(() => {
        // localStorage.setItem("userid", "")
        // window.location.href = "/login"
        localStorage.removeItem("userdata");
        // localStorage.removeItem("isAdmin")
        localStorage.removeItem("onCurrentTab");
        localStorage.removeItem("onCurrentTabCompany");
        localStorage.removeItem("userid");
        localStorage.removeItem("cookie_item");
        this.props.updateSignUpFieldsFilled("");
        this.props.updateGuestUserCartExtendOpportunity("");
        this.props.updateGuestUserCartTime("");
        this.props.updateGuestUserCart([]);
        setTimeout(() => {
          window.location.href = "/";
        }, 50);
      });
  }


  fileUpload(e) {
    let files = e.target.files[0];
    let storageRef = firebase
      .storage()
      .ref()
      .child(`userimages/${files.name}${new Date().getTime()}`);
    storageRef.put(files).then((snapshot) => {
      snapshot.ref.getDownloadURL().then((snapUrl) => {
        firebase
          .database()
          .ref("users/" + this.state.userid + "/profile_image")
          .set(snapUrl)
          .then(() => { });
      });
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.title) {
      this.setState({ updated: false });
      this.updateTitle();
      this.props.updateTitle(false);
      setTimeout(() => {
        this.setState({ updated: true });
      }, 250);
    }
    let userid = localStorage.getItem("userid");

    if (this.props.images !== prevProps.images) {
      let allImages = this.props.images;
      let filtered = [];
      allImages.map((f) => {
        if (f.created_by === userid && !f.inAlbums) {
          filtered.push(f);
        }
      });
      this.setState({ myownImages: filtered })
    }

    if (
      prevProps.closeCompleteRightMenu !== this.props.closeCompleteRightMenu
    ) {
      if (this.props.closeCompleteRightMenu) {
        this.setState({ rightModalSettings: false });
        document.querySelector("html").style.overflowY = "auto"

        let element = document.getElementById("footermain");
        if (element) {
          element.style.display = "flex";
        }
        if (window.innerWidth < 1009) {
          document.getElementById("root").style.position = "static";
          document.getElementById("root").style.overflow = "hidden";
        }
        if (this.props.closeAboutBhodiRightMenu) {
          this.props.closeAboutBhodiRightMenu();
        }
        document.getElementById("root").style.position = "static";
        if (this.props.revertBackCloseCompleteRightMenu) {
          this.props.revertBackCloseCompleteRightMenu();
        }
      }
    }

    if (
      prevProps.albumToAdd !== this.props.albumToAdd &&
      this.state.showLists
    ) {
      // if (this.props.albumToAdd) {
      //     if (this.props.albumToAdd !== "mijn_public" && this.props.albumToAdd !== "vrienden" && this.props.albumToAdd !== "trending") {
      //         this.setState({ toShowAlbum: this.props.albumToAdd.name })
      //     } else if (this.props.albumToAdd === "mijn_public") {
      //         this.setState({ toShowAlbum: "Mijn profielpagina" })
      //     } else if (this.props.albumToAdd === "vrienden") {
      //         this.setState({ toShowAlbum: "TRENDING" })
      //     }
      // }
      if (this.props.albumToAdd) {
        if (
          this.props.albumToAdd !== "mijn_public" &&
          this.props.albumToAdd !== "vrienden" &&
          this.props.albumToAdd !== "trending"
        ) {
          let albumName = this.props.albumToAdd.name;
          let forcedSmall =
            albumName.charAt(0).toUpperCase() +
            albumName.slice(1).toLowerCase();
          this.setState({
            toShowAlbum: forcedSmall,
            onOpgelsagenMain: false,
            onGelikedMain: false,
            textSmall: true,
          });
          if (
            this.props.albumToAdd.img &&
            this.props.albumToAdd.img !==
            "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9"
          ) {
            this.setState({ toShowAlbumImg: this.props.albumToAdd.img });
          } else {
            if (this.props.albumToAdd.owner !== userid && this.props.user) {
              let filtered = this.props.user.filter((j) => {
                return j.user_id === this.props.albumToAdd.owner;
              });
              if (filtered.length > 0) {
                if (filtered[0].profile_image) {
                  this.setState({ toShowAlbumImg: filtered[0].profile_image });
                } else {
                  this.setState({
                    toShowAlbumImg:
                      "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9",
                  });
                }
                return false;
              }
            }
            this.setState({ toShowAlbumImg: "" });
          }
        } else if (this.props.albumToAdd === "mijn_public") {
          this.setState({
            toShowAlbum: "Mijn profielpagina",
            onOpgelsagenMain: false,
            onGelikedMain: false,
            textSmall: true,
            toShowAlbumImg: "",
          });
        } else if (this.props.albumToAdd === "vrienden") {
          this.setState({
            toShowAlbum: "Trending",
            onOpgelsagenMain: false,
            onGelikedMain: false,
            textSmall: true,
            toShowAlbumImg: "",
          });
        }
      }
    }

    if (this.props.showGegevensPopup) {
      let element = document.getElementById("footermain");
      if (element) {
        element.style.display = "none";
      }
      this.setState({ rightModalSettings: true });

      document.querySelector("html").style.overflowY = "hidden"

      if (window.innerWidth < 1009) {
        this.setState({ userInfoRight: false, menuItemsModals: true });
      }
      if (this.props.closeShowGegevensPopup) {
        this.props.closeShowGegevensPopup();
      }
    }
    if (this.props.doShowMenuRightAbout) {
      if (this.props.doShowMenuRightAbout !== this.state.showBhodiAbouts) {
        let element = document.getElementById("footermain");
        if (element) {
          element.style.display = "none";
        }
        document.querySelector("html").style.overflowY = "hidden"

        this.setState({
          rightModalSettings: true,
          menuItemsModals: false,
          showBhodiAbouts: true,
        });
      }
    }
  }

  render() {
    return (
      this.state.updated && (
        <div>
          <div
            className="navbarfullwidthMobile navbarfullwidthmobilefullpagesupdated navbartodowhitedefault navbartodowhitedefaultnewdesign"
            id="navbartohidemainly"
            style={{
              overflow:
                window.location.href.includes("charityConfirmation") &&
                "hidden", display: this.state.openAddPostsPopup && "none"
            }}
          >
            {this.state.currentUserMember && (
              <div className="top-nfwpm">
                <div className="name-wrapper-nav" style={{ position: "relative", display: "flex", alignItems: "center" }}>
                  {/* <Link
                    className="link-style-trending"
                    to={this.props.linkToTrending}
                    onClick={() => {
                      localStorage.removeItem("onCurrentTab");
                      localStorage.removeItem("onCurrentTabCompany");
                    }}
                  >
                    <div className="captionvoucherstuff imguploadonvoucher" style={{ overflow: "hidden", marginRight: 0 }}>
                      <img src={
                        this.state.currentUserMember.profile_image
                          ? this.state.currentUserMember.profile_image
                          : "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9"
                      } />
                    </div>
                  </Link> */}
                  {/* <div>
                    <p
                      id="nameToShowOfAlbum"
                      style={{ textTransform: "none" }}
                      onClick={() => {
                        localStorage.removeItem("onCurrentTab");
                        localStorage.removeItem("onCurrentTabCompany");
                        history.push(`/member/${this.state.currentUserMember.user_name_id}`)
                      }}
                    >
                      {this.state.currentUserMember.first_name + " " + this.state.currentUserMember.last_name}
                    </p>
                  </div> */}
                  {/* {this.props.onProductDetailed ? (<BsChevronLeft onClick={() => {
                    history.goBack()
                  }} />) : */}
                  <p
                    onClick={() => {
                      localStorage.removeItem("onCurrentTab");
                      localStorage.removeItem("onCurrentTabCompany");
                      history.push(`/member/${this.state.currentUserMember.user_name_id}`)
                    }}
                    style={{ textTransform: "none", flexShrink: 0 }}
                  >
                    <img src={headerBhodiIcon} style={{ width: 100, height: 30, borderRadius: 0, objectFit: "cover", objectPosition: "center" }} />
                  </p>
                  {/* } */}
                </div>
                <div className="icons-wrapper-nav">
                  {this.props.showSearchOnGlobal && (<a
                    href="javascript:void(0)"
                    className="btn btn-black orderbasketicon btnpopupwork"
                    style={{ background: "none", paddingRight: 0 }}
                  >
                    <AiOutlineSearch
                      style={{ fontSize: 18, marginTop: 3 }}
                      onClick={() => {
                        this.setState({ showSearchLine: !this.state.showSearchLine })
                      }}
                    />
                    {/* <img
                      src={searchpicpng}
                      style={{ maxWidth: 20, marginTop: 3 }}
                    /> */}
                    {this.state.showSearchLine && (
                      <input
                        type="text"
                        className="input-inclgd"
                        onChange={(e) => {
                          if (this.props.searchFiltered) {
                            this.props.searchFiltered(e.target.value);
                          }
                        }}
                      />
                    )}
                  </a>)}
                  {(this.state.showOrderIcon && !this.props.onProductDetailed) && (
                    <div
                      style={{ position: "relative", display: "inline-block" }}
                      className="righticonopacity"
                    >
                      <a
                        href="javascript:void(0)"
                        className="btn btn-black orderbasketicon btnpopupwork"
                        style={{ background: "none" }}
                        onClick={() => {
                          this.setState({ ordersRightMenu: true });
                        }}
                      >
                        <IoScanCircleOutline
                          style={{ fontSize: 18, marginTop: 3 }}
                        />
                        <div id="mustShowOnAddOrder" style={{ left: "-60%" }}>
                          <p style={{ textTransform: "none" }}>Toegevoegd</p>
                        </div>
                      </a>
                    </div>
                  )}
                  {
                    !window.location.href.includes("/charityConfirmation") && !window.location.href.includes("/exclusive-subscribing") && (
                      <CartNonUserMobile showOnlyCount={true} />
                    )}
                  {this.state.currentUserMember.user_id === this.state.currentUserId ? <div className="own-user-countnotif" onClick={() => {
                    let element = document.getElementById("footermain");
                    if (element) {
                      element.style.display = "none";
                    }
                    this.setState({ rightModalSettings: true });

                    document.querySelector("html").style.overflowY = "hidden"

                    if (window.innerWidth < 1009) {
                      this.setState({
                        userInfoRight: false,
                        menuItemsModals: true,
                      });
                    }
                  }}>
                    <BiMenu
                      style={{ fontSize: 20 }}
                    />
                  </div> : <BiMenu
                    style={{ fontSize: 20 }}
                    onClick={() => {
                      let element = document.getElementById("footermain");
                      if (element) {
                        element.style.display = "none";
                      }
                      this.setState({ rightModalSettings: true });

                      document.querySelector("html").style.overflowY = "hidden"

                      if (window.innerWidth < 1009) {
                        this.setState({
                          userInfoRight: false,
                          menuItemsModals: true,
                        });
                      }
                    }}
                  />}
                </div>
              </div>
            )}
          </div>
          {this.state.showFriends &&
            this.state.currentUserMember.user_id ===
            this.state.currentUserId && (
              <SearchIcon
                className="dosearchiconposition"
                onClick={() => this.setState({ rightModalSearchFriends: true })}
                style={{
                  top: this.state.currentUserMember.user_description
                    ? 158
                    : 121,
                }}
              />
            )}
          <div
            className={`mnicart-flyout-inner mnicartflyout-updated updated-mnicartflyout ${this.state.rightModalSettings && !this.state.commentsPopup && !this.state.showSettingsMenu
              ? "open"
              : null
              }`}
            style={{
              maxWidth: 400,
              opacity:
                this.state.rightModalSettings && !this.state.commentsPopup && !this.state.showSettingsMenu
                  ? 1
                  : 0,
            }}
          >
            <div
              className="checkout-flyout-inner-content"
              style={{ paddingBottom: 10 }}
            >
              <div
                className="flyout-section"
                style={{ backgroundColor: "white" }}
              >
                <div className="header-companysection">
                  <p>PROFIELINSTELLINGEN</p>
                  <div>
                    <CloseIcon
                      onClick={() => {
                        this.setState({ rightModalSettings: false });
                        let element = document.getElementById("footermain");
                        if (element) {
                          element.style.display = "flex";
                        }
                        if (window.innerWidth < 1009) {
                          document.getElementById("root").style.position =
                            "static";
                          document.getElementById("root").style.overflow =
                            "hidden";
                        }
                        if (this.props.closeAboutBhodiRightMenu) {
                          this.props.closeAboutBhodiRightMenu();
                        }
                        document.getElementById("root").style.position = "static";
                        document.querySelector("html").style.overflowY = "auto"
                      }}
                    />
                  </div>
                </div>
                <div className="container-companysectionright">
                  <div className="lists-companysectionright listsmobilenavbarmembernew">
                    <ul>
                      <li className="litexttransformnone" style={{ cursor: "pointer", position: "relative" }}>
                        <div className="innercontltn">
                          <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => this.fileUpload(e)}
                          />
                          <div className="imginnermcaadd" style={{ top: -5, right: "auto", left: 14, zIndex: 99 }}>
                            <AddIcon style={{ color: "white" }} />
                          </div>
                          <div className="captionvoucherstuff imguploadonvoucher" style={{ overflow: "hidden", borderColor: "transparent" }}>
                            {this.state.currentUserMember.profile_image ? (
                              <img src={this.state.currentUserMember.profile_image} />
                            ) : (
                              <img src="https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9" />
                            )}
                          </div>
                          <label>
                            Profielfoto
                          </label>
                        </div>
                      </li>
                      {/* <li style={{ cursor: "pointer" }}>
                        <div className="captionvoucherstuff charityimageonvoucher"
                          onClick={() => {
                            if (document.querySelector(".new-round-icons-member")) {
                              document.querySelector(".new-round-icons-member").style.display = "none"
                            }
                            this.setState({
                              typeOfPopup: "mijn_abonnementen",
                              showSettingsMenu: true,
                            });
                          }}>
                          <TbFolderStar />
                        </div>
                        <label
                          onClick={() => {
                            if (document.querySelector(".new-round-icons-member")) {
                              document.querySelector(".new-round-icons-member").style.display = "none"
                            }
                            this.setState({
                              typeOfPopup: "mijn_abonnementen",
                              showSettingsMenu: true,
                            });
                          }}
                        >
                          Mijn exclusives
                        </label>
                      </li> */}
                      <li style={{ cursor: "pointer" }}>
                        <div className="captionvoucherstuff charityimageonvoucher"
                          onClick={() => {
                            if (document.querySelector(".new-round-icons-member")) {
                              document.querySelector(".new-round-icons-member").style.display = "none"
                            }
                            this.setState({
                              typeOfPopup: "mijn_gegevens",
                              showSettingsMenu: true,
                            });
                          }}>
                          <RiUserSettingsLine />
                        </div>
                        <label
                          onClick={() => {
                            if (document.querySelector(".new-round-icons-member")) {
                              document.querySelector(".new-round-icons-member").style.display = "none"
                            }
                            this.setState({
                              typeOfPopup: "mijn_gegevens",
                              showSettingsMenu: true,
                            });
                            // window.location.href = "/settings-separate-users/mijn_gegevens"
                          }}
                        >
                          Mijn gegevens
                        </label>
                      </li>
                      {/* <li style={{ cursor: "pointer" }}>
                        <div className="captionvoucherstuff charityimageonvoucher"
                          onClick={() => {
                            if (document.querySelector(".new-round-icons-member")) {
                              document.querySelector(".new-round-icons-member").style.display = "none"
                            }
                            this.setState({
                              typeOfPopup: "privacy",
                              showSettingsMenu: true,
                            });
                          }}>
                          <MdOutlinePrivacyTip />
                        </div>
                        <label
                          onClick={() => {
                            if (document.querySelector(".new-round-icons-member")) {
                              document.querySelector(".new-round-icons-member").style.display = "none"
                            }
                            this.setState({
                              typeOfPopup: "privacy",
                              showSettingsMenu: true,
                            });
                          }}
                        >
                          Privacy instellingen
                        </label>
                      </li> */}
                      <li style={{ cursor: "pointer" }}>
                        <div className="captionvoucherstuff charityimageonvoucher"
                          onClick={() => {
                            if (document.querySelector(".new-round-icons-member")) {
                              document.querySelector(".new-round-icons-member").style.display = "none"
                            }
                            this.setState({
                              typeOfPopup: "inloggegevens",
                              showSettingsMenu: true,
                            });
                          }}>
                          <RiGitRepositoryPrivateLine />
                        </div>
                        <label
                          onClick={() => {
                            if (document.querySelector(".new-round-icons-member")) {
                              document.querySelector(".new-round-icons-member").style.display = "none"
                            }
                            // window.location.href = "/settings-separate-users/inloggegevens"
                            this.setState({
                              typeOfPopup: "inloggegevens",
                              showSettingsMenu: true,
                            });
                          }}
                        >
                          Inloggegevens
                        </label>
                      </li>
                      {this.state.bhodiFaqs.length > 0 && (
                        <li style={{ cursor: "pointer" }}>
                          <div className="captionvoucherstuff charityimageonvoucher faq-icon-mobile"
                            onClick={() => {
                              if (document.querySelector(".new-round-icons-member")) {
                                document.querySelector(".new-round-icons-member").style.display = "none"
                              }
                              this.setState({
                                typeOfPopup: "faqs",
                                showSettingsMenu: true,
                              });
                            }}>
                            <FaQ />
                          </div>
                          <label
                            onClick={() => {
                              if (document.querySelector(".new-round-icons-member")) {
                                document.querySelector(".new-round-icons-member").style.display = "none"
                              }
                              // window.location.href = "/settings-separate-users/faqs"
                              this.setState({
                                typeOfPopup: "faqs",
                                showSettingsMenu: true,
                              });
                            }}
                          >
                            FAQ
                          </label>
                        </li>
                      )}
                      {this.state.bhodiAbouts.length > 0 && (
                        <li
                          className="right-mcls"
                          style={{ cursor: "pointer", marginBottom: 20 }}
                        >
                          <div className="captionvoucherstuff charityimageonvoucher"
                            onClick={() => {
                              if (document.querySelector(".new-round-icons-member")) {
                                document.querySelector(".new-round-icons-member").style.display = "none"
                              }
                              this.setState({
                                typeOfPopup: "abouts",
                                showSettingsMenu: true,
                              });
                            }}>
                            <TiInfo />
                          </div>
                          <label
                            onClick={() => {
                              if (document.querySelector(".new-round-icons-member")) {
                                document.querySelector(".new-round-icons-member").style.display = "none"
                              }
                              // window.location.href = "/settings-separate-users/abouts"
                              this.setState({
                                typeOfPopup: "abouts",
                                showSettingsMenu: true,
                              });
                            }}
                          >
                            About
                          </label>
                        </li>
                      )}
                      <li
                        className="right-mcls logout-mcls"
                        style={{ width: "100%" }}
                      >
                        <ExitToAppIcon onClick={this.logout.bind(this)} />
                        <label onClick={this.logout.bind(this)}>
                          Uitloggen
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.rightModalAbout && (
            <div className="loader-area mcaloader">
              <div className="modal-create-album" style={{ width: 400 }}>
                <div
                  className="heading-mca"
                  style={{
                    textAlign: "center",
                    position: "relative",
                    padding: "13px",
                  }}
                >
                  <font>ABOUT</font>
                  <CloseIcon
                    onClick={() => {
                      this.setState({ rightModalAbout: false });
                    }}
                    style={{
                      position: "absolute",
                      top: "50%",
                      transform: "translateY(-50%)",
                      right: 10,
                      fontSize: 24,
                    }}
                  />
                </div>
                <div className="body-mca groupsettingsbody">
                  <div className="form-body-mca">
                    <div className="inner-mca-settings">
                      <div
                        className="mca-settings-list"
                        style={{
                          flexDirection: "column",
                          backgroundColor: "transparent",
                          height: "auto",
                        }}
                      >
                        <div
                          className="right-mcls imageinnermca"
                          style={{
                            color: "white",
                            position: "relative",
                            maxWidth: 70,
                            margin: "0px auto",
                          }}
                        >
                          {this.state.currentUserMember.profile_image ? (
                            <img
                              src={this.state.currentUserMember.profile_image}
                            />
                          ) : (
                            <img src="https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9" />
                          )}
                        </div>
                        {this.state.userNameInput ? (
                          <div
                            className="right-mcls imageinnermca"
                            style={{ color: "white", flexDirection: "column" }}
                          >
                            <div
                              className="form-profile"
                              style={{ textAlign: "left" }}
                            >
                              <label>First Name</label>
                              <input
                                type="text"
                                value={this.state.currentUserMember.first_name}
                                style={{ position: "static", opacity: 1 }}
                                onChange={(e) => {
                                  this.state.currentUserMember.first_name =
                                    e.target.value;
                                  this.setState({
                                    currentUserMember:
                                      this.state.currentUserMember,
                                  });
                                  let userid = localStorage.getItem("userid");
                                  firebase
                                    .database()
                                    .ref("users/" + userid + "/first_name")
                                    .set(e.target.value);
                                }}
                                onBlur={() => {
                                  this.setState({ userNameInput: false });
                                }}
                              />
                            </div>
                            <div
                              className="form-profile"
                              style={{ textAlign: "left" }}
                            >
                              <label>Last Name</label>
                              <input
                                type="text"
                                value={this.state.currentUserMember.last_name}
                                style={{ position: "static", opacity: 1 }}
                                onChange={(e) => {
                                  this.state.currentUserMember.last_name =
                                    e.target.value;
                                  this.setState({
                                    currentUserMember:
                                      this.state.currentUserMember,
                                  });
                                  let userid = localStorage.getItem("userid");
                                  firebase
                                    .database()
                                    .ref("users/" + userid + "/last_name")
                                    .set(e.target.value);
                                }}
                                onBlur={() => {
                                  this.setState({ userNameInput: false });
                                }}
                              />
                            </div>
                          </div>
                        ) : (
                          <div
                            className="right-mcls imageinnermca"
                            style={{ color: "white" }}
                            onClick={() =>
                              this.setState({ userNameInput: true })
                            }
                          >
                            <font>
                              {this.state.currentUserMember.first_name}{" "}
                              {this.state.currentUserMember.last_name}
                            </font>{" "}
                            <EditIcon
                              style={{
                                position: "static",
                                fontSize: 16,
                                margin: "0px 15px",
                              }}
                            />
                          </div>
                        )}
                        {this.state.currentUserMember.user_description ? (
                          <div
                            className="top-mcls-one"
                            style={{ marginBottom: 20 }}
                          >
                            {this.state.userDescInput ? (
                              <div
                                className="form-profile"
                                style={{ textAlign: "left" }}
                              >
                                <textarea
                                  value={
                                    this.state.currentUserMember
                                      .user_description
                                  }
                                  style={{ position: "static", opacity: 1 }}
                                  onChange={(e) => {
                                    this.state.currentUserMember.user_description =
                                      e.target.value;
                                    this.setState({
                                      currentUserMember:
                                        this.state.currentUserMember,
                                    });
                                    let userid = localStorage.getItem("userid");
                                    firebase
                                      .database()
                                      .ref(
                                        "users/" + userid + "/user_description"
                                      )
                                      .set(e.target.value);
                                  }}
                                  onBlur={() => {
                                    this.setState({ userDescInput: false });
                                  }}
                                ></textarea>
                              </div>
                            ) : (
                              <p
                                onClick={() =>
                                  this.setState({ userDescInput: true })
                                }
                              >
                                {this.state.currentUserMember.user_description}{" "}
                                <EditIcon
                                  style={{
                                    position: "static",
                                    fontSize: 16,
                                    margin: "0px 15px",
                                  }}
                                />
                              </p>
                            )}
                          </div>
                        ) : (
                          <div
                            className="top-mcls-one"
                            style={{ marginBottom: 20 }}
                          >
                            {this.state.userDescInput ? (
                              <div
                                className="form-profile"
                                style={{ textAlign: "left" }}
                              >
                                <textarea
                                  value=""
                                  style={{ position: "static", opacity: 1 }}
                                  onChange={(e) => {
                                    this.state.currentUserMember.user_description =
                                      e.target.value;
                                    this.setState({
                                      currentUserMember:
                                        this.state.currentUserMember,
                                    });
                                    let userid = localStorage.getItem("userid");
                                    firebase
                                      .database()
                                      .ref(
                                        "users/" + userid + "/user_description"
                                      )
                                      .set(e.target.value);
                                  }}
                                  onBlur={() => {
                                    this.setState({ userDescInput: false });
                                  }}
                                ></textarea>
                              </div>
                            ) : (
                              <p
                                onClick={() =>
                                  this.setState({ userDescInput: true })
                                }
                              >
                                Description{" "}
                                <EditIcon
                                  style={{
                                    position: "static",
                                    fontSize: 16,
                                    margin: "0px 15px",
                                  }}
                                />
                              </p>
                            )}
                          </div>
                        )}
                        <div
                          className="right-mcls"
                          style={{ color: "white", paddingLeft: 20 }}
                        >
                          <EventAvailableIcon
                            style={{ position: "static", fontSize: 24 }}
                          />
                          <font>
                            Member Sinds{" "}
                            {this.state.currentUserMember.created_on}{" "}
                          </font>
                        </div>
                        <div
                          className="right-mcls"
                          style={{ color: "white", paddingLeft: 20 }}
                        >
                          <LocationOnIcon
                            style={{ position: "static", fontSize: 24 }}
                          />
                          <font>
                            {this.state.currentUserMember.city}{" "}
                            {this.state.currentUserMember.country}
                          </font>
                        </div>
                        <div
                          className="right-mcls"
                          style={{ color: "white", paddingLeft: 20 }}
                        >
                          <PersonIcon
                            style={{ position: "static", fontSize: 24 }}
                          />
                          <font>
                            {
                              this.state.currentUserMember.objectedFriends
                                .length
                            }{" "}
                            vrienden
                          </font>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {this.state.ordersRightMenu && (
            <div
              className="bg-overlaymini"
              onClick={() => {
                document.getElementById("root").style.position = "static";
                this.setState({ ordersRightMenu: false });
              }}
            ></div>
          )}
          {this.state.ordersRightMenu && (
            <RightMenuOrders
              showFooter={true}
              closePopup={() => {
                document.getElementById("root").style.position = "static";
                this.setState({ ordersRightMenu: false });

              }}
              showVerzilverPopup={(e) => {
                this.setState({
                  validOnMobilePopup: true,
                  currentUserOfPopups: e,
                });
              }}
              showVerzilverLastMinutePopup={(e) => {
                this.setState({
                  validOnMobilePopup: true,
                  currentUserOfPopups: e,
                });
              }}
              showVerzilverCharityPopup={(e) => {
                this.setState({
                  validOnMobilePopup: true,
                  currentUserOfPopups: e,
                });
              }}
              showVerzilverGiveawayPopup={(e) => {
                this.setState({
                  validOnMobilePopup: true,
                  currentUserOfPopups: e,
                });
              }}
              showVerzilverWebShopPopup={(e, redeem_code) => {
                this.setState({
                  verzilverPopupWebShop: true,
                  currentUserOfPopups: e,
                  redeem_code,
                });
              }}
            />
          )}
          {this.state.showSettingsMenu && (
            <SettingsSeparateUser
              type={this.state.typeOfPopup}
              fromMainPage={true}
              closeOnlyCurrent={() => {
                if (document.querySelector(".new-round-icons-member")) {
                  document.querySelector(".new-round-icons-member").style.display = "block"
                }
                this.setState({ showSettingsMenu: false, typeOfPopup: "" });
              }}
              closeIncludingRightMenu={() => {
                if (document.querySelector(".new-round-icons-member")) {
                  document.querySelector(".new-round-icons-member").style.display = "block"
                }
                document.querySelector("html").style.overflowY = "auto"
                this.setState({
                  rightModalSettings: false,
                  showSettingsMenu: false,
                  typeOfPopup: "",
                });
                let element = document.getElementById("footermain");
                if (element) {
                  element.style.display = "flex";
                }
                if (window.innerWidth < 1009) {
                  document.getElementById("root").style.position = "static";
                  document.getElementById("root").style.overflow = "hidden";
                }
                if (this.props.closeAboutBhodiRightMenu) {
                  this.props.closeAboutBhodiRightMenu();
                }
                document.getElementById("root").style.position = "static";
              }}
            />
          )}
        </div>
      )
    );
  }
}

const mapStateToProps = (state) => {
  return {
    title: state.renderReducer.title,
    user: state.authReducers.user,
    album: state.postsReducers.album,
    images: state.postsReducers.images,
    product: state.campaignsReducer.product,
    global_campaigns: state.campaignsReducer.global_campaigns,
    guest_user_cart: state.cartReducer.guest_user_cart,
    message: state.authReducers.message,
    albumToAdd: state.imagesReducer.albumToAdd,
    bhodi_abouts: state.globalReducers.bhodi_abouts,
    bhodi_faqs: state.globalReducers.bhodi_faqs,
    currentMoments: state.imagesReducer.currentMoments,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateTitle: (title) => dispatch(updateTitle(title)),
    updateGuestUserCart: (guest_user_cart) =>
      dispatch(updateGuestUserCart(guest_user_cart)),
    updateGuestUserCartTime: (guest_user_cart_time) =>
      dispatch(updateGuestUserCartTime(guest_user_cart_time)),
    updateGuestUserCartExtendOpportunity: (
      guest_user_cart_extend_opportunity
    ) =>
      dispatch(
        updateGuestUserCartExtendOpportunity(guest_user_cart_extend_opportunity)
      ),
    updateSignUpFieldsFilled: (signup_fields_info) =>
      dispatch(updateSignUpFieldsFilled(signup_fields_info)),
    updateBhodiAbouts: (bhodi_abouts) => dispatch(updateBhodiAbouts(bhodi_abouts)),
    updateBhodiFaqs: (bhodi_faqs) => dispatch(updateBhodiFaqs(bhodi_faqs)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavbarFullWidthMobile);
