import React, { Component } from 'react'
import styles from "./index.module.css"
import image from "../../assets/watch.jpeg"
import "./styles.css"
import { RiArrowDownSLine } from 'react-icons/ri'
import {
    updateGuestUserCart,
    updateGuestUserCartTime,
    updateGuestUserCartExtendOpportunity,
    updateBhodiProducts,
} from "../../Redux/actions/authActions";
import { connect } from 'react-redux'
import swal from "sweetalert";
import { history } from '../../Config/Routes'
import CartNonUser from "../../Components/CartNonUser";
import firebase from "../../Config/Firebase"
import Footer from '../../Components/Footer'
import CloseIcon from "@material-ui/icons/Close";
import { BsBookmark, BsBookmarkFill, BsFillPersonXFill, BsFillPersonPlusFill, BsChevronLeft } from "react-icons/bs";
import AddIcon from "@material-ui/icons/Add";
import FooterGreen from '../../Components/FooterGreen'
import FriendFollowBottom from '../../Components/FriendFollowBottom'
import ButtonTimerProducts from '../../Components/ButtonTimerProducts'
import FooterBasket from '../../Components/FooterBasket'
import Charity from '../Charity'
import { HiMail } from "react-icons/hi";
import { IoLocationSharp } from "react-icons/io5";
import { checkForSubscribedExclusives } from '../../Functions/checkForSubscribedExclusives'
import NavbarWrapperVisitorMobile from '../../Components/NavbarWrapperVisitorMobile'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import { AiFillCaretLeft, AiFillCaretRight } from 'react-icons/ai'
import ButtonTimerProductsNew from '../../Components/ButtonTimerProductsNew'

let dbRef = firebase.database().ref("bhodi_products")

export class ProductDetailed extends Component {
    constructor() {
        super()
        this.state = {
            currentImage: image,
            dropdownOpen: false,
            currentProduct: "",
            currentImageIndex: 0,
            changingImage: false,
            selectedSize: "",
            currentUserCharityMember: false,
            currentUserHere: "",
            isGuestUser: false,
            commentsHere: [],
            arrayOfUsersLikeToShow: [],
            commentsReplies: [],
            commentsRepliesOpened: false,
            maxTextToShow: 220,
            productsTimerSettedAt: "",
            productsTimer: "",
            guestUserCart: [],
            sizeMustSelectPopup: false,
            additionalPrice: "",
            globalDesc: "",
            globalGender: [],
            currentUserId: "",
            selectedSkuOfSize: "",
            deliveryNote: "",
            deliveryNotePopup: false,
            freeItemText: "",
            currentMemberData: "",
            productsToRender: [],
            currentUserCreator: "",
            allProductImages: [],
            maxProductsToShow: 1,
        }
    }

    componentDidMount() {
        if (this.props.guest_user_cart) {
            this.setState({ guestUserCart: this.props.guest_user_cart });
        }

        if (window.innerWidth > 1008) {
            this.setState({ maxTextToShow: 230 })
        } else {
            setTimeout(() => {
                window.scrollTo(0, 0)
            }, 500);
        }

        let key = new URL(window.location.href).searchParams.get("key")

        if (this.props.defaultProduct) {
            key = this.props.defaultProduct.pushKey
        }

        let uid = localStorage.getItem("userid");

        this.setState({ currentUserId: uid });

        firebase
            .database()
            .ref("bhodi_products")
            .on("value", (data) => {
                let a = data.val();
                if (a) {
                    let arrayMain = []
                    let objected = Object.entries(a);
                    objected.map((h) => {
                        arrayMain.push(h[1])
                    });

                    if (arrayMain.length > 0) {
                        this.props.updateBhodiProducts(arrayMain);
                    } else {
                        this.props.updateBhodiProducts([]);
                    }
                } else {
                    this.props.updateBhodiProducts([]);
                }
            });

        if (this.props.user) {
            if (this.props.user.length > 0) {
                let filtered = this.props.user.filter((j) => {
                    return j.user_id === uid;
                });
                if (filtered.length > 0) {
                    let a = filtered[0];
                    if (a.subscription) {
                        if (a.subscription.status === "active") {
                            this.setState({ currentUserCharityMember: true });
                        } else {
                            if (checkForSubscribedExclusives(a)) {
                                this.setState({ currentUserCharityMember: true });
                            }
                        }
                    } else {
                        if (checkForSubscribedExclusives(a)) {
                            this.setState({ currentUserCharityMember: true });
                        }
                    }
                    this.setState({ currentUserHere: a, isGuestUser: false });
                }
            }
        }
        firebase
            .database()
            .ref("users/" + uid)
            .on("value", (data) => {
                let a = data.val();
                if (a) {
                    if (a.subscription) {
                        if (a.subscription.status === "active") {
                            this.setState({ currentUserCharityMember: true });
                        } else {
                            if (checkForSubscribedExclusives(a)) {
                                this.setState({ currentUserCharityMember: true });
                            }
                        }
                    } else {
                        if (checkForSubscribedExclusives(a)) {
                            this.setState({ currentUserCharityMember: true });
                        }
                    }
                    this.setState({ currentUserHere: a, isGuestUser: false });
                }
            });

        if (this.props.bhodi_products) {
            let filtered = this.props.bhodi_products.filter((e) => {
                return e.pushKey === key
            })
            if (filtered.length > 0) {
                // users
                if (!uid) {
                    if (!filtered[0].availableForGuests) {
                        this.setState({ dontRenderProduct: true })
                        swal(
                            "Niet meer beschikbaar",
                            "Helaas is het product niet meer beschikbaar",
                            "error"
                        ).then(() => {
                            window.location.href = "/"
                        })
                    }
                }
                let filteredUserCreator = this.props.user.filter((j) => {
                    return j.user_id === filtered[0].created_by;
                });
                let filteredUserProducts = this.props.bhodi_products.filter((j) => {
                    return j.created_by === filtered[0].created_by;
                });
                // this.checkForShowingIndexes(filteredUserCreator[0])

                if (filteredUserCreator.length > 0) {

                    if (filteredUserProducts.length > 0) {
                        let maxProductsToShow = 4

                        if (filtered[0].isSpecialProduct || filtered[0].availableForCompanyGuest) {
                            maxProductsToShow = 5
                            if (this.props.global_values) {
                                if (this.props.global_values.products_count_new) {
                                    maxProductsToShow = Number(this.props.global_values.products_count_new)
                                }
                            }
                        } else if (filteredUserCreator[0].productsInfo) {
                            if (filteredUserCreator[0].productsInfo.maxProductsToShow) {
                                maxProductsToShow = Number(filteredUserCreator[0].productsInfo.maxProductsToShow)
                            }
                        }

                        let filteredProducts = filteredUserProducts.filter((product) => {
                            if (product.sizesActive) {
                                let stocksAvailableSizes = []
                                product.variations_data.map((e) => {
                                    if (Number(e.qty) === 0) {
                                        return false;
                                    }
                                    let filteredStockLength = []
                                    let filteredStockLengthVerzilvered = []
                                    if (product.inCartOf) {
                                        filteredStockLength = Object.entries(product.inCartOf).filter((t) => {
                                            return t[1].selectedSize === e.size
                                        })
                                    }
                                    if (product.verzilvered_by) {
                                        filteredStockLengthVerzilvered = Object.entries(product.verzilvered_by).filter((t) => {
                                            return t[1].selectedSize === e.size
                                        })
                                    }
                                    if ((filteredStockLength.length + filteredStockLengthVerzilvered.length) < Number(e.qty)) {
                                        stocksAvailableSizes.push(e)
                                    }
                                })
                                if (stocksAvailableSizes.length === 0) {
                                    return false;
                                }
                            } else {
                                if (Number(product.qty) === 0) {
                                    return false;
                                }
                            }

                            if (filtered[0].availableForCompanyGuest) {
                                return !product.availableOnTime && product.images && product.active && product.availableForCompanyGuest
                            } else if (filtered[0].isSpecialProduct) {
                                if (filtered[0].productForPage) {
                                    return !product.availableOnTime && product.images && product.active && product.isSpecialProduct && filtered[0].productForPage === product.productForPage
                                }
                                return !product.availableOnTime && product.images && product.active && product.isSpecialProduct && !product.productForPage
                            } else {
                                return !product.availableOnTime && product.images && product.active && !product.is_bookmarked && !product.isSpecialProduct && !product.onlyAvailableViaURL
                            }
                        })
                        let sortedProducts = [...filteredProducts].sort((a, b) => {
                            return a.sort_time * 100 - b.sort_time * 100;
                        });
                        if (sortedProducts.length > 0) {
                            let productsShowing = [...sortedProducts].splice(0, Number(maxProductsToShow))
                            let checkSeeingCurrent = productsShowing.filter((t) => {
                                return t.pushKey === key
                            })
                            if (checkSeeingCurrent.length === 0 && !filtered[0].is_bookmarked && !filtered[0].isSpecialProduct && !filtered[0].availableForCompanyGuest && !filtered[0].onlyAvailableViaURL) {
                                swal(
                                    "Niet meer beschikbaar",
                                    "Helaas is het product niet meer beschikbaar",
                                    "error"
                                ).then(() => {
                                    firebase.database().ref("bhodi_products/" + key).off()

                                    setTimeout(() => {
                                        history.goBack();
                                    }, 200);
                                })
                            }
                        }
                    }

                    let a = filteredUserCreator[0];
                    if (a.shop_offline) {
                        swal(
                            "Niet meer beschikbaar",
                            "Helaas is het product niet meer beschikbaar",
                            "error"
                        ).then(() => {
                            firebase.database().ref("bhodi_products/" + key).off()

                            setTimeout(() => {
                                history.goBack();
                            }, 200);
                        })
                    }
                    if (this.props.global_values.productsTimer) {
                        this.setState({ productsTimer: this.props.global_values.productsTimer })
                    }
                    if (a.productsTimerSettedAt) {
                        this.setState({ productsTimerSettedAt: a.productsTimerSettedAt })
                    }
                    if (a.productsInfo) {
                        if (a.productsInfo.globalDesc) {
                            this.setState({
                                globalDesc: a.productsInfo.globalDesc,
                            })
                        }
                        if (a.productsInfo.deliveryNote) {
                            this.setState({
                                deliveryNote: a.productsInfo.deliveryNote,
                            })
                        }
                        if (a.productsInfo.freeItemText) {
                            this.setState({
                                freeItemText: a.productsInfo.freeItemText,
                            })
                        }
                        if (a.productsInfo.globalGender) {
                            this.setState({
                                globalGender: a.productsInfo.globalGender,
                            })
                        }
                    }
                    this.setState({ currentUserCreator: a })
                }
                let a = filtered[0]

                if (a && a.comments) {
                    let arr = [];
                    for (let key in a.comments) {
                        arr.push(a.comments[key]);
                    }
                    if (this.state.commentsRepliesOpened) {
                        let filteredInner = arr.filter((y) => {
                            return this.state.commentKey === y.key;
                        });
                        let arrInner = [];
                        if (filteredInner[0].comments) {
                            for (let key in filteredInner[0].comments) {
                                arrInner.push(filteredInner[0].comments[key]);
                            }
                            this.setState({ commentsReplies: arrInner });
                        } else {
                            this.setState({ commentsReplies: [] });
                        }
                    }
                    this.setState({ commentsHere: arr.reverse() });
                } else {
                    this.setState({ commentsHere: [] });
                }

                let allImagesNew = []
                let indexOfImage = 0
                let featuredImage = 0
                if (filtered[0].images && filtered[0].images.length > 0) {
                    // let findIndex = filtered[0].images.findIndex((g) => {
                    //     return g.includes("_1")
                    // })
                    // if (findIndex !== -1) {
                    //     indexOfImage = findIndex
                    // }

                    let duplicated = Array.from(filtered[0].images)

                    let allImagesUrl = []
                    duplicated.map((g) => {
                        let filePath = g.split("/")[7].split("?")[0]
                        allImagesUrl.push(filePath)
                    })

                    allImagesUrl.sort().map((t) => {
                        let filtered = duplicated.filter((g) => {
                            return g.includes(t)
                        })

                        if (filtered.length > 0) {
                            allImagesNew.push(filtered[0])
                        }
                    })
                    if (typeof filtered[0].featuredImage !== "undefined" && filtered[0].featuredImage !== null) {
                        if (filtered[0].featuredImage < filtered[0].images.length) {
                            let filteredIndex = allImagesNew.findIndex((g) => {
                                return g === filtered[0].images[filtered[0].featuredImage]
                            })
                            if (filteredIndex !== -1) {
                                indexOfImage = filteredIndex
                            }
                        }
                    }
                }
                this.setState({ currentProduct: filtered[0], allProductImages: allImagesNew, currentImageIndex: indexOfImage })
                // this.setState({ currentProduct: filtered[0], allProductImages: allImagesNew, currentImageIndex: indexOfImage === 0 ? ((filtered[0].featuredImage && filtered[0].images && filtered[0].featuredImage < filtered[0].images.length) ? filtered[0].featuredImage : indexOfImage) : indexOfImage })
                // this.setState({ currentProduct: filtered[0], currentImageIndex: indexOfImage === 0 ? (filtered[0].featuredImage ? filtered[0].featuredImage : indexOfImage) : indexOfImage })
            }
            //  else {
            //     swal(
            //         "Important",
            //         "Product is deleted by company",
            //         "error"
            //     ).then(() => {
            //         history.goBack();
            //     });
            // }
        }

        console.log(this.props.remainingProducts, '/check out here')
        firebase.database().ref("bhodi_products/" + key).on("value", (data) => {
            let a = data.val()
            if (!uid) {
                if (!a.availableForGuests) {
                    this.setState({ dontRenderProduct: true })
                    swal(
                        "Niet meer beschikbaar",
                        "Helaas is het product niet meer beschikbaar",
                        "error"
                    ).then(() => {
                        window.location.href = "/"
                    })
                }
            }
            if (!window.location.href.includes("product-detailed") && !this.props.defaultProduct) {
                firebase.database().ref("bhodi_products/" + key).off()
            }
            if (a && window.location.href.includes("product-detailed")) {
                if (a.variations_data) {
                    let objected = Object.entries(a.variations_data)
                    let filtered = objected.filter((t) => {
                        return Number(t[1].qty) > 0
                    })
                    if (filtered.length === 0) {
                        swal(
                            "Niet meer beschikbaar",
                            "Helaas is het product niet meer beschikbaar",
                            "error"
                        ).then(() => {
                            firebase.database().ref("bhodi_products/" + key).off()

                            setTimeout(() => {
                                history.goBack();
                            }, 200);
                        });
                    }
                } else {
                    if (Number(a.qty) === 0) {
                        swal(
                            "Niet meer beschikbaar",
                            "Helaas is het product niet meer beschikbaar",
                            "error"
                        ).then(() => {
                            firebase.database().ref("bhodi_products/" + key).off()

                            setTimeout(() => {
                                history.goBack();
                            }, 200);
                        });
                    }
                }

                if (!a.active) {
                    if (!this.props.onMainpage && window.location.href.includes("product-detailed")) {
                        swal(
                            "Niet meer beschikbaar",
                            "Helaas is het product niet meer beschikbaar",
                            "error"
                        ).then(() => {
                            firebase.database().ref("bhodi_products/" + key).off()

                            setTimeout(() => {
                                history.goBack();
                            }, 200);
                        });
                    }
                }

                if (a.liked_by) {
                    if (document.querySelector(`#likesCount`)) {
                        document.querySelector(`#likesCount`).innerText = Object.entries(
                            a.liked_by
                        ).length;
                    }
                } else {
                    if (document.querySelector(`#likesCount`)) {
                        document.querySelector(`#likesCount`).innerText = "0";
                    }
                }

                if (a && a.comments) {
                    let arr = [];
                    for (let key in a.comments) {
                        arr.push(a.comments[key]);
                    }
                    if (this.state.commentsRepliesOpened) {
                        let filteredInner = arr.filter((y) => {
                            return this.state.commentKey === y.key;
                        });
                        let arrInner = [];
                        if (filteredInner[0].comments) {
                            for (let key in filteredInner[0].comments) {
                                arrInner.push(filteredInner[0].comments[key]);
                            }
                            this.setState({ commentsReplies: arrInner });
                        } else {
                            this.setState({ commentsReplies: [] });
                        }
                    }
                    this.setState({ commentsHere: arr.reverse() });
                } else {
                    this.setState({ commentsHere: [] });
                }

                let indexOfImage = 0
                let allImagesNew = []
                if (a.images && a.images.length > 0) {
                    // let findIndex = a.images.findIndex((g) => {
                    //     return g.includes("_1")
                    // })
                    // if (findIndex !== -1) {
                    //     indexOfImage = findIndex
                    // }

                    let duplicated = Array.from(a.images)

                    let allImagesUrl = []
                    duplicated.map((g) => {
                        let filePath = g.split("/")[7].split("?")[0]
                        allImagesUrl.push(filePath)
                    })

                    allImagesUrl.sort().map((t) => {
                        let filtered = duplicated.filter((g) => {
                            return g.includes(t)
                        })

                        if (filtered.length > 0) {
                            allImagesNew.push(filtered[0])
                        }
                    })
                    if (typeof a.featuredImage !== "undefined" && a.featuredImage !== null) {
                        if (a.featuredImage < a.images.length) {
                            let filteredIndex = allImagesNew.findIndex((g) => {
                                return g === a.images[a.featuredImage]
                            })
                            if (filteredIndex !== -1) {
                                indexOfImage = filteredIndex
                            }
                        }
                    }
                }


                // let allProducts = [...this.props.bhodi_products]
                // let filteredIndex = allProducts.findIndex((e) => {
                //     return e.pushKey === a.pushKey
                // })
                // if (filteredIndex !== -1) {
                //     allProducts.splice(filteredIndex, 1, a)
                //     this.props.updateBhodiProducts(allProducts)
                // }

                this.setState({ currentProduct: a, allProductImages: allImagesNew, currentImageIndex: indexOfImage })
                // this.setState({ currentProduct: a, allProductImages: allImagesNew, currentImageIndex: indexOfImage === 0 ? ((a.featuredImage && a.images && a.featuredImage < a.images.length) ? a.featuredImage : indexOfImage) : indexOfImage })
                // this.setState({ currentProduct: a, currentImageIndex: indexOfImage })
            } else {
                if (!this.props.onMainpage && !a) {
                    swal(
                        "LET OP",
                        "Het product is helaas zojuist verwijderd door de aanbieder.",
                        "error"
                    ).then(() => {
                        history.goBack();
                    });
                }
            }
        })
    }

    componentWillUnmount() {
        // if (this.state.currentMemberData) {
        //     dbRef.orderByChild("created_by").equalTo(this.state.currentMemberData.user_id).off()
        // }

        let key = new URL(window.location.href).searchParams.get("key")

        if (this.props.defaultProduct) {
            key = this.props.defaultProduct.pushKey
        }

        firebase.database().ref("bhodi_products/" + key).off()
    }

    componentDidUpdate(prevProps) {
        if (this.props.guest_user_cart) {
            if (prevProps.guest_user_cart !== this.props.guest_user_cart) {
                this.setState({ guestUserCart: this.props.guest_user_cart });
            }
        }
    }

    checkForShowingIndexes(currentMemberData) {
        let maxProductsToShow = this.state.maxProductsToShow
        if (currentMemberData.productsInfo && currentMemberData.productsInfo.maxProductsToShow) {
            maxProductsToShow = currentMemberData.productsInfo.maxProductsToShow
            this.setState({ maxProductsToShow: currentMemberData.productsInfo.maxProductsToShow })
        }

        let indexToStartProducts = 0
        let indexToStartProductsWeekly = 0
        let indexToStartProductsDaily = 0

        let allDatesWeekly = []
        let currentWeekAt = ""
        let currentDayAt = ""
        let allDatesDaily = []

        if (currentMemberData) {
            this.setState({ currentMemberData })
            if (currentMemberData.announcement && currentMemberData.announcement.active) {
                if (new Date(Date.parse(currentMemberData.announcement.end_date_iso)).getTime() > new Date().getTime()) {
                    this.setState({ showBannerAnnouncement: true, loading: false })
                }
            }
            firebase
                .database()
                .ref("users/" + currentMemberData.user_id)
                .on("value", (data) => {
                    let a = data.val();
                    if (a) {
                        if (a.shop_offline) {
                            swal(
                                "Niet meer beschikbaar",
                                "Helaas is het product niet meer beschikbaar",
                                "error"
                            ).then(() => {
                                history.goBack();
                            })
                        }
                        if (this.props.global_values.productsTimer) {
                            this.setState({ productsTimer: this.props.global_values.productsTimer })
                        }
                        if (a.productsTimerSettedAt) {
                            this.setState({ productsTimerSettedAt: a.productsTimerSettedAt })
                        }
                        if (a.productsInfo) {
                            if (a.productsInfo.globalDesc) {
                                this.setState({
                                    globalDesc: a.productsInfo.globalDesc,
                                })
                            }
                            if (a.productsInfo.deliveryNote) {
                                this.setState({
                                    deliveryNote: a.productsInfo.deliveryNote,
                                })
                            }
                            if (a.productsInfo.freeItemText) {
                                this.setState({
                                    freeItemText: a.productsInfo.freeItemText,
                                })
                            }
                            if (a.productsInfo.globalGender) {
                                this.setState({
                                    globalGender: a.productsInfo.globalGender,
                                })
                            }
                        }
                        this.setState({ currentUserCreator: a })

                        if (a.productsInfo && a.productsInfo.maxProductsToShow && a.productsInfo.maxProductsToShow !== this.state.maxProductsToShow) {
                            maxProductsToShow = a.productsInfo.maxProductsToShow
                            this.setState({ maxProductsToShow: a.productsInfo.maxProductsToShow })
                        }

                        this.setState({ currentMemberData: a });

                        setTimeout(() => {
                            if (this.props.bhodi_products) {
                                let filtered = this.props.bhodi_products.filter((g) => {
                                    let isAvailable = true
                                    return g.created_by === currentMemberData.user_id && isAvailable
                                })

                                let isRepeating = false
                                let availableProducts = []
                                filtered.map((product, index) => {
                                    let currentProduct = Object.assign({}, product)
                                    if (product.active) {
                                        if (product.inCartOf || product.verzilvered_by) {
                                            if (currentProduct.sizesActive) {
                                                let stocksAvailableSizes = []
                                                currentProduct.variations_data.map((e) => {
                                                    let filteredStockLength = []
                                                    let filteredStockLengthVerzilvered = []
                                                    if (product.inCartOf) {
                                                        filteredStockLength = Object.entries(product.inCartOf).filter((t) => {
                                                            return t[1].selectedSize === e.size
                                                        })
                                                    }
                                                    if (product.verzilvered_by) {
                                                        filteredStockLengthVerzilvered = Object.entries(product.verzilvered_by).filter((t) => {
                                                            return t[1].selectedSize === e.size
                                                        })
                                                    }
                                                    if ((filteredStockLength.length + filteredStockLengthVerzilvered.length) < Number(e.qty)) {
                                                        stocksAvailableSizes.push(e)
                                                    }
                                                })
                                                if (stocksAvailableSizes.length === 0) {
                                                    console.log("no stock")
                                                } else {
                                                    availableProducts.push(product)
                                                }
                                            } else {
                                                let objectedCart = []
                                                let objectedVerzilvered = []

                                                if (product.inCartOf) {
                                                    objectedCart = Object.entries(product.inCartOf)
                                                }
                                                if (product.verzilvered_by) {
                                                    objectedVerzilvered = Object.entries(product.verzilvered_by)
                                                }
                                                if ((objectedCart.length + objectedVerzilvered.length) >= Number(currentProduct.qty)) {
                                                    console.log("no stock")
                                                } else {
                                                    availableProducts.push(product)
                                                }
                                            }
                                        } else {
                                            if (product.sizesActive) {
                                                let stocksAvailableSizes = []
                                                product.variations_data.map((e) => {
                                                    if (Number(e.qty) === 0) {
                                                        return false;
                                                    }
                                                    let filteredStockLength = []
                                                    let filteredStockLengthVerzilvered = []
                                                    if (product.inCartOf) {
                                                        filteredStockLength = Object.entries(product.inCartOf).filter((t) => {
                                                            return t[1].selectedSize === e.size
                                                        })
                                                    }
                                                    if (product.verzilvered_by) {
                                                        filteredStockLengthVerzilvered = Object.entries(product.verzilvered_by).filter((t) => {
                                                            return t[1].selectedSize === e.size
                                                        })
                                                    }
                                                    if ((filteredStockLength.length + filteredStockLengthVerzilvered.length) < Number(e.qty)) {
                                                        stocksAvailableSizes.push(e)
                                                    }
                                                })
                                                if (stocksAvailableSizes.length === 0) {
                                                    return false;
                                                }
                                                availableProducts.push(product)
                                            } else {
                                                if (Number(product.qty) === 0) {
                                                    return false;
                                                }
                                                availableProducts.push(product)
                                            }
                                        }
                                    }
                                })

                                this.processProducts(filtered, maxProductsToShow, a.recurring, indexToStartProducts, "", isRepeating, a)
                            }
                        }, 2000);
                    }
                });
        }

        let userid = localStorage.getItem("userid");
        this.setState({ currentUserId: userid })

        let lastCaught = ""
        dbRef.orderByChild("created_by").equalTo(currentMemberData.user_id).on("value", (data) => {
            let a = data.val()
            if (a) {
                let allProducts = []
                let objected = Object.entries(a)
                objected.map((e) => {
                    // if (e[1].images) {
                    allProducts.push(e[1])
                    // }
                })

                let bhodiProducts = this.props.bhodi_products
                if (bhodiProducts && bhodiProducts.length > 0) {
                    let arrToSet = []
                    bhodiProducts.map((e) => {
                        let alreadyAvailable = allProducts.filter((t) => {
                            return t.pushKey === e.pushKey
                        })
                        if (alreadyAvailable.length > 0) {
                            arrToSet.push(alreadyAvailable[0])
                        } else {
                            arrToSet.push(e)
                        }
                    })

                    this.props.updateBhodiProducts(arrToSet)
                }

                let isRepeating = false
                let availableProducts = []
                allProducts.map((product, index) => {
                    let currentProduct = Object.assign({}, product)
                    if (product.active) {
                        if (product.inCartOf || product.verzilvered_by) {
                            if (currentProduct.sizesActive) {
                                let stocksAvailableSizes = []
                                currentProduct.variations_data.map((e) => {
                                    let filteredStockLength = []
                                    let filteredStockLengthVerzilvered = []
                                    if (product.inCartOf) {
                                        filteredStockLength = Object.entries(product.inCartOf).filter((t) => {
                                            return t[1].selectedSize === e.size
                                        })
                                    }
                                    if (product.verzilvered_by) {
                                        filteredStockLengthVerzilvered = Object.entries(product.verzilvered_by).filter((t) => {
                                            return t[1].selectedSize === e.size
                                        })
                                    }
                                    if ((filteredStockLength.length + filteredStockLengthVerzilvered.length) < Number(e.qty)) {
                                        stocksAvailableSizes.push(e)
                                    }
                                })
                                if (stocksAvailableSizes.length === 0) {
                                    console.log("no stock")
                                } else {
                                    availableProducts.push(product)
                                }
                            } else {
                                let objectedCart = []
                                let objectedVerzilvered = []

                                if (product.inCartOf) {
                                    objectedCart = Object.entries(product.inCartOf)
                                }
                                if (product.verzilvered_by) {
                                    objectedVerzilvered = Object.entries(product.verzilvered_by)
                                }
                                if ((objectedCart.length + objectedVerzilvered.length) >= Number(currentProduct.qty)) {
                                    console.log("no stock")
                                } else {
                                    availableProducts.push(product)
                                }
                            }
                        } else {
                            if (product.sizesActive) {
                                let stocksAvailableSizes = []
                                product.variations_data.map((e) => {
                                    if (Number(e.qty) === 0) {
                                        return false;
                                    }
                                    let filteredStockLength = []
                                    let filteredStockLengthVerzilvered = []
                                    if (product.inCartOf) {
                                        filteredStockLength = Object.entries(product.inCartOf).filter((t) => {
                                            return t[1].selectedSize === e.size
                                        })
                                    }
                                    if (product.verzilvered_by) {
                                        filteredStockLengthVerzilvered = Object.entries(product.verzilvered_by).filter((t) => {
                                            return t[1].selectedSize === e.size
                                        })
                                    }
                                    if ((filteredStockLength.length + filteredStockLengthVerzilvered.length) < Number(e.qty)) {
                                        stocksAvailableSizes.push(e)
                                    }
                                })
                                if (stocksAvailableSizes.length === 0) {
                                    return false;
                                }
                                availableProducts.push(product)
                            } else {
                                if (Number(product.qty) === 0) {
                                    return false;
                                }
                                availableProducts.push(product)
                            }
                            // availableProducts.push(product)
                        }
                    }
                })

                this.processProducts(allProducts, maxProductsToShow, currentMemberData.recurring, indexToStartProducts, "", isRepeating, currentMemberData)
            }
        })
    }

    processProducts(filtered, maxProductsToShow, recurring, indexToStartProducts, avoidAvailability, isRepeating, currentMemberData) {
        let mustProcess = true
        if (!currentMemberData.recurring) {
            if (this.state.keepShowing) {
                mustProcess = false
            }
        } else {
            if (!currentMemberData.recurring.active) {
                if (this.state.keepShowing) {
                    mustProcess = false
                }
            }
        }

        let currentIndex = indexToStartProducts - 1
        let sortedProducts = [...filtered].sort((a, b) => {
            return a.sort_time * 100 - b.sort_time * 100;
        });

        if (filtered.length > 0) {
            let availableStockProducts = []
            let availableIndexes = []

            if (recurring && recurring.active) {
                sortedProducts.map((product, index) => {
                    if (isRepeating) {
                        let currentProduct = Object.assign({}, product)
                        if (product.active) {
                            if (product.inCartOf || product.verzilvered_by) {
                                if (currentProduct.sizesActive) {
                                    let stocksAvailableSizes = []
                                    currentProduct.variations_data.map((e) => {
                                        let filteredStockLength = []
                                        let filteredStockLengthVerzilvered = []
                                        if (product.inCartOf) {
                                            filteredStockLength = Object.entries(product.inCartOf).filter((t) => {
                                                return t[1].selectedSize === e.size
                                            })
                                        }
                                        if (product.verzilvered_by) {
                                            filteredStockLengthVerzilvered = Object.entries(product.verzilvered_by).filter((t) => {
                                                return t[1].selectedSize === e.size
                                            })
                                        }
                                        if ((filteredStockLength.length + filteredStockLengthVerzilvered.length) < Number(e.qty)) {
                                            stocksAvailableSizes.push(e)
                                        }
                                    })
                                    if (stocksAvailableSizes.length === 0) {
                                        console.log("no stock")
                                    } else {
                                        availableStockProducts.push(product)
                                        availableIndexes.push(index)
                                    }
                                } else {
                                    let objectedCart = []
                                    let objectedVerzilvered = []

                                    if (product.inCartOf) {
                                        objectedCart = Object.entries(product.inCartOf)
                                    }
                                    if (product.verzilvered_by) {
                                        objectedVerzilvered = Object.entries(product.verzilvered_by)
                                    }
                                    if ((objectedCart.length + objectedVerzilvered.length) >= Number(currentProduct.qty)) {
                                        console.log("no stock")
                                    } else {
                                        availableStockProducts.push(product)
                                        availableIndexes.push(index)
                                    }
                                }
                            } else {
                                if (currentProduct.sizesActive) {
                                    let stocksAvailableSizes = []
                                    currentProduct.variations_data.map((e) => {
                                        if (Number(e.qty) === 0) {
                                            return false;
                                        }
                                        let filteredStockLength = []
                                        let filteredStockLengthVerzilvered = []
                                        if (product.inCartOf) {
                                            filteredStockLength = Object.entries(product.inCartOf).filter((t) => {
                                                return t[1].selectedSize === e.size
                                            })
                                        }
                                        if (product.verzilvered_by) {
                                            filteredStockLengthVerzilvered = Object.entries(product.verzilvered_by).filter((t) => {
                                                return t[1].selectedSize === e.size
                                            })
                                        }
                                        if ((filteredStockLength.length + filteredStockLengthVerzilvered.length) < Number(e.qty)) {
                                            stocksAvailableSizes.push(e)
                                        }
                                    })
                                    if (stocksAvailableSizes.length === 0) {
                                        return false;
                                    }
                                    availableStockProducts.push(product)
                                    availableIndexes.push(index)
                                } else {
                                    if (Number(currentProduct.qty) === 0) {
                                        return false;
                                    }
                                    availableStockProducts.push(product)
                                    availableIndexes.push(index)
                                }
                            }
                        }
                    } else {
                        availableStockProducts.push(product)
                        availableIndexes.push(index)
                    }
                })

                let productsAvailableForRecurr = []
                sortedProducts.map((product, index) => {
                    let currentProduct = Object.assign({}, product)
                    if (product.active) {
                        if (product.inCartOf || product.verzilvered_by) {
                            if (currentProduct.sizesActive) {
                                let stocksAvailableSizes = []
                                currentProduct.variations_data.map((e) => {
                                    let filteredStockLength = []
                                    let filteredStockLengthVerzilvered = []
                                    if (product.inCartOf) {
                                        filteredStockLength = Object.entries(product.inCartOf).filter((t) => {
                                            return t[1].selectedSize === e.size
                                        })
                                    }
                                    if (product.verzilvered_by) {
                                        filteredStockLengthVerzilvered = Object.entries(product.verzilvered_by).filter((t) => {
                                            return t[1].selectedSize === e.size
                                        })
                                    }
                                    if ((filteredStockLength.length + filteredStockLengthVerzilvered.length) < Number(e.qty)) {
                                        stocksAvailableSizes.push(e)
                                    }
                                })
                                if (stocksAvailableSizes.length === 0) {
                                    console.log("no stock")
                                } else {
                                    productsAvailableForRecurr.push(product)
                                }
                            } else {
                                let objectedCart = []
                                let objectedVerzilvered = []

                                if (product.inCartOf) {
                                    objectedCart = Object.entries(product.inCartOf)
                                }
                                if (product.verzilvered_by) {
                                    objectedVerzilvered = Object.entries(product.verzilvered_by)
                                }
                                if ((objectedCart.length + objectedVerzilvered.length) >= Number(currentProduct.qty)) {
                                    console.log("no stock")
                                } else {
                                    productsAvailableForRecurr.push(product)
                                }
                            }
                        } else {
                            productsAvailableForRecurr.push(product)
                        }
                    }
                })

                this.setState({ productsAvailableForRecurr })

            } else {
                sortedProducts.map((product, index) => {
                    let currentProduct = Object.assign({}, product)
                    if (product.variations_data) {
                        let objected = Object.entries(product.variations_data)
                        let filtered = objected.filter((t) => {
                            return Number(t[1].qty) > 0
                        })
                        if (filtered.length === 0) {
                            return false;
                        }
                    } else {
                        if (Number(product.qty) === 0) {
                            return false;
                        }
                    }
                    if (!product.images) {
                        return false;
                    }
                    if (product.active) {
                        if (product.inCartOf || product.verzilvered_by) {
                            if (currentProduct.sizesActive) {
                                let stocksAvailableSizes = []
                                currentProduct.variations_data.map((e) => {
                                    let filteredStockLength = []
                                    let filteredStockLengthVerzilvered = []
                                    if (product.inCartOf) {
                                        filteredStockLength = Object.entries(product.inCartOf).filter((t) => {
                                            return t[1].selectedSize === e.size
                                        })
                                    }
                                    if (product.verzilvered_by) {
                                        filteredStockLengthVerzilvered = Object.entries(product.verzilvered_by).filter((t) => {
                                            return t[1].selectedSize === e.size
                                        })
                                    }
                                    if ((filteredStockLength.length + filteredStockLengthVerzilvered.length) < Number(e.qty)) {
                                        stocksAvailableSizes.push(e)
                                    }
                                })
                                if (stocksAvailableSizes.length === 0) {
                                    console.log("no stock")
                                } else {
                                    availableStockProducts.push(product)
                                    availableIndexes.push(index)
                                }
                            } else {
                                let objectedCart = []
                                let objectedVerzilvered = []

                                if (product.inCartOf) {
                                    objectedCart = Object.entries(product.inCartOf)
                                }
                                if (product.verzilvered_by) {
                                    objectedVerzilvered = Object.entries(product.verzilvered_by)
                                }
                                if ((objectedCart.length + objectedVerzilvered.length) >= Number(currentProduct.qty)) {
                                    console.log("no stock")
                                } else {
                                    availableStockProducts.push(product)
                                    availableIndexes.push(index)
                                }
                            }
                        } else {
                            availableStockProducts.push(product)
                            availableIndexes.push(index)
                        }
                    }
                })
            }

            if (availableStockProducts.length === 3) {
                this.setState({ keepShowing: true })
            } else {
                this.setState({ keepShowing: false })
            }

            if (availableStockProducts.length === 0) {
                this.setState({ noCarouselProducts: true })
            }
            let maxIndexesToShow = []
            let maxIndexesToShowNext = []

            maxIndexesToShow = [...availableIndexes].splice(0, maxProductsToShow).reverse()

            maxIndexesToShowNext = availableIndexes.splice(0, maxProductsToShow).reverse()

            let productsToRender = []
            let productsAllRecurring = []

            maxIndexesToShow.map((index, ind) => {
                let currentProduct = Object.assign(sortedProducts[index], {})
                productsToRender.push(currentProduct)
            })

            let productsToRenderNext = []
            maxIndexesToShowNext.map((index, ind) => {
                let currentProduct = Object.assign(sortedProducts[index], {})
                productsToRenderNext.push(currentProduct)
            })

            this.setState({ productsToRender })

        }
    }


    checkForBookMarkIcon(i) {
        let currentProduct = this.state.currentProduct;
        if (this.state.currentUserHere) {
            if (this.state.currentUserHere.bookmarked_things) {
                let objected = Object.entries(
                    this.state.currentUserHere.bookmarked_things
                );
                let filtered = objected.filter((g) => {
                    return g[1].productPush === currentProduct.pushKey;
                });
                if (filtered.length > 0) {
                    return (
                        <div
                            className="icon-riimage"
                            onClick={this.unBookmarkCurrentProduct.bind(this, i)}
                        >
                            <BsBookmarkFill />
                        </div>
                    );
                } else {
                    return (
                        <div
                            className="icon-riimage"
                            onClick={this.bookmarkCurrentProduct.bind(this, i)}
                        >
                            <BsBookmark />
                        </div>
                    );
                }
            } else {
                return (
                    <div
                        className="icon-riimage"
                        onClick={this.bookmarkCurrentProduct.bind(this, i)}
                    >
                        <BsBookmark />
                    </div>
                );
            }
        }
    }

    bookmarkCurrentProduct() {
        let currentProduct = this.state.currentProduct;
        let e = currentProduct;
        let uid = localStorage.getItem("userid");
        let productToBookmarkKey = firebase
            .database()
            .ref("users/" + uid + "/bookmarked_things")
            .push().key;
        let productToBookmarkKeySec = firebase
            .database()
            .ref(
                "bhodi_products/" +
                currentProduct.pushKey +
                "/bookmarked_by/"
            )
            .push().key;
        let obj = {
            productPush: currentProduct.pushKey,
            pushKey: productToBookmarkKey,
            type: "product",
        };
        let objSec = {
            bookmarked_by: uid,
            pushKey: productToBookmarkKeySec,
            type: "product",
        };
        firebase
            .database()
            .ref("users/" + uid + "/bookmarked_things/" + productToBookmarkKey)
            .set(obj);
        firebase
            .database()
            .ref(
                "bhodi_products/" +
                currentProduct.pushKey +
                "/bookmarked_by/" +
                productToBookmarkKeySec
            )
            .set(objSec);


        let notificationToShow = document.getElementById("mustShowOnFavoriteProducten");
        if (notificationToShow) {
            notificationToShow.style.opacity = 1;
            notificationToShow.style.pointerEvents = "all";
        }

        setTimeout(() => {
            if (notificationToShow) {
                notificationToShow.style.opacity = 0;
                notificationToShow.style.pointerEvents = "none";
            }
        }, 4000);
    }

    unBookmarkCurrentProduct(i) {
        let uid = localStorage.getItem("userid");
        let currentProduct = this.state.currentProduct;
        let e = currentProduct;
        if (this.state.currentUserHere.bookmarked_things) {
            let objected = Object.entries(
                this.state.currentUserHere.bookmarked_things
            );
            let filtered = objected.filter((y) => {
                return y[1].productPush === currentProduct.pushKey;
            });
            if (filtered.length > 0) {
                firebase
                    .database()
                    .ref("users/" + uid + "/bookmarked_things/" + filtered[0][0])
                    .remove();
            }
            if (currentProduct.bookmarked_by) {
                let objectedSec = Object.entries(currentProduct.bookmarked_by);
                let filteredSec = objectedSec.filter((y) => {
                    return y[1].bookmarked_by === uid;
                });
                firebase
                    .database()
                    .ref(
                        "bhodi_products/" +
                        currentProduct.pushKey +
                        "/bookmarked_by/" +
                        filteredSec[0][0]
                    )
                    .remove();
            }
        }
    }

    checkForMemberImage() {
        let filtered = this.props.user.filter((g) => {
            return g.user_id === this.state.currentProduct.created_by;
        });
        if (filtered.length > 0) {
            if (filtered[0].profile_image) {
                return filtered[0].profile_image;
            } else {
                return "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9";
            }
        }
    }

    getRightIcons() {
        return (<div className='right-icons-image'>
            {window.innerWidth < 1008 && (<img src={this.checkForMemberImage()} className="right-memimage"
            // onClick={() => {
            //     let userid = localStorage.getItem("userid");
            //     if (window.innerWidth < 1008 && this.state.currentProduct.created_by !== userid) {
            //         this.setState({ showPopupFriendsFollow: true })
            //     } else {
            //         let filtered = this.props.user.filter((g) => {
            //             return g.user_id === this.state.currentProduct.created_by;
            //         });
            //         if (filtered.length > 0) {
            //             localStorage.removeItem("onCurrentTab");
            //             localStorage.removeItem("onCurrentTabCompany");
            //             if (filtered[0].type === "user") {
            //                 history.push(`/member/${filtered[0].user_name_id}`);
            //             } else {
            //                 history.push(`/company/${filtered[0].user_name_id}`);
            //             }
            //         }
            //     }
            // }}
            />)}
            {window.innerWidth < 1008 && (<div className='cart-mob-productdetailed'>
                <CartNonUser notNeedOrders={true} hideFooterOnOpen={true} />
            </div>)}
        </div>)
    }

    checkForFollowText() {
        let userid = localStorage.getItem("userid");

        let filtered = this.props.user.filter((g) => {
            return g.user_id === this.state.currentProduct.created_by;
        });

        if (filtered[0].user_id !== userid) {
            if (filtered.length > 0) {
                if (filtered[0].followed_by) {
                    let objected = Object.entries(filtered[0].followed_by);
                    let filteredInner = objected.filter((g) => {
                        return g[1].user_id === userid;
                    });
                    if (filteredInner.length > 0) {
                        return (
                            <div className='icon-riimage'
                                onClick={() => {
                                    this.setState({ showPopupFriendsFollow: true })
                                }}>
                                <BsFillPersonXFill />
                            </div>
                        );
                    } else {
                        if (filtered[0].friends) {
                            let objected = Object.entries(filtered[0].friends);
                            let filteredInnerFriends = objected.filter((g) => {
                                return g[1].user_id === userid;
                            });
                            if (filteredInnerFriends.length > 0) {
                                return (
                                    <div className='icon-riimage'
                                        onClick={() => {
                                            this.setState({ showPopupFriendsFollow: true })
                                        }}>
                                        <BsFillPersonXFill />
                                    </div>
                                );
                            } else {
                                return (
                                    <div className='icon-riimage'
                                        onClick={() => {
                                            this.setState({ showPopupFriendsFollow: true })
                                        }}>
                                        <BsFillPersonPlusFill />
                                    </div>
                                );
                            }
                        } else {
                            return (
                                <div className='icon-riimage'
                                    onClick={() => {
                                        this.setState({ showPopupFriendsFollow: true })
                                    }}>
                                    <BsFillPersonPlusFill />
                                </div>
                            );
                        }
                    }
                } else {
                    if (filtered[0].friends) {
                        let objected = Object.entries(filtered[0].friends);
                        let filteredInnerFriends = objected.filter((g) => {
                            return g[1].user_id === userid;
                        });
                        if (filteredInnerFriends.length > 0) {
                            return (
                                <div className='icon-riimage'
                                    onClick={() => {
                                        this.setState({ showPopupFriendsFollow: true })
                                    }}>
                                    <BsFillPersonXFill />
                                </div>
                            );
                        } else {
                            return (
                                <div className='icon-riimage'
                                    onClick={() => {
                                        this.setState({ showPopupFriendsFollow: true })
                                    }}>
                                    <BsFillPersonPlusFill />
                                </div>
                            );
                        }
                    } else {
                        return (
                            <div className='icon-riimage'
                                onClick={() => {
                                    this.setState({ showPopupFriendsFollow: true })
                                }}>
                                <BsFillPersonPlusFill />
                            </div>
                        );
                    }
                }
            }
        }
    }

    checkForTimerOrWinkelmand() {
        if (this.state.productsTimer && this.state.currentProduct.productTimerMax) {
            let dateCreated = new Date(this.state.currentProduct.productTimerMax)

            let splitted = this.state.productsTimer.split(" ")
            if (splitted[1].includes("minute")) {
                dateCreated.setMinutes(dateCreated.getMinutes() + Number(splitted[0]))
            } else {
                dateCreated.setHours(dateCreated.getHours() + Number(splitted[0]))
            }
            if (dateCreated.getTime() > new Date().getTime()) {
                return <ButtonTimerProducts timeToCalculate={dateCreated.getTime()} />
            }
        }
    }

    checkForNextProduct(productParam) {
        let filteredUserCreator = this.props.user.filter((j) => {
            return j.user_id === productParam.created_by;
        });
        let filteredUserProducts = this.props.bhodi_products.filter((j) => {
            return j.created_by === productParam.created_by;
        });
        // this.checkForShowingIndexes(filteredUserCreator[0])

        if (filteredUserCreator.length > 0) {
            if (filteredUserProducts.length > 0) {
                let maxProductsToShow = 4

                if (productParam.isSpecialProduct || productParam.availableForCompanyGuest) {
                    maxProductsToShow = 5
                    if (this.props.global_values) {
                        if (this.props.global_values.products_count_new) {
                            maxProductsToShow = Number(this.props.global_values.products_count_new)
                        }
                    }
                } else if (filteredUserCreator[0].productsInfo) {
                    if (filteredUserCreator[0].productsInfo.maxProductsToShow) {
                        maxProductsToShow = Number(filteredUserCreator[0].productsInfo.maxProductsToShow)
                    }
                }

                let filteredProducts = filteredUserProducts.filter((product) => {
                    if (product.sizesActive) {
                        let stocksAvailableSizes = []
                        product.variations_data.map((e) => {
                            if (Number(e.qty) === 0) {
                                return false;
                            }
                            let filteredStockLength = []
                            let filteredStockLengthVerzilvered = []
                            if (product.inCartOf) {
                                filteredStockLength = Object.entries(product.inCartOf).filter((t) => {
                                    return t[1].selectedSize === e.size
                                })
                            }
                            if (product.verzilvered_by) {
                                filteredStockLengthVerzilvered = Object.entries(product.verzilvered_by).filter((t) => {
                                    return t[1].selectedSize === e.size
                                })
                            }
                            if ((filteredStockLength.length + filteredStockLengthVerzilvered.length) < Number(e.qty)) {
                                stocksAvailableSizes.push(e)
                            }
                        })
                        if (stocksAvailableSizes.length === 0) {
                            return false;
                        }
                    } else {
                        let filteredStockLength = []
                        let filteredStockLengthVerzilvered = []
                        if (product.inCartOf) {
                            filteredStockLength = Object.entries(product.inCartOf).filter((t) => {
                                return !t[1].selectedSize
                            })
                        }
                        if (product.verzilvered_by) {
                            filteredStockLengthVerzilvered = Object.entries(product.verzilvered_by).filter((t) => {
                                return !t[1].selectedSize
                            })
                        }
                        if (Number(product.qty) <= (filteredStockLength.length + filteredStockLengthVerzilvered.length) || Number(product.qty) === 0) {
                            return false;
                        }
                        // if (Number(product.qty) === 0) {
                        //     return false;
                        // }
                    }
                    if (product.availableForCompanyGuest) {
                        return !product.availableOnTime && product.images && product.active && product.availableForCompanyGuest
                    } else if (product.isSpecialProduct) {
                        return !product.availableOnTime && product.images && product.active && product.isSpecialProduct
                    } else {
                        return !product.availableOnTime && product.images && product.active && !product.is_bookmarked && !product.isSpecialProduct && !product.onlyAvailableViaURL
                    }
                })
                let sortedProducts = [...filteredProducts].sort((a, b) => {
                    return a.sort_time * 100 - b.sort_time * 100;
                });


                if (sortedProducts.length > Number(maxProductsToShow) && filteredUserCreator[0].productsInfo.intervalTimer) {
                    let newerProduct = sortedProducts[Number(maxProductsToShow)]

                    let dateCreated = new Date()
                    let splitted = filteredUserCreator[0].productsInfo.intervalTimer.split(" ")

                    if (splitted[1].includes("second")) {
                        dateCreated.setSeconds(dateCreated.getSeconds() + Number(splitted[0]))
                    } else if (splitted[1].includes("minute")) {
                        dateCreated.setMinutes(dateCreated.getMinutes() + Number(splitted[0]))
                    } else {
                        dateCreated.setHours(dateCreated.getHours() + Number(splitted[0]))
                    }

                    firebase.database().ref(`bhodi_products/${newerProduct.pushKey}/buttonTimingToShow`).set(dateCreated.toISOString())
                }

                return sortedProducts[sortedProducts.length - 1].sort_time + 8000
            }
        }
    }

    addToCart(e, selectedSize) {
        // if (this.state.productsToRender) {
        //     let filtered = this.state.productsToRender.filter((t) => {
        //         return t.pushKey === e.pushKey
        //     })
        //     if (filtered.length === 0) {
        //         swal({
        //             title: "Sorry!",
        //             text: "Het product is helaas niet meer beschikbaar,",
        //             icon: "warning",
        //         })
        //         return false;
        //     }
        // }
        if (this.state.guestUserCart && this.state.guestUserCart.length > 0) {
            let allCart = [...this.state.guestUserCart].slice(0, this.state.guestUserCart.length)
            let filtered = allCart.filter((t) => {
                return e.created_by === t.created_by
            })
            if (filtered.length === 0) {
                swal({
                    title: "Sorry",
                    text: "Please complete your other company order first",
                    icon: "warning"
                })
                return false;
            }
        }

        if (e.sizesActive) {
            if (!selectedSize) {
                this.setState({ sizeMustSelectPopup: true })
                setTimeout(() => {
                    this.setState({ sizeMustSelectPopup: false })
                }, 2000);
                return false;
            }
        }

        this.setState({ sizeMustSelectPopup: false })

        let arrCart = [...this.state.guestUserCart];

        let f = e;

        let imgtodrag = document.getElementsByClassName("image-front")[0];

        // let arrCart = [...this.state.guestUserCart];
        let objForCart = e
        if (selectedSize) {
            objForCart.selectedSize = selectedSize;
        }
        let cookieItem = localStorage.getItem("cookie_item");

        var minutesToAddDB = Number(process.env.REACT_APP_CART_TIME);
        var currentDateDB = new Date();
        var futureDateDB = new Date(
            currentDateDB.getTime() + minutesToAddDB * 60000
        );

        let futureTimeStampDB = Math.floor(futureDateDB.getTime() / 1000);

        let objToMake = {
            cookieItem,
            itemPush: e.pushKey,
            timestamp: futureTimeStampDB,
            item_type: "bhodi_product",
        };

        if (selectedSize) {
            objToMake.selectedSize = selectedSize;
        }
        var minutesToAdd = Number(process.env.REACT_APP_CART_TIME);
        var currentDate = new Date();
        var futureDate = new Date(currentDate.getTime() + minutesToAdd * 60000);

        let futureTimeStamp = Math.floor(futureDate.getTime() / 1000);


        let currentObj = Object.assign({}, e)
        currentObj.item_type = "bhodi_product"
        if (selectedSize) {
            currentObj.selectedSizeOfOrder = selectedSize;
        }
        arrCart.push(currentObj);

        this.props.updateGuestUserCart(arrCart);
        this.props.updateGuestUserCartTime(futureTimeStamp);
        this.props.updateGuestUserCartExtendOpportunity(true);

        let userid = localStorage.getItem("userid");


        // Logic to take this product to end if it's the last in stock

        if (!e.isSpecialProduct && !e.availableForCompanyGuest) {
            let amountToMinus = 1
            if (selectedSize) {
                let stocksAvailableSizes = []
                currentObj.variations_data.map((e) => {
                    if (Number(e.qty) === 0) {
                        return false;
                    }
                    let filteredStockLength = []
                    let filteredStockLengthVerzilvered = []
                    if (currentObj.inCartOf) {
                        filteredStockLength = Object.entries(currentObj.inCartOf).filter((t) => {
                            return t[1].selectedSize === e.size
                        })
                    }
                    if (currentObj.verzilvered_by) {
                        filteredStockLengthVerzilvered = Object.entries(currentObj.verzilvered_by).filter((t) => {
                            return t[1].selectedSize === e.size
                        })
                    }
                    let amountToMinus = 0

                    if (e.size === this.state.selectedSize) {
                        amountToMinus = 1
                    }
                    console.log(amountToMinus, '/see here')
                    if ((filteredStockLength.length + filteredStockLengthVerzilvered.length) < (Number(e.qty) - amountToMinus)) {
                        stocksAvailableSizes.push(e)
                    }
                })
                if (stocksAvailableSizes.length === 0) {
                    let sortTimeToSet = this.checkForNextProduct(currentObj)
                    firebase
                        .database()
                        .ref("bhodi_products/" + currentObj.pushKey + "/sort_time")
                        .set(sortTimeToSet);
                    console.log("sort timestamp now")
                }
            } else {
                let filteredStockLength = []
                let filteredStockLengthVerzilvered = []
                if (currentObj.inCartOf) {
                    filteredStockLength = Object.entries(currentObj.inCartOf).filter((t) => {
                        return !t[1].selectedSize
                    })
                }
                if (currentObj.verzilvered_by) {
                    filteredStockLengthVerzilvered = Object.entries(currentObj.verzilvered_by).filter((t) => {
                        return !t[1].selectedSize
                    })
                }
                if ((Number(currentObj.qty) - amountToMinus) - (filteredStockLength.length + filteredStockLengthVerzilvered.length) <= 0) {
                    let sortTimeToSet = this.checkForNextProduct(currentObj)
                    firebase
                        .database()
                        .ref("bhodi_products/" + currentObj.pushKey + "/sort_time")
                        .set(sortTimeToSet);
                    console.log("sort timestamp now")
                }
            }
        }
        // Logic to take this product to end if it's the last in stock

        if (userid) {
            objToMake.cookieItem = userid;
            let pushKey = firebase
                .database()
                .ref("guest_carts/" + userid)
                .push().key;
            objToMake.pushKey = pushKey;
            let ownPushKey = firebase
                .database()
                .ref("bhodi_products/" + f.pushKey + "/inCartOf")
                .push().key;

            objToMake.pushKeyInCartOf = ownPushKey;
            objToMake.creatorCompanyId = f.created_by;

            firebase
                .database()
                .ref("guest_carts/" + userid + "/" + pushKey)
                .set(objToMake);

            let objToOwnPush = {
                cookie: objToMake.cookieItem,
                pushKey: ownPushKey,
                cartPushKey: pushKey,
            };
            if (selectedSize) {
                objToOwnPush.selectedSize = selectedSize;
            }
            firebase
                .database()
                .ref(
                    "bhodi_products/" +
                    f.pushKey +
                    "/inCartOf/" +
                    ownPushKey
                )
                .set(objToOwnPush);
        } else {
            if (cookieItem) {
                let pushKey = firebase
                    .database()
                    .ref("guest_carts/" + cookieItem)
                    .push().key;
                objToMake.pushKey = pushKey;

                let ownPushKey = firebase
                    .database()
                    .ref(
                        "bhodi_products/" + f.pushKey + "/inCartOf"
                    )
                    .push().key;

                objToMake.pushKeyInCartOf = ownPushKey;
                objToMake.creatorCompanyId = f.created_by;

                firebase
                    .database()
                    .ref("guest_carts/" + cookieItem + "/" + pushKey)
                    .set(objToMake);

                let objToOwnPush = {
                    cookie: objToMake.cookieItem,
                    pushKey: ownPushKey,
                    cartPushKey: pushKey,
                };
                if (selectedSize) {
                    objToOwnPush.selectedSize = selectedSize;
                }
                firebase
                    .database()
                    .ref(
                        "bhodi_products/" +
                        f.pushKey +
                        "/inCartOf/" +
                        ownPushKey
                    )
                    .set(objToOwnPush);
            }
        }

        if (userid) {
            this.props.updateGuestUserCartTime(futureTimeStamp)
            this.props.updateGuestUserCart(arrCart)
            this.props.updateGuestUserCartExtendOpportunity(true)
        } else {
            if (cookieItem) {
                this.props.updateGuestUserCart(arrCart)
                this.props.updateGuestUserCartTime(futureTimeStamp)
                this.props.updateGuestUserCartExtendOpportunity(true)
            }
        }

        let disLeft = imgtodrag.getBoundingClientRect().left;
        let disTop = imgtodrag.getBoundingClientRect().top;
        let viewcart = document.getElementsByClassName("cart-icon")[0];
        let image = imgtodrag.cloneNode(true);

        let cartleft = viewcart.getBoundingClientRect().left;
        let carttop = viewcart.getBoundingClientRect().top;

        image.style =
            "z-index: 99999999999; width: 30px;opacity:0.8; position:fixed; top:" +
            disTop +
            "px;left:" +
            disLeft +
            "px;transition: left 2s, top 2s, width 2s, opacity 2s cubic-bezier(1, 1, 1, 1)";

        var rechange = document.body.appendChild(image);
        setTimeout(function () {
            image.style.left = cartleft + "px";
            image.style.top = carttop + "px";
            image.style.width = "30px";
            image.style.opacity = "0";
        }, 200);
        setTimeout(function () {
            rechange.parentNode.removeChild(rechange);
        }, 2000);

        let notificationToShow = document.getElementById("mustShowOnAddBasket");
        if (notificationToShow) {
            notificationToShow.style.opacity = 1;
            notificationToShow.style.pointerEvents = "all";
        }

        setTimeout(() => {
            if (notificationToShow) {
                notificationToShow.style.opacity = 0;
                notificationToShow.style.pointerEvents = "none";
            }
        }, 4000);
    }

    checkForProductWinkelmand() {
        if (!this.state.currentUserCharityMember && !this.state.currentProduct.availableForGuests) {
            return <button onClick={() => {
                if (this.state.currentUserHere.type === "company") {
                    window.location.href = `/company/${this.state.currentUserHere.user_name_id}/charity`
                } else {
                    window.location.href = `/member/${this.state.currentUserHere.user_name_id}/charity`
                }
                // this.setState({ showCharityPlans: true })
            }}>Register</button>
        }

        let e = this.state.currentProduct

        let noStock = false
        let alreadyInCartOfCurrentUser = false
        let allMaxReached = false


        let cookieItem = localStorage.getItem("cookie_item");
        let userid = localStorage.getItem("userid");

        let idToCheck = cookieItem

        if (userid) {
            idToCheck = userid
        }

        let productNotRendering = false;
        if (this.state.productsToRender) {
            let filtered = this.state.productsToRender.filter((t) => {
                return t.pushKey === e.pushKey
            })
            if (filtered.length === 0) {
                productNotRendering = true
            }
        }
        let maxItemsPerMonthCart = ""

        if (this.props.user && this.props.bhodi_products) {
            let currentCreator = this.props.user.filter((g) => {
                return g.user_id === this.state.currentProduct.created_by
            })
            if (currentCreator.length > 0) {
                if (currentCreator[0].itemsMaxProductCart && currentCreator[0].itemsMaxProductCart !== "unlimited") {
                    let allProducts = this.props.bhodi_products.filter((g) => {
                        return g.created_by === this.state.currentProduct.created_by
                    })
                    if (allProducts.length > 0) {
                        let filtered = allProducts.filter((g) => {
                            return g.inCartOf || g.verzilvered_by
                        })
                        let allToCheck = []
                        filtered.map((t) => {
                            if (t.inCartOf) {
                                let objected = Object.entries(t.inCartOf)
                                objected.map((p) => {
                                    if (p[1].cookie === userid) {
                                        allToCheck.push(p)
                                    }
                                })
                            }
                            if (t.verzilvered_by) {
                                let objected = Object.entries(t.verzilvered_by)
                                objected.map((p) => {
                                    if (p[1].user_id === userid) {
                                        allToCheck.push(p)
                                    }
                                })
                            }
                        })
                        if (filtered.length > 0) {
                            if (allToCheck.length >= Number(currentCreator[0].itemsMaxProductCart)) {
                                maxItemsPerMonthCart = currentCreator[0].itemsMaxProductCart
                                allMaxReached = true
                            }
                        }
                    }
                }
            }
        }

        if (e.sizesActive) {
            if (e.inCartOf || e.verzilvered_by) {
                let objectedCart = []
                let objectedVerzilvered = []
                if (e.inCartOf) {
                    objectedCart = Object.entries(e.inCartOf)
                    let alreadyInCartCurrent = objectedCart.filter((t) => {
                        return t[1].cookie === idToCheck && t[1].selectedSize === this.state.selectedSize
                    })
                    if (alreadyInCartCurrent.length > 0) {
                        alreadyInCartOfCurrentUser = true
                    }
                }

                if (e.verzilvered_by) {
                    objectedVerzilvered = Object.entries(e.verzilvered_by)
                }

                let checkForSizeAvailable = objectedCart.filter((e) => {
                    return e[1].selectedSize === this.state.selectedSize
                })
                let checkForSizeAvailableVerzilvered = objectedVerzilvered.filter((e) => {
                    return e[1].selectedSize === this.state.selectedSize
                })
                let filtered = this.state.currentProduct.variations_data.filter((e) => {
                    return e.size === this.state.selectedSize
                })
                if (filtered.length > 0) {
                    if ((checkForSizeAvailable.length + checkForSizeAvailableVerzilvered.length) >= Number(filtered[0].qty)) {
                        noStock = true
                    }
                }
            }
            if (e.variations_data && e.variations_data.length > 0) {
                let filtered = e.variations_data.filter((t) => {
                    return Number(t.qty) > 0
                })
                if (filtered.length === 0) {
                    noStock = true
                }
            }
        } else {
            if (e.inCartOf || e.verzilvered_by) {
                let objectedCart = []
                let objectedVerzilvered = []

                if (e.inCartOf) {
                    objectedCart = Object.entries(e.inCartOf)
                    let alreadyInCartCurrent = objectedCart.filter((t) => {
                        return t[1].cookie === idToCheck
                    })
                    if (alreadyInCartCurrent.length > 0) {
                        alreadyInCartOfCurrentUser = true
                    }
                }

                if (e.verzilvered_by) {
                    objectedVerzilvered = Object.entries(e.verzilvered_by)
                }

                if ((objectedCart.length + objectedVerzilvered.length) >= Number(e.qty)) {
                    noStock = true
                }
            }
            if (Number(e.qty) === 0) {
                noStock = true
            }
        }

        if (!this.state.currentUserId && this.state.guestUserCart.length > 0) {
            let filtered = this.state.guestUserCart.filter((t) => {
                return t.item_type === "bhodi_product"
            })
            if (filtered.length > 0) {
                if (filtered[0].pushKey !== e.pushKey) {
                    allMaxReached = true
                }
            }
        }

        return this.showButtonForWinkelmand(alreadyInCartOfCurrentUser, noStock, allMaxReached, productNotRendering, maxItemsPerMonthCart)
    }

    showButtonForWinkelmand(cartAdded, noStock, allMaxReached, productNotRendering, maxItemsPerMonthCart) {
        return <div style={{ display: "flex" }} className='buttonstohideontimer'>
            {this.state.currentProduct.variations_data && this.state.currentProduct.variations_data.length > 0 && (<button className={styles.sizeButton} style={{ padding: window.innerWidth > 1008 && 0 }}>
                <div className={`${styles.sizeMainInner} sizemainbtninner`} onClick={() => {
                    this.setState({ dropdownOpen: !this.state.dropdownOpen })
                }}>
                    <label>{this.state.selectedSize ? this.state.selectedSize : `Maat`}</label>
                    <RiArrowDownSLine />
                </div>
                <div className={styles.sizeSelectMustPopup} style={{ opacity: this.state.sizeMustSelectPopup ? 1 : 0, pointerEvents: this.state.sizeMustSelectPopup ? "all" : "none" }}>
                    <p>Selecteer maat</p>
                </div>
                <div className={styles.dropdownContainer} style={{ opacity: this.state.dropdownOpen ? 1 : 0, pointerEvents: this.state.dropdownOpen ? "all" : "none" }}>
                    <ul>
                        {this.state.currentProduct.variations_data.map((e) => {
                            let isUitverkocht = false
                            if (this.state.currentProduct.inCartOf || this.state.currentProduct.verzilvered_by) {
                                let objectedCart = []
                                let objectedVerzilvered = []
                                if (this.state.currentProduct.inCartOf) {
                                    objectedCart = Object.entries(this.state.currentProduct.inCartOf)
                                }

                                if (this.state.currentProduct.verzilvered_by) {
                                    objectedVerzilvered = Object.entries(this.state.currentProduct.verzilvered_by)
                                }

                                let checkForSizeAvailable = objectedCart.filter((t) => {
                                    return t[1].selectedSize === e.size
                                })
                                let checkForSizeAvailableVerzilvered = objectedVerzilvered.filter((t) => {
                                    return t[1].selectedSize === e.size
                                })
                                let filtered = this.state.currentProduct.variations_data.filter((t) => {
                                    return t.size === e.size
                                })
                                if (filtered.length > 0) {
                                    if ((checkForSizeAvailable.length + checkForSizeAvailableVerzilvered.length) >= Number(filtered[0].qty)) {
                                        isUitverkocht = true
                                    }
                                }
                            } else {
                                if (Number(e.qty) === 0) {
                                    isUitverkocht = true
                                }
                            }

                            return (<li onClick={() => {
                                if (!isUitverkocht) {
                                    if (e.price) {
                                        this.setState({ additionalPrice: e.price })
                                    } else {
                                        this.setState({ additionalPrice: "" })
                                    }
                                    this.setState({ selectedSize: e.size, dropdownOpen: false, selectedSkuOfSize: e.sku })
                                }
                            }}>{e.size} {isUitverkocht ? `${this.state.currentProduct.inCartOf ? ("Gereserveerd") : ("Uitverkocht")}` : ""}</li>)
                        })}
                    </ul>
                </div>
            </button>)}
            <div className='button-main-addtocart'>
                <div className="popup-gri-thifc" style={{ opacity: this.state.limitReachedPopup ? 1 : 0, pointerEvents: this.state.limitReachedPopup ? "all" : "none" }}>
                    <CloseIcon onClick={() => this.setState({ limitReachedPopup: false })} />
                    {!this.state.currentUserId ? (<label style={{ fontSize: 15 }}>
                        Je kunt maximaal 1 product<br />
                        in je winkelmand plaatsen.
                    </label>) : (<label style={{ fontSize: 15 }}>
                        Je kunt maximaal {maxItemsPerMonthCart || 1} gratis product
                        per maand krijgen bij deze aanbieder.
                    </label>)}
                </div>
                {cartAdded ? <button>
                    Toegevoegd
                </button> :
                    // productNotRendering ? <button onClick={this.addToCart.bind(this, this.state.currentProduct, this.state.selectedSize)}>
                    //     Niet beschikbaar
                    // </button> :
                    allMaxReached ? <button onClick={() => {
                        this.setState({ limitReachedPopup: true })
                    }}>Limiet bereikt</button> : noStock ? <button>
                        {this.state.currentProduct.inCartOf ? "Gereserveerd" : "Uitverkocht"}
                    </button> : (<button onClick={this.addToCart.bind(this, this.state.currentProduct, this.state.selectedSize)}>
                        <div
                            className="image-front addIconCart"
                            style={{ pointerEvents: "none" }}
                        >
                            <AddIcon />
                        </div>
                        Winkelmand
                    </button>)}
            </div>
        </div>
    }

    getTransformedGenderText(e) {
        if (e === "dames") {
            return `Dames`
        } else if (e === "heren") {
            return `Heren`
        } else if (e === "kinderen") {
            return `Kinderen`
        } else if (e === "unisex") {
            return `Unisex`
        }
    }

    getCreatorName(user_id, avoidCity) {
        if (user_id) {
            let filtered = this.props.user.filter((g) => {
                return g.user_id === user_id
            })
            if (filtered.length > 0) {
                if (window.innerWidth > 1008) {
                    if (filtered[0].type === "user") {
                        return filtered[0].first_name.charAt(0).toUpperCase() + filtered[0].first_name.slice(1)
                    } else {
                        return filtered[0].company_name.charAt(0).toUpperCase() + filtered[0].company_name.slice(1)
                    }
                } else {
                    if (filtered[0].type === "user") {
                        if (filtered[0].city && !avoidCity) {
                            return `${filtered[0].first_name.charAt(0).toUpperCase() + filtered[0].first_name.slice(1)} | ${filtered[0].city}`
                        }
                        return filtered[0].first_name.charAt(0).toUpperCase() + filtered[0].first_name.slice(1)
                    } else {
                        if (filtered[0].city && !avoidCity) {
                            return `${filtered[0].company_name.charAt(0).toUpperCase() + filtered[0].company_name.slice(1)} | ${filtered[0].city}`
                        }
                        return filtered[0].company_name.charAt(0).toUpperCase() + filtered[0].company_name.slice(1)
                    }
                }
            }
        }
    }



    createMarkup(e) {
        if (this.state.currentUserCreator.company_about) {
            return { __html: this.state.currentUserCreator.company_about };
        }
    }

    render() {
        return (
            this.state.dontRenderProduct ? <></> : this.state.showCharityPlans ? <Charity onProductDetailed={true} closePopup={() => {
                this.setState({ showCharityPlans: false })
            }} /> : <div className='product-detailed-css'>
                {this.state.currentProduct && window.innerWidth < 1008 && (<div className={styles.productDetailed}>
                    <div className='new-detailed-page'>
                        {window.innerWidth < 1008 && (<div className='detailed-bottom-mob'>
                            <p className='bottom-right-indp'>{(this.state.freeItemText && <font style={{ cursor: "pointer" }}>{this.state.freeItemText} | </font>)}{this.state.currentProduct.created_by !== process.env.REACT_APP_ADMIN_ID && (<font onClick={() => this.setState({ companyInfoDetailed: true })}>{this.getCreatorName(this.state.currentProduct.created_by, true)}</font>)} {this.state.currentProduct.created_by !== process.env.REACT_APP_ADMIN_ID && `|`} {(this.state.deliveryNote && <font style={{ cursor: "pointer" }} onClick={() => this.setState({ deliveryNotePopup: true })}>BEZORGING | </font>)}<font style={{ cursor: "pointer" }} onClick={() => {
                                document.getElementById("root").style.position = "fixed"
                                this.setState({ attributesRightMenu: true })
                            }}>SPECIFICATIES</font></p>
                        </div>)}
                        <div className='content-newdetpage'>
                            <div className='inner-content-newdetpage'>
                                {this.state.allProductImages.length > 0 && (<div className='content-images-ndp'>
                                    {this.state.allProductImages.map((g, i) => {
                                        return (<div className='inner-cindp'>
                                            <img src={g} onClick={() => {
                                                this.setState({ changingImage: true })
                                                setTimeout(() => {
                                                    this.setState({ currentImageIndex: i })
                                                }, 200);
                                                setTimeout(() => {
                                                    this.setState({ changingImage: false })
                                                }, 700);
                                            }} />
                                        </div>)
                                    })}
                                </div>)}
                                <div className='left-tsmhnc'>
                                    <div className='container-ltsmhnc'>
                                        <p>Free Item</p>
                                        <p>{this.state.currentProduct.title}</p>
                                        <p onClick={() => {
                                            this.setState({ showDescriptionPopup: true })
                                        }}>{this.state.currentProduct.desc ? `${this.state.currentProduct.desc.substr(0, this.state.maxTextToShow)} ${this.state.currentProduct.desc.length > 220 ? " ..." : ""}` : this.state.globalDesc && `${this.state.globalDesc.substr(0, this.state.maxTextToShow)} ${this.state.globalDesc.length > 220 ? " ..." : ""}`}</p>
                                        <p><s style={{ color: "#8B8B8B" }}>€{this.state.additionalPrice ? this.state.additionalPrice : this.state.currentProduct.price}</s> Free</p>
                                    </div>
                                </div>
                                {/* <h2>{this.state.currentProduct.title}</h2>
                                <p onClick={() => {
                                    this.setState({ showDescriptionPopup: true })
                                }}>{this.state.currentProduct.desc ? `${this.state.currentProduct.desc.substr(0, this.state.maxTextToShow)} ${this.state.currentProduct.desc.length > 70 ? " ..." : ""}` : this.state.globalDesc && `${this.state.globalDesc.substr(0, this.state.maxTextToShow)} ${this.state.globalDesc.length > 70 ? " ..." : ""}`}</p>
                                <p><s>€{this.state.additionalPrice ? this.state.additionalPrice : this.state.currentProduct.price}</s> FREE ITEM</p> */}
                                {this.state.currentProduct.timerToStartProduct && <ButtonTimerProductsNew timerToDo={new Date(Date.parse(this.state.currentProduct.timerToStartProduct)).getTime()} />}
                                {this.state.currentProduct.buttonTimingToShow && <ButtonTimerProductsNew timerToDo={new Date(Date.parse(this.state.currentProduct.buttonTimingToShow)).getTime()} closeScreen={() => {
                                    firebase.database().ref(`bhodi_products/${this.state.currentProduct.pushKey}/buttonTimingToShow`).remove()
                                }} />}
                                <div className='buttonswrapperinsideproduct'>
                                    {this.checkForTimerOrWinkelmand()}
                                    {this.checkForProductWinkelmand()}
                                </div>
                            </div>
                            {/* {window.innerWidth < 1008 && (<div className='icons-right-mobile'>
                                {this.getRightIcons()}
                            </div>)} */}
                        </div>
                        <div className='image-newdetpage indp-image-main'>
                            <img src={this.state.allProductImages[this.state.currentImageIndex]} style={{ opacity: !this.state.changingImage ? 1 : 0, pointerEvents: !this.state.changingImage ? "all" : "none" }} className="midimage-indp" onClick={() => {
                                // if (window.innerWidth > 1008) {
                                if (document.getElementById("footermain")) {
                                    document.getElementById("footermain").style.zIndex = 999999
                                }
                                this.setState({ productDetailedOpen: true })
                                // }
                            }} />
                            {/* {window.innerWidth > 1008 && this.getRightIcons()}
                            {window.innerWidth > 1008 && <p className='bottom-right-indp'>{this.state.currentProduct.created_by !== process.env.REACT_APP_ADMIN_ID && <font onClick={() => this.setState({ companyInfoDetailed: true })}>{this.getCreatorName(this.state.currentProduct.created_by)}</font>} | {(this.state.deliveryNote && <font style={{ cursor: "pointer" }} onClick={() => this.setState({ deliveryNotePopup: true })}>BEZORGING | </font>)}<font style={{ cursor: "pointer" }} onClick={() => {
                                document.getElementById("root").style.position = "fixed"
                                this.setState({ attributesRightMenu: true })
                            }}>SPECS</font></p>} */}
                            {/* {window.innerWidth > 1008 && <p className='bottom-right-indp'>FREE ITEM {((this.state.currentProduct.attributesToShow && this.state.currentProduct.attributesToShow.length > 0) || (this.state.currentProduct.gender && this.state.currentProduct.gender.length > 0) || (this.state.globalGender.length > 0)) && <>| <font style={{ cursor: "pointer" }} onClick={() => this.setState({ attributesRightMenu: true })}>SPECS</font></>}</p>} */}
                        </div>
                        {window.innerWidth < 1008 && (
                            // <div className='header-pdm-new'>
                            //     <BsChevronLeft onClick={() => {
                            //         history.goBack()
                            //     }} />
                            //     <CartNonUser notNeedOrders={true} hideFooterOnOpen={true} />
                            // </div>
                            <NavbarWrapperVisitorMobile onProductDetailed={true} />
                        )}
                    </div>
                    {this.state.productDetailedOpen && (<div className='popup-main-detailed' style={{ zIndex: this.state.commentsPopup && 99999999 }}>
                        <div className='inner-popup-main-detailed ipmd-mobile'>
                            <CloseIcon className='close-icon-ipmd' onClick={() => this.setState({ productDetailedOpen: false })} />
                            <div className='image-newdetpage'>
                                <div className='top-newdetpage-popup'>
                                    {this.state.allProductImages.length > 0 && (<div className='content-images-ndp'>
                                        {this.state.allProductImages.map((g, i) => {
                                            return (<div className='inner-cindp'>
                                                <img src={g} onClick={() => {
                                                    this.setState({ changingImage: true })
                                                    setTimeout(() => {
                                                        this.setState({ currentImageIndex: i })
                                                    }, 200);
                                                    setTimeout(() => {
                                                        this.setState({ changingImage: false })
                                                    }, 700);
                                                }} />
                                            </div>)
                                        })}
                                    </div>)}
                                </div>
                                <img src={this.state.allProductImages[this.state.currentImageIndex]} style={{ opacity: !this.state.changingImage ? 1 : 0, pointerEvents: !this.state.changingImage ? "all" : "none" }} className="midimage-indp" />
                                {window.innerWidth > 1009 && (this.getRightIcons())}
                            </div>
                        </div>
                    </div>)}

                    {!this.state.showLikeLists && !this.state.commentsPopup && window.innerWidth > 1008 && !this.props.defaultProduct && (
                        <div className="top-icons-product-main tipm-detailed" style={{ right: window.innerWidth < 1008 ? 20 : 50 }}>
                            <div
                                style={{ position: "relative", display: "inline-block" }}
                                className="righticonopacity righticonsbasketglobal detailedpagedesktopwork"
                            >
                                <a
                                    href="javascript:void(0)"
                                    style={{ position: "relative" }}
                                    className="btn btn-black orderbasketicon orderbasketiconmain"
                                >
                                    {/* <CartNonUser /> */}
                                    {this.state.currentUserId ? (<CartNonUser />) : (<FooterBasket />)}
                                </a>
                            </div>
                        </div>
                    )}
                </div>)}
                {this.state.currentProduct && window.innerWidth > 1008 && (<div className={styles.productDetailed}>
                    {/* <div className='new-detailed-page'>
                        <div className='content-newdetpage'>
                            <div className='inner-content-newdetpage'>
                                {this.state.allProductImages.length > 0 && (<div className='content-images-ndp'>
                                    {this.state.allProductImages.map((g, i) => {
                                        return (<div className='inner-cindp'>
                                            <img src={g} onClick={() => {
                                                this.setState({ changingImage: true })
                                                setTimeout(() => {
                                                    this.setState({ currentImageIndex: i })
                                                }, 200);
                                                setTimeout(() => {
                                                    this.setState({ changingImage: false })
                                                }, 700);
                                            }} />
                                        </div>)
                                    })}
                                </div>)}
                                <h2>{this.state.currentProduct.title}</h2>
                                <p onClick={() => {
                                    this.setState({ showDescriptionPopup: true })
                                }}>{this.state.currentProduct.desc ? `${this.state.currentProduct.desc.substr(0, this.state.maxTextToShow)} ${this.state.currentProduct.desc.length > 70 ? " ..." : ""}` : this.state.globalDesc && `${this.state.globalDesc.substr(0, this.state.maxTextToShow)} ${this.state.globalDesc.length > 70 ? " ..." : ""}`}</p>
                                <p><s>€{this.state.additionalPrice ? this.state.additionalPrice : this.state.currentProduct.price}</s> FREE ITEM</p>
                                {this.checkForTimerOrWinkelmand()}
                                {this.checkForProductWinkelmand()}
                            </div>
                        </div>
                        <div className='image-newdetpage indp-image-main'>
                            <img src={this.state.allProductImages[this.state.currentImageIndex]} style={{ opacity: !this.state.changingImage ? 1 : 0, pointerEvents: !this.state.changingImage ? "all" : "none" }} className="midimage-indp" onClick={() => {
                                // if (window.innerWidth > 1008) {
                                if (document.getElementById("footermain")) {
                                    document.getElementById("footermain").style.zIndex = 999999
                                }
                                this.setState({ productDetailedOpen: true })
                                // }
                            }} />
                            {window.innerWidth > 1008 && this.getRightIcons()}
                            {window.innerWidth > 1008 && <p className='bottom-right-indp'><font onClick={() => this.setState({ companyInfoDetailed: true })}>{this.getCreatorName(this.state.currentProduct.created_by)}</font> | {(this.state.deliveryNote && <font style={{ cursor: "pointer" }} onClick={() => this.setState({ deliveryNotePopup: true })}>BEZORGING | </font>)}<font style={{ cursor: "pointer" }} onClick={() => {
                                document.getElementById("root").style.position = "fixed"
                                this.setState({ attributesRightMenu: true })
                            }}>SPECS</font></p>}
                        </div>
                    </div> */}
                    {this.state.allProductImages.length > 1 && (<div className="absolute-bg-left-pdd">
                        <div className='right-ablpd' onClick={() => {
                            if (this.state.currentImageIndex > 0) {
                                this.setState({ changingImage: true })
                                setTimeout(() => {
                                    this.setState({ currentImageIndex: this.state.currentImageIndex - 1 })
                                }, 200);
                                setTimeout(() => {
                                    this.setState({ changingImage: false })
                                }, 700);
                                // this.setState({ currentImageIndex: this.state.currentImageIndex - 1 })
                            }
                        }}>
                            <AiFillCaretLeft />
                        </div>
                        <div className='right-ablpd' onClick={() => {
                            if (this.state.currentImageIndex < this.state.allProductImages.length - 1) {
                                this.setState({ changingImage: true })
                                setTimeout(() => {
                                    this.setState({ currentImageIndex: this.state.currentImageIndex + 1 })
                                }, 200);
                                setTimeout(() => {
                                    this.setState({ changingImage: false })
                                }, 700);
                                // this.setState({ currentImageIndex: this.state.currentImageIndex + 1 })
                            }
                        }}>
                            <AiFillCaretRight />
                        </div>
                    </div>)}

                    <div className='product-detailed-desktop'>
                        <div className={styles.leftPddMain}>
                            <img src={this.state.allProductImages[this.state.currentImageIndex]} style={{ opacity: !this.state.changingImage ? 1 : 0, pointerEvents: !this.state.changingImage ? "all" : "none" }} className="midimage-indp" />
                        </div>
                        <div className="right-pdd-main">
                            <div className='left-tsmhnc'>
                                <div className='container-ltsmhnc'>
                                    <p>Free Item</p>
                                    <p>{this.state.currentProduct.title}</p>
                                    {(window.innerWidth < 1008 && this.state.currentProduct.desc.length > 80) ? (<p onClick={() => {
                                        this.setState({ showDescriptionPopup: true })
                                    }}>{this.state.currentProduct.desc.substr(0, 80)}...</p>) : (<p>{this.state.currentProduct.desc}</p>)}
                                    <p><s>€{this.state.additionalPrice ? this.state.additionalPrice : this.state.currentProduct.price}</s> Free</p>
                                    {this.state.currentProduct.timerToStartProduct && <ButtonTimerProductsNew timerToDo={new Date(Date.parse(this.state.currentProduct.timerToStartProduct)).getTime()} />}
                                    {this.state.currentProduct.buttonTimingToShow && <ButtonTimerProductsNew timerToDo={new Date(Date.parse(this.state.currentProduct.buttonTimingToShow)).getTime()} closeScreen={() => {
                                        firebase.database().ref(`bhodi_products/${this.state.currentProduct.pushKey}/buttonTimingToShow`).remove()
                                    }} />}
                                    {this.state.currentProduct.active && <div className='buttonswrapperinsideproduct' style={{ position: "relative" }}>
                                        {this.checkForTimerOrWinkelmand()}
                                        {this.checkForProductWinkelmand()}
                                    </div>}
                                    <div className='labels-cltsmhnc'>
                                        {(this.state.freeItemText && <label style={{ cursor: "pointer" }}>{this.state.freeItemText} | </label>)}
                                        {(this.state.deliveryNote && <label style={{ cursor: "pointer" }} onClick={() => this.setState({ deliveryNotePopup: true })}>BEZORGING | </label>)}
                                        <label style={{ cursor: "pointer" }} onClick={() => {
                                            document.getElementById("root").style.position = "fixed"
                                            this.setState({ attributesRightMenu: true })
                                        }}>SPECIFICATIES</label>
                                    </div>
                                    {this.state.allProductImages.length > 0 && (<div className='content-images-ndp'>
                                        {this.state.allProductImages.map((g, i) => {
                                            return (<div className='inner-cindp' style={{ backgroundImage: `url("${g}")` }} onClick={() => {
                                                this.setState({ changingImage: true })
                                                setTimeout(() => {
                                                    this.setState({ currentImageIndex: i })
                                                }, 200);
                                                setTimeout(() => {
                                                    this.setState({ changingImage: false })
                                                }, 700);
                                            }} >
                                                {/* <img src={g} onClick={() => {
                                                    this.setState({ changingImage: true })
                                                    setTimeout(() => {
                                                        this.setState({ currentImageIndex: i })
                                                    }, 200);
                                                    setTimeout(() => {
                                                        this.setState({ changingImage: false })
                                                    }, 700);
                                                }} /> */}
                                            </div>)
                                        })}
                                    </div>)}
                                    {/* <div className='labels-cltsmhnc'>
                                        <label>INFORMATIE</label>
                                        {this.getUseOfVoucherKortingsVoucher(
                                            this.state.currentProduct
                                        )}
                                        {this.getfilelink(
                                            this.state.currentProduct
                                        )}
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    {this.state.productDetailedOpen && (<div className='popup-main-detailed' style={{ zIndex: this.state.commentsPopup && 99999999 }}>
                        <div className='inner-popup-main-detailed ipmd-mobile'>
                            <CloseIcon className='close-icon-ipmd' onClick={() => this.setState({ productDetailedOpen: false })} />
                            <div className='image-newdetpage'>
                                <div className='top-newdetpage-popup'>
                                    {this.state.allProductImages.length > 0 && (<div className='content-images-ndp'>
                                        {this.state.allProductImages.map((g, i) => {
                                            return (<div className='inner-cindp'>
                                                <img src={g} onClick={() => {
                                                    this.setState({ changingImage: true })
                                                    setTimeout(() => {
                                                        this.setState({ currentImageIndex: i })
                                                    }, 200);
                                                    setTimeout(() => {
                                                        this.setState({ changingImage: false })
                                                    }, 700);
                                                }} />
                                            </div>)
                                        })}
                                    </div>)}
                                </div>
                                <img src={this.state.allProductImages[this.state.currentImageIndex]} style={{ opacity: !this.state.changingImage ? 1 : 0, pointerEvents: !this.state.changingImage ? "all" : "none" }} className="midimage-indp" />
                                {window.innerWidth > 1009 && (this.getRightIcons())}
                            </div>
                        </div>
                    </div>)}

                    {!this.props.defaultProduct && (
                        <div className="top-icons-product-main tipm-detailed" style={{ right: 86 }}>
                            {this.state.currentProduct.created_by !== process.env.REACT_APP_ADMIN_ID && (<div className='tipm-label'>
                                <label>Company name | Borne</label>
                            </div>)}
                            <div
                                style={{ position: "relative", display: "inline-block" }}
                                className="righticonopacity righticonsbasketglobal detailedpagedesktopwork"
                            >
                                <a
                                    href="javascript:void(0)"
                                    style={{ position: "relative" }}
                                    className="btn btn-black orderbasketicon orderbasketiconmain"
                                >
                                    {/* <CartNonUser /> */}
                                    {this.state.currentUserId ? (<CartNonUser />) : (<FooterBasket />)}
                                </a>
                            </div>
                        </div>
                    )}
                    {!this.props.defaultProduct && (
                        <div className="top-icons-product-main tipm-detailed" style={{ left: 50, right: "auto" }}>
                            <button className='back-btn-tipm' onClick={() => {
                                history.goBack()
                            }}>
                                <ChevronLeft />
                            </button>
                        </div>
                    )}
                </div>)}
                {this.state.showDescriptionPopup && window.innerWidth > 1009 && <div className='overlay-description-popup-asp'></div>}
                <div
                    className="add-stuff-popup addstuffpopupdesktop"
                    // className="add-stuff-popup description-popup-main"
                    style={{
                        height: this.state.showDescriptionPopup ? 350 : 0,
                        pointerEvents: this.state.showDescriptionPopup ? "all" : "none",
                        overflow: !this.state.showDescriptionPopup && "hidden",
                    }}
                >
                    <CloseIcon
                        onClick={() => {
                            this.setState({ showDescriptionPopup: false });
                        }}
                    />
                    <p
                        className="desc-heading-asp"
                        style={{ fontSize: 11, textTransform: "none" }}
                    >
                        Omschrijving
                    </p>
                    <p className="desc-content-asp">
                        {this.state.currentProduct.desc}
                    </p>
                </div>
                {window.innerWidth > 1008 && !this.props.defaultProduct && (<Footer notOnMainPage={true} />)}
                {window.innerWidth < 1008 && !this.props.defaultProduct && (<FooterGreen notOnMainPage={true} />)}
                {window.innerWidth < 1008 && this.state.showPopupFriendsFollow && <div className="add-friend-remove-popup">
                    <FriendFollowBottom member_id={this.state.currentProduct.created_by} mustShowVisitLink={true} closePopup={() => {
                        this.setState({ showPopupFriendsFollow: false })
                    }} />
                </div>}
                {this.state.attributesRightMenu && (
                    <div
                        className="overlay-div-openadd"
                        onClick={() => {
                            this.setState({
                                attributesRightMenu: false,
                            });
                            document.getElementById("root").style.position = "static"
                        }}
                    ></div>
                )}
                {this.state.attributesRightMenu && (
                    <div className="separate-page-ce new-campaigns-design campaigns-design-final new-campaigns-design-final ncd-on-desktop">
                        <div
                            className="rightmenuaddproduct rightmenucompletewidthaddproduct rightmenugiveawaycampaign"
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <div className="sectiongiveawaycompletepopup" style={{ height: "100%", backgroundColor: "white" }}>
                                <div className="header-spce" style={{ justifyContent: "center" }}>
                                    <p>SPECS</p>
                                    <CloseIcon className="arrow-back-svg"
                                        onClick={() => {
                                            this.setState({ attributesRightMenu: false })
                                            document.getElementById("root").style.position = "static"
                                        }}
                                    />
                                </div>
                                <div
                                    className="section-first-cplp"
                                    key={Math.random()}
                                    style={{ flexDirection: "row" }}
                                >
                                    <div className="content-sfcplp" style={{ justifyContent: "flex-start" }}>
                                        <div
                                            className="savebtnwork"
                                            style={{ display: "flex", alignItems: "center" }}
                                        >
                                            <div
                                                className="form-miur formfullwidthftcic"
                                                style={{ position: "relative" }}
                                            >
                                                <p className='paragraphasfield' style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between", flexWrap: "wrap" }}>
                                                    <font style={{ width: 120 }}>SKU</font>
                                                    <font><b>{this.state.selectedSkuOfSize ? this.state.selectedSkuOfSize : this.state.currentProduct.sku}</b></font>
                                                </p>
                                            </div>
                                            {(this.state.currentProduct.gender && this.state.currentProduct.gender.length > 0) ? this.state.currentProduct.gender.map((e) => {
                                                return (<div
                                                    className="form-miur formfullwidthftcic"
                                                    style={{ position: "relative" }}
                                                >
                                                    <p className='paragraphasfield' style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between", flexWrap: "wrap" }}>
                                                        <font style={{ width: 120 }}>Gender</font>
                                                        <font><b>{this.getTransformedGenderText(e)}</b></font>
                                                    </p>
                                                </div>)
                                            }) : this.state.globalGender.length > 0 && this.state.globalGender.map((e) => {
                                                return (<div
                                                    className="form-miur formfullwidthftcic"
                                                    style={{ position: "relative" }}
                                                >
                                                    <p className='paragraphasfield' style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between", flexWrap: "wrap" }}>
                                                        <font style={{ width: 120 }}>Gender</font>
                                                        <font><b>{this.getTransformedGenderText(e)}</b></font>
                                                    </p>
                                                </div>)
                                            })}
                                            {this.state.currentProduct.attributesToShow && this.state.currentProduct.attributesToShow.length > 0 && this.state.currentProduct.attributesToShow.map((e) => {
                                                return (<div
                                                    className="form-miur formfullwidthftcic"
                                                    style={{ position: "relative" }}
                                                >
                                                    <p className='paragraphasfield' style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between", flexWrap: "wrap" }}>
                                                        <font style={{ width: 120 }}>{e.attribute}</font>
                                                        <font><b>{e.value}</b></font>
                                                    </p>
                                                </div>)
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {this.state.deliveryNotePopup && (
                    <div
                        className="overlay-asp"
                        onClick={() => {
                            this.setState({
                                deliveryNotePopup: false,
                            });
                        }}
                    ></div>
                )}
                {this.state.deliveryNotePopup && (<div
                    className="add-stuff-popup addstuffpopupdesktop"
                    style={{
                        minHeight: this.state.deliveryNotePopup ? 350 : 0,
                        height: this.state.deliveryNotePopup ? "auto" : 0,
                        pointerEvents: this.state.deliveryNotePopup ? "all" : "none",
                        overflow: !this.state.deliveryNotePopup && "hidden",
                        padding: this.state.deliveryNotePopup ? 20 : "0px 20px"
                    }}
                >
                    <CloseIcon
                        onClick={() => {
                            this.setState({
                                deliveryNotePopup: false,
                            });
                        }}
                    />
                    <p
                        className="desc-heading-asp"
                        style={{ fontSize: 11, textTransform: "none" }}
                    >
                        Omschrijving
                    </p>
                    <p className="desc-content-asp">
                        {this.state.deliveryNote}
                    </p>
                </div>)}


                {this.state.companyInfoDetailed && (<div className='popup-company-detailed'>
                    <div
                        className="about-reve about-reve-updated"
                        style={{ width: "100%", maxWidth: 400 }}
                    // data-aos="zoom-in"
                    >
                        <div style={{ width: "100%", height: "100%", position: "relative" }}>
                            <CloseIcon style={{ position: "absolute", top: -10, right: -10, color: "black", zIndex: 99 }} onClick={() => {
                                this.setState({ companyInfoDetailed: false })
                            }} />
                            <div
                                className="bottom-areve"
                                style={{ margin: "0px auto" }}
                            >
                                <div className="container-bareve">
                                    <p
                                        className="charitysection-heading"
                                        style={{
                                            fontWeight: 600,
                                            color: "black",
                                        }}
                                    >
                                        OVER ONS
                                    </p>
                                    <p
                                        className="charitysection-heading-inner"
                                        style={{
                                            fontWeight: 600,
                                            color: "black",
                                        }}
                                    >
                                        {this.state.currentUserCreator.company_name}
                                    </p>
                                    <div className="text-container-charity">
                                        {/* <MdHouseboat /> */}
                                        <p>
                                            <div dangerouslySetInnerHTML={this.createMarkup()}>

                                            </div>
                                        </p>
                                    </div>
                                    <div className="text-container-charity">
                                        <IoLocationSharp />
                                        <p>
                                            {this.state.currentUserCreator.street && this.state.currentUserCreator.street} {this.state.currentUserCreator.houseNumber && this.state.currentUserCreator.houseNumber}
                                            <br />
                                            {this.state.currentUserCreator.zipCode && this.state.currentUserCreator.zipCode} {this.state.currentUserCreator.city && this.state.currentUserCreator.city}
                                        </p>
                                    </div>
                                    <div className="text-container-charity">
                                        <HiMail />
                                        <p>
                                            {this.state.currentUserCreator.phoneNumber && <a style={{ color: "black", textDecoration: "none" }}
                                                href={`tel:${this.state.currentUserCreator.phoneNumber}`}
                                            >{this.state.currentUserCreator.phoneNumber}</a>}
                                            <br />
                                            {this.state.currentUserCreator.email && <a style={{ color: "black", textDecoration: "none" }}
                                                href={`mailto:${this.state.currentUserCreator.email}`}
                                            >{this.state.currentUserCreator.email}</a>}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>)}
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.authReducers.user,
        album: state.postsReducers.album,
        global_campaigns: state.campaignsReducer.global_campaigns,
        images: state.postsReducers.images,
        global_values: state.globalReducers.global_values,
        guest_user_cart: state.cartReducer.guest_user_cart,
        subscription_plans: state.globalReducers.subscription_plans,
        globalsectors: state.categoriesReducer.globalsectors,
        products_new: state.campaignsReducer.products_new,
        admin_boolean: state.globalReducers.admin_boolean,
        stock_images: state.globalReducers.stock_images,
        bhodi_products: state.productsReducers.bhodi_products,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateGuestUserCart: (guest_user_cart) =>
            dispatch(updateGuestUserCart(guest_user_cart)),
        updateGuestUserCartTime: (guest_user_cart_time) =>
            dispatch(updateGuestUserCartTime(guest_user_cart_time)),
        updateGuestUserCartExtendOpportunity: (
            guest_user_cart_extend_opportunity
        ) =>
            dispatch(
                updateGuestUserCartExtendOpportunity(guest_user_cart_extend_opportunity)
            ),
        updateBhodiProducts: (bhodi_products) => dispatch(updateBhodiProducts(bhodi_products)),
    };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProductDetailed);