import React, { Component } from "react";
import { connect } from "react-redux";
import firebase from "../Config/Firebase";
import AdminNavbar from "./AdminNavbar";

const axios = require("axios");

export class InvoiceGenerator extends Component {
    constructor(props) {
        super(props);

        this.state = {
            treeData: [],
            sectorModal: false,
            sectorModalCategory: false,
            sectorName: "",
            all_categories: [],
            sortingTab: true,
            adjustingTab: false,
            completeMainTitles: [],
            arrOfInputs: [],
            allListings: [],
            allListingsDuplicated: [],
            currentUserSpecific: "",
            all_categories: [],
            selectedSector: "",
        };
    }

    componentDidMount() {
        const loaderId = document.getElementById("main-page-loader-id");
        loaderId.style.display = "none";

        firebase.database().ref("payouts_info").on("value", (data) => {
            let a = data.val()
            if (a) {
                let allAdministrationData = []

                let objectedMain = Object.entries(a)
                objectedMain.map((f) => {
                    let objected = Object.entries(f[1])
                    objected.map((t) => {
                        let objectedInner = Object.entries(t[1])
                        objectedInner.map((g) => {
                            let newObj = Object.assign(g[1], {})
                            if (newObj.payment_status === "paid") {

                                newObj.amount = newObj.amount_charged
                                newObj.added_at = new Date(Date.parse(newObj.created_at)).getTime()

                                let date1Obj = new Date(newObj.added_at);
                                let date2Obj = new Date();

                                // Calculate the difference in milliseconds
                                let timeDifference = Math.abs(date2Obj - date1Obj);

                                let daysDifference = timeDifference / (1000 * 60 * 60 * 24);

                                allAdministrationData.push(newObj)
                                // if (newObj.isNotRefundable) {
                                //     allAdministrationData.push(newObj)
                                // } else if (daysDifference > 30) {
                                //     allAdministrationData.push(newObj)
                                // }
                            }
                        })
                    })
                })

                this.setState({ allListings: allAdministrationData, allListingsDuplicated: allAdministrationData })
            }
        })
    }

    randomStr(e) {
        let len = e
        let arr = "abcdefghijklmnopqrstuvwxyz1928102489"
        var ans = '';
        for (var i = len; i > 0; i--) {
            ans +=
                arr[Math.floor(Math.random() * arr.length)];
        }
        return ans;
    }


    getWholeAmount(org_id) {
        let allListingsOfCompany = this.state.allListings.filter((t) => {
            return t.org_id === org_id
        })
        if (allListingsOfCompany.length > 0) {
            const sum = allListingsOfCompany.reduce((accumulator, currentObject) => {
                return accumulator + Number(currentObject.amount_charged);
            }, 0);
            return sum
        } else {
            return 0
        }
    }

    getBankAccountNum(org_id) {
        let filtered = this.props.user.filter((t) => {
            return t.user_id === org_id
        })
        if (filtered.length > 0) {
            return filtered[0].bankAccountNumber
        }
    }

    getCompanyName(org_id) {
        let filtered = this.props.user.filter((t) => {
            return t.user_id === org_id
        })
        if (filtered.length > 0) {
            return filtered[0].company_name
        }
    }

    getFirstName(org_id) {
        let filtered = this.props.user.filter((t) => {
            return t.user_id === org_id
        })
        if (filtered.length > 0) {
            return filtered[0].first_name
        }
    }

    getLastName(org_id) {
        let filtered = this.props.user.filter((t) => {
            return t.user_id === org_id
        })
        if (filtered.length > 0) {
            return filtered[0].last_name
        }
    }

    generateInvoice() {
        // this.state.allListings.map((t) => {
        //     let objectContact = {
        //         "contact": {
        //             "company_name": this.getCompanyName(t.org_id),
        //             "firstname": this.getFirstName(t.org_id),
        //             "lastname": this.getLastName(t.org_id),
        //             "sepa_active": true,
        //             "sepa_iban": "NL42RABO0125141521",
        //             "sepa_iban_account_name": "Fahim Test",
        //             "sepa_bic": "RABONL2U",
        //             "sepa_mandate_id": "ABCD",
        //             "sepa_mandate_date": "2017-6-30"
        //         }
        //     }
        // })
        let objectContact = JSON.stringify({
            "contact": {
                "company_name": "Fahim is testing",
                "firstname": "Fahim",
                "lastname": "Is Testing",
                "sepa_active": true,
                "sepa_iban": "NL42RABO0125141521",
                "sepa_iban_account_name": "Fahim Test",
                "sepa_bic": "RABONL2U",
                "sepa_mandate_id": "ABCD",
                "sepa_mandate_date": "2017-6-30"
            }
        })

        let config = {
            method: "post",
            url: "https://us-central1-bhodi-production-da765.cloudfunctions.net/createContactMoneyBird",
            headers: {
                "Content-Type": "application/json"
            },
            data: objectContact
        }

        axios.request(config)
            // axios.post("https://us-central1-bhodi-production-da765.cloudfunctions.net/createContactMoneyBird", objectContact)
            .then((response) => {
                console.log(response)
            })
            .catch((error) => {
                console.log(error);
            });
    }

    render() {
        return (
            <div className="sorting-mainadmin">
                <AdminNavbar invoiceGenerator={true} />
                <div className="container-sma containeraccountcreatoradmin">
                    <div className="info-div-editor">
                        <div className="group-textbox" style={{ textAlign: "center" }}>
                            <label style={{ fontSize: 16, fontWeight: 600 }}>
                                INVOICE GENERATOR
                            </label>
                        </div>
                        <div className="top-inv-searchbutton">
                            <input type="text" placeholder="Search For Companies" value={this.state.searchedText} onChange={(e) => {
                                this.setState({ searchedText: e.target.value })
                                let text = e.target.value

                                if (e.target.value === "") {
                                    this.setState({ allListings: this.state.allListingsDuplicated })
                                } else {
                                    let filtered = [...this.state.allListingsDuplicated].filter((t) => {
                                        let company_name = this.getCompanyName(t.org_id)

                                        return company_name.toLowerCase().includes(text.toLowerCase())
                                    })
                                    this.setState({ allListings: filtered })
                                }
                            }} />
                            <button onClick={this.generateInvoice.bind(this)}>GENERATE INVOICE</button>
                        </div>
                        <div className="table-account-creator">
                            <div className="firstrow-invgen">
                                <div>
                                    <p>Company Name</p>
                                </div>
                                <div>
                                    <p>Bank Account No.</p>
                                </div>
                                <div>
                                    <p>Payment Amount</p>
                                </div>
                            </div>
                            {this.state.allListings.length > 0 && [...this.state.allListings].filter(
                                (v, i, a) => a.findIndex((t) => t.org_id === v.org_id) === i
                            ).map((e) => {
                                return (<div className="normalrows-invgen" key={Math.random()}>
                                    <div>
                                        <p>{this.getCompanyName(e.org_id)}</p>
                                    </div>
                                    <div>
                                        <p>{this.getBankAccountNum(e.org_id)}</p>
                                    </div>
                                    <div>
                                        <p>{this.getWholeAmount(e.org_id)}</p>
                                    </div>
                                </div>)
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducers.user,
    };
};


export default connect(mapStateToProps)(InvoiceGenerator);
