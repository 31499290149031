import React, { Component } from "react";
import AddIcon from "@material-ui/icons/Add";
import PublishIcon from "@material-ui/icons/Publish";
import "react-sortable-tree/style.css";
import CloseIcon from "@material-ui/icons/Close";
import firebase from "../Config/Firebase";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import AdminNavbar from "./AdminNavbar";

export class VideoMakerSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      treeData: [],
      sectorModal: false,
      createVideoMakerPopup: false,
      sectorName: "",
      sectors: [],
      currentSector: "",
      currentSectorId: "",
      categoryName: "",
      currentSector: "",
      categoryId: "",
      all_categories: [],
      sortingTab: true,
      adjustingTab: false,
      completeMainTitles: [],
      arrOfInputs: [],
      kortingsInfo: "",
      charityInfo: "",
      lastMinuteInfo: "",
      giveawayInfo: "",
      webShopInfo: "",
      videoMakers: [],
      videoMakerName: "",
      videoMakerCode: "",
      pushToEdit: "",
      assignedMembers: "",
      assignedMembersToShow: [],
      currentVideoMakerKey: "",
    };
  }

  componentDidMount() {
    const loaderId = document.getElementById("main-page-loader-id");
    loaderId.style.display = "none";

    firebase
      .database()
      .ref("video_makers")
      .on("value", (data) => {
        let a = data.val();
        let arr = [];
        if (a) {
          let objected = Object.entries(a);
          objected.map((y) => {
            arr.push(y[1]);
          });
          this.setState({ videoMakers: arr });
        } else {
          this.setState({ videoMakers: [] });
        }
      });

    firebase
      .database()
      .ref("assigned_members")
      .on("value", (data) => {
        let a = data.val();
        let arr = [];
        if (a) {
          // let objected = Object.entries(a)
          // objected.map((y) => {
          //     arr.push(y[1])
          // })
          // console.log(arr, '/this')
          this.setState({ assignedMembers: a });
        } else {
          this.setState({ assignedMembers: "" });
        }
      });
  }

  createVideoMaker() {
    const { videoMakerName, videoMakerCode } = this.state;

    let pushKey = firebase.database().ref("video_makers").push().key;

    let obj = {
      name: videoMakerName,
      code: videoMakerCode,
      key: pushKey,
    };

    firebase
      .database()
      .ref("video_makers/" + pushKey)
      .set(obj)
      .then(() => {
        document.getElementById("root").style.position = "static";
        document.getElementById("root").style.overflow = "auto";
        this.setState({
          videoMakerName: "",
          videoMakerCode: "",
          createVideoMakerPopup: false,
        });
      });
  }

  editVideoMaker() {
    const { videoMakerName, videoMakerCode } = this.state;

    let pushKey = firebase.database().ref("video_makers").push().key;

    let obj = {
      name: videoMakerName,
      code: videoMakerCode,
      key: this.state.pushToEdit,
    };

    firebase
      .database()
      .ref("video_makers/" + this.state.pushToEdit)
      .set(obj)
      .then(() => {
        document.getElementById("root").style.position = "static";
        document.getElementById("root").style.overflow = "auto";
        this.setState({
          videoMakerName: "",
          videoMakerCode: "",
          pushToEdit: "",
          editVideoMakerPopup: false,
        });
      });
  }

  checkForEyeIcon(h) {
    if (this.state.assignedMembers) {
      let objected = Object.entries(this.state.assignedMembers);
      if (objected.length > 0) {
        let filtered = objected.filter((e) => {
          return e[0] === h.key;
        });
        if (filtered.length > 0) {
          return (
            <div
              className="inner-btn-vma"
              onClick={() => {
                this.setState({
                  showAssignedMembers: true,
                  assignedMembersToShow: filtered[0][1],
                  currentVideoMakerKey: filtered[0][0],
                });
              }}
            >
              <VisibilityIcon />
            </div>
          );
        }
      }
    }
  }

  clearStatus() {
    let assignedBack = 0;
    let completeToCheckLength = [];
    let assignedArray = [...this.state.assignedMembersToShow];
    console.log(assignedArray);
    assignedArray
      .filter((v, i, a) => a.findIndex((t) => t.user_id === v.user_id) === i)
      .map((y) => {
        let currentCounted = this.state.assignedMembersToShow.filter((j) => {
          return j.user_id === y.user_id;
        });
        if (!y.videoUploaded) {
          completeToCheckLength.push(y);
          firebase
            .database()
            .ref("users/" + y.user_id)
            .once("value", async (data) => {
              let dataCame = data.val();
              if (dataCame) {
                let alreadyAssigned = 0;
                if (dataCame.maxVideosToMake) {
                  alreadyAssigned = dataCame.maxVideosToMake;
                }
                let a = alreadyAssigned;
                let b = currentCounted.length;
                let difference = (a, b) => {
                  return Math.abs(Number(a) - Number(b));
                };
                firebase
                  .database()
                  .ref("users/" + y.user_id + "/maxVideosToMake")
                  .set(difference(a, b))
                  .then(async () => {
                    assignedBack = assignedBack + 1;
                    if (assignedBack === completeToCheckLength.length) {
                      firebase
                        .database()
                        .ref(
                          "assigned_members/" + this.state.currentVideoMakerKey
                        )
                        .remove()
                        .then(async () => {
                          this.setState({
                            assignedMembersToShow: [],
                            showAssignedMembers: false,
                          });
                        });
                    }
                  });
              }
            });
        }
      });
  }

  uploadCharityVideoSound(e) {
    let a = e.target.files[0];
    firebase
      .storage()
      .ref()
      .child(`bhodi_charity_video_sound/${a.name}${new Date().getTime()}`)
      .put(a)
      .then((snapshot) => {
        snapshot.ref.getDownloadURL().then((snapUrl) => {
          firebase
            .database()
            .ref("bhodi_home/charity_video_sound")
            .set(snapUrl);
        });
      });
  }

  render() {
    return (
      <div className="sorting-mainadmin">
        <AdminNavbar videoMakerPage={true} />
        <div className="container-sma">
          <div className="top-container-sma">
            <div className="sector-tcsma"></div>
            <div className="category-tcsma">
              <p
                onClick={() => {
                  document.getElementById("root").style.position = "fixed";
                  document.getElementById("root").style.overflow = "hidden";
                  this.setState({ createVideoMakerPopup: true });
                }}
              >
                <AddIcon /> VIDEO MAKER{" "}
              </p>
              <p className="uploadiconaudio" style={{ overflow: "hidden" }}>
                <PublishIcon /> UPLOAD SOUND
                <input
                  type="file"
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    opacity: 0,
                  }}
                  accept="audio/*"
                  onChange={(e) => this.uploadCharityVideoSound(e)}
                />
              </p>
            </div>
          </div>
          <div className="tree-inner" style={{ marginTop: 30 }}>
            <ul className="adjustng-ti videomakeradjusting">
              {this.state.videoMakers.map((h, i) => {
                return (
                  <li>
                    <div>{h.name}</div>
                    <div className="btns-videomakeradjusting">
                      {this.checkForEyeIcon(h)}
                      <div
                        className="inner-btn-vma"
                        onClick={() => {
                          document.getElementById("root").style.position =
                            "fixed";
                          document.getElementById("root").style.overflow =
                            "hidden";
                          this.setState({
                            editVideoMakerPopup: true,
                            videoMakerName: h.name,
                            videoMakerCode: h.code,
                            pushToEdit: h.key,
                          });
                        }}
                      >
                        <EditIcon />
                      </div>
                      <div className="inner-btn-vma">
                        <DeleteIcon
                          onClick={() => {
                            firebase
                              .database()
                              .ref("video_makers/" + h.key)
                              .remove();
                          }}
                        />
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
            {this.state.showAssignedMembers && (
              <div className="table-container-sma" style={{ padding: 15 }}>
                <div className="row-tcsma">
                  <p className="name-rtcs">&nbsp;</p>
                  <p className="email-rtcs">&nbsp;</p>
                  <p className="type-rtcs">&nbsp;</p>
                  <p
                    className="subscription-rtcs"
                    style={{ cursor: "pointer" }}
                    onClick={this.clearStatus.bind(this)}
                  >
                    <b>Clear All</b>
                  </p>
                </div>
                <div className="row-tcsma">
                  <p className="name-rtcs">Information</p>
                  <p className="email-rtcs">Plan Amount</p>
                  <p className="type-rtcs">Type</p>
                  <p className="subscription-rtcs">Subscription</p>
                </div>
                {this.state.assignedMembersToShow.length > 0 &&
                  [...this.state.assignedMembersToShow]
                    .filter(
                      (v, i, a) =>
                        a.findIndex((t) => t.user_id === v.user_id) === i
                    )
                    .map((y) => {
                      return (
                        <div className="row-tcsma">
                          <p className="name-rtcs">
                            {!y.companyName
                              ? `${y.firstName} ${y.lastName}`
                              : y.companyName}
                          </p>
                          <p className="email-rtcs">{y.plan_amount}</p>
                          <p className="type-rtcs">
                            {!y.companyName ? "Member" : "Company"}
                          </p>
                          <p className="subscription-rtcs">
                            {y.subscription ? y.subscription.status : "No"}
                          </p>
                        </div>
                      );
                    })}
              </div>
            )}
          </div>
        </div>
        <div
          className="modal-sector-sorting"
          style={{ right: this.state.createVideoMakerPopup ? 0 : "-100%" }}
        >
          <CloseIcon
            className="close-mss"
            onClick={() => {
              document.getElementById("root").style.position = "static";
              document.getElementById("root").style.overflow = "auto";
              this.setState({ createVideoMakerPopup: false });
            }}
          />
          <p>Create Category</p>
          <div className="form-mss">
            <label>Video Maker Name</label>
            <input
              type="text"
              value={this.state.videoMakerName}
              onChange={(e) =>
                this.setState({ videoMakerName: e.target.value })
              }
            />
          </div>
          <div className="form-mss">
            <label>Video Maker Code</label>
            <input
              type="number"
              value={this.state.videoMakerCode}
              onChange={(e) => {
                if (e.target.value.length < 5) {
                  this.setState({ videoMakerCode: e.target.value });
                }
              }}
            />
          </div>
          <div className="form-mss" style={{ marginBottom: 40 }}>
            <button onClick={this.createVideoMaker.bind(this)}>CREATE</button>
          </div>
        </div>

        <div
          className="modal-sector-overlay"
          style={{
            opacity: this.state.editVideoMakerPopup ? 0.7 : 0,
            pointerEvents: this.state.editVideoMakerPopup ? "all" : "none",
          }}
          onClick={() => {
            document.getElementById("root").style.position = "static";
            document.getElementById("root").style.overflow = "auto";
            this.setState({ editVideoMakerPopup: false });
          }}
        ></div>
        <div
          className="modal-sector-sorting"
          style={{ right: this.state.editVideoMakerPopup ? 0 : "-100%" }}
        >
          <CloseIcon
            className="close-mss"
            onClick={() => {
              document.getElementById("root").style.position = "static";
              document.getElementById("root").style.overflow = "auto";
              this.setState({ editVideoMakerPopup: false });
            }}
          />
          <p>Create Category</p>
          <div className="form-mss">
            <label>Video Maker Name</label>
            <input
              type="text"
              value={this.state.videoMakerName}
              onChange={(e) =>
                this.setState({ videoMakerName: e.target.value })
              }
            />
          </div>
          <div className="form-mss">
            <label>Video Maker Code</label>
            <input
              type="number"
              value={this.state.videoMakerCode}
              onChange={(e) => {
                if (e.target.value.length < 5) {
                  this.setState({ videoMakerCode: e.target.value });
                }
              }}
            />
          </div>
          <div className="form-mss" style={{ marginBottom: 40 }}>
            <button onClick={this.editVideoMaker.bind(this)}>EDIT</button>
          </div>
        </div>
        <div
          className="modal-sector-overlay"
          style={{
            opacity: this.state.editVideoMakerPopup ? 0.7 : 0,
            pointerEvents: this.state.editVideoMakerPopup ? "all" : "none",
          }}
          onClick={() => {
            document.getElementById("root").style.position = "static";
            document.getElementById("root").style.overflow = "auto";
            this.setState({ editVideoMakerPopup: false });
          }}
        ></div>
      </div>
    );
  }
}

export default VideoMakerSettings;
