import React, { Component } from "react";
// import bhodiVideo from "../../assets/bhodi-home-video.mp4";
import Footer from "../../Components/Footer";
import FooterGreen from "../../Components/FooterGreen";
import swal from "sweetalert";
import firebase from "../../Config/Firebase";
import "./index.css";
import PaymentFailed from "../../Components/PaymentFailed";
const axios = require("axios");

export class NewVisit extends Component {
  constructor() {
    super();
    this.state = {
      currentNum: 0,
      videos: [
        { key: "videoone", played: false, sound: false },
        { key: "videosec", played: false, sound: false },
      ],
      videoPopup: false,
      allVideos: [],
      videourl: "",
      limit: 5,
      showPlayIcon: true,
      showLoader: true,
      notSubscribed: true,
      subscription_data: [],
      currentUser: "",
      editWork: false,
      showTopButton: true,
      toShowSections: [],
      isDesktop: false,
      loader: false,
    };
    // window.addEventListener("beforeunload", (ev) => {
    //   let uid = localStorage.getItem("userid");
    //   if (uid) {
    //     firebase
    //       .database()
    //       .ref("users/" + uid + "/newly_registered")
    //       .set(false);
    //     firebase
    //       .database()
    //       .ref("users/" + uid + "/movedToMollie")
    //       .set(false);
    //   }
    // });
  }

  componentDidMount() {
    const loaderId = document.getElementById("main-page-loader-id");
    loaderId.style.display = "none";
    let uid = localStorage.getItem("userid");
    document.getElementById("root").style.overflow = "hidden";

    if (document.getElementById("navbartohidemainly")) {
      document.getElementById("navbartohidemainly").style.overflowX = "hidden";
    }

    let arr = [];

    if (window.innerWidth < 1009) {
      this.setState({ isDesktop: false });
    } else {
      this.setState({ isDesktop: true });
    }

    firebase
      .database()
      .ref("subscription_plans")
      .on("child_added", (data) => {
        if (data.val().price !== "0.00") {
          arr.push(data.val());
          this.setState({
            subscription_data: arr,
            plan_id: arr[0].plan_id,
            plan_amount: arr[0].price,
            stripe_plan_key: arr[0].stripe_plan_key,
          });
        }
      });

    if (this.props.currentMemberData) {
      if (this.props.currentMemberData.movedToMollie) {
        this.setState({ loader: true })
      }
      this.setState({ currentUser: this.props.currentMemberData })
    }
    firebase
      .database()
      .ref("users/" + uid)
      .once("value", (data) => {
        let a = data.val();
        if (a) {
          this.setState({ currentUser: a, notSubscribed: true, loader: false });
        }
      });
  }

  saveSubscription() {
    if (this.state.notSubscribed) {
      let userId = localStorage.getItem("userid");
      let uid = localStorage.getItem("userid");

      if (!(this.state.plan_id && this.state.plan_amount)) {
        return false;
      }
      this.setState({ loader: true });

      let plan_amount = this.state.plan_amount;
      let plan_id = this.state.plan_id;

      if (userId) {
        let currentTime = Math.floor(Date.now() / 1000);
        let getRandom = this.randomStr(6);
        let hashToken = getRandom + "" + currentTime;

        let redirect_url = "";
        if (this.state.currentUser.type == "user") {
          redirect_url = `${window.location.origin}/member/${this.state.currentUser.user_name_id}/charity`;
        } else {
          redirect_url = `${window.location.origin}/company/${this.state.currentUser.user_name_id}/charity`;
        }

        axios
          .post(
            "https://us-central1-bhodi-production-da765.cloudfunctions.net/createStripeCustomer",
            {
              fullName:
                this.state.currentUser.first_name +
                "" +
                this.state.currentUser.last_name,
              email: this.state.currentUser.email,
            }
          )
          .then((response) => {
            axios
              .post(
                "https://us-central1-bhodi-production-da765.cloudfunctions.net/createStripeCheckout",
                {
                  priceId: this.state.stripe_plan_key,
                  successUrl: redirect_url,
                  cancelUrl: redirect_url,
                  customerId: response.data.data.id,
                  // amount: this.state.plan_amount,
                  // customerId: response.data.customer.id,
                  // redirectUrl: redirect_url,
                }
              )
              .then((responseSec) => {
                firebase
                  .database()
                  .ref("users/" + uid + "/stripe_id")
                  .set(response.data.data.id);
                firebase
                  .database()
                  .ref("users/" + uid + "/create_subscription")
                  .set(true);
                // firebase
                //   .database()
                //   .ref("users/" + uid + "/payment_url")
                //   .set(responseSec.data.payment._links.checkout.href);
                firebase
                  .database()
                  .ref("users/" + uid + "/plan_id")
                  .set(plan_id);
                firebase
                  .database()
                  .ref("users/" + uid + "/plan_amount")
                  .set(plan_amount);
                // firebase
                //   .database()
                //   .ref("users/" + uid + "/mollieHashToken")
                //   .set(hashToken);
                firebase
                  .database()
                  .ref("users/" + uid + "/newly_registered")
                  .set(false);
                firebase
                  .database()
                  .ref("users/" + uid + "/payment_id")
                  .set(responseSec.data.data.id)
                  .then(() => {
                    window.location.href = responseSec.data.data.url;
                    // window.location.href =
                    //   responseSec.data.payment._links.checkout.href;
                  });
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }

  randomStr(e) {
    let len = e;
    let arr = "abcdefghijklmnopqrstuvwxyz1928102489";
    var ans = "";
    for (var i = len; i > 0; i--) {
      ans += arr[Math.floor(Math.random() * arr.length)];
    }
    return ans;
  }

  render() {
    return (
      <div
        style={{
          position: this.state.isDesktop && "fixed",
          width: "100%",
          height: "calc(100% - 70px)",
          top: 70,
          zIndex: 99999,
          minHeight: window.innerWidth < 1008 && `${window.innerHeight - 129.86}px`,
          paddingBottom: !this.state.isDesktop && 250,
        }}
        className="charitysectionmainwork newvisitwork newvisitworkupdatedmaindesign"
      >
        {/* <div style={{ position: this.state.isDesktop && "fixed", width: "100%", height: "calc(100% - 70px)", top: 70, zIndex: 99999, paddingBottom: !this.state.isDesktop && 250, backgroundImage: `url("https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/bhodi_assets_web%2Fbg3.png?alt=media&token=c2fd76ff-64bd-482a-8f13-0a9a21e81dae")` }} className="charitysectionmainwork newvisitwork"> */}
        <div
          className="loader-fullPage"
          style={{ opacity: this.state.loader ? 1 : 0 }}
        >
          <div className="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        {this.state.isDesktop ? (
          <video
            src="https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/bhodi-home-video.mp4?alt=media&token=792129cc-4b91-4a3d-becd-a5e87a7aee7f"
            // src={bhodiVideo}
            autoPlay
            playsInline
            muted
            loop
            className="absolutevideofullbg"
          ></video>
        ) : window.innerWidth > 1008 && (
          <div className="videocvoer-newvisit">
            <video
              src="https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/bhodi-home-video.mp4?alt=media&token=792129cc-4b91-4a3d-becd-a5e87a7aee7f"
              autoPlay
              playsInline
              muted
              loop
              className="absolutevideofullbg"
            ></video>
          </div>
        )}

        {(this.state.currentUser.movedToMollie && this.state.currentUser.specialAccountDonator && this.state.currentUser.donation_payment_status !== "paid" && !this.state.loader) ? (<PaymentFailed currentMemberData={this.state.currentUser} />) : !this.state.loader && (
          <div
            className="third-sec-cdd"
            style={{ paddingTop: 0, paddingBottom: 0 }}
          >
            <div
              className="footer-sscdd"
              style={{
                marginTop: 0,
                alignItems: "initial",
                justifyContent: "center",
                height: "auto",
              }}
            >
              <div
                className="left-fsscdd"
                style={{
                  backgroundColor: "#285151",
                  maxWidth: "26%",
                  textAlign: "center",
                }}
              >
                <div className="charity-lfss">
                  <p
                    className="heading-charity-lfss"
                    style={{
                      textTransform: "none",
                      fontSize: 14,
                      fontWeight: 400,
                      marginBottom: 25,
                    }}
                  >
                    Hoi{" "}
                    <b style={{ fontWeight: 600 }}>
                      {this.state.currentUser.type === "company"
                        ? this.state.currentUser.company_name
                        : this.state.currentUser.first_name}
                    </b>,{" "}
                    welkom bij Bhodi.
                  </p>
                  {/* <p className="heading-charity-lfss" style={{ textTransform: "none", fontWeight: 400 }}>Welkom <b style={{ fontWeight: 600 }}>{this.state.currentUser.first_name}</b></p> */}
                  <p className="para-charity-lfss">
                    Word Charity Member en krijg toegang tot
                    <br />
                    exclusieve aanbiedingen op onze website.
                  </p>
                  <p className="para-charity-lfss">
                    Als Charity Member ondersteun je kinderen
                    <br />
                    en scholen in Tanzania met maaltijden en
                    <br />
                    materialen. Van deze uitgifte ontvang je
                    {/* materialen. Hiervan ontvang je elke maand */}
                    <br />
                    een bedankvideo vanuit Tanzania.
                    {/* een bedankvideo vanuit Tanzania. */}
                  </p>
                  <p className="para-charity-lfss">
                    Je maandelijkse bijdrage kun je op ieder
                    <br />
                    gewenst moment aanpassen of stopzetten.
                  </p>
                  {!this.state.currentUser.specialAccountDonator && (<p className="para-charity-lfss">Selecteer je bijdrage:</p>)}
                  {!this.state.currentUser.specialAccountDonator && <div
                    className="radios-charity-lfss"
                    style={{ justifyContent: "center" }}
                  >
                    {this.state.subscription_data.length > 0 &&
                      ([...this.state.subscription_data].sort((a, b) => a.sort_level - b.sort_level).map((e) => {
                        if (!e.active) {
                          return false;
                        }
                        return (
                          <div className="inner-rclfss">
                            <input
                              type="radio"
                              id="radiocharity"
                              name="charity"
                              value={e.plan_id}
                              checked={e.plan_id === this.state.plan_id}
                              onChange={() =>
                                this.setState({
                                  plan_id: e.plan_id,
                                  plan_amount: e.price,
                                  stripe_plan_key: e.stripe_plan_key,
                                })
                              }
                            />
                            <span>€ {Number(e.price)}</span>
                          </div>
                        );
                      }))}
                  </div>}
                  {/* <p className="para-sec-charity-lfss">Klik hier als je de bijdrage over wilt slaan</p> */}
                  <button
                    className="button-charity-lfss"
                    onClick={() => {
                      let uid = localStorage.getItem("userid");
                      if (uid) {
                        if (this.props.fromConfirmationPage) {
                          let a = this.props.currentUserPropin;
                          if (a.type === "user") {
                            window.location.href = `/member/${a.user_name_id}`;
                          } else {
                            window.location.href = `/company/${a.user_name_id}`;
                          }
                        } else {
                          if (document.getElementById("navbartohidemainly")) {
                            document.getElementById(
                              "navbartohidemainly"
                            ).style.overflowX = "auto";
                          }
                          firebase
                            .database()
                            .ref("users/" + uid + "/newly_registered")
                            .set(false);
                        }
                      }
                    }}
                  >
                    Overslaan
                  </button>
                  {!this.state.currentUser.specialAccountDonator && (<button
                    className="button-charity-lfss"
                    style={{ marginLeft: 5 }}
                    onClick={this.saveSubscription.bind(this)}
                  >
                    Verder
                  </button>)}
                  {this.state.currentUser.specialAccountDonator && (<button
                    className="button-charity-lfss"
                    style={{ marginLeft: 5 }}
                    onClick={() => {
                      if (window.innerWidth > 1008) {
                        this.props.openClientListRight()
                      } else {
                        swal({
                          title: "LET OP",
                          text: "You can open desktop to upload clients list",
                          icon: "warning"
                        })
                      }
                    }}
                  >
                    UPLOAD CLIENTS
                  </button>)}
                  {/* <div>
                                    <p className="text-cancel-new-visit" onClick={() => {
                                        let uid = localStorage.getItem("userid")
                                        firebase.database().ref("users/" + uid + "/newly_registered").set(false)
                                    }}>OVERSLAN</p>
                                </div> */}
                </div>
              </div>
            </div>
          </div>
        )}
        {window.innerWidth > 1008 && <Footer notOnMainPage={true} />}
        {window.innerWidth < 1008 && <FooterGreen notOnMainPage={true} />}
      </div>
    );
  }
}

export default NewVisit;
