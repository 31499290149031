import React, { Component } from "react";
import "react-sortable-tree/style.css";
import firebase from "../Config/Firebase";
import { connect } from "react-redux";
import { updateGlobalStuff } from "../Redux/actions/authActions";
import CK from "./Ck";
import AdminNavbar from "./AdminNavbar";

export class WizardSettings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            treeData: [],
            sectorModal: false,
            sectorModalCategory: false,
            sectorName: "",
            sectors: [],
            currentSector: "",
            currentSectorId: "",
            categoryName: "",
            currentSector: "",
            categoryId: "",
            all_categories: [],
            completeMainTitles: [],
            showStuffNow: false,
            onMobileDesigns: false,

            member_trending_dutch: "",
            member_trending_english: "",
            member_stories_dutch: "",
            member_stories_english: "",
            member_vrienden_dutch: "",
            member_vrienden_english: "",
            member_charity_dutch: "",
            member_charity_english: "",

            company_home_dutch: "",
            company_home_english: "",
            company_events_dutch: "",
            company_events_english: "",
            company_products_dutch: "",
            company_products_english: "",
            company_stories_dutch: "",
            company_stories_english: "",
            company_impressie_dutch: "",
            company_impressie_english: "",
            company_charity_dutch: "",
            company_charity_english: "",
            company_vrienden_dutch: "",
            company_vrienden_english: "",
            company_greetings_dutch: "",
            company_greetings_english: "",


            member_trending_dutch_mobile: "",
            member_trending_english_mobile: "",
            member_stories_dutch_mobile: "",
            member_stories_english_mobile: "",
            member_vrienden_dutch_mobile: "",
            member_vrienden_english_mobile: "",
            member_charity_dutch_mobile: "",
            member_charity_english_mobile: "",

            company_home_dutch_mobile: "",
            company_home_english_mobile: "",
            company_events_dutch_mobile: "",
            company_events_english_mobile: "",
            company_products_dutch_mobile: "",
            company_products_english_mobile: "",
            company_stories_dutch_mobile: "",
            company_stories_english_mobile: "",
            company_impressie_dutch_mobile: "",
            company_impressie_english_mobile: "",
            company_charity_dutch_mobile: "",
            company_charity_english_mobile: "",
            company_vrienden_dutch_mobile: "",
            company_vrienden_english_mobile: "",
            company_greetings_dutch_mobile: "",
            company_greetings_english_mobile: "",
        };
    }

    componentDidMount() {
        const loaderId = document.getElementById("main-page-loader-id");
        loaderId.style.display = "none";

        setTimeout(() => {
            this.setState({ showStuffNow: true });
        }, 100);

        if (this.props.global_values) {
            let a = this.props.global_values;
            if (a) {
                this.setState({
                    member_trending_dutch: a.member_trending_dutch,
                    member_trending_english: a.member_trending_english,
                    member_stories_dutch: a.member_stories_dutch,
                    member_stories_english: a.member_stories_english,
                    member_vrienden_dutch: a.member_vrienden_dutch,
                    member_vrienden_english: a.member_vrienden_english,
                    member_charity_dutch: a.member_charity_dutch,
                    member_charity_english: a.member_charity_english,

                    company_home_dutch: a.company_home_dutch,
                    company_home_english: a.company_home_english,
                    company_events_dutch: a.company_events_dutch,
                    company_events_english: a.company_events_english,
                    company_products_dutch: a.company_products_dutch,
                    company_products_english: a.company_products_english,
                    company_stories_dutch: a.company_stories_dutch,
                    company_stories_english: a.company_stories_english,
                    company_impressie_dutch: a.company_impressie_dutch,
                    company_impressie_english: a.company_impressie_english,
                    company_charity_dutch: a.company_charity_dutch,
                    company_charity_english: a.company_charity_english,
                    company_vrienden_dutch: a.company_vrienden_dutch,
                    company_vrienden_english: a.company_vrienden_english,
                    company_greetings_dutch: a.company_greetings_dutch,
                    company_greetings_english: a.company_greetings_english,


                    member_trending_dutch_mobile: a.member_trending_dutch_mobile,
                    member_trending_english_mobile: a.member_trending_english_mobile,
                    member_stories_dutch_mobile: a.member_stories_dutch_mobile,
                    member_stories_english_mobile: a.member_stories_english_mobile,
                    member_vrienden_dutch_mobile: a.member_vrienden_dutch_mobile,
                    member_vrienden_english_mobile: a.member_vrienden_english_mobile,
                    member_charity_dutch_mobile: a.member_charity_dutch_mobile,
                    member_charity_english_mobile: a.member_charity_english_mobile,

                    company_home_dutch_mobile: a.company_home_dutch_mobile,
                    company_home_english_mobile: a.company_home_english_mobile,
                    company_events_dutch_mobile: a.company_events_dutch_mobile,
                    company_events_english_mobile: a.company_events_english_mobile,
                    company_products_dutch_mobile: a.company_products_dutch_mobile,
                    company_products_english_mobile: a.company_products_english_mobile,
                    company_stories_dutch_mobile: a.company_stories_dutch_mobile,
                    company_stories_english_mobile: a.company_stories_english_mobile,
                    company_impressie_dutch_mobile: a.company_impressie_dutch_mobile,
                    company_impressie_english_mobile: a.company_impressie_english_mobile,
                    company_charity_dutch_mobile: a.company_charity_dutch_mobile,
                    company_charity_english_mobile: a.company_charity_english_mobile,
                    company_vrienden_dutch_mobile: a.company_vrienden_dutch_mobile,
                    company_vrienden_english_mobile: a.company_vrienden_english_mobile,
                    company_greetings_dutch_mobile: a.company_greetings_dutch_mobile,
                    company_greetings_english_mobile: a.company_greetings_english_mobile,
                });
            }
        }

        firebase
            .database()
            .ref("bhodi_home")
            .on("value", (data) => {
                let a = data.val();
                if (a) {
                    this.setState({
                        member_trending_dutch: a.member_trending_dutch,
                        member_trending_english: a.member_trending_english,
                        member_stories_dutch: a.member_stories_dutch,
                        member_stories_english: a.member_stories_english,
                        member_vrienden_dutch: a.member_vrienden_dutch,
                        member_vrienden_english: a.member_vrienden_english,
                        member_charity_dutch: a.member_charity_dutch,
                        member_charity_english: a.member_charity_english,

                        company_home_dutch: a.company_home_dutch,
                        company_home_english: a.company_home_english,
                        company_events_dutch: a.company_events_dutch,
                        company_events_english: a.company_events_english,
                        company_products_dutch: a.company_products_dutch,
                        company_products_english: a.company_products_english,
                        company_stories_dutch: a.company_stories_dutch,
                        company_stories_english: a.company_stories_english,
                        company_impressie_dutch: a.company_impressie_dutch,
                        company_impressie_english: a.company_impressie_english,
                        company_charity_dutch: a.company_charity_dutch,
                        company_charity_english: a.company_charity_english,
                        company_vrienden_dutch: a.company_vrienden_dutch,
                        company_vrienden_english: a.company_vrienden_english,
                        company_greetings_dutch: a.company_greetings_dutch,
                        company_greetings_english: a.company_greetings_english,

                        member_trending_dutch_mobile: a.member_trending_dutch_mobile,
                        member_trending_english_mobile: a.member_trending_english_mobile,
                        member_stories_dutch_mobile: a.member_stories_dutch_mobile,
                        member_stories_english_mobile: a.member_stories_english_mobile,
                        member_vrienden_dutch_mobile: a.member_vrienden_dutch_mobile,
                        member_vrienden_english_mobile: a.member_vrienden_english_mobile,
                        member_charity_dutch_mobile: a.member_charity_dutch_mobile,
                        member_charity_english_mobile: a.member_charity_english_mobile,

                        company_home_dutch_mobile: a.company_home_dutch_mobile,
                        company_home_english_mobile: a.company_home_english_mobile,
                        company_events_dutch_mobile: a.company_events_dutch_mobile,
                        company_events_english_mobile: a.company_events_english_mobile,
                        company_products_dutch_mobile: a.company_products_dutch_mobile,
                        company_products_english_mobile: a.company_products_english_mobile,
                        company_stories_dutch_mobile: a.company_stories_dutch_mobile,
                        company_stories_english_mobile: a.company_stories_english_mobile,
                        company_impressie_dutch_mobile: a.company_impressie_dutch_mobile,
                        company_impressie_english_mobile: a.company_impressie_english_mobile,
                        company_charity_dutch_mobile: a.company_charity_dutch_mobile,
                        company_charity_english_mobile: a.company_charity_english_mobile,
                        company_vrienden_dutch_mobile: a.company_vrienden_dutch_mobile,
                        company_vrienden_english_mobile: a.company_vrienden_english_mobile,
                        company_greetings_dutch_mobile: a.company_greetings_dutch_mobile,
                        company_greetings_english_mobile: a.company_greetings_english_mobile,
                    });
                }
            });
    }


    saveValueToDb(storeAt, e) {
        if (this.state.onMobileDesigns) {
            firebase.database().ref(`bhodi_home/${storeAt}`).set(e)
        } else {
            firebase.database().ref(`bhodi_home/${storeAt}`).set(e)
        }
    }

    render() {
        return (
            <div className="sorting-mainadmin">
                <AdminNavbar onWizard={true} />
                <div className="buttons-container-displayeditor">
                    {this.state.onMobileDesigns ? (
                        <button
                            className="buttonsgreendisplay"
                            onClick={() => {
                                this.setState({ onMobileDesigns: false });
                            }}
                        >
                            Switch To Desktop
                        </button>
                    ) : (
                        <button
                            className="buttonsgreendisplay"
                            onClick={() => {
                                this.setState({ onMobileDesigns: true });
                            }}
                        >
                            Switch To Mobile
                        </button>
                    )}
                </div>
                {this.state.showStuffNow && this.state.onMobileDesigns ? (
                    <div
                        className="container-sma"
                        style={{ maxWidth: 1200, paddingBottom: 50 }}
                    >
                        <div className="tree-inner" style={{ marginTop: 30 }}>
                            <div
                                className="container-editors-lp"
                                style={{ justifyContent: "center", marginTop: 30 }}
                            >
                                <div className="inner-celandingpage headinglabelcelp">
                                    <label>Member Trending</label>
                                </div>
                            </div>
                            <div className="container-editors-lp">
                                <div className="inner-celandingpage">
                                    <label>Dutch</label>
                                    <CK
                                        data={this.state.member_trending_dutch_mobile}
                                        onChildChange={this.saveValueToDb.bind(this, "member_trending_dutch_mobile")}
                                    />
                                </div>
                                <div className="inner-celandingpage">
                                    <label>English</label>
                                    <CK
                                        data={this.state.member_trending_english_mobile}
                                        onChildChange={this.saveValueToDb.bind(this, "member_trending_english_mobile")}
                                    />
                                </div>
                            </div>
                            <div
                                className="container-editors-lp"
                                style={{ justifyContent: "center", marginTop: 30 }}
                            >
                                <div className="inner-celandingpage headinglabelcelp">
                                    <label>Member Stories</label>
                                </div>
                            </div>
                            <div className="container-editors-lp">
                                <div className="inner-celandingpage">
                                    <label>Dutch</label>
                                    <CK
                                        data={this.state.member_stories_dutch_mobile}
                                        onChildChange={this.saveValueToDb.bind(this, "member_stories_dutch_mobile")}
                                    />
                                </div>
                                <div className="inner-celandingpage">
                                    <label>English</label>
                                    <CK
                                        data={this.state.member_stories_english_mobile}
                                        onChildChange={this.saveValueToDb.bind(this, "member_stories_english_mobile")}
                                    />
                                </div>
                            </div>
                            <div
                                className="container-editors-lp"
                                style={{ justifyContent: "center", marginTop: 30 }}
                            >
                                <div className="inner-celandingpage headinglabelcelp">
                                    <label>Member Vrienden</label>
                                </div>
                            </div>
                            <div className="container-editors-lp">
                                <div className="inner-celandingpage">
                                    <label>Dutch</label>
                                    <CK
                                        data={this.state.member_vrienden_dutch_mobile}
                                        onChildChange={this.saveValueToDb.bind(this, "member_vrienden_dutch_mobile")}
                                    />
                                </div>
                                <div className="inner-celandingpage">
                                    <label>English</label>
                                    <CK
                                        data={this.state.member_vrienden_english_mobile}
                                        onChildChange={this.saveValueToDb.bind(this, "member_vrienden_english_mobile")}
                                    />
                                </div>
                            </div>
                            <div
                                className="container-editors-lp"
                                style={{ justifyContent: "center", marginTop: 30 }}
                            >
                                <div className="inner-celandingpage headinglabelcelp">
                                    <label>Member Charity</label>
                                </div>
                            </div>
                            <div className="container-editors-lp">
                                <div className="inner-celandingpage">
                                    <label>Dutch</label>
                                    <CK
                                        data={this.state.member_charity_dutch_mobile}
                                        onChildChange={this.saveValueToDb.bind(this, "member_charity_dutch_mobile")}
                                    />
                                </div>
                                <div className="inner-celandingpage">
                                    <label>English</label>
                                    <CK
                                        data={this.state.member_charity_english_mobile}
                                        onChildChange={this.saveValueToDb.bind(this, "member_charity_english_mobile")}
                                    />
                                </div>
                            </div>


                            <div
                                className="container-editors-lp"
                                style={{ justifyContent: "center", marginTop: 30 }}
                            >
                                <div className="inner-celandingpage headinglabelcelp">
                                    <label>Company Home</label>
                                </div>
                            </div>
                            <div className="container-editors-lp">
                                <div className="inner-celandingpage">
                                    <label>Dutch</label>
                                    <CK
                                        data={this.state.company_home_dutch_mobile}
                                        onChildChange={this.saveValueToDb.bind(this, "company_home_dutch_mobile")}
                                    />
                                </div>
                                <div className="inner-celandingpage">
                                    <label>English</label>
                                    <CK
                                        data={this.state.company_home_english_mobile}
                                        onChildChange={this.saveValueToDb.bind(this, "company_home_english_mobile")}
                                    />
                                </div>
                            </div>
                            <div
                                className="container-editors-lp"
                                style={{ justifyContent: "center", marginTop: 30 }}
                            >
                                <div className="inner-celandingpage headinglabelcelp">
                                    <label>Company Events</label>
                                </div>
                            </div>
                            <div className="container-editors-lp">
                                <div className="inner-celandingpage">
                                    <label>Dutch</label>
                                    <CK
                                        data={this.state.company_events_dutch_mobile}
                                        onChildChange={this.saveValueToDb.bind(this, "company_events_dutch_mobile")}
                                    />
                                </div>
                                <div className="inner-celandingpage">
                                    <label>English</label>
                                    <CK
                                        data={this.state.company_events_english_mobile}
                                        onChildChange={this.saveValueToDb.bind(this, "company_events_english_mobile")}
                                    />
                                </div>
                            </div>
                            <div
                                className="container-editors-lp"
                                style={{ justifyContent: "center", marginTop: 30 }}
                            >
                                <div className="inner-celandingpage headinglabelcelp">
                                    <label>Company Products</label>
                                </div>
                            </div>
                            <div className="container-editors-lp">
                                <div className="inner-celandingpage">
                                    <label>Dutch</label>
                                    <CK
                                        data={this.state.company_products_dutch_mobile}
                                        onChildChange={this.saveValueToDb.bind(this, "company_products_dutch_mobile")}
                                    />
                                </div>
                                <div className="inner-celandingpage">
                                    <label>English</label>
                                    <CK
                                        data={this.state.company_products_english_mobile}
                                        onChildChange={this.saveValueToDb.bind(this, "company_products_english_mobile")}
                                    />
                                </div>
                            </div>
                            <div
                                className="container-editors-lp"
                                style={{ justifyContent: "center", marginTop: 30 }}
                            >
                                <div className="inner-celandingpage headinglabelcelp">
                                    <label>Company Stories</label>
                                </div>
                            </div>
                            <div className="container-editors-lp">
                                <div className="inner-celandingpage">
                                    <label>Dutch</label>
                                    <CK
                                        data={this.state.company_stories_dutch_mobile}
                                        onChildChange={this.saveValueToDb.bind(this, "company_stories_dutch_mobile")}
                                    />
                                </div>
                                <div className="inner-celandingpage">
                                    <label>English</label>
                                    <CK
                                        data={this.state.company_stories_english_mobile}
                                        onChildChange={this.saveValueToDb.bind(this, "company_stories_english_mobile")}
                                    />
                                </div>
                            </div>
                            <div
                                className="container-editors-lp"
                                style={{ justifyContent: "center", marginTop: 30 }}
                            >
                                <div className="inner-celandingpage headinglabelcelp">
                                    <label>Company Impressie</label>
                                </div>
                            </div>
                            <div className="container-editors-lp">
                                <div className="inner-celandingpage">
                                    <label>Dutch</label>
                                    <CK
                                        data={this.state.company_impressie_dutch_mobile}
                                        onChildChange={this.saveValueToDb.bind(this, "company_impressie_dutch_mobile")}
                                    />
                                </div>
                                <div className="inner-celandingpage">
                                    <label>English</label>
                                    <CK
                                        data={this.state.company_impressie_english_mobile}
                                        onChildChange={this.saveValueToDb.bind(this, "company_impressie_english_mobile")}
                                    />
                                </div>
                            </div>
                            <div
                                className="container-editors-lp"
                                style={{ justifyContent: "center", marginTop: 30 }}
                            >
                                <div className="inner-celandingpage headinglabelcelp">
                                    <label>Company Charity</label>
                                </div>
                            </div>
                            <div className="container-editors-lp">
                                <div className="inner-celandingpage">
                                    <label>Dutch</label>
                                    <CK
                                        data={this.state.company_charity_dutch_mobile}
                                        onChildChange={this.saveValueToDb.bind(this, "company_charity_dutch_mobile")}
                                    />
                                </div>
                                <div className="inner-celandingpage">
                                    <label>English</label>
                                    <CK
                                        data={this.state.company_charity_english_mobile}
                                        onChildChange={this.saveValueToDb.bind(this, "company_charity_english_mobile")}
                                    />
                                </div>
                            </div>
                            <div
                                className="container-editors-lp"
                                style={{ justifyContent: "center", marginTop: 30 }}
                            >
                                <div className="inner-celandingpage headinglabelcelp">
                                    <label>Company Vrienden</label>
                                </div>
                            </div>
                            <div className="container-editors-lp">
                                <div className="inner-celandingpage">
                                    <label>Dutch</label>
                                    <CK
                                        data={this.state.company_vrienden_dutch_mobile}
                                        onChildChange={this.saveValueToDb.bind(this, "company_vrienden_dutch_mobile")}
                                    />
                                </div>
                                <div className="inner-celandingpage">
                                    <label>English</label>
                                    <CK
                                        data={this.state.company_vrienden_english_mobile}
                                        onChildChange={this.saveValueToDb.bind(this, "company_vrienden_english_mobile")}
                                    />
                                </div>
                            </div>
                            <div
                                className="container-editors-lp"
                                style={{ justifyContent: "center", marginTop: 30 }}
                            >
                                <div className="inner-celandingpage headinglabelcelp">
                                    <label>Company Greetings</label>
                                </div>
                            </div>
                            <div className="container-editors-lp">
                                <div className="inner-celandingpage">
                                    <label>Dutch</label>
                                    <CK
                                        data={this.state.company_greetings_dutch_mobile}
                                        onChildChange={this.saveValueToDb.bind(this, "company_greetings_dutch_mobile")}
                                    />
                                </div>
                                <div className="inner-celandingpage">
                                    <label>English</label>
                                    <CK
                                        data={this.state.company_greetings_english_mobile}
                                        onChildChange={this.saveValueToDb.bind(this, "company_greetings_english_mobile")}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    this.state.showStuffNow && (
                        <div
                            className="container-sma"
                            style={{ maxWidth: 1200, paddingBottom: 50 }}
                        >
                            <div className="tree-inner" style={{ marginTop: 30 }}>
                                <div
                                    className="container-editors-lp"
                                    style={{ justifyContent: "center", marginTop: 30 }}
                                >
                                    <div className="inner-celandingpage headinglabelcelp">
                                        <label>Member Trending</label>
                                    </div>
                                </div>
                                <div className="container-editors-lp">
                                    <div className="inner-celandingpage">
                                        <label>Dutch</label>
                                        <CK
                                            data={this.state.member_trending_dutch}
                                            onChildChange={this.saveValueToDb.bind(this, "member_trending_dutch")}
                                        />
                                    </div>
                                    <div className="inner-celandingpage">
                                        <label>English</label>
                                        <CK
                                            data={this.state.member_trending_english}
                                            onChildChange={this.saveValueToDb.bind(this, "member_trending_english")}
                                        />
                                    </div>
                                </div>
                                <div
                                    className="container-editors-lp"
                                    style={{ justifyContent: "center", marginTop: 30 }}
                                >
                                    <div className="inner-celandingpage headinglabelcelp">
                                        <label>Member Stories</label>
                                    </div>
                                </div>
                                <div className="container-editors-lp">
                                    <div className="inner-celandingpage">
                                        <label>Dutch</label>
                                        <CK
                                            data={this.state.member_stories_dutch}
                                            onChildChange={this.saveValueToDb.bind(this, "member_stories_dutch")}
                                        />
                                    </div>
                                    <div className="inner-celandingpage">
                                        <label>English</label>
                                        <CK
                                            data={this.state.member_stories_english}
                                            onChildChange={this.saveValueToDb.bind(this, "member_stories_english")}
                                        />
                                    </div>
                                </div>
                                <div
                                    className="container-editors-lp"
                                    style={{ justifyContent: "center", marginTop: 30 }}
                                >
                                    <div className="inner-celandingpage headinglabelcelp">
                                        <label>Member Vrienden</label>
                                    </div>
                                </div>
                                <div className="container-editors-lp">
                                    <div className="inner-celandingpage">
                                        <label>Dutch</label>
                                        <CK
                                            data={this.state.member_vrienden_dutch}
                                            onChildChange={this.saveValueToDb.bind(this, "member_vrienden_dutch")}
                                        />
                                    </div>
                                    <div className="inner-celandingpage">
                                        <label>English</label>
                                        <CK
                                            data={this.state.member_vrienden_english}
                                            onChildChange={this.saveValueToDb.bind(this, "member_vrienden_english")}
                                        />
                                    </div>
                                </div>
                                <div
                                    className="container-editors-lp"
                                    style={{ justifyContent: "center", marginTop: 30 }}
                                >
                                    <div className="inner-celandingpage headinglabelcelp">
                                        <label>Member Charity</label>
                                    </div>
                                </div>
                                <div className="container-editors-lp">
                                    <div className="inner-celandingpage">
                                        <label>Dutch</label>
                                        <CK
                                            data={this.state.member_charity_dutch}
                                            onChildChange={this.saveValueToDb.bind(this, "member_charity_dutch")}
                                        />
                                    </div>
                                    <div className="inner-celandingpage">
                                        <label>English</label>
                                        <CK
                                            data={this.state.member_charity_english}
                                            onChildChange={this.saveValueToDb.bind(this, "member_charity_english")}
                                        />
                                    </div>
                                </div>


                                <div
                                    className="container-editors-lp"
                                    style={{ justifyContent: "center", marginTop: 30 }}
                                >
                                    <div className="inner-celandingpage headinglabelcelp">
                                        <label>Company Home</label>
                                    </div>
                                </div>
                                <div className="container-editors-lp">
                                    <div className="inner-celandingpage">
                                        <label>Dutch</label>
                                        <CK
                                            data={this.state.company_home_dutch}
                                            onChildChange={this.saveValueToDb.bind(this, "company_home_dutch")}
                                        />
                                    </div>
                                    <div className="inner-celandingpage">
                                        <label>English</label>
                                        <CK
                                            data={this.state.company_home_english}
                                            onChildChange={this.saveValueToDb.bind(this, "company_home_english")}
                                        />
                                    </div>
                                </div>
                                <div
                                    className="container-editors-lp"
                                    style={{ justifyContent: "center", marginTop: 30 }}
                                >
                                    <div className="inner-celandingpage headinglabelcelp">
                                        <label>Company Events</label>
                                    </div>
                                </div>
                                <div className="container-editors-lp">
                                    <div className="inner-celandingpage">
                                        <label>Dutch</label>
                                        <CK
                                            data={this.state.company_events_dutch}
                                            onChildChange={this.saveValueToDb.bind(this, "company_events_dutch")}
                                        />
                                    </div>
                                    <div className="inner-celandingpage">
                                        <label>English</label>
                                        <CK
                                            data={this.state.company_events_english}
                                            onChildChange={this.saveValueToDb.bind(this, "company_events_english")}
                                        />
                                    </div>
                                </div>
                                <div
                                    className="container-editors-lp"
                                    style={{ justifyContent: "center", marginTop: 30 }}
                                >
                                    <div className="inner-celandingpage headinglabelcelp">
                                        <label>Company Products</label>
                                    </div>
                                </div>
                                <div className="container-editors-lp">
                                    <div className="inner-celandingpage">
                                        <label>Dutch</label>
                                        <CK
                                            data={this.state.company_products_dutch}
                                            onChildChange={this.saveValueToDb.bind(this, "company_products_dutch")}
                                        />
                                    </div>
                                    <div className="inner-celandingpage">
                                        <label>English</label>
                                        <CK
                                            data={this.state.company_products_english}
                                            onChildChange={this.saveValueToDb.bind(this, "company_products_english")}
                                        />
                                    </div>
                                </div>
                                <div
                                    className="container-editors-lp"
                                    style={{ justifyContent: "center", marginTop: 30 }}
                                >
                                    <div className="inner-celandingpage headinglabelcelp">
                                        <label>Company Stories</label>
                                    </div>
                                </div>
                                <div className="container-editors-lp">
                                    <div className="inner-celandingpage">
                                        <label>Dutch</label>
                                        <CK
                                            data={this.state.company_stories_dutch}
                                            onChildChange={this.saveValueToDb.bind(this, "company_stories_dutch")}
                                        />
                                    </div>
                                    <div className="inner-celandingpage">
                                        <label>English</label>
                                        <CK
                                            data={this.state.company_stories_english}
                                            onChildChange={this.saveValueToDb.bind(this, "company_stories_english")}
                                        />
                                    </div>
                                </div>
                                <div
                                    className="container-editors-lp"
                                    style={{ justifyContent: "center", marginTop: 30 }}
                                >
                                    <div className="inner-celandingpage headinglabelcelp">
                                        <label>Company Impressie</label>
                                    </div>
                                </div>
                                <div className="container-editors-lp">
                                    <div className="inner-celandingpage">
                                        <label>Dutch</label>
                                        <CK
                                            data={this.state.company_impressie_dutch}
                                            onChildChange={this.saveValueToDb.bind(this, "company_impressie_dutch")}
                                        />
                                    </div>
                                    <div className="inner-celandingpage">
                                        <label>English</label>
                                        <CK
                                            data={this.state.company_impressie_english}
                                            onChildChange={this.saveValueToDb.bind(this, "company_impressie_english")}
                                        />
                                    </div>
                                </div>
                                <div
                                    className="container-editors-lp"
                                    style={{ justifyContent: "center", marginTop: 30 }}
                                >
                                    <div className="inner-celandingpage headinglabelcelp">
                                        <label>Company Charity</label>
                                    </div>
                                </div>
                                <div className="container-editors-lp">
                                    <div className="inner-celandingpage">
                                        <label>Dutch</label>
                                        <CK
                                            data={this.state.company_charity_dutch}
                                            onChildChange={this.saveValueToDb.bind(this, "company_charity_dutch")}
                                        />
                                    </div>
                                    <div className="inner-celandingpage">
                                        <label>English</label>
                                        <CK
                                            data={this.state.company_charity_english}
                                            onChildChange={this.saveValueToDb.bind(this, "company_charity_english")}
                                        />
                                    </div>
                                </div>
                                <div
                                    className="container-editors-lp"
                                    style={{ justifyContent: "center", marginTop: 30 }}
                                >
                                    <div className="inner-celandingpage headinglabelcelp">
                                        <label>Company Vrienden</label>
                                    </div>
                                </div>
                                <div className="container-editors-lp">
                                    <div className="inner-celandingpage">
                                        <label>Dutch</label>
                                        <CK
                                            data={this.state.company_vrienden_dutch}
                                            onChildChange={this.saveValueToDb.bind(this, "company_vrienden_dutch")}
                                        />
                                    </div>
                                    <div className="inner-celandingpage">
                                        <label>English</label>
                                        <CK
                                            data={this.state.company_vrienden_english}
                                            onChildChange={this.saveValueToDb.bind(this, "company_vrienden_english")}
                                        />
                                    </div>
                                </div>
                                <div
                                    className="container-editors-lp"
                                    style={{ justifyContent: "center", marginTop: 30 }}
                                >
                                    <div className="inner-celandingpage headinglabelcelp">
                                        <label>Company Greetings</label>
                                    </div>
                                </div>
                                <div className="container-editors-lp">
                                    <div className="inner-celandingpage">
                                        <label>Dutch</label>
                                        <CK
                                            data={this.state.company_greetings_dutch}
                                            onChildChange={this.saveValueToDb.bind(this, "company_greetings_dutch")}
                                        />
                                    </div>
                                    <div className="inner-celandingpage">
                                        <label>English</label>
                                        <CK
                                            data={this.state.company_greetings_english}
                                            onChildChange={this.saveValueToDb.bind(this, "company_greetings_english")}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        global_values: state.globalReducers.global_values,
        user: state.authReducers.user,
        admin_boolean: state.globalReducers.admin_boolean,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateGlobalStuff: (global_values) =>
            dispatch(updateGlobalStuff(global_values)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WizardSettings);
