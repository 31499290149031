import React, { Component } from 'react'
import styles from "./index.module.css"
import { connect } from 'react-redux';

export class ProductTimer extends Component {


    constructor() {
        super()
        this.state = {
            intervalTimer: "",
            availabilityTime: "",
            productTimerEndOn: "",
        }
    }

    componentDidMount() {
        if (this.props.global_values && this.props.user) {
            let filtered = this.props.user.filter((t) => {
                return t.user_id === this.props.creatorId
            })
            if (filtered.length > 0 && this.props.global_values.intervalTimer) {
                let dateCreated = new Date(this.props.timerStartFrom)
                let splitted = this.props.global_values.intervalTimer.split(" ")
                if (splitted[1].includes("second")) {
                    dateCreated.setSeconds(dateCreated.getSeconds() + Number(splitted[0]))
                } else if (splitted[1].includes("minute")) {
                    dateCreated.setMinutes(dateCreated.getMinutes() + Number(splitted[0]))
                } else {
                    dateCreated.setHours(dateCreated.getHours() + Number(splitted[0]))
                }

                if (this.props.global_values.productsTimer) {
                    let innerDate = new Date(dateCreated.getTime())
                    let splittedInner = this.props.global_values.productsTimer.split(" ")
                    if (splittedInner[1].includes("minute")) {
                        innerDate.setMinutes(innerDate.getMinutes() + Number(splittedInner[0]))
                    } else {
                        innerDate.setHours(innerDate.getHours() + Number(splittedInner[0]))
                    }
                    this.setState({ productTimerEndOn: innerDate.getTime() })
                }

                this.setState({ intervalTimer: this.props.global_values.intervalTimer, availabilityTime: dateCreated.getTime() })
            }
        }

        this.interval = setInterval(
            () => this.setState({ timeCurrent: Date.now() }),
            1000
        );
    }

    getMonthName(i) {
        i = Number(i);
        if (i === 1) {
            return "januari";
        } else if (i === 2) {
            return "februari";
        } else if (i === 3) {
            return "maart";
        } else if (i === 4) {
            return "april";
        } else if (i === 5) {
            return "Mei";
        } else if (i === 6) {
            return "juni";
        } else if (i === 7) {
            return "juli";
        } else if (i === 8) {
            return "augustus";
        } else if (i === 9) {
            return "september";
        } else if (i === 10) {
            return "oktober";
        } else if (i === 11) {
            return "november";
        } else if (i === 12) {
            return "december";
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.user !== prevProps.user || this.props.global_values !== prevProps.global_values || this.props.timerStartFrom !== prevProps.timerStartFrom) {
            let filtered = this.props.user.filter((t) => {
                return t.user_id === this.props.creatorId
            })
            if (filtered.length > 0 && this.props.global_values.intervalTimer) {
                let dateCreated = new Date(this.props.timerStartFrom)
                let splitted = this.props.global_values.intervalTimer.split(" ")
                // if (splitted[1].includes("minute")) {
                //     dateCreated.setMinutes(dateCreated.getMinutes() + Number(splitted[0]))
                // } else {
                //     dateCreated.setHours(dateCreated.getHours() + Number(splitted[0]))
                // }
                if (splitted[1].includes("second")) {
                    dateCreated.setSeconds(dateCreated.getSeconds() + Number(splitted[0]))
                } else if (splitted[1].includes("minute")) {
                    dateCreated.setMinutes(dateCreated.getMinutes() + Number(splitted[0]))
                } else {
                    dateCreated.setHours(dateCreated.getHours() + Number(splitted[0]))
                }

                if (filtered[0].productsTimer) {
                    let innerDate = new Date(dateCreated.getTime())
                    let splittedInner = filtered[0].productsTimer.split(" ")
                    if (splittedInner[1].includes("minute")) {
                        innerDate.setMinutes(innerDate.getMinutes() + Number(splittedInner[0]))
                    } else {
                        innerDate.setHours(innerDate.getHours() + Number(splittedInner[0]))
                    }
                    this.setState({ productTimerEndOn: innerDate.getTime() })
                }

                this.setState({ intervalTimer: this.props.global_values.intervalTimer, availabilityTime: dateCreated.getTime() })
            }
        }
    }

    calculateTimeStamp() {
        if (this.state.availabilityTime) {
            var date = new Date(this.state.availabilityTime);

            let dateFuture = date.getTime();

            let dateNow = new Date().getTime();

            let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

            let days = Math.floor(diffInMilliSeconds / 86400);
            let daysNumber = Math.floor(diffInMilliSeconds / 86400);
            diffInMilliSeconds -= days * 86400;

            let hours = Math.floor(diffInMilliSeconds / 3600) % 24;
            diffInMilliSeconds -= hours * 3600;

            let minutes = Math.floor(diffInMilliSeconds / 60) % 60;
            diffInMilliSeconds -= minutes * 60;

            let seconds = diffInMilliSeconds % 60;

            let difference = "";
            if (days > 0) {
                difference += days === 1 ? `${days}:` : `${days}:`;
            }

            if (days < 10) {
                days = `0${days}`;
            } else {
                days = `${days}`;
            }

            if (hours < 10) {
                hours = `0${hours}`;
            } else {
                hours = `${hours}`;
            }

            if (minutes < 10) {
                minutes = `0${minutes}`;
            } else {
                minutes = `${minutes}`;
            }

            if (seconds < 9) {
                seconds = `0${Math.ceil(seconds)}`;
            } else {
                seconds = `${Math.ceil(seconds)}`;
            }

            if (dateFuture > dateNow) {
                return (
                    <>
                        {/* <p className={styles.descProductMain} style={{ textTransform: "uppercase" }}>{new Date(this.state.availabilityTime).getDate()} {this.getMonthName(new Date(this.state.availabilityTime).getMonth() + 1)} | {new Date(this.state.availabilityTime).getHours()}:{new Date(this.state.availabilityTime).getMinutes()} | {new Date(this.state.availabilityTime).getFullYear()}</p> */}
                        <div className='big-container-timer'>
                            {Number(days) > 0 && (<div className='inner-bigconttim'>
                                <p>{days}</p>
                                <p>days</p>
                            </div>)}
                            {Number(hours) > 0 && (<div className='inner-bigconttim'>
                                <p>{hours}</p>
                                <p>hours</p>
                            </div>)}
                            {Number(minutes) > 0 && (<div className='inner-bigconttim'>
                                <p>{minutes}</p>
                                <p>min</p>
                            </div>)}
                            {Number(seconds) > 0 && (<div className='inner-bigconttim'>
                                <p>{seconds}</p>
                                <p>sec</p>
                            </div>)}
                        </div>
                    </>
                );
            } else {
                if (this.props.closeScreen) {
                    this.props.closeScreen(this.state.productTimerEndOn, dateFuture)
                }
                clearInterval(this.interval);
                return <></>;
            }
        } else {
            return <></>;
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval)
    }

    render() {
        return (
            this.calculateTimeStamp()
            // <>
            //     <p className={styles.descProductMain} style={{ textTransform: "uppercase" }}>{new Date(this.state.availabilityTime).getDate()} {this.getMonthName(new Date(this.state.availabilityTime).getMonth() + 1)} | {new Date(this.state.availabilityTime).getHours()}:{new Date(this.state.availabilityTime).getMinutes()} | {new Date(this.state.availabilityTime).getFullYear()}</p>
            //     <div className='big-container-timer'>
            //         <div className='inner-bigconttim'>
            //             <p>47</p>
            //             <p>min</p>
            //         </div>
            //         <div className='inner-bigconttim'>
            //             <p>22</p>
            //             <p>sec</p>
            //         </div>
            //     </div>
            // </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducers.user,
        global_values: state.globalReducers.global_values,
    };
};



export default connect(
    mapStateToProps,
)(ProductTimer);