import React, { Component } from "react";
import firebase from "../Config/Firebase";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CloseIcon from "@material-ui/icons/Close";
import Switch from "@material-ui/core/Switch";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import swal from "sweetalert";
import { connect } from "react-redux";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  updateSignUpFieldsFilled,
  updateGuestUserCart,
  updateGuestUserCartTime,
  updateGuestUserCartExtendOpportunity,
  updateBhodiAbouts,
  updateBhodiFaqs,
  updateBhodiProducts,
} from "../Redux/actions/authActions";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import PublishIcon from "@material-ui/icons/Publish";
import { BsCheck2, BsTag } from "react-icons/bs";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ChevronLeft, FilterList } from "@material-ui/icons";
import { RiGitRepositoryPrivateLine, RiUserSettingsLine } from "react-icons/ri";
import RightMenuOrders from "./RightMenuOrders";
import orderspicpng from "../assets/ordericontopnavbars.png";
import RightMenuIncomingOrders from "./RightMenuIncomingOrders";
import CartNonUser from "./CartNonUser";
import hwb from "../assets/heartwhiteborder.png";
import hbb from "../assets/heartblackborder.png";
import { MdOutlinePrivacyTip } from "react-icons/md";
import { BiChevronRight } from "react-icons/bi";
import MySubcribedExclusives from "./MySubcribedExclusives";
import NumberFormatter from "./NumberFormatter";
import { TbFolderStar, TbPigMoney } from "react-icons/tb";
import { FaQ } from "react-icons/fa6";
import { TiInfo } from "react-icons/ti";
import { checkForSubscribedExclusives } from "../Functions/checkForSubscribedExclusives";
import menupicpng from "../assets/menuicontopnavbar.png";
import searchpicpng from "../assets/searchiconglobalevent.png";
import { HiOutlineClipboardList } from "react-icons/hi";
import NavbarMenuMobileFooterStuff from "./NavbarMenuMobileFooterStuff";
import topcompanylandingpage from "../assets/toplandingpagecompany.png"
import SearchIcon from "@material-ui/icons/Search";
import NavGlobalVoucherEventsCity from "./NavGlobalVoucherEventsCity";
import FilterIconGlobalNav from "./FilterIconGlobalNav";
import { history } from "../Config/Routes";
import NavGlobalVoucherCity from "./NavGlobalVoucherCity";
import FilterIconGlobalNavVoucher from "./FilterIconGlobalNavVoucher";
import { followUnfollowCompany } from "../Functions/friendFunctions";
import { mergeAdminEvent } from "../Functions/globalFunctions";
import BarberAdministrationStuff from "./BarberAdministrationStuff";
import VendorAdministrationStuff from "./VendorAdministrationStuff";

const axios = require("axios");

const bcrypt = require("bcryptjs");
const saltRounds = 10;

export class NavbarMainFullWidthCompany extends Component {
  constructor() {
    super();
    this.state = {
      active: false,
      search: false,
      searchText: "",
      rightModalSettings: false,
      arrForSearch: [],
      checkedPrivate: false,
      checkedHideDonation: false,
      checkedHideFriendsInfo: false,
      profileActive: true,
      redirect_guest_to_link_via_event: false,
      hide_contact_info_on_order: false,
      checkedHideProfileImage: false,
      checkedHideProfileInfo: false,
      showPostsActive: true,
      showItemsDiv: false,
      showItems: false,
      showLists: true,
      showFriends: false,
      showProductsOwn: false,
      showFriendstab: false,
      showProductsBookmarked: false,
      showBookmarkTab: false,
      showProductsOthertab: false,
      memberProducts: [],
      paymentEmail: "",
      given_name: "",
      surname: "",
      date_of_birth: "",
      nationality_country_code: "",
      phone_number_country_code: "",
      phone_number_national_number: "",
      business_address_line1: "",
      business_address_state: "",
      business_address_city: "",
      business_address_country_code: "",
      business_address_postal_code: "",
      bussiness_legal_name: "",
      business_description: "",
      website_urls: "",
      business_established: "",
      currentUserMember: "",
      userInfoRight: false,
      typeOfOrder: "incoming",
      showCartOrder: true,
      impressiePhotosWork: [],
      savtBtnText: "SAVE",
      deleteBtnText: "SAVE",
      listsOpacity: 1,
      listsPointers: "all",
      isDesktop: true,
      isMenuOpen: false,
      isScrolled: false,
      menuItemsModals: true,
      deliverTextOne: "",
      deliverTextTwo: "",
      productsBackColor: "#F5F5F5",
      lengthOfOtherProducts: [],
      lengthOfOtherVouchers: [],
      lengthOfOtherCampaigns: [],
      voucherSettings: false,
      useOfVoucher: "",
      useOfVoucherCharity: "",
      useOfVoucherGiveaway: "",
      useOfVoucherDoorlopende: "",
      useOfVoucherLocalEvent: "",
      useOfVoucherKortingsVoucher: "",
      useOfVoucherWebShop: "",
      useOfVoucherWebShop: "",
      useOfVoucherLocalCampaign: "",
      voucherExceptions: "",
      termsText: "",
      hideMenuShop: false,
      hideMenuVouchers: false,
      arrCurrentProducts: [],
      lengthOfCurrentVouhers: [],
      hideMenuContent: false,
      lengthOfCurrentContents: [],
      lengthOfOtherContents: [],
      showContentTab: false,
      gebruikGiveAwayDown: false,
      gebruikCharityDown: false,
      gebruikVanDown: false,
      gebruikLastMinuteDown: false,
      gebruikWebShopDown: false,
      gebruikKortingsDown: false,
      voorwaardenDown: false,
      hideFriendsInfo: false,
      bhodiFaqs: [],
      arrDropdowns: [],
      bhodiAbouts: [],
      arrDropdownsAbouts: [],
      currentUserId: "",
      orderidToUpload: 0,
      currentUserOfPopups: "",
      redeem_code: "",
      guestUserCart: [],
      showDelete: false,
      password: "",
      showPassword: false,
      confirmPassword: "",
      showconfirmPassword: false,
      currentPassword: "",
      showcurrentPassword: false,
      copiedTextDone: false,
      liveEventCompany: [],
      dropdownKeys: [],
      popupToClarify: false,
      fieldsAlreadySelected: [],
      csvFileId: "",
      csvFileBedrijfsnaam: "",
      csvFileAdres: "",
      csvFileWoonplaats: "",
      csvFileTelefoon: "",
      csvFileEmail: "",
      csvFilePostCode: "",
      completeCSVArray: [],
      completeCSVfromDB: [],
      commentsHere: [],
      commentsReplies: [],
      currentProductDetailed: "",
      commentInformationToShow: "",
      allAlbumsCurrentShared: [],
      showProductsCurrently: [],
      notSeenMessages: [],
      campaignsToShowDropEmail: [],
      allCSVsFull: [],
      joinedUsersForMyEvent: [],
      showJoinedUsers: false,
      currentUserHere: "",
      isCanceled: false,
      sendingEmail: false,
      clientPageRight: false,
      dropdownKeysClient: [],
      popupToClarifyClient: false,
      fieldsAlreadySelectedClient: [],
      csvFileBedrijfsnaamClient: "",
      csvFileAdresClient: "",
      csvFileWoonplaatsClient: "",
      csvFileTelefoonClient: "",
      csvFileEmailClient: "",
      csvFilePostCodeClient: "",
      completeCSVArrayClient: [],
      completeCSVfromDBClient: [],
      allCSVsFullClient: [],
      filteredClientList: [],
      filteredListMain: [],
      showImportPopup: true,
      charityCount: 0,
      currentUserProducts: [],
      selectedDateAdministratie: "",
      selectedYearAdministratie: "",
      allAdministrationData: [],
      allAdministrationDataFilter: [],
      allAdministrationYears: [],
      maxLimitAlleReacties: 15,
      passwordForDel: "",
      allIdsJoinedChannel: [],
      currentWeekEvents: [],
      comingWeekEvents: [],
      allCategories: [],
      availableMainCategories: [],

      expireTypeVoucher: "",
      expireDateVoucher: "",

      maxProductsToShow: 4,
      intervalTimer: "",

      allPayoutsInfo: [],
      syncAdminCode: true,
      webshopCodeSaloon: "",
    };
  }

  componentWillMount() {

    let userid = localStorage.getItem("userid");
    this.setState({ userid });

    if (this.props.bhodi_faqs) {
      let a = this.props.bhodi_faqs;
      if (a) {
        let arrSec = a;
        let arrDropdowns = [];
        arrSec.map(() => {
          let obj = {
            opened: false,
          };
          arrDropdowns.push(obj);
        });
        this.setState({ bhodiFaqs: arrSec, arrDropdowns });
      } else {
        this.setState({ bhodiFaqs: [], arrDropdowns: [] });
      }
    }
    if (this.props.bhodi_abouts) {
      let a = this.props.bhodi_abouts;
      if (a) {
        let arrSec = a;
        let arrDropdownsAbouts = [];
        arrSec.map(() => {
          let obj = {
            opened: false,
          };
          arrDropdownsAbouts.push(obj);
        });
        this.setState({ bhodiAbouts: arrSec, arrDropdownsAbouts });
      } else {
        this.setState({ bhodiAbouts: [], arrDropdownsAbouts: [] });
      }
    }

    firebase
      .database()
      .ref("bhodi_faqs")
      .on("value", (data) => {
        let a = data.val();
        let arrSec = [];
        if (a) {
          arrSec = a;
          let arrDropdowns = [];
          arrSec.map(() => {
            let obj = {
              opened: false,
            };
            arrDropdowns.push(obj);
          });
          this.setState({ bhodiFaqs: arrSec, arrDropdowns });
        } else {
          this.setState({ bhodiFaqs: [], arrDropdowns: [] });
        }
        this.props.updateBhodiFaqs(arrSec)
      });

    firebase
      .database()
      .ref("bhodi_abouts")
      .on("value", (data) => {
        let a = data.val();
        let arrSec = [];
        if (a) {
          arrSec = a;
          let arrDropdownsAbouts = [];
          arrSec.map(() => {
            let obj = {
              opened: false,
            };
            arrDropdownsAbouts.push(obj);
          });
          this.setState({ bhodiAbouts: arrSec, arrDropdownsAbouts });
        } else {
          this.setState({ bhodiAbouts: [], arrDropdownsAbouts: [] });
        }
        this.props.updateBhodiAbouts(arrSec)
      });

    firebase.database().ref("payouts_info/" + userid).on("value", (data) => {
      let a = data.val()
      if (a) {
        let objected = Object.entries(a)

        let allAdministrationData = []
        let allYears = []

        objected.map((t) => {
          let objectedInner = Object.entries(t[1])
          objectedInner.map((g) => {
            let newObj = Object.assign(g[1], {})
            if (newObj.payment_status === "paid") {
              newObj.amount = newObj.amount_charged
              newObj.added_at = new Date(Date.parse(newObj.created_at)).getTime()

              allYears.push(new Date(Number(newObj.created_at)).getFullYear())

              allAdministrationData.push(newObj)
            }

          })
        })

        let filteredAllYears = allYears.filter(
          (v, i, a) => a.findIndex((t) => t === v) === i
        );
        this.setState({ allAdministrationData, allAdministrationDataFilter: allAdministrationData, allAdministrationYears: filteredAllYears })

        // this.setState({ allPayoutsInfo: allPayments })
      }
    })

    if (this.props.user) {
      if (this.props.user.length > 0) {
        let filter = this.props.user.filter((u) => {
          return u.user_name_id === this.props.member_id;
        });
        let currentUserFilteredUpdated = this.props.user.filter((e) => {
          return e.user_id === userid;
        });
        if (currentUserFilteredUpdated.length > 0) {
          this.setState({
            currentUserHere: currentUserFilteredUpdated[0],
            isCanceled: !(
              currentUserFilteredUpdated[0].subscription &&
              currentUserFilteredUpdated[0].subscription.status === "active"
            ),
          });
        }
        // my shared campaigns
        if (this.props.global_campaigns && filter[0].user_id === userid) {
          let myCampaigns = this.props.global_campaigns.filter((e) => {
            return e[1].takeFromVendor && e[1].vendor_id === filter[0].user_id;
          });
          let allJoined = [];
          myCampaigns.map((b) => {
            let usersFiltered = this.props.user.filter((j) => {
              return b[1].created_by === j.user_id;
            });
            if (usersFiltered.length > 0) {
              allJoined.push(usersFiltered[0]);
            }
          });
          this.setState({ joinedUsersForMyEvent: allJoined });
        }

        // let filteredImpressie = [];
        // if (this.props.all_impressies) {
        //   if (this.props.all_impressies.length > 0)
        //     filteredImpressie = this.props.all_impressies.filter((u) => {
        //       return u[0] === filter[0].user_id;
        //     });
        // }
        // this.setState({ impressiePhotosWork: filteredImpressie });
        let currentData = filter[0];
        // album check
        // album
        if (filter.length > 0) {
          if (currentData) {
            let a = currentData;
            this.setState({
              currentUserMember: a,
              create_subscription: a.create_subscription,
              userid: userid,
              url: a.user_name_id,
              firstName: a.first_name,
              lastName: a.last_name,
              city: a.city,
              zipCode: a.zipCode,
              street: a.street,
              houseNumber: a.houseNumber,
              country: a.country,
              phoneNumber: a.phoneNumber,
              bankName: a.bankName,
              bankAccountNumber: a.bankAccountNumber,
              email: a.email,
              companyName: a.company_name,
              kvkCompany: a.kvkCompany,
              company_website: a.company_website,
              companyEmail: a.email,
              profileImageUrl: a.profile_image,
              description: a.user_description,
              currentUserId: userid,
              paymentEmail: a.paymentEmail,
              given_name: a.given_name,
              surname: a.surname,
              date_of_birth: a.date_of_birth,
              nationality_country_code: a.nationality_country_code,
              phone_number_country_code: a.phone_number_country_code,
              phone_number_national_number: a.phone_number_national_number,
              business_address_line1: a.business_address_line1,
              business_address_state: a.business_address_state,
              business_address_city: a.business_address_city,
              business_address_country_code: a.business_address_country_code,
              business_address_postal_code: a.business_address_postal_code,
              bussiness_legal_name: a.bussiness_legal_name,
              business_description: a.business_description,
              website_urls: a.website_urls,
              business_established: a.business_established,
              deliverTextOne: a.delivery_text_one,
              deliverTextTwo: a.delivery_text_two,
              productsBackColor: a.product_background_color,
              hide_contact_info_on_order: a.hide_contact_info_on_order || false,
            });
            if (typeof a.hideFriendsInfo === "boolean") {
              this.setState({
                checkedHideDonation: a.hideDonation,
                checkedHideFriendsInfo: a.hideFriendsInfo,
                checkedHideProfileImage: a.hideProfileImage,
                checkedHideProfileInfo: a.hideProfileInfo,
                checkedHideGlobalProduct: a.hideGlobalProducts,
              });
            }
            if (a.profileActive) {
              this.setState({
                profileActive: a.profileActive
              })
            } else {
              this.setState({ profileActive: false })
            }
            if (a.redirect_guest_to_link_via_event) {
              this.setState({
                redirect_guest_to_link_via_event: a.redirect_guest_to_link_via_event
              })
            } else {
              this.setState({ redirect_guest_to_link_via_event: false })
            }
            if (a.following) {
              this.setState({ showFriendstab: true });
            } else {
              this.setState({ showFriendstab: false });
            }
            if (a.hideFriendsInfo) {
              this.setState({ hideFriendsInfo: a.hideFriendsInfo });
            } else {
              this.setState({ hideFriendsInfo: false });
            }
            if (a.useOfVoucherLocalEvent) {
              this.setState({
                useOfVoucherLocalEvent: a.useOfVoucherLocalEvent,
              });
            }
            if (a.productsInfo) {
              this.setState({
                maxProductsToShow: a.productsInfo.maxProductsToShow ? a.productsInfo.maxProductsToShow : 4,
                intervalTimer: a.productsInfo.intervalTimer ? a.productsInfo.intervalTimer : "",
              });
            }
            if (a.useOfVoucherKortingsVoucher) {
              this.setState({
                useOfVoucherKortingsVoucher: a.useOfVoucherKortingsVoucher,
              });
            }
            if (a.useOfVoucherWebShop) {
              this.setState({
                useOfVoucherWebShop: a.useOfVoucherWebShop,
              });
            }
            if (a.voucherExceptions) {
              this.setState({ voucherExceptions: a.voucherExceptions });
            }
            if (a.termsText) {
              this.setState({ termsText: a.termsText });
            }
            if (a.expireTypeVoucher) {
              this.setState({ expireTypeVoucher: a.expireTypeVoucher });
            }
            if (a.expireDateVoucher) {
              this.setState({ expireDateVoucher: a.expireDateVoucher });
            }
            if (a.barber_new) {
              this.setState({ syncAdminCode: a.syncAdminCode || false, webshopCodeSaloon: a.webshopCodeSaloon || "" })
            }
            if (!a.profile_image) {
              a.profile_image =
                "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9";
            }
            if (a.subscription) {
              if (a.subscription.status === "active") {
                this.setState({ showDelete: false });
              } else {
                this.setState({ showDelete: true });
              }
            } else {
              if (checkForSubscribedExclusives(a)) {
                this.setState({ showDelete: false })
              } else {
                this.setState({ showDelete: true });
              }
            }
          }
        }
      }
    }
  }

  componentWillReceiveProps(e) {
    if (e.fillUpAddress) {
      let element = document.getElementById("footermain");
      if (element) {
        element.style.display = "none";
      }
      this.setState({ rightModalSettings: true });
      if (window.innerWidth > 1009) {
        this.setState({ userInfoRight: true, menuItemsModals: true, openedDirectlyInfos: true });
      }
    }
  }

  componentDidMount() {
    const loaderId = document.getElementById("main-page-loader-id");
    loaderId.style.display = "none";

    // check for connect paypal
    let newUrl = new URL(window.location.href);
    let trackingId = newUrl.searchParams.get("merchantId");
    if (trackingId) {
      var config = {
        method: "get",
        url: "https://us-central1-bhodi-production-da765.cloudfunctions.net/getAuthDetails",
        headers: {},
      };

      axios(config)
        .then((response) => {
          if (response.status === 200) {
            let accessToken = response.data.access_token;

            var configAnother = {
              method: "get",
              url: `https://us-central1-bhodi-production-da765.cloudfunctions.net/getMerchantId?trackingId=${trackingId}`,
              headers: {
                authToken: accessToken,
                "Content-Type": "application/json",
              },
            };

            axios(configAnother)
              .then((responseNew) => {
                let obj = {
                  merchant_id: responseNew.data.merchant_id,
                  tracking_id: responseNew.data.tracking_id,
                };
                firebase
                  .database()
                  .ref("users/" + userid + "/paypalInfo")
                  .set(obj)
                  .then(() => {
                    swal({
                      title: "Cool.",
                      text: "Paypal connected successfully",
                      icon: "success",
                    }).then(() => {
                      setTimeout(() => {
                        window.location.href = `/company/${this.props.member_id}`;
                      }, 1000);
                    })
                  });
              })
              .catch(function (error) {
                console.log(error);
              });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    // check for mollie
    if (window.location.search) {
      let urlMain = new URLSearchParams(window.location.search)
      if (urlMain.get("code")) {
        let obj = {
          code: urlMain.get("code"),
        }
        if (urlMain.get("state")) {
          obj.state = urlMain.get("state")
        }
        axios.post("https://us-central1-bhodi-production-da765.cloudfunctions.net/generateMollieAccessToken", { code: obj.code })
          .then((response) => {
            if (response.data.statusCode === 201) {
              obj.auth_token = response.data.access_token

              axios({
                url: "https://us-central1-bhodi-production-da765.cloudfunctions.net/getOrganizationDetails",
                method: "get",
                headers: {
                  authToken: response.data.access_token,
                }
              }).then((resp) => {
                if (resp.data.statusCode === 201) {
                  obj.org_id = resp.data.id

                  firebase.database().ref(`users/${userid}/mollie_credentials`).set(obj).then(() => {
                    swal({
                      title: "Cool.",
                      text: "Mollie connected successfully",
                      icon: "success",
                    }).then(() => {
                      setTimeout(() => {
                        window.location.href = `/company/${this.props.member_id}`;
                      }, 1000);
                    })
                  })
                }
              }).catch((err) => {
                swal({
                  title: "Sorry.",
                  text: "Error occured in connecting mollie",
                  icon: "error",
                })
                console.log(err, '/err')
              })
            }
          }).catch((err) => {
            swal({
              title: "Sorry.",
              text: "Error occured in connecting mollie",
              icon: "error",
            })
            console.log(err, '/err')
          })
      }
    }

    let userid = localStorage.getItem("userid");

    firebase
      .database()
      .ref("charity_videos/" + userid)
      .on("value", (data) => {
        let a = data.val();
        if (a) {
          let objected = Object.entries(a);
          let filtered = objected.filter((e) => {
            return !e[1].seen
          })
          this.setState({ charityCount: filtered.length });
        }
      });

    if (this.props.message) {
      let allMessages = [];
      this.props.message.map((g) => {
        let objected = Object.entries(g[1]);
        objected.map((y) => {
          allMessages.push(y[1]);
        });
      });
      let filtered = allMessages.filter((g) => {
        return !g.seen && g.type === "reciever";
      });
      this.setState({ notSeenMessages: filtered });
    } else {
      this.setState({ notSeenMessages: [] });
    }

    this.setState({ currentUserId: userid });

    if (window.innerWidth > 1009) {
      this.setState({
        listsOpacity: 1,
        listsPointers: "all",
        isDesktop: true,
      });
    } else {
      this.setState({
        listsOpacity: 1,
        listsPointers: "all",
        isDesktop: false,
      });
      window.onscroll = () => {
        if (window.scrollY === 0) {
          this.setState({ isScrolled: false });
        } else {
          this.setState({ isScrolled: true });
        }
      };
    }

    if (this.props.member_id) {
      firebase
        .database()
        .ref("users")
        .on("value", (data) => {
          let a = data.val();
          let objected = Object.entries(a);
          objected.map((y) => {
            if (y[1].user_id === userid) {
              this.setState({
                currentUserHere: y[1],
              })
            }
            if (y[1].user_name_id === this.props.member_id) {
              let a = y[1];

              this.setState({
                currentUserMember: a,
                create_subscription: a.create_subscription,
                userid: userid,
                url: a.user_name_id,
                firstName: a.first_name,
                lastName: a.last_name,
                city: a.city,
                zipCode: a.zipCode,
                street: a.street,
                houseNumber: a.houseNumber,
                country: a.country,
                phoneNumber: a.phoneNumber,
                bankName: a.bankName,
                bankAccountNumber: a.bankAccountNumber,
                email: a.email,
                companyName: a.company_name,
                kvkCompany: a.kvkCompany,
                company_website: a.company_website,
                companyEmail: a.email,
                profileImageUrl: a.profile_image,
                description: a.user_description,
                currentUserId: userid,
                paymentEmail: a.paymentEmail,
                given_name: a.given_name,
                surname: a.surname,
                date_of_birth: a.date_of_birth,
                nationality_country_code: a.nationality_country_code,
                phone_number_country_code: a.phone_number_country_code,
                phone_number_national_number: a.phone_number_national_number,
                business_address_line1: a.business_address_line1,
                business_address_state: a.business_address_state,
                business_address_city: a.business_address_city,
                business_address_country_code: a.business_address_country_code,
                business_address_postal_code: a.business_address_postal_code,
                bussiness_legal_name: a.bussiness_legal_name,
                business_description: a.business_description,
                website_urls: a.website_urls,
                business_established: a.business_established,
                deliverTextOne: a.delivery_text_one,
                deliverTextTwo: a.delivery_text_two,
                productsBackColor: a.product_background_color,
                hide_contact_info_on_order: a.hide_contact_info_on_order || false,
              });
              if (typeof a.hideFriendsInfo === "boolean") {
                this.setState({
                  checkedHideDonation: a.hideDonation,
                  checkedHideFriendsInfo: a.hideFriendsInfo,
                  checkedHideProfileImage: a.hideProfileImage,
                  checkedHideProfileInfo: a.hideProfileInfo,
                  checkedHideGlobalProduct: a.hideGlobalProducts,
                });
              }
              if (a.profileActive) {
                this.setState({
                  profileActive: a.profileActive
                })
              } else {
                this.setState({ profileActive: false })
              }
              if (a.redirect_guest_to_link_via_event) {
                this.setState({
                  redirect_guest_to_link_via_event: a.redirect_guest_to_link_via_event
                })
              } else {
                this.setState({ redirect_guest_to_link_via_event: false })
              }
              if (a.following) {
                this.setState({ showFriendstab: true });
              } else {
                this.setState({ showFriendstab: false });
              }
              if (a.hideFriendsInfo) {
                this.setState({ hideFriendsInfo: a.hideFriendsInfo });
              } else {
                this.setState({ hideFriendsInfo: false });
              }
              if (!a.profile_image) {
                a.profile_image =
                  "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9";
              }
              if (a.accountType === "global") {
                this.setState({ checkedPrivate: false });
              } else {
                this.setState({ checkedPrivate: true });
              }
              if (a.useOfVoucherLocalEvent) {
                this.setState({
                  useOfVoucherLocalEvent: a.useOfVoucherLocalEvent,
                });
              }
              if (a.productsInfo) {
                this.setState({
                  maxProductsToShow: a.productsInfo.maxProductsToShow ? a.productsInfo.maxProductsToShow : 4,
                  intervalTimer: a.productsInfo.intervalTimer ? a.productsInfo.intervalTimer : "",
                });
              }
              if (a.useOfVoucherKortingsVoucher) {
                this.setState({
                  useOfVoucherKortingsVoucher: a.useOfVoucherKortingsVoucher,
                });
              }
              if (a.useOfVoucherWebShop) {
                this.setState({
                  useOfVoucherWebShop: a.useOfVoucherWebShop,
                });
              }
              if (a.voucherExceptions) {
                this.setState({ voucherExceptions: a.voucherExceptions });
              }
              if (a.termsText) {
                this.setState({ termsText: a.termsText });
              }
              if (a.expireTypeVoucher) {
                this.setState({ expireTypeVoucher: a.expireTypeVoucher });
              }
              if (a.expireDateVoucher) {
                this.setState({ expireDateVoucher: a.expireDateVoucher });
              }
              if (a.barber_new) {
                this.setState({ syncAdminCode: a.syncAdminCode || false, webshopCodeSaloon: a.webshopCodeSaloon || "" })
              }
              // if (a.user_id === userid) {
              //     let email = a.email
              //     let password = a.pass
              //     var user = firebase.auth().currentUser;
              //     var credential = firebase.auth.EmailAuthProvider.credential(
              //         email,
              //         password
              //     );;
              //     user.reauthenticateWithCredential(credential)
              // }
              if (a.subscription) {
                if (a.subscription.status === "active") {
                  this.setState({ showDelete: false });
                } else {
                  this.setState({ showDelete: true });
                }
              } else {
                if (checkForSubscribedExclusives(a)) {
                  this.setState({ showDelete: false })
                } else {
                  this.setState({ showDelete: true });
                }
              }
            }
          });
        });

      let sessionStored = localStorage.getItem("onCurrentTabCompany");
      let route = window.location.pathname;

      if (!this.props.onGlobalPageEvents && !this.props.onGlobalPageVoucher) {
        if (route.includes("vriendenDesktop")) {
          this.setState({
            showVriendenWizard: true,
            showLists: false,
          });
        } else if (sessionStored) {
          if (sessionStored === "showItems") {
            this.setState({
              showLive: false,
              showProductsNew: false,
              showItems: true,
              doLessHeight: false,
              showLists: false,
              showFriends: false,
              showImpression: false,
              showGreetings: false,
              showVoucher: false,
              showPosts: false,
              showProductsBookmarked: false,
              showCharity: false,
              showItemsShops: false,
              showProductsFriends: false,
            });
          } else if (sessionStored === "showLists") {
            this.setState({
              showLive: false,
              showProductsNew: false,
              showLists: true,
              doLessHeight: true,
              showItems: false,
              showFriends: false,
              showImpression: false,
              showGreetings: false,
              showVoucher: false,
              showPosts: false,
              showProductsBookmarked: false,
              showCharity: false,
              showItemsShops: false,
              showProductsFriends: false,
            });
          } else if (sessionStored === "shownewtrending") {
            this.setState({
              showLive: false,
              showProductsNew: false,
              showLists: false,
              showNewTrending: true,
              doLessHeight: false,
              showItems: false,
              showFriends: false,
              showImpression: false,
              showGreetings: false,
              showVoucher: false,
              showPosts: false,
              showProductsBookmarked: false,
              showCharity: false,
              showItemsShops: false,
              showProductsFriends: false,
            });
          } else if (sessionStored === "showFriends") {
            this.setState({
              showLive: false,
              showProductsNew: false,
              showGreetings: false,
              showLists: false,
              doLessHeight: false,
              showItems: false,
              showFriends: true,
              showImpression: false,
              showVoucher: false,
              showPosts: false,
              showProductsBookmarked: false,
              showCharity: false,
              showItemsShops: false,
              showProductsFriends: false,
            });
          } else if (sessionStored === "showImpression") {
            this.setState({
              showLive: false,
              showProductsNew: false,
              showLists: false,
              doLessHeight: false,
              showItems: false,
              showFriends: false,
              showImpression: true,
              showVoucher: false,
              showPosts: false,
              showProductsBookmarked: false,
              showCharity: false,
              showItemsShops: false,
              showProductsFriends: false,
              showGreetings: false,
            });
          } else if (sessionStored === "showLive") {
            this.setState({
              showLive: true,
              showLists: false,
              doLessHeight: false,
              showItems: false,
              showFriends: false,
              showImpression: false,
              showVoucher: false,
              showPosts: false,
              showProductsBookmarked: false,
              showGreetings: false,
              showCharity: false,
              showItemsShops: false,
              showProductsFriends: false,
            });
          } else if (sessionStored === "showProductsNew") {
            this.setState({
              showLive: false,
              showProductsNew: true,
              showLists: false,
              doLessHeight: false,
              showItems: false,
              showFriends: false,
              showImpression: false,
              showGreetings: false,
              showVoucher: false,
              showPosts: false,
              showProductsBookmarked: false,
              showCharity: false,
              showItemsShops: false,
              showProductsFriends: false,
            });
          } else if (sessionStored === "showCharity") {
            this.setState({
              showLive: false,
              showProductsNew: false,
              showLists: false,
              doLessHeight: false,
              showCharity: true,
              showGreetings: false,
              showItems: false,
              showFriends: false,
              showImpression: false,
              showVoucher: false,
              showPosts: false,
              showProductsBookmarked: false,
              showItemsShops: false,
              showProductsFriends: false,
            });
          } else if (sessionStored === "showproductsfriends") {
            this.setState({
              showLive: false,
              showProductsNew: false,
              showProductsFriends: true,
              showGreetings: false,
              showLists: false,
              doLessHeight: false,
              showItems: false,
              showFriends: false,
              showImpression: false,
              showVoucher: false,
              showPosts: false,
              showProductsBookmarked: false,
              showCharity: false,
              showItemsShops: false,
              showItemsShops: false,
            });
          } else if (sessionStored === "charityConfirmation") {
            this.setState({
              showLive: false,
              showProductsNew: false,
              showProductsFriends: false,
              showLists: false,
              showGreetings: false,
              doLessHeight: false,
              showItems: false,
              showFriends: false,
              showImpression: false,
              showVoucher: false,
              showPosts: false,
              showProductsBookmarked: false,
              showCharity: false,
              showItemsShops: false,
              showItemsShops: false,
            });
          }
        }
      }

      let uid = localStorage.getItem("userid");

      let bhodi_campaigns = this.props.global_campaigns;
      if (bhodi_campaigns) {
        if (bhodi_campaigns.length > 0) {
          let arr = [];
          let allCampaignsCompleted = [];
          let allCampaignsCompletedToRender = [];
          let arrCurrentCampaigns = [];
          let objected = bhodi_campaigns;
          objected.map((e) => {
            e[1].type = "campaign";
            if (e[1].active) {
              allCampaignsCompletedToRender.push(e[1]);
              // if (e[1].desc) {
              //   e[1].desc = e[1].desc.substr(0, 200);
              // }
              allCampaignsCompleted.push(e[1]);
            }
          });
          allCampaignsCompleted.map((e) => {
            e.type = "campaign";
            if (e.created_by !== uid) {
              let filtered = this.props.user.filter((f) => {
                return f.user_id === e.created_by;
              });
              if (filtered.length > 0) {
                let f = filtered[0];
                if (f.followed_by || f.friends) {
                  let objectedFriends = [];
                  if (f.followed_by) {
                    objectedFriends = Object.entries(f.followed_by);
                  }
                  objectedFriends.map((g) => {
                    if (g[1].user_id === uid) {
                      if (e.active) {
                        if (e.desc) {
                          e.desc = e.desc.substr(0, 520);
                          e.short_desc = e.desc.substr(0, 120);
                        }
                        e.images = [e.image];
                        e.imagesObjected = [
                          { image: e.image, pushKey: e.pushKey },
                        ];

                        if (e.campaignType === "kortings_voucher") {
                          let objectedVerzilveredBy = [];
                          let num = 0;
                          if (e.verzilvered_by) {
                            objectedVerzilveredBy = Object.entries(
                              e.verzilvered_by
                            );
                            num = this.getVerzilveredQty(objectedVerzilveredBy);
                          }
                          let bewaaredArray = [];
                          if (!e.kortingsVoucherUnlimited) {
                            if (num + bewaaredArray.length < Number(e.number)) {
                              arr.push(e);
                            }
                          } else {
                            arr.push(e);
                          }
                        }
                        if (e.campaignType === "local_event") {
                          if (e.eventForGuestUser) {
                            return false;
                          }
                          if (e.takeFromAdmin) {
                            let obj = mergeAdminEvent(e, this.props.global_campaigns)
                            e = Object.assign({}, obj)
                          }
                          let arrTimingsAvailable = [];
                          if (e.timings) {
                            e.timings.map((y) => {
                              if (y.isoStartTime && y.isoEndTime) {
                                let checkedInOfCurrentBlock = [];
                                if (e.checked_in_by) {
                                  Object.entries(e.checked_in_by).map((t) => {
                                    if (t[1].event_id === y.eventId) {
                                      checkedInOfCurrentBlock.push(t[1]);
                                    }
                                  });
                                }
                                // let getEventDate = new Date(`${y.endDateEvent} ${y.endTimeEvent}`)
                                // let getEventDate = new Date(y.endDateEvent);
                                // let endTimeGood =
                                //   y.endTimeEvent.concat(":00");
                                // getEventDate.setHours(
                                //   endTimeGood.split(":")[0]
                                // );
                                // getEventDate.setMinutes(
                                //   endTimeGood.split(":")[1]
                                // );
                                let getCurrentDate = new Date();
                                if (
                                  getCurrentDate.getTime() <
                                  new Date(Date.parse(y.isoEndTime)).getTime()
                                ) {
                                  if (
                                    checkedInOfCurrentBlock.length <
                                    Number(y.personsAmountEvent)
                                  ) {
                                    arrTimingsAvailable.push(y);
                                  }
                                }
                              }
                            });
                          }
                          if (arrTimingsAvailable.length > 0) {
                            arr.push(e);
                          }
                        }
                        if (e.campaignType === "web_shop") {
                          let objectedVerzilveredBy = [];
                          let num = 0;
                          if (e.verzilvered_by) {
                            objectedVerzilveredBy = Object.entries(
                              e.verzilvered_by
                            );
                            num = this.getVerzilveredQty(
                              objectedVerzilveredBy
                            );
                          }
                          let bewaaredArray = [];
                          if (!e.voucherUnlimited) {
                            if (
                              num + bewaaredArray.length <
                              Number(e.number)
                            ) {
                              arr.push(e);
                            }
                          } else {
                            arr.push(e);
                          }
                        }
                      }
                    }
                  });
                }
              }
            }
            if (e.created_by === uid) {
              arrCurrentCampaigns.push(e);
            }
          });
          this.setState({ lengthOfOtherCampaigns: arr });
        }
      }
    }
    if (!window.location.href.includes("/charityConfirmation") && !window.location.href.includes("/exclusive-subscribing")) {
      firebase
        .database()
        .ref("users/" + userid)
        .once("value", (data) => {
          let a = data.val();
          if (a) {
            if (a.user_name_id === this.props.member_id) {
              this.props.updateSignUpFieldsFilled("");
            }
            if (a.create_subscription) {
              this.setState({ create_subscription: a.create_subscription });
            }
            setTimeout(() => {
              firebase
                .database()
                .ref("users/" + userid + "/create_subscription")
                .set(false)
            }, 1000);
          }
        });
    }
  }

  makeid(length) {
    var result = '';
    var characters = 'bhodi';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    return result;
  }

  handleForm(e) {
    e.preventDefault();
    return false;
  }

  getCharityObjectedQty(e) {
    let arrItems = e;
    if (arrItems.length > 0) {
      Array.prototype.sum = function (prop) {
        var total = 0;
        for (var i = 0, _len = this.length; i < _len; i++) {
          total += Number(this[i][1][prop]);
        }
        return total;
      };
      return arrItems.sum("used_time");
    }
  }

  getVerzilveredQty(e) {
    let arrItems = e;
    if (arrItems.length > 0) {
      Array.prototype.sum = function (prop) {
        var total = 0;
        for (var i = 0, _len = this.length; i < _len; i++) {
          total += Number(this[i][1][prop].length);
        }
        return total;
      };
      return arrItems.sum("allDates");
    }
  }

  saveUrl() {
    const { url, description } = this.state;
    let userid = localStorage.getItem("userid");
    if (description) {
      firebase
        .database()
        .ref("users/" + userid + "/user_description")
        .set(description);
    }
    if (url) {
      firebase
        .database()
        .ref("users/" + userid + "/user_name_id")
        .set(url)
        .then(() => {
          firebase
            .auth()
            .signOut()
            .then(() => {
              localStorage.setItem("userid", "");
              window.location.href = "/login";
            });
        });
    } else {
      console.log("fill some thing");
    }
    let element = document.getElementById("footermain");
    if (element) {
      element.style.display = "flex";
    }
    this.setState({ rightModalSettings: false, profileRight: false });
  }

  saveInfo() {
    this.setState({ savtBtnText: "SAVING.." });
    const {
      firstName,
      lastName,
      street,
      city,
      zipCode,
      houseNumber,
      country,
      phoneNumber,
      bankAccountNumber,
      bankName,
      profileActive,
      redirect_guest_to_link_via_event,
      hide_contact_info_on_order,
      companyEmail,
      companyName,
      kvkCompany,
      company_website,
    } = this.state;
    const { first_name, last_name } = this.state.currentUserMember;
    let userid = localStorage.getItem("userid");
    if (firstName) {
      firebase
        .database()
        .ref("users/" + userid + "/first_name")
        .set(firstName);
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/first_name")
        .set("");
    }
    if (lastName) {
      firebase
        .database()
        .ref("users/" + userid + "/last_name")
        .set(lastName);
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/last_name")
        .set("");
    }
    if (street) {
      firebase
        .database()
        .ref("users/" + userid + "/street")
        .set(street);
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/street")
        .set("");
    }
    if (city) {
      firebase
        .database()
        .ref("users/" + userid + "/city")
        .set(city);
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/city")
        .set("");
    }
    if (zipCode) {
      firebase
        .database()
        .ref("users/" + userid + "/zipCode")
        .set(zipCode);
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/zipCode")
        .set("");
    }
    if (houseNumber) {
      firebase
        .database()
        .ref("users/" + userid + "/houseNumber")
        .set(houseNumber);
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/houseNumber")
        .set("");
    }
    if (kvkCompany) {
      firebase
        .database()
        .ref("users/" + userid + "/kvkCompany")
        .set(kvkCompany);
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/kvkCompany")
        .set("");
    }
    if (company_website) {
      firebase
        .database()
        .ref("users/" + userid + "/company_website")
        .set(company_website);
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/company_website")
        .set("");
    }
    if (street && houseNumber && zipCode && city) {
      let address = `${street} ${houseNumber}, ${zipCode} ${city}, ${country}`;
      var config = {
        method: "get",
        url: `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${process.env.REACT_APP_GEOCODE_KEY}`,
        headers: {},
      };

      axios(config)
        .then((response) => {
          let coords = response.data.results[0].geometry.location;
          firebase
            .database()
            .ref("users/" + userid + "/latitude")
            .set(coords.lat);
          firebase
            .database()
            .ref("users/" + userid + "/longitude")
            .set(coords.lng);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/latitude")
        .remove();
      firebase
        .database()
        .ref("users/" + userid + "/longitude")
        .remove();
    }
    if (country) {
      firebase
        .database()
        .ref("users/" + userid + "/country")
        .set(country);
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/country")
        .set("");
    }
    if (phoneNumber) {
      firebase
        .database()
        .ref("users/" + userid + "/phoneNumber")
        .set(phoneNumber);
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/phoneNumber")
        .set("");
    }
    if (bankName) {
      firebase
        .database()
        .ref("users/" + userid + "/bankName")
        .set(bankName);
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/bankName")
        .set("");
    }
    if (bankAccountNumber) {
      firebase
        .database()
        .ref("users/" + userid + "/bankAccountNumber")
        .set(bankAccountNumber);
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/bankAccountNumber")
        .set("");
    }
    if (profileActive) {
      firebase
        .database()
        .ref("users/" + userid + "/profileActive")
        .set(true);
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/profileActive")
        .set(false);
    }
    if (redirect_guest_to_link_via_event) {
      firebase
        .database()
        .ref("users/" + userid + "/redirect_guest_to_link_via_event")
        .set(true);
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/redirect_guest_to_link_via_event")
        .set(false);
    }
    if (hide_contact_info_on_order) {
      firebase
        .database()
        .ref("users/" + userid + "/hide_contact_info_on_order")
        .set(true);
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/hide_contact_info_on_order")
        .set(false);
    }
    if (companyName) {
      firebase
        .database()
        .ref("users/" + userid + "/company_name")
        .set(companyName);
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/company_name")
        .set("");
    }
    // if (companyEmail) {
    //     firebase.database().ref("users/" + userid + "/company_email").set(companyEmail)
    // } else {
    //     firebase.database().ref("users/" + userid + "/company_email").set("")
    // }
    if (
      (first_name !== firstName || last_name !== lastName) && this.state.currentUserMember.stripe_id
    ) {
      axios
        .post(
          "https://us-central1-bhodi-production-da765.cloudfunctions.net/updateStripeCustomer",
          {
            name: firstName + "" + lastName,
            email: this.state.currentUserMember.email,
            customerId: this.state.currentUserMember.stripe_id,
          }
        )
        .then((response) => {
          this.closingPopupOnSave()
          // this.setState({ savtBtnText: "SAVE" });
        });
    } else {
      setTimeout(() => {
        this.closingPopupOnSave()
        // this.setState({ savtBtnText: "SAVE" });
      }, 3000);
    }
    // this.setState({ rightModalSettings: false, userInfoRight: false })
  }

  savePrivacy() {
    // let element = document.getElementById("footermain");
    // if (element) {
    //   element.style.display = "flex";
    // }
    this.setState({ savtBtnText: "SAVING.." });
    if (this.state.checkedHideFriendsInfo) {
      firebase
        .database()
        .ref("users/" + this.state.userid + "/hideFriendsInfo")
        .set(true)
        .then(() => {
          setTimeout(() => {
            this.closingPopupOnSave()
            // this.setState({ savtBtnText: "SAVE" });
          }, 3000);
        });
    } else {
      firebase
        .database()
        .ref("users/" + this.state.userid + "/hideFriendsInfo")
        .set(false)
        .then(() => {
          setTimeout(() => {
            this.closingPopupOnSave()
            // this.setState({ savtBtnText: "SAVE" });
          }, 3000);
        });
    }
  }

  saveVoucherSettings() {
    this.setState({ savtBtnText: "SAVING.." });
    const {
      termsText,
      useOfVoucherLocalEvent,
      useOfVoucherKortingsVoucher,
      useOfVoucherWebShop,
      expireTypeVoucher,
      expireDateVoucher,
      webshopCodeSaloon,
      syncAdminCode
    } = this.state;
    let userid = localStorage.getItem("userid");
    if (useOfVoucherLocalEvent) {
      firebase
        .database()
        .ref("users/" + userid + "/useOfVoucherLocalEvent")
        .set(useOfVoucherLocalEvent);
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/useOfVoucherLocalEvent")
        .set("");
    }
    if (useOfVoucherKortingsVoucher) {
      firebase
        .database()
        .ref("users/" + userid + "/useOfVoucherKortingsVoucher")
        .set(useOfVoucherKortingsVoucher);
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/useOfVoucherKortingsVoucher")
        .set("");
    }
    if (useOfVoucherWebShop) {
      firebase
        .database()
        .ref("users/" + userid + "/useOfVoucherWebShop")
        .set(useOfVoucherWebShop);
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/useOfVoucherWebShop")
        .set("");
    }
    if (termsText) {
      firebase
        .database()
        .ref("users/" + userid + "/termsText")
        .set(termsText);
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/termsText")
        .remove();
    }
    if (expireDateVoucher) {
      firebase
        .database()
        .ref("users/" + userid + "/expireDateVoucher")
        .set(expireDateVoucher);
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/expireDateVoucher")
        .remove();
    }
    if (expireTypeVoucher) {
      firebase
        .database()
        .ref("users/" + userid + "/expireTypeVoucher")
        .set(expireTypeVoucher);
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/expireTypeVoucher")
        .remove();
    }
    if (this.state.currentUserHere.barber_new) {
      if (webshopCodeSaloon) {
        firebase
          .database()
          .ref("users/" + userid + "/webshopCodeSaloon")
          .set(webshopCodeSaloon);
      } else {
        firebase
          .database()
          .ref("users/" + userid + "/webshopCodeSaloon")
          .remove();
      }
      if (syncAdminCode) {
        firebase
          .database()
          .ref("users/" + userid + "/syncAdminCode")
          .set(syncAdminCode);
      } else {
        firebase
          .database()
          .ref("users/" + userid + "/syncAdminCode")
          .set(false);
      }
    }
    setTimeout(() => {
      this.closingPopupOnSave()
      // this.setState({ savtBtnText: "SAVE" });
    }, 3000);
  }

  saveProductSettings() {
    this.setState({ savtBtnText: "SAVING.." });
    const {
      maxProductsToShow,
      intervalTimer,
    } = this.state;

    let userid = localStorage.getItem("userid");
    if (maxProductsToShow) {
      firebase
        .database()
        .ref("users/" + userid + "/productsInfo/maxProductsToShow")
        .set(maxProductsToShow);
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/productsInfo/maxProductsToShow")
        .set("");
    }

    if (intervalTimer) {
      firebase
        .database()
        .ref("users/" + userid + "/productsInfo/intervalTimer")
        .set(intervalTimer);
    } else {
      firebase
        .database()
        .ref("users/" + userid + "/productsInfo/intervalTimer")
        .remove();
    }

    setTimeout(() => {
      this.closingPopupOnSave()
      // this.setState({ savtBtnText: "SAVE" });
    }, 3000);
  }

  logout() {
    firebase
      .auth()
      .signOut()
      .then(() => {
        localStorage.removeItem("userdata");
        // localStorage.removeItem("isAdmin")
        localStorage.removeItem("onCurrentTab");
        localStorage.removeItem("onCurrentTabCompany");
        localStorage.removeItem("userid");
        this.props.updateSignUpFieldsFilled("");
        this.props.updateGuestUserCartExtendOpportunity("");
        this.props.updateGuestUserCartTime("");
        this.props.updateGuestUserCart([]);
        setTimeout(() => {
          window.location.href = "/";
        }, 50);
      });
  }

  saveEmailPassword() {
    const { email, password, confirmPassword, url, currentPassword } =
      this.state;

    let filteredEmail = [];
    let filteredURL = [];

    if (email !== this.state.currentUserMember.email) {
      filteredEmail = this.props.user.filter((h) => {
        return h.email === email;
      });
    }

    if (url !== this.state.currentUserMember.user_name_id) {
      filteredURL = this.props.user.filter((h) => {
        return h.user_name_id === url;
      });
    }

    if (filteredEmail.length === 0 && filteredURL.length === 0) {
      this.setState({ savtBtnText: "SAVING.." });

      let userid = localStorage.getItem("userid");
      if (url && url !== this.state.currentUserMember.user_name_id) {
        firebase
          .database()
          .ref("users/" + userid + "/user_name_id")
          .set(url)
          .then(() => {
            firebase
              .auth()
              .signOut()
              .then(() => {
                localStorage.setItem("userid", "");
                window.location.href = "/login";
              });
          });
      } else {
        console.log("fill some thing");
      }
      let changePassword = confirmPassword;
      if (email !== "") {
        // let user = firebase.auth().currentUser;
        // user.updateEmail(email).then(() => {
        //     firebase.database().ref("users/" + this.state.userid + "/email").set(email).then(() => {
        //     })
        // })
        var data = JSON.stringify({
          userId: userid,
          email: email,
        });

        var config = {
          method: "post",
          url: "https://us-central1-bhodi-production-da765.cloudfunctions.net/updateEmail",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios(config)
          .then((response) => {
            if (response.data.statusCode === 201 && this.state.currentUserMember.stripe_id) {
              axios
                .post(
                  "https://us-central1-bhodi-production-da765.cloudfunctions.net/updateStripeCustomer",
                  {
                    name:
                      this.state.currentUserMember.first_name +
                      "" +
                      this.state.currentUserMember.last_name,
                    email: email,
                    customerId: this.state.currentUserMember.stripe_id,
                  }
                )
                .then((responseInner) => {
                  console.log("mollie Updated");
                });
            }
            if (response.data.statusCode === 201) {
              firebase
                .database()
                .ref("users/" + userid + "/email")
                .set(email);
            }
          })
          .catch((response) => {
            var errorMessage = response.error.message;
            if (
              errorMessage ===
              "There is no user record corresponding to this identifier. The user may have been deleted."
            ) {
              this.setState({ emailNotFound: true, aanmeldenloader: false });
            }
          });
      }
      if (password !== changePassword) {
        if (password !== "" && changePassword !== "") {
          if (currentPassword === "") {
            this.setState({ savtBtnText: "SAVE", fillCurrentPassword: true });
          } else {
            this.setState({ fillCurrentPassword: false });
          }
        }
        this.setState({ changedPassword: true });
      } else {
        if (password !== "" && changePassword !== "") {
          if (currentPassword !== "") {
            this.setState({
              changedPassword: false,
              fillCurrentPassword: false,
            });
          } else {
            this.setState({
              savtBtnText: "SAVE",
              changedPassword: false,
              fillCurrentPassword: true,
            });
          }
        }
      }
      if (
        password !== "" &&
        changePassword !== "" &&
        currentPassword !== "" &&
        password === changePassword
      ) {
        var user = firebase.auth().currentUser;
        var credential = firebase.auth.EmailAuthProvider.credential(
          email,
          currentPassword
        );
        user
          .reauthenticateWithCredential(credential)
          .then(() => {
            this.setState({ fillCurrentPassword: false });
            var newPassword = password;
            user
              .updatePassword(newPassword)
              .then((e) => {
                bcrypt.genSalt(saltRounds, (err, salt) => {
                  bcrypt.hash(newPassword, salt, (err, hash) => {
                    firebase
                      .auth()
                      .signInWithEmailAndPassword(email, newPassword)
                      .then((succ) => {
                        // firebase
                        //   .database()
                        //   .ref("users/" + this.state.userid + "/pass")
                        //   .set(newPassword)
                        //   .then(() => {
                        firebase
                          .database()
                          .ref("users/" + this.state.userid + "/password")
                          .set(hash)
                          .then(() => {
                            this.setState({
                              password: "",
                              confirmPassword: "",
                            });
                            window.location.reload();
                          });
                        // });
                      });
                  });
                });
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((err) => {
            if (err.code === "auth/wrong-password") {
              this.setState({ savtBtnText: "SAVE", fillCurrentPassword: true });
            }
          });
      } else {
        setTimeout(() => {
          this.closingPopupOnSave()
          // this.setState({ savtBtnText: "SAVE" });
        }, 3000);
      }

    } else {
      if (filteredEmail.length > 0) {
        this.setState({ emailAlreadyTooked: true });
      } else {
        this.setState({ emailAlreadyTooked: false });
      }
      if (filteredURL.length > 0) {
        this.setState({ urlNotAvailable: true });
      } else {
        this.setState({ urlNotAvailable: false });
      }
    }
  }

  fileUpload(e) {
    let files = e.target.files[0];
    let storageRef = firebase
      .storage()
      .ref()
      .child(`userimages/${files.name}${new Date().getTime()}`);
    storageRef.put(files).then((snapshot) => {
      snapshot.ref.getDownloadURL().then((snapUrl) => {
        firebase
          .database()
          .ref("users/" + this.state.userid + "/profile_image")
          .set(snapUrl)
          .then(() => { });
      });
    });
  }

  fileUploadpdf(e) {
    let files = e.target.files[0];
    let storageRef = firebase
      .storage()
      .ref()
      .child(`requirementfiles/${files.name}${new Date().getTime()}`);
    storageRef.put(files).then((snapshot) => {
      snapshot.ref.getDownloadURL().then((snapUrl) => {
        firebase
          .database()
          .ref("users/" + this.state.userid + "/requirement_file")
          .set(snapUrl)
          .then(() => {
            firebase
              .database()
              .ref("users/" + this.state.userid + "/file_name")
              .set(files.name)
              .then(() => {
                this.setState({ termsText: "" });
              });
          });
      });
    });
  }

  deletePdf() {
    firebase
      .database()
      .ref("users/" + this.state.userid + "/requirement_file")
      .remove()
      .then(() => {
        firebase
          .database()
          .ref("users/" + this.state.userid + "/file_name")
          .remove();
      });
  }

  getCountComing(e) {
    let objected = [];

    let newFollowers = 0;
    if (this.state.currentUserMember.newFollowersList) {
      let objectedInner = Object.entries(
        this.state.currentUserMember.newFollowersList
      );
      newFollowers = objectedInner.length;
    }

    let allCounted =
      objected.length + newFollowers;

    let toShowText = 0
    if (Number(allCounted) > 999) {
      toShowText = "1k+";
    } else if (Number(allCounted) > 99) {
      toShowText = "99+";
    } else {
      toShowText = allCounted;
    }

    if (allCounted > 0) {
      return (<div className="comingrequestsnotify">
        <p>{toShowText}</p>
      </div>)
    }
    // return objected.length + newFollowers + this.state.notSeenMessages.length
  }


  componentDidUpdate(prevProps, prevState) {
    if (prevProps.message !== this.props.message) {
      if (this.props.message) {
        let allMessages = [];
        this.props.message.map((g) => {
          let objected = Object.entries(g[1]);
          objected.map((y) => {
            allMessages.push(y[1]);
          });
        });
        let filtered = allMessages.filter((g) => {
          return !g.seen && g.type === "reciever";
        });
        this.setState({ notSeenMessages: filtered });
      } else {
        this.setState({ notSeenMessages: [] });
      }
    }

    if (this.state.rightModalSettings !== prevState.rightModalSettings) {
      // Call your function here
      if (this.state.rightModalSettings) {
        this.doHtmlOverflowHidden()
      } else {
        this.removeHtmlOverflowHidden()
      }
    }

    if (this.props.global_campaigns !== prevProps.global_campaigns) {
      if (!window.location.href.includes("volgend")) {
        let uid = localStorage.getItem("userid");
        let bhodi_campaigns = this.props.global_campaigns;
        if (bhodi_campaigns) {
          if (bhodi_campaigns.length > 0) {
            let arr = [];
            let allCampaignsCompleted = [];
            let allCampaignsCompletedToRender = [];
            let arrCurrentCampaigns = [];
            let objected = bhodi_campaigns;
            objected.map((e) => {
              e[1].type = "campaign";
              if (e[1].active) {
                allCampaignsCompletedToRender.push(e[1]);
                // if (e[1].desc) {
                //   e[1].desc = e[1].desc.substr(0, 200);
                // }
                allCampaignsCompleted.push(e[1]);
              }
            });
            allCampaignsCompleted.map((e) => {
              e.type = "campaign";
              if (e.created_by !== uid) {
                let filtered = this.props.user.filter((f) => {
                  return f.user_id === e.created_by;
                });
                if (filtered.length > 0) {
                  let f = filtered[0];
                  if (f.followed_by || f.friends) {
                    let objectedFriends = [];
                    if (f.followed_by) {
                      objectedFriends = Object.entries(f.followed_by);
                    }
                    objectedFriends.map((g) => {
                      if (g[1].user_id === uid) {
                        if (e.active) {
                          if (e.desc) {
                            e.desc = e.desc.substr(0, 520);
                            e.short_desc = e.desc.substr(0, 120);
                          }
                          e.images = [e.image];
                          e.imagesObjected = [
                            { image: e.image, pushKey: e.pushKey },
                          ];

                          if (e.campaignType === "kortings_voucher") {
                            let objectedVerzilveredBy = [];
                            let num = 0;
                            if (e.verzilvered_by) {
                              objectedVerzilveredBy = Object.entries(
                                e.verzilvered_by
                              );
                              num = this.getVerzilveredQty(objectedVerzilveredBy);
                            }
                            let bewaaredArray = [];
                            if (!e.kortingsVoucherUnlimited) {
                              if (num + bewaaredArray.length < Number(e.number)) {
                                arr.push(e);
                              }
                            } else {
                              arr.push(e);
                            }
                          }
                          if (e.campaignType === "local_event") {
                            if (e.eventForGuestUser) {
                              return false;
                            }
                            if (e.takeFromAdmin) {
                              let obj = mergeAdminEvent(e, this.props.global_campaigns)
                              e = Object.assign({}, obj)
                            }
                            let arrTimingsAvailable = [];
                            if (e.timings) {
                              e.timings.map((y) => {
                                if (y.isoStartTime && y.isoEndTime) {
                                  let checkedInOfCurrentBlock = [];
                                  if (e.checked_in_by) {
                                    Object.entries(e.checked_in_by).map((t) => {
                                      if (t[1].event_id === y.eventId) {
                                        checkedInOfCurrentBlock.push(t[1]);
                                      }
                                    });
                                  }
                                  // let getEventDate = new Date(`${y.endDateEvent} ${y.endTimeEvent}`)
                                  // let getEventDate = new Date(y.endDateEvent);
                                  // let endTimeGood =
                                  //   y.endTimeEvent.concat(":00");
                                  // getEventDate.setHours(
                                  //   endTimeGood.split(":")[0]
                                  // );
                                  // getEventDate.setMinutes(
                                  //   endTimeGood.split(":")[1]
                                  // );
                                  let getCurrentDate = new Date();
                                  if (
                                    getCurrentDate.getTime() <
                                    new Date(Date.parse(y.isoEndTime)).getTime()
                                  ) {
                                    if (
                                      checkedInOfCurrentBlock.length <
                                      Number(y.personsAmountEvent)
                                    ) {
                                      arrTimingsAvailable.push(y);
                                    }
                                  }
                                }
                              });
                            }
                            if (arrTimingsAvailable.length > 0) {
                              arr.push(e);
                            }
                          }
                          if (e.campaignType === "web_shop") {
                            let objectedVerzilveredBy = [];
                            let num = 0;
                            if (e.verzilvered_by) {
                              objectedVerzilveredBy = Object.entries(
                                e.verzilvered_by
                              );
                              num = this.getVerzilveredQty(
                                objectedVerzilveredBy
                              );
                            }
                            let bewaaredArray = [];
                            if (!e.voucherUnlimited) {
                              if (
                                num + bewaaredArray.length <
                                Number(e.number)
                              ) {
                                arr.push(e);
                              }
                            } else {
                              arr.push(e);
                            }
                          }
                        }
                      }
                    });
                  }
                }
              }
              if (e.created_by === uid) {
                arrCurrentCampaigns.push(e);
              }
            });
            this.setState({ lengthOfOtherCampaigns: arr });
          }
        }
      }
    }
    if (this.props.doShowMenuRightAbout) {
      if (this.props.doShowMenuRightAbout !== this.state.showBhodiAbouts) {
        this.setState({
          rightModalSettings: true,
          openedFromPropsAbout: true,
          menuItemsModals: true,
          showBhodiAbouts: true,
        });
      }
    }
    if (this.props.openClientListRight) {
      if (this.props.openClientListRight !== this.state.clientPageRight) {
        this.setState({
          rightModalSettings: true,
          clientPageRight: true,
          openedClientFromProps: true,
        });
        if (this.props.closeopenClientListRight) {
          this.props.closeopenClientListRight();
        }
      }
    }
  }

  createMarkup(e) {
    if (e) {
      return { __html: e };
    }
  }

  async deleteCurrentUser() {
    if (this.state.showDelete) {
      if (this.state.passwordForDel) {
        this.setState({ passwordRequiredDel: false })

        let email = this.state.currentUserMember.email
        let password = this.state.passwordForDel
        var user = firebase.auth().currentUser;
        var credential = firebase.auth.EmailAuthProvider.credential(
          email,
          password
        );;
        user.reauthenticateWithCredential(credential).then(() => {
          this.setState({ passwordWrongDel: false })
          this.deleteUserMain()
        }).catch(() => {
          this.setState({ passwordWrongDel: true })
          return false;
        })
      } else {
        this.setState({ passwordRequiredDel: true, passwordWrongDel: false })
        return false;
      }
    } else {
      this.setState({
        // cancelSubscriptionPopup: false,
        showDelete: true,
      });
      axios
        .post(
          "https://us-central1-bhodi-production-da765.cloudfunctions.net/cancelStripeCustomerSubscription",
          {
            subscriptionId:
              this.state.currentUserMember.subscription_id,
            customerId: this.state.currentUserMember.stripe_id,
          }
        )
        .then(() => {
          axios
            .post(
              "https://us-central1-bhodi-production-da765.cloudfunctions.net/deleteStripeCustomer",
              {
                customerId: this.state.currentUserMember.stripe_id,
              }
            )
            .then(() => {
              this.deleteCurrentUser();
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
      // this.setState({ cancelSubscriptionPopup: true });
    }
  }

  async deleteUserMain() {

    this.setState({ deleteBtnText: "SAVING.." });

    let allUsers = this.props.user;
    allUsers.map((u) => {
      if (u.following) {
        let objected = Object.entries(u.following);
        let filtered = objected.filter((b) => {
          return b[1].user_id === this.state.currentUserId;
        });
        if (filtered.length > 0) {
          firebase
            .database()
            .ref(
              "users/" + u.user_id + "/following/" + filtered[0][1].pushKey
            )
            .remove();
        }
      }
      if (u.followed_by) {
        let objected = Object.entries(u.followed_by);
        let filtered = objected.filter((b) => {
          return b[1].user_id === this.state.currentUserId;
        });
        if (filtered.length > 0) {
          firebase
            .database()
            .ref(
              "users/" + u.user_id + "/followed_by/" + filtered[0][1].pushKey
            )
            .remove();
        }
      }
      if (u.newFollowersList) {
        let objected = Object.entries(u.newFollowersList);
        let filtered = objected.filter((b) => {
          return b[1].user_id === this.state.currentUserId;
        });
        if (filtered.length > 0) {
          let newFollowers = u.newFollowers - 1;
          firebase
            .database()
            .ref(
              "users/" +
              u.user_id +
              "/newFollowersList/" +
              filtered[0][1].pushKey
            )
            .remove();
          firebase
            .database()
            .ref("users/" + u.user_id + "/newFollowers")
            .set(newFollowers);
        }
      }
    });
    await firebase
      .database()
      .ref("bhodi_campaigns/" + this.state.currentUserId)
      .remove();
    await firebase
      .database()
      .ref("impressiePhotos/" + this.state.currentUserId)
      .remove();
    await firebase
      .database()
      .ref("allMessages/" + this.state.currentUserId)
      .remove();
    await firebase
      .database()
      .ref("guest_carts/" + this.state.currentUserId)
      .remove();
    await firebase.auth().currentUser.delete();

    firebase
      .database()
      .ref("users/" + this.state.currentUserId)
      .remove()
      .then(() => {
        localStorage.removeItem("userdata");
        // localStorage.removeItem("isAdmin")
        localStorage.removeItem("onCurrentTab");
        localStorage.removeItem("onCurrentTabCompany");
        localStorage.removeItem("userid");
        this.props.updateSignUpFieldsFilled("");
        this.props.updateGuestUserCartExtendOpportunity("");
        this.props.updateGuestUserCartTime("");
        this.props.updateGuestUserCart([]);
        setTimeout(() => {
          window.location.href = "/";
        }, 50);
      });
  }

  doHtmlOverflowHidden() {
    document.querySelector("html").classList.add("htmlvriendenoverflowhidden")
  }

  removeHtmlOverflowHidden() {
    document.querySelector("html").classList.remove("htmlvriendenoverflowhidden")
  }

  checkForFollowIcon(userToSee) {
    if (userToSee.followed_by) {
      let objected = Object.entries(userToSee.followed_by);
      let filtered = objected.filter((g) => {
        return g[1].user_id === this.state.currentUserId;
      });
      if (filtered.length > 0) {
        if (userToSee.friends) {
          let objectedFriends = Object.entries(
            userToSee.friends
          );
          let filteredFriends = objectedFriends.filter((g) => {
            return g[1].user_id === this.state.currentUserId;
          });
          if (filteredFriends.length === 0) {
            return <img src={hwb} style={{ maxWidth: 16, marginRight: 10 }} />;
          }
        } else {
          return <img src={hwb} style={{ maxWidth: 16, marginRight: 10 }} />;
        }
      } else {
        if (userToSee.friends) {
          let objectedFriends = Object.entries(
            userToSee.friends
          );
          let filteredFriends = objectedFriends.filter((g) => {
            return g[1].user_id === this.state.currentUserId;
          });
          if (filteredFriends.length === 0) {
            //     return <img src={hwb} style={{ maxWidth: 20, marginRight: 10 }} />
            // } else {
            return <img src={hbb} style={{ maxWidth: 16, marginRight: 10 }} />;
          }
        } else {
          return <img src={hbb} style={{ maxWidth: 16, marginRight: 10 }} />;
        }
      }
    } else {
      if (userToSee.friends) {
        let objectedFriends = Object.entries(
          userToSee.friends
        );
        let filteredFriends = objectedFriends.filter((g) => {
          return g[1].user_id === this.state.currentUserId;
        });
        if (filteredFriends.length === 0) {
          //     return <img src={hwb} style={{ maxWidth: 20, marginRight: 10 }} />
          // } else {
          return <img src={hbb} style={{ maxWidth: 16, marginRight: 10 }} />;
        }
      } else {
        return <img src={hbb} style={{ maxWidth: 16, marginRight: 10 }} />;
      }
    }
  }

  checkForFollowText(userToSee) {
    if (userToSee.followed_by) {
      let objected = Object.entries(userToSee.followed_by);
      let filtered = objected.filter((g) => {
        return g[1].user_id === this.state.currentUserId;
      });
      if (filtered.length > 0) {
        if (userToSee.friends) {
          let objectedFriends = Object.entries(
            userToSee.friends
          );
          let filteredFriends = objectedFriends.filter((g) => {
            return g[1].user_id === this.state.currentUserId;
          });
          if (filteredFriends.length === 0) {
            return <font>Ontvolgen</font>;
          }
        } else {
          return <font>Ontvolgen</font>;
        }
      } else {
        if (userToSee.friends) {
          let objectedFriends = Object.entries(
            userToSee.friends
          );
          let filteredFriends = objectedFriends.filter((g) => {
            return g[1].user_id === this.state.currentUserId;
          });
          if (filteredFriends.length === 0) {
            //     return <font>Ontvolgen</font>
            // } else {
            return <font>Volgen</font>;
          }
        } else {
          return <font>Volgen</font>;
        }
      }
    } else {
      if (userToSee.friends) {
        let objectedFriends = Object.entries(
          userToSee.friends
        );
        let filteredFriends = objectedFriends.filter((g) => {
          return g[1].user_id === this.state.currentUserId;
        });
        if (filteredFriends.length === 0) {
          //     return <font>Ontvolgen</font>
          // } else {
          return <font>Volgen</font>;
        }
      } else {
        return <font>Volgen</font>;
      }
    }
  }

  followCompany(userToSee) {
    followUnfollowCompany(userToSee, this.state.currentUserId, this.props.user)
  }

  // followCompany(userToSee) {
  //   if (userToSee.followed_by) {
  //     let objected = Object.entries(userToSee.followed_by);
  //     let filtered = objected.filter((g) => {
  //       return g[1].user_id === this.state.currentUserId;
  //     });
  //     if (filtered.length > 0) {
  //       if (userToSee.newFollowersList) {
  //         let objectedSec = Object.entries(
  //           userToSee.newFollowersList
  //         );
  //         let filteredNewFollowers = objectedSec.filter((g) => {
  //           return g[1].user_id === this.state.currentUserId;
  //         });
  //         if (filteredNewFollowers.length > 0) {
  //           let newFollowers = Number(
  //             userToSee.newFollowers
  //           );
  //           newFollowers = newFollowers - 1;
  //           firebase
  //             .database()
  //             .ref(
  //               "users/" +
  //               userToSee.user_id +
  //               "/newFollowersList/" +
  //               filteredNewFollowers[0][1].push_key
  //             )
  //             .remove();
  //           firebase
  //             .database()
  //             .ref(
  //               "users/" +
  //               userToSee.user_id +
  //               "/newFollowers"
  //             )
  //             .set(newFollowers);
  //         }
  //       }
  //       let filteredCurrentUser = this.props.user.filter((h) => {
  //         return h.user_id === this.state.currentUserId;
  //       });
  //       if (filteredCurrentUser[0].following) {
  //         let objectedSec = Object.entries(filteredCurrentUser[0].following);
  //         let filteredSec = objectedSec.filter((g) => {
  //           return g[1].user_id === userToSee.user_id;
  //         });
  //         if (filteredSec.length > 0) {
  //           firebase
  //             .database()
  //             .ref(
  //               "users/" +
  //               this.state.currentUserId +
  //               "/following/" +
  //               filteredSec[0][1].push_key
  //             )
  //             .remove();
  //         }
  //       }
  //       firebase
  //         .database()
  //         .ref(
  //           "users/" +
  //           userToSee.user_id +
  //           "/followed_by/" +
  //           filtered[0][1].push_key
  //         )
  //         .remove();
  //     } else {
  //       let pushFirst = firebase
  //         .database()
  //         .ref("users/" + this.state.currentUserId + "/following")
  //         .push().key;
  //       let pushSecond = firebase
  //         .database()
  //         .ref("users/" + userToSee.user_id + "/followed_by")
  //         .push().key;
  //       let pushSecondNew = firebase
  //         .database()
  //         .ref(
  //           "users/" +
  //           userToSee.user_id +
  //           "/newFollowersList"
  //         )
  //         .push().key;
  //       let newFollowers = 0;
  //       let obj = {
  //         user_id: userToSee.user_id,
  //         push_key: pushFirst,
  //       };
  //       let objSec = {
  //         user_id: this.state.currentUserId,
  //         push_key: pushSecond,
  //       };
  //       let objThird = {
  //         user_id: this.state.currentUserId,
  //         push_key: pushSecondNew,
  //       };
  //       if (userToSee.newFollowers) {
  //         newFollowers = Number(userToSee.newFollowers);
  //       }
  //       newFollowers = newFollowers + 1;
  //       firebase
  //         .database()
  //         .ref("users/" + this.state.currentUserId + "/following/" + pushFirst)
  //         .set(obj);
  //       firebase
  //         .database()
  //         .ref(
  //           "users/" +
  //           userToSee.user_id +
  //           "/followed_by/" +
  //           pushSecond
  //         )
  //         .set(objSec);
  //       firebase
  //         .database()
  //         .ref(
  //           "users/" + userToSee.user_id + "/newFollowers"
  //         )
  //         .set(newFollowers);
  //       firebase
  //         .database()
  //         .ref(
  //           "users/" +
  //           userToSee.user_id +
  //           "/newFollowersList/" +
  //           pushSecondNew
  //         )
  //         .set(objThird);
  //     }
  //   } else {
  //     let pushFirst = firebase
  //       .database()
  //       .ref("users/" + this.state.currentUserId + "/following")
  //       .push().key;
  //     let pushSecond = firebase
  //       .database()
  //       .ref("users/" + userToSee.user_id + "/followed_by")
  //       .push().key;
  //     let pushSecondNew = firebase
  //       .database()
  //       .ref(
  //         "users/" + userToSee.user_id + "/newFollowersList"
  //       )
  //       .push().key;
  //     let newFollowers = 0;
  //     let obj = {
  //       user_id: userToSee.user_id,
  //       push_key: pushFirst,
  //     };
  //     let objSec = {
  //       user_id: this.state.currentUserId,
  //       push_key: pushSecond,
  //     };
  //     let objThird = {
  //       user_id: this.state.currentUserId,
  //       push_key: pushSecondNew,
  //     };
  //     if (userToSee.newFollowers) {
  //       newFollowers = Number(userToSee.newFollowers);
  //     }
  //     newFollowers = newFollowers + 1;
  //     firebase
  //       .database()
  //       .ref("users/" + this.state.currentUserId + "/following/" + pushFirst)
  //       .set(obj);
  //     firebase
  //       .database()
  //       .ref(
  //         "users/" +
  //         userToSee.user_id +
  //         "/followed_by/" +
  //         pushSecond
  //       )
  //       .set(objSec);
  //     firebase
  //       .database()
  //       .ref("users/" + userToSee.user_id + "/newFollowers")
  //       .set(newFollowers);
  //     firebase
  //       .database()
  //       .ref(
  //         "users/" +
  //         userToSee.user_id +
  //         "/newFollowersList/" +
  //         pushSecondNew
  //       )
  //       .set(objThird);
  //   }
  // }

  connectToMollie() {
    var config = {
      method: "get",
      url: "https://us-central1-bhodi-production-da765.cloudfunctions.net/connectToMollie",
    }

    axios(config)
      .then((response) => {
        if (response.data.statusCode === 201) {
          window.location.href = response.data.url
        } else {
          swal({
            title: "Oops.",
            text: "Error occured, please try again in a while",
            icon: "error",
          })
        }
      })
      .catch((error) => {
        swal({
          title: "Oops.",
          text: "Error occured, please try again in a while",
          icon: "error",
        })
      });
  }

  disconnectMollie() {
    firebase.database().ref(`users/${this.state.currentUserHere.user_id}/mollie_credentials`).remove()
  }

  disconnectPaypal() {
    firebase.database().ref(`users/${this.state.currentUserHere.user_id}/paypalInfo`).remove()
  }

  connectToPaypal() {
    let returnUrl = "";
    if (this.state.currentUserHere.type === "company") {
      returnUrl = `${window.location.origin}/company/${this.state.currentUserHere.user_name_id}`;
    }

    var data = JSON.stringify({
      customer_data: {
        customer_type: "MERCHANT",
        person_details: {
          email_address: this.state.currentUserHere.email,
        },
        partner_specific_identifiers: [
          {
            type: "TRACKING_ID",
            value: "ebaef1b4-5550-429d-9925-ed99d1666efc2",
          },
        ],
      },
      requested_capabilities: [
        {
          capability: "API_INTEGRATION",
          api_integration_preference: {
            partner_id: "E3M5Y9GGKLQQJ",
            rest_api_integration: {
              integration_method: "PAYPAL",
              integration_type: "THIRD_PARTY",
            },
            rest_third_party_details: {
              partner_client_id:
                "AS_WXrkwOcMdxirbjpW230Zw_HKMN_kFhxR7XtmrB2FO4LFziukkSGX2Vtn1kBaHtZGKzKt4RWwp3r6j",
              feature_list: [
                "PAYMENT",
                "REFUND",
                "PARTNER_FEE",
                "DELAY_FUNDS_DISBURSEMENT",
              ],
            },
          },
        },
      ],
      web_experience_preference: {
        return_url: returnUrl,
        partner_logo_url:
          "https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/logowhite.png?alt=media&token=12590aef-0263-47e7-8462-15bf3a7f5e71",
      },
      collected_consents: [
        {
          type: "SHARE_DATA_CONSENT",
          granted: true,
        },
      ],
      products: ["EXPRESS_CHECKOUT"],
    });

    var config = {
      method: "get",
      url: "https://us-central1-bhodi-production-da765.cloudfunctions.net/getAuthDetails",
      headers: {},
    };

    axios(config)
      .then((response) => {
        if (response.status === 200) {
          let accessToken = response.data.access_token;

          var configAnother = {
            method: "post",
            url: "https://us-central1-bhodi-production-da765.cloudfunctions.net/partnerReferrals",
            headers: {
              authToken: accessToken,
              "Content-Type": "application/json",
            },
            data: data,
          };

          axios(configAnother)
            .then((responseNew) => {
              if (responseNew.data.links.length > 0) {
                let filtered = responseNew.data.links.filter((e) => {
                  return e.rel === "action_url";
                });
                if (filtered.length > 0) {
                  window.location.href = filtered[0].href;
                }
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  getAdministrationData() {
    if (this.state.allAdministrationData.length > 0) {
      let allTotal = 0
      this.state.allAdministrationData.map((e) => {
        allTotal += Number(e.amount)
      })
      return <>
        <p>Charity members: {this.state.allAdministrationData.length}</p>
        <p>Total €{<NumberFormatter toFormat={allTotal.toFixed(2)} />}</p>
      </>
    } else {
      return <p>No Matching records found</p>
    }
  }


  closingPopupOnSave() {
    let element = document.getElementById("footermain");
    if (element) {
      element.style.display = "flex";
    }
    if (this.state.openedDirectlyInfos) {
      this.props.removeFillUpAddress()
      this.setState({
        rightModalSettings: false,
        privacyRight: false,
        channelPageRight: false,
        userInfoRight: false,
        shopSettings: false,
        voucherSettings: false,
        emailPassword: false,
        myOrdersRight: false,
        allOrdersRight: false,
        showBhodiFaqs: false,
        showBhodiAbouts: false,
        productSettings: false,
        savtBtnText: "SAVE",
        openedDirectlyInfos: false,
      });
      return;
    }
    this.setState({
      rightModalSettings: false,
      privacyRight: false,
      channelPageRight: false,
      userInfoRight: false,
      shopSettings: false,
      voucherSettings: false,
      emailPassword: false,
      myOrdersRight: false,
      allOrdersRight: false,
      showBhodiFaqs: false,
      showBhodiAbouts: false,
      productSettings: false,
      savtBtnText: "SAVE"
    });
  }

  render() {
    return (
      !this.props.hideNav && (
        <div>
          <div
            className="nav-fullwidth"
            id="navfullwidthmaincomp"
            style={{ backgroundColor: this.props.mustBeGray && "#F8F8F8" }}
          >
            <div className="container-nfw">
              {/* <div className="top-cnfw-updated"> */}
              {/* </div> */}
              {/* <div className="bottom-cnfw-updated"> */}
              {this.props.menuItemsShowing && (
                <div
                  className="left-nfw"
                  style={{
                    opacity: this.state.listsOpacity,
                    pointerEvents: this.state.listsPointers,
                  }}
                >
                  <ul>
                    {/* {this.state.currentUserMember && (
                      <li
                        onClick={() => {
                          // this.setState({ showItemsShops: false, showLists: true, showItems: false, showFriends: false, showCharity: false, showImpression: false, showVoucher: false, showPosts: false, showProductsBookmarked: false, showProductsFriends: false, showContent: false })
                          this.props.showPosts();
                        }}
                      >
                        <HomeIcon />
                      </li>
                    )} */}
                    <NavbarMenuMobileFooterStuff showTrending={this.state.currentUserMember.user_id === this.state.currentUserId} currentUserMember={this.state.currentUserMember} />
                    {this.state.lengthOfOtherCampaigns.length > 0 &&
                      this.state.currentUserMember.user_id ===
                      this.state.currentUserId && (
                        <li
                          className={this.state.showProductsFriends && "active"}
                          onClick={() => {
                            this.props.showProductsOfFriends();
                          }}
                        >
                          Volgend
                        </li>
                      )}


                    {this.state.currentUserMember &&
                      this.state.currentUserMember.user_id ===
                      this.state.currentUserId && (
                        <li
                          className={this.state.showCharity && "active"}
                          onClick={() => {
                            this.props.showCharity();
                          }}
                        >
                          {this.state.charityCount > 0 && (<div className="comingrequestsnotify">
                            <p>{this.state.charityCount}</p>
                          </div>)}
                          Charity
                        </li>
                      )}
                    {this.state.currentUserMember &&
                      this.state.currentUserMember.user_id ===
                      this.state.currentUserId ? (
                      <li
                        className={(this.state.showFriends || this.state.showVriendenWizard) && "active"}
                        onClick={() => {
                          this.props.showFriends();
                        }}
                      >
                        {this.getCountComing()}
                        <font>Vrienden</font>
                      </li>
                    ) : (
                      this.state.currentUserMember &&
                      this.state.showFriendstab &&
                      !this.state.hideFriendsInfo && (
                        <li
                          className={this.state.showFriends && "active"}
                          onClick={() => {
                            this.props.showFriends();
                          }}
                        >
                          Vrienden
                        </li>
                      )
                    )}
                  </ul>
                </div>
              )}
              <div
                className="center-nfw"
                style={{
                  display: "flex",
                  flexDirection: !this.props.menuItemsShowing
                    ? "row"
                    : "column",
                  left: !this.props.menuItemsShowing
                    ? "3.2%"
                    : !this.state.isDesktop && this.state.showPosts
                      ? 0
                      : !this.state.isDesktop && this.state.showItems
                        ? 0
                        : !this.state.isDesktop && this.state.showVoucher && 0,
                  right: !this.props.menuItemsShowing && "auto",
                  transform:
                    !this.state.isDesktop && this.state.showPosts
                      ? "none"
                      : !this.state.isDesktop && this.state.showItems
                        ? "none"
                        : !this.state.isDesktop &&
                        this.state.showVoucher &&
                        "none",
                }}
              >
                <img src={topcompanylandingpage} style={{ width: 90, height: 45, objectFit: "contain" }} />
                {/* {this.state.currentUserMember && (
                  <img
                    src={playicon}
                    onClick={() => {
                      this.props.showPosts();
                      this.setState({
                        showLists: true,
                        showItems: false,
                        showFriends: false,
                        showProductsOwn: false,
                        showProductsBookmarked: false,
                      });
                    }}
                    style={{
                      width: this.props.menuItemsShowing ? 25 : 30,
                      height: this.props.menuItemsShowing ? 25 : 30,
                      objectFit: "cover",
                      objectPosition: "center",
                      // marginRight: 8,
                      borderRadius: "50%",
                    }}
                  />
                )}
                <label
                  style={{
                    fontSize: 14,
                    letterSpacing: 1,
                    marginTop: 5,
                    marginBottom: 0,
                    fontWeight: 600,
                    textTransform: "none",
                    // textTransform: this.props.menuItemsShowing && "uppercase",
                  }}
                  className={`${!this.props.menuItemsShowing && "doliststylefont"}`}
                  onClick={() => {
                    this.props.showPosts();
                    this.setState({
                      showLists: true,
                      showItems: false,
                      showFriends: false,
                      showProductsOwn: false,
                      showProductsBookmarked: false,
                    });
                  }}
                >
                  BHODI
                </label> */}
              </div>
              {!this.props.hideMenuWork &&
                this.state.currentUserMember.user_id ===
                this.state.currentUserId ? (
                <div
                  className="right-nfw righticons"
                  style={{ position: "static" }}
                >
                  {this.props.onGlobalPageEvents && (<div className="icons-nclgd">
                    <NavGlobalVoucherEventsCity
                      sector={this.props.sector}
                      onMainSector={(!this.props.isDezeWeek && !this.props.isVolgendeWeek) ? true : false}
                      onCurrentWeek={this.props.isDezeWeek ? true : false}
                      onComingWeek={this.props.isVolgendeWeek ? true : false}
                      noCityFiltered={this.props.noCityFiltered ? true : false}
                      // noCityFiltered={true}
                      noShowCity={true}
                    />
                    <a
                      href="javascript:void(0)"
                      className="btn btn-black orderbasketicon btnpopupwork"
                    >
                      <img
                        src={searchpicpng}
                        style={{ maxWidth: 20, marginTop: 3 }}
                        onClick={() => {
                          this.setState({ showSearchLine: !this.state.showSearchLine })
                        }}
                      />
                      {this.state.showSearchLine && (
                        <input
                          type="text"
                          className="input-inclgd"
                          onChange={(e) => {
                            if (this.props.searchFiltered) {
                              this.props.searchFiltered(e.target.value);
                            }
                          }}
                        />
                      )}
                    </a>
                    {/* <a
                      href="javascript:void(0)"
                      className="btn btn-black orderbasketicon btnpopupwork"
                    >
                      <FilterList
                        style={{ cursor: "pointer" }}
                        onClick={() => this.setState({ showFiltersModal: true })}
                      />
                    </a> */}
                    <FilterIconGlobalNav sector={this.props.sector} onPopupOpen={(currentWeekEvents, comingWeekEvents) => {
                      this.setState({ currentWeekEvents, comingWeekEvents, showFiltersModal: true })
                    }} />
                  </div>)}

                  {this.props.onGlobalPageVoucher && (<div className="icons-nclgd">
                    <NavGlobalVoucherCity
                      sector={this.props.sector}
                      onMainSector={this.props.onMainSector}
                      noCityFiltered={this.props.noCityFiltered}
                      noShowCity={this.props.noShowCity}
                      category={this.props.category}
                      onMainCategory={this.props.onMainCategory}
                    />
                    <a
                      href="javascript:void(0)"
                      className="btn btn-black orderbasketicon btnpopupwork"
                    >
                      <img
                        src={searchpicpng}
                        style={{ maxWidth: 20, marginTop: 3 }}
                        onClick={() => {
                          this.setState({ showSearchLine: !this.state.showSearchLine })
                        }}
                      />
                      {this.state.showSearchLine && (
                        <input
                          type="text"
                          className="input-inclgd"
                          onChange={(e) => {
                            if (this.props.searchFiltered) {
                              this.props.searchFiltered(e.target.value);
                            }
                          }}
                        />
                      )}
                    </a>
                    <FilterIconGlobalNavVoucher
                      sector={this.props.sector}
                      onMainSector={this.props.onMainSector}
                      noCityFiltered={this.props.noCityFiltered}
                      noShowCity={this.props.noShowCity}
                      category={this.props.category}
                      onMainCategory={this.props.onMainCategory}
                      onPopupOpen={(availableMainCategories, allCategories) => {
                        this.setState({ availableMainCategories, allCategories, showFiltersModalVouchers: true })
                      }} />
                  </div>)}

                  {
                    <div
                      style={{ position: "relative", display: "inline-block" }}
                      className="righticonopacity"
                    >
                      {this.props.showFollowIcon && <div
                        className="btn btn-black orderbasketicon btnpopupwork obifollowicon"
                        onClick={this.followCompany.bind(this, this.props.currentMainMember)}
                      >
                        {this.checkForFollowIcon(this.props.currentMainMember)}
                        {this.checkForFollowText(this.props.currentMainMember)}
                      </div>}
                      <a
                        href="javascript:void(0)"
                        className="btn btn-black orderbasketicon btnpopupwork"
                        onClick={() => {
                          this.setState({ ordersRightMenu: true });
                        }}
                      >
                        <img
                          src={orderspicpng}
                          style={{ maxWidth: 20, marginTop: 3 }}
                        />
                        <div id="mustShowOnAddOrder" style={{ left: "-126%" }}>
                          <p style={{ textTransform: "none" }}>Toegevoegd</p>
                        </div>
                        <div
                          className="notification-bpw"
                          style={{ left: "-190%", minWidth: 150 }}
                        >
                          <p>Orderoverzicht</p>
                        </div>
                      </a>
                      {!window.location.href.includes("/charityConfirmation") && !window.location.href.includes("/exclusive-subscribing") && (
                        <a
                          href="javascript:void(0)"
                          style={{ position: "relative" }}
                          className="btn btn-black orderbasketicon orderbasketiconmain"
                          onClick={() => { }}
                        >
                          <CartNonUser notNeedOrders={true} />
                        </a>
                      )}
                      <a
                        href="javascript:void(0)"
                        className="btn btn-black btnpopupwork"
                        onClick={() => {
                          let element = document.getElementById("footermain");
                          if (element) {
                            element.style.display = "none";
                          }
                          this.setState({ rightModalSettings: true });
                          if (window.innerWidth < 1009) {
                            this.setState({
                              userInfoRight: false,
                              menuItemsModals: true,
                            });
                          }
                        }}
                      >
                        <img
                          src={menupicpng}
                          style={{ maxWidth: 20, marginTop: 3 }}
                        />
                        <div className="notification-bpw">
                          <p>Gegevens</p>
                        </div>
                      </a>
                    </div>
                  }
                </div>
              ) : (
                !this.props.hideMenuWork && (
                  <div
                    className="right-nfw righticons"
                    style={{ position: "static" }}
                  >
                    {
                      <div
                        style={{
                          position: "relative",
                          display: "inline-block",
                        }}
                        className="righticonopacity"
                      >
                        <div
                          className="btn-cdd"
                          style={{
                            cursor: "pointer",
                            padding: "5px 15px",
                            top: 9,
                            letterSpacing: 1,
                            fontSize: 13,
                            right: this.props.subscriptionActivated ? 30 : 65,
                            backgroundColor: "transparent",
                          }}
                          onClick={this.followCompany.bind(this, this.state.currentUserMember)}
                        >
                          {this.checkForFollowIcon(this.state.currentUserMember)}
                          {this.checkForFollowText(this.state.currentUserMember)}
                        </div>
                        <a
                          href="javascript:void(0)"
                          className="btn btn-black orderbasketicon btnpopupwork"
                          onClick={() => {
                            this.setState({ ordersRightMenu: true });
                          }}
                        >
                          <img
                            src={orderspicpng}
                            style={{ maxWidth: 20, marginTop: 3 }}
                          />
                          <div
                            id="mustShowOnAddOrder"
                            style={{ left: "-126%" }}
                          >
                            <p style={{ textTransform: "none" }}>Toegevoegd</p>
                          </div>
                          <div
                            className="notification-bpw"
                            style={{ left: "-190%", minWidth: 150 }}
                          >
                            <p>Orderoverzicht</p>
                          </div>
                        </a>
                        {(
                          <a
                            href="javascript:void(0)"
                            style={{ position: "relative" }}
                            className="btn btn-black orderbasketicon orderbasketiconmain"
                            onClick={() => { }}
                          >
                            <CartNonUser notNeedOrders={true} />
                          </a>
                        )}
                      </div>
                    }
                  </div>
                )
              )}
            </div>
          </div>
          {
            // this.state.rightModalSettings && <div className={`loaderareaduplicate mcaloader loaderAreaUpdatedNavbar`}>
            this.state.rightModalSettings && !this.state.commentsPopup && (
              <div
                className={`loaderareaduplicate mcaloader loaderAreaUpdatedNavbar ${this.state.showBhodiAbouts ? "loaderAreaUpdateMustGray" : ""
                  } ${this.state.showBhodiFaqs ? "loaderAreaUpdateMustGray" : ""
                  } ${this.state.userInfoRight ? "loaderAreaUpdateMustGray" : ""
                  } ${this.state.voucherSettings ? "loaderAreaUpdateMustGray" : ""
                  } ${this.state.productSettings ? "loaderAreaUpdateMustGray" : ""
                  } ${this.state.privacyRight ? "loaderAreaUpdateMustGray" : ""
                  } ${this.state.emailPassword ? "loaderAreaUpdateMustGray" : ""
                  } ${this.state.myOrdersRight ? "loaderAreaUpdateMustGray" : ""
                  } ${this.state.allOrdersRight ? "loaderAreaUpdateMustGray" : ""
                  } ${this.state.paymentSettingsRight ? "loaderAreaUpdateMustGray" : ""
                  } ${this.state.administrationRight ? "loaderAreaUpdateMustGray" : ""
                  } ${this.state.barberAdministrationRight ? "loaderAreaUpdateMustGray" : ""
                  } ${this.state.vendorAdministrationRight ? "loaderAreaUpdateMustGray" : ""
                  }`}
              >
                {this.state.userInfoRight && (
                  <div className="modal-create-album modalcreatealbumchangedheader changedheadermodalcreatealbum">
                    {!this.state.isDesktop && (
                      <ArrowBackIcon
                        onClick={() => {
                          // to close directly if it's opened via popup
                          if (this.state.openedDirectlyInfos) {
                            this.props.removeFillUpAddress()
                            this.setState({
                              userInfoRight: false,
                              rightModalSettings: false,
                            });
                            return;
                          }
                          this.setState({
                            userInfoRight: false,
                            menuItemsModals: true,
                          });
                        }}
                        style={{
                          position: "absolute",
                          top: 15,
                          left: 10,
                          fontSize: 18,
                        }}
                      />
                    )}
                    <div
                      className="heading-mca"
                      style={{
                        textAlign: "center",
                        position: "relative",
                        padding: "13px",
                      }}
                    >
                      <font>MIJN GEGEVENS</font>
                      <div>
                        <ChevronLeft
                          onClick={() => {
                            const {
                              first_name,
                              last_name,
                              zipCode,
                              street,
                              company_name,
                              kvkCompany,
                              company_website,
                              houseNumber,
                              country,
                              city,
                              phoneNumber,
                              bankAccountNumber,
                              bankName,
                              profileActive,
                              redirect_guest_to_link_via_event,
                              hide_contact_info_on_order
                            } = this.state.currentUserMember;
                            let activatedProfile = false;
                            let redirect_guest_to_link_via_event_new = false;
                            let hide_contact_info_on_order_new = false;
                            if (profileActive) {
                              activatedProfile = true
                            } else {
                              activatedProfile = false
                            }
                            if (redirect_guest_to_link_via_event) {
                              redirect_guest_to_link_via_event_new = true
                            } else {
                              redirect_guest_to_link_via_event_new = false
                            }
                            if (hide_contact_info_on_order) {
                              hide_contact_info_on_order_new = true
                            } else {
                              hide_contact_info_on_order_new = false
                            }
                            if (
                              first_name !== this.state.firstName ||
                              last_name !== this.state.lastName ||
                              zipCode !== this.state.zipCode ||
                              street !== this.state.street ||
                              company_name !== this.state.companyName ||
                              kvkCompany !== this.state.kvkCompany ||
                              houseNumber !== this.state.houseNumber ||
                              company_website !== this.state.company_website ||
                              country !== this.state.country ||
                              city !== this.state.city ||
                              phoneNumber !== this.state.phoneNumber ||
                              bankName !== this.state.bankName ||
                              bankAccountNumber !== this.state.bankAccountNumber ||
                              redirect_guest_to_link_via_event_new !== this.state.redirect_guest_to_link_via_event ||
                              hide_contact_info_on_order_new !== this.state.hide_contact_info_on_order
                            ) {
                              this.setState({ beforeCloseMenuConfirm: true });
                              return false;
                            }

                            if (this.state.openedDirectlyInfos) {
                              this.props.removeFillUpAddress()
                              this.setState({
                                userInfoRight: false,
                                rightModalSettings: false,
                              });
                              return;
                            }
                            this.setState({ userInfoRight: false });
                          }}
                          style={{
                            fontSize: 18,
                          }}
                        />
                        <div className="spacing-between-icons"></div>
                        <CloseIcon
                          onClick={() => {
                            let element = document.getElementById("footermain");
                            if (element) {
                              element.style.display = "flex";
                            }
                            if (this.state.openedDirectlyInfos) {
                              this.props.removeFillUpAddress()
                              this.setState({
                                userInfoRight: false,
                                rightModalSettings: false,
                              });
                              return;
                            }
                            this.setState({
                              rightModalSettings: false,
                              privacyRight: false,
                              channelPageRight: false,
                              userInfoRight: false,
                              shopSettings: false,
                              voucherSettings: false,
                              emailPassword: false,
                              myOrdersRight: false,
                              allOrdersRight: false,
                              showBhodiFaqs: false,
                              showBhodiAbouts: false,
                            });
                            // this.setState({ rightModalSettings: false, privacyRight: false, channelPageRight: false, userInfoRight: false, shopSettings: false, voucherSettings: false, emailPassword: false, myOrdersRight: false, allOrdersRight: false, showBhodiFaqs: false, showBhodiAbouts: false })
                          }}
                          style={{
                            fontSize: 18,
                          }}
                        />
                        {/* {this.state.savtBtnText === "SAVE" ? (
                        <AiOutlineSave
                          onClick={() => {
                            this.saveInfo();
                          }}
                          style={{
                            position: "absolute",
                            top: "50%",
                            transform: "translateY(-50%)",
                            right: 40,
                            fontSize: 18,
                          }}
                        />
                      ) : (
                        <AiOutlineCheckSquare
                          style={{
                            position: "absolute",
                            top: "50%",
                            transform: "translateY(-50%)",
                            right: 40,
                            fontSize: 18,
                          }}
                        />
                      )} */}
                      </div>
                    </div>
                    <div className="body-mca groupsettingsbody mcasettingsupdatednavbar">
                      <div className="form-body-mca">
                        <div className="inner-mca-settings">
                          <div
                            className="mca-settings-list"
                            style={{
                              flexDirection: "column",
                              backgroundColor: "transparent",
                              width: "90%",
                              margin: "10px auto",
                            }}
                          >
                            <div
                              className="wrapperforfullwidth"
                              style={{ position: "relative" }}
                            >
                              <div
                                className="right-mcls"
                                style={{ color: "white", margin: 0 }}
                              >
                                <div className="form-profile">
                                  <label>Voornaam</label>
                                  <input
                                    type="text"
                                    value={this.state.firstName}
                                    onChange={(e) =>
                                      this.setState({
                                        firstName: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                              <div
                                className="right-mcls"
                                style={{ color: "white", margin: 0 }}
                              >
                                <div className="form-profile">
                                  <label>Achternaam</label>
                                  <input
                                    type="text"
                                    value={this.state.lastName}
                                    onChange={(e) =>
                                      this.setState({
                                        lastName: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                              <div
                                className="right-mcls"
                                style={{ color: "white", margin: 0 }}
                              >
                                <div className="form-profile">
                                  <label>Bedrijfsnaam</label>
                                  <input
                                    type="text"
                                    value={this.state.companyName}
                                    onChange={(e) => {
                                      // this.state.currentUserMember.company_name = e.target.value
                                      this.setState({
                                        companyName: e.target.value,
                                        currentUserMember:
                                          this.state.currentUserMember,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div
                                className="right-mcls"
                                style={{ color: "white", margin: 0 }}
                              >
                                <div className="form-profile">
                                  <label>KVK</label>
                                  <input
                                    type="text"
                                    value={this.state.kvkCompany}
                                    onChange={(e) => {
                                      this.setState({
                                        kvkCompany: e.target.value,
                                        currentUserMember:
                                          this.state.currentUserMember,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div
                                className="right-mcls"
                                style={{ color: "white", margin: 0 }}
                              >
                                {/* <div className="form-profile">
                                                        <label>Zakelijke e-mail</label>
                                                        <input type="text" value={this.state.companyEmail} onChange={(e) => this.setState({ companyEmail: e.target.value })} />
                                                    </div> */}
                                <div className="form-profile">
                                  <label>Website</label>
                                  <input
                                    type="text"
                                    value={this.state.company_website}
                                    onChange={(e) => {
                                      // this.state.currentUserMember.company_website = e.target.value
                                      this.setState({
                                        company_website: e.target.value,
                                        currentUserMember:
                                          this.state.currentUserMember,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              {/* <div className="right-mcls" style={{ color: "white", margin: 0 }}>
                                                <div className="form-profile">
                                                    <label>Zakelijke e-mail</label>
                                                    <input type="text" value={this.state.companyEmail} onChange={(e) => this.setState({ companyEmail: e.target.value })} />
                                                </div>
                                            </div> */}
                              <div
                                className="right-mcls"
                                style={{ color: "white", margin: 0 }}
                              >
                                <div className="form-profile">
                                  <label>Straatnaam</label>
                                  <input
                                    type="text"
                                    value={this.state.street}
                                    onChange={(e) =>
                                      this.setState({ street: e.target.value })
                                    }
                                  />
                                </div>
                              </div>
                              <div
                                className="right-mcls"
                                style={{ color: "white", margin: 0 }}
                              >
                                <div className="form-profile">
                                  <label>Huisnummer</label>
                                  <input
                                    type="text"
                                    value={this.state.houseNumber}
                                    onChange={(e) =>
                                      this.setState({
                                        houseNumber: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                              <div
                                className="right-mcls"
                                style={{ color: "white", margin: 0 }}
                              >
                                <div className="form-profile">
                                  <label>Postcode</label>
                                  <input
                                    type="text"
                                    value={this.state.zipCode}
                                    onChange={(e) =>
                                      this.setState({ zipCode: e.target.value })
                                    }
                                  />
                                </div>
                              </div>
                              <div
                                className="right-mcls"
                                style={{ color: "white", margin: 0 }}
                              >
                                <div className="form-profile">
                                  <label>Plaatsnaam</label>
                                  <input
                                    type="text"
                                    value={this.state.city}
                                    onChange={(e) =>
                                      this.setState({ city: e.target.value })
                                    }
                                  />
                                </div>
                              </div>
                              <div
                                className="right-mcls"
                                style={{ color: "white", margin: 0 }}
                              >
                                <div className="form-profile">
                                  <label>Land</label>
                                  <input
                                    type="text"
                                    value={this.state.country}
                                    onChange={(e) =>
                                      this.setState({ country: e.target.value })
                                    }
                                  />
                                </div>
                              </div>
                              <div
                                className="right-mcls"
                                style={{ color: "white", margin: 0 }}
                              >
                                <div className="form-profile">
                                  <label>Telefoonnummer</label>
                                  <input
                                    type="text"
                                    value={this.state.phoneNumber}
                                    onChange={(e) =>
                                      this.setState({
                                        phoneNumber: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                              <div
                                className="right-mcls"
                                style={{ color: "white", margin: 0 }}
                              >
                                <div className="form-profile">
                                  <label>Naam rekeninghouder</label>
                                  <input
                                    type="text"
                                    value={this.state.bankName}
                                    onChange={(e) =>
                                      this.setState({
                                        bankName: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                              <div
                                className="right-mcls"
                                style={{ color: "white", margin: 0 }}
                              >
                                <div className="form-profile">
                                  <label>Bankrekeningnummer</label>
                                  <input
                                    type="text"
                                    value={this.state.bankAccountNumber}
                                    onChange={(e) =>
                                      this.setState({
                                        bankAccountNumber: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                              <div
                                className="right-mcls"
                                style={{ color: "#1C1C1C" }}
                              >
                                <Switch
                                  color="primary"
                                  inputProps={{
                                    "aria-label": "primary checkbox",
                                  }}
                                  checked={this.state.profileActive}
                                  onClick={() => {
                                    this.setState({
                                      profileActive:
                                        !this.state.profileActive,
                                    });
                                  }}
                                />
                                {this.state.profileActive ? (
                                  <font>Profiel actief</font>
                                ) : (
                                  <font>Profiel inactief</font>
                                )}
                              </div>
                              <div
                                className="right-mcls"
                                style={{ color: "#1C1C1C" }}
                              >
                                <Switch
                                  color="primary"
                                  inputProps={{
                                    "aria-label": "primary checkbox",
                                  }}
                                  checked={this.state.redirect_guest_to_link_via_event}
                                  onClick={() => {
                                    this.setState({
                                      redirect_guest_to_link_via_event:
                                        !this.state.redirect_guest_to_link_via_event,
                                    });
                                  }}
                                />
                                <font>Guest redirect payment link</font>
                              </div>
                              <div
                                className="right-mcls"
                                style={{ color: "#1C1C1C" }}
                              >
                                <Switch
                                  color="primary"
                                  inputProps={{
                                    "aria-label": "primary checkbox",
                                  }}
                                  checked={this.state.hide_contact_info_on_order}
                                  onClick={() => {
                                    this.setState({
                                      hide_contact_info_on_order:
                                        !this.state.hide_contact_info_on_order,
                                    });
                                  }}
                                />
                                <font>Hide Contact Info On Orders</font>
                              </div>
                              {/* <div
                                className="right-mcls"
                                style={{ color: "white", margin: 0 }}
                              >
                                <div className="form-profile">
                                  <label>Profiel Actief</label>
                                  <Switch
                                    color="primary"
                                    inputProps={{
                                      "aria-label": "primary checkbox",
                                    }}
                                    checked={this.state.checkedHideFriendsInfo}
                                    onClick={() => {
                                      this.setState({
                                        checkedHideFriendsInfo:
                                          !this.state.checkedHideFriendsInfo,
                                      });
                                    }}
                                  />
                                </div>
                              </div> */}
                              <div
                                className="btn-save-rmcls btnsaveupdateddesktop"
                                style={{ position: "absolute", bottom: -50 }}
                              >
                                {/* {this.state.deleteBtnText !== "SAVE" ? (
                                  <button
                                    style={{
                                      position: "absolute",
                                      left: 0,
                                      backgroundColor: "red",
                                    }}
                                  >
                                    <i class="fa fa-spinner fa-spin"></i>{" "}
                                    DELETING
                                  </button>
                                ) : (
                                  <button
                                    style={{
                                      position: "absolute",
                                      left: 0,
                                      backgroundColor: "red",
                                    }}
                                    onClick={() =>
                                      this.setState({ verwijderPopupDel: true })
                                    }
                                  >
                                    Verwijder account
                                  </button>
                                )} */}
                                {/* {this.state.deleteBtnText !== "SAVE" ? <button style={{ position: "absolute", left: 0, backgroundColor: "red" }}><i class="fa fa-spinner fa-spin"></i> DELETING</button> : <button style={{ position: "absolute", left: 0, backgroundColor: "red" }} onClick={this.deleteCurrentUser.bind(this)}>Verwijder account</button>} */}
                                {/* {this.state.deleteBtnText !== "SAVE" ? this.state.showDelete && <button style={{ position: "absolute", left: 0, backgroundColor: "red" }}><i class="fa fa-spinner fa-spin"></i> DELETING</button> : this.state.showDelete && <button style={{ position: "absolute", left: 0, backgroundColor: "red" }} onClick={this.deleteCurrentUser.bind(this)}>Verwijder account</button>} */}
                                {this.state.savtBtnText === "SAVE" ? <button onClick={() => {
                                  if (this.state.savtBtnText === "SAVE") {
                                    this.saveInfo()
                                  }
                                }}>OPSLAAN</button> : <button><i class="fa fa-spinner fa-spin"></i> OPSLAAN</button>}
                                {/* <button style={{ marginLeft: 10 }} onClick={() => {
                                                        let element = document.getElementById("footermain")
                                                        if (element) {
                                                            element.style.display = "block"
                                                        }
                                                        this.setState({ rightModalSettings: false })
                                                    }}>SLUITEN</button> */}
                              </div>
                              <div className="delete-account-content" style={{ width: "100%", padding: 5, margin: "20px auto" }}>
                                <p style={{ color: "black", fontWeight: "bold" }}>Het verwijderen van je account betekent:</p>
                                <p style={{ color: "black" }}>Dat je alle gegevens en content in dit account kwijtraakt. Hierna kun je niet meer inloggen met dit account.</p>
                                <div className="red-button-verwijder">
                                  {this.state.deleteBtnText !== "SAVE" ? (
                                    <button
                                      style={{ backgroundColor: "red", minWidth: 170 }}
                                    >
                                      <i class="fa fa-spinner fa-spin"></i> DELETING
                                    </button>
                                  ) : (
                                    <button
                                      style={{ backgroundColor: "red", minWidth: 170 }}
                                      onClick={() =>
                                        this.setState({ verwijderPopupDel: true })
                                      }
                                    >
                                      Verwijder account
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {this.state.subscriptionOfExclusivesRight && (
                  <div className="modal-create-album modalcreatealbumchangedheader changedheadermodalcreatealbum">
                    {!this.state.isDesktop && (
                      <ArrowBackIcon
                        onClick={() => {
                          this.setState({
                            userInfoRight: false,
                            menuItemsModals: true,
                          });
                        }}
                        style={{
                          position: "absolute",
                          top: 15,
                          left: 10,
                          fontSize: 18,
                        }}
                      />
                    )}
                    <div
                      className="heading-mca"
                      style={{
                        textAlign: "center",
                        position: "relative",
                        padding: "13px",
                      }}
                    >
                      <font>MIJN EXCLUSIVES</font>
                      <div>
                        <ChevronLeft
                          onClick={() => {
                            this.setState({ subscriptionOfExclusivesRight: false });
                          }}
                          style={{
                            fontSize: 18,
                          }}
                        />
                        <div className="spacing-between-icons"></div>
                        <CloseIcon
                          onClick={() => {
                            let element = document.getElementById("footermain");
                            if (element) {
                              element.style.display = "flex";
                            }
                            this.setState({
                              rightModalSettings: false,
                              subscriptionOfExclusivesRight: false,
                            });
                            // this.setState({ rightModalSettings: false, privacyRight: false, channelPageRight: false, userInfoRight: false, shopSettings: false, voucherSettings: false, emailPassword: false, myOrdersRight: false, allOrdersRight: false, showBhodiFaqs: false, showBhodiAbouts: false })
                          }}
                          style={{
                            fontSize: 18,
                          }}
                        />
                      </div>
                    </div>
                    <div className="body-mca groupsettingsbody mcasettingsupdatednavbar">
                      <div className="form-body-mca">
                        <div className="inner-mca-settings">
                          <div
                            className="mca-settings-list"
                            style={{
                              flexDirection: "column",
                              backgroundColor: "transparent",
                              width: "90%",
                              margin: "10px auto",
                            }}
                          >
                            <div
                              className="wrapperforfullwidth"
                              style={{ position: "relative" }}
                            >
                              <MySubcribedExclusives />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {this.state.voucherSettings && (
                  <div className="modal-create-album modalcreatealbumchangedheader changedheadermodalcreatealbum changedheadermodallabelnomargin" style={{ width: 400 }}>
                    {!this.state.isDesktop && (
                      <ArrowBackIcon
                        onClick={() => {
                          this.setState({
                            voucherSettings: false,
                            menuItemsModals: true,
                          });
                        }}
                        style={{
                          position: "absolute",
                          top: 15,
                          left: 10,
                          fontSize: 18,
                        }}
                      />
                    )}
                    <div
                      className="heading-mca"
                      style={{
                        textAlign: "center",
                        position: "relative",
                        padding: "13px",
                      }}
                    >
                      <font>VOUCHER INSTELLINGEN</font>
                      <div>
                        <ChevronLeft
                          onClick={() => {
                            let {
                              useOfVoucherLocalEvent,
                              useOfVoucherKortingsVoucher,
                              useOfVoucherWebShop,
                            } = this.state.currentUserMember;
                            if (
                              useOfVoucherLocalEvent === undefined ||
                              useOfVoucherLocalEvent === null
                            ) {
                              useOfVoucherLocalEvent = "";
                            }
                            if (
                              useOfVoucherKortingsVoucher === undefined ||
                              useOfVoucherKortingsVoucher === null
                            ) {
                              useOfVoucherKortingsVoucher = "";
                            }
                            if (
                              useOfVoucherWebShop === undefined ||
                              useOfVoucherWebShop === null
                            ) {
                              useOfVoucherWebShop = "";
                            }
                            let webshopCodeSaloonCompany = ""
                            let syncAdminCodeCompany = true

                            if (this.state.currentUserMember.barber_new) {
                              webshopCodeSaloonCompany = this.state.currentUserMember.webshopCodeSaloon || ""
                              syncAdminCodeCompany = this.state.currentUserMember.syncAdminCode || false
                            }

                            if (useOfVoucherLocalEvent !== this.state.useOfVoucherLocalEvent || useOfVoucherKortingsVoucher !== this.state.useOfVoucherKortingsVoucher || useOfVoucherWebShop !== this.state.useOfVoucherWebShop || webshopCodeSaloonCompany !== this.state.webshopCodeSaloon || syncAdminCodeCompany !== this.state.syncAdminCode) {
                              this.setState({
                                beforeCloseMenuConfirmVoucher: true,
                              });
                              return false;
                            }
                            this.setState({ voucherSettings: false });
                          }}
                          style={{
                            position: "absolute",
                            top: "50%",
                            transform: "translateY(-50%)",
                            left: 10,
                            fontSize: 18,
                          }}
                        />
                        <div className="spacing-between-icons"></div>
                        <CloseIcon
                          onClick={() => {
                            let element = document.getElementById("footermain");
                            if (element) {
                              element.style.display = "flex";
                            }
                            this.setState({
                              rightModalSettings: false,
                              privacyRight: false,
                              channelPageRight: false,
                              userInfoRight: false,
                              shopSettings: false,
                              voucherSettings: false,
                              emailPassword: false,
                              myOrdersRight: false,
                              allOrdersRight: false,
                              showBhodiFaqs: false,
                              showBhodiAbouts: false,
                            });
                            // this.setState({ voucherSettings: false })
                          }}
                          style={{
                            position: "absolute",
                            top: "50%",
                            transform: "translateY(-50%)",
                            right: 10,
                            fontSize: 18,
                          }}
                        />
                      </div>
                      {/* {this.state.savtBtnText === "SAVE" ? (
                        <AiOutlineSave
                          onClick={() => {
                            this.saveVoucherSettings();
                          }}
                          style={{
                            position: "absolute",
                            top: "50%",
                            transform: "translateY(-50%)",
                            right: 40,
                            fontSize: 18,
                          }}
                        />
                      ) : (
                        <AiOutlineCheckSquare
                          style={{
                            position: "absolute",
                            top: "50%",
                            transform: "translateY(-50%)",
                            right: 40,
                            fontSize: 18,
                          }}
                        />
                      )} */}
                    </div>
                    <div className="body-mca groupsettingsbody mcasettingsupdatednavbar">
                      <div className="form-body-mca formbodyvouchersettings">
                        <div className="inner-mca-settings">
                          <div
                            className="mca-settings-list"
                            style={{
                              flexDirection: "column",
                              backgroundColor: "transparent",
                              width: "90%",
                              margin: "10px auto",
                            }}
                          >
                            <div
                              className="wrapperforfullwidth vouchersettingswrapperform"
                              style={{
                                alignItems: "flex-start",
                                position: "relative",
                              }}
                            >
                              <div
                                className="right-mcls"
                                style={{ color: "white", margin: 0 }}
                              >
                                <div
                                  className="form-profile"
                                  style={{ position: "relative" }}
                                >
                                  <div
                                    style={{
                                      width: "100%",
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <label>Voorwaarden</label>
                                    {/* {this.state.currentUserMember.requirement_file && <label style={{ marginLeft: 22 }}>{this.state.currentUserMember.file_name}</label>} */}
                                    {!this.state.currentUserMember
                                      .requirement_file && (
                                        <label style={{ position: "relative", width: "auto" }}>
                                          <PublishIcon style={{ margin: 0 }} />
                                          <input
                                            type="file"
                                            style={{
                                              position: "absolute",
                                              width: "100%",
                                              height: "100%",
                                              top: 0,
                                              bottom: 0,
                                              left: 0,
                                              right: 0,
                                              opacity: 0,
                                            }}
                                            accept=".pdf,.doc"
                                            onChange={(e) =>
                                              this.fileUploadpdf(e)
                                            }
                                          />
                                        </label>
                                      )}
                                  </div>
                                  {this.state.currentUserMember
                                    .requirement_file && (
                                      <DeleteIcon
                                        style={{
                                          margin: 0,
                                          color: "black",
                                          position: "absolute",
                                          top: 2,
                                          right: 4,
                                          fontSize: 20,
                                        }}
                                        onClick={this.deletePdf.bind(this)}
                                      />
                                    )}
                                  {!this.state.currentUserMember
                                    .requirement_file ? (
                                    <textarea
                                      value={this.state.termsText}
                                      onChange={(e) =>
                                        this.setState({
                                          termsText: e.target.value,
                                        })
                                      }
                                      style={{ marginTop: 20 }}
                                    ></textarea>
                                  ) : (
                                    <input
                                      value={
                                        this.state.currentUserMember.file_name
                                      }
                                      style={{ marginTop: 20 }}
                                      disabled
                                    />
                                  )}
                                </div>
                              </div>
                              <div
                                className="right-mcls"
                                style={{ color: "white", margin: 0 }}
                              >
                                <div
                                  className="form-profile"
                                  style={{ position: "relative" }}
                                >
                                  <div
                                    style={{
                                      width: "100%",
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <label>Geldigheidsperiode van de voucher</label>
                                  </div>
                                </div>
                              </div>
                              <div className="right-mcls" style={{ marginTop: 0 }}>
                                <div
                                  className="form-profile"
                                  style={{ position: "relative" }}
                                >
                                  <div className='wrapper-select-new'>
                                    <select
                                      value={this.state.expireTypeVoucher}
                                      onChange={(e) => {
                                        this.setState({ expireTypeVoucher: e.target.value, expireDateVoucher: "" })
                                      }}
                                    >
                                      {!this.state.expireTypeVoucher && (<option value=""></option>)}
                                      <option value="daily">Aantal dagen geldig</option>
                                      <option value="weekly">Aantal weken geldig</option>
                                      <option value="monthly">Aantal maanden geldig</option>
                                    </select>
                                    <ExpandMoreIcon
                                      style={{
                                        cursor: "pointer",
                                        position: "absolute",
                                        right: 5,
                                        bottom: 10,
                                        fontSize: 18,
                                        pointerEvents: "none",
                                        pointerEvents: "none",
                                      }}
                                      className='expand-more'
                                    />
                                  </div>
                                </div>
                                <div
                                  className="form-profile kortingnumberdiv"
                                  style={{ position: "relative" }}
                                >
                                  <div className='wrapper-select-new'>
                                    <select
                                      value={this.state.expireDateVoucher}
                                      onChange={(e) => {
                                        this.setState({ expireDateVoucher: e.target.value })
                                      }}
                                    >
                                      {!this.state.expireDateVoucher && (<option value=""></option>)}
                                      {this.state.expireTypeVoucher === "weekly" && [1, 2, 3, 4].map((week) => {
                                        return <option value={week}>{week} {week > 1 ? `weeks` : `week`}</option>
                                      })}
                                      {this.state.expireTypeVoucher === "daily" && [1, 2, 3, 4, 5, 6, 7].map((day) => {
                                        return <option value={day}>{day} {day > 1 ? `days` : `day`}</option>
                                      })}
                                      {this.state.expireTypeVoucher === "monthly" && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month) => {
                                        return <option value={month}>{month} {month > 1 ? `months` : `month`}</option>
                                      })}
                                    </select>
                                    <ExpandMoreIcon
                                      style={{
                                        cursor: "pointer",
                                        position: "absolute",
                                        right: 5,
                                        bottom: 10,
                                        fontSize: 18,
                                        pointerEvents: "none",
                                        pointerEvents: "none",
                                      }}
                                      className='expand-more'
                                    />
                                  </div>
                                </div>
                              </div>
                              {this.state.currentUserHere.barber_new && (<>
                                <div
                                  className="right-mcls"
                                  style={{ color: "white", margin: 0 }}
                                >
                                  <div
                                    className="form-profile"
                                    style={{ position: "relative" }}
                                  >
                                    <div
                                      style={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <label>Webshop code</label>
                                    </div>
                                  </div>
                                </div>
                                <div className="right-mcls" style={{ marginTop: 0 }}>
                                  <div
                                    className="form-profile"
                                    style={{ position: "relative" }}
                                  >
                                    <div className='wrapper-select-new'>
                                      <input
                                        type="text"
                                        value={this.state.webshopCodeSaloon}
                                        onChange={(e) => {
                                          this.setState({ webshopCodeSaloon: e.target.value })
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="right-mcls"
                                  style={{ color: "#1C1C1C" }}
                                >
                                  <Switch
                                    color="primary"
                                    inputProps={{
                                      "aria-label": "primary checkbox",
                                    }}
                                    checked={this.state.syncAdminCode}
                                    onClick={() => {
                                      this.setState({
                                        syncAdminCode: !this.state.syncAdminCode,
                                      });
                                    }}
                                  />
                                  <font>Sync from Admin</font>
                                </div>
                              </>)}
                              <div
                                className="right-mcls"
                                style={{ color: "white", margin: 0 }}
                              ></div>
                              <div
                                className="right-mcls"
                                style={{
                                  color: "white",
                                  margin: 0,
                                  padding: "20px 0px",
                                }}
                              >
                                <div
                                  style={{
                                    width: "100%",
                                    height: 2,
                                    backgroundColor: "#d3d3d3",
                                  }}
                                ></div>
                              </div>
                              <div
                                className="right-mcls"
                                style={{ color: "white", margin: 0 }}
                              ></div>
                              <div
                                className="right-mcls"
                                style={{ color: "white", margin: 0 }}
                              ></div>
                              <div
                                className="right-mcls"
                                style={{ color: "white", margin: 0 }}
                              >
                                <div className="form-profile">
                                  <div
                                    style={{
                                      width: "100%",
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    <label style={{ marginBottom: 0 }}>
                                      Gebruik events
                                    </label>
                                    {this.state.gebruikLastMinuteDown ? (
                                      <KeyboardArrowUpIcon
                                        style={{
                                          color: "black",
                                          cursor: "pointer",
                                          margin: 0,
                                        }}
                                        onClick={() =>
                                          this.setState({
                                            gebruikLastMinuteDown: false,
                                          })
                                        }
                                      />
                                    ) : (
                                      <KeyboardArrowDownIcon
                                        style={{
                                          color: "black",
                                          cursor: "pointer",
                                          margin: 0,
                                        }}
                                        onClick={() =>
                                          this.setState({
                                            gebruikCharityDown: false,
                                            gebruikGiveAwayDown: false,
                                            gebruikLastMinuteDown: true,
                                            gebruikWebShopDown: false,
                                            gebruikVanDown: false,
                                            gebruikKortingsDown: false,
                                            voorwaardenDown: false,
                                          })
                                        }
                                      />
                                    )}
                                  </div>
                                  {this.state.gebruikLastMinuteDown && (
                                    <textarea
                                      style={{ marginTop: 20 }}
                                      value={this.state.useOfVoucherLocalEvent}
                                      onChange={(e) =>
                                        this.setState({
                                          useOfVoucherLocalEvent:
                                            e.target.value,
                                        })
                                      }
                                    ></textarea>
                                  )}
                                </div>
                              </div>
                              <div
                                className="right-mcls"
                                style={{ color: "white", margin: 0 }}
                              >
                                <div className="form-profile">
                                  <div
                                    style={{
                                      width: "100%",
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    <label style={{ marginBottom: 0 }}>
                                      Gebruik KortingsVoucher
                                    </label>
                                    {this.state.gebruikKortingsDown ? (
                                      <KeyboardArrowUpIcon
                                        style={{
                                          color: "black",
                                          cursor: "pointer",
                                          margin: 0,
                                        }}
                                        onClick={() =>
                                          this.setState({
                                            gebruikKortingsDown: false,
                                          })
                                        }
                                      />
                                    ) : (
                                      <KeyboardArrowDownIcon
                                        style={{
                                          color: "black",
                                          cursor: "pointer",
                                          margin: 0,
                                        }}
                                        onClick={() =>
                                          this.setState({
                                            gebruikLastMinuteDown: false,
                                            gebruikKortingsDown: true,
                                            gebruikWebShopDown: false,
                                          })
                                        }
                                      />
                                    )}
                                  </div>
                                  {this.state.gebruikKortingsDown && (
                                    <textarea
                                      style={{ marginTop: 20 }}
                                      value={this.state.useOfVoucherKortingsVoucher}
                                      onChange={(e) =>
                                        this.setState({
                                          useOfVoucherKortingsVoucher: e.target.value,
                                        })
                                      }
                                    ></textarea>
                                  )}
                                </div>
                              </div>
                              <div
                                className="right-mcls"
                                style={{ color: "white", margin: 0 }}
                              >
                                <div className="form-profile">
                                  <div
                                    style={{
                                      width: "100%",
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    <label style={{ marginBottom: 0 }}>
                                      Gebruik Webshop
                                    </label>
                                    {this.state.gebruikWebShopDown ? (
                                      <KeyboardArrowUpIcon
                                        style={{
                                          color: "black",
                                          cursor: "pointer",
                                          margin: 0,
                                        }}
                                        onClick={() =>
                                          this.setState({
                                            gebruikWebShopDown: false,
                                          })
                                        }
                                      />
                                    ) : (
                                      <KeyboardArrowDownIcon
                                        style={{
                                          color: "black",
                                          cursor: "pointer",
                                          margin: 0,
                                        }}
                                        onClick={() =>
                                          this.setState({
                                            gebruikLastMinuteDown: false,
                                            gebruikKortingsDown: false,
                                            gebruikWebShopDown: true,
                                          })
                                        }
                                      />
                                    )}
                                  </div>
                                  {this.state.gebruikWebShopDown && (
                                    <textarea
                                      style={{ marginTop: 20 }}
                                      value={this.state.useOfVoucherWebShop}
                                      onChange={(e) =>
                                        this.setState({
                                          useOfVoucherWebShop: e.target.value,
                                        })
                                      }
                                    ></textarea>
                                  )}
                                </div>
                              </div>
                              <div className="btn-save-rmcls btnsaveupdateddesktop" style={{ position: "absolute", paddingTop: 25, marginTop: 30, paddingBottom: 20 }}>
                                {this.state.savtBtnText === "SAVE" ? <button onClick={() => {
                                  if (this.state.savtBtnText === "SAVE") {
                                    this.saveVoucherSettings()
                                  }
                                }}>OPSLAAN</button> : <button><i class="fa fa-spinner fa-spin"></i> OPSLAAN</button>}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {this.state.productSettings && (
                  <div className="modal-create-album modalcreatealbumchangedheader changedheadermodalcreatealbum changedheadermodallabelnomargin" style={{ width: 400 }}>
                    {!this.state.isDesktop && (
                      <ArrowBackIcon
                        onClick={() => {
                          this.setState({
                            productSettings: false,
                            menuItemsModals: true,
                          });
                        }}
                        style={{
                          position: "absolute",
                          top: 15,
                          left: 10,
                          fontSize: 18,
                        }}
                      />
                    )}
                    <div
                      className="heading-mca"
                      style={{
                        textAlign: "center",
                        position: "relative",
                        padding: "13px",
                      }}
                    >
                      <font>PRODUCT INSTELLINGEN</font>
                      <div>
                        <ChevronLeft
                          onClick={() => {
                            let {
                              maxProductsToShow,
                              intervalTimer,
                            } = this.state.currentUserMember.productsInfo;
                            if (
                              maxProductsToShow === undefined ||
                              maxProductsToShow === null
                            ) {
                              maxProductsToShow = 4;
                            }
                            if (
                              intervalTimer === undefined ||
                              intervalTimer === null
                            ) {
                              intervalTimer = "";
                            }
                            if (maxProductsToShow !== this.state.maxProductsToShow || intervalTimer !== this.state.intervalTimer) {
                              this.setState({
                                beforeCloseMenuConfirmProducten: true,
                              });
                              return false;
                            }
                            this.setState({ productSettings: false });
                          }}
                          style={{
                            position: "absolute",
                            top: "50%",
                            transform: "translateY(-50%)",
                            left: 10,
                            fontSize: 18,
                          }}
                        />
                        <div className="spacing-between-icons"></div>
                        <CloseIcon
                          onClick={() => {
                            let element = document.getElementById("footermain");
                            if (element) {
                              element.style.display = "flex";
                            }
                            this.setState({
                              rightModalSettings: false,
                              productSettings: false,
                            });
                            // this.setState({ voucherSettings: false })
                          }}
                          style={{
                            position: "absolute",
                            top: "50%",
                            transform: "translateY(-50%)",
                            right: 10,
                            fontSize: 18,
                          }}
                        />
                      </div>
                    </div>
                    <div className="body-mca groupsettingsbody mcasettingsupdatednavbar">
                      <div className="form-body-mca formbodyvouchersettings">
                        <div className="inner-mca-settings">
                          <div
                            className="mca-settings-list"
                            style={{
                              flexDirection: "column",
                              backgroundColor: "transparent",
                              width: "90%",
                              margin: "10px auto",
                            }}
                          >
                            <div
                              className="wrapperforfullwidth vouchersettingswrapperform"
                              style={{
                                alignItems: "flex-start",
                                position: "relative",
                              }}
                            >
                              <div className="right-mcls" style={{ marginTop: 0 }}>
                                <div
                                  className="form-profile"
                                  style={{ position: "relative" }}
                                >
                                  <div className='wrapper-select-new'>
                                    <select
                                      value={this.state.maxProductsToShow}
                                      onChange={(e) => {
                                        this.setState({ maxProductsToShow: e.target.value })
                                      }}
                                    >
                                      {[4, 8, 16, 24].map((e) => {
                                        return (<option value={e} key={Math.random()}>{e}</option>)
                                      })}
                                    </select>
                                    <ExpandMoreIcon
                                      style={{
                                        cursor: "pointer",
                                        position: "absolute",
                                        right: 5,
                                        bottom: 10,
                                        fontSize: 18,
                                        pointerEvents: "none",
                                        pointerEvents: "none",
                                      }}
                                      className='expand-more'
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="right-mcls" style={{ marginTop: 0 }}>
                                <div
                                  className="form-profile kortingnumberdiv"
                                  style={{ position: "relative" }}
                                >
                                  <div className='wrapper-select-new'>
                                    <select
                                      value={this.state.intervalTimer}
                                      onChange={(e) => {
                                        this.setState({ intervalTimer: e.target.value })
                                      }}
                                    >
                                      {!this.state.intervalTimer && (<option value="" disabled hidden>Selecteer</option>)}
                                      {[10, 20, 30, 40, 50].map((e) => {
                                        return (<option value={`${e} seconds`}>{e} seconds</option>)
                                      })}
                                      <option value="1 minute">1 minute</option>
                                      {[2, 3, 4, 5, 6, 7, 8, 9, 10].map((e) => {
                                        return (<option value={`${e} minutes`}>{e} minutes</option>)
                                      })}
                                      <option value="1 hour">1 hour</option>
                                      {[2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24].map((e) => {
                                        return (<option value={`${e} hours`}>{e} hours</option>)
                                      })}
                                    </select>
                                    <ExpandMoreIcon
                                      style={{
                                        cursor: "pointer",
                                        position: "absolute",
                                        right: 5,
                                        bottom: 10,
                                        fontSize: 18,
                                        pointerEvents: "none",
                                        pointerEvents: "none",
                                      }}
                                      className='expand-more'
                                    />
                                  </div>
                                </div>
                              </div>
                              <div
                                className="right-mcls"
                                style={{ color: "white", margin: 0 }}
                              ></div>
                              <div className="btn-save-rmcls btnsaveupdateddesktop" style={{ position: "absolute", paddingTop: 25, marginTop: 30, paddingBottom: 20 }}>
                                {this.state.savtBtnText === "SAVE" ? <button onClick={() => {
                                  if (this.state.savtBtnText === "SAVE") {
                                    this.saveProductSettings()
                                  }
                                }}>OPSLAAN</button> : <button><i class="fa fa-spinner fa-spin"></i> OPSLAAN</button>}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {this.state.emailPassword && (
                  <div className="modal-create-album modalcreatealbumchangedheader changedheadermodalcreatealbum" style={{ width: 400 }}>
                    {!this.state.isDesktop && (
                      <ArrowBackIcon
                        onClick={() => {
                          this.setState({
                            emailPassword: false,
                            menuItemsModals: true,
                          });
                        }}
                        style={{
                          position: "absolute",
                          top: 15,
                          left: 10,
                          fontSize: 18,
                        }}
                      />
                    )}
                    <div
                      className="heading-mca"
                      style={{
                        textAlign: "center",
                        position: "relative",
                        padding: "13px",
                      }}
                    >
                      <font>INLOGGEGEVENS</font>
                      <div>
                        <ChevronLeft
                          onClick={() => {
                            const { email, user_name_id } =
                              this.state.currentUserMember;
                            if (
                              email !== this.state.email ||
                              user_name_id !== this.state.url ||
                              this.state.currentPassword ||
                              this.state.password ||
                              this.state.confirmPassword
                            ) {
                              this.setState({
                                beforeCloseMenuConfirmInloggevens: true,
                              });
                              return false;
                            }
                            this.setState({ emailPassword: false });
                          }}
                          style={{
                            position: "absolute",
                            top: "50%",
                            transform: "translateY(-50%)",
                            left: 10,
                            fontSize: 18,
                          }}
                        />
                        <div className="spacing-between-icons"></div>
                        <CloseIcon
                          onClick={() => {
                            let element = document.getElementById("footermain");
                            if (element) {
                              element.style.display = "flex";
                            }
                            this.setState({
                              rightModalSettings: false,
                              privacyRight: false,
                              channelPageRight: false,
                              userInfoRight: false,
                              shopSettings: false,
                              voucherSettings: false,
                              emailPassword: false,
                              myOrdersRight: false,
                              allOrdersRight: false,
                              showBhodiFaqs: false,
                              showBhodiAbouts: false,
                            });
                            // this.setState({ emailPassword: false })
                          }}
                          style={{
                            position: "absolute",
                            top: "50%",
                            transform: "translateY(-50%)",
                            right: 10,
                            fontSize: 18,
                          }}
                        />
                      </div>
                      {/* {this.state.savtBtnText === "SAVE" ? (
                        <AiOutlineSave
                          onClick={() => {
                            this.saveEmailPassword();
                          }}
                          style={{
                            position: "absolute",
                            top: "50%",
                            transform: "translateY(-50%)",
                            right: 40,
                            fontSize: 18,
                          }}
                        />
                      ) : (
                        <AiOutlineCheckSquare
                          style={{
                            position: "absolute",
                            top: "50%",
                            transform: "translateY(-50%)",
                            right: 40,
                            fontSize: 18,
                          }}
                        />
                      )} */}
                      {/* <CloseIcon onClick={() => {
                                    this.setState({ emailPassword: false })
                                }} style={{ position: "absolute", top: "50%", transform: "translateY(-50%)", right: 10, fontSize: 24 }} /> */}
                    </div>
                    <div className="body-mca groupsettingsbody mcasettingsupdatednavbar">
                      <div className="form-body-mca">
                        <div className="inner-mca-settings">
                          <div
                            className="mca-settings-list"
                            style={{
                              flexDirection: "column",
                              backgroundColor: "transparent",
                              width: "90%",
                              margin: "10px auto",
                            }}
                          >
                            {/* <div className="top-mcls-one">
                                                <p>
                                                    <AccountCircleOutlinedIcon />
                                                    INLOGGEGEVENS
                                                </p>
                                            </div> */}
                            <div
                              className="wrapperforfullwidth"
                              style={{ position: "relative" }}
                            >
                              <div
                                className="right-mcls"
                                style={{ color: "white", margin: 0 }}
                              >
                                <div className="form-profile">
                                  <label>Gebruikersnaam</label>
                                  <input
                                    type="text"
                                    value={this.state.email}
                                    onChange={(e) =>
                                      this.setState({ email: e.target.value })
                                    }
                                  />
                                  {this.state.emailAlreadyTooked && (
                                    <p style={{ color: "red" }}>
                                      Email is already tooked!
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div
                                className="right-mcls"
                                style={{ color: "white", margin: 0 }}
                              ></div>
                              <div
                                className="right-mcls"
                                style={{ color: "white" }}
                              >
                                <div className="form-profile">
                                  <label>
                                    Link naar je profielpagina:
                                    www.bhodi.com/company/{this.state.url}
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="Page url"
                                    value={this.state.url}
                                    onChange={(e) => {
                                      let converted = e.target.value
                                        .split(" ")
                                        .join("");
                                      this.setState({
                                        url: converted.toLowerCase(),
                                      });
                                    }}
                                  />
                                  {this.state.urlNotAvailable && (
                                    <p style={{ color: "red" }}>
                                      Sorry! this URL is taken
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div
                                className="right-mcls"
                                style={{ color: "white", margin: 0 }}
                              >
                                <div className="form-profile">
                                  <label
                                    style={{ marginBottom: 10, width: "100%" }}
                                  >
                                    Wachtwoord wijzigen:{" "}
                                  </label>
                                  <label>Huidig wachtwoord</label>
                                  <div className="toonpasswordcontainer">
                                    <input
                                      type={
                                        !this.state.showcurrentPassword
                                          ? "password"
                                          : "text"
                                      }
                                      autoComplete="new-password"
                                      value={this.state.currentPassword}
                                      onChange={(e) =>
                                        this.setState({
                                          currentPassword: e.target.value,
                                        })
                                      }
                                    />
                                    <label
                                      style={{ color: "#a9a7a7" }}
                                      onClick={() => {
                                        this.setState({
                                          showcurrentPassword:
                                            !this.state.showcurrentPassword,
                                        });
                                      }}
                                    >
                                      toon
                                    </label>
                                  </div>
                                  {this.state.fillCurrentPassword && (
                                    <p style={{ color: "red" }}>
                                      Enter your right correct password!
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div
                                className="right-mcls"
                                style={{ color: "white", margin: 0 }}
                              ></div>
                              <div
                                className="right-mcls"
                                style={{ color: "white", margin: 0 }}
                              >
                                <div className="form-profile">
                                  <label>Nieuw wachtwoord</label>
                                  <div className="toonpasswordcontainer">
                                    <input
                                      type={
                                        !this.state.showPassword
                                          ? "password"
                                          : "text"
                                      }
                                      value={this.state.password}
                                      autoComplete="new-password"
                                      onChange={(e) =>
                                        this.setState({
                                          password: e.target.value,
                                        })
                                      }
                                    />
                                    <label
                                      style={{ color: "#a9a7a7" }}
                                      onClick={() => {
                                        this.setState({
                                          showPassword:
                                            !this.state.showPassword,
                                        });
                                      }}
                                    >
                                      toon
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="right-mcls"
                                style={{ color: "white", margin: 0 }}
                              ></div>
                              <div
                                className="right-mcls"
                                style={{ color: "white", margin: 0 }}
                              >
                                <div className="form-profile">
                                  <label>Herhaal wachtwoord</label>
                                  <div className="toonpasswordcontainer">
                                    <input
                                      type={
                                        !this.state.showconfirmPassword
                                          ? "password"
                                          : "text"
                                      }
                                      autoComplete="new-password"
                                      value={this.state.confirmPassword}
                                      onChange={(e) =>
                                        this.setState({
                                          confirmPassword: e.target.value,
                                        })
                                      }
                                    />
                                    <label
                                      style={{ color: "#a9a7a7" }}
                                      onClick={() => {
                                        this.setState({
                                          showconfirmPassword:
                                            !this.state.showconfirmPassword,
                                        });
                                      }}
                                    >
                                      toon
                                    </label>
                                  </div>
                                  {this.state.changedPassword && (
                                    <p style={{ color: "red" }}>
                                      Password and Confirm Password should be
                                      same
                                    </p>
                                  )}
                                </div>
                              </div>
                              {/* <div className="btn-save-rmcls" style={{ position: "absolute", bottom: -50 }}>
                                                    <button style={{ marginRight: 10 }} onClick={() => {
                                                        this.setState({ currentPassword: "", password: "", confirmPassword: "", changePassword: "", fillCurrentPassword: "" })
                                                    }}>ANNULEREN</button>
                                                </div> */}
                              <div className="btn-save-rmcls btnsaveupdateddesktop" style={{ position: "absolute", paddingTop: 25, marginTop: 30, paddingBottom: 20 }}>
                                {this.state.savtBtnText === "SAVE" ? <button onClick={() => {
                                  if (this.state.savtBtnText === "SAVE") {
                                    this.saveEmailPassword()
                                  }
                                }}>OPSLAAN</button> : <button><i class="fa fa-spinner fa-spin"></i> OPSLAAN</button>}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {this.state.allOrdersRight && (
                  <div className="modal-create-album">
                    {/* {!this.state.isDesktop && <ArrowBackIcon onClick={() => {
                                this.setState({ allOrdersRight: false, menuItemsModals: true })
                            }} style={{ position: "absolute", top: 15, left: 10, fontSize: 18 }} />}
                            {this.state.showCartOrder && <OrdersCartUpdated />} */}
                    <RightMenuIncomingOrders
                      closePopup={() => {
                        this.setState({ allOrdersRight: false });
                      }}
                      closePopupComplete={() => {
                        let element = document.getElementById("footermain");
                        if (element) {
                          element.style.display = "flex";
                        }
                        this.setState({
                          rightModalSettings: false,
                          privacyRight: false,
                          channelPageRight: false,
                          userInfoRight: false,
                          shopSettings: false,
                          voucherSettings: false,
                          emailPassword: false,
                          myOrdersRight: false,
                          allOrdersRight: false,
                          showBhodiFaqs: false,
                          showBhodiAbouts: false,
                        });
                      }}
                    />
                  </div>
                )}
                {this.state.paymentSettingsRight && (
                  <div className="modal-create-album modalcreatealbumchangedheader changedheadermodalcreatealbum">
                    {!this.state.isDesktop && (
                      <ArrowBackIcon
                        onClick={() => {
                          this.setState({
                            userInfoRight: false,
                            menuItemsModals: true,
                          });
                        }}
                        style={{
                          position: "absolute",
                          top: 15,
                          left: 10,
                          fontSize: 18,
                        }}
                      />
                    )}
                    <div
                      className="heading-mca"
                      style={{
                        textAlign: "center",
                        position: "relative",
                        padding: "13px",
                      }}
                    >
                      <font>PAYMENT SETTINGS</font>
                      <div>
                        <ChevronLeft
                          onClick={() => {
                            this.setState({ paymentSettingsRight: false });
                          }}
                          style={{
                            fontSize: 18,
                          }}
                        />
                        <div className="spacing-between-icons"></div>
                        <CloseIcon
                          onClick={() => {
                            let element = document.getElementById("footermain");
                            if (element) {
                              element.style.display = "flex";
                            }
                            this.setState({
                              rightModalSettings: false,
                              privacyRight: false,
                              channelPageRight: false,
                              userInfoRight: false,
                              shopSettings: false,
                              voucherSettings: false,
                              emailPassword: false,
                              myOrdersRight: false,
                              allOrdersRight: false,
                              showBhodiFaqs: false,
                              showBhodiAbouts: false,
                              paymentSettingsRight: false,
                              administrationRight: false,
                              barberAdministrationRight: false,
                              vendorAdministrationRight: false,
                            });
                          }}
                          style={{
                            fontSize: 18,
                          }}
                        />
                      </div>
                    </div>

                    <div className='rightmenuinner-rmmms updatedirghtmenunew newrightmenuglobalgood' style={{ height: "auto" }}>
                      {this.state.currentUserMember.approved_by_admin ? (<div className='content-rmmi-rmms'>
                        <div
                          className="form-miur"
                          style={{ position: "relative" }}
                        >
                          <label>Mollie subscription <div className='green-round-info'>?</div></label>
                          {this.state.currentUserMember.mollie_credentials ? (<div className='wrapper-select-new wrapper-select-green'>
                            <input value="Verbonden" style={{ pointerEvents: "none" }} />
                            <BsCheck2
                              style={{
                                position: "absolute",
                                bottom: 5,
                                right: 5,
                                pointerEvents: "none",
                              }}
                              className='more-right-icon fontsizemsallsvg'
                            />
                            <CloseIcon
                              style={{
                                position: "absolute",
                                bottom: 5,
                                right: 5,
                                // pointerEvents: "none",
                                cursor: "pointer",
                              }}
                              className='expand-more fontsizemsallsvg'
                              onClick={this.disconnectMollie.bind(this)}
                            />
                          </div>) : (<div className='wrapper-select-new wrapper-select-green' onClick={this.connectToMollie.bind(this)}>
                            <input value="Connect your account" readOnly style={{ cursor: "pointer" }} />
                            <BiChevronRight
                              style={{
                                position: "absolute",
                                bottom: 5,
                                right: 5,
                                pointerEvents: "none",
                              }}
                              className='expand-more'
                            />
                          </div>)}
                        </div>
                        <div
                          className="form-miur"
                          style={{ position: "relative" }}
                        >
                          <label>Paypal <div className='green-round-info'>?</div></label>
                          <div className='wrapper-select-new wrapper-select-green'>
                            {/* <input value="Verbonden" /> */}
                            {/* <BsCheck2
                                        style={{
                                            position: "absolute",
                                            bottom: 5,
                                            right: 5,
                                            pointerEvents: "none",
                                        }}
                                        className='more-right-icon fontsizemsallsvg'
                                    />
                                    <CloseIcon
                                        style={{
                                            position: "absolute",
                                            bottom: 5,
                                            right: 5,
                                            pointerEvents: "none",
                                        }}
                                        className='expand-more fontsizemsallsvg'
                                    /> */}
                            {this.state.currentUserMember.paypalInfo ? (<div className='wrapper-select-new wrapper-select-green' >
                              <input value="Verbonden" style={{ pointerEvents: "none" }} />
                              <BsCheck2
                                style={{
                                  position: "absolute",
                                  bottom: 5,
                                  right: 5,
                                  pointerEvents: "none",
                                }}
                                className='more-right-icon fontsizemsallsvg'
                              />
                              <CloseIcon
                                style={{
                                  position: "absolute",
                                  bottom: 5,
                                  right: 5,
                                  // pointerEvents: "none",
                                  cursor: "pointer",
                                }}
                                className='expand-more fontsizemsallsvg'
                                onClick={this.disconnectPaypal.bind(this)}
                              />
                            </div>) : (<div className='wrapper-select-new wrapper-select-green' onClick={this.connectToPaypal.bind(this)}>
                              <input value="Connect your account" readOnly style={{ cursor: "pointer" }} />
                              <BiChevronRight
                                style={{
                                  position: "absolute",
                                  bottom: 5,
                                  right: 5,
                                  pointerEvents: "none",
                                }}
                                className='expand-more'
                              />
                            </div>)}
                          </div>
                        </div>
                        {/* <div className='save-button-main' style={{ marginTop: 40 }}>
                          <button>OPSLAAN</button>
                        </div> */}
                      </div>) : this.state.currentUserMember.pending_admin_approve ? (<div className='content-rmmi-rmms'>
                        <div className='save-button-main' style={{ marginTop: 40 }}>
                          <button>AANVRAAG IN BEHANDELING</button>
                        </div>
                        <div className='save-button-main' style={{ marginTop: 20 }} onClick={() => {
                          firebase.database().ref("users/" + this.state.currentUserMember.user_id + "/pending_admin_approve").remove()
                        }}>
                          <button>CANCEL</button>
                        </div>
                      </div>) : (<div className='content-rmmi-rmms'>
                        <div className='save-button-main' style={{ marginTop: 40 }} onClick={() => {
                          firebase.database().ref("users/" + this.state.currentUserMember.user_id + "/pending_admin_approve").set(true)
                        }}>
                          <button>ACTIVEER BETALINGEN</button>
                        </div>
                      </div>)}
                    </div>
                    {/* <div className="body-mca groupsettingsbody mcasettingsupdatednavbar">
                      <div className="form-body-mca">
                        <div className="inner-mca-settings">
                          <div
                            className="mca-settings-list"
                            style={{
                              flexDirection: "column",
                              backgroundColor: "transparent",
                              width: "90%",
                              margin: "10px auto",
                            }}
                          >
                            <div
                              className="wrapperforfullwidth"
                              style={{ position: "relative" }}
                            >
                              <div className='content-rmmi-rmms'>
                                <div
                                  className="form-miur"
                                  style={{ position: "relative" }}
                                >
                                  <label>Mollie subscription <div className='green-round-info'>?</div></label>
                                  {this.state.currentUserMember.mollie_credentials ? (<div className='wrapper-select-new wrapper-select-green'>
                                    <input value="Verbonden" />
                                    <BsCheck2
                                      style={{
                                        position: "absolute",
                                        bottom: 5,
                                        right: 5,
                                        pointerEvents: "none",
                                      }}
                                      className='more-right-icon fontsizemsallsvg'
                                    />
                                    <CloseIcon
                                      style={{
                                        position: "absolute",
                                        bottom: 5,
                                        right: 5,
                                        pointerEvents: "none",
                                      }}
                                      className='expand-more fontsizemsallsvg'
                                    />
                                  </div>) : (<div className='wrapper-select-new wrapper-select-green' onClick={this.connectToMollie.bind(this)}>
                                    <input value="Connect your account" readOnly style={{ cursor: "pointer" }} />
                                    <BiChevronRight
                                      style={{
                                        position: "absolute",
                                        bottom: 5,
                                        right: 5,
                                        pointerEvents: "none",
                                      }}
                                      className='expand-more'
                                    />
                                  </div>)}
                                </div>
                                <div
                                  className="form-miur"
                                  style={{ position: "relative" }}
                                >
                                  <label>Paypal <div className='green-round-info'>?</div></label>
                                  <div className='wrapper-select-new wrapper-select-green'>
                                    {this.state.currentUserMember.paypalInfo ? (<div className='wrapper-select-new wrapper-select-green'>
                                      <input value="Verbonden" />
                                      <BsCheck2
                                        style={{
                                          position: "absolute",
                                          bottom: 5,
                                          right: 5,
                                          pointerEvents: "none",
                                        }}
                                        className='more-right-icon fontsizemsallsvg'
                                      />
                                      <CloseIcon
                                        style={{
                                          position: "absolute",
                                          bottom: 5,
                                          right: 5,
                                          pointerEvents: "none",
                                        }}
                                        className='expand-more fontsizemsallsvg'
                                      />
                                    </div>) : (<div className='wrapper-select-new wrapper-select-green' onClick={this.connectToPaypal.bind(this)}>
                                      <input value="Connect your account" readOnly style={{ cursor: "pointer" }} />
                                      <BiChevronRight
                                        style={{
                                          position: "absolute",
                                          bottom: 5,
                                          right: 5,
                                          pointerEvents: "none",
                                        }}
                                        className='expand-more'
                                      />
                                    </div>)}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                )}

                {this.state.administrationRight && (
                  <div className="modal-create-album modalcreatealbumchangedheader changedheadermodalcreatealbum">
                    {!this.state.isDesktop && (
                      <ArrowBackIcon
                        onClick={() => {
                          this.setState({
                            userInfoRight: false,
                            menuItemsModals: true,
                          });
                        }}
                        style={{
                          position: "absolute",
                          top: 15,
                          left: 10,
                          fontSize: 18,
                        }}
                      />
                    )}
                    <div
                      className="heading-mca"
                      style={{
                        textAlign: "center",
                        position: "relative",
                        padding: "13px",
                      }}
                    >
                      <font>ADMINISTRATIE</font>
                      <div>
                        <ChevronLeft
                          onClick={() => {
                            this.setState({ administrationRight: false });
                          }}
                          style={{
                            fontSize: 18,
                          }}
                        />
                        <div className="spacing-between-icons"></div>
                        <CloseIcon
                          onClick={() => {
                            let element = document.getElementById("footermain");
                            if (element) {
                              element.style.display = "flex";
                            }
                            this.setState({
                              rightModalSettings: false,
                              privacyRight: false,
                              channelPageRight: false,
                              userInfoRight: false,
                              shopSettings: false,
                              voucherSettings: false,
                              emailPassword: false,
                              myOrdersRight: false,
                              allOrdersRight: false,
                              showBhodiFaqs: false,
                              showBhodiAbouts: false,
                              paymentSettingsRight: false,
                              administrationRight: false,
                            });
                          }}
                          style={{
                            fontSize: 18,
                          }}
                        />
                      </div>
                    </div>
                    <div className='rightmenuinner-rmmms updatedirghtmenunew newrightmenuglobalgood' style={{ height: "auto", padding: "0px 20px" }}>
                      <div className="filter-administratie">
                        <div
                          className="form-miur"
                          style={{ position: "relative" }}
                        >
                          <label>Filter op datum</label>
                          <div className='wrapper-select-new' style={{ marginBottom: 15 }}>
                            <select defaultValue={this.state.selectedDateAdministratie} onChange={(e) => {
                              this.setState({ selectedDateAdministratie: e.target.value })

                              if (e.target.value === "") {
                                if (this.state.selectedYearAdministratie) {
                                  let filtered = this.state.allAdministrationDataFilter.filter((t) => {
                                    if (t.added_at) {
                                      return new Date(Number(t.added_at)).getFullYear() === Number(this.state.selectedYearAdministratie)
                                    }
                                  })
                                  if (filtered.length > 0) {
                                    this.setState({ allAdministrationData: filtered })
                                  } else {
                                    this.setState({ allAdministrationData: [] })
                                  }
                                  return false;
                                }
                                this.setState({ allAdministrationData: this.state.allAdministrationDataFilter })
                                return false;
                              }

                              let filtered = this.state.allAdministrationDataFilter.filter((t) => {
                                if (t.added_at) {
                                  if (this.state.selectedYearAdministratie) {
                                    return new Date(Number(t.added_at)).getMonth() === Number(e.target.value) && new Date(Number(t.added_at)).getFullYear() === Number(this.state.selectedYearAdministratie)
                                  } else {
                                    return new Date(Number(t.added_at)).getMonth() === Number(e.target.value)
                                  }
                                }
                              })
                              if (filtered.length > 0) {
                                this.setState({ allAdministrationData: filtered })
                              } else {
                                this.setState({ allAdministrationData: [] })
                              }
                            }}>
                              <option value="">Alle manden</option>
                              <option value="0">January</option>
                              <option value="1">February</option>
                              <option value="2">March</option>
                              <option value="3">April</option>
                              <option value="4">May</option>
                              <option value="5">June</option>
                              <option value="6">July</option>
                              <option value="7">August</option>
                              <option value="8">September</option>
                              <option value="9">October</option>
                              <option value="10">November</option>
                              <option value="11">December</option>
                            </select>
                            <ExpandMoreIcon
                              style={{
                                position: "absolute",
                                bottom: 5,
                                right: 5,
                                pointerEvents: "none",
                              }}
                              className='expand-more'
                            />
                          </div>
                          {this.state.allAdministrationYears.length > 1 && (<div className='wrapper-select-new' style={{ marginBottom: 15 }}>
                            <select defaultValue={this.state.selectedYearAdministratie} onChange={(e) => {
                              this.setState({ selectedYearAdministratie: e.target.value })

                              if (e.target.value === "") {
                                if (this.state.selectedDateAdministratie) {
                                  let filtered = this.state.allAdministrationDataFilter.filter((t) => {
                                    if (t.added_at) {
                                      return new Date(Number(t.added_at)).getMonth() === Number(this.state.selectedDateAdministratie)
                                    }
                                  })
                                  if (filtered.length > 0) {
                                    this.setState({ allAdministrationData: filtered })
                                  } else {
                                    this.setState({ allAdministrationData: [] })
                                  }
                                  return false;
                                }
                                this.setState({ allAdministrationData: this.state.allAdministrationDataFilter })
                                return false;
                              }

                              let filtered = this.state.allAdministrationDataFilter.filter((t) => {
                                if (t.added_at) {
                                  if (this.state.selectedDateAdministratie) {
                                    return new Date(Number(t.added_at)).getFullYear() === Number(e.target.value) && new Date(Number(t.added_at)).getMonth() === Number(this.state.selectedDateAdministratie)
                                  } else {
                                    return new Date(Number(t.added_at)).getFullYear() === Number(e.target.value)
                                  }
                                }
                              })
                              if (filtered.length > 0) {
                                this.setState({ allAdministrationData: filtered })
                              } else {
                                this.setState({ allAdministrationData: [] })
                              }
                            }}>
                              <option value="">Alle year</option>
                              {this.state.allAdministrationYears.map((t) => {
                                return (<option value={t}>{t}</option>)
                              })}
                            </select>
                            <ExpandMoreIcon
                              style={{
                                position: "absolute",
                                bottom: 5,
                                right: 5,
                                pointerEvents: "none",
                              }}
                              className='expand-more'
                            />
                          </div>)}
                          <div className="administration-data-wsn">
                            {this.getAdministrationData()}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {this.state.barberAdministrationRight && (
                  <BarberAdministrationStuff isDesktop={this.state.isDesktop} onChevronLeft={() => {
                    this.setState({ barberAdministrationRight: false });
                  }} onCloseComplete={() => {
                    let element = document.getElementById("footermain");
                    if (element) {
                      element.style.display = "flex";
                    }
                    this.setState({
                      rightModalSettings: false,
                      privacyRight: false,
                      channelPageRight: false,
                      userInfoRight: false,
                      shopSettings: false,
                      voucherSettings: false,
                      emailPassword: false,
                      myOrdersRight: false,
                      allOrdersRight: false,
                      showBhodiFaqs: false,
                      showBhodiAbouts: false,
                      paymentSettingsRight: false,
                      administrationRight: false,
                      barberAdministrationRight: false,
                    });
                  }} />
                )}
                {this.state.vendorAdministrationRight && (
                  <VendorAdministrationStuff isDesktop={this.state.isDesktop} onChevronLeft={() => {
                    this.setState({ vendorAdministrationRight: false });
                  }} onCloseComplete={() => {
                    let element = document.getElementById("footermain");
                    if (element) {
                      element.style.display = "flex";
                    }
                    this.setState({
                      rightModalSettings: false,
                      privacyRight: false,
                      channelPageRight: false,
                      userInfoRight: false,
                      shopSettings: false,
                      voucherSettings: false,
                      emailPassword: false,
                      myOrdersRight: false,
                      allOrdersRight: false,
                      showBhodiFaqs: false,
                      showBhodiAbouts: false,
                      paymentSettingsRight: false,
                      administrationRight: false,
                      vendorAdministrationRight: false,
                    });
                  }} />
                )}
                {this.state.showBhodiFaqs && (
                  <div className="modal-create-album modalcreatealbumchangedheader changedheadermodalcreatealbum" style={{ width: 400 }}>
                    <div
                      className="heading-mca"
                      style={{
                        textAlign: "center",
                        position: "relative",
                        padding: "13px",
                      }}
                    >
                      <font>VEELGESTELDE VRAGEN</font>
                      <div>
                        {!this.state.isDesktop && (
                          <ArrowBackIcon
                            onClick={() => {
                              this.setState({
                                emailPassword: false,
                                menuItemsModals: true,
                              });
                            }}
                            style={{
                              position: "absolute",
                              top: 15,
                              left: 10,
                              fontSize: 18,
                            }}
                          />
                        )}
                        <div className="spacing-between-icons"></div>
                        <ChevronLeft
                          onClick={() => {
                            // let element = document.getElementById("footermain")
                            // if (element) {
                            //     element.style.display = "flex"
                            // }
                            // this.setState({ rightModalSettings: false, privacyRight: false, channelPageRight: false, userInfoRight: false, shopSettings: false, voucherSettings: false, emailPassword: false, myOrdersRight: false, allOrdersRight: false, showBhodiFaqs: false, showBhodiAbouts: false })
                            this.setState({ showBhodiFaqs: false });
                          }}
                          style={{
                            position: "absolute",
                            top: "50%",
                            transform: "translateY(-50%)",
                            left: 10,
                            fontSize: 18,
                          }}
                        />
                        <div className="spacing-between-icons"></div>
                        <CloseIcon
                          onClick={() => {
                            let element = document.getElementById("footermain");
                            if (element) {
                              element.style.display = "flex";
                            }
                            this.setState({
                              rightModalSettings: false,
                              privacyRight: false,
                              channelPageRight: false,
                              userInfoRight: false,
                              shopSettings: false,
                              voucherSettings: false,
                              emailPassword: false,
                              myOrdersRight: false,
                              allOrdersRight: false,
                              showBhodiFaqs: false,
                              showBhodiAbouts: false,
                            });
                            // this.setState({ showBhodiFaqs: false })
                          }}
                          style={{
                            position: "absolute",
                            top: "50%",
                            transform: "translateY(-50%)",
                            right: 10,
                            fontSize: 18,
                          }}
                        />
                      </div>
                    </div>
                    <div className="body-mca groupsettingsbody mcasettingsupdatednavbar">
                      <div className="form-body-mca">
                        <div className="inner-mca-settings">
                          <div
                            className="mca-settings-list"
                            style={{
                              flexDirection: "column",
                              backgroundColor: "transparent",
                              width: "90%",
                              margin: "10px auto",
                            }}
                          >
                            {/* <div className="top-mcls-one">
                                            <p>
                                                <AccountCircleOutlinedIcon />
                                                VEELGESTELDE VRAGEN
                                            </p>
                                        </div> */}
                            <div
                              className="wrapperforfullwidth wrapperbhodifaqs"
                              style={{ position: "relative" }}
                            >
                              {this.state.bhodiFaqs.length > 0 &&
                                this.state.bhodiFaqs.map((y, i) => {
                                  if (y.title) {
                                    return (
                                      <div className="faq-inner-main">
                                        <div
                                          className="top-faq-rmcls"
                                          onClick={() => {
                                            if (y.desc) {
                                              this.state.arrDropdowns[
                                                i
                                              ].opened =
                                                !this.state.arrDropdowns[i]
                                                  .opened;
                                              this.setState({
                                                arrDropdowns:
                                                  this.state.arrDropdowns,
                                              });
                                            }
                                          }}
                                        >
                                          <p>{y.title}</p>
                                          {this.state.arrDropdowns[i].opened
                                            ? y.desc && <RemoveIcon />
                                            : y.desc && <AddIcon />}
                                        </div>
                                        {this.state.arrDropdowns[i].opened && (
                                          <div className="bottom-faq-rmcls">
                                            {/* <p>{y.desc}</p> */}
                                            <div
                                              dangerouslySetInnerHTML={this.createMarkup(
                                                y.desc
                                              )}
                                            ></div>
                                          </div>
                                        )}
                                      </div>
                                    );
                                  }
                                })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {this.state.showBhodiAbouts && (
                  <div className="modal-create-album modalcreatealbumchangedheader changedheadermodalcreatealbum" style={{ width: 400 }}>
                    <div
                      className="heading-mca"
                      style={{
                        textAlign: "center",
                        position: "relative",
                        padding: "13px",
                      }}
                    >
                      <font>ABOUT</font>
                      {/* <CloseIcon onClick={() => {
                                    if (this.props.closeAboutBhodiRightMenu) {
                                        this.props.closeAboutBhodiRightMenu()
                                        if (this.props.doShowMenuRightAbout) {
                                            setTimeout(() => {
                                                this.setState({ showBhodiAbouts: false })
                                            }, 1000);
                                            return false;
                                        } else {
                                            this.setState({ showBhodiAbouts: false })
                                        }
                                    }
                                    this.setState({ showBhodiAbouts: false })
                                }} style={{ position: "absolute", top: "50%", transform: "translateY(-50%)", right: 10, fontSize: 18 }} />
                                <ChevronLeft onClick={() => {
                                    if (this.props.closeAboutBhodiRightMenu) {
                                        this.props.closeAboutBhodiRightMenu()
                                        if (this.props.doShowMenuRightAbout) {
                                            setTimeout(() => {
                                                this.setState({ showBhodiAbouts: false })
                                            }, 1000);
                                            return false;
                                        } else {
                                            this.setState({ showBhodiAbouts: false })
                                        }
                                    }
                                    this.setState({ showBhodiAbouts: false })
                                }} style={{ position: "absolute", top: "50%", transform: "translateY(-50%)", left: 10, fontSize: 18 }} /> */}
                      <div>
                        {!this.state.isDesktop && (
                          <ArrowBackIcon
                            onClick={() => {
                              this.setState({
                                showBhodiAbouts: false,
                                menuItemsModals: true,
                              });
                            }}
                            style={{
                              position: "absolute",
                              top: 15,
                              left: 10,
                              fontSize: 18,
                            }}
                          />
                        )}
                        <div className="spacing-between-icons"></div>
                        {!this.state.openedFromPropsAbout && (
                          <ChevronLeft
                            onClick={() => {
                              if (this.props.closeAboutBhodiRightMenu) {
                                this.props.closeAboutBhodiRightMenu();
                                if (this.props.doShowMenuRightAbout) {
                                  setTimeout(() => {
                                    this.setState({ showBhodiAbouts: false });
                                  }, 1000);
                                  return false;
                                } else {
                                  this.setState({ showBhodiAbouts: false });
                                }
                              }
                              this.setState({ showBhodiAbouts: false });
                            }}
                            style={{
                              position: "absolute",
                              top: "50%",
                              transform: "translateY(-50%)",
                              left: 10,
                              fontSize: 18,
                            }}
                          />
                        )}
                        <div className="spacing-between-icons"></div>
                        <CloseIcon
                          onClick={() => {
                            if (this.props.closeAboutBhodiRightMenu) {
                              this.props.closeAboutBhodiRightMenu();
                              if (this.props.doShowMenuRightAbout) {
                                setTimeout(() => {
                                  this.setState({ showBhodiAbouts: false });
                                  if (this.state.openedFromPropsAbout) {
                                    let element =
                                      document.getElementById("footermain");
                                    if (element) {
                                      element.style.display = "flex";
                                    }
                                    this.setState({
                                      rightModalSettings: false,
                                      openedFromPropsAbout: false,
                                      menuItemsModals: false,
                                      showBhodiAbouts: false,
                                    });
                                  }
                                }, 400);
                                return false;
                              } else {
                                let element =
                                  document.getElementById("footermain");
                                if (element) {
                                  element.style.display = "flex";
                                }
                                this.setState({
                                  rightModalSettings: false,
                                  privacyRight: false,
                                  channelPageRight: false,
                                  userInfoRight: false,
                                  shopSettings: false,
                                  voucherSettings: false,
                                  emailPassword: false,
                                  myOrdersRight: false,
                                  allOrdersRight: false,
                                  showBhodiFaqs: false,
                                  showBhodiAbouts: false,
                                });
                                // this.setState({ showBhodiAbouts: false })
                              }
                            }
                            if (this.state.openedFromPropsAbout) {
                              let element = document.getElementById("footermain");
                              if (element) {
                                element.style.display = "flex";
                              }
                              this.setState({
                                rightModalSettings: false,
                                openedFromPropsAbout: false,
                                menuItemsModals: false,
                                showBhodiAbouts: false,
                              });
                            }
                            this.setState({ showBhodiAbouts: false });
                          }}
                          style={{
                            position: "absolute",
                            top: "50%",
                            transform: "translateY(-50%)",
                            right: 10,
                            fontSize: 18,
                          }}
                        />
                      </div>
                    </div>
                    <div className="body-mca groupsettingsbody mcasettingsupdatednavbar">
                      <div className="form-body-mca">
                        <div className="inner-mca-settings">
                          <div
                            className="mca-settings-list"
                            style={{
                              flexDirection: "column",
                              backgroundColor: "transparent",
                              width: "90%",
                              margin: "10px auto",
                            }}
                          >
                            {/* <div className="top-mcls-one">
                                                <p>
                                                    <AccountCircleOutlinedIcon />
                                                    VEELGESTELDE VRAGEN
                                                </p>
                                            </div> */}
                            <div
                              className="wrapperforfullwidth wrapperbhodifaqs"
                              style={{ position: "relative" }}
                            >
                              {this.state.bhodiAbouts.length > 0 &&
                                this.state.bhodiAbouts.map((y, i) => {
                                  if (y.title) {
                                    return (
                                      <div className="faq-inner-main">
                                        <div
                                          className="top-faq-rmcls"
                                          onClick={() => {
                                            if (y.desc) {
                                              this.state.arrDropdownsAbouts[
                                                i
                                              ].opened =
                                                !this.state.arrDropdownsAbouts[
                                                  i
                                                ].opened;
                                              this.setState({
                                                arrDropdownsAbouts:
                                                  this.state.arrDropdownsAbouts,
                                              });
                                            }
                                          }}
                                        >
                                          <p>{y.title}</p>
                                          {this.state.arrDropdownsAbouts[i]
                                            .opened
                                            ? y.desc && <RemoveIcon />
                                            : y.desc && <AddIcon />}
                                        </div>
                                        {this.state.arrDropdownsAbouts[i]
                                          .opened && (
                                            <div className="bottom-faq-rmcls">
                                              <div
                                                dangerouslySetInnerHTML={this.createMarkup(
                                                  y.desc
                                                )}
                                              ></div>
                                              {y.image && <img src={y.image} />}
                                            </div>
                                          )}
                                      </div>
                                    );
                                  }
                                })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {this.state.privacyRight && (
                  <div className="modal-create-album modalcreatealbumchangedheader changedheadermodalcreatealbum" style={{ width: 400 }}>
                    {!this.state.isDesktop && (
                      <ArrowBackIcon
                        onClick={() => {
                          this.setState({
                            privacyRight: false,
                            menuItemsModals: true,
                          });
                        }}
                        style={{
                          position: "absolute",
                          top: 15,
                          left: 10,
                          fontSize: 18,
                        }}
                      />
                    )}
                    <div
                      className="heading-mca"
                      style={{
                        textAlign: "center",
                        position: "relative",
                        padding: "13px",
                      }}
                    >
                      <font>PRIVACY INSTELLINGEN</font>
                      <div>
                        <ChevronLeft
                          onClick={() => {
                            const { hideFriendsInfo } =
                              this.state.currentUserMember;
                            let hideFriendsMain = false;
                            if (hideFriendsInfo) {
                              hideFriendsMain = hideFriendsInfo
                            }
                            if (
                              hideFriendsMain !==
                              this.state.checkedHideFriendsInfo
                            ) {
                              this.setState({
                                beforeCloseMenuConfirmPrivacy: true,
                              });
                              return false;
                            }
                            this.setState({ privacyRight: false });
                          }}
                          style={{
                            position: "absolute",
                            top: "50%",
                            transform: "translateY(-50%)",
                            left: 10,
                            fontSize: 18,
                          }}
                        />
                        <div className="spacing-between-icons"></div>
                        <CloseIcon
                          onClick={() => {
                            let element = document.getElementById("footermain");
                            if (element) {
                              element.style.display = "flex";
                            }
                            this.setState({
                              rightModalSettings: false,
                              privacyRight: false,
                              channelPageRight: false,
                              userInfoRight: false,
                              shopSettings: false,
                              voucherSettings: false,
                              emailPassword: false,
                              myOrdersRight: false,
                              allOrdersRight: false,
                              showBhodiFaqs: false,
                              showBhodiAbouts: false,
                            });
                            // this.setState({ privacyRight: false })
                          }}
                          style={{
                            position: "absolute",
                            top: "50%",
                            transform: "translateY(-50%)",
                            right: 10,
                            fontSize: 18,
                          }}
                        />
                      </div>
                      {/* {this.state.savtBtnText === "SAVE" ? (
                        <AiOutlineSave
                          onClick={() => {
                            this.savePrivacy();
                          }}
                          style={{
                            position: "absolute",
                            top: "50%",
                            transform: "translateY(-50%)",
                            right: 40,
                            fontSize: 18,
                          }}
                        />
                      ) : (
                        <AiOutlineCheckSquare
                          style={{
                            position: "absolute",
                            top: "50%",
                            transform: "translateY(-50%)",
                            right: 40,
                            fontSize: 18,
                          }}
                        />
                      )} */}
                    </div>
                    <div className="body-mca groupsettingsbody mcasettingsupdatednavbar">
                      <div className="form-body-mca">
                        <div className="inner-mca-settings">
                          <div
                            className="mca-settings-list"
                            style={{
                              flexDirection: "column",
                              backgroundColor: "transparent",
                              width: "90%",
                              margin: "10px auto",
                            }}
                          >
                            {/* <div className="top-mcls-one">
                                                <p>
                                                    <AccountCircleOutlinedIcon />
                                                    PRIVACY INSTELLINGEN
                                                </p>
                                            </div> */}
                            <div
                              className="wrapperforfullwidth"
                              style={{ position: "relative" }}
                            >
                              <div
                                className="right-mcls"
                                style={{ color: "#1C1C1C" }}
                              >
                                <Switch
                                  color="primary"
                                  inputProps={{
                                    "aria-label": "primary checkbox",
                                  }}
                                  checked={this.state.checkedHideFriendsInfo}
                                  onClick={() => {
                                    this.setState({
                                      checkedHideFriendsInfo:
                                        !this.state.checkedHideFriendsInfo,
                                    });
                                  }}
                                />
                                {this.state.checkedHideFriendsInfo ? (
                                  <font>Je vriendenpagina is nu verborgen</font>
                                ) : (
                                  <font>Verberg vriendenpagina</font>
                                )}
                              </div>
                              <div className="btn-save-rmcls btnsaveupdateddesktop" style={{ position: "absolute", paddingTop: 25, marginTop: 30, paddingBottom: 20 }}>
                                {this.state.savtBtnText === "SAVE" ? <button onClick={() => {
                                  if (this.state.savtBtnText === "SAVE") {
                                    this.savePrivacy()
                                  }
                                }}>OPSLAAN</button> : <button><i class="fa fa-spinner fa-spin"></i> OPSLAAN</button>}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {this.state.isDesktop &&
                  !this.state.allOrdersRight &&
                  !this.state.shopSettings &&
                  !this.state.userInfoRight &&
                  !this.state.subscriptionOfExclusivesRight &&
                  !this.state.privacyRight &&
                  !this.state.emailPassword &&
                  !this.state.showBhodiAbouts &&
                  !this.state.showBhodiFaqs &&
                  !this.state.paymentSettingsRight &&
                  !this.state.administrationRight &&
                  !this.state.barberAdministrationRight &&
                  !this.state.vendorAdministrationRight &&
                  !this.state.productSettings &&
                  !this.state.voucherSettings && (
                    <div
                      className="modal-create-album modalcreatealbumchangedheader changedheadermodalcreatealbum"
                      style={{ width: window.innerWidth < 1600 ? 330 : 400 }}
                    >
                      <div
                        className="heading-mca"
                        style={{
                          textAlign: "center",
                          position: "relative",
                          padding: "13px",
                        }}
                      >
                        <font>PROFIELINSTELLINGEN</font>
                        <CloseIcon
                          onClick={() => {
                            let element = document.getElementById("footermain");
                            if (element) {
                              element.style.display = "flex";
                            }
                            this.setState({ rightModalSettings: false });
                            if (this.props.removeFillUpAddress) {
                              this.props.removeFillUpAddress();
                            }
                            this.setState({ showBhodiAbouts: false });
                            if (this.props.closeAboutBhodiRightMenu) {
                              this.props.closeAboutBhodiRightMenu();
                            }
                          }}
                          style={{
                            position: "absolute",
                            top: "50%",
                            transform: "translateY(-50%)",
                            right: 10,
                            fontSize: 18,
                          }}
                        />
                      </div>
                      <div className="body-mca groupsettingsbody mcasettingsupdatednavbar">
                        <div className="form-body-mca">
                          <div className="inner-mca-settings rmclswithiconsbordered">
                            <div
                              className="mca-settings-list"
                              style={{
                                flexDirection: "column",
                                alignItems: "flex-start",
                                backgroundColor: "transparent",
                                height: "auto",
                              }}
                            >
                              <div className="top-mca-updatedlist topmcaupdatediconsdo" style={{ marginBottom: 8 }}>
                                <div
                                  className="right-mcls imageinnermca"
                                  style={{
                                    color: "white",
                                    position: "relative",
                                    maxWidth: 70,
                                    margin: "0px auto",
                                  }}
                                >
                                  {this.state.currentUserMember.profile_image ? (
                                    <img
                                      src={this.state.currentUserMember.profile_image}
                                      style={{ width: 30, height: 30 }}
                                    />
                                  ) : (
                                    <img
                                      src="https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/Capture.PNG?alt=media&token=b8fc6c86-884b-40d4-8649-1ef7ec84bf4f"
                                      style={{ width: 30, height: 30 }}
                                    />
                                  )}
                                  <div className="imginnermcaadd" style={{ top: -3 }}>
                                    <AddIcon />
                                  </div>
                                </div>
                                <div
                                  className="right-mcls imageinnermca"
                                  style={{ color: "white" }}
                                >
                                  <font>Profielfoto</font>
                                </div>
                                <input
                                  type="file"
                                  accept="image/*"
                                  onChange={(e) => this.fileUpload(e)}
                                  style={{
                                    position: "absolute",
                                    top: 0,
                                    right: 0,
                                    left: 0,
                                    bottom: 0,
                                    cursor: "pointer",
                                    opacity: 0,
                                  }}
                                />
                              </div>
                              {/* <div className="line-rcmls"></div> */}
                              {/* <div
                                className="right-mcls"
                                style={{ color: "white", paddingLeft: 20 }}
                              >
                                <div className="captionvoucherstuff charityimageonvoucher"
                                  onClick={() => {
                                    if (this.props.closeAboutBhodiRightMenu) {
                                      this.props.closeAboutBhodiRightMenu();
                                    }
                                    this.setState({
                                      subscriptionOfExclusivesRight: true,
                                    });
                                  }}>
                                  <TbFolderStar />
                                </div>
                                <font
                                  onClick={() => {
                                    if (this.props.closeAboutBhodiRightMenu) {
                                      this.props.closeAboutBhodiRightMenu();
                                    }
                                    this.setState({
                                      subscriptionOfExclusivesRight: true,
                                    });
                                  }}
                                >
                                  Mijn exclusives
                                </font>
                              </div> */}
                              <div
                                className="right-mcls"
                                style={{ color: "white", paddingLeft: 20 }}
                              >
                                <div className="captionvoucherstuff charityimageonvoucher"
                                  onClick={() => {
                                    if (this.props.closeAboutBhodiRightMenu) {
                                      this.props.closeAboutBhodiRightMenu();
                                    }
                                    this.setState({
                                      channelPageRight: false,
                                      userInfoRight: true,
                                      privacyRight: false,
                                      emailPassword: false,
                                      shopSettings: false,
                                      myOrdersRight: false,
                                      allOrdersRight: false,
                                      shopSettings: false,
                                      voucherSettings: false,
                                      showSubscribedContent: false,
                                      showBhodiFaqs: false,
                                      showBhodiAbouts: false,
                                    });
                                  }}>
                                  <RiUserSettingsLine />
                                </div>
                                <font
                                  onClick={() => {
                                    if (this.props.closeAboutBhodiRightMenu) {
                                      this.props.closeAboutBhodiRightMenu();
                                    }
                                    this.setState({
                                      channelPageRight: false,
                                      userInfoRight: true,
                                      privacyRight: false,
                                      emailPassword: false,
                                      shopSettings: false,
                                      myOrdersRight: false,
                                      allOrdersRight: false,
                                      shopSettings: false,
                                      voucherSettings: false,
                                      showSubscribedContent: false,
                                      showBhodiFaqs: false,
                                      showBhodiAbouts: false,
                                    });
                                  }}
                                >
                                  Mijn gegevens
                                </font>
                              </div>
                              <div
                                className="right-mcls"
                                style={{ color: "white", paddingLeft: 20 }}
                              >
                                <div className="captionvoucherstuff charityimageonvoucher"
                                  onClick={() => {
                                    if (this.props.closeAboutBhodiRightMenu) {
                                      this.props.closeAboutBhodiRightMenu();
                                    }
                                    this.setState({
                                      channelPageRight: false,
                                      privacyRight: true,
                                      userInfoRight: false,
                                      emailPassword: false,
                                      shopSettings: false,
                                      myOrdersRight: false,
                                      allOrdersRight: false,
                                      shopSettings: false,
                                      voucherSettings: false,
                                      showSubscribedContent: false,
                                      showBhodiFaqs: false,
                                      showBhodiAbouts: false,
                                    });
                                  }}>
                                  <MdOutlinePrivacyTip />
                                </div>
                                <font
                                  onClick={() => {
                                    if (this.props.closeAboutBhodiRightMenu) {
                                      this.props.closeAboutBhodiRightMenu();
                                    }
                                    this.setState({
                                      channelPageRight: false,
                                      privacyRight: true,
                                      userInfoRight: false,
                                      emailPassword: false,
                                      shopSettings: false,
                                      myOrdersRight: false,
                                      allOrdersRight: false,
                                      shopSettings: false,
                                      voucherSettings: false,
                                      showSubscribedContent: false,
                                      showBhodiFaqs: false,
                                      showBhodiAbouts: false,
                                    });
                                  }}
                                >
                                  Privacy instellingen
                                </font>
                              </div>
                              <div
                                className="right-mcls"
                                style={{ color: "white", paddingLeft: 20 }}
                              >
                                <div className="captionvoucherstuff charityimageonvoucher"
                                  onClick={() => {
                                    if (this.props.closeAboutBhodiRightMenu) {
                                      this.props.closeAboutBhodiRightMenu();
                                    }
                                    this.setState({
                                      channelPageRight: false,
                                      emailPassword: true,
                                      privacyRight: false,
                                      userInfoRight: false,
                                      shopSettings: false,
                                      myOrdersRight: false,
                                      allOrdersRight: false,
                                      shopSettings: false,
                                      voucherSettings: false,
                                      showSubscribedContent: false,
                                      showBhodiFaqs: false,
                                      showBhodiAbouts: false,
                                    });
                                  }}>
                                  <RiGitRepositoryPrivateLine />
                                </div>
                                <font
                                  onClick={() => {
                                    if (this.props.closeAboutBhodiRightMenu) {
                                      this.props.closeAboutBhodiRightMenu();
                                    }
                                    this.setState({
                                      channelPageRight: false,
                                      emailPassword: true,
                                      privacyRight: false,
                                      userInfoRight: false,
                                      shopSettings: false,
                                      myOrdersRight: false,
                                      allOrdersRight: false,
                                      shopSettings: false,
                                      voucherSettings: false,
                                      showSubscribedContent: false,
                                      showBhodiFaqs: false,
                                      showBhodiAbouts: false,
                                    });
                                  }}
                                >
                                  Inloggegevens
                                </font>
                              </div>
                              {/* <div
                                style={{
                                  width: "90%",
                                  height: 1,
                                  margin: "20px auto",
                                  // backgroundColor: "#f8f8f8",
                                }}
                              ></div> */}
                              {this.state.currentUserMember.isAllowedAdministration && (<div
                                className="right-mcls"
                                style={{ color: "white", paddingLeft: 20 }}
                              >
                                <div className="captionvoucherstuff charityimageonvoucher"
                                  onClick={() => {
                                    if (this.props.closeAboutBhodiRightMenu) {
                                      this.props.closeAboutBhodiRightMenu();
                                    }
                                    this.setState({
                                      administrationRight: true,
                                    });
                                    // let allAdministrationData = []
                                    // let allYears = []
                                    // if (this.state.currentUserMember.subscribersForExclusives) {
                                    //   let objected = Object.entries(this.state.currentUserMember.subscribersForExclusives)
                                    //   objected.map((t) => {
                                    //     if (t[1].added_at) {
                                    //       allYears.push(new Date(Number(t[1].added_at)).getFullYear())
                                    //       allAdministrationData.push(t[1])
                                    //     }
                                    //   })
                                    // }
                                    // if (this.props.user) {
                                    //   let filtered = this.props.user.filter((t) => {
                                    //     return t.organization_user_id === this.state.currentUserMember.user_id
                                    //   })
                                    //   if (filtered.length > 0) {
                                    //     filtered.map((t) => {
                                    //       let newObj = Object.assign(t, {})
                                    //       if (filtered.length > 0 && newObj.user_split_amount_default && newObj.user_created_at) {
                                    //         newObj.amount = newObj.user_split_amount_default
                                    //         newObj.added_at = newObj.user_created_at
                                    //         allYears.push(new Date(Number(newObj.user_created_at)).getFullYear())
                                    //         allAdministrationData.push(newObj)
                                    //       }
                                    //     })
                                    //   }
                                    // }
                                    // let filteredAllYears = allYears.filter(
                                    //   (v, i, a) => a.findIndex((t) => t === v) === i
                                    // );
                                    // this.setState({ allAdministrationData, allAdministrationDataFilter: allAdministrationData, allAdministrationYears: filteredAllYears })
                                  }}
                                >
                                  <TbPigMoney />
                                </div>
                                <font
                                  onClick={() => {
                                    if (this.props.closeAboutBhodiRightMenu) {
                                      this.props.closeAboutBhodiRightMenu();
                                    }
                                    this.setState({
                                      administrationRight: true,
                                    });
                                    // let allAdministrationData = []
                                    // let allYears = []
                                    // if (this.state.currentUserMember.subscribersForExclusives) {
                                    //   let objected = Object.entries(this.state.currentUserMember.subscribersForExclusives)
                                    //   objected.map((t) => {
                                    //     if (t[1].added_at) {
                                    //       allYears.push(new Date(Number(t[1].added_at)).getFullYear())
                                    //       allAdministrationData.push(t[1])
                                    //     }
                                    //   })
                                    // }
                                    // if (this.props.user) {
                                    //   let filtered = this.props.user.filter((t) => {
                                    //     return t.organization_user_id === this.state.currentUserMember.user_id
                                    //   })
                                    //   if (filtered.length > 0) {
                                    //     filtered.map((t) => {
                                    //       let newObj = Object.assign(t, {})
                                    //       if (filtered.length > 0 && newObj.user_split_amount_default && newObj.user_created_at) {
                                    //         newObj.amount = newObj.user_split_amount_default
                                    //         newObj.added_at = newObj.user_created_at
                                    //         allYears.push(new Date(Number(newObj.user_created_at)).getFullYear())
                                    //         allAdministrationData.push(newObj)
                                    //       }
                                    //     })
                                    //   }
                                    // }
                                    // let filteredAllYears = allYears.filter(
                                    //   (v, i, a) => a.findIndex((t) => t === v) === i
                                    // );
                                    // this.setState({ allAdministrationData, allAdministrationDataFilter: allAdministrationData, allAdministrationYears: filteredAllYears })
                                  }}
                                >
                                  Administratie
                                </font>
                              </div>)}
                              {this.state.currentUserMember.barber_new && (<div
                                className="right-mcls"
                                style={{ color: "white", paddingLeft: 20 }}
                              >
                                <div className="captionvoucherstuff charityimageonvoucher"
                                  onClick={() => {
                                    if (this.props.closeAboutBhodiRightMenu) {
                                      this.props.closeAboutBhodiRightMenu();
                                    }
                                    this.setState({
                                      barberAdministrationRight: true,
                                    });
                                  }}
                                >
                                  <TbPigMoney />
                                </div>
                                <font
                                  onClick={() => {
                                    if (this.props.closeAboutBhodiRightMenu) {
                                      this.props.closeAboutBhodiRightMenu();
                                    }
                                    this.setState({
                                      barberAdministrationRight: true,
                                    });
                                  }}
                                >
                                  Signups
                                </font>
                              </div>)}
                              {this.state.currentUserMember.saloon_vendor && (<div
                                className="right-mcls"
                                style={{ color: "white", paddingLeft: 20 }}
                              >
                                <div className="captionvoucherstuff charityimageonvoucher"
                                  onClick={() => {
                                    if (this.props.closeAboutBhodiRightMenu) {
                                      this.props.closeAboutBhodiRightMenu();
                                    }
                                    this.setState({
                                      vendorAdministrationRight: true,
                                    });
                                  }}
                                >
                                  <TbPigMoney />
                                </div>
                                <font
                                  onClick={() => {
                                    if (this.props.closeAboutBhodiRightMenu) {
                                      this.props.closeAboutBhodiRightMenu();
                                    }
                                    this.setState({
                                      vendorAdministrationRight: true,
                                    });
                                  }}
                                >
                                  Signups
                                </font>
                              </div>)}
                              <div
                                className="right-mcls"
                                style={{ color: "white", paddingLeft: 20 }}
                              >
                                <div className="captionvoucherstuff charityimageonvoucher"
                                  onClick={() => {
                                    if (this.props.closeAboutBhodiRightMenu) {
                                      this.props.closeAboutBhodiRightMenu();
                                    }
                                    this.setState({
                                      channelPageRight: false,
                                      myOrdersRight: false,
                                      shopSettings: false,
                                      privacyRight: false,
                                      emailPassword: false,
                                      userInfoRight: false,
                                      allOrdersRight: true,
                                      shopSettings: false,
                                      voucherSettings: false,
                                      showSubscribedContent: false,
                                      showBhodiFaqs: false,
                                      showBhodiAbouts: false,
                                    });
                                  }}>
                                  <HiOutlineClipboardList />
                                </div>
                                <font
                                  onClick={() => {
                                    if (this.props.closeAboutBhodiRightMenu) {
                                      this.props.closeAboutBhodiRightMenu();
                                    }
                                    this.setState({
                                      channelPageRight: false,
                                      myOrdersRight: false,
                                      shopSettings: false,
                                      privacyRight: false,
                                      emailPassword: false,
                                      userInfoRight: false,
                                      allOrdersRight: true,
                                      shopSettings: false,
                                      voucherSettings: false,
                                      showSubscribedContent: false,
                                      showBhodiFaqs: false,
                                      showBhodiAbouts: false,
                                    });
                                  }}
                                >
                                  Klantbestellingen
                                </font>
                              </div>
                              <div
                                className="right-mcls"
                                style={{ color: "white", paddingLeft: 20 }}
                              >
                                <div className="captionvoucherstuff charityimageonvoucher"
                                  onClick={() => {
                                    if (this.props.closeAboutBhodiRightMenu) {
                                      this.props.closeAboutBhodiRightMenu();
                                    }
                                    this.setState({
                                      productSettings: true
                                    });
                                  }}>
                                  <BsTag />
                                </div>
                                <font
                                  onClick={() => {
                                    if (this.props.closeAboutBhodiRightMenu) {
                                      this.props.closeAboutBhodiRightMenu();
                                    }
                                    this.setState({
                                      productSettings: true
                                    });
                                  }}
                                >
                                  Product instellingen
                                </font>
                              </div>
                              <div
                                className="right-mcls"
                                style={{ color: "white", paddingLeft: 20 }}
                              >
                                <div className="captionvoucherstuff charityimageonvoucher"
                                  onClick={() => {
                                    if (this.props.closeAboutBhodiRightMenu) {
                                      this.props.closeAboutBhodiRightMenu();
                                    }
                                    this.setState({
                                      channelPageRight: false,
                                      myOrdersRight: false,
                                      privacyRight: false,
                                      emailPassword: false,
                                      userInfoRight: false,
                                      allOrdersRight: false,
                                      menuItemsModals: false,
                                      shopSettings: false,
                                      voucherSettings: true,
                                      showSubscribedContent: false,
                                      showBhodiFaqs: false,
                                      showBhodiAbouts: false,
                                    });
                                  }}>
                                  <BsTag />
                                </div>
                                <font
                                  onClick={() => {
                                    if (this.props.closeAboutBhodiRightMenu) {
                                      this.props.closeAboutBhodiRightMenu();
                                    }
                                    this.setState({
                                      channelPageRight: false,
                                      myOrdersRight: false,
                                      privacyRight: false,
                                      emailPassword: false,
                                      userInfoRight: false,
                                      allOrdersRight: false,
                                      menuItemsModals: false,
                                      shopSettings: false,
                                      voucherSettings: true,
                                      showSubscribedContent: false,
                                      showBhodiFaqs: false,
                                      showBhodiAbouts: false,
                                    });
                                  }}
                                >
                                  Voucher instellingen
                                </font>
                              </div>
                              {this.state.bhodiFaqs.length > 0 && (
                                <div
                                  className="right-mcls"
                                  style={{ color: "white", paddingLeft: 20 }}
                                >
                                  <div className="captionvoucherstuff charityimageonvoucher"
                                    onClick={() => {
                                      if (this.props.closeAboutBhodiRightMenu) {
                                        this.props.closeAboutBhodiRightMenu();
                                      }
                                      this.setState({
                                        channelPageRight: false,
                                        myOrdersRight: false,
                                        privacyRight: false,
                                        emailPassword: false,
                                        userInfoRight: false,
                                        allOrdersRight: false,
                                        menuItemsModals: false,
                                        shopSettings: false,
                                        voucherSettings: false,
                                        showBhodiFaqs: true,
                                        showSubscribedContent: false,
                                        showBhodiAbouts: false,
                                      });
                                    }}>
                                    <FaQ />
                                  </div>
                                  <font
                                    onClick={() => {
                                      if (this.props.closeAboutBhodiRightMenu) {
                                        this.props.closeAboutBhodiRightMenu();
                                      }
                                      this.setState({
                                        channelPageRight: false,
                                        myOrdersRight: false,
                                        privacyRight: false,
                                        emailPassword: false,
                                        userInfoRight: false,
                                        allOrdersRight: false,
                                        menuItemsModals: false,
                                        shopSettings: false,
                                        voucherSettings: false,
                                        showBhodiFaqs: true,
                                        showSubscribedContent: false,
                                        showBhodiAbouts: false,
                                      });
                                    }}
                                  >
                                    FAQ
                                  </font>
                                </div>
                              )}
                              {this.state.bhodiAbouts.length > 0 && (
                                <div
                                  className="right-mcls"
                                  style={{ color: "white", paddingLeft: 20 }}
                                >
                                  <div className="captionvoucherstuff charityimageonvoucher"
                                    onClick={() => {
                                      this.setState({
                                        channelPageRight: false,
                                        myOrdersRight: false,
                                        privacyRight: false,
                                        emailPassword: false,
                                        userInfoRight: false,
                                        allOrdersRight: false,
                                        menuItemsModals: false,
                                        shopSettings: false,
                                        voucherSettings: false,
                                        showBhodiFaqs: false,
                                        showBhodiAbouts: true,
                                        showSubscribedContent: false,
                                      });
                                    }}>
                                    <TiInfo />
                                  </div>
                                  <font
                                    onClick={() => {
                                      this.setState({
                                        channelPageRight: false,
                                        myOrdersRight: false,
                                        privacyRight: false,
                                        emailPassword: false,
                                        userInfoRight: false,
                                        allOrdersRight: false,
                                        menuItemsModals: false,
                                        shopSettings: false,
                                        voucherSettings: false,
                                        showBhodiFaqs: false,
                                        showBhodiAbouts: true,
                                        showSubscribedContent: false,
                                      });
                                    }}
                                  >
                                    About
                                  </font>
                                </div>
                              )}
                              <div
                                className="right-mcls logout-mcls"
                                style={{ color: "white" }}
                              >
                                <ExitToAppIcon
                                  onClick={this.logout.bind(this)}
                                />
                                <font onClick={this.logout.bind(this)}>
                                  Uitloggen
                                </font>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            )
          }

          {this.state.showFiltersModal && (<div className="loaderareaduplicate mcaloader loaderAreaUpdatedNavbar ">
            <div
              className="modal-create-album modalcreatealbumchangedheader changedheadermodalcreatealbum"
              style={{ width: window.innerWidth < 1600 ? 330 : 400 }}
            >
              <div
                className="heading-mca"
                style={{
                  textAlign: "center",
                  position: "relative",
                  padding: "13px",
                }}
              >
                <font>FILTERS</font>
                <CloseIcon
                  onClick={() => {
                    this.setState({ showFiltersModal: false });
                  }}
                  style={{
                    position: "absolute",
                    top: "50%",
                    transform: "translateY(-50%)",
                    right: 10,
                    fontSize: 18,
                  }}
                />
              </div>
              <div className="body-mca groupsettingsbody mcasettingsupdatednavbar">
                <div className="form-body-mca">
                  <div className="inner-mca-settings rmclswithiconsbordered">
                    <div
                      className="mca-settings-list"
                      style={{
                        flexDirection: "column",
                        alignItems: "flex-start",
                        backgroundColor: "transparent",
                        height: "auto",
                      }}
                    >
                      <div
                        className="right-mcls"
                        style={{ color: "white", paddingLeft: 20 }}
                      >
                        <font
                          onClick={() => {
                            this.setState({ showFiltersModal: false });

                            history.push(`/global-voucher-events/${this.props.sector}`)
                          }}
                        >
                          Alle events
                        </font>
                      </div>
                      {this.state.currentWeekEvents.length > 0 && (
                        <div
                          className="right-mcls"
                          style={{ color: "white", paddingLeft: 20 }}
                        >
                          <font
                            onClick={() => {
                              this.setState({ showFiltersModal: false });

                              history.push(`/global-voucher-events/${this.props.sector}/filter/currentWeek`)
                            }}
                          >
                            Deze week
                          </font>
                        </div>
                      )}
                      {this.state.comingWeekEvents.length > 0 && (
                        <div
                          className="right-mcls"
                          style={{ color: "white", paddingLeft: 20 }}
                        >
                          <font
                            onClick={() => {
                              this.setState({ showFiltersModal: false });

                              history.push(`/global-voucher-events/${this.props.sector}/filter/comingWeek`)
                            }}
                          >
                            Volgende week
                          </font>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>)}

          {this.state.showFiltersModalVouchers && (<div className="loaderareaduplicate mcaloader loaderAreaUpdatedNavbar ">
            <div
              className="modal-create-album modalcreatealbumchangedheader changedheadermodalcreatealbum"
              style={{ width: window.innerWidth < 1600 ? 330 : 400 }}
            >
              <div
                className="heading-mca"
                style={{
                  textAlign: "center",
                  position: "relative",
                  padding: "13px",
                }}
              >
                <font>FILTERS</font>
                <CloseIcon
                  onClick={() => {
                    this.setState({ showFiltersModalVouchers: false });
                  }}
                  style={{
                    position: "absolute",
                    top: "50%",
                    transform: "translateY(-50%)",
                    right: 10,
                    fontSize: 18,
                  }}
                />
              </div>
              <div className="body-mca groupsettingsbody mcasettingsupdatednavbar">
                <div className="form-body-mca">
                  <div className="inner-mca-settings rmclswithiconsbordered">
                    <div
                      className="mca-settings-list"
                      style={{
                        flexDirection: "column",
                        alignItems: "flex-start",
                        backgroundColor: "transparent",
                        height: "auto",
                      }}
                    >
                      {this.state.allCategories.length > 0 &&
                        this.state.allCategories.map((e) => {
                          if (
                            this.state.availableMainCategories.includes(e.category_id)
                          ) {
                            return (
                              <div
                                className="right-mcls"
                                style={{ color: "white", paddingLeft: 20 }}
                              >
                                <font
                                  onClick={() => {
                                    this.setState({ showFiltersModalVouchers: false });

                                    localStorage.setItem("subcategoryidProduct", e.category_id);
                                    history.push(`/global-vouchers/${this.props.sector}/filterbyCategory/${e.category_id}`)
                                  }}
                                >
                                  {e.title}
                                </font>
                              </div>
                            );
                          }
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>)}


          <Dialog
            open={this.state.cancelSubscriptionPopup}
            onClose={() => this.setState({ cancelSubscriptionPopup: false })}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={{ zIndex: 99999999999999999 }}
            className="edit-dialog-cdd"
          >
            <DialogTitle id="alert-dialog-title">OPGELET</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Je bent nog Charity Member.
                <br />
                <br />
                Om je account te verwijderen zul je eerst<br />
                je maandelijkse bijdrage moeten stopzetten.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <button
                onClick={() => {
                  this.setState({
                    cancelSubscriptionPopup: false,
                    showDelete: true,
                  });
                  axios
                    .post(
                      "https://us-central1-bhodi-production-da765.cloudfunctions.net/cancelStripeCustomerSubscription",
                      {
                        subscriptionId:
                          this.state.currentUserMember.subscription_id,
                        customerId: this.state.currentUserMember.stripe_id,
                      }
                    )
                    .then(() => {
                      axios
                        .post(
                          "https://us-central1-bhodi-production-da765.cloudfunctions.net/deleteStripeCustomer",
                          {
                            customerId: this.state.currentUserMember.stripe_id,
                          }
                        )
                        .then(() => {
                          this.deleteCurrentUser();
                        })
                        .catch((error) => {
                          console.log(error);
                        });
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }}
                className="stopplan-button"
              >
                BIJDRAGE STOPPEN
              </button>
              <Button
                onClick={() =>
                  this.setState({ cancelSubscriptionPopup: false })
                }
                color="primary"
                className="close-modal-btn-edcd"
              >
                <CloseIcon />
              </Button>
            </DialogActions>
          </Dialog>

          {/* Valid dialogs */}
          <Dialog
            open={this.state.validOnMobilePopup}
            onClose={() => this.setState({ validOnMobilePopup: false })}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={{ zIndex: 99999999999999999 }}
            className="edit-dialog-cdd smallertitleedcdd"
          >
            <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Het is alleen mogelijk om te
                <br />
                valideren via mobiele telefoon.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => this.setState({ validOnMobilePopup: false })}
                color="primary"
                className="close-modal-btn-edcd"
              >
                <CloseIcon />
              </Button>
            </DialogActions>
          </Dialog>

          {/* Delete dialogs */}
          <Dialog
            open={this.state.pleaseSelectAnyCampaign}
            onClose={() => this.setState({ pleaseSelectAnyCampaign: false })}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={{ zIndex: 99999999999999999 }}
            className="edit-dialog-cdd"
          >
            <DialogTitle id="alert-dialog-title">OPGELET</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Please select any campaign to send email of
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  this.setState({ pleaseSelectAnyCampaign: false });
                }}
                color="primary"
              >
                OK
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={this.state.allEmailsSent}
            onClose={() => this.setState({ allEmailsSent: false })}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={{ zIndex: 99999999999999999 }}
            className="edit-dialog-cdd"
          >
            <DialogTitle id="alert-dialog-title">OPGELET</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Email is sent to the selected retailers
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  this.setState({ allEmailsSent: false });
                }}
                color="primary"
              >
                OK
              </Button>
            </DialogActions>
          </Dialog>

          {this.state.ordersRightMenu && (
            <div
              className="bg-overlaymini"
              onClick={() => {
                document.getElementById("root").style.position = "static";
                this.setState({ ordersRightMenu: false });
              }}
            ></div>
          )}
          {this.state.ordersRightMenu && (
            <RightMenuIncomingOrders
              closePopup={() => {
                document.getElementById("root").style.position = "static";
                this.setState({ ordersRightMenu: false });
              }}
              closePopupComplete={() => {
                document.getElementById("root").style.position = "static";
                this.setState({ ordersRightMenu: false });
              }}
            />
          )}

          <Dialog
            open={this.state.askForConfirmationClientsCsv}
            onClose={() => this.setState({ askForConfirmationClientsCsv: false })}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={{ zIndex: 99999999999999999 }}
            className="edit-dialog-cdd smallertitleedcdd"
          >
            <DialogTitle id="alert-dialog-title">&nbsp;</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Kloppen de gegevens en de<br />
                aantallen in uw import?<br />
                Na uw bevestiging kunt u<br />
                dit niet meer wijzigen
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => this.setState({ askForConfirmationClientsCsv: false })}
                color="primary"
                className="close-modal-btn-edcd"
              >
                <CloseIcon />
              </Button>
              <Button
                onClick={() => {
                  firebase
                    .database()
                    .ref(
                      `users/${this.state.currentUserMember.user_id}/clients_confirmed`
                    )
                    .set(true);
                  firebase
                    .database()
                    .ref(
                      `users/${this.state.currentUserMember.user_id}/flyers_status`
                    )
                    .set("pending");
                  this.setState({ askForConfirmationClientsCsv: false })
                }}
                color="primary"
              >
                Bevestig
              </Button>
            </DialogActions>
          </Dialog>


          <Dialog
            open={this.state.beforeCloseMenuConfirm}
            onClose={() => this.setState({ beforeCloseMenuConfirm: false })}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={{ zIndex: 99999999999999999 }}
            className="edit-dialog-cdd smallertitleedcdd"
          >
            <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Weet je zeker dat je verder <br /> wilt zonder op te slaan?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => this.setState({ beforeCloseMenuConfirm: false })}
                color="primary"
                className="close-modal-btn-edcd"
              >
                <CloseIcon />
              </Button>
              <Button
                onClick={() => {
                  this.setState({
                    beforeCloseMenuConfirm: false,
                    userInfoRight: false,
                  });
                  this.saveInfo();
                  if (this.state.openedDirectlyInfos) {
                    this.props.removeFillUpAddress()
                  }
                }}
                color="primary"
              >
                OPSLAAN
              </Button>
              <Button
                onClick={() => {
                  this.setState({
                    beforeCloseMenuConfirm: false,
                  });
                }}
                color="primary"
              >
                NIET OPSLAAN
              </Button>
              <Button
                onClick={() => {
                  const {
                    first_name,
                    last_name,
                    zipCode,
                    street,
                    company_name,
                    kvkCompany,
                    company_website,
                    houseNumber,
                    country,
                    city,
                    phoneNumber,
                    bankName,
                    bankAccountNumber,
                    profileActive,
                    redirect_guest_to_link_via_event,
                    hide_contact_info_on_order,
                  } = this.state.currentUserMember;
                  this.setState({
                    beforeCloseMenuConfirm: false,
                    userInfoRight: false,
                    firstName: first_name,
                    lastName: last_name,
                    zipCode,
                    street,
                    houseNumber,
                    country,
                    city,
                    phoneNumber,
                    bankName,
                    bankAccountNumber,
                    companyName: company_name,
                    kvkCompany,
                    company_website,
                  });
                  if (profileActive) {
                    this.setState({ profileActive: true })
                  } else {
                    this.setState({ profileActive: false })
                  }
                  if (redirect_guest_to_link_via_event) {
                    this.setState({ redirect_guest_to_link_via_event: true })
                  } else {
                    this.setState({ redirect_guest_to_link_via_event: false })
                  }
                  if (hide_contact_info_on_order) {
                    this.setState({ hide_contact_info_on_order: true })
                  } else {
                    this.setState({ hide_contact_info_on_order: false })
                  }
                  if (this.state.openedDirectlyInfos) {
                    this.props.removeFillUpAddress()
                    this.setState({
                      rightModalSettings: false,
                    });
                  }
                }}
                color="primary"
              >
                ANNULEREN
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={this.state.beforeCloseMenuConfirmVoucher}
            onClose={() =>
              this.setState({ beforeCloseMenuConfirmVoucher: false })
            }
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={{ zIndex: 99999999999999999 }}
            className="edit-dialog-cdd smallertitleedcdd"
          >
            <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Weet je zeker dat je verder <br /> wilt zonder op te slaan?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() =>
                  this.setState({ beforeCloseMenuConfirmVoucher: false })
                }
                color="primary"
                className="close-modal-btn-edcd"
              >
                <CloseIcon />
              </Button>
              <Button
                onClick={() => {
                  this.setState({
                    beforeCloseMenuConfirmVoucher: false,
                    voucherSettings: false,
                  });
                  this.saveVoucherSettings();
                }}
                color="primary"
              >
                OPSLAAN
              </Button>
              <Button
                onClick={() => {
                  this.setState({
                    beforeCloseMenuConfirmVoucher: false,
                  });
                }}
                color="primary"
              >
                NIET OPSLAAN
              </Button>
              <Button
                onClick={() => {
                  const {
                    useOfVoucherLocalEvent,
                    useOfVoucherKortingsVoucher,
                    useOfVoucherWebShop,
                  } = this.state.currentUserMember;

                  let webshopCodeSaloonCompany = ""
                  let syncAdminCodeCompany = true

                  if (this.state.currentUserMember.barber_new) {
                    webshopCodeSaloonCompany = this.state.currentUserMember.webshopCodeSaloon || ""
                    syncAdminCodeCompany = this.state.currentUserMember.syncAdminCode || false
                  }

                  this.setState({
                    beforeCloseMenuConfirmVoucher: false,
                    voucherSettings: false,
                    useOfVoucherLocalEvent,
                    useOfVoucherKortingsVoucher,
                    useOfVoucherWebShop,
                    webshopCodeSaloon: webshopCodeSaloonCompany,
                    syncAdminCode: syncAdminCodeCompany
                  });
                }}
                color="primary"
              >
                ANNULEREN
              </Button>
            </DialogActions>
          </Dialog>


          <Dialog
            open={this.state.beforeCloseMenuConfirmProducten}
            onClose={() =>
              this.setState({ beforeCloseMenuConfirmProducten: false })
            }
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={{ zIndex: 99999999999999999 }}
            className="edit-dialog-cdd smallertitleedcdd"
          >
            <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Weet je zeker dat je verder <br /> wilt zonder op te slaan?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() =>
                  this.setState({ beforeCloseMenuConfirmProducten: false })
                }
                color="primary"
                className="close-modal-btn-edcd"
              >
                <CloseIcon />
              </Button>
              <Button
                onClick={() => {
                  this.setState({
                    beforeCloseMenuConfirmProducten: false,
                    productSettings: false,
                  });
                  this.saveProductSettings();
                }}
                color="primary"
              >
                OPSLAAN
              </Button>
              <Button
                onClick={() => {
                  this.setState({
                    beforeCloseMenuConfirmProducten: false,
                  });
                }}
                color="primary"
              >
                NIET OPSLAAN
              </Button>
              <Button
                onClick={() => {
                  const {
                    maxProductsToShow,
                    intervalTimer,
                  } = this.state.currentUserMember.productsInfo;
                  this.setState({
                    beforeCloseMenuConfirmProducten: false,
                    productSettings: false,
                    maxProductsToShow: maxProductsToShow ? maxProductsToShow : 4,
                    intervalTimer: intervalTimer ? intervalTimer : "",
                  });
                }}
                color="primary"
              >
                ANNULEREN
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={this.state.beforeCloseMenuConfirmPrivacy}
            onClose={() =>
              this.setState({ beforeCloseMenuConfirmPrivacy: false })
            }
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={{ zIndex: 99999999999999999 }}
            className="edit-dialog-cdd smallertitleedcdd"
          >
            <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Weet je zeker dat je verder <br /> wilt zonder op te slaan?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() =>
                  this.setState({ beforeCloseMenuConfirmPrivacy: false })
                }
                color="primary"
                className="close-modal-btn-edcd"
              >
                <CloseIcon />
              </Button>
              <Button
                onClick={() => {
                  this.setState({
                    beforeCloseMenuConfirmPrivacy: false,
                    privacyRight: false,
                  });
                  this.savePrivacy();
                }}
                color="primary"
              >
                OPSLAAN
              </Button>
              <Button
                onClick={() => {
                  this.setState({
                    beforeCloseMenuConfirmPrivacy: false,
                  });
                }}
                color="primary"
              >
                NIET OPSLAAN
              </Button>
              <Button
                onClick={() => {
                  const { hideFriendsInfo } = this.state.currentUserMember;
                  let hideFriendsMain = false;
                  if (hideFriendsInfo) {
                    hideFriendsMain = hideFriendsInfo
                  }
                  this.setState({
                    beforeCloseMenuConfirmPrivacy: false,
                    privacyRight: false,
                    checkedHideFriendsInfo: hideFriendsMain,
                  });
                }}
                color="primary"
              >
                ANNULEREN
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={this.state.beforeCloseMenuConfirmInloggevens}
            onClose={() =>
              this.setState({ beforeCloseMenuConfirmInloggevens: false })
            }
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={{ zIndex: 99999999999999999 }}
            className="edit-dialog-cdd smallertitleedcdd"
          >
            <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Weet je zeker dat je verder <br /> wilt zonder op te slaan?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() =>
                  this.setState({ beforeCloseMenuConfirmInloggevens: false })
                }
                color="primary"
                className="close-modal-btn-edcd"
              >
                <CloseIcon />
              </Button>
              <Button
                onClick={() => {
                  this.setState({
                    beforeCloseMenuConfirmInloggevens: false,
                    // emailPassword: false,
                  });
                  this.saveEmailPassword();
                }}
                color="primary"
              >
                OPSLAAN
              </Button>
              <Button
                onClick={() => {
                  this.setState({
                    beforeCloseMenuConfirmInloggevens: false,
                  });
                }}
                color="primary"
              >
                NIET OPSLAAN
              </Button>
              <Button
                onClick={() => {
                  const { email, user_name_id } = this.state.currentUserMember;
                  this.setState({
                    beforeCloseMenuConfirmInloggevens: false,
                    emailPassword: false,
                    email: email,
                    url: user_name_id,
                    currentPassword: "",
                    password: "",
                    confirmPassword: "",
                  });
                }}
                color="primary"
              >
                ANNULEREN
              </Button>
            </DialogActions>
          </Dialog>

          {this.state.verwijderPopupDel && (
            <div className="overlay-delete-account">
              <div className="inner-odacc">
                <div className="left-iodacc"></div>
                <div className="right-iodacc">
                  <CloseIcon
                    onClick={() => this.setState({ verwijderPopupDel: false })}
                  />
                  <h4>ACCOUNT VERWIJDEREN</h4>
                  {this.state.showDelete ? (<p>
                    Je staat op het punt om je account<br />
                    te verwijderen. Hierna kun je niet<br />
                    meer inloggen met dit account.
                    <br />
                    <br />
                    Voer je wachtwoord opnieuw in<br />
                    om je account te verwijderen.
                    {/* We want to get know your account password in order to delete your account. */}
                  </p>) : (<p>
                    Je staat op het punt om je account<br />
                    te verwijderen. Hierna kun je niet<br />
                    meer inloggen met dit account. Je<br />
                    maandelijkse bijdrage wordt ook<br />
                    automatisch stopgezet.
                    <br />
                    <br />
                    Voer je wachtwoord opnieuw in<br />
                    om je account te verwijderen.
                  </p>)}
                  <div className="input-riodacc">
                    <label>Jouw  wachtwoord</label>
                    <input type="password" value={this.state.passwordForDel} onChange={(e) => {
                      this.setState({ passwordForDel: e.target.value })
                    }} />
                    {this.state.passwordRequiredDel && (
                      <label style={{ color: "red" }}>Please enter password to delete</label>
                    )}
                    {this.state.passwordWrongDel && (
                      <label style={{ color: "red" }}>Please enter correct password</label>
                    )}
                  </div>
                  {this.state.deleteBtnText !== "SAVE" ? (
                    <button>DELETING</button>
                  ) : (
                    <button onClick={this.deleteCurrentUser.bind(this)}>
                      VERWIJDEREN
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}

        </div>
      )
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducers.user,
    album: state.postsReducers.album,
    images: state.postsReducers.images,
    product: state.campaignsReducer.product,
    global_campaigns: state.campaignsReducer.global_campaigns,
    all_impressies: state.globalReducers.all_impressies,
    guest_user_cart: state.cartReducer.guest_user_cart,
    products_new: state.campaignsReducer.products_new,
    message: state.authReducers.message,
    charity_videos: state.globalReducers.charity_videos,
    bhodi_abouts: state.globalReducers.bhodi_abouts,
    bhodi_faqs: state.globalReducers.bhodi_faqs,
    bhodi_products: state.productsReducers.bhodi_products,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateGuestUserCart: (guest_user_cart) =>
      dispatch(updateGuestUserCart(guest_user_cart)),
    updateGuestUserCartTime: (guest_user_cart_time) =>
      dispatch(updateGuestUserCartTime(guest_user_cart_time)),
    updateBhodiAbouts: (bhodi_abouts) => dispatch(updateBhodiAbouts(bhodi_abouts)),
    updateBhodiFaqs: (bhodi_faqs) => dispatch(updateBhodiFaqs(bhodi_faqs)),
    updateGuestUserCartExtendOpportunity: (
      guest_user_cart_extend_opportunity
    ) =>
      dispatch(
        updateGuestUserCartExtendOpportunity(guest_user_cart_extend_opportunity)
      ),
    updateSignUpFieldsFilled: (signup_fields_info) =>
      dispatch(updateSignUpFieldsFilled(signup_fields_info)),
    updateBhodiProducts: (bhodi_products) => dispatch(updateBhodiProducts(bhodi_products)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavbarMainFullWidthCompany);
