import React, { Component } from 'react'
import { connect } from 'react-redux';
import TimerGlobalPages from './TimerGlobalPages';

export class GlobalEventTime extends Component {

    constructor() {
        super()
        this.state = {

        }
    }

    componentDidMount() {
        if (this.props.global_values) {
            let a = this.props.global_values;
            if (a) {
                this.setState({ timerDate: a.globalTimerDate, timerTime: a.globalTimerTime })
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.global_values !== this.props.global_values) {
            let a = this.props.global_values;
            if (a) {
                this.setState({ timerDate: a.globalTimerDate, timerTime: a.globalTimerTime })
            }
        }

    }

    render() {
        return (
            <TimerGlobalPages timerDate={this.state.timerDate} timerTime={this.state.timerTime} />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        global_values: state.globalReducers.global_values,
    };
};

export default connect(mapStateToProps)(GlobalEventTime);