import React, { Component } from "react";
import { connect } from "react-redux";
import firebase from "../../Config/Firebase";
import swal from "sweetalert";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CloseIcon from "@material-ui/icons/Close";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Switch from "@material-ui/core/Switch";
import PublishIcon from "@material-ui/icons/Publish";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { BiCalendar } from "react-icons/bi";
import { MdClose, MdTimer } from "react-icons/md";
import { GoDeviceDesktop } from "react-icons/go";
import { CiMobile3 } from "react-icons/ci"
import { history } from "../../Config/Routes";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {
    updateCategoriesVouchers,
} from "../../Redux/actions/authActions";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { mergeAdminEvent } from "../../Functions/globalFunctions";

let stockImageRef = firebase.database().ref("stock_images")
let campaignsCategoryRef = firebase.database().ref("bhodi_campaigns_category")
let categoriesVouchersRef = firebase.database().ref("bhodi_categories_vouchers")
let campaignsInfoRef = firebase.database().ref("campaigns_info")

class CustomCKEditor extends Component {
    constructor(props) {
        super(props);
        this.editorRef = React.createRef();
    }

    componentDidMount() {
        // Initialize CKEditor
        ClassicEditor.create(this.editorRef.current, {
            toolbar: [],
        })
            .then(editor => {
                this.editor = editor;
                // Set initial data
                editor.setData(this.props.data);
            })
            .catch(error => {
                console.error(error);
            });
    }

    componentWillUnmount() {
        if (this.editor) {
            this.editor.destroy().then(() => {
                // Editor destroyed
            });
        }
    }

    render() {
        return <div ref={this.editorRef} />;
    }
}

export class AddEditLocalEvent extends Component {
    constructor() {
        super();
        this.state = {
            liveEventCompany: [],
            currentCampaigns: [],
            isDesktop: false,
            campaignsToShow: [],
            liveEventBlock: [],
            openAddPopupLiveEvent: false,
            liveEventChatActive: false,
            descriptionLiveEvent: "",
            placeHolderIsImage: true,
            alertEventDescription: false,
            noCategorySelectedLiveEvent: false,
            currentOpenedCheck: true,
            hideOnGlobalEvent: false,
            hideOnGlobalVoucher: false,
            showTimerToGuests: false,
            discountAvailable: true,
            showViewersCountOnStream: true,
            stockEditByRetailer: false,
            currentUserHere: "",
            startDateLiveEvent: "",
            startTimeLiveEvent: "",
            allTimes: [
                { label: "01:00" },
                { label: "01:15" },
                { label: "01:30" },
                { label: "01:45" },
                { label: "02:00" },
                { label: "02:15" },
                { label: "02:30" },
                { label: "02:45" },
                { label: "03:00" },
                { label: "03:15" },
                { label: "03:30" },
                { label: "03:45" },
                { label: "04:00" },
                { label: "04:15" },
                { label: "04:30" },
                { label: "04:45" },
                { label: "05:00" },
                { label: "05:15" },
                { label: "05:30" },
                { label: "05:45" },
                { label: "06:00" },
                { label: "06:15" },
                { label: "06:30" },
                { label: "06:45" },
                { label: "07:00" },
                { label: "07:15" },
                { label: "07:30" },
                { label: "07:45" },
                { label: "08:00" },
                { label: "08:15" },
                { label: "08:30" },
                { label: "08:45" },
                { label: "09:00" },
                { label: "09:15" },
                { label: "09:30" },
                { label: "09:45" },
                { label: "10:00" },
                { label: "10:15" },
                { label: "10:30" },
                { label: "10:45" },
                { label: "11:00" },
                { label: "11:15" },
                { label: "11:30" },
                { label: "11:45" },
                { label: "12:00" },
                { label: "12:15" },
                { label: "12:30" },
                { label: "12:45" },
                { label: "13:00" },
                { label: "13:15" },
                { label: "13:30" },
                { label: "13:45" },
                { label: "14:00" },
                { label: "14:15" },
                { label: "14:30" },
                { label: "14:45" },
                { label: "15:00" },
                { label: "15:15" },
                { label: "15:30" },
                { label: "15:45" },
                { label: "16:00" },
                { label: "16:15" },
                { label: "16:30" },
                { label: "16:45" },
                { label: "17:00" },
                { label: "17:15" },
                { label: "17:30" },
                { label: "17:45" },
                { label: "18:00" },
                { label: "18:15" },
                { label: "18:30" },
                { label: "18:45" },
                { label: "19:00" },
                { label: "19:15" },
                { label: "19:30" },
                { label: "19:45" },
                { label: "20:00" },
                { label: "20:15" },
                { label: "20:30" },
                { label: "20:45" },
                { label: "21:00" },
                { label: "21:15" },
                { label: "21:30" },
                { label: "21:45" },
                { label: "22:00" },
                { label: "22:15" },
                { label: "22:30" },
                { label: "22:45" },
                { label: "23:00" },
                { label: "23:15" },
                { label: "23:30" },
                { label: "23:45" },
                { label: "24:00" },
            ],
            selectedMain: [],
            comment: "",
            productsArray: [],
            sizePopupModal: false,
            localEventNumber: 1,
            notShowTimerGuest: false,
            currentUser: "",
            completeCSVfromDB: [],
            maxTimer: "",
            currentChatUser: "",
            descInfolocalEvent: "",
            allSectors: [],
            currentUserId: "",
            localEventImageType: "uploadfromcomp",
            localEventUnlimited: true,
            localEventTitle: "",
            localEventFromAdmin: false,
            eventForGuestUser: false,
            manual_code_import: false,
            hide_date_on_page: false,
            localEventDesc: "",
            localEventShortDesc: "",
            localEventLongDesc: "",
            localEventInfoMobile: "",
            localEventAmount: "",
            hideLocalEventWork: false,
            localEventPercentage: true,
            localEventImage: [
                "https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/placeholder.jpg?alt=media&token=3146a89c-5b95-4a6b-b60f-fb6b0f166bdb",
            ],
            dropFilelocalEvent: [],
            allImagesStock: [],
            localEventBlock: [],
            eventStockZero: false,
            allImagesCurrentSectorStock: [],
            localEventPlaceHolderIsImage: true,
            localEventNumber: 0,
            showLiveEventIcons: false,
            localEventAccessible: false,
            localEventShowOnGlobal: false,
            localEventIfSharedThen: [],
            onlyShowStockToEdit: false,
            selectRetailersList: [],
            currentUser: "",
            onCurrentIndexEvents: 0,
            localEventBlockOnEdit: false,
            allValuesLoaded: false,
            showingLoaderCreateEdit: false,
            isGuestUser: false,
            vendorStockEvent: "unlimited",
            companyCurrentEvent: "",

            // kortingsvoucher
            kortingsVoucherImageType: "uploadfromcomp",
            kortingsVoucherImageTypeMobile: "uploadfromcomp",
            kortingsVoucherUnlimited: true,
            kortingsVoucherTitle: "",
            kortingsVoucherDesc: "A",
            kortingsVoucherInfoMobile: "",
            kortingsVoucherNumber: "",
            kortingsVoucherAmount: "",
            kortingsVoucherSalePrice: "",
            kortingsVoucherNormalPrice: "",
            kortingsVoucherPrijs: true,
            kortingsVoucherImage: [
                "https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/placeholder.jpg?alt=media&token=3146a89c-5b95-4a6b-b60f-fb6b0f166bdb",
            ],
            kortingsVoucherImageMobile: [
                "https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/placeholder.jpg?alt=media&token=3146a89c-5b95-4a6b-b60f-fb6b0f166bdb",
            ],
            kortingsVoucherSelectedMobile: false,
            dropFileKortingsVoucher: [],
            dropFileKortingsVoucherMobile: [],
            kortingsVoucherBlock: [],
            descInfoKortingsVoucher: "",
            companyCurrentVoucher: "",
            completeCategories: [],
            all_categories: [],
            editKortingsVoucherNum: 0,
            kortingsVoucherPercentage: false,
            kortingsVoucherGiveaway: false,


            // webshop
            voucherImageType: "uploadfromcomp",
            voucherImageTypeMobile: "uploadfromcomp",
            voucherUnlimited: true,
            voucherIsPercentage: false,
            voucherTitle: "",
            voucherWebshopUrl: "",
            voucherDesc: "",
            voucherInfoMobile: "",
            voucherNumber: "",
            voucherAmount: "",
            voucherImage: [
                "https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/placeholder.jpg?alt=media&token=3146a89c-5b95-4a6b-b60f-fb6b0f166bdb",
            ],
            voucherImageMobile: [
                "https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/placeholder.jpg?alt=media&token=3146a89c-5b95-4a6b-b60f-fb6b0f166bdb",
            ],
            voucherSelectedMobile: false,
            dropFileVoucher: [],
            dropFileVoucherMobile: [],
            webShopBlock: [],
            descInfoWebShop: "",
            companyCurrentWebshop: "",
            editWebShopNum: 0,
            timerActiveOnWebshop: true,
            hideForGuestUsers: false,
            voucherMultipleUseage: false,
            alreadyMultipleUsage: false,

            multipleTimesOpened: false,
            arrEvents: [],
            couponsCreated: [],
        };
        this.kortingsVoucherDescRef = React.createRef()
        this.voucherDescRef = React.createRef()
        this.localEventDescRef = React.createRef()
        this.localEventShortDescRef = React.createRef()
        this.localEventLongDescRef = React.createRef()
    }

    async componentDidMount() {
        setTimeout(() => {
            this.setState({ showDescComponent: true })
        }, 2000);

        if (window.innerWidth < 1008 && document.querySelector(".navbarfullwidthMobile")) {
            document.querySelector(".navbarfullwidthMobile").classList.add("navbarfullwidthoverflowxhidden")
        }

        const loaderId = document.getElementById("main-page-loader-id");
        loaderId.style.display = "none";

        // local event
        let newDateLocalEvent = new Date();
        let monthTodayLocalEvent = newDateLocalEvent.getMonth() + 1;
        let dateTodayToMakeLocalEvent = newDateLocalEvent.getDate();
        if (monthTodayLocalEvent < 10) {
            monthTodayLocalEvent = `0${monthTodayLocalEvent}`;
        }
        if (dateTodayToMakeLocalEvent < 10) {
            dateTodayToMakeLocalEvent = `0${dateTodayToMakeLocalEvent}`;
        }
        let makeTodayLocalEvent = `${newDateLocalEvent.getFullYear()}-${monthTodayLocalEvent}-${dateTodayToMakeLocalEvent}`;

        let objToPush = {
            startDateEvent: makeTodayLocalEvent,
            startTimeEvent: "",
            endDateEvent: "",
            endTimeEvent: "",
            personsAmountEvent: "",
            eventId: 1,
        };
        let arrEvents = [objToPush];
        this.setState({
            startDateEvent: makeTodayLocalEvent,
            startDateLiveEvent: makeTodayLocalEvent,
            todayDateToBeMax: makeTodayLocalEvent,
            endDateEvent: makeTodayLocalEvent,
            arrEvents,
            arrEventsDuplicated: arrEvents,
        });

        let personsAmount = [];
        for (let i = 0; i < 50; i++) {
            let numberToAdd = i + 1;
            if (numberToAdd < 10) {
                numberToAdd = `0${numberToAdd}`;
            }
            personsAmount.push(`${numberToAdd}`);
        }
        this.setState({ personsAmount });
        // local event

        document.getElementById("root").style.overflow = "hidden";

        window.addEventListener("resize", () => {
            if (window.innerWidth < 1009) {
                this.setState({ isDesktop: false });
            } else {
                this.setState({ isDesktop: true });
            }
        });
        if (window.innerWidth < 1009) {
            this.setState({ isDesktop: false });
        } else {
            this.setState({ isDesktop: true });
        }

        let uid = localStorage.getItem("userid");


        if (uid) {
            let filter = this.props.user.filter((u) => {
                return u.user_id === uid;
            });
            if (filter.length > 0) {
                let currentData = filter[0];
                let a = currentData;
                this.setState({
                    currentUserHere: currentData,
                    currentUserId: uid,
                });
            }
            firebase
                .database()
                .ref("users/" + uid)
                .on("value", (data) => {
                    this.avoidInputsClear()
                    let a = data.val();
                    if (a) {
                        this.setState({ currentUserHere: a });
                    }
                });
        }

        if (this.props.global_values) {
            let a = this.props.global_values;
            if (a) {
                if (a.localEventInfoMobile) {
                    this.setState({ localEventInfoMobile: a.localEventInfoMobile });
                }
            }
        }

        if (this.props.stock_images) {
            this.setState({ allImagesStock: this.props.stock_images });
        }


        stockImageRef.on("value", (data) => {
            this.avoidInputsClear()

            let a = data.val();
            if (a) {
                let objected = Object.entries(a);
                this.setState({ allImagesStock: objected });
            } else {
                this.setState({ allImagesStock: [] });
            }
        });

        // if (this.props.globalsectors) {
        //     if (this.props.globalsectors.length > 0) {
        //         let arr = this.props.globalsectors;
        //         this.setState({ allSectors: arr });
        //         campaignsCategoryRef.on("value", (data) => {
        //             let b = data.val();
        //             if (b) {
        //                 this.setState({ categoryData: b });
        //             }
        //         });
        //         if (arr.length > 0) {
        //             if (this.state.selectedSector) {
        //                 this.setState({ sectors: arr });
        //             } else {
        //                 this.setState({ sectors: arr, currentSector: arr[0] });
        //             }
        //             categoriesVouchersRef.on("value", (data) => {
        //                 this.avoidInputsClear()

        //                 let a = data.val();
        //                 let arrSec = [];
        //                 if (a) {
        //                     let objected = Object.entries(a);
        //                     objected.map((g) => {
        //                         if (g[0] === arr[0].sector_id) {
        //                             arrSec = g[1];
        //                         }
        //                     });
        //                     let categories = [];
        //                     arrSec.map((h) => {
        //                         if (h.children) {
        //                             let obj = {
        //                                 title: h.title,
        //                                 category_id: h.category_id,
        //                                 sub_category: h.children,
        //                             };
        //                             categories.push(obj);
        //                         } else {
        //                             let obj = {
        //                                 title: h.title,
        //                                 category_id: h.category_id,
        //                             };
        //                             categories.push(obj);
        //                         }
        //                     });
        //                     if (categories.length > 0) {
        //                         this.setState({
        //                             completeCategories: objected,
        //                             treeData: arrSec,
        //                         });
        //                     }
        //                 } else {
        //                     this.setState({ completeCategories: [] });
        //                 }

        //                 this.setState({ allValuesLoaded: true });
        //                 if (this.state.showingLoaderCreateEdit) {
        //                     setTimeout(() => {
        //                         this.setState({ showingLoaderCreateEdit: false });
        //                     }, 2000);
        //                 }
        //             });
        //         }
        //     }
        // }

        if (this.props.globalsectors) {
            if (this.props.globalsectors.length > 0) {
                let arr = this.props.globalsectors;
                this.setState({ allSectors: arr });


                if (this.props.campaigns_category) {
                    this.setState({ categoryData: this.props.campaigns_category })
                }
                if (arr.length > 0) {
                    if (this.state.selectedSector) {
                        this.setState({ sectors: arr });
                    } else {
                        this.setState({ sectors: arr, currentSector: arr[0] });
                    }
                    if (this.props.categories_vouchers && this.props.campaigns_category) {
                        let a = this.props.categories_vouchers;
                        let arrSec = [];
                        if (a) {
                            let objected = Object.entries(a);
                            objected.map((g) => {
                                if (g[0] === arr[0].sector_id) {
                                    arrSec = g[1];
                                }
                            });
                            let categories = [];
                            arrSec.map((h) => {
                                if (h.children) {
                                    let obj = {
                                        title: h.title,
                                        category_id: h.category_id,
                                        sub_category: h.children,
                                    };
                                    categories.push(obj);
                                } else {
                                    let obj = {
                                        title: h.title,
                                        category_id: h.category_id,
                                    };
                                    categories.push(obj);
                                }
                            });
                            if (categories.length > 0) {
                                this.setState({
                                    completeCategories: objected,
                                    treeData: arrSec,
                                });
                            }
                        } else {
                            this.setState({ completeCategories: [] });
                        }

                        this.setState({ allValuesLoaded: true });
                        if (this.state.showingLoaderCreateEdit) {
                            setTimeout(() => {
                                this.setState({ showingLoaderCreateEdit: false });
                            }, 2000);
                        }
                    } else {
                        categoriesVouchersRef.on("value", (data) => {
                            let a = data.val();

                            this.avoidInputsClear()

                            this.props.updateCategoriesVouchers(a)
                            let arrSec = [];
                            if (a) {
                                let objected = Object.entries(a);
                                objected.map((g) => {
                                    if (g[0] === arr[0].sector_id) {
                                        arrSec = g[1];
                                    }
                                });
                                let categories = [];
                                arrSec.map((h) => {
                                    if (h.children) {
                                        let obj = {
                                            title: h.title,
                                            category_id: h.category_id,
                                            sub_category: h.children,
                                        };
                                        categories.push(obj);
                                    } else {
                                        let obj = {
                                            title: h.title,
                                            category_id: h.category_id,
                                        };
                                        categories.push(obj);
                                    }
                                });
                                if (categories.length > 0) {
                                    this.setState({
                                        completeCategories: objected,
                                        treeData: arrSec,
                                    });
                                }
                            } else {
                                this.setState({ completeCategories: [] });
                            }

                            this.setState({ allValuesLoaded: true });
                            if (this.state.showingLoaderCreateEdit) {
                                setTimeout(() => {
                                    this.setState({ showingLoaderCreateEdit: false });
                                }, 2000);
                            }
                        });
                    }
                }
            }
        }

        let newDate = new Date();
        let monthToday = newDate.getMonth() + 1;
        let dateTodayToMake = newDate.getDate();
        if (monthToday < 10) {
            monthToday = `0${monthToday}`;
        }
        if (dateTodayToMake < 10) {
            dateTodayToMake = `0${dateTodayToMake}`;
        }
        let makeToday = `${newDate.getFullYear()}-${monthToday}-${dateTodayToMake}`;

        this.setState({
            startDateLiveEvent: makeToday,
            todayDateToBeMax: makeToday,
        });

        campaignsInfoRef.on("value", (data) => {
            this.avoidInputsClear()

            let a = data.val();
            if (a) {
                if (a.localEventInfo) {
                    this.setState({ descInfolocalEvent: a.localEventInfo });
                }
                if (a.kortingsVoucherInfo) {
                    this.setState({ descInfoKortingsVoucher: a.kortingsVoucherInfo });
                }
                if (a.webShopInfo) {
                    this.setState({ descInfoWebShop: a.webShopInfo });
                }
            }
        });

        if (this.props.openAddPopup) {
            let filtered = this.props.global_campaigns.filter((t) => {
                return t[1].created_by === uid && t[1].campaignType === "local_event"
            })
            if (filtered.length > 0) {
                this.setState({ companyCurrentEvent: filtered[0][1] })
            }
            let filteredVoucher = this.props.global_campaigns.filter((t) => {
                return t[1].created_by === uid && t[1].campaignType === "kortings_voucher"
            })
            if (filteredVoucher.length > 0) {
                this.setState({ companyCurrentVoucher: filteredVoucher[0][1] })
            }
            if (this.props.doEditVoucher) {
                if (filteredVoucher.length > 0) {
                    this.fillEditFieldsVoucher(filteredVoucher[0][1])
                } else {
                    this.setState({
                        openAddPopup: true
                    })
                }
            } else if (this.props.doEditLocalEvent) {
                if (filtered.length > 0) {
                    this.fillEditFieldsEvent(filtered[0][1])
                } else {
                    this.setState({
                        openAddPopup: true
                    })
                }
            } else if (this.props.doEditWebShop) {
                let filteredWebShop = this.props.global_campaigns.filter((t) => {
                    return t[1].created_by === uid && t[1].campaignType === "web_shop" && t[1].pushKey === this.props.currentWebShopCampaign.pushKey
                })
                if (filteredWebShop.length > 0) {
                    this.setState({ companyCurrentWebshop: filteredWebShop[0][1] })
                }

                if (filteredWebShop.length > 0) {
                    this.fillEditFieldsWebshop(filteredWebShop[0][1])
                } else {
                    this.setState({
                        openAddPopup: true
                    })
                }
            } else {
                this.setState({
                    openAddPopup: true,
                });
            }
        }
    }

    fillEditFieldsEvent(currentCampaign) {

        this.setState({ showingLoaderCreateEdit: true, openAddPopupLocalEvent: true, openAddPopup: false })
        let e = currentCampaign

        if (e.takeFromAdmin) {
            let obj = mergeAdminEvent(e, this.props.global_campaigns)
            e = Object.assign({}, obj)
        }

        let filter = [e];
        let currentProduct = e;

        this.setState({
            localEventBlock: filter,
            localEventTitle: filter[0].title,
            localEventDesc: filter[0].desc,
            localEventShortDesc: filter[0].first_desc || filter[0].desc,
            localEventLongDesc: filter[0].second_desc || "",
            localEventAmount: filter[0].amount,
            localEventImage: [filter[0].image],
            currentOpenedCheck: filter[0].active,
            localEventNumber: filter[0].number,
            localEventUnlimited: filter[0].localEventUnlimited,
            localEventPlaceHolderIsImage: filter[0].localEventPlaceHolderIsImage,
            localEventBlockOnEdit: true,
            localEventFromAdmin: e.takeFromAdmin || false,
            eventForGuestUser: e.eventForGuestUser || false,
            manual_code_import: e.manual_code_import || false,
            hide_date_on_page: e.hide_date_on_page || false,
        });
        if (filter[0].hideOnGlobalEvent) {
            this.setState({ hideOnGlobalEvent: true })
        }
        if (filter[0].discountAvailable) {
            this.setState({ discountAvailable: true })
        } else {
            this.setState({ discountAvailable: false })
        }
        if (filter[0].timings) {
            let arrTimings = [];
            if (
                e.checked_in_by || e.inCartOf
            ) {

                filter[0].timings.map((h) => {
                    let cartAmountFiltered = 0;
                    if (e.inCartOf) {
                        let objected = Object.entries(e.inCartOf);
                        objected = objected.filter((t) => {
                            return t[1].event_id === h.eventId
                        })
                        cartAmountFiltered = objected.length;
                    }

                    let checkedInAmountFiltered = 0;
                    if (e.checked_in_by) {
                        let objected = Object.entries(e.checked_in_by);
                        objected = objected.filter((t) => {
                            return t[1].event_id === h.eventId
                        })
                        checkedInAmountFiltered = objected.length;
                    }


                    h.personsAmountEvent = Number(h.personsAmountEvent) - cartAmountFiltered - checkedInAmountFiltered;
                    if (Number(h.personsAmountEvent) < 10) {
                        h.personsAmountEvent = `0${h.personsAmountEvent}`;
                    } else {
                        h.personsAmountEvent = `${h.personsAmountEvent}`;
                    }
                    arrTimings.push(h);
                });
            } else {
                filter[0].timings.map((h) => {
                    arrTimings.push(h);
                });
            }
            if (arrTimings.length > 1) {
                this.setState({ multipleTimesOpened: true })
            }
            this.setState({
                arrEvents: arrTimings,
                arrTimingsToCheck: arrTimings,
            });
        }

        if (filter[0].localEventImageType) {
            this.setState({
                localEventImageType:
                    filter[0].localEventImageType,
            });
        }
        if (filter[0].mainSectorStock) {
            let filteredSecond =
                this.props.stock_images.filter((u) => {
                    return (u[0] === filter[0].mainSectorStock);
                });
            if (filteredSecond.length > 0) {
                let a = filteredSecond[0];
                let arr = [];
                if (a.length > 0) {
                    let objected = Object.entries(a[1]);
                    objected.map((y) => {
                        arr.push(y[1]);
                    });
                    this.setState({
                        allImagesCurrentSectorStock: arr,
                    });
                } else {
                    this.setState({
                        allImagesCurrentSectorStock: [],
                    });
                }
            }
            this.setState({
                mainSectorStock: filter[0].mainSectorStock,
            });
        }
        if (currentProduct.main_category) {
            let arrSec = [];
            this.state.completeCategories.map(
                (g) => {
                    if (g[0] === currentProduct.main_sector) {
                        arrSec = g[1];
                    }
                }
            );
            let categories = [];
            arrSec.map((h) => {
                let obj = {
                    title: h.title,
                    category_id: h.category_id,
                    sub_category: h.children,
                };
                categories.push(obj);
            });
            categories.map((f) => {
                if (
                    f.category_id ===
                    currentProduct.main_category
                ) {
                    if (f.sub_category) {
                        this.setState({
                            subCategoryPresent: true,
                        });
                    } else {
                        this.setState({
                            subCategoryPresent: false,
                        });
                    }
                    this.setState({
                        sub_categories: f.sub_category,
                    });
                }
            });
            this.setState({
                selectedSector: currentProduct.main_sector,
                selectedMain: currentProduct.main_category,
                categorySelected: true,
            });
            this.setState({
                all_categories: categories,
            });
        }
        if (filter[0].localEventType === "percentage") {
            this.setState({
                localEventPercentage: true,
            });
        } else {
            this.setState({
                localEventPercentage: false,
            });
        }
        setTimeout(() => {
            if (this.state.allValuesLoaded) {
                this.setState({
                    showingLoaderCreateEdit: false,
                });
            }
        }, 3000);
    }

    fillEditFieldsVoucher(currentCampaign) {

        this.setState({ showingLoaderCreateEdit: true, openAddPopupKortingsvoucher: true, openAddPopup: false })
        let e = currentCampaign

        let filter = [e];
        let currentProduct = e;

        let num = 0;
        if (filter[0].verzilvered_by) {
            if (filter[0].verzilvered_by) {
                let objectedVerzilveredBy = Object.entries(filter[0].verzilvered_by);
                num = this.getVerzilveredQty(objectedVerzilveredBy);
            }
        }
        let inCartOfNum = 0;
        if (filter[0].inCartOf) {
            let objected = Object.entries(filter[0].inCartOf);
            inCartOfNum = objected.length;
        }

        this.setState({
            kortingsVoucherBlock: filter,
            kortingsVoucherTitle: filter[0].title,
            kortingsVoucherDesc: filter[0].desc,
            kortingsVoucherImage: [filter[0].image],
            kortingsVoucherPrijs: filter[0].kortingsVoucherPrijs,
            currentOpenedCheck: filter[0].active,
            kortingsVoucherNumber: filter[0].number - num - inCartOfNum,
            kortingsVoucherUnlimited: filter[0].kortingsVoucherUnlimited,
            kortingsVoucherBlockOnEdit: true,
            editKortingsVoucherNum: filter[0].number - num - inCartOfNum,
        });
        if (filter[0].hideOnGlobal) {
            this.setState({ hideOnGlobalVoucher: true })
        }
        if (filter[0].showTimerToGuests) {
            this.setState({ showTimerToGuests: true })
        }

        if (filter[0].kortingsVoucherPercentage) {
            this.setState({
                kortingsVoucherPercentage: filter[0].kortingsVoucherPercentage,
            })
        } else {
            this.setState({
                kortingsVoucherPercentage: false,
            })
        }
        if (filter[0].kortingsVoucherGiveaway) {
            this.setState({
                kortingsVoucherGiveaway: filter[0].kortingsVoucherGiveaway,
            })
        } else {
            this.setState({
                kortingsVoucherGiveaway: false,
            })
        }
        if (filter[0].kortingsVoucherGiveaway) {
            this.setState({ kortingsVoucherAmount: "Free" })
        } else if (filter[0].kortingsVoucherPrijs || filter[0].kortingsVoucherPercentage) {
            this.setState({ kortingsVoucherAmount: filter[0].amount })
        } else {
            this.setState({
                kortingsVoucherNormalPrice: filter[0].kortingsVoucherNormalPrice,
                kortingsVoucherSalePrice: filter[0].kortingsVoucherSalePrice
            })

            if (filter[0].timerTimeWaitingEndTime && filter[0].timerDateWaitingEndTime) {
                this.setState({
                    timerDateWaitingEndTime: filter[0].timerDateWaitingEndTime,
                    timerTimeWaitingEndTime: filter[0].timerTimeWaitingEndTime,
                })
            }
        }

        if (filter[0].timerTimeWaiting && filter[0].timerDateWaiting) {
            this.setState({
                timerDateWaiting: filter[0].timerDateWaiting,
                timerTimeWaiting: filter[0].timerTimeWaiting,
            })
        }

        if (filter[0].image_mobile) {
            if (filter[0].kortingsVoucherImageTypeMobile) {
                this.setState({
                    kortingsVoucherImageTypeMobile: filter[0].kortingsVoucherImageTypeMobile,
                });
            }
            this.setState({
                kortingsVoucherImageMobile: [filter[0].image_mobile],
            });

            if (filter[0].mainSectorStockMobile) {
                this.setState({
                    mainSectorStockMobile: filter[0].mainSectorStockMobile,
                });
            }
        }

        if (filter[0].kortingsVoucherImageType) {
            this.setState({
                kortingsVoucherImageType: filter[0].kortingsVoucherImageType,
            });
        }
        if (filter[0].mainSectorStock) {
            let filteredSecond =
                this.props.stock_images.filter((u) => {
                    return (u[0] === filter[0].mainSectorStock);
                });
            if (filteredSecond.length > 0) {
                let a = filteredSecond[0];
                let arr = [];
                if (a.length > 0) {
                    let objected = Object.entries(a[1]);
                    objected.map((y) => {
                        arr.push(y[1]);
                    });
                    this.setState({
                        allImagesCurrentSectorStock: arr,
                    });
                } else {
                    this.setState({
                        allImagesCurrentSectorStock: [],
                    });
                }
            }
            this.setState({
                mainSectorStock: filter[0].mainSectorStock,
            });
        }
        if (currentProduct.main_category) {
            let arrSec = [];
            let promiseCategories = new Promise((res, rej) => {
                setInterval(() => {
                    if (this.state.completeCategories.length > 0) {
                        res()
                    }
                }, 10);
            })
            promiseCategories.then(() => {
                this.state.completeCategories.map(
                    (g) => {
                        if (g[0] === currentProduct.main_sector) {
                            arrSec = g[1];
                        }
                    }
                );
                let categories = [];
                arrSec.map((h) => {
                    let obj = {
                        title: h.title,
                        category_id: h.category_id,
                        sub_category: h.children,
                    };
                    categories.push(obj);
                });
                categories.map((f) => {
                    if (
                        f.category_id ===
                        currentProduct.main_category
                    ) {
                        if (f.sub_category) {
                            this.setState({
                                subCategoryPresent: true,
                            });
                        } else {
                            this.setState({
                                subCategoryPresent: false,
                            });
                        }
                        this.setState({
                            sub_categories: f.sub_category,
                        });
                    }
                });
                this.setState({
                    selectedSector: currentProduct.main_sector,
                    selectedMain: currentProduct.main_category,
                    categorySelected: true,
                });
                this.setState({
                    all_categories: categories,
                });
            })
        }

        setTimeout(() => {
            if (this.state.allValuesLoaded) {
                this.setState({
                    showingLoaderCreateEdit: false,
                });
            }
        }, 3000);
    }


    fillEditFieldsWebshop(currentCampaign) {

        this.setState({ showingLoaderCreateEdit: true, openAddPopupWebShop: true, openAddPopup: false })
        let e = currentCampaign

        let filter = [e];
        let currentProduct = e;

        let num = 0;
        if (filter[0].verzilvered_by) {
            if (filter[0].verzilvered_by) {
                let objectedVerzilveredBy = Object.entries(filter[0].verzilvered_by);
                num = this.getVerzilveredQty(objectedVerzilveredBy);
            }
        }
        let inCartOfNum = 0;
        if (filter[0].inCartOf) {
            let objected = Object.entries(filter[0].inCartOf);
            inCartOfNum = objected.length;
        }

        this.setState({
            webShopBlock: filter,
            voucherTitle: filter[0].title,
            voucherWebshopUrl: filter[0].voucherWebshopUrl || "",
            voucherDesc: filter[0].desc,
            voucherImage: [filter[0].image],
            voucherAmount: filter[0].amount,
            voucherIsPercentage: filter[0].voucherIsPercentage || false,
            currentOpenedCheck: filter[0].active,
            voucherNumber: filter[0].number - num - inCartOfNum,
            voucherUnlimited: filter[0].voucherUnlimited,
            webShopBlockOnEdit: true,
            editWebShopNum: filter[0].number - num - inCartOfNum,
            dropFileVoucher: [],
            webShopBlockOnEdit: true,
            couponsCreated: filter[0].coupons,
            timerActiveOnWebshop: filter[0].timerActiveOnWebshop,
        });
        if (filter[0].hideForGuestUsers) {
            this.setState({ hideForGuestUsers: true })
        } else {
            this.setState({ hideForGuestUsers: false })
        }
        if (filter[0].voucherMultipleUseage) {
            this.setState({ voucherMultipleUseage: true, alreadyMultipleUsage: true })
        } else {
            this.setState({ voucherMultipleUseage: false, alreadyMultipleUsage: false })
        }
        if (filter[0].timerTimeWaiting && filter[0].timerDateWaiting) {
            this.setState({
                timerDateWaiting: filter[0].timerDateWaiting,
                timerTimeWaiting: filter[0].timerTimeWaiting,
            })
        }
        if (filter[0].voucherStartDate && filter[0].voucherStartTime && filter[0].voucherEndDate && filter[0].voucherEndTime) {
            this.setState({
                voucherStartDate: filter[0].voucherStartDate,
                voucherStartTime: filter[0].voucherStartTime,
                voucherEndDate: filter[0].voucherEndDate,
                voucherEndTime: filter[0].voucherEndTime,
            })
        } else {
            let newDate = new Date();
            let monthToday = newDate.getMonth() + 1;
            let dateTodayToMake = newDate.getDate();
            if (monthToday < 10) {
                monthToday = `0${monthToday}`;
            }
            if (dateTodayToMake < 10) {
                dateTodayToMake = `0${dateTodayToMake}`;
            }
            let makeToday = `${newDate.getFullYear()}-${monthToday}-${dateTodayToMake}`;

            this.setState({
                voucherStartDate: makeToday,
                voucherStartTime: "",
                voucherEndDate: "",
                voucherEndTime: "",
            })
        }
        if (filter[0].hideOnGlobal) {
            this.setState({ hideOnGlobalVoucher: true })
        }

        if (filter[0].image_mobile) {
            if (filter[0].imageTypeMobile) {
                this.setState({
                    voucherImageTypeMobile: filter[0].imageTypeMobile,
                });
            }
            this.setState({
                voucherImageMobile: [filter[0].image_mobile],
            });

            if (filter[0].mainSectorStockMobile) {
                this.setState({
                    mainSectorStockMobile: filter[0].mainSectorStockMobile,
                });
            }
        }

        if (filter[0].imageType) {
            this.setState({
                voucherImageType: filter[0].imageType,
            });
        }
        if (filter[0].mainSectorStock) {
            let filteredSecond =
                this.props.stock_images.filter((u) => {
                    return (u[0] === filter[0].mainSectorStock);
                });
            if (filteredSecond.length > 0) {
                let a = filteredSecond[0];
                let arr = [];
                if (a.length > 0) {
                    let objected = Object.entries(a[1]);
                    objected.map((y) => {
                        arr.push(y[1]);
                    });
                    this.setState({
                        allImagesCurrentSectorStock: arr,
                    });
                } else {
                    this.setState({
                        allImagesCurrentSectorStock: [],
                    });
                }
            }
            this.setState({
                mainSectorStock: filter[0].mainSectorStock,
            });
        }
        if (currentProduct.main_category) {
            let arrSec = [];
            let promiseCategories = new Promise((res, rej) => {
                setInterval(() => {
                    if (this.state.completeCategories.length > 0) {
                        res()
                    }
                }, 10);
            })
            promiseCategories.then(() => {
                this.state.completeCategories.map(
                    (g) => {
                        if (g[0] === currentProduct.main_sector) {
                            arrSec = g[1];
                        }
                    }
                );
                let categories = [];
                arrSec.map((h) => {
                    let obj = {
                        title: h.title,
                        category_id: h.category_id,
                        sub_category: h.children,
                    };
                    categories.push(obj);
                });
                categories.map((f) => {
                    if (
                        f.category_id ===
                        currentProduct.main_category
                    ) {
                        if (f.sub_category) {
                            this.setState({
                                subCategoryPresent: true,
                            });
                        } else {
                            this.setState({
                                subCategoryPresent: false,
                            });
                        }
                        this.setState({
                            sub_categories: f.sub_category,
                        });
                    }
                });
                this.setState({
                    selectedSector: currentProduct.main_sector,
                    selectedMain: currentProduct.main_category,
                    categorySelected: true,
                });
                this.setState({
                    all_categories: categories,
                });
            })
        }

        setTimeout(() => {
            if (this.state.allValuesLoaded) {
                this.setState({
                    showingLoaderCreateEdit: false,
                });
            }
        }, 3000);
    }

    componentWillUnmount() {
        let uid = localStorage.getItem("userid");

        stockImageRef.off()
        campaignsCategoryRef.off()
        categoriesVouchersRef.off()
        campaignsInfoRef.off()

        if (window.innerWidth < 1008 && document.querySelector(".navbarfullwidthMobile")) {
            document.querySelector(".navbarfullwidthMobile").classList.remove("navbarfullwidthoverflowxhidden")
        }
    }

    getVerzilveredQty(e) {
        let arrItems = e;
        if (arrItems.length > 0) {
            Array.prototype.sum = function (prop) {
                var total = 0;
                for (var i = 0, _len = this.length; i < _len; i++) {
                    total += Number(this[i][1][prop].length);
                }
                return total;
            };
            return arrItems.sum("allDates");
        }
    }

    uploadImglocalEvent(e) {
        e.preventDefault();
        let dropFiles = [];
        let localEventTitle = document.getElementById("localEventTitle").value;
        // let localEventDesc = this.localEventDescRef.current.editor.getData();
        let localEventShortDesc = this.localEventShortDescRef.current.editor.getData();
        let localEventLongDesc = this.localEventLongDescRef.current.editor.getData();
        let localEventAmount = document.getElementById("localEventAmount").value;

        if (e.target.files && e.target.files.length > 0) {
            let objected = Object.entries(e.target.files);

            if (e.target.files[0].type.includes("video")) {
                this.setState({ localEventPlaceHolderIsImage: false });
            } else {
                this.setState({ localEventPlaceHolderIsImage: true });
            }
            objected.map((f, i) => {
                const reader = new FileReader();
                dropFiles.push(objected[i][1]);
                reader.addEventListener("load", () => {
                    if (objected[i][1].type.includes("video")) {
                        // let video = new Video();
                        let result = reader.result;
                        // video.onload = (e) => {
                        let arr = [];
                        arr.push(result);
                        //     this.setState({ localEventImage: arr, dropFilelocalEvent: dropFiles, localEventTitle, localEventDesc, localEventAmount })
                        // };
                        // video.src = result;
                        // let urlReadabale = URL.createObjectURL(f[0][1])
                        // let arr = []
                        // arr.push(urlReadabale)
                        this.setState({
                            localEventImage: arr,
                            dropFilelocalEvent: dropFiles,
                            localEventTitle,
                            localEventDesc: localEventShortDesc + localEventLongDesc,
                            localEventShortDesc,
                            localEventLongDesc,
                            localEventAmount,
                        });
                    } else {
                        let img = new Image();
                        let result = reader.result;
                        img.onload = (e) => {
                            let arr = [];
                            arr.push(result);
                            this.setState({
                                localEventImage: arr,
                                dropFilelocalEvent: dropFiles,
                                localEventTitle,
                                localEventDesc: localEventShortDesc + localEventLongDesc,
                                localEventShortDesc,
                                localEventLongDesc,
                                localEventAmount,
                            });
                        };
                        img.src = result;
                    }
                });
                reader.readAsDataURL(objected[i][1]);
                e.target.value = null;
            });
        }
    }

    avoidInputsClear() {
        if (this.state.openAddPopupLocalEvent) {
            let localEventTitle =
                document.getElementById(
                    "localEventTitle"
                );
            let localEventAmount =
                document.getElementById(
                    "localEventAmount"
                );
            if (localEventTitle) {
                localEventTitle = localEventTitle.value
            }
            if (localEventAmount) {
                localEventAmount = localEventAmount.value
            }
            this.setState({
                localEventTitle,
                // localEventDesc,
                localEventAmount,
            });
        }
    }

    getFormattedDate(e) {
        if (e) {
            let splitted = e.split("-");
            splitted = splitted.reverse().join("-");
            return `${splitted}`;
        }
    }

    checkForDeleteLocalEvent() {
        if (
            this.state.localEventImage[0] !==
            "https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/placeholder.jpg?alt=media&token=3146a89c-5b95-4a6b-b60f-fb6b0f166bdb"
        ) {
            return (
                <div
                    className="editimageupload"
                    onClick={() => {
                        let localEventTitle =
                            document.getElementById("localEventTitle").value;
                        // let localEventDesc =
                        //     this.localEventDescRef.current.editor.getData();
                        let localEventAmount =
                            document.getElementById("localEventAmount").value;

                        this.setState({
                            localEventTitle,
                            localEventAmount,
                            // localEventDesc,
                        });

                        this.setState({
                            dropFilelocalEvent: [],
                            localEventPlaceHolderIsImage: true,
                            localEventImage: [
                                "https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/placeholder.jpg?alt=media&token=3146a89c-5b95-4a6b-b60f-fb6b0f166bdb",
                            ],
                        });
                    }}
                >
                    {window.innerWidth > 1008 ? <MdClose /> : <MdClose />}
                </div>
            );
        }
    }

    saveCampaignlocalEvent(e) {
        const {
            localEventNumber,
            localEventAccessible,
            localEventShowOnGlobal,
            localEventPlaceHolderIsImage,
            localEventPercentage,
            dropFilelocalEvent,
            localEventImageType,
            localEventImage,
            selectedSector,
            mainSectorStock,
            arrEvents,
            eventForGuestUser,
            manual_code_import,
            hide_date_on_page,
        } = this.state;

        if (this.state.notLoadData) {
            return false;
        }

        let localEventTitle = "";
        let localEventDesc = "";
        let localEventShortDesc = "";
        let localEventLongDesc = "";
        let localEventAmount = "";

        if (!this.state.localEventFromAdmin) {
            localEventTitle = document.getElementById("localEventTitle").value;
            // localEventDesc = this.localEventDescRef.current.editor.getData();
            localEventShortDesc = this.localEventShortDescRef.current.editor.getData();
            localEventLongDesc = this.localEventLongDescRef.current.editor.getData();

            localEventDesc = localEventShortDesc + localEventLongDesc

            localEventAmount = document.getElementById("localEventAmount").value;

            this.setState({ localEventTitle, localEventDesc, localEventShortDesc, localEventLongDesc, localEventAmount })
        } else {
            localEventTitle = "Dummy"
            localEventDesc = "Dummy"
            localEventShortDesc = "Dummy"
            localEventLongDesc = "Dummy"
        }

        let uid = localStorage.getItem("userid");

        let arrTimings = [];
        if (this.state.localEventBlock.length > 0) {
            if (
                !this.state.localEventBlock[this.state.onCurrentIndexEvents].localEventIfSharedThen
            ) {
                this.state.localEventBlock[this.state.onCurrentIndexEvents].localEventIfSharedThen = this.state.localEventBlock[this.state.onCurrentIndexEvents];
            }
        }
        // return false;
        if (e === "noupdate") {
            arrEvents.map((y) => {
                if (
                    y.startDateEvent &&
                    // y.startTimeEvent &&
                    y.endDateEvent &&
                    // y.endTimeEvent &&
                    y.personsAmountEvent
                    // &&
                    // y.personsAmountEvent !== "00"
                ) {
                    let inCartOf = 0;

                    let filtered = this.state.localEventBlock[this.state.onCurrentIndexEvents].timings.filter((t) => {
                        return t.eventId === y.eventId
                    })

                    // if (!(filtered.length > 0 && filtered[0].startDateEvent === y.startDateEvent && filtered[0].startTimeEvent === y.startTimeEvent && filtered[0].endDateEvent === y.endDateEvent && filtered[0].endTimeEvent === y.endTimeEvent)) {
                    //     arrTimings.push(y)
                    // } else {
                    if (this.state.localEventBlock[this.state.onCurrentIndexEvents].localEventIfSharedThen.inCartOf) {
                        let objected = Object.entries(this.state.localEventBlock[this.state.onCurrentIndexEvents].localEventIfSharedThen.inCartOf
                        );
                        objected = objected.filter((t) => {
                            return t[1].event_id === y.eventId
                        })
                        inCartOf = objected.length;
                    }
                    if (this.state.localEventBlock[this.state.onCurrentIndexEvents].localEventIfSharedThen.checked_in_by) {
                        let objected = Object.entries(this.state.localEventBlock[this.state.onCurrentIndexEvents].localEventIfSharedThen.checked_in_by)
                        // let filtered = objected
                        // y.personsAmountEvent = Number(y.personsAmountEvent) + inCartOf;
                        objected = objected.filter((t) => {
                            return t[1].event_id === y.eventId
                        })
                        y.personsAmountEvent = Number(y.personsAmountEvent) + objected.length + inCartOf
                        if (Number(y.personsAmountEvent) < 10) {
                            y.personsAmountEvent = `0${y.personsAmountEvent}`;
                        } else {
                            y.personsAmountEvent = `${y.personsAmountEvent}`;
                        }
                        arrTimings.push(y);
                    } else {
                        if (this.state.localEventBlock[this.state.onCurrentIndexEvents].localEventIfSharedThen.inCartOf) {
                            y.personsAmountEvent = Number(y.personsAmountEvent) + inCartOf;
                            if (Number(y.personsAmountEvent) < 10) {
                                y.personsAmountEvent = `0${y.personsAmountEvent}`;
                            } else {
                                y.personsAmountEvent = `${y.personsAmountEvent}`;
                            }
                        }

                        arrTimings.push(y);
                    }
                    // }
                }
            });
        } else {
            arrEvents.map((y) => {
                if (
                    y.startDateEvent &&
                    // y.startTimeEvent &&
                    y.endDateEvent &&
                    // y.endTimeEvent &&
                    y.personsAmountEvent &&
                    y.personsAmountEvent !== "00"
                ) {
                    let inCartOf = 0;
                    if (this.state.localEventBlock.length > 0) {
                        if (this.state.localEventBlock[this.state.onCurrentIndexEvents].localEventIfSharedThen.inCartOf) {
                            let objected = Object.entries(this.state.localEventBlock[this.state.onCurrentIndexEvents].localEventIfSharedThen.inCartOf);
                            objected = objected.filter((t) => {
                                return t[1].event_id === y.eventId
                            })
                            inCartOf = objected.length;

                            y.personsAmountEvent = Number(y.personsAmountEvent) + inCartOf;
                            if (Number(y.personsAmountEvent) < 10) {
                                y.personsAmountEvent = `0${y.personsAmountEvent}`;
                            } else {
                                y.personsAmountEvent = `${y.personsAmountEvent}`;
                            }
                        }
                    }
                    arrTimings.push(y);
                }
            });
        }

        if (arrTimings.length > 0) {
            arrTimings.map((g) => {
                if (g.startTimeEvent && g.endTimeEvent) {
                    let isoTimeTimerBtn = new Date(Number(g.startDateEvent.split("-")[0]), Number(g.startDateEvent.split("-")[1] - 1), Number(g.startDateEvent.split("-")[2]), Number(g.startTimeEvent.split(":")[0]), Number(g.startTimeEvent.split(":")[1]), 0).toISOString()
                    let isoTimeEndDate = new Date(Number(g.endDateEvent.split("-")[0]), Number(g.endDateEvent.split("-")[1] - 1), Number(g.endDateEvent.split("-")[2]), Number(g.endTimeEvent.split(":")[0]), Number(g.endTimeEvent.split(":")[1]), 0).toISOString()
                    g.isoStartTime = isoTimeTimerBtn
                    g.isoEndTime = isoTimeEndDate
                } else {
                    let isoTimeTimerBtn = new Date(Number(g.startDateEvent.split("-")[0]), Number(g.startDateEvent.split("-")[1] - 1), Number(g.startDateEvent.split("-")[2]), 0, 0, 0).toISOString()
                    let isoTimeEndDate = new Date(Number(g.endDateEvent.split("-")[0]), Number(g.endDateEvent.split("-")[1] - 1), Number(g.endDateEvent.split("-")[2]), 23, 59, 59).toISOString()
                    g.isoStartTime = isoTimeTimerBtn
                    g.isoEndTime = isoTimeEndDate
                }
            })
            // if (arrTimings[0].startTimeEvent && arrTimings[0].endTimeEvent) {
            //     let isoTimeTimerBtn = new Date(Number(arrTimings[0].startDateEvent.split("-")[0]), Number(arrTimings[0].startDateEvent.split("-")[1] - 1), Number(arrTimings[0].startDateEvent.split("-")[2]), Number(arrTimings[0].startTimeEvent.split(":")[0]), Number(arrTimings[0].startTimeEvent.split(":")[1]), 0).toISOString()
            //     let isoTimeEndDate = new Date(Number(arrTimings[0].endDateEvent.split("-")[0]), Number(arrTimings[0].endDateEvent.split("-")[1] - 1), Number(arrTimings[0].endDateEvent.split("-")[2]), Number(arrTimings[0].endTimeEvent.split(":")[0]), Number(arrTimings[0].endTimeEvent.split(":")[1]), 0).toISOString()
            //     arrTimings[0].isoStartTime = isoTimeTimerBtn
            //     arrTimings[0].isoEndTime = isoTimeEndDate
            // } else {
            //     let isoTimeTimerBtn = new Date(Number(arrTimings[0].startDateEvent.split("-")[0]), Number(arrTimings[0].startDateEvent.split("-")[1] - 1), Number(arrTimings[0].startDateEvent.split("-")[2]), 0, 0, 0).toISOString()
            //     let isoTimeEndDate = new Date(Number(arrTimings[0].endDateEvent.split("-")[0]), Number(arrTimings[0].endDateEvent.split("-")[1] - 1), Number(arrTimings[0].endDateEvent.split("-")[2]), 23, 59, 59).toISOString()
            //     arrTimings[0].isoStartTime = isoTimeTimerBtn
            //     arrTimings[0].isoEndTime = isoTimeEndDate
            // }
        }

        if (
            localEventTitle &&
            localEventDesc &&
            localEventImage[0] !==
            "https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/placeholder.jpg?alt=media&token=3146a89c-5b95-4a6b-b60f-fb6b0f166bdb" &&
            arrEvents[0].startDateEvent &&
            // arrEvents[0].startTimeEvent &&
            arrEvents[0].endDateEvent &&
            // arrEvents[0].endTimeEvent &&
            arrEvents[0].personsAmountEvent &&
            arrEvents[0].personsAmountEvent !== "00" &&
            arrEvents[0].personsAmountEvent !== "aantal"
        ) {
            if (this.state.localEventBlockOnEdit) {
                this.setState({
                    noCategorySelectedlocalEvent: false,
                    localEventTitleAlert: false,
                    localEventImageAlert: false,
                    localEventDescAlert: false,
                    localEventAmountAlert: false,
                    noTimingSelectedEvent: false,
                    noAantalSelectedEvent: false,
                });
                // this.setState({ openAddPopupLocalEvent: false, openAddPopup: false });
                let type = "";
                if (localEventPercentage) {
                    type = "percentage";
                } else {
                    type = "price";
                }
                let filter = this.state.localEventBlock;
                if (!this.state.localEventFromAdmin) {
                    firebase
                        .database()
                        .ref(
                            "bhodi_campaigns/" +
                            uid +
                            "/" +
                            filter[this.state.onCurrentIndexEvents].pushKey +
                            "/title"
                        )
                        .set(localEventTitle);
                    firebase
                        .database()
                        .ref(
                            "bhodi_campaigns/" +
                            uid +
                            "/" +
                            filter[this.state.onCurrentIndexEvents].pushKey +
                            "/desc"
                        )
                        .set(localEventDesc);
                    firebase
                        .database()
                        .ref(
                            "bhodi_campaigns/" +
                            uid +
                            "/" +
                            filter[this.state.onCurrentIndexEvents].pushKey +
                            "/first_desc"
                        )
                        .set(localEventShortDesc);
                    firebase
                        .database()
                        .ref(
                            "bhodi_campaigns/" +
                            uid +
                            "/" +
                            filter[this.state.onCurrentIndexEvents].pushKey +
                            "/second_desc"
                        )
                        .set(localEventLongDesc);
                    firebase
                        .database()
                        .ref(
                            "bhodi_campaigns/" +
                            uid +
                            "/" +
                            filter[this.state.onCurrentIndexEvents].pushKey +
                            "/amount"
                        )
                        .set(localEventAmount);
                    firebase
                        .database()
                        .ref(
                            "bhodi_campaigns/" +
                            uid +
                            "/" +
                            filter[this.state.onCurrentIndexEvents].pushKey +
                            "/localEventType"
                        )
                        .set(type);
                    firebase
                        .database()
                        .ref(
                            "bhodi_campaigns/" +
                            uid +
                            "/" +
                            filter[this.state.onCurrentIndexEvents].pushKey +
                            "/number"
                        )
                        .set(localEventNumber);
                    firebase
                        .database()
                        .ref(
                            "bhodi_campaigns/" +
                            uid +
                            "/" +
                            filter[this.state.onCurrentIndexEvents].pushKey +
                            "/timings"
                        )
                        .set(arrTimings);
                    firebase
                        .database()
                        .ref(
                            "bhodi_campaigns/" +
                            uid +
                            "/" +
                            filter[this.state.onCurrentIndexEvents].pushKey +
                            "/localEventAccessible"
                        )
                        .set(localEventAccessible);
                    firebase
                        .database()
                        .ref(
                            "bhodi_campaigns/" +
                            uid +
                            "/" +
                            filter[this.state.onCurrentIndexEvents].pushKey +
                            "/localEventUnlimited"
                        )
                        .set(this.state.localEventUnlimited);
                    firebase
                        .database()
                        .ref(
                            "bhodi_campaigns/" +
                            uid +
                            "/" +
                            filter[this.state.onCurrentIndexEvents].pushKey +
                            "/localEventShowOnGlobal"
                        )
                        .set(localEventShowOnGlobal);
                    firebase
                        .database()
                        .ref(
                            "bhodi_campaigns/" +
                            uid +
                            "/" +
                            filter[this.state.onCurrentIndexEvents].pushKey +
                            "/discountAvailable"
                        )
                        .set(this.state.discountAvailable);
                } else {
                    firebase
                        .database()
                        .ref(
                            "bhodi_campaigns/" +
                            uid +
                            "/" +
                            filter[this.state.onCurrentIndexEvents].pushKey +
                            "/stockAmount"
                        )
                        .set(arrTimings[0].personsAmountEvent);
                }

                firebase
                    .database()
                    .ref(
                        "bhodi_campaigns/" +
                        uid +
                        "/" +
                        filter[this.state.onCurrentIndexEvents].pushKey +
                        "/hideOnGlobalEvent"
                    )
                    .set(this.state.hideOnGlobalEvent);
                firebase
                    .database()
                    .ref(
                        "bhodi_campaigns/" +
                        uid +
                        "/" +
                        filter[this.state.onCurrentIndexEvents].pushKey +
                        "/localEventImageType"
                    )
                    .set(localEventImageType);
                firebase
                    .database()
                    .ref(
                        "bhodi_campaigns/" +
                        uid +
                        "/" +
                        filter[this.state.onCurrentIndexEvents].pushKey +
                        "/localEventPlaceHolderIsImage"
                    )
                    .set(localEventPlaceHolderIsImage);
                if (localEventImageType === "takefromlibrary") {
                    firebase
                        .database()
                        .ref(
                            "bhodi_campaigns/" +
                            uid +
                            "/" +
                            filter[this.state.onCurrentIndexEvents].pushKey +
                            "/mainSectorStock"
                        )
                        .set(mainSectorStock);
                } else {
                    firebase
                        .database()
                        .ref(
                            "bhodi_campaigns/" +
                            uid +
                            "/" +
                            filter[this.state.onCurrentIndexEvents].pushKey +
                            "/mainSectorStock"
                        )
                        .remove();
                }
                firebase
                    .database()
                    .ref(
                        "bhodi_campaigns/" +
                        uid +
                        "/" +
                        filter[this.state.onCurrentIndexEvents].pushKey +
                        "/active"
                    )
                    .set(this.state.currentOpenedCheck);
                firebase
                    .database()
                    .ref(
                        "bhodi_campaigns/" +
                        uid +
                        "/" +
                        filter[this.state.onCurrentIndexEvents].pushKey +
                        "/eventForGuestUser"
                    )
                    .set(eventForGuestUser);
                firebase
                    .database()
                    .ref(
                        "bhodi_campaigns/" +
                        uid +
                        "/" +
                        filter[this.state.onCurrentIndexEvents].pushKey +
                        "/manual_code_import"
                    )
                    .set(manual_code_import);
                firebase
                    .database()
                    .ref(
                        "bhodi_campaigns/" +
                        uid +
                        "/" +
                        filter[this.state.onCurrentIndexEvents].pushKey +
                        "/hide_date_on_page"
                    )
                    .set(hide_date_on_page);

                if (dropFilelocalEvent.length > 0) {
                    this.setState({ loaderShow: true });
                    dropFilelocalEvent.map((e) => {
                        firebase
                            .storage()
                            .ref()
                            .child(`campaigns/${e.name}${new Date().getTime()}`)
                            .put(e)
                            .then((snapshot) => {
                                snapshot.ref.getDownloadURL().then((snapUrl) => {
                                    firebase
                                        .database()
                                        .ref(
                                            "bhodi_campaigns/" +
                                            uid +
                                            "/" +
                                            filter[this.state.onCurrentIndexEvents].pushKey +
                                            "/image"
                                        )
                                        .set(snapUrl)
                                        .then(() => {
                                            setTimeout(() => {
                                                this.props.closePopup()
                                            }, 2000);
                                        });
                                });
                            });
                    });
                } else if (localEventImage.length > 0) {
                    firebase
                        .database()
                        .ref(
                            "bhodi_campaigns/" +
                            uid +
                            "/" +
                            filter[this.state.onCurrentIndexEvents].pushKey +
                            "/image"
                        )
                        .set(localEventImage[0]);
                    // setTimeout(() => {
                    this.setState({ openAddPopupLocalEvent: false })
                    this.props.closePopup()
                    // }, 2000);
                } else {
                    // setTimeout(() => {
                    this.setState({ openAddPopupLocalEvent: false })
                    this.props.closePopup()

                    // }, 2000);
                }
            } else {
                this.setState({
                    noCategorySelectedlocalEvent: false,
                    localEventTitleAlert: false,
                    localEventImageAlert: false,
                    localEventDescAlert: false,
                    localEventAmountAlert: false,
                    noTimingSelectedEvent: false,
                    noAantalSelectedEvent: false,
                });
                // this.setState({ openAddPopupLocalEvent: false, openAddPopup: false });
                let type = "";
                if (localEventPercentage) {
                    type = "percentage";
                } else {
                    type = "price";
                }
                this.setState({ redirectlocalEvent: true });
                let storageKey = firebase
                    .database()
                    .ref("bhodi_campaigns/" + uid)
                    .push().key;
                let timestamp = Math.floor(Date.now() / 100);
                if (dropFilelocalEvent.length > 0) {
                    this.setState({ loaderShow: true, notLoadData: true });
                    // if (dropFilelocalEvent.length > 0 && localEventTitle && localEventDesc && localEventAmount) {
                    dropFilelocalEvent.map((e) => {
                        firebase
                            .storage()
                            .ref()
                            .child(`campaigns/${e.name}${new Date().getTime()}`)
                            .put(e)
                            .then((snapshot) => {
                                snapshot.ref.getDownloadURL().then((snapUrl) => {
                                    let obj = {
                                        title: localEventTitle,
                                        desc: localEventDesc,
                                        first_desc: localEventShortDesc,
                                        second_desc: localEventShortDesc,
                                        amount: localEventAmount,
                                        localEventType: type,
                                        campaignType: "local_event",
                                        localEventPlaceHolderIsImage,
                                        pushKey: storageKey,
                                        image: snapUrl,
                                        localEventAccessible,
                                        localEventShowOnGlobal,
                                        active: this.props.isAdmin ? false : this.state.currentOpenedCheck,
                                        hideOnGlobalEvent: this.props.isAdmin ? true : this.state.hideOnGlobalEvent,
                                        discountAvailable: this.state.discountAvailable,
                                        created_by: uid,
                                        timestamp,
                                        localEventImageType,
                                        number: localEventNumber,
                                        localEventUnlimited: this.state.localEventUnlimited,
                                        timings: arrTimings,
                                        main_sector: selectedSector,
                                        created_at: new Date().getTime(),
                                        eventForGuestUser,
                                        manual_code_import,
                                        hide_date_on_page,
                                    };
                                    if (localEventImageType === "takefromlibrary") {
                                        obj.mainSectorStock = mainSectorStock;
                                    }
                                    firebase
                                        .database()
                                        .ref("bhodi_campaigns/" + uid + "/" + storageKey)
                                        .set(obj)
                                        .then(() => {
                                            setTimeout(() => {
                                                this.setState({ openAddPopupLocalEvent: false })
                                                this.props.closePopup()
                                            }, 2000);
                                        });
                                });
                            });
                    });
                } else {
                    this.setState({ notLoadData: true })

                    let obj = {
                        title: localEventTitle,
                        desc: localEventDesc,
                        first_desc: localEventShortDesc,
                        second_desc: localEventShortDesc,
                        localEventAccessible,
                        localEventShowOnGlobal,
                        amount: localEventAmount,
                        localEventType: type,
                        campaignType: "local_event",
                        localEventPlaceHolderIsImage,
                        pushKey: storageKey,
                        image: localEventImage[0],
                        active: this.props.isAdmin ? false : this.state.currentOpenedCheck,
                        hideOnGlobalEvent: this.props.isAdmin ? true : this.state.hideOnGlobalEvent,
                        discountAvailable: this.state.discountAvailable,
                        created_by: uid,
                        timestamp,
                        localEventImageType,
                        number: localEventNumber,
                        localEventUnlimited: this.state.localEventUnlimited,
                        timings: arrTimings,
                        main_sector: selectedSector,
                        created_at: new Date().getTime(),
                        eventForGuestUser,
                        hide_date_on_page,
                        manual_code_import,
                    };
                    if (localEventImageType === "takefromlibrary") {
                        obj.mainSectorStock = mainSectorStock;
                    }
                    firebase
                        .database()
                        .ref("bhodi_campaigns/" + uid + "/" + storageKey)
                        .set(obj)
                        .then(() => {
                            this.setState({ openAddPopupLocalEvent: false })
                            this.props.closePopup()
                        });
                }
            }
        } else {
            if (this.state.selectedMain.length > 0) {
                this.setState({ noCategorySelectedlocalEvent: false });
            } else {
                this.setState({ noCategorySelectedlocalEvent: true });
            }
            if (localEventTitle) {
                this.setState({ localEventTitleAlert: false });
            } else {
                this.setState({ localEventTitleAlert: true });
            }
            if (localEventDesc) {
                this.setState({ localEventDescAlert: false });
            } else {
                this.setState({ localEventDescAlert: true });
            }
            if (localEventAmount) {
                this.setState({ localEventAmountAlert: false });
            } else {
                this.setState({ localEventAmountAlert: true });
            }
            if (
                localEventImage[0] !==
                "https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/placeholder.jpg?alt=media&token=3146a89c-5b95-4a6b-b60f-fb6b0f166bdb"
            ) {
                this.setState({ localEventImageAlert: false });
            } else {
                this.setState({ localEventImageAlert: true });
            }
            if (
                // arrEvents[0].startTimeEvent &&
                arrEvents[0].startDateEvent &&
                arrEvents[0].endDateEvent
                // arrEvents[0].endTimeEvent
            ) {
                this.setState({ noTimingSelectedEvent: false });
            } else {
                this.setState({ noTimingSelectedEvent: true });
            }
            if (
                arrEvents[0].personsAmountEvent &&
                arrEvents[0].personsAmountEvent !== "00" &&
                arrEvents[0].personsAmountEvent !== "aantal"
            ) {
                this.setState({ noAantalSelectedEvent: false });
            } else {
                this.setState({ noAantalSelectedEvent: true });
            }
        }
    }


    uploadImgKortingsvoucher(e) {
        e.preventDefault();
        let dropFiles = [];

        this.handleValuesKortingsVoucher()

        if (e.target.files && e.target.files.length > 0) {
            let objected = Object.entries(e.target.files);
            objected.map((f, i) => {
                const reader = new FileReader();
                dropFiles.push(objected[i][1]);
                reader.addEventListener("load", () => {
                    let img = new Image();
                    let result = reader.result;
                    img.onload = (e) => {
                        let arr = [];
                        arr.push(result);
                        if (this.state.kortingsVoucherSelectedMobile) {
                            this.setState({
                                kortingsVoucherImageMobile: arr,
                                dropFileKortingsVoucherMobile: dropFiles,
                            });
                        } else {
                            this.setState({
                                kortingsVoucherImage: arr,
                                dropFileKortingsVoucher: dropFiles,
                            });
                        }
                    };
                    img.src = result;
                });
                reader.readAsDataURL(objected[i][1]);
                e.target.value = null;
            });
        }
    }


    uploadImgVoucher(e) {
        e.preventDefault();
        let dropFiles = [];

        this.handleValuesWebShop()

        if (e.target.files && e.target.files.length > 0) {
            let objected = Object.entries(e.target.files);
            objected.map((f, i) => {
                const reader = new FileReader();
                dropFiles.push(objected[i][1]);
                reader.addEventListener("load", () => {
                    let img = new Image();
                    let result = reader.result;
                    img.onload = (e) => {
                        let arr = [];
                        arr.push(result);
                        if (this.state.voucherSelectedMobile) {
                            this.setState({
                                voucherImageMobile: arr,
                                dropFileVoucherMobile: dropFiles,
                            });
                        } else {
                            this.setState({
                                voucherImage: arr,
                                dropFileVoucher: dropFiles,
                            });
                        }
                    };
                    img.src = result;
                });
                reader.readAsDataURL(objected[i][1]);
                e.target.value = null;
            });
        }
    }


    getSelectedCategoryName() {
        if (this.state.all_categories.length > 0) {
            let filtered = this.state.all_categories.filter((y) => {
                return this.state.selectedMain.includes(y.category_id);
            });
            if (filtered.length > 0) {
                let arr = [];
                filtered.map((y, i) => {
                    arr.push(y.title);
                });
                return arr.join(", ");
            }
        }
    }

    saveCampaignKortingsVoucher() {
        const {
            kortingsVoucherPrijs,
            kortingsVoucherPercentage,
            kortingsVoucherGiveaway,
            kortingsVoucherImageType,
            kortingsVoucherImageTypeMobile,
            dropFileKortingsVoucher,
            dropFileKortingsVoucherMobile,
            kortingsVoucherImage,
            kortingsVoucherImageMobile,
            selectedSector,
            selectedMain,
            mainSectorStock,
            mainSectorStockMobile,
        } = this.state;

        let kortingsVoucherAmount = ""
        let kortingsVoucherSalePrice = ""
        let kortingsVoucherNormalPrice = ""

        if (this.state.kortingsVoucherGiveaway) {
            kortingsVoucherAmount = "";
        } else if (this.state.kortingsVoucherPrijs || this.state.kortingsVoucherPercentage) {
            kortingsVoucherAmount = document.getElementById("kortingsVoucherAmount").value;
        } else {
            kortingsVoucherSalePrice = document.getElementById("kortingsVoucherSalePrice").value;
            kortingsVoucherNormalPrice = document.getElementById("kortingsVoucherNormalPrice").value;
        }

        let kortingsVoucherTitle = document.getElementById("kortingsVoucherTitle").value;
        let kortingsVoucherDesc = this.kortingsVoucherDescRef.current.editor.getData();
        let kortingsVoucherNumber = document.getElementById("kortingsVoucherNumber");

        if (document.getElementById("kortingsVoucherNumber")) {
            this.setState({ kortingsVoucherNumber: kortingsVoucherNumber.value });
            kortingsVoucherNumber = kortingsVoucherNumber.value;
        } else {
            kortingsVoucherNumber = 1;
        }

        this.setState({ kortingsVoucherDesc, kortingsVoucherTitle, kortingsVoucherAmount, kortingsVoucherSalePrice, kortingsVoucherNormalPrice });

        let booleanAmount = true;
        if (kortingsVoucherGiveaway) {
            booleanAmount = true
        } else if (kortingsVoucherPrijs || kortingsVoucherPercentage) {
            if (kortingsVoucherAmount) {
                booleanAmount = true
            } else {
                booleanAmount = false
            }
        } else {
            if (kortingsVoucherSalePrice && kortingsVoucherNormalPrice) {
                booleanAmount = true
            } else {
                booleanAmount = false
            }
        }

        let uid = localStorage.getItem("userid");
        if (
            this.state.selectedMain.length > 0 &&
            kortingsVoucherTitle &&
            kortingsVoucherDesc &&
            booleanAmount &&
            this.state.timerDateWaiting &&
            this.state.timerTimeWaiting &&
            kortingsVoucherImage[0] !==
            "https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/placeholder.jpg?alt=media&token=3146a89c-5b95-4a6b-b60f-fb6b0f166bdb"
        ) {
            let isoTimeTimerBtnEndTime = ""

            if (this.state.timerTimeWaitingEndTime && this.state.timerDateWaitingEndTime) {
                isoTimeTimerBtnEndTime = new Date(Number(this.state.timerDateWaitingEndTime.split("-")[0]), Number(this.state.timerDateWaitingEndTime.split("-")[1] - 1), Number(this.state.timerDateWaitingEndTime.split("-")[2]), Number(this.state.timerTimeWaitingEndTime.split(":")[0]), Number(this.state.timerTimeWaitingEndTime.split(":")[1]), 0).toISOString()
            }
            let isoTimeTimerBtn = ""

            if (this.state.timerTimeWaiting && this.state.timerDateWaiting) {
                isoTimeTimerBtn = new Date(Number(this.state.timerDateWaiting.split("-")[0]), Number(this.state.timerDateWaiting.split("-")[1] - 1), Number(this.state.timerDateWaiting.split("-")[2]), Number(this.state.timerTimeWaiting.split(":")[0]), Number(this.state.timerTimeWaiting.split(":")[1]), 0).toISOString()
            }

            // this.setState({ redirectCharity: false, redirectGiveaway: false, redirectLastMinute: false, redirectWebShop: false })
            let promiseMobileImage = new Promise((res, rej) => {
                if (dropFileKortingsVoucherMobile.length > 0) {
                    this.setState({ loaderShow: true });
                    dropFileKortingsVoucherMobile.map((e) => {
                        firebase
                            .storage()
                            .ref()
                            .child(`campaigns/${e.name}${new Date().getTime()}`)
                            .put(e)
                            .then((snapshot) => {
                                snapshot.ref.getDownloadURL().then((snapUrl) => {
                                    res(snapUrl)
                                })
                            })
                    })
                } else {
                    if (kortingsVoucherImageMobile[0] !== "https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/placeholder.jpg?alt=media&token=3146a89c-5b95-4a6b-b60f-fb6b0f166bdb") {
                        res(kortingsVoucherImageMobile[0])
                    } else {
                        res()
                    }
                }
            })
            promiseMobileImage.then((mobilePicUrl) => {
                if (this.state.companyCurrentVoucher) {
                    this.setState({
                        noCategorySelectedKortingsVoucher: false,
                        kortingsVoucherTitleAlert: false,
                        kortingsVoucherImageAlert: false,
                        kortingsVoucherDescAlert: false,
                        kortingsVoucherTimerAlert: false,
                        kortingsVoucherAmountAlert: false,
                    });
                    let filter = [this.state.companyCurrentVoucher];
                    if (this.state.timerDateWaitingEndTime && this.state.timerTimeWaitingEndTime) {
                        firebase
                            .database()
                            .ref("bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/timerDateWaitingEndTime")
                            .set(this.state.timerDateWaitingEndTime);
                        firebase
                            .database()
                            .ref("bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/timerTimeWaitingEndTime")
                            .set(this.state.timerTimeWaitingEndTime);
                        firebase
                            .database()
                            .ref("bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/isoTimeTimerBtnEndTime")
                            .set(isoTimeTimerBtnEndTime);
                    } else {
                        firebase
                            .database()
                            .ref("bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/timerDateWaitingEndTime")
                            .remove();
                        firebase
                            .database()
                            .ref("bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/timerTimeWaitingEndTime")
                            .remove();
                        firebase
                            .database()
                            .ref("bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/isoTimeTimerBtnEndTime")
                            .remove();
                    }
                    if (this.state.timerDateWaiting && this.state.timerTimeWaiting) {
                        firebase
                            .database()
                            .ref("bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/timerDateWaiting")
                            .set(this.state.timerDateWaiting);
                        firebase
                            .database()
                            .ref("bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/timerTimeWaiting")
                            .set(this.state.timerTimeWaiting);
                        firebase
                            .database()
                            .ref("bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/isoTimeTimerBtn")
                            .set(isoTimeTimerBtn);
                    } else {
                        firebase
                            .database()
                            .ref("bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/timerDateWaiting")
                            .remove();
                        firebase
                            .database()
                            .ref("bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/timerTimeWaiting")
                            .remove();
                        firebase
                            .database()
                            .ref("bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/isoTimeTimerBtn")
                            .remove();
                    }
                    firebase
                        .database()
                        .ref("bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/title")
                        .set(kortingsVoucherTitle);

                    if (mobilePicUrl) {
                        firebase
                            .database()
                            .ref("bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/image_mobile")
                            .set(mobilePicUrl);
                        firebase
                            .database()
                            .ref("bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/kortingsVoucherImageTypeMobile")
                            .set(kortingsVoucherImageTypeMobile);

                        if (kortingsVoucherImageTypeMobile === "takefromlibrary") {
                            firebase
                                .database()
                                .ref(
                                    "bhodi_campaigns/" +
                                    uid +
                                    "/" +
                                    filter[0].pushKey +
                                    "/mainSectorStockMobile"
                                )
                                .set(mainSectorStockMobile);
                        } else {
                            firebase
                                .database()
                                .ref(
                                    "bhodi_campaigns/" +
                                    uid +
                                    "/" +
                                    filter[0].pushKey +
                                    "/mainSectorStockMobile"
                                )
                                .remove();
                        }
                    } else {
                        firebase
                            .database()
                            .ref("bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/image_mobile")
                            .remove();
                        firebase
                            .database()
                            .ref("bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/kortingsVoucherImageTypeMobile")
                            .remove();

                        firebase
                            .database()
                            .ref("bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/mainSectorStockMobile").remove();
                    }
                    firebase
                        .database()
                        .ref("bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/desc")
                        .set(kortingsVoucherDesc);
                    firebase
                        .database()
                        .ref(
                            "bhodi_campaigns/" +
                            uid +
                            "/" +
                            filter[0].pushKey +
                            "/kortingsVoucherImageType"
                        )
                        .set(kortingsVoucherImageType);
                    if (kortingsVoucherGiveaway) {
                        firebase
                            .database()
                            .ref("bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/amount")
                            .remove();
                        firebase
                            .database()
                            .ref("bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/kortingsVoucherNormalPrice")
                            .remove();
                        firebase
                            .database()
                            .ref("bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/kortingsVoucherSalePrice")
                            .remove();
                    } else if (kortingsVoucherPrijs || kortingsVoucherPercentage) {
                        firebase
                            .database()
                            .ref("bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/amount")
                            .set(kortingsVoucherAmount);
                        firebase
                            .database()
                            .ref("bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/kortingsVoucherNormalPrice")
                            .remove();
                        firebase
                            .database()
                            .ref("bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/kortingsVoucherSalePrice")
                            .remove();
                    } else {
                        firebase
                            .database()
                            .ref("bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/kortingsVoucherNormalPrice")
                            .set(kortingsVoucherNormalPrice);
                        firebase
                            .database()
                            .ref("bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/kortingsVoucherSalePrice")
                            .set(kortingsVoucherSalePrice);
                    }
                    firebase
                        .database()
                        .ref(
                            "bhodi_campaigns/" +
                            uid +
                            "/" +
                            filter[0].pushKey +
                            "/kortingsVoucherPrijs"
                        )
                        .set(kortingsVoucherPrijs);
                    firebase
                        .database()
                        .ref(
                            "bhodi_campaigns/" +
                            uid +
                            "/" +
                            filter[0].pushKey +
                            "/kortingsVoucherPercentage"
                        )
                        .set(kortingsVoucherPercentage);
                    firebase
                        .database()
                        .ref(
                            "bhodi_campaigns/" +
                            uid +
                            "/" +
                            filter[0].pushKey +
                            "/kortingsVoucherGiveaway"
                        )
                        .set(kortingsVoucherGiveaway);
                    if (kortingsVoucherImageType === "takefromlibrary") {
                        firebase
                            .database()
                            .ref(
                                "bhodi_campaigns/" +
                                uid +
                                "/" +
                                filter[0].pushKey +
                                "/mainSectorStock"
                            )
                            .set(mainSectorStock);
                    } else {
                        firebase
                            .database()
                            .ref(
                                "bhodi_campaigns/" +
                                uid +
                                "/" +
                                filter[0].pushKey +
                                "/mainSectorStock"
                            )
                            .remove();
                    }
                    if (this.state.selectedMain.length > 0) {
                        firebase
                            .database()
                            .ref(
                                "bhodi_campaigns/" +
                                uid +
                                "/" +
                                filter[0].pushKey +
                                "/main_sector"
                            )
                            .set(selectedSector);
                        firebase
                            .database()
                            .ref(
                                "bhodi_campaigns/" +
                                uid +
                                "/" +
                                filter[0].pushKey +
                                "/main_category"
                            )
                            .set(selectedMain);
                    }
                    firebase
                        .database()
                        .ref("bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/active")
                        .set(this.state.currentOpenedCheck);
                    firebase
                        .database()
                        .ref(
                            "bhodi_campaigns/" +
                            uid +
                            "/" +
                            filter[this.state.onCurrentIndexEvents].pushKey +
                            "/hideOnGlobal"
                        )
                        .set(this.state.hideOnGlobalVoucher);
                    firebase
                        .database()
                        .ref(
                            "bhodi_campaigns/" +
                            uid +
                            "/" +
                            filter[this.state.onCurrentIndexEvents].pushKey +
                            "/showTimerToGuests"
                        )
                        .set(this.state.showTimerToGuests);
                    firebase
                        .database()
                        .ref(
                            "bhodi_campaigns/" +
                            uid +
                            "/" +
                            filter[0].pushKey +
                            "/kortingsVoucherUnlimited"
                        )
                        .set(this.state.kortingsVoucherUnlimited);
                    if (
                        Number(kortingsVoucherNumber) !== Number(this.state.editKortingsVoucherNum)
                    ) {
                        firebase
                            .database()
                            .ref(
                                "bhodi_campaigns/" +
                                uid +
                                "/" +
                                filter[0].pushKey +
                                "/checked_in_by"
                            )
                            .remove();
                        firebase
                            .database()
                            .ref(
                                "bhodi_campaigns/" +
                                uid +
                                "/" +
                                filter[0].pushKey +
                                "/verzilvered_by"
                            )
                            .remove();

                        let filteredCampaign = this.props.global_campaigns.filter((h) => {
                            return h[1].pushKey === this.state.companyCurrentVoucher.pushKey;
                        });

                        if (filteredCampaign.length > 0) {
                            if (filteredCampaign[0][1].inCartOf) {
                                let objected = Object.entries(filteredCampaign[0][1].inCartOf);
                                firebase
                                    .database()
                                    .ref(
                                        "bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/number"
                                    )
                                    .set(Number(kortingsVoucherNumber) + objected.length);
                            } else {
                                firebase
                                    .database()
                                    .ref(
                                        "bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/number"
                                    )
                                    .set(kortingsVoucherNumber);
                            }
                        } else {
                            firebase
                                .database()
                                .ref(
                                    "bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/number"
                                )
                                .set(kortingsVoucherNumber);
                        }
                    }
                    if (dropFileKortingsVoucher.length > 0) {
                        this.setState({ loaderShow: true });
                        dropFileKortingsVoucher.map((e) => {
                            firebase
                                .storage()
                                .ref()
                                .child(`campaigns/${e.name}${new Date().getTime()}`)
                                .put(e)
                                .then((snapshot) => {
                                    snapshot.ref.getDownloadURL().then((snapUrl) => {
                                        firebase
                                            .database()
                                            .ref(
                                                "bhodi_campaigns/" +
                                                uid +
                                                "/" +
                                                filter[0].pushKey +
                                                "/image"
                                            )
                                            .set(snapUrl)
                                            .then(() => {
                                                setTimeout(() => {
                                                    this.props.closePopup("redirect-voucher")
                                                }, 2000);
                                            });
                                    });
                                });
                        });
                    } else if (kortingsVoucherImage.length > 0) {
                        firebase
                            .database()
                            .ref("bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/image")
                            .set(kortingsVoucherImage[0]);

                        this.props.closePopup("redirect-voucher")
                    } else {
                        this.props.closePopup("redirect-voucher")
                    }
                } else {
                    if (dropFileKortingsVoucher.length > 0) {
                        this.setState({
                            noCategorySelectedKortingsVoucher: false,
                            kortingsVoucherTitleAlert: false,
                            kortingsVoucherImageAlert: false,
                            kortingsVoucherDescAlert: false,
                            kortingsVoucherTimerAlert: false,
                            kortingsVoucherAmountAlert: false,
                            loaderShow: true,
                        });
                        this.setState({ loaderShow: true });
                        let storageKey = firebase
                            .database()
                            .ref("bhodi_campaigns/" + uid)
                            .push().key;
                        let timestamp = Math.floor(Date.now() / 100);
                        dropFileKortingsVoucher.map((e) => {
                            firebase
                                .storage()
                                .ref()
                                .child(`campaigns/${e.name}${new Date().getTime()}`)
                                .put(e)
                                .then((snapshot) => {
                                    snapshot.ref.getDownloadURL().then((snapUrl) => {
                                        let obj = {
                                            title: kortingsVoucherTitle,
                                            desc: kortingsVoucherDesc,
                                            amount: kortingsVoucherAmount,
                                            kortingsVoucherImageType,
                                            kortingsVoucherPrijs,
                                            kortingsVoucherPercentage,
                                            kortingsVoucherGiveaway,
                                            campaignType: "kortings_voucher",
                                            pushKey: storageKey,
                                            image: snapUrl,
                                            active: this.state.currentOpenedCheck,
                                            hideOnGlobal: this.state.hideOnGlobalVoucher,
                                            showTimerToGuests: this.state.showTimerToGuests,
                                            created_by: uid,
                                            timestamp,
                                            number: kortingsVoucherNumber,
                                            kortingsVoucherUnlimited: this.state.kortingsVoucherUnlimited,
                                            created_at: new Date().getTime(),
                                        };
                                        if (mobilePicUrl) {
                                            obj.image_mobile = mobilePicUrl
                                            obj.kortingsVoucherImageTypeMobile = kortingsVoucherImageTypeMobile

                                            if (kortingsVoucherImageTypeMobile === "takefromlibrary") {
                                                obj.mainSectorStockMobile = mainSectorStockMobile;
                                            }
                                        }

                                        if (kortingsVoucherGiveaway) {
                                            obj.amount = ""
                                        }
                                        if (!kortingsVoucherPrijs && !kortingsVoucherPercentage && !kortingsVoucherGiveaway) {
                                            delete obj.amount

                                            obj.kortingsVoucherNormalPrice = kortingsVoucherNormalPrice
                                            obj.kortingsVoucherSalePrice = kortingsVoucherSalePrice
                                        }
                                        if (this.state.selectedMain.length > 0) {
                                            obj.main_sector = selectedSector;
                                            obj.main_category = selectedMain;
                                        }
                                        if (this.state.timerTimeWaitingEndTime && this.state.timerDateWaitingEndTime) {
                                            obj.timerTimeWaitingEndTime = this.state.timerTimeWaitingEndTime;
                                            obj.timerDateWaitingEndTime = this.state.timerDateWaitingEndTime;
                                            obj.isoTimeTimerBtnEndTime = isoTimeTimerBtnEndTime
                                        }
                                        if (this.state.timerTimeWaiting && this.state.timerDateWaiting) {
                                            obj.timerTimeWaiting = this.state.timerTimeWaiting;
                                            obj.timerDateWaiting = this.state.timerDateWaiting;
                                            obj.isoTimeTimerBtn = isoTimeTimerBtn
                                        }
                                        if (kortingsVoucherImageType === "takefromlibrary") {
                                            obj.mainSectorStock = mainSectorStock;
                                        }
                                        firebase
                                            .database()
                                            .ref("bhodi_campaigns/" + uid + "/" + storageKey)
                                            .set(obj)
                                            .then(() => {
                                                setTimeout(() => {
                                                    this.props.closePopup("redirect-voucher")
                                                }, 2000);
                                            });
                                    });
                                });
                        });
                    } else {
                        this.setState({
                            noCategorySelectedKortingsVoucher: false,
                            kortingsVoucherTitleAlert: false,
                            kortingsVoucherImageAlert: false,
                            kortingsVoucherDescAlert: false,
                            kortingsVoucherTimerAlert: false,
                            loaderShow: true,
                            kortingsVoucherAmountAlert: false,
                        });
                        let storageKey = firebase
                            .database()
                            .ref("bhodi_campaigns/" + uid)
                            .push().key;
                        let timestamp = Math.floor(Date.now() / 100);
                        let obj = {
                            title: kortingsVoucherTitle,
                            desc: kortingsVoucherDesc,
                            amount: kortingsVoucherAmount,
                            kortingsVoucherImageType,
                            kortingsVoucherPrijs,
                            campaignType: "kortings_voucher",
                            pushKey: storageKey,
                            image: kortingsVoucherImage[0],
                            active: this.state.currentOpenedCheck,
                            hideOnGlobal: this.state.hideOnGlobalVoucher,
                            showTimerToGuests: this.state.showTimerToGuests,
                            created_by: uid,
                            timestamp,
                            number: kortingsVoucherNumber,
                            kortingsVoucherUnlimited: this.state.kortingsVoucherUnlimited,
                            created_at: new Date().getTime(),
                        };
                        if (kortingsVoucherGiveaway) {
                            obj.amount = ""
                        }
                        if (!kortingsVoucherPrijs && !kortingsVoucherPercentage && !kortingsVoucherGiveaway) {
                            delete obj.amount

                            obj.kortingsVoucherNormalPrice = kortingsVoucherNormalPrice
                            obj.kortingsVoucherSalePrice = kortingsVoucherSalePrice
                        }
                        if (mobilePicUrl) {
                            obj.image_mobile = mobilePicUrl
                            obj.kortingsVoucherImageTypeMobile = kortingsVoucherImageTypeMobile

                            if (kortingsVoucherImageTypeMobile === "takefromlibrary") {
                                obj.mainSectorStockMobile = mainSectorStockMobile;
                            }
                        }

                        if (this.state.selectedMain.length > 0) {
                            obj.main_sector = selectedSector;
                            obj.main_category = selectedMain;
                        }
                        if (this.state.timerTimeWaitingEndTime && this.state.timerDateWaitingEndTime) {
                            obj.timerTimeWaitingEndTime = this.state.timerTimeWaitingEndTime;
                            obj.timerDateWaitingEndTime = this.state.timerDateWaitingEndTime;
                            obj.isoTimeTimerBtnEndTime = isoTimeTimerBtnEndTime
                        }
                        if (this.state.timerTimeWaiting && this.state.timerDateWaiting) {
                            obj.timerTimeWaiting = this.state.timerTimeWaiting;
                            obj.timerDateWaiting = this.state.timerDateWaiting;
                            obj.isoTimeTimerBtn = isoTimeTimerBtn
                        }
                        if (kortingsVoucherImageType === "takefromlibrary") {
                            obj.mainSectorStock = mainSectorStock;
                        }
                        firebase
                            .database()
                            .ref("bhodi_campaigns/" + uid + "/" + storageKey)
                            .set(obj)
                            .then(() => {
                                setTimeout(() => {
                                    this.props.closePopup("redirect-voucher")
                                }, 2000);
                            });
                    }
                }
            })
        } else {
            if (this.state.selectedMain.length > 0) {
                this.setState({ noCategorySelectedKortingsVoucher: false });
            } else {
                this.setState({ noCategorySelectedKortingsVoucher: true });
            }
            if (kortingsVoucherTitle) {
                this.setState({ kortingsVoucherTitleAlert: false });
            } else {
                this.setState({ kortingsVoucherTitleAlert: true });
            }
            if (this.state.timerDateWaiting && this.state.timerTimeWaiting) {
                this.setState({ kortingsVoucherTimerAlert: false });
            } else {
                this.setState({ kortingsVoucherTimerAlert: true });
            }
            if (kortingsVoucherDesc) {
                this.setState({ kortingsVoucherDescAlert: false });
            } else {
                this.setState({ kortingsVoucherDescAlert: true });
            }
            if (booleanAmount) {
                this.setState({ kortingsVoucherAmountAlert: false });
            } else {
                if (!kortingsVoucherNormalPrice || !kortingsVoucherSalePrice) {
                    this.setState({ kortingsVoucherAmountAlert: true });
                } else {
                    this.setState({ kortingsVoucherAmountAlert: false });
                }
            }
            if (!this.state.companyCurrentVoucher) {
                if (dropFileKortingsVoucher.length > 0) {
                    this.setState({ kortingsVoucherImageAlert: false });
                } else {
                    this.setState({ kortingsVoucherImageAlert: true });
                }
            }
            if (
                kortingsVoucherImage[0] !== "https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/placeholder.jpg?alt=media&token=3146a89c-5b95-4a6b-b60f-fb6b0f166bdb"
            ) {
                this.setState({ kortingsVoucherImageAlert: false });
            } else {
                this.setState({ kortingsVoucherImageAlert: true });
            }
        }
    }

    handleValuesKortingsVoucher() {
        if (this.state.kortingsVoucherGiveaway) {
            this.setState({
                kortingsVoucherAmount: "Free",
                kortingsVoucherSalePrice: "",
                kortingsVoucherNormalPrice: "",
            })
        } else if (this.state.kortingsVoucherPrijs || this.state.kortingsVoucherPercentage) {
            let kortingsVoucherAmount = document.getElementById("kortingsVoucherAmount").value;
            this.setState({
                kortingsVoucherAmount,
                kortingsVoucherSalePrice: "",
                kortingsVoucherNormalPrice: "",
            })
        } else {
            let kortingsVoucherSalePrice = document.getElementById("kortingsVoucherSalePrice").value;
            let kortingsVoucherNormalPrice = document.getElementById("kortingsVoucherNormalPrice").value;
            this.setState({
                kortingsVoucherSalePrice,
                kortingsVoucherNormalPrice,
                kortingsVoucherAmount: "",
            })
        }


        let kortingsVoucherTitle = document.getElementById("kortingsVoucherTitle").value;
        let kortingsVoucherDesc = this.kortingsVoucherDescRef.current.editor.getData();
        let kortingsVoucherNumber = document.getElementById("kortingsVoucherNumber");

        if (document.getElementById("kortingsVoucherNumber")) {
            this.setState({
                kortingsVoucherNumber: kortingsVoucherNumber.value,
            });
        }

        this.setState({
            kortingsVoucherTitle,
            kortingsVoucherDesc,
        });
    }

    checkForDeleteKortingsVoucher() {
        if (this.state.kortingsVoucherSelectedMobile) {
            if (
                this.state.kortingsVoucherImageMobile[0] !==
                "https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/placeholder.jpg?alt=media&token=3146a89c-5b95-4a6b-b60f-fb6b0f166bdb"
            ) {
                return (
                    <div
                        className="editimageupload"
                        onClick={() => {
                            this.handleValuesKortingsVoucher()

                            this.setState({
                                dropFileKortingsVoucherMobile: [],
                                kortingsVoucherImageMobile: [
                                    "https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/placeholder.jpg?alt=media&token=3146a89c-5b95-4a6b-b60f-fb6b0f166bdb",
                                ],
                            });
                        }}
                    >
                        <MdClose />
                    </div>
                );
            }
        } else {
            if (this.state.kortingsVoucherImage[0] !== "https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/placeholder.jpg?alt=media&token=3146a89c-5b95-4a6b-b60f-fb6b0f166bdb") {
                return (
                    <div
                        className="editimageupload"
                        onClick={() => {
                            this.handleValuesKortingsVoucher()

                            this.setState({
                                dropFileKortingsVoucher: [],
                                kortingsVoucherImage: [
                                    "https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/placeholder.jpg?alt=media&token=3146a89c-5b95-4a6b-b60f-fb6b0f166bdb",
                                ],
                            });
                        }}
                    >
                        <MdClose />
                    </div>
                );
            }
        }
    }

    categoryPopupRender() {
        return (
            <div className="rightmenuaddproduct rightmenuaddcategoryformfw rightmenucategorynew newcategoriesrightmenu">
                <div
                    className="mid-rmap"
                    style={{ position: "relative", padding: 13 }}
                >
                    <span>SELECTEER CATEGORIE</span>
                    <div className="top-header-infocont">
                        <div style={{ position: "relative" }}>
                            <div className='green-round-info' style={{ cursor: "pointer" }} onClick={() => {
                                this.setState({ categoryInfoOpen: !this.state.categoryInfoOpen })
                            }}>
                                <font>?</font>
                            </div>
                            <div className="popup-gri-thifc" style={{ opacity: this.state.categoryInfoOpen ? 1 : 0, pointerEvents: this.state.categoryInfoOpen ? "all" : "none" }}>
                                <CloseIcon onClick={() => this.setState({ categoryInfoOpen: false })} />
                                <label style={{ fontSize: 15 }}>
                                    Selecteer de juiste categorie(ën)
                                    <br />
                                    waaronder je actie wordt getoond.
                                </label>
                            </div>
                        </div>
                        <ChevronLeft
                            style={{
                                cursor: "pointer",
                                position: "absolute",
                                top: "50%",
                                transform: "translateY(-50%)",
                                left: 20,
                                fontSize: 18,
                            }}
                            onClick={() => this.setState({ addCategory: false, categoryInfoOpen: false })}
                        />
                    </div>
                </div>
                <div className="bottom-rmap">
                    <div className="mid-img-uploading-rmap">
                        {this.state.all_categories.length > 0 && (
                            <ul className="checkboxlistselectcategory">
                                {this.state.all_categories.map((e) => {
                                    return (
                                        <li className="checkboxfieldstypeselection">
                                            <input
                                                type="checkbox"
                                                className="input-radio checkboxessortname"
                                                value={e.category_id}
                                                checked={this.state.selectedMain.includes(
                                                    e.category_id
                                                )}
                                                onChange={() => {
                                                    if (
                                                        this.state.selectedMain.includes(
                                                            e.category_id
                                                        )
                                                    ) {
                                                        let indexed =
                                                            this.state.selectedMain.indexOf(
                                                                e.category_id
                                                            );
                                                        this.state.selectedMain.splice(indexed, 1);
                                                        this.setState({
                                                            selectedMain: this.state.selectedMain,
                                                        });
                                                    } else {
                                                        this.state.selectedMain.push(e.category_id);
                                                        this.setState({
                                                            selectedMain: this.state.selectedMain,
                                                        });
                                                    }
                                                }}
                                            />
                                            <p>{e.title}</p>
                                        </li>
                                    );
                                })}
                            </ul>
                        )}
                    </div>
                </div>
            </div>
        )
    }


    handleValuesWebShop() {
        let voucherTitle = document.getElementById("voucherTitle").value;
        let voucherWebshopUrl = document.getElementById("voucherWebshopUrl").value;
        let voucherDesc = this.voucherDescRef.current.editor.getData();
        let voucherNumber = document.getElementById("voucherNumber");
        let voucherAmount = document.getElementById("voucherAmount").value;

        if (document.getElementById("voucherNumber")) {
            this.setState({
                voucherNumber: voucherNumber.value,
            });
        }

        this.setState({
            voucherTitle,
            voucherDesc,
            voucherAmount,
            voucherWebshopUrl,
        });
    }


    checkForDeleteVoucher() {
        if (this.state.voucherSelectedMobile) {
            if (
                this.state.voucherImageMobile[0] !==
                "https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/placeholder.jpg?alt=media&token=3146a89c-5b95-4a6b-b60f-fb6b0f166bdb"
            ) {
                return (
                    <div
                        className="editimageupload"
                        onClick={() => {
                            this.handleValuesKortingsVoucher()

                            this.setState({
                                dropFileVoucherMobile: [],
                                voucherImageMobile: [
                                    "https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/placeholder.jpg?alt=media&token=3146a89c-5b95-4a6b-b60f-fb6b0f166bdb",
                                ],
                            });
                        }}
                    >
                        <MdClose />
                    </div>
                );
            }
        } else {
            if (this.state.voucherImage[0] !== "https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/placeholder.jpg?alt=media&token=3146a89c-5b95-4a6b-b60f-fb6b0f166bdb") {
                return (
                    <div
                        className="editimageupload"
                        onClick={() => {
                            this.handleValuesKortingsVoucher()

                            this.setState({
                                dropFileVoucher: [],
                                voucherImage: [
                                    "https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/placeholder.jpg?alt=media&token=3146a89c-5b95-4a6b-b60f-fb6b0f166bdb",
                                ],
                            });
                        }}
                    >
                        <MdClose />
                    </div>
                );
            }
        }
    }

    saveCampaignWebShop() {
        const {
            voucherIsPercentage,
            voucherImageType,
            voucherImageTypeMobile,
            dropFileVoucher,
            dropFileVoucherMobile,
            voucherImage,
            voucherImageMobile,
            selectedSector,
            selectedMain,
            mainSectorStock,
            mainSectorStockMobile,
            voucherStartDate,
            voucherEndDate,
            voucherStartTime,
            voucherEndTime,
            couponsCreated,
            timerActiveOnWebshop,
            hideForGuestUsers,
            voucherMultipleUseage,
        } = this.state;


        let voucherTitle = document.getElementById("voucherTitle").value;
        let voucherWebshopUrl = document.getElementById("voucherWebshopUrl").value;
        let voucherDesc = this.voucherDescRef.current.editor.getData();
        let voucherNumber = document.getElementById("voucherNumber");
        let voucherAmount = document.getElementById("voucherAmount").value;

        if (document.getElementById("voucherNumber")) {
            this.setState({ voucherNumber: voucherNumber.value });
            voucherNumber = voucherNumber.value;
        } else {
            voucherNumber = 1;
        }

        this.setState({ voucherDesc, voucherTitle, voucherAmount });

        let booleanForTimer = true
        if (timerActiveOnWebshop) {
            if (!voucherStartDate || !voucherStartTime || !voucherEndDate || !voucherEndTime) {
                booleanForTimer = false
            }
        }

        let isoTimeTimerBtn = ""

        if (this.state.timerTimeWaiting && this.state.timerDateWaiting) {
            isoTimeTimerBtn = new Date(Number(this.state.timerDateWaiting.split("-")[0]), Number(this.state.timerDateWaiting.split("-")[1] - 1), Number(this.state.timerDateWaiting.split("-")[2]), Number(this.state.timerTimeWaiting.split(":")[0]), Number(this.state.timerTimeWaiting.split(":")[1]), 0).toISOString()
        }

        let uid = localStorage.getItem("userid");
        if (
            this.state.selectedMain.length > 0 &&
            voucherTitle &&
            voucherDesc &&
            voucherAmount &&
            couponsCreated.length > 0 &&
            booleanForTimer &&
            voucherImage[0] !==
            "https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/placeholder.jpg?alt=media&token=3146a89c-5b95-4a6b-b60f-fb6b0f166bdb"
        ) {
            let isoStartTime = ""
            let isoEndTime = ""

            if (voucherStartDate && voucherStartTime && voucherEndDate && voucherEndTime) {
                isoStartTime = new Date(Number(voucherStartDate.split("-")[0]), Number(voucherStartDate.split("-")[1] - 1), Number(voucherStartDate.split("-")[2]), Number(voucherStartTime.split(":")[0]), Number(voucherStartTime.split(":")[1]), 0).toISOString()
                isoEndTime = new Date(Number(voucherEndDate.split("-")[0]), Number(voucherEndDate.split("-")[1] - 1), Number(voucherEndDate.split("-")[2]), Number(voucherEndTime.split(":")[0]), Number(voucherEndTime.split(":")[1]), 0).toISOString()
            }

            // this.setState({ redirectCharity: false, redirectGiveaway: false, redirectLastMinute: false, redirectWebShop: false })
            let promiseMobileImage = new Promise((res, rej) => {
                if (dropFileVoucherMobile.length > 0) {
                    this.setState({ loaderShow: true });
                    dropFileVoucherMobile.map((e) => {
                        firebase
                            .storage()
                            .ref()
                            .child(`campaigns/${e.name}${new Date().getTime()}`)
                            .put(e)
                            .then((snapshot) => {
                                snapshot.ref.getDownloadURL().then((snapUrl) => {
                                    res(snapUrl)
                                })
                            })
                    })
                } else {
                    if (voucherImageMobile[0] !== "https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/placeholder.jpg?alt=media&token=3146a89c-5b95-4a6b-b60f-fb6b0f166bdb") {
                        res(voucherImageMobile[0])
                    } else {
                        res()
                    }
                }
            })
            promiseMobileImage.then((mobilePicUrl) => {
                if (this.state.companyCurrentWebshop) {
                    this.setState({
                        noCategorySelectedWebshop: false,
                        voucherTitleAlert: false,
                        voucherImageAlert: false,
                        voucherDescAlert: false,
                        kortingsVoucherTimerAlert: false,
                        voucherAmountAlert: false,
                    });
                    let filter = [this.state.companyCurrentWebshop];

                    if (voucherStartDate && voucherStartTime && voucherEndDate && voucherEndTime) {
                        firebase
                            .database()
                            .ref("bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/voucherStartDate")
                            .set(voucherStartDate);
                        firebase
                            .database()
                            .ref("bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/voucherStartTime")
                            .set(voucherStartTime);
                        firebase
                            .database()
                            .ref("bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/voucherEndDate")
                            .set(voucherEndDate);
                        firebase
                            .database()
                            .ref("bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/voucherEndTime")
                            .set(voucherEndTime);
                        firebase
                            .database()
                            .ref("bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/isoStartTime")
                            .set(isoStartTime);
                        firebase
                            .database()
                            .ref("bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/isoEndTime")
                            .set(isoEndTime);
                    } else {
                        firebase
                            .database()
                            .ref("bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/voucherStartDate")
                            .remove();
                        firebase
                            .database()
                            .ref("bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/voucherStartTime")
                            .remove();
                        firebase
                            .database()
                            .ref("bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/voucherEndDate")
                            .remove();
                        firebase
                            .database()
                            .ref("bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/voucherEndTime")
                            .remove();
                        firebase
                            .database()
                            .ref("bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/isoStartTime")
                            .remove();
                        firebase
                            .database()
                            .ref("bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/isoEndTime")
                            .remove();
                    }
                    if (mobilePicUrl) {
                        firebase
                            .database()
                            .ref("bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/image_mobile")
                            .set(mobilePicUrl);
                        firebase
                            .database()
                            .ref("bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/imageTypeMobile")
                            .set(voucherImageTypeMobile);

                        if (voucherImageTypeMobile === "takefromlibrary") {
                            firebase
                                .database()
                                .ref(
                                    "bhodi_campaigns/" +
                                    uid +
                                    "/" +
                                    filter[0].pushKey +
                                    "/mainSectorStockMobile"
                                )
                                .set(mainSectorStockMobile);
                        } else {
                            firebase
                                .database()
                                .ref(
                                    "bhodi_campaigns/" +
                                    uid +
                                    "/" +
                                    filter[0].pushKey +
                                    "/mainSectorStockMobile"
                                )
                                .remove();
                        }
                    } else {
                        firebase
                            .database()
                            .ref("bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/image_mobile")
                            .remove();
                        firebase
                            .database()
                            .ref("bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/imageTypeMobile")
                            .remove();

                        firebase
                            .database()
                            .ref("bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/mainSectorStockMobile").remove();
                    }

                    if (this.state.timerDateWaiting && this.state.timerTimeWaiting) {
                        firebase
                            .database()
                            .ref("bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/timerDateWaiting")
                            .set(this.state.timerDateWaiting);
                        firebase
                            .database()
                            .ref("bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/timerTimeWaiting")
                            .set(this.state.timerTimeWaiting);
                        firebase
                            .database()
                            .ref("bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/isoTimeTimerBtn")
                            .set(isoTimeTimerBtn);
                    } else {
                        firebase
                            .database()
                            .ref("bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/timerDateWaiting")
                            .remove();
                        firebase
                            .database()
                            .ref("bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/timerTimeWaiting")
                            .remove();
                        firebase
                            .database()
                            .ref("bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/isoTimeTimerBtn")
                            .remove();
                    }
                    firebase
                        .database()
                        .ref("bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/title")
                        .set(voucherTitle);
                    firebase
                        .database()
                        .ref("bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/timerActiveOnWebshop")
                        .set(timerActiveOnWebshop);
                    firebase
                        .database()
                        .ref("bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/desc")
                        .set(voucherDesc);
                    firebase
                        .database()
                        .ref(
                            "bhodi_campaigns/" +
                            uid +
                            "/" +
                            filter[0].pushKey +
                            "/imageType"
                        )
                        .set(voucherImageType);

                    firebase
                        .database()
                        .ref("bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/amount")
                        .set(voucherAmount);
                    firebase
                        .database()
                        .ref("bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/coupons")
                        .set(couponsCreated);
                    firebase
                        .database()
                        .ref("bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/voucherWebshopUrl")
                        .set(voucherWebshopUrl);
                    firebase
                        .database()
                        .ref("bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/hideForGuestUsers")
                        .set(hideForGuestUsers);
                    firebase
                        .database()
                        .ref("bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/voucherMultipleUseage")
                        .set(voucherMultipleUseage);

                    firebase
                        .database()
                        .ref(
                            "bhodi_campaigns/" +
                            uid +
                            "/" +
                            filter[0].pushKey +
                            "/voucherIsPercentage"
                        )
                        .set(voucherIsPercentage);

                    if (voucherImageType === "takefromlibrary") {
                        firebase
                            .database()
                            .ref(
                                "bhodi_campaigns/" +
                                uid +
                                "/" +
                                filter[0].pushKey +
                                "/mainSectorStock"
                            )
                            .set(mainSectorStock);
                    } else {
                        firebase
                            .database()
                            .ref(
                                "bhodi_campaigns/" +
                                uid +
                                "/" +
                                filter[0].pushKey +
                                "/mainSectorStock"
                            )
                            .remove();
                    }
                    if (this.state.selectedMain.length > 0) {
                        firebase
                            .database()
                            .ref(
                                "bhodi_campaigns/" +
                                uid +
                                "/" +
                                filter[0].pushKey +
                                "/main_sector"
                            )
                            .set(selectedSector);
                        firebase
                            .database()
                            .ref(
                                "bhodi_campaigns/" +
                                uid +
                                "/" +
                                filter[0].pushKey +
                                "/main_category"
                            )
                            .set(selectedMain);
                    }
                    firebase
                        .database()
                        .ref("bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/active")
                        .set(this.state.currentOpenedCheck);
                    firebase
                        .database()
                        .ref(
                            "bhodi_campaigns/" +
                            uid +
                            "/" +
                            filter[this.state.onCurrentIndexEvents].pushKey +
                            "/hideOnGlobal"
                        )
                        .set(this.state.hideOnGlobalVoucher);
                    firebase
                        .database()
                        .ref(
                            "bhodi_campaigns/" +
                            uid +
                            "/" +
                            filter[this.state.onCurrentIndexEvents].pushKey +
                            "/showTimerToGuests"
                        )
                        .set(this.state.showTimerToGuests);
                    firebase
                        .database()
                        .ref(
                            "bhodi_campaigns/" +
                            uid +
                            "/" +
                            filter[0].pushKey +
                            "/voucherUnlimited"
                        )
                        .set(this.state.voucherUnlimited);
                    if (this.state.alreadyMultipleUsage !== this.state.voucherMultipleUseage && this.state.voucherMultipleUseage) {
                        firebase
                            .database()
                            .ref(
                                "bhodi_campaigns/" +
                                uid +
                                "/" +
                                filter[0].pushKey +
                                "/checked_in_by"
                            )
                            .remove();
                        firebase
                            .database()
                            .ref(
                                "bhodi_campaigns/" +
                                uid +
                                "/" +
                                filter[0].pushKey +
                                "/verzilvered_by"
                            )
                            .remove();
                    }
                    if (
                        Number(voucherNumber) !== Number(this.state.editWebShopNum)
                    ) {

                        let filteredCampaign = this.props.global_campaigns.filter((h) => {
                            return h[1].pushKey === this.state.companyCurrentWebshop.pushKey;
                        });

                        if (filteredCampaign.length > 0) {
                            if (filteredCampaign[0][1].inCartOf) {
                                let objected = Object.entries(filteredCampaign[0][1].inCartOf);
                                firebase
                                    .database()
                                    .ref(
                                        "bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/number"
                                    )
                                    .set(Number(voucherNumber) + objected.length);
                            } else {
                                firebase
                                    .database()
                                    .ref(
                                        "bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/number"
                                    )
                                    .set(voucherNumber);
                            }
                        } else {
                            firebase
                                .database()
                                .ref(
                                    "bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/number"
                                )
                                .set(voucherNumber);
                        }
                    }
                    if (dropFileVoucher.length > 0) {
                        this.setState({ loaderShow: true });
                        dropFileVoucher.map((e) => {
                            firebase
                                .storage()
                                .ref()
                                .child(`campaigns/${e.name}${new Date().getTime()}`)
                                .put(e)
                                .then((snapshot) => {
                                    snapshot.ref.getDownloadURL().then((snapUrl) => {
                                        firebase
                                            .database()
                                            .ref(
                                                "bhodi_campaigns/" +
                                                uid +
                                                "/" +
                                                filter[0].pushKey +
                                                "/image"
                                            )
                                            .set(snapUrl)
                                            .then(() => {
                                                setTimeout(() => {
                                                    this.props.closePopup("redirect-webshop")
                                                }, 2000);
                                            });
                                    });
                                });
                        });
                    } else if (voucherImage.length > 0) {
                        firebase
                            .database()
                            .ref("bhodi_campaigns/" + uid + "/" + filter[0].pushKey + "/image")
                            .set(voucherImage[0]);

                        this.props.closePopup("redirect-webshop")
                    } else {
                        this.props.closePopup("redirect-webshop")
                    }
                } else {
                    if (dropFileVoucher.length > 0) {
                        this.setState({
                            noCategorySelectedWebshop: false,
                            voucherTitleAlert: false,
                            voucherImageAlert: false,
                            voucherDescAlert: false,
                            voucherAmountAlert: false,
                            loaderShow: true,
                        });
                        this.setState({ loaderShow: true });
                        let storageKey = firebase
                            .database()
                            .ref("bhodi_campaigns/" + uid)
                            .push().key;
                        let timestamp = Math.floor(Date.now() / 100);
                        dropFileVoucher.map((e) => {
                            firebase
                                .storage()
                                .ref()
                                .child(`campaigns/${e.name}${new Date().getTime()}`)
                                .put(e)
                                .then((snapshot) => {
                                    snapshot.ref.getDownloadURL().then((snapUrl) => {
                                        let obj = {
                                            title: voucherTitle,
                                            desc: voucherDesc,
                                            amount: voucherAmount,
                                            imageType: voucherImageType,
                                            voucherIsPercentage,
                                            campaignType: "web_shop",
                                            pushKey: storageKey,
                                            image: snapUrl,
                                            active: this.state.currentOpenedCheck,
                                            hideOnGlobal: this.state.hideOnGlobalVoucher,
                                            showTimerToGuests: this.state.showTimerToGuests,
                                            created_by: uid,
                                            timestamp,
                                            number: voucherNumber,
                                            voucherUnlimited: this.state.voucherUnlimited,
                                            created_at: new Date().getTime(),
                                            coupons: couponsCreated,
                                            timerActiveOnWebshop: this.state.timerActiveOnWebshop,
                                            voucherWebshopUrl,
                                            hideForGuestUsers,
                                            voucherMultipleUseage
                                        };
                                        if (mobilePicUrl) {
                                            obj.image_mobile = mobilePicUrl
                                            obj.imageTypeMobile = voucherImageTypeMobile

                                            if (voucherImageTypeMobile === "takefromlibrary") {
                                                obj.mainSectorStockMobile = mainSectorStockMobile;
                                            }
                                        }
                                        if (this.state.selectedMain.length > 0) {
                                            obj.main_sector = selectedSector;
                                            obj.main_category = selectedMain;
                                        }
                                        if (voucherStartDate && voucherStartTime && voucherEndDate && voucherEndTime) {
                                            obj.voucherStartDate = voucherStartDate;
                                            obj.voucherStartTime = voucherStartTime;
                                            obj.voucherEndDate = voucherEndDate;
                                            obj.voucherEndTime = voucherEndTime;

                                            obj.isoStartTime = isoStartTime
                                            obj.isoEndTime = isoEndTime
                                        }
                                        if (this.state.timerTimeWaiting && this.state.timerDateWaiting) {
                                            obj.timerTimeWaiting = this.state.timerTimeWaiting;
                                            obj.timerDateWaiting = this.state.timerDateWaiting;
                                            obj.isoTimeTimerBtn = isoTimeTimerBtn
                                        }
                                        if (voucherImageType === "takefromlibrary") {
                                            obj.mainSectorStock = mainSectorStock;
                                        }
                                        firebase
                                            .database()
                                            .ref("bhodi_campaigns/" + uid + "/" + storageKey)
                                            .set(obj)
                                            .then(() => {
                                                setTimeout(() => {
                                                    this.props.closePopup("redirect-webshop")
                                                }, 2000);
                                            });
                                    });
                                });
                        });
                    } else {
                        this.setState({
                            noCategorySelectedWebshop: false,
                            voucherTitleAlert: false,
                            voucherImageAlert: false,
                            voucherDescAlert: false,
                            kortingsVoucherTimerAlert: false,
                            loaderShow: true,
                            voucherAmountAlert: false,
                        });
                        let storageKey = firebase
                            .database()
                            .ref("bhodi_campaigns/" + uid)
                            .push().key;
                        let timestamp = Math.floor(Date.now() / 100);
                        let obj = {
                            title: voucherTitle,
                            desc: voucherDesc,
                            amount: voucherAmount,
                            imageType: voucherImageType,
                            voucherIsPercentage,
                            campaignType: "web_shop",
                            pushKey: storageKey,
                            image: voucherImage[0],
                            active: this.state.currentOpenedCheck,
                            hideOnGlobal: this.state.hideOnGlobalVoucher,
                            showTimerToGuests: this.state.showTimerToGuests,
                            created_by: uid,
                            timestamp,
                            number: voucherNumber,
                            voucherUnlimited: this.state.voucherUnlimited,
                            created_at: new Date().getTime(),
                            coupons: couponsCreated,
                            timerActiveOnWebshop: this.state.timerActiveOnWebshop,
                            voucherWebshopUrl,
                            hideForGuestUsers,
                            voucherMultipleUseage
                        };

                        if (mobilePicUrl) {
                            obj.image_mobile = mobilePicUrl
                            obj.imageTypeMobile = voucherImageTypeMobile

                            if (voucherImageTypeMobile === "takefromlibrary") {
                                obj.mainSectorStockMobile = mainSectorStockMobile;
                            }
                        }
                        if (this.state.selectedMain.length > 0) {
                            obj.main_sector = selectedSector;
                            obj.main_category = selectedMain;
                        }
                        if (voucherStartDate && voucherStartTime && voucherEndDate && voucherEndTime) {
                            obj.voucherStartDate = voucherStartDate;
                            obj.voucherStartTime = voucherStartTime;
                            obj.voucherEndDate = voucherEndDate;
                            obj.voucherEndTime = voucherEndTime;

                            obj.isoStartTime = isoStartTime
                            obj.isoEndTime = isoEndTime
                        }
                        if (this.state.timerTimeWaiting && this.state.timerDateWaiting) {
                            obj.timerTimeWaiting = this.state.timerTimeWaiting;
                            obj.timerDateWaiting = this.state.timerDateWaiting;
                            obj.isoTimeTimerBtn = isoTimeTimerBtn
                        }
                        if (voucherImageType === "takefromlibrary") {
                            obj.mainSectorStock = mainSectorStock;
                        }
                        firebase
                            .database()
                            .ref("bhodi_campaigns/" + uid + "/" + storageKey)
                            .set(obj)
                            .then(() => {
                                setTimeout(() => {
                                    this.props.closePopup("redirect-webshop")
                                }, 2000);
                            });
                    }
                }
            })
        } else {
            if (this.state.selectedMain.length > 0) {
                this.setState({ noCategorySelectedWebshop: false });
            } else {
                this.setState({ noCategorySelectedWebshop: true });
            }
            if (voucherTitle) {
                this.setState({ voucherTitleAlert: false });
            } else {
                this.setState({ voucherTitleAlert: true });
            }
            if (voucherDesc) {
                this.setState({ voucherDescAlert: false });
            } else {
                this.setState({ voucherDescAlert: true });
            }
            if (voucherAmount) {
                this.setState({ voucherAmountAlert: false });
            } else {
                this.setState({ voucherAmountAlert: true });
            }
            if (booleanForTimer) {
                this.setState({ voucherTimingAlert: false })
            } else {
                this.setState({ voucherTimingAlert: true })
            }
            if (couponsCreated.length > 0) {
                this.setState({ couponsAlert: false });
            } else {
                this.setState({ couponsAlert: true });
            }
            if (!this.state.companyCurrentWebshop) {
                if (dropFileVoucher.length > 0) {
                    this.setState({ voucherImageAlert: false });
                } else {
                    this.setState({ voucherImageAlert: true });
                }
            }
            if (
                voucherImage[0] !== "https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/placeholder.jpg?alt=media&token=3146a89c-5b95-4a6b-b60f-fb6b0f166bdb"
            ) {
                this.setState({ voucherImageAlert: false });
            } else {
                this.setState({ voucherImageAlert: true });
            }
        }
    }

    render() {
        let uid = localStorage.getItem("userid");
        return (
            <div className="live-event-mainworking">


                {/* local event */}
                {this.state.openAddPopupLocalEvent && (
                    <div
                        className="overlay-div-openadd"
                        onClick={() => {
                            this.setState({ openAddPopupLocalEvent: false })
                            this.props.closePopup()
                        }}
                    ></div>
                )}
                {this.state.openAddPopupLocalEvent && (
                    <div className={`${"separate-page-ce new-campaigns-design campaigns-design-final new-campaigns-design-final"}`} style={{
                        position: window.innerWidth < 1008 && "fixed",
                        zIndex: window.innerWidth < 1008 && 9999999999,
                        backgroundColor: window.innerWidth < 1008 && "white",
                        top: window.innerWidth < 1008 && 0,
                        left: window.innerWidth < 1008 && 0,
                        right: window.innerWidth < 1008 && 0,
                        overflowY: window.innerWidth < 1008 && "auto",
                        height: window.innerWidth < 1008 && "100%",
                        width: window.innerWidth < 1008 && window.innerWidth,
                    }}>
                        <div
                            className={`rightmenuaddproduct rightmenucompletewidthaddproduct`}
                            style={{
                                display: "flex",
                                alignItems: window.innerWidth > 1008 && "center",
                                justifyContent: window.innerWidth > 1008 && "center",
                                flexDirection: window.innerWidth < 1008 && "column",
                            }}
                        >
                            {this.state.loaderShow && (
                                <div className="loader-area" style={{ backgroundImage: "none" }}>
                                    <CloseIcon onClick={() => window.location.reload()} />
                                    <div className="lds-ring">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                    <p style={{ color: "white" }}>Saving Campaign</p>
                                </div>
                            )}
                            {this.state.showingLoaderCreateEdit && (
                                <div className="loader-area" style={{ backgroundImage: "none" }}>
                                    <div className="lds-ring">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                    <p style={{ color: "white" }}>Loading</p>
                                </div>
                            )}

                            {this.state.addStockImage && (
                                <div className="overlay-rmadfofw"></div>
                            )}
                            {this.state.addStockImage && (
                                <div className="rightmenuaddproduct rightmenuaddcategoryformfw rightmenuaddproductstockselection rightmenuproductstockselectionupdated updatedrightmenustock">
                                    <div
                                        className="mid-rmap"
                                        style={{ position: "relative", padding: 13 }}
                                    >
                                        <span>SELECTEER AFBEELDING</span>
                                        <ChevronLeft
                                            style={{
                                                cursor: "pointer",
                                                position: "absolute",
                                                top: "50%",
                                                transform: "translateY(-50%)",
                                                left: 20,
                                                fontSize: 18,
                                            }}
                                            onClick={() => this.setState({ addStockImage: false })}
                                        />
                                    </div>
                                    <div className="bottom-rmap">
                                        <div className="mid-img-uploading-rmap">
                                            <div
                                                className="form-miur"
                                                style={{ marginBottom: 30, position: "relative" }}
                                            >
                                                <label>TYPE BEDRIJF</label>
                                                <div className='wrapper-select-new'>
                                                    <select
                                                        defaultValue="Selecteer"
                                                        value={this.state.mainSectorStock}
                                                        onChange={(e) => {
                                                            if (e.target.value !== "Selecteer") {
                                                                let cloned = Object.entries(
                                                                    this.state.categoryData
                                                                );
                                                                if (this.state.allImagesStock.length > 0) {
                                                                    let filteredSecond =
                                                                        this.state.allImagesStock.filter((u) => {
                                                                            return u[0] === e.target.value;
                                                                        });
                                                                    if (filteredSecond.length > 0) {
                                                                        let a = filteredSecond[0];
                                                                        let arr = [];
                                                                        if (a.length > 0) {
                                                                            let objected = Object.entries(a[1]);
                                                                            objected.map((y) => {
                                                                                arr.push(y[1]);
                                                                            });
                                                                            this.setState({
                                                                                allImagesCurrentSectorStock: arr,
                                                                            });
                                                                        } else {
                                                                            this.setState({
                                                                                allImagesCurrentSectorStock: [],
                                                                            });
                                                                        }
                                                                    }
                                                                }
                                                                this.setState({ mainSectorStock: e.target.value });
                                                            }
                                                        }}
                                                    >
                                                        {!this.state.mainSector && <option>Selecteer</option>}
                                                        {this.state.allSectors.map((g) => {
                                                            return (
                                                                <option value={g.sector_id}>{g.sector_name}</option>
                                                            );
                                                        })}
                                                    </select>
                                                    <ExpandMoreIcon
                                                        style={{
                                                            position: "absolute",
                                                            bottom: 5,
                                                            right: 5,
                                                            pointerEvents: "none",
                                                        }}
                                                        className='expand-more'
                                                    />
                                                </div>
                                            </div>
                                            {this.state.allImagesCurrentSectorStock.length > 0 && (
                                                <div className="imagesstockselectionrightmenu">
                                                    {this.state.allImagesCurrentSectorStock.map((y) => {
                                                        return (
                                                            <div
                                                                className="imagecontainerstockuploader"
                                                                style={{
                                                                    backgroundImage: `url("${y.resizedUrl}")`,
                                                                }}
                                                                onClick={() => {
                                                                    this.state.localEventImage = [y.snapUrl];
                                                                    if (window.innerWidth < 1008) {
                                                                        this.setState({ addStockImage: false });
                                                                    }
                                                                    this.setState({
                                                                        localEventImage: this.state.localEventImage,
                                                                        dropFilelocalEvent: [],
                                                                    });
                                                                }}
                                                            >
                                                                {(this.state.localEventImage.length > 0 && this.state.localEventImage[0] === y.snapUrl) && <div className="overlay-icontstup">
                                                                    <CheckCircleIcon />
                                                                </div>}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                            {this.state.moreTimingsPopup && (
                                <div className="overlay-rmadfofw"></div>
                            )}
                            {this.state.moreTimingsPopup && (
                                <div className="rightmenuaddproduct rightmenuaddcategoryformfw rightmenucategorynew newcategoriesrightmenu">
                                    <div
                                        className="mid-rmap"
                                        style={{ position: "relative", padding: 13 }}
                                    >
                                        <span>TIJDSLOT INSTELLINGEN</span>
                                        <div className="top-header-infocont">
                                            <div style={{ position: "relative" }}>
                                                <div className='green-round-info' style={{ cursor: "pointer" }} onClick={() => {
                                                    this.setState({ categoryInfoOpen: !this.state.categoryInfoOpen })
                                                }}>
                                                    <font>?</font>
                                                </div>
                                                <div className="popup-gri-thifc" style={{ opacity: this.state.categoryInfoOpen ? 1 : 0, pointerEvents: this.state.categoryInfoOpen ? "all" : "none" }}>
                                                    <CloseIcon onClick={() => this.setState({ categoryInfoOpen: false })} />
                                                    <label style={{ fontSize: 15 }}>
                                                        Selecteer de juiste categorie(ën)
                                                        <br />
                                                        waaronder je actie wordt getoond.
                                                    </label>
                                                </div>
                                            </div>
                                            <ChevronLeft
                                                style={{
                                                    cursor: "pointer",
                                                    position: "absolute",
                                                    top: "50%",
                                                    transform: "translateY(-50%)",
                                                    left: 20,
                                                    fontSize: 18,
                                                }}
                                                onClick={() => this.setState({ moreTimingsPopup: false, moreTimingsPopup: false })}
                                            />
                                        </div>
                                    </div>
                                    <div className="bottom-rmap">
                                        <div className="mid-img-uploading-rmap miur-multipletimes">

                                            {this.state.arrEvents.length > 0 && this.state.arrEvents.map((timing, index) => {
                                                if (index === 0) {
                                                    return (<div
                                                        className="form-miur formfullwidthftcic form-inner-for-datetime"
                                                        style={{ position: "relative" }}
                                                    >
                                                        <label>Tijd {index + 1}</label>
                                                        <div className="inner-top-main" style={{ marginTop: 15 }}>
                                                            <label>Starttijd van het event</label>
                                                            <div className="right-fifd">
                                                                <div className="datinput-fifd">
                                                                    <BiCalendar />
                                                                    {this.state.arrEvents[0].startDateEvent ? (
                                                                        <label>
                                                                            {this.getFormattedDate(this.state.arrEvents[0].startDateEvent)}
                                                                        </label>
                                                                    ) : (
                                                                        <label>Datum</label>
                                                                    )}
                                                                    <input
                                                                        type="date"
                                                                        min={this.state.todayDateToBeMax}
                                                                        max={this.state.arrEvents[0].endDateEvent}
                                                                        value={this.state.arrEvents[0].startDateEvent}
                                                                        onChange={(e) => {
                                                                            this.avoidInputsClear()

                                                                            this.state.arrEvents.map((g) => {
                                                                                g.startDateEvent = e.target.value
                                                                            })
                                                                            // this.state.arrEvents[0].startDateEvent =
                                                                            //     e.target.value;
                                                                            this.setState({
                                                                                arrEvents: this.state.arrEvents,
                                                                            });
                                                                        }}
                                                                        disabled={this.state.localEventFromAdmin}
                                                                        style={{ cursor: this.state.localEventFromAdmin && "not-allowed" }}
                                                                    />
                                                                </div>
                                                                <div className="select-fifd">
                                                                    <MdTimer />
                                                                    <select
                                                                        value={this.state.arrEvents[0].startTimeEvent}
                                                                        onChange={(e) => {
                                                                            this.avoidInputsClear()

                                                                            this.state.arrEvents[0].startTimeEvent =
                                                                                e.target.value;
                                                                            this.setState({
                                                                                arrEvents: this.state.arrEvents,
                                                                            });
                                                                        }}
                                                                        disabled={this.state.localEventFromAdmin}
                                                                        style={{ cursor: this.state.localEventFromAdmin && "not-allowed" }}
                                                                    >
                                                                        <option disabled selected hidden value="">
                                                                            Tijd
                                                                        </option>
                                                                        {this.state.allTimes.map((y) => {
                                                                            return (
                                                                                <option value={y.label}>
                                                                                    {y.label}
                                                                                </option>
                                                                            );
                                                                        })}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="inner-top-main" style={{ marginTop: 15 }}>
                                                            <label>Eindtijd van het event</label>
                                                            <div className="right-fifd">
                                                                <div className="datinput-fifd">
                                                                    <BiCalendar />
                                                                    {this.state.arrEvents[0].endDateEvent ? (
                                                                        <label>
                                                                            {this.getFormattedDate(this.state.arrEvents[0].endDateEvent)}
                                                                        </label>
                                                                    ) : (
                                                                        <label>Datum</label>
                                                                    )}
                                                                    <input
                                                                        type="date"
                                                                        min={this.state.arrEvents[0].startDateEvent}
                                                                        value={this.state.arrEvents[0].endDateEvent}
                                                                        onChange={(e) => {
                                                                            this.avoidInputsClear()

                                                                            this.state.arrEvents.map((g) => {
                                                                                g.endDateEvent = e.target.value
                                                                            })
                                                                            this.setState({
                                                                                arrEvents: this.state.arrEvents,
                                                                            });
                                                                            // this.setState({ endDateEvent: e.target.value })
                                                                        }}
                                                                        disabled={this.state.localEventFromAdmin}
                                                                        style={{ cursor: this.state.localEventFromAdmin && "not-allowed" }}
                                                                    />
                                                                </div>
                                                                <div className="select-fifd">
                                                                    <MdTimer />
                                                                    <select
                                                                        value={this.state.arrEvents[0].endTimeEvent}
                                                                        onChange={(e) => {
                                                                            this.avoidInputsClear()

                                                                            this.state.arrEvents[0].endTimeEvent =
                                                                                e.target.value;
                                                                            this.setState({
                                                                                arrEvents: this.state.arrEvents,
                                                                            });
                                                                            // this.setState({ endTimeEvent: e.target.value })
                                                                        }}
                                                                        disabled={this.state.localEventFromAdmin}
                                                                        style={{ cursor: this.state.localEventFromAdmin && "not-allowed" }}
                                                                    >
                                                                        <option disabled selected hidden value="">
                                                                            Tijd
                                                                        </option>
                                                                        {this.state.allTimes.map((y) => {
                                                                            if (this.state.arrEvents[0].startTimeEvent) {
                                                                                if (this.state.arrEvents[0].endDateEvent) {
                                                                                    let currentDate = new Date(
                                                                                        this.state.arrEvents[0].endDateEvent
                                                                                    );
                                                                                    currentDate.setHours(0, 0, 0, 0);
                                                                                    let currentDateStart = new Date(
                                                                                        this.state.arrEvents[0].startDateEvent
                                                                                    );
                                                                                    currentDateStart.setHours(0, 0, 0, 0);
                                                                                    if (currentDate.getTime() === currentDateStart.getTime()) {
                                                                                        let splittedFirst = y.label.split(":")[0];
                                                                                        let splittedMinutesFirst = y.label.split(":")[1];
                                                                                        let splittedSecond = this.state.arrEvents[0].startTimeEvent.split(":")[0];
                                                                                        let splittedMinutesSecond = this.state.arrEvents[0].startTimeEvent.split(":")[1];
                                                                                        if (Number(splittedSecond) < Number(splittedFirst)) {
                                                                                            return (
                                                                                                <option value={y.label}>
                                                                                                    {y.label}
                                                                                                </option>
                                                                                            );
                                                                                        } else if (Number(splittedSecond) === Number(splittedFirst)) {
                                                                                            if (Number(splittedMinutesSecond) < Number(splittedMinutesFirst)) {
                                                                                                return (
                                                                                                    <option value={y.label}>
                                                                                                        {y.label}
                                                                                                    </option>
                                                                                                );
                                                                                            }
                                                                                        }
                                                                                    } else {
                                                                                        return (
                                                                                            <option value={y.label}>
                                                                                                {y.label}
                                                                                            </option>
                                                                                        );
                                                                                    }
                                                                                } else {
                                                                                    return (
                                                                                        <option value={y.label}>
                                                                                            {y.label}
                                                                                        </option>
                                                                                    );
                                                                                }
                                                                            } else {
                                                                                return (
                                                                                    <option value={y.label}>
                                                                                        {y.label}
                                                                                    </option>
                                                                                );
                                                                            }
                                                                        })}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-miur formfullwidthftcic" style={{ paddingTop: 15 }}>
                                                            <label>Aantal</label>
                                                            <input
                                                                defaultValue={this.state.arrEvents[index].personsAmountEvent}
                                                                type="number"
                                                                onBlur={(e) => {
                                                                    let arrEvents = [...this.state.arrEvents];

                                                                    arrEvents[index].personsAmountEvent = Number(e.target.value).toString();
                                                                    this.setState({ arrEvents });
                                                                }}
                                                            />
                                                        </div>
                                                        {this.state.noTimingSelectedEvent && (
                                                            <label style={{ color: "red" }}>Verplicht veld</label>
                                                        )}
                                                    </div>)
                                                }
                                                return (<div
                                                    className="form-miur formfullwidthftcic form-inner-for-datetime form-inner-widthfull"
                                                    style={{ position: "relative" }}
                                                >
                                                    <label>Tijd {index + 1}</label>
                                                    <div className="inner-top-main">
                                                        <label>Starttijd van het event</label>
                                                        <div className="right-fifd">
                                                            <div className="select-fifd">
                                                                <MdTimer />
                                                                <select
                                                                    value={this.state.arrEvents[index].startTimeEvent}
                                                                    onChange={(e) => {
                                                                        this.avoidInputsClear()

                                                                        this.state.arrEvents[index].startTimeEvent = e.target.value;

                                                                        this.setState({
                                                                            arrEvents: this.state.arrEvents,
                                                                        });
                                                                    }}
                                                                >
                                                                    <option disabled selected hidden value="">
                                                                        Tijd
                                                                    </option>
                                                                    {[...this.state.allTimes].filter(time => time.label > this.state.arrEvents[0].startTimeEvent && time.label < this.state.arrEvents[0].endTimeEvent).map((y) => {
                                                                        return (
                                                                            <option value={y.label}>
                                                                                {y.label}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="inner-top-main" style={{ marginTop: 15 }}>
                                                        <label>Eindtijd van het event</label>
                                                        <div className="right-fifd">
                                                            <div className="select-fifd">
                                                                <MdTimer />
                                                                <select
                                                                    value={this.state.arrEvents[index].endTimeEvent}
                                                                    onChange={(e) => {
                                                                        this.avoidInputsClear()

                                                                        this.state.arrEvents[index].endTimeEvent = e.target.value;
                                                                        this.setState({
                                                                            arrEvents: this.state.arrEvents,
                                                                        });
                                                                        // this.setState({ endTimeEvent: e.target.value })
                                                                    }}
                                                                >
                                                                    <option disabled selected hidden value="">
                                                                        Tijd
                                                                    </option>
                                                                    {[...this.state.allTimes].filter(time => time.label > this.state.arrEvents[index].startTimeEvent && time.label < this.state.arrEvents[0].endTimeEvent).map((y) => {
                                                                        return (
                                                                            <option value={y.label}>
                                                                                {y.label}
                                                                            </option>
                                                                        );

                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-miur formfullwidthftcic" style={{ paddingTop: 15 }}>
                                                        <label>Aantal</label>
                                                        <input
                                                            defaultValue={this.state.arrEvents[index].personsAmountEvent}
                                                            type="number"
                                                            onBlur={(e) => {
                                                                let arrEvents = [...this.state.arrEvents];

                                                                arrEvents[index].personsAmountEvent = Number(e.target.value).toString();
                                                                this.setState({ arrEvents });
                                                            }}
                                                        />
                                                    </div>
                                                    {this.state.noTimingSelectedEvent && (
                                                        <label style={{ color: "red" }}>Verplicht veld</label>
                                                    )}
                                                </div>)
                                            })}
                                            {this.state.multipleTimesOpened && (<div className="add-timings" style={{ marginBottom: 20 }} onClick={() => {
                                                let obj = {
                                                    startDateEvent: this.state.arrEvents[0].startDateEvent,
                                                    startTimeEvent: "",
                                                    endDateEvent: this.state.arrEvents[0].endDateEvent,
                                                    endTimeEvent: "",
                                                    personsAmountEvent: "",
                                                    eventId: this.state.arrEvents.length + 1,
                                                }

                                                let allEvents = [...this.state.arrEvents]
                                                allEvents.push(obj)
                                                this.setState({ arrEvents: allEvents })
                                            }}>
                                                <AddCircleOutlineIcon />
                                                <label>Add Timing</label>
                                            </div>)}

                                            <div style={{ width: "100%", height: 15 }}>
                                            </div>
                                            {(this.state.arrEvents[0].startDateEvent && this.state.arrEvents[0].endDateEvent) &&
                                                new Date(this.state.arrEvents[0].startDateEvent).getDate() === new Date(this.state.arrEvents[0].endDateEvent).getDate() &&
                                                new Date(this.state.arrEvents[0].startDateEvent).getMonth() === new Date(this.state.arrEvents[0].endDateEvent).getMonth() &&
                                                new Date(this.state.arrEvents[0].startDateEvent).getFullYear() === new Date(this.state.arrEvents[0].endDateEvent).getFullYear() &&
                                                (<div className="savebtnwork" style={{ width: "100%", position: "static" }}>
                                                    <div
                                                        className="form-miur formfullwidthftcic"
                                                        style={{ position: "relative" }}
                                                    >
                                                        <label>
                                                            Tijdslot instellingen
                                                        </label>
                                                    </div>
                                                    <button>
                                                        <Switch
                                                            color="primary"
                                                            inputProps={{ "aria-label": "primary checkbox" }}
                                                            checked={this.state.multipleTimesOpened}
                                                            onClick={() => {
                                                                if (this.state.multipleTimesOpened) {
                                                                    let allEvents = [...this.state.arrEvents]
                                                                    allEvents.splice(1)
                                                                    this.setState({
                                                                        arrEvents: allEvents,
                                                                    })
                                                                }
                                                                this.setState({
                                                                    multipleTimesOpened: !this.state.multipleTimesOpened,
                                                                });
                                                            }}
                                                        />
                                                        {this.state.multipleTimesOpened ? (
                                                            <label>Actief</label>
                                                        ) : (
                                                            <label>Niet Actief</label>
                                                        )}
                                                    </button>
                                                </div>)}
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div
                                className={`sectiongiveawaycompletepopup ${window.innerWidth < 1008 && "sectionaddproductwork"
                                    }`}
                            >
                                {window.innerWidth > 1008 && (<div className="header-spce" style={{ justifyContent: "center" }}>
                                    <p>EVENT</p>
                                    <div className="top-header-infocont">
                                        <div style={{ position: "relative" }}>
                                            <div className='green-round-info' style={{ cursor: "pointer" }} onClick={() => {
                                                this.setState({ infoRightMenuOpen: !this.state.infoRightMenuOpen })
                                            }}>
                                                <font>?</font>
                                            </div>
                                            <div className="popup-gri-thifc" style={{ opacity: this.state.infoRightMenuOpen ? 1 : 0, pointerEvents: this.state.infoRightMenuOpen ? "all" : "none" }}>
                                                <CloseIcon onClick={() => this.setState({ infoRightMenuOpen: false })} />
                                                <label style={{ fontSize: 15 }}>
                                                    {this.state.descInfolocalEvent}
                                                </label>
                                            </div>
                                        </div>
                                        <CloseIcon className="arrow-back-svg"
                                            onClick={() => {
                                                this.setState({ openAddPopupLocalEvent: false })
                                                this.props.closePopup()
                                            }}
                                        />
                                    </div>
                                </div>)}
                                <div
                                    className="section-first-cplp"
                                    key={Math.random()}
                                    style={{ flexDirection: "row" }}
                                >
                                    <div
                                        className="content-sfcplp"
                                        style={{ position: "relative" }}
                                    >
                                        <div className="formtocreate-icc" style={{ minHeight: this.state.localEventFromAdmin && "auto" }}>
                                            {!this.state.localEventFromAdmin && (<>
                                                <div className="form-miur formfullwidthftcic">
                                                    <label>Titel</label>
                                                    <input
                                                        defaultValue={this.state.localEventTitle}
                                                        id="localEventTitle"
                                                        maxLength={65}
                                                        style={{
                                                            textTransform: "uppercase",
                                                        }}
                                                    />
                                                    {this.state.localEventTitleAlert && (
                                                        <label style={{ color: "red" }}>Verplicht veld</label>
                                                    )}
                                                </div>
                                                <div className="form-miur formfullwidthftcic formckeeditorinner">
                                                    <label>Omschrijving</label>
                                                    <div>
                                                        <CustomCKEditor
                                                            data={this.state.localEventShortDesc}
                                                            ref={this.localEventShortDescRef}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-miur formfullwidthftcic formckeeditorinner">
                                                    <label>Omschrijving</label>
                                                    <div>
                                                        <CustomCKEditor
                                                            data={this.state.localEventLongDesc}
                                                            ref={this.localEventLongDescRef}
                                                        />
                                                    </div>
                                                    {this.state.localEventDescAlert && (
                                                        <label style={{ color: "red" }}>Verplicht veld</label>
                                                    )}
                                                </div>
                                                {/* <div className="form-miur" style={{ position: "relative" }}>
                                                <div className="wrapper-label-nomarginwithswitch">
                                                    <label>Activeer type korting</label>
                                                    <Switch
                                                        color="primary"
                                                        inputProps={{ "aria-label": "primary checkbox" }}
                                                        checked={this.state.discountAvailable}
                                                        onClick={() => {
                                                            let localEventTitle = document.getElementById("localEventTitle").value;
                                                            let localEventDesc = this.localEventDescRef.current.editor.getData();
                                                            let localEventAmount = document.getElementById("localEventAmount").value;

                                                            this.setState({
                                                                discountAvailable:
                                                                    !this.state.discountAvailable,
                                                                localEventTitle,
                                                                localEventDesc,
                                                                localEventAmount,
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div> */}
                                                {this.state.discountAvailable ? (
                                                    <div className="row-form-miur">
                                                        <div
                                                            className="form-miur"
                                                            style={{ position: "relative" }}
                                                        >
                                                            <div>
                                                                <label>Soort korting</label>
                                                            </div>
                                                            <div className='wrapper-select-new'>
                                                                <select
                                                                    value={
                                                                        !this.state.discountAvailable ? "none" : this.state.localEventPercentage ? "per" : "pri"
                                                                    }
                                                                    onChange={(e) => {
                                                                        let localEventTitle =
                                                                            document.getElementById(
                                                                                "localEventTitle"
                                                                            ).value;
                                                                        // let localEventDesc = this.localEventDescRef.current.editor.getData()
                                                                        let localEventShortDesc = this.localEventShortDescRef.current.editor.getData()
                                                                        let localEventLongDesc = this.localEventLongDescRef.current.editor.getData()
                                                                        let localEventAmount =
                                                                            document.getElementById(
                                                                                "localEventAmount"
                                                                            ).value;
                                                                        this.setState({
                                                                            localEventTitle,
                                                                            localEventDesc: localEventShortDesc + localEventLongDesc,
                                                                            localEventShortDesc,
                                                                            localEventLongDesc,
                                                                            localEventAmount,
                                                                        });

                                                                        if (e.target.value === "none") {
                                                                            this.setState({
                                                                                discountAvailable: false,
                                                                                localEventAmount: "",
                                                                            });
                                                                        } else if (e.target.value === "per") {
                                                                            this.setState({
                                                                                localEventPercentage: true,
                                                                                localEventAmount: "",
                                                                                discountAvailable: true,
                                                                            });
                                                                        } else {
                                                                            this.setState({
                                                                                localEventPercentage: false,
                                                                                localEventAmount: "",
                                                                                discountAvailable: true,
                                                                            });
                                                                        }
                                                                    }}
                                                                >
                                                                    <option value="per">Percentage</option>
                                                                    <option value="pri">Bedrag</option>
                                                                    <option value="none">None</option>
                                                                </select>
                                                                <ExpandMoreIcon
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        position: "absolute",
                                                                        right: 5,
                                                                        bottom: 10,
                                                                        fontSize: 18,
                                                                        pointerEvents: "none",
                                                                        pointerEvents: "none",
                                                                    }}
                                                                    className='expand-more'
                                                                />
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="form-miur kortingnumberdiv"
                                                            style={{ position: "relative" }}
                                                        >
                                                            {this.state.discountAvailable && (<label
                                                                style={{
                                                                    position: "absolute",
                                                                    left: this.state.localEventPercentage ? 43 : 8,
                                                                    color: "#8c8c8c",
                                                                    top: 45,
                                                                    zIndex: 99999,
                                                                    pointerEvents: "none"
                                                                }}
                                                            >
                                                                {this.state.localEventPercentage ? "%" : "€"}
                                                            </label>)}
                                                            <label>
                                                                &nbsp;
                                                            </label>
                                                            {this.state.localEventPercentage ? (
                                                                <input
                                                                    type="number"
                                                                    defaultValue={this.state.localEventAmount}
                                                                    id="localEventAmount"
                                                                    onChange={(e) => {
                                                                        if (Number(e.target.value) > 100) {
                                                                            document.getElementById(
                                                                                "localEventAmount"
                                                                            ).value = 100;
                                                                        }
                                                                    }}
                                                                />
                                                            ) : (
                                                                <input
                                                                    type="number"
                                                                    defaultValue={this.state.localEventAmount}
                                                                    id="localEventAmount"
                                                                />
                                                            )}
                                                            {this.state.localEventAmountAlert && (
                                                                <label style={{ color: "red" }}>
                                                                    Verplicht veld
                                                                </label>
                                                            )}
                                                        </div>
                                                    </div>
                                                ) : (<div
                                                    className="form-miur"
                                                    style={{ position: "relative" }}
                                                >
                                                    <div>
                                                        <label>Soort korting</label>
                                                    </div>
                                                    <div className='wrapper-select-new'>
                                                        <input
                                                            type="number"
                                                            defaultValue={this.state.localEventAmount}
                                                            id="localEventAmount"
                                                            style={{ display: "none" }}
                                                        />

                                                        <select
                                                            value={
                                                                !this.state.discountAvailable ? "none" : this.state.localEventPercentage ? "per" : "pri"
                                                            }
                                                            onChange={(e) => {
                                                                let localEventTitle =
                                                                    document.getElementById(
                                                                        "localEventTitle"
                                                                    ).value;
                                                                // let localEventDesc = this.localEventDescRef.current.editor.getData()
                                                                let localEventShortDesc = this.localEventShortDescRef.current.editor.getData()
                                                                let localEventLongDesc = this.localEventLongDescRef.current.editor.getData()
                                                                let localEventAmount =
                                                                    document.getElementById(
                                                                        "localEventAmount"
                                                                    ).value;
                                                                this.setState({
                                                                    localEventTitle,
                                                                    localEventDesc: localEventShortDesc + localEventLongDesc,
                                                                    localEventShortDesc,
                                                                    localEventLongDesc,
                                                                    localEventAmount,
                                                                });

                                                                if (e.target.value === "none") {
                                                                    this.setState({
                                                                        discountAvailable: false,
                                                                        localEventAmount: "",
                                                                    });
                                                                } else if (e.target.value === "per") {
                                                                    this.setState({
                                                                        localEventPercentage: true,
                                                                        localEventAmount: "",
                                                                        discountAvailable: true,
                                                                    });
                                                                } else {
                                                                    this.setState({
                                                                        localEventPercentage: false,
                                                                        localEventAmount: "",
                                                                        discountAvailable: true,
                                                                    });
                                                                }
                                                            }}
                                                        >
                                                            <option value="per">Percentage</option>
                                                            <option value="pri">Bedrag</option>
                                                            <option value="none">None</option>
                                                        </select>
                                                        <ExpandMoreIcon
                                                            style={{
                                                                cursor: "pointer",
                                                                position: "absolute",
                                                                right: 5,
                                                                bottom: 10,
                                                                fontSize: 18,
                                                                pointerEvents: "none",
                                                                pointerEvents: "none",
                                                            }}
                                                            className='expand-more'
                                                        />
                                                    </div>
                                                </div>)}
                                            </>)}

                                            {/* {(<div
                                                className="form-miur formfullwidthftcic form-inner-for-datetime"
                                                style={{ position: "relative" }}
                                            >
                                                <div className="inner-top-main" style={{ marginTop: 15 }}>
                                                    <label>Starttijd van het event</label>
                                                    <div className="right-fifd">
                                                        <div className="datinput-fifd">
                                                            <BiCalendar />
                                                            {this.state.arrEvents[0].startDateEvent ? (
                                                                <label>
                                                                    {this.getFormattedDate(this.state.arrEvents[0].startDateEvent)}
                                                                </label>
                                                            ) : (
                                                                <label>Datum</label>
                                                            )}
                                                            <input
                                                                type="date"
                                                                min={this.state.todayDateToBeMax}
                                                                max={this.state.arrEvents[0].endDateEvent}
                                                                value={this.state.arrEvents[0].startDateEvent}
                                                                onChange={(e) => {
                                                                    this.avoidInputsClear()

                                                                    this.state.arrEvents.map((g) => {
                                                                        g.startDateEvent = e.target.value
                                                                    })
                                                                    // this.state.arrEvents[0].startDateEvent =
                                                                    //     e.target.value;
                                                                    this.setState({
                                                                        arrEvents: this.state.arrEvents,
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="select-fifd">
                                                            <MdTimer />
                                                            <select
                                                                value={this.state.arrEvents[0].startTimeEvent}
                                                                onChange={(e) => {
                                                                    this.avoidInputsClear()

                                                                    this.state.arrEvents[0].startTimeEvent =
                                                                        e.target.value;
                                                                    this.setState({
                                                                        arrEvents: this.state.arrEvents,
                                                                    });
                                                                }}
                                                            >
                                                                <option disabled selected hidden value="">
                                                                    Tijd
                                                                </option>
                                                                {this.state.allTimes.map((y) => {
                                                                    return (
                                                                        <option value={y.label}>
                                                                            {y.label}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="inner-top-main" style={{ marginTop: 15 }}>
                                                    <label>Eindtijd van het event</label>
                                                    <div className="right-fifd">
                                                        <div className="datinput-fifd">
                                                            <BiCalendar />
                                                            {this.state.arrEvents[0].endDateEvent ? (
                                                                <label>
                                                                    {this.getFormattedDate(this.state.arrEvents[0].endDateEvent)}
                                                                </label>
                                                            ) : (
                                                                <label>Datum</label>
                                                            )}
                                                            <input
                                                                type="date"
                                                                min={this.state.arrEvents[0].startDateEvent}
                                                                value={this.state.arrEvents[0].endDateEvent}
                                                                onChange={(e) => {
                                                                    this.avoidInputsClear()

                                                                    this.state.arrEvents.map((g) => {
                                                                        g.endDateEvent = e.target.value
                                                                    })
                                                                    this.setState({
                                                                        arrEvents: this.state.arrEvents,
                                                                    });
                                                                    // this.setState({ endDateEvent: e.target.value })
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="select-fifd">
                                                            <MdTimer />
                                                            <select
                                                                value={this.state.arrEvents[0].endTimeEvent}
                                                                onChange={(e) => {
                                                                    this.avoidInputsClear()

                                                                    this.state.arrEvents[0].endTimeEvent =
                                                                        e.target.value;
                                                                    this.setState({
                                                                        arrEvents: this.state.arrEvents,
                                                                    });
                                                                    // this.setState({ endTimeEvent: e.target.value })
                                                                }}
                                                            >
                                                                <option disabled selected hidden value="">
                                                                    Tijd
                                                                </option>
                                                                {this.state.allTimes.map((y) => {
                                                                    if (this.state.arrEvents[0].startTimeEvent) {
                                                                        if (this.state.arrEvents[0].endDateEvent) {
                                                                            let currentDate = new Date(
                                                                                this.state.arrEvents[0].endDateEvent
                                                                            );
                                                                            currentDate.setHours(0, 0, 0, 0);
                                                                            let currentDateStart = new Date(
                                                                                this.state.arrEvents[0].startDateEvent
                                                                            );
                                                                            currentDateStart.setHours(0, 0, 0, 0);
                                                                            if (currentDate.getTime() === currentDateStart.getTime()) {
                                                                                let splittedFirst = y.label.split(":")[0];
                                                                                let splittedMinutesFirst = y.label.split(":")[1];
                                                                                let splittedSecond = this.state.arrEvents[0].startTimeEvent.split(":")[0];
                                                                                let splittedMinutesSecond = this.state.arrEvents[0].startTimeEvent.split(":")[1];
                                                                                if (Number(splittedSecond) < Number(splittedFirst)) {
                                                                                    return (
                                                                                        <option value={y.label}>
                                                                                            {y.label}
                                                                                        </option>
                                                                                    );
                                                                                } else if (Number(splittedSecond) === Number(splittedFirst)) {
                                                                                    if (Number(splittedMinutesSecond) < Number(splittedMinutesFirst)) {
                                                                                        return (
                                                                                            <option value={y.label}>
                                                                                                {y.label}
                                                                                            </option>
                                                                                        );
                                                                                    }
                                                                                }
                                                                            } else {
                                                                                return (
                                                                                    <option value={y.label}>
                                                                                        {y.label}
                                                                                    </option>
                                                                                );
                                                                            }
                                                                        } else {
                                                                            return (
                                                                                <option value={y.label}>
                                                                                    {y.label}
                                                                                </option>
                                                                            );
                                                                        }
                                                                    } else {
                                                                        return (
                                                                            <option value={y.label}>
                                                                                {y.label}
                                                                            </option>
                                                                        );
                                                                    }
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                {this.state.noTimingSelectedEvent && (
                                                    <label style={{ color: "red" }}>Verplicht veld</label>
                                                )}
                                            </div>)} */}
                                            <>
                                                <div
                                                    className="form-miur"
                                                    style={{ position: "relative" }}
                                                >
                                                    <label>
                                                        Afbeelding
                                                    </label>
                                                    <div className='wrapper-select-new'>
                                                        <select
                                                            value={this.state.localEventImageType}
                                                            onChange={(e) => {
                                                                let localEventTitle =
                                                                    document.getElementById(
                                                                        "localEventTitle"
                                                                    ).value;
                                                                // let localEventDesc = this.localEventDescRef.current.editor.getData()
                                                                let localEventShortDesc = this.localEventShortDescRef.current.editor.getData()
                                                                let localEventLongDesc = this.localEventLongDescRef.current.editor.getData()
                                                                let localEventAmount =
                                                                    document.getElementById(
                                                                        "localEventAmount"
                                                                    ).value;

                                                                this.setState({
                                                                    localEventImageType: e.target.value,
                                                                    localEventTitle,
                                                                    localEventDesc: localEventShortDesc + localEventLongDesc,
                                                                    localEventShortDesc,
                                                                    localEventLongDesc,
                                                                    localEventAmount,
                                                                });
                                                            }}
                                                        >
                                                            <option value="uploadfromcomp">Eigen foto</option>
                                                            <option value="takefromlibrary">
                                                                Bibliotheek
                                                            </option>
                                                        </select>
                                                        <ExpandMoreIcon
                                                            style={{
                                                                cursor: "pointer",
                                                                position: "absolute",
                                                                right: 5,
                                                                bottom: 10,
                                                                fontSize: 18,
                                                                pointerEvents: "none",
                                                                pointerEvents: "none",
                                                            }}
                                                            className='expand-more'
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    className="form-miur"
                                                    style={{ position: "relative" }}
                                                >
                                                    <div className='wrapper-select-new'>
                                                        {this.state.localEventImageType ===
                                                            "uploadfromcomp" ? (
                                                            <input
                                                                type="text"
                                                                value="Upload foto"
                                                                readOnly
                                                                style={{
                                                                    fontSize: 13,
                                                                    letterSpacing: 1,
                                                                    backgroundColor: "white",
                                                                }}
                                                                className="dolabelsuppercase"
                                                            />
                                                        ) : (
                                                            <input
                                                                type="text"
                                                                value="Kies foto"
                                                                readOnly
                                                                style={{
                                                                    fontSize: 13,
                                                                    letterSpacing: 1,
                                                                    backgroundColor: "white",
                                                                }}
                                                                className="dolabelsuppercase"
                                                            />
                                                        )}
                                                        {this.state.localEventImageType ===
                                                            "uploadfromcomp" && (
                                                                <input
                                                                    type="file"
                                                                    onChange={(e) => this.uploadImglocalEvent(e)}
                                                                    accept="image/*,video/*"
                                                                    style={{
                                                                        position: "absolute",
                                                                        width: "100%",
                                                                        height: "80%",
                                                                        top: 0,
                                                                        left: 0,
                                                                        right: 0,
                                                                        bottom: 0,
                                                                        opacity: 0,
                                                                    }}
                                                                />
                                                            )}
                                                        {this.state.localEventImageType ===
                                                            "takefromlibrary" && (
                                                                <div
                                                                    style={{
                                                                        position: "absolute",
                                                                        width: "100%",
                                                                        height: 40,
                                                                        top: 0,
                                                                        cursor: "pointer",
                                                                        left: 0,
                                                                        right: 0,
                                                                        bottom: 0,
                                                                        opacity: 0,
                                                                    }}
                                                                    onClick={() => {
                                                                        let localEventTitle =
                                                                            document.getElementById(
                                                                                "localEventTitle"
                                                                            ).value;
                                                                        // let localEventDesc = this.localEventDescRef.current.editor.getData()
                                                                        let localEventShortDesc = this.localEventShortDescRef.current.editor.getData()
                                                                        let localEventLongDesc = this.localEventLongDescRef.current.editor.getData()
                                                                        let localEventAmount =
                                                                            document.getElementById(
                                                                                "localEventAmount"
                                                                            ).value;

                                                                        this.setState({
                                                                            addStockImage: true,
                                                                            localEventTitle,
                                                                            localEventDesc: localEventShortDesc + localEventLongDesc,
                                                                            localEventShortDesc,
                                                                            localEventLongDesc,
                                                                            localEventAmount,
                                                                        });
                                                                    }}
                                                                ></div>
                                                            )}
                                                        <PublishIcon
                                                            style={{
                                                                cursor: "pointer",
                                                                position: "absolute",
                                                                right: 5,
                                                                bottom: this.state.localEventImageAlert ? 37 : 10,
                                                                fontSize: 18,
                                                                pointerEvents:
                                                                    this.state.localEventImageType ===
                                                                    "uploadfromcomp" && "none",
                                                            }}
                                                            onClick={() => {
                                                                let localEventTitle =
                                                                    document.getElementById(
                                                                        "localEventTitle"
                                                                    ).value;
                                                                // let localEventDesc = this.localEventDescRef.current.editor.getData()
                                                                let localEventShortDesc = this.localEventShortDescRef.current.editor.getData()
                                                                let localEventLongDesc = this.localEventLongDescRef.current.editor.getData()
                                                                let localEventAmount =
                                                                    document.getElementById(
                                                                        "localEventAmount"
                                                                    ).value;

                                                                this.setState({
                                                                    addStockImage: true,
                                                                    localEventTitle,
                                                                    localEventDesc: localEventShortDesc + localEventLongDesc,
                                                                    localEventShortDesc,
                                                                    localEventLongDesc,
                                                                    localEventAmount,
                                                                });
                                                            }}
                                                            className='expand-more'
                                                        />
                                                    </div>
                                                    {this.state.localEventImageAlert && (
                                                        <label style={{ color: "red" }}>
                                                            Verplicht veld
                                                        </label>
                                                    )}
                                                </div>
                                            </>
                                            <div className='form-miur'>
                                                <div className='image-wrappwr-fwcoco imagewithnewdesign'>
                                                    <input />
                                                    <div className="image-campaigns-new icn-newupdatedoneline">
                                                        <div
                                                            className="image-sfcplp"
                                                            style={{
                                                                backgroundImage: this.state.localEventPlaceHolderIsImage
                                                                    ? `url("${this.state.localEventImage[0]}")`
                                                                    : "none",
                                                            }}
                                                        >
                                                            {!this.state.localEventPlaceHolderIsImage && (
                                                                <video
                                                                    className="video-local-event-cedit"
                                                                    muted
                                                                    src={this.state.localEventImage[0]}
                                                                    autoPlay
                                                                    loop
                                                                    playsInline
                                                                ></video>
                                                            )}
                                                        </div>
                                                        {this.checkForDeleteLocalEvent()}
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="image-campaigns-new">
                                                <div
                                                    className="image-sfcplp"
                                                    style={{
                                                        backgroundImage: this.state.localEventPlaceHolderIsImage
                                                            ? `url("${this.state.localEventImage[0]}")`
                                                            : "none",
                                                    }}
                                                >
                                                    {!this.state.localEventPlaceHolderIsImage && (
                                                        <video
                                                            className="video-local-event-cedit"
                                                            muted
                                                            src={this.state.localEventImage[0]}
                                                            autoPlay
                                                            loop
                                                            playsInline
                                                        ></video>
                                                    )}
                                                </div>
                                                {this.checkForDeleteLocalEvent()}
                                            </div> */}
                                        </div>
                                        <div className="savebtnwork">
                                            {/* {this.state.arrEvents.length === 1 && (<div
                                                className="form-miur formfullwidthftcic"
                                                style={{ position: "relative" }}
                                            >
                                                <label>
                                                    Voorraad
                                                </label>
                                            </div>)}
                                            {this.state.arrEvents.length === 1 && (<div
                                                className="form-miur formmiurnumbersinputaantal"
                                                style={{
                                                    padding: "0px 20px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <input
                                                    type="number"
                                                    defaultValue={Number(this.state.arrEvents[0].personsAmountEvent)}
                                                    id="localEventAmount"
                                                    onBlur={(e) => {
                                                        let arrEvents = [...this.state.arrEvents];

                                                        arrEvents[0].personsAmountEvent = Number(e.target.value).toString();
                                                        this.setState({ arrEvents });

                                                        let localEventTitle = document.getElementById("localEventTitle").value;
                                                        let localEventDesc = this.localEventDescRef.current.editor.getData();
                                                        let localEventAmount = document.getElementById("localEventAmount").value;

                                                        this.setState({
                                                            localEventTitle,
                                                            localEventDesc,
                                                            localEventAmount,
                                                        });
                                                    }}
                                                />
                                                <label
                                                    style={{
                                                        color: "black",
                                                        marginBottom: 0,
                                                        marginLeft: 10,
                                                        fontSize: 13,
                                                        fontWeight: 400,
                                                        letterSpacing: 1,
                                                    }}
                                                >
                                                    Aantal
                                                </label>
                                            </div>)} */}

                                            {/* {(this.state.arrEvents[0].startDateEvent && this.state.arrEvents[0].endDateEvent) &&
                                                new Date(this.state.arrEvents[0].startDateEvent).getDate() === new Date(this.state.arrEvents[0].endDateEvent).getDate() &&
                                                new Date(this.state.arrEvents[0].startDateEvent).getMonth() === new Date(this.state.arrEvents[0].endDateEvent).getMonth() &&
                                                new Date(this.state.arrEvents[0].startDateEvent).getFullYear() === new Date(this.state.arrEvents[0].endDateEvent).getFullYear() &&
                                                (<>
                                                    <div
                                                        className="form-miur formfullwidthftcic"
                                                        style={{ position: "relative" }}
                                                    >
                                                        <label>
                                                            Tijdslot instellingen
                                                        </label>
                                                    </div>
                                                    <button>
                                                        <Switch
                                                            color="primary"
                                                            inputProps={{ "aria-label": "primary checkbox" }}
                                                            checked={this.state.multipleTimesOpened}
                                                            onClick={() => {
                                                                if (this.state.multipleTimesOpened) {
                                                                    let allEvents = [...this.state.arrEvents]
                                                                    allEvents.splice(1)
                                                                    this.setState({
                                                                        arrEvents: allEvents,
                                                                    })
                                                                }
                                                                let localEventTitle = document.getElementById("localEventTitle").value;
                                                                let localEventDesc = this.localEventDescRef.current.editor.getData();
                                                                let localEventAmount = document.getElementById("localEventAmount").value;

                                                                this.setState({
                                                                    multipleTimesOpened: !this.state.multipleTimesOpened,
                                                                    localEventTitle,
                                                                    localEventDesc,
                                                                    localEventAmount,
                                                                });
                                                            }}
                                                        />
                                                        {this.state.multipleTimesOpened ? (
                                                            <label>Actief</label>
                                                        ) : (
                                                            <label>Niet Actief</label>
                                                        )}
                                                    </button>
                                                </>)} */}
                                            <div
                                                className="form-miur formfullwidthftcic"
                                                style={{ position: "relative" }}
                                            >
                                                <label>
                                                    Datum en tijd voor het event
                                                </label>
                                            </div>
                                            <button onClick={() => {
                                                if (this.state.localEventFromAdmin) {
                                                    this.setState({
                                                        moreTimingsPopup: true,
                                                    });
                                                    return false;
                                                }

                                                let localEventTitle = document.getElementById("localEventTitle").value;
                                                // let localEventDesc = this.localEventDescRef.current.editor.getData();
                                                let localEventShortDesc = this.localEventShortDescRef.current.editor.getData()
                                                let localEventLongDesc = this.localEventLongDescRef.current.editor.getData()
                                                let localEventAmount = document.getElementById("localEventAmount").value;

                                                this.setState({
                                                    moreTimingsPopup: true,
                                                    localEventTitle,
                                                    localEventDesc: localEventShortDesc + localEventLongDesc,
                                                    localEventShortDesc,
                                                    localEventLongDesc,
                                                    localEventAmount,
                                                });
                                            }}>
                                                <ChevronRight />
                                                {/* {this.state.arrEvents.length > 1 ? (
                                                            <label>Added</label>
                                                        ) : (
                                                            <label>Add</label>
                                                        )} */}
                                                <label>Bekijk</label>
                                            </button>
                                            {!this.props.isAdmin && !this.state.localEventFromAdmin && (<>
                                                <div
                                                    className="form-miur formfullwidthftcic"
                                                    style={{ position: "relative" }}
                                                >
                                                    <label>
                                                        Verberg op globale pagina
                                                    </label>
                                                </div>
                                                <button>
                                                    <Switch
                                                        color="primary"
                                                        inputProps={{ "aria-label": "primary checkbox" }}
                                                        checked={this.state.hideOnGlobalEvent}
                                                        onClick={() => {
                                                            if (this.state.localEventFromAdmin) {
                                                                this.setState({
                                                                    hideOnGlobalEvent: !this.state.hideOnGlobalEvent,
                                                                });
                                                                return false;
                                                            }

                                                            let localEventTitle = document.getElementById("localEventTitle").value;
                                                            // let localEventDesc = this.localEventDescRef.current.editor.getData();
                                                            let localEventShortDesc = this.localEventShortDescRef.current.editor.getData()
                                                            let localEventLongDesc = this.localEventLongDescRef.current.editor.getData()
                                                            let localEventAmount = document.getElementById("localEventAmount").value;

                                                            this.setState({
                                                                hideOnGlobalEvent: !this.state.hideOnGlobalEvent,
                                                                localEventTitle,
                                                                localEventDesc: localEventShortDesc + localEventLongDesc,
                                                                localEventShortDesc,
                                                                localEventLongDesc,
                                                                localEventAmount,
                                                            });
                                                        }}
                                                    />
                                                    {this.state.hideOnGlobalEvent ? (
                                                        <label>Hidden</label>
                                                    ) : (
                                                        <label>Showing</label>
                                                    )}
                                                </button>
                                            </>)}
                                            {!this.props.isAdmin && (<>
                                                <div
                                                    className="form-miur formfullwidthftcic"
                                                    style={{ position: "relative" }}
                                                >
                                                    <label>
                                                        Actie Status
                                                    </label>
                                                </div>
                                                <button>
                                                    <Switch
                                                        color="primary"
                                                        inputProps={{ "aria-label": "primary checkbox" }}
                                                        checked={this.state.currentOpenedCheck}
                                                        onClick={() => {
                                                            if (this.state.localEventFromAdmin) {
                                                                this.setState({
                                                                    currentOpenedCheck: !this.state.currentOpenedCheck,
                                                                });
                                                                return false;
                                                            }
                                                            let localEventTitle = document.getElementById("localEventTitle").value;
                                                            // let localEventDesc = this.localEventDescRef.current.editor.getData();
                                                            let localEventShortDesc = this.localEventShortDescRef.current.editor.getData()
                                                            let localEventLongDesc = this.localEventLongDescRef.current.editor.getData()
                                                            let localEventAmount = document.getElementById("localEventAmount").value;

                                                            this.setState({
                                                                currentOpenedCheck: !this.state.currentOpenedCheck,
                                                                localEventTitle,
                                                                localEventDesc: localEventShortDesc + localEventLongDesc,
                                                                localEventShortDesc,
                                                                localEventLongDesc,
                                                                localEventAmount,
                                                            });
                                                        }}
                                                    />
                                                    {this.state.currentOpenedCheck ? (
                                                        <label>Live</label>
                                                    ) : (
                                                        <label>Offline</label>
                                                    )}
                                                </button>
                                            </>)}
                                            {!this.props.isAdmin && (<>
                                                <div
                                                    className="form-miur formfullwidthftcic"
                                                    style={{ position: "relative" }}
                                                >
                                                    <label>
                                                        Guest User Only
                                                    </label>
                                                </div>
                                                <button>
                                                    <Switch
                                                        color="primary"
                                                        inputProps={{ "aria-label": "primary checkbox" }}
                                                        checked={this.state.eventForGuestUser}
                                                        onClick={() => {
                                                            if (this.state.localEventFromAdmin) {
                                                                this.setState({
                                                                    eventForGuestUser: !this.state.eventForGuestUser,
                                                                });
                                                                return false;
                                                            }
                                                            let localEventTitle = document.getElementById("localEventTitle").value;
                                                            // let localEventDesc = this.localEventDescRef.current.editor.getData();
                                                            let localEventShortDesc = this.localEventShortDescRef.current.editor.getData()
                                                            let localEventLongDesc = this.localEventLongDescRef.current.editor.getData()
                                                            let localEventAmount = document.getElementById("localEventAmount").value;

                                                            this.setState({
                                                                eventForGuestUser: !this.state.eventForGuestUser,
                                                                localEventTitle,
                                                                localEventDesc: localEventShortDesc + localEventLongDesc,
                                                                localEventShortDesc,
                                                                localEventLongDesc,
                                                                localEventAmount,
                                                            });
                                                        }}
                                                    />
                                                    {this.state.eventForGuestUser ? (
                                                        <label>Yes</label>
                                                    ) : (
                                                        <label>No</label>
                                                    )}
                                                </button>
                                            </>)}
                                            {!this.props.isAdmin && (<>
                                                <div
                                                    className="form-miur formfullwidthftcic"
                                                    style={{ position: "relative" }}
                                                >
                                                    <label>
                                                        Manual Code Import
                                                    </label>
                                                </div>
                                                <button>
                                                    <Switch
                                                        color="primary"
                                                        inputProps={{ "aria-label": "primary checkbox" }}
                                                        checked={this.state.manual_code_import}
                                                        onClick={() => {
                                                            if (this.state.localEventFromAdmin) {
                                                                this.setState({
                                                                    manual_code_import: !this.state.manual_code_import,
                                                                });
                                                                return false;
                                                            }
                                                            let localEventTitle = document.getElementById("localEventTitle").value;
                                                            // let localEventDesc = this.localEventDescRef.current.editor.getData();
                                                            let localEventShortDesc = this.localEventShortDescRef.current.editor.getData()
                                                            let localEventLongDesc = this.localEventLongDescRef.current.editor.getData()
                                                            let localEventAmount = document.getElementById("localEventAmount").value;

                                                            this.setState({
                                                                manual_code_import: !this.state.manual_code_import,
                                                                localEventTitle,
                                                                localEventDesc: localEventShortDesc + localEventLongDesc,
                                                                localEventShortDesc,
                                                                localEventLongDesc,
                                                                localEventAmount,
                                                            });
                                                        }}
                                                    />
                                                    {this.state.manual_code_import ? (
                                                        <label>Yes</label>
                                                    ) : (
                                                        <label>No</label>
                                                    )}
                                                </button>
                                            </>)}
                                            {!this.props.isAdmin && (<>
                                                <div
                                                    className="form-miur formfullwidthftcic"
                                                    style={{ position: "relative" }}
                                                >
                                                    <label>
                                                        Hide Date on Page
                                                    </label>
                                                </div>
                                                <button>
                                                    <Switch
                                                        color="primary"
                                                        inputProps={{ "aria-label": "primary checkbox" }}
                                                        checked={this.state.hide_date_on_page}
                                                        onClick={() => {
                                                            if (this.state.localEventFromAdmin) {
                                                                this.setState({
                                                                    hide_date_on_page: !this.state.hide_date_on_page,
                                                                });
                                                                return false;
                                                            }
                                                            let localEventTitle = document.getElementById("localEventTitle").value;
                                                            // let localEventDesc = this.localEventDescRef.current.editor.getData();
                                                            let localEventShortDesc = this.localEventShortDescRef.current.editor.getData()
                                                            let localEventLongDesc = this.localEventLongDescRef.current.editor.getData()
                                                            let localEventAmount = document.getElementById("localEventAmount").value;

                                                            this.setState({
                                                                hide_date_on_page: !this.state.hide_date_on_page,
                                                                localEventTitle,
                                                                localEventDesc: localEventShortDesc + localEventLongDesc,
                                                                localEventShortDesc,
                                                                localEventLongDesc,
                                                                localEventAmount,
                                                            });
                                                        }}
                                                    />
                                                    {this.state.hide_date_on_page ? (
                                                        <label>Yes</label>
                                                    ) : (
                                                        <label>No</label>
                                                    )}
                                                </button>
                                            </>)}
                                            {this.state.localEventBlock.length > 0 ? (
                                                <button
                                                    className="savebtnworksave"
                                                    style={{ justifyContent: window.innerWidth < 1008 && "center" }}
                                                    onClick={() => {
                                                        this.saveCampaignlocalEvent("noupdate");
                                                    }}
                                                >
                                                    OPSLAAN
                                                </button>
                                            ) : (
                                                <button
                                                    className="savebtnworksave"
                                                    style={{ justifyContent: window.innerWidth < 1008 && "center" }}
                                                    onClick={this.saveCampaignlocalEvent.bind(this)}
                                                >
                                                    OPSLAAN
                                                </button>
                                            )}
                                            {this.state.localEventBlockOnEdit && (
                                                <button
                                                    className="savebtnworksave pinkcolorbuttondesign"
                                                    style={{ justifyContent: window.innerWidth < 1008 && "center" }}
                                                    onClick={() => this.setState({ deletelocalEvent: true })}
                                                >
                                                    VERWIJDER
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}


                {/* Kortingsvoucher */}

                {/* local event */}
                {this.state.openAddPopupKortingsvoucher && (
                    <div
                        className="overlay-div-openadd"
                        onClick={() => {
                            this.props.closePopup("redirect-voucher")
                            // this.setState({
                            //     openAddPopupKortingsvoucher: false,
                            // });
                        }}
                    ></div>
                )}
                {this.state.openAddPopupKortingsvoucher && (
                    <div className="separate-page-ce new-campaigns-design campaigns-design-final new-campaigns-design-final">
                        <div
                            className="rightmenuaddproduct rightmenucompletewidthaddproduct"
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            {this.state.loaderShow && (
                                <div className="loader-area" style={{ backgroundImage: "none" }}>
                                    <CloseIcon onClick={() => window.location.reload()} />
                                    <div className="lds-ring">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                    <p style={{ color: "white" }}>Saving Campaign</p>
                                </div>
                            )}
                            {this.state.showingLoaderCreateEdit && (
                                <div className="loader-area" style={{ backgroundImage: "none" }}>
                                    <div className="lds-ring">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                    <p style={{ color: "white" }}>Loading</p>
                                </div>
                            )}
                            {this.state.addCategory && <div className="overlay-rmadfofw"></div>}
                            {/* {this.state.addCategory && (
                                <div className="rightmenuaddproduct rightmenuaddcategoryformfw rightmenucategorynew newcategoriesrightmenu">
                                    <div
                                        className="mid-rmap"
                                        style={{ position: "relative", padding: 13 }}
                                    >
                                        <span>SELECTEER CATEGORIE</span>
                                        <div className="top-header-infocont">
                                            <div style={{ position: "relative" }}>
                                                <div className='green-round-info' style={{ cursor: "pointer" }} onClick={() => {
                                                    this.setState({ categoryInfoOpen: !this.state.categoryInfoOpen })
                                                }}>
                                                    <font>?</font>
                                                </div>
                                                <div className="popup-gri-thifc" style={{ opacity: this.state.categoryInfoOpen ? 1 : 0, pointerEvents: this.state.categoryInfoOpen ? "all" : "none" }}>
                                                    <CloseIcon onClick={() => this.setState({ categoryInfoOpen: false })} />
                                                    <label style={{ fontSize: 15 }}>
                                                        Selecteer de juiste categorie(ën)
                                                        <br />
                                                        waaronder je actie wordt getoond.
                                                    </label>
                                                </div>
                                            </div>
                                            <ChevronLeft
                                                style={{
                                                    cursor: "pointer",
                                                    position: "absolute",
                                                    top: "50%",
                                                    transform: "translateY(-50%)",
                                                    left: 20,
                                                    fontSize: 18,
                                                }}
                                                onClick={() => this.setState({ addCategory: false, categoryInfoOpen: false })}
                                            />
                                        </div>
                                    </div>
                                    <div className="bottom-rmap">
                                        <div className="mid-img-uploading-rmap">
                                            {this.state.all_categories.length > 0 && (
                                                <ul className="checkboxlistselectcategory">
                                                    {this.state.all_categories.map((e) => {
                                                        return (
                                                            <li className="checkboxfieldstypeselection">
                                                                <input
                                                                    type="checkbox"
                                                                    className="input-radio checkboxessortname"
                                                                    value={e.category_id}
                                                                    checked={this.state.selectedMain.includes(
                                                                        e.category_id
                                                                    )}
                                                                    onChange={() => {
                                                                        if (
                                                                            this.state.selectedMain.includes(
                                                                                e.category_id
                                                                            )
                                                                        ) {
                                                                            let indexed =
                                                                                this.state.selectedMain.indexOf(
                                                                                    e.category_id
                                                                                );
                                                                            this.state.selectedMain.splice(indexed, 1);
                                                                            this.setState({
                                                                                selectedMain: this.state.selectedMain,
                                                                            });
                                                                        } else {
                                                                            this.state.selectedMain.push(e.category_id);
                                                                            this.setState({
                                                                                selectedMain: this.state.selectedMain,
                                                                            });
                                                                        }
                                                                    }}
                                                                />
                                                                <p>{e.title}</p>
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )} */}
                            {this.state.addCategory && this.categoryPopupRender()}
                            {this.state.addStockImage && (
                                <div className="overlay-rmadfofw"></div>
                            )}
                            {this.state.addStockImage && (
                                <div className="rightmenuaddproduct rightmenuaddcategoryformfw rightmenuaddproductstockselection rightmenuproductstockselectionupdated updatedrightmenustock">
                                    <div
                                        className="mid-rmap"
                                        style={{ position: "relative", padding: 13 }}
                                    >
                                        <span>SELECTEER AFBEELDING</span>
                                        <ChevronLeft
                                            style={{
                                                cursor: "pointer",
                                                position: "absolute",
                                                top: "50%",
                                                fontSize: 18,
                                                transform: "translateY(-50%)",
                                                left: 20,
                                            }}
                                            onClick={() => this.setState({ addStockImage: false })}
                                        />
                                    </div>
                                    <div className="bottom-rmap">
                                        <div className="mid-img-uploading-rmap">
                                            <div
                                                className="form-miur"
                                                style={{ marginBottom: 30, position: "relative" }}
                                            >
                                                <label>TYPE BEDRIJF</label>
                                                <div className='wrapper-select-new'>
                                                    <select
                                                        defaultValue="Selecteer"
                                                        value={(this.state.kortingsVoucherSelectedMobile && this.state.mainSectorStockMobile) ? this.state.mainSectorStockMobile : this.state.mainSectorStock}
                                                        // value={this.state.mainSectorStock}
                                                        onChange={(e) => {
                                                            if (e.target.value !== "Selecteer") {
                                                                if (this.state.allImagesStock.length > 0) {
                                                                    let filteredSecond =
                                                                        this.state.allImagesStock.filter((u) => {
                                                                            return u[0] === e.target.value;
                                                                        });
                                                                    if (filteredSecond.length > 0) {
                                                                        let a = filteredSecond[0];
                                                                        let arr = [];
                                                                        if (a.length > 0) {
                                                                            let objected = Object.entries(a[1]);
                                                                            objected.map((y) => {
                                                                                arr.push(y[1]);
                                                                            });
                                                                            this.setState({
                                                                                allImagesCurrentSectorStock: arr,
                                                                            });
                                                                        } else {
                                                                            this.setState({
                                                                                allImagesCurrentSectorStock: [],
                                                                            });
                                                                        }
                                                                    }
                                                                }
                                                                if (this.state.kortingsVoucherSelectedMobile) {
                                                                    this.setState({ mainSectorStockMobile: e.target.value });
                                                                } else {
                                                                    this.setState({ mainSectorStock: e.target.value });
                                                                }
                                                            }
                                                        }}
                                                    >
                                                        {!this.state.mainSector && <option>Selecteer</option>}
                                                        {this.state.allSectors.map((g) => {
                                                            return (
                                                                <option value={g.sector_id}>{g.sector_name}</option>
                                                            );
                                                        })}
                                                    </select>
                                                    <ExpandMoreIcon
                                                        style={{
                                                            position: "absolute",
                                                            bottom: 5,
                                                            right: 5,
                                                            pointerEvents: "none",
                                                        }}
                                                        className='expand-more'
                                                    />
                                                </div>
                                            </div>
                                            {this.state.allImagesCurrentSectorStock.length > 0 && (
                                                <div className="imagesstockselectionrightmenu">
                                                    {this.state.allImagesCurrentSectorStock.map((y) => {
                                                        return (
                                                            <div
                                                                className="imagecontainerstockuploader"
                                                                style={{
                                                                    backgroundImage: `url("${y.resizedUrl}")`,
                                                                }}
                                                                onClick={() => {
                                                                    if (this.state.kortingsVoucherSelectedMobile) {
                                                                        this.state.kortingsVoucherImageMobile = [y.snapUrl];
                                                                        if (window.innerWidth < 1008) {
                                                                            this.setState({ addStockImage: false });
                                                                        }
                                                                        this.setState({
                                                                            kortingsVoucherImageMobile: this.state.kortingsVoucherImageMobile,
                                                                            dropFileKortingsVoucherMobile: [],
                                                                        });
                                                                        return false;
                                                                    }
                                                                    this.state.kortingsVoucherImage = [y.snapUrl];
                                                                    if (window.innerWidth < 1008) {
                                                                        this.setState({ addStockImage: false });
                                                                    }
                                                                    this.setState({
                                                                        kortingsVoucherImage: this.state.kortingsVoucherImage,
                                                                        dropFileKortingsVoucher: [],
                                                                    });
                                                                }}
                                                            >
                                                                {this.state.kortingsVoucherSelectedMobile && (this.state.kortingsVoucherImageMobile.length > 0 && this.state.kortingsVoucherImageMobile[0] === y.snapUrl) && <div className="overlay-icontstup">
                                                                    <CheckCircleIcon />
                                                                </div>}
                                                                {!this.state.kortingsVoucherSelectedMobile && (this.state.kortingsVoucherImage.length > 0 && this.state.kortingsVoucherImage[0] === y.snapUrl) && <div className="overlay-icontstup">
                                                                    <CheckCircleIcon />
                                                                </div>}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="sectiongiveawaycompletepopup">
                                <div className="header-spce" style={{ justifyContent: "center" }}>
                                    <p>KORTINGSVOUCHER</p>
                                    <div className="top-header-infocont">
                                        <div style={{ position: "relative" }}>
                                            <div className='green-round-info' style={{ cursor: "pointer" }} onClick={() => {
                                                this.setState({ kortingInfoRightMenuOpen: !this.state.kortingInfoRightMenuOpen })
                                            }}>
                                                <font>?</font>
                                            </div>
                                            <div className="popup-gri-thifc" style={{ opacity: this.state.kortingInfoRightMenuOpen ? 1 : 0, pointerEvents: this.state.kortingInfoRightMenuOpen ? "all" : "none" }}>
                                                <CloseIcon onClick={() => this.setState({ kortingInfoRightMenuOpen: false })} />
                                                <label style={{ fontSize: 15 }}>
                                                    {this.state.descInfoKortingsVoucher}
                                                </label>
                                            </div>
                                        </div>
                                        <CloseIcon className="arrow-back-svg"
                                            onClick={() => {
                                                this.props.closePopup("redirect-voucher")
                                            }}
                                        />
                                    </div>
                                </div>
                                <div
                                    className="section-first-cplp"
                                    key={Math.random()}
                                    style={{ flexDirection: "row" }}
                                >
                                    <div
                                        className="content-sfcplp"
                                        style={{ position: "relative" }}
                                    >
                                        <div className="formtocreate-icc">
                                            <div className="form-miur formfullwidthftcic">
                                                <label>Titel</label>
                                                <input
                                                    defaultValue={this.state.kortingsVoucherTitle}
                                                    id="kortingsVoucherTitle"
                                                    maxLength={65}
                                                // style={{ textTransform: "uppercase" }}
                                                />
                                                {this.state.kortingsVoucherTitleAlert && (
                                                    <label style={{ color: "red" }}>Verplicht veld</label>
                                                )}
                                            </div>
                                            <div className="form-miur formfullwidthftcic formckeeditorinner">
                                                <label>Omschrijving</label>
                                                {/* <textarea
                                                    defaultValue={this.state.kortingsVoucherDesc}
                                                    id="kortingsVoucherDesc"
                                                    maxLength={480}
                                                    style={{ minHeight: 140 }}
                                                ></textarea> */}
                                                {/* <CKDesc
                                                    data={this.state.kortingsVoucherDesc}
                                                    // onChildChange={(e) => {
                                                    //     this.setState({ kortingsVoucherDesc: e })
                                                    // }}
                                                    id="kortingsVoucherDesc"
                                                /> */}
                                                {/* {this.state.showDescComponent && (<CKEditor
                                                    editor={ClassicEditor}
                                                    data={this.state.kortingsVoucherDesc}
                                                    config={{ toolbar: [] }}
                                                    ref={this.kortingsVoucherDescRef}
                                                />)} */}
                                                <CustomCKEditor
                                                    data={this.state.kortingsVoucherDesc}
                                                    ref={this.kortingsVoucherDescRef}
                                                />
                                                {/* <div id="editorKortingsVoucher" data={this.state.kortingsVoucherDesc}></div> */}
                                                {this.state.kortingsVoucherDescAlert && (
                                                    <label style={{ color: "red" }}>Verplicht veld</label>
                                                )}
                                            </div>
                                            <div
                                                className="form-miur"
                                                style={{ position: "relative" }}
                                            >
                                                <label className="dolabelsuppercase">
                                                    <font>Afbeelding</font>
                                                    <div>
                                                        <div className={`round-icons-mobilepics ${!this.state.kortingsVoucherSelectedMobile && "active"}`} onClick={() => {
                                                            if (this.state.allImagesStock.length > 0) {
                                                                let filteredSecond =
                                                                    this.state.allImagesStock.filter((u) => {
                                                                        return u[0] === this.state.mainSectorStock;
                                                                    });
                                                                if (filteredSecond.length > 0) {
                                                                    let a = filteredSecond[0];
                                                                    let arr = [];
                                                                    if (a.length > 0) {
                                                                        let objected = Object.entries(a[1]);
                                                                        objected.map((y) => {
                                                                            arr.push(y[1]);
                                                                        });
                                                                        this.setState({
                                                                            allImagesCurrentSectorStock: arr,
                                                                        });
                                                                    } else {
                                                                        this.setState({
                                                                            allImagesCurrentSectorStock: [],
                                                                        });
                                                                    }
                                                                }
                                                            }

                                                            this.setState({ kortingsVoucherSelectedMobile: false })
                                                        }}>
                                                            <GoDeviceDesktop />
                                                        </div>
                                                        {/* <div className="spacing-between-icons"></div> */}
                                                        <div className={`round-icons-mobilepics  ${this.state.kortingsVoucherSelectedMobile && "active"}`} onClick={() => {
                                                            if (this.state.allImagesStock.length > 0) {
                                                                let filteredSecond =
                                                                    this.state.allImagesStock.filter((u) => {
                                                                        return u[0] === this.state.mainSectorStockMobile;
                                                                    });
                                                                if (filteredSecond.length > 0) {
                                                                    let a = filteredSecond[0];
                                                                    let arr = [];
                                                                    if (a.length > 0) {
                                                                        let objected = Object.entries(a[1]);
                                                                        objected.map((y) => {
                                                                            arr.push(y[1]);
                                                                        });
                                                                        this.setState({
                                                                            allImagesCurrentSectorStock: arr,
                                                                        });
                                                                    } else {
                                                                        this.setState({
                                                                            allImagesCurrentSectorStock: [],
                                                                        });
                                                                    }
                                                                }
                                                            }

                                                            this.setState({ kortingsVoucherSelectedMobile: true })
                                                        }}>
                                                            <CiMobile3 />
                                                        </div>
                                                    </div>
                                                </label>
                                                {this.state.kortingsVoucherSelectedMobile ? (<div className='wrapper-select-new'>
                                                    <select
                                                        value={this.state.kortingsVoucherImageTypeMobile}
                                                        onChange={(e) => {
                                                            this.handleValuesKortingsVoucher()

                                                            this.setState({
                                                                kortingsVoucherImageTypeMobile: e.target.value,
                                                            });
                                                        }}
                                                    >
                                                        <option value="uploadfromcomp">Eigen foto</option>
                                                        <option value="takefromlibrary">
                                                            Bibliotheek
                                                        </option>
                                                    </select>
                                                    <ExpandMoreIcon
                                                        style={{
                                                            cursor: "pointer",
                                                            position: "absolute",
                                                            right: 5,
                                                            bottom: 10,
                                                            fontSize: 18,
                                                            pointerEvents: "none",
                                                            pointerEvents: "none",
                                                        }}
                                                        className='expand-more'
                                                    />
                                                </div>) : (<div className='wrapper-select-new'>
                                                    <select
                                                        value={this.state.kortingsVoucherImageType}
                                                        onChange={(e) => {
                                                            this.handleValuesKortingsVoucher()

                                                            this.setState({
                                                                kortingsVoucherImageType: e.target.value,
                                                            });
                                                        }}
                                                    >
                                                        <option value="uploadfromcomp">Eigen foto</option>
                                                        <option value="takefromlibrary">
                                                            Bibliotheek
                                                        </option>
                                                    </select>
                                                    <ExpandMoreIcon
                                                        style={{
                                                            cursor: "pointer",
                                                            position: "absolute",
                                                            right: 5,
                                                            bottom: 10,
                                                            fontSize: 18,
                                                            pointerEvents: "none",
                                                            pointerEvents: "none",
                                                        }}
                                                        className='expand-more'
                                                    />
                                                </div>)}
                                            </div>
                                            {this.state.kortingsVoucherSelectedMobile ? (<div
                                                className="form-miur"
                                                style={{ position: "relative" }}
                                            >
                                                <div className='wrapper-select-new'>
                                                    {this.state.kortingsVoucherImageTypeMobile ===
                                                        "uploadfromcomp" ? (
                                                        <input
                                                            type="text"
                                                            value={this.state.kortingsVoucherImageMobile[0] !==
                                                                "https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/placeholder.jpg?alt=media&token=3146a89c-5b95-4a6b-b60f-fb6b0f166bdb" ? "Geselecteerd" : "Upload foto"}
                                                            // value="Upload foto"
                                                            readOnly
                                                            // disabled
                                                            style={{
                                                                fontSize: 13,
                                                                letterSpacing: 1,
                                                                backgroundColor: "white",
                                                            }}
                                                            className="dolabelsuppercase inputplaceholderbold"
                                                        />
                                                    ) : (
                                                        <input
                                                            type="text"
                                                            value="Kies foto"
                                                            readOnly
                                                            // disabled
                                                            style={{
                                                                fontSize: 13,
                                                                letterSpacing: 1,
                                                                backgroundColor: "white",
                                                            }}
                                                            className="dolabelsuppercase"
                                                        />
                                                    )}
                                                    {this.state.kortingsVoucherImageTypeMobile ===
                                                        "uploadfromcomp" && (
                                                            <input
                                                                type="file"
                                                                onChange={(e) => this.uploadImgKortingsvoucher(e)}
                                                                accept="image/*"
                                                                style={{
                                                                    position: "absolute",
                                                                    width: "100%",
                                                                    height: "80%",
                                                                    top: 0,
                                                                    left: 0,
                                                                    right: 0,
                                                                    bottom: 0,
                                                                    opacity: 0,
                                                                }}
                                                            />
                                                        )}
                                                    {this.state.kortingsVoucherImageTypeMobile ===
                                                        "takefromlibrary" && (
                                                            <div
                                                                style={{
                                                                    position: "absolute",
                                                                    width: "100%",
                                                                    height: 40,
                                                                    top: 0,
                                                                    cursor: "pointer",
                                                                    left: 0,
                                                                    right: 0,
                                                                    bottom: 0,
                                                                    opacity: 0,
                                                                }}
                                                                onClick={() => {
                                                                    this.handleValuesKortingsVoucher()

                                                                    this.setState({
                                                                        addStockImage: true,
                                                                    });
                                                                }}
                                                            ></div>
                                                        )}
                                                    <PublishIcon
                                                        style={{
                                                            cursor: "pointer",
                                                            position: "absolute",
                                                            right: 5,
                                                            bottom: this.state.kortingsVoucherImageAlert ? 37 : 10,
                                                            fontSize: 18,
                                                            pointerEvents:
                                                                this.state.kortingsVoucherImageTypeMobile ===
                                                                "uploadfromcomp" && "none",
                                                        }}
                                                        onClick={() => {
                                                            this.handleValuesKortingsVoucher()

                                                            this.setState({
                                                                addStockImage: true,
                                                            });
                                                        }}
                                                        className='expand-more'
                                                    />
                                                </div>
                                                {this.state.kortingsVoucherImageAlert && (
                                                    <label style={{ color: "red" }}>Verplicht veld</label>
                                                )}
                                            </div>) : (<div
                                                className="form-miur"
                                                style={{ position: "relative" }}
                                            >
                                                <div className='wrapper-select-new'>
                                                    {this.state.kortingsVoucherImageType ===
                                                        "uploadfromcomp" ? (
                                                        <input
                                                            type="text"
                                                            value={this.state.kortingsVoucherImage[0] !==
                                                                "https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/placeholder.jpg?alt=media&token=3146a89c-5b95-4a6b-b60f-fb6b0f166bdb" ? "Geselecteerd" : "Upload foto"}
                                                            // value="Upload foto"
                                                            readOnly
                                                            // disabled
                                                            style={{
                                                                fontSize: 13,
                                                                letterSpacing: 1,
                                                                backgroundColor: "white",
                                                            }}
                                                            className="dolabelsuppercase inputplaceholderbold"
                                                        />
                                                    ) : (
                                                        <input
                                                            type="text"
                                                            value="Kies foto"
                                                            readOnly
                                                            // disabled
                                                            style={{
                                                                fontSize: 13,
                                                                letterSpacing: 1,
                                                                backgroundColor: "white",
                                                            }}
                                                            className="dolabelsuppercase"
                                                        />
                                                    )}
                                                    {this.state.kortingsVoucherImageType ===
                                                        "uploadfromcomp" && (
                                                            <input
                                                                type="file"
                                                                onChange={(e) => this.uploadImgKortingsvoucher(e)}
                                                                accept="image/*"
                                                                style={{
                                                                    position: "absolute",
                                                                    width: "100%",
                                                                    height: "80%",
                                                                    top: 0,
                                                                    left: 0,
                                                                    right: 0,
                                                                    bottom: 0,
                                                                    opacity: 0,
                                                                }}
                                                            />
                                                        )}
                                                    {this.state.kortingsVoucherImageType ===
                                                        "takefromlibrary" && (
                                                            <div
                                                                style={{
                                                                    position: "absolute",
                                                                    width: "100%",
                                                                    height: 40,
                                                                    top: 0,
                                                                    cursor: "pointer",
                                                                    left: 0,
                                                                    right: 0,
                                                                    bottom: 0,
                                                                    opacity: 0,
                                                                }}
                                                                onClick={() => {
                                                                    this.handleValuesKortingsVoucher()

                                                                    this.setState({
                                                                        addStockImage: true,
                                                                    });
                                                                }}
                                                            ></div>
                                                        )}
                                                    <PublishIcon
                                                        style={{
                                                            cursor: "pointer",
                                                            position: "absolute",
                                                            right: 5,
                                                            bottom: this.state.kortingsVoucherImageAlert ? 37 : 10,
                                                            fontSize: 18,
                                                            pointerEvents:
                                                                this.state.kortingsVoucherImageType ===
                                                                "uploadfromcomp" && "none",
                                                        }}
                                                        onClick={() => {
                                                            this.handleValuesKortingsVoucher()

                                                            this.setState({
                                                                addStockImage: true,
                                                            });
                                                        }}
                                                        className='expand-more'
                                                    />
                                                </div>
                                                {this.state.kortingsVoucherImageAlert && (
                                                    <label style={{ color: "red" }}>Verplicht veld</label>
                                                )}
                                            </div>)}
                                            {this.state.kortingsVoucherSelectedMobile && (this.state.kortingsVoucherImageMobile[0] !== "https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/placeholder.jpg?alt=media&token=3146a89c-5b95-4a6b-b60f-fb6b0f166bdb" && (
                                                <div className='form-miur'>
                                                    <div className='image-wrappwr-fwcoco imagewithnewdesign'>
                                                        <input />
                                                        <div className="image-campaigns-new icn-newupdatedoneline">
                                                            <div
                                                                className="image-sfcplp"
                                                                style={{
                                                                    backgroundImage: `url("${this.state.kortingsVoucherImageMobile[0]}")`,
                                                                }}
                                                            >
                                                            </div>
                                                            {this.checkForDeleteKortingsVoucher()}
                                                        </div>
                                                    </div>
                                                </div>))}
                                            {!this.state.kortingsVoucherSelectedMobile && (this.state.kortingsVoucherImage[0] !== "https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/placeholder.jpg?alt=media&token=3146a89c-5b95-4a6b-b60f-fb6b0f166bdb" && (
                                                <div className='form-miur'>
                                                    <div className='image-wrappwr-fwcoco imagewithnewdesign'>
                                                        <input />
                                                        <div className="image-campaigns-new icn-newupdatedoneline">
                                                            <div
                                                                className="image-sfcplp"
                                                                style={{
                                                                    backgroundImage: `url("${this.state.kortingsVoucherImage[0]}")`,
                                                                }}
                                                            >
                                                            </div>
                                                            {this.checkForDeleteKortingsVoucher()}
                                                        </div>
                                                    </div>
                                                    {/* <div className="image-campaigns-new">
                                                    <div
                                                        className="image-sfcplp"
                                                        style={{
                                                            backgroundImage: `url("${this.state.kortingsVoucherImage[0]}")`,
                                                        }}
                                                    >
                                                    </div>
                                                    {this.checkForDeleteKortingsVoucher()} */}
                                                </div>))}
                                            <div
                                                className="form-miur formfullwidthftcic"
                                                style={{ position: "relative" }}
                                            >
                                                <label>
                                                    Categorieën
                                                </label>
                                                <div className='wrapper-select-new'>
                                                    {this.state.selectedMain.length > 0 ? (
                                                        <input
                                                            type="text"
                                                            value={this.getSelectedCategoryName()}
                                                            disabled
                                                            style={{
                                                                fontSize: 13,
                                                                letterSpacing: 1,
                                                                backgroundColor: "white",
                                                                paddingRight: 40,
                                                            }}
                                                        />
                                                    ) : (
                                                        <input
                                                            type="text"
                                                            value=""
                                                            disabled
                                                            style={{
                                                                fontSize: 13,
                                                                letterSpacing: 1,
                                                                backgroundColor: "white",
                                                            }}
                                                        />
                                                    )}
                                                    {this.state.selectedMain.length > 0 ? (
                                                        <CheckCircleIcon
                                                            style={{
                                                                cursor: "pointer",
                                                                position: "absolute",
                                                                right: 5,
                                                                bottom: 0,
                                                                fontSize: 18,
                                                            }}
                                                            onClick={() =>
                                                                this.setState({
                                                                    addCategory: true,
                                                                    categorySelected: true,
                                                                })
                                                            }
                                                            className='expand-more'
                                                        />
                                                    ) : (
                                                        <AddCircleIcon
                                                            style={{
                                                                cursor: "pointer",
                                                                position: "absolute",
                                                                right: 5,
                                                                bottom: 0,
                                                                fontSize: 18,
                                                            }}
                                                            onClick={() =>
                                                                this.setState({
                                                                    addCategory: true,
                                                                    categorySelected: true,
                                                                })
                                                            }
                                                            className='expand-more'
                                                        />
                                                    )}
                                                    <div
                                                        style={{
                                                            position: "absolute",
                                                            width: "100%",
                                                            height: 40,
                                                            top: 0,
                                                            cursor: "pointer",
                                                            left: 0,
                                                            right: 0,
                                                            bottom: 0,
                                                            opacity: 0,
                                                        }}
                                                        onClick={() => {
                                                            this.handleValuesKortingsVoucher()

                                                            this.setState({
                                                                addCategory: true,
                                                                categorySelected: true,
                                                            });
                                                        }}
                                                    ></div>
                                                </div>
                                                {this.state.noCategorySelectedKortingsVoucher && (
                                                    <label style={{ color: "red" }}>Verplicht veld</label>
                                                )}
                                            </div>
                                            <div className="row-form-miur">
                                                <div
                                                    className="form-miur"
                                                    style={{ position: "relative" }}
                                                >
                                                    <label>Soort korting</label>
                                                    <div className='wrapper-select-new'>
                                                        <select
                                                            value={
                                                                this.state.kortingsVoucherGiveaway ? "giveaway" : this.state.kortingsVoucherPercentage ? "per" : this.state.kortingsVoucherPrijs ? "price" : "sale_price"
                                                            }
                                                            onChange={(e) => {
                                                                this.handleValuesKortingsVoucher()

                                                                if (e.target.value === "giveaway") {
                                                                    this.setState({
                                                                        kortingsVoucherPrijs: false,
                                                                        kortingsVoucherAmount: "Free",
                                                                        kortingsVoucherGiveaway: true,
                                                                        kortingsVoucherPercentage: false,
                                                                        kortingsVoucherSalePrice: "",
                                                                        kortingsVoucherNormalPrice: "",
                                                                        timerTimeWaitingEndTime: "",
                                                                        timerDateWaitingEndTime: "",
                                                                    });
                                                                } else if (e.target.value === "price") {
                                                                    this.setState({
                                                                        kortingsVoucherPrijs: true,
                                                                        kortingsVoucherAmount: "",
                                                                        kortingsVoucherPercentage: false,
                                                                        kortingsVoucherGiveaway: false,
                                                                        kortingsVoucherSalePrice: "",
                                                                        kortingsVoucherNormalPrice: "",
                                                                    });
                                                                } else if (e.target.value === "per") {
                                                                    this.setState({
                                                                        kortingsVoucherGiveaway: false,
                                                                        kortingsVoucherPercentage: true,
                                                                        kortingsVoucherPrijs: false,
                                                                        kortingsVoucherAmount: "",
                                                                        kortingsVoucherSalePrice: "",
                                                                        kortingsVoucherNormalPrice: "",
                                                                    });
                                                                } else {
                                                                    this.setState({
                                                                        kortingsVoucherPrijs: false,
                                                                        kortingsVoucherGiveaway: false,
                                                                        kortingsVoucherPercentage: false,
                                                                        kortingsVoucherAmount: "",
                                                                    });
                                                                }
                                                            }}
                                                        >
                                                            <option value="price">Bedrag</option>
                                                            <option value="per">Percentage</option>
                                                            <option value="sale_price">Actieprijs</option>
                                                            <option value="giveaway">Giveaway</option>
                                                        </select>
                                                        <ExpandMoreIcon
                                                            style={{
                                                                cursor: "pointer",
                                                                position: "absolute",
                                                                right: 5,
                                                                bottom: 10,
                                                                fontSize: 18,
                                                                pointerEvents: "none",
                                                                pointerEvents: "none",
                                                            }}
                                                            className='expand-more'
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    className="form-miur kortingnumberdiv"
                                                    style={{ position: "relative" }}
                                                >
                                                    {!this.state.kortingsVoucherGiveaway && (<span
                                                        style={{
                                                            position: "absolute",
                                                            left: this.state.kortingsVoucherPercentage ? 48 : 8,
                                                            // left: 8,
                                                            color: "#8c8c8c",
                                                            fontSize: 13,
                                                            top: 45,
                                                            zIndex: 99999,
                                                        }}
                                                    >
                                                        {this.state.kortingsVoucherPercentage ? "%" : "€"}
                                                        {/* € */}
                                                    </span>)}
                                                    {(!this.state.kortingsVoucherPrijs && !this.state.kortingsVoucherPercentage && !this.state.kortingsVoucherGiveaway) && (<span
                                                        style={{
                                                            position: "absolute",
                                                            left: 93,
                                                            color: "#8c8c8c",
                                                            fontSize: 13,
                                                            top: 45,
                                                            zIndex: 99999,
                                                        }}
                                                    >
                                                        €
                                                    </span>)}
                                                    {(this.state.kortingsVoucherPrijs || this.state.kortingsVoucherPercentage || this.state.kortingsVoucherGiveaway) && (<label>&nbsp;</label>)}
                                                    {(this.state.kortingsVoucherGiveaway) ? (
                                                        <input
                                                            type="text"
                                                            defaultValue={this.state.kortingsVoucherAmount}
                                                            id="kortingsVoucherAmount"
                                                            readOnly={true}
                                                        />
                                                    ) : (this.state.kortingsVoucherPrijs || this.state.kortingsVoucherPercentage) ? (
                                                        <input
                                                            type="number"
                                                            defaultValue={this.state.kortingsVoucherAmount}
                                                            id="kortingsVoucherAmount"
                                                            onChange={(e) => {
                                                                if (Number(e.target.value) > 100 && this.state.kortingsVoucherPercentage) {
                                                                    document.getElementById("kortingsVoucherAmount").value = 100;
                                                                }
                                                            }}
                                                        />
                                                    ) : (
                                                        <div className="input-double-prices">
                                                            <div className="idp-inner-main">
                                                                <label>Van</label>
                                                                <input
                                                                    type="number"
                                                                    defaultValue={this.state.kortingsVoucherNormalPrice}
                                                                    id="kortingsVoucherNormalPrice"
                                                                />
                                                            </div>
                                                            <div className="idp-inner-main">
                                                                <label>Voor</label>
                                                                <input
                                                                    type="number"
                                                                    defaultValue={this.state.kortingsVoucherSalePrice}
                                                                    id="kortingsVoucherSalePrice"
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                    {this.state.kortingsVoucherAmountAlert && (
                                                        <label style={{ color: "red" }}>Verplicht veld</label>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="savebtnwork" style={{ marginTop: window.innerWidth > 1008 && -15 }}>
                                            {(!this.state.kortingsVoucherPrijs && !this.state.kortingsVoucherPercentage && !this.state.kortingsVoucherGiveaway) && (<div className="form-inner-for-datetime">
                                                <label>Einddatum uitverkoop </label>
                                                <div className="inner-top-main" style={{ marginTop: 15 }}>
                                                    <div className="right-fifd">
                                                        <div className="datinput-fifd">
                                                            <BiCalendar />
                                                            {this.state.timerDateWaitingEndTime ? (
                                                                <label>
                                                                    {this.getFormattedDate(
                                                                        this.state.timerDateWaitingEndTime
                                                                    )}
                                                                </label>
                                                            ) : (
                                                                <label>Datum</label>
                                                            )}
                                                            <input
                                                                type="date"
                                                                min={this.state.makeTodayTimer}
                                                                value={this.state.timerDateWaitingEndTime}
                                                                onChange={(e) => {
                                                                    this.handleValuesKortingsVoucher()

                                                                    this.setState({
                                                                        timerDateWaitingEndTime: e.target.value,
                                                                    });
                                                                    let timerDate = new Date(e.target.value)
                                                                    let currentDate = new Date()
                                                                    if (timerDate.getMonth() === currentDate.getMonth() && timerDate.getDate() === currentDate.getDate() && timerDate.getFullYear() === currentDate.getFullYear()) {
                                                                        this.setState({ timerTimeWaitingEndTime: "" })
                                                                    }
                                                                }}
                                                            />
                                                            {/* <ExpandMoreIcon /> */}
                                                        </div>
                                                        <div className="select-fifd">
                                                            <MdTimer />
                                                            <select
                                                                value={this.state.timerTimeWaitingEndTime}
                                                                onChange={(e) => {
                                                                    this.handleValuesKortingsVoucher()

                                                                    this.setState({
                                                                        timerTimeWaitingEndTime: e.target.value,
                                                                    });
                                                                }}
                                                            >
                                                                <option disabled selected hidden value="">
                                                                    Tijd
                                                                </option>
                                                                {this.state.allTimes.map((y) => {
                                                                    let mustRestrictHours = false;
                                                                    let minHour = 0;
                                                                    let minMinutes = 0;
                                                                    if (this.state.timerDateWaitingEndTime) {
                                                                        let timerDate = new Date(this.state.timerDateWaitingEndTime)
                                                                        let currentDate = new Date()
                                                                        if (timerDate.getMonth() === currentDate.getMonth() && timerDate.getDate() === currentDate.getDate() && timerDate.getFullYear() === currentDate.getFullYear()) {
                                                                            minHour = currentDate.getHours()
                                                                            minMinutes = currentDate.getMinutes()
                                                                            mustRestrictHours = true
                                                                        } else {
                                                                            minHour = 0
                                                                            minMinutes = 0
                                                                            mustRestrictHours = false
                                                                        }
                                                                    }
                                                                    if (mustRestrictHours) {
                                                                        if (Number(y.label.split(":")[0]) >= minHour) {
                                                                            if (Number(y.label.split(":")[0]) === minHour) {
                                                                                if (Number(y.label.split(":")[1] > minMinutes)) {
                                                                                    return (
                                                                                        <option value={y.label}>{y.label}</option>
                                                                                    );
                                                                                } else if (y.label == this.state.timerTimeWaitingEndTime) {
                                                                                    return (
                                                                                        <option value={y.label}>{y.label}</option>
                                                                                    );
                                                                                }
                                                                            } else {
                                                                                return (
                                                                                    <option value={y.label}>{y.label}</option>
                                                                                );
                                                                            }
                                                                        } else if (y.label == this.state.timerTimeWaitingEndTime) {
                                                                            return (
                                                                                <option value={y.label}>{y.label}</option>
                                                                            );
                                                                        }
                                                                    } else {
                                                                        return (
                                                                            <option value={y.label}>{y.label}</option>
                                                                        );
                                                                    }
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>)}
                                            <div
                                                className="form-miur formfullwidthftcic"
                                                style={{ position: "relative" }}
                                            >
                                                <label>
                                                    Voorraad
                                                </label>
                                            </div>
                                            <button>
                                                <Switch
                                                    color="primary"
                                                    inputProps={{ "aria-label": "primary checkbox" }}
                                                    checked={this.state.kortingsVoucherUnlimited}
                                                    onClick={() => {
                                                        this.handleValuesKortingsVoucher()

                                                        this.setState({
                                                            kortingsVoucherUnlimited: !this.state.kortingsVoucherUnlimited,
                                                        });
                                                    }}
                                                />
                                                {this.state.kortingsVoucherUnlimited ? (
                                                    <label>Onbeperkt</label>
                                                ) : (
                                                    <label>Beperkt</label>
                                                )}
                                            </button>
                                            {!this.state.kortingsVoucherUnlimited && (
                                                <div
                                                    className="form-miur formmiurnumbersinputaantal"
                                                    style={{
                                                        padding: "0px 20px",
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <input
                                                        type="number"
                                                        defaultValue={this.state.kortingsVoucherNumber}
                                                        id="kortingsVoucherNumber"
                                                    />
                                                    <label style={{ color: "black" }}>Aantal</label>
                                                </div>
                                            )}
                                            <div
                                                className="form-miur formfullwidthftcic"
                                                style={{ position: "relative" }}
                                            >
                                                <label>
                                                    Verberg op globale pagina
                                                </label>
                                            </div>
                                            <button>
                                                <Switch
                                                    color="primary"
                                                    inputProps={{ "aria-label": "primary checkbox" }}
                                                    checked={this.state.hideOnGlobalVoucher}
                                                    onClick={() => {
                                                        this.handleValuesKortingsVoucher()

                                                        this.setState({
                                                            hideOnGlobalVoucher: !this.state.hideOnGlobalVoucher,
                                                        });
                                                    }}
                                                />
                                                {this.state.hideOnGlobalVoucher ? (
                                                    <label>Hidden</label>
                                                ) : (
                                                    <label>Showing</label>
                                                )}
                                            </button>

                                            <div
                                                className="form-miur formfullwidthftcic"
                                                style={{ position: "relative" }}
                                            >
                                                <label>
                                                    Direct te bestellen
                                                </label>
                                            </div>
                                            <button>
                                                <Switch
                                                    color="primary"
                                                    inputProps={{ "aria-label": "primary checkbox" }}
                                                    checked={this.state.showTimerToGuests}
                                                    onClick={() => {
                                                        this.handleValuesKortingsVoucher()

                                                        this.setState({
                                                            showTimerToGuests: !this.state.showTimerToGuests,
                                                        });
                                                    }}
                                                />
                                                {this.state.showTimerToGuests ? (
                                                    <label>On</label>
                                                ) : (
                                                    <label>Off</label>
                                                )}
                                            </button>

                                            <div
                                                className="form-miur formfullwidthftcic"
                                                style={{ position: "relative" }}
                                            >
                                                <label>
                                                    Actie status
                                                </label>
                                            </div>
                                            <button>
                                                <Switch
                                                    color="primary"
                                                    inputProps={{ "aria-label": "primary checkbox" }}
                                                    checked={this.state.currentOpenedCheck}
                                                    onClick={() => {
                                                        this.handleValuesKortingsVoucher()

                                                        this.setState({
                                                            currentOpenedCheck: !this.state.currentOpenedCheck,
                                                        });
                                                    }}
                                                />
                                                {this.state.currentOpenedCheck ? (
                                                    <label>Actief</label>
                                                ) : (
                                                    <label>Niet Actief</label>
                                                )}
                                            </button>
                                            <div className="form-inner-for-datetime">
                                                <label>Startdatum </label>
                                                <div className="inner-top-main" style={{ marginTop: 15 }}>
                                                    <div className="right-fifd">
                                                        <div className="datinput-fifd">
                                                            <BiCalendar />
                                                            {this.state.timerDateWaiting ? (
                                                                <label>
                                                                    {this.getFormattedDate(
                                                                        this.state.timerDateWaiting
                                                                    )}
                                                                </label>
                                                            ) : (
                                                                <label>Datum</label>
                                                            )}
                                                            <input
                                                                type="date"
                                                                min={this.state.makeTodayTimer}
                                                                value={this.state.timerDateWaiting}
                                                                onChange={(e) => {
                                                                    this.handleValuesKortingsVoucher()

                                                                    this.setState({
                                                                        timerDateWaiting: e.target.value,
                                                                    });
                                                                    let timerDate = new Date(e.target.value)
                                                                    let currentDate = new Date()
                                                                    if (timerDate.getMonth() === currentDate.getMonth() && timerDate.getDate() === currentDate.getDate() && timerDate.getFullYear() === currentDate.getFullYear()) {
                                                                        this.setState({ timerTimeWaiting: "" })
                                                                    }
                                                                }}
                                                            />
                                                            {/* <ExpandMoreIcon /> */}
                                                        </div>
                                                        <div className="select-fifd">
                                                            <MdTimer />
                                                            <select
                                                                value={this.state.timerTimeWaiting}
                                                                onChange={(e) => {
                                                                    this.handleValuesKortingsVoucher()

                                                                    this.setState({
                                                                        timerTimeWaiting: e.target.value,
                                                                    });
                                                                }}
                                                            >
                                                                <option disabled selected hidden value="">
                                                                    Tijd
                                                                </option>
                                                                {this.state.allTimes.map((y) => {
                                                                    let mustRestrictHours = false;
                                                                    let minHour = 0;
                                                                    let minMinutes = 0;
                                                                    if (this.state.timerDateWaiting) {
                                                                        let timerDate = new Date(this.state.timerDateWaiting)
                                                                        let currentDate = new Date()
                                                                        if (timerDate.getMonth() === currentDate.getMonth() && timerDate.getDate() === currentDate.getDate() && timerDate.getFullYear() === currentDate.getFullYear()) {
                                                                            minHour = currentDate.getHours()
                                                                            minMinutes = currentDate.getMinutes()
                                                                            mustRestrictHours = true
                                                                        } else {
                                                                            minHour = 0
                                                                            minMinutes = 0
                                                                            mustRestrictHours = false
                                                                        }
                                                                    }
                                                                    if (mustRestrictHours) {
                                                                        if (Number(y.label.split(":")[0]) >= minHour) {
                                                                            if (Number(y.label.split(":")[0]) === minHour) {
                                                                                if (Number(y.label.split(":")[1] > minMinutes)) {
                                                                                    return (
                                                                                        <option value={y.label}>{y.label}</option>
                                                                                    );
                                                                                }
                                                                            } else {
                                                                                return (
                                                                                    <option value={y.label}>{y.label}</option>
                                                                                );
                                                                            }
                                                                        }
                                                                    } else {
                                                                        return (
                                                                            <option value={y.label}>{y.label}</option>
                                                                        );
                                                                    }
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {this.state.kortingsVoucherTimerAlert && (
                                                <label style={{ color: "red", width: "100%", fontSize: 13, letterSpacing: 1 }}>Verplicht veld</label>
                                            )}
                                            {this.state.companyCurrentVoucher ? (
                                                <button
                                                    className="savebtnworksave"
                                                    onClick={() => {
                                                        let kortingsVoucherNumber = document.getElementById("kortingsVoucherNumber");
                                                        if (kortingsVoucherNumber) {
                                                            kortingsVoucherNumber = kortingsVoucherNumber.value;
                                                            this.setState({ kortingsVoucherNumber });
                                                        } else {
                                                            kortingsVoucherNumber = 1;
                                                        }

                                                        this.handleValuesKortingsVoucher()


                                                        if (
                                                            Number(kortingsVoucherNumber) ===
                                                            Number(this.state.editKortingsVoucherNum)
                                                        ) {
                                                            this.saveCampaignKortingsVoucher();
                                                        } else {
                                                            this.setState({
                                                                editKortingsVoucher: true,
                                                                kortingsVoucherNumber,
                                                            });
                                                        }
                                                    }}
                                                >
                                                    OPSLAAN
                                                </button>
                                            ) : (
                                                <button
                                                    className="savebtnworksave"
                                                    onClick={this.saveCampaignKortingsVoucher.bind(this)}
                                                >
                                                    OPSLAAN
                                                </button>
                                            )}
                                            {this.state.companyCurrentVoucher && (
                                                <button
                                                    className="savebtnworksave pinkcolorbuttondesign"
                                                    onClick={() => {
                                                        this.setState({ deleteKortingsVoucher: true });
                                                    }}
                                                >
                                                    VERWIJDER
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}


                {/* local event */}
                {this.state.openAddPopupWebShop && (
                    <div
                        className="overlay-div-openadd"
                        onClick={() => {
                            this.props.closePopup("redirect-voucher")
                            // this.setState({
                            //     openAddPopupKortingsvoucher: false,
                            // });
                        }}
                    ></div>
                )}
                {this.state.openAddPopupWebShop && (
                    <div className="separate-page-ce new-campaigns-design campaigns-design-final new-campaigns-design-final">
                        <div
                            className="rightmenuaddproduct rightmenucompletewidthaddproduct"
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            {this.state.loaderShow && (
                                <div className="loader-area" style={{ backgroundImage: "none" }}>
                                    <CloseIcon onClick={() => window.location.reload()} />
                                    <div className="lds-ring">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                    <p style={{ color: "white" }}>Saving Campaign</p>
                                </div>
                            )}
                            {this.state.showingLoaderCreateEdit && (
                                <div className="loader-area" style={{ backgroundImage: "none" }}>
                                    <div className="lds-ring">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                    <p style={{ color: "white" }}>Loading</p>
                                </div>
                            )}
                            {this.state.addCategory && <div className="overlay-rmadfofw"></div>}
                            {this.state.addCategory && this.categoryPopupRender()}
                            {/* {this.state.addCategory && (
                                <div className="rightmenuaddproduct rightmenuaddcategoryformfw rightmenucategorynew newcategoriesrightmenu">
                                    <div
                                        className="mid-rmap"
                                        style={{ position: "relative", padding: 13 }}
                                    >
                                        <span>SELECTEER CATEGORIE</span>
                                        <div className="top-header-infocont">
                                            <div style={{ position: "relative" }}>
                                                <div className='green-round-info' style={{ cursor: "pointer" }} onClick={() => {
                                                    this.setState({ categoryInfoOpen: !this.state.categoryInfoOpen })
                                                }}>
                                                    <font>?</font>
                                                </div>
                                                <div className="popup-gri-thifc" style={{ opacity: this.state.categoryInfoOpen ? 1 : 0, pointerEvents: this.state.categoryInfoOpen ? "all" : "none" }}>
                                                    <CloseIcon onClick={() => this.setState({ categoryInfoOpen: false })} />
                                                    <label style={{ fontSize: 15 }}>
                                                        Selecteer de juiste categorie(ën)
                                                        <br />
                                                        waaronder je actie wordt getoond.
                                                    </label>
                                                </div>
                                            </div>
                                            <ChevronLeft
                                                style={{
                                                    cursor: "pointer",
                                                    position: "absolute",
                                                    top: "50%",
                                                    transform: "translateY(-50%)",
                                                    left: 20,
                                                    fontSize: 18,
                                                }}
                                                onClick={() => this.setState({ addCategory: false, categoryInfoOpen: false })}
                                            />
                                        </div>
                                    </div>
                                    <div className="bottom-rmap">
                                        <div className="mid-img-uploading-rmap">
                                            {this.state.all_categories.length > 0 && (
                                                <ul className="checkboxlistselectcategory">
                                                    {this.state.all_categories.map((e) => {
                                                        return (
                                                            <li className="checkboxfieldstypeselection">
                                                                <input
                                                                    type="checkbox"
                                                                    className="input-radio checkboxessortname"
                                                                    value={e.category_id}
                                                                    checked={this.state.selectedMain.includes(
                                                                        e.category_id
                                                                    )}
                                                                    onChange={() => {
                                                                        if (
                                                                            this.state.selectedMain.includes(
                                                                                e.category_id
                                                                            )
                                                                        ) {
                                                                            let indexed =
                                                                                this.state.selectedMain.indexOf(
                                                                                    e.category_id
                                                                                );
                                                                            this.state.selectedMain.splice(indexed, 1);
                                                                            this.setState({
                                                                                selectedMain: this.state.selectedMain,
                                                                            });
                                                                        } else {
                                                                            this.state.selectedMain.push(e.category_id);
                                                                            this.setState({
                                                                                selectedMain: this.state.selectedMain,
                                                                            });
                                                                        }
                                                                    }}
                                                                />
                                                                <p>{e.title}</p>
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )} */}
                            {this.state.addStockImage && (
                                <div className="overlay-rmadfofw"></div>
                            )}
                            {this.state.addStockImage && (
                                <div className="rightmenuaddproduct rightmenuaddcategoryformfw rightmenuaddproductstockselection rightmenuproductstockselectionupdated updatedrightmenustock">
                                    <div
                                        className="mid-rmap"
                                        style={{ position: "relative", padding: 13 }}
                                    >
                                        <span>SELECTEER AFBEELDING</span>
                                        <ChevronLeft
                                            style={{
                                                cursor: "pointer",
                                                position: "absolute",
                                                top: "50%",
                                                fontSize: 18,
                                                transform: "translateY(-50%)",
                                                left: 20,
                                            }}
                                            onClick={() => this.setState({ addStockImage: false })}
                                        />
                                    </div>
                                    <div className="bottom-rmap">
                                        <div className="mid-img-uploading-rmap">
                                            <div
                                                className="form-miur"
                                                style={{ marginBottom: 30, position: "relative" }}
                                            >
                                                <label>TYPE BEDRIJF</label>
                                                <div className='wrapper-select-new'>
                                                    <select
                                                        defaultValue="Selecteer"
                                                        value={(this.state.voucherSelectedMobile && this.state.mainSectorStockMobile) ? this.state.mainSectorStockMobile : this.state.mainSectorStock}
                                                        // value={this.state.mainSectorStock}
                                                        onChange={(e) => {
                                                            if (e.target.value !== "Selecteer") {
                                                                if (this.state.allImagesStock.length > 0) {
                                                                    let filteredSecond =
                                                                        this.state.allImagesStock.filter((u) => {
                                                                            return u[0] === e.target.value;
                                                                        });
                                                                    if (filteredSecond.length > 0) {
                                                                        let a = filteredSecond[0];
                                                                        let arr = [];
                                                                        if (a.length > 0) {
                                                                            let objected = Object.entries(a[1]);
                                                                            objected.map((y) => {
                                                                                arr.push(y[1]);
                                                                            });
                                                                            this.setState({
                                                                                allImagesCurrentSectorStock: arr,
                                                                            });
                                                                        } else {
                                                                            this.setState({
                                                                                allImagesCurrentSectorStock: [],
                                                                            });
                                                                        }
                                                                    }
                                                                }
                                                                if (this.state.voucherSelectedMobile) {
                                                                    this.setState({ mainSectorStockMobile: e.target.value });
                                                                } else {
                                                                    this.setState({ mainSectorStock: e.target.value });
                                                                }
                                                            }
                                                        }}
                                                    >
                                                        {!this.state.mainSector && <option>Selecteer</option>}
                                                        {this.state.allSectors.map((g) => {
                                                            return (
                                                                <option value={g.sector_id}>{g.sector_name}</option>
                                                            );
                                                        })}
                                                    </select>
                                                    <ExpandMoreIcon
                                                        style={{
                                                            position: "absolute",
                                                            bottom: 5,
                                                            right: 5,
                                                            pointerEvents: "none",
                                                        }}
                                                        className='expand-more'
                                                    />
                                                </div>
                                            </div>
                                            {this.state.allImagesCurrentSectorStock.length > 0 && (
                                                <div className="imagesstockselectionrightmenu">
                                                    {this.state.allImagesCurrentSectorStock.map((y) => {
                                                        return (
                                                            <div
                                                                className="imagecontainerstockuploader"
                                                                style={{
                                                                    backgroundImage: `url("${y.resizedUrl}")`,
                                                                }}
                                                                onClick={() => {
                                                                    if (this.state.voucherSelectedMobile) {
                                                                        this.state.voucherImageMobile = [y.snapUrl];
                                                                        if (window.innerWidth < 1008) {
                                                                            this.setState({ addStockImage: false });
                                                                        }
                                                                        this.setState({
                                                                            voucherImageMobile: this.state.voucherImageMobile,
                                                                            dropFileVoucherMobile: [],
                                                                        });
                                                                        return false;
                                                                    }
                                                                    this.state.voucherImage = [y.snapUrl];
                                                                    if (window.innerWidth < 1008) {
                                                                        this.setState({ addStockImage: false });
                                                                    }
                                                                    this.setState({
                                                                        voucherImage: this.state.voucherImage,
                                                                        dropFileVoucher: [],
                                                                    });
                                                                }}
                                                            >
                                                                {this.state.voucherSelectedMobile && (this.state.voucherImageMobile.length > 0 && this.state.voucherImageMobile[0] === y.snapUrl) && <div className="overlay-icontstup">
                                                                    <CheckCircleIcon />
                                                                </div>}
                                                                {!this.state.voucherSelectedMobile && (this.state.voucherImage.length > 0 && this.state.voucherImage[0] === y.snapUrl) && <div className="overlay-icontstup">
                                                                    <CheckCircleIcon />
                                                                </div>}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="sectiongiveawaycompletepopup">
                                <div className="header-spce" style={{ justifyContent: "center" }}>
                                    <p>COUPON CODE</p>
                                    <div className="top-header-infocont">
                                        <div style={{ position: "relative" }}>
                                            <div className='green-round-info' style={{ cursor: "pointer" }} onClick={() => {
                                                this.setState({ webShopInfoRightMenuOpen: !this.state.webShopInfoRightMenuOpen })
                                            }}>
                                                <font>?</font>
                                            </div>
                                            <div className="popup-gri-thifc" style={{ opacity: this.state.webShopInfoRightMenuOpen ? 1 : 0, pointerEvents: this.state.webShopInfoRightMenuOpen ? "all" : "none" }}>
                                                <CloseIcon onClick={() => this.setState({ webShopInfoRightMenuOpen: false })} />
                                                <label style={{ fontSize: 15 }}>
                                                    {this.state.descInfoWebShop}
                                                </label>
                                            </div>
                                        </div>
                                        <CloseIcon className="arrow-back-svg"
                                            onClick={() => {
                                                this.props.closePopup("redirect-webshop")
                                            }}
                                        />
                                    </div>
                                </div>
                                <div
                                    className="section-first-cplp"
                                    key={Math.random()}
                                    style={{ flexDirection: "row" }}
                                >
                                    <div
                                        className="content-sfcplp"
                                        style={{ position: "relative" }}
                                    >
                                        <div className="formtocreate-icc">
                                            <div className="form-miur formfullwidthftcic">
                                                <label>Titel</label>
                                                <input
                                                    defaultValue={this.state.voucherTitle}
                                                    id="voucherTitle"
                                                    maxLength={65}
                                                // style={{ textTransform: "uppercase" }}
                                                />
                                                {this.state.voucherTitleAlert && (
                                                    <label style={{ color: "red" }}>Verplicht veld</label>
                                                )}
                                            </div>
                                            <div className="form-miur formfullwidthftcic formckeeditorinner">
                                                <label>Omschrijving</label>
                                                <CustomCKEditor
                                                    data={this.state.voucherDesc}
                                                    ref={this.voucherDescRef}
                                                />
                                                {/* <div id="editorKortingsVoucher" data={this.state.kortingsVoucherDesc}></div> */}
                                                {this.state.voucherDescAlert && (
                                                    <label style={{ color: "red" }}>Verplicht veld</label>
                                                )}
                                            </div>
                                            <div
                                                className="form-miur"
                                                style={{ position: "relative" }}
                                            >
                                                <label>Kortingscode toevoegen</label>
                                                <TagsInput
                                                    value={this.state.couponsCreated}
                                                    addOnBlur
                                                    onChange={(e) => {
                                                        this.handleValuesWebShop()

                                                        this.setState({ couponsCreated: e });
                                                    }}
                                                />
                                                {this.state.couponsAlert && (
                                                    <label style={{ color: "red" }}>Verplicht veld</label>
                                                )}
                                            </div>
                                            <div className="form-miur formfullwidthftcic">
                                                <label>Webshop URL</label>
                                                <input
                                                    defaultValue={this.state.voucherWebshopUrl}
                                                    id="voucherWebshopUrl"
                                                    maxLength={65}
                                                // style={{ textTransform: "uppercase" }}
                                                />
                                                {this.state.voucherWebshopUrlAlert && (
                                                    <label style={{ color: "red" }}>Verplicht veld</label>
                                                )}
                                            </div>
                                            <div
                                                className="form-miur"
                                                style={{ position: "relative" }}
                                            >
                                                <label className="dolabelsuppercase">
                                                    <font>Afbeelding</font>
                                                    <div>
                                                        <div className={`round-icons-mobilepics ${!this.state.voucherSelectedMobile && "active"}`} onClick={() => {
                                                            if (this.state.allImagesStock.length > 0) {
                                                                let filteredSecond =
                                                                    this.state.allImagesStock.filter((u) => {
                                                                        return u[0] === this.state.mainSectorStock;
                                                                    });
                                                                if (filteredSecond.length > 0) {
                                                                    let a = filteredSecond[0];
                                                                    let arr = [];
                                                                    if (a.length > 0) {
                                                                        let objected = Object.entries(a[1]);
                                                                        objected.map((y) => {
                                                                            arr.push(y[1]);
                                                                        });
                                                                        this.setState({
                                                                            allImagesCurrentSectorStock: arr,
                                                                        });
                                                                    } else {
                                                                        this.setState({
                                                                            allImagesCurrentSectorStock: [],
                                                                        });
                                                                    }
                                                                }
                                                            }

                                                            this.setState({ voucherSelectedMobile: false })
                                                        }}>
                                                            <GoDeviceDesktop />
                                                        </div>
                                                        {/* <div className="spacing-between-icons"></div> */}
                                                        <div className={`round-icons-mobilepics  ${this.state.voucherSelectedMobile && "active"}`} onClick={() => {
                                                            if (this.state.allImagesStock.length > 0) {
                                                                let filteredSecond =
                                                                    this.state.allImagesStock.filter((u) => {
                                                                        return u[0] === this.state.mainSectorStockMobile;
                                                                    });
                                                                if (filteredSecond.length > 0) {
                                                                    let a = filteredSecond[0];
                                                                    let arr = [];
                                                                    if (a.length > 0) {
                                                                        let objected = Object.entries(a[1]);
                                                                        objected.map((y) => {
                                                                            arr.push(y[1]);
                                                                        });
                                                                        this.setState({
                                                                            allImagesCurrentSectorStock: arr,
                                                                        });
                                                                    } else {
                                                                        this.setState({
                                                                            allImagesCurrentSectorStock: [],
                                                                        });
                                                                    }
                                                                }
                                                            }

                                                            this.setState({ voucherSelectedMobile: true })
                                                        }}>
                                                            <CiMobile3 />
                                                        </div>
                                                    </div>
                                                </label>
                                                {this.state.voucherSelectedMobile ? (<div className='wrapper-select-new'>
                                                    <select
                                                        value={this.state.voucherImageTypeMobile}
                                                        onChange={(e) => {
                                                            this.handleValuesWebShop()

                                                            this.setState({
                                                                voucherImageTypeMobile: e.target.value,
                                                            });
                                                        }}
                                                    >
                                                        <option value="uploadfromcomp">Eigen foto</option>
                                                        <option value="takefromlibrary">
                                                            Bibliotheek
                                                        </option>
                                                        <option value="external_url">
                                                            External
                                                        </option>
                                                    </select>
                                                    <ExpandMoreIcon
                                                        style={{
                                                            cursor: "pointer",
                                                            position: "absolute",
                                                            right: 5,
                                                            bottom: 10,
                                                            fontSize: 18,
                                                            pointerEvents: "none",
                                                            pointerEvents: "none",
                                                        }}
                                                        className='expand-more'
                                                    />
                                                </div>) : (<div className='wrapper-select-new'>
                                                    <select
                                                        value={this.state.voucherImageType}
                                                        onChange={(e) => {
                                                            this.handleValuesWebShop()

                                                            this.setState({
                                                                voucherImageType: e.target.value,
                                                            });
                                                        }}
                                                    >
                                                        <option value="uploadfromcomp">Eigen foto</option>
                                                        <option value="takefromlibrary">
                                                            Bibliotheek
                                                        </option>
                                                        <option value="external_url">
                                                            External
                                                        </option>
                                                    </select>
                                                    <ExpandMoreIcon
                                                        style={{
                                                            cursor: "pointer",
                                                            position: "absolute",
                                                            right: 5,
                                                            bottom: 10,
                                                            fontSize: 18,
                                                            pointerEvents: "none",
                                                            pointerEvents: "none",
                                                        }}
                                                        className='expand-more'
                                                    />
                                                </div>)}
                                            </div>
                                            {this.state.voucherSelectedMobile ? (<div
                                                className="form-miur"
                                                style={{ position: "relative" }}
                                            >
                                                <div className='wrapper-select-new'>
                                                    {this.state.voucherImageTypeMobile === "external_url" ? (
                                                        <input
                                                            type="text"
                                                            defaultValue={this.state.voucherImageMobile[0] !== "https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/placeholder.jpg?alt=media&token=3146a89c-5b95-4a6b-b60f-fb6b0f166bdb" ? this.state.voucherImage[0] : ""}
                                                            onBlur={(e) => {
                                                                let url = e.target.value

                                                                const img = new Image();
                                                                img.onload = () => {
                                                                    this.setState({ voucherImageMobile: [url] })
                                                                    // Image is valid, you can use the URL or perform additional checks here
                                                                };
                                                                img.onerror = () => {
                                                                    this.setState({
                                                                        voucherImageMobile: [
                                                                            "https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/placeholder.jpg?alt=media&token=3146a89c-5b95-4a6b-b60f-fb6b0f166bdb",
                                                                        ]
                                                                    })
                                                                };

                                                                img.src = url;

                                                            }}
                                                            style={{
                                                                fontSize: 13,
                                                                letterSpacing: 1,
                                                                backgroundColor: "white",
                                                            }}
                                                            className="dolabelsuppercase inputplaceholderbold"
                                                        />
                                                    ) : this.state.voucherImageTypeMobile ===
                                                        "uploadfromcomp" ? (
                                                        <input
                                                            type="text"
                                                            value={this.state.voucherImageMobile[0] !==
                                                                "https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/placeholder.jpg?alt=media&token=3146a89c-5b95-4a6b-b60f-fb6b0f166bdb" ? "Geselecteerd" : "Upload foto"}
                                                            // value="Upload foto"
                                                            readOnly
                                                            // disabled
                                                            style={{
                                                                fontSize: 13,
                                                                letterSpacing: 1,
                                                                backgroundColor: "white",
                                                            }}
                                                            className="dolabelsuppercase inputplaceholderbold"
                                                        />
                                                    ) : (
                                                        <input
                                                            type="text"
                                                            value="Kies foto"
                                                            readOnly
                                                            // disabled
                                                            style={{
                                                                fontSize: 13,
                                                                letterSpacing: 1,
                                                                backgroundColor: "white",
                                                            }}
                                                            className="dolabelsuppercase"
                                                        />
                                                    )}
                                                    {this.state.voucherImageTypeMobile ===
                                                        "uploadfromcomp" && (
                                                            <input
                                                                type="file"
                                                                onChange={(e) => this.uploadImgVoucher(e)}
                                                                accept="image/*"
                                                                style={{
                                                                    position: "absolute",
                                                                    width: "100%",
                                                                    height: "80%",
                                                                    top: 0,
                                                                    left: 0,
                                                                    right: 0,
                                                                    bottom: 0,
                                                                    opacity: 0,
                                                                }}
                                                            />
                                                        )}
                                                    {this.state.voucherImageTypeMobile ===
                                                        "takefromlibrary" && (
                                                            <div
                                                                style={{
                                                                    position: "absolute",
                                                                    width: "100%",
                                                                    height: 40,
                                                                    top: 0,
                                                                    cursor: "pointer",
                                                                    left: 0,
                                                                    right: 0,
                                                                    bottom: 0,
                                                                    opacity: 0,
                                                                }}
                                                                onClick={() => {
                                                                    this.handleValuesWebShop()

                                                                    this.setState({
                                                                        addStockImage: true,
                                                                    });
                                                                }}
                                                            ></div>
                                                        )}
                                                    {this.state.voucherImageTypeMobile !== "external_url" && (<PublishIcon
                                                        style={{
                                                            cursor: "pointer",
                                                            position: "absolute",
                                                            right: 5,
                                                            bottom: this.state.voucherImageAlert ? 37 : 10,
                                                            fontSize: 18,
                                                            pointerEvents:
                                                                this.state.voucherImageTypeMobile ===
                                                                "uploadfromcomp" && "none",
                                                        }}
                                                        onClick={() => {
                                                            this.handleValuesWebShop()

                                                            this.setState({
                                                                addStockImage: true,
                                                            });
                                                        }}
                                                        className='expand-more'
                                                    />)}
                                                </div>
                                                {this.state.voucherImageAlert && (
                                                    <label style={{ color: "red" }}>Verplicht veld</label>
                                                )}
                                            </div>) : (<div
                                                className="form-miur"
                                                style={{ position: "relative" }}
                                            >
                                                <div className='wrapper-select-new'>
                                                    {this.state.voucherImageType === "external_url" ? (
                                                        <input
                                                            type="text"
                                                            defaultValue={this.state.voucherImage[0] !== "https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/placeholder.jpg?alt=media&token=3146a89c-5b95-4a6b-b60f-fb6b0f166bdb" ? this.state.voucherImage[0] : ""}
                                                            onBlur={(e) => {
                                                                let url = e.target.value

                                                                const img = new Image();
                                                                img.onload = () => {
                                                                    this.setState({ voucherImage: [url] })
                                                                    // Image is valid, you can use the URL or perform additional checks here
                                                                };
                                                                img.onerror = () => {
                                                                    this.setState({
                                                                        voucherImage: [
                                                                            "https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/placeholder.jpg?alt=media&token=3146a89c-5b95-4a6b-b60f-fb6b0f166bdb",
                                                                        ]
                                                                    })
                                                                };

                                                                img.src = url;

                                                            }}
                                                            style={{
                                                                fontSize: 13,
                                                                letterSpacing: 1,
                                                                backgroundColor: "white",
                                                            }}
                                                            className="dolabelsuppercase inputplaceholderbold"
                                                        />
                                                    ) : this.state.voucherImageType ===
                                                        "uploadfromcomp" ? (
                                                        <input
                                                            type="text"
                                                            value={this.state.voucherImage[0] !==
                                                                "https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/placeholder.jpg?alt=media&token=3146a89c-5b95-4a6b-b60f-fb6b0f166bdb" ? "Geselecteerd" : "Upload foto"}
                                                            // value="Upload foto"
                                                            readOnly
                                                            // disabled
                                                            style={{
                                                                fontSize: 13,
                                                                letterSpacing: 1,
                                                                backgroundColor: "white",
                                                            }}
                                                            className="dolabelsuppercase inputplaceholderbold"
                                                        />
                                                    ) : (
                                                        <input
                                                            type="text"
                                                            value="Kies foto"
                                                            readOnly
                                                            // disabled
                                                            style={{
                                                                fontSize: 13,
                                                                letterSpacing: 1,
                                                                backgroundColor: "white",
                                                            }}
                                                            className="dolabelsuppercase"
                                                        />
                                                    )}
                                                    {this.state.voucherImageType ===
                                                        "uploadfromcomp" && (
                                                            <input
                                                                type="file"
                                                                onChange={(e) => this.uploadImgVoucher(e)}
                                                                accept="image/*"
                                                                style={{
                                                                    position: "absolute",
                                                                    width: "100%",
                                                                    height: "80%",
                                                                    top: 0,
                                                                    left: 0,
                                                                    right: 0,
                                                                    bottom: 0,
                                                                    opacity: 0,
                                                                }}
                                                            />
                                                        )}
                                                    {this.state.voucherImageType ===
                                                        "takefromlibrary" && (
                                                            <div
                                                                style={{
                                                                    position: "absolute",
                                                                    width: "100%",
                                                                    height: 40,
                                                                    top: 0,
                                                                    cursor: "pointer",
                                                                    left: 0,
                                                                    right: 0,
                                                                    bottom: 0,
                                                                    opacity: 0,
                                                                }}
                                                                onClick={() => {
                                                                    this.handleValuesWebShop()

                                                                    this.setState({
                                                                        addStockImage: true,
                                                                    });
                                                                }}
                                                            ></div>
                                                        )}
                                                    {this.state.voucherImageType !== "external_url" && (<PublishIcon
                                                        style={{
                                                            cursor: "pointer",
                                                            position: "absolute",
                                                            right: 5,
                                                            bottom: this.state.voucherImageAlert ? 37 : 10,
                                                            fontSize: 18,
                                                            pointerEvents:
                                                                this.state.voucherImageType ===
                                                                "uploadfromcomp" && "none",
                                                        }}
                                                        onClick={() => {
                                                            this.handleValuesWebShop()

                                                            this.setState({
                                                                addStockImage: true,
                                                            });
                                                        }}
                                                        className='expand-more'
                                                    />)}
                                                </div>
                                                {this.state.voucherImageAlert && (
                                                    <label style={{ color: "red" }}>Verplicht veld</label>
                                                )}
                                            </div>)}
                                            {this.state.voucherSelectedMobile && (this.state.voucherImageMobile[0] !== "https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/placeholder.jpg?alt=media&token=3146a89c-5b95-4a6b-b60f-fb6b0f166bdb" && (
                                                <div className='form-miur'>
                                                    <div className='image-wrappwr-fwcoco imagewithnewdesign'>
                                                        <input />
                                                        <div className="image-campaigns-new icn-newupdatedoneline">
                                                            <div
                                                                className="image-sfcplp"
                                                                style={{
                                                                    backgroundImage: `url("${this.state.voucherImageMobile[0]}")`,
                                                                }}
                                                            >
                                                            </div>
                                                            {this.checkForDeleteVoucher()}
                                                        </div>
                                                    </div>
                                                </div>))}
                                            {!this.state.voucherSelectedMobile && (this.state.voucherImage[0] !== "https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/placeholder.jpg?alt=media&token=3146a89c-5b95-4a6b-b60f-fb6b0f166bdb" && (
                                                <div className='form-miur'>
                                                    <div className='image-wrappwr-fwcoco imagewithnewdesign'>
                                                        <input />
                                                        <div className="image-campaigns-new icn-newupdatedoneline">
                                                            <div
                                                                className="image-sfcplp"
                                                                style={{
                                                                    backgroundImage: `url("${this.state.voucherImage[0]}")`,
                                                                }}
                                                            >
                                                            </div>
                                                            {this.checkForDeleteVoucher()}
                                                        </div>
                                                    </div>
                                                </div>))}
                                            <div
                                                className="form-miur formfullwidthftcic"
                                                style={{ position: "relative" }}
                                            >
                                                <label>
                                                    Categorieën
                                                </label>
                                                <div className='wrapper-select-new'>
                                                    {this.state.selectedMain.length > 0 ? (
                                                        <input
                                                            type="text"
                                                            value={this.getSelectedCategoryName()}
                                                            disabled
                                                            style={{
                                                                fontSize: 13,
                                                                letterSpacing: 1,
                                                                backgroundColor: "white",
                                                                paddingRight: 40,
                                                            }}
                                                        />
                                                    ) : (
                                                        <input
                                                            type="text"
                                                            value=""
                                                            disabled
                                                            style={{
                                                                fontSize: 13,
                                                                letterSpacing: 1,
                                                                backgroundColor: "white",
                                                            }}
                                                        />
                                                    )}
                                                    {this.state.selectedMain.length > 0 ? (
                                                        <CheckCircleIcon
                                                            style={{
                                                                cursor: "pointer",
                                                                position: "absolute",
                                                                right: 5,
                                                                bottom: 0,
                                                                fontSize: 18,
                                                            }}
                                                            onClick={() =>
                                                                this.setState({
                                                                    addCategory: true,
                                                                    categorySelected: true,
                                                                })
                                                            }
                                                            className='expand-more'
                                                        />
                                                    ) : (
                                                        <AddCircleIcon
                                                            style={{
                                                                cursor: "pointer",
                                                                position: "absolute",
                                                                right: 5,
                                                                bottom: 0,
                                                                fontSize: 18,
                                                            }}
                                                            onClick={() =>
                                                                this.setState({
                                                                    addCategory: true,
                                                                    categorySelected: true,
                                                                })
                                                            }
                                                            className='expand-more'
                                                        />
                                                    )}
                                                    <div
                                                        style={{
                                                            position: "absolute",
                                                            width: "100%",
                                                            height: 40,
                                                            top: 0,
                                                            cursor: "pointer",
                                                            left: 0,
                                                            right: 0,
                                                            bottom: 0,
                                                            opacity: 0,
                                                        }}
                                                        onClick={() => {
                                                            this.handleValuesWebShop()

                                                            this.setState({
                                                                addCategory: true,
                                                                categorySelected: true,
                                                            });
                                                        }}
                                                    ></div>
                                                </div>
                                                {this.state.noCategorySelectedWebshop && (
                                                    <label style={{ color: "red" }}>Verplicht veld</label>
                                                )}
                                            </div>
                                            <div className="row-form-miur">
                                                <div
                                                    className="form-miur"
                                                    style={{ position: "relative" }}
                                                >
                                                    <label>Soort korting</label>
                                                    <div className='wrapper-select-new'>
                                                        <select
                                                            value={
                                                                this.state.voucherIsPercentage ? "per" : "price"
                                                            }
                                                            onChange={(e) => {
                                                                this.handleValuesWebShop()

                                                                if (e.target.value === "price") {
                                                                    this.setState({
                                                                        voucherIsPercentage: false,
                                                                        voucherAmount: "",
                                                                    });
                                                                } else if (e.target.value === "per") {
                                                                    this.setState({
                                                                        voucherIsPercentage: true,
                                                                        voucherAmount: "",
                                                                    });
                                                                }
                                                            }}
                                                        >
                                                            <option value="price">Bedrag</option>
                                                            <option value="per">Percentage</option>
                                                        </select>
                                                        <ExpandMoreIcon
                                                            style={{
                                                                cursor: "pointer",
                                                                position: "absolute",
                                                                right: 5,
                                                                bottom: 10,
                                                                fontSize: 18,
                                                                pointerEvents: "none",
                                                                pointerEvents: "none",
                                                            }}
                                                            className='expand-more'
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    className="form-miur kortingnumberdiv"
                                                    style={{ position: "relative" }}
                                                >
                                                    <span
                                                        style={{
                                                            position: "absolute",
                                                            left: this.state.voucherIsPercentage ? 48 : 8,
                                                            // left: 8,
                                                            color: "#8c8c8c",
                                                            fontSize: 13,
                                                            top: 45,
                                                            zIndex: 99999,
                                                        }}
                                                    >
                                                        {this.state.voucherIsPercentage ? "%" : "€"}
                                                        {/* € */}
                                                    </span>
                                                    <label>&nbsp;</label>
                                                    <input
                                                        type="number"
                                                        defaultValue={this.state.voucherAmount}
                                                        id="voucherAmount"
                                                        onChange={(e) => {
                                                            if (Number(e.target.value) > 100 && this.state.voucherIsPercentage) {
                                                                document.getElementById("voucherAmount").value = 100;
                                                            }
                                                        }}
                                                    />
                                                    {this.state.voucherAmountAlert && (
                                                        <label style={{ color: "red" }}>Verplicht veld</label>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="savebtnwork" style={{ marginTop: window.innerWidth > 1008 && -15 }}>
                                            <div className="timerwork-savebtn">
                                                <div
                                                    className="form-miur formfullwidthftcic"
                                                    style={{ position: "relative" }}
                                                >
                                                    <label>
                                                        Timer
                                                    </label>
                                                </div>
                                                <button>
                                                    <Switch
                                                        color="primary"
                                                        inputProps={{ "aria-label": "primary checkbox" }}
                                                        checked={this.state.timerActiveOnWebshop}
                                                        onClick={() => {
                                                            this.handleValuesWebShop()

                                                            let newDate = new Date();
                                                            let monthToday = newDate.getMonth() + 1;
                                                            let dateTodayToMake = newDate.getDate();
                                                            if (monthToday < 10) {
                                                                monthToday = `0${monthToday}`;
                                                            }
                                                            if (dateTodayToMake < 10) {
                                                                dateTodayToMake = `0${dateTodayToMake}`;
                                                            }
                                                            let makeToday = `${newDate.getFullYear()}-${monthToday}-${dateTodayToMake}`;

                                                            this.setState({
                                                                timerActiveOnWebshop: !this.state.timerActiveOnWebshop,

                                                                voucherStartDate: makeToday,
                                                                voucherStartTime: "",
                                                                voucherEndDate: "",
                                                                voucherEndTime: "",
                                                            });
                                                        }}
                                                    />
                                                    {this.state.timerActive ? (
                                                        <label>Onbeperkt</label>
                                                    ) : (
                                                        <label>Beperkt</label>
                                                    )}
                                                </button>
                                                {this.state.timerActiveOnWebshop && (<div
                                                    className="form-miur formfullwidthftcic form-inner-for-datetime"
                                                    style={{ position: "relative" }}
                                                >
                                                    <div className="inner-top-main">
                                                        <label>Starttijd van het coupon</label>
                                                        <div className="right-fifd" style={{ marginTop: 12 }}>
                                                            <div className="datinput-fifd">
                                                                <BiCalendar />
                                                                {this.state.voucherStartDate ? (
                                                                    <label>
                                                                        {this.getFormattedDate(this.state.voucherStartDate)}
                                                                    </label>
                                                                ) : (
                                                                    <label>Datum</label>
                                                                )}
                                                                <input
                                                                    type="date"
                                                                    min={this.state.todayDateToBeMax}
                                                                    max={this.state.voucherEndDate}
                                                                    value={this.state.voucherStartDate}
                                                                    onChange={(e) => {
                                                                        this.handleValuesWebShop()

                                                                        this.setState({
                                                                            voucherStartDate: e.target.value,
                                                                        });
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="select-fifd">
                                                                <MdTimer />
                                                                <select
                                                                    value={this.state.voucherStartTime}
                                                                    onChange={(e) => {
                                                                        this.handleValuesWebShop()

                                                                        this.setState({
                                                                            voucherStartTime: e.target.value,
                                                                        });
                                                                    }}
                                                                >
                                                                    <option disabled selected hidden value="">
                                                                        Tijd
                                                                    </option>
                                                                    {this.state.allTimes.map((y) => {
                                                                        let mustRestrictHours = false;
                                                                        let minHour = 0;
                                                                        let minMinutes = 0;
                                                                        if (this.state.voucherStartDate) {
                                                                            let timerDate = new Date(this.state.voucherStartDate)
                                                                            let currentDate = new Date()
                                                                            if (timerDate.getMonth() === currentDate.getMonth() && timerDate.getDate() === currentDate.getDate() && timerDate.getFullYear() === currentDate.getFullYear()) {
                                                                                minHour = currentDate.getHours()
                                                                                minMinutes = currentDate.getMinutes()
                                                                                mustRestrictHours = true
                                                                            } else {
                                                                                minHour = 0
                                                                                minMinutes = 0
                                                                                mustRestrictHours = false
                                                                            }
                                                                        }
                                                                        if (mustRestrictHours) {
                                                                            if (Number(y.label.split(":")[0]) >= minHour) {
                                                                                if (Number(y.label.split(":")[0]) === minHour) {
                                                                                    if (Number(y.label.split(":")[1] > minMinutes)) {
                                                                                        return (
                                                                                            <option value={y.label}>{y.label}</option>
                                                                                        );
                                                                                    }
                                                                                } else {
                                                                                    return (
                                                                                        <option value={y.label}>{y.label}</option>
                                                                                    );
                                                                                }
                                                                            }
                                                                        } else {
                                                                            return (
                                                                                <option value={y.label}>{y.label}</option>
                                                                            );
                                                                        }
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="inner-top-main" style={{ marginTop: 15 }}>
                                                        <label>Eindtijd van het coupon</label>
                                                        <div className="right-fifd" style={{ marginTop: 12 }}>
                                                            <div className="datinput-fifd">
                                                                <BiCalendar />
                                                                {this.state.voucherEndDate ? (
                                                                    <label>
                                                                        {this.getFormattedDate(this.state.voucherEndDate)}
                                                                    </label>
                                                                ) : (
                                                                    <label>Datum</label>
                                                                )}
                                                                <input
                                                                    type="date"
                                                                    min={this.state.voucherStartDate}
                                                                    value={this.state.voucherEndDate}
                                                                    onChange={(e) => {
                                                                        this.handleValuesWebShop()

                                                                        this.setState({
                                                                            voucherEndDate: e.target.value,
                                                                        });
                                                                        // this.setState({ endDateEvent: e.target.value })
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="select-fifd">
                                                                <MdTimer />
                                                                <select
                                                                    value={this.state.voucherEndTime}
                                                                    onChange={(e) => {
                                                                        this.handleValuesWebShop()

                                                                        this.setState({
                                                                            voucherEndTime: e.target.value,
                                                                        });
                                                                        // this.setState({ endTimeEvent: e.target.value })
                                                                    }}
                                                                >
                                                                    <option disabled selected hidden value="">
                                                                        Tijd
                                                                    </option>
                                                                    {this.state.allTimes.map((y) => {
                                                                        if (this.state.voucherStartTime) {
                                                                            if (this.state.voucherEndDate) {
                                                                                let currentDate = new Date(
                                                                                    this.state.voucherEndDate
                                                                                );
                                                                                currentDate.setHours(0, 0, 0, 0);
                                                                                let currentDateStart = new Date(this.state.voucherStartDate);
                                                                                currentDateStart.setHours(0, 0, 0, 0);
                                                                                if (currentDate.getTime() === currentDateStart.getTime()) {
                                                                                    let splittedFirst = y.label.split(":")[0];
                                                                                    let splittedMinutesFirst = y.label.split(":")[1];
                                                                                    let splittedSecond = this.state.voucherStartTime.split(":")[0];
                                                                                    let splittedMinutesSecond = this.state.voucherStartTime.split(":")[1];
                                                                                    if (Number(splittedSecond) < Number(splittedFirst)) {
                                                                                        return (
                                                                                            <option value={y.label}>
                                                                                                {y.label}
                                                                                            </option>
                                                                                        );
                                                                                    } else if (Number(splittedSecond) === Number(splittedFirst)) {
                                                                                        if (Number(splittedMinutesSecond) < Number(splittedMinutesFirst)) {
                                                                                            return (
                                                                                                <option value={y.label}>
                                                                                                    {y.label}
                                                                                                </option>
                                                                                            );
                                                                                        }
                                                                                    }
                                                                                } else {
                                                                                    return (
                                                                                        <option value={y.label}>
                                                                                            {y.label}
                                                                                        </option>
                                                                                    );
                                                                                }
                                                                            } else {
                                                                                return (
                                                                                    <option value={y.label}>
                                                                                        {y.label}
                                                                                    </option>
                                                                                );
                                                                            }
                                                                        } else {
                                                                            return (
                                                                                <option value={y.label}>
                                                                                    {y.label}
                                                                                </option>
                                                                            );
                                                                        }
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {this.state.voucherTimingAlert && (
                                                        <label style={{ color: "red", marginTop: 10 }}>Verplicht veld</label>
                                                    )}
                                                </div>)}
                                            </div>
                                            <div
                                                className="form-miur formfullwidthftcic"
                                                style={{ position: "relative" }}
                                            >
                                                <label>
                                                    Voorraad
                                                </label>
                                            </div>
                                            <button>
                                                <Switch
                                                    color="primary"
                                                    inputProps={{ "aria-label": "primary checkbox" }}
                                                    checked={this.state.voucherUnlimited}
                                                    onClick={() => {
                                                        this.handleValuesWebShop()

                                                        this.setState({
                                                            voucherUnlimited: !this.state.voucherUnlimited,
                                                        });
                                                    }}
                                                />
                                                {this.state.voucherUnlimited ? (
                                                    <label>Onbeperkt</label>
                                                ) : (
                                                    <label>Beperkt</label>
                                                )}
                                            </button>
                                            {!this.state.voucherUnlimited && (
                                                <div
                                                    className="form-miur formmiurnumbersinputaantal"
                                                    style={{
                                                        padding: "0px 20px",
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <input
                                                        type="number"
                                                        defaultValue={this.state.voucherNumber}
                                                        id="voucherNumber"
                                                    />
                                                    <label style={{ color: "black" }}>Aantal</label>
                                                </div>
                                            )}
                                            <div
                                                className="form-miur formfullwidthftcic"
                                                style={{ position: "relative" }}
                                            >
                                                <label>
                                                    Gast gebruikers
                                                </label>
                                            </div>
                                            <button>
                                                <Switch
                                                    color="primary"
                                                    inputProps={{ "aria-label": "primary checkbox" }}
                                                    checked={this.state.hideForGuestUsers}
                                                    onClick={() => {
                                                        this.handleValuesWebShop()

                                                        this.setState({
                                                            hideForGuestUsers: !this.state.hideForGuestUsers,
                                                        });
                                                    }}
                                                />
                                                {this.state.hideForGuestUsers ? (
                                                    <label>Niet zichtbaar voor gast gebruikers</label>
                                                ) : (
                                                    <label>Zichtbaar</label>
                                                )}
                                            </button>
                                            <div
                                                className="form-miur formfullwidthftcic"
                                                style={{ position: "relative" }}
                                            >
                                                <label>
                                                    Multiple Usage
                                                </label>
                                            </div>
                                            <button>
                                                <Switch
                                                    color="primary"
                                                    inputProps={{ "aria-label": "primary checkbox" }}
                                                    checked={this.state.voucherMultipleUseage}
                                                    onClick={() => {
                                                        this.handleValuesWebShop()

                                                        this.setState({
                                                            voucherMultipleUseage: !this.state.voucherMultipleUseage,
                                                        });
                                                    }}
                                                />
                                                {this.state.voucherMultipleUseage ? (
                                                    <label>Allowed</label>
                                                ) : (
                                                    <label>Not Allowed</label>
                                                )}
                                            </button>
                                            <div
                                                className="form-miur formfullwidthftcic"
                                                style={{ position: "relative" }}
                                            >
                                                <label>
                                                    Verberg op globale pagina
                                                </label>
                                            </div>
                                            <button>
                                                <Switch
                                                    color="primary"
                                                    inputProps={{ "aria-label": "primary checkbox" }}
                                                    checked={this.state.hideOnGlobalVoucher}
                                                    onClick={() => {
                                                        this.handleValuesWebShop()

                                                        this.setState({
                                                            hideOnGlobalVoucher: !this.state.hideOnGlobalVoucher,
                                                        });
                                                    }}
                                                />
                                                {this.state.hideOnGlobalVoucher ? (
                                                    <label>Hidden</label>
                                                ) : (
                                                    <label>Showing</label>
                                                )}
                                            </button>

                                            <div
                                                className="form-miur formfullwidthftcic"
                                                style={{ position: "relative" }}
                                            >
                                                <label>
                                                    Actie status
                                                </label>
                                            </div>
                                            <button>
                                                <Switch
                                                    color="primary"
                                                    inputProps={{ "aria-label": "primary checkbox" }}
                                                    checked={this.state.currentOpenedCheck}
                                                    onClick={() => {
                                                        this.handleValuesWebShop()

                                                        this.setState({
                                                            currentOpenedCheck: !this.state.currentOpenedCheck,
                                                        });
                                                    }}
                                                />
                                                {this.state.currentOpenedCheck ? (
                                                    <label>Actief</label>
                                                ) : (
                                                    <label>Niet Actief</label>
                                                )}
                                            </button>
                                            <div className="form-inner-for-datetime">
                                                <label>Startdatum </label>
                                                <div className="inner-top-main" style={{ marginTop: 15 }}>
                                                    <div className="right-fifd">
                                                        <div className="datinput-fifd">
                                                            <BiCalendar />
                                                            {this.state.timerDateWaiting ? (
                                                                <label>
                                                                    {this.getFormattedDate(
                                                                        this.state.timerDateWaiting
                                                                    )}
                                                                </label>
                                                            ) : (
                                                                <label>Datum</label>
                                                            )}
                                                            <input
                                                                type="date"
                                                                min={this.state.makeTodayTimer}
                                                                value={this.state.timerDateWaiting}
                                                                onChange={(e) => {
                                                                    this.handleValuesWebShop()

                                                                    this.setState({
                                                                        timerDateWaiting: e.target.value,
                                                                    });
                                                                    let timerDate = new Date(e.target.value)
                                                                    let currentDate = new Date()
                                                                    if (timerDate.getMonth() === currentDate.getMonth() && timerDate.getDate() === currentDate.getDate() && timerDate.getFullYear() === currentDate.getFullYear()) {
                                                                        this.setState({ timerTimeWaiting: "" })
                                                                    }
                                                                }}
                                                            />
                                                            {/* <ExpandMoreIcon /> */}
                                                        </div>
                                                        <div className="select-fifd">
                                                            <MdTimer />
                                                            <select
                                                                value={this.state.timerTimeWaiting}
                                                                onChange={(e) => {
                                                                    this.handleValuesWebShop()

                                                                    this.setState({
                                                                        timerTimeWaiting: e.target.value,
                                                                    });
                                                                }}
                                                            >
                                                                <option disabled selected hidden value="">
                                                                    Tijd
                                                                </option>
                                                                {this.state.allTimes.map((y) => {
                                                                    let mustRestrictHours = false;
                                                                    let minHour = 0;
                                                                    let minMinutes = 0;
                                                                    if (this.state.timerDateWaiting) {
                                                                        let timerDate = new Date(this.state.timerDateWaiting)
                                                                        let currentDate = new Date()
                                                                        if (timerDate.getMonth() === currentDate.getMonth() && timerDate.getDate() === currentDate.getDate() && timerDate.getFullYear() === currentDate.getFullYear()) {
                                                                            minHour = currentDate.getHours()
                                                                            minMinutes = currentDate.getMinutes()
                                                                            mustRestrictHours = true
                                                                        } else {
                                                                            minHour = 0
                                                                            minMinutes = 0
                                                                            mustRestrictHours = false
                                                                        }
                                                                    }
                                                                    if (mustRestrictHours) {
                                                                        if (Number(y.label.split(":")[0]) >= minHour) {
                                                                            if (Number(y.label.split(":")[0]) === minHour) {
                                                                                if (Number(y.label.split(":")[1] > minMinutes)) {
                                                                                    return (
                                                                                        <option value={y.label}>{y.label}</option>
                                                                                    );
                                                                                }
                                                                            } else {
                                                                                return (
                                                                                    <option value={y.label}>{y.label}</option>
                                                                                );
                                                                            }
                                                                        }
                                                                    } else {
                                                                        return (
                                                                            <option value={y.label}>{y.label}</option>
                                                                        );
                                                                    }
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {this.state.companyCurrentWebshop ? (
                                                <button
                                                    className="savebtnworksave"
                                                    onClick={() => {
                                                        let voucherNumber = document.getElementById("voucherNumber");
                                                        if (voucherNumber) {
                                                            voucherNumber = voucherNumber.value;
                                                            this.setState({ voucherNumber });
                                                        } else {
                                                            voucherNumber = 1;
                                                        }

                                                        this.handleValuesWebShop()

                                                        // this.saveCampaignWebShop();

                                                        if (this.state.alreadyMultipleUsage === this.state.voucherMultipleUseage) {
                                                            this.saveCampaignWebShop();
                                                        } else if (this.state.voucherMultipleUseage) {
                                                            this.setState({
                                                                editWebShop: true,
                                                                voucherNumber,
                                                            });
                                                        } else {
                                                            this.saveCampaignWebShop();
                                                        }
                                                    }}
                                                >
                                                    OPSLAAN
                                                </button>
                                            ) : (
                                                <button
                                                    className="savebtnworksave"
                                                    onClick={this.saveCampaignWebShop.bind(this)}
                                                >
                                                    OPSLAAN
                                                </button>
                                            )}
                                            {this.state.companyCurrentWebshop && (
                                                <button
                                                    className="savebtnworksave pinkcolorbuttondesign"
                                                    onClick={() => {
                                                        this.setState({ deleteVoucher: true });
                                                    }}
                                                >
                                                    VERWIJDER
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}


                {this.state.openAddPopup && (
                    <div
                        className="overlay-div-openadd"
                        onClick={() => {
                            this.props.closePopup("closewithoutredirect")
                        }}
                    ></div>
                )}
                {this.state.openAddPopup && (
                    <div className="rightmenuaddproduct rightmenusoloopenedupdated rightmenuopenedsoloupdated rightmenufieldsupdated updatedfieldsrightmenu">
                        {/* <div className="rightmenuaddproduct"> */}
                        <div
                            className="mid-rmap"
                            style={{ position: "relative", padding: 13 }}
                        >
                            <span>ACTIE TOEVOEGEN</span>
                            <div className="top-header-infocont">
                                <div style={{ position: "relative" }}>
                                    <div className='green-round-info' style={{ cursor: "pointer" }} onClick={() => {
                                        this.setState({ infoMainRightMenu: !this.state.infoMainRightMenu })
                                    }}>
                                        <font>?</font>
                                    </div>
                                    <div className="popup-gri-thifc" style={{ opacity: this.state.infoMainRightMenu ? 1 : 0, pointerEvents: this.state.infoMainRightMenu ? "all" : "none" }}>
                                        <CloseIcon onClick={() => this.setState({ infoMainRightMenu: false })} />
                                        <label style={{ fontSize: 15 }}>
                                            Kies de juiste branche voor je bedrijf
                                            <br />
                                            en ontdek meteen welke type acties je
                                            <br />
                                            beschikbaar kunt stellen op je pagina.
                                        </label>
                                    </div>
                                </div>
                                <CloseIcon
                                    style={{
                                        cursor: "pointer",
                                        position: "absolute",
                                        top: "50%",
                                        transform: "translateY(-50%)",
                                        right: 20,
                                        fontSize: 18,
                                    }}
                                    onClick={() => {
                                        this.props.closePopup("closewithoutredirect")
                                    }}
                                />
                            </div>
                        </div>
                        <div className="bottom-rmap">
                            <div
                                className="mid-img-uploading-rmap"
                                style={{ alignItems: "flex-start" }}
                            >
                                <div
                                    className="form-miur"
                                    style={{ marginBottom: 30, position: "relative" }}
                                >
                                    <label>Type Branche</label>
                                    <div className='wrapper-select-new'>
                                        <select
                                            style={{ fontSize: 13, letterSpacing: 1 }}
                                            value={this.state.mainSector}
                                            onChange={(e) => {
                                                if (e.target.value !== "Selecteer") {
                                                    let cloned = Object.entries(this.state.categoryData);
                                                    let filtered = cloned.filter((g) => {
                                                        return g[0] === e.target.value;
                                                    });
                                                    if (filtered.length > 0) {
                                                        if (this.state.allImagesStock.length > 0) {
                                                            let filteredSecond =
                                                                this.state.allImagesStock.filter((u) => {
                                                                    return u[0] === e.target.value;
                                                                });
                                                            if (filteredSecond.length > 0) {
                                                                let a = filteredSecond[0];
                                                                let arr = [];
                                                                if (a.length > 0) {
                                                                    let objected = Object.entries(a[1]);
                                                                    objected.map((y) => {
                                                                        arr.push(y[1]);
                                                                    });
                                                                    this.setState({
                                                                        allImagesCurrentSectorStock: arr,
                                                                    });
                                                                } else {
                                                                    this.setState({
                                                                        allImagesCurrentSectorStock: [],
                                                                    });
                                                                }
                                                            }
                                                        }

                                                        this.setState({
                                                            campaignsToShow: filtered[0][1],
                                                            mainSector: e.target.value,
                                                            mainSectorStock: e.target.value,
                                                        });
                                                        if (e.target.value !== "Selecteer") {
                                                            let arrSec = [];
                                                            this.state.completeCategories.map((g) => {
                                                                if (g[0] === e.target.value) {
                                                                    arrSec = g[1];
                                                                }
                                                            });
                                                            let categories = [];
                                                            arrSec.map((h) => {
                                                                if (h.children) {
                                                                    let obj = {
                                                                        title: h.title,
                                                                        category_id: h.category_id,
                                                                        sub_category: h.children,
                                                                    };
                                                                    categories.push(obj);
                                                                } else {
                                                                    let obj = {
                                                                        title: h.title,
                                                                        category_id: h.category_id,
                                                                    };
                                                                    categories.push(obj);
                                                                }
                                                            });
                                                            if (categories[0].sub_category) {
                                                                this.setState({
                                                                    all_categories: categories,
                                                                    sub_categories: categories[0].sub_category,
                                                                    subCategoryPresent: true,
                                                                });
                                                                this.setState({ selectedSector: e.target.value });
                                                            } else {
                                                                this.setState({
                                                                    all_categories: categories,
                                                                    sub_categories: [],
                                                                    subCategoryPresent: false,
                                                                });
                                                                this.setState({ selectedSector: e.target.value });
                                                            }
                                                        } else {
                                                            this.setState({
                                                                all_categories: [],
                                                                subCategoryPresent: false,
                                                                selectedMain: [],
                                                                sub_categories: [],
                                                                selectedSector: "",
                                                            });
                                                        }
                                                    }
                                                }
                                            }}
                                        >
                                            <option disabled selected hidden value="">
                                                Selecteer
                                            </option>
                                            {this.state.allSectors.map((g) => {
                                                return (
                                                    <option value={g.sector_id}>{g.sector_name}</option>
                                                );
                                            })}
                                        </select>
                                        <ExpandMoreIcon
                                            style={{
                                                position: "absolute",
                                                bottom: 5,
                                                right: 5,
                                                pointerEvents: "none",
                                            }}
                                            className='expand-more'
                                        />
                                    </div>
                                </div>
                                {this.state.mainSector &&
                                    this.state.campaignsToShow.length > 0 && (
                                        <div className="form-miur">
                                            <label>Type Actie</label>
                                        </div>
                                    )}
                                {this.state.mainSector &&
                                    this.state.campaignsToShow.map((g, i) => {
                                        if (g.category_main_id === "local_event") {
                                            return (
                                                <div className="form-miur-main-gray">
                                                    <div
                                                        className="form-miur"
                                                        key={Math.random()}
                                                        style={{
                                                            marginTop: 10,
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            cursor: "pointer",
                                                            position: "relative",
                                                            width: "auto",
                                                        }}
                                                        onClick={() => {
                                                            if (this.state.companyCurrentEvent) {
                                                                if (
                                                                    this.props.isAdmin || this.state.currentUserHere.latitude || (this.state.currentUserHere.houseNumber && this.state.currentUserHere.street && this.state.currentUserHere.city && this.state.currentUserHere.kvkCompany && this.state.currentUserHere.zipCode)
                                                                ) {
                                                                    if (window.innerWidth > 1008) {
                                                                        this.fillEditFieldsEvent(this.state.companyCurrentEvent)
                                                                    } else {
                                                                        localStorage.setItem("localEventInfo", JSON.stringify(this.state.companyCurrentEvent))
                                                                        history.push(`/editor/local_event`)
                                                                        return false;
                                                                    }
                                                                } else {
                                                                    swal(
                                                                        "Warning!",
                                                                        "Please fill up address to create",
                                                                        "error"
                                                                    ).then(() => {
                                                                        this.props.closePopup()
                                                                        this.props.showAddressOpened();
                                                                    });
                                                                }
                                                                return false;
                                                            }
                                                            if (
                                                                this.props.isAdmin || this.state.currentUserHere.latitude || (this.state.currentUserHere.houseNumber && this.state.currentUserHere.street && this.state.currentUserHere.city && this.state.currentUserHere.kvkCompany && this.state.currentUserHere.zipCode)
                                                            ) {
                                                                if (window.innerWidth < 1008) {
                                                                    localStorage.setItem("currentSectorForCampaign", this.state.selectedSector)
                                                                    localStorage.removeItem("localEventInfo")
                                                                    history.push(`/editor/local_event`)
                                                                    return;
                                                                }

                                                                let newDate = new Date();
                                                                let monthToday = newDate.getMonth() + 1;
                                                                let dateTodayToMake = newDate.getDate();
                                                                if (monthToday < 10) {
                                                                    monthToday = `0${monthToday}`;
                                                                }
                                                                if (dateTodayToMake < 10) {
                                                                    dateTodayToMake = `0${dateTodayToMake}`;
                                                                }
                                                                let makeToday = `${newDate.getFullYear()}-${monthToday}-${dateTodayToMake}`;

                                                                let objToPush = {
                                                                    startDateEvent: makeToday,
                                                                    startTimeEvent: "",
                                                                    endDateEvent: "",
                                                                    endTimeEvent: "",
                                                                    personsAmountEvent: "aantal",
                                                                    eventId: 1,
                                                                };
                                                                let arrEvents = [objToPush];
                                                                this.setState({ arrEvents });

                                                                this.setState({
                                                                    localEventImageType: "uploadfromcomp",
                                                                    localEventUnlimited: true,
                                                                    localEventTitle: "",
                                                                    localEventDesc: "",
                                                                    localEventShortDesc: "",
                                                                    localEventLongDesc: "",
                                                                    localEventAmount: "",
                                                                    hideLocalEventWork: false,
                                                                    localEventPercentage: true,
                                                                    localEventImage: [
                                                                        "https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/placeholder.jpg?alt=media&token=3146a89c-5b95-4a6b-b60f-fb6b0f166bdb",
                                                                    ],
                                                                    dropFilelocalEvent: [],
                                                                    localEventPlaceHolderIsImage: true,
                                                                    localEventNumber: 0,
                                                                    localEventBlockOnEdit: false,
                                                                });

                                                                if (this.state.allValuesLoaded) {
                                                                    this.setState({
                                                                        showingLoaderCreateEdit: true,
                                                                    });
                                                                    setTimeout(() => {
                                                                        this.setState({
                                                                            showingLoaderCreateEdit: false,
                                                                        });
                                                                    }, 2000);
                                                                } else {
                                                                    this.setState({
                                                                        showingLoaderCreateEdit: true,
                                                                    });
                                                                }
                                                                this.setState({
                                                                    openAddPopupLocalEvent: true,
                                                                    openAddPopup: false,
                                                                });
                                                            } else {
                                                                swal(
                                                                    "Warning!",
                                                                    "Please fill up address to create",
                                                                    "error"
                                                                ).then(() => {
                                                                    this.props.closePopup()
                                                                    this.props.showAddressOpened();
                                                                });
                                                            }
                                                        }}
                                                    >
                                                        {this.state.companyCurrentEvent ? (<CheckCircleIcon
                                                            style={{
                                                                // color: "white",
                                                                marginRight: 12,
                                                                fontSize: 18,
                                                            }}
                                                        />) : (<AddCircleOutlineIcon
                                                            style={{
                                                                // color: "white",
                                                                marginRight: 12,
                                                                fontSize: 18,
                                                            }}
                                                        />)}
                                                        <label style={{ marginBottom: 0 }}>{g.title}</label>
                                                    </div>
                                                </div>
                                            );
                                        } else if (g.category_main_id === "new_kortingsvoucher" && !this.props.isAdmin) {
                                            return (
                                                <div className="form-miur-main-gray">
                                                    <div
                                                        className="form-miur"
                                                        key={Math.random()}
                                                        style={{
                                                            marginTop: 10,
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            cursor: "pointer",
                                                            position: "relative",
                                                            width: "auto",
                                                        }}
                                                        onClick={() => {
                                                            if (this.state.companyCurrentVoucher) {
                                                                if (
                                                                    this.state.currentUserHere.latitude || (this.state.currentUserHere.houseNumber && this.state.currentUserHere.street && this.state.currentUserHere.city && this.state.currentUserHere.kvkCompany && this.state.currentUserHere.zipCode)
                                                                ) {
                                                                    if (window.innerWidth > 1008) {
                                                                        this.fillEditFieldsVoucher(this.state.companyCurrentVoucher)
                                                                    } else {
                                                                        localStorage.setItem("kortingsVoucherInfo", JSON.stringify(this.state.companyCurrentVoucher))
                                                                        history.push(`/editor/kortings_voucher`)
                                                                        return false;
                                                                    }
                                                                } else {
                                                                    swal(
                                                                        "Warning!",
                                                                        "Please fill up address to create",
                                                                        "error"
                                                                    ).then(() => {
                                                                        this.props.closePopup()
                                                                        this.props.showAddressOpened();
                                                                    });
                                                                }
                                                                return false;
                                                            }
                                                            if (
                                                                this.state.currentUserHere.latitude || (this.state.currentUserHere.houseNumber && this.state.currentUserHere.street && this.state.currentUserHere.city && this.state.currentUserHere.kvkCompany && this.state.currentUserHere.zipCode)
                                                            ) {
                                                                if (window.innerWidth < 1008) {
                                                                    localStorage.setItem("currentSectorForCampaign", this.state.selectedSector)
                                                                    localStorage.removeItem("localEventInfo")
                                                                    history.push(`/editor/kortings_voucher`)
                                                                    return;
                                                                }


                                                                this.setState({
                                                                    kortingsVoucherImageType: "uploadfromcomp",
                                                                    kortingsVoucherImageTypeMobile: "uploadfromcomp",
                                                                    kortingsVoucherUnlimited: true,
                                                                    kortingsVoucherTitle: "",
                                                                    kortingsVoucherDesc: "",
                                                                    kortingsVoucherInfoMobile: "",
                                                                    kortingsVoucherNumber: "",
                                                                    kortingsVoucherAmount: "",
                                                                    kortingsVoucherSalePrice: "",
                                                                    kortingsVoucherNormalPrice: "",
                                                                    kortingsVoucherPrijs: true,
                                                                    kortingsVoucherImage: [
                                                                        "https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/placeholder.jpg?alt=media&token=3146a89c-5b95-4a6b-b60f-fb6b0f166bdb",
                                                                    ],
                                                                    kortingsVoucherImageMobile: [
                                                                        "https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/placeholder.jpg?alt=media&token=3146a89c-5b95-4a6b-b60f-fb6b0f166bdb",
                                                                    ],
                                                                    kortingsVoucherSelectedMobile: false,
                                                                    dropFileKortingsVoucher: [],
                                                                    dropFileKortingsVoucherMobile: [],
                                                                    kortingsVoucherBlock: [],
                                                                    companyCurrentVoucher: "",
                                                                });

                                                                if (this.state.allValuesLoaded) {
                                                                    this.setState({
                                                                        showingLoaderCreateEdit: true,
                                                                    });
                                                                    setTimeout(() => {
                                                                        this.setState({
                                                                            showingLoaderCreateEdit: false,
                                                                        });
                                                                    }, 2000);
                                                                } else {
                                                                    this.setState({
                                                                        showingLoaderCreateEdit: true,
                                                                    });
                                                                }
                                                                this.setState({
                                                                    openAddPopupKortingsvoucher: true,
                                                                    openAddPopup: false,
                                                                });
                                                            } else {
                                                                swal(
                                                                    "Warning!",
                                                                    "Please fill up address to create",
                                                                    "error"
                                                                ).then(() => {
                                                                    this.props.closePopup()
                                                                    this.props.showAddressOpened();
                                                                });
                                                            }
                                                        }}
                                                    >
                                                        {this.state.companyCurrentVoucher ? (<CheckCircleIcon
                                                            style={{
                                                                // color: "white",
                                                                marginRight: 12,
                                                                fontSize: 18,
                                                            }}
                                                        />) : (<AddCircleOutlineIcon
                                                            style={{
                                                                // color: "white",
                                                                marginRight: 12,
                                                                fontSize: 18,
                                                            }}
                                                        />)}
                                                        <label style={{ marginBottom: 0 }}>{g.title}</label>
                                                    </div>
                                                </div>
                                            );
                                        } else if (g.category_main_id === "new_webshop" && !this.props.isAdmin) {
                                            return (
                                                <div className="form-miur-main-gray">
                                                    <div
                                                        className="form-miur"
                                                        key={Math.random()}
                                                        style={{
                                                            marginTop: 10,
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            cursor: "pointer",
                                                            position: "relative",
                                                            width: "auto",
                                                        }}
                                                        onClick={() => {
                                                            // if (this.state.companyCurrentWebshop) {
                                                            //     if (
                                                            //         this.state.currentUserHere.latitude || (this.state.currentUserHere.houseNumber && this.state.currentUserHere.street && this.state.currentUserHere.city && this.state.currentUserHere.kvkCompany && this.state.currentUserHere.zipCode)
                                                            //     ) {
                                                            //         if (window.innerWidth > 1008) {
                                                            //             this.fillEditFieldsWebshop(this.state.companyCurrentWebshop)
                                                            //         } else {
                                                            //             localStorage.setItem("webShopInfo", JSON.stringify(this.state.companyCurrentWebshop))
                                                            //             history.push(`/editor/web_shop`)
                                                            //             return false;
                                                            //         }
                                                            //     } else {
                                                            //         swal(
                                                            //             "Warning!",
                                                            //             "Please fill up address to create",
                                                            //             "error"
                                                            //         ).then(() => {
                                                            //             this.props.closePopup()
                                                            //             this.props.showAddressOpened();
                                                            //         });
                                                            //     }
                                                            //     return false;
                                                            // }
                                                            if (
                                                                this.state.currentUserHere.latitude || (this.state.currentUserHere.houseNumber && this.state.currentUserHere.street && this.state.currentUserHere.city && this.state.currentUserHere.kvkCompany && this.state.currentUserHere.zipCode)
                                                            ) {
                                                                if (window.innerWidth < 1008) {
                                                                    localStorage.setItem("currentSectorForCampaign", this.state.selectedSector)
                                                                    localStorage.removeItem("webShopInfo")
                                                                    history.push(`/editor/web_shop`)
                                                                    return;
                                                                }

                                                                let newDate = new Date();
                                                                let monthToday = newDate.getMonth() + 1;
                                                                let dateTodayToMake = newDate.getDate();
                                                                if (monthToday < 10) {
                                                                    monthToday = `0${monthToday}`;
                                                                }
                                                                if (dateTodayToMake < 10) {
                                                                    dateTodayToMake = `0${dateTodayToMake}`;
                                                                }
                                                                let makeToday = `${newDate.getFullYear()}-${monthToday}-${dateTodayToMake}`;


                                                                this.setState({
                                                                    voucherImageType: "uploadfromcomp",
                                                                    voucherImageTypeMobile: "uploadfromcomp",
                                                                    voucherUnlimited: true,
                                                                    voucherTitle: "",
                                                                    voucherWebshopUrl: "",
                                                                    voucherDesc: "",
                                                                    voucherAmount: "",
                                                                    voucherIsPercentage: true,
                                                                    voucherImage: [
                                                                        "https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/placeholder.jpg?alt=media&token=3146a89c-5b95-4a6b-b60f-fb6b0f166bdb",
                                                                    ],
                                                                    voucherImageMobile: [
                                                                        "https://firebasestorage.googleapis.com/v0/b/bhodi-7c327.appspot.com/o/placeholder.jpg?alt=media&token=3146a89c-5b95-4a6b-b60f-fb6b0f166bdb",
                                                                    ],
                                                                    dropFileVoucher: [],
                                                                    voucherNumber: 0,
                                                                    webShopBlockOnEdit: false,
                                                                    voucherStartDate: makeToday,
                                                                    voucherStartTime: "",
                                                                    voucherEndDate: "",
                                                                    voucherEndTime: "",
                                                                    timerActiveOnWebshop: true,
                                                                    hideForGuestUsers: false,
                                                                    voucherMultipleUseage: false,
                                                                    timerDateWaiting: "",
                                                                    timerTimeWaiting: ""
                                                                });

                                                                if (this.state.allValuesLoaded) {
                                                                    this.setState({
                                                                        showingLoaderCreateEdit: true,
                                                                    });
                                                                    setTimeout(() => {
                                                                        this.setState({
                                                                            showingLoaderCreateEdit: false,
                                                                        });
                                                                    }, 2000);
                                                                } else {
                                                                    this.setState({
                                                                        showingLoaderCreateEdit: true,
                                                                    });
                                                                }
                                                                this.setState({
                                                                    openAddPopupWebShop: true,
                                                                    openAddPopup: false,
                                                                });
                                                            } else {
                                                                swal(
                                                                    "Warning!",
                                                                    "Please fill up address to create",
                                                                    "error"
                                                                ).then(() => {
                                                                    this.props.closePopup()
                                                                    this.props.showAddressOpened();
                                                                });
                                                            }
                                                        }}
                                                    >
                                                        <AddCircleOutlineIcon
                                                            style={{
                                                                // color: "white",
                                                                marginRight: 12,
                                                                fontSize: 18,
                                                            }}
                                                        />
                                                        <label style={{ marginBottom: 0 }}>{g.title}</label>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    })}
                            </div>
                        </div>
                    </div>
                )}


                <Dialog
                    open={this.state.deletelocalEvent}
                    onClose={() => this.setState({ deletelocalEvent: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999 }}
                    className="modal-delete-campaigntest"
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Weet je zeker dat je de campagne wilt verwijderen?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                if (this.state.localEventBlock.length > 0) {
                                    this.setState({ notLoadData: true })
                                    let e = this.state.localEventBlock[this.state.onCurrentIndexEvents];

                                    firebase
                                        .database()
                                        .ref("bhodi_campaigns/" + uid + "/" + e.pushKey)
                                        .remove();
                                    setTimeout(() => {
                                        this.setState({ deletelocalEvent: false });

                                        window.location.reload();
                                    }, 500);
                                }
                            }}
                            color="primary"
                        >
                            JA
                        </Button>
                        <Button
                            onClick={() => this.setState({ deletelocalEvent: false })}
                            color="primary"
                        >
                            NEE
                        </Button>
                    </DialogActions>
                </Dialog>


                <Dialog
                    open={this.state.deleteKortingsVoucher}
                    onClose={() => this.setState({ deleteKortingsVoucher: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999 }}
                    className="modal-delete-campaigntest"
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Weet je zeker dat je de campagne wilt verwijderen?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                if (this.state.companyCurrentVoucher) {
                                    this.setState({ notLoadData: true })

                                    firebase
                                        .database()
                                        .ref("bhodi_campaigns/" + uid + "/" + this.state.companyCurrentVoucher.pushKey)
                                        .remove();
                                    setTimeout(() => {
                                        this.setState({ deleteKortingsVoucher: false });

                                        window.location.reload();
                                    }, 500);
                                }
                            }}
                            color="primary"
                        >
                            JA
                        </Button>
                        <Button
                            onClick={() => this.setState({ deleteKortingsVoucher: false })}
                            color="primary"
                        >
                            NEE
                        </Button>
                    </DialogActions>
                </Dialog>



                <Dialog
                    open={this.state.deleteVoucher}
                    onClose={() => this.setState({ deleteVoucher: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999 }}
                    className="modal-delete-campaigntest"
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Weet je zeker dat je de campagne wilt verwijderen?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                if (this.state.companyCurrentWebshop) {
                                    this.setState({ notLoadData: true })

                                    firebase
                                        .database()
                                        .ref("bhodi_campaigns/" + uid + "/" + this.state.companyCurrentWebshop.pushKey)
                                        .remove();
                                    setTimeout(() => {
                                        this.setState({ deleteVoucher: false });

                                        window.location.reload();
                                    }, 500);
                                }
                            }}
                            color="primary"
                        >
                            JA
                        </Button>
                        <Button
                            onClick={() => this.setState({ deleteVoucher: false })}
                            color="primary"
                        >
                            NEE
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.editKortingsVoucher}
                    onClose={() => this.setState({ editKortingsVoucher: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999 }}
                    className="edit-dialog-cdd"
                >
                    <DialogTitle id="alert-dialog-title">OPGELET</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Members die al eerder geboekt <br /> hebben, kunnen opnieuw
                            boeken
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                this.setState({ editKortingsVoucher: false });
                                this.saveCampaignKortingsVoucher();
                            }}
                            color="primary"
                        >
                            JA
                        </Button>
                        <Button
                            onClick={() => this.setState({ editKortingsVoucher: false })}
                            color="primary"
                            className="close-modal-btn-edcd"
                        >
                            <CloseIcon />
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.editWebShop}
                    onClose={() => this.setState({ editWebShop: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999 }}
                    className="edit-dialog-cdd"
                >
                    <DialogTitle id="alert-dialog-title">OPGELET</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Members die al eerder geboekt <br /> hebben, kunnen opnieuw
                            boeken
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                this.setState({ editWebShop: false });
                                this.saveCampaignWebShop();
                            }}
                            color="primary"
                        >
                            JA
                        </Button>
                        <Button
                            onClick={() => this.setState({ editWebShop: false })}
                            color="primary"
                            className="close-modal-btn-edcd"
                        >
                            <CloseIcon />
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        global_campaigns: state.campaignsReducer.global_campaigns,
        user: state.authReducers.user,
        globalsectors: state.categoriesReducer.globalsectors,
        global_values: state.globalReducers.global_values,
        campaigns_category: state.categoriesReducer.campaigns_category,
        categories_vouchers: state.categoriesReducer.categories_vouchers,
        stock_images: state.globalReducers.stock_images,
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        updateCategoriesVouchers: (categories_vouchers) =>
            dispatch(updateCategoriesVouchers(categories_vouchers)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditLocalEvent);
