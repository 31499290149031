const initial_state = {
    // current_exclusives: null,
    // friends_exclusives: null,
    // selected_exclusive: null,
    // all_exclusives: null,
    // selected_exclusive_category: null,
    // selected_exclusive_category_friends: null,
    // selected_exclusive_friends: null,
}

const reducer = (state = initial_state, action) => {
    switch (action.type) {
        // case "update_current_exclusives": {
        //     return { ...state, current_exclusives: action.current_exclusives }
        // }
        // case "update_friends_exclusives": {
        //     return { ...state, friends_exclusives: action.friends_exclusives }
        // }
        // case "update_selected_exclusive": {
        //     return { ...state, selected_exclusive: action.selected_exclusive }
        // }
        // case "update_all_exclusives": {
        //     return { ...state, all_exclusives: action.all_exclusives }
        // }
        // case "update_selected_exclusive_category": {
        //     return { ...state, selected_exclusive_category: action.selected_exclusive_category }
        // }
        // case "update_selected_exclusive_friends": {
        //     return { ...state, selected_exclusive_friends: action.selected_exclusive_friends }
        // }
        // case "update_selected_exclusive_category_friends": {
        //     return { ...state, selected_exclusive_category_friends: action.selected_exclusive_category_friends }
        // }
        default: {
            return state;
        }
    }
}

export default reducer