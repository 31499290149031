import React, { Component } from "react";
import "./index.css";
import firebase from "../Config/Firebase";
import CloseIcon from "@material-ui/icons/Close";
import {
    updateUser,
    updateAlbums,
    updateImages,
    updateProducts,
    updateGlobalVouchers,
    updateAllSectors,
    updateGuestUserCart,
    updateGuestUserCartTime,
    updateGuestUserCartExtendOpportunity,
} from "../Redux/actions/authActions";
import { connect } from "react-redux";
import ReactFullpage from "@fullpage/react-fullpage";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
// import VolgendMobile from "../../VolgendMobile";
import { bookmarkCurrentProduct, unBookmarkCurrentProduct } from "../Functions/shopFunctions";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import NumberFormatter from "./NumberFormatter";
import ButtonTimerCampaign from "./ButtonTimerCampaign";
import KortingsVoucherTimer from "../Containers/MemberHome/KortingsVoucherTimer";
import AddIcon from "@material-ui/icons/Add";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { checkForSubscribedExclusives } from "../Functions/checkForSubscribedExclusives";
import CheckIcon from "@material-ui/icons/Check";
import { isDesktop } from "react-device-detect";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { history } from "../Config/Routes";
import DOMPurify from "dompurify";
import { RiArrowDownSLine } from "react-icons/ri";
import { mergeAdminEvent } from "../Functions/globalFunctions";

const initialState = {
    beschikbaar: true,
    popuprightToShow: false,
    titleProduct: "",
    normalPriceProduct: "",
    charityPriceProduct: "",
    descProduct: "",
    currentImageIndex: 0,
    arrImages: [],
    addCategory: false,
    gebruikers: false,
    addTimer: false,
    activateTimer: false,
    obj: {
        "category 1": {
            sub_category: "sub category 1",
        },
        "category 2": {
            sub_category: "sub category 2",
        },
    },
    isMain: "category 1",
    selectedMain: "category 1",
    selectedSub: "sub category 1",
    isSub: "sub category 1",
    daysIndex: 0,
    hoursIndex: -1,
    minutesIndex: -1,
    defaultQty: 1,
    allProducts: [],
    dropFile: [],
    filesName: [],
    productsIndex: 0,
    reachedProductsIndex: 0,
    hour: "",
    minutes: "",
    productDetailOpen: false,
    hours: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23,
    ],
    sectors: [],
    currentSector: "",
    currentSectorId: "",
    categoryName: "",
    currentSector: "",
    all_categories: [],
    currentUserHere: "",
    currentUser: "",
    fullpageApi: "",
    scrollStart: true,
    eventDate: "",
    startHour: "",
    endHour: "",
    startMinute: "",
    endMinute: "",
    currentActie: "",
    payOnline: false,
    isDesktop: false,
    currentProductDetailed: "",
    allProductsFiltered: [],
    noFilteredProducts: false,
    searchText: "",
    availableMainCategories: [],
    availableSectors: [],
    availableSubCategories: [],
    categoriesDropdown: [],
    allSectors: [],
    categoriesToFilter: [],
    latitude: "",
    longitude: "",
    firstSectionToShow: [],
    onCurrentPage: 0,
    allCampaigns: [],
    currentVoucherWork: "",
    activeSection: 0,
    hideFullpage: false,
    showDescriptionPopup: false,
    selectedDish: 0,
    mustShowAnimation: false,
    useOfVoucherOpen: false,
    allVouchers: [],
    completeArrProducts: [],
    filteredProductsToShow: [],
    showSearchLine: false,
    urlParams: null,
    orderidtoupload: 1,
    guestUserCart: [],
    currentUserCharityMember: false,
};

let orderIdRef = firebase.database().ref("current_order_id")

export class VouchersNewGridDesktop extends Component {
    constructor() {
        super();
        this.state = {
            ...initialState,
        };
    }

    getVoucherInfo() {
        this.setState({ ...initialState });

        window.addEventListener("resize", () => {
            if (window.innerWidth < 1009) {
                this.setState({ isDesktop: false });
            } else {
                this.setState({ isDesktop: true });
            }
        });
        if (window.innerWidth < 1009) {
            this.setState({ isDesktop: false });
        } else {
            this.setState({ isDesktop: true });
        }

        if (this.props.global_values) {
            let a = this.props.global_values;
            if (a) {
                if (a.globalOverlay) {
                    this.setState({ globalOverlay: a.globalOverlay });
                }
                if (a.showGlobalTimer) {
                    this.setState({ showOverlay: true, timerDate: a.globalTimerDate, timerTime: a.globalTimerTime })
                } else {
                    this.setState({ showOverlay: false })
                }
            }
        }

        if (window.innerWidth < 1009) {
            this.setState({ isDesktop: false });
        } else {
            if (!this.props.onHomePages) {
                document.getElementById("root").style.position = "fixed";
                document.getElementById("root").style.overflow = "hidden";
            }
            this.setState({ isDesktop: true });
        }

        let uid = localStorage.getItem("userid");
        this.setState({ currentUserId: uid });
        if (!uid) {
            this.setState({ isGuest: true });
        } else {
            this.setState({ isGuest: false });
        }


        if (this.props.user) {
            if (this.props.user.length > 0) {
                let filtered = this.props.user.filter((y) => {
                    return y.user_id === uid;
                });
                if (filtered.length > 0) {
                    let a = filtered[0]
                    if (a.subscription) {
                        if (a.subscription.status === "active") {
                            this.setState({ currentUserCharityMember: true });
                        } else {
                            if (checkForSubscribedExclusives(a)) {
                                this.setState({ currentUserCharityMember: true })
                            }
                        }
                    } else {
                        if (checkForSubscribedExclusives(a)) {
                            this.setState({ currentUserCharityMember: true })
                        }
                    }
                    this.setState({ currentUserHere: filtered[0] });
                }

                let latitude = localStorage.getItem("latitudeAfterLogin");
                let longitude = localStorage.getItem("longitudeAfterLogin");
                if (latitude) {
                    this.setState({ latitude, longitude });
                }
            }
        }

        orderIdRef.on("value", (data) => {
            let a = data.val()
            if (a) {
                this.setState({ orderidToUpload: Number(a) });
            }
        })

        if (this.props.guest_user_cart) {
            this.setState({ guestUserCart: this.props.guest_user_cart });
        }


        this.props.filteredProductsToShow.map((e) => {
            e.selectedTimeIndex = 0
            e.reserved_event_id = 1
            e.selectedTiming = false
            e.dropdownOpenTiming = false
        })

        this.setState({
            filteredProductsToShow: this.props.filteredProductsToShow,
            noProducts: this.props.noProducts,
        });
    }

    componentDidMount() {
        this.getVoucherInfo();
    }

    componentWillUnmount() {
        orderIdRef.off()
    }

    getVerzilveredQty(e) {
        let arrItems = e;
        if (arrItems.length > 0) {
            Array.prototype.sum = function (prop) {
                var total = 0;
                for (var i = 0, _len = this.length; i < _len; i++) {
                    total += Number(this[i][1][prop].length);
                }
                return total;
            };
            return arrItems.sum("allDates");
        }
    }

    onLeave(origin, destination, direction) {
        if (Number(origin.index) > 0) {
            // this.state.arrToHideOrShow[origin.index - 1].show = false
            this.setState({ countsArray: [] });
        }
    }

    afterLoad(origin, destination, direction) {
        this.setState({ countsArray: [], onCurrentPage: destination.index });
    }

    componentDidUpdate(prevProps) {
        if (this.props.guest_user_cart) {
            if (prevProps.guest_user_cart !== this.props.guest_user_cart) {
                this.setState({ guestUserCart: this.props.guest_user_cart });
            }
        }

        if (prevProps.global_values !== this.props.global_values) {
            let a = this.props.global_values;
            if (a) {
                if (a.showGlobalTimer) {
                    let dateTimer = new Date(Number(a.globalTimerDate.split("-")[0]), Number(a.globalTimerDate.split("-")[1]) - 1, Number(a.globalTimerDate.split("-")[2]), Number(a.globalTimerTime.split(":")[0]), Number(a.globalTimerTime.split(":")[1]), 0).getTime()
                    let dateYet = new Date().getTime()
                    if (dateYet < dateTimer) {
                        this.setState({ showOverlay: true, timerDate: a.globalTimerDate, timerTime: a.globalTimerTime })
                    }
                } else {
                    this.setState({ showOverlay: false })
                }
            }
        }

        if (this.props.global_campaigns !== prevProps.global_campaigns) {
            let allArrs = this.props.global_campaigns;
            let allKeysCurrent = [];
            let allKeysComingCampaigns = [];
            this.state.filteredProductsToShow.map((u) => {
                allKeysCurrent.push(u.pushKey);
            });
            allArrs.map((y) => {
                y[1].type = "campaign";
                y[1].currentImageIndex = 0;
                allKeysComingCampaigns.push(y[1].pushKey);
                if (y[1].desc) {
                    // y[1].desc = y[1].desc.substr(0, 200)
                    y[1].short_desc = y[1].desc.substr(0, 32);
                }
                y[1].images = [y[1].image];
                y[1].imagesObjected = [{ image: y[1].image, pushKey: y[1].pushKey }];
                if (y[1].campaignType === "kortings_voucher") {
                    this.state.filteredProductsToShow.map((t, i) => {
                        if (t.pushKey === y[1].pushKey) {
                            if (!y[1].active) {
                                y[1].showPopupNotLeft = true;
                            } else {
                                if (!y[1].kortingsVoucherUnlimited) {
                                    let bewaaredArray = [];
                                    let num = 0;
                                    if (y[1].verzilvered_by) {
                                        let objectedVerzilvered = Object.entries(
                                            y[1].verzilvered_by
                                        );
                                        num = this.getVerzilveredQty(objectedVerzilvered);
                                    }
                                    if (num + bewaaredArray.length >= Number(y[1].number)) {
                                        y[1].showPopupNotLeft = true;
                                    } else {
                                        y[1].showPopupNotLeft = false;
                                    }
                                } else {
                                    y[1].showPopupNotLeft = false;
                                }
                            }
                            this.state.filteredProductsToShow[i] = y[1];
                        }
                    });
                }
                if (y[1].campaignType === "web_shop") {
                    this.state.filteredProductsToShow.map((t, i) => {
                        if (t.pushKey === y[1].pushKey) {
                            if (!y[1].active) {
                                y[1].showPopupNotLeft = true;
                            } else {
                                if (!y[1].voucherUnlimited) {
                                    let bewaaredArray = [];
                                    let num = 0;
                                    if (y[1].verzilvered_by) {
                                        let objectedVerzilvered = Object.entries(
                                            y[1].verzilvered_by
                                        );
                                        num = this.getVerzilveredQty(objectedVerzilvered);
                                    }
                                    if (num + bewaaredArray.length >= Number(y[1].number)) {
                                        y[1].showPopupNotLeft = true;
                                    } else {
                                        y[1].showPopupNotLeft = false;
                                    }
                                } else {
                                    y[1].showPopupNotLeft = false;
                                }
                            }
                            this.state.filteredProductsToShow[i] = y[1];
                        }
                    });
                }
                if (y[1].campaignType === "local_event") {
                    if (y[1].takeFromAdmin) {
                        let obj = mergeAdminEvent(y[1], this.props.global_campaigns)
                        y[1] = Object.assign({}, obj)
                    }
                    y[1].images = [y[1].image];
                    y[1].imagesObjected = [{ image: y[1].image, pushKey: y[1].pushKey }];
                    this.state.filteredProductsToShow.map((t, i) => {
                        if (t.pushKey === y[1].pushKey) {
                            if (!y[1].active) {
                                y[1].showPopupNotLeft = true;
                            } else {
                                y[1].showPopupNotLeft = false;
                            }

                            let obj = {
                                ...y[1]
                            }

                            if (t.selectedTimeIndex) {
                                obj.selectedTimeIndex = t.selectedTimeIndex
                            } else {
                                obj.selectedTimeIndex = 0
                            }
                            if (t.dropdownOpenTiming) {
                                obj.dropdownOpenTiming = t.dropdownOpenTiming
                            }
                            if (t.selectedTiming) {
                                obj.selectedTiming = t.selectedTiming
                            }
                            if (t.reserved_event_id) {
                                obj.reserved_event_id = t.reserved_event_id
                            }

                            this.state.filteredProductsToShow[i] = obj;
                        }
                    });
                }
            });

            this.setState({
                filteredProductsToShow: this.state.filteredProductsToShow,
            });
        }

        if (this.props.filteredProductsToShow !== prevProps.filteredProductsToShow || this.props.noProducts !== prevProps.noProducts && this.state.filteredProductsToShow.length > 0) {
            //     console.log(this.state.completeArrProducts, '/see here')
            //     if (this.state.completeArrProducts.length > 0) {
            //         this.props.completeArrProducts.map((y) => {
            //             this.state.completeArrProducts.map((t, i) => {
            //                 if (t.pushKey === y[1].pushKey && t.campaignType === "local_event") {
            //                     if (!y[1].active) {
            //                         y[1].showPopupNotLeft = true;
            //                     } else {
            //                         y[1].showPopupNotLeft = false;
            //                     }
            //                     let obj = {
            //                         ...y[1]
            //                     }
            //                     if (t.reserved_event_id) {
            //                         obj.selectedTimeIndex = t.selectedTimeIndex
            //                         obj.reserved_event_id = t.reserved_event_id
            //                         obj.selectedTiming = t.selectedTiming
            //                         obj.dropdownOpenTiming = t.dropdownOpenTiming
            //                     }
            //                     this.state.completeArrProducts[i] = y[1];
            //                 }
            //             });
            //         })
            //         this.props.allProducts.map((y) => {
            //             this.state.allProducts.map((t, i) => {
            //                 if (t.pushKey === y[1].pushKey && t.campaignType === "local_event") {
            //                     if (!y[1].active) {
            //                         y[1].showPopupNotLeft = true;
            //                     } else {
            //                         y[1].showPopupNotLeft = false;
            //                     }
            //                     let obj = {
            //                         ...y[1]
            //                     }
            //                     if (t.reserved_event_id) {
            //                         obj.selectedTimeIndex = t.selectedTimeIndex
            //                         obj.reserved_event_id = t.reserved_event_id
            //                         obj.selectedTiming = t.selectedTiming
            //                         obj.dropdownOpenTiming = t.dropdownOpenTiming
            //                     }
            //                     this.state.allProducts[i] = y[1];
            //                 }
            //             });
            //         })
            //         this.props.filteredProductsToShow.map((y) => {
            //             this.state.filteredProductsToShow.map((t, i) => {
            //                 if (t.pushKey === y[1].pushKey && t.campaignType === "local_event") {
            //                     if (!y[1].active) {
            //                         y[1].showPopupNotLeft = true;
            //                     } else {
            //                         y[1].showPopupNotLeft = false;
            //                     }
            //                     let obj = {
            //                         ...y[1]
            //                     }
            //                     if (t.reserved_event_id) {
            //                         obj.selectedTimeIndex = t.selectedTimeIndex
            //                         obj.reserved_event_id = t.reserved_event_id
            //                         obj.selectedTiming = t.selectedTiming
            //                         obj.dropdownOpenTiming = t.dropdownOpenTiming
            //                     }
            //                     this.state.filteredProductsToShow[i] = y[1];
            //                 }
            //             });
            //         })


            //         this.setState({
            //             allProducts: this.state.allProducts,
            //             completeArrProducts: this.state.completeArrProducts,
            //             filteredProductsToShow: this.state.filteredProductsToShow,
            //             noProducts: this.props.noProducts,
            //         });
            //     } else {
            //         console.log(this.props.allProducts, '/see here')
            //         console.log(this.props.completeArrProducts, '/see here')
            //         console.log(this.props.filteredProductsToShow, '/see here')

            //         this.setState({
            //             allProducts: this.props.allProducts,
            //             completeArrProducts: this.props.completeArrProducts,
            //             filteredProductsToShow: this.props.filteredProductsToShow,
            //             noProducts: this.props.noProducts,
            //         });
            //     }
            this.setState({
                filteredProductsToShow: this.props.filteredProductsToShow,
                noProducts: this.props.noProducts,
            });
        }


        let uid = localStorage.getItem("userid");

        if (this.props.user !== prevProps.user) {
            if (this.props.user.length > 0) {
                let filtered = this.props.user.filter((y) => {
                    return y.user_id === uid;
                });
                if (filtered.length > 0) {
                    this.setState({ currentUserHere: filtered[0] });
                }

                let latitude = localStorage.getItem("latitudeAfterLogin");
                let longitude = localStorage.getItem("longitudeAfterLogin");
                if (latitude) {
                    this.setState({ latitude, longitude });
                }
            }
        }
    }

    getSellerName(e) {
        let filtered = this.props.user.filter((g) => {
            return g.user_id === e.created_by;
        });
        if (filtered.length > 0) {
            return `${filtered[0].company_name} | ${filtered[0].city}`;
        }
    }

    getSellerImage(e) {
        let filtered = this.props.user.filter((g) => {
            return g.user_id === e.created_by;
        });
        if (filtered.length > 0) {
            if (filtered[0].profile_image) {
                return filtered[0].profile_image;
            } else {
                return "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9";
            }
        }
    }

    getSellerRedirect(e) {
        let filtered = this.props.user.filter((g) => {
            return g.user_id === e.created_by;
        });
        if (filtered.length > 0) {
            history.push(`/company/${filtered[0].user_name_id}`)
        }
    }

    getSellerCity(e) {
        let filtered = this.props.user.filter((g) => {
            return g.user_id === e.created_by;
        });
        if (filtered.length > 0) {
            return filtered[0].city;
        }
    }



    checkForBookMarkIconVouchers(g) {
        if (g.bookmarked_by) {
            let objected = Object.entries(
                g.bookmarked_by
            );
            let filtered = objected.filter((g) => {
                return g[1].bookmarked_by === this.state.currentUserId;
            });
            if (filtered.length > 0) {
                return (
                    <div
                        className="icons-lcltsmhnc"
                        onClick={() =>
                            unBookmarkCurrentProduct(g)
                        }
                    >
                        <AiFillStar />
                    </div>
                );
            } else {
                return (
                    <div
                        className="icons-lcltsmhnc"
                        onClick={() => bookmarkCurrentProduct(g)}
                    >
                        <AiOutlineStar />
                    </div>
                );
            }
        } else {
            return (
                <div
                    className="icons-lcltsmhnc"
                    onClick={() => bookmarkCurrentProduct(g)}
                >
                    <AiOutlineStar />
                </div>
            );
        }
    }

    getUseOfVoucherKortingsVoucher(e) {
        if (this.props.user) {
            let filtered = this.props.user.filter((g) => {
                return g.user_id === e.created_by;
            });
            if (filtered.length > 0) {
                if (filtered[0].useOfVoucherKortingsVoucher) {
                    return (
                        <label onClick={() => {
                            this.setState({
                                useOfVouchersPopup: true,
                                currentUseOfVoucher: filtered[0].useOfVoucherKortingsVoucher,
                            });
                        }}
                        >
                            GEBRUIK
                        </label>
                    );
                }
            }
        }
    }

    getUseOfVoucherWebShop(e) {
        if (this.props.user) {
            let filtered = this.props.user.filter((g) => {
                return g.user_id === e.created_by;
            });
            if (filtered.length > 0) {
                if (filtered[0].useOfVoucherWebShop) {
                    return (
                        <label onClick={() => {
                            this.setState({
                                useOfVouchersPopup: true,
                                currentUseOfVoucher: filtered[0].useOfVoucherWebShop,
                            });
                        }}
                        >
                            GEBRUIK
                        </label>
                    );
                }
            }
        }
    }

    getfilelink(e) {
        if (this.props.user) {
            let filtered = this.props.user.filter((g) => {
                return g.user_id === e.created_by;
            });
            if (filtered.length > 0) {
                if (filtered[0].requirement_file) {
                    return (
                        <label
                            onClick={() => {
                                window.open(
                                    filtered[0].requirement_file,
                                    "_blank" // <- This is what makes it open in a new window.
                                );
                            }}
                        >
                            VOORWAARDEN
                        </label>
                    );
                } else if (filtered[0].termsText) {
                    return (
                        <label onClick={() => {
                            this.setState({
                                termsTextPopup: true,
                                currentTermsText: filtered[0].termsText,
                            });
                        }}
                        >
                            VOORWAARDEN
                        </label>
                    );
                }
            }
        }
    }

    checkForVerzilverKortingsVoucher(e, index) {
        if (this.state.currentUserHere) {
            if ((this.state.currentUserHere.subscription && this.state.currentUserHere.subscription.status === "active") || checkForSubscribedExclusives(this.state.currentUserHere)) {
                if (e.verzilvered_by) {
                    let objected = Object.entries(e.verzilvered_by);
                    let filtered = objected.filter((h) => {
                        return h[1].user_id === this.state.currentUserId;
                    });
                    if (filtered.length > 0) {
                        return (
                            <button
                                style={{
                                    backgroundColor: "#C17F6B",
                                    display: "inline-flex",
                                    alignItems: "center",
                                }}
                                className="pinkcolorbutton"
                            >
                                <CheckIcon
                                    style={{ fontSize: 18, marginRight: 5, marginLeft: 0 }}
                                />{" "}
                                {window.innerWidth > 1008 ? `In orderoverzicht` : `Overzicht`}
                            </button>
                        );
                    }
                }
                if (!e.kortingsVoucherUnlimited) {
                    let bewaaredArray = [];
                    let num = 0;
                    if (e.verzilvered_by) {
                        let objectedCheckedIn = Object.entries(e.verzilvered_by);
                        num = this.getVerzilveredQty(objectedCheckedIn);
                    }
                    if (e.inCartOf) {
                        let objectedInCartOf = Object.entries(e.inCartOf);
                        num = num + objectedInCartOf.length;
                    }
                    if (num + bewaaredArray.length < Number(e.number)) {
                        if (window.innerWidth > 1008) {
                            if (e.verzilvered_by) {
                                let objected = Object.entries(e.verzilvered_by);
                                let filtered = objected.filter((h) => {
                                    return h[1].user_id === this.state.currentUserId;
                                });
                                if (filtered.length > 0) {
                                    return (
                                        <button
                                            className="pinkcolorbutton"
                                            style={{
                                                backgroundColor: "#C17F6B",
                                                display: "inline-flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <CheckIcon
                                                style={{ fontSize: 18, marginRight: 5, marginLeft: 0 }}
                                            />{" "}
                                            {window.innerWidth > 1008
                                                ? `In orderoverzicht`
                                                : `Overzicht`}
                                        </button>
                                    );
                                } else {
                                    return (
                                        <button onClick={this.verzilverKortingsVoucher.bind(this, e)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                            Valideren{" "}
                                        </button>
                                    );
                                }
                            } else {
                                return (
                                    <button onClick={this.verzilverKortingsVoucher.bind(this, e)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                        Valideren
                                    </button>
                                );
                            }
                        } else {
                            if (
                                this.state.currentUserHere.stripe_id &&
                                !this.state.userNotSubscribed
                            ) {
                                if (e.verzilvered_by) {
                                    let objected = Object.entries(e.verzilvered_by);
                                    let filtered = objected.filter((h) => {
                                        return h[1].user_id === this.state.currentUserId;
                                    });
                                    if (filtered.length > 0) {
                                        return (
                                            <button
                                                style={{
                                                    backgroundColor: "#C17F6B",
                                                    display: "inline-flex",
                                                    alignItems: "center",
                                                }}
                                                className="pinkcolorbutton"
                                            >
                                                <CheckIcon
                                                    style={{
                                                        fontSize: 18,
                                                        marginRight: 5,
                                                        marginLeft: 0,
                                                    }}
                                                />{" "}
                                                {window.innerWidth > 1008
                                                    ? `In orderoverzicht`
                                                    : `Overzicht`}
                                            </button>
                                        );
                                    } else {
                                        return (
                                            <button onClick={this.verzilverKortingsVoucher.bind(this, e)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                                Valideren{" "}
                                            </button>
                                        );
                                    }
                                } else {
                                    return (
                                        <button onClick={this.verzilverKortingsVoucher.bind(this, e)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                            Valideren{" "}
                                        </button>
                                    );
                                }
                            } else {
                                return (
                                    <button onClick={this.verzilverKortingsVoucher.bind(this, e)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                        Valideren
                                    </button>
                                    // <Link to="/charity" className="link-style">
                                    //   <button
                                    //   // Commented by me
                                    //   //
                                    //   >
                                    //     Register
                                    //   </button>
                                    // </Link>
                                );
                            }
                        }
                    } else {
                        return (
                            <button
                                style={{ backgroundColor: "#C17F6B" }}
                                className="pinkcolorbutton"
                            >
                                Uitverkocht
                            </button>
                        );
                    }
                } else {
                    if (window.innerWidth > 1008) {
                        if (e.verzilvered_by) {
                            let objected = Object.entries(e.verzilvered_by);
                            let filtered = objected.filter((h) => {
                                return h[1].user_id === this.state.currentUserId;
                            });
                            if (filtered.length > 0) {
                                return (
                                    <button
                                        style={{
                                            backgroundColor: "#C17F6B",
                                            display: "inline-flex",
                                            alignItems: "center",
                                        }}
                                        className="pinkcolorbutton"
                                    >
                                        <CheckIcon
                                            style={{ fontSize: 18, marginRight: 5, marginLeft: 0 }}
                                        />{" "}
                                        {window.innerWidth > 1008
                                            ? `In orderoverzicht`
                                            : `Overzicht`}
                                    </button>
                                );
                            } else {
                                return (
                                    <button onClick={this.verzilverKortingsVoucher.bind(this, e)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                        Valideren
                                    </button>
                                );
                            }
                        } else {
                            return (
                                <button onClick={this.verzilverKortingsVoucher.bind(this, e)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                    Valideren
                                </button>
                            );
                        }
                    } else {
                        if (
                            this.state.currentUserHere.stripe_id &&
                            !this.state.userNotSubscribed
                        ) {
                            if (e.verzilvered_by) {
                                let objected = Object.entries(e.verzilvered_by);
                                let filtered = objected.filter((h) => {
                                    return h[1].user_id === this.state.currentUserId;
                                });
                                if (filtered.length > 0) {
                                    return (
                                        <button
                                            style={{
                                                backgroundColor: "#C17F6B",
                                                display: "inline-flex",
                                                alignItems: "center",
                                            }}
                                            className="pinkcolorbutton"
                                        >
                                            <CheckIcon
                                                style={{ fontSize: 18, marginRight: 5, marginLeft: 0 }}
                                            />{" "}
                                            {window.innerWidth > 1008
                                                ? `In orderoverzicht`
                                                : `Overzicht`}
                                        </button>
                                    );
                                } else {
                                    return (
                                        <button onClick={this.verzilverKortingsVoucher.bind(this, e)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                            Valideren{" "}
                                        </button>
                                    );
                                }
                            } else {
                                return (
                                    <button onClick={this.verzilverKortingsVoucher.bind(this, e)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                        Valideren
                                    </button>
                                );
                            }
                        } else {
                            return (
                                <button>
                                    Register
                                </button>
                            );
                        }
                    }
                }
            } else {
                if (this.state.guestUserCart) {
                    let filtered = this.state.guestUserCart.filter((h) => {
                        return h.pushKey === e.pushKey;
                    });
                    if (filtered.length > 0) {
                        // if (!e.kortingsVoucherUnlimited) {
                        //     let num = 0
                        //     if (e.verzilvered_by) {
                        //         let objectedCheckedIn = Object.entries(e.verzilvered_by)
                        //         num = this.getVerzilveredQty(objectedCheckedIn)
                        //     }
                        //     if (num < Number(e.number)) {
                        //         return <button style={{ backgroundColor: "#C17F6B" }} className="pinkcolorbutton">Toegevoegd</button>
                        //     } else {
                        //         return <button style={{ backgroundColor: "#C17F6B" }} className="pinkcolorbutton">Uitverkocht</button>
                        //     }
                        // } else {
                        return (
                            <button
                                style={{ backgroundColor: "#C17F6B" }}
                                className="pinkcolorbutton"
                            >
                                Toegevoegd
                            </button>
                        );
                        // }
                    } else {
                        if (!e.kortingsVoucherUnlimited) {
                            let num = 0;
                            if (e.verzilvered_by) {
                                let objectedCheckedIn = Object.entries(e.verzilvered_by);
                                num = this.getVerzilveredQty(objectedCheckedIn);
                            }
                            if (e.inCartOf) {
                                let objectedInCartOf = Object.entries(e.inCartOf);
                                num = num + objectedInCartOf.length;
                            }
                            if (num < Number(e.number)) {
                                return (
                                    <button onClick={this.addToCartNonUsers.bind(this, e, index)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                        Boek nu
                                    </button>
                                );
                            } else {
                                return (
                                    <button
                                        style={{ backgroundColor: "#C17F6B" }}
                                        className="pinkcolorbutton"
                                    >
                                        Uitverkocht
                                    </button>
                                );
                            }
                        } else {
                            return (
                                <button onClick={this.addToCartNonUsers.bind(this, e, index)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                    Boek nu
                                </button>
                            );
                        }
                    }
                } else {
                    if (!e.kortingsVoucherUnlimited) {
                        let num = 0;
                        if (e.verzilvered_by) {
                            let objectedCheckedIn = Object.entries(e.verzilvered_by);
                            num = this.getVerzilveredQty(objectedCheckedIn);
                        }
                        if (e.inCartOf) {
                            let objectedInCartOf = Object.entries(e.inCartOf);
                            num = num + objectedInCartOf.length;
                        }
                        if (num < Number(e.number)) {
                            return (
                                <button onClick={this.addToCartNonUsers.bind(this, e, index)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                    Boek nu
                                </button>
                            );
                        } else {
                            return (
                                <button
                                    style={{ backgroundColor: "#C17F6B" }}
                                    className="pinkcolorbutton"
                                >
                                    Uitverkocht
                                </button>
                            );
                        }
                    } else {
                        return (
                            <button onClick={this.addToCartNonUsers.bind(this, e, index)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                Boek nu
                            </button>
                        );
                    }
                }
            }
        } else {
            if (this.state.guestUserCart) {
                let filtered = this.state.guestUserCart.filter((h) => {
                    return h.pushKey === e.pushKey;
                });
                if (filtered.length > 0) {
                    // if (!e.kortingsVoucherUnlimited) {
                    //     let num = 0
                    //     if (e.verzilvered_by) {
                    //         let objectedCheckedIn = Object.entries(e.verzilvered_by)
                    //         num = this.getVerzilveredQty(objectedCheckedIn)
                    //     }
                    //     if (num < Number(e.number)) {
                    //         return <button style={{ backgroundColor: "#C17F6B" }} className="pinkcolorbutton">Toegevoegd</button>
                    //     } else {
                    //         return <button style={{ backgroundColor: "#C17F6B" }} className="pinkcolorbutton">Uitverkocht</button>
                    //     }
                    // } else {
                    return (
                        <button
                            style={{ backgroundColor: "#C17F6B" }}
                            className="pinkcolorbutton"
                        >
                            Toegevoegd
                        </button>
                    );
                    // }
                } else {
                    if (!e.kortingsVoucherUnlimited) {
                        let num = 0;
                        if (e.verzilvered_by) {
                            let objectedCheckedIn = Object.entries(e.verzilvered_by);
                            num = this.getVerzilveredQty(objectedCheckedIn);
                        }
                        if (e.inCartOf) {
                            let objectedInCartOf = Object.entries(e.inCartOf);
                            num = num + objectedInCartOf.length;
                        }
                        if (num < Number(e.number)) {
                            return (
                                <button onClick={this.addToCartNonUsers.bind(this, e, index)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                    Boek nu
                                </button>
                            );
                        } else {
                            return (
                                <button
                                    style={{ backgroundColor: "#C17F6B" }}
                                    className="pinkcolorbutton"
                                >
                                    Uitverkocht
                                </button>
                            );
                        }
                    } else {
                        return (
                            <button onClick={this.addToCartNonUsers.bind(this, e, index)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                Boek nu
                            </button>
                        );
                    }
                }
            } else {
                if (!e.kortingsVoucherUnlimited) {
                    let num = 0;
                    if (e.verzilvered_by) {
                        let objectedCheckedIn = Object.entries(e.verzilvered_by);
                        num = this.getVerzilveredQty(objectedCheckedIn);
                    }
                    if (e.inCartOf) {
                        let objectedInCartOf = Object.entries(e.inCartOf);
                        num = num + objectedInCartOf.length;
                    }
                    if (num < Number(e.number)) {
                        return (
                            <button onClick={this.addToCartNonUsers.bind(this, e, index)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                Boek nu
                            </button>
                        );
                    } else {
                        return (
                            <button
                                style={{ backgroundColor: "#C17F6B" }}
                                className="pinkcolorbutton"
                            >
                                Uitverkocht
                            </button>
                        );
                    }
                } else {
                    return (
                        <button onClick={this.addToCartNonUsers.bind(this, e, index)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                            Boek nu
                        </button>
                    );
                }
            }
        }
    }


    verzilverKortingsVoucher(e) {
        if (this.props.user) {
            let filtered = this.props.user.filter((g) => {
                return g.user_id === e.created_by;
            });
            if (filtered.length > 0) {
                this.setState({ currentUser: filtered[0] });
            }
        }
        this.setState({
            verzilverPopup: true,
            currentActie: e,
            currentActieType: "kortings_voucher",
        });
    }


    checkForButtonsVerzilverKortingsVoucher(currentActie) {
        return (
            <div style={{ display: "flex", marginTop: 20, justifyContent: "center" }}>
                {!isDesktop ? (
                    <button
                        style={{ marginRight: 5 }}
                        onClick={this.proceedKortingsVoucher.bind(this)}
                    >
                        OK
                    </button>
                ) : (
                    <button
                        style={{ marginRight: 5 }}
                        onClick={this.proceedKortingsVoucher.bind(this)}
                    >
                        OK
                    </button>
                )}
            </div>
        );
    }

    proceedKortingsVoucher() {
        let order_id = this.state.orderidToUpload + 1;
        firebase.database().ref("current_order_id").set(order_id)
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, "0");
        let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        let yyyy = today.getFullYear();

        today = mm + "/" + dd + "/" + yyyy;
        let arrSellers = [];
        arrSellers.push(this.state.currentUser.user_id);

        if (this.state.currentUser.expireTypeVoucher && this.state.currentUser.expireDateVoucher) {
            this.state.currentActie.expireTypeVoucher = this.state.currentUser.expireTypeVoucher
            this.state.currentActie.expireDateVoucher = this.state.currentUser.expireDateVoucher
        }

        let obj = {
            created_on: today,
            order_id,
            products: [this.state.currentActie],
            buyer: this.state.currentUserId,
            sellers: arrSellers,
            timeStampCreated: new Date().getTime(),
            order_type: "campaign",
        };
        // let pushuserOrderCurrentUser = firebase
        //     .database()
        //     .ref("users/" + this.state.currentUserId + "/orders")
        //     .push().key;
        // firebase
        //     .database()
        //     .ref(
        //         "users/" +
        //         this.state.currentUserId +
        //         "/orders/" +
        //         pushuserOrderCurrentUser
        //     )
        //     .set(obj);
        // let pushuserOrder = firebase
        //     .database()
        //     .ref("users/" + this.state.currentUser.user_id + "/orders")
        //     .push().key;
        // firebase
        //     .database()
        //     .ref(
        //         "users/" + this.state.currentUser.user_id + "/orders/" + pushuserOrder
        //     )
        //     .set(obj);
        let orderKey = firebase.database().ref("all_orders").push().key;
        obj.pushKey = orderKey;
        firebase
            .database()
            .ref("all_orders/" + orderKey)
            .set(obj);
        if (this.state.currentActie.verzilvered_by) {
            let objected = Object.entries(this.state.currentActie.verzilvered_by);
            let filtered = objected.filter((h) => {
                return h[1].user_id === this.state.currentUserId;
            });
            if (filtered.length > 0) {
                let newDate = new Date().getTime();
                let arr = filtered[0][1].allDates;
                arr.push(newDate);
                let pushKey = filtered[0][1].pushKey;
                let obj = {
                    user_id: this.state.currentUserId,
                    date: newDate,
                    allDates: arr,
                    pushKey,
                };
                firebase
                    .database()
                    .ref(
                        "bhodi_campaigns/" +
                        this.state.currentUser.user_id +
                        "/" +
                        this.state.currentActie.pushKey +
                        "/verzilvered_by/" +
                        filtered[0][1].pushKey
                    )
                    .set(obj);
            } else {
                let newDate = new Date().getTime();
                let arr = [];
                arr.push(newDate);
                let pushKey = firebase
                    .database()
                    .ref(
                        "bhodi_campaigns/" +
                        this.state.currentUser.user_id +
                        "/" +
                        this.state.currentActie.pushKey +
                        "/verzilvered_by"
                    )
                    .push().key;
                let obj = {
                    user_id: this.state.currentUserId,
                    date: newDate,
                    allDates: arr,
                    pushKey,
                };
                firebase
                    .database()
                    .ref(
                        "bhodi_campaigns/" +
                        this.state.currentUser.user_id +
                        "/" +
                        this.state.currentActie.pushKey +
                        "/verzilvered_by/" +
                        pushKey
                    )
                    .set(obj);
            }
        } else {
            let newDate = new Date().getTime();
            let arr = [];
            arr.push(newDate);
            let pushKey = firebase
                .database()
                .ref(
                    "bhodi_campaigns/" +
                    this.state.currentUser.user_id +
                    "/" +
                    this.state.currentActie.pushKey +
                    "/verzilvered_by"
                )
                .push().key;
            let obj = {
                user_id: this.state.currentUserId,
                date: newDate,
                allDates: arr,
                pushKey,
            };
            firebase
                .database()
                .ref(
                    "bhodi_campaigns/" +
                    this.state.currentUser.user_id +
                    "/" +
                    this.state.currentActie.pushKey +
                    "/verzilvered_by/" +
                    pushKey
                )
                .set(obj);
        }

        let notificationToShow = document.getElementById("mustShowOnAddOrder");
        if (notificationToShow) {
            notificationToShow.style.opacity = 1;
            notificationToShow.style.pointerEvents = "all";
        }

        setTimeout(() => {
            if (notificationToShow) {
                notificationToShow.style.opacity = 0;
                notificationToShow.style.pointerEvents = "none";
            }
        }, 4000);
        this.setState({ verzilverPopup: false, verzilverPopupGreen: false });
    }


    addToCartNonUsers(e, index) {
        let f = e;
        let imgtodrag = "";

        imgtodrag = document.getElementsByClassName("addIconCart")[index];

        let arrCart = [...this.state.guestUserCart];

        let cookieItem = localStorage.getItem("cookie_item");

        var minutesToAddDB = Number(process.env.REACT_APP_CART_TIME);
        var currentDateDB = new Date();
        var futureDateDB = new Date(
            currentDateDB.getTime() + minutesToAddDB * 60000
        );

        let futureTimeStampDB = Math.floor(futureDateDB.getTime() / 1000);

        let objToMake = {
            cookieItem,
            itemPush: e.pushKey,
            timestamp: futureTimeStampDB,
        };

        if (e.campaignType === "web_shop") {
            const min = 0;
            const max = e.coupons.length;
            const intNumber = Math.floor(Math.random() * (max - min)) + min;
            e.redeemed_code = e.coupons[intNumber];
            objToMake.redeemed_code = e.coupons[intNumber];
        }

        var minutesToAdd = Number(process.env.REACT_APP_CART_TIME);
        var currentDate = new Date();
        var futureDate = new Date(currentDate.getTime() + minutesToAdd * 60000);

        let futureTimeStamp = Math.floor(futureDate.getTime() / 1000);

        arrCart.push(e);

        this.props.updateGuestUserCart(arrCart);
        this.props.updateGuestUserCartTime(futureTimeStamp);
        this.props.updateGuestUserCartExtendOpportunity(true);

        let userid = localStorage.getItem("userid");
        if (userid) {
            objToMake.cookieItem = userid;
            let pushKey = firebase
                .database()
                .ref("guest_carts/" + userid)
                .push().key;
            objToMake.pushKey = pushKey;
            let ownPushKey = firebase
                .database()
                .ref("bhodi_campaigns/" + f.created_by + "/" + f.pushKey + "/inCartOf")
                .push().key;

            objToMake.pushKeyInCartOf = ownPushKey;
            objToMake.creatorCompanyId = f.created_by;

            firebase
                .database()
                .ref("guest_carts/" + userid + "/" + pushKey)
                .set(objToMake);

            let objToOwnPush = {
                cookie: objToMake.cookieItem,
                pushKey: ownPushKey,
                cartPushKey: pushKey,
            };
            firebase
                .database()
                .ref(
                    "bhodi_campaigns/" +
                    f.created_by +
                    "/" +
                    f.pushKey +
                    "/inCartOf/" +
                    ownPushKey
                )
                .set(objToOwnPush);
        } else {
            if (cookieItem) {
                let pushKey = firebase
                    .database()
                    .ref("guest_carts/" + cookieItem)
                    .push().key;
                objToMake.pushKey = pushKey;

                let ownPushKey = firebase
                    .database()
                    .ref(
                        "bhodi_campaigns/" + f.created_by + "/" + f.pushKey + "/inCartOf"
                    )
                    .push().key;

                objToMake.pushKeyInCartOf = ownPushKey;
                objToMake.creatorCompanyId = f.created_by;

                firebase
                    .database()
                    .ref("guest_carts/" + cookieItem + "/" + pushKey)
                    .set(objToMake);
                let objToOwnPush = {
                    cookie: objToMake.cookieItem,
                    pushKey: ownPushKey,
                    cartPushKey: pushKey,
                };
                firebase
                    .database()
                    .ref(
                        "bhodi_campaigns/" +
                        f.created_by +
                        "/" +
                        f.pushKey +
                        "/inCartOf/" +
                        ownPushKey
                    )
                    .set(objToOwnPush);
            }
        }

        let disLeft = imgtodrag.getBoundingClientRect().left;
        let disTop = imgtodrag.getBoundingClientRect().top;
        let viewcart = document.getElementsByClassName("cart-icon")[0];
        let image = imgtodrag.cloneNode(true);

        let cartleft = viewcart.getBoundingClientRect().left;
        let carttop = viewcart.getBoundingClientRect().top;

        image.style =
            "z-index: 99999999999; width: 30px;opacity:0.8; position:fixed; top:" +
            disTop +
            "px;left:" +
            disLeft +
            "px;transition: left 2s, top 2s, width 2s, opacity 2s cubic-bezier(1, 1, 1, 1)";

        var rechange = document.body.appendChild(image);
        setTimeout(function () {
            image.style.left = cartleft + "px";
            image.style.top = carttop + "px";
            image.style.width = "30px";
            image.style.opacity = "0";
        }, 200);
        setTimeout(function () {
            rechange.parentNode.removeChild(rechange);
        }, 2000);

        let notificationToShow = document.getElementById("mustShowOnAddBasket");
        if (notificationToShow) {
            notificationToShow.style.opacity = 1;
            notificationToShow.style.pointerEvents = "all";
        }

        setTimeout(() => {
            if (notificationToShow) {
                notificationToShow.style.opacity = 0;
                notificationToShow.style.pointerEvents = "none";
            }
        }, 4000);
    }


    getLocalEventSubscribeButton(event) {
        let currentLocalEvent = event;
        let localEventIfSharedThen = "";
        if (!currentLocalEvent.localEventIfSharedThen) {
            localEventIfSharedThen = currentLocalEvent;
        } else {
            localEventIfSharedThen = currentLocalEvent.localEventIfSharedThen;
        }
        let alreadyInCart = false;

        if (localEventIfSharedThen.inCartOf) {
            let objected = Object.entries(localEventIfSharedThen.inCartOf);
            objected = objected.filter((t) => {
                return t[1].event_id === currentLocalEvent.timings[currentLocalEvent.selectedTimeIndex].eventId
            })
            let cookieItem = localStorage.getItem("cookie_item");
            let filtered = objected.filter((h) => {
                if (this.state.currentUserId) {
                    return h[1].cookie === this.state.currentUserId && h[1].event_id === event.reserved_event_id;
                } else {
                    return h[1].cookie === cookieItem && h[1].event_id === event.reserved_event_id;
                }
            });
            if (filtered.length > 0) {
                alreadyInCart = true;
            }
        }
        if (alreadyInCart) {
            return (
                <button
                    style={{ backgroundColor: "#C17F6B" }}
                    className="pinkcolorbutton"
                >
                    <a>Toegevoegd</a>
                </button>
            );
        } else {
            let qtyToMinus = 0;
            if (localEventIfSharedThen.inCartOf) {
                let objected = Object.entries(localEventIfSharedThen.inCartOf);
                objected = objected.filter((t) => {
                    return t[1].event_id === currentLocalEvent.timings[currentLocalEvent.selectedTimeIndex].eventId
                })
                qtyToMinus = qtyToMinus + objected.length;
            }
            if (localEventIfSharedThen.checked_in_by) {
                let objected = Object.entries(localEventIfSharedThen.checked_in_by);
                objected = objected.filter((t) => {
                    return t[1].event_id === currentLocalEvent.timings[currentLocalEvent.selectedTimeIndex].eventId
                })
                qtyToMinus = qtyToMinus + objected.length;
            }
            if (currentLocalEvent.timings && currentLocalEvent.timings.length > 0) {
                if (
                    Number(currentLocalEvent.timings[currentLocalEvent.selectedTimeIndex].personsAmountEvent) -
                    qtyToMinus <=
                    0
                ) {
                    return (
                        <button style={{ backgroundColor: "#C17F6B", color: "white" }}>
                            Uitverkocht
                        </button>
                    );
                }
            }
            return (
                <>
                    <button
                        onClick={this.addToCartLocalEvent.bind(this, currentLocalEvent)} className={window.innerWidth < 1008 && "pink-color-btndcw"}
                    >
                        <a>{currentLocalEvent.created_by === this.state.currentUserId ? "Boek nu" : `Boek nu`}</a>
                    </button>
                    <div className="image-front addIconCart">
                        <AddIcon />
                    </div>
                </>
            );
        }
    }

    addToCartLocalEvent(event) {
        let currentLocalEvent = event;
        let localEventIfSharedThen = "";
        if (!currentLocalEvent.localEventIfSharedThen) {
            localEventIfSharedThen = currentLocalEvent;
        } else {
            localEventIfSharedThen = currentLocalEvent.localEventIfSharedThen;
        }

        let e = localEventIfSharedThen;

        let findIndexCurrent = this.state.filteredProductsToShow.findIndex((t) => {
            return t.pushKey === event.pushKey
        })

        let indexDefault = 0
        if (findIndexCurrent !== -1) {
            indexDefault = findIndexCurrent
        }

        let imgtodrag = document.getElementsByClassName("addIconCart")[findIndexCurrent];
        let disLeft = imgtodrag.getBoundingClientRect().left;
        let disTop = imgtodrag.getBoundingClientRect().top;
        let viewcart = document.getElementsByClassName("cart-icon")[0];
        let image = imgtodrag.cloneNode(true);

        let cartleft = viewcart.getBoundingClientRect().left;
        let carttop = viewcart.getBoundingClientRect().top;

        image.style =
            "z-index: 99999999999; width: 30px;opacity:0.8; position:fixed; top:" +
            disTop +
            "px;left:" +
            disLeft +
            "px;transition: left 2s, top 2s, width 2s, opacity 2s cubic-bezier(1, 1, 1, 1)";

        var rechange = document.body.appendChild(image);
        setTimeout(function () {
            image.style.left = cartleft + "px";
            image.style.top = carttop + "px";
            image.style.width = "30px";
            image.style.opacity = "0";
        }, 200);
        setTimeout(function () {
            rechange.parentNode.removeChild(rechange);
        }, 2000);

        let arrCart = [...this.state.guestUserCart];
        let cookieItem = localStorage.getItem("userid");

        var minutesToAddDB = Number(process.env.REACT_APP_CART_TIME);
        var currentDateDB = new Date();
        var futureDateDB = new Date(
            currentDateDB.getTime() + minutesToAddDB * 60000
        );

        let futureTimeStampDB = Math.floor(futureDateDB.getTime() / 1000);

        let objToMake = {
            cookieItem,
            itemPush: e.pushKey,
            timestamp: futureTimeStampDB,
            event_id: event.reserved_event_id,
        };

        let pushKeyInCartOf = firebase
            .database()
            .ref(
                `bhodi_campaigns/${event.created_by}/${localEventIfSharedThen.pushKey}/inCartOf`
            )
            .push().key;
        let objInCartOf = {
            cookie: cookieItem,
            pushKey: pushKeyInCartOf,
            event_id: event.reserved_event_id,
        };

        objToMake.pushKeyInCartOf = pushKeyInCartOf;
        objToMake.creatorCompanyId = event.created_by;

        firebase
            .database()
            .ref(
                `bhodi_campaigns/${event.created_by}/${localEventIfSharedThen.pushKey}/inCartOf/${pushKeyInCartOf}`
            )
            .set(objInCartOf);

        var minutesToAdd = Number(process.env.REACT_APP_CART_TIME);
        var currentDate = new Date();
        var futureDate = new Date(currentDate.getTime() + minutesToAdd * 60000);

        let futureTimeStamp = Math.floor(futureDate.getTime() / 1000);

        arrCart.push(e);

        this.props.updateGuestUserCart(arrCart);
        this.props.updateGuestUserCartTime(futureTimeStamp);
        this.props.updateGuestUserCartExtendOpportunity(true);

        let userid = localStorage.getItem("userid");
        if (userid) {
            objToMake.cookieItem = userid;
            let pushKey = firebase
                .database()
                .ref("guest_carts/" + userid)
                .push().key;
            objToMake.pushKey = pushKey;
            firebase
                .database()
                .ref("guest_carts/" + userid + "/" + pushKey)
                .set(objToMake);
        }

        let notificationToShow = document.getElementById("mustShowOnAddBasket");
        if (notificationToShow) {
            notificationToShow.style.opacity = 1;
            notificationToShow.style.pointerEvents = "all";
        }

        setTimeout(() => {
            if (notificationToShow) {
                notificationToShow.style.opacity = 0;
                notificationToShow.style.pointerEvents = "none";
            }
        }, 4000);
    }

    getWinkelmandbuttonUsersLocalBlock(event) {
        let currentLocalEvent = event;
        let localEventIfSharedThen = "";

        if (!currentLocalEvent.localEventIfSharedThen) {
            localEventIfSharedThen = currentLocalEvent;
        } else {
            localEventIfSharedThen = currentLocalEvent.localEventIfSharedThen;
        }
        let e = localEventIfSharedThen;
        if (this.state.currentUserHere) {
            if (
                ((this.state.currentUserHere.subscription && this.state.currentUserHere.subscription.status === "active") || checkForSubscribedExclusives(this.state.currentUserHere))
            ) {
                if (window.innerWidth > 1009) {
                    let added_via_payment_link = []
                    if (e.added_via_payment_link) {
                        added_via_payment_link = Object.entries(e.added_via_payment_link)
                    }
                    if (e.checked_in_by) {
                        let objected = Object.entries(e.checked_in_by);
                        if (added_via_payment_link.length > 0) {
                            objected.concat(added_via_payment_link)
                        }
                        let filtered = objected.filter((h) => {
                            return h[1].user_id === this.state.currentUserId && h[1].event_id === event.reserved_event_id;
                        });
                        if (filtered.length > 0) {
                            return (
                                <button
                                    style={{ backgroundColor: "#C17F6B" }}
                                    className="pink-color-btndcw"
                                >
                                    <a>{window.innerWidth > 1008 ? `In Overzicht` : `Overzicht`}</a>
                                </button>
                            );
                        } else {
                            let qtyToMinus = 0;
                            if (localEventIfSharedThen.inCartOf) {
                                let objected = Object.entries(localEventIfSharedThen.inCartOf);
                                objected = objected.filter((t) => {
                                    return t[1].event_id === currentLocalEvent.timings[currentLocalEvent.selectedTimeIndex].eventId
                                })
                                qtyToMinus = qtyToMinus + objected.length;
                            }
                            if (localEventIfSharedThen.checked_in_by) {
                                let objected = Object.entries(
                                    localEventIfSharedThen.checked_in_by
                                );
                                objected = objected.filter((t) => {
                                    return t[1].event_id === currentLocalEvent.timings[currentLocalEvent.selectedTimeIndex].eventId
                                })
                                qtyToMinus = qtyToMinus + objected.length;
                            }
                            if (
                                currentLocalEvent.timings &&
                                currentLocalEvent.timings.length > 0
                            ) {
                                if (
                                    Number(currentLocalEvent.timings[currentLocalEvent.selectedTimeIndex].personsAmountEvent) -
                                    qtyToMinus <=
                                    0
                                ) {
                                    return (
                                        <button
                                            style={{ backgroundColor: "#C17F6B", color: "white" }}
                                        >
                                            Uitverkocht
                                        </button>
                                    );
                                }
                            }
                            return (
                                <button
                                    onClick={this.verzilverLocalBlock.bind(this, currentLocalEvent)} className={window.innerWidth < 1008 && "pink-color-btndcw"}
                                >
                                    <a>
                                        Boek nu {window.innerWidth < 1008 && <ChevronRightIcon />}
                                    </a>
                                    <div className="image-local-event addIconCart">
                                        <AddIcon />
                                    </div>
                                </button>
                            );
                        }
                    } else {
                        let qtyToMinus = 0;
                        if (localEventIfSharedThen.inCartOf) {
                            let objected = Object.entries(localEventIfSharedThen.inCartOf);
                            objected = objected.filter((t) => {
                                return t[1].event_id === currentLocalEvent.timings[currentLocalEvent.selectedTimeIndex].eventId
                            })
                            qtyToMinus = qtyToMinus + objected.length;
                        }
                        if (localEventIfSharedThen.checked_in_by) {
                            let objected = Object.entries(
                                localEventIfSharedThen.checked_in_by
                            );
                            objected = objected.filter((t) => {
                                return t[1].event_id === currentLocalEvent.timings[currentLocalEvent.selectedTimeIndex].eventId
                            })
                            qtyToMinus = qtyToMinus + objected.length;
                        }
                        if (
                            currentLocalEvent.timings &&
                            currentLocalEvent.timings.length > 0
                        ) {
                            if (
                                Number(currentLocalEvent.timings[currentLocalEvent.selectedTimeIndex].personsAmountEvent) -
                                qtyToMinus <=
                                0
                            ) {
                                return (
                                    <button
                                        style={{ backgroundColor: "#C17F6B", color: "white" }}
                                    >
                                        Uitverkocht
                                    </button>
                                );
                            }
                        }
                        return (
                            <button
                                onClick={this.verzilverLocalBlock.bind(this, currentLocalEvent)} className={window.innerWidth < 1008 && "pink-color-btndcw"}
                            >
                                <a>
                                    Boek nu {window.innerWidth < 1008 && <ChevronRightIcon />}
                                </a>
                                <div className="image-local-event addIconCart">
                                    <AddIcon />
                                </div>
                            </button>
                        );
                    }
                } else {
                    if (
                        this.state.currentUserHere.stripe_id &&
                        !this.state.userNotSubscribed
                    ) {
                        let added_via_payment_link = []
                        if (e.added_via_payment_link) {
                            added_via_payment_link = Object.entries(e.added_via_payment_link)
                        }
                        if (e.checked_in_by) {
                            let objected = Object.entries(e.checked_in_by);
                            if (added_via_payment_link.length > 0) {
                                objected.concat(added_via_payment_link)
                            }
                            let filtered = objected.filter((h) => {
                                return h[1].user_id === this.state.currentUserId && h[1].event_id === event.reserved_event_id;
                            });
                            if (filtered.length > 0) {
                                return (
                                    <button
                                        style={{ backgroundColor: "#C17F6B" }}
                                        className="pink-color-btndcw"
                                    >
                                        <a>{window.innerWidth > 1008 ? `In orderoverzicht` : `Overzicht`}</a>
                                        {/* <a>In orderoverzicht</a> */}
                                    </button>
                                );
                            } else {
                                return (
                                    <button
                                        onClick={this.verzilverLocalBlock.bind(
                                            this,
                                            currentLocalEvent
                                        )} className={window.innerWidth < 1008 && "pink-color-btndcw"}
                                    >
                                        <a>
                                            Boek nu {window.innerWidth < 1008 && <ChevronRightIcon />}
                                        </a>
                                        <div className="image-local-event addIconCart">
                                            <AddIcon />
                                        </div>
                                    </button>
                                );
                            }
                        } else {
                            return (
                                <button
                                    onClick={this.verzilverLocalBlock.bind(this, currentLocalEvent)} className={window.innerWidth < 1008 && "pink-color-btndcw"}
                                >
                                    <a>
                                        Boek nu {window.innerWidth < 1008 && <ChevronRightIcon />}
                                    </a>
                                    <div className="image-local-event addIconCart">
                                        <AddIcon />
                                    </div>
                                </button>
                            );
                        }
                    } else {
                        return (
                            <button onClick={() => (window.location.href = "/charity")}>
                                Register
                            </button>
                        );
                    }
                }
            }
        }
    }

    verzilverLocalBlock(event) {
        if (this.props.user) {
            let filtered = this.props.user.filter((g) => {
                return g.user_id === event.created_by;
            });
            if (filtered.length > 0) {
                this.setState({ currentUser: filtered[0] });
            }
        }

        if (document.querySelector(".nav-clgd")) {
            document.querySelectorAll(".nav-clgd").forEach((el) => {
                el.style.opacity = 0
            })
            document.querySelectorAll(".nav-clgd").forEach((el) => {
                el.style.pointerEvents = "none"
            })
        }

        this.setState({
            verzilverPopupLocalEvent: true,
            currentEventPopup: event,
        });
    }

    proceedLocalBlock(event) {
        let currentLocalEvent = event;
        let localEventIfSharedThen = "";
        if (!currentLocalEvent.localEventIfSharedThen) {
            localEventIfSharedThen = currentLocalEvent;
        } else {
            localEventIfSharedThen = currentLocalEvent.localEventIfSharedThen;
        }

        let order_id = this.state.orderidToUpload + 1;
        firebase.database().ref("current_order_id").set(order_id)
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, "0");
        let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        let yyyy = today.getFullYear();

        today = mm + "/" + dd + "/" + yyyy;
        let arrSellers = [];
        arrSellers.push(this.state.currentUser.user_id);

        currentLocalEvent.reserved_event_id = event.reserved_event_id;

        let obj = {
            created_on: today,
            order_id,
            products: [currentLocalEvent],
            buyer: this.state.currentUserId,
            sellers: arrSellers,
            timeStampCreated: new Date().getTime(),
            order_type: "campaign",
        };
        // let pushuserOrderCurrentUser = firebase
        //     .database()
        //     .ref("users/" + this.state.currentUserId + "/orders")
        //     .push().key;
        // firebase
        //     .database()
        //     .ref(
        //         "users/" +
        //         this.state.currentUserId +
        //         "/orders/" +
        //         pushuserOrderCurrentUser
        //     )
        //     .set(obj);
        // let pushuserOrder = firebase
        //     .database()
        //     .ref("users/" + this.state.currentUser.user_id + "/orders")
        //     .push().key;
        // firebase
        //     .database()
        //     .ref(
        //         "users/" + this.state.currentUser.user_id + "/orders/" + pushuserOrder
        //     )
        //     .set(obj);
        let orderKey = firebase.database().ref("all_orders").push().key;
        obj.pushKey = orderKey;

        if (obj.products[0].campaignType === "local_event" && !obj.products[0].manual_code_import) {
            let toSendEmailkey = firebase.database().ref("events_for_emails").push().key;
            obj.toSendEmailkey = toSendEmailkey;


            let objToSendEmailValidate = {
                orderKey,
                pushKey: toSendEmailkey,
                created_at: new Date().toISOString(),
                event_key: obj.products[0].pushKey,
                buyer_id: obj.buyer,
            }

            if (obj.products[0].timings) {
                if (
                    obj.products[0].timings.length > 0
                ) {
                    let filtered = obj.products[0].timings.filter((j) => {
                        return j.eventId === obj.products[0].reserved_event_id;
                    });
                    if (filtered.length > 0) {
                        let dateMax = new Date(filtered[0].startDateEvent);
                        if (filtered[0].isoStartTime && filtered[0].isoEndTime) {
                            dateMax = new Date(Date.parse(filtered[0].isoStartTime))
                        } else {
                            let endTimeGood = filtered[0].startTimeEvent.concat(":00");
                            dateMax.setHours(endTimeGood.split(":")[0]);
                            dateMax.setMinutes(endTimeGood.split(":")[1]);
                        }
                        objToSendEmailValidate.send_email_at = dateMax.toISOString()

                        firebase.database().ref("events_for_emails/" + toSendEmailkey).set(objToSendEmailValidate)
                    }
                }
            }
        }

        firebase
            .database()
            .ref("all_orders/" + orderKey)
            .set(obj);

        let newDate = new Date().getTime();
        let arr = [];
        arr.push(newDate);
        let pushKey = firebase
            .database()
            .ref(
                "bhodi_campaigns/" +
                this.state.currentUser.user_id +
                "/" +
                localEventIfSharedThen.pushKey +
                "/checked_in_by"
            )
            .push().key;
        let objCheckedIn = {
            user_id: this.state.currentUserId,
            date: newDate,
            allDates: arr,
            pushKey,
            event_id: event.reserved_event_id,
            order_key: orderKey,
        };
        firebase
            .database()
            .ref(
                "bhodi_campaigns/" +
                this.state.currentUser.user_id +
                "/" +
                localEventIfSharedThen.pushKey +
                "/checked_in_by/" +
                pushKey
            )
            .set(objCheckedIn);

        let notificationToShow = document.getElementById("mustShowOnAddOrder");
        if (notificationToShow) {
            notificationToShow.style.opacity = 1;
            notificationToShow.style.pointerEvents = "all";
        }

        setTimeout(() => {
            if (notificationToShow) {
                notificationToShow.style.opacity = 0;
                notificationToShow.style.pointerEvents = "none";
            }
        }, 4000);
        // if (!isDesktop) {
        //     this.setState({ verzilverPopup: false, verzilverPopupGreen: true })
        // } else {
        //     this.setState({ verzilverPopup: false, verzilverPopupGreen: false })
        // }
        if (document.querySelector(".nav-clgd")) {
            document.querySelectorAll(".nav-clgd").forEach((el) => {
                el.style.opacity = 1
            })
            document.querySelectorAll(".nav-clgd").forEach((el) => {
                el.style.pointerEvents = "all"
            })
        }
        this.setState({ verzilverPopup: false, verzilverPopupGreen: false, verzilverPopupLocalEvent: false, verzilverPopupGreenLocalEvent: true });
    }

    getSectorName() {
        if (this.state.allSectors.length > 0) {
            let filtered = this.state.allSectors.filter((t) => {
                return t.sector_id === this.props.currentSector;
            });
            return filtered[0].sector_name;
        }
    }

    checkForBookMarkIconVouchers(g) {
        if (g.bookmarked_by) {
            let objected = Object.entries(
                g.bookmarked_by
            );
            let filtered = objected.filter((g) => {
                return g[1].bookmarked_by === this.state.currentUserId;
            });
            if (filtered.length > 0) {
                return (
                    <div
                        className="icons-lcltsmhnc"
                        onClick={() =>
                            unBookmarkCurrentProduct(g)
                        }
                    >
                        <AiFillStar />
                    </div>
                );
            } else {
                return (
                    <div
                        className="icons-lcltsmhnc"
                        onClick={() => bookmarkCurrentProduct(g)}
                    >
                        <AiOutlineStar />
                    </div>
                );
            }
        } else {
            return (
                <div
                    className="icons-lcltsmhnc"
                    onClick={() => bookmarkCurrentProduct(g)}
                >
                    <AiOutlineStar />
                </div>
            );
        }
    }


    getUseOfVoucherlocalEvent(e) {
        if (this.props.user) {
            let filtered = this.props.user.filter((g) => {
                return g.user_id === e.created_by;
            });
            if (filtered.length > 0) {
                if (filtered[0].useOfVoucherLocalEvent) {
                    return (
                        <label onClick={() => {
                            this.setState({
                                useOfVouchersPopup: true,
                                currentUseOfVoucher: filtered[0].useOfVoucherLocalEvent,
                            });
                        }}
                        >
                            GEBRUIK
                        </label>
                    );
                }
            }
        }
    }

    getStartTimeOfReservedEvent(event) {
        if (event) {
            if (event.campaignType === "local_event") {
                let filtered = event.timings
                if (filtered.length > 0) {
                    return filtered[event.selectedTimeIndex].startTimeEvent;
                }
            }
        }
    }

    getEndTimeOfReservedEvent(event) {
        if (event) {
            if (event.campaignType === "local_event") {
                let filtered = event.timings
                if (filtered.length > 0) {
                    return filtered[event.selectedTimeIndex].endTimeEvent;
                }
            }
        }
    }


    getMonthName(i) {
        i = Number(i);
        if (i === 1) {
            return "Januari";
        } else if (i === 2) {
            return "Februari";
        } else if (i === 3) {
            return "Maart";
        } else if (i === 4) {
            return "April";
        } else if (i === 5) {
            return "Mei";
        } else if (i === 6) {
            return "Juni";
        } else if (i === 7) {
            return "Juli";
        } else if (i === 8) {
            return "Augustus";
        } else if (i === 9) {
            return "September";
        } else if (i === 10) {
            return "Oktober";
        } else if (i === 11) {
            return "November";
        } else if (i === 12) {
            return "December";
        }
    }

    getFormattedStartDateLocalEvent(e) {
        if (e.timings && e.timings.length > 0) {
            let currentTimings = e.timings[0]
            let date = new Date(
                currentTimings.isoStartTime ? currentTimings.isoStartTime : currentTimings.startDateEvent
            );
            let endDate = new Date(
                currentTimings.isoEndTime ? currentTimings.isoEndTime : currentTimings.endDateEvent
            );
            if (date.getMonth() === endDate.getMonth()) {
                if (date.getDate() === endDate.getDate()) {
                    return `${date.getDate()} ${this.getMonthName(
                        date.getMonth() + 1
                    )} 
                    ${(currentTimings.startTimeEvent && currentTimings.endTimeEvent && !e.takeFromAdmin) ? `| ${currentTimings.startTimeEvent}-${currentTimings.endTimeEvent} uur` : ""} 
                    ${!e.takeFromAdmin ? "|" : ""} ${date.getFullYear()}`;
                } else {
                    return `${date.getDate()} t/m ${endDate.getDate()} ${this.getMonthName(
                        date.getMonth() + 1
                    )} 
                    ${(currentTimings.startTimeEvent && currentTimings.endTimeEvent && !e.takeFromAdmin) ? `| ${currentTimings.startTimeEvent}-${currentTimings.endTimeEvent} uur` : ""} 
                    ${!e.takeFromAdmin ? "|" : ""} ${date.getFullYear()}`;
                }
            } else {
                return `${date.getDate()} ${this.getMonthName(
                    date.getMonth() + 1
                )} t/m ${endDate.getDate()} ${this.getMonthName(
                    endDate.getMonth() + 1
                )} 
                 ${(currentTimings.startTimeEvent && currentTimings.endTimeEvent && !e.takeFromAdmin) ? `| ${currentTimings.startTimeEvent}-${currentTimings.endTimeEvent} uur` : ""} 
                ${!e.takeFromAdmin ? "|" : ""} ${date.getFullYear()}`;
            }
        } else {
            return ""
        }
    }

    getFormattedDateWebShop(e) {
        let currentTimings = e
        let date = new Date(
            currentTimings.isoStartTime ? currentTimings.isoStartTime : currentTimings.startDateEvent
        );
        let endDate = new Date(
            currentTimings.isoEndTime ? currentTimings.isoEndTime : currentTimings.endDateEvent
        );
        if (date.getMonth() === endDate.getMonth()) {
            if (date.getDate() === endDate.getDate()) {
                return `${date.getDate()} ${this.getMonthName(
                    date.getMonth() + 1
                )} 
                ${(currentTimings.voucherStartTime && currentTimings.voucherEndTime) && `| ${currentTimings.voucherStartTime}-${currentTimings.voucherEndTime} uur`} 
                | ${date.getFullYear()}`;
            } else {
                return `${date.getDate()} t/m ${endDate.getDate()} ${this.getMonthName(
                    date.getMonth() + 1
                )} 
                ${(currentTimings.voucherStartTime && currentTimings.voucherEndTime) && `| ${currentTimings.voucherStartTime}-${currentTimings.voucherEndTime} uur`} 
                | ${date.getFullYear()}`;
            }
        } else {
            return `${date.getDate()} ${this.getMonthName(
                date.getMonth() + 1
            )} t/m ${endDate.getDate()} ${this.getMonthName(
                endDate.getMonth() + 1
            )} 
             ${(currentTimings.voucherStartTime && currentTimings.voucherEndTime) && `| ${currentTimings.voucherStartTime}-${currentTimings.voucherEndTime} uur`} 
            | ${date.getFullYear()}`;
        }
    }

    truncateAndSanitize(html, maxLength) {
        // Use DOMPurify to sanitize the HTML content.
        const sanitizedHTML = DOMPurify.sanitize(html);

        if (sanitizedHTML.length <= maxLength) {
            return sanitizedHTML;
        } else {
            return sanitizedHTML.substring(0, maxLength) + '...';
        }
    };

    createMarkup(e) {
        if (e) {
            const truncatedDescription = this.truncateAndSanitize(e, 130);

            return { __html: truncatedDescription };
        }
    }

    createDescPopup(e) {
        if (e) {
            return { __html: e };
        }
    }


    // webshop 


    checkForVerzilverButton(e, index) {
        if (this.state.currentUserHere) {
            if ((this.state.currentUserHere.subscription && this.state.currentUserHere.subscription.status === "active") || checkForSubscribedExclusives(this.state.currentUserHere)) {
                if (e.verzilvered_by) {
                    let objected = Object.entries(e.verzilvered_by);
                    let filtered = objected.filter((h) => {
                        return h[1].user_id === this.state.currentUserId;
                    });
                    if (filtered.length > 0) {
                        return (
                            <button
                                style={{
                                    backgroundColor: "#C17F6B",
                                    display: "inline-flex",
                                    alignItems: "center",
                                }}
                                className="pinkcolorbutton"
                            >
                                <CheckIcon
                                    style={{ fontSize: 18, marginRight: 5, marginLeft: 0 }}
                                />{" "}
                                {window.innerWidth > 1008 ? `In orderoverzicht` : `Overzicht`}
                            </button>
                        );
                    }
                }
                if (!e.voucherUnlimited) {
                    let bewaaredArray = [];
                    let num = 0;
                    if (e.verzilvered_by) {
                        let objectedCheckedIn = Object.entries(e.verzilvered_by);
                        num = this.getVerzilveredQty(objectedCheckedIn);
                    }
                    if (e.inCartOf) {
                        let objectedInCartOf = Object.entries(e.inCartOf);
                        num = num + objectedInCartOf.length;
                    }
                    if (num + bewaaredArray.length < Number(e.number)) {
                        if (window.innerWidth > 1008) {
                            if (e.verzilvered_by) {
                                let objected = Object.entries(e.verzilvered_by);
                                let filtered = objected.filter((h) => {
                                    return h[1].user_id === this.state.currentUserId;
                                });
                                if (filtered.length > 0) {
                                    return (
                                        <button
                                            className="pinkcolorbutton"
                                            style={{
                                                backgroundColor: "#C17F6B",
                                                display: "inline-flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <CheckIcon
                                                style={{ fontSize: 18, marginRight: 5, marginLeft: 0 }}
                                            />{" "}
                                            {window.innerWidth > 1008 ? `In orderoverzicht` : `Overzicht`}
                                        </button>
                                    );
                                }
                            }

                            return (
                                <button onClick={this.verzilverVoucher.bind(this, e)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                    Valideren
                                </button>
                            );
                        } else {
                            if (
                                this.state.currentUserHere.stripe_id &&
                                !this.state.userNotSubscribed
                            ) {
                                if (e.verzilvered_by) {
                                    let objected = Object.entries(e.verzilvered_by);
                                    let filtered = objected.filter((h) => {
                                        return h[1].user_id === this.state.currentUserId;
                                    });
                                    if (filtered.length > 0) {
                                        return (
                                            <button
                                                style={{
                                                    backgroundColor: "#C17F6B",
                                                    display: "inline-flex",
                                                    alignItems: "center",
                                                }}
                                                className="pinkcolorbutton"
                                            >
                                                <CheckIcon
                                                    style={{
                                                        fontSize: 18,
                                                        marginRight: 5,
                                                        marginLeft: 0,
                                                    }}
                                                />{" "}
                                                {window.innerWidth > 1008
                                                    ? `In orderoverzicht`
                                                    : `Overzicht`}
                                            </button>
                                        );
                                    }
                                }
                                return (
                                    <button onClick={this.verzilverVoucher.bind(this, e)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                        Valideren{" "}
                                    </button>
                                );
                            } else {
                                return (
                                    <button onClick={this.verzilverVoucher.bind(this, e)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                        Valideren
                                    </button>
                                );
                            }
                        }
                    } else {
                        return (
                            <button
                                style={{ backgroundColor: "#C17F6B" }}
                                className="pinkcolorbutton"
                            >
                                Uitverkocht
                            </button>
                        );
                    }
                } else {
                    if (window.innerWidth > 1008) {
                        if (e.verzilvered_by) {
                            let objected = Object.entries(e.verzilvered_by);
                            let filtered = objected.filter((h) => {
                                return h[1].user_id === this.state.currentUserId;
                            });
                            if (filtered.length > 0) {
                                return (
                                    <button
                                        style={{
                                            backgroundColor: "#C17F6B",
                                            display: "inline-flex",
                                            alignItems: "center",
                                        }}
                                        className="pinkcolorbutton"
                                    >
                                        <CheckIcon
                                            style={{ fontSize: 18, marginRight: 5, marginLeft: 0 }}
                                        />{" "}
                                        {window.innerWidth > 1008
                                            ? `In orderoverzicht`
                                            : `Overzicht`}
                                    </button>
                                );
                            }
                        }
                        return (
                            <button onClick={this.verzilverVoucher.bind(this, e)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                Valideren
                            </button>
                        );
                    } else {
                        if (
                            this.state.currentUserHere.stripe_id &&
                            !this.state.userNotSubscribed
                        ) {
                            if (e.verzilvered_by) {
                                let objected = Object.entries(e.verzilvered_by);
                                let filtered = objected.filter((h) => {
                                    return h[1].user_id === this.state.currentUserId;
                                });
                                if (filtered.length > 0) {
                                    return (
                                        <button
                                            style={{
                                                backgroundColor: "#C17F6B",
                                                display: "inline-flex",
                                                alignItems: "center",
                                            }}
                                            className="pinkcolorbutton"
                                        >
                                            <CheckIcon
                                                style={{ fontSize: 18, marginRight: 5, marginLeft: 0 }}
                                            />{" "}
                                            {window.innerWidth > 1008
                                                ? `In orderoverzicht`
                                                : `Overzicht`}
                                        </button>
                                    );
                                }
                            }
                            return (
                                <button onClick={this.verzilverVoucher.bind(this, e)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                    Valideren
                                </button>
                            );
                        } else {
                            return (
                                <button>
                                    Register
                                </button>
                            );
                        }
                    }
                }
            } else {
                if (this.state.guestUserCart) {
                    let filtered = this.state.guestUserCart.filter((h) => {
                        return h.pushKey === e.pushKey;
                    });
                    if (filtered.length > 0) {
                        return (
                            <button
                                style={{ backgroundColor: "#C17F6B" }}
                                className="pinkcolorbutton"
                            >
                                Toegevoegd
                            </button>
                        );
                    } else {
                        if (!e.voucherUnlimited) {
                            let num = 0;
                            if (e.verzilvered_by) {
                                let objectedCheckedIn = Object.entries(e.verzilvered_by);
                                num = this.getVerzilveredQty(objectedCheckedIn);
                            }
                            if (e.inCartOf) {
                                let objectedInCartOf = Object.entries(e.inCartOf);
                                num = num + objectedInCartOf.length;
                            }
                            if (num < Number(e.number)) {
                                return (
                                    <button onClick={this.addToCartNonUsers.bind(this, e, index)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                        Boek nu
                                    </button>
                                );
                            } else {
                                return (
                                    <button
                                        style={{ backgroundColor: "#C17F6B" }}
                                        className="pinkcolorbutton"
                                    >
                                        Uitverkocht
                                    </button>
                                );
                            }
                        } else {
                            return (
                                <button onClick={this.addToCartNonUsers.bind(this, e, index)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                    Boek nu
                                </button>
                            );
                        }
                    }
                } else {
                    if (!e.voucherUnlimited) {
                        let num = 0;
                        if (e.verzilvered_by) {
                            let objectedCheckedIn = Object.entries(e.verzilvered_by);
                            num = this.getVerzilveredQty(objectedCheckedIn);
                        }
                        if (e.inCartOf) {
                            let objectedInCartOf = Object.entries(e.inCartOf);
                            num = num + objectedInCartOf.length;
                        }
                        if (num < Number(e.number)) {
                            return (
                                <button onClick={this.addToCartNonUsers.bind(this, e, index)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                    Boek nu
                                </button>
                            );
                        } else {
                            return (
                                <button
                                    style={{ backgroundColor: "#C17F6B" }}
                                    className="pinkcolorbutton"
                                >
                                    Uitverkocht
                                </button>
                            );
                        }
                    } else {
                        return (
                            <button onClick={this.addToCartNonUsers.bind(this, e, index)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                Boek nu
                            </button>
                        );
                    }
                }
            }
        } else {
            if (this.state.guestUserCart) {
                let filtered = this.state.guestUserCart.filter((h) => {
                    return h.pushKey === e.pushKey;
                });
                if (filtered.length > 0) {
                    return (
                        <button
                            style={{ backgroundColor: "#C17F6B" }}
                            className="pinkcolorbutton"
                        >
                            Toegevoegd
                        </button>
                    );
                } else {
                    if (!e.voucherUnlimited) {
                        let num = 0;
                        if (e.verzilvered_by) {
                            let objectedCheckedIn = Object.entries(e.verzilvered_by);
                            num = this.getVerzilveredQty(objectedCheckedIn);
                        }
                        if (e.inCartOf) {
                            let objectedInCartOf = Object.entries(e.inCartOf);
                            num = num + objectedInCartOf.length;
                        }
                        if (num < Number(e.number)) {
                            return (
                                <button onClick={this.addToCartNonUsers.bind(this, e, index)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                    Boek nu
                                </button>
                            );
                        } else {
                            return (
                                <button
                                    style={{ backgroundColor: "#C17F6B" }}
                                    className="pinkcolorbutton"
                                >
                                    Uitverkocht
                                </button>
                            );
                        }
                    } else {
                        return (
                            <button onClick={this.addToCartNonUsers.bind(this, e, index)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                Boek nu
                            </button>
                        );
                    }
                }
            } else {
                if (!e.voucherUnlimited) {
                    let num = 0;
                    if (e.verzilvered_by) {
                        let objectedCheckedIn = Object.entries(e.verzilvered_by);
                        num = this.getVerzilveredQty(objectedCheckedIn);
                    }
                    if (e.inCartOf) {
                        let objectedInCartOf = Object.entries(e.inCartOf);
                        num = num + objectedInCartOf.length;
                    }
                    if (num < Number(e.number)) {
                        return (
                            <button onClick={this.addToCartNonUsers.bind(this, e, index)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                                Boek nu
                            </button>
                        );
                    } else {
                        return (
                            <button
                                style={{ backgroundColor: "#C17F6B" }}
                                className="pinkcolorbutton"
                            >
                                Uitverkocht
                            </button>
                        );
                    }
                } else {
                    return (
                        <button onClick={this.addToCartNonUsers.bind(this, e, index)} className={window.innerWidth < 1008 && "pinkcolorbutton"}>
                            Boek nu
                        </button>
                    );
                }
            }
        }
    }

    verzilverVoucher(e) {
        if (this.props.user) {
            let filtered = this.props.user.filter((g) => {
                return g.user_id === e.created_by;
            });
            if (filtered.length > 0) {
                this.setState({ currentUser: filtered[0] });
            }
        }
        this.setState({
            verzilverPopupWebShop: true,
            currentActie: e,
            currentActieType: "web_shop",
        });
    }

    checkForButtonsverzilverVoucher(currentActie) {
        return (
            <div style={{ display: "flex", marginTop: 20, justifyContent: "center" }}>
                {!isDesktop ? (
                    <button
                        style={{ marginRight: 5 }}
                        onClick={this.proceedVoucher.bind(this, currentActie)}
                    >
                        OK
                    </button>
                ) : (
                    <button
                        style={{ marginRight: 5 }}
                        onClick={this.proceedVoucher.bind(this, currentActie)}
                    >
                        OK
                    </button>
                )}
            </div>
        );
    }

    proceedVoucher() {
        let order_id = this.state.orderidToUpload + 1;
        firebase.database().ref("current_order_id").set(order_id)
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, "0");
        let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        let yyyy = today.getFullYear();

        today = mm + "/" + dd + "/" + yyyy;
        let arrSellers = [];
        arrSellers.push(this.state.currentUser.user_id);

        if (this.state.currentUser.expireTypeVoucher && this.state.currentUser.expireDateVoucher) {
            this.state.currentActie.expireTypeVoucher = this.state.currentUser.expireTypeVoucher
            this.state.currentActie.expireDateVoucher = this.state.currentUser.expireDateVoucher
        }

        const min = 0;
        const max = this.state.currentActie.coupons.length;
        const intNumber = Math.floor(Math.random() * (max - min)) + min;

        this.state.currentActie.redeemed_code = this.state.currentActie.coupons[intNumber];

        let obj = {
            created_on: today,
            order_id,
            products: [this.state.currentActie],
            buyer: this.state.currentUserId,
            sellers: arrSellers,
            timeStampCreated: new Date().getTime(),
            order_type: "campaign",
        };
        // let pushuserOrderCurrentUser = firebase.database().ref("users/" + this.state.currentUserId + "/orders").push().key;

        // firebase.database().ref("users/" + this.state.currentUserId + "/orders/" + pushuserOrderCurrentUser).set(obj);

        // let pushuserOrder = firebase
        //     .database()
        //     .ref("users/" + this.state.currentUser.user_id + "/orders")
        //     .push().key;
        // firebase
        //     .database()
        //     .ref(
        //         "users/" + this.state.currentUser.user_id + "/orders/" + pushuserOrder
        //     )
        //     .set(obj);
        let orderKey = firebase.database().ref("all_orders").push().key;
        obj.pushKey = orderKey;
        firebase
            .database()
            .ref("all_orders/" + orderKey)
            .set(obj);
        if (this.state.currentActie.verzilvered_by) {
            let objected = Object.entries(this.state.currentActie.verzilvered_by);
            let filtered = objected.filter((h) => {
                return h[1].user_id === this.state.currentUserId;
            });
            if (filtered.length > 0) {
                let newDate = new Date().getTime();
                let arr = filtered[0][1].allDates;
                arr.push(newDate);
                let pushKey = filtered[0][1].pushKey;
                let obj = {
                    user_id: this.state.currentUserId,
                    date: newDate,
                    allDates: arr,
                    pushKey,
                };
                firebase
                    .database()
                    .ref(
                        "bhodi_campaigns/" +
                        this.state.currentUser.user_id +
                        "/" +
                        this.state.currentActie.pushKey +
                        "/verzilvered_by/" +
                        filtered[0][1].pushKey
                    )
                    .set(obj);
            } else {
                let newDate = new Date().getTime();
                let arr = [];
                arr.push(newDate);
                let pushKey = firebase
                    .database()
                    .ref(
                        "bhodi_campaigns/" +
                        this.state.currentUser.user_id +
                        "/" +
                        this.state.currentActie.pushKey +
                        "/verzilvered_by"
                    )
                    .push().key;
                let obj = {
                    user_id: this.state.currentUserId,
                    date: newDate,
                    allDates: arr,
                    pushKey,
                };
                firebase
                    .database()
                    .ref(
                        "bhodi_campaigns/" +
                        this.state.currentUser.user_id +
                        "/" +
                        this.state.currentActie.pushKey +
                        "/verzilvered_by/" +
                        pushKey
                    )
                    .set(obj);
            }
        } else {
            let newDate = new Date().getTime();
            let arr = [];
            arr.push(newDate);
            let pushKey = firebase
                .database()
                .ref(
                    "bhodi_campaigns/" +
                    this.state.currentUser.user_id +
                    "/" +
                    this.state.currentActie.pushKey +
                    "/verzilvered_by"
                )
                .push().key;
            let obj = {
                user_id: this.state.currentUserId,
                date: newDate,
                allDates: arr,
                pushKey,
            };
            firebase
                .database()
                .ref(
                    "bhodi_campaigns/" +
                    this.state.currentUser.user_id +
                    "/" +
                    this.state.currentActie.pushKey +
                    "/verzilvered_by/" +
                    pushKey
                )
                .set(obj);
        }

        let notificationToShow = document.getElementById("mustShowOnAddOrder");
        if (notificationToShow) {
            notificationToShow.style.opacity = 1;
            notificationToShow.style.pointerEvents = "all";
        }

        setTimeout(() => {
            if (notificationToShow) {
                notificationToShow.style.opacity = 0;
                notificationToShow.style.pointerEvents = "none";
            }
        }, 4000);
        this.setState({ verzilverPopupWebShop: false, verzilverPopupGreenWebShop: true, currentActie: "", currentActieType: "" });
    }

    render() {
        return (
            <div
                className={`vouchersglobalpagemaintodoWhite ${this.state.firstSectionToShow.length === 1 &&
                    "dofullwidthvouchersectionmain"
                    }`}
            >
                <div
                    ref={this.dropRef}
                    className={`flex-section globalshoppage globalvoucherspage mainvouchergpage newdesignsvoucherpage vouchersnewdesignglobalupdated ${this.state.filteredProductsToShow.length === 1 && "designtodofullpagetotal"}`}
                    style={{ backgroundColor: "#F8F8F8" }}
                >
                    {/* Doorlopende Verzilver */}
                    <div
                        className="verzilver-popup-white-opacity"
                        style={{
                            opacity: this.state.verzilverPopup ? 1 : 0,
                            pointerEvents: this.state.verzilverPopup ? "all" : "none",
                        }}
                    ></div>
                    <div
                        className="verzilver-popup-white"
                        style={{
                            opacity: this.state.verzilverPopup ? 1 : 0,
                            pointerEvents: this.state.verzilverPopup ? "all" : "none",
                        }}
                    >
                        <CloseIcon
                            className="close-hpw"
                            onClick={() => this.setState({ verzilverPopup: false })}
                        />
                        <LockOpenIcon className="lock-hpw" />
                        <p className="heading-hpw">VALIDEER KORTINGSVOUCHER</p>
                        <p className="desc-hpw">
                            Leuk dat je gebruik wil maken van
                            <br />
                            onze kortingsvoucher. Deze kun je
                            <br />
                            valideren vanuit je orderoverzicht.
                            <br />
                            {/* <br />
                            <b>Let op</b>: de kortingsvoucher blijft
                            <br />
                            24 uur voor jou gereserveerd
                            <br /> */}
                            <br />
                            Klik op OK om de kortingsvoucher
                            <br />
                            in je orderoverzicht te plaatsen.
                        </p>
                        {this.state.currentActie && this.checkForButtonsVerzilverKortingsVoucher(this.state.currentActie)}
                    </div>
                    {/* Doorlopende verzilver */}

                    {/* local event verzilver */}
                    <div
                        className="verzilver-popup-white-opacity"
                        style={{
                            opacity: this.state.verzilverPopupLocalEvent ? 1 : 0,
                            pointerEvents: this.state.verzilverPopupLocalEvent ? "all" : "none",
                        }}
                    ></div>
                    <div
                        className="verzilver-popup-white"
                        style={{
                            opacity: this.state.verzilverPopupLocalEvent ? 1 : 0,
                            pointerEvents: this.state.verzilverPopupLocalEvent ? "all" : "none",
                        }}
                    >
                        <CloseIcon
                            className="close-hpw"
                            onClick={() => this.setState({ verzilverPopupLocalEvent: false })}
                        />
                        <p
                            className="heading-hpw"
                            style={{
                                textTransform: "uppercase",
                                marginBottom: 6,
                                fontSize: 16,
                                letterSpacing: 2,
                            }}
                        >
                            Aanmelding
                        </p>
                        <div
                            style={{
                                width: 40,
                                height: 3,
                                backgroundColor: "#C17F6B",
                                margin: "0px auto",
                                marginBottom: 25,
                            }}
                        ></div>
                        <p className="desc-hpw">
                            Leuk, je staat op het punt om je
                            <br />
                            aan te melden voor ons event!
                            <br />
                            <br />
                            Klik op de onderstaande knop
                            <br />
                            om dit te bevestigen.
                        </p>
                        <div style={{ display: "flex", marginTop: 20, justifyContent: "center" }}>
                            <button
                                style={{ marginRight: 5 }}
                                onClick={() => {
                                    this.proceedLocalBlock(this.state.currentEventPopup)
                                }}
                            >
                                OK
                            </button>
                        </div>
                    </div>
                    <div
                        className="verzilver-popup-white-opacity"
                        style={{
                            opacity: this.state.verzilverPopupGreenLocalEvent ? 1 : 0,
                            pointerEvents: this.state.verzilverPopupGreenLocalEvent
                                ? "all"
                                : "none",
                        }}
                    ></div>
                    <div
                        className="verzilver-popup-white"
                        style={{
                            opacity: this.state.verzilverPopupGreenLocalEvent ? 1 : 0,
                            pointerEvents: this.state.verzilverPopupGreenLocalEvent
                                ? "all"
                                : "none",
                        }}
                    >
                        <CloseIcon
                            className="close-hpw"
                            onClick={() =>
                                this.setState({ verzilverPopupGreenLocalEvent: false })
                            }
                        />
                        <p
                            className="heading-hpw"
                            style={{
                                textTransform: "uppercase",
                                marginBottom: 6,
                                fontSize: 16,
                                letterSpacing: 2,
                            }}
                        >
                            {this.state.currentUser.company_name}
                        </p>
                        <p className="heading-hpw" style={{ textTransform: "uppercase" }}>
                            Bevestiging
                        </p>
                        <div
                            style={{
                                width: 40,
                                height: 3,
                                backgroundColor: "#C17F6B",
                                margin: "0px auto",
                                marginBottom: 25,
                            }}
                        ></div>
                        <p className="desc-hpw">
                            Je aanmelding is ontvangen, <br />
                            deze vind je terug in je <br />
                            <u
                                style={{ cursor: "pointer" }}
                                onClick={() => (window.location.href = "/order-list")}
                            >
                                orderoverzicht
                            </u>
                            .
                            <br />
                            <br />
                            {/* We zien je graag op{" "}
                            <br />
                            {this.getStartTimeOfReservedEvent(this.state.currentEventPopup)} tot{" "}
                            {this.getEndTimeOfReservedEvent(this.state.currentEventPopup)}
                            <br /> */}
                            Wij verheugen ons op je komst! <br />
                        </p>
                        {/* {this.state.currentUser.phoneNumber && <p className="heading-hpw" style={{ marginBottom: 6 }}>Tel: {this.state.currentUser.phoneNumber}</p>}
                    {this.state.currentUser.company_website && <p className="heading-hpw">{this.state.currentUser.company_website}</p>} */}
                        <button
                            style={{ backgroundColor: "#C17F6B" }}
                            onClick={() => {
                                this.setState({
                                    verzilverPopupLocalEvent: false,
                                    verzilverPopupGreenLocalEvent: false,
                                    currentEventPopup: "",
                                    currentUser: "",
                                });
                            }}
                        >
                            SLUIT
                        </button>
                    </div>

                    {/* local event verzilver */}

                    {/* Webshop Verzilver */}
                    <div
                        className="verzilver-popup-white-opacity"
                        style={{
                            opacity: this.state.verzilverPopupWebShop ? 1 : 0,
                            pointerEvents: this.state.verzilverPopupWebShop ? "all" : "none",
                        }}
                    ></div>
                    <div
                        className="verzilver-popup-white"
                        style={{
                            opacity: this.state.verzilverPopupWebShop ? 1 : 0,
                            pointerEvents: this.state.verzilverPopupWebShop ? "all" : "none",
                        }}
                    >
                        <CloseIcon
                            className="close-hpw"
                            onClick={() => this.setState({ verzilverPopupWebShop: false })}
                        />
                        <LockOpenIcon className="lock-hpw" />
                        <p className="heading-hpw">VALIDEER COUPONCODE</p>
                        <p className="desc-hpw">
                            Leuk dat je gebruik wil maken van
                            <br />
                            onze kortingsvoucher. Deze kun je
                            <br />
                            valideren vanuit je orderoverzicht.
                            <br />
                            {/* <br />
                            <b>Let op</b>: de kortingsvoucher blijft
                            <br />
                            24 uur voor jou gereserveerd
                            <br /> */}
                            <br />
                            Klik op OK om de kortingsvoucher
                            <br />
                            in je orderoverzicht te plaatsen.
                        </p>
                        {this.state.currentActie && this.checkForButtonsverzilverVoucher(this.state.currentActie)}
                    </div>
                    {/* Webshop verzilver */}

                    {this.state.filteredProductsToShow.length > 0 && (
                        <ReactFullpage
                            onLeave={this.onLeave.bind(this)}
                            afterLoad={this.afterLoad.bind(this)}
                            responsiveWidth="1008"
                            normalScrollElements=".posts-detailed-popup, .charity-confirmation-complete, .loaderareaduplicate, .bg-overlaymini, .mnicart-flyout-inner, .rightmenucompletewidthaddproduct, .rightmenucommentsaddproduct"
                            render={({ state, fullpageApi }) => {
                                let lengthToShow = Math.ceil(
                                    this.state.filteredProductsToShow.length / 2
                                );
                                let arrSec = [];
                                for (let i = 0; i < lengthToShow; i++) {
                                    arrSec.push(i);
                                }
                                if (!this.state.fullpageApi) {
                                    this.setState({ fullpageApi });
                                }
                                return (
                                    <div>
                                        {arrSec.length > 0 &&
                                            arrSec.map((y, is) => {
                                                const cloneSheepsES6 = [
                                                    ...this.state.filteredProductsToShow,
                                                ];
                                                let toProductsShow = cloneSheepsES6.splice(y * 2, 2);
                                                return (
                                                    <div
                                                        className={`section ${this.state.activeSection === is + 1
                                                            ? "active"
                                                            : "nonactive"
                                                            }`}
                                                    >
                                                        <div className="container-clgd leftscreenlesswidthabit">
                                                            <div className="completesectionforshop">
                                                                <div className="content-csfs-shop contentvouchermainglobal">
                                                                    {toProductsShow.length > 0 &&
                                                                        toProductsShow.map((e, ind) => {
                                                                            if (e.campaignType === "kortings_voucher") {
                                                                                return (
                                                                                    <div className="left-vcvgm">
                                                                                        {e.showPopupNotLeft ? (
                                                                                            <div
                                                                                                className="voucher-container-cvmg contentsfcplpgreenblock"
                                                                                                style={{
                                                                                                    backgroundColor: "#285151",
                                                                                                    alignItems: "center",
                                                                                                }}
                                                                                            >
                                                                                                <HighlightOffIcon />
                                                                                                {/* <p className="main-heading-csfcplp firstmhc" style={{ textTransform: "uppercase" }}>NIET MEER BESCHIKBAAR</p> */}
                                                                                                <p
                                                                                                    className="main-heading-csfcplp secondmhc"
                                                                                                    style={{
                                                                                                        fontSize: 14,
                                                                                                        marginTop: 10,
                                                                                                    }}
                                                                                                >
                                                                                                    Je hebt de aanbieding net
                                                                                                    gemist en is helaas niet meer
                                                                                                    beschikbaar
                                                                                                </p>
                                                                                            </div>
                                                                                        ) : (
                                                                                            <div
                                                                                                className="voucher-container-cvmg vss-new-vmg"
                                                                                            >
                                                                                                <div
                                                                                                    className="overlay-vccmg"
                                                                                                    style={{
                                                                                                        backgroundColor: `rgba(0,0,0,${this.state.globalOverlay})`,
                                                                                                    }}
                                                                                                ></div>
                                                                                                {e.images &&
                                                                                                    e.images.length > 0 && (
                                                                                                        <img src={e.images[0]} />
                                                                                                    )}
                                                                                                <div className="top-right-vnvmg">
                                                                                                    <img
                                                                                                        src={this.getSellerImage(e)}
                                                                                                        style={{
                                                                                                            width: 25,
                                                                                                            height: 25,
                                                                                                            position: "static",
                                                                                                            borderRadius: "50%",
                                                                                                            marginRight: 9,
                                                                                                        }}
                                                                                                        onClick={() => this.getSellerRedirect(e)}
                                                                                                    />{" "}
                                                                                                    <label onClick={() => this.getSellerRedirect(e)}>
                                                                                                        {this.getSellerName(e)}
                                                                                                    </label>
                                                                                                    {this.state.currentUserId && this.checkForBookMarkIconVouchers(e)}
                                                                                                </div>
                                                                                                <div className='left-tsmhnc'>
                                                                                                    <div className='container-ltsmhnc'>
                                                                                                        <div className="wrap-cltsm-top">
                                                                                                            {e.kortingsVoucherGiveaway ? (<p>Giveaway</p>) : (<p>Voucher</p>)}
                                                                                                            <p>{e.title}</p>
                                                                                                        </div>
                                                                                                        {!e.active && (<p style={{ color: "#C17F6B" }}>Actie is niet actief</p>)}
                                                                                                        <p>
                                                                                                            <div dangerouslySetInnerHTML={this.createMarkup(e.desc)} className="description-content-cltsm" onClick={() => {
                                                                                                                const truncatedDescription = this.truncateAndSanitize(e.desc, 130);
                                                                                                                if (truncatedDescription.length > 130) {
                                                                                                                    this.setState({ showDescriptionPopup: true, currentDesc: e.desc })
                                                                                                                }
                                                                                                            }}></div>
                                                                                                        </p>
                                                                                                        {/* {e.desc.length > 130 ? (<p onClick={() => {
                                                                                                                this.setState({ showDescriptionPopup: true, currentDesc: e.desc })
                                                                                                            }}>{e.desc.substr(0, 130)}...</p>) : (<p>{e.desc}</p>)} */}
                                                                                                        {!(e.timerDateWaiting && e.timerTimeWaiting && new Date(Date.parse(e.isoTimeTimerBtn)).getTime() > new Date().getTime()) && (!e.kortingsVoucherPrijs && !e.kortingsVoucherPercentage && e.isoTimeTimerBtnEndTime && new Date(e.isoTimeTimerBtnEndTime).getTime() > new Date().getTime()) && (<p style={{ marginTop: 10 }}>
                                                                                                            {<KortingsVoucherTimer timerToDo={e.isoTimeTimerBtnEndTime} salePrice={e.kortingsVoucherSalePrice} closeScreen={() => {
                                                                                                                this.setState({ updatePricing: true })
                                                                                                            }} />}
                                                                                                        </p>)}
                                                                                                        {e.kortingsVoucherPrijs && (<p style={{ marginTop: 10 }}>
                                                                                                            <font>
                                                                                                                €
                                                                                                                <NumberFormatter
                                                                                                                    toFormat={e.amount}
                                                                                                                />{" "}
                                                                                                                Korting
                                                                                                            </font>
                                                                                                        </p>)}
                                                                                                        {e.kortingsVoucherPercentage && (<p style={{ marginTop: 10 }}>
                                                                                                            <font>
                                                                                                                Korting{" "}
                                                                                                                {e.amount}
                                                                                                                {" "}%
                                                                                                            </font>
                                                                                                        </p>)}
                                                                                                        {(!e.kortingsVoucherPrijs && !e.kortingsVoucherPercentage && e.isoTimeTimerBtnEndTime) ? (<p style={{ marginTop: 10 }}>
                                                                                                            {(new Date(e.isoTimeTimerBtnEndTime).getTime() > new Date().getTime()) ? (
                                                                                                                <>
                                                                                                                    <s>
                                                                                                                        €
                                                                                                                        <NumberFormatter
                                                                                                                            toFormat={e.kortingsVoucherNormalPrice}
                                                                                                                        />{" "}
                                                                                                                    </s>
                                                                                                                    <div style={{ width: 15, height: 1, display: "inline-block" }}></div>
                                                                                                                    <font>
                                                                                                                        €
                                                                                                                        <NumberFormatter
                                                                                                                            toFormat={e.kortingsVoucherSalePrice}
                                                                                                                        />{" "}
                                                                                                                    </font>
                                                                                                                </>
                                                                                                            ) : <>
                                                                                                                €
                                                                                                                <NumberFormatter
                                                                                                                    toFormat={e.kortingsVoucherNormalPrice}
                                                                                                                />{" "}
                                                                                                            </>}
                                                                                                        </p>) : e.kortingsVoucherGiveaway ? (<p>Free</p>) : (!e.kortingsVoucherPrijs && !e.kortingsVoucherPercentage) && (<p style={{ marginTop: 10 }}>
                                                                                                            €
                                                                                                            <NumberFormatter
                                                                                                                toFormat={e.kortingsVoucherSalePrice}
                                                                                                            />{" "}
                                                                                                        </p>)}
                                                                                                        {/* <button>BOEK NU</button> */}
                                                                                                        {e.active && <div style={{ position: "relative" }}>
                                                                                                            {(e.timerDateWaiting && e.timerTimeWaiting && new Date(Date.parse(e.isoTimeTimerBtn)).getTime() > new Date().getTime()) && <ButtonTimerCampaign mobileNewSpacing={true} showLabelAbsolute={true} labelOnOwnPage={true} timerToDo={new Date(Date.parse(e.isoTimeTimerBtn)).getTime()} atProduct={e} currentUserHere={this.state.currentUserHere} guestUserCart={this.state.guestUserCart} timeToCalculate={new Date(Number(e.timerDateWaiting.split("-")[0]), Number(e.timerDateWaiting.split("-")[1] - 1), Number(e.timerDateWaiting.split("-")[2]), Number(e.timerTimeWaiting.split(":")[0]), Number(e.timerTimeWaiting.split(":")[1]), 0).getTime()}
                                                                                                                closeScreen={() => {
                                                                                                                    this.setState({ updatePricing: true })
                                                                                                                }}
                                                                                                            />
                                                                                                            }
                                                                                                            {this.checkForVerzilverKortingsVoucher(e, is + ind)}
                                                                                                            <div className="image-frontkortingsvoucher addIconCart">
                                                                                                                <AddIcon />
                                                                                                            </div>
                                                                                                        </div>}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className='labels-cltsmhnc'>
                                                                                                    {this.getUseOfVoucherKortingsVoucher(e)}
                                                                                                    {this.getfilelink(e)}
                                                                                                </div>
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                );
                                                                            } else if (e.campaignType === "web_shop") {
                                                                                return (
                                                                                    <div className="left-vcvgm">
                                                                                        {e.showPopupNotLeft ? (
                                                                                            <div
                                                                                                className="voucher-container-cvmg contentsfcplpgreenblock"
                                                                                                style={{
                                                                                                    backgroundColor: "#285151",
                                                                                                    alignItems: "center",
                                                                                                }}
                                                                                            >
                                                                                                <HighlightOffIcon />
                                                                                                <p
                                                                                                    className="main-heading-csfcplp secondmhc"
                                                                                                    style={{
                                                                                                        fontSize: 14,
                                                                                                        marginTop: 10,
                                                                                                    }}
                                                                                                >
                                                                                                    Je hebt de aanbieding net
                                                                                                    gemist en is helaas niet meer
                                                                                                    beschikbaar
                                                                                                </p>
                                                                                            </div>
                                                                                        ) : (
                                                                                            <div
                                                                                                className="voucher-container-cvmg vss-new-vmg"
                                                                                            >
                                                                                                <div
                                                                                                    className="overlay-vccmg"
                                                                                                    style={{
                                                                                                        backgroundColor: `rgba(0,0,0,${this.state.globalOverlay})`,
                                                                                                    }}
                                                                                                ></div>
                                                                                                {e.images &&
                                                                                                    e.images.length > 0 && (
                                                                                                        <img src={e.images[0]} />
                                                                                                    )}
                                                                                                <div className="top-right-vnvmg">
                                                                                                    <img
                                                                                                        src={this.getSellerImage(e)}
                                                                                                        style={{
                                                                                                            width: 25,
                                                                                                            height: 25,
                                                                                                            position: "static",
                                                                                                            borderRadius: "50%",
                                                                                                            marginRight: 9,
                                                                                                        }}
                                                                                                        onClick={() => this.getSellerRedirect(e)}
                                                                                                    />{" "}
                                                                                                    <label onClick={() => this.getSellerRedirect(e)}>
                                                                                                        {this.getSellerName(e)}
                                                                                                    </label>
                                                                                                    {this.state.currentUserId && this.checkForBookMarkIconVouchers(e)}
                                                                                                </div>
                                                                                                <div className='left-tsmhnc'>
                                                                                                    <div className='container-ltsmhnc'>
                                                                                                        <div className="wrap-cltsm-top">
                                                                                                            <p>Webshop Coupon</p>
                                                                                                            <p>{e.title}</p>
                                                                                                        </div>
                                                                                                        {!e.active && (<p style={{ color: "#C17F6B" }}>Actie is niet actief</p>)}
                                                                                                        <p>
                                                                                                            <div dangerouslySetInnerHTML={this.createMarkup(e.desc)} className="description-content-cltsm" onClick={() => {
                                                                                                                const truncatedDescription = this.truncateAndSanitize(e.desc, 130);
                                                                                                                if (truncatedDescription.length > 130) {
                                                                                                                    this.setState({ showDescriptionPopup: true, currentDesc: e.desc })
                                                                                                                }
                                                                                                            }}></div>
                                                                                                        </p>
                                                                                                        {e.isoStartTime && e.isoEndTime && (<p style={{ marginTop: 10 }}>Datum: {this.getFormattedDateWebShop(e)}</p>)}
                                                                                                        {!e.voucherIsPercentage && (<p style={{ marginTop: 10 }}>
                                                                                                            <font>
                                                                                                                €
                                                                                                                <NumberFormatter
                                                                                                                    toFormat={e.amount}
                                                                                                                />{" "}
                                                                                                                Korting
                                                                                                            </font>
                                                                                                        </p>)}
                                                                                                        {e.voucherIsPercentage && (<p style={{ marginTop: 10 }}>
                                                                                                            <font>
                                                                                                                Korting{" "}
                                                                                                                {e.amount}
                                                                                                                {" "}%
                                                                                                            </font>
                                                                                                        </p>)}
                                                                                                        {e.active && <div style={{ position: "relative" }}>
                                                                                                            {((e.isoTimeTimerBtn && !e.isoStartTime && new Date(Date.parse(e.isoTimeTimerBtn)).getTime() > new Date().getTime()) || (e.isoTimeTimerBtn && e.isoStartTime && new Date(Date.parse(e.isoTimeTimerBtn)).getTime() > new Date(Date.parse(e.isoStartTime)) && new Date(Date.parse(e.isoTimeTimerBtn)).getTime() > new Date().getTime())) ?
                                                                                                                (
                                                                                                                    <ButtonTimerCampaign mobileNewSpacing={true} showLabelAbsolute={true} labelOnOwnPage={true} timerToDo={new Date(Date.parse(e.isoTimeTimerBtn)).getTime()} atProduct={e} currentUserHere={this.state.currentUserHere} guestUserCart={this.state.guestUserCart} timeToCalculate={new Date(Number(e.timerDateWaiting.split("-")[0]), Number(e.timerDateWaiting.split("-")[1] - 1), Number(e.timerDateWaiting.split("-")[2]), Number(e.timerTimeWaiting.split(":")[0]), Number(e.timerTimeWaiting.split(":")[1]), 0).getTime()}
                                                                                                                        closeScreen={() => {
                                                                                                                            this.setState({ updatePricing: true })
                                                                                                                        }}
                                                                                                                    />
                                                                                                                )
                                                                                                                : (e.isoStartTime && new Date(Date.parse(e.isoStartTime)).getTime() > new Date().getTime()) && <ButtonTimerCampaign mobileNewSpacing={true} showLabelAbsolute={true} labelOnOwnPage={true} timerToDo={new Date(Date.parse(e.isoStartTime)).getTime()} atProduct={e} currentUserHere={this.state.currentUserHere} guestUserCart={this.state.guestUserCart} timeToCalculate={new Date(Date.parse(e.isoStartTime)).getTime()}
                                                                                                                    closeScreen={() => {
                                                                                                                        this.setState({ updatePricing: true })
                                                                                                                    }}
                                                                                                                />}
                                                                                                            {this.checkForVerzilverButton(e, is + ind)}
                                                                                                            <div className="image-frontkortingsvoucher addIconCart">
                                                                                                                <AddIcon />
                                                                                                            </div>
                                                                                                        </div>}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className='labels-cltsmhnc'>
                                                                                                    {this.getUseOfVoucherWebShop(e)}
                                                                                                    {this.getfilelink(e)}
                                                                                                </div>
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                );
                                                                            } else if (e.campaignType === "local_event") {
                                                                                if (!e.reserved_event_id) {
                                                                                    e.selectedTimeIndex = 0
                                                                                    e.reserved_event_id = 1
                                                                                    e.selectedTiming = false
                                                                                    e.dropdownOpenTiming = false
                                                                                }
                                                                                return (
                                                                                    <div className="left-vcvgm">
                                                                                        {e.showPopupNotLeft ? (
                                                                                            <div
                                                                                                className="voucher-container-cvmg contentsfcplpgreenblock"
                                                                                                style={{
                                                                                                    backgroundColor:
                                                                                                        "#285151",
                                                                                                    alignItems: "center",
                                                                                                }}
                                                                                            >
                                                                                                <HighlightOffIcon />
                                                                                                <p
                                                                                                    className="main-heading-csfcplp secondmhc"
                                                                                                    style={{
                                                                                                        fontSize: 14,
                                                                                                        marginTop: 10,
                                                                                                    }}
                                                                                                >
                                                                                                    Je hebt de aanbieding
                                                                                                    net gemist en is helaas
                                                                                                    niet meer beschikbaar
                                                                                                </p>
                                                                                            </div>
                                                                                        ) : (
                                                                                            <div className="voucher-container-cvmg vss-new-vmg">
                                                                                                <div
                                                                                                    className="overlay-vccmg"
                                                                                                    style={{
                                                                                                        backgroundColor: `rgba(0,0,0,${this.state.globalOverlay})`,
                                                                                                        pointerEvents: "all",
                                                                                                    }}
                                                                                                ></div>
                                                                                                {e.images &&
                                                                                                    e.images.length > 0 &&
                                                                                                    e.localEventPlaceHolderIsImage && (
                                                                                                        <img
                                                                                                            src={e.images[0]}
                                                                                                        />
                                                                                                    )}
                                                                                                {e.images &&
                                                                                                    e.images.length > 0 &&
                                                                                                    !e.localEventPlaceHolderIsImage && (
                                                                                                        <video
                                                                                                            src={e.images[0]}
                                                                                                            className="videotowholewidthheightfullyupdated"
                                                                                                            data-autoplay
                                                                                                            muted
                                                                                                            loop
                                                                                                        ></video>
                                                                                                    )}
                                                                                                <div className="top-right-vnvmg">
                                                                                                    <img
                                                                                                        src={this.getSellerImage(e)}
                                                                                                        onClick={() => this.getSellerRedirect(e)}
                                                                                                        style={{
                                                                                                            width: 25,
                                                                                                            height: 25,
                                                                                                            position: "static",
                                                                                                            borderRadius: "50%",
                                                                                                            marginRight: 9,
                                                                                                        }}
                                                                                                    />{" "}
                                                                                                    <label onClick={() => this.getSellerRedirect(e)}>
                                                                                                        {this.getSellerName(e)}
                                                                                                    </label>
                                                                                                    {this.state.currentUserId && this.checkForBookMarkIconVouchers(e)}
                                                                                                </div>
                                                                                                <div className='left-tsmhnc'>
                                                                                                    <div className='container-ltsmhnc'>
                                                                                                        <div className="wrap-cltsm-top">
                                                                                                            <p>
                                                                                                                Event
                                                                                                                {(e.amount && e.localEventType === "percentage" && !e.takeFromAdmin) && (
                                                                                                                    <font>
                                                                                                                        &nbsp;{e.amount}% Off
                                                                                                                    </font>
                                                                                                                )}
                                                                                                            </p>
                                                                                                            <p>{e.title}</p>
                                                                                                        </div>
                                                                                                        {!e.active && (<p style={{ color: "#C17F6B" }}>Actie is niet actief</p>)}
                                                                                                        {/* <p>{e.desc}</p> */}
                                                                                                        <p>
                                                                                                            <div dangerouslySetInnerHTML={this.createMarkup(e.desc)} className="description-content-cltsm" onClick={() => {
                                                                                                                const truncatedDescription = this.truncateAndSanitize(e.desc, 130);
                                                                                                                if (truncatedDescription.length > 130) {
                                                                                                                    this.setState({ showDescriptionPopup: true, currentDesc: e.desc })
                                                                                                                }
                                                                                                            }}></div>
                                                                                                        </p>
                                                                                                        {/* {e.desc.length > 130 ? (<p onClick={() => {
                                                                                                                this.setState({ showDescriptionPopup: true, currentDesc: e.desc })
                                                                                                            }}>{e.desc.substr(0, 130)}...</p>) : (<p>{e.desc}</p>)} */}
                                                                                                        <p style={{ marginTop: 10 }}>Datum: {this.getFormattedStartDateLocalEvent(e)}</p>
                                                                                                        {(e.amount && e.localEventType !== "percentage" && !e.takeFromAdmin) && (<p>
                                                                                                            <font>
                                                                                                                €{" "}
                                                                                                                <NumberFormatter
                                                                                                                    toFormat={e.amount}
                                                                                                                />{" "}
                                                                                                                Korting
                                                                                                            </font>
                                                                                                        </p>)}
                                                                                                        {this.state.currentUserId && (e.isoTimeTimerBtn && new Date(Date.parse(e.isoTimeTimerBtn)).getTime() > new Date().getTime()) && <ButtonTimerCampaign mobileNewSpacing={true} showLabelAbsolute={true} labelOnOwnPage={true} timerToDo={new Date(Date.parse(e.isoTimeTimerBtn)).getTime()} atProduct={e} currentUserHere={this.state.currentUserHere} guestUserCart={this.state.guestUserCart} closeScreen={() => {
                                                                                                            this.setState({ updatePricing: true })
                                                                                                        }} />}
                                                                                                        {e.active && e.timings && <div id="innerbtnboeknulocalevent">
                                                                                                            {e.timings.length > 1 && (<button className="timing-btnContainer">
                                                                                                                <div className="main-tibtncont" onClick={() => {
                                                                                                                    e.dropdownOpenTiming = !e.dropdownOpenTiming
                                                                                                                    this.setState({ filteredProductsToShow: this.state.filteredProductsToShow })
                                                                                                                }}>
                                                                                                                    {e.selectedTiming ? (<font>{e.timings[e.selectedTimeIndex].startTimeEvent}-{e.timings[e.selectedTimeIndex].endTimeEvent} uur</font>) : (<font>Selecteer Tijd</font>)}
                                                                                                                    <RiArrowDownSLine />
                                                                                                                </div>
                                                                                                                <div className="dropdownContainer-tibtn" style={{ opacity: e.dropdownOpenTiming ? 1 : 0, pointerEvents: e.dropdownOpenTiming ? "all" : "none" }}>
                                                                                                                    <ul>
                                                                                                                        {e.timings.map((t, index) => {
                                                                                                                            let isUitverkocht = false
                                                                                                                            return (<li onClick={() => {
                                                                                                                                if (!isUitverkocht) {
                                                                                                                                    e.selectedTimeIndex = index
                                                                                                                                    e.selectedTiming = true
                                                                                                                                    e.dropdownOpenTiming = false
                                                                                                                                    e.reserved_event_id = t.eventId

                                                                                                                                    this.setState({ filteredProductsToShow: this.state.filteredProductsToShow })
                                                                                                                                    // this.setState({ selectedTiming: true, dropdownOpenTiming: false, selectedTimeIndex: index, reserved_event_id: t.eventId })
                                                                                                                                }
                                                                                                                            }}>{t.startTimeEvent}-{t.endTimeEvent} uur {isUitverkocht ? ` (Uitverkocht)` : ""}</li>)
                                                                                                                        })}
                                                                                                                    </ul>
                                                                                                                </div>
                                                                                                            </button>)}

                                                                                                            {(e.timings.length === 1 || (e.timings.length > 1 && e.selectedTiming)) && (<>
                                                                                                                {window.innerWidth > 1009 &&
                                                                                                                    this.state.currentUserHere &&
                                                                                                                    !this.state.currentUserCharityMember &&
                                                                                                                    this.getLocalEventSubscribeButton(e)}
                                                                                                                {this.state.currentUserHere &&
                                                                                                                    this.state.currentUserCharityMember &&
                                                                                                                    this.getWinkelmandbuttonUsersLocalBlock(e)}
                                                                                                            </>)}
                                                                                                        </div>}

                                                                                                    </div>
                                                                                                    <div className='labels-cltsmhnc'>
                                                                                                        {this.getUseOfVoucherlocalEvent(
                                                                                                            e
                                                                                                        )}
                                                                                                        {this.getfilelink(
                                                                                                            e
                                                                                                        )}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        })}
                                                                </div>
                                                                {this.state.filteredProductsToShow.length >
                                                                    2 && (
                                                                        <div className="bottom-csfs-shop">
                                                                            <div
                                                                                className="unds-buttons"
                                                                                style={{ alignItems: "center" }}
                                                                            >
                                                                                <font
                                                                                    style={{
                                                                                        fontSize: 13,
                                                                                        letterSpacing: 1,
                                                                                        marginRight: 8,
                                                                                        fontWeight: 600,
                                                                                        cursor: "pointer",
                                                                                    }}
                                                                                >
                                                                                    {this.state.onCurrentPage + 1}/
                                                                                    {arrSec.length}
                                                                                </font>
                                                                                {arrSec.length >
                                                                                    this.state.onCurrentPage + 1 && (
                                                                                        <div
                                                                                            className="inner-undb"
                                                                                            onClick={() => {
                                                                                                fullpageApi.moveSectionDown();
                                                                                            }}
                                                                                        >
                                                                                            <KeyboardArrowDownIcon />
                                                                                        </div>
                                                                                    )}
                                                                                {this.state.onCurrentPage > 0 && (
                                                                                    <div
                                                                                        className="inner-undb"
                                                                                        onClick={() => {
                                                                                            fullpageApi.moveSectionUp();
                                                                                        }}
                                                                                    >
                                                                                        <KeyboardArrowUpIcon />
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                );
                            }}
                        />
                    )}
                </div>


                {this.state.useOfVouchersPopup && (
                    <div className="overlay-popup-vdd"></div>
                )}
                {this.state.showDescriptionPopup && (
                    <div className="overlay-popup-vdd"></div>
                )}
                {this.state.termsTextPopup && <div className="overlay-popup-vdd"></div>}
                <div
                    className="card-popup-vdd"
                    style={{
                        opacity: this.state.useOfVouchersPopup ? 1 : 0,
                        pointerEvents: this.state.useOfVouchersPopup ? "all" : "none",
                        width: "100%",
                        maxWidth: 900,
                        padding: 30,
                    }}
                >
                    <CloseIcon
                        onClick={() => {
                            this.setState({ useOfVouchersPopup: false });
                        }}
                    />
                    <p className="main-title-cpv">Gebruik van voucher</p>
                    <p className="main-desc-cpv">{this.state.currentUseOfVoucher}</p>
                </div>
                <div
                    className="card-popup-vdd"
                    style={{
                        opacity: this.state.termsTextPopup ? 1 : 0,
                        pointerEvents: this.state.termsTextPopup ? "all" : "none",
                    }}
                >
                    <CloseIcon
                        onClick={() => {
                            this.setState({ termsTextPopup: false });
                        }}
                    />
                    <p className="main-title-cpv">VOORWAARDEN</p>
                    <p className="main-desc-cpv">{this.state.currentTermsText}</p>
                </div>
                <div
                    className="card-popup-vdd"
                    style={{
                        opacity: this.state.showDescriptionPopup ? 1 : 0,
                        pointerEvents: this.state.showDescriptionPopup ? "all" : "none",
                        width: "100%",
                        maxWidth: 900,
                    }}
                >
                    <CloseIcon
                        onClick={() => {
                            this.setState({ showDescriptionPopup: false });
                        }}
                    />
                    <p
                        className="main-title-cpv"
                    >
                        Omschrijving
                    </p>
                    <p className="main-desc-cpv">
                        <div dangerouslySetInnerHTML={this.createDescPopup(this.state.currentDesc)}></div>
                    </p>
                </div>

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducers.user,
        album: state.postsReducers.album,
        images: state.postsReducers.images,
        product: state.campaignsReducer.product,
        globalvouchers: state.campaignsReducer.globalvouchers,
        globalsectors: state.categoriesReducer.globalsectors,
        global_campaigns: state.campaignsReducer.global_campaigns,
        global_values: state.globalReducers.global_values,
        guest_user_cart: state.cartReducer.guest_user_cart,
        guest_user_cart_time: state.cartReducer.guest_user_cart_time,
        guest_user_cart_extend_opportunity: state.cartReducer.guest_user_cart_extend_opportunity,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateUser: (user) => dispatch(updateUser(user)),
        updateAlbums: (album) => dispatch(updateAlbums(album)),
        updateImages: (images) => dispatch(updateImages(images)),
        updateProducts: (product) => dispatch(updateProducts(product)),
        updateGlobalVouchers: (vouchers) =>
            dispatch(updateGlobalVouchers(vouchers)),
        updateAllSectors: (globalsectors) =>
            dispatch(updateAllSectors(globalsectors)),
        updateGuestUserCart: (guest_user_cart) => dispatch(updateGuestUserCart(guest_user_cart)),
        updateGuestUserCartTime: (guest_user_cart_time) => dispatch(updateGuestUserCartTime(guest_user_cart_time)),
        updateGuestUserCartExtendOpportunity: (guest_user_cart_extend_opportunity) => dispatch(updateGuestUserCartExtendOpportunity(guest_user_cart_extend_opportunity)),

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VouchersNewGridDesktop);
