import React, { Component } from 'react'
import charityVideo from "../assets/charity-page-video.mp4"
import firebase from '../Config/Firebase'
import Switch from '@material-ui/core/Switch';
import "./index.css"
import Footer from './Footer';
import FooterGreen from './FooterGreen';
import {
    updateAlbumToAdd,
    updateMoreAlbumsAdded,
    updateSelectedExclusive,
    updateGlobalStuff,
} from "../Redux/actions/authActions";
import { history } from '../Config/Routes';
import { connect } from 'react-redux';
import axios from "axios";

export class WizardMember extends Component {

    constructor() {
        super()
        this.state = {
            currentStep: 1,
            doAnimate: false,
            showDutch: true,
            member_trending_dutch: "",
            member_trending_english: "",
            member_stories_dutch: "",
            member_stories_english: "",
            member_vrienden_dutch: "",
            member_vrienden_english: "",
            member_charity_dutch: "",
            member_charity_english: "",
            member_trending_dutch_mobile: "",
            member_trending_english_mobile: "",
            member_stories_dutch_mobile: "",
            member_stories_english_mobile: "",
            member_vrienden_dutch_mobile: "",
            member_vrienden_english_mobile: "",
            member_charity_dutch_mobile: "",
            member_charity_english_mobile: "",
        }
    }

    componentDidMount() {
        if (this.props.atCurrentStep) {
            this.setState({ currentStep: this.props.atCurrentStep })
        }

        if (document.querySelector(".navbartodowhitedefault")) {
            document.querySelector(".navbartodowhitedefault").style.overflowX = `hidden`;
        }

        setTimeout(() => {
            this.setState({ doAnimate: true })
        }, 300);

        let res = axios.get(
            "https://us-central1-bhodi-production-da765.cloudfunctions.net/getIpLocation"
        );
        res.then((output) => {
            if (output.data.deta) {
                if (output.data.deta.country === "NL") {
                    this.setState({ showDutch: true });
                } else {
                    this.setState({ showDutch: false });
                }
            }
        });

        if (this.props.global_values) {
            let a = this.props.global_values;
            if (a) {
                this.setState({
                    member_trending_dutch: a.member_trending_dutch,
                    member_trending_english: a.member_trending_english,
                    member_stories_dutch: a.member_stories_dutch,
                    member_stories_english: a.member_stories_english,
                    member_vrienden_dutch: a.member_vrienden_dutch,
                    member_vrienden_english: a.member_vrienden_english,
                    member_charity_dutch: a.member_charity_dutch,
                    member_charity_english: a.member_charity_english,
                    member_trending_dutch_mobile: a.member_trending_dutch_mobile,
                    member_trending_english_mobile: a.member_trending_english_mobile,
                    member_stories_dutch_mobile: a.member_stories_dutch_mobile,
                    member_stories_english_mobile: a.member_stories_english_mobile,
                    member_vrienden_dutch_mobile: a.member_vrienden_dutch_mobile,
                    member_vrienden_english_mobile: a.member_vrienden_english_mobile,
                    member_charity_dutch_mobile: a.member_charity_dutch_mobile,
                    member_charity_english_mobile: a.member_charity_english_mobile,
                });
            }
        }

        firebase
            .database()
            .ref("bhodi_home")
            .on("value", (data) => {
                let a = data.val();
                if (a) {
                    this.props.updateGlobalStuff(a);
                    this.setState({
                        member_trending_dutch: a.member_trending_dutch,
                        member_trending_english: a.member_trending_english,
                        member_stories_dutch: a.member_stories_dutch,
                        member_stories_english: a.member_stories_english,
                        member_vrienden_dutch: a.member_vrienden_dutch,
                        member_vrienden_english: a.member_vrienden_english,
                        member_charity_dutch: a.member_charity_dutch,
                        member_charity_english: a.member_charity_english,
                        member_trending_dutch_mobile: a.member_trending_dutch_mobile,
                        member_trending_english_mobile: a.member_trending_english_mobile,
                        member_stories_dutch_mobile: a.member_stories_dutch_mobile,
                        member_stories_english_mobile: a.member_stories_english_mobile,
                        member_vrienden_dutch_mobile: a.member_vrienden_dutch_mobile,
                        member_vrienden_english_mobile: a.member_vrienden_english_mobile,
                        member_charity_dutch_mobile: a.member_charity_dutch_mobile,
                        member_charity_english_mobile: a.member_charity_english_mobile,
                    });
                }
            });
    }


    componentDidUpdate(prevProps) {
        if (this.props.atCurrentStep !== prevProps.atCurrentStep) {
            this.setState({ currentStep: this.props.atCurrentStep })
        }
    }

    createMarkup(htmlToMake) {
        return { __html: htmlToMake };
    }

    render() {
        return (
            <div className='wizard-main' style={{ height: window.innerWidth > 1008 ? window.innerHeight : "auto", minHeight: `${window.innerHeight - 200}px`, paddingTop: window.innerWidth < 1008 ? 30 : 0, paddingBottom: window.innerWidth < 1008 ? 60 : 0, backgroundColor: window.innerWidth < 1008 && "#285151" }}>
                {window.innerWidth > 1009 ? (<video
                    src={charityVideo}
                    autoPlay
                    playsInline
                    muted
                    loop
                    className="absolutevideofullbg"
                ></video>) : null}
                <div
                    className="third-sec-cdd"
                    style={{ paddingTop: 0, paddingBottom: 0, opacity: this.state.doAnimate ? 1 : 0, pointerEvents: this.state.doAnimate ? "all" : "none", backgroundColor: window.innerWidth > 1009 ? "transparent" : "#285151" }}
                >
                    <div
                        className="footer-sscdd"
                        style={{
                            marginTop: 0,
                            alignItems: "initial",
                            justifyContent: "center",
                            height: "auto",
                        }}
                    >
                        <div
                            className="left-fsscdd"
                            style={{
                                backgroundColor: "#285151",
                                maxWidth: "26%",
                                textAlign: "center",
                            }}
                        >
                            <div className="charity-lfss">
                                {this.state.currentStep === 1 ? (<div className='content-main-inner'>
                                    <p className="para-charity-lfss">
                                        <label className='bottom-line-pclf'>Trending</label>
                                        <br />
                                        <label style={{ color: "#C17F6B" }}>Hoe werkt het</label>
                                        {this.state.showDutch ? <div dangerouslySetInnerHTML={this.createMarkup(window.innerWidth > 1008 ? this.state.member_trending_dutch : this.state.member_trending_dutch_mobile)}></div> : <div dangerouslySetInnerHTML={this.createMarkup(window.innerWidth > 1008 ? this.state.member_trending_english : this.state.member_trending_english_mobile)}></div>}
                                    </p>
                                </div>) : this.state.currentStep === 2 ? (<div className='content-main-inner'>
                                    <p className="para-charity-lfss">
                                        <label className='bottom-line-pclf'>Stories</label>
                                        <br />
                                        <label style={{ color: "#C17F6B" }}>Hoe werkt het</label>
                                        {this.state.showDutch ? <div dangerouslySetInnerHTML={this.createMarkup(window.innerWidth > 1008 ? this.state.member_stories_dutch : this.state.member_stories_dutch_mobile)}></div> : <div dangerouslySetInnerHTML={this.createMarkup(window.innerWidth > 1008 ? this.state.member_stories_english : this.state.member_stories_english_mobile)}></div>}
                                    </p>
                                </div>) : this.state.currentStep === 3 ? (<div className='content-main-inner'>
                                    <p className="para-charity-lfss">
                                        <label className='bottom-line-pclf'>Vrienden</label>
                                        <br />
                                        <label style={{ color: "#C17F6B" }}>Hoe werkt het</label>
                                        {this.state.showDutch ? <div dangerouslySetInnerHTML={this.createMarkup(window.innerWidth > 1008 ? this.state.member_vrienden_dutch : this.state.member_vrienden_dutch_mobile)}></div> : <div dangerouslySetInnerHTML={this.createMarkup(window.innerWidth > 1008 ? this.state.member_vrienden_english : this.state.member_vrienden_english_mobile)}></div>}
                                    </p>
                                </div>) : (<div className='content-main-inner'>
                                    <p className="para-charity-lfss">
                                        <label className='bottom-line-pclf'>Charity</label>
                                        <br />
                                        <label style={{ color: "#C17F6B" }}>Hoe werkt het</label>
                                        {this.state.showDutch ? <div dangerouslySetInnerHTML={this.createMarkup(window.innerWidth > 1008 ? this.state.member_charity_dutch : this.state.member_charity_dutch_mobile)}></div> : <div dangerouslySetInnerHTML={this.createMarkup(window.innerWidth > 1008 ? this.state.member_charity_english : this.state.member_charity_english_mobile)}></div>}
                                    </p>
                                </div>)}
                                {this.state.currentStep > 2 && (<button
                                    className="button-charity-lfss"
                                    style={{ marginRight: 5 }}
                                    onClick={() => {
                                        if (this.state.currentStep > 2) {
                                            if (this.state.currentStep === 2) {
                                                localStorage.removeItem("onStoriesActive")

                                                setTimeout(() => {
                                                    window.location.href = `/member/${this.props.currentMemberData.user_name_id}`
                                                }, 200);
                                            } else if (this.state.currentStep === 4) {
                                                // this.props.updateAlbumToAdd(
                                                //     "mijn_public"
                                                // );
                                                // this.props.updateMoreAlbumsAdded([]);
                                                // this.props.updateSelectedExclusive("");
                                                // localStorage.setItem(
                                                //     "currentAlbumOwnImage",
                                                //     "own"
                                                // );
                                                // localStorage.setItem(
                                                //     "onStoriesActive",
                                                //     true
                                                // );
                                                // setTimeout(() => {
                                                //     window.location.href = `/member/${this.props.currentMemberData.user_name_id}`
                                                // }, 200);
                                                localStorage.removeItem("onStoriesActive")

                                                setTimeout(() => {
                                                    history.push(`/member/${this.props.currentMemberData.user_name_id}`)
                                                    // window.location.href = `/member/${this.props.currentMemberData.user_name_id}`
                                                }, 200);
                                            } else if (this.state.currentStep === 3) {
                                                localStorage.removeItem("onStoriesActive")
                                                history.push(`/member/${this.props.currentMemberData.user_name_id}/charity`)
                                            }
                                        }
                                    }}
                                >
                                    Vorige
                                </button>)}
                                {/* <button
                                    className="button-charity-lfss"
                                    style={{ marginRight: 5 }}
                                    onClick={() => {
                                        let userid = localStorage.getItem("userid")
                                        if (userid) {
                                            firebase.database().ref(`users/${userid}/hideWizard`).set(true)
                                        }
                                    }}
                                >
                                    HIDE
                                </button> */}
                                {(this.state.currentStep === 1 || this.state.currentStep === 2 || this.state.currentStep === 4) && (<button
                                    className="button-charity-lfss"
                                    onClick={() => {
                                        if (this.state.currentStep < 5) {
                                            if (this.state.currentStep === 1) {
                                                localStorage.setItem(
                                                    "currentAlbumOwnImage",
                                                    "own"
                                                );
                                                localStorage.setItem(
                                                    "onStoriesActive",
                                                    true
                                                );
                                                setTimeout(() => {
                                                    window.location.href = `/member/${this.props.currentMemberData.user_name_id}`
                                                    // history.push(`/member/${this.props.currentMemberData.user_name_id}`)
                                                }, 200);
                                            } else if (this.state.currentStep === 2) {
                                                history.push(`/member/${this.props.currentMemberData.user_name_id}/charity`)
                                            } else if (this.state.currentStep === 4) {
                                                if (window.innerWidth > 1008) {
                                                    history.push(`/member/${this.props.currentMemberData.user_name_id}/vriendenDesktop`)
                                                } else {
                                                    history.push(`/member/${this.props.currentMemberData.user_name_id}/vrienden`)
                                                }
                                            }
                                            // this.setState({ currentStep: currentStep + 1 })
                                        }
                                    }}
                                >
                                    Volgende
                                </button>)}
                                {/* <button className="button-charity-lfss" onClick={this.saveSubscription.bind(this)} style={{ backgroundColor: this.state.startSubLoader && "black", color: this.state.startSubLoader && "white" }}>{!this.state.startSubLoader ? <font>OPSLAAN </font> : <font><i class="fa fa-spinner fa-spin"></i> OPSLAAN</font>}</button> */}
                            </div>
                            <div className="add-items-miur" style={{ padding: "10px 0px" }}>
                                <Switch
                                    color="primary"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                    onClick={() => {
                                        let userid = localStorage.getItem("userid")
                                        if (userid) {
                                            firebase.database().ref(`users/${userid}/hideWizard`).set(true)
                                        }
                                    }}
                                />
                                <label>Niet meer tonen</label>
                            </div>
                        </div>
                    </div>
                </div>
                {window.innerWidth > 1008 && <Footer black />}
                {window.innerWidth < 1008 && <FooterGreen black />}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        albumToAdd: state.imagesReducer.albumToAdd,
        morealbumsToAdd: state.imagesReducer.morealbumsToAdd,
        selected_exclusive: state.exclusiveReducers.selected_exclusive,
        global_values: state.globalReducers.global_values,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateAlbumToAdd: (albumToAdd) => dispatch(updateAlbumToAdd(albumToAdd)),
        updateMoreAlbumsAdded: (morealbumsToAdd) =>
            dispatch(updateMoreAlbumsAdded(morealbumsToAdd)),
        updateSelectedExclusive: (selected_exclusive) =>
            dispatch(updateSelectedExclusive(selected_exclusive)),
        updateGlobalStuff: (global_values) =>
            dispatch(updateGlobalStuff(global_values)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WizardMember);