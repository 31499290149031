import React, { Component } from "react";
import "./index.css";
import firebase from "../../../Config/Firebase";
import Footer from "../../../Components/Footer";
import {
  updateUser,
  updateAlbums,
  updateImages,
  updateProducts,
  updateGlobalVouchers,
  updateAllSectors,
} from "../../../Redux/actions/authActions";
import { connect } from "react-redux";
import ReactFullpage from "@fullpage/react-fullpage";
import NavGlobalVoucherCity from "../../../Components/NavGlobalVoucherCity";
import CharityPopup from "../../../Components/CharityPopup";
import { Country, City } from "country-state-city";
import TimerGlobalPages from "../../../Components/TimerGlobalPages";
import VouchersNewMobile from "../../VouchersNewMobile";
import NavbarMainFullWidth from "../../../Components/NavbarMainFullWidth";
import NavbarMainFullWidthCompany from "../../../Components/NavSecMainFullWidthCompany";
import { history } from "../../../Config/Routes";
import NavbarWrapperVisitorMobile from "../../../Components/NavbarWrapperVisitorMobile";
import VouchersNewGridDesktop from "../../../Components/VouchersNewGridDesktop";
import FriendListRightMenu from "../../../Components/FriendListRightMenu";

export class VouchersGlobalMainCity extends Component {
  constructor() {
    super();
    this.state = {
      beschikbaar: true,
      popuprightToShow: false,
      titleProduct: "",
      normalPriceProduct: "",
      charityPriceProduct: "",
      descProduct: "",
      currentImageIndex: 0,
      arrImages: [],
      addCategory: false,
      gebruikers: false,
      addTimer: false,
      activateTimer: false,
      obj: {
        "category 1": {
          sub_category: "sub category 1",
        },
        "category 2": {
          sub_category: "sub category 2",
        },
      },
      isMain: "category 1",
      selectedMain: "category 1",
      selectedSub: "sub category 1",
      isSub: "sub category 1",
      daysIndex: 0,
      hoursIndex: -1,
      minutesIndex: -1,
      defaultQty: 1,
      allProducts: [],
      dropFile: [],
      filesName: [],
      productsIndex: 0,
      reachedProductsIndex: 0,
      hour: "",
      minutes: "",
      productActivated: true,
      firstTime: true,
      beforeUnloaded: false,
      currentUserProduct: true,
      newerProducts: false,
      seenProductsCheck: false,
      newProducts: [],
      seenProducts: [],
      currentProductsShowing: [],
      showAllProducts: false,
      arrCurrentProducts: [],
      cartData: [],
      selectCategoryPopup: false,
      uploadImagePopup: false,
      productDetailOpen: false,
      dayBigger: false,
      showingBookMarkProducts: false,
      allMonths: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
      hours: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23,
      ],
      productStatus: ["exclusive", "normal"],
      productStatusIndex: 0,
      sectors: [],
      currentSector: "",
      currentSectorId: "",
      categoryName: "",
      currentSector: "",
      all_categories: [],
      sub_categories: [],
      completeCategories: [],
      subCategoryPresent: true,
      currentUserHere: "",
      fullpageApi: "",
      scrollStart: true,
      scrollStop: false,
      eventDate: "",
      startHour: "",
      endHour: "",
      startMinute: "",
      endMinute: "",
      showTimerFieldsEmptyWork: false,
      counterMinute: 1,
      counterSecond: 59,
      currentActie: "",
      dateToday: "",
      endDateToday: "",
      muchDaysEvent: false,
      payOnline: false,
      isDesktop: false,
      currentProductDetailed: "",
      allProductsFiltered: [],
      noFilteredProducts: false,
      searchText: "",
      availableMainCategories: [],
      availableSectors: [],
      availableSubCategories: [],
      categoriesDropdown: [],
      subCategoriesCheck: [],
      subCategoriesDropdown: [],
      checkedToFilter: [],
      allProductsDup: [],
      allProductsFilteredDup: [],
      selectSectorsPopup: false,
      allProductsForSectors: [],
      allSectors: [],
      categoriesToFilter: [],
      latitude: "",
      longitude: "",
      firstSectionToShow: [],
      onCurrentPage: 0,
      allCampaigns: [],
      currentVoucherWork: "",
      activeSection: 0,
      hideFullpage: false,
      showOptionsToAdd: false,
      showDescriptionPopup: false,
      codegebruikpopup: false,
      codebewaarpopup: false,
      selectedDish: 0,
      alreadyBewared: [],
      mustShowAnimation: false,
      useOfVoucherOpen: false,
      exceptionsVoucherOpen: false,
      allVouchers: [],
      completeArrProducts: [],
      filteredProductsToShow: [],
    };
  }

  componentDidMount() {
    const loaderId = document.getElementById("main-page-loader-id");
    loaderId.style.display = "none";
    window.addEventListener("resize", () => {
      if (window.innerWidth < 1009) {
        this.setState({ isDesktop: false });
      } else {
        this.setState({ isDesktop: true });
      }
    });
    if (window.innerWidth < 1009) {
      this.setState({ isDesktop: false });
    } else {
      this.setState({ isDesktop: true });
    }

    if (this.props.global_values) {
      let a = this.props.global_values;
      if (a) {
        if (a.globalOverlay) {
          this.setState({ globalOverlay: a.globalOverlay });
        }
        if (a.showGlobalTimer) {
          this.setState({ showOverlay: true, timerDate: a.globalTimerDate, timerTime: a.globalTimerTime })
        } else {
          this.setState({ showOverlay: false })
        }
      }
    }

    let splitted = this.props.match.params.coords.split("=");
    let splittedAnother = splitted[1].split("&");

    let latitude = "";
    let longitude = "";

    if (splittedAnother.length > 0) {
      latitude = splittedAnother[0];
      longitude = splittedAnother[1];
    }

    this.setState({ latitude, longitude });

    let uid = localStorage.getItem("userid");
    this.setState({ currentUserId: uid });
    if (!uid) {
      this.setState({ isGuest: true });
    } else {
      this.setState({ isGuest: false });
    }

    // setTimeout(() => {
    //     let elems = document.getElementById("#fullpage")
    //     console.log(elems)
    //     elems[0].classList.remove("active")
    //     elems[1].className += " active"
    // }, 10);

    if (window.innerWidth < 1009) {
      this.setState({ isDesktop: false });
    } else {
      document.getElementById("root").style.position = "fixed";
      document.getElementById("root").style.overflow = "hidden";
      this.setState({ isDesktop: true });
    }

    if (this.props.globalsectors) {
      if (this.props.globalsectors.length > 0) {
        this.setState({ allSectors: this.props.globalsectors });
      }
    }
    firebase
      .database()
      .ref("all_sectors")
      .on("value", (data) => {
        let a = data.val();
        let arr = [];
        if (a) {
          let objected = Object.entries(a);
          objected.map((h) => {
            arr.push(h[1]);
          });
          this.props.updateAllSectors(arr);
          this.setState({ allSectors: arr });
        } else {
          this.setState({ allSectors: [] });
        }
      });

    // firebase
    //   .database()
    //   .ref("all_carts/" + uid)
    //   .on("value", (data) => {
    //     let a = data.val();
    //     if (a) {
    //       let objected = Object.entries(a);
    //       this.setState({ cartData: objected });
    //     } else {
    //       this.setState({ cartData: [] });
    //     }
    //   });
    let localCurrent = localStorage.getItem("onCurrentSection");
    if (this.props.global_campaigns) {
      if (this.props.global_campaigns.length > 0) {
        let objected = this.props.global_campaigns;
        let arr = [];
        let allCampaignsCompletedToRender = [];
        objected.map((e) => {
          e[1].type = "campaign";
          e[1].currentImageIndex = 0;


          allCampaignsCompletedToRender.push(e[1]);
          if (e[1].desc) {
            // e[1].desc = e[1].desc.substr(0, 200)
            e[1].short_desc = e[1].desc.substr(0, 32);
          }
          e[1].images = [e[1].image];
          e[1].imagesObjected = [
            { image: e[1].image, pushKey: e[1].pushKey },
          ];

          // if (e[1].created_by === uid) {
          //     arr.push(e[1])
          // } else {
          if (e[1].active) {
            if (e[1].campaignType === "kortings_voucher") {
              if (e[1].hideOnGlobal) {
                return false;
              }
              let objectedVerzilveredBy = [];
              let num = 0;
              if (e[1].verzilvered_by) {
                objectedVerzilveredBy = Object.entries(e[1].verzilvered_by);
                num = this.getVerzilveredQty(objectedVerzilveredBy);
              }
              let bewaaredArray = [];

              if (e[1].inCartOf) {
                num = num + Object.entries(e[1].inCartOf).length
              }
              if (!e[1].kortingsVoucherUnlimited) {
                if (num + bewaaredArray.length < Number(e[1].number)) {
                  arr.push(e[1]);
                }
              } else {
                arr.push(e[1]);
              }
            }
            if (e[1].campaignType === "web_shop") {
              if (e[1].hideOnGlobal) {
                return false;
              }
              let objectedVerzilveredBy = [];
              let num = 0;
              if (e[1].verzilvered_by) {
                objectedVerzilveredBy = Object.entries(e[1].verzilvered_by);
                num = this.getVerzilveredQty(objectedVerzilveredBy);
              }
              let bewaaredArray = [];
              if (e[1].inCartOf) {
                num = num + Object.entries(e[1].inCartOf).length
              }
              if (!e[1].voucherUnlimited) {
                if (num + bewaaredArray.length < Number(e[1].number)) {
                  arr.push(e[1]);
                }
              } else {
                arr.push(e[1]);
              }
            }
          }
          // }
        });
        let arrToMake = [];
        arr.map((t, i) => {
          if (t.campaignType !== "last_minute") {
            // arr.splice(i, 1)
            arrToMake.push(t);
          }
        });
        this.setState({ allCampaigns: arrToMake });
      }
    }
    if (localCurrent) {
      setTimeout(() => {
        localStorage.removeItem("onCurrentSection");
        if (this.state.fullpageApi) {
          this.state.fullpageApi.moveTo(Number(localCurrent), 0)
        }
        // this.setState({ activeSection: Number(localCurrent) });
      }, 1000);
    }

    let promise = new Promise((resolve, reject) => {
      // navigator.geolocation.getCurrentPosition((position) => {
      //     this.setState({ latitude: position.coords.latitude, longitude: position.coords.longitude })
      // });
      let latitude = localStorage.getItem("latitudeAfterLogin");
      let longitude = localStorage.getItem("longitudeAfterLogin");
      if (latitude) {
        this.setState({ latitude, longitude });
      }
      if (uid) {
        if (this.props.user) {
          if (this.props.user.length > 0) {
            let filtered = this.props.user.filter((y) => {
              return y.user_id === uid;
            });
            if (filtered.length > 0) {
              this.setState({ currentUserHere: filtered[0] });
              resolve();
            }
            // navigator.geolocation.getCurrentPosition((position) => {
            //     this.setState({ latitude: position.coords.latitude, longitude: position.coords.longitude })
            // });
            let latitude = localStorage.getItem("latitudeAfterLogin");
            let longitude = localStorage.getItem("longitudeAfterLogin");
            if (latitude) {
              this.setState({ latitude, longitude });
            }
          }
        }
      } else {
        resolve();
      }
    });
    promise.then(() => {
      let arrCurrentProducts = this.state.allVouchers.concat(
        this.state.allCampaigns
      );
      let arrDistanced = [];
      if (arrCurrentProducts.length > 0) {
        arrCurrentProducts.map((y) => {
          let current = this.props.user.filter((u) => {
            return u.user_id === y.created_by;
          });
          let filteredHere = this.props.user.filter((y) => {
            return y.user_id === uid;
          });
          if (current[0].blocked) {
            let filtered = Object.entries(current[0].blocked).filter((t) => {
              return t[1].user_id === uid
            })
            if (filtered.length > 0) {
              return false;
            }
          }
          if (filteredHere[0].blocked) {
            let filtered = Object.entries(filteredHere[0].blocked).filter((t) => {
              return t[1].user_id === current[0].user_id
            })
            if (filtered.length > 0) {
              return false;
            }
          }
          if (current[0].latitude && current[0].longitude) {
            let lat1 = latitude;
            let lat2 = current[0].latitude;
            let lon1 = longitude;
            let lon2 = current[0].longitude;

            var rad = function (x) {
              return (x * Math.PI) / 180;
            };

            var R = 6378137; // Earth’s mean radius in meter
            var dLat = rad(lat2 - lat1);
            var dLong = rad(lon2 - lon1);
            var a =
              Math.sin(dLat / 2) * Math.sin(dLat / 2) +
              Math.cos(rad(lat1)) *
              Math.cos(rad(lat2)) *
              Math.sin(dLong / 2) *
              Math.sin(dLong / 2);
            var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            var d = R * c;
            y.distanceToSort = d;
          } else {
            if (current[0].country) {
              let filteredCountry = Country.getAllCountries().filter((t) => {
                if (
                  current[0].country === "Netherlands" ||
                  current[0].country === "Netherland"
                ) {
                  return t.name === "Netherlands The";
                } else {
                  return t.name.toLowerCase() === current[0].country.toLowerCase();
                }
              });
              if (filteredCountry.length > 0) {
                let allCities = City.getCitiesOfCountry(
                  filteredCountry[0].isoCode
                );
                if (current[0].city) {
                  let filteredCityForLatLng = allCities.filter((t) => {
                    return t.name.toLowerCase() === current[0].city.toLowerCase()
                  });
                  if (filteredCityForLatLng.length > 0) {
                    let lat1 = latitude;
                    let lat2 = Number(filteredCityForLatLng[0].latitude);
                    let lon1 = longitude;
                    let lon2 = Number(filteredCityForLatLng[0].longitude);

                    var rad = function (x) {
                      return (x * Math.PI) / 180;
                    };

                    var R = 6378137; // Earth’s mean radius in meter
                    var dLat = rad(lat2 - lat1);
                    var dLong = rad(lon2 - lon1);
                    var a =
                      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                      Math.cos(rad(lat1)) *
                      Math.cos(rad(lat2)) *
                      Math.sin(dLong / 2) *
                      Math.sin(dLong / 2);
                    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
                    var d = R * c;

                    y.distanceToSort = d;
                  } else {
                    y.distanceToSort = 9999999999;
                  }
                } else {
                  y.distanceToSort = 9999999999;
                }
              } else {
                y.distanceToSort = 9999999999;
              }
            } else {
              y.distanceToSort = 9999999999;
            }
          }
          if (current[0].profileActive) {
            arrDistanced.push(y);
          }
        });
        // arrDistanced = arrDistanced.sort((a, b) => {
        //     return a.distanceToSort - b.distanceToSort
        // })
        let filtered = arrDistanced.filter((y) => {
          if (y.main_category) {
            return y.main_category.includes(
              this.props.match.params.maincategory
            );
          }
        });
        filtered = filtered.sort((a, b) => {
          return a.distanceToSort - b.distanceToSort;
        });

        let allFiltered = arrDistanced.filter((y) => {
          if (y.main_category) {
            return y.main_category.includes(
              this.props.match.params.maincategory
            );
          }
        });

        allFiltered = allFiltered.sort((a, b) => {
          return a.distanceToSort - b.distanceToSort;
        });


        if (allFiltered.length > 0) {
          this.setState({
            allProducts: filtered,
            completeArrProducts: allFiltered,
            filteredProductsToShow: allFiltered,
            allProductsDup: arrCurrentProducts,
            allProductsForSectors: arrCurrentProducts,
            noProducts: false,
          });
        } else {
          if (this.props.user) {
            let toRedirect = this.props.user.filter((h) => {
              return h.user_id === uid;
            });
            if (toRedirect.length > 0) {
              if (toRedirect[0].type === "user") {
                window.location.href = `/member/${toRedirect[0].user_name_id}`;
              } else {
                window.location.href = `/company/${toRedirect[0].user_name_id}`;
              }
            } else {
              window.location.href = "/";
            }
          } else {
            window.location.href = "/";
          }
        }
      } else {
        if (this.props.user) {
          let toRedirect = this.props.user.filter((h) => {
            return h.user_id === uid;
          });
          if (toRedirect.length > 0) {
            if (toRedirect[0].type === "user") {
              window.location.href = `/member/${toRedirect[0].user_name_id}`;
            } else {
              window.location.href = `/company/${toRedirect[0].user_name_id}`;
            }
          } else {
            window.location.href = "/";
          }
        } else {
          window.location.href = "/";
        }
        this.setState({
          noProducts: true,
          allProducts: [],
          completeArrProducts: [],
          filteredProductsToShow: [],
          allProductsForSectors: [],
          allProductsDup: [],
        });
      }
    });
    // firebase.database().ref("bewaar_vouchers/" + uid).on("value", (data) => {
    //     let a = data.val()
    //     if (a) {
    //         let objected = Object.entries(a)
    //         let arr = []
    //         objected.map((y) => {
    //             let productTimeStamp = new Date(Number(y[1].added_on))

    //             var futureDate = new Date();

    //             let diffInMilliSeconds = Math.abs(futureDate - productTimeStamp) / 1000;

    //             const days = Math.floor(diffInMilliSeconds / 86400);
    //             diffInMilliSeconds -= days * 86400;

    //             const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    //             diffInMilliSeconds -= hours * 3600;

    //             const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    //             diffInMilliSeconds -= minutes * 60;

    //             let difference = '';

    //             difference += (hours === 0 || hours === 1) ? `${hours}:` : `${hours}:`;

    //             difference += (minutes === 0 || hours === 1) ? `${minutes}` : `${minutes}`;

    //             if (days === 0) {
    //                 arr.push(y[1])
    //             }
    //         })
    //         if (arr.length > 0) {
    //             this.interval = setInterval(() => this.setState({ timeCurrent: Date.now() }), 1000);
    //         }
    //         this.setState({ alreadyBewared: arr })
    //     } else {
    //         this.setState({ alreadyBewared: [] })
    //     }
    // })
  }

  onLeave(origin, destination, direction) {
    if (Number(origin.index) > 0) {
      // this.state.arrToHideOrShow[origin.index - 1].show = false
      this.setState({ countsArray: [] });
    }
  }

  getCharityObjectedQty(e) {
    let arrItems = e;
    if (arrItems.length > 0) {
      Array.prototype.sum = function (prop) {
        var total = 0;
        for (var i = 0, _len = this.length; i < _len; i++) {
          total += Number(this[i][1][prop]);
        }
        return total;
      };
      return arrItems.sum("used_time");
    }
  }

  getVerzilveredQty(e) {
    let arrItems = e;
    if (arrItems.length > 0) {
      Array.prototype.sum = function (prop) {
        var total = 0;
        for (var i = 0, _len = this.length; i < _len; i++) {
          total += Number(this[i][1][prop].length);
        }
        return total;
      };
      return arrItems.sum("allDates");
    }
  }

  afterLoad(origin, destination, direction) {
    this.setState({ countsArray: [], onCurrentPage: destination.index });
  }

  getSectorName() {
    if (this.state.allSectors.length > 0) {
      let filtered = this.state.allSectors.filter((t) => {
        return t.sector_id === this.props.match.params.sectorid;
      });
      return filtered[0].sector_name;
    }
  }

  getSectorImage() {
    if (this.state.allSectors.length > 0) {
      let filtered = this.state.allSectors.filter((t) => {
        return t.sector_id === this.props.match.params.sectorid;
      });
      if (filtered.length > 0) {
        if (filtered[0].image) {
          return filtered[0].image;
        } else {
          return "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9";
        }
      }
    }
  }

  getSectorRedirect() {
    if (this.state.allSectors.length > 0) {
      let filtered = this.state.allSectors.filter((t) => {
        return t.sector_id === this.props.match.params.sectorid;
      });
      if (filtered.length > 0) {
        window.location.href = `/global-vouchers/${filtered[0].sector_id}`;
      }
    }
  }

  getTouches(evt) {
    return (
      evt.touches || // browser API
      evt.originalEvent.touches
    ); // jQuery
  }

  getSellerCity(e) {
    let filtered = this.props.user.filter((g) => {
      return g.user_id === e.created_by;
    });
    if (filtered.length > 0) {
      return filtered[0].city;
    }
  }

  componentDidUpdate(prevProps) {
    let uid = localStorage.getItem("userid");
    if (prevProps.global_values !== this.props.global_values) {
      let a = this.props.global_values;
      if (a) {
        if (a.showGlobalTimer) {
          let dateTimer = new Date(Number(a.globalTimerDate.split("-")[0]), Number(a.globalTimerDate.split("-")[1]) - 1, Number(a.globalTimerDate.split("-")[2]), Number(a.globalTimerTime.split(":")[0]), Number(a.globalTimerTime.split(":")[1]), 0).getTime()
          let dateYet = new Date().getTime()
          if (dateYet < dateTimer) {
            this.setState({ showOverlay: true, timerDate: a.globalTimerDate, timerTime: a.globalTimerTime })
          }
        } else {
          this.setState({ showOverlay: false })
        }
      }
    }
    if (this.props.global_campaigns !== prevProps.global_campaigns) {
      let allArrs = this.props.global_campaigns;
      let allKeysCurrent = [];
      let allKeysComingCampaigns = [];
      this.state.filteredProductsToShow.map((u) => {
        allKeysCurrent.push(u.pushKey);
      });

      this.state.allProducts.map((t, i) => {
        let checkProductDeleted = allArrs.filter((y) => {
          return t.pushKey === y[1].pushKey
        })
        if (checkProductDeleted.length === 0) {
          this.state.allProducts[i].showPopupNotLeft = true
        }
      });
      this.state.filteredProductsToShow.map((t, i) => {
        let checkProductDeleted = allArrs.filter((y) => {
          return t.pushKey === y[1].pushKey
        })
        if (checkProductDeleted.length === 0) {
          this.state.filteredProductsToShow[i].showPopupNotLeft = true
        }
      });
      allArrs.map((y) => {
        y[1].type = "campaign";
        y[1].currentImageIndex = 0;
        allKeysComingCampaigns.push(y[1].pushKey);

        if (y[1].desc) {
          // y[1].desc = y[1].desc.substr(0, 200)
          y[1].short_desc = y[1].desc.substr(0, 32);
        }
        y[1].images = [y[1].image];
        y[1].imagesObjected = [{ image: y[1].image, pushKey: y[1].pushKey }];

        if (y[1].campaignType === "kortings_voucher") {
          this.state.allProducts.map((t, i) => {
            if (t.pushKey === y[1].pushKey) {
              if (!y[1].active) {
                y[1].showPopupNotLeft = true;
              } else {
                if (!y[1].kortingsVoucherUnlimited) {
                  let bewaaredArray = [];
                  let num = 0;

                  if (y[1].verzilvered_by) {
                    let objectedVerzilvered = Object.entries(
                      y[1].verzilvered_by
                    );
                    num = this.getVerzilveredQty(objectedVerzilvered);
                  }
                  if (num + bewaaredArray.length >= Number(y[1].number)) {
                    y[1].showPopupNotLeft = true;
                  } else {
                    y[1].showPopupNotLeft = false;
                  }
                } else {
                  y[1].showPopupNotLeft = false;
                }
              }
              this.state.allProducts[i] = y[1];
            }
          });

          this.state.filteredProductsToShow.map((t, i) => {
            if (t.pushKey === y[1].pushKey) {
              if (!y[1].active) {
                y[1].showPopupNotLeft = true;
              } else {
                if (!y[1].kortingsVoucherUnlimited) {
                  let bewaaredArray = [];
                  let num = 0;

                  if (y[1].verzilvered_by) {
                    let objectedVerzilvered = Object.entries(
                      y[1].verzilvered_by
                    );
                    num = this.getVerzilveredQty(objectedVerzilvered);
                  }
                  if (num + bewaaredArray.length >= Number(y[1].number)) {
                    y[1].showPopupNotLeft = true;
                  } else {
                    y[1].showPopupNotLeft = false;
                  }
                } else {
                  y[1].showPopupNotLeft = false;
                }
              }
              this.state.filteredProductsToShow[i] = y[1];
            }
          });
        }
        if (y[1].campaignType === "web_shop" && !y[1].isoEndTime) {
          this.state.allProducts.map((t, i) => {
            if (t.pushKey === y[1].pushKey) {
              if (!y[1].active) {
                y[1].showPopupNotLeft = true;
              } else {
                if (!y[1].voucherUnlimited) {
                  let bewaaredArray = [];
                  let num = 0;

                  if (y[1].verzilvered_by) {
                    let objectedVerzilvered = Object.entries(
                      y[1].verzilvered_by
                    );
                    num = this.getVerzilveredQty(objectedVerzilvered);
                  }
                  if (num + bewaaredArray.length >= Number(y[1].number)) {
                    y[1].showPopupNotLeft = true;
                  } else {
                    y[1].showPopupNotLeft = false;
                  }
                } else {
                  y[1].showPopupNotLeft = false;
                }
              }
              this.state.allProducts[i] = y[1];
            }
          });

          this.state.filteredProductsToShow.map((t, i) => {
            if (t.pushKey === y[1].pushKey) {
              if (!y[1].active) {
                y[1].showPopupNotLeft = true;
              } else {
                if (!y[1].voucherUnlimited) {
                  let bewaaredArray = [];
                  let num = 0;

                  if (y[1].verzilvered_by) {
                    let objectedVerzilvered = Object.entries(
                      y[1].verzilvered_by
                    );
                    num = this.getVerzilveredQty(objectedVerzilvered);
                  }
                  if (num + bewaaredArray.length >= Number(y[1].number)) {
                    y[1].showPopupNotLeft = true;
                  } else {
                    y[1].showPopupNotLeft = false;
                  }
                } else {
                  y[1].showPopupNotLeft = false;
                }
              }
              this.state.filteredProductsToShow[i] = y[1];
            }
          });
        }
      });
      this.setState({
        allProducts: this.state.allProducts,
        firstSectionToShow: this.state.firstSectionToShow,
      });
    }
    if (this.props.user !== prevProps.user) {
      if (this.props.user.length > 0) {
        let filtered = this.props.user.filter((y) => {
          return y.user_id === uid;
        });
        if (filtered.length > 0) {
          this.setState({ currentUserHere: filtered[0] });
        }
        // navigator.geolocation.getCurrentPosition((position) => {
        //     this.setState({ latitude: position.coords.latitude, longitude: position.coords.longitude })
        // });
        let latitude = localStorage.getItem("latitudeAfterLogin");
        let longitude = localStorage.getItem("longitudeAfterLogin");
        if (latitude) {
          this.setState({ latitude, longitude });
        }
      }
    }
  }

  getSellerName(e) {
    let filtered = this.props.user.filter((g) => {
      return g.user_id === e.created_by;
    });
    if (filtered.length > 0) {
      return filtered[0].company_name;
    }
  }

  getSellerImage(e) {
    let filtered = this.props.user.filter((g) => {
      return g.user_id === e.created_by;
    });
    if (filtered.length > 0) {
      if (filtered[0].profile_image) {
        return filtered[0].profile_image;
      } else {
        return "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9";
      }
    }
  }

  render() {
    return (
      this.state.showOverlay ? <TimerGlobalPages timerDate={this.state.timerDate} timerTime={this.state.timerTime} closeScreen={() => {
        this.setState({ showOverlay: false })
      }} /> : <div
        className={`vouchersglobalpagemaintodoWhite ${this.state.firstSectionToShow.length === 1 &&
          "dofullwidthvouchersectionmain"
          }`}
      >

        {this.state.isDesktop && (<>
          {this.state.currentUserHere.type === "company" ? (<NavbarMainFullWidthCompany
            menuItemsShowing={true}
            member_id={this.state.currentUserHere.user_name_id}
            showPosts={() => {
              localStorage.setItem("onCurrentTabCompany", "showLists");
              history.push(
                `/company/${this.state.currentUserHere.user_name_id}`
              );
            }}
            showFriends={() => {
              this.setState({ showRightMenuFriends: true });
            }}
            showProductsOfFriends={() => {
              localStorage.setItem(
                "onCurrentTabCompany",
                "showproductsfriends"
              );
              // Commented by me
              history.push(
                `/company/${this.state.currentUserHere.user_name_id}/volgend`
              );
            }}
            showCharity={() => {
              localStorage.setItem(
                "onCurrentTabCompany",
                "showCharity"
              );
              // Commented by me
              history.push(
                `/company/${this.state.currentUserHere.user_name_id}/charity`
              );
              // window.location.href = `/company/${this.state.currentMemberData.user_name_id}/charity`;
            }}
            hideMenuWork={false}

            onGlobalPageVoucher={true}
            sector={this.props.match.params.sectorid}
            onMainCategory={true}
            category={this.props.match.params.maincategory}
            noShowCity={true}
            searchFiltered={(e) => {
              if (e !== "") {
                let filtered = this.state.completeArrProducts.filter(
                  (y) => {
                    let filteredUser = this.props.user.filter((j) => {
                      return j.user_id === y.created_by;
                    });
                    if (filteredUser.length > 0) {
                      return (
                        y.title
                          .toLowerCase()
                          .includes(e.toLowerCase()) || y.desc.toLowerCase().includes(e.toLowerCase()) ||
                        filteredUser[0].company_name
                          .toLowerCase()
                          .includes(e.toLowerCase())
                      );
                    }
                  }
                );

                let filteredHere = [...filtered];

                if (filtered.length > 0) {
                  this.setState({
                    allProducts: [],
                  });

                  setTimeout(() => {
                    this.setState({
                      allProducts: filtered,
                      filteredProductsToShow: filteredHere,
                    });
                  }, 10);
                } else {
                  this.setState({
                    allProducts: [],
                    filteredProductsToShow: [],
                  });
                }
              } else {
                let filtered = [...this.state.completeArrProducts];
                let filteredHere = [...filtered];

                this.setState({
                  allProducts: [],
                });

                setTimeout(() => {
                  this.setState({
                    allProducts: filtered,
                    filteredProductsToShow: filteredHere,
                  });
                }, 10);
              }
            }}
          />) : (<NavbarMainFullWidth
            member_id={this.state.currentUserHere.user_name_id}
            linkToVolgend={`/member/${this.state.currentUserHere.user_name_id}/volgend`}
            linkToCharity={`/member/${this.state.currentUserHere.user_name_id}/charity`}
            showProducts={() => {
              localStorage.setItem("onCurrentTab", "products");
            }}
            showFriends={() => {
              this.setState({ showRightMenuFriends: true });
            }}
            showCharity={() => {
              localStorage.setItem("onCurrentTab", "charitypage");
            }}


            onGlobalPageVoucher={true}
            sector={this.props.match.params.sectorid}
            onMainCategory={true}
            category={this.props.match.params.maincategory}
            noShowCity={true}
            searchFiltered={(e) => {
              if (e !== "") {
                let filtered = this.state.completeArrProducts.filter(
                  (y) => {
                    let filteredUser = this.props.user.filter((j) => {
                      return j.user_id === y.created_by;
                    });
                    if (filteredUser.length > 0) {
                      return (
                        y.title
                          .toLowerCase()
                          .includes(e.toLowerCase()) || y.desc.toLowerCase().includes(e.toLowerCase()) ||
                        filteredUser[0].company_name
                          .toLowerCase()
                          .includes(e.toLowerCase())
                      );
                    }
                  }
                );

                let filteredHere = [...filtered];

                if (filtered.length > 0) {
                  this.setState({
                    allProducts: [],
                  });

                  setTimeout(() => {
                    this.setState({
                      allProducts: filtered,
                      filteredProductsToShow: filteredHere,
                    });
                  }, 10);
                } else {
                  this.setState({
                    allProducts: [],
                    filteredProductsToShow: [],
                  });
                }
              } else {
                let filtered = [...this.state.completeArrProducts];
                let filteredHere = [...filtered];

                this.setState({
                  allProducts: [],
                });

                setTimeout(() => {
                  this.setState({
                    allProducts: filtered,
                    filteredProductsToShow: filteredHere,
                  });
                }, 10);
              }
            }}
          />)}
        </>)}
        {/* friends list */}
        {this.state.showRightMenuFriends && (
          <div
            className="overlay-div-openadd"
            onClick={() => {
              this.setState({ showRightMenuFriends: false });
            }}
          ></div>
        )}
        {this.state.showRightMenuFriends && (
          <FriendListRightMenu
            closePopup={(type) => {
              if (type === "fromfriendscompletely") {
                this.setState({ closeCompleteRightMenu: true });
              }
              this.setState({ showRightMenuFriends: false });
            }}
          />
        )}
        {this.state.isDesktop ? (
          <div>
            <VouchersNewGridDesktop
              completeArrProducts={this.state.completeArrProducts}
              filteredProductsToShow={this.state.filteredProductsToShow}
              allProducts={this.state.allProducts}
              noProducts={this.state.noProducts}
            />
            {!this.state.popuprightToShow && !this.state.productDetailOpen && (
              <Footer getData={this.state.deletingItem} notOnMainPage={true} />
            )}
          </div>
        ) : (
          <div style={{ backgroundColor: "white" }} className="new-vouchers-page nvp-total-fixed">
            <NavbarWrapperVisitorMobile showSearchOnGlobal={true}
              searchFiltered={(e) => {
                if (e !== "") {
                  let filtered = [...this.state.filteredProductsToShow].filter(
                    (y) => {
                      let filteredUser = this.props.user.filter((j) => {
                        return j.user_id === y.created_by;
                      });
                      if (filteredUser.length > 0) {
                        return (
                          y.title.toLowerCase().includes(e.toLowerCase()) || y.desc.toLowerCase().includes(e.toLowerCase()) ||
                          filteredUser[0].company_name
                            .toLowerCase()
                            .includes(e.toLowerCase())
                        );
                      }
                    }
                  );

                  this.setState({
                    completeArrProducts: [],
                    noProductsHere: false,
                  });
                  setTimeout(() => {
                    if (filtered.length > 0) {
                      this.setState({
                        completeArrProducts: filtered,
                        noProductsHere: true,
                      });
                    } else {
                      this.setState({ completeArrProducts: [] });
                    }
                  }, 100);
                } else {
                  this.setState({
                    completeArrProducts: [],
                    noProductsHere: false,
                  });

                  setTimeout(() => {
                    this.setState({
                      completeArrProducts: this.state.filteredProductsToShow,
                    });
                  }, 100);
                }
              }} />
            {/* <div className="space-globalpages"></div> */}
            {this.state.completeArrProducts.length > 0 &&
              !this.state.noProductsHere && (
                <div className="top-event-page-new">
                  <label className="heading-tepn">{this.getSectorName()}</label>
                  <NavGlobalVoucherCity
                    sector={this.props.match.params.sectorid}
                    onMainCategory={true}
                    category={this.props.match.params.maincategory}
                    noShowCity={true}
                    cityOnNewDesign={true}
                  />
                </div>)}
            {this.state.completeArrProducts.length > 0 &&
              !this.state.noProductsHere && (
                <VouchersNewMobile
                  productsWhole={this.state.completeArrProducts}
                  lastNodeEmpty={true}
                />
              )}
            {this.state.noProductsHere && (
              <ReactFullpage
                // navigation
                onLeave={this.onLeave.bind(this)}
                afterLoad={this.afterLoad.bind(this)}
                // responsiveWidth="1008"
                normalScrollElements=".posts-detailed-popup, .charity-confirmation-complete, .loaderareaduplicate, .bg-overlaymini, .mnicart-flyout-inner, .rightmenucompletewidthaddproduct, .rightmenucommentsaddproduct"
                render={({ state, fullpageApi }) => {
                  return (
                    <div>
                      <div className="section" id="sectionnotheight">
                        <div
                          className="inner-shop-container normalimgscampaigns containerEmptyPage"
                          style={{
                            backgroundImage: `none`,
                            backgroundColor: "#285151",
                          }}
                        >
                          <div className="container-contemptpage">
                            <h3>Geen resultaten</h3>
                          </div>
                          {/* <div className='overlay-volgendpage' style={{ backgroundColor: `rgba(0,0,0,${this.state.globalOverlay})` }}></div> */}
                        </div>
                      </div>
                    </div>
                  );
                }}
              />
            )}
          </div>
        )}
        {this.state.registrationscreenpopup && (
          <CharityPopup
            closePopupCharity={() => {
              this.setState({ registrationscreenpopup: false });
            }}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducers.user,
    album: state.postsReducers.album,
    images: state.postsReducers.images,
    product: state.campaignsReducer.product,
    globalvouchers: state.campaignsReducer.globalvouchers,
    globalsectors: state.categoriesReducer.globalsectors,
    global_campaigns: state.campaignsReducer.global_campaigns,
    global_values: state.globalReducers.global_values,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: (user) => dispatch(updateUser(user)),
    updateAlbums: (album) => dispatch(updateAlbums(album)),
    updateImages: (images) => dispatch(updateImages(images)),
    updateProducts: (product) => dispatch(updateProducts(product)),
    updateGlobalVouchers: (vouchers) =>
      dispatch(updateGlobalVouchers(vouchers)),
    updateAllSectors: (globalsectors) =>
      dispatch(updateAllSectors(globalsectors)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VouchersGlobalMainCity);
