import React, { Component } from "react";
import firebase from "../Config/Firebase";
import { connect } from "react-redux";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import CheckIcon from "@material-ui/icons/Check";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SearchIcon from "@material-ui/icons/Search";
import { IoIosSend } from "react-icons/io";
import {
  updateUser,
  updateMessage,
} from "../Redux/actions/authActions";
import { history } from "../Config/Routes";
import { RiUserFollowLine, RiUserUnfollowLine } from "react-icons/ri";
import { followUnfollowCompany } from "../Functions/friendFunctions";

let usersRef = firebase.database().ref("users")

export class FriendListRightMenu extends Component {
  constructor() {
    super();
    this.state = {
      arr: [],
      friendRequests: [],
      noFriendRequests: false,
      showFriends: true,
      showGroups: false,
      arrOfRequests: [],
      arrOfRejects: [],
      arrOfBlocked: [],
      rejectionModal: false,
      noFriends: false,
      inviteHeading: true,
      createGroupModal: false,
      friendsArray: [],
      groupNameInput: "",
      groupDescriptionInput: "",
      arrUidsOfFriends: [],
      src: "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9",
      arrGroups: [],
      groupUsers: [],
      arrForCheckingIcons: [],
      currentUsersGroup: [],
      groupsSettingsModal: false,
      currentUsersAlbums: [],
      arrForCheckingIconsAlbums: [],
      arrAlbums: [],
      sendingMessage: "",
      allMessages: [],
      allUrls: [],
      allProductsCompleted: [],
      alreadyScrolledOnce: false,
      showGroupsWork: false,
      showPermissions: false,
      arrOfFollow: [],
      arrOfFriendsRightMenu: [],
      arrOfSentRequests: [],
      currentUser: "",
      arrOfFollowed: [],
      arrOfFollowing: [],
      ownUser: [],
      beherenOnMobile: false,
      currentChatUser: "",
      arrForSearch: [],
      allMessagesGlobal: [],
      currentUserid: "",
      searchText: "",
      dropdownSelection: false,
      arrFriendsFiltered: [],
      onceSearched: false,

      arrFollowingForMessages: [],
      arrFollowersForMessages: [],
      onFollowingMessages: true,
    };
  }

  componentDidMount() {
    const loaderId = document.getElementById("main-page-loader-id");
    loaderId.style.display = "none";
    let userid = localStorage.getItem("userid");
    this.setState({ currentUserid: userid });

    document.getElementById("root").style.overflow = "hidden"

    document.querySelector("html").classList.add("htmlvriendenoverflowhidden")

    let promiseFirst = new Promise((res) => {
      if (this.props.user) {
        if (this.props.user.length > 0) {
          let arrUsers = this.props.user;
          let currentUser = "";
          let filtered = this.props.user.filter((t) => {
            return t.user_id === userid;
          });
          if (filtered.length > 0) {
            currentUser = filtered[0];
            this.setState({ currentUser });
            let a = currentUser;
            let followingAll = "";
            let followingAllOne = [];
            let followedAll = [];
            let followedAllOne = [];
            if (a.following) {
              followingAll = Object.entries(a.following);
              followingAllOne = [];
              followingAll.map((e) => {
                let filteredFriends = arrUsers.filter((f) => {
                  return f.user_id === e[1].user_id;
                });
                if (filteredFriends.length > 0) {
                  if (!filteredFriends[0].profileActive) {
                    return false;
                  }
                  followingAllOne.push(filteredFriends[0]);
                }
              });
              followingAllOne.map((e) => {
                if (this.props.message) {
                  let ownMessages = this.props.message.filter((messages) => {
                    return messages[0] === e.user_id;
                  });
                  if (ownMessages.length > 0) {
                    let objected = Object.entries(ownMessages[0][1]);
                    objected.map((f) => {
                      f[1].timeStamp = new Date(Date.parse(f[1].timeStampOfSend)).getTime();
                    });
                    e.timeStamp = objected.reverse()[0][1].timeStamp;
                  } else {
                    e.timeStamp = 0;
                  }
                } else {
                  e.timeStamp = 0;
                }
              });
              let sorted = followingAllOne.sort((x, y) => {
                return y.timeStamp - x.timeStamp;
              });
              if (currentUser.type === "company") {
                this.setState({ arrFollowingForMessages: sorted });
              } else {
                this.setState({ arrFollowingForMessages: sorted, arr: sorted });
              }
            } else {
              this.setState({ arrFollowingForMessages: [], noFriends: true });
            }

            if (a.followed_by) {
              followedAll = Object.entries(a.followed_by);
              followedAllOne = [];
              followedAll.map((e) => {
                let filteredFriends = arrUsers.filter((f) => {
                  return f.user_id === e[1].user_id;
                });
                if (filteredFriends.length > 0) {
                  if (!filteredFriends[0].profileActive) {
                    return false;
                  }
                  followedAllOne.push(filteredFriends[0]);
                }
              });
              followedAllOne.map((e) => {
                if (this.props.message) {
                  let ownMessages = this.props.message.filter((messages) => {
                    return messages[0] === e.user_id;
                  });
                  if (ownMessages.length > 0) {
                    let objected = Object.entries(ownMessages[0][1]);
                    objected.map((f) => {
                      f[1].timeStamp = new Date(Date.parse(f[1].timeStampOfSend)).getTime();
                    });
                    e.timeStamp = objected.reverse()[0][1].timeStamp;
                  } else {
                    e.timeStamp = 0;
                  }
                } else {
                  e.timeStamp = 0;
                }
              });
              let sorted = followedAllOne.sort((x, y) => {
                return y.timeStamp - x.timeStamp;
              });
              if (currentUser.type === "company") {
                this.setState({ arrFollowersForMessages: sorted, arr: sorted, onFollowingMessages: false });
              } else {
                this.setState({ arrFollowersForMessages: sorted });
              }
            } else {
              this.setState({ arrFollowersForMessages: [], noFriends: true });
            }
          }
        }
        res()
      }
    })

    firebase
      .database()
      .ref("allMessages/" + userid)
      .on("value", (data) => {
        let a = data.val();
        let arr = [];
        if (a) {
          let objected = Object.entries(a);
          arr = objected;
          if (this.state.arr.length > 0) {
            this.state.arr.map((e) => {
              let ownMessages = arr.filter((messages) => {
                return messages[0] === e.user_id;
              });
              if (ownMessages.length > 0) {
                let objected = Object.entries(ownMessages[0][1]);
                objected.map((f) => {
                  f[1].timeStamp = new Date(Date.parse(f[1].timeStampOfSend)).getTime();
                });
                e.timeStamp = objected.reverse()[0][1].timeStamp;
              } else {
                e.timeStamp = 0;
              }
            });
            let sorted = [...this.state.arr].sort((x, y) => {
              return y.timeStamp - x.timeStamp;
            });
            this.setState({ arr: sorted });
          }

          this.setState({ allMessagesGlobal: arr });
          this.props.updateMessage(arr);
        } else {
          this.setState({ allMessagesGlobal: arr });
          this.props.updateMessage(arr);
        }
      });

    promiseFirst.then(() => {
      usersRef.on("value", (data) => {
        let a = data.val();
        let arrUsers = [];
        let currentUser = "";
        if (a) {
          let entries = Object.entries(a);
          entries.map((f) => {
            arrUsers.push(f[1]);
            if (f[1].user_id === userid) {
              currentUser = f[1];
            }
          });
          if (currentUser) {
            let followingAll = "";
            let followingAllOne = [];
            let followedAll = [];
            let followedAllOne = [];
            if (currentUser.following) {
              followingAll = Object.entries(currentUser.following);
              followingAllOne = [];
              followingAll.map((e) => {
                let filteredFriends = arrUsers.filter((f) => {
                  return f.user_id === e[1].user_id;
                });
                if (filteredFriends.length > 0) {
                  if (!filteredFriends[0].profileActive) {
                    return false;
                  }
                  followingAllOne.push(filteredFriends[0]);
                }
              });
              followingAllOne.map((e) => {
                if (this.props.message) {
                  let ownMessages = this.props.message.filter((messages) => {
                    return messages[0] === e.user_id;
                  });
                  if (ownMessages.length > 0) {
                    let objected = Object.entries(ownMessages[0][1]);
                    objected.map((f) => {
                      f[1].timeStamp = new Date(Date.parse(f[1].timeStampOfSend)).getTime();
                    });
                    e.timeStamp = objected.reverse()[0][1].timeStamp;
                  } else {
                    e.timeStamp = 0;
                  }
                } else {
                  e.timeStamp = 0;
                }
              });
              let sorted = followingAllOne.sort((x, y) => {
                return y.timeStamp - x.timeStamp;
              });
              if (this.state.onFollowingMessages) {
                this.setState({ arrFollowingForMessages: sorted, arr: sorted });
              } else {
                this.setState({ arrFollowingForMessages: sorted });
              }
            } else {
              if (this.state.onFollowingMessages) {
                this.setState({ arrFollowingForMessages: [], noFriends: true, arr: [] });
              } else {
                this.setState({ arrFollowingForMessages: [] });
              }
            }

            if (currentUser.followed_by) {
              followedAll = Object.entries(currentUser.followed_by);
              followedAllOne = [];
              followedAll.map((e) => {
                let filteredFriends = arrUsers.filter((f) => {
                  return f.user_id === e[1].user_id;
                });
                if (filteredFriends.length > 0) {
                  if (!filteredFriends[0].profileActive) {
                    return false;
                  }
                  followedAllOne.push(filteredFriends[0]);
                }
              });
              followedAllOne.map((e) => {
                if (this.props.message) {
                  let ownMessages = this.props.message.filter((messages) => {
                    return messages[0] === e.user_id;
                  });
                  if (ownMessages.length > 0) {
                    let objected = Object.entries(ownMessages[0][1]);
                    objected.map((f) => {
                      f[1].timeStamp = new Date(Date.parse(f[1].timeStampOfSend)).getTime();
                    });
                    e.timeStamp = objected.reverse()[0][1].timeStamp;
                  } else {
                    e.timeStamp = 0;
                  }
                } else {
                  e.timeStamp = 0;
                }
              });
              let sorted = followedAllOne.sort((x, y) => {
                return y.timeStamp - x.timeStamp;
              });
              if (!this.state.onFollowingMessages) {
                this.setState({ arrFollowersForMessages: sorted, arr: sorted });
              } else {
                this.setState({ arrFollowersForMessages: sorted });
              }
            } else {
              if (!this.state.onFollowingMessages) {
                this.setState({ arrFollowersForMessages: [], noFriends: true, arr: [] });
              } else {
                this.setState({ arrFollowersForMessages: [] });
              }
            }


            if (currentUser.followed_by) {
              let arrComing = [];
              let dataSec = Object.entries(currentUser.followed_by);
              dataSec.map((e) => {
                arrComing.push(e[1].user_id);
              });
              let doneFiltered = [];
              arrComing.map((id) => {
                let doneFilteredSec = arrUsers.filter((g) => {
                  return g.user_id === id;
                });
                if (doneFilteredSec.length > 0) {
                  if (!doneFilteredSec[0].profileActive) {
                    return false;
                  }
                  doneFiltered.push(doneFilteredSec[0]);
                }
              });

              this.state.arrOfFollowing.map((t, i) => {
                if (t.isRemoved) {
                  doneFiltered.splice(i, 0, t);
                }
              });


              if (doneFiltered.length > this.state.arrOfFollowing.length) {
                this.setState({
                  arrOfFollowing: doneFiltered,
                });
              } else {
                let arrayToPlace = []
                this.state.arrOfFollowing.map((t) => {
                  let filteredInner = doneFiltered.filter((h) => {
                    return h.user_id === t.user_id
                  })
                  if (filteredInner.length > 0) {
                    arrayToPlace.push(filteredInner[0])
                  } else {
                    t.showInGrayBlock = true
                    arrayToPlace.push(t)
                  }
                })
                this.setState({
                  arrOfFollowing: arrayToPlace,
                });
              }
              // if (doneFiltered.length > 0) {
              //   this.setState({ arrOfFollowing: doneFiltered });
              // } else {
              //   this.setState({ arrOfFollowing: [] });
              // }
            } else {
              let arr = [];
              if (this.state.arrOfFollowing.length > 0) {
                this.state.arrOfFollowing.map((y) => {
                  if (y.isRemoved) {
                    arr.push(y);
                  }
                });
              }
              let arrayToPlace = []
              this.state.arrOfFollowing.map((t) => {
                let filteredInner = arr.filter((h) => {
                  return h.user_id === t.user_id
                })
                if (filteredInner.length > 0) {
                  arrayToPlace.push(filteredInner[0])
                } else {
                  t.showInGrayBlock = true
                  arrayToPlace.push(t)
                }
              })
              this.setState({ arrOfFollowing: arrayToPlace });
            }
            if (currentUser.following) {
              let arrComing = [];
              let dataSec = Object.entries(currentUser.following);
              dataSec.map((e) => {
                arrComing.push(e[1].user_id);
              });
              let doneFiltered = [];
              arrComing.map((id) => {
                let doneFilteredSec = arrUsers.filter((g) => {
                  return g.user_id === id;
                });
                if (doneFilteredSec.length > 0) {
                  if (!doneFilteredSec[0].profileActive) {
                    return false;
                  }
                  doneFiltered.push(doneFilteredSec[0]);
                }
              });

              this.state.arrOfFollowed.map((t, i) => {
                if (t.isRemoved) {
                  doneFiltered.splice(i, 0, t);
                }
              });

              if (doneFiltered.length > 0) {
                this.setState({ arrOfFollowed: doneFiltered });
              } else {
                this.setState({ arrOfFollowed: [] });
              }
            } else {
              let arr = [];
              if (this.state.arrOfFollowed.length > 0) {
                this.state.arrOfFollowed.map((y) => {
                  if (y.isRemoved) {
                    arr.push(y);
                  }
                });
              }
              this.setState({ arrOfFollowed: arr });
            }
          }

          this.setState({ currentUser })

          let arrUsersForPush = [];
          entries.map((f) => {
            if (f && f.length > 0 && f[1]) {
              f[1].pass = "";
              f[1].password = "";
              arrUsersForPush.push(f[1]);
            }
          });
          this.props.updateUser(arrUsersForPush);
        }
      });
    });
  }

  componentWillUnmount() {
    //   usersRef.off()
    document.querySelector("html").classList.remove("htmlvriendenoverflowhidden")
  }

  getRecentMessage(e) {
    let filteredCurrentUserMessages = this.props.message.filter((y) => {
      return y[0] === e.user_id;
    });
    if (filteredCurrentUserMessages.length > 0) {
      let sendingMsg = "";
      let allMessages = [];
      let a = filteredCurrentUserMessages[0][1];
      if (a) {
        a = Object.entries(a);
        a.map((e, i) => {
          allMessages.push(a[i][1]);
        });
      }
      if (allMessages.length > 0) {
        sendingMsg = allMessages[allMessages.length - 1].messageText;
      }
      if (sendingMsg.length > 30) {
        return `${sendingMsg.substr(0, 30)}...`;
      } else {
        return sendingMsg;
      }
    } else {
      let userId = localStorage.getItem("userid");
      let sendingMsg = "";
      firebase
        .database()
        .ref("allMessages/" + userId + "/" + e.user_id)
        .on("value", (data) => {
          let allMessages = [];
          let a = data.val();
          if (a) {
            a = Object.entries(a);
            a.map((e, i) => {
              allMessages.push(a[i][1]);
            });
          }
          if (allMessages.length > 0) {
            sendingMsg = allMessages[allMessages.length - 1].messageText;
          }
        });
      if (sendingMsg.length > 30) {
        return `${sendingMsg.substr(0, 30)}...`;
      } else {
        return sendingMsg;
      }
    }
  }

  checkForUserMessage(f) {
    let e = f;
    let message = this.props.message;
    if (message) {
      let messageFiltered = this.props.message.filter((y) => {
        return y[0] === f.user_id;
      });
      if (messageFiltered.length > 0) {
        return (
          <font style={{ color: "#9f9f9f" }}>{this.getRecentMessage(f)}</font>
        );
      }
    }
  }

  sendMessage() {
    let userId = localStorage.getItem("userid");
    const { selectedUser, sendingMessage } = this.state;
    if (sendingMessage !== "") {
      let key = firebase
        .database()
        .ref("allMessages/" + userId + "/" + selectedUser.user_id)
        .push().key;
      let today = new Date();
      let date =
        today.getDate() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getFullYear();
      let hour = today.getHours();
      if (hour < 10) {
        hour = `0${hour}`;
      }
      let minutes = today.getMinutes();
      if (minutes < 10) {
        minutes = `0${minutes}`;
      }
      let seconds = today.getSeconds();
      if (seconds < 10) {
        seconds = `0${seconds}`;
      }
      let time = hour + ":" + minutes + ":" + seconds;
      let obj = {
        messageText: sendingMessage,
        type: "sender",
        date,
        time,
        timeStampOfSend: new Date().toISOString(),
        key,
        seen: true,
      };
      let objSec = {
        messageText: sendingMessage,
        type: "reciever",
        date,
        timeStampOfSend: new Date().toISOString(),
        time,
        key,
        seen: false,
      };
      firebase
        .database()
        .ref("allMessages/" + selectedUser.user_id + "/" + userId + "/" + key)
        .set(objSec);
      firebase
        .database()
        .ref("allMessages/" + userId + "/" + selectedUser.user_id + "/" + key)
        .set(obj);
      setTimeout(() => {
        this.setState({ sendingMessage: "" });
      }, 200);
      //     })
      // })
    }
  }

  getFollowingRightMenu() {
    let allUsers = this.props.user;
    let userid = localStorage.getItem("userid");
    let arr = [];

    let currentUser = allUsers.filter((e) => {
      return e.user_id === userid;
    });

    if (currentUser[0].followed_by) {
      let dataSec = Object.entries(currentUser[0].followed_by);
      dataSec.map((e) => {
        arr.push(e[1].user_id);
      });
      let doneFiltered = [];
      arr.map((id) => {
        let doneFilteredSec = allUsers.filter((g) => {
          return g.user_id === id;
        });
        if (doneFilteredSec.length > 0) {
          if (!doneFilteredSec[0].profileActive) {
            return false;
          }
          doneFiltered.push(doneFilteredSec[0]);
        }
      });
      this.setState({
        arrOfFollowing: doneFiltered,
        rejectionModal: true,
        noTrashRequests: false,
        arrOfRequests: [],
        followingTabRightMenu: true,
        followersTabRightMenu: false,
        sentRequestsTab: false,
        blocksTab: false,
        rejectsTab: false,
        requestsTab: false,
        friendsTab: false,
      });
    } else {
      this.setState({
        noTrashRequests: true,
        rejectionModal: true,
        arrOfFollowing: [],
        arrOfRequests: [],
        followingTabRightMenu: true,
        followersTabRightMenu: false,
        sentRequestsTab: false,
        blocksTab: false,
        rejectsTab: false,
        requestsTab: false,
        friendsTab: false,
      });
    }
  }

  getFollowersRightMenu() {
    let allUsers = this.props.user;
    let userid = localStorage.getItem("userid");
    let arr = [];

    let currentUser = allUsers.filter((e) => {
      return e.user_id === userid;
    });

    if (currentUser[0].following) {
      let dataSec = Object.entries(currentUser[0].following);
      dataSec.map((e) => {
        arr.push(e[1].user_id);
      });
      let doneFiltered = [];
      arr.map((id) => {
        let doneFilteredSec = allUsers.filter((g) => {
          return g.user_id === id;
        });
        if (doneFilteredSec.length > 0) {
          if (!doneFilteredSec[0].profileActive) {
            return false;
          }
          doneFiltered.push(doneFilteredSec[0]);
        }
      });
      this.setState({
        arrOfFollowed: doneFiltered,
        arrOfFriendsRightMenu: this.state.arr,
        noTrashRequests: false,
        rejectionModal: true,
        arrOfRequests: [],
        followersTabRightMenu: true,
        sentRequestsTab: false,
        followingTabRightMenu: false,
        blocksTab: false,
        rejectsTab: false,
        requestsTab: false,
        friendsTab: false,
      });
    } else {
      if (this.state.arr.length > 0) {
        this.setState({
          arrOfFollowed: [],
          arrOfFriendsRightMenu: this.state.arr,
          noTrashRequests: false,
          arrOfRequests: [],
          followersTabRightMenu: true,
          rejectionModal: true,
          sentRequestsTab: false,
          followingTabRightMenu: false,
          blocksTab: false,
          rejectsTab: false,
          requestsTab: false,
          friendsTab: false,
        });
      } else {
        this.setState({
          noTrashRequests: true,
          arrOfFollowed: [],
          arrOfRequests: [],
          followersTabRightMenu: true,
          sentRequestsTab: false,
          followingTabRightMenu: false,
          rejectionModal: true,
          blocksTab: false,
          rejectsTab: false,
          requestsTab: false,
          friendsTab: false,
        });
      }
    }
  }

  removeFromFollowings(e, index) {
    let allUsers = this.props.user;
    let filteredUser = allUsers.filter((f) => {
      return e === f.user_id;
    });
    if (this.state.currentUser.followed_by) {
      let objected = Object.entries(this.state.currentUser.followed_by);
      let filtered = objected.filter((g) => {
        return g[1].user_id === e;
      });
      if (filtered.length > 0 && filteredUser.length > 0) {
        if (filteredUser[0].following) {
          let objectedSent = Object.entries(filteredUser[0].following);
          let filteredSent = objectedSent.filter((g) => {
            return g[1].user_id === this.state.currentUser.user_id;
          });
          if (filteredSent.length > 0) {
            firebase
              .database()
              .ref("users/" + e + "/following/" + filteredSent[0][0])
              .remove();
          }
        }
        firebase
          .database()
          .ref(
            "users/" +
            this.state.currentUser.user_id +
            "/followed_by/" +
            filtered[0][0]
          )
          .remove();
        this.state.arrOfFollowing[index].isRemoved = true;
        this.setState({ arrOfFollowing: this.state.arrOfFollowing });

        setTimeout(() => {
          this.state.arrOfFollowing.splice(index, 1);
          this.setState({ arrOfFollowing: this.state.arrOfFollowing });
        }, 3000);
      }
    }
  }

  removeFromFollowed(e, index) {
    let allUsers = this.props.user;
    let filteredUser = allUsers.filter((f) => {
      return e === f.user_id;
    });
    if (this.state.currentUser.following) {
      let objected = Object.entries(this.state.currentUser.following);
      let filtered = objected.filter((g) => {
        return g[1].user_id === e;
      });
      if (filtered.length > 0) {
        let objectedSent = Object.entries(filteredUser[0].followed_by);
        let filteredSent = objectedSent.filter((g) => {
          return g[1].user_id === this.state.currentUser.user_id;
        });
        if (filteredSent.length > 0) {
          firebase
            .database()
            .ref(
              "users/" +
              this.state.currentUser.user_id +
              "/following/" +
              filtered[0][0]
            )
            .remove();
          firebase
            .database()
            .ref("users/" + e + "/followed_by/" + filteredSent[0][0])
            .remove();
          this.state.arrOfFollowed[index].isRemoved = true;
          this.setState({ arrOfFollowed: this.state.arrOfFollowed });

          setTimeout(() => {
            this.state.arrOfFollowed.splice(index, 1);
            this.setState({ arrOfFollowed: this.state.arrOfFollowed });
          }, 3000);
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    var objDiv = document.getElementById("scrollerdivmsg");
    if (objDiv && !this.state.alreadyScrolledOnce) {
      objDiv.scrollTo(0, objDiv.scrollHeight);
      this.setState({ alreadyScrolledOnce: true });
    }

    if (this.props.message !== prevProps.message) {
      let userid = localStorage.getItem("userid");
      if (this.state.chatPopup) {
        let filtered = this.props.message.filter((h) => {
          return h[0] === this.state.currentChatUser.user_id;
        });
        if (filtered.length > 0) {
          let a = filtered[0][1];
          let allMessages = [];
          if (a) {
            a = Object.entries(a);
            a.map((e, i) => {
              allMessages.push(a[i][1]);
            });
            this.setState({ allMessages });
            var objDiv = document.getElementById("scrollerdivmsg");
            setTimeout(() => {
              if (objDiv) {
                objDiv.scrollTop = objDiv.scrollHeight;
              }
            }, 100);
            allMessages.map((g) => {
              if (g.date && g.key) {
                firebase
                  .database()
                  .ref(
                    "allMessages/" +
                    userid +
                    "/" +
                    this.state.currentChatUser.user_id +
                    "/" +
                    g.key +
                    "/seen"
                  )
                  .set(true);
              }
            });
          }
        }
      }
    }


    if (prevProps.user !== this.props.user) {
      let uid = localStorage.getItem("userid");
      let arr = [];
      let arrForUpdate = [];
      if (this.state.searchText !== "" && this.state.onceSearched) {
        if (this.props.user.length > 0) {
          let a = this.props.user;
          let filtered = a.filter((e) => {
            if (e.user_id !== uid && e.profileActive) {
              if (e.company_name) {
                return e.company_name
                  .toLowerCase()
                  .includes(this.state.searchText.toLowerCase());
              } else {
                if (e.first_name) {
                  let toSearch = e.first_name + " " + e.last_name;
                  return toSearch
                    .toLowerCase()
                    .includes(this.state.searchText.toLowerCase());
                }
              }
            }
          });
          let filteredOfUser = a.filter((e) => {
            if (e.user_id === uid) {
              return e;
            }
          });
          if (filtered.length > 0) {
            filtered.map((e) => {
              if (filteredOfUser.length > 0) {
                e.alreadyRequest = false;
                arr.push(e);
              } else {
                e.alreadyRequest = false;
                arr.push(e);
              }
            });
          }
          arr.map((t) => {
            arrForUpdate.push(t);
          });
          if (arrForUpdate.length > 0) {
            if (this.state.arrForSearch !== arrForUpdate) {
              this.setState({ arrForSearch: arrForUpdate });
            }
          } else {
            this.setState({ arrForSearch: [] });
          }
        }
      }
    }
  }

  getFollowing() {
    let currentUser = this.state.currentUserid;
    let filtered = this.props.user.filter((g) => {
      return g.user_id === currentUser;
    });
    if (filtered.length > 0) {
      if (filtered[0].followed_by) {
        let objected = Object.entries(filtered[0].followed_by);
        let arr = [];
        let arrComp = [];
        objected.map((g) => {
          arr.push(g[1]);
        });
        arr.map((g) => {
          let filteredToPush = this.props.user.filter((h) => {
            return g.user_id === h.user_id;
          });
          if (filteredToPush.length > 0) {
            arrComp.push(filteredToPush[0]);
          }
        });
        if (filtered[0].newFollowersList) {
          firebase
            .database()
            .ref("users/" + filtered[0].user_id + "/newFollowersList")
            .remove();
          firebase
            .database()
            .ref("users/" + filtered[0].user_id + "/newFollowers")
            .remove();
        }
        this.setState({
          arrOfFollow: arrComp,
          followingTab: false,
          beherenTab: false,
          followersTab: true,
          beherenOnMobile: false,
          newFriendsTab: false,
          friendsTab: false,
          requestsTab: false,
          rejectsTab: false,
          blocksTab: false,
        });
      }
    }
  }

  getToShowFollowing() {
    let filtered = [this.state.currentUser];
    if (filtered[0].followed_by) {
      let objected = Object.entries(filtered[0].followed_by);
      let arr = [];
      let arrComp = [];
      objected.map((g) => {
        arr.push(g[1]);
      });
      arr.map((g) => {
        let filteredToPush = this.props.user.filter((h) => {
          return g.user_id === h.user_id;
        });
        if (filteredToPush.length > 0) {
          arrComp.push(filteredToPush[0]);
        }
      });
      if (arrComp.length > 0) {
        return (
          <div
            className={`inner-bfm ${this.state.followersTab && !this.state.beherenTab && "active"
              }`}
          >
            {this.state.currentUser.newFollowersList && (
              <div className="count-ibfm">
                <font>{this.state.currentUser.newFollowers}</font>
              </div>
            )}
            <button
              style={{
                backgroundColor: "black",
                marginLeft: 0,
                border: "1px solid black",
                outline: "none",
                color: "white",
                fontWeight: "500",
                display: "flex",
                alignItems: "center",
              }}
              onClick={this.getFollowing.bind(this)}
            >
              {window.innerWidth > 1009 ? "VOLGERS" : "Volgers"}
            </button>
          </div>
        );
      }
    }
  }

  getFollowers() {
    let currentUser = this.state.currentUserid;
    let filtered = this.props.user.filter((g) => {
      return g.user_id === currentUser;
    });
    if (filtered.length > 0) {
      if (filtered[0].following) {
        let objected = Object.entries(filtered[0].following);
        let arr = [];
        let arrComp = [];
        objected.map((g) => {
          arr.push(g[1]);
        });
        arr.map((g) => {
          let filteredToPush = this.props.user.filter((h) => {
            return g.user_id === h.user_id;
          });
          if (filteredToPush.length > 0) {
            arrComp.push(filteredToPush[0]);
          }
        });
        this.setState({
          arrOfFollow: arrComp,
          followingTab: true,
          beherenTab: false,
          beherenOnMobile: false,
          newFriendsTab: false,
          followersTab: false,
          friendsTab: false,
          requestsTab: false,
          rejectsTab: false,
          blocksTab: false,
        });
      }
    }
  }

  getArrOfFollowedLength() {
    let filtered = this.state.arrOfFollowed.filter((y) => {
      return !y.isRemoved;
    });
    return filtered.length;
  }

  getArrOfFollowingLength() {
    let filtered = this.state.arrOfFollowing.filter((y) => {
      return !y.isRemoved;
    });
    return filtered.length;
  }

  getMessageCount(f) {
    let message = this.state.allMessagesGlobal;
    let lengthToSend = 0;
    if (message.length > 0) {
      let messageFiltered = this.state.allMessagesGlobal.filter((y) => {
        return y[0] === f.user_id;
      });
      if (messageFiltered.length > 0) {
        let objected = Object.entries(messageFiltered[0][1]);
        let innerFilter = objected.filter((x) => {
          return x[1].seen === false;
        });
        lengthToSend = innerFilter.length;
      }
    } else {
      lengthToSend = 0;
    }
    if (lengthToSend > 0) {
      return (
        <div
          className="innner-round-count"
          onClick={() => {
            if (window.innerWidth < 1009) {
              document.querySelector("html").style.overflowY = "hidden"
            }
            this.setState({ chatPopup: true, selectedUser: e });
            let userid = localStorage.getItem("userid");
            let e = f;
            let filtered = this.props.message.filter((h) => {
              return h[0] === e.user_id;
            });
            if (filtered.length > 0) {
              let a = filtered[0][1];
              let allMessages = [];
              if (a) {
                a = Object.entries(a);
                a.map((e, i) => {
                  allMessages.push(a[i][1]);
                });
                this.setState({ allMessages, currentChatUser: e });
                allMessages.map((g) => {
                  if (g.date && g.key) {
                    firebase
                      .database()
                      .ref(
                        "allMessages/" +
                        userid +
                        "/" +
                        e.user_id +
                        "/" +
                        g.key +
                        "/seen"
                      )
                      .set(true);
                  }
                });
              }
            }
            // firebase.database().ref("allMessages/" + userId + "/" + e.user_id).once("value", (data) => {
            //     let allMessages = []
            //     let a = data.val()
            //     if (a) {
            //         a = Object.entries(a)
            //         a.map((e, i) => {
            //             allMessages.push(a[i][1])
            //         })
            //     }
            //     this.setState({ allMessages, currentChatUser: e })
            //     allMessages.map((g) => {
            //         if (this.state.chatPopup) {
            //             firebase.database().ref("allMessages/" + userId + "/" + e.user_id + "/" + g.key + "/seen").set(true)
            //         }
            //     })
            // })
          }}
        >
          <font>{lengthToSend}</font>
        </div>
      );
    }
  }

  getRecentMessageDate(e) {
    let filteredCurrentUserMessages = [];
    if (this.props.message) {
      filteredCurrentUserMessages = this.props.message.filter((y) => {
        return y[0] === e.user_id;
      });
    }
    if (filteredCurrentUserMessages.length > 0) {
      let sendingMsg = "";
      let allMessages = [];
      let a = filteredCurrentUserMessages[0][1];
      if (a) {
        a = Object.entries(a);
        a.map((e, i) => {
          allMessages.push(a[i][1]);
        });
      }
      if (allMessages.length > 0) {
        let date = allMessages[allMessages.length - 1].date;
        let checkTimeStamp = new Date(
          date.split("-")[2],
          Number(date.split("-")[1]) - 1,
          date.split("-")[0]
        );
        let currentDate = new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        );
        if (currentDate.getTime() === checkTimeStamp.getTime()) {
          // let time = allMessages[allMessages.length - 1].time;
          let lastDate = new Date(Date.parse(allMessages[allMessages.length - 1].timeStampOfSend))
          let hours = lastDate.getHours()
          let minutes = lastDate.getMinutes()

          if (hours < 10) {
            hours = `0${hours}`
          }
          if (minutes < 10) {
            minutes = `0${minutes}`
          }

          sendingMsg = `${hours}:${minutes}`;
          // sendingMsg = `${time.split(":")[0]}:${time.split(":")[1]}`;
        } else {
          sendingMsg = allMessages[allMessages.length - 1].date;
        }
      } else {
        sendingMsg = `Start Chat`;
      }
      return sendingMsg;
    } else {
      let userId = localStorage.getItem("userid");
      let sendingMsg = "";
      firebase
        .database()
        .ref("allMessages/" + userId + "/" + e.user_id)
        .on("value", (data) => {
          let allMessages = [];
          let a = data.val();
          if (a) {
            a = Object.entries(a);
            a.map((e, i) => {
              allMessages.push(a[i][1]);
            });
          }
          if (allMessages.length > 0) {
            let date = allMessages[allMessages.length - 1].date;
            let checkTimeStamp = new Date(
              date.split("-")[2],
              Number(date.split("-")[1]) - 1,
              date.split("-")[0]
            );
            let currentDate = new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate()
            );
            if (currentDate.getTime() === checkTimeStamp.getTime()) {
              // let time = allMessages[allMessages.length - 1].time;
              // sendingMsg = `${time.split(":")[0]}:${time.split(":")[1]}`;
              let lastDate = new Date(Date.parse(allMessages[allMessages.length - 1].timeStampOfSend))
              let hours = lastDate.getHours()
              let minutes = lastDate.getMinutes()

              if (hours < 10) {
                hours = `0${hours}`
              }
              if (minutes < 10) {
                minutes = `0${minutes}`
              }

              sendingMsg = `${hours}:${minutes}`;
            } else {
              sendingMsg = allMessages[allMessages.length - 1].date;
            }
          } else {
            sendingMsg = `Start Chat`;
          }
        });
      sendingMsg = `Start Chat`;
      return sendingMsg;
    }
  }

  getCurrentUserChatLikes(userMain) {
    if (this.props.images) {
      let filtered = this.props.images.filter((e) => {
        return e.created_by === userMain.user_id;
      });
      let allLikes = [];
      filtered.map((y) => {
        if (y.liked_by) {
          let objected = Object.entries(y.liked_by);
          objected.map((t) => {
            allLikes.push(t[1]);
          });
        }
      });
      return allLikes.length;
    } else {
      return `0`;
    }
  }

  getCurrentUserChatCount(userMain) {
    if (this.props.images) {
      let filtered = this.props.images.filter((e) => {
        return e.created_by === userMain.user_id;
      });
      // let allLikes = []
      // filtered.map((y) => {
      //     if (y.liked_by) {
      //         let objected = Object.entries(y.liked_by)
      //         objected.map((t) => {
      //             allLikes.push(t[1])
      //         })
      //     }
      // })
      return filtered.length;
    } else {
      return `0`;
    }
  }

  checkForDropdownContainer() {
    return (
      <div
        className="dropdown-main-fsdcont"
        style={{
          opacity: this.state.dropdownSelection ? 1 : 0,
          pointerEvents: this.state.dropdownSelection ? "all" : "none",
        }}
      >
        <label
          onClick={() =>
            this.setState({
              onFollowingMessages: true,
              arr: this.state.arrFollowingForMessages,
              dropdownSelection: false,
              noResultFoundedTextFriends: false,
              searchNewFriendsInner: false
            })
          }
        >
          Volgend
        </label>
        <label
          onClick={() =>
            this.setState({
              onFollowingMessages: false,
              arr: this.state.arrFollowersForMessages,
              dropdownSelection: false,
              noResultFoundedTextFriends: false,
              searchNewFriendsInner: false
            })
          }
        >
          Mijn volgers
        </label>
        {/* <label
          onClick={() =>
            this.setState({
              searchNewFriendsInner: true,
              dropdownSelection: false,
            })
          }
        >
          Zoek persoon/bedrijf
        </label> */}
      </div>
    )
  }


  searchForUsers() {
    let uid = localStorage.getItem("userid");
    let arr = [];
    let arrForUpdate = [];
    if (this.state.searchText !== "") {
      if (this.props.user.length > 0) {
        let a = this.props.user;
        let filtered = a.filter((e) => {
          if (e.user_id !== uid && e.profileActive) {
            if (e.company_name) {
              return e.company_name
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase());
            } else {
              if (e.first_name) {
                let toSearch = e.first_name + " " + e.last_name;
                return toSearch
                  .toLowerCase()
                  .includes(this.state.searchText.toLowerCase());
              }
            }
          }
        });
        let filteredOfUser = a.filter((e) => {
          if (e.user_id === uid) {
            return e;
          }
        });
        if (filtered.length > 0) {
          filtered.map((e) => {
            if (filteredOfUser.length > 0) {
              e.alreadyRequest = false;
              arr.push(e);
            } else {
              e.alreadyRequest = false;
              arr.push(e);
            }
          });
        }
        arr.map((t) => {
          arrForUpdate.push(t);
        });
        if (arrForUpdate.length > 0) {
          this.setState({ noResultFoundedText: false });
        } else {
          this.setState({ noResultFoundedText: true });
        }
        this.setState({ arrForSearch: arrForUpdate, uid, onceSearched: true });
      }
    }
  }


  checkForFollowIcon(creatorId) {
    let currentUserMember = this.props.user.filter((t) => {
      return t.user_id === creatorId
    })
    if (currentUserMember[0].followed_by) {
      let objected = Object.entries(currentUserMember[0].followed_by);
      let filtered = objected.filter((g) => {
        return g[1].user_id === this.state.currentUserid;
      });
      if (filtered.length > 0) {
        return (
          <div className="btns-rmodal btnrmodal-updated" style={{ width: 80 }}>
            <div className="text-brmodal">
              <p>UnFollowed</p>
            </div>
            <button
              className="add-icon-bmodal"
              onClick={this.followCompany.bind(this, currentUserMember[0])}
            >
              <RiUserUnfollowLine />
            </button>
          </div>
        );
      } else {
        return (
          <div className="btns-rmodal btnrmodal-updated" style={{ width: 80 }}>
            <div className="text-brmodal">
              <p>Followed</p>
            </div>
            <button
              className="add-icon-bmodal"
              onClick={this.followCompany.bind(this, currentUserMember[0])}
            >
              <RiUserFollowLine />
            </button>
          </div>
          // <RiUserFollowLine onClick={this.followCompany.bind(this, currentUserMember[0])} />
        );
      }
    } else {
      return (
        <div className="btns-rmodal btnrmodal-updated" style={{ width: 80 }}>
          <div className="text-brmodal">
            <p>Followed</p>
          </div>
          <button
            className="add-icon-bmodal"
            onClick={this.followCompany.bind(this, currentUserMember[0])}
          >
            <RiUserFollowLine />
          </button>
        </div>
      );
    }
  }


  followCompany(currentUser) {
    followUnfollowCompany(currentUser, this.state.currentUserid, this.props.user)
  }


  // followCompany(currentUser) {
  //   if (currentUser.followed_by) {
  //     let objected = Object.entries(currentUser.followed_by);
  //     let filtered = objected.filter((g) => {
  //       return g[1].user_id === this.state.currentUserid;
  //     });
  //     if (filtered.length > 0) {
  //       if (currentUser.newFollowersList) {
  //         let objectedSec = Object.entries(
  //           currentUser.newFollowersList
  //         );
  //         let filteredNewFollowers = objectedSec.filter((g) => {
  //           return g[1].user_id === this.state.currentUserid;
  //         });
  //         if (filteredNewFollowers.length > 0) {
  //           let newFollowers = Number(currentUser.newFollowers);
  //           newFollowers = newFollowers - 1;
  //           firebase
  //             .database()
  //             .ref(
  //               "users/" +
  //               currentUser.user_id +
  //               "/newFollowersList/" +
  //               filteredNewFollowers[0][1].push_key
  //             )
  //             .remove();
  //           firebase
  //             .database()
  //             .ref("users/" + currentUser.user_id + "/newFollowers")
  //             .set(newFollowers);
  //         }
  //       }
  //       let filteredCurrentUser = this.props.user.filter((h) => {
  //         return h.user_id === this.state.currentUserid;
  //       });
  //       if (filteredCurrentUser[0].following) {
  //         let objectedSec = Object.entries(filteredCurrentUser[0].following);
  //         let filteredSec = objectedSec.filter((g) => {
  //           return g[1].user_id === currentUser.user_id;
  //         });
  //         if (filteredSec.length > 0) {
  //           firebase
  //             .database()
  //             .ref(
  //               "users/" +
  //               this.state.currentUserid +
  //               "/following/" +
  //               filteredSec[0][1].push_key
  //             )
  //             .remove();
  //         }
  //       }
  //       firebase
  //         .database()
  //         .ref(
  //           "users/" +
  //           currentUser.user_id +
  //           "/followed_by/" +
  //           filtered[0][1].push_key
  //         )
  //         .remove();
  //     } else {
  //       let pushFirst = firebase
  //         .database()
  //         .ref("users/" + this.state.currentUserid + "/following")
  //         .push().key;
  //       let pushSecond = firebase
  //         .database()
  //         .ref("users/" + currentUser.user_id + "/followed_by")
  //         .push().key;
  //       let pushSecondNew = firebase
  //         .database()
  //         .ref("users/" + currentUser.user_id + "/newFollowersList")
  //         .push().key;
  //       let newFollowers = 0;
  //       let obj = {
  //         user_id: currentUser.user_id,
  //         push_key: pushFirst,
  //       };
  //       let objSec = {
  //         user_id: this.state.currentUserid,
  //         push_key: pushSecond,
  //       };
  //       let objThird = {
  //         user_id: this.state.currentUserid,
  //         push_key: pushSecondNew,
  //       };
  //       if (currentUser.newFollowers) {
  //         newFollowers = Number(currentUser.newFollowers);
  //       }
  //       newFollowers = newFollowers + 1;
  //       firebase
  //         .database()
  //         .ref("users/" + this.state.currentUserid + "/following/" + pushFirst)
  //         .set(obj);
  //       firebase
  //         .database()
  //         .ref(
  //           "users/" +
  //           currentUser.user_id +
  //           "/followed_by/" +
  //           pushSecond
  //         )
  //         .set(objSec);
  //       firebase
  //         .database()
  //         .ref("users/" + currentUser.user_id + "/newFollowers")
  //         .set(newFollowers);
  //       firebase
  //         .database()
  //         .ref(
  //           "users/" +
  //           currentUser.user_id +
  //           "/newFollowersList/" +
  //           pushSecondNew
  //         )
  //         .set(objThird);
  //     }
  //   } else {
  //     let pushFirst = firebase
  //       .database()
  //       .ref("users/" + this.state.currentUserid + "/following")
  //       .push().key;
  //     let pushSecond = firebase
  //       .database()
  //       .ref("users/" + currentUser.user_id + "/followed_by")
  //       .push().key;
  //     let pushSecondNew = firebase
  //       .database()
  //       .ref("users/" + currentUser.user_id + "/newFollowersList")
  //       .push().key;
  //     let newFollowers = 0;
  //     let obj = {
  //       user_id: currentUser.user_id,
  //       push_key: pushFirst,
  //     };
  //     let objSec = {
  //       user_id: this.state.currentUserid,
  //       push_key: pushSecond,
  //     };
  //     let objThird = {
  //       user_id: this.state.currentUserid,
  //       push_key: pushSecondNew,
  //     };
  //     if (currentUser.newFollowers) {
  //       newFollowers = Number(currentUser.newFollowers);
  //     }
  //     newFollowers = newFollowers + 1;
  //     firebase
  //       .database()
  //       .ref("users/" + this.state.currentUserid + "/following/" + pushFirst)
  //       .set(obj);
  //     firebase
  //       .database()
  //       .ref(
  //         "users/" +
  //         currentUser.user_id +
  //         "/followed_by/" +
  //         pushSecond
  //       )
  //       .set(objSec);
  //     firebase
  //       .database()
  //       .ref("users/" + currentUser.user_id + "/newFollowers")
  //       .set(newFollowers);
  //     firebase
  //       .database()
  //       .ref(
  //         "users/" +
  //         currentUser.user_id +
  //         "/newFollowersList/" +
  //         pushSecondNew
  //       )
  //       .set(objThird);
  //   }
  // }


  render() {
    return this.state.rejectionModal ? (
      <>
        {/* <div className="rejection-modal-overlay" style={{ opacity: this.state.rejectionModal ? 1 : 0, pointerEvents: this.state.rejectionModal ? "all" : "none" }} onClick={() => this.setState({ rejectionModal: false })}>

                </div> */}
        <div
          className="rejection-modal friendlistbeherenmodal friendlistmainbeherenmodalstuff rightmenusoloopenedupdated rightmenuopenedsoloupdated rightmenuavoiduppercaseinfriendlist"
          style={{
            right: this.state.rejectionModal ? 0 : "-100%",
            borderRadius: 0,
          }}
        >
          <div className="header-rm">
            <label className="titleinheader-rm">VRIENDEN BEHEREN</label>
            <div className="top-header-infocont">
              <div style={{ position: "relative" }}>
                <div className='green-round-info' style={{ cursor: "pointer" }} onClick={() => {
                  this.setState({ infoRightMenuOpen: !this.state.infoRightMenuOpen })
                }}>
                  <font>?</font>
                </div>
                {this.state.infoRightMenuOpen && (<div className="popup-gri-thifc" style={{ opacity: this.state.infoRightMenuOpen ? 1 : 0, pointerEvents: this.state.infoRightMenuOpen ? "all" : "none" }}>
                  <CloseIcon onClick={() => this.setState({ infoRightMenuOpen: false })} />
                  <p style={{ fontSize: 15 }}>
                    Beheer nieuwe vriendschapsverzoeken en bestaande vrienden
                    (of volgers). Door het (niet) accepteren van een verzoek of
                    het (de)blokkeren van een vriend.
                  </p>
                </div>)}
              </div>
              <CloseIcon
                style={{ fontSize: 18 }}
                onClick={() => this.setState({ rejectionModal: false })}
              />
            </div>
          </div>
          <div className="tabs-rm-main">
            <div
              className="friendlistselectiondropdown"
              style={{ position: "relative" }}
            >
              <div className="wrapper-select-new">
                <select
                  value={
                    this.state.followingTabRightMenu ? "volgers" : "volgend"
                  }
                  onChange={(e) => {
                    if (e.target.value === "volgers") {
                      this.getFollowingRightMenu();
                    } else if (e.target.value === "volgend") {
                      this.getFollowersRightMenu();
                    }
                  }}
                >
                  <option value="volgers">
                    Volgers
                  </option>
                  <option value="volgend">
                    Volgend
                  </option>
                </select>
                <ExpandMoreIcon
                  style={{
                    position: "absolute",
                    right: 5,
                    bottom: 5,
                    pointerEvents: "none",
                  }}
                  className="expand-more"
                />
              </div>
            </div>
          </div>
          <div className="search-rm-main">
            <div className="wrapper-select-new">
              <input type="text" placeholder="Zoek in je vriendenlijst" />
              <SearchIcon
                className="expand-more"
              />
            </div>
          </div>
          {this.state.followersTabRightMenu && (
            <p className="totalCount" style={{ textAlign: "right" }}>
              {this.getArrOfFollowedLength()} Volgend
            </p>
          )}
          {this.state.followingTabRightMenu && (
            <p className="totalCount" style={{ textAlign: "right" }}>
              {this.getArrOfFollowingLength()} Volgers
            </p>
          )}
          {this.state.followingTabRightMenu &&
            this.state.arrOfFollowing.length > 0 && (
              <ul className="arrRejects">
                {this.state.arrOfFollowing.map((e, i) => {
                  if (e.isRemoved) {
                    return (
                      <li key={Math.random()} className="listscheckedcontainer listscheckedcontainerblack">
                        <div className="circle-inner-accept">
                          <CheckIcon />
                        </div>
                        <span>verwijderd uit volgerslijst</span>
                      </li>
                    );
                  } else {
                    return (
                      <li key={Math.random()} className={`${e.showInGrayBlock ? "listingraycolorbuttonshide" : ""}`}>
                        {!e.profile_image ? (
                          <img src="https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9" />
                        ) : (
                          <img src={e.profile_image} alt="profile" />
                        )}
                        <div className="contentdescar">
                          {e.type === "company" ? (
                            <span
                              onClick={() => {
                                localStorage.removeItem("onCurrentTab");
                                localStorage.removeItem("onCurrentTabCompany");
                                setTimeout(() => {
                                  if (e.type === "user") {
                                    // Commented by me
                                    history.push(`/member/${e.user_name_id}`);
                                    if (window.innerWidth > 1008) {
                                      this.props.closePopup(
                                        "fromfriendscompletely"
                                      );
                                    }

                                    // window.location.href = `/member/${e.user_name_id}`;
                                  } else {
                                    // Commented by me
                                    history.push(`/company/${e.user_name_id}`);
                                    if (window.innerWidth > 1008) {
                                      this.props.closePopup(
                                        "fromfriendscompletely"
                                      );
                                    }

                                    // window.location.href = `/company/${e.user_name_id}`;
                                  }
                                });
                              }}
                            >
                              {e.company_name}
                            </span>
                          ) : (
                            <span
                              onClick={() => {
                                localStorage.removeItem("onCurrentTab");
                                localStorage.removeItem("onCurrentTabCompany");
                                setTimeout(() => {
                                  if (e.type === "user") {
                                    // Commented by me
                                    history.push(`/member/${e.user_name_id}`);
                                    if (window.innerWidth > 1008) {
                                      this.props.closePopup(
                                        "fromfriendscompletely"
                                      );
                                    }

                                    // window.location.href = `/member/${e.user_name_id}`;
                                  } else {
                                    // Commented by me
                                    history.push(`/company/${e.user_name_id}`);
                                    if (window.innerWidth > 1008) {
                                      this.props.closePopup(
                                        "fromfriendscompletely"
                                      );
                                    }
                                    // window.location.href = `/company/${e.user_name_id}`;
                                  }
                                }, 400);
                              }}
                            >
                              {e.first_name} {e.last_name}
                            </span>
                          )}
                          <span>{e.country}</span>
                        </div>
                        <div className="onlyshowonhidenewpopup">
                          <button>
                            Gewijzigd
                          </button>
                        </div>
                        <div className="btns-rmodal">
                          <button
                            onClick={this.removeFromFollowings.bind(
                              this,
                              e.user_id,
                              i
                            )}
                          >
                            <DeleteForeverIcon />
                          </button>
                          <div
                            className="top-notification-bekijk addnotificationbekijk"
                            style={{ top: -40 }}
                          >
                            <p>Verwijderen</p>
                          </div>
                        </div>
                      </li>
                    );
                  }
                })}
              </ul>
            )}
          {this.state.followersTabRightMenu && (
            <ul className="arrRejects">
              {this.state.arrOfFollowed.length > 0 &&
                this.state.arrOfFollowed.map((e, i) => {
                  if (e.isRemoved) {
                    return (
                      <li key={Math.random()} className="listscheckedcontainer listscheckedcontainerblack">
                        <div className="circle-inner-accept">
                          <CheckIcon />
                        </div>
                        <span>verwijderd uit volglijst</span>
                      </li>
                    );
                  } else {
                    return (
                      <li key={Math.random()}>
                        {!e.profile_image ? (
                          <img src="https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9" />
                        ) : (
                          <img src={e.profile_image} alt="profile" />
                        )}
                        <div className="contentdescar">
                          {e.type === "company" ? (
                            <span
                              onClick={() => {
                                localStorage.removeItem("onCurrentTab");
                                localStorage.removeItem("onCurrentTabCompany");
                                setTimeout(() => {
                                  if (e.type === "user") {
                                    // Commented by me
                                    history.push(`/member/${e.user_name_id}`);
                                    if (window.innerWidth > 1008) {
                                      this.props.closePopup(
                                        "fromfriendscompletely"
                                      );
                                    }
                                    // window.location.href = `/member/${e.user_name_id}`;
                                  } else {
                                    //  Commented by me
                                    history.push(`/company/${e.user_name_id}`);
                                    if (window.innerWidth > 1008) {
                                      this.props.closePopup(
                                        "fromfriendscompletely"
                                      );
                                    }

                                    // window.location.href = `/company/${e.user_name_id}`;
                                  }
                                }, 400);
                              }}
                            >
                              {e.company_name}
                            </span>
                          ) : (
                            <span
                              onClick={() => {
                                localStorage.removeItem("onCurrentTab");
                                localStorage.removeItem("onCurrentTabCompany");
                                setTimeout(() => {
                                  if (e.type === "user") {
                                    // Commented by me
                                    history.push(`/member/${e.user_name_id}`);
                                    if (window.innerWidth > 1008) {
                                      this.props.closePopup(
                                        "fromfriendscompletely"
                                      );
                                    }
                                    // window.location.href = `/member/${e.user_name_id}`;
                                  } else {
                                    //  Commented by me
                                    history.push(`/company/${e.user_name_id}`);
                                    if (window.innerWidth > 1008) {
                                      this.props.closePopup(
                                        "fromfriendscompletely"
                                      );
                                    }

                                    // window.location.href = `/company/${e.user_name_id}`;
                                  }
                                }, 400);
                              }}
                            >
                              {e.first_name} {e.last_name}
                            </span>
                          )}
                          <span>{e.country}</span>
                        </div>
                        <div className="btns-rmodal">
                          <button
                            onClick={this.removeFromFollowed.bind(
                              this,
                              e.user_id,
                              i
                            )}
                          >
                            <DeleteForeverIcon />
                          </button>
                          <div
                            className="top-notification-bekijk addnotificationbekijk"
                            style={{ top: -40 }}
                          >
                            <p>Verwijderen</p>
                          </div>
                        </div>
                      </li>
                    );
                  }
                })}
            </ul>
          )}
        </div>
      </>
    ) : this.state.chatPopup ? (
      <div
        className="loader-area mcaloader loaderareachatliveevent loaderarearightmenuchatfriends rightmenuchatnewfriends"
        style={{ backgroundColor: "transparent" }}
      >
        <div className="modal-create-album chatpopupnooverflow">
          <div
            className="heading-mca"
            style={{ position: "relative", display: "flex", padding: 20 }}
          >
            <ChevronLeftIcon
              style={{
                fontSize: 18,
                position: "absolute",
                left: 10,
                top: "50%",
                transform: "translateY(-50%)",
              }}
              onClick={() => {
                if (window.innerWidth < 1009) {
                  document.querySelector("html").style.overflowY = "auto"
                }
                this.setState({
                  chatPopup: false,
                  currentChatUser: "",
                  alreadyScrolledOnce: false,
                });
              }}
            />
            <CloseIcon
              style={{
                fontSize: 18,
                position: "absolute",
                right: 10,
                top: "50%",
                transform: "translateY(-50%)",
              }}
              onClick={() => {
                if (window.innerWidth < 1009) {
                  document.querySelector("html").style.overflowY = "auto"
                }
                this.setState({
                  chatPopup: false,
                  currentChatUser: "",
                  alreadyScrolledOnce: false,
                });
                this.props.closePopup();
              }}
            />
            {/* <font>CHAT</font> */}
            {/* <font>{this.state.currentChatUser.first_name} {this.state.currentChatUser.last_name}</font> */}
          </div>
          <div
            className="user-info-settings-albums"
            style={{ marginTop: 10 }}
            onClick={() => {
              this.props.closePopup("fromfriendscompletely");
              if (this.state.currentChatUser.type === "company") {
                history.push(`/company/${this.state.currentChatUser.user_name_id}`)
                // window.location.href = `/company/${this.state.currentChatUser.user_name_id}`;
              } else {
                history.push(`/member/${this.state.currentChatUser.user_name_id}`)
                // window.location.href = `/member/${this.state.currentChatUser.user_name_id}`;
              }
            }}
          >
            {!this.state.currentChatUser.hideProfileImage &&
              this.state.currentChatUser.profile_image ? (
              <img
                style={{ border: "none" }}
                src={this.state.currentChatUser.profile_image}
                onClick={() => {
                  this.props.closePopup("fromfriendscompletely");
                  if (this.state.currentChatUser.type === "company") {
                    history.push(`/company/${this.state.currentChatUser.user_name_id}`)
                    // window.location.href = `/company/${this.state.currentChatUser.user_name_id}`;
                  } else {
                    history.push(`/member/${this.state.currentChatUser.user_name_id}`)
                    // window.location.href = `/member/${this.state.currentChatUser.user_name_id}`;
                  }
                }}
              />
            ) : (
              <img
                style={{ border: "none" }}
                src="https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9"
                onClick={() => {
                  this.props.closePopup("fromfriendscompletely");
                  if (this.state.currentChatUser.type === "company") {
                    history.push(`/company/${this.state.currentChatUser.user_name_id}`)
                    // window.location.href = `/company/${this.state.currentChatUser.user_name_id}`;
                  } else {
                    history.push(`/member/${this.state.currentChatUser.user_name_id}`)
                    // window.location.href = `/member/${this.state.currentChatUser.user_name_id}`;
                  }
                }}
              />
            )}
            <div
              onClick={() => {
                this.props.closePopup("fromfriendscompletely");
                if (this.state.currentChatUser.type === "company") {
                  history.push(`/company/${this.state.currentChatUser.user_name_id}`)
                  // window.location.href = `/company/${this.state.currentChatUser.user_name_id}`;
                } else {
                  history.push(`/member/${this.state.currentChatUser.user_name_id}`)
                  // window.location.href = `/member/${this.state.currentChatUser.user_name_id}`;
                }
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                {this.state.currentChatUser.type === "company" ? (
                  <p style={{ fontWeight: "bold" }}>
                    {this.state.currentChatUser.company_name}
                  </p>
                ) : (
                  <p style={{ fontWeight: "bold" }}>
                    {this.state.currentChatUser.first_name}{" "}
                    {this.state.currentChatUser.last_name}
                  </p>
                )}
              </div>
              <div
                style={{ display: "flex", alignItems: "center" }}
                className="chatuserinfo-main-top chatuserinfo-main-toplessweight"
              >
                {/* <p>{this.getCurrentUserChatCount(this.state.currentUser)} bericht</p> */}
                {/* <p>{this.state.allMessages.length} bericht</p> */}
                <p>
                  {this.state.currentChatUser.followed_by
                    ? Object.entries(this.state.currentChatUser.followed_by)
                      .length
                    : 0}{" "}
                  volgers
                </p>
                <p>
                  {this.state.currentChatUser.following
                    ? Object.entries(this.state.currentChatUser.following)
                      .length
                    : 0}{" "}
                  volgend
                </p>
              </div>
            </div>
          </div>
          <div className="body-mca chatbodymca" id="scrollerdivmsg">
            {this.state.allMessages.length > 0 &&
              this.state.allMessages.map((e) => {
                let lastDate = new Date(Date.parse(e.timeStampOfSend))
                let hours = lastDate.getHours()
                let minutes = lastDate.getMinutes()

                if (hours < 10) {
                  hours = `0${hours}`
                }
                if (minutes < 10) {
                  minutes = `0${minutes}`
                }

                if (e.type === "sender") {
                  return (
                    <div
                      className="chat-card senderchat"
                      style={{
                        justifyContent: "flex-end",
                        flexDirection: "column",
                        alignItems: "flex-end",
                      }}
                      key={Math.random()}
                    >
                      <div
                        className="chat-content"
                        style={{ width: "auto", margin: 0, maxWidth: "90%" }}
                      >
                        <p className="chat-text">{e.messageText}</p>
                      </div>
                      {e.timeStampOfSend && (
                        <p className="date-cc-main">
                          {hours}:{minutes}
                        </p>
                      )}
                    </div>
                  );
                } else {
                  return (
                    <div
                      className="chat-card minechat"
                      style={{
                        justifyContent: "flex-start",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                      key={Math.random()}
                    >
                      <div
                        className="chat-content"
                        style={{ width: "auto", margin: 0, maxWidth: "90%" }}
                      >
                        <p className="chat-text">{e.messageText}</p>
                      </div>
                      {e.timeStampOfSend && (
                        <p className="date-cc-main">
                          {hours}:{minutes}
                        </p>
                      )}
                    </div>
                  );
                }
              })}
          </div>
          <div
            className="input-for-chat chatinputchanges"
            style={{ width: 500 }}
          >
            <input
              type="text"
              style={{ fontSize: 13 }}
              placeholder="Typ een bericht"
              value={this.state.sendingMessage}
              onChange={(e) =>
                this.setState({ sendingMessage: e.target.value })
              }
              onKeyUp={(e) => {
                if (e.keyCode === 13) {
                  this.sendMessage();
                }
              }}
            />
            <div
              className="send-icon-cic"
              onClick={this.sendMessage.bind(this)}
              style={{ color: "white", right: 6 }}
            >
              <IoIosSend />
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div
        className="rightmenuaddproduct rightmenucommentsaddproduct rightmenuproductfriendlists friendlistSeparatePageMain rightmenuonlychangeheader changeheaderonlyrightmenu"
        style={{ overflowX: "hidden", overflowY: this.state.searchNewFriendsInner && "hidden" }}
      >
        <div
          className="mid-rmap"
          style={{
            position: "relative",
            padding: 13,
            // alignItems: "flex-start",
          }}
        >
          <span>VRIENDEN</span>
          <div>
            <div style={{ display: "inline-flex" }}>
              {this.state.currentUser.newFollowersList && (
                <div
                  className="friendrequestsinrightmenu"
                  style={{ position: "static" }}
                  onClick={() => {
                    this.getFollowingRightMenu();
                    firebase
                      .database()
                      .ref(
                        `users/${this.state.currentUser.user_id}/newFollowersList`
                      )
                      .remove();
                    firebase
                      .database()
                      .ref(`users/${this.state.currentUser.user_id}/newFollowers`)
                      .remove();
                  }}
                >
                  <div>
                    {
                      Object.entries(this.state.currentUser.newFollowersList)
                        .length
                    }
                  </div>
                  <p>Volgers</p>
                </div>
              )}
            </div>
            {/* {window.innerWidth < 1008 && (<div style={{ display: "inline", padding: 5 }}></div>)} */}
            {/* {window.innerWidth < 1008 && (
              <ChevronLeftIcon
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  top: "50%",
                  transform: "translateY(-50%)",
                  left: 10,
                  fontSize: 18,
                }}
                onClick={() => {
                  this.props.closePopup();
                }}
              />
            )} */}
            <div style={{ display: "inline", padding: 5 }}></div>
            <EditIcon
              style={{
                cursor: "pointer",
                fontSize: 14,
              }}
              onClick={() => {
                this.getFollowersRightMenu();
              }}
            />
            <div style={{ display: "inline", padding: 5 }}></div>
            <CloseIcon
              style={{
                cursor: "pointer",
                fontSize: 14,
              }}
              onClick={() => {
                this.props.closePopup("fromfriendscompletely");
              }}
            />
          </div>
        </div>
        <div
          className="mid-img-uploading-rmap commentsmidimgurmap"
          style={{ alignItems: "flex-start", paddingTop: 0, width: "100%" }}
        >
          {this.state.searchNewFriendsInner ? (<div style={{ width: "100%" }} className="overflowingdivscrollfriendlist">
            {/* <div style={{ width: "100%", marginTop: 15 }} className="overflowingdivscrollfriendlist"> */}
            <div className="search-rm-main friendlist-search-dropdownCont">
              <input
                type="text"
                placeholder="Zoek naar nieuwe contacten"
                value={this.state.searchText}
                onChange={(e) => {
                  if (e.target.value === "") {
                    this.setState({
                      searchText: e.target.value,
                      arrForSearch: [],
                      noResultFoundedText: false,
                    });
                  } else {
                    this.setState({ searchText: e.target.value });
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    this.searchForUsers();
                  }
                }}
              />
              <SearchIcon
                onClick={this.searchForUsers.bind(this)}
                style={{ cursor: "pointer" }}
              />
              <ExpandMoreIcon
                className="chevron-icon-friendlist"
                onClick={() =>
                  this.setState({
                    dropdownSelection: !this.state.dropdownSelection,
                  })
                }
              />
              {this.checkForDropdownContainer()}
              {/* <div
                className="dropdown-main-fsdcont"
                style={{
                  opacity: this.state.dropdownSelection ? 1 : 0,
                  pointerEvents: this.state.dropdownSelection ? "all" : "none",
                }}
              >
                <label
                  onClick={() =>
                    this.setState({
                      searchNewFriendsInner: false,
                      dropdownSelection: false,
                      arrForSearch: [],
                      searchText: "",
                      noResultFoundedText: false,
                      noResultFoundedTextFriends: false,
                    })
                  }
                >
                  Mijn vriendenlijst
                </label>
                <label
                  onClick={() =>
                    this.setState({
                      searchNewFriendsInner: true,
                      dropdownSelection: false,
                      searchText: "",
                      arrFriendsFiltered: [],
                      noResultFoundedText: false,
                      noResultFoundedTextFriends: false,
                    })
                  }
                >
                  Nieuwe contacten
                </label>
              </div> */}
            </div>
            {this.state.noResultFoundedText ? (
              <ul className="search-stuff-new">
                <li style={{ fontSize: 15, letterSpacing: 1.6 }}>
                  Geen resultaten
                </li>
              </ul>
            ) : this.state.arrForSearch.length > 0 && (
              <ul className="search-stuff-new">
                {this.state.arrForSearch.map((e) => {
                  return (
                    <li key={Math.random()} style={{ position: "relative" }}>
                      <div
                        className="overlay-bekijk-lists"
                        style={{
                          width: "65%",
                          position: "absolute",
                          top: 0,
                          left: 0,
                          bottom: 0,
                        }}
                        onClick={() => {
                          localStorage.removeItem("onCurrentTab");
                          localStorage.removeItem("onCurrentTabCompany");

                          setTimeout(() => {
                            if (e.type === "user") {
                              history.push(`/member/${e.user_name_id}`);
                              if (window.innerWidth > 1008) {
                                this.props.closePopup("fromfriendscompletely");
                              }
                            } else {
                              history.push(`/company/${e.user_name_id}`);
                              if (window.innerWidth > 1008) {
                                this.props.closePopup("fromfriendscompletely");
                              }
                            }
                          }, 200);
                        }}
                      ></div>
                      {!e.profile_image ? (
                        <img src="https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9" />
                      ) : (
                        <img src={e.profile_image} alt="profile" />
                      )}
                      <div className="contentdescar">
                        {e.company_name ? (
                          <span>{e.company_name}</span>
                        ) : (
                          <span>
                            {e.first_name} {e.last_name}
                          </span>
                        )}
                        <span>{e.country}</span>
                      </div>
                      {this.checkForFollowIcon(e.user_id)}
                      {/* <div className="btns-rmodal" style={{ width: 130 }}>
                        <div className="text-brmodal">
                          {e.alreadyFriend ? (
                            <p>Vrienden</p>
                          ) : e.alreadyRequest ? (
                            e.sentByMe ? (
                              <p>Verstuurd</p>
                            ) : (
                              <p>Ontvangen</p>
                            )
                          ) : (
                            <p>Uitnodigen</p>
                          )}
                        </div>
                        {e.alreadyFriend ? (
                          <button className="add-icon-bmodal">
                            <CheckIcon style={{ cursor: "pointer" }} />
                          </button>
                        ) : e.alreadyRequest ? (
                          <button
                            className="add-icon-bmodal"
                            style={{ backgroundColor: "#C17F6B" }}
                          >
                            <CheckIcon style={{ cursor: "pointer" }} />
                          </button>
                        ) : (
                          <button
                            className="add-icon-bmodal"
                            onClick={() => sendRequest(e)}
                          >
                            <AddIcon />
                          </button>
                        )}
                      </div> */}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>) : (<div style={{ width: "100%" }} className="overflowingdivscrollfriendlist">
            <div className="search-rm-main friendlist-search-dropdownCont">
              <input
                type="text"
                placeholder={`Zoek in je ${this.state.onFollowingMessages ? "volgend" : "volgers"}`}
                value={this.state.searchText}
                onChange={(e) => {
                  if (e.target.value === "") {
                    this.setState({
                      searchText: e.target.value,
                      arrFriendsFiltered: [],
                      noResultFoundedTextFriends: false,
                    });
                  } else {
                    let filtered = this.state.arr.filter((e) => {
                      if (e.company_name) {
                        return e.company_name
                          .toLowerCase()
                          .includes(this.state.searchText.toLowerCase());
                      } else {
                        if (e.first_name) {
                          let toSearch = e.first_name + " " + e.last_name;
                          return toSearch
                            .toLowerCase()
                            .includes(this.state.searchText.toLowerCase());
                        }
                      }
                    });
                    if (filtered.length > 0) {
                      this.setState({ noResultFoundedTextFriends: false });
                    } else {
                      this.setState({ noResultFoundedTextFriends: true });
                    }
                    this.setState({
                      searchText: e.target.value,
                      arrFriendsFiltered: filtered,
                    });
                  }
                }}
              />
              <SearchIcon className={this.state.currentUser.type === "user" ? "search-to-right" : ""} />
              {this.state.currentUser.type !== "user" && (<ExpandMoreIcon
                className="chevron-icon-friendlist"
                onClick={() =>
                  this.setState({
                    dropdownSelection: !this.state.dropdownSelection,
                  })
                }
              />)}
              {this.checkForDropdownContainer()}
            </div>
            {this.state.noResultFoundedTextFriends ? (
              <ul className="search-stuff-new">
                <li style={{ fontSize: 15, letterSpacing: 1.6 }}>
                  Geen resultaten
                </li>
              </ul>
            ) : this.state.arrFriendsFiltered.length > 0 ? (
              [...this.state.arrFriendsFiltered]
                .sort((x, y) => {
                  return y.timestamp - x.timestamp;
                })
                .map((e, i) => {
                  return (
                    <>
                      <div className="comment-block-iled">
                        <div className="credentials-cbiled">
                          {e.profile_image ? (
                            <img
                              src={e.profile_image}
                              alt="userimage"
                              onClick={() => {
                                localStorage.removeItem("onCurrentTab");
                                localStorage.removeItem("onCurrentTabCompany");
                                setTimeout(() => {
                                  if (e.type === "company") {
                                    // Commented by me
                                    history.push(`/company/${e.user_name_id}`);
                                    if (window.innerWidth > 1009) {
                                      this.props.closePopup(
                                        "fromfriendscompletely"
                                      );
                                    }

                                    // window.location.href = `/company/${e.user_name_id}`;
                                  } else {
                                    // Commented by me
                                    history.push(`/member/${e.user_name_id}`);
                                    if (window.innerWidth > 1009) {
                                      this.props.closePopup(
                                        "fromfriendscompletely"
                                      );
                                    }

                                    // window.location.href = `/member/${e.user_name_id}`;
                                  }
                                }, 200);
                              }}
                            />
                          ) : (
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9"
                              alt="userimage"
                              onClick={() => {
                                localStorage.removeItem("onCurrentTab");
                                localStorage.removeItem("onCurrentTabCompany");
                                setTimeout(() => {
                                  if (e.type === "company") {
                                    // Commented by me
                                    history.push(`/company/${e.user_name_id}`);
                                    if (window.innerWidth > 1009) {
                                      this.props.closePopup(
                                        "fromfriendscompletely"
                                      );
                                    }

                                    // window.location.href = `/company/${e.user_name_id}`;
                                  } else {
                                    // Commented by me
                                    history.push(`/member/${e.user_name_id}`);
                                    if (window.innerWidth > 1009) {
                                      this.props.closePopup(
                                        "fromfriendscompletely"
                                      );
                                    }

                                    // window.location.href = `/member/${e.user_name_id}`;
                                  }
                                }, 200);
                              }}
                            />
                          )}
                          {/* <div
                            className="overflow-redirect"
                            onClick={() => {
                              let userid = localStorage.getItem("userid");
                              let filtered = this.props.message.filter((h) => {
                                return h[0] === e.user_id;
                              });
                              if (filtered.length > 0) {
                                let a = filtered[0][1];
                                let allMessages = [];
                                if (a) {
                                  a = Object.entries(a);
                                  a.map((e, i) => {
                                    allMessages.push(a[i][1]);
                                  });
                                  this.setState({ allMessages });
                                  allMessages.map((g) => {
                                    if (g.date && g.key) {
                                      firebase
                                        .database()
                                        .ref(
                                          "allMessages/" +
                                          userid +
                                          "/" +
                                          e.user_id +
                                          "/" +
                                          g.key +
                                          "/seen"
                                        )
                                        .set(true);
                                    }
                                  });
                                }
                              } else {
                                this.setState({ allMessages: [] });
                              }
                              if (window.innerWidth < 1009) {
                                document.querySelector("html").style.overflowY = "hidden"
                              }
                              this.setState({
                                chatPopup: true,
                                selectedUser: e,
                                currentChatUser: e,
                              });
                            }}
                          ></div>
                          {e.type === "user" ? (
                            <label>
                              {e.first_name} {e.last_name} <br />{" "}
                              {this.checkForUserMessage(e)}{" "}
                            </label>
                          ) : (
                            <label>
                              {e.company_name} <br />{" "}
                              {this.checkForUserMessage(e)}
                            </label>
                          )}
                          <p className="recentmsgdate">
                            {this.getRecentMessageDate(e)}
                          </p>
                          {this.getMessageCount(e)} */}
                          {e.type === "user" ? (
                            <label>
                              {e.first_name} {e.last_name}
                            </label>
                          ) : (
                            <label>
                              {e.company_name}
                            </label>
                          )}
                        </div>
                      </div>
                    </>
                  );
                })
            ) : (
              this.state.arr.length > 0 &&
              [...this.state.arr]
                .sort((x, y) => {
                  return y.timestamp - x.timestamp;
                })
                .map((e, i) => {
                  return (
                    <>
                      <div className="comment-block-iled">
                        <div className="credentials-cbiled">
                          {e.profile_image ? (
                            <img
                              src={e.profile_image}
                              alt="userimage"
                              onClick={() => {
                                localStorage.removeItem("onCurrentTab");
                                localStorage.removeItem("onCurrentTabCompany");
                                setTimeout(() => {
                                  if (e.type === "company") {
                                    // Commented by me
                                    history.push(`/company/${e.user_name_id}`);
                                    if (window.innerWidth > 1009) {
                                      this.props.closePopup(
                                        "fromfriendscompletely"
                                      );
                                    }

                                    // window.location.href = `/company/${e.user_name_id}`;
                                  } else {
                                    // Commented by me
                                    history.push(`/member/${e.user_name_id}`);
                                    if (window.innerWidth > 1009) {
                                      this.props.closePopup(
                                        "fromfriendscompletely"
                                      );
                                    }
                                    // window.location.href = `/member/${e.user_name_id}`;
                                  }
                                }, 200);
                              }}
                            />
                          ) : (
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9"
                              alt="userimage"
                              onClick={() => {
                                localStorage.removeItem("onCurrentTab");
                                localStorage.removeItem("onCurrentTabCompany");
                                setTimeout(() => {
                                  if (e.type === "company") {
                                    // Commented by me
                                    history.push(`/company/${e.user_name_id}`);
                                    if (window.innerWidth > 1009) {
                                      this.props.closePopup(
                                        "fromfriendscompletely"
                                      );
                                    }

                                    // window.location.href = `/company/${e.user_name_id}`;
                                  } else {
                                    // Commented by me
                                    history.push(`/member/${e.user_name_id}`);
                                    if (window.innerWidth > 1009) {
                                      this.props.closePopup(
                                        "fromfriendscompletely"
                                      );
                                    }
                                    // window.location.href = `/member/${e.user_name_id}`;
                                  }
                                }, 200);
                              }}
                            />
                          )}
                          {/* <div
                            className="overflow-redirect"
                            onClick={() => {
                              let userid = localStorage.getItem("userid");
                              let filtered = this.props.message.filter((h) => {
                                return h[0] === e.user_id;
                              });
                              if (filtered.length > 0) {
                                let a = filtered[0][1];
                                let allMessages = [];
                                if (a) {
                                  a = Object.entries(a);
                                  a.map((e, i) => {
                                    allMessages.push(a[i][1]);
                                  });
                                  this.setState({ allMessages });
                                  allMessages.map((g) => {
                                    if (g.date && g.key) {
                                      firebase
                                        .database()
                                        .ref(
                                          "allMessages/" +
                                          userid +
                                          "/" +
                                          e.user_id +
                                          "/" +
                                          g.key +
                                          "/seen"
                                        )
                                        .set(true);
                                    }
                                  });
                                }
                              } else {
                                this.setState({ allMessages: [] });
                              }
                              if (window.innerWidth < 1009) {
                                document.querySelector("html").style.overflowY = "hidden"
                              }
                              this.setState({
                                chatPopup: true,
                                selectedUser: e,
                                currentChatUser: e,
                              });
                            }}
                          ></div>
                          {e.type === "user" ? (
                            <label>
                              {e.first_name} {e.last_name} <br />{" "}
                              {this.checkForUserMessage(e)}{" "}
                            </label>
                          ) : (
                            <label>
                              {e.company_name} <br />{" "}
                              {this.checkForUserMessage(e)}
                            </label>
                          )}
                          <p className="recentmsgdate">
                            {this.getRecentMessageDate(e)}
                          </p>
                          {this.getMessageCount(e)} */}
                          {e.type === "user" ? (
                            <label>
                              {e.first_name} {e.last_name}
                            </label>
                          ) : (
                            <label>
                              {e.company_name}
                            </label>
                          )}
                        </div>
                      </div>
                    </>
                  );
                })
            )}
          </div>)}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducers.user,
    message: state.authReducers.message,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: (user) => dispatch(updateUser(user)),
    updateMessage: (message) => dispatch(updateMessage(message)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FriendListRightMenu);
