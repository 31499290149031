import React, { Component } from 'react'
import { connect } from 'react-redux'
import AdminNavbar from '../AdminNavbar'
import { updateBhodiProducts } from '../../Redux/actions/authActions'
import ManualEventCode from './ManualEventCode'

export class AdminCodeWrapper extends Component {
    constructor() {
        super()
        this.state = {
            currentMemberData: "",
            showLoader: false,
        }
    }

    componentDidMount() {
        let userid = localStorage.getItem("userid")

        let currentMemberData = this.props.user.filter((t) => {
            return t.user_id === userid
        })
        if (currentMemberData.length > 0) {
            this.setState({ currentMemberData: currentMemberData[0] })
        }
    }

    render() {
        return (
            <div>
                <AdminNavbar codeImportPage={true} />
                {this.state.showLoader ? (<div className="loaderspaceloading">
                    <div className="lds-heart">
                        <div></div>
                    </div>
                </div>) : this.state.currentMemberData && (<ManualEventCode currentMemberData={this.state.currentMemberData} changeCompany={(userid) => {
                    this.setState({ showLoader: true })

                    setTimeout(() => {
                        let currentMemberData = this.props.user.filter((t) => {
                            return t.user_id === userid
                        })
                        if (currentMemberData.length > 0) {
                            this.setState({ currentMemberData: currentMemberData[0], showLoader: false })
                        }
                    }, 1000);
                }} />)}
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.authReducers.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateBhodiProducts: (bhodi_products) => dispatch(updateBhodiProducts(bhodi_products)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(AdminCodeWrapper);