const checkForSubscribedExclusives = (user) => {
    if (user) {
        if (user.subscription_validity) {
            if (new Date(Date.parse(user.subscription_validity)).getTime() > new Date().getTime()) {
                return true;
            }
        }
        if (user.subscribedForExclusives) {
            let objected = Object.entries(user.subscribedForExclusives)
            if (objected.length > 0) {
                let filtered = objected.filter((t) => {
                    return t[1].payment_status === "paid" && t[1].subscription && t[1].subscription.status === "active"
                })
                if (filtered.length > 0) {
                    return true;
                }
            }
        }
    }
    return false;
}

export {
    checkForSubscribedExclusives
}