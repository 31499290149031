import React, { Component } from 'react'
import LikeIcon from "../assets/hearticonmainwhite.png";
import LikeIconBlack from "../assets/hearticonmainblack.png";
import firebase from "../Config/Firebase";
import LikeFilledIcon from "../assets/heartfilleimage.png";
import LikeFilledIconBlack from "../assets/heartfilleimageblack.png";
import "./index.css"
import { connect } from 'react-redux';
import CloseIcon from "@material-ui/icons/Close";
import { history } from '../Config/Routes';
import { AiOutlineLink } from 'react-icons/ai';
import { followUnfollowCompany } from '../Functions/friendFunctions';

export class FriendFollowBottom extends Component {

    constructor() {
        super()
        this.state = {
            currentUserMember: "",
            currentUser: "",
            currentUserHere: "",
            currentUserId: "",
        }
    }

    componentDidMount() {
        if (document.querySelector(".footergreen")) {
            document.querySelector(".footergreen").style.display = "none"
        }

        let userid = localStorage.getItem("userid");
        this.setState({ currentUserId: userid });

        let filter = this.props.user.filter((u) => {
            return u.user_id === this.props.member_id;
        });
        if (filter.length > 0) {
            this.setState({ currentUserMember: filter[0], currentUser: filter[0] })
        }

        let filterCurrentHere = this.props.user.filter((u) => {
            return u.user_id === userid;
        });
        this.setState({ currentUserHere: filterCurrentHere[0] });
    }

    componentDidUpdate(prevProps) {
        if (this.props.user !== prevProps.user) {
            let userid = localStorage.getItem("userid");

            let filter = this.props.user.filter((u) => {
                return u.user_id === this.props.member_id;
            });
            if (filter.length > 0) {
                this.setState({ currentUserMember: filter[0], currentUser: filter[0] })
            }

            let filterCurrentHere = this.props.user.filter((u) => {
                return u.user_id === userid;
            });
            this.setState({ currentUserHere: filterCurrentHere[0] });
        }
    }

    componentWillUnmount() {
        if (document.querySelector(".footergreen")) {
            document.querySelector(".footergreen").style.display = "flex"
        }
    }

    checkForFollowIcon() {
        if (this.state.currentUserMember.followed_by) {
            let objected = Object.entries(this.state.currentUserMember.followed_by);
            let filtered = objected.filter((g) => {
                return g[1].user_id === this.state.currentUserId;
            });
            if (filtered.length > 0) {
                if (this.state.currentUserMember.friends) {
                    let objectedFriends = Object.entries(
                        this.state.currentUserMember.friends
                    );
                    let filteredFriends = objectedFriends.filter((g) => {
                        return g[1].user_id === this.state.currentUserId;
                    });
                    if (filteredFriends.length === 0) {
                        return (
                            <div className='inner-ffbot'
                                style={{ cursor: "pointer" }}
                                onClick={this.followCompany.bind(this)}
                            >
                                <div className="captionvoucherstuff charityimageonvoucher">
                                    <img src={this.props.showBlackIcon ? LikeFilledIconBlack : LikeFilledIcon} />
                                </div>
                                <label style={{ cursor: "pointer" }}>Ontvolgen</label>
                            </div>
                        );
                    }
                } else {
                    return (
                        <div className='inner-ffbot'
                            style={{ cursor: "pointer" }}
                            onClick={this.followCompany.bind(this)}
                        >
                            <div className="captionvoucherstuff charityimageonvoucher">
                                <img src={this.props.showBlackIcon ? LikeFilledIconBlack : LikeFilledIcon} />
                            </div>
                            <label style={{ cursor: "pointer" }}>Ontvolgen</label>
                        </div>
                    );
                    // return <img src={hwb} style={{ width: 20, height: 20, borderRadius: 0, marginLeft: 10, marginRight: 0 }} onClick={this.followCompany.bind(this)} />
                }
            } else {
                if (this.state.currentUserMember.friends) {
                    let objectedFriends = Object.entries(
                        this.state.currentUserMember.friends
                    );
                    let filteredFriends = objectedFriends.filter((g) => {
                        return g[1].user_id === this.state.currentUserId;
                    });
                    if (filteredFriends.length === 0) {
                        return (
                            <div className='inner-ffbot'
                                style={{ cursor: "pointer" }}
                                onClick={this.followCompany.bind(this)}
                            >
                                <div className="captionvoucherstuff charityimageonvoucher">
                                    <img src={this.props.showBlackIcon ? LikeIconBlack : LikeIcon} />
                                </div>
                                <label style={{ cursor: "pointer" }}>Volgen</label>
                            </div>
                        );
                        // return <img src={hbb} style={{ width: 20, height: 20, borderRadius: 0, marginLeft: 10, marginRight: 0 }} onClick={this.followCompany.bind(this)} />
                    }
                } else {
                    return (
                        <div className='inner-ffbot'
                            style={{ cursor: "pointer" }}
                            onClick={this.followCompany.bind(this)}
                        >
                            <div className="captionvoucherstuff charityimageonvoucher">
                                <img src={this.props.showBlackIcon ? LikeIconBlack : LikeIcon} />
                            </div>
                            <label style={{ cursor: "pointer" }}>Volgen</label>
                        </div>
                    );
                    // return <img src={hbb} style={{ width: 20, height: 20, borderRadius: 0, marginLeft: 10, marginRight: 0 }} onClick={this.followCompany.bind(this)} />
                }
            }
        } else {
            if (this.state.currentUserMember.friends) {
                let objectedFriends = Object.entries(
                    this.state.currentUserMember.friends
                );
                let filteredFriends = objectedFriends.filter((g) => {
                    return g[1].user_id === this.state.currentUserId;
                });
                if (filteredFriends.length === 0) {
                    return (
                        <div className='inner-ffbot'
                            style={{ cursor: "pointer" }}
                            onClick={this.followCompany.bind(this)}
                        >
                            <div className="captionvoucherstuff charityimageonvoucher">
                                <img src={this.props.showBlackIcon ? LikeIconBlack : LikeIcon} />
                            </div>
                            <label style={{ cursor: "pointer" }}>Volgen</label>
                        </div>
                    );
                    // return <img src={hbb} style={{ width: 20, height: 20, borderRadius: 0, marginLeft: 10, marginRight: 0 }} onClick={this.followCompany.bind(this)} />
                }
            } else {
                return (
                    <div className='inner-ffbot'
                        style={{ cursor: "pointer" }}
                        onClick={this.followCompany.bind(this)}
                    >
                        <div className="captionvoucherstuff charityimageonvoucher">
                            <img src={this.props.showBlackIcon ? LikeIconBlack : LikeIcon} />
                        </div>
                        <label style={{ cursor: "pointer" }}>Volgen</label>
                    </div>
                );
                // return <img src={hbb} style={{ width: 20, height: 20, borderRadius: 0, marginLeft: 10, marginRight: 0 }} onClick={this.followCompany.bind(this)} />
            }
        }
    }

    followCompany() {
        followUnfollowCompany(this.state.currentUser, this.state.currentUserId, this.props.user)
        // if (this.state.currentUser.followed_by) {
        //     let objected = Object.entries(this.state.currentUser.followed_by);
        //     let filtered = objected.filter((g) => {
        //         return g[1].user_id === this.state.currentUserId;
        //     });
        //     if (filtered.length > 0) {
        //         if (this.state.currentUser.newFollowersList) {
        //             let objectedSec = Object.entries(
        //                 this.state.currentUser.newFollowersList
        //             );
        //             let filteredNewFollowers = objectedSec.filter((g) => {
        //                 return g[1].user_id === this.state.currentUserId;
        //             });
        //             if (filteredNewFollowers.length > 0) {
        //                 let newFollowers = Number(this.state.currentUser.newFollowers);
        //                 newFollowers = newFollowers - 1;
        //                 firebase
        //                     .database()
        //                     .ref(
        //                         "users/" +
        //                         this.state.currentUser.user_id +
        //                         "/newFollowersList/" +
        //                         filteredNewFollowers[0][1].push_key
        //                     )
        //                     .remove();
        //                 firebase
        //                     .database()
        //                     .ref("users/" + this.state.currentUser.user_id + "/newFollowers")
        //                     .set(newFollowers);
        //             }
        //         }
        //         let filteredCurrentUser = this.props.user.filter((h) => {
        //             return h.user_id === this.state.currentUserId;
        //         });
        //         if (filteredCurrentUser[0].following) {
        //             let objectedSec = Object.entries(filteredCurrentUser[0].following);
        //             let filteredSec = objectedSec.filter((g) => {
        //                 return g[1].user_id === this.state.currentUser.user_id;
        //             });
        //             if (filteredSec.length > 0) {
        //                 firebase
        //                     .database()
        //                     .ref(
        //                         "users/" +
        //                         this.state.currentUserId +
        //                         "/following/" +
        //                         filteredSec[0][1].push_key
        //                     )
        //                     .remove();
        //             }
        //         }
        //         firebase
        //             .database()
        //             .ref(
        //                 "users/" +
        //                 this.state.currentUser.user_id +
        //                 "/followed_by/" +
        //                 filtered[0][1].push_key
        //             )
        //             .remove();
        //     } else {
        //         let pushFirst = firebase
        //             .database()
        //             .ref("users/" + this.state.currentUserId + "/following")
        //             .push().key;
        //         let pushSecond = firebase
        //             .database()
        //             .ref("users/" + this.state.currentUser.user_id + "/followed_by")
        //             .push().key;
        //         let pushSecondNew = firebase
        //             .database()
        //             .ref("users/" + this.state.currentUser.user_id + "/newFollowersList")
        //             .push().key;
        //         let newFollowers = 0;
        //         let obj = {
        //             user_id: this.state.currentUser.user_id,
        //             push_key: pushFirst,
        //         };
        //         let objSec = {
        //             user_id: this.state.currentUserId,
        //             push_key: pushSecond,
        //         };
        //         let objThird = {
        //             user_id: this.state.currentUserId,
        //             push_key: pushSecondNew,
        //         };
        //         if (this.state.currentUser.newFollowers) {
        //             newFollowers = Number(this.state.currentUser.newFollowers);
        //         }
        //         newFollowers = newFollowers + 1;
        //         firebase
        //             .database()
        //             .ref("users/" + this.state.currentUserId + "/following/" + pushFirst)
        //             .set(obj);
        //         firebase
        //             .database()
        //             .ref(
        //                 "users/" +
        //                 this.state.currentUser.user_id +
        //                 "/followed_by/" +
        //                 pushSecond
        //             )
        //             .set(objSec);
        //         firebase
        //             .database()
        //             .ref("users/" + this.state.currentUser.user_id + "/newFollowers")
        //             .set(newFollowers);
        //         firebase
        //             .database()
        //             .ref(
        //                 "users/" +
        //                 this.state.currentUser.user_id +
        //                 "/newFollowersList/" +
        //                 pushSecondNew
        //             )
        //             .set(objThird);
        //     }
        // } else {
        //     let pushFirst = firebase
        //         .database()
        //         .ref("users/" + this.state.currentUserId + "/following")
        //         .push().key;
        //     let pushSecond = firebase
        //         .database()
        //         .ref("users/" + this.state.currentUser.user_id + "/followed_by")
        //         .push().key;
        //     let pushSecondNew = firebase
        //         .database()
        //         .ref("users/" + this.state.currentUser.user_id + "/newFollowersList")
        //         .push().key;
        //     let newFollowers = 0;
        //     let obj = {
        //         user_id: this.state.currentUser.user_id,
        //         push_key: pushFirst,
        //     };
        //     let objSec = {
        //         user_id: this.state.currentUserId,
        //         push_key: pushSecond,
        //     };
        //     let objThird = {
        //         user_id: this.state.currentUserId,
        //         push_key: pushSecondNew,
        //     };
        //     if (this.state.currentUser.newFollowers) {
        //         newFollowers = Number(this.state.currentUser.newFollowers);
        //     }
        //     newFollowers = newFollowers + 1;
        //     firebase
        //         .database()
        //         .ref("users/" + this.state.currentUserId + "/following/" + pushFirst)
        //         .set(obj);
        //     firebase
        //         .database()
        //         .ref(
        //             "users/" +
        //             this.state.currentUser.user_id +
        //             "/followed_by/" +
        //             pushSecond
        //         )
        //         .set(objSec);
        //     firebase
        //         .database()
        //         .ref("users/" + this.state.currentUser.user_id + "/newFollowers")
        //         .set(newFollowers);
        //     firebase
        //         .database()
        //         .ref(
        //             "users/" +
        //             this.state.currentUser.user_id +
        //             "/newFollowersList/" +
        //             pushSecondNew
        //         )
        //         .set(objThird);
        // }
    }

    // followCompany() {
    //     if (this.state.currentUser.followed_by) {
    //         let objected = Object.entries(this.state.currentUser.followed_by);
    //         let filtered = objected.filter((g) => {
    //             return g[1].user_id === this.state.currentUserId;
    //         });
    //         if (filtered.length > 0) {
    //             if (this.state.currentUser.newFollowersList) {
    //                 let objectedSec = Object.entries(
    //                     this.state.currentUser.newFollowersList
    //                 );
    //                 let filteredNewFollowers = objectedSec.filter((g) => {
    //                     return g[1].user_id === this.state.currentUserId;
    //                 });
    //                 if (filteredNewFollowers.length > 0) {
    //                     let newFollowers = Number(this.state.currentUser.newFollowers);
    //                     newFollowers = newFollowers - 1;
    //                     firebase
    //                         .database()
    //                         .ref(
    //                             "users/" +
    //                             this.state.currentUser.user_id +
    //                             "/newFollowersList/" +
    //                             filteredNewFollowers[0][1].push_key
    //                         )
    //                         .remove();
    //                     firebase
    //                         .database()
    //                         .ref("users/" + this.state.currentUser.user_id + "/newFollowers")
    //                         .set(newFollowers);
    //                 }
    //             }
    //             let filteredCurrentUser = this.props.user.filter((h) => {
    //                 return h.user_id === this.state.currentUserId;
    //             });
    //             if (filteredCurrentUser[0].following) {
    //                 let objectedSec = Object.entries(filteredCurrentUser[0].following);
    //                 let filteredSec = objectedSec.filter((g) => {
    //                     return g[1].user_id === this.state.currentUser.user_id;
    //                 });
    //                 if (filteredSec.length > 0) {
    //                     firebase
    //                         .database()
    //                         .ref(
    //                             "users/" +
    //                             this.state.currentUserId +
    //                             "/following/" +
    //                             filteredSec[0][1].push_key
    //                         )
    //                         .remove();
    //                 }
    //             }
    //             firebase
    //                 .database()
    //                 .ref(
    //                     "users/" +
    //                     this.state.currentUser.user_id +
    //                     "/followed_by/" +
    //                     filtered[0][1].push_key
    //                 )
    //                 .remove();
    //         } else {
    //             let pushFirst = firebase
    //                 .database()
    //                 .ref("users/" + this.state.currentUserId + "/following")
    //                 .push().key;
    //             let pushSecond = firebase
    //                 .database()
    //                 .ref("users/" + this.state.currentUser.user_id + "/followed_by")
    //                 .push().key;
    //             let pushSecondNew = firebase
    //                 .database()
    //                 .ref("users/" + this.state.currentUser.user_id + "/newFollowersList")
    //                 .push().key;
    //             let newFollowers = 0;
    //             let obj = {
    //                 user_id: this.state.currentUser.user_id,
    //                 push_key: pushFirst,
    //             };
    //             let objSec = {
    //                 user_id: this.state.currentUserId,
    //                 push_key: pushSecond,
    //             };
    //             let objThird = {
    //                 user_id: this.state.currentUserId,
    //                 push_key: pushSecondNew,
    //             };
    //             if (this.state.currentUser.newFollowers) {
    //                 newFollowers = Number(this.state.currentUser.newFollowers);
    //             }
    //             newFollowers = newFollowers + 1;
    //             firebase
    //                 .database()
    //                 .ref("users/" + this.state.currentUserId + "/following/" + pushFirst)
    //                 .set(obj);
    //             firebase
    //                 .database()
    //                 .ref(
    //                     "users/" +
    //                     this.state.currentUser.user_id +
    //                     "/followed_by/" +
    //                     pushSecond
    //                 )
    //                 .set(objSec);
    //             firebase
    //                 .database()
    //                 .ref("users/" + this.state.currentUser.user_id + "/newFollowers")
    //                 .set(newFollowers);
    //             firebase
    //                 .database()
    //                 .ref(
    //                     "users/" +
    //                     this.state.currentUser.user_id +
    //                     "/newFollowersList/" +
    //                     pushSecondNew
    //                 )
    //                 .set(objThird);
    //         }
    //     } else {
    //         let pushFirst = firebase
    //             .database()
    //             .ref("users/" + this.state.currentUserId + "/following")
    //             .push().key;
    //         let pushSecond = firebase
    //             .database()
    //             .ref("users/" + this.state.currentUser.user_id + "/followed_by")
    //             .push().key;
    //         let pushSecondNew = firebase
    //             .database()
    //             .ref("users/" + this.state.currentUser.user_id + "/newFollowersList")
    //             .push().key;
    //         let newFollowers = 0;
    //         let obj = {
    //             user_id: this.state.currentUser.user_id,
    //             push_key: pushFirst,
    //         };
    //         let objSec = {
    //             user_id: this.state.currentUserId,
    //             push_key: pushSecond,
    //         };
    //         let objThird = {
    //             user_id: this.state.currentUserId,
    //             push_key: pushSecondNew,
    //         };
    //         if (this.state.currentUser.newFollowers) {
    //             newFollowers = Number(this.state.currentUser.newFollowers);
    //         }
    //         newFollowers = newFollowers + 1;
    //         firebase
    //             .database()
    //             .ref("users/" + this.state.currentUserId + "/following/" + pushFirst)
    //             .set(obj);
    //         firebase
    //             .database()
    //             .ref(
    //                 "users/" +
    //                 this.state.currentUser.user_id +
    //                 "/followed_by/" +
    //                 pushSecond
    //             )
    //             .set(objSec);
    //         firebase
    //             .database()
    //             .ref("users/" + this.state.currentUser.user_id + "/newFollowers")
    //             .set(newFollowers);
    //         firebase
    //             .database()
    //             .ref(
    //                 "users/" +
    //                 this.state.currentUser.user_id +
    //                 "/newFollowersList/" +
    //                 pushSecondNew
    //             )
    //             .set(objThird);
    //     }
    // }

    render() {
        return (
            <div className='friends-follow-bottom'>
                <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: 15 }}>
                    <CloseIcon onClick={() => this.props.closePopup()} />
                </div>
                {this.props.showUserInfo && (<div className='inner-ffbot' style={{ cursor: "pointer" }} onClick={() => {
                    localStorage.removeItem("onCurrentTab");
                    localStorage.removeItem("onCurrentTabCompany");
                    setTimeout(() => {
                        if (this.state.currentUser.type === "company") {
                            history.push(`/company/${this.state.currentUser.user_name_id}`)
                        } else {
                            history.push(`/member/${this.state.currentUser.user_name_id}`)
                        }
                    }, 400);
                }}>
                    {this.state.currentUser.profile_image ? (
                        <img
                            style={{ width: 30, height: 30, borderRadius: "50%", marginRight: 10 }}
                            src={this.state.currentUser.profile_image}
                        />
                    ) : (
                        <img
                            style={{ width: 30, height: 30, borderRadius: "50%", marginRight: 10 }}
                            src="https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9"
                        />
                    )}
                    <label style={{ cursor: "pointer", fontWeight: "bold" }}>{this.state.currentUser.type === "company" ? this.state.currentUser.company_name : this.state.currentUser.first_name + " " + this.state.currentUser.last_name} </label>
                </div>)}
                {this.props.mustShowVisitLink && (<div className='inner-ffbot' style={{ cursor: "pointer" }} onClick={() => {
                    localStorage.removeItem("onCurrentTab");
                    localStorage.removeItem("onCurrentTabCompany");
                    setTimeout(() => {
                        if (this.state.currentUser.type === "company") {
                            history.push(`/company/${this.state.currentUser.user_name_id}`)
                        } else {
                            history.push(`/member/${this.state.currentUser.user_name_id}`)
                        }
                    }, 400);
                }}>
                    <div className="captionvoucherstuff charityimageonvoucher">
                        <AiOutlineLink />
                    </div>
                    <label style={{ cursor: "pointer" }}>Bekijk profielpagina </label>
                </div>)}
                {this.checkForFollowIcon()}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducers.user,
        album: state.postsReducers.album,
    };
};

export default connect(
    mapStateToProps,
)(FriendFollowBottom);