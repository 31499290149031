import React, { Component } from "react";
import { connect } from "react-redux";
import {
    updateAllCampaigns,
} from "../Redux/actions/authActions";
import topcompanylandingpage from "../assets/toplandingpagecompany.png"
import headerBhodiIcon from "../assets/bhodi-header-mobile.png";
import { history } from "../Config/Routes";

class GuestCompanyNavbar extends Component {
    constructor() {
        super();
        this.state = {
            isDesktop: true,
        };
    }

    componentDidMount() {
        if (window.innerWidth > 1008) {
            this.setState({ isDesktop: true });
        } else {
            this.setState({ isDesktop: false });
        }

        window.addEventListener("resize", () => {
            if (window.innerWidth > 1008) {
                this.setState({ isDesktop: true });
            } else {
                this.setState({ isDesktop: false });
            }
        });

    }

    render() {
        return this.state.isDesktop ? (
            <div
                className="nav-fullwidth"
                style={{ backgroundColor: this.props.mustBeGray && "#F8F8F8" }}
            >
                <div className="container-nfw containernfw-guestnavbar">
                    <div
                        className="center-nfw"
                        style={{ flexDirection: "column" }}
                        onClick={() => {
                            window.location.href = `/company/${this.props.currentCompany.user_name_id}`;
                        }}
                    >
                        <img src={topcompanylandingpage} style={{ width: 90, height: 45, objectFit: "contain" }} />
                    </div>
                </div>
            </div>
        ) : (
            <div
                className={`navbarfullwidthMobile positionabsolutenav navbarmobileforcompanyguest`}
            >
                <div
                    className="top-nfwpm"
                    onClick={() => {
                        window.location.href = `/company/${this.props.currentCompany.user_name_id}`;
                    }}
                >
                    <img src={headerBhodiIcon} style={{ width: 100, height: 30, borderRadius: 0, objectFit: "cover", objectPosition: "center" }} />
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        bhodi_products: state.productsReducers.bhodi_products,
        all_impressies: state.globalReducers.all_impressies,
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        updateAllCampaigns: (global_campaigns) =>
            dispatch(updateAllCampaigns(global_campaigns)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(GuestCompanyNavbar);