import React, { Component } from 'react'

export class NumberFormatter extends Component {
    getformattedText() {
        let splitTo = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(Number(this.props.toFormat))
        let newNumber = splitTo.replace("€", "")
        return newNumber
    }

    render() {
        return (
            <font>{this.getformattedText()}</font>
        )
    }
}

export default NumberFormatter