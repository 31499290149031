import firebase from '../Config/Firebase'

const addToCart = (e, cartData) => {
    let uid = localStorage.getItem("userid")

    var futureDate = Math.floor(Date.now() / 1000)
    var minutesToAdd = Number(process.env.REACT_APP_CART_TIME);
    var currentDate = new Date();
    var futureDate = new Date(currentDate.getTime() + minutesToAdd * 60000);

    let futureTimeStamp = Math.floor(futureDate.getTime() / 1000)

    firebase.database().ref("cart_timings/" + uid + "/endDate").set(futureTimeStamp)
    firebase.database().ref("cart_timings/" + uid + "/extendOpportunity").set(true)

    let currentProduct = e
    let inCartOf = 0
    if (currentProduct.inCartOf) {
        inCartOf = currentProduct.inCartOf
    }
    let toUploadInCartOf = inCartOf + 1
    if (currentProduct.inUsersCartOf) {
        firebase.database().ref("all_events/" + currentProduct.pushKey + "/inCartOf").set(toUploadInCartOf)
        let objected = Object.entries(currentProduct.inUsersCartOf)
        let checkedUser = objected.filter((u) => {
            return u[1].user_id === uid
        })
        if (checkedUser.length > 0) {
            let filtered = cartData.filter((h) => {
                return h[1].pushProduct === currentProduct.pushKey
            })
            let qtyToHard = filtered[0][1].qty + 1
            firebase.database().ref("all_carts/" + uid + "/" + filtered[0][0] + "/qty").set(qtyToHard)
        } else {
            firebase.database().ref("all_events/" + currentProduct.pushKey + "/inCartOf").set(toUploadInCartOf)
            let obj = {
                user_id: uid,
                qty: 1
            }
            let pushUser = firebase.database().ref("all_events/" + currentProduct.pushKey + "/inUsersCartOf").push().key
            firebase.database().ref("all_events/" + currentProduct.pushKey + "/inUsersCartOf/" + pushUser).set(obj)
            let pushKey = firebase.database().ref("all_carts/" + uid).push().key
            let objProduct = {
                pushProduct: currentProduct.pushKey,
                qty: 1,
            }
            firebase.database().ref("all_carts/" + uid + "/" + pushKey).set(objProduct)
        }
    } else {
        firebase.database().ref("all_events/" + currentProduct.pushKey + "/inCartOf").set(toUploadInCartOf)
        let obj = {
            user_id: uid,
            qty: 1
        }
        let pushUser = firebase.database().ref("all_events/" + currentProduct.pushKey + "/inUsersCartOf").push().key
        firebase.database().ref("all_events/" + currentProduct.pushKey + "/inUsersCartOf/" + pushUser).set(obj)
        let pushKey = firebase.database().ref("all_carts/" + uid).push().key
        let objProduct = {
            pushProduct: currentProduct.pushKey,
            qty: 1,
        }
        firebase.database().ref("all_carts/" + uid + "/" + pushKey).set(objProduct).then(() => {
            let notificationToShow = document.getElementById('mustShowOnAddBasket');
            if (notificationToShow) {
                notificationToShow.style.opacity = 1
                notificationToShow.style.pointerEvents = "all"
            }

            setTimeout(() => {
                if (notificationToShow) {
                    notificationToShow.style.opacity = 0
                    notificationToShow.style.pointerEvents = "none"
                }
            }, 4000);
        })
    }
}

const bookmarkCurrentProduct = (e) => {
    let uid = localStorage.getItem("userid")

    let currentProduct = e
    let productToBookmarkKeySec = firebase.database().ref("bhodi_campaigns/" + currentProduct.created_by + "/" + currentProduct.pushKey + "/bookmarked_by/").push().key
    let objSec = {
        bookmarked_by: uid,
        pushKey: productToBookmarkKeySec,
        type: "campaign"
    }
    firebase.database().ref("bhodi_campaigns/" + currentProduct.created_by + "/" + currentProduct.pushKey + "/bookmarked_by/" + productToBookmarkKeySec).set(objSec)


    // if (currentProduct.campaignType === "local_event") {
    //     let notificationToShow = document.getElementById('mustShowOnFavoriteLastMinute');
    //     if (notificationToShow) {
    //         notificationToShow.style.opacity = 1
    //         notificationToShow.style.pointerEvents = "all"
    //     }

    //     setTimeout(() => {
    //         if (notificationToShow) {
    //             notificationToShow.style.opacity = 0
    //             notificationToShow.style.pointerEvents = "none"
    //         }
    //     }, 4000);
    // }
}

const unBookmarkCurrentProduct = (e) => {
    let uid = localStorage.getItem("userid")
    let currentProduct = e
    if (currentProduct.bookmarked_by) {
        let objectedSec = Object.entries(currentProduct.bookmarked_by)
        let filteredSec = objectedSec.filter((y) => {
            return y[1].bookmarked_by === uid
        })
        firebase.database().ref("bhodi_campaigns/" + currentProduct.created_by + "/" + currentProduct.pushKey + "/bookmarked_by/" + filteredSec[0][0]).remove()
    }
}

export { addToCart, bookmarkCurrentProduct, unBookmarkCurrentProduct }