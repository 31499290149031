import React, { Component } from 'react'
import { connect } from 'react-redux'
import ButtonTimerProductsNew from '../../Components/ButtonTimerProductsNew'
import { updateBhodiProducts, updateCampaignsCategory } from '../../Redux/actions/authActions'
import firebase from '../../Config/Firebase'
import { debounce } from 'lodash';
import AnnouncementBannerNew from './AnnouncementBannerNew'

let productsPerPage = 2
let currentPage = 1

export class NewProductWrapper extends Component {

    constructor() {
        super()
        this.state = {
            currentUserId: "",
            adminProduct: "",
            allFourProducts: [],
            memberFirstHeading: "",
            bhodiNewsHeading: "",
            bhodiNewsText: "",
            memberSecondHeading: "",
            dashboardMemberDesc: "",
            currentCampaignToShow: "",
            currentCampaignToShowVoucher: "",
            localEventBlock: [],
            showUploadPopup: false,
            currentUser: "",
            allImagesStock: [],
            allSectors: [],
            mainSector: "",
            mainSectorStock: "",
            bannerHeight: window.innerHeight,
            showDesktopLanding: true,
            showDesktopLandingContact: true,
            allImagesCurrentSectorStock: [],
            company_about: "",
            contactImage: "",
            contactImageMobile: "",
            showBottomIcons: false,
            memberDashboardDescription: "",
            memberLandingPageText: "",
            showImpressiePopup: false,
            impressiePhotos: [],
            currentImpressieIndex: 0,
            uploadedItems: 0,
            dropFile: [],
            loaderAddingImpressie: false,
            latestEventOrVoucher: "",
            isLoadingProducts: true,
            productsStart: 0,
            productsEnd: 2,
            currentPage: currentPage,
            indexOfFirstProduct: 0,
            indexOfLastProduct: productsPerPage,
            currentCompanyData: "",
        }

        this.debouncedFetchProductInfo = debounce(this.fetchProductInfo, 2000); // Adjust the delay as needed (2 seconds in this example)
    }

    async componentDidMount() {

        let userid = localStorage.getItem("userid")

        this.setState({ currentUserId: userid })

        let global_values = await this.waitForGlobalValues()
        let currentUser = await this.waitForCurrentUser(this.props.toShowProductOf)

        let maxProductsToShow = 4

        if (this.props.isSpecialProductsPage || this.props.onGuestUserPage) {
            maxProductsToShow = 5
            if (this.props.global_values) {
                if (this.props.global_values.products_count_new) {
                    maxProductsToShow = Number(this.props.global_values.products_count_new)
                }
            }
        } else {
            let currentCompany = this.props.user.filter((t) => {
                return t.user_id === this.props.toShowProductOf
            })
            if (currentCompany.length > 0) {
                this.setState({ currentCompanyData: currentCompany[0] })
                if (currentCompany[0].announcement && currentCompany[0].announcement.active) {
                    if (currentCompany[0].announcement.showAnnouncementWithoutTiming) {
                        this.setState({ showBannerAnnouncement: true, isLoadingProducts: false })
                    } else if (new Date(Date.parse(currentCompany[0].announcement.end_date_iso)).getTime() > new Date().getTime()) {
                        this.setState({ showBannerAnnouncement: true, isLoadingProducts: false })
                    }
                }
                if (currentCompany[0].productsInfo) {
                    if (currentCompany[0].productsInfo.maxProductsToShow) {
                        maxProductsToShow = Number(currentCompany[0].productsInfo.maxProductsToShow)
                    }
                }
            }
        }

        if (this.props.isSpecialProductsPage || this.props.onGuestUserPage) {
            // let filteredProducts = this.props.bhodi_products.filter((product) => {
            //     if (product.sizesActive) {
            //         let stocksAvailableSizes = []
            //         product.variations_data.map((e) => {
            //             if (Number(e.qty) === 0) {
            //                 return false;
            //             }
            //             let filteredStockLength = []
            //             let filteredStockLengthVerzilvered = []
            //             if (product.inCartOf) {
            //                 filteredStockLength = Object.entries(product.inCartOf).filter((t) => {
            //                     return t[1].selectedSize === e.size
            //                 })
            //             }
            //             if (product.verzilvered_by) {
            //                 filteredStockLengthVerzilvered = Object.entries(product.verzilvered_by).filter((t) => {
            //                     return t[1].selectedSize === e.size
            //                 })
            //             }
            //             if ((filteredStockLength.length + filteredStockLengthVerzilvered.length) < Number(e.qty)) {
            //                 stocksAvailableSizes.push(e)
            //             }
            //         })
            //         if (stocksAvailableSizes.length === 0) {
            //             return false;
            //         }
            //     } else {
            //         let filteredStockLength = []
            //         let filteredStockLengthVerzilvered = []
            //         if (product.inCartOf) {
            //             filteredStockLength = Object.entries(product.inCartOf).filter((t) => {
            //                 return !t[1].selectedSize
            //             })
            //         }
            //         if (product.verzilvered_by) {
            //             filteredStockLengthVerzilvered = Object.entries(product.verzilvered_by).filter((t) => {
            //                 return !t[1].selectedSize
            //             })
            //         }
            //         if (Number(product.qty) <= (filteredStockLength.length + filteredStockLengthVerzilvered.length) || Number(product.qty) === 0) {
            //             return false;
            //         }
            //     }

            //     return !product.availableOnTime && product.images && product.created_by === this.props.toShowProductOf && product.active && product.isSpecialProduct
            // })
            // let sortedProducts = [...filteredProducts].sort((a, b) => {
            //     return a.sort_time * 100 - b.sort_time * 100;
            // });
            // if (sortedProducts.length > 0) {
            //     this.setState({ allFourProducts: [...sortedProducts].splice(0, Number(maxProductsToShow)) })
            // }
            this.setState({ isLoadingProducts: true })
        } else if (this.props.bhodi_products && userid) {
            let filteredProducts = this.props.bhodi_products.filter((product) => {
                if (product.sizesActive) {
                    let stocksAvailableSizes = []
                    product.variations_data.map((e) => {
                        if (Number(e.qty) === 0) {
                            return false;
                        }
                        let filteredStockLength = []
                        let filteredStockLengthVerzilvered = []
                        if (product.inCartOf) {
                            filteredStockLength = Object.entries(product.inCartOf).filter((t) => {
                                return t[1].selectedSize === e.size
                            })
                        }
                        if (product.verzilvered_by) {
                            filteredStockLengthVerzilvered = Object.entries(product.verzilvered_by).filter((t) => {
                                return t[1].selectedSize === e.size
                            })
                        }
                        if ((filteredStockLength.length + filteredStockLengthVerzilvered.length) < Number(e.qty)) {
                            stocksAvailableSizes.push(e)
                        }
                    })
                    if (stocksAvailableSizes.length === 0) {
                        return false;
                    }
                } else {
                    let filteredStockLength = []
                    let filteredStockLengthVerzilvered = []
                    if (product.inCartOf) {
                        filteredStockLength = Object.entries(product.inCartOf).filter((t) => {
                            return !t[1].selectedSize
                        })
                    }
                    if (product.verzilvered_by) {
                        filteredStockLengthVerzilvered = Object.entries(product.verzilvered_by).filter((t) => {
                            return !t[1].selectedSize
                        })
                    }
                    if (Number(product.qty) <= (filteredStockLength.length + filteredStockLengthVerzilvered.length) || Number(product.qty) === 0) {
                        return false;
                    }
                }

                return !product.availableOnTime && product.images && product.created_by === this.props.toShowProductOf && product.active && !product.is_bookmarked && !product.onlyAvailableViaURL && !product.isSpecialProduct && !product.availableForCompanyGuest
            })
            let sortedProducts = [...filteredProducts].sort((a, b) => {
                return a.sort_time * 100 - b.sort_time * 100;
            });
            if (sortedProducts.length > 0) {
                this.setState({ allFourProducts: [...sortedProducts].splice(0, Number(maxProductsToShow)) })
            }
        }

        this.debouncedFetchProductInfo();

    }

    componentDidUpdate(prevProps) {
        if (prevProps.user !== this.props.user && this.props.toShowProductOf && this.props.toShowProductOf !== process.env.REACT_APP_ADMIN_ID) {
            let currentCompany = this.props.user.filter((t) => {
                return t.user_id === this.props.toShowProductOf
            })
            if (currentCompany.length > 0) {
                this.setState({ currentCompanyData: currentCompany[0] })
                if (currentCompany[0].announcement && currentCompany[0].announcement.active) {
                    if (currentCompany[0].announcement.showAnnouncementWithoutTiming) {
                        this.setState({ showBannerAnnouncement: true, isLoadingProducts: false })
                    } else if (new Date(Date.parse(currentCompany[0].announcement.end_date_iso)).getTime() > new Date().getTime()) {
                        this.setState({ showBannerAnnouncement: true, isLoadingProducts: false })
                    }
                }
            }
        }
    }

    waitForGlobalValues() {
        return new Promise((res) => {
            if (this.props.global_values) {
                res()
            } else {
                this.intervalGlobalValues = setInterval(() => {
                    if (this.props.global_values) {
                        res()
                        clearInterval(this.intervalGlobalValues)
                    }
                }, 100);
            }
        })
    }

    waitForCurrentUser(uid_creator) {
        return new Promise((res) => {
            if (this.props.user) {
                let filtered = this.props.user.filter((t) => {
                    return t.user_id === uid_creator
                })
                if (filtered.length > 0) {
                    res(filtered[0])
                }
            } else {
                this.intervalUsers = setInterval(() => {
                    if (this.props.user) {
                        clearInterval(this.intervalUsers)
                        let filtered = this.props.user.filter((t) => {
                            return t.user_id === uid_creator
                        })
                        if (filtered.length > 0) {
                            res(filtered[0])
                        }
                    }
                }, 100);
            }
        })
    }

    fetchProductInfo() {

        let maxProductsToShow = 4

        if (this.props.isSpecialProductsPage || this.props.onGuestUserPage) {
            maxProductsToShow = 5
            if (this.props.global_values) {
                if (this.props.global_values.products_count_new) {
                    maxProductsToShow = Number(this.props.global_values.products_count_new)
                }
            }
        } else {
            let currentCompany = this.props.user.filter((t) => {
                return t.user_id === this.props.toShowProductOf
            })
            if (currentCompany.length > 0) {
                if (currentCompany[0].productsInfo) {
                    if (currentCompany[0].productsInfo.maxProductsToShow) {
                        maxProductsToShow = Number(currentCompany[0].productsInfo.maxProductsToShow)
                    }
                }
            }
        }

        console.log(maxProductsToShow, '/see out here')

        if (this.props.isSpecialProductsPage || this.props.onGuestUserPage) {
            firebase.database().ref("bhodi_products").orderByChild("created_by").equalTo(this.props.toShowProductOf).on("value", (data) => {
                let a = data.val()
                let allProducts = []
                if (a) {
                    let objected = Object.entries(a)
                    objected.map((g) => {
                        allProducts.push(g[1])
                    })

                    let bhodiProducts = this.props.bhodi_products
                    if (bhodiProducts && bhodiProducts.length > 0) {
                        let arrToSet = []
                        bhodiProducts.map((e) => {
                            let alreadyAvailable = allProducts.filter((t) => {
                                return t.pushKey === e.pushKey
                            })
                            if (alreadyAvailable.length > 0) {
                                arrToSet.push(alreadyAvailable[0])
                            } else {
                                arrToSet.push(e)
                            }
                        })

                        this.props.updateBhodiProducts(arrToSet)
                    }

                    let filteredProducts = []
                    if (this.state.allFourProducts.length > 0) {
                        filteredProducts = allProducts.filter((product) => {
                            if (product.sizesActive) {
                                let stocksAvailableSizes = []
                                product.variations_data.map((e) => {
                                    if (Number(e.qty) === 0) {
                                        return false;
                                    }
                                    let filteredStockLength = []
                                    let filteredStockLengthVerzilvered = []
                                    // if (product.inCartOf) {
                                    //     filteredStockLength = Object.entries(product.inCartOf).filter((t) => {
                                    //         return t[1].selectedSize === e.size
                                    //     })
                                    // }
                                    if (product.verzilvered_by) {
                                        filteredStockLengthVerzilvered = Object.entries(product.verzilvered_by).filter((t) => {
                                            return t[1].selectedSize === e.size
                                        })
                                    }
                                    if ((filteredStockLength.length + filteredStockLengthVerzilvered.length) < Number(e.qty)) {
                                        stocksAvailableSizes.push(e)
                                    }
                                })
                                if (stocksAvailableSizes.length === 0) {
                                    return false;
                                }
                            } else {
                                let filteredStockLength = []
                                let filteredStockLengthVerzilvered = []
                                // if (product.inCartOf) {
                                //     filteredStockLength = Object.entries(product.inCartOf).filter((t) => {
                                //         return !t[1].selectedSize
                                //     })
                                // }
                                if (product.verzilvered_by) {
                                    filteredStockLengthVerzilvered = Object.entries(product.verzilvered_by).filter((t) => {
                                        return !t[1].selectedSize
                                    })
                                }
                                if (Number(product.qty) <= (filteredStockLength.length + filteredStockLengthVerzilvered.length) || Number(product.qty) === 0) {
                                    return false;
                                }
                            }

                            if (this.props.onGuestUserPage) {
                                return !product.availableOnTime && product.images && product.active && product.availableForCompanyGuest && product.created_by === this.props.toShowProductOf
                            } else if (this.props.isSpecialProductsPage && this.props.productForPage) {
                                return !product.availableOnTime && product.images && product.active && product.isSpecialProduct && product.productForPage === this.props.productForPage
                            } else if (this.props.isSpecialProductsPage) {
                                return !product.availableOnTime && product.images && product.active && product.isSpecialProduct && !product.productForPage
                            }
                        })
                        // show all products then if it's less than max products to show
                        if (filteredProducts.length < maxProductsToShow) {
                            let onlyIds = this.state.allFourProducts.map((data) => data.pushKey)
                            filteredProducts = allProducts.filter((product) => {
                                if (product.sizesActive) {
                                    let stocksAvailableSizes = []
                                    product.variations_data.map((e) => {
                                        if (Number(e.qty) === 0) {
                                            return false;
                                        }
                                        let filteredStockLength = []
                                        let filteredStockLengthVerzilvered = []
                                        if (product.verzilvered_by) {
                                            filteredStockLengthVerzilvered = Object.entries(product.verzilvered_by).filter((t) => {
                                                return t[1].selectedSize === e.size
                                            })
                                        }
                                        if ((filteredStockLength.length + filteredStockLengthVerzilvered.length) < Number(e.qty)) {
                                            stocksAvailableSizes.push(e)
                                        }
                                    })
                                    if (stocksAvailableSizes.length === 0) {
                                        if (!onlyIds.includes(product.pushKey)) {
                                            return false;
                                        }
                                    }
                                } else {
                                    let filteredStockLength = []
                                    let filteredStockLengthVerzilvered = []
                                    if (product.verzilvered_by) {
                                        filteredStockLengthVerzilvered = Object.entries(product.verzilvered_by).filter((t) => {
                                            return !t[1].selectedSize
                                        })
                                    }
                                    if (Number(product.qty) <= (filteredStockLength.length + filteredStockLengthVerzilvered.length) || Number(product.qty) === 0) {
                                        if (!onlyIds.includes(product.pushKey)) {
                                            return false;
                                        }
                                    }
                                }

                                if (this.props.onGuestUserPage) {
                                    return !product.availableOnTime && product.images && product.active && product.availableForCompanyGuest && product.created_by === this.props.toShowProductOf
                                } else if (this.props.isSpecialProductsPage && this.props.productForPage) {
                                    return !product.availableOnTime && product.images && product.active && product.isSpecialProduct && product.productForPage === this.props.productForPage
                                } else if (this.props.isSpecialProductsPage) {
                                    return !product.availableOnTime && product.images && product.active && product.isSpecialProduct && !product.productForPage
                                }
                            })
                        }
                    } else {
                        filteredProducts = allProducts.filter((product) => {
                            if (product.sizesActive) {
                                let stocksAvailableSizes = []
                                product.variations_data.map((e) => {
                                    if (Number(e.qty) === 0) {
                                        return false;
                                    }
                                    let filteredStockLength = []
                                    let filteredStockLengthVerzilvered = []
                                    // if (product.inCartOf) {
                                    //     filteredStockLength = Object.entries(product.inCartOf).filter((t) => {
                                    //         return t[1].selectedSize === e.size
                                    //     })
                                    // }
                                    if (product.verzilvered_by) {
                                        filteredStockLengthVerzilvered = Object.entries(product.verzilvered_by).filter((t) => {
                                            return t[1].selectedSize === e.size
                                        })
                                    }
                                    if ((filteredStockLength.length + filteredStockLengthVerzilvered.length) < Number(e.qty)) {
                                        stocksAvailableSizes.push(e)
                                    }
                                })
                                if (stocksAvailableSizes.length === 0) {
                                    return false;
                                }
                            } else {
                                let filteredStockLength = []
                                let filteredStockLengthVerzilvered = []
                                // if (product.inCartOf) {
                                //     filteredStockLength = Object.entries(product.inCartOf).filter((t) => {
                                //         return !t[1].selectedSize
                                //     })
                                // }
                                if (product.verzilvered_by) {
                                    filteredStockLengthVerzilvered = Object.entries(product.verzilvered_by).filter((t) => {
                                        return !t[1].selectedSize
                                    })
                                }
                                if (Number(product.qty) <= (filteredStockLength.length + filteredStockLengthVerzilvered.length) || Number(product.qty) === 0) {
                                    return false;
                                }
                            }

                            if (this.props.onGuestUserPage) {
                                return !product.availableOnTime && product.images && product.active && product.availableForCompanyGuest && product.created_by === this.props.toShowProductOf
                            } else if (this.props.isSpecialProductsPage && this.props.productForPage) {
                                return !product.availableOnTime && product.images && product.active && product.isSpecialProduct && product.productForPage === this.props.productForPage
                            } else if (this.props.isSpecialProductsPage) {
                                return !product.availableOnTime && product.images && product.active && product.isSpecialProduct && !product.productForPage
                            }
                        })
                    }


                    let sortedProducts = [...filteredProducts].sort((a, b) => {
                        return a.sort_time * 100 - b.sort_time * 100;
                    });
                    if (sortedProducts.length > 0) {

                        let productsSetting = [...sortedProducts].splice(0, Number(maxProductsToShow))


                        let filteredAvailableOld = this.state.allFourProducts.filter((e) => {
                            return e.buttonTimingToShow
                        })
                        let filteredAvailable = productsSetting.filter((e) => {
                            return e.buttonTimingToShow
                        })

                        if (filteredAvailable.length > 0 && filteredAvailableOld.length < filteredAvailable.length) {
                            this.setState({ mustAnimate: true })

                            setTimeout(() => {
                                this.setState({ mustAnimate: false })
                            }, 2000);
                        }

                        this.setState({ allFourProducts: productsSetting, isLoadingProducts: false })
                    } else {
                        this.setState({ allFourProducts: [], isLoadingProducts: false })
                    }
                } else {
                    this.setState({ allFourProducts: [], isLoadingProducts: false })
                }
            })
        } else {
            firebase.database().ref("bhodi_products").orderByChild("created_by").equalTo(this.props.toShowProductOf).on("value", (data) => {
                let a = data.val()
                let allProducts = []
                if (a) {
                    let objected = Object.entries(a)
                    objected.map((g) => {
                        allProducts.push(g[1])
                    })

                    let bhodiProducts = this.props.bhodi_products
                    if (bhodiProducts && bhodiProducts.length > 0) {
                        let arrToSet = []
                        bhodiProducts.map((e) => {
                            let alreadyAvailable = allProducts.filter((t) => {
                                return t.pushKey === e.pushKey
                            })
                            if (alreadyAvailable.length > 0) {
                                arrToSet.push(alreadyAvailable[0])
                            } else {
                                arrToSet.push(e)
                            }
                        })

                        this.props.updateBhodiProducts(arrToSet)
                    }


                    let filteredProducts = allProducts.filter((product) => {
                        if (product.sizesActive) {
                            let stocksAvailableSizes = []
                            product.variations_data.map((e) => {
                                if (Number(e.qty) === 0) {
                                    return false;
                                }
                                let filteredStockLength = []
                                let filteredStockLengthVerzilvered = []
                                if (product.inCartOf) {
                                    filteredStockLength = Object.entries(product.inCartOf).filter((t) => {
                                        return t[1].selectedSize === e.size
                                    })
                                }
                                if (product.verzilvered_by) {
                                    filteredStockLengthVerzilvered = Object.entries(product.verzilvered_by).filter((t) => {
                                        return t[1].selectedSize === e.size
                                    })
                                }
                                if ((filteredStockLength.length + filteredStockLengthVerzilvered.length) < Number(e.qty)) {
                                    stocksAvailableSizes.push(e)
                                }
                            })
                            if (stocksAvailableSizes.length === 0) {
                                return false;
                            }
                        } else {
                            let filteredStockLength = []
                            let filteredStockLengthVerzilvered = []
                            if (product.inCartOf) {
                                filteredStockLength = Object.entries(product.inCartOf).filter((t) => {
                                    return !t[1].selectedSize
                                })
                            }
                            if (product.verzilvered_by) {
                                filteredStockLengthVerzilvered = Object.entries(product.verzilvered_by).filter((t) => {
                                    return !t[1].selectedSize
                                })
                            }
                            if (Number(product.qty) <= (filteredStockLength.length + filteredStockLengthVerzilvered.length) || Number(product.qty) === 0) {
                                return false;
                            }
                        }

                        return !product.availableOnTime && product.images && product.active && !product.is_bookmarked && !product.onlyAvailableViaURL && !product.isSpecialProduct && !product.availableForCompanyGuest
                    })
                    console.log(filteredProducts, '/see out here')
                    let sortedProducts = [...filteredProducts].sort((a, b) => {
                        return a.sort_time * 100 - b.sort_time * 100;
                    });
                    if (sortedProducts.length > 0) {

                        let productsSetting = [...sortedProducts].splice(0, Number(maxProductsToShow))


                        let filteredAvailableOld = this.state.allFourProducts.filter((e) => {
                            return e.buttonTimingToShow
                        })
                        let filteredAvailable = productsSetting.filter((e) => {
                            return e.buttonTimingToShow
                        })

                        if (filteredAvailable.length > 0 && filteredAvailableOld.length < filteredAvailable.length) {
                            this.setState({ mustAnimate: true })

                            setTimeout(() => {
                                this.setState({ mustAnimate: false })
                            }, 1000);
                        }

                        this.setState({ allFourProducts: productsSetting, isLoadingProducts: false })
                    } else {
                        this.setState({ allFourProducts: [], isLoadingProducts: false })
                    }
                } else {
                    this.setState({ allFourProducts: [], isLoadingProducts: false })
                }
            })
        }
    }

    componentWillUnmount() {
        if (document.querySelector(".navbarfullwidthMobile")) {
            document.querySelector(".navbarfullwidthMobile").style.overflowX = "auto"
        }

        if (document.querySelector(".nav-fullwidth")) {
            document.querySelector(".nav-fullwidth").classList.add("navbarfixedpositionupdatedmember")
        }

        this.debouncedFetchProductInfo.cancel();
    }

    // Random name creator for pushing data in firebase storage
    makeid(length) {
        var result = "";
        var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }


    checkForButtonProducts(e) {
        if (!e.sizesActive) {
            if (e.inCartOf || e.verzilvered_by) {
                let objectedCart = []
                let objectedVerzilvered = []

                if (e.inCartOf) {
                    objectedCart = Object.entries(e.inCartOf)
                }

                if (e.verzilvered_by) {
                    objectedVerzilvered = Object.entries(e.verzilvered_by)
                }

                if ((objectedCart.length + objectedVerzilvered.length) >= Number(e.qty)) {
                    return (<button>Uitverkocht</button>)
                }
            }
            if (Number(e.qty) === 0) {
                return <button>Uitverkocht</button>
            }
        } else {
            let stocksAvailableSizes = []
            e.variations_data.map((g) => {
                let filteredStockLength = []
                let filteredStockLengthVerzilvered = []
                if (e.inCartOf) {
                    filteredStockLength = Object.entries(e.inCartOf).filter((t) => {
                        return t[1].selectedSize === g.size
                    })
                }
                if (e.verzilvered_by) {
                    filteredStockLengthVerzilvered = Object.entries(e.verzilvered_by).filter((t) => {
                        return t[1].selectedSize === g.size
                    })
                }
                if ((filteredStockLength.length + filteredStockLengthVerzilvered.length) < Number(g.qty)) {
                    stocksAvailableSizes.push(g)
                }
            })
            if (stocksAvailableSizes.length === 0) {
                return <button>Uitverkocht</button>
            }
        }

        return (<button onClick={() => {
            window.location.href = `/product-detailed?key=${e.pushKey}`
            // history.push(`/product-detailed?key=${e.pushKey}`)
        }}>Bekijk</button>)
    }

    checkForExploreButton(e) {
        if (!e.sizesActive) {
            if (e.inCartOf || e.verzilvered_by) {
                let objectedCart = []
                let objectedVerzilvered = []

                if (e.inCartOf) {
                    objectedCart = Object.entries(e.inCartOf)
                }
                if (e.verzilvered_by) {
                    objectedVerzilvered = Object.entries(e.verzilvered_by)
                }

                if ((objectedCart.length + objectedVerzilvered.length) >= Number(e.qty)) {
                    return (<button>{objectedCart.length > 0 ? "Gereserveerd" : "Uitverkocht"}</button>)
                }
            }
            if (Number(e.qty) === 0) {
                return (<button>{e.inCartOf ? "Gereserveerd" : "Uitverkocht"}</button>)
            }
        } else {
            let stocksAvailableSizes = []
            e.variations_data.map((g) => {
                let filteredStockLength = []
                let filteredStockLengthVerzilvered = []

                if (e.inCartOf) {
                    filteredStockLength = Object.entries(e.inCartOf).filter((t) => {
                        return t[1].selectedSize === g.size
                    })
                }
                if (e.verzilvered_by) {
                    filteredStockLengthVerzilvered = Object.entries(e.verzilvered_by).filter((t) => {
                        return t[1].selectedSize === g.size
                    })
                }
                if ((filteredStockLength.length + filteredStockLengthVerzilvered.length) < Number(g.qty)) {
                    stocksAvailableSizes.push(g)
                }
            })
            if (stocksAvailableSizes.length === 0) {
                return (<button>{e.inCartOf ? "Gereserveerd" : "Uitverkocht"}</button>)
            }
        }

        return <button onClick={() => {
            window.location.href = `/product-detailed?key=${e.pushKey}`
            // history.push(`/product-detailed?key=${product.pushKey}`)
        }}>Bekijk</button>
    }

    checkForCreatorName(uid) {
        let filtered = this.props.user.filter((t) => {
            return t.user_id === uid
        })
        if (filtered.length > 0) {
            return `${filtered[0].company_name}`
        }
    }

    render() {
        return (
            this.state.currentCompanyData.shop_offline ? (<div></div>) : (this.state.showBannerAnnouncement && this.state.currentCompanyData.announcement) ? <div className='new-announcement-section'>
                <div className='top-events-section'>
                    <p>Products</p>
                    {/* <p>Company Name</p> */}
                    <p>{this.checkForCreatorName(this.props.toShowProductOf)}</p>
                </div>
                <div className='tsmhn-container'>
                    <AnnouncementBannerNew avoidMarginTop={true} announcement={this.state.currentCompanyData.announcement} currentCompanyData={this.state.currentCompanyData} showNotifyButton={this.state.currentCompanyData.user_id !== this.state.currentUserId} announcementDone={() => {
                        this.setState({ showBannerAnnouncement: false })
                    }} />
                </div>
            </div> : this.state.isLoadingProducts ? (<div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", paddingTop: 25 }}>
                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                <label style={{ fontFamily: "Poppins", letterSpacing: 1, fontSize: 20 }}>Loading Products</label>
            </div>
            ) : (this.state.allFourProducts.length > 0) ? (<div className='second-section-mhn' style={{ marginBottom: 0, paddingBottom: 0 }}>
                <div className='top-ssmhn'>
                    {/* {this.props.isSpecialProductsPage ?   ( */}
                    <div className='content-tssmhn' style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                        <div>
                            <p>Live drop</p>
                            <p>Free items for charity</p>
                        </div>
                        {window.innerWidth > 1008 && this.state.allFourProducts.length > productsPerPage && (<div className='pagination-products'>
                            {Array.from({ length: Math.ceil(this.state.allFourProducts.length / productsPerPage) }).map((_, index) => (
                                <div className={`pagpro-inner ${this.state.currentPage === index + 1 ? "active" : ""}`} key={index} onClick={() => {
                                    let indexOfLastProduct = (index + 1) * productsPerPage
                                    let indexOfFirstProduct = indexOfLastProduct - productsPerPage

                                    this.setState({
                                        currentPage: index + 1,
                                        indexOfLastProduct,
                                        indexOfFirstProduct,
                                    })
                                }}>
                                    <label>{index + 1}</label>
                                </div>
                            ))}
                        </div>)}
                    </div>
                    {/* <ReactPaginate
                        breakLabel="..."
                        nextLabel="Next >"
                        onPageChange={() => {
                            this.setState({ productsStart: this.state.productsStart + 2, productsEnd: this.state.productsEnd + 2 })
                        }}
                        pageRangeDisplayed={2}
                        pageCount={Math.ceil(this.state.allFourProducts.length / 2)}
                        previousLabel="< Previous"
                        renderOnZeroPageCount={null}
                    /> */}
                    {/* ): (<div className='content-tssmhn'>
                        <p>Live Items</p>
                        <p>Daily paper collection</p>
                    </div>)} */}
                </div>
                <div className='products-holder-container phc-transition' style={{ marginTop: 20 }}>
                    {this.state.allFourProducts.length > 0 && [...this.state.allFourProducts.sort((a, b) => {
                        return b.sort_time * 100 - a.sort_time * 100;
                    })].slice(this.state.indexOfFirstProduct, this.state.indexOfLastProduct).map((product, index) => {
                        let indexOfImage = 0

                        let allImagesNew = []
                        if (product.images && product.images.length > 0) {
                            let duplicated = Array.from(product.images)

                            let allImagesUrl = []
                            duplicated.map((g) => {
                                if (g) {
                                    let filePath = g.split("/")[7].split("?")[0]
                                    allImagesUrl.push(filePath)
                                }
                            })

                            allImagesUrl.sort().map((t) => {
                                let filtered = duplicated.filter((g) => {
                                    return g.includes(t)
                                })

                                if (filtered.length > 0) {
                                    allImagesNew.push(filtered[0])
                                }
                            })
                            if (typeof product.featuredImage !== "undefined" && product.featuredImage !== null) {
                                if (product.featuredImage < product.images.length) {
                                    let filteredIndex = allImagesNew.findIndex((g) => {
                                        return g === product.images[product.featuredImage]
                                    })
                                    if (filteredIndex !== -1) {
                                        indexOfImage = filteredIndex
                                    }
                                }
                            }
                        }
                        return (
                            <div className={`inner-phc ${this.state.mustAnimate && "animateProductContainerNew"}`}>
                                <div className='img-inner-phc'>
                                    <img src={allImagesNew[indexOfImage]} onClick={() => {
                                        window.location.href = `/product-detailed?key=${product.pushKey}`
                                        // history.push(`/product-detailed?key=${product.pushKey}`)
                                    }} />
                                </div>
                                <div className='content-inner-phc'>
                                    <div className='left-ciphc'>
                                        <p>{product.title}</p>
                                        <p><s>€{product.price}</s> for <font style={{ color: "#c17f6b" }}>Free</font></p>
                                    </div>
                                    <div className='right-ciphc'>
                                        {product.showingNote && (
                                            <div className='banote-inner'>
                                                <p>Note</p>
                                                <p>Wanneer de timer is voltooid, bestel dan zo snel mogelijk als je kunt! Want op is op.</p>
                                            </div>
                                        )}
                                        {product.timerToStartProduct && <ButtonTimerProductsNew timerToDo={new Date(Date.parse(product.timerToStartProduct)).getTime()} btnClicked={() => {
                                            this.state.allFourProducts[index].showingNote = true
                                            this.setState({ showingProductNote: true })
                                            setTimeout(() => {
                                                this.state.allFourProducts[index].showingNote = false
                                                this.setState({ showingProductNote: false })
                                            }, 2000);
                                        }} />}
                                        {product.buttonTimingToShow && <ButtonTimerProductsNew timerToDo={new Date(Date.parse(product.buttonTimingToShow)).getTime()} closeScreen={() => {
                                            firebase.database().ref(`bhodi_products/${product.pushKey}/buttonTimingToShow`).remove()
                                        }} />}
                                        {(this.props.isSpecialProductsPage || this.props.onGuestUserPage) ? this.checkForExploreButton(product) : (<button onClick={() => {
                                            window.location.href = `/product-detailed?key=${product.pushKey}`
                                            // history.push(`/product-detailed?key=${product.pushKey}`)
                                        }}>Bekijk</button>)}
                                    </div>
                                </div>
                            </div>)
                    })}
                </div>
                {window.innerWidth < 1008 && this.state.allFourProducts.length > productsPerPage && (<div className='pagination-products'>
                    {Array.from({ length: Math.ceil(this.state.allFourProducts.length / productsPerPage) }).map((_, index) => (
                        <div className={`pagpro-inner ${this.state.currentPage === index + 1 ? "active" : ""}`} key={index} onClick={() => {
                            let indexOfLastProduct = (index + 1) * productsPerPage
                            let indexOfFirstProduct = indexOfLastProduct - productsPerPage

                            this.setState({
                                currentPage: index + 1,
                                indexOfLastProduct,
                                indexOfFirstProduct,
                            })
                        }}>
                            <label>{index + 1}</label>
                        </div>
                    ))}
                </div>)}
            </div>) : (<div>
            </div>)
        )
    }
}

const mapStateToProps = (state) => {
    return {
        bhodi_products: state.productsReducers.bhodi_products,
        global_campaigns: state.campaignsReducer.global_campaigns,
        global_values: state.globalReducers.global_values,
        globalsectors: state.categoriesReducer.globalsectors,
        stock_images: state.globalReducers.stock_images,
        all_impressies: state.globalReducers.all_impressies,
        user: state.authReducers.user,
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        updateCampaignsCategory: (campaigns_category) => dispatch(updateCampaignsCategory(campaigns_category)),
        updateBhodiProducts: (bhodi_products) => dispatch(updateBhodiProducts(bhodi_products)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewProductWrapper);