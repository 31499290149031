import React, { Component } from 'react'
import FooterGreen from '../../Components/FooterGreen'
import "./index.css"
import VoucherEventsNewGlobal from '../../Components/VoucherEventsNewGlobal'

export class VolgendNewMobile extends Component {

    constructor() {
        super()
        this.state = {
            latestProducts: [],
            otherProducts: [],
        }
    }

    componentDidMount() {
        // let allProducts = [...this.props.productsWhole]
        // let allProductsSorted = allProducts.sort((a, b) => {
        //     return b.timestamp * 100 - a.timestamp * 100;
        // })

        // this.setState({ otherProducts: allProductsSorted })

        let scrollOnGlobal = localStorage.getItem("scrollOnGlobal")
        if (scrollOnGlobal) {
            window.scrollTo(0, Number(scrollOnGlobal))
            localStorage.removeItem("scrollOnGlobal")
        }

        document.getElementById("root").style.position = "static";
        document.getElementById("root").style.overflow = "auto";
    }

    render() {
        return (
            <div style={{ marginBottom: 70 }} id="divtoscrollfromtop">
                <VoucherEventsNewGlobal
                    allCampaigns={this.props.productsWhole}
                    onEditOpen={() => {
                        document.querySelector("html").style.overflowY = "hidden"
                        this.setState({
                            openAddPopup: true,
                            doEditVoucher: true,
                        });
                    }}
                />
                <FooterGreen notOnMainPage={true} />
            </div>
        )
    }
}

export default VolgendNewMobile