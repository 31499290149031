import React, { Component } from 'react'
import firebase from "../../Config/Firebase"
import "./index.css"
import axios from "axios";
import { connect } from 'react-redux';
import {
    updateAdminOrNot,
    updateGlobalStuff,
    updateAlbumToAdd,
    updateCategoriesVouchers,
} from "../../Redux/actions/authActions";
import Footer from '../../Components/Footer';
import FooterGreen from '../../Components/FooterGreen';
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import swal from 'sweetalert';
import logoSubAdmin from "../../assets/subadminlogo.png";

const bcrypt = require("bcryptjs");
const saltRounds = 10;

export class OnboardCompany extends Component {
    constructor() {
        super()
        this.state = {
            aanmeldenInfo: false,
            secondStep: false,
            aanmeldenloader: false,
            dropFileCampaignImage: [],
            companyName: "",
            phoneNum: "",
            street: "",
            email: "",
            houseNum: "",
            password: "",
            postCode: "",
            city: "",
            bankName: "",
            allUsers: [],
            currentUserSpecific: "",
            showMainLoader: false,
            fromDirectUrl: false,
            companyNameDefault: "",
            thirdStep: false,
            companyUrlRedirect: "",
            defaultEmailParam: "",
            defaultKeyParam: "",

            allListings: [],
            indexOfCurrentCompany: -1,
            currentIndustry: "restaurant",

            allImagesCurrentSectorStock: [],
            allSectors: [],
            mainSectorStock: "",

            allImagesStock: [],

            arrEvents: [],

            localEventPercentage: true,
            localEventTitle: "",
            localEventDesc: "",
            localEventAmount: "",


            isEventEnabled: true,

            selectedSector: "Fashion & Shoes1633955735296",
            selectedMain: ["Accessoires1633956984219"],

            clientIDDefault: "",
        }
    }

    componentDidMount() {
        let urlMain = new URLSearchParams(window.location.search)

        if (urlMain.get("email") && urlMain.get("key")) {
            this.setState({ showMainLoader: true })

            let industry = "restaurant"
            if (urlMain.get("industry")) {
                industry = urlMain.get("industry")
            }

            firebase.database().ref(industry === "restaurant" ? "accounts_by_admin_manual" : `accounts_by_admin_manual_${industry}`).once("value", (data) => {
                let a = data.val()
                if (a) {
                    let filtered = a.filter((t) => {
                        return t.email === urlMain.get("email")
                    })
                    let indexOfCurrent = a.findIndex((t) => {
                        return t.email === urlMain.get("email")
                    })
                    if (filtered.length > 0) {
                        if (filtered[0].account_created) {
                            swal({
                                title: "Sorry",
                                text: "Account with this email already created",
                                icon: "error"
                            }).then(() => {
                                window.location.href = "/"
                            })
                        }
                        this.setState({ fromDirectUrl: true, showMainLoader: false, allListings: a, companyNameDefault: filtered[0].company_name, clientIDDefault: filtered[0].client_id, defaultEmailParam: urlMain.get("email"), email: urlMain.get("email"), defaultKeyParam: urlMain.get("key"), indexOfCurrentCompany: indexOfCurrent, currentIndustry: industry })
                    }
                }
            })
        }
    }


    checkForValuePresent(param) {
        if (param !== "") {
            return true;
        } else {
            return false;
        }
    }

    updateStateOnCondition(param, paramToSet) {
        if (param !== "") {
            this.setState({
                [paramToSet]: false,
            })
        } else {
            this.setState({
                [paramToSet]: true,
            })
        }
    }


    createCompany() {
        const { street, houseNum, postCode, city, email, password, agreed } = this.state
        let regexEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (agreed) {
            this.setState({ agreedPopup: false });
        } else {
            this.setState({ agreedPopup: true });
        }
        if (email === "" || !regexEmail.test(email)) {
            this.setState({ emailInvalid: true });
        } else {
            this.setState({ emailInvalid: false });
        }
        if (password === "" || password.length < 6) {
            if (password.length < 6) {
                this.setState({ password: "" });
            }
            this.setState({ passInvalid: true });
        } else {
            this.setState({ passInvalid: false });
        }

        if (email !== "" && regexEmail.test(email) && password !== "" && password.length > 5 && agreed) {
            let promiseAccountDataEmailSend = new Promise((resolve, reject) => {
                let promiseLogin = new Promise((res) => {
                    let urlMain = new URLSearchParams(window.location.search)
                    this.setState({ aanmeldenloader: true })

                    if (urlMain.get("email") && urlMain.get("key")) {
                        firebase.auth().signInWithEmailAndPassword(this.state.defaultEmailParam, this.state.defaultKeyParam)
                            .then((succ) => {
                                res(succ.user.uid)
                            })
                    }
                })

                promiseLogin.then((uid) => {
                    let myPlaintextPassword = password;
                    bcrypt.genSalt(saltRounds, (err, salt) => {
                        bcrypt.hash(myPlaintextPassword, salt, (err, hash) => {

                            let promisePwdUpdate = new Promise((res) => {
                                const user = firebase.auth().currentUser;
                                user.updatePassword(myPlaintextPassword)
                                    .then(() => {
                                        firebase
                                            .auth()
                                            .signInWithEmailAndPassword(this.state.defaultEmailParam, myPlaintextPassword)
                                            .then(() => {
                                                res()
                                            }).catch((err) => {
                                                console.log(err)
                                            })
                                    }).catch((err) => {
                                        console.log(err)
                                    })
                            })

                            promisePwdUpdate.then(() => {
                                const user = firebase.auth().currentUser;
                                let promiseEmailUpdate = new Promise((res) => {
                                    if (email !== this.state.defaultEmailParam) {
                                        user.updateEmail(email)
                                            .then(() => {
                                                res()
                                            }).catch((err) => {
                                                console.log(err)
                                            })
                                    } else {
                                        res()
                                    }
                                })

                                promiseEmailUpdate.then(() => {
                                    const updates = {
                                        [`users/${uid}/email`]: email,
                                        [`users/${uid}/password`]: hash,
                                        [`users/${uid}/client_id_creation`]: this.state.clientIDDefault,
                                        [`users/${uid}/newly_registered`]: false,
                                        [`users/${uid}/hideWizard`]: true,
                                        [`users/${uid}/profileActive`]: true
                                    };

                                    let userCurrent = this.props.user.filter((t) => {
                                        return t.user_id === uid
                                    })

                                    firebase.database().ref().update(updates).then(() => {
                                        let redirect_url = ""
                                        if (userCurrent.length > 0) {
                                            redirect_url = `${window.location.origin}/company/${userCurrent[0].user_name_id}`
                                        }

                                        localStorage.setItem("userid", uid);
                                        localStorage.removeItem("cookie_item");
                                        localStorage.setItem("isAdmin", false);
                                        this.props.updateAdminOrNot(false);

                                        let obj = {
                                            id: uid,
                                            urlRedirect: redirect_url,
                                        }

                                        if (this.state.allListings.length > 0 && this.state.indexOfCurrentCompany !== -1) {
                                            this.state.allListings[this.state.indexOfCurrentCompany].account_created = true
                                            firebase.database().ref(this.state.currentIndustry === "restaurant" ? "accounts_by_admin_manual" : `accounts_by_admin_manual_${this.state.currentIndustry}`).set(this.state.allListings)
                                        }

                                        resolve(obj)
                                    })
                                })
                            })
                        })
                    })
                })
            })
            let promiseGetLatLong = new Promise((resolve, reject) => {
                let address = ""
                if (this.state.fromDirectUrl) {
                    address = `${this.state.allListings[this.state.indexOfCurrentCompany].street} ${this.state.allListings[this.state.indexOfCurrentCompany].houseNumber}, ${this.state.allListings[this.state.indexOfCurrentCompany].zipCode} ${this.state.allListings[this.state.indexOfCurrentCompany].city}, Netherlands`;
                } else {
                    address = `${street} ${houseNum}, ${postCode} ${city}, Netherlands`;
                }

                var config = {
                    method: "get",
                    url: `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${process.env.REACT_APP_GEOCODE_KEY}`,
                    headers: {},
                };

                axios(config)
                    .then((response) => {
                        let coords = response.data.results[0].geometry.location;

                        let obj = {
                            lat: coords.lat,
                            lng: coords.lng,
                        }
                        resolve(obj)
                    })
                    .catch(function () {
                        let obj = {
                            lat: "",
                            lng: "",
                        }
                        resolve(obj)
                    });
            })
            Promise.all([promiseAccountDataEmailSend, promiseGetLatLong]).then((data) => {
                let userId = data[0].id
                let latitude = data[1].lat
                let longitude = data[1].lng

                firebase.database().ref("users/" + userId + "/latitude").set(latitude)
                firebase.database().ref("users/" + userId + "/longitude").set(longitude)

                if (this.state.fromDirectUrl) {
                    this.setState({ secondStep: true, companyUrlRedirect: data[0].urlRedirect, companyUserId: userId })
                } else {
                    setTimeout(() => {
                        window.location.href = data[0].urlRedirect
                    }, 500);
                }
            })
        }
    }


    copyToClipboard() {
        this.setState({ copiedTextDone: true });
        var textField = document.createElement("textarea");
        textField.innerText = this.state.companyUrlRedirect;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand("copy");
        textField.remove();
        setTimeout(() => {
            this.setState({ copiedTextDone: false })
        }, 3000);
    }


    getFormattedDate(e) {
        if (e) {
            let splitted = e.split("-");
            splitted = splitted.reverse().join("-");
            return `${splitted}`;
        }
    }

    render() {
        return (
            this.state.showMainLoader ? (
                <div
                    className="global-event-cart globaleventloaderpage"
                    style={{
                        height: "auto",
                        top: 0,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        zIndex: 999999999,
                    }}
                >
                    <div className="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    {window.innerWidth > 1008 && <Footer notOnMainPage={true} />}
                    {window.innerWidth < 1008 && <FooterGreen notOnMainPage={true} />}
                </div>
            ) : <div className="global-event-cart globaleventcartfixedstickyfooter companysubscribepagelink">
                {this.state.loadingAddress && (<div className="loader-fullpage-checkout">
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                </div>)}
                <div className="left-gec">
                    {this.state.secondStep ? (<div className='form-box-cocosum third-step-fbcoco'>
                        <p className='first-smt-fbcoco'>Gelukt</p>
                        <div className='second-headingwrap-fbcoco sc-fourthstep-main' style={{ flexDirection: "column", alignItems: "flex-start" }}>
                            <p>Yess! Jullie profielpagina is aangemaakt.</p>
                            <p className='third-smt-fbcoco'>
                                Indien gewenst kunnen jullie de pagina nog verder personaliseren
                                met een foto of video van jullie winkel of van een bekend merk.
                            </p>
                            <p className='third-smt-fbcoco'>
                                Uiteraard kunnen jullie ook de kinderen en scholen in Tanzania
                                ondersteunen met maaltijden en materialen. Tijdens de uitgiftes
                                maakt ons team speciaal voor jullie een persoonlijke en op naam
                                ingesproken bedankvideo.
                            </p>
                        </div>
                        <div className='bottom-cocosum' style={{ justifyContent: "flex-start" }}>
                            <button style={{ marginLeft: 0 }} onClick={() => {
                                window.location.href = this.state.companyUrlRedirect
                            }}>BEKIJK PAGINA</button>
                            <button style={{ marginLeft: 10 }} onClick={() => {
                                window.location.href = `${this.state.companyUrlRedirect}/charity`
                            }}>WORD MEMBER</button>
                        </div>
                    </div>) : (<div className="container-lgec">
                        <div className="top-step-one-clgec">
                            <label>Aanmelden</label>
                            <label>Account gegevens</label>
                            <label>
                                Leuk dat jullie willen gaan meedoen! <br />
                                Controleer het e-mail adres en kies een wachtwoord.
                            </label>
                            {/* <label>Indien gewenst kun je het menu later nog wijzigen with the dezctiptrion and a bit to show here</label> */}
                        </div>
                        <div className="forms-clgec">
                            <div className={`form-field-clgec ${this.state.emailInvalid ? "redalertffcl" : ""}`}>
                                <input
                                    placeholder='E-mail'
                                    value={this.state.email}
                                    onChange={(e) => {
                                        this.setState({ email: e.target.value })
                                    }}
                                />
                            </div>
                            <div className={`form-field-clgec ${this.state.passInvalid ? "redalertffcl" : ""}`}>
                                <input
                                    placeholder='Kies wachtwoord'
                                    type='password'
                                    value={this.state.password}
                                    onChange={(e) => {
                                        this.setState({ password: e.target.value })
                                    }}
                                />
                            </div>
                            <div
                                className="fx-registration-inner"
                                style={{ alignItems: "center" }}
                            >
                                <div className="selectoptionfull" style={{ marginTop: 15 }}>
                                    <div
                                        className="left-frvn"
                                        style={{
                                            width: "100%",
                                            paddingLeft: 10,
                                            backgroundColor: "transparent",
                                        }}
                                    >
                                        <div className="checking-box">
                                            <input
                                                type="checkbox"
                                                checked={this.state.agreed}
                                                onChange={() =>
                                                    this.setState({ agreed: !this.state.agreed })
                                                }
                                            />
                                            <label
                                                style={{
                                                    color: this.state.agreedPopup ? "red" : "black",
                                                    fontSize: 13,
                                                }}
                                            >
                                                <font
                                                    style={{
                                                        textDecorationLine: "underline",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                        window.open(
                                                            "https://media.bhodi.com/gebruikersvoorwaarden.pdf",
                                                            "_blank" // <- This is what makes it open in a new window.
                                                        );
                                                    }}
                                                >
                                                    Akkoord met voorwaarden
                                                </font>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bottom-buttons-clgec">
                                {/* {window.innerWidth < 1008 && !this.state.aanmeldenloader && (
                                    <button onClick={() => history.goBack()}>
                                        {" "}
                                        <ChevronLeftIcon />{" "}
                                    </button>
                                )} */}
                                {this.state.aanmeldenloader ? (
                                    window.innerWidth < 1008 && (
                                        <></>
                                    )
                                ) : (
                                    <button
                                        onClick={() => {
                                            this.createCompany()
                                        }}
                                    >
                                        Afronden
                                        {window.innerWidth < 1008 && <ChevronRightIcon />}
                                    </button>
                                )}
                                {this.state.aanmeldenloader && (
                                    <div className="lds-ellipsis">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>)}
                </div>
                {window.innerWidth > 1008 && (<div className="right-gec">

                </div>)}
                {window.innerWidth < 1009 && (
                    <div className="showtoopen-gec stg-updated-checkout">
                        <div className="popup-opener-stogec pos-inner-img">
                            <img src={logoSubAdmin} alt='logo' width={50} />
                        </div>
                    </div>
                )}

                {window.innerWidth > 1008 && <Footer notOnMainPage={true} />}
                {window.innerWidth < 1008 && <FooterGreen notOnMainPage={true} />}
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        global_values: state.globalReducers.global_values,
        user: state.authReducers.user,
        admin_boolean: state.globalReducers.admin_boolean,
        subscription_plans: state.globalReducers.subscription_plans,
        campaigns_category: state.categoriesReducer.campaigns_category,
        categories_vouchers: state.categoriesReducer.categories_vouchers,
        stock_images: state.globalReducers.stock_images,
        globalsectors: state.categoriesReducer.globalsectors,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateAdminOrNot: (admin_boolean) => dispatch(updateAdminOrNot(admin_boolean)),
        updateGlobalStuff: (global_values) => dispatch(updateGlobalStuff(global_values)),
        updateAlbumToAdd: (albumToAdd) => dispatch(updateAlbumToAdd(albumToAdd)),
        updateCategoriesVouchers: (categories_vouchers) => dispatch(updateCategoriesVouchers(categories_vouchers)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OnboardCompany);