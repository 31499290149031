import React, { Component } from 'react'
import LandingPage from '../LandingPage'
import Footer from '../../Components/Footer'
import FooterGreen from '../../Components/FooterGreen'
import firebase from '../../Config/Firebase'
import { history } from '../../Config/Routes'
import { updateGlobalStuff } from '../../Redux/actions/authActions'
import { connect } from 'react-redux'

export class TiktokPage extends Component {

    constructor() {
        super()
        this.state = {
            currentUserId: "",
            isTikTokBrowser: true,
        }
        this.charityPageRef = React.createRef()
        this.childRef = React.createRef()
    }

    async componentDidMount() {
        let userid = localStorage.getItem("userid")
        this.setState({ currentUserId: userid })


        const isTikTokBrowser = navigator.userAgent.includes("musical_ly") || navigator.userAgent.includes("tiktok") || navigator.userAgent.includes("TikTok");


        if (isTikTokBrowser) {
            this.setState({ isTikTokBrowser: true })
        } else {
            let pageUrl = ""

            let promise = new Promise((res, rej) => {
                if (this.props.global_values) {
                    let a = this.props.global_values
                    if (a) {
                        pageUrl = a.page_url_to_show
                        res()
                    }
                } else {
                    firebase.database().ref("bhodi_home").on("value", (data) => {
                        let a = data.val()
                        if (a) {
                            pageUrl = a.page_url_to_show
                            this.props.updateGlobalStuff(a)
                            res()
                        }
                    })
                }
            })
            promise.then(() => {
                if (pageUrl) {
                    history.push(`/brand/${pageUrl}`)
                } else {
                    history.push("/special-products")
                }
            })
            // window.location.href = "/special-products"
        }
    }

    render() {
        return (
            <div className='specialproductslandingpage'>
                <LandingPage onSpecialProducts={true} tiktokPage={true} moveToCharitySection={() => {
                    this.charityPageRef.current.scrollIntoView({ behavior: 'smooth' });
                }} moveToAnotherSection={() => {
                    if (this.childRef.current) {
                        this.childRef.current.scrollIntoView({ behavior: 'smooth' });
                    }
                }} />
                {window.innerWidth > 1008 ? (<Footer notOnMainPage={true} />) : (<FooterGreen notOnMainPage={true} />)}
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        global_values: state.globalReducers.global_values,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateGlobalStuff: (global_values) =>
            dispatch(updateGlobalStuff(global_values)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TiktokPage);