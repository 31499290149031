import React, { Component } from "react";
import { updateGlobalStuff } from "../../../Redux/actions/authActions";
import { connect } from "react-redux";
import AdminNavbar from "../../../Components/AdminNavbar";
import * as XLSX from "xlsx";
import Footer from "../../../Components/Footer";

var convert = require("xml-js")

export class AdminClientsList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            treeData: [],
            sortingTab: true,
            adjustingTab: false,
            completeMainTitles: [],
            arrOfInputs: [],
            kortingsInfo: "",
            completeCSVArray: [],
            allListings: [],
            allListingsDuplicated: [],
            selectedSector: "",
            isSubAdmin: false,
            currentStatus: "fresh",
            switchedToSubAdmin: false,

            callBackofCompany: "",
            callBackDate: "",
            callBackDateHours: "",
            callBackDateMinutes: "",
            callBackofCompanyIndex: 0,

            currentIndustryType: "",
            loadingData: true,

            completeCategories: [],
            mainSector: "",
        };
    }

    componentDidMount() {
        const loaderId = document.getElementById("main-page-loader-id");
        loaderId.style.display = "none";

        if (this.props.user) {
            let filtered = this.props.user.filter((t) => {
                return t.client_id_creation && t.profileActive && t.donation_link_client
            })
            if (filtered.length > 0) {
                if (this.props.global_campaigns) {
                    let filteredCampaigns = filtered.filter((user) => {
                        let foundInCampaign = this.props.global_campaigns.find((campaign) => {
                            return campaign[1].created_by === user.user_id && campaign[1].campaignType === "local_event"
                        })
                        if (foundInCampaign) {
                            return true
                        }
                    })
                    if (filteredCampaigns.length > 0) {
                        this.setState({
                            allListings: filteredCampaigns,
                            allListingsDuplicated: filteredCampaigns,
                            loadingData: false,
                        })
                    }
                }
            }
        }

    }

    randomStr(e) {
        let len = e
        let arr = "abcdefghijklmnopqrstuvwxyz1928102489"
        var ans = '';
        for (var i = len; i > 0; i--) {
            ans +=
                arr[Math.floor(Math.random() * arr.length)];
        }
        return ans;
    }


    exportToXLSX(data, fileName) {
        // Create a new workbook and add a worksheet
        const workbook = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(data);

        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(workbook, ws, 'Sheet 1');

        // Convert the workbook to a binary XLSX file
        const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });

        // Create a Blob with the XLSX data
        const blob = new Blob([this.s2ab(wbout)], { type: 'application/octet-stream' });

        // Create a download link and trigger the download
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${fileName}.xlsx`;
        a.click();
        window.URL.revokeObjectURL(url);
    }

    // Helper function to convert string to array buffer
    s2ab(s) {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) {
            view[i] = s.charCodeAt(i) & 0xFF;
        }
        return buf;
    }


    exportCsv() {
        let data = [
            ["Client ID", "Company Name", "Contact Person", "Email", "Straatnaam", "House Number", "Postcode", "Plaats", "Unique URL"]
        ]
        this.state.allListingsDuplicated.map((e) => {
            let unique_url = `${window.location.origin}/campaign/${e.user_id}`

            let arr = [e.client_id_creation, e.company_name, e.email, e.street, e.houseNumber, e.zipCode, e.city, unique_url]
            if (e.contactPerson) {
                arr.splice(2, 0, e.contactPerson)
            } else {
                arr.splice(2, 0, "")
            }
            data.push(arr)
        })

        this.exportToXLSX(data, 'exported_data');
    }

    render() {
        return (
            <div className="sorting-mainadmin">
                <AdminNavbar clientsListPage={true} />
                <div className="container-sma containeraccountcreatoradmin">
                    <div className="info-div-editor">
                        <div className="group-textbox" style={{ textAlign: "center" }}>
                            <label style={{ fontSize: 16, fontWeight: 600 }}>
                                ACCOUNT LISTS
                            </label>
                        </div>
                        {(<div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginBottom: 20 }}>
                            <div className="group-textbox" style={{ maxWidth: 500 }}>

                            </div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                {this.state.allListings.length > 0 && (<button
                                    style={{ backgroundColor: "black", color: "white", padding: "8px 12px", border: "none", outline: "none", fontSize: 13, fontFamily: "Poppins", letterSpacing: 0.6, borderRadius: 4 }}
                                    onClick={() => {
                                        this.exportCsv()
                                    }}
                                >Export</button>)}
                            </div>
                        </div>)}
                        {(<div className="table-account-creator">
                            <div className="firstrow-tac frt-admin frt-adminimport">
                                <div>
                                    <p>Straatnaam</p>
                                </div>
                                <div>
                                    <p>Email</p>
                                </div>
                                <div>
                                    <p>Bedrijfsnaam</p>
                                </div>
                                <div>
                                    <p>Client ID</p>
                                </div>
                                <div>
                                    <p>Postcode</p>
                                </div>
                                <div>
                                    <p>Plaats</p>
                                </div>
                                <div>
                                    <p>Number</p>
                                </div>
                                <div>
                                    <p>Website</p>
                                </div>
                            </div>
                            {this.state.loadingData ? <div className="loader-admin-import">
                                <div className="lds-ring">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </div> : this.state.allListingsDuplicated.length > 0 && this.state.allListingsDuplicated.map((e) => {
                                return (<div className="normalrows-tac nrt-admin nrt-adminimport" key={Math.random()}>
                                    <div>
                                        <p>{e.street}</p>
                                    </div>
                                    <div>
                                        <p>{e.email}</p>
                                    </div>
                                    <div>
                                        <p>{e.company_name}</p>
                                    </div>
                                    <div>
                                        <p>{e.client_id_creation}</p>
                                    </div>
                                    <div>
                                        <p>{e.zipCode}</p>
                                    </div>
                                    <div>
                                        <p>{e.city}</p>
                                    </div>
                                    <div>
                                        <p>{e.phoneNumber}</p>
                                    </div>
                                    <div>
                                        <p>{e.company_website}</p>
                                    </div>
                                </div>)
                            })}
                        </div>)}
                    </div>
                </div>


                <Footer notOnMainPage={true} />
            </div >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        global_values: state.globalReducers.global_values,
        global_campaigns: state.campaignsReducer.global_campaigns,
        user: state.authReducers.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateGlobalStuff: (global_values) => dispatch(updateGlobalStuff(global_values)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminClientsList);
