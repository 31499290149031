import React, { Component } from 'react'
import TimerBanner from './TimerBanner'
import firebase from "../../Config/Firebase"
import { connect } from 'react-redux'
import TimerBannerSeparate from './TimerBannerSeparate'
import RecurringTimer from './RecurringTimer'

export class ProductBannerTimer extends Component {


    constructor() {
        super()
        this.state = {
            bannerImage: "",
            intervalTitle: "",
            intervalDesc: "",
            intervalBgLargeAdmin: "",
        }
    }

    componentDidMount() {
        if (this.props.user && !this.props.announcement && !this.props.isOffline && !this.props.isMaintenance && !this.props.recurring) {
            let filtered = this.props.user.filter((t) => {
                return t.user_id === this.props.currentProduct.created_by
            })
            if (filtered.length > 0) {
                if (filtered[0].interval_big_banner_image) {
                    this.setState({ bannerImage: filtered[0].interval_big_banner_image })
                }
            }
        }

        if (this.props.global_values) {
            if (this.props.global_values.intervalTitle) {
                this.setState({ intervalTitle: this.props.global_values.intervalTitle })
            }
            if (this.props.global_values.intervalDesc) {
                this.setState({ intervalDesc: this.props.global_values.intervalDesc })
            }
            if (this.props.global_values.intervalBgLarge) {
                this.setState({ intervalBgLargeAdmin: this.props.global_values.intervalBgLarge })
            }
        }

    }

    componentDidUpdate(prevProps) {
        if (this.props.user !== prevProps.user && !this.props.announcement && !this.props.isOffline && !this.props.isMaintenance && !this.props.recurring) {
            let filtered = this.props.user.filter((t) => {
                return t.user_id === this.props.currentProduct.created_by
            })
            if (filtered.length > 0) {
                if (filtered[0].interval_big_banner_image) {
                    this.setState({ bannerImage: filtered[0].interval_big_banner_image })
                }
            }
        }

        if (this.props.global_values !== prevProps.global_values) {
            if (this.props.global_values.intervalTitle) {
                this.setState({ intervalTitle: this.props.global_values.intervalTitle })
            }
            if (this.props.global_values.intervalDesc) {
                this.setState({ intervalDesc: this.props.global_values.intervalDesc })
            }
            if (this.props.global_values.intervalBgLarge) {
                this.setState({ intervalBgLargeAdmin: this.props.global_values.intervalBgLarge })
            }
        }
    }

    render() {
        return (
            this.props.isOffline ? <div className='banner-product-main' style={{ backgroundColor: "#193535" }}>
                <div className='overlay-fsblpb'></div>
                <div className='ace-fsblpb'>
                    <h1 style={{ marginBottom: 10, textTransform: "uppercase" }}>Ping Ping</h1>
                    <p>{"Shop is offline, will be back soon"}</p>
                    <button>STAY TUNED</button>
                </div>
            </div> : this.props.isMaintenance ? <div className='banner-product-main' style={{ backgroundColor: "#193535" }}>
                <div className='overlay-fsblpb'></div>
                <div className='ace-fsblpb'>
                    <h1 style={{ marginBottom: 10, textTransform: "uppercase" }}>Ping Ping</h1>
                    <p>{"Some maintenance is going on, will be done soon"}</p>
                    <button>STAY TUNED</button>
                </div>
            </div> : this.props.recurring ? <div className='banner-product-main' style={{ backgroundImage: this.props.recurring.bannerType === "image" ? `url("${this.props.recurring.bannerMedia}")` : "none" }}>
                {this.props.recurring.bannerType !== "image" && <video
                    src={this.props.recurring.bannerMedia}
                    autoPlay
                    muted
                    loop
                    data-keepplaying
                    playsInline
                ></video>}
                <div className='overlay-fsblpb'></div>
                <div className='ace-fsblpb'>
                    <h1 style={{ marginBottom: 10, textTransform: "uppercase" }}>{this.props.recurring.title}</h1>
                    <RecurringTimer weeklyAvailableDate={this.props.weeklyAvailableDate} recurringData={this.props.recurring} closeScreen={() => {
                        if (this.props.recurringDone) {
                            this.props.recurringDone()
                        }
                    }} />
                    <p>{this.props.recurring.desc}</p>
                    <button>STAY TUNED</button>
                </div>
            </div> : this.props.announcement ? <div className='banner-product-main' style={{ backgroundImage: this.props.announcement.bannerType === "image" ? `url("${this.props.announcement.bannerMedia}")` : "none" }}>
                {this.props.announcement.bannerType !== "image" && <video
                    src={this.props.announcement.bannerMedia}
                    autoPlay
                    muted
                    loop
                    data-keepplaying
                    playsInline
                ></video>}
                <div className='overlay-fsblpb'></div>
                <div className='ace-fsblpb'>
                    <h1 style={{ marginBottom: 10, textTransform: "uppercase" }}>{this.props.announcement.title}</h1>
                    <TimerBannerSeparate availabilityTime={new Date(Date.parse(this.props.announcement.end_date_iso)).getTime()} closeScreen={() => {
                        if (this.props.announcementDone) {
                            this.props.announcementDone()
                        }
                    }} />
                    <p>{this.props.announcement.desc}</p>
                    <button>STAY TUNED</button>
                </div>
            </div> : <div className='banner-product-main' style={{ backgroundImage: this.state.intervalBgLargeAdmin ? `url("${this.state.intervalBgLargeAdmin}")` : "none", backgroundColor: !this.state.intervalBgLargeAdmin && "#193535" }}>
                <div className='overlay-fsblpb'></div>
                <div className='ace-fsblpb'>
                    <h1 style={{ marginBottom: 10, textTransform: "uppercase" }}>{this.state.intervalTitle ? this.state.intervalTitle : this.props.currentProduct.title}</h1>
                    <TimerBanner creatorId={this.props.currentProduct.created_by} timerStartFrom={this.props.currentProduct.availableOnTime} closeScreen={(productTimer, startTimerFrom) => {
                        firebase.database().ref(`bhodi_products/${this.props.currentProduct.pushKey}/availableOnTime`).remove()

                        if (productTimer > new Date().getTime()) {
                            if (this.props.updateCurrentProduct) {
                                this.props.updateCurrentProduct(startTimerFrom)
                            }
                            firebase.database().ref(`bhodi_products/${this.props.currentProduct.pushKey}/productTimerMax`).set(startTimerFrom)
                        }
                    }} />
                    <p>{this.state.intervalDesc ? this.state.intervalDesc : this.props.currentProduct.desc.substr(0, 40)}</p>
                    <button>STAY TUNED</button>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.authReducers.user,
        global_values: state.globalReducers.global_values,
    };
};

export default connect(
    mapStateToProps,
)(ProductBannerTimer);