import React, { Component } from 'react'
import AdminProducts from './AdminProducts'
import { connect } from 'react-redux'
import AdminNavbar from '../../Components/AdminNavbar'
import { updateBhodiProducts } from '../../Redux/actions/authActions'
import firebase from "../../Config/Firebase"

export class AdminProductsWrapper extends Component {
    constructor() {
        super()
        this.state = {
            currentMemberData: "",
            showLoader: false,
        }
    }

    componentDidMount() {
        let userid = localStorage.getItem("userid")

        let currentMemberData = this.props.user.filter((t) => {
            return t.user_id === userid
        })
        if (currentMemberData.length > 0) {
            this.setState({ currentMemberData: currentMemberData[0] })
        }


        firebase
            .database()
            .ref("bhodi_products")
            .on("value", (data) => {
                let a = data.val();
                if (a) {
                    let arrayMain = []
                    let objected = Object.entries(a);
                    objected.map((h) => {
                        arrayMain.push(h[1])
                    });

                    if (arrayMain.length > 0) {
                        this.props.updateBhodiProducts(arrayMain);
                    } else {
                        this.props.updateBhodiProducts([]);
                    }
                } else {
                    this.props.updateBhodiProducts([]);
                }
            });
    }

    render() {
        return (
            <div>
                <AdminNavbar productsPage={true} />
                {this.state.showLoader ? (<div className="loaderspaceloading">
                    <div className="lds-heart">
                        <div></div>
                    </div>
                </div>) : this.state.currentMemberData && (<AdminProducts currentMemberData={this.state.currentMemberData} changeCompany={(userid) => {
                    this.setState({ showLoader: true })

                    setTimeout(() => {
                        let currentMemberData = this.props.user.filter((t) => {
                            return t.user_id === userid
                        })
                        if (currentMemberData.length > 0) {
                            this.setState({ currentMemberData: currentMemberData[0], showLoader: false })
                        }
                    }, 1000);
                }} />)}
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.authReducers.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateBhodiProducts: (bhodi_products) => dispatch(updateBhodiProducts(bhodi_products)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(AdminProductsWrapper);