import React, { Component } from 'react'
import styles from "./index.module.css"
import { connect } from 'react-redux';
import {
    updateGlobalStuff,
    updateGuestUserCart,
    updateStockImages,
    updateGuestUserCartTime,
    updateGuestUserCartExtendOpportunity,
} from "../../Redux/actions/authActions";
import ReactFullpage from "@fullpage/react-fullpage";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { history } from '../../Config/Routes';
import ProductTimer from './ProductTimer';
import firebase from "../../Config/Firebase"
import ProductTimerInline from './ProductTimerInline';
import playiconlandingpage from "../../assets/playiconlandingpage.png"

export class ProductGrid extends Component {

    constructor() {
        super()
        this.state = {
            allProducts: [],
            categoryData: {},
            allSectors: [],
            sectors: [],
            completeCategories: [],
            onCurrentPage: 1,
            editScreenOpen: false,
            allProductsDuplicated: [],
            currentProductToEdit: "",
            allCategories: [],
            intervalTitle: "NEW ITEM DROP",
            intervalBgSmallAdmin: "",
            fullpageApi: "",
        }
    }

    componentDidMount() {
        if (this.props.productsToShow) {
            this.setState({ allProducts: this.props.productsToShow })
        } else {
            this.setState({ allProducts: [], allProductsDuplicated: [] })
        }

        if (this.props.global_values) {
            if (this.props.global_values.intervalTitle) {
                this.setState({ intervalTitle: this.props.global_values.intervalTitle })
            }
            if (this.props.global_values.intervalBgSmall) {
                this.setState({ intervalBgSmallAdmin: this.props.global_values.intervalBgSmall })
            }
        }

        let localCurrent = localStorage.getItem("onCurrentSectionProducts");
        localStorage.removeItem("onCurrentSectionProducts");

        if (window.innerWidth > 1008) {
            if (localCurrent) {
                setTimeout(() => {
                    if (this.state.fullpageApi) {
                        this.state.fullpageApi.moveTo(Number(localCurrent), 0)
                    }
                }, 1000);
            }
        }

        if (this.props.product_categories) {
            let objected = Object.entries(this.props.product_categories);
            let allArrays = []
            objected.map((u) => {
                u[1].map((t) => {
                    allArrays.push(t)
                })
            });
            if (allArrays.length > 0) {
                this.setState({ allCategories: allArrays });
            }
        }
    }

    onLeave(origin, destination, direction) {
        console.log("on leave")
    }

    afterLoad(origin, destination, direction) {
        this.setState({ onCurrentPage: destination.index });
    }

    getCreatorImage(user_id) {
        if (user_id) {
            let filtered = this.props.user.filter((g) => {
                return g.user_id === user_id
            })
            if (filtered.length > 0) {
                if (filtered[0].profile_image) {
                    return filtered[0].profile_image;
                } else {
                    return "https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9";
                }
            }
        }
    }

    getCreatorName(user_id) {
        if (user_id) {
            let filtered = this.props.user.filter((g) => {
                return g.user_id === user_id
            })
            if (filtered.length > 0) {
                if (filtered[0].type === "user") {
                    return filtered[0].first_name
                } else {
                    return filtered[0].company_name
                }
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.productsToShow !== this.props.productsToShow) {
            if (this.props.productsToShow) {
                if (this.props.productsToShow.length > 0) {
                    this.setState({ allProducts: this.props.productsToShow, allProductsDuplicated: this.props.productsToShow })
                } else {
                    this.setState({ allProducts: [], allProductsDuplicated: [] })
                }
            } else {
                this.setState({ allProducts: [], allProductsDuplicated: [] })
            }
        }
        if (this.props.global_values !== prevProps.global_values) {
            if (this.props.global_values.intervalTitle) {
                this.setState({ intervalTitle: this.props.global_values.intervalTitle })
            }
            if (this.props.global_values.intervalBgSmall) {
                this.setState({ intervalBgSmallAdmin: this.props.global_values.intervalBgSmall })
            }
        }
    }

    checkForStockOverlay(product) {
        let currentProduct = Object.assign({}, product)
        let mustShowOverlay = false;
        if (product.inCartOf) {
            if (currentProduct.sizesActive) {
                let stocksAvailableSizes = []
                currentProduct.variations_data.map((e) => {
                    let filteredStockLength = Object.entries(product.inCartOf).filter((t) => {
                        return t[1].selectedSize === e.size
                    })
                    if (filteredStockLength.length < Number(e.qty)) {
                        stocksAvailableSizes.push(e)
                    }
                })
                if (stocksAvailableSizes.length === 0) {
                    mustShowOverlay = true
                } else {
                    mustShowOverlay = false
                }
            } else {
                let objected = Object.entries(product.inCartOf)
                if (objected.length >= Number(currentProduct.qty)) {
                    mustShowOverlay = true
                }
            }
        }
        if (mustShowOverlay) {
            return (<div className='overlay-stock'>
                <div className='stock-text-top'>
                    <p>Stock reached</p>
                </div>
            </div>)
        }
    }

    checkForActiveOverlay(product) {
        if (!product.active) {
            return (<div className='overlay-stock'>
                <div className='stock-text-top'>
                    <p>Niet Actief</p>
                </div>
            </div>)
        }
    }

    getProductCategory(product) {
        if (product.main_category && product.main_category.length > 0 && this.state.allCategories.length > 0) {
            let filtered = this.state.allCategories.filter((g) => {
                return product.main_category.includes(g.category_id)
            })
            if (filtered.length > 0) {
                return filtered[0].title
            }
        }
    }

    captureSmallImage(e) {
        if (this.props.user) {
            let filtered = this.props.user.filter((t) => {
                return t.user_id === e.created_by
            })
            if (filtered.length > 0) {
                if (filtered[0].interval_small_banner_image) {
                    return `url("${filtered[0].interval_small_banner_image}")`
                }
            }
        }
        if (this.state.intervalBgSmallAdmin) {
            return `url("${this.state.intervalBgSmallAdmin}")`
        }
    }

    getUserGlobalDesc(e) {
        if (this.props.user) {
            let filtered = this.props.user.filter((t) => {
                return t.user_id === e.created_by
            })
            if (filtered.length > 0) {
                if (filtered[0].productsInfo) {
                    if (filtered[0].productsInfo.globalDesc) {
                        return <p className={styles.descProductMain}>{filtered[0].productsInfo.globalDesc.substr(0, 40)}...</p>
                    }
                }
            }
        }
    }

    render() {
        console.log(this.state.allProducts, '/check this products')
        return (
            <div className='product-grid-main'>
                {this.state.allProducts.length > 0 &&
                    <ReactFullpage
                        onLeave={this.onLeave.bind(this)}
                        afterLoad={this.afterLoad.bind(this)}
                        responsiveWidth="1008"
                        normalScrollElements=".rightmenuaddproduct, .new-campaigns-design, .mnicart-flyout-inner, .rightmodalsearchupdated, .friendlistbeherenmodal, .loaderareachatliveevent, .rightmenuproductfriendlists, .charity-confirmation-complete, .mnicart-flyout-inner, .loaderareaduplicate, .bg-overlaymini, .mnicart-flyout-inner, .rightmenucompletewidthaddproduct"
                        render={({ state, fullpageApi }) => {
                            let lengthToShow = Math.ceil(
                                this.state.allProducts.length / 3
                            );
                            let arrSec = [];
                            for (let i = 0; i < lengthToShow; i++) {
                                arrSec.push(i);
                            }
                            if (!this.state.fullpageApi) {
                                this.setState({ fullpageApi });
                            }
                            console.log(arrSec, '/check this products')
                            return (
                                <div>
                                    {arrSec.length > 0 &&
                                        arrSec.map((y, is) => {
                                            const cloneSheepsES6 = [
                                                ...this.state.allProducts,
                                            ];
                                            let toProductsShow = cloneSheepsES6.splice(y * 3, 3);
                                            return (
                                                <div
                                                    className={`section ${this.state.activeSection === is + 1
                                                        ? "active"
                                                        : "nonactive"
                                                        }`}
                                                >
                                                    <div className={styles.flexBoxContainer} style={{ backgroundColor: window.innerWidth > 1008 && this.props.onGlobalPage && "#F8F8F8" }}>
                                                        <div className={styles.flexBoxInnerContainer}>
                                                            {toProductsShow.map((e, index) => {
                                                                // return <div className={`${styles.productContainerWithTimer} ${this.props.mustAnimate && "animateProductContainer"}`}>
                                                                //     {/* <div className={styles.opacityOverlay}>
                                                                //     </div> */}
                                                                //     <div className={styles.titleContainerPc}>
                                                                //         <img src={playiconlandingpage} />
                                                                //         <h4 style={{ textTransform: "uppercase" }}>{this.state.intervalTitle}</h4>
                                                                //         <p>Missed a product?<br />no worries a new item is coming</p>
                                                                //         <div className='big-container-timer'>
                                                                //             <div className='inner-bigconttim'>
                                                                //                 <p>40</p>
                                                                //                 <p>sec</p>
                                                                //             </div>
                                                                //         </div>
                                                                //         {/* <ProductTimer creatorId={e.created_by} timerStartFrom={e.availableOnTime} closeScreen={(productTimer, startTimerFrom) => {
                                                                //                 e.availableOnTime = ""
                                                                //                 firebase.database().ref(`bhodi_products/${e.pushKey}/availableOnTime`).remove()
                                                                //                 if (productTimer > new Date().getTime()) {
                                                                //                     e.productTimerMax = startTimerFrom
                                                                //                     firebase.database().ref(`bhodi_products/${e.pushKey}/productTimerMax`).set(startTimerFrom)
                                                                //                 }
                                                                //             }} /> */}
                                                                //         <button>STAY TUNED</button>
                                                                //     </div>
                                                                // </div>
                                                                let indexOfImage = 0
                                                                // if (e.images && e.images.length > 0) {
                                                                //     let findIndex = e.images.findIndex((g) => {
                                                                //         return g.includes("_1")
                                                                //     })
                                                                //     if (findIndex !== -1) {
                                                                //         indexOfImage = findIndex
                                                                //     }

                                                                //     if (e.featuredImage && e.featuredImage < e.images.length) {
                                                                //         indexOfImage = e.featuredImage
                                                                //     }
                                                                // }
                                                                let allImagesNew = []
                                                                if (e.images && e.images.length > 0) {
                                                                    let duplicated = Array.from(e.images)

                                                                    let allImagesUrl = []
                                                                    duplicated.map((g) => {
                                                                        if (g) {
                                                                            let filePath = g.split("/")[7].split("?")[0]
                                                                            allImagesUrl.push(filePath)
                                                                        }
                                                                    })

                                                                    allImagesUrl.sort().map((t) => {
                                                                        let filtered = duplicated.filter((g) => {
                                                                            return g.includes(t)
                                                                        })

                                                                        if (filtered.length > 0) {
                                                                            allImagesNew.push(filtered[0])
                                                                        }
                                                                    })
                                                                    if (typeof e.featuredImage !== "undefined" && e.featuredImage !== null) {
                                                                        if (e.featuredImage < e.images.length) {
                                                                            let filteredIndex = allImagesNew.findIndex((g) => {
                                                                                return g === e.images[e.featuredImage]
                                                                            })
                                                                            if (filteredIndex !== -1) {
                                                                                indexOfImage = filteredIndex
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                return e.availableOnTime ? (<div className={`${styles.productContainerWithTimer} ${this.props.mustAnimate && "animateProductContainer"}`}>
                                                                    {/* return e.availableOnTime ? (<div className={`${styles.productContainerWithTimer} ${this.props.mustAnimate && "animateProductContainer"}`} style={{ backgroundImage: this.captureSmallImage(e) }}> */}
                                                                    <div className={styles.opacityOverlay}>
                                                                    </div>
                                                                    <div className={styles.titleContainerPc}>
                                                                        <img src={playiconlandingpage} />
                                                                        <h4 style={{ textTransform: "uppercase" }}>{this.state.intervalTitle}</h4>
                                                                        <p>Er komt een nieuw product aan!</p>
                                                                        <ProductTimer creatorId={e.created_by} timerStartFrom={e.availableOnTime} closeScreen={(productTimer, startTimerFrom) => {
                                                                            e.availableOnTime = ""
                                                                            // firebase.database().ref(`bhodi_products/${e.pushKey}/availableOnTime`).remove()
                                                                            if (productTimer > new Date().getTime()) {
                                                                                e.productTimerMax = startTimerFrom
                                                                            }
                                                                            if (!e.descAdded) {
                                                                                e.desc = ""
                                                                            }
                                                                            firebase.database().ref(`bhodi_products/${e.pushKey}`).set(e)
                                                                        }} />
                                                                        <button>STAY TUNED</button>
                                                                    </div>
                                                                </div>) : (<div className={`${styles.productContainer} ${this.props.mustAnimate && "animateProductContainer"}`}>
                                                                    {/* {window.innerWidth > 1008 && (<div className={styles.userDetailedPc}>
                                                                        <img src={this.getCreatorImage(e.created_by)} />
                                                                        <p>{this.getCreatorName(e.created_by)}</p>
                                                                    </div>)} */}
                                                                    {window.innerWidth > 1008 && <div className='overlay-redirect' onClick={() => {
                                                                        localStorage.setItem("onCurrentSectionProducts", is + 1);

                                                                        // window.location.href = `/product-detailed?key=${e.pushKey}`
                                                                        history.push(`/product-detailed?key=${e.pushKey}`)
                                                                    }}>
                                                                    </div>}
                                                                    {(e.inCartOf) && this.checkForStockOverlay(e)}
                                                                    {(!e.inCartOf) && this.checkForActiveOverlay(e)}
                                                                    <div className={styles.imageContainerPc}>
                                                                        <img src={allImagesNew[indexOfImage]} onClick={() => {
                                                                            if (window.innerWidth < 1008) {
                                                                                history.push(`/product-detailed?key=${e.pushKey}`)
                                                                                // window.location.href = `/product-detailed?key=${e.pushKey}`
                                                                            }
                                                                        }} />
                                                                    </div>
                                                                    {/* <div className={styles.sizesContainer}>
                                                                        {e.variations_data.map((g) => {
                                                                            return (<p>{g.size}</p>)
                                                                        })}
                                                                    </div> */}
                                                                    {(e.variations_data && e.variations_data.length > 0) ? (<div className={styles.sizesContainer}>
                                                                        {[...e.variations_data].splice(0, 3).map((g, index) => {
                                                                            let isUitverkocht = false
                                                                            if (e.inCartOf || e.verzilvered_by) {
                                                                                let objectedCart = []
                                                                                let objectedVerzilvered = []
                                                                                if (e.inCartOf) {
                                                                                    objectedCart = Object.entries(e.inCartOf)
                                                                                }

                                                                                if (e.verzilvered_by) {
                                                                                    objectedVerzilvered = Object.entries(e.verzilvered_by)
                                                                                }

                                                                                let checkForSizeAvailable = objectedCart.filter((t) => {
                                                                                    return t[1].selectedSize === g.size
                                                                                })
                                                                                let checkForSizeAvailableVerzilvered = objectedVerzilvered.filter((t) => {
                                                                                    return t[1].selectedSize === g.size
                                                                                })
                                                                                let filtered = e.variations_data.filter((t) => {
                                                                                    return t.size === g.size
                                                                                })
                                                                                if (filtered.length > 0) {
                                                                                    if ((checkForSizeAvailable.length + checkForSizeAvailableVerzilvered.length) >= Number(filtered[0].qty)) {
                                                                                        isUitverkocht = true
                                                                                    }
                                                                                }
                                                                            } else {
                                                                                if (Number(g.qty) === 0) {
                                                                                    isUitverkocht = true
                                                                                }
                                                                            }

                                                                            if (e.variations_data.length - 1 > index) {
                                                                                return (<p style={{ color: isUitverkocht && "#f6f6f6" }}>{g.size}&nbsp;&nbsp;</p>)
                                                                            } else {
                                                                                return (<p style={{ color: isUitverkocht && "#f6f6f6" }}>{g.size}</p>)
                                                                            }
                                                                        })}
                                                                        {e.variations_data.length > 3 && (<p>...</p>)}
                                                                    </div>) : (<div className={styles.sizesContainer} style={{ height: 20 }}>
                                                                    </div>)}
                                                                    <div className={styles.titleContainerPc}>
                                                                        <h4 style={{ textTransform: "uppercase" }}>{e.title.substr(0, 22)}</h4>
                                                                        {/* <p className={styles.descProductMain}>{this.getProductCategory(e)}</p> */}
                                                                        {e.desc && window.innerWidth < 1008 && (<p className={styles.descProductMain}>{e.desc.substr(0, 40)}...</p>)}
                                                                        {e.desc && window.innerWidth > 1008 && (<p className={styles.descProductMain}>{e.desc.substr(0, 40)}...</p>)}
                                                                        {!e.desc && this.getUserGlobalDesc(e)}
                                                                        {e.productTimerMax && (<p className={styles.descProductMain}><ProductTimerInline creatorId={e.created_by} availabilityTime={e.productTimerMax} closeScreen={(productTimer) => {
                                                                            e.productTimerMax = ""
                                                                            firebase.database().ref(`bhodi_products/${e.pushKey}/productTimerMax`).remove()
                                                                        }} /></p>)}
                                                                        {!e.productTimerMax && (<p className={styles.descProductMain}>&nbsp;</p>)}
                                                                        <p className={styles.priceProductMain}><s>€ {e.price}</s> FREE ITEM</p>
                                                                        <button onClick={() => {
                                                                            if (window.innerWidth < 1008) {
                                                                                history.push(`/product-detailed?key=${e.pushKey}`)
                                                                                // window.location.href = `/product-detailed?key=${e.pushKey}`
                                                                            }
                                                                        }}>BEKIJK</button>
                                                                    </div>
                                                                </div>)
                                                            })}
                                                            <div className="completesectionforshop completesectionforshopnewupdatedgood" style={{ height: "auto", paddingRight: "0.6%" }}>
                                                                {this.state.allProducts.length >
                                                                    3 && (
                                                                        <div className="bottom-csfs-shop" style={{ position: "static", paddingRight: 0 }}>
                                                                            <div
                                                                                className="unds-buttons"
                                                                                style={{ alignItems: "center" }}
                                                                            >
                                                                                <font
                                                                                    style={{
                                                                                        fontSize: 13,
                                                                                        letterSpacing: 1,
                                                                                        marginRight: 8,
                                                                                        fontWeight: 600,
                                                                                        cursor: "pointer",
                                                                                    }}
                                                                                >
                                                                                    {this.state.onCurrentPage + 1}/
                                                                                    {arrSec.length}
                                                                                </font>
                                                                                {arrSec.length >
                                                                                    this.state.onCurrentPage + 1 && (
                                                                                        <div
                                                                                            className="inner-undb"
                                                                                            onClick={() => {
                                                                                                fullpageApi.moveSectionDown();
                                                                                            }}
                                                                                        >
                                                                                            <KeyboardArrowDownIcon />
                                                                                        </div>
                                                                                    )}
                                                                                {this.state.onCurrentPage > 0 && (
                                                                                    <div
                                                                                        className="inner-undb"
                                                                                        onClick={() => {
                                                                                            fullpageApi.moveSectionUp();
                                                                                        }}
                                                                                    >
                                                                                        <KeyboardArrowUpIcon />
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            );
                        }}
                    />}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducers.user,
        album: state.postsReducers.album,
        global_campaigns: state.campaignsReducer.global_campaigns,
        images: state.postsReducers.images,
        global_values: state.globalReducers.global_values,
        guest_user_cart: state.cartReducer.guest_user_cart,
        subscription_plans: state.globalReducers.subscription_plans,
        globalsectors: state.categoriesReducer.globalsectors,
        products_new: state.campaignsReducer.products_new,
        admin_boolean: state.globalReducers.admin_boolean,
        stock_images: state.globalReducers.stock_images,
        bhodi_products: state.productsReducers.bhodi_products,
        product_categories: state.productsReducers.product_categories,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateGlobalStuff: (global_values) =>
            dispatch(updateGlobalStuff(global_values)),
        updateGuestUserCart: (guest_user_cart) =>
            dispatch(updateGuestUserCart(guest_user_cart)),
        updateGuestUserCartTime: (guest_user_cart_time) =>
            dispatch(updateGuestUserCartTime(guest_user_cart_time)),
        updateGuestUserCartExtendOpportunity: (
            guest_user_cart_extend_opportunity
        ) =>
            dispatch(
                updateGuestUserCartExtendOpportunity(guest_user_cart_extend_opportunity)
            ),
        updateStockImages: (stock_images) =>
            dispatch(updateStockImages(stock_images)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductGrid);