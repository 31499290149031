import React, { Component } from "react";
import "./index.css";


export class ShopInnerMember extends Component {
  render() {
    return (<div>
    </div>)
  }
}


export default ShopInnerMember;
