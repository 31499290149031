import React, { Component } from "react";
import "react-sortable-tree/style.css";
import { updateGlobalStuff } from "../Redux/actions/authActions";
import { connect } from "react-redux";
import firebase from "../Config/Firebase";
import AdminNavbar from "./AdminNavbar";

export class TextChanges extends Component {
  constructor(props) {
    super(props);

    this.state = {
      treeData: [],
      sectorModal: false,
      sectorModalCategory: false,
      sectorName: "",
      sectors: [],
      currentSector: "",
      currentSectorId: "",
      categoryName: "",
      currentSector: "",
      categoryId: "",
      all_categories: [],
      sortingTab: true,
      adjustingTab: false,
      completeMainTitles: [],
      arrOfInputs: [],
      companyPageText: "",
    };
  }

  componentDidMount() {
    const loaderId = document.getElementById("main-page-loader-id");
    loaderId.style.display = "none";
    if (this.props.global_values) {
      let a = this.props.global_values;
      if (a) {
        if (a.companyPageText) {
          this.setState({ companyPageText: a.companyPageText });
        }
      }
    }

    firebase
      .database()
      .ref("bhodi_home")
      .on("value", (data) => {
        let a = data.val();
        if (a) {
          this.props.updateGlobalStuff(a);
          if (a.companyPageText) {
            this.setState({ companyPageText: a.companyPageText });
          }
        }
      });
  }

  saveCampaignInfo() {
    const { companyPageText } = this.state;
    firebase.database().ref("bhodi_home/companyPageText").set(companyPageText);
  }

  render() {
    return (
      <div className="sorting-mainadmin">
        <AdminNavbar textChanges={true} />
        <div className="container-sma">
          <div className="info-div-editor">
            <div className="group-textbox" style={{ textAlign: "center" }}>
              <label style={{ fontSize: 16, fontWeight: 600 }}>
                TEXT EDITS
              </label>
            </div>
            <div className="group-textbox">
              <label>COMPANY PAGE TEXT</label>
              <textarea
                value={this.state.companyPageText}
                onChange={(e) => {
                  this.setState({ companyPageText: e.target.value });
                }}
              ></textarea>
            </div>
            <div className="group-textbox">
              <button onClick={this.saveCampaignInfo.bind(this)}>SAVE</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    global_values: state.globalReducers.global_values,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateGlobalStuff: (global_values) =>
      dispatch(updateGlobalStuff(global_values)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TextChanges);
