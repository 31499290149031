import React, { Component } from "react";
import "./index.css";
import firebase from "../../Config/Firebase";
import { connect } from "react-redux";
import VolgendNewMobile from "../VolgendNewMobile";
import VouchersGrid from "../../Components/VouchersGrid";
import { checkForSubscribedExclusives } from "../../Functions/checkForSubscribedExclusives";
import { mergeAdminEvent } from "../../Functions/globalFunctions";

let currentOrderIdRef = firebase.database().ref("current_order_id")
let bhodiCampaignsRef = firebase.database().ref("bhodi_campaigns")

export class CreateProductMemberCompany extends Component {
  constructor() {
    super();
    this.state = {
      beschikbaar: true,
      popuprightToShow: false,
      titleProduct: "",
      normalPriceProduct: "",
      charityPriceProduct: "",
      descProduct: "",
      currentImageIndex: 0,
      arrImages: [],
      addCategory: false,
      gebruikers: false,
      addTimer: false,
      obj: {
        "category 1": {
          sub_category: "sub category 1",
        },
        "category 2": {
          sub_category: "sub category 2",
        },
      },
      allProducts: [],
      dropFile: [],
      filesName: [],
      productsIndex: 0,
      reachedProductsIndex: 0,
      hour: "",
      minutes: "",
      productActivated: true,
      firstTime: true,
      currentProductsShowing: [],
      showAllProducts: false,
      arrCurrentProducts: [],
      arrUsersArr: [],
      cartData: [],
      selectCategoryPopup: false,
      dayBigger: false,
      allMonths: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
      hours: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23,
      ],
      isGuest: false,
      categoryName: "",
      sub_categories: [],
      allVouchers: [],
      currentUser: "",
      orderidToUpload: 0,
      verzilverPopup: false,
      verzilverPopupGreen: false,
      isDesktop: true,
      noProducts: false,
      arrUsersArrDuplicate: [],
      onShowItems: false,
      currentProductDetailed: "",
      allProductsEvents: [],
      allProductsCampaigns: [],
      activeSection: 0,
      allProductsCompleted: [],
      arrayOfUsersLikeToShow: [],
      currentUserHere: "",
      guestUserCart: [],
      currentUserCharityMember: false,
      currentCampaignHere: [],
      onCurrentProductComments: "",
    };
    // window.addEventListener("beforeunload", function() { debugger; }, false)
  }

  componentDidMount() {
    const loaderId = document.getElementById("main-page-loader-id");
    loaderId.style.display = "none";
    document.getElementById("root").style.overflow = "hidden";
    let uid = localStorage.getItem("userid");
    if (!uid) {
      this.setState({ isGuest: true });
    } else {
      this.setState({ isGuest: false });
    }
    this.setState({ currentUserId: uid });

    if (this.props.guest_user_cart) {
      this.setState({ guestUserCart: this.props.guest_user_cart });
    }

    if (this.props.global_values) {
      let a = this.props.global_values;
      if (a) {
        if (a.globalOverlay) {
          this.setState({ bannerOpacity: a.globalOverlay });
        }
      }
    }

    // let currentUserData = localStorage.getItem("userdata")
    // if (currentUserData) {
    //     currentUserData = JSON.parse(currentUserData)
    //     this.setState({ currentUserHere: currentUserData })
    // }

    if (this.props.user) {
      if (this.props.user.length > 0) {
        let filtered = this.props.user.filter((y) => {
          return y.user_id === uid;
        });
        if (filtered.length > 0) {
          let a = filtered[0];
          if (a) {
            if (a.subscription) {
              if (a.subscription.status === "active") {
                this.setState({ currentUserCharityMember: true });
              } else {
                if (checkForSubscribedExclusives(a)) {
                  this.setState({ currentUserCharityMember: true });
                }
              }
            }
            if (document.querySelector(".navbartodowhitedefault")) {
              document.querySelector(".navbartodowhitedefault").style.overflowX = `hidden`;
            }

            this.setState({ currentUserHere: a, notSubscribed: true });
          }
        }
      }
    }

    // firebase.database().ref("all_carts/" + uid).on("value", (data) => {
    //     let a = data.val()
    //     if (a) {
    //         let objected = Object.entries(a)
    //         this.setState({ cartData: objected })
    //     } else {
    //         this.setState({ cartData: [] })
    //     }
    // })

    if (window.innerWidth < 1009) {
      this.setState({ isDesktop: false });
    } else {
      this.setState({ isDesktop: true });
    }
    window.addEventListener("resize", () => {
      if (window.innerWidth < 1009) {
        this.setState({ isDesktop: false });
      } else {
        this.setState({ isDesktop: true });
      }
    });

    currentOrderIdRef.on("value", (data) => {
      let a = data.val()
      if (a) {
        this.setState({ orderidToUpload: Number(a) });
      }
    })
    firebase
      .database()
      .ref("users/" + uid)
      .on("value", (data) => {
        let a = data.val();
        if (a) {
          let currentUser = a;
          if (a.subscription) {
            if (a.subscription.status === "active") {
              this.setState({ currentUserCharityMember: true });
            } else {
              if (checkForSubscribedExclusives(a)) {
                this.setState({ currentUserCharityMember: true });
              }
            }
          }
          this.setState({ currentUserHere: currentUser });
        }
      });

    // this.interval = setInterval(() => this.setState({ timeCurrent: Date.now() }), 1000);

    let promiseTwo = new Promise((resolve, reject) => {
      if (this.props.user) {
        if (this.props.user.length > 0) {
          let filtered = this.props.user.filter((y) => {
            return y.user_id === uid;
          });
          if (filtered.length > 0) {
            let a = filtered[0];
            if (a) {
              let allUidsFollowingAndFriends = []
              if (a.following) {
                Object.entries(a.following).map((t) => {
                  allUidsFollowingAndFriends.push(t[1].user_id)
                })
              }
              let allUids = allUidsFollowingAndFriends.filter((v, i, a) => a.findIndex((t) => t === v) === i);
              resolve(allUids)
            }
          }
        }
      }
      // resolve();
    });
    promiseTwo.then((useridsToShowProductOf) => {
      if (useridsToShowProductOf.length === 0) {
        return false;
      }
      let promiseThree = new Promise((resolve, reject) => {
        if (this.props.global_campaigns) {
          // console.log(this.props.global_campaigns, '/these campaigns')
          let as = this.props.global_campaigns;
          if (as) {
            let arrMain = [];
            // let objected = Object.entries(as)
            as.map((y) => {
              // let objectedNested = Object.entries(y[1])
              // objectedNested.map((g) => {
              arrMain.push(y[1]);
              // })
            });
            // return false;
            let arr = [];
            let allCampaignsCompleted = [];
            arrMain.map((e) => {
              e.type = "campaign";
              if (useridsToShowProductOf.includes(e.created_by)) {
                console.log(e, '/check this')
                let filtered = this.props.user.filter((f) => {
                  return f.user_id === e.created_by;
                });
                if (filtered.length > 0) {
                  let f = filtered[0];
                  if (!f.profileActive) {
                    return false;
                  }
                  if (e.active) {
                    if (e.desc) {
                      // e.desc = e.desc.substr(0, 520);
                      e.short_desc = e.desc.substr(0, 32);
                    }
                    e.images = [e.image];
                    e.imagesObjected = [
                      { image: e.image, pushKey: e.pushKey },
                    ];
                    if (e.campaignType === "kortings_voucher") {
                      let objectedVerzilveredBy = [];
                      let num = 0;
                      if (e.verzilvered_by) {
                        objectedVerzilveredBy = Object.entries(
                          e.verzilvered_by
                        );
                        num = this.getVerzilveredQty(
                          objectedVerzilveredBy
                        );
                      }
                      let bewaaredArray = [];
                      if (!e.kortingsVoucherUnlimited) {
                        if (
                          num + bewaaredArray.length <
                          Number(e.number)
                        ) {
                          arr.push(e);
                        }
                      } else {
                        arr.push(e);
                      }
                    }
                    if (e.campaignType === "web_shop") {
                      let objectedVerzilveredBy = [];
                      let num = 0;
                      if (e.verzilvered_by) {
                        objectedVerzilveredBy = Object.entries(
                          e.verzilvered_by
                        );
                        num = this.getVerzilveredQty(
                          objectedVerzilveredBy
                        );
                      }
                      let bewaaredArray = [];
                      if (!e.voucherUnlimited) {
                        if (
                          num + bewaaredArray.length <
                          Number(e.number)
                        ) {
                          arr.push(e);
                        }
                      } else {
                        arr.push(e);
                      }
                    }
                    if (e.campaignType === "local_event") {
                      if (e.eventForGuestUser) {
                        return false;
                      }
                      if (e.takeFromAdmin) {
                        let obj = mergeAdminEvent(e, this.props.global_campaigns)
                        e = Object.assign({}, obj)
                      }
                      let arrTimingsAvailable = [];

                      if (e.timings) {
                        e.timings.map((y) => {
                          if (y.isoStartTime && y.isoEndTime) {
                            let checkedInOfCurrentBlock = [];
                            if (e.checked_in_by) {
                              Object.entries(e.checked_in_by).map((t) => {
                                if (t[1].event_id === y.eventId) {
                                  checkedInOfCurrentBlock.push(t[1]);
                                }
                              }
                              );
                            }
                            let getCurrentDate = new Date();
                            if (
                              getCurrentDate.getTime() <
                              new Date(Date.parse(y.isoEndTime)).getTime()
                            ) {
                              if (
                                checkedInOfCurrentBlock.length <
                                Number(y.personsAmountEvent)
                              ) {
                                arrTimingsAvailable.push(y);
                              }
                            }
                          }
                        });
                      }
                      if (arrTimingsAvailable.length > 0) {
                        arr.push(e);
                      }
                    }
                  }
                }
              }
            });
            console.log(arr, '/check this')
            let allCampaigns = [];
            this.state.allProducts.map((u) => {
              allCampaigns.push(u);
            });
            arr.map((g) => {
              allCampaigns.push(g);
            });
            arr = arr.sort((a, b) => {
              return b.timestamp * 100 - a.timestamp * 100;
            });
            this.setState({ allProductsCampaigns: arr });
            this.setState({ allCampaignsCompleted });
          }
          resolve();
        }
        return false;
      });
      promiseThree.then(() => {
        let promiseFourth = new Promise((resolve, reject) => {
          resolve();
        });
        promiseFourth.then(() => {
          let cloned = [
            ...this.state.allProductsEvents,
            ...this.state.allProductsCampaigns,
          ]
          // .filter(
          //   (v, i, a) => a.findIndex((t) => t.pushKey === v.pushKey) === i
          // );
          cloned.sort(function (x, y) {
            return y.timestamp - x.timxestamp;
          });
          this.setState({ allProducts: [] });
          setTimeout(() => {
            if (cloned.length > 0) {
              let filteredArr = cloned.filter(
                (v, i, a) =>
                  a.findIndex((t) => t.created_by === v.created_by) === i
              );
              let arrSec = [];
              this.props.user.map((f) => {
                filteredArr.map((g) => {
                  if (f.user_id === g.created_by) {
                    f.checkedThat = true;
                    arrSec.push(f);
                  }
                });
              });
              this.setState({
                arrUsersArr: arrSec,
                arrUsersArrDuplicate: arrSec,
              });
            }
            cloned = cloned.sort((x, y) => {
              return y.created_at - x.created_at;
            });
            this.setState({
              allProducts: cloned,
              allProductsCompleted: cloned,
            });
          }, 10);
        });
      });
    });

    // Drag and drop
    let div = this.dropRef.current;
    if (div) {
      div.addEventListener("dragenter", this.handleDragIn);
      div.addEventListener("dragleave", this.handleDragOut);
      div.addEventListener("dragover", this.handleDrag);
      div.addEventListener("drop", this.handleDrop);
    }

    setTimeout(() => {
      localStorage.removeItem("onCurrentSectionCompanyProducts");
      localStorage.removeItem("productishiddenNow");
    }, 100);
    let localCurrent = localStorage.getItem("onCurrentSectionCompanyProducts");
    if (localCurrent) {
      let productishiddenNow = localStorage.getItem("productishiddenNow");
      if (!productishiddenNow) {
        this.setState({ activeSection: Number(localCurrent) });
      }
    }

    // bhodiCampaignsRef.on("value", (data) => {
    //   if (this.state.allProducts.length > 0) {
    //     let a = data.val();
    //     if (a) {
    //       let objected = Object.entries(a);
    //       let allArrs = [];
    //       let arr = [];
    //       objected.map((y) => {
    //         let objectedAgain = Object.entries(y[1]);
    //         objectedAgain.map((t) => {
    //           allArrs.push(t);
    //         });
    //       });
    //       allArrs.map((y) => {
    //         y[1].type = "campaign";
    //         if (y[1].desc) {
    //           // y[1].desc = y[1].desc.substr(0, 520);
    //           y[1].short_desc = y[1].desc.substr(0, 32);
    //         }
    //         if (y[1].campaignType !== "charity") {
    //           y[1].images = [y[1].image];
    //           y[1].imagesObjected = [
    //             { image: y[1].image, pushKey: y[1].pushKey },
    //           ];
    //         }
    //         if (y[1].campaignType === "local_event") {
    //           this.state.allProducts.map((t, i) => {
    //             let myCampaign = Object.assign({}, y[1])
    //             if (t.pushKey === myCampaign.pushKey) {
    //               if (t.localEventIfSharedThen) {
    //                 let filteredShared = allArrs.filter((g) => {
    //                   return g[1].pushKey === t.localEventIfSharedThen.pushKey
    //                 })
    //                 if (filteredShared.length > 0) {
    //                   myCampaign.localEventIfSharedThen = filteredShared[0][1]
    //                   if (!myCampaign.active || !myCampaign.localEventIfSharedThen.active) {
    //                     myCampaign.showPopupNotLeft = true;
    //                   }
    //                   this.state.allProducts[i] = myCampaign;
    //                 }
    //               } else {
    //                 if (!myCampaign.active) {
    //                   myCampaign.showPopupNotLeft = true;
    //                 }
    //                 this.state.allProducts[i] = myCampaign;
    //               }
    //             }
    //           });
    //         }

    //         if (y[1].campaignType === "kortings_voucher") {
    //           this.state.allProducts.map((t, i) => {
    //             if (t.pushKey === y[1].pushKey) {
    //               if (!y[1].active) {
    //                 y[1].showPopupNotLeft = true;
    //               } else {
    //                 if (!y[1].kortingsVoucherUnlimited) {
    //                   let bewaaredArray = [];
    //                   let num = 0;
    //                   if (y[1].verzilvered_by) {
    //                     let objectedVerzilvered = Object.entries(
    //                       y[1].verzilvered_by
    //                     );
    //                     num = this.getVerzilveredQty(objectedVerzilvered);
    //                   }
    //                   if (num + bewaaredArray.length >= Number(y[1].number)) {
    //                     let checkForMe = []
    //                     if (y[1].verzilvered_by) {
    //                       let objectedInner = Object.entries(y[1].verzilvered_by)
    //                       checkForMe = objectedInner.filter((t) => {
    //                         return t[1].user_id === uid
    //                       })
    //                     }
    //                     if (checkForMe.length === 0) {
    //                       y[1].showPopupNotLeft = true;
    //                     }
    //                   } else {
    //                     y[1].showPopupNotLeft = false;
    //                   }
    //                 } else {
    //                   y[1].showPopupNotLeft = false;
    //                 }
    //               }
    //               this.state.allProducts[i] = y[1];
    //             }
    //           });
    //         }
    //       });
    //       this.setState({ allProducts: this.state.allProducts });
    //     }
    //   }
    // });
  }

  // Drag and drop
  dropRef = React.createRef();

  getCharityObjectedQty(e) {
    let arrItems = e;
    if (arrItems.length > 0) {
      Array.prototype.sum = function (prop) {
        var total = 0;
        for (var i = 0, _len = this.length; i < _len; i++) {
          total += Number(this[i][1][prop]);
        }
        return total;
      };
      return arrItems.sum("used_time");
    }
  }

  getVerzilveredQty(e) {
    let arrItems = e;
    if (arrItems.length > 0) {
      Array.prototype.sum = function (prop) {
        var total = 0;
        for (var i = 0, _len = this.length; i < _len; i++) {
          total += Number(this[i][1][prop].length);
        }
        return total;
      };
      return arrItems.sum("allDates");
    }
  }

  componentWillUnmount() {
    this.setState({ onShowItems: false });
  }

  componentWillReceiveProps(e) {
    this.setState({ onShowItems: e.onShowItems });
  }

  getDayName(num) {
    if (num === 0) {
      return `Zondag`;
    } else if (num === 1) {
      return `Maandag`;
    } else if (num === 2) {
      return `Dinsdag`;
    } else if (num === 3) {
      return `Woensdag`;
    } else if (num === 4) {
      return `Donderdag`;
    } else if (num === 5) {
      return `Vrijdag`;
    } else if (num === 6) {
      return `Zaterdag`;
    }
  }

  getMonthName(i) {
    i = Number(i);
    if (i === 1) {
      return "Januari";
    } else if (i === 2) {
      return "Februari";
    } else if (i === 3) {
      return "Maart";
    } else if (i === 4) {
      return "April";
    } else if (i === 5) {
      return "Mei";
    } else if (i === 6) {
      return "Juni";
    } else if (i === 7) {
      return "Juli";
    } else if (i === 8) {
      return "Augustus";
    } else if (i === 9) {
      return "September";
    } else if (i === 10) {
      return "Oktober";
    } else if (i === 11) {
      return "November";
    } else if (i === 12) {
      return "December";
    }
  }


  componentDidUpdate(prevProps) {
    if (this.props.guest_user_cart) {
      if (prevProps.guest_user_cart !== this.props.guest_user_cart) {
        this.setState({ guestUserCart: this.props.guest_user_cart });
      }
    }
  }

  render() {
    // console.log(this.state.allProducts, '/check props volgend')
    return !this.state.isDesktop ? (
      this.state.allProducts.length > 0 && (
        <VolgendNewMobile productsWhole={this.state.allProducts} />
      )
    )
      : (
        this.state.allProducts.length > 0 && (<VouchersGrid all_products_grid={this.state.allProducts} />)
      );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducers.user,
    album: state.postsReducers.album,
    images: state.postsReducers.images,
    product: state.campaignsReducer.product,
    vouchers: state.campaignsReducer.vouchers,
    global_values: state.globalReducers.global_values,
    guest_user_cart: state.cartReducer.guest_user_cart,
    global_campaigns: state.campaignsReducer.global_campaigns,
  };
};


export default connect(
  mapStateToProps,
)(CreateProductMemberCompany);
