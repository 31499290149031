import React, { Component } from 'react'
import Footer from '../../Components/Footer'
import './index.css'
import FooterGreen from '../../Components/FooterGreen'

export class ErrorPage extends Component {
    render() {
        return (
            <div className="no-page-found">
                <h3>Sorry!</h3>
                <p>helaas is deze pagina niet beschikbaar</p>
                {window.innerWidth > 1008 ? (<Footer />) : (<FooterGreen />)}
            </div>
        )
    }
}

export default ErrorPage
