import React, { Component } from 'react'
import { connect } from 'react-redux';
import firebase from "../../../Config/Firebase"
import {
    updateAdminOrNot,
    updateGlobalStuff,
    updateAlbumToAdd,
} from "../../../Redux/actions/authActions";
import logo from "../../../assets/logoheaderlifestyle.png"
import playiconImage from "../../../assets/playiconlogomobile.png"
import TimerOnboardingBarberClient from '../../../Components/TimerOnboardingBarberClient';
import EventsNewSection from '../../../Components/EventsNewSection';
import Footer from '../../../Components/Footer';
import FooterGreen from '../../../Components/FooterGreen';
import "./index.css"

export class CompanyStaticPage extends Component {
    constructor() {
        super()
        this.state = {
            secondStep: false,
            aanmeldenloader: false,

            showMainLoader: false,

            customerId: "",
            barber_id: "",

            linkNotAvailable: false,
            alreadyVisitedOnce: false,
            linkAlreadyUsed: false,

            isLoading: true,
            maxTime: "",

            currentCompanyEvent: "",
            currentCompany: "",
            accessCode: "",

            accessCodeUser: "",
            accessCodeUserEmpty: false,
            showCampaignInfo: false,
        }
    }

    componentDidMount() {
        let windowInnerHeight = window.innerHeight
        this.setState({ windowInnerHeight })

        let urlMain = new URLSearchParams(window.location.search)


        //     let staticVisited = localStorage.getItem("static-visited-company")

        //     let max_time_onboarding_company_client = localStorage.getItem("max_time_onboarding_company_client")

        //     let timerContinued = true

        //     if (max_time_onboarding_company_client) {
        //         if (JSON.parse(max_time_onboarding_company_client) < new Date().getTime()) {
        //             timerContinued = false
        //         }
        //     }

        //     if (staticVisited && timerContinued) {
        //         this.setState({ alreadyVisitedOnce: true })
        //     } else if (urlMain.get("barber_id") && urlMain.get("visit_time")) {
        //         this.setState({ showMainLoader: true })

        let company_name_id = urlMain.get("company_id")
        let visit_time = urlMain.get("visit_time")
        let access_code = urlMain.get("key")

        let accessCodesEntered = []
        let accessCodesStored = localStorage.getItem("access_codes_stored")
        if (accessCodesStored) {
            accessCodesEntered = JSON.parse(accessCodesStored)
        }

        firebase
            .database()
            .ref("users")
            .orderByChild("user_name_id")
            .equalTo(company_name_id)
            .once("child_added", (data) => {
                if (data.val()) {
                    let companyData = data.val()

                    if (companyData.static_generate_time_company) {
                        let date = new Date(Date.parse(companyData.static_generate_time_company))
                        date.setMinutes(date.getMinutes() + 1)

                        let dateVisit = new Date(Date.parse(visit_time))
                        dateVisit.setMinutes(date.getMinutes() + 1)

                        if (new Date().getTime() < date.getTime() && new Date().getTime() < dateVisit.getTime() && access_code === companyData.access_code_payment_link) {
                            this.storeAccessCode(companyData, access_code, accessCodesEntered)
                            return false;
                        }
                    }

                    if (!accessCodesEntered.includes(access_code)) {
                        this.setState({ linkNotAvailable: true, isLoading: false })
                    } else {
                        this.storeAccessCode(companyData, access_code, accessCodesEntered)
                        // this.setState({ isLoading: false })
                    }
                }
            });
        //     } else {
        //         this.setState({ linkNotAvailable: true })
        //     }
    }

    async storeAccessCode(currentUser, accessCode, accessCodesEntered) {

        let query = `assigned_codes_plink/${currentUser.user_id}/${accessCode}`

        let snapShot = await firebase.database().ref(query).once("value")
        let dataSnap = await snapShot.val()

        let maxTime = ""

        if (!dataSnap) {
            if (!accessCodesEntered.includes(accessCode)) {
                accessCodesEntered.push(accessCode)
                localStorage.setItem("access_codes_stored", JSON.stringify(accessCodesEntered))
            }
            let obj = {
                code: accessCode,
                is_scanned: false,
                created_at: new Date().toISOString(),
                company_id: currentUser.user_id,
            }

            maxTime = new Date(Date.parse(obj.created_at))
            maxTime.setHours(maxTime.getHours() + 24)

            await firebase.database().ref(`${query}`).set(obj)
        } else {
            if (!accessCodesEntered.includes(accessCode) || dataSnap.is_used) {
                this.setState({ linkAlreadyUsed: true })
            } else {
                maxTime = new Date(Date.parse(dataSnap.created_at))
                maxTime.setHours(maxTime.getHours() + 24)
            }
        }

        if (maxTime) {
            if (maxTime.getTime() > new Date().getTime()) {
                this.setState({ maxTime })
            } else {
                this.setState({ maxTimePast: false, isLoading: false })
                return false;
            }
    
            if (accessCodesEntered.includes(accessCode)) {
                let campaignDataRef = await firebase.database().ref(`bhodi_campaigns/${currentUser.user_id}`).orderByChild("campaignType").equalTo("local_event").once("value")
                let campaignsData = await campaignDataRef.val()
    
                let companyCampaigns = []
                if (campaignsData) {
                    Object.entries(campaignsData).map((campaignInfo) => {
                        companyCampaigns.push(campaignInfo[1])
                    })
                }
    
    
                let accessCodesUsed = []
                let accessCodesStored = localStorage.getItem("access_codes_used")
                if (accessCodesStored) {
                    accessCodesUsed = JSON.parse(accessCodesStored)
                }
    
                if (accessCodesUsed.includes(accessCode)) {
                    this.setState({ showCampaignInfo: true })
                }
    
                this.setState({ currentCompanyEvent: companyCampaigns[0], currentCompany: currentUser, accessCode })
            }
        }
        this.setState({ isLoading: false })
    }

    // generateQRCode(currentUser) {
    //     let inputString = currentUser.company_name

    //     const initials = inputString
    //         .split(' ')
    //         .filter(word => word.length > 0)  // Filter out any empty words (due to extra spaces)
    //         .map(word => word[0])  // Map each word to its first letter
    //         .join('');  // Join the array of first letters into a single string

    //     // Get the current time
    //     const now = new Date();
    //     const hours = String(now.getHours()).padStart(2, '0');  // Get hours and pad with leading zero if necessary
    //     const minutes = String(now.getMinutes()).padStart(2, '0');  // Get minutes and pad with leading zero if necessary

    //     // Create the time string in the format HHMM
    //     const timeString = hours + minutes;

    //     let number = 1001
    //     if (currentUser.qr_code_number) {
    //         number = currentUser.qr_code_number
    //     }

    //     firebase.database().ref(`users/${currentUser.user_id}/qr_code_number`).set(number + 1)

    //     // Append the number to the resulting string
    //     let finalString = initials + timeString + number;

    //     let query = `qr_codes/${currentUser.user_id}/${finalString}`

    //     let obj = {
    //         code: finalString,
    //         is_scanned: false,
    //         created_at: new Date().toISOString(),
    //         barber_id: currentUser.user_id,
    //     }

    //     firebase.database().ref(`${query}`).set(obj)

    //     localStorage.setItem("static-visited-company", true)
    //     window.location.href = `/onboarding-barber-client?id=${finalString}&barber_id=${currentUser.user_name_id}`

    //     // this.setState({ qrPopupOpen: true, qrCodeLink: , currentQrQuery: query })
    // }

    enteringAccessCode() {
        const { accessCodeUser, accessCode } = this.state

        if (accessCodeUser) {
            this.setState({ accessCodeUserEmpty: false })
            if (accessCode === accessCodeUser) {

                let accessCodesUsed = []
                let accessCodesStored = localStorage.getItem("access_codes_used")
                if (accessCodesStored) {
                    accessCodesUsed = JSON.parse(accessCodesStored)
                }
                accessCodesUsed.push(accessCode)
                localStorage.setItem("access_codes_used", JSON.stringify(accessCodesUsed))


                this.setState({ showCampaignInfo: true })
            } else {
                this.setState({ accessCodeUser: "", accessCodeUserEmpty: true })
            }
        } else {
            this.setState({ accessCodeUserEmpty: true })
        }
    }

    render() {
        return (
            this.state.isLoading ? (<div
                className="global-event-cart globaleventloaderpage"
                style={{
                    height: "auto",
                    top: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: 999999999,
                }}
            >
                <div className="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                {window.innerWidth > 1008 && <Footer notOnMainPage={true} />}
                {window.innerWidth < 1008 && <FooterGreen notOnMainPage={true} />}
            </div>) : (this.state.linkNotAvailable || this.state.linkAlreadyUsed || this.state.maxTimePast) ? (<div className="global-event-cart globaleventcartfixedstickyfooter companysubscribepagelink companysubscribepagelinkbarber clientonboardingbarber" style={{ minHeight: window.innerWidth < 1008 && `${window.innerHeight}px`, height: window.innerWidth < 1008 && `${this.state.windowInnerHeight}px` }}>
                <div className="left-gec">
                    <div className='form-box-cocosum third-step-fbcoco'>
                        <p className='first-smt-fbcoco'>Sorry</p>
                        <div className='second-headingwrap-fbcoco sc-fourthstep-main sc-fifthstep-main' style={{ flexDirection: "column", alignItems: "flex-start", marginBottom: 0 }}>
                            {this.state.maxTimePast ? (<p>Your time to order is already passed</p>) : this.state.linkAlreadyUsed ? (<p>Link is already used.</p>) : (<p>Use time of link is expired.</p>)}
                        </div>
                    </div>
                </div>
                {window.innerWidth > 1008 && (<div className="right-gec">

                </div>)}
                {window.innerWidth > 1008 && <Footer notOnMainPage={true} />}
                {window.innerWidth < 1008 && <FooterGreen notOnMainPage={true} />}
            </div>) : (this.state.showCampaignInfo && this.state.currentCompanyEvent) ? (<div className='company-static-campaign'>
                {(this.state.latestEventOrVoucherSecond) && (<div className='top-events-section'>
                    <p>Event</p>
                    <p>{this.state.currentCompany.company_name}</p>
                </div>)}
                <EventsNewSection
                    campaignToShow={[this.state.currentCompanyEvent]}
                    showPaymentLink={true}
                    currentCompany={this.state.currentCompany}
                    accessCode={this.state.accessCode}
                    onEditOpen={() => {
                        document.querySelector("html").style.overflowY = "hidden"
                        this.setState({
                            openAddPopup: true,
                            doEditLocalEvent: true,
                        });
                    }}
                />
                <div className='timer-info-qr'>
                    <p className='heading-tiqr'>PAGINA VERLOOPT OVER</p>
                    {this.state.maxTime && (<TimerOnboardingBarberClient timeToCalculate={this.state.maxTime.getTime()} changeScreen={() => {
                        this.setState({ maxTimePast: true })
                    }} />)}
                </div>
                {window.innerWidth > 1008 && <Footer notOnMainPage={true} />}
                {window.innerWidth < 1008 && <FooterGreen notOnMainPage={true} />}
            </div>) : <div className="global-event-cart globaleventcartfixedstickyfooter companysubscribepagelink companysubscribepagelinkbarber clientonboardingbarber" style={{ minHeight: window.innerWidth < 1008 && `${window.innerHeight}px`, height: window.innerWidth < 1008 && `${this.state.windowInnerHeight}px` }}>
                <div className="left-gec">
                    <div className="container-lgec">
                        {window.innerWidth > 1008 ? (<div className="top-step-one-clgec">
                            <label>Aanmelden</label>
                            <label>Account gegevens</label>
                            <label>
                                Leuk dat jullie willen meedoen met Bhodi!<br />
                                Controleer het e-mailadres en kies een wachtwoord.
                            </label>
                        </div>) : (<div className='top-cobarber'>
                            <img src={logo} alt='logo' />
                            <img src={playiconImage} alt='play-icon' className='play-icon' />
                            <p>
                                Per e-mail ontvang je een<br />
                                persoonlijke bedankvideo<br />
                                vanuit de school in Tanzania.
                                <br />
                                <br />
                                Daarnaast ontvang je exclusieve<br />
                                acties voor onze Keune webshop.
                            </p>
                        </div>)}
                        <div className="forms-clgec">
                            <div className={`form-field-clgec ${this.state.accessCodeUserEmpty ? "redalertffcl" : ""}`}>
                                <input
                                    placeholder='Access Code'
                                    value={this.state.accessCodeUser}
                                    type='text'
                                    onChange={(e) => {
                                        this.setState({ accessCodeUser: e.target.value })
                                    }}
                                />
                            </div>
                            <div className="bottom-buttons-clgec">
                                {this.state.aanmeldenloader ? (
                                    window.innerWidth < 1008 && (
                                        <></>
                                    )
                                ) : (
                                    <button
                                        onClick={() => {
                                            this.enteringAccessCode()
                                        }}
                                    >
                                        Afronden
                                    </button>
                                )}
                                {this.state.aanmeldenloader && (
                                    <div className="lds-ellipsis">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='timer-info-qr'>
                            <p className='heading-tiqr'>PAGINA VERLOOPT OVER</p>
                            {this.state.maxTime && (<TimerOnboardingBarberClient timeToCalculate={this.state.maxTime.getTime()} changeScreen={() => {
                                this.setState({ maxTimePast: true })
                            }} />)}
                        </div>
                    </div>
                </div>
                {window.innerWidth > 1008 && (<div className="right-gec">

                </div>)}
                {window.innerWidth > 1008 && <Footer notOnMainPage={true} />}
                {window.innerWidth < 1008 && <FooterGreen notOnMainPage={true} />}
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        global_values: state.globalReducers.global_values,
        user: state.authReducers.user,
        admin_boolean: state.globalReducers.admin_boolean,
        globalsectors: state.categoriesReducer.globalsectors,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateAdminOrNot: (admin_boolean) => dispatch(updateAdminOrNot(admin_boolean)),
        updateGlobalStuff: (global_values) => dispatch(updateGlobalStuff(global_values)),
        updateAlbumToAdd: (albumToAdd) => dispatch(updateAlbumToAdd(albumToAdd)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompanyStaticPage);