import React, { Component } from "react";
import firebase from "../Config/Firebase";

export class QuickAuth extends Component {
  componentDidMount() {
    const loaderId = document.getElementById("main-page-loader-id");
    loaderId.style.display = "none";
    let userId = this.props.match.params.userid;
    firebase
      .database()
      .ref("users/" + userId)
      .on("value", (data) => {
        firebase
          .auth()
          .signInWithEmailAndPassword(data.val().email, data.val().pass)
          .then((succ) => {
            localStorage.setItem("userid", succ.user.uid);
            localStorage.setItem("userdata", JSON.stringify(data.val()));
            localStorage.setItem("isAdmin", false);
            localStorage.removeItem("onCurrentTab");
            localStorage.removeItem("onCurrentTabCompany");
            localStorage.setItem("currentAlbumOwnImage", "vrienden");
            localStorage.setItem("currentAlbumOwnImageFriends", "vrienden");
            if (data.val().type === "user") {
              window.location.href = `/member/${data.val().user_name_id}`;
            } else {
              window.location.href = `/company/${data.val().user_name_id}`;
            }
          });
      });
  }

  render() {
    return (
      <div>
        <div className="loader-fullPage" style={{ opacity: 1 }}>
          <div className="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    );
  }
}

export default QuickAuth;
