import * as firebase from "firebase";

export const firebaseConfig = {
  // apiKey: "AIzaSyCYLy2usyh-jZ2ZvIplc2PjtfcOkniRoWY",
  // authDomain: "bhodi-7c327.firebaseapp.com",
  // databaseURL: "https://bhodi-7c327.firebaseio.com",
  // projectId: "bhodi-7c327",
  // storageBucket: "bhodi-7c327.appspot.com",
  // messagingSenderId: "206128177666",
  // appId: "1:206128177666:web:8142f5559fea68029afd1b",
  // measurementId: "G-XXT7DT4122",
  apiKey: "AIzaSyAeTnVHULDBgR-IOmHX8W7SPi_b1ML7wI4",
  authDomain: "bhodi-production-da765.firebaseapp.com",
  databaseURL: "https://bhodi-production-da765-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "bhodi-production-da765",
  storageBucket: "bhodi-production-da765.appspot.com",
  messagingSenderId: "1053691118351",
  appId: "1:1053691118351:web:90d7ddf854d9a474e9f47a"
};

firebase.initializeApp(firebaseConfig);

export default firebase;
