import React, { Component } from "react";
import AddIcon from "@material-ui/icons/Add";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import SortableTree from "react-sortable-tree";
import "react-sortable-tree/style.css";
import CloseIcon from "@material-ui/icons/Close";
import firebase from "../Config/Firebase";
import AdminNavbar from "./AdminNavbar";

export class SectorSorting extends Component {
  constructor(props) {
    super(props);

    this.state = {
      treeData: [],
      sectorModal: false,
      sectorModalCategory: false,
      sectorName: "",
      sectors: [],
      currentSector: "",
      currentSectorId: "",
      categoryName: "",
      currentSector: "",
      all_categories: [],
      sortingTab: true,
      adjustingTab: false,
      completeMainTitles: [],
      arrOfInputs: [],
    };
  }

  componentDidMount() {
    const loaderId = document.getElementById("main-page-loader-id");
    loaderId.style.display = "none";
    firebase
      .database()
      .ref("all_sectors")
      .on("value", (data) => {
        let a = data.val();
        let arr = [];
        if (a) {
          let objected = Object.entries(a);
          objected.map((g) => {
            arr.push(g[1]);
          });
          let arrOfInputs = [];
          if (arr.length > 0) {
            if (this.state.arrOfInputs.length > 0) {
              console.log(this.state.sectors.length);
              console.log(arr.length);
              if (this.state.sectors.length !== arr.length) {
                arr.map((g) => {
                  let obj = {
                    inputOpened: false,
                  };
                  arrOfInputs.push(obj);
                });
              } else {
                arrOfInputs = this.state.arrOfInputs;
              }
            } else {
              arr.map((g) => {
                let obj = {
                  inputOpened: false,
                };
                arrOfInputs.push(obj);
              });
            }
            this.setState({ sectors: arr, arrOfInputs });
          }
        } else {
          this.setState({ sectors: [] });
        }
      });
  }

  createSector() {
    const { sectorName } = this.state;
    let timestamp = +new Date();
    let id = sectorName + "" + timestamp;
    let pushKey = firebase.database().ref("all_sectors").push().key;
    let obj = {
      sector_name: sectorName,
      sector_id: id,
      pushKey,
    };
    firebase
      .database()
      .ref("all_sectors/" + pushKey)
      .set(obj)
      .then(() => {
        document.getElementById("root").style.position = "static";
        document.getElementById("root").style.overflow = "auto";
        this.setState({ sectorName: "", sectorModal: false });
      });
  }

  render() {
    return (
      <div className="sorting-mainadmin">
        <AdminNavbar sectorsPage={true} />
        <div className="container-sma">
          <div className="top-container-sma">
            <div className="sector-tcsma">
              {/* <select onChange={(e) => {
                                this.setState({ currentSector: JSON.parse(e.target.value), currentSectorId: JSON.parse(e.target.value).sector_id })
                                let filtered = []
                                this.state.all_categories.map((j) => {
                                    if (j[0] === JSON.parse(e.target.value).sector_id) {
                                        filtered = j[1]
                                    }
                                })
                                this.setState({ treeData: filtered })
                            }}>
                                {this.state.sectors.length > 0 && this.state.sectors.map((g) => {
                                    return <option value={JSON.stringify(g)} defaultChecked={this.state.currentSectorId === g.sector_id}>{g.sector_name}</option>
                                })}
                            </select>
                            <ArrowDropDownIcon className="dropdown-select-tcsma" /> */}
            </div>
            <div className="category-tcsma">
              <p
                onClick={() => {
                  document.getElementById("root").style.position = "fixed";
                  document.getElementById("root").style.overflow = "hidden";
                  this.setState({ sectorModal: true });
                }}
              >
                <AddIcon /> SECTOR{" "}
              </p>
            </div>
          </div>
          {this.state.sectors.length > 0 && (
            <div className="tree-inner" style={{ marginTop: 30 }}>
              <ul className="adjustng-ti">
                {this.state.sectors.map((h, i) => {
                  console.log(h);
                  return (
                    <li>
                      {!this.state.arrOfInputs[i].inputOpened ? (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div className="imgdivalbumscategory">
                            {h.image ? (
                              <img src={h.image} />
                            ) : (
                              <img src="https://firebasestorage.googleapis.com/v0/b/bhodi-production-da765.appspot.com/o/profile-image.jpg?alt=media&token=2f12b65e-4895-4351-b82e-607a71f3a3a9" />
                            )}
                            <input
                              type="file"
                              onChange={(e) => {
                                console.log(e);
                                e.preventDefault();
                                let dropFiles = [];
                                if (
                                  e.target.files &&
                                  e.target.files.length > 0
                                ) {
                                  let objected = Object.entries(e.target.files);
                                  objected.map((f, is) => {
                                    let mainimg = objected[is][1];
                                    firebase
                                      .storage()
                                      .ref()
                                      .child(
                                        `campaigns/${
                                          mainimg.name
                                        }${new Date().getTime()}`
                                      )
                                      .put(mainimg)
                                      .then((snapshot) => {
                                        snapshot.ref
                                          .getDownloadURL()
                                          .then((snapUrl) => {
                                            firebase
                                              .database()
                                              .ref(
                                                "all_sectors/" +
                                                  h.pushKey +
                                                  "/image"
                                              )
                                              .set(snapUrl);
                                          });
                                      });
                                  });
                                }
                              }}
                            />
                          </div>
                          <font
                            onClick={() => {
                              this.state.arrOfInputs[i].inputOpened = true;
                              this.setState({
                                arrOfInputs: this.state.arrOfInputs,
                              });
                            }}
                          >
                            {h.sector_name}
                          </font>
                        </div>
                      ) : (
                        <input
                          type="text"
                          value={h.sector_name}
                          autoFocus
                          onBlur={() => {
                            this.state.arrOfInputs[i].inputOpened = false;
                            this.setState({
                              arrOfInputs: this.state.arrOfInputs,
                            });
                          }}
                          onChange={(e) => {
                            this.state.sectors[i].sector_name = e.target.value;
                            this.setState({ sectors: this.state.sectors });
                          }}
                          onBlur={() => {
                            firebase
                              .database()
                              .ref("all_sectors/" + h.pushKey + "/sector_name")
                              .set(h.sector_name);
                          }}
                        />
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
        <div
          className="modal-sector-sorting"
          style={{ right: this.state.sectorModal ? 0 : "-100%" }}
        >
          <CloseIcon
            className="close-mss"
            onClick={() => {
              document.getElementById("root").style.position = "static";
              document.getElementById("root").style.overflow = "auto";
              this.setState({ sectorModal: false });
            }}
          />
          <p>Create Sector</p>
          <div className="form-mss">
            <label>Sector name</label>
            <input
              type="text"
              value={this.state.sectorName}
              onChange={(e) => this.setState({ sectorName: e.target.value })}
            />
          </div>
          <div className="form-mss">
            <button onClick={this.createSector.bind(this)}>CREATE</button>
          </div>
        </div>
        <div
          className="modal-sector-overlay"
          style={{
            opacity: this.state.sectorModal ? 0.7 : 0,
            pointerEvents: this.state.sectorModal ? "all" : "none",
          }}
          onClick={() => {
            document.getElementById("root").style.position = "static";
            document.getElementById("root").style.overflow = "auto";
            this.setState({ sectorModal: false });
          }}
        ></div>
      </div>
    );
  }
}

export default SectorSorting;
