import React, { Component } from 'react'
import { connect } from 'react-redux';
import {
    updateAdminOrNot,
    updateGlobalStuff,
    updateAlbumToAdd,
} from "../Redux/actions/authActions";
import { MdClose } from 'react-icons/md';
import firebase from "../Config/Firebase";

export class UpdatedLoginDesign extends Component {
    constructor() {
        super()
        this.state = {
            loginEmail: "",
            loginEmailPopup: false,
            loginPassword: "",
            loginPasswordPopup: false,
        }
    }

    logIn() {
        const { loginEmail, loginPassword } = this.state;
        let regexEmail =
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (loginEmail === "" || !regexEmail.test(loginEmail)) {
            this.setState({ loginEmailPopup: true, loginEmail: "" });
        } else {
            this.setState({ loginEmailPopup: false });
        }
        if (loginPassword === "") {
            this.setState({ loginPasswordPopup: true });
        } else {
            this.setState({ loginPasswordPopup: false });
        }
        if (loginEmail !== "" && regexEmail.test(loginEmail) && loginPassword !== "") {
            this.setState({ aanmeldenloader: true });
            if (loginEmail === "admin@bhodi.com") {
                firebase
                    .auth()
                    .signInWithEmailAndPassword(loginEmail, loginPassword)
                    .then((succ) => {
                        firebase
                            .database()
                            .ref("users/" + succ.user.uid)
                            .on("value", (data) => {
                                localStorage.setItem("userid", succ.user.uid);
                                // localStorage.setItem("userdata", JSON.stringify(data.val()))
                                localStorage.setItem("isAdmin", true);
                                this.props.updateAdminOrNot(true);
                                localStorage.removeItem("onCurrentTab");
                                localStorage.removeItem("onCurrentTabCompany");
                                localStorage.removeItem("cookie_item");
                                setTimeout(() => {
                                    window.location.href = "/admin";
                                }, 400);
                            });
                    })
                    .catch((err) => {
                        if (err.code === "auth/user-not-found") {
                            this.setState({
                                aanmeldenloader: false,
                                loginEmailPopup: true,
                                loginEmail: "",
                                loginPassword: "",
                            });
                        } else if (err.code === "auth/wrong-password") {
                            this.setState({
                                aanmeldenloader: false,
                                loginPasswordPopup: true,
                                loginPassword: "",
                            });
                        } else {
                            this.setState({
                                aanmeldenloader: false
                            })
                            alert("Login failed, please try again")
                        }
                    });
            } else {
                firebase
                    .auth()
                    .signInWithEmailAndPassword(loginEmail, loginPassword)
                    .then((succ) => {
                        firebase
                            .database()
                            .ref("users/" + succ.user.uid)
                            .on("value", (data) => {
                                let a = data.val();
                                if (a) {
                                    if (a.type === "user") {
                                        localStorage.removeItem("onStoriesActive");
                                        this.props.updateAlbumToAdd("vrienden");
                                    }
                                    let promiseOne = new Promise((res) => {
                                        if (a.type === "user") {
                                            navigator.geolocation.getCurrentPosition((position) => {
                                                firebase
                                                    .database()
                                                    .ref("users/" + succ.user.uid + "/latitude")
                                                    .set(position.coords.latitude);
                                                firebase
                                                    .database()
                                                    .ref("users/" + succ.user.uid + "/longitude")
                                                    .set(position.coords.longitude);
                                                localStorage.setItem(
                                                    "latitudeAfterLogin",
                                                    position.coords.latitude
                                                );
                                                localStorage.setItem(
                                                    "longitudeAfterLogin",
                                                    position.coords.longitude
                                                );
                                                res();
                                            });
                                        } else {
                                            navigator.geolocation.getCurrentPosition((position) => {
                                                localStorage.setItem(
                                                    "latitudeAfterLogin",
                                                    position.coords.latitude
                                                );
                                                localStorage.setItem(
                                                    "longitudeAfterLogin",
                                                    position.coords.longitude
                                                );
                                                res();
                                            });
                                        }
                                        setTimeout(() => {
                                            res();
                                        }, 4000);
                                    });
                                    promiseOne.then(() => {
                                        localStorage.setItem("userid", succ.user.uid);
                                        // localStorage.setItem("userdata", JSON.stringify(a))
                                        localStorage.setItem("isAdmin", false);
                                        this.props.updateAdminOrNot(false);
                                        localStorage.removeItem("onCurrentTab");
                                        localStorage.removeItem("onCurrentTabCompany");
                                        localStorage.setItem("currentAlbumOwnImage", "vrienden");
                                        localStorage.setItem(
                                            "currentAlbumOwnImageFriends",
                                            "vrienden"
                                        );
                                        localStorage.removeItem("cookie_item");
                                        setTimeout(() => {
                                            window.location.reload()
                                        }, 400);
                                    });
                                }
                            });
                    })
                    .catch((err) => {
                        if (err.code === "auth/user-not-found") {
                            this.setState({
                                aanmeldenloader: false,
                                loginEmailPopup: true,
                                loginEmail: "",
                                loginPassword: "",
                            });
                        } else if (err.code === "auth/wrong-password") {
                            this.setState({
                                aanmeldenloader: false,
                                loginPasswordPopup: true,
                                loginPassword: "",
                            });
                        } else {
                            this.setState({
                                aanmeldenloader: false
                            })
                            alert("Login failed, please try again")
                        }
                    });
            }
        }
    }

    render() {
        return (
            <div className='login-overlay-main' style={{ zIndex: 99999999999 }}>
                <div className='login-container-lpn'>
                    <MdClose className='icon-person-top-lpn' onClick={() => {
                        this.props.closePopup()
                    }} />
                    <h2>LOGIN</h2>
                    <div className={`container-field-cglpn ${this.state.loginEmailPopup ? "containerRedAlerCglpn" : ""}`}>
                        <input type="email" placeholder='E-mail' value={this.state.loginEmail} onChange={(e) => {
                            this.setState({ loginEmail: e.target.value })
                        }} />
                    </div>
                    <div className={`container-field-cglpn ${this.state.loginPasswordPopup ? "containerRedAlerCglpn" : ""}`}>
                        <input type="password" placeholder='Wachtwoord' value={this.state.loginPassword} onChange={(e) => {
                            this.setState({ loginPassword: e.target.value })
                        }} />
                    </div>
                    <div className='container-button-cglpn'>
                        {this.state.aanmeldenloader ? (
                            <div className="lds-ellipsis">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        ) : (<button onClick={() => this.logIn()} style={{ borderRadius: 6 }}>Inloggen</button>)}
                    </div>
                    <label className='forget-pwd-lpn'>Wachtwoord vergeten?</label>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        global_values: state.globalReducers.global_values,
        user: state.authReducers.user,
        admin_boolean: state.globalReducers.admin_boolean,
        subscription_plans: state.globalReducers.subscription_plans,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateAdminOrNot: (admin_boolean) =>
            dispatch(updateAdminOrNot(admin_boolean)),
        updateGlobalStuff: (global_values) =>
            dispatch(updateGlobalStuff(global_values)),
        updateAlbumToAdd: (albumToAdd) => dispatch(updateAlbumToAdd(albumToAdd)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UpdatedLoginDesign);