import React, { Component } from "react";
import "./index.css";
import firebase from "../../Config/Firebase";
import { connect } from "react-redux";
import {
    updateAdminOrNot,
    updateGlobalStuff,
    updateAlbumToAdd,
} from "../../Redux/actions/authActions";
import Footer from "../../Components/Footer";
import axios from "axios";
import FooterGreen from "../../Components/FooterGreen";

export class LoginCompany extends Component {
    constructor() {
        super();
        this.state = {
            showDutch: true,
            events: [],
            menuOpen: false,
            allUsers: [],
            country: "155",
            isUserForm: true,
            showCharityStuff: false,
            subscription_data: [],
            plan_id: "",
            plan_amount: "",
            // registration fields
            firstName: "",
            firstNamePopup: false,
            lastName: "",
            lastNamePopup: false,
            email: "",
            emailPopup: false,
            password: "",
            passwordPopup: false,
            country: "155",
            companyRegistration: false,
            companyName: "",
            kvkCompany: "",
            kvkCompanyPopup: false,
            street: "",
            streetPopup: false,
            houseNumber: "",
            houseNumberPopup: false,
            city: "",
            cityPopup: false,
            zipCode: "",
            zipCodePopup: false,
            phoneNumber: "",
            phoneNumberPopup: false,
            company_website: "",
            company_websitePopup: false,
            passwordInputType: "password",
            guestUserCart: [],
            aanmeldenloader: false,
            allUsers: [],
            agreed: false,
            agreedPopup: false,
            currentUserSpecific: "",
            isUserCreated: false,
            currentDataOfUser: "",
            popupopenforright: true,
            isAdmin: false,
        };
    }

    componentDidMount() {
        const loaderId = document.getElementById("main-page-loader-id");
        loaderId.style.display = "none";
    }

    logIn() {
        const { email, password } = this.state;
        const loginEmail = email
        const loginPassword = password
        let regexEmail =
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (loginEmail === "" || !regexEmail.test(loginEmail)) {
            this.setState({ loginEmailPopup: true, loginEmail: "" });
        } else {
            this.setState({ loginEmailPopup: false });
        }
        if (loginPassword === "") {
            this.setState({ loginPasswordPopup: true });
        } else {
            this.setState({ loginPasswordPopup: false });
        }
        if (loginEmail !== "" && regexEmail.test(loginEmail) && loginPassword !== "") {
            this.setState({ aanmeldenloader: true });
            if (loginEmail === "admin@bhodi.com") {
                firebase
                    .auth()
                    .signInWithEmailAndPassword(loginEmail, loginPassword)
                    .then((succ) => {
                        firebase
                            .database()
                            .ref("users/" + succ.user.uid)
                            .on("value", (data) => {
                                localStorage.setItem("userid", succ.user.uid);
                                // localStorage.setItem("userdata", JSON.stringify(data.val()))
                                localStorage.setItem("isAdmin", true);
                                this.props.updateAdminOrNot(true);
                                localStorage.removeItem("onCurrentTab");
                                localStorage.removeItem("onCurrentTabCompany");
                                localStorage.removeItem("cookie_item");
                                setTimeout(() => {
                                    window.location.href = "/admin";
                                }, 400);
                            });
                    })
                    .catch((err) => {
                        if (err.code === "auth/user-not-found") {
                            this.setState({
                                aanmeldenloader: false,
                                loginEmailPopup: true,
                                loginEmail: "",
                                loginPassword: "",
                            });
                        } else if (err.code === "auth/wrong-password") {
                            this.setState({
                                aanmeldenloader: false,
                                loginPasswordPopup: true,
                                loginPassword: "",
                            });
                        } else {
                            this.setState({
                                aanmeldenloader: false
                            })
                            alert("Login failed, please try again")
                        }
                    });
            } else {
                firebase
                    .auth()
                    .signInWithEmailAndPassword(loginEmail, loginPassword)
                    .then((succ) => {
                        firebase
                            .database()
                            .ref("users/" + succ.user.uid)
                            .on("value", (data) => {
                                let a = data.val();
                                if (a) {
                                    if (a.type === "user") {
                                        localStorage.removeItem("onStoriesActive");
                                        this.props.updateAlbumToAdd("vrienden");
                                    }
                                    let promiseOne = new Promise((res) => {
                                        if (a.type === "user") {
                                            navigator.geolocation.getCurrentPosition((position) => {
                                                firebase
                                                    .database()
                                                    .ref("users/" + succ.user.uid + "/latitude")
                                                    .set(position.coords.latitude);
                                                firebase
                                                    .database()
                                                    .ref("users/" + succ.user.uid + "/longitude")
                                                    .set(position.coords.longitude);
                                                localStorage.setItem(
                                                    "latitudeAfterLogin",
                                                    position.coords.latitude
                                                );
                                                localStorage.setItem(
                                                    "longitudeAfterLogin",
                                                    position.coords.longitude
                                                );
                                                res();
                                            });
                                        } else {
                                            let address = `${a.street} ${a.houseNumber}, ${a.zipCode} ${a.city}, ${a.country}`;
                                            var config = {
                                                method: "get",
                                                url: `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${process.env.REACT_APP_GEOCODE_KEY}`,
                                                headers: {},
                                            };

                                            axios(config)
                                                .then((response) => {
                                                    let coords = response.data.results[0].geometry.location;
                                                    firebase
                                                        .database()
                                                        .ref("users/" + a.user_id + "/latitude")
                                                        .set(coords.lat);
                                                    firebase
                                                        .database()
                                                        .ref("users/" + a.user_id + "/longitude")
                                                        .set(coords.lng);
                                                })
                                                .catch(function (error) {
                                                    console.log(error);
                                                });
                                            navigator.geolocation.getCurrentPosition((position) => {
                                                localStorage.setItem(
                                                    "latitudeAfterLogin",
                                                    position.coords.latitude
                                                );
                                                localStorage.setItem(
                                                    "longitudeAfterLogin",
                                                    position.coords.longitude
                                                );
                                                res();
                                            });
                                        }
                                        setTimeout(() => {
                                            res();
                                        }, 4000);
                                    });
                                    promiseOne.then(() => {
                                        localStorage.setItem("userid", succ.user.uid);
                                        // localStorage.setItem("userdata", JSON.stringify(a))
                                        localStorage.setItem("isAdmin", false);
                                        this.props.updateAdminOrNot(false);
                                        localStorage.removeItem("onCurrentTab");
                                        localStorage.removeItem("onCurrentTabCompany");
                                        localStorage.setItem("currentAlbumOwnImage", "vrienden");
                                        localStorage.setItem(
                                            "currentAlbumOwnImageFriends",
                                            "vrienden"
                                        );
                                        localStorage.removeItem("cookie_item");
                                        setTimeout(() => {
                                            if (a.type === "user") {
                                                window.location.href = `/member/${a.user_name_id}`;
                                                // if (window.innerWidth < 1009) {
                                                //     this.setState({ loader: false })
                                                // }
                                            } else {
                                                window.location.href = `/company/${a.user_name_id}`;
                                                // if (window.innerWidth < 1009) {
                                                //     this.setState({ loader: false })
                                                // }
                                            }
                                        }, 400);
                                    });
                                }
                            });
                    })
                    .catch((err) => {
                        if (err.code === "auth/user-not-found") {
                            this.setState({
                                aanmeldenloader: false,
                                loginEmailPopup: true,
                                loginEmail: "",
                                loginPassword: "",
                            });
                        } else if (err.code === "auth/wrong-password") {
                            this.setState({
                                aanmeldenloader: false,
                                loginPasswordPopup: true,
                                loginPassword: "",
                            });
                        } else {
                            this.setState({
                                aanmeldenloader: false
                            })
                            alert("Login failed, please try again")
                        }
                    });
            }
        }
    }

    render() {
        return (
            <div className="landingpage-bhodi registrationpagebhodiupdated loginpageupdated adminloginpage">
                <div className="third-section-background-lpb">
                    <div className="left-tsblpb" style={{ backgroundColor: "#f7f7f7" }}>
                        <h2 data-aos="fade-in">Company Login</h2>
                        <div className="forms-clgec">
                            <div
                                className={`form-field-clgec ${this.state.emailPopup ? "redalertffcl" : ""
                                    }`}
                            >
                                <label>Contact</label>
                                <input
                                    type="email"
                                    placeholder={!this.state.emailPopup ? "Email adres" : "E-mail adres is niet gevonden"}
                                    autoComplete="off"
                                    value={this.state.email}
                                    onChange={(e) => this.setState({ email: e.target.value })}
                                />
                            </div>
                            <div
                                className={`form-field-clgec ${this.state.passwordPopup ? "redalertffcl" : ""
                                    }`}
                            >
                                <input
                                    type={this.state.passwordInputType}
                                    autoComplete="off"
                                    placeholder="Kies wachtwoord"
                                    value={this.state.password}
                                    onChange={(e) => {
                                        this.setState({ password: e.target.value });
                                    }}
                                />
                            </div>
                            <div className="bottom-buttons-clgec">
                                {this.state.aanmeldenloader ? (
                                    <div className="lds-ellipsis">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                ) : (
                                    <button
                                        onClick={this.logIn.bind(this)}
                                        style={{ backgroundColor: "#C17F6B" }}
                                    >
                                        Inloggen
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {window.innerWidth > 1008 && <Footer notOnMainPage={true} />}
                {window.innerWidth < 1008 && <FooterGreen notOnMainPage={true} />}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        global_values: state.globalReducers.global_values,
        user: state.authReducers.user,
        admin_boolean: state.globalReducers.admin_boolean,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateGlobalStuff: (global_values) =>
            dispatch(updateGlobalStuff(global_values)),
        updateAdminOrNot: (admin_boolean) =>
            dispatch(updateAdminOrNot(admin_boolean)),
        updateAlbumToAdd: (albumToAdd) => dispatch(updateAlbumToAdd(albumToAdd)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginCompany);
