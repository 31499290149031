import React, { Component } from 'react'
import NewStoryPopup from '../Containers/MemberHome/NewStoryPopup'

export class BhodiAbout extends Component {
  render() {
    return (
      <div>
        <NewStoryPopup moveOnePageBack={true} />
      </div>
    )
  }
}

export default BhodiAbout