import React, { Component } from "react";
import "./index.css";

export class Impressie extends Component {
  render() {
    return (
      <div></div>
    )
  }
}  

export default Impressie;
