import React, { Component } from "react";
import "./index.css";
import { connect } from "react-redux";
import filterIcon from "../assets/filtericonglobalevent.png";
import { BsFilter } from "react-icons/bs";
import { mergeAdminEvent } from "../Functions/globalFunctions";

const initialState = {
    dropFile: [],
    filesName: [],
    hour: "",
    minutes: "",
    firstTime: true,
    cartData: [],
    dayBigger: false,
    hours: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23,
    ],
    sectors: [],
    fullpageApi: "",
    eventDate: "",
    isDesktop: false,
    searchText: "",
    allSectors: [],
    latitude: "",
    longitude: "",
    toShowSections: [],
    firstSectionToShow: [],
    onCurrentPage: 0,
    allCampaigns: [],
    currentVoucherWork: "",
    activeSection: 0,
    hideFullpage: false,
    showOptionsToAdd: false,
    showDescriptionPopup: false,
    codegebruikpopup: false,
    codebewaarpopup: false,
    selectedDish: 0,
    currentWeekEvents: [],
    comingWeekEvents: [],
    currentUserId: '',
    currentUser: "",
    currentUserHere: "",
};

export class FilterIconGlobalNav extends Component {
    constructor() {
        super();
        this.state = {
            ...initialState,
        };
    }

    getVoucherEventInfo() {
        this.setState({ ...initialState });

        let uid = localStorage.getItem("userid");
        this.setState({ currentUserId: uid });
        // Current week
        var currentDate = new Date(); // Get the current date
        var currentDay = currentDate.getDay(); // Get the current day of the week (0-6, where 0 is Sunday)

        // Calculate the first date of the current week
        var firstDateCurrentWeek = new Date(currentDate); // Create a new date object
        if (firstDateCurrentWeek.getDay() === 0) {
            let lastMonday = new Date()
            lastMonday.setDate(lastMonday.getDate() - 6)

            firstDateCurrentWeek = new Date(lastMonday)
        } else {
            firstDateCurrentWeek.setDate(currentDate.getDate() - currentDay + 1); // Subtract the current day of the week to get the first day (Sunday)
        }
        firstDateCurrentWeek.setHours(0, 1, 0, 0)

        // Calculate the last date of the current week
        var lastDateCurrentWeek = new Date(firstDateCurrentWeek);
        lastDateCurrentWeek.setDate(firstDateCurrentWeek.getDate() + 6); // Add 6 days to get the last day (Saturday)
        lastDateCurrentWeek.setHours(23, 59, 0, 0)

        // Upcoming week
        var firstDateUpcomingWeek = new Date(lastDateCurrentWeek);
        firstDateUpcomingWeek.setDate(lastDateCurrentWeek.getDate() + 1); // Add 1 day to get the first day of the upcoming week
        firstDateUpcomingWeek.setHours(0, 1, 0, 0)

        var lastDateUpcomingWeek = new Date(firstDateUpcomingWeek);
        lastDateUpcomingWeek.setDate(firstDateUpcomingWeek.getDate() + 6); // Add 6 days to get the last day of the upcoming week
        lastDateUpcomingWeek.setHours(23, 59, 0, 0)

        // Get the timestamps
        var firstTimestampCurrentWeek = firstDateCurrentWeek.getTime();
        var lastTimestampCurrentWeek = lastDateCurrentWeek.getTime();
        var firstTimestampUpcomingWeek = firstDateUpcomingWeek.getTime();
        var lastTimestampUpcomingWeek = lastDateUpcomingWeek.getTime();

        if (this.props.globalsectors) {
            if (this.props.globalsectors.length > 0) {
                this.setState({ allSectors: this.props.globalsectors });
            }
        }

        if (this.props.global_campaigns) {
            if (this.props.global_campaigns.length > 0) {
                let objected = this.props.global_campaigns;
                let arr = [];
                let allCampaignsCompletedToRender = [];
                let arrCurrent = [];
                let arrComing = [];
                objected.map((e) => {
                    e[1].type = "campaign";
                    e[1].currentImageIndex = 0;
                    allCampaignsCompletedToRender.push(e[1]);
                    if (e[1].desc) {
                        // e[1].desc = e[1].desc.substr(0, 200)
                        e[1].short_desc = e[1].desc.substr(0, 32);
                    }
                    if (e[1].campaignType !== "charity") {
                        e[1].images = [e[1].image];
                        e[1].imagesObjected = [
                            { image: e[1].image, pushKey: e[1].pushKey },
                        ];
                    }
                    // if (e[1].created_by === uid) {
                    //     arr.push(e[1])
                    // } else {
                    let filteredHere = this.props.user.filter((y) => {
                        return y.user_id === uid;
                    });
                    this.setState({ currentUserHere: filteredHere[0] })

                    if (e[1].active) {
                        if (e[1].campaignType === "local_event") {
                            if (e[1].takeFromVendor) {
                                return false;
                            }
                            if (e[1].hideOnGlobalEvent) {
                                return false;
                            }
                            if (e[1].eventForGuestUser) {
                                return false;
                            }
                            if (e[1].takeFromAdmin) {
                                let obj = mergeAdminEvent(e[1], this.props.global_campaigns)
                                e[1] = Object.assign({}, obj)
                            }

                            let arrTimingsAvailable = [];
                            if (e[1].timings) {
                                e[1].timings.map((y) => {
                                    let checkedInOfCurrentBlock = [];
                                    if (e[1].checked_in_by) {
                                        Object.entries(e[1].checked_in_by).map((t) => {
                                            if (t[1].event_id === y.eventId) {
                                                checkedInOfCurrentBlock.push(t[1]);
                                            }
                                        });
                                    }
                                    // let getEventDate = new Date(`${y.endDateEvent} ${y.endTimeEvent}`)
                                    let getEventDate = new Date(y.startDateEvent);
                                    let endTimeGood = y.startTimeEvent.concat(":00");
                                    getEventDate.setHours(endTimeGood.split(":")[0]);
                                    getEventDate.setMinutes(endTimeGood.split(":")[1]);
                                    let getCurrentDate = new Date();
                                    if (getCurrentDate.getTime() < getEventDate.getTime()) {
                                        if (
                                            checkedInOfCurrentBlock.length <
                                            Number(y.personsAmountEvent)
                                        ) {
                                            arrTimingsAvailable.push(y);
                                        }
                                    }
                                });
                            }
                            if (arrTimingsAvailable.length > 0) {
                                arr.push(e[1]);
                            }

                            // Current tab check
                            let arrTimingsAvailableCurrent = [];
                            if (e[1].timings) {
                                e[1].timings.map((y) => {
                                    let checkedInOfCurrentBlock = [];
                                    if (e[1].checked_in_by) {
                                        Object.entries(e[1].checked_in_by).map((t) => {
                                            if (t[1].event_id === y.eventId) {
                                                checkedInOfCurrentBlock.push(t[1]);
                                            }
                                        });
                                    }

                                    if (y.isoStartTime && y.isoEndTime) {
                                        let startDateTimeStamp = new Date(Date.parse(y.isoStartTime)).getTime();
                                        let getCurrentDate = new Date();

                                        console.log(new Date(firstTimestampCurrentWeek), '/see timestamp')
                                        console.log(new Date(startDateTimeStamp), '/see timestamp')
                                        console.log(new Date(lastTimestampCurrentWeek), '/see timestamp')

                                        if (getCurrentDate.getTime() < startDateTimeStamp && startDateTimeStamp > firstTimestampCurrentWeek && startDateTimeStamp < lastTimestampCurrentWeek) {
                                            if (
                                                checkedInOfCurrentBlock.length <
                                                Number(y.personsAmountEvent)
                                            ) {
                                                arrTimingsAvailableCurrent.push(y);
                                            }
                                        }
                                    }
                                });
                            }
                            if (arrTimingsAvailableCurrent.length > 0) {
                                arrCurrent.push(e[1]);
                            }
                            // Current tab check

                            // Coming tab check
                            let arrTimingsAvailableComing = [];
                            if (e[1].timings) {
                                e[1].timings.map((y) => {
                                    let checkedInOfCurrentBlock = [];
                                    if (e[1].checked_in_by) {
                                        Object.entries(e[1].checked_in_by).map((t) => {
                                            if (t[1].event_id === y.eventId) {
                                                checkedInOfCurrentBlock.push(t[1]);
                                            }
                                        });
                                    }

                                    if (y.isoStartTime && y.isoEndTime) {
                                        let startDateTimeStamp = new Date(Date.parse(y.isoStartTime)).getTime();
                                        let getCurrentDate = new Date();

                                        if (getCurrentDate.getTime() < startDateTimeStamp && startDateTimeStamp > firstTimestampUpcomingWeek && startDateTimeStamp < lastTimestampUpcomingWeek) {
                                            if (
                                                checkedInOfCurrentBlock.length <
                                                Number(y.personsAmountEvent)
                                            ) {
                                                arrTimingsAvailableComing.push(y);
                                            }
                                        }
                                    }
                                });
                            }
                            if (arrTimingsAvailableComing.length > 0) {
                                arrComing.push(e[1]);
                            }
                            // Coming tab check
                        }
                        if (e[1].campaignType === "web_shop" && e[1].isoEndTime && e[1].isoStartTime) {
                            if (e[1].hideOnGlobal) {
                                return false;
                            }
                            let getCurrentDate = new Date();
                            if (getCurrentDate.getTime() < new Date(Date.parse(e[1].isoEndTime)).getTime()) {
                                arr.push(e[1]);
                            }

                            // Current tab check

                            let startDateTimeStamp = new Date(Date.parse(e[1].isoStartTime)).getTime();
                            console.log(startDateTimeStamp, '/see here')

                            if (getCurrentDate.getTime() < startDateTimeStamp && startDateTimeStamp > firstTimestampCurrentWeek && startDateTimeStamp < lastTimestampCurrentWeek) {
                                arrCurrent.push(e[1]);
                            }
                            // Current tab check

                            // Coming tab check
                            if (getCurrentDate.getTime() < startDateTimeStamp && startDateTimeStamp > firstTimestampUpcomingWeek && startDateTimeStamp < lastTimestampUpcomingWeek) {
                                arrComing.push(e[1]);
                            }
                            // Coming tab check
                        }
                    }
                    // }
                });
                let arrToMake = [];
                let arrToMakeCurrent = [];
                let arrToMakeComing = [];
                arr.map((t, i) => {
                    if (
                        t.campaignType === "local_event" || t.campaignType === "web_shop"
                    ) {
                        arrToMake.push(t);
                    }
                });
                arrCurrent.map((t, i) => {
                    if (
                        t.campaignType === "local_event" || t.campaignType === "web_shop"
                    ) {
                        arrToMakeCurrent.push(t);
                    }
                });
                arrComing.map((t, i) => {
                    if (
                        t.campaignType === "local_event" || t.campaignType === "web_shop"
                    ) {
                        arrToMakeComing.push(t);
                    }
                });
                // arr.map((t, i) => {
                //     if (t.campaignType === "last_minute") {
                //         arrToMake.push(t)
                //     }
                // })

                let allFilteredCurrent = arrToMakeCurrent.filter((y) => {
                    return y.main_sector === this.props.sector;
                });
                let allFilteredComing = arrToMakeComing.filter((y) => {
                    return y.main_sector === this.props.sector;
                });

                console.log(allFilteredCurrent, '/see timestamp')

                this.setState({
                    allCampaigns: arrToMake,
                    currentWeekEvents: allFilteredCurrent,
                    comingWeekEvents: allFilteredComing,
                });
            }
        }
    }

    componentDidMount() {
        this.getVoucherEventInfo();
    }

    render() {
        return (
            this.props.showAsList ? <li
                style={{
                    border: "none",
                    fontWeight: 600,
                    paddingLeft: 0,
                    marginLeft: 0,
                }}
                onClick={() => {
                    this.props.onPopupOpen(this.state.currentWeekEvents, this.state.comingWeekEvents)
                }}
            >
                <BsFilter style={{ fontSize: 20 }} />
            </li> : <a
                href="javascript:void(0)"
                className="btn btn-black orderbasketicon btnpopupwork"
                onClick={() => {
                    this.props.onPopupOpen(this.state.currentWeekEvents, this.state.comingWeekEvents)
                }}
            >
                <img
                    src={filterIcon}
                    style={{ maxWidth: 20, marginTop: 3 }}
                    alt="filter-icon"
                />
            </a>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducers.user,
        album: state.postsReducers.album,
        images: state.postsReducers.images,
        globalsectors: state.categoriesReducer.globalsectors,
        global_campaigns: state.campaignsReducer.global_campaigns,
    };
};

export default connect(mapStateToProps)(FilterIconGlobalNav);
