import React, { Component } from 'react'
import playicon from "../../assets/playiconlogomobile.png"
import { connect } from 'react-redux'
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import firebase from '../../Config/Firebase'
import swal from "sweetalert";
import imageCompression from "browser-image-compression";

export class ImpressiePopup extends Component {

    constructor() {
        super()
        this.state = {
            currentUserId: "",
            currentUser: "",
            showImpressiePopup: true,
            impressiePhotos: [],
            currentImpressieIndex: 0,
            uploadedItems: 0,
            dropFile: [],
            loaderAddingImpressie: false,
        }
    }

    componentDidMount() {
        let userid = localStorage.getItem("userid")

        this.setState({ currentUserId: userid })

        if (this.props.all_impressies) {
            if (this.props.all_impressies.length > 0) {
                let filtered = this.props.all_impressies.filter((h) => {
                    return h[0] === this.props.member_user_id
                })
                if (filtered.length > 0) {
                    let objected = Object.entries(filtered[0][1])
                    let arr = []
                    objected.map((g) => {
                        arr.push(g[1])
                    })
                    this.setState({ impressiePhotos: arr })
                }
            }
        } else {
            firebase
                .database()
                .ref("impressiePhotos/" + this.props.member_user_id)
                .once("value", (data) => {
                    let a = data.val();
                    if (a) {
                        let objected = Object.entries(a);
                        let arr = [];
                        objected.map((g) => {
                            arr.push(g[1]);
                        });
                        this.setState({ impressiePhotos: arr });
                    } else {
                        this.setState({ impressiePhotos: [] });
                    }
                });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.all_impressies !== this.props.all_impressies) {
            if (this.props.all_impressies.length > 0) {
                this.setState({ impressiePhotos: [] })
                let filtered = this.props.all_impressies.filter((h) => {
                    return h[0] === this.props.member_user_id
                })
                if (filtered.length > 0) {
                    let objected = Object.entries(filtered[0][1])
                    let arr = []
                    objected.map((g) => {
                        arr.push(g[1])
                    })
                    this.setState({ impressiePhotos: arr })
                } else {
                    this.setState({ impressiePhotos: [] });
                }
            } else {
                this.setState({ impressiePhotos: [] })
            }
        }
    }

    deleteImpressie(pushKey) {
        let uid = localStorage.getItem("userid");
        firebase
            .database()
            .ref("impressiePhotos/" + uid + "/" + pushKey)
            .remove()
            .then(() => {
                this.setState({ currentImpressie: 0 });
            });
    }

    // Blob creator
    async b64toBlob(dataURI) {
        var byteString = await atob(dataURI.split(",")[1]);
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);

        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: "image/jpeg" });
    }

    // Random name creator for pushing data in firebase storage
    makeid(length) {
        var result = "";
        var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    // File uploader
    async fileUploadFirst(e) {
        let file = e.target;
        let uid = localStorage.getItem("userid");

        let dropFile = this.state.dropFile;
        for (let i = 0; i < file.files.length; i++) {
            if (
                file.files[i].type.match(/image.*/) || file.files[i].type.match(/video.*/)
            ) {
                var sizeInMb = file.files[i].size / 1024;
                var sizeLimit = 1024 * 15;
                if (sizeInMb < sizeLimit) {
                    dropFile.push(file.files[i]);
                }
            }
        }

        this.setState({ dropFile, loaderAddingImpressie: true });

        for (let i = 0; i < dropFile.length; i++) {
            if (dropFile[i].type.match(/image.*/)) {
                firebase
                    .storage()
                    .ref()
                    .child(`impressieimages/${dropFile[i].name}${new Date().getTime()}`)
                    .put(dropFile[i])
                    .then((snapshot) => {
                        snapshot.ref.getDownloadURL().then((snapUrl) => {
                            let push = firebase.database().ref("impressiePhotos").push().key;
                            let today = new Date();
                            let date = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
                            let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
                            let timestamp = Math.floor(Date.now() / 100);
                            let obj = {
                                push,
                                snapUrl,
                                type: "image",
                                resizedUrl: snapUrl,
                                date,
                                time,
                                created_by: this.state.currentUserId,
                                timestamp,
                            };

                            firebase
                                .database()
                                .ref("impressiePhotos/" + uid + "/" + push)
                                .set(obj)
                                .then(() => {
                                    this.setState({ uploadedItems: this.state.uploadedItems + 1, });

                                    if (this.state.uploadedItems === dropFile.length) {
                                        swal({
                                            title: "Gelukt!",
                                            text: "De mediabestanden zijn succesvol toegevoegd aan je impressie pagina.",
                                            icon: "success",
                                            className: "icon-small-swal",
                                        }).then(() => {
                                            this.setState({
                                                loaderAddingImpressie: false,
                                                dropFile: [],
                                                uploadedItems: 0,
                                            });
                                        });
                                    }
                                })
                                .catch((err) => {
                                    console.log(err);
                                });
                        });
                    });
                // })
                // .catch(function (error) {
                //   console.log(error.message);
                // });
            }
            if (dropFile[i].type.match(/video.*/)) {
                let videoUrl = URL.createObjectURL(dropFile[i]);
                let frames = await this.extractFramesFromVideo(videoUrl);

                frames.map(async (e) => {
                    let blob = await this.b64toBlob(e);
                    var options = {
                        maxSizeMB: 5,
                    };
                    imageCompression(blob, options).then((compressedFile) => {
                        firebase
                            .storage()
                            .ref()
                            .child(`impressieimages/${this.makeid(8)}`)
                            .put(compressedFile)
                            .then((snapshot) => {
                                snapshot.ref.getDownloadURL().then((thumbnailUrl) => {
                                    var options = {
                                        maxSizeMB: 1,
                                        maxWidthOrHeight: 800,
                                    };
                                    imageCompression(blob, options).then((compressedFiler) => {
                                        firebase
                                            .storage()
                                            .ref()
                                            .child(`impressieimages/${this.makeid(8)}`)
                                            .put(compressedFiler)
                                            .then((snapshottt) => {
                                                snapshottt.ref.getDownloadURL().then((resizedUrl) => {
                                                    firebase
                                                        .storage()
                                                        .ref()
                                                        .child(`impressievideos/${dropFile[i].name}${new Date().getTime()}`)
                                                        .put(dropFile[i])
                                                        .then((snapshot) => {
                                                            snapshot.ref.getDownloadURL().then((snapUrl) => {
                                                                let push = firebase.database().ref("impressiePhotos").push().key;
                                                                let today = new Date();
                                                                let date = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
                                                                let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
                                                                let timestamp = Math.floor(Date.now() / 100);
                                                                let obj = {
                                                                    push,
                                                                    thumbnailUrl,
                                                                    snapUrl,
                                                                    type: "video",
                                                                    resizedUrl,
                                                                    date,
                                                                    time,
                                                                    created_by: this.state.currentUserId,
                                                                    timestamp,
                                                                };
                                                                firebase
                                                                    .database()
                                                                    .ref("impressiePhotos/" + uid + "/" + push)
                                                                    .set(obj)
                                                                    .then(() => {
                                                                        this.setState({ uploadedItems: this.state.uploadedItems + 1, });
                                                                        if (this.state.uploadedItems === dropFile.length) {
                                                                            swal({
                                                                                title: "Gelukt!",
                                                                                text: "De mediabestanden zijn succesvol toegevoegd aan je impressie pagina.",
                                                                                icon: "success",
                                                                                className: "icon-small-swal",
                                                                            }).then(() => {
                                                                                this.setState({
                                                                                    loaderAddingImpressie: false,
                                                                                    dropFile: [],
                                                                                    uploadedItems: 0,
                                                                                });
                                                                            });
                                                                        }
                                                                    })
                                                                    .catch((err) => {
                                                                        console.log(err);
                                                                    });
                                                            });
                                                        });
                                                });
                                            });
                                    });
                                });
                            });
                    });
                });
            }
        }
    }

    async extractFramesFromVideo(videoUrl, fps = 25) {
        return new Promise(async (resolve) => {
            let videoBlob = await fetch(videoUrl).then((r) => r.blob());
            let videoObjectUrl = URL.createObjectURL(videoBlob);
            let video = document.createElement("video");

            let seekResolve;
            video.addEventListener("seeked", async function () {
                if (seekResolve) seekResolve();
            });

            video.src = videoObjectUrl;

            while (
                (video.duration === Infinity || isNaN(video.duration)) &&
                video.readyState < 2
            ) {
                await new Promise((r) => setTimeout(r, 1000));
                video.currentTime = 10000000 * Math.random();
            }

            let canvas = document.createElement("canvas");
            let context = canvas.getContext("2d");
            let [w, h] = [video.videoWidth, video.videoHeight];
            canvas.width = w;
            canvas.height = h;

            let frames = [];
            let interval = 1 / fps;
            let currentTime = 0;

            video.currentTime = currentTime;
            await new Promise((r) => (seekResolve = r));

            context.drawImage(video, 0, 0, 1920, 1080);
            let base64ImageData = canvas.toDataURL();
            frames.push(base64ImageData);

            currentTime += interval;
            resolve(frames);
        });
    }

    render() {
        return (
            <div>
                {this.state.showImpressiePopup && <div className='popup-impressie-page'>
                    <div className="overlay-poimpa" onClick={() => {
                        this.props.closeImpressie()
                        // this.setState({ showImpressiePopup: false })
                    }}>
                    </div>
                    <div className={`content-poimpa ${this.state.impressiePhotos.length === 0 && "noimpressiescont-poimpa"}`}>
                        {this.state.impressiePhotos.length > 0 ? <div className='image-container-impressie' style={{ backgroundImage: this.state.impressiePhotos[this.state.currentImpressieIndex].type !== "video" && `url("${this.state.impressiePhotos[this.state.currentImpressieIndex].snapUrl}")` }}>
                            {this.state.impressiePhotos[this.state.currentImpressieIndex].type === "video" && <video src={this.state.impressiePhotos[this.state.currentImpressieIndex].snapUrl} autoPlay loop muted playsInline></video>}
                            {this.state.impressiePhotos.length > 1 && <div className='mid-icontimpr'>
                                {this.state.impressiePhotos.map((t, index) => {
                                    return (<div className='img-micont' style={{ backgroundImage: t.type === "image" ? `url("${t.snapUrl}")` : `url("${t.thumbnailUrl}")` }} onClick={() => {
                                        this.setState({ currentImpressieIndex: index })
                                    }}>
                                    </div>)
                                })}
                            </div>}
                        </div> : (this.props.member_user_id === this.state.currentUserId ? <div>
                            <div className='top-ssmhn tssmhn-impressie'>
                                <img src={playicon} alt='playicon' />
                                <p>Impressie</p>
                                <p>
                                    Voeg meerdere foto's en/of korte<br />
                                    video's toe voor een uitgebreide impressie van je bedrijf.
                                </p>
                                <div className='container-biconimp' style={{ marginTop: 30, width: "auto", height: "auto" }}>
                                    <AddIcon />
                                    <input
                                        type="file"
                                        className="inputfilemob"
                                        accept="image/*, video/*"
                                        multiple={true}
                                        onChange={(e) => this.fileUploadFirst(e)}
                                    />
                                </div>
                            </div>
                        </div> : <div>
                            <div className='top-ssmhn tssmhn-impressie'>
                                <img src={playicon} alt='playicon' />
                                <p>Impressie</p>
                                <p>Er zijn nog geen foto’s toegevoegd.</p>
                            </div>
                        </div>)}
                        {(this.state.impressiePhotos.length > 0 && this.props.member_user_id === this.state.currentUserId) && (<div className='bottom-icons-conimp'>
                            <div className='container-biconimp' onClick={this.deleteImpressie.bind(this, this.state.impressiePhotos[this.state.currentImpressieIndex].push)}>
                                <DeleteIcon />
                            </div>
                            <div className='container-biconimp'>
                                <AddIcon />
                                <input
                                    type="file"
                                    className="inputfilemob"
                                    accept="image/*, video/*"
                                    multiple={true}
                                    onChange={(e) => this.fileUploadFirst(e)}
                                />
                            </div>
                        </div>)}
                        <div className='top-icons-conimp'>
                            <div className='container-biconimp' onClick={() => {
                                this.props.closeImpressie()
                                // this.setState({ showImpressiePopup: false })
                            }}>
                                <CloseIcon />
                            </div>
                        </div>
                    </div>
                </div>}

                {this.state.loaderAddingImpressie && (
                    <div className="loader-area">
                        <CloseIcon onClick={() => window.location.reload()} />
                        <div className="lds-ring">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                        <p style={{ color: "white" }}>
                            Uploading Media {this.state.uploadedItems} /{" "}
                            {this.state.dropFile.length}
                        </p>
                    </div>
                )}

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        bhodi_products: state.productsReducers.bhodi_products,
        global_campaigns: state.campaignsReducer.global_campaigns,
        global_values: state.globalReducers.global_values,
        globalsectors: state.categoriesReducer.globalsectors,
        stock_images: state.globalReducers.stock_images,
        all_impressies: state.globalReducers.all_impressies,
        user: state.authReducers.user,
    };
};

export default connect(
    mapStateToProps
)(ImpressiePopup);