import React, { Component } from 'react'
import styles from "./index.module.css"
import { connect } from 'react-redux';

export class ProductTimer extends Component {


    constructor() {
        super()
        this.state = {
            availabilityTime: "",
            productTimerEndOn: "",
        }
    }

    componentDidMount() {
        if (this.props.user) {
            let filtered = this.props.user.filter((t) => {
                return t.user_id === this.props.creatorId
            })
            if (filtered.length > 0) {
                if (this.props.global_values.productsTimer) {
                    let dateCreated = new Date(this.props.availabilityTime)
                    let splitted = this.props.global_values.productsTimer.split(" ")
                    if (splitted[1].includes("minute")) {
                        dateCreated.setMinutes(dateCreated.getMinutes() + Number(splitted[0]))
                    } else {
                        dateCreated.setHours(dateCreated.getHours() + Number(splitted[0]))
                    }

                    this.setState({ availabilityTime: dateCreated.getTime() })
                }
            }
        }

        this.interval = setInterval(
            () => this.setState({ timeCurrent: Date.now() }),
            1000
        );
    }

    getMonthName(i) {
        i = Number(i);
        if (i === 1) {
            return "januari";
        } else if (i === 2) {
            return "februari";
        } else if (i === 3) {
            return "maart";
        } else if (i === 4) {
            return "april";
        } else if (i === 5) {
            return "Mei";
        } else if (i === 6) {
            return "juni";
        } else if (i === 7) {
            return "juli";
        } else if (i === 8) {
            return "augustus";
        } else if (i === 9) {
            return "september";
        } else if (i === 10) {
            return "oktober";
        } else if (i === 11) {
            return "november";
        } else if (i === 12) {
            return "december";
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.user !== prevProps.user) {
            let filtered = this.props.user.filter((t) => {
                return t.user_id === this.props.creatorId
            })
            if (filtered.length > 0) {
                if (this.props.global_values.productsTimer) {
                    let dateCreated = new Date(this.props.availabilityTime)
                    let splitted = this.props.global_values.productsTimer.split(" ")
                    if (splitted[1].includes("minute")) {
                        dateCreated.setMinutes(dateCreated.getMinutes() + Number(splitted[0]))
                    } else {
                        dateCreated.setHours(dateCreated.getHours() + Number(splitted[0]))
                    }

                    this.setState({ availabilityTime: dateCreated.getTime() })
                }
            }
        }
    }

    calculateTimeStamp() {
        if (this.state.availabilityTime) {
            var date = new Date(this.state.availabilityTime);

            let dateFuture = date.getTime();

            let dateNow = new Date().getTime();

            let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

            let days = Math.floor(diffInMilliSeconds / 86400);
            let daysNumber = Math.floor(diffInMilliSeconds / 86400);
            diffInMilliSeconds -= days * 86400;

            let hours = Math.floor(diffInMilliSeconds / 3600) % 24;
            diffInMilliSeconds -= hours * 3600;

            let minutes = Math.floor(diffInMilliSeconds / 60) % 60;
            diffInMilliSeconds -= minutes * 60;

            let seconds = diffInMilliSeconds % 60;

            let difference = "";
            if (days > 0) {
                difference += days === 1 ? `${days}:` : `${days}:`;
            }

            if (days < 10) {
                days = `0${days}`;
            } else {
                days = `${days}`;
            }

            if (hours < 10) {
                hours = `0${hours}`;
            } else {
                hours = `${hours}`;
            }

            if (minutes < 10) {
                minutes = `0${minutes}`;
            } else {
                minutes = `${minutes}`;
            }

            if (seconds < 9) {
                seconds = `0${Math.ceil(seconds)}`;
            } else {
                seconds = `${Math.ceil(seconds)}`;
            }

            if (dateFuture > dateNow) {
                return (
                    <>
                        <font style={{ color: "#C17f6b" }}>
                            {`Te bestellen over `}
                            {Number(days) > 0 && (`${days} days `)}
                            {Number(hours) > 0 && (`${hours} hours `)}
                            {Number(minutes) > 0 && (`${minutes} min `)}
                            {Number(seconds) > 0 && (`${seconds} sec`)}
                        </font>
                    </>
                );
            } else {
                if (this.props.closeScreen) {
                    this.props.closeScreen()
                }
                clearInterval(this.interval);
                return <p className={styles.descProductMain}>&nbsp;</p>;
            }
        } else {
            return <p className={styles.descProductMain}>&nbsp;</p>;
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval)
    }

    render() {
        return (
            this.calculateTimeStamp()
            // <>
            //     <p className={styles.descProductMain} style={{ textTransform: "uppercase" }}>{new Date(this.state.availabilityTime).getDate()} {this.getMonthName(new Date(this.state.availabilityTime).getMonth() + 1)} | {new Date(this.state.availabilityTime).getHours()}:{new Date(this.state.availabilityTime).getMinutes()} | {new Date(this.state.availabilityTime).getFullYear()}</p>
            //     <div className='big-container-timer'>
            //         <div className='inner-bigconttim'>
            //             <p>47</p>
            //             <p>min</p>
            //         </div>
            //         <div className='inner-bigconttim'>
            //             <p>22</p>
            //             <p>sec</p>
            //         </div>
            //     </div>
            // </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducers.user,
    };
};



export default connect(
    mapStateToProps,
)(ProductTimer);